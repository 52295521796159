export default class Invoice {
  constructor(client) {
    this.client = client;
  }

  getAllInvoices(pageIndex = 1, pageSize = 10) {
    return this.client.get(
      `/invoices?page[number]=${pageIndex}&page[size]=${pageSize}`
    );
  }

  getAllInvoicesByDates(pageIndex = 1, pageSize = 10, begin, end) {
    return this.client.get(
      `/invoices?to=${end}&from=${begin}&page[number]=${pageIndex}&page[size]=${pageSize}`
    );
  }

  getAllInvoicesByCustomerName(name, pageIndex = 1, pageSize = 10) {
    return this.client.get(
      `/invoices?query=${name}&page[number]=${pageIndex}&page[size]=${pageSize}`
    );
  }

  saveInvoice(invoiceData) {
    return this.client.post("/invoices", { data: invoiceData });
  }

  addPaymentMessage(data) {
    return this.client.post("/set_invoice_payment_message", { message: data });
  }

  getPaymentMessage(data) {
    return this.client.get("/get_invoice_payment_message", { message: data });
  }

  sendInvoiceToCustomerAsMail(invoiceId) {
    return this.client.post(`invoices/${invoiceId}/send_invoice`);
  }

  getDownloadableInvoiceLink(invoiceId) {
    return this.client.get(`invoices/${invoiceId}/get_invoice_download_link`);
  }

  editInvoice(data) {
    return this.client.put(`invoices/${data.invoiceId}`, { data });
  }

  deleteInvoice(id) {
    return this.client.delete(`invoices/${id}`);
  }

  sendReceipt(id) {
    return this.client.post(`invoices/${id}/send_receipt`);
  }

  getDownloadableReceiptLink(invoiceId) {
    return this.client.get(`invoices/${invoiceId}/get_receipt_download_link`);
  }
}
