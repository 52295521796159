import { Card, Dropdown, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import strings from '../../strings'
import { getOnePurchaseOrder, supplieracceptOrder, supplierRejectOrder } from '../../redux/actions/purchaseOrder';
import { connect } from 'react-redux';
import "./index.scss";
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';

const SupplierPage = ({ match, getOnePurchaseOrder, supplierRejectOrder, supplieracceptOrder }) => {
  const [ suppliersRequest, setSuppliersRequest ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ selectedAction, setSelectedAction ] = useState(null);
  const [ acceptLoading, setAcceptLoading ] = useState(false);
  const [ rejectLoading, setRejectLoading ] = useState(false);

  useEffect(() => {
    const { params } = match;
    const { uuid } = params;

    handleGetSuppliersRequest(uuid);
  }, [match]);

  
  const column = [
    {
      key: "1",
      title: strings.merchantName,
      dataIndex: "merchant",
      render(record) {
        return <span>{record.business_name}</span>
      }
    },
    {
      key: "2",
      title: strings.merchantAddress,
      dataIndex: "merchant",
      render(record) {
        return <span>{record.address_line1}, {record.address_line2} {record.state}</span>
      }
    },
    {
      key: "3",
      title: strings.orderItems,
      dataIndex: "purchase_order_items",
      render(record) {
        return <div style={{display: "flex", flexDirection: "column", gap:10}}>
          {record.length > 0 && record.map((items, index) => {
            return (<span key={index}>{items.name} - {items.ordered_quantity} units </span>)
          })}
        </div>
      }
    },
    {
      key: "4",
      title: strings.supplier,
      dataIndex: "supplier",
      render(record) {
        return <span>{record.business_name}</span>
      }
    },
    {
      key: "5",
      title: strings.status,
      dataIndex: "purchase_order_histories",
      render(record) {
        return <span>{record.length > 0 && record[record.length - 1].status}</span>
      }
    },
    {
      key: "6",
      title: strings.action,
      dataIndex: "supplier",
      render(value, record) {
        return (
          <Dropdown menu={{ items: dropdownItem }}>
            <EllipsisOutlined 
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAction(record);
              }}
              onMouseEnter={(e) => {
                e.stopPropagation();
                setSelectedAction(record);
              }} 
            />
          </Dropdown>
        )
      }
    },
    
  ]

  const handleAccept = () => {
    setAcceptLoading(true);
    supplieracceptOrder(selectedAction.uuid)
    .then(res => {
      if(res.status === 200){
        Modal.success({
          title: res.data.message,
          onOk: () => {
            setAcceptLoading(false);
            handleGetSuppliersRequest();
          }
        });
      }
    })
    .catch(err => {
      setAcceptLoading(false);
      Modal.error({
        title: `${err?.response?.data?.error || err?.response?.data?.message || "Unable to complete your request - " + err?.message}`
      })
    })
  }

  const handleReject = () => {
    setRejectLoading(true)
    supplierRejectOrder(selectedAction.uuid)
    .then(res => {
      if(res.status === 200){
        Modal.success({
          title: res.data.message,
          onOk: () => {
            setRejectLoading(false);
            handleGetSuppliersRequest();
          }
        });
      }
    })
    .catch(err => {
      setRejectLoading(false);
      Modal.error({
        title: `${err?.response?.data?.error || err?.response?.data?.message || "Unable to complete your request - " + err?.message}`
      })
    })
  }

  const dropdownItem = [
    {
      key: '1',
      label: (
        <div className='flex_display' onClick={handleAccept}>
          {acceptLoading && <LoadingOutlined spin />}
          <p>{strings.acceptRequest}</p>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className='flex_display' onClick={handleReject}>
          {rejectLoading && <LoadingOutlined spin />}
          <p>{strings.rejectRequest}</p>
        </div>
      ),
    }
  ]

  const handleGetSuppliersRequest = (uid) => {
    setLoading(true);
    getOnePurchaseOrder(uid)
    .then(res => {
      if(res.status === 200){
        setSuppliersRequest([res.data]);
        setLoading(false);
      }
    })
    .catch(err => {
      console.log("err", err);
      setLoading(false);
    })
  }
  
  return (
    <Card 
      title={<h2>{strings.kindlyPerformAction}</h2>}
      bordered
      className='card_supply'
    >
      
      <Table 
        dataSource={suppliersRequest}
        columns={column}
        loading={loading}
      />
    </Card>
  )
}

export default connect(null, {
  getOnePurchaseOrder,
  supplierRejectOrder,
  supplieracceptOrder
})(SupplierPage);