import {
  GET_ALL_PRODUCTS,
  GET_PAGE_PRODUCTS,
  GET_PRODUCT_SUPPLIES,
  GET_SUPPLIERS,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCT_SUPPLIES,
  GET_ALL_BRANCH_PRODUCTS
} from "../constants";

const initialState = {
  allProducts: localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")) : [],
  pageProducts: [],
  branchProducts: [],
  suppliers: [],
  supplies: []
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: payload,
      };
    case GET_PAGE_PRODUCTS:
      return {
        ...state,
        pageProducts: payload,
      };
    case GET_ALL_BRANCH_PRODUCTS:
      return {
        ...state,
        branchProducts: payload,
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        allProducts: payload,
        branchProducts: payload,
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload,
      };

    case GET_PRODUCT_SUPPLIES:
      return {
        ...state,
        supplies: payload,
      };

    case UPDATE_PRODUCT_SUPPLIES:
      return {
        ...state,
        supplies: [...state.supplies, payload],
      };

    default:
      return state;
  }
};
export default reducer;
