import { Modal, Table, Card, Button, Input, Select, message } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import React, { useState, useEffect } from "react";
import api from "../../../../../../api";

const { Option } = Select;

const CustomerGroups = ({ onCancel, visible, customers }) => {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editGroup, setEditGroup] = useState(null);
  const [groupDetailsVisible, setGroupDetailsVisible] = useState(false);
  const [groupDetail, setGroupDetail] = useState(null);

  useEffect(() => {
    getCustomerGroup();
  }, []);

  const deleteGroup = id => {
    Modal.confirm({
      title: "Delete Group",
      content: "Are you sure you want to delete this customer group?",
      onOk: () =>
        api.HttpClient.delete(`/customer_groups/${id}`)
          .then(res => {
            if (res && res.status === 204) {
              Modal.success({
                title: "Group deleted successfully",
                onOk: () => getCustomerGroup(),
              });
            }
          })
          .catch(err => {
            message.error(err.message);
          }),
    });
  };

  const getCustomerGroup = () => {
    setLoading(true);
    api.HttpClient.get("/customer_groups")
      .then(res => {
        setLoading(false);
        if (res.status && res.status === 200) {
          return setCustomerGroups(res.data);
        }
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
  };

  const getCustomerName = arr => {
    const initialCustomers = [];
    const customerObj = {};
    arr.forEach(id => (customerObj[id] = id));
    customers &&
      customers.forEach(cust => {
        if (customerObj[cust.id])
          initialCustomers.push(
            `${cust.first_name ? cust.first_name : "N/A"} ${
              cust.last_name ? cust.last_name : ""
            } ${cust.phone_number ? ":" + cust.phone_number : ""}`
          );
      });
    return (
      <ol>
        {initialCustomers.map(name => (
          <li>{name}</li>
        ))}
      </ol>
    );
  };

  const columns = [
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of customers",
      dataIndex: "customers",
      key: "customerIds",
      render: data => data.length,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (value, data) => (
        <>
          <Icon
            type="edit"
            title="edit group"
            onClick={() => {
              setEditVisible(true);
              setEditGroup(data);
            }}
          />{" "}
          <Icon
            title="delete group"
            style={{ marginLeft: "10px" }}
            onClick={() => deleteGroup(value)}
            type="delete"
          />{" "}
          <Button
            type="link"
            onClick={() => {
              setGroupDetailsVisible(true);
              setGroupDetail(data);
            }}
          >
            Details
          </Button>
        </>
      ),
    },
  ];

  const AddGroupForm = ({ form }) => {
    const { getFieldDecorator } = form;
    const [addLoading, setAddLoading] = useState(false);

    const submitAddForm = () => {
      form.validateFields((err, values) => {
        if (err) return;
        setAddLoading(true);
        api.HttpClient.post("/customer_groups", { group: { ...values } })
          .then(res => {
            setAddLoading(false);

            if (res && res.status === 200) {
              form.resetFields();
              Modal.success({
                title: "Group created successfully",
                onOk: () => {
                  setAddModalVisible(false);
                  getCustomerGroup();
                },
              });
            }
          })
          .catch(err => {
            setAddLoading(false);
            message.error(err.message);
          });
      });
    };

    return (
      <Form layout="vertical">
        <Form.Item label="Group Name">
          {getFieldDecorator("name", {
            rules: [
              { required: true, message: "Please input the group name!" },
            ],
          })(<Input size="large" placeholder="Enter group name" />)}
        </Form.Item>

        <Form.Item label="Select Customers">
          {getFieldDecorator("customer_ids", {
            rules: [
              {
                required: true,
                message: "Please select at least 2 customers!",
              },
            ],
          })(
            <Select
              allowClear
              mode="multiple"
              showSearch
              placeholder="Select assigned IDs"
              optionFilterProp="children"
              size="large"
              style={{ width: "80%" }}
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  (option.props.id && option.props.id.indexOf(input) >= 0)
                );
              }}
              disabled={false}
            >
              {customers &&
                customers.map(customer => (
                  <Option
                    key={customer.user_id}
                    value={customer.id}
                    id={customer.loyalty_id}
                  >
                    {`${customer.first_name ? customer.first_name : "N/A"} ${
                      customer.last_name ? customer.last_name : ""
                    } ${
                      customer.phone_number ? ":" + customer.phone_number : ""
                    }`}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button type="ghost" onClick={() => setAddModalVisible(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              loading={addLoading}
              onClick={submitAddForm}
            >
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const EditGroupForm = ({ form, group, customers }) => {
    const { getFieldDecorator } = form;
    const [editLoading, setEditLoading] = useState(false);

    const submitEditForm = () => {
      form.validateFields((err, values) => {
        if (err) return;

        const newCustomers = [];
        values.customer_ids.forEach(value => {
          if (Number(value)) {
            newCustomers.push(value);
          } else if (customerDetailsObj[value])
            newCustomers.push(customerDetailsObj[value]);
        });
        setEditLoading(true);
        api.HttpClient.put(`/customer_groups/${group.id}`, {
          group: { ...values, customer_ids: newCustomers },
        })
          .then(res => {
            setEditLoading(false);
            if (res && res.status === 200) {
              form.resetFields();
              Modal.success({
                title: "Group edited successfully",
                onOk: () => {
                  setEditGroup(null);
                  setEditVisible(false);
                  getCustomerGroup();
                },
              });
            }
          })
          .catch(err => {
            setEditLoading(false);
            message.error(err.message);
          });
      });
    };

    const { name } = group;

    const initialCustomers = [];
    const customerObj = {};
    const customerDetailsObj = {};
    group.customers.forEach(id => (customerObj[id] = id));
    customers &&
      customers.forEach(cust => {
        if (customerObj[cust.id]) {
          initialCustomers.push(
            `${cust.first_name ? cust.first_name : "N/A"} ${
              cust.last_name ? cust.last_name : ""
            } ${cust.phone_number ? ":" + cust.phone_number : ""}`
          );
          customerDetailsObj[
            `${cust.first_name ? cust.first_name : "N/A"} ${
              cust.last_name ? cust.last_name : ""
            } ${cust.phone_number ? ":" + cust.phone_number : ""}`
          ] = cust.id;
        }
      });

    return (
      <Form layout="vertical">
        <Form.Item label="Group Name">
          {getFieldDecorator("name", {
            initialValue: name,
            rules: [
              { required: true, message: "Please input the group name!" },
            ],
          })(<Input size="large" placeholder="Enter group name" />)}
        </Form.Item>

        <Form.Item label="Select Customers">
          {getFieldDecorator("customer_ids", {
            initialValue: initialCustomers,
            rules: [
              {
                required: true,
                message: "Please select at least 2 customers!",
              },
            ],
          })(
            <Select
              allowClear
              mode="multiple"
              showSearch
              placeholder="Select assigned IDs"
              optionFilterProp="children"
              size="large"
              style={{ width: "80%" }}
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  (option.props.id && option.props.id.indexOf(input) >= 0)
                );
              }}
              disabled={false}
            >
              {customers &&
                customers.map(customer => (
                  <Option
                    key={customer.user_id}
                    value={customer.id}
                    id={customer.loyalty_id}
                    disabled={!!customerObj[customer.id]}
                  >
                    {`${customer.first_name ? customer.first_name : "N/A"} ${
                      customer.last_name ? customer.last_name : ""
                    } ${
                      customer.phone_number ? ":" + customer.phone_number : ""
                    }
    `}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              type="ghost"
              onClick={() => {
                setEditVisible(false);
                setEditGroup(null);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "20px" }}
              type="primary"
              loading={editLoading}
              onClick={submitEditForm}
            >
              Edit
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const AddForm = Form.create()(AddGroupForm);
  const EditForm = Form.create()(EditGroupForm);

  return (
    <div>
      <Modal
        open={visible}
        onCancel={onCancel}
        onOk={onCancel}
        width={700}
        title="Customer Groups"
      >
        <Modal
          open={addModalVisible}
          onCancel={() => setAddModalVisible(false)}
          footer={[]}
        >
          <AddForm />
        </Modal>
        {editGroup && (
          <Modal open={editVisible} footer={[]}>
            <EditForm group={editGroup} customers={customers} />
          </Modal>
        )}
        {groupDetail && (
          <Modal
            visible={groupDetailsVisible}
            title="Customer Group"
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  setGroupDetail(null);
                  setGroupDetailsVisible(false);
                }}
              >
                Ok
              </Button>,
            ]}
          >
            <p>
              <strong>Group Name:</strong> {groupDetail.name}
            </p>
            <p>
              <strong>Date Created:</strong>{" "}
              {new Date(groupDetail.created_at).toDateString() +
                ", " +
                new Date(groupDetail.created_at).toLocaleTimeString()}
            </p>
            <p>
              <strong>Customers:</strong>{" "}
              {getCustomerName(groupDetail.customers)}
            </p>
          </Modal>
        )}
        <Card
          size="small"
          headStyle={{ marginTop: "-20px" }}
          loading={loading}
          bordered={false}
          extra={[
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              Add New
            </Button>,
          ]}
        >
          {customerGroups.length === 0 && (
            <p>
              <em>You don't have any customer group saved yet</em>
            </p>
          )}
          {customerGroups.length > 0 && (
            <Table dataSource={customerGroups} columns={columns} />
          )}
        </Card>
      </Modal>
    </div>
  );
};

export default CustomerGroups;
