import { message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAllReturns, mangeReturnedItems } from '../../../../../../redux/actions/saleActions';
import { connect } from 'react-redux';
import CustomTable from '../../../../../../components/CustomDataTable';
import strings from '../../../../../../strings';
import { EditOutlined } from "@ant-design/icons"
import ManageReturnModal from '../ManageReturnModa';
import moment from 'moment';

const ReturnedItems = ({
    open, 
    onCancel,
    getAllReturns,
    user,
    mangeReturnedItems
}) => {
    const [ pagination, setPagination ] = useState({
        total: 0,
        pageSize: 10,
        pageNumber: 1,
        defaultPageSize:10
    });
    const [ loading, setLoading ] = useState(false);
    const [ returnedItems, setReturnedItems ] = useState([]);
    const [ ismanageReturnOpen, setIsManageReturnOpen ] = useState(false);
    const [ seletedItem, setSelectedItem ] = useState(null);

    useEffect(() => {
        handleGetReturnedItems(pagination.pageNumber, pagination.pageSize)
    }, []);

    const columns = [
        {
          title: strings.productName,
          dataIndex: "product",
          render: (record) =>
            record ? <span>{record.name}</span> : <span>N/A</span>,
        },
        {
          title: strings.price,
          dataIndex: "product",
          render: (record, value) => (
            record ? <span>{user.currency} {record.variants && record.variants.length > 0 && record.variants[0].price || record.price}</span> : <span>N/A</span>
          )
        },
        {
          title: strings.quantity,
          dataIndex: "quantity",
          render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
    
        //   onFilter: (value, record) => {
        //     // return String(record.paymentType).indexOf(value) === 0;
        //     return String(record.paymentType).toLowerCase().includes(value.toLowerCase());
        //   },
        //   filterDropdown: (...props) => {
        //     const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
        //       props[0];
    
        //     // console.log(props);
    
        //     return (
        //       <section className="filter-container">
        //         <Radio.Group
        //           onChange={(e) => {
        //             setSelectedKeys([e.target.value]);
        //           }}
        //           value={selectedKeys[0]}
        //           style={{ padding: "0 10px" }}
        //         >
        //           {Array.isArray(paymentMethod) &&
        //             paymentMethod.map((filter, key) => (
        //               <Radio key={key + filter} style={radioStyle} value={filter}>
        //                 {filter}
        //               </Radio>
        //             ))}
        //             <Radio key={"+"} style={radioStyle} value={"+"}>
        //               Split payment
        //             </Radio>
        //         </Radio.Group>
    
        //         <footer
        //           style={{
        //             display: "flex",
        //             justifyContent: "space-between",
        //             alignItems: "center",
        //             borderTop: "solid 1px #E6E6E6",
        //           }}
        //         >
        //           <Button
        //             type="link"
        //             onClick={() => {
        //               this.props.updateFilterValue(selectedKeys[0]);
        //               // confirm();
        //             }}
        //           >
        //             Ok
        //           </Button>
        //           <Button
        //             type="link"
        //             onClick={() => {
        //               this.props.updateFilterValue(null);
        //               clearFilters();
        //               confirm();
        //             }}
        //           >
        //             {strings.reset}
        //           </Button>
        //         </footer>
        //       </section>
        //     );
        //   },
        //   // sorter: (a, b) => a.name.length - b.name.length,
        //   filterMultiple: false,
        },
        {
          title: strings.returnedQuantity,
          dataIndex: "return_quantity",
          render: (record) => (record ? <span>{record}</span> : <span>N/A</span>),
        },
        {
            title: strings.returnedDate,
            dataIndex: "sales_return_date",
            render: (record, item) => (
                record ? <span>{moment(record).format("DD MMMM YYYY")}</span> : <span>N/A</span>
            ),
        },
    ];

    const handleGetReturnedItems = (pageNumber, pageSize) => {
        setLoading(true);
        getAllReturns(pageNumber, pageSize)
        .then(res => {
            if(res.status === 200){
                setPagination((prev) => {
                    return {
                        ...prev,
                        total: res.headers["total"],
                        pageNumber: pageNumber
                    }
                })
                setReturnedItems(res.data.sort((a, b) => b.sales_return_date.localeCompare(a.sales_return_date)));
                setLoading(false);
            }
        })
        .catch(err => {
            setLoading(false);
            console.log("Err", err);
            message.error("Unable to all returned items");
        })
    }
  return (

    <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={strings.viewReturnedItems}
            footer={null}
        >
            <CustomTable 
                columns={columns}
                data={returnedItems}
                pagination={pagination}
                current={pagination.pageNumber}
                loading={loading}
                total={pagination.total}
                dataSource={returnedItems}
                onChange={(page, rest) => {
                    const { pageSize, defaultPageSize, current } = page;
                    handleGetReturnedItems(current, pageSize)
                }}
            />
        </Modal>

        {ismanageReturnOpen && (
            <ManageReturnModal 
                open={ismanageReturnOpen}
                onCancel={() => setIsManageReturnOpen(false)}
                item={seletedItem}
                mangeReturnedItems={mangeReturnedItems}
                handleGetReturnedItems={handleGetReturnedItems}
            />
        )}
    </>
    
  )
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { getAllReturns, mangeReturnedItems })(ReturnedItems)
// export default ReturnedItems