import api from "../../api";
import Axios from "axios";

export const createPurchase = (payload) => async (dispatch) =>  {
    const res = await api.productSupplies.createProductOrder(payload);

    return Promise.resolve(res);
}

export const getAllPurchaseOrder = (pageNumber, pageSize, begin, end) => async (dispatch) =>  {
    const res = await api.productSupplies.getAllPurchaseOrder(pageNumber, pageSize, begin, end);

    return Promise.resolve(res);
}

export const getOnePurchaseOrder = (id) => async (dispatch) =>  {
    const res = await api.productSupplies.getOnePurchaseOrder(id);

    return Promise.resolve(res);
}

export const getSupplierProducts = (id) => async (dispatch) =>  {
    const res = await api.productSupplies.getSupplierProducts(id);

    return Promise.resolve(res);
}

export const receivePurchaseInventory = (id, payload) => async (dispatch) =>  {
    const res = await api.productSupplies.receiveInventory(id, payload);

    return Promise.resolve(res);
}

export const supplierRejectOrder = (uuid) => async (dispatch) =>  {
    const res = await api.productSupplies.supplierReject(uuid);

    return Promise.resolve(res);
}

export const supplieracceptOrder = (uuid) => async (dispatch) =>  {
    const res = await api.productSupplies.supplierAccept(uuid);

    return Promise.resolve(res);
}

export const updatePurchaseOrder = (id, payload) => async (dispatch) =>  {
    const res = await api.productSupplies.receiveInventory(id, payload);

    return Promise.resolve(res);
}

export const updateOrderStatus = (id, payload) => async (dispatch) =>  {
    const res = await api.productSupplies.updateOrderStatus(id, payload);

    return Promise.resolve(res);
}