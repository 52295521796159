import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Upload,
  message,
  Image,
  Input,
  InputNumber,
  Radio,
  Switch,
} from "antd";
import { Form } from "@ant-design/compatible";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  EDIT_LOYALTY,
  EDIT_LOYALTY_SUCCESS,
  LIST_LOYALTIES,
} from "../../constants";

import "../../index.scss";
import strings from "../../../../../../../../strings";

const EditLoyalty = ({ visible, closeModal, currLoyalty, form }) => {
  const [state, setstate] = useState({
    imageUrl: "",
    loading: false,
  });
  const [showExpiration, setShowExpiration] = useState(!!currLoyalty.expiration);
  const [ imageUrL, setImageUrl ] = useState(null);

  const { id: loyaltyId, name, currency_value } = currLoyalty;

  useEffect(()=>{
    if(currLoyalty.image_url){
      setImageUrl(currLoyalty.image_url);
    }
  }, []);

  useEffect(() => {
    if (currLoyalty) {
      setstate({ imageUrl: currLoyalty.image_url, loading: false });
    }
  }, [currLoyalty, currency_value, form]);

  const [editLoyalty, { loading: isEditLoyaltyLoading }] = useMutation(
    EDIT_LOYALTY,
    {
      onCompleted: () => {
        form.resetFields();

        closeModal();

        Modal.success({
          title: EDIT_LOYALTY_SUCCESS,
          okButtonProps: {
            id: "btn-component",
          },
        });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_LOYALTIES }],
    }
  );

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    name: "image",
    action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
        setstate({
          ...state,
          imageUrl: info.file.response.imageCloudData.url,
        });
        setImageUrl(info.file.response.imageCloudData.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${strings.fileUploadFailed}.`);
      }
    },
  };

  const onEdit = async values => {
    const data = {
      name: values.name,
      currency_value: Number(values.amount),
      points: Number(values.points),
      verification: values.verification,
      expiration: Number(values.expiration),
    };

    if (imageUrL) {
      data.image_url = imageUrL || currLoyalty.image_url;
    } else return message.error("No image provided");

    console.log(data, imageUrL);
    await editLoyalty({
      variables: {
        id: loyaltyId,
        data,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onEdit(values);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const { getFieldDecorator } = form;

  return (
    <Modal
      title={`Edit ${name}`}
      open={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="edit"
          type="primary"
          loading={isEditLoyaltyLoading}
          onClick={() => handleSubmit()}
        >
          {strings.edit}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="create_loyalty" id="loyaltyForm">
        <Form.Item name="name" label={strings.name} className="wrap">
          {getFieldDecorator("name", {
            initialValue: currLoyalty?.name,
            rules: [{ required: true }],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          name="upload"
          label={strings.loyaltyArtwork}
          className="wrap"
        >
          <Image src={imageUrL} alt="loyalty" />
          {getFieldDecorator("upload", {
            valuePropName: "fileList",
            getValueFromEvent: normFile,
          })(
            <Upload listType="picture" multiple={false} {...uploadProps}>
              <Button icon={<UploadOutlined />}>{strings.clickToUpload}</Button>
            </Upload>
          )}
        </Form.Item>

        <Typography.Text>
          <p>{strings.amountToPointsRatio}</p>
        </Typography.Text>

        <div className="curr_points_ratio">
          <Form.Item
            name="amount"
            label={strings.amount + " (N)"}
            className="wrap"
          >
            {getFieldDecorator("amount", {
              initialValue: currLoyalty?.currency_value,
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>

          <span className="equal_sign">===</span>

          <Form.Item name="points" label={strings.points} className="wrap">
            {getFieldDecorator("points", {
              initialValue: currLoyalty?.points,
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
        </div>
        <Typography.Text  style={{marginBottom:20, display: "inline-block"}}>
          {strings.recommendedAmountToPointRatio}
        </Typography.Text>

        <Form.Item
          name="verification"
          label="Verify With Token"
          className="wrap mt-2"
        >
          <Typography.Text>
            Verify with token before redeeming points{" "}
          </Typography.Text>
          {getFieldDecorator("verification", {
            initialValue: currLoyalty.verification,
          })(<Switch defaultChecked={currLoyalty.verification} />)}
        </Form.Item>

        <div>
          <Radio.Group
            onChange={val =>
              val.target.value === 1
                ? setShowExpiration(false)
                : setShowExpiration(true)
            }
            defaultValue={currLoyalty.expiration ? 2 : 1}
          >
            <Radio value={1}>Points do not expire</Radio>
            <Radio value={2}>Points expire</Radio>
          </Radio.Group>
        </div>

        {showExpiration && (
          <div className="mt-2">
            <Form.Item
              name="expiration"
              label="Set Points Expiration"
              className="wrap mt-2"
            >
              {getFieldDecorator("expiration", {
                initialValue: currLoyalty.expiration,
              })(<InputNumber min={1} style={{ width: "60px" }} />)}
              <Typography.Text> Days after point is credited</Typography.Text>
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default Form.create()(EditLoyalty);
