import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import wrapWithClickout from "react-clickout";
import "./index.scss";
import strings from "../../../../strings";

class Menu extends React.Component {
  handleClickout = () => {
    this.props.handleClickOut();
  };

  render() {
    const { signoutUser, user, client, handleShowChangePassword } = this.props;
    return (
      <ul className="menu">
        <li>
          {user.role_id || (user.role && user.role.id < 4) ? (
            <Link to="/d/transactions/today/report">
              {strings.backToDashboard}
            </Link>
          ) : (
            <h5
              style={{
                textAlign: "center",
                backgroundColor: "#D90068",
                height: "41px",
                lineHeight: "41px",
                borderRadius: 28,
                color: "#fff",
              }}
            >
              {user.username}
            </h5>
          )}
        </li>
        {user.role_id > 1 && (
          <li>
            <a onClick={handleShowChangePassword}>{strings.changePassword}</a>
          </li>
        )}

        {user.role_id || (user.role && user.role.id < 4) ? (
          <Link to="/d/transactions/draft-sales">{strings.viewDraftSales}</Link>
        ) : null}

        <li>
        </li>
          <Link to="/d/orders">{strings.orders}</Link>
        <li>
          <Button
            onClick={() => signoutUser(client)}
            type="default"
            style={{ marginLeft: "20px" }}
          >
            {strings.signOut}
          </Button>
        </li>
      </ul>
    );
  }
}

export default wrapWithClickout(Menu);
