import React from "react";
import CartListItem from "../CartListItem";
import PropTypes from "prop-types";

const CartList = ({
  data,
  increaseItemQuantity,
  removeItemFromCart,
  reduceItemFromCart,
  handleShowBulkModal,
  handleShowSetPrice,
  onClickOnItem,
  location,
  addBundleToCart,
  removeBundleFromcart,
  reduceBundleFromCart,
  checkoutData,
  returnItem,
  onClickReturn,
  returnLoading,
  returnOneItem,
  setSelectedItem,
  setOpenManangeReturnModal,
  getAllSales
}) => (
  <ul className="cart-list">
    {!(data && data?.length === 0)
      ? data?.map(item => (
          <CartListItem
            key={`${item.id}${JSON.stringify(item.variants)}`}
            item={item}
            increaseItemQuantity={increaseItemQuantity}
            removeItemFromCart={removeItemFromCart}
            reduceItemFromCart={reduceItemFromCart}
            handleShowBulkModal={handleShowBulkModal}
            handleShowSetPrice={handleShowSetPrice}
            onClickOnItem={onClickOnItem}
            location={location}
            addBundleToCart={addBundleToCart}
            reduceBundleFromCart={reduceBundleFromCart}
            removeBundleFromcart={removeBundleFromcart}
            checkoutData={checkoutData}
            returnItem={returnItem}
            onClickReturn={onClickReturn}
            returnLoading={returnLoading}
            returnOneItem={returnOneItem}
            setSelectedItem={setSelectedItem}
            setOpenManangeReturnModal={setOpenManangeReturnModal}
            getAllSales={getAllSales}
          />
        ))
      : null}
  </ul>
);

CartList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  increaseItemQuantity: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
  reduceItemFromCart: PropTypes.func.isRequired,
  handleShowBulkModal: PropTypes.func.isRequired,
  handleShowSetPrice: PropTypes.func.isRequired
};

export default CartList;
