import React from 'react';
import Lehjah from "lehjah-react";
import { connect } from 'react-redux';

const Leyjahs = ({
    user
}) => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Lehjah
                business_id={user.id}
                public_key={process.env.REACT_APP_LEHJAH_PUBLIC_KEY}
                token="tvFFKAa9buzWl91izZvXDQ"
                environment={process.env.REACT_APP_LEHJAH_ENVIRONMENT} // or 'production'
                mode="full"
                page=""
                width="100%"
                height="100%"
                variable1={user["access_token"] || user["access-token"]}
                // variable3={user.uid}
                variable2={user.client}
                variable3={user.email}
                variable5={user.id}
            />
        </div>
    );
}
const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps, null)(Leyjahs);