import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllMerchantLoyalty } from '../../../../redux/actions/saleActions';
import strings from '../../../../strings';
import { Card, Button, message, DatePicker, Input, Dropdown, Modal, Tag } from "antd";
import CustomDataTable from '../../../../components/CustomDataTable';
import moment from 'moment';
import { getAllCustomers } from '../../../../redux/actions/customerActions';
import {
  SearchOutlined
} from "@ant-design/icons";
import { getAllPurchaseOrder, updateOrderStatus } from '../../../../redux/actions/purchaseOrder';
import PurchaseDetails from './partials/PurchaseDetails';
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons';


const { RangePicker } = DatePicker;

const PurchaseHistory = ({ getAllPurchaseOrder, user, updateOrderStatus }) => {
    const [ allPurchaseHistory, setAllPurchaseOrderHistory ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ selectedHistory, setSelectedHistory ] = useState(null);
    const [ showDetails, setShowDetails ] = useState(false);
    const [ searchedItem, setSearchedItem ] = useState([]);
    const [ searchedValue, setSearchedValue ] = useState("");
    const [begin, setBegin] = useState(null);
    const [end, setEnd] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState(30);
    const [ pagination, setPagination ] = useState({ current: 1, defaultPageSize: 10, pageSize: 10 });
    const [ selectedAction, setSelectedAction ] = useState(null);
    const [ acceptLoading, setAcceptLoading ] = useState(false);


    useEffect(() => {
        handleGetPurchaseOrderHx(pageNumber, pageSize, begin, end);
    }, []);

    const handleGetPurchaseOrderHx = (pageNumber, pageSize, begin, end) => {
        setLoading(true);
        getAllPurchaseOrder(pageNumber, pageSize, begin, end)
        .then(res => {
            if(res.status === 200 ){
                setAllPurchaseOrderHistory(res.data);
                setLoading(false);
            }
        })
        .catch(err => {
            message.error(`${err?.response && err?.response?.data?.message || `Unable to get purchase order - ${err.message}`}`)
            setLoading(false);
        })
    }

    const handleMarkAsDelivered = (e, id) => {
      e.stopPropagation();
      setAcceptLoading(true)
      updateOrderStatus(id, { status: "supplier_accepted"})
      .then(res => {
        if(res.status === 200 ){
          Modal.success({
            title: "Status has been updated",
            onOk: () => {
              setAcceptLoading(false);
              handleGetPurchaseOrderHx(pageNumber, pageSize, begin, end)
            }
          })
        }
      })
      .catch(err => {
          message.error(`${err?.response && err?.response?.data?.message || `Unable to mark as delivered - ${err.message}`}`)
          setAcceptLoading(false);
      })
    }

    const dropdownItem = [
      {
        key: '1',
        label: (
          <div className='flex_display' onClick={handleMarkAsDelivered}>
            <Button type="link" onClick={handleMarkAsDelivered} loading={acceptLoading}>{strings.markAsDelivered}</Button>
            {/* {acceptLoading && <LoadingOutlined spin />}
            <p>{strings.markAsDelivered}</p> */}
          </div>
        ),
      },
      // {
      //   key: '2',
      //   label: (
      //     <div className='flex_display' onClick={handleReject}>
      //       {rejectLoading && <LoadingOutlined spin />}
      //       <p>{strings.rejectRequest}</p>
      //     </div>
      //   ),
      // }
    ]

    const column = [
        {
          title: strings.supplier,
          dataIndex: "user",
          render: record => {
            return record ? `${record.first_name} ${record.last_name ? record.last_name : ""}` : <p>N/A</p>
          },
          onFilter: (value, record) => {
             if (!record["customers"]) return;
            
             return record["customers"].first_name
             .toString()
             .toLowerCase()
             .includes(value.toLowerCase()) 
             || record["customers"].last_name
             .toString()
             .toLowerCase()
             .includes(value.toLowerCase());
          },
          filterDropdown: (...props) => {
            const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
              props[0];
            return (
              <div style={{ padding: 8 }}>
                <section className="filter-container">
                <Input 
                  onChange={(e) => {
                    setSelectedKeys([e.target.value])}
                  }
                  value={selectedKeys[0]}
                  // style={{ margin: "0 10px" }}
                  placeholder="Search customer's name"
                  onPressEnter={() =>
                    confirm()
                  }
                  style={{ marginBottom: 8, display: "block" }}
                />
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "solid 1px #E6E6E6",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, "user_id")}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                  >
                    Ok
                  </Button>
                  <Button
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => handleReset(clearFilters, confirm)}
                  >
                    {strings.reset}
                  </Button>
                </footer>
              </section>
              </div>
              
            );
          },
          filterMultiple: false,
        },
        {
            title: strings.businessName,
            dataIndex: "supplier",
            render: (record, details) => {
              return (record ? <span>{record.business_name}</span> : <span>N/A</span>)
            },
            // ...this.getColumnSearchProps("active"),
        },
        {
          title: strings.status,
          dataIndex: "status",
          render: (record, details) => {
            let color;
            switch (record) {
              case "pending":
                color = "red";
                break;
              case "supplier_accepted":
                color = "blue";
                break;
              case "supply_delivered":
                color = "green";
                break;
                case "merchant_received":
                color = "green";
                break;
              default:
                color = "red";
                break;
            }
            return (
              <Tag color={color} key={record}>
                {record === "supplier_accepted" ? "accepted" : record === "supply_delivered" ? "delivered" : record === "merchant_received" ? "received" : record}
              </Tag>
            );
            // return (
            //   <span>{record === "supplier_accepted" ? "accepted" : record}</span>
            // )
          },
          // ...this.getColumnSearchProps("active"),
        },
        // {
        //   title: strings.notes,
        //   dataIndex: "notes",
        //   render: record => (record ? <span>{record}</span> : <span>N/A</span>),
        // },
        {
          title: strings.createdDate,
          dataIndex: "created_at",
          render: record => (record ? <span>{moment(record).format("YYYY/MM/DD h:mm:ss A")}</span> : <span>N/A</span>),
          sorter: (a, b) => {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
          },
        },
        {
          title: strings.action,
          dataIndex: "created_at",
          render(value, record) {
            return (
              <Button type="link" disabled={record.status !== "supplier_accepted"} onClick={(e) => handleMarkAsDelivered(e, record.id)} loading={acceptLoading}>{strings.markAsDelivered}</Button>
            )
          }
        }
    ]


    const handleRowSelection = (record) => {
        setSelectedHistory(record);
        setShowDetails(true);
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setSearchedItem(selectedKeys[0]);
        confirm();
    };

    const updateFilterValue = (value) => {

    }

    const onChangeTablePage = (newPagination, filters) => {
        handleGetPurchaseOrderHx(newPagination.current, newPagination.pageSize, begin, end);
        setPageNumber(newPagination.current);
        setPageSize(newPagination.pageSize);
    };
  
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        setSearchedValue("");
        // setSearchedColumn("")
        setSearchedItem([]);
    };
  
    const handleDurationChange = function (duration, ...rest) {
        updateFilterValue("");
        
        const [begin, end] = duration;
        if (begin && end) {
            setLoading(true);
            
            getAllPurchaseOrder(
            pageNumber,
            pageSize,
            begin.format().split("T")[0],
            end.format().split("T")[0]
            )
            .then((response) => {
            if(response.status === 200){
                setAllPurchaseOrderHistory(response.data);
                setLoading(false);
            }
            })
            .catch(() => {
                message.error(strings.errorFetchingRecord);
            });
        } 
    };

  return (
    <Card
        title={strings.purchaseHistory}
        loading={loading}
        extra={ 
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
            <RangePicker
                placeholder={[strings.startDate, strings.endDate]}
                onChange={handleDurationChange}
                allowClear="false"
            />
            </div>
        </div>
    }
    >
        <CustomDataTable 
            columns={column}
            dataSource={searchedValue.length > 0 ? searchedItem : allPurchaseHistory}
            loading={loading}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        handleRowSelection(record);
                    },
                };
            }}
            onChange={onChangeTablePage}
            pagination={pagination}
        />

        {showDetails && (
            <PurchaseDetails 
                open={showDetails}
                onCancel={() => setShowDetails(false)}
                purchaseOrder={selectedHistory}
                user={user}
            />
        )}
    </Card>
  )
}



const mapStateToProps = (state) => ({
    user: state.auth.user || state.merchant.user,
    customers: state.customer.allCustomers,
  });
  
  export default connect(mapStateToProps, {
      getAllPurchaseOrder,
      updateOrderStatus
    //   getAllCustomers
  })(PurchaseHistory)
