import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Row, Col, Select, Input, Icon, Tabs } from "antd";
import { connect } from 'react-redux';
import WithApolloClient from "../../../../utils/withApolloClient";
import strings from "../../../../strings";


const Option = Select.Option;


const Filter = (props) => {

  const isNitroActive = props.state.appStore.isNitroActive;
  const myRef = useRef();
  const [ changeFilter, setChangeFilter ] = useState(false);
  const [ val, setVal ] = useState("all");
  const [ serviceVal, setServiceVal ] = useState("all")


  const handleChangeSelect = () => {
    if(window.matchMedia("(max-width: 768px)").matches){
      setChangeFilter(true);
    }else{
      setChangeFilter(false);
    }
  }

  useEffect(()=>{
    handleChangeSelect();

    console.log(window.matchMedia("(max-width: 768px)").matches);
    // return () => {}
  }, [window.matchMedia])

  const handleblur = () =>{
    if(!props.shouldBlurIn) return;
    myRef.current.focus();
  }

  useEffect(() => {
    if(props.shouldBlurIn) myRef.current.focus();
  }, [props.shouldBlurIn])



  const hardCodedCategories = ["Body", "Hair", "Face", "Nails", "Dental"];

  const tabProductItems = props.categories && [
    {key: "all", label: strings.all}, 
    ...props.categories.map((ite)=>{
       return {key: Number(ite.id), label: ite.name}
    })
  ];

  const tabServiceItems = props.serviceCategory && [
    {key: "all", label: strings.all},
    ...props.serviceCategory.map((ite)=>{
      return {key: ite.category_name, label: ite.category_name}
   })
  ];
  

  return (
    <div className="search-container">
      <Row gutter={[15, 5]}>
        <Col span={24} sm={24} md={isNitroActive ? 24 : 24} lg={isNitroActive ? 24 : 24}>
          {isNitroActive ? 
          <Input
            placeholder="Touch here to Scan item"
            style={{
              width: "100%",
              height: "67px"
            }}
            ref={myRef}
            value={props.searchTerm}
            // onKeyUp={isNitroActive === false && props.onSearchEnter}
            size="large"
            onChange={props.onSearch}
            onKeyDown={props.onNitroSearch}
            // onBlur={handleblur}
            autoFocus
            suffix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
          /> : 
          <Input
            placeholder={
              props.checkoutData === "products"
                ? strings.searchForProduct : props.checkoutData === "bundle" ? "Search for Bundle Products"
                : "Search for Services"
            }
            
            value={props.searchTerm}
            onKeyUp={props.onSearchEnter}
            size="large"
            onChange={props.checkoutData === "bundle" ? props.handleBundleOnSearchInput: props.checkoutData === "services" ? props.handleServiceOnSearchInput : props.onSearch}
            autoFocus
            ref={myRef}
            onBlur={handleblur}
            suffix={<Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />}
          />}
        </Col>
        {!isNitroActive && props.checkoutData === "products" && props.draftModal === undefined && <Col span={24} sm={24} md={24} lg={24}>
          {<Select
            onChange={props.onChangeCategory}
            size="large"
            style={{ width: "100%" }}
            defaultValue="all"
            className="tabSmallScreen"
          >
            <Option value="all">{strings.all}</Option>
            {props.categories &&
              props.categories.map((category, index) => (
                <Option key={index} value={category.id}>
                  {category.name}
                </Option>
              ))}
          </Select>}
          {props.categories && <Tabs
            items={tabProductItems}
            activeKey={val}
            onChange={(value)=>{
              props.onChangeCategory(value) 
              setVal(Number(value))
            }}
            className="tabBigScreen"
          >

          </Tabs>}
        </Col>}
        {!isNitroActive && props.checkoutData === "services" && <Col span={24} sm={24} md={24} lg={24}>
          <Select
            onChange={props.onChangeServiceCategory}
            size="large"
            style={{ width: "100%" }}
            defaultValue="all"
            className="tabSmallScreen"
          >
            <Option value="all">{strings.all}</Option>
            {hardCodedCategories.map((cat, index)=>{
              return <Option key={index} value={cat}>{cat}</Option>
            })}
            {props.serviceCategory &&
              props.serviceCategory.map((category, index) => (
                <Option key={index} value={category.category_name}>
                  {category.category_name}
                </Option>
              ))}
          </Select>
          {props.serviceCategory && <Tabs
            items={tabServiceItems}
            onChange={(value)=>{
              props.onChangeServiceCategory(value);
              setServiceVal(value)
            }}
            className="tabBigScreen"
            activeKey={serviceVal}
          >

          </Tabs>}
        </Col>}
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  isNitroActive: state.appStore.isNitroActive
});

export default connect(mapStateToProps)(WithApolloClient(Filter));
