import React, { useRef } from "react";
import {
  Button,
  Layout,
  Row,
  Modal,
  notification,
  message,
  Col,
  Radio,
  Menu as IconMenu,
  Tabs
} from "antd";
import classnames from "classnames";
import PropTypes from "prop-types";
import { MenuOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

// Redux
import { connect } from "react-redux";
import { signoutUser } from "../../redux/actions/authActions";
import { getAllCategories } from "../../redux/actions/categoryActions";
import {
  getAllCustomers,
  addLoyaltyCustomers,
  setNewCustomer,
} from "../../redux/actions/customerActions";
import { getAllLoyaltyPrograms } from "../../redux/actions/loyaltyActions";
import {
  getAllProducts,
  updateProductItemInStore,
  getAllBranchProducts
} from "../../redux/actions/productActions";
import {
  clearShowVariantModalData,
  recordSale,
  getAllSales,
  showVariantModal,
  showCustomQtyModal,
  activeSplitPayment
} from "../../redux/actions/saleActions";
import { getMerchantSubPlan } from "../../redux/actions/merchantAction";
import { getMerchantsSMSBalance, addMerchantCurrentPlan } from "../../redux/actions/merchantAction";
import { getAllBusinessBranches } from "../../redux/actions/branchActions";
import {
  sendReceipt,
  getDownloadableReceiptLink,
} from "../../redux/actions/invoiceActions";
import { toggleRenewalModal, checkSubscriptionStatus } from "../../redux/actions/renewalActions";
import {
  QrcodeOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import NewWindow from "react-new-window";

// Component imported functions
import {
  addProductToCart,
  getCartTotalAmount,
  handleCategoryChange,
  handleServiceCategoryChange,
  handleCustomerSearchFieldChange,
  handlePayment,
  handlePayWithCashValue,
  hanldeEnterOnSearchInput,
  hanldeProductSearch,
  reduceItemFromCart,
  removeItemFromCart,
  computeLoyaltyPoints,
  computeLoyaltyStamps,
  handleSaveInvoiceDone,
  gqlGetActiveLoyalty,
  handleAddServiceToCart,
  handleAddBundleProductToCart,
  handleReduceBundleProductToCart,
  handleRemoveBundleProductToCart,
  handleBundleOnSearchInput,
  handleServiceOnSearchInput,
  reduceServiceFromCart,
  removeServiceFromCart,
  addBulkQuantityNew,
  handleNitroSearch,
  handleNitroVariantSelect,
  handleNitroCustomSelect
} from "./functions";
import api from "../../api";
// UI
import "./index.scss";
import CartFooter from "./_partials/CartFooter";
import CartList from "./_partials/CartList";
import Filter from "./_partials/Filter";
import MobileCartButton from "./_partials/MobileCartButton";
import ProductList from "./_partials/ProductList";
import RecordSalePaymentModal from "./_partials/RecordSalePaymentModal";
import AddCustomerModal from "../Dashboard/Layouts/Customers/_partials/AddCustomerModal";
import BulkQuantityModal from "../Dashboard/Layouts/Products/_partials/BulkQuantityModal";
import SetServicePriceModal from "../Dashboard/Layouts/Products/_partials/SetServicePrice";
import Menu from "./_partials/Menu";
import { setNetworkStatus } from "../../redux/actions/networkStatusActions";
import { fetchActiveApps } from "../../redux/actions/appActions";
import {
  changeMultiLevelStatus,
  changeBookingStatus,
} from "../../redux/actions/multiLevelActions";
import OnlineIndicator from "../../components/OnlineIndicator";
import SendOptionModal from "../../components/SendOptionModal";
import SubscriptionNotification from "../../components/SubscriptionNotification";
import RenewalModal from "./_partials/RenewalModal";
import RedeemReward from "../Dashboard/Layouts/Customers/_partials/RedeemReward";
import ReceiptToEmailModal from "./_partials/ReceiptToEmailModal";
import ShippingAddressModal from "./_partials/ShippingAddressModal";
import VerifyTokenModal from "./_partials/VerifyTokenModal";
import ChangeStaffPasswordModal from "../Dashboard/Layouts/Account/_partials/ChangeStaffPasswordModal";
import ShortSMSModal from "../Dashboard/Layouts/Customers/_partials/ShortSMSModal";
import WithApolloClient from "../../utils/withApolloClient";
import CustomPriceModal from "./_partials/CustomPriceModal";
import ServicesList from "./_partials/ServicesList";
import {
  GET_CUSTOMER,
  GET_SERVICES,
  LIST_LOYALTY_CUSTOMERS,
  GET_BOOKINGS,
  UPDATE_BOOKING_PAYMENT,
  GET_WORK_HOURS,
  UPDATE_MERCHANT_UREWARD_ID,
  GET_CATEGORIES
} from "../Dashboard/Layouts/Apps/MultiLevelLoyalty/Loyalties/constants";
import CalendarView from "./_partials/Calendar";
import BookingDetails from "./_partials/Calendar/BookingDetails";
import RequestDeliveryModal from "./_partials/RequestDeliveryModal";
import strings from "../../strings";
import ScanQrCode from "./_partials/ScanQrCode";
import LoyaltyMode from "./_partials/LoyaltyMode";
import { payOrder } from "../../redux/actions/orderActions";
import AuthModal from "./_partials/auth/authModal";
import VariantsModal from "./_partials/VariantsModal";
import CustomVariant from "./_partials/VariantsModal/customVariant";
import CustomQuantity from "./_partials/CustomQtyModal";
import CustomQuantityNitroForm from "./_partials/CustomQtyModal/nitroCustom"
import BundleList from "./_partials/BundleList";
import { bundleImg } from "../../icons";
import { exportComponentAsJPEG } from "react-component-export-image";
const { Header } = Layout;


const milisecondsToDaysConst = 86400000;

const THANK_YOU_SMS =
  "Thank you for your Patronage. We look forward to doing business with you again.";

class Sales extends React.Component {
  state = {
    cartList: [],
    checkoutData: "products",
    allProducts: [],
    allBookings: [],
    bookingCheckoutLoading: false,
    bookingPaymentSuccessful: false,
    customerData: this.props.customers,
    products: [],
    pagination: null,
    categoryToShow: "all",
    searchTerm: "",
    recordingCashSale: false,
    recordingGiftCardSale: false,
    recordingCardSale: false,
    recordingManualTransfer: false,
    customerValue: [],
    isAddCustomer: false,
    isCartOpenOnMobile: false,
    isTransactionModalVisible: false,
    isRedeemRewardModalOpen: false,
    showAddCustomerModal: false,
    payWithCashChange: 0,
    cartTotalAmount: 0,
    customerCash: 0,
    loading: false,
    loadingMore: false,
    showBulkModal: false,
    showSetQuantityModal: false,
    currentBulkItem: null,
    currentServiceItem: null,
    showCashForm: false,
    saleLoyaltyPoint: null,
    addLoyaltyProgramToSale: false,
    isCustomPriceModalopen: false,
    productWithoutPrice: null,
    showChangePassword: false,
    showMenu: false,
    invoicePrompt: false,
    openOptionModal: false,
    denyAccess: false,
    isReceiptModalVisible: false,
    isShippingAddressVisible: false,
    isSendThankSMSModalVisible: false,
    sms_balance: null,
    isPayingWithPoints: false,
    isTwoPaymentMethod: false,
    visible: false,
    twoPointModalVisible: false,
    verification: false,
    requestDeliveryModalOpen: false,
    isDisableNewFreePlanAccounts: false,
    openCheckInWindow: false,
    checkInCustomer: null,
    checkInNewCustomer: null,
    isAuthModal: false,
    isPermitted: false,
    chooseVariant: false,
    newVariantProduct: null,
    newCustomProduct: null,
    chooseCustomQty: false,
    isNitroSearchEmpty: false,
    chooseCustomQty: false,
    allBundles: [],
    bundleSearchLoading: false,
    isGettingFromServer: false,
    serviceCategory: [],
    shouldBlurIn: true
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isSubscribedToMultiLevel !== this.props.isSubscribedToMultiLevel
    ) {
      if (prevProps.newCustomer !== this.props.newCustomer) {
        this.handleCustomerSearchFieldChange(this.props.newCustomer.user_id);
      }
      if (this.props.isSubscribedToMultiLevel) {
        // Get Customer Point Details associated with merchant active loyalty program
        const activeLoyalty = await this.gqlGetActiveLoyalty();

        this.setState({ activeLoyalty });
      }
    }
    // window.addEventListener("beforeunload", this.setState({
    //   cartList: []
    // }))
  }

  async componentDidMount() {
    const {
      getAllProducts,
      getAllBranchProducts,
      getAllCustomers,
      getAllBusinessBranches,
      getAllCategories,
      getAllLoyaltyPrograms,
      setNetworkStatus,
      isSubscribedToMultiLevel,
      fetchActiveApps,
      changeMultiLevelStatus,
      changeBookingStatus,
      location,
      products,
      user,
      isNitroActive,
      isProductBundleActive,
      subscriptionStatus,
      addMerchantCurrentPlan,
      signoutUser,
      client,
      checkSubscriptionStatus
    } = this.props;

    // this.props.toggleRenewalModal();
    const isAdmin = user.role_id === 1;
    const isAdminAndCashier = user.role ? user.role.id === 3 : user.role_id === 1;
    const isAdminAndManagerAndCashier = user.role ? user.role.id === 3 || user.role.id === 2 : user.role_id === 1;
    if (this.props.newCustomer)
      this.handleCustomerSearchFieldChange(this.props.newCustomer.user_id);

    await fetchActiveApps();

    if (user.employer) {
      if(isNitroActive === false){
        getAllBranchProducts(user.employer.id, user.business_branch.id);
      }
    } else {
      if(isNitroActive === false){
        getAllProducts();
      }
    }

    const getProductBundle = () => {
      api.HttpClient.get(`/merchant/${user.id}/bundles`)
      .then((res)=>{
          this.setState({ allBundles: res.data });
          localStorage.setItem("product_bundle", JSON.stringify(res.data))
      })
      .catch((err)=>{  
          Modal.error({
              title: `${err.message}`
          })
      })
    }

    checkSubscriptionStatus(user.employer ? user.employer.id : user.id);

    isProductBundleActive && getProductBundle()

    const getMerchantDetails = () => {
      api.HttpClient.get(
        `/merchants/find_merchant_by_id?id=${user?.employer ? user.employer.id : user.id}`
      ).then((res) => {
        if (res.status === 200) {
          const isDisableNewFreePlanAccounts =
            getMerchantDetails.subscription_plan === "Free" &&
            moment(res.data.created_at) > moment("22-07-2022", "DD-MM-YYYY");

          this.setState({ isDisableNewFreePlanAccounts });
        }
      });
    };
    getMerchantDetails();

    const {
      data: { updateMerchantUrewardId },
    } = await this.props.client.query({
      query: UPDATE_MERCHANT_UREWARD_ID,
      onError: (error) => {
        message.error(error.message, 5);
      },
    });

    const {
      data: { getCategories },
    } = await this.props.client.query({
      query: GET_CATEGORIES,
      onError: (error) => {
        message.error(error.message, 5);
      },
    });

    this.setState({ serviceCategory: getCategories })

    const getAllBookingsData = async () => {
      try {
        const {
          data: { getServices: allServices },
        } = await this.props.client.query({
          query: GET_SERVICES,
          variables: {},
          onError: (error) => {
            message.error(error.message, 5);
          },
        });
        this.setState({ allServices });
        localStorage.setItem("service_product", JSON.stringify(allServices))
        const {
          data: { getWorkHours: workHours },
        } = await this.props.client.query({
          query: GET_WORK_HOURS,
          onError: (error) => {
            message.error(error.message, 5);
          },
        });
        this.setState({ workHours });

        const {
          data: { getBookings: allBookings },
        } = await this.props.client.query({
          query: GET_BOOKINGS,
          onError: (error) => {
            message.error(error.message, 5);
          },
        });
        this.setState({ allBookings });

        if (location.search && !location.search.match("topup")) {
          const bookingId = location.search.split("/")[1];
          const booking =
            allBookings && allBookings.find((bkng) => bkng.id === bookingId);
          if (booking) {
            return this.setState({
              currentBooking: booking,
              checkoutData: "booking-details",
            });
          }
          window.location.replace("/sales");
        }
      } catch (error) {
        console.log(error);
      }
    };

    getAllBookingsData();
    
    isAdminAndManagerAndCashier &&
      fetchActiveApps().then((res) => {
        const subscribedApps = res.data;

        const isSubscribedToMultiLevel = subscribedApps.find(
          (app) => app.app.app_name === "Multi-Level Loyalty"
        )
          ? true
          : false;

        const isSubscribedToBookings = subscribedApps.find(
          (app) => app.app.app_name === "Services, Bookings & Appointments"
        )
          ? true
          : false;

        changeMultiLevelStatus(isSubscribedToMultiLevel);
        changeBookingStatus(isSubscribedToBookings);
      });

    // Check If State in Location is from DraftSales Page, If Present, populate cart with transacton data in location state
    if (location.state && location.state.fromDraftSales) {
      this.setState({
        cartList: location.state.transaction,
        customerName: location.state.selectedCustomer,
      });
      this.handleCustomerSearchFieldChange(location.state.customerValue);
    }

    if (location.state && location.state.fromInstoreOrder) {
      // console.log("here", location.state, location.state.transaction, location.state.selectedCustomer);
      const cartItem = location.state.transaction;
      cartItem.map((item)=>{
        if(item.products && item.products.length > 0){
          this.handleAddBundleProductToCart(item)
        }else{
          this.addProductToCart(item)
        }
      })
      this.setState({
        // cartList: location.state.transaction,
        customerName: location.state.selectedCustomer,
        // customerName: `${location.state.selectedCustomer.first_name}: ${location.state.selectedCustomer.phone_number}`,
      });
      this.handleCustomerSearchFieldChange(location.state.customerValue);
    }

    // if(isNitroActive === false){
    //   if (products.length < 1) {
      
    //     this.setState({ loading: true });
    //     if (user.employer) {
    //       getAllBranchProducts(user.employer.id, user.business_branch.id).then((res) => {
    //         const { data } = res || {};
  
    //         const filterProduccts =
    //           data && data.filter((product) => product.deleted === false);

    //         let sortedProducts = filterProduccts.sort((a, b) => a.name.localeCompare(b.name))
  
    //         this.setState(
    //           {
    //             loading: false,
    //             products: sortedProducts,
    //           },
    //           () => {
    //             localStorage.setItem("products", JSON.stringify(sortedProducts));
    //           }
    //         );
    //       });
    //     } else {
    //       getAllProducts().then((res) => {
    //         const { data } = res || {};
  
    //         const filterProduccts =
    //           data && data.filter((product) => product.deleted === false);

    //           let sortedProducts = filterProduccts.sort((a, b) => a.name.localeCompare(b.name))
  
    //         this.setState(
    //           {
    //             loading: false,
    //             products: sortedProducts,
    //           },
    //           () => {
    //             localStorage.setItem("products", JSON.stringify(sortedProducts));
    //           }
    //         );
    //       });
    //     }
    //   } else {
    //     this.setState({ products }, () => {
    //       localStorage.setItem("products", JSON.stringify(products));
    //     });
    //   }
    // }

    if (location.search && location.search.match("topup")) {
      const topUpData = location.search.split("&");
      const customerId = topUpData[1].split("=")[1];
      const cardId = topUpData[2].split("=")[1];
      const topupAmount = topUpData[3].split("=")[1];
      const productItem = products.find(
        (prod) => prod.name === "Topup Gift Card"
      );
      if (!productItem) return message.error("Topup product not found");
      productItem.price = String(Number(topupAmount) + 100);
      productItem.cost_price = String(Number(topupAmount) + 100);
      productItem.original_price = String(Number(topupAmount) + 100);
      this.addProductToCart(productItem);
      this.handleCustomerSearchFieldChange(Number(customerId));
      this.setState({ topupGiftCard: true, cardId: cardId });

      window.history.replaceState({}, "", "/sales");
    }

    if (isSubscribedToMultiLevel) {
      // Get Customer Point Details associated with merchant active loyalty program
      const activeLoyalty = await this.gqlGetActiveLoyalty();
      this.setState({ activeLoyalty });
    }

    getAllCustomers(1, 1000000);
    getAllCategories();
    getAllBusinessBranches();

    // if (user.role_id === 1) {
    this.getMerchantsSMSBalance();
    // }

    const loyaltyPrograms = await getAllLoyaltyPrograms();

    (async () => {
      const res = await api.HttpClient.get("/customer_groups");
      if (res.status && res.status === 200) {
        localStorage.setItem("customer_groups", JSON.stringify(res.data));
        return res.data;
      }
    })();

    // store loyaltyPoints to local storage
    localStorage.setItem(
      "loyaltyPrograms",
      JSON.stringify(loyaltyPrograms && loyaltyPrograms.data)
    );

    const activeInstantRewardLoyalty =
      loyaltyPrograms &&
      loyaltyPrograms.data.find(
        (program) =>
          program.program_type === "InstantRewards" &&
          program.active === true &&
          program.deleted === false
      );
    if (activeInstantRewardLoyalty)
      localStorage.setItem(
        "activeInstantRewardLoyalty",
        JSON.stringify(activeInstantRewardLoyalty)
      );

    window.addEventListener("online", () => {
      setNetworkStatus(navigator.onLine);
    });
    window.addEventListener("offline", () => {
      setNetworkStatus(navigator.onLine);
    });

    // If Merchant Account's Expired, Show Renewal Modal
    // const typesObj = {
    //   ngBasic: "basic-pay",
    //   ngPro: "pro-pay",
    //   ghBasic: "basic-pay-gh",
    //   ghPro: "pro-pay-gh",
    //   intBasic: "basic-pay-int",
    //   intPro: "pro-pay-int",
    // };

    // const business = this.props.user.employer
    //   ? { ...this.props.user.employer }
    //   : { ...this.props.user };

    // if (business.subscription_plan === "Free") {
    //   if (products.length >= 20) {
    //     // const country =
    //     //   business.currency === "NGN"
    //     //     ? "ng"
    //     //     : business.currency === "GHS"
    //     //     ? "gh"
    //     //     : "int";

    //     // let subscription_plan = "Basic";

    //     // const subPaymentType = typesObj[country + subscription_plan];

    //     const daysLeft = Math.ceil(
    //       (new Date(business.subscription_expires_on) - Date.now()) /
    //         milisecondsToDaysConst
    //     );

    //     daysLeft <= 0 &&
    //       Modal.confirm({
    //         title: strings.yourSubscriptionHasExpired,
    //         okText: strings.renew,
    //         onOk: () => this.props.toggleRenewalModal(),
    //       });
    //   }
    // } else {
    //   const country =
    //     business.currency === "NGN"
    //       ? "ng"
    //       : business.currency === "GHS"
    //       ? "gh"
    //       : "int";

    //   const subPaymentType = typesObj[country + business.subscription_plan];

    //   this.setState({
    //     subPaymentType: subPaymentType,
    //   });

    //   let sub_expiry_date = new Date(business.subscription_expires_on);

    //   let date_expired = sub_expiry_date.getDate();

    //   // Give Merchnat 7 days grace for renewal
    //   let sub_expiry_date_after_grace = sub_expiry_date.setDate(
    //     date_expired + 2
    //   );

    //   const daysLeft = Math.ceil(
    //     (new Date(business.subscription_expires_on) - Date.now()) / milisecondsToDaysConst
    //   );

    //   const daysLeftAfterGrace = Math.ceil(
    //     (sub_expiry_date_after_grace - Date.now()) / milisecondsToDaysConst
    //   );

    //   daysLeftAfterGrace <= 0 && subscriptionStatus !== "active" && this.setState({ denyAccess: true });

    //   // console.log("left", daysLeft);
    //   // if(subscriptionStatus === "active" && daysLeft <= 0){
    //   //   let noOfBranches = 0;
    //   //   let noOfStaffs = 0;

    //   //   let modal = Modal.info({
    //   //     title: `Subscription has expired, renewing...`,
    //   //   })

    //   //   if (this.props.allBranches.length) {
    //   //     const { allBranches } = this.props;

    //   //     const filteredBranches = allBranches.filter(
    //   //       (branch) => branch.name !== "Ecommerce"
    //   //     );

    //   //     const staffs = filteredBranches.flatMap((branch) => {
    //   //       return branch.staff;
    //   //     });

    //   //     noOfStaffs = staffs.length;
    //   //     noOfBranches = filteredBranches.length;
    //   //   }

    //   //   const duratio = JSON.parse(localStorage.getItem("duration"));
    //   //   const timeKey = JSON.parse(localStorage.getItem("timeKey"));
    //   //   const planAlone = JSON.parse(localStorage.getItem("plan_alone"));

    //   //   const currentDate = new Date(Date.now());
    
    //   //   const numberOfMonthsFromNow = Number(currentDate.getMonth()) + parseFloat(duratio);
    
    //   //   // const expiresOn = new Date(
    //   //   //   currentDate.setMonth(numberOfMonthsFromNow)
    //   //   // );
    //   //   const expiresOn = timeKey === "1D" ? new Date(currentDate.getDay() + 1) : new Date(currentDate.setMonth(numberOfMonthsFromNow));
    //   //   const NOC = expiresOn.toLocaleDateString()

    //   //   const noOfDays = timeKey === "1D" ? 1 : (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst;
    
    //   //   // const noOfDays = (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst; 
    
    //   //   const trans_ref = "stripe_subscription_" + Math.round(Math.random() * 100000000);

    //   //   setTimeout(() => {
    //   //     addMerchantCurrentPlan(business.email, {
    //   //       plan: planAlone,
    //   //       currency_code: business.currency,
    //   //       no_of_days: noOfDays,
    //   //       expires_on: NOC,
    //   //       transaction_reference: `${trans_ref}_no_of_staff:${noOfStaffs}_no_of_branch:${noOfBranches}_payee_email_address:${business?.email}`,
    //   //     }).then((res) => {
    //   //       Modal.success({
    //   //         title: `Plan Renewal successful!.`,
    //   //         content: "You will be logged out. Please log back in continue enjoying the service",
    //   //         onOk: () => {
    //   //           signoutUser(client);
    //   //         } 
    //   //       });
    //   //       modal.destroy();
    //   //     });
    //   //   }, 2000);

    //   // }else{
    //   //   daysLeft <= 0 &&
    //   //   Modal.confirm({
    //   //     title: strings.yourSubscriptionHasExpired,
    //   //     okText: strings.renew,
    //   //     onOk: () => this.props.toggleRenewalModal(),
    //   //   });
    //   // }
    // }

    const getLoyaltyCustomers = async () => {
      const activeLoyalty = await this.gqlGetActiveLoyalty();
      if (activeLoyalty) {
        const data = await this.props.client.query({
          query: LIST_LOYALTY_CUSTOMERS,
          variables: { loyalty_id: activeLoyalty.id },
          onError: (error) => {
            message.error(error.message, 5);
          },
        });
        data.data &&
          data.data.listLoyaltyCustomers &&
          this.props.addLoyaltyCustomers(data.data.listLoyaltyCustomers);
      } else this.props.addLoyaltyCustomers([]);
    };
    getLoyaltyCustomers();
    this.getBusinessBranchSub();
    this.handleSubscriptionProcess();
  }

  getBusinessBranchSub = () => {
    this.props.getMerchantSubPlan()
    .then((res)=>{
      const { duration, expires_on, transaction_reference, plan_name } = res.data;
      const splitReference = res.data.transaction_reference.split("_");
      const branchr = splitReference[6];
      const stf = splitReference[3];
      this.setState({
        staffPaidFor: stf.split(":")[1],
        branchPaidFor: branchr.split(":")[1]
      });

      this.handleSubscribe(expires_on, duration, plan_name, transaction_reference);
      
    })
    .catch((err)=>{
      console.log(err);
    })
  }

  handleSubscribe = (expires_on, duration, plan_name) => {
    const {
      subscriptionStatus,
      addMerchantCurrentPlan,
      signoutUser,
      client,
    } = this.props; 

    const business = this.props.user.employer
      ? { ...this.props.user.employer }
      : { ...this.props.user };

    const daysLeft = Math.ceil(
      (new Date(business.subscription_expires_on) - Date.now()) / milisecondsToDaysConst
    );

    if(subscriptionStatus === "active" && daysLeft <= 0){
      console.log("is subscribing......");
      let noOfBranches = 0;
      let noOfStaffs = 0;

      let modal = Modal.info({
        title: `Subscription has expired, renewing...`,
      })

      if (this.props.allBranches.length) {
        const { allBranches } = this.props;

        const filteredBranches = allBranches.filter(
          (branch) => branch.name !== "Ecommerce"
        );

        const staffs = filteredBranches.flatMap((branch) => {
          return branch.staff;
        });

        noOfStaffs = staffs.length;
        noOfBranches = filteredBranches.length;
      }

      const timeKey = JSON.parse(localStorage.getItem("timeKey"));
      // const planAlone = JSON.parse(localStorage.getItem("plan_alone"));

      const currentDate = new Date(Date.now());
  
      const numberOfMonthsFromNow = Number(currentDate.getMonth()) + parseFloat(duration);
  
      // const expiresOn = new Date(
      //   currentDate.setMonth(numberOfMonthsFromNow)
      // );
      const expiresOn = timeKey === "1D" ? new Date(currentDate.getDay() + 1) : new Date(currentDate.setMonth(numberOfMonthsFromNow));
      const NOC = expiresOn.toLocaleDateString()

      const noOfDays = timeKey === "1D" ? 1 : (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst;
  
      // const noOfDays = (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst; 
  
      const trans_ref = "stripe_subscription_" + Math.round(Math.random() * 100000000);

      setTimeout(() => {
        addMerchantCurrentPlan(business.email, {
          plan: plan_name,
          currency_code: business.currency,
          no_of_days: noOfDays,
          expires_on: NOC,
          transaction_reference: `${trans_ref}_no_of_staff:${noOfStaffs}_no_of_branch:${noOfBranches}_payee_email_address:${business?.email}`,
        }).then((res) => {
          Modal.success({
            title: `Plan Renewal successful!.`,
            content: "You will be logged out. Please log back in continue enjoying the service",
            onOk: () => {
              signoutUser(client);
            } 
          });
          modal.destroy();
        });
      }, 2000);
    }
  }

  handleSubscriptionProcess = () => {
    const {
      products,
      subscriptionStatus
    } = this.props;

    const typesObj = {
      ngBasic: "basic-pay",
      ngPro: "pro-pay",
      ghBasic: "basic-pay-gh",
      ghPro: "pro-pay-gh",
      intBasic: "basic-pay-int",
      intPro: "pro-pay-int",
    };

    const business = this.props.user.employer
      ? { ...this.props.user.employer }
      : { ...this.props.user };

    if (business.subscription_plan === "Free") {
      if (products.length >= 20) {
        // const country =
        //   business.currency === "NGN"
        //     ? "ng"
        //     : business.currency === "GHS"
        //     ? "gh"
        //     : "int";

        // let subscription_plan = "Basic";

        // const subPaymentType = typesObj[country + subscription_plan];

        const daysLeft = Math.ceil(
          (new Date(business.subscription_expires_on) - Date.now()) /
            milisecondsToDaysConst
        );

        daysLeft <= 0 &&
          Modal.confirm({
            title: strings.yourSubscriptionHasExpired,
            okText: strings.renew,
            onOk: () => this.props.toggleRenewalModal(),
          });
      }
    } else {
      const country =
        business.currency === "NGN"
          ? "ng"
          : business.currency === "GHS"
          ? "gh"
          : "int";

      const subPaymentType = typesObj[country + business.subscription_plan];

      this.setState({
        subPaymentType: subPaymentType,
      });

      let sub_expiry_date = new Date(business.subscription_expires_on);

      let date_expired = sub_expiry_date.getDate();

      // Give Merchnat 7 days grace for renewal
      let sub_expiry_date_after_grace = sub_expiry_date.setDate(
        date_expired + 2
      );

      const daysLeft = Math.ceil(
        (new Date(business.subscription_expires_on) - Date.now()) / milisecondsToDaysConst
      );

      const daysLeftAfterGrace = Math.ceil(
        (sub_expiry_date_after_grace - Date.now()) / milisecondsToDaysConst
      );

      daysLeftAfterGrace <= 0 && subscriptionStatus !== "active" && this.setState({ denyAccess: true });

      // console.log("left", daysLeft);
      // if(subscriptionStatus === "active" && daysLeft <= 0){
      //   let noOfBranches = 0;
      //   let noOfStaffs = 0;

      //   let modal = Modal.info({
      //     title: `Subscription has expired, renewing...`,
      //   })

      //   if (this.props.allBranches.length) {
      //     const { allBranches } = this.props;

      //     const filteredBranches = allBranches.filter(
      //       (branch) => branch.name !== "Ecommerce"
      //     );

      //     const staffs = filteredBranches.flatMap((branch) => {
      //       return branch.staff;
      //     });

      //     noOfStaffs = staffs.length;
      //     noOfBranches = filteredBranches.length;
      //   }

      //   const duratio = JSON.parse(localStorage.getItem("duration"));
      //   const timeKey = JSON.parse(localStorage.getItem("timeKey"));
      //   const planAlone = JSON.parse(localStorage.getItem("plan_alone"));

      //   const currentDate = new Date(Date.now());
    
      //   const numberOfMonthsFromNow = Number(currentDate.getMonth()) + parseFloat(duratio);
    
      //   // const expiresOn = new Date(
      //   //   currentDate.setMonth(numberOfMonthsFromNow)
      //   // );
      //   const expiresOn = timeKey === "1D" ? new Date(currentDate.getDay() + 1) : new Date(currentDate.setMonth(numberOfMonthsFromNow));
      //   const NOC = expiresOn.toLocaleDateString()

      //   const noOfDays = timeKey === "1D" ? 1 : (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst;
    
      //   // const noOfDays = (expiresOn.getTime() - new Date().getTime()) / milisecondsToDaysConst; 
    
      //   const trans_ref = "stripe_subscription_" + Math.round(Math.random() * 100000000);

      //   setTimeout(() => {
      //     addMerchantCurrentPlan(business.email, {
      //       plan: planAlone,
      //       currency_code: business.currency,
      //       no_of_days: noOfDays,
      //       expires_on: NOC,
      //       transaction_reference: `${trans_ref}_no_of_staff:${noOfStaffs}_no_of_branch:${noOfBranches}_payee_email_address:${business?.email}`,
      //     }).then((res) => {
      //       Modal.success({
      //         title: `Plan Renewal successful!.`,
      //         content: "You will be logged out. Please log back in continue enjoying the service",
      //         onOk: () => {
      //           signoutUser(client);
      //         } 
      //       });
      //       modal.destroy();
      //     });
      //   }, 2000);

      // }else{
      //   daysLeft <= 0 &&
      //   Modal.confirm({
      //     title: strings.yourSubscriptionHasExpired,
      //     okText: strings.renew,
      //     onOk: () => this.props.toggleRenewalModal(),
      //   });
      // }
    }
  }

  handleBlurFactor = (value) => {
    this.setState({ shouldBlurIn: value })
  }

  getMerchantsSMSBalance = async () => {
    try {
      const res = await this.props.getMerchantsSMSBalance();

      if (res.status === 200) {
        this.setState({ sms_balance: res.data.balance });
      }
    } catch (error) {
      console.log(error);
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.error) {
      return {
        loading: false,
        recordingCashSale: false,
        recordingCardSale: false,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  componentWillUnmount() {
    const { setNetworkStatus } = this.props;

    window.removeEventListener("online", () => {
      setNetworkStatus(navigator.onLine);
    });
    window.removeEventListener("offline", () => {
      setNetworkStatus(navigator.onLine);
    });
  }

  /**
   * This is done so that this component will be shorter and
   * easier to navigate
   */
  addProductToCart = addProductToCart.bind(this);
  handleAddServiceToCart = handleAddServiceToCart.bind(this);
  handleAddBundleProductToCart = handleAddBundleProductToCart.bind(this);
  handleReduceBundleProductToCart = handleReduceBundleProductToCart.bind(this);
  handleRemoveBundleProductToCart = handleRemoveBundleProductToCart.bind(this);
  removeServiceFromCart = removeServiceFromCart.bind(this);
  handleBundleOnSearchInput = handleBundleOnSearchInput.bind(this);
  handleServiceOnSearchInput = handleServiceOnSearchInput.bind(this);
  reduceServiceFromCart = reduceServiceFromCart.bind(this);
  handlePayment = handlePayment.bind(this);
  reduceItemFromCart = reduceItemFromCart.bind(this);
  removeItemFromCart = removeItemFromCart.bind(this);
  getCartTotalAmount = getCartTotalAmount.bind(this);
  hanldeProductSearch = hanldeProductSearch.bind(this);
  handleNitroSearch = handleNitroSearch.bind(this);
  handleCategoryChange = handleCategoryChange.bind(this);
  handleServiceCategoryChange = handleServiceCategoryChange.bind(this);
  handlePayWithCashValue = handlePayWithCashValue.bind(this);
  hanldeEnterOnSearchInput = hanldeEnterOnSearchInput.bind(this);
  handleCustomerSearchFieldChange = handleCustomerSearchFieldChange.bind(this);
  computeLoyaltyPoints = computeLoyaltyPoints.bind(this);
  computeLoyaltyStamps = computeLoyaltyStamps.bind(this);
  handleSaveInvoiceDone = handleSaveInvoiceDone.bind(this);
  gqlGetActiveLoyalty = gqlGetActiveLoyalty.bind(this);
  addBulkQuantityNew = addBulkQuantityNew.bind(this);
  handleNitroVariantSelect = handleNitroVariantSelect.bind(this);
  handleNitroCustomSelect = handleNitroCustomSelect.bind(this)

  updateCustomerAfterAdd = (user_id, userName) => {
    this.setState({
      customerValue:  user_id,
      customerName: userName,
      addLoyaltyProgramToSale: isNaN(user_id) ? false : true,
      isAddCustomer: false
    })
  }

  closeAddCustomerModal = () => {
    this.setState({ showAddCustomerModal: false, customerValue: [] });
  };

  updateBookingPayment = async (id) => {
    this.setState({ bookingCheckoutLoading: true });
    const {
      data: { updateBookingPayment: updateBookingResult },
    } = await this.props.client.mutate({
      mutation: UPDATE_BOOKING_PAYMENT,
      variables: { id },
      refetchQueries: [{ query: GET_BOOKINGS }],
    });
    this.setState({ bookingCheckoutLoading: false });
    updateBookingResult.status &&
      this.setState({ bookingPaymentSuccessful: true });
  };

  openAddCustomerModal = () => {
    this.setState({ showAddCustomerModal: true });
  };

  filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  toggleCartOnMobile = () => {
    this.setState({ isCartOpenOnMobile: !this.state.isCartOpenOnMobile });
  };

  toggleCustomPriceModal = (productWithoutPrice) => {
    this.setState({
      productWithoutPrice,
      isCustomPriceModalopen: !this.state.isCustomPriceModalopen,
    });
  };

  toggleTransactionModal = () => {
    !this.state.isTransactionModalVisible && this.checkCustomerLoyaltyBalance();

    this.setState({
      isTransactionModalVisible: !this.state.isTransactionModalVisible,
      customerCash: null,
    });
  };

  checkCustomerLoyaltyBalance = () => {
    const { customers } = this.props;
    const { customerValue } = this.state;

    const customer =
      (this.state.customerName || customerValue) &&
      customers.find((customer) => customer.user_id === customerValue);

    if (customer && customer.loyalty_points) {
      const {
        loyalty_points: { simple_points, stamps_program },
      } = customer;

      let rewards = [];

      simple_points.forEach((loyalty) => {
        if (loyalty.accumulated_points > loyalty.threshold) {
          rewards.push(loyalty.reward);
        }
      });

      stamps_program.forEach((loyalty) => {
        if (loyalty.accumulated_stamps > loyalty.threshold) {
          rewards.push(loyalty.reward);
        }
      });

      const stringified_rewards = `${rewards.join(", ")}`;

      const hasExceedSimplePointsThreshold = simple_points.some(
        (loyalty) => loyalty.accumulated_points > loyalty.threshold
      );

      const hasExceedStampsProgramsThreshold = stamps_program.some(
        (loyalty) => loyalty.accumulated_stamps > loyalty.threshold
      );

      const activeInstantRewardLoyalty = JSON.parse(
        localStorage.getItem("activeInstantRewardLoyalty")
      );

      const applyInstantLoyalty = (activeInstantRewardLoyalty) => {
        const customer =
          (this.state.customerName || customerValue) &&
          customers.find((item) => item.user_id === customerValue);

        if (!customer) return false;
        if (this.state.topupGiftCard) return false;
        if (!activeInstantRewardLoyalty) return false;
        else if (activeInstantRewardLoyalty.apply_to === "everyone")
          return checkLastRedemptionDate(customer, activeInstantRewardLoyalty);
        else if (
          activeInstantRewardLoyalty.apply_to === "set" &&
          activeInstantRewardLoyalty.instant_rewards_groups
        ) {
          const groupObj = {};
          const customer_groups = JSON.parse(
            localStorage.getItem("customer_groups")
          );
          customer_groups &&
            customer_groups.forEach((group) => (groupObj[group.id] = group));
          const customerIDs = [];
          activeInstantRewardLoyalty.instant_rewards_groups.forEach(
            (rewardGroup) =>
              groupObj[rewardGroup.group_id]
                ? customerIDs.push(groupObj[rewardGroup.group_id])
                : ""
          );
          const idObj = {};
          customerIDs.forEach((idGroup) =>
            idGroup.customers.forEach((id) => (idObj[id] = id))
          );
          return idObj[customer.id]
            ? checkLastRedemptionDate(customer, activeInstantRewardLoyalty)
            : false;
        } else return false;

        function checkLastRedemptionDate(customer, activeInstantRewardLoyalty) {
          if (
            customer.customer_eligibilities &&
            customer.customer_eligibilities.length > 0 &&
            customer.customer_eligibilities[0].merchant_loyalty_program_id ===
              activeInstantRewardLoyalty.id
          ) {
            if (activeInstantRewardLoyalty.eligibility_period) {
              return (
                new Date().getTime() >
                new Date(
                  customer.customer_eligibilities[0].last_redeemed_date
                ).getTime() +
                  activeInstantRewardLoyalty.eligibility_period * 86400000
              );
            } else return true;
          } else return true;
        }
      };

      if (applyInstantLoyalty(activeInstantRewardLoyalty)) {
        notification.info({
          message: "🎁 Instant Reward Active 🎁",
          description: `Customer will get ${activeInstantRewardLoyalty.reward_value}% ${activeInstantRewardLoyalty.reward} on checkout`,
          // placement: "topLeft",
        });
      }

      if (hasExceedSimplePointsThreshold || hasExceedStampsProgramsThreshold) {
        !applyInstantLoyalty(activeInstantRewardLoyalty) &&
          notification.info({
            message: "🎁 " + strings.redeemReward + "🎁",
            description: `${
              customer.first_name || customer.last_name || strings.customer
            } ${strings.isDueToRedeem} ${
              stringified_rewards || strings.aReward
            }.`,
          });
      }
    }
  };

  updateProductPrice = (product) => {
    this.handleVariantAddToCart(product);
  };

  handleVariantAddToCart = (item, type = "single") => {
    this.addProductToCart(item, type);
  };

  handleAddBulkQuantity = (item) => {
    // Check if the bulk quantity is more than the product quantity in stock

    this.addProductToCart(item, "bulk");
    this.setState({ showBulkModal: false });
  };

  handleSetPrice = (item) => {
    // Check if the bulk quantity is more than the product quantity in stock

    this.addProductToCart(item);
    this.setState({ showSetQuantityModal: false });
  };

  handleShowBulkQuantityModal = (item) => {
    this.setState({
      showBulkModal: true,
      currentBulkItem: item,
    });
  };

  handleSetServicePriceModal = (item) => {
    this.setState({
      showSetQuantityModal: true,
      currentServiceItem: item,
    });
  };

  handleCloseShowBulkQuantityModal = () => {
    this.setState({ showBulkModal: false });
  };

  handleCloseSetServicePriceModal = () => {
    this.setState({ showSetQuantityModal: false });
  };

  toggleShowCashForm = (val, from) => {
    if (from) {
      this.setState({
        showCashForm: val,
        invoicePrompt: false,
      });
    } else {
      this.setState({ showCashForm: !this.state.showCashForm });
    }
  };

  toggleShowInvoicePrompt = (val) => {
    this.setState({
      invoicePrompt: val,
    });
  };

  toggleOptionModal = () => {
    this.setState({
      openOptionModal: !this.state.openOptionModal,
    });
  };

  handleSelectLoyalty = function (value) {
    this.setState({ saleLoyaltyPoint: value });
  };

  handleCleanupSelectCustomerDropdown = () => {
    this.setState({ customerValue: [] });
  };

  toggleReceiptToEmailModal = () => {
    this.setState({ isReceiptModalVisible: !this.state.isReceiptModalVisible });
  };

  toggleShippingAddressModal = () => {
    this.setState({
      isShippingAddressVisible: !this.state.isShippingAddressVisible,
    });
  };

  toggleSendThankSMSModal = () => {
    this.setState({
      isSendThankSMSModalVisible: !this.state.isSendThankSMSModalVisible,
    });
  };

  toggleRedeemRewardModal = () => {
    if (this.state.customerValue.length === 0 || !this.state.customerValue) {
      Modal.warn({
        title: strings.selectCustomer,
        content: strings.pleaseSelectCustomerToReeemReward,
      });
    } else {
      if (this.state.addLoyaltyProgramToSale) {
        this.setState({ addLoyaltyProgramToSale: false });
      }

      this.setState({
        isRedeemRewardModalOpen: !this.state.isRedeemRewardModalOpen,
      });
    }
  };

  handlePaymentWithPoint = async () => {
    try {
      const { client, customers, location, user } = this.props;
      const { customerName, customerValue, activeLoyalty } = this.state;
      const customer =
        (customerName || customerValue) &&
        customers.find((item) => item.user_id === customerValue);

      if (!activeLoyalty) {
        message.warn(strings.youHaveNoLoyaltyProgramRunning);

        return;
      }
      this.setState({
        isPayingWithPoints: true,
        verification: activeLoyalty.verification,
      });

      const {
        data: { customer: currentCustomer, error },
      } = await client.query({
        query: GET_CUSTOMER,
        variables: { id: customer.user_id },
        fetchPolicy: "network-only",
      });

      if (error) {
        return this.setState({
          isPayingWithPoints: false,
          visible: false,
          twoPointModalVisible: false,
        });
      }

      const customerPoint =
        currentCustomer && currentCustomer.points.length
          ? currentCustomer.points.find(
              (point) => point.loyalty_id === activeLoyalty?.id
            )
          : null;

      if (currentCustomer && !customerPoint) {
        message.error(strings.customerHhasNoPointsInLoyaltyProgram);
        this.setState({ isPayingWithPoints: false });
        return;
      }
      const isLoyaltyExpiring =
        activeLoyalty.expiration !== null && activeLoyalty.expiration !== 0;

      if (
        isLoyaltyExpiring &&
        customerPoint &&
        customerPoint.expiry_date !== 0 &&
        Date.now() > customerPoint.expiry_date
      ) {
        message.error("Sorry, your loyalty points have expired!");
        this.setState({
          isPayingWithPoints: false,
          visible: false,
          twoPointModalVisible: false,
        });
        return;
      }

      // Confirm if point is enough to pay for transaction, else, show mwssage telling merchant to select another payment method to complete transaction
      const point = customerPoint?.points_value.toFixed(2);
      const amountPoint = customerPoint?.amount.toFixed(2);
      const { currency_value, points } = activeLoyalty;
      /*
       Formula to get Points: - (amount * points) / currency_value
       If Point is greater than Cart Total, Customer can make full payment with Points else Partial Payment would be made

      */
      const cartTotal = this.getCartTotalAmount();

      const cartPoints = (cartTotal * points) / currency_value;

      const phoneNumber = customer.phone_number;
      if (!phoneNumber && activeLoyalty.verification) {
        this.setState({
          isPayingWithPoints: false,
          visible: false,
          twoPointModalVisible: false,
        });
        return message.error("Customer has no phone number to redeem points!");
      } else if (phoneNumber && activeLoyalty.verification) {
        this.setState({ phoneNumber });
        const res = await api.HttpClient.put(
          "/generate_phone_verification_token",
          {
            phone_number: phoneNumber,
          }
        );
        message.info(res.message);
      }

      if (point >= cartPoints) {
        const content = (
          <span>
            {strings.youAreAboutToPayForTransactionUsingPoints}{" "}
            {strings.customerHas} {point} {strings.points} {strings.worth}{" "}
            {user.currency}
            {amountPoint}.
          </span>
        );
        this.setState({
          loyalty_id: activeLoyalty.id,
          mtier_amount: Number(this.getCartTotalAmount()),
          visible: true,
          twoPointModalVisible: false,
          content: content,
          location: location,
        });
      } else {
        const content = (
          <span>
            {strings.customerHas} {point || 0} {strings.points} {strings.worth}{" "}
            {user.currency}
            {amountPoint || 0} {strings.andIsNotSufficientToPayForTransaction}
          </span>
        );
        this.setState({
          loyalty_id: activeLoyalty.id,
          mtier_amount: Number(amountPoint),
          visible: false,
          twoPointModalVisible: true,
          content: content,
        });
      }
      // this.setState({ isPayingWithPoints: false })
    } catch (error) {
      console.log(error);
      message.error(error.message);
      this.setState({ isPayingWithPoints: false });
    }
  };

  handleToggleAddLoyaltyProgramToTransaction = () => {
    if (this.state.customerValue.length === 0 || !this.state.customerValue) {
      Modal.warning({
        title: strings.addCustomerLoyalty,
        content: strings.pleaseAddCustomerFirst,
      });
    } else {
      this.setState({
        addLoyaltyProgramToSale: !this.state.addLoyaltyProgramToSale,
      });
    }
  };

  handleShowMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleShowChangePassword = () => {
    this.setState({ showChangePassword: !this.state.showChangePassword });
  };

  clearSelectedCustomer = () => {
    this.setState({
      customerName: "",
    });
  };

  clearCart = () => {
    this.setState({
      cartList: [],
      isPayingWithPoints: false,
      isTwoPaymentMethod: false,
      mtier_amount: null,
      recordingCashSale: false,
    });
  };

  clearSaleId = () => this.setState({ saleId: "" });

  render() {
    const {
      user,
      customers,
      signoutUser,
      categories,
      sendReceipt,
      getDownloadableReceiptLink,
      allLoyaltyPrograms,
      isRenewalModalOpen,
      isNitroActive,
      isProductBundleActive,
      subscriptionStatus
    } = this.props;

    const {
      loading,
      cartList,
      products,
      searchTerm,
      showBulkModal,
      showSetQuantityModal,
      customerValue,
      currentBulkItem,
      currentServiceItem,
      recordingCardSale,
      payWithCashChange,
      showChangePassword,
      recordingCashSale,
      recordingGiftCardSale,
      recordingManualTransfer,
      isCartOpenOnMobile,
      showAddCustomerModal,
      isSendThankSMSModalVisible,
      isTransactionModalVisible,
      isRedeemRewardModalOpen,
      addLoyaltyProgramToSale,
      isCustomPriceModalopen,
      showCashForm,
      showMenu,
      openOptionModal,
      isReceipt,
      isReceiptModalVisible,
      isShippingAddressVisible,
      saleId,
      phoneNumber,
      requestDeliveryModalOpen,
      checkInCustomer,
      checkInNewCustomer,
      checkoutData,
      chooseVariant,
      chooseCustomQty,
      isPermitted,
      newVariantProduct,
      newCustomProduct,
      isNitroSearchEmpty,
      isGettingFromServer,
      serviceCategory
    } = this.state;

    const tabItems = [
      {
        key: 1, 
        label: <ShoppingCartOutlined
          className={checkoutData === "products" ? "active" : ""}
          onClick={() => this.setState({ checkoutData: "products" })}
        />, 
        disabled: (!this.props.isSubscribedToBookings ||
          !this.props.isSubscribedToMultiLevel) },
      {
        key: 2, 
        label: <img 
            src={bundleImg} 
            style={{
              cursor: "pointer",
            }}
            alt="bundle icon"
            className={checkoutData === "bundle" ? "active bundimg" : "bundimg"}
            onClick={() => this.setState({ checkoutData: "bundle" })}
          />,  
        disabled: !isProductBundleActive},
      {
        key: 3, 
        label:  <CalendarOutlined
          style={{ marginLeft: 10 }}
          className={checkoutData === "services" ? "active" : ""}
          onClick={() => this.setState({ checkoutData: "services" })}
        />,  
        disabled: !this.props.isSubscribedToBookings},
      {
        key: 4, 
        label:  <GiftOutlined
          style={{ marginLeft: 10 }}
          className={checkoutData === "loyalty" ? "active" : ""}
          onClick={() => this.setState({ checkoutData: "loyalty" })}
        />,  
        disabled: !this.props.isSubscribedToMultiLevel}
    ]

    checkInNewCustomer &&
      Modal.confirm({
        title: "New Check In",
        content: `${
          checkInNewCustomer?.first_name || "Customer"
        } just checked in.`,
        onOk: () => {
          this.handleCustomerSearchFieldChange(checkInNewCustomer.user_id);
          this.setState({
            checkInCustomer: checkInNewCustomer,
            checkInNewCustomer: null,
          });
        },
        okButtonProps: {
          name: "Proceed to Checkout",
        },
        onCancel: () =>
          this.setState({
            checkInNewCustomer: null,
            checkInCustomer: null,
          }),
      });

    const customer =
      (this.state.customerName || customerValue) &&
      customers.find((item) => item.user_id === customerValue);

    const cartClass = classnames("cart", {
      open: isCartOpenOnMobile,
    });

    const openQrCodeWindow = () => {
      this.setState((prev) => ({
        openCheckInWindow: !prev.openCheckInWindow,
        checkInCustomer: null,
      }));
    };

    return (
      <div className="sales">
        <VerifyTokenModal
          visible={this.state.visible}
          phoneNumber={phoneNumber}
          closeModal={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ isPayingWithPoints: false })}
          onOk={() => this.handlePayment("point", this.state.location)}
          isRequired={this.state.verification}
          content={this.state.content}
          loading={loading}
        />
        ;
        <VerifyTokenModal
          visible={this.state.twoPointModalVisible}
          phoneNumber={phoneNumber}
          closeModal={() => this.setState({ twoPointModalVisible: false })}
          onCancel={() => this.setState({ isPayingWithPoints: false })}
          isRequired={this.state.verification}
          onOk={() =>
            this.setState({
              isTwoPaymentMethod: true,
              twoPointModalVisible: false,
            })
          }
          loading={loading}
          content={this.state.content}
        />
        ;
        <Header style={{ zIndex: "2" }}>
          {subscriptionStatus === "not_active" || subscriptionStatus === "incomplete" ? <SubscriptionNotification user={user} /> : null}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            className="main-nav"
          >
            <h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                {user.employer && user.employer.image ?  <img 
                  src={user.employer ? user.employer.image : user.image} 
                  alt={user.employer ? user.employer.business_name : user.business_name} 
                  style={{
                    height: "40px", 
                    width: "40px"
                  }}
                /> : user.image ? <img 
                src={user.employer ? user.employer.image : user.image} 
                alt={user.employer ? user.employer.business_name : user.business_name} 
                style={{
                  height: "40px", 
                  width: "40px"
                }}
              /> : null}
                <h3 style={{fontSize: "18px", margin: "0"}}>
                  {user.business_name ||
                  `${user.employer.business_name} - ${user.business_branch.name.toLowerCase().includes("branch") ? user.business_branch.name : user.business_branch.name +" "+ "branch"}`}
                {` ${"Sales"}`}
                </h3>
              </div>
              <span
                className="products_services_switch_small"
                style={{ marginRight: "20px" }}
              >
                {(this.props.isSubscribedToBookings ||
                  this.props.isSubscribedToMultiLevel) && (
                  <ShoppingCartOutlined
                    className={checkoutData === "products" ? "active" : ""}
                    onClick={() => this.setState({ checkoutData: "products" })}
                  />
                )}
                {isProductBundleActive && 
                    <img 
                      src={bundleImg} 
                      style={{
                        cursor: "pointer",
                      }}
                      alt="bundle icon"
                      className={checkoutData === "bundle" ? "active bundimg" : "bundimg"}
                      onClick={() => this.setState({ checkoutData: "bundle" })}
                    />
                  }
                {this.props.isSubscribedToBookings && (
                  <CalendarOutlined
                    style={{ marginLeft: 10 }}
                    className={checkoutData === "services" ? "active" : ""}
                    onClick={() => this.setState({ checkoutData: "services" })}
                  />
                )}
                {this.props.isSubscribedToMultiLevel && (
                  <GiftOutlined
                    style={{ marginLeft: 10 }}
                    className={checkoutData === "loyalty" ? "active" : ""}
                    onClick={() => this.setState({ checkoutData: "loyalty" })}
                  />
                )}
              </span>
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{ marginRight: 10, borderRadius: 30 }}
                  onClick={openQrCodeWindow}
                >
                  <QrcodeOutlined />
                </Button>
                <span
                  className="products_services_switch_big"
                  style={{ marginRight: 20 }}
                >
                  {(this.props.isSubscribedToBookings ||
                    this.props.isSubscribedToMultiLevel) && (
                    <ShoppingCartOutlined
                      className={checkoutData === "products" ? "active" : ""}
                      onClick={() =>
                        this.setState({ checkoutData: "products" })
                      }
                    />
                  )}
                   {isProductBundleActive && 
                    <img 
                      src={bundleImg} 
                      style={{
                        cursor: "pointer",
                      }}
                      alt="bundle icon"
                      className={checkoutData === "bundle" ? "active bundimg" : "bundimg"}
                      onClick={() => this.setState({ checkoutData: "bundle" })}
                    />
                  }
                  {this.props.isSubscribedToBookings && (
                    <CalendarOutlined
                      style={{ marginLeft: 10 }}
                      className={checkoutData === "services" ? "active" : ""}
                      onClick={() =>
                        this.setState({ checkoutData: "services" })
                      }
                    />
                  )}
                  {this.props.isSubscribedToMultiLevel && (
                    <GiftOutlined
                      style={{ marginLeft: 10 }}
                      className={checkoutData === "loyalty" ? "active" : ""}
                      onClick={() => this.setState({ checkoutData: "loyalty" })}
                    />
                  )}
                </span>
                <div style={{ marginRight: "20px", display: "inline" }}>
                  <OnlineIndicator />
                </div>
                <Button icon={<MenuOutlined />} onClick={this.handleShowMenu} />
              </div>
            </div>
          </div>

          {showMenu ? (
            <Menu
              signoutUser={signoutUser}
              handleClickOut={this.handleShowMenu}
              user={user}
              client={this.props.client}
              handleShowChangePassword={this.handleShowChangePassword}
            />
          ) : null}
          {
            <AuthModal 
              open={this.state.isAuthModal}
              onCancel={()=>{
                this.setState({ isAuthModal: false })
              }}
              grantPermission={()=>{
                this.setState({ isPermitted: true})
              }}
              user={user}
            />
          }
        </Header>
        <main>
          <section className="products container">
            <div className="product-list">
              {this.state.openCheckInWindow && (
                <NewWindow
                  title="Scan QR"
                  center="screen"
                  name={user.business_name}
                  ref={this.qrComponentRef}
                >
                  <ScanQrCode
                    user={user}
                    customers={customers}
                    allLoyaltyPrograms={allLoyaltyPrograms}
                    setCheckInCustomer={(val) =>
                      this.setState({ checkInNewCustomer: val })
                    }
                    getAllCustomers={this.props.getAllCustomers}
                    checkInCustomer={checkInCustomer}
                  />
                </NewWindow>
              )}
              {checkoutData !== "loyalty" && (
                <Row className="searchbar-row">
                  <Col span={22}>
                    <Filter
                      categories={categories}
                      serviceCategory={serviceCategory}
                      onChangeCategory={this.handleCategoryChange}
                      onChangeServiceCategory={this.handleServiceCategoryChange}
                      onSearch={this.hanldeProductSearch}
                      searchTerm={searchTerm}
                      onSearchEnter={this.hanldeEnterOnSearchInput}
                      checkoutData={this.state.checkoutData}
                      onNitroSearch={this.handleNitroSearch}
                      handleBundleOnSearchInput={this.handleBundleOnSearchInput}
                      handleServiceOnSearchInput={this.handleServiceOnSearchInput}
                      shouldBlurIn={this.state.shouldBlurIn}
                      handleBlurFactor={this.handleBlurFactor}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    span={2}
                  >
                    {this.state.checkoutData !== "products" && this.state.checkoutData !== "bundle" && (
                      <CalendarOutlined
                        title="Show Bookings"
                        className={
                          this.state.checkoutData === "bookings"
                            ? "show-bookings-icon active"
                            : "show-bookings-icon"
                        }
                        onClick={() =>
                          this.setState((prev) => ({
                            checkoutData:
                              prev.checkoutData === "services"
                                ? "bookings"
                                : "services",
                          }))
                        }
                      />
                    )}
                  </Col>
                </Row>
              )}
              <Row gutter={15} style={{ marginTop: 120 }}>
                {!loading ? (
                  <>
                    {checkoutData === "products" && (
                      <ProductList
                        data={products && products.length > 0 ? products : user.employer ? this.props.branchProducts : this.props.products}
                        addProductToCart={this.handleVariantAddToCart}
                        user={user}
                        toggleCustomPriceModal={this.toggleCustomPriceModal}
                        location={this.props.location}
                        cartList={cartList}
                        isNitroActive={isNitroActive}
                        isNitroSearchEmpty={isNitroSearchEmpty}
                        isGettingFromServer={isGettingFromServer}
                        handleShowSetPrice={this.handleSetServicePriceModal}
                        checkoutData={this.state.checkoutData}
                        handleBlurFactor={this.handleBlurFactor}
                      />
                    )}
                    {checkoutData === "services" && (
                      <ServicesList
                        data={this.state.allServices}
                        addServiceToCart={this.handleAddServiceToCart}
                        user={user}
                        handleShowSetPrice={this.handleSetServicePriceModal}
                        checkoutData={this.state.checkoutData}
                      />
                    )}
                    {checkoutData === "bundle" && (
                      <BundleList 
                        data={this.state.allBundles}
                        addBundleToCart={this.handleAddBundleProductToCart}
                        user={user}
                        location={this.props.location}
                        cartList={cartList}
                      />
                    )}
                    {checkoutData === "bookings" && (
                      <CalendarView
                        allBookings={this.state.allBookings}
                        addServiceToCart={this.handleAddServiceToCart}
                        customers={customers}
                        setCurrentBooking={(booking) =>
                          this.setState({ currentBooking: booking })
                        }
                        loading={this.state.bookingCheckoutLoading}
                        workHours={this.state.workHours}
                        toggleTransactionModal={this.toggleTransactionModal}
                        bookingPaymentSuccessful={
                          this.state.bookingPaymentSuccessful
                        }
                      />
                    )}
                    {checkoutData === "loyalty" && (
                      <LoyaltyMode
                        user={user}
                        activeLoyalty={this.state.activeLoyalty}
                        customers={customers}
                      />
                    )}
                    {checkoutData === "booking-details" && (
                      <BookingDetails
                        onCancel={() =>
                          this.setState({ checkoutData: "bookings" })
                        }
                        currentBooking={this.state.currentBooking}
                        customers={customers}
                        addServiceToCart={this.handleAddServiceToCart}
                        loading={this.state.bookingCheckoutLoading}
                        toggleTransactionModal={this.toggleTransactionModal}
                        bookingPaymentSuccessful={
                          this.state.bookingPaymentSuccessful
                        }
                      />
                    )}
                  </>
                ) : (
                  <p style={{ margin: 20 }}>
                    {strings.pleaseWaitWhileWeGetReady + "..."}
                  </p>
                )}
              </Row>
            </div>

            {/* SHOPPING CART BUTTON */}
            <MobileCartButton
              isCartOpenOnMobile={isCartOpenOnMobile}
              cartData={cartList}
              toggleCartOnMobile={this.toggleCartOnMobile}
            />

            {/* CART */}
            <div className={cartClass}>
              {/* Cart List */}
              <CartList
                data={cartList}
                increaseItemQuantity={this.addProductToCart}
                removeItemFromCart={this.removeItemFromCart}
                reduceItemFromCart={this.reduceItemFromCart}
                addBundleToCart={this.handleAddBundleProductToCart}
                removeBundleFromcart={this.handleRemoveBundleProductToCart}
                reduceBundleFromCart={this.handleReduceBundleProductToCart}
                handleShowBulkModal={this.handleShowBulkQuantityModal}
                handleShowSetPrice={this.handleSetServicePriceModal}
                location={this.props.location}
                checkoutData={this.state.checkoutData}
              />

              {/* Cart Footer */}
              {cartList.length ? (
                <CartFooter
                  user={user}
                  subPaymentType={this.state.subPaymentType}
                  denyAccess={this.state.denyAccess}
                  cartList={cartList}
                  customers={customers}
                  customerName={this.state.customerName}
                  customerValue={customerValue}
                  onCustomerSearch={this.handleCustomerSearchFieldChange}
                  toggleTransactionModal={this.toggleTransactionModal}
                  getCartTotalAmount={this.getCartTotalAmount}
                  cleanupSelectCustomerDropdown={
                    this.handleCleanupSelectCustomerDropdown
                  }
                  addLoyaltyProgramToSale={addLoyaltyProgramToSale}
                  toggleAddLoyaltyProgramToTransaction={
                    this.handleToggleAddLoyaltyProgramToTransaction
                  }
                  toggleRenewalModal={this.props.toggleRenewalModal}
                  isRedeemRewardModalOpen={isRedeemRewardModalOpen}
                  toggleRedeemRewardModal={this.toggleRedeemRewardModal}
                  location={this.props.location}
                  handleBlurFactor={this.handleBlurFactor}
                />
              ) : null}
            </div>

            {isTransactionModalVisible ? (
              <RecordSalePaymentModal
                user={user}
                fetchActiveApps={this.props.fetchActiveApps}
                location={this.props.location}
                visible={isTransactionModalVisible}
                toggleTransactionModal={this.toggleTransactionModal}
                onCancel={this.toggleTransactionModal}
                customerCash={this.state.customerCash}
                onCashValueChange={this.handlePayWithCashValue}
                getCartTotalAmount={this.getCartTotalAmount}
                recordingCardSale={recordingCardSale}
                recordingCashSale={recordingCashSale}
                recordingGiftCardSale={recordingGiftCardSale}
                recordingManualTransfer={recordingManualTransfer}
                cashChange={payWithCashChange}
                handlePayment={this.handlePayment}
                onPayWithCash={this.handlePayWithCash}
                toggleShowCashForm={this.toggleShowCashForm}
                showCashForm={showCashForm}
                toggleShowInvoicePrompt={this.toggleShowInvoicePrompt}
                invoicePrompt={this.state.invoicePrompt}
                selectedCustomer={this.state.customerName}
                clearSelectedCustomer={this.clearSelectedCustomer}
                customers={customers}
                customerValue={customerValue}
                customer={customer}
                categories={categories}
                onCustomerSearch={this.handleCustomerSearchFieldChange}
                cleanupSelectCustomerDropdown={
                  this.handleCleanupSelectCustomerDropdown
                }
                cartList={cartList}
                addProductToCart={this.addProductToCart}
                removeItemFromCart={this.removeItemFromCart}
                reduceItemFromCart={this.reduceItemFromCart}
                handleShowBulkQuantityModal={this.handleShowBulkQuantityModal}
                handleSaveInvoiceDone={this.handleSaveInvoiceDone}
                allLoyaltyPrograms={allLoyaltyPrograms}
                clearCart={this.clearCart}
                isSubscribedToMultiLevel={this.props.isSubscribedToMultiLevel}
                isPayingWithPoints={this.state.isPayingWithPoints}
                handlePaymentWithPoint={this.handlePaymentWithPoint}
                isTwoPaymentMethod={this.state.isTwoPaymentMethod}
                mtier_amount={this.state.mtier_amount}
                loyalty_id={this.state.loyalty_id}
                cardId={this.state.cardId}
                clearTopupGiftCard={() =>
                  this.setState({ topupGiftCard: false, cardId: null })
                }
                topupGiftCard={this.state.topupGiftCard}
                isDisableNewFreePlanAccounts={
                  this.state.isDisableNewFreePlanAccounts
                }
                checkoutData={this.state.checkoutData}
                handleBlurFactor={this.handleBlurFactor}
              />
            ) : null}

            {requestDeliveryModalOpen && (
              <RequestDeliveryModal
                visible={requestDeliveryModalOpen}
                onCancel={() =>
                  this.setState({ requestDeliveryModalOpen: false })
                }
                cartList={cartList}
                customer={customer}
                user={user}
              />
            )}

            <SendOptionModal
              cancel={this.toggleOptionModal}
              openOptionModal={openOptionModal}
              user={user}
              isReceipt={isReceipt}
              saleId
              sendReceipt={sendReceipt}
              getDownloadableReceiptLink={getDownloadableReceiptLink}
            />
          </section>
        </main>
        <AddCustomerModal
          showModal={showAddCustomerModal}
          closeModal={this.closeAddCustomerModal}
          isAddCustomer={this.state.isAddCustomer}
          updateCustomerAfterAdd={this.updateCustomerAfterAdd}
        />
        {showChangePassword ? (
          <ChangeStaffPasswordModal
            showChangePassword={showChangePassword}
            handleShowChangePassword={this.handleShowChangePassword}
          />
        ) : null}
        {showBulkModal ? (
          <BulkQuantityModal
            item={currentBulkItem}
            products={products}
            visible={showBulkModal}
            handleAdd={this.handleAddBulkQuantity}
            handleAddBundle={this.handleAddBundleProductToCart}
            closeModal={this.handleCloseShowBulkQuantityModal}
            checkoutData={checkoutData}
            handleBlurFactor={this.handleBlurFactor}
          />
        ) : null}
        {showSetQuantityModal ? (
          <SetServicePriceModal
            item={currentServiceItem}
            products={products}
            visible={showSetQuantityModal}
            handleAdd={this.handleSetPrice}
            handleAddBundle={this.handleAddBundleProductToCart}
            closeModal={this.handleCloseSetServicePriceModal}
            checkoutData={checkoutData}
            addServiceToCart={this.handleAddServiceToCart}
          />
        ) : null}
        {isReceiptModalVisible ? (
          <ReceiptToEmailModal
            isReceiptModalVisible={isReceiptModalVisible}
            closeModal={this.toggleReceiptToEmailModal}
            customerValue={customerValue}
            customerList={customers}
            saleId={saleId}
            clearSaleId={this.clearSaleId}
          />
        ) : null}
        {isShippingAddressVisible ? (
          <ShippingAddressModal
            user={user}
            isShippingAddressVisible={isShippingAddressVisible}
            closeModal={this.toggleShippingAddressModal}
            customerValue={customerValue}
            customerList={customers}
            saleId={saleId}
            clearSaleId={this.clearSaleId}
            cartList={cartList}
            allLoyaltyPrograms={allLoyaltyPrograms}
            isTwoPaymentMethod={this.state.isTwoPaymentMethod}
            mtier_amount={this.state.mtier_amount}
            loyalty_id={this.state.loyalty_id}
            hasDiscount={this.props.salesDetails.has_discount}
            discount_amount={this.props.salesDetails.discount_amount}
            channel_mtd={this.props.salesDetails.channel}
            paid_with={
              (this.props.salesDetails.is_paid_with_card === true && "card") ||
              (this.props.salesDetails.is_paid_with_cash === true && "cash") ||
              (this.props.salesDetails.is_paid_with_mobile === true && "") ||
              (this.props.salesDetails.is_paid_with_mtransfer === true &&
                "bank transfer") ||
              (this.props.salesDetails.is_paid_with_point === true && "point")
            }
          />
        ) : null}
        {isSendThankSMSModalVisible ? (
          <ShortSMSModal
            showModal={isSendThankSMSModalVisible}
            closeModal={this.toggleSendThankSMSModal}
            customer={customer || {}}
            totalCustomers={customers}
            isBroadcast={false}
            smsBalance={this.state.sms_balance}
            thankYouMessage={THANK_YOU_SMS}
          />
        ) : null}
        {isRenewalModalOpen ? (
          <RenewalModal
            isRenewalModalOpen={isRenewalModalOpen}
            toggleRenewalModal={this.props.toggleRenewalModal}
            signoutUser={signoutUser}
            client={this.props.client}
            isFromSales
          />
        ) : null}
        {isRedeemRewardModalOpen ? (
          <RedeemReward
            customer={customers.find(
              (customer) => customer.user_id === this.state.customerValue
            )}
            allLoyaltyPrograms={allLoyaltyPrograms}
            showModal={isRedeemRewardModalOpen}
            closeModal={this.toggleRedeemRewardModal}
          />
        ) : null}
        {isCustomPriceModalopen ? (
          <CustomPriceModal
            visible={isCustomPriceModalopen}
            closeModal={this.toggleCustomPriceModal}
            updateProductPrice={this.updateProductPrice}
            productWithoutPrice={this.state.productWithoutPrice}
            handleBlurFactor={this.handleBlurFactor}
          />
        ) : null}
        {chooseVariant && 
          <CustomVariant 
            open={chooseVariant}
            onCancel={()=>this.setState({ chooseVariant: false, products: [], shouldBlurIn: true })}
            product={newVariantProduct}
            handleNitroVariantSelect={this.handleNitroVariantSelect}
            handleBlurFactor={this.handleBlurFactor}
          />}
          {chooseCustomQty && 
            <CustomQuantityNitroForm 
              open={chooseCustomQty}
              onCancel={()=>this.setState({ chooseCustomQty: false, products: [] })}
              product={newCustomProduct}
              onClick={this.handleNitroCustomSelect}
              handleBlurFactor={this.handleBlurFactor}
            />  
          }
      </div>
    );
  }
}

Sales.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string.isRequired,
  recordSale: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllCustomers: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  signoutUser: PropTypes.func.isRequired,
  showVariantModal: PropTypes.func.isRequired,
  showCustomQtyModal: PropTypes.func.isRequired,
  getAllLoyaltyPrograms: PropTypes.func.isRequired,
  updateProductItemInStore: PropTypes.func.isRequired,
  getAllBranchProducts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isRenewalModalOpen: state.renewal.isRenewalModalOpen,
  subscriptionStatus: state.renewal.subscriptionStatus,
  products: state.product.allProducts,
  branchProducts: state.product.branchProducts,
  categories: state.category.allCategories,
  user: state.auth.user || state.merchant.user,
  customers: state.customer.allCustomers,
  newCustomer: state.customer.newCustomer,
  error: state.error,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  isSubscribedToMultiLevel: state.multiLevel.isSubscribed,
  isSubscribedToBookings: state.multiLevel.isSubscribedToBookings,
  salesDetails: state.sale.salesDetails,
  isNitroActive: state.appStore.isNitroActive,
  isProductBundleActive: state.appStore.isProductBundleActive,
  allBranches: state.branch.allBranches,
  splitPaymentActive: state.sale.splitPaymentActive
});

export default connect(mapStateToProps, {
  recordSale,
  getAllProducts,
  getAllCustomers,
  addLoyaltyCustomers,
  getAllCategories,
  getAllSales,
  signoutUser,
  showVariantModal,
  showCustomQtyModal,
  clearShowVariantModalData,
  getAllLoyaltyPrograms,
  setNetworkStatus,
  updateProductItemInStore,
  sendReceipt,
  getDownloadableReceiptLink,
  getAllBranchProducts,
  getAllBusinessBranches,
  toggleRenewalModal,
  checkSubscriptionStatus,
  getMerchantsSMSBalance,
  fetchActiveApps,
  changeMultiLevelStatus,
  changeBookingStatus,
  setNewCustomer,
  payOrder,
  addMerchantCurrentPlan,
  activeSplitPayment,
  getMerchantSubPlan
})(WithApolloClient(Sales));
