import React from "react";
import { Card, Button, Input, Menu, Dropdown, Spin, DatePicker, message } from "antd";
import { Icon } from "@ant-design/compatible";
import { PlusOutlined, SearchOutlined, DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Highlighter from "react-highlight-words";
import {
  getTableColumns,
  parseInvoice,
  handleCustomerSearchFieldChange,
  decreaseItemQuantity,
  removeItemFromCart,
  addProductToCart,
  getCartAmount,
  getTotal,
  getCartItems,
} from "./functions";
import CustomDataTable from "../../../../components/CustomDataTable";
import { getAllCustomers } from "../../../../redux/actions/customerActions";
import { getAllBusinessBranches } from "../../../../redux/actions/branchActions";
import { updateProductItemInStore } from "../../../../redux/actions/productActions";
import InvoiceDescription from "./_partials/InvoiceDescription";
import DeleteInvoiceModal from "./_partials/DeleteInvoiceModal";
import SendOptionModal from "../../../../components/SendOptionModal";
import InvoiceForm from "../../../../components/Invoice/InvoiceForm";
import {
  sendInvoiceToCustomerAsMail,
  getDownloadableInvoiceLink,
  getPaymentMessage,
  getAllInvoices,
  getAllInvoicesByDates,
  sendReceipt,
  getDownloadableReceiptLink,
  getAllInvoicesByCustomerName
} from "../../../../redux/actions/invoiceActions";
import numberFormatter from "../../../../utils/numberFormatter";
import "./index.scss";
import strings from "../../../../strings";

const MenuItem = Menu.Item;
const { RangePicker } = DatePicker;

class Invoices extends React.Component {
  state = {
    loading: false,
    loadingInvoice: false,
    showDeleteInvoiceModal: false,
    openOptionModal: false,
    showEditInvoice: false,
    cartList: [],
    invoices: [],
    allInvoices: [],
    isReceipt: false,
    invoice: {},
    total: 0,
    current: 1,
    defaultPageSize: 10,
    pageSize: 10,
    filterValue: "",
    isSelectedDate: false,
    begin: null,
    end: null,
    customer_name: null
  };

  handleCustomerSearchFieldChange = handleCustomerSearchFieldChange.bind(this);
  decreaseItemQuantity = decreaseItemQuantity.bind(this);
  removeItemFromCart = removeItemFromCart.bind(this);
  addProductToCart = addProductToCart.bind(this);
  getCartAmount = getCartAmount.bind(this);
  getTotal = getTotal.bind(this);
  parseInvoice = parseInvoice.bind(this);

  componentDidMount() {
    this.fetchInvoices();
    this.props.getAllCustomers();
    this.props.getAllBusinessBranches();
    this.fetchAllInvoices();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      // invoices: nextProps.invoices,
    });
    this.handleLoading(false);
  }

  fetchInvoices = (
    current = this.state.current,
    pageSize = this.state.pageSize
  ) => {
    this.setState({ loading: true });
    this.props.getAllInvoices(current, pageSize).then((res) =>
      this.setState({
        loading: false,
        total: Number(res.headers.total || 0),
        current: Number(res.headers.page),
        pageSize: Number(res.headers["per-page"]),
        invoices: res.data
      })
    );
  };

  fetchInvoicesByDate = (
    current = this.state.current,
    pageSize = this.state.pageSize,
    begin = this.state.begin,
    end = this.state.end
  ) => {
    this.setState({ loading: true });
    this.props
    .getAllInvoicesByDates(current, pageSize, begin, end)
    .then((res) =>
      this.setState({
        loading: false,
        total: Number(res.headers.total || 0),
        current: Number(res.headers.page),
        pageSize: Number(res.headers["per-page"]),
        invoices: res.data
      })
    )
    .catch(() => {
      message.error(strings.errorFetchingRecord);
    });
    
  };

  fetchAllInvoices = () => {
    this.setState({ allInvoicesLoading: true });
    this.props
      .getAllInvoices(1, 10000000)
      .then((res) =>{
        // console.log("res", res.data);
        this.setState({ allInvoices: res.data, allInvoicesLoading: false })
      }
      );
  };

  toggleDescriptionModal = () => {
    this.setState({
      showDescriptionModal: !this.state.showDescriptionModal,
    });
  };

  toggleDeleteInvoiceModal = () => {
    this.setState({
      showDeleteInvoiceModal: !this.state.showDeleteInvoiceModal,
    });
  };

  toggleSendOptionModal = () => {
    const { invoice } = this.state;
    this.setState({
      openOptionModal: !this.state.openOptionModal,
      isReceipt: invoice.status === "paid" ? true : false,
    });
  };

  toggleEditInvoiceModal = (value) => {
    if (value) {
      this.setState({
        showEditInvoice: true,
      });
    } else {
      this.setState({
        showEditInvoice: !this.state.showEditInvoice,
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {strings.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          {strings.reset}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.productSearchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  handleGetInvoiceByCustName = (
    customer_name,
    current = this.state.current,
    pageSize = this.state.pageSize
  ) => {
    this.setState({ loading: true });
    this.props
    .getAllInvoicesByCustomerName(customer_name, current, pageSize)
    .then((res) =>{
      this.setState({
        loading: false,
        total: Number(res.headers.total || 0),
        current: Number(res.headers.page),
        pageSize: Number(res.headers["per-page"]),
        invoices: res.data
      })
    })
    .catch((err) => {
      message.error(strings.errorFetchingRecord);
      this.setState({ loading: false })
    });
  }
  
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    if(dataIndex === "customerName"){
      this.setState({ productSearchText: selectedKeys[0], customer_name: selectedKeys[0]  });
      return this.handleGetInvoiceByCustName(selectedKeys[0])
    }
    
    confirm();
    this.setState({ productSearchText: selectedKeys[0] });
  };

  handleReset = (clearFilters, confirm, dataIndex) => {
    if(dataIndex === "customerName"){
      this.setState({ productSearchText: "", customer_name: null  });
      return this.fetchInvoices()
    }
    clearFilters();
    confirm();
    this.setState({ productSearchText: "" });
  };

  updateFilterValue = filterValue => this.setState({ filterValue: filterValue });

  handleDurationChange = (duration, ...rest) => {
    const { current, pageSize, begin, end } = this.state;
    this.updateFilterValue("");

    if (duration) {
      const [begin, end] = duration;
      this.setState({
        loading: true,
        begin: begin.format().split("T")[0],
        end: end.format().split("T")[0]
      });
      
      this.fetchInvoicesByDate(
        1,
        pageSize,
        begin.format().split("T")[0],
        end.format().split("T")[0]
      )
      
    } else if (!duration) {
      this.setState({
        loading: true,
        begin: null,
        end: null
      });
      
      this.fetchInvoices(
        1,
        pageSize,
      )
    } else if (!begin || !end) {
      message.error(
        `${strings.pleaseEnterAValid} ${!begin ? strings.begin : strings.end} ${
          strings.date
        }.`
      );
    }
  };

  handleLoading = (value) => {
    this.setState({
      invoiceLoading: value,
    });
  };

  handleTableChange = ({ current, pageSize }) => {
    const { begin, end, customer_name } = this.state;
    if (current !== this.state.current || pageSize !== this.state.pageSize){
      if(customer_name){
        this.handleGetInvoiceByCustName(customer_name, current, pageSize)
      }else if(begin && end ){
        this.fetchInvoicesByDate(current, pageSize, begin, end)
      }else{
        this.fetchInvoices(current, pageSize);
      }
    }
  };

  render() {
    const {
      loading,
      loadingInvoice,
      showDescriptionModal,
      showDeleteInvoiceModal,
      invoice,
      openOptionModal,
      showEditInvoice,
      cartList,
      invoices,
      customerName,
      customerValue,
      isReceipt,
      defaultPageSize,
      current,
      total,
      pageSize,
      allInvoices,
      allInvoicesLoading,
      end,
      begin
    } = this.state;

    const {
      sendInvoiceToCustomerAsMail,
      getDownloadableInvoiceLink,
      user,
      customers,
      sendReceipt,
      getDownloadableReceiptLink,
      products
    } = this.props;

    const columns = getTableColumns.call(this);
    const totalInvoices = this.state.total;

    const acceptedHeaders = [
      {
        label: "Invoice ID",
        key: "number",
      },
      {
        label: "Customer",
        key: "customerName",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Total",
        key: "subtotal",
      },
      {
        label: "Amount Paid",
        key: "amountPaid",
      },
      {
        label: "Amount Due",
        key: "amountDue",
      },
      {
        label: "Staff Username",
        key: "staff_username",
      },
      {
        label: "Created Date",
        key: "createdDate",
      },
      {
        label: "Date Paid",
        key: "paidDate",
      },
      {
        label: "Reference",
        key: "payment_reference",
      },
      {
        label: "Payment Message",
        key: "payment_message",
      },
    ];

    return (
      <main>
        <Card
          title={strings.invoices}
          loading={loading}
          extra={
            (user.role_id === 1 ||
              user.role.id === 2 ||
              user.role.id === 3) && (
              <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <div>
                  <span style={{ margin: "0 10px" }}>{strings.invoiceFrom}:</span>
                  <RangePicker
                    placeholder={[strings.startDate, strings.endDate]}
                    onChange={this.handleDurationChange} />
                </div>
                <Dropdown
                  overlay={
                    <Menu>
                      <MenuItem key="1">
                        <CSVLink
                          data={this.parseInvoice(allInvoices)}
                          headers={acceptedHeaders}
                          filename={"invoiceList.csv"}
                          target="_blank"
                          style={{ marginLeft: "10px" }}
                        >
                          <Button> Download Invoices</Button>
                        </CSVLink>
                      </MenuItem>
                    </Menu>
                  }
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="default"
                    onClick={() => {
                      this.props.getPaymentMessage();
                      this.toggleDescriptionModal();
                    }}
                  >
                    {strings.paymentMessage} <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            )
          }
        >
          <section className="invoices-table mt-40">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5>
                {strings.totalInvoices}: {totalInvoices}
              </h5>
              <span
                style={{
                  display: "none",
                  width: "auto",
                  minWidth: "40%",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ marginRight: "10px" }}>
                  {strings.totalPaidInvoices}:{" "}
                  {allInvoicesLoading ? (
                    <Spin size="small" />
                  ) : (
                    numberFormatter(this.getTotal("paid", allInvoices))
                  )}
                </h5>
                <h5>
                  {strings.totalUnpaidInvoices}:{" "}
                  {allInvoicesLoading ? (
                    <Spin size="small" />
                  ) : (
                    numberFormatter(this.getTotal("unpaid", allInvoices))
                  )}
                </h5>
              </span>
            </div>

            <CustomDataTable
              columns={columns}
              dataSource={this.parseInvoice(invoices)}
              loading={loadingInvoice}
              pagination={{ defaultPageSize: pageSize, current, total, pageSize }}
              onChange={this.handleTableChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.classList.contains("fa-ellipsis-h")) {
                      // console.log("item", record.items);
                      this.setState(
                        {
                          invoice: {
                            ...record,
                            hasDiscount: record.has_discount,
                            discountAmount: record.discount_amount,
                          },
                          // cartList: record.items,
                          cartList: getCartItems(record.items),
                          customerName: `${record.customer.first_name} ${
                            record.customer.last_name || ""
                          }`,
                          customerValue: record.customer.user_id,
                        },
                        () => {
                          this.toggleEditInvoiceModal("open");
                        }
                      );
                    }
                  },
                };
              }}
            />
          </section>
        </Card>
        {showDescriptionModal ? (
          <InvoiceDescription
            showModal={showDescriptionModal}
            closeModal={this.toggleDescriptionModal}
          />
        ) : null}

        {showDeleteInvoiceModal ? (
          <DeleteInvoiceModal
            showModal={showDeleteInvoiceModal}
            closeModal={this.toggleDeleteInvoiceModal}
            invoice={invoice}
          />
        ) : null}

        <SendOptionModal
          cancel={this.toggleSendOptionModal}
          openOptionModal={openOptionModal}
          invoice={invoice}
          sendInvoiceToCustomerAsMail={sendInvoiceToCustomerAsMail}
          getDownloadableInvoiceLink={getDownloadableInvoiceLink}
          user={user}
          isReceipt={isReceipt}
          saleId={invoice.id}
          sendReceipt={sendReceipt}
          getDownloadableReceiptLink={getDownloadableReceiptLink}
          getAllInvoices={this.props.getAllInvoices}
        />

        {showEditInvoice && (
          <InvoiceForm
            selectedCustomer={customerName}
            cartList={cartList}
            addProductToCart={this.addProductToCart}
            removeItemFromCart={this.removeItemFromCart}
            reduceItemFromCart={this.decreaseItemQuantity}
            addNewProductToCart={this.addProductToCart}
            handleShowBulkQuantityModal={() => {}}
            toggleShowInvoicePrompt={() => {}}
            type="edit"
            user={user}
            getCartTotalAmount={this.getCartAmount}
            invoicePrompt={true}
            visible={showEditInvoice}
            toggleShowCashForm={() => {}}
            onCancel={this.toggleEditInvoiceModal}
            customerValue={customerValue}
            customerName={customerName}
            onCustomerSearch={this.handleCustomerSearchFieldChange}
            customers={customers}
            cleanupSelectCustomerDropdown={() => {}}
            handleSaveInvoiceDone={() => {}}
            invoiceId={invoice.id}
            invoice={invoice}
            handleLoading={this.handleLoading}
            customer={invoice.customer}
            toggleDeleteInvoiceModal={this.toggleDeleteInvoiceModal}
            hasDiscount={invoice && invoice.hasDiscount}
            discountAmount={invoice && invoice.discountAmount}
            fetchInvoices={() => begin && end ? this.fetchInvoicesByDate() : this.fetchInvoices()}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  customers: state.customer.allCustomers,
  allBranches: state.branch.allBranches,
  products: state.product.allProducts,
});

export default connect(mapStateToProps, {
  getAllInvoices,
  sendInvoiceToCustomerAsMail,
  getDownloadableInvoiceLink,
  getAllCustomers,
  updateProductItemInStore,
  getPaymentMessage,
  sendReceipt,
  getDownloadableReceiptLink,
  getAllBusinessBranches,
  getAllInvoicesByDates,
  getAllInvoicesByCustomerName
})(Invoices);
