import React, { act } from "react";
import { Modal, Input, message, Tabs, Button, Badge, Tooltip } from "antd";
import { Form, Icon } from "@ant-design/compatible";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import {
  addNewBusinessBranch,
  getAllBusinessBranches,
} from "../../../../../../redux/actions/branchActions";
import strings from "../../../../../../strings";
import PaymentModal from "../paymentModal";
import "./index.scss"

const FormItem = Form.Item;

class AddNewBranchModal extends React.Component {
  state = {
    loading: false,
    openPaymentModal: false,
    activeKey: 1,
    multipleBranchDetails: []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ loading: false });
    }
  }

  handleAddBranch = () => {
    const form = this.props.form;
    const { toggleModal } = this.props;
    const { activeKey, multipleBranchDetails } = this.state;

    if( activeKey === 1){
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
  
        this.setState({ loading: true });
        this.props.addNewBusinessBranch(values).then(res => {
          if (res.status && res.status === 200) {
            this.props.form.resetFields();
  
            Modal.success({
              title: strings.youSuccessfullyAddedNewBranch,
            });
  
            this.setState({ loading: false });
            this.props.getAllBusinessBranches();
            this.props.getBusinessBranchSub();
            toggleModal();
          }
        });
      });
    }else{
      this.setState({ loading: true });
      multipleBranchDetails.map((branch, index) => {
        this.props.addNewBusinessBranch(branch).then(res => {
          if(index+1 === multipleBranchDetails.length){
            if (res.status && res.status === 200) {
              this.props.form.resetFields();
    
              Modal.success({
                title: strings.youSuccessfullyAddedNewBranches,
              });
    
              this.setState({ loading: false });
              this.props.getAllBusinessBranches();
              this.props.getBusinessBranchSub();
              toggleModal();
            }
          }
          
        });
      })
    }
    
  };

  handleRemoveBranch = (name) => {
    const { multipleBranchDetails } = this.state;
    this.setState(state => {
      return {
        multipleBranchDetails: [...multipleBranchDetails.filter(item => item.name !== name )]
      }
    })
  }

  displayBranches = () => {
    const { multipleBranchDetails } = this.state;

    if (multipleBranchDetails.length === 0) return "Fill the form above to start adding branches" + "...";
    return multipleBranchDetails.map((branch, key) => {
      return (
        <Badge
          key={key}
          count={null}
          style={{
            backgroundColor: "#D90068",
            color: "#fffff",
            marginBottom: "10px",
          }}
        >
          <Tooltip title={`name:- ${branch.name}`}>
            <div className="variant">
              <span>{branch.name}</span>
              
              <span
                className="btn delete-variant-btn"
                onClick={() => {
                  this.handleRemoveBranch(branch.name);
                }}
              >
                x
              </span>
            </div>
          </Tooltip>
        </Badge>
      );
    });
  };

  openPriceModal = () => {
    const form = this.props.form;
    const { validateFields } = form;
    if(this.state.activeKey === 1){
      validateFields((err, value)=>{
        if (err) {
          return Modal.error({ title: "Please fill all fields" });
        }
        this.setState({ openPaymentModal: true }); 
      });
    }else{
      this.setState({ openPaymentModal: true }); 
    }
    
  };

  handleAddMultiBranches = () => {
    const { getFieldsValue, setFieldsValue } = this.props.form;

    const { name, address_line1, address_line2} = getFieldsValue()

    const isBranchExist = this.state.multipleBranchDetails.filter(item => item.name === name);

    if (isBranchExist.length > 0) return message.warn("Branch with this name has been selected")
    this.setState(state => {
      return {
        multipleBranchDetails: [...state.multipleBranchDetails, {name, address_line1, address_line2}]
      }
    });

    setFieldsValue({
      name: "",
      address_line1: "",
      address_line2: ""
    })

  }


  tabIems = [
    {key: 1, label: "Add single branch"},
    {key: 2, label: "Add multiple branches"}
  ]

  render() {
    const {
      visible,
      form: { getFieldDecorator },
      toggleModal,
      branchPrice,
      ecommercePrice,
      user,
      branchPaidFor, 
      branches
    } = this.props;

    const { loading, openPaymentModal, activeKey, multipleBranchDetails } = this.state;

    const total = activeKey === 1 ? Number(branchPrice) : (Number(branchPrice) * multipleBranchDetails.length )

    return (
      <>
        <Modal
          open={visible}
          title={strings.addNewBranch}
          okText={strings.addBranch}
          onOk={()=>{
            if(branches.length >= (Number(branchPaidFor) + 1)){
              this.openPriceModal();
            }else{
              this.handleAddBranch();
            }
          }}
          footer={[
            <Button
              htmlType="button"
              onClick={toggleModal}
            >
              Cancel
            </Button>,
            activeKey === 2 && <Button
              type="primary"
              htmlType="button"
              onClick={this.handleAddMultiBranches}
            >
              {strings.addBranch}
            </Button>,
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              disabled={activeKey===2 && multipleBranchDetails.length === 0}
              onClick={() => {
                if(branches.length >= (Number(branchPaidFor) + 1)){
                  this.openPriceModal();
                }else{
                  this.handleAddBranch();
                }
              }}
            >
              {strings.createBranch}
            </Button>,
          ]}
          onCancel={toggleModal}
          // okButtonProps={{ loading: loading, disabled:  }}
          destroyOnClose
        >
          <div style={{display: "flex", flexDirection: "column", gap: "2px"}}>
            <p style={{margin: 0}}>
              {strings.additionalBranchWillIncur}{" "}
              <strong>
                {user.currency}
                {branchPrice} {strings.perBranch}.
              </strong>
            </p>
            <p style={{margin: 0}}>
              {strings.ecommerceBranchCosts}{" "}
              <strong>
                {user.currency}
                {ecommercePrice}
              </strong>
              .
            </p>
          </div>

          <Tabs 
            items={this.tabIems}
            activeKey={activeKey}
            onChange={(value) => this.setState({ activeKey: value })}
          />

          <Form layout="vertical" >
            {/* Branch Email Input */}
           
            <FormItem>
              {getFieldDecorator("name", {
                rules: [
                  {
                    message: strings.pleaseEnterBranchName,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={strings.enterBranchName}
                />
              )}
            </FormItem>
            {/* Branch Username Input */}
            <FormItem>
              {getFieldDecorator("address_line1", {
                rules: [
                  {
                    message: strings.pleaseEnterBranchAddress1,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon
                      type="environment"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  placeholder={strings.enterBranchAddress1}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator("address_line2", {
                rules: [
                  {
                    message: strings.pleaseEnterBranchAddress2,
                    required: true,
                  },
                ],
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon
                      type="environment"
                      style={{ color: "rgba(0,0,0,.25)" }}
                    />
                  }
                  placeholder={strings.enterBranchAddress2}
                />
              )}
            </FormItem>

            {activeKey === 2 && <div className="variant-container" style={{marginTop: 20}}>
              {this.displayBranches()}
            </div>}
          </Form>
        </Modal>
        { openPaymentModal && 
        <PaymentModal 
          open={openPaymentModal}
          onCancel={()=>this.setState({ openPaymentModal: false })}
          title={`${user.currency}${Number(branchPrice)}`}
          total={total}
          paymentDescription="How would you like to pay"
          onPay={this.handleAddBranch}
        />
      }
      </>
      
    );
  }
}

const AddNewBranchForm = Form.create()(AddNewBranchModal);

AddNewBranchForm.propTypes = {
  error: PropTypes.string.isRequired,
  addNewBusinessBranch: PropTypes.func.isRequired,
  getAllBusinessBranches: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  error: state.error,
});

export default connect(mapStateToProps, {
  addNewBusinessBranch,
  getAllBusinessBranches,
})(AddNewBranchForm);
