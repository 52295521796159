import axios from "axios";
import store from "../redux/store";
import { GET_ERRORS, RESET_ERROR } from "../redux/constants";
import Auth from "./Auth";
import Sales from "./Sales";
import Product from "./Product";
import Customer from "./Customer";
import Branch from "./Branch";
import Category from "./Category";
import Loyalty from "./Loyalty";
import Invoice from "./Invoice";
import BirthdayOffers from "./BirthdayOffers";
import Notification from "./Notification";
import Merchants from "./Merchants";
import ProductSupplies from "./ProductSupplies";
import Orders from "./Orders";
import Credley from "./Credley"
import PurchaseOrders from "./PurchaseOrder";
import { Modal } from "antd";
import { useApolloClient } from "@apollo/client";
import { signoutUser } from "../redux/actions/authActions";

const axiosInstance = axios.create({ 
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// const client = useApolloClient();
// const signOu

//Intercept For Error reset
axiosInstance.interceptors.request.use(
  function(config) {
    // Reset error state before make a fresh API call
    store.dispatch({
      type: RESET_ERROR,
    });

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

//Intercept For Errors
axiosInstance.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    console.log({ error });
    // Do something with response error
    const errorResponse = error.response;

    // console.log("interceptoe error", error, errorResponse);
    // if(errorResponse.status == 401){
    //   Modal.error({
    //     title: "You current token has expire, logging out....."
    //   })

    //   signoutUser();
    // }
    // const errorRequest = error.request;

    // errorResponse &&
    //   store.dispatch({
    //     type: GET_ERRORS,
    //     payload: {
    //       message: errorResponse
    //         ? errorResponse.data && Array.isArray(errorResponse.data.errors)
    //           ? errorResponse.data.errors[0]
    //           : (errorResponse.data.message || errorResponse.data.error)
    //         : (errorResponse.data.errors),
    //       fullMessage: errorResponse
    //         ? errorResponse.data &&
    //           Array.isArray(errorResponse.data.full_messages)
    //           ? errorResponse.data.full_messages[0]
    //           : errorResponse.data.full_messages
    //         : error.message,
    //       data:
    //         errorResponse.data && typeof errorResponse.data === "string"
    //           ? errorResponse.data
    //           : null,
    //       statusText: errorResponse && errorResponse.statusText,
    //       errors: errorResponse
    //         ? (errorResponse.data.errors || errorResponse.data.error) &&
    //           (errorResponse.data.errors?.messages ||
    //             errorResponse.data.error?.messages) &&
    //           (errorResponse.data.errors?.messages ||
    //             errorResponse.data.error?.messages)
    //         : errorResponse.data.errors || errorResponse.data.error,
    //     },
    //   });

    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth: new Auth(axiosInstance),
  sales: new Sales(axiosInstance),
  branch: new Branch(axiosInstance),
  product: new Product(axiosInstance),
  loyalty: new Loyalty(axiosInstance),
  customer: new Customer(axiosInstance),
  category: new Category(axiosInstance),
  invoice: new Invoice(axiosInstance),
  notification: new Notification(axiosInstance),
  birthdayOffers: new BirthdayOffers(axiosInstance),
  merchants: new Merchants(axiosInstance),
  productSupplies: new ProductSupplies(axiosInstance),
  orders: new Orders(axiosInstance),
  credley: new Credley(axiosInstance),
  purchaseOrder: new PurchaseOrders(axiosInstance),
  HttpClient: axiosInstance,
};
