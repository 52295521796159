export const stringTranslations = {
  English: {
    cashier: "cashier",
    manager: "manager",
    owner: "owner",
    online: "online",
    offline: "offline",
    changePassword: "Change Password",
    currentPasswordMessage: "Please enter your current password",
    passwordMessage: "Please enter your password",
    currentPassword: "Current Password",
    password: "password",
    confirmPasswordMessage: "Please confirm your password!",
    confirmPassword: "Confirm Password",
    sendViaEmail: "Send Via Email",
    sendViaWhatsapp: "Send Via WhatsApp",
    downloadPdf: "Download PDF",
    paid: "paid",
    unpaid: "unpaid",
    partial: "partial",
    closeInvoice: "Do you want to close the Invoice?",
    closeInvoiceConfirmation: "Invoice may not be saved. Do you want to close?",
    yes: "yes",
    no: "no",
    invoice: "Invoice",
    wasDeducted: "was deducted",
    for: "for",
    item: "Item",
    addProduct: "Add Product",
    paymentReference: "Payment Reference",
    amountPaid: "Amount Paid",
    discountAmount: "Discount Amount",
    amountDue: "Amount Due",
    amount: "Amount",
    dueDate: "Due Date",
    paymentType: "Payment Type",
    card: "Card",
    cash: "Cash",
    bankTransfer: "Offline Bank Transfer",
    paymentMessage: "Payment Message",
    description: "Description",
    sendReceipt: "Send Receipt",
    delete: "Delete",
    save: "Save",
    resend: "Re-Send",
    saveAndSend: "Save & Send",
    invoiceSaved: "Invoice saved!",
    selectPaymentMethod: "Please select a Payment Method!",
    selectCustomer: "Please select a Customer!",
    cartEmptyError:
      "Cart List can not be empty, close the invoice and add Item to cart!",
    subscriptionExpired:
      "Your subscription has expired and your account is now limited. Click on the Button below to renew your account",
    renew: "Renew",
    holdOn: "Hold On",
    customerBank: "Customer Bank",
    cancel: "Cancel",
    selectACustomer: "Select Customer",
    invoiceSuccessfulPdfError:
      "Invoice created successfully but PDF Generation is taking longer than usual. Please check back shortly.",
    downloadingInvoice: "Downloading Invoice",
    downloadingReceipt: "Downloading Receipt",
    whatsappReceiptError:
      "An error occured sending the receipt via WhatsApp, Please try again.",
    receiptToWhatsapp: "Receipt forwarded to WhatsApp",
    thankYouForPatronage: "Thank you for your patronage",
    hereIsYourReceipt: "Here is your payment receipt",
    errorSendingEmailReceipt:
      "An error occured sending the receipt via e-mail, please try again or contact support",
    receiptSentToEmail: "Receipt has been sent to customer's email",
    invoiceSentToEmail: "Invoice has been sent to customer's email",
    invoiceSuccessWhatsappError:
      "Invoice created successfully but an error occured sending to WhatsApp. Try again on the invoice list",
    invoiceSuccessfulEmailError:
      "Invoice created successfully but an error occured while sending as e-mail. Please try again from the invoice list",
    invoiceSentToWhatsapp: "Invoice forwarded to WhatsApp",
    hello: "Hello",
    pleaseDownloadInvoice: "Please download invoice",
    pleaseDownloadReceipt: "Please download receipt",
    from: "from",
    email: "Email",
    upgrade: "Upgrade",
    youAreOnFreePlan: "You are on a Free Plan.",
    clickOn: "Click on",
    yourPlanInFewSteps: " your subscription in a few quick steps.",
    to: "to",
    yourSubscriptionHasExpired:
      "Your subscription has expired and your account is now limited.",
    days: "days",
    yourSubscriptionExpiresIn: "Your Loystar subscription expires in",
    createAcount: "Create an Account",
    signIn: "Sign In",
    continue: "Continue",
    enterOtp: "Enter OTP PIN",
    enterValidOtp: "Please enter a valid PIN",
    pin: "PIN",
    tokenSentToMail: "A token has been sent to your email",
    passwordResetSuccessful: "Password reset was successful",
    somethingWentWrong: "Something went wrong!",
    resetPassword: "Reset Password",
    iHaveResetCode: "I have a password reset code",
    pleaseEnterEmail: "Please enter your email",
    aTokenWillBeSentToEmail: "A token would be sent to your email",
    enterEmail: "Enter your email",
    sendinYourToken: "Sending your token...",
    makeSureItMatchesPassword: "Make sure it matches your new password",
    pleaseChooseNewPassword: "Please choose a new password",
    chooseNewPassword: "Choose a new password",
    enterNewPassword: "Enter your new password to confirm",
    enterTokenSent: "Enter the token that was sent your mail",
    resetToken: "Reset Token",
    resettingPassword: "Resetting your Password...",
    signUp: "Sign Up",
    adminSignInWithEmail:
      " Admin signs in with email while Staff signs in with username.",
    pleaseEnterEmailOrUsername: "Please enter your email or username",
    emailOrUsername: "Email or Username",
    pleaseEnterPassword: "Please enter password",
    createAnAccount: "Create an Account",
    forgotPassword: "Forgot Password?",
    enterPhoneNumber: "Enter Phone Number",
    personalData: "Personal Data",
    validateConfirmationCOde: "Validate Confirmation Code",
    pleaseEnterFirstName: "Please enter your first name",
    pleaseEnterPhoneNumber: "Please enter your phone number",
    pleaseEnterLastName: "Please enter your last name",
    pleaseEnterBusinessName: "Please enter your business name",
    firstName: "First Name",
    lastName: "Last Name",
    businessName: "Business Name",
    previous: "Previous",
    next: "Next",
    pleaseSelectBusinessCategory: "Please select your business category",
    pleaseEnterValidEmail: "Please enter a valid email",
    pleaseEnterPostCode: "Please enter post code",
    postCode: "Post Code",
    phoneNumberInUse: "This phone number is in use already!",
    emailInUse: "This email is in use already!",
    foodAndDrinks: "Food and Drinks",
    salonAndBeauty: "Salon and Beauty",
    fashionAndAccessories: "Fashion and Accessories",
    gymAndFitness: "Gym and Fitness",
    travelAndHotel: "Travel and Hotel",
    homeAndGifts: "Home and Gifts",
    washingAndCleaning: "Washing and Cleaning",
    gadgetsAndElectronics: "Gadgets and Electronics",
    groceries: "Groceries",
    others: "Others",
    photography: "Photography",
    bookstore: "Bookstore",
    healthcare: "Healthcare",
    submit: "Submit",
    accountCreatedSuccessful: "Your account was created successfully.",
    pleaseEnterAddress: "Please Enter Your Address",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    choosePassword: "Choose Password",
    passwordMustBe6Characters: "Password must be at least 6 characters.",
    howDidYouHearLoystar: "How did you hear about Loystar?",
    referralCode: "referral Code",
    byClickingTheButton: " By clicking the button below, you agree to the",
    termsAndSevice: "Terms & Service, Privacy & Data Protection Policy",
    wereCreatingAccount: "We're creating your account",
    proceed: "Proceed",
    verificationCodeMustBe6: "Verification Code must be 6 digits",
    pleaseEnter6DigitCode: "Please Enter 6 digit code",
    enterVerificationCode: "Enter Verification Code",
    resendToken: "Resend Token",
    verify: "Verify",
    transactions: "Transactions",
    todaySales: "Today's Sales",
    salesHistory: "Sales History",
    supplyHistory: "Supply History",
    new: "New",
    invoices: "Invoices",
    disbursements: "Disbursements",
    offlineSales: "Offline Sales",
    products: "Products",
    customers: "Customers",
    multiLevelLoyalty: "Multi-Level Loyalty",
    loyaltyPrograms: "Loyalty Programs",
    members: "Members",
    appStore: "App Store",
    orderMenu: "Order Menu",
    settings: "Settings",
    staffAndBranches: "Staffs and Branches",
    myAccount: "My Account",
    switchToSellMode: "Switch to Sell Mode",
    signOut: "Sign Out",
    getFreeSubscription: "Get Free Subscription",
    onlyNumbersAllowed: "Only numbers are allowed",
    yourAccountMustBe10Digits: "your account number must be a 10 digit number",
    yourBvnMustBe11: "your BVN must be a 11 digit number",
    pleaseSelectBank: "Please select your bank",
    selectYourBank: "Select your Bank",
    enterBankAccountNumber: "Enter Bank Account Number",
    enterBvnNumber: "Enter your BVN",
    connectBankAccount: "Connect Bank Account",
    passwordResetSuccessfulAndSignOut:
      "Your Password has been reset sucessfully. Click on the Sign Out button below to signin again",
    enterCurrentPassword: "Enter current password",
    pleaseEnterCurrentPassword: "Please enter current password",
    phoneNumber: "Phone Number",
    sex: "Sex",
    dateOfBirth: "Date of Birth",
    state: "State",
    country: "Country",
    loyaltyId: "Loyalty ID",
    createdAt: "Created At",
    noOfTransactionsMade: "No. of transactions made",
    yourDownloadWillStart: "Your download will start in a moment",
    exportCustomers: "Export Customers",
    youhaveSuccessfullyToppedUp:
      "Your have successfully topped up your SMS Units.",
    youNowHave: "You now have",
    smsUnits: "SMS Units",
    enterNumberOfSmsUnits: "Enter Number of SMS Units You Intend to Purchase",
    pleaseEnterANumericValue: "Please enter a numeric value",
    pay: "Pay",
    accountEditedSuccessfully: "Your account was edited successfully.",
    youAeCurrentlyOn: "You're currently on",
    plan: "Plan",
    userData: "User Data",
    businessData: "BUSINESS DATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Please select your currency",
    selectYourCurrency: "Select your currency",
    purchaseMoreSmsUnits: "Purchase more SMS Units using the form below",
    youHave: "You have",
    atLeast4SmsUnitsRrequired:
      "At least 4 sms units is required for verification, please top up!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Please verify your bank account to comply with the 'Know Your Customer' (KYC) requirements. This will allow you collect payments via USSD or instant transfer, receive orders from customers and process transaction settlement. Applicable to Nigerian merchants only. Please press the button below to begin.",
    reConnectBankAccount: "Re-connect Bank Account",
    accountName: "Account Name",
    accountNumber: "Account Number",
    bankName: "Bank Name",
    verified: "Verified",
    accountDetails: "Account Details",
    kycBankAccount: "KYC & Bank Account",
    createTier: "Create Tier",
    fileUploadSuccessful: "file uploaded successfully",
    fileUploadFailed: "file upload failed",
    createLoyaltyProgram: "Create a Loyalty Program",
    createLoyalty: "Create Loyalty",
    name: "Name",
    loyaltyArtwork: "Loyalty Artwork",
    clickToUpload: "Click to upload",
    amountToPointsRatio: "Amount to Points Ratio",
    points: "Points",
    recommendedAmountToPointRatio:
      "Recommended : 1 to 1 point. i.e for every 1 currency spent, your customers get 1 point",
    pleaseTypeIn: "Please type in ",
    toDeleteLoyalty: "to delete this loyalty",
    deleteLoyalty: "Delete Loyalty",
    toConfirm: " to confirm",
    edit: "Edit",
    pointsAwardedSuccessfully: "Points Awarded Successfully.",
    enterPointValueToAward:
      "Enter Point Value You Would like to Award Customers",
    award: "Award",
    awardPointValuesToCustomer: "Award Point Value to Customer",
    enrollMembersToLoyalty: "Enroll Members onto Loyalty",
    awardPoints: "Award Points",
    enroll: "Enroll",
    home: "Home",
    loyalty: "Loyalty",
    enrollCustomers: "Enroll Customers",
    selected: "Selected",
    customer: "Customer",
    loyaltyActivationSuccessful: "Loyalty Activation is successful.",
    loyaltyDeactivationSuccessful: "Loyalty Deactivation is successful.",
    viewTier: "View Tier",
    deactivate: "De-Activate",
    activate: "Activate",
    actions: "Actions",
    nameOfLoyalty: "Name of Loyalty",
    loyaltyStatus: "Loyalty StatusLoyalty Status",
    numberOfTiers: "Number of Tiers",
    createdOn: "Created On",
    createATier: "Create a Tier",
    stopCreatingTierConfirmation: "Do you want to stop creating a tier?",
    stopEditingTierConfirmation: "Do you want to stop editing this tier?",
    nameOfTier: "Name of Tier",
    minimumSpendingTarget: "Minimum Spending Target",
    maximumSpendingTarget: "Maximum Spending Target",
    minimumSpendingTargetRequired: "minimum spending target is requiured",
    maximumSpendingTargetRequired: "maximum spending target is requiured",
    rewardSponsor: "Reward Sponsor",
    pleaseChooseARewardSponsor: "Please choose a reward sponsor",
    self: "Self",
    partner: "Partner",
    rewardPartner: "Reward Partner",
    pleaseSelectRewardPartner: "Please select your reward partner",
    rewards: "Rewards",
    pleaseSelectAReward: "Please select a reward",
    instructionsOnRedeemingReward:
      "Instructions on How Customer Should Redeem Reward",
    pleaseFillInThisField: "Please Fill in this Field!",
    toDeleteThisTier: " to delete this tier",
    deleteTier: "Delete Tier",
    viewMembers: "View Members",
    membersEnrolled: "Members Enrolled",
    instruction: "Instruction",
    membersIn: "Members in",
    availableTiersInLoyalty: "Available Tier(s) in Loyalty Program",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Available Loyalty Programme",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Member Details",
    nameOfCustomer: "Name of Customer",
    address: "Address",
    allEnrolledMembers: "All Enrolled Members",
    thisIsToWishYouHappyBirthday:
      "This is to wishing you a very happy birthday and prosperous life. Thanks for all that you are to Loyster. Happy Celebrations!",
    inputAnOfferPlease: "Input an Offer please",
    pleaseSelectTheInsertPoint:
      "Please select the insert point in the message and click again",
    birthdayOfferAndMessage: "Birthday Offer and Message",
    birthdayOffer: "Birthday Offer",
    birthdayMessage: "Birthday Message",
    noOfferFound: "No offer found",
    settingABirthdayOffer:
      "Setting a birthday offer allows customers to receive this offer via SMS on their birthday",
    createOffer: "Create Offer",
    whatIsTheOffer: "What is the offer?",
    editMessage: "Edit Message",
    insert: "Insert",
    theNameOfCustomerInserted: "The name of the customer will be inserted here",
    theBirtdayOfferInserted: "The birthday offer will be inserted here",
    youSuccessfullyAddedNewBranch: "You've successfully added a new branch!",
    addNewBranch: "Add New Branch",
    addBranch: "Add Branch",
    additionalBranchWillIncur: "Additional Branch would incur",
    perBranch: "per branch",
    ecommerceBranchCosts: "Ecommerce Branch costs",
    pleaseEnterBranchName: "Please enter the branch name",
    pleaseEnterBranchAddress1: "Please enter the branch's address line 1",
    enterBranchAddress1: "Enter the branch's address line 1",
    enterBranchAddress2: "Enter the branch's address line 1",
    pleaseEnterBranchAddress2: "Please enter the branch's address line 2",
    enterBranchName: "Enter the branch name",
    successfullyAddedStaff: "You've successfully added a new staff!",
    addNewStaff: "Add New Staff",
    addStaff: "Add Staff",
    additionalStaffWillIncur: "Additional Staff would incur",
    perStaff: "per staff.",
    pleaseEnterStaffEmail: "Please enter the staff's email",
    pleaseEnterStaffUsername: "Please enter the staff's username",
    pleaseEnterStaffPassword: "Please enter the staff's password",
    pleaseSelectStaffRole: "Please select the staff's role",
    selectStaffRole: "Select the staff's role",
    enterStaffEmail: "Enter the staff's email",
    enterStaffUsername: "Enter the staff's username",
    enterStaffPassword: "Enter the staff's password",
    spacesNotAllowedInUsername: "spaces not allowed in username",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff: "Please select business to attach staff",
    searchForBranchToAttachStaff: "Search for branch to attach staff",
    username: "Username",
    role: "Role",
    areYouSureToDeleteThis: "Are you sure to delete this",
    branches: "Branches",
    upgradeYourPlan: "Upgrade your Plan.",
    add: "ADD",
    addNew: "Add New",
    customerWithEmailAlreadyExists:
      "Customer with email/phone number already exists!",
    successfullyAddedNewCustomer: "You've successfully added a new customer!",
    addCustomer: "Add Customer",
    pleaseEnterCustomerFirstName: "Please enter customer's first name",
    pleaseEnterCustomerLastName: "Please enter customer's last name",
    pleaseEnterCustomerPhoneNumber: "Please enter customer's phone number",
    pleaseEnterCustomerEmail: "Please enter customer's email",
    pleaseEnterCustomerAddressLine: "Please enter customer's Address Line",
    pleaseEnterCustomerOtherAddress: "Please enter customer's other Address",
    pleaseSelectCustomerGender: "Please select customer's gender",
    gender: "Gender",
    male: "Male",
    female: "Female",
    bank: "Bank",
    selectBank: "Select Bank",
    stateOrRegionOrProvince: "State/Region/Province",
    customerNotes: "Customer Notes",
    sendSms: "Send SMS",
    editCustomer: "Edit Customer",
    redeemReward: "Redeem Reward",
    issueLoyaltyCard: "Issue Loyalty Card",
    deleteCustomer: "Delete Customer",
    youveSuccessfullyAssignedLoyaltyMembership:
      "You've successfully Assigned a Loyalty Membership ID",
    noMembershipIdAvailable:
      "No membership IDs available. Please contact us on hello@loystar.co",
    sendEmail: "Send Email",
    membershipPoints: "Membership Points",
    customerDetails: "Customer Details",
    close: "Close",
    loyaltyBalance: "Loyalty Balance",
    pointsBalance: "Points Balance",
    starBalance: "Star Balance",
    requiredPoints: "Required Points",
    requiredStars: "Required Stars",
    reddemCode: "Redeem Code",
    toDeleteThisCustomer: "to delete this customer",
    customerHasBeenDeletedSuccessfully:
      "Customer has been deleted successfully!",
    customerWithPhoneAlreadyExists: "Customer with phone number already exists",
    customerWasSuccessfullyEdited: "Customer was successfully edited",
    anErrorOccured: "An error occured",
    phoneNumberIncludeCountry: "Phone Number (Include Country Code)",
    yourFileQueuedForUpload:
      "Your file has been queued for upload. Please refresh the page after a few seconds.",
    thereWasAnErrorPerformingOperation:
      "There was an error performing the operation!",
    pleaseSelectFile: "Please Select a File!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Only CSV files allowed. Please upload a .csv file.",
    fileShouldBeSmallerThan5Mb:
      "File should be smaller than 5MB. If you have a larger file, please email support@loystar.co. Thank you",
    customerFirstNameIsRequired: "customer first name is required on row",
    customerPhoneNumberIsRequired: "customer phone number is required on row",
    importCustomers: "Import Customers",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Click this icon to download the CSV file template.",
    getGoogleSheetVersion: "Get the Google sheet version",
    clickOrDragFileToUpload: "Click or drag file to this area to upload",
    missingOrInvalidColumnHeader:
      "Missing or invalid column header. Please follow the template format. Thank you.",
    youHaveImported: "You Have Imported",
    youSuccessfullyRedeemedReward: "You've successfully redeemed your reward!",
    sixDigitCode: "Six digit code",
    pleaseEnterCustomerRewardCode: "Please enter customer's reward code",
    enterRewardCode: "Enter reward code.(The reward code is case sensitive)",
    selectLoyaltyProgram: "Select loyalty program",
    stamps: "Stamps",
    smsUnitsLow: "SMS Units Low",
    pleaseTopUpSmsUnits: "Please Top Up SMS Units",
    smsSuccessfullySentForDelivery: "SMS successfully sent for delivery!",
    sendSmsTo: "Send SMS to",
    allCustomers: "All Customers",
    unitsAvailable: "Units Available",
    pleaseTypeInTheMessage: "Please type in the message",
    message: "Message",
    charactersRemaining: "characters remaining",
    avoidUseOfSpecialCharacters:
      "Avoid the use of Special Characters and Emojis to conserve SMS Units.",
    note: "Note",
    errorFetchingCustomersMultilevelDetail:
      "Error Fetching Customers MultiLevel Details",
    search: "Search",
    reset: "Reset",
    importCustomer: "Import Customer",
    addNewCustomer: "Add New Customer",
    sendSmsBroadcast: "Send SMS Broadcast",
    totalCustomers: "TOTAL CUSTOMERS",
    disbursementDetails: "Disbursement Details",
    paidBy: "Paid By",
    disbursed: "Disbursed",
    bankAccountName: "Bank Account Name",
    bankAccountNumber: "Bank Account Number",
    transferInitiated: "Transfer Initiated",
    transferCompleted: "Transfer Completed",
    pleaseEnterAValid: "Please enter a valid",
    begin: "begin",
    end: "end",
    date: "date",
    paymentDate: "Payment Date",
    selectDisbursementDuration: "Select Disbursement Duration",
    totalSettled: "Total Settled",
    totalUnsettled: "Total Unsettled",
    toDeleteThisSale: "to delete this sale",
    draftSaleDeletedSuccessfully: "Draft Sale has been deleted successfully!",
    deleteSale: "Delete Sale",
    pleaseTypeInYourTotal: "Please type in your total to confirm deletion",
    billDetails: "Bill Details",
    printBill: "Print Bill",
    servedBy: "Served By",
    total: "Total",
    createdDate: "Created Date",
    createdTime: "Created Time",
    status: "Status",
    loginSuccessful: "Login Successful",
    pleaseWaitWhileWeConnectAccount:
      "Please wait while we connect your account",
    connectionFailedTryAgain: "Connection failed. Please try again.",
    connectionSuccessful: "Connection Successful",
    viewDetails: "View Details",
    enable: "Enable",
    free: "Free",
    cost: "Cost",
    visitWebsite: "Visit Website",
    pleaseUpgradeYourPlanToPro:
      "Please Upgrade your plan to Pro Plus to enable this app",
    connectYourBankAccount:
      "Connect your Bank Account to be able to receive payments.",
    disable: "Disable",
    enableApp: "Enable App",
    addNewProductToInvoice: "Add New Product to Invoice",
    toDeleteThisInvoice: "to delete this Invoice",
    invoiceDeletedSuccessfully: "Invoice has been deleted successfully!",
    deleteInvoice: "Delete Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "You've successfully added an Invoice payment message!",
    pleaseEnterPaymentInstructions: "Please enter payment instructions",
    invoiceId: "Invoice ID",
    paidDate: "Paid Date",
    reference: "Reference",
    productAdded: "Product added",
    productOutOfStock: "Product out of stock. Please re-stock.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "TOTAL PAID INVOICES",
    totalUnpaidInvoices: "TOTAL UNPAID INVOICES",
    loyaltyProgramDeleted: "Loyalty Program Deleted",
    thisLoyaltyProgramDeletedSuccessfully:
      "This loyalty program has been deleted successfully",
    thisLoyaltyProgramEditedSuccessfully:
      "This loyalty program has been edited successfully",
    loyaltyProgramAddedSuccessfully:
      "Loyalty program has been added successfully",
    loyaltyProgramEdited: "Loyalty Program Edited",
    loyaltyProgramAdded: "Loyalty Program Added",
    loyaltyDetails: "Loyalty Details",
    doYouWantToCloseDialog: "Do you want to close this dialog?",
    pleaseEnterLoyaltyName: "Please enter the name of your loyalty",
    programType: "Program Type",
    pleaseSelectProgramType: "Please select a program type",
    simplePoints: "Simple Points",
    stampsProgram: "Stamps Program",
    threshold: "Threshold",
    pleaseEnterLoyaltyThreshold: "Please enter the loyalty threshold",
    reward: "Reward",
    pleaseEnterLoyaltyReward: "Please enter the reward of the loyalty",
    totalUserPoints: "Total User Points",
    totalUserStamps: "Total User Stamps",
    spendingTarget: "Spending target",
    spendingTargetHint1:
      "Spending target is how much a customer must spend to earn the reward. 1 currency value = 1 point.",
    spendingTargetHint2:
      "Stamps target is how many stamps a customer must collect to earn the reward. eg. 5",
    addNewLoyaltyProgram: "Add New Loyalty Program",
    addLoyaltyProgram: "Add Loyalty Program",
    loyaltyProgramType: "Loyalty Program Type",
    pleaseSelectLoyaltyProgramType: "Please select loyalty program type",
    nameOfProgram: "Name of program",
    pleaseEnterProgramName: "Please enter program name",
    whatIsTheReward: "What is the reward?",
    egNextPurchaseIsFree: "E.g. Next purchase is free",
    customerName: "Customer Name",
    guestCustomer: "Guest Customer",
    orderConfirmedSuccessfully: "Order confirmed successfully",
    orderCancelledSuccessfully: "Order cancelled successfully",
    confirmOrder: "Confirm order",
    cancelOrder: "Cancel order",
    allOrders: "All Orders",
    totalOrders: "TOTAL ORDERS",
    doYouWantToAcceptOrder: "Do you want to accept this order?",
    doYouWantToCancelOrder: "Do you want to cancel this order?",
    orderDetails: "Order Details",
    orderCreatedAt: "Order created at",
    supplier: "Supplier",
    productName: "Product Name",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    receivedBy: "Received By",
    reportFrom: "Report from",
    totalSupplies: "Total Supplies",
    allRightsReserved: "All Rights Reserved",
    toDeleteThisTransaction: "to delete this Transaction",
    transactionDeletedSuccessfully:
      "Transaction has been deleted successfully. Stock has been returned to inventory.",
    deleteTransaction: "Delete Transaction",
    transactionDetails: "Transaction Details",
    printReceipt: "Print Receipt",
    channel: "Channel",
    discount: "Discount",
    profit: "Profit",
    discountedSales: "Discounted Sales",
    errorFetchingRecord: "Error Fetching Record",
    exportTransactions: "Export Transactions",
    errorFetchingSalesRecord: "Error fetching Sales record for Export.",
    totalSellingPrice: "Total Selling Price",
    totalCostPrice: "Total Cost Price",
    appliedDiscount: "Applied Discount",
    noOfItems: "No. of Items",
    sales: "Sales",
    export: "Export",
    totalProfit: "Total Profit",
    totalBalanceInPeriod: "Total Balance in Period",
    allTimeSales: "All Time Sales",
    records: "Records",
    todaysSales: "Today's Sales",
    transaction: "transaction",
    youHaveExceededTotalNumberOfProducts:
      "You have exceeded the total number of products allowed on your plan. Upgrade your plan to enjoy a higher a limit.",
    youNeedToHaveLoyaltyProgram:
      "You Need to have a Loyalty Program to use this feature!",
    price: "Price",
    category: "Category",
    stockTracking: "Stock Tracking",
    stockCount: "Stock Count",
    taxed: "Taxed",
    originalPrice: "Original Price",
    costPrice: "Cost Price",
    unit: "Unit",
    productImage: "Product Image",
    taxRate: "Tax Rate",
    taxType: "Tax Type",
    trackInventory: "Track Inventory",
    variants: "Variants",
    hasVariants: "Has Variants",
    importProduct: "Import Product",
    exportProducts: "Export Products",
    addNewProduct: "Add New Product",
    viewCategory: "View Category",
    viewSuppliers: "View Suppliers",
    receiveInventory: "Receive Inventory",
    printAllProductsTag: "Print All Products Tag",
    deleteAll: "Delete All",
    totalProducts: "TOTAL PRODUCTS",
    youveSuccessfullyAddedANewCategory:
      "You've successfully added a new category",
    addNewCategory: "Add New Category",
    addCategory: "Add Category",
    categoryName: "Category Name",
    pleaseEnterCategoryName: "Please enter category name",
    stampsTarget: "Stamps Target",
    sendInventory: "Send Inventory",
    productDetails: "Product Details",
    youveSuccessfullyEditedThisCategory:
      "You've successfully edited this category",
    update: "Update",
    categoryList: "Category List",
    categories: "Categories",
    enterQuantityToUpdate: "Enter a quantity to update",
    inventorySentSuccessfully: "The inventory was sent successfully!",
    updateInventory: "Update Inventory",
    currentQuantity: "Current Quantity",
    pleaseEnterQuantityToAdd: "Please enter the quantity you want to add",
    pleaseSelectABranch: "Please select a Branch",
    searchForBranch: "Search for branch",
    youCantSendMoreThanStock: "You can't send more than you have in stock",
    send: "Send",
    pleaseEnterQuantityToSend: "Please enter the quantity you want to send",
    productNameIsRequiredOnRow: "product name is required on row",
    productCategoryIsRequiredOnRow: "product category is required on row",
    productPriceIsRequiredOnRow: "product price is required on row",
    productUnitIsRequiredOnRow: "product unit is required on row",
    productQuantityIsRequiredOnRow: "product quantity is required on row",
    productVariantsRequireTracking: "product variants requires tracking!",
    pleaseAddVariantClickButton:
      "Please add a variant by clicking the add button!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Variants are more than selected product quantity, Pls reduce variant quantity",
    productEditedSuccessfully: "Product has been edited successfully!",
    fileTooLargeLessThan4Mb:
      "File size is too large! File size should be less than 4MB.",
    suchVariantAlreadyExist: "Such variant already exist",
    addVariants: "Add Variants",
    editProduct: "Edit Product",
    pleaseEnterProductName: "Please Enter Product Name",
    pleaseEnterProductPrice: "Please Enter Product Price",
    pleaseEnterProductOriginalPrice: "Please Enter Product Original Price",
    productDescription: "Product Description",
    selectProductCategory: "Select Product Category",
    pleaseSelectProductCategory: "Please select product category",
    productCostPrice: "Product Cost Price",
    productSellingPrice: "Product Selling Price",
    productOriginalPrice: "Product Original Price",
    maxFileSizeAllowedIs4Mb: "Max. File Size allowed is 4mb",
    productsWithPicturesArePublished:
      "Products with pictures are published on Discover to receive orders",
    shouldThisProductBeTracked: "Should this product be tracked?",
    pleaseSelectStockUnit: "Please select stock unit",
    stockUnit: "Stock Unit",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "CAN",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRE",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Please enter product quantity",
    productQuantity: "Product Quantity",
    isThisProductTaxed: "Is this product taxed?",
    selectTaxType: "Select Tax Type",
    pleaseSelectTaxType: "Please select Tax Type",
    progressive: "Progressive",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Please enter product tax rate",
    doesProductHaveVariants: "Does this product have variants?",
    type: "Type",
    value: "Value",
    pleaseEnterVariantType: "Please enter variant's type",
    pleaseEnterVariantValue: "Please enter variant's value",
    pleaseEnterVariantPrice: "Please enter variant's price",
    pleaseEnterVariantQuantity: "Please enter variant's quantity",
    productDeletedSuccessfully: "Product has been deleted successfully!",
    categoryDeletedSuccessfully: "Category has been deleted successfully!",
    toDeleteThisProduct: "to delete this Product",
    invalidProductQuantity: "Invalid Product Quantity",
    quantityAddedIsOverStock:
      "The quantity you're adding is over what you have in stock.",
    itemInventoryNotTracked: "Item inventory is not tracked",
    addBulkQuantity: "Add Bulk Quantity",
    enterBulkQuantity: "Enter Bulk Quantity",
    pleaseEnterBulkQuantity: "Please enter Bulk Quantity",
    youveSuccessfullyAddedANewProduct:
      "You've successfully added a new product!",
    pleaseEnterProductSellingPrice: "Please enter product selling price",
    doYouWantToTrackProductStock: "Do you want to track product stock?",
    sellingPrice: "Selling Price",
    setProductExpiryReminder: "Set Product Expiry Reminder",
    productExpiryDate: "Product Expiry date",
    startRemindingFrom: "Start Reminding From",
    productSuppliesAddedSuccessfully:
      "You have added product supplies successfully.",
    addProductSupplies: "Add Product Supplies",
    pleaseSelectSupplier: "Please Select Supplier",
    nameOfProduct: "Name Of Product",
    pleaseSelectProduct: "Please Select Product",
    productVariant: "Product Variant",
    pleaseSelectAVariant: "Please Select A Variant",
    pleaseEnterUnitPrice: "Please Enter Unit Price",
    businessBranch: "Business Branch",
    pleaseSelectBranch: "Please select branch",
    youveSuccessfullyAddedANewSupplier:
      "You've successfully added a new supplier",
    addSupplier: "Add Supplier",
    pleaseEnterSupplierEmail: "Please enter supplier's email",
    pleaseAddADescription: "Please add a description",
    anErrorOccuredProductsDeleted:
      "An error occurred while performing the operation. Please take note, few products might have gotten deleted in the process",
    bulkDelete: "Bulk Delete",
    youAreAboutToDelete: "You are about to delete",
    product: "Product",
    isDueToRedeem: "is due to redeem",
    aReward: "a reward",
    pleaseSelectCustomerToReeemReward:
      "Please select a customer to redeem reward.",
    youHaveNoLoyaltyProgramRunning:
      "You have no active loyalty program running",
    customerHhasNoPointsInLoyaltyProgram:
      "Customer has no points in this loyalty program",
    proceedWithPayment: "Proceed with Payment?",
    youAreAboutToPayForTransactionUsingPoints:
      "You are about to pay for the transaction using points.",
    customerHas: "Customer Has",
    worth: "worth",
    andIsNotSufficientToPayForTransaction:
      "and is not sufficient to pay for this transaction. Would they want to add the balance using another payment method?",
    addCustomerLoyalty: "Add Customer Loyalty",
    pleaseAddCustomerFirst: "Please add or select a customer first.",
    pleaseWaitWhileWeGetReady: "Please wait, while we get ready",
    lowStock: "Low Stock",
    pleaseEnterAmountTendered: "Please enter the amount tendered",
    areYouSureToBookSaleOffline:
      "Are you sure you want to book this sale offline?",
    saleWouldBeBookedAutomatically:
      "Sale would be booked automatically when when you turn on your internet",
    offlineSalesBookingCancelled: "Offline sales booking canceled",
    covid19Message:
      "COVID19: Wash your hands with soap or Sanitize to stop the spread. Stay Safe Always",
    saleSuccessfullyRecorded: "Sale Successfully Recorded!",
    sendReceiptToEmail: "Send receipt to Email",
    sendThankYouSms: "Send thank you SMS",
    sendShippingDetails: "Send Shipping details",
    addLoyalty: "Add Loyalty",
    searchCustomerNameOrNumber: "Search customer name or number",
    clickTheSearchCustomerBox: "Click the Search Customer Box and Scan Card",
    enterProductPrice: "Enter Product Price",
    enterPriceFor: "Enter Price for",
    searchForProduct: "Search for product",
    all: "All",
    backToDashboard: "Back to Dashboard",
    viewDraftSales: "View Draft Sales",
    variantSelected: "variant selected",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "The previous variant selection is not available for the new variant selected based on price, please change your selection.",
    pleaseSupplyPositiveQuantityNumber:
      "Please supply a postive quantity number",
    lowStockFor: "Low stock for",
    clearVariants: "Clear variants",
    pleaseEnterQuantity: "Please enter Quantity",
    picture: "Picture",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Total Spent",
    confirmPayment: "Confirm Payment",
    hasPaymentBeenMade: "Has Payment been processed succesfully?",
    enterTransactionReference: "Enter the transaction reference you paid with",
    pleaseSelectACustomer: "Please select a customer!",
    areYouSureToApplyDiscount: "Are you sure you want to apply discount?",
    addYourBankAccountToEnableUssd:
      "Add your bank account to enable Instant USSD transfer by uPay",
    totalAmountToPay: "Total Amount to Pay",
    doYouWantToCancelTransaction: "Do you want to cancel this transaction?",
    savePrintBill: "Save/Print Bill",
    enterAmountCollectedForCustomer: "Enter amount collected for customer",
    recordSale: "Record Sale",
    checkOutWith: "Check out with",
    instantTransfer: "Instant Transfer ",
    dialTheUSSDCode: "Dial the USSD code",
    pleaseSelectABank: "Please select a bank",
    payWithUSSD: "Pay With USSD (Instant)",
    sendBillTo: " - Send Bill to ",
    waitingForUSSDTransfer: "Waiting for USSD Transfer",
    percent: "Percent",
    applyDiscount: "Apply Discount",
    thisBillHasBeenSaved: "This Bill has been saved",
    saveDraft: "Save Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Please type in a name to identify the customer",
    paymentDetails: "Payment Details",
    basePrice: "Base Price",
    staff: "Staff",
    subTotal: "SubTotal",
    durationMonths: "Duration (months)",
    selectADuration: "Select a Duration",
    oneMonth: "1 Month",
    twoMonths: "2 Months",
    threeMonths: "3 Months",
    sixMonths: "6 Months",
    twelveMonths: "12 Months",
    eighteenMonths: "18 Months",
    twentyFourMonths: "24 Months",
    twoMonthsFree: "(2 Months Free)",
    threeMonthsFree: "(3 Months Free)",
    fiveMonthsFree: "(5 Months Free)",
    yourAccountHasBeen: "Your Account has been",
    renewed: "renewed",
    upgraded: "upgraded",
    successfully: "successfully",
    yourSubscription: "Your Subscription",
    youAreCurrentlyEnrolledOnA: "You are currently enrolled on a",
    pleaseChooseAPaymentOption: "Please Choose a Payment Option",
    planRenewal: "Plan Renewal",
    planUpgrade: "Plan Upgrade",
    pleaseSelectDurationToPayFor:
      "Please Select Duration you intend to pay for",
    staffAccounts: "Staff Accounts",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Please select a plan",
    includeAddons: "Include Add-ons",
    viewTransactions: "View Transactions",
    customerHasNoCompletedTansactions:
      "Customer has no completed tansactions yet",
    branch: "Branch",
    enterNumberOfEcommerceBranches: "Enter Number of Ecommerce Branches",
    enterNumberOfEcommerceBranchesToPay:
      "Enter Number of Ecommerce Branches You Intend to Pay For",
    ecommerceIntegration: "Ecommerce Integration",
    enterNumberOfBranches: "Enter Number of Branches",
    enterNumberOfAdditionalBranchesToPay:
      "Enter Number of Additional Branches You Intend to Pay For",
    enterNumberOfStaffs: "Enter Number of Staffs",
    enterNumberOfStaffsToPayFor: "Enter Number of Staffs You Intend to Pay For",
    discountApplies: "Discount Applies",
    starsOnThe: "stars on the",
    offer: "offer",
    get: "Get",
    moreStarsToRedeem: "more stars to redeem",
    taxVat: "Tax (VAT)",
    callCashierToCompletePayment: "Call Cashier to Complete Payment",
    receipt: "Receipt",
    dear: "Dear",
    thankYouForYourOrderFindGoods:
      "Thank you for your order. Please find the following goods supplied, as agreed.",
    shippingNote: "Shipping Note",
    enterShippingNote: "Enter Shipping Note",
    shippingAddress: "Shipping address",
    enterShippingAddress: "Enter Shipping address",
    wellDoneYouAreDueToRedeem: "Well done! You are due to redeem",
    toGetYourRewardNextVisit:
      "to get your reward on your next visit. Thank you",
    pointsOnThe: "Points on the",
    morePointsToRedeem: "more points to redeem",
    showCode: "Show code",
    toGetYourRewardOnNextVisit:
      "to get your reward on your next visit. Thank you",
    earn: "Earn",
    delivaryNote: "Delivary Note",
    draftSales: "Draft Sales",
    startDate: "Start date",
    endDate: "End date",
    orders: "Orders",
    checkout: "Checkout",
    noProductItem: "No Product Item",
    selectProductImage: "Select Product Image",
    selectCountry: "Select country",
    selectRegion: "Select State/Region",
    printProductTag: "Print Product Tag",
    transactionReference: "transactionReference",
   
  },
  Chichewa: {
    cashier: "wothandizira",
    manager: "woyang'anira",
    owner: "mwini",
    online: "pa intaneti",
    offline: "olumikizidwa ku makina",
    changePassword: "Sinthani mawu achinsinsi",
    currentPasswordMessage: "Chonde lowetsani mawu anu achinsinsi",
    passwordMessage: "Chonde lowetsani mawu achinsinsi",
    currentPassword: "Mawu Achinsinsi Apano",
    password: "achinsinsi",
    confirmPasswordMessage: "Chonde tsimikizani mawu anu achinsinsi!",
    confirmPassword: "Tsimikizani Mawu Achinsinsi",
    sendViaEmail: "Tumizani Kudzera pa Imelo",
    sendViaWhatsapp: "Tumizani kudzera pa WhatsApp",
    downloadPdf: "Tsitsani PDF",
    paid: "analipira",
    unpaid: "osalipidwa",
    partial: "tsankho",
    closeInvoice: "Kodi mukufuna kutseka Invoice?",
    closeInvoiceConfirmation:
      "Invoice mwina singasungidwe. Kodi mukufuna kutseka?",
    yes: "inde",
    no: "ayi",
    invoice: "Chizindikiro",
    wasDeducted: "adachotsedwa",
    for: "chifukwa",
    item: "Katunduyo",
    addProduct: "Onjezani Zogulitsa",
    paymentReference: "Malipiro a Malipiro",
    amountPaid: "Ndalama Zolipidwa",
    discountAmount: "Mtengo Wotsitsa",
    amountDue: "Ndalama Zoyenera",
    amount: "Kuchuluka",
    dueDate: "Tsiku lomaliza",
    paymentType: "Mtundu wa Malipiro",
    card: "Khadi",
    cash: "Ndalama",
    bankTransfer: "Kutumiza Banki",
    paymentMessage: "Uthenga wa Malipiro",
    description: "Kufotokozera",
    sendReceipt: "Tumizani Chiphaso",
    delete: "Chotsani",
    save: "Sungani",
    resend: "Tumizaninso",
    saveAndSend: "Sungani",
    invoiceSaved: "Invoice yasungidwa!",
    selectPaymentMethod: "Chonde sankhani Njira Yolipira!",
    selectCustomer: "Chonde sankhani Makasitomala!",
    cartEmptyError:
      "Mndandanda wamagalimoto sungakhale wopanda kanthu, tsekani invoice ndikuwonjezera Katundu ku ngolo!",
    subscriptionExpired:
      "Kulembetsa kwanu kwatha ndipo akaunti yanu tsopano ndi yocheperako. Dinani pa Batani pansipa kuti mukonzenso akaunti yanu",
    renew: "Konzaninso",
    holdOn: "Gwiritsitsani",
    customerBank: "Makasitomala Bank",
    cancel: "Kuletsa",
    selectACustomer: "Sankhani Makasitomala",
    invoiceSuccessfulPdfError:
      "Invoice idapangidwa bwino koma PDF Generation ikutenga nthawi yayitali kuposa masiku onse. Chonde onani posachedwa.",
    downloadingInvoice: "Kutsitsa Invoice",
    downloadingReceipt: "Kutsitsa Chiphaso",
    whatsappReceiptError:
      "Panali vuto potumiza chiphaso kudzera pa WhatsApp, Chonde yesaninso.",
    receiptToWhatsapp: "Chiphaso chotumizira ku WhatsApp",
    thankYouForPatronage: "Zikomo chifukwa chothandizira",
    hereIsYourReceipt: "Nayi risiti yanu yolipira",
    errorSendingEmailReceipt:
      "Panali vuto potumiza chiphaso kudzera pa imelo, chonde yesaninso kapena kulumikizana ndi othandizira",
    receiptSentToEmail: "Chiphaso chatumizidwa ku imelo ya kasitomala",
    invoiceSentToEmail: "Invoice yatumizidwa ku imelo ya kasitomala",
    invoiceSuccessWhatsappError:
      "Invoice idapangidwa bwino koma panali vuto potumiza kwa WhatsApp. Yeseraninso pamndandanda wa inivoyisi",
    invoiceSuccessfulEmailError:
      "Invoice idapangidwa bwino koma panali vuto politumiza ngati imelo. Chonde yesaninso kuchokera pandandanda wa inivoyisi",
    invoiceSentToWhatsapp: "Invoice yatumizidwa ku WhatsApp",
    hello: "Moni",
    pleaseDownloadInvoice: "Chonde tsitsani inivoyisi",
    pleaseDownloadReceipt: "Chonde tsitsani risiti",
    from: "kuchokera",
    email: "Imelo",
    upgrade: "Sinthani",
    youAreOnFreePlan: "Muli pa Dongosolo Laulere.",
    clickOn: "Dinani pa",
    yourPlanInFewSteps: " muzimvetsera muzitsulo zochepa zochepa.",
    to: "kuti",
    yourSubscriptionHasExpired:
      "Kulembetsa kwanu kwatha ndipo akaunti yanu tsopano ndi yocheperako.",
    days: "masiku",
    yourSubscriptionExpiresIn: "Kulembetsa kwanu kwa Loystar kumatha",
    createAcount: "Pangani akaunti",
    signIn: "Lowani muakaunti",
    continue: "Pitirizani",
    enterOtp: "Lowetsani PIN ya OTP",
    enterValidOtp: "Chonde lowetsani PIN yoyenera",
    pin: "PIN",
    tokenSentToMail: "Chizindikiro chatumizidwa ku imelo yanu",
    passwordResetSuccessful: "Kubwezeretsanso mawu achinsinsi kudachita bwino",
    somethingWentWrong: "China chake chalakwika!",
    resetPassword: "Bwezeretsani Chinsinsi",
    iHaveResetCode: "Ndili ndi nambala yokhazikitsanso mawu achinsinsi",
    pleaseEnterEmail: "Chonde lowetsani imelo",
    aTokenWillBeSentToEmail: "Chizindikiro chikhoza kutumizidwa ku imelo yanu",
    enterEmail: "Lowani imelo yanu",
    sendinYourToken: "Kutumiza chizindikiro chanu ...",
    makeSureItMatchesPassword:
      "Onetsetsani kuti chikufanana ndi password yanu yatsopano",
    pleaseChooseNewPassword: "Chonde sankhani mawu achinsinsi atsopano",
    chooseNewPassword: "Sankhani mawu achinsinsi atsopano",
    enterNewPassword: "Lowetsani mawu anu achinsinsi kuti mutsimikizire",
    enterTokenSent: "Lowetsani chizindikiro chomwe chidatumiza makalata anu",
    resetToken: "Bwezeretsani Chizindikiro",
    resettingPassword: "Kubwezeretsanso mawu achinsinsi ...",
    signUp: "Lowani",
    adminSignInWithEmail:
      " Admin amalembetsa ndi imelo pomwe Ogwira ntchito amalowetsamo dzina lanu.",
    pleaseEnterEmailOrUsername: "Chonde lowetsani imelo kapena dzina lanu",
    emailOrUsername: "Imelo kapena Lolowera",
    pleaseEnterPassword: "Chonde lowetsani mawu achinsinsi",
    createAnAccount: "Pangani akaunti",
    forgotPassword: "Mwayiwala mawu achinsinsi olowera?",
    enterPhoneNumber: "Lowetsani Nambala yafoni",
    personalData: "Zambiri Zanu",
    validateConfirmationCOde: "Tsimikizani Chitsimikizo Code",
    pleaseEnterFirstName: "Chonde lowetsani dzina lanu loyamba",
    pleaseEnterPhoneNumber: "Chonde lowetsani nambala yanu yafoni",
    pleaseEnterLastName: "Chonde lowetsani dzina lanu lomaliza",
    pleaseEnterBusinessName: "Chonde lowetsani dzina lanu labizinesi",
    firstName: "Dzina loyamba",
    lastName: "Dzina lomaliza",
    businessName: "Dzina la Bizinesi",
    previous: "Zakale",
    next: "Ena",
    pleaseSelectBusinessCategory: "Chonde sankhani gulu lanu",
    pleaseEnterValidEmail: "Chonde lowetsani imelo yolondola",
    pleaseEnterPostCode: "Chonde lowetsani nambala yakalata",
    postCode: "Post Code",
    phoneNumberInUse: "Nambala yafoni iyi imagwiritsidwa ntchito kale!",
    emailInUse: "Imeloyi yakhala ikugwiritsidwa ntchito kale!",
    foodAndDrinks: "Chakudya ndi Zakumwa",
    salonAndBeauty: "Salon ndi Kukongola",
    fashionAndAccessories: "Mafashoni ndi Chalk",
    gymAndFitness: "Olimbitsa Thupi ndi Fitness",
    travelAndHotel: "Ulendo ndi Hotelo",
    homeAndGifts: "Kunyumba ndi Mphatso",
    washingAndCleaning: "Kuchapa ndi Kukonza",
    gadgetsAndElectronics: "Zida zamagetsi ndi zamagetsi",
    groceries: "Zogulitsa",
    others: "Ena",
    submit: "Tumizani",
    accountCreatedSuccessful: "Akaunti yanu idapangidwa bwino.",
    pleaseEnterAddress: "Chonde Lowetsani Adilesi Yanu",
    addressLine1: "Mzere Woyamba Waadilesi",
    addressLine2: "Mzere wa Adilesi 2",
    choosePassword: "Sankhani Chinsinsi",
    passwordMustBe6Characters: "Mawu achinsinsi ayenera kukhala osachepera 6.",
    howDidYouHearLoystar: "Munamva bwanji za Loystar?",
    referralCode: "kutumiza Code",
    byClickingTheButton: " Podina batani pansipa, mukuvomera",
    termsAndSevice: "Migwirizano",
    wereCreatingAccount: "Tikupanga akaunti yanu",
    proceed: "Chitani",
    verificationCodeMustBe6: "Code Verification iyenera kukhala manambala 6",
    pleaseEnter6DigitCode: "Chonde Lowetsani nambala 6 manambala",
    enterVerificationCode: "Lowetsani Code Yotsimikiza",
    resendToken: "Tumizani Chizindikiro",
    verify: "Tsimikizani",
    transactions: "Zochitika",
    todaySales: "Zogulitsa Zamakono",
    salesHistory: "Mbiri Yogulitsa",
    supplyHistory: "Mbiri Yotsatsa",
    new: "Chatsopano",
    invoices: "Invoice",
    disbursements: "Malipiro",
    offlineSales: "Kugulitsa Kwapaintaneti",
    products: "Zamgululi",
    customers: "Makasitomala",
    multiLevelLoyalty: "Kukhulupirika Kwampikisano",
    loyaltyPrograms: "Mapulogalamu Okhulupirika",
    members: "Mamembala",
    appStore: "App Store",
    orderMenu: "Dongosolo la Menyu",
    settings: "Zokonzera",
    staffAndBranches: "Antchito ndi Nthambi",
    myAccount: "Akaunti Yanga",
    switchToSellMode: "Pitani ku Gulitsani mumalowedwe",
    signOut: "Tulukani",
    getFreeSubscription: "Pezani Kulembetsa Kwaulere",
    onlyNumbersAllowed: "Manambala okha ndi omwe amaloledwa",
    yourAccountMustBe10Digits:
      "nambala yanu yaakaunti iyenera kukhala nambala ya manambala 10",
    yourBvnMustBe11: "BVN yanu iyenera kukhala nambala 11",
    pleaseSelectBank: "Chonde sankhani banki yanu",
    selectYourBank: "Sankhani Banki yanu",
    enterBankAccountNumber: "Lowetsani Nambala ya Akaunti Yabanki",
    enterBvnNumber: "Lowani BVN yanu",
    connectBankAccount: "Lumikizani Akaunti ya Banki",
    passwordResetSuccessfulAndSignOut:
      "Chinsinsi chanu chakhazikitsidwanso bwino. Dinani pa batani Lowani Pansipa kuti mulowetsenso",
    enterCurrentPassword: "Lowetsani mawu achinsinsi apano",
    pleaseEnterCurrentPassword: "Chonde lowetsani mawu achinsinsi",
    phoneNumber: "Nambala yafoni",
    sex: "Kugonana",
    dateOfBirth: "Tsiku lobadwa",
    state: "State",
    country: "Dziko",
    loyaltyId: "Kukhulupirika ID",
    createdAt: "Adapanga Pa",
    noOfTransactionsMade: "Ayi yazogulitsa zopangidwa",
    yourDownloadWillStart: "Kutsitsa kwanu kumayamba pakamphindi",
    exportCustomers: "Kutumiza Makasitomala",
    youhaveSuccessfullyToppedUp:
      "Mwakwanitsa kupititsa patsogolo ma Units anu a SMS.",
    youNowHave: "Tsopano mwatero",
    smsUnits: "Maumboni a SMS",
    enterNumberOfSmsUnits:
      "Lowetsani Chiwerengero cha Ma Units a SMS omwe Mukufuna Kugula",
    pleaseEnterANumericValue: "Chonde lowetsani mtengo wamanambala",
    pay: "Lipirani",
    accountEditedSuccessfully: "Akaunti yanu idasinthidwa bwino.",
    youAeCurrentlyOn: "Mukuyatsa",
    plan: "Konzani",
    userData: "Zambiri Zogwiritsa Ntchito",
    businessData: "DATA LA Bizinesi",
    businessCategory: "Catergory Yabizinesi",
    pleaseSelectCurrency: "Chonde sankhani ndalama zanu",
    selectYourCurrency: "Sankhani ndalama zanu",
    purchaseMoreSmsUnits:
      "Gulani ma Units ambiri a SMS pogwiritsa ntchito fomu ili pansipa",
    youHave: "Muli ndi",
    atLeast4SmsUnitsRrequired:
      "Pafupifupi ma sms 4 amafunikira kuti mutsimikizire, chonde onjezani!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Chonde tsimikizani akaunti yanu yakubanki kuti mugwirizane ndi zofunikira za 'Know Your Customer' (KYC). Izi zikuthandizani kuti mutolere ndalama kudzera ku USSD kapena kusamutsa pompopompo, kulandila ma oda kuchokera kwa makasitomala ndikukonzekera momwe mungagwiritsire ntchito ndalama zanu. Amagwiritsidwa ntchito kwa amalonda aku Nigeria okha. Chonde dinani batani pansipa kuti muyambe.",
    reConnectBankAccount: "Lumikizaninso Akaunti ya Banki",
    accountName: "Dzina laakaunti",
    accountNumber: "Nambala ya Akaunti",
    bankName: "Dzina la Banki",
    verified: "Chotsimikizika",
    accountDetails: "Zambiri Za Akaunti",
    kycBankAccount: "KYC",
    createTier: "Pangani Gawo",
    fileUploadSuccessful: "fayilo yokwezedwa bwino",
    fileUploadFailed: "kukweza mafayilo kwalephera",
    createLoyaltyProgram: "Pangani Pulogalamu Yokhulupirika",
    createLoyalty: "Pangani Kukhulupirika",
    name: "Dzina",
    loyaltyArtwork: "Kukhulupirika Zojambula",
    clickToUpload: "Dinani kuti muyike",
    amountToPointsRatio: "Kuchuluka kwa Poyerekeza Ratio",
    points: "Mfundo",
    recommendedAmountToPointRatio:
      "Kuti Mukhale Wokhulupirika Kwabwino Kwambiri, mfundo za Ndalama Zamtengo Wapatali zitha kukhala Nambala Yonse Yozungulira.",
    pleaseTypeIn: "Chonde lembani",
    toDeleteLoyalty: "kuchotsa kukhulupirika uku",
    deleteLoyalty: "Chotsani Kukhulupirika",
    toConfirm: "kutsimikizira",
    edit: "Sinthani",
    pointsAwardedSuccessfully: "Mfundo Zapatsidwa Bwino.",
    enterPointValueToAward:
      "Lowetsani Mtengo Wamtengo Womwe Mungakonde Kulipira Amakasitomala",
    award: "Mphoto",
    awardPointValuesToCustomer: "Mtengo Wopereka Mphoto kwa Kasitomala",
    enrollMembersToLoyalty: "Lembetsani Mamembala Kukhulupirika",
    awardPoints: "Mfundo Zopereka",
    enroll: "Lembetsani",
    home: "Kunyumba",
    loyalty: "Kukhulupirika",
    enrollCustomers: "Kulembetsa Makasitomala",
    selected: "Zosankhidwa",
    customer: "Makasitomala",
    loyaltyActivationSuccessful: "Kukhulupirika kumachita bwino.",
    loyaltyDeactivationSuccessful:
      "Kukhazikitsa Kukhulupirika kumachita bwino.",
    viewTier: "Onani Gawo",
    deactivate: "Chotsani",
    activate: "Yambitsani",
    actions: "Zochita",
    nameOfLoyalty: "Dzinalo Lokhulupirika",
    loyaltyStatus: "Mkhalidwe WokhulupirikaMakhalidwe Okhulupirika",
    numberOfTiers: "Chiwerengero cha Matayala",
    createdOn: "Analengedwa",
    createATier: "Pangani Gawo",
    stopCreatingTierConfirmation: "Kodi mukufuna kusiya kupanga gawo?",
    stopEditingTierConfirmation: "Kodi mukufuna kusiya kukonza gawo ili?",
    nameOfTier: "Dzina la Tier",
    minimumSpendingTarget: "Zochepa Zomwe Mungagwiritse Ntchito",
    maximumSpendingTarget: "Zolemba malire Kugwiritsira Ntchito",
    minimumSpendingTargetRequired: "osachepera ndalama chandamale chofunika",
    maximumSpendingTargetRequired:
      "kuchuluka kwa zomwe mukugwiritsa ntchito kumafunika",
    rewardSponsor: "Wopereka Mphoto",
    pleaseChooseARewardSponsor: "Chonde sankhani wopereka mphotho",
    self: "Kudzikonda",
    partner: "Mnzanu",
    rewardPartner: "Mphotho Mnzanu",
    pleaseSelectRewardPartner: "Chonde sankhani mnzanu wapabanja",
    rewards: "Mphoto",
    pleaseSelectAReward: "Chonde sankhani mphotho",
    instructionsOnRedeemingReward:
      "Malangizo Omwe Makasitomala Ayenera Kuombolera Mphoto",
    pleaseFillInThisField: "Chonde Dzazani Mundawu!",
    toDeleteThisTier: " kuchotsa gawo ili",
    deleteTier: "Chotsani Gawo",
    viewMembers: "Onani Mamembala",
    membersEnrolled: "Mamembala Analembetsa",
    instruction: "Malangizo",
    membersIn: "Mamembala mu",
    availableTiersInLoyalty: "Zigawo Zomwe Zikupezeka mu Kukhulupirika Program",
    tiers: "Matayala",
    totalTier: "KULIMBIKITSA KWAMBIRI",
    availableLoyaltyProgramme: "Dongosolo Lokhulupirika Lopezeka",
    totalLoyalties: "OKHULUPIRIKA KWAMBIRI",
    memberDetails: "Zambiri Zamembala",
    nameOfCustomer: "Dzina la Makasitomala",
    address: "Adilesi",
    allEnrolledMembers: "Mamembala Onse Olembetsa",
    thisIsToWishYouHappyBirthday:
      "Izi ndikuti ndikufunireni tsiku lobadwa losangalala komanso moyo wabwino. Zikomo pazonse zomwe muli ku Loyster. Zikondwerero Zosangalatsa!",
    inputAnOfferPlease: "Lowetsani Chonde chonde",
    pleaseSelectTheInsertPoint:
      "Chonde sankhani mfundo yolowetsamo uthengawo ndikudina",
    birthdayOfferAndMessage: "Tsiku Lakubadwa ndi Uthenga",
    birthdayOffer: "Tsiku Lakubadwa",
    birthdayMessage: "Uthenga Wakubadwa",
    noOfferFound: "Palibe chopezeka",
    settingABirthdayOffer:
      "Kukhazikitsa tsiku lobadwa kumalola makasitomala kuti alandire izi kudzera pa SMS patsiku lawo lobadwa",
    createOffer: "Pangani Kutsatsa",
    whatIsTheOffer: "Kodi mwayiwo ndi chiyani?",
    editMessage: "Sinthani Uthenga",
    insert: "Ikani",
    theNameOfCustomerInserted: "Dzina la kasitomala lidzaikidwa apa",
    theBirtdayOfferInserted: "Chopereka chakubadwa chidzaikidwa pano",
    youSuccessfullyAddedNewBranch:
      "Mwachita bwino kuwonjezera nthambi yatsopano!",
    addNewBranch: "Onjezani Nthambi Yatsopano",
    addBranch: "Onjezani Nthambi",
    additionalBranchWillIncur: "Nthambi Yowonjezera ikanabwera",
    perBranch: "nthambi iliyonse",
    ecommerceBranchCosts: "Mtengo wa nthambi ya Ecommerce",
    pleaseEnterBranchName: "Chonde lowetsani dzina la nthambi",
    pleaseEnterBranchAddress1: "Chonde lowetsani nambala 1 ya adilesi",
    enterBranchAddress1: "Lowetsani mzere waku adilesi 1",
    enterBranchAddress2: "Lowetsani mzere waku adilesi 1",
    pleaseEnterBranchAddress2: "Chonde lowetsani mzere adilesi yanthambi 2",
    enterBranchName: "Lowetsani dzina la nthambi",
    successfullyAddedStaff: "Mwawonjezera bwino antchito atsopano!",
    addNewStaff: "Onjezani Ogwira Ntchito Atsopano",
    addStaff: "Onjezani Ogwira Ntchito",
    additionalStaffWillIncur: "Ogwira Ntchito Zowonjezera amabwera",
    perStaff: "ogwira ntchito.",
    pleaseEnterStaffEmail: "Chonde lowetsani imelo ya ogwira ntchito",
    pleaseEnterStaffUsername: "Chonde lowetsani dzina lolowera antchito",
    pleaseEnterStaffPassword:
      "Chonde lowetsani mawu achinsinsi a ogwira ntchito",
    pleaseSelectStaffRole: "Chonde sankhani udindo wa ogwira ntchito",
    selectStaffRole: "Sankhani udindo wa ogwira nawo ntchito",
    enterStaffEmail: "Lowetsani imelo ya ogwira ntchito",
    enterStaffUsername: "Lowetsani dzina la ogwiritsa ntchito",
    enterStaffPassword: "Lowetsani mawu achinsinsi a ogwira ntchito",
    spacesNotAllowedInUsername: "malo osaloledwa mu dzina lathu",
    admin: "Kuwongolera",
    pleaseSelectBusinessToAttachStaff:
      "Chonde sankhani bizinesi kuti mulumikize antchito",
    searchForBranchToAttachStaff: "Sakani nthambi kuti mulumikize antchito",
    username: "Lolowera",
    role: "Udindo",
    areYouSureToDeleteThis: "Mukutsimikiza kuchotsa izi",
    branches: "Nthambi",
    upgradeYourPlan: "Sinthani dongosolo lanu.",
    add: "Onjezani",
    addNew: "Onjezani Chatsopano",
    customerWithEmailAlreadyExists:
      "Makasitomala omwe ali ndi imelo / nambala yafoni alipo kale!",
    successfullyAddedNewCustomer:
      "Mwachita bwino kuwonjezera kasitomala watsopano!",
    addCustomer: "Onjezani Makasitomala",
    pleaseEnterCustomerFirstName:
      "Chonde lowetsani dzina loyamba la kasitomala",
    pleaseEnterCustomerLastName:
      "Chonde lowetsani dzina lomaliza la kasitomala",
    pleaseEnterCustomerPhoneNumber:
      "Chonde lowetsani nambala yafoni yamakasitomala",
    pleaseEnterCustomerEmail: "Chonde lowetsani imelo yamakasitomala",
    pleaseEnterCustomerAddressLine:
      "Chonde lowetsani Mzere wa Adilesi ya kasitomala",
    pleaseEnterCustomerOtherAddress:
      "Chonde lowetsani Maadiresi ena a kasitomala",
    pleaseSelectCustomerGender: "Chonde sankhani jenda la kasitomala",
    gender: "Gender",
    male: "Mwamuna",
    female: "Mkazi",
    bank: "Banki",
    selectBank: "Sankhani Bank",
    stateOrRegionOrProvince: "Dziko / Chigawo / Chigawo",
    customerNotes: "Ndemanga Zamakasitomala",
    sendSms: "Tumizani SMS",
    editCustomer: "Sinthani Makasitomala",
    redeemReward: "Pulumutsani Mphoto",
    issueLoyaltyCard: "Tumizani Khadi Lokhulupirika",
    deleteCustomer: "Chotsani Makasitomala",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Mwasankha bwino ID ya Mamembala Okhulupirika",
    noMembershipIdAvailable:
      "Palibe ma ID aumembala omwe akupezeka. Chonde titumizireni ku hello@loystar.co",
    sendEmail: "Tumizani Imelo",
    membershipPoints: "Mfundo Za Umembala",
    customerDetails: "Zambiri Zamakasitomala",
    close: "Tsekani",
    loyaltyBalance: "Kukhulupirika Kusamala",
    pointsBalance: "Kusamala kwa Mfundo",
    starBalance: "Kusamala kwa Star",
    requiredPoints: "Mfundo Zofunikira",
    requiredStars: "Amafuna Nyenyezi",
    reddemCode: "Pulumutsani Code",
    toDeleteThisCustomer: "kuti achotse kasitomala uyu",
    customerHasBeenDeletedSuccessfully: "Makasitomala achotsedwa bwino!",
    customerWithPhoneAlreadyExists:
      "Makasitomala omwe ali ndi nambala yafoni alipo kale",
    customerWasSuccessfullyEdited: "Makasitomala adasinthidwa bwino",
    anErrorOccured: "Panali vuto",
    phoneNumberIncludeCountry: "Nambala yafoni (Phatikizani Dziko Code)",
    yourFileQueuedForUpload:
      "Fayilo yanu yaikidwa pamzere kuti ikwezeke. Chonde tsitsimutsani tsambalo patadutsa masekondi angapo.",
    thereWasAnErrorPerformingOperation: "Panali vuto polipira opaleshoniyi!",
    pleaseSelectFile: "Chonde Sankhani Fayilo!",
    oopsOnlyCsvFilesAllowed:
      "Pepani! Mafayilo a CSV okha ndi omwe amaloledwa. Chonde tumizani fayilo ya .csv.",
    fileShouldBeSmallerThan5Mb:
      "Fayilo iyenera kukhala yaying'ono kuposa 5MB. Ngati muli ndi fayilo yayikulu, chonde imelo support@loystar.co. Zikomo",
    customerFirstNameIsRequired: "kasitomala dzina loyamba likufunika pamzere",
    customerPhoneNumberIsRequired:
      "nambala yafoni yamakasitomala imafunika pamzere",
    importCustomers: "Tengani Makasitomala",
    upload: "Kwezani",
    clickIconToDownloadCsv:
      "Dinani chizindikirochi kuti mulandire template ya CSV.",
    getGoogleSheetVersion: "Pezani mtundu wa Google sheet",
    clickOrDragFileToUpload:
      "Dinani kapena kukokera fayilo m'dera lino kuti mukweze",
    missingOrInvalidColumnHeader:
      "Chosowa kapena chosagwira mutu wamutu. Chonde tsatirani mtundu wa template. Zikomo.",
    youHaveImported: "Mwatulutsa",
    youSuccessfullyRedeemedReward: "Mwawombola bwino mphotho yanu!",
    sixDigitCode: "Nambala zisanu ndi chimodzi",
    pleaseEnterCustomerRewardCode:
      "Chonde lowetsani nambala ya mphotho ya kasitomala",
    enterRewardCode: "Lowetsani nambala ya mphotho.",
    selectLoyaltyProgram: "Sankhani pulogalamu yokhulupirika",
    stamps: "Zitampu",
    smsUnitsLow: "Ma Units a SMS Otsika",
    pleaseTopUpSmsUnits: "Chonde Top Up SMS mayunitsi",
    smsSuccessfullySentForDelivery: "SMS yatumizidwa bwino kuti iperekedwe!",
    sendSmsTo: "Tumizani SMS ku",
    allCustomers: "Makasitomala Onse",
    unitsAvailable: "Mayunitsi Alipo",
    pleaseTypeInTheMessage: "Chonde lembani uthengawu",
    message: "Uthenga",
    charactersRemaining: "zilembo zotsalira",
    avoidUseOfSpecialCharacters:
      "Pewani kugwiritsa ntchito Anthu Otchuka ndi Emojis kusunga Units ya SMS.",
    note: "Zindikirani",
    errorFetchingCustomersMultilevelDetail:
      "Kulakwitsa Kutenga Makasitomala Zambiri zaLeLevel",
    search: "Sakani",
    reset: "Bwezeretsani",
    importCustomer: "Tengani Makasitomala",
    addNewCustomer: "Onjezani Makasitomala Atsopano",
    sendSmsBroadcast: "Tumizani Mauthenga a SMS",
    totalCustomers: "Makasitomala ONSE",
    disbursementDetails: "Zambiri Zamalipiro",
    paidBy: "Kulipidwa Ndi",
    disbursed: "Kutulutsidwa",
    bankAccountName: "Dzina la Akaunti Yabanki",
    bankAccountNumber: "Nambala ya Akaunti Yabanki",
    transferInitiated: "Tumizani Kuyambitsa",
    transferCompleted: "Kutumiza Kumaliza",
    pleaseEnterAValid: "Chonde lowetsani chomveka",
    begin: "yamba",
    end: "TSIRIZA",
    date: "tsiku",
    paymentDate: "Tsiku Lopereka",
    selectDisbursementDuration: "Sankhani Nthawi Yolipira",
    totalSettled: "Onse Akhazikika",
    totalUnsettled: "Onse Osakhazikika",
    toDeleteThisSale: "kuchotsa kugulitsa uku",
    draftSaleDeletedSuccessfully: "Choyesera Kugulitsa chachotsedwa bwino!",
    deleteSale: "Chotsani Kugulitsa",
    pleaseTypeInYourTotal:
      "Chonde lembani kwathunthu kuti mutsimikizire kufufutidwa",
    billDetails: "Zambiri za Bill",
    printBill: "Sindikizani Bill",
    servedBy: "Kutumikira ndi",
    total: "Chiwerengero",
    createdDate: "Tsiku Lopangidwa",
    createdTime: "Nthawi Yolengedwa",
    status: "Mkhalidwe",
    loginSuccessful: "Kulowa Bwino",
    pleaseWaitWhileWeConnectAccount:
      "Chonde dikirani pomwe tikulumikiza akaunti yanu",
    connectionFailedTryAgain: "Kulumikiza kwalephera. Chonde yesaninso.",
    connectionSuccessful: "Kulumikizana Kuli Bwino",
    viewDetails: "Onani Zambiri",
    enable: "Yambitsani",
    free: "Kwaulere",
    cost: "Mtengo",
    visitWebsite: "Pitani pa Webusayiti",
    pleaseUpgradeYourPlanToPro:
      "Chonde Sinthani dongosolo lanu kuti likhale Pro Plus kuti pulogalamuyi iziyenda bwino",
    connectYourBankAccount:
      "Lumikizani akaunti yanu yakubanki kuti muzitha kulandila.",
    disable: "Letsani",
    enableApp: "Yambitsani App",
    addNewProductToInvoice: "Onjezani Zatsopano ku Invoice",
    toDeleteThisInvoice: "kuchotsa Invoice iyi",
    invoiceDeletedSuccessfully: "Invoice yachotsedwa bwino!",
    deleteInvoice: "Chotsani Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Mwachita kuwonjezera uthenga wolipiritsa wa Invoice!",
    pleaseEnterPaymentInstructions: "Chonde lowetsani malangizo olipira",
    invoiceId: "ID ya chiphaso",
    paidDate: "Tsiku Lolipidwa",
    reference: "Kutchulidwa",
    productAdded: "Mankhwala awonjezeredwa",
    productOutOfStock: "Zatha posachedwa. Chonde pindaninso katundu.",
    totalInvoices: "MAVUTO ANTHU",
    totalPaidInvoices: "MAVODI OKONZEDWA",
    totalUnpaidInvoices: "MAVIDIZO OSALIPIRIDWA",
    loyaltyProgramDeleted: "Kukhulupirika Pulogalamu Yachotsedwa",
    thisLoyaltyProgramDeletedSuccessfully:
      "Pulogalamu yokhulupirika iyi yachotsedwa bwino",
    thisLoyaltyProgramEditedSuccessfully:
      "Pulogalamu yokhulupirika iyi yasinthidwa bwino",
    loyaltyProgramAddedSuccessfully:
      "Pulogalamu yokhulupirika yawonjezedwa bwino",
    loyaltyProgramEdited: "Ndondomeko Yokhulupirika Yasinthidwa",
    loyaltyProgramAdded: "Kukhulupirika Ndondomeko",
    loyaltyDetails: "Zambiri Zokhulupirika",
    doYouWantToCloseDialog: "Kodi mukufuna kutseka zokambirana izi?",
    pleaseEnterLoyaltyName: "Chonde lowetsani dzina la kukhulupirika kwanu",
    programType: "Mtundu wa Pulogalamu",
    pleaseSelectProgramType: "Chonde sankhani mtundu wa pulogalamu",
    simplePoints: "Mfundo Zosavuta",
    stampsProgram: "Ndondomeko Ya Zitampu",
    threshold: "Kutalika",
    pleaseEnterLoyaltyThreshold: "Chonde lowetsani malo okhulupilika",
    reward: "Mphotho",
    pleaseEnterLoyaltyReward: "Chonde lowetsani mphotho ya kukhulupirika",
    totalUserPoints: "Mfundo Zonse Zogwiritsa Ntchito",
    totalUserStamps: "Zampampu Zonse Zogwiritsa Ntchito",
    spendingTarget: "Kuwononga ndalama",
    spendingTargetHint1:
      "Kugwiritsa ntchito chandamale ndi kuchuluka kwa zomwe kasitomala ayenera kuwononga kuti alandire mphothoyo. Mtengo wa ndalama 1 = mfundo imodzi.",
    spendingTargetHint2:
      "Zidindo ndizomwe amatenga kasitampu kuti kalandire mphotho. Mwachitsanzo. 5",
    addNewLoyaltyProgram: "Onjezani Dongosolo Latsopano Lokhulupirika",
    addLoyaltyProgram: "Onjezani Kukhulupirika Program",
    loyaltyProgramType: "Kukhulupirika Program Program",
    pleaseSelectLoyaltyProgramType:
      "Chonde sankhani mtundu wa pulogalamu yokhulupirika",
    nameOfProgram: "Dzina la pulogalamu",
    pleaseEnterProgramName: "Chonde lowetsani dzina la pulogalamuyi",
    whatIsTheReward: "Mphotho yake ndi yotani?",
    egNextPurchaseIsFree: "Mwachitsanzo. Kugula kwotsatira ndi kwaulere",
    customerName: "Makasitomala Dzina",
    guestCustomer: "Mlendo Makasitomala",
    orderConfirmedSuccessfully: "Dongosolo latsimikiziridwa bwino",
    orderCancelledSuccessfully: "Dongosolo layimitsidwa bwino",
    confirmOrder: "Tsimikizani dongosolo",
    cancelOrder: "Letsani dongosolo",
    allOrders: "Malamulo Onse",
    totalOrders: "MAFUNSO OKWANA",
    doYouWantToAcceptOrder: "Kodi mukufuna kulandira lamuloli?",
    doYouWantToCancelOrder: "Kodi mukufuna kuletsa lamuloli?",
    orderDetails: "Zambiri Zaz oda",
    orderCreatedAt: "Dongosolo lopangidwa pa",
    supplier: "Wogulitsa",
    productName: "Dzina la Zogulitsa",
    quantity: "Kuchuluka",
    unitPrice: "Mtengo wagawo",
    receivedBy: "Yolandiridwa Ndi",
    reportFrom: "Nenani kuchokera",
    totalSupplies: "Zida Zonse",
    allRightsReserved: "Maumwini onse ndi otetezedwa",
    toDeleteThisTransaction: "kuchotsa Transaction iyi",
    transactionDeletedSuccessfully:
      "Zogulitsa zachotsedwa bwino. Katundu wabwezeretsedwanso pamtengo.",
    deleteTransaction: "Chotsani Zogulitsa",
    transactionDetails: "Zambiri Zogulitsa",
    printReceipt: "Chiphaso Chosindikizira",
    channel: "Kanema",
    discount: "Kuchotsera",
    profit: "Phindu",
    discountedSales: "Kuchotsera Kuchotsera",
    errorFetchingRecord: "Kulakwitsa Kutenga Mbiri",
    exportTransactions: "Zogulitsa Kunja",
    errorFetchingSalesRecord: "Cholakwika potenga malonda a Sales for Export.",
    totalSellingPrice: "Mtengo Wonse Wogulitsa",
    totalCostPrice: "Mtengo Wonse Wamtengo",
    appliedDiscount: "Ntchito Kuchotsera",
    noOfItems: "Zinthu",
    sales: "Zogulitsa",
    export: "Tumizani",
    totalProfit: "Phindu Lonse",
    totalBalanceInPeriod: "Kusamala Kwathunthu M'nyengo",
    allTimeSales: "Nthawi Yonse Yogulitsa",
    records: "Zolemba",
    todaysSales: "Zogulitsa Zamakono",
    transaction: "kugulitsa",
    youHaveExceededTotalNumberOfProducts:
      "Mudapitilira kuchuluka kwa zinthu zomwe zimaloledwa papulani yanu. Sinthani dongosolo lanu kuti musangalale ndi malire.",
    youNeedToHaveLoyaltyProgram:
      "Muyenera Kukhala ndi Pulogalamu Yokhulupirika kuti mugwiritse ntchito izi!",
    price: "Mtengo",
    category: "Gulu",
    stockTracking: "Kutsata Masheya",
    stockCount: "Kuwerengera Masheya",
    taxed: "Misonkho",
    originalPrice: "Mtengo Woyamba",
    costPrice: "Mtengo wamtengo",
    unit: "Chigawo",
    productImage: "Chithunzi Cha Zamalonda",
    taxRate: "Misonkho",
    taxType: "Mtundu wa Misonkho",
    trackInventory: "Tsatani Inventory",
    variants: "Zosiyanasiyana",
    hasVariants: "Ali Zosiyanasiyana",
    importProduct: "Tengani Zogulitsa",
    exportProducts: "Katundu Katundu",
    addNewProduct: "Onjezani Zatsopano",
    viewCategory: "Onani Gulu",
    viewSuppliers: "Onani Ogulitsa",
    receiveInventory: "Landirani Zowerengera",
    printAllProductsTag: "Sindikizani Zogulitsa Zonse",
    deleteAll: "Chotsani Zonse",
    totalProducts: "ZOTHANDIZA ZONSE",
    youveSuccessfullyAddedANewCategory:
      "Mwakwanitsa kuwonjezera gulu latsopano",
    addNewCategory: "Onjezani Gulu Latsopano",
    addCategory: "Onjezani Gulu",
    categoryName: "Dzina la Gulu",
    pleaseEnterCategoryName: "Chonde lowetsani dzina la gulu",
    stampsTarget: "Zampampu Zolinga",
    sendInventory: "Tumizani kufufuza",
    productDetails: "Zambiri Zamalonda",
    youveSuccessfullyEditedThisCategory: "Mwasintha gawo ili bwinobwino",
    update: "Kusintha",
    categoryList: "Mndandanda Wamagulu",
    categories: "Magulu",
    enterQuantityToUpdate: "Lowetsani kuchuluka kuti musinthe",
    inventorySentSuccessfully: "Chiwerengerocho chinatumizidwa bwino!",
    updateInventory: "Sinthani Inventory",
    currentQuantity: "Kuchuluka Kwatsopano",
    pleaseEnterQuantityToAdd:
      "Chonde lowetsani kuchuluka komwe mukufuna kuwonjezera",
    pleaseSelectABranch: "Chonde sankhani Nthambi",
    searchForBranch: "Sakani nthambi",
    youCantSendMoreThanStock: "Simungatumize zambiri kuposa zomwe muli nazo",
    send: "Tumizani",
    pleaseEnterQuantityToSend:
      "Chonde lowetsani kuchuluka komwe mukufuna kutumiza",
    productNameIsRequiredOnRow: "dzina la mankhwala likufunika pamzere",
    productCategoryIsRequiredOnRow: "Gulu lazogulitsa limafunikira pamzere",
    productPriceIsRequiredOnRow: "Mtengo wazinthu umafunika pamzere",
    productUnitIsRequiredOnRow: "mankhwala unit chofunika pa mzere",
    productQuantityIsRequiredOnRow:
      "kuchuluka kwa mankhwala kumafunika pamzere",
    productVariantsRequireTracking: "mitundu yazogulitsa imafuna kutsatira!",
    pleaseAddVariantClickButton:
      "Chonde onjezani zosintha podina batani lowonjezera!",
    totalVariantsMoreThanSelectedQuantity:
      "Zosiyanasiyana Zonse ndizochulukirapo kuposa mankhwala osankhidwa, ma Pls amachepetsa kuchuluka kwa mitundu",
    productEditedSuccessfully: "Zida zasinthidwa bwino!",
    fileTooLargeLessThan4Mb:
      "Kukula kwa fayilo ndikokulirapo! Kukula kwa fayilo kuyenera kukhala kochepera 4MB.",
    suchVariantAlreadyExist: "Zosiyanazi zilipo kale",
    addVariants: "Onjezani Zosiyanasiyana",
    editProduct: "Sinthani Zida",
    pleaseEnterProductName: "Chonde Lowetsani Zamalonda",
    pleaseEnterProductPrice: "Chonde Lowani Mtengo Wazogulitsa",
    pleaseEnterProductOriginalPrice: "Chonde Lowetsani Zogulitsa Zoyambirira",
    productDescription: "Mafotokozedwe Akatundu",
    selectProductCategory: "Sankhani Gulu lazogulitsa",
    pleaseSelectProductCategory: "Chonde sankhani gulu lazogulitsa",
    productCostPrice: "Mtengo Mtengo Wazinthu",
    productSellingPrice: "Mtengo Wogulitsa Zamalonda",
    productOriginalPrice: "Mtengo Wapadera Wogulitsa",
    maxFileSizeAllowedIs4Mb: "Max. Kukula kwa Fayilo kololedwa ndi 4mb",
    productsWithPicturesArePublished:
      "Zida zomwe zili ndi zithunzi zimasindikizidwa pa Discover kuti mulandire maoda",
    shouldThisProductBeTracked: "Kodi izi ziyenera kutsatidwa?",
    pleaseSelectStockUnit: "Chonde sankhani stock stock",
    stockUnit: "Chigawo Chamasheya",
    bag: "Thumba",
    bottle: "MBOTO",
    bunch: "KUSINTHA",
    can: "KODI",
    carton: "Katoni",
    crate: "MALO OGULITSIRA",
    cup: "KAPU",
    dozen: "DOZEN",
    gigabytes: "MAGIGABI",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "ZOLEMBA",
    pack: "Phukusi",
    pair: "KUCHEZA",
    pieces: "Zidutswa",
    plate: "Mbale",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "Bwalo",
    pleaseEnterProductQuantity: "Chonde lowetsani kuchuluka kwa mankhwala",
    productQuantity: "Kuchuluka kwa Zogulitsa",
    isThisProductTaxed: "Kodi izi zimakhomeredwa msonkho?",
    selectTaxType: "Sankhani Mtundu Wamisonkho",
    pleaseSelectTaxType: "Chonde sankhani Mtundu Wamsonkho",
    progressive: "Kupita patsogolo",
    proportional: "Zofanana",
    pleaseEnterProductTaxRate: "Chonde lowetsani mitengo yamisonkho",
    doesProductHaveVariants: "Kodi mankhwalawa ali ndi mitundu?",
    type: "Lembani",
    value: "Mtengo",
    pleaseEnterVariantType: "Chonde lowetsani mtundu wamitundu",
    pleaseEnterVariantValue: "Chonde lowetsani mtengo wamitundu",
    pleaseEnterVariantPrice: "Chonde lowetsani mtengo wosiyanasiyana",
    pleaseEnterVariantQuantity: "Chonde lowetsani kuchuluka kwake",
    productDeletedSuccessfully: "Zogulitsa zachotsedwa bwino!",
    categoryDeletedSuccessfully: "Gulu lachotsedwa bwino!",
    toDeleteThisProduct: "kuchotsa Mankhwala",
    invalidProductQuantity: "Kuchuluka Kwazinthu Zamalonda",
    quantityAddedIsOverStock:
      "Kuchuluka komwe mukuwonjezera kwatha kuposa zomwe muli nazo.",
    itemInventoryNotTracked: "Kusunga katundu sikutsatiridwa",
    addBulkQuantity: "Onjezerani Zambiri",
    enterBulkQuantity: "Lowetsani Zambiri",
    pleaseEnterBulkQuantity: "Chonde lowetsani Zambiri",
    youveSuccessfullyAddedANewProduct: "Mwachita kuwonjezera chatsopano!",
    pleaseEnterProductSellingPrice: "Chonde lowetsani mtengo wogulitsa",
    doYouWantToTrackProductStock:
      "Kodi mukufuna kutsatira katundu wa mankhwala?",
    sellingPrice: "Kugulitsa Mtengo",
    setProductExpiryReminder: "Ikani Chikumbutso Chomaliza Chazogulitsa",
    productExpiryDate: "Tsiku Lotha Ntchito Zamalonda",
    startRemindingFrom: "Yambani Kukumbutsa Kuchokera",
    productSuppliesAddedSuccessfully: "Mwawonjezera katundu pazinthu bwino.",
    addProductSupplies: "Onjezani Zogulitsa",
    pleaseSelectSupplier: "Chonde Sankhani Wogulitsa",
    nameOfProduct: "Dzina La Zogulitsa",
    pleaseSelectProduct: "Chonde Sankhani Zogulitsa",
    productVariant: "Zosintha Zazinthu",
    pleaseSelectAVariant: "Chonde Sankhani Zosiyanasiyana",
    pleaseEnterUnitPrice: "Chonde Lowetsani Mtengo Wamtengo",
    businessBranch: "Nthambi Yabizinesi",
    pleaseSelectBranch: "Chonde sankhani nthambi",
    youveSuccessfullyAddedANewSupplier:
      "Mwawonjezera bwino wogulitsa watsopano",
    addSupplier: "Onjezani Wogulitsa",
    pleaseEnterSupplierEmail: "Chonde lowetsani imelo yaogulitsa",
    pleaseAddADescription: "Chonde onjezani kufotokozera",
    anErrorOccuredProductsDeleted:
      "Vuto linachitika pochita opaleshoniyi. Chonde dziwani, ndi zinthu zochepa zomwe zikadatha kuchotsedwa",
    bulkDelete: "Chotsani Chochuluka",
    youAreAboutToDelete: "Mukufuna kuchotsa",
    product: "Mankhwala",
    isDueToRedeem: "ikuyenera kuwomboledwa",
    aReward: "mphotho",
    pleaseSelectCustomerToReeemReward:
      "Chonde sankhani kasitomala kuti awombole mphotho.",
    youHaveNoLoyaltyProgramRunning:
      "Mulibe pulogalamu yokhulupirika yomwe ikugwira ntchito",
    customerHhasNoPointsInLoyaltyProgram:
      "Makasitomala alibe mfundo mu pulogalamu yokhulupirika iyi",
    proceedWithPayment: "Pitilizani ndi Kulipira?",
    youAreAboutToPayForTransactionUsingPoints:
      "Muli pafupi kulipira ntchitoyi pogwiritsa ntchito mfundo.",
    customerHas: "Makasitomala Ali",
    worth: "ofunika",
    andIsNotSufficientToPayForTransaction:
      "ndipo sikokwanira kulipira ntchitoyi. Kodi angafune kuwonjezera zotsalazo pogwiritsa ntchito njira ina yolipira?",
    addCustomerLoyalty: "Onjezani Kukhulupirika kwa Makasitomala",
    pleaseAddCustomerFirst:
      "Chonde onjezani kapena sankhani kasitomala choyamba.",
    pleaseWaitWhileWeGetReady: "Chonde dikirani, tikukonzekera",
    lowStock: "Kutsika Kwambiri",
    pleaseEnterAmountTendered: "Chonde lowetsani ndalama zomwe mwapereka",
    areYouSureToBookSaleOffline:
      "Mukutsimikiza kuti mukufuna kusungitsa malonda amenewa popanda intaneti?",
    saleWouldBeBookedAutomatically:
      "Kugulitsa kungasungidwe pokhapokha mutatsegula intaneti",
    offlineSalesBookingCancelled: "Kusungitsa malonda pa intaneti kwaletsedwa",
    covid19Message:
      "COVID19: Sambani m'manja ndi sopo kapena Sanitize kuti musafalikire. Khalani Otetezeka Nthawizonse",
    saleSuccessfullyRecorded: "Kugulitsa Kwasungidwa Bwino!",
    sendReceiptToEmail: "Tumizani risiti ku Imelo",
    sendThankYouSms: "Tumizani SMS yothokoza",
    sendShippingDetails: "Tumizani tsatanetsatane wa Kutumiza",
    addLoyalty: "Onjezani Kukhulupirika",
    searchCustomerNameOrNumber: "Sakani dzina la kasitomala kapena nambala",
    clickTheSearchCustomerBox:
      "Dinani Bokosi Lofufuzira Makasitomala ndikusanthula Khadi",
    enterProductPrice: "Lowani Mtengo Wazogulitsa",
    enterPriceFor: "Lowani Mtengo wa",
    searchForProduct: "Sakani zogulitsa",
    all: "Zonse",
    backToDashboard: "Bwererani ku Dashboard",
    viewDraftSales: "Onani Zoyeserera Zogulitsa",
    variantSelected: "zosankhidwa",
    variant: "zosiyanasiyana",
    thePreviousVariantSelectionNotAvailable:
      "Zosankha zam'mbuyomu sizipezeka pamitundu yatsopano yomwe yasankhidwa kutengera mtengo, chonde sinthani kusankha kwanu.",
    pleaseSupplyPositiveQuantityNumber: "Chonde perekani nambala yochulukira",
    lowStockFor: "Katundu wotsika wa",
    clearVariants: "Chotsani mitundu",
    pleaseEnterQuantity: "Chonde lowetsani Kuchuluka",
    picture: "Chithunzi",
    redemptionToken: "Chizindikiro cha Chiwombolo",
    token: "Chizindikiro",
    totalSpent: "Zonse Zomwe Mwawononga",
    confirmPayment: "Tsimikizani Malipiro",
    hasPaymentBeenMade: "Kodi Ndalama zasinthidwa bwino?",
    enterTransactionReference: "Lowetsani zomwe mumalipira nazo",
    pleaseSelectACustomer: "Chonde sankhani kasitomala!",
    areYouSureToApplyDiscount:
      "Mukutsimikiza kuti mukufuna kugwiritsa ntchito kuchotsera?",
    addYourBankAccountToEnableUssd:
      "Onjezani akaunti yanu yakubanki kuti mutsegule Instant USSD posachedwa ndi uPay",
    totalAmountToPay: "Ndalama Zolipira",
    doYouWantToCancelTransaction: "Kodi mukufuna kuletsa izi?",
    savePrintBill: "Sungani / Sindikizani Bill",
    enterAmountCollectedForCustomer:
      "Lowetsani ndalama zomwe asonkhanitsa makasitomala",
    recordSale: "Lembani Kugulitsa",
    checkOutWith: "Onani ndi",
    instantTransfer: "Choka Instant",
    dialTheUSSDCode: "Imbani nambala ya USSD",
    pleaseSelectABank: "Chonde sankhani banki",
    payWithUSSD: "Lipirani Ndi USSD",
    sendBillTo: " - Tumizani Bill ku",
    waitingForUSSDTransfer: "Kuyembekezera Kutumiza kwa USSD",
    percent: "Peresenti",
    applyDiscount: "Ikani Kuchotsera",
    thisBillHasBeenSaved: "Lamuloli lasungidwa",
    saveDraft: "Sungani Choyesera",
    pleaseTypeANameToIdentifyCustomer:
      "Chonde lembani dzina kuti mudziwe kasitomala",
    paymentDetails: "Zambiri Zamalipiro",
    basePrice: "Mtengo Woyamba",
    staff: "Ogwira ntchito",
    subTotal: "Chiwerengero",
    durationMonths: "Kutalika (miyezi)",
    selectADuration: "Sankhani Nthawi",
    oneMonth: "1 Mwezi",
    twoMonths: "Miyezi 2",
    threeMonths: "Miyezi 3",
    sixMonths: "Miyezi 6",
    twelveMonths: "Miyezi 12",
    eighteenMonths: "Miyezi 18",
    twentyFourMonths: "Miyezi 24",
    twoMonthsFree: "(Miyezi 2 Kwaulere)",
    threeMonthsFree: "(Miyezi 3 Kwaulere)",
    fiveMonthsFree: "(Miyezi 5 Kwaulere)",
    yourAccountHasBeen: "Akaunti yanu yakhala",
    renewed: "zatsopano",
    upgraded: "akweza",
    successfully: "bwino",
    yourSubscription: "Kulembetsa kwanu",
    youAreCurrentlyEnrolledOnA: "Tsopano mwalembetsa pa",
    pleaseChooseAPaymentOption: "Chonde Sankhani Njira Yolipira",
    planRenewal: "Konzani Kukonzanso",
    planUpgrade: "Konzani Kusintha",
    pleaseSelectDurationToPayFor:
      "Chonde Sankhani Nthawi yomwe mukufuna kulipira",
    staffAccounts: "Maakaunti Antchito",
    ecommerce: "Zamalonda",
    pleaseSelectAPlan: "Chonde sankhani dongosolo",
    includeAddons: "Phatikizani Zowonjezera",
    viewTransactions: "Onani Zogulitsa",
    customerHasNoCompletedTansactions:
      "Makasitomala alibe ma tansaction omaliza panobe",
    branch: "Nthambi",
    enterNumberOfEcommerceBranches: "Lowetsani Nambala Yanthambi Zamalonda",
    enterNumberOfEcommerceBranchesToPay:
      "Lowetsani Nambala ya Nthambi Zamalonda Zomwe Mukufuna Kulipira",
    ecommerceIntegration: "Kuphatikiza Kwama Ecommerce",
    enterNumberOfBranches: "Lowetsani Chiwerengero cha Nthambi",
    enterNumberOfAdditionalBranchesToPay:
      "Lowetsani Chiwerengero cha Nthambi Zowonjezera Zomwe Mukufuna Kulipira",
    enterNumberOfStaffs: "Lowetsani Chiwerengero cha Ogwira Ntchito",
    enterNumberOfStaffsToPayFor:
      "Lowetsani Chiwerengero Cha Ogwira Ntchito Mukufuna Kulipira",
    discountApplies: "Kuchotsera Kumagwira",
    starsOnThe: "nyenyezi pa",
    offer: "kupereka",
    get: "Pezani",
    moreStarsToRedeem: "nyenyezi zambiri zoti ziwombole",
    taxVat: "Misonkho (VAT)",
    callCashierToCompletePayment: "Imbani Cashier kuti Mumalize Kulipira",
    receipt: "Chiphaso",
    dear: "Wokondedwa",
    thankYouForYourOrderFindGoods:
      "Zikomo chifukwa cha oda yanu. Chonde pezani izi:",
    shippingNote: "Kutumiza Chidziwitso",
    enterShippingNote: "Lowetsani Kutumiza",
    shippingAddress: "Adilesi Yakotumiza",
    enterShippingAddress: "Lowetsani adilesi yotumiza",
    wellDoneYouAreDueToRedeem: "Mwachita bwino! Muyenera kuti muwombole",
    toGetYourRewardNextVisit:
      "kuti mudzalandire mphotho yanu mukadzabweranso. Zikomo",
    pointsOnThe: "Zolemba pa",
    morePointsToRedeem: "mfundo zina zowombola",
    showCode: "Onetsani nambala",
    toGetYourRewardOnNextVisit:
      "kuti mudzalandire mphotho yanu mukadzabweranso. Zikomo",
    earn: "Zimadzetsa",
    delivaryNote: "Chidziwitso",
    draftSales: "Zolemba Zogulitsa",
    startDate: "Tsiku loyambira",
    endDate: "Tsiku lomaliza",
    orders: "Malamulo",
    checkout: "Onani",
    noProductItem: "Palibe Katundu Wogulitsa",
    selectProductImage: "Sankhani Zithunzi Zamtundu",
    selectCountry: "Sankhani dziko",
    selectRegion: "Sankhani Boma / Chigawo",
    printProductTag: "Sindikizani Tag Tag",
    transactionReference: "Kutengera zochitika",
    Cashier: "Wopereka ndalama",
    Manager: "Woyang'anira",
    Owner: "Mwini",
    Admin: "Kuwongolera",
    addPartners: "Onjezani Othandizira",
    addNewLoyaltyPartner: "Onjezani New Loyalty Partner",
    pleaseEnterCompanyName: "Chonde lowetsani Dzina la Kampani",
    companyName: "Dzina Lakampani",
    pleaseEnterCompanyRepName: "Chonde lowetsani dzina loimira kampani",
    companyRepName: "Dzina la Woimira Kampani",
    pleaseEnterCompanyRepEmail: "Chonde lowetsani imelo ya woimira kampani",
    companyRepEmail: "Imelo ya kampani rep",
    pleaseEnterCompanyRepPhone:
      "Chonde lowetsani nambala yafoni ya woimira kampani",
    companyRepPhone: "Nambala yafoni ya rep kampani",
    addReward: "Onjezani mphotho",
    pleaseEnterRewardName: "Chonde lowetsani dzina la mphotho",
    rewardName: "Dzina la Mphotho",
    rewardQuantity: "Kuchuluka kwa Mphotho",
    rewardDescription: "Kufotokozera kwa Mphotho",
    rewardType: "Mtundu wa Mphotho",
    pleaseEnterRewardType: "Chonde lowetsani mtundu wa mphotho",
    pleaseEnterRewardQuantity: "Chonde lowetsani kuchuluka kwa mphotho",
    pleaseEnterRewardDescription: "Chonde lowetsani tanthauzo la mphotho",
    fineDining: "Kudya Kwabwino",
    luxuryFashion: "Mafashoni Apamwamba",
    hotels: "Mahotela",
    travel: "Ulendo",
    foodAndBeverage: "Chakudya ndi Chakumwa",
    fashion: "Mafashoni",
    health: "Thanzi",
    furniture: "Mipando",
    entertainment: "Zosangalatsa",
    automobile: "Galimoto",
    education: "Maphunziro",
    beautyAndSpa: "Kukongola ndi Spa",
    staycation: "Kukhala",
    events: "Zochitika",
    trips: "Maulendo",
    oilAndGas: "Mafuta ndi Gasi",
    laundry: "Kuchapira",
    partnerCategory: "Gawo la Partner",
    freeItem: "Chinthu Chaulere",
  },
  "Chinese (Simplified)": {
    cashier: "出纳员",
    manager: "经理",
    owner: "所有者",
    online: "在线的",
    offline: "离线",
    changePassword: "更改密码",
    currentPasswordMessage: "请输入您当前的密码",
    passwordMessage: "请输入您的密码",
    currentPassword: "当前密码",
    password: "密码",
    confirmPasswordMessage: "请确认您的密码！",
    confirmPassword: "确认密码",
    sendViaEmail: "通过电子邮件发送",
    sendViaWhatsapp: "通过 WhatsApp 发送",
    downloadPdf: "下载PDF",
    paid: "有薪酬的",
    unpaid: "未付",
    partial: "部分的",
    closeInvoice: "您要关闭发票吗？",
    closeInvoiceConfirmation: "发票可能无法保存。你想关闭吗？",
    yes: "是的",
    no: "不",
    invoice: "发票",
    wasDeducted: "被扣除",
    for: "为了",
    item: "物品",
    addProduct: "添加产品",
    paymentReference: "付款凭据",
    amountPaid: "支付的金额",
    discountAmount: "折扣金额",
    amountDue: "应付金额",
    amount: "数量",
    dueDate: "到期日",
    paymentType: "支付方式",
    card: "卡片",
    cash: "现金",
    bankTransfer: "银行转帐",
    paymentMessage: "付款信息",
    description: "描述",
    sendReceipt: "发送收据",
    delete: "删除",
    save: "节省",
    resend: "重发",
    saveAndSend: "节省",
    invoiceSaved: "发票已保存！",
    selectPaymentMethod: "请选择付款方式！",
    selectCustomer: "请选择一个客户！",
    cartEmptyError: "购物车列表不能为空，关闭发票并将商品添加到购物车！",
    subscriptionExpired:
      "您的订阅已过期，您的帐户现在受到限制。单击下面的按钮更新您的帐户",
    renew: "更新",
    holdOn: "坚持，稍等",
    customerBank: "客户银行",
    cancel: "取消",
    selectACustomer: "选择客户",
    invoiceSuccessfulPdfError:
      "发票创建成功，但 PDF 生成时间比平时长。请稍后再回来查看。",
    downloadingInvoice: "下载发票",
    downloadingReceipt: "下载收据",
    whatsappReceiptError: "通过 WhatsApp 发送收据时发生错误，请重试。",
    receiptToWhatsapp: "收据转发给WhatsApp",
    thankYouForPatronage: "谢谢您的光顾",
    hereIsYourReceipt: "这是您的付款收据",
    errorSendingEmailReceipt:
      "通过电子邮件发送收据时发生错误，请重试或联系支持",
    receiptSentToEmail: "收据已发送至客户邮箱",
    invoiceSentToEmail: "发票已发送至客户邮箱",
    invoiceSuccessWhatsappError:
      "发票创建成功，但发送到 WhatsApp 时发生错误。在发票清单上重试",
    invoiceSuccessfulEmailError:
      "发票创建成功，但作为电子邮件发送时发生错误。请从发票列表中重试",
    invoiceSentToWhatsapp: "发票已转发至 WhatsApp",
    hello: "你好",
    pleaseDownloadInvoice: "请下载发票",
    pleaseDownloadReceipt: "请下载收据",
    from: "从",
    email: "电子邮件",
    upgrade: "升级",
    youAreOnFreePlan: "您使用的是免费计划。",
    clickOn: "点击",
    yourPlanInFewSteps: " 只需几个快速步骤即可订阅。",
    to: "到",
    yourSubscriptionHasExpired: "您的订阅已过期，您的帐户现在受到限制。",
    days: "天",
    yourSubscriptionExpiresIn: "您的 Loystar 订阅在",
    createAcount: "创建一个帐户",
    signIn: "登入",
    continue: "继续",
    enterOtp: "输入一次性密码",
    enterValidOtp: "请输入有效的 PIN 码",
    pin: "别针",
    tokenSentToMail: "令牌已发送到您的电子邮件",
    passwordResetSuccessful: "密码重置成功",
    somethingWentWrong: "出了些问题！",
    resetPassword: "重设密码",
    iHaveResetCode: "我有密码重置码",
    pleaseEnterEmail: "请输入您的电子邮件",
    aTokenWillBeSentToEmail: "令牌将发送到您的电子邮件",
    enterEmail: "输入你的电子邮箱",
    sendinYourToken: "正在发送您的令牌...",
    makeSureItMatchesPassword: "确保它与您的新密码匹配",
    pleaseChooseNewPassword: "请选择一个新密码",
    chooseNewPassword: "选择一个新密码",
    enterNewPassword: "输入您的新密码进行确认",
    enterTokenSent: "输入发送给您的邮件的令牌",
    resetToken: "重置令牌",
    resettingPassword: "重置密码...",
    signUp: "报名",
    adminSignInWithEmail: " 管理员使用电子邮件登录，而员工使用用户名登录。",
    pleaseEnterEmailOrUsername: "请输入您的电子邮件或用户名",
    emailOrUsername: "电子邮件或用户名",
    pleaseEnterPassword: "请输入密码",
    createAnAccount: "创建一个帐户",
    forgotPassword: "忘记密码？",
    enterPhoneNumber: "输入电话号码",
    personalData: "个人资料",
    validateConfirmationCOde: "验证确认码",
    pleaseEnterFirstName: "请输入您的名字",
    pleaseEnterPhoneNumber: "请输入您的电话号码",
    pleaseEnterLastName: "请输入您的姓氏",
    pleaseEnterBusinessName: "请输入您的公司名称",
    firstName: "名",
    lastName: "姓",
    businessName: "企业名称",
    previous: "以前的",
    next: "下一个",
    pleaseSelectBusinessCategory: "请选择您的业务类别",
    pleaseEnterValidEmail: "请输入有效电子邮件",
    pleaseEnterPostCode: "请输入邮政编码",
    postCode: "邮政编码",
    phoneNumberInUse: "此电话号码已被使用！",
    emailInUse: "此电子邮件已被使用！",
    foodAndDrinks: "食品和饮料",
    salonAndBeauty: "沙龙和美容",
    fashionAndAccessories: "时装和配饰",
    gymAndFitness: "健身房和健身",
    travelAndHotel: "旅游和酒店",
    homeAndGifts: "家居及礼品",
    washingAndCleaning: "洗涤和清洁",
    gadgetsAndElectronics: "小工具和电子产品",
    groceries: "杂货",
    others: "其他",
    submit: "提交",
    accountCreatedSuccessful: "您的帐户已成功创建。",
    pleaseEnterAddress: "请输入您的地址",
    addressLine1: "地址第一行",
    addressLine2: "地址行 2",
    choosePassword: "选择密码",
    passwordMustBe6Characters: "密码必须至少6个字符。",
    howDidYouHearLoystar: "您是如何得知 Loystar 的？",
    referralCode: "推荐代码",
    byClickingTheButton: " 点击下方按钮，即表示您同意",
    termsAndSevice: "条款",
    wereCreatingAccount: "我们正在创建您的帐户",
    proceed: "继续",
    verificationCodeMustBe6: "验证码必须是 6 位数字",
    pleaseEnter6DigitCode: "请输入6位数代码",
    enterVerificationCode: "输入验证码",
    resendToken: "重新发送令牌",
    verify: "核实",
    transactions: "交易",
    todaySales: "今天的销售",
    salesHistory: "销售历史",
    supplyHistory: "供应历史",
    new: "新的",
    invoices: "发票",
    disbursements: "支出",
    offlineSales: "线下销售",
    products: "产品",
    customers: "顾客",
    multiLevelLoyalty: "多层次的忠诚",
    loyaltyPrograms: "忠诚度计划",
    members: "会员",
    appStore: "应用商店",
    orderMenu: "订购菜单",
    settings: "设置",
    staffAndBranches: "员工和分支机构",
    myAccount: "我的账户",
    switchToSellMode: "切换到销售模式",
    signOut: "登出",
    getFreeSubscription: "获得免费订阅",
    onlyNumbersAllowed: "只允许数字",
    yourAccountMustBe10Digits: "您的帐号必须是 10 位数字",
    yourBvnMustBe11: "您的 BVN 必须是 11 位数字",
    pleaseSelectBank: "请选择您的银行",
    selectYourBank: "选择您的银行",
    enterBankAccountNumber: "输入银行帐号",
    enterBvnNumber: "输入您的 BVN",
    connectBankAccount: "连接银行账户",
    passwordResetSuccessfulAndSignOut:
      "您的密码已成功重置。点击下方的退出按钮再次登录",
    enterCurrentPassword: "输入当前密码",
    pleaseEnterCurrentPassword: "请输入当前密码",
    phoneNumber: "电话号码",
    sex: "性别",
    dateOfBirth: "出生日期",
    state: "状态",
    country: "国家",
    loyaltyId: "忠诚度 ID",
    createdAt: "创建于",
    noOfTransactionsMade: "交易次数",
    yourDownloadWillStart: "您的下载将在稍后开始",
    exportCustomers: "出口客户",
    youhaveSuccessfullyToppedUp: "您已成功为您的 SMS 单元充值。",
    youNowHave: "你现在有",
    smsUnits: "短信单位",
    enterNumberOfSmsUnits: "输入您打算购买的短信数量",
    pleaseEnterANumericValue: "请输入数值",
    pay: "支付",
    accountEditedSuccessfully: "您的帐户已成功编辑。",
    youAeCurrentlyOn: "你目前在",
    plan: "计划",
    userData: "用户数据",
    businessData: "业务数据",
    businessCategory: "业务类别",
    pleaseSelectCurrency: "请选择您的货币",
    selectYourCurrency: "选择您的货币",
    purchaseMoreSmsUnits: "使用以下表格购买更多 SMS 单元",
    youHave: "你有",
    atLeast4SmsUnitsRrequired: "至少需要4条短信验证，请充值！",
    pleaseVerifyYourAccountToComplyWithKyc:
      "请验证您的银行帐户以符合“了解您的客户”(KYC) 要求。这将允许您通过 USSD 或即时转账收款、接收客户订单并处理交易结算。仅适用于尼日利亚商家。请按下面的按钮开始。",
    reConnectBankAccount: "重新连接银行账户",
    accountName: "用户名",
    accountNumber: "帐号",
    bankName: "银行名",
    verified: "已验证",
    accountDetails: "帐户详细资料",
    kycBankAccount: "了解您的客户",
    createTier: "创建层",
    fileUploadSuccessful: "文件上传成功",
    fileUploadFailed: "文件上传失败",
    createLoyaltyProgram: "创建忠诚度计划",
    createLoyalty: "建立忠诚度",
    name: "姓名",
    loyaltyArtwork: "忠诚艺术品",
    clickToUpload: "点击上传",
    amountToPointsRatio: "积分比",
    points: "积分",
    recommendedAmountToPointRatio:
      "推荐：₦1到1点。即每消费 1 奈拉，您的客户就会获得 1 分",
    pleaseTypeIn: "请输入",
    toDeleteLoyalty: "删除此忠诚度",
    deleteLoyalty: "删除忠诚度",
    toConfirm: "确认",
    edit: "编辑",
    pointsAwardedSuccessfully: "成功获得积分。",
    enterPointValueToAward: "输入您想奖励客户的积分值",
    award: "奖",
    awardPointValuesToCustomer: "对客户的奖励积分价值",
    enrollMembersToLoyalty: "注册会员忠诚度",
    awardPoints: "奖励积分",
    enroll: "注册",
    home: "家",
    loyalty: "忠诚",
    enrollCustomers: "注册客户",
    selected: "已选",
    customer: "顾客",
    loyaltyActivationSuccessful: "忠诚度激活成功。",
    loyaltyDeactivationSuccessful: "忠诚度停用成功。",
    viewTier: "查看层",
    deactivate: "停用",
    activate: "启用",
    actions: "行动",
    nameOfLoyalty: "忠诚之名",
    loyaltyStatus: "忠诚度状态忠诚度状态",
    numberOfTiers: "层数",
    createdOn: "创建于",
    createATier: "创建层",
    stopCreatingTierConfirmation: "是否要停止创建层？",
    stopEditingTierConfirmation: "您要停止编辑此层吗？",
    nameOfTier: "层名",
    minimumSpendingTarget: "最低消费目标",
    maximumSpendingTarget: "最大支出目标",
    minimumSpendingTargetRequired: "最低消费目标是必需的",
    maximumSpendingTargetRequired: "需要最大支出目标",
    rewardSponsor: "奖励赞助商",
    pleaseChooseARewardSponsor: "请选择奖励赞助商",
    self: "自己",
    partner: "伙伴",
    rewardPartner: "奖励合作伙伴",
    pleaseSelectRewardPartner: "请选择您的奖励合作伙伴",
    rewards: "奖励",
    pleaseSelectAReward: "请选择奖励",
    instructionsOnRedeemingReward: "客户应如何兑换奖励的说明",
    pleaseFillInThisField: "请填写此字段！",
    toDeleteThisTier: " 删除此层",
    deleteTier: "删除层",
    viewMembers: "查看成员",
    membersEnrolled: "会员注册",
    instruction: "操作说明",
    membersIn: "成员在",
    availableTiersInLoyalty: "忠诚度计划中的可用层级",
    tiers: "等级",
    totalTier: "总层",
    availableLoyaltyProgramme: "可用的忠诚度计划",
    totalLoyalties: "总忠诚度",
    memberDetails: "会员详情",
    nameOfCustomer: "客户姓名",
    address: "地址",
    allEnrolledMembers: "所有注册会员",
    thisIsToWishYouHappyBirthday:
      "谨此祝您生日快乐，生活兴旺。感谢您对 Loyster 所做的一切。欢庆快乐！",
    inputAnOfferPlease: "请输入报价",
    pleaseSelectTheInsertPoint: "请选择消息中的插入点并再次单击",
    birthdayOfferAndMessage: "生日优惠及留言",
    birthdayOffer: "生日优惠",
    birthdayMessage: "生日留言",
    noOfferFound: "没有找到优惠",
    settingABirthdayOffer: "设置生日优惠可让客户在生日当天通过短信收到此优惠",
    createOffer: "创建报价",
    whatIsTheOffer: "优惠是什么？",
    editMessage: "编辑消息",
    insert: "插入",
    theNameOfCustomerInserted: "客户姓名将插入此处",
    theBirtdayOfferInserted: "生日优惠将插入此处",
    youSuccessfullyAddedNewBranch: "您已成功添加新分支！",
    addNewBranch: "添加新分支",
    addBranch: "添加分支",
    additionalBranchWillIncur: "额外的分支会产生",
    perBranch: "每个分行",
    ecommerceBranchCosts: "电子商务分支机构费用",
    pleaseEnterBranchName: "请输入分行名称",
    pleaseEnterBranchAddress1: "请输入分行地址第 1 行",
    enterBranchAddress1: "输入分行地址行 1",
    enterBranchAddress2: "输入分行地址行 1",
    pleaseEnterBranchAddress2: "请输入分行地址第 2 行",
    enterBranchName: "输入分行名称",
    successfullyAddedStaff: "您已成功添加新员工！",
    addNewStaff: "添加新员工",
    addStaff: "添加员工",
    additionalStaffWillIncur: "额外的工作人员将招致",
    perStaff: "每名员工。",
    pleaseEnterStaffEmail: "请输入工作人员的邮箱",
    pleaseEnterStaffUsername: "请输入工作人员的用户名",
    pleaseEnterStaffPassword: "请输入工作人员的密码",
    pleaseSelectStaffRole: "请选择工作人员的角色",
    selectStaffRole: "选择员工的角色",
    enterStaffEmail: "输入工作人员的电子邮件",
    enterStaffUsername: "输入工作人员的用户名",
    enterStaffPassword: "输入员工密码",
    spacesNotAllowedInUsername: "用户名中不允许有空格",
    admin: "行政",
    pleaseSelectBusinessToAttachStaff: "请选择要附加员工的业务",
    searchForBranchToAttachStaff: "寻找分行附加员工",
    username: "用户名",
    role: "角色",
    areYouSureToDeleteThis: "你确定要删除这个吗",
    branches: "分行",
    upgradeYourPlan: "升级您的计划。",
    add: "添加",
    addNew: "添新",
    customerWithEmailAlreadyExists: "具有电子邮件/电话号码的客户已存在！",
    successfullyAddedNewCustomer: "您已成功添加新客户！",
    addCustomer: "添加客户",
    pleaseEnterCustomerFirstName: "请输入客户的名字",
    pleaseEnterCustomerLastName: "请输入客户的姓氏",
    pleaseEnterCustomerPhoneNumber: "请输入客户的电话号码",
    pleaseEnterCustomerEmail: "请输入客户的电子邮件",
    pleaseEnterCustomerAddressLine: "请输入客户的地址行",
    pleaseEnterCustomerOtherAddress: "请输入客户的其他地址",
    pleaseSelectCustomerGender: "请选择客户的性别",
    gender: "性别",
    male: "男性",
    female: "女性",
    bank: "银行",
    selectBank: "选择银行",
    stateOrRegionOrProvince: "州/地区/省",
    customerNotes: "客户备注",
    sendSms: "发简讯",
    editCustomer: "编辑客户",
    redeemReward: "兑换奖励",
    issueLoyaltyCard: "发行会员卡",
    deleteCustomer: "删除客户",
    youveSuccessfullyAssignedLoyaltyMembership: "您已成功分配忠诚度会员 ID",
    noMembershipIdAvailable:
      "没有可用的会员 ID。请通过 hello@loystar.co 与我们联系",
    sendEmail: "发电子邮件",
    membershipPoints: "会员积分",
    customerDetails: "顾客信息",
    close: "关闭",
    loyaltyBalance: "忠诚度余额",
    pointsBalance: "积分余额",
    starBalance: "星平衡",
    requiredPoints: "所需积分",
    requiredStars: "所需星星",
    reddemCode: "兑换码",
    toDeleteThisCustomer: "删除此客户",
    customerHasBeenDeletedSuccessfully: "客户已成功删除！",
    customerWithPhoneAlreadyExists: "有电话号码的客户已存在",
    customerWasSuccessfullyEdited: "客户已成功编辑",
    anErrorOccured: "发生错误",
    phoneNumberIncludeCountry: "电话号码（包括国家代码）",
    yourFileQueuedForUpload: "您的文件已排队等待上传。请在几秒钟后刷新页面。",
    thereWasAnErrorPerformingOperation: "执行操作时出错！",
    pleaseSelectFile: "请选择一个文件！",
    oopsOnlyCsvFilesAllowed: "哎呀！仅允许使用 CSV 文件。请上传 .csv 文件。",
    fileShouldBeSmallerThan5Mb:
      "文件应小于 5MB。如果您有更大的文件，请发送电子邮件至 support@loystar.co。谢谢",
    customerFirstNameIsRequired: "行上需要客户的名字",
    customerPhoneNumberIsRequired: "行上需要客户电话号码",
    importCustomers: "进口客户",
    upload: "上传",
    clickIconToDownloadCsv: "单击此图标可下载 CSV 文件模板。",
    getGoogleSheetVersion: "获取 Google 表格版本",
    clickOrDragFileToUpload: "单击或拖动文件到此区域进行上传",
    missingOrInvalidColumnHeader:
      "缺少或无效的列标题。请遵循模板格式。谢谢你。",
    youHaveImported: "您已导入",
    youSuccessfullyRedeemedReward: "您已成功兑换奖励！",
    sixDigitCode: "六位数代码",
    pleaseEnterCustomerRewardCode: "请输入客户的奖励代码",
    enterRewardCode: "输入奖励代码。（奖励代码区分大小写）",
    selectLoyaltyProgram: "选择忠诚度计划",
    stamps: "邮票",
    smsUnitsLow: "SMS 单位低",
    pleaseTopUpSmsUnits: "请充值短信单位",
    smsSuccessfullySentForDelivery: "短信发送成功！",
    sendSmsTo: "发送短信至",
    allCustomers: "所有客户",
    unitsAvailable: "可用单位",
    pleaseTypeInTheMessage: "请输入留言",
    message: "信息",
    charactersRemaining: "剩余字符",
    avoidUseOfSpecialCharacters: "避免使用特殊字符和表情符号来节省 SMS 单元。",
    note: "笔记",
    errorFetchingCustomersMultilevelDetail: "获取客户多级详细信息时出错",
    search: "搜索",
    reset: "重启",
    importCustomer: "进口客户",
    addNewCustomer: "添加新客户",
    sendSmsBroadcast: "发送短信广播",
    totalCustomers: "客户总数",
    disbursementDetails: "付款明细",
    paidBy: "由...支付",
    disbursed: "已支付",
    bankAccountName: "银行账户名称",
    bankAccountNumber: "银行帐号",
    transferInitiated: "传输启动",
    transferCompleted: "转移完成",
    pleaseEnterAValid: "请输入一个有效的",
    begin: "开始",
    end: "结尾",
    date: "日期",
    paymentDate: "支付日期",
    selectDisbursementDuration: "选择支付期限",
    totalSettled: "总结算",
    totalUnsettled: "总未结算",
    toDeleteThisSale: "删除此销售",
    draftSaleDeletedSuccessfully: "草稿销售已成功删除！",
    deleteSale: "删除销售",
    pleaseTypeInYourTotal: "请输入您的总数以确认删除",
    billDetails: "账单详情",
    printBill: "打印账单",
    servedBy: "服务于",
    total: "全部的",
    createdDate: "创建日期",
    createdTime: "创建时间",
    status: "地位",
    loginSuccessful: "登陆成功",
    pleaseWaitWhileWeConnectAccount: "我们正在连接您的帐户，请稍候",
    connectionFailedTryAgain: "连接失败。请再试一次。",
    connectionSuccessful: "连接成功",
    viewDetails: "查看详情",
    enable: "使能够",
    free: "自由",
    cost: "成本",
    visitWebsite: "访问网站",
    pleaseUpgradeYourPlanToPro: "请将您的计划升级到 Pro Plus 以启用此应用",
    connectYourBankAccount: "连接您的银行帐户以接收付款。",
    disable: "禁用",
    enableApp: "启用应用程序",
    addNewProductToInvoice: "将新产品添加到发票",
    toDeleteThisInvoice: "删除此发票",
    invoiceDeletedSuccessfully: "发票已成功删除！",
    deleteInvoice: "删除发票",
    youveSuccessfullyAddedInvoicePaymentMessage: "您已成功添加发票付款消息！",
    pleaseEnterPaymentInstructions: "请输入付款说明",
    invoiceId: "发票编号",
    paidDate: "支付日期",
    reference: "参考",
    productAdded: "添加产品",
    productOutOfStock: "产品缺货。请补货。",
    totalInvoices: "总发票",
    totalPaidInvoices: "总已付发票",
    totalUnpaidInvoices: "未付发票总额",
    loyaltyProgramDeleted: "忠诚度计划已删除",
    thisLoyaltyProgramDeletedSuccessfully: "此忠诚度计划已成功删除",
    thisLoyaltyProgramEditedSuccessfully: "此忠诚度计划已成功编辑",
    loyaltyProgramAddedSuccessfully: "忠诚度计划已成功添加",
    loyaltyProgramEdited: "忠诚度计划已编辑",
    loyaltyProgramAdded: "添加了忠诚度计划",
    loyaltyDetails: "忠诚度详情",
    doYouWantToCloseDialog: "您要关闭此对话框吗？",
    pleaseEnterLoyaltyName: "请输入您的忠诚度名称",
    programType: "节目类型",
    pleaseSelectProgramType: "请选择程序类型",
    simplePoints: "简单点",
    stampsProgram: "邮票计划",
    threshold: "临界点",
    pleaseEnterLoyaltyThreshold: "请输入忠诚度门槛",
    reward: "报酬",
    pleaseEnterLoyaltyReward: "请输入忠诚奖励",
    totalUserPoints: "总用户积分",
    totalUserStamps: "总用户邮票",
    spendingTarget: "消费目标",
    spendingTargetHint1:
      "支出目标是客户必须花费多少才能获得奖励。 1 货币价值 = 1 点。",
    spendingTargetHint2: "邮票目标是客户必须收集多少邮票才能获得奖励。例如。 5",
    addNewLoyaltyProgram: "添加新的忠诚度计划",
    addLoyaltyProgram: "添加忠诚度计划",
    loyaltyProgramType: "忠诚度计划类型",
    pleaseSelectLoyaltyProgramType: "请选择忠诚度计划类型",
    nameOfProgram: "程序名称",
    pleaseEnterProgramName: "请输入程序名称",
    whatIsTheReward: "奖励是什么？",
    egNextPurchaseIsFree: "例如。下次购买免费",
    customerName: "顾客姓名",
    guestCustomer: "来宾客户",
    orderConfirmedSuccessfully: "订单确认成功",
    orderCancelledSuccessfully: "订单取消成功",
    confirmOrder: "确认订单",
    cancelOrder: "取消订单",
    allOrders: "所有订单",
    totalOrders: "总订单",
    doYouWantToAcceptOrder: "您要接受此订单吗？",
    doYouWantToCancelOrder: "您要取消此订单吗？",
    orderDetails: "订单详细信息",
    orderCreatedAt: "订单创建于",
    supplier: "供应商",
    productName: "产品名称",
    quantity: "数量",
    unitPrice: "单价",
    receivedBy: "接收人",
    reportFrom: "报告来自",
    totalSupplies: "总供应量",
    allRightsReserved: "版权所有",
    toDeleteThisTransaction: "删除此交易",
    transactionDeletedSuccessfully: "交易已成功删除。库存已退回库存。",
    deleteTransaction: "删除交易",
    transactionDetails: "交易明细",
    printReceipt: "打印收据",
    channel: "渠道",
    discount: "折扣",
    profit: "利润",
    discountedSales: "折扣销售",
    errorFetchingRecord: "获取记录时出错",
    exportTransactions: "出口交易",
    errorFetchingSalesRecord: "获取导出的销售记录时出错。",
    totalSellingPrice: "总售价",
    totalCostPrice: "总成本价",
    appliedDiscount: "应用折扣",
    noOfItems: "物品数量",
    sales: "销售量",
    export: "出口",
    totalProfit: "总利润",
    totalBalanceInPeriod: "期间总余额",
    allTimeSales: "全时销售",
    records: "记录",
    todaysSales: "今天的销售",
    transaction: "交易",
    youHaveExceededTotalNumberOfProducts:
      "您已超出计划允许的产品总数。升级您的计划以享受更高的限额。",
    youNeedToHaveLoyaltyProgram: "您需要有忠诚度计划才能使用此功能！",
    price: "价钱",
    category: "类别",
    stockTracking: "股票追踪",
    stockCount: "库存数量",
    taxed: "征税",
    originalPrice: "原价",
    costPrice: "成本价格",
    unit: "单元",
    productImage: "产品图片",
    taxRate: "税率",
    taxType: "税种",
    trackInventory: "跟踪库存",
    variants: "变体",
    hasVariants: "有变体",
    importProduct: "进口产品",
    exportProducts: "出口产品",
    addNewProduct: "添加新产品",
    viewCategory: "查看类别",
    viewSuppliers: "查看供应商",
    receiveInventory: "接收库存",
    printAllProductsTag: "打印所有产品标签",
    deleteAll: "删除所有",
    totalProducts: "总产品",
    youveSuccessfullyAddedANewCategory: "您已成功添加新类别",
    addNewCategory: "添加新类别",
    addCategory: "添加类别",
    categoryName: "分类名称",
    pleaseEnterCategoryName: "请输入类别名称",
    stampsTarget: "邮票目标",
    sendInventory: "发送库存",
    productDetails: "产品详情",
    youveSuccessfullyEditedThisCategory: "您已成功编辑此类别",
    update: "更新",
    categoryList: "类别列表",
    categories: "类别",
    enterQuantityToUpdate: "输入要更新的数量",
    inventorySentSuccessfully: "库存已成功发送！",
    updateInventory: "更新库存",
    currentQuantity: "当前数量",
    pleaseEnterQuantityToAdd: "请输入您要添加的数量",
    pleaseSelectABranch: "请选择分行",
    searchForBranch: "搜索分行",
    youCantSendMoreThanStock: "您不能发送超过库存的数量",
    send: "发送",
    pleaseEnterQuantityToSend: "请输入您要发送的数量",
    productNameIsRequiredOnRow: "行上需要产品名称",
    productCategoryIsRequiredOnRow: "行上需要产品类别",
    productPriceIsRequiredOnRow: "行上需要产品价格",
    productUnitIsRequiredOnRow: "行上需要产品单位",
    productQuantityIsRequiredOnRow: "行上需要产品数量",
    productVariantsRequireTracking: "产品变体需要跟踪！",
    pleaseAddVariantClickButton: "请单击添加按钮添加变体！",
    totalVariantsMoreThanSelectedQuantity:
      "总变体超过所选产品数量，请减少变体数量",
    productEditedSuccessfully: "产品编辑成功！",
    fileTooLargeLessThan4Mb: "文件过大！文件大小应小于 4MB。",
    suchVariantAlreadyExist: "这种变体已经存在",
    addVariants: "添加变体",
    editProduct: "编辑产品",
    pleaseEnterProductName: "请输入产品名称",
    pleaseEnterProductPrice: "请输入产品价格",
    pleaseEnterProductOriginalPrice: "请输入产品原价",
    productDescription: "产品描述",
    selectProductCategory: "选择产品类别",
    pleaseSelectProductCategory: "请选择产品类别",
    productCostPrice: "产品成本价",
    productSellingPrice: "产品售价",
    productOriginalPrice: "产品原价",
    maxFileSizeAllowedIs4Mb: "最大限度。允许的文件大小为 4mb",
    productsWithPicturesArePublished: "有图片的产品在发现上发布以接收订单",
    shouldThisProductBeTracked: "这个产品应该被追踪吗？",
    pleaseSelectStockUnit: "请选择库存单位",
    stockUnit: "股票单位",
    bag: "包",
    bottle: "瓶子",
    bunch: "束",
    can: "能够",
    carton: "纸盒",
    crate: "箱",
    cup: "杯子",
    dozen: "打",
    gigabytes: "技嘉",
    gram: "公克",
    kilogram: "公斤",
    litre: "升",
    pack: "盒",
    pair: "一对",
    pieces: "件",
    plate: "盘子",
    tonne: "吨 (吨)",
    uNIT: "单元",
    yard: "院子",
    pleaseEnterProductQuantity: "请输入产品数量",
    productQuantity: "产品数量",
    isThisProductTaxed: "这个产品有税吗？",
    selectTaxType: "选择税种",
    pleaseSelectTaxType: "请选择税种",
    progressive: "进步",
    proportional: "成比例的",
    pleaseEnterProductTaxRate: "请输入产品税率",
    doesProductHaveVariants: "这个产品有变体吗？",
    type: "类型",
    value: "价值",
    pleaseEnterVariantType: "请输入变体的类型",
    pleaseEnterVariantValue: "请输入变体的值",
    pleaseEnterVariantPrice: "请输入变体的价格",
    pleaseEnterVariantQuantity: "请输入变体的数量",
    productDeletedSuccessfully: "产品已成功删除！",
    categoryDeletedSuccessfully: "分类已成功删除！",
    toDeleteThisProduct: "删除此产品",
    invalidProductQuantity: "产品数量无效",
    quantityAddedIsOverStock: "您添加的数量超过了您的库存数量。",
    itemInventoryNotTracked: "不跟踪物品库存",
    addBulkQuantity: "添加散装数量",
    enterBulkQuantity: "输入批量",
    pleaseEnterBulkQuantity: "请输入批量",
    youveSuccessfullyAddedANewProduct: "您已成功添加新产品！",
    pleaseEnterProductSellingPrice: "请输入产品售价",
    doYouWantToTrackProductStock: "您想跟踪产品库存吗？",
    sellingPrice: "售价",
    setProductExpiryReminder: "设置产品到期提醒",
    productExpiryDate: "产品有效期",
    startRemindingFrom: "开始提醒自",
    productSuppliesAddedSuccessfully: "您已成功添加产品耗材。",
    addProductSupplies: "添加产品供应",
    pleaseSelectSupplier: "请选择供应商",
    nameOfProduct: "产品名称",
    pleaseSelectProduct: "请选择产品",
    productVariant: "产品变体",
    pleaseSelectAVariant: "请选择一个变体",
    pleaseEnterUnitPrice: "请输入单价",
    businessBranch: "业务分行",
    pleaseSelectBranch: "请选择分行",
    youveSuccessfullyAddedANewSupplier: "您已成功添加新供应商",
    addSupplier: "添加供应商",
    pleaseEnterSupplierEmail: "请输入供应商的电子邮件",
    pleaseAddADescription: "请添加说明",
    anErrorOccuredProductsDeleted:
      "执行操作时发生错误。请注意，在此过程中可能会删除少数产品",
    bulkDelete: "批量删除",
    youAreAboutToDelete: "您即将删除",
    product: "产品",
    isDueToRedeem: "是由于赎回",
    aReward: "一份奖励",
    pleaseSelectCustomerToReeemReward: "请选择一个客户来兑换奖励。",
    youHaveNoLoyaltyProgramRunning: "您没有运行有效的忠诚度计划",
    customerHhasNoPointsInLoyaltyProgram: "客户在此忠诚度计划中没有积分",
    proceedWithPayment: "继续付款？",
    youAreAboutToPayForTransactionUsingPoints: "您将要使用积分支付交易费用。",
    customerHas: "客户有",
    worth: "值得",
    andIsNotSufficientToPayForTransaction:
      "并且不足以支付这笔交易。他们想使用其他付款方式添加余额吗？",
    addCustomerLoyalty: "添加客户忠诚度",
    pleaseAddCustomerFirst: "请先添加或选择客户。",
    pleaseWaitWhileWeGetReady: "请稍等，我们正在准备中",
    lowStock: "低库存",
    pleaseEnterAmountTendered: "请输入投标金额",
    areYouSureToBookSaleOffline: "您确定要离线预订此促销吗？",
    saleWouldBeBookedAutomatically: "当您打开互联网时，销售将自动预订",
    offlineSalesBookingCancelled: "线下销售预订取消",
    covid19Message: "COVID19：用肥皂洗手或消毒以阻止传播。时刻保持安全",
    saleSuccessfullyRecorded: "销售记录成功！",
    sendReceiptToEmail: "将收据发送到电子邮件",
    sendThankYouSms: "发送感谢短信",
    sendShippingDetails: "发送送货详情",
    addLoyalty: "增加忠诚度",
    searchCustomerNameOrNumber: "搜索客户姓名或号码",
    clickTheSearchCustomerBox: "单击搜索客户框和扫描卡",
    enterProductPrice: "输入产品价格",
    enterPriceFor: "输入价格",
    searchForProduct: "搜索产品",
    all: "全部",
    backToDashboard: "返回仪表板",
    viewDraftSales: "查看草稿销售",
    variantSelected: "选择的变体",
    variant: "变体",
    thePreviousVariantSelectionNotAvailable:
      "先前的变体选择不适用于根据价格选择的新变体，请更改您的选择。",
    pleaseSupplyPositiveQuantityNumber: "请提供一个正数量",
    lowStockFor: "低库存",
    clearVariants: "清除变体",
    pleaseEnterQuantity: "请输入数量",
    picture: "图片",
    redemptionToken: "赎回令牌",
    token: "令牌",
    totalSpent: "总花费",
    confirmPayment: "确认付款",
    hasPaymentBeenMade: "付款处理成功了吗？",
    enterTransactionReference: "输入您付款时使用的交易参考",
    pleaseSelectACustomer: "请选择客户！",
    areYouSureToApplyDiscount: "您确定要申请折扣吗？",
    addYourBankAccountToEnableUssd:
      "添加您的银行账户以通过 uPay 启用即时 USSD 转账",
    totalAmountToPay: "总支付金额",
    doYouWantToCancelTransaction: "您要取消此交易吗？",
    savePrintBill: "保存/打印账单",
    enterAmountCollectedForCustomer: "输入为客户收集的金额",
    recordSale: "唱片销售",
    checkOutWith: "退房",
    instantTransfer: "即时转账",
    dialTheUSSDCode: "拨打 USSD 代码",
    pleaseSelectABank: "请选择银行",
    payWithUSSD: "使用 USSD 付款",
    sendBillTo: " - 将账单发送至",
    waitingForUSSDTransfer: "等待 USSD 传输",
    percent: "百分",
    applyDiscount: "申请折扣",
    thisBillHasBeenSaved: "此法案已保存",
    saveDraft: "保存草稿",
    pleaseTypeANameToIdentifyCustomer: "请输入名称以识别客户",
    paymentDetails: "付款详情",
    basePrice: "基本价格",
    staff: "职员",
    subTotal: "小计",
    durationMonths: "持续时间（月）",
    selectADuration: "选择持续时间",
    oneMonth: "1个月",
    twoMonths: "2个月",
    threeMonths: "3个月",
    sixMonths: "6个月",
    twelveMonths: "12个月",
    eighteenMonths: "18个月",
    twentyFourMonths: "24 个月",
    twoMonthsFree: "（2 个月免费）",
    threeMonthsFree: "（3 个月免费）",
    fiveMonthsFree: "（5 个月免费）",
    yourAccountHasBeen: "您的帐户已",
    renewed: "更新",
    upgraded: "升级了",
    successfully: "成功地",
    yourSubscription: "您的订阅",
    youAreCurrentlyEnrolledOnA: "您目前注册了一个",
    pleaseChooseAPaymentOption: "请选择付款方式",
    planRenewal: "计划更新",
    planUpgrade: "计划升级",
    pleaseSelectDurationToPayFor: "请选择您打算支付的持续时间",
    staffAccounts: "员工账户",
    ecommerce: "电子商务",
    pleaseSelectAPlan: "请选择一个计划",
    includeAddons: "包括附加组件",
    viewTransactions: "查看交易",
    customerHasNoCompletedTansactions: "客户还没有完成交易",
    branch: "分支",
    enterNumberOfEcommerceBranches: "输入电子商务分支机构的数量",
    enterNumberOfEcommerceBranchesToPay: "输入您打算支付的电子商务分支机构数量",
    ecommerceIntegration: "电子商务整合",
    enterNumberOfBranches: "输入分行数量",
    enterNumberOfAdditionalBranchesToPay: "输入您打算支付的额外分行数量",
    enterNumberOfStaffs: "输入员工人数",
    enterNumberOfStaffsToPayFor: "输入您打算支付的员工人数",
    discountApplies: "折扣适用",
    starsOnThe: "星星在",
    offer: "提供",
    get: "得到",
    moreStarsToRedeem: "更多星星可以兑换",
    taxVat: "税（增值税）",
    callCashierToCompletePayment: "致电收银员完成付款",
    receipt: "收据",
    dear: "亲",
    thankYouForYourOrderFindGoods:
      "谢谢您的订单。请按照约定找到以下提供的货物。",
    shippingNote: "发货单",
    enterShippingNote: "输入发货单",
    shippingAddress: "送货地址",
    enterShippingAddress: "输入送货地址",
    wellDoneYouAreDueToRedeem: "做得好！你要赎回",
    toGetYourRewardNextVisit: "在您下次访问时获得奖励。谢谢",
    pointsOnThe: "点上",
    morePointsToRedeem: "更多积分兑换",
    showCode: "显示代码",
    toGetYourRewardOnNextVisit: "在您下次访问时获得奖励。谢谢",
    earn: "赚",
    delivaryNote: "送货单",
    draftSales: "草稿销售",
    startDate: "开始日期",
    endDate: "结束日期",
    orders: "订单",
    checkout: "查看",
    noProductItem: "没有产品项目",
    selectProductImage: "选择产品图片",
    selectCountry: "选择国家",
    selectRegion: "选择州/地区",
    printProductTag: "打印产品标签",
    transactionReference: "交易参考",
    Cashier: "出纳员",
    Manager: "经理",
    Owner: "所有者",
    Admin: "行政",
    addPartners: "添加合作伙伴",
    addNewLoyaltyPartner: "添加新的忠诚合作伙伴",
    pleaseEnterCompanyName: "请输入公司名称",
    companyName: "公司名",
    pleaseEnterCompanyRepName: "请输入公司代表姓名",
    companyRepName: "公司代表姓名",
    pleaseEnterCompanyRepEmail: "请输入公司代表的电子邮件",
    companyRepEmail: "公司代表的电子邮件",
    pleaseEnterCompanyRepPhone: "请输入公司代表的电话号码",
    companyRepPhone: "公司代表电话",
    addReward: "添加奖励",
    pleaseEnterRewardName: "请输入奖励名称",
    rewardName: "奖励名称",
    rewardQuantity: "奖励数量",
    rewardDescription: "奖励说明",
    rewardType: "奖励类型",
    pleaseEnterRewardType: "请输入奖励类型",
    pleaseEnterRewardQuantity: "请输入奖励数量",
    pleaseEnterRewardDescription: "请输入奖励说明",
    fineDining: "精致餐饮",
    luxuryFashion: "奢华时尚",
    hotels: "酒店",
    travel: "旅行",
    foodAndBeverage: "食品和饮料",
    fashion: "时尚",
    health: "健康",
    furniture: "家具",
    entertainment: "娱乐",
    automobile: "汽车",
    education: "教育",
    beautyAndSpa: "美容和水疗",
    staycation: "留宿",
    events: "活动",
    trips: "旅行",
    oilAndGas: "油和气",
    laundry: "洗衣店",
    partnerCategory: "合作伙伴类别",
    freeItem: "免费项目",
  },
  "Chinese (Traditional)": {
    cashier: "出納員",
    manager: "經理",
    owner: "所有者",
    online: "在線的",
    offline: "離線",
    changePassword: "更改密碼",
    currentPasswordMessage: "請輸入您當前的密碼",
    passwordMessage: "請輸入您的密碼",
    currentPassword: "當前密碼",
    password: "密碼",
    confirmPasswordMessage: "請確認您的密碼！",
    confirmPassword: "確認密碼",
    sendViaEmail: "通過電子郵件發送",
    sendViaWhatsapp: "通過 WhatsApp 發送",
    downloadPdf: "下載PDF",
    paid: "有薪酬的",
    unpaid: "未付",
    partial: "部分的",
    closeInvoice: "您要關閉發票嗎？",
    closeInvoiceConfirmation: "發票可能無法保存。你想關閉嗎？",
    yes: "是的",
    no: "不",
    invoice: "發票",
    wasDeducted: "被扣除",
    for: "為了",
    item: "物品",
    addProduct: "添加產品",
    paymentReference: "付款憑據",
    amountPaid: "支付的金額",
    discountAmount: "折扣金額",
    amountDue: "應付金額",
    amount: "數量",
    dueDate: "到期日",
    paymentType: "支付方式",
    card: "卡片",
    cash: "現金",
    bankTransfer: "銀行轉帳",
    paymentMessage: "付款信息",
    description: "描述",
    sendReceipt: "發送收據",
    delete: "刪除",
    save: "節省",
    resend: "重發",
    saveAndSend: "節省",
    invoiceSaved: "發票已保存！",
    selectPaymentMethod: "請選擇付款方式！",
    selectCustomer: "請選擇一個客戶！",
    cartEmptyError: "購物車列表不能為空，關閉發票並將商品添加到購物車！",
    subscriptionExpired:
      "您的訂閱已過期，您的帳戶現在受到限制。單擊下面的按鈕更新您的帳戶",
    renew: "更新",
    holdOn: "堅持，稍等",
    customerBank: "客戶銀行",
    cancel: "取消",
    selectACustomer: "選擇客戶",
    invoiceSuccessfulPdfError:
      "發票創建成功，但 PDF 生成時間比平時長。請稍後再回來查看。",
    downloadingInvoice: "下載發票",
    downloadingReceipt: "下載收據",
    whatsappReceiptError: "通過 WhatsApp 發送收據時發生錯誤，請重試。",
    receiptToWhatsapp: "收據轉發給WhatsApp",
    thankYouForPatronage: "謝謝您的光顧",
    hereIsYourReceipt: "這是您的付款收據",
    errorSendingEmailReceipt:
      "通過電子郵件發送收據時發生錯誤，請重試或聯繫支持",
    receiptSentToEmail: "收據已發送至客戶郵箱",
    invoiceSentToEmail: "發票已發送至客戶郵箱",
    invoiceSuccessWhatsappError:
      "發票創建成功，但發送到 WhatsApp 時發生錯誤。在發票清單上重試",
    invoiceSuccessfulEmailError:
      "發票創建成功，但作為電子郵件發送時發生錯誤。請從發票列表中重試",
    invoiceSentToWhatsapp: "發票已轉發至 WhatsApp",
    hello: "你好",
    pleaseDownloadInvoice: "請下載發票",
    pleaseDownloadReceipt: "請下載收據",
    from: "從",
    email: "電子郵件",
    upgrade: "升級",
    youAreOnFreePlan: "您使用的是免費計劃。",
    clickOn: "點擊",
    yourPlanInFewSteps: " 只需幾個快速步驟即可訂閱。",
    to: "到",
    yourSubscriptionHasExpired: "您的訂閱已過期，您的帳戶現在受到限制。",
    days: "天",
    yourSubscriptionExpiresIn: "您的 Loystar 訂閱在",
    createAcount: "創建一個帳戶",
    signIn: "登入",
    continue: "繼續",
    enterOtp: "輸入一次性密碼",
    enterValidOtp: "請輸入有效的 PIN 碼",
    pin: "別針",
    tokenSentToMail: "令牌已發送到您的電子郵件",
    passwordResetSuccessful: "密碼重置成功",
    somethingWentWrong: "出了些問題！",
    resetPassword: "重設密碼",
    iHaveResetCode: "我有密碼重置碼",
    pleaseEnterEmail: "請輸入您的電子郵件",
    aTokenWillBeSentToEmail: "令牌將發送到您的電子郵件",
    enterEmail: "輸入你的電子郵箱",
    sendinYourToken: "正在發送您的令牌...",
    makeSureItMatchesPassword: "確保它與您的新密碼匹配",
    pleaseChooseNewPassword: "請選擇一個新密碼",
    chooseNewPassword: "選擇一個新密碼",
    enterNewPassword: "輸入您的新密碼進行確認",
    enterTokenSent: "輸入發送給您的郵件的令牌",
    resetToken: "重置令牌",
    resettingPassword: "重置密碼...",
    signUp: "報名",
    adminSignInWithEmail: " 管理員使用電子郵件登錄，而員工使用用戶名登錄。",
    pleaseEnterEmailOrUsername: "請輸入您的電子郵件或用戶名",
    emailOrUsername: "電子郵件或用戶名",
    pleaseEnterPassword: "請輸入密碼",
    createAnAccount: "創建一個帳戶",
    forgotPassword: "忘記密碼？",
    enterPhoneNumber: "輸入電話號碼",
    personalData: "個人資料",
    validateConfirmationCOde: "驗證確認碼",
    pleaseEnterFirstName: "請輸入您的名字",
    pleaseEnterPhoneNumber: "請輸入您的電話號碼",
    pleaseEnterLastName: "請輸入您的姓氏",
    pleaseEnterBusinessName: "請輸入您的公司名稱",
    firstName: "名",
    lastName: "姓",
    businessName: "企業名稱",
    previous: "以前的",
    next: "下一個",
    pleaseSelectBusinessCategory: "請選擇您的業務類別",
    pleaseEnterValidEmail: "請輸入有效電子郵件",
    pleaseEnterPostCode: "請輸入郵政編碼",
    postCode: "郵政編碼",
    phoneNumberInUse: "此電話號碼已被使用！",
    emailInUse: "此電子郵件已被使用！",
    foodAndDrinks: "食品和飲料",
    salonAndBeauty: "沙龍和美容",
    fashionAndAccessories: "時裝和配飾",
    gymAndFitness: "健身房和健身",
    travelAndHotel: "旅遊和酒店",
    homeAndGifts: "家居及禮品",
    washingAndCleaning: "洗滌和清潔",
    gadgetsAndElectronics: "小工具和電子產品",
    groceries: "雜貨",
    others: "其他",
    submit: "提交",
    accountCreatedSuccessful: "您的帳戶已成功創建。",
    pleaseEnterAddress: "請輸入您的地址",
    addressLine1: "地址第一行",
    addressLine2: "地址行 2",
    choosePassword: "選擇密碼",
    passwordMustBe6Characters: "密碼必須至少6個字符。",
    howDidYouHearLoystar: "您是如何得知 Loystar 的？",
    referralCode: "推薦代碼",
    byClickingTheButton: " 點擊下方按鈕，即表示您同意",
    termsAndSevice: "條款",
    wereCreatingAccount: "我們正在創建您的帳戶",
    proceed: "繼續",
    verificationCodeMustBe6: "驗證碼必須是 6 位數字",
    pleaseEnter6DigitCode: "請輸入6位數代碼",
    enterVerificationCode: "輸入驗證碼",
    resendToken: "重新發送令牌",
    verify: "核實",
    transactions: "交易",
    todaySales: "今天的銷售",
    salesHistory: "銷售歷史",
    supplyHistory: "供應歷史",
    new: "新的",
    invoices: "發票",
    disbursements: "支出",
    offlineSales: "線下銷售",
    products: "產品",
    customers: "顧客",
    multiLevelLoyalty: "多層次的忠誠",
    loyaltyPrograms: "忠誠度計劃",
    members: "會員",
    appStore: "應用商店",
    orderMenu: "訂購菜單",
    settings: "設置",
    staffAndBranches: "員工和分支機構",
    myAccount: "我的賬戶",
    switchToSellMode: "切換到銷售模式",
    signOut: "登出",
    getFreeSubscription: "獲得免費訂閱",
    onlyNumbersAllowed: "只允許數字",
    yourAccountMustBe10Digits: "您的帳號必須是 10 位數字",
    yourBvnMustBe11: "您的 BVN 必須是 11 位數字",
    pleaseSelectBank: "請選擇您的銀行",
    selectYourBank: "選擇您的銀行",
    enterBankAccountNumber: "輸入銀行帳號",
    enterBvnNumber: "輸入您的 BVN",
    connectBankAccount: "連接銀行賬戶",
    passwordResetSuccessfulAndSignOut:
      "您的密碼已成功重置。點擊下方的退出按鈕再次登錄",
    enterCurrentPassword: "輸入當前密碼",
    pleaseEnterCurrentPassword: "請輸入當前密碼",
    phoneNumber: "電話號碼",
    sex: "性別",
    dateOfBirth: "出生日期",
    state: "狀態",
    country: "國家",
    loyaltyId: "忠誠度 ID",
    createdAt: "創建於",
    noOfTransactionsMade: "交易次數",
    yourDownloadWillStart: "您的下載將在稍後開始",
    exportCustomers: "出口客戶",
    youhaveSuccessfullyToppedUp: "您已成功為您的 SMS 單元充值。",
    youNowHave: "你現在有",
    smsUnits: "短信單位",
    enterNumberOfSmsUnits: "輸入您打算購買的短信數量",
    pleaseEnterANumericValue: "請輸入數值",
    pay: "支付",
    accountEditedSuccessfully: "您的帳戶已成功編輯。",
    youAeCurrentlyOn: "你目前在",
    plan: "計劃",
    userData: "用戶數據",
    businessData: "業務數據",
    businessCategory: "業務類別",
    pleaseSelectCurrency: "請選擇您的貨幣",
    selectYourCurrency: "選擇您的貨幣",
    purchaseMoreSmsUnits: "使用以下表格購買更多 SMS 單元",
    youHave: "你有",
    atLeast4SmsUnitsRrequired: "至少需要4條短信驗證，請充值！",
    pleaseVerifyYourAccountToComplyWithKyc:
      "請驗證您的銀行帳戶以符合“了解您的客戶”(KYC) 要求。這將允許您通過 USSD 或即時轉賬收款、接收客戶訂單並處理交易結算。僅適用於尼日利亞商家。請按下面的按鈕開始。",
    reConnectBankAccount: "重新連接銀行賬戶",
    accountName: "用戶名",
    accountNumber: "帳號",
    bankName: "銀行名",
    verified: "已驗證",
    accountDetails: "帳戶詳細資料",
    kycBankAccount: "了解您的客戶",
    createTier: "創建層",
    fileUploadSuccessful: "文件上傳成功",
    fileUploadFailed: "文件上傳失敗",
    createLoyaltyProgram: "創建忠誠度計劃",
    createLoyalty: "建立忠誠度",
    name: "姓名",
    loyaltyArtwork: "忠誠藝術品",
    clickToUpload: "點擊上傳",
    amountToPointsRatio: "積分比",
    points: "積分",
    recommendedAmountToPointRatio:
      "推薦：₦1到1點。即每消費 1 奈拉，您的客戶就會獲得 1 分",
    pleaseTypeIn: "請輸入",
    toDeleteLoyalty: "刪除此忠誠度",
    deleteLoyalty: "刪除忠誠度",
    toConfirm: "確認",
    edit: "編輯",
    pointsAwardedSuccessfully: "成功獲得積分。",
    enterPointValueToAward: "輸入您想獎勵客戶的積分值",
    award: "獎",
    awardPointValuesToCustomer: "對客戶的獎勵積分價值",
    enrollMembersToLoyalty: "註冊會員忠誠度",
    awardPoints: "獎勵積分",
    enroll: "註冊",
    home: "家",
    loyalty: "忠誠",
    enrollCustomers: "註冊客戶",
    selected: "已選",
    customer: "顧客",
    loyaltyActivationSuccessful: "忠誠度激活成功。",
    loyaltyDeactivationSuccessful: "忠誠度停用成功。",
    viewTier: "查看層",
    deactivate: "停用",
    activate: "啟用",
    actions: "行動",
    nameOfLoyalty: "忠誠之名",
    loyaltyStatus: "忠誠度狀態忠誠度狀態",
    numberOfTiers: "層數",
    createdOn: "創建於",
    createATier: "創建層",
    stopCreatingTierConfirmation: "是否要停止創建層？",
    stopEditingTierConfirmation: "您要停止編輯此層嗎？",
    nameOfTier: "層名",
    minimumSpendingTarget: "最低消費目標",
    maximumSpendingTarget: "最大支出目標",
    minimumSpendingTargetRequired: "最低消費目標是必需的",
    maximumSpendingTargetRequired: "需要最大支出目標",
    rewardSponsor: "獎勵贊助商",
    pleaseChooseARewardSponsor: "請選擇獎勵贊助商",
    self: "自己",
    partner: "夥伴",
    rewardPartner: "獎勵合作夥伴",
    pleaseSelectRewardPartner: "請選擇您的獎勵合作夥伴",
    rewards: "獎勵",
    pleaseSelectAReward: "請選擇獎勵",
    instructionsOnRedeemingReward: "客戶應如何兌換獎勵的說明",
    pleaseFillInThisField: "請填寫此字段！",
    toDeleteThisTier: " 刪除此層",
    deleteTier: "刪除層",
    viewMembers: "查看成員",
    membersEnrolled: "會員註冊",
    instruction: "操作說明",
    membersIn: "成員在",
    availableTiersInLoyalty: "忠誠度計劃中的可用層級",
    tiers: "等級",
    totalTier: "總層",
    availableLoyaltyProgramme: "可用的忠誠度計劃",
    totalLoyalties: "總忠誠度",
    memberDetails: "會員詳情",
    nameOfCustomer: "客戶姓名",
    address: "地址",
    allEnrolledMembers: "所有註冊會員",
    thisIsToWishYouHappyBirthday:
      "謹此祝您生日快樂，生活興旺。感謝您對 Loyster 所做的一切。歡慶快樂！",
    inputAnOfferPlease: "請輸入報價",
    pleaseSelectTheInsertPoint: "請選擇消息中的插入點並再次單擊",
    birthdayOfferAndMessage: "生日優惠及留言",
    birthdayOffer: "生日優惠",
    birthdayMessage: "生日留言",
    noOfferFound: "沒有找到優惠",
    settingABirthdayOffer: "設置生日優惠可讓客戶在生日當天通過短信收到此優惠",
    createOffer: "創建報價",
    whatIsTheOffer: "優惠是什麼？",
    editMessage: "編輯消息",
    insert: "插入",
    theNameOfCustomerInserted: "客戶姓名將插入此處",
    theBirtdayOfferInserted: "生日優惠將插入此處",
    youSuccessfullyAddedNewBranch: "您已成功添加新分支！",
    addNewBranch: "添加新分支",
    addBranch: "添加分支",
    additionalBranchWillIncur: "額外的分支會產生",
    perBranch: "每個分行",
    ecommerceBranchCosts: "電子商務分支機構費用",
    pleaseEnterBranchName: "請輸入分行名稱",
    pleaseEnterBranchAddress1: "請輸入分行地址第 1 行",
    enterBranchAddress1: "輸入分行地址行 1",
    enterBranchAddress2: "輸入分行地址行 1",
    pleaseEnterBranchAddress2: "請輸入分行地址第 2 行",
    enterBranchName: "輸入分行名稱",
    successfullyAddedStaff: "您已成功添加新員工！",
    addNewStaff: "添加新員工",
    addStaff: "添加員工",
    additionalStaffWillIncur: "額外的工作人員將招致",
    perStaff: "每名員工。",
    pleaseEnterStaffEmail: "請輸入工作人員的郵箱",
    pleaseEnterStaffUsername: "請輸入工作人員的用戶名",
    pleaseEnterStaffPassword: "請輸入工作人員的密碼",
    pleaseSelectStaffRole: "請選擇工作人員的角色",
    selectStaffRole: "選擇員工的角色",
    enterStaffEmail: "輸入工作人員的電子郵件",
    enterStaffUsername: "輸入工作人員的用戶名",
    enterStaffPassword: "輸入員工密碼",
    spacesNotAllowedInUsername: "用戶名中不允許有空格",
    admin: "行政",
    pleaseSelectBusinessToAttachStaff: "請選擇要附加員工的業務",
    searchForBranchToAttachStaff: "尋找分行附加員工",
    username: "用戶名",
    role: "角色",
    areYouSureToDeleteThis: "你確定要刪除這個嗎",
    branches: "分行",
    upgradeYourPlan: "升級您的計劃。",
    add: "添加",
    addNew: "添新",
    customerWithEmailAlreadyExists: "具有電子郵件/電話號碼的客戶已存在！",
    successfullyAddedNewCustomer: "您已成功添加新客戶！",
    addCustomer: "添加客戶",
    pleaseEnterCustomerFirstName: "請輸入客戶的名字",
    pleaseEnterCustomerLastName: "請輸入客戶的姓氏",
    pleaseEnterCustomerPhoneNumber: "請輸入客戶的電話號碼",
    pleaseEnterCustomerEmail: "請輸入客戶的電子郵件",
    pleaseEnterCustomerAddressLine: "請輸入客戶的地址行",
    pleaseEnterCustomerOtherAddress: "請輸入客戶的其他地址",
    pleaseSelectCustomerGender: "請選擇客戶的性別",
    gender: "性別",
    male: "男性",
    female: "女性",
    bank: "銀行",
    selectBank: "選擇銀行",
    stateOrRegionOrProvince: "州/地區/省",
    customerNotes: "客戶備註",
    sendSms: "發簡訊",
    editCustomer: "編輯客戶",
    redeemReward: "兌換獎勵",
    issueLoyaltyCard: "發行會員卡",
    deleteCustomer: "刪除客戶",
    youveSuccessfullyAssignedLoyaltyMembership: "您已成功分配忠誠度會員 ID",
    noMembershipIdAvailable:
      "沒有可用的會員 ID。請通過 hello@loystar.co 與我們聯繫",
    sendEmail: "發電子郵件",
    membershipPoints: "會員積分",
    customerDetails: "顧客信息",
    close: "關閉",
    loyaltyBalance: "忠誠度餘額",
    pointsBalance: "積分餘額",
    starBalance: "星平衡",
    requiredPoints: "所需積分",
    requiredStars: "所需星星",
    reddemCode: "兌換碼",
    toDeleteThisCustomer: "刪除此客戶",
    customerHasBeenDeletedSuccessfully: "客戶已成功刪除！",
    customerWithPhoneAlreadyExists: "有電話號碼的客戶已存在",
    customerWasSuccessfullyEdited: "客戶已成功編輯",
    anErrorOccured: "發生錯誤",
    phoneNumberIncludeCountry: "電話號碼（包括國家代碼）",
    yourFileQueuedForUpload: "您的文件已排隊等待上傳。請在幾秒鐘後刷新頁面。",
    thereWasAnErrorPerformingOperation: "執行操作時出錯！",
    pleaseSelectFile: "請選擇一個文件！",
    oopsOnlyCsvFilesAllowed: "哎呀！僅允許使用 CSV 文件。請上傳 .csv 文件。",
    fileShouldBeSmallerThan5Mb:
      "文件應小於 5MB。如果您有更大的文件，請發送電子郵件至 support@loystar.co。謝謝",
    customerFirstNameIsRequired: "行上需要客戶的名字",
    customerPhoneNumberIsRequired: "行上需要客戶電話號碼",
    importCustomers: "進口客戶",
    upload: "上傳",
    clickIconToDownloadCsv: "單擊此圖標可下載 CSV 文件模板。",
    getGoogleSheetVersion: "獲取 Google 表格版本",
    clickOrDragFileToUpload: "單擊或拖動文件到此區域進行上傳",
    missingOrInvalidColumnHeader:
      "缺少或無效的列標題。請遵循模板格式。謝謝你。",
    youHaveImported: "您已導入",
    youSuccessfullyRedeemedReward: "您已成功兌換獎勵！",
    sixDigitCode: "六位數代碼",
    pleaseEnterCustomerRewardCode: "請輸入客戶的獎勵代碼",
    enterRewardCode: "輸入獎勵代碼。（獎勵代碼區分大小寫）",
    selectLoyaltyProgram: "選擇忠誠度計劃",
    stamps: "郵票",
    smsUnitsLow: "SMS 單位低",
    pleaseTopUpSmsUnits: "請充值短信單位",
    smsSuccessfullySentForDelivery: "短信發送成功！",
    sendSmsTo: "發送短信至",
    allCustomers: "所有客戶",
    unitsAvailable: "可用單位",
    pleaseTypeInTheMessage: "請輸入留言",
    message: "信息",
    charactersRemaining: "剩餘字符",
    avoidUseOfSpecialCharacters: "避免使用特殊字符和表情符號來節省 SMS 單元。",
    note: "筆記",
    errorFetchingCustomersMultilevelDetail: "獲取客戶多級詳細信息時出錯",
    search: "搜索",
    reset: "重啟",
    importCustomer: "進口客戶",
    addNewCustomer: "添加新客戶",
    sendSmsBroadcast: "發送短信廣播",
    totalCustomers: "客戶總數",
    disbursementDetails: "付款明細",
    paidBy: "由...支付",
    disbursed: "已支付",
    bankAccountName: "銀行賬戶名稱",
    bankAccountNumber: "銀行帳號",
    transferInitiated: "傳輸啟動",
    transferCompleted: "轉移完成",
    pleaseEnterAValid: "請輸入一個有效的",
    begin: "開始",
    end: "結尾",
    date: "日期",
    paymentDate: "支付日期",
    selectDisbursementDuration: "選擇支付期限",
    totalSettled: "總結算",
    totalUnsettled: "總未結算",
    toDeleteThisSale: "刪除此銷售",
    draftSaleDeletedSuccessfully: "草稿銷售已成功刪除！",
    deleteSale: "刪除銷售",
    pleaseTypeInYourTotal: "請輸入您的總數以確認刪除",
    billDetails: "賬單詳情",
    printBill: "打印賬單",
    servedBy: "服務於",
    total: "全部的",
    createdDate: "創建日期",
    createdTime: "創建時間",
    status: "地位",
    loginSuccessful: "登陸成功",
    pleaseWaitWhileWeConnectAccount: "我們正在連接您的帳戶，請稍候",
    connectionFailedTryAgain: "連接失敗。請再試一次。",
    connectionSuccessful: "連接成功",
    viewDetails: "查看詳情",
    enable: "使能夠",
    free: "自由",
    cost: "成本",
    visitWebsite: "訪問網站",
    pleaseUpgradeYourPlanToPro: "請將您的計劃升級到 Pro Plus 以啟用此應用",
    connectYourBankAccount: "連接您的銀行帳戶以接收付款。",
    disable: "禁用",
    enableApp: "啟用應用程序",
    addNewProductToInvoice: "將新產品添加到發票",
    toDeleteThisInvoice: "刪除此發票",
    invoiceDeletedSuccessfully: "發票已成功刪除！",
    deleteInvoice: "刪除發票",
    youveSuccessfullyAddedInvoicePaymentMessage: "您已成功添加發票付款消息！",
    pleaseEnterPaymentInstructions: "請輸入付款說明",
    invoiceId: "發票編號",
    paidDate: "支付日期",
    reference: "參考",
    productAdded: "添加產品",
    productOutOfStock: "產品缺貨。請補貨。",
    totalInvoices: "總發票",
    totalPaidInvoices: "總已付發票",
    totalUnpaidInvoices: "未付發票總額",
    loyaltyProgramDeleted: "忠誠度計劃已刪除",
    thisLoyaltyProgramDeletedSuccessfully: "此忠誠度計劃已成功刪除",
    thisLoyaltyProgramEditedSuccessfully: "此忠誠度計劃已成功編輯",
    loyaltyProgramAddedSuccessfully: "忠誠度計劃已成功添加",
    loyaltyProgramEdited: "忠誠度計劃已編輯",
    loyaltyProgramAdded: "添加了忠誠度計劃",
    loyaltyDetails: "忠誠度詳情",
    doYouWantToCloseDialog: "您要關閉此對話框嗎？",
    pleaseEnterLoyaltyName: "請輸入您的忠誠度名稱",
    programType: "節目類型",
    pleaseSelectProgramType: "請選擇程序類型",
    simplePoints: "簡單點",
    stampsProgram: "郵票計劃",
    threshold: "臨界點",
    pleaseEnterLoyaltyThreshold: "請輸入忠誠度門檻",
    reward: "報酬",
    pleaseEnterLoyaltyReward: "請輸入忠誠獎勵",
    totalUserPoints: "總用戶積分",
    totalUserStamps: "總用戶郵票",
    spendingTarget: "消費目標",
    spendingTargetHint1:
      "支出目標是客戶必須花費多少才能獲得獎勵。 1 貨幣價值 = 1 點。",
    spendingTargetHint2: "郵票目標是客戶必須收集多少郵票才能獲得獎勵。例如。 5",
    addNewLoyaltyProgram: "添加新的忠誠度計劃",
    addLoyaltyProgram: "添加忠誠度計劃",
    loyaltyProgramType: "忠誠度計劃類型",
    pleaseSelectLoyaltyProgramType: "請選擇忠誠度計劃類型",
    nameOfProgram: "程序名稱",
    pleaseEnterProgramName: "請輸入程序名稱",
    whatIsTheReward: "獎勵是什麼？",
    egNextPurchaseIsFree: "例如。下次購買免費",
    customerName: "顧客姓名",
    guestCustomer: "來賓客戶",
    orderConfirmedSuccessfully: "訂單確認成功",
    orderCancelledSuccessfully: "訂單取消成功",
    confirmOrder: "確認訂單",
    cancelOrder: "取消訂單",
    allOrders: "所有訂單",
    totalOrders: "總訂單",
    doYouWantToAcceptOrder: "您要接受此訂單嗎？",
    doYouWantToCancelOrder: "您要取消此訂單嗎？",
    orderDetails: "訂單詳細信息",
    orderCreatedAt: "訂單創建於",
    supplier: "供應商",
    productName: "產品名稱",
    quantity: "數量",
    unitPrice: "單價",
    receivedBy: "接收人",
    reportFrom: "報告來自",
    totalSupplies: "總供應量",
    allRightsReserved: "版權所有",
    toDeleteThisTransaction: "刪除此交易",
    transactionDeletedSuccessfully: "交易已成功刪除。庫存已退回庫存。",
    deleteTransaction: "刪除交易",
    transactionDetails: "交易明細",
    printReceipt: "打印收據",
    channel: "渠道",
    discount: "折扣",
    profit: "利潤",
    discountedSales: "折扣銷售",
    errorFetchingRecord: "獲取記錄時出錯",
    exportTransactions: "出口交易",
    errorFetchingSalesRecord: "獲取導出的銷售記錄時出錯。",
    totalSellingPrice: "總售價",
    totalCostPrice: "總成本價",
    appliedDiscount: "應用折扣",
    noOfItems: "物品數量",
    sales: "銷售量",
    export: "出口",
    totalProfit: "總利潤",
    totalBalanceInPeriod: "期間總餘額",
    allTimeSales: "全時銷售",
    records: "記錄",
    todaysSales: "今天的銷售",
    transaction: "交易",
    youHaveExceededTotalNumberOfProducts:
      "您已超出計劃允許的產品總數。升級您的計劃以享受更高的限額。",
    youNeedToHaveLoyaltyProgram: "您需要有忠誠度計劃才能使用此功能！",
    price: "價錢",
    category: "類別",
    stockTracking: "股票追踪",
    stockCount: "庫存數量",
    taxed: "徵稅",
    originalPrice: "原價",
    costPrice: "成本價格",
    unit: "單元",
    productImage: "產品圖片",
    taxRate: "稅率",
    taxType: "稅種",
    trackInventory: "跟踪庫存",
    variants: "變體",
    hasVariants: "有變體",
    importProduct: "進口產品",
    exportProducts: "出口產品",
    addNewProduct: "添加新產品",
    viewCategory: "查看類別",
    viewSuppliers: "查看供應商",
    receiveInventory: "接收庫存",
    printAllProductsTag: "打印所有產品標籤",
    deleteAll: "刪除所有",
    totalProducts: "總產品",
    youveSuccessfullyAddedANewCategory: "您已成功添加新類別",
    addNewCategory: "添加新類別",
    addCategory: "添加類別",
    categoryName: "分類名稱",
    pleaseEnterCategoryName: "請輸入類別名稱",
    stampsTarget: "郵票目標",
    sendInventory: "發送庫存",
    productDetails: "產品詳情",
    youveSuccessfullyEditedThisCategory: "您已成功編輯此類別",
    update: "更新",
    categoryList: "類別列表",
    categories: "類別",
    enterQuantityToUpdate: "輸入要更新的數量",
    inventorySentSuccessfully: "庫存已成功發送！",
    updateInventory: "更新庫存",
    currentQuantity: "當前數量",
    pleaseEnterQuantityToAdd: "請輸入您要添加的數量",
    pleaseSelectABranch: "請選擇分行",
    searchForBranch: "搜索分行",
    youCantSendMoreThanStock: "您不能發送超過庫存的數量",
    send: "發送",
    pleaseEnterQuantityToSend: "請輸入您要發送的數量",
    productNameIsRequiredOnRow: "行上需要產品名稱",
    productCategoryIsRequiredOnRow: "行上需要產品類別",
    productPriceIsRequiredOnRow: "行上需要產品價格",
    productUnitIsRequiredOnRow: "行上需要產品單位",
    productQuantityIsRequiredOnRow: "行上需要產品數量",
    productVariantsRequireTracking: "產品變體需要跟踪！",
    pleaseAddVariantClickButton: "請單擊添加按鈕添加變體！",
    totalVariantsMoreThanSelectedQuantity:
      "總變體超過所選產品數量，請減少變體數量",
    productEditedSuccessfully: "產品編輯成功！",
    fileTooLargeLessThan4Mb: "文件過大！文件大小應小於 4MB。",
    suchVariantAlreadyExist: "這種變體已經存在",
    addVariants: "添加變體",
    editProduct: "編輯產品",
    pleaseEnterProductName: "請輸入產品名稱",
    pleaseEnterProductPrice: "請輸入產品價格",
    pleaseEnterProductOriginalPrice: "請輸入產品原價",
    productDescription: "產品描述",
    selectProductCategory: "選擇產品類別",
    pleaseSelectProductCategory: "請選擇產品類別",
    productCostPrice: "產品成本價",
    productSellingPrice: "產品售價",
    productOriginalPrice: "產品原價",
    maxFileSizeAllowedIs4Mb: "最大限度。允許的文件大小為 4mb",
    productsWithPicturesArePublished: "有圖片的產品在發現上發布以接收訂單",
    shouldThisProductBeTracked: "這個產品應該被追踪嗎？",
    pleaseSelectStockUnit: "請選擇庫存單位",
    stockUnit: "股票單位",
    bag: "包",
    bottle: "瓶子",
    bunch: "束",
    can: "能夠",
    carton: "紙盒",
    crate: "箱",
    cup: "杯子",
    dozen: "打",
    gigabytes: "技嘉",
    gram: "公克",
    kilogram: "公斤",
    litre: "升",
    pack: "盒",
    pair: "一對",
    pieces: "件",
    plate: "盤子",
    tonne: "噸 (噸)",
    uNIT: "單元",
    yard: "院子",
    pleaseEnterProductQuantity: "請輸入產品數量",
    productQuantity: "產品數量",
    isThisProductTaxed: "這個產品有稅嗎？",
    selectTaxType: "選擇稅種",
    pleaseSelectTaxType: "請選擇稅種",
    progressive: "進步",
    proportional: "成比例的",
    pleaseEnterProductTaxRate: "請輸入產品稅率",
    doesProductHaveVariants: "這個產品有變體嗎？",
    type: "類型",
    value: "價值",
    pleaseEnterVariantType: "請輸入變體的類型",
    pleaseEnterVariantValue: "請輸入變體的值",
    pleaseEnterVariantPrice: "請輸入變體的價格",
    pleaseEnterVariantQuantity: "請輸入變體的數量",
    productDeletedSuccessfully: "產品已成功刪除！",
    categoryDeletedSuccessfully: "分類已成功刪除！",
    toDeleteThisProduct: "刪除此產品",
    invalidProductQuantity: "產品數量無效",
    quantityAddedIsOverStock: "您添加的數量超過了您的庫存數量。",
    itemInventoryNotTracked: "不跟踪物品庫存",
    addBulkQuantity: "添加散裝數量",
    enterBulkQuantity: "輸入批量",
    pleaseEnterBulkQuantity: "請輸入批量",
    youveSuccessfullyAddedANewProduct: "您已成功添加新產品！",
    pleaseEnterProductSellingPrice: "請輸入產品售價",
    doYouWantToTrackProductStock: "您想跟踪產品庫存嗎？",
    sellingPrice: "售價",
    setProductExpiryReminder: "設置產品到期提醒",
    productExpiryDate: "產品有效期",
    startRemindingFrom: "開始提醒自",
    productSuppliesAddedSuccessfully: "您已成功添加產品耗材。",
    addProductSupplies: "添加產品供應",
    pleaseSelectSupplier: "請選擇供應商",
    nameOfProduct: "產品名稱",
    pleaseSelectProduct: "請選擇產品",
    productVariant: "產品變體",
    pleaseSelectAVariant: "請選擇一個變體",
    pleaseEnterUnitPrice: "請輸入單價",
    businessBranch: "業務分行",
    pleaseSelectBranch: "請選擇分行",
    youveSuccessfullyAddedANewSupplier: "您已成功添加新供應商",
    addSupplier: "添加供應商",
    pleaseEnterSupplierEmail: "請輸入供應商的電子郵件",
    pleaseAddADescription: "請添加說明",
    anErrorOccuredProductsDeleted:
      "執行操作時發生錯誤。請注意，在此過程中可能會刪除少數產品",
    bulkDelete: "批量刪除",
    youAreAboutToDelete: "您即將刪除",
    product: "產品",
    isDueToRedeem: "是由於贖回",
    aReward: "一份獎勵",
    pleaseSelectCustomerToReeemReward: "請選擇一個客戶來兌換獎勵。",
    youHaveNoLoyaltyProgramRunning: "您沒有運行有效的忠誠度計劃",
    customerHhasNoPointsInLoyaltyProgram: "客戶在此忠誠度計劃中沒有積分",
    proceedWithPayment: "繼續付款？",
    youAreAboutToPayForTransactionUsingPoints: "您將要使用積分支付交易費用。",
    customerHas: "客戶有",
    worth: "值得",
    andIsNotSufficientToPayForTransaction:
      "並且不足以支付這筆交易。他們想使用其他付款方式添加餘額嗎？",
    addCustomerLoyalty: "添加客戶忠誠度",
    pleaseAddCustomerFirst: "請先添加或選擇客戶。",
    pleaseWaitWhileWeGetReady: "請稍等，我們正在準備中",
    lowStock: "低庫存",
    pleaseEnterAmountTendered: "請輸入投標金額",
    areYouSureToBookSaleOffline: "您確定要離線預訂此促銷嗎？",
    saleWouldBeBookedAutomatically: "當您打開互聯網時，銷售將自動預訂",
    offlineSalesBookingCancelled: "線下銷售預訂取消",
    covid19Message: "COVID19：用肥皂洗手或消毒以阻止傳播。時刻保持安全",
    saleSuccessfullyRecorded: "銷售記錄成功！",
    sendReceiptToEmail: "將收據發送到電子郵件",
    sendThankYouSms: "發送感謝短信",
    sendShippingDetails: "發送送貨詳情",
    addLoyalty: "增加忠誠度",
    searchCustomerNameOrNumber: "搜索客戶姓名或號碼",
    clickTheSearchCustomerBox: "單擊搜索客戶框和掃描卡",
    enterProductPrice: "輸入產品價格",
    enterPriceFor: "輸入價格",
    searchForProduct: "搜索產品",
    all: "全部",
    backToDashboard: "返回儀表板",
    viewDraftSales: "查看草稿銷售",
    variantSelected: "選擇的變體",
    variant: "變體",
    thePreviousVariantSelectionNotAvailable:
      "先前的變體選擇不適用於根據價格選擇的新變體，請更改您的選擇。",
    pleaseSupplyPositiveQuantityNumber: "請提供一個正數量",
    lowStockFor: "低庫存",
    clearVariants: "清除變體",
    pleaseEnterQuantity: "請輸入數量",
    picture: "圖片",
    redemptionToken: "贖回令牌",
    token: "令牌",
    totalSpent: "總花費",
    confirmPayment: "確認付款",
    hasPaymentBeenMade: "付款處理成功了嗎？",
    enterTransactionReference: "輸入您付款時使用的交易參考",
    pleaseSelectACustomer: "請選擇客戶！",
    areYouSureToApplyDiscount: "您確定要申請折扣嗎？",
    addYourBankAccountToEnableUssd:
      "添加您的銀行賬戶以通過 uPay 啟用即時 USSD 轉賬",
    totalAmountToPay: "總支付金額",
    doYouWantToCancelTransaction: "您要取消此交易嗎？",
    savePrintBill: "保存/打印賬單",
    enterAmountCollectedForCustomer: "輸入為客戶收集的金額",
    recordSale: "唱片銷售",
    checkOutWith: "退房",
    instantTransfer: "即時轉賬",
    dialTheUSSDCode: "撥打 USSD 代碼",
    pleaseSelectABank: "請選擇銀行",
    payWithUSSD: "使用 USSD 付款",
    sendBillTo: " - 將賬單發送至",
    waitingForUSSDTransfer: "等待 USSD 傳輸",
    percent: "百分",
    applyDiscount: "申請折扣",
    thisBillHasBeenSaved: "此法案已保存",
    saveDraft: "保存草稿",
    pleaseTypeANameToIdentifyCustomer: "請輸入名稱以識別客戶",
    paymentDetails: "付款詳情",
    basePrice: "基本價格",
    staff: "職員",
    subTotal: "小計",
    durationMonths: "持續時間（月）",
    selectADuration: "選擇持續時間",
    oneMonth: "1個月",
    twoMonths: "2個月",
    threeMonths: "3個月",
    sixMonths: "6個月",
    twelveMonths: "12個月",
    eighteenMonths: "18個月",
    twentyFourMonths: "24 個月",
    twoMonthsFree: "（2 個月免費）",
    threeMonthsFree: "（3 個月免費）",
    fiveMonthsFree: "（5 個月免費）",
    yourAccountHasBeen: "您的帳戶已",
    renewed: "更新",
    upgraded: "升級了",
    successfully: "成功地",
    yourSubscription: "您的訂閱",
    youAreCurrentlyEnrolledOnA: "您目前註冊了一個",
    pleaseChooseAPaymentOption: "請選擇付款方式",
    planRenewal: "計劃更新",
    planUpgrade: "計劃升級",
    pleaseSelectDurationToPayFor: "請選擇您打算支付的持續時間",
    staffAccounts: "員工賬戶",
    ecommerce: "電子商務",
    pleaseSelectAPlan: "請選擇一個計劃",
    includeAddons: "包括附加組件",
    viewTransactions: "查看交易",
    customerHasNoCompletedTansactions: "客戶還沒有完成交易",
    branch: "分支",
    enterNumberOfEcommerceBranches: "輸入電子商務分支機構的數量",
    enterNumberOfEcommerceBranchesToPay: "輸入您打算支付的電子商務分支機構數量",
    ecommerceIntegration: "電子商務整合",
    enterNumberOfBranches: "輸入分行數量",
    enterNumberOfAdditionalBranchesToPay: "輸入您打算支付的額外分行數量",
    enterNumberOfStaffs: "輸入員工人數",
    enterNumberOfStaffsToPayFor: "輸入您打算支付的員工人數",
    discountApplies: "折扣適用",
    starsOnThe: "星星在",
    offer: "提供",
    get: "得到",
    moreStarsToRedeem: "更多星星可以兌換",
    taxVat: "稅（增值稅）",
    callCashierToCompletePayment: "致電收銀員完成付款",
    receipt: "收據",
    dear: "親",
    thankYouForYourOrderFindGoods:
      "謝謝您的訂單。請按照約定找到以下提供的貨物。",
    shippingNote: "發貨單",
    enterShippingNote: "輸入發貨單",
    shippingAddress: "送貨地址",
    enterShippingAddress: "輸入送貨地址",
    wellDoneYouAreDueToRedeem: "做得好！你要贖回",
    toGetYourRewardNextVisit: "在您下次訪問時獲得獎勵。謝謝",
    pointsOnThe: "點上",
    morePointsToRedeem: "更多積分兌換",
    showCode: "顯示代碼",
    toGetYourRewardOnNextVisit: "在您下次訪問時獲得獎勵。謝謝",
    earn: "賺",
    delivaryNote: "送貨單",
    draftSales: "草稿銷售",
    startDate: "開始日期",
    endDate: "結束日期",
    orders: "訂單",
    checkout: "查看",
    noProductItem: "沒有產品項目",
    selectProductImage: "選擇產品圖片",
    selectCountry: "選擇國家",
    selectRegion: "選擇州/地區",
    printProductTag: "打印產品標籤",
    transactionReference: "交易參考",
    Cashier: "出納員",
    Manager: "經理",
    Owner: "所有者",
    Admin: "行政",
    addPartners: "添加合作夥伴",
    addNewLoyaltyPartner: "添加新的忠誠合作夥伴",
    pleaseEnterCompanyName: "請輸入公司名稱",
    companyName: "公司名",
    pleaseEnterCompanyRepName: "請輸入公司代表姓名",
    companyRepName: "公司代表姓名",
    pleaseEnterCompanyRepEmail: "請輸入公司代表的電子郵件",
    companyRepEmail: "公司代表的電子郵件",
    pleaseEnterCompanyRepPhone: "請輸入公司代表的電話號碼",
    companyRepPhone: "公司代表電話",
    addReward: "添加獎勵",
    pleaseEnterRewardName: "請輸入獎勵名稱",
    rewardName: "獎勵名稱",
    rewardQuantity: "獎勵數量",
    rewardDescription: "獎勵說明",
    rewardType: "獎勵類型",
    pleaseEnterRewardType: "請輸入獎勵類型",
    pleaseEnterRewardQuantity: "請輸入獎勵數量",
    pleaseEnterRewardDescription: "請輸入獎勵說明",
    fineDining: "精緻餐飲",
    luxuryFashion: "奢華時尚",
    hotels: "酒店",
    travel: "旅行",
    foodAndBeverage: "食品和飲料",
    fashion: "時尚",
    health: "健康",
    furniture: "家具",
    entertainment: "娛樂",
    automobile: "汽車",
    education: "教育",
    beautyAndSpa: "美容和水療",
    staycation: "留宿",
    events: "活動",
    trips: "旅行",
    oilAndGas: "油和氣",
    laundry: "洗衣店",
    partnerCategory: "合作夥伴類別",
    freeItem: "免費項目",
  },
  Corsican: {
    cashier: "cassieru",
    manager: "direttore",
    owner: "pruprietariu",
    online: "in ligna",
    offline: "fora di linea",
    changePassword: "Cambià Password",
    currentPasswordMessage: "Per piacè inserite a vostra password attuale",
    passwordMessage: "Inserite puru a vostra chjave",
    currentPassword: "parolla d'intesa attuale",
    password: "Codice",
    confirmPasswordMessage: "Per piacè cunfirmate a vostra password!",
    confirmPassword: "Cunfirmà a parolla d'intesa",
    sendViaEmail: "Mandà Via Email",
    sendViaWhatsapp: "Mandà Via WhatsApp",
    downloadPdf: "Scaricate u PDF",
    paid: "pagatu",
    unpaid: "impagatu",
    partial: "parziale",
    closeInvoice: "Vulete chjode a Fattura?",
    closeInvoiceConfirmation:
      "A fattura ùn pò micca esse salvata. Vulete chjude?",
    yes: "Iè",
    no: "Innò",
    invoice: "Fattura",
    wasDeducted: "hè statu deduttu",
    for: "per",
    item: "Articulu",
    addProduct: "Aghjunghje u Produttu",
    paymentReference: "Riferimentu di Pagamentu",
    amountPaid: "Quantità Pagata",
    discountAmount: "Quantità di Scontu",
    amountDue: "Quantità Due",
    amount: "Quantità",
    dueDate: "Scadenza",
    paymentType: "Tipu di Pagamentu",
    card: "Card",
    cash: "Cash",
    bankTransfer: "Trasferimentu Bancariu",
    paymentMessage: "Missaghju di Pagamentu",
    description: "Description",
    sendReceipt: "Mandà Receipt",
    delete: "Sguassà",
    save: "Salvà",
    resend: "Rinviate",
    saveAndSend: "Salvà",
    invoiceSaved: "Fattura salvata!",
    selectPaymentMethod: "Sceglite puru un Metudu di Pagamentu!",
    selectCustomer: "Per piacè selezziunate un Cliente!",
    cartEmptyError:
      "U Cart List ùn pò esse viotu, chjude a fattura è aghjunghje Articulu à u carrettu!",
    subscriptionExpired:
      "A vostra sottumissione hè scaduta è u vostru contu hè avà limitatu. Cliccate nantu à u Buttone sottu per rinnuvà u vostru contu",
    renew: "Rinnovà",
    holdOn: "Aspetta",
    customerBank: "Banca di Cliente",
    cancel: "Annulla",
    selectACustomer: "Selezziunate Cliente",
    invoiceSuccessfulPdfError:
      "Fattura creata cù successu ma a Generazione PDF piglia più tempu di l'usu. Si prega di verificà torna prestu.",
    downloadingInvoice: "Scaricamentu di Fattura",
    downloadingReceipt: "Scaricamentu di Receipt",
    whatsappReceiptError:
      "Un errore hè accadutu à l'invio di a ricevuta via WhatsApp, Per piacè riprova.",
    receiptToWhatsapp: "Ricevuta trasmessa à WhatsApp",
    thankYouForPatronage: "Vi ringraziu per u vostru patronagiu",
    hereIsYourReceipt: "Eccu a vostra ricevuta di pagamentu",
    errorSendingEmailReceipt:
      "Un errore hè accadutu à l'invio di a ricevuta per e-mail, per piacè riprova o cuntattate l'assistenza",
    receiptSentToEmail: "A ricevuta hè stata inviata à l'email di u cliente",
    invoiceSentToEmail: "A fattura hè stata mandata à l'email di u cliente",
    invoiceSuccessWhatsappError:
      "A fattura hè stata creata bè, ma un errore hè statu inviatu à WhatsApp. Pruvate torna nantu à a lista di fattura",
    invoiceSuccessfulEmailError:
      "A fattura hè stata creata cun successu, ma un errore hè accadutu durante a spedizione cum'è e-mail. Pruvate torna da a lista di fattura",
    invoiceSentToWhatsapp: "Fattura trasmessa à WhatsApp",
    hello: "Bonghjornu",
    pleaseDownloadInvoice: "Per piacè scaricate fattura",
    pleaseDownloadReceipt: "Per piacè scaricate ricevuta",
    from: "da",
    email: "Email",
    upgrade: "Aghjurnamentu",
    youAreOnFreePlan: "Site nantu à un Pianu Gratuitu.",
    clickOn: "Cliccate nantu",
    yourPlanInFewSteps: " u vostru abbonamentu in pochi passi rapidi.",
    to: "à",
    yourSubscriptionHasExpired:
      "A vostra sottumissione hè scaduta è u vostru contu hè avà limitatu.",
    days: "ghjorni",
    yourSubscriptionExpiresIn: "U vostru abbonamentu Loystar scade in",
    createAcount: "Crea un Account",
    signIn: "Firmà lu",
    continue: "Cuntinuà",
    enterOtp: "Inserite PIN OTP",
    enterValidOtp: "Inserite un PIN validu",
    pin: "PIN",
    tokenSentToMail: "Un token hè statu mandatu à u vostru email",
    passwordResetSuccessful: "A resettazione di a password hè stata riesciuta",
    somethingWentWrong: "Qualcosa ùn hè andatu male!",
    resetPassword: "Reset Password",
    iHaveResetCode: "Aghju un codice di resettore di password",
    pleaseEnterEmail: "Inserite u vostru email",
    aTokenWillBeSentToEmail: "Un token seria inviatu à u vostru email",
    enterEmail: "Inserite u vostru email",
    sendinYourToken: "Mandendu u to token ...",
    makeSureItMatchesPassword:
      "Assicuratevi chì currisponde à a vostra nova password",
    pleaseChooseNewPassword: "Sceglite puru una nova chjave",
    chooseNewPassword: "Sceglite una nova password",
    enterNewPassword: "Inserite a vostra nova password per cunfirmà",
    enterTokenSent: "Inserite u gettone chì hè statu mandatu u vostru mail",
    resetToken: "Resettate u token",
    resettingPassword: "Resettendu a vostra Password ...",
    signUp: "Firma",
    adminSignInWithEmail:
      " L'amministratore accede à e-mail mentre u Staff accede à u nome d'utilizatore.",
    pleaseEnterEmailOrUsername: "Inserite u vostru email o nome d'utilizatore",
    emailOrUsername: "Email o Username",
    pleaseEnterPassword: "Inserite a chjave",
    createAnAccount: "Crea un Account",
    forgotPassword: "Dimenticatu u Password?",
    enterPhoneNumber: "Inserite u Numeru di Telefono",
    personalData: "Dati Personali",
    validateConfirmationCOde: "Validà u Codice di Cunferma",
    pleaseEnterFirstName: "Per piacè inserite u vostru nome",
    pleaseEnterPhoneNumber: "Inserite puru u vostru numeru di telefonu",
    pleaseEnterLastName: "Per piacè inserite u vostru cognome",
    pleaseEnterBusinessName: "Per piacè inserite u nome di a vostra impresa",
    firstName: "Nome",
    lastName: "Casata",
    businessName: "Nome d'affari",
    previous: "Precedente",
    next: "Dopu",
    pleaseSelectBusinessCategory: "Sceglite puru a vostra categuria d'impresa",
    pleaseEnterValidEmail: "Inserite un email validu",
    pleaseEnterPostCode: "Inserite u codice postale",
    postCode: "Codice postale",
    phoneNumberInUse: "Stu numeru di telefunu hè in usu dighjà!",
    emailInUse: "Stu email hè digià in usu!",
    foodAndDrinks: "Alimentazione è Bevande",
    salonAndBeauty: "Salone è Bellezza",
    fashionAndAccessories: "Moda è Accessori",
    gymAndFitness: "Palestra è Fitness",
    travelAndHotel: "Viaghju è Hotel",
    homeAndGifts: "Casa è Regali",
    washingAndCleaning: "Lavatu è Pulitu",
    gadgetsAndElectronics: "Gadgets è Elettronica",
    groceries: "Prudutti",
    others: "Altri",
    submit: "Invia",
    accountCreatedSuccessful: "U vostru contu hè statu creatu cù successu.",
    pleaseEnterAddress: "Per piacè Inserite u vostru Indirizzu",
    addressLine1: "Linia d'indirizzu 1",
    addressLine2: "Linia d'indirizzu 2",
    choosePassword: "Sceglite u Password",
    passwordMustBe6Characters:
      "A parolla d'intesa deve esse almenu 6 caratteri.",
    howDidYouHearLoystar: "Cumu avete intesu parlà di Loystar?",
    referralCode: "Codice di riferimentu",
    byClickingTheButton: " Clicchendu u buttone sottu, accunsente à u",
    termsAndSevice: "Termini",
    wereCreatingAccount: "Avemu creatu u vostru contu",
    proceed: "Procede",
    verificationCodeMustBe6: "U Codice di Verificazione deve esse 6 cifre",
    pleaseEnter6DigitCode: "Per piacè Inserite un codice à 6 cifre",
    enterVerificationCode: "Ingressu u Codice di Verificazione",
    resendToken: "Rinviate u Gettone",
    verify: "Verificà",
    transactions: "Transazzioni",
    todaySales: "Vendite d'oghje",
    salesHistory: "Storia di e Vendite",
    supplyHistory: "Storia di l'approvvigionamentu",
    new: "Novu",
    invoices: "Fatture",
    disbursements: "Pagamenti",
    offlineSales: "Vendite Offline",
    products: "Prudutti",
    customers: "Clienti",
    multiLevelLoyalty: "Lealtà Multi-Livellu",
    loyaltyPrograms: "Programmi di Lealtà",
    members: "Membri",
    appStore: "App Store",
    orderMenu: "Menu Ordine",
    settings: "Impostazioni",
    staffAndBranches: "Bastoni è Filiali",
    myAccount: "U mo contu",
    switchToSellMode: "Passà à u Modu di Vendita",
    signOut: "Sorte",
    getFreeSubscription: "Uttene Abbonamentu Gratuitu",
    onlyNumbersAllowed: "Solu i numeri sò permessi",
    yourAccountMustBe10Digits:
      "u vostru numeru di contu deve esse un numeru à 10 cifre",
    yourBvnMustBe11: "u vostru BVN deve esse un numeru di 11 cifre",
    pleaseSelectBank: "Sceglite puru a vostra banca",
    selectYourBank: "Sceglite a vostra Banca",
    enterBankAccountNumber: "Inserite u Numeru di Contu Bancariu",
    enterBvnNumber: "Inserite a vostra BVN",
    connectBankAccount: "Cunnette vi Contu Bancariu",
    passwordResetSuccessfulAndSignOut:
      "A Vostra Password hè stata resettata cù successu. Fate un clic nantu à u buttone Esci quì sottu per firmà torna",
    enterCurrentPassword: "Inserite a password attuale",
    pleaseEnterCurrentPassword: "Per piacè inserite a password attuale",
    phoneNumber: "Numeru di telefonu",
    sex: "Sessu",
    dateOfBirth: "Data di nascimentu",
    state: "Statu",
    country: "Paese",
    loyaltyId: "ID di Lealtà",
    createdAt: "Creatu à",
    noOfTransactionsMade: "N ° transazzioni fatte",
    yourDownloadWillStart: "U vostru scaricamentu principierà in un mumentu",
    exportCustomers: "Esporta Clienti",
    youhaveSuccessfullyToppedUp:
      "Avete rializatu cù successu e vostre Unità SMS.",
    youNowHave: "Avete avà",
    smsUnits: "Unità SMS",
    enterNumberOfSmsUnits: "Inserite u Numru di Unità SMS chì Intendite Cumprà",
    pleaseEnterANumericValue: "Inserite un valore numericu",
    pay: "Pagà",
    accountEditedSuccessfully:
      "U vostru contu hè statu modificatu cù successu.",
    youAeCurrentlyOn: "Avete attualmente",
    plan: "Pianu",
    userData: "Dati di l'Usuariu",
    businessData: "DATI DI AFFARI",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Sceglite puru a vostra valuta",
    selectYourCurrency: "Sceglite a vostra valuta",
    purchaseMoreSmsUnits: "Cumprate più Unità SMS cù u modulu sottu",
    youHave: "Hai",
    atLeast4SmsUnitsRrequired:
      "Almenu 4 unità sms sò richieste per a verificazione, per piacè ricaricate!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verificate puru u vostru contu bancariu per rispettà i requisiti di "Cunnosce u vostru Cliente" (KYC). Questu vi permetterà di raccoglie pagamenti via USSD o trasferimentu istantaneu, riceve ordini da i clienti è trasformà a liquidazione di e transazzioni. Applicabile solu à i cummercianti nigeriani. Per piacè, premete u buttone sottu per cumincià.',
    reConnectBankAccount: "Ricunnettate u Contu Bancariu",
    accountName: "Nome di u contu",
    accountNumber: "Numeru di contu",
    bankName: "Nome di a Banca",
    verified: "Verificatu",
    accountDetails: "Dettagli di u contu",
    kycBankAccount: "KYC",
    createTier: "Creà Livellu",
    fileUploadSuccessful: "schedariu caricatu cù successu",
    fileUploadFailed: "Caricamentu di u schedariu hà fiascatu",
    createLoyaltyProgram: "Creà un Prugramma di Fideltà",
    createLoyalty: "Creà Lealtà",
    name: "Nome",
    loyaltyArtwork: "Opere d'arte di Lealtà",
    clickToUpload: "Cliccate per carica",
    amountToPointsRatio: "Rapportu Quantità à Punti",
    points: "Punti",
    recommendedAmountToPointRatio:
      "Per a Best Loyalty Experience, i punti à a Razione di Valuta seranu Numeri Round Round.",
    pleaseTypeIn: "Per piacè scrivite",
    toDeleteLoyalty: "per sguassà sta lealtà",
    deleteLoyalty: "Elimina a Lealtà",
    toConfirm: "per cunfirmà",
    edit: "Mudificà",
    pointsAwardedSuccessfully: "Punti attribuiti cù successu.",
    enterPointValueToAward:
      "Inserite u Valore Puntale chì Vulete Premià i Clienti",
    award: "Premiu",
    awardPointValuesToCustomer: "Valore di Premiu per Cliente",
    enrollMembersToLoyalty: "Scrive i Membri à a Lealtà",
    awardPoints: "Punti di Premiu",
    enroll: "Scrive si",
    home: "Home",
    loyalty: "Lealtà",
    enrollCustomers: "Scrive i Clienti",
    selected: "Sceltu",
    customer: "Cliente",
    loyaltyActivationSuccessful: "L'attivazione di a Lealtà hè riesciuta.",
    loyaltyDeactivationSuccessful: "A disattivazione di a Lealtà hè riesciuta.",
    viewTier: "Vede u Livellu",
    deactivate: "De-Attivà",
    activate: "Attivà",
    actions: "Azzioni",
    nameOfLoyalty: "Nome di Lealtà",
    loyaltyStatus: "Status di Lealtà Status di Lealtà",
    numberOfTiers: "Numaru di Livelli",
    createdOn: "Creatu u",
    createATier: "Crea un Tier",
    stopCreatingTierConfirmation: "Vulete smettere di creà un tier?",
    stopEditingTierConfirmation: "Vulete smette di mudificà stu livellu?",
    nameOfTier: "Nome di u Livellu",
    minimumSpendingTarget: "Obiettivu di Spesa Minima",
    maximumSpendingTarget: "Target Massimu di Spesa",
    minimumSpendingTargetRequired: "u scopu minimu di spesa hè richiestu",
    maximumSpendingTargetRequired: "u target massimu di spesa hè richiestu",
    rewardSponsor: "Sponsor di Reward",
    pleaseChooseARewardSponsor: "Per piacè sceglite un sponsor di ricumpensa",
    self: "Self",
    partner: "Partner",
    rewardPartner: "Partner di Reward",
    pleaseSelectRewardPartner: "Sceglite puru u vostru cumpagnu di ricumpensa",
    rewards: "Ricumpense",
    pleaseSelectAReward: "Per piacè selezziunate una ricumpensa",
    instructionsOnRedeemingReward:
      "Istruzzioni nantu à cume u Cliente Duverebbe Riacquistà a Ricumpensa",
    pleaseFillInThisField: "Per piacè Riempite stu Campu!",
    toDeleteThisTier: " per sguassà stu livellu",
    deleteTier: "Elimina u Tier",
    viewMembers: "Vede i Membri",
    membersEnrolled: "Membri Inscritti",
    instruction: "Istruzzioni",
    membersIn: "Membri in",
    availableTiersInLoyalty: "Livellu (i) dispunibule in u prugramma di Lealtà",
    tiers: "Livelli",
    totalTier: "TIERU TOTALE",
    availableLoyaltyProgramme: "Programu di Fideltà Disponibile",
    totalLoyalties: "FIDALTÀ TOTALI",
    memberDetails: "Dettagli di i membri",
    nameOfCustomer: "Nome di u Cliente",
    address: "Indirizzu",
    allEnrolledMembers: "Tutti i membri iscritti",
    thisIsToWishYouHappyBirthday:
      "Questu hè per desiderà un assai felice anniversariu è una vita prospera. Grazie per tuttu ciò chì site à Loyster. Bona Feste!",
    inputAnOfferPlease: "Inserite una Offerta per piacè",
    pleaseSelectTheInsertPoint:
      "Sceglite puru u puntu di inserimentu in u messagiu è cliccate di novu",
    birthdayOfferAndMessage: "Offerta d'anniversariu è Messaghju",
    birthdayOffer: "Offerta d'anniversariu",
    birthdayMessage: "Missaghju d'anniversariu",
    noOfferFound: "Nisuna offerta truvata",
    settingABirthdayOffer:
      "Impostà una offerta d'anniversariu permette à i clienti di riceve sta offerta per SMS u so anniversariu",
    createOffer: "Crea Offerta",
    whatIsTheOffer: "Chì hè l'offerta?",
    editMessage: "Mudificà u Messaghju",
    insert: "Inserisce",
    theNameOfCustomerInserted: "U nome di u cliente serà inseritu quì",
    theBirtdayOfferInserted: "L'offerta d'anniversariu serà inserita quì",
    youSuccessfullyAddedNewBranch:
      "Avete aghjuntu cù successu una nova filiale!",
    addNewBranch: "Aghjunghje New Branch",
    addBranch: "Aghjunghje Branch",
    additionalBranchWillIncur: "Un Ramu Addizionale incurreria",
    perBranch: "per ramu",
    ecommerceBranchCosts: "Coste di u Ramu di eCommerce",
    pleaseEnterBranchName: "Inserite puru u nome di a filiale",
    pleaseEnterBranchAddress1: "Inserite l'indirizzu linea 1 di u ramu",
    enterBranchAddress1: "Inserite l'indirizzu linea 1 di u ramu",
    enterBranchAddress2: "Inserite l'indirizzu linea 1 di u ramu",
    pleaseEnterBranchAddress2: "Inserite l'indirizzu linea 2 di u ramu",
    enterBranchName: "Inserite u nome di u ramu",
    successfullyAddedStaff: "Avete aghjustatu cù successu un novu persunale!",
    addNewStaff: "Aghjunghje New Staff",
    addStaff: "Aghjunghje Staff",
    additionalStaffWillIncur: "U Staff Addizionale incurrerebbe",
    perStaff: "per persunale.",
    pleaseEnterStaffEmail: "Per piacè inserite l'email di u persunale",
    pleaseEnterStaffUsername:
      "Inserite puru u nome d'utilizatore di u persunale",
    pleaseEnterStaffPassword: "Per piacè inserite a password di u persunale",
    pleaseSelectStaffRole: "Per piacè selezziunate u rolu di u persunale",
    selectStaffRole: "Sceglite u rolu di u persunale",
    enterStaffEmail: "Inserite l'email di u persunale",
    enterStaffUsername: "Inserite u nome d'utilizatore di u persunale",
    enterStaffPassword: "Inserite a password di u persunale",
    spacesNotAllowedInUsername: "spazii micca permessi in nome d'utilizatore",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Per piacè selezziunate l'imprese per attaccà u persunale",
    searchForBranchToAttachStaff: "Cerca filiale per attaccà u persunale",
    username: "Nome d'utilizatore",
    role: "Rolu",
    areYouSureToDeleteThis: "Site sicuru di cancellà questu",
    branches: "Rami",
    upgradeYourPlan: "Aghjurnate u vostru Pianu.",
    add: "ADD",
    addNew: "Aghjunghje Novu",
    customerWithEmailAlreadyExists:
      "U cliente cù email / numaru di telefunu esiste dighjà!",
    successfullyAddedNewCustomer:
      "Avete aghjustatu cù successu un novu cliente!",
    addCustomer: "Aghjunghje Cliente",
    pleaseEnterCustomerFirstName: "Per piacè inserite u nome di u cliente",
    pleaseEnterCustomerLastName: "Per piacè inserite u cognome di u cliente",
    pleaseEnterCustomerPhoneNumber:
      "Inserite u numeru di telefonu di u cliente",
    pleaseEnterCustomerEmail: "Per piacè inserite l'email di u cliente",
    pleaseEnterCustomerAddressLine: "Inserite a Linea d'indirizzu di u cliente",
    pleaseEnterCustomerOtherAddress: "Inserite l'altru Indirizzu di u cliente",
    pleaseSelectCustomerGender: "Sceglite puru u sessu di u cliente",
    gender: "Genere",
    male: "Maschile",
    female: "Femmina",
    bank: "Banca",
    selectBank: "Selezziunate Banca",
    stateOrRegionOrProvince: "Statu / Regione / Pruvincia",
    customerNotes: "Note di Cliente",
    sendSms: "Mandà SMS",
    editCustomer: "Modificà u Cliente",
    redeemReward: "Riscatta Reward",
    issueLoyaltyCard: "Emissione Carta di Fideltà",
    deleteCustomer: "Elimina Cliente",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Avete assignatu cù successu un ID di Cumpagnia di Lealtà",
    noMembershipIdAvailable:
      "Nisun ID d'appartenenza dispunibule. Per piacè cuntattateci hello@loystar.co",
    sendEmail: "Mandà Email",
    membershipPoints: "Punti di aderenza",
    customerDetails: "Dettagli Clienti",
    close: "Vicinu",
    loyaltyBalance: "Balance di Fideltà",
    pointsBalance: "Balance di Punti",
    starBalance: "Star Balance",
    requiredPoints: "Punti richiesti",
    requiredStars: "Stelle richieste",
    reddemCode: "Riacquistà u Codice",
    toDeleteThisCustomer: "per sguassà stu cliente",
    customerHasBeenDeletedSuccessfully:
      "U Cliente hè statu cancellatu cù successu!",
    customerWithPhoneAlreadyExists:
      "Cliente cù numeru di telefunu esiste dighjà",
    customerWasSuccessfullyEdited: "U Cliente hè statu editatu cù successu",
    anErrorOccured: "Un errore hè accadutu",
    phoneNumberIncludeCountry:
      "Numeru di Telefono (Includite u Codice di u Paese)",
    yourFileQueuedForUpload:
      "U vostru schedariu hè statu messu in coda per u upload. Per piacè rinfriscà a pagina dopu qualchì siconda.",
    thereWasAnErrorPerformingOperation:
      "Ci hè statu un errore durante a realizazione di l'operazione!",
    pleaseSelectFile: "Per piacè Selezziunate un File!",
    oopsOnlyCsvFilesAllowed:
      "Oups! Solu i fugliali CSV permessi. Per piacè caricate un schedariu .csv.",
    fileShouldBeSmallerThan5Mb:
      "U schedariu deve esse più chjucu cà 5 MB. Se avete un schedariu più grande, per piacè mail support@loystar.co. Grazie",
    customerFirstNameIsRequired: "u nome di u cliente hè necessariu in fila",
    customerPhoneNumberIsRequired:
      "u numeru di telefunu di u cliente hè necessariu in fila",
    importCustomers: "Importa Clienti",
    upload: "Caricà",
    clickIconToDownloadCsv:
      "Cliccate nant'à questu icona per scaricà u mudellu di u schedariu CSV.",
    getGoogleSheetVersion: "Uttenite a versione di u fogliu Google",
    clickOrDragFileToUpload:
      "Cliccate o trascinate u fugliale in questa zona per caricare",
    missingOrInvalidColumnHeader:
      "Intestazione di colonna mancante o invalida. Per piacè seguitate u furmatu di mudellu. Grazie.",
    youHaveImported: "Avete Importatu",
    youSuccessfullyRedeemedReward:
      "Avete riscattatu cù successu a vostra ricumpensa!",
    sixDigitCode: "Codice di sei cifre",
    pleaseEnterCustomerRewardCode:
      "Inserite u codice di ricumpensa di u cliente",
    enterRewardCode:
      "Inserite u codice di ricumpensa. (U codice di ricumpensa hè sensibile à maiuscule)",
    selectLoyaltyProgram: "Selezziunate u prugramma di fidelizazione",
    stamps: "Francobolli",
    smsUnitsLow: "Unità SMS Bassa",
    pleaseTopUpSmsUnits: "Per piacè Ricaricà Unità SMS",
    smsSuccessfullySentForDelivery: "SMS inviatu cù successu per a consegna!",
    sendSmsTo: "Mandà SMS à",
    allCustomers: "Tutti i Clienti",
    unitsAvailable: "Unità Dispunibule",
    pleaseTypeInTheMessage: "Per piacè scrivite u missaghju",
    message: "Missaghju",
    charactersRemaining: "caratteri restanti",
    avoidUseOfSpecialCharacters:
      "Evite l'usu di Caratteri Speziali è Emojis per cunservà Unità SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Errore Fetching Clienti Dettagli MultiLevel",
    search: "Cerca",
    reset: "Resettate",
    importCustomer: "Importa Cliente",
    addNewCustomer: "Aghjunghje Novu Cliente",
    sendSmsBroadcast: "Mandate Trasmissione SMS",
    totalCustomers: "CLIENTI TOTALI",
    disbursementDetails: "Dettagli di Sbursement",
    paidBy: "Pagatu Da",
    disbursed: "Sbursatu",
    bankAccountName: "Nome di u Contu Bancariu",
    bankAccountNumber: "Numeru di Contu Bancariu",
    transferInitiated: "Trasferimentu Iniziatu",
    transferCompleted: "Trasferimentu Cumpletatu",
    pleaseEnterAValid: "Inserite una valida",
    begin: "principià",
    end: "fine",
    date: "data",
    paymentDate: "Data di Pagamentu",
    selectDisbursementDuration: "Selezziunate Durata di Sbursement",
    totalSettled: "Total Settled",
    totalUnsettled: "Total Unsettled",
    toDeleteThisSale: "per sguassà sta vendita",
    draftSaleDeletedSuccessfully:
      "Abbozzu di Vendita hè statu sguassatu cù successu!",
    deleteSale: "Sguassà Vendita",
    pleaseTypeInYourTotal:
      "Inserite u vostru tutale per cunfirmà l'eliminazione",
    billDetails: "Dettagli di fattura",
    printBill: "Stampa Bill",
    servedBy: "Servitu da",
    total: "Tutale",
    createdDate: "Data di creazione",
    createdTime: "Tempu Creatu",
    status: "Statutu",
    loginSuccessful: "Cunnessione successu",
    pleaseWaitWhileWeConnectAccount:
      "Aspettate per piacè mentre cunnettemu u vostru contu",
    connectionFailedTryAgain: "A cunnessione hà fiascatu. Pruvate torna.",
    connectionSuccessful: "Cunnessione riesciuta",
    viewDetails: "Vede i Dettagli",
    enable: "Attivà",
    free: "Gratuitu",
    cost: "Costu",
    visitWebsite: "Visita u situ Web",
    pleaseUpgradeYourPlanToPro:
      "Per piacè aghjurnate u vostru pianu à Pro Plus per attivà sta app",
    connectYourBankAccount:
      "Cunnette vi u vostru Contu Bancariu per pudè riceve pagamenti.",
    disable: "Disattivà",
    enableApp: "Attivà App",
    addNewProductToInvoice: "Aghjunghje Novu Produttu à Fattura",
    toDeleteThisInvoice: "per sguassà sta Fattura",
    invoiceDeletedSuccessfully: "A fattura hè stata sguassata bè!",
    deleteInvoice: "Sguassà Fattura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Avete aghjustatu cù successu un missaghju di pagamentu di Fattura!",
    pleaseEnterPaymentInstructions: "Inserite puru istruzzioni di pagamentu",
    invoiceId: "ID di fattura",
    paidDate: "Data pagata",
    reference: "Riferimentu",
    productAdded: "Produttu aghjuntu",
    productOutOfStock: "Produttu fora di scorta. Per piacè rifornite.",
    totalInvoices: "FACTURI TOTALI",
    totalPaidInvoices: "FACTURI PAGATE TOTALI",
    totalUnpaidInvoices: "FACTURI TOTALI NON PAGATE",
    loyaltyProgramDeleted: "Programu di Fideltà Eliminatu",
    thisLoyaltyProgramDeletedSuccessfully:
      "Stu prugramma di fidelizazione hè statu sguassatu cù successu",
    thisLoyaltyProgramEditedSuccessfully:
      "Stu prugramma di fidelizazione hè statu editatu cù successu",
    loyaltyProgramAddedSuccessfully:
      "U prugramma di fidelizazione hè statu aghjuntu cù successu",
    loyaltyProgramEdited: "Programma di Lealtà Editatu",
    loyaltyProgramAdded: "Prugramma di Lealtà aghjuntu",
    loyaltyDetails: "Dettagli di Lealtà",
    doYouWantToCloseDialog: "Vulete chjude stu dialogu?",
    pleaseEnterLoyaltyName: "Per piacè inserite u nome di a vostra lealtà",
    programType: "Tipu di prugramma",
    pleaseSelectProgramType: "Sceglite puru un tipu di prugramma",
    simplePoints: "Punti Semplici",
    stampsProgram: "Programma di Francobolli",
    threshold: "Soglia",
    pleaseEnterLoyaltyThreshold: "Per piacè inserite a soglia di fidelizazione",
    reward: "Ricumpensa",
    pleaseEnterLoyaltyReward: "Per piacè inserite a ricumpensa di a lealtà",
    totalUserPoints: "Total Punti Utente",
    totalUserStamps: "Timbri Utenti Totali",
    spendingTarget: "Ughjettivu di spesa",
    spendingTargetHint1:
      "U target di spesa hè quantu un cliente deve spende per guadagnà a ricumpensa. 1 valore di valuta = 1 puntu.",
    spendingTargetHint2:
      "U timbru di destinazione hè quanti timbri chì un cliente deve raccoglie per guadagnà a ricumpensa. es. 5",
    addNewLoyaltyProgram: "Aghjunghje Novu Prugramma di Fideltà",
    addLoyaltyProgram: "Aghjunghje u prugramma di fidelizazione",
    loyaltyProgramType: "Tipu di prugramma di Lealtà",
    pleaseSelectLoyaltyProgramType:
      "Per piacè selezziunate u tippu di prugramma di fidelizazione",
    nameOfProgram: "Nome di u prugramma",
    pleaseEnterProgramName: "Inserite u nome di u prugramma",
    whatIsTheReward: "Chì hè a ricumpensa?",
    egNextPurchaseIsFree: "Es. A prossima compra hè gratuita",
    customerName: "Nome di Cliente",
    guestCustomer: "Cliente Invitatu",
    orderConfirmedSuccessfully: "Ordine cunfirmatu cù successu",
    orderCancelledSuccessfully: "Ordine annullatu cù successu",
    confirmOrder: "Cunfirmà l'ordine",
    cancelOrder: "Annullà l'ordine",
    allOrders: "Tutti l'Ordini",
    totalOrders: "ORDINI TOTALI",
    doYouWantToAcceptOrder: "Vulete accettà questu ordine?",
    doYouWantToCancelOrder: "Vulete annullà questu ordine?",
    orderDetails: "Dettagli di l'Ordine",
    orderCreatedAt: "Ordine creatu à",
    supplier: "Fornitore",
    productName: "Nome di u Produttu",
    quantity: "Quantità",
    unitPrice: "Prezzo Unitu",
    receivedBy: "Ricevutu da",
    reportFrom: "Rapportu da",
    totalSupplies: "Pruvisti Totali",
    allRightsReserved: "Tutti i diritti riservati",
    toDeleteThisTransaction: "per sguassà sta Transazzione",
    transactionDeletedSuccessfully:
      "A transazzione hè stata sguassata cù successu. U stock hè statu restituitu à l'inventariu.",
    deleteTransaction: "Elimina Transazzione",
    transactionDetails: "Dettagli di Transazzione",
    printReceipt: "Print Receipt",
    channel: "Canale",
    discount: "Scontu",
    profit: "Prufittu",
    discountedSales: "Vendite scontate",
    errorFetchingRecord: "Errore Fetching Record",
    exportTransactions: "Transazzioni di Esportazione",
    errorFetchingSalesRecord:
      "Errore durante a ricerca di u registru di vendita per l'Esportazione.",
    totalSellingPrice: "Prezzo di Vendita Totale",
    totalCostPrice: "Prezzo di Costu Totale",
    appliedDiscount: "Discount applicatu",
    noOfItems: "Nùmeru di Articuli",
    sales: "Vendite",
    export: "Esporta",
    totalProfit: "Prufittu Totale",
    totalBalanceInPeriod: "Bilanciu Totale in Periodu",
    allTimeSales: "Vendite Tutti i Tempi",
    records: "Dischi",
    todaysSales: "Vendite d'oghje",
    transaction: "transazzione",
    youHaveExceededTotalNumberOfProducts:
      "Avete superatu u numeru tutale di prudutti permessi in u vostru pianu. Aghjurnate u vostru pianu per gode di un limite più altu.",
    youNeedToHaveLoyaltyProgram:
      "Avete bisognu di avè un Programma di Fideltà per aduprà sta funzione!",
    price: "U prezzu",
    category: "Catigurìa",
    stockTracking: "Tracciatu Stock",
    stockCount: "Conti di Stocchi",
    taxed: "Impositu",
    originalPrice: "Prezzo originale",
    costPrice: "Prezzo di Costu",
    unit: "Unità",
    productImage: "Immagine di u Produttu",
    taxRate: "Tariffa fiscale",
    taxType: "Tipu di impositu",
    trackInventory: "Inventariu di Pista",
    variants: "Varianti",
    hasVariants: "Hà Varianti",
    importProduct: "Pruduttu di Importazione",
    exportProducts: "Prudutti di Esportazione",
    addNewProduct: "Aghjunghje Novu Produttu",
    viewCategory: "Vede a Categoria",
    viewSuppliers: "Vede i Suppliers",
    receiveInventory: "Ricevi Inventariu",
    printAllProductsTag: "Stampa Tag Tutti i Prudutti",
    deleteAll: "Sguassà tuttu",
    totalProducts: "TOTALI PRODOTTI",
    youveSuccessfullyAddedANewCategory:
      "Avete aghjustatu cù successu una nova categuria",
    addNewCategory: "Aghjunghje Nova Categoria",
    addCategory: "Aghjunghje Categoria",
    categoryName: "Nome di a categuria",
    pleaseEnterCategoryName: "Inserite u nome di a categuria",
    stampsTarget: "Target di Francobolli",
    sendInventory: "Mandà Inventariu",
    productDetails: "Dettagli di u Produttu",
    youveSuccessfullyEditedThisCategory:
      "Avete editatu cù successu sta categuria",
    update: "Aghjurnamentu",
    categoryList: "Lista di categurie",
    categories: "Categurie",
    enterQuantityToUpdate: "Inserite una quantità da aghjurnà",
    inventorySentSuccessfully: "L'inventariu hè statu mandatu cù successu!",
    updateInventory: "Inventariu d'aghjurnamentu",
    currentQuantity: "Quantità attuale",
    pleaseEnterQuantityToAdd: "Inserite a quantità chì vulete aghjunghje",
    pleaseSelectABranch: "Per piacè selezziunate una Filiale",
    searchForBranch: "Cerca filiale",
    youCantSendMoreThanStock:
      "Ùn pudete micca mandà più di ciò chì avete in stock",
    send: "Mandà",
    pleaseEnterQuantityToSend: "Per piacè inserite a quantità chì vulete mandà",
    productNameIsRequiredOnRow: "u nome di u produttu hè necessariu in fila",
    productCategoryIsRequiredOnRow:
      "a categuria di pruduttu hè necessariu in fila",
    productPriceIsRequiredOnRow: "U prezzu di u produttu hè necessariu in fila",
    productUnitIsRequiredOnRow: "unità di produttu hè necessariu in fila",
    productQuantityIsRequiredOnRow:
      "a quantità di pruduttu hè necessariu in fila",
    productVariantsRequireTracking:
      "e varianti di produttu richiedenu tracciamentu!",
    pleaseAddVariantClickButton:
      "Per piacè aghjunghje una variante clicchendu nantu à u buttone aghjustà!",
    totalVariantsMoreThanSelectedQuantity:
      "Varianti Totali sò più cà quantità di prudutti selezziunati, Pls riduce a quantità di varianti",
    productEditedSuccessfully: "U pruduttu hè statu editatu cù successu!",
    fileTooLargeLessThan4Mb:
      "A dimensione di u fugliale hè troppu grande! A dimensione di u file deve esse menu di 4 MB.",
    suchVariantAlreadyExist: "Tale variante esiste dighjà",
    addVariants: "Aghjunghje varianti",
    editProduct: "Mudificà u produttu",
    pleaseEnterProductName: "Inserite u Nome di u Produttu",
    pleaseEnterProductPrice: "Inserite u prezzu di u produttu",
    pleaseEnterProductOriginalPrice:
      "Per piacè Inserite u Prezzu Originale di u Produttu",
    productDescription: "Descrizione di u Produttu",
    selectProductCategory: "Selezziunà a categuria di pruduttu",
    pleaseSelectProductCategory: "Sceglite puru a categuria di pruduttu",
    productCostPrice: "Prezzo di Costu di u Produttu",
    productSellingPrice: "Prezzo di Vendita di u Produttu",
    productOriginalPrice: "Produttu Prezzo Originale",
    maxFileSizeAllowedIs4Mb: "Max. File Size permessa hè 4mb",
    productsWithPicturesArePublished:
      "I prudutti cù fiure sò publicati in Scuprite per riceve cumandamenti",
    shouldThisProductBeTracked: "Stu pruduttu deve esse tracciatu?",
    pleaseSelectStockUnit: "Sceglite puru unità di stock",
    stockUnit: "Unità di Stocu",
    bag: "BORSA",
    bottle: "BOTTIGLIA",
    bunch: "BUNCH",
    can: "CAN",
    carton: "Cartone",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRU",
    pack: "PACK",
    pair: "PARIGLIA",
    pieces: "PIECI",
    plate: "PIASTRA",
    tonne: "TONNE (MT)",
    uNIT: "UNITÀ",
    yard: "IARDU",
    pleaseEnterProductQuantity: "Per piacè inserite a quantità di u produttu",
    productQuantity: "Quantità di u Produttu",
    isThisProductTaxed: "Stu pruduttu hè tassatu?",
    selectTaxType: "Sceglite u Tipu di Impositu",
    pleaseSelectTaxType: "Sceglite puru u Tipu di Tassa",
    progressive: "Progressivu",
    proportional: "Prupurziunale",
    pleaseEnterProductTaxRate:
      "Inserite u tassiu di l'impositu nantu à i prudutti",
    doesProductHaveVariants: "Stu pruduttu hà varianti?",
    type: "Type",
    value: "Valore",
    pleaseEnterVariantType: "Inserite u tippu di a variante",
    pleaseEnterVariantValue: "Inserite u valore di a variante",
    pleaseEnterVariantPrice: "Inserite u prezzu di a variante",
    pleaseEnterVariantQuantity: "Inserite a quantità di a variante",
    productDeletedSuccessfully: "U pruduttu hè statu cancellatu cù successu!",
    categoryDeletedSuccessfully: "A categuria hè stata sguassata cù successu!",
    toDeleteThisProduct: "per sguassà stu Produttu",
    invalidProductQuantity: "Quantità di Produttu invalidu",
    quantityAddedIsOverStock:
      "A quantità chì aghjunghje hè sopra à ciò chì avete in stock.",
    itemInventoryNotTracked: "L'inventariu di l'articuli ùn hè micca tracciatu",
    addBulkQuantity: "Aghjunghje Quantità Bulk",
    enterBulkQuantity: "Inserite Quantità Bulk",
    pleaseEnterBulkQuantity: "Per piacè inserite Quantità Bulk",
    youveSuccessfullyAddedANewProduct:
      "Avete aghjuntu cù successu un novu pruduttu!",
    pleaseEnterProductSellingPrice:
      "Inserite u prezzu di vendita di u produttu",
    doYouWantToTrackProductStock: "Vulete seguità u stock di u produttu?",
    sellingPrice: "Prezzo di vendita",
    setProductExpiryReminder: "Impostate Reminder di Scadenza di u Produttu",
    productExpiryDate: "Produttu Data di Scadenza",
    startRemindingFrom: "Principià à ramintà Da",
    productSuppliesAddedSuccessfully:
      "Avete aghjuntu forniture di prudutti cù successu.",
    addProductSupplies: "Aghjunghje Forniture di Prudutti",
    pleaseSelectSupplier: "Sceglite puru u Fornitore",
    nameOfProduct: "Nome di u Produttu",
    pleaseSelectProduct: "Per piacè Selezziunate u Produttu",
    productVariant: "Variante di u Produttu",
    pleaseSelectAVariant: "Sceglite per piacè una variante",
    pleaseEnterUnitPrice: "Per piacè Inserite u Prezzo Unitu",
    businessBranch: "Filiale di l'affari",
    pleaseSelectBranch: "Per piacè selezziunate u ramu",
    youveSuccessfullyAddedANewSupplier:
      "Avete aghjustatu cù successu un novu fornitore",
    addSupplier: "Aghjunghje u Fornitore",
    pleaseEnterSupplierEmail: "Per piacè inserite l'email di u fornitore",
    pleaseAddADescription: "Per piacè aghjunghje una descrizzione",
    anErrorOccuredProductsDeleted:
      "Un errore hè accadutu durante a realizazione di l'operazione. Per piacè fate nutà, pochi prudutti puderianu esse stati cancellati in u prucessu",
    bulkDelete: "Delete Bulk",
    youAreAboutToDelete: "Site in traccia di sguassà",
    product: "Produttu",
    isDueToRedeem: "hè duvutu riscattà",
    aReward: "una ricumpensa",
    pleaseSelectCustomerToReeemReward:
      "Per piacè selezziunate un cliente per riscattà a ricumpensa.",
    youHaveNoLoyaltyProgramRunning:
      "Ùn avete micca un prugramma di lealtà attivu in esecuzione",
    customerHhasNoPointsInLoyaltyProgram:
      "U Cliente ùn hà micca punti in stu prugramma di fidelizazione",
    proceedWithPayment: "Procede cù u Pagamentu?",
    youAreAboutToPayForTransactionUsingPoints:
      "Site in traccia di pagà per a transazzione aduprendu punti.",
    customerHas: "U Cliente Hà",
    worth: "vale",
    andIsNotSufficientToPayForTransaction:
      "è ùn hè micca abbastanza per pagà per sta transazzione. Vulerianu aghjunghje u saldu cù un altru metudu di pagamentu?",
    addCustomerLoyalty: "Aghjunghjite a Lealtà di u Cliente",
    pleaseAddCustomerFirst:
      "Per piacè aghjunghje o selezziunate un cliente prima.",
    pleaseWaitWhileWeGetReady: "Aspettate per piacè, mentre ci preparemu",
    lowStock: "Low Stock",
    pleaseEnterAmountTendered: "Inserite a quantità offerta",
    areYouSureToBookSaleOffline:
      "Site sicuru di vulè riservà sta vendita fora di linea?",
    saleWouldBeBookedAutomatically:
      "A vendita seria prenotata automaticamente quandu accendite u vostru internet",
    offlineSalesBookingCancelled:
      "Riservazione di vendita fora di linea annullata",
    covid19Message:
      "COVID19: Lavate e mani cù sapone o Sanitize per fermà a diffusione. Stà sicuru sempre",
    saleSuccessfullyRecorded: "Vendita Arregistrata cù Successu!",
    sendReceiptToEmail: "Mandate ricevuta per E-mail",
    sendThankYouSms: "Mandate SMS di ringraziamentu",
    sendShippingDetails: "Mandà i dettagli di Spedizioni",
    addLoyalty: "Aghjunghjite a Lealtà",
    searchCustomerNameOrNumber: "Cerca nome o numeru di cliente",
    clickTheSearchCustomerBox:
      "Cliccate nantu à a Ricerca Cliente Box è Scan Card",
    enterProductPrice: "Inserite u prezzu di u produttu",
    enterPriceFor: "Inserite u prezzu per",
    searchForProduct: "Ricerca per u pruduttu",
    all: "Tutti",
    backToDashboard: "Torna à Dashboard",
    viewDraftSales: "Vede Abbozzi di Vendite",
    variantSelected: "variante scelta",
    variant: "variante",
    thePreviousVariantSelectionNotAvailable:
      "A selezzione di variante precedente ùn hè micca dispunibile per a nova variante scelta in base à u prezzu, cambiate a vostra selezzione.",
    pleaseSupplyPositiveQuantityNumber:
      "Per piacè furnite un numeru di quantità postive",
    lowStockFor: "Bassa scorta per",
    clearVariants: "Varianti chjaru",
    pleaseEnterQuantity: "Inserite Quantità",
    picture: "Ritrattu",
    redemptionToken: "Gettone di Redenzione",
    token: "Token",
    totalSpent: "Spesu Totale",
    confirmPayment: "Cunfirmà u Pagamentu",
    hasPaymentBeenMade: "U Pagamentu hè statu trattatu cù successu?",
    enterTransactionReference:
      "Inserite a referenza di transazzione chì avete pagatu cù",
    pleaseSelectACustomer: "Sceglite puru un cliente!",
    areYouSureToApplyDiscount: "Site sicuru chì vulete applicà u scontu?",
    addYourBankAccountToEnableUssd:
      "Aghjunghjite u vostru contu bancariu per attivà u trasferimentu Instant USSD da uPay",
    totalAmountToPay: "Quantità Totale Da Pagà",
    doYouWantToCancelTransaction: "Vulete annullà sta transazzione?",
    savePrintBill: "Salva / Stampa Bill",
    enterAmountCollectedForCustomer:
      "Inserite a quantità raccolta per u cliente",
    recordSale: "Vendita di Dischi",
    checkOutWith: "Scuprite cù",
    instantTransfer: "Trasferimentu Instant",
    dialTheUSSDCode: "Chjamate u codice USSD",
    pleaseSelectABank: "Per piacè selezziunate una banca",
    payWithUSSD: "Pagà Cù USSD",
    sendBillTo: " - Mandà Bill à",
    waitingForUSSDTransfer: "Aspittendu u Trasferimentu USSD",
    percent: "Percentuale",
    applyDiscount: "Dumandà Discount",
    thisBillHasBeenSaved: "Stu Bill hè statu salvatu",
    saveDraft: "Salvà Prughjettu",
    pleaseTypeANameToIdentifyCustomer:
      "Inserite un nome per identificà u cliente",
    paymentDetails: "Dettagli di Pagamentu",
    basePrice: "Prezzo Base",
    staff: "Staff",
    subTotal: "SubTotale",
    durationMonths: "Durata (mesi)",
    selectADuration: "Sceglite una Durata",
    oneMonth: "1 Mese",
    twoMonths: "2 Mesi",
    threeMonths: "3 Mesi",
    sixMonths: "6 Mesi",
    twelveMonths: "12 Mesi",
    eighteenMonths: "18 Mesi",
    twentyFourMonths: "24 Mesi",
    twoMonthsFree: "(2 Mesi Gratuiti)",
    threeMonthsFree: "(3 Mesi Gratuiti)",
    fiveMonthsFree: "(5 Mesi Gratuiti)",
    yourAccountHasBeen: "U vostru contu hè statu",
    renewed: "rinnuvatu",
    upgraded: "aghjurnatu",
    successfully: "successu",
    yourSubscription: "U vostru Abbonamentu",
    youAreCurrentlyEnrolledOnA: "Attualmente site iscrittu à un",
    pleaseChooseAPaymentOption: "Sceglite per piacè una Opzione di Pagamentu",
    planRenewal: "Pianu di Rinnovu",
    planUpgrade: "Pianu di Upgrade",
    pleaseSelectDurationToPayFor: "Sceglite per piacè a Durata chì vulete pagà",
    staffAccounts: "Conti di u persunale",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Sceglite puru un pianu",
    includeAddons: "Includite Add-ons",
    viewTransactions: "Vede Transazzioni",
    customerHasNoCompletedTansactions: "U Cliente ùn hà ancu compie tansazioni",
    branch: "Ramu",
    enterNumberOfEcommerceBranches: "Inserite Numeru di Rami di eCommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Inserite u numeru di Rami di eCommerce chì Intendite Pagà",
    ecommerceIntegration: "Integrazione Ecommerce",
    enterNumberOfBranches: "Inserite u numeru di filiali",
    enterNumberOfAdditionalBranchesToPay:
      "Inserite u Numaru di Rami Ulteriori chì Intendete Pagà",
    enterNumberOfStaffs: "Inserite u Numaru di Staff",
    enterNumberOfStaffsToPayFor:
      "Inserite u Numaru di Staff chì Intendete Pagà",
    discountApplies: "Scontu S'applica",
    starsOnThe: "stelle nantu à u",
    offer: "offerta",
    get: "Get",
    moreStarsToRedeem: "più stelle da riscattà",
    taxVat: "Impôt (TVA)",
    callCashierToCompletePayment: "Chjama Cassaiu per Cumplete u Pagamentu",
    receipt: "Ricevuta",
    dear: "Caru",
    thankYouForYourOrderFindGoods:
      "Grazie per u vostru ordine. Per piacè, truverete i seguenti beni furniti, cum'è accettatu.",
    shippingNote: "Nota di Spedizioni",
    enterShippingNote: "Inserite Nota di Spedizioni",
    shippingAddress: "Indirizzu di spedizione",
    enterShippingAddress: "Inserite l'indirizzu di Spedizioni",
    wellDoneYouAreDueToRedeem: "Bè fatta! Duvete riscattà",
    toGetYourRewardNextVisit:
      "per uttene a vostra ricumpensa in a vostra prossima visita. Grazie",
    pointsOnThe: "Punti nantu à u",
    morePointsToRedeem: "più punti da riscattà",
    showCode: "Mostra codice",
    toGetYourRewardOnNextVisit:
      "per uttene a vostra ricumpensa in a vostra prossima visita. Grazie",
    earn: "Guadagna",
    delivaryNote: "Nota Delivaria",
    draftSales: "Prughjetti di Vendita",
    startDate: "Data d'iniziu",
    endDate: "Data di fine",
    orders: "Ordini",
    checkout: "cuntrolla",
    noProductItem: "Nisun Produttu Articulu",
    selectProductImage: "Selezziunate Immagine di u Produttu",
    selectCountry: "Sceglite u paese",
    selectRegion: "Selezziunate Statu / Regione",
    printProductTag: "Stampa Tag Produttu",
    transactionReference: "Riferimentu di transazzione",
    Cashier: "Casieru",
    Manager: "Manager",
    Owner: "Pruprietariu",
    Admin: "Admin",
    addPartners: "Aghjunghjite Partenarii",
    addNewLoyaltyPartner: "Aghjunghjite un novu Partner di Fideltà",
    pleaseEnterCompanyName: "Per piacè inserite u nome di a cumpagnia",
    companyName: "Nome di a cumpagnia",
    pleaseEnterCompanyRepName:
      "Per piacè inserite u nome di u rappresentante di a cumpagnia",
    companyRepName: "Nome di u Rappresentante di a Cumpagnia",
    pleaseEnterCompanyRepEmail:
      "Per piacè inserite l'email di u rappresentante di a cumpagnia",
    companyRepEmail: "E-mail di u rappresentante di a cumpagnia",
    pleaseEnterCompanyRepPhone:
      "Per piacè inserite u numeru di telefunu di u rappresentante di a cumpagnia",
    companyRepPhone: "Numeru di telefunu di u rappresentante di a cumpagnia",
    addReward: "Aghjunghjite ricumpensa",
    pleaseEnterRewardName: "Per piacè inserite u nome di ricumpensa",
    rewardName: "Nome di ricumpensa",
    rewardQuantity: "Quantità di ricumpensa",
    rewardDescription: "Descrizzione di ricumpensa",
    rewardType: "Tipu di ricumpensa",
    pleaseEnterRewardType: "Per piacè inserite u tipu di ricumpensa",
    pleaseEnterRewardQuantity: "Per piacè inserite a quantità di ricumpensa",
    pleaseEnterRewardDescription:
      "Per piacè inserite a descrizzione di ricumpensa",
    fineDining: "Fine Dining",
    luxuryFashion: "Moda di lussu",
    hotels: "Alberghi",
    travel: "Viaghjà",
    foodAndBeverage: "Alimentazione è Bevande",
    fashion: "Moda",
    health: "Salute",
    furniture: "Mobili",
    entertainment: "Divertimentu",
    automobile: "Automobile",
    education: "Educazione",
    beautyAndSpa: "Bellezza è Spa",
    staycation: "A permanenza",
    events: "Avvenimenti",
    trips: "Viaghji",
    oilAndGas: "Oliu è Gas",
    laundry: "Lavanderia",
    partnerCategory: "Category Partner",
    freeItem: "Articulu gratuitu",
  },
  Croatian: {
    cashier: "blagajnik",
    manager: "menadžer",
    owner: "vlasnik",
    online: "na liniji",
    offline: "offline",
    changePassword: "Promijenite lozinku",
    currentPasswordMessage: "Molimo unesite svoju trenutnu lozinku",
    passwordMessage: "Molimo unesite svoju lozinku",
    currentPassword: "Trenutna lozinka",
    password: "lozinka",
    confirmPasswordMessage: "Molimo potvrdite svoju lozinku!",
    confirmPassword: "Potvrdi lozinku",
    sendViaEmail: "Slanje putem e -pošte",
    sendViaWhatsapp: "Slanje putem WhatsAppa",
    downloadPdf: "Preuzmite PDF",
    paid: "plaćeno",
    unpaid: "neplaćeno",
    partial: "djelomična",
    closeInvoice: "Želite li zatvoriti fakturu?",
    closeInvoiceConfirmation:
      "Faktura se možda neće spremiti. Želite li zatvoriti?",
    yes: "Da",
    no: "Ne",
    invoice: "Dostavnica",
    wasDeducted: "bio odbijen",
    for: "za",
    item: "Artikal",
    addProduct: "Dodajte proizvod",
    paymentReference: "Referenca plaćanja",
    amountPaid: "Uplaćeni iznos",
    discountAmount: "Iznos popusta",
    amountDue: "Dospjeli iznos",
    amount: "Iznos",
    dueDate: "Datum dospijeća",
    paymentType: "Način plaćanja",
    card: "Kartica",
    cash: "Unovčiti",
    bankTransfer: "Bankovni prijenos",
    paymentMessage: "Poruka o plaćanju",
    description: "Opis",
    sendReceipt: "Pošaljite potvrdu",
    delete: "Izbrisati",
    save: "Uštedjeti",
    resend: "Ponovno pošalji",
    saveAndSend: "Uštedjeti",
    invoiceSaved: "Račun je spremljen!",
    selectPaymentMethod: "Odaberite način plaćanja!",
    selectCustomer: "Molimo odaberite klijenta!",
    cartEmptyError:
      "Popis košarica ne može biti prazan, zatvorite račun i dodajte artikl u košaricu!",
    subscriptionExpired:
      "Vaša je pretplata istekla i vaš je račun sada ograničen. Kliknite donji gumb za obnovu računa",
    renew: "Obnoviti",
    holdOn: "Drži se",
    customerBank: "Banka klijenata",
    cancel: "Otkazati",
    selectACustomer: "Odaberite Kupac",
    invoiceSuccessfulPdfError:
      "Račun je uspješno kreiran, ali generiranje PDF -a traje duže nego inače. Vratite se uskoro.",
    downloadingInvoice: "Preuzimanje fakture",
    downloadingReceipt: "Preuzimanje potvrde",
    whatsappReceiptError:
      "Došlo je do pogreške prilikom slanja računa putem WhatsAppa. Pokušajte ponovo.",
    receiptToWhatsapp: "Račun je proslijeđen na WhatsApp",
    thankYouForPatronage: "Hvala vam na pokroviteljstvu",
    hereIsYourReceipt: "Evo vaše potvrde o uplati",
    errorSendingEmailReceipt:
      "Došlo je do greške pri slanju računa putem e-pošte, pokušajte ponovo ili kontaktirajte podršku",
    receiptSentToEmail: "Potvrda je poslana na e -poštu korisnika",
    invoiceSentToEmail: "Račun je poslan na e -mail kupca",
    invoiceSuccessWhatsappError:
      "Račun je uspješno kreiran, ali došlo je do pogreške prilikom slanja na WhatsApp. Pokušajte ponovo na popisu faktura",
    invoiceSuccessfulEmailError:
      "Račun je uspješno kreiran, ali došlo je do pogreške prilikom slanja kao e-pošte. Pokušajte ponovo s popisa faktura",
    invoiceSentToWhatsapp: "Račun je proslijeđen na WhatsApp",
    hello: "zdravo",
    pleaseDownloadInvoice: "Molimo preuzmite fakturu",
    pleaseDownloadReceipt: "Molimo preuzmite potvrdu",
    from: "iz",
    email: "E -pošta",
    upgrade: "Nadogradi",
    youAreOnFreePlan: "Nalazite se na besplatnom planu.",
    clickOn: "Kliknite na",
    yourPlanInFewSteps: " svoju pretplatu u nekoliko brzih koraka.",
    to: "do",
    yourSubscriptionHasExpired:
      "Vaša je pretplata istekla i vaš je račun sada ograničen.",
    days: "dana",
    yourSubscriptionExpiresIn: "Vaša pretplata na Loystar istječe u",
    createAcount: "Stvorite račun",
    signIn: "Prijaviti se",
    continue: "Nastaviti",
    enterOtp: "Unesite OTP PIN",
    enterValidOtp: "Unesite važeći PIN",
    pin: "PIN",
    tokenSentToMail: "Token je poslan na vašu e -poštu",
    passwordResetSuccessful: "Poništavanje lozinke je uspjelo",
    somethingWentWrong: "Nešto je pošlo po zlu!",
    resetPassword: "Resetiranje lozinke",
    iHaveResetCode: "Imam kôd za poništavanje lozinke",
    pleaseEnterEmail: "Molimo unesite svoju adresu e -pošte",
    aTokenWillBeSentToEmail: "Token će biti poslan na vašu e -poštu",
    enterEmail: "Upiši svoj email",
    sendinYourToken: "Slanje vašeg žetona ...",
    makeSureItMatchesPassword: "Provjerite odgovara li vašoj novoj zaporci",
    pleaseChooseNewPassword: "Odaberite novu lozinku",
    chooseNewPassword: "Odaberite novu lozinku",
    enterNewPassword: "Unesite novu lozinku za potvrdu",
    enterTokenSent: "Unesite žeton koji vam je poslan na poštu",
    resetToken: "Poništite token",
    resettingPassword: "Ponovno postavljanje lozinke ...",
    signUp: "Prijavite se",
    adminSignInWithEmail:
      " Administrator se prijavljuje e -poštom, dok se osoblje prijavljuje korisničkim imenom.",
    pleaseEnterEmailOrUsername:
      "Molimo unesite svoju adresu e -pošte ili korisničko ime",
    emailOrUsername: "E-mail ili korisničko ime",
    pleaseEnterPassword: "Molimo unesite lozinku",
    createAnAccount: "Stvorite račun",
    forgotPassword: "Zaboravili ste lozinku?",
    enterPhoneNumber: "Unesite telefonski broj",
    personalData: "Osobni podaci",
    validateConfirmationCOde: "Potvrdite kôd za potvrdu",
    pleaseEnterFirstName: "Molimo napišite svoje ime",
    pleaseEnterPhoneNumber: "Molimo unesite svoj telefonski broj",
    pleaseEnterLastName: "Molimo unesite svoje prezime",
    pleaseEnterBusinessName: "Unesite naziv svoje tvrtke",
    firstName: "Ime",
    lastName: "Prezime",
    businessName: "Naziv tvrtke",
    previous: "Prethodni",
    next: "Sljedeći",
    pleaseSelectBusinessCategory: "Odaberite svoju poslovnu kategoriju",
    pleaseEnterValidEmail: "Molimo unesite valjanu adresu e -pošte",
    pleaseEnterPostCode: "Molimo unesite poštanski broj",
    postCode: "Poštanski broj",
    phoneNumberInUse: "Ovaj telefonski broj je već u upotrebi!",
    emailInUse: "Ova adresa e -pošte je već u upotrebi!",
    foodAndDrinks: "Hrana i piće",
    salonAndBeauty: "Salon i ljepota",
    fashionAndAccessories: "Moda i dodaci",
    gymAndFitness: "Teretana i fitnes",
    travelAndHotel: "Putovanja i hotel",
    homeAndGifts: "Dom i darovi",
    washingAndCleaning: "Pranje i čišćenje",
    gadgetsAndElectronics: "Gadgeti i elektronika",
    groceries: "Namirnice",
    others: "Drugi",
    submit: "podnijeti",
    accountCreatedSuccessful: "Vaš je račun uspješno kreiran.",
    pleaseEnterAddress: "Molimo unesite svoju adresu",
    addressLine1: "1. redak adrese",
    addressLine2: "Red 2 adrese",
    choosePassword: "Odaberite lozinku",
    passwordMustBe6Characters: "Lozinka mora imati najmanje 6 znakova.",
    howDidYouHearLoystar: "Kako ste čuli za Loystar?",
    referralCode: "referentni kod",
    byClickingTheButton: " Klikom na donji gumb slažete se sa",
    termsAndSevice: "Pojmovi",
    wereCreatingAccount: "Kreiramo vaš račun",
    proceed: "Nastavite",
    verificationCodeMustBe6: "Verifikacijski kôd mora imati 6 znamenki",
    pleaseEnter6DigitCode: "Molimo unesite 6 -znamenkasti kôd",
    enterVerificationCode: "Unesite verifikacijski kôd",
    resendToken: "Ponovno pošaljite token",
    verify: "Provjerite",
    transactions: "Transakcije",
    todaySales: "Današnja prodaja",
    salesHistory: "Povijest prodaje",
    supplyHistory: "Povijest opskrbe",
    new: "Novi",
    invoices: "Fakture",
    disbursements: "Isplate",
    offlineSales: "Offline prodaja",
    products: "Proizvodi",
    customers: "Kupci",
    multiLevelLoyalty: "Vjernost na više razina",
    loyaltyPrograms: "Programi vjernosti",
    members: "Članovi",
    appStore: "Trgovina aplikacijama",
    orderMenu: "Izbornik naručivanja",
    settings: "Postavke",
    staffAndBranches: "Osoblje i podružnice",
    myAccount: "Moj račun",
    switchToSellMode: "Prebacite se u način prodaje",
    signOut: "Odjavi se",
    getFreeSubscription: "Nabavite besplatnu pretplatu",
    onlyNumbersAllowed: "Dopušteni su samo brojevi",
    yourAccountMustBe10Digits:
      "broj vašeg računa mora biti 10 -znamenkasti broj",
    yourBvnMustBe11: "vaš BVN mora biti 11 -znamenkasti broj",
    pleaseSelectBank: "Odaberite svoju banku",
    selectYourBank: "Odaberite svoju banku",
    enterBankAccountNumber: "Unesite broj bankovnog računa",
    enterBvnNumber: "Unesite svoj BVN",
    connectBankAccount: "Povežite bankovni račun",
    passwordResetSuccessfulAndSignOut:
      "Vaša lozinka je uspješno poništena. Kliknite dolje na gumb Odjava da biste se ponovno prijavili",
    enterCurrentPassword: "Unesite trenutnu lozinku",
    pleaseEnterCurrentPassword: "Molimo unesite trenutnu lozinku",
    phoneNumber: "Broj telefona",
    sex: "Seks",
    dateOfBirth: "Datum rođenja",
    state: "država",
    country: "Zemlja",
    loyaltyId: "ID vjernosti",
    createdAt: "Napravljeno u",
    noOfTransactionsMade: "Broj izvršenih transakcija",
    yourDownloadWillStart: "Preuzimanje će početi za trenutak",
    exportCustomers: "Izvozni kupci",
    youhaveSuccessfullyToppedUp: "Uspješno ste nadopunili svoje SMS jedinice.",
    youNowHave: "Sada imate",
    smsUnits: "SMS jedinice",
    enterNumberOfSmsUnits: "Unesite broj SMS jedinica koje namjeravate kupiti",
    pleaseEnterANumericValue: "Molimo unesite brojčanu vrijednost",
    pay: "Platiti",
    accountEditedSuccessfully: "Vaš je račun uspješno uređen.",
    youAeCurrentlyOn: "Trenutno ste uključeni",
    plan: "Plan",
    userData: "Korisnički podaci",
    businessData: "POSLOVNI PODACI",
    businessCategory: "Poslovna kategorija",
    pleaseSelectCurrency: "Molimo odaberite svoju valutu",
    selectYourCurrency: "Odaberite svoju valutu",
    purchaseMoreSmsUnits: "Kupite više SMS jedinica putem donjeg obrasca",
    youHave: "Imaš",
    atLeast4SmsUnitsRrequired:
      "Za potvrdu su potrebne najmanje 4 sms jedinice, nadopunite!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Potvrdite svoj bankovni račun u skladu sa zahtjevima "Upoznaj svog klijenta" (KYC). To će vam omogućiti prikupljanje plaćanja putem USSD -a ili trenutni prijenos, primanje naloga od korisnika i obradu namire transakcija. Odnosi se samo na nigerijske trgovce. Pritisnite donji gumb za početak.',
    reConnectBankAccount: "Ponovo povežite bankovni račun",
    accountName: "Korisničko ime",
    accountNumber: "Broj računa",
    bankName: "Ime banke",
    verified: "Provjereno",
    accountDetails: "Detalji Računa",
    kycBankAccount: "KYC",
    createTier: "Stvorite razinu",
    fileUploadSuccessful: "datoteka uspješno prenesena",
    fileUploadFailed: "prijenos datoteke nije uspio",
    createLoyaltyProgram: "Napravite program vjernosti",
    createLoyalty: "Stvorite lojalnost",
    name: "Ime",
    loyaltyArtwork: "Umjetnost lojalnosti",
    clickToUpload: "Kliknite za učitavanje",
    amountToPointsRatio: "Omjer iznosa u bodovima",
    points: "Bodovi",
    recommendedAmountToPointRatio:
      "Preporučeno: ₦ 1 do 1 bod. tj. za svaku potrošenu 1 Nairu vaši klijenti dobivaju 1 bod",
    pleaseTypeIn: "Molimo upišite",
    toDeleteLoyalty: "izbrisati tu vjernost",
    deleteLoyalty: "Izbriši vjernost",
    toConfirm: "potvrditi",
    edit: "Uredi",
    pointsAwardedSuccessfully: "Bodovi su uspješno dodijeljeni.",
    enterPointValueToAward:
      "Unesite vrijednost boda koju želite nagraditi kupcima",
    award: "Dodijeliti",
    awardPointValuesToCustomer: "Dodijelite vrijednost boda klijentu",
    enrollMembersToLoyalty: "Učlanite članove u Lojalnost",
    awardPoints: "Nagradni bodovi",
    enroll: "Upisati",
    home: "Dom",
    loyalty: "Odanost",
    enrollCustomers: "Upišite kupce",
    selected: "Odabran",
    customer: "Kupac",
    loyaltyActivationSuccessful: "Aktivacija vjernosti je uspješna.",
    loyaltyDeactivationSuccessful: "Deaktivacija vjernosti je uspješna.",
    viewTier: "Prikaz razine",
    deactivate: "Deaktivirajte",
    activate: "Aktivirati",
    actions: "Radnje",
    nameOfLoyalty: "Ime lojalnosti",
    loyaltyStatus: "Status vjernostiStatus lojalnosti",
    numberOfTiers: "Broj slojeva",
    createdOn: "Stvoreno dana",
    createATier: "Stvorite nivo",
    stopCreatingTierConfirmation: "Želite li prestati stvarati sloj?",
    stopEditingTierConfirmation: "Želite li prestati uređivati ovu razinu?",
    nameOfTier: "Naziv reda",
    minimumSpendingTarget: "Cilj minimalne potrošnje",
    maximumSpendingTarget: "Cilj maksimalne potrošnje",
    minimumSpendingTargetRequired: "potreban je minimalni cilj potrošnje",
    maximumSpendingTargetRequired: "potreban je maksimalni cilj potrošnje",
    rewardSponsor: "Sponzor nagrade",
    pleaseChooseARewardSponsor: "Odaberite sponzora nagrade",
    self: "Sebstvo",
    partner: "Partner",
    rewardPartner: "Nagradni partner",
    pleaseSelectRewardPartner: "Odaberite svog partnera za nagradu",
    rewards: "Nagrade",
    pleaseSelectAReward: "Odaberite nagradu",
    instructionsOnRedeemingReward:
      "Upute o tome kako kupac treba iskoristiti nagradu",
    pleaseFillInThisField: "Molimo ispunite ovo polje!",
    toDeleteThisTier: " za brisanje ove razine",
    deleteTier: "Izbriši razinu",
    viewMembers: "Pogledajte članove",
    membersEnrolled: "Upisani članovi",
    instruction: "Uputa",
    membersIn: "Članovi u",
    availableTiersInLoyalty: "Dostupni nivoi u programu vjernosti",
    tiers: "Redovi",
    totalTier: "UKUPNO VRIJEME",
    availableLoyaltyProgramme: "Dostupan program vjernosti",
    totalLoyalties: "UKUPNA VJERNOST",
    memberDetails: "Pojedinosti o članu",
    nameOfCustomer: "Ime kupca",
    address: "Adresa",
    allEnrolledMembers: "Svi upisani članovi",
    thisIsToWishYouHappyBirthday:
      "Ovim putem želim vam sretan rođendan i uspješan život. Hvala ti za sve što si Loyster. Sretne proslave!",
    inputAnOfferPlease: "Molimo unesite ponudu",
    pleaseSelectTheInsertPoint:
      "Molimo odaberite mjesto umetanja u poruci i kliknite ponovno",
    birthdayOfferAndMessage: "Rođendanska ponuda i poruka",
    birthdayOffer: "Rođendanska ponuda",
    birthdayMessage: "Rođendanska poruka",
    noOfferFound: "Nije pronađena nijedna ponuda",
    settingABirthdayOffer:
      "Postavljanje rođendanske ponude omogućuje korisnicima da ovu ponudu dobiju putem SMS -a na svoj rođendan",
    createOffer: "Napravi ponudu",
    whatIsTheOffer: "Koja je ponuda?",
    editMessage: "Uredi poruku",
    insert: "Umetnuti",
    theNameOfCustomerInserted: "Ovdje će biti umetnuto ime kupca",
    theBirtdayOfferInserted: "Ovdje će biti umetnuta rođendanska ponuda",
    youSuccessfullyAddedNewBranch: "Uspješno ste dodali novu podružnicu!",
    addNewBranch: "Dodajte novu podružnicu",
    addBranch: "Dodaj granu",
    additionalBranchWillIncur: "Dodatna podružnica bi nastala",
    perBranch: "po grani",
    ecommerceBranchCosts: "Troškovi podružnice e -trgovine",
    pleaseEnterBranchName: "Molimo unesite naziv podružnice",
    pleaseEnterBranchAddress1: "Molimo unesite 1. red adrese podružnice",
    enterBranchAddress1: "Unesite adresu 1. redak podružnice",
    enterBranchAddress2: "Unesite adresu 1. redak podružnice",
    pleaseEnterBranchAddress2: "Molimo unesite 2. red adrese podružnice",
    enterBranchName: "Unesite naziv podružnice",
    successfullyAddedStaff: "Uspješno ste dodali novo osoblje!",
    addNewStaff: "Dodajte novo osoblje",
    addStaff: "Dodajte osoblje",
    additionalStaffWillIncur: "Dodatno osoblje bi nastalo",
    perStaff: "po osoblju.",
    pleaseEnterStaffEmail: "Molimo unesite e -poštu osoblja",
    pleaseEnterStaffUsername: "Molimo unesite korisničko ime osoblja",
    pleaseEnterStaffPassword: "Molimo unesite lozinku osoblja",
    pleaseSelectStaffRole: "Odaberite ulogu osoblja",
    selectStaffRole: "Odaberite ulogu osoblja",
    enterStaffEmail: "Unesite e -adresu osoblja",
    enterStaffUsername: "Unesite korisničko ime osoblja",
    enterStaffPassword: "Unesite lozinku osoblja",
    spacesNotAllowedInUsername: "razmaci nisu dopušteni u korisničkom imenu",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Molimo odaberite tvrtku za pridruživanje osoblja",
    searchForBranchToAttachStaff:
      "Potražite poslovnicu za pridruživanje osoblja",
    username: "Korisničko ime",
    role: "Uloga",
    areYouSureToDeleteThis: "Jeste li sigurni da ćete ovo izbrisati",
    branches: "Podružnice",
    upgradeYourPlan: "Nadogradite svoj plan.",
    add: "DODATI",
    addNew: "Dodaj novi",
    customerWithEmailAlreadyExists:
      "Klijent s e -poštom/telefonskim brojem već postoji!",
    successfullyAddedNewCustomer: "Uspješno ste dodali novog kupca!",
    addCustomer: "Dodajte kupca",
    pleaseEnterCustomerFirstName: "Molimo unesite ime kupca",
    pleaseEnterCustomerLastName: "Molimo unesite prezime kupca",
    pleaseEnterCustomerPhoneNumber: "Molimo unesite telefonski broj korisnika",
    pleaseEnterCustomerEmail: "Molimo unesite e -mail klijenta",
    pleaseEnterCustomerAddressLine: "Molimo unesite adresu klijenta",
    pleaseEnterCustomerOtherAddress: "Molimo unesite drugu adresu kupca",
    pleaseSelectCustomerGender: "Odaberite spol kupca",
    gender: "Spol",
    male: "Muški",
    female: "Žena",
    bank: "Banka",
    selectBank: "Odaberite Banka",
    stateOrRegionOrProvince: "Država/Regija/Pokrajina",
    customerNotes: "Bilješke kupaca",
    sendSms: "Pošaljite SMS",
    editCustomer: "Uređivanje klijenta",
    redeemReward: "Iskoristite nagradu",
    issueLoyaltyCard: "Izdajte karticu vjernosti",
    deleteCustomer: "Izbriši kupca",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Uspješno ste dodijelili ID članstva vjernosti",
    noMembershipIdAvailable:
      "Nema dostupnih ID -ova članstva. Molimo kontaktirajte nas na hello@loystar.co",
    sendEmail: "Pošalji e-mail",
    membershipPoints: "Bodovi članstva",
    customerDetails: "Pojedinosti o kupcu",
    close: "Zatvoriti",
    loyaltyBalance: "Ravnoteža vjernosti",
    pointsBalance: "Stanje bodova",
    starBalance: "Star Balance",
    requiredPoints: "Potrebni bodovi",
    requiredStars: "Potrebne zvjezdice",
    reddemCode: "Otkupi kod",
    toDeleteThisCustomer: "za brisanje ovog kupca",
    customerHasBeenDeletedSuccessfully: "Klijent je uspješno izbrisan!",
    customerWithPhoneAlreadyExists: "Korisnik s telefonskim brojem već postoji",
    customerWasSuccessfullyEdited: "Klijent je uspješno uređen",
    anErrorOccured: "Došlo je do pogreške",
    phoneNumberIncludeCountry:
      "Telefonski broj (Uključuje pozivni broj zemlje)",
    yourFileQueuedForUpload:
      "Vaša datoteka je u redu za prijenos. Osvježite stranicu nakon nekoliko sekundi.",
    thereWasAnErrorPerformingOperation:
      "Došlo je do pogreške pri izvođenju operacije!",
    pleaseSelectFile: "Molimo odaberite datoteku!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Dopuštene su samo CSV datoteke. Prenesite .csv datoteku.",
    fileShouldBeSmallerThan5Mb:
      "Datoteka bi trebala biti manja od 5 MB. Ako imate veću datoteku, pošaljite e -poruku na support@loystar.co. Hvala vam",
    customerFirstNameIsRequired: "u retku je potrebno ime kupca",
    customerPhoneNumberIsRequired:
      "telefonski broj korisnika potreban je u retku",
    importCustomers: "Uvoz kupaca",
    upload: "Učitaj",
    clickIconToDownloadCsv:
      "Pritisnite ovu ikonu za preuzimanje predloška datoteke CSV.",
    getGoogleSheetVersion: "Preuzmite verziju Google tablice",
    clickOrDragFileToUpload:
      "Kliknite ili povucite datoteku na ovo područje za prijenos",
    missingOrInvalidColumnHeader:
      "Zaglavlje stupca nedostaje ili je nevažeće. Slijedite format predloška. Hvala vam.",
    youHaveImported: "Uvezli ste",
    youSuccessfullyRedeemedReward: "Uspješno ste iskoristili svoju nagradu!",
    sixDigitCode: "Šesteroznamenkasti kod",
    pleaseEnterCustomerRewardCode: "Molimo unesite kôd nagrade klijenta",
    enterRewardCode:
      "Unesite kôd nagrade. (Kôd nagrade razlikuje velika i mala slova)",
    selectLoyaltyProgram: "Odaberite program vjernosti",
    stamps: "Marke",
    smsUnitsLow: "SMS jedinice niske",
    pleaseTopUpSmsUnits: "Dopunite SMS jedinice",
    smsSuccessfullySentForDelivery: "SMS uspješno poslan za isporuku!",
    sendSmsTo: "Pošaljite SMS na",
    allCustomers: "Svi kupci",
    unitsAvailable: "Dostupne jedinice",
    pleaseTypeInTheMessage: "Molimo upišite poruku",
    message: "Poruka",
    charactersRemaining: "preostalih znakova",
    avoidUseOfSpecialCharacters:
      "Izbjegavajte upotrebu posebnih znakova i emotikona za očuvanje SMS jedinica.",
    note: "Bilješka",
    errorFetchingCustomersMultilevelDetail:
      "Pogreška pri dohvaćanju pojedinosti o više razina kupaca",
    search: "traži",
    reset: "Poništi",
    importCustomer: "Uvezi kupca",
    addNewCustomer: "Dodajte novog kupca",
    sendSmsBroadcast: "Slanje SMS prijenosa",
    totalCustomers: "UKUPNO KUPCI",
    disbursementDetails: "Pojedinosti o isplati",
    paidBy: "Platio By",
    disbursed: "Isplaćeno",
    bankAccountName: "Naziv bankovnog računa",
    bankAccountNumber: "Broj bankovnog računa",
    transferInitiated: "Prijenos započet",
    transferCompleted: "Prijenos dovršen",
    pleaseEnterAValid: "Unesite valjano",
    begin: "početi",
    end: "kraj",
    date: "datum",
    paymentDate: "Datum plačanja",
    selectDisbursementDuration: "Odaberite Trajanje isplate",
    totalSettled: "Ukupno riješeno",
    totalUnsettled: "Ukupno Neriješeno",
    toDeleteThisSale: "za brisanje ove prodaje",
    draftSaleDeletedSuccessfully: "Skica prodaje uspješno je izbrisana!",
    deleteSale: "Izbriši rasprodaju",
    pleaseTypeInYourTotal: "Unesite ukupni zbroj za potvrdu brisanja",
    billDetails: "Pojedinosti o računu",
    printBill: "Ispis računa",
    servedBy: "Poslužuje",
    total: "Ukupno",
    createdDate: "Datum kreiranja",
    createdTime: "Stvoreno vrijeme",
    status: "Status",
    loginSuccessful: "Prijava uspješna",
    pleaseWaitWhileWeConnectAccount: "Pričekajte dok ne povežemo vaš račun",
    connectionFailedTryAgain: "Veza nije uspjela. Molim te pokušaj ponovno.",
    connectionSuccessful: "Povezivanje uspješno",
    viewDetails: "Pregledavati pojedinosti",
    enable: "Omogućiti",
    free: "Besplatno",
    cost: "Trošak",
    visitWebsite: "Posjetite web stranicu",
    pleaseUpgradeYourPlanToPro:
      "Nadogradite svoj plan na Pro Plus da biste omogućili ovu aplikaciju",
    connectYourBankAccount:
      "Povežite svoj bankovni račun da biste mogli primati plaćanja.",
    disable: "Onemogući",
    enableApp: "Omogući aplikaciju",
    addNewProductToInvoice: "Dodajte novi proizvod u fakturu",
    toDeleteThisInvoice: "za brisanje ove Fakture",
    invoiceDeletedSuccessfully: "Račun je uspješno izbrisan!",
    deleteInvoice: "Izbrišite fakturu",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Uspješno ste dodali poruku o plaćanju fakture!",
    pleaseEnterPaymentInstructions: "Molimo upišite upute za plaćanje",
    invoiceId: "ID fakture",
    paidDate: "Datum plaćanja",
    reference: "Referenca",
    productAdded: "Proizvod dodan",
    productOutOfStock: "Proizvod nema na skladištu. Molimo zalihe ponovo.",
    totalInvoices: "UKUPNO RAČUNI",
    totalPaidInvoices: "UKUPNO PLAĆENI RAČUNI",
    totalUnpaidInvoices: "UKUPNO NEPLAĆENI RAČUNI",
    loyaltyProgramDeleted: "Program vjernosti izbrisan",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ovaj program vjernosti uspješno je izbrisan",
    thisLoyaltyProgramEditedSuccessfully:
      "Ovaj program vjernosti uspješno je uređen",
    loyaltyProgramAddedSuccessfully: "Program vjernosti uspješno je dodan",
    loyaltyProgramEdited: "Program vjernosti uređen",
    loyaltyProgramAdded: "Dodan program vjernosti",
    loyaltyDetails: "Detalji o vjernosti",
    doYouWantToCloseDialog: "Želite li zatvoriti ovaj dijalog?",
    pleaseEnterLoyaltyName: "Molimo unesite naziv svoje vjernosti",
    programType: "Vrsta programa",
    pleaseSelectProgramType: "Odaberite vrstu programa",
    simplePoints: "Jednostavne točke",
    stampsProgram: "Program za marke",
    threshold: "Prag",
    pleaseEnterLoyaltyThreshold: "Unesite prag lojalnosti",
    reward: "Nagrada",
    pleaseEnterLoyaltyReward: "Molimo unesite nagradu vjernosti",
    totalUserPoints: "Ukupno korisničkih bodova",
    totalUserStamps: "Ukupno korisničkih pečata",
    spendingTarget: "Cilj potrošnje",
    spendingTargetHint1:
      "Cilj potrošnje je koliko kupac mora potrošiti da bi zaradio nagradu. 1 vrijednost valute = 1 bod.",
    spendingTargetHint2:
      "Cilj marke je koliko markica kupac mora prikupiti da bi zaradio nagradu. npr. 5",
    addNewLoyaltyProgram: "Dodajte novi program vjernosti",
    addLoyaltyProgram: "Dodajte program vjernosti",
    loyaltyProgramType: "Vrsta programa vjernosti",
    pleaseSelectLoyaltyProgramType: "Odaberite vrstu programa vjernosti",
    nameOfProgram: "Naziv programa",
    pleaseEnterProgramName: "Molimo unesite naziv programa",
    whatIsTheReward: "Koja je nagrada?",
    egNextPurchaseIsFree: "Npr. Sljedeća kupovina je besplatna",
    customerName: "Ime kupca",
    guestCustomer: "Gost kupac",
    orderConfirmedSuccessfully: "Narudžba je uspješno potvrđena",
    orderCancelledSuccessfully: "Narudžba je uspješno otkazana",
    confirmOrder: "Potvrda narudžbe",
    cancelOrder: "Otkazati narudžbu",
    allOrders: "Sve narudžbe",
    totalOrders: "UKUPNO NARUDŽBE",
    doYouWantToAcceptOrder: "Želite li prihvatiti ovu narudžbu?",
    doYouWantToCancelOrder: "Želite li otkazati ovu narudžbu?",
    orderDetails: "Detalji narudžbe",
    orderCreatedAt: "Narudžba izrađena u",
    supplier: "Dobavljač",
    productName: "ime proizvoda",
    quantity: "Količina",
    unitPrice: "Jedinična cijena",
    receivedBy: "Primljeno od",
    reportFrom: "Izvješće od",
    totalSupplies: "Ukupne zalihe",
    allRightsReserved: "Sva prava pridržana",
    toDeleteThisTransaction: "za brisanje ove transakcije",
    transactionDeletedSuccessfully:
      "Transakcija je uspješno izbrisana. Zalihe su vraćene na zalihe.",
    deleteTransaction: "Izbriši transakciju",
    transactionDetails: "detalji transakcije",
    printReceipt: "Ispis potvrde",
    channel: "Kanal",
    discount: "Popust",
    profit: "Dobit",
    discountedSales: "Prodaja s popustom",
    errorFetchingRecord: "Pogreška pri preuzimanju zapisa",
    exportTransactions: "Izvozne transakcije",
    errorFetchingSalesRecord:
      "Pogreška pri dohvaćanju prodajnog zapisa za izvoz.",
    totalSellingPrice: "Ukupna prodajna cijena",
    totalCostPrice: "Ukupna cijena",
    appliedDiscount: "Primijenjeni popust",
    noOfItems: "Broj stavki",
    sales: "Prodajni",
    export: "Izvoz",
    totalProfit: "Ukupna dobit",
    totalBalanceInPeriod: "Ukupno stanje u razdoblju",
    allTimeSales: "Svevremenska prodaja",
    records: "Zapisi",
    todaysSales: "Današnja prodaja",
    transaction: "transakcija",
    youHaveExceededTotalNumberOfProducts:
      "Premašili ste ukupni broj proizvoda dopuštenih u vašem planu. Nadogradite svoj plan kako biste uživali u većoj granici.",
    youNeedToHaveLoyaltyProgram:
      "Za korištenje ove značajke morate imati program vjernosti!",
    price: "Cijena",
    category: "Kategorija",
    stockTracking: "Praćenje dionica",
    stockCount: "Brojanje dionica",
    taxed: "Oporezivo",
    originalPrice: "Originalna cijena",
    costPrice: "Cijena",
    unit: "Jedinica",
    productImage: "Slika proizvoda",
    taxRate: "Porezna stopa",
    taxType: "Vrsta poreza",
    trackInventory: "Oglasni prostor za praćenje",
    variants: "Varijante",
    hasVariants: "Ima varijante",
    importProduct: "Uvoz proizvoda",
    exportProducts: "Izvozni proizvodi",
    addNewProduct: "Dodajte novi proizvod",
    viewCategory: "Pregled kategorije",
    viewSuppliers: "Pogledajte dobavljače",
    receiveInventory: "Primi inventar",
    printAllProductsTag: "Ispišite oznaku svih proizvoda",
    deleteAll: "Izbrisati sve",
    totalProducts: "UKUPNI PROIZVODI",
    youveSuccessfullyAddedANewCategory: "Uspješno ste dodali novu kategoriju",
    addNewCategory: "Dodaj novu kategoriju",
    addCategory: "Dodaj kategoriju",
    categoryName: "ime kategorije",
    pleaseEnterCategoryName: "Molimo unesite naziv kategorije",
    stampsTarget: "Marke Target",
    sendInventory: "Pošalji oglasni prostor",
    productDetails: "detalji o proizvodu",
    youveSuccessfullyEditedThisCategory: "Uspješno ste uredili ovu kategoriju",
    update: "ažuriranje",
    categoryList: "Popis kategorija",
    categories: "Kategorije",
    enterQuantityToUpdate: "Unesite količinu za ažuriranje",
    inventorySentSuccessfully: "Inventar je uspješno poslan!",
    updateInventory: "Ažuriraj oglasni prostor",
    currentQuantity: "Trenutna količina",
    pleaseEnterQuantityToAdd: "Molimo unesite količinu koju želite dodati",
    pleaseSelectABranch: "Odaberite podružnicu",
    searchForBranch: "Potražite podružnicu",
    youCantSendMoreThanStock:
      "Ne možete poslati više nego što imate na skladištu",
    send: "Poslati",
    pleaseEnterQuantityToSend: "Molimo unesite količinu koju želite poslati",
    productNameIsRequiredOnRow: "naziv proizvoda je obavezan u retku",
    productCategoryIsRequiredOnRow: "kategorija proizvoda je obavezna u retku",
    productPriceIsRequiredOnRow: "cijena proizvoda je potrebna u redu",
    productUnitIsRequiredOnRow: "jedinica proizvoda je potrebna u redu",
    productQuantityIsRequiredOnRow: "količina proizvoda je potrebna u redu",
    productVariantsRequireTracking: "varijante proizvoda zahtijeva praćenje!",
    pleaseAddVariantClickButton:
      "Dodajte varijantu klikom na gumb za dodavanje!",
    totalVariantsMoreThanSelectedQuantity:
      "Ukupne varijante su više od odabrane količine proizvoda. Molimo smanjite količinu varijante",
    productEditedSuccessfully: "Proizvod je uspješno uređen!",
    fileTooLargeLessThan4Mb:
      "Veličina datoteke je prevelika! Veličina datoteke trebala bi biti manja od 4 MB.",
    suchVariantAlreadyExist: "Takve varijante već postoje",
    addVariants: "Dodajte varijante",
    editProduct: "Uredite proizvod",
    pleaseEnterProductName: "Molimo unesite naziv proizvoda",
    pleaseEnterProductPrice: "Molimo unesite cijenu proizvoda",
    pleaseEnterProductOriginalPrice:
      "Molimo unesite originalnu cijenu proizvoda",
    productDescription: "Opis proizvoda",
    selectProductCategory: "Odaberite kategoriju proizvoda",
    pleaseSelectProductCategory: "Odaberite kategoriju proizvoda",
    productCostPrice: "Cijena proizvoda",
    productSellingPrice: "Prodajna cijena proizvoda",
    productOriginalPrice: "Originalna cijena proizvoda",
    maxFileSizeAllowedIs4Mb: "Maks. Dopuštena veličina datoteke je 4 MB",
    productsWithPicturesArePublished:
      "Proizvodi sa slikama objavljuju se na Discoveru radi primanja narudžbi",
    shouldThisProductBeTracked: "Treba li ovaj proizvod pratiti?",
    pleaseSelectStockUnit: "Odaberite jedinicu zalihe",
    stockUnit: "Jedinica za dionice",
    bag: "VREĆA",
    bottle: "BOCA",
    bunch: "MNOGO",
    can: "LIMENKA",
    carton: "KARTON",
    crate: "SANDUK",
    cup: "KUPA",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITAR",
    pack: "PAKET",
    pair: "PAR",
    pieces: "KOMADI",
    plate: "TANJUR",
    tonne: "TONNE (MT)",
    uNIT: "JEDINICA",
    yard: "DVORIŠTE",
    pleaseEnterProductQuantity: "Molimo unesite količinu proizvoda",
    productQuantity: "Količina proizvoda",
    isThisProductTaxed: "Da li se ovaj proizvod oporezuje?",
    selectTaxType: "Odaberite vrstu poreza",
    pleaseSelectTaxType: "Molimo odaberite vrstu poreza",
    progressive: "Progresivno",
    proportional: "Proporcionalan",
    pleaseEnterProductTaxRate: "Unesite stopu poreza na proizvode",
    doesProductHaveVariants: "Ima li ovaj proizvod varijante?",
    type: "Tip",
    value: "Vrijednost",
    pleaseEnterVariantType: "Unesite vrstu varijante",
    pleaseEnterVariantValue: "Unesite vrijednost varijante",
    pleaseEnterVariantPrice: "Molimo unesite cijenu varijante",
    pleaseEnterVariantQuantity: "Molimo unesite količinu varijante",
    productDeletedSuccessfully: "Proizvod je uspješno izbrisan!",
    categoryDeletedSuccessfully: "Kategorija je uspješno izbrisana!",
    toDeleteThisProduct: "za brisanje ovog Proizvoda",
    invalidProductQuantity: "Nevažeća količina proizvoda",
    quantityAddedIsOverStock:
      "Količina koju dodajete je veća od onoga što imate na zalihi.",
    itemInventoryNotTracked: "Inventar stavki se ne prati",
    addBulkQuantity: "Dodajte masovnu količinu",
    enterBulkQuantity: "Unesite masovnu količinu",
    pleaseEnterBulkQuantity: "Unesite masovnu količinu",
    youveSuccessfullyAddedANewProduct: "Uspješno ste dodali novi proizvod!",
    pleaseEnterProductSellingPrice: "Molimo unesite prodajnu cijenu proizvoda",
    doYouWantToTrackProductStock: "Želite li pratiti zalihe proizvoda?",
    sellingPrice: "Prodajna cijena",
    setProductExpiryReminder: "Postavite podsjetnik isteka proizvoda",
    productExpiryDate: "Datum isteka proizvoda",
    startRemindingFrom: "Počni podsjećati od",
    productSuppliesAddedSuccessfully: "Uspješno ste dodali zalihe proizvoda.",
    addProductSupplies: "Dodajte zalihe proizvoda",
    pleaseSelectSupplier: "Odaberite dobavljača",
    nameOfProduct: "Naziv proizvoda",
    pleaseSelectProduct: "Molimo odaberite proizvod",
    productVariant: "Varijanta proizvoda",
    pleaseSelectAVariant: "Odaberite varijantu",
    pleaseEnterUnitPrice: "Molimo unesite jediničnu cijenu",
    businessBranch: "Poslovna podružnica",
    pleaseSelectBranch: "Odaberite podružnicu",
    youveSuccessfullyAddedANewSupplier: "Uspješno ste dodali novog dobavljača",
    addSupplier: "Dodajte dobavljača",
    pleaseEnterSupplierEmail: "Molimo unesite e -adresu dobavljača",
    pleaseAddADescription: "Dodajte opis",
    anErrorOccuredProductsDeleted:
      "Došlo je do pogreške tijekom izvođenja operacije. Imajte na umu da je u tom procesu možda izbrisano nekoliko proizvoda",
    bulkDelete: "Skupno brisanje",
    youAreAboutToDelete: "Spremate se izbrisati",
    product: "Proizvod",
    isDueToRedeem: "treba otkupiti",
    aReward: "nagrada",
    pleaseSelectCustomerToReeemReward:
      "Odaberite klijenta da biste iskoristili nagradu.",
    youHaveNoLoyaltyProgramRunning: "Nemate aktivni program vjernosti",
    customerHhasNoPointsInLoyaltyProgram:
      "Kupac nema bodova u ovom programu vjernosti",
    proceedWithPayment: "Nastaviti s plaćanjem?",
    youAreAboutToPayForTransactionUsingPoints:
      "Planirate platiti transakciju pomoću bodova.",
    customerHas: "Kupac ima",
    worth: "vrijedan",
    andIsNotSufficientToPayForTransaction:
      "i nije dovoljno za plaćanje ove transakcije. Bi li htjeli dodati saldo pomoću drugog načina plaćanja?",
    addCustomerLoyalty: "Dodajte lojalnost kupaca",
    pleaseAddCustomerFirst: "Prvo dodajte ili odaberite kupca.",
    pleaseWaitWhileWeGetReady: "Pričekajte dok se ne spremimo",
    lowStock: "Niske zalihe",
    pleaseEnterAmountTendered: "Molimo unesite ponuđeni iznos",
    areYouSureToBookSaleOffline:
      "Jeste li sigurni da želite rezervirati ovu prodaju offline?",
    saleWouldBeBookedAutomatically:
      "Prodaja bi se automatski knjižila kada uključite internet",
    offlineSalesBookingCancelled: "Otkazana je rezervacija offline prodaje",
    covid19Message:
      "COVID19: Operite ruke sapunom ili dezinficirajte kako biste spriječili širenje. Uvijek ostanite sigurni",
    saleSuccessfullyRecorded: "Prodaja uspješno snimljena!",
    sendReceiptToEmail: "Pošaljite potvrdu na e -poštu",
    sendThankYouSms: "Pošaljite zahvalni SMS",
    sendShippingDetails: "Pošaljite detalje dostave",
    addLoyalty: "Dodajte lojalnost",
    searchCustomerNameOrNumber: "Pretražite ime ili broj korisnika",
    clickTheSearchCustomerBox:
      "Pritisnite Pretraživanje okvira za korisnike i karticu za skeniranje",
    enterProductPrice: "Unesite cijenu proizvoda",
    enterPriceFor: "Unesite cijenu za",
    searchForProduct: "Potražite proizvod",
    all: "svi",
    backToDashboard: "Natrag na nadzornu ploču",
    viewDraftSales: "Pogledajte Nacrt prodaje",
    variantSelected: "odabrana varijanta",
    variant: "varijanta",
    thePreviousVariantSelectionNotAvailable:
      "Prethodni odabir varijante nije dostupan za novu varijantu odabranu na temelju cijene. Promijenite svoj odabir.",
    pleaseSupplyPositiveQuantityNumber: "Navedite pozitivan broj količine",
    lowStockFor: "Niske zalihe za",
    clearVariants: "Jasne varijante",
    pleaseEnterQuantity: "Molimo unesite količinu",
    picture: "Slika",
    redemptionToken: "Token za otkup",
    token: "Znak",
    totalSpent: "Ukupno potrošeno",
    confirmPayment: "Potvrdite plaćanje",
    hasPaymentBeenMade: "Je li uplata uspješno obrađena?",
    enterTransactionReference:
      "Unesite referencu transakcije kojom ste platili",
    pleaseSelectACustomer: "Molimo odaberite kupca!",
    areYouSureToApplyDiscount: "Jeste li sigurni da želite primijeniti popust?",
    addYourBankAccountToEnableUssd:
      "Dodajte svoj bankovni račun da biste omogućili trenutni USSD prijenos putem uPay -a",
    totalAmountToPay: "Ukupan iznos za plaćanje",
    doYouWantToCancelTransaction: "Želite li otkazati ovu transakciju?",
    savePrintBill: "Spremi/Ispiši račun",
    enterAmountCollectedForCustomer: "Unesite prikupljeni iznos za kupca",
    recordSale: "Rekordna prodaja",
    checkOutWith: "Odjavite se sa",
    instantTransfer: "Trenutni prijenos",
    dialTheUSSDCode: "Birajte USSD kod",
    pleaseSelectABank: "Odaberite banku",
    payWithUSSD: "Platite putem USSD -a",
    sendBillTo: " - Pošalji Bill na",
    waitingForUSSDTransfer: "Čeka se USSD prijenos",
    percent: "Postotak",
    applyDiscount: "Primijenite popust",
    thisBillHasBeenSaved: "Ovaj je račun spremljen",
    saveDraft: "Spremiti nacrt",
    pleaseTypeANameToIdentifyCustomer:
      "Molimo upišite ime radi identifikacije kupca",
    paymentDetails: "Pojedinosti o plaćanju",
    basePrice: "Osnovna cijena",
    staff: "Osoblje",
    subTotal: "SubTotal",
    durationMonths: "Trajanje (mjeseci)",
    selectADuration: "Odaberite trajanje",
    oneMonth: "1 mjesec",
    twoMonths: "2 mjeseca",
    threeMonths: "3 mjeseca",
    sixMonths: "6 mjeseci",
    twelveMonths: "12 mjeseci",
    eighteenMonths: "18 mjeseci",
    twentyFourMonths: "24 mjeseca",
    twoMonthsFree: "(2 mjeseca besplatno)",
    threeMonthsFree: "(3 mjeseca besplatno)",
    fiveMonthsFree: "(5 mjeseci besplatno)",
    yourAccountHasBeen: "Vaš račun je bio",
    renewed: "obnovljeno",
    upgraded: "nadograđena",
    successfully: "uspješno",
    yourSubscription: "Vaša pretplata",
    youAreCurrentlyEnrolledOnA: "Trenutno ste upisani na a",
    pleaseChooseAPaymentOption: "Odaberite opciju plaćanja",
    planRenewal: "Planirajte obnovu",
    planUpgrade: "Planirajte nadogradnju",
    pleaseSelectDurationToPayFor:
      "Molimo odaberite Trajanje za koje namjeravate platiti",
    staffAccounts: "Računi osoblja",
    ecommerce: "E -trgovina",
    pleaseSelectAPlan: "Odaberite plan",
    includeAddons: "Uključite dodatke",
    viewTransactions: "Prikaz transakcija",
    customerHasNoCompletedTansactions: "Kupac još nije dovršio pregovaranje",
    branch: "Podružnica",
    enterNumberOfEcommerceBranches: "Unesite broj podružnica e -trgovine",
    enterNumberOfEcommerceBranchesToPay:
      "Unesite broj podružnica e -trgovine za koje namjeravate platiti",
    ecommerceIntegration: "Integracija e -trgovine",
    enterNumberOfBranches: "Unesite broj grana",
    enterNumberOfAdditionalBranchesToPay:
      "Unesite broj dodatnih poslovnica koje namjeravate platiti",
    enterNumberOfStaffs: "Unesite broj osoblja",
    enterNumberOfStaffsToPayFor:
      "Unesite broj osoblja koje namjeravate platiti",
    discountApplies: "Primjenjuje se popust",
    starsOnThe: "zvijezde na",
    offer: "ponuda",
    get: "Dobiti",
    moreStarsToRedeem: "više zvijezda za otkup",
    taxVat: "Porez (PDV)",
    callCashierToCompletePayment:
      "Nazovite blagajnika kako biste dovršili plaćanje",
    receipt: "Priznanica",
    dear: "Poštovani",
    thankYouForYourOrderFindGoods:
      "Hvala na Vašoj Narudžbi. U skladu s dogovorom pronađite sljedeću isporučenu robu.",
    shippingNote: "Napomena o otpremi",
    enterShippingNote: "Unesite napomenu o otpremi",
    shippingAddress: "Dostavna adresa",
    enterShippingAddress: "Unesite adresu za dostavu",
    wellDoneYouAreDueToRedeem: "Dobro napravljeno! Trebate otkupiti",
    toGetYourRewardNextVisit:
      "kako biste dobili nagradu prilikom sljedećeg posjeta. Hvala vam",
    pointsOnThe: "Bodovi na",
    morePointsToRedeem: "više bodova za otkup",
    showCode: "Prikaži kôd",
    toGetYourRewardOnNextVisit:
      "kako biste dobili nagradu prilikom sljedećeg posjeta. Hvala vam",
    earn: "Zaraditi",
    delivaryNote: "Dostavnica",
    draftSales: "Nacrt prodaje",
    startDate: "Početni datum",
    endDate: "Datum završetka",
    orders: "Narudžbe",
    checkout: "provjeri",
    noProductItem: "Nema stavke proizvoda",
    printProductTag: "Ispis oznake proizvoda",
    transactionReference: "Referenca transakcije",
    Cashier: "Blagajnik",
    Manager: "Menadžer",
    Owner: "Vlasnik",
    Admin: "Admin",
    addPartners: "Dodajte partnere",
    addNewLoyaltyPartner: "Dodajte novog partnera vjernosti",
    pleaseEnterCompanyName: "Unesite naziv tvrtke",
    companyName: "Naziv tvrtke",
    pleaseEnterCompanyRepName: "Unesite ime predstavnika tvrtke",
    companyRepName: "Ime predstavnika tvrtke",
    pleaseEnterCompanyRepEmail: "Molimo unesite e-mail predstavnika tvrtke",
    companyRepEmail: "E-mail predstavnika tvrtke",
    pleaseEnterCompanyRepPhone: "Unesite telefonski broj predstavnika tvrtke",
    companyRepPhone: "Broj telefona predstavnika tvrtke",
    addReward: "Dodajte nagradu",
    pleaseEnterRewardName: "Unesite naziv nagrade",
    rewardName: "Naziv nagrade",
    rewardQuantity: "Količina nagrade",
    rewardDescription: "Opis nagrade",
    rewardType: "Vrsta nagrade",
    pleaseEnterRewardType: "Unesite vrstu nagrade",
    pleaseEnterRewardQuantity: "Molimo unesite količinu nagrade",
    pleaseEnterRewardDescription: "Unesite opis nagrade",
    fineDining: "Otmjena večera",
    luxuryFashion: "Luksuzna moda",
    hotels: "Hoteli",
    travel: "Putovati",
    foodAndBeverage: "Hrane i pića",
    fashion: "Moda",
    health: "Zdravlje",
    furniture: "Namještaj",
    entertainment: "Zabava",
    automobile: "Automobil",
    education: "Obrazovanje",
    beautyAndSpa: "Ljepota i toplice",
    staycation: "Staycation",
    events: "Događaji",
    trips: "Izleti",
    oilAndGas: "Nafta i plin",
    laundry: "Praonica",
    partnerCategory: "Kategorija partnera",
    freeItem: "Besplatna stavka",
  },
  Afrikaans: {
    cashier: "kassier",
    manager: "bestuurder",
    owner: "Eienaar",
    online: "aanlyn",
    offline: "vanlyn",
    changePassword: "Verander wagwoord",
    currentPasswordMessage: "Voer u huidige wagwoord in",
    passwordMessage: "Tik asseblief jou wagwoord",
    currentPassword: "huidige Sleutelwoord",
    password: "wagwoord",
    confirmPasswordMessage: "Bevestig asseblief u wagwoord!",
    confirmPassword: "Bevestig Wagwoord",
    sendViaEmail: "Stuur via e -pos",
    sendViaWhatsapp: "Stuur via WhatsApp",
    downloadPdf: "Laai PDF af",
    paid: "betaal",
    unpaid: "onbetaal",
    partial: "gedeeltelik",
    closeInvoice: "Wil u die faktuur sluit?",
    closeInvoiceConfirmation: "Faktuur mag nie gestoor word nie. Wil jy sluit?",
    yes: "ja",
    no: "geen",
    invoice: "Faktuur",
    wasDeducted: "is afgetrek",
    for: "vir",
    item: "Item",
    addProduct: "Voeg produk by",
    paymentReference: "Betaalverwysing",
    amountPaid: "Bedrag betaal",
    discountAmount: "Afslagbedrag",
    amountDue: "Bedrag verskuldig",
    amount: "Bedrag",
    dueDate: "Sperdatum",
    paymentType: "Tipe van betaling",
    card: "Kaart",
    cash: "Kontant",
    bankTransfer: "Bankoordrag",
    paymentMessage: "Betalingsboodskap",
    description: "Beskrywing",
    sendReceipt: "Stuur kwitansie",
    delete: "Vee uit",
    save: "Stoor",
    resend: "Stuur weer",
    saveAndSend: "Stoor",
    invoiceSaved: "Faktuur gestoor!",
    selectPaymentMethod: "Kies 'n betaalmetode!",
    selectCustomer: "Kies 'n kliënt!",
    cartEmptyError:
      "Karlys kan nie leeg wees nie, maak die faktuur toe en voeg die item by die mandjie!",
    subscriptionExpired:
      "U intekening het verstryk en u rekening is nou beperk. Klik op die knoppie hieronder om u rekening te hernu",
    renew: "Hernu",
    holdOn: "Hou vas",
    customerBank: "Klantebank",
    cancel: "Kanselleer",
    selectACustomer: "Kies kliënt",
    invoiceSuccessfulPdfError:
      "Faktuur is suksesvol geskep, maar PDF -generasie neem langer as gewoonlik. Gaan kyk gou weer.",
    downloadingInvoice: "Aflaai faktuur",
    downloadingReceipt: "Laai kwitansie af",
    whatsappReceiptError:
      "Kon nie die kwitansie per WhatsApp stuur nie. Probeer asseblief weer.",
    receiptToWhatsapp: "Kwitansie gestuur na WhatsApp",
    thankYouForPatronage: "Dankie vir u beskerming",
    hereIsYourReceipt: "Hier is u betalingsbewys",
    errorSendingEmailReceipt:
      "Kon nie die kwitansie per e-pos stuur nie, probeer weer of kontak die ondersteuning",
    receiptSentToEmail: "Kwitansie is na die kliënt se e -pos gestuur",
    invoiceSentToEmail: "Faktuur is na die kliënt se e -pos gestuur",
    invoiceSuccessWhatsappError:
      "Faktuur is suksesvol geskep, maar daar was 'n fout met die stuur na WhatsApp. Probeer weer op die faktuurlys",
    invoiceSuccessfulEmailError:
      "Faktuur is suksesvol geskep, maar daar was 'n fout tydens die stuur as e-pos. Probeer asseblief weer uit die faktuurlys",
    invoiceSentToWhatsapp: "Faktuur gestuur na WhatsApp",
    hello: "Hallo",
    pleaseDownloadInvoice: "Laai die faktuur af",
    pleaseDownloadReceipt: "Laai die kwitansie af",
    from: "van",
    email: "E -pos",
    upgrade: "Opgradeer",
    youAreOnFreePlan: "U het 'n gratis plan.",
    clickOn: "Klik op",
    yourPlanInFewSteps: " u intekening in 'n paar vinnige stappe.",
    to: "aan",
    yourSubscriptionHasExpired:
      "U intekening het verstryk en u rekening is nou beperk.",
    days: "dae",
    yourSubscriptionExpiresIn: "U Loystar -intekening verval oor",
    createAcount: "Maak 'n rekening oop",
    signIn: "Meld aan",
    continue: "Aanhou",
    enterOtp: "Voer OTP -PIN in",
    enterValidOtp: "Voer asseblief 'n geldige PIN in",
    pin: "SPELD",
    tokenSentToMail: "'N Teken is na u e -pos gestuur",
    passwordResetSuccessful: "Die herstel van die wagwoord was suksesvol",
    somethingWentWrong: "Iets het verkeerd geloop!",
    resetPassword: "Herstel wagwoord",
    iHaveResetCode: "Ek het 'n wagwoordterugstellingskode",
    pleaseEnterEmail: "Voer u e -posadres in",
    aTokenWillBeSentToEmail: "'N Teken sal na u e -pos gestuur word",
    enterEmail: "Gee jou e-pos",
    sendinYourToken: "Stuur jou teken ...",
    makeSureItMatchesPassword: "Maak seker dat dit by u nuwe wagwoord pas",
    pleaseChooseNewPassword: "Kies 'n nuwe wagwoord",
    chooseNewPassword: "Kies 'n nuwe wagwoord",
    enterNewPassword: "Voer u nuwe wagwoord in om te bevestig",
    enterTokenSent: "Voer die teken in wat u e -pos gestuur het",
    resetToken: "Stel teken terug",
    resettingPassword: "Stel jou wagwoord terug…",
    signUp: "Teken aan",
    adminSignInWithEmail:
      " Admin teken aan met e -pos terwyl personeel aanmeld met gebruikersnaam.",
    pleaseEnterEmailOrUsername: "Voer u e -posadres of gebruikersnaam in",
    emailOrUsername: "E-pos of gebruikersnaam",
    pleaseEnterPassword: "Voer asseblief wagwoord in",
    createAnAccount: "Maak 'n rekening oop",
    forgotPassword: "Wagwoord vergeet?",
    enterPhoneNumber: "Voer telefoonnommer in",
    personalData: "Persoonlike data",
    validateConfirmationCOde: "Bevestig die bevestigingskode",
    pleaseEnterFirstName: "Voer asseblief u voornaam in",
    pleaseEnterPhoneNumber: "Voer asseblief u telefoonnommer in",
    pleaseEnterLastName: "Voer asseblief u van in",
    pleaseEnterBusinessName: "Voer u besigheidsnaam in",
    firstName: "Eerste naam",
    lastName: "Van",
    businessName: "besigheids naam",
    previous: "Vorige",
    next: "Volgende",
    pleaseSelectBusinessCategory: "Kies u besigheidskategorie",
    pleaseEnterValidEmail: "Verskaf asseblief 'n geldige e-pos adres",
    pleaseEnterPostCode: "Voer die poskode in",
    postCode: "Poskode",
    phoneNumberInUse: "Hierdie telefoonnommer is reeds in gebruik!",
    emailInUse: "Hierdie e -pos is reeds in gebruik!",
    foodAndDrinks: "Kos en drankies",
    salonAndBeauty: "Salon en skoonheid",
    fashionAndAccessories: "Mode en bykomstighede",
    gymAndFitness: "Gimnasium en fiksheid",
    travelAndHotel: "Reis en hotel",
    homeAndGifts: "Huis en geskenke",
    washingAndCleaning: "Was en skoonmaak",
    gadgetsAndElectronics: "Gadgets en elektronika",
    groceries: "Kruideniersware",
    others: "Ander",
    submit: "Indien",
    accountCreatedSuccessful: "Jou rekening is suksesvol geskep.",
    pleaseEnterAddress: "Voer asseblief u adres in",
    addressLine1: "Adres Lyn 1",
    addressLine2: "Adreslyn 2",
    choosePassword: "Kies wagwoord",
    passwordMustBe6Characters:
      "Die wagwoord moet ten minste 6 karakters bevat.",
    howDidYouHearLoystar: "Hoe het jy van Loystar gehoor?",
    referralCode: "verwysingskode",
    byClickingTheButton:
      " Deur op die onderstaande knoppie te klik, stem u in tot die",
    termsAndSevice: "Terme",
    wereCreatingAccount: "Ons skep u rekening",
    proceed: "Voortgaan",
    verificationCodeMustBe6: "Die verifikasiekode moet 6 syfers hê",
    pleaseEnter6DigitCode: "Voer die 6 -syfer -kode in",
    enterVerificationCode: "Tik verifikasiekode",
    resendToken: "Herstuur teken",
    verify: "Verifieer",
    transactions: "Transaksies",
    todaySales: "Vandag se verkope",
    salesHistory: "Verkoopsgeskiedenis",
    supplyHistory: "Voorsieningsgeskiedenis",
    new: "Nuut",
    invoices: "Fakture",
    disbursements: "Uitbetalings",
    offlineSales: "Vanlyn verkope",
    products: "Produkte",
    customers: "Kliënte",
    multiLevelLoyalty: "Lojaliteit op meer vlakke",
    loyaltyPrograms: "Lojaliteitsprogramme",
    members: "Lede",
    appStore: "App Store",
    orderMenu: "Bestel spyskaart",
    settings: "Instellings",
    staffAndBranches: "Personeel en takke",
    myAccount: "My rekening",
    switchToSellMode: "Skakel oor na die verkoopmodus",
    signOut: "Teken uit",
    getFreeSubscription: "Kry gratis intekening",
    onlyNumbersAllowed: "Slegs getalle word toegelaat",
    yourAccountMustBe10Digits: "u rekeningnommer moet 'n 10 -syfernommer wees",
    yourBvnMustBe11: "jou BVN moet 'n 11 -syfer nommer wees",
    pleaseSelectBank: "Kies u bank",
    selectYourBank: "Kies u bank",
    enterBankAccountNumber: "Voer bankrekeningnommer in",
    enterBvnNumber: "Voer u BVN in",
    connectBankAccount: "Koppel bankrekening",
    passwordResetSuccessfulAndSignOut:
      "U wagwoord is suksesvol teruggestel. Klik op die afmeldknoppie hieronder om weer aan te meld",
    enterCurrentPassword: "Voer die huidige wagwoord in",
    pleaseEnterCurrentPassword: "Voer die huidige wagwoord in",
    phoneNumber: "Telefoon nommer",
    sex: "Seks",
    dateOfBirth: "Geboortedatum",
    state: "Staat",
    country: "Land",
    loyaltyId: "Lojaliteit ID",
    createdAt: "Geskep by",
    noOfTransactionsMade: "Aantal transaksies gemaak",
    yourDownloadWillStart: "U aflaai sal binne 'n oomblik begin",
    exportCustomers: "Klante uitvoer",
    youhaveSuccessfullyToppedUp: "U het u SMS -eenhede suksesvol aangevul.",
    youNowHave: "Jy het nou",
    smsUnits: "SMS -eenhede",
    enterNumberOfSmsUnits: "Voer die aantal SMS -eenhede in wat u wil koop",
    pleaseEnterANumericValue: "Voer 'n numeriese waarde in",
    pay: "Betaal",
    accountEditedSuccessfully: "Jou rekening is suksesvol geredigeer.",
    youAeCurrentlyOn: "Jy is tans aan",
    plan: "Beplan",
    userData: "Gebruikersdata",
    businessData: "BESIGHEIDSDATA",
    businessCategory: "Besigheid Catergory",
    pleaseSelectCurrency: "Kies u geldeenheid",
    selectYourCurrency: "Kies u geldeenheid",
    purchaseMoreSmsUnits:
      "Koop meer SMS -eenhede deur die onderstaande vorm te gebruik",
    youHave: "Jy het",
    atLeast4SmsUnitsRrequired:
      "Minstens 4 sms -eenhede word benodig vir verifikasie.",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Verifieer u bankrekening om aan die vereistes 'Ken u kliënt' (KYC) te voldoen. Hiermee kan u betalings via USSD of onmiddellike oordrag invorder, bestellings van kliënte ontvang en transaksieoplossing verwerk. Slegs van toepassing op Nigeriese handelaars. Druk die onderstaande knoppie om te begin.",
    reConnectBankAccount: "Koppel die bankrekening weer aan",
    accountName: "Rekeningnaam",
    accountNumber: "Rekening nommer",
    bankName: "Bank Naam",
    verified: "Geverifieer",
    accountDetails: "Rekeningbesonderhede",
    kycBankAccount: "KYC",
    createTier: "Skep vlak",
    fileUploadSuccessful: "lêer suksesvol opgelaai",
    fileUploadFailed: "lêeroplaai kon nie",
    createLoyaltyProgram: "Skep 'n lojaliteitsprogram",
    createLoyalty: "Skep lojaliteit",
    name: "Naam",
    loyaltyArtwork: "Lojaliteitskunswerke",
    clickToUpload: "Klik om op te laai",
    amountToPointsRatio: "Bedrag in punteverhouding",
    points: "Punte",
    recommendedAmountToPointRatio:
      "Aanbeveel: ₦ 1 tot 1 punt. dit wil sê, vir elke 1 Naira wat u spandeer, kry u kliënte 1 punt",
    pleaseTypeIn: "Tik asb in",
    toDeleteLoyalty: "om hierdie lojaliteit te verwyder",
    deleteLoyalty: "Verwyder lojaliteit",
    toConfirm: "om te bevestig",
    edit: "Redigeer",
    pointsAwardedSuccessfully: "Punte Suksesvol toegeken.",
    enterPointValueToAward: "Voer puntwaarde in wat u aan kliënte wil toeken",
    award: "Toekenning",
    awardPointValuesToCustomer: "Toekenningspuntwaarde aan kliënt",
    enrollMembersToLoyalty: "Skryf lede in op lojaliteit",
    awardPoints: "Toekenningspunte",
    enroll: "Inskryf",
    home: "Tuis",
    loyalty: "Lojaliteit",
    enrollCustomers: "Skryf kliënte in",
    selected: "Gekies",
    customer: "Kliënt",
    loyaltyActivationSuccessful: "Lojaliteitaktivering is suksesvol.",
    loyaltyDeactivationSuccessful: "Lojaliteit Deaktivering is suksesvol.",
    viewTier: "Bekyk Tier",
    deactivate: "De-aktiveer",
    activate: "Aktiveer",
    actions: "Aksies",
    nameOfLoyalty: "Naam van lojaliteit",
    loyaltyStatus: "Lojaliteitstatus Lojaliteitstatus",
    numberOfTiers: "Aantal vlakke",
    createdOn: "Geskep op",
    createATier: "Skep 'n vlak",
    stopCreatingTierConfirmation: "Wil u ophou om 'n vlak te skep?",
    stopEditingTierConfirmation: "Wil u ophou om hierdie vlak te redigeer?",
    nameOfTier: "Naam van Tier",
    minimumSpendingTarget: "Minimum bestedingsdoelwit",
    maximumSpendingTarget: "Maksimum bestedingsdoelwit",
    minimumSpendingTargetRequired: "minimum bestedingsdoelwit word vereis",
    maximumSpendingTargetRequired: "maksimum bestedingsdoelwit word vereis",
    rewardSponsor: "Beloning Borg",
    pleaseChooseARewardSponsor: "Kies 'n beloningborg",
    self: "Self",
    partner: "Vennoot",
    rewardPartner: "Beloning vennoot",
    pleaseSelectRewardPartner: "Kies u beloningsvennoot",
    rewards: "Belonings",
    pleaseSelectAReward: "Kies 'n beloning",
    instructionsOnRedeemingReward:
      "Instruksies oor hoe die kliënt beloning moet inbetaal",
    pleaseFillInThisField: "Vul asseblief hierdie veld in!",
    toDeleteThisTier: " om hierdie vlak te verwyder",
    deleteTier: "Vee Tier uit",
    viewMembers: "Bekyk lede",
    membersEnrolled: "Lede ingeskryf",
    instruction: "Instruksie",
    membersIn: "Lede in",
    availableTiersInLoyalty: "Beskikbare vlakke in lojaliteitsprogramme",
    tiers: "Vlakke",
    totalTier: "TOTALE TIER",
    availableLoyaltyProgramme: "Lojaliteitsprogram beskikbaar",
    totalLoyalties: "TOTALE lojaliteit",
    memberDetails: "Lidbesonderhede",
    nameOfCustomer: "Naam van kliënt",
    address: "Adres",
    allEnrolledMembers: "Alle ingeskrewe lede",
    thisIsToWishYouHappyBirthday:
      "Dit is 'n baie gelukkige verjaardag en 'n voorspoedige lewe. Dankie vir alles wat u vir Loyster is. Gelukkige vieringe!",
    inputAnOfferPlease: "Gee 'n aanbod asb",
    pleaseSelectTheInsertPoint:
      "Kies die invoegpunt in die boodskap en klik weer",
    birthdayOfferAndMessage: "Verjaarsdagaanbod en boodskap",
    birthdayOffer: "Verjaarsdagaanbieding",
    birthdayMessage: "Verjaarsdag Boodskap",
    noOfferFound: "Geen aanbod gevind nie",
    settingABirthdayOffer:
      "Deur 'n verjaarsdagaanbieding op te stel, kan kliënte hierdie aanbod per SMS op hul verjaardag ontvang",
    createOffer: "Skep aanbod",
    whatIsTheOffer: "Wat is die aanbod?",
    editMessage: "Wysig boodskap",
    insert: "Voeg in",
    theNameOfCustomerInserted: "Die naam van die kliënt sal hier ingevoeg word",
    theBirtdayOfferInserted: "Die verjaardagaanbod sal hier ingevoeg word",
    youSuccessfullyAddedNewBranch: "Jy het 'n nuwe tak suksesvol bygevoeg!",
    addNewBranch: "Voeg nuwe tak by",
    addBranch: "Voeg tak by",
    additionalBranchWillIncur: "Bykomende tak sal aangaan",
    perBranch: "per tak",
    ecommerceBranchCosts: "Koste vir e -handel Tak",
    pleaseEnterBranchName: "Voer die taknaam in",
    pleaseEnterBranchAddress1: "Voer die tak se adresreël 1 in",
    enterBranchAddress1: "Voer die tak se adresreël 1 in",
    enterBranchAddress2: "Voer die tak se adresreël 1 in",
    pleaseEnterBranchAddress2: "Voer die tak se adresreël 2 in",
    enterBranchName: "Voer die taknaam in",
    successfullyAddedStaff: "U het 'n nuwe personeel suksesvol bygevoeg!",
    addNewStaff: "Voeg nuwe personeel by",
    addStaff: "Voeg personeel by",
    additionalStaffWillIncur: "Bykomende personeel sal aangaan",
    perStaff: "per personeel.",
    pleaseEnterStaffEmail: "Voer die personeel se e -posadres in",
    pleaseEnterStaffUsername: "Voer die personeel se gebruikersnaam in",
    pleaseEnterStaffPassword: "Voer die personeel se wagwoord in",
    pleaseSelectStaffRole: "Kies die rol van die personeel",
    selectStaffRole: "Kies die rol van die personeel",
    enterStaffEmail: "Voer die personeel se e -posadres in",
    enterStaffUsername: "Voer die personeel se gebruikersnaam in",
    enterStaffPassword: "Voer die personeel se wagwoord in",
    spacesNotAllowedInUsername:
      "spasies wat nie in gebruikersnaam toegelaat word nie",
    admin: "admin",
    pleaseSelectBusinessToAttachStaff:
      "Kies 'n besigheid om personeel aan te sluit",
    searchForBranchToAttachStaff: "Soek 'n tak om personeel aan te sluit",
    username: "Gebruikersnaam",
    role: "Rol",
    areYouSureToDeleteThis: "Is u seker om dit uit te vee",
    branches: "Takke",
    upgradeYourPlan: "Gradeer u plan op.",
    add: "VOEG TOE",
    addNew: "Voeg nuwe",
    customerWithEmailAlreadyExists:
      "Kliënt met e -pos/telefoonnommer bestaan reeds!",
    successfullyAddedNewCustomer: "U het 'n nuwe kliënt suksesvol bygevoeg!",
    addCustomer: "Voeg kliënt by",
    pleaseEnterCustomerFirstName: "Voer die kliënt se voornaam in",
    pleaseEnterCustomerLastName: "Voer die kliënt se van in",
    pleaseEnterCustomerPhoneNumber: "Voer die kliënt se telefoonnommer in",
    pleaseEnterCustomerEmail: "Voer die kliënt se e -posadres in",
    pleaseEnterCustomerAddressLine: "Voer die adreslyn van die klant in",
    pleaseEnterCustomerOtherAddress: "Voer die ander adres van die kliënt in",
    pleaseSelectCustomerGender: "Kies die geslag van die kliënt",
    gender: "Geslag",
    male: "Manlik",
    female: "Vroulik",
    bank: "Bank",
    selectBank: "Kies Bank",
    stateOrRegionOrProvince: "Staat/streek/provinsie",
    customerNotes: "Klante aantekeninge",
    sendSms: "Stuur SMS",
    editCustomer: "Wysig kliënt",
    redeemReward: "Betaal beloning",
    issueLoyaltyCard: "Maak lojaliteitskaart uit",
    deleteCustomer: "Vee kliënt uit",
    youveSuccessfullyAssignedLoyaltyMembership:
      "U het 'n lojaliteit -lidmaatskap -ID suksesvol toegeken",
    noMembershipIdAvailable:
      "Geen lidmaatskap -ID's beskikbaar nie. Kontak ons asseblief op hello@loystar.co",
    sendEmail: "Stuur e-pos",
    membershipPoints: "Lidmaatskappunte",
    customerDetails: "Kliëntbesonderhede",
    close: "Naby",
    loyaltyBalance: "Lojaliteit Balans",
    pointsBalance: "Puntesaldo",
    starBalance: "Sterbalans",
    requiredPoints: "Vereiste punte",
    requiredStars: "Vereiste sterre",
    reddemCode: "Gebruik kode",
    toDeleteThisCustomer: "hierdie kliënt te verwyder",
    customerHasBeenDeletedSuccessfully: "Kliënt is suksesvol uitgevee!",
    customerWithPhoneAlreadyExists: "Kliënt met telefoonnommer bestaan reeds",
    customerWasSuccessfullyEdited: "Kliënt is suksesvol geredigeer",
    anErrorOccured: "N Fout het voorgekom",
    phoneNumberIncludeCountry: "Telefoonnommer (sluit landkode in)",
    yourFileQueuedForUpload:
      "U lêer is in die tou vir oplaai. Herlaai die bladsy na 'n paar sekondes.",
    thereWasAnErrorPerformingOperation: "Kon nie die operasie uitvoer nie!",
    pleaseSelectFile: "Kies 'n lêer!",
    oopsOnlyCsvFilesAllowed:
      "Oeps! Slegs CSV -lêers word toegelaat. Laai 'n .csv -lêer op.",
    fileShouldBeSmallerThan5Mb:
      "Die lêer moet kleiner as 5 MB wees. Stuur 'n e -pos aan support@loystar.co as u 'n groter lêer het. Dankie",
    customerFirstNameIsRequired: "kliënt se voornaam word op ry vereis",
    customerPhoneNumberIsRequired: "kliënt se telefoonnommer word op ry vereis",
    importCustomers: "Voer klante in",
    upload: "Laai op",
    clickIconToDownloadCsv:
      "Klik op hierdie ikoon om die CSV -lêersjabloon af te laai.",
    getGoogleSheetVersion: "Kry die Google blad weergawe",
    clickOrDragFileToUpload:
      "Klik of sleep die lêer na hierdie area om dit op te laai",
    missingOrInvalidColumnHeader:
      "Ontbrekende of ongeldige kolomopskrif. Volg die sjabloonformaat. Dankie.",
    youHaveImported: "U het ingevoer",
    youSuccessfullyRedeemedReward: "U het u beloning suksesvol afgelos!",
    sixDigitCode: "Sesyfer -kode",
    pleaseEnterCustomerRewardCode:
      "Voer asseblief die kliënt se beloningskode in",
    enterRewardCode:
      "Voer beloningskode in. (Die beloningskode is hooflettergevoelig)",
    selectLoyaltyProgram: "Kies lojaliteitsprogram",
    stamps: "Seëls",
    smsUnitsLow: "SMS -eenhede laag",
    pleaseTopUpSmsUnits: "Vul asseblief SMS -eenhede op",
    smsSuccessfullySentForDelivery: "SMS suksesvol gestuur vir aflewering!",
    sendSmsTo: "Stuur SMS na",
    allCustomers: "Alle kliënte",
    unitsAvailable: "Eenhede beskikbaar",
    pleaseTypeInTheMessage: "Tik asseblief die boodskap in",
    message: "Boodskap",
    charactersRemaining: "karakters oor",
    avoidUseOfSpecialCharacters:
      "Vermy die gebruik van spesiale karakters en emoji's om SMS -eenhede te bewaar.",
    note: "Let op",
    errorFetchingCustomersMultilevelDetail:
      "Kon nie die multi -vlak -besonderhede van kliënte haal nie",
    search: "Soek",
    reset: "Herstel",
    importCustomer: "Voer kliënt in",
    addNewCustomer: "Voeg nuwe kliënt by",
    sendSmsBroadcast: "Stuur SMS -uitsending",
    totalCustomers: "TOTAAL Kliënte",
    disbursementDetails: "Uitbetalingsbesonderhede",
    paidBy: "Betaal deur",
    disbursed: "Uitbetaal",
    bankAccountName: "Bankrekeningnaam",
    bankAccountNumber: "Bankrekeningnommer",
    transferInitiated: "Oordrag begin",
    transferCompleted: "Oordrag voltooi",
    pleaseEnterAValid: "Voer asseblief 'n geldige in",
    begin: "begin",
    end: "einde",
    date: "datum",
    paymentDate: "Betaaldatum",
    selectDisbursementDuration: "Kies Uitbetalingsduur",
    totalSettled: "Totaal afgehandel",
    totalUnsettled: "Totaal ongesetel",
    toDeleteThisSale: "hierdie verkoop te verwyder",
    draftSaleDeletedSuccessfully: "Konsepverkoop is suksesvol uitgevee!",
    deleteSale: "Vee verkoop uit",
    pleaseTypeInYourTotal: "Voer u totaal in om die verwydering te bevestig",
    billDetails: "Bill besonderhede",
    printBill: "Druk Bill",
    servedBy: "Bedien deur",
    total: "Totaal",
    createdDate: "Datum geskep",
    createdTime: "Tyd geskep",
    status: "Status",
    loginSuccessful: "Aanmelding suksesvol",
    pleaseWaitWhileWeConnectAccount:
      "Wag asseblief terwyl ons u rekening koppel",
    connectionFailedTryAgain: "Konneksie misluk. Probeer asseblief weer.",
    connectionSuccessful: "Verbinding suksesvol",
    viewDetails: "Sien besonderhede",
    enable: "Aktiveer",
    free: "Vry",
    cost: "Koste",
    visitWebsite: "Besoek webwerf",
    pleaseUpgradeYourPlanToPro:
      "Gradeer u plan op na Pro Plus om hierdie app moontlik te maak",
    connectYourBankAccount:
      "Koppel u bankrekening om betalings te kan ontvang.",
    disable: "Deaktiveer",
    enableApp: "Aktiveer program",
    addNewProductToInvoice: "Voeg nuwe produk by faktuur",
    toDeleteThisInvoice: "om hierdie faktuur uit te vee",
    invoiceDeletedSuccessfully: "Faktuur is suksesvol uitgevee!",
    deleteInvoice: "Vee faktuur uit",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "U het 'n faktuurbetalingsboodskap suksesvol bygevoeg!",
    pleaseEnterPaymentInstructions: "Voer asseblief betalingsinstruksies in",
    invoiceId: "Faktuur ID",
    paidDate: "Betaalde datum",
    reference: "Verwysing",
    productAdded: "Produk bygevoeg",
    productOutOfStock: "Produk uit voorraad. Hou asseblief weer voorraad.",
    totalInvoices: "TOTAAL FAKTURE",
    totalPaidInvoices: "TOTAAL BETAALDE FAKTURE",
    totalUnpaidInvoices: "TOTAAL onbetaalde fakture",
    loyaltyProgramDeleted: "Lojaliteitsprogram uitgevee",
    thisLoyaltyProgramDeletedSuccessfully:
      "Hierdie lojaliteitsprogram is suksesvol uitgevee",
    thisLoyaltyProgramEditedSuccessfully:
      "Hierdie lojaliteitsprogram is suksesvol geredigeer",
    loyaltyProgramAddedSuccessfully: "Lojaliteitsprogram is suksesvol bygevoeg",
    loyaltyProgramEdited: "Lojaliteitsprogram gewysig",
    loyaltyProgramAdded: "Lojaliteitsprogram bygevoeg",
    loyaltyDetails: "Lojaliteitbesonderhede",
    doYouWantToCloseDialog: "Wil u hierdie dialoog sluit?",
    pleaseEnterLoyaltyName: "Voer die naam van u lojaliteit in",
    programType: "Program tipe",
    pleaseSelectProgramType: "Kies 'n programtipe",
    simplePoints: "Eenvoudige punte",
    stampsProgram: "Seëls Program",
    threshold: "Drempel",
    pleaseEnterLoyaltyThreshold: "Voer asseblief die lojaliteitsdrempel in",
    reward: "Beloning",
    pleaseEnterLoyaltyReward: "Voer die loon van die lojaliteit in",
    totalUserPoints: "Totale gebruikerspunte",
    totalUserStamps: "Totale gebruikersstempels",
    spendingTarget: "Bestedingsteiken",
    spendingTargetHint1:
      "Bestedingsdoelwit is hoeveel 'n kliënt moet bestee om die beloning te verdien. 1 geldeenheidwaarde = 1 punt.",
    spendingTargetHint2:
      "Die doel van seëls is hoeveel seëls 'n kliënt moet insamel om die beloning te verdien. bv. 5",
    addNewLoyaltyProgram: "Voeg nuwe lojaliteitsprogram by",
    addLoyaltyProgram: "Voeg lojaliteitsprogram by",
    loyaltyProgramType: "Lojaliteitsprogram tipe",
    pleaseSelectLoyaltyProgramType: "Kies die tipe lojaliteitsprogram",
    nameOfProgram: "Naam van program",
    pleaseEnterProgramName: "Voer die programnaam in",
    whatIsTheReward: "Wat is die beloning?",
    egNextPurchaseIsFree: "Bv. Die volgende aankoop is gratis",
    customerName: "Kliënt naam",
    guestCustomer: "Gaste Kliënt",
    orderConfirmedSuccessfully: "Bestelling suksesvol bevestig",
    orderCancelledSuccessfully: "Bestelling is suksesvol gekanselleer",
    confirmOrder: "Bevestig bestelling",
    cancelOrder: "Kanselleer bestelling",
    allOrders: "Alle bestellings",
    totalOrders: "TOTALE BESTELLINGS",
    doYouWantToAcceptOrder: "Wil u hierdie bestelling aanvaar?",
    doYouWantToCancelOrder: "Wil u hierdie bestelling kanselleer?",
    orderDetails: "Bestellingsbesonderhede",
    orderCreatedAt: "Bestelling geskep by",
    supplier: "Verskaffer",
    productName: "Produk Naam",
    quantity: "Hoeveelheid",
    unitPrice: "Eenheidsprys",
    receivedBy: "Ontvang deur",
    reportFrom: "Verslag van",
    totalSupplies: "Totale voorraad",
    allRightsReserved: "Alle regte voorbehou",
    toDeleteThisTransaction: "om hierdie transaksie uit te vee",
    transactionDeletedSuccessfully:
      "Die transaksie is suksesvol uitgevee. Voorraad is na voorraad terugbesorg.",
    deleteTransaction: "Vee transaksie uit",
    transactionDetails: "Transaksiebesonderhede",
    printReceipt: "Druk kwitansie",
    channel: "Kanaal",
    discount: "Afslag",
    profit: "Wins",
    discountedSales: "Afslag op afslag",
    errorFetchingRecord: "Kon nie rekord haal nie",
    exportTransactions: "Uitvoer transaksies",
    errorFetchingSalesRecord: "Kon nie verkooprekord vir uitvoer haal nie.",
    totalSellingPrice: "Totale verkoopprys",
    totalCostPrice: "Totale kosprys",
    appliedDiscount: "Toegepaste afslag",
    noOfItems: "Aantal items",
    sales: "Verkope",
    export: "Uitvoer",
    totalProfit: "Totale wins",
    totalBalanceInPeriod: "Totale saldo in tydperk",
    allTimeSales: "Alle tye verkope",
    records: "Rekords",
    todaysSales: "Vandag se verkope",
    transaction: "transaksie",
    youHaveExceededTotalNumberOfProducts:
      "U het die totale aantal produkte wat op u plan toegelaat word, oorskry. Gradeer u plan op om 'n hoër perk te geniet.",
    youNeedToHaveLoyaltyProgram:
      "U moet 'n lojaliteitsprogram hê om hierdie funksie te kan gebruik!",
    price: "Prys",
    category: "Kategorie",
    stockTracking: "Voorraadopsporing",
    stockCount: "Aantal voorraad",
    taxed: "Belas",
    originalPrice: "Oorspronklike prys",
    costPrice: "Kosprys",
    unit: "Eenheid",
    productImage: "Produkbeeld",
    taxRate: "Belastingkoers",
    taxType: "Belastingtipe",
    trackInventory: "Volg voorraad",
    variants: "Variante",
    hasVariants: "Het variante",
    importProduct: "Voer produk in",
    exportProducts: "Produkte uitvoer",
    addNewProduct: "Voeg nuwe produk by",
    viewCategory: "Bekyk kategorie",
    viewSuppliers: "Bekyk verskaffers",
    receiveInventory: "Ontvang voorraad",
    printAllProductsTag: "Druk alle produkte -etiket af",
    deleteAll: "Vee alles uit",
    totalProducts: "TOTAAL PRODUKTE",
    youveSuccessfullyAddedANewCategory:
      "Jy het 'n nuwe kategorie suksesvol bygevoeg",
    addNewCategory: "Voeg nuwe kategorie by",
    addCategory: "Voeg kategorie by",
    categoryName: "Kategorienaam",
    pleaseEnterCategoryName: "Voer asseblief kategorienaam in",
    stampsTarget: "Seëls Doel",
    sendInventory: "Stuur voorraad",
    productDetails: "Produk besonderhede",
    youveSuccessfullyEditedThisCategory:
      "Jy het hierdie kategorie suksesvol geredigeer",
    update: "Opdateer",
    categoryList: "Kategorie lys",
    categories: "Kategorieë",
    enterQuantityToUpdate: "Voer 'n hoeveelheid in om op te dateer",
    inventorySentSuccessfully: "Die voorraad is suksesvol gestuur!",
    updateInventory: "Dateer voorraad op",
    currentQuantity: "Huidige hoeveelheid",
    pleaseEnterQuantityToAdd: "Voer die hoeveelheid in wat u wil byvoeg",
    pleaseSelectABranch: "Kies 'n tak",
    searchForBranch: "Soek vir tak",
    youCantSendMoreThanStock:
      "U kan nie meer stuur as wat u in voorraad het nie",
    send: "Stuur",
    pleaseEnterQuantityToSend: "Voer die hoeveelheid in wat u wil stuur",
    productNameIsRequiredOnRow: "produknaam word op ry vereis",
    productCategoryIsRequiredOnRow: "produkkategorie word op ry vereis",
    productPriceIsRequiredOnRow: "produkprys word op ry vereis",
    productUnitIsRequiredOnRow: "produdeenheid word op ry vereis",
    productQuantityIsRequiredOnRow: "produkhoeveelheid word op ry vereis",
    productVariantsRequireTracking: "produkvariante vereis opsporing!",
    pleaseAddVariantClickButton:
      "Voeg 'n variant by deur op die voeg -knoppie te klik!",
    totalVariantsMoreThanSelectedQuantity:
      "Totale variante is meer as die gekose produkhoeveelheid, pls verminder die hoeveelheid hoeveelheid",
    productEditedSuccessfully: "Die produk is suksesvol geredigeer!",
    fileTooLargeLessThan4Mb:
      "Lêergrootte is te groot! Lêergrootte moet minder as 4MB wees.",
    suchVariantAlreadyExist: "Sulke variant bestaan reeds",
    addVariants: "Voeg variante by",
    editProduct: "Wysig produk",
    pleaseEnterProductName: "Voer die produknaam in",
    pleaseEnterProductPrice: "Voer die produkprys in",
    pleaseEnterProductOriginalPrice:
      "Voer die oorspronklike prys van die produk in",
    productDescription: "Produk Beskrywing",
    selectProductCategory: "Kies produkkategorie",
    pleaseSelectProductCategory: "Kies die produkkategorie",
    productCostPrice: "Produk kosprys",
    productSellingPrice: "Verkoopprys van produkte",
    productOriginalPrice: "Produk oorspronklike prys",
    maxFileSizeAllowedIs4Mb: "Maks. Die toegelate lêergrootte is 4 MB",
    productsWithPicturesArePublished:
      "Produkte met foto's word op Discover gepubliseer om bestellings te ontvang",
    shouldThisProductBeTracked: "Moet hierdie produk dopgehou word?",
    pleaseSelectStockUnit: "Kies asseblief voorraad eenheid",
    stockUnit: "Voorraad Eenheid",
    bag: "TAS",
    bottle: "FLES",
    bunch: "BUNCH",
    can: "KAN",
    carton: "KARTON",
    crate: "KRAT",
    cup: "KOPIE",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKIE",
    pair: "PAAR",
    pieces: "STUKKE",
    plate: "PLAAT",
    tonne: "TONNE (MT)",
    uNIT: "EENHEID",
    yard: "YARD",
    pleaseEnterProductQuantity: "Voer die produkhoeveelheid in",
    productQuantity: "Produk Hoeveelheid",
    isThisProductTaxed: "Word hierdie produk belas?",
    selectTaxType: "Kies Belastingtipe",
    pleaseSelectTaxType: "Kies belastingtipe",
    progressive: "Progressief",
    proportional: "Proporsioneel",
    pleaseEnterProductTaxRate: "Voer die produkbelastingkoers in",
    doesProductHaveVariants: "Het hierdie produk variante?",
    type: "Tik",
    value: "Waarde",
    pleaseEnterVariantType: "Voer die variant se tipe in",
    pleaseEnterVariantValue: "Voer die waarde van die variant in",
    pleaseEnterVariantPrice: "Voer die prys van die variant in",
    pleaseEnterVariantQuantity: "Voer die hoeveelheid van die variant in",
    productDeletedSuccessfully: "Die produk is suksesvol uitgevee!",
    categoryDeletedSuccessfully: "Kategorie is suksesvol uitgevee!",
    toDeleteThisProduct: "om hierdie produk te verwyder",
    invalidProductQuantity: "Ongeldige produkhoeveelheid",
    quantityAddedIsOverStock:
      "Die hoeveelheid wat u byvoeg, is meer as wat u in voorraad het.",
    itemInventoryNotTracked: "Artikelvoorraad word nie opgespoor nie",
    addBulkQuantity: "Voeg grootmaat by",
    enterBulkQuantity: "Voer grootmaathoeveelheid in",
    pleaseEnterBulkQuantity: "Voer 'n groot hoeveelheid in",
    youveSuccessfullyAddedANewProduct:
      "U het 'n nuwe produk suksesvol bygevoeg!",
    pleaseEnterProductSellingPrice: "Voer die verkoopprys van die produk in",
    doYouWantToTrackProductStock: "Wil u die produkvoorraad opspoor?",
    sellingPrice: "Verkoopprys",
    setProductExpiryReminder:
      "Stel herinnering aan verstryking van die produk in",
    productExpiryDate: "Vervaldatum van die produk",
    startRemindingFrom: "Begin herinner aan",
    productSuppliesAddedSuccessfully:
      "U het produkvoorrade suksesvol bygevoeg.",
    addProductSupplies: "Voeg produkbenodighede by",
    pleaseSelectSupplier: "Kies verskaffer",
    nameOfProduct: "Naam van produk",
    pleaseSelectProduct: "Kies die produk",
    productVariant: "Produkvariant",
    pleaseSelectAVariant: "Kies asseblief 'n variant",
    pleaseEnterUnitPrice: "Voer asseblief eenheidsprys in",
    businessBranch: "Besigheidstak",
    pleaseSelectBranch: "Kies 'n tak",
    youveSuccessfullyAddedANewSupplier:
      "U het 'n nuwe verskaffer suksesvol bygevoeg",
    addSupplier: "Voeg verskaffer by",
    pleaseEnterSupplierEmail: "Voer die verskaffer se e -posadres in",
    pleaseAddADescription: "Voeg 'n beskrywing by",
    anErrorOccuredProductsDeleted:
      "Kon nie die operasie uitvoer nie. Let asseblief daarop dat daar min produkte in die proses verwyder is",
    bulkDelete: "Grootmaat verwyder",
    youAreAboutToDelete: "Jy is op die punt om uit te vee",
    product: "Produk",
    isDueToRedeem: "moet betaal",
    aReward: "'n beloning",
    pleaseSelectCustomerToReeemReward:
      "Kies 'n kliënt om die beloning te gebruik.",
    youHaveNoLoyaltyProgramRunning: "U het geen aktiewe lojaliteitsprogram nie",
    customerHhasNoPointsInLoyaltyProgram:
      "Kliënt het geen punte in hierdie lojaliteitsprogram nie",
    proceedWithPayment: "Gaan voort met betaling?",
    youAreAboutToPayForTransactionUsingPoints:
      "U is op die punt om vir die transaksie met punte te betaal.",
    customerHas: "Kliënt het",
    worth: "waarde",
    andIsNotSufficientToPayForTransaction:
      "en is nie voldoende om vir hierdie transaksie te betaal nie. Sou hulle die saldo wou byvoeg met 'n ander betaalmetode?",
    addCustomerLoyalty: "Voeg kliëntlojaliteit by",
    pleaseAddCustomerFirst: "Voeg eers 'n kliënt by of kies dit eers.",
    pleaseWaitWhileWeGetReady: "Wag asseblief, terwyl ons gereed is",
    lowStock: "Lae voorraad",
    pleaseEnterAmountTendered: "Voer die tenderbedrag in",
    areYouSureToBookSaleOffline:
      "Weet u seker dat u hierdie uitverkoping vanlyn wil bespreek?",
    saleWouldBeBookedAutomatically:
      "Verkoop word outomaties bespreek wanneer u u internet aanskakel",
    offlineSalesBookingCancelled: "Vanlyn verkoopbespreking gekanselleer",
    covid19Message:
      "COVID19: Was u hande met seep of ontsmet om die verspreiding te voorkom. Bly altyd veilig",
    saleSuccessfullyRecorded: "Verkoop suksesvol aangeteken!",
    sendReceiptToEmail: "Stuur kwitansie na e -pos",
    sendThankYouSms: "Stuur dankie SMS",
    sendShippingDetails: "Stuur gestuurbesonderhede",
    addLoyalty: "Voeg lojaliteit by",
    searchCustomerNameOrNumber: "Soek die kliënt se naam of nommer",
    clickTheSearchCustomerBox:
      "Klik op die Soek klanteboks en skanderingskaart",
    enterProductPrice: "Voer die produkprys in",
    enterPriceFor: "Voer prys in vir",
    searchForProduct: "Soek produk",
    all: "Almal",
    backToDashboard: "Terug na Dashboard",
    viewDraftSales: "Bekyk konsepverkope",
    variantSelected: "variant gekies",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Die vorige variantkeuse is nie beskikbaar vir die nuwe variant wat op prys gekies is nie, verander u keuse.",
    pleaseSupplyPositiveQuantityNumber: "Gee 'n postiewe hoeveelheidnommer",
    lowStockFor: "Lae voorraad vir",
    clearVariants: "Duidelike variante",
    pleaseEnterQuantity: "Voer asseblief hoeveelheid in",
    picture: "Prent",
    redemptionToken: "Verlossingsteken",
    token: "Teken",
    totalSpent: "Totale besteding",
    confirmPayment: "Bevestig betaling",
    hasPaymentBeenMade: "Is betaling suksesvol verwerk?",
    enterTransactionReference:
      "Voer die transaksieverwysing in waarmee u betaal het",
    pleaseSelectACustomer: "Kies 'n kliënt!",
    areYouSureToApplyDiscount: "Weet u seker dat u afslag wil toepas?",
    addYourBankAccountToEnableUssd:
      "Voeg u bankrekening by om onmiddellike USSD -oordrag per uPay moontlik te maak",
    totalAmountToPay: "Totale bedrag te betaal",
    doYouWantToCancelTransaction: "Wil u hierdie transaksie kanselleer?",
    savePrintBill: "Stoor/druk rekening",
    enterAmountCollectedForCustomer:
      "Voer die bedrag in wat vir die kliënt ingesamel is",
    recordSale: "Rekordverkoping",
    checkOutWith: "Check uit met",
    instantTransfer: "Onmiddellike oordrag",
    dialTheUSSDCode: "Skakel die USSD -kode",
    pleaseSelectABank: "Kies 'n bank",
    payWithUSSD: "Betaal met USSD",
    sendBillTo: " - Stuur Bill na",
    waitingForUSSDTransfer: "Wag vir USSD -oordrag",
    percent: "Persentasie",
    applyDiscount: "Pas afslag toe",
    thisBillHasBeenSaved: "Hierdie wetsontwerp is gered",
    saveDraft: "Stoor konsep",
    pleaseTypeANameToIdentifyCustomer:
      "Tik 'n naam in om die kliënt te identifiseer",
    paymentDetails: "Betaling besonderhede",
    basePrice: "Basisprys",
    staff: "Personeel",
    subTotal: "Subtotaal",
    durationMonths: "Duur (maande)",
    selectADuration: "Kies 'n tydsduur",
    oneMonth: "1 maand",
    twoMonths: "2 maande",
    threeMonths: "3 maande",
    sixMonths: "6 maande",
    twelveMonths: "12 maande",
    eighteenMonths: "18 maande",
    twentyFourMonths: "24 maande",
    twoMonthsFree: "(2 maande gratis)",
    threeMonthsFree: "(3 maande gratis)",
    fiveMonthsFree: "(5 maande gratis)",
    yourAccountHasBeen: "Jou rekening is",
    renewed: "hernu",
    upgraded: "opgegradeer",
    successfully: "suksesvol",
    yourSubscription: "Jou intekening",
    youAreCurrentlyEnrolledOnA: "U is tans ingeskryf op 'n",
    pleaseChooseAPaymentOption: "Kies 'n betaalopsie",
    planRenewal: "Beplan hernuwing",
    planUpgrade: "Beplan opgradering",
    pleaseSelectDurationToPayFor: "Kies die tydsduur waarvoor u wil betaal",
    staffAccounts: "Personeelrekeninge",
    ecommerce: "E -handel",
    pleaseSelectAPlan: "Kies 'n plan",
    includeAddons: "Sluit byvoegings in",
    viewTransactions: "Bekyk transaksies",
    customerHasNoCompletedTansactions:
      "Kliënt het nog nie voltooide transaksies nie",
    branch: "Tak",
    enterNumberOfEcommerceBranches: "Voer die aantal e -handelstakke in",
    enterNumberOfEcommerceBranchesToPay:
      "Voer die aantal e -handelstakke in waarvoor u van plan is om te betaal",
    ecommerceIntegration: "Integrasie van e -handel",
    enterNumberOfBranches: "Voer die aantal takke in",
    enterNumberOfAdditionalBranchesToPay:
      "Voer die aantal bykomende takke in waarvoor u van plan is om te betaal",
    enterNumberOfStaffs: "Voer die aantal persone in",
    enterNumberOfStaffsToPayFor: "Voer die aantal persone in wat u wil betaal",
    discountApplies: "Afslag geld",
    starsOnThe: "sterre op die",
    offer: "aanbod",
    get: "Kry",
    moreStarsToRedeem: "meer sterre om in te los",
    taxVat: "Belasting (BTW)",
    callCashierToCompletePayment: "Bel die kassier om die betaling te voltooi",
    receipt: "Kwitansie",
    dear: "Liewe",
    thankYouForYourOrderFindGoods:
      "Dankie vir jou bestelling. Soek die onderstaande goedere, soos ooreengekom.",
    shippingNote: "Gestuurnota",
    enterShippingNote: "Voer afleweringsnota in",
    shippingAddress: "Afleweringsadres",
    enterShippingAddress: "Voer afleweringsadres in",
    wellDoneYouAreDueToRedeem: "Wel gedaan! U moet betaal",
    toGetYourRewardNextVisit:
      "om u beloning by u volgende besoek te kry. Dankie",
    pointsOnThe: "Punte op die",
    morePointsToRedeem: "meer punte om te gebruik",
    showCode: "Wys kode",
    toGetYourRewardOnNextVisit:
      "om u beloning by u volgende besoek te kry. Dankie",
    earn: "Verdien",
    delivaryNote: "Afleweringsnota",
    draftSales: "Konsepverkope",
    startDate: "Begindatum",
    endDate: "Einddatum",
    orders: "Bestellings",
    checkout: "uitteken",
    noProductItem: "Geen produkitem nie",
    selectProductImage: "Kies die produkbeeld",
    selectCountry: "Kies land",
    selectRegion: "Kies Staat/streek",
    printProductTag: "Druk die produketiket af",
    transactionReference: "Transaksieverwysing",
    Cashier: "Kassier",
    Manager: "Bestuurder",
    Owner: "Eienaar",
    Admin: "admin",
  },
  Albanian: {
    cashier: "arkëtar",
    manager: "menaxher",
    owner: "pronar",
    online: "online",
    offline: "jashtë linje",
    changePassword: "Ndrysho fjalekalimin",
    currentPasswordMessage: "Ju lutemi shkruani fjalëkalimin tuaj aktual",
    passwordMessage: "Ju lutem vendosni fjalëkalimin tuaj",
    currentPassword: "Fjalëkalimi aktual",
    password: "fjalëkalimin",
    confirmPasswordMessage: "Ju lutemi konfirmoni fjalëkalimin tuaj!",
    confirmPassword: "Konfirmo Fjalëkalimin",
    sendViaEmail: "Dërgoni përmes emailit",
    sendViaWhatsapp: "Dërgoni përmes WhatsApp",
    downloadPdf: "Shkarkoni PDF",
    paid: "e paguar",
    unpaid: "i papaguar",
    partial: "i pjesshëm",
    closeInvoice: "Dëshiron të mbyllësh Faturën?",
    closeInvoiceConfirmation: "Fatura mund të mos ruhet. Doni ta mbyllni?",
    yes: "po",
    no: "jo",
    invoice: "Fatura",
    wasDeducted: "ishte zbritur",
    for: "për",
    item: "Artikulli",
    addProduct: "Shto Produkt",
    paymentReference: "Referenca e pagesës",
    amountPaid: "Shuma e paguar",
    discountAmount: "Shuma e Zbritjes",
    amountDue: "Sasia e detyruar për pagesë",
    amount: "Shuma",
    dueDate: "Data e Pranimit",
    paymentType: "Lloji i pageses",
    card: "Kartë",
    cash: "Para të gatshme",
    bankTransfer: "Transferte bankare",
    paymentMessage: "Mesazh pagese",
    description: "Përshkrim",
    sendReceipt: "Dërgo Faturën",
    delete: "Fshi",
    save: "Ruaj",
    resend: "Ridërgo",
    saveAndSend: "Ruaj",
    invoiceSaved: "Fatura u ruajt!",
    selectPaymentMethod: "Ju lutemi zgjidhni një mënyrë pagese!",
    selectCustomer: "Ju lutemi zgjidhni një Klient!",
    cartEmptyError:
      "Lista e Shportave nuk mund të jetë bosh, mbyllni faturën dhe shtoni artikullin në karrocë!",
    subscriptionExpired:
      "Abonimi juaj ka skaduar dhe llogaria juaj tani është e kufizuar. Klikoni në butonin më poshtë për të rinovuar llogarinë tuaj",
    renew: "Rinovoj",
    holdOn: "Prit",
    customerBank: "Banka e Klientit",
    cancel: "Anulo",
    selectACustomer: "Zgjidhni Klientin",
    invoiceSuccessfulPdfError:
      "Fatura u krijua me sukses, por PDF Generation po merr më shumë se zakonisht. Ju lutemi kontrolloni përsëri së shpejti.",
    downloadingInvoice: "Po shkarkon Faturën",
    downloadingReceipt: "Po shkarkon faturën",
    whatsappReceiptError:
      "Ndodhi një gabim gjatë dërgimit të faturës përmes WhatsApp, ju lutemi provoni përsëri.",
    receiptToWhatsapp: "Fatura u përcoll në WhatsApp",
    thankYouForPatronage: "Faleminderit për patronazhin tuaj",
    hereIsYourReceipt: "Këtu është fatura juaj e pagesës",
    errorSendingEmailReceipt:
      "Ndodhi një gabim gjatë dërgimit të faturës përmes postës elektronike, ju lutemi provoni përsëri ose kontaktoni mbështetjen",
    receiptSentToEmail: "Fatura është dërguar në emailin e klientit",
    invoiceSentToEmail: "Fatura është dërguar në emailin e klientit",
    invoiceSuccessWhatsappError:
      "Fatura u krijua me sukses, por ndodhi një gabim gjatë dërgimit në WhatsApp. Provoni përsëri në listën e faturave",
    invoiceSuccessfulEmailError:
      "Fatura u krijua me sukses, por ndodhi një gabim gjatë dërgimit si e-mail. Ju lutemi provoni përsëri nga lista e faturave",
    invoiceSentToWhatsapp: "Fatura u përcoll në WhatsApp",
    hello: "Përshëndetje",
    pleaseDownloadInvoice: "Ju lutemi shkarkoni faturën",
    pleaseDownloadReceipt: "Ju lutemi shkarkoni faturën",
    from: "nga",
    email: "E -mail",
    upgrade: "Përmirëso",
    youAreOnFreePlan: "Ju jeni në një Plan Falas.",
    clickOn: "Klikoni mbi",
    yourPlanInFewSteps: " pajtimin tuaj në disa hapa të shpejtë.",
    to: "te",
    yourSubscriptionHasExpired:
      "Abonimi juaj ka skaduar dhe llogaria juaj tani është e kufizuar.",
    days: "ditë",
    yourSubscriptionExpiresIn: "Abonimi juaj në Loystar skadon në",
    createAcount: "Krijo nje llogari",
    signIn: "Hyni",
    continue: "Vazhdo",
    enterOtp: "Fut kodin PIN OTP",
    enterValidOtp: "Ju lutemi vendosni një kod PIN të vlefshëm",
    pin: "GJILPERE",
    tokenSentToMail: "Një shenjë është dërguar në emailin tuaj",
    passwordResetSuccessful: "Rivendosja e fjalëkalimit ishte e suksesshme",
    somethingWentWrong: "Dicka shkoi keq!",
    resetPassword: "Rivendosni fjalëkalimin",
    iHaveResetCode: "Unë kam një kod të rivendosjes së fjalëkalimit",
    pleaseEnterEmail: "Ju lutemi shkruani emailin tuaj",
    aTokenWillBeSentToEmail: "Një shenjë do të dërgohet në emailin tuaj",
    enterEmail: "Shkruani emailin tuaj",
    sendinYourToken: "Shenja juaj po dërgohet ...",
    makeSureItMatchesPassword:
      "Sigurohuni që përputhet me fjalëkalimin tuaj të ri",
    pleaseChooseNewPassword: "Ju lutemi zgjidhni një fjalëkalim të ri",
    chooseNewPassword: "Zgjidhni një fjalëkalim të ri",
    enterNewPassword: "Futni fjalëkalimin tuaj të ri për të konfirmuar",
    enterTokenSent: "Shkruani shenjën që iu dërgua posta juaj",
    resetToken: "Rivendos Token",
    resettingPassword: "Po rivendos fjalëkalimin ...",
    signUp: "Regjistrohu",
    adminSignInWithEmail:
      " Administratori hyn me email ndërsa Stafi hyn me emrin e përdoruesit.",
    pleaseEnterEmailOrUsername:
      "Ju lutemi shkruani emailin ose emrin e përdoruesit",
    emailOrUsername: "Email ose emrin e përdoruesit",
    pleaseEnterPassword: "Ju lutemi shkruani fjalëkalimin",
    createAnAccount: "Krijo nje llogari",
    forgotPassword: "Keni harruar fjalëkalimin?",
    enterPhoneNumber: "Fut numrin e telefonit",
    personalData: "Te dhena Personale",
    validateConfirmationCOde: "Validoni Kodin e Konfirmimit",
    pleaseEnterFirstName: "Ju lutemi shkruani emrin tuaj të parë",
    pleaseEnterPhoneNumber: "Ju lutemi shkruani numrin tuaj të telefonit",
    pleaseEnterLastName: "Ju lutemi shkruani mbiemrin tuaj",
    pleaseEnterBusinessName: "Ju lutemi shkruani emrin e biznesit tuaj",
    firstName: "Emri",
    lastName: "Mbiemri",
    businessName: "Emri i biznesit",
    previous: "E mëparshme",
    next: "Tjetra",
    pleaseSelectBusinessCategory:
      "Ju lutemi zgjidhni kategorinë e biznesit tuaj",
    pleaseEnterValidEmail: "Ju lutemi shkruani një email të vlefshëm",
    pleaseEnterPostCode: "Ju lutemi shkruani kodin postar",
    postCode: "Kodi Postar",
    phoneNumberInUse: "Ky numër telefoni është në përdorim tashmë!",
    emailInUse: "Ky email është në përdorim tashmë!",
    foodAndDrinks: "Ushqim dhe pije",
    salonAndBeauty: "Salloni dhe Bukuria",
    fashionAndAccessories: "Moda dhe Aksesorë",
    gymAndFitness: "Palestër dhe palestër",
    travelAndHotel: "Udhëtimi dhe Hoteli",
    homeAndGifts: "Shtëpi dhe Dhurata",
    washingAndCleaning: "Larje dhe Pastrim",
    gadgetsAndElectronics: "Gadgets dhe Elektronikë",
    groceries: "Sende ushqimore",
    others: "Të tjerët",
    submit: "Paraqit",
    accountCreatedSuccessful: "Llogaria juaj u krijua me sukses.",
    pleaseEnterAddress: "Ju lutemi shkruani adresën tuaj",
    addressLine1: "Linja e Adresës 1",
    addressLine2: "Linja e Adresës 2",
    choosePassword: "Zgjidhni fjalëkalimin",
    passwordMustBe6Characters: "Fjalëkalimi duhet të jetë së paku 6 karaktere.",
    howDidYouHearLoystar: "Si dëgjuat për Loystar?",
    referralCode: "Kodi i referimit",
    byClickingTheButton: " Duke klikuar butonin më poshtë, ju pranoni",
    termsAndSevice: "Kushtet",
    wereCreatingAccount: "Ne po krijojmë llogarinë tuaj",
    proceed: "Vazhdo",
    verificationCodeMustBe6: "Kodi i Verifikimit duhet të jetë 6 shifror",
    pleaseEnter6DigitCode: "Ju lutemi shkruani kodin 6 shifror",
    enterVerificationCode: "Vendos kodin e verifikimit",
    resendToken: "Ridërgo Tokenin",
    verify: "Verifiko",
    transactions: "Transaksionet",
    todaySales: "Shitjet e sotme",
    salesHistory: "Historia e shitjeve",
    supplyHistory: "Historia e Furnizimit",
    new: "I ri",
    invoices: "Faturat",
    disbursements: "Disbursimet",
    offlineSales: "Shitje jashtë linje",
    products: "Produktet",
    customers: "Klientët",
    multiLevelLoyalty: "Besnikëria me shumë nivele",
    loyaltyPrograms: "Programet e Besnikërisë",
    members: "Anëtarët",
    appStore: "Dyqani i Aplikacioneve",
    orderMenu: "Menyja e porosive",
    settings: "Cilësimet",
    staffAndBranches: "Shtabet dhe Degët",
    myAccount: "Llogaria ime",
    switchToSellMode: "Kaloni në modalitetin e shitjes",
    signOut: "Identifikohu",
    getFreeSubscription: "Merrni Abonim Falas",
    onlyNumbersAllowed: "Vetëm numrat lejohen",
    yourAccountMustBe10Digits:
      "numri i llogarisë tuaj duhet të jetë një numër 10 shifror",
    yourBvnMustBe11: "BVN -ja juaj duhet të jetë një numër 11 shifror",
    pleaseSelectBank: "Ju lutemi zgjidhni bankën tuaj",
    selectYourBank: "Zgjidhni Bankën tuaj",
    enterBankAccountNumber: "Fut numrin e llogarisë bankare",
    enterBvnNumber: "Vendosni BVN tuaj",
    connectBankAccount: "Lidhni llogarinë bankare",
    passwordResetSuccessfulAndSignOut:
      "Fjalëkalimi juaj është rivendosur me sukses. Klikoni në butonin Sign Out më poshtë për t'u identifikuar përsëri",
    enterCurrentPassword: "Fut fjalëkalimin aktual",
    pleaseEnterCurrentPassword: "Ju lutemi shkruani fjalëkalimin aktual",
    phoneNumber: "Numri i telefonit",
    sex: "Seksi",
    dateOfBirth: "Data e lindjes",
    state: "Shteti",
    country: "Vend",
    loyaltyId: "ID e Besnikërisë",
    createdAt: "Krijuar në",
    noOfTransactionsMade: "Numri i transaksioneve të bëra",
    yourDownloadWillStart: "Shkarkimi juaj do të fillojë në një moment",
    exportCustomers: "Konsumatorët e eksportit",
    youhaveSuccessfullyToppedUp: "Ju keni mbushur me sukses Njësitë tuaja SMS.",
    youNowHave: "Tani keni",
    smsUnits: "Njësitë SMS",
    enterNumberOfSmsUnits:
      "Vendosni numrin e njësive SMS që keni ndërmend të blini",
    pleaseEnterANumericValue: "Ju lutemi shkruani një vlerë numerike",
    pay: "Paguaj",
    accountEditedSuccessfully: "Llogaria juaj u redaktua me sukses.",
    youAeCurrentlyOn: "Ju jeni aktualisht",
    plan: "Planifikoni",
    userData: "Të dhënat e përdoruesit",
    businessData: "T D DHNA BIZNESI",
    businessCategory: "Catergory e biznesit",
    pleaseSelectCurrency: "Ju lutemi zgjidhni monedhën tuaj",
    selectYourCurrency: "Zgjidhni monedhën tuaj",
    purchaseMoreSmsUnits:
      "Blini më shumë Njësi SMS duke përdorur formularin më poshtë",
    youHave: "Ju keni",
    atLeast4SmsUnitsRrequired:
      "Të paktën 4 njësi sms kërkohen për verifikim, ju lutemi plotësoni!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Ju lutemi verifikoni llogarinë tuaj bankare për të qenë në përputhje me kërkesat "Njihni klientin tuaj" (KYC). Kjo do t\'ju lejojë të mbledhni pagesa përmes USSD ose transferim të menjëhershëm, të merrni urdhra nga klientët dhe të përpunoni shlyerjen e transaksioneve. Zbatohet vetëm për tregtarët nigerianë. Ju lutemi shtypni butonin më poshtë për të filluar.',
    reConnectBankAccount: "Lidheni përsëri llogarinë bankare",
    accountName: "Emri i llogarise",
    accountNumber: "Numri i llogarisë",
    bankName: "Emri i bankes",
    verified: "E verifikuar",
    accountDetails: "detajet e llogarise",
    kycBankAccount: "KYC",
    createTier: "Krijo Nivelin",
    fileUploadSuccessful: "skedari u ngarkua me sukses",
    fileUploadFailed: "ngarkimi i skedarit dështoi",
    createLoyaltyProgram: "Krijoni një Program Besnikërie",
    createLoyalty: "Krijo Besnikëri",
    name: "Emri",
    loyaltyArtwork: "Vepra Arti e Besnikërisë",
    clickToUpload: "Klikoni për të ngarkuar",
    amountToPointsRatio: "Raporti i shumës ndaj pikëve",
    points: "Pikat",
    recommendedAmountToPointRatio:
      "Rekomanduar: ₦ 1 deri në 1 pikë. dmth për çdo 1 Naira të shpenzuar, klientët tuaj marrin 1 pikë",
    pleaseTypeIn: "Ju lutemi shkruani",
    toDeleteLoyalty: "për të fshirë këtë besnikëri",
    deleteLoyalty: "Fshi Besnikërinë",
    toConfirm: "për të konfirmuar",
    edit: "Ndrysho",
    pointsAwardedSuccessfully: "Pikët u dhanë me sukses.",
    enterPointValueToAward:
      "Shkruani vlerën e pikës që dëshironi të shpërbleni për klientët",
    award: "Çmim",
    awardPointValuesToCustomer: "Vlera e Pikës së Çmimit për Klientin",
    enrollMembersToLoyalty: "Regjistroni Anëtarët në Besnikëri",
    awardPoints: "Pikët e dhënies",
    enroll: "Regjistrohu",
    home: "Në shtëpi",
    loyalty: "Besnikëria",
    enrollCustomers: "Regjistroni klientët",
    selected: "Zgjedhur",
    customer: "Klienti",
    loyaltyActivationSuccessful: "Aktivizimi i Besnikërisë është i suksesshëm.",
    loyaltyDeactivationSuccessful:
      "Deaktivizimi i Besnikërisë është i suksesshëm.",
    viewTier: "Shikoni nivelin",
    deactivate: "Çaktivizo",
    activate: "Aktivizo",
    actions: "Veprimet",
    nameOfLoyalty: "Emri i Besnikërisë",
    loyaltyStatus: "Statusi i Besnikërisë Statusi i Besnikërisë",
    numberOfTiers: "Numri i niveleve",
    createdOn: "Krijuar më",
    createATier: "Krijoni një Nivel",
    stopCreatingTierConfirmation: "Dëshironi të ndaloni krijimin e një niveli?",
    stopEditingTierConfirmation:
      "Dëshiron të ndalosh redaktimin e këtij niveli?",
    nameOfTier: "Emri i Nivelit",
    minimumSpendingTarget: "Synimi minimal i shpenzimeve",
    maximumSpendingTarget: "Synimi maksimal i shpenzimeve",
    minimumSpendingTargetRequired: "kërkohet objektivi minimal i shpenzimeve",
    maximumSpendingTargetRequired: "kërkohet objektivi maksimal i shpenzimeve",
    rewardSponsor: "Sponsor shpërblimi",
    pleaseChooseARewardSponsor: "Ju lutemi zgjidhni një sponsor shpërblimi",
    self: "Vetë",
    partner: "Partnere",
    rewardPartner: "Partneri i Shpërblimit",
    pleaseSelectRewardPartner:
      "Ju lutemi zgjidhni partnerin tuaj të shpërblimit",
    rewards: "Shpërblimet",
    pleaseSelectAReward: "Ju lutemi zgjidhni një shpërblim",
    instructionsOnRedeemingReward:
      "Udhëzime se si klienti duhet të shpengojë shpërblimin",
    pleaseFillInThisField: "Ju lutemi Plotësoni këtë Fushë!",
    toDeleteThisTier: " për të fshirë këtë nivel",
    deleteTier: "Fshi nivelin",
    viewMembers: "Shikoni Anëtarët",
    membersEnrolled: "Anëtarët e Regjistruar",
    instruction: "Udhëzim",
    membersIn: "Anëtarët në",
    availableTiersInLoyalty: "Niveli i disponueshëm në Programin e Besnikërisë",
    tiers: "Nivelet",
    totalTier: "NDIRTIMI TOTAL",
    availableLoyaltyProgramme: "Programi në dispozicion i Besnikërisë",
    totalLoyalties: "BESIMTARI TOTAL",
    memberDetails: "Detajet e Anëtarëve",
    nameOfCustomer: "Emri i Klientit",
    address: "Adresë",
    allEnrolledMembers: "Të gjithë anëtarët e regjistruar",
    thisIsToWishYouHappyBirthday:
      "Kjo është për t'ju uruar një ditëlindje shumë të lumtur dhe një jetë të begatë. Faleminderit për gjithçka që jeni për Loyster. Gëzuar Festimet!",
    inputAnOfferPlease: "Ju lutemi shkruani një ofertë",
    pleaseSelectTheInsertPoint:
      "Ju lutemi zgjidhni pikën e futjes në mesazh dhe klikoni përsëri",
    birthdayOfferAndMessage: "Ofertë dhe mesazh për ditëlindje",
    birthdayOffer: "Oferta për ditëlindje",
    birthdayMessage: "Mesazhi i ditëlindjes",
    noOfferFound: "Asnjë ofertë nuk u gjet",
    settingABirthdayOffer:
      "Vendosja e një oferte ditëlindjeje i lejon klientët ta marrin këtë ofertë me SMS në ditëlindjen e tyre",
    createOffer: "Krijo Ofertë",
    whatIsTheOffer: "Cila është oferta?",
    editMessage: "Ndrysho Mesazhin",
    insert: "Fut",
    theNameOfCustomerInserted: "Emri i klientit do të futet këtu",
    theBirtdayOfferInserted: "Oferta e ditëlindjes do të futet këtu",
    youSuccessfullyAddedNewBranch: "Ju keni shtuar me sukses një degë të re!",
    addNewBranch: "Shto Degë të Re",
    addBranch: "Shto Degë",
    additionalBranchWillIncur: "Do të ketë degë shtesë",
    perBranch: "për degë",
    ecommerceBranchCosts: "Shpenzimet e Degës së Ecommerce",
    pleaseEnterBranchName: "Ju lutemi shkruani emrin e degës",
    pleaseEnterBranchAddress1:
      "Ju lutemi shkruani linjën 1 të adresës së degës",
    enterBranchAddress1: "Futni linjën e adresës 1 të degës",
    enterBranchAddress2: "Futni linjën e adresës 1 të degës",
    pleaseEnterBranchAddress2: "Ju lutemi shkruani linjën e adresës së degës 2",
    enterBranchName: "Shkruani emrin e degës",
    successfullyAddedStaff: "Ju keni shtuar me sukses një staf të ri!",
    addNewStaff: "Shtoni staf të ri",
    addStaff: "Shto Staf",
    additionalStaffWillIncur: "Stafi shtesë do të pësonte",
    perStaff: "për staf.",
    pleaseEnterStaffEmail: "Ju lutemi shkruani emailin e stafit",
    pleaseEnterStaffUsername:
      "Ju lutemi shkruani emrin e përdoruesit të stafit",
    pleaseEnterStaffPassword: "Ju lutemi shkruani fjalëkalimin e stafit",
    pleaseSelectStaffRole: "Ju lutemi zgjidhni rolin e stafit",
    selectStaffRole: "Zgjidhni rolin e stafit",
    enterStaffEmail: "Shkruani emailin e stafit",
    enterStaffUsername: "Vendosni emrin e përdoruesit të stafit",
    enterStaffPassword: "Vendosni fjalëkalimin e stafit",
    spacesNotAllowedInUsername: "hapësirat nuk lejohen në emrin e përdoruesit",
    admin: "Administratori",
    pleaseSelectBusinessToAttachStaff:
      "Ju lutemi zgjidhni biznesin për të bashkuar stafin",
    searchForBranchToAttachStaff: "Kërkoni për degë për të bashkuar stafin",
    username: "Emri i përdoruesit",
    role: "Roli",
    areYouSureToDeleteThis: "A jeni i sigurt për ta fshirë këtë",
    branches: "Degët",
    upgradeYourPlan: "Përmirësoni Planin tuaj.",
    add: "SHTOJ",
    addNew: "Shto nje te ri",
    customerWithEmailAlreadyExists:
      "Klienti me email/numër telefoni ekziston tashmë!",
    successfullyAddedNewCustomer: "Ke shtuar me sukses një klient të ri!",
    addCustomer: "Shto klient",
    pleaseEnterCustomerFirstName: "Ju lutemi shkruani emrin e klientit",
    pleaseEnterCustomerLastName: "Ju lutemi shkruani mbiemrin e klientit",
    pleaseEnterCustomerPhoneNumber:
      "Ju lutemi shkruani numrin e telefonit të klientit",
    pleaseEnterCustomerEmail: "Ju lutemi shkruani emailin e klientit",
    pleaseEnterCustomerAddressLine:
      "Ju lutemi shkruani Linjën e Adresës së klientit",
    pleaseEnterCustomerOtherAddress:
      "Ju lutemi shkruani Adresën tjetër të klientit",
    pleaseSelectCustomerGender: "Ju lutemi zgjidhni gjininë e klientit",
    gender: "Gjinia",
    male: "Mashkull",
    female: "Femër",
    bank: "Bankë",
    selectBank: "Zgjidhni Bankën",
    stateOrRegionOrProvince: "Shteti/Rajoni/Provinca",
    customerNotes: "Shënimet e Klientit",
    sendSms: "Dërgo SMS",
    editCustomer: "Ndrysho Klientin",
    redeemReward: "Shlyeni shpërblimin",
    issueLoyaltyCard: "Lëshoni Kartën e Besnikërisë",
    deleteCustomer: "Fshi Klientin",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ju keni caktuar me sukses një ID Anëtarësimi Besnikërie",
    noMembershipIdAvailable:
      "Nuk ka ID të anëtarësimit në dispozicion. Ju lutemi na kontaktoni në hello@loystar.co",
    sendEmail: "Dërgoni një email",
    membershipPoints: "Pikat e Anëtarësimit",
    customerDetails: "Detajet e Klientit",
    close: "Mbylle",
    loyaltyBalance: "Bilanci i Besnikërisë",
    pointsBalance: "Bilanci i pikëve",
    starBalance: "Bilanci i Yjeve",
    requiredPoints: "Pikat e kërkuara",
    requiredStars: "Yjet e kërkuar",
    reddemCode: "Shpengoj kodin",
    toDeleteThisCustomer: "për të fshirë këtë klient",
    customerHasBeenDeletedSuccessfully: "Klienti është fshirë me sukses!",
    customerWithPhoneAlreadyExists:
      "Klienti me numrin e telefonit ekziston tashmë",
    customerWasSuccessfullyEdited: "Klienti u redaktua me sukses",
    anErrorOccured: "Një gabim Ndodhi",
    phoneNumberIncludeCountry: "Numri i telefonit (Përfshi kodin e vendit)",
    yourFileQueuedForUpload:
      "Skedari juaj është vendosur në radhë për t'u ngarkuar. Ju lutemi rifreskoni faqen pas disa sekondash.",
    thereWasAnErrorPerformingOperation:
      "Ndodhi një gabim gjatë kryerjes së operacionit!",
    pleaseSelectFile: "Ju lutemi zgjidhni një skedar!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Lejohen vetëm skedarët CSV. Ju lutemi ngarkoni një skedar .csv.",
    fileShouldBeSmallerThan5Mb:
      "Skedari duhet të jetë më i vogël se 5 MB. Nëse keni një skedar më të madh, ju lutemi dërgoni email support@loystar.co. Faleminderit",
    customerFirstNameIsRequired: "emri i klientit kërkohet në rresht",
    customerPhoneNumberIsRequired:
      "numri i telefonit të klientit kërkohet në rresht",
    importCustomers: "Importoni Klientë",
    upload: "Ngarkoni",
    clickIconToDownloadCsv:
      "Klikoni këtë ikonë për të shkarkuar modelin e skedarit CSV.",
    getGoogleSheetVersion: "Merrni versionin e fletës së Google",
    clickOrDragFileToUpload:
      "Klikoni ose tërhiqni skedarin në këtë zonë për ta ngarkuar",
    missingOrInvalidColumnHeader:
      "Koka e kolonës mungon ose është e pavlefshme. Ju lutemi ndiqni formatin e modelit. Faleminderit.",
    youHaveImported: "Ju keni Importuar",
    youSuccessfullyRedeemedReward:
      "Ju keni shpërblyer me sukses shpërblimin tuaj!",
    sixDigitCode: "Kodi gjashtë shifror",
    pleaseEnterCustomerRewardCode:
      "Ju lutemi shkruani kodin e shpërblimit të klientit",
    enterRewardCode:
      "Fut kodin e shpërblimit. (Kodi i shpërblimit është i ndjeshëm ndaj shkronjave të mëdha)",
    selectLoyaltyProgram: "Zgjidhni programin e besnikërisë",
    stamps: "Pulla",
    smsUnitsLow: "Njësitë SMS të ulëta",
    pleaseTopUpSmsUnits: "Ju lutemi plotësoni njësitë SMS",
    smsSuccessfullySentForDelivery: "SMS u dërgua me sukses për dorëzim!",
    sendSmsTo: "Dërgoni SMS te",
    allCustomers: "Të gjithë klientët",
    unitsAvailable: "Njësitë në dispozicion",
    pleaseTypeInTheMessage: "Ju lutemi shkruani mesazhin",
    message: "Mesazh",
    charactersRemaining: "personazhet e mbetur",
    avoidUseOfSpecialCharacters:
      "Shmangni përdorimin e Karaktereve Speciale dhe Emoji për të ruajtur Njësitë SMS.",
    note: "shënim",
    errorFetchingCustomersMultilevelDetail:
      "Gabim në marrjen e detajeve të shumë niveleve të klientëve",
    search: "Kërko",
    reset: "Rivendos",
    importCustomer: "Importo Klientin",
    addNewCustomer: "Shtoni një klient të ri",
    sendSmsBroadcast: "Dërgoni transmetim SMS",
    totalCustomers: "KLIENT TOTAL",
    disbursementDetails: "Detajet e Disbursimit",
    paidBy: "Paguar Nga",
    disbursed: "I disbursuar",
    bankAccountName: "Emri i llogarisë bankare",
    bankAccountNumber: "Numri i llogarisë bankare",
    transferInitiated: "Transferimi filloi",
    transferCompleted: "Transferimi përfundoi",
    pleaseEnterAValid: "Ju lutemi shkruani një të vlefshme",
    begin: "filloj",
    end: "fund",
    date: "data",
    paymentDate: "Data e pagesës",
    selectDisbursementDuration: "Zgjidhni Kohëzgjatjen e Disbursimit",
    totalSettled: "Gjithsej e zgjidhur",
    totalUnsettled: "Gjithsej e pazgjidhur",
    toDeleteThisSale: "për të fshirë këtë shitje",
    draftSaleDeletedSuccessfully: "Draft -shitja është fshirë me sukses!",
    deleteSale: "Fshi Shitjen",
    pleaseTypeInYourTotal:
      "Ju lutemi shkruani totalin tuaj për të konfirmuar fshirjen",
    billDetails: "Detajet e faturës",
    printBill: "Shtyp Faturën",
    servedBy: "Shërbyer nga",
    total: "Total",
    createdDate: "Data e Krijimit",
    createdTime: "Koha e Krijuar",
    status: "Statusi",
    loginSuccessful: "Hyrja është e suksesshme",
    pleaseWaitWhileWeConnectAccount:
      "Ju lutemi prisni derisa të lidhim llogarinë tuaj",
    connectionFailedTryAgain: "Lidhja dështoi. Ju lutemi provoni përsëri.",
    connectionSuccessful: "Lidhja është e suksesshme",
    viewDetails: "Shiko detajet",
    enable: "Aktivizo",
    free: "Falas",
    cost: "Kosto",
    visitWebsite: "Vizitoni faqen e internetit",
    pleaseUpgradeYourPlanToPro:
      "Ju lutemi azhurnoni planin tuaj në Pro Plus për të aktivizuar këtë aplikacion",
    connectYourBankAccount:
      "Lidhni llogarinë tuaj bankare për të qenë në gjendje të merrni pagesa.",
    disable: "Çaktivizo",
    enableApp: "Aktivizo aplikacionin",
    addNewProductToInvoice: "Shtoni Produkt të Ri në Faturë",
    toDeleteThisInvoice: "për të fshirë këtë Faturë",
    invoiceDeletedSuccessfully: "Fatura është fshirë me sukses!",
    deleteInvoice: "Fshije Faturën",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ke shtuar me sukses një mesazh pagese në Faturë!",
    pleaseEnterPaymentInstructions: "Ju lutemi vendosni udhëzimet e pagesës",
    invoiceId: "ID e faturës",
    paidDate: "Data e Paguar",
    reference: "Referenca",
    productAdded: "Produkti u shtua",
    productOutOfStock: "Produkt pa stok. Ju lutemi ri-stokoni.",
    totalInvoices: "FATURAT TOTALE",
    totalPaidInvoices: "TOTALI FATURAT E PAGUARA",
    totalUnpaidInvoices: "TOTALI FATURAT E PAGUARA",
    loyaltyProgramDeleted: "Programi i Besnikërisë u Fshi",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ky program besnikërie është fshirë me sukses",
    thisLoyaltyProgramEditedSuccessfully:
      "Ky program besnikërie është redaktuar me sukses",
    loyaltyProgramAddedSuccessfully:
      "Programi i Besnikërisë është shtuar me sukses",
    loyaltyProgramEdited: "Programi i Besnikërisë i Redaktuar",
    loyaltyProgramAdded: "Programi i Besnikërisë u Shtua",
    loyaltyDetails: "Detajet e Besnikërisë",
    doYouWantToCloseDialog: "Dëshironi ta mbyllni këtë dialog?",
    pleaseEnterLoyaltyName: "Ju lutemi shkruani emrin e besnikërisë tuaj",
    programType: "Lloji i Programit",
    pleaseSelectProgramType: "Ju lutemi zgjidhni një lloj programi",
    simplePoints: "Pikat e thjeshta",
    stampsProgram: "Programi i Pullave",
    threshold: "Pragu",
    pleaseEnterLoyaltyThreshold: "Ju lutemi shkruani pragun e besnikërisë",
    reward: "Shperblim",
    pleaseEnterLoyaltyReward: "Ju lutemi shkruani shpërblimin e besnikërisë",
    totalUserPoints: "Pikët totale të përdoruesit",
    totalUserStamps: "Pulla totale të përdoruesit",
    spendingTarget: "Objektivi i shpenzimit",
    spendingTargetHint1:
      "Objektivi i shpenzimit është sa një klient duhet të shpenzojë për të fituar shpërblimin. 1 vlerë monedhe = 1 pikë.",
    spendingTargetHint2:
      "Objektivi i pullave është sa pulla duhet të mbledhë një klient për të fituar shpërblimin. p.sh. 5",
    addNewLoyaltyProgram: "Shtoni Program të Ri Besnikërie",
    addLoyaltyProgram: "Shtoni Programin e Besnikërisë",
    loyaltyProgramType: "Lloji i Programit të Besnikërisë",
    pleaseSelectLoyaltyProgramType:
      "Ju lutemi zgjidhni llojin e programit të besnikërisë",
    nameOfProgram: "Emri i programit",
    pleaseEnterProgramName: "Ju lutemi shkruani emrin e programit",
    whatIsTheReward: "Cili është shpërblimi?",
    egNextPurchaseIsFree: "P.sh. Blerja tjetër është falas",
    customerName: "Emri i Klientit",
    guestCustomer: "Klienti mysafir",
    orderConfirmedSuccessfully: "Porosia u konfirmua me sukses",
    orderCancelledSuccessfully: "Porosia u anulua me sukses",
    confirmOrder: "Konfirmo porosinë",
    cancelOrder: "Anulo porosinë",
    allOrders: "Te gjitha porosite",
    totalOrders: "POROSIT TOTAL",
    doYouWantToAcceptOrder: "A doni ta pranoni këtë urdhër?",
    doYouWantToCancelOrder: "Dëshiron ta anulosh këtë porosi?",
    orderDetails: "Detajet e porosisë",
    orderCreatedAt: "Porosia u krijua në",
    supplier: "Furnizuesi",
    productName: "Emri i produktit",
    quantity: "sasi",
    unitPrice: "Çmimi i njësisë",
    receivedBy: "Marrë nga",
    reportFrom: "Raport nga",
    totalSupplies: "Furnizimet totale",
    allRightsReserved: "Të gjitha të drejtat e rezervuara",
    toDeleteThisTransaction: "për të fshirë këtë Transaksion",
    transactionDeletedSuccessfully:
      "Transaksioni është fshirë me sukses. Aksionet janë kthyer në inventar.",
    deleteTransaction: "Fshi Transaksionin",
    transactionDetails: "Detajet e transaksionit",
    printReceipt: "Shtypni Faturën",
    channel: "Kanali",
    discount: "Zbritje",
    profit: "Fitimi",
    discountedSales: "Shitje me zbritje",
    errorFetchingRecord: "Gabim në marrjen e regjistrimit",
    exportTransactions: "Transaksionet e eksportit",
    errorFetchingSalesRecord:
      "Gabim në marrjen e rekordit të shitjeve për Export.",
    totalSellingPrice: "Çmimi total i shitjes",
    totalCostPrice: "Çmimi i kostos totale",
    appliedDiscount: "Zbritje e aplikuar",
    noOfItems: "Numri i artikujve",
    sales: "Shitjet",
    export: "Eksporto",
    totalProfit: "Fitimi total",
    totalBalanceInPeriod: "Bilanci total në periudhë",
    allTimeSales: "Shitjet e të gjitha kohërave",
    records: "Regjistrimet",
    todaysSales: "Shitjet e sotme",
    transaction: "transaksion",
    youHaveExceededTotalNumberOfProducts:
      "Ju keni tejkaluar numrin e përgjithshëm të produkteve të lejuara në planin tuaj. Përmirësoni planin tuaj për të shijuar një kufi më të lartë.",
    youNeedToHaveLoyaltyProgram:
      "Ju duhet të keni një Program Besnikërie për të përdorur këtë veçori!",
    price: "Çmimi",
    category: "Kategoria",
    stockTracking: "Ndjekja e aksioneve",
    stockCount: "Numërimi i aksioneve",
    taxed: "I tatuar",
    originalPrice: "Çmimi Origjinal",
    costPrice: "Çmimi i kostos",
    unit: "Njësia",
    productImage: "Imazhi i produktit",
    taxRate: "Norma e tatimit",
    taxType: "Lloji i Taksës",
    trackInventory: "Ndiq inventarin",
    variants: "Variantet",
    hasVariants: "Ka Variante",
    importProduct: "Import Produkt",
    exportProducts: "Produkte eksporti",
    addNewProduct: "Shto Produkt të Ri",
    viewCategory: "Shikoni Kategorinë",
    viewSuppliers: "Shikoni Furnizuesit",
    receiveInventory: "Merrni Inventarin",
    printAllProductsTag: "Shtypni të gjitha etiketat e produkteve",
    deleteAll: "Fshij te gjitha",
    totalProducts: "PRODUKTET TOTAL",
    youveSuccessfullyAddedANewCategory:
      "Ju keni shtuar me sukses një kategori të re",
    addNewCategory: "Shto Kategori të Re",
    addCategory: "Shto Kategori",
    categoryName: "Emri i kategorisë",
    pleaseEnterCategoryName: "Ju lutemi shkruani emrin e kategorisë",
    stampsTarget: "Pulla Target",
    sendInventory: "Dërgo Inventar",
    productDetails: "detajet e produktit",
    youveSuccessfullyEditedThisCategory:
      "Ju e keni redaktuar me sukses këtë kategori",
    update: "Përditëso",
    categoryList: "Lista e kategorive",
    categories: "Kategoritë",
    enterQuantityToUpdate: "Fut një sasi për tu përditësuar",
    inventorySentSuccessfully: "Inventari u dërgua me sukses!",
    updateInventory: "Përditëso Inventarin",
    currentQuantity: "Sasia aktuale",
    pleaseEnterQuantityToAdd:
      "Ju lutemi shkruani sasinë që dëshironi të shtoni",
    pleaseSelectABranch: "Ju lutemi zgjidhni një Degë",
    searchForBranch: "Kërkoni për degën",
    youCantSendMoreThanStock:
      "Ju nuk mund të dërgoni më shumë sesa keni në magazinë",
    send: "Dërgoni",
    pleaseEnterQuantityToSend:
      "Ju lutemi shkruani sasinë që dëshironi të dërgoni",
    productNameIsRequiredOnRow: "emri i produktit kërkohet në rresht",
    productCategoryIsRequiredOnRow: "Kategoria e produktit kërkohet në rresht",
    productPriceIsRequiredOnRow: "çmimi i produktit kërkohet në rresht",
    productUnitIsRequiredOnRow: "njësia e produktit kërkohet në rresht",
    productQuantityIsRequiredOnRow: "Sasia e produktit kërkohet në rresht",
    productVariantsRequireTracking: "variantet e produktit kërkojnë ndjekje!",
    pleaseAddVariantClickButton:
      "Ju lutemi shtoni një variant duke klikuar butonin shto!",
    totalVariantsMoreThanSelectedQuantity:
      "Variantet totale janë më shumë se sasi të produktit të zgjedhur, Pls zvogëlojnë sasinë e variantit",
    productEditedSuccessfully: "Produkti është redaktuar me sukses!",
    fileTooLargeLessThan4Mb:
      "Madhësia e skedarit është shumë e madhe! Madhësia e skedarit duhet të jetë më pak se 4 MB.",
    suchVariantAlreadyExist: "Një variant i tillë tashmë ekziston",
    addVariants: "Shto variante",
    editProduct: "Ndrysho produktin",
    pleaseEnterProductName: "Ju lutemi Shkruani Emrin e Produktit",
    pleaseEnterProductPrice: "Ju lutemi Shkruani Çmimin e Produktit",
    pleaseEnterProductOriginalPrice:
      "Ju lutemi Shkruani Çmimin Origjinal të Produktit",
    productDescription: "përshkrim i produktit",
    selectProductCategory: "Zgjidhni Kategorinë e Produkteve",
    pleaseSelectProductCategory: "Ju lutemi zgjidhni kategorinë e produktit",
    productCostPrice: "Çmimi i kostos së produktit",
    productSellingPrice: "Çmimi i shitjes së produktit",
    productOriginalPrice: "Çmimi Origjinal i Produktit",
    maxFileSizeAllowedIs4Mb: "Maks. Madhësia e lejuar e skedarit është 4mb",
    productsWithPicturesArePublished:
      "Produktet me fotografi publikohen në Discover për të marrë porosi",
    shouldThisProductBeTracked: "A duhet të gjurmohet ky produkt?",
    pleaseSelectStockUnit: "Ju lutemi zgjidhni njësinë e aksioneve",
    stockUnit: "Njësia e aksioneve",
    bag: "ÇANTË",
    bottle: "Shishe",
    bunch: "BUNCH",
    can: "MUND",
    carton: "KARTON",
    crate: "KRATI",
    cup: "CUP",
    dozen: "BOZEN",
    gigabytes: "GIGABITETET",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKET",
    pair: "Çifti",
    pieces: "PJESA",
    plate: "Pllakë",
    tonne: "Ton (MT)",
    uNIT: "NJ .SI",
    yard: "Oborr",
    pleaseEnterProductQuantity: "Ju lutemi shkruani sasinë e produktit",
    productQuantity: "Sasia e produktit",
    isThisProductTaxed: "A tatohet ky produkt?",
    selectTaxType: "Zgjidhni llojin e taksës",
    pleaseSelectTaxType: "Ju lutemi zgjidhni Llojin e Taksës",
    progressive: "Progresive",
    proportional: "Proporcional",
    pleaseEnterProductTaxRate:
      "Ju lutemi shkruani normën e taksës së produktit",
    doesProductHaveVariants: "A ka ky produkt variante?",
    type: "Lloji",
    value: "Vlerë",
    pleaseEnterVariantType: "Ju lutemi shkruani llojin e variantit",
    pleaseEnterVariantValue: "Ju lutemi shkruani vlerën e variantit",
    pleaseEnterVariantPrice: "Ju lutemi shkruani çmimin e variantit",
    pleaseEnterVariantQuantity: "Ju lutemi shkruani sasinë e variantit",
    productDeletedSuccessfully: "Produkti është fshirë me sukses!",
    categoryDeletedSuccessfully: "Kategoria u fshi me sukses!",
    toDeleteThisProduct: "për të fshirë këtë Produkt",
    invalidProductQuantity: "Sasia e pavlefshme e produktit",
    quantityAddedIsOverStock:
      "Sasia që po shtoni është mbi atë që keni në magazinë.",
    itemInventoryNotTracked: "Inventari i artikullit nuk gjurmohet",
    addBulkQuantity: "Shto sasi në masë",
    enterBulkQuantity: "Fut sasinë në masë",
    pleaseEnterBulkQuantity: "Ju lutemi shkruani sasinë në masë",
    youveSuccessfullyAddedANewProduct:
      "Ju keni shtuar me sukses një produkt të ri!",
    pleaseEnterProductSellingPrice:
      "Ju lutemi shkruani çmimin e shitjes së produktit",
    doYouWantToTrackProductStock: "Dëshironi të gjurmoni stokun e produktit?",
    sellingPrice: "Çmimi i shitjes",
    setProductExpiryReminder: "Vendosni kujtuesin e skadimit të produktit",
    productExpiryDate: "Data e skadimit të produktit",
    startRemindingFrom: "Filloni të kujtoni nga",
    productSuppliesAddedSuccessfully:
      "Ju i keni shtuar furnizimet e produktit me sukses.",
    addProductSupplies: "Shtoni Furnizime me Produkte",
    pleaseSelectSupplier: "Ju lutemi zgjidhni Furnizuesin",
    nameOfProduct: "Emri i Produktit",
    pleaseSelectProduct: "Ju lutemi zgjidhni produktin",
    productVariant: "Varianti i produktit",
    pleaseSelectAVariant: "Ju lutemi zgjidhni një variant",
    pleaseEnterUnitPrice: "Ju lutemi shkruani Çmimin e Njësisë",
    businessBranch: "Dega e Biznesit",
    pleaseSelectBranch: "Ju lutemi zgjidhni degën",
    youveSuccessfullyAddedANewSupplier:
      "Ju keni shtuar me sukses një furnizues të ri",
    addSupplier: "Shto Furnizuesin",
    pleaseEnterSupplierEmail: "Ju lutemi shkruani emailin e furnizuesit",
    pleaseAddADescription: "Ju lutemi shtoni një përshkrim",
    anErrorOccuredProductsDeleted:
      "Ndodhi një gabim gjatë kryerjes së operacionit. Ju lutemi vini re, disa produkte mund të jenë fshirë gjatë procesit",
    bulkDelete: "Fshi në masë",
    youAreAboutToDelete: "Ju jeni gati për të fshirë",
    product: "Produkt",
    isDueToRedeem: "është për tu shpenguar",
    aReward: "nje shperblim",
    pleaseSelectCustomerToReeemReward:
      "Ju lutemi zgjidhni një klient për të shpërblyer shpërblimin.",
    youHaveNoLoyaltyProgramRunning:
      "Nuk keni asnjë program aktiv besnikërie që po funksionon",
    customerHhasNoPointsInLoyaltyProgram:
      "Klienti nuk ka pikë në këtë program besnikërie",
    proceedWithPayment: "Vazhdoni me Pagesën?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ju jeni gati të paguani për transaksionin duke përdorur pikë.",
    customerHas: "Klienti ka",
    worth: "me vlerë",
    andIsNotSufficientToPayForTransaction:
      "dhe nuk është e mjaftueshme për të paguar për këtë transaksion. A duan ata të shtojnë bilancin duke përdorur një mënyrë tjetër pagese?",
    addCustomerLoyalty: "Shtoni Besnikërinë e Klientit",
    pleaseAddCustomerFirst: "Ju lutemi shtoni ose zgjidhni një klient së pari.",
    pleaseWaitWhileWeGetReady: "Ju lutemi prisni, derisa të bëhemi gati",
    lowStock: "Aksion i ulët",
    pleaseEnterAmountTendered: "Ju lutemi shkruani shumën e tenderuar",
    areYouSureToBookSaleOffline:
      "Je i sigurt që dëshiron ta rezervosh këtë shitje jashtë linje?",
    saleWouldBeBookedAutomatically:
      "Shitja do të rezervohej automatikisht kur të ndizni internetin",
    offlineSalesBookingCancelled: "Rezervimi i shitjeve jashtë linje u anulua",
    covid19Message:
      "COVID19: Lani duart me sapun ose dezinfektoni për të ndaluar përhapjen. Qëndroni të Sigurt Gjithmonë",
    saleSuccessfullyRecorded: "Shitja u regjistrua me sukses!",
    sendReceiptToEmail: "Dërgo faturën në Email",
    sendThankYouSms: "Dërgo SMS falenderimi",
    sendShippingDetails: "Dërgo detajet e Transportit",
    addLoyalty: "Shtoni Besnikërinë",
    searchCustomerNameOrNumber: "Kërkoni emrin ose numrin e klientit",
    clickTheSearchCustomerBox:
      "Klikoni në Kutinë e Kërkimit të Klientit dhe Kartën e Skanimit",
    enterProductPrice: "Shkruani Çmimin e Produktit",
    enterPriceFor: "Shkruani Çmimin për",
    searchForProduct: "Kërkoni për produktin",
    all: "Te gjitha",
    backToDashboard: "Kthehu te Paneli i Kontrollit",
    viewDraftSales: "Shikoni Draft Shitjet",
    variantSelected: "varianti i zgjedhur",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Zgjedhja e variantit të mëparshëm nuk është e disponueshme për variantin e ri të zgjedhur në bazë të çmimit, ju lutemi ndryshoni zgjedhjen tuaj.",
    pleaseSupplyPositiveQuantityNumber:
      "Ju lutemi jepni një numër sasie postive",
    lowStockFor: "Stok i ulët për",
    clearVariants: "Variante të qarta",
    pleaseEnterQuantity: "Ju lutemi shkruani Sasinë",
    picture: "Foto",
    redemptionToken: "Shenja e shpengimit",
    token: "Shenja",
    totalSpent: "Totali i shpenzuar",
    confirmPayment: "Konfirmo Pagesën",
    hasPaymentBeenMade: "A është përpunuar pagesa me sukses?",
    enterTransactionReference:
      "Vendosni referencën e transaksionit me të cilën keni paguar",
    pleaseSelectACustomer: "Ju lutemi zgjidhni një klient!",
    areYouSureToApplyDiscount:
      "A jeni i sigurt që dëshironi të aplikoni zbritje?",
    addYourBankAccountToEnableUssd:
      "Shtoni llogarinë tuaj bankare për të aktivizuar transferimin e menjëhershëm të USSD me uPay",
    totalAmountToPay: "Shuma totale për të paguar",
    doYouWantToCancelTransaction: "Dëshironi të anuloni këtë transaksion?",
    savePrintBill: "Ruani/Shtypni Faturën",
    enterAmountCollectedForCustomer: "Futni shumën e mbledhur për klientin",
    recordSale: "Shitje rekord",
    checkOutWith: "Kontrolloni me",
    instantTransfer: "Transferim i menjëhershëm",
    dialTheUSSDCode: "Thirrni kodin USSD",
    pleaseSelectABank: "Ju lutemi zgjidhni një bankë",
    payWithUSSD: "Paguani me USSD",
    sendBillTo: " - Dërgo Bill në",
    waitingForUSSDTransfer: "Duke pritur për transferimin USSD",
    percent: "Përqindja",
    applyDiscount: "Aplikoni Zbritje",
    thisBillHasBeenSaved: "Ky projektligj është ruajtur",
    saveDraft: "Ruaj draftin",
    pleaseTypeANameToIdentifyCustomer:
      "Ju lutemi shkruani një emër për të identifikuar klientin",
    paymentDetails: "Detajet e pagesës",
    basePrice: "Çmimi bazë",
    staff: "Stafi",
    subTotal: "Nën -total",
    durationMonths: "Kohëzgjatja (muaj)",
    selectADuration: "Zgjidhni një kohëzgjatje",
    oneMonth: "1 muaj",
    twoMonths: "2 muaj",
    threeMonths: "3 muaj",
    sixMonths: "6 Muaj",
    twelveMonths: "12 muaj",
    eighteenMonths: "18 Muaj",
    twentyFourMonths: "24 Muaj",
    twoMonthsFree: "(2 muaj falas)",
    threeMonthsFree: "(3 muaj falas)",
    fiveMonthsFree: "(5 muaj falas)",
    yourAccountHasBeen: "Llogaria juaj ka qenë",
    renewed: "rinovuar",
    upgraded: "i përmirësuar",
    successfully: "me sukses",
    yourSubscription: "Abonimi juaj",
    youAreCurrentlyEnrolledOnA: "Aktualisht jeni regjistruar në një",
    pleaseChooseAPaymentOption: "Ju lutemi zgjidhni një opsion pagese",
    planRenewal: "Rinovimi i Planit",
    planUpgrade: "Plani i Përmirësimit",
    pleaseSelectDurationToPayFor:
      "Ju lutemi zgjidhni kohëzgjatjen për të cilën keni ndërmend të paguani",
    staffAccounts: "Llogaritë e stafit",
    ecommerce: "Tregti elektronike",
    pleaseSelectAPlan: "Ju lutemi zgjidhni një plan",
    includeAddons: "Përfshi Shtesa",
    viewTransactions: "Shikoni Transaksionet",
    customerHasNoCompletedTansactions:
      "Konsumatori nuk ka përfunduar ende asnjë kundërveprim",
    branch: "Dega",
    enterNumberOfEcommerceBranches:
      "Shkruani numrin e degëve të tregtisë elektronike",
    enterNumberOfEcommerceBranchesToPay:
      "Shkruani numrin e degëve të tregtisë elektronike për të cilat keni ndërmend të paguani",
    ecommerceIntegration: "Integrimi i Ecommerce",
    enterNumberOfBranches: "Shkruani numrin e degëve",
    enterNumberOfAdditionalBranchesToPay:
      "Vendosni numrin e degëve shtesë për të cilat keni ndërmend të paguani",
    enterNumberOfStaffs: "Shkruani numrin e stafit",
    enterNumberOfStaffsToPayFor:
      "Vendosni numrin e stafit për të cilin keni ndërmend të paguani",
    discountApplies: "Zbritja Zbatohet",
    starsOnThe: "yjet në",
    offer: "ofroj",
    get: "Marr",
    moreStarsToRedeem: "më shumë yje për të shpenguar",
    taxVat: "Taksa (TVSH)",
    callCashierToCompletePayment: "Thirrni arkëtarin për të përfunduar pagesën",
    receipt: "Faturë",
    dear: "I dashur",
    thankYouForYourOrderFindGoods:
      "Faleminderit per porosine. Ju lutemi gjeni mallrat e mëposhtëm të furnizuar, siç është rënë dakord.",
    shippingNote: "Shënim i Transportit",
    enterShippingNote: "Futni Shënimin e Transportit",
    shippingAddress: "Adresa e transportit",
    enterShippingAddress: "Shkruani adresën e Transportit",
    wellDoneYouAreDueToRedeem: "Te lumte! Ju jeni duke u shpenguar",
    toGetYourRewardNextVisit:
      "për të marrë shpërblimin tuaj në vizitën tuaj të radhës. Faleminderit",
    pointsOnThe: "Pikat në",
    morePointsToRedeem: "më shumë pikë për të shpenguar",
    showCode: "Shfaq kodin",
    toGetYourRewardOnNextVisit:
      "për të marrë shpërblimin tuaj në vizitën tuaj të radhës. Faleminderit",
    earn: "Fito",
    delivaryNote: "Shënim i dorëzimit",
    draftSales: "Draft Shitje",
    startDate: "Data e fillimit",
    endDate: "Data e përfundimit",
    orders: "Porositë",
    checkout: "arkë",
    noProductItem: "Asnjë artikull produkti",
    selectProductImage: "Zgjidhni Imazhin e Produktit",
    selectCountry: "Zgjidh Shtetin",
    selectRegion: "Zgjidhni Shtetin/Rajonin",
    printProductTag: "Shtyp etiketën e produktit",
    transactionReference: "Referenca e transaksionit",
    Cashier: "Arkëtar",
    Manager: "Menaxher",
    Owner: "Pronar",
    Admin: "Administratori",
  },
  Amharic: {
    cashier: "ገንዘብ ተቀባይ",
    manager: "ሥራ አስኪያጅ",
    owner: "ባለቤት",
    online: "በመስመር ላይ",
    offline: "ከመስመር ውጭ",
    changePassword: "የሚስጥር ቁልፍ ይቀይሩ",
    currentPasswordMessage: "እባክዎ የአሁኑን የይለፍ ቃልዎን ያስገቡ",
    passwordMessage: "እባክዎ የይለፍ ቃልዎን ያስገቡ",
    currentPassword: "የአሁኑ ሚስጥራዊ ማለፊያ ቁልፍ",
    password: "ፕስወርድ",
    confirmPasswordMessage: "እባክዎ የይለፍ ቃልዎን ያረጋግጡ!",
    confirmPassword: "የይለፍ ቃል አረጋግጥ",
    sendViaEmail: "በኢሜል በኩል ይላኩ",
    sendViaWhatsapp: "በ WhatsApp በኩል ይላኩ",
    downloadPdf: "ፒዲኤፍ ያውርዱ",
    paid: "ተከፍሏል",
    unpaid: "ያልተከፈለ",
    partial: "ከፊል",
    closeInvoice: "ደረሰኙን መዝጋት ይፈልጋሉ?",
    closeInvoiceConfirmation: "ደረሰኝ ላይቀመጥ ይችላል። መዝጋት ይፈልጋሉ?",
    yes: "አዎ",
    no: "አይ",
    invoice: "ደረሰኝ",
    wasDeducted: "ተቀናሽ ተደርጓል",
    for: "ለ",
    item: "ንጥል",
    addProduct: "ምርት ያክሉ",
    paymentReference: "የክፍያ ማጣቀሻ",
    amountPaid: "የተከፈለ መጠን",
    discountAmount: "የቅናሽ መጠን",
    amountDue: "የሚከፈለው መጠን",
    amount: "መጠን",
    dueDate: "የመጨረሻ ማስረከቢያ ቀን",
    paymentType: "የክፍያ ዓይነት",
    card: "ካርድ",
    cash: "ጥሬ ገንዘብ",
    bankTransfer: "የባንክ ማስተላለፍ",
    paymentMessage: "የክፍያ መልእክት",
    description: "መግለጫ",
    sendReceipt: "ደረሰኝ ይላኩ",
    delete: "ሰርዝ",
    save: "አስቀምጥ",
    resend: "እንደገና ላክ",
    saveAndSend: "አስቀምጥ",
    invoiceSaved: "ደረሰኝ ተቀምጧል!",
    selectPaymentMethod: "እባክዎን የክፍያ ዘዴ ይምረጡ!",
    selectCustomer: "እባክዎን አንድ ደንበኛ ይምረጡ!",
    cartEmptyError:
      "የጋሪ ዝርዝር ባዶ ሊሆን አይችልም ፣ የክፍያ መጠየቂያውን ይዝጉ እና ዕቃውን ወደ ጋሪ ያክሉ!",
    subscriptionExpired:
      "የእርስዎ የደንበኝነት ምዝገባ ጊዜው አልፎበታል እና የእርስዎ መለያ አሁን የተገደበ ነው። መለያዎን ለማደስ ከዚህ በታች ያለውን አዝራር ጠቅ ያድርጉ",
    renew: "አድስ",
    holdOn: "ቆይ",
    customerBank: "የደንበኛ ባንክ",
    cancel: "ሰርዝ",
    selectACustomer: "ደንበኛን ይምረጡ",
    invoiceSuccessfulPdfError:
      "የክፍያ መጠየቂያ በተሳካ ሁኔታ ተፈጥሯል ፣ ግን የፒዲኤፍ ትውልድ ከተለመደው የበለጠ ጊዜ እየወሰደ ነው። እባክዎን በቅርቡ ተመልሰው ይመልከቱ።",
    downloadingInvoice: "ደረሰኝ በማውረድ ላይ",
    downloadingReceipt: "ደረሰኝ በማውረድ ላይ",
    whatsappReceiptError:
      "በ WhatsApp በኩል ደረሰኙን መላክ ላይ አንድ ስህተት ተከስቷል ፣ እባክዎ እንደገና ይሞክሩ።",
    receiptToWhatsapp: "ደረሰኝ ወደ ዋትሳፕ ተላልedል",
    thankYouForPatronage: "ለደጋፊነትዎ እናመሰግናለን",
    hereIsYourReceipt: "የክፍያ ደረሰኝዎ እዚህ አለ",
    errorSendingEmailReceipt:
      "ደረሰኙን በኢሜል መላክ ላይ አንድ ስህተት ተከስቷል ፣ እባክዎ እንደገና ይሞክሩ ወይም ድጋፍን ያነጋግሩ",
    receiptSentToEmail: "ደረሰኝ ለደንበኛው ኢሜል ተልኳል",
    invoiceSentToEmail: "ደረሰኝ ለደንበኛ ኢሜል ተልኳል",
    invoiceSuccessWhatsappError:
      "የክፍያ መጠየቂያ በተሳካ ሁኔታ ተፈጥሯል ነገር ግን ወደ WhatsApp በመላክ ላይ ስህተት ተከስቷል። በክፍያ መጠየቂያ ዝርዝር ላይ እንደገና ይሞክሩ",
    invoiceSuccessfulEmailError:
      "ደረሰኝ በተሳካ ሁኔታ ተፈጥሯል ፣ ግን እንደ ኢ-ሜል በመላክ ላይ ስህተት ተከስቷል። እባክዎ ከሂሳብ መጠየቂያ ዝርዝር እንደገና ይሞክሩ",
    invoiceSentToWhatsapp: "ደረሰኝ ወደ ዋትሳፕ ተላልedል",
    hello: "ሰላም",
    pleaseDownloadInvoice: "እባክዎን የክፍያ መጠየቂያ ያውርዱ",
    pleaseDownloadReceipt: "እባክዎን ደረሰኝ ያውርዱ",
    from: "ከ",
    email: "ኢሜል",
    upgrade: "ያልቁ",
    youAreOnFreePlan: "በነፃ ዕቅድ ላይ ነዎት።",
    clickOn: "ጠቅ ያድርጉ",
    yourPlanInFewSteps: " በጥቂት ፈጣን ደረጃዎች ውስጥ የደንበኝነት ምዝገባዎ።",
    to: "ወደ",
    yourSubscriptionHasExpired:
      "የእርስዎ የደንበኝነት ምዝገባ ጊዜው አልፎበታል እና የእርስዎ መለያ አሁን የተገደበ ነው።",
    days: "ቀናት",
    yourSubscriptionExpiresIn: "የእርስዎ Loystar የደንበኝነት ምዝገባ ጊዜው ያበቃል",
    createAcount: "መለያ ይፍጠሩ",
    signIn: "ስግን እን",
    continue: "ቀጥል",
    enterOtp: "የኦቲፒ ፒን ያስገቡ",
    enterValidOtp: "እባክዎ የሚሰራ ፒን ያስገቡ",
    pin: "ፒን",
    tokenSentToMail: "ምልክት ወደ ኢሜልዎ ተልኳል",
    passwordResetSuccessful: "የይለፍ ቃል ዳግም ማስጀመር ተሳክቷል",
    somethingWentWrong: "የሆነ ስህተት ተከስቷል!",
    resetPassword: "የይለፍ ቃል ዳግም ያስጀምሩ",
    iHaveResetCode: "የይለፍ ቃል ዳግም ማስጀመሪያ ኮድ አለኝ",
    pleaseEnterEmail: "እባክዎን ኢሜልዎን ያስገቡ",
    aTokenWillBeSentToEmail: "ምልክት ወደ ኢሜልዎ ይላካል",
    enterEmail: "የእርስዎን ኢሜይል ያስገቡ",
    sendinYourToken: "ማስመሰያዎን በመላክ ላይ ...",
    makeSureItMatchesPassword: "ከአዲሱ የይለፍ ቃልዎ ጋር የሚዛመድ መሆኑን ያረጋግጡ",
    pleaseChooseNewPassword: "እባክዎ አዲስ የይለፍ ቃል ይምረጡ",
    chooseNewPassword: "አዲስ የይለፍ ቃል ይምረጡ",
    enterNewPassword: "ለማረጋገጥ አዲሱን የይለፍ ቃልዎን ያስገቡ",
    enterTokenSent: "ደብዳቤዎን የተላከውን ማስመሰያ ያስገቡ",
    resetToken: "ማስመሰያ ዳግም አስጀምር",
    resettingPassword: "የይለፍ ቃልዎን ዳግም በማስጀመር ላይ ...",
    signUp: "ክፈት",
    adminSignInWithEmail: " ሰራተኛው በተጠቃሚ ስም ሲገባ አስተዳዳሪ በኢሜል ሲገባ።",
    pleaseEnterEmailOrUsername: "እባክዎን ኢሜልዎን ወይም የተጠቃሚ ስምዎን ያስገቡ",
    emailOrUsername: "ኢሜል ወይም የተጠቃሚ ስም",
    pleaseEnterPassword: "እባክዎ የይለፍ ቃል ያስገቡ",
    createAnAccount: "መለያ ይፍጠሩ",
    forgotPassword: "መክፈቻ ቁልፉን ረሳኽው?",
    enterPhoneNumber: "ስልክ ቁጥር ያስገቡ",
    personalData: "የግል መረጃ",
    validateConfirmationCOde: "የማረጋገጫ ኮድ ያረጋግጡ",
    pleaseEnterFirstName: "እባክዎን የመጀመሪያ ስምዎን ያስገቡ",
    pleaseEnterPhoneNumber: "እባክዎ ስልክ ቁጥርዎን ያስገቡ",
    pleaseEnterLastName: "እባክዎ የመጨረሻ ስምዎን ያስገቡ",
    pleaseEnterBusinessName: "እባክዎ የንግድዎን ስም ያስገቡ",
    firstName: "የመጀመሪያ ስም",
    lastName: "ያባት ስም",
    businessName: "የንግድ ስም",
    previous: "ቀዳሚ",
    next: "ቀጥሎ",
    pleaseSelectBusinessCategory: "እባክዎ የንግድዎን ምድብ ይምረጡ",
    pleaseEnterValidEmail: "እባክዎ የሚሰራ ኢሜል ያስገቡ",
    pleaseEnterPostCode: "እባክዎ የፖስታ ኮድ ያስገቡ",
    postCode: "የፖስታ ኮድ",
    phoneNumberInUse: "ይህ ስልክ ቁጥር አስቀድሞ ስራ ላይ ውሏል!",
    emailInUse: "ይህ ኢሜል ቀድሞውኑ ጥቅም ላይ ውሏል!",
    foodAndDrinks: "ምግብ እና መጠጦች",
    salonAndBeauty: "ሳሎን እና ውበት",
    fashionAndAccessories: "ፋሽን እና መለዋወጫዎች",
    gymAndFitness: "ጂምና የአካል ብቃት እንቅስቃሴ",
    travelAndHotel: "ጉዞ እና ሆቴል",
    homeAndGifts: "ቤት እና ስጦታዎች",
    washingAndCleaning: "ማጠብ እና ማጽዳት",
    gadgetsAndElectronics: "መግብሮች እና ኤሌክትሮኒክስ",
    groceries: "የምግብ ዕቃዎች",
    others: "ሌሎች",
    submit: "አስረክብ",
    accountCreatedSuccessful: "የእርስዎ መለያ በተሳካ ሁኔታ ተፈጥሯል።",
    pleaseEnterAddress: "እባክዎን አድራሻዎን ያስገቡ",
    addressLine1: "አድራሻ መስመር 1",
    addressLine2: "የአድራሻ መስመር 2",
    choosePassword: "የይለፍ ቃል ይምረጡ",
    passwordMustBe6Characters: "የይለፍ ቃል ቢያንስ 6 ቁምፊዎች መሆን አለበት።",
    howDidYouHearLoystar: "ስለ ሎይስታር እንዴት ሰማህ?",
    referralCode: "የማጣቀሻ ኮድ",
    byClickingTheButton: " ከታች ያለውን አዝራር ጠቅ በማድረግ ፣ በ",
    termsAndSevice: "ውሎች",
    wereCreatingAccount: "መለያዎን እየፈጠርን ነው",
    proceed: "ይቀጥሉ",
    verificationCodeMustBe6: "የማረጋገጫ ኮድ 6 አሃዞች መሆን አለበት",
    pleaseEnter6DigitCode: "እባክዎ 6 አሃዝ ኮድ ያስገቡ",
    enterVerificationCode: "የማረጋገጫ ኮድ ያስገቡ",
    resendToken: "ቶከን ዳግም ላክ",
    verify: "ያረጋግጡ",
    transactions: "ግብይቶች",
    todaySales: "የዛሬ ሽያጮች",
    salesHistory: "የሽያጭ ታሪክ",
    supplyHistory: "የአቅርቦት ታሪክ",
    new: "አዲስ",
    invoices: "ደረሰኞች",
    disbursements: "ክፍያዎች",
    offlineSales: "ከመስመር ውጭ ሽያጭ",
    products: "ምርቶች",
    customers: "ደንበኞች",
    multiLevelLoyalty: "ባለብዙ ደረጃ ታማኝነት",
    loyaltyPrograms: "የታማኝነት ፕሮግራሞች",
    members: "አባላት",
    appStore: "የመተግበሪያ መደብር",
    orderMenu: "የትዕዛዝ ምናሌ",
    settings: "ቅንብሮች",
    staffAndBranches: "ሠራተኞች እና ቅርንጫፎች",
    myAccount: "አካውንቴ",
    switchToSellMode: "ወደ የሽያጭ ሁኔታ ይቀይሩ",
    signOut: "ዛግተ ውጣ",
    getFreeSubscription: "ነፃ የደንበኝነት ምዝገባን ያግኙ",
    onlyNumbersAllowed: "ቁጥሮች ብቻ ይፈቀዳሉ",
    yourAccountMustBe10Digits: "የመለያ ቁጥርዎ 10 አሃዝ ቁጥር መሆን አለበት",
    yourBvnMustBe11: "የእርስዎ BVN 11 አሃዝ ቁጥር መሆን አለበት",
    pleaseSelectBank: "እባክዎ ባንክዎን ይምረጡ",
    selectYourBank: "ባንክዎን ይምረጡ",
    enterBankAccountNumber: "የባንክ ሂሳብ ቁጥር ያስገቡ",
    enterBvnNumber: "የእርስዎን BVN ያስገቡ",
    connectBankAccount: "የባንክ ሂሳብን ያገናኙ",
    passwordResetSuccessfulAndSignOut:
      "የእርስዎ የይለፍ ቃል በተሳካ ሁኔታ ዳግም ተጀምሯል። እንደገና ለመግባት ከታች መውጫ አዝራሩን ጠቅ ያድርጉ",
    enterCurrentPassword: "የአሁኑን የይለፍ ቃል ያስገቡ",
    pleaseEnterCurrentPassword: "እባክዎን የአሁኑን የይለፍ ቃል ያስገቡ",
    phoneNumber: "ስልክ ቁጥር",
    sex: "ወሲብ",
    dateOfBirth: "የትውልድ ቀን",
    state: "ግዛት",
    country: "ሀገር",
    loyaltyId: "የታማኝነት መታወቂያ",
    createdAt: "በ የተፈጠረ",
    noOfTransactionsMade: "የተደረጉ ግብይቶች ቁጥር",
    yourDownloadWillStart: "የእርስዎ ማውረድ በአንድ ደቂቃ ውስጥ ይጀምራል",
    exportCustomers: "ደንበኞችን ወደ ውጭ ይላኩ",
    youhaveSuccessfullyToppedUp: "የኤስኤምኤስ ክፍሎችዎን በተሳካ ሁኔታ ከፍ አድርገውታል።",
    youNowHave: "አሁን አለዎት",
    smsUnits: "የኤስኤምኤስ ክፍሎች",
    enterNumberOfSmsUnits: "ሊገዙት ያሰቡትን የኤስኤምኤስ ክፍሎች ቁጥር ያስገቡ",
    pleaseEnterANumericValue: "እባክዎ የቁጥር እሴት ያስገቡ",
    pay: "ይክፈሉ",
    accountEditedSuccessfully: "የእርስዎ መለያ በተሳካ ሁኔታ ተስተካክሏል።",
    youAeCurrentlyOn: "አሁን በርተዋል",
    plan: "እቅድ ያውጡ",
    userData: "የተጠቃሚ ውሂብ",
    businessData: "የንግድ መረጃ",
    businessCategory: "የቢዝነስ አባጨጓሬ",
    pleaseSelectCurrency: "እባክዎን ምንዛሬዎን ይምረጡ",
    selectYourCurrency: "ምንዛሬዎን ይምረጡ",
    purchaseMoreSmsUnits: "ከዚህ በታች ያለውን ቅጽ በመጠቀም ተጨማሪ የኤስኤምኤስ ክፍሎችን ይግዙ",
    youHave: "አለሽ",
    atLeast4SmsUnitsRrequired: "ለማረጋገጫ ቢያንስ 4 ኤስኤምኤስ ክፍሎች ያስፈልጋሉ ፣ እባክዎን ይሙሉ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "‹ደንበኛዎን ይወቁ› (KYC) መስፈርቶችን ለማክበር እባክዎ የባንክ ሂሳብዎን ያረጋግጡ። ይህ ክፍያዎችን በ USSD ወይም በአፋጣኝ ዝውውር እንዲሰበስቡ ፣ ከደንበኞች ትዕዛዞችን እንዲቀበሉ እና የግብይት እልባት እንዲያካሂዱ ያስችልዎታል። ለናይጄሪያ ነጋዴዎች ብቻ የሚተገበር። ለመጀመር ከዚህ በታች ያለውን ቁልፍ ይጫኑ።",
    reConnectBankAccount: "የባንክ ሂሳብን እንደገና ያገናኙ",
    accountName: "የአድራሻ ስም",
    accountNumber: "የመለያ ቁጥር",
    bankName: "የባንክ ስም",
    verified: "ተረጋግጧል",
    accountDetails: "የመለያ ዝርዝሮች",
    kycBankAccount: "ኪ.ሲ.ሲ",
    createTier: "ደረጃ ይፍጠሩ",
    fileUploadSuccessful: "ፋይል በተሳካ ሁኔታ ተሰቅሏል",
    fileUploadFailed: "ፋይል መስቀል አልተሳካም",
    createLoyaltyProgram: "የታማኝነት ፕሮግራም ይፍጠሩ",
    createLoyalty: "ታማኝነትን ይፍጠሩ",
    name: "ስም",
    loyaltyArtwork: "የታማኝነት ሥነ ጥበብ",
    clickToUpload: "ለመስቀል ጠቅ ያድርጉ",
    amountToPointsRatio: "ለ ነጥቦች ነጥቦች መጠን",
    points: "ነጥቦች",
    recommendedAmountToPointRatio:
      "የሚመከር - ከ 1 እስከ 1 ነጥብ። ማለትም ለሚያጠፋው እያንዳንዱ 1 ናይራ ደንበኞችዎ 1 ነጥብ ያገኛሉ",
    pleaseTypeIn: "እባክዎን ያስገቡ",
    toDeleteLoyalty: "ይህንን ታማኝነት ለመሰረዝ",
    deleteLoyalty: "ታማኝነትን ሰርዝ",
    toConfirm: "ለማረጋገጥ",
    edit: "አርትዕ",
    pointsAwardedSuccessfully: "ነጥቦች በተሳካ ሁኔታ ተሸልመዋል።",
    enterPointValueToAward: "ለደንበኞች ሽልማት መስጠት የሚፈልጉትን የነጥብ እሴት ያስገቡ",
    award: "ሽልማት",
    awardPointValuesToCustomer: "የሽልማት ነጥብ ዋጋ ለደንበኛ",
    enrollMembersToLoyalty: "አባላትን ወደ ታማኝነት ይመዝገቡ",
    awardPoints: "የሽልማት ነጥቦች",
    enroll: "ይመዝገቡ",
    home: "ቤት",
    loyalty: "ታማኝነት",
    enrollCustomers: "ደንበኞችን ይመዝገቡ",
    selected: "ተመርጧል",
    customer: "ደንበኛ",
    loyaltyActivationSuccessful: "የታማኝነት ማግበር ስኬታማ ነው።",
    loyaltyDeactivationSuccessful: "የታማኝነት ማቦዘን ስኬታማ ነው።",
    viewTier: "ደረጃን ይመልከቱ",
    deactivate: "ደ-አግብር",
    activate: "አግብር",
    actions: "እርምጃዎች",
    nameOfLoyalty: "የታማኝነት ስም",
    loyaltyStatus: "የታማኝነት ሁኔታ የታማኝነት ሁኔታ",
    numberOfTiers: "የቲርሶች ብዛት",
    createdOn: "በርቷል",
    createATier: "ደረጃ ይፍጠሩ",
    stopCreatingTierConfirmation: "ደረጃ መፍጠርን ማቆም ይፈልጋሉ?",
    stopEditingTierConfirmation: "ይህንን ደረጃ ማርትዕ ማቆም ይፈልጋሉ?",
    nameOfTier: "የደረጃ ስም",
    minimumSpendingTarget: "አነስተኛ የወጪ ግብ",
    maximumSpendingTarget: "ከፍተኛ የወጪ ግብ",
    minimumSpendingTargetRequired: "ዝቅተኛው የወጪ ግብ ተጠይቋል",
    maximumSpendingTargetRequired: "ከፍተኛው የወጪ ግብ ተጠይቋል",
    rewardSponsor: "የሽልማት ስፖንሰር",
    pleaseChooseARewardSponsor: "እባክዎን የሽልማት ስፖንሰር ይምረጡ",
    self: "ራስን",
    partner: "አጋር",
    rewardPartner: "የሽልማት አጋር",
    pleaseSelectRewardPartner: "እባክዎ የሽልማት አጋርዎን ይምረጡ",
    rewards: "ሽልማቶች",
    pleaseSelectAReward: "እባክዎ ሽልማት ይምረጡ",
    instructionsOnRedeemingReward: "ደንበኛ ሽልማትን እንዴት መመለስ እንዳለበት መመሪያ",
    pleaseFillInThisField: "እባክዎን ይህንን መስክ ይሙሉ!",
    toDeleteThisTier: " ይህንን ደረጃ ለመሰረዝ",
    deleteTier: "ደረጃን ሰርዝ",
    viewMembers: "አባላትን ይመልከቱ",
    membersEnrolled: "አባላት ተመዝግበዋል",
    instruction: "ትምህርት",
    membersIn: "ውስጥ ያሉ አባላት",
    availableTiersInLoyalty: "በታማኝነት ፕሮግራም ውስጥ የሚገኙ ደረጃዎች (ዎች)",
    tiers: "ተረቶች",
    totalTier: "ጠቅላላ ደረጃ",
    availableLoyaltyProgramme: "የሚገኝ የታማኝነት ፕሮግራም",
    totalLoyalties: "ጠቅላላ ታማኝነት",
    memberDetails: "የአባል ዝርዝሮች",
    nameOfCustomer: "የደንበኛ ስም",
    address: "አድራሻ",
    allEnrolledMembers: "ሁሉም የተመዘገቡ አባላት",
    thisIsToWishYouHappyBirthday:
      "ይህ በጣም መልካም የልደት ቀን እና የብልጽግና ሕይወት እንዲመኙልዎት ነው። ለሎይስተር ላደረጉት ሁሉ እናመሰግናለን። መልካም ክብረ በዓላት!",
    inputAnOfferPlease: "እባክዎን ቅናሽ ያስገቡ",
    pleaseSelectTheInsertPoint:
      "በመልዕክቱ ውስጥ ያለውን የማስገቢያ ነጥብ ይምረጡ እና እንደገና ጠቅ ያድርጉ",
    birthdayOfferAndMessage: "የልደት ቀን ስጦታ እና መልእክት",
    birthdayOffer: "የልደት ቀን አቅርቦት",
    birthdayMessage: "የልደት ቀን መልእክት",
    noOfferFound: "ምንም ቅናሽ አልተገኘም",
    settingABirthdayOffer:
      "የልደት ቀን ቅናሽ ማዘጋጀት ደንበኞች ይህንን ቅናሽ በልደት ቀን በኤስኤምኤስ እንዲቀበሉ ያስችላቸዋል",
    createOffer: "ቅናሽ ይፍጠሩ",
    whatIsTheOffer: "ቅናሹ ምንድነው?",
    editMessage: "መልዕክት አርትዕ",
    insert: "አስገባ",
    theNameOfCustomerInserted: "የደንበኛው ስም እዚህ ይገባል",
    theBirtdayOfferInserted: "የልደት ቀን አቅርቦቱ እዚህ ይገባል",
    youSuccessfullyAddedNewBranch: "አዲስ ቅርንጫፍ በተሳካ ሁኔታ አክለዋል!",
    addNewBranch: "አዲስ ቅርንጫፍ ያክሉ",
    addBranch: "ቅርንጫፍ ይጨምሩ",
    additionalBranchWillIncur: "ተጨማሪ ቅርንጫፍ ይከሰታል",
    perBranch: "በአንድ ቅርንጫፍ",
    ecommerceBranchCosts: "የኢኮሜርስ ቅርንጫፍ ወጪዎች",
    pleaseEnterBranchName: "እባክዎን የቅርንጫፉን ስም ያስገቡ",
    pleaseEnterBranchAddress1: "እባክዎን የቅርንጫፉን አድራሻ መስመር 1 ያስገቡ",
    enterBranchAddress1: "የቅርንጫፉን አድራሻ መስመር ያስገቡ 1",
    enterBranchAddress2: "የቅርንጫፉን አድራሻ መስመር ያስገቡ 1",
    pleaseEnterBranchAddress2: "እባክዎን የቅርንጫፉን አድራሻ መስመር 2 ያስገቡ",
    enterBranchName: "የቅርንጫፉን ስም ያስገቡ",
    successfullyAddedStaff: "አዲስ ሰራተኛ በተሳካ ሁኔታ አክለዋል!",
    addNewStaff: "አዲስ ሰራተኛ ያክሉ",
    addStaff: "ሠራተኛ ያክሉ",
    additionalStaffWillIncur: "ተጨማሪ ሠራተኞች ይገደዳሉ",
    perStaff: "በአንድ ሠራተኛ።",
    pleaseEnterStaffEmail: "እባክዎን የሰራተኞችን ኢሜል ያስገቡ",
    pleaseEnterStaffUsername: "እባክዎ የሰራተኞችን የተጠቃሚ ስም ያስገቡ",
    pleaseEnterStaffPassword: "እባክዎ የሰራተኞችን የይለፍ ቃል ያስገቡ",
    pleaseSelectStaffRole: "እባክዎን የሰራተኞችን ሚና ይምረጡ",
    selectStaffRole: "የሰራተኞችን ሚና ይምረጡ",
    enterStaffEmail: "የሰራተኞችን ኢሜል ያስገቡ",
    enterStaffUsername: "የሠራተኛውን የተጠቃሚ ስም ያስገቡ",
    enterStaffPassword: "የሰራተኞችን የይለፍ ቃል ያስገቡ",
    spacesNotAllowedInUsername: "በተጠቃሚ ስም ውስጥ ክፍተቶች አይፈቀዱም",
    admin: "አስተዳዳሪ",
    pleaseSelectBusinessToAttachStaff: "ሰራተኞችን ለማያያዝ እባክዎ ንግድ ይምረጡ",
    searchForBranchToAttachStaff: "ሠራተኞችን ለማያያዝ ቅርንጫፍ ይፈልጉ",
    username: "የተጠቃሚ ስም",
    role: "ሚና",
    areYouSureToDeleteThis: "ይህን ለመሰረዝ እርግጠኛ ነዎት",
    branches: "ቅርንጫፎች",
    upgradeYourPlan: "ዕቅድዎን ያሻሽሉ።",
    add: "አክል",
    addNew: "አዲስ አስገባ",
    customerWithEmailAlreadyExists: "ኢሜል/ስልክ ቁጥር ያለው ደንበኛ ቀድሞውኑ አለ!",
    successfullyAddedNewCustomer: "አዲስ ደንበኛን በተሳካ ሁኔታ አክለዋል!",
    addCustomer: "ደንበኛን ያክሉ",
    pleaseEnterCustomerFirstName: "እባክዎ የደንበኛውን የመጀመሪያ ስም ያስገቡ",
    pleaseEnterCustomerLastName: "እባክዎ የደንበኛውን የመጨረሻ ስም ያስገቡ",
    pleaseEnterCustomerPhoneNumber: "እባክዎ የደንበኛውን ስልክ ቁጥር ያስገቡ",
    pleaseEnterCustomerEmail: "እባክዎ የደንበኛውን ኢሜል ያስገቡ",
    pleaseEnterCustomerAddressLine: "እባክዎ የደንበኛውን አድራሻ መስመር ያስገቡ",
    pleaseEnterCustomerOtherAddress: "እባክዎ የደንበኛውን ሌላ አድራሻ ያስገቡ",
    pleaseSelectCustomerGender: "እባክዎ የደንበኛውን ጾታ ይምረጡ",
    gender: "ጾታ",
    male: "ወንድ",
    female: "ሴት",
    bank: "ባንክ",
    selectBank: "ባንክ ይምረጡ",
    stateOrRegionOrProvince: "ግዛት/ክልል/አውራጃ",
    customerNotes: "የደንበኛ ማስታወሻዎች",
    sendSms: "ኤስኤምኤስ ይላኩ",
    editCustomer: "ደንበኛን ያርትዑ",
    redeemReward: "ሽልማትን ያስመልሱ",
    issueLoyaltyCard: "የታማኝነት ካርድ ያውጡ",
    deleteCustomer: "ደንበኛን ሰርዝ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "የታማኝነት የአባልነት መታወቂያ በተሳካ ሁኔታ መድበዋል",
    noMembershipIdAvailable:
      "ምንም የአባልነት መታወቂያዎች የሉም። እባክዎን በ hello@loystar.co ላይ ያነጋግሩን",
    sendEmail: "ኢሜል ይላኩ",
    membershipPoints: "የአባልነት ነጥቦች",
    customerDetails: "የደንበኛ ዝርዝሮች",
    close: "ገጠመ",
    loyaltyBalance: "የታማኝነት ሚዛን",
    pointsBalance: "የነጥቦች ሚዛን",
    starBalance: "የኮከብ ሚዛን",
    requiredPoints: "አስፈላጊ ነጥቦች",
    requiredStars: "ተፈላጊ ኮከቦች",
    reddemCode: "ኮድ ማስመለስ",
    toDeleteThisCustomer: "ይህንን ደንበኛ ለመሰረዝ",
    customerHasBeenDeletedSuccessfully: "ደንበኛ በተሳካ ሁኔታ ተሰር !ል!",
    customerWithPhoneAlreadyExists: "ስልክ ቁጥር ያለው ደንበኛ አስቀድሞ አለ",
    customerWasSuccessfullyEdited: "ደንበኛ በተሳካ ሁኔታ ተስተካክሏል",
    anErrorOccured: "ስህተት ተከስቷል",
    phoneNumberIncludeCountry: "ስልክ ቁጥር (የአገር ኮድ ያካትቱ)",
    yourFileQueuedForUpload:
      "ፋይልዎ ለመስቀል ወረፋ ተይ hasል። እባክዎን ከጥቂት ሰከንዶች በኋላ ገጹን ያድሱ።",
    thereWasAnErrorPerformingOperation: "ቀዶ ጥገናውን ማከናወን ላይ ስህተት ነበር!",
    pleaseSelectFile: "እባክዎን ፋይል ይምረጡ!",
    oopsOnlyCsvFilesAllowed: "ውይ! የ CSV ፋይሎች ብቻ ተፈቅደዋል። እባክዎ የ .csv ፋይል ይስቀሉ።",
    fileShouldBeSmallerThan5Mb:
      "ፋይሉ ከ 5 ሜባ ያነሰ መሆን አለበት። ትልቅ ፋይል ካለዎት እባክዎን support@loystar.co ን በኢሜል ይላኩ። አመሰግናለሁ",
    customerFirstNameIsRequired: "የደንበኛ የመጀመሪያ ስም በረድፍ ላይ ያስፈልጋል",
    customerPhoneNumberIsRequired: "በተራ ቁጥር ላይ የደንበኛ ስልክ ቁጥር ያስፈልጋል",
    importCustomers: "ደንበኞችን ያስመጡ",
    upload: "ስቀል",
    clickIconToDownloadCsv: "የ CSV ፋይል አብነት ለማውረድ ይህንን አዶ ጠቅ ያድርጉ።",
    getGoogleSheetVersion: "የ Google ሉህ ሥሪት ያግኙ",
    clickOrDragFileToUpload: "ለመስቀል ፋይል ወደዚህ አካባቢ ጠቅ ያድርጉ ወይም ይጎትቱ",
    missingOrInvalidColumnHeader:
      "የጠፋ ወይም ልክ ያልሆነ የአምድ ራስጌ። እባክዎ የአብነት ቅርጸቱን ይከተሉ። አመሰግናለሁ.",
    youHaveImported: "አስመጥተዋል",
    youSuccessfullyRedeemedReward: "ሽልማትዎን በተሳካ ሁኔታ መልሰዋል!",
    sixDigitCode: "ባለ ስድስት አኃዝ ኮድ",
    pleaseEnterCustomerRewardCode: "እባክዎ የደንበኛን የሽልማት ኮድ ያስገቡ",
    enterRewardCode: "የሽልማት ኮድ ያስገቡ። (የሽልማት ኮዱ ለጉዳዩ ተጋላጭ ነው)",
    selectLoyaltyProgram: "የታማኝነት ፕሮግራም ይምረጡ",
    stamps: "ማህተሞች",
    smsUnitsLow: "የኤስኤምኤስ አሃዶች ዝቅተኛ",
    pleaseTopUpSmsUnits: "እባክዎን የኤስኤምኤስ ክፍሎችን ከፍ ያድርጉ",
    smsSuccessfullySentForDelivery: "ኤስኤምኤስ ለማድረስ በተሳካ ሁኔታ ተልኳል!",
    sendSmsTo: "ኤስ ኤም ኤስ ይላኩ",
    allCustomers: "ሁሉም ደንበኞች",
    unitsAvailable: "አሃዶች ይገኛሉ",
    pleaseTypeInTheMessage: "እባክዎን መልዕክቱን ይተይቡ",
    message: "መልዕክት",
    charactersRemaining: "ቁምፊዎች ይቀራሉ",
    avoidUseOfSpecialCharacters:
      "የኤስኤምኤስ ክፍሎችን ለመጠበቅ ልዩ ቁምፊዎችን እና ኢሞጂዎችን ከመጠቀም ይቆጠቡ።",
    note: "ማስታወሻ",
    errorFetchingCustomersMultilevelDetail:
      "ባለብዙ ደረጃ ዝርዝሮችን ደንበኞችን ማምጣት ላይ ስህተት",
    search: "ይፈልጉ",
    reset: "ዳግም አስጀምር",
    importCustomer: "ደንበኛ ያስመጡ",
    addNewCustomer: "አዲስ ደንበኛ ያክሉ",
    sendSmsBroadcast: "የኤስኤምኤስ ስርጭት ላክ",
    totalCustomers: "ጠቅላላ ደንበኞች",
    disbursementDetails: "የክፍያ ዝርዝሮች",
    paidBy: "የተከፈለበት",
    disbursed: "የተሰጠ",
    bankAccountName: "የባንክ ሂሳብ ስም",
    bankAccountNumber: "የባንክ ሂሳብ ቁጥር",
    transferInitiated: "ማስተላለፍ ተጀመረ",
    transferCompleted: "ዝውውሩ ተጠናቋል",
    pleaseEnterAValid: "እባክዎ ልክ የሆነ ያስገቡ",
    begin: "ጀምር",
    end: "አበቃ",
    date: "ቀን",
    paymentDate: "የክፍያ ቀን",
    selectDisbursementDuration: "የአከፋፈል ጊዜን ይምረጡ",
    totalSettled: "ጠቅላላ ተረጋግቷል",
    totalUnsettled: "ጠቅላላ ያልተረጋጋ",
    toDeleteThisSale: "ይህንን ሽያጭ ለመሰረዝ",
    draftSaleDeletedSuccessfully: "ረቂቅ ሽያጭ በተሳካ ሁኔታ ተሰር !ል!",
    deleteSale: "ሽያጭን ሰርዝ",
    pleaseTypeInYourTotal: "ስረዛን ለማረጋገጥ እባክዎ ጠቅላላዎን ይተይቡ",
    billDetails: "የሂሳብ ዝርዝሮች",
    printBill: "የህትመት ሂሳብ",
    servedBy: "አገልግሏል",
    total: "ጠቅላላ",
    createdDate: "የተፈጠረ ቀን",
    createdTime: "ጊዜ ተፈጥሯል",
    status: "ሁኔታ",
    loginSuccessful: "መግቢያ ተሳክቷል",
    pleaseWaitWhileWeConnectAccount: "እባክዎ መለያዎን እስክናገናኝ ድረስ ይጠብቁ",
    connectionFailedTryAgain: "ግንኙነት አልተሳካም. እባክዎ ዳግም ይሞክሩ.",
    connectionSuccessful: "ግንኙነት ተሳክቷል",
    viewDetails: "ዝርዝሮችን ይመልከቱ",
    enable: "አንቃ",
    free: "ፍርይ",
    cost: "ወጪ",
    visitWebsite: "ድር ጣቢያ ይጎብኙ",
    pleaseUpgradeYourPlanToPro:
      "ይህንን መተግበሪያ ለማንቃት እባክዎን ዕቅድዎን ወደ Pro Plus ያሻሽሉ",
    connectYourBankAccount: "ክፍያዎችን ለመቀበል የባንክ ሂሳብዎን ያገናኙ።",
    disable: "አሰናክል",
    enableApp: "መተግበሪያን ያንቁ",
    addNewProductToInvoice: "አዲስ ምርት ወደ ደረሰኝ ያክሉ",
    toDeleteThisInvoice: "ይህንን የክፍያ መጠየቂያ ለመሰረዝ",
    invoiceDeletedSuccessfully: "የክፍያ መጠየቂያ በተሳካ ሁኔታ ተሰር !ል!",
    deleteInvoice: "የክፍያ መጠየቂያ ይሰርዙ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "የክፍያ መጠየቂያ የክፍያ መልእክት በተሳካ ሁኔታ አክለዋል!",
    pleaseEnterPaymentInstructions: "እባክዎ የክፍያ መመሪያዎችን ያስገቡ",
    invoiceId: "የክፍያ መጠየቂያ መታወቂያ",
    paidDate: "የተከፈለበት ቀን",
    reference: "ማጣቀሻ",
    productAdded: "ምርት ታክሏል",
    productOutOfStock: "ምርት አልቋል። እባክዎ እንደገና ያከማቹ።",
    totalInvoices: "ጠቅላላ ጥሰቶች",
    totalPaidInvoices: "ጠቅላላ የሚከፈልባቸው ኢንቮይሶች",
    totalUnpaidInvoices: "ጠቅላላ ያልተጠበቁ ጥሰቶች",
    loyaltyProgramDeleted: "የታማኝነት ፕሮግራም ተሰር .ል",
    thisLoyaltyProgramDeletedSuccessfully: "ይህ የታማኝነት ፕሮግራም በተሳካ ሁኔታ ተሰር hasል",
    thisLoyaltyProgramEditedSuccessfully: "ይህ የታማኝነት ፕሮግራም በተሳካ ሁኔታ ተስተካክሏል",
    loyaltyProgramAddedSuccessfully: "የታማኝነት ፕሮግራም በተሳካ ሁኔታ ታክሏል",
    loyaltyProgramEdited: "የታማኝነት ፕሮግራም ተስተካክሏል",
    loyaltyProgramAdded: "የታማኝነት ፕሮግራም ታክሏል",
    loyaltyDetails: "የታማኝነት ዝርዝሮች",
    doYouWantToCloseDialog: "ይህን መገናኛ መዝጋት ይፈልጋሉ?",
    pleaseEnterLoyaltyName: "እባክዎን የታማኝነትዎን ስም ያስገቡ",
    programType: "የፕሮግራም ዓይነት",
    pleaseSelectProgramType: "እባክዎን የፕሮግራም ዓይነት ይምረጡ",
    simplePoints: "ቀላል ነጥቦች",
    stampsProgram: "ማህተሞች ፕሮግራም",
    threshold: "ደፍ",
    pleaseEnterLoyaltyThreshold: "እባክዎን የታማኝነት ደረጃን ያስገቡ",
    reward: "ሽልማት",
    pleaseEnterLoyaltyReward: "እባክዎን የታማኝነትን ሽልማት ያስገቡ",
    totalUserPoints: "ጠቅላላ የተጠቃሚ ነጥቦች",
    totalUserStamps: "ጠቅላላ የተጠቃሚ ማህተሞች",
    spendingTarget: "ግብ ማውጣት",
    spendingTargetHint1:
      "የወጪ ዒላማ ደንበኛው ሽልማቱን ለማግኘት ምን ያህል ማውጣት እንዳለበት ነው። 1 የምንዛሬ ዋጋ = 1 ነጥብ።",
    spendingTargetHint2:
      "ማህተሞች ኢላማ አንድ ደንበኛ ሽልማቱን ለማግኘት ስንት ማህተሞችን መሰብሰብ አለበት። ለምሳሌ. 5",
    addNewLoyaltyProgram: "አዲስ የታማኝነት ፕሮግራም ያክሉ",
    addLoyaltyProgram: "የታማኝነት ፕሮግራም ያክሉ",
    loyaltyProgramType: "የታማኝነት ፕሮግራም ዓይነት",
    pleaseSelectLoyaltyProgramType: "እባክዎ የታማኝነት ፕሮግራም ዓይነት ይምረጡ",
    nameOfProgram: "የፕሮግራሙ ስም",
    pleaseEnterProgramName: "እባክዎን የፕሮግራሙን ስም ያስገቡ",
    whatIsTheReward: "ሽልማቱ ምንድነው?",
    egNextPurchaseIsFree: "ለምሳሌ የሚቀጥለው ግዢ ነፃ ነው",
    customerName: "የደንበኛ ስም",
    guestCustomer: "የእንግዳ ደንበኛ",
    orderConfirmedSuccessfully: "ትዕዛዝ በተሳካ ሁኔታ ተረጋግጧል",
    orderCancelledSuccessfully: "ትዕዛዝ በተሳካ ሁኔታ ተሰር .ል",
    confirmOrder: "ትዕዛዝ ያረጋግጡ",
    cancelOrder: "ትዕዛዝን ሰርዝ",
    allOrders: "ሁሉም ትዕዛዞች",
    totalOrders: "ጠቅላላ ትዕዛዞች",
    doYouWantToAcceptOrder: "ይህን ትዕዛዝ መቀበል ይፈልጋሉ?",
    doYouWantToCancelOrder: "ይህን ትዕዛዝ መሰረዝ ይፈልጋሉ?",
    orderDetails: "የትዕዛዝ ዝርዝሮች",
    orderCreatedAt: "ትዕዛዝ የተፈጠረው በ",
    supplier: "አቅራቢ",
    productName: "የምርት ስም",
    quantity: "ብዛት",
    unitPrice: "ነጠላ ዋጋ",
    receivedBy: "ተቀብሏል በ",
    reportFrom: "ሪፖርት ከ",
    totalSupplies: "ጠቅላላ አቅርቦቶች",
    allRightsReserved: "መብቱ በህግ የተጠበቀ ነው",
    toDeleteThisTransaction: "ይህንን ግብይት ለመሰረዝ",
    transactionDeletedSuccessfully:
      "ግብይት በተሳካ ሁኔታ ተሰር hasል። አክሲዮን ወደ ክምችት ተመልሷል።",
    deleteTransaction: "ግብይትን ሰርዝ",
    transactionDetails: "የግብይት ዝርዝሮች",
    printReceipt: "የህትመት ደረሰኝ",
    channel: "ሰርጥ",
    discount: "ቅናሽ",
    profit: "ትርፍ",
    discountedSales: "ቅናሽ የተደረገ ሽያጭ",
    errorFetchingRecord: "መዝገብ ማምጣት ላይ ስህተት",
    exportTransactions: "ግብይቶችን ወደ ውጭ ይላኩ",
    errorFetchingSalesRecord: "ለላኪ የሽያጭ መዝገብ ማምጣት ላይ ስህተት።",
    totalSellingPrice: "ጠቅላላ የሽያጭ ዋጋ",
    totalCostPrice: "ጠቅላላ ወጪ ዋጋ",
    appliedDiscount: "የተተገበረ ቅናሽ",
    noOfItems: "የእቃዎች ብዛት",
    sales: "ሽያጮች",
    export: "ወደ ውጭ ላክ",
    totalProfit: "ጠቅላላ ትርፍ",
    totalBalanceInPeriod: "በጠቅላላው የጊዜ ሚዛን",
    allTimeSales: "የሁሉም ጊዜ ሽያጭ",
    records: "መዝገቦች",
    todaysSales: "የዛሬ ሽያጮች",
    transaction: "ግብይት",
    youHaveExceededTotalNumberOfProducts:
      "በእቅድዎ ላይ የተፈቀዱትን ጠቅላላ ምርቶች ብዛት አልፈዋል። በከፍተኛ ወሰን ለመደሰት ዕቅድዎን ያሻሽሉ።",
    youNeedToHaveLoyaltyProgram: "ይህንን ባህሪ ለመጠቀም የታማኝነት ፕሮግራም ሊኖርዎት ይገባል!",
    price: "ዋጋ",
    category: "ምድብ",
    stockTracking: "የአክሲዮን ክትትል",
    stockCount: "የአክሲዮን ብዛት",
    taxed: "ግብር ተከፍሏል",
    originalPrice: "የመጀመሪያው ዋጋ",
    costPrice: "የወጪ ዋጋ",
    unit: "ክፍል",
    productImage: "የምርት ምስል",
    taxRate: "የግብር ተመን",
    taxType: "የግብር ዓይነት",
    trackInventory: "የትራክ ክምችት",
    variants: "ተለዋጮች",
    hasVariants: "ተለዋጮች አሉት",
    importProduct: "ምርት አስመጣ",
    exportProducts: "ምርቶችን ወደ ውጭ ላክ",
    addNewProduct: "አዲስ ምርት ያክሉ",
    viewCategory: "ምድብ ይመልከቱ",
    viewSuppliers: "አቅራቢዎችን ይመልከቱ",
    receiveInventory: "ክምችት ተቀበል",
    printAllProductsTag: "ሁሉንም ምርቶች መለያ ያትሙ",
    deleteAll: "ሁሉንም ሰርዝ",
    totalProducts: "ጠቅላላ ምርቶች",
    youveSuccessfullyAddedANewCategory: "አዲስ ምድብ በተሳካ ሁኔታ አክለዋል",
    addNewCategory: "አዲስ ምድብ ያክሉ",
    addCategory: "ምድብ ያክሉ",
    categoryName: "የምድብ ስም",
    pleaseEnterCategoryName: "እባክዎ የምድብ ስም ያስገቡ",
    stampsTarget: "ማህተሞች ዒላማ",
    sendInventory: "ክምችት ላክ",
    productDetails: "የምርት ዝርዝሮች",
    youveSuccessfullyEditedThisCategory: "ይህን ምድብ በተሳካ ሁኔታ አርትዖት አድርገዋል",
    update: "አዘምን",
    categoryList: "የምድብ ዝርዝር",
    categories: "ምድቦች",
    enterQuantityToUpdate: "ለማዘመን ብዛት ያስገቡ",
    inventorySentSuccessfully: "ቆጠራው በተሳካ ሁኔታ ተልኳል!",
    updateInventory: "ዕቃዎችን አዘምን",
    currentQuantity: "የአሁኑ ብዛት",
    pleaseEnterQuantityToAdd: "እባክዎን ማከል የሚፈልጉትን መጠን ያስገቡ",
    pleaseSelectABranch: "እባክዎ ቅርንጫፍ ይምረጡ",
    searchForBranch: "ቅርንጫፍ ይፈልጉ",
    youCantSendMoreThanStock: "በክምችት ውስጥ ካሉት በላይ መላክ አይችሉም",
    send: "ላክ",
    pleaseEnterQuantityToSend: "እባክዎን ለመላክ የሚፈልጉትን ብዛት ያስገቡ",
    productNameIsRequiredOnRow: "የምርት ስም በረድፍ ላይ ያስፈልጋል",
    productCategoryIsRequiredOnRow: "ረድፍ ላይ የምርት ምድብ ያስፈልጋል",
    productPriceIsRequiredOnRow: "የምርት ዋጋ በረድፍ ላይ ያስፈልጋል",
    productUnitIsRequiredOnRow: "የምርት አሃድ ረድፍ ላይ ያስፈልጋል",
    productQuantityIsRequiredOnRow: "የምርት ብዛት በረድፍ ላይ ያስፈልጋል",
    productVariantsRequireTracking: "የምርት ልዩነቶች መከታተልን ይፈልጋሉ!",
    pleaseAddVariantClickButton: "እባክዎን የመደመር ቁልፍን ጠቅ በማድረግ ተለዋጭ ያክሉ!",
    totalVariantsMoreThanSelectedQuantity:
      "ጠቅላላ ተለዋጮች ከተመረጠው የምርት ብዛት በላይ ናቸው ፣ Pls ተለዋጭ ብዛትን ይቀንሳል",
    productEditedSuccessfully: "ምርቱ በተሳካ ሁኔታ ተስተካክሏል!",
    fileTooLargeLessThan4Mb:
      "የፋይል መጠን በጣም ትልቅ ነው! የፋይል መጠን ከ 4 ሜባ ያነሰ መሆን አለበት።",
    suchVariantAlreadyExist: "እንዲህ ዓይነቱ ተለዋጭ ቀድሞውኑ አለ",
    addVariants: "ተለዋጮችን ያክሉ",
    editProduct: "ምርት አርትዕ",
    pleaseEnterProductName: "እባክዎን የምርት ስም ያስገቡ",
    pleaseEnterProductPrice: "እባክዎን የምርት ዋጋ ያስገቡ",
    pleaseEnterProductOriginalPrice: "እባክዎን የምርት ኦሪጅናል ዋጋ ያስገቡ",
    productDescription: "የምርት ማብራሪያ",
    selectProductCategory: "የምርት ምድብ ይምረጡ",
    pleaseSelectProductCategory: "እባክዎን የምርት ምድብ ይምረጡ",
    productCostPrice: "የምርት ዋጋ ዋጋ",
    productSellingPrice: "የምርት ሽያጭ ዋጋ",
    productOriginalPrice: "የምርት የመጀመሪያ ዋጋ",
    maxFileSizeAllowedIs4Mb: "ማክስ. የተፈቀደ ፋይል መጠን 4 ሜባ ነው",
    productsWithPicturesArePublished:
      "ስዕሎች ያላቸው ምርቶች ትዕዛዞችን ለመቀበል በ Discover ላይ ታትመዋል",
    shouldThisProductBeTracked: "ይህ ምርት መከታተል አለበት?",
    pleaseSelectStockUnit: "እባክዎ የአክሲዮን ክፍል ይምረጡ",
    stockUnit: "የአክሲዮን ክፍል",
    bag: "ቦርሳ",
    bottle: "ጠርሙስ",
    bunch: "ዳቦ",
    can: "CAN",
    carton: "ካርቶን",
    crate: "CRATE",
    cup: "ዋንጫ",
    dozen: "ዶዝ",
    gigabytes: "ጊጋባይትስ",
    gram: "ግሬም",
    kilogram: "ኪሎግራም",
    litre: "LITER",
    pack: "ጥቅል",
    pair: "ጥንድ",
    pieces: "ቁርጥራጮች",
    plate: "ጠፍጣፋ",
    tonne: "ቶን (ኤምቲ)",
    uNIT: "ዩኒት",
    yard: "ያርድ",
    pleaseEnterProductQuantity: "እባክዎን የምርት ብዛት ያስገቡ",
    productQuantity: "የምርት ብዛት",
    isThisProductTaxed: "ይህ ምርት ግብር ይጣልበታል?",
    selectTaxType: "የግብር ዓይነትን ይምረጡ",
    pleaseSelectTaxType: "እባክዎ የግብር ዓይነትን ይምረጡ",
    progressive: "ተራማጅ",
    proportional: "የተመጣጠነ",
    pleaseEnterProductTaxRate: "እባክዎን የምርት የግብር ተመን ያስገቡ",
    doesProductHaveVariants: "ይህ ምርት ተለዋጮች አሉት?",
    type: "ዓይነት",
    value: "እሴት",
    pleaseEnterVariantType: "እባክዎን የልዩ ዓይነትን ያስገቡ",
    pleaseEnterVariantValue: "እባክዎ ተለዋጭ እሴትን ያስገቡ",
    pleaseEnterVariantPrice: "እባክዎ የተለዋጭ ዋጋን ያስገቡ",
    pleaseEnterVariantQuantity: "እባክዎን ተለዋጭ ብዛትን ያስገቡ",
    productDeletedSuccessfully: "ምርቱ በተሳካ ሁኔታ ተሰር !ል!",
    categoryDeletedSuccessfully: "ምድብ በተሳካ ሁኔታ ተሰር !ል!",
    toDeleteThisProduct: "ይህንን ምርት ለመሰረዝ",
    invalidProductQuantity: "ልክ ያልሆነ የምርት ብዛት",
    quantityAddedIsOverStock: "እርስዎ የሚያክሉት ብዛት በክምችትዎ ላይ ካለው በላይ ነው።",
    itemInventoryNotTracked: "የንጥል ክምችት ክትትል አልተደረገበትም",
    addBulkQuantity: "የጅምላ ብዛት ይጨምሩ",
    enterBulkQuantity: "የጅምላ ብዛት ያስገቡ",
    pleaseEnterBulkQuantity: "እባክዎን የጅምላ ብዛት ያስገቡ",
    youveSuccessfullyAddedANewProduct: "አዲስ ምርት በተሳካ ሁኔታ አክለዋል!",
    pleaseEnterProductSellingPrice: "እባክዎን የምርት ሽያጭ ዋጋ ያስገቡ",
    doYouWantToTrackProductStock: "የምርት ክምችት መከታተል ይፈልጋሉ?",
    sellingPrice: "የሽያጭ ዋጋ",
    setProductExpiryReminder: "የምርት ማብቂያ አስታዋሽ ያዘጋጁ",
    productExpiryDate: "የምርት ማብቂያ ቀን",
    startRemindingFrom: "ማስታወሻን ከ",
    productSuppliesAddedSuccessfully: "የምርት አቅርቦቶችን በተሳካ ሁኔታ አክለዋል።",
    addProductSupplies: "የምርት አቅርቦቶችን ያክሉ",
    pleaseSelectSupplier: "እባክዎን አቅራቢ ይምረጡ",
    nameOfProduct: "የምርት ስም",
    pleaseSelectProduct: "እባክዎ ምርት ይምረጡ",
    productVariant: "የምርት ተለዋጭ",
    pleaseSelectAVariant: "እባክዎ ተለዋጭ ይምረጡ",
    pleaseEnterUnitPrice: "እባክዎን የአንድ ክፍል ዋጋ ያስገቡ",
    businessBranch: "የንግድ ቅርንጫፍ",
    pleaseSelectBranch: "እባክዎ ቅርንጫፍ ይምረጡ",
    youveSuccessfullyAddedANewSupplier: "አዲስ አቅራቢን በተሳካ ሁኔታ አክለዋል",
    addSupplier: "አቅራቢ ያክሉ",
    pleaseEnterSupplierEmail: "እባክዎን የአቅራቢውን ኢሜል ያስገቡ",
    pleaseAddADescription: "እባክዎን መግለጫ ያክሉ",
    anErrorOccuredProductsDeleted:
      "ቀዶ ጥገናውን ሲያከናውን ስህተት ተከስቷል። እባክዎን ያስተውሉ ፣ በሂደቱ ውስጥ ጥቂት ምርቶች ተሰርዘው ሊሆን ይችላል",
    bulkDelete: "ጅምላ ሰርዝ",
    youAreAboutToDelete: "ሊሰርዙ ነው",
    product: "ምርት",
    isDueToRedeem: "በመቤ dueት ምክንያት ነው",
    aReward: "ሽልማት",
    pleaseSelectCustomerToReeemReward: "ሽልማትን ለማስመለስ እባክዎ ደንበኛ ይምረጡ።",
    youHaveNoLoyaltyProgramRunning: "እየሄደ ያለ ንቁ የታማኝነት ፕሮግራም የለዎትም",
    customerHhasNoPointsInLoyaltyProgram:
      "በዚህ የታማኝነት ፕሮግራም ውስጥ ደንበኛ ምንም ነጥቦች የሉትም",
    proceedWithPayment: "በክፍያ ይቀጥሉ?",
    youAreAboutToPayForTransactionUsingPoints: "ነጥቦችን በመጠቀም ለግብይቱ ሊከፍሉ ነው።",
    customerHas: "ደንበኛ አለው",
    worth: "ዋጋ ያለው",
    andIsNotSufficientToPayForTransaction:
      "እና ለዚህ ግብይት ለመክፈል በቂ አይደለም። ሌላ የመክፈያ ዘዴን በመጠቀም ሚዛኑን ማከል ይፈልጋሉ?",
    addCustomerLoyalty: "የደንበኛ ታማኝነትን ያክሉ",
    pleaseAddCustomerFirst: "እባክዎ መጀመሪያ ደንበኛ ይጨምሩ ወይም ይምረጡ።",
    pleaseWaitWhileWeGetReady: "እኛ እስክንዘጋጅ እባክዎ ይጠብቁ",
    lowStock: "ዝቅተኛ ክምችት",
    pleaseEnterAmountTendered: "እባክዎ የጨረታውን መጠን ያስገቡ",
    areYouSureToBookSaleOffline: "እርግጠኛ ነዎት ይህንን ሽያጭ ከመስመር ውጭ ማስያዝ ይፈልጋሉ?",
    saleWouldBeBookedAutomatically: "በይነመረብዎን ሲያበሩ ሽያጭ በራስ -ሰር ይያዛል",
    offlineSalesBookingCancelled: "ከመስመር ውጭ የሽያጭ ቦታ ማስያዝ ተሰር .ል",
    covid19Message: "COVID19 - ስርጭቱን ለማስቆም እጆችዎን በሳሙና ይታጠቡ ወይም ያፅዱ። ሁሌም ደህና ሁን",
    saleSuccessfullyRecorded: "ሽያጭ በተሳካ ሁኔታ ተመዝግቧል!",
    sendReceiptToEmail: "ደረሰኝ ወደ ኢሜል ይላኩ",
    sendThankYouSms: "የምስጋና ኤስኤምኤስ ይላኩ",
    sendShippingDetails: "የመላኪያ ዝርዝሮችን ይላኩ",
    addLoyalty: "ታማኝነትን ይጨምሩ",
    searchCustomerNameOrNumber: "የደንበኛ ስም ወይም ቁጥር ይፈልጉ",
    clickTheSearchCustomerBox: "የፍለጋ ደንበኛ ሣጥን እና የፍተሻ ካርድ ጠቅ ያድርጉ",
    enterProductPrice: "የምርት ዋጋን ያስገቡ",
    enterPriceFor: "ዋጋ ያስገቡ",
    searchForProduct: "ምርት ይፈልጉ",
    all: "ሁሉም",
    backToDashboard: "ወደ ዳሽቦርድ ተመለስ",
    viewDraftSales: "ረቂቅ ሽያጮችን ይመልከቱ",
    variantSelected: "ተለዋጭ ተመርጧል",
    variant: "ተለዋጭ",
    thePreviousVariantSelectionNotAvailable:
      "የቀድሞው ተለዋጭ ምርጫ በዋጋ ላይ በመመርኮዝ ለተመረጠው አዲሱ ተለዋጭ አይገኝም ፣ እባክዎ ምርጫዎን ይለውጡ።",
    pleaseSupplyPositiveQuantityNumber: "እባክዎን የሚለጠፍ ብዛት ቁጥር ያቅርቡ",
    lowStockFor: "ዝቅተኛ ክምችት ለ",
    clearVariants: "ተለዋጮችን ያፅዱ",
    pleaseEnterQuantity: "እባክዎን ብዛት ያስገቡ",
    picture: "ስዕል",
    redemptionToken: "የመቤ Toት ማስመሰያ",
    token: "ማስመሰያ",
    totalSpent: "ጠቅላላ ወጪ",
    confirmPayment: "ክፍያን ያረጋግጡ",
    hasPaymentBeenMade: "ክፍያ በተሳካ ሁኔታ ተካሂዷል?",
    enterTransactionReference: "እርስዎ የከፈሉበትን የግብይት ማጣቀሻ ያስገቡ",
    pleaseSelectACustomer: "እባክዎ ደንበኛ ይምረጡ!",
    areYouSureToApplyDiscount: "እርግጠኛ ነዎት ቅናሽ ማመልከት ይፈልጋሉ?",
    addYourBankAccountToEnableUssd:
      "በ uPay ፈጣን የ USSD ዝውውርን ለማንቃት የባንክ ሂሳብዎን ያክሉ",
    totalAmountToPay: "የሚከፈልበት ጠቅላላ መጠን",
    doYouWantToCancelTransaction: "ይህን ግብይት መሰረዝ ይፈልጋሉ?",
    savePrintBill: "ሂሳብ አስቀምጥ/አትም",
    enterAmountCollectedForCustomer: "ለደንበኛ የተሰበሰበውን መጠን ያስገቡ",
    recordSale: "የሽያጭ መዝገብ",
    checkOutWith: "ጋር ይመልከቱ",
    instantTransfer: "ፈጣን ማስተላለፍ",
    dialTheUSSDCode: "የ USSD ኮድ ይደውሉ",
    pleaseSelectABank: "እባክዎ ባንክ ይምረጡ",
    payWithUSSD: "በ USSD ይክፈሉ",
    sendBillTo: " - ቢል ይላኩ",
    waitingForUSSDTransfer: "የ USSD ሽግግርን በመጠባበቅ ላይ",
    percent: "መቶኛ",
    applyDiscount: "ቅናሽ ይተግብሩ",
    thisBillHasBeenSaved: "ይህ ቢል ተቀምጧል",
    saveDraft: "ረቂቁን አስቀምጥ",
    pleaseTypeANameToIdentifyCustomer: "ደንበኛውን ለመለየት እባክዎ ስም ያስገቡ",
    paymentDetails: "የክፍያ ዝርዝሮች",
    basePrice: "የመሠረት ዋጋ",
    staff: "ሰራተኛ",
    subTotal: "ንዑስ ድምር",
    durationMonths: "የጊዜ ቆይታ (ወራት)",
    selectADuration: "የጊዜ ቆይታ ይምረጡ",
    oneMonth: "1 ወር",
    twoMonths: "2 ወራት",
    threeMonths: "3 ወራት",
    sixMonths: "6 ወራት",
    twelveMonths: "12 ወሮች",
    eighteenMonths: "18 ወሮች",
    twentyFourMonths: "24 ወሮች",
    twoMonthsFree: "(2 ወራት ነፃ)",
    threeMonthsFree: "(3 ወር ነፃ)",
    fiveMonthsFree: "(5 ወራት ነፃ)",
    yourAccountHasBeen: "የእርስዎ መለያ ሆኗል",
    renewed: "ታደሰ",
    upgraded: "ተሻሽሏል",
    successfully: "በተሳካ ሁኔታ",
    yourSubscription: "የእርስዎ የደንበኝነት ምዝገባ",
    youAreCurrentlyEnrolledOnA: "በአሁኑ ጊዜ በ",
    pleaseChooseAPaymentOption: "እባክዎ የክፍያ አማራጭ ይምረጡ",
    planRenewal: "የእድሳት ዕቅድ",
    planUpgrade: "ማሻሻል ዕቅድ",
    pleaseSelectDurationToPayFor: "ለመክፈል ያሰቡትን የጊዜ ርዝመት ይምረጡ",
    staffAccounts: "የሰራተኞች መለያዎች",
    ecommerce: "ኢኮሜርስ",
    pleaseSelectAPlan: "እባክዎ ዕቅድ ይምረጡ",
    includeAddons: "ተጨማሪዎችን ያካትቱ",
    viewTransactions: "ግብይቶችን ይመልከቱ",
    customerHasNoCompletedTansactions:
      "ደንበኛው እስካሁን ምንም የተጠናቀቁ የእርዳታ ዕርምጃዎች የሉትም",
    branch: "ቅርንጫፍ",
    enterNumberOfEcommerceBranches: "የኢኮሜርስ ቅርንጫፎች ቁጥር ያስገቡ",
    enterNumberOfEcommerceBranchesToPay: "ለመክፈል ያሰቡትን የኢኮሜርስ ቅርንጫፎች ቁጥር ያስገቡ",
    ecommerceIntegration: "የኢኮሜርስ ውህደት",
    enterNumberOfBranches: "የቅርንጫፎችን ቁጥር ያስገቡ",
    enterNumberOfAdditionalBranchesToPay: "ሊከፍሏቸው ያሰቡትን ተጨማሪ ቅርንጫፎች ቁጥር ያስገቡ",
    enterNumberOfStaffs: "የሰራተኞች ብዛት ያስገቡ",
    enterNumberOfStaffsToPayFor: "ሊከፍሏቸው ያሰቡትን የሰራተኞች ብዛት ያስገቡ",
    discountApplies: "ቅናሽ ይተገበራል",
    starsOnThe: "ላይ ያሉ ኮከቦች",
    offer: "አቅርብ",
    get: "ያግኙ",
    moreStarsToRedeem: "የሚዋጁ ተጨማሪ ኮከቦች",
    taxVat: "ግብር (ተ.እ.ታ.)",
    callCashierToCompletePayment: "ክፍያን ለማጠናቀቅ ገንዘብ ተቀባይ ይደውሉ",
    receipt: "ደረሰኝ",
    dear: "ውድ",
    thankYouForYourOrderFindGoods:
      "ለትዕዛዝዎ እናመሰግናለን። በስምምነት መሠረት እባክዎን የሚከተሉትን ሸቀጦች ያቀረቡትን ያግኙ።",
    shippingNote: "የመላኪያ ማስታወሻ",
    enterShippingNote: "የመላኪያ ማስታወሻ ያስገቡ",
    shippingAddress: "የመላኪያ አድራሻ",
    enterShippingAddress: "የመላኪያ አድራሻ ያስገቡ",
    wellDoneYouAreDueToRedeem: "ጥሩ ስራ! ለመቤ dueት ነው",
    toGetYourRewardNextVisit: "በሚቀጥለው ጉብኝትዎ ሽልማትዎን ለማግኘት። አመሰግናለሁ",
    pointsOnThe: "ነጥቦች ላይ",
    morePointsToRedeem: "ለማስመለስ ተጨማሪ ነጥቦች",
    showCode: "ኮድ አሳይ",
    toGetYourRewardOnNextVisit: "በሚቀጥለው ጉብኝትዎ ሽልማትዎን ለማግኘት። አመሰግናለሁ",
    earn: "ያግኙ",
    delivaryNote: "የመላኪያ ማስታወሻ",
    draftSales: "ረቂቅ ሽያጭ",
    startDate: "የመጀመሪያ ቀን",
    endDate: "ማብቂያ ቀን",
    orders: "ትዕዛዞች",
    checkout: "ጨርሰህ ውጣ",
    noProductItem: "የምርት እቃ የለም",
    selectProductImage: "የምርት ምስል ይምረጡ",
    selectCountry: "አገር ይምረጡ",
    selectRegion: "ክልል/ክልል ይምረጡ",
    printProductTag: "የህትመት ምርት መለያ",
    transactionReference: "የግብይት ማጣቀሻ",
    Cashier: "ገንዘብ ተቀባይ",
    Manager: "ሥራ አስኪያጅ",
    Owner: "ባለቤት",
    Admin: "አስተዳዳሪ",
  },
  Arabic: {
    cashier: "أمين الصندوق",
    manager: "إدارة",
    owner: "صاحب",
    online: "عبر الانترنت",
    offline: "غير متصل على الانترنت",
    changePassword: "تغيير كلمة المرور",
    currentPasswordMessage: "الرجاء إدخال كلمة المرور الحالية الخاصة بك",
    passwordMessage: "من فضلك أدخل رقمك السري",
    currentPassword: "كلمة المرور الحالي",
    password: "كلمه السر",
    confirmPasswordMessage: "يرجى التأكد من صحة كلمة المرور الخاصة بك!",
    confirmPassword: "تأكيد كلمة المرور",
    sendViaEmail: "إرسال عبر البريد الإلكتروني",
    sendViaWhatsapp: "أرسل عبر WhatsApp",
    downloadPdf: "تحميل PDF",
    paid: "دفع",
    unpaid: "غير مدفوع الأجر",
    partial: "جزئي",
    closeInvoice: "هل تريد إغلاق الفاتورة؟",
    closeInvoiceConfirmation: "الفاتورة قد لا يتم حفظها. هل تريد الاغلاق؟",
    yes: "نعم",
    no: "لا",
    invoice: "فاتورة",
    wasDeducted: "تم خصمه",
    for: "ل",
    item: "غرض",
    addProduct: "أضف منتج",
    paymentReference: "إشارة دفع",
    amountPaid: "المبلغ المدفوع",
    discountAmount: "مقدار الخصم",
    amountDue: "المبلغ المستحق",
    amount: "كمية",
    dueDate: "تاريخ الاستحقاق",
    paymentType: "نوع الدفع",
    card: "بطاقة",
    cash: "السيولة النقدية",
    bankTransfer: "حوالة بنكية",
    paymentMessage: "رسالة الدفع",
    description: "وصف",
    sendReceipt: "إرسال الإيصال",
    delete: "حذف",
    save: "يحفظ",
    resend: "إعادة إرسال",
    saveAndSend: "يحفظ",
    invoiceSaved: "تم حفظ الفاتورة!",
    selectPaymentMethod: "الرجاء اختيار طريقة الدفع!",
    selectCustomer: "الرجاء تحديد عميل!",
    cartEmptyError:
      "لا يمكن أن تكون قائمة عربة التسوق فارغة ، أغلق الفاتورة وأضف عنصرًا إلى عربة التسوق!",
    subscriptionExpired:
      "انتهى اشتراكك وأصبح حسابك الآن مقيدًا. انقر فوق الزر أدناه لتجديد حسابك",
    renew: "تجديد",
    holdOn: "يتمسك",
    customerBank: "بنك العملاء",
    cancel: "يلغي",
    selectACustomer: "حدد العميل",
    invoiceSuccessfulPdfError:
      "تم إنشاء الفاتورة بنجاح ولكن إنشاء PDF يستغرق وقتًا أطول من المعتاد. يرجى التحقق مرة أخرى بعد قليل.",
    downloadingInvoice: "تحميل الفاتورة",
    downloadingReceipt: "تحميل الإيصال",
    whatsappReceiptError:
      "حدث خطأ أثناء إرسال الإيصال عبر WhatsApp ، يرجى المحاولة مرة أخرى.",
    receiptToWhatsapp: "تم تحويل الإيصال إلى WhatsApp",
    thankYouForPatronage: "أشكركم على رعايتكم",
    hereIsYourReceipt: "ها هو إيصال الدفع الخاص بك",
    errorSendingEmailReceipt:
      "حدث خطأ أثناء إرسال الإيصال عبر البريد الإلكتروني ، يرجى المحاولة مرة أخرى أو الاتصال بالدعم",
    receiptSentToEmail: "تم إرسال الإيصال إلى البريد الإلكتروني الخاص بالعميل",
    invoiceSentToEmail: "تم إرسال الفاتورة إلى البريد الإلكتروني الخاص بالعميل",
    invoiceSuccessWhatsappError:
      "تم إنشاء الفاتورة بنجاح ولكن حدث خطأ في الإرسال إلى WhatsApp. حاول مرة أخرى في قائمة الفاتورة",
    invoiceSuccessfulEmailError:
      "تم إنشاء الفاتورة بنجاح ولكن حدث خطأ أثناء الإرسال كبريد إلكتروني. يرجى المحاولة مرة أخرى من قائمة الفاتورة",
    invoiceSentToWhatsapp: "الفاتورة محولة إلى WhatsApp",
    hello: "أهلا",
    pleaseDownloadInvoice: "الرجاء تحميل الفاتورة",
    pleaseDownloadReceipt: "يرجى تحميل الإيصال",
    from: "من عند",
    email: "بريد الالكتروني",
    upgrade: "تطوير",
    youAreOnFreePlan: "أنت على خطة مجانية.",
    clickOn: "انقر فوق",
    yourPlanInFewSteps: " اشتراكك في بضع خطوات سريعة.",
    to: "إلى",
    yourSubscriptionHasExpired: "انتهى اشتراكك وأصبح حسابك الآن مقيدًا.",
    days: "أيام",
    yourSubscriptionExpiresIn: "ينتهي اشتراك Loystar الخاص بك في",
    createAcount: "انشئ حساب",
    signIn: "تسجيل الدخول",
    continue: "يكمل",
    enterOtp: "أدخل رقم التعريف الشخصي لمرة واحدة",
    enterValidOtp: "الرجاء إدخال PIN صالح",
    pin: "دبوس",
    tokenSentToMail: "تم إرسال رمز مميز إلى بريدك الإلكتروني",
    passwordResetSuccessful: "تمت إعادة تعيين كلمة المرور بنجاح",
    somethingWentWrong: "هناك خطأ ما!",
    resetPassword: "إعادة تعيين كلمة المرور",
    iHaveResetCode: "لدي رمز إعادة تعيين كلمة المرور",
    pleaseEnterEmail: "رجاءا أدخل بريدك الإلكتروني",
    aTokenWillBeSentToEmail: "سيتم إرسال رمز مميز إلى بريدك الإلكتروني",
    enterEmail: "أدخل بريدك الإلكتروني",
    sendinYourToken: "إرسال الرمز المميز الخاص بك ...",
    makeSureItMatchesPassword: "تأكد من أنها تطابق كلمة مرورك الجديدة",
    pleaseChooseNewPassword: "الرجاء اختيار كلمة مرور جديدة",
    chooseNewPassword: "اختر كلمة مرور جديدة",
    enterNewPassword: "أدخل كلمة المرور الجديدة للتأكيد",
    enterTokenSent: "أدخل الرمز الذي تم إرسال البريد الخاص بك",
    resetToken: "إعادة تعيين الرمز",
    resettingPassword: "إعادة تعيين كلمة المرور الخاصة بك ...",
    signUp: "اشتراك",
    adminSignInWithEmail:
      " يقوم المسؤول بتسجيل الدخول باستخدام البريد الإلكتروني بينما يقوم الموظفون بتسجيل الدخول باستخدام اسم المستخدم.",
    pleaseEnterEmailOrUsername: "الرجاء إدخال بريدك الإلكتروني أو اسم المستخدم",
    emailOrUsername: "البريد الإلكتروني أو اسم المستخدم",
    pleaseEnterPassword: "الرجاء إدخال كلمة المرور",
    createAnAccount: "انشئ حساب",
    forgotPassword: "هل نسيت كلمة السر؟",
    enterPhoneNumber: "أدخل رقم الهاتف",
    personalData: "بيانات شخصية",
    validateConfirmationCOde: "تحقق من صحة رمز التأكيد",
    pleaseEnterFirstName: "يرجى ادخال الاسم الاول",
    pleaseEnterPhoneNumber: "يرجى إدخال رقم الهاتف الخاص بك",
    pleaseEnterLastName: "الرجاء إدخال اسمك الأخير",
    pleaseEnterBusinessName: "الرجاء إدخال اسم عملك",
    firstName: "الاسم الأول",
    lastName: "الكنية",
    businessName: "الاسم التجاري",
    previous: "سابق",
    next: "التالي",
    pleaseSelectBusinessCategory: "الرجاء تحديد فئة عملك",
    pleaseEnterValidEmail: "يرجى إدخال البريد الإلكتروني الصحيح",
    pleaseEnterPostCode: "يرجى إدخال الرمز البريدي",
    postCode: "الرمز البريدي",
    phoneNumberInUse: "رقم الهاتف هذا قيد الاستخدام بالفعل!",
    emailInUse: "هذا البريد الإلكتروني قيد الاستخدام بالفعل!",
    foodAndDrinks: "الطعام و الشراب",
    salonAndBeauty: "صالون و تجميل",
    fashionAndAccessories: "الأزياء والاكسسوارات",
    gymAndFitness: "صالة رياضية ولياقة بدنية",
    travelAndHotel: "السفر والفنادق",
    homeAndGifts: "المنزل والهدايا",
    washingAndCleaning: "الغسيل والتنظيف",
    gadgetsAndElectronics: "الأدوات والإلكترونيات",
    groceries: "البقالة",
    others: "آحرون",
    submit: "يقدم",
    accountCreatedSuccessful: "لقد ثم انشاء حسابك بنجاح.",
    pleaseEnterAddress: "الرجاء إدخال عنوانك",
    addressLine1: "العنوان السطر 1",
    addressLine2: "سطر العنوان 2",
    choosePassword: "إختر كلمة السر",
    passwordMustBe6Characters: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.",
    howDidYouHearLoystar: "كيف سمعت عن Loystar؟",
    referralCode: "كود الإحالة",
    byClickingTheButton: " بالنقر فوق الزر أدناه ، فإنك توافق على",
    termsAndSevice: "مصطلحات",
    wereCreatingAccount: "نحن ننشئ حسابك",
    proceed: "تقدم",
    verificationCodeMustBe6: "يجب أن يتكون رمز التحقق من 6 أرقام",
    pleaseEnter6DigitCode: "الرجاء إدخال رمز مكون من 6 أرقام",
    enterVerificationCode: "أدخل رمز التحقق",
    resendToken: "إعادة إرسال الرمز",
    verify: "التحقق",
    transactions: "المعاملات",
    todaySales: "مبيعات اليوم",
    salesHistory: "تاريخ المبيعات",
    supplyHistory: "تاريخ التوريد",
    new: "جديد",
    invoices: "الفواتير",
    disbursements: "المدفوعات",
    offlineSales: "المبيعات دون اتصال بالإنترنت",
    products: "منتجات",
    customers: "عملاء",
    multiLevelLoyalty: "ولاء متعدد المستويات",
    loyaltyPrograms: "برامج الولاء",
    members: "أعضاء",
    appStore: "متجر التطبيقات",
    orderMenu: "قائمة الطلب",
    settings: "إعدادات",
    staffAndBranches: "الموظفين والفروع",
    myAccount: "حسابي",
    switchToSellMode: "قم بالتبديل إلى وضع البيع",
    signOut: "خروج",
    getFreeSubscription: "احصل على اشتراك مجاني",
    onlyNumbersAllowed: "يسمح فقط بالأرقام",
    yourAccountMustBe10Digits: "يجب أن يتكون رقم حسابك من 10 أرقام",
    yourBvnMustBe11: "يجب أن يكون رقم BVN الخاص بك مكونًا من 11 رقمًا",
    pleaseSelectBank: "الرجاء تحديد البنك الذي تتعامل معه",
    selectYourBank: "حدد البنك الخاص بك",
    enterBankAccountNumber: "أدخل رقم الحساب المصرفي",
    enterBvnNumber: "أدخل BVN الخاص بك",
    connectBankAccount: "ربط الحساب المصرفي",
    passwordResetSuccessfulAndSignOut:
      "تم إعادة تعيين كلمة المرور الخاصة بك بنجاح. انقر فوق الزر تسجيل الخروج أدناه لتسجيل الدخول مرة أخرى",
    enterCurrentPassword: "إدخل كلمة السر الحالية",
    pleaseEnterCurrentPassword: "الرجاء إدخال كلمة المرور الحالية",
    phoneNumber: "رقم الهاتف",
    sex: "الجنس",
    dateOfBirth: "تاريخ الولادة",
    state: "ولاية",
    country: "دولة",
    loyaltyId: "معرف الولاء",
    createdAt: "أنشئت في",
    noOfTransactionsMade: "عدد المعاملات المنجزة",
    yourDownloadWillStart: "سيبدأ التنزيل الخاص بك في لحظة",
    exportCustomers: "عملاء التصدير",
    youhaveSuccessfullyToppedUp:
      "لقد نجحت في شحن وحدات الرسائل القصيرة الخاصة بك.",
    youNowHave: "لديك الآن",
    smsUnits: "وحدات الرسائل القصيرة",
    enterNumberOfSmsUnits: "أدخل عدد وحدات الرسائل القصيرة التي تنوي شرائها",
    pleaseEnterANumericValue: "الرجاء إدخال قيمة عددية",
    pay: "يدفع",
    accountEditedSuccessfully: "تم تحرير حسابك بنجاح.",
    youAeCurrentlyOn: "أنت الآن على",
    plan: "يخطط",
    userData: "بيانات المستخدم",
    businessData: "البيانات التجارية",
    businessCategory: "كاترجوري الأعمال",
    pleaseSelectCurrency: "الرجاء تحديد عملتك",
    selectYourCurrency: "اختر عملتك",
    purchaseMoreSmsUnits:
      "شراء المزيد من وحدات الرسائل القصيرة باستخدام النموذج أدناه",
    youHave: "عندك",
    atLeast4SmsUnitsRrequired:
      "مطلوب 4 وحدات SMS على الأقل للتحقق ، يرجى إعادة تعبئة الرصيد!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'يرجى التحقق من حسابك المصرفي للامتثال لمتطلبات "اعرف عميلك" (KYC). سيسمح لك ذلك بتحصيل المدفوعات عبر USSD أو التحويل الفوري واستلام الطلبات من العملاء ومعالجة تسوية المعاملات. ينطبق على التجار النيجيريين فقط. الرجاء الضغط على الزر أدناه للبدء.',
    reConnectBankAccount: "أعد ربط الحساب المصرفي",
    accountName: "إسم الحساب",
    accountNumber: "رقم حساب",
    bankName: "اسم البنك",
    verified: "تم التحقق",
    accountDetails: "تفاصيل الحساب",
    kycBankAccount: "اعرف عميلك",
    createTier: "إنشاء الطبقة",
    fileUploadSuccessful: "تم رفع الملف بنجاح",
    fileUploadFailed: "فشل تحميل الملف",
    createLoyaltyProgram: "أنشئ برنامج ولاء",
    createLoyalty: "خلق الولاء",
    name: "اسم",
    loyaltyArtwork: "الولاء الفني",
    clickToUpload: "انقر للتحميل",
    amountToPointsRatio: "المبلغ إلى نسبة النقاط",
    points: "نقاط",
    recommendedAmountToPointRatio:
      "الموصى به: ₦ 1 إلى 1 نقطة. أي مقابل كل 1 Naira تنفقه ، يحصل عملاؤك على نقطة واحدة",
    pleaseTypeIn: "الرجاء كتابة",
    toDeleteLoyalty: "لحذف هذا الولاء",
    deleteLoyalty: "حذف الولاء",
    toConfirm: "للتأكيد",
    edit: "يحرر",
    pointsAwardedSuccessfully: "النقاط الممنوحة بنجاح.",
    enterPointValueToAward: "أدخل قيمة النقطة التي ترغب في منحها للعملاء",
    award: "جائزة",
    awardPointValuesToCustomer: "قيمة نقطة المكافأة للعميل",
    enrollMembersToLoyalty: "سجل الأعضاء في برنامج الولاء",
    awardPoints: "نقاط الجائزة",
    enroll: "يتسجل، يلتحق",
    home: "الصفحة الرئيسية",
    loyalty: "وفاء",
    enrollCustomers: "تسجيل العملاء",
    selected: "المحدد",
    customer: "عميل",
    loyaltyActivationSuccessful: "تفعيل الولاء ناجح.",
    loyaltyDeactivationSuccessful: "تم إلغاء تنشيط الولاء بنجاح.",
    viewTier: "مشاهدة ملف Tier",
    deactivate: "إلغاء التنشيط",
    activate: "تفعيل",
    actions: "أجراءات",
    nameOfLoyalty: "اسم الولاء",
    loyaltyStatus: "حالة الولاء",
    numberOfTiers: "عدد المستويات",
    createdOn: "تم إنشاؤها على",
    createATier: "قم بإنشاء طبقة",
    stopCreatingTierConfirmation: "هل تريد التوقف عن إنشاء فئة؟",
    stopEditingTierConfirmation: "هل تريد التوقف عن تعديل هذا المستوى؟",
    nameOfTier: "اسم الطبقة",
    minimumSpendingTarget: "هدف الحد الأدنى للإنفاق",
    maximumSpendingTarget: "الحد الأقصى للإنفاق المستهدف",
    minimumSpendingTargetRequired: "يتم تحديد هدف الحد الأدنى للإنفاق",
    maximumSpendingTargetRequired: "الحد الأقصى للإنفاق المستهدف",
    rewardSponsor: "راعي المكافآت",
    pleaseChooseARewardSponsor: "الرجاء اختيار راعي مكافأة",
    self: "الذات",
    partner: "شريك",
    rewardPartner: "شريك المكافأة",
    pleaseSelectRewardPartner: "الرجاء تحديد شريك المكافأة الخاص بك",
    rewards: "المكافآت",
    pleaseSelectAReward: "يرجى تحديد مكافأة",
    instructionsOnRedeemingReward: "إرشادات حول كيفية استرداد العميل للمكافأة",
    pleaseFillInThisField: "يرجى ملء هذا الحقل!",
    toDeleteThisTier: " لحذف هذه الطبقة",
    deleteTier: "حذف الطبقة",
    viewMembers: "عرض الأعضاء",
    membersEnrolled: "الأعضاء المسجلين",
    instruction: "تعليمات",
    membersIn: "الأعضاء في",
    availableTiersInLoyalty: "المستويات المتوفرة في برنامج الولاء",
    tiers: "المستويات",
    totalTier: "إجمالي الطبقة",
    availableLoyaltyProgramme: "برنامج الولاء المتاح",
    totalLoyalties: "إجمالي الولاءات",
    memberDetails: "تفاصيل العضو",
    nameOfCustomer: "اسم العميل",
    address: "عنوان",
    allEnrolledMembers: "جميع الأعضاء المسجلين",
    thisIsToWishYouHappyBirthday:
      "هذا أتمنى لكم عيد ميلاد سعيد للغاية وحياة مزدهرة. شكرا لكل ما أنت عليه لـ Loyster. احتفالات سعيدة!",
    inputAnOfferPlease: "أدخل عرض من فضلك",
    pleaseSelectTheInsertPoint:
      "يرجى تحديد نقطة الإدراج في الرسالة والنقر مرة أخرى",
    birthdayOfferAndMessage: "عرض عيد ميلاد ورسالة",
    birthdayOffer: "عرض عيد الميلاد",
    birthdayMessage: "رسالة عيد ميلاد",
    noOfferFound: "لم يتم العثور على عرض",
    settingABirthdayOffer:
      "يتيح تحديد عرض عيد ميلاد للعملاء تلقي هذا العرض عبر الرسائل القصيرة في عيد ميلادهم",
    createOffer: "إنشاء عرض",
    whatIsTheOffer: "ما هو العرض؟",
    editMessage: "تحرير الرسالة",
    insert: "إدراج",
    theNameOfCustomerInserted: "سيتم إدخال اسم العميل هنا",
    theBirtdayOfferInserted: "سيتم إدراج عرض عيد الميلاد هنا",
    youSuccessfullyAddedNewBranch: "لقد نجحت في إضافة فرع جديد!",
    addNewBranch: "إضافة فرع جديد",
    addBranch: "اضافة فرع",
    additionalBranchWillIncur: "سيتكبد فرع إضافي",
    perBranch: "لكل فرع",
    ecommerceBranchCosts: "تكاليف فرع التجارة الإلكترونية",
    pleaseEnterBranchName: "الرجاء إدخال اسم الفرع",
    pleaseEnterBranchAddress1: "الرجاء إدخال سطر عنوان الفرع 1",
    enterBranchAddress1: "أدخل سطر عنوان الفرع 1",
    enterBranchAddress2: "أدخل سطر عنوان الفرع 1",
    pleaseEnterBranchAddress2: "الرجاء إدخال سطر عنوان الفرع 2",
    enterBranchName: "أدخل اسم الفرع",
    successfullyAddedStaff: "لقد نجحت في إضافة فريق عمل جديد!",
    addNewStaff: "إضافة فريق عمل جديد",
    addStaff: "إضافة طاقم العمل",
    additionalStaffWillIncur: "سيتحمل الموظفون الإضافيون",
    perStaff: "لكل موظف.",
    pleaseEnterStaffEmail: "الرجاء إدخال البريد الإلكتروني للموظفين",
    pleaseEnterStaffUsername: "الرجاء إدخال اسم المستخدم للموظفين",
    pleaseEnterStaffPassword: "الرجاء إدخال كلمة مرور الموظفين",
    pleaseSelectStaffRole: "الرجاء تحديد دور الموظفين",
    selectStaffRole: "حدد دور الموظفين",
    enterStaffEmail: "أدخل البريد الإلكتروني للموظفين",
    enterStaffUsername: "أدخل اسم المستخدم للموظفين",
    enterStaffPassword: "أدخل كلمة مرور الموظفين",
    spacesNotAllowedInUsername: "مسافات غير مسموح بها في اسم المستخدم",
    admin: "مشرف",
    pleaseSelectBusinessToAttachStaff: "الرجاء تحديد الأعمال لإرفاق الموظفين",
    searchForBranchToAttachStaff: "ابحث عن فرع لإلحاق موظفين",
    username: "اسم المستخدم",
    role: "دور",
    areYouSureToDeleteThis: "هل أنت متأكد من حذف هذا",
    branches: "الفروع",
    upgradeYourPlan: "قم بترقية خطتك.",
    add: "يضيف",
    addNew: "اضف جديد",
    customerWithEmailAlreadyExists:
      "العميل مع البريد الإلكتروني / رقم الهاتف موجود بالفعل!",
    successfullyAddedNewCustomer: "لقد نجحت في إضافة عميل جديد!",
    addCustomer: "أضف الزبون",
    pleaseEnterCustomerFirstName: "الرجاء إدخال الاسم الأول للعميل",
    pleaseEnterCustomerLastName: "الرجاء إدخال الاسم الأخير للعميل",
    pleaseEnterCustomerPhoneNumber: "الرجاء إدخال رقم هاتف العميل",
    pleaseEnterCustomerEmail: "الرجاء إدخال البريد الإلكتروني الخاص بالعميل",
    pleaseEnterCustomerAddressLine: "الرجاء إدخال سطر عنوان العميل",
    pleaseEnterCustomerOtherAddress: "الرجاء إدخال عنوان العميل الآخر",
    pleaseSelectCustomerGender: "الرجاء تحديد جنس العميل",
    gender: "جنس تذكير أو تأنيث",
    male: "ذكر",
    female: "أنثى",
    bank: "مصرف",
    selectBank: "حدد البنك",
    stateOrRegionOrProvince: "الولاية / المنطقة / المقاطعة",
    customerNotes: "ملاحظات الزبون",
    sendSms: "أرسل رسالة نصية قصيرة",
    editCustomer: "تحرير العميل",
    redeemReward: "استرداد المكافأة",
    issueLoyaltyCard: "إصدار بطاقة ولاء",
    deleteCustomer: "حذف العميل",
    youveSuccessfullyAssignedLoyaltyMembership:
      "لقد نجحت في تعيين معرف عضوية برنامج الولاء",
    noMembershipIdAvailable:
      "لا توجد معرفات عضوية متاحة. يرجى الاتصال بنا على hello@loystar.co",
    sendEmail: "ارسل بريد الكتروني",
    membershipPoints: "نقاط العضوية",
    customerDetails: "تفاصيل العميل",
    close: "قريب",
    loyaltyBalance: "رصيد الولاء",
    pointsBalance: "رصيد النقاط",
    starBalance: "ستار بالانس",
    requiredPoints: "النقاط المطلوبة",
    requiredStars: "النجوم المطلوبة",
    reddemCode: "استرداد الرمز",
    toDeleteThisCustomer: "لحذف هذا العميل",
    customerHasBeenDeletedSuccessfully: "تم حذف العميل بنجاح!",
    customerWithPhoneAlreadyExists: "العميل مع رقم الهاتف موجود بالفعل",
    customerWasSuccessfullyEdited: "تم تحرير العميل بنجاح",
    anErrorOccured: "حدث خطأ",
    phoneNumberIncludeCountry: "رقم الهاتف (تضمين رمز الدولة)",
    yourFileQueuedForUpload:
      "تم وضع ملفك في قائمة الانتظار للتحميل. يرجى تحديث الصفحة بعد بضع ثوان.",
    thereWasAnErrorPerformingOperation: "حدث خطأ أثناء إجراء العملية!",
    pleaseSelectFile: "اختر ملفا، رجاءا!",
    oopsOnlyCsvFilesAllowed:
      "وجه الفتاة! يسمح فقط بملفات CSV. يرجى تحميل ملف .csv.",
    fileShouldBeSmallerThan5Mb:
      "يجب أن يكون الملف أصغر من 5 ميغا بايت. إذا كان لديك ملف أكبر ، يرجى إرسال بريد إلكتروني إلى support@loystar.co. شكرا لك",
    customerFirstNameIsRequired: "الاسم الأول للعميل مطلوب في الصف",
    customerPhoneNumberIsRequired: "رقم هاتف العميل مطلوب في الصف",
    importCustomers: "عملاء الاستيراد",
    upload: "تحميل",
    clickIconToDownloadCsv: "انقر فوق هذا الرمز لتنزيل نموذج ملف CSV.",
    getGoogleSheetVersion: "احصل على نسخة ورقة جوجل",
    clickOrDragFileToUpload: "انقر أو اسحب الملف إلى هذه المنطقة للتحميل",
    missingOrInvalidColumnHeader:
      "رأس العمود مفقود أو غير صالح. يرجى اتباع تنسيق القالب. شكرا لك.",
    youHaveImported: "لقد قمت باستيراد",
    youSuccessfullyRedeemedReward: "لقد نجحت في استرداد مكافأتك!",
    sixDigitCode: "رمز من ستة أرقام",
    pleaseEnterCustomerRewardCode: "الرجاء إدخال رمز مكافأة العميل",
    enterRewardCode: "أدخل رمز المكافأة (رمز المكافأة حساس لحالة الأحرف)",
    selectLoyaltyProgram: "حدد برنامج الولاء",
    stamps: "طوابع بريدية",
    smsUnitsLow: "وحدات الرسائل القصيرة منخفضة",
    pleaseTopUpSmsUnits: "يرجى تعبئة وحدات الرسائل القصيرة",
    smsSuccessfullySentForDelivery: "تم إرسال الرسائل القصيرة للتسليم بنجاح!",
    sendSmsTo: "أرسل رسالة نصية قصيرة إلى",
    allCustomers: "كل العملاء",
    unitsAvailable: "الوحدات المتاحة",
    pleaseTypeInTheMessage: "الرجاء كتابة الرسالة",
    message: "رسالة",
    charactersRemaining: "الأحرف المتبقية",
    avoidUseOfSpecialCharacters:
      "تجنب استخدام الأحرف الخاصة والرموز التعبيرية للحفاظ على وحدات الرسائل القصيرة.",
    note: "ملحوظة",
    errorFetchingCustomersMultilevelDetail:
      "خطأ في جلب تفاصيل متعددة المستويات للعملاء",
    search: "بحث",
    reset: "إعادة ضبط",
    importCustomer: "استيراد العميل",
    addNewCustomer: "إضافة عميل جديد",
    sendSmsBroadcast: "إرسال البث عبر الرسائل القصيرة",
    totalCustomers: "إجمالي العملاء",
    disbursementDetails: "تفاصيل الصرف",
    paidBy: "دفع بواسطة",
    disbursed: "المصروفات",
    bankAccountName: "اسم الحساب المصرفي",
    bankAccountNumber: "رقم الحساب المصرفي",
    transferInitiated: "بدأ التحويل",
    transferCompleted: "اكتمل التحويل",
    pleaseEnterAValid: "الرجاء إدخال",
    begin: "يبدأ",
    end: "نهاية",
    date: "تاريخ",
    paymentDate: "يوم الدفع او الاستحقاق",
    selectDisbursementDuration: "حدد مدة الصرف",
    totalSettled: "إجمالي تسوية",
    totalUnsettled: "المجموع غير المسدد",
    toDeleteThisSale: "لحذف هذا البيع",
    draftSaleDeletedSuccessfully: "تم حذف مسودة البيع بنجاح!",
    deleteSale: "حذف البيع",
    pleaseTypeInYourTotal: "يرجى كتابة المجموع الخاص بك لتأكيد الحذف",
    billDetails: "تفاصيل الفاتورة",
    printBill: "طباعة الفاتورة",
    servedBy: "قدم بواسطة",
    total: "المجموع",
    createdDate: "تاريخ الإنشاء",
    createdTime: "وقت الإنشاء",
    status: "حالة",
    loginSuccessful: "تم تسجيل الدخول بنجاح",
    pleaseWaitWhileWeConnectAccount: "يرجى الانتظار بينما نقوم بتوصيل حسابك",
    connectionFailedTryAgain: "فشل الاتصال. حاول مرة اخرى.",
    connectionSuccessful: "تم الاتصال بنجاح",
    viewDetails: "عرض التفاصيل",
    enable: "ممكن",
    free: "حر",
    cost: "كلفة",
    visitWebsite: "زيارة الموقع",
    pleaseUpgradeYourPlanToPro:
      "يرجى ترقية خطتك إلى Pro Plus لتمكين هذا التطبيق",
    connectYourBankAccount: "قم بتوصيل حسابك المصرفي لتتمكن من تلقي المدفوعات.",
    disable: "إبطال",
    enableApp: "تفعيل التطبيق",
    addNewProductToInvoice: "إضافة منتج جديد إلى الفاتورة",
    toDeleteThisInvoice: "لحذف هذه الفاتورة",
    invoiceDeletedSuccessfully: "تم حذف الفاتورة بنجاح!",
    deleteInvoice: "حذف الفاتورة",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "لقد نجحت في إضافة رسالة دفع فاتورة!",
    pleaseEnterPaymentInstructions: "الرجاء إدخال تعليمات الدفع",
    invoiceId: "هوية صوتية",
    paidDate: "تاريخ المدفوعة",
    reference: "المرجعي",
    productAdded: "تمت إضافة المنتج",
    productOutOfStock: "المنتج نفذ من المخزون. يرجى إعادة التخزين.",
    totalInvoices: "إجمالي الفواتير",
    totalPaidInvoices: "إجمالي الفواتير المدفوعة",
    totalUnpaidInvoices: "إجمالي الفواتير غير المسددة",
    loyaltyProgramDeleted: "تم حذف برنامج الولاء",
    thisLoyaltyProgramDeletedSuccessfully: "تم حذف برنامج الولاء هذا بنجاح",
    thisLoyaltyProgramEditedSuccessfully: "تم تحرير برنامج الولاء هذا بنجاح",
    loyaltyProgramAddedSuccessfully: "تمت إضافة برنامج الولاء بنجاح",
    loyaltyProgramEdited: "تم تعديل برنامج الولاء",
    loyaltyProgramAdded: "تمت إضافة برنامج الولاء",
    loyaltyDetails: "تفاصيل الولاء",
    doYouWantToCloseDialog: "هل تريد إغلاق هذا الحوار؟",
    pleaseEnterLoyaltyName: "الرجاء إدخال اسم ولائك",
    programType: "نوع البرنامج",
    pleaseSelectProgramType: "الرجاء تحديد نوع البرنامج",
    simplePoints: "نقاط بسيطة",
    stampsProgram: "برنامج الطوابع",
    threshold: "عتبة",
    pleaseEnterLoyaltyThreshold: "الرجاء إدخال حد الولاء",
    reward: "جائزة او مكافاة",
    pleaseEnterLoyaltyReward: "الرجاء إدخال مكافأة الولاء",
    totalUserPoints: "إجمالي نقاط المستخدم",
    totalUserStamps: "مجموع طوابع المستخدم",
    spendingTarget: "هدف الإنفاق",
    spendingTargetHint1:
      "هدف الإنفاق هو المبلغ الذي يجب على العميل إنفاقه لكسب المكافأة. 1 قيمة العملة = 1 نقطة.",
    spendingTargetHint2:
      "هدف الطوابع هو عدد الطوابع التي يجب على العميل جمعها لكسب المكافأة. على سبيل المثال 5",
    addNewLoyaltyProgram: "أضف برنامج ولاء جديد",
    addLoyaltyProgram: "أضف برنامج الولاء",
    loyaltyProgramType: "نوع برنامج الولاء",
    pleaseSelectLoyaltyProgramType: "الرجاء تحديد نوع برنامج الولاء",
    nameOfProgram: "اسم البرنامج",
    pleaseEnterProgramName: "الرجاء إدخال اسم البرنامج",
    whatIsTheReward: "ما هي المكافأة؟",
    egNextPurchaseIsFree: "على سبيل المثال الشراء التالي مجاني",
    customerName: "اسم الزبون",
    guestCustomer: "الزبون الضيف",
    orderConfirmedSuccessfully: "تم تأكيد الطلب بنجاح",
    orderCancelledSuccessfully: "تم إلغاء الطلب بنجاح",
    confirmOrder: "أكد الطلب",
    cancelOrder: "الغاء الطلب",
    allOrders: "جميع الطلبات",
    totalOrders: "إجمالي الطلبات",
    doYouWantToAcceptOrder: "هل تريد قبول هذا الطلب؟",
    doYouWantToCancelOrder: "هل تريد إلغاء هذا الطلب؟",
    orderDetails: "تفاصيل الطلب",
    orderCreatedAt: "تم إنشاء الطلب في",
    supplier: "المورد",
    productName: "اسم المنتج",
    quantity: "كمية",
    unitPrice: "سعر الوحدة",
    receivedBy: "استلمت من قبل",
    reportFrom: "تقرير من",
    totalSupplies: "إجمالي الإمدادات",
    allRightsReserved: "كل الحقوق محفوظة",
    toDeleteThisTransaction: "لحذف هذه الصفقة",
    transactionDeletedSuccessfully:
      "تم حذف المعاملة بنجاح. تم إرجاع المخزون إلى المخزون.",
    deleteTransaction: "حذف المعاملة",
    transactionDetails: "تفاصيل الصفقه",
    printReceipt: "اطبع الايصال",
    channel: "قناة",
    discount: "خصم",
    profit: "ربح",
    discountedSales: "مبيعات مخفضة",
    errorFetchingRecord: "خطأ في إحضار السجل",
    exportTransactions: "معاملات التصدير",
    errorFetchingSalesRecord: "خطأ في جلب سجل المبيعات للتصدير.",
    totalSellingPrice: "إجمالي سعر البيع",
    totalCostPrice: "إجمالي سعر التكلفة",
    appliedDiscount: "الخصم المطبق",
    noOfItems: "عدد العناصر",
    sales: "مبيعات",
    export: "يصدر",
    totalProfit: "اجمالي الربح",
    totalBalanceInPeriod: "إجمالي الرصيد في الفترة",
    allTimeSales: "كل وقت المبيعات",
    records: "السجلات",
    todaysSales: "مبيعات اليوم",
    transaction: "عملية تجارية",
    youHaveExceededTotalNumberOfProducts:
      "لقد تجاوزت العدد الإجمالي للمنتجات المسموح بها في خطتك. قم بترقية خطتك للاستمتاع بحد أعلى.",
    youNeedToHaveLoyaltyProgram:
      "يجب أن يكون لديك برنامج ولاء لاستخدام هذه الميزة!",
    price: "سعر",
    category: "فئة",
    stockTracking: "تتبع المخزون",
    stockCount: "عدد الأسهم",
    taxed: "خاضع للضريبة",
    originalPrice: "السعر الأصلي",
    costPrice: "سعر الكلفة",
    unit: "وحدة",
    productImage: "صورة المنتج",
    taxRate: "معدل الضريبة",
    taxType: "نوع الضريبة",
    trackInventory: "حساب المخزون",
    variants: "المتغيرات",
    hasVariants: "له متغيرات",
    importProduct: "منتج استيراد",
    exportProducts: "منتجات التصدير",
    addNewProduct: "اضافة منتج جديد",
    viewCategory: "مشاهدة الفئة",
    viewSuppliers: "عرض الموردين",
    receiveInventory: "استلام الجرد",
    printAllProductsTag: "طباعة علامة جميع المنتجات",
    deleteAll: "حذف الكل",
    totalProducts: "إجمالي المنتجات",
    youveSuccessfullyAddedANewCategory: "لقد نجحت في إضافة فئة جديدة",
    addNewCategory: "إضافة فئة جديدة",
    addCategory: "إضافة فئة",
    categoryName: "اسم التصنيف",
    pleaseEnterCategoryName: "الرجاء إدخال اسم الفئة",
    stampsTarget: "هدف الطوابع",
    sendInventory: "إرسال الجرد",
    productDetails: "تفاصيل المنتج",
    youveSuccessfullyEditedThisCategory: "لقد نجحت في تحرير هذه الفئة",
    update: "تحديث",
    categoryList: "قائمة الفئات",
    categories: "فئات",
    enterQuantityToUpdate: "أدخل كمية للتحديث",
    inventorySentSuccessfully: "تم إرسال المخزون بنجاح!",
    updateInventory: "تحديث الجرد",
    currentQuantity: "الكمية الحالية",
    pleaseEnterQuantityToAdd: "الرجاء إدخال الكمية التي تريد إضافتها",
    pleaseSelectABranch: "الرجاء تحديد الفرع",
    searchForBranch: "ابحث عن فرع",
    youCantSendMoreThanStock: "لا يمكنك إرسال أكثر مما لديك في المخزون",
    send: "يرسل",
    pleaseEnterQuantityToSend: "الرجاء إدخال الكمية التي تريد إرسالها",
    productNameIsRequiredOnRow: "اسم المنتج مطلوب في الصف",
    productCategoryIsRequiredOnRow: "فئة المنتج مطلوبة في الصف",
    productPriceIsRequiredOnRow: "سعر المنتج مطلوب في الصف",
    productUnitIsRequiredOnRow: "وحدة المنتج مطلوبة في الصف",
    productQuantityIsRequiredOnRow: "كمية المنتج مطلوبة في الصف",
    productVariantsRequireTracking: "متغيرات المنتج تتطلب تتبع!",
    pleaseAddVariantClickButton: "الرجاء إضافة متغير بالنقر فوق زر الإضافة!",
    totalVariantsMoreThanSelectedQuantity:
      "تعد المتغيرات الإجمالية أكثر من كمية المنتج المحددة ، لذا تعمل الثابتة والمتنقلة على تقليل كمية المتغير",
    productEditedSuccessfully: "تم تحرير المنتج بنجاح!",
    fileTooLargeLessThan4Mb:
      "حجم الملف كبير جدًا! يجب أن يكون حجم الملف أقل من 4 ميغا بايت.",
    suchVariantAlreadyExist: "هذا البديل موجود بالفعل",
    addVariants: "أضف المتغيرات",
    editProduct: "تحرير المنتج",
    pleaseEnterProductName: "الرجاء إدخال اسم المنتج",
    pleaseEnterProductPrice: "الرجاء إدخال سعر المنتج",
    pleaseEnterProductOriginalPrice: "الرجاء إدخال سعر المنتج الأصلي",
    productDescription: "وصف المنتج",
    selectProductCategory: "حدد فئة المنتج",
    pleaseSelectProductCategory: "الرجاء تحديد فئة المنتج",
    productCostPrice: "سعر تكلفة المنتج",
    productSellingPrice: "سعر بيع المنتج",
    productOriginalPrice: "سعر المنتج الأصلي",
    maxFileSizeAllowedIs4Mb: "الأعلى. حجم الملف المسموح به هو 4 ميغا بايت",
    productsWithPicturesArePublished:
      "يتم نشر المنتجات بالصور على Discover لتلقي الطلبات",
    shouldThisProductBeTracked: "هل يجب تتبع هذا المنتج؟",
    pleaseSelectStockUnit: "الرجاء تحديد وحدة المخزون",
    stockUnit: "وحدة المخزون",
    bag: "حقيبة",
    bottle: "زجاجة",
    bunch: "حزمة",
    can: "علبة",
    carton: "كرتون",
    crate: "قفص",
    cup: "فنجان",
    dozen: "دزينة",
    gigabytes: "جيجا بايت",
    gram: "غرام",
    kilogram: "كيلوغرام",
    litre: "لتر",
    pack: "حزمة",
    pair: "زوج",
    pieces: "قطع",
    plate: "طبق",
    tonne: "TONNE (MT)",
    uNIT: "وحدة",
    yard: "حديقة منزل",
    pleaseEnterProductQuantity: "الرجاء إدخال كمية المنتج",
    productQuantity: "كمية المنتج",
    isThisProductTaxed: "هل هذا المنتج خاضع للضريبة؟",
    selectTaxType: "حدد نوع الضريبة",
    pleaseSelectTaxType: "الرجاء تحديد نوع الضريبة",
    progressive: "تدريجي",
    proportional: "متناسب",
    pleaseEnterProductTaxRate: "الرجاء إدخال معدل ضريبة المنتج",
    doesProductHaveVariants: "هل هذا المنتج له متغيرات؟",
    type: "نوع",
    value: "قيمة",
    pleaseEnterVariantType: "الرجاء إدخال نوع المتغير",
    pleaseEnterVariantValue: "الرجاء إدخال قيمة المتغير",
    pleaseEnterVariantPrice: "الرجاء إدخال سعر المتغير",
    pleaseEnterVariantQuantity: "الرجاء إدخال كمية المتغير",
    productDeletedSuccessfully: "تم حذف المنتج بنجاح!",
    categoryDeletedSuccessfully: "تم حذف الفئة بنجاح!",
    toDeleteThisProduct: "لحذف هذا المنتج",
    invalidProductQuantity: "كمية المنتج غير صالحة",
    quantityAddedIsOverStock: "الكمية التي تضيفها تفوق ما لديك في المخزن.",
    itemInventoryNotTracked: "لا يتم تعقب مخزون العنصر",
    addBulkQuantity: "أضف الكمية المجمعة",
    enterBulkQuantity: "أدخل الكمية بالجملة",
    pleaseEnterBulkQuantity: "الرجاء إدخال الكمية بالجملة",
    youveSuccessfullyAddedANewProduct: "لقد نجحت في إضافة منتج جديد!",
    pleaseEnterProductSellingPrice: "الرجاء إدخال سعر بيع المنتج",
    doYouWantToTrackProductStock: "هل تريد تتبع مخزون المنتج؟",
    sellingPrice: "سعر البيع",
    setProductExpiryReminder: "تعيين تذكير انتهاء صلاحية المنتج",
    productExpiryDate: "تاريخ انتهاء صلاحية المنتج",
    startRemindingFrom: "ابدأ التذكير من",
    productSuppliesAddedSuccessfully: "لقد أضفت مستلزمات المنتج بنجاح.",
    addProductSupplies: "أضف مستلزمات المنتج",
    pleaseSelectSupplier: "الرجاء تحديد المورد",
    nameOfProduct: "اسم المنتج",
    pleaseSelectProduct: "الرجاء تحديد المنتج",
    productVariant: "متغير المنتج",
    pleaseSelectAVariant: "الرجاء تحديد متغير",
    pleaseEnterUnitPrice: "الرجاء إدخال سعر الوحدة",
    businessBranch: "فرع الأعمال",
    pleaseSelectBranch: "الرجاء تحديد الفرع",
    youveSuccessfullyAddedANewSupplier: "لقد نجحت في إضافة مورد جديد",
    addSupplier: "إضافة مورد",
    pleaseEnterSupplierEmail: "الرجاء إدخال البريد الإلكتروني للمورد",
    pleaseAddADescription: "الرجاء إضافة وصف",
    anErrorOccuredProductsDeleted:
      "حدث خطأ أثناء إجراء العملية. يرجى ملاحظة أنه قد يتم حذف بعض المنتجات في هذه العملية",
    bulkDelete: "حذف مجمع",
    youAreAboutToDelete: "أنت على وشك الحذف",
    product: "المنتج",
    isDueToRedeem: "من المقرر أن تخليص",
    aReward: "مكافأة",
    pleaseSelectCustomerToReeemReward: "الرجاء تحديد عميل لاسترداد المكافأة.",
    youHaveNoLoyaltyProgramRunning: "ليس لديك برنامج ولاء نشط قيد التشغيل",
    customerHhasNoPointsInLoyaltyProgram:
      "العميل ليس لديه نقاط في برنامج الولاء هذا",
    proceedWithPayment: "المضي قدما في الدفع؟",
    youAreAboutToPayForTransactionUsingPoints:
      "أنت على وشك الدفع مقابل المعاملة باستخدام النقاط.",
    customerHas: "العميل لديه",
    worth: "قيمة",
    andIsNotSufficientToPayForTransaction:
      "ولا يكفي لدفع ثمن هذه الصفقة. هل يريدون إضافة الرصيد باستخدام طريقة دفع أخرى؟",
    addCustomerLoyalty: "أضف ولاء العملاء",
    pleaseAddCustomerFirst: "الرجاء إضافة أو تحديد عميل أولاً.",
    pleaseWaitWhileWeGetReady: "من فضلك انتظر ريثما نستعد",
    lowStock: "الأسهم منخفضة",
    pleaseEnterAmountTendered: "الرجاء إدخال المبلغ المقدم",
    areYouSureToBookSaleOffline:
      "هل أنت متأكد أنك تريد حجز هذا البيع في وضع عدم الاتصال؟",
    saleWouldBeBookedAutomatically:
      "سيتم حجز البيع تلقائيًا عند تشغيل الإنترنت",
    offlineSalesBookingCancelled: "تم إلغاء حجز المبيعات دون اتصال بالإنترنت",
    covid19Message:
      "COVID19: اغسل يديك بالصابون أو عقم لمنع انتشار المرض. ابق آمنًا دائمًا",
    saleSuccessfullyRecorded: "تم تسجيل البيع بنجاح!",
    sendReceiptToEmail: "إرسال إيصال إلى البريد الإلكتروني",
    sendThankYouSms: "أرسل رسالة شكر قصيرة",
    sendShippingDetails: "إرسال تفاصيل الشحن",
    addLoyalty: "أضف الولاء",
    searchCustomerNameOrNumber: "ابحث عن اسم العميل أو الرقم",
    clickTheSearchCustomerBox:
      "انقر فوق مربع البحث في العميل ثم امسح البطاقة ضوئيًا",
    enterProductPrice: "أدخل سعر المنتج",
    enterPriceFor: "أدخل السعر لـ",
    searchForProduct: "ابحث عن المنتج",
    all: "الجميع",
    backToDashboard: "رجوع إلى لوحة القيادة",
    viewDraftSales: "عرض مسودة المبيعات",
    variantSelected: "المتغير المحدد",
    variant: "البديل",
    thePreviousVariantSelectionNotAvailable:
      "اختيار المتغير السابق غير متاح للمتغير الجديد المحدد بناءً على السعر ، يرجى تغيير اختيارك.",
    pleaseSupplyPositiveQuantityNumber: "يرجى تقديم رقم كمية صالح للإختيار",
    lowStockFor: "مخزون منخفض لـ",
    clearVariants: "متغيرات واضحة",
    pleaseEnterQuantity: "الرجاء إدخال الكمية",
    picture: "صورة",
    redemptionToken: "رمز الاسترداد",
    token: "رمز",
    totalSpent: "إجمال الصرف",
    confirmPayment: "تأكيد الدفع",
    hasPaymentBeenMade: "هل تمت معالجة الدفع بنجاح؟",
    enterTransactionReference: "أدخل مرجع المعاملة الذي دفعت به",
    pleaseSelectACustomer: "الرجاء تحديد عميل!",
    areYouSureToApplyDiscount: "هل أنت متأكد أنك تريد تطبيق الخصم؟",
    addYourBankAccountToEnableUssd:
      "أضف حسابك المصرفي لتمكين تحويل USSD الفوري عن طريق uPay",
    totalAmountToPay: "المبلغ الإجمالي للدفع",
    doYouWantToCancelTransaction: "هل تريد إلغاء هذه الصفقة؟",
    savePrintBill: "حفظ / طباعة الفاتورة",
    enterAmountCollectedForCustomer: "أدخل المبلغ المحصل للعميل",
    recordSale: "بيع قياسي",
    checkOutWith: "تحقق من مع",
    instantTransfer: "نقل فوري",
    dialTheUSSDCode: "اطلب رمز USSD",
    pleaseSelectABank: "الرجاء تحديد بنك",
    payWithUSSD: "ادفع باستخدام USSD",
    sendBillTo: " - أرسل الفاتورة إلى",
    waitingForUSSDTransfer: "في انتظار تحويل USSD",
    percent: "نسبه مئويه",
    applyDiscount: "تطبيق الخصم",
    thisBillHasBeenSaved: "تم حفظ هذا القانون",
    saveDraft: "حفظ المسودة",
    pleaseTypeANameToIdentifyCustomer: "الرجاء كتابة اسم لتعريف العميل",
    paymentDetails: "بيانات الدفع",
    basePrice: "السعر الأساسي",
    staff: "طاقم عمل",
    subTotal: "المجموع الفرعي",
    durationMonths: "المدة (شهور)",
    selectADuration: "حدد المدة",
    oneMonth: "1 شهر",
    twoMonths: "2 أشهر",
    threeMonths: "3 اشهر",
    sixMonths: "6 اشهر",
    twelveMonths: "12 شهر",
    eighteenMonths: "18 شهرا",
    twentyFourMonths: "24 شهرا",
    twoMonthsFree: "(شهرين مجانا)",
    threeMonthsFree: "(3 أشهر مجانية)",
    fiveMonthsFree: "(5 أشهر مجانية)",
    yourAccountHasBeen: "لقد كان حسابك",
    renewed: "متجدد",
    upgraded: "ترقية",
    successfully: "بنجاح",
    yourSubscription: "اشتراكك",
    youAreCurrentlyEnrolledOnA: "أنت مسجل حاليًا في",
    pleaseChooseAPaymentOption: "الرجاء اختيار خيار الدفع",
    planRenewal: "تجديد الخطة",
    planUpgrade: "ترقية الخطة",
    pleaseSelectDurationToPayFor: "يرجى تحديد المدة التي تنوي دفع ثمنها",
    staffAccounts: "حسابات الموظفين",
    ecommerce: "التجارة الإلكترونية",
    pleaseSelectAPlan: "الرجاء تحديد خطة",
    includeAddons: "تضمين الوظائف الإضافية",
    viewTransactions: "مشاهدة المعاملات",
    customerHasNoCompletedTansactions:
      "الزبون ليس لديه tansactions مكتملة حتى الآن",
    branch: "فرع",
    enterNumberOfEcommerceBranches: "أدخل عدد فروع التجارة الإلكترونية",
    enterNumberOfEcommerceBranchesToPay:
      "أدخل عدد فروع التجارة الإلكترونية التي تنوي الدفع مقابلها",
    ecommerceIntegration: "تكامل التجارة الإلكترونية",
    enterNumberOfBranches: "أدخل عدد الفروع",
    enterNumberOfAdditionalBranchesToPay:
      "أدخل عدد الفروع الإضافية التي تنوي الدفع مقابلها",
    enterNumberOfStaffs: "أدخل عدد الموظفين",
    enterNumberOfStaffsToPayFor: "أدخل عدد الموظفين الذين تنوي الدفع لهم",
    discountApplies: "يسري الخصم",
    starsOnThe: "النجوم على",
    offer: "يعرض",
    get: "احصل على",
    moreStarsToRedeem: "المزيد من النجوم لتخليصها",
    taxVat: "ضريبة القيمة المضافة)",
    callCashierToCompletePayment: "اتصل بالصراف لإتمام الدفع",
    receipt: "إيصال",
    dear: "عزيزي",
    thankYouForYourOrderFindGoods:
      "شكرا لطلبك. يرجى العثور على البضائع التالية الموردة ، كما هو متفق عليه.",
    shippingNote: "ملاحظة الشحن",
    enterShippingNote: "أدخل مذكرة الشحن",
    shippingAddress: "عنوان الشحن",
    enterShippingAddress: "أدخل عنوان الشحن",
    wellDoneYouAreDueToRedeem: "أحسنت! أنت على وشك الفداء",
    toGetYourRewardNextVisit: "لتحصل على مكافأتك في زيارتك القادمة. شكرا لك",
    pointsOnThe: "النقاط على",
    morePointsToRedeem: "المزيد من النقاط لاستردادها",
    showCode: "أظهر الكود",
    toGetYourRewardOnNextVisit: "لتحصل على مكافأتك في زيارتك القادمة. شكرا لك",
    earn: "يكسب",
    delivaryNote: "ملاحظة Delivary",
    draftSales: "مسودة المبيعات",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    orders: "الطلب #٪ s",
    checkout: "الدفع",
    noProductItem: "لا يوجد عنصر في المنتج",
    selectProductImage: "حدد صورة المنتج",
    selectCountry: "حدد الدولة",
    selectRegion: "حدد الولاية / المنطقة",
    printProductTag: "طباعة بطاقة المنتج",
    transactionReference: "رمز العملية",
    Cashier: "أمين الصندوق",
    Manager: "مدير",
    Owner: "صاحب",
    Admin: "مشرف",
  },
  Armenian: {
    cashier: "գանձապահ",
    manager: "մենեջեր",
    owner: "սեփականատերը",
    online: "առցանց",
    offline: "անցանց",
    changePassword: "Փոխել գաղտնաբառը",
    currentPasswordMessage: "Խնդրում ենք մուտքագրել ձեր ընթացիկ գաղտնաբառը",
    passwordMessage: "Խնդրում ենք մուտքագրել ձեր գաղտնաբառը",
    currentPassword: "Ընթացիկ գաղտնաբառ",
    password: "գաղտնաբառ",
    confirmPasswordMessage: "Խնդրում ենք հաստատել ձեր գաղտնաբառը:",
    confirmPassword: "Հաստատել գաղտնաբառը",
    sendViaEmail: "Ուղարկեք էլ",
    sendViaWhatsapp: "Ուղարկեք WhatsApp- ի միջոցով",
    downloadPdf: "Ներբեռնեք PDF",
    paid: "վճարված",
    unpaid: "չվճարված",
    partial: "մասնակի",
    closeInvoice: "Youանկանու՞մ եք փակել հաշիվ -ապրանքագիրը:",
    closeInvoiceConfirmation: "Հաշիվը չի կարող պահվել: Ուզու՞մ ես փակել:",
    yes: "այո",
    no: "ոչ",
    invoice: "Հաշիվ -ապրանքագիր",
    wasDeducted: "հանվել է",
    for: "համար",
    item: "Նյութ",
    addProduct: "Ավելացնել Ապրանք",
    paymentReference: "Վճարման տեղեկանք",
    amountPaid: "Վճարված գումարը",
    discountAmount: "Discեղչի գումար",
    amountDue: "Պարտքի գումարը",
    amount: "Գումարը",
    dueDate: "Վերջնաժամկետ",
    paymentType: "Վճարման տեսակ",
    card: "Քարտ",
    cash: "Կանխիկ",
    bankTransfer: "Բանկային փոխանցում",
    paymentMessage: "Վճարման հաղորդագրություն",
    description: "Նկարագրություն",
    sendReceipt: "Ուղարկել անդորրագիր",
    delete: "Նջել",
    save: "Պահել",
    resend: "Կրկին ուղարկել",
    saveAndSend: "Պահել",
    invoiceSaved: "Հաշիվը պահված է:",
    selectPaymentMethod: "Խնդրում ենք ընտրել Վճարման եղանակ:",
    selectCustomer: "Խնդրում ենք ընտրել Հաճախորդ:",
    cartEmptyError:
      "Cartամբյուղների ցանկը չի կարող դատարկ լինել, փակեք հաշիվը և ապրանքն ավելացրեք զամբյուղի մեջ:",
    subscriptionExpired:
      "Ձեր բաժանորդագրության ժամկետը սպառվել է, և ձեր հաշիվն այժմ սահմանափակ է: Կտտացրեք ներքևի կոճակին ՝ ձեր հաշիվը թարմացնելու համար",
    renew: "Նորացնել",
    holdOn: "Սպասիր",
    customerBank: "Հաճախորդների բանկ",
    cancel: "Չեղարկել",
    selectACustomer: "Ընտրեք Հաճախորդ",
    invoiceSuccessfulPdfError:
      "Հաշիվը հաջողությամբ ստեղծվեց, բայց PDF սերունդը սովորականից երկար է տևում: Խնդրում ենք կարճ ժամանակում նորից ստուգել:",
    downloadingInvoice: "Ներբեռնվում է հաշիվ -ապրանքագիր",
    downloadingReceipt: "Ներբեռնման անդորրագիրը",
    whatsappReceiptError:
      "Սխալ առաջացավ անդորրագիրը WhatsApp- ի միջոցով ուղարկելու դեպքում: Խնդրում ենք կրկին փորձել:",
    receiptToWhatsapp: "Ստացականը փոխանցվել է WhatsApp- ին",
    thankYouForPatronage: "Շնորհակալություն ձեր հովանավորության համար",
    hereIsYourReceipt: "Ահա ձեր վճարման անդորրագիրը",
    errorSendingEmailReceipt:
      "Էլեկտրոնային փոստի միջոցով ստացականը ուղարկելիս սխալ տեղի ունեցավ: Խնդրում ենք կրկին փորձել կամ կապվել աջակցության ծառայության հետ",
    receiptSentToEmail: "Ստացականը ուղարկվել է հաճախորդի էլ",
    invoiceSentToEmail: "Հաշիվը ուղարկվել է հաճախորդի էլ",
    invoiceSuccessWhatsappError:
      "Հաշիվը հաջողությամբ ստեղծվեց, բայց սխալ տեղի ունեցավ WhatsApp- ին ուղարկելու դեպքում: Կրկին փորձեք հաշիվ ապրանքագրերի ցանկում",
    invoiceSuccessfulEmailError:
      "Հաշիվ-հաշիվը հաջողությամբ ստեղծվեց, բայց սխալ տեղի ունեցավ որպես էլ. Խնդրում ենք կրկին փորձել հաշիվ ապրանքագրերի ցանկից",
    invoiceSentToWhatsapp: "Հաշիվը փոխանցվել է WhatsApp- ին",
    hello: "Բարեւ Ձեզ",
    pleaseDownloadInvoice: "Ներբեռնեք հաշիվը",
    pleaseDownloadReceipt: "Ներբեռնեք անդորրագիրը",
    from: "ից",
    email: "Էլ",
    upgrade: "Բարձրացնել",
    youAreOnFreePlan: "Դուք գտնվում եք անվճար ծրագրի վրա:",
    clickOn: "Սեղմեք",
    yourPlanInFewSteps: " ձեր բաժանորդագրությունը մի քանի արագ քայլերով:",
    to: "դեպի",
    yourSubscriptionHasExpired:
      "Ձեր բաժանորդագրության ժամկետը սպառվել է, և ձեր հաշիվն այժմ սահմանափակ է:",
    days: "օր",
    yourSubscriptionExpiresIn:
      "Ձեր Loystar բաժանորդագրության ժամկետը լրանում է",
    createAcount: "Ստեղծել հաշիվ",
    signIn: "Մուտք գործել",
    continue: "Շարունակել",
    enterOtp: "Մուտքագրեք OTP PIN- ը",
    enterValidOtp: "Մուտքագրեք վավեր PIN կոդ",
    pin: "PIN կոդ",
    tokenSentToMail: "Ձեր էլ. Փոստին նշան է ուղարկվել",
    passwordResetSuccessful: "Գաղտնաբառի վերականգնումը հաջողվեց",
    somethingWentWrong: "Ինչ որ բան այնպես չգնաց!",
    resetPassword: "Վերականգնել գաղտնաբառը",
    iHaveResetCode: "Ես գաղտնաբառի վերականգնման կոդ ունեմ",
    pleaseEnterEmail: "Խնդրում ենք մուտքագրել ձեր էլ",
    aTokenWillBeSentToEmail: "Նշան կուղարկվի ձեր էլ",
    enterEmail: "Մուտքագրեք ձեր էլ",
    sendinYourToken: "Ձեր նշանն ուղարկվում է ...",
    makeSureItMatchesPassword:
      "Համոզվեք, որ այն համապատասխանում է ձեր նոր գաղտնաբառին",
    pleaseChooseNewPassword: "Խնդրում ենք ընտրել նոր գաղտնաբառ",
    chooseNewPassword: "Ընտրեք նոր գաղտնաբառ",
    enterNewPassword: "Մուտքագրեք ձեր նոր գաղտնաբառը `հաստատելու համար",
    enterTokenSent: "Մուտքագրեք այն նշանը, որն ուղարկվել է ձեր փոստին",
    resetToken: "Վերականգնել նշանը",
    resettingPassword: "Գաղտնաբառի վերականգնում ...",
    signUp: "Գրանցվել",
    adminSignInWithEmail:
      " Ադմինիստրատորը մուտք է գործում էլ. Փոստով, իսկ անձնակազմը մուտք է գործում օգտվողի անունով:",
    pleaseEnterEmailOrUsername:
      "Խնդրում ենք մուտքագրել ձեր էլ. Հասցեն կամ օգտվողի անունը",
    emailOrUsername: "Էլ. Փոստ կամ Մականուն",
    pleaseEnterPassword: "Խնդրում ենք մուտքագրել գաղտնաբառը",
    createAnAccount: "Ստեղծել հաշիվ",
    forgotPassword: "Մոռացել եք գաղտնաբառը?",
    enterPhoneNumber: "Մուտքագրեք հեռախոսահամարը",
    personalData: "Անձնական տվյալներ",
    validateConfirmationCOde: "Վավերացրեք հաստատման ծածկագիրը",
    pleaseEnterFirstName: "Խնդրում ենք մուտքագրել ձեր անունը",
    pleaseEnterPhoneNumber: "Խնդրում ենք մուտքագրել ձեր հեռախոսահամարը",
    pleaseEnterLastName: "Խնդրում ենք մուտքագրել ձեր ազգանունը",
    pleaseEnterBusinessName: "Խնդրում ենք մուտքագրել ձեր ընկերության անունը",
    firstName: "Անուն",
    lastName: "Ազգանուն",
    businessName: "Բիզնեսի անվանումը",
    previous: "Նախորդ",
    next: "Հաջորդը",
    pleaseSelectBusinessCategory: "Խնդրում ենք ընտրել ձեր բիզնեսի կատեգորիան",
    pleaseEnterValidEmail: "Խնդրում ենք մուտքագրել վավեր էլ",
    pleaseEnterPostCode: "Խնդրում ենք մուտքագրել փոստային կոդը",
    postCode: "Փոստային ինդեքս",
    phoneNumberInUse: "Այս հեռախոսահամարը արդեն օգտագործվում է:",
    emailInUse: "Այս նամակն արդեն օգտագործվում է:",
    foodAndDrinks: "Սնունդ և խմիչքներ",
    salonAndBeauty: "Սրահ և գեղեցկություն",
    fashionAndAccessories: "Նորաձևություն և աքսեսուարներ",
    gymAndFitness: "Մարզասրահ և ֆիթնես",
    travelAndHotel: "Travelամփորդություն և հյուրանոց",
    homeAndGifts: "Տուն և նվերներ",
    washingAndCleaning: "Լվացում և մաքրում",
    gadgetsAndElectronics: "Գաջեթներ և էլեկտրոնիկա",
    groceries: "Մթերային ապրանքներ",
    others: "Մյուսները",
    submit: "Ներկայացնել",
    accountCreatedSuccessful: "Ձեր հաշիվը հաջողությամբ ստեղծվեց:",
    pleaseEnterAddress: "Խնդրում ենք մուտքագրել ձեր հասցեն",
    addressLine1: "հասցեատող 1",
    addressLine2: "Հասցե 2",
    choosePassword: "Ընտրեք Գաղտնաբառ",
    passwordMustBe6Characters: "Գաղտնաբառը պետք է պարունակի առնվազն 6 նիշ:",
    howDidYouHearLoystar: "Ինչպե՞ս լսեցիք Loystar- ի մասին:",
    referralCode: "Ուղղորդման Կոդ",
    byClickingTheButton: " Սեղմելով ներքևի կոճակին ՝ դուք համաձայնում եք",
    termsAndSevice: "Պայմանները",
    wereCreatingAccount: "Մենք ստեղծում ենք ձեր հաշիվը",
    proceed: "Շարունակել",
    verificationCodeMustBe6: "Հաստատման ծածկագիրը պետք է լինի 6 նիշ",
    pleaseEnter6DigitCode: "Խնդրում ենք մուտքագրել 6 նիշանոց ծածկագիր",
    enterVerificationCode: "Մուտքագրեք ստուգման կոդը",
    resendToken: "Նորից ուղարկել նշանը",
    verify: "Հաստատել",
    transactions: "Գործարքներ",
    todaySales: "Այսօրվա վաճառք",
    salesHistory: "Վաճառքի պատմություն",
    supplyHistory: "Մատակարարման պատմություն",
    new: "Նոր",
    invoices: "Հաշիվ -ապրանքագրեր",
    disbursements: "Հատկացումներ",
    offlineSales: "Անցանց վաճառք",
    products: "Ապրանքներ",
    customers: "Հաճախորդներ",
    multiLevelLoyalty: "Բազմաստիճան հավատարմություն",
    loyaltyPrograms: "Հավատարմության ծրագրեր",
    members: "Անդամներ",
    appStore: "App Store",
    orderMenu: "Պատվերի ընտրացանկ",
    settings: "Կարգավորումներ",
    staffAndBranches: "Աշխատակազմեր և մասնաճյուղեր",
    myAccount: "Իմ հաշիվը",
    switchToSellMode: "Անցնել վաճառքի ռեժիմին",
    signOut: "Դուրս գալ",
    getFreeSubscription: "Ստացեք անվճար բաժանորդագրություն",
    onlyNumbersAllowed: "Թույլատրվում են միայն թվեր",
    yourAccountMustBe10Digits: "ձեր հաշվի համարը պետք է լինի 10 նիշանոց համար",
    yourBvnMustBe11: "ձեր BVN- ը պետք է լինի 11 նիշանոց համար",
    pleaseSelectBank: "Խնդրում ենք ընտրել ձեր բանկը",
    selectYourBank: "Ընտրեք ձեր բանկը",
    enterBankAccountNumber: "Մուտքագրեք բանկային հաշվի համարը",
    enterBvnNumber: "Մուտքագրեք ձեր BVN- ը",
    connectBankAccount: "Միացրեք բանկային հաշիվը",
    passwordResetSuccessfulAndSignOut:
      "Ձեր գաղտնաբառը հաջողությամբ վերականգնվեց: Կտտացրեք ստորև նշված «Ելք» կոճակին ՝ կրկին մուտք գործելու համար",
    enterCurrentPassword: "Մուտքագրեք ընթացիկ գաղտնաբառը",
    pleaseEnterCurrentPassword: "Խնդրում ենք մուտքագրել ընթացիկ գաղտնաբառը",
    phoneNumber: "Հեռախոսահամար",
    sex: "Սեքս",
    dateOfBirth: "Ծննդյան ամսաթիվ",
    state: "Պետություն",
    country: "Երկիրը",
    loyaltyId: "Հավատարմության ID",
    createdAt: "Ստեղծվել է At",
    noOfTransactionsMade: "Կատարված գործարքների քանակը",
    yourDownloadWillStart: "Ձեր ներբեռնումը կսկսվի մի պահից",
    exportCustomers: "Հաճախորդների արտահանում",
    youhaveSuccessfullyToppedUp:
      "Դուք հաջողությամբ համալրել եք ձեր SMS միավորները:",
    youNowHave: "Դուք այժմ ունեք",
    smsUnits: "SMS միավորներ",
    enterNumberOfSmsUnits:
      "Մուտքագրեք SMS միավորների քանակը, որոնք մտադիր եք գնել",
    pleaseEnterANumericValue: "Մուտքագրեք թվային արժեք",
    pay: "Վճարել",
    accountEditedSuccessfully: "Ձեր հաշիվը հաջողությամբ խմբագրվեց:",
    youAeCurrentlyOn: "Դուք ներկայումս միացված եք",
    plan: "Պլանավորել",
    userData: "Օգտագործողի տվյալները",
    businessData: "ԲԻINՆԵՍ ՏՎՅԱԼՆԵՐ",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Խնդրում ենք ընտրել ձեր արժույթը",
    selectYourCurrency: "Ընտրեք ձեր արժույթը",
    purchaseMoreSmsUnits:
      "Գնեք ավելի շատ SMS միավոր ՝ օգտագործելով ստորև բերված ձևը",
    youHave: "Դու ունես",
    atLeast4SmsUnitsRrequired:
      "Հաստատման համար պահանջվում է առնվազն 4 SMS միավոր, խնդրում ենք լիցքավորել:",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Խնդրում ենք հաստատել ձեր բանկային հաշիվը `« Knowանաչիր քո հաճախորդին »(KYC) պահանջներին համապատասխանելու համար: Սա թույլ կտա ձեզ հավաքել վճարումներ USSD- ի կամ ակնթարթային փոխանցման միջոցով, հաճախորդներից ստանալ պատվերներ և մշակել գործարքների կարգավորումը: Կիրառելի է միայն նիգերիացի վաճառականների համար: Սկսելու համար սեղմեք ներքևի կոճակը:",
    reConnectBankAccount: "Կրկին միացրեք բանկային հաշիվը",
    accountName: "Հաշվեհամար",
    accountNumber: "Հաշվեհամար",
    bankName: "Բանկի անվանումը",
    verified: "Ստուգված է",
    accountDetails: "հաշվի մանրամասներ",
    kycBankAccount: "KYC",
    createTier: "Ստեղծել Tier",
    fileUploadSuccessful: "ֆայլը հաջողությամբ վերբեռնվեց",
    fileUploadFailed: "ֆայլի վերբեռնումը ձախողվեց",
    createLoyaltyProgram: "Ստեղծեք հավատարմության ծրագիր",
    createLoyalty: "Ստեղծեք հավատարմություն",
    name: "Անուն",
    loyaltyArtwork: "Հավատարմության ստեղծագործություն",
    clickToUpload: "Կտտացրեք ՝ վերբեռնելու համար",
    amountToPointsRatio: "Գումարի և միավորների հարաբերակցությունը",
    points: "Միավորներ",
    recommendedAmountToPointRatio:
      "Խորհուրդ է տրվում ՝ to 1 -ից 1 միավոր: այսինքն ՝ ծախսված յուրաքանչյուր 1 Նաիրայի դիմաց ձեր հաճախորդները ստանում են 1 միավոր",
    pleaseTypeIn: "Խնդրում ենք մուտքագրել",
    toDeleteLoyalty: "ջնջել այս հավատարմությունը",
    deleteLoyalty: "Deնջել հավատարմությունը",
    toConfirm: "հաստատել",
    edit: "Խմբագրել",
    pointsAwardedSuccessfully: "Միավորները հաջողությամբ շնորհվեցին:",
    enterPointValueToAward:
      "Մուտքագրեք միավորի արժեքը, որը կցանկանայիք շնորհել հաճախորդներին",
    award: "Մրցանակ",
    awardPointValuesToCustomer: "Պարգևատրման միավորի արժեքը հաճախորդին",
    enrollMembersToLoyalty: "Անդամներին գրանցեք հավատարմության վրա",
    awardPoints: "Մրցանակային միավորներ",
    enroll: "Գրանցվել",
    home: "տուն",
    loyalty: "Հավատարմություն",
    enrollCustomers: "Հաճախորդների գրանցում",
    selected: "Ընտրված է",
    customer: "Հաճախորդ",
    loyaltyActivationSuccessful: "Հավատարմության ակտիվացումը հաջողված է:",
    loyaltyDeactivationSuccessful: "Հավատարմության ապաակտիվացումը հաջողված է:",
    viewTier: "Դիտել մակարդակը",
    deactivate: "Ակտիվացնել",
    activate: "Ակտիվացնել",
    actions: "Գործողություններ",
    nameOfLoyalty: "Հավատարմության անվանումը",
    loyaltyStatus: "Հավատարմության կարգավիճակ",
    numberOfTiers: "Մակարդակների քանակը",
    createdOn: "Ստեղծվել է ՝",
    createATier: "Ստեղծեք մակարդակ",
    stopCreatingTierConfirmation:
      "Youանկանու՞մ եք դադարեցնել մակարդակի ստեղծումը:",
    stopEditingTierConfirmation:
      "Wantանկանու՞մ եք դադարեցնել այս շերտի խմբագրումը:",
    nameOfTier: "Մակարդակի անվանումը",
    minimumSpendingTarget: "Նվազագույն ծախսերի թիրախ",
    maximumSpendingTarget: "Maxախսերի առավելագույն թիրախ",
    minimumSpendingTargetRequired: "պահանջվում է նվազագույն ծախսերի թիրախ",
    maximumSpendingTargetRequired: "ծախսերի առավելագույն թիրախը պահանջվում է",
    rewardSponsor: "Պարգևատրման հովանավոր",
    pleaseChooseARewardSponsor: "Խնդրում ենք ընտրել պարգևատրման հովանավոր",
    self: "Ես",
    partner: "Գործընկեր",
    rewardPartner: "Պարգևատրման գործընկեր",
    pleaseSelectRewardPartner: "Խնդրում ենք ընտրել ձեր պարգևատրման գործընկերը",
    rewards: "Պարգեւներ",
    pleaseSelectAReward: "Խնդրում ենք ընտրել մրցանակ",
    instructionsOnRedeemingReward:
      "Հրահանգներ, թե ինչպես հաճախորդը պետք է վճարի պարգևատրումը",
    pleaseFillInThisField: "Խնդրում ենք լրացնել այս դաշտը:",
    toDeleteThisTier: " ջնջել այս շերտը",
    deleteTier: "Deնջել մակարդակը",
    viewMembers: "Դիտել անդամներին",
    membersEnrolled: "Անդամները գրանցվեցին",
    instruction: "Հրահանգ",
    membersIn: "Անդամները ՝",
    availableTiersInLoyalty: "Հավատարմության ծրագրում առկա մակարդակ (ներ)",
    tiers: "Մակարդակներ",
    totalTier: "ԸՆԴԱՄԵՆԸ",
    availableLoyaltyProgramme: "Հավատարմության մատչելի ծրագիր",
    totalLoyalties: "ԸՆԴԱՄԵՆԸ ՀԱՎԱՍՏԱՆՈԹՅՈՆՆԵՐ",
    memberDetails: "Անդամի մանրամասները",
    nameOfCustomer: "Հաճախորդի անունը",
    address: "Հասցե",
    allEnrolledMembers: "Բոլոր գրանցված անդամները",
    thisIsToWishYouHappyBirthday:
      "Սա նշանակում է, որ մաղթում եմ ձեզ երջանիկ ծննդյան օր և բարգավաճ կյանք: Շնորհակալություն այն ամենի համար, ինչ դու Լոյստերին ես: Շնորհավոր տոնակատարություններ:",
    inputAnOfferPlease: "Խնդրում ենք մուտքագրել առաջարկ",
    pleaseSelectTheInsertPoint:
      "Խնդրում ենք ընտրել հաղորդագրության մեջ տեղադրման կետը և նորից սեղմել",
    birthdayOfferAndMessage: "Bննդյան օրվա առաջարկ և հաղորդագրություն",
    birthdayOffer: "Dayննդյան օրվա առաջարկ",
    birthdayMessage: "Bննդյան օրվա ուղերձը",
    noOfferFound: "Առաջարկ չի գտնվել",
    settingABirthdayOffer:
      "Birthdayննդյան օրվա առաջարկի սահմանումը հնարավորություն է տալիս հաճախորդներին ստանալ այս առաջարկը SMS- ով `իրենց ծննդյան օրը",
    createOffer: "Ստեղծեք առաջարկ",
    whatIsTheOffer: "Ո՞րն է առաջարկը:",
    editMessage: "Խմբագրել հաղորդագրությունը",
    insert: "Տեղադրեք",
    theNameOfCustomerInserted: "Հաճախորդի անունը կտեղադրվի այստեղ",
    theBirtdayOfferInserted: "Theննդյան օրվա առաջարկը կտեղադրվի այստեղ",
    youSuccessfullyAddedNewBranch:
      "Դուք հաջողությամբ ավելացրել եք նոր մասնաճյուղ:",
    addNewBranch: "Ավելացնել նոր մասնաճյուղ",
    addBranch: "Ավելացնել մասնաճյուղ",
    additionalBranchWillIncur: "Լրացուցիչ մասնաճյուղ կարող է առաջանալ",
    perBranch: "մեկ ճյուղի համար",
    ecommerceBranchCosts: "Էլեկտրոնային առևտրի մասնաճյուղի ծախսերը",
    pleaseEnterBranchName: "Խնդրում ենք մուտքագրել մասնաճյուղի անունը",
    pleaseEnterBranchAddress1:
      "Խնդրում ենք մուտքագրել մասնաճյուղի հասցեի տողը 1",
    enterBranchAddress1: "Մուտքագրեք մասնաճյուղի հասցեի տողը 1",
    enterBranchAddress2: "Մուտքագրեք մասնաճյուղի հասցեի տողը 1",
    pleaseEnterBranchAddress2:
      "Խնդրում ենք մուտքագրել մասնաճյուղի հասցեի տողը 2",
    enterBranchName: "Մուտքագրեք մասնաճյուղի անունը",
    successfullyAddedStaff: "Դուք հաջողությամբ ավելացրել եք նոր աշխատակազմ:",
    addNewStaff: "Ավելացնել նոր աշխատակազմ",
    addStaff: "Ավելացնել աշխատակազմ",
    additionalStaffWillIncur: "Լրացուցիչ անձնակազմ կծավալվի",
    perStaff: "մեկ անձնակազմի համար:",
    pleaseEnterStaffEmail: "Խնդրում ենք մուտքագրել անձնակազմի էլ",
    pleaseEnterStaffUsername: "Խնդրում ենք մուտքագրել անձնակազմի օգտանունը",
    pleaseEnterStaffPassword: "Խնդրում ենք մուտքագրել անձնակազմի գաղտնաբառը",
    pleaseSelectStaffRole: "Խնդրում ենք ընտրել անձնակազմի դերը",
    selectStaffRole: "Ընտրեք անձնակազմի դերը",
    enterStaffEmail: "Մուտքագրեք անձնակազմի էլ",
    enterStaffUsername: "Մուտքագրեք անձնակազմի օգտվողի անունը",
    enterStaffPassword: "Մուտքագրեք անձնակազմի գաղտնաբառը",
    spacesNotAllowedInUsername:
      "օգտվողի անվան մեջ անթույլատրելի տարածություններ",
    admin: "Ադմինիստրատոր",
    pleaseSelectBusinessToAttachStaff:
      "Խնդրում ենք ընտրել ձեռնարկություն ՝ անձնակազմին կցելու համար",
    searchForBranchToAttachStaff: "Որոնեք մասնաճյուղ `անձնակազմին կցելու համար",
    username: "Օգտագործողի անունը",
    role: "Դերը",
    areYouSureToDeleteThis: "Իսկապե՞ս ջնջել սա",
    branches: "Մասնաճյուղերը",
    upgradeYourPlan: "Թարմացրեք ձեր րագիրը:",
    add: "ԱՎԵԼԱՆԵԼ",
    addNew: "Ավելացնել նոր",
    customerWithEmailAlreadyExists:
      "Էլփոստով/հեռախոսահամարով հաճախորդ արդեն գոյություն ունի:",
    successfullyAddedNewCustomer:
      "Դուք հաջողությամբ ավելացրել եք նոր հաճախորդ:",
    addCustomer: "Ավելացնել հաճախորդ",
    pleaseEnterCustomerFirstName: "Խնդրում ենք մուտքագրել հաճախորդի անունը",
    pleaseEnterCustomerLastName: "Խնդրում ենք մուտքագրել հաճախորդի ազգանունը",
    pleaseEnterCustomerPhoneNumber:
      "Խնդրում ենք մուտքագրել հաճախորդի հեռախոսահամարը",
    pleaseEnterCustomerEmail: "Խնդրում ենք մուտքագրել հաճախորդի էլ",
    pleaseEnterCustomerAddressLine:
      "Խնդրում ենք մուտքագրել հաճախորդի հասցեի գիծը",
    pleaseEnterCustomerOtherAddress:
      "Խնդրում ենք մուտքագրել հաճախորդի այլ հասցե",
    pleaseSelectCustomerGender: "Խնդրում ենք ընտրել հաճախորդի սեռը",
    gender: "Սեռը",
    male: "Արական",
    female: "իգական",
    bank: "Բանկ",
    selectBank: "Ընտրեք բանկ",
    stateOrRegionOrProvince: "Նահանգ/մարզ/մարզ",
    customerNotes: "Հաճախորդի նշումներ",
    sendSms: "Ուղարկեք SMS",
    editCustomer: "Խմբագրել հաճախորդին",
    redeemReward: "Մարել պարգևը",
    issueLoyaltyCard: "Թողարկեք հավատարմության քարտ",
    deleteCustomer: "Deնջել հաճախորդին",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Դուք հաջողությամբ հանձնարարել եք հավատարմության անդամակցության ID",
    noMembershipIdAvailable:
      "Անդամակցության ID- ներ հասանելի չեն: Խնդրում ենք կապնվել մեզ հետ hello@loystar.co հասցեով",
    sendEmail: "Ուղարկել նամակ",
    membershipPoints: "Անդամակցության միավորներ",
    customerDetails: "Հաճախորդի մանրամասներ",
    close: "փակել",
    loyaltyBalance: "Հավատարմության մնացորդ",
    pointsBalance: "Միավորների մնացորդ",
    starBalance: "Աստղային հաշվեկշիռ",
    requiredPoints: "Պահանջվող միավորներ",
    requiredStars: "Պահանջվող աստղեր",
    reddemCode: "Փրկել ծածկագիրը",
    toDeleteThisCustomer: "ջնջել այս հաճախորդին",
    customerHasBeenDeletedSuccessfully: "Հաճախորդը հաջողությամբ ջնջվել է:",
    customerWithPhoneAlreadyExists:
      "Հեռախոսահամարով հաճախորդ արդեն գոյություն ունի",
    customerWasSuccessfullyEdited: "Հաճախորդը հաջողությամբ խմբագրվել է",
    anErrorOccured: "Սխալ է տեղի ունեցել",
    phoneNumberIncludeCountry: "Հեռախոսահամարը (ներառյալ երկրի կոդը)",
    yourFileQueuedForUpload:
      "Ձեր ֆայլը հերթագրվել է վերբեռնման համար: Թարմացրեք էջը մի քանի վայրկյանից հետո:",
    thereWasAnErrorPerformingOperation:
      "Գործողությունը կատարելիս սխալ տեղի ունեցավ:",
    pleaseSelectFile: "Խնդրում ենք ընտրել ֆայլ:",
    oopsOnlyCsvFilesAllowed:
      "Վայ! Թույլատրվում է միայն CSV ֆայլեր: Խնդրում ենք վերբեռնել .csv ֆայլ:",
    fileShouldBeSmallerThan5Mb:
      "Ֆայլը պետք է լինի 5 ՄԲ -ից փոքր: Եթե ունեք ավելի մեծ ֆայլ, խնդրում ենք ուղարկել support@loystar.co էլ. Շնորհակալություն",
    customerFirstNameIsRequired: "հաճախորդի անունը պարտադիր է տողում",
    customerPhoneNumberIsRequired:
      "հաճախորդի հեռախոսահամարը պահանջվում է տողում",
    importCustomers: "Ներմուծեք հաճախորդներ",
    upload: "Վերբեռնել",
    clickIconToDownloadCsv:
      "Կտտացրեք այս պատկերակին ՝ CSV ֆայլի ձևանմուշը ներբեռնելու համար:",
    getGoogleSheetVersion: "Ստացեք Google թերթիկի տարբերակը",
    clickOrDragFileToUpload:
      "Կտտացրեք կամ քաշեք ֆայլը այս տարածք ՝ վերբեռնելու համար",
    missingOrInvalidColumnHeader:
      "Սյունակի վերնագիր բացակայում է կամ անվավեր է: Խնդրում ենք հետևել ձևանմուշի ձևին: Շնորհակալություն.",
    youHaveImported: "Դուք ներմուծել եք",
    youSuccessfullyRedeemedReward: "Դուք հաջողությամբ մարեցիք ձեր պարգևը:",
    sixDigitCode: "Վեցանիշ կոդ",
    pleaseEnterCustomerRewardCode:
      "Խնդրում ենք մուտքագրել հաճախորդի պարգևատրման ծածկագիրը",
    enterRewardCode:
      "Մուտքագրեք պարգևատրման ծածկագիր: (Պարգևատրման ծածկագիրը մեծատառ է)",
    selectLoyaltyProgram: "Ընտրեք հավատարմության ծրագիր",
    stamps: "Նամականիշեր",
    smsUnitsLow: "SMS միավորները ցածր են",
    pleaseTopUpSmsUnits: "Լրացրեք SMS միավորները",
    smsSuccessfullySentForDelivery: "SMS- ը հաջողությամբ ուղարկվեց առաքման:",
    sendSmsTo: "SMS ուղարկեք էլ",
    allCustomers: "Բոլոր Հաճախորդները",
    unitsAvailable: "Հասանելի միավորներ",
    pleaseTypeInTheMessage: "Խնդրում ենք մուտքագրել հաղորդագրությունը",
    message: "Հաղորդագրություն",
    charactersRemaining: "մնացել են նիշերը",
    avoidUseOfSpecialCharacters:
      "Խուսափեք SMS- ի միավորների պահպանման համար հատուկ կերպարների և էմոջիների օգտագործումից:",
    note: "Նշում",
    errorFetchingCustomersMultilevelDetail:
      "Հաճախորդների բազմակի մակարդակի մանրամասների ստացման սխալ",
    search: "Որոնել",
    reset: "Վերակայել",
    importCustomer: "Ներմուծել հաճախորդ",
    addNewCustomer: "Ավելացնել նոր հաճախորդ",
    sendSmsBroadcast: "Ուղարկեք SMS հեռարձակում",
    totalCustomers: "ԸՆԴՀԱՆՈՐ Հաճախորդներ",
    disbursementDetails: "Մուծման մանրամասները",
    paidBy: "Վճարված է",
    disbursed: "Տրամադրված",
    bankAccountName: "Բանկային հաշվի անվանումը",
    bankAccountNumber: "Բանկային հաշվի համարը",
    transferInitiated: "Տեղափոխումը նախաձեռնված է",
    transferCompleted: "Փոխանցումն ավարտված է",
    pleaseEnterAValid: "Խնդրում ենք մուտքագրել վավեր",
    begin: "սկսել",
    end: "վերջ",
    date: "ամսաթիվը",
    paymentDate: "Վճարման օր",
    selectDisbursementDuration: "Ընտրեք հատկացման տևողությունը",
    totalSettled: "Ընդհանուր կարգավորված",
    totalUnsettled: "Ընդհանուր չկայացած",
    toDeleteThisSale: "ջնջել այս վաճառքը",
    draftSaleDeletedSuccessfully: "Վաճառքի նախագիծը հաջողությամբ ջնջվեց:",
    deleteSale: "Leteնջել վաճառքը",
    pleaseTypeInYourTotal:
      "Խնդրում ենք մուտքագրել ձեր ընդհանուր գումարը `ջնջումը հաստատելու համար",
    billDetails: "Bill Մանրամասներ",
    printBill: "Տպել հաշիվը",
    servedBy: "Vedառայել է",
    total: "Ընդհանուր",
    createdDate: "Ստեղծման ամսաթիվը",
    createdTime: "Ստեղծված ժամանակ",
    status: "Կարգավիճակը",
    loginSuccessful: "Մուտքը հաջողվեց",
    pleaseWaitWhileWeConnectAccount:
      "Խնդրում ենք սպասել, մինչև մենք միացնենք ձեր հաշիվը",
    connectionFailedTryAgain: "Կապը ձախողվեց. Խնդրում եմ կրկին փորձեք.",
    connectionSuccessful: "Կապը հաջողվեց",
    viewDetails: "Մանրամասնորեն",
    enable: "Միացնել",
    free: "Անվճար",
    cost: "Արժեք",
    visitWebsite: "Այցելեք կայք",
    pleaseUpgradeYourPlanToPro:
      "Խնդրում ենք բարձրացնել ձեր ծրագիրը Pro Plus- ին `այս ծրագիրը միացնելու համար",
    connectYourBankAccount:
      "Միացրեք ձեր բանկային հաշիվը, որպեսզի կարողանաք վճարումներ ստանալ:",
    disable: "Անջատել",
    enableApp: "Միացնել հավելվածը",
    addNewProductToInvoice: "Ավելացրեք նոր ապրանքագիր հաշիվ -ապրանքագրին",
    toDeleteThisInvoice: "այս հաշիվ -ապրանքագիրը ջնջելու համար",
    invoiceDeletedSuccessfully: "Հաշիվը հաջողությամբ ջնջվեց:",
    deleteInvoice: "Leteնջել հաշիվ -ապրանքագիրը",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Դուք հաջողությամբ ավելացրել եք հաշիվ -ապրանքագրի վճարման հաղորդագրություն:",
    pleaseEnterPaymentInstructions: "Խնդրում ենք մուտքագրել վճարման հրահանգներ",
    invoiceId: "Հաշիվ -ապրանքագրի ID",
    paidDate: "Վճարված ամսաթիվը",
    reference: "Տեղեկանք",
    productAdded: "Ապրանքը ավելացված է",
    productOutOfStock: "Ապրանքը պահեստում չկա: Խնդրում ենք վերագրանցել:",
    totalInvoices: "ԸՆԴԱՄԵՆԸ ՀԱՎԱՍՏԱԳՐԵՐ",
    totalPaidInvoices: "ԸՆԴԱՄԵՆԸ ՎIDԱՐՎԱ ՀԻՄՆԱԿԱՆՆԵՐ",
    totalUnpaidInvoices: "ԸՆԴԱՄԵՆԸ ԱՆՎIDԱՐ հաշիվ -ապրանքագրեր",
    loyaltyProgramDeleted: "Հավատարմության ծրագիրը ջնջված է",
    thisLoyaltyProgramDeletedSuccessfully:
      "Հավատարմության այս ծրագիրը հաջողությամբ ջնջվել է",
    thisLoyaltyProgramEditedSuccessfully:
      "Հավատարմության այս ծրագիրը հաջողությամբ խմբագրվել է",
    loyaltyProgramAddedSuccessfully:
      "Հավատարմության ծրագիրը հաջողությամբ ավելացվել է",
    loyaltyProgramEdited: "Հավատարմության ծրագիրը խմբագրվել է",
    loyaltyProgramAdded: "Ավելացել է հավատարմության ծրագիրը",
    loyaltyDetails: "Հավատարմության մանրամասներ",
    doYouWantToCloseDialog: "Wantանկանու՞մ եք փակել այս երկխոսությունը:",
    pleaseEnterLoyaltyName: "Խնդրում ենք մուտքագրել ձեր հավատարմության անունը",
    programType: "Programրագրի տեսակը",
    pleaseSelectProgramType: "Խնդրում ենք ընտրել ծրագրի տեսակը",
    simplePoints: "Պարզ միավորներ",
    stampsProgram: "Նամականիշերի ծրագիր",
    threshold: "Շեմ",
    pleaseEnterLoyaltyThreshold: "Մուտքագրեք հավատարմության շեմը",
    reward: "Պարգեւատրում",
    pleaseEnterLoyaltyReward: "Խնդրում ենք մուտքագրել հավատարմության պարգևը",
    totalUserPoints: "Օգտվողների ընդհանուր միավորներ",
    totalUserStamps: "Օգտվողի ընդհանուր նամականիշեր",
    spendingTarget: "Sախսերի թիրախ",
    spendingTargetHint1:
      "Targetախսերի թիրախը այն է, թե հաճախորդը որքան գումար պետք է ծախսի պարգևը վաստակելու համար: 1 արժույթի արժեքը = 1 միավոր:",
    spendingTargetHint2:
      "Նամականիշերի նպատակն այն է, թե քանի նամականիշ պետք է հավաքի հաճախորդը `պարգևը վաստակելու համար: օր. 5",
    addNewLoyaltyProgram: "Ավելացնել հավատարմության նոր ծրագիր",
    addLoyaltyProgram: "Ավելացնել հավատարմության ծրագիր",
    loyaltyProgramType: "Հավատարմության ծրագրի տեսակը",
    pleaseSelectLoyaltyProgramType:
      "Խնդրում ենք ընտրել հավատարմության ծրագրի տեսակը",
    nameOfProgram: "Nameրագրի անվանումը",
    pleaseEnterProgramName: "Խնդրում ենք մուտքագրել ծրագրի անունը",
    whatIsTheReward: "Ի՞նչ է վարձատրությունը:",
    egNextPurchaseIsFree: "Օրինակ ՝ Հաջորդ գնումն անվճար է",
    customerName: "Հաճախորդի անուն",
    guestCustomer: "Հյուր Հաճախորդ",
    orderConfirmedSuccessfully: "Պատվերը հաջողությամբ հաստատվեց",
    orderCancelledSuccessfully: "Պատվերը հաջողությամբ չեղարկվեց",
    confirmOrder: "Հաստատել պատվերը",
    cancelOrder: "Չեղարկել պատվերը",
    allOrders: "Բոլոր պատվերները",
    totalOrders: "Ընդհանուր պատվերներ",
    doYouWantToAcceptOrder: "Wantանկանու՞մ եք ընդունել այս պատվերը:",
    doYouWantToCancelOrder: "Wantանկանու՞մ եք չեղարկել այս պատվերը:",
    orderDetails: "Պատվերի տվյալներ",
    orderCreatedAt: "Պատվերը ստեղծվել է ժամը",
    supplier: "Մատակարար",
    productName: "ապրանքային անուն",
    quantity: "Քանակ",
    unitPrice: "Միավոր գինը",
    receivedBy: "Ստացել է",
    reportFrom: "Հաշվետվություն ՝ ից",
    totalSupplies: "Ընդհանուր մատակարարումներ",
    allRightsReserved: "Բոլոր իրավունքները պաշտպանված են",
    toDeleteThisTransaction: "ջնջել այս Գործարքը",
    transactionDeletedSuccessfully:
      "Գործարքը հաջողությամբ ջնջվել է: Պահեստը վերադարձվել է գույքագրմանը:",
    deleteTransaction: "Deնջել գործարքը",
    transactionDetails: "Գործարքի մանրամասներ",
    printReceipt: "Տպել անդորրագիրը",
    channel: "Ալիք",
    discount: "Զեղչ",
    profit: "Շահույթ",
    discountedSales: "Discեղչված վաճառք",
    errorFetchingRecord: "Սխալ ՝ ձայնագրությունը բեռնելիս",
    exportTransactions: "Արտահանման գործարքներ",
    errorFetchingSalesRecord: "Չհաջողվեց բեռնել Արտահանման վաճառքի ռեկորդը:",
    totalSellingPrice: "Ընդհանուր վաճառքի գինը",
    totalCostPrice: "Ընդհանուր ծախսերի գինը",
    appliedDiscount: "Կիրառական զեղչ",
    noOfItems: "Նյութերի քանակը",
    sales: "Վաճառք",
    export: "Արտահանել",
    totalProfit: "Ընդհանուր շահույթ",
    totalBalanceInPeriod: "Ընդհանուր մնացորդը ժամանակաշրջանում",
    allTimeSales: "Բոլոր ժամանակների վաճառք",
    records: "Գրառումներ",
    todaysSales: "Այսօրվա վաճառք",
    transaction: "գործարք",
    youHaveExceededTotalNumberOfProducts:
      "Դուք գերազանցել եք ձեր պլանում թույլատրված ապրանքների ընդհանուր թիվը: Թարմացրեք ձեր ծրագիրը `ավելի բարձր սահմաններից օգտվելու համար:",
    youNeedToHaveLoyaltyProgram:
      "Այս հնարավորությունն օգտագործելու համար հարկավոր է ունենալ Հավատարմության ծրագիր:",
    price: "Գինը",
    category: "Կատեգորիա",
    stockTracking: "Բաժնետոմսերի հետևում",
    stockCount: "Բաժնետոմսերի հաշվարկ",
    taxed: "Հարկվում է",
    originalPrice: "Օրիգինալ Գին",
    costPrice: "Արժեք Գին",
    unit: "Միավոր",
    productImage: "Ապրանքի պատկերը",
    taxRate: "Հարկային դրույքաչափ",
    taxType: "Հարկի տեսակը",
    trackInventory: "Հետևել գույքագրմանը",
    variants: "Տարբերակներ",
    hasVariants: "Ունի տարբերակներ",
    importProduct: "Ներմուծել ապրանք",
    exportProducts: "Արտահանման արտադրանք",
    addNewProduct: "Ավելացնել նոր ապրանք",
    viewCategory: "Դիտել կատեգորիա",
    viewSuppliers: "Դիտեք Մատակարարներ",
    receiveInventory: "Ստացեք գույքագրում",
    printAllProductsTag: "Տպել բոլոր ապրանքների պիտակը",
    deleteAll: "Նջել բոլորը",
    totalProducts: "ԸՆԴՀԱՆՈՐ ԱՊՐԱՆՔՆԵՐ",
    youveSuccessfullyAddedANewCategory:
      "Դուք հաջողությամբ ավելացրել եք նոր կատեգորիա",
    addNewCategory: "Ավելացնել նոր կատեգորիա",
    addCategory: "Ավելացնել կատեգորիա",
    categoryName: "Կատեգորիայի անվանումը",
    pleaseEnterCategoryName: "Խնդրում ենք մուտքագրել կատեգորիայի անունը",
    stampsTarget: "Նամականիշերի թիրախ",
    sendInventory: "Ուղարկել գույքագրում",
    productDetails: "Ապրանքի մանրամասերը",
    youveSuccessfullyEditedThisCategory:
      "Դուք հաջողությամբ խմբագրել եք այս կատեգորիան",
    update: "Թարմացնել",
    categoryList: "Կատեգորիաների ցուցակ",
    categories: "Կատեգորիաներ",
    enterQuantityToUpdate: "Մուտքագրեք թարմացման քանակ",
    inventorySentSuccessfully: "Գրապահոցը հաջողությամբ ուղարկվեց:",
    updateInventory: "Թարմացնել գույքագրումը",
    currentQuantity: "Ընթացիկ Քանակ",
    pleaseEnterQuantityToAdd:
      "Մուտքագրեք այն քանակությունը, որը ցանկանում եք ավելացնել",
    pleaseSelectABranch: "Խնդրում ենք ընտրել մասնաճյուղ",
    searchForBranch: "Որոնել մասնաճյուղ",
    youCantSendMoreThanStock:
      "Դուք չեք կարող ուղարկել ավելին, քան ունեք պահեստում",
    send: "Ուղարկել",
    pleaseEnterQuantityToSend:
      "Խնդրում ենք մուտքագրել այն քանակությունը, որը ցանկանում եք ուղարկել",
    productNameIsRequiredOnRow: "ապրանքի անվանումը պարտադիր է տողում",
    productCategoryIsRequiredOnRow: "ապրանքի կատեգորիան պարտադիր է շարքում",
    productPriceIsRequiredOnRow: "ապրանքի գինը պահանջվում է անընդմեջ",
    productUnitIsRequiredOnRow: "ապրանքի միավորը պահանջվում է շարքում",
    productQuantityIsRequiredOnRow: "ապրանքի քանակը պահանջվում է շարքում",
    productVariantsRequireTracking:
      "արտադրանքի տարբերակները պահանջում են հետևում:",
    pleaseAddVariantClickButton:
      "Խնդրում ենք ավելացնել տարբերակ ՝ սեղմելով ավելացնել կոճակը:",
    totalVariantsMoreThanSelectedQuantity:
      "Ընդհանուր տարբերակները ավելին են, քան ընտրված արտադրանքի քանակը, Pls- ն նվազեցնում է տարբերակի քանակը",
    productEditedSuccessfully: "Ապրանքը հաջողությամբ խմբագրվել է:",
    fileTooLargeLessThan4Mb:
      "Ֆայլի չափը չափազանց մեծ է: Ֆայլի չափը պետք է լինի 4 ՄԲ -ից պակաս:",
    suchVariantAlreadyExist: "Նման տարբերակ արդեն կա",
    addVariants: "Ավելացնել տարբերակներ",
    editProduct: "Խմբագրել արտադրանքը",
    pleaseEnterProductName: "Խնդրում ենք մուտքագրել Ապրանքի Անունը",
    pleaseEnterProductPrice: "Խնդրում ենք մուտքագրել Ապրանքի Գինը",
    pleaseEnterProductOriginalPrice:
      "Խնդրում ենք մուտքագրել ապրանքի սկզբնական գինը",
    productDescription: "ապրանքի նկարագրությունը",
    selectProductCategory: "Ընտրեք Ապրանքի կատեգորիա",
    pleaseSelectProductCategory: "Խնդրում ենք ընտրել արտադրանքի կատեգորիա",
    productCostPrice: "Ապրանքի արժեքը Գինը",
    productSellingPrice: "Ապրանքի վաճառքի գինը",
    productOriginalPrice: "Ապրանքի օրիգինալ գին",
    maxFileSizeAllowedIs4Mb: "Առավելագույնը Ֆայլի թույլատրելի չափը 4 ՄԲ է",
    productsWithPicturesArePublished:
      "Նկարներով ապրանքները հրապարակվում են Discover կայքում ՝ պատվերներ ստանալու համար",
    shouldThisProductBeTracked: "Արդյո՞ք պետք է հետևել այս ապրանքին:",
    pleaseSelectStockUnit: "Խնդրում ենք ընտրել բաժնետոմս",
    stockUnit: "Ֆոնդային միավոր",
    bag: "Պայուսակ",
    bottle: "ՇՇԻ",
    bunch: "ՓՈԽԱՆԱԿ",
    can: "ԿԱՐՈ Է",
    carton: "ԿԱՐՏՈՆ",
    crate: "ՎԱՐԿԱՅԻՆ",
    cup: "CUP",
    dozen: "ԴՈZՆ",
    gigabytes: "ԳԻԳԱԲԻՏԵՍ",
    gram: "ԳՐԱՄ",
    kilogram: "ԿԻԼՈԳՐԱՄ",
    litre: "ԳՐԻՆ",
    pack: "ՓԱԹԵԹ",
    pair: "PAՈ PAՅՉ",
    pieces: "ԿԱՐ PԵՐ",
    plate: "ՍԱATEԱՆ",
    tonne: "ՏՈՆ (MT)",
    uNIT: "ՄԻԱՎՈՐ",
    yard: "ԲԱԿ",
    pleaseEnterProductQuantity: "Խնդրում ենք մուտքագրել արտադրանքի քանակը",
    productQuantity: "Ապրանքի քանակը",
    isThisProductTaxed: "Արդյո՞ք այս ապրանքը հարկվում է:",
    selectTaxType: "Ընտրեք Հարկի տեսակը",
    pleaseSelectTaxType: "Խնդրում ենք ընտրել Հարկի տեսակը",
    progressive: "Առաջադիմական",
    proportional: "Համաչափ",
    pleaseEnterProductTaxRate:
      "Խնդրում ենք մուտքագրել արտադրանքի հարկի դրույքաչափը",
    doesProductHaveVariants: "Այս ապրանքը ունի՞ տարբերակներ:",
    type: "Տիպ",
    value: "Արժեք",
    pleaseEnterVariantType: "Խնդրում ենք մուտքագրել տարբերակի տեսակը",
    pleaseEnterVariantValue: "Խնդրում ենք մուտքագրել տարբերակի արժեքը",
    pleaseEnterVariantPrice: "Խնդրում ենք մուտքագրել տարբերակի գինը",
    pleaseEnterVariantQuantity: "Խնդրում ենք մուտքագրել տարբերակի քանակը",
    productDeletedSuccessfully: "Ապրանքը հաջողությամբ ջնջվել է:",
    categoryDeletedSuccessfully: "Կատեգորիան հաջողությամբ ջնջվեց:",
    toDeleteThisProduct: "ջնջել այս Ապրանքը",
    invalidProductQuantity: "Ապրանքի անվավեր քանակ",
    quantityAddedIsOverStock:
      "Ձեր ավելացրած քանակությունը գերազանցում է այն, ինչ ունեք պահեստում:",
    itemInventoryNotTracked: "Նյութերի գույքագրումը չի վերահսկվում",
    addBulkQuantity: "Ավելացնել զանգվածային քանակ",
    enterBulkQuantity: "Մուտքագրեք զանգվածային քանակ",
    pleaseEnterBulkQuantity: "Խնդրում ենք մուտքագրել զանգվածային քանակ",
    youveSuccessfullyAddedANewProduct:
      "Դուք հաջողությամբ ավելացրել եք նոր ապրանք:",
    pleaseEnterProductSellingPrice:
      "Խնդրում ենք մուտքագրել ապրանքի վաճառքի գինը",
    doYouWantToTrackProductStock:
      "Youանկանու՞մ եք հետևել արտադրանքի պաշարներին:",
    sellingPrice: "Վաճառքի գինը",
    setProductExpiryReminder: "Սահմանեք ապրանքի ժամկետի ավարտի հիշեցում",
    productExpiryDate: "Ապրանքի ժամկետի ավարտը",
    startRemindingFrom: "Սկսեք հիշեցում - ից",
    productSuppliesAddedSuccessfully:
      "Դուք հաջողությամբ ավելացրել եք արտադրանքի պաշարները:",
    addProductSupplies: "Ավելացնել արտադրանքի պարագաներ",
    pleaseSelectSupplier: "Խնդրում ենք ընտրել մատակարարին",
    nameOfProduct: "Ապրանքի անվանումը",
    pleaseSelectProduct: "Խնդրում ենք ընտրել Ապրանքը",
    productVariant: "Ապրանքի տարբերակ",
    pleaseSelectAVariant: "Խնդրում ենք ընտրել տարբերակ",
    pleaseEnterUnitPrice: "Խնդրում ենք մուտքագրել միավորի գինը",
    businessBranch: "Բիզնեսի մասնաճյուղ",
    pleaseSelectBranch: "Խնդրում ենք ընտրել մասնաճյուղը",
    youveSuccessfullyAddedANewSupplier:
      "Դուք հաջողությամբ ավելացրել եք նոր մատակարար",
    addSupplier: "Ավելացնել մատակարարին",
    pleaseEnterSupplierEmail: "Խնդրում ենք մուտքագրել մատակարարի էլ",
    pleaseAddADescription: "Խնդրում ենք ավելացնել նկարագրություն",
    anErrorOccuredProductsDeleted:
      "Գործողությունը կատարելիս սխալ է տեղի ունեցել: Խնդրում ենք նկատի ունենալ, մի քանի ապրանք կարող է ջնջվել գործընթացում",
    bulkDelete: "Kանգվածային ջնջում",
    youAreAboutToDelete: "Դուք պատրաստվում եք ջնջել",
    product: "Արտադրանք",
    isDueToRedeem: "մարվելու է",
    aReward: "վարձատրություն",
    pleaseSelectCustomerToReeemReward:
      "Խնդրում ենք ընտրել հաճախորդին ՝ պարգևը մարելու համար:",
    youHaveNoLoyaltyProgramRunning:
      "Դուք չունեք ակտիվ հավատարմության ծրագիր, որը գործում է",
    customerHhasNoPointsInLoyaltyProgram:
      "Այս հավատարմության ծրագրում հաճախորդը միավորներ չունի",
    proceedWithPayment: "Շարունակե՞լ վճարումով:",
    youAreAboutToPayForTransactionUsingPoints:
      "Դուք պատրաստվում եք վճարել գործարքի համար ՝ օգտագործելով միավորներ:",
    customerHas: "Հաճախորդն ունի",
    worth: "արժանի",
    andIsNotSufficientToPayForTransaction:
      "և բավարար չէ այս գործարքի համար վճարելու համար: Արդյո՞ք նրանք կցանկանան ավելացնել մնացորդը ՝ օգտագործելով վճարման այլ եղանակ:",
    addCustomerLoyalty: "Ավելացնել հաճախորդների հավատարմություն",
    pleaseAddCustomerFirst: "Խնդրում ենք նախ ավելացնել կամ ընտրել հաճախորդ:",
    pleaseWaitWhileWeGetReady: "Խնդրում ենք սպասել, մինչ մենք պատրաստվենք",
    lowStock: "Lowածր ֆոնդ",
    pleaseEnterAmountTendered: "Խնդրում ենք մուտքագրել առաջարկվող գումարը",
    areYouSureToBookSaleOffline:
      "Վստա՞հ եք, որ ցանկանում եք գրանցել այս վաճառքը անցանց ռեժիմում:",
    saleWouldBeBookedAutomatically:
      "Վաճառքը ինքնաբերաբար կգրանցվի, երբ միացնեք ձեր ինտերնետը",
    offlineSalesBookingCancelled: "Անցանց վաճառքի ամրագրումը չեղարկվեց",
    covid19Message:
      "COVID19. Լվացեք ձեր ձեռքերը օճառով կամ ախտահանեք ՝ տարածումը դադարեցնելու համար: Եղեք միշտ ապահով",
    saleSuccessfullyRecorded: "Վաճառքը հաջողությամբ գրանցված է:",
    sendReceiptToEmail: "Ուղարկեք անդորրագիր էլ",
    sendThankYouSms: "Ուղարկեք շնորհակալական SMS",
    sendShippingDetails: "Ուղարկեք առաքման մանրամասները",
    addLoyalty: "Ավելացնել հավատարմություն",
    searchCustomerNameOrNumber: "Որոնեք հաճախորդի անունը կամ համարը",
    clickTheSearchCustomerBox:
      "Կտտացրեք Search Customer Box- ին և Scan Card- ին",
    enterProductPrice: "Մուտքագրեք Ապրանքի Գինը",
    enterPriceFor: "Մուտքագրեք գինը",
    searchForProduct: "Որոնեք ապրանք",
    all: "Բոլորը",
    backToDashboard: "Վերադառնալ վահանակ",
    viewDraftSales: "Դիտեք վաճառքի նախագիծը",
    variantSelected: "տարբերակ է ընտրված",
    variant: "տարբերակ",
    thePreviousVariantSelectionNotAvailable:
      "Գնի հիման վրա ընտրված նոր տարբերակի համար նախորդ տարբերակի ընտրությունը հասանելի չէ: Խնդրում ենք փոխել ձեր ընտրությունը:",
    pleaseSupplyPositiveQuantityNumber:
      "Խնդրում ենք տրամադրել պոստատիվ քանակի համար",
    lowStockFor: "Lowածր բաժնետոմսերի համար",
    clearVariants: "Հստակ տարբերակներ",
    pleaseEnterQuantity: "Խնդրում ենք մուտքագրել Քանակ",
    picture: "Նկար",
    redemptionToken: "Փրկման խորհրդանիշ",
    token: "Նշան",
    totalSpent: "Ընդհանուր ծախսված",
    confirmPayment: "Հաստատեք վճարումը",
    hasPaymentBeenMade: "Արդյո՞ք վճարումը հաջողությամբ իրականացվեց:",
    enterTransactionReference: "Մուտքագրեք գործարքի տեղեկանքը, որով վճարել եք",
    pleaseSelectACustomer: "Խնդրում ենք ընտրել հաճախորդ:",
    areYouSureToApplyDiscount: "Վստա՞հ եք, որ ցանկանում եք զեղչ կիրառել:",
    addYourBankAccountToEnableUssd:
      "Ավելացրեք ձեր բանկային հաշիվը ՝ uPay- ի միջոցով USSD ակնթարթային փոխանցումը միացնելու համար",
    totalAmountToPay: "Վճարման ընդհանուր գումարը",
    doYouWantToCancelTransaction: "Wantանկանու՞մ եք չեղարկել այս գործարքը:",
    savePrintBill: "Պահել/տպել հաշիվը",
    enterAmountCollectedForCustomer:
      "Մուտքագրեք հաճախորդի համար հավաքված գումարը",
    recordSale: "Ռեկորդային վաճառք",
    checkOutWith: "Ստուգեք հետ",
    instantTransfer: "Ակնթարթային փոխանցում",
    dialTheUSSDCode: "Հավաքեք USSD ծածկագիրը",
    pleaseSelectABank: "Խնդրում ենք ընտրել բանկ",
    payWithUSSD: "Վճարեք USSD- ով",
    sendBillTo: " - Բիլին ուղարկիր",
    waitingForUSSDTransfer: "Սպասում ենք USSD փոխանցմանը",
    percent: "Տոկոս",
    applyDiscount: "Կիրառել զեղչ",
    thisBillHasBeenSaved: "Այս օրինագիծը պահպանվել է",
    saveDraft: "Պահել նախագիծը",
    pleaseTypeANameToIdentifyCustomer:
      "Խնդրում ենք մուտքագրել անուն ՝ հաճախորդին նույնականացնելու համար",
    paymentDetails: "Վճարման մանրամասներ",
    basePrice: "Հիմնական գինը",
    staff: "Անձնակազմ",
    subTotal: "Ենթաընդհանուր",
    durationMonths: "Տևողությունը (ամիս)",
    selectADuration: "Ընտրեք տևողություն",
    oneMonth: "1 ամիս",
    twoMonths: "2 ամիս",
    threeMonths: "3 ամիս",
    sixMonths: "6 ամիս",
    twelveMonths: "12 ամիս",
    eighteenMonths: "18 ամիս",
    twentyFourMonths: "24 ամիս",
    twoMonthsFree: "(2 ամիս անվճար)",
    threeMonthsFree: "(3 ամիս անվճար)",
    fiveMonthsFree: "(5 ամիս անվճար)",
    yourAccountHasBeen: "Ձեր հաշիվը եղել է",
    renewed: "նորացվել է",
    upgraded: "արդիականացվել է",
    successfully: "հաջողությամբ",
    yourSubscription: "Ձեր բաժանորդագրությունը",
    youAreCurrentlyEnrolledOnA: "Դուք այժմ գրանցված եք ա",
    pleaseChooseAPaymentOption: "Խնդրում ենք ընտրել Վճարման տարբերակ",
    planRenewal: "Պլանի նորացում",
    planUpgrade: "Պլանի բարելավում",
    pleaseSelectDurationToPayFor:
      "Խնդրում ենք ընտրել տևողությունը, որի համար մտադիր եք վճարել",
    staffAccounts: "Աշխատակազմի հաշիվներ",
    ecommerce: "Էլեկտրոնային առևտուր",
    pleaseSelectAPlan: "Խնդրում ենք ընտրել ծրագիր",
    includeAddons: "Ներառեք հավելումներ",
    viewTransactions: "Դիտեք գործարքները",
    customerHasNoCompletedTansactions: "Հաճախորդը դեռ չունի ավարտված մերժում",
    branch: "Մասնաճյուղ",
    enterNumberOfEcommerceBranches:
      "Մուտքագրեք էլեկտրոնային առևտրի մասնաճյուղերի թիվը",
    enterNumberOfEcommerceBranchesToPay:
      "Մուտքագրեք էլեկտրոնային առևտրի այն մասնաճյուղերի քանակը, որոնց համար մտադիր եք վճարել",
    ecommerceIntegration: "Էլեկտրոնային առևտրի ինտեգրում",
    enterNumberOfBranches: "Մուտքագրեք մասնաճյուղերի թիվը",
    enterNumberOfAdditionalBranchesToPay:
      "Մուտքագրեք լրացուցիչ մասնաճյուղերի քանակը, որոնց համար մտադիր եք վճարել",
    enterNumberOfStaffs: "Մուտքագրեք աշխատակազմի թիվը",
    enterNumberOfStaffsToPayFor:
      "Մուտքագրեք աշխատակազմի քանակը, որոնց համար նախատեսում եք վճարել",
    discountApplies: "Գործում է զեղչ",
    starsOnThe: "աստղերը վրա",
    offer: "առաջարկել",
    get: "Ստացեք",
    moreStarsToRedeem: "ավելի շատ աստղեր մարելու համար",
    taxVat: "Հարկ (ԱԱՀ)",
    callCashierToCompletePayment:
      "Callանգահարեք գանձապահին ՝ վճարումն ավարտելու համար",
    receipt: "Անդորրագիր",
    dear: "սիրելի",
    thankYouForYourOrderFindGoods:
      "Շնորհակալություն Ձեր պատվերի համար. Խնդրում ենք գտնել մատակարարված հետևյալ ապրանքները ՝ համաձայնեցված:",
    shippingNote: "Առաքման նշում",
    enterShippingNote: "Մուտքագրեք Առաքման Նշում",
    shippingAddress: "Աոաքման Հասցե",
    enterShippingAddress: "Մուտքագրեք Առաքման հասցեն",
    wellDoneYouAreDueToRedeem: "Լավ արեց: Դուք պետք է մարեք",
    toGetYourRewardNextVisit:
      "հաջորդ այցի ժամանակ ձեր պարգևը ստանալու համար: Շնորհակալություն",
    pointsOnThe: "Կետեր վրա",
    morePointsToRedeem: "ավելի շատ միավորներ մարելու համար",
    showCode: "Showույց տալ կոդը",
    toGetYourRewardOnNextVisit:
      "հաջորդ այցի ժամանակ ձեր պարգևը ստանալու համար: Շնորհակալություն",
    earn: "Վաստակեք",
    delivaryNote: "Առաքման նշում",
    draftSales: "Վաճառքի նախագիծ",
    startDate: "Մեկնարկի ամսաթիվ",
    endDate: "Ավարտի ամսաթիվը",
    orders: "Պատվերներ",
    checkout: "դրամարկղ",
    noProductItem: "Ապրանքի տարր չկա",
    selectProductImage: "Ընտրեք Ապրանքի պատկերը",
    selectCountry: "Ընտրեք երկիր",
    selectRegion: "Ընտրեք նահանգ/շրջան",
    printProductTag: "Տպել ապրանքի պիտակը",
    transactionReference: "Գործարքի տեղեկանք",
    Cashier: "Գանձապահ",
    Manager: "Մենեջեր",
    Owner: "Սեփականատեր",
    Admin: "Ադմինիստրատոր",
  },
  Azerbaijani: {
    cashier: "kassir",
    manager: "menecer",
    owner: "sahibi",
    online: "onlayn",
    offline: "oflayn",
    changePassword: "Şifrəni dəyişdirin",
    currentPasswordMessage: "Zəhmət olmasa cari parolunuzu daxil edin",
    passwordMessage: "Zəhmət olmasa parolunuzu daxil edin",
    currentPassword: "Hazırkı şifrə",
    password: "parol",
    confirmPasswordMessage: "Zəhmət olmasa şifrənizi təsdiq edin!",
    confirmPassword: "Şifrəni təsdiq edin",
    sendViaEmail: "E -poçtla Göndərin",
    sendViaWhatsapp: "WhatsApp vasitəsi ilə göndərin",
    downloadPdf: "PDF yükləyin",
    paid: "ödənişli",
    unpaid: "ödənişsiz",
    partial: "qismən",
    closeInvoice: "Fakturanı bağlamaq istəyirsiniz?",
    closeInvoiceConfirmation: "Faktura saxlanıla bilməz. Bağlamaq istəyirsən?",
    yes: "bəli",
    no: "yox",
    invoice: "Faktura",
    wasDeducted: "çıxarıldı",
    for: "üçün",
    item: "Maddə",
    addProduct: "Məhsul əlavə edin",
    paymentReference: "Ödəniş Referansı",
    amountPaid: "Ödənilmiş məbləğ",
    discountAmount: "Endirim Məbləği",
    amountDue: "Ödəniləcək məbləğ",
    amount: "Məbləğ",
    dueDate: "Bitiş tarixi, son tarix",
    paymentType: "Ödəniş növü",
    card: "Kart",
    cash: "Nağd pul",
    bankTransfer: "Bank köçürməsi",
    paymentMessage: "Ödəniş Mesajı",
    description: "Təsvir",
    sendReceipt: "Qəbz göndərin",
    delete: "Silin",
    save: "Yadda saxla",
    resend: "Yenidən göndərmək",
    saveAndSend: "Yadda saxla",
    invoiceSaved: "Faktura saxlanıldı!",
    selectPaymentMethod: "Zəhmət olmasa Ödəniş metodunu seçin!",
    selectCustomer: "Zəhmət olmasa Müştəri seçin!",
    cartEmptyError:
      "Səbət siyahısı boş ola bilməz, hesab -fakturanı bağlayın və məhsulu səbətə əlavə edin!",
    subscriptionExpired:
      "Abunəliyinizin müddəti bitdi və hesabınız artıq məhduddur. Hesabınızı yeniləmək üçün aşağıdakı düyməni basın",
    renew: "Yeniləyin",
    holdOn: "Gözlə",
    customerBank: "Müştəri Bankı",
    cancel: "Ləğv et",
    selectACustomer: "Müştəri seçin",
    invoiceSuccessfulPdfError:
      "Faktura uğurla yaradıldı, lakin PDF Nəsil edilməsi həmişəkindən daha uzun sürdü. Zəhmət olmasa bir qədər sonra yoxlayın.",
    downloadingInvoice: "Faktura endirilir",
    downloadingReceipt: "Qəbz endirilir",
    whatsappReceiptError:
      "Qəbz WhatsApp vasitəsilə göndərilərkən xəta baş verdi, yenidən cəhd edin.",
    receiptToWhatsapp: "Qəbz WhatsApp -a göndərildi",
    thankYouForPatronage: "Himayəniz üçün təşəkkür edirik",
    hereIsYourReceipt: "Budur ödəniş qəbziniz",
    errorSendingEmailReceipt:
      "Qəbzi e-poçtla göndərərkən xəta baş verdi, yenidən cəhd edin və ya dəstəklə əlaqə saxlayın",
    receiptSentToEmail: "Qəbz müştərinin e -poçtuna göndərildi",
    invoiceSentToEmail: "Faktura müştərinin e -poçtuna göndərildi",
    invoiceSuccessWhatsappError:
      "Faktura uğurla yaradıldı, lakin WhatsApp -a göndərərkən xəta baş verdi. Faktura siyahısında yenidən cəhd edin",
    invoiceSuccessfulEmailError:
      "Faktura uğurla yaradıldı, ancaq e-poçt olaraq göndərilərkən bir səhv baş verdi. Faktura siyahısından yenidən cəhd edin",
    invoiceSentToWhatsapp: "Faktura WhatsApp -a göndərildi",
    hello: "Salam",
    pleaseDownloadInvoice: "Zəhmət olmasa fakturanı yükləyin",
    pleaseDownloadReceipt: "Zəhmət olmasa qəbzi yükləyin",
    from: "dan",
    email: "E -poçt",
    upgrade: "Təkmilləşdirin",
    youAreOnFreePlan: "Pulsuz bir Plana sahibsiniz.",
    clickOn: "Basın",
    yourPlanInFewSteps: " abunəliyinizi bir neçə sürətli addımda edin.",
    to: "-ə",
    yourSubscriptionHasExpired:
      "Abunəliyinizin müddəti bitdi və hesabınız artıq məhduddur.",
    days: "günlər",
    yourSubscriptionExpiresIn: "Loystar abunəliyiniz sona çatır",
    createAcount: "Hesab yarat",
    signIn: "Daxil ol",
    continue: "Davam et",
    enterOtp: "OTP PIN daxil edin",
    enterValidOtp: "Zəhmət olmasa etibarlı bir PIN daxil edin",
    pin: "PİN",
    tokenSentToMail: "E -poçtunuza bir işarə göndərildi",
    passwordResetSuccessful: "Şifrə sıfırlama uğurlu oldu",
    somethingWentWrong: "Nəsə səhv oldu!",
    resetPassword: "Parolu sıfırlamak",
    iHaveResetCode: "Şifrə sıfırlama kodum var",
    pleaseEnterEmail: "Zəhmət olmasa e -poçtunuzu daxil edin",
    aTokenWillBeSentToEmail: "E -poçtunuza bir işarə göndəriləcək",
    enterEmail: "E -poçtunuzu daxil edin",
    sendinYourToken: "İşarəniz göndərilir ...",
    makeSureItMatchesPassword: "Yeni parolunuzla uyğun olduğundan əmin olun",
    pleaseChooseNewPassword: "Yeni parol seçin",
    chooseNewPassword: "Yeni bir parol seçin",
    enterNewPassword: "Təsdiq etmək üçün yeni şifrənizi daxil edin",
    enterTokenSent: "Məktubunuzu göndərdiyiniz nişanı daxil edin",
    resetToken: "Tokeni sıfırlayın",
    resettingPassword: "Şifrəniz sıfırlanır ...",
    signUp: "Abunə olun",
    adminSignInWithEmail:
      " İdarəçi e -poçtla, İşçi isə istifadəçi adı ilə daxil olur.",
    pleaseEnterEmailOrUsername:
      "Zəhmət olmasa e -poçtunuzu və ya istifadəçi adınızı daxil edin",
    emailOrUsername: "E-poçt və ya istifadəçi adı",
    pleaseEnterPassword: "Zəhmət olmasa parol daxil edin",
    createAnAccount: "Hesab yarat",
    forgotPassword: "Şifrəni unutmusunuz?",
    enterPhoneNumber: "Telefon nömrəsini daxil edin",
    personalData: "Şəxsi məlumat",
    validateConfirmationCOde: "Təsdiq kodunu təsdiq edin",
    pleaseEnterFirstName: "Zəhmət olmasa adınızı daxil edin",
    pleaseEnterPhoneNumber: "Zəhmət olmasa telefon nömrənizi daxil edin",
    pleaseEnterLastName: "Zəhmət olmasa soyadınızı daxil edin",
    pleaseEnterBusinessName: "Zəhmət olmasa iş adınızı daxil edin",
    firstName: "Adı",
    lastName: "Soyad",
    businessName: "İş Adı",
    previous: "Əvvəlki",
    next: "Sonrakı",
    pleaseSelectBusinessCategory: "Zəhmət olmasa biznes kateqoriyanızı seçin",
    pleaseEnterValidEmail: "Zəhmət olmasa etibarlı bir e -poçt daxil edin",
    pleaseEnterPostCode: "Zəhmət olmasa poçt kodunu daxil edin",
    postCode: "Poçt Kodu",
    phoneNumberInUse: "Bu telefon nömrəsi artıq istifadə olunur!",
    emailInUse: "Bu e -poçt artıq istifadə olunur!",
    foodAndDrinks: "Qida və içkilər",
    salonAndBeauty: "Salon və Gözəllik",
    fashionAndAccessories: "Moda və Aksesuarlar",
    gymAndFitness: "İdman və Fitness",
    travelAndHotel: "Səyahət və Otel",
    homeAndGifts: "Ev və Hədiyyələr",
    washingAndCleaning: "Yuma və Təmizləmə",
    gadgetsAndElectronics: "Alətlər və Elektronika",
    groceries: "Ərzaq məhsulları",
    others: "Digərləri",
    submit: "təqdim",
    accountCreatedSuccessful: "Hesabınız uğurla yaradıldı.",
    pleaseEnterAddress: "Zəhmət olmasa Ünvanınızı daxil edin",
    addressLine1: "Ünvan xətti 1",
    addressLine2: "Ünvan xətti 2",
    choosePassword: "Şifrəni seçmək",
    passwordMustBe6Characters: "Şifrə ən azı 6 simvoldan ibarət olmalıdır.",
    howDidYouHearLoystar: "Loystar haqqında necə eşitdiniz?",
    referralCode: "müraciət kodu",
    byClickingTheButton: " Aşağıdakı düyməni basaraq, ilə razılaşırsınız",
    termsAndSevice: "Şərtlər",
    wereCreatingAccount: "Hesabınızı yaradırıq",
    proceed: "Davam et",
    verificationCodeMustBe6: "Doğrulama kodu 6 rəqəmdən ibarət olmalıdır",
    pleaseEnter6DigitCode: "Zəhmət olmasa 6 rəqəmli kodu daxil edin",
    enterVerificationCode: "Doğrulama Kodunu daxil edin",
    resendToken: "Tokeni yenidən göndərin",
    verify: "Doğrulayın",
    transactions: "Əməliyyatlar",
    todaySales: "Bugünkü Satışlar",
    salesHistory: "Satış Tarixi",
    supplyHistory: "Təchizat Tarixi",
    new: "Yeni",
    invoices: "Faturalar",
    disbursements: "Ödənişlər",
    offlineSales: "Oflayn Satışlar",
    products: "Məhsullar",
    customers: "Müştərilər",
    multiLevelLoyalty: "Çox səviyyəli sadiqlik",
    loyaltyPrograms: "Sadiqlik Proqramları",
    members: "Üzvlər",
    appStore: "App Store",
    orderMenu: "Sifariş Menyu",
    settings: "Ayarlar",
    staffAndBranches: "Kadrlar və Filiallar",
    myAccount: "Mənim Hesabım",
    switchToSellMode: "Satış rejiminə keçin",
    signOut: "Çıxış",
    getFreeSubscription: "Pulsuz Abunəlik əldə edin",
    onlyNumbersAllowed: "Yalnız nömrələrə icazə verilir",
    yourAccountMustBe10Digits: "hesab nömrəniz 10 rəqəmdən ibarət olmalıdır",
    yourBvnMustBe11: "BVN nömrəniz 11 rəqəmdən ibarət olmalıdır",
    pleaseSelectBank: "Zəhmət olmasa bankınızı seçin",
    selectYourBank: "Bankınızı seçin",
    enterBankAccountNumber: "Bank Hesab nömrəsini daxil edin",
    enterBvnNumber: "BVN daxil edin",
    connectBankAccount: "Bank Hesabına qoşulun",
    passwordResetSuccessfulAndSignOut:
      "Parolunuz uğurla sıfırlandı. Yenidən daxil olmaq üçün aşağıdakı Çıxış düyməsini vurun",
    enterCurrentPassword: "Cari şifrəni daxil edin",
    pleaseEnterCurrentPassword: "Zəhmət olmasa cari şifrənizi daxil edin",
    phoneNumber: "Telefon nömrəsi",
    sex: "Seks",
    dateOfBirth: "Doğum tarixi",
    state: "Dövlət",
    country: "Ölkə",
    loyaltyId: "Sadiqlik ID",
    createdAt: "At yaradıldı",
    noOfTransactionsMade: "Edilən əməliyyatların sayı",
    yourDownloadWillStart: "Yükləməniz bir an sonra başlayacaq",
    exportCustomers: "Müştəriləri ixrac edin",
    youhaveSuccessfullyToppedUp: "SMS vahidlərinizi uğurla doldurdunuz.",
    youNowHave: "İndi var",
    smsUnits: "SMS vahidləri",
    enterNumberOfSmsUnits:
      "Almaq istədiyiniz SMS vahidlərinin sayını daxil edin",
    pleaseEnterANumericValue: "Zəhmət olmasa rəqəmsal bir dəyər daxil edin",
    pay: "Ödə",
    accountEditedSuccessfully: "Hesabınız uğurla redaktə edildi.",
    youAeCurrentlyOn: "Hazırda aktivsiniz",
    plan: "Plan",
    userData: "İstifadəçi Məlumatları",
    businessData: "İŞ BİLGİLƏRİ",
    businessCategory: "İş Catergory",
    pleaseSelectCurrency: "Zəhmət olmasa valyutanızı seçin",
    selectYourCurrency: "Valyutanızı seçin",
    purchaseMoreSmsUnits:
      "Aşağıdakı formu istifadə edərək daha çox SMS vahidi satın alın",
    youHave: "Var",
    atLeast4SmsUnitsRrequired:
      "Doğrulama üçün ən azı 4 sms vahid tələb olunur, zəhmət olmasa doldurun!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Zəhmət olmasa 'Müştərinizi tanıyın' (KYC) tələblərinə uyğun olmaq üçün bank hesabınızı doğrulayın. Bu, USSD və ya ani köçürmə yolu ilə ödənişlər toplamağa, müştərilərdən sifariş almağa və əməliyyatların həllini emal etməyə imkan verəcək. Yalnız Nigeriyalı tacirlər üçün keçərlidir. Başlamaq üçün aşağıdakı düyməni basın.",
    reConnectBankAccount: "Bank Hesabını yenidən bağlayın",
    accountName: "Hesab adı",
    accountNumber: "Hesab nömrəsi",
    bankName: "Bank adı",
    verified: "Doğrulandı",
    accountDetails: "Hesab Təfərrüatları",
    kycBankAccount: "KYC",
    createTier: "Tier yaradın",
    fileUploadSuccessful: "fayl uğurla yükləndi",
    fileUploadFailed: "fayl yüklənmədi",
    createLoyaltyProgram: "Sadiqlik Proqramı yaradın",
    createLoyalty: "Sadiqlik yaradın",
    name: "Adı",
    loyaltyArtwork: "Sadiqlik Sənəti",
    clickToUpload: "Yükləmək üçün vurun",
    amountToPointsRatio: "Məbləğ nisbəti",
    points: "Xallar",
    recommendedAmountToPointRatio:
      "Tövsiyə olunur: ₦ 1 - 1 bal. yəni xərclənən hər 1 Naira üçün müştərilərinizə 1 xal verilir",
    pleaseTypeIn: "Zəhmət olmasa yazın",
    toDeleteLoyalty: "bu sadiqliyi silmək üçün",
    deleteLoyalty: "Sadiqliyi silin",
    toConfirm: "təsdiq etmək",
    edit: "Redaktə edin",
    pointsAwardedSuccessfully: "Xallar Uğurla Verildi.",
    enterPointValueToAward:
      "Müştəriləri mükafatlandırmaq istədiyiniz nöqtə dəyərini daxil edin",
    award: "Mükafat",
    awardPointValuesToCustomer: "Müştəriyə verilən dəyər nöqtəsi",
    enrollMembersToLoyalty: "Üzvləri Sadiqliyə yazın",
    awardPoints: "Mükafat Xalları",
    enroll: "Qeydiyyatdan keçin",
    home: "Ev",
    loyalty: "Sadiqlik",
    enrollCustomers: "Müştəriləri qeyd edin",
    selected: "Seçildi",
    customer: "Müştəri",
    loyaltyActivationSuccessful: "Sadiqlik Aktivləşdirmə uğurludur.",
    loyaltyDeactivationSuccessful: "Sadiqliyin Deaktivasiyası uğurlu alındı.",
    viewTier: "Səviyyəyə baxın",
    deactivate: "Deaktiv edin",
    activate: "Aktiv edin",
    actions: "Tədbirlər",
    nameOfLoyalty: "Sadiqliyin adı",
    loyaltyStatus: "Sadiqlik Vəziyyəti",
    numberOfTiers: "Qatların sayı",
    createdOn: "Yaradılma tarixi",
    createATier: "Tier yaradın",
    stopCreatingTierConfirmation:
      "Bir pillə yaratmağı dayandırmaq istəyirsiniz?",
    stopEditingTierConfirmation:
      "Bu pilləni redaktə etməyi dayandırmaq istəyirsiniz?",
    nameOfTier: "Tier adı",
    minimumSpendingTarget: "Minimum xərcləmə hədəfi",
    maximumSpendingTarget: "Maksimum xərcləmə hədəfi",
    minimumSpendingTargetRequired: "minimum xərcləmə hədəfi tələb olunur",
    maximumSpendingTargetRequired: "maksimum xərcləmə hədəfi tələb olunur",
    rewardSponsor: "Mükafat Sponsoru",
    pleaseChooseARewardSponsor: "Zəhmət olmasa bir sponsor seçin",
    self: "Özünü",
    partner: "Tərəfdaş",
    rewardPartner: "Mükafat Tərəfdaşı",
    pleaseSelectRewardPartner: "Zəhmət olmasa mükafat ortağınızı seçin",
    rewards: "Mükafatlar",
    pleaseSelectAReward: "Zəhmət olmasa bir mükafat seçin",
    instructionsOnRedeemingReward:
      "Müştərinin Mükafatı necə istifadə etməli olduğuna dair təlimatlar",
    pleaseFillInThisField: "Zəhmət olmasa bu sahəni doldurun!",
    toDeleteThisTier: " bu səviyyəni silmək üçün",
    deleteTier: "Səviyyəni silin",
    viewMembers: "Üzvlərə baxın",
    membersEnrolled: "Üzvlər Qeydiyyatdan keçdi",
    instruction: "Təlimat",
    membersIn: "Üzvlər daxil",
    availableTiersInLoyalty: "Sadiqlik Proqramında Mövcud Tier (lər)",
    tiers: "Səviyyələr",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Mövcud Sadiqlik Proqramı",
    totalLoyalties: "TOPLAM SADİQƏTLƏR",
    memberDetails: "Üzv Təfərrüatları",
    nameOfCustomer: "Müştərinin adı",
    address: "Ünvan",
    allEnrolledMembers: "Bütün Qeydiyyat Üzvləri",
    thisIsToWishYouHappyBirthday:
      "Bu, doğum gününüz mübarək və firavan həyat arzulayıram. Loysterə etdiyiniz hər şey üçün təşəkkür edirik. Bayramınız mübarək!",
    inputAnOfferPlease: "Zəhmət olmasa bir təklif daxil edin",
    pleaseSelectTheInsertPoint:
      "Zəhmət olmasa mesajdakı əlavə nöqtəsini seçin və yenidən basın",
    birthdayOfferAndMessage: "Doğum Günü Təklifi və Mesajı",
    birthdayOffer: "Ad günü təklifi",
    birthdayMessage: "Ad günü mesajı",
    noOfferFound: "Təklif tapılmadı",
    settingABirthdayOffer:
      "Doğum günü təklifi təyin etmək müştərilərə bu təklifi doğum günlərində SMS vasitəsilə almağa imkan verir",
    createOffer: "Təklif Yarat",
    whatIsTheOffer: "Təklif nədir?",
    editMessage: "Mesajı redaktə edin",
    insert: "Daxil edin",
    theNameOfCustomerInserted: "Müştərinin adı bura daxil ediləcək",
    theBirtdayOfferInserted: "Ad günü təklifi bura daxil ediləcək",
    youSuccessfullyAddedNewBranch: "Yeni bir filialı uğurla əlavə etdiniz!",
    addNewBranch: "Yeni filial əlavə edin",
    addBranch: "Filial əlavə edin",
    additionalBranchWillIncur: "Əlavə Şöbə yaranacaq",
    perBranch: "filial başına",
    ecommerceBranchCosts: "Elektron Ticarət Şöbəsinin xərcləri",
    pleaseEnterBranchName: "Zəhmət olmasa filialın adını daxil edin",
    pleaseEnterBranchAddress1:
      "Zəhmət olmasa filialın ünvan sətrini 1 daxil edin",
    enterBranchAddress1: "Filialın ünvan sətrini 1 daxil edin",
    enterBranchAddress2: "Filialın ünvan sətrini 1 daxil edin",
    pleaseEnterBranchAddress2:
      "Zəhmət olmasa filialın ünvan xəttini 2 daxil edin",
    enterBranchName: "Filialın adını daxil edin",
    successfullyAddedStaff: "Uğurla yeni bir heyət əlavə etdiniz!",
    addNewStaff: "Yeni Kadr əlavə edin",
    addStaff: "Kadr əlavə edin",
    additionalStaffWillIncur: "Əlavə işçilərə ehtiyac olacaq",
    perStaff: "heyət başına.",
    pleaseEnterStaffEmail: "Zəhmət olmasa işçilərin e -poçtunu daxil edin",
    pleaseEnterStaffUsername:
      "Zəhmət olmasa işçilərin istifadəçi adını daxil edin",
    pleaseEnterStaffPassword: "Zəhmət olmasa işçilərin şifrəsini daxil edin",
    pleaseSelectStaffRole: "Zəhmət olmasa işçilərin rolunu seçin",
    selectStaffRole: "İşçilərin rolunu seçin",
    enterStaffEmail: "İşçilərin e -poçtunu daxil edin",
    enterStaffUsername: "İşçilərin istifadəçi adını daxil edin",
    enterStaffPassword: "İşçilərin şifrəsini daxil edin",
    spacesNotAllowedInUsername: "istifadəçi adında boşluqlara icazə verilmir",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Zəhmət olmasa işçi əlavə etmək üçün biznes seçin",
    searchForBranchToAttachStaff: "İşçi əlavə etmək üçün filial axtarın",
    username: "İstifadəçi adı",
    role: "Rol",
    areYouSureToDeleteThis: "Bunu silməyinizə əminsinizmi?",
    branches: "Filiallar",
    upgradeYourPlan: "Planınızı təkmilləşdirin.",
    add: "Əlavə edin",
    addNew: "Yeni əlavə et",
    customerWithEmailAlreadyExists:
      "E -poçt/telefon nömrəsi olan müştəri artıq mövcuddur!",
    successfullyAddedNewCustomer: "Uğurla yeni bir müştəri əlavə etdiniz!",
    addCustomer: "Müştəri əlavə edin",
    pleaseEnterCustomerFirstName: "Zəhmət olmasa müştərinin adını daxil edin",
    pleaseEnterCustomerLastName: "Zəhmət olmasa müştərinin soyadını daxil edin",
    pleaseEnterCustomerPhoneNumber:
      "Zəhmət olmasa müştərinin telefon nömrəsini daxil edin",
    pleaseEnterCustomerEmail: "Zəhmət olmasa müştərinin e -poçtunu daxil edin",
    pleaseEnterCustomerAddressLine:
      "Zəhmət olmasa müştərinin Ünvan Xəttini daxil edin",
    pleaseEnterCustomerOtherAddress:
      "Zəhmət olmasa müştərinin digər ünvanını daxil edin",
    pleaseSelectCustomerGender: "Zəhmət olmasa müştərinin cinsini seçin",
    gender: "Cins",
    male: "Kişi",
    female: "Qadın",
    bank: "Bank",
    selectBank: "Bank seçin",
    stateOrRegionOrProvince: "Əyalət/Bölgə/İl",
    customerNotes: "Müştəri Qeydləri",
    sendSms: "SMS göndərin",
    editCustomer: "Müştərini redaktə edin",
    redeemReward: "Mükafatı istifadə edin",
    issueLoyaltyCard: "Sadiqlik Kartı verin",
    deleteCustomer: "Müştərini silin",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Sadiqlik Üzvlüyü ID -ni uğurla təyin etdiniz",
    noMembershipIdAvailable:
      "Üzvlük ID -ləri mövcud deyil. Zəhmət olmasa bizimlə əlaqə saxlayın hello@loystar.co",
    sendEmail: "E -poçt göndər",
    membershipPoints: "Üzvlük Xalları",
    customerDetails: "Müştəri Təfərrüatları",
    close: "Yaxın",
    loyaltyBalance: "Sadiqlik Balansı",
    pointsBalance: "Bal balansı",
    starBalance: "Ulduz balansı",
    requiredPoints: "Lazımi Xallar",
    requiredStars: "Tələb olunan Ulduzlar",
    reddemCode: "Kodu istifadə edin",
    toDeleteThisCustomer: "bu müştərini silmək üçün",
    customerHasBeenDeletedSuccessfully: "Müştəri uğurla silindi!",
    customerWithPhoneAlreadyExists:
      "Telefon nömrəsi olan müştəri artıq mövcuddur",
    customerWasSuccessfullyEdited: "Müştəri uğurla redaktə edildi",
    anErrorOccured: "Bir səhv baş verdi",
    phoneNumberIncludeCountry: "Telefon nömrəsi (ölkə kodu daxil olmaqla)",
    yourFileQueuedForUpload:
      "Faylınız yüklənmək üçün növbəyə qoyuldu. Zəhmət olmasa bir neçə saniyədən sonra səhifəni yeniləyin.",
    thereWasAnErrorPerformingOperation:
      "Əməliyyatı yerinə yetirərkən xəta baş verdi!",
    pleaseSelectFile: "Zəhmət olmasa Fayl Seçin!",
    oopsOnlyCsvFilesAllowed:
      "Vay! Yalnız CSV fayllarına icazə verilir. Zəhmət olmasa .csv faylını yükləyin.",
    fileShouldBeSmallerThan5Mb:
      "Fayl 5 MB -dan kiçik olmalıdır. Daha böyük bir faylınız varsa, support@loystar.co ünvanına e -poçt göndərin. Çox sağ ol",
    customerFirstNameIsRequired: "satırda müştərinin adı tələb olunur",
    customerPhoneNumberIsRequired:
      "müştərinin telefon nömrəsi növbədə tələb olunur",
    importCustomers: "Müştəriləri idxal edin",
    upload: "Yükləmək",
    clickIconToDownloadCsv:
      "CSV fayl şablonunu yükləmək üçün bu işarəni vurun.",
    getGoogleSheetVersion: "Google hesabatı versiyasını əldə edin",
    clickOrDragFileToUpload:
      "Yükləmək üçün faylı vurun və ya bu sahəyə sürükləyin",
    missingOrInvalidColumnHeader:
      "Sütun başlığı yoxdur və ya etibarsızdır. Zəhmət olmasa şablon formatına əməl edin. Çox sağ ol.",
    youHaveImported: "İdxal Etmisiniz",
    youSuccessfullyRedeemedReward: "Mükafatınızı uğurla aldınız!",
    sixDigitCode: "Altı rəqəmli kod",
    pleaseEnterCustomerRewardCode:
      "Zəhmət olmasa müştərinin mükafat kodunu daxil edin",
    enterRewardCode:
      "Mükafat kodunu daxil edin. (Mükafat kodu hərflərə həssasdır)",
    selectLoyaltyProgram: "Sadiqlik proqramını seçin",
    stamps: "Markalar",
    smsUnitsLow: "SMS vahidləri azdır",
    pleaseTopUpSmsUnits: "Zəhmət olmasa SMS vahidlərini doldurun",
    smsSuccessfullySentForDelivery: "SMS çatdırılma üçün uğurla göndərildi!",
    sendSmsTo: "SMS göndərin",
    allCustomers: "Bütün Müştərilər",
    unitsAvailable: "Mövcud vahidlər",
    pleaseTypeInTheMessage: "Zəhmət olmasa mesajı yazın",
    message: "Mesaj",
    charactersRemaining: "qalan simvollar",
    avoidUseOfSpecialCharacters:
      "SMS vahidlərini qorumaq üçün Xüsusi İşarələr və Emojilərdən istifadə etməyin.",
    note: "Qeyd",
    errorFetchingCustomersMultilevelDetail:
      "Müştərilərin Çoxsəviyyəli Təfərrüatları Alınmasında Xəta",
    search: "Axtar",
    reset: "Sıfırla",
    importCustomer: "Müştəri İdxal edin",
    addNewCustomer: "Yeni Müştəri əlavə edin",
    sendSmsBroadcast: "SMS Yayını göndərin",
    totalCustomers: "TOTAL MÜŞTƏRİLƏR",
    disbursementDetails: "Ödəniş Təfərrüatları",
    paidBy: "Tərəfindən Ödənildi",
    disbursed: "Ödənildi",
    bankAccountName: "Bank Hesabının Adı",
    bankAccountNumber: "Bank hesab nömrəsi",
    transferInitiated: "Transfer Başlandı",
    transferCompleted: "Transfer Tamamlandı",
    pleaseEnterAValid: "Zəhmət olmasa etibarlı hesab daxil edin",
    begin: "başlamaq",
    end: "bitmək",
    date: "Tarix",
    paymentDate: "Ödəniş tarixi",
    selectDisbursementDuration: "Ödəniş müddəti seçin",
    totalSettled: "Ümumi məskunlaşma",
    totalUnsettled: "Ümumi narahatlıq",
    toDeleteThisSale: "bu satışı silmək üçün",
    draftSaleDeletedSuccessfully: "Qaralama Satış uğurla silindi!",
    deleteSale: "Satış Silin",
    pleaseTypeInYourTotal: "Zəhmət olmasa silməyi təsdiq etmək üçün cəmi yazın",
    billDetails: "Bill Detalları",
    printBill: "Bill Çap et",
    servedBy: "Xidmət edir",
    total: "Ümumi",
    createdDate: "Yaradılma tarixi",
    createdTime: "Yaradılmış Zaman",
    status: "Vəziyyət",
    loginSuccessful: "Giriş Uğurlu",
    pleaseWaitWhileWeConnectAccount: "Hesabınızı bağlayana qədər gözləyin",
    connectionFailedTryAgain:
      "Bağlantı alınmadı. Zəhmət olmasa bir daha cəhd edin.",
    connectionSuccessful: "Bağlantı Uğurlu oldu",
    viewDetails: "Detallara Bax",
    enable: "Aktiv et",
    free: "Pulsuz",
    cost: "Qiymət",
    visitWebsite: "Veb saytı ziyarət edin",
    pleaseUpgradeYourPlanToPro:
      "Bu proqramı aktiv etmək üçün planınızı Pro Plus -a yüksəldin",
    connectYourBankAccount: "Ödənişləri almaq üçün Bank Hesabınızı bağlayın.",
    disable: "Deaktiv edin",
    enableApp: "Tətbiqi aktiv edin",
    addNewProductToInvoice: "Fakturaya Yeni Məhsul əlavə edin",
    toDeleteThisInvoice: "bu Fakturanı silmək üçün",
    invoiceDeletedSuccessfully: "Faktura uğurla silindi!",
    deleteInvoice: "Fakturanı silin",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Bir faktura ödəmə mesajını uğurla əlavə etdiniz!",
    pleaseEnterPaymentInstructions:
      "Zəhmət olmasa ödəniş təlimatlarını daxil edin",
    invoiceId: "Faktura ID -si",
    paidDate: "Ödəniş tarixi",
    reference: "İstinad",
    productAdded: "Məhsul əlavə edildi",
    productOutOfStock: "Məhsul stokda deyil. Zəhmət olmasa yenidən stoklayın.",
    totalInvoices: "TOTAL FATURALAR",
    totalPaidInvoices: "TOPLAM ÖDƏNİŞLİ FATURALAR",
    totalUnpaidInvoices: "TOPLAM ÖDƏNİŞSİZ FATURALAR",
    loyaltyProgramDeleted: "Sadiqlik Proqramı Silindi",
    thisLoyaltyProgramDeletedSuccessfully:
      "Bu sadiqlik proqramı uğurla silindi",
    thisLoyaltyProgramEditedSuccessfully:
      "Bu sadiqlik proqramı uğurla redaktə edildi",
    loyaltyProgramAddedSuccessfully: "Sadiqlik proqramı uğurla əlavə edildi",
    loyaltyProgramEdited: "Sadiqlik Proqramı Redaktə Edildi",
    loyaltyProgramAdded: "Sadiqlik Proqramı əlavə edildi",
    loyaltyDetails: "Sadiqlik Təfərrüatları",
    doYouWantToCloseDialog: "Bu dialoqu bağlamaq istəyirsiniz?",
    pleaseEnterLoyaltyName: "Zəhmət olmasa sadiqliyinizin adını daxil edin",
    programType: "Proqram Növü",
    pleaseSelectProgramType: "Zəhmət olmasa bir proqram növü seçin",
    simplePoints: "Sadə nöqtələr",
    stampsProgram: "Markalar Proqramı",
    threshold: "Eşik",
    pleaseEnterLoyaltyThreshold: "Zəhmət olmasa sadiqlik həddini daxil edin",
    reward: "Mükafat",
    pleaseEnterLoyaltyReward: "Zəhmət olmasa sadiqliyin mükafatını daxil edin",
    totalUserPoints: "Ümumi İstifadəçi Xalları",
    totalUserStamps: "Ümumi İstifadəçi Markaları",
    spendingTarget: "Hədəf xərcləmək",
    spendingTargetHint1:
      "Xərcləmə hədəfi, bir müştərinin mükafat qazanmaq üçün nə qədər xərcləməli olmasıdır. 1 valyuta dəyəri = 1 bal.",
    spendingTargetHint2:
      "Marka hədəfi, bir müştərinin mükafat qazanmaq üçün neçə marka toplamasıdır. məs. 5",
    addNewLoyaltyProgram: "Yeni Sadiqlik Proqramı əlavə edin",
    addLoyaltyProgram: "Sadiqlik Proqramı əlavə edin",
    loyaltyProgramType: "Sadiqlik Proqramının növü",
    pleaseSelectLoyaltyProgramType: "Sadiqlik proqramının növünü seçin",
    nameOfProgram: "Proqramın adı",
    pleaseEnterProgramName: "Zəhmət olmasa proqramın adını daxil edin",
    whatIsTheReward: "Mükafat nədir?",
    egNextPurchaseIsFree: "Məsələn, Növbəti alış pulsuzdur",
    customerName: "Müştəri adı",
    guestCustomer: "Qonaq Müştəri",
    orderConfirmedSuccessfully: "Sifariş uğurla təsdiq edildi",
    orderCancelledSuccessfully: "Sifariş uğurla ləğv edildi",
    confirmOrder: "Sifarişi təsdiq edin",
    cancelOrder: "Sifarişi ləğv et",
    allOrders: "Bütün Sifarişlər",
    totalOrders: "TOPLAM SİFARİŞLƏR",
    doYouWantToAcceptOrder: "Bu sifarişi qəbul etmək istəyirsiniz?",
    doYouWantToCancelOrder: "Bu sifarişi ləğv etmək istəyirsiniz?",
    orderDetails: "Sifariş detalları",
    orderCreatedAt: "Sifariş yaradıldı",
    supplier: "Təchizatçı",
    productName: "Məhsul Adı",
    quantity: "Kəmiyyət",
    unitPrice: "Vahid qiymət",
    receivedBy: "Tərəfindən qəbul edildi",
    reportFrom: "Hesabat",
    totalSupplies: "Ümumi Təchizat",
    allRightsReserved: "Bütün hüquqlar qorunur",
    toDeleteThisTransaction: "bu əməliyyatı silmək üçün",
    transactionDeletedSuccessfully:
      "Əməliyyat uğurla silindi. Səhmlər inventara qaytarılıb.",
    deleteTransaction: "Əməliyyatı silin",
    transactionDetails: "Əməliyyat Təfərrüatları",
    printReceipt: "Qəbz çap edin",
    channel: "Kanal",
    discount: "Endirim",
    profit: "Mənfəət",
    discountedSales: "Endirimli Satışlar",
    errorFetchingRecord: "Qeyd Alınarkən Xəta",
    exportTransactions: "İxrac Əməliyyatları",
    errorFetchingSalesRecord:
      "İxrac üçün Satış rekordu alınarkən xəta baş verdi.",
    totalSellingPrice: "Ümumi Satış Qiyməti",
    totalCostPrice: "Ümumi Qiymət Qiyməti",
    appliedDiscount: "Tətbiq edilən Endirim",
    noOfItems: "Maddələrin sayı",
    sales: "Satış",
    export: "İxrac edin",
    totalProfit: "Ümumi mənfəət",
    totalBalanceInPeriod: "Dövr ərzində ümumi qalıq",
    allTimeSales: "Hər Zaman Satış",
    records: "Rekordlar",
    todaysSales: "Bugünkü Satışlar",
    transaction: "əməliyyat",
    youHaveExceededTotalNumberOfProducts:
      "Planınızda icazə verilən məhsulların ümumi sayını keçmisiniz. Daha yüksək bir limitdən istifadə etmək üçün planınızı təkmilləşdirin.",
    youNeedToHaveLoyaltyProgram:
      "Bu xüsusiyyətdən istifadə etmək üçün Sadiqlik Proqramına sahib olmalısınız!",
    price: "Qiymət",
    category: "Kateqoriya",
    stockTracking: "Stok İzləmə",
    stockCount: "Stok sayı",
    taxed: "Vergi",
    originalPrice: "Orijinal Qiymət",
    costPrice: "Qiymət Qiyməti",
    unit: "Vahid",
    productImage: "Məhsul Şəkli",
    taxRate: "Vergi dərəcəsi",
    taxType: "Vergi növü",
    trackInventory: "Envanteri izləyin",
    variants: "Variantlar",
    hasVariants: "Variantları var",
    importProduct: "Məhsulu İdxal edin",
    exportProducts: "Məhsulları İxrac edin",
    addNewProduct: "Yeni məhsul əlavə edin",
    viewCategory: "Kateqoriyaya baxın",
    viewSuppliers: "Təchizatçılara baxın",
    receiveInventory: "Envanter alın",
    printAllProductsTag: "Bütün məhsulların etiketini çap edin",
    deleteAll: "Hamısını Sil",
    totalProducts: "ÜMUMİ MƏHSULLAR",
    youveSuccessfullyAddedANewCategory:
      "Yeni bir kateqoriya uğurla əlavə etdiniz",
    addNewCategory: "Yeni Kateqoriya əlavə edin",
    addCategory: "Kateqoriya əlavə edin",
    categoryName: "Kateqoriya Adı",
    pleaseEnterCategoryName: "Zəhmət olmasa kateqoriya adını daxil edin",
    stampsTarget: "Marka Hədəfi",
    sendInventory: "Envanter göndərin",
    productDetails: "Məhsul Detalları",
    youveSuccessfullyEditedThisCategory:
      "Bu kateqoriyanı uğurla redaktə etdiniz",
    update: "Yeniləyin",
    categoryList: "Kateqoriya siyahısı",
    categories: "Kateqoriyalar",
    enterQuantityToUpdate: "Yeniləmək üçün bir miqdar daxil edin",
    inventorySentSuccessfully: "İnventarizasiya uğurla göndərildi!",
    updateInventory: "Envanteri yeniləyin",
    currentQuantity: "Cari Miqdarı",
    pleaseEnterQuantityToAdd:
      "Zəhmət olmasa əlavə etmək istədiyiniz miqdarı daxil edin",
    pleaseSelectABranch: "Zəhmət olmasa Filial seçin",
    searchForBranch: "Filial axtarın",
    youCantSendMoreThanStock: "Stokda olduğundan artıq göndərə bilməzsiniz",
    send: "Göndər",
    pleaseEnterQuantityToSend:
      "Zəhmət olmasa göndərmək istədiyiniz miqdarı daxil edin",
    productNameIsRequiredOnRow: "satırda məhsul adı tələb olunur",
    productCategoryIsRequiredOnRow:
      "sıra üzrə məhsul kateqoriyası tələb olunur",
    productPriceIsRequiredOnRow: "məhsulun qiyməti növbədə tələb olunur",
    productUnitIsRequiredOnRow: "sıra üzrə məhsul vahidi tələb olunur",
    productQuantityIsRequiredOnRow: "məhsul miqdarı sıra ilə tələb olunur",
    productVariantsRequireTracking: "məhsul variantları izləmə tələb edir!",
    pleaseAddVariantClickButton:
      "Əlavə et düyməsini basaraq bir variant əlavə edin!",
    totalVariantsMoreThanSelectedQuantity:
      "Ümumi Variantlar seçilmiş məhsul miqdarından çoxdur, Pls variant miqdarını azaldır",
    productEditedSuccessfully: "Məhsul uğurla redaktə edildi!",
    fileTooLargeLessThan4Mb:
      "Fayl ölçüsü çox böyükdür! Faylın ölçüsü 4 MB -dan az olmalıdır.",
    suchVariantAlreadyExist: "Belə bir variant artıq mövcuddur",
    addVariants: "Variantlar əlavə edin",
    editProduct: "Məhsulu redaktə edin",
    pleaseEnterProductName: "Zəhmət olmasa Məhsul Adını daxil edin",
    pleaseEnterProductPrice: "Zəhmət olmasa Məhsul Qiymətini daxil edin",
    pleaseEnterProductOriginalPrice:
      "Zəhmət olmasa Məhsulun Orijinal Qiymətini daxil edin",
    productDescription: "Məhsul Təsviri",
    selectProductCategory: "Məhsul kateqoriyasını seçin",
    pleaseSelectProductCategory: "Zəhmət olmasa məhsul kateqoriyasını seçin",
    productCostPrice: "Məhsulun Qiyməti",
    productSellingPrice: "Məhsul Satış Qiyməti",
    productOriginalPrice: "Məhsulun Orijinal Qiyməti",
    maxFileSizeAllowedIs4Mb: "Maks. İzin verilən fayl ölçüsü 4 mb -dir",
    productsWithPicturesArePublished:
      "Sifariş almaq üçün şəkilləri olan məhsullar Discoverda yayımlanır",
    shouldThisProductBeTracked: "Bu məhsul izlənilməlidirmi?",
    pleaseSelectStockUnit: "Zəhmət olmasa stok vahidini seçin",
    stockUnit: "Səhm vahidi",
    bag: "ÇANTA",
    bottle: "ŞİŞƏ",
    bunch: "BUNCH",
    can: "BACARMAQ",
    carton: "KARTON",
    crate: "KRAS",
    cup: "CUP",
    dozen: "DOZ",
    gigabytes: "GIGABYTES",
    gram: "QRAM",
    kilogram: "KİLOQRAM",
    litre: "LITER",
    pack: "PAKET",
    pair: "Cütlük",
    pieces: "PARÇALAR",
    plate: "TABAKA",
    tonne: "TONE (MT)",
    uNIT: "Vahid",
    yard: "YARD",
    pleaseEnterProductQuantity: "Zəhmət olmasa məhsulun miqdarını daxil edin",
    productQuantity: "Məhsul miqdarı",
    isThisProductTaxed: "Bu məhsul vergiyə cəlb olunurmu?",
    selectTaxType: "Vergi növünü seçin",
    pleaseSelectTaxType: "Zəhmət olmasa Vergi Növünü seçin",
    progressive: "Proqressiv",
    proportional: "Mütənasib",
    pleaseEnterProductTaxRate: "Zəhmət olmasa məhsul vergisini daxil edin",
    doesProductHaveVariants: "Bu məhsulun variantları varmı?",
    type: "Yazın",
    value: "Dəyər",
    pleaseEnterVariantType: "Variantın növünü daxil edin",
    pleaseEnterVariantValue: "Variantın dəyərini daxil edin",
    pleaseEnterVariantPrice: "Variantın qiymətini daxil edin",
    pleaseEnterVariantQuantity: "Variantın miqdarını daxil edin",
    productDeletedSuccessfully: "Məhsul uğurla silindi!",
    categoryDeletedSuccessfully: "Kateqoriya uğurla silindi!",
    toDeleteThisProduct: "bu Məhsulu silmək üçün",
    invalidProductQuantity: "Yanlış Məhsul miqdarı",
    quantityAddedIsOverStock:
      "Əlavə etdiyiniz miqdar, stokunuzda olanlardan çoxdur.",
    itemInventoryNotTracked: "Maddə inventarı izlənilmir",
    addBulkQuantity: "Toplu miqdar əlavə edin",
    enterBulkQuantity: "Toplu Miqdarı daxil edin",
    pleaseEnterBulkQuantity: "Zəhmət olmasa Toplu Miqdarı daxil edin",
    youveSuccessfullyAddedANewProduct: "Yeni bir məhsulu uğurla əlavə etdiniz!",
    pleaseEnterProductSellingPrice:
      "Zəhmət olmasa məhsul satış qiymətini daxil edin",
    doYouWantToTrackProductStock: "Məhsul ehtiyatını izləmək istəyirsiniz?",
    sellingPrice: "Satış Qiyməti",
    setProductExpiryReminder: "Məhsulun Müddəti Xatırlatıcısını təyin edin",
    productExpiryDate: "Məhsulun son istifadə tarixi",
    startRemindingFrom: "Xatırlamağa Başlayın",
    productSuppliesAddedSuccessfully: "Məhsul tədarükünü uğurla əlavə etdiniz.",
    addProductSupplies: "Məhsul Təchizatlarını əlavə edin",
    pleaseSelectSupplier: "Zəhmət olmasa Təchizatçı seçin",
    nameOfProduct: "Məhsulun Adı",
    pleaseSelectProduct: "Zəhmət olmasa Məhsul seçin",
    productVariant: "Məhsul Variantı",
    pleaseSelectAVariant: "Zəhmət olmasa Variant seçin",
    pleaseEnterUnitPrice: "Zəhmət olmasa vahid qiymətini daxil edin",
    businessBranch: "İş Filialı",
    pleaseSelectBranch: "Zəhmət olmasa filial seçin",
    youveSuccessfullyAddedANewSupplier: "Yeni tədarükçünü uğurla əlavə etdiniz",
    addSupplier: "Təchizatçı əlavə edin",
    pleaseEnterSupplierEmail: "Zəhmət olmasa təchizatçı e -poçtunu daxil edin",
    pleaseAddADescription: "Zəhmət olmasa təsviri əlavə edin",
    anErrorOccuredProductsDeleted:
      "Əməliyyatı yerinə yetirərkən xəta baş verdi. Nəzərə alın ki, bu prosesdə bir neçə məhsul silinə bilər",
    bulkDelete: "Toplu Sil",
    youAreAboutToDelete: "Silmək üzrəsiniz",
    product: "Məhsul",
    isDueToRedeem: "qurtarmaqla bağlıdır",
    aReward: "mükafat",
    pleaseSelectCustomerToReeemReward:
      "Zəhmət olmasa mükafat almaq üçün bir müştəri seçin.",
    youHaveNoLoyaltyProgramRunning: "Çalışan aktiv sadiqlik proqramınız yoxdur",
    customerHhasNoPointsInLoyaltyProgram:
      "Müştərinin bu sadiqlik proqramında heç bir nöqtəsi yoxdur",
    proceedWithPayment: "Ödənişlə davam edilsin?",
    youAreAboutToPayForTransactionUsingPoints:
      "Balları istifadə edərək əməliyyatı ödəmək üzrəsiniz.",
    customerHas: "Müştəri Var",
    worth: "dəyər",
    andIsNotSufficientToPayForTransaction:
      "və bu əməliyyatı ödəmək üçün kifayət deyil. Balansı başqa bir ödəniş üsulu ilə əlavə etmək istərdinizmi?",
    addCustomerLoyalty: "Müştəri Sadiqliyi əlavə edin",
    pleaseAddCustomerFirst: "Əvvəlcə müştəri əlavə edin və ya seçin.",
    pleaseWaitWhileWeGetReady: "Hazırlaşana qədər gözləyin",
    lowStock: "Aşağı Stok",
    pleaseEnterAmountTendered: "Zəhmət olmasa təklif olunan məbləği daxil edin",
    areYouSureToBookSaleOffline:
      "Bu satışı oflayn olaraq sifariş etmək istədiyinizə əminsiniz?",
    saleWouldBeBookedAutomatically:
      "İnternet açıldıqda satış avtomatik olaraq reallaşdırılacaq",
    offlineSalesBookingCancelled: "Oflayn satış sifarişi ləğv edildi",
    covid19Message:
      "COVID19: Yayılmasını dayandırmaq üçün əllərinizi sabunla yuyun və ya dezinfeksiya edin. Həmişə Təhlükəsiz qalın",
    saleSuccessfullyRecorded: "Satış uğurla qeyd edildi!",
    sendReceiptToEmail: "Qəbzini E -poçt ünvanına göndərin",
    sendThankYouSms: "Təşəkkür SMS göndərin",
    sendShippingDetails: "Göndərmə məlumatlarını göndərin",
    addLoyalty: "Sadiqlik əlavə edin",
    searchCustomerNameOrNumber: "Müştəri adını və ya nömrəsini axtarın",
    clickTheSearchCustomerBox:
      "Axtarış Müştəri Qutusuna və Tarama Kartına vurun",
    enterProductPrice: "Məhsul Qiymətini daxil edin",
    enterPriceFor: "Üçün Qiyməti daxil edin",
    searchForProduct: "Məhsul axtarın",
    all: "Hamısı",
    backToDashboard: "Tablosuna qayıt",
    viewDraftSales: "Qaralama Satışlarına baxın",
    variantSelected: "variant seçildi",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Qiymətə görə seçilən yeni variant üçün əvvəlki variant seçimi mövcud deyil, zəhmət olmasa seçiminizi dəyişdirin.",
    pleaseSupplyPositiveQuantityNumber:
      "Zəhmət olmasa, pozitiv bir miqdar nömrəsi verin",
    lowStockFor: "Üçün aşağı ehtiyat",
    clearVariants: "Variantları aydınlaşdırın",
    pleaseEnterQuantity: "Zəhmət olmasa Miqdarı daxil edin",
    picture: "Şəkil",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Ümumi xərcləndi",
    confirmPayment: "Ödənişi təsdiq edin",
    hasPaymentBeenMade: "Ödəniş uğurla işlənibmi?",
    enterTransactionReference:
      "Ödəniş etdiyiniz əməliyyat istinadını daxil edin",
    pleaseSelectACustomer: "Zəhmət olmasa müştəri seçin!",
    areYouSureToApplyDiscount: "Endirim tətbiq etmək istədiyinizə əminsiniz?",
    addYourBankAccountToEnableUssd:
      "UPay ilə dərhal USSD köçürməsini aktivləşdirmək üçün bank hesabınızı əlavə edin",
    totalAmountToPay: "Ödəniləcək ümumi məbləğ",
    doYouWantToCancelTransaction: "Bu əməliyyatı ləğv etmək istəyirsiniz?",
    savePrintBill: "Qeydiyyatdan Keç/Saxla",
    enterAmountCollectedForCustomer: "Müştəri üçün toplanan məbləği daxil edin",
    recordSale: "Rekord Satış",
    checkOutWith: "İlə yoxlayın",
    instantTransfer: "Ani Transfer",
    dialTheUSSDCode: "USSD kodunu yığın",
    pleaseSelectABank: "Zəhmət olmasa bir bank seçin",
    payWithUSSD: "USSD ilə ödəyin",
    sendBillTo: " - Bill göndər",
    waitingForUSSDTransfer: "USSD Transferini gözləyirik",
    percent: "Faiz",
    applyDiscount: "Endirim tətbiq edin",
    thisBillHasBeenSaved: "Bu Bill saxlanıldı",
    saveDraft: "Qaralamanı Saxla",
    pleaseTypeANameToIdentifyCustomer:
      "Müştərini müəyyən etmək üçün bir ad yazın",
    paymentDetails: "Ödəniş Təfərrüatları",
    basePrice: "Əsas Qiymət",
    staff: "Heyət",
    subTotal: "Alt Cəmi",
    durationMonths: "Müddət (ay)",
    selectADuration: "Müddəti seçin",
    oneMonth: "1 ay",
    twoMonths: "2 ay",
    threeMonths: "3 ay",
    sixMonths: "6 ay",
    twelveMonths: "12 ay",
    eighteenMonths: "18 Ay",
    twentyFourMonths: "24 Ay",
    twoMonthsFree: "(2 Ay Pulsuz)",
    threeMonthsFree: "(3 Ay Pulsuz)",
    fiveMonthsFree: "(5 Ay Pulsuz)",
    yourAccountHasBeen: "Hesabınız olub",
    renewed: "yenilənmiş",
    upgraded: "təkmilləşdirilmişdir",
    successfully: "uğurla",
    yourSubscription: "Abunəliyiniz",
    youAreCurrentlyEnrolledOnA: "Hazırda a -ya yazılırsınız",
    pleaseChooseAPaymentOption: "Zəhmət olmasa Ödəniş Seçimi Seçin",
    planRenewal: "Yeniləmə Planı",
    planUpgrade: "Yeniləmə Planı",
    pleaseSelectDurationToPayFor:
      "Zəhmət olmasa ödəmək istədiyiniz müddəti seçin",
    staffAccounts: "Kadr hesabları",
    ecommerce: "Elektron ticarət",
    pleaseSelectAPlan: "Zəhmət olmasa bir plan seçin",
    includeAddons: "Əlavələr daxil edin",
    viewTransactions: "Əməliyyatlara baxın",
    customerHasNoCompletedTansactions:
      "Müştərinin hələlik tamamlanmış əməliyyatı yoxdur",
    branch: "Filial",
    enterNumberOfEcommerceBranches:
      "Elektron ticarət filiallarının sayını daxil edin",
    enterNumberOfEcommerceBranchesToPay:
      "Ödəmək istədiyiniz E -Ticarət Filiallarının Sayını daxil edin",
    ecommerceIntegration: "Elektron Ticarət İnteqrasiyası",
    enterNumberOfBranches: "Filial sayını daxil edin",
    enterNumberOfAdditionalBranchesToPay:
      "Ödəmək istədiyiniz əlavə filialların sayını daxil edin",
    enterNumberOfStaffs: "İşçilərin sayını daxil edin",
    enterNumberOfStaffsToPayFor:
      "Ödəmək istədiyiniz işçilərin sayını daxil edin",
    discountApplies: "Endirim tətbiq olunur",
    starsOnThe: "üzərindəki ulduzlar",
    offer: "təklif",
    get: "Alın",
    moreStarsToRedeem: "qurtarmaq üçün daha çox ulduz",
    taxVat: "Vergi (ƏDV)",
    callCashierToCompletePayment:
      "Ödənişi başa çatdırmaq üçün Kassirə zəng edin",
    receipt: "Qəbz",
    dear: "Əziz",
    thankYouForYourOrderFindGoods:
      "Sifarişiniz üçün təşəkkür edirik. Zəhmət olmasa razılaşdırıldığı kimi aşağıdakı malları tapın.",
    shippingNote: "Göndərmə Qeyd",
    enterShippingNote: "Göndərmə Qeydini daxil edin",
    shippingAddress: "Çatdırılma ünvanı",
    enterShippingAddress: "Çatdırılma ünvanını daxil edin",
    wellDoneYouAreDueToRedeem: "Əla! Qurtarmalısınız",
    toGetYourRewardNextVisit:
      "növbəti səfərinizdə mükafat almaq üçün. Çox sağ ol",
    pointsOnThe: "Üzərindəki nöqtələr",
    morePointsToRedeem: "geri almaq üçün daha çox bal",
    showCode: "Kodu göstərin",
    toGetYourRewardOnNextVisit:
      "növbəti səfərinizdə mükafat almaq üçün. Çox sağ ol",
    earn: "Qazan",
    delivaryNote: "Çatdırılma Qeyd",
    draftSales: "Qaralama Satışları",
    startDate: "Başlama tarixi",
    endDate: "Bitmə vaxtı",
    orders: "Sifarişlər",
    checkout: "yoxla",
    noProductItem: "Məhsul Məhsulu yoxdur",
    selectProductImage: "Məhsul Şəklini seçin",
    selectCountry: "Ölkə seç",
    selectRegion: "Ştatı/Bölgəni seçin",
    printProductTag: "Məhsul etiketini çap edin",
    transactionReference: "Əməliyyat arayış",
    Cashier: "Kassir",
    Manager: "Menecer",
    Owner: "Sahibi",
    Admin: "Admin",
  },
  Basque: {
    cashier: "kutxazaina",
    manager: "kudeatzailea",
    owner: "jabea",
    online: "linean",
    offline: "lineaz kanpo",
    changePassword: "Aldatu pasahitza",
    currentPasswordMessage: "Mesedez, sartu zure uneko pasahitza",
    passwordMessage: "Mesedez, sartu zure pasahitza",
    currentPassword: "oraingo pasahitza",
    password: "pasahitza",
    confirmPasswordMessage: "Mesedez berretsi zure pasahitza!",
    confirmPassword: "pasahitza baieztatu",
    sendViaEmail: "Bidali posta elektroniko bidez",
    sendViaWhatsapp: "Bidali WhatsApp bidez",
    downloadPdf: "Deskargatu PDFa",
    paid: "ordainduta",
    unpaid: "ordaindu gabea",
    partial: "partziala",
    closeInvoice: "Faktura itxi nahi al duzu?",
    closeInvoiceConfirmation:
      "Baliteke faktura ez gordetzea. Itxi nahi al duzu?",
    yes: "bai",
    no: "ez",
    invoice: "Faktura",
    wasDeducted: "kendu zen",
    for: "for",
    item: "Item",
    addProduct: "Gehitu produktua",
    paymentReference: "Ordainketaren erreferentzia",
    amountPaid: "Ordaindutako kopurua",
    discountAmount: "Deskontu kopurua",
    amountDue: "Ordaindu beharreko zenbatekoa",
    amount: "Zenbatekoa",
    dueDate: "Epemuga",
    paymentType: "Ordainketa mota",
    card: "Txartela",
    cash: "Dirua",
    bankTransfer: "Banku-transferentzia",
    paymentMessage: "Ordainketa mezua",
    description: "Deskribapena",
    sendReceipt: "Bidali Agiria",
    delete: "Ezabatu",
    save: "Gorde",
    resend: "Bidali berriro",
    saveAndSend: "Gorde",
    invoiceSaved: "Faktura gordeta!",
    selectPaymentMethod: "Mesedez, hautatu Ordainketa Metodo bat!",
    selectCustomer: "Mesedez, hautatu bezero bat!",
    cartEmptyError:
      "Saskiaren zerrenda ezin da hutsik egon, itxi faktura eta gehitu artikulua saskira!",
    subscriptionExpired:
      "Harpidetza iraungi egin da eta zure kontua orain mugatuta dago. Egin klik beheko botoian zure kontua berritzeko",
    renew: "Berritu",
    holdOn: "Eutsi",
    customerBank: "Bezeroen Bankua",
    cancel: "Utzi",
    selectACustomer: "Aukeratu Bezeroa",
    invoiceSuccessfulPdfError:
      "Faktura ondo sortu da, baina PDF Sorkuntza ohi baino denbora gehiago behar da. Mesedez, egiaztatu berriro laster.",
    downloadingInvoice: "Faktura deskargatzen",
    downloadingReceipt: "Ordainagiria deskargatzen",
    whatsappReceiptError:
      "Errore bat gertatu da ordainagiria WhatsApp bidez bidaltzean. Saiatu berriro.",
    receiptToWhatsapp: "WhatsApp-era bidalitako ordainagiria",
    thankYouForPatronage: "Eskerrik asko zure mezenasagatik",
    hereIsYourReceipt: "Hemen duzu ordainketa ordainagiria",
    errorSendingEmailReceipt:
      "Errore bat gertatu da ordainagiria posta elektronikoz bidaltzean. Saiatu berriro edo jarri harremanetan laguntza zerbitzuarekin",
    receiptSentToEmail: "Ordainagiria bezeroaren posta elektronikora bidali da",
    invoiceSentToEmail: "Faktura bezeroaren posta elektronikora bidali da",
    invoiceSuccessWhatsappError:
      "Faktura ondo sortu da, baina errore bat gertatu da WhatsAppera bidaltzean. Saiatu berriro faktura zerrendan",
    invoiceSuccessfulEmailError:
      "Faktura ondo sortu da, baina errore bat gertatu da mezu elektroniko gisa bidaltzean. Saiatu berriro faktura zerrendan",
    invoiceSentToWhatsapp: "WhatsAppera bidalitako faktura",
    hello: "Kaixo",
    pleaseDownloadInvoice: "Mesedez, deskargatu faktura",
    pleaseDownloadReceipt: "Mesedez deskargatu ordainagiria",
    from: "tik",
    email: "Posta elektronikoa",
    upgrade: "Berritu",
    youAreOnFreePlan: "Doako planean zaude.",
    clickOn: "Egin klik gainean",
    yourPlanInFewSteps: " zure harpidetza urrats azkar batzuetan.",
    to: "ra",
    yourSubscriptionHasExpired:
      "Harpidetza iraungi egin da eta zure kontua orain mugatuta dago.",
    days: "egunak",
    yourSubscriptionExpiresIn: "Loystar harpidetza hemen iraungitzen da",
    createAcount: "Kontu bat sortu",
    signIn: "Hasi saioa",
    continue: "Jarraitu",
    enterOtp: "Idatzi OTP PINa",
    enterValidOtp: "Mesedez, sartu baliozko PIN kodea",
    pin: "PIN kodea",
    tokenSentToMail: "Token bat bidali da zure posta elektronikora",
    passwordResetSuccessful: "Pasahitza berrezarri da",
    somethingWentWrong: "Arazoren bat izan da!",
    resetPassword: "Pasahitza berrezarri",
    iHaveResetCode: "Pasahitza berrezartzeko kodea daukat",
    pleaseEnterEmail: "Mesedez, idatzi zure posta elektronikoa",
    aTokenWillBeSentToEmail:
      "Token bat bidaliko litzateke zure posta elektronikora",
    enterEmail: "Idatzi zure posta elektronikoa",
    sendinYourToken: "Zure token-a bidaltzen ...",
    makeSureItMatchesPassword:
      "Ziurtatu zure pasahitz berriarekin bat datorrela",
    pleaseChooseNewPassword: "Aukeratu pasahitz berria",
    chooseNewPassword: "Aukeratu pasahitz berria",
    enterNewPassword: "Sartu zure pasahitz berria berresteko",
    enterTokenSent: "Idatzi zure mezua bidali zaizun token-a",
    resetToken: "Berrezarri tokena",
    resettingPassword: "Pasahitza berrezartzen ...",
    signUp: "Izena eman",
    adminSignInWithEmail:
      " Administratzaileak posta elektroniko bidez saioa hasten du Langileek erabiltzaile izenarekin saioa hasten duten bitartean.",
    pleaseEnterEmailOrUsername:
      "Mesedez, idatzi zure posta elektronikoa edo erabiltzaile izena",
    emailOrUsername: "Posta elektronikoa edo erabiltzaile izena",
    pleaseEnterPassword: "Mesedez, sartu pasahitza",
    createAnAccount: "Kontu bat sortu",
    forgotPassword: "Pasahitza ahaztua?",
    enterPhoneNumber: "Sartu telefono zenbakia",
    personalData: "Datu pertsonalak",
    validateConfirmationCOde: "Balioztatu baieztapen kodea",
    pleaseEnterFirstName: "Mesedez, idatzi zure izena",
    pleaseEnterPhoneNumber: "Mesedez, sartu zure telefono zenbakia",
    pleaseEnterLastName: "Mesedez, idatzi zure abizena",
    pleaseEnterBusinessName: "Mesedez, idatzi zure negozioaren izena",
    firstName: "Izena",
    lastName: "Abizena",
    businessName: "Enpresaren izena",
    previous: "Aurrekoa",
    next: "Hurrengoa",
    pleaseSelectBusinessCategory: "Mesedez, hautatu zure negozioaren kategoria",
    pleaseEnterValidEmail: "Mesedez, sartu baliozko mezu elektronikoa",
    pleaseEnterPostCode: "Mesedez, sartu posta kodea",
    postCode: "Posta kodea",
    phoneNumberInUse: "Telefono zenbaki hau erabiltzen ari da dagoeneko!",
    emailInUse: "Mezu hau dagoeneko erabiltzen ari da!",
    foodAndDrinks: "Janaria eta edaria",
    salonAndBeauty: "Apaindegia eta edertasuna",
    fashionAndAccessories: "Moda eta Osagarriak",
    gymAndFitness: "Gimnasioa eta Fitness",
    travelAndHotel: "Bidaia eta hotela",
    homeAndGifts: "Etxea eta Opariak",
    washingAndCleaning: "Garbiketa eta Garbiketa",
    gadgetsAndElectronics: "Gadgets eta Elektronika",
    groceries: "Janariak",
    others: "Beste batzuk",
    submit: "Bidali",
    accountCreatedSuccessful: "Zure kontua behar bezala sortu da.",
    pleaseEnterAddress: "Mesedez, idatzi zure helbidea",
    addressLine1: "Helbidea 1. lerroa",
    addressLine2: "Helbidea 2. lerroa",
    choosePassword: "Aukeratu Pasahitza",
    passwordMustBe6Characters:
      "Pasahitzak gutxienez 6 karaktere izan behar ditu.",
    howDidYouHearLoystar: "Nola izan zenuen Loystarren berri?",
    referralCode: "erreferentzia-kodea",
    byClickingTheButton: " Beheko botoian klik eginez gero, onartu egingo duzu",
    termsAndSevice: "Baldintzak",
    wereCreatingAccount: "Zure kontua sortzen ari gara",
    proceed: "Jarraitu",
    verificationCodeMustBe6: "Egiaztapen Kodeak 6 digitu izan behar ditu",
    pleaseEnter6DigitCode: "Mesedez, sartu 6 digituko kodea",
    enterVerificationCode: "Sartu egiaztapen-kodea",
    resendToken: "Bidali tokena",
    verify: "Egiaztatu",
    transactions: "Transakzioak",
    todaySales: "Gaurko salmentak",
    salesHistory: "Salmenten historia",
    supplyHistory: "Hornikuntzaren historia",
    new: "Berria",
    invoices: "Fakturak",
    disbursements: "Ordainketak",
    offlineSales: "Lineaz kanpoko salmentak",
    products: "Produktuak",
    customers: "Bezeroak",
    multiLevelLoyalty: "Maila anitzeko leialtasuna",
    loyaltyPrograms: "Fideltasun Programak",
    members: "Kideak",
    appStore: "App denda",
    orderMenu: "Eskaera menua",
    settings: "Ezarpenak",
    staffAndBranches: "Langileak eta sukurtsalak",
    myAccount: "Nire kontua",
    switchToSellMode: "Saldu modura aldatu",
    signOut: "Saioa amaitu",
    getFreeSubscription: "Lortu doako harpidetza",
    onlyNumbersAllowed: "Zenbakiak soilik onartzen dira",
    yourAccountMustBe10Digits:
      "zure kontu zenbakiak 10 digituko zenbakia izan behar du",
    yourBvnMustBe11: "zure BVNak 11 digituko zenbakia izan behar du",
    pleaseSelectBank: "Mesedez, hautatu zure bankua",
    selectYourBank: "Aukeratu zure Bankua",
    enterBankAccountNumber: "Idatzi Bankuko kontuaren zenbakia",
    enterBvnNumber: "Sartu zure BVN",
    connectBankAccount: "Konektatu Banku kontua",
    passwordResetSuccessfulAndSignOut:
      "Zure pasahitza arrakastaz berrezarri da. Egin klik beheko saioa amaitzeko botoian berriro saioa hasteko",
    enterCurrentPassword: "Sartu uneko pasahitza",
    pleaseEnterCurrentPassword: "Mesedez, sartu uneko pasahitza",
    phoneNumber: "Telefono zenbakia",
    sex: "Sexua",
    dateOfBirth: "Jaioteguna",
    state: "Estatu",
    country: "Herrialdea",
    loyaltyId: "Fideltasun IDa",
    createdAt: "At sortua",
    noOfTransactionsMade: "Egindako transakzio kopurua",
    yourDownloadWillStart: "Deskarga momentu batean hasiko da",
    exportCustomers: "Esportatu bezeroak",
    youhaveSuccessfullyToppedUp:
      "Zure SMS unitateak behar bezala osatu dituzu.",
    youNowHave: "Orain duzu",
    smsUnits: "SMS Unitateak",
    enterNumberOfSmsUnits: "Idatzi erosi nahi dituzun SMS unitate kopurua",
    pleaseEnterANumericValue: "Mesedez, sartu zenbakizko balioa",
    pay: "Ordaindu",
    accountEditedSuccessfully: "Zure kontua ondo editatu da.",
    youAeCurrentlyOn: "Une honetan aktibatuta zaude",
    plan: "Plana",
    userData: "Erabiltzailearen datuak",
    businessData: "ENPRESAREN DATUAK",
    businessCategory: "Enpresa sukaldaritza",
    pleaseSelectCurrency: "Mesedez, hautatu zure moneta",
    selectYourCurrency: "Aukeratu zure moneta",
    purchaseMoreSmsUnits:
      "Erosi SMS unitate gehiago beheko formularioaren bidez",
    youHave: "Baduzu",
    atLeast4SmsUnitsRrequired:
      "Egiaztatzeko gutxienez 4 sms unitate behar dira.",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Mesedez, egiaztatu zure banku-kontua "Ezagutu zure bezeroa" (KYC) baldintzak betetzeko. Honek ordainketak USSD edo berehalako transferentziaren bidez jasotzea, bezeroen aginduak jasotzea eta transakzioen likidazioa prozesatzea ahalbidetuko dizu. Nigeriar merkatarientzat soilik aplikagarria. Mesedez, sakatu beheko botoia hasteko.',
    reConnectBankAccount: "Konektatu berriro Banku kontua",
    accountName: "Kontuaren izena",
    accountNumber: "Kontu zenbakia",
    bankName: "Bankuaren izena",
    verified: "Egiaztatuta",
    accountDetails: "Kontuaren xehetasunak",
    kycBankAccount: "KYC",
    createTier: "Sortu maila",
    fileUploadSuccessful: "fitxategia behar bezala kargatu da",
    fileUploadFailed: "fitxategiak kargatzeak huts egin du",
    createLoyaltyProgram: "Sortu Leialtasun Programa",
    createLoyalty: "Sortu Leialtasuna",
    name: "Izena",
    loyaltyArtwork: "Leialtasun Artelanak",
    clickToUpload: "Egin klik kargatzeko",
    amountToPointsRatio: "Puntuen ratioa",
    points: "Puntuak",
    recommendedAmountToPointRatio:
      "Gomendatua: ₦ 1etik 1 puntura. hau da, gastatutako Naira bakoitzeko 1 puntu lortzen dute zure bezeroek",
    pleaseTypeIn: "Idatzi mesedez",
    toDeleteLoyalty: "leialtasun hori ezabatzeko",
    deleteLoyalty: "Ezabatu Leialtasuna",
    toConfirm: "baieztatzeko",
    edit: "Editatu",
    pointsAwardedSuccessfully: "Puntuak arrakastaz eman dira.",
    enterPointValueToAward:
      "Sartu bezeroei sarbidea eman nahi zeniekeen balioa",
    award: "Saria",
    awardPointValuesToCustomer: "Bezeroari puntuaren balioa eman",
    enrollMembersToLoyalty: "Eman izena Kideak Leialtasunera",
    awardPoints: "Sari Puntuak",
    enroll: "Izena eman",
    home: "Etxea",
    loyalty: "Leialtasuna",
    enrollCustomers: "Matrikulatu bezeroak",
    selected: "Aukeratua",
    customer: "Bezeroa",
    loyaltyActivationSuccessful: "Leialtasun Aktibazioa arrakastatsua da.",
    loyaltyDeactivationSuccessful:
      "Leialtasuna desaktibatzea arrakastatsua da.",
    viewTier: "Ikusi maila",
    deactivate: "Desaktibatu",
    activate: "Aktibatu",
    actions: "Ekintzak",
    nameOfLoyalty: "Leialtasunaren izena",
    loyaltyStatus: "Fideltasun egoeraLealtasun egoera",
    numberOfTiers: "Maila kopurua",
    createdOn: "Sortua On",
    createATier: "Sortu maila",
    stopCreatingTierConfirmation: "Tier bat sortzeari utzi nahi diozu?",
    stopEditingTierConfirmation: "Maila hau editatzeari utzi nahi diozu?",
    nameOfTier: "Mailaren izena",
    minimumSpendingTarget: "Gutxieneko Gastu Helburua",
    maximumSpendingTarget: "Gastuaren gehieneko helburua",
    minimumSpendingTargetRequired:
      "gutxieneko gastuaren helburua beharrezkoa da",
    maximumSpendingTargetRequired:
      "gehieneko gastuaren helburua beharrezkoa da",
    rewardSponsor: "Sari Babeslea",
    pleaseChooseARewardSponsor: "Aukeratu sari babesle bat",
    self: "Norbera",
    partner: "Bazkidea",
    rewardPartner: "Sari bikaina",
    pleaseSelectRewardPartner: "Mesedez, hautatu zure bikotekide saria",
    rewards: "Sariak",
    pleaseSelectAReward: "Mesedez, hautatu sari bat",
    instructionsOnRedeemingReward:
      "Bezeroak saria nola berreskuratu behar duen argibideak",
    pleaseFillInThisField: "Mesedez Bete Eremu hau!",
    toDeleteThisTier: " maila hau ezabatzeko",
    deleteTier: "Ezabatu maila",
    viewMembers: "Ikusi kideak",
    membersEnrolled: "Kideak Matrikulatuta",
    instruction: "Instrukzioa",
    membersIn: "Kideak hemen",
    availableTiersInLoyalty:
      "Fidagarritasun Programan eskuragarri dauden maila",
    tiers: "Mailak",
    totalTier: "GUZTIRA Maila",
    availableLoyaltyProgramme: "Eskuragarri dagoen Leialtasun Programa",
    totalLoyalties: "GUZTIRA LEIALTASUNAK",
    memberDetails: "Kideen xehetasunak",
    nameOfCustomer: "Bezeroaren izena",
    address: "Helbidea",
    allEnrolledMembers: "Matrikulatutako kide guztiak",
    thisIsToWishYouHappyBirthday:
      "Oso urtebetetze zoriontsua eta bizitza oparoa opa dizuegu. Eskerrik asko Loyster-i zaren guztiagatik. Ospakizun zoriontsuak!",
    inputAnOfferPlease: "Sartu Eskaintza mesedez",
    pleaseSelectTheInsertPoint:
      "Mesedez, hautatu mezuan txertatzeko puntua eta egin klik berriro",
    birthdayOfferAndMessage: "Urtebetetze Eskaintza eta Mezua",
    birthdayOffer: "Urtebetetze Eskaintza",
    birthdayMessage: "Urtebetetze mezua",
    noOfferFound: "Ez da eskaintzarik aurkitu",
    settingABirthdayOffer:
      "Urtebetetze eskaintza ezartzeak bezeroei eskaintza SMS bidez jasotzea ahalbidetzen die urtebetetzean",
    createOffer: "Sortu Eskaintza",
    whatIsTheOffer: "Zein da eskaintza?",
    editMessage: "Editatu mezua",
    insert: "Txertatu",
    theNameOfCustomerInserted: "Hemen sartuko da bezeroaren izena",
    theBirtdayOfferInserted: "Urtebetetze eskaintza hemen txertatuko da",
    youSuccessfullyAddedNewBranch: "Adar berri bat behar bezala gehitu duzu!",
    addNewBranch: "Gehitu adar berria",
    addBranch: "Gehitu adarra",
    additionalBranchWillIncur: "Adar gehigarriak sortuko luke",
    perBranch: "adar bakoitzeko",
    ecommerceBranchCosts: "Ecommerce Bulegoaren kostuak",
    pleaseEnterBranchName: "Idatzi sukurtsalaren izena",
    pleaseEnterBranchAddress1: "Mesedez, sartu bulegoaren helbide 1",
    enterBranchAddress1: "Idatzi bulegoaren helbide 1",
    enterBranchAddress2: "Idatzi bulegoaren helbide 1",
    pleaseEnterBranchAddress2:
      "Mesedez, sartu bulegoaren helbidearen 2. lerroa",
    enterBranchName: "Idatzi sukurtsalaren izena",
    successfullyAddedStaff: "Langile berri bat behar bezala gehitu duzu!",
    addNewStaff: "Gehitu langile berriak",
    addStaff: "Gehitu langileak",
    additionalStaffWillIncur: "Langile osagarriak sortuko lirateke",
    perStaff: "langile bakoitzeko.",
    pleaseEnterStaffEmail: "Mesedez, idatzi langileen helbide elektronikoa",
    pleaseEnterStaffUsername: "Mesedez, sartu langileen erabiltzaile izena",
    pleaseEnterStaffPassword: "Mesedez, sartu langileen pasahitza",
    pleaseSelectStaffRole: "Mesedez, hautatu langileen rola",
    selectStaffRole: "Aukeratu langileen rola",
    enterStaffEmail: "Idatzi langileen helbide elektronikoa",
    enterStaffUsername: "Idatzi langileen erabiltzaile izena",
    enterStaffPassword: "Idatzi langileen pasahitza",
    spacesNotAllowedInUsername: "erabiltzaile izenean baimendutako espazioak",
    admin: "Administratzailea",
    pleaseSelectBusinessToAttachStaff:
      "Aukeratu negozioa langileak atxikitzeko",
    searchForBranchToAttachStaff: "Bilatu bulegoa langileak atxikitzeko",
    username: "Erabiltzaile izena",
    role: "Rola",
    areYouSureToDeleteThis: "Ziur zaude hau ezabatuko duzula",
    branches: "Adarrak",
    upgradeYourPlan: "Bertsio-berritu zure plana.",
    add: "GEHITU",
    addNew: "Gehitu berria",
    customerWithEmailAlreadyExists:
      "Posta elektronikoa / telefono zenbakia duen bezeroa dagoeneko badago!",
    successfullyAddedNewCustomer: "Bezero berri bat behar bezala gehitu duzu!",
    addCustomer: "Gehitu bezeroa",
    pleaseEnterCustomerFirstName: "Mesedez, sartu bezeroaren izena",
    pleaseEnterCustomerLastName: "Mesedez, sartu bezeroaren abizena",
    pleaseEnterCustomerPhoneNumber:
      "Mesedez, sartu bezeroaren telefono zenbakia",
    pleaseEnterCustomerEmail: "Mesedez, sartu bezeroaren helbide elektronikoa",
    pleaseEnterCustomerAddressLine: "Mesedez, sartu bezeroaren helbide lerroa",
    pleaseEnterCustomerOtherAddress: "Mesedez, sartu bezeroaren beste helbidea",
    pleaseSelectCustomerGender: "Mesedez, aukeratu bezeroaren generoa",
    gender: "Generoa",
    male: "Arra",
    female: "Emakumezkoa",
    bank: "Bankua",
    selectBank: "Aukeratu Bankua",
    stateOrRegionOrProvince: "Estatua / Eskualdea / Probintzia",
    customerNotes: "Bezeroaren oharrak",
    sendSms: "Bidali SMSa",
    editCustomer: "Editatu bezeroa",
    redeemReward: "Berreskuratu saria",
    issueLoyaltyCard: "Jaulki Fideltasun Txartela",
    deleteCustomer: "Ezabatu bezeroa",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Leialtasuneko kidetza IDa esleitu duzu",
    noMembershipIdAvailable:
      "Ez dago bazkide IDrik erabilgarri. Mesedez, jarri gurekin harremanetan hello@loystar.co helbidean",
    sendEmail: "Bidali posta elektronikoa",
    membershipPoints: "Kide Puntuak",
    customerDetails: "Bezeroaren datuak",
    close: "Itxi",
    loyaltyBalance: "Leialtasun saldoa",
    pointsBalance: "Puntuen oreka",
    starBalance: "Izarren oreka",
    requiredPoints: "Beharrezko Puntuak",
    requiredStars: "Beharrezko Izarrak",
    reddemCode: "Erabili kodea",
    toDeleteThisCustomer: "bezero hau ezabatzeko",
    customerHasBeenDeletedSuccessfully: "Bezeroa behar bezala ezabatu da!",
    customerWithPhoneAlreadyExists:
      "Telefono zenbakia duen bezeroa lehendik dago",
    customerWasSuccessfullyEdited: "Bezeroa behar bezala editatu da",
    anErrorOccured: "Errore bat gertatu da",
    phoneNumberIncludeCountry: "Telefono zenbakia (Herrialde kodea sartu)",
    yourFileQueuedForUpload:
      "Zure fitxategia ilaran jarri da kargatzeko. Freskatu orria segundo batzuen buruan.",
    thereWasAnErrorPerformingOperation:
      "Errore bat gertatu da eragiketa burutzean!",
    pleaseSelectFile: "Mesedez, hautatu fitxategi bat!",
    oopsOnlyCsvFilesAllowed:
      "Aupa! CSV fitxategiak soilik onartzen dira. Kargatu .csv fitxategi bat.",
    fileShouldBeSmallerThan5Mb:
      "Fitxategiak 5 MB baino txikiagoa izan behar du. Fitxategi handiagoa baduzu, bidali helbide elektronikoa support@loystar.co helbidera. Eskerrik asko",
    customerFirstNameIsRequired: "bezeroaren izena beharrezkoa da errenkadan",
    customerPhoneNumberIsRequired:
      "bezeroaren telefono zenbakia beharrezkoa da errenkadan",
    importCustomers: "Inportatu bezeroak",
    upload: "Kargatu",
    clickIconToDownloadCsv:
      "Egin klik ikono honetan CSV fitxategiaren txantiloia deskargatzeko.",
    getGoogleSheetVersion: "Lortu Google orriaren bertsioa",
    clickOrDragFileToUpload:
      "Egin klik edo arrastatu fitxategia area honetara kargatzeko",
    missingOrInvalidColumnHeader:
      "Zutabe goiburua falta edo baliogabea. Jarrai ezazu txantiloiaren formatua. Eskerrik asko.",
    youHaveImported: "Inportatu duzu",
    youSuccessfullyRedeemedReward: "Zure saria behar bezala trukatu duzu!",
    sixDigitCode: "Sei digituko kodea",
    pleaseEnterCustomerRewardCode: "Mesedez, sartu bezeroaren sari kodea",
    enterRewardCode:
      "Idatzi sari kodea. (Sari kodea maiuskulak eta minuskulak bereizten ditu)",
    selectLoyaltyProgram: "Aukeratu leialtasun programa",
    stamps: "Zigiluak",
    smsUnitsLow: "SMS unitateak baxuak",
    pleaseTopUpSmsUnits: "Mesedez, kargatu SMS unitateak",
    smsSuccessfullySentForDelivery:
      "SMSak bidaltzeko behar bezala bidali dira!",
    sendSmsTo: "Bidali SMS helbidera",
    allCustomers: "Bezero guztiak",
    unitsAvailable: "Unitateak eskuragarri",
    pleaseTypeInTheMessage: "Idatzi mezua",
    message: "Mezua",
    charactersRemaining: "karaktere geratzen dira",
    avoidUseOfSpecialCharacters:
      "Saihestu Pertsonaia bereziak eta Emojiak erabiltzea SMS unitateak gordetzeko.",
    note: "Ohar",
    errorFetchingCustomersMultilevelDetail:
      "Errorea bezeroak maila anitzeko xehetasunak eskuratzean",
    search: "Bilatu",
    reset: "Berrezarri",
    importCustomer: "Inportatu bezeroa",
    addNewCustomer: "Gehitu bezero berria",
    sendSmsBroadcast: "Bidali SMS igorpena",
    totalCustomers: "BEZERO GUZTIRA",
    disbursementDetails: "Ordainketaren xehetasunak",
    paidBy: "Ordaindutakoa",
    disbursed: "Dirua",
    bankAccountName: "Banku-kontuaren izena",
    bankAccountNumber: "Bankuko kontuaren zenbakia",
    transferInitiated: "Transferentzia hasitakoa",
    transferCompleted: "Transferentzia amaituta",
    pleaseEnterAValid: "Mesedez, sartu baliozko bat",
    begin: "hasi",
    end: "amaiera",
    date: "data",
    paymentDate: "Ordainketa Data",
    selectDisbursementDuration: "Aukeratu Iraupenaren iraupena",
    totalSettled: "Guztiz likidatua",
    totalUnsettled: "Guztiz ezargabea",
    toDeleteThisSale: "salmenta hau ezabatzeko",
    draftSaleDeletedSuccessfully:
      "Zirriborroen salmenta behar bezala ezabatu da!",
    deleteSale: "Ezabatu salmenta",
    pleaseTypeInYourTotal:
      "Mesedez, idatzi zure zenbatekoa ezabatzen dela baieztatzeko",
    billDetails: "Fakturaren xehetasunak",
    printBill: "Inprimatu faktura",
    servedBy: "Zerbitzaria",
    total: "Guztira",
    createdDate: "Sortze-data",
    createdTime: "Sortutako denbora",
    status: "Egoera",
    loginSuccessful: "Saioa hasi da",
    pleaseWaitWhileWeConnectAccount:
      "Mesedez, itxaron zure kontua konektatzen dugun bitartean",
    connectionFailedTryAgain: "Konexioak huts egin du. Saiatu berriro mesedez.",
    connectionSuccessful: "Konexioa arrakastatsua da",
    viewDetails: "Ikusi xehetasunak",
    enable: "Gaitu",
    free: "Doakoa",
    cost: "Kostua",
    visitWebsite: "Bisitatu webgunea",
    pleaseUpgradeYourPlanToPro:
      "Bertsio-berritu plana Pro Plus-era aplikazio hau gaitzeko",
    connectYourBankAccount:
      "Konektatu bankuko kontua ordainketak jaso ahal izateko.",
    disable: "Desgaitu",
    enableApp: "Gaitu aplikazioa",
    addNewProductToInvoice: "Gehitu produktu berria fakturan",
    toDeleteThisInvoice: "Faktura hau ezabatzeko",
    invoiceDeletedSuccessfully: "Faktura ondo ezabatu da!",
    deleteInvoice: "Ezabatu faktura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Fakturen ordainketa mezua behar bezala gehitu duzu!",
    pleaseEnterPaymentInstructions: "Mesedez, sartu ordainketa argibideak",
    invoiceId: "Faktura IDa",
    paidDate: "Ordaindutako Data",
    reference: "Erreferentzia",
    productAdded: "Produktua gehitu da",
    productOutOfStock: "Produktua agortu gabe. Mesedez, berriro hornitu.",
    totalInvoices: "FAKTURAK GUZTIRA",
    totalPaidInvoices: "ORDAINDUTAKO FAKTURA GUZTIRA",
    totalUnpaidInvoices: "ORDAINDU GABEKO FAKTURAK",
    loyaltyProgramDeleted: "Leialtasun programa ezabatu da",
    thisLoyaltyProgramDeletedSuccessfully:
      "Leialtasun programa hau behar bezala ezabatu da",
    thisLoyaltyProgramEditedSuccessfully:
      "Leialtasun programa hau ongi editatu da",
    loyaltyProgramAddedSuccessfully: "Leialtasun programa ondo gehitu da",
    loyaltyProgramEdited: "Leialtasun programa editatua",
    loyaltyProgramAdded: "Leialtasun programa gehitu da",
    loyaltyDetails: "Fideltasun xehetasunak",
    doYouWantToCloseDialog: "Elkarrizketa hau itxi nahi al duzu?",
    pleaseEnterLoyaltyName: "Mesedez, idatzi zure leialtasunaren izena",
    programType: "Programa mota",
    pleaseSelectProgramType: "Hautatu programa mota bat",
    simplePoints: "Puntu sinpleak",
    stampsProgram: "Zigiluen Programa",
    threshold: "Atalasea",
    pleaseEnterLoyaltyThreshold: "Mesedez, sartu leialtasun atalasea",
    reward: "Saria",
    pleaseEnterLoyaltyReward: "Mesedez, sartu leialtasunaren saria",
    totalUserPoints: "Erabiltzaile puntu guztiak",
    totalUserStamps: "Erabiltzaileen zigiluak guztira",
    spendingTarget: "Gastuaren xedea",
    spendingTargetHint1:
      "Gastuaren helburua bezeroak saria irabazteko zenbat gastatu behar duen da. 1 moneta balio = 1 puntu.",
    spendingTargetHint2:
      "Zigiluen xedea da bezeroak saria irabazteko zenbat zigilu bildu behar dituen. Adib. 5",
    addNewLoyaltyProgram: "Gehitu Leialtasun Programa Berria",
    addLoyaltyProgram: "Gehitu Leialtasun Programa",
    loyaltyProgramType: "Leialtasun programa mota",
    pleaseSelectLoyaltyProgramType: "Mesedez, hautatu leialtasun programa mota",
    nameOfProgram: "Programaren izena",
    pleaseEnterProgramName: "Mesedez, sartu programaren izena",
    whatIsTheReward: "Zein da saria?",
    egNextPurchaseIsFree: "Adib. Hurrengo erosketa doakoa da",
    customerName: "Bezeroaren Izena",
    guestCustomer: "Bezero gonbidatua",
    orderConfirmedSuccessfully: "Eskaera behar bezala baieztatu da",
    orderCancelledSuccessfully: "Eskaera behar bezala bertan behera utzi da",
    confirmOrder: "Berretsi eskaera",
    cancelOrder: "Utzi eskaera",
    allOrders: "Agindu guztiak",
    totalOrders: "ESKAERA GUZTIRA",
    doYouWantToAcceptOrder: "Eskaera hau onartu nahi al duzu?",
    doYouWantToCancelOrder: "Eskaera bertan behera utzi nahi al duzu?",
    orderDetails: "Eskaeraren xehetasunak",
    orderCreatedAt: "Urtean sortutako eskaera",
    supplier: "Hornitzailea",
    productName: "produktuaren izena",
    quantity: "Kopurua",
    unitPrice: "Prezio unitarioa",
    receivedBy: "Jasotakoa",
    reportFrom: "Ren berri",
    totalSupplies: "Hornidura totala",
    allRightsReserved: "Eskubide guztiak erreserbatuak",
    toDeleteThisTransaction: "Transakzio hau ezabatzeko",
    transactionDeletedSuccessfully:
      "Transakzioa behar bezala ezabatu da. Izakinak inbentariora itzuli dira.",
    deleteTransaction: "Ezabatu Transakzioa",
    transactionDetails: "Transakzioaren xehetasunak",
    printReceipt: "Inprimatu ordainagiria",
    channel: "Kanala",
    discount: "Deskontua",
    profit: "Irabaziak",
    discountedSales: "Deskontatutako salmentak",
    errorFetchingRecord: "Errorea erregistroa eskuratzean",
    exportTransactions: "Esportazio transakzioak",
    errorFetchingSalesRecord:
      "Errorea Esportatzeko salmenten erregistroa eskuratzean.",
    totalSellingPrice: "Salmenta Prezioa",
    totalCostPrice: "Guztizko kostuaren prezioa",
    appliedDiscount: "Aplikatutako Deskontua",
    noOfItems: "Elementu kopurua",
    sales: "Salmentak",
    export: "Esportatu",
    totalProfit: "Irabaziak guztira",
    totalBalanceInPeriod: "Guztizko saldoa aldian",
    allTimeSales: "Denbora guztietako salmentak",
    records: "Diskoak",
    todaysSales: "Gaurko salmentak",
    transaction: "transakzioa",
    youHaveExceededTotalNumberOfProducts:
      "Zure planean onartutako produktuen kopurua gainditu duzu. Bertsio-berritu zure plana muga altuago bat izateko.",
    youNeedToHaveLoyaltyProgram:
      "Ezaugarri hau erabiltzeko Leialtasun Programa izan behar duzu!",
    price: "Prezioa",
    category: "Kategoria",
    stockTracking: "Izakinen jarraipena",
    stockCount: "Izakinen kopurua",
    taxed: "Zergapean",
    originalPrice: "Jatorrizko prezioa",
    costPrice: "Kostuaren prezioa",
    unit: "Unitatea",
    productImage: "Produktuaren irudia",
    taxRate: "Zerga tasa",
    taxType: "Zerga mota",
    trackInventory: "Track Inbentarioa",
    variants: "Aldaerak",
    hasVariants: "Aldaerak ditu",
    importProduct: "Inportatu produktua",
    exportProducts: "Esportatu produktuak",
    addNewProduct: "Gehitu produktu berria",
    viewCategory: "Ikusi Kategoria",
    viewSuppliers: "Hornitzaileak ikusi",
    receiveInventory: "Inbentarioa jaso",
    printAllProductsTag: "Inprimatu produktu guztien etiketa",
    deleteAll: "Ezabatu guztiak",
    totalProducts: "PRODUKTU GUZTIRA",
    youveSuccessfullyAddedANewCategory: "Kategoria berria ondo gehitu duzu",
    addNewCategory: "Gehitu kategoria berria",
    addCategory: "Gehitu Kategoria",
    categoryName: "Kategoriaren izena",
    pleaseEnterCategoryName: "Mesedez, sartu kategoriaren izena",
    stampsTarget: "Stamps Target",
    sendInventory: "Bidali inbentarioa",
    productDetails: "Produktuaren xehetasunak",
    youveSuccessfullyEditedThisCategory: "Kategoria hau ondo editatu duzu",
    update: "Eguneratu",
    categoryList: "Kategoria zerrenda",
    categories: "Kategoriak",
    enterQuantityToUpdate: "Idatzi kantitate bat eguneratzeko",
    inventorySentSuccessfully: "Inbentarioa behar bezala bidali da!",
    updateInventory: "Eguneratu inbentarioa",
    currentQuantity: "Uneko kantitatea",
    pleaseEnterQuantityToAdd: "Idatzi gehitu nahi duzun kantitatea",
    pleaseSelectABranch: "Mesedez, hautatu bulego bat",
    searchForBranch: "Bilatu sukurtsala",
    youCantSendMoreThanStock: "Ezin duzu stockean baino gehiago bidali",
    send: "Bidali",
    pleaseEnterQuantityToSend: "Idatzi bidali nahi duzun kantitatea",
    productNameIsRequiredOnRow: "produktuaren izena beharrezkoa da errenkadan",
    productCategoryIsRequiredOnRow:
      "produktuaren kategoria beharrezkoa da errenkadan",
    productPriceIsRequiredOnRow: "produktuaren prezioa beharrezkoa da ilaran",
    productUnitIsRequiredOnRow: "produktu unitatea beharrezkoa da ilaran",
    productQuantityIsRequiredOnRow: "produktu kopurua beharrezkoa da ilaran",
    productVariantsRequireTracking:
      "produktuaren aldaerak jarraipena egitea eskatzen du!",
    pleaseAddVariantClickButton:
      "Gehitu aldaera bat gehitu botoian klik eginez!",
    totalVariantsMoreThanSelectedQuantity:
      "Aldaera guztiak hautatutako produktu kantitatea baino gehiago dira. Aldagai kantitatea murrizten da",
    productEditedSuccessfully: "Produktua ondo editatu da!",
    fileTooLargeLessThan4Mb:
      "Fitxategiaren tamaina handiegia da! Fitxategiaren tamainak 4 MB baino gutxiago izan behar du.",
    suchVariantAlreadyExist: "Aldaera hori dagoeneko existitzen da",
    addVariants: "Gehitu aldaerak",
    editProduct: "Editatu produktua",
    pleaseEnterProductName: "Idatzi produktuaren izena",
    pleaseEnterProductPrice: "Idatzi produktuaren prezioa",
    pleaseEnterProductOriginalPrice: "Idatzi produktuaren jatorrizko prezioa",
    productDescription: "Produktuaren Deskribapena",
    selectProductCategory: "Aukeratu produktuaren kategoria",
    pleaseSelectProductCategory: "Aukeratu produktu kategoria",
    productCostPrice: "Produktuaren kostuaren prezioa",
    productSellingPrice: "Produktuaren salmenta prezioa",
    productOriginalPrice: "Produktuaren jatorrizko prezioa",
    maxFileSizeAllowedIs4Mb: "Gehienez Fitxategiaren tamaina 4 MB da",
    productsWithPicturesArePublished:
      "Irudiak dituzten produktuak Discover-en argitaratzen dira eskaerak jasotzeko",
    shouldThisProductBeTracked: "Produktu honen jarraipena egin behar al da?",
    pleaseSelectStockUnit: "Hautatu stock unitatea",
    stockUnit: "Stock Unitatea",
    bag: "POLTSA",
    bottle: "BOTILA",
    bunch: "SORTZA",
    can: "CAN",
    carton: "KARTOIA",
    crate: "CRATE",
    cup: "KOPA",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAMA",
    litre: "LITROA",
    pack: "PACK",
    pair: "PARE",
    pieces: "PIEZAK",
    plate: "PLAKA",
    tonne: "TONNE (MT)",
    uNIT: "UNITATEA",
    yard: "PATIOA",
    pleaseEnterProductQuantity: "Mesedez, sartu produktuaren kantitatea",
    productQuantity: "Produktuaren kantitatea",
    isThisProductTaxed: "Produktu hau zergapetuta al dago?",
    selectTaxType: "Aukeratu Zerga mota",
    pleaseSelectTaxType: "Aukeratu zerga mota",
    progressive: "Progresiboa",
    proportional: "Proportzionala",
    pleaseEnterProductTaxRate:
      "Mesedez, sartu produktuaren gaineko zergaren tasa",
    doesProductHaveVariants: "Produktu honek aldaerak ditu?",
    type: "Mota",
    value: "Balioa",
    pleaseEnterVariantType: "Mesedez, sartu aldaeraren mota",
    pleaseEnterVariantValue: "Mesedez, sartu aldaeraren balioa",
    pleaseEnterVariantPrice: "Mesedez, sartu aldaeraren prezioa",
    pleaseEnterVariantQuantity: "Mesedez, sartu aldaeraren kantitatea",
    productDeletedSuccessfully: "Produktua behar bezala ezabatu da!",
    categoryDeletedSuccessfully: "Kategoria behar bezala ezabatu da!",
    toDeleteThisProduct: "Produktu hau ezabatzeko",
    invalidProductQuantity: "Produktuaren kantitate baliogabea",
    quantityAddedIsOverStock:
      "Gehitzen ari zaren kopurua stockean duzuna baino gehiago da.",
    itemInventoryNotTracked: "Elementuen inbentarioa ez da jarraipena egiten",
    addBulkQuantity: "Gehitu kantitate handikoa",
    enterBulkQuantity: "Sartu kantitate masiboa",
    pleaseEnterBulkQuantity: "Mesedez, sartu kantitate masiboa",
    youveSuccessfullyAddedANewProduct:
      "Produktu berri bat behar bezala gehitu duzu!",
    pleaseEnterProductSellingPrice:
      "Mesedez, sartu produktuaren salmenta prezioa",
    doYouWantToTrackProductStock:
      "Produktuen stockaren jarraipena egin nahi al duzu?",
    sellingPrice: "Salmenta prezioa",
    setProductExpiryReminder: "Ezarri produktuaren iraungitze abisua",
    productExpiryDate: "Produktuaren iraungitze data",
    startRemindingFrom: "Hasi gogoratzen",
    productSuppliesAddedSuccessfully:
      "Produktuen hornidurak ondo gehitu dituzu.",
    addProductSupplies: "Gehitu produktuen hornidurak",
    pleaseSelectSupplier: "Mesedez, hautatu hornitzailea",
    nameOfProduct: "Produktuaren izena",
    pleaseSelectProduct: "Aukeratu produktua",
    productVariant: "Produktuaren aldaera",
    pleaseSelectAVariant: "Hautatu aldaera bat",
    pleaseEnterUnitPrice: "Mesedez, sartu unitateko prezioa",
    businessBranch: "Enpresa-adarra",
    pleaseSelectBranch: "Mesedez, hautatu adarra",
    youveSuccessfullyAddedANewSupplier:
      "Hornitzaile berri bat behar bezala gehitu duzu",
    addSupplier: "Gehitu hornitzailea",
    pleaseEnterSupplierEmail:
      "Mesedez, sartu hornitzailearen helbide elektronikoa",
    pleaseAddADescription: "Mesedez, gehitu deskribapena",
    anErrorOccuredProductsDeleted:
      "Errore bat gertatu da eragiketa burutzean. Kontuan izan, baliteke produktu gutxi ezabatu izana prozesuan",
    bulkDelete: "Ezabatu masiboki",
    youAreAboutToDelete: "Ezabatzera zoaz",
    product: "Produktua",
    isDueToRedeem: "berreskuratu behar da",
    aReward: "sari bat",
    pleaseSelectCustomerToReeemReward:
      "Mesedez, hautatu bezero bat saria trukatzeko.",
    youHaveNoLoyaltyProgramRunning:
      "Ez duzu leialtasun programa aktiborik martxan",
    customerHhasNoPointsInLoyaltyProgram:
      "Bezeroak ez du punturik fidelizazio programa honetan",
    proceedWithPayment: "Ordainketarekin jarraitu nahi duzu?",
    youAreAboutToPayForTransactionUsingPoints:
      "Transakzioa puntuen bidez ordaintzekotan zaude.",
    customerHas: "Bezeroak du",
    worth: "merezi du",
    andIsNotSufficientToPayForTransaction:
      "eta ez da nahikoa transakzio hori ordaintzeko. Saldoa beste ordainketa modu bat erabiliz gehitu nahi al lukete?",
    addCustomerLoyalty: "Gehitu bezeroen leialtasuna",
    pleaseAddCustomerFirst: "Mesedez, gehitu edo hautatu bezero bat lehenik.",
    pleaseWaitWhileWeGetReady: "Itxaron mesedez, prestatzen garen bitartean",
    lowStock: "Stock baxua",
    pleaseEnterAmountTendered: "Mesedez, sartu lizitatutako zenbatekoa",
    areYouSureToBookSaleOffline:
      "Ziur zaude salmenta lineaz kanpo erreserbatu nahi duzula?",
    saleWouldBeBookedAutomatically:
      "Salmenta automatikoki erreserbatuko litzateke zure internet piztean",
    offlineSalesBookingCancelled:
      "Lineaz kanpoko salmenten erreserba bertan behera utzi da",
    covid19Message:
      "COVID19: Garbitu eskuak xaboiarekin edo higienizatu hedapena gelditzeko. Seguru egon beti",
    saleSuccessfullyRecorded: "Salmenta ongi grabatu da!",
    sendReceiptToEmail: "Bidali ordainagiria posta elektronikora",
    sendThankYouSms: "Bidali eskerrik asko SMS",
    sendShippingDetails: "Bidali bidalketa xehetasunak",
    addLoyalty: "Gehitu Leialtasuna",
    searchCustomerNameOrNumber: "Bilatu bezeroaren izena edo zenbakia",
    clickTheSearchCustomerBox:
      "Egin klik Bilatu bezeroaren kutxan eta Eskaneatu txartelean",
    enterProductPrice: "Sartu produktuaren prezioa",
    enterPriceFor: "Sartu prezioa urtean",
    searchForProduct: "Bilatu produktua",
    all: "Guztiak",
    backToDashboard: "Itzuli Arbelera",
    viewDraftSales: "Ikusi zirriborroen salmentak",
    variantSelected: "hautatutako aldaera",
    variant: "aldaera",
    thePreviousVariantSelectionNotAvailable:
      "Aurreko aldaeraren aukeraketa ez dago eskuragarri prezioaren arabera hautatutako aldaera berrirako. Aldatu hautapena.",
    pleaseSupplyPositiveQuantityNumber:
      "Mesedez, eman zenbaki kopuru positiboa",
    lowStockFor: "Stock gutxi",
    clearVariants: "Aldaera garbiak",
    pleaseEnterQuantity: "Mesedez, sartu Kopurua",
    picture: "Irudia",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Erabat gastatua",
    confirmPayment: "Berretsi ordainketa",
    hasPaymentBeenMade: "Ordainketa behar bezala prozesatu da?",
    enterTransactionReference:
      "Idatzi ordaindutako transakzioaren erreferentzia",
    pleaseSelectACustomer: "Mesedez, hautatu bezero bat!",
    areYouSureToApplyDiscount: "Ziur al zaude beherapena aplikatu nahi duzula?",
    addYourBankAccountToEnableUssd:
      "Gehitu zure banku-kontua berehalako USSD transferentzia gaitzeko uPay bidez",
    totalAmountToPay: "Ordaindu beharreko zenbatekoa guztira",
    doYouWantToCancelTransaction:
      "Transakzio hau bertan behera utzi nahi duzu?",
    savePrintBill: "Gorde / Inprimatu faktura",
    enterAmountCollectedForCustomer: "Idatzi bezeroarentzako bildutako kopurua",
    recordSale: "Disko salmenta",
    checkOutWith: "Begiratu honekin",
    instantTransfer: "Berehalako transferentzia",
    dialTheUSSDCode: "Markatu USSD kodea",
    pleaseSelectABank: "Mesedez, aukeratu banku bat",
    payWithUSSD: "Ordaindu USSD-rekin",
    sendBillTo: " - Bidali faktura helbidera",
    waitingForUSSDTransfer: "USSD transferentziaren zain",
    percent: "Ehunekoa",
    applyDiscount: "Aplikatu deskontua",
    thisBillHasBeenSaved: "Faktura hau gorde egin da",
    saveDraft: "Gorde zirriborroa",
    pleaseTypeANameToIdentifyCustomer:
      "Mesedez, idatzi izena bezeroa identifikatzeko",
    paymentDetails: "Ordainketaren xehetasunak",
    basePrice: "Oinarrizko prezioa",
    staff: "Langileak",
    subTotal: "AzpiTotala",
    durationMonths: "Iraupena (hilabeteak)",
    selectADuration: "Aukeratu Iraupena",
    oneMonth: "1 Hilabetea",
    twoMonths: "2 hilabete",
    threeMonths: "3 hilabete",
    sixMonths: "6 hilabete",
    twelveMonths: "12 hilabete",
    eighteenMonths: "18 hilabete",
    twentyFourMonths: "24 Hilabeteak",
    twoMonthsFree: "(2 hilabete doan)",
    threeMonthsFree: "(3 hilabete doan)",
    fiveMonthsFree: "(5 hilabete doan)",
    yourAccountHasBeen: "Zure kontua izan da",
    renewed: "berritu",
    upgraded: "berritu",
    successfully: "arrakastaz",
    yourSubscription: "Zure harpidetza",
    youAreCurrentlyEnrolledOnA: "Une honetan erregistratuta zaude",
    pleaseChooseAPaymentOption: "Aukeratu Ordainketa Aukera",
    planRenewal: "Plana berritzea",
    planUpgrade: "Planifikatu bertsio berritzea",
    pleaseSelectDurationToPayFor: "Aukeratu ordaintzeko asmoa duzun iraupena",
    staffAccounts: "Langileen kontuak",
    ecommerce: "E-merkataritza",
    pleaseSelectAPlan: "Mesedez, hautatu plan bat",
    includeAddons: "Sartu gehigarriak",
    viewTransactions: "Ikusi Transakzioak",
    customerHasNoCompletedTansactions:
      "Bezeroak oraindik ez ditu burutu tanakzioak",
    branch: "Adarra",
    enterNumberOfEcommerceBranches:
      "Idatzi merkataritza elektronikoko bulego kopurua",
    enterNumberOfEcommerceBranchesToPay:
      "Sartu ordaindu nahi dituzun merkataritza elektronikoko sukurtsalen kopurua",
    ecommerceIntegration: "Ecommerce integrazioa",
    enterNumberOfBranches: "Idatzi adar kopurua",
    enterNumberOfAdditionalBranchesToPay:
      "Idatzi ordaintzeko asmoa duzun sukurtsal gehigarri kopurua",
    enterNumberOfStaffs: "Idatzi langile kopurua",
    enterNumberOfStaffsToPayFor: "Idatzi ordaindu nahi duzun langile kopurua",
    discountApplies: "Deskontua aplikatzen da",
    starsOnThe: "izarrak",
    offer: "eskaintza",
    get: "Lortu",
    moreStarsToRedeem: "izar gehiago trukatzeko",
    taxVat: "Zerga (BEZ)",
    callCashierToCompletePayment: "Deitu Kutxazaina Ordainketa osatzeko",
    receipt: "Ordainagiria",
    dear: "Maitea",
    thankYouForYourOrderFindGoods:
      "Eskerrik asko zure eskaeragatik. Aurkitu ondoko ondasun hauek, adostu bezala.",
    shippingNote: "Bidalketa Oharra",
    enterShippingNote: "Idatzi Bidalketa Oharra",
    shippingAddress: "Bidaltzeko Helbidea",
    enterShippingAddress: "Idatzi Bidalketa helbidea",
    wellDoneYouAreDueToRedeem: "Ongi egina! Erabiliko duzu",
    toGetYourRewardNextVisit:
      "zure hurrengo bisitan zure saria lortzeko. Eskerrik asko",
    pointsOnThe: "Puntuak",
    morePointsToRedeem: "puntu gehiago trukatzeko",
    showCode: "Erakutsi kodea",
    toGetYourRewardOnNextVisit:
      "zure hurrengo bisitan zure saria lortzeko. Eskerrik asko",
    earn: "Irabazi",
    delivaryNote: "Banaketa Oharra",
    draftSales: "Zirriborroen salmentak",
    startDate: "Hasiera data",
    endDate: "Amaiera data",
    orders: "Eskaerak",
    checkout: "errebisatu",
    noProductItem: "Produktuik ez",
    selectProductImage: "Aukeratu produktuaren irudia",
    selectCountry: "Aukeratu herrialdea",
    selectRegion: "Aukeratu Estatua / Eskualdea",
    printProductTag: "Inprimatu produktuaren etiketa",
    transactionReference: "Transakzioaren erreferentzia",
    Cashier: "Kutxazaina",
    Manager: "Kudeatzailea",
    Owner: "Jabea",
    Admin: "Administratzailea",
  },
  Belarusian: {
    cashier: "касір",
    manager: "менеджэр",
    owner: "уладальнік",
    online: "анлайн",
    offline: "афлайн",
    changePassword: "Змяніць пароль",
    currentPasswordMessage: "Калі ласка, увядзіце ваш бягучы пароль",
    passwordMessage: "Калі ласка, увядзіце пароль",
    currentPassword: "Бягучы пароль",
    password: "пароль",
    confirmPasswordMessage: "Пацвердзіце пароль!",
    confirmPassword: "Пацвердзіце пароль",
    sendViaEmail: "Адправіць па электроннай пошце",
    sendViaWhatsapp: "Адпраўка праз WhatsApp",
    downloadPdf: "Спампаваць pdf",
    paid: "платныя",
    unpaid: "неаплачаны",
    partial: "частковы",
    closeInvoice: "Вы хочаце закрыць рахунак -фактуру?",
    closeInvoiceConfirmation:
      "Рахунак не можа быць захаваны. Вы хочаце закрыць?",
    yes: "так",
    no: "не",
    invoice: "Рахунак -фактура",
    wasDeducted: "быў адлічаны",
    for: "за",
    item: "Пункт",
    addProduct: "Дадаць прадукт",
    paymentReference: "Даведка аб аплаце",
    amountPaid: "Выплачаная сума",
    discountAmount: "Сума зніжкі",
    amountDue: "Запазычаная сума",
    amount: "Сума",
    dueDate: "Тэрмін",
    paymentType: "Тып аплаты",
    card: "Картка",
    cash: "Наяўныя",
    bankTransfer: "Банкаўскі пераклад",
    paymentMessage: "Паведамленне аб аплаце",
    description: "Апісанне",
    sendReceipt: "Адпраўка квітанцыі",
    delete: "Выдаліць",
    save: "Захаваць",
    resend: "Паўторна адправіць",
    saveAndSend: "Захаваць",
    invoiceSaved: "Рахунак захаваны!",
    selectPaymentMethod: "Калі ласка, абярыце спосаб аплаты!",
    selectCustomer: "Калі ласка, абярыце кліента!",
    cartEmptyError:
      "Спіс кошыкаў не можа быць пустым, зачыніце рахунак -фактуру і дадайце тавар у кошык!",
    subscriptionExpired:
      "Ваша падпіска скончылася, і ваш уліковы запіс зараз абмежаваны. Націсніце на кнопку ніжэй, каб абнавіць свой рахунак",
    renew: "Падоўжыць",
    holdOn: "Пачакай",
    customerBank: "Банк кліентаў",
    cancel: "Адмяніць",
    selectACustomer: "Выберыце Кліент",
    invoiceSuccessfulPdfError:
      "Рахунак паспяхова створаны, але стварэнне PDF займае больш часу, чым звычайна. Калі ласка, праверце неўзабаве.",
    downloadingInvoice: "Загрузка рахунку -фактуры",
    downloadingReceipt: "Загрузка квітанцыі",
    whatsappReceiptError:
      "Адбылася памылка пры адпраўцы квітанцыі праз WhatsApp. Паўтарыце спробу.",
    receiptToWhatsapp: "Квітанцыя перанакіравана ў WhatsApp",
    thankYouForPatronage: "Дзякуй вам за заступніцтва",
    hereIsYourReceipt: "Вось ваша квітанцыя аб аплаце",
    errorSendingEmailReceipt:
      "Адбылася памылка пры адпраўцы квітанцыі па электроннай пошце. Паўтарыце спробу або звярніцеся ў службу падтрымкі",
    receiptSentToEmail:
      "Квітанцыя была адпраўлена на электронную пошту кліента",
    invoiceSentToEmail:
      "Рахунак -фактура быў адпраўлены на электронную пошту кліента",
    invoiceSuccessWhatsappError:
      "Рахунак -фактура створаны паспяхова, але пры адпраўцы ў WhatsApp адбылася памылка. Паўтарыце спробу ў спісе рахункаў -фактур",
    invoiceSuccessfulEmailError:
      "Рахунак паспяхова створаны, але пры адпраўцы па электроннай пошце адбылася памылка. Паўтарыце спробу са спісу рахункаў -фактур",
    invoiceSentToWhatsapp: "Рахунак -фактура перанакіраваны ў WhatsApp",
    hello: "добры дзень",
    pleaseDownloadInvoice: "Калі ласка, загрузіце рахунак -фактуру",
    pleaseDownloadReceipt: "Калі ласка, запампуйце квітанцыю",
    from: "ад",
    email: "Электронная пошта",
    upgrade: "Абнавіце",
    youAreOnFreePlan: "Вы знаходзіцеся ў бясплатным плане.",
    clickOn: "Націсніце на",
    yourPlanInFewSteps: " падпіску ў некалькі хуткіх крокаў.",
    to: "да",
    yourSubscriptionHasExpired:
      "Ваша падпіска скончылася, і ваш уліковы запіс зараз абмежаваны.",
    days: "дзён",
    yourSubscriptionExpiresIn: "Твая падпіска на Loystar заканчваецца ў",
    createAcount: "Стварыць уліковы запіс",
    signIn: "Увайсці",
    continue: "Працягвайце",
    enterOtp: "Увядзіце PIN -код OTP",
    enterValidOtp: "Увядзіце сапраўдны PIN -код",
    pin: "PIN -код",
    tokenSentToMail: "Токен быў адпраўлены на вашу электронную пошту",
    passwordResetSuccessful: "Скід пароля прайшоў паспяхова",
    somethingWentWrong: "Нешта пайшло не так!",
    resetPassword: "Скінуць пароль",
    iHaveResetCode: "У мяне ёсць код скіду пароля",
    pleaseEnterEmail: "Калі ласка, увядзіце свой адрас электроннай пошты",
    aTokenWillBeSentToEmail: "Токен будзе адпраўлены на вашу электронную пошту",
    enterEmail: "Увядзіце свой адрас электроннай пошты",
    sendinYourToken: "Адпраўка маркера ...",
    makeSureItMatchesPassword:
      "Пераканайцеся, што ён адпавядае вашаму новаму паролю",
    pleaseChooseNewPassword: "Выберыце новы пароль",
    chooseNewPassword: "Выберыце новы пароль",
    enterNewPassword: "Увядзіце новы пароль для пацверджання",
    enterTokenSent: "Увядзіце маркер, які быў адпраўлены на вашу пошту",
    resetToken: "Скінуць маркер",
    resettingPassword: "Скід пароля ...",
    signUp: "Зарэгістравацца",
    adminSignInWithEmail:
      " Адміністратар уваходзіць у сістэму з дапамогай электроннай пошты, а персанал - з імем карыстальніка.",
    pleaseEnterEmailOrUsername:
      "Калі ласка, увядзіце свой адрас электроннай пошты або імя карыстальніка",
    emailOrUsername: "Электронная пошта або імя карыстальніка",
    pleaseEnterPassword: "Калі ласка, увядзіце пароль",
    createAnAccount: "Стварыць уліковы запіс",
    forgotPassword: "Забыліся на пароль?",
    enterPhoneNumber: "Увядзіце нумар тэлефона",
    personalData: "Асабістыя дадзеныя",
    validateConfirmationCOde: "Праверце код пацверджання",
    pleaseEnterFirstName: "Калі ласка, увядзіце сваё імя",
    pleaseEnterPhoneNumber: "Калі ласка, увядзіце свой нумар тэлефона",
    pleaseEnterLastName: "Калі ласка, увядзіце сваё прозвішча",
    pleaseEnterBusinessName: "Калі ласка, увядзіце назву сваёй кампаніі",
    firstName: "Імя",
    lastName: "Прозвішча",
    businessName: "Назва фірмы",
    previous: "Папярэдні",
    next: "Далей",
    pleaseSelectBusinessCategory: "Выберыце катэгорыю бізнесу",
    pleaseEnterValidEmail:
      "Калі ласка, увядзіце сапраўдны адрас электроннай пошты",
    pleaseEnterPostCode: "Калі ласка, увядзіце паштовы індэкс",
    postCode: "Паштовы індэкс",
    phoneNumberInUse: "Гэты нумар тэлефона ўжо выкарыстоўваецца!",
    emailInUse: "Гэты ліст ужо выкарыстоўваецца!",
    foodAndDrinks: "Ежа і напоі",
    salonAndBeauty: "Салон і прыгажосць",
    fashionAndAccessories: "Мода і аксэсуары",
    gymAndFitness: "Трэнажорная зала і фітнес",
    travelAndHotel: "Падарожжы і гатэлі",
    homeAndGifts: "Дом і падарункі",
    washingAndCleaning: "Мыццё і ачыстка",
    gadgetsAndElectronics: "Гаджэты і электроніка",
    groceries: "Бакалейныя тавары",
    others: "Іншыя",
    submit: "Адправіць",
    accountCreatedSuccessful: "Ваш уліковы запіс паспяхова створаны.",
    pleaseEnterAddress: "Калі ласка, увядзіце свой адрас",
    addressLine1: "Адрасны радок 1",
    addressLine2: "Адрасны радок 2",
    choosePassword: "Выбраць пароль",
    passwordMustBe6Characters: "Пароль павінен складацца не менш за 6 знакаў.",
    howDidYouHearLoystar: "Як вы пачулі пра Loystar?",
    referralCode: "рэферальны код",
    byClickingTheButton: " Націскаючы кнопку ніжэй, вы згаджаецеся з",
    termsAndSevice: "Умовы",
    wereCreatingAccount: "Мы ствараем ваш уліковы запіс",
    proceed: "Працягвайце",
    verificationCodeMustBe6: "Код спраўджання павінен складацца з 6 лічбаў",
    pleaseEnter6DigitCode: "Калі ласка, увядзіце 6 -значны код",
    enterVerificationCode: "Увядзіце код спраўджання",
    resendToken: "Паўторна адправіць маркер",
    verify: "Праверыць",
    transactions: "Здзелкі",
    todaySales: "Сённяшнія продажы",
    salesHistory: "Гісторыя продажаў",
    supplyHistory: "Гісторыя паставак",
    new: "Новы",
    invoices: "Рахункі -фактуры",
    disbursements: "Выплаты",
    offlineSales: "Аўтаномны продаж",
    products: "Прадукты",
    customers: "Кліенты",
    multiLevelLoyalty: "Шматузроўневая лаяльнасць",
    loyaltyPrograms: "Праграмы лаяльнасці",
    members: "Члены",
    appStore: "App Store",
    orderMenu: "Меню заказаў",
    settings: "Налады",
    staffAndBranches: "Кадры і аддзяленні",
    myAccount: "Мой рахунак",
    switchToSellMode: "Пераключыцеся ў рэжым продажу",
    signOut: "Выйсці",
    getFreeSubscription: "Атрымайце бясплатную падпіску",
    onlyNumbersAllowed: "Дапускаюцца толькі лічбы",
    yourAccountMustBe10Digits: "нумар вашага рахунку павінен быць 10 -значным",
    yourBvnMustBe11: "ваш BVN павінен быць 11 -значным нумарам",
    pleaseSelectBank: "Калі ласка, абярыце свой банк",
    selectYourBank: "Выберыце свой банк",
    enterBankAccountNumber: "Увядзіце нумар банкаўскага рахунку",
    enterBvnNumber: "Увядзіце свой BVN",
    connectBankAccount: "Падключыце банкаўскі рахунак",
    passwordResetSuccessfulAndSignOut:
      "Ваш пароль быў паспяхова скінуты. Націсніце на кнопку Выйсці ніжэй, каб зноў увайсці",
    enterCurrentPassword: "Увядзіце бягучы пароль",
    pleaseEnterCurrentPassword: "Калі ласка, увядзіце бягучы пароль",
    phoneNumber: "Нумар тэлефона",
    sex: "Сэкс",
    dateOfBirth: "Дата нараджэння",
    state: "Дзяржава",
    country: "Краіна",
    loyaltyId: "Ідэнтыфікатар лаяльнасці",
    createdAt: "Створана ў",
    noOfTransactionsMade: "Колькасць здзейсненых транзакцый",
    yourDownloadWillStart: "Ваша загрузка пачнецца праз імгненне",
    exportCustomers: "Экспарт кліентаў",
    youhaveSuccessfullyToppedUp: "Вы паспяхова папоўнілі свае SMS -блокі.",
    youNowHave: "У вас цяпер ёсць",
    smsUnits: "Адзінкі SMS",
    enterNumberOfSmsUnits:
      "Увядзіце колькасць SMS -адзінак, якія вы збіраецеся набыць",
    pleaseEnterANumericValue: "Калі ласка, увядзіце лікавае значэнне",
    pay: "Плаціць",
    accountEditedSuccessfully: "Ваш уліковы запіс паспяхова адрэдагаваны.",
    youAeCurrentlyOn: "Вы зараз уключаны",
    plan: "План",
    userData: "Дадзеныя карыстальніка",
    businessData: "БІЗНЕС -ДАННЫЯ",
    businessCategory: "Дзелавыя катэгорыі",
    pleaseSelectCurrency: "Калі ласка, абярыце валюту",
    selectYourCurrency: "Выберыце валюту",
    purchaseMoreSmsUnits:
      "Набудзьце больш SMS -блокаў, выкарыстоўваючы форму ніжэй",
    youHave: "У цябе ёсць",
    atLeast4SmsUnitsRrequired:
      "Для праверкі патрабуецца не менш за 4 смс -адзінак, папоўніце!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Калі ласка, праверце свой банкаўскі рахунак на адпаведнасць патрабаванням "Ведайце свайго кліента" (KYC). Гэта дазволіць вам збіраць плацяжы праз USSD або імгненны пераклад, атрымліваць заказы ад кліентаў і апрацоўваць разлік транзакцый. Дастасавальна толькі да нігерыйскіх купцоў. Калі ласка, націсніце кнопку ніжэй, каб пачаць.',
    reConnectBankAccount: "Паўторна падключыце банкаўскі рахунак",
    accountName: "Імя ўліковага запісу",
    accountNumber: "Нумар акаўнта",
    bankName: "Назва банка",
    verified: "Праверана",
    accountDetails: "Рэквізіты рахунку",
    kycBankAccount: "KYC",
    createTier: "Стварэнне ўзроўню",
    fileUploadSuccessful: "файл паспяхова загружаны",
    fileUploadFailed: "няўдалая загрузка файла",
    createLoyaltyProgram: "Стварыце праграму лаяльнасці",
    createLoyalty: "Стварыце лаяльнасць",
    name: "Імя",
    loyaltyArtwork: "Мастацтва лаяльнасці",
    clickToUpload: "Націсніце, каб загрузіць",
    amountToPointsRatio: "Суадносіны сумы да ачкоў",
    points: "Ачкі",
    recommendedAmountToPointRatio:
      "Рэкамендуецца: ад 1 да 1 бала. г.зн. за кожную выдаткаваную 1 Найру вашы кліенты атрымліваюць 1 бал",
    pleaseTypeIn: "Калі ласка, увядзіце",
    toDeleteLoyalty: "выдаліць гэтую лаяльнасць",
    deleteLoyalty: "Выдаліць лаяльнасць",
    toConfirm: "пацвердзіць",
    edit: "Рэдагаваць",
    pointsAwardedSuccessfully: "Ачкі набраны паспяхова.",
    enterPointValueToAward:
      "Увядзіце кошт бала, які вы хочаце ўзнагародзіць кліентаў",
    award: "Прэмія",
    awardPointValuesToCustomer: "Прызначэнне балаў кліенту",
    enrollMembersToLoyalty: "Залічыце членаў на лаяльнасць",
    awardPoints: "Ацэначныя ачкі",
    enroll: "Запісацца",
    home: "Дадому",
    loyalty: "Лаяльнасць",
    enrollCustomers: "Запіс кліентаў",
    selected: "Выбрана",
    customer: "Кліент",
    loyaltyActivationSuccessful: "Актывацыя лаяльнасці паспяхова.",
    loyaltyDeactivationSuccessful: "Адключэнне лаяльнасці паспяхова.",
    viewTier: "Прагляд ўзроўню",
    deactivate: "Дэактываваць",
    activate: "Актываваць",
    actions: "Дзеянні",
    nameOfLoyalty: "Імя лаяльнасці",
    loyaltyStatus: "Статус лаяльнасці",
    numberOfTiers: "Колькасць узроўняў",
    createdOn: "Створана ў",
    createATier: "Стварыце ўзровень",
    stopCreatingTierConfirmation: "Вы хочаце спыніць стварэнне ўзроўню?",
    stopEditingTierConfirmation:
      "Вы хочаце спыніць рэдагаванне гэтага ўзроўню?",
    nameOfTier: "Назва ўзроўню",
    minimumSpendingTarget: "Мэтавы мінімальны расход",
    maximumSpendingTarget: "Мэтавы максімальны расход",
    minimumSpendingTargetRequired:
      "патрабуецца мінімальная мэтавая выдаткаванасць",
    maximumSpendingTargetRequired:
      "патрабуецца максімальная мэтавая выдаткаванасць",
    rewardSponsor: "Спонсар узнагароды",
    pleaseChooseARewardSponsor: "Калі ласка, абярыце спонсара ўзнагароджання",
    self: "Самастойнасць",
    partner: "Партнёр",
    rewardPartner: "Узнагароджаны партнёр",
    pleaseSelectRewardPartner: "Выберыце партнёра па ўзнагароджанні",
    rewards: "Узнагароды",
    pleaseSelectAReward: "Выберыце ўзнагароду",
    instructionsOnRedeemingReward:
      "Інструкцыі аб тым, як кліенту трэба выкарыстоўваць узнагароду",
    pleaseFillInThisField: "Калі ласка, запоўніце гэтае поле!",
    toDeleteThisTier: " каб выдаліць гэты ўзровень",
    deleteTier: "Выдаліць ўзровень",
    viewMembers: "Прагляд членаў",
    membersEnrolled: "Зарэгістраваны ўдзельнікі",
    instruction: "Інструкцыя",
    membersIn: "Члены ў",
    availableTiersInLoyalty: "Даступныя ўзроўні ў праграме лаяльнасці",
    tiers: "Ярусы",
    totalTier: "АГУЛЬНЫ ЯРЫК",
    availableLoyaltyProgramme: "Даступная праграма лаяльнасці",
    totalLoyalties: "УСЯГО ЛАЯЛЬНАСЦІ",
    memberDetails: "Падрабязнасці ўдзельніка",
    nameOfCustomer: "Імя кліента",
    address: "Адрас",
    allEnrolledMembers: "Усе зарэгістраваныя члены",
    thisIsToWishYouHappyBirthday:
      "Гэта віншаванне з днём нараджэння і шчаслівага жыцця. Дзякуй за ўсё, што ты ёсць для Loyster. Са святам!",
    inputAnOfferPlease: "Калі ласка, увядзіце прапанову",
    pleaseSelectTheInsertPoint:
      "Калі ласка, абярыце кропку ўстаўкі ў паведамленні і націсніце яшчэ раз",
    birthdayOfferAndMessage: "Прапанова і паведамленне да дня нараджэння",
    birthdayOffer: "Прапанова на дзень нараджэння",
    birthdayMessage: "Паведамленне пра дзень нараджэння",
    noOfferFound: "Прапанова не знойдзена",
    settingABirthdayOffer:
      "Усталяванне прапановы на дзень нараджэння дазваляе кліентам атрымліваць гэтую прапанову праз SMS у дзень нараджэння",
    createOffer: "Стварыць прапанову",
    whatIsTheOffer: "Якая прапанова?",
    editMessage: "Рэдагаваць паведамленне",
    insert: "Уставіць",
    theNameOfCustomerInserted: "Тут будзе ўстаўлена імя кліента",
    theBirtdayOfferInserted: "Сюды будзе ўстаўлена прапанова дня нараджэння",
    youSuccessfullyAddedNewBranch: "Вы паспяхова дадалі новую галіну!",
    addNewBranch: "Дадаць новую галіну",
    addBranch: "Дадаць галінку",
    additionalBranchWillIncur: "Патрабуецца дадатковае аддзяленне",
    perBranch: "за аддзяленне",
    ecommerceBranchCosts: "Выдаткі філіяла электроннай камерцыі",
    pleaseEnterBranchName: "Калі ласка, увядзіце назву філіяла",
    pleaseEnterBranchAddress1:
      "Калі ласка, увядзіце адрасны радок аддзялення 1",
    enterBranchAddress1: "Увядзіце адрасны радок аддзялення 1",
    enterBranchAddress2: "Увядзіце адрасны радок аддзялення 1",
    pleaseEnterBranchAddress2: "Калі ласка, увядзіце адрасны радок філіяла 2",
    enterBranchName: "Увядзіце назву філіяла",
    successfullyAddedStaff: "Вы паспяхова дадалі новых супрацоўнікаў!",
    addNewStaff: "Дадаць новы персанал",
    addStaff: "Дадаць персанал",
    additionalStaffWillIncur: "Патрабуецца дадатковы персанал",
    perStaff: "на персанал.",
    pleaseEnterStaffEmail:
      "Калі ласка, увядзіце электронную пошту супрацоўнікаў",
    pleaseEnterStaffUsername:
      "Калі ласка, увядзіце імя карыстальніка супрацоўнікаў",
    pleaseEnterStaffPassword: "Калі ласка, увядзіце пароль супрацоўнікаў",
    pleaseSelectStaffRole: "Калі ласка, абярыце ролю супрацоўнікаў",
    selectStaffRole: "Выберыце ролю супрацоўнікаў",
    enterStaffEmail: "Увядзіце электронную пошту супрацоўнікаў",
    enterStaffUsername: "Увядзіце імя карыстальніка персаналу",
    enterStaffPassword: "Увядзіце пароль супрацоўнікаў",
    spacesNotAllowedInUsername: "у імені карыстальніка не дапускаюцца прабелы",
    admin: "Адмін",
    pleaseSelectBusinessToAttachStaff:
      "Калі ласка, абярыце бізнес, каб далучыць супрацоўнікаў",
    searchForBranchToAttachStaff:
      "Знайдзіце філіял, каб далучыць супрацоўнікаў",
    username: "Імя карыстальніка",
    role: "Роля",
    areYouSureToDeleteThis: "Вы ўпэўненыя, што выдалілі гэта",
    branches: "Галіны",
    upgradeYourPlan: "Абнавіце свой план.",
    add: "ДАДАЦЬ",
    addNew: "Дадаць Новае",
    customerWithEmailAlreadyExists:
      "Кліент з электроннай поштай/нумарам тэлефона ўжо існуе!",
    successfullyAddedNewCustomer: "Вы паспяхова дадалі новага кліента!",
    addCustomer: "Дадаць кліента",
    pleaseEnterCustomerFirstName: "Калі ласка, увядзіце імя кліента",
    pleaseEnterCustomerLastName: "Калі ласка, увядзіце прозвішча кліента",
    pleaseEnterCustomerPhoneNumber:
      "Калі ласка, увядзіце нумар тэлефона кліента",
    pleaseEnterCustomerEmail:
      "Калі ласка, увядзіце адрас электроннай пошты кліента",
    pleaseEnterCustomerAddressLine:
      "Калі ласка, увядзіце адрасны радок кліента",
    pleaseEnterCustomerOtherAddress: "Калі ласка, увядзіце іншы адрас кліента",
    pleaseSelectCustomerGender: "Выберыце пол кліента",
    gender: "Пол",
    male: "Мужчынскі",
    female: "Жаночы",
    bank: "Банк",
    selectBank: "Выберыце банк",
    stateOrRegionOrProvince: "Штат/рэгіён/правінцыя",
    customerNotes: "Заўвагі кліентаў",
    sendSms: "Адпраўка смс",
    editCustomer: "Рэдагаваць кліента",
    redeemReward: "Выкупіць узнагароду",
    issueLoyaltyCard: "Выдайце карту лаяльнасці",
    deleteCustomer: "Выдаліць кліента",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Вы паспяхова прызначылі ідэнтыфікатар лаяльнасці",
    noMembershipIdAvailable:
      "Няма ідэнтыфікатараў сяброўства. Калі ласка, звяжыцеся з намі па адрасе hello@loystar.co",
    sendEmail: "Адправіць электронную пошту",
    membershipPoints: "Пункты сяброўства",
    customerDetails: "Інфармацыя аб кліенце",
    close: "Зачыніць",
    loyaltyBalance: "Баланс лаяльнасці",
    pointsBalance: "Баланс балаў",
    starBalance: "Зорны баланс",
    requiredPoints: "Абавязковыя ачкі",
    requiredStars: "Абавязковыя зоркі",
    reddemCode: "Актываваць код",
    toDeleteThisCustomer: "выдаліць гэтага кліента",
    customerHasBeenDeletedSuccessfully: "Кліент паспяхова выдалены!",
    customerWithPhoneAlreadyExists: "Кліент з нумарам тэлефона ўжо існуе",
    customerWasSuccessfullyEdited: "Кліент паспяхова рэдагаваны",
    anErrorOccured: "Адбылася памылка",
    phoneNumberIncludeCountry: "Нумар тэлефона (уключае код краіны)",
    yourFileQueuedForUpload:
      "Ваш файл пастаўлены ў чаргу для запампоўкі. Абнавіце старонку праз некалькі секунд.",
    thereWasAnErrorPerformingOperation:
      "Адбылася памылка пры выкананні аперацыі!",
    pleaseSelectFile: "Калі ласка, абярыце файл!",
    oopsOnlyCsvFilesAllowed:
      "Ой! Дазваляюцца толькі файлы CSV. Калі ласка, загрузіце файл .csv.",
    fileShouldBeSmallerThan5Mb:
      "Файл павінен быць меншым за 5 МБ. Калі ў вас ёсць вялікі файл, калі ласка, напішыце support@loystar.co. Дзякуй",
    customerFirstNameIsRequired: "імя кліента патрабуецца ў радку",
    customerPhoneNumberIsRequired: "нумар тэлефона кліента патрабуецца ў радку",
    importCustomers: "Імпарт кліентаў",
    upload: "Загрузіць",
    clickIconToDownloadCsv:
      "Пстрыкніце гэты значок, каб загрузіць шаблон файла CSV.",
    getGoogleSheetVersion: "Атрымаць версію табліцы Google",
    clickOrDragFileToUpload:
      "Націсніце або перацягніце файл у гэтую вобласць, каб загрузіць",
    missingOrInvalidColumnHeader:
      "Адсутнічае ці няправільны загаловак слупка. Выконвайце фармат шаблону. Дзякуй.",
    youHaveImported: "Вы імпартавалі",
    youSuccessfullyRedeemedReward: "Вы паспяхова выкупілі ўзнагароду!",
    sixDigitCode: "Шасцізначны код",
    pleaseEnterCustomerRewardCode:
      "Калі ласка, увядзіце код узнагароды кліента",
    enterRewardCode:
      "Увядзіце код узнагароды. (Код узнагароды адчувальны да рэгістра)",
    selectLoyaltyProgram: "Выберыце праграму лаяльнасці",
    stamps: "Маркі",
    smsUnitsLow: "Нізкія адзінкі SMS",
    pleaseTopUpSmsUnits: "Калі ласка, папоўніце SMS -блокі",
    smsSuccessfullySentForDelivery: "SMS паспяхова адпраўлена на дастаўку!",
    sendSmsTo: "Адправіць SMS на",
    allCustomers: "Усе кліенты",
    unitsAvailable: "Даступныя адзінкі",
    pleaseTypeInTheMessage: "Калі ласка, увядзіце паведамленне",
    message: "Паведамленне",
    charactersRemaining: "засталося персанажаў",
    avoidUseOfSpecialCharacters:
      "Пазбягайце выкарыстання спецыяльных знакаў і смайлікаў для захавання адзінак SMS.",
    note: "Нататка",
    errorFetchingCustomersMultilevelDetail:
      "Памылка атрымання падрабязных звестак кліентаў",
    search: "Пошук",
    reset: "Скінуць",
    importCustomer: "Імпарт кліента",
    addNewCustomer: "Дадаць новага кліента",
    sendSmsBroadcast: "Адправіць SMS -трансляцыю",
    totalCustomers: "УСЯГО КЛІЕНТАЎ",
    disbursementDetails: "Падрабязнасці выплаты",
    paidBy: "Аплачана",
    disbursed: "Выплачана",
    bankAccountName: "Назва банкаўскага рахунку",
    bankAccountNumber: "Нумар банкаўскага рахунку",
    transferInitiated: "Пачатак перадачы",
    transferCompleted: "Перадача завершана",
    pleaseEnterAValid: "Калі ласка, увядзіце сапраўдны",
    begin: "пачаць",
    end: "канец",
    date: "дата",
    paymentDate: "Дата аплаты",
    selectDisbursementDuration: "Выберыце Працягласць выплаты",
    totalSettled: "Усяго разлічана",
    totalUnsettled: "Усяго неўрэгулявана",
    toDeleteThisSale: "выдаліць гэты продаж",
    draftSaleDeletedSuccessfully: "Чарнавік продажу паспяхова выдалены!",
    deleteSale: "Выдаліць распродаж",
    pleaseTypeInYourTotal:
      "Калі ласка, увядзіце агульную суму, каб пацвердзіць выдаленне",
    billDetails: "Падрабязнасці законапраекта",
    printBill: "Раздрукаваць Біл",
    servedBy: "Абслугоўваецца",
    total: "Усяго",
    createdDate: "Дата стварэння",
    createdTime: "Створаны час",
    status: "Стан",
    loginSuccessful: "Уваход паспяховы",
    pleaseWaitWhileWeConnectAccount:
      "Пачакайце, пакуль мы падключым ваш уліковы запіс",
    connectionFailedTryAgain:
      "Падключэнне не ўдалося. Калі ласка, паўтарыце спробу.",
    connectionSuccessful: "Падлучэнне паспяхова",
    viewDetails: "Паглядзець падрабязнасці",
    enable: "Уключыць",
    free: "Бясплатна",
    cost: "Кошт",
    visitWebsite: "Наведайце вэб -сайт",
    pleaseUpgradeYourPlanToPro:
      "Каб уключыць гэта дадатак, абнавіце свой план да Pro Plus",
    connectYourBankAccount:
      "Падключыце свой банкаўскі рахунак, каб мець магчымасць атрымліваць плацяжы.",
    disable: "Адключыць",
    enableApp: "Уключыць прыкладанне",
    addNewProductToInvoice: "Дадаць новы прадукт у рахунак -фактуру",
    toDeleteThisInvoice: "выдаліць гэты рахунак -фактуру",
    invoiceDeletedSuccessfully: "Рахунак паспяхова выдалены!",
    deleteInvoice: "Выдаліць рахунак -фактуру",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Вы паспяхова дадалі паведамленне аб аплаце рахунку -фактуры!",
    pleaseEnterPaymentInstructions: "Калі ласка, увядзіце інструкцыю па аплаце",
    invoiceId: "Ідэнтыфікатар рахунку -фактуры",
    paidDate: "Дата аплаты",
    reference: "Даведка",
    productAdded: "Прадукт дададзены",
    productOutOfStock: "Прадукту няма ў наяўнасці. Калі ласка, зрабіце запас.",
    totalInvoices: "АГУЛЬНЫЯ ФАКТУРЫ",
    totalPaidInvoices: "УСЕ АПЛАТНЫЯ РАХУНКЫ",
    totalUnpaidInvoices: "АГУЛЬНЫЯ НЕАПЛАТНЫЯ РАХУНКЫ",
    loyaltyProgramDeleted: "Праграма лаяльнасці выдалена",
    thisLoyaltyProgramDeletedSuccessfully:
      "Гэтая праграма лаяльнасці паспяхова выдалена",
    thisLoyaltyProgramEditedSuccessfully:
      "Гэтая праграма лаяльнасці паспяхова адрэдагаваная",
    loyaltyProgramAddedSuccessfully: "Праграма лаяльнасці паспяхова дададзена",
    loyaltyProgramEdited: "Праграма лаяльнасці адрэдагаваная",
    loyaltyProgramAdded: "Дададзена праграма лаяльнасці",
    loyaltyDetails: "Падрабязнасці лаяльнасці",
    doYouWantToCloseDialog: "Вы хочаце закрыць гэты дыялог?",
    pleaseEnterLoyaltyName: "Калі ласка, увядзіце назву сваёй лаяльнасці",
    programType: "Тып праграмы",
    pleaseSelectProgramType: "Выберыце тып праграмы",
    simplePoints: "Простыя ачкі",
    stampsProgram: "Праграма маркі",
    threshold: "Парог",
    pleaseEnterLoyaltyThreshold: "Калі ласка, увядзіце парог лаяльнасці",
    reward: "Узнагарода",
    pleaseEnterLoyaltyReward: "Калі ласка, увядзіце ўзнагароду за лаяльнасць",
    totalUserPoints: "Агульная колькасць ачкоў карыстальніка",
    totalUserStamps: "Агульная колькасць карыстацкіх марак",
    spendingTarget: "Мэта расходаў",
    spendingTargetHint1:
      "Мэта расходаў - гэта тое, колькі кліент павінен выдаткаваць, каб зарабіць узнагароду. 1 кошт валюты = 1 бал.",
    spendingTargetHint2:
      "Мэтавая марка - гэта тое, колькі марак кліент павінен сабраць, каб зарабіць узнагароду. напрыклад, 5",
    addNewLoyaltyProgram: "Дадаць новую праграму лаяльнасці",
    addLoyaltyProgram: "Дадаць праграму лаяльнасці",
    loyaltyProgramType: "Тып праграмы лаяльнасці",
    pleaseSelectLoyaltyProgramType: "Выберыце тып праграмы лаяльнасці",
    nameOfProgram: "Назва праграмы",
    pleaseEnterProgramName: "Калі ласка, увядзіце назву праграмы",
    whatIsTheReward: "Якая ўзнагарода?",
    egNextPurchaseIsFree: "Напрыклад, Наступная купля бясплатная",
    customerName: "Імя кліента",
    guestCustomer: "Гасцявы кліент",
    orderConfirmedSuccessfully: "Заказ паспяхова пацверджаны",
    orderCancelledSuccessfully: "Заказ паспяхова адменены",
    confirmOrder: "Пацвердзіце заказ",
    cancelOrder: "Адмяніць заказ",
    allOrders: "Усе заказы",
    totalOrders: "УСЕ ЗАКАЗЫ",
    doYouWantToAcceptOrder: "Вы хочаце прыняць гэты заказ?",
    doYouWantToCancelOrder: "Вы хочаце адмяніць гэты заказ?",
    orderDetails: "Падрабязнасці замовы",
    orderCreatedAt: "Заказ створаны ў",
    supplier: "Пастаўшчык",
    productName: "Назва прадукту",
    quantity: "Колькасць",
    unitPrice: "Цана за адзінку",
    receivedBy: "Атрымаў",
    reportFrom: "Справаздача ад",
    totalSupplies: "Усяго паставак",
    allRightsReserved: "Усе правы ахоўваюцца",
    toDeleteThisTransaction: "каб выдаліць гэтую транзакцыю",
    transactionDeletedSuccessfully:
      "Здзелка паспяхова выдалена. Запас вернуты ў інвентар.",
    deleteTransaction: "Выдаліць транзакцыю",
    transactionDetails: "Падрабязнасці здзелкі",
    printReceipt: "Друк квітанцыі",
    channel: "Канал",
    discount: "Зніжка",
    profit: "Прыбытак",
    discountedSales: "Продаж са зніжкай",
    errorFetchingRecord: "Памылка атрымання запісу",
    exportTransactions: "Экспартныя здзелкі",
    errorFetchingSalesRecord: "Памылка атрымання запісу продажаў для экспарту.",
    totalSellingPrice: "Агульная цана продажу",
    totalCostPrice: "Агульная кошт",
    appliedDiscount: "Прыкладная зніжка",
    noOfItems: "Колькасць тавараў",
    sales: "Продаж",
    export: "Экспарт",
    totalProfit: "Агульны прыбытак",
    totalBalanceInPeriod: "Агульны баланс за перыяд",
    allTimeSales: "Продаж за ўвесь час",
    records: "Запісы",
    todaysSales: "Сённяшнія продажы",
    transaction: "здзелка",
    youHaveExceededTotalNumberOfProducts:
      "Вы перавысілі агульную колькасць прадуктаў, дазволеных у вашым плане. Абнавіце свой план, каб атрымаць больш высокі ліміт.",
    youNeedToHaveLoyaltyProgram:
      "Каб выкарыстоўваць гэтую функцыю, неабходна мець праграму лаяльнасці!",
    price: "Цана",
    category: "Катэгорыя",
    stockTracking: "Адсочванне акцый",
    stockCount: "Колькасць акцый",
    taxed: "Падаткам",
    originalPrice: "Арыгінальная цана",
    costPrice: "Сабекошт",
    unit: "Адзінка",
    productImage: "Вобраз прадукту",
    taxRate: "Стаўка падатку",
    taxType: "Тып падатку",
    trackInventory: "Адсочванне інвентарызацыі",
    variants: "Варыянты",
    hasVariants: "Мае варыянты",
    importProduct: "Імпарт прадукту",
    exportProducts: "Экспарт прадукцыі",
    addNewProduct: "Дадаць новы прадукт",
    viewCategory: "Прагляд катэгорыі",
    viewSuppliers: "Паглядзець пастаўшчыкоў",
    receiveInventory: "Атрымаць інвентар",
    printAllProductsTag: "Раздрукаваць усе прадукты",
    deleteAll: "Выдаліць усе",
    totalProducts: "Агульная прадукцыя",
    youveSuccessfullyAddedANewCategory: "Вы паспяхова дадалі новую катэгорыю",
    addNewCategory: "Дадаць новую катэгорыю",
    addCategory: "Дадаць катэгорыю",
    categoryName: "Назва катэгорыі",
    pleaseEnterCategoryName: "Калі ласка, увядзіце назву катэгорыі",
    stampsTarget: "Маркі Мэта",
    sendInventory: "Адправіць інвентар",
    productDetails: "Падрабязнасці прадукту",
    youveSuccessfullyEditedThisCategory:
      "Вы паспяхова рэдагавалі гэтую катэгорыю",
    update: "Абнаўленне",
    categoryList: "Спіс катэгорый",
    categories: "Катэгорыі",
    enterQuantityToUpdate: "Увядзіце колькасць для абнаўлення",
    inventorySentSuccessfully: "Інвентар быў паспяхова адпраўлены!",
    updateInventory: "Абнавіць інвентар",
    currentQuantity: "Бягучая колькасць",
    pleaseEnterQuantityToAdd:
      "Калі ласка, увядзіце колькасць, якое вы хочаце дадаць",
    pleaseSelectABranch: "Калі ласка, абярыце філіял",
    searchForBranch: "Пошук філіяла",
    youCantSendMoreThanStock:
      "Вы не можаце адправіць больш, чым у вас ёсць у наяўнасці",
    send: "Адправіць",
    pleaseEnterQuantityToSend:
      "Калі ласка, увядзіце колькасць, якое вы хочаце адправіць",
    productNameIsRequiredOnRow: "назва радка патрабуецца",
    productCategoryIsRequiredOnRow: "катэгорыя прадукту неабходная ў радку",
    productPriceIsRequiredOnRow: "цана прадукту патрабуецца ў радку",
    productUnitIsRequiredOnRow: "адзінка прадукту патрабуецца ў радку",
    productQuantityIsRequiredOnRow: "колькасць радка патрабуецца",
    productVariantsRequireTracking: "варыянты прадуктаў патрабуюць адсочвання!",
    pleaseAddVariantClickButton:
      "Калі ласка, дадайце варыянт, націснуўшы кнопку дадаць!",
    totalVariantsMoreThanSelectedQuantity:
      "Усяго варыянтаў больш, чым абраная колькасць прадукту, калі ласка, знізіце колькасць варыянтаў",
    productEditedSuccessfully: "Прадукт паспяхова адрэдагаваны!",
    fileTooLargeLessThan4Mb:
      "Памер файла занадта вялікі! Памер файла павінен быць менш за 4 МБ.",
    suchVariantAlreadyExist: "Такі варыянт ужо існуе",
    addVariants: "Дадайце варыянты",
    editProduct: "Рэдагаваць прадукт",
    pleaseEnterProductName: "Калі ласка, увядзіце назву прадукту",
    pleaseEnterProductPrice: "Калі ласка, увядзіце цану прадукту",
    pleaseEnterProductOriginalPrice:
      "Калі ласка, увядзіце арыгінальную цану прадукту",
    productDescription: "Апісанне Прадукта",
    selectProductCategory: "Выберыце катэгорыю прадукту",
    pleaseSelectProductCategory: "Калі ласка, абярыце катэгорыю прадукту",
    productCostPrice: "Кошт прадукту",
    productSellingPrice: "Кошт продажу прадукту",
    productOriginalPrice: "Арыгінальная цана прадукту",
    maxFileSizeAllowedIs4Mb: "Макс. Дазволены памер файла - 4 Мб",
    productsWithPicturesArePublished:
      "Прадукты з выявамі публікуюцца на Discover для атрымання заказаў",
    shouldThisProductBeTracked: "Ці варта адсочваць гэты прадукт?",
    pleaseSelectStockUnit: "Калі ласка, абярыце адзінку акцый",
    stockUnit: "Запас адзінкі",
    bag: "СУМКА",
    bottle: "БУТЫЛКА",
    bunch: "ГРУП",
    can: "МОЖА",
    carton: "КАРТОН",
    crate: "СКЛАД",
    cup: "КУБКА",
    dozen: "ДЗЕСЯЦЬ",
    gigabytes: "GIGABYTES",
    gram: "ГРАМ",
    kilogram: "КІЛАГРАМА",
    litre: "ЛІТАР",
    pack: "УПАКОВКА",
    pair: "ПАРЫ",
    pieces: "КОМПЛЕКТЫ",
    plate: "ПЛІТА",
    tonne: "ТОННА (MT)",
    uNIT: "АДЗІН",
    yard: "ДВОР",
    pleaseEnterProductQuantity: "Калі ласка, увядзіце колькасць прадукту",
    productQuantity: "Колькасць прадукту",
    isThisProductTaxed: "Ці абкладаецца гэты прадукт падаткам?",
    selectTaxType: "Выберыце Тып падатку",
    pleaseSelectTaxType: "Калі ласка, абярыце Тып падатку",
    progressive: "Прагрэсіўная",
    proportional: "Прапарцыйна",
    pleaseEnterProductTaxRate: "Калі ласка, увядзіце стаўку падатку на прадукт",
    doesProductHaveVariants: "Ці ёсць у гэтага прадукта варыянты?",
    type: "Тып",
    value: "Каштоўнасць",
    pleaseEnterVariantType: "Калі ласка, увядзіце тып варыянту",
    pleaseEnterVariantValue: "Калі ласка, увядзіце значэнне варыянту",
    pleaseEnterVariantPrice: "Калі ласка, увядзіце цану варыянту",
    pleaseEnterVariantQuantity: "Калі ласка, увядзіце колькасць варыянту",
    productDeletedSuccessfully: "Прадукт паспяхова выдалены!",
    categoryDeletedSuccessfully: "Катэгорыя паспяхова выдалена!",
    toDeleteThisProduct: "выдаліць гэты прадукт",
    invalidProductQuantity: "Несапраўдная колькасць прадукту",
    quantityAddedIsOverStock:
      "Колькасць, якую вы дадаеце, перавышае тое, што ёсць у вас у наяўнасці.",
    itemInventoryNotTracked: "Інвентарызацыя тавараў не адсочваецца",
    addBulkQuantity: "Дадаць масавы аб'ём",
    enterBulkQuantity: "Увядзіце масавы аб'ём",
    pleaseEnterBulkQuantity: "Калі ласка, увядзіце масавы аб'ём",
    youveSuccessfullyAddedANewProduct: "Вы паспяхова дадалі новы прадукт!",
    pleaseEnterProductSellingPrice:
      "Калі ласка, увядзіце цану продажу прадукту",
    doYouWantToTrackProductStock: "Вы хочаце адсочваць склад прадуктаў?",
    sellingPrice: "Адпускная цана",
    setProductExpiryReminder:
      "Усталяваць напамін аб заканчэнні тэрміну дзеяння прадукту",
    productExpiryDate: "Тэрмін прыдатнасці прадукту",
    startRemindingFrom: "Пачаць нагадваць з",
    productSuppliesAddedSuccessfully: "Вы паспяхова дадалі пастаўкі прадукту.",
    addProductSupplies: "Дадайце тавары",
    pleaseSelectSupplier: "Выберыце пастаўшчыка",
    nameOfProduct: "Назва прадукту",
    pleaseSelectProduct: "Калі ласка, абярыце прадукт",
    productVariant: "Варыянт прадукту",
    pleaseSelectAVariant: "Выберыце варыянт",
    pleaseEnterUnitPrice: "Калі ласка, увядзіце цану за адзінку",
    businessBranch: "Бізнес -філіял",
    pleaseSelectBranch: "Калі ласка, абярыце філіял",
    youveSuccessfullyAddedANewSupplier:
      "Вы паспяхова дадалі новага пастаўшчыка",
    addSupplier: "Дадаць пастаўшчыка",
    pleaseEnterSupplierEmail:
      "Калі ласка, увядзіце адрас электроннай пошты пастаўшчыка",
    pleaseAddADescription: "Калі ласка, дадайце апісанне",
    anErrorOccuredProductsDeleted:
      "Адбылася памылка падчас выканання аперацыі. Калі ласка, звярніце ўвагу, што ў гэтым працэсе магчыма было выдалена некалькі прадуктаў",
    bulkDelete: "Масавае выдаленне",
    youAreAboutToDelete: "Вы збіраецеся выдаліць",
    product: "Прадукт",
    isDueToRedeem: "падлягае выкупу",
    aReward: "узнагарода",
    pleaseSelectCustomerToReeemReward:
      "Выберыце кліента, каб пагасіць узнагароду.",
    youHaveNoLoyaltyProgramRunning: "У вас няма актыўнай праграмы лаяльнасці",
    customerHhasNoPointsInLoyaltyProgram:
      "Кліент не мае ачкоў у гэтай праграме лаяльнасці",
    proceedWithPayment: "Працягнуць аплату?",
    youAreAboutToPayForTransactionUsingPoints:
      "Вы збіраецеся плаціць за транзакцыю з дапамогай ачкоў.",
    customerHas: "Кліент мае",
    worth: "варта",
    andIsNotSufficientToPayForTransaction:
      "і гэтага недастаткова для аплаты гэтай транзакцыі. Яны хацелі б дадаць баланс з дапамогай іншага спосабу аплаты?",
    addCustomerLoyalty: "Дадайце лаяльнасць кліентаў",
    pleaseAddCustomerFirst: "Калі ласка, дадайце або абярыце спачатку кліента.",
    pleaseWaitWhileWeGetReady: "Пачакайце, пакуль мы падрыхтуемся",
    lowStock: "Нізкі запас",
    pleaseEnterAmountTendered: "Калі ласка, увядзіце прапанаваную суму",
    areYouSureToBookSaleOffline:
      "Вы ўпэўненыя, што хочаце замовіць распродаж у аўтаномным рэжыме?",
    saleWouldBeBookedAutomatically:
      "Продаж будзе запісвацца аўтаматычна пры ўключэнні Інтэрнэту",
    offlineSalesBookingCancelled: "Браніраванне пазасеткавых продажаў адменена",
    covid19Message:
      "COVID19: Памыйце рукі з мылам або абеззаражвайце, каб спыніць распаўсюджванне. Заўсёды заставайся ў бяспецы",
    saleSuccessfullyRecorded: "Продаж паспяхова запісаны!",
    sendReceiptToEmail: "Адпраўце квітанцыю на электронную пошту",
    sendThankYouSms: "Адпраўце дзякуй SMS",
    sendShippingDetails: "Адпраўце падрабязную інфармацыю аб дастаўцы",
    addLoyalty: "Дадайце лаяльнасць",
    searchCustomerNameOrNumber: "Шукаць імя або нумар кліента",
    clickTheSearchCustomerBox:
      "Пстрыкніце поле пошуку кліента і сканіруйце картку",
    enterProductPrice: "Увядзіце цану прадукту",
    enterPriceFor: "Увядзіце цану для",
    searchForProduct: "Пошук прадукту",
    all: "Усе",
    backToDashboard: "Назад да прыборнай панэлі",
    viewDraftSales: "Прагляд праектаў продажаў",
    variantSelected: "абраны варыянт",
    variant: "варыянт",
    thePreviousVariantSelectionNotAvailable:
      "Выбар папярэдняга варыянту недаступны для новага варыянту, абранага ў залежнасці ад кошту, калі ласка, змяніце свой выбар.",
    pleaseSupplyPositiveQuantityNumber:
      "Калі ласка, дайце станоўчы нумар колькасці",
    lowStockFor: "Нізкі запас для",
    clearVariants: "Выразныя варыянты",
    pleaseEnterQuantity: "Калі ласка, увядзіце колькасць",
    picture: "Малюнак",
    redemptionToken: "Жэтон выкупу",
    token: "Жэтон",
    totalSpent: "Агульны выдатк",
    confirmPayment: "Пацвердзіце аплату",
    hasPaymentBeenMade: "Ці аплата апрацавана паспяхова?",
    enterTransactionReference:
      "Увядзіце спасылку на транзакцыю, з якой вы аплацілі",
    pleaseSelectACustomer: "Калі ласка, абярыце кліента!",
    areYouSureToApplyDiscount: "Вы ўпэўненыя, што хочаце прымяніць зніжку?",
    addYourBankAccountToEnableUssd:
      "Дадайце свой банкаўскі рахунак, каб уключыць імгненны перавод USSD з дапамогай uPay",
    totalAmountToPay: "Агульная сума выплаты",
    doYouWantToCancelTransaction: "Вы хочаце адмяніць гэтую транзакцыю?",
    savePrintBill: "Захаваць/раздрукаваць рахунак",
    enterAmountCollectedForCustomer: "Увядзіце суму, сабраную для кліента",
    recordSale: "Рэкордны продаж",
    checkOutWith: "Праверыць з",
    instantTransfer: "Імгненная перадача",
    dialTheUSSDCode: "Набярыце USSD -код",
    pleaseSelectABank: "Выберыце банк",
    payWithUSSD: "Аплата з дапамогай USSD",
    sendBillTo: " - Адпраўце Біла на",
    waitingForUSSDTransfer: "У чаканні перадачы USSD",
    percent: "Працэнт",
    applyDiscount: "Ужыць зніжку",
    thisBillHasBeenSaved: "Гэты законапраект захаваны",
    saveDraft: "Захаваць чарнавік",
    pleaseTypeANameToIdentifyCustomer:
      "Калі ласка, увядзіце імя, каб ідэнтыфікаваць кліента",
    paymentDetails: "Інфармацыя аб аплаце",
    basePrice: "Базавая цана",
    staff: "Персанал",
    subTotal: "SubTotal",
    durationMonths: "Працягласць (месяцы)",
    selectADuration: "Выберыце працягласць",
    oneMonth: "1 месяц",
    twoMonths: "2 месяцы",
    threeMonths: "3 месяцы",
    sixMonths: "6 месяцаў",
    twelveMonths: "12 месяцаў",
    eighteenMonths: "18 месяцаў",
    twentyFourMonths: "24 месяцы",
    twoMonthsFree: "(2 месяцы бясплатна)",
    threeMonthsFree: "(3 месяцы бясплатна)",
    fiveMonthsFree: "(5 месяцаў бясплатна)",
    yourAccountHasBeen: "Ваш уліковы запіс быў",
    renewed: "абноўлена",
    upgraded: "мадэрнізаваны",
    successfully: "паспяхова",
    yourSubscription: "Ваша падпіска",
    youAreCurrentlyEnrolledOnA: "У цяперашні час вы зарэгістраваны на",
    pleaseChooseAPaymentOption: "Выберыце спосаб аплаты",
    planRenewal: "План абнаўлення",
    planUpgrade: "План абнаўлення",
    pleaseSelectDurationToPayFor:
      "Калі ласка, абярыце працягласць, за якую вы збіраецеся плаціць",
    staffAccounts: "Рахункі персаналу",
    ecommerce: "Электронная камерцыя",
    pleaseSelectAPlan: "Выберыце план",
    includeAddons: "Уключыце дапаўненні",
    viewTransactions: "Прагляд транзакцый",
    customerHasNoCompletedTansactions: "Кліент яшчэ не завяршыў размовы",
    branch: "Аддзяленне",
    enterNumberOfEcommerceBranches:
      "Увядзіце колькасць філіялаў электроннай камерцыі",
    enterNumberOfEcommerceBranchesToPay:
      "Увядзіце колькасць філіялаў электроннай камерцыі, за якія вы збіраецеся плаціць",
    ecommerceIntegration: "Інтэграцыя электроннай камерцыі",
    enterNumberOfBranches: "Увядзіце колькасць аддзяленняў",
    enterNumberOfAdditionalBranchesToPay:
      "Увядзіце колькасць дадатковых аддзяленняў, за якія вы збіраецеся плаціць",
    enterNumberOfStaffs: "Увядзіце колькасць супрацоўнікаў",
    enterNumberOfStaffsToPayFor:
      "Увядзіце колькасць супрацоўнікаў, за якія вы збіраецеся плаціць",
    discountApplies: "Ужываецца зніжка",
    starsOnThe: "зоркі на",
    offer: "прапанова",
    get: "Атрымаць",
    moreStarsToRedeem: "больш зорак, каб выкупіць",
    taxVat: "Падатак (ПДВ)",
    callCashierToCompletePayment: "Патэлефануйце ў касір, каб завяршыць аплату",
    receipt: "Квітанцыя",
    dear: "Паважаны",
    thankYouForYourOrderFindGoods:
      "Дзякуй за заказ. Калі ласка, знайдзіце наступныя тавары, якія пастаўляюцца, як дамовіліся.",
    shippingNote: "Заўвага аб дастаўцы",
    enterShippingNote: "Увядзіце Заўвагу аб дастаўцы",
    shippingAddress: "Адрас дастаўкі",
    enterShippingAddress: "Увядзіце адрас дастаўкі",
    wellDoneYouAreDueToRedeem: "Добра зроблена! Вы павінны выкупіць",
    toGetYourRewardNextVisit:
      "каб атрымаць узнагароду пры наступным наведванні. Дзякуй",
    pointsOnThe: "Пункты на",
    morePointsToRedeem: "больш ачкоў, каб выкупіць",
    showCode: "Паказаць код",
    toGetYourRewardOnNextVisit:
      "каб атрымаць узнагароду пры наступным наведванні. Дзякуй",
    earn: "Зарабіць",
    delivaryNote: "Заўвага аб дастаўцы",
    draftSales: "Чарнавыя продажу",
    startDate: "Дата пачатку",
    endDate: "Дата заканчэння",
    orders: "Заказы",
    checkout: "каса",
    noProductItem: "Няма тавару прадукту",
    selectProductImage: "Выберыце Выява прадукту",
    selectCountry: "Выберыце краіну",
    selectRegion: "Выберыце штат/рэгіён",
    printProductTag: "Раздрукаваць тэг прадукту",
    transactionReference: "Спасылка на здзелку",
    Cashier: "Касір",
    Manager: "Менеджэр",
    Owner: "Уладальнік",
    Admin: "Адмін",
  },
  Bengali: {
    cashier: "কোষাধ্যক্ষ",
    manager: "ম্যানেজার",
    owner: "মালিক",
    online: "অনলাইন",
    offline: "অফলাইন",
    changePassword: "পাসওয়ার্ড পরিবর্তন করুন",
    currentPasswordMessage: "আপনার বর্তমান পাসওয়ার্ড লিখুন",
    passwordMessage: "আপনার পাসওয়ার্ড দিন",
    currentPassword: "বর্তমান পাসওয়ার্ড",
    password: "পাসওয়ার্ড",
    confirmPasswordMessage: "আপনার পাসওয়ার্ড নিশ্চিত করুন!",
    confirmPassword: "পাসওয়ার্ড নিশ্চিত করুন",
    sendViaEmail: "ইমেলের মাধ্যমে পাঠান",
    sendViaWhatsapp: "হোয়াটসঅ্যাপের মাধ্যমে পাঠান",
    downloadPdf: "পিডিএফ ডাউনলোড করুন",
    paid: "পরিশোধ",
    unpaid: "অবৈতনিক",
    partial: "আংশিক",
    closeInvoice: "আপনি কি চালান বন্ধ করতে চান?",
    closeInvoiceConfirmation: "চালান সেভ নাও হতে পারে। আপনি কি বন্ধ করতে চান?",
    yes: "হ্যাঁ",
    no: "না",
    invoice: "চালান",
    wasDeducted: "কাটা ছিল",
    for: "জন্য",
    item: "আইটেম",
    addProduct: "পণ্য যোগ করুন",
    paymentReference: "পেমেন্ট রেফারেন্স",
    amountPaid: "পরিমাণ অর্থ প্রদান করা",
    discountAmount: "হ্রাসকৃত মুল্য",
    amountDue: "বাকি আছে",
    amount: "পরিমাণ",
    dueDate: "নির্দিষ্ট তারিখ",
    paymentType: "শোধের ধরণ",
    card: "কার্ড",
    cash: "নগদ",
    bankTransfer: "ব্যাংক লেনদেন",
    paymentMessage: "পেমেন্ট মেসেজ",
    description: "বর্ণনা",
    sendReceipt: "রসিদ পাঠান",
    delete: "মুছে ফেলা",
    save: "সংরক্ষণ",
    resend: "পুনরায় পাঠান",
    saveAndSend: "সংরক্ষণ",
    invoiceSaved: "চালান সংরক্ষিত!",
    selectPaymentMethod: "অনুগ্রহ পূবর্ক মূল্য পরিশোধের পদ্ধতি নিবাচন করুন!",
    selectCustomer: "দয়া করে একজন গ্রাহক নির্বাচন করুন!",
    cartEmptyError:
      "কার্ট তালিকা খালি হতে পারে না, চালান বন্ধ করুন এবং কার্টে আইটেম যোগ করুন!",
    subscriptionExpired:
      "আপনার সাবস্ক্রিপশনের মেয়াদ শেষ হয়ে গেছে এবং আপনার অ্যাকাউন্ট এখন সীমিত। আপনার অ্যাকাউন্ট রিনিউ করতে নিচের বাটনে ক্লিক করুন",
    renew: "নবায়ন করুন",
    holdOn: "অপেক্ষা কর",
    customerBank: "গ্রাহক ব্যাংক",
    cancel: "বাতিল করুন",
    selectACustomer: "গ্রাহক নির্বাচন করুন",
    invoiceSuccessfulPdfError:
      "ইনভয়েস সফলভাবে তৈরি হয়েছে কিন্তু PDF জেনারেশন স্বাভাবিকের চেয়ে বেশি সময় নিচ্ছে। শীঘ্রই আবার চেক করুন।",
    downloadingInvoice: "চালান ডাউনলোড করা হচ্ছে",
    downloadingReceipt: "রসিদ ডাউনলোড করা হচ্ছে",
    whatsappReceiptError:
      "হোয়াটসঅ্যাপের মাধ্যমে রসিদ পাঠাতে একটি ত্রুটি ঘটেছে, দয়া করে আবার চেষ্টা করুন।",
    receiptToWhatsapp: "রসিদ হোয়াটসঅ্যাপে পাঠানো হয়েছে",
    thankYouForPatronage: "আপনার পৃষ্ঠপোষকতা করার জন্য আপনাকে ধন্যবাদ",
    hereIsYourReceipt: "এই হল আপনার পেমেন্টের রসিদ",
    errorSendingEmailReceipt:
      "ই-মেইলের মাধ্যমে রসিদ পাঠাতে একটি ত্রুটি ঘটেছে, অনুগ্রহ করে আবার চেষ্টা করুন অথবা সমর্থনে যোগাযোগ করুন",
    receiptSentToEmail: "গ্রাহকের ইমেইলে রসিদ পাঠানো হয়েছে",
    invoiceSentToEmail: "গ্রাহকের ইমেইলে চালান পাঠানো হয়েছে",
    invoiceSuccessWhatsappError:
      "ইনভয়েস সফলভাবে তৈরি হয়েছে কিন্তু হোয়াটসঅ্যাপে পাঠানোর সময় একটি ত্রুটি ঘটেছে। চালান তালিকায় আবার চেষ্টা করুন",
    invoiceSuccessfulEmailError:
      "ইনভয়েস সফলভাবে তৈরি হয়েছে কিন্তু ই-মেইল হিসাবে পাঠানোর সময় একটি ত্রুটি ঘটেছে। চালান তালিকা থেকে আবার চেষ্টা করুন",
    invoiceSentToWhatsapp: "হোয়াটসঅ্যাপে চালান পাঠানো হয়েছে",
    hello: "হ্যালো",
    pleaseDownloadInvoice: "দয়া করে চালান ডাউনলোড করুন",
    pleaseDownloadReceipt: "দয়া করে রসিদ ডাউনলোড করুন",
    from: "থেকে",
    email: "ইমেইল",
    upgrade: "আপগ্রেড করুন",
    youAreOnFreePlan: "আপনি একটি বিনামূল্যে পরিকল্পনায় আছেন।",
    clickOn: "ক্লিক করুন",
    yourPlanInFewSteps: " কিছু দ্রুত ধাপে আপনার সাবস্ক্রিপশন।",
    to: "প্রতি",
    yourSubscriptionHasExpired:
      "আপনার সাবস্ক্রিপশনের মেয়াদ শেষ হয়ে গেছে এবং আপনার অ্যাকাউন্ট এখন সীমিত।",
    days: "দিন",
    yourSubscriptionExpiresIn:
      "আপনার লয়স্টার সাবস্ক্রিপশনের মেয়াদ শেষ হয়ে যাচ্ছে",
    createAcount: "একটি অ্যাকাউন্ট তৈরি করুন",
    signIn: "সাইন ইন করুন",
    continue: "চালিয়ে যান",
    enterOtp: "ওটিপি পিন লিখুন",
    enterValidOtp: "দয়া করে একটি বৈধ পিন লিখুন",
    pin: "পিন",
    tokenSentToMail: "আপনার ইমেইলে একটি টোকেন পাঠানো হয়েছে",
    passwordResetSuccessful: "পাসওয়ার্ড রিসেট সফল হয়েছে",
    somethingWentWrong: "কিছু ভুল হয়েছে!",
    resetPassword: "পাসওয়ার্ড রিসেট করুন",
    iHaveResetCode: "আমার পাসওয়ার্ড রিসেট কোড আছে",
    pleaseEnterEmail: "আপনার ইমেল ঠিকানা লিখুন দয়া করে",
    aTokenWillBeSentToEmail: "আপনার ইমেইলে একটি টোকেন পাঠানো হবে",
    enterEmail: "তুমার ইমেইল প্রবেশ করাও",
    sendinYourToken: "আপনার টোকেন পাঠানো হচ্ছে ...",
    makeSureItMatchesPassword:
      "নিশ্চিত করুন যে এটি আপনার নতুন পাসওয়ার্ডের সাথে মেলে",
    pleaseChooseNewPassword: "দয়া করে একটি নতুন পাসওয়ার্ড চয়ন করুন",
    chooseNewPassword: "একটি নতুন পাসওয়ার্ড পছন্দ করুন",
    enterNewPassword: "নিশ্চিত করতে আপনার নতুন পাসওয়ার্ড লিখুন",
    enterTokenSent: "আপনার মেইল পাঠানো টোকেন লিখুন",
    resetToken: "টোকেন রিসেট করুন",
    resettingPassword: "আপনার পাসওয়ার্ড পুনরায় সেট করা হচ্ছে ...",
    signUp: "নিবন্ধন করুন",
    adminSignInWithEmail:
      " প্রশাসক ইমেল দিয়ে সাইন ইন করেন যখন স্টাফ ব্যবহারকারীর নাম দিয়ে সাইন ইন করেন।",
    pleaseEnterEmailOrUsername: "আপনার ইমেল বা ব্যবহারকারীর নাম লিখুন",
    emailOrUsername: "ইমেল বা ব্যবহারকারীর নাম",
    pleaseEnterPassword: "দয়া করে পাসওয়ার্ড লিখুন",
    createAnAccount: "একটি অ্যাকাউন্ট তৈরি করুন",
    forgotPassword: "পাসওয়ার্ড ভুলে গেছেন?",
    enterPhoneNumber: "ফোন নম্বর লিখুন",
    personalData: "ব্যক্তিগত তথ্য",
    validateConfirmationCOde: "নিশ্চিতকরণ কোড যাচাই করুন",
    pleaseEnterFirstName: "দয়া করে আপনার প্রথম নাম লিখুন",
    pleaseEnterPhoneNumber: "অনুগ্রহ করে আপনার ফোন নাম্বারটি প্রদান করুন",
    pleaseEnterLastName: "তোমার নামের শেষ অংশ দাও",
    pleaseEnterBusinessName: "আপনার ব্যবসার নাম লিখুন",
    firstName: "নামের প্রথম অংশ",
    lastName: "নামের শেষাংশ",
    businessName: "প্রাতিষ্ঠানিক নাম",
    previous: "আগে",
    next: "পরবর্তী",
    pleaseSelectBusinessCategory:
      "অনুগ্রহ করে আপনার ব্যবসার বিভাগ নির্বাচন করুন",
    pleaseEnterValidEmail: "একটি বৈধ ইমেইল প্রবেশ করুন",
    pleaseEnterPostCode: "পোস্ট কোড লিখুন",
    postCode: "পোস্ট কোড",
    phoneNumberInUse: "এই ফোন নম্বরটি ইতিমধ্যে ব্যবহার করা হচ্ছে!",
    emailInUse: "এই ইমেইলটি ইতিমধ্যে ব্যবহার করা হয়েছে!",
    foodAndDrinks: "খাদ্য এবং পানীয়",
    salonAndBeauty: "সেলুন এবং সৌন্দর্য",
    fashionAndAccessories: "ফ্যাশন এবং আনুষাঙ্গিক",
    gymAndFitness: "জিম এবং ফিটনেস",
    travelAndHotel: "ভ্রমণ এবং হোটেল",
    homeAndGifts: "বাড়ি এবং উপহার",
    washingAndCleaning: "ধোয়া এবং পরিষ্কার করা",
    gadgetsAndElectronics: "গ্যাজেট এবং ইলেকট্রনিক্স",
    groceries: "মুদির জিনিসপত্র",
    others: "অন্যান্য",
    submit: "জমা দিন",
    accountCreatedSuccessful: "আপনার অ্যাকাউন্ট সফলভাবে তৈরি করা হয়েছে।",
    pleaseEnterAddress: "অনুগ্রহ করে আপনার ঠিকানা লিখুন",
    addressLine1: "ঠিকানা লাইন 1",
    addressLine2: "ঠিকানা লাইন ২",
    choosePassword: "পাসওয়ার্ড নির্বাচন করুন",
    passwordMustBe6Characters: "পাসওয়ার্ড কমপক্ষে 6 অক্ষরের হতে হবে।",
    howDidYouHearLoystar: "আপনি লয়স্টার সম্পর্কে কীভাবে শুনেছেন?",
    referralCode: "রেফারেল কোড",
    byClickingTheButton: " নীচের বোতামে ক্লিক করে, আপনি এতে সম্মত হন",
    termsAndSevice: "শর্তাবলী",
    wereCreatingAccount: "আমরা আপনার অ্যাকাউন্ট তৈরি করছি",
    proceed: "এগিয়ে যান",
    verificationCodeMustBe6: "ভেরিফিকেশন কোড অবশ্যই 6 ডিজিটের হতে হবে",
    pleaseEnter6DigitCode: "অনুগ্রহ করে digit সংখ্যার কোড লিখুন",
    enterVerificationCode: "আপনার যাচাইকরণ সংকেতটি প্রবেশ করুন",
    resendToken: "টোকেন আবার পাঠান",
    verify: "যাচাই করুন",
    transactions: "লেনদেন",
    todaySales: "আজকের বিক্রয়",
    salesHistory: "বিক্রয় ইতিহাস",
    supplyHistory: "সরবরাহের ইতিহাস",
    new: "নতুন",
    invoices: "চালান",
    disbursements: "বিতরণ",
    offlineSales: "অফলাইন বিক্রয়",
    products: "পণ্য",
    customers: "গ্রাহক",
    multiLevelLoyalty: "বহু স্তরের আনুগত্য",
    loyaltyPrograms: "আনুগত্য প্রোগ্রাম",
    members: "সদস্যরা",
    appStore: "অ্যাপ স্টোর",
    orderMenu: "অর্ডার মেনু",
    settings: "সেটিংস",
    staffAndBranches: "কর্মী এবং শাখা",
    myAccount: "আমার অ্যাকাউন্ট",
    switchToSellMode: "বিক্রয় মোডে যান",
    signOut: "সাইন আউট",
    getFreeSubscription: "বিনামূল্যে সাবস্ক্রিপশন পান",
    onlyNumbersAllowed: "শুধুমাত্র সংখ্যা অনুমোদিত",
    yourAccountMustBe10Digits:
      "আপনার অ্যাকাউন্ট নম্বর অবশ্যই 10 অঙ্কের নম্বর হতে হবে",
    yourBvnMustBe11: "আপনার BVN অবশ্যই 11 অঙ্কের নম্বর হতে হবে",
    pleaseSelectBank: "অনুগ্রহ করে আপনার ব্যাংক নির্বাচন করুন",
    selectYourBank: "আপনার ব্যাংক নির্বাচন করুন",
    enterBankAccountNumber: "ব্যাঙ্ক অ্যাকাউন্ট নম্বর লিখুন",
    enterBvnNumber: "আপনার BVN লিখুন",
    connectBankAccount: "ব্যাংক অ্যাকাউন্ট সংযুক্ত করুন",
    passwordResetSuccessfulAndSignOut:
      "আপনার পাসওয়ার্ড সফলভাবে পুনরায় সেট করা হয়েছে। আবার সাইন ইন করতে নিচের সাইন আউট বাটনে ক্লিক করুন",
    enterCurrentPassword: "বর্তমান পাসওয়ার্ড লিখুন",
    pleaseEnterCurrentPassword: "বর্তমান পাসওয়ার্ড লিখুন",
    phoneNumber: "ফোন নম্বর",
    sex: "সেক্স",
    dateOfBirth: "জন্ম তারিখ",
    state: "রাষ্ট্র",
    country: "দেশ",
    loyaltyId: "আনুগত্য আইডি",
    createdAt: "এ নির্মিত",
    noOfTransactionsMade: "করা লেনদেনের সংখ্যা",
    yourDownloadWillStart: "আপনার ডাউনলোড কিছুক্ষণের মধ্যে শুরু হবে",
    exportCustomers: "গ্রাহকদের রপ্তানি করুন",
    youhaveSuccessfullyToppedUp:
      "আপনি আপনার এসএমএস ইউনিট সফলভাবে টপ আপ করেছেন।",
    youNowHave: "তোমার এখন আছে",
    smsUnits: "এসএমএস ইউনিট",
    enterNumberOfSmsUnits:
      "আপনি ক্রয় করতে চান এমন এসএমএস ইউনিটের সংখ্যা লিখুন",
    pleaseEnterANumericValue: "একটি সংখ্যাসূচক মান লিখুন",
    pay: "পে",
    accountEditedSuccessfully: "আপনার অ্যাকাউন্ট সফলভাবে সম্পাদিত হয়েছে।",
    youAeCurrentlyOn: "আপনি বর্তমানে চালু আছেন",
    plan: "পরিকল্পনা",
    userData: "ব্যবহারকারী তথ্য",
    businessData: "বিজনেস ডেটা",
    businessCategory: "বিজনেস ক্যাটারগরি",
    pleaseSelectCurrency: "আপনার মুদ্রা নির্বাচন করুন",
    selectYourCurrency: "আপনার মুদ্রা নির্বাচন করুন",
    purchaseMoreSmsUnits: "নিচের ফর্মটি ব্যবহার করে আরো এসএমএস ইউনিট কিনুন",
    youHave: "তোমার আছে",
    atLeast4SmsUnitsRrequired:
      "যাচাই করার জন্য কমপক্ষে 4 টি এসএমএস ইউনিট প্রয়োজন, দয়া করে টপ আপ করুন!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "আপনার গ্রাহককে জানুন এটি আপনাকে ইউএসএসডি বা তাত্ক্ষণিক স্থানান্তরের মাধ্যমে পেমেন্ট সংগ্রহ, গ্রাহকদের কাছ থেকে অর্ডার গ্রহণ এবং লেনদেনের নিষ্পত্তি প্রক্রিয়া করার অনুমতি দেবে। শুধুমাত্র নাইজেরিয়ান বণিকদের জন্য প্রযোজ্য। শুরু করতে নীচের বোতাম টিপুন।",
    reConnectBankAccount: "ব্যাংক অ্যাকাউন্ট পুনরায় সংযুক্ত করুন",
    accountName: "হিসাবের নাম",
    accountNumber: "হিসাব নাম্বার",
    bankName: "ব্যাংকের নাম",
    verified: "যাচাই",
    accountDetails: "বিস্তারিত হিসাব",
    kycBankAccount: "কেওয়াইসি",
    createTier: "টিয়ার তৈরি করুন",
    fileUploadSuccessful: "ফাইল সফলভাবে আপলোড",
    fileUploadFailed: "ফাইল আপলোড ব্যর্থ হয়েছে",
    createLoyaltyProgram: "একটি আনুগত্য প্রোগ্রাম তৈরি করুন",
    createLoyalty: "আনুগত্য তৈরি করুন",
    name: "নাম",
    loyaltyArtwork: "আনুগত্য শিল্পকর্ম",
    clickToUpload: "আপলোড করতে ক্লিক করুন",
    amountToPointsRatio: "পয়েন্ট অনুপাতের পরিমাণ",
    points: "পয়েন্ট",
    recommendedAmountToPointRatio:
      "প্রস্তাবিত: ₦ 1 থেকে 1 পয়েন্ট। অর্থাত্ প্রতি 1 নায়রা ব্যয় করার জন্য, আপনার গ্রাহকরা 1 পয়েন্ট পান",
    pleaseTypeIn: "দয়া করে টাইপ করুন",
    toDeleteLoyalty: "এই আনুগত্য মুছে ফেলার জন্য",
    deleteLoyalty: "আনুগত্য মুছে দিন",
    toConfirm: "নিশ্চিত করতে",
    edit: "সম্পাদনা করুন",
    pointsAwardedSuccessfully: "পয়েন্ট সফলভাবে পুরস্কৃত।",
    enterPointValueToAward:
      "আপনি গ্রাহকদের পুরস্কার দিতে চান এমন পয়েন্ট মান লিখুন",
    award: "পুরস্কার",
    awardPointValuesToCustomer: "অ্যাওয়ার্ড পয়েন্ট মূল্য গ্রাহকের জন্য",
    enrollMembersToLoyalty: "সদস্যদের আনুগত্যে নথিভুক্ত করুন",
    awardPoints: "পুরস্কার পয়েন্ট",
    enroll: "তালিকাভুক্ত করুন",
    home: "বাড়ি",
    loyalty: "আনুগত্য",
    enrollCustomers: "গ্রাহকদের তালিকাভুক্ত করুন",
    selected: "নির্বাচিত",
    customer: "ক্রেতা",
    loyaltyActivationSuccessful: "আনুগত্য সক্রিয়করণ সফল।",
    loyaltyDeactivationSuccessful: "আনুগত্য নিষ্ক্রিয়করণ সফল।",
    viewTier: "টিয়ার দেখুন",
    deactivate: "ডি-অ্যাক্টিভেট করুন",
    activate: "সক্রিয় করুন",
    actions: "ক্রিয়া",
    nameOfLoyalty: "আনুগত্যের নাম",
    loyaltyStatus: "আনুগত্য অবস্থা আনুগত্য অবস্থা",
    numberOfTiers: "স্তরের সংখ্যা",
    createdOn: "তৈরি",
    createATier: "একটি স্তর তৈরি করুন",
    stopCreatingTierConfirmation: "আপনি কি একটি স্তর তৈরি বন্ধ করতে চান?",
    stopEditingTierConfirmation: "আপনি কি এই স্তর সম্পাদনা বন্ধ করতে চান?",
    nameOfTier: "স্তরের নাম",
    minimumSpendingTarget: "ন্যূনতম ব্যয় লক্ষ্য",
    maximumSpendingTarget: "সর্বোচ্চ ব্যয়ের লক্ষ্যমাত্রা",
    minimumSpendingTargetRequired: "ন্যূনতম ব্যয়ের লক্ষ্যমাত্রা প্রয়োজন",
    maximumSpendingTargetRequired: "সর্বাধিক ব্যয়ের লক্ষ্য প্রয়োজন",
    rewardSponsor: "পুরস্কার স্পনসর",
    pleaseChooseARewardSponsor: "অনুগ্রহ করে একটি পুরস্কার স্পনসর বেছে নিন",
    self: "স্বয়ং",
    partner: "অংশীদার",
    rewardPartner: "পুরস্কার অংশীদার",
    pleaseSelectRewardPartner:
      "অনুগ্রহ করে আপনার পুরস্কারের অংশীদার নির্বাচন করুন",
    rewards: "পুরস্কার",
    pleaseSelectAReward: "অনুগ্রহ করে একটি পুরস্কার নির্বাচন করুন",
    instructionsOnRedeemingReward:
      "কিভাবে গ্রাহককে পুরস্কার প্রদান করতে হবে সে বিষয়ে নির্দেশনা",
    pleaseFillInThisField: "দয়া করে এই ক্ষেত্রটি পূরণ করুন!",
    toDeleteThisTier: " এই স্তরটি মুছে ফেলার জন্য",
    deleteTier: "টিয়ার মুছুন",
    viewMembers: "সদস্যদের দেখুন",
    membersEnrolled: "সদস্য তালিকাভুক্ত",
    instruction: "নির্দেশ",
    membersIn: "সদস্যরা",
    availableTiersInLoyalty: "আনুগত্য প্রোগ্রামে উপলব্ধ স্তর (গুলি)",
    tiers: "স্তর",
    totalTier: "মোট টিয়ার",
    availableLoyaltyProgramme: "উপলব্ধ আনুগত্য প্রোগ্রাম",
    totalLoyalties: "মোট আনুগত্য",
    memberDetails: "সদস্য বিবরণ",
    nameOfCustomer: "গ্রাহকের নাম",
    address: "ঠিকানা",
    allEnrolledMembers: "তালিকাভুক্ত সকল সদস্য",
    thisIsToWishYouHappyBirthday:
      "এটি আপনার জন্মদিন এবং সমৃদ্ধ জীবন কামনা করে। আপনি লইস্টারের জন্য যা কিছু করেছেন তার জন্য ধন্যবাদ। শুভ উদযাপন!",
    inputAnOfferPlease: "একটি অফার লিখুন দয়া করে",
    pleaseSelectTheInsertPoint:
      "দয়া করে মেসেজের ইনসার্ট পয়েন্ট নির্বাচন করুন এবং আবার ক্লিক করুন",
    birthdayOfferAndMessage: "জন্মদিনের অফার এবং বার্তা",
    birthdayOffer: "জন্মদিনের অফার",
    birthdayMessage: "জন্মদিনের বার্তা",
    noOfferFound: "কোন অফার পাওয়া যায়নি",
    settingABirthdayOffer:
      "জন্মদিনের অফার সেট করলে গ্রাহকরা তাদের জন্মদিনে এসএমএসের মাধ্যমে এই অফারটি গ্রহণ করতে পারবেন",
    createOffer: "অফার তৈরি করুন",
    whatIsTheOffer: "অফার কি?",
    editMessage: "বার্তা সম্পাদনা করুন",
    insert: "Insোকান",
    theNameOfCustomerInserted: "গ্রাহকের নাম এখানে ertedোকানো হবে",
    theBirtdayOfferInserted: "জন্মদিনের প্রস্তাব এখানে beোকানো হবে",
    youSuccessfullyAddedNewBranch: "আপনি সফলভাবে একটি নতুন শাখা যোগ করেছেন!",
    addNewBranch: "নতুন শাখা যোগ করুন",
    addBranch: "শাখা যোগ করুন",
    additionalBranchWillIncur: "অতিরিক্ত শাখা বহন করবে",
    perBranch: "প্রতি শাখা",
    ecommerceBranchCosts: "ইকমার্স শাখার খরচ",
    pleaseEnterBranchName: "অনুগ্রহ করে শাখার নাম লিখুন",
    pleaseEnterBranchAddress1: "অনুগ্রহ করে শাখার ঠিকানা লাইন 1 লিখুন",
    enterBranchAddress1: "শাখার ঠিকানা লাইন 1 লিখুন",
    enterBranchAddress2: "শাখার ঠিকানা লাইন 1 লিখুন",
    pleaseEnterBranchAddress2: "অনুগ্রহ করে শাখার ঠিকানা লাইন 2 লিখুন",
    enterBranchName: "শাখার নাম লিখুন",
    successfullyAddedStaff: "আপনি সফলভাবে একটি নতুন কর্মী যোগ করেছেন!",
    addNewStaff: "নতুন কর্মী যোগ করুন",
    addStaff: "কর্মী যোগ করুন",
    additionalStaffWillIncur: "অতিরিক্ত স্টাফ লাগবে",
    perStaff: "প্রতি কর্মী।",
    pleaseEnterStaffEmail: "দয়া করে কর্মীদের ইমেল লিখুন",
    pleaseEnterStaffUsername: "দয়া করে কর্মীদের ব্যবহারকারীর নাম লিখুন",
    pleaseEnterStaffPassword: "কর্মীদের পাসওয়ার্ড লিখুন",
    pleaseSelectStaffRole: "দয়া করে কর্মীদের ভূমিকা নির্বাচন করুন",
    selectStaffRole: "কর্মীদের ভূমিকা নির্বাচন করুন",
    enterStaffEmail: "কর্মীদের ইমেল লিখুন",
    enterStaffUsername: "কর্মীদের ব্যবহারকারীর নাম লিখুন",
    enterStaffPassword: "কর্মীদের পাসওয়ার্ড লিখুন",
    spacesNotAllowedInUsername: "ইউজারনেমে স্পেস অনুমোদিত নয়",
    admin: "অ্যাডমিন",
    pleaseSelectBusinessToAttachStaff:
      "কর্মীদের সংযুক্ত করার জন্য অনুগ্রহ করে ব্যবসা নির্বাচন করুন",
    searchForBranchToAttachStaff:
      "কর্মীদের সংযুক্ত করার জন্য শাখা অনুসন্ধান করুন",
    username: "ব্যবহারকারীর নাম",
    role: "ভূমিকা",
    areYouSureToDeleteThis: "আপনি কি এটি মুছে ফেলার ব্যাপারে নিশ্চিত?",
    branches: "শাখা",
    upgradeYourPlan: "আপনার পরিকল্পনা আপগ্রেড করুন।",
    add: "যোগ করুন",
    addNew: "নতুন যোগ করুন",
    customerWithEmailAlreadyExists:
      "ইমেইল/ফোন নম্বর সহ গ্রাহক ইতিমধ্যে বিদ্যমান!",
    successfullyAddedNewCustomer: "আপনি সফলভাবে একটি নতুন গ্রাহক যোগ করেছেন!",
    addCustomer: "গ্রাহক যোগ করুন",
    pleaseEnterCustomerFirstName: "গ্রাহকের প্রথম নাম লিখুন",
    pleaseEnterCustomerLastName: "অনুগ্রহ করে গ্রাহকের শেষ নাম লিখুন",
    pleaseEnterCustomerPhoneNumber: "গ্রাহকের ফোন নম্বর লিখুন",
    pleaseEnterCustomerEmail: "অনুগ্রহ করে গ্রাহকের ইমেল লিখুন",
    pleaseEnterCustomerAddressLine: "গ্রাহকের ঠিকানা লাইন লিখুন",
    pleaseEnterCustomerOtherAddress: "গ্রাহকের অন্য ঠিকানা লিখুন",
    pleaseSelectCustomerGender: "অনুগ্রহ করে গ্রাহকের লিঙ্গ নির্বাচন করুন",
    gender: "লিঙ্গ",
    male: "পুরুষ",
    female: "মহিলা",
    bank: "ব্যাংক",
    selectBank: "ব্যাংক নির্বাচন করুন",
    stateOrRegionOrProvince: "রাজ্য/অঞ্চল/প্রদেশ",
    customerNotes: "গ্রাহক নোট",
    sendSms: "বার্তা পাঠান",
    editCustomer: "গ্রাহক সম্পাদনা করুন",
    redeemReward: "পুরস্কার মুক্ত করুন",
    issueLoyaltyCard: "আনুগত্য কার্ড প্রদান",
    deleteCustomer: "গ্রাহক মুছুন",
    youveSuccessfullyAssignedLoyaltyMembership:
      "আপনি সফলভাবে একটি লয়ালটি মেম্বারশিপ আইডি অ্যাসাইন করেছেন",
    noMembershipIdAvailable:
      "কোনো মেম্বারশিপ আইডি পাওয়া যায় না। আমাদের সাথে hello@loystar.co এ যোগাযোগ করুন",
    sendEmail: "ইমেইল পাঠান",
    membershipPoints: "সদস্যপদ পয়েন্ট",
    customerDetails: "কাস্টমার বিস্তারিত",
    close: "বন্ধ",
    loyaltyBalance: "আনুগত্য ভারসাম্য",
    pointsBalance: "পয়েন্ট ব্যালেন্স",
    starBalance: "স্টার ব্যালেন্স",
    requiredPoints: "প্রয়োজনীয় পয়েন্ট",
    requiredStars: "প্রয়োজনীয় তারা",
    reddemCode: "কোড উদ্ধার",
    toDeleteThisCustomer: "এই গ্রাহক মুছে ফেলার জন্য",
    customerHasBeenDeletedSuccessfully: "গ্রাহক সফলভাবে মুছে ফেলা হয়েছে!",
    customerWithPhoneAlreadyExists: "ফোন নম্বর সহ গ্রাহক ইতিমধ্যে বিদ্যমান",
    customerWasSuccessfullyEdited: "গ্রাহক সফলভাবে সম্পাদিত হয়েছে",
    anErrorOccured: "একটি ত্রুটি ঘটেছে",
    phoneNumberIncludeCountry: "ফোন নম্বর (দেশের কোড অন্তর্ভুক্ত করুন)",
    yourFileQueuedForUpload:
      "আপনার ফাইল আপলোডের জন্য সারিবদ্ধ করা হয়েছে। কয়েক সেকেন্ড পর পৃষ্ঠাটি রিফ্রেশ করুন।",
    thereWasAnErrorPerformingOperation:
      "অপারেশন করার সময় একটি ত্রুটি হয়েছিল!",
    pleaseSelectFile: "দয়া করে একটি ফাইল নির্বাচন করুন!",
    oopsOnlyCsvFilesAllowed:
      "উফ! শুধুমাত্র CSV ফাইল অনুমোদিত। দয়া করে একটি .csv ফাইল আপলোড করুন।",
    fileShouldBeSmallerThan5Mb:
      "ফাইল 5MB এর চেয়ে ছোট হওয়া উচিত। আপনার যদি আরও বড় ফাইল থাকে, দয়া করে support emailloystar.co ইমেইল করুন। ধন্যবাদ",
    customerFirstNameIsRequired: "সারিতে গ্রাহকের প্রথম নাম প্রয়োজন",
    customerPhoneNumberIsRequired: "সারিতে গ্রাহকের ফোন নম্বর প্রয়োজন",
    importCustomers: "গ্রাহকদের আমদানি করুন",
    upload: "আপলোড করুন",
    clickIconToDownloadCsv:
      "CSV ফাইল টেমপ্লেট ডাউনলোড করতে এই আইকনে ক্লিক করুন।",
    getGoogleSheetVersion: "গুগল শীট সংস্করণ পান",
    clickOrDragFileToUpload:
      "ফাইলটি আপলোড করতে এই এলাকায় ক্লিক করুন বা টেনে আনুন",
    missingOrInvalidColumnHeader:
      "অনুপস্থিত বা অবৈধ কলাম শিরোলেখ। অনুগ্রহ করে টেমপ্লেট বিন্যাস অনুসরণ করুন। ধন্যবাদ.",
    youHaveImported: "আপনি আমদানি করেছেন",
    youSuccessfullyRedeemedReward: "আপনি আপনার পুরস্কার সফলভাবে রিডিম করেছেন!",
    sixDigitCode: "ছয় অঙ্কের কোড",
    pleaseEnterCustomerRewardCode: "অনুগ্রহ করে গ্রাহকের পুরস্কার কোড লিখুন",
    enterRewardCode: "পুরস্কার কোড লিখুন। (পুরস্কার কোড কেস সংবেদনশীল)",
    selectLoyaltyProgram: "আনুগত্য প্রোগ্রাম নির্বাচন করুন",
    stamps: "ডাকটিকিট",
    smsUnitsLow: "এসএমএস ইউনিট কম",
    pleaseTopUpSmsUnits: "দয়া করে এসএমএস ইউনিট টপ আপ করুন",
    smsSuccessfullySentForDelivery:
      "এসএমএস সফলভাবে পাঠানোর জন্য পাঠানো হয়েছে!",
    sendSmsTo: "এ এসএমএস পাঠান",
    allCustomers: "সকল গ্রাহক",
    unitsAvailable: "ইউনিট উপলব্ধ",
    pleaseTypeInTheMessage: "দয়া করে মেসেজ টাইপ করুন",
    message: "বার্তা",
    charactersRemaining: "অক্ষর বাকি",
    avoidUseOfSpecialCharacters:
      "এসএমএস ইউনিট সংরক্ষণের জন্য বিশেষ অক্ষর এবং ইমোজি ব্যবহার এড়িয়ে চলুন।",
    note: "বিঃদ্রঃ",
    errorFetchingCustomersMultilevelDetail:
      "গ্রাহকদের মাল্টি লেভেলের বিশদ বিবরণ আনতে ত্রুটি",
    search: "অনুসন্ধান করুন",
    reset: "রিসেট",
    importCustomer: "আমদানি গ্রাহক",
    addNewCustomer: "নতুন গ্রাহক যোগ করুন",
    sendSmsBroadcast: "এসএমএস ব্রডকাস্ট পাঠান",
    totalCustomers: "মোট গ্রাহক",
    disbursementDetails: "বিতরণের বিবরণ",
    paidBy: "দ্বারা প্রদত্ত",
    disbursed: "বিতরণ করা হয়েছে",
    bankAccountName: "ব্যাংক হিসাব নাম",
    bankAccountNumber: "ব্যাংক একাউন্ট নম্বর",
    transferInitiated: "বদলি শুরু হয়েছে",
    transferCompleted: "স্থানান্তর সম্পন্ন",
    pleaseEnterAValid: "দয়া করে একটি বৈধ লিখুন",
    begin: "শুরু",
    end: "শেষ",
    date: "তারিখ",
    paymentDate: "টাকা প্রদানের তারিখ",
    selectDisbursementDuration: "বিতরণের সময়কাল নির্বাচন করুন",
    totalSettled: "মোট বন্দোবস্ত",
    totalUnsettled: "মোট অস্থির",
    toDeleteThisSale: "এই বিক্রয় মুছে ফেলার জন্য",
    draftSaleDeletedSuccessfully: "খসড়া বিক্রয় সফলভাবে মুছে ফেলা হয়েছে!",
    deleteSale: "বিক্রয় মুছুন",
    pleaseTypeInYourTotal: "মুছে ফেলার বিষয়টি নিশ্চিত করতে আপনার মোট লিখুন",
    billDetails: "বিলের বিবরণ",
    printBill: "প্রিন্ট বিল",
    servedBy: "দ্বারা পরিসেবিত",
    total: "মোট",
    createdDate: "তৈরীর তারিখ",
    createdTime: "সৃষ্ট সময়",
    status: "স্থিতি",
    loginSuccessful: "সফল লগইন",
    pleaseWaitWhileWeConnectAccount:
      "অনুগ্রহ করে অপেক্ষা করুন যখন আমরা আপনার অ্যাকাউন্ট সংযুক্ত করি",
    connectionFailedTryAgain:
      "সংযোগ ব্যর্থ হয়েছে. অনুগ্রহপূর্বক আবার চেষ্টা করুন.",
    connectionSuccessful: "সংযোগ সফল",
    viewDetails: "বিস্তারিত দেখুন",
    enable: "সক্ষম করুন",
    free: "মুক্ত",
    cost: "খরচ",
    visitWebsite: "ওয়েবসাইট",
    pleaseUpgradeYourPlanToPro:
      "অনুগ্রহ করে এই অ্যাপটি সক্ষম করতে আপনার প্লানটিকে প্রো প্লাসে আপগ্রেড করুন",
    connectYourBankAccount:
      "পেমেন্ট পাওয়ার জন্য আপনার ব্যাঙ্ক অ্যাকাউন্ট সংযুক্ত করুন।",
    disable: "নিষ্ক্রিয় করুন",
    enableApp: "অ্যাপ সক্ষম করুন",
    addNewProductToInvoice: "চালানে নতুন পণ্য যোগ করুন",
    toDeleteThisInvoice: "এই চালান মুছে ফেলার জন্য",
    invoiceDeletedSuccessfully: "চালান সফলভাবে মুছে ফেলা হয়েছে!",
    deleteInvoice: "চালান মুছুন",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "আপনি সফলভাবে একটি চালান পেমেন্ট বার্তা যোগ করেছেন!",
    pleaseEnterPaymentInstructions: "অনুগ্রহ করে পেমেন্ট নির্দেশাবলী লিখুন",
    invoiceId: "চালান আইডি",
    paidDate: "প্রদানের তারিখ",
    reference: "রেফারেন্স",
    productAdded: "পণ্য যোগ করা হয়েছে",
    productOutOfStock: "স্টক আউট পণ্য। দয়া করে পুনরায় স্টক করুন।",
    totalInvoices: "মোট ইনভয়েস",
    totalPaidInvoices: "মোট প্রদত্ত চালান",
    totalUnpaidInvoices: "মোট অবৈতনিক ইনভয়েস",
    loyaltyProgramDeleted: "আনুগত্য প্রোগ্রাম মুছে ফেলা হয়েছে",
    thisLoyaltyProgramDeletedSuccessfully:
      "এই আনুগত্য প্রোগ্রাম সফলভাবে মুছে ফেলা হয়েছে",
    thisLoyaltyProgramEditedSuccessfully:
      "এই আনুগত্য প্রোগ্রাম সফলভাবে সম্পাদিত হয়েছে",
    loyaltyProgramAddedSuccessfully: "আনুগত্য প্রোগ্রাম সফলভাবে যোগ করা হয়েছে",
    loyaltyProgramEdited: "আনুগত্য প্রোগ্রাম সম্পাদিত",
    loyaltyProgramAdded: "আনুগত্য প্রোগ্রাম যোগ করা হয়েছে",
    loyaltyDetails: "আনুগত্য বিবরণ",
    doYouWantToCloseDialog: "আপনি কি এই ডায়ালগটি বন্ধ করতে চান?",
    pleaseEnterLoyaltyName: "অনুগ্রহ করে আপনার আনুগত্যের নাম লিখুন",
    programType: "প্রোগ্রামের ধরন",
    pleaseSelectProgramType: "অনুগ্রহ করে একটি প্রোগ্রামের ধরন নির্বাচন করুন",
    simplePoints: "সহজ পয়েন্ট",
    stampsProgram: "ডাকটিকিট প্রোগ্রাম",
    threshold: "থ্রেশহোল্ড",
    pleaseEnterLoyaltyThreshold: "অনুগ্রহ করে আনুগত্যের সীমা লিখুন",
    reward: "পুরস্কার",
    pleaseEnterLoyaltyReward: "অনুগ্রহ করে অনুগ্রহের পুরস্কার লিখুন",
    totalUserPoints: "মোট ব্যবহারকারী পয়েন্ট",
    totalUserStamps: "মোট ব্যবহারকারীর স্ট্যাম্প",
    spendingTarget: "ব্যয় লক্ষ্য",
    spendingTargetHint1:
      "খরচের লক্ষ্য হল একজন গ্রাহককে পুরস্কার অর্জন করতে কতটা ব্যয় করতে হবে। 1 মুদ্রার মান = 1 পয়েন্ট।",
    spendingTargetHint2:
      "স্ট্যাম্প টার্গেট হল একজন গ্রাহককে পুরস্কার পাওয়ার জন্য কতগুলি স্ট্যাম্প সংগ্রহ করতে হবে। যেমন 5",
    addNewLoyaltyProgram: "নতুন আনুগত্য প্রোগ্রাম যোগ করুন",
    addLoyaltyProgram: "আনুগত্য প্রোগ্রাম যোগ করুন",
    loyaltyProgramType: "আনুগত্য প্রোগ্রামের ধরণ",
    pleaseSelectLoyaltyProgramType:
      "অনুগ্রহ করে অনুগত প্রোগ্রামের ধরন নির্বাচন করুন",
    nameOfProgram: "প্রোগ্রামের নাম",
    pleaseEnterProgramName: "অনুগ্রহ করে প্রোগ্রামের নাম লিখুন",
    whatIsTheReward: "পুরস্কার কি?",
    egNextPurchaseIsFree: "যেমন পরবর্তী ক্রয় বিনামূল্যে",
    customerName: "ক্রেতার নাম",
    guestCustomer: "অতিথি গ্রাহক",
    orderConfirmedSuccessfully: "অর্ডার সফলভাবে নিশ্চিত করা হয়েছে",
    orderCancelledSuccessfully: "অর্ডার সফলভাবে বাতিল করা হয়েছে",
    confirmOrder: "আদেশ নিশ্চিত করুন",
    cancelOrder: "আদেশ বাতিল",
    allOrders: "সব অর্ডার",
    totalOrders: "মোট আদেশ",
    doYouWantToAcceptOrder: "আপনি কি এই আদেশ গ্রহণ করতে চান?",
    doYouWantToCancelOrder: "আপনি কি এই আদেশ বাতিল করতে চান?",
    orderDetails: "আদেশ বিবরণী",
    orderCreatedAt: "অর্ডার তৈরি করা হয়েছে",
    supplier: "সরবরাহকারী",
    productName: "পণ্যের নাম",
    quantity: "পরিমাণ",
    unitPrice: "একক দাম",
    receivedBy: "গ্রহণকারী",
    reportFrom: "থেকে রিপোর্ট",
    totalSupplies: "মোট সরবরাহ",
    allRightsReserved: "সমস্ত অধিকার সংরক্ষিত",
    toDeleteThisTransaction: "এই লেনদেন মুছে ফেলার জন্য",
    transactionDeletedSuccessfully:
      "লেনদেন সফলভাবে মুছে ফেলা হয়েছে। স্টক ইনভেন্টরিতে ফেরত দেওয়া হয়েছে।",
    deleteTransaction: "লেনদেন মুছুন",
    transactionDetails: "লেনদেন বিবরণী",
    printReceipt: "প্রিন্ট রসিদ",
    channel: "চ্যানেল",
    discount: "ছাড়",
    profit: "লাভ",
    discountedSales: "ছাড়কৃত বিক্রয়",
    errorFetchingRecord: "রেকর্ড আনার ত্রুটি",
    exportTransactions: "রপ্তানি লেনদেন",
    errorFetchingSalesRecord: "রপ্তানির জন্য বিক্রয় রেকর্ড আনতে ত্রুটি।",
    totalSellingPrice: "মোট বিক্রয় মূল্য",
    totalCostPrice: "মোট খরচ মূল্য",
    appliedDiscount: "ফলিত ডিসকাউন্ট",
    noOfItems: "আইটেমের সংখ্যা",
    sales: "বিক্রয়",
    export: "রপ্তানি",
    totalProfit: "সমস্ত লাভ",
    totalBalanceInPeriod: "পিরিয়ডে মোট ব্যালেন্স",
    allTimeSales: "অল টাইম সেলস",
    records: "রেকর্ড",
    todaysSales: "আজকের বিক্রয়",
    transaction: "লেনদেন",
    youHaveExceededTotalNumberOfProducts:
      "আপনি আপনার পরিকল্পনায় অনুমোদিত মোট পণ্যের সংখ্যা অতিক্রম করেছেন। একটি উচ্চতর সীমা উপভোগ করার জন্য আপনার পরিকল্পনা আপগ্রেড করুন।",
    youNeedToHaveLoyaltyProgram:
      "এই বৈশিষ্ট্যটি ব্যবহার করার জন্য আপনার একটি আনুগত্য প্রোগ্রাম থাকতে হবে!",
    price: "দাম",
    category: "বিভাগ",
    stockTracking: "স্টক ট্র্যাকিং",
    stockCount: "মজুত গণনা",
    taxed: "কর",
    originalPrice: "মূল দাম",
    costPrice: "কেনা দাম",
    unit: "ইউনিট",
    productImage: "পণ্যের ছবি",
    taxRate: "করের হার",
    taxType: "কর প্রকার",
    trackInventory: "ট্র্যাক ইনভেন্টরি",
    variants: "রূপ",
    hasVariants: "ভেরিয়েন্ট আছে",
    importProduct: "আমদানি পণ্য",
    exportProducts: "পণ্য রপ্তানি করুন",
    addNewProduct: "নতুন পণ্য যোগ করুন",
    viewCategory: "বিভাগ দেখুন",
    viewSuppliers: "সরবরাহকারী দেখুন",
    receiveInventory: "ইনভেন্টরি পান",
    printAllProductsTag: "সমস্ত পণ্য ট্যাগ মুদ্রণ করুন",
    deleteAll: "সব মুছে ফেলুন",
    totalProducts: "মোট পণ্য",
    youveSuccessfullyAddedANewCategory:
      "আপনি সফলভাবে একটি নতুন বিভাগ যোগ করেছেন",
    addNewCategory: "নতুন বিভাগ যোগ করুন",
    addCategory: "বিভাগ যোগ করুন",
    categoryName: "বিভাগ নাম",
    pleaseEnterCategoryName: "অনুগ্রহ করে বিভাগের নাম লিখুন",
    stampsTarget: "স্ট্যাম্প টার্গেট",
    sendInventory: "ইনভেন্টরি পাঠান",
    productDetails: "পণ্যের বিবরণ",
    youveSuccessfullyEditedThisCategory:
      "আপনি এই বিভাগটি সফলভাবে সম্পাদনা করেছেন",
    update: "হালনাগাদ",
    categoryList: "বিভাগ তালিকা",
    categories: "বিভাগ",
    enterQuantityToUpdate: "আপডেট করার জন্য একটি পরিমাণ লিখুন",
    inventorySentSuccessfully: "ইনভেন্টরি সফলভাবে পাঠানো হয়েছে!",
    updateInventory: "আপডেট ইনভেন্টরি",
    currentQuantity: "বর্তমান পরিমাণ",
    pleaseEnterQuantityToAdd: "আপনি যে পরিমাণ যোগ করতে চান তা লিখুন",
    pleaseSelectABranch: "অনুগ্রহ করে একটি শাখা নির্বাচন করুন",
    searchForBranch: "শাখার জন্য অনুসন্ধান করুন",
    youCantSendMoreThanStock: "আপনার স্টকের চেয়ে বেশি পাঠাতে পারবেন না",
    send: "পাঠান",
    pleaseEnterQuantityToSend: "আপনি যে পরিমাণ পাঠাতে চান তা লিখুন",
    productNameIsRequiredOnRow: "সারিতে পণ্যের নাম আবশ্যক",
    productCategoryIsRequiredOnRow: "পণ্যের শ্রেণী সারিতে প্রয়োজন",
    productPriceIsRequiredOnRow: "পণ্যের দাম সারিতে প্রয়োজন",
    productUnitIsRequiredOnRow: "সারিতে পণ্য ইউনিট প্রয়োজন",
    productQuantityIsRequiredOnRow: "সারিতে পণ্যের পরিমাণ প্রয়োজন",
    productVariantsRequireTracking: "পণ্য ভেরিয়েন্ট ট্র্যাকিং প্রয়োজন!",
    pleaseAddVariantClickButton:
      "অ্যাড বাটনে ক্লিক করে একটি বৈকল্পিক যোগ করুন!",
    totalVariantsMoreThanSelectedQuantity:
      "মোট বৈকল্পিকগুলি নির্বাচিত পণ্যের পরিমাণের চেয়ে বেশি, Pls বৈকল্পিক পরিমাণ হ্রাস করে",
    productEditedSuccessfully: "পণ্য সফলভাবে সম্পাদিত হয়েছে!",
    fileTooLargeLessThan4Mb:
      "ফাইলের আকার খুব বড়! ফাইলের আকার 4MB এর চেয়ে কম হওয়া উচিত।",
    suchVariantAlreadyExist: "এই ধরনের বৈকল্পিক ইতিমধ্যে বিদ্যমান",
    addVariants: "বৈকল্পিক যোগ করুন",
    editProduct: "পণ্য সম্পাদনা করুন",
    pleaseEnterProductName: "পণ্যের নাম লিখুন",
    pleaseEnterProductPrice: "পণ্যের মূল্য লিখুন",
    pleaseEnterProductOriginalPrice: "পণ্যের মূল মূল্য লিখুন",
    productDescription: "পণ্যের বর্ণনা",
    selectProductCategory: "পণ্য বিভাগ নির্বাচন করুন",
    pleaseSelectProductCategory: "অনুগ্রহ করে পণ্যের বিভাগ নির্বাচন করুন",
    productCostPrice: "পণ্য খরচ মূল্য",
    productSellingPrice: "পণ্য বিক্রয় মূল্য",
    productOriginalPrice: "পণ্যের মূল মূল্য",
    maxFileSizeAllowedIs4Mb: "সর্বোচ্চ অনুমোদিত ফাইলের আকার 4 এমবি",
    productsWithPicturesArePublished:
      "অর্ডার পাওয়ার জন্য ডিসকভারে ছবি সহ পণ্য প্রকাশ করা হয়",
    shouldThisProductBeTracked: "এই পণ্য ট্র্যাক করা উচিত?",
    pleaseSelectStockUnit: "স্টক ইউনিট নির্বাচন করুন",
    stockUnit: "স্টক ইউনিট",
    bag: "থলে",
    bottle: "বোতল",
    bunch: "গুচ্ছ",
    can: "করতে পারা",
    carton: "কার্টন",
    crate: "ক্র্যাট",
    cup: "কাপ",
    dozen: "ডোজেন",
    gigabytes: "গিগাবাইট",
    gram: "গ্রাম",
    kilogram: "কিলোগ্রাম",
    litre: "লিটার",
    pack: "প্যাক",
    pair: "জোড়া",
    pieces: "টুকরা",
    plate: "প্লেট",
    tonne: "টন (MT)",
    uNIT: "ইউনিট",
    yard: "গজ",
    pleaseEnterProductQuantity: "পণ্যের পরিমাণ লিখুন",
    productQuantity: "পণ্যের পরিমাণ",
    isThisProductTaxed: "এই পণ্যটি কি ট্যাক্সযুক্ত?",
    selectTaxType: "কর প্রকার নির্বাচন করুন",
    pleaseSelectTaxType: "দয়া করে কর প্রকার নির্বাচন করুন",
    progressive: "প্রগতিশীল",
    proportional: "সমানুপাতিক",
    pleaseEnterProductTaxRate: "পণ্য করের হার লিখুন",
    doesProductHaveVariants: "এই পণ্যের কি রূপ আছে?",
    type: "প্রকার",
    value: "মান",
    pleaseEnterVariantType: "ভেরিয়েন্টের ধরন লিখুন",
    pleaseEnterVariantValue: "দয়া করে ভেরিয়েন্টের মান লিখুন",
    pleaseEnterVariantPrice: "দয়া করে ভেরিয়েন্টের দাম লিখুন",
    pleaseEnterVariantQuantity: "দয়া করে ভেরিয়েন্টের পরিমাণ লিখুন",
    productDeletedSuccessfully: "পণ্য সফলভাবে মুছে ফেলা হয়েছে!",
    categoryDeletedSuccessfully: "বিভাগ সফলভাবে মুছে ফেলা হয়েছে!",
    toDeleteThisProduct: "এই পণ্য মুছে ফেলার জন্য",
    invalidProductQuantity: "অবৈধ পণ্যের পরিমাণ",
    quantityAddedIsOverStock:
      "আপনি যে পরিমাণ যোগ করছেন তা আপনার স্টকে যা আছে তার চেয়ে বেশি।",
    itemInventoryNotTracked: "আইটেম তালিকা ট্র্যাক করা হয় না",
    addBulkQuantity: "বাল্ক পরিমাণ যোগ করুন",
    enterBulkQuantity: "বাল্ক পরিমাণ লিখুন",
    pleaseEnterBulkQuantity: "দয়া করে বাল্ক পরিমাণ লিখুন",
    youveSuccessfullyAddedANewProduct:
      "আপনি সফলভাবে একটি নতুন পণ্য যোগ করেছেন!",
    pleaseEnterProductSellingPrice: "পণ্য বিক্রয় মূল্য লিখুন",
    doYouWantToTrackProductStock: "আপনি কি পণ্য স্টক ট্র্যাক করতে চান?",
    sellingPrice: "বিক্রয় মূল্য",
    setProductExpiryReminder: "পণ্যের মেয়াদ শেষ হওয়ার রিমাইন্ডার সেট করুন",
    productExpiryDate: "পণ্যের মেয়াদ শেষ হওয়ার তারিখ",
    startRemindingFrom: "থেকে মনে করিয়ে দেওয়া শুরু করুন",
    productSuppliesAddedSuccessfully: "আপনি সফলভাবে পণ্য সরবরাহ যোগ করেছেন।",
    addProductSupplies: "পণ্য সরবরাহ যোগ করুন",
    pleaseSelectSupplier: "দয়া করে সরবরাহকারী নির্বাচন করুন",
    nameOfProduct: "পণ্যের নাম",
    pleaseSelectProduct: "পণ্য নির্বাচন করুন",
    productVariant: "পণ্য বৈকল্পিক",
    pleaseSelectAVariant: "দয়া করে একটি বৈকল্পিক নির্বাচন করুন",
    pleaseEnterUnitPrice: "দয়া করে ইউনিটের মূল্য লিখুন",
    businessBranch: "ব্যবসায় শাখা",
    pleaseSelectBranch: "অনুগ্রহ করে শাখা নির্বাচন করুন",
    youveSuccessfullyAddedANewSupplier:
      "আপনি সফলভাবে একটি নতুন সরবরাহকারী যোগ করেছেন",
    addSupplier: "সরবরাহকারী যোগ করুন",
    pleaseEnterSupplierEmail: "দয়া করে সরবরাহকারীর ইমেল লিখুন",
    pleaseAddADescription: "একটি বিবরণ যোগ করুন",
    anErrorOccuredProductsDeleted:
      "অপারেশন করার সময় একটি ত্রুটি ঘটেছে। অনুগ্রহ করে নোট করুন, প্রক্রিয়ায় কিছু পণ্য মুছে ফেলা হতে পারে",
    bulkDelete: "বাল্ক ডিলিট",
    youAreAboutToDelete: "আপনি মুছে ফেলতে চলেছেন",
    product: "পণ্য",
    isDueToRedeem: "খালাসের কারণে",
    aReward: "একটি পুরস্কার",
    pleaseSelectCustomerToReeemReward:
      "পুরস্কার খালাস করার জন্য অনুগ্রহ করে একজন গ্রাহক নির্বাচন করুন।",
    youHaveNoLoyaltyProgramRunning:
      "আপনার কোন সক্রিয় আনুগত্য প্রোগ্রাম চলছে না",
    customerHhasNoPointsInLoyaltyProgram:
      "এই আনুগত্য প্রোগ্রামে গ্রাহকের কোন পয়েন্ট নেই",
    proceedWithPayment: "পেমেন্ট নিয়ে এগিয়ে যান?",
    youAreAboutToPayForTransactionUsingPoints:
      "আপনি পয়েন্ট ব্যবহার করে লেনদেনের জন্য অর্থ প্রদান করতে চলেছেন।",
    customerHas: "গ্রাহক আছে",
    worth: "মূল্য",
    andIsNotSufficientToPayForTransaction:
      "এবং এই লেনদেনের জন্য অর্থ প্রদানের জন্য যথেষ্ট নয়। তারা কি অন্য পেমেন্ট পদ্ধতি ব্যবহার করে ব্যালেন্স যোগ করতে চান?",
    addCustomerLoyalty: "গ্রাহকের আনুগত্য যোগ করুন",
    pleaseAddCustomerFirst:
      "দয়া করে প্রথমে একজন গ্রাহক যোগ করুন বা নির্বাচন করুন।",
    pleaseWaitWhileWeGetReady: "দয়া করে অপেক্ষা করুন, যখন আমরা প্রস্তুত হচ্ছি",
    lowStock: "স্বল্প জমা",
    pleaseEnterAmountTendered: "দরপত্রের পরিমাণ লিখুন",
    areYouSureToBookSaleOffline: "আপনি কি এই বিক্রয়টি অফলাইনে বুক করতে চান?",
    saleWouldBeBookedAutomatically:
      "যখন আপনি আপনার ইন্টারনেট চালু করবেন তখন বিক্রয় স্বয়ংক্রিয়ভাবে বুক করা হবে",
    offlineSalesBookingCancelled: "অফলাইন বিক্রয় বুকিং বাতিল",
    covid19Message:
      "কোভিড ১:: সাবান দিয়ে হাত ধুয়ে নিন বা ছড়িয়ে পড়া বন্ধ করতে স্যানিটাইজ করুন। সর্বদা নিরাপদ থাকুন",
    saleSuccessfullyRecorded: "বিক্রয় সফলভাবে রেকর্ড করা হয়েছে!",
    sendReceiptToEmail: "ইমেইলে রসিদ পাঠান",
    sendThankYouSms: "ধন্যবাদ এসএমএস পাঠান",
    sendShippingDetails: "শিপিংয়ের বিবরণ পাঠান",
    addLoyalty: "আনুগত্য যোগ করুন",
    searchCustomerNameOrNumber: "গ্রাহকের নাম বা নম্বর খুঁজুন",
    clickTheSearchCustomerBox:
      "অনুসন্ধান গ্রাহক বাক্স এবং স্ক্যান কার্ডে ক্লিক করুন",
    enterProductPrice: "পণ্যের মূল্য লিখুন",
    enterPriceFor: "জন্য মূল্য লিখুন",
    searchForProduct: "পণ্য অনুসন্ধান করুন",
    all: "সব",
    backToDashboard: "ড্যাশবোর্ডে ফিরে যান",
    viewDraftSales: "খসড়া বিক্রয় দেখুন",
    variantSelected: "বৈকল্পিক নির্বাচিত",
    variant: "বৈকল্পিক",
    thePreviousVariantSelectionNotAvailable:
      "দামের উপর ভিত্তি করে নির্বাচিত নতুন বৈকল্পিকের জন্য পূর্ববর্তী বৈকল্পিক নির্বাচন উপলব্ধ নয়, দয়া করে আপনার নির্বাচন পরিবর্তন করুন।",
    pleaseSupplyPositiveQuantityNumber:
      "অনুগ্রহ করে একটি পোস্টিভ পরিমাণ সংখ্যা প্রদান করুন",
    lowStockFor: "জন্য কম স্টক",
    clearVariants: "স্পষ্ট রূপ",
    pleaseEnterQuantity: "দয়া করে পরিমাণ লিখুন",
    picture: "ছবি",
    redemptionToken: "খালাস টোকেন",
    token: "টোকেন",
    totalSpent: "মোট খরচ",
    confirmPayment: "পেমেন্ট নিশ্চিত করুন",
    hasPaymentBeenMade: "পেমেন্ট কি সফলভাবে প্রক্রিয়া করা হয়েছে?",
    enterTransactionReference:
      "আপনি যে লেনদেনের রেফারেন্স দিয়ে পেমেন্ট করেছেন তা লিখুন",
    pleaseSelectACustomer: "দয়া করে একজন গ্রাহক নির্বাচন করুন!",
    areYouSureToApplyDiscount: "আপনি কি ডিসকাউন্ট প্রয়োগ করতে চান?",
    addYourBankAccountToEnableUssd:
      "ইউপে দ্বারা তাত্ক্ষণিক ইউএসএসডি স্থানান্তর সক্ষম করতে আপনার ব্যাঙ্ক অ্যাকাউন্ট যুক্ত করুন",
    totalAmountToPay: "প্রদানের মোট পরিমাণ",
    doYouWantToCancelTransaction: "আপনি কি এই লেনদেন বাতিল করতে চান?",
    savePrintBill: "বিল সংরক্ষণ/মুদ্রণ করুন",
    enterAmountCollectedForCustomer: "গ্রাহকের জন্য সংগৃহীত পরিমাণ লিখুন",
    recordSale: "রেকর্ড বিক্রয়",
    checkOutWith: "সঙ্গে চেক আউট",
    instantTransfer: "তাত্ক্ষণিক স্থানান্তর",
    dialTheUSSDCode: "ইউএসএসডি কোড ডায়াল করুন",
    pleaseSelectABank: "দয়া করে একটি ব্যাংক নির্বাচন করুন",
    payWithUSSD: "ইউএসএসডি দিয়ে অর্থ প্রদান করুন",
    sendBillTo: " -কে বিল পাঠান",
    waitingForUSSDTransfer: "ইউএসএসডি ট্রান্সফারের অপেক্ষায়",
    percent: "শতাংশ",
    applyDiscount: "ছাড় প্রয়োগ করুন",
    thisBillHasBeenSaved: "এই বিলটি সংরক্ষণ করা হয়েছে",
    saveDraft: "খসড়া সংরক্ষণ",
    pleaseTypeANameToIdentifyCustomer:
      "গ্রাহক শনাক্ত করতে দয়া করে একটি নাম লিখুন",
    paymentDetails: "পেমেন্ট বিবরণ",
    basePrice: "মুলদাম",
    staff: "কর্মী",
    subTotal: "উপ -মোট",
    durationMonths: "সময়কাল (মাস)",
    selectADuration: "একটি সময়কাল নির্বাচন করুন",
    oneMonth: "1 মাস",
    twoMonths: "2 মাস",
    threeMonths: "3 মাস",
    sixMonths: "6 মাস",
    twelveMonths: "1 ২ মাস",
    eighteenMonths: "18 মাস",
    twentyFourMonths: "24 মাস",
    twoMonthsFree: "(2 মাস বিনামূল্যে)",
    threeMonthsFree: "(3 মাস বিনামূল্যে)",
    fiveMonthsFree: "(5 মাস বিনামূল্যে)",
    yourAccountHasBeen: "আপনার অ্যাকাউন্ট হয়েছে",
    renewed: "পুনর্নবীকরণ",
    upgraded: "আপগ্রেড",
    successfully: "সফলভাবে",
    yourSubscription: "আপনার সাবস্ক্রিপশন",
    youAreCurrentlyEnrolledOnA: "আপনি বর্তমানে একটি এ নথিভুক্ত",
    pleaseChooseAPaymentOption: "একটি পেমেন্ট বিকল্প চয়ন করুন",
    planRenewal: "পুনর্নবীকরণের পরিকল্পনা করুন",
    planUpgrade: "আপগ্রেড করার পরিকল্পনা করুন",
    pleaseSelectDurationToPayFor:
      "আপনি যে মেয়াদে অর্থ প্রদান করতে চান তা নির্বাচন করুন",
    staffAccounts: "কর্মীদের হিসাব",
    ecommerce: "ইকমার্স",
    pleaseSelectAPlan: "দয়া করে একটি পরিকল্পনা নির্বাচন করুন",
    includeAddons: "অ্যাড-অন অন্তর্ভুক্ত করুন",
    viewTransactions: "লেনদেন দেখুন",
    customerHasNoCompletedTansactions:
      "গ্রাহকের এখনও কোনো সম্পূর্ণ টানসেকশন হয়নি",
    branch: "শাখা",
    enterNumberOfEcommerceBranches: "ইকমার্স শাখার সংখ্যা লিখুন",
    enterNumberOfEcommerceBranchesToPay:
      "ইকমার্স শাখার সংখ্যা লিখুন যার জন্য আপনি অর্থ প্রদান করতে চান",
    ecommerceIntegration: "ইকমার্স ইন্টিগ্রেশন",
    enterNumberOfBranches: "শাখার সংখ্যা লিখুন",
    enterNumberOfAdditionalBranchesToPay:
      "অতিরিক্ত শাখার সংখ্যা লিখুন যার জন্য আপনি অর্থ প্রদান করতে চান",
    enterNumberOfStaffs: "কর্মীদের সংখ্যা লিখুন",
    enterNumberOfStaffsToPayFor:
      "কর্মীদের সংখ্যা লিখুন যার জন্য আপনি অর্থ প্রদান করতে চান",
    discountApplies: "ডিসকাউন্ট প্রযোজ্য",
    starsOnThe: "তারকা",
    offer: "অফার",
    get: "পাওয়া",
    moreStarsToRedeem: "খালি করার জন্য আরো তারকা",
    taxVat: "কর (ভ্যাট)",
    callCashierToCompletePayment: "পেমেন্ট সম্পূর্ণ করতে ক্যাশিয়ারকে কল করুন",
    receipt: "প্রাপ্তি",
    dear: "প্রিয়",
    thankYouForYourOrderFindGoods:
      "আপনার অর্ডারের জন্য ধন্যবাদ সম্মতি অনুযায়ী অনুগ্রহ করে সরবরাহকৃত নিম্নলিখিত পণ্যগুলি খুঁজুন।",
    shippingNote: "স্থানান্তরিত করার বিবরণ",
    enterShippingNote: "শিপিং নোট লিখুন",
    shippingAddress: "প্রেরণের ঠিকানা",
    enterShippingAddress: "শিপিং ঠিকানা লিখুন",
    wellDoneYouAreDueToRedeem: "সাবাশ! আপনার খালাস পাওয়ার কথা",
    toGetYourRewardNextVisit: "আপনার পরবর্তী সফরে আপনার পুরস্কার পেতে। ধন্যবাদ",
    pointsOnThe: "উপর পয়েন্ট",
    morePointsToRedeem: "খালাস করার জন্য আরো পয়েন্ট",
    showCode: "কোড দেখান",
    toGetYourRewardOnNextVisit:
      "আপনার পরবর্তী সফরে আপনার পুরস্কার পেতে। ধন্যবাদ",
    earn: "উপার্জন করুন",
    delivaryNote: "ডেলিভারি নোট",
    draftSales: "খসড়া বিক্রয়",
    startDate: "শুরুর তারিখ",
    endDate: "শেষ তারিখ",
    orders: "আদেশ",
    checkout: "চেকআউট",
    noProductItem: "কোন পণ্যের আইটেম নেই",
    selectProductImage: "পণ্য চিত্র নির্বাচন করুন",
    selectCountry: "দেশ নির্বাচন করুন",
    selectRegion: "রাজ্য/অঞ্চল নির্বাচন করুন",
    printProductTag: "প্রিন্ট প্রোডাক্ট ট্যাগ",
    transactionReference: "লেনদেন সূত্র",
    Cashier: "কোষাধ্যক্ষ",
    Manager: "ম্যানেজার",
    Owner: "মালিক",
    Admin: "অ্যাডমিন",
  },
  Bosnian: {
    cashier: "blagajnik",
    manager: "menadžer",
    owner: "vlasnik",
    online: "online",
    offline: "van mreže",
    changePassword: "Change Password",
    currentPasswordMessage: "Molimo unesite svoju trenutnu lozinku",
    passwordMessage: "Molimo unesite svoju lozinku",
    currentPassword: "trenutna šifra",
    password: "lozinka",
    confirmPasswordMessage: "Molimo potvrdite lozinku!",
    confirmPassword: "Potvrdi šifru",
    sendViaEmail: "Slanje putem e -pošte",
    sendViaWhatsapp: "Slanje putem WhatsAppa",
    downloadPdf: "Preuzmite PDF",
    paid: "plaćeno",
    unpaid: "neplaćeno",
    partial: "djelomičan",
    closeInvoice: "Želite li zatvoriti fakturu?",
    closeInvoiceConfirmation:
      "Faktura se možda neće sačuvati. Želite li zatvoriti?",
    yes: "da",
    no: "no",
    invoice: "Faktura",
    wasDeducted: "je odbijen",
    for: "for",
    item: "Stavka",
    addProduct: "Dodajte proizvod",
    paymentReference: "Referenca plaćanja",
    amountPaid: "Iznos plaćen",
    discountAmount: "Iznos popusta",
    amountDue: "Dospjeli iznos",
    amount: "Iznos",
    dueDate: "Datum dospijeća",
    paymentType: "Vrsta plaćanja",
    card: "Kartica",
    cash: "Gotovinom",
    bankTransfer: "Bankovni transfer",
    paymentMessage: "Poruka o plaćanju",
    description: "Opis",
    sendReceipt: "Send Receipt",
    delete: "Izbriši",
    save: "Save",
    resend: "Ponovo pošalji",
    saveAndSend: "Save",
    invoiceSaved: "Račun sačuvan!",
    selectPaymentMethod: "Molimo odaberite način plaćanja!",
    selectCustomer: "Molimo odaberite klijenta!",
    cartEmptyError:
      "Lista korpi ne može biti prazna, zatvorite račun i dodajte artikl u korpu!",
    subscriptionExpired:
      "Vaša pretplata je istekla i vaš račun je sada ograničen. Kliknite na dugme ispod da biste obnovili račun",
    renew: "Obnoviti",
    holdOn: "Čekaj",
    customerBank: "Banka klijenata",
    cancel: "Cancel",
    selectACustomer: "Odaberite kupac",
    invoiceSuccessfulPdfError:
      "Račun je uspješno kreiran, ali generiranje PDF -a traje duže nego inače. Vratite se uskoro.",
    downloadingInvoice: "Preuzimanje fakture",
    downloadingReceipt: "Preuzimanje potvrde",
    whatsappReceiptError:
      "Došlo je do greške pri slanju računa putem WhatsAppa, pokušajte ponovo.",
    receiptToWhatsapp: "Račun je proslijeđen na WhatsApp",
    thankYouForPatronage: "Hvala vam na pokroviteljstvu",
    hereIsYourReceipt: "Evo vaše potvrde o uplati",
    errorSendingEmailReceipt:
      "Došlo je do greške pri slanju računa putem e-pošte, pokušajte ponovo ili kontaktirajte podršku",
    receiptSentToEmail: "Račun je poslan na e -mail korisnika",
    invoiceSentToEmail: "Račun je poslan na e -mail korisnika",
    invoiceSuccessWhatsappError:
      "Račun je uspješno kreiran, ali došlo je do greške prilikom slanja na WhatsApp. Pokušajte ponovo na listi računa",
    invoiceSuccessfulEmailError:
      "Račun je uspješno kreiran, ali je došlo do greške prilikom slanja putem e-pošte. Pokušajte ponovo sa liste faktura",
    invoiceSentToWhatsapp: "Faktura proslijeđena na WhatsApp",
    hello: "zdravo",
    pleaseDownloadInvoice: "Molimo preuzmite fakturu",
    pleaseDownloadReceipt: "Molimo preuzmite priznanicu",
    from: "from",
    email: "Email",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Nalazite se na besplatnom planu.",
    clickOn: "Kliknite na",
    yourPlanInFewSteps: " svoju pretplatu u nekoliko brzih koraka.",
    to: "to",
    yourSubscriptionHasExpired:
      "Vaša pretplata je istekla i vaš račun je sada ograničen.",
    days: "dana",
    yourSubscriptionExpiresIn: "Vaša pretplata na Loystar ističe za",
    createAcount: "Kreirajte račun",
    signIn: "Prijavite se",
    continue: "Nastavi",
    enterOtp: "Unesite OTP PIN",
    enterValidOtp: "Unesite važeći PIN",
    pin: "PIN",
    tokenSentToMail: "Token je poslan na vašu e -poštu",
    passwordResetSuccessful: "Poništavanje lozinke je uspjelo",
    somethingWentWrong: "Nešto nije u redu!",
    resetPassword: "Reset lozinke",
    iHaveResetCode: "Imam kôd za poništavanje lozinke",
    pleaseEnterEmail: "Molimo unesite svoju adresu e -pošte",
    aTokenWillBeSentToEmail: "Token će biti poslan na vašu e -poštu",
    enterEmail: "Unesite svoju adresu e -pošte",
    sendinYourToken: "Slanje vašeg tokena ...",
    makeSureItMatchesPassword: "Uvjerite se da se podudara s novom lozinkom",
    pleaseChooseNewPassword: "Molimo odaberite novu lozinku",
    chooseNewPassword: "Odaberite novu lozinku",
    enterNewPassword: "Unesite novu lozinku za potvrdu",
    enterTokenSent: "Unesite token koji vam je poslao poštu",
    resetToken: "Reset Token",
    resettingPassword: "Ponovno postavljanje lozinke ...",
    signUp: "Prijaviti se",
    adminSignInWithEmail:
      " Administrator se prijavljuje e -poštom, dok se osoblje prijavljuje korisničkim imenom.",
    pleaseEnterEmailOrUsername:
      "Molimo unesite svoju adresu e -pošte ili korisničko ime",
    emailOrUsername: "E-mail ili korisničko ime",
    pleaseEnterPassword: "Molimo unesite lozinku",
    createAnAccount: "Kreirajte račun",
    forgotPassword: "Zaboravili ste lozinku?",
    enterPhoneNumber: "Unesite telefonski broj",
    personalData: "Lični podaci",
    validateConfirmationCOde: "Potvrdite kôd za potvrdu",
    pleaseEnterFirstName: "Molimo unesite svoje ime",
    pleaseEnterPhoneNumber: "Molimo unesite svoj broj telefona",
    pleaseEnterLastName: "Molimo unesite svoje prezime",
    pleaseEnterBusinessName: "Molimo unesite naziv vašeg preduzeća",
    firstName: "Ime",
    lastName: "Prezime",
    businessName: "Naziv preduzeća",
    previous: "Prethodno",
    next: "Sljedeći",
    pleaseSelectBusinessCategory:
      "Molimo odaberite svoju kategoriju poslovanja",
    pleaseEnterValidEmail: "Molimo unesite važeću adresu e -pošte",
    pleaseEnterPostCode: "Molimo unesite poštanski broj",
    postCode: "Poštanski broj",
    phoneNumberInUse: "Ovaj broj telefona je već u upotrebi!",
    emailInUse: "Ova adresa e -pošte je već u upotrebi!",
    foodAndDrinks: "Hrana i pice",
    salonAndBeauty: "Salon i ljepota",
    fashionAndAccessories: "Moda i dodaci",
    gymAndFitness: "Teretana i fitnes",
    travelAndHotel: "Putovanja i hotel",
    homeAndGifts: "Dom i pokloni",
    washingAndCleaning: "Pranje i čišćenje",
    gadgetsAndElectronics: "Gadgeti i elektronika",
    groceries: "Namirnice",
    others: "Drugi",
    submit: "Submit",
    accountCreatedSuccessful: "Vaš račun je uspješno kreiran.",
    pleaseEnterAddress: "Molimo unesite svoju adresu",
    addressLine1: "Adresa Line 1",
    addressLine2: "2. red adrese",
    choosePassword: "Odabrati lozinku",
    passwordMustBe6Characters: "Lozinka mora imati najmanje 6 znakova.",
    howDidYouHearLoystar: "Kako ste čuli za Loystar?",
    referralCode: "uputni kod",
    byClickingTheButton: " Klikom na dugme ispod pristajete na",
    termsAndSevice: "Uslovi",
    wereCreatingAccount: "Kreiramo vaš račun",
    proceed: "Nastavite",
    verificationCodeMustBe6: "Verifikacijski kôd mora imati 6 znamenki",
    pleaseEnter6DigitCode: "Molimo unesite šestocifreni kod",
    enterVerificationCode: "Unesite verifikacijski kod",
    resendToken: "Ponovo pošaljite token",
    verify: "Verify",
    transactions: "Transakcije",
    todaySales: "Današnja prodaja",
    salesHistory: "Istorija prodaje",
    supplyHistory: "Istorija nabavke",
    new: "Novo",
    invoices: "Fakture",
    disbursements: "Isplate",
    offlineSales: "Offline prodaja",
    products: "Proizvodi",
    customers: "Kupci",
    multiLevelLoyalty: "Vjernost na više nivoa",
    loyaltyPrograms: "Programi lojalnosti",
    members: "Članovi",
    appStore: "Prodavnica aplikacija",
    orderMenu: "Meni za naručivanje",
    settings: "Postavke",
    staffAndBranches: "Osoblje i podružnice",
    myAccount: "Moj račun",
    switchToSellMode: "Prebacite se u način prodaje",
    signOut: "Odjaviti se",
    getFreeSubscription: "Nabavite besplatnu pretplatu",
    onlyNumbersAllowed: "Dozvoljeni su samo brojevi",
    yourAccountMustBe10Digits:
      "broj vašeg računa mora biti 10 -znamenkasti broj",
    yourBvnMustBe11: "vaš BVN mora biti 11 -znamenkasti broj",
    pleaseSelectBank: "Molimo odaberite svoju banku",
    selectYourBank: "Odaberite svoju banku",
    enterBankAccountNumber: "Unesite broj bankovnog računa",
    enterBvnNumber: "Unesite svoj BVN",
    connectBankAccount: "Povežite bankovni račun",
    passwordResetSuccessfulAndSignOut:
      "Vaša lozinka je uspješno poništena. Kliknite na dugme Odjava ispod da se ponovo prijavite",
    enterCurrentPassword: "Unesite trenutnu lozinku",
    pleaseEnterCurrentPassword: "Molimo unesite trenutnu lozinku",
    phoneNumber: "Telefonski broj",
    sex: "Sex",
    dateOfBirth: "Datum rođenja",
    state: "State",
    country: "Country",
    loyaltyId: "ID lojalnosti",
    createdAt: "Created at",
    noOfTransactionsMade: "Broj izvršenih transakcija",
    yourDownloadWillStart: "Preuzimanje će početi za trenutak",
    exportCustomers: "Izvozni kupci",
    youhaveSuccessfullyToppedUp: "Uspješno ste dopunili svoje SMS jedinice.",
    youNowHave: "Sada imate",
    smsUnits: "SMS jedinice",
    enterNumberOfSmsUnits: "Unesite broj SMS jedinica koje namjeravate kupiti",
    pleaseEnterANumericValue: "Molimo unesite brojčanu vrijednost",
    pay: "Plati",
    accountEditedSuccessfully: "Vaš račun je uspješno uređen.",
    youAeCurrentlyOn: "Trenutno ste uključeni",
    plan: "Plan",
    userData: "Korisnički podaci",
    businessData: "POSLOVNI PODACI",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Molimo odaberite svoju valutu",
    selectYourCurrency: "Odaberite svoju valutu",
    purchaseMoreSmsUnits: "Kupite više SMS jedinica koristeći donji obrazac",
    youHave: "Ti imaš",
    atLeast4SmsUnitsRrequired:
      "Za verifikaciju su potrebne najmanje 4 sms jedinice, molimo dopunite!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Molimo vas da potvrdite svoj bankovni račun u skladu sa zahtjevima „Upoznajte svog klijenta“ (KYC). To će vam omogućiti prikupljanje plaćanja putem USSD -a ili trenutni prijenos, primanje naloga od korisnika i obradu namirenja transakcija. Odnosi se samo na nigerijske trgovce. Molimo pritisnite donje dugme za početak.",
    reConnectBankAccount: "Ponovo povežite bankovni račun",
    accountName: "Ime računa",
    accountNumber: "Broj računa",
    bankName: "Ime banke",
    verified: "Verified",
    accountDetails: "Detalji računa",
    kycBankAccount: "KYC",
    createTier: "Create Tier",
    fileUploadSuccessful: "datoteka uspješno otpremljena",
    fileUploadFailed: "otpremanje datoteke nije uspjelo",
    createLoyaltyProgram: "Kreirajte program vjernosti",
    createLoyalty: "Stvorite lojalnost",
    name: "Ime",
    loyaltyArtwork: "Loyalty Artwork",
    clickToUpload: "Kliknite za učitavanje",
    amountToPointsRatio: "Odnos iznosa poena",
    points: "Poeni",
    recommendedAmountToPointRatio:
      "Preporučuje se: ₦ 1 do 1 poen. odnosno za svaku potrošenu 1 Nairu vaši klijenti dobivaju 1 bod",
    pleaseTypeIn: "Molimo upišite",
    toDeleteLoyalty: "za brisanje ove lojalnosti",
    deleteLoyalty: "Izbriši lojalnost",
    toConfirm: "da potvrdite",
    edit: "Uredi",
    pointsAwardedSuccessfully: "Bodovi su uspješno dodijeljeni.",
    enterPointValueToAward:
      "Unesite vrijednost boda koju želite nagraditi kupcima",
    award: "Nagrada",
    awardPointValuesToCustomer: "Dodijelite vrijednost bodova kupcu",
    enrollMembersToLoyalty: "Učlanite članove u Lojalnost",
    awardPoints: "Nagradni bodovi",
    enroll: "Upišite se",
    home: "Dom",
    loyalty: "Lojalnost",
    enrollCustomers: "Upišite kupce",
    selected: "Odabrano",
    customer: "Kupac",
    loyaltyActivationSuccessful: "Aktiviranje lojalnosti je uspješno.",
    loyaltyDeactivationSuccessful: "Deaktivacija lojalnosti je uspješna.",
    viewTier: "View Tier",
    deactivate: "Deaktiviraj",
    activate: "Aktiviraj",
    actions: "Akcije",
    nameOfLoyalty: "Ime lojalnosti",
    loyaltyStatus: "Status lojalnostiStatus lojalnosti",
    numberOfTiers: "Broj slojeva",
    createdOn: "Created On",
    createATier: "Kreirajte nivo",
    stopCreatingTierConfirmation: "Želite li prestati stvarati sloj?",
    stopEditingTierConfirmation: "Želite li prestati uređivati ovaj nivo?",
    nameOfTier: "Naziv reda",
    minimumSpendingTarget: "Cilj minimalne potrošnje",
    maximumSpendingTarget: "Cilj maksimalne potrošnje",
    minimumSpendingTargetRequired: "potreban je minimalni cilj potrošnje",
    maximumSpendingTargetRequired: "potreban je maksimalni cilj potrošnje",
    rewardSponsor: "Sponzor nagrade",
    pleaseChooseARewardSponsor: "Molimo vas da odaberete sponzora nagrade",
    self: "Self",
    partner: "Partner",
    rewardPartner: "Nagradni partner",
    pleaseSelectRewardPartner: "Molimo odaberite svog partnera za nagradu",
    rewards: "Nagrade",
    pleaseSelectAReward: "Odaberite nagradu",
    instructionsOnRedeemingReward:
      "Upute o tome kako kupac treba iskoristiti nagradu",
    pleaseFillInThisField: "Molimo popunite ovo polje!",
    toDeleteThisTier: " za brisanje ovog sloja",
    deleteTier: "Izbriši nivo",
    viewMembers: "View Members",
    membersEnrolled: "Upisani članovi",
    instruction: "Uputstvo",
    membersIn: "Članovi u",
    availableTiersInLoyalty: "Dostupni nivoi u programu vjernosti",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Dostupan program lojalnosti",
    totalLoyalties: "UKUPNA LOJALNOST",
    memberDetails: "Detalji o članu",
    nameOfCustomer: "Ime kupca",
    address: "Adresa",
    allEnrolledMembers: "Svi upisani članovi",
    thisIsToWishYouHappyBirthday:
      "Ovim putem želim vam sretan rođendan i uspješan život. Hvala ti za sve što si Loyster. Srećne proslave!",
    inputAnOfferPlease: "Molimo unesite ponudu",
    pleaseSelectTheInsertPoint:
      "Molimo odaberite tačku umetanja u poruku i kliknite ponovo",
    birthdayOfferAndMessage: "Rođendanska ponuda i poruka",
    birthdayOffer: "Rođendanska ponuda",
    birthdayMessage: "Rođendanska poruka",
    noOfferFound: "Nije pronađena ponuda",
    settingABirthdayOffer:
      "Postavljanje rođendanske ponude omogućava korisnicima da ovu ponudu dobiju putem SMS -a na svoj rođendan",
    createOffer: "Kreiraj ponudu",
    whatIsTheOffer: "Koja je ponuda?",
    editMessage: "Uredi poruku",
    insert: "Insert",
    theNameOfCustomerInserted: "Ovdje će biti umetnuto ime kupca",
    theBirtdayOfferInserted: "Ovdje će biti umetnuta rođendanska ponuda",
    youSuccessfullyAddedNewBranch: "Uspješno ste dodali novu granu!",
    addNewBranch: "Dodaj novu granu",
    addBranch: "Dodaj granu",
    additionalBranchWillIncur: "Dodatna podružnica bi nastala",
    perBranch: "po grani",
    ecommerceBranchCosts: "Troškovi podružnice e -trgovine",
    pleaseEnterBranchName: "Molimo unesite naziv podružnice",
    pleaseEnterBranchAddress1: "Molimo unesite 1. red adrese podružnice",
    enterBranchAddress1: "Unesite adresni red podružnice 1",
    enterBranchAddress2: "Unesite adresni red podružnice 1",
    pleaseEnterBranchAddress2: "Molimo unesite 2. red adrese podružnice",
    enterBranchName: "Unesite naziv podružnice",
    successfullyAddedStaff: "Uspješno ste dodali novo osoblje!",
    addNewStaff: "Dodajte novo osoblje",
    addStaff: "Dodajte osoblje",
    additionalStaffWillIncur: "Dodatno osoblje bi nastalo",
    perStaff: "po osoblju.",
    pleaseEnterStaffEmail: "Molimo unesite e -poštu osoblja",
    pleaseEnterStaffUsername: "Molimo unesite korisničko ime osoblja",
    pleaseEnterStaffPassword: "Molimo unesite lozinku osoblja",
    pleaseSelectStaffRole: "Molimo vas da odaberete ulogu osoblja",
    selectStaffRole: "Odaberite ulogu osoblja",
    enterStaffEmail: "Unesite e -poštu osoblja",
    enterStaffUsername: "Unesite korisničko ime osoblja",
    enterStaffPassword: "Unesite lozinku osoblja",
    spacesNotAllowedInUsername: "razmaci nisu dozvoljeni u korisničkom imenu",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Molimo da odaberete preduzeće za pridruživanje osoblja",
    searchForBranchToAttachStaff:
      "Potražite podružnicu za pridruživanje osoblja",
    username: "Korisničko ime",
    role: "Uloga",
    areYouSureToDeleteThis: "Jeste li sigurni da ćete ovo izbrisati",
    branches: "Podružnice",
    upgradeYourPlan: "Nadogradite svoj plan.",
    add: "DODATI",
    addNew: "Dodaj novi",
    customerWithEmailAlreadyExists:
      "Klijent sa e -poštom/telefonskim brojem već postoji!",
    successfullyAddedNewCustomer: "Uspješno ste dodali novog kupca!",
    addCustomer: "Dodajte kupca",
    pleaseEnterCustomerFirstName: "Molimo unesite ime kupca",
    pleaseEnterCustomerLastName: "Molimo unesite prezime kupca",
    pleaseEnterCustomerPhoneNumber: "Molimo unesite telefonski broj korisnika",
    pleaseEnterCustomerEmail: "Molimo unesite e -mail klijenta",
    pleaseEnterCustomerAddressLine: "Molimo unesite adresu klijenta",
    pleaseEnterCustomerOtherAddress: "Molimo unesite drugu adresu kupca",
    pleaseSelectCustomerGender: "Odaberite spol kupca",
    gender: "Spol",
    male: "Muško",
    female: "Ženski",
    bank: "Banka",
    selectBank: "Odaberite Banka",
    stateOrRegionOrProvince: "Država/Regija/Pokrajina",
    customerNotes: "Napomene kupaca",
    sendSms: "Pošaljite SMS",
    editCustomer: "Edit Customer",
    redeemReward: "Iskoristite nagradu",
    issueLoyaltyCard: "Izdajte karticu vjernosti",
    deleteCustomer: "Izbriši kupca",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Uspješno ste dodijelili ID člana lojalnosti",
    noMembershipIdAvailable:
      "Nema dostupnih ID -ova članstva. Molimo kontaktirajte nas na hello@loystar.co",
    sendEmail: "Pošaljite e-mail",
    membershipPoints: "Bodovi članstva",
    customerDetails: "Podaci o kupcu",
    close: "Zatvori",
    loyaltyBalance: "Bilans lojalnosti",
    pointsBalance: "Bilans bodova",
    starBalance: "Star Balance",
    requiredPoints: "Potrebni bodovi",
    requiredStars: "Obavezne zvjezdice",
    reddemCode: "Iskoristite kôd",
    toDeleteThisCustomer: "da izbrišete ovog kupca",
    customerHasBeenDeletedSuccessfully: "Klijent je uspješno izbrisan!",
    customerWithPhoneAlreadyExists: "Klijent sa brojem telefona već postoji",
    customerWasSuccessfullyEdited: "Klijent je uspješno uređen",
    anErrorOccured: "Došlo je do greške",
    phoneNumberIncludeCountry: "Broj telefona (Uključuje pozivni broj zemlje)",
    yourFileQueuedForUpload:
      "Vaša datoteka je u redu za učitavanje. Osvježite stranicu nakon nekoliko sekundi.",
    thereWasAnErrorPerformingOperation:
      "Došlo je do greške pri izvođenju operacije!",
    pleaseSelectFile: "Molimo odaberite datoteku!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Dozvoljene su samo CSV datoteke. Otpremite .csv datoteku.",
    fileShouldBeSmallerThan5Mb:
      "Datoteka bi trebala biti manja od 5 MB. Ako imate veću datoteku, pošaljite e -poruku na support@loystar.co. Hvala ti",
    customerFirstNameIsRequired: "ime klijenta je potrebno u retku",
    customerPhoneNumberIsRequired:
      "telefonski broj korisnika je potreban u redu",
    importCustomers: "Uvoz kupaca",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Kliknite na ovu ikonu za preuzimanje predloška CSV datoteke.",
    getGoogleSheetVersion: "Preuzmite verziju Google tablice",
    clickOrDragFileToUpload:
      "Kliknite ili povucite datoteku u ovo područje za učitavanje",
    missingOrInvalidColumnHeader:
      "Zaglavlje stupca nedostaje ili je nevažeće. Slijedite format predloška. Hvala ti.",
    youHaveImported: "Uvezli ste",
    youSuccessfullyRedeemedReward: "Uspješno ste iskoristili svoju nagradu!",
    sixDigitCode: "Šestocifreni kod",
    pleaseEnterCustomerRewardCode: "Molimo unesite kôd nagrade klijenta",
    enterRewardCode:
      "Unesite kôd nagrade. (Kôd nagrade razlikuje velika i mala slova)",
    selectLoyaltyProgram: "Odaberite program vjernosti",
    stamps: "Marke",
    smsUnitsLow: "SMS jedinice niske",
    pleaseTopUpSmsUnits: "Dopunite SMS jedinice",
    smsSuccessfullySentForDelivery: "SMS uspješno poslan za isporuku!",
    sendSmsTo: "Pošaljite SMS na",
    allCustomers: "Svi kupci",
    unitsAvailable: "Dostupne jedinice",
    pleaseTypeInTheMessage: "Molimo upišite poruku",
    message: "Poruka",
    charactersRemaining: "preostalih znakova",
    avoidUseOfSpecialCharacters:
      "Izbjegavajte upotrebu posebnih znakova i emotikona za očuvanje SMS jedinica.",
    note: "Bilješka",
    errorFetchingCustomersMultilevelDetail:
      "Greška pri dohvaćanju detalja o više nivoa kupaca",
    search: "Search",
    reset: "Resetovati",
    importCustomer: "Uvezi kupca",
    addNewCustomer: "Dodajte novog kupca",
    sendSmsBroadcast: "Slanje SMS prenosa",
    totalCustomers: "UKUPNO KUPCI",
    disbursementDetails: "Detalji isplate",
    paidBy: "Paid By",
    disbursed: "Isplaćeno",
    bankAccountName: "Naziv bankovnog računa",
    bankAccountNumber: "Broj računa u banci",
    transferInitiated: "Prijenos započet",
    transferCompleted: "Prenos završen",
    pleaseEnterAValid: "Molimo unesite važeći",
    begin: "početi",
    end: "kraj",
    date: "datum",
    paymentDate: "Datum plaćanja",
    selectDisbursementDuration: "Odaberite Trajanje isplate",
    totalSettled: "Ukupno riješeno",
    totalUnsettled: "Ukupno Neriješeno",
    toDeleteThisSale: "da obrišete ovu prodaju",
    draftSaleDeletedSuccessfully: "Nacrt prodaje je uspješno izbrisan!",
    deleteSale: "Izbriši Sale",
    pleaseTypeInYourTotal:
      "Molimo vas da unesete svoj ukupni zbroj za potvrdu brisanja",
    billDetails: "Detalji o računu",
    printBill: "Print Bill",
    servedBy: "Opslužuje",
    total: "Ukupno",
    createdDate: "Datum kreiranja",
    createdTime: "Created Time",
    status: "Status",
    loginSuccessful: "Prijava uspješna",
    pleaseWaitWhileWeConnectAccount:
      "Molimo pričekajte dok ne povežemo vaš račun",
    connectionFailedTryAgain: "Veza nije uspjela. Molimo pokušajte ponovo.",
    connectionSuccessful: "Povezivanje uspješno",
    viewDetails: "Detaljnije",
    enable: "Omogući",
    free: "Besplatno",
    cost: "Cost",
    visitWebsite: "Posjetite web stranicu",
    pleaseUpgradeYourPlanToPro:
      "Nadogradite svoj plan na Pro Plus da biste omogućili ovu aplikaciju",
    connectYourBankAccount:
      "Povežite svoj bankovni račun da biste mogli primati uplate.",
    disable: "Onemogući",
    enableApp: "Omogući aplikaciju",
    addNewProductToInvoice: "Dodajte novi proizvod u fakturu",
    toDeleteThisInvoice: "da obrišete ovu Račun",
    invoiceDeletedSuccessfully: "Račun je uspješno izbrisan!",
    deleteInvoice: "Izbriši fakturu",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Uspješno ste dodali poruku o plaćanju putem fakture!",
    pleaseEnterPaymentInstructions: "Molimo upišite upute za plaćanje",
    invoiceId: "ID fakture",
    paidDate: "Datum plaćanja",
    reference: "Reference",
    productAdded: "Proizvod dodan",
    productOutOfStock: "Proizvod nije na lageru. Molimo ponovo zalihe.",
    totalInvoices: "UKUPNO RAČUNI",
    totalPaidInvoices: "UKUPNO PLAĆENI RAČUNI",
    totalUnpaidInvoices: "UKUPNO NEPLAĆENE RAČUNE",
    loyaltyProgramDeleted: "Program vjernosti izbrisan",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ovaj program vjernosti je uspješno izbrisan",
    thisLoyaltyProgramEditedSuccessfully:
      "Ovaj program vjernosti je uspješno uređen",
    loyaltyProgramAddedSuccessfully: "Program vjernosti je uspješno dodan",
    loyaltyProgramEdited: "Program vjernosti uređen",
    loyaltyProgramAdded: "Program vjernosti je dodan",
    loyaltyDetails: "Detalji o lojalnosti",
    doYouWantToCloseDialog: "Želite li zatvoriti ovaj dijalog?",
    pleaseEnterLoyaltyName: "Molimo unesite naziv svoje lojalnosti",
    programType: "Vrsta programa",
    pleaseSelectProgramType: "Molimo odaberite vrstu programa",
    simplePoints: "Simple Points",
    stampsProgram: "Program za marke",
    threshold: "Prag",
    pleaseEnterLoyaltyThreshold: "Molimo unesite prag lojalnosti",
    reward: "Nagrada",
    pleaseEnterLoyaltyReward: "Molimo unesite nagradu lojalnosti",
    totalUserPoints: "Ukupan broj korisničkih poena",
    totalUserStamps: "Ukupno korisničkih pečata",
    spendingTarget: "Cilj potrošnje",
    spendingTargetHint1:
      "Cilj potrošnje je koliko kupac mora potrošiti da zaradi nagradu. 1 vrijednost valute = 1 bod.",
    spendingTargetHint2:
      "Cilj marke je koliko markica kupac mora prikupiti da bi zaradio nagradu. npr. 5",
    addNewLoyaltyProgram: "Dodajte novi program vjernosti",
    addLoyaltyProgram: "Dodajte program vjernosti",
    loyaltyProgramType: "Vrsta programa vjernosti",
    pleaseSelectLoyaltyProgramType: "Molimo odaberite vrstu programa vjernosti",
    nameOfProgram: "Naziv programa",
    pleaseEnterProgramName: "Molimo unesite naziv programa",
    whatIsTheReward: "Koja je nagrada?",
    egNextPurchaseIsFree: "Npr. Sljedeća kupovina je besplatna",
    customerName: "Ime klijenta",
    guestCustomer: "Gost kupac",
    orderConfirmedSuccessfully: "Narudžba je uspješno potvrđena",
    orderCancelledSuccessfully: "Narudžba je uspješno otkazana",
    confirmOrder: "Potvrdite narudžbu",
    cancelOrder: "Otkazati narudžbu",
    allOrders: "Sve narudžbe",
    totalOrders: "UKUPNO NARUDŽBE",
    doYouWantToAcceptOrder: "Želite li prihvatiti ovu narudžbu?",
    doYouWantToCancelOrder: "Želite li otkazati ovu narudžbu?",
    orderDetails: "Detalji narudžbe",
    orderCreatedAt: "Narudžba kreirana u",
    supplier: "Dobavljač",
    productName: "ime proizvoda",
    quantity: "Quantity",
    unitPrice: "Cijena po jedinici",
    receivedBy: "Primio",
    reportFrom: "Izveštaj od",
    totalSupplies: "Total Supplies",
    allRightsReserved: "Sva prava zadržana",
    toDeleteThisTransaction: "da biste izbrisali ovu transakciju",
    transactionDeletedSuccessfully:
      "Transakcija je uspješno izbrisana. Zalihe su vraćene u inventar.",
    deleteTransaction: "Izbriši transakciju",
    transactionDetails: "Detalji transakcije",
    printReceipt: "Print Receipt",
    channel: "Channel",
    discount: "Popust",
    profit: "Profit",
    discountedSales: "Diskontirana prodaja",
    errorFetchingRecord: "Greška pri preuzimanju zapisa",
    exportTransactions: "Izvozne transakcije",
    errorFetchingSalesRecord:
      "Greška pri preuzimanju prodajnog zapisa za izvoz.",
    totalSellingPrice: "Ukupna prodajna cijena",
    totalCostPrice: "Ukupna cena",
    appliedDiscount: "Primijenjeni popust",
    noOfItems: "Broj stavki",
    sales: "Prodaja",
    export: "Izvoz",
    totalProfit: "Ukupan profit",
    totalBalanceInPeriod: "Ukupno stanje u periodu",
    allTimeSales: "All Time Sales",
    records: "Records",
    todaysSales: "Današnja prodaja",
    transaction: "transakcija",
    youHaveExceededTotalNumberOfProducts:
      "Premašili ste ukupan broj proizvoda koji je dopušten u vašem planu. Nadogradite svoj plan kako biste uživali u većoj granici.",
    youNeedToHaveLoyaltyProgram:
      "Morate imati program vjernosti da biste koristili ovu funkciju!",
    price: "Cijena",
    category: "Kategorija",
    stockTracking: "Praćenje zaliha",
    stockCount: "Stock Count",
    taxed: "Oporezivo",
    originalPrice: "Originalna cijena",
    costPrice: "Cijena",
    unit: "Jedinica",
    productImage: "Slika proizvoda",
    taxRate: "Stopa poreza",
    taxType: "Vrsta poreza",
    trackInventory: "Inventar praćenja",
    variants: "Varijante",
    hasVariants: "Ima varijante",
    importProduct: "Uvoz proizvoda",
    exportProducts: "Izvoz proizvoda",
    addNewProduct: "Dodajte novi proizvod",
    viewCategory: "Prikaži kategoriju",
    viewSuppliers: "Pogledajte dobavljače",
    receiveInventory: "Primi inventar",
    printAllProductsTag: "Odštampajte oznaku svih proizvoda",
    deleteAll: "Izbrisati sve",
    totalProducts: "UKUPNI PROIZVODI",
    youveSuccessfullyAddedANewCategory: "Uspješno ste dodali novu kategoriju",
    addNewCategory: "Dodaj novu kategoriju",
    addCategory: "Dodaj kategoriju",
    categoryName: "Naziv kategorije",
    pleaseEnterCategoryName: "Molimo unesite naziv kategorije",
    stampsTarget: "Marke Target",
    sendInventory: "Pošalji inventar",
    productDetails: "Detalji o proizvodu",
    youveSuccessfullyEditedThisCategory: "Uspješno ste uredili ovu kategoriju",
    update: "Update",
    categoryList: "Lista kategorija",
    categories: "Kategorije",
    enterQuantityToUpdate: "Unesite količinu za ažuriranje",
    inventorySentSuccessfully: "Inventar je uspješno poslan!",
    updateInventory: "Ažuriraj inventar",
    currentQuantity: "Trenutna količina",
    pleaseEnterQuantityToAdd: "Molimo unesite količinu koju želite dodati",
    pleaseSelectABranch: "Molimo odaberite podružnicu",
    searchForBranch: "Potražite granu",
    youCantSendMoreThanStock:
      "Ne možete poslati više nego što imate na skladištu",
    send: "Pošalji",
    pleaseEnterQuantityToSend: "Molimo unesite količinu koju želite poslati",
    productNameIsRequiredOnRow: "naziv proizvoda je obavezan u redu",
    productCategoryIsRequiredOnRow: "kategorija proizvoda je obavezna u redu",
    productPriceIsRequiredOnRow: "cijena proizvoda je potrebna u redu",
    productUnitIsRequiredOnRow: "jedinica proizvoda je potrebna u redu",
    productQuantityIsRequiredOnRow: "količina proizvoda je potrebna u redu",
    productVariantsRequireTracking: "varijante proizvoda zahtijevaju praćenje!",
    pleaseAddVariantClickButton:
      "Molimo vas da dodate varijantu klikom na dugme za dodavanje!",
    totalVariantsMoreThanSelectedQuantity:
      "Ukupne varijante su više od odabrane količine proizvoda, Pls smanjuje količinu varijante",
    productEditedSuccessfully: "Proizvod je uspješno uređen!",
    fileTooLargeLessThan4Mb:
      "Veličina datoteke je prevelika! Veličina datoteke treba biti manja od 4 MB.",
    suchVariantAlreadyExist: "Takve varijante već postoje",
    addVariants: "Dodajte varijante",
    editProduct: "Uredite proizvod",
    pleaseEnterProductName: "Molimo unesite naziv proizvoda",
    pleaseEnterProductPrice: "Molimo unesite cijenu proizvoda",
    pleaseEnterProductOriginalPrice:
      "Molimo unesite originalnu cijenu proizvoda",
    productDescription: "opis proizvoda",
    selectProductCategory: "Odaberite kategoriju proizvoda",
    pleaseSelectProductCategory: "Odaberite kategoriju proizvoda",
    productCostPrice: "Cijena proizvoda",
    productSellingPrice: "Prodajna cijena proizvoda",
    productOriginalPrice: "Originalna cijena proizvoda",
    maxFileSizeAllowedIs4Mb: "Maks. Dozvoljena veličina datoteke je 4 MB",
    productsWithPicturesArePublished:
      "Proizvodi sa slikama objavljuju se na Discover -u radi primanja narudžbi",
    shouldThisProductBeTracked: "Treba li pratiti ovaj proizvod?",
    pleaseSelectStockUnit: "Molimo odaberite jedinicu zalihe",
    stockUnit: "Stock Unit",
    bag: "BAG",
    bottle: "BOCA",
    bunch: "BUNCH",
    can: "CAN",
    carton: "CARTON",
    crate: "KUTIJA",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAIR",
    pieces: "KOMADI",
    plate: "PLOČA",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "DVORIŠTE",
    pleaseEnterProductQuantity: "Molimo unesite količinu proizvoda",
    productQuantity: "Količina proizvoda",
    isThisProductTaxed: "Da li se ovaj proizvod oporezuje?",
    selectTaxType: "Odaberite vrstu poreza",
    pleaseSelectTaxType: "Molimo odaberite vrstu poreza",
    progressive: "Progresivno",
    proportional: "Proporcionalno",
    pleaseEnterProductTaxRate: "Molimo unesite stopu poreza na proizvode",
    doesProductHaveVariants: "Ima li ovaj proizvod varijante?",
    type: "Tip",
    value: "Vrijednost",
    pleaseEnterVariantType: "Molimo unesite tip varijante",
    pleaseEnterVariantValue: "Molimo unesite vrijednost varijante",
    pleaseEnterVariantPrice: "Molimo unesite cijenu varijante",
    pleaseEnterVariantQuantity: "Molimo unesite količinu varijante",
    productDeletedSuccessfully: "Proizvod je uspješno izbrisan!",
    categoryDeletedSuccessfully: "Kategorija je uspješno izbrisana!",
    toDeleteThisProduct: "za brisanje ovog Proizvoda",
    invalidProductQuantity: "Nevažeća količina proizvoda",
    quantityAddedIsOverStock:
      "Količina koju dodajete je veća od onoga što imate na zalihi.",
    itemInventoryNotTracked: "Inventar stavki se ne prati",
    addBulkQuantity: "Dodajte masovnu količinu",
    enterBulkQuantity: "Unesite masovnu količinu",
    pleaseEnterBulkQuantity: "Molimo unesite masovnu količinu",
    youveSuccessfullyAddedANewProduct: "Uspješno ste dodali novi proizvod!",
    pleaseEnterProductSellingPrice: "Molimo unesite prodajnu cijenu proizvoda",
    doYouWantToTrackProductStock: "Želite li pratiti zalihe proizvoda?",
    sellingPrice: "Prodajna cijena",
    setProductExpiryReminder: "Postavite podsjetnik isteka proizvoda",
    productExpiryDate: "Datum isteka proizvoda",
    startRemindingFrom: "Počni podsjetiti od",
    productSuppliesAddedSuccessfully: "Uspješno ste dodali zalihe proizvoda.",
    addProductSupplies: "Dodajte zalihe proizvoda",
    pleaseSelectSupplier: "Molimo odaberite Dobavljača",
    nameOfProduct: "Naziv proizvoda",
    pleaseSelectProduct: "Molimo odaberite proizvod",
    productVariant: "Varijanta proizvoda",
    pleaseSelectAVariant: "Molimo odaberite varijantu",
    pleaseEnterUnitPrice: "Molimo unesite jediničnu cijenu",
    businessBranch: "Poslovna podružnica",
    pleaseSelectBranch: "Molimo izaberite granu",
    youveSuccessfullyAddedANewSupplier: "Uspješno ste dodali novog dobavljača",
    addSupplier: "Dodaj dobavljača",
    pleaseEnterSupplierEmail: "Molimo unesite e -adresu dobavljača",
    pleaseAddADescription: "Dodajte opis",
    anErrorOccuredProductsDeleted:
      "Došlo je do greške pri izvođenju operacije. Imajte na umu da je nekoliko proizvoda možda izbrisano u tom procesu",
    bulkDelete: "Skupno brisanje",
    youAreAboutToDelete: "Upravo ćete izbrisati",
    product: "Proizvod",
    isDueToRedeem: "treba otkupiti",
    aReward: "nagradu",
    pleaseSelectCustomerToReeemReward:
      "Molimo odaberite klijenta da iskoristite nagradu.",
    youHaveNoLoyaltyProgramRunning: "Nemate aktivan program vjernosti",
    customerHhasNoPointsInLoyaltyProgram:
      "Klijent nema bodova u ovom programu vjernosti",
    proceedWithPayment: "Nastaviti s plaćanjem?",
    youAreAboutToPayForTransactionUsingPoints:
      "Planirate platiti transakciju pomoću bodova.",
    customerHas: "Kupac ima",
    worth: "vrijedi",
    andIsNotSufficientToPayForTransaction:
      "i nije dovoljno za plaćanje ove transakcije. Da li bi htjeli dodati saldo pomoću drugog načina plaćanja?",
    addCustomerLoyalty: "Dodajte lojalnost kupaca",
    pleaseAddCustomerFirst: "Molimo vas da prvo dodate ili odaberete kupca.",
    pleaseWaitWhileWeGetReady: "Sačekajte dok se ne spremimo",
    lowStock: "Low Stock",
    pleaseEnterAmountTendered: "Molimo unesite ponuđeni iznos",
    areYouSureToBookSaleOffline:
      "Jeste li sigurni da želite rezervirati ovu prodaju van mreže?",
    saleWouldBeBookedAutomatically:
      "Prodaja bi se automatski knjižila kada uključite internet",
    offlineSalesBookingCancelled: "Rezervacija prodaje van mreže otkazana",
    covid19Message:
      "COVID19: Operite ruke sapunom ili dezinficirajte kako biste spriječili širenje. Uvijek budite sigurni",
    saleSuccessfullyRecorded: "Prodaja uspješno snimljena!",
    sendReceiptToEmail: "Pošaljite priznanicu na email",
    sendThankYouSms: "Pošaljite zahvalni SMS",
    sendShippingDetails: "Pošaljite detalje dostave",
    addLoyalty: "Dodaj lojalnost",
    searchCustomerNameOrNumber: "Pretražite ime ili broj korisnika",
    clickTheSearchCustomerBox:
      "Kliknite Pretraži korisnički okvir i karticu za skeniranje",
    enterProductPrice: "Unesite cenu proizvoda",
    enterPriceFor: "Unesite cijenu za",
    searchForProduct: "Potražite proizvod",
    all: "Sve",
    backToDashboard: "Nazad na kontrolnu tablu",
    viewDraftSales: "Pogledajte Nacrt prodaje",
    variantSelected: "odabrana varijanta",
    variant: "varijanta",
    thePreviousVariantSelectionNotAvailable:
      "Prethodni odabir varijante nije dostupan za novu varijantu odabranu na osnovu cijene, promijenite svoj odabir.",
    pleaseSupplyPositiveQuantityNumber: "Navedite pozitivan broj količine",
    lowStockFor: "Niske zalihe za",
    clearVariants: "Jasne varijante",
    pleaseEnterQuantity: "Molimo unesite količinu",
    picture: "Slika",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Ukupno potrošeno",
    confirmPayment: "Potvrdite plaćanje",
    hasPaymentBeenMade: "Je li uplata uspješno obrađena?",
    enterTransactionReference:
      "Unesite referencu transakcije kojom ste platili",
    pleaseSelectACustomer: "Molimo odaberite kupca!",
    areYouSureToApplyDiscount: "Jeste li sigurni da želite primijeniti popust?",
    addYourBankAccountToEnableUssd:
      "Dodajte svoj bankovni račun kako biste omogućili trenutni USSD prijenos putem uPay -a",
    totalAmountToPay: "Ukupan iznos za plaćanje",
    doYouWantToCancelTransaction: "Želite li otkazati ovu transakciju?",
    savePrintBill: "Sačuvaj/odštampaj račun",
    enterAmountCollectedForCustomer: "Unesite prikupljeni iznos za kupca",
    recordSale: "Rekordna prodaja",
    checkOutWith: "Odjavite se sa",
    instantTransfer: "Trenutni transfer",
    dialTheUSSDCode: "Birajte USSD kod",
    pleaseSelectABank: "Odaberite banku",
    payWithUSSD: "Platite putem USSD -a",
    sendBillTo: " - Pošalji Bill -a na",
    waitingForUSSDTransfer: "Čeka se USSD prijenos",
    percent: "Procenat",
    applyDiscount: "Primijenite popust",
    thisBillHasBeenSaved: "Ovaj račun je sačuvan",
    saveDraft: "Spasiti nacrt",
    pleaseTypeANameToIdentifyCustomer: "Unesite ime radi identifikacije kupca",
    paymentDetails: "Detalji plaćanja",
    basePrice: "Osnovna cijena",
    staff: "Osoblje",
    subTotal: "SubTotal",
    durationMonths: "Trajanje (mjeseci)",
    selectADuration: "Odaberite trajanje",
    oneMonth: "1 mjesec",
    twoMonths: "2 mjeseca",
    threeMonths: "3 mjeseca",
    sixMonths: "6 mjeseci",
    twelveMonths: "12 mjeseci",
    eighteenMonths: "18 mjeseci",
    twentyFourMonths: "24 mjeseca",
    twoMonthsFree: "(2 mjeseca besplatno)",
    threeMonthsFree: "(3 mjeseca besplatno)",
    fiveMonthsFree: "(5 mjeseci besplatno)",
    yourAccountHasBeen: "Vaš račun je bio",
    renewed: "obnovljeno",
    upgraded: "nadograđen",
    successfully: "uspješno",
    yourSubscription: "Vaša pretplata",
    youAreCurrentlyEnrolledOnA: "Trenutno ste upisani na a",
    pleaseChooseAPaymentOption: "Odaberite opciju plaćanja",
    planRenewal: "Plan obnove",
    planUpgrade: "Planirajte nadogradnju",
    pleaseSelectDurationToPayFor:
      "Molimo odaberite Trajanje za koje namjeravate platiti",
    staffAccounts: "Računi osoblja",
    ecommerce: "E -trgovina",
    pleaseSelectAPlan: "Molimo odaberite plan",
    includeAddons: "Uključuje dodatke",
    viewTransactions: "Pregled transakcija",
    customerHasNoCompletedTansactions: "Kupac još nije završio transakcije",
    branch: "Branch",
    enterNumberOfEcommerceBranches: "Unesite broj podružnica e -trgovine",
    enterNumberOfEcommerceBranchesToPay:
      "Unesite broj poslovnica e -trgovine za koje namjeravate platiti",
    ecommerceIntegration: "Integracija e -trgovine",
    enterNumberOfBranches: "Unesite broj grana",
    enterNumberOfAdditionalBranchesToPay:
      "Unesite broj dodatnih poslovnica koje namjeravate platiti",
    enterNumberOfStaffs: "Unesite broj osoblja",
    enterNumberOfStaffsToPayFor:
      "Unesite broj osoblja koje namjeravate platiti",
    discountApplies: "Važi popust",
    starsOnThe: "zvezde na",
    offer: "ponuda",
    get: "Get",
    moreStarsToRedeem: "više zvijezda za otkup",
    taxVat: "Porez (PDV)",
    callCashierToCompletePayment: "Pozovite blagajnika da dovršite plaćanje",
    receipt: "Receipt",
    dear: "Draga",
    thankYouForYourOrderFindGoods:
      "Hvala vam za narudžbu. U skladu s dogovorom pronađite sljedeću isporučenu robu.",
    shippingNote: "Napomena o otpremi",
    enterShippingNote: "Unesite napomenu o otpremi",
    shippingAddress: "Adresa za dostavu",
    enterShippingAddress: "Unesite adresu za dostavu",
    wellDoneYouAreDueToRedeem: "Dobro urađeno! Trebate otkupiti",
    toGetYourRewardNextVisit:
      "kako biste dobili nagradu prilikom sljedeće posjete. Hvala ti",
    pointsOnThe: "Bodovi na",
    morePointsToRedeem: "više bodova za otkup",
    showCode: "Prikaži kod",
    toGetYourRewardOnNextVisit:
      "kako biste dobili nagradu prilikom sljedeće posjete. Hvala ti",
    earn: "Zaradite",
    delivaryNote: "Napomena o isporuci",
    draftSales: "Nacrt prodaje",
    startDate: "Datum početka",
    endDate: "Datum završetka",
    orders: "Narudžbe",
    checkout: "provjeri",
    noProductItem: "Nema stavke proizvoda",
    selectProductImage: "Odaberite sliku proizvoda",
    selectCountry: "Odaberite državu",
    selectRegion: "Odaberite državu/regiju",
    printProductTag: "Odštampajte oznaku proizvoda",
    transactionReference: "Referenca transakcije",
    Cashier: "Blagajna",
    Manager: "Menadžer",
    Owner: "Vlasnik",
    Admin: "Admin",
  },
  Bulgarian: {
    cashier: "касиер",
    manager: "управител",
    owner: "собственик",
    online: "на линия",
    offline: "извън линия",
    changePassword: "Промяна на паролата",
    currentPasswordMessage: "Моля, въведете текущата си парола",
    passwordMessage: "Моля въведете паролата си",
    currentPassword: "Настояща парола",
    password: "парола",
    confirmPasswordMessage: "Моля, потвърдете паролата си!",
    confirmPassword: "потвърди парола",
    sendViaEmail: "Изпращане чрез имейл",
    sendViaWhatsapp: "Изпратете чрез WhatsApp",
    downloadPdf: "Изтеглете PDF",
    paid: "платен",
    unpaid: "неплатен",
    partial: "частичен",
    closeInvoice: "Искате ли да затворите фактурата?",
    closeInvoiceConfirmation:
      "Фактурата може да не бъде запазена. Искате ли да затворите?",
    yes: "да",
    no: "не",
    invoice: "Фактура",
    wasDeducted: "беше приспаднато",
    for: "за",
    item: "Вещ",
    addProduct: "Добавяне на продукт",
    paymentReference: "Справка за плащане",
    amountPaid: "Платено количество",
    discountAmount: "Стойност на намалението",
    amountDue: "Сума, дължима",
    amount: "Количество",
    dueDate: "Краен срок",
    paymentType: "Вид плащане",
    card: "Карта",
    cash: "Пари в брой",
    bankTransfer: "Банков трансфер",
    paymentMessage: "Съобщение за плащане",
    description: "Описание",
    sendReceipt: "Изпратете разписка",
    delete: "Изтрий",
    save: "Запазване",
    resend: "Повторно изпращане",
    saveAndSend: "Запазване",
    invoiceSaved: "Фактурата е запазена!",
    selectPaymentMethod: "Моля, изберете начин на плащане!",
    selectCustomer: "Моля, изберете клиент!",
    cartEmptyError:
      "Списъкът с колички не може да бъде празен, затворете фактурата и добавете артикул в количката!",
    subscriptionExpired:
      "Абонаментът ви е изтекъл и профилът ви вече е ограничен. Щракнете върху бутона по -долу, за да подновите акаунта си",
    renew: "Подновете",
    holdOn: "Задръжте",
    customerBank: "Клиентска банка",
    cancel: "Отказ",
    selectACustomer: "Изберете Клиент",
    invoiceSuccessfulPdfError:
      "Фактурата е създадена успешно, но генерирането на PDF отнема повече време от обикновено. Моля, проверете отново скоро.",
    downloadingInvoice: "Изтегляне на фактура",
    downloadingReceipt: "Изтегляне на разписка",
    whatsappReceiptError:
      "Възникна грешка при изпращането на разписката чрез WhatsApp. Моля, опитайте отново.",
    receiptToWhatsapp: "Разписка, препратена до WhatsApp",
    thankYouForPatronage: "Благодаря ви за покровителството",
    hereIsYourReceipt: "Ето ви разписката за плащане",
    errorSendingEmailReceipt:
      "Възникна грешка при изпращането на разписката по имейл, моля, опитайте отново или се свържете с екипа за поддръжка",
    receiptSentToEmail: "Разписката е изпратена на имейла на клиента",
    invoiceSentToEmail: "Фактурата е изпратена на имейла на клиента",
    invoiceSuccessWhatsappError:
      "Фактурата е създадена успешно, но възникна грешка при изпращането до WhatsApp. Опитайте отново в списъка с фактури",
    invoiceSuccessfulEmailError:
      "Фактурата е създадена успешно, но възникна грешка при изпращането като имейл. Моля, опитайте отново от списъка с фактури",
    invoiceSentToWhatsapp: "Фактура, препратена до WhatsApp",
    hello: "Здравейте",
    pleaseDownloadInvoice: "Моля, изтеглете фактура",
    pleaseDownloadReceipt: "Моля, изтеглете разписка",
    from: "от",
    email: "електронна поща",
    upgrade: "Подобряване на",
    youAreOnFreePlan: "Вие сте на безплатен план.",
    clickOn: "Кликнете върху",
    yourPlanInFewSteps: " вашия абонамент в няколко бързи стъпки.",
    to: "да се",
    yourSubscriptionHasExpired:
      "Абонаментът ви е изтекъл и профилът ви вече е ограничен.",
    days: "дни",
    yourSubscriptionExpiresIn: "Абонаментът ви за Loystar изтича след",
    createAcount: "Създай профил",
    signIn: "Впиши се",
    continue: "продължи",
    enterOtp: "Въведете OTP PIN",
    enterValidOtp: "Моля, въведете валиден ПИН код",
    pin: "ПИН код",
    tokenSentToMail: "На вашия имейл е изпратен знак",
    passwordResetSuccessful: "Нулирането на паролата беше успешно",
    somethingWentWrong: "Нещо се обърка!",
    resetPassword: "Нулиране на паролата",
    iHaveResetCode: "Имам код за възстановяване на паролата",
    pleaseEnterEmail: "Моля, въведете своя имейл",
    aTokenWillBeSentToEmail: "На вашия имейл ще бъде изпратен знак",
    enterEmail: "Въведете своя имейл",
    sendinYourToken: "Изпращате вашия жетон ...",
    makeSureItMatchesPassword: "Уверете се, че съвпада с новата ви парола",
    pleaseChooseNewPassword: "Моля, изберете нова парола",
    chooseNewPassword: "Изберете нова парола",
    enterNewPassword: "Въведете новата си парола, за да потвърдите",
    enterTokenSent: "Въведете жетона, който е изпратен на вашата поща",
    resetToken: "Нулиране на жетона",
    resettingPassword: "Нулирането на вашата парола ...",
    signUp: "Регистрирай се",
    adminSignInWithEmail:
      " Администраторът влиза с имейл, докато персоналът влиза с потребителско име.",
    pleaseEnterEmailOrUsername:
      "Моля, въведете вашия имейл или потребителско име",
    emailOrUsername: "Имейл или потребителско име",
    pleaseEnterPassword: "Моля, въведете парола",
    createAnAccount: "Създай профил",
    forgotPassword: "Забравена парола?",
    enterPhoneNumber: "Въведете телефонен номер",
    personalData: "Лични данни",
    validateConfirmationCOde: "Потвърдете кода за потвърждение",
    pleaseEnterFirstName: "Моля въведете собственото си име",
    pleaseEnterPhoneNumber: "Моля, въведете телефонния си номер",
    pleaseEnterLastName: "Моля, въведете фамилното си име",
    pleaseEnterBusinessName: "Моля, въведете името на вашия бизнес",
    firstName: "Първо име",
    lastName: "Фамилия",
    businessName: "Име на фирмата",
    previous: "Предишни",
    next: "Следващия",
    pleaseSelectBusinessCategory: "Моля, изберете вашата бизнес категория",
    pleaseEnterValidEmail: "Моля, въведете валиден имейл",
    pleaseEnterPostCode: "Моля, въведете пощенския код",
    postCode: "Пощенски код",
    phoneNumberInUse: "Този телефонен номер вече се използва!",
    emailInUse: "Този имейл вече се използва!",
    foodAndDrinks: "Храна и напитки",
    salonAndBeauty: "Салон и красота",
    fashionAndAccessories: "Мода и аксесоари",
    gymAndFitness: "Фитнес зала и фитнес",
    travelAndHotel: "Пътуване и хотел",
    homeAndGifts: "Дом и подаръци",
    washingAndCleaning: "Измиване и почистване",
    gadgetsAndElectronics: "Притурки и електроника",
    groceries: "Бакалия",
    others: "Други",
    submit: "Изпращане",
    accountCreatedSuccessful: "Профилът ви е създаден успешно.",
    pleaseEnterAddress: "Моля, въведете вашия адрес",
    addressLine1: "Адрес 1 ред",
    addressLine2: "Адрес ред 2",
    choosePassword: "Изберете парола",
    passwordMustBe6Characters: "Паролата трябва да съдържа поне 6 знака.",
    howDidYouHearLoystar: "Как чухте за Loystar?",
    referralCode: "реферален код",
    byClickingTheButton:
      " Щраквайки върху бутона по -долу, вие се съгласявате с",
    termsAndSevice: "Условия",
    wereCreatingAccount: "Ние създаваме вашия акаунт",
    proceed: "Продължете",
    verificationCodeMustBe6: "Кодът за потвърждение трябва да бъде 6 цифри",
    pleaseEnter6DigitCode: "Моля, въведете 6 -цифрен код",
    enterVerificationCode: "Въведете код за верификация",
    resendToken: "Повторно изпращане на жетон",
    verify: "Проверете",
    transactions: "Транзакции",
    todaySales: "Днешните продажби",
    salesHistory: "История на продажбите",
    supplyHistory: "История на доставките",
    new: "Ново",
    invoices: "Фактури",
    disbursements: "Изплащания",
    offlineSales: "Офлайн продажби",
    products: "Продукти",
    customers: "Клиенти",
    multiLevelLoyalty: "Многостепенна лоялност",
    loyaltyPrograms: "Програми за лоялност",
    members: "Членове",
    appStore: "App Store",
    orderMenu: "Меню за поръчка",
    settings: "Настройки",
    staffAndBranches: "Персонал и клонове",
    myAccount: "Моята сметка",
    switchToSellMode: "Превключете в режим на продажба",
    signOut: "Отписване",
    getFreeSubscription: "Вземете безплатен абонамент",
    onlyNumbersAllowed: "Допускат се само числа",
    yourAccountMustBe10Digits: "номерът на сметката ви трябва да е 10 -цифрен",
    yourBvnMustBe11: "вашият BVN трябва да бъде 11 -цифрено число",
    pleaseSelectBank: "Моля, изберете вашата банка",
    selectYourBank: "Изберете вашата банка",
    enterBankAccountNumber: "Въведете номера на банкова сметка",
    enterBvnNumber: "Въведете своя BVN",
    connectBankAccount: "Свържете банкова сметка",
    passwordResetSuccessfulAndSignOut:
      "Вашата парола е нулирана успешно. Щракнете върху бутона Изход по -долу, за да влезете отново",
    enterCurrentPassword: "Въведете текущата парола",
    pleaseEnterCurrentPassword: "Моля, въведете текущата парола",
    phoneNumber: "Телефонен номер",
    sex: "Секс",
    dateOfBirth: "Дата на раждане",
    state: "Щат",
    country: "Страна",
    loyaltyId: "Идентификационен номер за лоялност",
    createdAt: "Създадено в",
    noOfTransactionsMade: "Брой извършени транзакции",
    yourDownloadWillStart: "Изтеглянето ви ще започне след малко",
    exportCustomers: "Експортиране на клиенти",
    youhaveSuccessfullyToppedUp: "Успешно попълнихте вашите SMS единици.",
    youNowHave: "Сега имате",
    smsUnits: "SMS единици",
    enterNumberOfSmsUnits:
      "Въведете броя на SMS блоковете, които възнамерявате да закупите",
    pleaseEnterANumericValue: "Моля, въведете числова стойност",
    pay: "Платете",
    accountEditedSuccessfully: "Профилът ви е редактиран успешно.",
    youAeCurrentlyOn: "В момента сте включени",
    plan: "План",
    userData: "Потребителски данни",
    businessData: "БИЗНЕС ДАННИ",
    businessCategory: "Бизнес категория",
    pleaseSelectCurrency: "Моля, изберете валутата си",
    selectYourCurrency: "Изберете вашата валута",
    purchaseMoreSmsUnits:
      "Купете още SMS единици, като използвате формата по -долу",
    youHave: "Ти имаш",
    atLeast4SmsUnitsRrequired:
      "За проверка са необходими поне 4 sms единици, моля, допълнете!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Моля, проверете банковата си сметка, за да отговаря на изискванията „Познайте своя клиент“ (KYC). Това ще ви позволи да събирате плащания чрез USSD или незабавен трансфер, да получавате поръчки от клиенти и да обработвате уреждане на транзакции. Приложимо само за нигерийски търговци. Моля, натиснете бутона по -долу, за да започнете.",
    reConnectBankAccount: "Свържете отново банковата сметка",
    accountName: "Име на акаунта",
    accountNumber: "Номер на сметка",
    bankName: "Име на банката",
    verified: "Проверено",
    accountDetails: "детайли за акаунта",
    kycBankAccount: "KYC",
    createTier: "Създаване на ниво",
    fileUploadSuccessful: "файлът е качен успешно",
    fileUploadFailed: "качването на файла не бе успешно",
    createLoyaltyProgram: "Създайте програма за лоялност",
    createLoyalty: "Създайте лоялност",
    name: "Име",
    loyaltyArtwork: "Произведения на лоялността",
    clickToUpload: "Щракнете, за да качите",
    amountToPointsRatio: "Съотношение сума към точки",
    points: "Точки",
    recommendedAmountToPointRatio:
      "Препоръчително: ₦ 1 до 1 точка. т.е. за всеки 1 изразходван Найра, вашите клиенти получават 1 точка",
    pleaseTypeIn: "Моля, въведете",
    toDeleteLoyalty: "за да изтриете тази лоялност",
    deleteLoyalty: "Изтриване на лоялност",
    toConfirm: "за да потвърдите",
    edit: "редактиране",
    pointsAwardedSuccessfully: "Успешно присъдени точки.",
    enterPointValueToAward:
      "Въведете точкова стойност, която искате да наградите клиенти",
    award: "Награда",
    awardPointValuesToCustomer: "Възлагане на стойност на точките на клиента",
    enrollMembersToLoyalty: "Запишете членовете в лоялност",
    awardPoints: "Награждаване на точки",
    enroll: "Записвам се",
    home: "У дома",
    loyalty: "Лоялност",
    enrollCustomers: "Запишете клиенти",
    selected: "Избрано",
    customer: "Клиент",
    loyaltyActivationSuccessful: "Активирането на лоялност е успешно.",
    loyaltyDeactivationSuccessful: "Деактивирането на лоялността е успешно.",
    viewTier: "Вижте ниво",
    deactivate: "Деактивирайте",
    activate: "Активирате",
    actions: "Действия",
    nameOfLoyalty: "Име на лоялност",
    loyaltyStatus: "Статус на лоялност",
    numberOfTiers: "Брой нива",
    createdOn: "Създадено на",
    createATier: "Създайте ниво",
    stopCreatingTierConfirmation: "Искате ли да спрете създаването на ниво?",
    stopEditingTierConfirmation:
      "Искате ли да спрете редактирането на това ниво?",
    nameOfTier: "Име на ниво",
    minimumSpendingTarget: "Цел за минимални разходи",
    maximumSpendingTarget: "Цел за максимални разходи",
    minimumSpendingTargetRequired: "е необходима минимална цел на разходите",
    maximumSpendingTargetRequired: "е необходима максимална цел на разходите",
    rewardSponsor: "Спонсор на наградата",
    pleaseChooseARewardSponsor: "Моля, изберете спонсор за награда",
    self: "Аз",
    partner: "Партньор",
    rewardPartner: "Награден партньор",
    pleaseSelectRewardPartner:
      "Моля, изберете вашия партньор за възнаграждение",
    rewards: "Награди",
    pleaseSelectAReward: "Моля, изберете награда",
    instructionsOnRedeemingReward:
      "Инструкции за това как клиентът трябва да осребри наградата",
    pleaseFillInThisField: "Моля, попълнете това поле!",
    toDeleteThisTier: " за да изтриете това ниво",
    deleteTier: "Изтриване на ниво",
    viewMembers: "Преглед на членовете",
    membersEnrolled: "Регистрирани членове",
    instruction: "Инструкция",
    membersIn: "Членове в",
    availableTiersInLoyalty: "Налични нива (и) в Програмата за лоялност",
    tiers: "Нива",
    totalTier: "Общо ниво",
    availableLoyaltyProgramme: "Налична програма за лоялност",
    totalLoyalties: "ОБЩИ ЛОЯЛНОСТИ",
    memberDetails: "Подробности за членовете",
    nameOfCustomer: "Име на клиента",
    address: "Адрес",
    allEnrolledMembers: "Всички регистрирани членове",
    thisIsToWishYouHappyBirthday:
      "Това е, за да ви пожелая много честит рожден ден и проспериращ живот. Благодаря за всичко, което си на Loyster. Весели празници!",
    inputAnOfferPlease: "Моля, въведете оферта",
    pleaseSelectTheInsertPoint:
      "Моля, изберете точката на вмъкване в съобщението и щракнете отново",
    birthdayOfferAndMessage: "Оферта и съобщение за рожден ден",
    birthdayOffer: "Оферта за рожден ден",
    birthdayMessage: "Съобщение за рожден ден",
    noOfferFound: "Не е намерена оферта",
    settingABirthdayOffer:
      "Задаването на оферта за рожден ден позволява на клиентите да получат тази оферта чрез SMS на техния рожден ден",
    createOffer: "Създайте оферта",
    whatIsTheOffer: "Каква е офертата?",
    editMessage: "Редактиране на съобщение",
    insert: "Вмъкване",
    theNameOfCustomerInserted: "Името на клиента ще бъде вмъкнато тук",
    theBirtdayOfferInserted: "Офертата за рожден ден ще бъде вмъкната тук",
    youSuccessfullyAddedNewBranch: "Успешно добавихте нов клон!",
    addNewBranch: "Добавяне на нов клон",
    addBranch: "Добавяне на клон",
    additionalBranchWillIncur: "Ще възникне допълнителен клон",
    perBranch: "на клон",
    ecommerceBranchCosts: "Разходи за клон за електронна търговия",
    pleaseEnterBranchName: "Моля, въведете името на клона",
    pleaseEnterBranchAddress1: "Моля, въведете адресния ред на клона 1",
    enterBranchAddress1: "Въведете адресния ред на клона 1",
    enterBranchAddress2: "Въведете адресния ред на клона 1",
    pleaseEnterBranchAddress2: "Моля, въведете адресния ред на клона 2",
    enterBranchName: "Въведете името на клона",
    successfullyAddedStaff: "Успешно добавихте нов персонал!",
    addNewStaff: "Добавяне на нов персонал",
    addStaff: "Добавяне на персонал",
    additionalStaffWillIncur: "Ще има допълнителен персонал",
    perStaff: "на персонал.",
    pleaseEnterStaffEmail: "Моля, въведете имейла на персонала",
    pleaseEnterStaffUsername: "Моля, въведете потребителското име на персонала",
    pleaseEnterStaffPassword: "Моля, въведете паролата на персонала",
    pleaseSelectStaffRole: "Моля, изберете ролята на персонала",
    selectStaffRole: "Изберете ролята на персонала",
    enterStaffEmail: "Въведете имейла на персонала",
    enterStaffUsername: "Въведете потребителското име на персонала",
    enterStaffPassword: "Въведете паролата на персонала",
    spacesNotAllowedInUsername:
      "в потребителското име не се допускат интервали",
    admin: "Администратор",
    pleaseSelectBusinessToAttachStaff:
      "Моля, изберете бизнес, за да прикачите персонал",
    searchForBranchToAttachStaff: "Търсете клон, за да прикачите персонал",
    username: "Потребителско име",
    role: "Роля",
    areYouSureToDeleteThis: "Сигурни ли сте, че ще изтриете това",
    branches: "Клонове",
    upgradeYourPlan: "Надстройте плана си.",
    add: "ДОБАВЯНЕ",
    addNew: "Добави нов",
    customerWithEmailAlreadyExists:
      "Клиент с имейл/телефонен номер вече съществува!",
    successfullyAddedNewCustomer: "Успешно добавихте нов клиент!",
    addCustomer: "Добавяне на клиент",
    pleaseEnterCustomerFirstName: "Моля, въведете името на клиента",
    pleaseEnterCustomerLastName: "Моля, въведете фамилията на клиента",
    pleaseEnterCustomerPhoneNumber:
      "Моля, въведете телефонния номер на клиента",
    pleaseEnterCustomerEmail: "Моля, въведете имейла на клиента",
    pleaseEnterCustomerAddressLine: "Моля, въведете адресния ред на клиента",
    pleaseEnterCustomerOtherAddress: "Моля, въведете другия адрес на клиента",
    pleaseSelectCustomerGender: "Моля, изберете пола на клиента",
    gender: "Пол",
    male: "Мъжки",
    female: "Женски пол",
    bank: "Банка",
    selectBank: "Изберете банка",
    stateOrRegionOrProvince: "Щат/регион/провинция",
    customerNotes: "Бележки на клиента",
    sendSms: "Изпратете SMS",
    editCustomer: "Редактиране на клиент",
    redeemReward: "Осребряване на наградата",
    issueLoyaltyCard: "Издаване на карта за лоялност",
    deleteCustomer: "Изтриване на клиента",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Успешно сте присвоили идентификационен номер на членство за лоялност",
    noMembershipIdAvailable:
      "Няма налични идентификационни номера за членство. Моля, свържете се с нас на hello@loystar.co",
    sendEmail: "Изпратете имейл",
    membershipPoints: "Точки за членство",
    customerDetails: "Данни за клиента",
    close: "Близо",
    loyaltyBalance: "Баланс на лоялността",
    pointsBalance: "Баланс на точки",
    starBalance: "Star Balance",
    requiredPoints: "Задължителни точки",
    requiredStars: "Задължителни звезди",
    reddemCode: "Осребряване на кода",
    toDeleteThisCustomer: "да изтриете този клиент",
    customerHasBeenDeletedSuccessfully: "Клиентът е изтрит успешно!",
    customerWithPhoneAlreadyExists: "Клиент с телефонен номер вече съществува",
    customerWasSuccessfullyEdited: "Клиентът беше редактиран успешно",
    anErrorOccured: "Възникна грешка",
    phoneNumberIncludeCountry: "Телефонен номер (включва кода на държавата)",
    yourFileQueuedForUpload:
      "Файлът ви е поставен на опашка за качване. Моля, опреснете страницата след няколко секунди.",
    thereWasAnErrorPerformingOperation:
      "Възникна грешка при извършване на операцията!",
    pleaseSelectFile: "Моля, изберете файл!",
    oopsOnlyCsvFilesAllowed:
      "Опа! Разрешени са само CSV файлове. Моля, качете .csv файл.",
    fileShouldBeSmallerThan5Mb:
      "Файлът трябва да е по -малък от 5 MB. Ако имате по -голям файл, моля, изпратете имейл на support@loystar.co. Благодаря ти",
    customerFirstNameIsRequired: "името на клиента се изисква на ред",
    customerPhoneNumberIsRequired:
      "телефонният номер на клиента се изисква на ред",
    importCustomers: "Импортирайте клиенти",
    upload: "Качване",
    clickIconToDownloadCsv:
      "Щракнете върху тази икона, за да изтеглите шаблона за CSV файл.",
    getGoogleSheetVersion: "Вземете версията на листа на Google",
    clickOrDragFileToUpload:
      "Щракнете или плъзнете файла в тази област, за да го качите",
    missingOrInvalidColumnHeader:
      "Липсваща или невалидна заглавка на колона. Моля, следвайте формата на шаблона. Благодаря ти.",
    youHaveImported: "Внесли сте",
    youSuccessfullyRedeemedReward: "Успешно изкупихте наградата си!",
    sixDigitCode: "Шестцифрен код",
    pleaseEnterCustomerRewardCode:
      "Моля, въведете кода за възнаграждение на клиента",
    enterRewardCode:
      "Въведете кода на наградата. (Кодът на наградата е чувствителен към регистъра)",
    selectLoyaltyProgram: "Изберете програма за лоялност",
    stamps: "Печати",
    smsUnitsLow: "Ниски SMS единици",
    pleaseTopUpSmsUnits: "Моля, попълнете SMS единици",
    smsSuccessfullySentForDelivery: "SMS успешно изпратен за доставка!",
    sendSmsTo: "Изпратете SMS на",
    allCustomers: "Всички клиенти",
    unitsAvailable: "Налични единици",
    pleaseTypeInTheMessage: "Моля, въведете съобщението",
    message: "Съобщение",
    charactersRemaining: "оставащи знаци",
    avoidUseOfSpecialCharacters:
      "Избягвайте използването на специални знаци и емотикони за запазване на SMS единици.",
    note: "Забележка",
    errorFetchingCustomersMultilevelDetail:
      "Грешка при извличането на подробности на много нива на клиентите",
    search: "Търсене",
    reset: "Нулиране",
    importCustomer: "Импортиране на клиент",
    addNewCustomer: "Добавяне на нов клиент",
    sendSmsBroadcast: "Изпратете SMS излъчване",
    totalCustomers: "ОБЩО КЛИЕНТИ",
    disbursementDetails: "Подробности за изплащане",
    paidBy: "Платен от",
    disbursed: "Изплатени",
    bankAccountName: "Име на банкова сметка",
    bankAccountNumber: "Номер на банкова сметка",
    transferInitiated: "Прехвърлянето е инициирано",
    transferCompleted: "Прехвърлянето завършено",
    pleaseEnterAValid: "Моля, въведете валиден",
    begin: "започнете",
    end: "край",
    date: "дата",
    paymentDate: "Дата за плащане",
    selectDisbursementDuration: "Изберете Продължителност на изплащане",
    totalSettled: "Общо уредени",
    totalUnsettled: "Общо неуредени",
    toDeleteThisSale: "да изтриете тази продажба",
    draftSaleDeletedSuccessfully: "Чернова продажба е изтрита успешно!",
    deleteSale: "Изтриване на разпродажба",
    pleaseTypeInYourTotal:
      "Моля, въведете общата сума, за да потвърдите изтриването",
    billDetails: "Подробности за сметката",
    printBill: "Отпечатайте Бил",
    servedBy: "Обслужва се от",
    total: "Обща сума",
    createdDate: "Дата на създаване",
    createdTime: "Създадено време",
    status: "Състояние",
    loginSuccessful: "Успешен вход",
    pleaseWaitWhileWeConnectAccount:
      "Моля, изчакайте, докато свържем профила ви",
    connectionFailedTryAgain: "Свързването е неуспешно. Моля, опитайте отново.",
    connectionSuccessful: "Връзката е успешна",
    viewDetails: "Разгледайте детайлите",
    enable: "Активиране",
    free: "Безплатно",
    cost: "Разходи",
    visitWebsite: "Посетете уебсайта",
    pleaseUpgradeYourPlanToPro:
      "Моля, надстройте плана си до Pro Plus, за да активирате това приложение",
    connectYourBankAccount:
      "Свържете банковата си сметка, за да можете да получавате плащания.",
    disable: "Деактивиране",
    enableApp: "Активиране на приложението",
    addNewProductToInvoice: "Добавете нов продукт към фактура",
    toDeleteThisInvoice: "за да изтриете тази фактура",
    invoiceDeletedSuccessfully: "Фактурата е изтрита успешно!",
    deleteInvoice: "Изтриване на фактура",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Успешно добавихте съобщение за плащане по фактура!",
    pleaseEnterPaymentInstructions: "Моля, въведете инструкции за плащане",
    invoiceId: "ИД на фактура",
    paidDate: "Платена дата",
    reference: "Справка",
    productAdded: "Продуктът е добавен",
    productOutOfStock: "Продуктът не е на склад. Моля, запасете отново.",
    totalInvoices: "ОБЩИ ФАКТУРИ",
    totalPaidInvoices: "ОБЩО ПЛАТЕНИ ФАКТУРИ",
    totalUnpaidInvoices: "Общо неплатени фактури",
    loyaltyProgramDeleted: "Програмата за лоялност е изтрита",
    thisLoyaltyProgramDeletedSuccessfully:
      "Тази програма за лоялност е изтрита успешно",
    thisLoyaltyProgramEditedSuccessfully:
      "Тази програма за лоялност е редактирана успешно",
    loyaltyProgramAddedSuccessfully:
      "Програмата за лоялност е добавена успешно",
    loyaltyProgramEdited: "Редактирана програма за лоялност",
    loyaltyProgramAdded: "Добавена е програма за лоялност",
    loyaltyDetails: "Детайли за лоялността",
    doYouWantToCloseDialog: "Искате ли да затворите този диалогов прозорец?",
    pleaseEnterLoyaltyName: "Моля, въведете името на вашата лоялност",
    programType: "Тип програма",
    pleaseSelectProgramType: "Моля, изберете тип програма",
    simplePoints: "Прости точки",
    stampsProgram: "Програма за марки",
    threshold: "Праг",
    pleaseEnterLoyaltyThreshold: "Моля, въведете прага на лоялност",
    reward: "Награда",
    pleaseEnterLoyaltyReward: "Моля, въведете наградата за лоялност",
    totalUserPoints: "Общо потребителски точки",
    totalUserStamps: "Общо потребителски печати",
    spendingTarget: "Цел на разходите",
    spendingTargetHint1:
      "Целта на разходите е колко клиентът трябва да похарчи, за да спечели наградата. 1 валутна стойност = 1 точка.",
    spendingTargetHint2:
      "Целевите марки са колко печати клиентът трябва да събере, за да спечели наградата. напр. 5",
    addNewLoyaltyProgram: "Добавете нова програма за лоялност",
    addLoyaltyProgram: "Добавете програма за лоялност",
    loyaltyProgramType: "Тип програма за лоялност",
    pleaseSelectLoyaltyProgramType: "Моля, изберете тип програма за лоялност",
    nameOfProgram: "Име на програмата",
    pleaseEnterProgramName: "Моля, въведете името на програмата",
    whatIsTheReward: "Каква е наградата?",
    egNextPurchaseIsFree: "Напр. Следващата покупка е безплатна",
    customerName: "Потребителско име",
    guestCustomer: "Гост Клиент",
    orderConfirmedSuccessfully: "Поръчката е потвърдена успешно",
    orderCancelledSuccessfully: "Поръчката е отменена успешно",
    confirmOrder: "Потвърди поръчката",
    cancelOrder: "Отмяна на поръчката",
    allOrders: "Всички поръчки",
    totalOrders: "ОБЩО ПОРЪЧКИ",
    doYouWantToAcceptOrder: "Искате ли да приемете тази поръчка?",
    doYouWantToCancelOrder: "Искате ли да отмените тази поръчка?",
    orderDetails: "подробности за поръчката",
    orderCreatedAt: "Поръчката е създадена на",
    supplier: "Доставчик",
    productName: "Име на продукта",
    quantity: "Количество",
    unitPrice: "Единична цена",
    receivedBy: "Получено от",
    reportFrom: "Доклад от",
    totalSupplies: "Общо доставки",
    allRightsReserved: "Всички права запазени",
    toDeleteThisTransaction: "за да изтриете тази транзакция",
    transactionDeletedSuccessfully:
      "Транзакцията е изтрита успешно. Запасите са върнати в инвентара.",
    deleteTransaction: "Изтриване на транзакция",
    transactionDetails: "Подробности за транзакцията",
    printReceipt: "Печатна разписка",
    channel: "Канал",
    discount: "Отстъпка",
    profit: "Печалба",
    discountedSales: "Продажби с отстъпка",
    errorFetchingRecord: "Грешка при извличането на запис",
    exportTransactions: "Експортни транзакции",
    errorFetchingSalesRecord:
      "Грешка при извличането на продажбен запис за експортиране.",
    totalSellingPrice: "Обща продажна цена",
    totalCostPrice: "Обща цена на разходите",
    appliedDiscount: "Приложена отстъпка",
    noOfItems: "Брой артикули",
    sales: "Продажби",
    export: "Експорт",
    totalProfit: "Обща печалба",
    totalBalanceInPeriod: "Общо салдо за период",
    allTimeSales: "Продажби за всички времена",
    records: "Записи",
    todaysSales: "Днешните продажби",
    transaction: "транзакция",
    youHaveExceededTotalNumberOfProducts:
      "Вие сте надвишили общия брой продукти, разрешени по вашия план. Надстройте плана си, за да се насладите на по -висок лимит.",
    youNeedToHaveLoyaltyProgram:
      "За да използвате тази функция, трябва да имате програма за лоялност!",
    price: "Цена",
    category: "Категория",
    stockTracking: "Проследяване на акции",
    stockCount: "Брой акции",
    taxed: "Облагани с данък",
    originalPrice: "Оригинална цена",
    costPrice: "Разходна цена",
    unit: "Мерна единица",
    productImage: "Изображение на продукта",
    taxRate: "Данъчна ставка",
    taxType: "Данъчен вид",
    trackInventory: "Проследяване на инвентара",
    variants: "Варианти",
    hasVariants: "Има варианти",
    importProduct: "Импортирайте продукт",
    exportProducts: "Експорт на продукти",
    addNewProduct: "Добавяне на нов продукт",
    viewCategory: "Преглед на категорията",
    viewSuppliers: "Вижте доставчиците",
    receiveInventory: "Получаване на инвентара",
    printAllProductsTag: "Отпечатайте етикет за всички продукти",
    deleteAll: "Изтриване на всички",
    totalProducts: "ОБЩО ПРОДУКТИ",
    youveSuccessfullyAddedANewCategory: "Успешно добавихте нова категория",
    addNewCategory: "Добавяне на нова категория",
    addCategory: "Добавяне на категория",
    categoryName: "Име на категория",
    pleaseEnterCategoryName: "Моля, въведете името на категорията",
    stampsTarget: "Печат Target",
    sendInventory: "Изпращане на рекламни места",
    productDetails: "информация за продукта",
    youveSuccessfullyEditedThisCategory: "Успешно редактирахте тази категория",
    update: "Актуализиране",
    categoryList: "Списък с категории",
    categories: "Категории",
    enterQuantityToUpdate: "Въведете количество за актуализиране",
    inventorySentSuccessfully: "Инвентарът е изпратен успешно!",
    updateInventory: "Актуализиране на инвентара",
    currentQuantity: "Текущо количество",
    pleaseEnterQuantityToAdd:
      "Моля, въведете количеството, което искате да добавите",
    pleaseSelectABranch: "Моля, изберете клон",
    searchForBranch: "Търсете клон",
    youCantSendMoreThanStock:
      "Не можете да изпращате повече, отколкото имате на склад",
    send: "Изпрати",
    pleaseEnterQuantityToSend:
      "Моля, въведете количеството, което искате да изпратите",
    productNameIsRequiredOnRow: "името на продукта е задължително на ред",
    productCategoryIsRequiredOnRow: "категорията на продукта се изисква на ред",
    productPriceIsRequiredOnRow: "цената на продукта се изисква на ред",
    productUnitIsRequiredOnRow: "продуктовата единица е необходима на ред",
    productQuantityIsRequiredOnRow: "на ред се изисква количество продукт",
    productVariantsRequireTracking:
      "продуктовите варианти изискват проследяване!",
    pleaseAddVariantClickButton:
      "Моля, добавете вариант, като кликнете върху бутона за добавяне!",
    totalVariantsMoreThanSelectedQuantity:
      "Общите варианти са повече от избраното количество продукт, моля намалете количеството на варианта",
    productEditedSuccessfully: "Продуктът е редактиран успешно!",
    fileTooLargeLessThan4Mb:
      "Размерът на файла е твърде голям! Размерът на файла трябва да бъде по -малък от 4 MB.",
    suchVariantAlreadyExist: "Такъв вариант вече съществува",
    addVariants: "Добавяне на варианти",
    editProduct: "Редактирайте продукта",
    pleaseEnterProductName: "Моля, въведете Име на продукта",
    pleaseEnterProductPrice: "Моля, въведете цена на продукта",
    pleaseEnterProductOriginalPrice:
      "Моля, въведете оригиналната цена на продукта",
    productDescription: "Описание на продукта",
    selectProductCategory: "Изберете категория продукт",
    pleaseSelectProductCategory: "Моля, изберете категория на продукта",
    productCostPrice: "Себестойност на продукта",
    productSellingPrice: "Продажна цена на продукта",
    productOriginalPrice: "Оригинална цена на продукта",
    maxFileSizeAllowedIs4Mb: "Макс. Разрешеният размер на файла е 4mb",
    productsWithPicturesArePublished:
      "Продуктите със снимки се публикуват в Discover за получаване на поръчки",
    shouldThisProductBeTracked: "Трябва ли този продукт да бъде проследен?",
    pleaseSelectStockUnit: "Моля, изберете единица на склад",
    stockUnit: "Стокова единица",
    bag: "ЧАНТА",
    bottle: "БУТИЛКА",
    bunch: "ГРУПА",
    can: "МОГА",
    carton: "КАРТОН",
    crate: "КРЕШКА",
    cup: "ЧАШКА",
    dozen: "ДУЗЕН",
    gigabytes: "GIGABYTES",
    gram: "ГРАМ",
    kilogram: "КИЛОГРАМ",
    litre: "ЛИТР",
    pack: "ПАКЕТ",
    pair: "ДВОЙКА",
    pieces: "БРОЙКИ",
    plate: "ПЛОЧА",
    tonne: "ТОННА (МТ)",
    uNIT: "МЕРНА ЕДИНИЦА",
    yard: "ДВОР",
    pleaseEnterProductQuantity: "Моля, въведете количество продукт",
    productQuantity: "Количество на продукта",
    isThisProductTaxed: "Данъчен ли е този продукт?",
    selectTaxType: "Изберете Данъчен тип",
    pleaseSelectTaxType: "Моля, изберете Данъчен вид",
    progressive: "Прогресивно",
    proportional: "Пропорционално",
    pleaseEnterProductTaxRate: "Моля, въведете данъчната ставка на продукта",
    doesProductHaveVariants: "Този продукт има ли варианти?",
    type: "Тип",
    value: "Стойност",
    pleaseEnterVariantType: "Моля, въведете типа на варианта",
    pleaseEnterVariantValue: "Моля, въведете стойността на варианта",
    pleaseEnterVariantPrice: "Моля, въведете цената на варианта",
    pleaseEnterVariantQuantity: "Моля, въведете количеството на варианта",
    productDeletedSuccessfully: "Продуктът е изтрит успешно!",
    categoryDeletedSuccessfully: "Категорията е изтрита успешно!",
    toDeleteThisProduct: "да изтриете този продукт",
    invalidProductQuantity: "Невалидно количество продукт",
    quantityAddedIsOverStock:
      "Количеството, което добавяте, надхвърля това, което имате на склад.",
    itemInventoryNotTracked: "Инвентарът на артикулите не се проследява",
    addBulkQuantity: "Добавяне на насипно количество",
    enterBulkQuantity: "Въведете групово количество",
    pleaseEnterBulkQuantity: "Моля, въведете групово количество",
    youveSuccessfullyAddedANewProduct: "Успешно добавихте нов продукт!",
    pleaseEnterProductSellingPrice: "Моля, въведете продажна цена на продукта",
    doYouWantToTrackProductStock:
      "Искате ли да проследявате запасите от продукти?",
    sellingPrice: "Продажна цена",
    setProductExpiryReminder: "Задайте напомняне за изтичане на продукта",
    productExpiryDate: "Срок на годност на продукта",
    startRemindingFrom: "Започнете да напомняте от",
    productSuppliesAddedSuccessfully: "Успешно сте добавили продукти.",
    addProductSupplies: "Добавете консумативи за продукти",
    pleaseSelectSupplier: "Моля, изберете доставчик",
    nameOfProduct: "Име на продукта",
    pleaseSelectProduct: "Моля, изберете Продукт",
    productVariant: "Вариант на продукта",
    pleaseSelectAVariant: "Моля, изберете вариант",
    pleaseEnterUnitPrice: "Моля, въведете единична цена",
    businessBranch: "Бизнес клон",
    pleaseSelectBranch: "Моля, изберете клон",
    youveSuccessfullyAddedANewSupplier: "Успешно добавихте нов доставчик",
    addSupplier: "Добавяне на доставчик",
    pleaseEnterSupplierEmail: "Моля, въведете имейла на доставчика",
    pleaseAddADescription: "Моля, добавете описание",
    anErrorOccuredProductsDeleted:
      "Възникна грешка при извършване на операцията. Моля, обърнете внимание, че някои продукти може да са били изтрити в процеса",
    bulkDelete: "Групово изтриване",
    youAreAboutToDelete: "На път сте да изтриете",
    product: "Продукт",
    isDueToRedeem: "се дължи на обратно изкупуване",
    aReward: "награда",
    pleaseSelectCustomerToReeemReward:
      "Моля, изберете клиент, за да осребрите наградата.",
    youHaveNoLoyaltyProgramRunning: "Нямате активна програма за лоялност",
    customerHhasNoPointsInLoyaltyProgram:
      "Клиентът няма точки в тази програма за лоялност",
    proceedWithPayment: "Да продължите ли с плащането?",
    youAreAboutToPayForTransactionUsingPoints:
      "На път сте да платите за транзакцията, като използвате точки.",
    customerHas: "Клиентът има",
    worth: "заслужава си",
    andIsNotSufficientToPayForTransaction:
      "и не е достатъчно за плащане на тази транзакция. Искат ли да добавят салдото, използвайки друг начин на плащане?",
    addCustomerLoyalty: "Добавете лоялност на клиентите",
    pleaseAddCustomerFirst: "Моля, първо добавете или изберете клиент.",
    pleaseWaitWhileWeGetReady: "Моля, изчакайте, докато се подготвим",
    lowStock: "Ниски запаси",
    pleaseEnterAmountTendered: "Моля, въведете предложената сума",
    areYouSureToBookSaleOffline:
      "Сигурни ли сте, че искате да резервирате тази разпродажба офлайн?",
    saleWouldBeBookedAutomatically:
      "Продажбата ще се резервира автоматично, когато включите интернет",
    offlineSalesBookingCancelled: "Резервацията за офлайн продажби е отменена",
    covid19Message:
      "COVID19: Измийте ръцете си със сапун или дезинфекцирайте, за да спрете разпространението. Бъдете винаги в безопасност",
    saleSuccessfullyRecorded: "Продажбата е записана успешно!",
    sendReceiptToEmail: "Изпратете разписка на имейл",
    sendThankYouSms: "Изпратете благодарствен SMS",
    sendShippingDetails: "Изпратете подробности за доставката",
    addLoyalty: "Добавете лоялност",
    searchCustomerNameOrNumber: "Потърсете име или номер на клиент",
    clickTheSearchCustomerBox:
      "Щракнете върху Търсене на поле за клиенти и сканиране на карта",
    enterProductPrice: "Въведете цена на продукта",
    enterPriceFor: "Въведете цена за",
    searchForProduct: "Търсете продукт",
    all: "всичко",
    backToDashboard: "Обратно към таблото за управление",
    viewDraftSales: "Преглед на черновите продажби",
    variantSelected: "избран вариант",
    variant: "вариант",
    thePreviousVariantSelectionNotAvailable:
      "Изборът на предишния вариант не е наличен за новия вариант, избран въз основа на цената, моля, променете своя избор.",
    pleaseSupplyPositiveQuantityNumber:
      "Моля, предоставете положително количество",
    lowStockFor: "Ниски наличности за",
    clearVariants: "Ясни варианти",
    pleaseEnterQuantity: "Моля, въведете Количество",
    picture: "Снимка",
    redemptionToken: "Жетон за осребряване",
    token: "Токен",
    totalSpent: "Общо изразходвани",
    confirmPayment: "Потвърдете плащането",
    hasPaymentBeenMade: "Успешно ли е обработено плащането?",
    enterTransactionReference:
      "Въведете референтния номер на транзакцията, с която сте платили",
    pleaseSelectACustomer: "Моля, изберете клиент!",
    areYouSureToApplyDiscount:
      "Сигурни ли сте, че искате да приложите отстъпка?",
    addYourBankAccountToEnableUssd:
      "Добавете банковата си сметка, за да активирате Незабавен USSD превод от uPay",
    totalAmountToPay: "Обща сума за плащане",
    doYouWantToCancelTransaction: "Искате ли да отмените тази транзакция?",
    savePrintBill: "Запазване/Печат на сметка",
    enterAmountCollectedForCustomer: "Въведете събраната сума за клиента",
    recordSale: "Рекордна продажба",
    checkOutWith: "Проверете с",
    instantTransfer: "Незабавно прехвърляне",
    dialTheUSSDCode: "Наберете USSD кода",
    pleaseSelectABank: "Моля, изберете банка",
    payWithUSSD: "Плащайте с USSD",
    sendBillTo: " - Изпратете Бил на",
    waitingForUSSDTransfer: "Изчаква се прехвърляне на USSD",
    percent: "Процент",
    applyDiscount: "Приложете отстъпка",
    thisBillHasBeenSaved: "Този законопроект е запазен",
    saveDraft: "Чернова",
    pleaseTypeANameToIdentifyCustomer:
      "Моля, въведете име, за да идентифицирате клиента",
    paymentDetails: "Подробности на плащане",
    basePrice: "Основна цена",
    staff: "Персонал",
    subTotal: "SubTotal",
    durationMonths: "Продължителност (месеци)",
    selectADuration: "Изберете продължителност",
    oneMonth: "1 месец",
    twoMonths: "2 месеца",
    threeMonths: "3 месеца",
    sixMonths: "6 месеца",
    twelveMonths: "12 месеца",
    eighteenMonths: "18 месеца",
    twentyFourMonths: "24 месеца",
    twoMonthsFree: "(2 месеца безплатно)",
    threeMonthsFree: "(3 месеца безплатно)",
    fiveMonthsFree: "(5 месеца безплатно)",
    yourAccountHasBeen: "Вашият акаунт е бил",
    renewed: "подновен",
    upgraded: "надградена",
    successfully: "успешно",
    yourSubscription: "Вашият абонамент",
    youAreCurrentlyEnrolledOnA: "В момента сте записани на a",
    pleaseChooseAPaymentOption: "Моля, изберете опция за плащане",
    planRenewal: "План за подновяване",
    planUpgrade: "Планирайте надстройка",
    pleaseSelectDurationToPayFor:
      "Моля, изберете продължителност, за която възнамерявате да платите",
    staffAccounts: "Сметки на персонала",
    ecommerce: "Е-търговия",
    pleaseSelectAPlan: "Моля, изберете план",
    includeAddons: "Включете добавки",
    viewTransactions: "Преглед на транзакциите",
    customerHasNoCompletedTansactions:
      "Клиентът все още не е завършил танзации",
    branch: "Клон",
    enterNumberOfEcommerceBranches:
      "Въведете брой клонове за електронна търговия",
    enterNumberOfEcommerceBranchesToPay:
      "Въведете броя на клоновете за електронна търговия, за които възнамерявате да платите",
    ecommerceIntegration: "Интеграция с електронна търговия",
    enterNumberOfBranches: "Въведете брой клонове",
    enterNumberOfAdditionalBranchesToPay:
      "Въведете броя на допълнителните клонове, за които възнамерявате да платите",
    enterNumberOfStaffs: "Въведете броя на персонала",
    enterNumberOfStaffsToPayFor:
      "Въведете броя служители, за които възнамерявате да платите",
    discountApplies: "Важи отстъпка",
    starsOnThe: "звезди на",
    offer: "оферта",
    get: "Вземи",
    moreStarsToRedeem: "повече звезди за изкупуване",
    taxVat: "Данък (ДДС)",
    callCashierToCompletePayment:
      "Обадете се на касиер, за да завършите плащането",
    receipt: "Касова бележка",
    dear: "Уважаеми",
    thankYouForYourOrderFindGoods:
      "Благодаря ви за вашата поръчка. Моля, намерете следните стоки доставени, както е договорено.",
    shippingNote: "Бележка за доставка",
    enterShippingNote: "Въведете бележка за доставка",
    shippingAddress: "Адрес за доставка",
    enterShippingAddress: "Въведете адреса за доставка",
    wellDoneYouAreDueToRedeem: "Много добре! Вие трябва да изкупите",
    toGetYourRewardNextVisit:
      "за да получите наградата си при следващото си посещение. Благодаря ти",
    pointsOnThe: "Точки върху",
    morePointsToRedeem: "повече точки за осребряване",
    showCode: "Показване на кода",
    toGetYourRewardOnNextVisit:
      "за да получите наградата си при следващото си посещение. Благодаря ти",
    earn: "Печелете",
    delivaryNote: "Бележка за доставка",
    draftSales: "Чернови продажби",
    startDate: "Начална дата",
    endDate: "Крайна дата",
    orders: "Поръчки",
    checkout: "Разгледайте",
    noProductItem: "Няма артикул",
    selectProductImage: "Изберете изображение на продукта",
    selectCountry: "Изберете държава",
    selectRegion: "Изберете държава/регион",
    printProductTag: "Отпечатайте етикет на продукта",
    transactionReference: "Справка за транзакция",
    Cashier: "Касиер",
    Manager: "Мениджър",
    Owner: "Собственик",
    Admin: "Администратор",
  },
  Catalan: {
    cashier: "caixer",
    manager: "gerent",
    owner: "propietari",
    online: "en línia",
    offline: "fora de línia",
    changePassword: "Canvia la contrasenya",
    currentPasswordMessage: "Introduïu la vostra contrasenya actual",
    passwordMessage: "Introduïu la vostra contrasenya",
    currentPassword: "Contrasenya actual",
    password: "contrasenya",
    confirmPasswordMessage: "Confirmeu la vostra contrasenya.",
    confirmPassword: "Confirma la contrassenya",
    sendViaEmail: "Envia per correu electrònic",
    sendViaWhatsapp: "Envia per WhatsApp",
    downloadPdf: "Descarregar PDF",
    paid: "pagat",
    unpaid: "sense pagar",
    partial: "parcial",
    closeInvoice: "Voleu tancar la factura?",
    closeInvoiceConfirmation:
      "És possible que la factura no es desi. Voleu tancar?",
    yes: "sí",
    no: "no",
    invoice: "Factura",
    wasDeducted: "es va deduir",
    for: "per",
    item: "Article",
    addProduct: "Afegeix producte",
    paymentReference: "Referència de pagament",
    amountPaid: "Quantitat pagada",
    discountAmount: "Import de descompte",
    amountDue: "Deute",
    amount: "Import",
    dueDate: "Data de venciment",
    paymentType: "Tipus de pagament",
    card: "Targeta",
    cash: "Efectiu",
    bankTransfer: "Transferència bancària",
    paymentMessage: "Missatge de pagament",
    description: "Descripció",
    sendReceipt: "Envia el rebut",
    delete: "Suprimeix",
    save: "Desa",
    resend: "Reenviar",
    saveAndSend: "Desa",
    invoiceSaved: "S'ha desat la factura.",
    selectPaymentMethod: "Seleccioneu una forma de pagament.",
    selectCustomer: "Seleccioneu un client.",
    cartEmptyError:
      "La llista de carretons no pot estar buida, tanqueu la factura i afegiu l'article al carretó.",
    subscriptionExpired:
      "La vostra subscripció ha caducat i el vostre compte ara està limitat. Feu clic al botó següent per renovar el vostre compte",
    renew: "Renova",
    holdOn: "Espera",
    customerBank: "Banc de clients",
    cancel: "Cancel · lar",
    selectACustomer: "Seleccioneu Client",
    invoiceSuccessfulPdfError:
      "La factura s'ha creat correctament, però la generació de PDF triga més del que és habitual. Torneu a comprovar-ho aviat.",
    downloadingInvoice: "S'està baixant la factura",
    downloadingReceipt: "S'està baixant el rebut",
    whatsappReceiptError:
      "S'ha produït un error en enviar el rebut per WhatsApp. Torneu-ho a provar.",
    receiptToWhatsapp: "Rebut rebut a WhatsApp",
    thankYouForPatronage: "Gràcies pel vostre patrocini",
    hereIsYourReceipt: "Aquí teniu el rebut de pagament",
    errorSendingEmailReceipt:
      "S'ha produït un error en enviar el rebut per correu electrònic. Torneu-ho a provar o contacteu amb el servei d'assistència",
    receiptSentToEmail: "El rebut s’ha enviat al correu electrònic del client",
    invoiceSentToEmail:
      "La factura s'ha enviat al correu electrònic del client",
    invoiceSuccessWhatsappError:
      "La factura s'ha creat correctament, però s'ha produït un error en enviar-la a WhatsApp. Torneu-ho a provar a la llista de factures",
    invoiceSuccessfulEmailError:
      "La factura s'ha creat correctament, però s'ha produït un error en enviar-lo com a correu electrònic. Torneu-ho a provar a la llista de factures",
    invoiceSentToWhatsapp: "Factura reenviada a WhatsApp",
    hello: "Hola",
    pleaseDownloadInvoice: "Descarregueu la factura",
    pleaseDownloadReceipt: "Descarregueu el rebut",
    from: "des de",
    email: "Correu electrònic",
    upgrade: "Actualització",
    youAreOnFreePlan: "Teniu un pla gratuït.",
    clickOn: "Fer clic a",
    yourPlanInFewSteps: " la vostra subscripció en uns quants passos ràpids.",
    to: "a",
    yourSubscriptionHasExpired:
      "La vostra subscripció ha caducat i el vostre compte ara està limitat.",
    days: "dies",
    yourSubscriptionExpiresIn: "La vostra subscripció a Loystar caduca el",
    createAcount: "Crear un compte",
    signIn: "Inicieu la sessió",
    continue: "Continua",
    enterOtp: "Introduïu el PIN OTP",
    enterValidOtp: "Introduïu un PIN vàlid",
    pin: "PIN",
    tokenSentToMail: "S'ha enviat un testimoni al vostre correu electrònic",
    passwordResetSuccessful:
      "El restabliment de la contrasenya ha estat correcte",
    somethingWentWrong: "Alguna cosa ha anat malament!",
    resetPassword: "Restablir la contrasenya",
    iHaveResetCode: "Tinc un codi de restabliment de contrasenya",
    pleaseEnterEmail: "Introduïu el vostre correu electrònic",
    aTokenWillBeSentToEmail:
      "S’enviaria un testimoni al vostre correu electrònic",
    enterEmail: "Introdueixi el seu correu electrònic",
    sendinYourToken: "S'està enviant el vostre testimoni ...",
    makeSureItMatchesPassword:
      "Assegureu-vos que coincideixi amb la vostra nova contrasenya",
    pleaseChooseNewPassword: "Trieu una contrasenya nova",
    chooseNewPassword: "Trieu una contrasenya nova",
    enterNewPassword: "Introduïu la vostra nova contrasenya per confirmar",
    enterTokenSent: "Introduïu el testimoni que us ha enviat el correu",
    resetToken: "Restableix el testimoni",
    resettingPassword: "Restabliment de la contrasenya ...",
    signUp: "Registra't",
    adminSignInWithEmail:
      " L’administrador inicia la sessió amb el correu electrònic mentre el personal inicia la sessió amb el nom d’usuari.",
    pleaseEnterEmailOrUsername:
      "Introduïu el vostre correu electrònic o nom d'usuari",
    emailOrUsername: "Correu electrònic o nom d'usuari",
    pleaseEnterPassword: "Introduïu la contrasenya",
    createAnAccount: "Crear un compte",
    forgotPassword: "Has oblidat la contrasenya?",
    enterPhoneNumber: "Introduïu el número de telèfon",
    personalData: "Dades personals",
    validateConfirmationCOde: "Valida el codi de confirmació",
    pleaseEnterFirstName: "Introduïu el vostre nom",
    pleaseEnterPhoneNumber: "Introduïu el vostre número de telèfon",
    pleaseEnterLastName: "Introduïu el vostre cognom",
    pleaseEnterBusinessName: "Introduïu el nom del vostre negoci",
    firstName: "Nom",
    lastName: "Cognom",
    businessName: "Nom del Negoci",
    previous: "Anterior",
    next: "Pròxim",
    pleaseSelectBusinessCategory: "Seleccioneu la vostra categoria d’empresa",
    pleaseEnterValidEmail: "Introduïu un correu electrònic vàlid",
    pleaseEnterPostCode: "Introduïu el codi postal",
    postCode: "Codi postal",
    phoneNumberInUse: "Aquest número de telèfon ja està en ús.",
    emailInUse: "Aquest correu electrònic ja està en ús.",
    foodAndDrinks: "Menjar i begudes",
    salonAndBeauty: "Saló i bellesa",
    fashionAndAccessories: "Moda i Complements",
    gymAndFitness: "Gimnàs i fitnes",
    travelAndHotel: "Viatges i hotels",
    homeAndGifts: "Llar i regals",
    washingAndCleaning: "Rentat i neteja",
    gadgetsAndElectronics: "Gadgets i electrònica",
    groceries: "Comestibles",
    others: "Altres",
    submit: "Presentar",
    accountCreatedSuccessful: "El vostre compte s'ha creat correctament.",
    pleaseEnterAddress: "Introduïu la vostra adreça",
    addressLine1: "Adreça Línia 1",
    addressLine2: "Direcció Línia 2",
    choosePassword: "Triar una contrasenya",
    passwordMustBe6Characters:
      "La contrasenya ha de tenir com a mínim 6 caràcters.",
    howDidYouHearLoystar: "Com va saber parlar de Loystar?",
    referralCode: "Codi de Referència",
    byClickingTheButton: " En fer clic al botó següent, accepteu el fitxer",
    termsAndSevice: "Termes",
    wereCreatingAccount: "Estem creant el vostre compte",
    proceed: "Continuar",
    verificationCodeMustBe6: "El codi de verificació ha de tenir 6 dígits",
    pleaseEnter6DigitCode: "Introduïu un codi de 6 dígits",
    enterVerificationCode: "Introduïu el codi de verificació",
    resendToken: "Torna a enviar el testimoni",
    verify: "Verifiqueu",
    transactions: "Transaccions",
    todaySales: "Vendes d'avui",
    salesHistory: "Historial de vendes",
    supplyHistory: "Historial de subministraments",
    new: "Novetat",
    invoices: "Factures",
    disbursements: "Desemborsaments",
    offlineSales: "Vendes fora de línia",
    products: "Productes",
    customers: "Clients",
    multiLevelLoyalty: "Fidelització a diversos nivells",
    loyaltyPrograms: "Programes de fidelització",
    members: "Membres",
    appStore: "Tenda d'aplicacions",
    orderMenu: "Menú de comandes",
    settings: "Configuració",
    staffAndBranches: "Bastons i sucursals",
    myAccount: "El meu compte",
    switchToSellMode: "Canvia al mode de venda",
    signOut: "Tanca sessió",
    getFreeSubscription: "Obteniu subscripció gratuïta",
    onlyNumbersAllowed: "Només es permeten números",
    yourAccountMustBe10Digits: "el número de compte ha de ser de deu dígits",
    yourBvnMustBe11: "el vostre BVN ha de ser un número d'11 dígits",
    pleaseSelectBank: "Seleccioneu el vostre banc",
    selectYourBank: "Seleccioneu el vostre banc",
    enterBankAccountNumber: "Introduïu el número de compte bancari",
    enterBvnNumber: "Introduïu el vostre BVN",
    connectBankAccount: "Connectar compte bancari",
    passwordResetSuccessfulAndSignOut:
      "La vostra contrasenya s’ha restablert correctament. Feu clic al botó Tanca la sessió següent per iniciar la sessió de nou",
    enterCurrentPassword: "Introduïu la contrasenya actual",
    pleaseEnterCurrentPassword: "Introduïu la contrasenya actual",
    phoneNumber: "Número de telèfon",
    sex: "Sexe",
    dateOfBirth: "Data de naixement",
    state: "Estat",
    country: "País",
    loyaltyId: "Identificador de fidelitat",
    createdAt: "Creat a",
    noOfTransactionsMade: "Nombre de transaccions realitzades",
    yourDownloadWillStart: "La baixada començarà d'aquí a un moment",
    exportCustomers: "Exportar clients",
    youhaveSuccessfullyToppedUp:
      "Heu completat correctament les vostres unitats de SMS.",
    youNowHave: "Ara ho tens",
    smsUnits: "Unitats SMS",
    enterNumberOfSmsUnits:
      "Introduïu el nombre d'unitats SMS que vulgueu comprar",
    pleaseEnterANumericValue: "Introduïu un valor numèric",
    pay: "Paga",
    accountEditedSuccessfully: "El vostre compte s'ha editat correctament.",
    youAeCurrentlyOn: "Actualment sou",
    plan: "Pla",
    userData: "Dades d’usuari",
    businessData: "DADES EMPRESARIALS",
    businessCategory: "Negocis Catergory",
    pleaseSelectCurrency: "Seleccioneu la vostra moneda",
    selectYourCurrency: "Seleccioneu la vostra moneda",
    purchaseMoreSmsUnits:
      "Compreu més unitats SMS mitjançant el formulari següent",
    youHave: "Tu tens",
    atLeast4SmsUnitsRrequired:
      "Es requereixen almenys 4 unitats de sms per a la verificació. Si us plau, recarregueu.",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verifiqueu el vostre compte bancari per complir els requisits de "Conegueu el vostre client" (KYC). Això us permetrà cobrar pagaments mitjançant USSD o transferència instantània, rebre comandes de clients i processar la liquidació de transaccions. Aplicable només als comerciants nigerians. Si us plau, premeu el botó següent per començar.',
    reConnectBankAccount: "Torneu a connectar el compte bancari",
    accountName: "Nom del compte",
    accountNumber: "Número de compte",
    bankName: "Nom del banc",
    verified: "Verificat",
    accountDetails: "Detalls de la Compta",
    kycBankAccount: "KYC",
    createTier: "Crea un nivell",
    fileUploadSuccessful: "fitxer carregat correctament",
    fileUploadFailed: "ha fallat la càrrega del fitxer",
    createLoyaltyProgram: "Creeu un programa de fidelització",
    createLoyalty: "Crear fidelitat",
    name: "Nom",
    loyaltyArtwork: "Obres d'art de fidelització",
    clickToUpload: "Feu clic per penjar",
    amountToPointsRatio: "Relació quantitat / punts",
    points: "Punts",
    recommendedAmountToPointRatio:
      "Recomanat: de 1 a 1 punt. és a dir, per cada 1 Naira gastat, els vostres clients obtenen 1 punt",
    pleaseTypeIn: "Si us plau, escriviu",
    toDeleteLoyalty: "per eliminar aquesta lleialtat",
    deleteLoyalty: "Suprimeix la lleialtat",
    toConfirm: "per confirmar",
    edit: "Edita",
    pointsAwardedSuccessfully: "Punts atorgats amb èxit.",
    enterPointValueToAward:
      "Introduïu el valor del punt que voleu atorgar als clients",
    award: "Premi",
    awardPointValuesToCustomer: "Valor del punt d’adjudicació al client",
    enrollMembersToLoyalty: "Inscriviu els membres a la lleialtat",
    awardPoints: "Punts de premis",
    enroll: "Inscriu-te",
    home: "Inici",
    loyalty: "Lleialtat",
    enrollCustomers: "Inscriviu els clients",
    selected: "Seleccionat",
    customer: "Client",
    loyaltyActivationSuccessful: "L’activació de la lleialtat té èxit.",
    loyaltyDeactivationSuccessful: "La desactivació de la lleialtat té èxit.",
    viewTier: "Visualitza el nivell",
    deactivate: "Desactiveu",
    activate: "Activa",
    actions: "Accions",
    nameOfLoyalty: "Nom de la lleialtat",
    loyaltyStatus: "Estat de fidelitat Estat de fidelitat",
    numberOfTiers: "Nombre de nivells",
    createdOn: "Creada el",
    createATier: "Creeu un nivell",
    stopCreatingTierConfirmation: "Voleu deixar de crear un nivell?",
    stopEditingTierConfirmation: "Voleu deixar d'editar aquest nivell?",
    nameOfTier: "Nom del nivell",
    minimumSpendingTarget: "Objectiu de despesa mínima",
    maximumSpendingTarget: "Objectiu de despesa màxima",
    minimumSpendingTargetRequired: "es requereix l'objectiu mínim de despesa",
    maximumSpendingTargetRequired: "es requereix l'objectiu màxim de despesa",
    rewardSponsor: "Patrocinador de recompenses",
    pleaseChooseARewardSponsor: "Trieu un patrocinador de recompenses",
    self: "Jo",
    partner: "Soci",
    rewardPartner: "Soci recompensat",
    pleaseSelectRewardPartner: "Seleccioneu el vostre soci de recompensa",
    rewards: "Recompenses",
    pleaseSelectAReward: "Seleccioneu una recompensa",
    instructionsOnRedeemingReward:
      "Instruccions sobre com s'ha de canviar la recompensa del client",
    pleaseFillInThisField: "Empleneu aquest camp.",
    toDeleteThisTier: " per suprimir aquest nivell",
    deleteTier: "Suprimeix el nivell",
    viewMembers: "Veure membres",
    membersEnrolled: "Membres inscrits",
    instruction: "Instrucció",
    membersIn: "Membres a",
    availableTiersInLoyalty: "Nivells disponibles al programa de fidelització",
    tiers: "Nivells",
    totalTier: "NIVELL TOTAL",
    availableLoyaltyProgramme: "Programa de fidelització disponible",
    totalLoyalties: "TOTAL FIDELITAT",
    memberDetails: "Detalls del membre",
    nameOfCustomer: "Nom del client",
    address: "adreça",
    allEnrolledMembers: "Tots els membres inscrits",
    thisIsToWishYouHappyBirthday:
      "Això és per desitjar-vos un molt feliç aniversari i una vida pròspera. Gràcies per tot el que ets a Loyster. Felices celebracions!",
    inputAnOfferPlease: "Introduïu una oferta si us plau",
    pleaseSelectTheInsertPoint:
      "Seleccioneu el punt d'inserció al missatge i torneu a fer clic",
    birthdayOfferAndMessage: "Oferta i missatge d'aniversari",
    birthdayOffer: "Oferta d'aniversari",
    birthdayMessage: "Missatge d'aniversari",
    noOfferFound: "No s'ha trobat cap oferta",
    settingABirthdayOffer:
      "Establir una oferta d’aniversari permet que els clients rebin aquesta oferta per SMS el dia del seu aniversari",
    createOffer: "Crea oferta",
    whatIsTheOffer: "Quina és l'oferta?",
    editMessage: "Edita el missatge",
    insert: "Insereix",
    theNameOfCustomerInserted: "Aquí s’inserirà el nom del client",
    theBirtdayOfferInserted: "Aquí s'inserirà l'oferta d'aniversari",
    youSuccessfullyAddedNewBranch: "Heu afegit amb èxit una nova sucursal.",
    addNewBranch: "Afegeix una branca nova",
    addBranch: "Afegiu una sucursal",
    additionalBranchWillIncur: "Incorreria una branca addicional",
    perBranch: "per branca",
    ecommerceBranchCosts: "Costos de la sucursal de comerç electrònic",
    pleaseEnterBranchName: "Introduïu el nom de la sucursal",
    pleaseEnterBranchAddress1: "Introduïu l'adreça 1 de la sucursal",
    enterBranchAddress1: "Introduïu l'adreça 1 de la sucursal",
    enterBranchAddress2: "Introduïu l'adreça 1 de la sucursal",
    pleaseEnterBranchAddress2: "Introduïu l'adreça 2 de la sucursal",
    enterBranchName: "Introduïu el nom de la sucursal",
    successfullyAddedStaff: "Heu afegit amb èxit un personal nou.",
    addNewStaff: "Afegeix personal nou",
    addStaff: "Afegir personal",
    additionalStaffWillIncur: "Incorreria personal addicional",
    perStaff: "per personal.",
    pleaseEnterStaffEmail: "Introduïu el correu electrònic del personal",
    pleaseEnterStaffUsername: "Introduïu el nom d’usuari del personal",
    pleaseEnterStaffPassword: "Introduïu la contrasenya del personal",
    pleaseSelectStaffRole: "Seleccioneu la funció del personal",
    selectStaffRole: "Seleccioneu la funció del personal",
    enterStaffEmail: "Introduïu el correu electrònic del personal",
    enterStaffUsername: "Introduïu el nom d'usuari del personal",
    enterStaffPassword: "Introduïu la contrasenya del personal",
    spacesNotAllowedInUsername: "espais no permesos al nom d'usuari",
    admin: "Administrador",
    pleaseSelectBusinessToAttachStaff:
      "Seleccioneu l'empresa per afegir personal",
    searchForBranchToAttachStaff: "Cerqueu sucursal per adscriure personal",
    username: "Nom d'usuari",
    role: "Paper",
    areYouSureToDeleteThis: "Esteu segur que suprimireu això?",
    branches: "Branques",
    upgradeYourPlan: "Actualitzeu el pla.",
    add: "AFEGEIX",
    addNew: "Afegir nou",
    customerWithEmailAlreadyExists:
      "El client amb número de correu electrònic o telèfon ja existeix.",
    successfullyAddedNewCustomer: "Heu afegit amb èxit un client nou.",
    addCustomer: "Afegeix client",
    pleaseEnterCustomerFirstName: "Introduïu el nom del client",
    pleaseEnterCustomerLastName: "Introduïu el cognom del client",
    pleaseEnterCustomerPhoneNumber: "Introduïu el número de telèfon del client",
    pleaseEnterCustomerEmail: "Introduïu el correu electrònic del client",
    pleaseEnterCustomerAddressLine: "Introduïu la línia d’adreça del client",
    pleaseEnterCustomerOtherAddress: "Introduïu l'altra adreça del client",
    pleaseSelectCustomerGender: "Seleccioneu el sexe del client",
    gender: "Gènere",
    male: "Home",
    female: "Dona",
    bank: "Banc",
    selectBank: "Seleccioneu Banc",
    stateOrRegionOrProvince: "Estat / Regió / Província",
    customerNotes: "Notes del client",
    sendSms: "Enviar SMS",
    editCustomer: "Edita el client",
    redeemReward: "Canvia la recompensa",
    issueLoyaltyCard: "Emetre targeta de fidelització",
    deleteCustomer: "Suprimeix el client",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Heu assignat correctament un identificador de membre de fidelitat",
    noMembershipIdAvailable:
      "No hi ha identificadors de membres disponibles. Poseu-vos en contacte amb nosaltres a hello@loystar.co",
    sendEmail: "Envia un correu electrònic",
    membershipPoints: "Punts de pertinença",
    customerDetails: "Dades del client",
    close: "Tanca",
    loyaltyBalance: "Saldo de fidelització",
    pointsBalance: "Saldo de punts",
    starBalance: "Saldo d’estrelles",
    requiredPoints: "Punts obligatoris",
    requiredStars: "Estrelles obligatòries",
    reddemCode: "Canvia el codi",
    toDeleteThisCustomer: "per eliminar aquest client",
    customerHasBeenDeletedSuccessfully: "El client s'ha suprimit correctament.",
    customerWithPhoneAlreadyExists:
      "El client amb número de telèfon ja existeix",
    customerWasSuccessfullyEdited: "El client s'ha editat correctament",
    anErrorOccured: "Va ocórrer un error",
    phoneNumberIncludeCountry: "Número de telèfon (incloeu el codi del país)",
    yourFileQueuedForUpload:
      "El vostre fitxer s'ha posat a la cua per penjar-lo. Actualitzeu la pàgina al cap d’uns segons.",
    thereWasAnErrorPerformingOperation:
      "S'ha produït un error en realitzar l'operació.",
    pleaseSelectFile: "Seleccioneu un fitxer.",
    oopsOnlyCsvFilesAllowed:
      "Vaja! Només es permeten fitxers CSV. Pengeu un fitxer .csv.",
    fileShouldBeSmallerThan5Mb:
      "El fitxer ha de ser inferior a 5 MB. Si teniu un fitxer més gran, envieu un correu electrònic a support@loystar.co. Gràcies",
    customerFirstNameIsRequired: "El nom del client és obligatori a la fila",
    customerPhoneNumberIsRequired:
      "El número de telèfon del client és obligatori a la fila",
    importCustomers: "Importar clients",
    upload: "Pujar",
    clickIconToDownloadCsv:
      "Feu clic a aquesta icona per baixar la plantilla de fitxer CSV.",
    getGoogleSheetVersion: "Obteniu la versió del full de Google",
    clickOrDragFileToUpload:
      "Feu clic o arrossegueu el fitxer a aquesta àrea per penjar-lo",
    missingOrInvalidColumnHeader:
      "Falta la capçalera de columna o no és vàlida. Seguiu el format de plantilla. Gràcies.",
    youHaveImported: "Heu importat",
    youSuccessfullyRedeemedReward:
      "Heu bescanviat la vostra recompensa amb èxit.",
    sixDigitCode: "Codi de sis dígits",
    pleaseEnterCustomerRewardCode: "Introduïu el codi de recompensa del client",
    enterRewardCode:
      "Introduïu el codi de recompensa. (El codi de recompensa distingeix entre majúscules i minúscules)",
    selectLoyaltyProgram: "Seleccioneu el programa de fidelització",
    stamps: "Segells",
    smsUnitsLow: "Unitats SMS baixes",
    pleaseTopUpSmsUnits: "Recarregueu unitats SMS",
    smsSuccessfullySentForDelivery:
      "S'ha enviat un SMS per lliurar-lo correctament.",
    sendSmsTo: "Enviar SMS a",
    allCustomers: "Tots els clients",
    unitsAvailable: "Unitats disponibles",
    pleaseTypeInTheMessage: "Escriviu el missatge",
    message: "Missatge",
    charactersRemaining: "personatges restants",
    avoidUseOfSpecialCharacters:
      "Eviteu l'ús de caràcters especials i emojis per conservar les unitats SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Error en obtenir els detalls de diversos nivells dels clients",
    search: "Cerca",
    reset: "Restableix",
    importCustomer: "Importa el client",
    addNewCustomer: "Afegeix un client nou",
    sendSmsBroadcast: "Enviar difusió per SMS",
    totalCustomers: "TOTALS CLIENTS",
    disbursementDetails: "Detalls del desemborsament",
    paidBy: "Pagat per",
    disbursed: "Desemborsat",
    bankAccountName: "Nom del compte bancari",
    bankAccountNumber: "Número de compte bancari",
    transferInitiated: "Transferència iniciada",
    transferCompleted: "La transferència s'ha completat",
    pleaseEnterAValid: "Introduïu un fitxer vàlid",
    begin: "començar",
    end: "final",
    date: "data",
    paymentDate: "Data de pagament",
    selectDisbursementDuration: "Seleccioneu Durada del desemborsament",
    totalSettled: "Total liquidat",
    totalUnsettled: "Total sense establir",
    toDeleteThisSale: "per eliminar aquesta venda",
    draftSaleDeletedSuccessfully:
      "L'esborrany de venda s'ha suprimit correctament.",
    deleteSale: "Suprimeix la venda",
    pleaseTypeInYourTotal: "Escriviu el total per confirmar la supressió",
    billDetails: "Detalls de la factura",
    printBill: "Imprimeix factura",
    servedBy: "Servit per",
    total: "Total",
    createdDate: "Data de creació",
    createdTime: "Temps creat",
    status: "Estat",
    loginSuccessful: "Inici de sessió correcte",
    pleaseWaitWhileWeConnectAccount:
      "Espereu mentre connectem el vostre compte",
    connectionFailedTryAgain: "Connexió fallida. Siusplau torna-ho a provar.",
    connectionSuccessful: "La connexió s'ha realitzat correctament",
    viewDetails: "Veure detalls",
    enable: "Activa",
    free: "Gratuït",
    cost: "Cost",
    visitWebsite: "Visiteu el lloc web",
    pleaseUpgradeYourPlanToPro:
      "Actualitzeu el vostre pla a Pro Plus per habilitar aquesta aplicació",
    connectYourBankAccount:
      "Connecteu el vostre compte bancari per poder rebre pagaments.",
    disable: "Inhabilitar",
    enableApp: "Activa l'aplicació",
    addNewProductToInvoice: "Afegiu un producte nou a la factura",
    toDeleteThisInvoice: "per suprimir aquesta factura",
    invoiceDeletedSuccessfully: "La factura s'ha suprimit correctament.",
    deleteInvoice: "Suprimeix la factura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Heu afegit correctament un missatge de pagament de factures.",
    pleaseEnterPaymentInstructions: "Introduïu les instruccions de pagament",
    invoiceId: "Identificador de factura",
    paidDate: "Data de pagament",
    reference: "Referència",
    productAdded: "Producte afegit",
    productOutOfStock:
      "Producte sense existències. Si us plau, torneu a estocar.",
    totalInvoices: "FACTURES TOTALS",
    totalPaidInvoices: "TOTAL DE FACTURES PAGADES",
    totalUnpaidInvoices: "TOTAL DE FACTURES NO PAGADES",
    loyaltyProgramDeleted: "S'ha suprimit el programa de fidelització",
    thisLoyaltyProgramDeletedSuccessfully:
      "Aquest programa de fidelització s'ha suprimit correctament",
    thisLoyaltyProgramEditedSuccessfully:
      "Aquest programa de fidelització s’ha editat amb èxit",
    loyaltyProgramAddedSuccessfully:
      "El programa de fidelització s'ha afegit correctament",
    loyaltyProgramEdited: "S'ha editat el programa de fidelització",
    loyaltyProgramAdded: "S'ha afegit el programa de fidelització",
    loyaltyDetails: "Detalls de fidelització",
    doYouWantToCloseDialog: "Voleu tancar aquest diàleg?",
    pleaseEnterLoyaltyName: "Introduïu el nom de la vostra fidelitat",
    programType: "Tipus de programa",
    pleaseSelectProgramType: "Seleccioneu un tipus de programa",
    simplePoints: "Punts simples",
    stampsProgram: "Programa de segells",
    threshold: "Llindar",
    pleaseEnterLoyaltyThreshold: "Introduïu el llindar de fidelitat",
    reward: "Recompensa",
    pleaseEnterLoyaltyReward: "Introduïu la recompensa de la fidelitat",
    totalUserPoints: "Punts d'usuari totals",
    totalUserStamps: "Segells d'usuari totals",
    spendingTarget: "Objectiu de despesa",
    spendingTargetHint1:
      "L’objectiu de despesa és quant ha de gastar un client per obtenir la recompensa. 1 valor de moneda = 1 punt.",
    spendingTargetHint2:
      "L’objectiu de segells és el nombre de segells que un client ha de recollir per obtenir la recompensa. per exemple. 5",
    addNewLoyaltyProgram: "Afegiu un nou programa de fidelització",
    addLoyaltyProgram: "Afegeix un programa de fidelització",
    loyaltyProgramType: "Tipus de programa de fidelització",
    pleaseSelectLoyaltyProgramType:
      "Seleccioneu el tipus de programa de fidelització",
    nameOfProgram: "Nom del programa",
    pleaseEnterProgramName: "Introduïu el nom del programa",
    whatIsTheReward: "Quina és la recompensa?",
    egNextPurchaseIsFree: "Per exemple. La propera compra és gratuïta",
    customerName: "Nom del client",
    guestCustomer: "Client convidat",
    orderConfirmedSuccessfully: "La comanda s'ha confirmat correctament",
    orderCancelledSuccessfully: "La comanda s'ha cancel·lat correctament",
    confirmOrder: "Confirmar encàrrec",
    cancelOrder: "Cancel · lar la comanda",
    allOrders: "Totes les comandes",
    totalOrders: "TOTAL DE COMANDES",
    doYouWantToAcceptOrder: "Voleu acceptar aquesta comanda?",
    doYouWantToCancelOrder: "Voleu cancel·lar aquesta comanda?",
    orderDetails: "Detalls de l'encarrec",
    orderCreatedAt: "Comanda creada a",
    supplier: "Proveïdor",
    productName: "nom del producte",
    quantity: "Quantitat",
    unitPrice: "Preu unitari",
    receivedBy: "Rebuda per",
    reportFrom: "Informe de",
    totalSupplies: "Subministraments totals",
    allRightsReserved: "Tots els drets reservats",
    toDeleteThisTransaction: "per suprimir aquesta transacció",
    transactionDeletedSuccessfully:
      "La transacció s'ha suprimit correctament. L'estoc s'ha retornat a l'inventari.",
    deleteTransaction: "Suprimeix la transacció",
    transactionDetails: "Detalls de la transacció",
    printReceipt: "Imprimir el resguard",
    channel: "Canal",
    discount: "Descompte",
    profit: "Benefici",
    discountedSales: "Vendes amb descompte",
    errorFetchingRecord: "Error en obtenir el registre",
    exportTransactions: "Transaccions d’exportació",
    errorFetchingSalesRecord:
      "S'ha produït un error en obtenir el registre de vendes per a l'exportació.",
    totalSellingPrice: "Preu de venda total",
    totalCostPrice: "Preu de cost total",
    appliedDiscount: "Descompte aplicat",
    noOfItems: "Nombre d'articles",
    sales: "Vendes",
    export: "Exporta",
    totalProfit: "Benefici total",
    totalBalanceInPeriod: "Saldo total del període",
    allTimeSales: "Totes les vendes",
    records: "Rècords",
    todaysSales: "Vendes d'avui",
    transaction: "transacció",
    youHaveExceededTotalNumberOfProducts:
      "Heu superat el nombre total de productes permesos al vostre pla. Actualitzeu el vostre pla per gaudir d’un límit superior.",
    youNeedToHaveLoyaltyProgram:
      "Per utilitzar aquesta funció, heu de tenir un programa de fidelització.",
    price: "Preu",
    category: "Categoria",
    stockTracking: "Seguiment d’estocs",
    stockCount: "Recompte d’estocs",
    taxed: "Imposat",
    originalPrice: "Preu original",
    costPrice: "Preu de cost",
    unit: "Unitat",
    productImage: "Imatge del producte",
    taxRate: "Taxa d'impost",
    taxType: "Tipus d’impost",
    trackInventory: "Inventari de seguiment",
    variants: "Variants",
    hasVariants: "Té variants",
    importProduct: "Importar producte",
    exportProducts: "Exportar productes",
    addNewProduct: "Afegeix un producte nou",
    viewCategory: "Veure categoria",
    viewSuppliers: "Veure proveïdors",
    receiveInventory: "Rebre inventari",
    printAllProductsTag: "Imprimeix l'etiqueta de tots els productes",
    deleteAll: "Eliminar tots",
    totalProducts: "TOTAL PRODUCTES",
    youveSuccessfullyAddedANewCategory:
      "Heu afegit amb èxit una nova categoria",
    addNewCategory: "Afegeix una nova categoria",
    addCategory: "Afegeix categoria",
    categoryName: "Nom de la categoria",
    pleaseEnterCategoryName: "Introduïu el nom de la categoria",
    stampsTarget: "Segells objectiu",
    sendInventory: "Enviar inventari",
    productDetails: "Detalls del producte",
    youveSuccessfullyEditedThisCategory:
      "Heu editat amb èxit aquesta categoria",
    update: "Actualització",
    categoryList: "Llista de categories",
    categories: "Categories",
    enterQuantityToUpdate: "Introduïu una quantitat per actualitzar",
    inventorySentSuccessfully: "L'inventari s'ha enviat correctament.",
    updateInventory: "Actualitza l'inventari",
    currentQuantity: "Quantitat actual",
    pleaseEnterQuantityToAdd: "Introduïu la quantitat que voleu afegir",
    pleaseSelectABranch: "Seleccioneu una sucursal",
    searchForBranch: "Cerca de sucursal",
    youCantSendMoreThanStock: "No podeu enviar més del que teniu en estoc",
    send: "Envia",
    pleaseEnterQuantityToSend: "Introduïu la quantitat que voleu enviar",
    productNameIsRequiredOnRow: "el nom del producte és obligatori a la fila",
    productCategoryIsRequiredOnRow:
      "La categoria de producte és obligatòria a la fila",
    productPriceIsRequiredOnRow: "El preu del producte és obligatori a la fila",
    productUnitIsRequiredOnRow: "es requereix unitat de producte a la fila",
    productQuantityIsRequiredOnRow:
      "la quantitat de producte és obligatòria a la fila",
    productVariantsRequireTracking:
      "les variants del producte requereixen un seguiment.",
    pleaseAddVariantClickButton:
      "Afegiu una variant fent clic al botó Afegeix.",
    totalVariantsMoreThanSelectedQuantity:
      "Les variants totals són més que la quantitat de producte seleccionada, si us plau redueixen la quantitat de variants",
    productEditedSuccessfully: "El producte s'ha editat correctament.",
    fileTooLargeLessThan4Mb:
      "La mida del fitxer és massa gran. La mida del fitxer ha de ser inferior a 4 MB.",
    suchVariantAlreadyExist: "Aquesta variant ja existeix",
    addVariants: "Afegiu variants",
    editProduct: "Edita el producte",
    pleaseEnterProductName: "Introduïu el nom del producte",
    pleaseEnterProductPrice: "Introduïu el preu del producte",
    pleaseEnterProductOriginalPrice: "Introduïu el preu original del producte",
    productDescription: "Descripció del producte",
    selectProductCategory: "Seleccioneu la categoria de producte",
    pleaseSelectProductCategory: "Seleccioneu la categoria de producte",
    productCostPrice: "Preu de cost del producte",
    productSellingPrice: "Preu de venda del producte",
    productOriginalPrice: "Preu original del producte",
    maxFileSizeAllowedIs4Mb: "Màx. La mida de fitxer permesa és de 4 MB",
    productsWithPicturesArePublished:
      "Els productes amb imatges es publiquen a Discover per rebre comandes",
    shouldThisProductBeTracked: "Cal fer un seguiment d’aquest producte?",
    pleaseSelectStockUnit: "Seleccioneu unitat d’estoc",
    stockUnit: "Unitat d’estoc",
    bag: "BOSSA",
    bottle: "AMPOLLA",
    bunch: "BUNCH",
    can: "LLAUNA",
    carton: "CAIXA DE CARTRÓ",
    crate: "CAIXA",
    cup: "COPA",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRE",
    pack: "PACK",
    pair: "PARELLA",
    pieces: "PECES",
    plate: "PLATA",
    tonne: "TONNE (MT)",
    uNIT: "UNITAT",
    yard: "PORT",
    pleaseEnterProductQuantity: "Introduïu la quantitat de producte",
    productQuantity: "Quantitat de producte",
    isThisProductTaxed: "Aquest producte està gravat?",
    selectTaxType: "Seleccioneu el tipus d’impost",
    pleaseSelectTaxType: "Seleccioneu Tipus d'impost",
    progressive: "Progressista",
    proportional: "Proporcional",
    pleaseEnterProductTaxRate: "Introduïu el tipus d’impost sobre el producte",
    doesProductHaveVariants: "Aquest producte té variants?",
    type: "Tipus",
    value: "Valor",
    pleaseEnterVariantType: "Introduïu el tipus de variant",
    pleaseEnterVariantValue: "Introduïu el valor de la variant",
    pleaseEnterVariantPrice: "Introduïu el preu de la variant",
    pleaseEnterVariantQuantity: "Introduïu la quantitat de la variant",
    productDeletedSuccessfully: "El producte s'ha suprimit correctament.",
    categoryDeletedSuccessfully: "La categoria s'ha suprimit correctament.",
    toDeleteThisProduct: "per eliminar aquest producte",
    invalidProductQuantity: "Quantitat de producte no vàlida",
    quantityAddedIsOverStock:
      "La quantitat que afegiu és superior a la que teniu en estoc.",
    itemInventoryNotTracked: "No es fa un seguiment de l’inventari d’articles",
    addBulkQuantity: "Afegeix una quantitat massiva",
    enterBulkQuantity: "Introduïu la quantitat massiva",
    pleaseEnterBulkQuantity: "Introduïu la quantitat massiva",
    youveSuccessfullyAddedANewProduct: "Heu afegit amb èxit un producte nou.",
    pleaseEnterProductSellingPrice: "Introduïu el preu de venda del producte",
    doYouWantToTrackProductStock:
      "Voleu fer un seguiment de l'estoc de productes?",
    sellingPrice: "Preu de venda",
    setProductExpiryReminder:
      "Configureu un recordatori de caducitat del producte",
    productExpiryDate: "Data de caducitat del producte",
    startRemindingFrom: "Comenceu a recordar-ho",
    productSuppliesAddedSuccessfully:
      "Heu afegit els subministraments de productes amb èxit.",
    addProductSupplies: "Afegiu subministraments de productes",
    pleaseSelectSupplier: "Seleccioneu el proveïdor",
    nameOfProduct: "Nom del producte",
    pleaseSelectProduct: "Seleccioneu el producte",
    productVariant: "Variant de producte",
    pleaseSelectAVariant: "Seleccioneu una variant",
    pleaseEnterUnitPrice: "Introduïu el preu unitari",
    businessBranch: "Oficina de negocis",
    pleaseSelectBranch: "Seleccioneu una sucursal",
    youveSuccessfullyAddedANewSupplier: "Heu afegit amb èxit un proveïdor nou",
    addSupplier: "Afegeix un proveïdor",
    pleaseEnterSupplierEmail: "Introduïu el correu electrònic del proveïdor",
    pleaseAddADescription: "Afegiu una descripció",
    anErrorOccuredProductsDeleted:
      "S'ha produït un error en realitzar l'operació. Tingueu en compte que és possible que alguns productes s’hagin suprimit durant el procés",
    bulkDelete: "Supressió massiva",
    youAreAboutToDelete: "Esteu a punt de suprimir-lo",
    product: "Producte",
    isDueToRedeem: "s'ha de bescanviar",
    aReward: "una recompensa",
    pleaseSelectCustomerToReeemReward:
      "Seleccioneu un client per bescanviar la recompensa.",
    youHaveNoLoyaltyProgramRunning:
      "No teniu cap programa de fidelització actiu en execució",
    customerHhasNoPointsInLoyaltyProgram:
      "El client no té cap punt en aquest programa de fidelització",
    proceedWithPayment: "Voleu continuar amb el pagament?",
    youAreAboutToPayForTransactionUsingPoints:
      "Esteu a punt de pagar la transacció mitjançant punts.",
    customerHas: "El client té",
    worth: "val la pena",
    andIsNotSufficientToPayForTransaction:
      "i no és suficient per pagar aquesta transacció. Voldrien afegir el saldo mitjançant un altre mètode de pagament?",
    addCustomerLoyalty: "Afegiu la fidelització del client",
    pleaseAddCustomerFirst: "Afegiu o seleccioneu primer un client.",
    pleaseWaitWhileWeGetReady: "Espereu, mentre ens preparem",
    lowStock: "Poc estoc",
    pleaseEnterAmountTendered: "Introduïu l'import ofertat",
    areYouSureToBookSaleOffline:
      "Esteu segur que voleu reservar aquesta venda fora de línia?",
    saleWouldBeBookedAutomatically:
      "La venda es reservaria automàticament quan activeu Internet",
    offlineSalesBookingCancelled:
      "S'ha cancel·lat la reserva de vendes fora de línia",
    covid19Message:
      "COVID19: Renteu-vos les mans amb sabó o desinfecteu per aturar la propagació. Mantingueu-vos sempre segurs",
    saleSuccessfullyRecorded: "La venda s'ha enregistrat correctament.",
    sendReceiptToEmail: "Envia el rebut al correu electrònic",
    sendThankYouSms: "Enviar SMS d’agraïment",
    sendShippingDetails: "Enviar detalls d’enviament",
    addLoyalty: "Afegeix lleialtat",
    searchCustomerNameOrNumber: "Cerqueu el nom o el número de client",
    clickTheSearchCustomerBox:
      "Feu clic al quadre de cerca del client i a la targeta d’escaneig",
    enterProductPrice: "Introduïu el preu del producte",
    enterPriceFor: "Introduïu el preu per a",
    searchForProduct: "Cerca de producte",
    all: "Tots",
    backToDashboard: "Torna al tauler",
    viewDraftSales: "Veure esborrany de vendes",
    variantSelected: "variant seleccionada",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "La selecció de variant anterior no està disponible per a la nova variant seleccionada en funció del preu. Canvieu la selecció.",
    pleaseSupplyPositiveQuantityNumber:
      "Proporcioneu un número de quantitat postiva",
    lowStockFor: "Estoc baix per a",
    clearVariants: "Variants clares",
    pleaseEnterQuantity: "Introduïu Quantitat",
    picture: "Imatge",
    redemptionToken: "Test de redempció",
    token: "Testimoni",
    totalSpent: "Total gastat",
    confirmPayment: "Confirmeu el pagament",
    hasPaymentBeenMade: "El pagament s'ha processat amb èxit?",
    enterTransactionReference:
      "Introduïu la referència de transacció amb què heu pagat",
    pleaseSelectACustomer: "Seleccioneu un client.",
    areYouSureToApplyDiscount: "Esteu segur que voleu aplicar un descompte?",
    addYourBankAccountToEnableUssd:
      "Afegiu el vostre compte bancari per activar la transferència instantània USSD mitjançant uPay",
    totalAmountToPay: "Import total a pagar",
    doYouWantToCancelTransaction: "Voleu cancel·lar aquesta transacció?",
    savePrintBill: "Desa / imprimeix factura",
    enterAmountCollectedForCustomer:
      "Introduïu l'import recollit per al client",
    recordSale: "Venda de discos",
    checkOutWith: "Consulteu amb",
    instantTransfer: "Transferència instantània",
    dialTheUSSDCode: "Marqueu el codi USSD",
    pleaseSelectABank: "Seleccioneu un banc",
    payWithUSSD: "Paga amb USSD",
    sendBillTo: " - Enviar factura a",
    waitingForUSSDTransfer: "S'està esperant la transferència USSD",
    percent: "Percentatge",
    applyDiscount: "Aplica el descompte",
    thisBillHasBeenSaved: "Aquesta factura s'ha desat",
    saveDraft: "Guardar l'esborrany",
    pleaseTypeANameToIdentifyCustomer:
      "Escriviu un nom per identificar el client",
    paymentDetails: "Detalls del pagament",
    basePrice: "Preu base",
    staff: "Personal",
    subTotal: "SubTotal",
    durationMonths: "Durada (mesos)",
    selectADuration: "Seleccioneu una durada",
    oneMonth: "1 mes",
    twoMonths: "2 mesos",
    threeMonths: "3 mesos",
    sixMonths: "6 mesos",
    twelveMonths: "12 mesos",
    eighteenMonths: "18 mesos",
    twentyFourMonths: "24 mesos",
    twoMonthsFree: "(2 mesos gratis)",
    threeMonthsFree: "(3 mesos gratis)",
    fiveMonthsFree: "(5 mesos gratis)",
    yourAccountHasBeen: "El vostre compte ha estat",
    renewed: "renovat",
    upgraded: "actualitzat",
    successfully: "amb èxit",
    yourSubscription: "La vostra subscripció",
    youAreCurrentlyEnrolledOnA: "Actualment esteu inscrit a",
    pleaseChooseAPaymentOption: "Trieu una opció de pagament",
    planRenewal: "Renovació del pla",
    planUpgrade: "Actualització del pla",
    pleaseSelectDurationToPayFor: "Seleccioneu la durada que voleu pagar",
    staffAccounts: "Comptes de personal",
    ecommerce: "Comerç electrònic",
    pleaseSelectAPlan: "Seleccioneu un pla",
    includeAddons: "Inclou complements",
    viewTransactions: "Veure transaccions",
    customerHasNoCompletedTansactions:
      "El client encara no ha completat les transaccions",
    branch: "Sucursal",
    enterNumberOfEcommerceBranches:
      "Introduïu el nombre de sucursals de comerç electrònic",
    enterNumberOfEcommerceBranchesToPay:
      "Introduïu el nombre d'oficines de comerç electrònic que vulgueu pagar",
    ecommerceIntegration: "Integració del comerç electrònic",
    enterNumberOfBranches: "Introduïu el nombre de sucursals",
    enterNumberOfAdditionalBranchesToPay:
      "Introduïu el nombre d'oficines addicionals que vulgueu pagar",
    enterNumberOfStaffs: "Introduïu el nombre de personal",
    enterNumberOfStaffsToPayFor:
      "Introduïu el nombre de personal que vulgueu pagar",
    discountApplies: "S’aplica el descompte",
    starsOnThe: "estrelles al",
    offer: "oferta",
    get: "Aconseguir",
    moreStarsToRedeem: "més estrelles per bescanviar",
    taxVat: "Impostos (IVA)",
    callCashierToCompletePayment: "Truqueu al caixer per completar el pagament",
    receipt: "Rebut",
    dear: "Benvolgut",
    thankYouForYourOrderFindGoods:
      "Gràcies per la seva comanda. Trobeu els següents productes subministrats, tal com s’ha acordat.",
    shippingNote: "Nota d’enviament",
    enterShippingNote: "Introduïu la nota d'enviament",
    shippingAddress: "Adreça d'enviament",
    enterShippingAddress: "Introduïu l'adreça d'enviament",
    wellDoneYouAreDueToRedeem: "Ben fet! Heu de canviar",
    toGetYourRewardNextVisit:
      "per obtenir la vostra recompensa a la propera visita. Gràcies",
    pointsOnThe: "Punts al",
    morePointsToRedeem: "més punts per bescanviar",
    showCode: "Mostra el codi",
    toGetYourRewardOnNextVisit:
      "per obtenir la vostra recompensa a la propera visita. Gràcies",
    earn: "Guanya",
    delivaryNote: "Nota de lliurament",
    draftSales: "Esborrany de vendes",
    startDate: "Data d'inici",
    endDate: "Data de finalització",
    orders: "Comandes",
    checkout: "caixa",
    noProductItem: "Cap article de producte",
    selectProductImage: "Seleccioneu la imatge del producte",
    selectCountry: "Selecciona país",
    selectRegion: "Seleccioneu Estat / Regió",
    printProductTag: "Imprimeix l'etiqueta de producte",
    transactionReference: "Referència de la transacció",
    Cashier: "Caixer",
    Manager: "Gerent",
    Owner: "Propietari",
    Admin: "Administrador",
  },
  Cebuano: {
    cashier: "kahera",
    manager: "manager",
    owner: "tag-iya",
    online: "online",
    offline: "offline",
    changePassword: "Pagbag-o sa Password",
    currentPasswordMessage: "Palihug isulud ang imong karon password",
    passwordMessage: "Palihug isulud ang imong password",
    currentPassword: "Karon nga Password",
    password: "password",
    confirmPasswordMessage: "Palihug kumpirmaha ang imong password!",
    confirmPassword: "Gikumpirma ang Password",
    sendViaEmail: "Ipadala Pinaagi sa Email",
    sendViaWhatsapp: "Ipadala Pinaagi sa WhatsApp",
    downloadPdf: "Pag-download sa PDF",
    paid: "gibayran",
    unpaid: "wala bayad",
    partial: "dili piho",
    closeInvoice: "Gusto ba nimo sirhan ang Invoice?",
    closeInvoiceConfirmation:
      "Ang invoice mahimong dili maluwas. Gusto nimo isira?",
    yes: "oo",
    no: "dili",
    invoice: "Invoice",
    wasDeducted: "gikuha",
    for: "kay",
    item: "Butang",
    addProduct: "Pagdugang Produkto",
    paymentReference: "Bayran sa Bayad",
    amountPaid: "Kadaghan Bayad",
    discountAmount: "Kadaghan sa Diskwento",
    amountDue: "Kadamuon nga Kinahanglan",
    amount: "Kadaghan",
    dueDate: "Takdang Petsa",
    paymentType: "Matang sa Bayad",
    card: "Kard",
    cash: "Salapi",
    bankTransfer: "Pagbalhin sa Bangko",
    paymentMessage: "Mensahe sa Bayad",
    description: "Paghulagway",
    sendReceipt: "Magpadala resibo",
    delete: "Pagtangtang",
    save: "Pagtipig",
    resend: "Pagpadala Pag-usab",
    saveAndSend: "Pagtipig",
    invoiceSaved: "Natipig ang invoice!",
    selectPaymentMethod: "Palihug pagpili usa ka Pamaagi sa Pagbayad!",
    selectCustomer: "Palihug pagpili usa ka Customer!",
    cartEmptyError:
      "Ang Lista sa Cart dili mahimong wala’y sulod, isira ang invoice ug idugang ang Item sa cart!",
    subscriptionExpired:
      "Natapos na ang imong suskrisyon ug gikutuban na ang imong account. Pag-klik sa Button sa ubus aron mabag-o ang imong account",
    renew: "Pagbag-o",
    holdOn: "Pagpugong",
    customerBank: "Customer Bank",
    cancel: "Kanselahon",
    selectACustomer: "Pilia ang Customer",
    invoiceSuccessfulPdfError:
      "Malampuson nga nahimo ang invoice apan ang PDF Generation nagkadugay kaysa naandan. Palihug balik dayon.",
    downloadingInvoice: "Pag-download sa Invoice",
    downloadingReceipt: "Pagdownload sa Resibo",
    whatsappReceiptError:
      "Adunay sayup nga nahimo sa pagpadala sa resibo pinaagi sa WhatsApp, Palihug pagsulay usab.",
    receiptToWhatsapp: "Ang resibo gipasa sa WhatsApp",
    thankYouForPatronage: "Salamat sa imong pagtabang",
    hereIsYourReceipt: "Ania ang imong resibo sa pagbayad",
    errorSendingEmailReceipt:
      "Adunay sayup nga nahimo sa pagpadala sa resibo pinaagi sa e-mail, palihug pagsulay usab o kontak sa suporta",
    receiptSentToEmail: "Ang resibo gipadala na sa email sa kostumer",
    invoiceSentToEmail: "Gipadala ang invoice sa email sa kustomer",
    invoiceSuccessWhatsappError:
      "Malampuson nga nahimo ang invoice apan adunay sayup nga nahimo sa pagpadala sa WhatsApp. Pagsulay usab sa lista sa invoice",
    invoiceSuccessfulEmailError:
      "Malampuson nga nahimo ang invoice apan adunay sayup nga nahitabo samtang nagpadala ingon usa ka e-mail. Palihug pagsulay usab gikan sa lista sa invoice",
    invoiceSentToWhatsapp: "Gipasa ang invoice sa WhatsApp",
    hello: "Kumusta",
    pleaseDownloadInvoice: "Palihug i-download ang invoice",
    pleaseDownloadReceipt: "Palihug i-download ang resibo",
    from: "gikan sa",
    email: "Email",
    upgrade: "Pag-upgrade",
    youAreOnFreePlan: "Naa ka sa usa ka Libre nga Plano.",
    clickOn: "Pag-klik sa",
    yourPlanInFewSteps:
      " ang imong suskrisyon sa pipila ka dali nga mga lakang.",
    to: "sa",
    yourSubscriptionHasExpired:
      "Natapos na ang imong suskrisyon ug gikutuban na ang imong account.",
    days: "adlaw",
    yourSubscriptionExpiresIn: "Natapos ang imong suskrisyon sa Loystar sa",
    createAcount: "Paghimo usa ka Account",
    signIn: "Pag-sign In",
    continue: "Padayon",
    enterOtp: "Pagsulud sa OTP PIN",
    enterValidOtp: "Palihug pagsulud sa usa ka balido nga PIN",
    pin: "PIN",
    tokenSentToMail: "Usa ka timaan ang gipadala sa imong email",
    passwordResetSuccessful: "Malampuson ang pag-reset sa password",
    somethingWentWrong: "Adunay sayup nga nahimo!",
    resetPassword: "I-reset ang Password",
    iHaveResetCode: "Naa koy password reset code",
    pleaseEnterEmail: "Palihug isulud ang imong email",
    aTokenWillBeSentToEmail: "Usa ka timaan ang ipadala sa imong email",
    enterEmail: "Pagsulud sa imong email",
    sendinYourToken: "Gipadala ang imong token ...",
    makeSureItMatchesPassword:
      "Siguruha nga motugma kini sa imong bag-ong password",
    pleaseChooseNewPassword: "Palihug pagpili usa ka bag-ong password",
    chooseNewPassword: "Pagpili usa ka bag-ong password",
    enterNewPassword: "Pagsulud sa imong bag-ong password aron pagkumpirma",
    enterTokenSent: "Pagsulud sa token nga gipadala sa imong mail",
    resetToken: "I-reset ang Token",
    resettingPassword: "Pag-usab sa imong password ...",
    signUp: "Pag-sign Up",
    adminSignInWithEmail:
      " Nag-sign in ang admin gamit ang email samtang ang Staff nagpirma uban ang username.",
    pleaseEnterEmailOrUsername: "Palihug isulud ang imong email o username",
    emailOrUsername: "Email o Username",
    pleaseEnterPassword: "Palihug pagsulud password",
    createAnAccount: "Paghimo usa ka Account",
    forgotPassword: "Nakalimtan ang Password?",
    enterPhoneNumber: "Pagsulud sa Numero sa Telepono",
    personalData: "Personal nga Data",
    validateConfirmationCOde: "Pagpanghimatuud Code sa Pagkumpirma",
    pleaseEnterFirstName: "Palihug isulud ang imong una nga ngalan",
    pleaseEnterPhoneNumber: "Palihug isulud ang imong numero sa telepono",
    pleaseEnterLastName: "Palihug isulud ang imong apelyido",
    pleaseEnterBusinessName: "Palihug isulud ang imong ngalan sa negosyo",
    firstName: "Unang Ngalan",
    lastName: "Katapusan nga Ngalan",
    businessName: "ngalan sa negosyo",
    previous: "Kaniadto",
    next: "Sunod",
    pleaseSelectBusinessCategory:
      "Palihug pilia ang imong kategorya sa negosyo",
    pleaseEnterValidEmail: "Palihug pagsulud usa ka balido nga email",
    pleaseEnterPostCode: "Palihug pagsulud post code",
    postCode: "Post Code",
    phoneNumberInUse: "Kini nga numero sa telepono gigamit na!",
    emailInUse: "Gigamit na kini nga email!",
    foodAndDrinks: "Pagkaon ug Inom",
    salonAndBeauty: "Salon ug Kaanyag",
    fashionAndAccessories: "Fashion ug Mga Aksesorya",
    gymAndFitness: "Gym ug Fitness",
    travelAndHotel: "Pagbiyahe ug Hotel",
    homeAndGifts: "Balay ug Gasa",
    washingAndCleaning: "Paglaba ug Paglimpyo",
    gadgetsAndElectronics: "Mga gadget ug Elektronikon",
    groceries: "Mga Groceries",
    others: "Ang uban pa",
    submit: "Isumite",
    accountCreatedSuccessful: "Malampuson nga nahimo ang imong account.",
    pleaseEnterAddress: "Palihug Isulud ang Imong Address",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    choosePassword: "Pilia ang Password",
    passwordMustBe6Characters:
      "Ang password kinahanglan dili moubus sa 6 nga mga karakter.",
    howDidYouHearLoystar: "Giunsa nimo nabati ang bahin sa Loystar?",
    referralCode: "referral Code",
    byClickingTheButton: " Pinaagi sa pag-klik sa buton sa ubus, uyon ka sa",
    termsAndSevice: "Mga termino",
    wereCreatingAccount: "Gihimo namon ang imong account",
    proceed: "Pagpadayon",
    verificationCodeMustBe6:
      "Ang Verification Code kinahanglan adunay 6 nga numero",
    pleaseEnter6DigitCode: "Palihug Isulud ang 6 digit nga code",
    enterVerificationCode: "Pagsulud sa Verification Code",
    resendToken: "Ipadala usab ang Token",
    verify: "Pagpanghimatuud",
    transactions: "Mga Transaksyon",
    todaySales: "Pagbaligya Karon",
    salesHistory: "Kasaysayan sa Pagbaligya",
    supplyHistory: "Kasaysayan sa Paghatag",
    new: "Bag-o nga",
    invoices: "Mga Invoice",
    disbursements: "Paghatag",
    offlineSales: "Pagbaligya sa Offline",
    products: "Mga Produkto",
    customers: "Mga kustomer",
    multiLevelLoyalty: "Multi-Level Loyalty",
    loyaltyPrograms: "Mga Program sa Pagkamaunongon",
    members: "Mga myembro",
    appStore: "App Store",
    orderMenu: "Menu sa Pag-order",
    settings: "Mga setting",
    staffAndBranches: "Mga kawani ug Sanga",
    myAccount: "Akong Account",
    switchToSellMode: "Pagbalhin sa Sell Mode",
    signOut: "Pag-sign Out",
    getFreeSubscription: "Pagkuha Libre nga Suskrisyon",
    onlyNumbersAllowed: "Mga numero ra ang gitugotan",
    yourAccountMustBe10Digits:
      "ang imong numero sa account kinahanglan nga usa ka 10 digit nga numero",
    yourBvnMustBe11: "ang imong BVN kinahanglan usa ka 11 nga numero",
    pleaseSelectBank: "Palihug pilia ang imong bangko",
    selectYourBank: "Pilia ang imong Bangko",
    enterBankAccountNumber: "Pagsulud sa Numero sa Bank Account",
    enterBvnNumber: "Pagsulud sa imong BVN",
    connectBankAccount: "Ikonektar ang Bank Account",
    passwordResetSuccessfulAndSignOut:
      "Ang imong Password malampuson nga na-reset. Pag-klik sa button nga Pag-sign Out sa ubus aron maka-signin pag-usab",
    enterCurrentPassword: "Pagsulud karon password",
    pleaseEnterCurrentPassword: "Palihug isulud ang karon nga password",
    phoneNumber: "Numero sa Telepono",
    sex: "Sekso",
    dateOfBirth: "Petsa sa Pagkatawo",
    state: "Estado",
    country: "Nasud",
    loyaltyId: "Loyalty ID",
    createdAt: "Gihimo At",
    noOfTransactionsMade: "Wala sa mga transaksyon nga gihimo",
    yourDownloadWillStart: "Ang imong pag-download magsugod kadiyot",
    exportCustomers: "I-export ang mga Kustomer",
    youhaveSuccessfullyToppedUp:
      "Malampuson nga napuno nimo ang imong mga SMS Units.",
    youNowHave: "Naa na nimo",
    smsUnits: "Mga Yunit sa SMS",
    enterNumberOfSmsUnits:
      "Pagsulud sa Gidaghan sa mga Yunit sa SMS nga Gitinguha Nimo nga Pagpalit",
    pleaseEnterANumericValue: "Palihug pagsulud sa usa ka kantidad nga numero",
    pay: "Bayri",
    accountEditedSuccessfully: "Malampuson nga na-edit ang imong account.",
    youAeCurrentlyOn: "Naa ka karon",
    plan: "Plano",
    userData: "Data sa Gumagamit",
    businessData: "DATA SA NEGOSYO",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Palihug pili ang imong kwarta",
    selectYourCurrency: "Pilia ang imong kwarta",
    purchaseMoreSmsUnits:
      "Pagpalit daghang mga Yunit sa SMS gamit ang porma sa ubus",
    youHave: "Naa nimo",
    atLeast4SmsUnitsRrequired:
      "Labing menos 4 nga mga yunit sa sms ang gikinahanglan alang sa panghimatuud, palihug i-top up!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Palihug pamatud-i ang imong account sa bangko aron masunod ang mga kinahanglanon nga 'Hibal-i ang Imong Customer' (KYC). Tugotan ka nga makolekta ang mga bayad pinaagi sa USSD o instant transfer, makadawat mga order gikan sa mga kostumer ug maproseso ang paghusay sa transaksyon. Naa sa aplikante ra sa mga negosyante nga taga-Nigeria. Palihug pindota ang buton sa ubus aron magsugod.",
    reConnectBankAccount: "Pagsumpay pag-usab sa Bank Account",
    accountName: "Ngalan sa Account",
    accountNumber: "Numero sa Account",
    bankName: "Ngalan sa Bangko",
    verified: "Napamatud-an",
    accountDetails: "Mga Detalye sa Account",
    kycBankAccount: "KYC",
    createTier: "Paghimo Tier",
    fileUploadSuccessful: "malampuson nga gi-upload ang file",
    fileUploadFailed: "pakyas nga pag-upload sa file",
    createLoyaltyProgram: "Paghimo usa ka Loyalty Program",
    createLoyalty: "Paghimo Pagkamaunongon",
    name: "Ngalan",
    loyaltyArtwork: "Pag-alagad sa Pagkamaunongon",
    clickToUpload: "Pag-klik aron ma-upload",
    amountToPointsRatio: "Kadaghan sa Ratio sa Mga Punto",
    points: "Mga Punto",
    recommendedAmountToPointRatio:
      "Girekomenda: ₦ 1 hangtod 1point. ie alang sa matag 1 Naira nga gigasto, ang imong mga kostumer makakuha 1 puntos",
    pleaseTypeIn: "Palihug pag-type",
    toDeleteLoyalty: "aron mapapas ang pagkamaunongon",
    deleteLoyalty: "Pagtangtang sa Pagkamaunongon",
    toConfirm: "aron pagkumpirma",
    edit: "Pag-edit",
    pointsAwardedSuccessfully: "Malampuson nga Gihatag nga Punto.",
    enterPointValueToAward:
      "Pagsulud sa Halaga nga Punto nga Gusto Nimo Hatagan Mga Kustomer",
    award: "Ganti",
    awardPointValuesToCustomer: "Gihatagan Bahin sa Award Point sa Customer",
    enrollMembersToLoyalty: "Pagpalista sa mga Miyembro ngadto sa Loyalty",
    awardPoints: "Mga Punto sa Ganti",
    enroll: "Pagpalista",
    home: "Puy-anan",
    loyalty: "Pagkamaunongon",
    enrollCustomers: "Pagpalista sa mga Kustomer",
    selected: "Gipili",
    customer: "Kustomer",
    loyaltyActivationSuccessful:
      "Malampuson ang Pagpa-aktibo sa Pagkamaunongon.",
    loyaltyDeactivationSuccessful: "Ang Loyalty Deactivation malampuson.",
    viewTier: "Tan-awa ang Tier",
    deactivate: "De-pagpaaktibo",
    activate: "Pagpaaktibo",
    actions: "Mga lihok",
    nameOfLoyalty: "Ngalan sa Pagkamaunongon",
    loyaltyStatus: "Kahimtang sa Pagkamaunongon",
    numberOfTiers: "Gidaghan sa mga Tier",
    createdOn: "Gibuhat Sa",
    createATier: "Paghimo usa ka Tier",
    stopCreatingTierConfirmation:
      "Gusto ba nimo ihunong ang paghimo og usa ka hut-ong?",
    stopEditingTierConfirmation:
      "Gusto ba nimo ihunong ang pag-edit sa kini nga hut-ong?",
    nameOfTier: "Ngalan sa Tier",
    minimumSpendingTarget: "Minimum nga Target sa Paggasto",
    maximumSpendingTarget: "Maximum Target sa Paggasto",
    minimumSpendingTargetRequired:
      "minimum nga target sa paggasto ang gikinahanglan",
    maximumSpendingTargetRequired:
      "maximum target sa paggasto ang gikinahanglan",
    rewardSponsor: "Tigpasiugda sa Ganti",
    pleaseChooseARewardSponsor: "Palihug pagpili usa ka tigpasiugda sa ganti",
    self: "Kaugalingon",
    partner: "Kauban",
    rewardPartner: "Ganti sa Ganti",
    pleaseSelectRewardPartner: "Palihug pilia ang imong kauban nga moganti",
    rewards: "Mga Ganti",
    pleaseSelectAReward: "Palihug pagpili usa ka ganti",
    instructionsOnRedeemingReward:
      "Mga Panudlo sa Giunsa nga Kinahanglan Magtubos ang Customer sa Ganti",
    pleaseFillInThisField: "Palihug sulati kini nga Natad!",
    toDeleteThisTier: " aron mapapas kini nga hut-ong",
    deleteTier: "Pagtangtang sa Tier",
    viewMembers: "Tan-awa ang mga Miyembro",
    membersEnrolled: "Mga Miyembro nga Gienrol",
    instruction: "Panudlo",
    membersIn: "Mga miyembro sa",
    availableTiersInLoyalty: "Magamit ang (mga) Tier sa Loyalty Program",
    tiers: "Mga tier",
    totalTier: "TOTAL NGA TIER",
    availableLoyaltyProgramme: "Magamit nga Programa sa Pagkamaunongon",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Mga Detalye sa Miyembro",
    nameOfCustomer: "Ngalan sa Customer",
    address: "Address",
    allEnrolledMembers: "Tanan nga mga Miyembro nga Naenrol",
    thisIsToWishYouHappyBirthday:
      "Kini aron malipay ako kanimo usa ka malipayon nga adlaw nga natawhan ug mauswagon nga kinabuhi. Salamat sa tanan nga naa ka kay Loyster. Malipayong Pagsaulog!",
    inputAnOfferPlease: "Pagpasok usa ka Tanyag nga palihug",
    pleaseSelectTheInsertPoint:
      "Palihug pilia ang insert point sa mensahe ug pag-klik usab",
    birthdayOfferAndMessage: "Paghalad sa Kaadlaw ug Mensahe",
    birthdayOffer: "Paghalad sa Kaadlawon",
    birthdayMessage: "Mensahe sa Pagkatawo",
    noOfferFound: "Wala nakit-an nga tanyag",
    settingABirthdayOffer:
      "Ang pagtakda sa usa ka tanyag sa adlawng natawhan nagtugot sa mga kustomer nga makadawat kini nga tanyag pinaagi sa SMS sa ilang adlawng natawhan",
    createOffer: "Paghimo Tanyag",
    whatIsTheOffer: "Unsa ang tanyag?",
    editMessage: "Usba ang Mensahe",
    insert: "Isuksok",
    theNameOfCustomerInserted: "Ang ngalan sa kostumer isal-ot dinhi",
    theBirtdayOfferInserted: "Isal-ot dinhi ang tanyag sa adlawng natawhan",
    youSuccessfullyAddedNewBranch:
      "Malampuson ka nga nakadugang usa ka bag-ong sanga!",
    addNewBranch: "Pagdugang Bag-ong Sanga",
    addBranch: "Idugang ang Sanga",
    additionalBranchWillIncur: "Dugang nga Sangang mangaigo",
    perBranch: "matag sanga",
    ecommerceBranchCosts: "Mga gasto sa sanga sa Ecommerce",
    pleaseEnterBranchName: "Palihug isulud ang ngalan sa sanga",
    pleaseEnterBranchAddress1:
      "Palihug isulud ang adres nga linya sa sangang 1",
    enterBranchAddress1: "Pagsulud sa adres nga linya sa sangang 1",
    enterBranchAddress2: "Pagsulud sa adres nga linya sa sangang 1",
    pleaseEnterBranchAddress2: "Palihug isulud ang linya sa adres sa sanga 2",
    enterBranchName: "Isulud ang ngalan sa sanga",
    successfullyAddedStaff:
      "Malampuson ka nga nakadugang usa ka bag-ong kawani!",
    addNewStaff: "Pagdugang Bag-ong kawani",
    addStaff: "Pagdugang Staff",
    additionalStaffWillIncur: "Dugang nga kawani ang maangkon",
    perStaff: "matag kawani.",
    pleaseEnterStaffEmail: "Palihug ipasok ang email sa kawani",
    pleaseEnterStaffUsername: "Palihug isulud ang username sa kawani",
    pleaseEnterStaffPassword: "Palihug isulud ang password sa kawani",
    pleaseSelectStaffRole: "Palihug pilia ang tahas sa kawani",
    selectStaffRole: "Pilia ang tahas sa kawani",
    enterStaffEmail: "Pagsulud sa email sa kawani",
    enterStaffUsername: "Pagsulud sa username sa kawani",
    enterStaffPassword: "Pagsulud password sa kawani",
    spacesNotAllowedInUsername: "wala gitugotan ang mga wanang sa username",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Palihug pili-a ang negosyo aron ikabit ang kawani",
    searchForBranchToAttachStaff:
      "Pagpangita alang sa sanga aron ikabit ang kawani",
    username: "Username",
    role: "Papel",
    areYouSureToDeleteThis: "Sigurado ba nga mapapas nimo kini",
    branches: "Mga sanga",
    upgradeYourPlan: "Pag-upgrade sa imong Plano.",
    add: "ADD",
    addNew: "Pagdugang Bag-o",
    customerWithEmailAlreadyExists:
      "Ang customer nga adunay email / numero sa telepono naa na!",
    successfullyAddedNewCustomer:
      "Malampuson ka nga nakadugang usa ka bag-ong kustomer!",
    addCustomer: "Idugang ang Customer",
    pleaseEnterCustomerFirstName:
      "Palihug isulud ang una nga ngalan sa kustomer",
    pleaseEnterCustomerLastName: "Palihug isulud ang apelyido sa kostumer",
    pleaseEnterCustomerPhoneNumber:
      "Palihug isulud ang numero sa telepono sa kustomer",
    pleaseEnterCustomerEmail: "Palihug ipasok ang email sa kostumer",
    pleaseEnterCustomerAddressLine:
      "Palihug isulud ang Address Line sa kostumer",
    pleaseEnterCustomerOtherAddress:
      "Palihug isulud ang ubang Address sa kustomer",
    pleaseSelectCustomerGender: "Palihug pili ang gender sa kustomer",
    gender: "Kinatawo",
    male: "Lalaki",
    female: "Babaye",
    bank: "Bangko",
    selectBank: "Pilia ang Bangko",
    stateOrRegionOrProvince: "Estado / Rehiyon / Lalawigan",
    customerNotes: "Mga Mubo nga sulat sa Customer",
    sendSms: "Magpadala SMS",
    editCustomer: "Pag-edit sa Customer",
    redeemReward: "Pagtubos sa Ganti",
    issueLoyaltyCard: "Isyu ang Kard sa Pagkamaunongon",
    deleteCustomer: "Pagtangtang sa Customer",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Malampuson nimo nga Natudlo ang usa ka Loyalty Membership ID",
    noMembershipIdAvailable:
      "Wala’y magamit nga mga membership ID. Palihug kontaka kami sa hello@loystar.co",
    sendEmail: "Magpadala Email",
    membershipPoints: "Mga Punto sa Pagkamiyembro",
    customerDetails: "Mga Detalye sa Customer",
    close: "Suod nga",
    loyaltyBalance: "Pagkatimbang sa Pagkamaunongon",
    pointsBalance: "Mga Balanse sa Punto",
    starBalance: "Balanse sa Bituon",
    requiredPoints: "Gikinahanglan nga Punto",
    requiredStars: "Gikinahanglan nga Mga Bituon",
    reddemCode: "Pagtubos Code",
    toDeleteThisCustomer: "aron mapapas kini nga kustomer",
    customerHasBeenDeletedSuccessfully:
      "Malampuson nga natanggal ang kostumer!",
    customerWithPhoneAlreadyExists:
      "Ang customer nga adunay numero sa telepono naa na",
    customerWasSuccessfullyEdited: "Malampuson nga na-edit ang kostumer",
    anErrorOccured: "Adunay sayup nga nahinabo",
    phoneNumberIncludeCountry: "Numero sa Telepono (Ilakip ang Country Code)",
    yourFileQueuedForUpload:
      "Ang imong file gilinya alang sa pag-upload. Palihug i-refresh ang panid pagkahuman sa pipila ka segundo.",
    thereWasAnErrorPerformingOperation:
      "Adunay usa ka sayup sa paghimo sa operasyon!",
    pleaseSelectFile: "Palihug Pagpili usa ka File!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Ang mga CSV file ra ang gitugotan. Palihug i-upload ang usa ka .csv file.",
    fileShouldBeSmallerThan5Mb:
      "Ang file kinahanglan nga labing gamay sa 5MB. Kung adunay ka daghang file, palihug email sa support@loystar.co. Salamat",
    customerFirstNameIsRequired:
      "gikinahanglan ang ngalan sa kostumer sa laray",
    customerPhoneNumberIsRequired:
      "Kinahanglan ang numero sa telepono sa kustomer sa laray",
    importCustomers: "Pag-import sa mga Kustomer",
    upload: "Pag-upload",
    clickIconToDownloadCsv:
      "Pag-klik sa kini nga icon aron ma-download ang template sa CSV file.",
    getGoogleSheetVersion: "Kuhaa ang bersyon sa sheet sa Google",
    clickOrDragFileToUpload:
      "Pag-klik o pag-drag file ngadto sa kini nga lugar aron ma-upload",
    missingOrInvalidColumnHeader:
      "Nawala o dili husto nga ulohan sa kolum. Palihug sunda ang format sa template. Salamat.",
    youHaveImported: "Imong Gipahinungdan",
    youSuccessfullyRedeemedReward:
      "Malampuson nimo nga natubos ang imong ganti!",
    sixDigitCode: "Unom nga digit nga code",
    pleaseEnterCustomerRewardCode:
      "Palihug isulud ang code sa gantimpala sa kustomer",
    enterRewardCode:
      "Pagsulud sa reward code. (Ang reward code sensitibo sa kaso)",
    selectLoyaltyProgram: "Pilia ang programa sa pagkamaunongon",
    stamps: "Mga selyo",
    smsUnitsLow: "Ang Mga Yunit sa SMS Mabusdos",
    pleaseTopUpSmsUnits: "Palihug Top Up SMS Units",
    smsSuccessfullySentForDelivery:
      "Malampuson nga gipadala ang SMS alang sa paghatud!",
    sendSmsTo: "Magpadala SMS sa",
    allCustomers: "Tanan nga mga Kustomer",
    unitsAvailable: "Adunay Mga Yunit",
    pleaseTypeInTheMessage: "Palihug i-type ang mensahe",
    message: "Mensahe",
    charactersRemaining: "nabilin nga mga karakter",
    avoidUseOfSpecialCharacters:
      "Paglikay sa paggamit sa mga Espesyal nga Mga Karakter ug Emojis aron makatipig mga Yunit sa SMS.",
    note: "Hinumdomi",
    errorFetchingCustomersMultilevelDetail:
      "Sayup sa Pagkuha sa Mga Detalye sa Mga Customer MultiLevel Mga Detalye",
    search: "Pagpangita",
    reset: "Pag-usab",
    importCustomer: "Pag-import sa Kustomer",
    addNewCustomer: "Pagdugang Bag-ong Customer",
    sendSmsBroadcast: "Magpadala og Broadcast sa SMS",
    totalCustomers: "TOTAL NGA MGA CUSTOMER",
    disbursementDetails: "Mga Detalye sa Paghatag",
    paidBy: "Gibayran Ni",
    disbursed: "Gipadala",
    bankAccountName: "Ngalan sa Bank Account",
    bankAccountNumber: "Numero sa Bank Account",
    transferInitiated: "Gisugdan ang Pagbalhin",
    transferCompleted: "Nahuman ang Pagbalhin",
    pleaseEnterAValid: "Palihug pagsulud sa usa ka balido",
    begin: "magsugod",
    end: "katapusan",
    date: "petsa",
    paymentDate: "Petsa sa Pagbayad",
    selectDisbursementDuration: "Pilia ang Kadugayon sa Paghatag",
    totalSettled: "Tibuuk nga Nahusay",
    totalUnsettled: "Tibuuk nga Wala’y kahimtang",
    toDeleteThisSale: "aron mapapas ang kini nga pagbaligya",
    draftSaleDeletedSuccessfully:
      "Malampuson nga natapos ang pagbaligya sa draft!",
    deleteSale: "I-delete ang Pagbaligya",
    pleaseTypeInYourTotal:
      "Palihug i-type ang imong kinatibuk-an aron kumpirmahon ang pagtangtang",
    billDetails: "Mga Detalye sa Bill",
    printBill: "Bill sa Pag-print",
    servedBy: "Gisilbihan ni",
    total: "Total",
    createdDate: "Gibuhat nga Petsa",
    createdTime: "Gilalang nga Oras",
    status: "Kahimtang",
    loginSuccessful: "Malampuson ang pag-login",
    pleaseWaitWhileWeConnectAccount:
      "Palihug paghulat samtang nagkonektar kami sa imong account",
    connectionFailedTryAgain: "Napakyas ang koneksyon Palihug pagsulay usab.",
    connectionSuccessful: "Malampuson nga koneksyon",
    viewDetails: "Tan-awa ang Mga Detalye",
    enable: "Makahimo",
    free: "Libre",
    cost: "Gasto",
    visitWebsite: "Bisitaha ang Website",
    pleaseUpgradeYourPlanToPro:
      "Palihug Pag-upgrade sa imong plano sa Pro Plus aron mapagana kini nga app",
    connectYourBankAccount:
      "Ikonektar ang imong Bank Account aron makadawat mga bayad.",
    disable: "Pagdili",
    enableApp: "Pagpalihok sa App",
    addNewProductToInvoice: "Pagdugang Bag-ong Produkto sa Invoice",
    toDeleteThisInvoice: "aron mapapas kini nga Invoice",
    invoiceDeletedSuccessfully: "Malampuson nga natapos ang invoice!",
    deleteInvoice: "Pagtangtang sa Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Malampuson nga nakadugang ka usa ka mensahe sa pagbayad sa Invoice!",
    pleaseEnterPaymentInstructions:
      "Palihug ipasok ang mga panudlo sa pagbayad",
    invoiceId: "ID sa invoice",
    paidDate: "Bayad nga Petsa",
    reference: "Pakisayran",
    productAdded: "Gidugang ang produkto",
    productOutOfStock: "Wala’y stock ang produkto. Palihug pag-stock usab.",
    totalInvoices: "TOTAL NGA INVOICES",
    totalPaidInvoices: "TOTAL NGA NABAYARAN NGA INVOICES",
    totalUnpaidInvoices: "TOTAL NGA INVOICES NGA Wala’y PAPAID",
    loyaltyProgramDeleted: "Gitangtang ang Loyalty Program",
    thisLoyaltyProgramDeletedSuccessfully:
      "Malampuson nga natapos ang kini nga programa sa pagkamaunongon",
    thisLoyaltyProgramEditedSuccessfully:
      "Kini nga programa sa pagkamaunongon malampuson nga na-edit",
    loyaltyProgramAddedSuccessfully:
      "Ang programa sa pagkamaunongon malampuson nga nadugang",
    loyaltyProgramEdited: "Gi-edit ang Loyalty Program",
    loyaltyProgramAdded: "Gidugang ang Program sa Pagkamaunongon",
    loyaltyDetails: "Mga Detalye sa Pagkamaunongon",
    doYouWantToCloseDialog: "Gusto ba nimo isira kini nga dayalogo?",
    pleaseEnterLoyaltyName: "Palihug isulud ang ngalan sa imong pagkamaunongon",
    programType: "Klase sa Programa",
    pleaseSelectProgramType: "Palihug pagpili usa ka tipo sa programa",
    simplePoints: "Yano nga Mga Punto",
    stampsProgram: "Programa sa Selyo",
    threshold: "Ambang",
    pleaseEnterLoyaltyThreshold:
      "Palihug pagsulud sa threshold sa pagkamaunongon",
    reward: "Ganti",
    pleaseEnterLoyaltyReward: "Palihug isulud ang ganti sa pagkamaunongon",
    totalUserPoints: "Total nga Punto sa Gumagamit",
    totalUserStamps: "Total nga mga Stamp sa Gumagamit",
    spendingTarget: "Target sa paggasto",
    spendingTargetHint1:
      "Target sa paggasto kung pila ang kinahanglan gasto sa usa ka kostumer aron makuha ang ganti. 1 nga kantidad sa kwarta = 1 nga punto.",
    spendingTargetHint2:
      "Ang target sa mga selyo kung pila ka mga selyo ang kinahanglan kolektahon sa kostumer aron makuha ang ganti. pananglitan 5",
    addNewLoyaltyProgram: "Pagdugang Bag-ong Programa sa Pag-unong",
    addLoyaltyProgram: "Idugang ang Loyalty Program",
    loyaltyProgramType: "Matang sa Program sa Pagkamaunongon",
    pleaseSelectLoyaltyProgramType:
      "Palihug pilia ang tipo sa programa sa pagkamaunongon",
    nameOfProgram: "Ngalan sa programa",
    pleaseEnterProgramName: "Palihug isulud ang ngalan sa programa",
    whatIsTheReward: "Unsa man ang ganti?",
    egNextPurchaseIsFree: "Pananglitan Ang sunod nga pagpamalit libre",
    customerName: "Ngalan sa Customer",
    guestCustomer: "Bisita nga Kustomer",
    orderConfirmedSuccessfully: "Malampuson nga nakumpirma ang order",
    orderCancelledSuccessfully: "Malampuson nga nakansela ang order",
    confirmOrder: "Gikumpirma ang han-ay",
    cancelOrder: "Kanselahon ang han-ay",
    allOrders: "Tanan nga Order",
    totalOrders: "TOTAL NGA MGA ORDER",
    doYouWantToAcceptOrder: "Gusto ba nimo madawat kini nga order?",
    doYouWantToCancelOrder: "Gusto ba nimo nga kanselahon kini nga order?",
    orderDetails: "Mga Detalye sa Pag-order",
    orderCreatedAt: "Ang pagmando gimugna sa",
    supplier: "Taghatag",
    productName: "Ngalan sa Produkto",
    quantity: "Kadaghan",
    unitPrice: "Yunit sa Presyo",
    receivedBy: "Nadawat Ni",
    reportFrom: "Pagreport gikan sa",
    totalSupplies: "Total nga Mga Panghatag",
    allRightsReserved: "Gireserba ang Tanan nga mga Katungod",
    toDeleteThisTransaction: "aron mapapas kini nga Transaksyon",
    transactionDeletedSuccessfully:
      "Malampuson nga natapos ang transaksyon. Ang stock gibalik sa imbentaryo.",
    deleteTransaction: "Pagtangtang sa Transaksyon",
    transactionDetails: "Mga Detalye sa Transaksyon",
    printReceipt: "Resibo sa Pag-print",
    channel: "Channel",
    discount: "Diskwento",
    profit: "Kita",
    discountedSales: "Gibaligya nga Gibaligya",
    errorFetchingRecord: "Sayup nga Rekord sa Pagkuha",
    exportTransactions: "Pagbalhin sa mga Transaksyon",
    errorFetchingSalesRecord:
      "Sayup sa pagkuha sa rekord sa Sales alang sa Export.",
    totalSellingPrice: "Total nga Presyo sa Pagbaligya",
    totalCostPrice: "Total nga Presyo sa gasto",
    appliedDiscount: "Giapil nga Discount",
    noOfItems: "Dili sa mga butang",
    sales: "Pagbaligya",
    export: "I-export",
    totalProfit: "Total nga Kita",
    totalBalanceInPeriod: "Total nga Balanse sa Panahon",
    allTimeSales: "Tanan nga Pagbaligya sa Oras",
    records: "Mga talaan",
    todaysSales: "Pagbaligya Karon",
    transaction: "transaksyon",
    youHaveExceededTotalNumberOfProducts:
      "Milapas ka sa kinatibuk-ang ihap sa mga produkto nga gitugotan sa imong plano. Pag-upgrade sa imong plano aron makatagamtam sa labi ka taas nga limitasyon.",
    youNeedToHaveLoyaltyProgram:
      "Kinahanglan nimo nga adunay usa ka Loyalty Program aron magamit kini nga bahin!",
    price: "Presyo",
    category: "Kategoryang",
    stockTracking: "Pagsubay sa Stock",
    stockCount: "Count sa Stock",
    taxed: "Gisulayan",
    originalPrice: "Orihinal nga Presyo",
    costPrice: "Presyo sa gasto",
    unit: "Yunit",
    productImage: "Hulagway sa Produkto",
    taxRate: "Bayad sa Buhis",
    taxType: "Matang sa Buhis",
    trackInventory: "Pagsubay sa Imbentaryo",
    variants: "Mga lahi",
    hasVariants: "Adunay Mga Lainlain",
    importProduct: "Pag-import sa Produkto",
    exportProducts: "Mga Produkto nga Export",
    addNewProduct: "Pagdugang Bag-ong Produkto",
    viewCategory: "Tan-awa ang kategorya",
    viewSuppliers: "Tan-awa ang Mga Tagahatag",
    receiveInventory: "Pagdawat Inventory",
    printAllProductsTag: "I-print ang Tanan nga Mga Produkto",
    deleteAll: "Delete tanan",
    totalProducts: "TOTAL NGA PRODUKTO",
    youveSuccessfullyAddedANewCategory:
      "Malampuson ka nga nakadugang usa ka bag-ong kategorya",
    addNewCategory: "Pagdugang Bag-ong kategorya",
    addCategory: "Idugang ang kategorya",
    categoryName: "Ngalan sa kategorya",
    pleaseEnterCategoryName: "Palihug pagsulud sa ngalan sa kategorya",
    stampsTarget: "Target sa Mga Selyo",
    sendInventory: "Pagpadala Imbentaryo",
    productDetails: "Mga Detalye sa Produkto",
    youveSuccessfullyEditedThisCategory:
      "Malampuson nimo nga na-edit kini nga kategorya",
    update: "Pagbag-o",
    categoryList: "Lista sa kategorya",
    categories: "Mga kategorya",
    enterQuantityToUpdate: "Pagsulud usa ka gidaghanon aron ma-update",
    inventorySentSuccessfully: "Malampuson nga gipadala ang imbentaryo!",
    updateInventory: "Pag-update sa Imbentaryo",
    currentQuantity: "Karon nga Kadaghan",
    pleaseEnterQuantityToAdd:
      "Palihug isulud ang gidaghanon nga gusto nimong idugang",
    pleaseSelectABranch: "Palihug pagpili usa ka Sanga",
    searchForBranch: "Pagpangita sanga",
    youCantSendMoreThanStock: "Dili ka makapadala labi pa sa naa sa stock",
    send: "Ipadala",
    pleaseEnterQuantityToSend:
      "Palihug isulud ang gidaghanon nga gusto nimo ipadala",
    productNameIsRequiredOnRow: "ngalan sa produkto kinahanglan sa laray",
    productCategoryIsRequiredOnRow:
      "kinahanglan ang kategorya sa produkto sa laray",
    productPriceIsRequiredOnRow: "kinahanglan ang presyo sa produkto sa laray",
    productUnitIsRequiredOnRow: "produkto yunit kinahanglan sa laray",
    productQuantityIsRequiredOnRow:
      "gikinahanglan ang gidaghanon sa produkto sa laray",
    productVariantsRequireTracking:
      "ang mga lahi sa produkto nanginahanglan pagsubay!",
    pleaseAddVariantClickButton:
      "Palihug pagdugang usa ka lahi pinaagi sa pag-klik sa add button!",
    totalVariantsMoreThanSelectedQuantity:
      "Ang kinatibuk-ang Mga Variant labaw pa sa gipili nga gidaghanon sa produkto, gipaminusan sa Pls ang kadaghan sa lainlain",
    productEditedSuccessfully: "Ang produkto malampuson nga na-edit!",
    fileTooLargeLessThan4Mb:
      "Labing kadako sa file! Ang kadako sa file kinahanglan nga mas mubu sa 4MB.",
    suchVariantAlreadyExist: "Ang ingon nga lahi adunay na",
    addVariants: "Pagdugang Mga Dagway",
    editProduct: "Pag-edit sa Produkto",
    pleaseEnterProductName: "Palihug Pagsulud sa Ngalan sa Produkto",
    pleaseEnterProductPrice: "Palihug Pagsulud sa Presyo sa Produkto",
    pleaseEnterProductOriginalPrice:
      "Palihug Pagsulud sa Orihinal nga Presyo sa Produkto",
    productDescription: "Paghulagway sa Produkto",
    selectProductCategory: "Pilia ang Kategoryang Produkto",
    pleaseSelectProductCategory: "Palihug pilia ang kategorya sa produkto",
    productCostPrice: "Presyo sa gasto sa produkto",
    productSellingPrice: "Presyo sa Pagpamaligya sa Produkto",
    productOriginalPrice: "Produkto nga Orihinal nga Presyo",
    maxFileSizeAllowedIs4Mb: "Max. Gitugotan ang Kadako sa file nga 4mb",
    productsWithPicturesArePublished:
      "Ang mga produkto nga adunay litrato gipatik sa Discover aron makadawat mga order",
    shouldThisProductBeTracked: "Kinahanglan ba masundan kini nga produkto?",
    pleaseSelectStockUnit: "Palihug pili ang unit sa stock",
    stockUnit: "Stock Unit",
    bag: "BAG",
    bottle: "BOTOL",
    bunch: "BUNCH",
    can: "MAHIMO",
    carton: "KARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "YUNIT",
    yard: "Yard",
    pleaseEnterProductQuantity: "Palihug pagsulud kadaghan sa produkto",
    productQuantity: "Kadaghanan sa Produkto",
    isThisProductTaxed: "Buhis ba kini nga produkto?",
    selectTaxType: "Pilia ang Matang sa Buhis",
    pleaseSelectTaxType: "Palihug pili ang Matang sa Buhis",
    progressive: "Progresibo",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Palihug isulud ang rate sa buhis sa produkto",
    doesProductHaveVariants: "Kini ba nga produkto adunay lainlain?",
    type: "Matang",
    value: "Bili",
    pleaseEnterVariantType: "Palihug isulud ang lahi sa lahi",
    pleaseEnterVariantValue: "Palihug isulud ang kantidad sa variant",
    pleaseEnterVariantPrice: "Palihug isulud ang presyo sa variant",
    pleaseEnterVariantQuantity: "Palihug isulud ang kantidad sa variant",
    productDeletedSuccessfully: "Ang produkto malampuson nga natanggal!",
    categoryDeletedSuccessfully: "Malampuson nga natapos ang kategorya!",
    toDeleteThisProduct: "aron mapapas kini nga Produkto",
    invalidProductQuantity: "Dili husto nga Kadaghan sa Produkto",
    quantityAddedIsOverStock:
      "Ang gidaghanon nga imong gidugang labaw pa sa kung unsa ang anaa sa stock.",
    itemInventoryNotTracked: "Wala gisubay ang imbentaryo sa butang",
    addBulkQuantity: "Pagdugang Bulk nga Kadaghan",
    enterBulkQuantity: "Pagsulud sa Bulk nga Kadaghan",
    pleaseEnterBulkQuantity: "Palihug pagsulud Bulk Quantity",
    youveSuccessfullyAddedANewProduct:
      "Malampuson nga nakadugang usa ka bag-ong produkto!",
    pleaseEnterProductSellingPrice:
      "Palihug isulud ang presyo sa pagpamaligya sa produkto",
    doYouWantToTrackProductStock:
      "Gusto ba nimo masundan ang stock sa produkto?",
    sellingPrice: "Presyo",
    setProductExpiryReminder:
      "Pagtakda sa Pagpahinumdom sa Pagkahuman sa Produkto",
    productExpiryDate: "Petsa sa Pagkahuman sa Produkto",
    startRemindingFrom: "Sugdi ang Pagpahinumdom Gikan",
    productSuppliesAddedSuccessfully:
      "Malampuson nga nakadugang ka mga suplay sa produkto.",
    addProductSupplies: "Pagdugang Mga Suplay sa Produkto",
    pleaseSelectSupplier: "Palihug Pagpili nga Maghatag",
    nameOfProduct: "Ngalan Sa Produkto",
    pleaseSelectProduct: "Palihug Pagpili nga Produkto",
    productVariant: "Dagway sa Produkto",
    pleaseSelectAVariant: "Palihug Pagpili Usa ka Magkalainlain",
    pleaseEnterUnitPrice: "Palihug Pagsulud sa Presyo sa Yunit",
    businessBranch: "Sanga sa Negosyo",
    pleaseSelectBranch: "Palihug pagpili sang sanga",
    youveSuccessfullyAddedANewSupplier:
      "Malampuson ka nga nakadugang usa ka bag-ong taghatag",
    addSupplier: "Idugang ang Tagahatag",
    pleaseEnterSupplierEmail: "Palihug ipasok ang email sa tagahatag",
    pleaseAddADescription: "Palihug pagdugang usa ka paghulagway",
    anErrorOccuredProductsDeleted:
      "Adunay sayup nga nahimo samtang gihimo ang operasyon. Palihug hinumdomi, pipila nga mga produkto ang tingali natanggal sa proseso",
    bulkDelete: "Daghang Pagtangtang",
    youAreAboutToDelete: "Hapit na nimo matanggal",
    product: "Produkto",
    isDueToRedeem: "tungod sa paglukat",
    aReward: "usa ka ganti",
    pleaseSelectCustomerToReeemReward:
      "Palihug pagpili usa ka kustomer aron makuha ang ganti.",
    youHaveNoLoyaltyProgramRunning:
      "Wala ka aktibo nga programa sa pagkamaunongon nga ningdagan",
    customerHhasNoPointsInLoyaltyProgram:
      "Wala’y puntos ang kustomer sa niining programa sa pagkamaunongon",
    proceedWithPayment: "Pagpadayon sa Bayad?",
    youAreAboutToPayForTransactionUsingPoints:
      "Mobayad ka na alang sa transaksyon gamit ang mga punto.",
    customerHas: "Ang Customer Adunay",
    worth: "kantidad",
    andIsNotSufficientToPayForTransaction:
      "ug dili igo aron mabayran kini nga transaksyon. Gusto ba nila nga idugang ang balanse gamit ang lain nga pamaagi sa pagbayad?",
    addCustomerLoyalty: "Idugang ang Pagkamaunongon sa Customer",
    pleaseAddCustomerFirst: "Palihug pagdugang o pagpili una og kostumer.",
    pleaseWaitWhileWeGetReady: "Palihug paghulat, samtang nangandam kami",
    lowStock: "Ubos nga Stock",
    pleaseEnterAmountTendered: "Palihug isulud ang kantidad nga gitugyan",
    areYouSureToBookSaleOffline:
      "Sigurado ka nga gusto nimo ipareserba kini nga pagbaligya offline?",
    saleWouldBeBookedAutomatically:
      "Ang pagbaligya mahimong ma-book nga awtomatiko kung imong gibuksan ang imong internet",
    offlineSalesBookingCancelled:
      "Nakansela ang pag-book sa pagpamaligya sa offline",
    covid19Message:
      "COVID19: Hugasi ang imong mga kamut gamit ang sabon o Sanitize aron mahunong ang pagkaylap. Pabilin nga Luwas Kanunay",
    saleSuccessfullyRecorded: "Malampuson nga Naitala ang Pagbaligya!",
    sendReceiptToEmail: "Magpadala resibo sa Email",
    sendThankYouSms: "Magpadala salamat sa SMS",
    sendShippingDetails: "Magpadala mga detalye sa Pagpadala",
    addLoyalty: "Idugang ang Pagkamaunongon",
    searchCustomerNameOrNumber: "Pagpangita ngalan o numero sa kustomer",
    clickTheSearchCustomerBox: "Pag-klik sa Search Customer Box ug Scan Card",
    enterProductPrice: "Pagsulud sa Presyo sa Produkto",
    enterPriceFor: "Pagsulud sa Presyo alang sa",
    searchForProduct: "Pagpangita alang sa produkto",
    all: "Tanan",
    backToDashboard: "Balik sa Dashboard",
    viewDraftSales: "Tan-awa ang Draft Sales",
    variantSelected: "lain nga gipili",
    variant: "laing",
    thePreviousVariantSelectionNotAvailable:
      "Ang miaging piliay nga kapilian dili magamit alang sa bag-ong lahi nga gipili pinahiuyon sa presyo, palihug usba ang imong kapilian.",
    pleaseSupplyPositiveQuantityNumber:
      "Palihug paghatag usa ka numero sa postive dami",
    lowStockFor: "Ubos nga stock alang sa",
    clearVariants: "Tin-aw nga mga lahi",
    pleaseEnterQuantity: "Palihug pagsulud sa Kadaghan",
    picture: "Hulagway",
    redemptionToken: "Token sa Katubsanan",
    token: "Token",
    totalSpent: "Total nga Paggasto",
    confirmPayment: "Gikumpirma ang Bayad",
    hasPaymentBeenMade: "Malampuson ba nga naproseso ang Bayad?",
    enterTransactionReference:
      "Pagsulud sa reperensiya sa transaksyon nga gibayran nimo",
    pleaseSelectACustomer: "Palihug pagpili usa ka kostumer!",
    areYouSureToApplyDiscount:
      "Sigurado ka nga gusto nimo mag-apply diskwento?",
    addYourBankAccountToEnableUssd:
      "Idugang ang imong bank account aron mapadali ang Instant USSD transfer pinaagi sa uPay",
    totalAmountToPay: "Total nga Bayad nga Bayaran",
    doYouWantToCancelTransaction:
      "Gusto ba nimo nga kanselahon kini nga transaksyon?",
    savePrintBill: "Pagtipig / Pag-print sa Bill",
    enterAmountCollectedForCustomer:
      "Pagsulud kantidad nga nakolekta alang sa kustomer",
    recordSale: "Pagbaligya sa Record",
    checkOutWith: "Pagsusi sa",
    instantTransfer: "Instant nga Pagbalhin",
    dialTheUSSDCode: "I-dial ang USSD code",
    pleaseSelectABank: "Palihug pagpili usa ka bangko",
    payWithUSSD: "Pagbayad Sa USSD",
    sendBillTo: " - Magpadala Bill ngadto",
    waitingForUSSDTransfer: "Naghulat alang sa USSD Transfer",
    percent: "Porsyento",
    applyDiscount: "Ibutang ang Diskwento",
    thisBillHasBeenSaved: "Kini nga Balaod naluwas",
    saveDraft: "Luwasa ang Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Palihug pag-type sa usa ka ngalan aron maila ang kustomer",
    paymentDetails: "Mga Detalye sa Bayad",
    basePrice: "Base nga Bili",
    staff: "Mga kawani",
    subTotal: "SubTotal",
    durationMonths: "Gidugayon (bulan)",
    selectADuration: "Pagpili usa ka Kadugayon",
    oneMonth: "1 Bulan",
    twoMonths: "2 Bulan",
    threeMonths: "3 Bulan",
    sixMonths: "6 Bulan",
    twelveMonths: "12 Bulan",
    eighteenMonths: "18 Bulan",
    twentyFourMonths: "24 Bulan",
    twoMonthsFree: "(Libre ang 2 Bulan)",
    threeMonthsFree: "(Libre ang 3 Bulan)",
    fiveMonthsFree: "(Libre ang 5 Bulan)",
    yourAccountHasBeen: "Ang imong Account nahimo na",
    renewed: "gibag-o",
    upgraded: "gi-upgrade",
    successfully: "malampuson",
    yourSubscription: "Imong Suskrisyon",
    youAreCurrentlyEnrolledOnA: "Karon ka naka-enrol sa a",
    pleaseChooseAPaymentOption: "Palihug Pagpili usa ka Kapilian sa Pagbayad",
    planRenewal: "Pagbag-o sa Plano",
    planUpgrade: "Pag-upgrade sa Plano",
    pleaseSelectDurationToPayFor:
      "Palihug Pilia ang Kadugayon nga gusto nimong bayran",
    staffAccounts: "Mga Account sa staff",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Palihug pagpili usa ka plano",
    includeAddons: "Ilakip ang mga Dugang",
    viewTransactions: "Tan-awa ang mga Transaksyon",
    customerHasNoCompletedTansactions:
      "Ang customer wala pa nakumpleto nga mga pag-usab",
    branch: "Sanga",
    enterNumberOfEcommerceBranches:
      "Pagsulud sa Gidaghan sa mga Sanga sa Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Pagsulud sa Gidaghan nga mga Canga sa Ecommerce nga Gusto Nimong Bayaran",
    ecommerceIntegration: "Paghiusa sa Ecommerce",
    enterNumberOfBranches: "Pagsulud sa Gidaghan sa mga Sanga",
    enterNumberOfAdditionalBranchesToPay:
      "Pagsulud sa Gidaghan nga Dugang nga mga Sangang Gusto Nimong Bayaran",
    enterNumberOfStaffs: "Pagsulud sa gidaghanon sa mga kawani",
    enterNumberOfStaffsToPayFor:
      "Pagsulud sa gidaghanon sa mga kawani nga Gusto Nimong Bayaran",
    discountApplies: "Giapil ang Discount",
    starsOnThe: "mga bituon sa",
    offer: "tanyag",
    get: "Pagkuha",
    moreStarsToRedeem: "daghang mga bituon aron matubos",
    taxVat: "Buhis (VAT)",
    callCashierToCompletePayment:
      "Pagtawag sa Cashier aron Makompleto ang Pagbayad",
    receipt: "Resibo",
    dear: "Mahal",
    thankYouForYourOrderFindGoods:
      "Salamat sa imong order. Palihug pangitaa ang mosunud nga mga paninda nga gihatag, sama sa gikasabutan.",
    shippingNote: "Mubo nga sulat sa Pagpadala",
    enterShippingNote: "Pagsulud sa Note Note",
    shippingAddress: "Address sa pagpadala",
    enterShippingAddress: "Pagsulud sa address sa Pagpadala",
    wellDoneYouAreDueToRedeem:
      "Maayong pagkabuhat! Kinahanglan nimo nga matubos",
    toGetYourRewardNextVisit:
      "aron makuha ang imong ganti sa sunod nimong pagbisita. Salamat",
    pointsOnThe: "Mga punto sa",
    morePointsToRedeem: "dugang nga mga puntos aron matubos",
    showCode: "Ipakita ang code",
    toGetYourRewardOnNextVisit:
      "aron makuha ang imong ganti sa sunod nimong pagbisita. Salamat",
    earn: "Kumita",
    delivaryNote: "Hinumdomi sa Delivary",
    draftSales: "Pagbaligya sa Draft",
    startDate: "Petsa sa pagsugod",
    endDate: "Petsa sa pagtapos",
    orders: "Mga mando",
    checkout: "pag-checkout",
    noProductItem: "Wala’y Butang sa Produkto",
    selectProductImage: "Pagpili Hulagway sa Produkto",
    selectCountry: "Pagpili nasud",
    selectRegion: "Pagpili Estado / Rehiyon",
    printProductTag: "I-print ang Tag sa Produkto",
    transactionReference: "Pakisayran sa transaksyon",
    Cashier: "Cashier",
    Manager: "Tigdumala",
    Owner: "Tag-iya",
    Admin: "Admin",
    addPartners: "Idugang ang mga Kasosyo",
    addNewLoyaltyPartner: "Idugang ang Bag-ong Loyalty Partner",
    pleaseEnterCompanyName: "Palihug isulod ang Ngalan sa Kompanya",
    companyName: "Ngalan sa Kompanya",
    pleaseEnterCompanyRepName:
      "Palihug isulod ang ngalan sa representante sa kompanya",
    companyRepName: "Ngalan sa Representante sa Kompanya",
    pleaseEnterCompanyRepEmail:
      "Palihug pagsulod sa email sa representante sa kompanya",
    companyRepEmail: "Email sa representante sa kompanya",
    pleaseEnterCompanyRepPhone:
      "Palihug isulod ang numero sa telepono sa representante sa kompanya",
    companyRepPhone: "Numero sa Telepono sa Rep",
    addReward: "Idugang ang ganti",
    pleaseEnterRewardName: "Palihug isulod ang ganti nga ngalan",
    rewardName: "Ngalan sa Ganti",
    rewardQuantity: "Gidaghanon sa Ganti",
    rewardDescription: "Deskripsyon sa Ganti",
    rewardType: "Matang sa Ganti",
    pleaseEnterRewardType: "Palihog isulod ang reward type",
    pleaseEnterRewardQuantity: "Palihug isulod ang gidaghanon sa ganti",
    pleaseEnterRewardDescription: "Palihug isulod ang paghulagway sa ganti",
    fineDining: "Lino nga fino nga kan-anan",
    luxuryFashion: "Luho nga Fashion",
    hotels: "Mga hotel",
    travel: "Pagbiyahe",
    foodAndBeverage: "Pagkaon ug Ilimnon",
    fashion: "Fashion",
    health: "Panglawas",
    furniture: "Muwebles",
    entertainment: "Kalingawan",
    automobile: "awto",
    education: "Edukasyon",
    beautyAndSpa: "Beauty ug Spa",
    staycation: "Pagpabilin",
    events: "Mga panghitabo",
    trips: "Mga biyahe",
    oilAndGas: "Langis ug Gas",
    laundry: "Panglaba",
    partnerCategory: "Kategorya sa Kasosyo",
    freeItem: "Libre nga Butang",
  },
  Czech: {
    cashier: "pokladní",
    manager: "manažer",
    owner: "majitel",
    online: "online",
    offline: "offline",
    changePassword: "Změnit heslo",
    currentPasswordMessage: "Zadejte prosím své aktuální heslo",
    passwordMessage: "Prosím zadejte své heslo",
    currentPassword: "Aktuální heslo",
    password: "Heslo",
    confirmPasswordMessage: "Potvrďte prosím své heslo!",
    confirmPassword: "Potvrďte heslo",
    sendViaEmail: "Odeslat e -mailem",
    sendViaWhatsapp: "Odeslat přes WhatsApp",
    downloadPdf: "Stáhnout PDF",
    paid: "zaplaceno",
    unpaid: "nezaplaceno",
    partial: "částečný",
    closeInvoice: "Chcete fakturu uzavřít?",
    closeInvoiceConfirmation: "Fakturu nelze uložit. Chcete zavřít?",
    yes: "Ano",
    no: "Ne",
    invoice: "Faktura",
    wasDeducted: "byla odečtena",
    for: "pro",
    item: "Položka",
    addProduct: "Přidat produkt",
    paymentReference: "Reference platby",
    amountPaid: "Částka vyplacená",
    discountAmount: "Výše slevy",
    amountDue: "Dlužná částka",
    amount: "Množství",
    dueDate: "Datum splatnosti",
    paymentType: "Způsob platby",
    card: "Kartu",
    cash: "Hotovost",
    bankTransfer: "Bankovní převod",
    paymentMessage: "Platební zpráva",
    description: "Popis",
    sendReceipt: "Odeslat potvrzení",
    delete: "Vymazat",
    save: "Uložit",
    resend: "Přeposlat",
    saveAndSend: "Uložit",
    invoiceSaved: "Faktura uložena!",
    selectPaymentMethod: "Vyberte prosím způsob platby!",
    selectCustomer: "Vyberte prosím zákazníka!",
    cartEmptyError:
      "Seznam košíků nemůže být prázdný, zavřete fakturu a vložte položku do košíku!",
    subscriptionExpired:
      "Vaše předplatné vypršelo a váš účet je nyní omezený. Účet obnovíte kliknutím na tlačítko níže",
    renew: "Obnovit",
    holdOn: "Vydrž",
    customerBank: "Zákaznická banka",
    cancel: "zrušení",
    selectACustomer: "Vyberte zákazníka",
    invoiceSuccessfulPdfError:
      "Faktura byla úspěšně vytvořena, ale generování PDF trvá déle než obvykle. Zkuste to prosím brzy znovu.",
    downloadingInvoice: "Stahování faktury",
    downloadingReceipt: "Stahování účtenky",
    whatsappReceiptError:
      "Při odesílání účtenky přes WhatsApp došlo k chybě. Zkuste to prosím znovu.",
    receiptToWhatsapp: "Účtenka přeposlána na WhatsApp",
    thankYouForPatronage: "Děkujeme za vaši přízeň",
    hereIsYourReceipt: "Zde je potvrzení o platbě",
    errorSendingEmailReceipt:
      "Při odesílání účtenky e-mailem došlo k chybě. Zkuste to prosím znovu nebo kontaktujte podporu",
    receiptSentToEmail: "Účtenka byla odeslána na e -mail zákazníka",
    invoiceSentToEmail: "Faktura byla odeslána na e -mail zákazníka",
    invoiceSuccessWhatsappError:
      "Faktura byla úspěšně vytvořena, ale při odesílání na WhatsApp došlo k chybě. Zkuste to znovu na seznamu faktur",
    invoiceSuccessfulEmailError:
      "Faktura byla úspěšně vytvořena, ale při odesílání jako e-mailu došlo k chybě. Zkuste to prosím znovu ze seznamu faktur",
    invoiceSentToWhatsapp: "Faktura přeposlána na WhatsApp",
    hello: "Ahoj",
    pleaseDownloadInvoice: "Stáhněte si prosím fakturu",
    pleaseDownloadReceipt: "Stáhněte si stvrzenku",
    from: "z",
    email: "E-mailem",
    upgrade: "Vylepšit",
    youAreOnFreePlan: "Jste na bezplatném plánu.",
    clickOn: "Klikněte na",
    yourPlanInFewSteps: " předplatné v několika rychlých krocích.",
    to: "na",
    yourSubscriptionHasExpired:
      "Vaše předplatné vypršelo a váš účet je nyní omezený.",
    days: "dny",
    yourSubscriptionExpiresIn: "Vaše předplatné Loystar vyprší za",
    createAcount: "Vytvořit účet",
    signIn: "Přihlásit se",
    continue: "Pokračovat",
    enterOtp: "Zadejte OTP PIN",
    enterValidOtp: "Zadejte prosím platný PIN",
    pin: "KOLÍK",
    tokenSentToMail: "Na váš e -mail byl odeslán token",
    passwordResetSuccessful: "Obnovení hesla bylo úspěšné",
    somethingWentWrong: "Něco se pokazilo!",
    resetPassword: "Obnovit heslo",
    iHaveResetCode: "Mám kód pro obnovení hesla",
    pleaseEnterEmail: "Zadejte prosím svůj e -mail",
    aTokenWillBeSentToEmail: "Token by byl zaslán na váš e -mail",
    enterEmail: "Vložte svůj e-mail",
    sendinYourToken: "Odesílání tokenu ...",
    makeSureItMatchesPassword: "Zkontrolujte, zda odpovídá novému heslu",
    pleaseChooseNewPassword: "Zvolte prosím nové heslo",
    chooseNewPassword: "Vyberte nové heslo",
    enterNewPassword: "Potvrďte zadáním nového hesla",
    enterTokenSent: "Zadejte token, který vám byl zaslán",
    resetToken: "Resetovat token",
    resettingPassword: "Resetování hesla ...",
    signUp: "Přihlásit se",
    adminSignInWithEmail:
      " Správce se přihlašuje pomocí e -mailu, zatímco zaměstnanci se přihlašují pomocí uživatelského jména.",
    pleaseEnterEmailOrUsername:
      "Zadejte prosím svůj e -mail nebo uživatelské jméno",
    emailOrUsername: "E-mail nebo uživatelské jméno",
    pleaseEnterPassword: "Prosím vložte heslo",
    createAnAccount: "Vytvořit účet",
    forgotPassword: "Zapomenuté heslo?",
    enterPhoneNumber: "Vložte telefonní číslo",
    personalData: "Osobní data",
    validateConfirmationCOde: "Ověřte potvrzovací kód",
    pleaseEnterFirstName: "Prosím zadej své křestní jméno",
    pleaseEnterPhoneNumber: "Zadejte prosím své telefonní číslo",
    pleaseEnterLastName: "Prosím zadejte své příjmení",
    pleaseEnterBusinessName: "Zadejte prosím název své firmy",
    firstName: "Jméno",
    lastName: "Příjmení",
    businessName: "Obchodní jméno",
    previous: "Předchozí",
    next: "další",
    pleaseSelectBusinessCategory: "Vyberte prosím svou kategorii podnikání",
    pleaseEnterValidEmail: "prosím zadejte platný email",
    pleaseEnterPostCode: "Zadejte poštovní směrovací číslo",
    postCode: "PSČ",
    phoneNumberInUse: "Toto telefonní číslo se již používá!",
    emailInUse: "Tento e -mail se již používá!",
    foodAndDrinks: "Jídlo a pití",
    salonAndBeauty: "Salon a krása",
    fashionAndAccessories: "Móda a doplňky",
    gymAndFitness: "Tělocvična a fitness",
    travelAndHotel: "Cestování a hotel",
    homeAndGifts: "Domov a dárky",
    washingAndCleaning: "Mytí a čištění",
    gadgetsAndElectronics: "Přístroje a elektronika",
    groceries: "Koloniál",
    others: "Ostatní",
    submit: "Předložit",
    accountCreatedSuccessful: "Váš účet byl úspěšně vytvořen.",
    pleaseEnterAddress: "Zadejte svoji adresu",
    addressLine1: "1. řádek adresy",
    addressLine2: "2. řádek adresy",
    choosePassword: "Zvolit heslo",
    passwordMustBe6Characters: "Heslo musí mít alespoň 6 znaků.",
    howDidYouHearLoystar: "Jak jste se dozvěděli o Loystar?",
    referralCode: "doporučující kodex",
    byClickingTheButton: " Kliknutím na tlačítko níže souhlasíte s",
    termsAndSevice: "Podmínky",
    wereCreatingAccount: "Vytváříme váš účet",
    proceed: "Pokračovat",
    verificationCodeMustBe6: "Ověřovací kód musí mít 6 číslic",
    pleaseEnter6DigitCode: "Zadejte prosím 6místný kód",
    enterVerificationCode: "Vložte ověřovací kód",
    resendToken: "Znovu poslat token",
    verify: "Ověřit",
    transactions: "Transakce",
    todaySales: "Dnešní výprodeje",
    salesHistory: "Historie prodeje",
    supplyHistory: "Historie dodávek",
    new: "Nový",
    invoices: "Faktury",
    disbursements: "Výplaty",
    offlineSales: "Offline prodej",
    products: "produkty",
    customers: "Zákazníci",
    multiLevelLoyalty: "Víceúrovňová loajalita",
    loyaltyPrograms: "Věrnostní programy",
    members: "Členové",
    appStore: "Obchod s aplikacemi",
    orderMenu: "Nabídka objednávky",
    settings: "Nastavení",
    staffAndBranches: "Štáby a pobočky",
    myAccount: "Můj účet",
    switchToSellMode: "Přepnout do režimu prodeje",
    signOut: "Odhlásit se",
    getFreeSubscription: "Získejte předplatné zdarma",
    onlyNumbersAllowed: "Povolena jsou pouze čísla",
    yourAccountMustBe10Digits: "číslo vašeho účtu musí být 10místné číslo",
    yourBvnMustBe11: "vaše BVN musí být 11místné číslo",
    pleaseSelectBank: "Vyberte svou banku",
    selectYourBank: "Vyberte svou banku",
    enterBankAccountNumber: "Zadejte číslo bankovního účtu",
    enterBvnNumber: "Zadejte své BVN",
    connectBankAccount: "Připojte bankovní účet",
    passwordResetSuccessfulAndSignOut:
      "Vaše heslo bylo úspěšně resetováno. Chcete -li se znovu přihlásit, klikněte níže na tlačítko Odhlásit",
    enterCurrentPassword: "Vložte stávající heslo",
    pleaseEnterCurrentPassword: "Zadejte prosím aktuální heslo",
    phoneNumber: "Telefonní číslo",
    sex: "Sex",
    dateOfBirth: "Datum narození",
    state: "Stát",
    country: "Země",
    loyaltyId: "Věrnostní ID",
    createdAt: "Vytvořeno v",
    noOfTransactionsMade: "Počet provedených transakcí",
    yourDownloadWillStart: "Stahování začne za chvíli",
    exportCustomers: "Exportovat zákazníky",
    youhaveSuccessfullyToppedUp: "Úspěšně jste dobili své jednotky SMS.",
    youNowHave: "Nyní máte",
    smsUnits: "Jednotky SMS",
    enterNumberOfSmsUnits: "Zadejte počet jednotek SMS, které chcete zakoupit",
    pleaseEnterANumericValue: "Zadejte prosím číselnou hodnotu",
    pay: "Platit",
    accountEditedSuccessfully: "Váš účet byl úspěšně upraven.",
    youAeCurrentlyOn: "Právě jste zapnutý",
    plan: "Plán",
    userData: "Uživatelská data",
    businessData: "OBCHODNÍ ÚDAJE",
    businessCategory: "Obchodní kategorie",
    pleaseSelectCurrency: "Vyberte prosím svou měnu",
    selectYourCurrency: "Vyberte svou měnu",
    purchaseMoreSmsUnits:
      "Kupte si více jednotek SMS pomocí níže uvedeného formuláře",
    youHave: "Ty máš",
    atLeast4SmsUnitsRrequired:
      "K ověření jsou potřeba alespoň 4 sms jednotky, doplňte!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ověřte svůj bankovní účet, aby vyhovoval požadavkům „Poznej svého zákazníka“ (KYC). To vám umožní sbírat platby přes USSD nebo okamžitý převod, přijímat objednávky od zákazníků a zpracovávat transakce. Platí pouze pro nigerijské obchodníky. Začněte stisknutím tlačítka níže.",
    reConnectBankAccount: "Znovu připojte bankovní účet",
    accountName: "Jméno účtu",
    accountNumber: "Číslo účtu",
    bankName: "Jméno banky",
    verified: "Ověřeno",
    accountDetails: "Detaily účtu",
    kycBankAccount: "KYC",
    createTier: "Vytvořit vrstvu",
    fileUploadSuccessful: "Soubor byl úspěšně nahrán",
    fileUploadFailed: "nahrání souboru se nezdařilo",
    createLoyaltyProgram: "Vytvořte věrnostní program",
    createLoyalty: "Vytvořte věrnost",
    name: "název",
    loyaltyArtwork: "Věrnostní umělecká díla",
    clickToUpload: "Kliknutím nahrajte",
    amountToPointsRatio: "Poměr částek k bodům",
    points: "Body",
    recommendedAmountToPointRatio:
      "Doporučeno: ₦ 1 až 1 bod. tj. za každou 1 utracenou Nairu vaši zákazníci získají 1 bod",
    pleaseTypeIn: "Prosím zadejte",
    toDeleteLoyalty: "tuto věrnost smazat",
    deleteLoyalty: "Smazat věrnost",
    toConfirm: "potvrdit",
    edit: "Upravit",
    pointsAwardedSuccessfully: "Body byly uděleny úspěšně.",
    enterPointValueToAward:
      "Zadejte bodovou hodnotu, kterou chcete ocenit zákazníky",
    award: "Cena",
    awardPointValuesToCustomer: "Udělte zákazníkovi bodovou hodnotu",
    enrollMembersToLoyalty: "Zaregistrujte členy do věrnosti",
    awardPoints: "Body udělení",
    enroll: "Zapsat",
    home: "Domov",
    loyalty: "Věrnost",
    enrollCustomers: "Zaregistrujte zákazníky",
    selected: "Vybraný",
    customer: "Zákazník",
    loyaltyActivationSuccessful: "Aktivace věrnosti je úspěšná.",
    loyaltyDeactivationSuccessful: "Deaktivace věrnosti je úspěšná.",
    viewTier: "Zobrazit úroveň",
    deactivate: "Deaktivovat",
    activate: "aktivovat",
    actions: "Akce",
    nameOfLoyalty: "Název loajality",
    loyaltyStatus: "Věrnostní stavVěrnostní stav",
    numberOfTiers: "Počet úrovní",
    createdOn: "Vytvořeno dne",
    createATier: "Vytvořte vrstvu",
    stopCreatingTierConfirmation: "Chcete přestat vytvářet vrstvu?",
    stopEditingTierConfirmation: "Chcete přestat upravovat tuto úroveň?",
    nameOfTier: "Název úrovně",
    minimumSpendingTarget: "Minimální cíl útraty",
    maximumSpendingTarget: "Maximální cíl útraty",
    minimumSpendingTargetRequired: "je požadován cíl minimálních výdajů",
    maximumSpendingTargetRequired: "je požadován maximální cíl útraty",
    rewardSponsor: "Sponzor odměn",
    pleaseChooseARewardSponsor: "Vyberte si sponzora odměn",
    self: "Já",
    partner: "Partner",
    rewardPartner: "Partner odměn",
    pleaseSelectRewardPartner: "Vyberte svého partnera odměn",
    rewards: "Odměny",
    pleaseSelectAReward: "Vyberte odměnu",
    instructionsOnRedeemingReward:
      "Pokyny k tomu, jak by měl zákazník uplatnit odměnu",
    pleaseFillInThisField: "Vyplňte prosím toto pole!",
    toDeleteThisTier: " odstranit tuto úroveň",
    deleteTier: "Odstranit vrstvu",
    viewMembers: "Zobrazit členy",
    membersEnrolled: "Členové zapsaní",
    instruction: "Návod",
    membersIn: "Členové v",
    availableTiersInLoyalty: "Dostupné úrovně ve věrnostním programu",
    tiers: "Úrovně",
    totalTier: "CELKEM ÚROVEŇ",
    availableLoyaltyProgramme: "Dostupný věrnostní program",
    totalLoyalties: "CELKOVÁ VĚRNOST",
    memberDetails: "Podrobnosti o členovi",
    nameOfCustomer: "Jméno zákazníka",
    address: "Adresa",
    allEnrolledMembers: "Všichni zapsaní členové",
    thisIsToWishYouHappyBirthday:
      "Tímto vám přejeme všechno nejlepší k narozeninám a prosperující život. Díky za všechno, co jste, Loysterovi. Veselé oslavy!",
    inputAnOfferPlease: "Zadejte prosím nabídku",
    pleaseSelectTheInsertPoint:
      "Vyberte bod vložení do zprávy a klikněte znovu",
    birthdayOfferAndMessage: "Narozeninová nabídka a zpráva",
    birthdayOffer: "Nabídka narozenin",
    birthdayMessage: "Zpráva k narozeninám",
    noOfferFound: "Nebyla nalezena žádná nabídka",
    settingABirthdayOffer:
      "Nastavení nabídky narozenin umožňuje zákazníkům tuto nabídku obdržet prostřednictvím SMS v den jejich narozenin",
    createOffer: "Vytvořit nabídku",
    whatIsTheOffer: "Jaká je nabídka?",
    editMessage: "Upravit zprávu",
    insert: "Vložit",
    theNameOfCustomerInserted: "Sem bude vloženo jméno zákazníka",
    theBirtdayOfferInserted: "Sem bude vložena nabídka k narozeninám",
    youSuccessfullyAddedNewBranch: "Úspěšně jste přidali novou větev!",
    addNewBranch: "Přidat novou pobočku",
    addBranch: "Přidat pobočku",
    additionalBranchWillIncur: "Další pobočka by vznikla",
    perBranch: "na pobočku",
    ecommerceBranchCosts: "Náklady na pobočku elektronického obchodu",
    pleaseEnterBranchName: "Zadejte prosím název pobočky",
    pleaseEnterBranchAddress1: "Zadejte prosím řádek adresy 1 pobočky",
    enterBranchAddress1: "Zadejte řádek adresy 1 pobočky",
    enterBranchAddress2: "Zadejte řádek adresy 1 pobočky",
    pleaseEnterBranchAddress2: "Zadejte řádek adresy 2 pobočky",
    enterBranchName: "Zadejte název pobočky",
    successfullyAddedStaff: "Úspěšně jste přidali nového zaměstnance!",
    addNewStaff: "Přidat nový personál",
    addStaff: "Přidat zaměstnance",
    additionalStaffWillIncur: "Vznikl by další personál",
    perStaff: "na zaměstnance.",
    pleaseEnterStaffEmail: "Zadejte prosím e -mail zaměstnance",
    pleaseEnterStaffUsername: "Zadejte prosím uživatelské jméno zaměstnance",
    pleaseEnterStaffPassword: "Zadejte prosím heslo zaměstnance",
    pleaseSelectStaffRole: "Vyberte prosím roli personálu",
    selectStaffRole: "Vyberte roli personálu",
    enterStaffEmail: "Zadejte e -mail zaměstnance",
    enterStaffUsername: "Zadejte uživatelské jméno zaměstnance",
    enterStaffPassword: "Zadejte heslo zaměstnance",
    spacesNotAllowedInUsername: "v uživatelském jménu nejsou povoleny mezery",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Chcete -li připojit zaměstnance, vyberte prosím firmu",
    searchForBranchToAttachStaff: "Vyhledejte pobočku a připojte zaměstnance",
    username: "Uživatelské jméno",
    role: "Role",
    areYouSureToDeleteThis: "Opravdu to chcete smazat?",
    branches: "Pobočky",
    upgradeYourPlan: "Upgradujte svůj plán.",
    add: "PŘIDAT",
    addNew: "Přidat nový",
    customerWithEmailAlreadyExists:
      "Zákazník s e -mailem/telefonním číslem již existuje!",
    successfullyAddedNewCustomer: "Úspěšně jste přidali nového zákazníka!",
    addCustomer: "Přidat zákazníka",
    pleaseEnterCustomerFirstName: "Zadejte křestní jméno zákazníka",
    pleaseEnterCustomerLastName: "Zadejte prosím příjmení zákazníka",
    pleaseEnterCustomerPhoneNumber: "Zadejte prosím telefonní číslo zákazníka",
    pleaseEnterCustomerEmail: "Zadejte e -mail zákazníka",
    pleaseEnterCustomerAddressLine: "Zadejte řádek adresy zákazníka",
    pleaseEnterCustomerOtherAddress: "Zadejte prosím jinou adresu zákazníka",
    pleaseSelectCustomerGender: "Vyberte prosím pohlaví zákazníka",
    gender: "Rod",
    male: "mužský",
    female: "ženský",
    bank: "banka",
    selectBank: "Vyberte Banka",
    stateOrRegionOrProvince: "Stát/Region/Provincie",
    customerNotes: "Zákaznické poznámky",
    sendSms: "Pošli SMS",
    editCustomer: "Upravit zákazníka",
    redeemReward: "Uplatnit odměnu",
    issueLoyaltyCard: "Vydejte věrnostní kartu",
    deleteCustomer: "Odstranit zákazníka",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Úspěšně jste přiřadili ID věrnostního členství",
    noMembershipIdAvailable:
      "Nejsou k dispozici žádná ID členství. Kontaktujte nás prosím na hello@loystar.co",
    sendEmail: "Poslat e-mailem",
    membershipPoints: "Body členství",
    customerDetails: "Podrobnosti o zákazníkovi",
    close: "Zavřít",
    loyaltyBalance: "Věrnostní zůstatek",
    pointsBalance: "Bodový zůstatek",
    starBalance: "Star Balance",
    requiredPoints: "Požadované body",
    requiredStars: "Požadované hvězdičky",
    reddemCode: "Použít kód",
    toDeleteThisCustomer: "smazat tohoto zákazníka",
    customerHasBeenDeletedSuccessfully: "Zákazník byl úspěšně smazán!",
    customerWithPhoneAlreadyExists: "Zákazník s telefonním číslem již existuje",
    customerWasSuccessfullyEdited: "Zákazník byl úspěšně upraven",
    anErrorOccured: "Nastala chyba",
    phoneNumberIncludeCountry: "Telefonní číslo (včetně kódu země)",
    yourFileQueuedForUpload:
      "Váš soubor byl zařazen do fronty k nahrání. Obnovte stránku po několika sekundách.",
    thereWasAnErrorPerformingOperation: "Při provádění operace došlo k chybě!",
    pleaseSelectFile: "Vyberte prosím soubor!",
    oopsOnlyCsvFilesAllowed:
      "Jejda! Povoleny jsou pouze soubory CSV. Nahrajte soubor .csv.",
    fileShouldBeSmallerThan5Mb:
      "Soubor by měl být menší než 5 MB. Pokud máte větší soubor, pošlete prosím e -mail na adresu support@loystar.co. Děkuji",
    customerFirstNameIsRequired: "na řádku je uvedeno křestní jméno zákazníka",
    customerPhoneNumberIsRequired:
      "na řádku je vyžadováno telefonní číslo zákazníka",
    importCustomers: "Importovat zákazníky",
    upload: "nahrát",
    clickIconToDownloadCsv:
      "Kliknutím na tuto ikonu stáhnete šablonu souboru CSV.",
    getGoogleSheetVersion: "Získejte verzi listu Google",
    clickOrDragFileToUpload:
      "Nahrajte kliknutím nebo přetažením souboru do této oblasti",
    missingOrInvalidColumnHeader:
      "Chybějící nebo neplatné záhlaví sloupce. Dodržujte prosím formát šablony. Děkuji.",
    youHaveImported: "Importovali jste",
    youSuccessfullyRedeemedReward: "Úspěšně jste uplatnili svou odměnu!",
    sixDigitCode: "Šestimístný kód",
    pleaseEnterCustomerRewardCode: "Zadejte kód odměny zákazníka",
    enterRewardCode:
      "Zadejte kód odměny. (Kód odměny rozlišuje velká a malá písmena)",
    selectLoyaltyProgram: "Vyberte věrnostní program",
    stamps: "Razítka",
    smsUnitsLow: "Jednotky SMS nízké",
    pleaseTopUpSmsUnits: "Doplňte prosím jednotky SMS",
    smsSuccessfullySentForDelivery: "SMS byla úspěšně odeslána k doručení!",
    sendSmsTo: "Odeslat SMS na",
    allCustomers: "Všichni zákazníci",
    unitsAvailable: "Dostupné jednotky",
    pleaseTypeInTheMessage: "Napište prosím zprávu",
    message: "Zpráva",
    charactersRemaining: "zbývající znaky",
    avoidUseOfSpecialCharacters:
      "Vyhněte se používání speciálních znaků a ikon emodži pro úsporu jednotek SMS.",
    note: "Poznámka",
    errorFetchingCustomersMultilevelDetail:
      "Chyba při načítání podrobností více úrovní zákazníků",
    search: "Vyhledávání",
    reset: "Resetovat",
    importCustomer: "Importovat zákazníka",
    addNewCustomer: "Přidat nového zákazníka",
    sendSmsBroadcast: "Odeslat vysílání SMS",
    totalCustomers: "CELKEM ZÁKAZNÍKŮ",
    disbursementDetails: "Podrobnosti o výplatě",
    paidBy: "Hradí",
    disbursed: "Vyplaceno",
    bankAccountName: "Jméno bankovního účtu",
    bankAccountNumber: "Číslo bankovního účtu",
    transferInitiated: "Převod zahájen",
    transferCompleted: "Přenos dokončen",
    pleaseEnterAValid: "Zadejte prosím platné",
    begin: "začít",
    end: "konec",
    date: "datum",
    paymentDate: "Datum splatnosti",
    selectDisbursementDuration: "Vyberte dobu trvání výplaty",
    totalSettled: "Celkem vyřízeno",
    totalUnsettled: "Celkem nevyrovnané",
    toDeleteThisSale: "smazat tento prodej",
    draftSaleDeletedSuccessfully: "Koncept výprodeje byl úspěšně smazán!",
    deleteSale: "Smazat prodej",
    pleaseTypeInYourTotal:
      "Chcete -li vymazání potvrdit, zadejte celkovou částku",
    billDetails: "Podrobnosti o účtu",
    printBill: "Vytisknout účet",
    servedBy: "Obsluhováno",
    total: "Celkový",
    createdDate: "Datum vytvoření",
    createdTime: "Vytvořený čas",
    status: "Postavení",
    loginSuccessful: "Přihlášení úspěšné",
    pleaseWaitWhileWeConnectAccount: "Počkejte prosím, než připojíme váš účet",
    connectionFailedTryAgain: "Připojení se nezdařilo. Prosím zkuste to znovu.",
    connectionSuccessful: "Připojení bylo úspěšné",
    viewDetails: "Zobrazit podrobnosti",
    enable: "Umožnit",
    free: "Volný, uvolnit",
    cost: "Náklady",
    visitWebsite: "Navštivte webové stránky",
    pleaseUpgradeYourPlanToPro:
      "Chcete -li tuto aplikaci povolit, upgradujte svůj plán na Pro Plus",
    connectYourBankAccount:
      "Připojte svůj bankovní účet, abyste mohli přijímat platby.",
    disable: "Zakázat",
    enableApp: "Povolit aplikaci",
    addNewProductToInvoice: "Přidat nový produkt k faktuře",
    toDeleteThisInvoice: "k odstranění této faktury",
    invoiceDeletedSuccessfully: "Faktura byla úspěšně smazána!",
    deleteInvoice: "Smazat fakturu",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Úspěšně jste přidali zprávu o platbě faktury!",
    pleaseEnterPaymentInstructions: "Zadejte prosím pokyny k platbě",
    invoiceId: "ID faktury",
    paidDate: "Placené datum",
    reference: "Odkaz",
    productAdded: "Přidán produkt",
    productOutOfStock: "Výrobek není skladem. Doplňte prosím zásoby.",
    totalInvoices: "CELKOVÉ FAKTURY",
    totalPaidInvoices: "CELKEM PLATENÉ FAKTURY",
    totalUnpaidInvoices: "CELKEM NEPLATENÉ FAKTURY",
    loyaltyProgramDeleted: "Věrnostní program smazán",
    thisLoyaltyProgramDeletedSuccessfully:
      "Tento věrnostní program byl úspěšně smazán",
    thisLoyaltyProgramEditedSuccessfully:
      "Tento věrnostní program byl úspěšně upraven",
    loyaltyProgramAddedSuccessfully: "Věrnostní program byl úspěšně přidán",
    loyaltyProgramEdited: "Věrnostní program upraven",
    loyaltyProgramAdded: "Přidán věrnostní program",
    loyaltyDetails: "Podrobnosti o věrnosti",
    doYouWantToCloseDialog: "Chcete toto dialogové okno zavřít?",
    pleaseEnterLoyaltyName: "Zadejte prosím název své věrnosti",
    programType: "Typ programu",
    pleaseSelectProgramType: "Vyberte prosím typ programu",
    simplePoints: "Jednoduché body",
    stampsProgram: "Program razítek",
    threshold: "Práh",
    pleaseEnterLoyaltyThreshold: "Zadejte práh věrnosti",
    reward: "Odměna",
    pleaseEnterLoyaltyReward: "Zadejte prosím odměnu za věrnost",
    totalUserPoints: "Celkový počet uživatelských bodů",
    totalUserStamps: "Celkem uživatelských známek",
    spendingTarget: "Výdaje cíl",
    spendingTargetHint1:
      "Cíl útraty je, kolik musí zákazník utratit, aby získal odměnu. 1 hodnota měny = 1 bod.",
    spendingTargetHint2:
      "Cíl razítek je, kolik známek musí zákazník nasbírat, aby získal odměnu. např. 5",
    addNewLoyaltyProgram: "Přidat nový věrnostní program",
    addLoyaltyProgram: "Přidat věrnostní program",
    loyaltyProgramType: "Typ věrnostního programu",
    pleaseSelectLoyaltyProgramType: "Vyberte prosím typ věrnostního programu",
    nameOfProgram: "Název programu",
    pleaseEnterProgramName: "Zadejte název programu",
    whatIsTheReward: "Jaká je odměna?",
    egNextPurchaseIsFree: "Např. Další nákup je zdarma",
    customerName: "Jméno zákazníka",
    guestCustomer: "Hostující zákazník",
    orderConfirmedSuccessfully: "Objednávka byla úspěšně potvrzena",
    orderCancelledSuccessfully: "Objednávka byla úspěšně zrušena",
    confirmOrder: "Potvrďte objednávku",
    cancelOrder: "Zrušit objednávku",
    allOrders: "Všechny objednávky",
    totalOrders: "CELKOVÉ OBJEDNÁVKY",
    doYouWantToAcceptOrder: "Chcete tuto objednávku přijmout?",
    doYouWantToCancelOrder: "Chcete zrušit tuto objednávku?",
    orderDetails: "podrobnosti k objednávce",
    orderCreatedAt: "Objednávka vytvořena v",
    supplier: "Dodavatel",
    productName: "jméno výrobku",
    quantity: "Množství",
    unitPrice: "Jednotková cena",
    receivedBy: "Přijato",
    reportFrom: "Zpráva od",
    totalSupplies: "Celkové zásoby",
    allRightsReserved: "Všechna práva vyhrazena",
    toDeleteThisTransaction: "k odstranění této Transakce",
    transactionDeletedSuccessfully:
      "Transakce byla úspěšně odstraněna. Zásoby byly vráceny do zásoby.",
    deleteTransaction: "Smazat transakci",
    transactionDetails: "Detaily transakce",
    printReceipt: "Tisk účtenky",
    channel: "Kanál",
    discount: "Sleva",
    profit: "Zisk",
    discountedSales: "Zlevněné prodeje",
    errorFetchingRecord: "Chyba při načítání záznamu",
    exportTransactions: "Exportní transakce",
    errorFetchingSalesRecord:
      "Chyba při načítání záznamu o prodeji pro export.",
    totalSellingPrice: "Celková prodejní cena",
    totalCostPrice: "Cena celkem",
    appliedDiscount: "Uplatněná sleva",
    noOfItems: "Počet položek",
    sales: "Odbyt",
    export: "Vývozní",
    totalProfit: "Celkový zisk",
    totalBalanceInPeriod: "Celkový zůstatek v období",
    allTimeSales: "Prodej všech dob",
    records: "Evidence",
    todaysSales: "Dnešní výprodeje",
    transaction: "transakce",
    youHaveExceededTotalNumberOfProducts:
      "Překročili jste celkový počet produktů povolených ve vašem plánu. Upgradujte svůj plán a užívejte si vyšší limit.",
    youNeedToHaveLoyaltyProgram:
      "K používání této funkce potřebujete věrnostní program!",
    price: "Cena",
    category: "Kategorie",
    stockTracking: "Sledování skladu",
    stockCount: "Počet akcií",
    taxed: "Daně",
    originalPrice: "Původní cena",
    costPrice: "Nákladová cena",
    unit: "Jednotka",
    productImage: "Obrázek produktu",
    taxRate: "Sazba daně",
    taxType: "Typ daně",
    trackInventory: "Sledovat inventář",
    variants: "Varianty",
    hasVariants: "Má varianty",
    importProduct: "Importovat produkt",
    exportProducts: "Export produktů",
    addNewProduct: "Přidat nový produkt",
    viewCategory: "Zobrazit kategorii",
    viewSuppliers: "Zobrazit dodavatele",
    receiveInventory: "Přijmout inventář",
    printAllProductsTag: "Vytisknout štítek všech produktů",
    deleteAll: "Smazat všechny",
    totalProducts: "CELKOVÉ PRODUKTY",
    youveSuccessfullyAddedANewCategory: "Úspěšně jste přidali novou kategorii",
    addNewCategory: "Přidat novou kategorii",
    addCategory: "Přidat kategorii",
    categoryName: "Název Kategorie",
    pleaseEnterCategoryName: "Zadejte prosím název kategorie",
    stampsTarget: "Známky Cíl",
    sendInventory: "Odeslat inventář",
    productDetails: "Detaily produktu",
    youveSuccessfullyEditedThisCategory: "Úspěšně jste upravili tuto kategorii",
    update: "Aktualizace",
    categoryList: "Seznam kategorií",
    categories: "Kategorie",
    enterQuantityToUpdate: "Zadejte množství, které chcete aktualizovat",
    inventorySentSuccessfully: "Inventář byl úspěšně odeslán!",
    updateInventory: "Aktualizovat inventář",
    currentQuantity: "Aktuální množství",
    pleaseEnterQuantityToAdd: "Zadejte množství, které chcete přidat",
    pleaseSelectABranch: "Vyberte prosím pobočku",
    searchForBranch: "Vyhledejte pobočku",
    youCantSendMoreThanStock: "Nelze odeslat více, než máte skladem",
    send: "Poslat",
    pleaseEnterQuantityToSend: "Zadejte prosím množství, které chcete zaslat",
    productNameIsRequiredOnRow: "na řádku je uveden název produktu",
    productCategoryIsRequiredOnRow: "na řádku je vyžadována kategorie produktu",
    productPriceIsRequiredOnRow: "na řádku je požadována cena produktu",
    productUnitIsRequiredOnRow: "na řádku je požadována jednotka produktu",
    productQuantityIsRequiredOnRow: "na řádku je požadováno množství produktu",
    productVariantsRequireTracking: "varianty produktu vyžadují sledování!",
    pleaseAddVariantClickButton:
      "Přidejte prosím variantu kliknutím na tlačítko Přidat!",
    totalVariantsMoreThanSelectedQuantity:
      "Celkové varianty jsou větší než vybrané množství produktů, Pls snižují množství variant",
    productEditedSuccessfully: "Produkt byl úspěšně upraven!",
    fileTooLargeLessThan4Mb:
      "Velikost souboru je příliš velká! Velikost souboru by měla být menší než 4 MB.",
    suchVariantAlreadyExist: "Taková varianta již existuje",
    addVariants: "Přidejte varianty",
    editProduct: "Upravit produkt",
    pleaseEnterProductName: "Zadejte prosím název produktu",
    pleaseEnterProductPrice: "Zadejte prosím cenu produktu",
    pleaseEnterProductOriginalPrice: "Zadejte původní cenu produktu",
    productDescription: "Popis výrobku",
    selectProductCategory: "Vyberte kategorii produktů",
    pleaseSelectProductCategory: "Vyberte prosím kategorii produktů",
    productCostPrice: "Cena produktu",
    productSellingPrice: "Prodejní cena produktu",
    productOriginalPrice: "Původní cena výrobku",
    maxFileSizeAllowedIs4Mb: "Max. Povolená velikost souboru je 4 MB",
    productsWithPicturesArePublished:
      "Produkty s obrázky jsou publikovány na Discover a přijímají objednávky",
    shouldThisProductBeTracked: "Měl by být tento produkt sledován?",
    pleaseSelectStockUnit: "Vyberte skladovou jednotku",
    stockUnit: "Skladová jednotka",
    bag: "TAŠKA",
    bottle: "LÁHEV",
    bunch: "CHOMÁČ",
    can: "UMĚT",
    carton: "LEPENKOVÁ KRABICE",
    crate: "BEDNA",
    cup: "POHÁR",
    dozen: "TUCET",
    gigabytes: "GIGABYTY",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITR",
    pack: "BALÍČEK",
    pair: "PÁR",
    pieces: "KOUSKY",
    plate: "TALÍŘ",
    tonne: "TONNE (MT)",
    uNIT: "JEDNOTKA",
    yard: "YARD",
    pleaseEnterProductQuantity: "Zadejte prosím množství produktu",
    productQuantity: "Množství produktu",
    isThisProductTaxed: "Je tento výrobek zdaněn?",
    selectTaxType: "Vyberte typ daně",
    pleaseSelectTaxType: "Vyberte prosím typ daně",
    progressive: "Progresivní",
    proportional: "Úměrný",
    pleaseEnterProductTaxRate: "Zadejte prosím sazbu daně z produktu",
    doesProductHaveVariants: "Má tento produkt varianty?",
    type: "Typ",
    value: "Hodnota",
    pleaseEnterVariantType: "Zadejte prosím typ varianty",
    pleaseEnterVariantValue: "Zadejte prosím hodnotu varianty",
    pleaseEnterVariantPrice: "Zadejte prosím cenu varianty",
    pleaseEnterVariantQuantity: "Zadejte prosím počet variant",
    productDeletedSuccessfully: "Produkt byl úspěšně smazán!",
    categoryDeletedSuccessfully: "Kategorie byla úspěšně smazána!",
    toDeleteThisProduct: "k odstranění tohoto produktu",
    invalidProductQuantity: "Neplatné množství produktu",
    quantityAddedIsOverStock:
      "Množství, které přidáváte, převyšuje množství, které máte na skladě.",
    itemInventoryNotTracked: "Inventář položek není sledován",
    addBulkQuantity: "Přidejte hromadné množství",
    enterBulkQuantity: "Zadejte hromadné množství",
    pleaseEnterBulkQuantity: "Zadejte hromadné množství",
    youveSuccessfullyAddedANewProduct: "Úspěšně jste přidali nový produkt!",
    pleaseEnterProductSellingPrice: "Zadejte prodejní cenu produktu",
    doYouWantToTrackProductStock: "Chcete sledovat skladové zásoby produktů?",
    sellingPrice: "Prodejní cena",
    setProductExpiryReminder:
      "Nastavte připomenutí vypršení platnosti produktu",
    productExpiryDate: "Datum vypršení platnosti produktu",
    startRemindingFrom: "Začít připomínat od",
    productSuppliesAddedSuccessfully:
      "Úspěšně jste přidali spotřební materiál.",
    addProductSupplies: "Přidejte spotřební materiál",
    pleaseSelectSupplier: "Vyberte dodavatele",
    nameOfProduct: "Název produktu",
    pleaseSelectProduct: "Vyberte prosím produkt",
    productVariant: "Varianta produktu",
    pleaseSelectAVariant: "Vyberte prosím variantu",
    pleaseEnterUnitPrice: "Zadejte jednotkovou cenu",
    businessBranch: "Obchodní pobočka",
    pleaseSelectBranch: "Vyberte prosím pobočku",
    youveSuccessfullyAddedANewSupplier:
      "Úspěšně jste přidali nového dodavatele",
    addSupplier: "Přidat dodavatele",
    pleaseEnterSupplierEmail: "Zadejte prosím e -mail dodavatele",
    pleaseAddADescription: "Přidejte popis",
    anErrorOccuredProductsDeleted:
      "Při provádění operace došlo k chybě. Vezměte prosím na vědomí, že v tomto procesu mohlo být odstraněno několik produktů",
    bulkDelete: "Hromadné odstranění",
    youAreAboutToDelete: "Chystáte se smazat",
    product: "Produkt",
    isDueToRedeem: "je kvůli vykoupení",
    aReward: "odměna",
    pleaseSelectCustomerToReeemReward:
      "Vyberte zákazníka, který má uplatnit odměnu.",
    youHaveNoLoyaltyProgramRunning:
      "Nemáte spuštěn žádný aktivní věrnostní program",
    customerHhasNoPointsInLoyaltyProgram:
      "Zákazník nemá v tomto věrnostním programu žádné body",
    proceedWithPayment: "Pokračovat v platbě?",
    youAreAboutToPayForTransactionUsingPoints:
      "Chystáte se zaplatit za transakci pomocí bodů.",
    customerHas: "Zákazník má",
    worth: "hodnota",
    andIsNotSufficientToPayForTransaction:
      "a není dostačující k zaplacení této transakce. Chtěli by přidat zůstatek pomocí jiné platební metody?",
    addCustomerLoyalty: "Přidejte věrnost zákazníků",
    pleaseAddCustomerFirst: "Nejprve přidejte nebo vyberte zákazníka.",
    pleaseWaitWhileWeGetReady: "Počkejte prosím, než se připravíme",
    lowStock: "Nízké zásoby",
    pleaseEnterAmountTendered: "Zadejte prosím nabídnutou částku",
    areYouSureToBookSaleOffline:
      "Opravdu chcete rezervovat tento prodej offline?",
    saleWouldBeBookedAutomatically:
      "Prodej by se zaúčtoval automaticky, když zapnete internet",
    offlineSalesBookingCancelled: "Offline rezervace prodeje zrušena",
    covid19Message:
      "COVID19: Umyjte si ruce mýdlem nebo dezinfikujte, abyste zabránili šíření. Zůstaňte vždy v bezpečí",
    saleSuccessfullyRecorded: "Prodej byl úspěšně zaznamenán!",
    sendReceiptToEmail: "Odeslat potvrzení na e -mail",
    sendThankYouSms: "Pošlete SMS s poděkováním",
    sendShippingDetails: "Odeslat přepravní údaje",
    addLoyalty: "Přidejte věrnost",
    searchCustomerNameOrNumber: "Vyhledejte jméno nebo číslo zákazníka",
    clickTheSearchCustomerBox:
      "Klikněte na pole Hledat u zákazníka a Skenovací karta",
    enterProductPrice: "Zadejte cenu produktu",
    enterPriceFor: "Zadejte cenu za",
    searchForProduct: "Hledat produkt",
    all: "Všechno",
    backToDashboard: "Zpět na hlavní panel",
    viewDraftSales: "Zobrazit koncept prodeje",
    variantSelected: "vybraná varianta",
    variant: "varianta",
    thePreviousVariantSelectionNotAvailable:
      "Předchozí výběr variant není pro novou variantu vybranou na základě ceny k dispozici, změňte prosím svůj výběr.",
    pleaseSupplyPositiveQuantityNumber:
      "Zadejte prosím číslo postivního množství",
    lowStockFor: "Nízké zásoby pro",
    clearVariants: "Jasné varianty",
    pleaseEnterQuantity: "Zadejte prosím množství",
    picture: "Obrázek",
    redemptionToken: "Žeton vykoupení",
    token: "Žeton",
    totalSpent: "Celková útrata",
    confirmPayment: "Potvrďte platbu",
    hasPaymentBeenMade: "Byla platba úspěšně zpracována?",
    enterTransactionReference:
      "Zadejte referenci transakce, kterou jste zaplatili",
    pleaseSelectACustomer: "Vyberte prosím zákazníka!",
    areYouSureToApplyDiscount: "Opravdu chcete uplatnit slevu?",
    addYourBankAccountToEnableUssd:
      "Chcete -li povolit okamžitý přenos USSD prostřednictvím uPay, přidejte svůj bankovní účet",
    totalAmountToPay: "Celková částka k zaplacení",
    doYouWantToCancelTransaction: "Chcete tuto transakci zrušit?",
    savePrintBill: "Uložit/vytisknout účet",
    enterAmountCollectedForCustomer: "Zadejte částku vybranou pro zákazníka",
    recordSale: "Rekordní prodej",
    checkOutWith: "Podívejte se pomocí",
    instantTransfer: "Okamžitý převod",
    dialTheUSSDCode: "Vytočte kód USSD",
    pleaseSelectABank: "Vyberte banku",
    payWithUSSD: "Plaťte pomocí USSD",
    sendBillTo: " - Pošlete Billa na",
    waitingForUSSDTransfer: "Čekání na přenos USSD",
    percent: "Procento",
    applyDiscount: "Uplatnit slevu",
    thisBillHasBeenSaved: "Tento návrh zákona byl uložen",
    saveDraft: "Uložit koncept",
    pleaseTypeANameToIdentifyCustomer:
      "Pro identifikaci zákazníka prosím zadejte jméno",
    paymentDetails: "Platební údaje",
    basePrice: "Základní cena",
    staff: "Personál",
    subTotal: "Mezisoučet",
    durationMonths: "Trvání (měsíce)",
    selectADuration: "Vyberte dobu trvání",
    oneMonth: "1 měsíc",
    twoMonths: "2 měsíce",
    threeMonths: "3 měsíce",
    sixMonths: "6 měsíců",
    twelveMonths: "12 měsíců",
    eighteenMonths: "18 měsíců",
    twentyFourMonths: "24 měsíců",
    twoMonthsFree: "(2 měsíce zdarma)",
    threeMonthsFree: "(3 měsíce zdarma)",
    fiveMonthsFree: "(5 měsíců zdarma)",
    yourAccountHasBeen: "Váš účet byl",
    renewed: "obnovený",
    upgraded: "upgradováno",
    successfully: "úspěšně",
    yourSubscription: "Vaše předplatné",
    youAreCurrentlyEnrolledOnA: "Aktuálně jste zapsáni do a",
    pleaseChooseAPaymentOption: "Vyberte prosím možnost platby",
    planRenewal: "Plánujte obnovu",
    planUpgrade: "Plánovat upgrade",
    pleaseSelectDurationToPayFor:
      "Vyberte prosím trvání, za které chcete zaplatit",
    staffAccounts: "Účty zaměstnanců",
    ecommerce: "Elektronický obchod",
    pleaseSelectAPlan: "Vyberte prosím plán",
    includeAddons: "Zahrnout doplňky",
    viewTransactions: "Zobrazit transakce",
    customerHasNoCompletedTansactions:
      "Zákazník zatím nedokončil žádné transakce",
    branch: "Větev",
    enterNumberOfEcommerceBranches:
      "Zadejte počet poboček elektronického obchodu",
    enterNumberOfEcommerceBranchesToPay:
      "Zadejte počet poboček elektronického obchodu, za které hodláte platit",
    ecommerceIntegration: "Integrace elektronického obchodu",
    enterNumberOfBranches: "Zadejte počet poboček",
    enterNumberOfAdditionalBranchesToPay:
      "Zadejte počet dalších poboček, za které hodláte platit",
    enterNumberOfStaffs: "Zadejte počet štábů",
    enterNumberOfStaffsToPayFor:
      "Zadejte počet zaměstnanců, za které hodláte platit",
    discountApplies: "Platí sleva",
    starsOnThe: "hvězdy na",
    offer: "nabídka",
    get: "Dostat",
    moreStarsToRedeem: "vykoupit více hvězd",
    taxVat: "Daň (DPH)",
    callCashierToCompletePayment: "Zavolejte pokladní a dokončete platbu",
    receipt: "Účtenka",
    dear: "Milý",
    thankYouForYourOrderFindGoods:
      "Děkujeme za Vaši objednávku. Najděte následující dodané zboží, jak bylo dohodnuto.",
    shippingNote: "Přepravní poznámka",
    enterShippingNote: "Zadejte dodací list",
    shippingAddress: "Doručovací adresa",
    enterShippingAddress: "Zadejte dodací adresu",
    wellDoneYouAreDueToRedeem: "Výborně! Máte nárok na uplatnění",
    toGetYourRewardNextVisit:
      "abyste dostali odměnu při příští návštěvě. Děkuji",
    pointsOnThe: "Body na",
    morePointsToRedeem: "více bodů k uplatnění",
    showCode: "Zobrazit kód",
    toGetYourRewardOnNextVisit:
      "abyste dostali odměnu při příští návštěvě. Děkuji",
    earn: "Vydělat",
    delivaryNote: "Doručení Poznámka",
    draftSales: "Návrh prodeje",
    startDate: "Datum začátku",
    endDate: "Datum ukončení",
    orders: "Objednávky",
    checkout: "Překontrolovat",
    noProductItem: "Žádný produkt",
    selectProductImage: "Vyberte obrázek produktu",
    selectCountry: "Vyberte zemi",
    selectRegion: "Vyberte stát/oblast",
    printProductTag: "Vytisknout produktový štítek",
    transactionReference: "Reference transakce",
    Cashier: "Pokladní",
    Manager: "Manažer",
    Owner: "Majitel",
    Admin: "Admin",
    addPartners: "Přidat partnery",
    addNewLoyaltyPartner: "Přidat nového věrnostního partnera",
    pleaseEnterCompanyName: "Zadejte název společnosti",
    companyName: "Jméno společnosti",
    pleaseEnterCompanyRepName: "Zadejte jméno zástupce společnosti",
    companyRepName: "Jméno zástupce společnosti",
    pleaseEnterCompanyRepEmail: "Zadejte e-mail zástupce společnosti",
    companyRepEmail: "Email zástupce společnosti",
    pleaseEnterCompanyRepPhone:
      "Zadejte prosím telefonní číslo zástupce společnosti",
    companyRepPhone: "Telefonní číslo zástupce společnosti",
    addReward: "Přidat odměnu",
    pleaseEnterRewardName: "Zadejte název odměny",
    rewardName: "Název odměny",
    rewardQuantity: "Množství odměny",
    rewardDescription: "Popis odměny",
    rewardType: "Typ odměny",
    pleaseEnterRewardType: "Zadejte prosím typ odměny",
    pleaseEnterRewardQuantity: "Zadejte množství odměny",
    pleaseEnterRewardDescription: "Zadejte prosím popis odměny",
    fineDining: "Dobré jídlo",
    luxuryFashion: "Luxusní móda",
    hotels: "hotely",
    travel: "Cestovat",
    foodAndBeverage: "Potravin a nápojů",
    fashion: "Móda",
    health: "Zdraví",
    furniture: "Nábytek",
    entertainment: "Zábava",
    automobile: "Automobil",
    education: "Vzdělávání",
    beautyAndSpa: "Krása a lázně",
    staycation: "Pobyt",
    events: "Události",
    trips: "Výlety",
    oilAndGas: "Ropa a plyn",
    laundry: "Prádelna",
    partnerCategory: "Kategorie partnera",
    freeItem: "Položka zdarma",
  },
  Esperanto: {
    cashier: "kasisto",
    manager: "administranto",
    owner: "posedanto",
    online: "interrete",
    offline: "eksterrete",
    changePassword: "Ŝanĝi Pasvorton",
    currentPasswordMessage: "Bonvolu enigi vian nunan pasvorton",
    passwordMessage: "Bonvolu enigi vian pasvorton",
    currentPassword: "Aktuala Pasvorto",
    password: "Pasvorto",
    confirmPasswordMessage: "Bonvolu konfirmi vian pasvorton!",
    confirmPassword: "Konfirmu pasvorton",
    sendViaEmail: "Sendu per retpoŝto",
    sendViaWhatsapp: "Sendu Per WhatsApp",
    downloadPdf: "Elŝutu PDF",
    paid: "pagita",
    unpaid: "sensalajra",
    partial: "parta",
    closeInvoice: "Ĉu vi volas fermi la Fakturon?",
    closeInvoiceConfirmation:
      "Fakturo eble ne estas konservita. Ĉu vi volas fermi?",
    yes: "jes",
    no: "ne",
    invoice: "Fakturo",
    wasDeducted: "estis subtrahita",
    for: "por",
    item: "Ero",
    addProduct: "Aldoni Produkton",
    paymentReference: "Paga Referenco",
    amountPaid: "Kvanto pagita",
    discountAmount: "Rabata Kvanto",
    amountDue: "Sumo Pagenda",
    amount: "Kvanto",
    dueDate: "Limdato",
    paymentType: "Paga Tipo",
    card: "Karto",
    cash: "Mono",
    bankTransfer: "Banka ĝiro",
    paymentMessage: "Paga Mesaĝo",
    description: "Priskribo",
    sendReceipt: "Sendu Kvitancon",
    delete: "Forigi",
    save: "Savi",
    resend: "Re-Sendu",
    saveAndSend: "Savi",
    invoiceSaved: "Fakturo konservita!",
    selectPaymentMethod: "Bonvolu elekti Pagmanieron!",
    selectCustomer: "Bonvolu elekti Klienton!",
    cartEmptyError:
      "Ĉaro-Listo ne povas esti malplena, fermu la fakturon kaj aldonu Aĵon al Ĉaro!",
    subscriptionExpired:
      "Via abono eksvalidiĝis kaj via konto nun estas limigita. Alklaku la butonon sube por renovigi vian konton",
    renew: "Renovigu",
    holdOn: "Atendu",
    customerBank: "Klienta Banko",
    cancel: "Nuligi",
    selectACustomer: "Elektu Klienton",
    invoiceSuccessfulPdfError:
      "Fakturo kreita sukcese sed PDF-Generacio daŭras pli longe ol kutime. Bonvolu revidi baldaŭ.",
    downloadingInvoice: "Elŝuti Fakturon",
    downloadingReceipt: "Elŝutanta Kvitancon",
    whatsappReceiptError:
      "Eraro okazis dum sendado de la kvitanco per WhatsApp, Bonvolu reprovi.",
    receiptToWhatsapp: "Kvitanco plusendita al WhatsApp",
    thankYouForPatronage: "Dankon pro via patroneco",
    hereIsYourReceipt: "Jen via pagokvitanco",
    errorSendingEmailReceipt:
      "Eraro okazis dum sendado de la kvitanco retpoŝte, bonvolu reprovi aŭ kontakti subtenon",
    receiptSentToEmail: "Kvitanco estis sendita al la retpoŝto de kliento",
    invoiceSentToEmail: "Fakturo estis sendita al la retpoŝto de kliento",
    invoiceSuccessWhatsappError:
      "Fakturo kreiĝis sukcese sed okazis eraro dum sendado al WhatsApp. Provu denove en la faktura listo",
    invoiceSuccessfulEmailError:
      "Fakturo kreiĝis sukcese sed eraro okazis dum sendado kiel retpoŝto. Bonvolu reprovi el la faktura listo",
    invoiceSentToWhatsapp: "Fakturo plusendita al WhatsApp",
    hello: "Saluton",
    pleaseDownloadInvoice: "Bonvolu elŝuti fakturon",
    pleaseDownloadReceipt: "Bonvolu elŝuti kvitancon",
    from: "de",
    email: "Retpoŝto",
    upgrade: "Altgradigon",
    youAreOnFreePlan: "Vi estas senpaga plano.",
    clickOn: "Alklaku",
    yourPlanInFewSteps: " vian abonon en kelkaj rapidaj paŝoj.",
    to: "al",
    yourSubscriptionHasExpired:
      "Via abono eksvalidiĝis kaj via konto nun estas limigita.",
    days: "tagojn",
    yourSubscriptionExpiresIn: "Via abono Loystar eksvalidiĝas en",
    createAcount: "Krei konton",
    signIn: "Ensaluti",
    continue: "Daŭrigu",
    enterOtp: "Enigu OTP-PIN",
    enterValidOtp: "Bonvolu enigi validan PIN",
    pin: "PIN",
    tokenSentToMail: "Toetono estis sendita al via retpoŝto",
    passwordResetSuccessful: "Pasvorta restarigo sukcesis",
    somethingWentWrong: "Io fuŝiĝis!",
    resetPassword: "Restarigi Pasvorton",
    iHaveResetCode: "Mi havas pasvortan rekomencigan kodon",
    pleaseEnterEmail: "Bonvolu enigi vian retpoŝton",
    aTokenWillBeSentToEmail: "Toetono estus sendita al via retpoŝto",
    enterEmail: "Enigu vian retpoŝton",
    sendinYourToken: "Sendante vian ĵetonon ...",
    makeSureItMatchesPassword: "Certigu, ke ĝi kongruas kun via nova pasvorto",
    pleaseChooseNewPassword: "Bonvolu elekti novan pasvorton",
    chooseNewPassword: "Elektu novan pasvorton",
    enterNewPassword: "Enigu vian novan pasvorton por konfirmi",
    enterTokenSent: "Enigu la signon, kiu estis sendita al via poŝto",
    resetToken: "Restarigi Token",
    resettingPassword: "Restarigante vian pasvorton ...",
    signUp: "Registriĝi",
    adminSignInWithEmail:
      " Administranto ensalutas per retpoŝto dum dungito ensalutas per uzantnomo.",
    pleaseEnterEmailOrUsername: "Bonvolu enigi vian retpoŝton aŭ salutnomon",
    emailOrUsername: "Retpoŝtadreso aŭ uzantnomo",
    pleaseEnterPassword: "Bonvolu enigi pasvorton",
    createAnAccount: "Krei konton",
    forgotPassword: "Ĉu vi forgesis la pasvorton?",
    enterPhoneNumber: "Enigu Telefonnumeron",
    personalData: "Personaj Datumoj",
    validateConfirmationCOde: "Konfirmu Konfirman Kodon",
    pleaseEnterFirstName: "Bonvolu enigi vian antaŭnomon",
    pleaseEnterPhoneNumber: "Bonvolu enigi vian telefonnumeron",
    pleaseEnterLastName: "Bonvolu enigi vian familian nomon",
    pleaseEnterBusinessName: "Bonvolu enigi vian komercan nomon",
    firstName: "Antaŭnomo",
    lastName: "Familia nomo",
    businessName: "Komerca Nomo",
    previous: "Antaŭa",
    next: "Poste",
    pleaseSelectBusinessCategory: "Bonvolu elekti vian komercan kategorion",
    pleaseEnterValidEmail: "Bonvolu enigi validan retpoŝton",
    pleaseEnterPostCode: "Bonvolu enigi poŝtkodon",
    postCode: "Poŝta Kodo",
    phoneNumberInUse: "Ĉi tiu telefona numero estas jam uzata!",
    emailInUse: "Ĉi tiu retpoŝto jam estas uzata!",
    foodAndDrinks: "Manĝaĵoj kaj Trinkaĵoj",
    salonAndBeauty: "Salono kaj Beleco",
    fashionAndAccessories: "Modo kaj Akcesoraĵoj",
    gymAndFitness: "Trejnsalono kaj Trejniteco",
    travelAndHotel: "Vojaĝado kaj Hotelo",
    homeAndGifts: "Hejmo kaj Donacoj",
    washingAndCleaning: "Lavado kaj Purigado",
    gadgetsAndElectronics: "Noviletoj kaj Elektroniko",
    groceries: "Nutraĵvaroj",
    others: "Aliaj",
    submit: "Sendu",
    accountCreatedSuccessful: "Via konto kreiĝis sukcese.",
    pleaseEnterAddress: "Bonvolu Enigi Vian Adreson",
    addressLine1: "Adresa Linio 1",
    addressLine2: "Adresa Linio 2",
    choosePassword: "Elektu pasvorton",
    passwordMustBe6Characters: "Pasvorto devas esti almenaŭ 6 signoj.",
    howDidYouHearLoystar: "Kiel vi aŭdis pri Loystar?",
    referralCode: "referenca Kodo",
    byClickingTheButton: " Alklakante la suban butonon, vi konsentas la",
    termsAndSevice: "Kondiĉoj",
    wereCreatingAccount: "Ni kreas vian konton",
    proceed: "Daŭrigu",
    verificationCodeMustBe6: "Kontrola Kodo devas esti 6 ciferoj",
    pleaseEnter6DigitCode: "Bonvolu Enigi 6-ciferan kodon",
    enterVerificationCode: "Enigu Kontrolan Kodon",
    resendToken: "Resendi Retonon",
    verify: "Konfirmu",
    transactions: "Transakcioj",
    todaySales: "Hodiaŭaj Vendoj",
    salesHistory: "Vendhistorio",
    supplyHistory: "Proviza Historio",
    new: "Nova",
    invoices: "Fakturoj",
    disbursements: "Elspezoj",
    offlineSales: "Senreta Vendado",
    products: "Produktoj",
    customers: "Klientoj",
    multiLevelLoyalty: "Plurnivela Lojaleco",
    loyaltyPrograms: "Programoj de Lojaleco",
    members: "Membroj",
    appStore: "App Store",
    orderMenu: "Mendu Menuon",
    settings: "Agordoj",
    staffAndBranches: "Bastonoj kaj Branĉoj",
    myAccount: "Mia konto",
    switchToSellMode: "Ŝanĝu al Venda Reĝimo",
    signOut: "Elsaluti",
    getFreeSubscription: "Akiru Senpagan Abonon",
    onlyNumbersAllowed: "Nur numeroj estas permesitaj",
    yourAccountMustBe10Digits: "via konto-numero devas esti 10-cifera numero",
    yourBvnMustBe11: "via BVN devas esti 11-cifera numero",
    pleaseSelectBank: "Bonvolu elekti vian bankon",
    selectYourBank: "Elektu vian bankon",
    enterBankAccountNumber: "Enigu Bankan Konto-Numeron",
    enterBvnNumber: "Eniru vian BVN",
    connectBankAccount: "Konekti Bankan Konton",
    passwordResetSuccessfulAndSignOut:
      "Via Pasvorto sukcese rekomenciĝis. Alklaku la Suban butonon por subskribi denove",
    enterCurrentPassword: "Enigu nunan pasvorton",
    pleaseEnterCurrentPassword: "Bonvolu enigi nunan pasvorton",
    phoneNumber: "Telefonnumero",
    sex: "Sekso",
    dateOfBirth: "Naskiĝdato",
    state: "Ŝtato",
    country: "Lando",
    loyaltyId: "Fidela ID",
    createdAt: "Kreita Ĉe",
    noOfTransactionsMade: "Nombro de transakcioj faritaj",
    yourDownloadWillStart: "Via elŝuto komenciĝos post momento",
    exportCustomers: "Eksportaj Klientoj",
    youhaveSuccessfullyToppedUp: "Vi sukcese kompletigis viajn SMS-unuojn.",
    youNowHave: "Vi nun havas",
    smsUnits: "SMS-Unuoj",
    enterNumberOfSmsUnits: "Enigu Nombro de SMS-Unuoj, kiujn Vi Intencas Aĉeti",
    pleaseEnterANumericValue: "Bonvolu enmeti nombran valoron",
    pay: "Pagu",
    accountEditedSuccessfully: "Via konto estis redaktita sukcese.",
    youAeCurrentlyOn: "Vi aktuale aktivas",
    plan: "Plano",
    userData: "Uzanto-Datumoj",
    businessData: "KOMERTAJ DATUMOJ",
    businessCategory: "Komerca Catergory",
    pleaseSelectCurrency: "Bonvolu elekti vian valuton",
    selectYourCurrency: "Elektu vian valuton",
    purchaseMoreSmsUnits: "Aĉetu pli da SMS-Unuoj per la suba formularo",
    youHave: "Vi havas",
    atLeast4SmsUnitsRrequired:
      "Almenaŭ 4 sms-unuoj necesas por konfirmo, bonvolu kompletigi!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Bonvolu kontroli vian bankan konton por plenumi la postulojn "Koni Vian Klienton" (KYC). Ĉi tio permesos vin kolekti pagojn per USSD aŭ tuja translokigo, ricevi mendojn de klientoj kaj procesi transakcion. Aplikebla nur al niĝeriaj komercistoj. Bonvolu premi la suban butonon por komenci.',
    reConnectBankAccount: "Rekonekti Bankan Konton",
    accountName: "Kontonomo",
    accountNumber: "Kontonumero",
    bankName: "Banka Nomo",
    verified: "Konfirmita",
    accountDetails: "Kontaj Detaloj",
    kycBankAccount: "KYC",
    createTier: "Kreu Nivelon",
    fileUploadSuccessful: "dosiero alŝutita sukcese",
    fileUploadFailed: "dosiero alŝutita malsukcesis",
    createLoyaltyProgram: "Kreu Lojalan Programon",
    createLoyalty: "Kreu Lojalecon",
    name: "Nomo",
    loyaltyArtwork: "Lojala Artaĵo",
    clickToUpload: "Alklaku por alŝuti",
    amountToPointsRatio: "Kvanto al Punktoj-Rilato",
    points: "Punktoj",
    recommendedAmountToPointRatio:
      "Rekomendita: ₦ 1 ĝis 1 poento. te por ĉiu 1 Naira elspezita, viaj klientoj ricevas 1 poenton",
    pleaseTypeIn: "Bonvolu tajpi",
    toDeleteLoyalty: "forigi ĉi tiun lojalecon",
    deleteLoyalty: "Forigi Lojalecon",
    toConfirm: "konfirmi",
    edit: "Redaktu",
    pointsAwardedSuccessfully: "Punktoj Aljuĝitaj Sukcese.",
    enterPointValueToAward:
      "Enigu Punktan Valoron, kiun Vi Ŝatus Premii Klientojn",
    award: "Premio",
    awardPointValuesToCustomer: "Premio de Punkta Valoro al Kliento",
    enrollMembersToLoyalty: "Registri Membrojn al Lojaleco",
    awardPoints: "Premiaj Punktoj",
    enroll: "Enskribiĝi",
    home: "Hejmo",
    loyalty: "Lojaleco",
    enrollCustomers: "Enskribu Klientojn",
    selected: "Elektita",
    customer: "Kliento",
    loyaltyActivationSuccessful: "Lojaleca Aktivigo sukcesas.",
    loyaltyDeactivationSuccessful: "Lojaleca Malaktivigo sukcesas.",
    viewTier: "Vidi Nivelon",
    deactivate: "Malaktivigi",
    activate: "Aktivigi",
    actions: "Agoj",
    nameOfLoyalty: "Nomo de Lojaleco",
    loyaltyStatus: "Lojala Stato",
    numberOfTiers: "Nombro de Tieroj",
    createdOn: "Kreita la",
    createATier: "Kreu Nivelon",
    stopCreatingTierConfirmation: "Ĉu vi volas ĉesi krei nivelon?",
    stopEditingTierConfirmation: "Ĉu vi volas ĉesi redakti ĉi tiun nivelon?",
    nameOfTier: "Nomo de Tier",
    minimumSpendingTarget: "Minimuma Elspezita Celo",
    maximumSpendingTarget: "Maksimuma Elspezita Celo",
    minimumSpendingTargetRequired: "minimuma elspezocelo estas postulata",
    maximumSpendingTargetRequired: "maksimuma elspezocelo estas postulata",
    rewardSponsor: "Rekompensa Sponsoro",
    pleaseChooseARewardSponsor: "Bonvolu elekti rekompencan sponsoron",
    self: "Mem",
    partner: "Partnero",
    rewardPartner: "Rekompenca Partnero",
    pleaseSelectRewardPartner: "Bonvolu elekti vian rekompencan partneron",
    rewards: "Rekompencoj",
    pleaseSelectAReward: "Bonvolu elekti rekompencon",
    instructionsOnRedeemingReward:
      "Instrukcioj pri Kiel Kliento Elaĉetu Rekompencon",
    pleaseFillInThisField: "Bonvolu Plenigi ĉi tiun Kampon!",
    toDeleteThisTier: " por forigi ĉi tiun nivelon",
    deleteTier: "Forigi Nivelon",
    viewMembers: "Vidi Membrojn",
    membersEnrolled: "Membroj Aliĝintaj",
    instruction: "Instrukcio",
    membersIn: "Membroj en",
    availableTiersInLoyalty: "Haveblaj Nivelo (j) en Lojala Programo",
    tiers: "Tieroj",
    totalTier: "SUMA TIERO",
    availableLoyaltyProgramme: "Havebla Lojaleca Programo",
    totalLoyalties: "TUTE FIDELOJ",
    memberDetails: "Membraj Detaloj",
    nameOfCustomer: "Nomo de Kliento",
    address: "Adreso",
    allEnrolledMembers: "Ĉiuj Aliĝintaj Membroj",
    thisIsToWishYouHappyBirthday:
      "Ĉi tio estas deziri al vi tre feliĉan naskiĝtagon kaj prosperan vivon. Dankon pro ĉio, kio vi estas al Loyster. Feliĉajn Festojn!",
    inputAnOfferPlease: "Enmetu Oferton bonvolu",
    pleaseSelectTheInsertPoint:
      "Bonvolu elekti la enmetpunkton en la mesaĝo kaj alklaki denove",
    birthdayOfferAndMessage: "Naskiĝtaga Oferto kaj Mesaĝo",
    birthdayOffer: "Naskiĝtaga Oferto",
    birthdayMessage: "Naskiĝtaga Mesaĝo",
    noOfferFound: "Neniu oferto trovita",
    settingABirthdayOffer:
      "Agordi naskiĝtagan oferton permesas al klientoj ricevi ĉi tiun oferton per SMS dum ilia naskiĝtago",
    createOffer: "Kreu Oferton",
    whatIsTheOffer: "Kio estas la oferto?",
    editMessage: "Redaktu Mesaĝon",
    insert: "Enmetu",
    theNameOfCustomerInserted: "La nomo de la kliento estos enigita ĉi tie",
    theBirtdayOfferInserted: "La naskiĝtaga oferto estos enmetita ĉi tie",
    youSuccessfullyAddedNewBranch: "Vi sukcese aldonis novan filion!",
    addNewBranch: "Aldonu Novan Filion",
    addBranch: "Aldonu Branĉon",
    additionalBranchWillIncur: "Plia Branĉo falus",
    perBranch: "po branĉo",
    ecommerceBranchCosts: "Ekomerca Branĉo kostas",
    pleaseEnterBranchName: "Bonvolu enigi la branĉan nomon",
    pleaseEnterBranchAddress1: "Bonvolu enigi la adreslinion 1 de la filio",
    enterBranchAddress1: "Enigu la adreslinion 1 de la filio",
    enterBranchAddress2: "Enigu la adreslinion 1 de la filio",
    pleaseEnterBranchAddress2: "Bonvolu enigi la adresan linion 2 de la filio",
    enterBranchName: "Enigu la branĉan nomon",
    successfullyAddedStaff: "Vi sukcese aldonis novan personaron!",
    addNewStaff: "Aldoni Novan Kunlaborantaron",
    addStaff: "Aldoni Bastonon",
    additionalStaffWillIncur: "Plia Stabo falus",
    perStaff: "po personaro.",
    pleaseEnterStaffEmail: "Bonvolu eniri la retpoŝton de la dungitaro",
    pleaseEnterStaffUsername: "Bonvolu enigi la uzantnomon de la dungitaro",
    pleaseEnterStaffPassword: "Bonvolu enigi la pasvorton de la dungitaro",
    pleaseSelectStaffRole: "Bonvolu elekti la rolon de la dungitaro",
    selectStaffRole: "Elektu la rolon de la dungitaro",
    enterStaffEmail: "Enigu la retpoŝton de la dungitaro",
    enterStaffUsername: "Enigu la uzantnomon de la dungitaro",
    enterStaffPassword: "Enigu la pasvorton de la dungitaro",
    spacesNotAllowedInUsername: "spacoj ne permesataj en uzantnomo",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Bonvolu elekti kompanion por ligi personaron",
    searchForBranchToAttachStaff: "Serĉu filion por ligi personaron",
    username: "Uzantnomo",
    role: "Rolo",
    areYouSureToDeleteThis: "Ĉu vi certe forigos ĉi tion",
    branches: "Branĉoj",
    upgradeYourPlan: "Ĝisdatigu vian Planon.",
    add: "ALDONI",
    addNew: "Aldoni Novan",
    customerWithEmailAlreadyExists:
      "Kliento kun retpoŝta / telefona numero jam ekzistas!",
    successfullyAddedNewCustomer: "Vi sukcese aldonis novan klienton!",
    addCustomer: "Aldonu Klienton",
    pleaseEnterCustomerFirstName: "Bonvolu enigi la antaŭnomon de kliento",
    pleaseEnterCustomerLastName: "Bonvolu enigi la familian nomon de kliento",
    pleaseEnterCustomerPhoneNumber:
      "Bonvolu enigi la telefonnumeron de kliento",
    pleaseEnterCustomerEmail: "Bonvolu enigi la retpoŝton de kliento",
    pleaseEnterCustomerAddressLine: "Bonvolu eniri la adreslinion de kliento",
    pleaseEnterCustomerOtherAddress:
      "Bonvolu enigi la alian adreson de kliento",
    pleaseSelectCustomerGender: "Bonvolu elekti la sekson de kliento",
    gender: "Sekso",
    male: "Vira",
    female: "Ino",
    bank: "Banko",
    selectBank: "Elektu Bankon",
    stateOrRegionOrProvince: "Ŝtato / Regiono / Provinco",
    customerNotes: "Klientaj Notoj",
    sendSms: "Sendu SMS",
    editCustomer: "Redaktu Klienton",
    redeemReward: "Redonu Rekompencon",
    issueLoyaltyCard: "Eldonu Fidelan Karton",
    deleteCustomer: "Forigi Klienton",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Vi sukcese Asignis Fidelan Membrecan Identigilon",
    noMembershipIdAvailable:
      "Neniuj membroj-identigiloj haveblas. Bonvolu kontakti nin per hello@loystar.co",
    sendEmail: "Sendu retpoŝton",
    membershipPoints: "Membrecaj Punktoj",
    customerDetails: "Klientaj Detaloj",
    close: "Fermu",
    loyaltyBalance: "Fidela Ekvilibro",
    pointsBalance: "Punktoj-Ekvilibro",
    starBalance: "Stela Ekvilibro",
    requiredPoints: "Bezonataj Punktoj",
    requiredStars: "Postulataj Steloj",
    reddemCode: "Redonu Kodon",
    toDeleteThisCustomer: "forigi ĉi tiun klienton",
    customerHasBeenDeletedSuccessfully: "Kliento estis forigita sukcese!",
    customerWithPhoneAlreadyExists: "Kliento kun telefonnumero jam ekzistas",
    customerWasSuccessfullyEdited: "Kliento estis sukcese redaktita",
    anErrorOccured: "Eraro okazis",
    phoneNumberIncludeCountry: "Telefona Numero (Inkluzivi Landan Kodon)",
    yourFileQueuedForUpload:
      "Via dosiero estis vicigita por alŝuto. Bonvolu refreŝigi la paĝon post kelkaj sekundoj.",
    thereWasAnErrorPerformingOperation: "Estis eraro plenumante la operacion!",
    pleaseSelectFile: "Bonvolu Elekti Dosieron!",
    oopsOnlyCsvFilesAllowed:
      "Ho! Nur CSV-dosieroj estas permesitaj. Bonvolu alŝuti .csv-dosieron.",
    fileShouldBeSmallerThan5Mb:
      "Dosiero devas esti malpli granda ol 5MB. Se vi havas pli grandan dosieron, bonvolu retpoŝti al support@loystar.co. Dankon",
    customerFirstNameIsRequired: "klienta nomo estas bezonata ĉe vico",
    customerPhoneNumberIsRequired: "klienta telefonnumero necesas ĉe vico",
    importCustomers: "Importi Klientojn",
    upload: "Alŝuti",
    clickIconToDownloadCsv:
      "Alklaku ĉi tiun ikonon por elŝuti la CSV-dosieran ŝablonon.",
    getGoogleSheetVersion: "Akiru la version de Google-folio",
    clickOrDragFileToUpload:
      "Alklaku aŭ trenu dosieron al ĉi tiu areo por alŝuti",
    missingOrInvalidColumnHeader:
      "Mankas aŭ nevalidas kolumna titolo. Bonvolu sekvi la ŝablonan formaton. Dankon.",
    youHaveImported: "Vi Importis",
    youSuccessfullyRedeemedReward: "Vi sukcese elaĉetis vian rekompencon!",
    sixDigitCode: "Sescifera kodo",
    pleaseEnterCustomerRewardCode:
      "Bonvolu enigi la rekompencan kodon de kliento",
    enterRewardCode:
      "Enigu rekompencan kodon. (La rekompenca kodo distingas majusklojn)",
    selectLoyaltyProgram: "Elektu lojalecan programon",
    stamps: "Poŝtmarkoj",
    smsUnitsLow: "SMS-Unuoj Malalte",
    pleaseTopUpSmsUnits: "Bonvolu Kompletigi SMS-Unuojn",
    smsSuccessfullySentForDelivery: "SMS sukcese sendita por liverado!",
    sendSmsTo: "Sendu SMS al",
    allCustomers: "Ĉiuj Klientoj",
    unitsAvailable: "Disponeblaj Unuoj",
    pleaseTypeInTheMessage: "Bonvolu tajpi la mesaĝon",
    message: "Mesaĝo",
    charactersRemaining: "signoj restantaj",
    avoidUseOfSpecialCharacters:
      "Evitu la uzon de Specialaj Karakteroj kaj Emojis por konservi SMS-Unuojn.",
    note: "Notu",
    errorFetchingCustomersMultilevelDetail:
      "Eraro Alportante Klientojn Multnivelajn Detalojn",
    search: "Serĉu",
    reset: "Restarigi",
    importCustomer: "Importi Klienton",
    addNewCustomer: "Aldonu Novan Klienton",
    sendSmsBroadcast: "Sendu SMS-elsendon",
    totalCustomers: "SUMOJ KLIENTOJ",
    disbursementDetails: "Elspezaj Detaloj",
    paidBy: "Pagita De",
    disbursed: "Elpagita",
    bankAccountName: "Nomo de Banka Konto",
    bankAccountNumber: "Banka Konto-Numero",
    transferInitiated: "Translokigo Komencita",
    transferCompleted: "Translokigo Finita",
    pleaseEnterAValid: "Bonvolu enigi validan",
    begin: "komenci",
    end: "fino",
    date: "dato",
    paymentDate: "Dato de Pago",
    selectDisbursementDuration: "Elektu Elspezan Daŭron",
    totalSettled: "Entute Enloĝigita",
    totalUnsettled: "Tuta Neklarigita",
    toDeleteThisSale: "forigi ĉi tiun vendon",
    draftSaleDeletedSuccessfully: "Malneta Vendo estis forigita sukcese!",
    deleteSale: "Forigi Vendon",
    pleaseTypeInYourTotal: "Bonvolu tajpi vian sumon por konfirmi forigon",
    billDetails: "Fakturaj Detaloj",
    printBill: "Presi Fakturon",
    servedBy: "Servita de",
    total: "Entute",
    createdDate: "Kreita Dato",
    createdTime: "Kreita Tempo",
    status: "Statuso",
    loginSuccessful: "Ensaluto Sukcesa",
    pleaseWaitWhileWeConnectAccount:
      "Bonvolu atendi, dum ni konektos vian konton",
    connectionFailedTryAgain: "Konekto malsukcesis. Bonvolu reprovi.",
    connectionSuccessful: "Konekto Sukcesa",
    viewDetails: "Vidi Detalojn",
    enable: "Ebligi",
    free: "Senpaga",
    cost: "Kosto",
    visitWebsite: "Vizitu Retejon",
    pleaseUpgradeYourPlanToPro:
      "Bonvolu ĝisdatigi vian planon al Pro Plus por ebligi ĉi tiun programon",
    connectYourBankAccount:
      "Konektu vian bankan konton por povi ricevi pagojn.",
    disable: "Malebligi",
    enableApp: "Ebligi programon",
    addNewProductToInvoice: "Aldonu Novan Produkton al Fakturo",
    toDeleteThisInvoice: "por forigi ĉi tiun Fakturon",
    invoiceDeletedSuccessfully: "Fakturo estis sukcese forigita!",
    deleteInvoice: "Forigi Fakturon",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Vi sukcese aldonis Fakturan pagmesaĝon!",
    pleaseEnterPaymentInstructions: "Bonvolu enigi paginstrukciojn",
    invoiceId: "Faktura ID",
    paidDate: "Pagita Dato",
    reference: "Referenco",
    productAdded: "Produkto aldonita",
    productOutOfStock: "Produkto elĉerpita. Bonvolu reprovizi.",
    totalInvoices: "SUMAJ FAKSOJ",
    totalPaidInvoices: "SUMPAGA FAKTOJ",
    totalUnpaidInvoices: "TUTE SENPAGAJ FAKSOJ",
    loyaltyProgramDeleted: "Programo de Lojaleco Forigita",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ĉi tiu lojala programo estis forigita sukcese",
    thisLoyaltyProgramEditedSuccessfully:
      "Ĉi tiu lojaleca programo estis redaktita sukcese",
    loyaltyProgramAddedSuccessfully: "Lojala programo estis aldonita sukcese",
    loyaltyProgramEdited: "Redaktita Programo de Lojaleco",
    loyaltyProgramAdded: "Aldonita Programo de Lojaleco",
    loyaltyDetails: "Lojalaj Detaloj",
    doYouWantToCloseDialog: "Ĉu vi volas fermi ĉi tiun dialogon?",
    pleaseEnterLoyaltyName: "Bonvolu enigi la nomon de via lojaleco",
    programType: "Programo-Tipo",
    pleaseSelectProgramType: "Bonvolu elekti programan tipon",
    simplePoints: "Simplaj Punktoj",
    stampsProgram: "Poŝtmarkoj-Programo",
    threshold: "Sojlo",
    pleaseEnterLoyaltyThreshold: "Bonvolu enigi la lojalan sojlon",
    reward: "Rekompenco",
    pleaseEnterLoyaltyReward: "Bonvolu enigi la rekompencon de la lojaleco",
    totalUserPoints: "Entute Uzanto-Punktoj",
    totalUserStamps: "Entutaj poŝtmarkoj",
    spendingTarget: "Elspezanta celo",
    spendingTargetHint1:
      "Elspezi celon estas kiom multe kliento devas elspezi por gajni la rekompencon. 1 valuto = 1 punkto.",
    spendingTargetHint2:
      "Poŝtmarkoj celas kiom multajn poŝtmarkojn kliento devas kolekti por gajni la rekompencon. ekz. 5",
    addNewLoyaltyProgram: "Aldonu Novan Lojalan Programon",
    addLoyaltyProgram: "Aldonu Programon de Lojaleco",
    loyaltyProgramType: "Programo de Lojaleco",
    pleaseSelectLoyaltyProgramType: "Bonvolu elekti programon de lojaleco",
    nameOfProgram: "Nomo de programo",
    pleaseEnterProgramName: "Bonvolu enigi programan nomon",
    whatIsTheReward: "Kio estas la rekompenco?",
    egNextPurchaseIsFree: "Ekz. Sekva aĉeto estas senpaga",
    customerName: "Klienta Nomo",
    guestCustomer: "Gasta Kliento",
    orderConfirmedSuccessfully: "Mendo konfirmita sukcese",
    orderCancelledSuccessfully: "Mendo nuligita sukcese",
    confirmOrder: "Konfirmu mendon",
    cancelOrder: "Nuligi mendon",
    allOrders: "Ĉiuj mendoj",
    totalOrders: "SUMAJ MENDOJ",
    doYouWantToAcceptOrder: "Ĉu vi volas akcepti ĉi tiun mendon?",
    doYouWantToCancelOrder: "Ĉu vi volas nuligi ĉi tiun mendon?",
    orderDetails: "Ordonaj Detaloj",
    orderCreatedAt: "Ordo kreita ĉe",
    supplier: "Liveranto",
    productName: "Produkta Nomo",
    quantity: "Kvanto",
    unitPrice: "Prezo unuopa",
    receivedBy: "Ricevita de",
    reportFrom: "Raporto de",
    totalSupplies: "Entutaj provizoj",
    allRightsReserved: "Ĉiuj rajtoj rezervitaj",
    toDeleteThisTransaction: "forigi ĉi tiun Transakcion",
    transactionDeletedSuccessfully:
      "Transakcio estis forigita sukcese. Akcioj revenis al stokregistro.",
    deleteTransaction: "Forigi Transakcion",
    transactionDetails: "Detaloj pri Transakcio",
    printReceipt: "Presi Kvitancon",
    channel: "Kanalo",
    discount: "Rabato",
    profit: "Profito",
    discountedSales: "Rabataj Vendoj",
    errorFetchingRecord: "Eraro dum rekupero",
    exportTransactions: "Eksportaj Transakcioj",
    errorFetchingSalesRecord: "Eraro dum vendado de Rekordo pri Eksportado.",
    totalSellingPrice: "Tuta Vendoprezo",
    totalCostPrice: "Totala Kosta Prezo",
    appliedDiscount: "Aplikita Rabato",
    noOfItems: "Nombro de Eroj",
    sales: "Vendoj",
    export: "Eksporti",
    totalProfit: "Totala Profito",
    totalBalanceInPeriod: "Tuta Ekvilibro en Periodo",
    allTimeSales: "Ĉiamaj Vendoj",
    records: "Rekordoj",
    todaysSales: "Hodiaŭaj Vendoj",
    transaction: "transakcio",
    youHaveExceededTotalNumberOfProducts:
      "Vi superis la tutan nombron de produktoj permesitaj en via plano. Ĝisdatigu vian planon por ĝui pli altan limon.",
    youNeedToHaveLoyaltyProgram:
      "Vi devas havi Lojalan Programon por uzi ĉi tiun funkcion!",
    price: "Prezo",
    category: "Kategorio",
    stockTracking: "Akcia Spurado",
    stockCount: "Akciokalkulo",
    taxed: "Impostita",
    originalPrice: "Originala Prezo",
    costPrice: "Kostoprezo",
    unit: "Unueco",
    productImage: "Produkta Bildo",
    taxRate: "Imposta Imposto",
    taxType: "Imposta Tipo",
    trackInventory: "Spura Inventaro",
    variants: "Variantoj",
    hasVariants: "Havas Variaĵojn",
    importProduct: "Importi Produkton",
    exportProducts: "Eksportaj Produktoj",
    addNewProduct: "Aldoni Novan Produkton",
    viewCategory: "Vidi Kategorion",
    viewSuppliers: "Vidi Provizantojn",
    receiveInventory: "Ricevu Inventaron",
    printAllProductsTag: "Presi ĉiujn etikedojn pri produktoj",
    deleteAll: "Forigi Ĉion",
    totalProducts: "SUMOJ DE PRODUKTOJ",
    youveSuccessfullyAddedANewCategory: "Vi sukcese aldonis novan kategorion",
    addNewCategory: "Aldoni Novan Kategorion",
    addCategory: "Aldoni Kategorion",
    categoryName: "Kategoria Nomo",
    pleaseEnterCategoryName: "Bonvolu enigi kategorian nomon",
    stampsTarget: "Poŝtmarkoj Celas",
    sendInventory: "Sendu Inventaron",
    productDetails: "Produktaj Detaloj",
    youveSuccessfullyEditedThisCategory:
      "Vi sukcese redaktis ĉi tiun kategorion",
    update: "Ĝisdatigu",
    categoryList: "Kategoria Listo",
    categories: "Kategorioj",
    enterQuantityToUpdate: "Enigu kvanton por ĝisdatigi",
    inventorySentSuccessfully: "La stokregistro estis sendita sukcese!",
    updateInventory: "Ĝisdatigi Inventaron",
    currentQuantity: "Nuna Kvanto",
    pleaseEnterQuantityToAdd: "Bonvolu enigi la kvanton, kiun vi volas aldoni",
    pleaseSelectABranch: "Bonvolu elekti Branĉon",
    searchForBranch: "Serĉu branĉon",
    youCantSendMoreThanStock: "Vi ne povas sendi pli ol vi havas en stoko",
    send: "Sendu",
    pleaseEnterQuantityToSend: "Bonvolu enigi la kvanton, kiun vi volas sendi",
    productNameIsRequiredOnRow: "produkta nomo necesas ĉe vico",
    productCategoryIsRequiredOnRow: "produkta kategorio estas bezonata ĉe vico",
    productPriceIsRequiredOnRow: "produkta prezo estas bezonata en vico",
    productUnitIsRequiredOnRow: "produkta unuo necesas en vico",
    productQuantityIsRequiredOnRow: "produkta kvanto necesas ĉe vico",
    productVariantsRequireTracking: "produktaj variantoj postulas spuron!",
    pleaseAddVariantClickButton:
      "Bonvolu aldoni varianton alklakante la butonon Aldoni!",
    totalVariantsMoreThanSelectedQuantity:
      "Entutaj variantoj estas pli ol elektita produkta kvanto, Pls reduktas varian kvanton",
    productEditedSuccessfully: "Produkto estis redaktita sukcese!",
    fileTooLargeLessThan4Mb:
      "Dosiera grandeco estas tro granda! Dosiera grandeco devas esti malpli ol 4MB.",
    suchVariantAlreadyExist: "Tia varianto jam ekzistas",
    addVariants: "Aldoni Variantojn",
    editProduct: "Redaktu produkton",
    pleaseEnterProductName: "Bonvolu Enmeti Produktan Nomon",
    pleaseEnterProductPrice: "Bonvolu Enmeti Produktan Prezon",
    pleaseEnterProductOriginalPrice: "Bonvolu Enigi Originalan Prezon",
    productDescription: "Priskribo de la produkto",
    selectProductCategory: "Elektu Produktan Kategorion",
    pleaseSelectProductCategory: "Bonvolu elekti produktan kategorion",
    productCostPrice: "Produkta Kosto-Prezo",
    productSellingPrice: "Vendoprezo",
    productOriginalPrice: "Produkto Originala Prezo",
    maxFileSizeAllowedIs4Mb: "Maks. Permesita dosiergrandeco estas 4mb",
    productsWithPicturesArePublished:
      "Produktoj kun bildoj estas publikigitaj en Discover por ricevi mendojn",
    shouldThisProductBeTracked: "Ĉu ĉi tiu produkto devas esti spurita?",
    pleaseSelectStockUnit: "Bonvolu elekti stokan unuon",
    stockUnit: "Akcia Unuo",
    bag: "SAKO",
    bottle: "BOTELO",
    bunch: "FASKO",
    can: "POVAS",
    carton: "KARTONO",
    crate: "KRAZO",
    cup: "POKALO",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAMO",
    litre: "LITRO",
    pack: "PAKO",
    pair: "PARO",
    pieces: "PECOJ",
    plate: "PLATO",
    tonne: "TONNE (MT)",
    uNIT: "UNUO",
    yard: "KORTO",
    pleaseEnterProductQuantity: "Bonvolu enigi produktan kvanton",
    productQuantity: "Produkta Kvanto",
    isThisProductTaxed: "Ĉu ĉi tiu produkto estas impostita?",
    selectTaxType: "Elektu Impostan Tipon",
    pleaseSelectTaxType: "Bonvolu elekti Impostan Tipon",
    progressive: "Progresema",
    proportional: "Proporcia",
    pleaseEnterProductTaxRate: "Bonvolu enigi produktan imposton",
    doesProductHaveVariants: "Ĉu ĉi tiu produkto havas variantojn?",
    type: "Tajpu",
    value: "Valoro",
    pleaseEnterVariantType: "Bonvolu enigi la tipon de varianto",
    pleaseEnterVariantValue: "Bonvolu enigi la valoron de varianto",
    pleaseEnterVariantPrice: "Bonvolu enigi la prezon de varianto",
    pleaseEnterVariantQuantity: "Bonvolu enigi la kvanton de varianto",
    productDeletedSuccessfully: "Produkto estis forigita sukcese!",
    categoryDeletedSuccessfully: "Kategorio estis forigita sukcese!",
    toDeleteThisProduct: "por forigi ĉi tiun Produkton",
    invalidProductQuantity: "Nevalida Produkta Kvanto",
    quantityAddedIsOverStock:
      "La kvanto, kiun vi aldonas, superas tion, kion vi havas en stoko.",
    itemInventoryNotTracked: "Varinventaro ne estas spurita",
    addBulkQuantity: "Aldoni Grandan Kvanton",
    enterBulkQuantity: "Enigu Grandan Kvanton",
    pleaseEnterBulkQuantity: "Bonvolu enigi Grocan Kvanton",
    youveSuccessfullyAddedANewProduct: "Vi sukcese aldonis novan produkton!",
    pleaseEnterProductSellingPrice: "Bonvolu enmeti produktan vendoprezon",
    doYouWantToTrackProductStock: "Ĉu vi volas spuri produktan stokon?",
    sellingPrice: "Vendanta Prezo",
    setProductExpiryReminder: "Fiksita Memoraĵo pri Produkta Eksvalidiĝo",
    productExpiryDate: "Produkta Eksvalida dato",
    startRemindingFrom: "Komencu Memorigi De",
    productSuppliesAddedSuccessfully: "Vi sukcese aldonis produktojn.",
    addProductSupplies: "Aldoni Produktajn Provizojn",
    pleaseSelectSupplier: "Bonvolu Elekti Provizanton",
    nameOfProduct: "Nomo De Produkto",
    pleaseSelectProduct: "Bonvolu Elekti Produkton",
    productVariant: "Produkta Varianto",
    pleaseSelectAVariant: "Bonvolu Elekti Varianton",
    pleaseEnterUnitPrice: "Bonvolu Enigi Unuoprezon",
    businessBranch: "Komerca Branĉo",
    pleaseSelectBranch: "Bonvolu elekti branĉon",
    youveSuccessfullyAddedANewSupplier: "Vi sukcese aldonis novan provizanton",
    addSupplier: "Aldoni Provizanton",
    pleaseEnterSupplierEmail: "Bonvolu eniri retpoŝton de provizanto",
    pleaseAddADescription: "Bonvolu aldoni priskribon",
    anErrorOccuredProductsDeleted:
      "Eraro okazis dum la operacio. Bonvolu noti, ke malmultaj produktoj eble forviŝiĝis en la procezo",
    bulkDelete: "Granda Forigo",
    youAreAboutToDelete: "Vi estas forigonta",
    product: "Produkto",
    isDueToRedeem: "devas elaĉeti",
    aReward: "rekompenco",
    pleaseSelectCustomerToReeemReward:
      "Bonvolu elekti klienton por elaĉeti rekompencon.",
    youHaveNoLoyaltyProgramRunning:
      "Vi ne havas aktivan programon de lojaleco funkcianta",
    customerHhasNoPointsInLoyaltyProgram:
      "Kliento havas neniujn punktojn en ĉi tiu lojala programo",
    proceedWithPayment: "Ĉu daŭrigi kun Pago?",
    youAreAboutToPayForTransactionUsingPoints:
      "Vi estas pagonta por la transakcio per punktoj.",
    customerHas: "Kliento Havas",
    worth: "valoras",
    andIsNotSufficientToPayForTransaction:
      "kaj ne sufiĉas por pagi ĉi tiun transakcion. Ĉu ili volus aldoni la saldon per alia pagmaniero?",
    addCustomerLoyalty: "Aldonu Klientan Fidelecon",
    pleaseAddCustomerFirst: "Bonvolu unue aldoni aŭ elekti klienton.",
    pleaseWaitWhileWeGetReady: "Bonvolu atendi, dum ni pretos",
    lowStock: "Malalta Provizo",
    pleaseEnterAmountTendered: "Bonvolu enmeti la ofertitan sumon",
    areYouSureToBookSaleOffline:
      "Ĉu vi certas, ke vi volas rezervi ĉi tiun vendon eksterrete?",
    saleWouldBeBookedAutomatically:
      "Vendo estos rezervita aŭtomate kiam vi enŝaltos vian interreton",
    offlineSalesBookingCancelled: "Senreta reta rezervo nuligita",
    covid19Message:
      "COVID19: Lavu viajn manojn per sapo aŭ Sanitize por ĉesigi la disvastiĝon. Restu Sekura Ĉiam",
    saleSuccessfullyRecorded: "Vendo Sukcese Registrita!",
    sendReceiptToEmail: "Sendu kvitancon al Retpoŝto",
    sendThankYouSms: "Sendu dankon SMS",
    sendShippingDetails: "Sendu Ekspedajn detalojn",
    addLoyalty: "Aldoni Lojalecon",
    searchCustomerNameOrNumber: "Serĉu klientan nomon aŭ numeron",
    clickTheSearchCustomerBox:
      "Alklaku la Serĉi Klientan Skatolon kaj Skani Karton",
    enterProductPrice: "Enigu Produktan Prezon",
    enterPriceFor: "Enigu Prezon por",
    searchForProduct: "Serĉu produkton",
    all: "Ĉiuj",
    backToDashboard: "Reen al Panelo",
    viewDraftSales: "Rigardu Projektajn Vendojn",
    variantSelected: "varianto elektita",
    variant: "varianto",
    thePreviousVariantSelectionNotAvailable:
      "La antaŭa varianto-elekto ne haveblas por la nova varianto elektita laŭ prezo, bonvolu ŝanĝi vian elekton.",
    pleaseSupplyPositiveQuantityNumber: "Bonvolu doni pozitivan kvanton",
    lowStockFor: "Malalta stoko por",
    clearVariants: "Klara variantoj",
    pleaseEnterQuantity: "Bonvolu enigi Kvanton",
    picture: "Bildo",
    redemptionToken: "Redemption Token",
    token: "Signo",
    totalSpent: "Tuta Elspezita",
    confirmPayment: "Konfirmu Pagon",
    hasPaymentBeenMade: "Ĉu Pago estis procesita sukcese?",
    enterTransactionReference:
      "Enigu la transakcian referencon, per kiu vi pagis",
    pleaseSelectACustomer: "Bonvolu elekti klienton!",
    areYouSureToApplyDiscount: "Ĉu vi certas, ke vi volas apliki rabaton?",
    addYourBankAccountToEnableUssd:
      "Aldonu vian bankan konton por ebligi Tujan USSD-translokigon per uPay",
    totalAmountToPay: "Sumo Pagota",
    doYouWantToCancelTransaction: "Ĉu vi volas nuligi ĉi tiun transakcion?",
    savePrintBill: "Konservu / Presu Fakturon",
    enterAmountCollectedForCustomer: "Enigu kvanton kolektitan por kliento",
    recordSale: "Rekorda Vendo",
    checkOutWith: "Kontrolu kun",
    instantTransfer: "Tuja Translokigo",
    dialTheUSSDCode: "Marku la USSD-kodon",
    pleaseSelectABank: "Bonvolu elekti bankon",
    payWithUSSD: "Pagu Per USSD",
    sendBillTo: " - Sendu Bill al",
    waitingForUSSDTransfer: "Atendante translokigon de USSD",
    percent: "Procento",
    applyDiscount: "Apliki Rabaton",
    thisBillHasBeenSaved: "Ĉi tiu Leĝpropono estis konservita",
    saveDraft: "Konservu Skizon",
    pleaseTypeANameToIdentifyCustomer:
      "Bonvolu tajpi nomon por identigi la klienton",
    paymentDetails: "Pag-detaloj",
    basePrice: "Baza Prezo",
    staff: "Kunlaborantaro",
    subTotal: "Subtotalo",
    durationMonths: "Daŭro (monatoj)",
    selectADuration: "Elektu Daŭron",
    oneMonth: "1 monato",
    twoMonths: "2 Monatoj",
    threeMonths: "3 monatoj",
    sixMonths: "6 Monatoj",
    twelveMonths: "12 Monatoj",
    eighteenMonths: "18 Monatoj",
    twentyFourMonths: "24 Monatoj",
    twoMonthsFree: "(2 Monatoj Senpaga)",
    threeMonthsFree: "(3 Monatoj Senpaga)",
    fiveMonthsFree: "(5 Monatoj Senpaga)",
    yourAccountHasBeen: "Via Konto estis",
    renewed: "renovigita",
    upgraded: "ĝisdatigita",
    successfully: "sukcese",
    yourSubscription: "Via Abono",
    youAreCurrentlyEnrolledOnA: "Vi nuntempe enskribiĝas ĉe",
    pleaseChooseAPaymentOption: "Bonvolu Elekti Pagopcion",
    planRenewal: "Plana Renovigo",
    planUpgrade: "Plani Ĝisdatigon",
    pleaseSelectDurationToPayFor:
      "Bonvolu Elekti Daŭron por kiu vi intencas pagi",
    staffAccounts: "Stabaj Kontoj",
    ecommerce: "Retkomerco",
    pleaseSelectAPlan: "Bonvolu elekti planon",
    includeAddons: "Inkluzivi aldonaĵojn",
    viewTransactions: "Vidi Transakciojn",
    customerHasNoCompletedTansactions:
      "Kliento ankoraŭ ne kompletigis tansakciojn",
    branch: "Branĉo",
    enterNumberOfEcommerceBranches: "Enigu Nombro de Retkomercaj Branĉoj",
    enterNumberOfEcommerceBranchesToPay:
      "Enigu Nombro de Retkomercaj Branĉoj, Pri kiuj Vi Pagas",
    ecommerceIntegration: "Retkomerca Integriĝo",
    enterNumberOfBranches: "Enigu Nombro de Branĉoj",
    enterNumberOfAdditionalBranchesToPay:
      "Enigu Nombro de Pliaj Branĉoj Por Vi Intencitaj Pagi",
    enterNumberOfStaffs: "Enigu Nombro de Staboj",
    enterNumberOfStaffsToPayFor:
      "Enigu Nombro de Staboj Por Vi Intencitaj Pagi",
    discountApplies: "Rabato validas",
    starsOnThe: "steloj sur la",
    offer: "oferto",
    get: "Akiru",
    moreStarsToRedeem: "pli da elaĉetaj steloj",
    taxVat: "Imposto (VAT)",
    callCashierToCompletePayment: "Telefonu Kasiston por Kompletigi Pagon",
    receipt: "Kvitanco",
    dear: "Kara",
    thankYouForYourOrderFindGoods:
      "Dankonpro via mendo. Bonvolu trovi la jenajn varojn provizitajn, kiel konsentite.",
    shippingNote: "Ekspeda Noto",
    enterShippingNote: "Enigu Ekspedan Noton",
    shippingAddress: "Sendadreso",
    enterShippingAddress: "Enigu Ekspedan adreson",
    wellDoneYouAreDueToRedeem: "Bone farita! Vi devas elaĉeti",
    toGetYourRewardNextVisit:
      "por ricevi vian rekompencon dum via sekva vizito. Dankon",
    pointsOnThe: "Punktoj sur la",
    morePointsToRedeem: "pli da elaĉetaj punktoj",
    showCode: "Montru kodon",
    toGetYourRewardOnNextVisit:
      "por ricevi vian rekompencon dum via sekva vizito. Dankon",
    earn: "Gajni",
    delivaryNote: "Transdona Noto",
    draftSales: "Malneta Vendado",
    startDate: "Komenca dato",
    endDate: "Fina dato",
    orders: "Ordoj",
    checkout: "kaso",
    noProductItem: "Neniu Produkta Artikolo",
    selectProductImage: "Elektu Produktan Bildon",
    selectCountry: "Elektu landon",
    selectRegion: "Elektu Ŝtaton / Regionon",
    printProductTag: "Presi Produktan Etikedon",
    transactionReference: "Transakcia referenco",
    Cashier: "Kasisto",
    Manager: "Administranto",
    Owner: "Posedanto",
    Admin: "Admin",
    addPartners: "Aldonu Partnerojn",
    addNewLoyaltyPartner: "Aldonu Novan Lojalecan Partneron",
    pleaseEnterCompanyName: "Bonvolu enigi Kompanion Nomon",
    companyName: "Nomo de Firmo",
    pleaseEnterCompanyRepName: "Bonvolu enigi kompanian reprezentanton Nomon",
    companyRepName: "Nomo de Kompanio Reprezentanto",
    pleaseEnterCompanyRepEmail:
      "Bonvolu enigi retpoŝton de reprezentanto de la kompanio",
    companyRepEmail: "Retpoŝto de firmaa reprezentanto",
    pleaseEnterCompanyRepPhone:
      "Bonvolu enigi telefonnumeron de reprezentanto de la kompanio",
    companyRepPhone: "Telefonnumero de firmaa reprezentanto",
    addReward: "Aldonu rekompencon",
    pleaseEnterRewardName: "Bonvolu enigi rekompencan nomon",
    rewardName: "Rekompenco Nomo",
    rewardQuantity: "Rekompenco Kvanto",
    rewardDescription: "Rekompenco Priskribo",
    rewardType: "Rekompenco Tipo",
    pleaseEnterRewardType: "Bonvolu enigi rekompencan tipon",
    pleaseEnterRewardQuantity: "Bonvolu enigi rekompencan kvanton",
    pleaseEnterRewardDescription: "Bonvolu enigi rekompencan priskribon",
    fineDining: "Bona Manĝado",
    luxuryFashion: "Luksa Modo",
    hotels: "Hoteloj",
    travel: "Vojaĝi",
    foodAndBeverage: "Manĝaĵoj kaj trinkaĵoj",
    fashion: "Modo",
    health: "Sano",
    furniture: "Mebloj",
    entertainment: "Distro",
    automobile: "Aŭtomobilo",
    education: "Edukado",
    beautyAndSpa: "Beleco kaj Banurbo",
    staycation: "Restado",
    events: "Eventoj",
    trips: "Vojaĝoj",
    oilAndGas: "Petrolo kaj Gaso",
    laundry: "Lavejo",
    partnerCategory: "Partnera Kategorio",
    freeItem: "Senpaga Ero",
  },
  Danish: {
    cashier: "kasserer",
    manager: "Manager",
    owner: "ejer",
    online: "online",
    offline: "offline",
    changePassword: "Skift kodeord",
    currentPasswordMessage: "Indtast venligst din nuværende adgangskode",
    passwordMessage: "Indtast venligst dit kodeord",
    currentPassword: "Nuværende kodeord",
    password: "adgangskode",
    confirmPasswordMessage: "Bekræft venligst din adgangskode!",
    confirmPassword: "Bekræft kodeord",
    sendViaEmail: "Send via e -mail",
    sendViaWhatsapp: "Send via WhatsApp",
    downloadPdf: "Download PDF",
    paid: "betalt",
    unpaid: "ulønnet",
    partial: "delvis",
    closeInvoice: "Vil du lukke fakturaen?",
    closeInvoiceConfirmation: "Faktura gemmes muligvis ikke. Vil du lukke?",
    yes: "Ja",
    no: "ingen",
    invoice: "Faktura",
    wasDeducted: "blev fratrukket",
    for: "til",
    item: "Vare",
    addProduct: "Tilføj produkt",
    paymentReference: "Betalings reference",
    amountPaid: "Betalt beløb",
    discountAmount: "Rabatbeløb",
    amountDue: "Forfaldent beløb",
    amount: "Beløb",
    dueDate: "Afleveringsdato",
    paymentType: "Betalings type",
    card: "Kort",
    cash: "Kontanter",
    bankTransfer: "Bankoverførsel",
    paymentMessage: "Betalingsmeddelelse",
    description: "Beskrivelse",
    sendReceipt: "Send kvittering",
    delete: "Slet",
    save: "Gemme",
    resend: "Send igen",
    saveAndSend: "Gemme",
    invoiceSaved: "Faktura gemt!",
    selectPaymentMethod: "Vælg en betalingsmetode!",
    selectCustomer: "Vælg en kunde!",
    cartEmptyError:
      "Indkøbskurvliste kan ikke være tom, luk fakturaen og tilføj varen til kurven!",
    subscriptionExpired:
      "Dit abonnement er udløbet, og din konto er nu begrænset. Klik på knappen herunder for at forny din konto",
    renew: "Forny",
    holdOn: "Hold fast",
    customerBank: "Kundebank",
    cancel: "Afbestille",
    selectACustomer: "Vælg Kunde",
    invoiceSuccessfulPdfError:
      "Faktura oprettet med succes, men PDF Generation tager længere tid end normalt. Tjek venligst tilbage snart.",
    downloadingInvoice: "Henter faktura",
    downloadingReceipt: "Download af kvittering",
    whatsappReceiptError:
      "Der opstod en fejl ved afsendelse af kvitteringen via WhatsApp. Prøv igen.",
    receiptToWhatsapp: "Kvittering videresendt til WhatsApp",
    thankYouForPatronage: "Tak for din protektion",
    hereIsYourReceipt: "Her er din betalingskvittering",
    errorSendingEmailReceipt:
      "Der opstod en fejl ved afsendelse af kvitteringen via e-mail. Prøv igen eller kontakt support",
    receiptSentToEmail: "Kvittering er sendt til kundens e -mail",
    invoiceSentToEmail: "Faktura er sendt til kundens e -mail",
    invoiceSuccessWhatsappError:
      "Faktura oprettet med succes, men der opstod en fejl ved afsendelse til WhatsApp. Prøv igen på fakturalisten",
    invoiceSuccessfulEmailError:
      "Faktura oprettet med succes, men der opstod en fejl under afsendelse som e-mail. Prøv igen fra fakturalisten",
    invoiceSentToWhatsapp: "Faktura videresendt til WhatsApp",
    hello: "Hej",
    pleaseDownloadInvoice: "Download venligst faktura",
    pleaseDownloadReceipt: "Download venligst kvittering",
    from: "fra",
    email: "E -mail",
    upgrade: "Opgrader",
    youAreOnFreePlan: "Du er på en gratis plan.",
    clickOn: "Klik på",
    yourPlanInFewSteps: " dit abonnement i et par hurtige trin.",
    to: "til",
    yourSubscriptionHasExpired:
      "Dit abonnement er udløbet, og din konto er nu begrænset.",
    days: "dage",
    yourSubscriptionExpiresIn: "Dit Loystar -abonnement udløber om",
    createAcount: "Opret en konto",
    signIn: "Log ind",
    continue: "Blive ved",
    enterOtp: "Indtast OTP -pinkode",
    enterValidOtp: "Angiv en gyldig pinkode",
    pin: "PIN",
    tokenSentToMail: "Et token er blevet sendt til din e -mail",
    passwordResetSuccessful: "Nulstilling af adgangskode lykkedes",
    somethingWentWrong: "Noget gik galt!",
    resetPassword: "Nulstille kodeord",
    iHaveResetCode: "Jeg har en kode til nulstilling af adgangskode",
    pleaseEnterEmail: "Indtast venligst din e -mail",
    aTokenWillBeSentToEmail: "Et token ville blive sendt til din e -mail",
    enterEmail: "Indtast din e -mail",
    sendinYourToken: "Sender dit token ...",
    makeSureItMatchesPassword: "Sørg for, at den matcher din nye adgangskode",
    pleaseChooseNewPassword: "Vælg en ny adgangskode",
    chooseNewPassword: "Vælg en ny adgangskode",
    enterNewPassword: "Indtast din nye adgangskode for at bekræfte",
    enterTokenSent: "Indtast det token, der blev sendt din mail",
    resetToken: "Nulstil token",
    resettingPassword: "Nulstiller din adgangskode ...",
    signUp: "Tilmelde",
    adminSignInWithEmail:
      " Administrator logger ind med e -mail, mens personalet logger ind med brugernavn.",
    pleaseEnterEmailOrUsername:
      "Indtast venligst din e -mail eller dit brugernavn",
    emailOrUsername: "E-mail eller brugernavn",
    pleaseEnterPassword: "Indtast venligst adgangskode",
    createAnAccount: "Opret en konto",
    forgotPassword: "Glemt kodeord?",
    enterPhoneNumber: "Indtast telefonnummer",
    personalData: "Personlig data",
    validateConfirmationCOde: "Valider bekræftelseskoden",
    pleaseEnterFirstName: "Indtast venligst dit fornavn",
    pleaseEnterPhoneNumber: "Indtast venligst dit telefonnummer",
    pleaseEnterLastName: "Indtast venligst dit efternavn",
    pleaseEnterBusinessName: "Angiv dit virksomhedsnavn",
    firstName: "Fornavn",
    lastName: "Efternavn",
    businessName: "Virksomhedsnavn",
    previous: "Tidligere",
    next: "Næste",
    pleaseSelectBusinessCategory: "Vælg din virksomhedskategori",
    pleaseEnterValidEmail: "Angiv en gyldig e -mail",
    pleaseEnterPostCode: "Indtast venligst postnummer",
    postCode: "Postnummer",
    phoneNumberInUse: "Dette telefonnummer er allerede i brug!",
    emailInUse: "Denne e -mail er allerede i brug!",
    foodAndDrinks: "Mad og drikke",
    salonAndBeauty: "Salon og skønhed",
    fashionAndAccessories: "Mode og tilbehør",
    gymAndFitness: "Gym og fitness",
    travelAndHotel: "Rejser og hotel",
    homeAndGifts: "Hjem og gaver",
    washingAndCleaning: "Vask og rengøring",
    gadgetsAndElectronics: "Gadgets og elektronik",
    groceries: "Dagligvarer",
    others: "Andre",
    submit: "Indsend",
    accountCreatedSuccessful: "Din konto blev oprettet.",
    pleaseEnterAddress: "Indtast venligst din adresse",
    addressLine1: "Adresse linie 1",
    addressLine2: "Adresselinje 2",
    choosePassword: "Vælg kodeord",
    passwordMustBe6Characters: "Adgangskoden skal indeholde mindst 6 tegn.",
    howDidYouHearLoystar: "Hvordan hørte du om Loystar?",
    referralCode: "Henvisningskode",
    byClickingTheButton: " Ved at klikke på knappen herunder accepterer du",
    termsAndSevice: "Vilkår",
    wereCreatingAccount: "Vi opretter din konto",
    proceed: "Fortsæt",
    verificationCodeMustBe6: "Bekræftelseskoden skal være på 6 cifre",
    pleaseEnter6DigitCode: "Indtast venligst 6 cifret kode",
    enterVerificationCode: "Indsæt verifikations kode",
    resendToken: "Send Token igen",
    verify: "Verificere",
    transactions: "Transaktioner",
    todaySales: "Dagens salg",
    salesHistory: "Salgshistorik",
    supplyHistory: "Forsyningshistorie",
    new: "Ny",
    invoices: "Fakturaer",
    disbursements: "Udbetalinger",
    offlineSales: "Offline salg",
    products: "Produkter",
    customers: "Kunder",
    multiLevelLoyalty: "Loyalitet på flere niveauer",
    loyaltyPrograms: "Loyalitetsprogrammer",
    members: "Medlemmer",
    appStore: "App butik",
    orderMenu: "Bestil menu",
    settings: "Indstillinger",
    staffAndBranches: "Stabe og filialer",
    myAccount: "Min konto",
    switchToSellMode: "Skift til salgstilstand",
    signOut: "Log ud",
    getFreeSubscription: "Få gratis abonnement",
    onlyNumbersAllowed: "Kun tal er tilladt",
    yourAccountMustBe10Digits: "dit kontonummer skal være et 10 -cifret nummer",
    yourBvnMustBe11: "dit BVN skal være et 11 -cifret nummer",
    pleaseSelectBank: "Vælg din bank",
    selectYourBank: "Vælg din bank",
    enterBankAccountNumber: "Indtast bankkontonummer",
    enterBvnNumber: "Indtast dit BVN",
    connectBankAccount: "Tilslut bankkonto",
    passwordResetSuccessfulAndSignOut:
      "Din adgangskode er blevet nulstillet. Klik på knappen Log ud herunder for at logge ind igen",
    enterCurrentPassword: "Indsæt nuværende kodeord",
    pleaseEnterCurrentPassword: "Indtast den aktuelle adgangskode",
    phoneNumber: "Telefonnummer",
    sex: "Køn",
    dateOfBirth: "Fødselsdato",
    state: "Stat",
    country: "Land",
    loyaltyId: "Loyalitets -ID",
    createdAt: "Oprettet kl",
    noOfTransactionsMade: "Antal foretagne transaktioner",
    yourDownloadWillStart: "Din download starter om et øjeblik",
    exportCustomers: "Eksporter kunder",
    youhaveSuccessfullyToppedUp: "Du har fyldt dine SMS -enheder med succes.",
    youNowHave: "Det har du nu",
    smsUnits: "SMS -enheder",
    enterNumberOfSmsUnits:
      "Indtast antallet af SMS -enheder, du har til hensigt at købe",
    pleaseEnterANumericValue: "Angiv en numerisk værdi",
    pay: "Betale",
    accountEditedSuccessfully: "Din konto blev redigeret.",
    youAeCurrentlyOn: "Du er i gang i øjeblikket",
    plan: "Plan",
    userData: "Brugerdata",
    businessData: "FORRETNINGSDATA",
    businessCategory: "Forretningskategori",
    pleaseSelectCurrency: "Vælg din valuta",
    selectYourCurrency: "Vælg din valuta",
    purchaseMoreSmsUnits:
      "Køb flere SMS -enheder ved hjælp af nedenstående formular",
    youHave: "Du har",
    atLeast4SmsUnitsRrequired:
      "Der kræves mindst 4 sms -enheder til verifikation.",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Bekræft din bankkonto for at overholde kravene til 'Kend din kunde' (KYC). Dette giver dig mulighed for at indsamle betalinger via USSD eller øjeblikkelig overførsel, modtage ordrer fra kunder og behandle transaktionsafregning. Gælder kun for nigerianske købmænd. Tryk venligst på knappen herunder for at starte.",
    reConnectBankAccount: "Tilslut bankkonto igen",
    accountName: "Kontonavn",
    accountNumber: "Kontonummer",
    bankName: "Bank-navn",
    verified: "Verificeret",
    accountDetails: "konto detaljer",
    kycBankAccount: "KYC",
    createTier: "Opret niveau",
    fileUploadSuccessful: "fil uploadet med succes",
    fileUploadFailed: "filoverførsel mislykkedes",
    createLoyaltyProgram: "Opret et loyalitetsprogram",
    createLoyalty: "Skab loyalitet",
    name: "Navn",
    loyaltyArtwork: "Loyalitetskunst",
    clickToUpload: "Klik for at uploade",
    amountToPointsRatio: "Beløb til pointforhold",
    points: "Point",
    recommendedAmountToPointRatio:
      "Anbefalet: ₦ 1 til 1 point. dvs. for hver 1 Naira brugt, får dine kunder 1 point",
    pleaseTypeIn: "Indtast venligst",
    toDeleteLoyalty: "at slette denne loyalitet",
    deleteLoyalty: "Slet loyalitet",
    toConfirm: "at bekræfte",
    edit: "Redigere",
    pointsAwardedSuccessfully: "Point tildelt med succes.",
    enterPointValueToAward: "Indtast pointværdi, du gerne vil tildele kunder",
    award: "Pris",
    awardPointValuesToCustomer: "Tildel pointværdi til kunden",
    enrollMembersToLoyalty: "Tilmeld medlemmer til loyalitet",
    awardPoints: "Tildel point",
    enroll: "Indskrive",
    home: "Hjem",
    loyalty: "Loyalitet",
    enrollCustomers: "Tilmeld kunder",
    selected: "Valgte",
    customer: "Kunde",
    loyaltyActivationSuccessful: "Loyalitetsaktivering er vellykket.",
    loyaltyDeactivationSuccessful: "Loyalitetsdeaktivering er vellykket.",
    viewTier: "Se niveau",
    deactivate: "Afaktiver",
    activate: "Aktiver",
    actions: "Handlinger",
    nameOfLoyalty: "Loyalitetens navn",
    loyaltyStatus: "Loyalitetsstatus Loyalitetsstatus",
    numberOfTiers: "Antal tier",
    createdOn: "Oprettet den",
    createATier: "Opret et niveau",
    stopCreatingTierConfirmation: "Vil du stoppe med at oprette et niveau?",
    stopEditingTierConfirmation: "Vil du stoppe med at redigere dette niveau?",
    nameOfTier: "Navn på tier",
    minimumSpendingTarget: "Mindste forbrugsmål",
    maximumSpendingTarget: "Maksimalt forbrugsmål",
    minimumSpendingTargetRequired: "minimumsudgiftsmål kræves",
    maximumSpendingTargetRequired: "maksimale forbrugsmål kræves",
    rewardSponsor: "Belønningssponsor",
    pleaseChooseARewardSponsor: "Vælg en belønningssponsor",
    self: "Selv",
    partner: "Partner",
    rewardPartner: "Belønningspartner",
    pleaseSelectRewardPartner: "Vælg din belønningspartner",
    rewards: "Belønninger",
    pleaseSelectAReward: "Vælg en belønning",
    instructionsOnRedeemingReward:
      "Instruktioner om, hvordan kunden skal indløse belønning",
    pleaseFillInThisField: "Udfyld venligst dette felt!",
    toDeleteThisTier: " at slette dette niveau",
    deleteTier: "Slet Tier",
    viewMembers: "Se medlemmer",
    membersEnrolled: "Medlemmer tilmeldt",
    instruction: "Instruktion",
    membersIn: "Medlemmer i",
    availableTiersInLoyalty: "Tilgængelige niveauer i loyalitetsprogrammet",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Tilgængeligt loyalitetsprogram",
    totalLoyalties: "I ALT LOYALTIES",
    memberDetails: "Medlemsoplysninger",
    nameOfCustomer: "Kundens navn",
    address: "Adresse",
    allEnrolledMembers: "Alle tilmeldte medlemmer",
    thisIsToWishYouHappyBirthday:
      "Dette er for at ønske dig en rigtig god fødselsdag og velstående liv. Tak for alt det du er til Loyster. Glade festligheder!",
    inputAnOfferPlease: "Indtast et tilbud venligst",
    pleaseSelectTheInsertPoint:
      "Vælg indsættelsespunktet i meddelelsen, og klik igen",
    birthdayOfferAndMessage: "Fødselsdagstilbud og besked",
    birthdayOffer: "Fødselsdagstilbud",
    birthdayMessage: "Fødselsdag besked",
    noOfferFound: "Ingen tilbud fundet",
    settingABirthdayOffer:
      "Indstilling af et fødselsdagstilbud giver kunderne mulighed for at modtage dette tilbud via SMS på deres fødselsdag",
    createOffer: "Opret tilbud",
    whatIsTheOffer: "Hvad er tilbuddet?",
    editMessage: "Rediger besked",
    insert: "Indsæt",
    theNameOfCustomerInserted: "Kundens navn indsættes her",
    theBirtdayOfferInserted: "Fødselsdagstilbuddet indsættes her",
    youSuccessfullyAddedNewBranch: "Du har tilføjet en ny filial!",
    addNewBranch: "Tilføj ny gren",
    addBranch: "Tilføj gren",
    additionalBranchWillIncur: "Yderligere afdeling ville pådrage sig",
    perBranch: "pr. gren",
    ecommerceBranchCosts: "E -handel Filialomkostninger",
    pleaseEnterBranchName: "Angiv filialnavnet",
    pleaseEnterBranchAddress1: "Angiv filialens adresselinje 1",
    enterBranchAddress1: "Indtast filialens adresselinje 1",
    enterBranchAddress2: "Indtast filialens adresselinje 1",
    pleaseEnterBranchAddress2: "Angiv filialens adresselinje 2",
    enterBranchName: "Indtast filialnavnet",
    successfullyAddedStaff: "Du har tilføjet et nyt personale!",
    addNewStaff: "Tilføj nyt personale",
    addStaff: "Tilføj personale",
    additionalStaffWillIncur: "Yderligere personale ville pådrage sig",
    perStaff: "pr. personale.",
    pleaseEnterStaffEmail: "Indtast venligst personalets e -mail",
    pleaseEnterStaffUsername: "Indtast venligst personalets brugernavn",
    pleaseEnterStaffPassword: "Indtast venligst personalets adgangskode",
    pleaseSelectStaffRole: "Vælg personalets rolle",
    selectStaffRole: "Vælg personalets rolle",
    enterStaffEmail: "Indtast personalets e -mail",
    enterStaffUsername: "Indtast personalets brugernavn",
    enterStaffPassword: "Indtast personalets adgangskode",
    spacesNotAllowedInUsername: "mellemrum ikke tilladt i brugernavn",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Vælg virksomhed for at tilknytte personale",
    searchForBranchToAttachStaff:
      "Søg efter afdeling for at tilknytte personale",
    username: "Brugernavn",
    role: "Rolle",
    areYouSureToDeleteThis: "Er du sikker på at slette dette",
    branches: "Grene",
    upgradeYourPlan: "Opgrader din plan.",
    add: "TILFØJE",
    addNew: "Tilføj ny",
    customerWithEmailAlreadyExists:
      "Kunde med e -mail/telefonnummer findes allerede!",
    successfullyAddedNewCustomer: "Du har tilføjet en ny kunde!",
    addCustomer: "Tilføj kunde",
    pleaseEnterCustomerFirstName: "Indtast venligst kundens fornavn",
    pleaseEnterCustomerLastName: "Indtast venligst kundens efternavn",
    pleaseEnterCustomerPhoneNumber: "Indtast venligst kundens telefonnummer",
    pleaseEnterCustomerEmail: "Indtast venligst kundens e -mail",
    pleaseEnterCustomerAddressLine: "Indtast venligst kundens adresselinje",
    pleaseEnterCustomerOtherAddress: "Indtast venligst kundens anden adresse",
    pleaseSelectCustomerGender: "Vælg kundens køn",
    gender: "Køn",
    male: "Han",
    female: "Kvinde",
    bank: "Bank",
    selectBank: "Vælg Bank",
    stateOrRegionOrProvince: "Stat/region/provins",
    customerNotes: "Kundenotater",
    sendSms: "Send sms",
    editCustomer: "Rediger kunde",
    redeemReward: "Indløs belønning",
    issueLoyaltyCard: "Udsted loyalitetskort",
    deleteCustomer: "Slet kunde",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Du har med succes tildelt et loyalitetsmedlemskabs -id",
    noMembershipIdAvailable:
      "Der findes ingen medlems -id'er. Kontakt os venligst på hello@loystar.co",
    sendEmail: "Send e-mail",
    membershipPoints: "Medlemskabspunkter",
    customerDetails: "Kundeoplysninger",
    close: "Tæt",
    loyaltyBalance: "Loyalitetsbalance",
    pointsBalance: "Points balance",
    starBalance: "Star Balance",
    requiredPoints: "Påkrævede point",
    requiredStars: "Påkrævede stjerner",
    reddemCode: "Indløs kode",
    toDeleteThisCustomer: "at slette denne kunde",
    customerHasBeenDeletedSuccessfully: "Kunden er blevet slettet!",
    customerWithPhoneAlreadyExists: "Kunde med telefonnummer findes allerede",
    customerWasSuccessfullyEdited: "Kunden blev redigeret",
    anErrorOccured: "En fejl opstod",
    phoneNumberIncludeCountry: "Telefonnummer (Inkluder landekode)",
    yourFileQueuedForUpload:
      "Din fil har stået i kø til upload. Opdater siden efter et par sekunder.",
    thereWasAnErrorPerformingOperation:
      "Der opstod en fejl under udførelsen af handlingen!",
    pleaseSelectFile: "Vælg en fil!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Kun CSV -filer er tilladt. Upload en .csv -fil.",
    fileShouldBeSmallerThan5Mb:
      "Filen skal være mindre end 5 MB. Hvis du har en større fil, kan du sende en e -mail til support@loystar.co. tak skal du have",
    customerFirstNameIsRequired: "kundens fornavn er påkrævet på række",
    customerPhoneNumberIsRequired: "kundens telefonnummer er påkrævet på række",
    importCustomers: "Importer kunder",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Klik på dette ikon for at downloade CSV -filskabelonen.",
    getGoogleSheetVersion: "Hent Google -arkversionen",
    clickOrDragFileToUpload:
      "Klik eller træk filen til dette område for at uploade",
    missingOrInvalidColumnHeader:
      "Manglende eller ugyldig kolonneoverskrift. Følg skabelonformatet. Tak skal du have.",
    youHaveImported: "Du har importeret",
    youSuccessfullyRedeemedReward: "Du har indløst din belønning!",
    sixDigitCode: "Sekscifret kode",
    pleaseEnterCustomerRewardCode: "Indtast venligst kundens belønningskode",
    enterRewardCode:
      "Indtast belønningskode. (Belønningskoden er forskellig fra store og små bogstaver)",
    selectLoyaltyProgram: "Vælg loyalitetsprogram",
    stamps: "Frimærker",
    smsUnitsLow: "SMS -enheder lav",
    pleaseTopUpSmsUnits: "Opfyld venligst SMS -enheder",
    smsSuccessfullySentForDelivery: "SMS med succes sendt til levering!",
    sendSmsTo: "Send sms til",
    allCustomers: "Alle kunder",
    unitsAvailable: "Tilgængelige enheder",
    pleaseTypeInTheMessage: "Skriv venligst beskeden",
    message: "Besked",
    charactersRemaining: "tegn tilbage",
    avoidUseOfSpecialCharacters:
      "Undgå brug af specialtegn og emojis for at bevare SMS -enheder.",
    note: "Bemærk",
    errorFetchingCustomersMultilevelDetail:
      "Fejl ved hentning af kunders multi -level detaljer",
    search: "Søg",
    reset: "Nulstil",
    importCustomer: "Importer kunde",
    addNewCustomer: "Tilføj ny kunde",
    sendSmsBroadcast: "Send SMS -udsendelse",
    totalCustomers: "I ALT KUNDER",
    disbursementDetails: "Udbetalingsoplysninger",
    paidBy: "Betalt af",
    disbursed: "Udbetalt",
    bankAccountName: "Bankkontonavn",
    bankAccountNumber: "Bankkontonummer",
    transferInitiated: "Overførsel påbegyndt",
    transferCompleted: "Overførsel gennemført",
    pleaseEnterAValid: "Angiv en gyldig",
    begin: "begynde",
    end: "ende",
    date: "dato",
    paymentDate: "Betalingsdato",
    selectDisbursementDuration: "Vælg Udbetalingstid",
    totalSettled: "I alt afregnet",
    totalUnsettled: "I alt uopgjort",
    toDeleteThisSale: "at slette dette salg",
    draftSaleDeletedSuccessfully: "Udkast til salg er blevet slettet!",
    deleteSale: "Slet salg",
    pleaseTypeInYourTotal: "Indtast dit total for at bekræfte sletningen",
    billDetails: "Regningsoplysninger",
    printBill: "Udskriv regning",
    servedBy: "Serveret af",
    total: "i alt",
    createdDate: "Oprettet dato",
    createdTime: "Oprettet tid",
    status: "Status",
    loginSuccessful: "Login vellykket",
    pleaseWaitWhileWeConnectAccount:
      "Vent venligst, mens vi forbinder din konto",
    connectionFailedTryAgain: "Forbindelsen mislykkedes. Prøv igen.",
    connectionSuccessful: "Forbindelse lykkedes",
    viewDetails: "Se detaljer",
    enable: "Aktiver",
    free: "Gratis",
    cost: "Koste",
    visitWebsite: "Besøg webstedet",
    pleaseUpgradeYourPlanToPro:
      "Opgrader din plan til Pro Plus for at aktivere denne app",
    connectYourBankAccount:
      "Tilslut din bankkonto for at kunne modtage betalinger.",
    disable: "Deaktiver",
    enableApp: "Aktiver app",
    addNewProductToInvoice: "Føj nyt produkt til faktura",
    toDeleteThisInvoice: "at slette denne faktura",
    invoiceDeletedSuccessfully: "Faktura er blevet slettet!",
    deleteInvoice: "Slet faktura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Du har tilføjet en faktura -betalingsmeddelelse!",
    pleaseEnterPaymentInstructions: "Angiv venligst betalingsinstruktioner",
    invoiceId: "Faktura -id",
    paidDate: "Betalt dato",
    reference: "Reference",
    productAdded: "Produkt tilføjet",
    productOutOfStock: "Produkt udsolgt. Lager venligst igen.",
    totalInvoices: "I ALT FAKTURER",
    totalPaidInvoices: "I ALT BETALTE FAKTURER",
    totalUnpaidInvoices: "I ALT UBETALEDE FAKTURER",
    loyaltyProgramDeleted: "Lojalitetsprogram slettet",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dette loyalitetsprogram er blevet slettet",
    thisLoyaltyProgramEditedSuccessfully:
      "Dette loyalitetsprogram er blevet redigeret med succes",
    loyaltyProgramAddedSuccessfully:
      "Loyalitetsprogrammet er tilføjet med succes",
    loyaltyProgramEdited: "Loyalitetsprogram redigeret",
    loyaltyProgramAdded: "Loyalitetsprogram tilføjet",
    loyaltyDetails: "Loyalitetsoplysninger",
    doYouWantToCloseDialog: "Vil du lukke denne dialog?",
    pleaseEnterLoyaltyName: "Indtast venligst navnet på din loyalitet",
    programType: "Programtype",
    pleaseSelectProgramType: "Vælg en programtype",
    simplePoints: "Enkle point",
    stampsProgram: "Frimærke program",
    threshold: "Grænseværdi",
    pleaseEnterLoyaltyThreshold: "Angiv loyalitetsgrænsen",
    reward: "Belønning",
    pleaseEnterLoyaltyReward: "Indtast venligst loyalitetsbelønningen",
    totalUserPoints: "I alt brugerpoint",
    totalUserStamps: "Brugerstempler i alt",
    spendingTarget: "Forbrugsmål",
    spendingTargetHint1:
      "Forbrugsmål er, hvor meget en kunde skal bruge for at opnå belønningen. 1 valutaværdi = 1 point.",
    spendingTargetHint2:
      "Frimærker mål er, hvor mange frimærker en kunde skal samle for at tjene belønningen. f.eks. 5",
    addNewLoyaltyProgram: "Tilføj nyt loyalitetsprogram",
    addLoyaltyProgram: "Tilføj loyalitetsprogram",
    loyaltyProgramType: "Loyalitetsprogramtype",
    pleaseSelectLoyaltyProgramType: "Vælg loyalitetsprogramtype",
    nameOfProgram: "Navn på program",
    pleaseEnterProgramName: "Indtast venligst programnavn",
    whatIsTheReward: "Hvad er belønningen?",
    egNextPurchaseIsFree: "F.eks. Næste køb er gratis",
    customerName: "Kundenavn",
    guestCustomer: "Gæstekunde",
    orderConfirmedSuccessfully: "Ordren blev bekræftet",
    orderCancelledSuccessfully: "Ordren blev annulleret",
    confirmOrder: "Bekræft ordre",
    cancelOrder: "Aflys ordre",
    allOrders: "Alle ordrer",
    totalOrders: "I ALLE ORDRE",
    doYouWantToAcceptOrder: "Vil du acceptere denne ordre?",
    doYouWantToCancelOrder: "Vil du annullere denne ordre?",
    orderDetails: "Ordre detaljer",
    orderCreatedAt: "Ordre oprettet kl",
    supplier: "Leverandør",
    productName: "produktnavn",
    quantity: "Antal",
    unitPrice: "Pris per stk",
    receivedBy: "Modtaget af",
    reportFrom: "Rapport fra",
    totalSupplies: "Leverancer i alt",
    allRightsReserved: "Alle rettigheder forbeholdes",
    toDeleteThisTransaction: "at slette denne transaktion",
    transactionDeletedSuccessfully:
      "Transaktionen er blevet slettet. Lageret er returneret til lageret.",
    deleteTransaction: "Slet transaktion",
    transactionDetails: "overførselsdetaljer",
    printReceipt: "Udskriv kvittering",
    channel: "Kanal",
    discount: "Rabat",
    profit: "Profit",
    discountedSales: "Nedsat salg",
    errorFetchingRecord: "Fejl ved hentning af post",
    exportTransactions: "Eksporter transaktioner",
    errorFetchingSalesRecord: "Fejl under hentning af salgsrekord til eksport.",
    totalSellingPrice: "Samlet salgspris",
    totalCostPrice: "Samlet kostpris",
    appliedDiscount: "Anvendt rabat",
    noOfItems: "Antal varer",
    sales: "Salg",
    export: "Eksport",
    totalProfit: "Samlet overskud",
    totalBalanceInPeriod: "Samlet saldo i periode",
    allTimeSales: "Hele tiden salg",
    records: "Optegnelser",
    todaysSales: "Dagens salg",
    transaction: "transaktion",
    youHaveExceededTotalNumberOfProducts:
      "Du har overskredet det samlede antal produkter, der er tilladt på din plan. Opgrader din plan for at nyde en højere grænse.",
    youNeedToHaveLoyaltyProgram:
      "Du skal have et loyalitetsprogram for at bruge denne funktion!",
    price: "Pris",
    category: "Kategori",
    stockTracking: "Lagersporing",
    stockCount: "Lagerantal",
    taxed: "Beskattet",
    originalPrice: "Original pris",
    costPrice: "Omkostning",
    unit: "Enhed",
    productImage: "Produktbillede",
    taxRate: "Skatteprocent",
    taxType: "Skattetype",
    trackInventory: "Spor beholdning",
    variants: "Varianter",
    hasVariants: "Har varianter",
    importProduct: "Importér produkt",
    exportProducts: "Eksporter produkter",
    addNewProduct: "Tilføj nyt produkt",
    viewCategory: "Se kategori",
    viewSuppliers: "Se leverandører",
    receiveInventory: "Modtag beholdning",
    printAllProductsTag: "Udskriv alle produkter",
    deleteAll: "Slet alt",
    totalProducts: "I ALT PRODUKTER",
    youveSuccessfullyAddedANewCategory: "Du har tilføjet en ny kategori",
    addNewCategory: "Tilføj ny kategori",
    addCategory: "Tilføj kategori",
    categoryName: "Kategorienavn",
    pleaseEnterCategoryName: "Indtast venligst kategorienavn",
    stampsTarget: "Frimærker Mål",
    sendInventory: "Send beholdning",
    productDetails: "Produktdetaljer",
    youveSuccessfullyEditedThisCategory: "Du har redigeret denne kategori",
    update: "Opdatering",
    categoryList: "Kategoriliste",
    categories: "Kategorier",
    enterQuantityToUpdate: "Indtast en mængde, der skal opdateres",
    inventorySentSuccessfully: "Beholdningen blev sendt med succes!",
    updateInventory: "Opdater beholdning",
    currentQuantity: "Nuværende mængde",
    pleaseEnterQuantityToAdd: "Angiv den mængde, du vil tilføje",
    pleaseSelectABranch: "Vælg en filial",
    searchForBranch: "Søg efter filial",
    youCantSendMoreThanStock: "Du kan ikke sende mere, end du har på lager",
    send: "Sende",
    pleaseEnterQuantityToSend: "Angiv den mængde, du vil sende",
    productNameIsRequiredOnRow: "produktnavn er påkrævet på række",
    productCategoryIsRequiredOnRow: "produktkategori er påkrævet på række",
    productPriceIsRequiredOnRow: "produktpris kræves på række",
    productUnitIsRequiredOnRow: "produktenhed er påkrævet på række",
    productQuantityIsRequiredOnRow: "produktmængde kræves på række",
    productVariantsRequireTracking: "produktvarianter kræver sporing!",
    pleaseAddVariantClickButton:
      "Tilføj en variant ved at klikke på knappen Tilføj!",
    totalVariantsMoreThanSelectedQuantity:
      "Varianter i alt er mere end valgt produktmængde, Pls reducerer variantmængde",
    productEditedSuccessfully: "Produktet er redigeret med succes!",
    fileTooLargeLessThan4Mb:
      "Filstørrelsen er for stor! Filstørrelsen skal være mindre end 4 MB.",
    suchVariantAlreadyExist: "En sådan variant findes allerede",
    addVariants: "Tilføj varianter",
    editProduct: "Rediger produkt",
    pleaseEnterProductName: "Indtast venligst produktnavn",
    pleaseEnterProductPrice: "Angiv venligst produktpris",
    pleaseEnterProductOriginalPrice:
      "Indtast venligst produktets oprindelige pris",
    productDescription: "Produkt beskrivelse",
    selectProductCategory: "Vælg produktkategori",
    pleaseSelectProductCategory: "Vælg venligst produktkategori",
    productCostPrice: "Produktpris Pris",
    productSellingPrice: "Produkt salgspris",
    productOriginalPrice: "Produktets oprindelige pris",
    maxFileSizeAllowedIs4Mb: "Maks. Tilladt filstørrelse er 4 MB",
    productsWithPicturesArePublished:
      "Produkter med billeder offentliggøres på Discover for at modtage ordrer",
    shouldThisProductBeTracked: "Skal dette produkt spores?",
    pleaseSelectStockUnit: "Vælg lagerenhed",
    stockUnit: "Lager Enhed",
    bag: "TASKE",
    bottle: "FLASKE",
    bunch: "BUNCH",
    can: "KAN",
    carton: "KARTON",
    crate: "KASTE",
    cup: "KOP",
    dozen: "DUSIN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKE",
    pair: "PAR",
    pieces: "STYKKER",
    plate: "PLADE",
    tonne: "TONNE (MT)",
    uNIT: "ENHED",
    yard: "GÅRD",
    pleaseEnterProductQuantity: "Indtast venligst produktmængde",
    productQuantity: "Produktmængde",
    isThisProductTaxed: "Er dette produkt beskattet?",
    selectTaxType: "Vælg skattetype",
    pleaseSelectTaxType: "Vælg venligst Skattetype",
    progressive: "Progressiv",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Indtast venligst produktskatteprocent",
    doesProductHaveVariants: "Har dette produkt varianter?",
    type: "Type",
    value: "Værdi",
    pleaseEnterVariantType: "Indtast venligst variantens type",
    pleaseEnterVariantValue: "Angiv variantens værdi",
    pleaseEnterVariantPrice: "Indtast venligst variantens pris",
    pleaseEnterVariantQuantity: "Indtast venligst variantens mængde",
    productDeletedSuccessfully: "Produktet er blevet slettet!",
    categoryDeletedSuccessfully: "Kategorien er blevet slettet!",
    toDeleteThisProduct: "at slette dette produkt",
    invalidProductQuantity: "Ugyldig produktmængde",
    quantityAddedIsOverStock:
      "Mængden, du tilføjer, er større end det, du har på lager.",
    itemInventoryNotTracked: "Varebeholdning spores ikke",
    addBulkQuantity: "Tilføj mængde",
    enterBulkQuantity: "Indtast bulk mængde",
    pleaseEnterBulkQuantity: "Angiv Bulk Antal",
    youveSuccessfullyAddedANewProduct: "Du har tilføjet et nyt produkt!",
    pleaseEnterProductSellingPrice: "Angiv produktets salgspris",
    doYouWantToTrackProductStock: "Vil du spore produktlager?",
    sellingPrice: "Salgspris",
    setProductExpiryReminder: "Indstil påmindelse om produktudløb",
    productExpiryDate: "Produktets udløbsdato",
    startRemindingFrom: "Start med at minde om fra",
    productSuppliesAddedSuccessfully: "Du har tilføjet produktforsyninger.",
    addProductSupplies: "Tilføj produktforsyninger",
    pleaseSelectSupplier: "Vælg leverandør",
    nameOfProduct: "Produktets navn",
    pleaseSelectProduct: "Vælg produkt",
    productVariant: "Produktvariant",
    pleaseSelectAVariant: "Vælg venligst en variant",
    pleaseEnterUnitPrice: "Indtast venligst enhedspris",
    businessBranch: "Forretningsfilial",
    pleaseSelectBranch: "Vælg filial",
    youveSuccessfullyAddedANewSupplier: "Du har tilføjet en ny leverandør",
    addSupplier: "Tilføj leverandør",
    pleaseEnterSupplierEmail: "Angiv leverandørens e -mail",
    pleaseAddADescription: "Tilføj venligst en beskrivelse",
    anErrorOccuredProductsDeleted:
      "Der opstod en fejl under udførelsen af handlingen. Vær opmærksom på, at få produkter muligvis er blevet slettet under processen",
    bulkDelete: "Slet masse",
    youAreAboutToDelete: "Du er ved at slette",
    product: "Produkt",
    isDueToRedeem: "skyldes at indløse",
    aReward: "en belønning",
    pleaseSelectCustomerToReeemReward:
      "Vælg en kunde for at indløse belønningen.",
    youHaveNoLoyaltyProgramRunning:
      "Du har ikke noget aktivt loyalitetsprogram kørende",
    customerHhasNoPointsInLoyaltyProgram:
      "Kunden har ingen point i dette loyalitetsprogram",
    proceedWithPayment: "Vil du fortsætte med betalingen?",
    youAreAboutToPayForTransactionUsingPoints:
      "Du er ved at betale for transaktionen ved hjælp af point.",
    customerHas: "Kunden har",
    worth: "værdi",
    andIsNotSufficientToPayForTransaction:
      "og er ikke tilstrækkelig til at betale for denne transaktion. Vil de tilføje saldoen ved hjælp af en anden betalingsmetode?",
    addCustomerLoyalty: "Tilføj kundeloyalitet",
    pleaseAddCustomerFirst: "Tilføj eller vælg en kunde først.",
    pleaseWaitWhileWeGetReady: "Vent venligst, mens vi gør os klar",
    lowStock: "Lavt lager",
    pleaseEnterAmountTendered: "Angiv det tilbudte beløb",
    areYouSureToBookSaleOffline:
      "Er du sikker på, at du vil booke dette salg offline?",
    saleWouldBeBookedAutomatically:
      "Salget bookes automatisk, når du tænder dit internet",
    offlineSalesBookingCancelled: "Offline salgsbestilling annulleret",
    covid19Message:
      "COVID19: Vask dine hænder med sæbe eller Sanitize for at stoppe spredningen. Vær altid sikker",
    saleSuccessfullyRecorded: "Salget er optaget!",
    sendReceiptToEmail: "Send kvittering til e -mail",
    sendThankYouSms: "Send tak SMS",
    sendShippingDetails: "Send forsendelsesoplysninger",
    addLoyalty: "Tilføj loyalitet",
    searchCustomerNameOrNumber: "Søg efter kundens navn eller nummer",
    clickTheSearchCustomerBox: "Klik på Søg kundeboks og Scan kort",
    enterProductPrice: "Indtast produktpris",
    enterPriceFor: "Indtast pris for",
    searchForProduct: "Søg efter produkt",
    all: "Alle",
    backToDashboard: "Tilbage til Dashboard",
    viewDraftSales: "Se udkast til salg",
    variantSelected: "variant valgt",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Det forrige variantvalg er ikke tilgængeligt for den nye variant, der er valgt baseret på pris, skal du ændre dit valg.",
    pleaseSupplyPositiveQuantityNumber: "Angiv et postivt mængdenummer",
    lowStockFor: "Lavt lager til",
    clearVariants: "Tydelige varianter",
    pleaseEnterQuantity: "Angiv antal",
    picture: "Billede",
    redemptionToken: "Indløsningstoken",
    token: "Polet",
    totalSpent: "Samlet brugt",
    confirmPayment: "Bekræft betaling",
    hasPaymentBeenMade: "Er betalingen blevet behandlet med succes?",
    enterTransactionReference:
      "Indtast den transaktionsreference, du betalte med",
    pleaseSelectACustomer: "Vælg en kunde!",
    areYouSureToApplyDiscount: "Er du sikker på, at du vil anvende rabat?",
    addYourBankAccountToEnableUssd:
      "Tilføj din bankkonto for at aktivere øjeblikkelig USSD -overførsel med uPay",
    totalAmountToPay: "Samlet beløb, der skal betales",
    doYouWantToCancelTransaction: "Vil du annullere denne transaktion?",
    savePrintBill: "Gem/udskriv regning",
    enterAmountCollectedForCustomer: "Indtast det indsamlede beløb for kunden",
    recordSale: "Pladesalg",
    checkOutWith: "Tjek med",
    instantTransfer: "Øjeblikkelig overførsel",
    dialTheUSSDCode: "Indtast USSD -koden",
    pleaseSelectABank: "Vælg en bank",
    payWithUSSD: "Betal med USSD",
    sendBillTo: " - Send regningen til",
    waitingForUSSDTransfer: "Venter på USSD -overførsel",
    percent: "Procent",
    applyDiscount: "Anvend rabat",
    thisBillHasBeenSaved: "Dette lovforslag er gemt",
    saveDraft: "Gem udkast",
    pleaseTypeANameToIdentifyCustomer:
      "Indtast venligst et navn for at identificere kunden",
    paymentDetails: "Betalingsoplysninger",
    basePrice: "Grundpris",
    staff: "Personale",
    subTotal: "Delsum",
    durationMonths: "Varighed (måneder)",
    selectADuration: "Vælg en varighed",
    oneMonth: "1 måned",
    twoMonths: "2 måneder",
    threeMonths: "3 måneder",
    sixMonths: "6 måneder",
    twelveMonths: "12 måneder",
    eighteenMonths: "18 måneder",
    twentyFourMonths: "24 måneder",
    twoMonthsFree: "(2 måneder gratis)",
    threeMonthsFree: "(3 måneder gratis)",
    fiveMonthsFree: "(5 måneder gratis)",
    yourAccountHasBeen: "Din konto har været",
    renewed: "fornyet",
    upgraded: "opgraderet",
    successfully: "succesfuldt",
    yourSubscription: "Dit abonnement",
    youAreCurrentlyEnrolledOnA: "Du er i øjeblikket tilmeldt på en",
    pleaseChooseAPaymentOption: "Vælg en betalingsmulighed",
    planRenewal: "Fornyelse af planen",
    planUpgrade: "Planlæg opgradering",
    pleaseSelectDurationToPayFor: "Vælg varighed, du agter at betale for",
    staffAccounts: "Personalekonti",
    ecommerce: "E -handel",
    pleaseSelectAPlan: "Vælg en plan",
    includeAddons: "Inkluder tilføjelser",
    viewTransactions: "Se transaktioner",
    customerHasNoCompletedTansactions:
      "Kunden har endnu ikke gennemført transaktioner",
    branch: "Afdeling",
    enterNumberOfEcommerceBranches: "Indtast antal e -handelsfilialer",
    enterNumberOfEcommerceBranchesToPay:
      "Indtast antallet af e -handelsfilialer, du har til hensigt at betale for",
    ecommerceIntegration: "Integration af e -handel",
    enterNumberOfBranches: "Indtast antal grene",
    enterNumberOfAdditionalBranchesToPay:
      "Indtast antallet af yderligere grene, du har til hensigt at betale for",
    enterNumberOfStaffs: "Indtast antal medarbejdere",
    enterNumberOfStaffsToPayFor:
      "Indtast antal medarbejdere, du vil betale for",
    discountApplies: "Rabat gælder",
    starsOnThe: "stjerner på",
    offer: "tilbud",
    get: "Få",
    moreStarsToRedeem: "flere stjerner at indløse",
    taxVat: "Skat (moms)",
    callCashierToCompletePayment:
      "Ring til kasserer for at fuldføre betalingen",
    receipt: "Kvittering",
    dear: "Kære",
    thankYouForYourOrderFindGoods:
      "Tak for din ordre. Find venligst følgende leverede varer som aftalt.",
    shippingNote: "Forsendelsesnotat",
    enterShippingNote: "Indtast forsendelsesnota",
    shippingAddress: "Leveringsadresse",
    enterShippingAddress: "Indtast forsendelsesadresse",
    wellDoneYouAreDueToRedeem: "Godt klaret! Du skal indløse",
    toGetYourRewardNextVisit:
      "for at få din belønning ved dit næste besøg. tak skal du have",
    pointsOnThe: "Punkter på",
    morePointsToRedeem: "flere point at indløse",
    showCode: "Vis kode",
    toGetYourRewardOnNextVisit:
      "for at få din belønning ved dit næste besøg. tak skal du have",
    earn: "Tjen",
    delivaryNote: "Afleveringsnotat",
    draftSales: "Udkast til salg",
    startDate: "Start dato",
    endDate: "Slutdato",
    orders: "Ordre:% s",
    checkout: "checkout",
    noProductItem: "Ingen produkt vare",
    selectProductImage: "Vælg produktbillede",
    selectCountry: "Vælg land",
    selectRegion: "Vælg stat/region",
    printProductTag: "Udskriv produktmærke",
    transactionReference: "Transaktionsreference",
    Cashier: "Kasserer",
    Manager: "Manager",
    Owner: "Ejer",
    Admin: "Admin",
    addPartners: "Tilføj partnere",
    addNewLoyaltyPartner: "Tilføj ny loyalitetspartner",
    pleaseEnterCompanyName: "Indtast venligst firmanavn",
    companyName: "firmanavn",
    pleaseEnterCompanyRepName: "Indtast venligst firmarepræsentantens navn",
    companyRepName: "Navn på virksomhedens repræsentant",
    pleaseEnterCompanyRepEmail:
      "Indtast venligst virksomhedens repræsentants e-mail",
    companyRepEmail: "E-mail fra firmaets rep",
    pleaseEnterCompanyRepPhone:
      "Indtast venligst telefonnummeret på virksomhedens repræsentant",
    companyRepPhone: "Telefonnummer på firmaets rep",
    addReward: "Tilføj belønning",
    pleaseEnterRewardName: "Indtast venligst belønningens navn",
    rewardName: "Navn på belønning",
    rewardQuantity: "Belønningsmængde",
    rewardDescription: "Belønningsbeskrivelse",
    rewardType: "Belønningstype",
    pleaseEnterRewardType: "Indtast belønningstype",
    pleaseEnterRewardQuantity: "Indtast belønningsmængde",
    pleaseEnterRewardDescription: "Indtast belønningsbeskrivelse",
    fineDining: "Spise fint",
    luxuryFashion: "Luksus mode",
    hotels: "Hoteller",
    travel: "Rejse",
    foodAndBeverage: "Mad og drikke",
    fashion: "Mode",
    health: "Sundhed",
    furniture: "Møbel",
    entertainment: "Underholdning",
    automobile: "Automobil",
    education: "Uddannelse",
    beautyAndSpa: "Skønhed og Spa",
    staycation: "Staycation",
    events: "Begivenheder",
    trips: "Rejser",
    oilAndGas: "Olie og gas",
    laundry: "Vasketøj",
    partnerCategory: "Partnerkategori",
    freeItem: "Gratis vare",
  },
  Dutch: {
    cashier: "Kassa",
    manager: "manager",
    owner: "eigenaar",
    online: "online",
    offline: "offline",
    changePassword: "Verander wachtwoord",
    currentPasswordMessage: "Voer uw huidige wachtwoord in",
    passwordMessage: "Voer uw wachtwoord in",
    currentPassword: "huidig wachtwoord",
    password: "wachtwoord",
    confirmPasswordMessage: "Bevestig alstublieft uw wachtwoord!",
    confirmPassword: "bevestig wachtwoord",
    sendViaEmail: "Verzenden via e-mail",
    sendViaWhatsapp: "Verzenden via WhatsApp",
    downloadPdf: "Download PDF",
    paid: "betaald",
    unpaid: "onbetaald",
    partial: "gedeeltelijk",
    closeInvoice: "Wilt u de factuur sluiten?",
    closeInvoiceConfirmation:
      "Factuur kan niet worden opgeslagen. Wil je afsluiten?",
    yes: "Ja",
    no: "Nee",
    invoice: "Factuur",
    wasDeducted: "werd afgetrokken",
    for: "voor",
    item: "Item",
    addProduct: "Product toevoegen",
    paymentReference: "Betalingsreferentie:",
    amountPaid: "Betaald bedrag",
    discountAmount: "Korting hoeveelheid",
    amountDue: "Verschuldigd bedrag",
    amount: "Hoeveelheid",
    dueDate: "Opleveringsdatum",
    paymentType: "Betalingswijze",
    card: "Kaart",
    cash: "Contant geld",
    bankTransfer: "Overschrijving",
    paymentMessage: "Betalingsbericht:",
    description: "Beschrijving",
    sendReceipt: "Ontvangstbewijs verzenden",
    delete: "Verwijderen",
    save: "Opslaan",
    resend: "Opnieuw verzenden",
    saveAndSend: "Opslaan",
    invoiceSaved: "Factuur opgeslagen!",
    selectPaymentMethod: "Selecteer een betaalmethode, alstublieft!",
    selectCustomer: "Selecteer een klant!",
    cartEmptyError:
      "Winkelwagenlijst mag niet leeg zijn, sluit de factuur en voeg artikel toe aan winkelwagen!",
    subscriptionExpired:
      "Uw abonnement is verlopen en uw account is nu beperkt. Klik op de onderstaande knop om uw account te vernieuwen",
    renew: "Vernieuwen",
    holdOn: "Vasthouden",
    customerBank: "Klantenbank",
    cancel: "Annuleren",
    selectACustomer: "Selecteer klant",
    invoiceSuccessfulPdfError:
      "Factuur aangemaakt, maar het genereren van PDF duurt langer dan normaal. Kom binnenkort terug.",
    downloadingInvoice: "Factuur downloaden",
    downloadingReceipt: "Ontvangstbewijs downloaden",
    whatsappReceiptError:
      "Er is een fout opgetreden bij het verzenden van de bon via WhatsApp. Probeer het opnieuw.",
    receiptToWhatsapp: "Ontvangst doorgestuurd naar WhatsApp",
    thankYouForPatronage: "Bedankt voor uw steun",
    hereIsYourReceipt: "Hier is uw betalingsbewijs",
    errorSendingEmailReceipt:
      "Er is een fout opgetreden bij het verzenden van de bon via e-mail, probeer het opnieuw of neem contact op met de ondersteuning",
    receiptSentToEmail: "Ontvangst is verzonden naar de e-mail van de klant",
    invoiceSentToEmail: "Factuur is verzonden naar de e-mail van de klant",
    invoiceSuccessWhatsappError:
      "Factuur is aangemaakt, maar er is een fout opgetreden bij het verzenden naar WhatsApp. Probeer het opnieuw op de factuurlijst",
    invoiceSuccessfulEmailError:
      "Factuur is aangemaakt, maar er is een fout opgetreden bij het verzenden als e-mail. Probeer het opnieuw vanuit de factuurlijst",
    invoiceSentToWhatsapp: "Factuur doorgestuurd naar WhatsApp",
    hello: "Hallo",
    pleaseDownloadInvoice: "Download factuur a.u.b.",
    pleaseDownloadReceipt: "Download het ontvangstbewijs a.u.b.",
    from: "van",
    email: "E-mail",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Je hebt een gratis abonnement.",
    clickOn: "Klik op",
    yourPlanInFewSteps: " uw abonnement in een paar snelle stappen.",
    to: "tot",
    yourSubscriptionHasExpired:
      "Uw abonnement is verlopen en uw account is nu beperkt.",
    days: "dagen",
    yourSubscriptionExpiresIn: "Uw Loystar-abonnement verloopt over",
    createAcount: "Account aanmaken",
    signIn: "Inloggen",
    continue: "Doorgaan met",
    enterOtp: "Voer de OTP-pincode in",
    enterValidOtp: "Voer een geldige pincode in",
    pin: "PIN",
    tokenSentToMail: "Er is een token naar uw e-mailadres verzonden",
    passwordResetSuccessful: "Wachtwoord opnieuw instellen is gelukt",
    somethingWentWrong: "Er is iets fout gegaan!",
    resetPassword: "Wachtwoord opnieuw instellen",
    iHaveResetCode: "Ik heb een wachtwoord-resetcode",
    pleaseEnterEmail: "Vul alstublieft uw e-mailadres in",
    aTokenWillBeSentToEmail: "Er wordt een token naar uw e-mail verzonden",
    enterEmail: "Vul je e-mailadres in",
    sendinYourToken: "Je token verzenden...",
    makeSureItMatchesPassword:
      "Zorg ervoor dat het overeenkomt met uw nieuwe wachtwoord",
    pleaseChooseNewPassword: "Kies een nieuw wachtwoord",
    chooseNewPassword: "kies een nieuw wachtwoord",
    enterNewPassword: "Voer je nieuwe wachtwoord in om te bevestigen",
    enterTokenSent: "Voer het token in dat naar uw e-mail is verzonden",
    resetToken: "Token resetten",
    resettingPassword: "Uw wachtwoord opnieuw instellen...",
    signUp: "Inschrijven",
    adminSignInWithEmail:
      " Beheerder logt in met e-mail terwijl Personeel inlogt met gebruikersnaam.",
    pleaseEnterEmailOrUsername: "Voer uw e-mailadres of gebruikersnaam in",
    emailOrUsername: "E-mail of gebruikersnaam",
    pleaseEnterPassword: "Voer wachtwoord in alstublieft",
    createAnAccount: "Account aanmaken",
    forgotPassword: "Wachtwoord vergeten?",
    enterPhoneNumber: "Voer telefoonnummer in",
    personalData: "Persoonlijke gegevens",
    validateConfirmationCOde: "Bevestigingscode valideren",
    pleaseEnterFirstName: "Gelieve uw voornaam in te vullen",
    pleaseEnterPhoneNumber: "Voer je telefoonnummer in",
    pleaseEnterLastName: "Gelieve uw achternaam in te voeren",
    pleaseEnterBusinessName: "Vul a.u.b. uw bedrijfsnaam in",
    firstName: "Voornaam",
    lastName: "Achternaam",
    businessName: "Bedrijfsnaam",
    previous: "Vorig",
    next: "Volgende",
    pleaseSelectBusinessCategory: "Selecteer uw bedrijfscategorie",
    pleaseEnterValidEmail: "Gelieve een geldig e-mailadres in te vullen",
    pleaseEnterPostCode: "Voer de postcode in",
    postCode: "Postcode",
    phoneNumberInUse: "Dit telefoonnummer is al in gebruik!",
    emailInUse: "Dit e-mailadres is al in gebruik!",
    foodAndDrinks: "Eten en drinken",
    salonAndBeauty: "Salon en schoonheid",
    fashionAndAccessories: "Mode en accessoires",
    gymAndFitness: "Fitnessruimte en fitness",
    travelAndHotel: "Reizen en hotel",
    homeAndGifts: "Thuis en geschenken",
    washingAndCleaning: "Wassen en schoonmaken",
    gadgetsAndElectronics: "Gadgets en elektronica",
    groceries: "Boodschappen",
    others: "anderen",
    submit: "Indienen",
    accountCreatedSuccessful: "Uw account is succesvol aangemaakt.",
    pleaseEnterAddress: "Vul a.u.b. uw adres in",
    addressLine1: "Adres regel 1",
    addressLine2: "Adres lijn 2",
    choosePassword: "Kies wachtwoord",
    passwordMustBe6Characters:
      "Wachtwoord moet bestaan uit minstens 6 karakters.",
    howDidYouHearLoystar: "Hoe heb je van Loystar gehoord?",
    referralCode: "verwijzingscode:",
    byClickingTheButton:
      " Door op de onderstaande knop te klikken, gaat u akkoord met de",
    termsAndSevice: "voorwaarden",
    wereCreatingAccount: "We maken uw account aan",
    proceed: "Doorgaan",
    verificationCodeMustBe6: "Verificatiecode moet 6 cijfers zijn",
    pleaseEnter6DigitCode: "Voer een 6-cijferige code in",
    enterVerificationCode: "Voer verificatiecode in",
    resendToken: "Token opnieuw verzenden",
    verify: "Verifiëren",
    transactions: "Transacties",
    todaySales: "Verkoop van vandaag",
    salesHistory: "Verkoopgeschiedenis",
    supplyHistory: "Leveringsgeschiedenis",
    new: "Nieuw",
    invoices: "Facturen",
    disbursements: "Uitbetalingen",
    offlineSales: "Offline verkoop",
    products: "Producten",
    customers: "Klanten",
    multiLevelLoyalty: "Loyaliteit op meerdere niveaus",
    loyaltyPrograms: "Loyaliteitsprogramma's",
    members: "Leden",
    appStore: "App Winkel",
    orderMenu: "Bestelmenu",
    settings: "Instellingen",
    staffAndBranches: "Staf en takken",
    myAccount: "Mijn rekening",
    switchToSellMode: "Overschakelen naar verkoopmodus",
    signOut: "Afmelden",
    getFreeSubscription: "Ontvang een gratis abonnement",
    onlyNumbersAllowed: "Alleen cijfers zijn toegestaan",
    yourAccountMustBe10Digits:
      "uw rekeningnummer moet een 10-cijferig nummer zijn",
    yourBvnMustBe11: "uw BVN moet een 11-cijferig nummer zijn",
    pleaseSelectBank: "Selecteer uw bank",
    selectYourBank: "Selecteer uw bank",
    enterBankAccountNumber: "Voer bankrekeningnummer in",
    enterBvnNumber: "Vul je BVN in",
    connectBankAccount: "Bankrekening koppelen",
    passwordResetSuccessfulAndSignOut:
      "Uw wachtwoord is met succes opnieuw ingesteld. Klik op de knop Afmelden hieronder om opnieuw in te loggen",
    enterCurrentPassword: "Vul huidig wachtwoord in",
    pleaseEnterCurrentPassword: "Voer het huidige wachtwoord in",
    phoneNumber: "Telefoonnummer",
    sex: "Seks",
    dateOfBirth: "Geboortedatum",
    state: "Staat",
    country: "Land",
    loyaltyId: "Loyaliteits-ID",
    createdAt: "Gemaakt bij",
    noOfTransactionsMade: "Aantal transacties gedaan",
    yourDownloadWillStart: "Je download zal zo beginnen",
    exportCustomers: "Klanten exporteren",
    youhaveSuccessfullyToppedUp:
      "Je hebt je SMS Units succesvol opgewaardeerd.",
    youNowHave: "Je hebt nu",
    smsUnits: "SMS-eenheden",
    enterNumberOfSmsUnits: "Voer het aantal sms-eenheden in dat u wilt kopen",
    pleaseEnterANumericValue: "Voer een numerieke waarde in",
    pay: "Betalen",
    accountEditedSuccessfully: "Uw account is met succes bewerkt.",
    youAeCurrentlyOn: "Je bent momenteel op",
    plan: "Plan",
    userData: "Gebruikersgegevens",
    businessData: "BEDRIJFSGEGEVENS:",
    businessCategory: "Zakelijke categorie",
    pleaseSelectCurrency: "Selecteer uw valuta",
    selectYourCurrency: "Selecteer uw valuta",
    purchaseMoreSmsUnits: "Koop meer SMS Units via onderstaand formulier",
    youHave: "Jij hebt",
    atLeast4SmsUnitsRrequired:
      "Er zijn minimaal 4 sms-units nodig voor verificatie, herlaad aub!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Verifieer uw bankrekening om te voldoen aan de 'Know Your Customer' (KYC)-vereisten. Hiermee kunt u betalingen innen via USSD of directe overboeking, bestellingen van klanten ontvangen en transactieafwikkeling verwerken. Alleen van toepassing op Nigeriaanse handelaren. Druk op de onderstaande knop om te beginnen.",
    reConnectBankAccount: "Bankrekening opnieuw koppelen",
    accountName: "Accountnaam",
    accountNumber: "Rekeningnummer",
    bankName: "Banknaam",
    verified: "Geverifieerd",
    accountDetails: "Accountgegevens",
    kycBankAccount: "KYC",
    createTier: "Tier maken",
    fileUploadSuccessful: "bestand succesvol geüpload",
    fileUploadFailed: "bestand uploaden mislukt",
    createLoyaltyProgram: "Een loyaliteitsprogramma maken",
    createLoyalty: "Loyaliteit creëren",
    name: "Naam",
    loyaltyArtwork: "Loyaliteitskunstwerk",
    clickToUpload: "Klik om te uploaden",
    amountToPointsRatio: "Verhouding van bedrag tot punten",
    points: "Punten",
    recommendedAmountToPointRatio:
      "Aanbevolen : ₦1 tot 1punt. d.w.z. voor elke uitgegeven 1 Naira, krijgen uw klanten 1 punt",
    pleaseTypeIn: "Typ alstublieft in",
    toDeleteLoyalty: "om deze loyaliteit te verwijderen",
    deleteLoyalty: "Loyaliteit verwijderen",
    toConfirm: "bevestigen",
    edit: "Bewerking",
    pointsAwardedSuccessfully: "Punten succesvol toegekend.",
    enterPointValueToAward:
      "Voer de puntenwaarde in die u klanten wilt belonen",
    award: "Prijs",
    awardPointValuesToCustomer: "Puntwaarde toekennen aan klant",
    enrollMembersToLoyalty: "Leden inschrijven voor Loyalty",
    awardPoints: "Punten toekennen",
    enroll: "Inschrijven",
    home: "Huis",
    loyalty: "Loyaliteit",
    enrollCustomers: "Klanten inschrijven",
    selected: "Geselecteerd",
    customer: "Klant",
    loyaltyActivationSuccessful: "Loyaliteitsactivering is gelukt.",
    loyaltyDeactivationSuccessful: "Loyaliteitsdeactivering is gelukt.",
    viewTier: "Niveau bekijken",
    deactivate: "Deactiveren",
    activate: "Activeren",
    actions: "Acties",
    nameOfLoyalty: "Naam van loyaliteit",
    loyaltyStatus: "LoyaliteitsstatusLoyaliteitsstatus",
    numberOfTiers: "Aantal niveaus",
    createdOn: "Gemaakt op",
    createATier: "Een niveau maken",
    stopCreatingTierConfirmation: "Wilt u stoppen met het maken van een laag?",
    stopEditingTierConfirmation:
      "Wilt u stoppen met het bewerken van deze laag?",
    nameOfTier: "Naam van niveau",
    minimumSpendingTarget: "Minimum bestedingsdoel",
    maximumSpendingTarget: "Maximaal bestedingsdoel",
    minimumSpendingTargetRequired: "minimale bestedingsdoelstelling is vereist",
    maximumSpendingTargetRequired: "maximale bestedingsdoel is vereist",
    rewardSponsor: "Beloning Sponsor",
    pleaseChooseARewardSponsor: "Kies een beloningssponsor",
    self: "Zelf",
    partner: "Partner",
    rewardPartner: "Beloningspartner",
    pleaseSelectRewardPartner: "Selecteer uw beloningspartner",
    rewards: "Beloningen",
    pleaseSelectAReward: "Kies een beloning",
    instructionsOnRedeemingReward:
      "Instructies over hoe de klant een beloning moet inwisselen",
    pleaseFillInThisField: "Gelieve dit veld in te vullen!",
    toDeleteThisTier: " om deze laag te verwijderen",
    deleteTier: "Tier verwijderen",
    viewMembers: "Leden bekijken",
    membersEnrolled: "Leden ingeschreven",
    instruction: "Instructie",
    membersIn: "Leden in",
    availableTiersInLoyalty: "Beschikbare tier(s) in loyaliteitsprogramma",
    tiers: "niveaus",
    totalTier: "TOTALE TIER",
    availableLoyaltyProgramme: "Beschikbaar loyaliteitsprogramma",
    totalLoyalties: "TOTALE LOYALITEITEN",
    memberDetails: "Gegevens lid",
    nameOfCustomer: "Naam van de klant",
    address: "Adres",
    allEnrolledMembers: "Alle ingeschreven leden",
    thisIsToWishYouHappyBirthday:
      "Dit is om u een zeer gelukkige verjaardag en een voorspoedig leven te wensen. Bedankt voor alles wat je bent voor Loyster. Fijne feesten!",
    inputAnOfferPlease: "Voer een bod in aub",
    pleaseSelectTheInsertPoint:
      "Selecteer het invoegpunt in het bericht en klik opnieuw",
    birthdayOfferAndMessage: "Verjaardagsaanbieding en bericht",
    birthdayOffer: "Verjaardagsaanbieding",
    birthdayMessage: "Verjaardag Bericht",
    noOfferFound: "Geen aanbieding gevonden",
    settingABirthdayOffer:
      "Door een verjaardagsaanbieding in te stellen, kunnen klanten deze aanbieding via sms ontvangen op hun verjaardag",
    createOffer: "Aanbieding maken",
    whatIsTheOffer: "Wat is het aanbod?",
    editMessage: "Bericht bewerken",
    insert: "Invoegen",
    theNameOfCustomerInserted: "De naam van de klant wordt hier ingevoegd",
    theBirtdayOfferInserted: "De verjaardagsaanbieding wordt hier ingevoegd",
    youSuccessfullyAddedNewBranch:
      "Je hebt succesvol een nieuw filiaal toegevoegd!",
    addNewBranch: "Nieuwe vestiging toevoegen",
    addBranch: "Vertakking toevoegen",
    additionalBranchWillIncur: "Extra tak zou oplopen:",
    perBranch: "per filiaal",
    ecommerceBranchCosts: "Kosten e-commerce filiaal",
    pleaseEnterBranchName: "Voer de naam van het filiaal in",
    pleaseEnterBranchAddress1: "Voer de adresregel van het filiaal in 1",
    enterBranchAddress1: "Voer de adresregel van het filiaal in 1",
    enterBranchAddress2: "Voer de adresregel van het filiaal in 1",
    pleaseEnterBranchAddress2: "Voer de adresregel van het filiaal in 2",
    enterBranchName: "Voer de filiaalnaam in",
    successfullyAddedStaff: "Je hebt met succes een nieuwe staf toegevoegd!",
    addNewStaff: "Nieuw personeel toevoegen",
    addStaff: "Personeel toevoegen",
    additionalStaffWillIncur: "Extra personeel zou oplopen",
    perStaff: "per personeel.",
    pleaseEnterStaffEmail: "Voer het e-mailadres van het personeel in",
    pleaseEnterStaffUsername: "Voer de gebruikersnaam van het personeel in",
    pleaseEnterStaffPassword: "Voer het wachtwoord van het personeel in",
    pleaseSelectStaffRole: "Selecteer de rol van het personeel",
    selectStaffRole: "Selecteer de rol van het personeel",
    enterStaffEmail: "Voer het e-mailadres van het personeel in",
    enterStaffUsername: "Voer de gebruikersnaam van het personeel in",
    enterStaffPassword: "Voer het wachtwoord van het personeel in",
    spacesNotAllowedInUsername: "spaties niet toegestaan in gebruikersnaam",
    admin: "beheerder",
    pleaseSelectBusinessToAttachStaff:
      "Selecteer een bedrijf om personeel toe te voegen",
    searchForBranchToAttachStaff:
      "Zoek naar filiaal om personeel toe te voegen",
    username: "gebruikersnaam",
    role: "Rol",
    areYouSureToDeleteThis: "Weet je zeker dat je dit wilt verwijderen",
    branches: "Takken",
    upgradeYourPlan: "Upgrade uw abonnement.",
    add: "TOEVOEGEN",
    addNew: "Nieuw toevoegen",
    customerWithEmailAlreadyExists:
      "Klant met e-mailadres/telefoonnummer bestaat al!",
    successfullyAddedNewCustomer:
      "Je hebt succesvol een nieuwe klant toegevoegd!",
    addCustomer: "Klant toevoegen",
    pleaseEnterCustomerFirstName: "Voer de voornaam van de klant in",
    pleaseEnterCustomerLastName: "Voer de achternaam van de klant in",
    pleaseEnterCustomerPhoneNumber: "Voer het telefoonnummer van de klant in",
    pleaseEnterCustomerEmail: "Voer het e-mailadres van de klant in",
    pleaseEnterCustomerAddressLine: "Voer de adresregel van de klant in",
    pleaseEnterCustomerOtherAddress: "Voer het andere adres van de klant in",
    pleaseSelectCustomerGender: "Selecteer het geslacht van de klant",
    gender: "Geslacht",
    male: "Mannelijk",
    female: "Vrouwelijk",
    bank: "bank",
    selectBank: "Selecteer Bank",
    stateOrRegionOrProvince: "Staat/Regio/Provincie",
    customerNotes: "Klantnotities",
    sendSms: "Verstuur sms",
    editCustomer: "Klant bewerken",
    redeemReward: "Beloning inwisselen",
    issueLoyaltyCard: "Klantenkaart uitgeven",
    deleteCustomer: "Klant verwijderen",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Je hebt met succes een loyaliteitslidmaatschaps-ID toegewezen",
    noMembershipIdAvailable:
      "Geen lidmaatschaps-ID's beschikbaar. Neem contact met ons op via hello@loystar.co",
    sendEmail: "E-mail verzenden",
    membershipPoints: "Lidmaatschapspunten",
    customerDetails: "Klant details",
    close: "Dichtbij",
    loyaltyBalance: "Loyaliteitssaldo",
    pointsBalance: "Puntensaldo",
    starBalance: "Sterrensaldo",
    requiredPoints: "Vereiste punten",
    requiredStars: "Vereiste sterren",
    reddemCode: "Code inwisselen",
    toDeleteThisCustomer: "om deze klant te verwijderen",
    customerHasBeenDeletedSuccessfully: "Klant is succesvol verwijderd!",
    customerWithPhoneAlreadyExists: "Klant met telefoonnummer bestaat al",
    customerWasSuccessfullyEdited: "Klant is succesvol bewerkt",
    anErrorOccured: "Er is een fout opgetreden",
    phoneNumberIncludeCountry: "Telefoonnummer (inclusief landcode)",
    yourFileQueuedForUpload:
      "Uw bestand staat in de wachtrij voor uploaden. Ververs de pagina na een paar seconden.",
    thereWasAnErrorPerformingOperation:
      "Er is een fout opgetreden bij het uitvoeren van de bewerking!",
    pleaseSelectFile: "Selecteer een bestand!",
    oopsOnlyCsvFilesAllowed:
      "Oeps! Alleen CSV-bestanden toegestaan. Upload een .csv-bestand.",
    fileShouldBeSmallerThan5Mb:
      "Bestand moet kleiner zijn dan 5 MB. Als je een groter bestand hebt, stuur dan een e-mail naar support@loystar.co. Bedankt",
    customerFirstNameIsRequired:
      "de voornaam van de klant is vereist in de rij",
    customerPhoneNumberIsRequired:
      "het telefoonnummer van de klant is vereist in de rij",
    importCustomers: "Klanten importeren",
    upload: "Uploaden",
    clickIconToDownloadCsv:
      "Klik op dit pictogram om de sjabloon voor het CSV-bestand te downloaden.",
    getGoogleSheetVersion: "Download de Google-bladversie",
    clickOrDragFileToUpload:
      "Klik of sleep het bestand naar dit gebied om te uploaden",
    missingOrInvalidColumnHeader:
      "Ontbrekende of ongeldige kolomkop. Volg het sjabloonformaat. Bedankt.",
    youHaveImported: "Je hebt geïmporteerd",
    youSuccessfullyRedeemedReward:
      "Je hebt je beloning met succes ingewisseld!",
    sixDigitCode: "Zescijferige code",
    pleaseEnterCustomerRewardCode: "Voer de beloningscode van de klant in",
    enterRewardCode:
      "Voer de beloningscode in. (De beloningscode is hoofdlettergevoelig)",
    selectLoyaltyProgram: "Selecteer loyaliteitsprogramma",
    stamps: "Postzegels",
    smsUnitsLow: "SMS-eenheden laag",
    pleaseTopUpSmsUnits: "Gelieve SMS-eenheden op te waarderen",
    smsSuccessfullySentForDelivery: "SMS succesvol verzonden voor levering!",
    sendSmsTo: "Sms sturen naar",
    allCustomers: "Alle klanten",
    unitsAvailable: "Eenheden beschikbaar",
    pleaseTypeInTheMessage: "Typ het bericht a.u.b. in",
    message: "Bericht",
    charactersRemaining: "overgebleven karakters",
    avoidUseOfSpecialCharacters:
      "Vermijd het gebruik van speciale tekens en emoji's om sms-eenheden te sparen.",
    note: "Opmerking",
    errorFetchingCustomersMultilevelDetail:
      "Fout bij ophalen van klantgegevens op meerdere niveaus",
    search: "Zoeken",
    reset: "Resetten",
    importCustomer: "Klant importeren",
    addNewCustomer: "Nieuwe klant toevoegen",
    sendSmsBroadcast: "Sms-uitzending verzenden",
    totalCustomers: "TOTAAL KLANTEN",
    disbursementDetails: "Uitbetalingsdetails",
    paidBy: "Betaald door",
    disbursed: "uitbetaald",
    bankAccountName: "Bank Account naam",
    bankAccountNumber: "Bankrekeningnummer",
    transferInitiated: "Overdracht gestart",
    transferCompleted: "Overdracht voltooid",
    pleaseEnterAValid: "Voer een geldig",
    begin: "beginnen",
    end: "einde",
    date: "datum",
    paymentDate: "Betaaldatum",
    selectDisbursementDuration: "Selecteer Uitbetalingsduur",
    totalSettled: "Totaal afgerekend",
    totalUnsettled: "Totaal onrustig",
    toDeleteThisSale: "om deze verkoop te verwijderen",
    draftSaleDeletedSuccessfully: "Conceptverkoop is succesvol verwijderd!",
    deleteSale: "Verkoop verwijderen",
    pleaseTypeInYourTotal: "Voer uw totaal in om de verwijdering te bevestigen",
    billDetails: "Factuurgegevens",
    printBill: "Factuur afdrukken",
    servedBy: "Bediend door",
    total: "Totaal",
    createdDate: "Aanmaakdatum",
    createdTime: "Gemaakte tijd",
    status: "Toestand",
    loginSuccessful: "Succesvol ingelogd",
    pleaseWaitWhileWeConnectAccount:
      "Een ogenblik geduld terwijl we uw account koppelen",
    connectionFailedTryAgain: "Verbinding mislukt. Probeer het opnieuw.",
    connectionSuccessful: "Verbinding succesvol",
    viewDetails: "Details bekijken",
    enable: "Inschakelen",
    free: "Vrij",
    cost: "Kosten",
    visitWebsite: "Bezoek website",
    pleaseUpgradeYourPlanToPro:
      "Upgrade uw abonnement naar Pro Plus om deze app in te schakelen",
    connectYourBankAccount:
      "Koppel uw bankrekening om betalingen te kunnen ontvangen.",
    disable: "Uitzetten",
    enableApp: "App inschakelen",
    addNewProductToInvoice: "Nieuw product toevoegen aan factuur",
    toDeleteThisInvoice: "om deze factuur te verwijderen",
    invoiceDeletedSuccessfully: "Factuur is succesvol verwijderd!",
    deleteInvoice: "Factuur verwijderen",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "U heeft met succes een factuurbetalingsbericht toegevoegd!",
    pleaseEnterPaymentInstructions: "Voer de betalingsinstructies in",
    invoiceId: "Factuur-ID",
    paidDate: "Betaalde datum",
    reference: "Verwijzing",
    productAdded: "Product toegevoegd",
    productOutOfStock: "Product niet op voorraad. Gelieve opnieuw op te slaan.",
    totalInvoices: "TOTAAL FACTUREN",
    totalPaidInvoices: "TOTAAL BETAALDE FACTUREN",
    totalUnpaidInvoices: "TOTAAL ONBETAALDE FACTUREN",
    loyaltyProgramDeleted: "Loyaliteitsprogramma verwijderd",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dit loyaliteitsprogramma is succesvol verwijderd",
    thisLoyaltyProgramEditedSuccessfully:
      "Dit loyaliteitsprogramma is met succes bewerkt",
    loyaltyProgramAddedSuccessfully:
      "Loyaliteitsprogramma is succesvol toegevoegd",
    loyaltyProgramEdited: "Loyaliteitsprogramma bewerkt",
    loyaltyProgramAdded: "Loyaliteitsprogramma toegevoegd",
    loyaltyDetails: "Loyaliteitsdetails",
    doYouWantToCloseDialog: "Wilt u dit dialoogvenster sluiten?",
    pleaseEnterLoyaltyName: "Voer de naam van uw loyaliteit in",
    programType: "Programmatype:",
    pleaseSelectProgramType: "Selecteer een programmatype",
    simplePoints: "Eenvoudige punten",
    stampsProgram: "Postzegelprogramma",
    threshold: "Drempelwaarde",
    pleaseEnterLoyaltyThreshold: "Voer de loyaliteitsdrempel in",
    reward: "Beloning",
    pleaseEnterLoyaltyReward: "Voer de beloning van de loyaliteit in",
    totalUserPoints: "Totaal aantal gebruikerspunten",
    totalUserStamps: "Totaal aantal gebruikersstempels",
    spendingTarget: "Bestedingsdoel",
    spendingTargetHint1:
      "Het bestedingsdoel is hoeveel een klant moet uitgeven om de beloning te verdienen. 1 valutawaarde = 1 punt.",
    spendingTargetHint2:
      "Het doel van postzegels is hoeveel postzegels een klant moet verzamelen om de beloning te verdienen. bijv. 5",
    addNewLoyaltyProgram: "Nieuw loyaliteitsprogramma toevoegen",
    addLoyaltyProgram: "Loyaliteitsprogramma toevoegen",
    loyaltyProgramType: "Type loyaliteitsprogramma",
    pleaseSelectLoyaltyProgramType: "Selecteer het type loyaliteitsprogramma",
    nameOfProgram: "Naam van het programma",
    pleaseEnterProgramName: "Voer a.u.b. de programmanaam in",
    whatIsTheReward: "Wat is de beloning?",
    egNextPurchaseIsFree: "bijv. Volgende aankoop is gratis",
    customerName: "klantnaam",
    guestCustomer: "Gast klant",
    orderConfirmedSuccessfully: "Bestelling succesvol bevestigd",
    orderCancelledSuccessfully: "Bestelling succesvol geannuleerd",
    confirmOrder: "Bevestig bestelling",
    cancelOrder: "Annuleer bestelling",
    allOrders: "Alle bestellingen",
    totalOrders: "TOTAAL BESTELLINGEN",
    doYouWantToAcceptOrder: "Wil je deze bestelling accepteren?",
    doYouWantToCancelOrder: "Wil je deze bestelling annuleren?",
    orderDetails: "Bestel Details",
    orderCreatedAt: "Bestelling aangemaakt op",
    supplier: "Leverancier",
    productName: "productnaam",
    quantity: "Hoeveelheid",
    unitPrice: "Stuksprijs",
    receivedBy: "Ontvangen door",
    reportFrom: "Melden van",
    totalSupplies: "Totale benodigdheden",
    allRightsReserved: "Alle rechten voorbehouden",
    toDeleteThisTransaction: "om deze transactie te verwijderen",
    transactionDeletedSuccessfully:
      "Transactie is succesvol verwijderd. De voorraad is teruggeplaatst in de voorraad.",
    deleteTransaction: "Transactie verwijderen",
    transactionDetails: "transactie details",
    printReceipt: "Printbon",
    channel: "Kanaal",
    discount: "Korting",
    profit: "Winst",
    discountedSales: "Verkoop met korting",
    errorFetchingRecord: "Fout bij ophalen van record",
    exportTransactions: "Transacties exporteren",
    errorFetchingSalesRecord: "Fout bij ophalen van verkooprecord voor export.",
    totalSellingPrice: "Totale verkoopprijs",
    totalCostPrice: "Totale kostprijs",
    appliedDiscount: "toegepaste korting",
    noOfItems: "Aantal artikelen",
    sales: "verkoop",
    export: "Exporteren",
    totalProfit: "Totale winst",
    totalBalanceInPeriod: "Totaal saldo in periode",
    allTimeSales: "Altijd verkopen",
    records: "Records",
    todaysSales: "Verkoop van vandaag",
    transaction: "transactie",
    youHaveExceededTotalNumberOfProducts:
      "U heeft het totale aantal toegestane producten voor uw abonnement overschreden. Upgrade uw abonnement om van een hogere limiet te genieten.",
    youNeedToHaveLoyaltyProgram:
      "U moet een loyaliteitsprogramma hebben om deze functie te gebruiken!",
    price: "Prijs",
    category: "Categorie",
    stockTracking: "Voorraad volgen",
    stockCount: "Voorraad",
    taxed: "Belast",
    originalPrice: "Originele prijs",
    costPrice: "Kostprijs",
    unit: "Eenheid",
    productImage: "product afbeelding",
    taxRate: "Belastingtarief",
    taxType: "Belastingsoort",
    trackInventory: "Voorraad bijhouden",
    variants: "varianten",
    hasVariants: "Heeft varianten",
    importProduct: "Product importeren",
    exportProducts: "Producten exporteren",
    addNewProduct: "Nieuw product toevoegen",
    viewCategory: "Bekijk categorie",
    viewSuppliers: "Bekijk leveranciers",
    receiveInventory: "Inventaris ontvangen",
    printAllProductsTag: "Print alle producten Tag",
    deleteAll: "Verwijder alles",
    totalProducts: "TOTAAL PRODUCTEN",
    youveSuccessfullyAddedANewCategory:
      "Je hebt met succes een nieuwe categorie toegevoegd",
    addNewCategory: "Voeg een nieuwe categorie toe",
    addCategory: "categorie toevoegen",
    categoryName: "categorie naam",
    pleaseEnterCategoryName: "Voer de categorienaam in",
    stampsTarget: "Postzegels Doel",
    sendInventory: "Inventaris verzenden",
    productDetails: "Productdetails",
    youveSuccessfullyEditedThisCategory:
      "Je hebt deze categorie met succes bewerkt",
    update: "Update",
    categoryList: "categorie lijst",
    categories: "Categorieën",
    enterQuantityToUpdate: "Voer een aantal in om bij te werken",
    inventorySentSuccessfully: "De inventaris is succesvol verzonden!",
    updateInventory: "Voorraad bijwerken",
    currentQuantity: "Huidige hoeveelheid",
    pleaseEnterQuantityToAdd: "Voer de hoeveelheid in die u wilt toevoegen",
    pleaseSelectABranch: "Selecteer een filiaal",
    searchForBranch: "Zoek naar filiaal",
    youCantSendMoreThanStock:
      "U kunt niet meer verzenden dan u op voorraad heeft",
    send: "Versturen",
    pleaseEnterQuantityToSend: "Voer het aantal in dat u wilt verzenden",
    productNameIsRequiredOnRow: "productnaam is vereist op rij",
    productCategoryIsRequiredOnRow: "productcategorie is verplicht op rij",
    productPriceIsRequiredOnRow: "productprijs is vereist op rij",
    productUnitIsRequiredOnRow: "producteenheid is vereist op rij",
    productQuantityIsRequiredOnRow: "producthoeveelheid is vereist op rij",
    productVariantsRequireTracking: "productvarianten vereist tracking!",
    pleaseAddVariantClickButton:
      "Voeg een variant toe door op de knop Toevoegen te klikken!",
    totalVariantsMoreThanSelectedQuantity:
      "Totale varianten zijn meer dan de geselecteerde producthoeveelheid, verminder de varianthoeveelheid!",
    productEditedSuccessfully: "Product is succesvol bewerkt!",
    fileTooLargeLessThan4Mb:
      "Bestandsgrootte is te groot! De bestandsgrootte moet kleiner zijn dan 4 MB.",
    suchVariantAlreadyExist: "Een dergelijke variant bestaat al",
    addVariants: "Varianten toevoegen",
    editProduct: "Product bewerken",
    pleaseEnterProductName: "Voer de productnaam in",
    pleaseEnterProductPrice: "Voer de productprijs in",
    pleaseEnterProductOriginalPrice:
      "Voer de originele prijs van het product in",
    productDescription: "Productomschrijving",
    selectProductCategory: "Selecteer productcategorie",
    pleaseSelectProductCategory: "Selecteer productcategorie",
    productCostPrice: "Kostprijs product",
    productSellingPrice: "Productverkoopprijs:",
    productOriginalPrice: "Oorspronkelijke prijs van het product:",
    maxFileSizeAllowedIs4Mb: "Maximaal Toegestane bestandsgrootte is 4mb",
    productsWithPicturesArePublished:
      "Producten met foto's worden op Discover gepubliceerd om bestellingen te ontvangen",
    shouldThisProductBeTracked: "Moet dit product worden gevolgd?",
    pleaseSelectStockUnit: "Selecteer een voorraadeenheid",
    stockUnit: "Voorraadeenheid:",
    bag: "TAS",
    bottle: "FLES",
    bunch: "BUNDEL",
    can: "KAN",
    carton: "KARTON",
    crate: "KRAT",
    cup: "BEKER",
    dozen: "DOZIJN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKET",
    pair: "PAAR",
    pieces: "STUKS",
    plate: "BORD",
    tonne: "TON (MT)",
    uNIT: "EENHEID",
    yard: "TUIN",
    pleaseEnterProductQuantity: "Voer de producthoeveelheid in",
    productQuantity: "Product kwantiteit",
    isThisProductTaxed: "Wordt dit product belast?",
    selectTaxType: "Selecteer belastingsoort",
    pleaseSelectTaxType: "Selecteer a.u.b. belastingsoort",
    progressive: "Progressief",
    proportional: "Proportioneel",
    pleaseEnterProductTaxRate: "Voer het btw-tarief van het product in",
    doesProductHaveVariants: "Heeft dit product varianten?",
    type: "Type",
    value: "Waarde",
    pleaseEnterVariantType: "Voer het type van de variant in",
    pleaseEnterVariantValue: "Voer de waarde van de variant in",
    pleaseEnterVariantPrice: "Voer de prijs van de variant in",
    pleaseEnterVariantQuantity: "Voer de hoeveelheid van de variant in",
    productDeletedSuccessfully: "Product is succesvol verwijderd!",
    categoryDeletedSuccessfully: "Categorie is succesvol verwijderd!",
    toDeleteThisProduct: "om dit product te verwijderen",
    invalidProductQuantity: "Ongeldige producthoeveelheid",
    quantityAddedIsOverStock:
      "De hoeveelheid die u toevoegt, is meer dan wat u op voorraad heeft.",
    itemInventoryNotTracked: "Artikelvoorraad wordt niet bijgehouden",
    addBulkQuantity: "Bulkhoeveelheid toevoegen",
    enterBulkQuantity: "Voer bulkhoeveelheid in",
    pleaseEnterBulkQuantity: "Vul a.u.b. bulkhoeveelheid in",
    youveSuccessfullyAddedANewProduct:
      "Je hebt succesvol een nieuw product toegevoegd!",
    pleaseEnterProductSellingPrice: "Voer de verkoopprijs van het product in",
    doYouWantToTrackProductStock: "Wilt u de productvoorraad volgen?",
    sellingPrice: "Verkoopprijs",
    setProductExpiryReminder: "Herinnering productverloop instellen",
    productExpiryDate: "Vervaldatum product",
    startRemindingFrom: "Begin met herinneren van",
    productSuppliesAddedSuccessfully:
      "U heeft productbenodigdheden succesvol toegevoegd.",
    addProductSupplies: "Productbenodigdheden toevoegen",
    pleaseSelectSupplier: "Selecteer leverancier",
    nameOfProduct: "Naam van het product",
    pleaseSelectProduct: "Selecteer product",
    productVariant: "Productvariant:",
    pleaseSelectAVariant: "Selecteer een variant",
    pleaseEnterUnitPrice: "Voer een eenheidsprijs in",
    businessBranch: "Bedrijfstak",
    pleaseSelectBranch: "Selecteer filiaal",
    youveSuccessfullyAddedANewSupplier:
      "Je hebt met succes een nieuwe leverancier toegevoegd",
    addSupplier: "Leverancier toevoegen",
    pleaseEnterSupplierEmail: "Voer het e-mailadres van de leverancier in",
    pleaseAddADescription: "Voeg een beschrijving toe",
    anErrorOccuredProductsDeleted:
      "Er is een fout opgetreden tijdens het uitvoeren van de bewerking. Houd er rekening mee dat er mogelijk enkele producten zijn verwijderd tijdens het proces",
    bulkDelete: "Bulk verwijderen",
    youAreAboutToDelete: "Je staat op het punt om te verwijderen",
    product: "Product",
    isDueToRedeem: "moet worden ingewisseld",
    aReward: "een beloning",
    pleaseSelectCustomerToReeemReward:
      "Selecteer een klant om de beloning in te wisselen.",
    youHaveNoLoyaltyProgramRunning: "U heeft geen actief loyaliteitsprogramma",
    customerHhasNoPointsInLoyaltyProgram:
      "Klant heeft geen punten in dit loyaliteitsprogramma",
    proceedWithPayment: "Doorgaan met betalen?",
    youAreAboutToPayForTransactionUsingPoints:
      "U staat op het punt de transactie met punten te betalen.",
    customerHas: "Klant heeft:",
    worth: "waard",
    andIsNotSufficientToPayForTransaction:
      "en is niet voldoende om deze transactie te betalen. Zouden ze het saldo willen toevoegen met een andere betaalmethode?",
    addCustomerLoyalty: "Klantloyaliteit toevoegen",
    pleaseAddCustomerFirst:
      "Gelieve eerst een klant toe te voegen of te selecteren.",
    pleaseWaitWhileWeGetReady: "Even geduld, terwijl we ons klaarmaken",
    lowStock: "Lage voorraad",
    pleaseEnterAmountTendered: "Vul a.u.b. het ontvangen bedrag in",
    areYouSureToBookSaleOffline:
      "Weet je zeker dat je deze uitverkoop offline wilt boeken?",
    saleWouldBeBookedAutomatically:
      "De verkoop wordt automatisch geboekt wanneer u uw internet inschakelt",
    offlineSalesBookingCancelled: "Offline verkoopboeking geannuleerd",
    covid19Message:
      "COVID19: Was je handen met zeep of Sanitize om de verspreiding te stoppen. Blijf altijd veilig",
    saleSuccessfullyRecorded: "Verkoop succesvol geregistreerd!",
    sendReceiptToEmail: "Stuur ontvangstbewijs naar e-mail",
    sendThankYouSms: "Stuur een bedank-sms",
    sendShippingDetails: "Verzendgegevens verzenden",
    addLoyalty: "Loyaliteit toevoegen",
    searchCustomerNameOrNumber: "Zoek de naam of het nummer van de klant",
    clickTheSearchCustomerBox: "Klik op Klantenbox zoeken en kaart scannen",
    enterProductPrice: "Voer productprijs in",
    enterPriceFor: "Voer prijs in voor",
    searchForProduct: "Product zoeken",
    all: "Alle",
    backToDashboard: "Terug naar Dashboard",
    viewDraftSales: "Conceptverkopen bekijken",
    variantSelected: "variant geselecteerd",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "De vorige variantselectie is niet beschikbaar voor de nieuwe variant die is geselecteerd op basis van prijs. Wijzig uw selectie.",
    pleaseSupplyPositiveQuantityNumber:
      "Geef een postive hoeveelheid nummer op",
    lowStockFor: "Weinig voorraad voor",
    clearVariants: "Varianten wissen",
    pleaseEnterQuantity: "Vul a.u.b. Hoeveelheid in",
    picture: "Afbeelding",
    redemptionToken: "Inwisseltoken",
    token: "token",
    totalSpent: "Totaal uitgegeven",
    confirmPayment: "Bevestig betaling",
    hasPaymentBeenMade: "Is de betaling succesvol verwerkt?",
    enterTransactionReference:
      "Voer de transactiereferentie in waarmee u heeft betaald",
    pleaseSelectACustomer: "Selecteer een klant!",
    areYouSureToApplyDiscount: "Weet je zeker dat je korting wilt toepassen?",
    addYourBankAccountToEnableUssd:
      "Voeg uw bankrekening toe om Instant USSD-overboeking via uPay in te schakelen",
    totalAmountToPay: "Totaal te betalen bedrag",
    doYouWantToCancelTransaction: "Wilt u deze transactie annuleren?",
    savePrintBill: "Factuur opslaan/afdrukken",
    enterAmountCollectedForCustomer:
      "Voer het geïncasseerde bedrag in voor de klant",
    recordSale: "Platenverkoop",
    checkOutWith: "Afrekenen met",
    instantTransfer: "Directe overdracht",
    dialTheUSSDCode: "Kies de USSD-code",
    pleaseSelectABank: "Selecteer een bank",
    payWithUSSD: "Betaal met USSD",
    sendBillTo: " - Stuur rekening naar",
    waitingForUSSDTransfer: "Wachten op USSD-overdracht",
    percent: "procent",
    applyDiscount: "Korting toepassen",
    thisBillHasBeenSaved: "Deze rekening is opgeslagen",
    saveDraft: "Concept opslaan",
    pleaseTypeANameToIdentifyCustomer:
      "Typ een naam om de klant te identificeren",
    paymentDetails: "Betalingsdetails",
    basePrice: "Basisprijs",
    staff: "Personeel",
    subTotal: "Subtotaal",
    durationMonths: "Duur (maanden)",
    selectADuration: "Selecteer een duur",
    oneMonth: "1 maand",
    twoMonths: "2 maanden",
    threeMonths: "3 maanden",
    sixMonths: "6 maanden",
    twelveMonths: "12 maanden",
    eighteenMonths: "18 maanden",
    twentyFourMonths: "24 maanden",
    twoMonthsFree: "(2 maanden gratis)",
    threeMonthsFree: "(3 maanden gratis)",
    fiveMonthsFree: "(5 maanden gratis)",
    yourAccountHasBeen: "Uw account is",
    renewed: "vernieuwd",
    upgraded: "opgewaardeerd",
    successfully: "met succes",
    yourSubscription: "Uw abonnement",
    youAreCurrentlyEnrolledOnA: "U bent momenteel ingeschreven op een",
    pleaseChooseAPaymentOption: "Kies een betalingsoptie",
    planRenewal: "Verlenging abonnement",
    planUpgrade: "Upgrade plannen",
    pleaseSelectDurationToPayFor: "Selecteer de duur waarvoor u wilt betalen",
    staffAccounts: "Personeelsaccounts",
    ecommerce: "E-commerce",
    pleaseSelectAPlan: "Selecteer een abonnement",
    includeAddons: "Add-ons opnemen",
    viewTransactions: "Transacties bekijken",
    customerHasNoCompletedTansactions:
      "Klant heeft nog geen transacties voltooid",
    branch: "Tak",
    enterNumberOfEcommerceBranches: "Voer het aantal e-commercevestigingen in",
    enterNumberOfEcommerceBranchesToPay:
      "Voer het aantal e-commercevestigingen in waarvoor u wilt betalen",
    ecommerceIntegration: "E-commerce integratie",
    enterNumberOfBranches: "Voer het aantal filialen in",
    enterNumberOfAdditionalBranchesToPay:
      "Voer het aantal extra filialen in waarvoor u wilt betalen",
    enterNumberOfStaffs: "Voer het aantal staven in",
    enterNumberOfStaffsToPayFor:
      "Voer het aantal medewerkers in waarvoor u wilt betalen",
    discountApplies: "Korting van toepassing",
    starsOnThe: "sterren op de",
    offer: "aanbod",
    get: "Krijgen",
    moreStarsToRedeem: "meer sterren om in te wisselen",
    taxVat: "Belasting (BTW)",
    callCashierToCompletePayment: "Bel de kassier om de betaling te voltooien",
    receipt: "Ontvangst",
    dear: "Lieve",
    thankYouForYourOrderFindGoods:
      "Bedankt voor je bestelling. Gelieve de volgende goederen geleverd te vinden, zoals overeengekomen.",
    shippingNote: "Verzendingsbrief",
    enterShippingNote: "Voer verzendnota in",
    shippingAddress: "Afleveradres",
    enterShippingAddress: "Voer het verzendadres in",
    wellDoneYouAreDueToRedeem: "Goed gedaan! Je moet verzilveren",
    toGetYourRewardNextVisit:
      "om uw beloning te ontvangen bij uw volgende bezoek. Bedankt",
    pointsOnThe: "Punten op de",
    morePointsToRedeem: "meer punten om in te wisselen",
    showCode: "Toon code",
    toGetYourRewardOnNextVisit:
      "om uw beloning te ontvangen bij uw volgende bezoek. Bedankt",
    earn: "Verdienen",
    delivaryNote: "Leveringsnota",
    draftSales: "Conceptverkoop",
    startDate: "Startdatum",
    endDate: "Einddatum",
    orders: "Bestellingen",
    checkout: "uitchecken",
    noProductItem: "Geen productitem",
    printProductTag: "Producttag afdrukken",
    transactionReference: "Referentie-transactie",
    Cashier: "Kassa",
    Manager: "Manager",
    Owner: "Eigenaar",
    Admin: "beheerder",
    addPartners: "Partners toevoegen",
    addNewLoyaltyPartner: "Nieuwe loyaliteitspartner toevoegen",
    pleaseEnterCompanyName: "Voer a.u.b. bedrijfsnaam in",
    companyName: "Bedrijfsnaam",
    pleaseEnterCompanyRepName:
      "Voer de naam van de bedrijfsvertegenwoordiger in",
    companyRepName: "Naam van bedrijfsvertegenwoordiger",
    pleaseEnterCompanyRepEmail:
      "Voer het e-mailadres van de bedrijfsvertegenwoordiger in",
    companyRepEmail: "E-mail van bedrijfsvertegenwoordiger",
    pleaseEnterCompanyRepPhone:
      "Voer het telefoonnummer van de bedrijfsvertegenwoordiger in",
    companyRepPhone: "Telefoonnummer van bedrijfsvertegenwoordiger",
    addReward: "Beloning toevoegen",
    pleaseEnterRewardName: "Voer de naam van de beloning in",
    rewardName: "Naam van beloning",
    rewardQuantity: "Beloning Hoeveelheid",
    rewardDescription: "Beschrijving van de beloning",
    rewardType: "Beloningstype",
    pleaseEnterRewardType: "Voer het beloningstype in",
    pleaseEnterRewardQuantity: "Voer de beloningshoeveelheid in",
    pleaseEnterRewardDescription: "Voer een beloningsbeschrijving in",
    fineDining: "Lekker eten",
    luxuryFashion: "Luxe Mode",
    hotels: "Hotels",
    travel: "Reis",
    foodAndBeverage: "Eten en drinken",
    fashion: "Mode",
    health: "Gezondheid",
    furniture: "Meubilair",
    entertainment: "Amusement",
    automobile: "Auto",
    education: "Opleiding",
    beautyAndSpa: "Schoonheid en spa",
    staycation: "Staycation",
    events: "Evenementen",
    trips: "Reizen",
    oilAndGas: "Olie en gas",
    laundry: "De was",
    partnerCategory: "Partnercategorie",
    freeItem: "Gratis artikel",
  },
  Estonian: {
    cashier: "kassapidaja",
    manager: "juhataja",
    owner: "omanik",
    online: "võrgus",
    offline: "võrguühenduseta",
    changePassword: "Muuda salasõna",
    currentPasswordMessage: "Sisestage oma praegune parool",
    passwordMessage: "Palun sisesta oma salasõna",
    currentPassword: "Praegune salasõna",
    password: "parool",
    confirmPasswordMessage: "Palun kinnitage oma parool!",
    confirmPassword: "Kinnita salasõna",
    sendViaEmail: "Saada e -posti teel",
    sendViaWhatsapp: "Saada WhatsAppi kaudu",
    downloadPdf: "Laadige alla PDF",
    paid: "makstud",
    unpaid: "maksmata",
    partial: "osaline",
    closeInvoice: "Kas soovite arve sulgeda?",
    closeInvoiceConfirmation:
      "Arvet ei pruugi salvestada. Kas soovite sulgeda?",
    yes: "jah",
    no: "ei",
    invoice: "Arve",
    wasDeducted: "võeti maha",
    for: "eest",
    item: "Üksus",
    addProduct: "Lisa toode",
    paymentReference: "Makseviide",
    amountPaid: "Makstud summa",
    discountAmount: "Soodustuse summa",
    amountDue: "Tasumisele kuuluv summa",
    amount: "Kogus",
    dueDate: "Tähtaeg",
    paymentType: "Makse tüüp",
    card: "Kaart",
    cash: "Sularaha",
    bankTransfer: "Pangaülekanne",
    paymentMessage: "Maksesõnum",
    description: "Kirjeldus",
    sendReceipt: "Saada kviitung",
    delete: "Kustuta",
    save: "Salvesta",
    resend: "Saada uuesti",
    saveAndSend: "Salvesta",
    invoiceSaved: "Arve salvestatud!",
    selectPaymentMethod: "Palun valige makseviis!",
    selectCustomer: "Palun valige klient!",
    cartEmptyError:
      "Ostukorvi loend ei saa olla tühi, sulgege arve ja lisage toode ostukorvi!",
    subscriptionExpired:
      "Teie tellimus on aegunud ja teie konto on nüüd piiratud. Konto uuendamiseks klõpsake alloleval nupul",
    renew: "Uuenda",
    holdOn: "Oota",
    customerBank: "Kliendipank",
    cancel: "Tühista",
    selectACustomer: "Valige Klient",
    invoiceSuccessfulPdfError:
      "Arve koostamine õnnestus, kuid PDF -i loomine võtab tavapärasest kauem aega. Palun vaadake varsti tagasi.",
    downloadingInvoice: "Arve allalaadimine",
    downloadingReceipt: "Kviitungi allalaadimine",
    whatsappReceiptError:
      "Kviitungi saatmisel WhatsAppi kaudu ilmnes viga. Proovige uuesti.",
    receiptToWhatsapp: "Kviitung edastati WhatsAppi",
    thankYouForPatronage: "Tänan teid patroonimise eest",
    hereIsYourReceipt: "Siin on teie maksekviitung",
    errorSendingEmailReceipt:
      "Kviitungi e-posti teel saatmisel ilmnes viga. Proovige uuesti või võtke ühendust toega",
    receiptSentToEmail: "Kviitung saadeti kliendi e -posti aadressile",
    invoiceSentToEmail: "Arve on saadetud kliendi e -posti aadressile",
    invoiceSuccessWhatsappError:
      "Arve koostamine õnnestus, kuid WhatsAppi saatmisel ilmnes viga. Proovige arveloendis uuesti",
    invoiceSuccessfulEmailError:
      "Arve koostamine õnnestus, kuid e-kirjana saatmisel ilmnes viga. Proovige arvete loendist uuesti",
    invoiceSentToWhatsapp: "Arve edastati WhatsAppi",
    hello: "Tere",
    pleaseDownloadInvoice: "Palun laadige arve alla",
    pleaseDownloadReceipt: "Palun laadige kviitung alla",
    from: "alates",
    email: "E -post",
    upgrade: "Uuenda",
    youAreOnFreePlan: "Teil on tasuta plaan.",
    clickOn: "Kliki",
    yourPlanInFewSteps: " tellimuse mõne kiire sammuga.",
    to: "et",
    yourSubscriptionHasExpired:
      "Teie tellimus on aegunud ja teie konto on nüüd piiratud.",
    days: "päeva",
    yourSubscriptionExpiresIn: "Teie Loystari tellimus aegub",
    createAcount: "Kontot looma",
    signIn: "Logi sisse",
    continue: "Jätka",
    enterOtp: "Sisestage OTP PIN -kood",
    enterValidOtp: "Sisestage kehtiv PIN -kood",
    pin: "PIN -kood",
    tokenSentToMail: "Teie e -posti aadressile saadeti märk",
    passwordResetSuccessful: "Parooli lähtestamine õnnestus",
    somethingWentWrong: "Midagi läks valesti!",
    resetPassword: "Lähtesta parool",
    iHaveResetCode: "Mul on parooli lähtestamise kood",
    pleaseEnterEmail: "Palun sisestage oma e -posti aadress",
    aTokenWillBeSentToEmail: "Märk saadetakse teie e -posti aadressile",
    enterEmail: "Sisestage oma e -posti aadress",
    sendinYourToken: "Märgi saatmine ...",
    makeSureItMatchesPassword: "Veenduge, et see vastab teie uuele paroolile",
    pleaseChooseNewPassword: "Palun valige uus parool",
    chooseNewPassword: "Valige uus parool",
    enterNewPassword: "Kinnitamiseks sisestage uus parool",
    enterTokenSent: "Sisestage luba, millega teie kiri saadeti",
    resetToken: "Lähtesta Token",
    resettingPassword: "Parooli lähtestamine ...",
    signUp: "Registreeri",
    adminSignInWithEmail:
      " Administraator logib sisse e -postiga, töötajad aga kasutajanimega.",
    pleaseEnterEmailOrUsername:
      "Palun sisesta oma e -posti aadress või kasutajanimi",
    emailOrUsername: "Email või kasutajanimi",
    pleaseEnterPassword: "Palun sisestage parool",
    createAnAccount: "Kontot looma",
    forgotPassword: "Unustasid parooli?",
    enterPhoneNumber: "Sisestage telefoninumber",
    personalData: "Isiklikud andmed",
    validateConfirmationCOde: "Kinnitage kinnituskood",
    pleaseEnterFirstName: "Palun sisestage oma eesnimi",
    pleaseEnterPhoneNumber: "Palun sisestage oma telefoninumber",
    pleaseEnterLastName: "Palun sisestage oma perekonnanimi",
    pleaseEnterBusinessName: "Sisestage oma ettevõtte nimi",
    firstName: "Eesnimi",
    lastName: "Perekonnanimi",
    businessName: "ärinimi",
    previous: "Eelmine",
    next: "Edasi",
    pleaseSelectBusinessCategory: "Palun valige oma ettevõtte kategooria",
    pleaseEnterValidEmail: "Palun sisesta kehtiv email",
    pleaseEnterPostCode: "Palun sisestage postiindeks",
    postCode: "Postiindeks",
    phoneNumberInUse: "See telefoninumber on juba kasutusel!",
    emailInUse: "See e -kiri on juba kasutusel!",
    foodAndDrinks: "Toit ja joogid",
    salonAndBeauty: "Salong ja ilu",
    fashionAndAccessories: "Mood ja aksessuaarid",
    gymAndFitness: "Jõusaal ja fitness",
    travelAndHotel: "Reisimine ja hotell",
    homeAndGifts: "Kodu ja kingitused",
    washingAndCleaning: "Pesemine ja puhastamine",
    gadgetsAndElectronics: "Vidinad ja elektroonika",
    groceries: "Toidukaubad",
    others: "Teised",
    submit: "Esita",
    accountCreatedSuccessful: "Teie konto loomine õnnestus.",
    pleaseEnterAddress: "Palun sisestage oma aadress",
    addressLine1: "Aadressirida 1",
    addressLine2: "aadressi rida 2",
    choosePassword: "Vali Parool",
    passwordMustBe6Characters: "Parool peab olema vähemalt 6 tähemärki.",
    howDidYouHearLoystar: "Kuidas kuulsite Loystarist?",
    referralCode: "saatekood",
    byClickingTheButton: " Kui klõpsate alloleval nupul, nõustute",
    termsAndSevice: "Tingimused",
    wereCreatingAccount: "Loome teie konto",
    proceed: "Jätka",
    verificationCodeMustBe6: "Kinnituskood peab olema 6 -kohaline",
    pleaseEnter6DigitCode: "Palun sisestage 6 -kohaline kood",
    enterVerificationCode: "Sisesta kontrollkood",
    resendToken: "Saada Token uuesti",
    verify: "Kinnita",
    transactions: "Tehingud",
    todaySales: "Tänane müük",
    salesHistory: "Müügi ajalugu",
    supplyHistory: "Tarneajalugu",
    new: "Uus",
    invoices: "Arved",
    disbursements: "Väljamaksed",
    offlineSales: "Võrguühenduseta müük",
    products: "Tooted",
    customers: "Kliendid",
    multiLevelLoyalty: "Mitmetasandiline lojaalsus",
    loyaltyPrograms: "Lojaalsusprogrammid",
    members: "Liikmed",
    appStore: "Rakenduste pood",
    orderMenu: "Tellimismenüü",
    settings: "Seaded",
    staffAndBranches: "Staabid ja filiaalid",
    myAccount: "Minu konto",
    switchToSellMode: "Lülitu müügirežiimile",
    signOut: "Logi välja",
    getFreeSubscription: "Hankige tasuta tellimus",
    onlyNumbersAllowed: "Lubatud on ainult numbrid",
    yourAccountMustBe10Digits: "teie kontonumber peab olema 10 -kohaline",
    yourBvnMustBe11: "teie BVN peab olema 11 -kohaline number",
    pleaseSelectBank: "Palun valige oma pank",
    selectYourBank: "Valige oma pank",
    enterBankAccountNumber: "Sisestage pangakonto number",
    enterBvnNumber: "Sisestage oma BVN",
    connectBankAccount: "Ühendage pangakonto",
    passwordResetSuccessfulAndSignOut:
      "Teie parool on edukalt lähtestatud. Uuesti sisselogimiseks klõpsake alloleval nupul Logi välja",
    enterCurrentPassword: "Sisestage praegune parool",
    pleaseEnterCurrentPassword: "Palun sisestage praegune parool",
    phoneNumber: "Telefoninumber",
    sex: "Seks",
    dateOfBirth: "Sünnikuupäev",
    state: "Osariik",
    country: "Riik",
    loyaltyId: "Lojaalsuse ID",
    createdAt: "Loodud kell",
    noOfTransactionsMade: "Tehtud tehingute arv",
    yourDownloadWillStart: "Teie allalaadimine algab hetke pärast",
    exportCustomers: "Eksportige kliente",
    youhaveSuccessfullyToppedUp: "Teie SMS -ühikute täiendamine õnnestus.",
    youNowHave: "Sul on nüüd",
    smsUnits: "SMS -ühikud",
    enterNumberOfSmsUnits: "Sisestage SMS -ühikute arv, mida kavatsete osta",
    pleaseEnterANumericValue: "Sisestage arvväärtus",
    pay: "Maksma",
    accountEditedSuccessfully: "Teie konto muutmine õnnestus.",
    youAeCurrentlyOn: "Olete praegu sisse lülitatud",
    plan: "Plaani",
    userData: "Kasutaja andmed",
    businessData: "ÄRIANDMED",
    businessCategory: "Ärikategooria",
    pleaseSelectCurrency: "Valige oma valuuta",
    selectYourCurrency: "Valige oma valuuta",
    purchaseMoreSmsUnits: "Ostke rohkem SMS -ühikuid alloleva vormi kaudu",
    youHave: "Sul on",
    atLeast4SmsUnitsRrequired:
      "Kontrollimiseks on vaja vähemalt 4 sms ühikut, palun lisage!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Palun kinnitage oma pangakonto, et see vastaks „Tunne oma klienti” (KYC) nõuetele. See võimaldab teil koguda makseid USSD või kohese ülekande kaudu, saada klientidelt tellimusi ja töödelda tehingute arveldamist. Kohaldatav ainult Nigeeria kaupmeestele. Alustamiseks vajutage allolevat nuppu.",
    reConnectBankAccount: "Ühendage pangakonto uuesti",
    accountName: "Kasutaja nimi",
    accountNumber: "Konto number",
    bankName: "Panga nimi",
    verified: "Kinnitatud",
    accountDetails: "Konto üksikasjad",
    kycBankAccount: "KYC",
    createTier: "Loo tase",
    fileUploadSuccessful: "faili üleslaadimine õnnestus",
    fileUploadFailed: "faili üleslaadimine ebaõnnestus",
    createLoyaltyProgram: "Looge püsikliendiprogramm",
    createLoyalty: "Loo lojaalsus",
    name: "Nimi",
    loyaltyArtwork: "Lojaalsuskunst",
    clickToUpload: "Laadimiseks klõpsake",
    amountToPointsRatio: "Summa ja punktide suhe",
    points: "Punktid",
    recommendedAmountToPointRatio:
      "Soovitatav: ₦ 1 kuni 1 punkt. st iga 1 kulutatud Naira eest saavad teie kliendid 1 punkti",
    pleaseTypeIn: "Palun sisestage",
    toDeleteLoyalty: "selle lojaalsuse kustutamiseks",
    deleteLoyalty: "Lojaalsuse kustutamine",
    toConfirm: "kinnitada",
    edit: "Muuda",
    pointsAwardedSuccessfully: "Punktid on edukalt antud.",
    enterPointValueToAward:
      "Sisestage punktiväärtus, mida soovite klientidele autasustada",
    award: "Auhind",
    awardPointValuesToCustomer: "Auhinnapunkti väärtus kliendile",
    enrollMembersToLoyalty: "Registreerige liikmed lojaalsusesse",
    awardPoints: "Auhinnapunktid",
    enroll: "Registreeri",
    home: "Kodu",
    loyalty: "Lojaalsus",
    enrollCustomers: "Registreerige kliente",
    selected: "Valitud",
    customer: "Klient",
    loyaltyActivationSuccessful: "Lojaalsuse aktiveerimine on edukas.",
    loyaltyDeactivationSuccessful: "Lojaalsuse desaktiveerimine on edukas.",
    viewTier: "Kuva tase",
    deactivate: "Deaktiveeri",
    activate: "Aktiveeri",
    actions: "Toimingud",
    nameOfLoyalty: "Lojaalsuse nimi",
    loyaltyStatus: "Lojaalsuse staatus",
    numberOfTiers: "Tasemete arv",
    createdOn: "Loodud",
    createATier: "Loo tasand",
    stopCreatingTierConfirmation: "Kas soovite astme loomise lõpetada?",
    stopEditingTierConfirmation: "Kas soovite selle astme muutmise lõpetada?",
    nameOfTier: "Taseme nimi",
    minimumSpendingTarget: "Minimaalne kulueesmärk",
    maximumSpendingTarget: "Maksimaalne kulueesmärk",
    minimumSpendingTargetRequired: "nõutakse minimaalset kulutuseesmärki",
    maximumSpendingTargetRequired: "nõutakse maksimaalset kulutuseesmärki",
    rewardSponsor: "Preemia sponsor",
    pleaseChooseARewardSponsor: "Palun vali preemia sponsor",
    self: "Ise",
    partner: "Partner",
    rewardPartner: "Preemiapartner",
    pleaseSelectRewardPartner: "Palun valige oma preemiapartner",
    rewards: "Preemiad",
    pleaseSelectAReward: "Palun valige preemia",
    instructionsOnRedeemingReward:
      "Juhised selle kohta, kuidas klient peaks preemia lunastama",
    pleaseFillInThisField: "Palun täitke see väli!",
    toDeleteThisTier: " selle astme kustutamiseks",
    deleteTier: "Kustuta tase",
    viewMembers: "Kuva liikmed",
    membersEnrolled: "Registreeritud liikmed",
    instruction: "Juhend",
    membersIn: "Liikmed sisse",
    availableTiersInLoyalty: "Lojaalsusprogrammis saadaolevad tasemed",
    tiers: "Tasemed",
    totalTier: "KOKKU TIER",
    availableLoyaltyProgramme: "Saadaval püsikliendiprogramm",
    totalLoyalties: "LOJALITEEDI KOKKU",
    memberDetails: "Liikme andmed",
    nameOfCustomer: "Kliendi nimi",
    address: "Aadress",
    allEnrolledMembers: "Kõik registreeritud liikmed",
    thisIsToWishYouHappyBirthday:
      "See on soovida teile palju õnne sünnipäevaks ja õitsvat elu. Tänan teid Loysteri eest. Head pidustusi!",
    inputAnOfferPlease: "Palun sisestage pakkumine",
    pleaseSelectTheInsertPoint:
      "Valige sõnumis sisestuspunkt ja klõpsake uuesti",
    birthdayOfferAndMessage: "Sünnipäevapakkumine ja sõnum",
    birthdayOffer: "Sünnipäeva pakkumine",
    birthdayMessage: "Sünnipäeva sõnum",
    noOfferFound: "Pakkumist ei leitud",
    settingABirthdayOffer:
      "Sünnipäevapakkumise seadmine võimaldab klientidel selle pakkumise oma sünnipäeval SMS -i teel saada",
    createOffer: "Loo pakkumine",
    whatIsTheOffer: "Mis on pakkumine?",
    editMessage: "Muuda sõnumit",
    insert: "Sisesta",
    theNameOfCustomerInserted: "Siia lisatakse kliendi nimi",
    theBirtdayOfferInserted: "Sünnipäevapakkumine sisestatakse siia",
    youSuccessfullyAddedNewBranch: "Olete uue haru edukalt lisanud!",
    addNewBranch: "Lisa uus filiaal",
    addBranch: "Lisa filiaal",
    additionalBranchWillIncur: "Tekiks lisaharu",
    perBranch: "haru kohta",
    ecommerceBranchCosts: "Poodharu kulud",
    pleaseEnterBranchName: "Palun sisestage filiaali nimi",
    pleaseEnterBranchAddress1: "Palun sisestage filiaali aadressirida 1",
    enterBranchAddress1: "Sisestage filiaali aadressirida 1",
    enterBranchAddress2: "Sisestage filiaali aadressirida 1",
    pleaseEnterBranchAddress2: "Palun sisestage filiaali aadressirida 2",
    enterBranchName: "Sisestage filiaali nimi",
    successfullyAddedStaff: "Olete edukalt lisanud uue töötaja!",
    addNewStaff: "Lisa uus personal",
    addStaff: "Lisa töötajad",
    additionalStaffWillIncur: "Kaasataks lisatööjõudu",
    perStaff: "personali kohta.",
    pleaseEnterStaffEmail: "Palun sisestage töötajate e -posti aadress",
    pleaseEnterStaffUsername: "Palun sisestage personali kasutajanimi",
    pleaseEnterStaffPassword: "Palun sisestage personali parool",
    pleaseSelectStaffRole: "Palun valige personali roll",
    selectStaffRole: "Valige personali roll",
    enterStaffEmail: "Sisestage personali e -posti aadress",
    enterStaffUsername: "Sisestage personali kasutajanimi",
    enterStaffPassword: "Sisestage personali parool",
    spacesNotAllowedInUsername: "tühikud pole kasutajanimes lubatud",
    admin: "Administraator",
    pleaseSelectBusinessToAttachStaff:
      "Palun valige ettevõtte kaasamiseks personal",
    searchForBranchToAttachStaff: "Otsige filiaali töötajate kaasamiseks",
    username: "Kasutajanimi",
    role: "Roll",
    areYouSureToDeleteThis: "Kas soovite selle kindlasti kustutada",
    branches: "Filiaalid",
    upgradeYourPlan: "Uuendage oma plaani.",
    add: "LISAMA",
    addNew: "Lisa uus",
    customerWithEmailAlreadyExists:
      "E -posti/telefoninumbriga klient on juba olemas!",
    successfullyAddedNewCustomer: "Olete edukalt lisanud uue kliendi!",
    addCustomer: "Lisa klient",
    pleaseEnterCustomerFirstName: "Palun sisestage kliendi eesnimi",
    pleaseEnterCustomerLastName: "Palun sisestage kliendi perekonnanimi",
    pleaseEnterCustomerPhoneNumber: "Palun sisestage kliendi telefoninumber",
    pleaseEnterCustomerEmail: "Palun sisestage kliendi e -posti aadress",
    pleaseEnterCustomerAddressLine: "Palun sisestage kliendi aadressirida",
    pleaseEnterCustomerOtherAddress: "Palun sisestage kliendi teine aadress",
    pleaseSelectCustomerGender: "Palun valige kliendi sugu",
    gender: "Sugu",
    male: "Mees",
    female: "Naine",
    bank: "Pank",
    selectBank: "Valige Pank",
    stateOrRegionOrProvince: "Osariik/piirkond/provints",
    customerNotes: "Kliendi märkused",
    sendSms: "Saada SMS",
    editCustomer: "Kliendi muutmine",
    redeemReward: "Lunasta preemia",
    issueLoyaltyCard: "Väljastage püsikliendikaart",
    deleteCustomer: "Kustuta klient",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Olete edukalt lojaalsuse liikmesuse ID määranud",
    noMembershipIdAvailable:
      "Liikmesuse ID -d pole saadaval. Palun võtke meiega ühendust aadressil hello@loystar.co",
    sendEmail: "Saada email",
    membershipPoints: "Liikmelisuse punktid",
    customerDetails: "Kliendi andmed",
    close: "Sulge",
    loyaltyBalance: "Lojaalsuse tasakaal",
    pointsBalance: "Punktide tasakaal",
    starBalance: "Tähe tasakaal",
    requiredPoints: "Nõutavad punktid",
    requiredStars: "Nõutavad tähed",
    reddemCode: "Lunastada koodi",
    toDeleteThisCustomer: "selle kliendi kustutamiseks",
    customerHasBeenDeletedSuccessfully: "Kliendi kustutamine õnnestus!",
    customerWithPhoneAlreadyExists: "Telefoninumbriga klient on juba olemas",
    customerWasSuccessfullyEdited: "Kliendi redigeerimine õnnestus",
    anErrorOccured: "Ilmnes viga",
    phoneNumberIncludeCountry: "Telefoninumber (lisage riigikood)",
    yourFileQueuedForUpload:
      "Teie fail on üleslaadimiseks järjekorda pandud. Värskendage lehte mõne sekundi pärast.",
    thereWasAnErrorPerformingOperation: "Toimingu tegemisel ilmnes viga!",
    pleaseSelectFile: "Palun valige fail!",
    oopsOnlyCsvFilesAllowed:
      "Oih! Lubatud on ainult CSV -failid. Palun laadige üles .csv -fail.",
    fileShouldBeSmallerThan5Mb:
      "Fail peab olema väiksem kui 5 MB. Kui teil on suurem fail, saatke e -kiri support@loystar.co. Aitäh",
    customerFirstNameIsRequired: "reas on nõutav kliendi eesnimi",
    customerPhoneNumberIsRequired: "reas on nõutav kliendi telefoninumber",
    importCustomers: "Klientide importimine",
    upload: "Laadi üles",
    clickIconToDownloadCsv:
      "CSV -failimalli allalaadimiseks klõpsake seda ikooni.",
    getGoogleSheetVersion: "Hankige Google'i lehe versioon",
    clickOrDragFileToUpload:
      "Üleslaadimiseks klõpsake või lohistage fail sellesse piirkonda",
    missingOrInvalidColumnHeader:
      "Veerupäis puudub või on vale. Palun järgige malli vormi. Aitäh.",
    youHaveImported: "Olete importinud",
    youSuccessfullyRedeemedReward: "Olete oma preemia edukalt lunastanud!",
    sixDigitCode: "Kuuekohaline kood",
    pleaseEnterCustomerRewardCode: "Palun sisestage kliendi preemiakood",
    enterRewardCode: "Sisestage preemiakood. (Preemiakood on tõstutundlik)",
    selectLoyaltyProgram: "Valige lojaalsusprogramm",
    stamps: "Margid",
    smsUnitsLow: "SMS -ühikute arv on madal",
    pleaseTopUpSmsUnits: "Palun täiendage SMS -ühikuid",
    smsSuccessfullySentForDelivery: "SMS saadeti edukalt kohale!",
    sendSmsTo: "Saada SMS numbrile",
    allCustomers: "Kõik kliendid",
    unitsAvailable: "Saadaval ühikud",
    pleaseTypeInTheMessage: "Palun sisestage sõnum",
    message: "Sõnum",
    charactersRemaining: "tähemärki alles",
    avoidUseOfSpecialCharacters:
      "Vältige SMS -ühikute säästmiseks erimärkide ja emotikonide kasutamist.",
    note: "Märge",
    errorFetchingCustomersMultilevelDetail:
      "Viga klientide mitmetasandiliste andmete toomisel",
    search: "Otsing",
    reset: "Lähtesta",
    importCustomer: "Impordi klient",
    addNewCustomer: "Lisa uus klient",
    sendSmsBroadcast: "Saada SMS -eetrisse",
    totalCustomers: "KLIENDI KOKKU",
    disbursementDetails: "Väljamakse üksikasjad",
    paidBy: "Maksis",
    disbursed: "Välja makstud",
    bankAccountName: "Pangakonto nimi",
    bankAccountNumber: "Pangakonto number",
    transferInitiated: "Ülekanne algatatud",
    transferCompleted: "Ülekanne on lõpetatud",
    pleaseEnterAValid: "Sisestage kehtiv",
    begin: "alustada",
    end: "lõpp",
    date: "kuupäev",
    paymentDate: "Makse kuupäev",
    selectDisbursementDuration: "Valige väljamakse kestus",
    totalSettled: "Kokku arveldatud",
    totalUnsettled: "Kokku lahendamata",
    toDeleteThisSale: "selle müügi kustutamiseks",
    draftSaleDeletedSuccessfully: "Mustandimüük on edukalt kustutatud!",
    deleteSale: "Kustuta müük",
    pleaseTypeInYourTotal: "Kustutamise kinnitamiseks sisestage oma kogusumma",
    billDetails: "Arve üksikasjad",
    printBill: "Prindi arve",
    servedBy: "Teenindaja",
    total: "Kokku",
    createdDate: "Loomiskuupäev",
    createdTime: "Loodud aeg",
    status: "Olek",
    loginSuccessful: "Sisselogimine õnnestus",
    pleaseWaitWhileWeConnectAccount:
      "Palun oodake, kuni me teie konto ühendame",
    connectionFailedTryAgain: "Ühendus nurjus. Palun proovi uuesti.",
    connectionSuccessful: "Ühendus õnnestus",
    viewDetails: "Vaata detaile",
    enable: "Luba",
    free: "Tasuta",
    cost: "Maksumus",
    visitWebsite: "Külastage veebisaiti",
    pleaseUpgradeYourPlanToPro:
      "Selle rakenduse lubamiseks uuendage oma plaan Pro Plus -ile",
    connectYourBankAccount: "Maksete vastuvõtmiseks ühendage oma pangakonto.",
    disable: "Keela",
    enableApp: "Luba rakendus",
    addNewProductToInvoice: "Lisage arvele uus toode",
    toDeleteThisInvoice: "selle arve kustutamiseks",
    invoiceDeletedSuccessfully: "Arve on edukalt kustutatud!",
    deleteInvoice: "Kustuta arve",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Olete arveldussõnumi edukalt lisanud!",
    pleaseEnterPaymentInstructions: "Palun sisestage maksejuhised",
    invoiceId: "Arve ID",
    paidDate: "Tasutud kuupäev",
    reference: "Viide",
    productAdded: "Toode lisatud",
    productOutOfStock: "Toode on otsas. Palun varuge uuesti.",
    totalInvoices: "ARVED KOKKU",
    totalPaidInvoices: "MAKSTUD ARVED KOKKU",
    totalUnpaidInvoices: "TASUMATUD ARVED KOKKU",
    loyaltyProgramDeleted: "Lojaalsusprogramm kustutatud",
    thisLoyaltyProgramDeletedSuccessfully:
      "See lojaalsusprogramm on edukalt kustutatud",
    thisLoyaltyProgramEditedSuccessfully:
      "Seda püsikliendiprogrammi on edukalt muudetud",
    loyaltyProgramAddedSuccessfully: "Lojaalsusprogrammi lisamine õnnestus",
    loyaltyProgramEdited: "Lojaalsusprogrammi muudetud",
    loyaltyProgramAdded: "Lojaalsusprogramm lisatud",
    loyaltyDetails: "Lojaalsuse üksikasjad",
    doYouWantToCloseDialog: "Kas soovite selle dialoogi sulgeda?",
    pleaseEnterLoyaltyName: "Palun sisestage oma lojaalsuse nimi",
    programType: "Programmi tüüp",
    pleaseSelectProgramType: "Palun valige programmi tüüp",
    simplePoints: "Lihtsad punktid",
    stampsProgram: "Templite programm",
    threshold: "Lävi",
    pleaseEnterLoyaltyThreshold: "Palun sisestage lojaalsuslävi",
    reward: "Auhind",
    pleaseEnterLoyaltyReward: "Palun sisestage lojaalsuse tasu",
    totalUserPoints: "Kasutajapunktid kokku",
    totalUserStamps: "Kasutaja templid kokku",
    spendingTarget: "Kulude sihtmärk",
    spendingTargetHint1:
      "Kulueesmärk on see, kui palju klient peab preemia teenimiseks kulutama. 1 valuuta väärtus = 1 punkt.",
    spendingTargetHint2:
      "Templite eesmärk on see, kui palju marke peab klient tasu saamiseks koguma. nt. 5",
    addNewLoyaltyProgram: "Lisage uus püsikliendiprogramm",
    addLoyaltyProgram: "Lisage püsikliendiprogramm",
    loyaltyProgramType: "Lojaalsusprogrammi tüüp",
    pleaseSelectLoyaltyProgramType: "Valige püsikliendiprogrammi tüüp",
    nameOfProgram: "Programmi nimi",
    pleaseEnterProgramName: "Palun sisestage programmi nimi",
    whatIsTheReward: "Mis on tasu?",
    egNextPurchaseIsFree: "Nt. Järgmine ost on tasuta",
    customerName: "Kliendi nimi",
    guestCustomer: "Külaline klient",
    orderConfirmedSuccessfully: "Tellimus õnnestus kinnitada",
    orderCancelledSuccessfully: "Tellimus tühistati",
    confirmOrder: "Kinnita tellimus",
    cancelOrder: "Tühista tellimus",
    allOrders: "Kõik tellimused",
    totalOrders: "TELLIMUSED KOKKU",
    doYouWantToAcceptOrder: "Kas soovite selle tellimuse vastu võtta?",
    doYouWantToCancelOrder: "Kas soovite selle tellimuse tühistada?",
    orderDetails: "Tellimuse üksikasjad",
    orderCreatedAt: "Tellimus loodud aadressil",
    supplier: "Tarnija",
    productName: "tootenimi",
    quantity: "Kogus",
    unitPrice: "Ühikuhind",
    receivedBy: "Saadud",
    reportFrom: "Raport alates",
    totalSupplies: "Tarneid kokku",
    allRightsReserved: "Kõik õigused kaitstud",
    toDeleteThisTransaction: "selle tehingu kustutamiseks",
    transactionDeletedSuccessfully:
      "Tehing on edukalt kustutatud. Varud on tagastatud varudesse.",
    deleteTransaction: "Kustuta tehing",
    transactionDetails: "Tehingu üksikasjad",
    printReceipt: "Prindi kviitung",
    channel: "Kanal",
    discount: "Soodustus",
    profit: "Kasum",
    discountedSales: "Soodusmüük",
    errorFetchingRecord: "Viga kirje toomisel",
    exportTransactions: "Tehingute eksportimine",
    errorFetchingSalesRecord: "Viga ekspordi müügikirje toomisel.",
    totalSellingPrice: "Müügihind kokku",
    totalCostPrice: "Kuluhind kokku",
    appliedDiscount: "Rakendatud allahindlus",
    noOfItems: "Üksuste arv",
    sales: "Müük",
    export: "Eksport",
    totalProfit: "Kogukasum",
    totalBalanceInPeriod: "Kogu saldo perioodil",
    allTimeSales: "Kogu aeg müük",
    records: "Rekordid",
    todaysSales: "Tänane müük",
    transaction: "tehing",
    youHaveExceededTotalNumberOfProducts:
      "Olete oma plaanis lubatud toodete koguarvu ületanud. Kõrgema limiidi nautimiseks uuendage oma plaani.",
    youNeedToHaveLoyaltyProgram:
      "Selle funktsiooni kasutamiseks peab teil olema püsikliendiprogramm!",
    price: "Hind",
    category: "Kategooria",
    stockTracking: "Varude jälgimine",
    stockCount: "Varude arv",
    taxed: "Maksustatud",
    originalPrice: "Algne hind",
    costPrice: "Sisseostuhind",
    unit: "Üksus",
    productImage: "Toote pilt",
    taxRate: "Maksumäär",
    taxType: "Maksuliik",
    trackInventory: "Lao jälgimine",
    variants: "Variandid",
    hasVariants: "On Variante",
    importProduct: "Impordi toode",
    exportProducts: "Toodete eksport",
    addNewProduct: "Lisa uus toode",
    viewCategory: "Kuva kategooria",
    viewSuppliers: "Vaata tarnijaid",
    receiveInventory: "Inventuuri vastuvõtmine",
    printAllProductsTag: "Prindi kõikide toodete silt",
    deleteAll: "Kustutada kõik",
    totalProducts: "TOOTEID KOKKU",
    youveSuccessfullyAddedANewCategory: "Olete edukalt lisanud uue kategooria",
    addNewCategory: "Lisa uus kategooria",
    addCategory: "Lisa kategooria",
    categoryName: "Kategooria nimi",
    pleaseEnterCategoryName: "Sisestage kategooria nimi",
    stampsTarget: "Markide sihtmärk",
    sendInventory: "Inventuuri saatmine",
    productDetails: "Toote üksikasjad",
    youveSuccessfullyEditedThisCategory:
      "Olete selle kategooria edukalt muutnud",
    update: "Värskenda",
    categoryList: "Kategooriate loend",
    categories: "Kategooriad",
    enterQuantityToUpdate: "Sisestage värskendatav kogus",
    inventorySentSuccessfully: "Inventari saatmine õnnestus!",
    updateInventory: "Varude värskendamine",
    currentQuantity: "Praegune kogus",
    pleaseEnterQuantityToAdd: "Sisestage kogus, mida soovite lisada",
    pleaseSelectABranch: "Palun valige filiaal",
    searchForBranch: "Otsi filiaali",
    youCantSendMoreThanStock: "Te ei saa saata rohkem, kui teil on laos",
    send: "Saada",
    pleaseEnterQuantityToSend: "Sisestage kogus, mida soovite saata",
    productNameIsRequiredOnRow: "tootenimi on reas kohustuslik",
    productCategoryIsRequiredOnRow: "real on nõutav tootekategooria",
    productPriceIsRequiredOnRow: "toote hind on reas nõutav",
    productUnitIsRequiredOnRow: "real on nõutav tooteühik",
    productQuantityIsRequiredOnRow: "real on nõutav toote kogus",
    productVariantsRequireTracking: "tootevariandid vajavad jälgimist!",
    pleaseAddVariantClickButton: "Palun lisage variant, klõpsates nuppu Lisa!",
    totalVariantsMoreThanSelectedQuantity:
      "Variante kokku on rohkem kui valitud toote kogus, Pls vähendab variandi kogust",
    productEditedSuccessfully: "Toote redigeerimine õnnestus!",
    fileTooLargeLessThan4Mb:
      "Faili suurus on liiga suur! Faili suurus peaks olema väiksem kui 4 MB.",
    suchVariantAlreadyExist: "Selline variant on juba olemas",
    addVariants: "Variantide lisamine",
    editProduct: "Muuda toodet",
    pleaseEnterProductName: "Sisestage toote nimi",
    pleaseEnterProductPrice: "Palun sisestage toote hind",
    pleaseEnterProductOriginalPrice: "Palun sisestage toote alghind",
    productDescription: "Tootekirjeldus",
    selectProductCategory: "Valige tootekategooria",
    pleaseSelectProductCategory: "Palun valige tootekategooria",
    productCostPrice: "Toote maksumus Hind",
    productSellingPrice: "Toote müügihind",
    productOriginalPrice: "Toote alghind",
    maxFileSizeAllowedIs4Mb: "Max Faili lubatud suurus on 4 MB",
    productsWithPicturesArePublished:
      "Piltidega tooted avaldatakse tellimuste vastuvõtmiseks Discoveris",
    shouldThisProductBeTracked: "Kas seda toodet tuleks jälgida?",
    pleaseSelectStockUnit: "Palun valige varuühik",
    stockUnit: "Varuühik",
    bag: "KOTT",
    bottle: "PUDEL",
    bunch: "BUNCH",
    can: "SAAB",
    carton: "KARP",
    crate: "CRATE",
    cup: "CUP",
    dozen: "KÜMNE",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAMM",
    litre: "LIITER",
    pack: "PAKEND",
    pair: "PAAR",
    pieces: "TÜKKID",
    plate: "PLAAT",
    tonne: "TONNE (MT)",
    uNIT: "ÜHIK",
    yard: "ÕU",
    pleaseEnterProductQuantity: "Palun sisestage toote kogus",
    productQuantity: "Toote kogus",
    isThisProductTaxed: "Kas see toode on maksustatud?",
    selectTaxType: "Valige Maksuliik",
    pleaseSelectTaxType: "Valige maksuliik",
    progressive: "Progressiivne",
    proportional: "Proportsionaalne",
    pleaseEnterProductTaxRate: "Sisestage toote maksumäär",
    doesProductHaveVariants: "Kas sellel tootel on variante?",
    type: "Tüüp",
    value: "Väärtus",
    pleaseEnterVariantType: "Sisestage variandi tüüp",
    pleaseEnterVariantValue: "Sisestage variandi väärtus",
    pleaseEnterVariantPrice: "Palun sisesta variandi hind",
    pleaseEnterVariantQuantity: "Sisestage variandi kogus",
    productDeletedSuccessfully: "Toode on edukalt kustutatud!",
    categoryDeletedSuccessfully: "Kategooria on edukalt kustutatud!",
    toDeleteThisProduct: "selle toote kustutamiseks",
    invalidProductQuantity: "Vale toote kogus",
    quantityAddedIsOverStock: "Lisatav kogus ületab teie laos oleva koguse.",
    itemInventoryNotTracked: "Kaubaartiklit ei jälgita",
    addBulkQuantity: "Lisage hulgikogus",
    enterBulkQuantity: "Sisestage lahtiselt kogus",
    pleaseEnterBulkQuantity: "Sisestage hulgikogus",
    youveSuccessfullyAddedANewProduct: "Olete edukalt lisanud uue toote!",
    pleaseEnterProductSellingPrice: "Palun sisestage toote müügihind",
    doYouWantToTrackProductStock: "Kas soovite jälgida toodete laoseisu?",
    sellingPrice: "Müügihind",
    setProductExpiryReminder: "Määrake toote aegumise meeldetuletus",
    productExpiryDate: "Toote aegumiskuupäev",
    startRemindingFrom: "Alustage meeldetuletamist",
    productSuppliesAddedSuccessfully: "Olete edukalt tootevarusid lisanud.",
    addProductSupplies: "Lisage tootevarusid",
    pleaseSelectSupplier: "Palun valige tarnija",
    nameOfProduct: "Toote nimetus",
    pleaseSelectProduct: "Palun valige toode",
    productVariant: "Toote variant",
    pleaseSelectAVariant: "Palun valige variant",
    pleaseEnterUnitPrice: "Palun sisesta ühiku hind",
    businessBranch: "Äriharu",
    pleaseSelectBranch: "Palun valige filiaal",
    youveSuccessfullyAddedANewSupplier: "Olete edukalt lisanud uue tarnija",
    addSupplier: "Lisa tarnija",
    pleaseEnterSupplierEmail: "Palun sisestage tarnija e -posti aadress",
    pleaseAddADescription: "Palun lisage kirjeldus",
    anErrorOccuredProductsDeleted:
      "Toimingu tegemisel ilmnes viga. Pange tähele, et mõned tooted võisid selle protsessi käigus kustutada",
    bulkDelete: "Hulgi kustutamine",
    youAreAboutToDelete: "Olete kustutamas",
    product: "Toode",
    isDueToRedeem: "tuleb lunastada",
    aReward: "tasu",
    pleaseSelectCustomerToReeemReward:
      "Palun valige preemia lunastamiseks klient.",
    youHaveNoLoyaltyProgramRunning:
      "Teil ei tööta ühtegi aktiivset püsikliendiprogrammi",
    customerHhasNoPointsInLoyaltyProgram:
      "Kliendil pole selles lojaalsusprogrammis punkte",
    proceedWithPayment: "Kas jätkata maksega?",
    youAreAboutToPayForTransactionUsingPoints:
      "Maksate tehingu eest punktide abil.",
    customerHas: "Kliendil on",
    worth: "väärt",
    andIsNotSufficientToPayForTransaction:
      "ja sellest ei piisa selle tehingu eest tasumiseks. Kas nad soovivad saldo lisada mõne muu makseviisi abil?",
    addCustomerLoyalty: "Lisage kliendilojaalsus",
    pleaseAddCustomerFirst: "Palun lisage või valige kõigepealt klient.",
    pleaseWaitWhileWeGetReady: "Palun oodake, kuni me valmistume",
    lowStock: "Madal varu",
    pleaseEnterAmountTendered: "Palun sisestage pakutud summa",
    areYouSureToBookSaleOffline:
      "Kas olete kindel, et soovite selle müügi võrguühenduseta broneerida?",
    saleWouldBeBookedAutomatically:
      "Müük broneeritakse automaatselt, kui lülitate sisse oma Interneti",
    offlineSalesBookingCancelled: "Võrguühenduseta müügibroneering tühistati",
    covid19Message:
      "COVID19: leviku peatamiseks peske käed seebiga või desinfitseerige. Olge alati turvaline",
    saleSuccessfullyRecorded: "Müük salvestatud!",
    sendReceiptToEmail: "Saada kviitung meilile",
    sendThankYouSms: "Saada tänusõnum",
    sendShippingDetails: "Saatmise üksikasjade saatmine",
    addLoyalty: "Lisage lojaalsust",
    searchCustomerNameOrNumber: "Otsige kliendi nime või numbrit",
    clickTheSearchCustomerBox:
      "Klõpsake nuppu Otsi kliendikasti ja skaneeri kaarti",
    enterProductPrice: "Sisestage toote hind",
    enterPriceFor: "Sisestage hind hinnale",
    searchForProduct: "Otsi toodet",
    all: "Kõik",
    backToDashboard: "Tagasi juhtpaneelile",
    viewDraftSales: "Vaadake mustandmüüki",
    variantSelected: "variant valitud",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Eelmine variandivalik ei ole hinna alusel valitud uue variandi jaoks saadaval, palun muutke oma valikut.",
    pleaseSupplyPositiveQuantityNumber: "Palun sisestage postikoguse number",
    lowStockFor: "Laos madal",
    clearVariants: "Selged variandid",
    pleaseEnterQuantity: "Palun sisestage Kogus",
    picture: "Pilt",
    redemptionToken: "Lunastusluba",
    token: "Märk",
    totalSpent: "Kokku kulutatud",
    confirmPayment: "Kinnitage makse",
    hasPaymentBeenMade: "Kas makse on edukalt töödeldud?",
    enterTransactionReference: "Sisestage tehingu viide, millega maksite",
    pleaseSelectACustomer: "Palun valige klient!",
    areYouSureToApplyDiscount:
      "Kas olete kindel, et soovite allahindlust rakendada?",
    addYourBankAccountToEnableUssd:
      "Lisage oma pangakonto, et lubada USP -i vahetu ülekanne uPay kaudu",
    totalAmountToPay: "Makstav kogusumma",
    doYouWantToCancelTransaction: "Kas soovite selle tehingu tühistada?",
    savePrintBill: "Salvesta/prindi arve",
    enterAmountCollectedForCustomer: "Sisestage kliendile kogutud summa",
    recordSale: "Rekordimüük",
    checkOutWith: "Uurige koos",
    instantTransfer: "Kohene ülekanne",
    dialTheUSSDCode: "Sisestage USSD -kood",
    pleaseSelectABank: "Palun valige pank",
    payWithUSSD: "Makske USSD -ga",
    sendBillTo: " - Saatke arve aadressile",
    waitingForUSSDTransfer: "USSD ülekande ootamine",
    percent: "Protsent",
    applyDiscount: "Rakenda allahindlust",
    thisBillHasBeenSaved: "See arve on salvestatud",
    saveDraft: "Salvesta mustand",
    pleaseTypeANameToIdentifyCustomer:
      "Palun sisestage kliendi tuvastamiseks nimi",
    paymentDetails: "Makse andmed",
    basePrice: "Baashind",
    staff: "Personal",
    subTotal: "Vahesumma",
    durationMonths: "Kestus (kuud)",
    selectADuration: "Valige kestus",
    oneMonth: "1 kuu",
    twoMonths: "2 kuud",
    threeMonths: "3 kuud",
    sixMonths: "6 kuud",
    twelveMonths: "12 kuud",
    eighteenMonths: "18 kuud",
    twentyFourMonths: "24 kuud",
    twoMonthsFree: "(2 kuud tasuta)",
    threeMonthsFree: "(3 kuud tasuta)",
    fiveMonthsFree: "(5 kuud tasuta)",
    yourAccountHasBeen: "Teie konto on olnud",
    renewed: "uuendatud",
    upgraded: "täiendatud",
    successfully: "edukalt",
    yourSubscription: "Teie tellimus",
    youAreCurrentlyEnrolledOnA: "Olete praegu registreerunud a",
    pleaseChooseAPaymentOption: "Palun valige makseviis",
    planRenewal: "Plaani uuendamine",
    planUpgrade: "Plaani täiendamine",
    pleaseSelectDurationToPayFor: "Valige kestus, mille eest kavatsete maksta",
    staffAccounts: "Personalikontod",
    ecommerce: "Pood",
    pleaseSelectAPlan: "Palun valige plaan",
    includeAddons: "Kaasa lisandmoodulid",
    viewTransactions: "Kuva tehingud",
    customerHasNoCompletedTansactions:
      "Kliendil pole veel lõpetatud tanseerimisi",
    branch: "Filiaal",
    enterNumberOfEcommerceBranches: "Sisestage e -kaubanduse harude arv",
    enterNumberOfEcommerceBranchesToPay:
      "Sisestage e -kaubanduse filiaalide arv, mille eest kavatsete maksta",
    ecommerceIntegration: "Poodide integreerimine",
    enterNumberOfBranches: "Sisestage harude arv",
    enterNumberOfAdditionalBranchesToPay:
      "Sisestage täiendavate filiaalide arv, mille eest kavatsete maksta",
    enterNumberOfStaffs: "Sisestage personali arv",
    enterNumberOfStaffsToPayFor:
      "Sisestage töötajate arv, mille eest kavatsete maksta",
    discountApplies: "Kehtib allahindlus",
    starsOnThe: "tähed",
    offer: "pakkumine",
    get: "Hangi",
    moreStarsToRedeem: "rohkem tähti lunastamiseks",
    taxVat: "Maks (käibemaks)",
    callCashierToCompletePayment:
      "Makse lõpuleviimiseks helistage kassapidajale",
    receipt: "Kviitung",
    dear: "Kallis",
    thankYouForYourOrderFindGoods:
      "Täname teid tellimuse eest. Palun leidke järgmised kaubad vastavalt kokkuleppele.",
    shippingNote: "Saatekiri",
    enterShippingNote: "Sisestage saatekiri",
    shippingAddress: "Kohaletoimetamise aadress",
    enterShippingAddress: "Sisestage kohaletoimetamise aadress",
    wellDoneYouAreDueToRedeem: "Hästi tehtud! Te peate lunastama",
    toGetYourRewardNextVisit: "et saada järgmisel külastusel tasu. Aitäh",
    pointsOnThe: "Punktid teemal",
    morePointsToRedeem: "lunastamiseks rohkem punkte",
    showCode: "Näita koodi",
    toGetYourRewardOnNextVisit: "et saada järgmisel külastusel tasu. Aitäh",
    earn: "Teeni",
    delivaryNote: "Saatekiri",
    draftSales: "Mustandmüük",
    startDate: "Algus kuupäev",
    endDate: "Lõppkuupäev",
    orders: "Tellimused",
    checkout: "kassasse",
    noProductItem: "Tooteartiklit pole",
    selectProductImage: "Valige toote pilt",
    selectCountry: "Vali riik",
    selectRegion: "Valige osariik/piirkond",
    printProductTag: "Prindi toote silt",
    transactionReference: "Tehingu viide",
    Cashier: "Kassapidaja",
    Manager: "Juhataja",
    Owner: "Omanik",
    Admin: "Administraator",
    addPartners: "Lisage partnereid",
    addNewLoyaltyPartner: "Lisage uus püsikliendipartner",
    pleaseEnterCompanyName: "Sisestage ettevõtte nimi",
    companyName: "Ettevõtte nimi",
    pleaseEnterCompanyRepName: "Sisestage ettevõtte esindaja nimi",
    companyRepName: "Ettevõtte esindaja nimi",
    pleaseEnterCompanyRepEmail: "Sisestage ettevõtte esindaja e-posti aadress",
    companyRepEmail: "Ettevõtte esindaja e-post",
    pleaseEnterCompanyRepPhone: "Sisestage ettevõtte esindaja telefoninumber",
    companyRepPhone: "Ettevõtte esindaja telefoninumber",
    addReward: "Lisa tasu",
    pleaseEnterRewardName: "Sisestage preemia nimi",
    rewardName: "Preemia nimi",
    rewardQuantity: "Preemia kogus",
    rewardDescription: "Preemia kirjeldus",
    rewardType: "Preemia tüüp",
    pleaseEnterRewardType: "Sisestage preemia tüüp",
    pleaseEnterRewardQuantity: "Sisestage preemia kogus",
    pleaseEnterRewardDescription: "Sisestage preemia kirjeldus",
    fineDining: "Fine Dining",
    luxuryFashion: "Luksuslik mood",
    hotels: "Hotellid",
    travel: "Reisimine",
    foodAndBeverage: "Toit ja jook",
    fashion: "Mood",
    health: "Tervis",
    furniture: "Mööbel",
    entertainment: "Meelelahutus",
    automobile: "Auto",
    education: "Haridus",
    beautyAndSpa: "Ilu ja spaa",
    staycation: "Peatumine",
    events: "Sündmused",
    trips: "Reisid",
    oilAndGas: "Nafta ja gaas",
    laundry: "Pesu",
    partnerCategory: "Partneri kategooria",
    freeItem: "Tasuta üksus",
  },
  Filipino: {
    cashier: "cashier",
    manager: "manager",
    owner: "may-ari",
    online: "sa online",
    offline: "offline",
    changePassword: "Palitan ANG password",
    currentPasswordMessage: "Mangyaring ipasok ang iyong kasalukuyang password",
    passwordMessage: "Mangyaring ipasok ang iyong password",
    currentPassword: "Kasalukuyang Password",
    password: "password",
    confirmPasswordMessage: "Mangyaring kumpirmahin ang iyong password!",
    confirmPassword: "Kumpirmahin ang Password",
    sendViaEmail: "Ipadala Sa pamamagitan ng Email",
    sendViaWhatsapp: "Ipadala Sa pamamagitan ng WhatsApp",
    downloadPdf: "Mag-download ng PDF",
    paid: "binayaran",
    unpaid: "walang bayad",
    partial: "bahagyang",
    closeInvoice: "Nais mo bang isara ang Invoice?",
    closeInvoiceConfirmation:
      "Maaaring hindi mai-save ang invoice. Gusto mo bang isara?",
    yes: "oo",
    no: "hindi",
    invoice: "Invoice",
    wasDeducted: "ay binawas",
    for: "para sa",
    item: "Item",
    addProduct: "Magdagdag ng Produkto",
    paymentReference: "Sanggunian sa Pagbabayad",
    amountPaid: "Halagang ibinayad",
    discountAmount: "Halaga ng Diskwento",
    amountDue: "Halagang dapat bayaran",
    amount: "Halaga",
    dueDate: "Takdang petsa",
    paymentType: "Uri ng Pagbabayad",
    card: "Kard",
    cash: "Pera",
    bankTransfer: "Offline Bank Transfer",
    paymentMessage: "Mensahe sa Pagbabayad",
    description: "Paglalarawan",
    sendReceipt: "Magpadala ng Resibo",
    delete: "Tanggalin",
    save: "Magtipid",
    resend: "Ipadala ulit",
    saveAndSend: "Magtipid",
    invoiceSaved: "Nai-save ang invoice!",
    selectPaymentMethod: "Mangyaring pumili ng Paraan ng Pagbabayad!",
    selectCustomer: "Mangyaring pumili ng isang Customer!",
    cartEmptyError:
      "Ang Listahan ng Cart ay hindi maaaring walang laman, isara ang invoice at idagdag ang Item sa cart!",
    subscriptionExpired:
      "Nag-expire na ang iyong subscription at limitado na ang iyong account. Mag-click sa Button sa ibaba upang i-renew ang iyong account",
    renew: "Magbago",
    holdOn: "Hawakan Mo",
    customerBank: "Customer Bank",
    cancel: "Kanselahin",
    selectACustomer: "Piliin ang Customer",
    invoiceSuccessfulPdfError:
      "Matagumpay na nilikha ang invoice ngunit ang PDF Generation ay tumatagal ng mas mahaba kaysa sa dati. Mangyaring suriin muli sa ilang sandali.",
    downloadingInvoice: "Pagda-download ng Invoice",
    downloadingReceipt: "Pagda-download ng Resibo",
    whatsappReceiptError:
      "Nagkaroon ng error sa pagpapadala ng resibo sa pamamagitan ng WhatsApp, Pakisubukang muli.",
    receiptToWhatsapp: "Ipinasa ang resibo sa WhatsApp",
    thankYouForPatronage: "Salamat sa iyong pagtangkilik",
    hereIsYourReceipt: "Narito ang iyong resibo sa pagbabayad",
    errorSendingEmailReceipt:
      "Nagkaroon ng error sa pagpapadala ng resibo sa pamamagitan ng e-mail, pakisubukan ulit o makipag-ugnay sa suporta",
    receiptSentToEmail: "Ang resibo ay naipadala na sa email ng customer",
    invoiceSentToEmail: "Naipadala na ang invoice sa email ng customer",
    invoiceSuccessWhatsappError:
      "Matagumpay na nilikha ang invoice ngunit isang error ang nangyari sa pagpapadala sa WhatsApp. Subukang muli sa listahan ng invoice",
    invoiceSuccessfulEmailError:
      "Matagumpay na nilikha ang invoice ngunit may naganap na error habang nagpapadala bilang e-mail. Mangyaring subukang muli mula sa listahan ng invoice",
    invoiceSentToWhatsapp: "Ipinasa ang invoice sa WhatsApp",
    hello: "Kamusta",
    pleaseDownloadInvoice: "Mangyaring mag-download ng invoice",
    pleaseDownloadReceipt: "Mangyaring mag-download ng resibo",
    from: "mula sa",
    email: "Email",
    upgrade: "Mag-upgrade",
    youAreOnFreePlan: "Nasa isang Libreng Plano ka.",
    clickOn: "Mag-click sa",
    yourPlanInFewSteps:
      " ang iyong subscription sa ilang mabilis na mga hakbang.",
    to: "sa",
    yourSubscriptionHasExpired:
      "Nag-expire na ang iyong subscription at limitado na ang iyong account.",
    days: "araw",
    yourSubscriptionExpiresIn:
      "Mag-e-expire ang iyong subscription sa Loystar sa",
    createAcount: "Gumawa ng account",
    signIn: "Mag-sign In",
    continue: "Magpatuloy",
    enterOtp: "Ipasok ang OTP PIN",
    enterValidOtp: "Mangyaring maglagay ng wastong PIN",
    pin: "PIN",
    tokenSentToMail: "Isang token ang naipadala sa iyong email",
    passwordResetSuccessful: "Matagumpay ang pag-reset ng password",
    somethingWentWrong: "May nangyaring mali!",
    resetPassword: "I-reset ang Password",
    iHaveResetCode: "Mayroon akong isang password reset code",
    pleaseEnterEmail: "Mangyaring ipasok ang iyong email",
    aTokenWillBeSentToEmail: "Ipapadala ang isang token sa iyong email",
    enterEmail: "Ipasok ang iyong email",
    sendinYourToken: "Ipinapadala ang iyong token ...",
    makeSureItMatchesPassword:
      "Tiyaking tumutugma ito sa iyong bagong password",
    pleaseChooseNewPassword: "Mangyaring pumili ng bagong password",
    chooseNewPassword: "Pumili ng isang bagong password",
    enterNewPassword: "Ipasok ang iyong bagong password upang kumpirmahin",
    enterTokenSent: "Ipasok ang token na ipinadala sa iyong mail",
    resetToken: "I-reset ang Token",
    resettingPassword: "Nire-reset ang iyong Password ...",
    signUp: "Mag-sign Up",
    adminSignInWithEmail:
      " Nag-sign in ang admin gamit ang email habang nag-sign in ang Staff na may username.",
    pleaseEnterEmailOrUsername: "Mangyaring ipasok ang iyong email o username",
    emailOrUsername: "Email o Username",
    pleaseEnterPassword: "Mangyaring ipasok ang password",
    createAnAccount: "Gumawa ng account",
    forgotPassword: "Nakalimutan ang password?",
    enterPhoneNumber: "Ilagay ang Numero ng telepono",
    personalData: "Personal na Data",
    validateConfirmationCOde: "Patunayan ang Code ng Pagkumpirma",
    pleaseEnterFirstName: "Mangyaring ipasok ang iyong unang pangalan",
    pleaseEnterPhoneNumber: "Pakibigay na lng po ng phone number niyo",
    pleaseEnterLastName: "Mangyaring ipasok ang iyong apelyido",
    pleaseEnterBusinessName: "Mangyaring ipasok ang pangalan ng iyong negosyo",
    firstName: "Pangalan",
    lastName: "Huling pangalan",
    businessName: "Pangalan ng Negosyo",
    previous: "Dati",
    next: "Susunod",
    pleaseSelectBusinessCategory:
      "Mangyaring piliin ang kategorya ng iyong negosyo",
    pleaseEnterValidEmail: "Mangyaring maglagay ng wastong email",
    pleaseEnterPostCode: "Mangyaring ipasok ang post code",
    postCode: "Post Code",
    phoneNumberInUse: "Ang numero ng telepono na ito ay ginagamit na!",
    emailInUse: "Ang email na ito ay ginagamit na!",
    foodAndDrinks: "Pagkain at Inumin",
    salonAndBeauty: "Salon at Pampaganda",
    fashionAndAccessories: "Fashion at Kagamitan",
    gymAndFitness: "Gym at Fitness",
    travelAndHotel: "Paglalakbay at Hotel",
    homeAndGifts: "Tahanan at Regalo",
    washingAndCleaning: "Paghuhugas at Paglilinis",
    gadgetsAndElectronics: "Mga Gadget at Elektronika",
    groceries: "Mga groseri",
    others: "Ang iba pa",
    submit: "Ipasa",
    accountCreatedSuccessful: "Matagumpay na nilikha ang iyong account.",
    pleaseEnterAddress: "Mangyaring Ipasok ang Iyong Address",
    addressLine1: "Linya ng Address 1",
    addressLine2: "Linya ng Address 2",
    choosePassword: "Pumili ng Password",
    passwordMustBe6Characters:
      "Ang password ay dapat na hindi bababa sa 6 na mga character.",
    howDidYouHearLoystar: "Paano mo narinig ang tungkol sa Loystar?",
    referralCode: "referral Code",
    byClickingTheButton:
      " Sa pamamagitan ng pag-click sa pindutan sa ibaba, sumasang-ayon ka sa",
    termsAndSevice: "Mga tuntunin",
    wereCreatingAccount: "Lumilikha kami ng iyong account",
    proceed: "Magpatuloy",
    verificationCodeMustBe6: "Ang verification Code ay dapat na 6 na digit",
    pleaseEnter6DigitCode: "Mangyaring Ipasok ang 6 na digit na code",
    enterVerificationCode: "Ipasok ang verification code",
    resendToken: "Ipadala muli ang Token",
    verify: "Patunayan",
    transactions: "Mga Transaksyon",
    todaySales: "Benta Ngayon",
    salesHistory: "Kasaysayan sa Pagbebenta",
    supplyHistory: "Kasaysayan ng Supply",
    new: "Bago",
    invoices: "Mga Invoice",
    disbursements: "Mga pagbibigay",
    offlineSales: "Pagbebenta ng Offline",
    products: "Mga produkto",
    customers: "Mga suki",
    multiLevelLoyalty: "Multi-Level Loyalty",
    loyaltyPrograms: "Mga Programa ng Katapatan",
    members: "Mga myembro",
    appStore: "App Store",
    orderMenu: "Order Menu",
    settings: "Mga setting",
    staffAndBranches: "Mga Tauhan at Sangay",
    myAccount: "Aking Account",
    switchToSellMode: "Lumipat sa Sell Mode",
    signOut: "Mag-sign Out",
    getFreeSubscription: "Kumuha ng Libreng Subscription",
    onlyNumbersAllowed: "Mga numero lang ang pinapayagan",
    yourAccountMustBe10Digits:
      "ang iyong numero ng account ay dapat na isang 10 digit na numero",
    yourBvnMustBe11: "ang iyong BVN ay dapat na isang 11 digit na numero",
    pleaseSelectBank: "Mangyaring piliin ang iyong bangko",
    selectYourBank: "Piliin ang iyong Bangko",
    enterBankAccountNumber: "Ipasok ang Numero ng Bank Account",
    enterBvnNumber: "Ipasok ang iyong BVN",
    connectBankAccount: "Ikonekta ang Bank Account",
    passwordResetSuccessfulAndSignOut:
      "Matagumpay na na-reset ang iyong Password. Mag-click sa button na Mag-sign Out sa ibaba upang muling mag-signin",
    enterCurrentPassword: "Ipasok ang kasalukuyang password",
    pleaseEnterCurrentPassword: "Mangyaring ipasok ang kasalukuyang password",
    phoneNumber: "Numero ng telepono",
    sex: "Kasarian",
    dateOfBirth: "Araw ng kapanganakan",
    state: "Estado",
    country: "Bansa",
    loyaltyId: "Loyalty ID",
    createdAt: "Nilikha At",
    noOfTransactionsMade: "Bilang ng mga transaksyon na ginawa",
    yourDownloadWillStart:
      "Ang iyong pag-download ay magsisimula sa isang sandali",
    exportCustomers: "I-export ang Mga Customer",
    youhaveSuccessfullyToppedUp:
      "Matagumpay mong na-top up ang iyong Mga SMS Unit.",
    youNowHave: "Meron ka na ngayon",
    smsUnits: "Mga Yunit ng SMS",
    enterNumberOfSmsUnits:
      "Ipasok ang Bilang ng mga Yunit ng SMS na Nilalayon mong Bumili",
    pleaseEnterANumericValue: "Mangyaring maglagay ng halagang bilang",
    pay: "Magbayad",
    accountEditedSuccessfully: "Matagumpay na na-edit ang iyong account.",
    youAeCurrentlyOn: "Kasalukuyan kang nasa",
    plan: "Plano",
    userData: "Data ng Gumagamit",
    businessData: "DATA NG NEGOSYO",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Mangyaring piliin ang iyong pera",
    selectYourCurrency: "Piliin ang iyong pera",
    purchaseMoreSmsUnits:
      "Bumili ng higit pang Mga Yunit ng SMS gamit ang form sa ibaba",
    youHave: "Meron kayo",
    atLeast4SmsUnitsRrequired:
      "Hindi bababa sa 4 na mga unit ng sms ang kinakailangan para sa pag-verify, mangyaring mag-top up!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Mangyaring i-verify ang iyong bank account upang sumunod sa mga kinakailangang 'Alamin ang Iyong Customer' (KYC). Papayagan ka nitong mangolekta ng mga pagbabayad sa pamamagitan ng USSD o instant transfer, makatanggap ng mga order mula sa mga customer at maproseso ang pag-areglo ng transaksyon. Nalalapat lamang sa mga mangangalakal na Nigeria. Mangyaring pindutin ang pindutan sa ibaba upang magsimula.",
    reConnectBankAccount: "Muling ikonekta ang Bank Account",
    accountName: "Pangalan ng Account",
    accountNumber: "Numero ng Account",
    bankName: "Pangalan ng bangko",
    verified: "Na-verify",
    accountDetails: "Mga Detalye ng Account",
    kycBankAccount: "KYC",
    createTier: "Lumikha ng Tier",
    fileUploadSuccessful: "matagumpay na na-upload ang file",
    fileUploadFailed: "bigo ang pag-upload ng file",
    createLoyaltyProgram: "Lumikha ng isang Loyalty Program",
    createLoyalty: "Lumikha ng Katapatan",
    name: "Pangalan",
    loyaltyArtwork: "Katapatan Artwork",
    clickToUpload: "Mag-click upang i-upload",
    amountToPointsRatio: "Halaga sa Points Ratio",
    points: "Puntos",
    recommendedAmountToPointRatio:
      "Inirekomenda: ₦ 1 hanggang 1point. ie para sa bawat 1 naira na ginugol, ang iyong mga customer ay nakakakuha ng 1 puntos",
    pleaseTypeIn: "Mangyaring i-type",
    toDeleteLoyalty: "upang tanggalin ang katapatan na ito",
    deleteLoyalty: "Tanggalin ang Katapatan",
    toConfirm: "upang kumpirmahin",
    edit: "I-edit",
    pointsAwardedSuccessfully: "Matagumpay na Ginawaran ng Mga Punto.",
    enterPointValueToAward:
      "Ipasok ang Halaga ng Puntong Nais mong Gawad Ang Mga Customer",
    award: "Gantimpala",
    awardPointValuesToCustomer: "Halaga ng Award Point sa Customer",
    enrollMembersToLoyalty: "Magpatala ng mga Miyembro sa Loyalty",
    awardPoints: "Mga Punto ng Award",
    enroll: "Magpatala",
    home: "Bahay",
    loyalty: "Katapatan",
    enrollCustomers: "Magpatala ng Mga Customer",
    selected: "Napili",
    customer: "Kostumer",
    loyaltyActivationSuccessful: "Matagumpay ang Pag-activate ng Loyalty.",
    loyaltyDeactivationSuccessful: "Ang Loyalty Deactivation ay matagumpay.",
    viewTier: "Tingnan ang Tier",
    deactivate: "I-de-aktibo",
    activate: "Buhayin",
    actions: "Mga kilos",
    nameOfLoyalty: "Pangalan ng Katapatan",
    loyaltyStatus: "Katayuan ng Katapatan ng Katayuan ng Katapatan",
    numberOfTiers: "Bilang ng mga Tier",
    createdOn: "Nilikha Sa",
    createATier: "Lumikha ng isang Tier",
    stopCreatingTierConfirmation:
      "Nais mo bang ihinto ang paglikha ng isang baitang?",
    stopEditingTierConfirmation:
      "Nais mo bang ihinto ang pag-edit ng tier na ito?",
    nameOfTier: "Pangalan ng Tier",
    minimumSpendingTarget: "Minimum na Target sa Paggastos",
    maximumSpendingTarget: "Target ng Maximum na Paggastos",
    minimumSpendingTargetRequired:
      "minimum na target sa paggastos ay kinakailangan",
    maximumSpendingTargetRequired:
      "maximum na target sa paggastos ay kinakailangan",
    rewardSponsor: "Tagataguyod ng Gantimpala",
    pleaseChooseARewardSponsor:
      "Mangyaring pumili ng isang sponsor ng gantimpala",
    self: "Sarili",
    partner: "Kasosyo",
    rewardPartner: "Kasosyo sa Gantimpala",
    pleaseSelectRewardPartner:
      "Mangyaring piliin ang iyong kasosyo sa gantimpala",
    rewards: "Gantimpala",
    pleaseSelectAReward: "Mangyaring pumili ng gantimpala",
    instructionsOnRedeemingReward:
      "Mga tagubilin sa Paano Dapat Tanggapin ng Customer ang Gantimpala",
    pleaseFillInThisField: "Mangyaring Punan ang Patlang na ito!",
    toDeleteThisTier: " upang tanggalin ang tier na ito",
    deleteTier: "Tanggalin ang Tier",
    viewMembers: "Tingnan ang mga Miyembro",
    membersEnrolled: "Nag-enrol ang mga Miyembro",
    instruction: "Panuto",
    membersIn: "Mga kasapi sa",
    availableTiersInLoyalty: "Magagamit ang (mga) Tier sa Loyalty Program",
    tiers: "Mga baitang",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Magagamit na Programa ng Katapatan",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Mga Detalye ng Miyembro",
    nameOfCustomer: "Pangalan ng Customer",
    address: "Address",
    allEnrolledMembers: "Lahat ng Miyembro na Naka-enrol",
    thisIsToWishYouHappyBirthday:
      "Ito ay upang mabati ka ng isang napakasayang kaarawan at masaganang buhay. Salamat sa lahat ng iyong kay Loyster. Maligayang Pagdiriwang!",
    inputAnOfferPlease: "Mangyaring maglagay ng isang Alok mangyaring",
    pleaseSelectTheInsertPoint:
      "Mangyaring piliin ang insert point sa mensahe at mag-click muli",
    birthdayOfferAndMessage: "Alok at Mensahe ng Kaarawan",
    birthdayOffer: "Alok ng Kaarawan",
    birthdayMessage: "Mensahe ng Kaarawan",
    noOfferFound: "Walang nahanap na alok",
    settingABirthdayOffer:
      "Ang pagtatakda ng alok ng kaarawan ay nagbibigay-daan sa mga customer na makatanggap ng alok na ito sa pamamagitan ng SMS sa kanilang kaarawan",
    createOffer: "Lumikha ng Alok",
    whatIsTheOffer: "Ano ang alok?",
    editMessage: "I-edit ang Mensahe",
    insert: "Isingit",
    theNameOfCustomerInserted: "Ang pangalan ng customer ay isisingit dito",
    theBirtdayOfferInserted: "Ipapasok dito ang alok ng kaarawan",
    youSuccessfullyAddedNewBranch:
      "Matagumpay kang nagdagdag ng isang bagong sangay!",
    addNewBranch: "Magdagdag ng Bagong Sangay",
    addBranch: "Magdagdag ng Sangay",
    additionalBranchWillIncur: "Karagdagang Sangay ay magkakaroon",
    perBranch: "bawat sangay",
    ecommerceBranchCosts: "Mga gastos sa Ecommerce Branch",
    pleaseEnterBranchName: "Mangyaring ipasok ang pangalan ng sangay",
    pleaseEnterBranchAddress1: "Mangyaring ipasok ang address line ng sangay 1",
    enterBranchAddress1: "Ipasok ang linya ng address ng sangay 1",
    enterBranchAddress2: "Ipasok ang linya ng address ng sangay 1",
    pleaseEnterBranchAddress2: "Mangyaring ipasok ang address line ng sangay 2",
    enterBranchName: "Ipasok ang pangalan ng sangay",
    successfullyAddedStaff: "Matagumpay kang nagdagdag ng bagong kawani!",
    addNewStaff: "Magdagdag ng Bagong Staff",
    addStaff: "Magdagdag ng Staff",
    additionalStaffWillIncur: "Karagdagang tauhan ay magkakaroon",
    perStaff: "bawat kawani.",
    pleaseEnterStaffEmail: "Mangyaring ipasok ang email ng staff",
    pleaseEnterStaffUsername: "Mangyaring ipasok ang username ng staff",
    pleaseEnterStaffPassword: "Mangyaring ipasok ang password ng tauhan",
    pleaseSelectStaffRole: "Mangyaring piliin ang papel ng kawani",
    selectStaffRole: "Piliin ang tungkulin ng tauhan",
    enterStaffEmail: "Ipasok ang email ng staff",
    enterStaffUsername: "Ipasok ang username ng staff",
    enterStaffPassword: "Ipasok ang password ng tauhan",
    spacesNotAllowedInUsername: "bawal ang mga puwang sa username",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Mangyaring pumili ng negosyo upang maglakip ng tauhan",
    searchForBranchToAttachStaff:
      "Maghanap ng sangay upang mai-attach ang tauhan",
    username: "Username",
    role: "Papel",
    areYouSureToDeleteThis: "Sigurado ka bang tatanggalin ito",
    branches: "Mga sanga",
    upgradeYourPlan: "I-upgrade ang iyong Plano.",
    add: "ADD",
    addNew: "Magdagdag ng bago",
    customerWithEmailAlreadyExists:
      "Ang customer na may email / numero ng telepono ay mayroon na!",
    successfullyAddedNewCustomer:
      "Matagumpay kang nagdagdag ng bagong customer!",
    addCustomer: "Idagdag ang Customer",
    pleaseEnterCustomerFirstName:
      "Mangyaring ipasok ang unang pangalan ng customer",
    pleaseEnterCustomerLastName: "Mangyaring ipasok ang apelyido ng customer",
    pleaseEnterCustomerPhoneNumber:
      "Mangyaring ipasok ang numero ng telepono ng customer",
    pleaseEnterCustomerEmail: "Mangyaring ipasok ang email ng customer",
    pleaseEnterCustomerAddressLine:
      "Mangyaring ipasok ang Address Line ng customer",
    pleaseEnterCustomerOtherAddress:
      "Mangyaring ipasok ang ibang Address ng customer",
    pleaseSelectCustomerGender: "Mangyaring piliin ang kasarian ng customer",
    gender: "Kasarian",
    male: "Lalaki",
    female: "Babae",
    bank: "bangko",
    selectBank: "Piliin ang Bangko",
    stateOrRegionOrProvince: "Estado / Rehiyon / Lalawigan",
    customerNotes: "Mga Tala ng Customer",
    sendSms: "Magpadala ng SMS",
    editCustomer: "I-edit ang Customer",
    redeemReward: "Kunin ang Gantimpala",
    issueLoyaltyCard: "Isyu ng Loyalty Card",
    deleteCustomer: "Tanggalin ang Customer",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Matagumpay kang naitalaga ng isang Loyalty Membership ID",
    noMembershipIdAvailable:
      "Walang magagamit na mga membership ID. Mangyaring makipag-ugnay sa amin sa hello@loystar.co",
    sendEmail: "Magpadala ng Email",
    membershipPoints: "Mga Punto ng Pagsapi",
    customerDetails: "Mga Detalye ng Customer",
    close: "Isara",
    loyaltyBalance: "Balanse ng Katapatan",
    pointsBalance: "Balanse ng Mga Punto",
    starBalance: "Balanse ng Bituin",
    requiredPoints: "Mga Kinakailangan na Punto",
    requiredStars: "Mga Kinakailangan na Mga Bituin",
    reddemCode: "Kunin ang Code",
    toDeleteThisCustomer: "upang tanggalin ang kostumer na ito",
    customerHasBeenDeletedSuccessfully: "Matagumpay na natanggal ang customer!",
    customerWithPhoneAlreadyExists:
      "Mayroon nang customer na may numero ng telepono",
    customerWasSuccessfullyEdited: "Matagumpay na na-edit ang customer",
    anErrorOccured: "May naganap na error",
    phoneNumberIncludeCountry: "Numero ng Telepono (Isama ang Country Code)",
    yourFileQueuedForUpload:
      "Ang iyong file ay nakapila para sa pag-upload. Mangyaring i-refresh ang pahina pagkatapos ng ilang segundo.",
    thereWasAnErrorPerformingOperation:
      "Nagkaroon ng error sa pagsasagawa ng operasyon!",
    pleaseSelectFile: "Mangyaring Pumili ng isang File!",
    oopsOnlyCsvFilesAllowed:
      "Naku! Ang mga CSV file lang ang pinapayagan. Mangyaring mag-upload ng isang .csv file.",
    fileShouldBeSmallerThan5Mb:
      "Ang file ay dapat na mas maliit sa 5MB. Kung mayroon kang isang mas malaking file, mangyaring mag-email sa support@loystar.co. Salamat",
    customerFirstNameIsRequired:
      "ang unang pangalan ng customer ay kinakailangan sa hilera",
    customerPhoneNumberIsRequired:
      "Ang numero ng telepono ng customer ay kinakailangan sa hilera",
    importCustomers: "Mag-import ng Mga Customer",
    upload: "I-upload",
    clickIconToDownloadCsv:
      "I-click ang icon na ito upang i-download ang template ng CSV file.",
    getGoogleSheetVersion: "Kunin ang bersyon ng Google sheet",
    clickOrDragFileToUpload:
      "I-click o i-drag ang file sa lugar na ito upang mai-upload",
    missingOrInvalidColumnHeader:
      "Nawawala o hindi wastong header ng haligi. Mangyaring sundin ang format ng template. Salamat.",
    youHaveImported: "Nag-import ka",
    youSuccessfullyRedeemedReward:
      "Matagumpay mong natubos ang iyong gantimpala!",
    sixDigitCode: "Anim na digit na code",
    pleaseEnterCustomerRewardCode:
      "Mangyaring ipasok ang code ng gantimpala ng customer",
    enterRewardCode:
      "Ipasok ang reward code. (Ang reward code ay case sensitive)",
    selectLoyaltyProgram: "Piliin ang loyalty program",
    stamps: "Mga selyo",
    smsUnitsLow: "Mababa ang Mga Yunit ng SMS",
    pleaseTopUpSmsUnits: "Mangyaring I-Top Up ang Mga Yunit ng SMS",
    smsSuccessfullySentForDelivery:
      "Matagumpay na naipadala ang SMS para sa paghahatid!",
    sendSmsTo: "Magpadala ng SMS kay",
    allCustomers: "Lahat ng Mga Customer",
    unitsAvailable: "Mga Magagamit na Yunit",
    pleaseTypeInTheMessage: "Mangyaring i-type ang mensahe",
    message: "Mensahe",
    charactersRemaining: "natitirang mga character",
    avoidUseOfSpecialCharacters:
      "Iwasan ang paggamit ng Mga Espesyal na Character at Emojis upang makatipid sa Mga Yunit ng SMS.",
    note: "Tandaan",
    errorFetchingCustomersMultilevelDetail:
      "Error sa Pagkuha ng Mga Detalye ng Mga MultiLevel ng Mga Customer",
    search: "Maghanap",
    reset: "I-reset",
    importCustomer: "Mag-import ng Customer",
    addNewCustomer: "Magdagdag ng Bagong Customer",
    sendSmsBroadcast: "Magpadala ng Broadcast sa SMS",
    totalCustomers: "TOTAL CUSTOMER",
    disbursementDetails: "Mga Detalye ng Disbursement",
    paidBy: "Bayad Ni",
    disbursed: "Ipinadala",
    bankAccountName: "Pangalan ng bank account",
    bankAccountNumber: "Numero ng account sa bangko",
    transferInitiated: "Pinasimulan ang Paglipat",
    transferCompleted: "Nakumpleto ang Paglipat",
    pleaseEnterAValid: "Pakiusap Maglagay ng tamang",
    begin: "magsimula",
    end: "magtapos",
    date: "petsa",
    paymentDate: "Petsa ng Pagbabayad",
    selectDisbursementDuration: "Piliin ang Tagal ng Disbursement",
    totalSettled: "Kabuuang Naayos na",
    totalUnsettled: "Kabuuang Hindi naayos",
    toDeleteThisSale: "upang tanggalin ang benta na ito",
    draftSaleDeletedSuccessfully: "Matagumpay na na-delete ang Draft Sale!",
    deleteSale: "Tanggalin ang Pagbebenta",
    pleaseTypeInYourTotal:
      "Mangyaring i-type ang iyong kabuuan upang kumpirmahin ang pagtanggal",
    billDetails: "Mga Detalye ng Bill",
    printBill: "Bill Bill",
    servedBy: "Inihatid ni",
    total: "Kabuuan",
    createdDate: "Nilikha ang Petsa",
    createdTime: "Nilikha ang Oras",
    status: "Katayuan",
    loginSuccessful: "Matagumpay ang pag-login",
    pleaseWaitWhileWeConnectAccount:
      "Mangyaring maghintay habang ikinonekta namin ang iyong account",
    connectionFailedTryAgain: "Nabigo ang koneksyon. Pakiulit.",
    connectionSuccessful: "Matagumpay ang koneksyon",
    viewDetails: "Tingnan ang mga detalye",
    enable: "Paganahin",
    free: "Libre",
    cost: "Gastos",
    visitWebsite: "Bisitahin ang Website",
    pleaseUpgradeYourPlanToPro:
      "Mangyaring I-upgrade ang iyong plano sa Pro Plus upang paganahin ang app na ito",
    connectYourBankAccount:
      "Ikonekta ang iyong Bank Account upang makatanggap ng mga pagbabayad.",
    disable: "Huwag paganahin",
    enableApp: "Paganahin ang App",
    addNewProductToInvoice: "Magdagdag ng Bagong Produkto sa Invoice",
    toDeleteThisInvoice: "upang tanggalin ang Invoice na ito",
    invoiceDeletedSuccessfully: "Matagumpay na natanggal ang invoice!",
    deleteInvoice: "Tanggalin ang Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Matagumpay kang nagdagdag ng isang mensahe sa pagbabayad ng Invoice!",
    pleaseEnterPaymentInstructions:
      "Mangyaring ipasok ang mga tagubilin sa pagbabayad",
    invoiceId: "ID ng Invoice",
    paidDate: "Bayad na Petsa",
    reference: "Sanggunian",
    productAdded: "Naidagdag ang produkto",
    productOutOfStock:
      "Wala nang stock ang produkto. Mangyaring muling i-stock.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "TOTAL Bayad na INVOICES",
    totalUnpaidInvoices: "TOTAL NA INVOICES NG UNPAID",
    loyaltyProgramDeleted: "Tinanggal ang Program ng Katapatan",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ang loyalty program na ito ay matagumpay na na-delete",
    thisLoyaltyProgramEditedSuccessfully:
      "Ang programang loyalty na ito ay matagumpay na na-edit",
    loyaltyProgramAddedSuccessfully:
      "Ang programa ng katapatan ay matagumpay na naidagdag",
    loyaltyProgramEdited: "Na-edit ang Loyalty Program",
    loyaltyProgramAdded: "Naidagdag ang Program ng Katapatan",
    loyaltyDetails: "Mga Detalye ng Katapatan",
    doYouWantToCloseDialog: "Nais mo bang isara ang dayalogo na ito?",
    pleaseEnterLoyaltyName: "Mangyaring ipasok ang pangalan ng iyong katapatan",
    programType: "Uri ng Programa",
    pleaseSelectProgramType: "Mangyaring pumili ng isang uri ng programa",
    simplePoints: "Mga Simpleng Punto",
    stampsProgram: "Programa ng Selyo",
    threshold: "Threshold",
    pleaseEnterLoyaltyThreshold: "Mangyaring ipasok ang threshold ng loyalty",
    reward: "Gantimpala",
    pleaseEnterLoyaltyReward: "Mangyaring ipasok ang gantimpala ng katapatan",
    totalUserPoints: "Kabuuang Mga Punto ng Gumagamit",
    totalUserStamps: "Kabuuang Mga Selyo ng Gumagamit",
    spendingTarget: "Target ng paggastos",
    spendingTargetHint1:
      "Ang target sa paggastos ay kung magkano dapat gastusin ng isang customer upang makuha ang gantimpala. 1 halaga ng pera = 1 point.",
    spendingTargetHint2:
      "Ang target na selyo ay kung gaano karaming mga selyo ang dapat kolektahin ng isang customer upang makuha ang gantimpala. hal. 5",
    addNewLoyaltyProgram: "Magdagdag ng Bagong Loyalty Program",
    addLoyaltyProgram: "Magdagdag ng Loyalty Program",
    loyaltyProgramType: "Uri ng Program ng Katapatan",
    pleaseSelectLoyaltyProgramType:
      "Mangyaring piliin ang uri ng programa ng loyalty",
    nameOfProgram: "Pangalan ng programa",
    pleaseEnterProgramName: "Mangyaring ipasok ang pangalan ng programa",
    whatIsTheReward: "Ano ang gantimpala?",
    egNextPurchaseIsFree: "Hal. Susunod na pagbili ay libre",
    customerName: "Pangalan ng Customer",
    guestCustomer: "Bisita Customer",
    orderConfirmedSuccessfully: "Matagumpay na nakumpirma ang order",
    orderCancelledSuccessfully: "Matagumpay na nakansela ang order",
    confirmOrder: "Kumpirmahin ang order",
    cancelOrder: "Kanselahin ang order",
    allOrders: "Lahat ng Order",
    totalOrders: "TOTAL NA ORDERS",
    doYouWantToAcceptOrder: "Nais mo bang tanggapin ang order na ito?",
    doYouWantToCancelOrder: "Nais mo bang kanselahin ang order na ito?",
    orderDetails: "Mga Detalye ng Order",
    orderCreatedAt: "Nilikha ang order sa",
    supplier: "Tagatustos",
    productName: "pangalan ng Produkto",
    quantity: "Dami",
    unitPrice: "Presyo ng isang piraso",
    receivedBy: "Natanggap ni",
    reportFrom: "Iulat mula sa",
    totalSupplies: "Kabuuang Mga Panustos",
    allRightsReserved: "Nakareserba ang Lahat ng Karapatan",
    toDeleteThisTransaction: "upang tanggalin ang Transaksyon na ito",
    transactionDeletedSuccessfully:
      "Matagumpay na natanggal ang transaksyon. Ang stock ay naibalik sa imbentaryo.",
    deleteTransaction: "Tanggalin ang Transaksyon",
    transactionDetails: "Mga Detalye ng Transaksyon",
    printReceipt: "Resibo ng Pag-print",
    channel: "Channel",
    discount: "Discount",
    profit: "Kita",
    discountedSales: "Discounted Sales",
    errorFetchingRecord: "Error sa Pagkuha ng Record",
    exportTransactions: "I-export ang Mga Transaksyon",
    errorFetchingSalesRecord:
      "Error sa pagkuha ng tala ng Benta para sa Pag-export.",
    totalSellingPrice: "Kabuuang Presyo ng Pagbebenta",
    totalCostPrice: "Kabuuang Presyo ng Gastos",
    appliedDiscount: "Inilapat na Diskwento",
    noOfItems: "Bilang ng mga Item",
    sales: "Benta",
    export: "I-export",
    totalProfit: "Kabuuang kita",
    totalBalanceInPeriod: "Kabuuang Balanse sa Panahon",
    allTimeSales: "Lahat ng Oras ng Pagbebenta",
    records: "Talaan",
    todaysSales: "Benta Ngayon",
    transaction: "transaksyon",
    youHaveExceededTotalNumberOfProducts:
      "Lumagpas ka sa kabuuang bilang ng mga produktong pinapayagan sa iyong plano. I-upgrade ang iyong plano upang masiyahan sa isang mas mataas na isang limitasyon.",
    youNeedToHaveLoyaltyProgram:
      "Kailangan mong magkaroon ng isang Loyalty Program upang magamit ang tampok na ito!",
    price: "Presyo",
    category: "Kategoryang",
    stockTracking: "Pagsubaybay sa Stock",
    stockCount: "Bilang ng Stock",
    taxed: "Taxed",
    originalPrice: "Orihinal na presyo",
    costPrice: "Presyo ng Gastos",
    unit: "Yunit",
    productImage: "Larawan ng Produkto",
    taxRate: "Buwis sa Buwis",
    taxType: "Uri ng Buwis",
    trackInventory: "Subaybayan ang Imbentaryo",
    variants: "Mga pagkakaiba-iba",
    hasVariants: "May Mga Variant",
    importProduct: "I-import ang Produkto",
    exportProducts: "I-export ang Mga Produkto",
    addNewProduct: "Magdagdag ng Bagong Produkto",
    viewCategory: "Tingnan ang Kategoryang",
    viewSuppliers: "Tingnan ang Mga Tagatustos",
    receiveInventory: "Tumanggap ng Imbentaryo",
    printAllProductsTag: "I-print ang Lahat ng Tag ng Mga Produkto",
    deleteAll: "Tanggalin ang lahat",
    totalProducts: "TOTAL PRODUKTO",
    youveSuccessfullyAddedANewCategory:
      "Matagumpay kang nagdagdag ng isang bagong kategorya",
    addNewCategory: "Magdagdag ng Bagong Kategoryang",
    addCategory: "Magdagdag ng Kategorya",
    categoryName: "Pangalan ng kategorya",
    pleaseEnterCategoryName: "Mangyaring ipasok ang pangalan ng kategorya",
    stampsTarget: "Target ng Mga Selyo",
    sendInventory: "Magpadala ng Imbentaryo",
    productDetails: "detalye ng Produkto",
    youveSuccessfullyEditedThisCategory:
      "Matagumpay mong na-edit ang kategoryang ito",
    update: "Update",
    categoryList: "Listahan ng kategorya",
    categories: "Mga kategorya",
    enterQuantityToUpdate: "Maglagay ng dami upang mai-update",
    inventorySentSuccessfully: "Matagumpay na naipadala ang imbentaryo!",
    updateInventory: "I-update ang Imbentaryo",
    currentQuantity: "Kasalukuyang Dami",
    pleaseEnterQuantityToAdd: "Mangyaring ipasok ang dami na nais mong idagdag",
    pleaseSelectABranch: "Mangyaring pumili ng isang Sangay",
    searchForBranch: "Maghanap para sa sangay",
    youCantSendMoreThanStock:
      "Hindi ka maaaring magpadala ng higit sa mayroon ka sa stock",
    send: "Ipadala",
    pleaseEnterQuantityToSend:
      "Mangyaring ipasok ang dami na nais mong ipadala",
    productNameIsRequiredOnRow:
      "pangalan ng produkto ay kinakailangan sa hilera",
    productCategoryIsRequiredOnRow:
      "kategorya ng produkto ay kinakailangan sa hilera",
    productPriceIsRequiredOnRow:
      "presyo ng produkto ay kinakailangan sa hilera",
    productUnitIsRequiredOnRow: "produkto unit ay kinakailangan sa hilera",
    productQuantityIsRequiredOnRow:
      "dami ng produkto ay kinakailangan sa hilera",
    productVariantsRequireTracking:
      "nangangailangan ng pagsubaybay ang mga variant ng produkto!",
    pleaseAddVariantClickButton:
      "Mangyaring magdagdag ng isang variant sa pamamagitan ng pag-click sa add button!",
    totalVariantsMoreThanSelectedQuantity:
      "Ang Kabuuang Mga Variant ay higit pa sa napiling dami ng produkto, binawasan ng Pls ang dami ng iba",
    productEditedSuccessfully: "Matagumpay na na-edit ang produkto!",
    fileTooLargeLessThan4Mb:
      "Masyadong malaki ang laki ng file! Ang laki ng file ay dapat mas mababa sa 4MB.",
    suchVariantAlreadyExist: "Ang gayong pagkakaiba-iba ay mayroon na",
    addVariants: "Magdagdag ng Mga Variant",
    editProduct: "I-edit ang Produkto",
    pleaseEnterProductName: "Mangyaring Ipasok ang Pangalan ng Produkto",
    pleaseEnterProductPrice: "Mangyaring Ipasok ang Presyo ng Produkto",
    pleaseEnterProductOriginalPrice:
      "Mangyaring Ipasok ang Orihinal na Presyo ng Produkto",
    productDescription: "Paglalarawan ng Produkto",
    selectProductCategory: "Piliin ang Kategoryang Produkto",
    pleaseSelectProductCategory: "Mangyaring piliin ang kategorya ng produkto",
    productCostPrice: "Presyo ng Gastos ng Produkto",
    productSellingPrice: "Presyo ng Pagbebenta ng Produkto",
    productOriginalPrice: "Orihinal na Presyo ng Produkto",
    maxFileSizeAllowedIs4Mb: "Max. Pinapayagan ang laki ng file na 4mb",
    productsWithPicturesArePublished:
      "Ang mga produktong may larawan ay nai-publish sa Discover upang makatanggap ng mga order",
    shouldThisProductBeTracked: "Dapat bang subaybayan ang produktong ito?",
    pleaseSelectStockUnit: "Mangyaring piliin ang stock unit",
    stockUnit: "Stock Unit",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "MAAARI",
    carton: "KARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "BAKURAN",
    pleaseEnterProductQuantity: "Mangyaring ipasok ang dami ng produkto",
    productQuantity: "Dami ng Produkto",
    isThisProductTaxed: "Buwis ba ang produktong ito?",
    selectTaxType: "Piliin ang Uri ng Buwis",
    pleaseSelectTaxType: "Mangyaring piliin ang Uri ng Buwis",
    progressive: "Progresibo",
    proportional: "Proportional",
    pleaseEnterProductTaxRate:
      "Mangyaring ipasok ang rate ng buwis ng produkto",
    doesProductHaveVariants: "Mayroon bang variant ang produktong ito?",
    type: "Uri",
    value: "Halaga",
    pleaseEnterVariantType: "Mangyaring ipasok ang uri ng variant",
    pleaseEnterVariantValue: "Mangyaring ipasok ang halaga ng variant",
    pleaseEnterVariantPrice: "Mangyaring ipasok ang presyo ng iba",
    pleaseEnterVariantQuantity: "Mangyaring ipasok ang dami ng iba",
    productDeletedSuccessfully: "Matagumpay na natanggal ang produkto!",
    categoryDeletedSuccessfully: "Matagumpay na natanggal ang kategorya!",
    toDeleteThisProduct: "upang tanggalin ang Produkto na ito",
    invalidProductQuantity: "Di-wastong Dami ng Produkto",
    quantityAddedIsOverStock:
      "Ang dami mong idinadagdag ay higit sa kung ano ang mayroon ka sa stock.",
    itemInventoryNotTracked: "Hindi sinusubaybayan ang imbentaryo ng item",
    addBulkQuantity: "Magdagdag ng Maramihang Dami",
    enterBulkQuantity: "Ipasok ang Maramihang Dami",
    pleaseEnterBulkQuantity: "Mangyaring ipasok ang Maramihang Dami",
    youveSuccessfullyAddedANewProduct:
      "Matagumpay kang nagdagdag ng isang bagong produkto!",
    pleaseEnterProductSellingPrice:
      "Mangyaring ipasok ang presyo ng pagbebenta ng produkto",
    doYouWantToTrackProductStock:
      "Nais mo bang subaybayan ang stock ng produkto?",
    sellingPrice: "Pagbebenta ng Presyo",
    setProductExpiryReminder: "Itakda ang Paalala sa Pag-expire ng Produkto",
    productExpiryDate: "Petsa ng Pag-expire ng Produkto",
    startRemindingFrom: "Simulang Paalalahanan Mula sa",
    productSuppliesAddedSuccessfully:
      "Matagumpay mong naidagdag ang mga supply ng produkto.",
    addProductSupplies: "Magdagdag ng Mga Pantustos ng Produkto",
    pleaseSelectSupplier: "Mangyaring Piliin ang Tagatustos",
    nameOfProduct: "Pangalan Ng Produkto",
    pleaseSelectProduct: "Mangyaring Piliin ang Produkto",
    productVariant: "Variant ng Produkto",
    pleaseSelectAVariant: "Mangyaring Pumili ng Isang Variant",
    pleaseEnterUnitPrice: "Mangyaring Ipasok ang Presyo ng Yunit",
    businessBranch: "Sangay ng Negosyo",
    pleaseSelectBranch: "Mangyaring pumili ng sangay",
    youveSuccessfullyAddedANewSupplier:
      "Matagumpay kang nagdagdag ng isang bagong tagapagtustos",
    addSupplier: "Magdagdag ng Tagatustos",
    pleaseEnterSupplierEmail: "Mangyaring ipasok ang email ng tagapagtustos",
    pleaseAddADescription: "Mangyaring magdagdag ng isang paglalarawan",
    anErrorOccuredProductsDeleted:
      "May naganap na error habang ginagawa ang operasyon. Mangyaring tandaan, ilang mga produkto ang maaaring natanggal sa proseso",
    bulkDelete: "Maramihang Tanggalin",
    youAreAboutToDelete: "Tatanggalin mo na",
    product: "Produkto",
    isDueToRedeem: "ay dahil sa pagtubos",
    aReward: "isang gantimpala",
    pleaseSelectCustomerToReeemReward:
      "Mangyaring pumili ng isang customer upang makuha ang gantimpala.",
    youHaveNoLoyaltyProgramRunning:
      "Wala kang aktibong programa sa loyalty na tumatakbo",
    customerHhasNoPointsInLoyaltyProgram:
      "Ang mga customer ay walang mga puntos sa loyalty program na ito",
    proceedWithPayment: "Magpatuloy sa Pagbabayad?",
    youAreAboutToPayForTransactionUsingPoints:
      "Magbabayad ka na para sa transaksyon gamit ang mga puntos.",
    customerHas: "Ang Customer Ay May",
    worth: "nagkakahalaga",
    andIsNotSufficientToPayForTransaction:
      "at hindi sapat upang magbayad para sa transaksyong ito. Nais ba nilang idagdag ang balanse gamit ang ibang paraan ng pagbabayad?",
    addCustomerLoyalty: "Magdagdag ng Customer Loyalty",
    pleaseAddCustomerFirst:
      "Mangyaring magdagdag o pumili muna ng isang customer.",
    pleaseWaitWhileWeGetReady: "Mangyaring maghintay, habang naghahanda kami",
    lowStock: "Mababang Stock",
    pleaseEnterAmountTendered: "Mangyaring ipasok ang halagang inilaan",
    areYouSureToBookSaleOffline:
      "Sigurado ka bang nais mong i-book ang pagbebenta na ito offline?",
    saleWouldBeBookedAutomatically:
      "Ang pagbebenta ay mai-book nang awtomatiko kapag binuksan mo ang iyong internet",
    offlineSalesBookingCancelled:
      "Nakansela ang pag-book ng mga benta sa offline",
    covid19Message:
      "COVID19: Hugasan ang iyong mga kamay ng sabon o Sanitize upang matigil ang pagkalat. Manatiling Ligtas Palagi",
    saleSuccessfullyRecorded: "Matagumpay na Naitala ang Pagbebenta!",
    sendReceiptToEmail: "Magpadala ng resibo sa Email",
    sendThankYouSms: "Magpadala ng SMS salamat",
    sendShippingDetails: "Magpadala ng mga detalye sa Pagpapadala",
    addLoyalty: "Magdagdag ng Katapatan",
    searchCustomerNameOrNumber: "Maghanap ng pangalan o numero ng customer",
    clickTheSearchCustomerBox: "I-click ang Search Customer Box at Scan Card",
    enterProductPrice: "Ipasok ang Presyo ng Produkto",
    enterPriceFor: "Ipasok ang Presyo para sa",
    searchForProduct: "Maghanap para sa produkto",
    all: "Lahat",
    backToDashboard: "Bumalik sa Dashboard",
    viewDraftSales: "Tingnan ang Draft Sales",
    variantSelected: "napiling variant",
    variant: "iba-iba",
    thePreviousVariantSelectionNotAvailable:
      "Ang dating pagpipilian ng variant ay hindi magagamit para sa bagong napiling variant batay sa presyo, mangyaring baguhin ang iyong pagpipilian.",
    pleaseSupplyPositiveQuantityNumber:
      "Mangyaring magbigay ng isang postive na bilang ng dami",
    lowStockFor: "Mababang stock para sa",
    clearVariants: "Malinaw na iba-iba",
    pleaseEnterQuantity: "Mangyaring ipasok ang Dami",
    picture: "Larawan",
    redemptionToken: "Token ng Katubusan",
    token: "Token",
    totalSpent: "Kabuuang Nagastos",
    confirmPayment: "Kumpirmahin ang Pagbabayad",
    hasPaymentBeenMade: "Matagumpay bang naproseso ang Bayad?",
    enterTransactionReference:
      "Ipasok ang sanggunian sa transaksyon na binayaran mo",
    pleaseSelectACustomer: "Mangyaring pumili ng isang customer!",
    areYouSureToApplyDiscount:
      "Sigurado ka bang nais mong maglapat ng diskwento?",
    addYourBankAccountToEnableUssd:
      "Idagdag ang iyong bank account upang paganahin ang Instant USSD transfer sa pamamagitan ng uPay",
    totalAmountToPay: "Kabuuang Halaga na Magbayad",
    doYouWantToCancelTransaction:
      "Nais mo bang kanselahin ang transaksyong ito?",
    savePrintBill: "Makatipid / Mag-print ng Bill",
    enterAmountCollectedForCustomer:
      "Ipasok ang halagang nakolekta para sa customer",
    recordSale: "Pagbebenta ng Record",
    checkOutWith: "Mag-check out sa",
    instantTransfer: "Instant Transfer",
    dialTheUSSDCode: "I-dial ang USSD code",
    pleaseSelectABank: "Mangyaring pumili ng isang bangko",
    payWithUSSD: "Magbayad Sa USSD",
    sendBillTo: " - Ipadala ang Bill kay",
    waitingForUSSDTransfer: "Naghihintay para sa USSD Transfer",
    percent: "Porsyento",
    applyDiscount: "Mag-apply ng Discount",
    thisBillHasBeenSaved: "Ang Bill na ito ay nai-save",
    saveDraft: "I-save ang Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Mangyaring mag-type sa isang pangalan upang makilala ang customer",
    paymentDetails: "Mga detalye ng pagbabayad",
    basePrice: "Batayang Presyo",
    staff: "Mga tauhan",
    subTotal: "SubTotal",
    durationMonths: "Tagal (buwan)",
    selectADuration: "Pumili ng isang Tagal",
    oneMonth: "1 buwan",
    twoMonths: "2 Buwan",
    threeMonths: "3 buwan",
    sixMonths: "6 Buwan",
    twelveMonths: "12 Buwan",
    eighteenMonths: "18 Buwan",
    twentyFourMonths: "24 Buwan",
    twoMonthsFree: "(2 Buwan Libre)",
    threeMonthsFree: "(3 Buwan Libre)",
    fiveMonthsFree: "(5 Buwan Libre)",
    yourAccountHasBeen: "Ang iyong Account ay naging",
    renewed: "binago",
    upgraded: "na-upgrade",
    successfully: "matagumpay",
    yourSubscription: "Ang iyong Subscription",
    youAreCurrentlyEnrolledOnA: "Kasalukuyan kang naka-enrol sa a",
    pleaseChooseAPaymentOption:
      "Mangyaring Pumili ng isang Pagpipilian sa Pagbabayad",
    planRenewal: "Pag-Renewal ng Plano",
    planUpgrade: "Pag-upgrade sa Plano",
    pleaseSelectDurationToPayFor:
      "Mangyaring Piliin ang Tagal na balak mong bayaran",
    staffAccounts: "Mga Account ng Staff",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Mangyaring pumili ng isang plano",
    includeAddons: "Isama ang Mga Add-on",
    viewTransactions: "Tingnan ang Mga Transaksyon",
    customerHasNoCompletedTansactions:
      "Ang customer ay hindi pa nakumpleto ang mga pakikipag-ugnay",
    branch: "Sangay",
    enterNumberOfEcommerceBranches:
      "Ipasok ang Bilang ng mga Sangay ng Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Ipasok ang Bilang ng mga Sangay ng Ecommerce Na Nilalayon mong Bayaran",
    ecommerceIntegration: "Pagsasama ng Ecommerce",
    enterNumberOfBranches: "Ipasok ang Bilang ng mga Sangay",
    enterNumberOfAdditionalBranchesToPay:
      "Ipasok ang Bilang ng Karagdagang Mga Sangay Na Nilalayon mong Bayaran",
    enterNumberOfStaffs: "Ipasok ang Bilang ng mga Tauhan",
    enterNumberOfStaffsToPayFor:
      "Ipasok ang Bilang ng mga Staff na Nilalayon mong Bayaran",
    discountApplies: "Nalalapat ang Discount",
    starsOnThe: "mga bituin sa",
    offer: "alok",
    get: "Kunin mo",
    moreStarsToRedeem: "mas maraming bituin upang matubos",
    taxVat: "Buwis (VAT)",
    callCashierToCompletePayment:
      "Tumawag sa Cashier upang Kumpletuhin ang Pagbabayad",
    receipt: "Resibo",
    dear: "Mahal",
    thankYouForYourOrderFindGoods:
      "Salamat sa iyong order. Mangyaring hanapin ang mga sumusunod na paninda na ibinigay, tulad ng napagkasunduan.",
    shippingNote: "Tala ng Pagpapadala",
    enterShippingNote: "Ipasok ang Tala ng Pagpapadala",
    shippingAddress: "Address sa pagpapadala",
    enterShippingAddress: "Ipasok ang address sa Pagpapadala",
    wellDoneYouAreDueToRedeem: "Magaling! Ikaw ay dahil sa pagtubos",
    toGetYourRewardNextVisit:
      "upang makuha ang iyong gantimpala sa iyong susunod na pagbisita. Salamat",
    pointsOnThe: "Mga puntos sa",
    morePointsToRedeem: "higit pang mga point upang matubos",
    showCode: "Ipakita ang code",
    toGetYourRewardOnNextVisit:
      "upang makuha ang iyong gantimpala sa iyong susunod na pagbisita. Salamat",
    earn: "Kumita",
    delivaryNote: "Tala ng Delivary",
    draftSales: "Draft Sales",
    startDate: "Petsa ng pagsisimula",
    endDate: "Petsa ng pagtatapos",
    orders: "Mga order",
    checkout: "Tignan mo",
    noProductItem: "Walang Item ng Produkto",
    selectProductImage: "Piliin ang Imahe ng Produkto",
    selectCountry: "Piliin ang bansa",
    selectRegion: "Piliin ang Estado / Rehiyon",
    printProductTag: "I-print ang Tag ng Produkto",
    transactionReference: "Sanggunian sa transaksyon",
    Cashier: "Cashier",
    Manager: "Manager",
    Owner: "May-ari",
    Admin: "Admin",
    addPartners: "Magdagdag ng Mga Kasosyo",
    addNewLoyaltyPartner: "Magdagdag ng Bagong Loyalty Partner",
    pleaseEnterCompanyName: "Pakilagay ang Pangalan ng Kumpanya",
    companyName: "pangalan ng Kumpanya",
    pleaseEnterCompanyRepName:
      "Pakilagay ang Pangalan ng kinatawan ng kumpanya",
    companyRepName: "Pangalan ng Kinatawan ng Kumpanya",
    pleaseEnterCompanyRepEmail: "Pakipasok ang email ng kinatawan ng kumpanya",
    companyRepEmail: "Email ng kinatawan ng kumpanya",
    pleaseEnterCompanyRepPhone:
      "Mangyaring ipasok ang numero ng telepono ng kinatawan ng kumpanya",
    companyRepPhone: "Numero ng Telepono ng kinatawan ng kumpanya",
    addReward: "Magdagdag ng gantimpala",
    pleaseEnterRewardName: "Pakilagay ang pangalan ng reward",
    rewardName: "Pangalan ng Gantimpala",
    rewardQuantity: "Dami ng Gantimpala",
    rewardDescription: "Paglalarawan ng Gantimpala",
    rewardType: "Uri ng Gantimpala",
    pleaseEnterRewardType: "Pakilagay ang uri ng reward",
    pleaseEnterRewardQuantity: "Pakilagay ang dami ng reward",
    pleaseEnterRewardDescription: "Pakilagay ang paglalarawan ng reward",
    fineDining: "Fine Dining",
    luxuryFashion: "Marangyang Fashion",
    hotels: "Mga hotel",
    travel: "Paglalakbay",
    foodAndBeverage: "Pagkain at Inumin",
    fashion: "Fashion",
    health: "Kalusugan",
    furniture: "Muwebles",
    entertainment: "Aliwan",
    automobile: "Sasakyan",
    education: "Edukasyon",
    beautyAndSpa: "Kagandahan at Spa",
    staycation: "Staycation",
    events: "Mga kaganapan",
    trips: "Mga biyahe",
    oilAndGas: "Langis at Gas",
    laundry: "Paglalaba",
    partnerCategory: "Kategorya ng Kasosyo",
    freeItem: "Libreng Item",
  },
  French: {
    cashier: "la caissière",
    manager: "directeur",
    owner: "propriétaire",
    online: "en ligne",
    offline: "hors ligne",
    changePassword: "Changer le mot de passe",
    currentPasswordMessage: "Veuillez saisir votre mot de passe actuel",
    passwordMessage: "s'il vous plait entrez votre mot de passe",
    currentPassword: "Mot de passe actuel",
    password: "le mot de passe",
    confirmPasswordMessage: "Veuillez confirmer votre mot de passe !",
    confirmPassword: "Confirmez le mot de passe",
    sendViaEmail: "Envoyer par e-mail",
    sendViaWhatsapp: "Envoyer via WhatsApp",
    downloadPdf: "Télécharger le PDF",
    paid: "payé",
    unpaid: "non payé",
    partial: "partiel",
    closeInvoice: "Voulez-vous fermer la facture ?",
    closeInvoiceConfirmation:
      "La facture peut ne pas être enregistrée. Voulez-vous fermer?",
    yes: "Oui",
    no: "non",
    invoice: "Facture d'achat",
    wasDeducted: "a été déduit",
    for: "pour",
    item: "Article",
    addProduct: "Ajouter un produit",
    paymentReference: "Référence de paiement",
    amountPaid: "Le montant payé",
    discountAmount: "Montant de la remise",
    amountDue: "Montant dû",
    amount: "Montant",
    dueDate: "Date d'échéance",
    paymentType: "Type de paiement",
    card: "Carte",
    cash: "Espèces",
    bankTransfer: "Virement",
    paymentMessage: "Message de paiement",
    description: "La description",
    sendReceipt: "Envoyer un reçu",
    delete: "Effacer",
    save: "sauvegarder",
    resend: "Renvoyer",
    saveAndSend: "sauvegarder",
    invoiceSaved: "Facture enregistrée !",
    selectPaymentMethod: "Veuillez choisir un moyen de paiement!",
    selectCustomer: "Veuillez sélectionner un client !",
    cartEmptyError:
      "La liste du panier ne peut pas être vide, fermez la facture et ajoutez l'article au panier !",
    subscriptionExpired:
      "Votre abonnement a expiré et votre compte est désormais limité. Cliquez sur le bouton ci-dessous pour renouveler votre compte",
    renew: "Renouveler",
    holdOn: "Attendez",
    customerBank: "Banque client",
    cancel: "Annuler",
    selectACustomer: "Sélectionnez le client",
    invoiceSuccessfulPdfError:
      "Facture créée avec succès, mais la génération de PDF prend plus de temps que d'habitude. Veuillez vérifier sous peu.",
    downloadingInvoice: "Télécharger la facture",
    downloadingReceipt: "Téléchargement du reçu",
    whatsappReceiptError:
      "Une erreur s'est produite lors de l'envoi du reçu via WhatsApp, veuillez réessayer.",
    receiptToWhatsapp: "Reçu transmis à WhatsApp",
    thankYouForPatronage: "Merci pour votre parrainage",
    hereIsYourReceipt: "Voici votre reçu de paiement",
    errorSendingEmailReceipt:
      "Une erreur s'est produite lors de l'envoi du reçu par e-mail, veuillez réessayer ou contacter l'assistance",
    receiptSentToEmail: "Le reçu a été envoyé à l'adresse e-mail du client",
    invoiceSentToEmail: "La facture a été envoyée à l'adresse e-mail du client",
    invoiceSuccessWhatsappError:
      "Facture créée avec succès mais une erreur s'est produite lors de l'envoi à WhatsApp. Réessayez sur la liste des factures",
    invoiceSuccessfulEmailError:
      "Facture créée avec succès mais une erreur s'est produite lors de l'envoi par e-mail. Veuillez réessayer à partir de la liste des factures",
    invoiceSentToWhatsapp: "Facture transmise à WhatsApp",
    hello: "Bonjour",
    pleaseDownloadInvoice: "Veuillez télécharger la facture",
    pleaseDownloadReceipt: "Veuillez télécharger le reçu",
    from: "de",
    email: "E-mail",
    upgrade: "Améliorer",
    youAreOnFreePlan: "Vous êtes sur un plan gratuit.",
    clickOn: "Cliquer sur",
    yourPlanInFewSteps: " votre abonnement en quelques étapes rapides.",
    to: "à",
    yourSubscriptionHasExpired:
      "Votre abonnement a expiré et votre compte est désormais limité.",
    days: "jours",
    yourSubscriptionExpiresIn: "Votre abonnement Loystar expire dans",
    createAcount: "Créer un compte",
    signIn: "S'identifier",
    continue: "Continuer",
    enterOtp: "Entrez le code PIN OTP",
    enterValidOtp: "Veuillez saisir un code PIN valide",
    pin: "ÉPINGLER",
    tokenSentToMail: "Un jeton a été envoyé à votre adresse e-mail",
    passwordResetSuccessful: "La réinitialisation du mot de passe a réussi",
    somethingWentWrong: "Quelque chose s'est mal passé !",
    resetPassword: "réinitialiser le mot de passe",
    iHaveResetCode: "J'ai un code de réinitialisation de mot de passe",
    pleaseEnterEmail: "Veuillez entrer votre email",
    aTokenWillBeSentToEmail: "Un jeton serait envoyé à votre adresse e-mail",
    enterEmail: "Entrer votre Email",
    sendinYourToken: "Envoi de votre jeton...",
    makeSureItMatchesPassword:
      "Assurez-vous qu'il correspond à votre nouveau mot de passe",
    pleaseChooseNewPassword: "Veuillez choisir un nouveau mot de passe",
    chooseNewPassword: "Choisissez un nouveau mot de passe",
    enterNewPassword: "Entrez votre nouveau mot de passe pour confirmer",
    enterTokenSent: "Entrez le jeton qui a été envoyé à votre courrier",
    resetToken: "Jeton de réinitialisation",
    resettingPassword: "Réinitialisation de votre mot de passe...",
    signUp: "S'inscrire",
    adminSignInWithEmail:
      " L'administrateur se connecte par e-mail tandis que le personnel se connecte avec le nom d'utilisateur.",
    pleaseEnterEmailOrUsername:
      "Veuillez entrer votre email ou nom d'utilisateur",
    emailOrUsername: "E-mail ou nom d'utilisateur",
    pleaseEnterPassword: "Veuillez entrer le mot de passe",
    createAnAccount: "Créer un compte",
    forgotPassword: "Mot de passe oublié?",
    enterPhoneNumber: "Entrez le numéro de téléphone",
    personalData: "Données personnelles",
    validateConfirmationCOde: "Valider le code de confirmation",
    pleaseEnterFirstName: "Entrez votre prénom s'il vous plait",
    pleaseEnterPhoneNumber: "Veuillez entrer votre numéro de téléphone",
    pleaseEnterLastName: "Veuillez entrer votre nom de famille",
    pleaseEnterBusinessName: "Veuillez saisir le nom de votre entreprise",
    firstName: "Prénom",
    lastName: "Nom de famille",
    businessName: "Nom de l'entreprise",
    previous: "Précédent",
    next: "Prochain",
    pleaseSelectBusinessCategory:
      "Veuillez sélectionner votre catégorie d'entreprise",
    pleaseEnterValidEmail: "Veuillez entrer un email valide",
    pleaseEnterPostCode: "Veuillez saisir le code postal",
    postCode: "Code postal",
    phoneNumberInUse: "Ce numéro de téléphone est déjà utilisé !",
    emailInUse: "Cet e-mail est déjà utilisé !",
    foodAndDrinks: "Nourriture et boissons",
    salonAndBeauty: "Salon et Beauté",
    fashionAndAccessories: "Mode et Accessoires",
    gymAndFitness: "Gym et remise en forme",
    travelAndHotel: "Voyage et Hôtel",
    homeAndGifts: "Maison et Cadeaux",
    washingAndCleaning: "Lavage et nettoyage",
    gadgetsAndElectronics: "Gadgets et Electronique",
    groceries: "Épiceries",
    others: "Autres",
    submit: "Soumettre",
    accountCreatedSuccessful: "Votre compte a été créé avec succès.",
    pleaseEnterAddress: "Veuillez entrer votre adresse",
    addressLine1: "Adresse 1",
    addressLine2: "Adresse Ligne 2",
    choosePassword: "Choisissez un mot de passe",
    passwordMustBe6Characters:
      "Le mot de passe doit être au moins de 6 caractères.",
    howDidYouHearLoystar: "Comment avez-vous entendu parler de Loystar ?",
    referralCode: "Code de Parrainage",
    byClickingTheButton:
      " En cliquant sur le bouton ci-dessous, vous acceptez les",
    termsAndSevice: "termes",
    wereCreatingAccount: "Nous créons votre compte",
    proceed: "Procéder",
    verificationCodeMustBe6:
      "Le code de vérification doit être composé de 6 chiffres",
    pleaseEnter6DigitCode: "Veuillez saisir un code à 6 chiffres",
    enterVerificationCode: "Entrez le code de vérification",
    resendToken: "Renvoyer le jeton",
    verify: "Vérifier",
    transactions: "Transactions",
    todaySales: "Les ventes du jour",
    salesHistory: "Historique des ventes",
    supplyHistory: "Historique d'approvisionnement",
    new: "Nouveau",
    invoices: "Factures",
    disbursements: "Décaissements",
    offlineSales: "Ventes hors ligne",
    products: "Des produits",
    customers: "Les clients",
    multiLevelLoyalty: "Fidélité à plusieurs niveaux",
    loyaltyPrograms: "Programmes de fidélité",
    members: "Membres",
    appStore: "Magasin d'applications",
    orderMenu: "Menu de commande",
    settings: "Paramètres",
    staffAndBranches: "Personnels et succursales",
    myAccount: "Mon compte",
    switchToSellMode: "Passer en mode vente",
    signOut: "Se déconnecter",
    getFreeSubscription: "Obtenez un abonnement gratuit",
    onlyNumbersAllowed: "Seuls les chiffres sont autorisés",
    yourAccountMustBe10Digits:
      "votre numéro de compte doit être un numéro à 10 chiffres",
    yourBvnMustBe11: "votre BVN doit être un numéro à 11 chiffres",
    pleaseSelectBank: "Veuillez sélectionner votre banque",
    selectYourBank: "Sélectionnez votre banque",
    enterBankAccountNumber: "Entrez le numéro de compte bancaire",
    enterBvnNumber: "Entrez votre BVN",
    connectBankAccount: "Connecter un compte bancaire",
    passwordResetSuccessfulAndSignOut:
      "Votre mot de passe a été réinitialisé avec succès. Cliquez sur le bouton Déconnexion ci-dessous pour vous reconnecter",
    enterCurrentPassword: "Saisissez le mot de passe actuel",
    pleaseEnterCurrentPassword: "Veuillez saisir le mot de passe actuel",
    phoneNumber: "Numéro de téléphone",
    sex: "Sexe",
    dateOfBirth: "Date de naissance",
    state: "État",
    country: "Pays",
    loyaltyId: "Identifiant de fidélité",
    createdAt: "Créé à",
    noOfTransactionsMade: "Nombre de transactions effectuées",
    yourDownloadWillStart: "Votre téléchargement va commencer dans un instant",
    exportCustomers: "Clients d'exportation",
    youhaveSuccessfullyToppedUp:
      "Vous avez rechargé avec succès vos unités SMS.",
    youNowHave: "Vous avez maintenant",
    smsUnits: "Unités SMS",
    enterNumberOfSmsUnits:
      "Entrez le nombre d'unités SMS que vous avez l'intention d'acheter",
    pleaseEnterANumericValue: "Veuillez saisir une valeur numérique",
    pay: "Payer",
    accountEditedSuccessfully: "Votre compte a été modifié avec succès.",
    youAeCurrentlyOn: "Vous êtes actuellement sur",
    plan: "Plan",
    userData: "Données d'utilisateur",
    businessData: "DONNÉES COMMERCIALES",
    businessCategory: "Catégorie d'entreprise",
    pleaseSelectCurrency: "Veuillez sélectionner votre devise",
    selectYourCurrency: "Sélectionnez votre devise",
    purchaseMoreSmsUnits:
      "Achetez plus d'unités SMS en utilisant le formulaire ci-dessous",
    youHave: "Tu as",
    atLeast4SmsUnitsRrequired:
      "Au moins 4 unités de SMS sont nécessaires pour la vérification, veuillez recharger !",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Veuillez vérifier votre compte bancaire pour vous conformer aux exigences « Connaître votre client » (KYC). Cela vous permettra de collecter les paiements via USSD ou par virement instantané, de recevoir les commandes des clients et de traiter le règlement des transactions. Applicable aux marchands nigérians uniquement. Veuillez appuyer sur le bouton ci-dessous pour commencer.",
    reConnectBankAccount: "Reconnecter le compte bancaire",
    accountName: "Nom du compte",
    accountNumber: "Numéro de compte",
    bankName: "Nom de banque",
    verified: "Vérifié",
    accountDetails: "Détails du compte",
    kycBankAccount: "KYC",
    createTier: "Créer un niveau",
    fileUploadSuccessful: "fichier téléchargé avec succès",
    fileUploadFailed: "le téléchargement du fichier a échoué",
    createLoyaltyProgram: "Créer un programme de fidélité",
    createLoyalty: "Créer de la fidélité",
    name: "Nom",
    loyaltyArtwork: "Oeuvre de fidélité",
    clickToUpload: "Cliquez pour télécharger",
    amountToPointsRatio: "Ratio montant/points",
    points: "Points",
    recommendedAmountToPointRatio:
      "Recommandé : ₦1 à 1point. c'est-à-dire pour chaque 1 Naira dépensé, vos clients obtiennent 1 point",
    pleaseTypeIn: "Veuillez saisir",
    toDeleteLoyalty: "supprimer cette fidélité",
    deleteLoyalty: "Supprimer la fidélité",
    toConfirm: "confirmer",
    edit: "Éditer",
    pointsAwardedSuccessfully: "Points attribués avec succès.",
    enterPointValueToAward:
      "Entrez la valeur du point que vous souhaitez attribuer aux clients",
    award: "Prix",
    awardPointValuesToCustomer: "Attribuer la valeur des points au client",
    enrollMembersToLoyalty: "Inscrire des membres à Loyalty",
    awardPoints: "Points de récompense",
    enroll: "Inscrire",
    home: "Accueil",
    loyalty: "Fidélité",
    enrollCustomers: "Inscrire des clients",
    selected: "Choisi",
    customer: "Client",
    loyaltyActivationSuccessful: "L'activation de la fidélité est réussie.",
    loyaltyDeactivationSuccessful:
      "La désactivation de la fidélité est réussie.",
    viewTier: "Afficher le niveau",
    deactivate: "Désactiver",
    activate: "Activer",
    actions: "Actions",
    nameOfLoyalty: "Nom de fidélité",
    loyaltyStatus: "Statut de fidélitéStatut de fidélité",
    numberOfTiers: "Nombre de niveaux",
    createdOn: "Créé sur",
    createATier: "Créer un niveau",
    stopCreatingTierConfirmation: "Voulez-vous arrêter de créer un niveau ?",
    stopEditingTierConfirmation: "Voulez-vous arrêter de modifier ce niveau ?",
    nameOfTier: "Nom du niveau",
    minimumSpendingTarget: "Objectif de dépenses minimales",
    maximumSpendingTarget: "Objectif de dépenses maximales",
    minimumSpendingTargetRequired: "un objectif de dépenses minimum est requis",
    maximumSpendingTargetRequired: "un objectif de dépenses maximum est requis",
    rewardSponsor: "Commanditaire de récompense",
    pleaseChooseARewardSponsor: "Veuillez choisir un sponsor de récompense",
    self: "Soi",
    partner: "Partenaire",
    rewardPartner: "Partenaire de récompense",
    pleaseSelectRewardPartner:
      "Veuillez sélectionner votre partenaire de récompense",
    rewards: "Récompenses",
    pleaseSelectAReward: "Veuillez sélectionner une récompense",
    instructionsOnRedeemingReward:
      "Instructions sur la façon dont le client doit utiliser la récompense",
    pleaseFillInThisField: "Veuillez remplir ce champ !",
    toDeleteThisTier: " supprimer ce niveau",
    deleteTier: "Supprimer le niveau",
    viewMembers: "Afficher les membres",
    membersEnrolled: "Membres inscrits",
    instruction: "Instruction",
    membersIn: "Membres en",
    availableTiersInLoyalty:
      "Niveau(x) disponible(s) dans le programme de fidélité",
    tiers: "Niveaux",
    totalTier: "NIVEAU TOTAL",
    availableLoyaltyProgramme: "Programme de fidélité disponible",
    totalLoyalties: "FIDÉLISATION TOTALE",
    memberDetails: "Détails du membre",
    nameOfCustomer: "Nom du client",
    address: "Adresse",
    allEnrolledMembers: "Tous les membres inscrits",
    thisIsToWishYouHappyBirthday:
      "C'est pour vous souhaiter un très joyeux anniversaire et une vie prospère. Merci pour tout ce que vous êtes pour Loyster. Joyeuses Fêtes !",
    inputAnOfferPlease: "Saisissez une offre s'il vous plaît",
    pleaseSelectTheInsertPoint:
      "Veuillez sélectionner le point d'insertion dans le message et cliquez à nouveau",
    birthdayOfferAndMessage: "Offre d'anniversaire et message",
    birthdayOffer: "Offre anniversaire",
    birthdayMessage: "Message d'anniversaire",
    noOfferFound: "Aucune offre trouvée",
    settingABirthdayOffer:
      "La mise en place d'une offre anniversaire permet aux clients de recevoir cette offre par SMS le jour de leur anniversaire",
    createOffer: "Créer une offre",
    whatIsTheOffer: "Quelle est l'offre ?",
    editMessage: "Modifier le message",
    insert: "Insérer",
    theNameOfCustomerInserted: "Le nom du client sera inséré ici",
    theBirtdayOfferInserted: "L'offre d'anniversaire sera insérée ici",
    youSuccessfullyAddedNewBranch:
      "Vous avez ajouté une nouvelle branche avec succès !",
    addNewBranch: "Ajouter une nouvelle succursale",
    addBranch: "Ajouter une succursale",
    additionalBranchWillIncur: "Une succursale supplémentaire entraînerait",
    perBranch: "par succursale",
    ecommerceBranchCosts: "Coûts de la succursale de commerce électronique",
    pleaseEnterBranchName: "Veuillez saisir le nom de la succursale",
    pleaseEnterBranchAddress1:
      "Veuillez entrer l'adresse de la succursale ligne 1",
    enterBranchAddress1: "Entrez l'adresse de la succursale ligne 1",
    enterBranchAddress2: "Entrez l'adresse de la succursale ligne 1",
    pleaseEnterBranchAddress2:
      "Veuillez entrer l'adresse de la succursale ligne 2",
    enterBranchName: "Entrez le nom de la succursale",
    successfullyAddedStaff:
      "Vous avez ajouté avec succès une nouvelle équipe !",
    addNewStaff: "Ajouter un nouveau personnel",
    addStaff: "Ajouter du personnel",
    additionalStaffWillIncur: "Le personnel supplémentaire entraînerait",
    perStaff: "par personnel.",
    pleaseEnterStaffEmail: "Veuillez entrer l'e-mail du personnel",
    pleaseEnterStaffUsername:
      "Veuillez saisir le nom d'utilisateur du personnel",
    pleaseEnterStaffPassword: "Veuillez saisir le mot de passe du personnel",
    pleaseSelectStaffRole: "Veuillez sélectionner le rôle du personnel",
    selectStaffRole: "Sélectionnez le rôle du personnel",
    enterStaffEmail: "Entrez l'email du personnel",
    enterStaffUsername: "Entrez le nom d'utilisateur du personnel",
    enterStaffPassword: "Entrez le mot de passe du personnel",
    spacesNotAllowedInUsername:
      "espaces non autorisés dans le nom d'utilisateur",
    admin: "Administrateur",
    pleaseSelectBusinessToAttachStaff:
      "Veuillez sélectionner une entreprise pour attacher du personnel",
    searchForBranchToAttachStaff:
      "Rechercher une succursale pour attacher du personnel",
    username: "Nom d'utilisateur",
    role: "Rôle",
    areYouSureToDeleteThis: "Êtes-vous sûr de supprimer ce",
    branches: "Branches",
    upgradeYourPlan: "Améliorez votre plan.",
    add: "AJOUTER",
    addNew: "Ajouter un nouveau",
    customerWithEmailAlreadyExists:
      "Le client avec e-mail/numéro de téléphone existe déjà !",
    successfullyAddedNewCustomer:
      "Vous avez ajouté un nouveau client avec succès !",
    addCustomer: "Ajouter un client",
    pleaseEnterCustomerFirstName: "Veuillez saisir le prénom du client",
    pleaseEnterCustomerLastName: "Veuillez saisir le nom de famille du client",
    pleaseEnterCustomerPhoneNumber:
      "Veuillez saisir le numéro de téléphone du client",
    pleaseEnterCustomerEmail: "Veuillez saisir l'e-mail du client",
    pleaseEnterCustomerAddressLine:
      "Veuillez saisir la ligne d'adresse du client",
    pleaseEnterCustomerOtherAddress:
      "Veuillez entrer l'autre adresse du client",
    pleaseSelectCustomerGender: "Veuillez sélectionner le sexe du client",
    gender: "Genre",
    male: "Homme",
    female: "Femelle",
    bank: "Banque",
    selectBank: "Sélectionnez la banque",
    stateOrRegionOrProvince: "État/Région/Province",
    customerNotes: "Remarques client",
    sendSms: "Envoyer un SMS",
    editCustomer: "Modifier le client",
    redeemReward: "Échanger la récompense",
    issueLoyaltyCard: "Émettre une carte de fidélité",
    deleteCustomer: "Supprimer le client",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Vous avez attribué avec succès un ID de membre de fidélité",
    noMembershipIdAvailable:
      "Aucun identifiant de membre disponible. Veuillez nous contacter sur hello@loystar.co",
    sendEmail: "Envoyer un e-mail",
    membershipPoints: "Points d'adhésion",
    customerDetails: "Détails du client",
    close: "proche",
    loyaltyBalance: "Solde de fidélité",
    pointsBalance: "Solde des points",
    starBalance: "Solde des étoiles",
    requiredPoints: "Points requis",
    requiredStars: "Étoiles requises",
    reddemCode: "Utiliser le code",
    toDeleteThisCustomer: "supprimer ce client",
    customerHasBeenDeletedSuccessfully:
      "Le client a été supprimé avec succès !",
    customerWithPhoneAlreadyExists:
      "Le client avec numéro de téléphone existe déjà",
    customerWasSuccessfullyEdited: "Le client a été modifié avec succès",
    anErrorOccured: "Une erreur s'est produite",
    phoneNumberIncludeCountry: "Numéro de téléphone (Inclure le code du pays)",
    yourFileQueuedForUpload:
      "Votre fichier a été mis en file d'attente pour le téléchargement. Veuillez actualiser la page après quelques secondes.",
    thereWasAnErrorPerformingOperation:
      "Une erreur s'est produite lors de l'exécution de l'opération !",
    pleaseSelectFile: "Veuillez sélectionner un fichier !",
    oopsOnlyCsvFilesAllowed:
      "Oups! Seuls les fichiers CSV sont autorisés. Veuillez télécharger un fichier .csv.",
    fileShouldBeSmallerThan5Mb:
      "Le fichier doit être inférieur à 5 Mo. Si vous avez un fichier plus volumineux, veuillez envoyer un e-mail à support@loystar.co. Merci",
    customerFirstNameIsRequired: "le prénom du client est requis sur la ligne",
    customerPhoneNumberIsRequired:
      "le numéro de téléphone du client est requis sur la ligne",
    importCustomers: "Importer des clients",
    upload: "Télécharger",
    clickIconToDownloadCsv:
      "Cliquez sur cette icône pour télécharger le modèle de fichier CSV.",
    getGoogleSheetVersion: "Obtenir la version de la feuille Google",
    clickOrDragFileToUpload:
      "Cliquez ou faites glisser le fichier dans cette zone pour le télécharger",
    missingOrInvalidColumnHeader:
      "En-tête de colonne manquant ou invalide. Veuillez suivre le format du modèle. Merci.",
    youHaveImported: "Vous avez importé",
    youSuccessfullyRedeemedReward:
      "Vous avez récupéré votre récompense avec succès !",
    sixDigitCode: "Code à six chiffres",
    pleaseEnterCustomerRewardCode:
      "Veuillez saisir le code de récompense du client",
    enterRewardCode:
      "Entrez le code de récompense. (Le code de récompense est sensible à la casse)",
    selectLoyaltyProgram: "Sélectionnez le programme de fidélité",
    stamps: "Timbres",
    smsUnitsLow: "Unités SMS faible",
    pleaseTopUpSmsUnits: "Veuillez recharger des unités SMS",
    smsSuccessfullySentForDelivery: "SMS envoyé avec succès pour la livraison!",
    sendSmsTo: "Envoyer des SMS à",
    allCustomers: "Tous les clients",
    unitsAvailable: "Unités disponibles",
    pleaseTypeInTheMessage: "Veuillez saisir le message",
    message: "Un message",
    charactersRemaining: "caractères restants",
    avoidUseOfSpecialCharacters:
      "Évitez l'utilisation de caractères spéciaux et d'émojis pour conserver les unités SMS.",
    note: "Noter",
    errorFetchingCustomersMultilevelDetail:
      "Erreur lors de la récupération des détails multiniveaux des clients",
    search: "Chercher",
    reset: "Réinitialiser",
    importCustomer: "Importer le client",
    addNewCustomer: "Ajouter un nouveau client",
    sendSmsBroadcast: "Envoyer une diffusion SMS",
    totalCustomers: "CLIENTS TOTAL",
    disbursementDetails: "Détails du décaissement",
    paidBy: "Payé par",
    disbursed: "Décaissé",
    bankAccountName: "Nom du compte bancaire",
    bankAccountNumber: "Numéro de compte bancaire",
    transferInitiated: "Transfert lancé",
    transferCompleted: "Transfert terminé",
    pleaseEnterAValid: "Veuillez saisir une valeur valide",
    begin: "commencer",
    end: "finir",
    date: "Date",
    paymentDate: "Date de paiement",
    selectDisbursementDuration: "Sélectionnez la durée du décaissement",
    totalSettled: "Total réglé",
    totalUnsettled: "Total non réglé",
    toDeleteThisSale: "supprimer cette vente",
    draftSaleDeletedSuccessfully:
      "Le brouillon de vente a été supprimé avec succès !",
    deleteSale: "Supprimer la vente",
    pleaseTypeInYourTotal:
      "Veuillez saisir votre total pour confirmer la suppression",
    billDetails: "Détails de la facture",
    printBill: "Imprimer la facture",
    servedBy: "Servi par",
    total: "Le total",
    createdDate: "Date de création",
    createdTime: "Heure de création",
    status: "Statut",
    loginSuccessful: "Connexion réussie",
    pleaseWaitWhileWeConnectAccount:
      "Veuillez patienter pendant que nous connectons votre compte",
    connectionFailedTryAgain: "La connexion a échoué. Veuillez réessayer.",
    connectionSuccessful: "Connexion réussie",
    viewDetails: "Voir les détails",
    enable: "Permettre",
    free: "Libérer",
    cost: "Coût",
    visitWebsite: "Visitez le site Web",
    pleaseUpgradeYourPlanToPro:
      "Veuillez mettre à niveau votre plan vers Pro Plus pour activer cette application",
    connectYourBankAccount:
      "Connectez votre compte bancaire pour pouvoir recevoir des paiements.",
    disable: "Désactiver",
    enableApp: "Activer l'application",
    addNewProductToInvoice: "Ajouter un nouveau produit à la facture",
    toDeleteThisInvoice: "pour supprimer cette facture",
    invoiceDeletedSuccessfully: "La facture a été supprimée avec succès !",
    deleteInvoice: "Supprimer la facture",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Vous avez ajouté avec succès un message de paiement de facture !",
    pleaseEnterPaymentInstructions:
      "Veuillez saisir les instructions de paiement",
    invoiceId: "Identifiant de la facture",
    paidDate: "La date de paiement",
    reference: "Référence",
    productAdded: "Produit ajouté",
    productOutOfStock:
      "Produit en rupture de stock. Veuillez réapprovisionner.",
    totalInvoices: "TOTAL DES FACTURES",
    totalPaidInvoices: "TOTAL DES FACTURES PAYÉES",
    totalUnpaidInvoices: "TOTAL DES FACTURES IMPAYÉES",
    loyaltyProgramDeleted: "Programme de fidélité supprimé",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ce programme de fidélité a été supprimé avec succès",
    thisLoyaltyProgramEditedSuccessfully:
      "Ce programme de fidélité a été modifié avec succès",
    loyaltyProgramAddedSuccessfully:
      "Le programme de fidélité a été ajouté avec succès",
    loyaltyProgramEdited: "Programme de fidélité modifié",
    loyaltyProgramAdded: "Programme de fidélité ajouté",
    loyaltyDetails: "Détails de fidélité",
    doYouWantToCloseDialog: "Voulez-vous fermer cette boîte de dialogue ?",
    pleaseEnterLoyaltyName: "Veuillez saisir le nom de votre fidélité",
    programType: "Type de programme",
    pleaseSelectProgramType: "Veuillez sélectionner un type de programme",
    simplePoints: "Points simples",
    stampsProgram: "Programme de timbres",
    threshold: "Seuil",
    pleaseEnterLoyaltyThreshold: "Veuillez saisir le seuil de fidélité",
    reward: "Récompense",
    pleaseEnterLoyaltyReward: "Veuillez saisir la récompense de la fidélité",
    totalUserPoints: "Total des points d'utilisateur",
    totalUserStamps: "Nombre total de tampons utilisateur",
    spendingTarget: "Objectif de dépenses",
    spendingTargetHint1:
      "L'objectif de dépenses est le montant qu'un client doit dépenser pour gagner la récompense. 1 valeur monétaire = 1 point.",
    spendingTargetHint2:
      "L'objectif de timbres est le nombre de timbres qu'un client doit collecter pour gagner la récompense. par exemple. 5",
    addNewLoyaltyProgram: "Ajouter un nouveau programme de fidélité",
    addLoyaltyProgram: "Ajouter un programme de fidélité",
    loyaltyProgramType: "Type de programme de fidélité",
    pleaseSelectLoyaltyProgramType:
      "Veuillez sélectionner le type de programme de fidélité",
    nameOfProgram: "Nom du programme",
    pleaseEnterProgramName: "Veuillez saisir le nom du programme",
    whatIsTheReward: "Quelle est la récompense ?",
    egNextPurchaseIsFree: "Par exemple. Le prochain achat est gratuit",
    customerName: "Nom du client",
    guestCustomer: "Client invité",
    orderConfirmedSuccessfully: "Commande confirmée avec succès",
    orderCancelledSuccessfully: "Commande annulée avec succès",
    confirmOrder: "Confirmer la commande",
    cancelOrder: "Annuler la commande",
    allOrders: "Tous les ordres",
    totalOrders: "TOTAL DES COMMANDES",
    doYouWantToAcceptOrder: "Voulez-vous accepter cette commande ?",
    doYouWantToCancelOrder: "Voulez-vous annuler cette commande ?",
    orderDetails: "détails de la commande",
    orderCreatedAt: "Commande créée à",
    supplier: "Le fournisseur",
    productName: "Nom du produit",
    quantity: "Quantité",
    unitPrice: "Prix unitaire",
    receivedBy: "Reçu par",
    reportFrom: "Rapport de",
    totalSupplies: "Fournitures totales",
    allRightsReserved: "Tous les droits sont réservés",
    toDeleteThisTransaction: "pour supprimer cette transaction",
    transactionDeletedSuccessfully:
      "La transaction a été supprimée avec succès. Le stock a été remis à l'inventaire.",
    deleteTransaction: "Supprimer la transaction",
    transactionDetails: "détails de la transaction",
    printReceipt: "Imprimer le reçu",
    channel: "Canaliser",
    discount: "Remise",
    profit: "Profit",
    discountedSales: "Ventes à prix réduits",
    errorFetchingRecord: "Erreur lors de la récupération de l'enregistrement",
    exportTransactions: "Opérations d'exportation",
    errorFetchingSalesRecord:
      "Erreur lors de la récupération de l'enregistrement des ventes pour l'exportation.",
    totalSellingPrice: "Prix de vente total",
    totalCostPrice: "Prix de revient total",
    appliedDiscount: "Remise appliquée",
    noOfItems: "Nombre d'articles",
    sales: "Ventes",
    export: "Exportation",
    totalProfit: "Bénéfice total",
    totalBalanceInPeriod: "Solde total de la période",
    allTimeSales: "Ventes de tous les temps",
    records: "Enregistrements",
    todaysSales: "Les ventes du jour",
    transaction: "transaction",
    youHaveExceededTotalNumberOfProducts:
      "Vous avez dépassé le nombre total de produits autorisés sur votre forfait. Améliorez votre plan pour profiter d'une limite plus élevée.",
    youNeedToHaveLoyaltyProgram:
      "Vous devez avoir un programme de fidélité pour utiliser cette fonctionnalité !",
    price: "Prix",
    category: "Catégorie",
    stockTracking: "Suivi des stocks",
    stockCount: "Décompte des stocks",
    taxed: "Taxé",
    originalPrice: "Prix d'origine",
    costPrice: "Prix de revient",
    unit: "Unité",
    productImage: "Image du produit",
    taxRate: "Taux d'imposition",
    taxType: "Type de taxe",
    trackInventory: "Suivre l'inventaire",
    variants: "Variantes",
    hasVariants: "A des variantes",
    importProduct: "Importer un produit",
    exportProducts: "Produits d'exportation",
    addNewProduct: "Ajouter un nouveau produit",
    viewCategory: "Voir la catégorie",
    viewSuppliers: "Voir les fournisseurs",
    receiveInventory: "Recevoir l'inventaire",
    printAllProductsTag: "Imprimer l'étiquette de tous les produits",
    deleteAll: "Tout supprimer",
    totalProducts: "PRODUITS TOTAL",
    youveSuccessfullyAddedANewCategory:
      "Vous avez ajouté avec succès une nouvelle catégorie",
    addNewCategory: "Ajouter une nouvelle catégorie",
    addCategory: "ajouter une catégorie",
    categoryName: "Nom de catégorie",
    pleaseEnterCategoryName: "Veuillez saisir le nom de la catégorie",
    stampsTarget: "Cible de timbres",
    sendInventory: "Envoyer l'inventaire",
    productDetails: "détails du produit",
    youveSuccessfullyEditedThisCategory:
      "Vous avez réussi à modifier cette catégorie",
    update: "Mettre à jour",
    categoryList: "Liste des catégories",
    categories: "Catégories",
    enterQuantityToUpdate: "Saisissez une quantité à mettre à jour",
    inventorySentSuccessfully: "L'inventaire a été envoyé avec succès !",
    updateInventory: "Mettre à jour l'inventaire",
    currentQuantity: "Quantité actuelle",
    pleaseEnterQuantityToAdd:
      "Veuillez saisir la quantité que vous souhaitez ajouter",
    pleaseSelectABranch: "Veuillez sélectionner une succursale",
    searchForBranch: "Rechercher une succursale",
    youCantSendMoreThanStock:
      "Vous ne pouvez pas envoyer plus que ce que vous avez en stock",
    send: "Envoyer",
    pleaseEnterQuantityToSend:
      "Veuillez saisir la quantité que vous souhaitez envoyer",
    productNameIsRequiredOnRow: "le nom du produit est requis sur la ligne",
    productCategoryIsRequiredOnRow:
      "la catégorie de produit est requise sur la ligne",
    productPriceIsRequiredOnRow: "le prix du produit est requis sur la ligne",
    productUnitIsRequiredOnRow: "l'unité de produit est requise sur la ligne",
    productQuantityIsRequiredOnRow:
      "la quantité de produit est requise sur la ligne",
    productVariantsRequireTracking:
      "les variantes de produits nécessitent un suivi !",
    pleaseAddVariantClickButton:
      "Veuillez ajouter une variante en cliquant sur le bouton Ajouter !",
    totalVariantsMoreThanSelectedQuantity:
      "Les variantes totales sont supérieures à la quantité de produit sélectionnée, veuillez réduire la quantité de variante",
    productEditedSuccessfully: "Le produit a été modifié avec succès !",
    fileTooLargeLessThan4Mb:
      "La taille du fichier est trop grande ! La taille du fichier doit être inférieure à 4 Mo.",
    suchVariantAlreadyExist: "Une telle variante existe déjà",
    addVariants: "Ajouter des variantes",
    editProduct: "Modifier le produit",
    pleaseEnterProductName: "Veuillez saisir le nom du produit",
    pleaseEnterProductPrice: "Veuillez saisir le prix du produit",
    pleaseEnterProductOriginalPrice:
      "Veuillez entrer le prix d'origine du produit",
    productDescription: "Description du produit",
    selectProductCategory: "Sélectionnez la catégorie de produit",
    pleaseSelectProductCategory:
      "Veuillez sélectionner la catégorie de produit",
    productCostPrice: "Prix de revient du produit",
    productSellingPrice: "Prix de vente du produit",
    productOriginalPrice: "Prix d'origine du produit",
    maxFileSizeAllowedIs4Mb: "Max. La taille de fichier autorisée est de 4 Mo",
    productsWithPicturesArePublished:
      "Les produits avec photos sont publiés sur Discover pour recevoir les commandes",
    shouldThisProductBeTracked: "Ce produit doit-il être suivi ?",
    pleaseSelectStockUnit: "Veuillez sélectionner l'unité de stock",
    stockUnit: "Unité de stock",
    bag: "SAC",
    bottle: "BOUTEILLE",
    bunch: "GROUPE",
    can: "POUVEZ",
    carton: "CARTON",
    crate: "CAISSE",
    cup: "TASSE",
    dozen: "DOUZAINE",
    gigabytes: "GIGABYTES",
    gram: "GRAMME",
    kilogram: "KILOGRAMME",
    litre: "LITRE",
    pack: "PACK",
    pair: "PAIRE",
    pieces: "PIÈCES",
    plate: "ASSIETTE",
    tonne: "TONNE (MT)",
    uNIT: "UNITÉ",
    yard: "COUR",
    pleaseEnterProductQuantity: "Veuillez saisir la quantité de produit",
    productQuantity: "La quantité de produit",
    isThisProductTaxed: "Ce produit est-il taxé ?",
    selectTaxType: "Sélectionnez le type de taxe",
    pleaseSelectTaxType: "Veuillez sélectionner le type de taxe",
    progressive: "Progressive",
    proportional: "Proportionnel",
    pleaseEnterProductTaxRate: "Veuillez saisir le taux de taxe sur le produit",
    doesProductHaveVariants: "Ce produit a-t-il des variantes ?",
    type: "Taper",
    value: "Valeur",
    pleaseEnterVariantType: "Veuillez saisir le type de variante",
    pleaseEnterVariantValue: "Veuillez saisir la valeur de la variante",
    pleaseEnterVariantPrice: "Veuillez saisir le prix de la variante",
    pleaseEnterVariantQuantity: "Veuillez saisir la quantité de la variante",
    productDeletedSuccessfully: "Le produit a été supprimé avec succès !",
    categoryDeletedSuccessfully: "La catégorie a été supprimée avec succès !",
    toDeleteThisProduct: "supprimer ce produit",
    invalidProductQuantity: "Quantité de produit invalide",
    quantityAddedIsOverStock:
      "La quantité que vous ajoutez est supérieure à ce que vous avez en stock.",
    itemInventoryNotTracked: "L'inventaire des articles n'est pas suivi",
    addBulkQuantity: "Ajouter une quantité en vrac",
    enterBulkQuantity: "Entrez la quantité en vrac",
    pleaseEnterBulkQuantity: "Veuillez saisir la quantité en vrac",
    youveSuccessfullyAddedANewProduct:
      "Vous avez ajouté un nouveau produit avec succès !",
    pleaseEnterProductSellingPrice:
      "Veuillez saisir le prix de vente du produit",
    doYouWantToTrackProductStock:
      "Vous souhaitez suivre le stock de produits ?",
    sellingPrice: "Prix de vente",
    setProductExpiryReminder: "Définir le rappel d'expiration du produit",
    productExpiryDate: "Date d'expiration du produit",
    startRemindingFrom: "Commencer à rappeler de",
    productSuppliesAddedSuccessfully:
      "Vous avez ajouté des fournitures de produits avec succès.",
    addProductSupplies: "Ajouter des fournitures de produit",
    pleaseSelectSupplier: "Veuillez sélectionner le fournisseur",
    nameOfProduct: "Nom du produit",
    pleaseSelectProduct: "Veuillez sélectionner le produit",
    productVariant: "Variante de produit",
    pleaseSelectAVariant: "Veuillez sélectionner une variante",
    pleaseEnterUnitPrice: "Veuillez saisir le prix unitaire",
    businessBranch: "Direction des affaires",
    pleaseSelectBranch: "Veuillez sélectionner une succursale",
    youveSuccessfullyAddedANewSupplier:
      "Vous avez ajouté avec succès un nouveau fournisseur",
    addSupplier: "Ajouter un fournisseur",
    pleaseEnterSupplierEmail: "Veuillez saisir l'e-mail du fournisseur",
    pleaseAddADescription: "Veuillez ajouter une description",
    anErrorOccuredProductsDeleted:
      "Une erreur s'est produite lors de l'exécution de l'opération. Veuillez noter que certains produits peuvent avoir été supprimés au cours du processus",
    bulkDelete: "Suppression en bloc",
    youAreAboutToDelete: "Vous êtes sur le point de supprimer",
    product: "Produit",
    isDueToRedeem: "doit racheter",
    aReward: "une récompense",
    pleaseSelectCustomerToReeemReward:
      "Veuillez sélectionner un client pour échanger la récompense.",
    youHaveNoLoyaltyProgramRunning:
      "Vous n'avez pas de programme de fidélité actif en cours",
    customerHhasNoPointsInLoyaltyProgram:
      "Le client n'a pas de points dans ce programme de fidélité",
    proceedWithPayment: "Procéder au paiement ?",
    youAreAboutToPayForTransactionUsingPoints:
      "Vous êtes sur le point de payer la transaction en utilisant des points.",
    customerHas: "Le client a",
    worth: "valeur",
    andIsNotSufficientToPayForTransaction:
      "et n'est pas suffisant pour payer cette transaction. Voudraient-ils ajouter le solde en utilisant un autre mode de paiement ?",
    addCustomerLoyalty: "Ajouter la fidélité client",
    pleaseAddCustomerFirst:
      "Veuillez d'abord ajouter ou sélectionner un client.",
    pleaseWaitWhileWeGetReady:
      "Veuillez patienter, pendant que nous nous préparons",
    lowStock: "Stock faible",
    pleaseEnterAmountTendered: "Veuillez saisir le montant encaissé",
    areYouSureToBookSaleOffline:
      "Êtes-vous sûr de vouloir réserver cette vente hors ligne ?",
    saleWouldBeBookedAutomatically:
      "La vente serait réservée automatiquement lorsque vous activez votre connexion Internet",
    offlineSalesBookingCancelled: "Réservation de vente hors ligne annulée",
    covid19Message:
      "COVID19 : Lavez-vous les mains avec du savon ou désinfectez pour arrêter la propagation. Restez toujours en sécurité",
    saleSuccessfullyRecorded: "Vente enregistrée avec succès !",
    sendReceiptToEmail: "Envoyer le reçu à l'e-mail",
    sendThankYouSms: "Envoyer un SMS de remerciement",
    sendShippingDetails: "Envoyer les détails d'expédition",
    addLoyalty: "Ajouter fidélité",
    searchCustomerNameOrNumber: "Rechercher le nom ou le numéro du client",
    clickTheSearchCustomerBox:
      "Cliquez sur la boîte de recherche du client et numérisez la carte",
    enterProductPrice: "Entrer le prix du produit",
    enterPriceFor: "Entrez le prix pour",
    searchForProduct: "Rechercher un produit",
    all: "Tous",
    backToDashboard: "Retour au tableau de bord",
    viewDraftSales: "Voir les ventes provisoires",
    variantSelected: "variante sélectionnée",
    variant: "une variante",
    thePreviousVariantSelectionNotAvailable:
      "La sélection de variante précédente n'est pas disponible pour la nouvelle variante sélectionnée en fonction du prix, veuillez modifier votre sélection.",
    pleaseSupplyPositiveQuantityNumber:
      "Veuillez fournir un numéro de quantité positif",
    lowStockFor: "Stock faible pour",
    clearVariants: "Effacer les variantes",
    pleaseEnterQuantity: "Veuillez saisir la quantité",
    picture: "Photo",
    redemptionToken: "Jeton d'échange",
    token: "Jeton",
    totalSpent: "Total dépensé",
    confirmPayment: "Confirmer le paiement",
    hasPaymentBeenMade: "Le paiement a-t-il été traité avec succès ?",
    enterTransactionReference:
      "Entrez la référence de la transaction avec laquelle vous avez payé",
    pleaseSelectACustomer: "Veuillez sélectionner un client !",
    areYouSureToApplyDiscount:
      "Êtes-vous sûr de vouloir appliquer une réduction ?",
    addYourBankAccountToEnableUssd:
      "Ajoutez votre compte bancaire pour activer le transfert instantané USSD par uPay",
    totalAmountToPay: "Montant total à payer",
    doYouWantToCancelTransaction: "Voulez-vous annuler cette transaction ?",
    savePrintBill: "Enregistrer/Imprimer la facture",
    enterAmountCollectedForCustomer:
      "Saisir le montant collecté pour le client",
    recordSale: "Vente de disques",
    checkOutWith: "Vérifier avec",
    instantTransfer: "Transfert instantané",
    dialTheUSSDCode: "Composez le code USSD",
    pleaseSelectABank: "Veuillez sélectionner une banque",
    payWithUSSD: "Payer avec USSD",
    sendBillTo: " - Envoyer la facture à",
    waitingForUSSDTransfer: "En attente de transfert USSD",
    percent: "Pour cent",
    applyDiscount: "Appliquer la remise",
    thisBillHasBeenSaved: "Cette facture a été enregistrée",
    saveDraft: "Enregistrer le brouillon",
    pleaseTypeANameToIdentifyCustomer:
      "Veuillez saisir un nom pour identifier le client",
    paymentDetails: "Détails de paiement",
    basePrice: "Prix de base",
    staff: "Personnel",
    subTotal: "Total",
    durationMonths: "Durée (mois)",
    selectADuration: "Sélectionnez une durée",
    oneMonth: "1 mois",
    twoMonths: "2 mois",
    threeMonths: "3 mois",
    sixMonths: "6 mois",
    twelveMonths: "12 mois",
    eighteenMonths: "18 mois",
    twentyFourMonths: "24mois",
    twoMonthsFree: "(2 mois gratuits)",
    threeMonthsFree: "(3 mois gratuits)",
    fiveMonthsFree: "(5 mois gratuits)",
    yourAccountHasBeen: "Votre compte a été",
    renewed: "renouvelé",
    upgraded: "amélioré",
    successfully: "avec succès",
    yourSubscription: "Votre abonnement",
    youAreCurrentlyEnrolledOnA: "Vous êtes actuellement inscrit sur un",
    pleaseChooseAPaymentOption: "Veuillez choisir une option de paiement",
    planRenewal: "Renouvellement du régime",
    planUpgrade: "Planifier la mise à niveau",
    pleaseSelectDurationToPayFor:
      "Veuillez sélectionner la durée pour laquelle vous avez l'intention de payer",
    staffAccounts: "Comptes du personnel",
    ecommerce: "Commerce électronique",
    pleaseSelectAPlan: "Veuillez sélectionner un forfait",
    includeAddons: "Inclure les modules complémentaires",
    viewTransactions: "Afficher les transactions",
    customerHasNoCompletedTansactions:
      "Le client n'a pas encore terminé les transactions",
    branch: "Branche",
    enterNumberOfEcommerceBranches:
      "Entrez le nombre de succursales de commerce électronique",
    enterNumberOfEcommerceBranchesToPay:
      "Entrez le nombre de succursales de commerce électronique pour lesquelles vous avez l'intention de payer",
    ecommerceIntegration: "Intégration du commerce électronique",
    enterNumberOfBranches: "Entrez le nombre de succursales",
    enterNumberOfAdditionalBranchesToPay:
      "Entrez le nombre de succursales supplémentaires que vous avez l'intention de payer",
    enterNumberOfStaffs: "Entrer le nombre d'employés",
    enterNumberOfStaffsToPayFor:
      "Entrez le nombre de membres du personnel que vous avez l'intention de payer",
    discountApplies: "La remise s'applique",
    starsOnThe: "étoiles sur le",
    offer: "offre",
    get: "Avoir",
    moreStarsToRedeem: "plus d'étoiles à racheter",
    taxVat: "Impôt (TVA)",
    callCashierToCompletePayment:
      "Appelez le caissier pour effectuer le paiement",
    receipt: "Reçu",
    dear: "chère",
    thankYouForYourOrderFindGoods:
      "Nous vous remercions de votre commande. Veuillez trouver les marchandises suivantes fournies, comme convenu.",
    shippingNote: "Note d'expédition",
    enterShippingNote: "Saisir la note d'expédition",
    shippingAddress: "Adresse de livraison",
    enterShippingAddress: "Entrez l'adresse de livraison",
    wellDoneYouAreDueToRedeem: "Bien fait! Vous devez racheter",
    toGetYourRewardNextVisit:
      "pour obtenir votre récompense lors de votre prochaine visite. Merci",
    pointsOnThe: "Points sur le",
    morePointsToRedeem: "plus de points à échanger",
    showCode: "Afficher le code",
    toGetYourRewardOnNextVisit:
      "pour obtenir votre récompense lors de votre prochaine visite. Merci",
    earn: "Gagner",
    delivaryNote: "Bon de livraison",
    draftSales: "Ventes provisoires",
    startDate: "Date de début",
    endDate: "Date de fin",
    orders: "Ordres",
    checkout: "vérifier",
    noProductItem: "Aucun article de produit",
    selectProductImage: "Sélectionnez l'image du produit",
    selectCountry: "Choisissez le pays",
    selectRegion: "Sélectionnez État/Région",
    printProductTag: "Imprimer l'étiquette du produit",
    transactionReference: "Référence de l'opération",
    Cashier: "La caissière",
    Manager: "Directeur",
    Owner: "Propriétaire",
    Admin: "Administrateur",
    addPartners: "Ajouter des partenaires",
    addNewLoyaltyPartner: "Ajouter un nouveau partenaire de fidélité",
    pleaseEnterCompanyName: "Veuillez saisir le nom de l'entreprise",
    companyName: "Nom de la compagnie",
    pleaseEnterCompanyRepName:
      "Veuillez saisir le nom du représentant de l'entreprise",
    companyRepName: "Nom du représentant de l'entreprise",
    pleaseEnterCompanyRepEmail:
      "Veuillez entrer l'e-mail du représentant de l'entreprise",
    companyRepEmail: "Courriel du représentant de l'entreprise",
    pleaseEnterCompanyRepPhone:
      "Veuillez entrer le numéro de téléphone du représentant de l'entreprise",
    companyRepPhone: "Numéro de téléphone du représentant de l'entreprise",
    addReward: "Ajouter une récompense",
    pleaseEnterRewardName: "Veuillez saisir le nom de la récompense",
    rewardName: "Nom de la récompense",
    rewardQuantity: "Quantité de récompense",
    rewardDescription: "Description de la récompense",
    rewardType: "Type de récompense",
    pleaseEnterRewardType: "Veuillez saisir le type de récompense",
    pleaseEnterRewardQuantity: "Veuillez saisir la quantité de récompense",
    pleaseEnterRewardDescription:
      "Veuillez saisir la description de la récompense",
    fineDining: "Gastronomie",
    luxuryFashion: "Mode de luxe",
    hotels: "Hôtels",
    travel: "Voyager",
    foodAndBeverage: "Nourriture et boisson",
    fashion: "Mode",
    health: "Santé",
    furniture: "Un meuble",
    entertainment: "Divertissement",
    automobile: "Voiture",
    education: "Éducation",
    beautyAndSpa: "Beauté et Spa",
    staycation: "Séjour",
    events: "Événements",
    trips: "Voyages",
    oilAndGas: "Pétrole et gaz",
    laundry: "Blanchisserie",
    partnerCategory: "Catégorie de partenaire",
    freeItem: "Article gratuit",
  },
  Finnish: {
    cashier: "kassa",
    manager: "johtaja",
    owner: "omistaja",
    online: "verkossa",
    offline: "offline -tilassa",
    changePassword: "Vaihda salasana",
    currentPasswordMessage: "Anna nykyinen salasanasi",
    passwordMessage: "Anna salasanasi",
    currentPassword: "Nykyinen salasana",
    password: "Salasana",
    confirmPasswordMessage: "Vahvista salasanasi!",
    confirmPassword: "Vahvista salasana",
    sendViaEmail: "Lähetä sähköpostitse",
    sendViaWhatsapp: "Lähetä WhatsAppin kautta",
    downloadPdf: "Lataa PDF",
    paid: "maksettu",
    unpaid: "palkaton",
    partial: "osittainen",
    closeInvoice: "Haluatko sulkea laskun?",
    closeInvoiceConfirmation: "Laskua ei ehkä tallenneta. Haluatko sulkea?",
    yes: "Joo",
    no: "ei",
    invoice: "Lasku",
    wasDeducted: "vähennettiin",
    for: "varten",
    item: "Tuote",
    addProduct: "Lisää tuote",
    paymentReference: "Maksuviite",
    amountPaid: "Maksettu summa",
    discountAmount: "Alennuksen määrä",
    amountDue: "Erääntyvä summa",
    amount: "Määrä",
    dueDate: "Eräpäivä",
    paymentType: "Maksutapa",
    card: "Kortti",
    cash: "Käteinen raha",
    bankTransfer: "Pankkisiirto",
    paymentMessage: "Maksuviesti",
    description: "Kuvaus",
    sendReceipt: "Lähetä kuitti",
    delete: "Poistaa",
    save: "Tallentaa",
    resend: "Uudelleen lähettää",
    saveAndSend: "Tallentaa",
    invoiceSaved: "Lasku tallennettu!",
    selectPaymentMethod: "Valitse maksutapa!",
    selectCustomer: "Valitse asiakas!",
    cartEmptyError:
      "Ostoskori ei voi olla tyhjä, sulje lasku ja lisää tuote ostoskoriin!",
    subscriptionExpired:
      "Tilauksesi on vanhentunut ja tilisi on nyt rajoitettu. Uudista tilisi napsauttamalla alla olevaa painiketta",
    renew: "Uudista",
    holdOn: "Pidä kiinni",
    customerBank: "Asiakaspankki",
    cancel: "Peruuttaa",
    selectACustomer: "Valitse Asiakas",
    invoiceSuccessfulPdfError:
      "Laskun luominen onnistui, mutta PDF -tiedoston luominen kestää tavallista kauemmin. Tarkista pian uudelleen.",
    downloadingInvoice: "Ladataan laskua",
    downloadingReceipt: "Kuitin lataaminen",
    whatsappReceiptError:
      "Kuitin lähettämisessä WhatsAppin kautta tapahtui virhe. Yritä uudelleen.",
    receiptToWhatsapp: "Kuitti välitettiin WhatsAppiin",
    thankYouForPatronage: "Kiitos suojeluksestasi",
    hereIsYourReceipt: "Tässä on maksukuittisi",
    errorSendingEmailReceipt:
      "Kuitin lähettämisessä sähköpostitse tapahtui virhe. Yritä uudelleen tai ota yhteyttä tukeen",
    receiptSentToEmail: "Kuitti on lähetetty asiakkaan sähköpostiin",
    invoiceSentToEmail: "Lasku on lähetetty asiakkaan sähköpostiin",
    invoiceSuccessWhatsappError:
      "Laskun luominen onnistui, mutta WhatsAppiin lähetettäessä tapahtui virhe. Yritä uudelleen laskuluettelossa",
    invoiceSuccessfulEmailError:
      "Laskun luominen onnistui, mutta sähköpostiviestinä lähetettäessä tapahtui virhe. Yritä uudelleen laskuluettelosta",
    invoiceSentToWhatsapp: "Lasku välitettiin WhatsAppiin",
    hello: "Hei",
    pleaseDownloadInvoice: "Lataa lasku",
    pleaseDownloadReceipt: "Lataa kuitti",
    from: "alkaen",
    email: "Sähköposti",
    upgrade: "Päivitä",
    youAreOnFreePlan: "Sinulla on ilmainen suunnitelma.",
    clickOn: "Klikkaa",
    yourPlanInFewSteps: " tilauksesi muutamalla nopealla vaiheella.",
    to: "kohteeseen",
    yourSubscriptionHasExpired:
      "Tilauksesi on vanhentunut ja tilisi on nyt rajoitettu.",
    days: "päivää",
    yourSubscriptionExpiresIn: "Loystar -tilauksesi vanhenee",
    createAcount: "Luo tili",
    signIn: "Kirjaudu sisään",
    continue: "Jatkaa",
    enterOtp: "Anna OTP -PIN",
    enterValidOtp: "Anna kelvollinen PIN -koodi",
    pin: "PIN -koodi",
    tokenSentToMail: "Tunnus on lähetetty sähköpostiisi",
    passwordResetSuccessful: "Salasanan palautus onnistui",
    somethingWentWrong: "Jotain meni pieleen!",
    resetPassword: "Nollaa salasana",
    iHaveResetCode: "Minulla on salasanan palautuskoodi",
    pleaseEnterEmail: "Ole hyvä ja syötä sähköpostiosoitteesi",
    aTokenWillBeSentToEmail: "Tunnus lähetetään sähköpostiisi",
    enterEmail: "Syötä sähköpostiosoitteesi",
    sendinYourToken: "Lähetetään tunnusta ...",
    makeSureItMatchesPassword: "Varmista, että se vastaa uutta salasanaasi",
    pleaseChooseNewPassword: "Valitse uusi salasana",
    chooseNewPassword: "Valitse uusi salasana",
    enterNewPassword: "Vahvista kirjoittamalla uusi salasana",
    enterTokenSent: "Kirjoita sähköpostisi lähettämä tunniste",
    resetToken: "Nollaa merkki",
    resettingPassword: "Salasanan vaihtaminen ...",
    signUp: "Kirjaudu",
    adminSignInWithEmail:
      " Järjestelmänvalvoja kirjautuu sisään sähköpostilla, kun taas henkilökunta kirjautuu sisään käyttäjätunnuksella.",
    pleaseEnterEmailOrUsername:
      "Anna sähköpostiosoitteesi tai käyttäjätunnuksesi",
    emailOrUsername: "Sähköpostiosoite tai käyttäjätunnus",
    pleaseEnterPassword: "Anna salasana",
    createAnAccount: "Luo tili",
    forgotPassword: "Unohtuiko salasana?",
    enterPhoneNumber: "Anna puhelinnumero",
    personalData: "Henkilökohtaiset tiedot",
    validateConfirmationCOde: "Vahvista vahvistuskoodi",
    pleaseEnterFirstName: "Kirjoita etunimesi, ole hyvä",
    pleaseEnterPhoneNumber: "Ole hyvä ja anna puhelinnumerosi",
    pleaseEnterLastName: "Anna sukunimesi",
    pleaseEnterBusinessName: "Anna yrityksesi nimi",
    firstName: "Etunimi",
    lastName: "Sukunimi",
    businessName: "Yrityksen nimi",
    previous: "Edellinen",
    next: "Seuraava",
    pleaseSelectBusinessCategory: "Valitse yrityksesi luokka",
    pleaseEnterValidEmail: "Anna kelvollinen sähköpostiosoite",
    pleaseEnterPostCode: "Anna postinumero",
    postCode: "Postinumero",
    phoneNumberInUse: "Tämä puhelinnumero on jo käytössä!",
    emailInUse: "Tämä sähköposti on jo käytössä!",
    foodAndDrinks: "Ruokaa ja juomia",
    salonAndBeauty: "Salonki ja kauneus",
    fashionAndAccessories: "Muoti ja asusteet",
    gymAndFitness: "Kuntosali ja kuntoilu",
    travelAndHotel: "Matkustaminen ja hotelli",
    homeAndGifts: "Koti ja lahjat",
    washingAndCleaning: "Pesu ja puhdistus",
    gadgetsAndElectronics: "Gadgetit ja elektroniikka",
    groceries: "Ruokaostokset",
    others: "Muut",
    submit: "Lähetä",
    accountCreatedSuccessful: "Tilisi luominen onnistui.",
    pleaseEnterAddress: "Anna osoitteesi",
    addressLine1: "Osoiterivi 1",
    addressLine2: "Osoiterivi 2",
    choosePassword: "Valitse salasana",
    passwordMustBe6Characters: "Salasanan on oltava vähintään 6 merkkiä pitkä.",
    howDidYouHearLoystar: "Mistä kuulit Loystarista?",
    referralCode: "viitekoodi",
    byClickingTheButton: " Napsauttamalla alla olevaa painiketta hyväksyt",
    termsAndSevice: "Ehdot",
    wereCreatingAccount: "Olemme luomassa tiliäsi",
    proceed: "Edetä",
    verificationCodeMustBe6: "Vahvistuskoodissa on oltava 6 numeroa",
    pleaseEnter6DigitCode: "Anna 6 -numeroinen koodi",
    enterVerificationCode: "Syötä vahvistus koodi",
    resendToken: "Lähetä tunnus uudelleen",
    verify: "Vahvista",
    transactions: "Tapahtumat",
    todaySales: "Tämän päivän myynti",
    salesHistory: "Myyntihistoria",
    supplyHistory: "Toimitushistoria",
    new: "Uusi",
    invoices: "Laskut",
    disbursements: "Maksut",
    offlineSales: "Offline -myynti",
    products: "Tuotteet",
    customers: "Asiakkaat",
    multiLevelLoyalty: "Monitasoinen uskollisuus",
    loyaltyPrograms: "Kanta -asiakasohjelmat",
    members: "Jäsenet",
    appStore: "Sovelluskauppa",
    orderMenu: "Tilausvalikko",
    settings: "asetukset",
    staffAndBranches: "Henkilökunta ja haaratoimistot",
    myAccount: "Tilini",
    switchToSellMode: "Vaihda myyntitilaan",
    signOut: "Kirjaudu ulos",
    getFreeSubscription: "Hanki ilmainen tilaus",
    onlyNumbersAllowed: "Vain numerot ovat sallittuja",
    yourAccountMustBe10Digits: "Tilinumerosi on oltava 10 -numeroinen",
    yourBvnMustBe11: "BVN -numerosi on oltava 11 -numeroinen",
    pleaseSelectBank: "Valitse pankkisi",
    selectYourBank: "Valitse pankkisi",
    enterBankAccountNumber: "Anna pankkitilin numero",
    enterBvnNumber: "Anna BVN",
    connectBankAccount: "Yhdistä pankkitili",
    passwordResetSuccessfulAndSignOut:
      "Salasanasi on palautettu onnistuneesti. Kirjaudu sisään uudelleen napsauttamalla alla olevaa Kirjaudu ulos -painiketta",
    enterCurrentPassword: "Anna nykyinen salasana",
    pleaseEnterCurrentPassword: "Anna nykyinen salasana",
    phoneNumber: "Puhelinnumero",
    sex: "Seksi",
    dateOfBirth: "Syntymäaika",
    state: "Osavaltio",
    country: "Maa",
    loyaltyId: "Kanta -asiakas tunnus",
    createdAt: "Luotu klo",
    noOfTransactionsMade: "Tehtyjen tapahtumien määrä",
    yourDownloadWillStart: "Lataus alkaa hetken kuluttua",
    exportCustomers: "Vie asiakkaat",
    youhaveSuccessfullyToppedUp:
      "Olet onnistuneesti täyttänyt tekstiviestiyksiköt.",
    youNowHave: "Sinulla on nyt",
    smsUnits: "SMS -yksiköt",
    enterNumberOfSmsUnits: "Anna SMS -yksiköiden lukumäärä, jonka aiot ostaa",
    pleaseEnterANumericValue: "Anna numeerinen arvo",
    pay: "Maksaa",
    accountEditedSuccessfully: "Tilisi muokkaaminen onnistui.",
    youAeCurrentlyOn: "Olet tällä hetkellä käytössä",
    plan: "Suunnitelma",
    userData: "Käyttäjätiedot",
    businessData: "LIIKETIEDOT",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Valitse valuuttasi",
    selectYourCurrency: "Valitse valuutta",
    purchaseMoreSmsUnits:
      "Osta lisää tekstiviestiyksiköitä alla olevalla lomakkeella",
    youHave: "Sinulla on",
    atLeast4SmsUnitsRrequired:
      "Vahvistamiseen tarvitaan vähintään 4 sms -yksikköä, täytä!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Vahvista pankkitilisi, jotta se täyttää KYC -vaatimukset. Näin voit kerätä maksuja USSD: n tai välittömän siirron kautta, vastaanottaa tilauksia asiakkailta ja käsitellä maksutapahtumia. Koskee vain Nigerian kauppiaita. Aloita painamalla alla olevaa painiketta.",
    reConnectBankAccount: "Yhdistä pankkitili uudelleen",
    accountName: "Tilin nimi",
    accountNumber: "Tilinumero",
    bankName: "Pankin nimi",
    verified: "Vahvistettu",
    accountDetails: "tilin tiedot",
    kycBankAccount: "KYC",
    createTier: "Luo taso",
    fileUploadSuccessful: "Tiedosto ladattu onnistuneesti",
    fileUploadFailed: "tiedoston lataus epäonnistui",
    createLoyaltyProgram: "Luo kanta -asiakasohjelma",
    createLoyalty: "Luo uskollisuus",
    name: "Nimi",
    loyaltyArtwork: "Uskollisuus Taideteos",
    clickToUpload: "Napsauta ladataksesi",
    amountToPointsRatio: "Määrä pisteiden suhde",
    points: "Pisteet",
    recommendedAmountToPointRatio:
      "Suositeltava: ₦ 1 - 1 piste. eli jokaista 1 Nairaa käytettäessä asiakkaasi saavat 1 pisteen",
    pleaseTypeIn: "Kirjoita",
    toDeleteLoyalty: "poistaa tämän uskollisuuden",
    deleteLoyalty: "Poista uskollisuus",
    toConfirm: "vahvistaa",
    edit: "Muokata",
    pointsAwardedSuccessfully: "Pisteet myönnetty onnistuneesti.",
    enterPointValueToAward: "Anna pistearvo, jonka haluat palkita asiakkaille",
    award: "Myöntää",
    awardPointValuesToCustomer: "Pistearvo asiakkaalle",
    enrollMembersToLoyalty: "Ilmoita jäsenet kanta -asiakasohjelmaan",
    awardPoints: "Pisteitä",
    enroll: "Ilmoittaudu",
    home: "Koti",
    loyalty: "Uskollisuus",
    enrollCustomers: "Rekisteröi asiakkaita",
    selected: "Valittu",
    customer: "Asiakas",
    loyaltyActivationSuccessful: "Uskollisuusaktivointi onnistui.",
    loyaltyDeactivationSuccessful:
      "Uskollisuuden poistaminen käytöstä onnistui.",
    viewTier: "Näytä taso",
    deactivate: "Poista käytöstä",
    activate: "Aktivoida",
    actions: "Toiminnot",
    nameOfLoyalty: "Uskollisuuden nimi",
    loyaltyStatus: "Kanta -asiakkuus",
    numberOfTiers: "Tasojen määrä",
    createdOn: "Luotu",
    createATier: "Luo taso",
    stopCreatingTierConfirmation: "Haluatko lopettaa tason luomisen?",
    stopEditingTierConfirmation: "Haluatko lopettaa tämän tason muokkaamisen?",
    nameOfTier: "Tason nimi",
    minimumSpendingTarget: "Vähimmäiskulutustavoite",
    maximumSpendingTarget: "Suurin kulutustavoite",
    minimumSpendingTargetRequired: "vähimmäistavoite vaaditaan",
    maximumSpendingTargetRequired: "suurin menotavoite vaaditaan",
    rewardSponsor: "Palkintojen sponsori",
    pleaseChooseARewardSponsor: "Valitse palkintosponsori",
    self: "Itse",
    partner: "Kumppani",
    rewardPartner: "Palkintokumppani",
    pleaseSelectRewardPartner: "Valitse palkitsemiskumppanisi",
    rewards: "Palkinnot",
    pleaseSelectAReward: "Valitse palkinto",
    instructionsOnRedeemingReward:
      "Ohjeet siitä, miten asiakkaan tulee lunastaa palkinto",
    pleaseFillInThisField: "Täytä tämä kenttä!",
    toDeleteThisTier: " tämän tason poistamiseksi",
    deleteTier: "Poista taso",
    viewMembers: "Näytä jäsenet",
    membersEnrolled: "Ilmoittautuneet jäsenet",
    instruction: "Ohje",
    membersIn: "Jäsenet sisään",
    availableTiersInLoyalty: "Saatavilla olevat tasot kanta -asiakasohjelmassa",
    tiers: "Tasot",
    totalTier: "YHTEENSÄ TIER",
    availableLoyaltyProgramme: "Saatavilla oleva kanta -asiakasohjelma",
    totalLoyalties: "LOJALITEETIT YHTEENSÄ",
    memberDetails: "Jäsenen tiedot",
    nameOfCustomer: "Asiakkaan nimi",
    address: "Osoite",
    allEnrolledMembers: "Kaikki ilmoittautuneet jäsenet",
    thisIsToWishYouHappyBirthday:
      "Tämä on toivottaa sinulle hyvää syntymäpäivää ja menestyvää elämää. Kiitos kaikesta, mitä olet Loysterille. Hyvää Juhlaa!",
    inputAnOfferPlease: "Anna tarjous kiitos",
    pleaseSelectTheInsertPoint:
      "Valitse lisäyskohta viestissä ja napsauta uudelleen",
    birthdayOfferAndMessage: "Syntymäpäivä tarjous ja viesti",
    birthdayOffer: "Syntymäpäivä tarjous",
    birthdayMessage: "Syntymäpäiväviesti",
    noOfferFound: "Tarjousta ei löytynyt",
    settingABirthdayOffer:
      "Syntymäpäivä tarjouksen avulla asiakkaat voivat saada tämän tarjouksen tekstiviestinä syntymäpäivänään",
    createOffer: "Luo tarjous",
    whatIsTheOffer: "Mikä on tarjous?",
    editMessage: "Muokkaa viestiä",
    insert: "Lisää",
    theNameOfCustomerInserted: "Asiakkaan nimi lisätään tähän",
    theBirtdayOfferInserted: "Synttäritarjous lisätään tähän",
    youSuccessfullyAddedNewBranch: "Olet lisännyt uuden haaran onnistuneesti!",
    addNewBranch: "Lisää uusi haara",
    addBranch: "Lisää haara",
    additionalBranchWillIncur: "Lisähaaraa aiheutuisi",
    perBranch: "haaraa kohti",
    ecommerceBranchCosts: "Verkkokaupan sivuliikkeen kustannukset",
    pleaseEnterBranchName: "Anna sivuliikkeen nimi",
    pleaseEnterBranchAddress1: "Anna sivuliikkeen osoiterivi 1",
    enterBranchAddress1: "Kirjoita sivukonttorin osoiterivi 1",
    enterBranchAddress2: "Kirjoita sivukonttorin osoiterivi 1",
    pleaseEnterBranchAddress2: "Anna sivuliikkeen osoiterivi 2",
    enterBranchName: "Anna haaran nimi",
    successfullyAddedStaff: "Olet lisännyt uuden henkilökunnan onnistuneesti!",
    addNewStaff: "Lisää uutta henkilökuntaa",
    addStaff: "Lisää henkilökuntaa",
    additionalStaffWillIncur: "Lisähenkilöstöä aiheutuisi",
    perStaff: "henkilöstöä kohden.",
    pleaseEnterStaffEmail: "Anna henkilökunnan sähköpostiosoite",
    pleaseEnterStaffUsername: "Anna henkilökunnan käyttäjätunnus",
    pleaseEnterStaffPassword: "Anna henkilökunnan salasana",
    pleaseSelectStaffRole: "Valitse henkilöstön rooli",
    selectStaffRole: "Valitse henkilöstön rooli",
    enterStaffEmail: "Kirjoita henkilökunnan sähköpostiosoite",
    enterStaffUsername: "Anna henkilökunnan käyttäjätunnus",
    enterStaffPassword: "Anna henkilökunnan salasana",
    spacesNotAllowedInUsername: "välilyöntejä ei sallita käyttäjätunnuksessa",
    admin: "Järjestelmänvalvoja",
    pleaseSelectBusinessToAttachStaff:
      "Valitse yritys, johon henkilöstöä liitetään",
    searchForBranchToAttachStaff:
      "Etsi haarakonttori henkilöstön liittämiseksi",
    username: "Käyttäjätunnus",
    role: "Rooli",
    areYouSureToDeleteThis: "Haluatko varmasti poistaa tämän",
    branches: "Oksat",
    upgradeYourPlan: "Päivitä suunnitelmasi.",
    add: "LISÄTÄ",
    addNew: "Lisää uusi",
    customerWithEmailAlreadyExists:
      "Asiakas, jolla on sähköposti/puhelinnumero, on jo olemassa!",
    successfullyAddedNewCustomer:
      "Olet lisännyt uuden asiakkaan onnistuneesti!",
    addCustomer: "Lisää asiakas",
    pleaseEnterCustomerFirstName: "Anna asiakkaan etunimi",
    pleaseEnterCustomerLastName: "Anna asiakkaan sukunimi",
    pleaseEnterCustomerPhoneNumber: "Anna asiakkaan puhelinnumero",
    pleaseEnterCustomerEmail: "Anna asiakkaan sähköpostiosoite",
    pleaseEnterCustomerAddressLine: "Anna asiakkaan osoiterivi",
    pleaseEnterCustomerOtherAddress: "Anna asiakkaan toinen osoite",
    pleaseSelectCustomerGender: "Valitse asiakkaan sukupuoli",
    gender: "Sukupuoli",
    male: "Uros",
    female: "Nainen",
    bank: "Pankki",
    selectBank: "Valitse Pankki",
    stateOrRegionOrProvince: "Osavaltio/alue/maakunta",
    customerNotes: "Asiakkaan huomautukset",
    sendSms: "Lähetä tekstiviesti",
    editCustomer: "Muokkaa asiakasta",
    redeemReward: "Lunasta palkinto",
    issueLoyaltyCard: "Anna kanta -asiakaskortti",
    deleteCustomer: "Poista asiakas",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Olet määrittänyt kanta -asiakasjäsenen tunnuksen",
    noMembershipIdAvailable:
      "Jäsenyystunnuksia ei ole saatavilla. Ota yhteyttä osoitteeseen hello@loystar.co",
    sendEmail: "Lähettää sähköpostia",
    membershipPoints: "Jäsenpisteet",
    customerDetails: "asiakkaan tiedot",
    close: "kiinni",
    loyaltyBalance: "Uskollisuustase",
    pointsBalance: "Pisteet Tasapaino",
    starBalance: "Tähtitase",
    requiredPoints: "Pakolliset pisteet",
    requiredStars: "Pakolliset tähdet",
    reddemCode: "Lunasta koodi",
    toDeleteThisCustomer: "tämän asiakkaan poistamiseksi",
    customerHasBeenDeletedSuccessfully: "Asiakas poistettiin onnistuneesti!",
    customerWithPhoneAlreadyExists:
      "Asiakas, jolla on puhelinnumero, on jo olemassa",
    customerWasSuccessfullyEdited: "Asiakkaan muokkaaminen onnistui",
    anErrorOccured: "Tapahtui virhe",
    phoneNumberIncludeCountry: "Puhelinnumero (sisällytä maakoodi)",
    yourFileQueuedForUpload:
      "Tiedostosi on jonotettu lataamista varten. Päivitä sivu muutaman sekunnin kuluttua.",
    thereWasAnErrorPerformingOperation:
      "Toiminnon suorittamisessa tapahtui virhe!",
    pleaseSelectFile: "Valitse tiedosto!",
    oopsOnlyCsvFilesAllowed:
      "Oho! Vain CSV -tiedostot sallitaan. Lähetä .csv -tiedosto.",
    fileShouldBeSmallerThan5Mb:
      "Tiedoston tulee olla pienempi kuin 5 Mt. Jos sinulla on suurempi tiedosto, lähetä sähköpostia osoitteeseen support@loystar.co. Kiitos",
    customerFirstNameIsRequired: "rivillä vaaditaan asiakkaan etunimi",
    customerPhoneNumberIsRequired: "rivillä vaaditaan asiakkaan puhelinnumero",
    importCustomers: "Tuo asiakkaita",
    upload: "Lataa",
    clickIconToDownloadCsv:
      "Lataa CSV -tiedostomalli napsauttamalla tätä kuvaketta.",
    getGoogleSheetVersion: "Hanki Google -versio",
    clickOrDragFileToUpload:
      "Napsauta tai vedä tiedosto tälle alueelle ladataksesi",
    missingOrInvalidColumnHeader:
      "Sarakeotsikko puuttuu tai on virheellinen. Noudata mallin muotoa. Kiitos.",
    youHaveImported: "Olet tuonut",
    youSuccessfullyRedeemedReward: "Olet lunastanut palkintosi onnistuneesti!",
    sixDigitCode: "Kuusinumeroinen koodi",
    pleaseEnterCustomerRewardCode: "Anna asiakkaan palkitsemiskoodi",
    enterRewardCode:
      "Anna palkintokoodi. (Palkitsemiskoodi erottaa isot ja pienet kirjaimet)",
    selectLoyaltyProgram: "Valitse kanta -asiakasohjelma",
    stamps: "Postimerkit",
    smsUnitsLow: "SMS -yksiköt vähissä",
    pleaseTopUpSmsUnits: "Lataa SMS -yksiköt",
    smsSuccessfullySentForDelivery: "Tekstiviestin lähetys onnistui!",
    sendSmsTo: "Lähetä tekstiviesti osoitteeseen",
    allCustomers: "Kaikki asiakkaat",
    unitsAvailable: "Yksiköt saatavilla",
    pleaseTypeInTheMessage: "Kirjoita viesti",
    message: "Viesti",
    charactersRemaining: "merkkiä jäljellä",
    avoidUseOfSpecialCharacters:
      "Vältä erikoismerkkien ja hymiöiden käyttöä SMS -yksiköiden säästämiseksi.",
    note: "Huomautus",
    errorFetchingCustomersMultilevelDetail:
      "Virhe haettaessa asiakkaiden monitasoisia tietoja",
    search: "Hae",
    reset: "Nollaa",
    importCustomer: "Tuo asiakas",
    addNewCustomer: "Lisää uusi asiakas",
    sendSmsBroadcast: "Lähetä SMS -lähetys",
    totalCustomers: "ASIAKKAITA YHTEENSÄ",
    disbursementDetails: "Maksutiedot",
    paidBy: "Maksanut",
    disbursed: "Maksettu",
    bankAccountName: "Pankkitilin nimi",
    bankAccountNumber: "Pankkitilin numero",
    transferInitiated: "Siirto aloitettu",
    transferCompleted: "Siirto suoritettu",
    pleaseEnterAValid: "Anna kelvollinen",
    begin: "alkaa",
    end: "loppuun",
    date: "Päivämäärä",
    paymentDate: "Maksupäivä",
    selectDisbursementDuration: "Valitse Maksun kesto",
    totalSettled: "Yhteensä ratkaistu",
    totalUnsettled: "Yhteensä ratkaisematta",
    toDeleteThisSale: "poistaa tämän myynnin",
    draftSaleDeletedSuccessfully: "Luonnosmyynti on poistettu onnistuneesti!",
    deleteSale: "Poista myynti",
    pleaseTypeInYourTotal: "Kirjoita kokonaissumma vahvistaaksesi poiston",
    billDetails: "Laskun tiedot",
    printBill: "Tulosta Bill",
    servedBy: "Palvelee",
    total: "Kaikki yhteensä",
    createdDate: "Luotu päivämäärä",
    createdTime: "Luotu aika",
    status: "Tila",
    loginSuccessful: "Kirjautuminen onnistui",
    pleaseWaitWhileWeConnectAccount: "Odota, kunnes yhdistämme tilisi",
    connectionFailedTryAgain: "Yhteys epäonnistui. Yritä uudelleen.",
    connectionSuccessful: "Yhteys onnistui",
    viewDetails: "Tarkemmat tiedot",
    enable: "ota käyttöön",
    free: "Vapaa",
    cost: "Kustannus",
    visitWebsite: "Vieraile verkkosivustolla",
    pleaseUpgradeYourPlanToPro:
      "Päivitä suunnitelmasi Pro Plus -versioksi, jotta voit ottaa tämän sovelluksen käyttöön",
    connectYourBankAccount:
      "Yhdistä pankkitilisi, jotta voit vastaanottaa maksuja.",
    disable: "Poista käytöstä",
    enableApp: "Ota sovellus käyttöön",
    addNewProductToInvoice: "Lisää uusi tuote laskuun",
    toDeleteThisInvoice: "poistaa tämän laskun",
    invoiceDeletedSuccessfully: "Laskun poistaminen onnistui!",
    deleteInvoice: "Poista lasku",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Olet lisännyt laskun maksuviestin!",
    pleaseEnterPaymentInstructions: "Anna maksuohjeet",
    invoiceId: "Laskun tunnus",
    paidDate: "Maksettu päivämäärä",
    reference: "Viite",
    productAdded: "Tuote lisätty",
    productOutOfStock: "Tuote loppu. Ole hyvä ja varastoi uudelleen.",
    totalInvoices: "LASKUT YHTEENSÄ",
    totalPaidInvoices: "MAKSUT LASKUT YHTEENSÄ",
    totalUnpaidInvoices: "MAKSAMATTOMAT LASKUT YHTEENSÄ",
    loyaltyProgramDeleted: "Kanta -asiakasohjelma poistettu",
    thisLoyaltyProgramDeletedSuccessfully:
      "Tämä kanta -asiakasohjelma on poistettu",
    thisLoyaltyProgramEditedSuccessfully:
      "Tätä kanta -asiakasohjelmaa on muokattu onnistuneesti",
    loyaltyProgramAddedSuccessfully:
      "Kanta -asiakasohjelma on lisätty onnistuneesti",
    loyaltyProgramEdited: "Kanta -asiakasohjelma Muokattu",
    loyaltyProgramAdded: "Kanta -asiakasohjelma lisätty",
    loyaltyDetails: "Uskollisuustiedot",
    doYouWantToCloseDialog: "Haluatko sulkea tämän valintaikkunan?",
    pleaseEnterLoyaltyName: "Anna uskollisuutesi nimi",
    programType: "Ohjelmatyyppi",
    pleaseSelectProgramType: "Valitse ohjelmatyyppi",
    simplePoints: "Yksinkertaiset pisteet",
    stampsProgram: "Postimerkkiohjelma",
    threshold: "Kynnys",
    pleaseEnterLoyaltyThreshold: "Anna uskollisuuskynnys",
    reward: "Palkinto",
    pleaseEnterLoyaltyReward: "Anna uskollisuuden palkinto",
    totalUserPoints: "Käyttäjäpisteet yhteensä",
    totalUserStamps: "Käyttäjäleimoja yhteensä",
    spendingTarget: "Kulutustavoite",
    spendingTargetHint1:
      "Kulutustavoite on se, kuinka paljon asiakkaan on käytettävä palkkion ansaitsemiseen. 1 valuutan arvo = 1 piste.",
    spendingTargetHint2:
      "Postimerkkien tavoite on kuinka monta postimerkkiä asiakkaan on kerättävä ansaitakseen palkinnon. esim. 5",
    addNewLoyaltyProgram: "Lisää uusi kanta -asiakasohjelma",
    addLoyaltyProgram: "Lisää kanta -asiakasohjelma",
    loyaltyProgramType: "Kanta -asiakasohjelman tyyppi",
    pleaseSelectLoyaltyProgramType: "Valitse kanta -asiakasohjelman tyyppi",
    nameOfProgram: "Ohjelman nimi",
    pleaseEnterProgramName: "Anna ohjelman nimi",
    whatIsTheReward: "Mikä on palkinto?",
    egNextPurchaseIsFree: "Esim. Seuraava osto on ilmainen",
    customerName: "Asiakkaan nimi",
    guestCustomer: "Vierasasiakas",
    orderConfirmedSuccessfully: "Tilaus vahvistettu",
    orderCancelledSuccessfully: "Tilaus peruutettiin",
    confirmOrder: "Vahvista tilaus",
    cancelOrder: "Peruuta tilaus",
    allOrders: "Kaikki tilaukset",
    totalOrders: "TILAUKSIA YHTEENSÄ",
    doYouWantToAcceptOrder: "Haluatko hyväksyä tämän tilauksen?",
    doYouWantToCancelOrder: "Haluatko peruuttaa tämän tilauksen?",
    orderDetails: "Tilauksen tiedot",
    orderCreatedAt: "Tilaus luotu osoitteessa",
    supplier: "Toimittaja",
    productName: "tuotteen nimi",
    quantity: "Määrä",
    unitPrice: "Yksikköhinta",
    receivedBy: "Vastaanottaja",
    reportFrom: "Raportti käyttäjältä",
    totalSupplies: "Tarvikkeita yhteensä",
    allRightsReserved: "Kaikki oikeudet pidätetään",
    toDeleteThisTransaction: "poistaa tämän tapahtuman",
    transactionDeletedSuccessfully:
      "Tapahtuma on poistettu. Varasto on palautettu varastoon.",
    deleteTransaction: "Poista tapahtuma",
    transactionDetails: "maksutapahtuman tiedot",
    printReceipt: "Tuloskuitti",
    channel: "Kanava",
    discount: "Alennus",
    profit: "Voitto",
    discountedSales: "Alennettu myynti",
    errorFetchingRecord: "Virhe noudettaessa tietuetta",
    exportTransactions: "Vie tapahtumat",
    errorFetchingSalesRecord:
      "Virhe noudettaessa myyntitietuetta vientiä varten.",
    totalSellingPrice: "Myyntihinta yhteensä",
    totalCostPrice: "Kokonaishinta",
    appliedDiscount: "Sovellettava alennus",
    noOfItems: "Kohteiden määrä",
    sales: "Myynti",
    export: "Viedä",
    totalProfit: "Koko tuotto",
    totalBalanceInPeriod: "Kauden kokonaissaldo",
    allTimeSales: "Kaikkien aikojen myynti",
    records: "Ennätykset",
    todaysSales: "Tämän päivän myynti",
    transaction: "kauppa",
    youHaveExceededTotalNumberOfProducts:
      "Olet ylittänyt suunnitelmasi sallittujen tuotteiden kokonaismäärän. Päivitä suunnitelmasi saadaksesi korkeamman rajan.",
    youNeedToHaveLoyaltyProgram:
      "Tämän ominaisuuden käyttäminen edellyttää kanta -asiakasohjelmaa!",
    price: "Hinta",
    category: "Kategoria",
    stockTracking: "Varaston seuranta",
    stockCount: "Varaston määrä",
    taxed: "Verotettu",
    originalPrice: "Alkuperäinen hinta",
    costPrice: "Kustannus hinta",
    unit: "Yksikkö",
    productImage: "Tuotekuva",
    taxRate: "Veroaste",
    taxType: "Verotyyppi",
    trackInventory: "Seuraa inventaariota",
    variants: "Vaihtoehdot",
    hasVariants: "On Variantteja",
    importProduct: "Tuo tuote",
    exportProducts: "Vie tuotteet",
    addNewProduct: "Lisää uusi tuote",
    viewCategory: "Näytä luokka",
    viewSuppliers: "Näytä toimittajat",
    receiveInventory: "Vastaanota inventaario",
    printAllProductsTag: "Tulosta kaikki tuotteet",
    deleteAll: "Poista kaikki",
    totalProducts: "TUOTTEITA YHTEENSÄ",
    youveSuccessfullyAddedANewCategory:
      "Olet lisännyt uuden luokan onnistuneesti",
    addNewCategory: "Lisää uusi luokka",
    addCategory: "Lisää kategoria",
    categoryName: "Kategorian nimi",
    pleaseEnterCategoryName: "Anna luokan nimi",
    stampsTarget: "Postimerkit Kohde",
    sendInventory: "Lähetä inventaario",
    productDetails: "tuotteen yksityiskohdat",
    youveSuccessfullyEditedThisCategory: "Tämän luokan muokkaaminen onnistui",
    update: "Päivittää",
    categoryList: "Luettelo",
    categories: "Luokat",
    enterQuantityToUpdate: "Anna päivitettävä määrä",
    inventorySentSuccessfully: "Mainosjakauma lähetettiin onnistuneesti!",
    updateInventory: "Päivitä inventaario",
    currentQuantity: "Nykyinen määrä",
    pleaseEnterQuantityToAdd: "Anna lisättävä määrä",
    pleaseSelectABranch: "Valitse haara",
    searchForBranch: "Etsi haara",
    youCantSendMoreThanStock: "Et voi lähettää enempää kuin varastossa",
    send: "Lähettää",
    pleaseEnterQuantityToSend: "Anna lähetettävä määrä",
    productNameIsRequiredOnRow: "rivillä vaaditaan tuotteen nimi",
    productCategoryIsRequiredOnRow: "rivillä vaaditaan tuoteluokka",
    productPriceIsRequiredOnRow: "rivillä vaaditaan tuotteen hinta",
    productUnitIsRequiredOnRow: "tuoteyksikkö on pakollinen rivillä",
    productQuantityIsRequiredOnRow: "rivillä vaaditaan tuotemäärä",
    productVariantsRequireTracking: "tuotevaihtoehdot vaativat seurantaa!",
    pleaseAddVariantClickButton:
      "Lisää variantti napsauttamalla Lisää -painiketta!",
    totalVariantsMoreThanSelectedQuantity:
      "Variantit yhteensä ovat enemmän kuin valittu tuotemäärä, Pls vähentää variantin määrää",
    productEditedSuccessfully: "Tuotteen muokkaaminen onnistui!",
    fileTooLargeLessThan4Mb:
      "Tiedoston koko on liian suuri! Tiedoston koon tulee olla alle 4 Mt.",
    suchVariantAlreadyExist: "Tällainen muunnelma on jo olemassa",
    addVariants: "Lisää muunnelmia",
    editProduct: "Muokkaa tuotetta",
    pleaseEnterProductName: "Anna tuotteen nimi",
    pleaseEnterProductPrice: "Anna tuotteen hinta",
    pleaseEnterProductOriginalPrice: "Anna tuotteen alkuperäinen hinta",
    productDescription: "Tuotteen Kuvaus",
    selectProductCategory: "Valitse Tuoteluokka",
    pleaseSelectProductCategory: "Valitse tuoteryhmä",
    productCostPrice: "Tuotteen hinta Hinta",
    productSellingPrice: "Tuotteen myyntihinta",
    productOriginalPrice: "Tuotteen alkuperäinen hinta",
    maxFileSizeAllowedIs4Mb: "Max. Sallittu tiedostokoko on 4 megatavua",
    productsWithPicturesArePublished:
      "Kuvia sisältävät tuotteet julkaistaan Discoverissa tilausten vastaanottamiseksi",
    shouldThisProductBeTracked: "Pitäisikö tätä tuotetta seurata?",
    pleaseSelectStockUnit: "Valitse varastossa oleva yksikkö",
    stockUnit: "Varastoyksikkö",
    bag: "LAUKKU",
    bottle: "PULLO",
    bunch: "KIMPPU",
    can: "VOI",
    carton: "KARTONKI",
    crate: "LAATIKKO",
    cup: "KUPPI",
    dozen: "TUSINA",
    gigabytes: "GIGABYTES",
    gram: "GRAMMA",
    kilogram: "KILOGRAMMA",
    litre: "LITER",
    pack: "PAKKAUS",
    pair: "PARI",
    pieces: "OSAT",
    plate: "LAUTANEN",
    tonne: "TONNE (MT)",
    uNIT: "YKSIKKÖ",
    yard: "PIHA",
    pleaseEnterProductQuantity: "Anna tuotteen määrä",
    productQuantity: "Tuotemäärä",
    isThisProductTaxed: "Onko tämä tuote verotettu?",
    selectTaxType: "Valitse Verotyyppi",
    pleaseSelectTaxType: "Valitse Verotyyppi",
    progressive: "Progressiivinen",
    proportional: "Suhteellinen",
    pleaseEnterProductTaxRate: "Anna tuotteen veroprosentti",
    doesProductHaveVariants: "Onko tästä tuotteesta muunnelmia?",
    type: "Tyyppi",
    value: "Arvo",
    pleaseEnterVariantType: "Anna muunnelman tyyppi",
    pleaseEnterVariantValue: "Anna muunnelman arvo",
    pleaseEnterVariantPrice: "Anna variantin hinta",
    pleaseEnterVariantQuantity: "Anna variantin määrä",
    productDeletedSuccessfully: "Tuote on poistettu onnistuneesti!",
    categoryDeletedSuccessfully: "Luokka on poistettu onnistuneesti!",
    toDeleteThisProduct: "tämän tuotteen poistamiseksi",
    invalidProductQuantity: "Virheellinen tuotemäärä",
    quantityAddedIsOverStock: "Lisäämäsi määrä ylittää varastosi.",
    itemInventoryNotTracked: "Tuotteiden varastoja ei seurata",
    addBulkQuantity: "Lisää irtotavarana oleva määrä",
    enterBulkQuantity: "Anna Bulk Quantity",
    pleaseEnterBulkQuantity: "Anna Bulk Quantity",
    youveSuccessfullyAddedANewProduct: "Olet lisännyt uuden tuotteen!",
    pleaseEnterProductSellingPrice: "Anna tuotteen myyntihinta",
    doYouWantToTrackProductStock: "Haluatko seurata tuotevarastoa?",
    sellingPrice: "Myyntihinta",
    setProductExpiryReminder: "Aseta muistutus tuotteen vanhenemisesta",
    productExpiryDate: "Tuotteen viimeinen voimassaolopäivä",
    startRemindingFrom: "Aloita muistutus",
    productSuppliesAddedSuccessfully: "Tuotetarvikkeiden lisääminen onnistui.",
    addProductSupplies: "Lisää tuotetarvikkeita",
    pleaseSelectSupplier: "Valitse toimittaja",
    nameOfProduct: "Tuotteen nimi",
    pleaseSelectProduct: "Valitse tuote",
    productVariant: "Tuotevaihtoehto",
    pleaseSelectAVariant: "Valitse vaihtoehto",
    pleaseEnterUnitPrice: "Anna yksikköhinta",
    businessBranch: "Liiketoiminta -alue",
    pleaseSelectBranch: "Valitse haara",
    youveSuccessfullyAddedANewSupplier: "Olet lisännyt uuden toimittajan",
    addSupplier: "Lisää toimittaja",
    pleaseEnterSupplierEmail: "Anna toimittajan sähköpostiosoite",
    pleaseAddADescription: "Lisää kuvaus",
    anErrorOccuredProductsDeleted:
      "Toiminnon suorittamisessa tapahtui virhe. Huomaa, että muutamia tuotteita on ehkä poistettu prosessin aikana",
    bulkDelete: "Joukkopoista",
    youAreAboutToDelete: "Olet poistamassa",
    product: "Tuote",
    isDueToRedeem: "johtuu lunastamisesta",
    aReward: "palkinto",
    pleaseSelectCustomerToReeemReward:
      "Valitse asiakas lunastaaksesi palkinnon.",
    youHaveNoLoyaltyProgramRunning:
      "Sinulla ei ole käynnissä aktiivista kanta -asiakasohjelmaa",
    customerHhasNoPointsInLoyaltyProgram:
      "Asiakkaalla ei ole pisteitä tässä kanta -asiakasohjelmassa",
    proceedWithPayment: "Jatketaanko maksua?",
    youAreAboutToPayForTransactionUsingPoints:
      "Olet maksamassa tapahtumasta pisteillä.",
    customerHas: "Asiakkaalla on",
    worth: "arvoinen",
    andIsNotSufficientToPayForTransaction:
      "eikä se riitä maksamaan tästä tapahtumasta. Haluaisivatko he lisätä saldon toisella maksutavalla?",
    addCustomerLoyalty: "Lisää asiakasuskollisuutta",
    pleaseAddCustomerFirst: "Lisää tai valitse ensin asiakas.",
    pleaseWaitWhileWeGetReady: "Odota, kun valmistaudumme",
    lowStock: "Varasto vähissä",
    pleaseEnterAmountTendered: "Anna tarjousmäärä",
    areYouSureToBookSaleOffline:
      "Haluatko varmasti varata tämän myynnin offline -tilassa?",
    saleWouldBeBookedAutomatically:
      "Myynti varataan automaattisesti, kun käynnistät Internetin",
    offlineSalesBookingCancelled: "Offline -myyntivaraus peruutettu",
    covid19Message:
      "COVID19: Pese kädet saippualla tai desinfioi leviämisen estämiseksi. Pysy aina turvassa",
    saleSuccessfullyRecorded: "Myynti onnistuneesti tallennettu!",
    sendReceiptToEmail: "Lähetä kuitti sähköpostiin",
    sendThankYouSms: "Lähetä kiitosviesti",
    sendShippingDetails: "Lähetä toimitustiedot",
    addLoyalty: "Lisää uskollisuus",
    searchCustomerNameOrNumber: "Etsi asiakkaan nimi tai numero",
    clickTheSearchCustomerBox:
      "Napsauta Hae asiakaslaatikkoa ja skannauskorttia",
    enterProductPrice: "Anna tuotteen hinta",
    enterPriceFor: "Syötä Hinta",
    searchForProduct: "Hae tuotetta",
    all: "Kaikki",
    backToDashboard: "Takaisin hallintapaneeliin",
    viewDraftSales: "Näytä Luonnosmyynti",
    variantSelected: "variantti valittu",
    variant: "variantti",
    thePreviousVariantSelectionNotAvailable:
      "Edellinen vaihtoehto ei ole käytettävissä uudelle hinnan perusteella valitulle muunnelmalle, muuta valintasi.",
    pleaseSupplyPositiveQuantityNumber: "Anna postinumero määrä",
    lowStockFor: "Varasto vähissä",
    clearVariants: "Selkeitä vaihtoehtoja",
    pleaseEnterQuantity: "Anna määrä",
    picture: "Kuva",
    redemptionToken: "Lunastusmerkki",
    token: "Token",
    totalSpent: "Käytetty yhteensä",
    confirmPayment: "Vahvista maksu",
    hasPaymentBeenMade: "Onko maksu käsitelty onnistuneesti?",
    enterTransactionReference: "Anna tapahtumaviite, jolla maksoit",
    pleaseSelectACustomer: "Valitse asiakas!",
    areYouSureToApplyDiscount: "Haluatko varmasti hakea alennusta?",
    addYourBankAccountToEnableUssd:
      "Lisää pankkitilisi, jotta voit ottaa käyttöön välittömän USSD -siirron uPaylla",
    totalAmountToPay: "Maksettava kokonaissumma",
    doYouWantToCancelTransaction: "Haluatko peruuttaa tämän tapahtuman?",
    savePrintBill: "Tallenna/tulosta lasku",
    enterAmountCollectedForCustomer: "Anna asiakkaalle kerätty summa",
    recordSale: "Ennätysmyynti",
    checkOutWith: "Tarkista kanssa",
    instantTransfer: "Välitön siirto",
    dialTheUSSDCode: "Valitse USSD -koodi",
    pleaseSelectABank: "Valitse pankki",
    payWithUSSD: "Maksa USSD: llä",
    sendBillTo: " - Lähetä Bill osoitteeseen",
    waitingForUSSDTransfer: "Odotetaan USSD -siirtoa",
    percent: "Prosenttia",
    applyDiscount: "Käytä alennusta",
    thisBillHasBeenSaved: "Tämä lasku on tallennettu",
    saveDraft: "Tallenna luonnos",
    pleaseTypeANameToIdentifyCustomer:
      "Kirjoita nimi asiakkaan tunnistamiseksi",
    paymentDetails: "Maksutiedot",
    basePrice: "Lähtöhinta",
    staff: "Henkilökunta",
    subTotal: "Välisumma",
    durationMonths: "Kesto (kuukautta)",
    selectADuration: "Valitse kesto",
    oneMonth: "1 kuukausi",
    twoMonths: "2 kuukautta",
    threeMonths: "3 kuukautta",
    sixMonths: "6 kuukautta",
    twelveMonths: "12 kuukautta",
    eighteenMonths: "18 kuukautta",
    twentyFourMonths: "24 kuukautta",
    twoMonthsFree: "(2 kuukautta ilmaiseksi)",
    threeMonthsFree: "(3 kuukautta ilmaiseksi)",
    fiveMonthsFree: "(5 kuukautta ilmaiseksi)",
    yourAccountHasBeen: "Tilisi on ollut",
    renewed: "uusittu",
    upgraded: "päivitetty",
    successfully: "onnistuneesti",
    yourSubscription: "Tilauksesi",
    youAreCurrentlyEnrolledOnA: "Olet tällä hetkellä ilmoittautunut a",
    pleaseChooseAPaymentOption: "Valitse maksuvaihtoehto",
    planRenewal: "Suunnittele uusiminen",
    planUpgrade: "Suunnittele päivitys",
    pleaseSelectDurationToPayFor: "Valitse kesto, josta aiot maksaa",
    staffAccounts: "Henkilötilit",
    ecommerce: "Verkkokauppa",
    pleaseSelectAPlan: "Valitse suunnitelma",
    includeAddons: "Sisällytä lisäosat",
    viewTransactions: "Näytä tapahtumat",
    customerHasNoCompletedTansactions:
      "Asiakas ei ole vielä suorittanut huoltoja",
    branch: "Haara",
    enterNumberOfEcommerceBranches: "Anna verkkokaupan sivukonttorien määrä",
    enterNumberOfEcommerceBranchesToPay:
      "Anna niiden verkkokaupan sivukonttoreiden määrä, joista aiot maksaa",
    ecommerceIntegration: "Verkkokaupan integrointi",
    enterNumberOfBranches: "Anna sivukonttoreiden lukumäärä",
    enterNumberOfAdditionalBranchesToPay:
      "Syötä lisätoimialojen määrä, joista aiot maksaa",
    enterNumberOfStaffs: "Syötä henkilöstön määrä",
    enterNumberOfStaffsToPayFor: "Anna henkilöstön määrä, josta aiot maksaa",
    discountApplies: "Alennus koskee",
    starsOnThe: "tähtiä",
    offer: "tarjous",
    get: "Saada",
    moreStarsToRedeem: "lisää tähtiä lunastettavaksi",
    taxVat: "Verot (alv)",
    callCashierToCompletePayment: "Soita kassalle suorittaaksesi maksun",
    receipt: "Kuitti",
    dear: "Rakas",
    thankYouForYourOrderFindGoods:
      "Kiitos tilauksestasi. Seuraavat tavarat toimitetaan sopimuksen mukaan.",
    shippingNote: "Toimitusilmoitus",
    enterShippingNote: "Anna lähetysilmoitus",
    shippingAddress: "Toimitusosoite",
    enterShippingAddress: "Anna toimitusosoite",
    wellDoneYouAreDueToRedeem: "Hyvin tehty! Sinun on lunastettava",
    toGetYourRewardNextVisit:
      "saadaksesi palkintosi seuraavalla vierailullasi. Kiitos",
    pointsOnThe: "Pisteitä",
    morePointsToRedeem: "lisää pisteitä lunastettavaksi",
    showCode: "Näytä koodi",
    toGetYourRewardOnNextVisit:
      "saadaksesi palkintosi seuraavalla vierailullasi. Kiitos",
    earn: "Ansaita",
    delivaryNote: "Toimitusilmoitus",
    draftSales: "Luonnosmyynti",
    startDate: "Aloituspäivämäärä",
    endDate: "Päättymispäivä",
    orders: "Tilaukset",
    checkout: "Tarkista",
    noProductItem: "Ei tuote -erää",
    selectProductImage: "Valitse Tuotekuva",
    selectCountry: "Valitse maa",
    selectRegion: "Valitse osavaltio/alue",
    printProductTag: "Tulosta tuotetunniste",
    transactionReference: "Tapahtuman viite",
    Cashier: "Kassa",
    Manager: "Johtaja",
    Owner: "Omistaja",
    Admin: "Järjestelmänvalvoja",
    addPartners: "Lisää kumppaneita",
    addNewLoyaltyPartner: "Lisää uusi kanta-asiakaskumppani",
    pleaseEnterCompanyName: "Anna Yrityksen nimi",
    companyName: "Yrityksen nimi",
    pleaseEnterCompanyRepName: "Anna yrityksen edustajan nimi",
    companyRepName: "Yrityksen edustajan nimi",
    pleaseEnterCompanyRepEmail: "Anna yrityksen edustajan sähköpostiosoite",
    companyRepEmail: "Yrityksen edustajan sähköposti",
    pleaseEnterCompanyRepPhone: "Anna yrityksen edustajan puhelinnumero",
    companyRepPhone: "Yrityksen edustajan puhelinnumero",
    addReward: "Lisää palkinto",
    pleaseEnterRewardName: "Anna palkinnon nimi",
    rewardName: "Palkinnon nimi",
    rewardQuantity: "Palkinnon määrä",
    rewardDescription: "Palkinnon kuvaus",
    rewardType: "Palkintotyyppi",
    pleaseEnterRewardType: "Anna palkintotyyppi",
    pleaseEnterRewardQuantity: "Anna palkkion määrä",
    pleaseEnterRewardDescription: "Anna palkinnon kuvaus",
    fineDining: "Hieno ruokailu",
    luxuryFashion: "Luksusmuoti",
    hotels: "Hotellit",
    travel: "Matkustaa",
    foodAndBeverage: "Ruoka ja juoma",
    fashion: "Muoti",
    health: "Terveys",
    furniture: "Huonekalut",
    entertainment: "Viihde",
    automobile: "Auto",
    education: "koulutus",
    beautyAndSpa: "Kauneus ja kylpylä",
    staycation: "Oleskelu",
    events: "Tapahtumat",
    trips: "Matkat",
    oilAndGas: "Öljy ja kaasu",
    laundry: "Pesula",
    partnerCategory: "Kumppaniluokka",
    freeItem: "Ilmainen kohde",
  },
  Frisian: {
    cashier: "kassier",
    manager: "behearder",
    owner: "eigner",
    online: "online",
    offline: "offline",
    changePassword: "Wachtwurd feroarje",
    currentPasswordMessage: "Fier jo hjoeddeiske wachtwurd yn",
    passwordMessage: "Folje jo wachtwurd yn",
    currentPassword: "Hjoeddeistich wachtwurd",
    password: "Wachtwurd",
    confirmPasswordMessage: "Befêstigje asjebleaft jo wachtwurd!",
    confirmPassword: "Befêstigje Wachtwurd",
    sendViaEmail: "Stjoer fia e -post",
    sendViaWhatsapp: "Stjoer fia WhatsApp",
    downloadPdf: "Download PDF",
    paid: "betelle",
    unpaid: "net betelle",
    partial: "part",
    closeInvoice: "Wolle jo de Faktuer slute?",
    closeInvoiceConfirmation: "Faktuer kin net wurde opslein. Wolle jo slute?",
    yes: "Ja",
    no: "Nee",
    invoice: "Faktuer",
    wasDeducted: "waard ferrekkene",
    for: "foar",
    item: "Ûnderdiel",
    addProduct: "Produkt tafoegje",
    paymentReference: "Payment Reference",
    amountPaid: "Betelle bedrach",
    discountAmount: "Koarting Bedrach",
    amountDue: "Betelle bedrach",
    amount: "Tal",
    dueDate: "Ferfaldatum",
    paymentType: "Betel wize",
    card: "Kaart",
    cash: "Cash",
    bankTransfer: "Bankoerskriuwing",
    paymentMessage: "Betelling Berjocht",
    description: "Beskriuwing",
    sendReceipt: "Stjoer kwitânsje",
    delete: "Wiskje",
    save: "Rêde",
    resend: "Opnij ferstjoere",
    saveAndSend: "Rêde",
    invoiceSaved: "Faktuer opslein!",
    selectPaymentMethod: "Selektearje asjebleaft in betelmethode!",
    selectCustomer: "Selektearje asjebleaft in klant!",
    cartEmptyError:
      "Winkellist kin net leech wêze, de faktuer slute en Item tafoegje oan winkelwein!",
    subscriptionExpired:
      "Jo abonnemint is ferrûn en jo akkount is no beheind. Klikje op de knop hjirûnder om jo akkount te fernijen",
    renew: "Fernije",
    holdOn: "Hald fol",
    customerBank: "Klantebank",
    cancel: "Ofbrekke",
    selectACustomer: "Selektearje klant",
    invoiceSuccessfulPdfError:
      "Faktuer is mei súkses oanmakke, mar PDF -generaasje duorret langer dan normaal. Kontrolearje asjebleaft efkes.",
    downloadingInvoice: "Faktuer downloade",
    downloadingReceipt: "Download kwitânsje",
    whatsappReceiptError:
      "Der is in flater bard by it ferstjoeren fan de kwitânsje fia WhatsApp. Besykje it nochris.",
    receiptToWhatsapp: "Untfang ferstjoerd nei WhatsApp",
    thankYouForPatronage: "Tankewol foar jo patronage",
    hereIsYourReceipt: "Hjir is jo betellingsbewiis",
    errorSendingEmailReceipt:
      "Der is in flater bard by it ferstjoeren fan de kwitânsje fia e-post, besykje it nochris as nim kontakt op mei stipe",
    receiptSentToEmail: "Untfang is stjoerd nei de e -post fan klant",
    invoiceSentToEmail: "Faktuer is stjoerd nei e -post fan klant",
    invoiceSuccessWhatsappError:
      "Faktuer is mei súkses oanmakke, mar d'r is in flater bard by it ferstjoeren nei WhatsApp. Besykje it nochris op 'e faktuerlist",
    invoiceSuccessfulEmailError:
      "Faktuer is mei súkses oanmakke, mar der is in flater bard by ferstjoeren as e-post. Besykje it nochris út 'e faktuerlist",
    invoiceSentToWhatsapp: "Faktuer trochstjoerd nei WhatsApp",
    hello: "Hallo",
    pleaseDownloadInvoice: "Download asjebleaft faktuer",
    pleaseDownloadReceipt: "Download asjebleaft kwitânsje",
    from: "fan",
    email: "E -post",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Jo binne op in fergees plan.",
    clickOn: "Klikje op",
    yourPlanInFewSteps: " jo abonnemint yn in pear rappe stappen.",
    to: "nei",
    yourSubscriptionHasExpired:
      "Jo abonnemint is ferrûn en jo akkount is no beheind.",
    days: "dagen",
    yourSubscriptionExpiresIn: "Jo Loystar -abonnemint ferrint yn",
    createAcount: "Meitsje in akkount",
    signIn: "Ynlogge",
    continue: "Trochgean",
    enterOtp: "Fier OTP PIN yn",
    enterValidOtp: "Fier in jildige PIN yn",
    pin: "PIN",
    tokenSentToMail: "In token is nei jo e -post stjoerd",
    passwordResetSuccessful: "Wachtwurd weromsette wie suksesfol",
    somethingWentWrong: "Der is wat misgien!",
    resetPassword: "Wachtwurd feroarje",
    iHaveResetCode: "Ik haw in wachtwurd weromsette koade",
    pleaseEnterEmail: "Fier jo e -postadres yn",
    aTokenWillBeSentToEmail: "In token soe nei jo e -post wurde ferstjoerd",
    enterEmail: "Fier jo e -post yn",
    sendinYourToken: "Stjoere jo token ...",
    makeSureItMatchesPassword:
      "Soargje derfoar dat it oerienkomt mei jo nije wachtwurd",
    pleaseChooseNewPassword: "Kies asjebleaft in nij wachtwurd",
    chooseNewPassword: "Kies in nij wachtwurd",
    enterNewPassword: "Fier jo nije wachtwurd yn om te befêstigjen",
    enterTokenSent: "Fier it token yn dat jo e -post is ferstjoerd",
    resetToken: "Reset Token",
    resettingPassword: "Wachtwurd weromsette ...",
    signUp: "Ynskriuwe",
    adminSignInWithEmail:
      " Admin meldt oan mei e -post, wylst personiel ynlogt mei brûkersnamme.",
    pleaseEnterEmailOrUsername: "Fier jo e -postadres as brûkersnamme yn",
    emailOrUsername: "E -post as brûkersnamme",
    pleaseEnterPassword: "Fier wachtwurd yn",
    createAnAccount: "Meitsje in akkount",
    forgotPassword: "Wachtwurd ferjitten?",
    enterPhoneNumber: "Fier telefoannûmer yn",
    personalData: "Persoanlike gegevens",
    validateConfirmationCOde: "Befêstigje koade falidearje",
    pleaseEnterFirstName: "Fier jo foarnamme yn",
    pleaseEnterPhoneNumber: "Fier jo tillefoannûmer yn",
    pleaseEnterLastName: "Fier asjebleaft jo efternamme yn",
    pleaseEnterBusinessName: "Fier jo bedriuwsnamme yn",
    firstName: "Foarnamme",
    lastName: "Achternamme",
    businessName: "Bedriuwsnamme",
    previous: "Foarige",
    next: "Folgjende",
    pleaseSelectBusinessCategory: "Selektearje asjebleaft jo saaklike kategory",
    pleaseEnterValidEmail: "Fier in jildich e-mailadres yn",
    pleaseEnterPostCode: "Fier de postkoade yn",
    postCode: "Postkoade",
    phoneNumberInUse: "Dit telefoannûmer is al yn gebrûk!",
    emailInUse: "Dizze e -post is al yn gebrûk!",
    foodAndDrinks: "Iten en drinken",
    salonAndBeauty: "Salon en skientme",
    fashionAndAccessories: "Moade en accessoires",
    gymAndFitness: "Gym en fitness",
    travelAndHotel: "Reizen en Hotel",
    homeAndGifts: "Thús en kado's",
    washingAndCleaning: "Waskje en skjinmeitsje",
    gadgetsAndElectronics: "Gadgets en elektroanika",
    groceries: "Boadskippen",
    others: "Oaren",
    submit: "Submit",
    accountCreatedSuccessful: "Jo akkount is mei súkses oanmakke.",
    pleaseEnterAddress: "Fier asjebleaft jo adres yn",
    addressLine1: "Adresline 1",
    addressLine2: "Adresline 2",
    choosePassword: "Kies wachtwurd",
    passwordMustBe6Characters: "Wachtwurd moat op syn minst 6 tekens wêze.",
    howDidYouHearLoystar: "Hoe hawwe jo heard oer Loystar?",
    referralCode: "referinsjekoade",
    byClickingTheButton:
      " Troch op de knop hjirûnder te klikken, geane jo akkoard mei de",
    termsAndSevice: "Betingsten",
    wereCreatingAccount: "Wy meitsje jo akkount oan",
    proceed: "Trochgean",
    verificationCodeMustBe6: "Ferifikaasjekoade moat 6 sifers wêze",
    pleaseEnter6DigitCode: "Fier asjebleaft 6 -sifer koade yn",
    enterVerificationCode: "Folje ferifikaasjekoade yn",
    resendToken: "Token opnij ferstjoere",
    verify: "Ferifiearje",
    transactions: "Transaksjes",
    todaySales: "De ferkeap fan hjoed",
    salesHistory: "Ferkeapskiednis",
    supplyHistory: "Supply Skiednis",
    new: "Nij",
    invoices: "Faktueren",
    disbursements: "Utjeften",
    offlineSales: "Offline ferkeap",
    products: "Products",
    customers: "Klanten",
    multiLevelLoyalty: "Loyaliteit op mear nivo",
    loyaltyPrograms: "Lojalitetsprogrammer",
    members: "Leden",
    appStore: "App Store",
    orderMenu: "Order Menu",
    settings: "Ynstellings",
    staffAndBranches: "Meiwurkers en tûken",
    myAccount: "Myn account",
    switchToSellMode: "Gean nei Ferkeapmodus",
    signOut: "Út logge",
    getFreeSubscription: "Krij fergees abonnemint",
    onlyNumbersAllowed: "Allinnich nûmers binne tastien",
    yourAccountMustBe10Digits: "jo akkountnûmer moat in 10 -sifer nûmer wêze",
    yourBvnMustBe11: "jo BVN moat in nûmer fan 11 sifers wêze",
    pleaseSelectBank: "Selektearje asjebleaft jo bank",
    selectYourBank: "Selektearje jo Bank",
    enterBankAccountNumber: "Fier it bankrekkennûmer yn",
    enterBvnNumber: "Fier jo BVN yn",
    connectBankAccount: "Ferbine Bankrekken",
    passwordResetSuccessfulAndSignOut:
      "Jo wachtwurd is mei súkses opnij ynsteld. Klikje op de knop Ofmelde hjirûnder om opnij oan te melden",
    enterCurrentPassword: "Fier it hjoeddeiske wachtwurd yn",
    pleaseEnterCurrentPassword: "Fier it hjoeddeiske wachtwurd yn",
    phoneNumber: "Telefoannûmer",
    sex: "Seks",
    dateOfBirth: "Bertedatum",
    state: "Steat",
    country: "Lân",
    loyaltyId: "Loyaliteit ID",
    createdAt: "Created At",
    noOfTransactionsMade: "Oantal transaksjes makke",
    yourDownloadWillStart: "Jo download sil yn in momint begjinne",
    exportCustomers: "Klanten eksportearje",
    youhaveSuccessfullyToppedUp:
      "Jo hawwe jo SMS -ienheden mei súkses oergien.",
    youNowHave: "Jo hawwe no",
    smsUnits: "SMS -ienheden",
    enterNumberOfSmsUnits:
      "Fier it oantal SMS -ienheden yn dat jo fan doel binne te keapjen",
    pleaseEnterANumericValue: "Fier asjebleaft in numerike wearde yn",
    pay: "Betelje",
    accountEditedSuccessfully: "Jo akkount is mei súkses bewurke.",
    youAeCurrentlyOn: "Jo binne op it stuit oan",
    plan: "Plan",
    userData: "Brûkersgegevens",
    businessData: "BUSINESS DATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Selektearje asjebleaft jo faluta",
    selectYourCurrency: "Selektearje jo faluta",
    purchaseMoreSmsUnits:
      "Keapje mear SMS -ienheden mei it formulier hjirûnder",
    youHave: "Jo hawwe",
    atLeast4SmsUnitsRrequired:
      "Op syn minst 4 sms -ienheden is fereaske foar ferifikaasje, folje asjebleaft op!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ferifiearje asjebleaft jo bankrekken om te foldwaan oan de 'Know Your Customer' (KYC) easken. Hjirmei kinne jo betellingen sammelje fia USSD as direkte oerdracht, oarders ûntfange fan klanten en transaksjeôfwikkeling ferwurkje. Allinnich fan tapassing op Nigeriaanske keaplju. Druk asjebleaft op de knop hjirûnder om te begjinnen.",
    reConnectBankAccount: "Ferbine bankkonto opnij",
    accountName: "Akkount Namme",
    accountNumber: "Akkountnûmer",
    bankName: "Bank Namme",
    verified: "Ferifiearje",
    accountDetails: "Account Details",
    kycBankAccount: "KYC",
    createTier: "Tier oanmeitsje",
    fileUploadSuccessful: "bestân mei súkses upload",
    fileUploadFailed: "upload fan bestân mislearre",
    createLoyaltyProgram: "Meitsje in loyaliteitsprogramma",
    createLoyalty: "Loyaliteit oanmeitsje",
    name: "Namme",
    loyaltyArtwork: "Loyalty Artwork",
    clickToUpload: "Klikje om te uploaden",
    amountToPointsRatio: "Bedrach nei Punten Ratio",
    points: "Punten",
    recommendedAmountToPointRatio:
      "Oanbefelle: ₦ 1 oant 1 punt. ie foar elke 1 Naira útjûn krije jo klanten 1 punt",
    pleaseTypeIn: "Typ asjebleaft yn",
    toDeleteLoyalty: "om dizze loyaliteit te wiskjen",
    deleteLoyalty: "Loyalty wiskje",
    toConfirm: "Befêstigje",
    edit: "Bewurkje",
    pointsAwardedSuccessfully: "Punten mei súkses takend.",
    enterPointValueToAward: "Puntwearde ynfiere dy't jo klanten wolle tawize",
    award: "Award",
    awardPointValuesToCustomer: "Priispuntwearde oan klant",
    enrollMembersToLoyalty: "Skriuw leden yn op loyaliteit",
    awardPoints: "Priispunten",
    enroll: "Ynskriuwe",
    home: "Thús",
    loyalty: "Loyaliteit",
    enrollCustomers: "Klanten ynskriuwe",
    selected: "Selektearre",
    customer: "Klant",
    loyaltyActivationSuccessful: "Loyalty Activation is suksesfol.",
    loyaltyDeactivationSuccessful: "Loyalty Deaktivearje is suksesfol.",
    viewTier: "Besjoch Tier",
    deactivate: "De-aktivearje",
    activate: "Aktivearje",
    actions: "Aksjes",
    nameOfLoyalty: "Namme fan loyaliteit",
    loyaltyStatus: "Loyalty StatusLoyalty Status",
    numberOfTiers: "Oantal Tiers",
    createdOn: "Oanmakke op",
    createATier: "Meitsje in Tier",
    stopCreatingTierConfirmation:
      "Wolle jo stopje mei it meitsjen fan in tier?",
    stopEditingTierConfirmation:
      "Wolle jo stopje mei it bewurkjen fan dizze tier?",
    nameOfTier: "Namme fan Tier",
    minimumSpendingTarget: "Minimum bestegingsdoel",
    maximumSpendingTarget: "Maksimum bestegingsdoel",
    minimumSpendingTargetRequired: "minimum bestegingsdoel wurdt fereaske",
    maximumSpendingTargetRequired: "maksimum bestegingsdoel wurdt fereaske",
    rewardSponsor: "Belonningsponsor",
    pleaseChooseARewardSponsor: "Kies asjebleaft in beleanningssponsor",
    self: "Sels",
    partner: "Kompanjon",
    rewardPartner: "Belonningspartner",
    pleaseSelectRewardPartner: "Selektearje asjebleaft jo beleanningspartner",
    rewards: "Rewards",
    pleaseSelectAReward: "Selektearje asjebleaft in beleanning",
    instructionsOnRedeemingReward:
      "Ynstruksjes oer hoe't klant beleanning moat ynlosse",
    pleaseFillInThisField: "Folje dit fjild yn asjebleaft!",
    toDeleteThisTier: " dizze tier te wiskjen",
    deleteTier: "Tier wiskje",
    viewMembers: "View Members",
    membersEnrolled: "Leden ynskreaun",
    instruction: "Ynstruksje",
    membersIn: "Leden yn",
    availableTiersInLoyalty: "Beskikbere tier (en) yn loyaliteitsprogramma",
    tiers: "Tiers",
    totalTier: "TOTAAL TIER",
    availableLoyaltyProgramme: "Beskikbere loyaliteitsprogramma",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Lid Details",
    nameOfCustomer: "Namme fan klant",
    address: "Adres",
    allEnrolledMembers: "Alle ynskreaune leden",
    thisIsToWishYouHappyBirthday:
      "Dit is om jo in heul lokkige jierdei en in wolfarrend libben te winskjen. Betanke foar alles wat jo binne foar Loyster. Lokkige feesten!",
    inputAnOfferPlease: "Jou in oanbieding yn asjebleaft",
    pleaseSelectTheInsertPoint:
      "Selektearje asjebleaft it ynfoegpunt yn it berjocht en klikje opnij",
    birthdayOfferAndMessage: "Jierdei oanbod en berjocht",
    birthdayOffer: "Birthday Offer",
    birthdayMessage: "Jierdei Berjocht",
    noOfferFound: "Gjin oanbod fûn",
    settingABirthdayOffer:
      "Troch in jierdei -oanbieding yn te stellen kinne klanten dit oanbod fia SMS op har jierdei ûntfange",
    createOffer: "Oanbod oanmeitsje",
    whatIsTheOffer: "Wat is it oanbod?",
    editMessage: "Berjocht bewurkje",
    insert: "Ynfoegje",
    theNameOfCustomerInserted: "De namme fan de klant sil hjir wurde ynfoege",
    theBirtdayOfferInserted: "It jierdei oanbod sil hjir wurde ynfoege",
    youSuccessfullyAddedNewBranch: "Jo hawwe mei súkses in nije tûke tafoege!",
    addNewBranch: "Nije tak taheakje",
    addBranch: "Branch taheakje",
    additionalBranchWillIncur: "Oanfoljende Branch soe opkomme",
    perBranch: "per tûke",
    ecommerceBranchCosts: "Kosten foar e -commerce Branch",
    pleaseEnterBranchName: "Fier de filiaalnamme yn",
    pleaseEnterBranchAddress1:
      "Fier asjebleaft de adresregel 1 fan 'e ôfdieling yn",
    enterBranchAddress1: "Fier de adresline fan 'e filiaal yn",
    enterBranchAddress2: "Fier de adresline fan 'e filiaal yn",
    pleaseEnterBranchAddress2:
      "Fier asjebleaft de adresline 2 fan 'e ôfdieling yn",
    enterBranchName: "Fier de filiaalnamme yn",
    successfullyAddedStaff: "Jo hawwe mei súkses in nij personiel tafoege!",
    addNewStaff: "Nije meiwurkers taheakje",
    addStaff: "Add Staff",
    additionalStaffWillIncur: "Ekstra personiel soe lije",
    perStaff: "per personiel.",
    pleaseEnterStaffEmail: "Fier de e -post fan it personiel yn",
    pleaseEnterStaffUsername: "Fier de brûkersnamme fan it personiel yn",
    pleaseEnterStaffPassword: "Fier it wachtwurd fan it personiel yn",
    pleaseSelectStaffRole: "Selektearje asjebleaft de rol fan it personiel",
    selectStaffRole: "Selektearje de rol fan it personiel",
    enterStaffEmail: "Fier de e -post fan it personiel yn",
    enterStaffUsername: "Fier de brûkersnamme fan it personiel yn",
    enterStaffPassword: "Fier it wachtwurd fan it personiel yn",
    spacesNotAllowedInUsername: "spaasjes net tastien yn brûkersnamme",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Selektearje asjebleaft bedriuw om personiel oan te heakjen",
    searchForBranchToAttachStaff:
      "Sykje nei filiaal om personiel oan te heakjen",
    username: "Brûkersnamme",
    role: "Rol",
    areYouSureToDeleteThis: "Binne jo wis dat jo dit wiskje",
    branches: "Tûken",
    upgradeYourPlan: "Upgrade jo plan.",
    add: "ADD",
    addNew: "Foegje nij ta",
    customerWithEmailAlreadyExists:
      "Klant mei e -post/telefoannûmer bestiet al!",
    successfullyAddedNewCustomer: "Jo hawwe mei súkses in nije klant tafoege!",
    addCustomer: "Klant tafoegje",
    pleaseEnterCustomerFirstName: "Fier asjebleaft de foarnamme fan klant yn",
    pleaseEnterCustomerLastName: "Fier asjebleaft de efternamme fan klant yn",
    pleaseEnterCustomerPhoneNumber:
      "Fier asjebleaft it telefoannûmer fan klant yn",
    pleaseEnterCustomerEmail: "Fier asjebleaft de e -post fan klant yn",
    pleaseEnterCustomerAddressLine: "Fier asjebleaft de adresline fan klant yn",
    pleaseEnterCustomerOtherAddress: "Fier it oare adres fan de klant yn",
    pleaseSelectCustomerGender: "Selektearje asjebleaft it geslacht fan klant",
    gender: "Geslacht",
    male: "Manlik",
    female: "Female",
    bank: "Bank",
    selectBank: "Selektearje Bank",
    stateOrRegionOrProvince: "Steat/Regio/Provinsje",
    customerNotes: "Klantnotysjes",
    sendSms: "Stjoer SMS",
    editCustomer: "Klant bewurkje",
    redeemReward: "Beleanning ynlosse",
    issueLoyaltyCard: "Loyaliteitskaart útjaan",
    deleteCustomer: "Wiskje klant",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Jo hawwe mei súkses in Loyalty Membership ID tawiisd",
    noMembershipIdAvailable:
      "Gjin lidmaatskip -ID's beskikber. Nim dan kontakt mei ús op op hello@loystar.co",
    sendEmail: "Stjoer email",
    membershipPoints: "Lidmaatskip Punten",
    customerDetails: "Klant Details",
    close: "Slute",
    loyaltyBalance: "Loyaliteitssaldo",
    pointsBalance: "Punten lykwicht",
    starBalance: "Star Balance",
    requiredPoints: "Ferplichte punten",
    requiredStars: "Fereaske stjerren",
    reddemCode: "Koade ynlosse",
    toDeleteThisCustomer: "om dizze klant te wiskjen",
    customerHasBeenDeletedSuccessfully: "Klant is mei súkses wiske!",
    customerWithPhoneAlreadyExists: "Klant mei telefoannûmer bestiet al",
    customerWasSuccessfullyEdited: "Klant is mei súkses bewurke",
    anErrorOccured: "Der is in flater bard",
    phoneNumberIncludeCountry: "Telefoannûmer (Lânkoade opnimme)",
    yourFileQueuedForUpload:
      "Jo bestân is yn 'e wachtrige foar upload. Ferfarskje de side nei in pear sekonden.",
    thereWasAnErrorPerformingOperation:
      "Der wie in flater by it útfieren fan de operaasje!",
    pleaseSelectFile: "Selektearje asjebleaft in bestân!",
    oopsOnlyCsvFilesAllowed:
      "Oeps! Allinnich CSV -bestannen tastien. Upload asjebleaft in .csv -bestân.",
    fileShouldBeSmallerThan5Mb:
      "Triem moat lytser wêze dan 5MB. As jo in grutter bestân hawwe, stjoer dan in e -post nei support@loystar.co. Dankewol",
    customerFirstNameIsRequired: "klant foarnamme is fereaske op rige",
    customerPhoneNumberIsRequired: "klant telefoannûmer is fereaske op rige",
    importCustomers: "Klanten ymportearje",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Klikje op dit ikoan om de CSV -bestânsjabloan te downloaden.",
    getGoogleSheetVersion: "Krij de ferzje fan Google sheet",
    clickOrDragFileToUpload:
      "Klikje of sleep it bestân nei dit gebiet om te uploaden",
    missingOrInvalidColumnHeader:
      "Untbrekkende of ûnjildige kolomkop. Folgje asjebleaft it sjabloanformaat. Dankewol.",
    youHaveImported: "Jo hawwe ymporteare",
    youSuccessfullyRedeemedReward: "Jo hawwe jo beleanning mei súkses ferlost!",
    sixDigitCode: "Seis sifer koade",
    pleaseEnterCustomerRewardCode:
      "Fier asjebleaft de beleanningskoade fan klant yn",
    enterRewardCode:
      "Fier beleanningskoade yn. (De beleanningskoade is haadlettergefoelig)",
    selectLoyaltyProgram: "Selektearje loyaliteitsprogramma",
    stamps: "Postsegels",
    smsUnitsLow: "SMS -ienheden leech",
    pleaseTopUpSmsUnits: "Meitsje asjebleaft SMS -ienheden op",
    smsSuccessfullySentForDelivery: "SMS mei súkses ferstjoerd foar levering!",
    sendSmsTo: "Stjoer SMS nei",
    allCustomers: "Alle Klanten",
    unitsAvailable: "Units beskikber",
    pleaseTypeInTheMessage: "Typ it berjocht yn",
    message: "Berjocht",
    charactersRemaining: "karakters oerbleaun",
    avoidUseOfSpecialCharacters:
      "Mije it brûken fan spesjale tekens en emoji's om SMS -ienheden te behâlden.",
    note: "Noat",
    errorFetchingCustomersMultilevelDetail:
      "Flater by it heljen fan details oer mearnivo's fan klanten",
    search: "Sykje",
    reset: "Reset",
    importCustomer: "Klant ymportearje",
    addNewCustomer: "Nije klant taheakje",
    sendSmsBroadcast: "Stjoer SMS -útstjoering",
    totalCustomers: "TOTAAL KLANTEN",
    disbursementDetails: "Utbetellingsdetails",
    paidBy: "Betelle troch",
    disbursed: "Utbetelle",
    bankAccountName: "Bankrekken Namme",
    bankAccountNumber: "Rekkennûmer",
    transferInitiated: "Oerdracht inisjearre",
    transferCompleted: "Oerdracht foltôge",
    pleaseEnterAValid: "Fier asjebleaft in jildich yn",
    begin: "begjinne",
    end: "ein",
    date: "datum",
    paymentDate: "Betalingsdatum",
    selectDisbursementDuration: "Selektearje Utbetellingstiid",
    totalSettled: "Totaal fêstlein",
    totalUnsettled: "Totaal ûnbetelle",
    toDeleteThisSale: "dizze ferkeap te wiskjen",
    draftSaleDeletedSuccessfully: "Konseptferkeap is mei súkses wiske!",
    deleteSale: "Sale wiskje",
    pleaseTypeInYourTotal:
      "Typ asjebleaft jo totaal yn om it wiskjen te befêstigjen",
    billDetails: "Bill Details",
    printBill: "Print Bill",
    servedBy: "Servearre troch",
    total: "Totaal",
    createdDate: "Datum makke",
    createdTime: "Tiid makke",
    status: "Status",
    loginSuccessful: "Login slagge",
    pleaseWaitWhileWeConnectAccount:
      "Wachtsje asjebleaft wylst wy jo akkount ferbine",
    connectionFailedTryAgain: "Ferbining mislearre. Besykje it nochris.",
    connectionSuccessful: "Ferbining Súksesfol",
    viewDetails: "Sjoch de details",
    enable: "Ynskeakelje",
    free: "Frij",
    cost: "Kosten",
    visitWebsite: "Besykje webside",
    pleaseUpgradeYourPlanToPro:
      "Upgrade asjebleaft jo plan nei Pro Plus om dizze app yn te skeakeljen",
    connectYourBankAccount:
      "Ferbine jo bankrekken om betellingen te ûntfangen.",
    disable: "Utskeakelje",
    enableApp: "App ynskeakelje",
    addNewProductToInvoice: "Foegje nij produkt ta oan faktuer",
    toDeleteThisInvoice: "om dizze Faktuer te wiskjen",
    invoiceDeletedSuccessfully: "Faktuer is mei súkses wiske!",
    deleteInvoice: "Faktuer wiskje",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Jo hawwe mei súkses in Faktuerbetellingsberjocht tafoege!",
    pleaseEnterPaymentInstructions: "Fier asjebleaft betellingsynstruksjes yn",
    invoiceId: "Faktuer ID",
    paidDate: "Betelle datum",
    reference: "Referinsje",
    productAdded: "Produkt tafoege",
    productOutOfStock: "Produkt net op foarried. Graach opnij ynventarisearje.",
    totalInvoices: "TOTAAL FAKTUREN",
    totalPaidInvoices: "TOTAAL BETALDE FAKTUREN",
    totalUnpaidInvoices: "TOTAAL ONBETALDE FAKTUREN",
    loyaltyProgramDeleted: "Lojalitetsprogram wiske",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dit loyaliteitsprogramma is mei súkses wiske",
    thisLoyaltyProgramEditedSuccessfully:
      "Dit loyaliteitsprogramma is mei súkses bewurke",
    loyaltyProgramAddedSuccessfully:
      "Loyaliteitsprogramma is mei súkses tafoege",
    loyaltyProgramEdited: "Loyalty Program bewurke",
    loyaltyProgramAdded: "Loyaliteitsprogramma tafoege",
    loyaltyDetails: "Loyalty Details",
    doYouWantToCloseDialog: "Wolle jo dit dialooch slute?",
    pleaseEnterLoyaltyName: "Fier de namme fan jo loyaliteit yn",
    programType: "Programma Type",
    pleaseSelectProgramType: "Selektearje asjebleaft in programmatype",
    simplePoints: "Ienfâldige punten",
    stampsProgram: "Postsegels Program",
    threshold: "Drompel",
    pleaseEnterLoyaltyThreshold: "Fier de lojaliteitsdrompel yn",
    reward: "Lean",
    pleaseEnterLoyaltyReward:
      "Fier asjebleaft de beleanning fan 'e loyaliteit yn",
    totalUserPoints: "Totaal brûkerspunten",
    totalUserStamps: "Totale brûkersstempels",
    spendingTarget: "Doel útjaan",
    spendingTargetHint1:
      "Doel útjaan is hoefolle in klant moat útjaan om de beleanning te fertsjinjen. 1 muntwearde = 1 punt.",
    spendingTargetHint2:
      "Doel fan postsegels is hoefolle postsegels in klant moat sammelje om de beleanning te fertsjinjen. bgl. 5",
    addNewLoyaltyProgram: "Foegje nij loyaliteitsprogramma ta",
    addLoyaltyProgram: "Foegje loyaliteitsprogramma ta",
    loyaltyProgramType: "Loyaliteitsprogrammatype",
    pleaseSelectLoyaltyProgramType:
      "Selektearje asjebleaft loyaliteitsprogrammatype",
    nameOfProgram: "Namme fan programma",
    pleaseEnterProgramName: "Fier programma namme yn",
    whatIsTheReward: "Wat is de beleanning?",
    egNextPurchaseIsFree: "Bgl. Folgjende oankeap is fergees",
    customerName: "Klant namme",
    guestCustomer: "Gast klant",
    orderConfirmedSuccessfully: "Bestelling mei súkses befêstige",
    orderCancelledSuccessfully: "Oarder is mei súkses annulearre",
    confirmOrder: "Bestelling befestigje",
    cancelOrder: "Ofbrekke annulearje",
    allOrders: "Alle bestellingen",
    totalOrders: "TOTAAL ORDERS",
    doYouWantToAcceptOrder: "Wolle jo dizze bestelling akseptearje?",
    doYouWantToCancelOrder: "Wolle jo dizze bestelling annulearje?",
    orderDetails: "Order Details",
    orderCreatedAt: "Oarder makke op",
    supplier: "Supplier",
    productName: "Produkt Namme",
    quantity: "Kwantiteit",
    unitPrice: "Unit Priis",
    receivedBy: "Ûntfongen troch",
    reportFrom: "Ferslach fan",
    totalSupplies: "Totaal Supplies",
    allRightsReserved: "Alle rjochten foarbehâlden",
    toDeleteThisTransaction: "dizze transaksje te wiskjen",
    transactionDeletedSuccessfully:
      "Transaksje is mei súkses wiske. Stock is weromjûn nei ynventarisaasje.",
    deleteTransaction: "Transaksje wiskje",
    transactionDetails: "Transaksje Details",
    printReceipt: "Print kwitânsje",
    channel: "Kanaal",
    discount: "Koarting",
    profit: "Winst",
    discountedSales: "Discounted Sales",
    errorFetchingRecord: "Flater by it opheljen fan rekord",
    exportTransactions: "Eksportearje transaksjes",
    errorFetchingSalesRecord:
      "Flater by it opheljen fan ferkeaprekord foar eksport.",
    totalSellingPrice: "Totale ferkeappriis",
    totalCostPrice: "Totale kostpriis",
    appliedDiscount: "Tapaste koarting",
    noOfItems: "Oantal items",
    sales: "Ferkeap",
    export: "Eksportearje",
    totalProfit: "Totale winst",
    totalBalanceInPeriod: "Totaal saldo yn perioade",
    allTimeSales: "All Time Sales",
    records: "Records",
    todaysSales: "De ferkeap fan hjoed",
    transaction: "transaksje",
    youHaveExceededTotalNumberOfProducts:
      "Jo hawwe it totale oantal produkten dat op jo plan is tastien oerskreaun. Upgrade jo plan om te genietsjen fan in hegere limyt.",
    youNeedToHaveLoyaltyProgram:
      "Jo moatte in loyaliteitsprogramma hawwe om dizze funksje te brûken!",
    price: "Priis",
    category: "Kategory",
    stockTracking: "Stock Tracking",
    stockCount: "Stock Count",
    taxed: "Belêste",
    originalPrice: "Oarspronklike priis",
    costPrice: "Kostpriis",
    unit: "Ienheid",
    productImage: "Produktôfbylding",
    taxRate: "Belestingtaryf",
    taxType: "Tax Type",
    trackInventory: "Track Ynventarisaasje",
    variants: "Farianten",
    hasVariants: "Hat Farianten",
    importProduct: "Produkt ymportearje",
    exportProducts: "Produkten eksportearje",
    addNewProduct: "Nij produkt taheakje",
    viewCategory: "Kategory besjen",
    viewSuppliers: "Besjoch leveransiers",
    receiveInventory: "Untfang ynventarisaasje",
    printAllProductsTag: "Print alle produkten tag",
    deleteAll: "Ferwiderje alles",
    totalProducts: "TOTAL PRODUCTS",
    youveSuccessfullyAddedANewCategory:
      "Jo hawwe mei súkses in nije kategory tafoege",
    addNewCategory: "Nije kategory taheakje",
    addCategory: "Kategory taheakje",
    categoryName: "Kategory Namme",
    pleaseEnterCategoryName: "Fier asjebleaft kategorynamme yn",
    stampsTarget: "Postsegels Doel",
    sendInventory: "Stjoer ynventarisaasje",
    productDetails: "Produkt Details",
    youveSuccessfullyEditedThisCategory:
      "Jo hawwe dizze kategory mei súkses bewurke",
    update: "Update",
    categoryList: "Kategory List",
    categories: "Kategoryen",
    enterQuantityToUpdate: "Fier in kwantiteit yn om by te wurkjen",
    inventorySentSuccessfully: "De ynventarisaasje is mei súkses ferstjoerd!",
    updateInventory: "Ynventarisaasje bywurkje",
    currentQuantity: "Aktuele kwantiteit",
    pleaseEnterQuantityToAdd: "Fier de kwantiteit yn dy't jo wolle tafoegje",
    pleaseSelectABranch: "Selektearje asjebleaft in Branch",
    searchForBranch: "Sykje nei branch",
    youCantSendMoreThanStock:
      "Jo kinne net mear stjoere dan jo op foarried hawwe",
    send: "Stjoere",
    pleaseEnterQuantityToSend: "Fier de kwantiteit yn dy't jo wolle ferstjoere",
    productNameIsRequiredOnRow: "produktnamme is fereaske op rige",
    productCategoryIsRequiredOnRow: "produktkategory is fereaske op rige",
    productPriceIsRequiredOnRow: "produktpriis is fereaske op rige",
    productUnitIsRequiredOnRow: "produkt ienheid is fereaske op rige",
    productQuantityIsRequiredOnRow: "produkt kwantiteit is fereaske op rige",
    productVariantsRequireTracking: "produktfarianten fereasket folgjen!",
    pleaseAddVariantClickButton:
      "Foegje asjebleaft in fariant ta troch te klikken op de knop taheakje!",
    totalVariantsMoreThanSelectedQuantity:
      "Totale farianten binne mear dan selekteare produktkwantiteit, Pls ferminderje fariant kwantiteit",
    productEditedSuccessfully: "Produkt is mei súkses bewurke!",
    fileTooLargeLessThan4Mb:
      "Bestânsgrutte is te grut! Bestânsgrutte moat minder dan 4MB wêze.",
    suchVariantAlreadyExist: "Sokke fariant bestiet al",
    addVariants: "Farianten tafoegje",
    editProduct: "Produkt bewurkje",
    pleaseEnterProductName: "Fier produktnamme yn",
    pleaseEnterProductPrice: "Fier asjebleaft produktpriis yn",
    pleaseEnterProductOriginalPrice:
      "Fier asjebleaft produkt orizjinele priis yn",
    productDescription: "produkt Omskriuwing",
    selectProductCategory: "Selektearje Produktkategory",
    pleaseSelectProductCategory: "Selektearje asjebleaft produktkategory",
    productCostPrice: "Produkt Kosten Priis",
    productSellingPrice: "Produkt ferkeappriis",
    productOriginalPrice: "Produkt Oarspronklike priis",
    maxFileSizeAllowedIs4Mb: "Max. Bestânsgrutte tastien is 4mb",
    productsWithPicturesArePublished:
      "Produkten mei foto's wurde publisearre op Discover om oarders te ûntfangen",
    shouldThisProductBeTracked: "Moat dit produkt wurde folge?",
    pleaseSelectStockUnit: "Selektearje asjebleaft stock ienheid",
    stockUnit: "Stock Unit",
    bag: "PÛDE",
    bottle: "FLESSE",
    bunch: "BUNCH",
    can: "KINNE",
    carton: "CARTON",
    crate: "KRATE",
    cup: "KOP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKE",
    pair: "PEAR",
    pieces: "PIECES",
    plate: "PLAAT",
    tonne: "TONNE (MT)",
    uNIT: "IENHEID",
    yard: "HIEM",
    pleaseEnterProductQuantity: "Fier produkt kwantiteit yn",
    productQuantity: "Produkt Quantity",
    isThisProductTaxed: "Is dit produkt belêste?",
    selectTaxType: "Selektearje Belestingtype",
    pleaseSelectTaxType: "Selektearje asjebleaft Belestingtype",
    progressive: "Progressyf",
    proportional: "Proportioneel",
    pleaseEnterProductTaxRate: "Fier produkttarief yn",
    doesProductHaveVariants: "Hat dit produkt farianten?",
    type: "Type",
    value: "Wearde",
    pleaseEnterVariantType: "Fier it type fariant yn",
    pleaseEnterVariantValue: "Fier de wearde fan de fariant yn",
    pleaseEnterVariantPrice: "Fier de priis fan de fariant yn",
    pleaseEnterVariantQuantity: "Fier de kwantiteit fan de fariant yn",
    productDeletedSuccessfully: "Produkt is mei súkses wiske!",
    categoryDeletedSuccessfully: "Kategory is mei súkses wiske!",
    toDeleteThisProduct: "dit produkt te wiskjen",
    invalidProductQuantity: "Unjildige produkthoeveelheid",
    quantityAddedIsOverStock:
      "De kwantiteit dy't jo tafoegje is mear dan wat jo op foarried hawwe.",
    itemInventoryNotTracked: "Itemynventarisaasje wurdt net folge",
    addBulkQuantity: "Bulk kwantiteit tafoegje",
    enterBulkQuantity: "Fier Bulk Quantity yn",
    pleaseEnterBulkQuantity: "Fier asjebleaft Bulkhoeveelheid yn",
    youveSuccessfullyAddedANewProduct:
      "Jo hawwe mei súkses in nij produkt tafoege!",
    pleaseEnterProductSellingPrice: "Fier produktferkooppriis yn",
    doYouWantToTrackProductStock: "Wolle jo produktfoarried folgje?",
    sellingPrice: "Ferkeappriis",
    setProductExpiryReminder: "Stel herinnering foar produktferfal yn",
    productExpiryDate: "Produkt Eindatum",
    startRemindingFrom: "Begjin herinnerje oan",
    productSuppliesAddedSuccessfully: "Jo hawwe mei súkses produkten levere.",
    addProductSupplies: "Produktfoarsjennings tafoegje",
    pleaseSelectSupplier: "Selektearje asjebleaft leveransier",
    nameOfProduct: "Produkt Namme",
    pleaseSelectProduct: "Selektearje asjebleaft produkt",
    productVariant: "Produkt Fariant",
    pleaseSelectAVariant: "Selektearje asjebleaft in fariant",
    pleaseEnterUnitPrice: "Fier asjebleaft ienheidspriis yn",
    businessBranch: "Business Branch",
    pleaseSelectBranch: "Selektearje asjebleaft branch",
    youveSuccessfullyAddedANewSupplier:
      "Jo hawwe mei súkses in nije leveransier tafoege",
    addSupplier: "Add Supplier",
    pleaseEnterSupplierEmail: "Fier asjebleaft de e -post fan leveransier yn",
    pleaseAddADescription: "Foegje asjebleaft in beskriuwing ta",
    anErrorOccuredProductsDeleted:
      "Der is in flater bard by it útfieren fan de operaasje. Hâld der rekken mei, d'r binne miskien net folle produkten wiske yn it proses",
    bulkDelete: "Bulk wiskje",
    youAreAboutToDelete: "Jo steane op it punt om te wiskjen",
    product: "Produkt",
    isDueToRedeem: "is te tankjen oan ferlossing",
    aReward: "in beleanning",
    pleaseSelectCustomerToReeemReward:
      "Selektearje asjebleaft in klant om beleanning te ferlossen.",
    youHaveNoLoyaltyProgramRunning: "Jo hawwe gjin aktyf loyaliteitsprogramma",
    customerHhasNoPointsInLoyaltyProgram:
      "Klant hat gjin punten yn dit loyaliteitsprogramma",
    proceedWithPayment: "Trochgean mei beteljen?",
    youAreAboutToPayForTransactionUsingPoints:
      "Jo steane op it punt om te beteljen foar de transaksje mei punten.",
    customerHas: "Klant hat",
    worth: "wearde",
    andIsNotSufficientToPayForTransaction:
      "en is net genôch om te beteljen foar dizze transaksje. Soene se it saldo wolle tafoegje mei in oare betelmethode?",
    addCustomerLoyalty: "Foegje klantloyaliteit ta",
    pleaseAddCustomerFirst:
      "Foegje asjebleaft earst in klant ta as selektearje.",
    pleaseWaitWhileWeGetReady: "Wachtsje asjebleaft, wylst wy klear binne",
    lowStock: "Lege foarried",
    pleaseEnterAmountTendered: "Fier it oanbeane bedrach yn",
    areYouSureToBookSaleOffline:
      "Binne jo wis dat jo dizze ferkeap offline wolle boeke?",
    saleWouldBeBookedAutomatically:
      "Ferkeap soe automatysk wurde boekt as jo jo ynternet ynskeakelje",
    offlineSalesBookingCancelled: "Offline ferkeapboeking annulearre",
    covid19Message:
      "COVID19: Waskje jo hannen mei sjippe of Sanitize om de fersprieding te stopjen. Bliuw altyd feilich",
    saleSuccessfullyRecorded: "Ferkeap suksesfol opnommen!",
    sendReceiptToEmail: "Stjoer ûntfangst nei e -post",
    sendThankYouSms: "Stjoer tank SMS",
    sendShippingDetails: "Stjoer ferstjoergegevens",
    addLoyalty: "Foegje loyaliteit ta",
    searchCustomerNameOrNumber: "Sykje klantnamme as nûmer",
    clickTheSearchCustomerBox: "Klikje op de fakje Sykje klant en scankaart",
    enterProductPrice: "Fier Produktpriis yn",
    enterPriceFor: "Priis ynfiere foar",
    searchForProduct: "Sykje nei produkt",
    all: "Alle",
    backToDashboard: "Werom nei Dashboard",
    viewDraftSales: "Besjoch konseptferkeap",
    variantSelected: "fariant selektearre",
    variant: "fariant",
    thePreviousVariantSelectionNotAvailable:
      "De foarige fariant seleksje is net beskikber foar de nije fariant selekteare op basis fan priis, feroarje asjebleaft jo seleksje.",
    pleaseSupplyPositiveQuantityNumber:
      "Jou asjebleaft in postyf kwantiteitsnûmer",
    lowStockFor: "Leech stock foar",
    clearVariants: "Dúdlike farianten",
    pleaseEnterQuantity: "Fier asjebleaft Quantity yn",
    picture: "Picture",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Totaal bestege",
    confirmPayment: "Befêstigje betelling",
    hasPaymentBeenMade: "Is de betelling mei súkses ferwurke?",
    enterTransactionReference:
      "Fier de transaksje referinsje yn wêr jo mei hawwe betelle",
    pleaseSelectACustomer: "Selektearje asjebleaft in klant!",
    areYouSureToApplyDiscount: "Binne jo wis dat jo koarting wolle oanfreegje?",
    addYourBankAccountToEnableUssd:
      "Foegje jo bankrekken ta om Instant USSD -oerdracht troch uPay yn te skeakeljen",
    totalAmountToPay: "Totaal bedrach te beteljen",
    doYouWantToCancelTransaction: "Wolle jo dizze transaksje annulearje?",
    savePrintBill: "Bewarje/ôfdrukke Bill",
    enterAmountCollectedForCustomer:
      "Fier bedrach yn dat is sammele foar klant",
    recordSale: "Record Sale",
    checkOutWith: "Kontrolearje mei",
    instantTransfer: "Instant Transfer",
    dialTheUSSDCode: "Kies de USSD -koade",
    pleaseSelectABank: "Selektearje asjebleaft in bank",
    payWithUSSD: "Betelje mei USSD",
    sendBillTo: " - Stjoer Bill nei",
    waitingForUSSDTransfer: "Wachtsje op USSD -oerdracht",
    percent: "Persint",
    applyDiscount: "Koarting tapasse",
    thisBillHasBeenSaved: "Dizze rekken is bewarre",
    saveDraft: "Konsept bewarje",
    pleaseTypeANameToIdentifyCustomer:
      "Typ asjebleaft in namme yn om de klant te identifisearjen",
    paymentDetails: "Betellingsdetails",
    basePrice: "Basis Priis",
    staff: "Personiel",
    subTotal: "SubTotaal",
    durationMonths: "Duration (moannen)",
    selectADuration: "Selektearje in Duration",
    oneMonth: "1 moanne",
    twoMonths: "2 moanne",
    threeMonths: "3 moanne",
    sixMonths: "6 Moannen",
    twelveMonths: "12 moanne",
    eighteenMonths: "18 Moannen",
    twentyFourMonths: "24 Moannen",
    twoMonthsFree: "(2 moannen fergees)",
    threeMonthsFree: "(3 moannen fergees)",
    fiveMonthsFree: "(5 moannen fergees)",
    yourAccountHasBeen: "Jo akkount hat west",
    renewed: "fernijd",
    upgraded: "opwurdearre",
    successfully: "mei súkses",
    yourSubscription: "Jo abonnemint",
    youAreCurrentlyEnrolledOnA: "Jo binne op it stuit ynskreaun op in",
    pleaseChooseAPaymentOption: "Kies asjebleaft in betellingsopsje",
    planRenewal: "Plan fernijing",
    planUpgrade: "Plan Upgrade",
    pleaseSelectDurationToPayFor:
      "Selektearje asjebleaft Duration wêr jo foar wolle betelje",
    staffAccounts: "Staff Accounts",
    ecommerce: "E -commerce",
    pleaseSelectAPlan: "Selektearje asjebleaft in plan",
    includeAddons: "Add-ons opnimme",
    viewTransactions: "Besjoch transaksjes",
    customerHasNoCompletedTansactions:
      "Klant hat noch gjin foltôge transaksjes",
    branch: "Tûke",
    enterNumberOfEcommerceBranches: "Fier oantal e -commerce tûken yn",
    enterNumberOfEcommerceBranchesToPay:
      "Fier it oantal e -commerce -tûken yn wêrfoar jo fan doel binne te beteljen",
    ecommerceIntegration: "E -commerce yntegraasje",
    enterNumberOfBranches: "Fier oantal tûken yn",
    enterNumberOfAdditionalBranchesToPay:
      "Fier it oantal oanfoljende tûken yn wêrfoar jo fan doel binne te beteljen",
    enterNumberOfStaffs: "Fier it oantal meiwurkers yn",
    enterNumberOfStaffsToPayFor:
      "Fier it oantal personiel yn wêrfoar jo fan doel binne te beteljen",
    discountApplies: "Koarting jildt",
    starsOnThe: "stjerren op 'e",
    offer: "oanbod",
    get: "Krije",
    moreStarsToRedeem: "mear stjerren om te ferlossen",
    taxVat: "Belesting (BTW)",
    callCashierToCompletePayment:
      "Rop de kassa oan om de betelling te foltôgjen",
    receipt: "Untfangst",
    dear: "Dear",
    thankYouForYourOrderFindGoods:
      "Tank foar jo bestelling. Fyn asjebleaft de folgjende levere guod, lykas ôfpraat.",
    shippingNote: "Shipping Notysje",
    enterShippingNote: "Fier ferstjoeringsnota yn",
    shippingAddress: "Ferstjoeradres",
    enterShippingAddress: "Fier ferstjoeringsadres yn",
    wellDoneYouAreDueToRedeem: "Goed dien! Jo moatte betelje",
    toGetYourRewardNextVisit:
      "om jo beleanning te krijen by jo folgjende besyk. Dankewol",
    pointsOnThe: "Punten op de",
    morePointsToRedeem: "mear punten om te ferlossen",
    showCode: "Koade sjen litte",
    toGetYourRewardOnNextVisit:
      "om jo beleanning te krijen by jo folgjende besyk. Dankewol",
    earn: "Fertsjinje",
    delivaryNote: "Delivary Notysje",
    draftSales: "Konseptferkeap",
    startDate: "Begjin Datum",
    endDate: "Eindatum",
    orders: "Oarders",
    checkout: "betelje",
    noProductItem: "Gjin produktartikel",
    selectProductImage: "Selektearje produktôfbylding",
    selectCountry: "Selektearje lân",
    selectRegion: "Selektearje steat/regio",
    printProductTag: "Print produkttag",
    transactionReference: "Transaksje referinsje",
    Cashier: "Kassier",
    Manager: "Behearder",
    Owner: "Eigner",
    Admin: "Admin",
    addPartners: "Add Partners",
    addNewLoyaltyPartner: "Add Nije Loyalty Partner",
    pleaseEnterCompanyName: "Fier asjebleaft bedriuwsnamme yn",
    companyName: "Bedriuwsnamme",
    pleaseEnterCompanyRepName:
      "Fier asjebleaft bedriuwsfertsjintwurdiger Namme yn",
    companyRepName: "Namme fan Company fertsjintwurdiger",
    pleaseEnterCompanyRepEmail:
      "Fier asjebleaft e-post fan bedriuwsfertsjintwurdiger yn",
    companyRepEmail: "E-post fan bedriuw rep",
    pleaseEnterCompanyRepPhone:
      "Fier asjebleaft telefoannûmer fan bedriuwsfertsjintwurdiger yn",
    companyRepPhone: "Telefoannûmer fan bedriuw rep",
    addReward: "Add beleanning",
    pleaseEnterRewardName: "Fier asjebleaft beleanningsnamme yn",
    rewardName: "Reward Namme",
    rewardQuantity: "Belonning Quantity",
    rewardDescription: "Beleanning Beskriuwing",
    rewardType: "Belonningstype",
    pleaseEnterRewardType: "Fier asjebleaft beleanningstype yn",
    pleaseEnterRewardQuantity: "Fier asjebleaft beleanningskwantiteit yn",
    pleaseEnterRewardDescription: "Fier asjebleaft beleanningsbeskriuwing yn",
    fineDining: "Fine Dining",
    luxuryFashion: "Luxe Fashion",
    hotels: "Hotels",
    travel: "Reizgje",
    foodAndBeverage: "Iten en drinken",
    fashion: "Moade",
    health: "Sûnens",
    furniture: "Meubilêr",
    entertainment: "Entertainment",
    automobile: "Automobile",
    education: "Oplieding",
    beautyAndSpa: "Beauty en Spa",
    staycation: "Staycation",
    events: "Eveneminten",
    trips: "Trips",
    oilAndGas: "Oalje en gas",
    laundry: "Wask",
    partnerCategory: "Partner Category",
    freeItem: "Fergees artikel",
  },
  Galician: {
    cashier: "caixeiro",
    manager: "xerente",
    owner: "propietario",
    online: "en liña",
    offline: "sen conexión",
    changePassword: "Cambie o contrasinal",
    currentPasswordMessage: "Introduza o seu contrasinal actual",
    passwordMessage: "Introduza o seu contrasinal",
    currentPassword: "Contrasinal actual",
    password: "contrasinal",
    confirmPasswordMessage: "Confirma o teu contrasinal.",
    confirmPassword: "Confirme o contrasinal",
    sendViaEmail: "Enviar vía correo electrónico",
    sendViaWhatsapp: "Enviar vía WhatsApp",
    downloadPdf: "Descargar PDF",
    paid: "pagado",
    unpaid: "sen pagar",
    partial: "parcial",
    closeInvoice: "¿Queres pechar a factura?",
    closeInvoiceConfirmation:
      "É posible que non se garde a factura. ¿Queres pechar?",
    yes: "si",
    no: "non",
    invoice: "Factura",
    wasDeducted: "descontouse",
    for: "para",
    item: "Elemento",
    addProduct: "Engadir produto",
    paymentReference: "Referencia de pagamento",
    amountPaid: "Importe pagado",
    discountAmount: "Importe de desconto",
    amountDue: "Cantidade debida",
    amount: "Cantidade",
    dueDate: "Data de caducidade",
    paymentType: "Tipo de pagamento",
    card: "Tarxeta",
    cash: "Efectivo",
    bankTransfer: "Transferencia bancaria",
    paymentMessage: "Mensaxe de pagamento",
    description: "Descrición",
    sendReceipt: "Enviar xustificante",
    delete: "Eliminar",
    save: "Gardar",
    resend: "Volver enviar",
    saveAndSend: "Gardar",
    invoiceSaved: "Factura gardada.",
    selectPaymentMethod: "Selecciona un método de pago.",
    selectCustomer: "Selecciona un cliente.",
    cartEmptyError:
      "A lista da cesta non pode estar baleira, pecha a factura e engade o artigo á cesta.",
    subscriptionExpired:
      "A túa subscrición caducou e a túa conta agora está limitada. Fai clic no botón de abaixo para renovar a túa conta",
    renew: "Renovar",
    holdOn: "Agarda",
    customerBank: "Banco de clientes",
    cancel: "Cancelar",
    selectACustomer: "Selecciona cliente",
    invoiceSuccessfulPdfError:
      "A factura creouse correctamente, pero a xeración de PDF leva máis tempo do habitual. Volve comprobar en breve.",
    downloadingInvoice: "Descargando factura",
    downloadingReceipt: "Descargando xustificante",
    whatsappReceiptError:
      "Produciuse un erro ao enviar o recibo por WhatsApp. Téntao de novo.",
    receiptToWhatsapp: "Envío de recibo a WhatsApp",
    thankYouForPatronage: "Grazas polo teu patrocinio",
    hereIsYourReceipt: "Aquí tes o recibo de pagamento",
    errorSendingEmailReceipt:
      "Produciuse un erro ao enviar o recibo por correo electrónico. Téntao de novo ou ponte en contacto co servizo de asistencia",
    receiptSentToEmail: "Enviouse o recibo ao correo electrónico do cliente",
    invoiceSentToEmail: "A factura enviouse ao correo electrónico do cliente",
    invoiceSuccessWhatsappError:
      "A factura creouse correctamente, pero produciuse un erro ao enviar a WhatsApp. Téntao de novo na lista de facturas",
    invoiceSuccessfulEmailError:
      "A factura creouse correctamente, pero produciuse un erro ao enviar como correo electrónico. Téntao de novo na lista de facturas",
    invoiceSentToWhatsapp: "Factura reenviada a WhatsApp",
    hello: "Ola",
    pleaseDownloadInvoice: "Descarga a factura",
    pleaseDownloadReceipt: "Descarga o recibo",
    from: "desde",
    email: "Correo electrónico",
    upgrade: "Actualización",
    youAreOnFreePlan: "Estás nun plan gratuíto.",
    clickOn: "Prema en",
    yourPlanInFewSteps: " a súa subscrición nalgúns pasos rápidos.",
    to: "a",
    yourSubscriptionHasExpired:
      "A túa subscrición caducou e a túa conta agora está limitada.",
    days: "días",
    yourSubscriptionExpiresIn: "A túa subscrición a Loystar caduca en",
    createAcount: "Crear unha conta",
    signIn: "Rexístrate",
    continue: "Continuar",
    enterOtp: "Introduza o PIN OTP",
    enterValidOtp: "Insire un PIN válido",
    pin: "PIN",
    tokenSentToMail: "Enviouse un token ao teu correo electrónico",
    passwordResetSuccessful: "O restablecemento do contrasinal foi correcto",
    somethingWentWrong: "Algo fallou.",
    resetPassword: "Restablecer contrasinal",
    iHaveResetCode: "Teño un código de restablecemento de contrasinal",
    pleaseEnterEmail: "Insira o seu correo electrónico",
    aTokenWillBeSentToEmail: "Enviaríase un token ao teu correo electrónico",
    enterEmail: "Introduce o teu correo electrónico",
    sendinYourToken: "Enviando o teu token ...",
    makeSureItMatchesPassword:
      "Asegúrate de que coincida co teu novo contrasinal",
    pleaseChooseNewPassword: "Escolla un novo contrasinal",
    chooseNewPassword: "Escolla un novo contrasinal",
    enterNewPassword: "Insira o seu novo contrasinal para confirmalo",
    enterTokenSent: "Introduce o token ao que se enviou o teu correo",
    resetToken: "Restablecer token",
    resettingPassword: "Restablecendo o seu contrasinal ...",
    signUp: "Rexistrarse",
    adminSignInWithEmail:
      " O administrador inicia sesión con correo electrónico mentres que o persoal inicia sesión co nome de usuario.",
    pleaseEnterEmailOrUsername:
      "Introduza o seu correo electrónico ou nome de usuario",
    emailOrUsername: "Correo electrónico ou nome de usuario",
    pleaseEnterPassword: "Introduza o contrasinal",
    createAnAccount: "Crear unha conta",
    forgotPassword: "Esqueceu o contrasinal?",
    enterPhoneNumber: "Introduza o número de teléfono",
    personalData: "Datos persoais",
    validateConfirmationCOde: "Valida o código de confirmación",
    pleaseEnterFirstName: "Introduza o seu nome",
    pleaseEnterPhoneNumber: "Insira o seu número de teléfono",
    pleaseEnterLastName: "Introduza o seu apelido",
    pleaseEnterBusinessName: "Introduce o nome da túa empresa",
    firstName: "Nome",
    lastName: "Apelido",
    businessName: "Nome da empresa",
    previous: "Anterior",
    next: "A continuación",
    pleaseSelectBusinessCategory: "Selecciona a túa categoría de empresa",
    pleaseEnterValidEmail: "Insire un correo electrónico válido",
    pleaseEnterPostCode: "Introduza o código postal",
    postCode: "Código postal",
    phoneNumberInUse: "Este número de teléfono xa está en uso.",
    emailInUse: "Este correo electrónico xa está en uso.",
    foodAndDrinks: "Comida e bebida",
    salonAndBeauty: "Salón e beleza",
    fashionAndAccessories: "Moda e Complementos",
    gymAndFitness: "Ximnasio e fitness",
    travelAndHotel: "Viaxes e hotel",
    homeAndGifts: "Fogar e agasallos",
    washingAndCleaning: "Lavado e limpeza",
    gadgetsAndElectronics: "Gadgets e electrónica",
    groceries: "Mercado",
    others: "Outros",
    submit: "Enviar",
    accountCreatedSuccessful: "A túa conta creouse correctamente.",
    pleaseEnterAddress: "Introduza o seu enderezo",
    addressLine1: "Enderezo Liña 1",
    addressLine2: "Enderezo Liña 2",
    choosePassword: "Escolla un contrasinal",
    passwordMustBe6Characters:
      "O contrasinal debe ter como mínimo 6 caracteres.",
    howDidYouHearLoystar: "Como souches de Loystar?",
    referralCode: "Código de referencia",
    byClickingTheButton: " Ao facer clic no botón seguinte, aceptas o",
    termsAndSevice: "Condicións",
    wereCreatingAccount: "Estamos creando a túa conta",
    proceed: "Continuar",
    verificationCodeMustBe6: "O código de verificación debe ter 6 díxitos",
    pleaseEnter6DigitCode: "Introduza un código de 6 díxitos",
    enterVerificationCode: "Introduza o código de verificación",
    resendToken: "Reenviar token",
    verify: "Verificar",
    transactions: "Transaccións",
    todaySales: "As vendas de hoxe",
    salesHistory: "Historial de vendas",
    supplyHistory: "Historial de subministracións",
    new: "Novidade",
    invoices: "Facturas",
    disbursements: "Desembolsos",
    offlineSales: "Vendas sen conexión",
    products: "Produtos",
    customers: "Clientes",
    multiLevelLoyalty: "Lealdade a varios niveis",
    loyaltyPrograms: "Programas de fidelización",
    members: "Membros",
    appStore: "Tenda de aplicacións",
    orderMenu: "Menú de pedido",
    settings: "Configuración",
    staffAndBranches: "Persoal e sucursais",
    myAccount: "A miña conta",
    switchToSellMode: "Cambia ao modo de venda",
    signOut: "Pechar sesión",
    getFreeSubscription: "Obtén subscrición gratuíta",
    onlyNumbersAllowed: "Só se permiten números",
    yourAccountMustBe10Digits:
      "o seu número de conta debe ser un número de 10 díxitos",
    yourBvnMustBe11: "o seu BVN debe ser un número de 11 díxitos",
    pleaseSelectBank: "Selecciona o teu banco",
    selectYourBank: "Selecciona o teu banco",
    enterBankAccountNumber: "Introduza o número de conta bancaria",
    enterBvnNumber: "Introduce o teu BVN",
    connectBankAccount: "Conectar conta bancaria",
    passwordResetSuccessfulAndSignOut:
      "O seu contrasinal restableceuse correctamente. Fai clic no botón Pechar sesión para iniciar sesión de novo",
    enterCurrentPassword: "Introduza o contrasinal actual",
    pleaseEnterCurrentPassword: "Introduza o contrasinal actual",
    phoneNumber: "Número de teléfono",
    sex: "Sexo",
    dateOfBirth: "Data de nacemento",
    state: "Estado",
    country: "País",
    loyaltyId: "ID de fidelidade",
    createdAt: "Creado en",
    noOfTransactionsMade: "No de transaccións realizadas",
    yourDownloadWillStart: "A descarga comezará nun momento",
    exportCustomers: "Exportar clientes",
    youhaveSuccessfullyToppedUp:
      "Completaches con éxito as túas unidades de SMS.",
    youNowHave: "Agora tes",
    smsUnits: "Unidades SMS",
    enterNumberOfSmsUnits:
      "Introduza o número de unidades SMS que pretende mercar",
    pleaseEnterANumericValue: "Introduza un valor numérico",
    pay: "Pagar",
    accountEditedSuccessfully: "A túa conta editouse correctamente.",
    youAeCurrentlyOn: "Actualmente estás activado",
    plan: "Plan",
    userData: "Datos de usuario",
    businessData: "DATOS EMPRESARIAIS",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Selecciona a túa moeda",
    selectYourCurrency: "Selecciona a túa moeda",
    purchaseMoreSmsUnits:
      "Compra máis unidades SMS usando o seguinte formulario",
    youHave: "Tes",
    atLeast4SmsUnitsRrequired:
      "Para a verificación son necesarios polo menos 4 unidades de SMS.",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verifique a súa conta bancaria para cumprir cos requisitos de "Coñece ao seu cliente" (KYC). Isto permitiralle recoller pagos a través de USSD ou transferencia instantánea, recibir pedidos de clientes e procesar a liquidación de transaccións. Aplicable só aos comerciantes nixerianos. Preme o botón de abaixo para comezar.',
    reConnectBankAccount: "Volve conectar a conta bancaria",
    accountName: "Nome da conta",
    accountNumber: "Número de conta",
    bankName: "Nome do banco",
    verified: "Verificado",
    accountDetails: "Detalles da conta",
    kycBankAccount: "KYC",
    createTier: "Crear nivel",
    fileUploadSuccessful: "ficheiro cargado con éxito",
    fileUploadFailed: "fallou a carga do ficheiro",
    createLoyaltyProgram: "Crea un programa de fidelización",
    createLoyalty: "Crear lealdade",
    name: "Nome",
    loyaltyArtwork: "Obra de arte de fidelidade",
    clickToUpload: "Fai clic para cargar",
    amountToPointsRatio: "Relación entre cantidade e puntos",
    points: "Puntos",
    recommendedAmountToPointRatio:
      "Recomendado: ₦ 1 a 1 punto. é dicir, por cada 1 Naira gastado, os teus clientes obteñen 1 punto",
    pleaseTypeIn: "Escribe por favor",
    toDeleteLoyalty: "para eliminar esta lealdade",
    deleteLoyalty: "Eliminar fidelidade",
    toConfirm: "para confirmar",
    edit: "Editar",
    pointsAwardedSuccessfully: "Puntos outorgados con éxito.",
    enterPointValueToAward:
      "Introduce o valor do punto que che gustaría conceder aos clientes",
    award: "Premio",
    awardPointValuesToCustomer: "Valor do punto de concesión ao cliente",
    enrollMembersToLoyalty: "Inscribir membros en Lealtad",
    awardPoints: "Puntos de concesión",
    enroll: "Inscríbete",
    home: "Casa",
    loyalty: "Lealdade",
    enrollCustomers: "Inscribir clientes",
    selected: "Seleccionado",
    customer: "Cliente",
    loyaltyActivationSuccessful: "A activación da fidelidade ten éxito.",
    loyaltyDeactivationSuccessful: "A desactivación da fidelidade ten éxito.",
    viewTier: "Ver nivel",
    deactivate: "Desactiva",
    activate: "Activar",
    actions: "Accións",
    nameOfLoyalty: "Nome da Lealdade",
    loyaltyStatus: "Estado de fidelidade Estado de fidelidade",
    numberOfTiers: "Número de niveis",
    createdOn: "Creado o",
    createATier: "Crea un nivel",
    stopCreatingTierConfirmation: "Queres deixar de crear un nivel?",
    stopEditingTierConfirmation: "Queres deixar de editar este nivel?",
    nameOfTier: "Nome do nivel",
    minimumSpendingTarget: "Obxectivo mínimo de gasto",
    maximumSpendingTarget: "Obxectivo máximo de gasto",
    minimumSpendingTargetRequired: "precísase o obxectivo de gasto mínimo",
    maximumSpendingTargetRequired: "precísase o obxectivo máximo de gasto",
    rewardSponsor: "Patrocinador de recompensas",
    pleaseChooseARewardSponsor: "Elixe un patrocinador de recompensa",
    self: "Eu",
    partner: "Socio",
    rewardPartner: "Socio de recompensa",
    pleaseSelectRewardPartner: "Selecciona o teu compañeiro de recompensa",
    rewards: "Recompensas",
    pleaseSelectAReward: "Selecciona unha recompensa",
    instructionsOnRedeemingReward:
      "Instrucións sobre como o cliente debe trocar a recompensa",
    pleaseFillInThisField: "Encha este campo.",
    toDeleteThisTier: " para eliminar este nivel",
    deleteTier: "Eliminar nivel",
    viewMembers: "Ver membros",
    membersEnrolled: "Membros inscritos",
    instruction: "Instrución",
    membersIn: "Membros en",
    availableTiersInLoyalty: "Niveles dispoñibles no programa de fidelización",
    tiers: "Niveles",
    totalTier: "NIVEL TOTAL",
    availableLoyaltyProgramme: "Programa de fidelización dispoñible",
    totalLoyalties: "TOTAL LLEALIDADES",
    memberDetails: "Detalles do membro",
    nameOfCustomer: "Nome do cliente",
    address: "Enderezo",
    allEnrolledMembers: "Todos os membros inscritos",
    thisIsToWishYouHappyBirthday:
      "Isto é para desexarche un feliz aniversario e unha vida próspera. Grazas por todo o que es para Loyster. Felices Festas!",
    inputAnOfferPlease: "Introduce unha oferta por favor",
    pleaseSelectTheInsertPoint:
      "Seleccione o punto de inserción na mensaxe e faga clic de novo",
    birthdayOfferAndMessage: "Oferta e mensaxe de aniversario",
    birthdayOffer: "Oferta de aniversario",
    birthdayMessage: "Mensaxe de aniversario",
    noOfferFound: "Non se atopou ningunha oferta",
    settingABirthdayOffer:
      "Establecer unha oferta de aniversario permite aos clientes recibir esta oferta por SMS o día do seu aniversario",
    createOffer: "Crear oferta",
    whatIsTheOffer: "Cal é a oferta?",
    editMessage: "Editar mensaxe",
    insert: "Inserir",
    theNameOfCustomerInserted: "Aquí inserirase o nome do cliente",
    theBirtdayOfferInserted: "A oferta de aniversario inserirase aquí",
    youSuccessfullyAddedNewBranch: "Engadiches con éxito unha nova sucursal.",
    addNewBranch: "Engadir nova sucursal",
    addBranch: "Engadir sucursal",
    additionalBranchWillIncur: "Incorrería unha rama adicional",
    perBranch: "por rama",
    ecommerceBranchCosts: "Custos da sucursal de comercio electrónico",
    pleaseEnterBranchName: "Introduza o nome da sucursal",
    pleaseEnterBranchAddress1: "Insira a liña de enderezo 1 da sucursal",
    enterBranchAddress1: "Introduza a liña de enderezo 1 da sucursal",
    enterBranchAddress2: "Introduza a liña de enderezo 1 da sucursal",
    pleaseEnterBranchAddress2: "Introduza a liña 2 do enderezo da sucursal",
    enterBranchName: "Introduza o nome da sucursal",
    successfullyAddedStaff: "Engadiches con éxito un novo persoal.",
    addNewStaff: "Engadir novo persoal",
    addStaff: "Engadir persoal",
    additionalStaffWillIncur: "Incorrería persoal adicional",
    perStaff: "por persoal.",
    pleaseEnterStaffEmail: "Introduza o correo electrónico do persoal",
    pleaseEnterStaffUsername: "Introduza o nome de usuario do persoal",
    pleaseEnterStaffPassword: "Introduza o contrasinal do persoal",
    pleaseSelectStaffRole: "Selecciona a función do persoal",
    selectStaffRole: "Selecciona a función do persoal",
    enterStaffEmail: "Introduce o correo electrónico do persoal",
    enterStaffUsername: "Introduce o nome de usuario do persoal",
    enterStaffPassword: "Introduza o contrasinal do persoal",
    spacesNotAllowedInUsername: "espazos non permitidos no nome de usuario",
    admin: "Administrador",
    pleaseSelectBusinessToAttachStaff:
      "Selecciona empresa para engadir persoal",
    searchForBranchToAttachStaff: "Busca sucursal para adscribir persoal",
    username: "Nome de usuario",
    role: "Función",
    areYouSureToDeleteThis: "Está seguro de eliminar isto",
    branches: "Pólas",
    upgradeYourPlan: "Actualiza o teu plan.",
    add: "Engadir",
    addNew: "Engadir novo",
    customerWithEmailAlreadyExists:
      "Xa existe un cliente con correo electrónico / número de teléfono.",
    successfullyAddedNewCustomer: "Engadiches con éxito un novo cliente.",
    addCustomer: "Engadir cliente",
    pleaseEnterCustomerFirstName: "Introduza o nome do cliente",
    pleaseEnterCustomerLastName: "Introduza o apelido do cliente",
    pleaseEnterCustomerPhoneNumber: "Introduza o número de teléfono do cliente",
    pleaseEnterCustomerEmail: "Introduza o correo electrónico do cliente",
    pleaseEnterCustomerAddressLine: "Introduza a liña de enderezo do cliente",
    pleaseEnterCustomerOtherAddress: "Introduza o outro enderezo do cliente",
    pleaseSelectCustomerGender: "Selecciona o sexo do cliente",
    gender: "Xénero",
    male: "Masculino",
    female: "Muller",
    bank: "Banco",
    selectBank: "Seleccione Banco",
    stateOrRegionOrProvince: "Estado / Rexión / Provincia",
    customerNotes: "Notas do cliente",
    sendSms: "Enviar SMS",
    editCustomer: "Editar cliente",
    redeemReward: "Canjear recompensa",
    issueLoyaltyCard: "Emitir tarxeta de fidelidade",
    deleteCustomer: "Eliminar cliente",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Asignou correctamente un ID de membro de fidelidade",
    noMembershipIdAvailable:
      "Non hai identificacións de membros dispoñibles. Póñase en contacto connosco en hello@loystar.co",
    sendEmail: "Enviar correo electrónico",
    membershipPoints: "Puntos de adhesión",
    customerDetails: "Datos do cliente",
    close: "Pechar",
    loyaltyBalance: "Saldo de fidelidade",
    pointsBalance: "Balance de puntos",
    starBalance: "Balance de estrelas",
    requiredPoints: "Puntos requiridos",
    requiredStars: "Estrelas requiridas",
    reddemCode: "Canjear código",
    toDeleteThisCustomer: "para eliminar este cliente",
    customerHasBeenDeletedSuccessfully: "O cliente eliminouse correctamente.",
    customerWithPhoneAlreadyExists:
      "O cliente con número de teléfono xa existe",
    customerWasSuccessfullyEdited: "O cliente editouse correctamente",
    anErrorOccured: "Produciuse un erro",
    phoneNumberIncludeCountry: "Número de teléfono (incluír o código do país)",
    yourFileQueuedForUpload:
      "O teu ficheiro colouse na cola para cargar. Actualiza a páxina despois duns segundos.",
    thereWasAnErrorPerformingOperation:
      "Produciuse un erro ao realizar a operación.",
    pleaseSelectFile: "Selecciona un ficheiro.",
    oopsOnlyCsvFilesAllowed:
      "Vaia! Só se permiten ficheiros CSV. Carga un ficheiro .csv.",
    fileShouldBeSmallerThan5Mb:
      "O ficheiro debe ser inferior a 5 MB. Se tes un ficheiro máis grande, envía un correo electrónico a support@loystar.co. Grazas",
    customerFirstNameIsRequired: "o nome do cliente é obrigatorio na fila",
    customerPhoneNumberIsRequired:
      "o número de teléfono do cliente é necesario na fila",
    importCustomers: "Importar clientes",
    upload: "Cargar",
    clickIconToDownloadCsv:
      "Fai clic nesta icona para descargar o modelo do ficheiro CSV.",
    getGoogleSheetVersion: "Obtén a versión de folla de Google",
    clickOrDragFileToUpload:
      "Fai clic ou arrastra o ficheiro a esta área para cargar",
    missingOrInvalidColumnHeader:
      "Falta o encabezado de columna non válido. Siga o formato do modelo. Grazas.",
    youHaveImported: "Importaches",
    youSuccessfullyRedeemedReward: "Redimiu con éxito a súa recompensa.",
    sixDigitCode: "Código de seis díxitos",
    pleaseEnterCustomerRewardCode:
      "Introduza o código de recompensa do cliente",
    enterRewardCode:
      "Introduce o código de recompensa. (O código de recompensa distingue entre maiúsculas e minúsculas)",
    selectLoyaltyProgram: "Selecciona un programa de fidelización",
    stamps: "Selos",
    smsUnitsLow: "Unidades SMS baixas",
    pleaseTopUpSmsUnits: "Recarga unidades SMS",
    smsSuccessfullySentForDelivery: "Enviar SMS con éxito para a súa entrega.",
    sendSmsTo: "Enviar SMS a",
    allCustomers: "Todos os clientes",
    unitsAvailable: "Unidades dispoñibles",
    pleaseTypeInTheMessage: "Escribe a mensaxe",
    message: "Mensaxe",
    charactersRemaining: "caracteres restantes",
    avoidUseOfSpecialCharacters:
      "Evite o uso de caracteres especiais e emojis para conservar as unidades SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Erro ao obter os detalles de varios niveis dos clientes",
    search: "Buscar",
    reset: "Restablecer",
    importCustomer: "Importar cliente",
    addNewCustomer: "Engadir novo cliente",
    sendSmsBroadcast: "Enviar transmisión por SMS",
    totalCustomers: "TOTAL CLIENTES",
    disbursementDetails: "Detalles do desembolso",
    paidBy: "Pagado por",
    disbursed: "Desembolsado",
    bankAccountName: "Nome da conta bancaria",
    bankAccountNumber: "Número de conta bancaria",
    transferInitiated: "Transferencia iniciada",
    transferCompleted: "Transferencia completada",
    pleaseEnterAValid: "Introduza un válido",
    begin: "comezar",
    end: "fin",
    date: "data",
    paymentDate: "Data de pagamento",
    selectDisbursementDuration: "Seleccione Duración do desembolso",
    totalSettled: "Total liquidado",
    totalUnsettled: "Total sen resolver",
    toDeleteThisSale: "para eliminar esta venda",
    draftSaleDeletedSuccessfully:
      "O borrador de venda eliminouse correctamente.",
    deleteSale: "Eliminar venda",
    pleaseTypeInYourTotal: "Escribe o total para confirmar a eliminación",
    billDetails: "Detalles da factura",
    printBill: "Imprimir factura",
    servedBy: "Servido por",
    total: "Total",
    createdDate: "Data de creación",
    createdTime: "Tempo creado",
    status: "Estado",
    loginSuccessful: "Inicio de sesión correcto",
    pleaseWaitWhileWeConnectAccount: "Agarde mentres conectamos a súa conta",
    connectionFailedTryAgain: "Fallou a conexión. Por favor intentao de novo.",
    connectionSuccessful: "Conexión realizada correctamente",
    viewDetails: "Ver detalles",
    enable: "Activar",
    free: "De balde",
    cost: "Custo",
    visitWebsite: "Visita o sitio web",
    pleaseUpgradeYourPlanToPro:
      "Actualiza o teu plan a Pro Plus para habilitar esta aplicación",
    connectYourBankAccount:
      "Conecta a túa conta bancaria para poder recibir pagos.",
    disable: "Desactivar",
    enableApp: "Activar a aplicación",
    addNewProductToInvoice: "Engade novo produto á factura",
    toDeleteThisInvoice: "para eliminar esta factura",
    invoiceDeletedSuccessfully: "A factura eliminouse correctamente.",
    deleteInvoice: "Eliminar factura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Engadiches correctamente unha mensaxe de pago con factura.",
    pleaseEnterPaymentInstructions: "Insira as instrucións de pagamento",
    invoiceId: "ID de factura",
    paidDate: "Data de pago",
    reference: "Referencia",
    productAdded: "Produto engadido",
    productOutOfStock: "Produto sen existencias. Volva a subministrar.",
    totalInvoices: "FACTURAS TOTAIS",
    totalPaidInvoices: "TOTAL DE FACTURAS PAGADAS",
    totalUnpaidInvoices: "TOTAL FACTURAS NON PAGADAS",
    loyaltyProgramDeleted: "Eliminouse o programa de fidelización",
    thisLoyaltyProgramDeletedSuccessfully:
      "Este programa de fidelización eliminouse correctamente",
    thisLoyaltyProgramEditedSuccessfully:
      "Este programa de fidelización editouse con éxito",
    loyaltyProgramAddedSuccessfully:
      "O programa de fidelización engadiuse correctamente",
    loyaltyProgramEdited: "Programa de fidelización editado",
    loyaltyProgramAdded: "Engadiuse o programa de fidelización",
    loyaltyDetails: "Detalles da fidelidade",
    doYouWantToCloseDialog: "Queres pechar este diálogo?",
    pleaseEnterLoyaltyName: "Insira o nome da súa lealdade",
    programType: "Tipo de programa",
    pleaseSelectProgramType: "Seleccione un tipo de programa",
    simplePoints: "Puntos sinxelos",
    stampsProgram: "Programa de selos",
    threshold: "Límite",
    pleaseEnterLoyaltyThreshold: "Insira o limiar de fidelidade",
    reward: "Recompensa",
    pleaseEnterLoyaltyReward: "Introduce a recompensa da lealdade",
    totalUserPoints: "Puntos de usuario totais",
    totalUserStamps: "Selos de usuario totais",
    spendingTarget: "Obxectivo de gasto",
    spendingTargetHint1:
      "O obxectivo de gasto é canto debe gastar un cliente para obter a recompensa. 1 valor de moeda = 1 punto.",
    spendingTargetHint2:
      "Obxectivo de selos é cantos selos debe recoller un cliente para gañar a recompensa. por exemplo. 5",
    addNewLoyaltyProgram: "Engade un novo programa de fidelización",
    addLoyaltyProgram: "Engadir programa de fidelización",
    loyaltyProgramType: "Tipo de programa de fidelización",
    pleaseSelectLoyaltyProgramType:
      "Selecciona o tipo de programa de fidelización",
    nameOfProgram: "Nome do programa",
    pleaseEnterProgramName: "Introduza o nome do programa",
    whatIsTheReward: "Cal é a recompensa?",
    egNextPurchaseIsFree: "Ex. A próxima compra é gratuíta",
    customerName: "Nome do cliente",
    guestCustomer: "Cliente invitado",
    orderConfirmedSuccessfully: "A orde confirmouse correctamente",
    orderCancelledSuccessfully: "A orde cancelouse correctamente",
    confirmOrder: "Confirmar o pedido",
    cancelOrder: "Cancelar pedido",
    allOrders: "Todas as ordes",
    totalOrders: "TOTAL DE ORDES",
    doYouWantToAcceptOrder: "¿Queres aceptar este pedido?",
    doYouWantToCancelOrder: "Queres cancelar este pedido?",
    orderDetails: "Detalles do pedido",
    orderCreatedAt: "Orde creada en",
    supplier: "Provedor",
    productName: "Nome do produto",
    quantity: "Cantidade",
    unitPrice: "Prezo por unidade",
    receivedBy: "Recibido por",
    reportFrom: "Informe de",
    totalSupplies: "Subministracións totais",
    allRightsReserved: "Todos os dereitos reservados",
    toDeleteThisTransaction: "para eliminar esta Transacción",
    transactionDeletedSuccessfully:
      "A transacción eliminouse correctamente. O stock volveuse ao inventario.",
    deleteTransaction: "Eliminar transacción",
    transactionDetails: "Detalles da transacción",
    printReceipt: "Imprimir xustificante",
    channel: "Canle",
    discount: "Desconto",
    profit: "Beneficio",
    discountedSales: "Vendas con desconto",
    errorFetchingRecord: "Erro ao recuperar o rexistro",
    exportTransactions: "Transaccións de exportación",
    errorFetchingSalesRecord:
      "Erro ao obter o rexistro de vendas para a exportación.",
    totalSellingPrice: "Prezo de venda total",
    totalCostPrice: "Prezo de custo total",
    appliedDiscount: "Desconto aplicado",
    noOfItems: "No de elementos",
    sales: "Vendas",
    export: "Exportar",
    totalProfit: "Beneficio total",
    totalBalanceInPeriod: "Saldo total no período",
    allTimeSales: "Todas as vendas",
    records: "Rexistros",
    todaysSales: "As vendas de hoxe",
    transaction: "transacción",
    youHaveExceededTotalNumberOfProducts:
      "Superaches o número total de produtos permitidos no teu plan. Actualiza o teu plan para gozar dun límite superior.",
    youNeedToHaveLoyaltyProgram:
      "Necesitas ter un programa de fidelización para usar esta función.",
    price: "Prezo",
    category: "Categoría",
    stockTracking: "Seguimento de accións",
    stockCount: "Reconto de accións",
    taxed: "Tributado",
    originalPrice: "Prezo orixinal",
    costPrice: "Prezo de custo",
    unit: "Unidade",
    productImage: "Imaxe do produto",
    taxRate: "Tipo impositivo",
    taxType: "Tipo de imposto",
    trackInventory: "Inventario da pista",
    variants: "Variantes",
    hasVariants: "Ten variantes",
    importProduct: "Importar produto",
    exportProducts: "Exportar produtos",
    addNewProduct: "Engadir novo produto",
    viewCategory: "Ver categoría",
    viewSuppliers: "Ver provedores",
    receiveInventory: "Recibir inventario",
    printAllProductsTag: "Imprimir a etiqueta de todos os produtos",
    deleteAll: "Eliminar todo",
    totalProducts: "TOTAL DE PRODUTOS",
    youveSuccessfullyAddedANewCategory:
      "Engadiches con éxito unha nova categoría",
    addNewCategory: "Engadir nova categoría",
    addCategory: "Engadir categoría",
    categoryName: "Nome da categoría",
    pleaseEnterCategoryName: "Introduza o nome da categoría",
    stampsTarget: "Selos branco",
    sendInventory: "Enviar inventario",
    productDetails: "Detalles do produto",
    youveSuccessfullyEditedThisCategory: "Editaches con éxito esta categoría",
    update: "Actualización",
    categoryList: "Lista de categorías",
    categories: "Categorías",
    enterQuantityToUpdate: "Introduce unha cantidade para actualizar",
    inventorySentSuccessfully: "O inventario enviouse correctamente.",
    updateInventory: "Actualizar inventario",
    currentQuantity: "Cantidade actual",
    pleaseEnterQuantityToAdd: "Introduza a cantidade que desexa engadir",
    pleaseSelectABranch: "Selecciona unha sucursal",
    searchForBranch: "Buscar sucursal",
    youCantSendMoreThanStock: "Non podes enviar máis do que tes en stock",
    send: "Enviar",
    pleaseEnterQuantityToSend: "Introduza a cantidade que desexa enviar",
    productNameIsRequiredOnRow: "o nome do produto é obrigatorio na fila",
    productCategoryIsRequiredOnRow:
      "a categoría de produto é necesaria na fila",
    productPriceIsRequiredOnRow: "o prezo do produto é obrigatorio na fila",
    productUnitIsRequiredOnRow: "é necesaria a unidade de produto na fila",
    productQuantityIsRequiredOnRow:
      "a cantidade de produto é necesaria na fila",
    productVariantsRequireTracking:
      "As variantes do produto requiren un seguimento.",
    pleaseAddVariantClickButton:
      "Engade unha variante facendo clic no botón engadir.",
    totalVariantsMoreThanSelectedQuantity:
      "As variantes totais son máis que a cantidade de produto seleccionada. Por favor, reducen a cantidade de variantes",
    productEditedSuccessfully: "O produto editouse correctamente.",
    fileTooLargeLessThan4Mb:
      "O tamaño do ficheiro é demasiado grande. O tamaño do ficheiro debe ser inferior a 4 MB.",
    suchVariantAlreadyExist: "Tal variante xa existe",
    addVariants: "Engadir variantes",
    editProduct: "Editar produto",
    pleaseEnterProductName: "Introduza o nome do produto",
    pleaseEnterProductPrice: "Introduza o prezo do produto",
    pleaseEnterProductOriginalPrice: "Introduza o prezo orixinal do produto",
    productDescription: "Descrición do produto",
    selectProductCategory: "Selecciona unha categoría de produto",
    pleaseSelectProductCategory: "Seleccione a categoría de produto",
    productCostPrice: "Prezo do custo do produto",
    productSellingPrice: "Prezo de venda do produto",
    productOriginalPrice: "Prezo orixinal do produto",
    maxFileSizeAllowedIs4Mb: "Máx. O tamaño do ficheiro permitido é de 4 MB",
    productsWithPicturesArePublished:
      "Os produtos con imaxes publícanse en Discover para recibir pedidos",
    shouldThisProductBeTracked: "Debe rastrexar este produto?",
    pleaseSelectStockUnit: "Selecciona unidade de stock",
    stockUnit: "Unidade de Accións",
    bag: "BOLSA",
    bottle: "BOTELLA",
    bunch: "AGRUPO",
    can: "PODE",
    carton: "CARTÓN",
    crate: "CAIXA",
    cup: "COPA",
    dozen: "DOZEN",
    gigabytes: "XIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRO",
    pack: "PACK",
    pair: "PARELLA",
    pieces: "PEZAS",
    plate: "PLACA",
    tonne: "TONNE (MT)",
    uNIT: "UNIDADE",
    yard: "IARDA",
    pleaseEnterProductQuantity: "Introduza a cantidade de produto",
    productQuantity: "Cantidade de produto",
    isThisProductTaxed: "Tributa este produto?",
    selectTaxType: "Selecciona o tipo de imposto",
    pleaseSelectTaxType: "Seleccione Tipo de imposto",
    progressive: "Progresista",
    proportional: "Proporcional",
    pleaseEnterProductTaxRate: "Introduza a taxa do imposto sobre o produto",
    doesProductHaveVariants: "Ten este produto variantes?",
    type: "Tipo",
    value: "Valor",
    pleaseEnterVariantType: "Introduza o tipo de variante",
    pleaseEnterVariantValue: "Introduza o valor da variante",
    pleaseEnterVariantPrice: "Introduza o prezo da variante",
    pleaseEnterVariantQuantity: "Introduza a cantidade da variante",
    productDeletedSuccessfully: "O produto eliminouse correctamente.",
    categoryDeletedSuccessfully: "A categoría eliminouse correctamente.",
    toDeleteThisProduct: "para eliminar este produto",
    invalidProductQuantity: "Cantidade de produto non válida",
    quantityAddedIsOverStock:
      "A cantidade que estás engadindo é superior á que tes en stock.",
    itemInventoryNotTracked:
      "Non se realiza un seguimento do inventario de artigos",
    addBulkQuantity: "Engadir cantidade masiva",
    enterBulkQuantity: "Introduza a cantidade masiva",
    pleaseEnterBulkQuantity: "Introduza a cantidade masiva",
    youveSuccessfullyAddedANewProduct: "Engadiches con éxito un novo produto.",
    pleaseEnterProductSellingPrice: "Introduza o prezo de venda do produto",
    doYouWantToTrackProductStock: "¿Quere rastrexar o stock de produtos?",
    sellingPrice: "Prezo de venda",
    setProductExpiryReminder:
      "Establecer recordatorio de caducidade do produto",
    productExpiryDate: "Data de caducidade do produto",
    startRemindingFrom: "Comeza a lembrar desde",
    productSuppliesAddedSuccessfully:
      "Engadiches subministracións de produtos con éxito.",
    addProductSupplies: "Engade subministracións de produtos",
    pleaseSelectSupplier: "Seleccione Provedor",
    nameOfProduct: "Nome do produto",
    pleaseSelectProduct: "Seleccione produto",
    productVariant: "Variante do produto",
    pleaseSelectAVariant: "Seleccione unha variante",
    pleaseEnterUnitPrice: "Introduza o prezo unitario",
    businessBranch: "Rama empresarial",
    pleaseSelectBranch: "Selecciona sucursal",
    youveSuccessfullyAddedANewSupplier: "Engadiches con éxito un novo provedor",
    addSupplier: "Engadir provedor",
    pleaseEnterSupplierEmail: "Introduza o correo electrónico do provedor",
    pleaseAddADescription: "Engade unha descrición",
    anErrorOccuredProductsDeleted:
      "Produciuse un erro ao realizar a operación. Ten en conta que é posible que se eliminaran poucos produtos no proceso",
    bulkDelete: "Eliminación masiva",
    youAreAboutToDelete: "Está a piques de borralo",
    product: "Produto",
    isDueToRedeem: "débese canxear",
    aReward: "unha recompensa",
    pleaseSelectCustomerToReeemReward:
      "Selecciona un cliente para trocar a recompensa.",
    youHaveNoLoyaltyProgramRunning:
      "Non tes ningún programa de fidelización activo en execución",
    customerHhasNoPointsInLoyaltyProgram:
      "O cliente non ten ningún punto neste programa de fidelización",
    proceedWithPayment: "Queres continuar co pagamento?",
    youAreAboutToPayForTransactionUsingPoints:
      "Está a piques de pagar a transacción mediante puntos.",
    customerHas: "O cliente ten",
    worth: "paga a pena",
    andIsNotSufficientToPayForTransaction:
      "e non é suficiente para pagar esta transacción. ¿Quererían engadir o saldo usando outro método de pago?",
    addCustomerLoyalty: "Engade fidelidade ao cliente",
    pleaseAddCustomerFirst: "Engade ou selecciona primeiro un cliente.",
    pleaseWaitWhileWeGetReady: "Agarde, mentres nos preparamos",
    lowStock: "Stock baixo",
    pleaseEnterAmountTendered: "Introduza o importe ofertado",
    areYouSureToBookSaleOffline:
      "Seguro que queres reservar esta venda sen conexión?",
    saleWouldBeBookedAutomatically:
      "A venda reservaríase automaticamente cando acenda a súa internet",
    offlineSalesBookingCancelled: "Cancelouse a reserva de vendas sen conexión",
    covid19Message:
      "COVID19: Lava as mans con xabón ou Sanitize para deter a propagación. Mantéñase seguro sempre",
    saleSuccessfullyRecorded: "Venda gravada correctamente.",
    sendReceiptToEmail: "Enviar recibo ao correo electrónico",
    sendThankYouSms: "Enviar SMS de agradecemento",
    sendShippingDetails: "Enviar detalles de envío",
    addLoyalty: "Engade fidelidade",
    searchCustomerNameOrNumber: "Buscar nome ou número de cliente",
    clickTheSearchCustomerBox:
      "Faga clic na caixa de busca de clientes e na tarxeta de dixitalización",
    enterProductPrice: "Introduza o prezo do produto",
    enterPriceFor: "Introduza o prezo para",
    searchForProduct: "Buscar produto",
    all: "Todo",
    backToDashboard: "Volver ao panel de control",
    viewDraftSales: "Ver borrador de vendas",
    variantSelected: "variante seleccionada",
    variant: "variante",
    thePreviousVariantSelectionNotAvailable:
      "A selección de variantes anteriores non está dispoñible para a nova variante seleccionada en función do prezo. Cambie a selección.",
    pleaseSupplyPositiveQuantityNumber:
      "Indique un número de cantidade postiva",
    lowStockFor: "Stock baixo para",
    clearVariants: "Variantes claras",
    pleaseEnterQuantity: "Insira Cantidade",
    picture: "Imaxe",
    redemptionToken: "Token de redención",
    token: "Token",
    totalSpent: "Total gastado",
    confirmPayment: "Confirmar o pago",
    hasPaymentBeenMade: "Procesouse correctamente o pagamento?",
    enterTransactionReference:
      "Introduza a referencia de transacción coa que pagou",
    pleaseSelectACustomer: "Selecciona un cliente.",
    areYouSureToApplyDiscount: "Seguro que queres aplicar un desconto?",
    addYourBankAccountToEnableUssd:
      "Engade a túa conta bancaria para activar a transferencia instantánea USSD por uPay",
    totalAmountToPay: "Importe total a pagar",
    doYouWantToCancelTransaction: "Queres cancelar esta transacción?",
    savePrintBill: "Gardar / imprimir factura",
    enterAmountCollectedForCustomer:
      "Introduza o importe recollido para o cliente",
    recordSale: "Venda de discos",
    checkOutWith: "Consulte con",
    instantTransfer: "Transferencia instantánea",
    dialTheUSSDCode: "Marque o código USSD",
    pleaseSelectABank: "Selecciona un banco",
    payWithUSSD: "Pagar con USSD",
    sendBillTo: " - Enviar factura a",
    waitingForUSSDTransfer: "Agardando a transferencia USSD",
    percent: "Porcentaxe",
    applyDiscount: "Aplicar desconto",
    thisBillHasBeenSaved: "Esta factura gardouse",
    saveDraft: "Gardar borrador",
    pleaseTypeANameToIdentifyCustomer:
      "Escribe un nome para identificar ao cliente",
    paymentDetails: "Detalles do pagamento",
    basePrice: "Prezo base",
    staff: "Persoal",
    subTotal: "Subtotal",
    durationMonths: "Duración (meses)",
    selectADuration: "Selecciona unha duración",
    oneMonth: "1 mes",
    twoMonths: "2 meses",
    threeMonths: "3 meses",
    sixMonths: "6 meses",
    twelveMonths: "12 meses",
    eighteenMonths: "18 meses",
    twentyFourMonths: "24 meses",
    twoMonthsFree: "(2 meses gratis)",
    threeMonthsFree: "(3 meses gratis)",
    fiveMonthsFree: "(5 meses gratis)",
    yourAccountHasBeen: "A túa conta foi",
    renewed: "renovado",
    upgraded: "actualizado",
    successfully: "con éxito",
    yourSubscription: "A túa subscrición",
    youAreCurrentlyEnrolledOnA: "Actualmente estás inscrito a",
    pleaseChooseAPaymentOption: "Escolla unha opción de pagamento",
    planRenewal: "Renovación do plan",
    planUpgrade: "Actualización do plan",
    pleaseSelectDurationToPayFor:
      "Selecciona a duración pola que pretendes pagar",
    staffAccounts: "Contas de persoal",
    ecommerce: "Comercio electrónico",
    pleaseSelectAPlan: "Selecciona un plan",
    includeAddons: "Incluír complementos",
    viewTransactions: "Ver transaccións",
    customerHasNoCompletedTansactions:
      "O cliente aínda non completou as transaccións",
    branch: "Rama",
    enterNumberOfEcommerceBranches:
      "Introduza o número de sucursais de comercio electrónico",
    enterNumberOfEcommerceBranchesToPay:
      "Introduza o número de sucursais de comercio electrónico que pretende pagar",
    ecommerceIntegration: "Integración do comercio electrónico",
    enterNumberOfBranches: "Introduza o número de sucursais",
    enterNumberOfAdditionalBranchesToPay:
      "Introduza o número de sucursais adicionais que pretende pagar",
    enterNumberOfStaffs: "Introduza o número de empregados",
    enterNumberOfStaffsToPayFor:
      "Introduza o número de empregados polos que pretende pagar",
    discountApplies: "Aplícase o desconto",
    starsOnThe: "estrelas no",
    offer: "oferta",
    get: "Consegue",
    moreStarsToRedeem: "máis estrelas para canxear",
    taxVat: "Imposto (IVE)",
    callCashierToCompletePayment: "Chama ao caixeiro para completar o pago",
    receipt: "Xustificante",
    dear: "querido",
    thankYouForYourOrderFindGoods:
      "Grazas polo teu pedido. Atopa os seguintes produtos subministrados, segundo o acordado.",
    shippingNote: "Nota de envío",
    enterShippingNote: "Introduce a nota de envío",
    shippingAddress: "Dirección de envío",
    enterShippingAddress: "Introduce o enderezo de envío",
    wellDoneYouAreDueToRedeem: "Ben feito! Debes canxear",
    toGetYourRewardNextVisit:
      "para obter a túa recompensa na túa próxima visita. Grazas",
    pointsOnThe: "Puntos no",
    morePointsToRedeem: "máis puntos para canxear",
    showCode: "Mostrar código",
    toGetYourRewardOnNextVisit:
      "para obter a túa recompensa na túa próxima visita. Grazas",
    earn: "Gañar",
    delivaryNote: "Nota de entrega",
    draftSales: "Borrador de vendas",
    startDate: "Data de inicio",
    endDate: "Data de finalización",
    orders: "Ordes",
    checkout: "Comprobar",
    noProductItem: "Sen produto",
    selectProductImage: "Selecciona a imaxe do produto",
    selectCountry: "Selecciona un país",
    selectRegion: "Selecciona Estado / Rexión",
    printProductTag: "Imprimir etiqueta de produto",
    transactionReference: "Referencia da transacción",
    Cashier: "Caixeiro",
    Manager: "Xerente",
    Owner: "Dono",
    Admin: "Administrador",
    addPartners: "Engadir socios",
    addNewLoyaltyPartner: "Engadir un novo socio de fidelidade",
    pleaseEnterCompanyName: "Introduza o nome da empresa",
    companyName: "Nome da compañía",
    pleaseEnterCompanyRepName: "Introduza o nome do representante da empresa",
    companyRepName: "Nome do representante da empresa",
    pleaseEnterCompanyRepEmail:
      "Introduce o correo electrónico do representante da empresa",
    companyRepEmail: "Correo electrónico do representante da empresa",
    pleaseEnterCompanyRepPhone:
      "Introduce o número de teléfono do representante da empresa",
    companyRepPhone: "Número de teléfono do representante da empresa",
    addReward: "Engade recompensa",
    pleaseEnterRewardName: "Introduce o nome da recompensa",
    rewardName: "Nome da recompensa",
    rewardQuantity: "Cantidade de recompensa",
    rewardDescription: "Descrición da recompensa",
    rewardType: "Tipo de recompensa",
    pleaseEnterRewardType: "Introduce o tipo de recompensa",
    pleaseEnterRewardQuantity: "Introduza a cantidade de recompensa",
    pleaseEnterRewardDescription: "Introduce a descrición da recompensa",
    fineDining: "Gastronomía fina",
    luxuryFashion: "Moda de luxo",
    hotels: "Hoteis",
    travel: "Viaxar",
    foodAndBeverage: "Alimentos e bebidas",
    fashion: "Moda",
    health: "Saúde",
    furniture: "Mobles",
    entertainment: "Entretemento",
    automobile: "Automóbil",
    education: "Educación",
    beautyAndSpa: "Beleza e Spa",
    staycation: "Estancia",
    events: "Eventos",
    trips: "Viaxes",
    oilAndGas: "Petróleo e Gas",
    laundry: "Lavandería",
    partnerCategory: "Categoría de socios",
    freeItem: "Elemento gratuíto",
  },
  Georgian: {
    cashier: "მოლარე",
    manager: "მენეჯერი",
    owner: "მფლობელი",
    online: "ონლაინ",
    offline: "ხაზგარეშე",
    changePassword: "Პაროლის შეცვლა",
    currentPasswordMessage: "გთხოვთ შეიყვანოთ თქვენი მიმდინარე პაროლი",
    passwordMessage: "გთხოვთ შეიყვანეთ თქვენი პაროლი",
    currentPassword: "მიმდინარე პაროლი",
    password: "პაროლი",
    confirmPasswordMessage: "გთხოვთ დაადასტუროთ თქვენი პაროლი!",
    confirmPassword: "Პაროლის დადასტურება",
    sendViaEmail: "ელ.ფოსტის საშუალებით გაგზავნა",
    sendViaWhatsapp: "გამოაგზავნეთ WhatsApp– ით",
    downloadPdf: "ჩამოტვირთეთ PDF",
    paid: "გადახდილი",
    unpaid: "გადაუხდელი",
    partial: "ნაწილობრივი",
    closeInvoice: "გსურთ ინვოისის დახურვა?",
    closeInvoiceConfirmation:
      "ინვოისი შეიძლება არ იყოს შენახული. დახურვა გინდა?",
    yes: "დიახ",
    no: "არა",
    invoice: "ინვოისი",
    wasDeducted: "გამოაკლდა",
    for: "ამისთვის",
    item: "საქონელი",
    addProduct: "პროდუქტის დამატება",
    paymentReference: "Გადახდის მითითება",
    amountPaid: "Გადახდილი თანხა",
    discountAmount: "Ფასდაკლების რაოდენობა",
    amountDue: "გადასახდელი თანხა",
    amount: "თანხა",
    dueDate: "Თარიღის თანახმად",
    paymentType: "Გადახდის ტიპი",
    card: "ბარათი",
    cash: "ნაღდი ფული",
    bankTransfer: "Საბანკო გადარიცხვა",
    paymentMessage: "გადახდის შეტყობინება",
    description: "აღწერა",
    sendReceipt: "ქვითრის გაგზავნა",
    delete: "წაშლა",
    save: "Შენახვა",
    resend: "Ხელახლა გაგზავნა",
    saveAndSend: "Შენახვა",
    invoiceSaved: "ინვოისი შენახულია!",
    selectPaymentMethod: "გთხოვთ აირჩიოთ გადახდის მეთოდი!",
    selectCustomer: "გთხოვთ შეარჩიოთ კლიენტი!",
    cartEmptyError:
      "კალათის სია არ შეიძლება იყოს ცარიელი, დახურეთ ინვოისი და დაამატეთ საქონელი კალათაში!",
    subscriptionExpired:
      "თქვენი გამოწერა ამოიწურა და თქვენი ანგარიში ახლა შეზღუდულია. დააწკაპუნეთ ქვემოთ ღილაკზე თქვენი ანგარიშის განახლებისთვის",
    renew: "განახლება",
    holdOn: "Შეჩერდი",
    customerBank: "კლიენტთა ბანკი",
    cancel: "გაუქმება",
    selectACustomer: "აირჩიეთ კლიენტი",
    invoiceSuccessfulPdfError:
      "ინვოისი წარმატებით შეიქმნა, მაგრამ PDF თაობას ჩვეულებრივზე მეტი დრო სჭირდება. გთხოვთ გადაამოწმოთ მალე.",
    downloadingInvoice: "მიმდინარეობს ინვოისის ჩამოტვირთვა",
    downloadingReceipt: "მიმდინარეობს ქვითრის ჩამოტვირთვა",
    whatsappReceiptError:
      "ქვითრის გაგზავნისას მოხდა შეცდომა WhatsApp– ით, გთხოვთ სცადოთ ხელახლა.",
    receiptToWhatsapp: "ქვითარი გადაგზავნილია WhatsApp– ში",
    thankYouForPatronage: "მადლობა თქვენი მფარველობისთვის",
    hereIsYourReceipt: "აქ არის თქვენი გადახდის ქვითარი",
    errorSendingEmailReceipt:
      "ელექტრონული ფოსტით ქვითრის გაგზავნისას მოხდა შეცდომა, გთხოვთ სცადოთ ხელახლა ან დაუკავშირდით მხარდაჭერას",
    receiptSentToEmail: "ქვითარი გაიგზავნა მომხმარებლის ელ",
    invoiceSentToEmail: "ინვოისი გაგზავნილია მომხმარებლის ელ",
    invoiceSuccessWhatsappError:
      "ინვოისი წარმატებით შეიქმნა, მაგრამ მოხდა შეცდომა WhatsApp– ში გაგზავნისას. სცადეთ ხელახლა ინვოისების სიაში",
    invoiceSuccessfulEmailError:
      "ინვოისი წარმატებით შეიქმნა, მაგრამ ელექტრონული ფოსტის გაგზავნისას მოხდა შეცდომა. გთხოვთ სცადოთ ხელახლა ინვოისის სიიდან",
    invoiceSentToWhatsapp: "ინვოისი გადაგზავნილია WhatsApp– ში",
    hello: "გამარჯობა",
    pleaseDownloadInvoice: "გთხოვთ გადმოწეროთ ინვოისი",
    pleaseDownloadReceipt: "გთხოვთ გადმოწეროთ ქვითარი",
    from: "დან",
    email: "ელ.ფოსტა",
    upgrade: "განახლება",
    youAreOnFreePlan: "თქვენ ხართ თავისუფალი გეგმის მიხედვით.",
    clickOn: "Დააკლიკეთ",
    yourPlanInFewSteps: " თქვენი ხელმოწერა რამდენიმე სწრაფ ნაბიჯში.",
    to: "რათა",
    yourSubscriptionHasExpired:
      "თქვენი გამოწერა ამოიწურა და თქვენი ანგარიში ახლა შეზღუდულია.",
    days: "დღეები",
    yourSubscriptionExpiresIn: "თქვენი Loystar გამოწერა იწურება",
    createAcount: "Შექმენით ანგარიში",
    signIn: "Შებრძანდით",
    continue: "განაგრძეთ",
    enterOtp: "შეიყვანეთ OTP PIN",
    enterValidOtp: "გთხოვთ შეიყვანოთ სწორი PIN",
    pin: "PIN",
    tokenSentToMail: "ნიშანი გაიგზავნა თქვენს ელექტრონულ ფოსტაზე",
    passwordResetSuccessful: "პაროლის გადატვირთვა წარმატებული იყო",
    somethingWentWrong: "Რაღაც არასწორად წავიდა!",
    resetPassword: "პაროლის აღდგენა",
    iHaveResetCode: "პაროლის გადატვირთვის კოდი მაქვს",
    pleaseEnterEmail: "გთხოვთ შეიყვანოთ თქვენი ელ",
    aTokenWillBeSentToEmail: "ნიშანი გაიგზავნება თქვენს ელ",
    enterEmail: "Შეიყვანეთ თქვენი ელექტრონული ფოსტა",
    sendinYourToken: "თქვენი ჟეტონის გაგზავნა ...",
    makeSureItMatchesPassword: "დარწმუნდით, რომ ის ემთხვევა თქვენს ახალ პაროლს",
    pleaseChooseNewPassword: "გთხოვთ აირჩიოთ ახალი პაროლი",
    chooseNewPassword: "აირჩიეთ ახალი პაროლი",
    enterNewPassword: "შეიყვანეთ თქვენი ახალი პაროლი დასადასტურებლად",
    enterTokenSent: "შეიყვანეთ ნიშანი, რომელიც გაიგზავნა თქვენს ფოსტაზე",
    resetToken: "ჟეტონის გადატვირთვა",
    resettingPassword: "პაროლის გადატვირთვა ...",
    signUp: "დარეგისტრირდით",
    adminSignInWithEmail:
      " ადმინისტრატორი შედის ელექტრონული ფოსტით, ხოლო თანამშრომლები შედიან მომხმარებლის სახელით.",
    pleaseEnterEmailOrUsername:
      "გთხოვთ შეიყვანოთ თქვენი ელ.ფოსტა ან მომხმარებლის სახელი",
    emailOrUsername: "ელ.ფოსტა ან მომხმარებლის სახელი",
    pleaseEnterPassword: "გთხოვთ შეიყვანოთ პაროლი",
    createAnAccount: "Შექმენით ანგარიში",
    forgotPassword: "Პაროლი დაგავიწყდა?",
    enterPhoneNumber: "შეიყვანეთ ტელეფონის ნომერი",
    personalData: "Პერსონალური მონაცემები",
    validateConfirmationCOde: "დაადასტურეთ დადასტურების კოდი",
    pleaseEnterFirstName: "გთხოვთ შეიყვანოთ თქვენი სახელი",
    pleaseEnterPhoneNumber: "გთხოვთ შეიყვანოთ თქვენი ტელეფონის ნომერი",
    pleaseEnterLastName: "გთხოვთ შეიყვანოთ თქვენი გვარი",
    pleaseEnterBusinessName: "გთხოვთ, შეიყვანოთ თქვენი ბიზნესის სახელი",
    firstName: "Სახელი",
    lastName: "Გვარი",
    businessName: "ბიზნესის სახელი",
    previous: "წინა",
    next: "შემდეგი",
    pleaseSelectBusinessCategory: "გთხოვთ აირჩიოთ თქვენი ბიზნესის კატეგორია",
    pleaseEnterValidEmail: "გთხოვთ შეიყვანოთ სწორი ელფოსტა",
    pleaseEnterPostCode: "გთხოვთ შეიყვანოთ საფოსტო კოდი",
    postCode: "საფოსტო კოდი",
    phoneNumberInUse: "ეს ტელეფონის ნომერი უკვე გამოიყენება!",
    emailInUse: "ეს ელფოსტა უკვე გამოიყენება!",
    foodAndDrinks: "Საჭმელი და სასმელი",
    salonAndBeauty: "სალონი და სილამაზე",
    fashionAndAccessories: "მოდა და აქსესუარები",
    gymAndFitness: "სპორტული დარბაზი და ფიტნესი",
    travelAndHotel: "მოგზაურობა და სასტუმრო",
    homeAndGifts: "სახლი და საჩუქრები",
    washingAndCleaning: "სარეცხი და გაწმენდა",
    gadgetsAndElectronics: "გაჯეტები და ელექტრონიკა",
    groceries: "სასურსათო პროდუქტები",
    others: "სხვა",
    submit: "წარდგენა",
    accountCreatedSuccessful: "თქვენი ანგარიში წარმატებით შეიქმნა.",
    pleaseEnterAddress: "გთხოვთ შეიყვანოთ თქვენი მისამართი",
    addressLine1: "მისამართის ხაზი 1",
    addressLine2: "მისამართის ხაზი 2",
    choosePassword: "აირჩიეთ პაროლი",
    passwordMustBe6Characters: "პაროლი უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს.",
    howDidYouHearLoystar: "როგორ გაიგე ლოისტარის შესახებ?",
    referralCode: "მიმართვის კოდი",
    byClickingTheButton: " ქვემოთ მოცემულ ღილაკზე დაჭერით თქვენ ეთანხმებით",
    termsAndSevice: "Ვადები",
    wereCreatingAccount: "ჩვენ ვქმნით თქვენს ანგარიშს",
    proceed: "გააგრძელე",
    verificationCodeMustBe6: "გადამოწმების კოდი უნდა იყოს 6 ციფრი",
    pleaseEnter6DigitCode: "გთხოვთ შეიყვანოთ 6 ციფრიანი კოდი",
    enterVerificationCode: "Შეიყვანეთ დამადასტურებელი კოდი",
    resendToken: "ჟეტონის ხელახლა გაგზავნა",
    verify: "გადამოწმება",
    transactions: "გარიგებები",
    todaySales: "დღევანდელი გაყიდვები",
    salesHistory: "გაყიდვების ისტორია",
    supplyHistory: "მიწოდების ისტორია",
    new: "ახალი",
    invoices: "ინვოისები",
    disbursements: "დაფინანსებები",
    offlineSales: "ხაზგარეშე გაყიდვები",
    products: "პროდუქტები",
    customers: "კლიენტები",
    multiLevelLoyalty: "მრავალ დონის ერთგულება",
    loyaltyPrograms: "ერთგულების პროგრამები",
    members: "წევრები",
    appStore: "Აპლიკაციების მაღაზია",
    orderMenu: "შეკვეთის მენიუ",
    settings: "პარამეტრები",
    staffAndBranches: "პერსონალი და ფილიალები",
    myAccount: "Ჩემი ანგარიში",
    switchToSellMode: "გადადით გაყიდვის რეჟიმში",
    signOut: "Სისტემიდან გამოსვლა",
    getFreeSubscription: "მიიღეთ უფასო გამოწერა",
    onlyNumbersAllowed: "დასაშვებია მხოლოდ რიცხვები",
    yourAccountMustBe10Digits:
      "თქვენი ანგარიშის ნომერი უნდა იყოს 10 ციფრიანი ნომერი",
    yourBvnMustBe11: "თქვენი BVN უნდა იყოს 11 ციფრიანი ნომერი",
    pleaseSelectBank: "გთხოვთ აირჩიოთ თქვენი ბანკი",
    selectYourBank: "აირჩიეთ თქვენი ბანკი",
    enterBankAccountNumber: "შეიყვანეთ საბანკო ანგარიშის ნომერი",
    enterBvnNumber: "შეიყვანეთ თქვენი BVN",
    connectBankAccount: "შეაერთეთ საბანკო ანგარიში",
    passwordResetSuccessfulAndSignOut:
      "თქვენი პაროლი წარმატებით იქნა გადაყენებული. დააწკაპუნეთ ღილაკზე „გამოსვლა“ ქვემოთ ხელახლა შესასვლელად",
    enterCurrentPassword: "Შეიყვანეთ მოქმედი პაროლი",
    pleaseEnterCurrentPassword: "გთხოვთ შეიყვანოთ მიმდინარე პაროლი",
    phoneNumber: "Ტელეფონის ნომერი",
    sex: "სექსი",
    dateOfBirth: "Დაბადების თარიღი",
    state: "სახელმწიფო",
    country: "ქვეყანა",
    loyaltyId: "ერთგულების პირადობის მოწმობა",
    createdAt: "შექმნილია At",
    noOfTransactionsMade: "გარიგებების რაოდენობა",
    yourDownloadWillStart: "თქვენი გადმოტვირთვა დაიწყება ერთ წამში",
    exportCustomers: "კლიენტების ექსპორტი",
    youhaveSuccessfullyToppedUp:
      "თქვენ წარმატებით შეავსეთ თქვენი SMS ერთეულები.",
    youNowHave: "თქვენ ახლა გაქვთ",
    smsUnits: "SMS ერთეულები",
    enterNumberOfSmsUnits:
      "შეიყვანეთ SMS ერთეულების რაოდენობა, რომელთა შეძენაც გსურთ",
    pleaseEnterANumericValue: "გთხოვთ შეიყვანოთ რიცხვითი მნიშვნელობა",
    pay: "გადაიხადე",
    accountEditedSuccessfully: "თქვენი ანგარიში წარმატებით იქნა რედაქტირებული.",
    youAeCurrentlyOn: "თქვენ ამჟამად ხართ",
    plan: "Გეგმა",
    userData: "Მომხმარებლის მონაცემები",
    businessData: "ბიზნესის მონაცემები",
    businessCategory: "ბიზნეს კატერიგო",
    pleaseSelectCurrency: "გთხოვთ აირჩიოთ თქვენი ვალუტა",
    selectYourCurrency: "აირჩიეთ თქვენი ვალუტა",
    purchaseMoreSmsUnits:
      "შეიძინეთ მეტი SMS ერთეული ქვემოთ მოცემული ფორმის გამოყენებით",
    youHave: "Შენ გაქვს",
    atLeast4SmsUnitsRrequired:
      "გადამოწმებისათვის საჭიროა სულ მცირე 4 სმს ერთეული, გთხოვთ შეავსოთ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "გთხოვთ გადაამოწმოთ თქვენი საბანკო ანგარიში, რომ შეასრულოთ „იცოდე შენი კლიენტის“ (KYC) მოთხოვნები. ეს საშუალებას მოგცემთ შეაგროვოთ გადახდები USSD– ით ან მყისიერი გადარიცხვით, მიიღოთ შეკვეთები მომხმარებლებისგან და დაამუშაოთ გარიგების ანგარიშსწორება. ვრცელდება მხოლოდ ნიგერიელ ვაჭრებზე. დასაწყებად გთხოვთ დააჭიროთ ქვემოთ მოცემულ ღილაკს.",
    reConnectBankAccount: "ხელახლა დააკავშირეთ საბანკო ანგარიში",
    accountName: "Ანგარიშის სახელი",
    accountNumber: "Ანგარიშის ნომერი",
    bankName: "Ბანკის სახელი",
    verified: "შემოწმებულია",
    accountDetails: "ანგარიშის დეტალები",
    kycBankAccount: "KYC",
    createTier: "შექმენით იარუსი",
    fileUploadSuccessful: "ფაილი წარმატებით აიტვირთა",
    fileUploadFailed: "ფაილის ატვირთვა ვერ მოხერხდა",
    createLoyaltyProgram: "შექმენით ერთგულების პროგრამა",
    createLoyalty: "შექმენით ერთგულება",
    name: "სახელი",
    loyaltyArtwork: "ერთგულების ნამუშევარი",
    clickToUpload: "დააწკაპუნეთ ასატვირთად",
    amountToPointsRatio: "რაოდენობა ქულა თანაფარდობა",
    points: "ქულები",
    recommendedAmountToPointRatio:
      "რეკომენდირებულია: ₦ 1 -დან 1 ქულამდე. ანუ ყოველი დახარჯული 1 ნაირაზე, თქვენი მომხმარებლები იღებენ 1 ქულას",
    pleaseTypeIn: "გთხოვთ ჩაწეროთ",
    toDeleteLoyalty: "ამ ერთგულების წაშლა",
    deleteLoyalty: "წაშალე ერთგულება",
    toConfirm: "დასამტკიცებლად",
    edit: "რედაქტირება",
    pointsAwardedSuccessfully: "ქულები წარმატებით დაჯილდოვდა.",
    enterPointValueToAward:
      "შეიყვანეთ ქულის მნიშვნელობა, რომლის მინიჭებაც გსურთ კლიენტებისთვის",
    award: "ჯილდო",
    awardPointValuesToCustomer: "დაჯილდოვება ქულა ღირებულება მომხმარებელს",
    enrollMembersToLoyalty: "ჩაწერეთ წევრები ერთგულებაზე",
    awardPoints: "დაჯილდოების ქულები",
    enroll: "ჩაეწერეთ",
    home: "მთავარი",
    loyalty: "Ერთგულება",
    enrollCustomers: "ჩაწერეთ მომხმარებლები",
    selected: "არჩეული",
    customer: "დამკვეთი",
    loyaltyActivationSuccessful: "ერთგულების აქტივაცია წარმატებულია.",
    loyaltyDeactivationSuccessful: "ერთგულების დეაქტივაცია წარმატებულია.",
    viewTier: "იხილეთ იარუსი",
    deactivate: "დე-გააქტიურება",
    activate: "გააქტიურეთ",
    actions: "მოქმედებები",
    nameOfLoyalty: "ერთგულების სახელი",
    loyaltyStatus: "ერთგულების სტატუსი ლოიალურობის სტატუსი",
    numberOfTiers: "იარუსების რაოდენობა",
    createdOn: "Შექმნილია",
    createATier: "შექმენით იარუსი",
    stopCreatingTierConfirmation: "გსურთ შეწყვიტოთ იარუსის შექმნა?",
    stopEditingTierConfirmation: "გსურთ შეწყვიტოთ ამ დონის რედაქტირება?",
    nameOfTier: "იარუსის სახელი",
    minimumSpendingTarget: "მინიმალური ხარჯვის სამიზნე",
    maximumSpendingTarget: "ხარჯვის მაქსიმალური სამიზნე",
    minimumSpendingTargetRequired: "მინიმალური ხარჯვის მიზანია მოთხოვნილი",
    maximumSpendingTargetRequired: "ხარჯვის მაქსიმალური მიზანია მოთხოვნილი",
    rewardSponsor: "დაჯილდოვების სპონსორი",
    pleaseChooseARewardSponsor: "გთხოვთ აირჩიოთ ჯილდოს სპონსორი",
    self: "საკუთარი თავი",
    partner: "პარტნიორი",
    rewardPartner: "ჯილდოს პარტნიორი",
    pleaseSelectRewardPartner: "გთხოვთ შეარჩიოთ თქვენი ჯილდოს პარტნიორი",
    rewards: "ჯილდოები",
    pleaseSelectAReward: "გთხოვთ აირჩიოთ ჯილდო",
    instructionsOnRedeemingReward:
      "ინსტრუქცია, თუ როგორ უნდა გამოისყიდოს მომხმარებელმა ჯილდო",
    pleaseFillInThisField: "გთხოვთ შეავსოთ ეს ველი!",
    toDeleteThisTier: " ამ დონის წაშლა",
    deleteTier: "დონის წაშლა",
    viewMembers: "წევრების ნახვა",
    membersEnrolled: "წევრები ჩაირიცხნენ",
    instruction: "ინსტრუქცია",
    membersIn: "წევრები in",
    availableTiersInLoyalty: "ერთგულების პროგრამაში ხელმისაწვდომია დონე (ები)",
    tiers: "იარუსები",
    totalTier: "სულ იარუსი",
    availableLoyaltyProgramme: "ხელმისაწვდომი ერთგულების პროგრამა",
    totalLoyalties: "სულ ერთგულება",
    memberDetails: "წევრის დეტალები",
    nameOfCustomer: "მომხმარებლის სახელი",
    address: "მისამართი",
    allEnrolledMembers: "ყველა დარეგისტრირებული წევრი",
    thisIsToWishYouHappyBirthday:
      "ეს გისურვებთ ბედნიერ დაბადების დღეს და წარმატებულ ცხოვრებას. მადლობა ყველაფრისთვის, რაც ლოისტერს ხარ. გილოცავთ დღესასწაულებს!",
    inputAnOfferPlease: "გთხოვთ შეიყვანოთ შეთავაზება",
    pleaseSelectTheInsertPoint:
      "გთხოვთ შეარჩიოთ შეტყობინებაში ჩასმის წერტილი და დააწკაპუნეთ ხელახლა",
    birthdayOfferAndMessage: "დაბადების დღის შემოთავაზება და შეტყობინება",
    birthdayOffer: "დაბადების დღის შემოთავაზება",
    birthdayMessage: "დაბადების დღის შეტყობინება",
    noOfferFound: "შეთავაზება არ მოიძებნა",
    settingABirthdayOffer:
      "დაბადების დღის შემოთავაზების დაყენება მომხმარებელს საშუალებას აძლევს მიიღონ ეს შეთავაზება SMS– ით დაბადების დღეზე",
    createOffer: "შექმენით შეთავაზება",
    whatIsTheOffer: "რა არის შემოთავაზება?",
    editMessage: "შეტყობინების რედაქტირება",
    insert: "ჩასმა",
    theNameOfCustomerInserted: "მომხმარებლის სახელი აქ იქნება ჩასმული",
    theBirtdayOfferInserted: "დაბადების დღის შემოთავაზება აქ იქნება ჩასმული",
    youSuccessfullyAddedNewBranch: "თქვენ წარმატებით დაამატეთ ახალი ფილიალი!",
    addNewBranch: "დაამატეთ ახალი ფილიალი",
    addBranch: "ფილიალის დამატება",
    additionalBranchWillIncur: "შეიქმნება დამატებითი ფილიალი",
    perBranch: "თითო ფილიალში",
    ecommerceBranchCosts: "კომერციის ფილიალის ხარჯები",
    pleaseEnterBranchName: "გთხოვთ შეიყვანოთ ფილიალის სახელი",
    pleaseEnterBranchAddress1: "გთხოვთ შეიყვანოთ ფილიალის მისამართის ხაზი 1",
    enterBranchAddress1: "შეიყვანეთ ფილიალის მისამართის ხაზი 1",
    enterBranchAddress2: "შეიყვანეთ ფილიალის მისამართის ხაზი 1",
    pleaseEnterBranchAddress2: "გთხოვთ შეიყვანოთ ფილიალის მისამართის ხაზი 2",
    enterBranchName: "შეიყვანეთ ფილიალის სახელი",
    successfullyAddedStaff: "თქვენ წარმატებით დაამატეთ ახალი პერსონალი!",
    addNewStaff: "დაამატეთ ახალი პერსონალი",
    addStaff: "პერსონალის დამატება",
    additionalStaffWillIncur: "დამატებითი პერსონალი იწვევდა",
    perStaff: "თითო პერსონალზე.",
    pleaseEnterStaffEmail: "გთხოვთ შეიყვანოთ პერსონალის ელ",
    pleaseEnterStaffUsername: "გთხოვთ შეიყვანოთ პერსონალის მომხმარებლის სახელი",
    pleaseEnterStaffPassword: "გთხოვთ შეიყვანოთ პერსონალის პაროლი",
    pleaseSelectStaffRole: "გთხოვთ შეარჩიოთ პერსონალის როლი",
    selectStaffRole: "შეარჩიეთ პერსონალის როლი",
    enterStaffEmail: "შეიყვანეთ პერსონალის ელ",
    enterStaffUsername: "შეიყვანეთ პერსონალის მომხმარებლის სახელი",
    enterStaffPassword: "შეიყვანეთ პერსონალის პაროლი",
    spacesNotAllowedInUsername:
      "მომხმარებლის სახელში არ არის დაშვებული ადგილები",
    admin: "ადმინისტრატორი",
    pleaseSelectBusinessToAttachStaff:
      "გთხოვთ აირჩიოთ ბიზნესი პერსონალის დასაკავშირებლად",
    searchForBranchToAttachStaff: "მოძებნეთ ფილიალი პერსონალის დასამაგრებლად",
    username: "მომხმარებლის სახელი",
    role: "როლი",
    areYouSureToDeleteThis: "დარწმუნებული ხართ, რომ წაშლით ამას",
    branches: "ფილიალები",
    upgradeYourPlan: "განაახლეთ თქვენი გეგმა.",
    add: "დამატება",
    addNew: "Ახლის დამატება",
    customerWithEmailAlreadyExists:
      "კლიენტი ელ.ფოსტის/ტელეფონის ნომრით უკვე არსებობს!",
    successfullyAddedNewCustomer:
      "თქვენ წარმატებით დაამატეთ ახალი მომხმარებელი!",
    addCustomer: "კლიენტის დამატება",
    pleaseEnterCustomerFirstName: "გთხოვთ შეიყვანოთ მომხმარებლის სახელი",
    pleaseEnterCustomerLastName: "გთხოვთ შეიყვანოთ მომხმარებლის გვარი",
    pleaseEnterCustomerPhoneNumber:
      "გთხოვთ შეიყვანოთ მომხმარებლის ტელეფონის ნომერი",
    pleaseEnterCustomerEmail:
      "გთხოვთ შეიყვანოთ მომხმარებლის ელ.ფოსტის მისამართი",
    pleaseEnterCustomerAddressLine:
      "გთხოვთ შეიყვანოთ მომხმარებლის მისამართის ხაზი",
    pleaseEnterCustomerOtherAddress:
      "გთხოვთ შეიყვანოთ მომხმარებლის სხვა მისამართი",
    pleaseSelectCustomerGender: "გთხოვთ შეარჩიოთ მომხმარებლის სქესი",
    gender: "სქესი",
    male: "მამაკაცი",
    female: "ქალი",
    bank: "ბანკი",
    selectBank: "აირჩიეთ ბანკი",
    stateOrRegionOrProvince: "სახელმწიფო/რეგიონი/პროვინცია",
    customerNotes: "მომხმარებლის შენიშვნები",
    sendSms: "გაგზავნეთ SMS",
    editCustomer: "კლიენტის რედაქტირება",
    redeemReward: "გამოისყიდეთ ჯილდო",
    issueLoyaltyCard: "გამოუშვით ერთგულების ბარათი",
    deleteCustomer: "კლიენტის წაშლა",
    youveSuccessfullyAssignedLoyaltyMembership:
      "თქვენ წარმატებით მიანიჭეთ ერთგულების წევრობის ID",
    noMembershipIdAvailable:
      "წევრობის ID არ არის ხელმისაწვდომი. გთხოვთ დაგვიკავშირდეთ hello@loystar.co",
    sendEmail: "ელ.ფოსტის გაგზავნა",
    membershipPoints: "წევრობის ქულები",
    customerDetails: "კლიენტის დეტალები",
    close: "დახურვა",
    loyaltyBalance: "ერთგულების ბალანსი",
    pointsBalance: "ქულების ბალანსი",
    starBalance: "ვარსკვლავის ბალანსი",
    requiredPoints: "საჭირო ქულები",
    requiredStars: "საჭირო ვარსკვლავები",
    reddemCode: "გამოიყენეთ კოდი",
    toDeleteThisCustomer: "ამ კლიენტის წასაშლელად",
    customerHasBeenDeletedSuccessfully: "კლიენტი წარმატებით წაიშალა!",
    customerWithPhoneAlreadyExists: "ტელეფონის ნომერიანი კლიენტი უკვე არსებობს",
    customerWasSuccessfullyEdited: "კლიენტი წარმატებით იქნა რედაქტირებული",
    anErrorOccured: "მოხდა შეცდომა",
    phoneNumberIncludeCountry: "ტელეფონის ნომერი (ქვეყნის კოდის ჩათვლით)",
    yourFileQueuedForUpload:
      "თქვენი ფაილი აიტვირთა რიგში. გთხოვთ განაახლოთ გვერდი რამდენიმე წამის შემდეგ.",
    thereWasAnErrorPerformingOperation: "ოპერაციის შესრულებისას მოხდა შეცდომა!",
    pleaseSelectFile: "გთხოვთ აირჩიოთ ფაილი!",
    oopsOnlyCsvFilesAllowed:
      "უკაცრავად! დასაშვებია მხოლოდ CSV ფაილები. გთხოვთ ატვირთოთ .csv ფაილი.",
    fileShouldBeSmallerThan5Mb:
      "ფაილი უნდა იყოს 5 მბ -ზე ნაკლები. თუ თქვენ გაქვთ უფრო დიდი ფაილი, გთხოვთ გამოაგზავნოთ ელექტრონული ფოსტა support@loystar.co. Გმადლობთ",
    customerFirstNameIsRequired: "მომხმარებლის სახელი აუცილებელია მწკრივზე",
    customerPhoneNumberIsRequired:
      "კლიენტის ტელეფონის ნომერი მითითებულია რიგში",
    importCustomers: "კლიენტების იმპორტი",
    upload: "ატვირთვა",
    clickIconToDownloadCsv:
      "დააწკაპუნეთ ამ ხატულაზე, რომ ჩამოტვირთოთ CSV ფაილის შაბლონი.",
    getGoogleSheetVersion: "მიიღეთ Google ფურცლის ვერსია",
    clickOrDragFileToUpload:
      "დააწკაპუნეთ ან გადაიტანეთ ფაილი ამ მხარეში ასატვირთად",
    missingOrInvalidColumnHeader:
      "სვეტის სათაური აკლია ან არასწორია. გთხოვთ მიჰყევით შაბლონის ფორმატს. Გმადლობთ.",
    youHaveImported: "თქვენ იმპორტირებული გაქვთ",
    youSuccessfullyRedeemedReward: "თქვენ წარმატებით გამოიყენეთ თქვენი ჯილდო!",
    sixDigitCode: "ექვსნიშნა კოდი",
    pleaseEnterCustomerRewardCode: "გთხოვთ შეიყვანოთ მომხმარებლის ჯილდოს კოდი",
    enterRewardCode:
      "შეიყვანეთ ჯილდოს კოდი. (ჯილდოს კოდი არის ასოებისადმი მგრძნობიარე)",
    selectLoyaltyProgram: "შეარჩიეთ ერთგულების პროგრამა",
    stamps: "მარკები",
    smsUnitsLow: "SMS ერთეულები დაბალია",
    pleaseTopUpSmsUnits: "გთხოვთ შეავსოთ SMS ერთეულები",
    smsSuccessfullySentForDelivery: "SMS წარმატებით გაიგზავნა მიწოდებისთვის!",
    sendSmsTo: "გააგზავნეთ SMS",
    allCustomers: "ყველა კლიენტი",
    unitsAvailable: "ერთეულები ხელმისაწვდომია",
    pleaseTypeInTheMessage: "გთხოვთ ჩაწეროთ შეტყობინება",
    message: "შეტყობინება",
    charactersRemaining: "დარჩენილი პერსონაჟები",
    avoidUseOfSpecialCharacters:
      "მოერიდეთ სპეციალური პერსონაჟების და ემოჯის გამოყენებას SMS ერთეულების შესანარჩუნებლად.",
    note: "შენიშვნა",
    errorFetchingCustomersMultilevelDetail:
      "მომხმარებელთა მრავალ დონის დეტალების მიღებისას მოხდა შეცდომა",
    search: "ძებნა",
    reset: "გადატვირთვა",
    importCustomer: "კლიენტის იმპორტი",
    addNewCustomer: "დაამატეთ ახალი მომხმარებელი",
    sendSmsBroadcast: "გააგზავნეთ SMS მაუწყებლობა",
    totalCustomers: "სულ მომხმარებელს",
    disbursementDetails: "გადახდის დეტალები",
    paidBy: "გადახდილი By",
    disbursed: "გაცემული",
    bankAccountName: "საბანკო ანგარიშის სახელი",
    bankAccountNumber: "Საბანკო ანგარიშის ნომერი",
    transferInitiated: "გადაცემა დაიწყო",
    transferCompleted: "გადაცემა დასრულებულია",
    pleaseEnterAValid: "გთხოვთ შეიყვანოთ მოქმედი",
    begin: "დაწყება",
    end: "დასასრული",
    date: "თარიღი",
    paymentDate: "Გადახდის დღე",
    selectDisbursementDuration: "აირჩიეთ გადახდის ხანგრძლივობა",
    totalSettled: "სულ დასახლდა",
    totalUnsettled: "სულ მოუგვარებელი",
    toDeleteThisSale: "წაშალოს ეს გაყიდვა",
    draftSaleDeletedSuccessfully: "გაყიდვის პროექტი წარმატებით წაიშალა!",
    deleteSale: "გაყიდვის წაშლა",
    pleaseTypeInYourTotal:
      "გთხოვთ ჩაწეროთ თქვენი ჯამური წაშლის დასადასტურებლად",
    billDetails: "ბილ დეტალები",
    printBill: "ბეჭდვა კანონპროექტი",
    servedBy: "მსახურობდა",
    total: "სულ",
    createdDate: "შექმნის თარიღი",
    createdTime: "შექმნილი დრო",
    status: "სტატუსი",
    loginSuccessful: "შესვლა წარმატებულია",
    pleaseWaitWhileWeConnectAccount:
      "გთხოვთ დაელოდოთ სანამ ჩვენ დავუკავშირდებით თქვენს ანგარიშს",
    connectionFailedTryAgain: "კავშირი ვერ მოხერხდა. Გთხოვთ კიდევ სცადეთ.",
    connectionSuccessful: "კავშირი წარმატებულია",
    viewDetails: "დეტალების ნახვა",
    enable: "ჩართვა",
    free: "უფასო",
    cost: "ღირებულება",
    visitWebsite: "ეწვიეთ ვებგვერდს",
    pleaseUpgradeYourPlanToPro:
      "გთხოვთ, განაახლოთ თქვენი გეგმა Pro Plus– ზე ამ აპლიკაციის გასააქტიურებლად",
    connectYourBankAccount:
      "შეაერთეთ თქვენი საბანკო ანგარიში, რომ შეძლოთ გადახდების მიღება.",
    disable: "გამორთვა",
    enableApp: "აპის ჩართვა",
    addNewProductToInvoice: "დაამატეთ ახალი პროდუქტი ინვოისს",
    toDeleteThisInvoice: "ამ ინვოისის წასაშლელად",
    invoiceDeletedSuccessfully: "ინვოისი წარმატებით წაიშალა!",
    deleteInvoice: "ინვოისის წაშლა",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "თქვენ წარმატებით დაამატეთ ინვოისის გადახდის შეტყობინება!",
    pleaseEnterPaymentInstructions: "გთხოვთ, შეიყვანოთ გადახდის ინსტრუქცია",
    invoiceId: "ინვოისის ID",
    paidDate: "გადახდილი თარიღი",
    reference: "მითითება",
    productAdded: "პროდუქტი დაემატა",
    productOutOfStock: "პროდუქტი მარაგში არ არის. გთხოვთ ხელახლა გააზიაროთ.",
    totalInvoices: "სულ ინვოისები",
    totalPaidInvoices: "სულ გადახდილი ინვოისები",
    totalUnpaidInvoices: "სულ გადაუხდელი ინვოისები",
    loyaltyProgramDeleted: "ერთგულების პროგრამა წაიშალა",
    thisLoyaltyProgramDeletedSuccessfully:
      "ეს ერთგულების პროგრამა წარმატებით წაიშალა",
    thisLoyaltyProgramEditedSuccessfully:
      "ეს ერთგულების პროგრამა წარმატებით იქნა რედაქტირებული",
    loyaltyProgramAddedSuccessfully: "ერთგულების პროგრამა წარმატებით დაემატა",
    loyaltyProgramEdited: "ერთგულების პროგრამა რედაქტირებულია",
    loyaltyProgramAdded: "დაემატა ერთგულების პროგრამა",
    loyaltyDetails: "ერთგულების დეტალები",
    doYouWantToCloseDialog: "გსურთ დაიხუროს ეს დიალოგი?",
    pleaseEnterLoyaltyName: "გთხოვთ შეიყვანოთ თქვენი ერთგულების სახელი",
    programType: "პროგრამის ტიპი",
    pleaseSelectProgramType: "გთხოვთ აირჩიოთ პროგრამის ტიპი",
    simplePoints: "მარტივი ქულები",
    stampsProgram: "მარკების პროგრამა",
    threshold: "ბარიერი",
    pleaseEnterLoyaltyThreshold: "გთხოვთ, შეიყვანოთ ერთგულების ბარიერი",
    reward: "Ჯილდო",
    pleaseEnterLoyaltyReward: "გთხოვთ შეიყვანოთ ერთგულების ჯილდო",
    totalUserPoints: "სულ მომხმარებლის ქულები",
    totalUserStamps: "სულ მომხმარებლის მარკები",
    spendingTarget: "დახარჯული სამიზნე",
    spendingTargetHint1:
      "დახარჯვის სამიზნე არის ის, თუ რამდენი უნდა დახარჯოს მომხმარებელმა ჯილდოს მოსაპოვებლად. 1 ვალუტის ღირებულება = 1 ქულა.",
    spendingTargetHint2:
      "მარკების მიზანია რამდენი მარკა უნდა შეაგროვოს მომხმარებელმა ჯილდოს მოსაპოვებლად. მაგალითად. 5",
    addNewLoyaltyProgram: "დაამატეთ ახალი ერთგულების პროგრამა",
    addLoyaltyProgram: "დაამატეთ ერთგულების პროგრამა",
    loyaltyProgramType: "ერთგულების პროგრამის ტიპი",
    pleaseSelectLoyaltyProgramType: "გთხოვთ აირჩიოთ ერთგულების პროგრამის ტიპი",
    nameOfProgram: "პროგრამის დასახელება",
    pleaseEnterProgramName: "გთხოვთ შეიყვანოთ პროგრამის სახელი",
    whatIsTheReward: "რა არის ჯილდო?",
    egNextPurchaseIsFree: "Მაგალითად. შემდეგი შენაძენი უფასოა",
    customerName: "მომხმარებლის სახელი",
    guestCustomer: "სტუმარი კლიენტი",
    orderConfirmedSuccessfully: "შეკვეთა წარმატებით დადასტურდა",
    orderCancelledSuccessfully: "შეკვეთა წარმატებით გაუქმდა",
    confirmOrder: "დაადასტურეთ შეკვეთა",
    cancelOrder: "Შეკვეთის გაუქმება",
    allOrders: "ყველა შეკვეთა",
    totalOrders: "სულ შეკვეთები",
    doYouWantToAcceptOrder: "გსურთ მიიღოთ ეს შეკვეთა?",
    doYouWantToCancelOrder: "გსურთ გააუქმოთ ეს შეკვეთა?",
    orderDetails: "შეკვეთის დეტალები",
    orderCreatedAt: "შეკვეთა შეიქმნა",
    supplier: "Მიმწოდებელი",
    productName: "Პროდუქტის სახელი",
    quantity: "რაოდენობა",
    unitPrice: "Ერთეულის ფასი",
    receivedBy: "Მიიღო",
    reportFrom: "მოხსენება საწყისიდან",
    totalSupplies: "მთლიანი მარაგი",
    allRightsReserved: "Ყველა უფლება დაცულია",
    toDeleteThisTransaction: "ამ გარიგების წაშლა",
    transactionDeletedSuccessfully:
      "გარიგება წარმატებით წაიშალა. მარაგი დაუბრუნდა ინვენტარს.",
    deleteTransaction: "გარიგების წაშლა",
    transactionDetails: "ტრანზაქციის დეტალები",
    printReceipt: "დაბეჭდე ქვითარი",
    channel: "არხი",
    discount: "ფასდაკლება",
    profit: "მოგება",
    discountedSales: "ფასდაკლებული გაყიდვები",
    errorFetchingRecord: "ჩანაწერის მიღებისას მოხდა შეცდომა",
    exportTransactions: "საექსპორტო ტრანზაქციები",
    errorFetchingSalesRecord:
      "შეცდომა ექსპორტის გაყიდვების ჩანაწერის მოძიებაში.",
    totalSellingPrice: "სულ გასაყიდი ფასი",
    totalCostPrice: "მთლიანი ღირებულების ფასი",
    appliedDiscount: "გამოყენებულია ფასდაკლება",
    noOfItems: "პუნქტების რაოდენობა",
    sales: "Გაყიდვების",
    export: "ექსპორტი",
    totalProfit: "მთლიანი მოგება",
    totalBalanceInPeriod: "მთლიანი ბალანსი პერიოდში",
    allTimeSales: "ყველა დროის გაყიდვები",
    records: "ჩანაწერები",
    todaysSales: "დღევანდელი გაყიდვები",
    transaction: "გარიგება",
    youHaveExceededTotalNumberOfProducts:
      "თქვენ გადააჭარბეთ თქვენს გეგმაზე ნებადართული პროდუქციის საერთო რაოდენობას. განაახლეთ თქვენი გეგმა, რომ ისარგებლოთ უფრო მაღალი ლიმიტით.",
    youNeedToHaveLoyaltyProgram:
      "თქვენ უნდა გქონდეთ ერთგულების პროგრამა ამ ფუნქციის გამოსაყენებლად!",
    price: "ფასი",
    category: "კატეგორია",
    stockTracking: "საფონდო თვალთვალი",
    stockCount: "საფონდო რაოდენობა",
    taxed: "იბეგრება",
    originalPrice: "Საწყისი ფასი",
    costPrice: "Ღირებულება",
    unit: "ერთეული",
    productImage: "პროდუქტის სურათი",
    taxRate: "Გადასახადის განაკვეთი",
    taxType: "საგადასახადო ტიპი",
    trackInventory: "ინვენტარის თვალყურის დევნება",
    variants: "ვარიანტები",
    hasVariants: "აქვს ვარიანტები",
    importProduct: "პროდუქტის იმპორტი",
    exportProducts: "პროდუქციის ექსპორტი",
    addNewProduct: "დაამატეთ ახალი პროდუქტი",
    viewCategory: "კატეგორიის ნახვა",
    viewSuppliers: "მომწოდებლების ნახვა",
    receiveInventory: "მიიღეთ ინვენტარი",
    printAllProductsTag: "ბეჭდვა ყველა პროდუქტი Tag",
    deleteAll: "წაშალე ყველა",
    totalProducts: "სულ პროდუქტები",
    youveSuccessfullyAddedANewCategory:
      "თქვენ წარმატებით დაამატეთ ახალი კატეგორია",
    addNewCategory: "ახალი კატეგორიის დამატება",
    addCategory: "დაამატეთ კატეგორია",
    categoryName: "კატეგორიის სახელი",
    pleaseEnterCategoryName: "გთხოვთ შეიყვანოთ კატეგორიის სახელი",
    stampsTarget: "მარკების სამიზნე",
    sendInventory: "ინვენტარის გაგზავნა",
    productDetails: "პროდუქტის აღწერილობა",
    youveSuccessfullyEditedThisCategory:
      "თქვენ წარმატებით დაარედაქტირეთ ეს კატეგორია",
    update: "განახლება",
    categoryList: "კატეგორიების სია",
    categories: "კატეგორიები",
    enterQuantityToUpdate: "შეიყვანეთ რაოდენობა განახლებისთვის",
    inventorySentSuccessfully: "ინვენტარი წარმატებით გაიგზავნა!",
    updateInventory: "ინვენტარის განახლება",
    currentQuantity: "მიმდინარე რაოდენობა",
    pleaseEnterQuantityToAdd:
      "გთხოვთ, შეიყვანოთ რაოდენობა, რომლის დამატებაც გსურთ",
    pleaseSelectABranch: "გთხოვთ აირჩიოთ ფილიალი",
    searchForBranch: "მოძებნეთ ფილიალი",
    youCantSendMoreThanStock:
      "თქვენ არ შეგიძლიათ გაგზავნოთ იმაზე მეტი, რაც გაქვთ საწყობში",
    send: "გაგზავნა",
    pleaseEnterQuantityToSend:
      "გთხოვთ შეიყვანოთ რაოდენობა, რომლის გაგზავნაც გსურთ",
    productNameIsRequiredOnRow: "პროდუქტის სახელი მითითებულია რიგში",
    productCategoryIsRequiredOnRow: "პროდუქტის კატეგორია საჭიროა ზედიზედ",
    productPriceIsRequiredOnRow: "პროდუქტის ფასი საჭიროა ზედიზედ",
    productUnitIsRequiredOnRow: "პროდუქტის ერთეული საჭიროა რიგში",
    productQuantityIsRequiredOnRow: "პროდუქტის რაოდენობა საჭიროა ზედიზედ",
    productVariantsRequireTracking: "პროდუქტის ვარიანტები საჭიროებს თვალყურს!",
    pleaseAddVariantClickButton:
      "გთხოვთ დაამატოთ ვარიანტი დამატების ღილაკზე დაჭერით!",
    totalVariantsMoreThanSelectedQuantity:
      "სულ ვარიანტები აღემატება პროდუქტის შერჩეულ რაოდენობას, Pls ამცირებს ვარიანტის რაოდენობას",
    productEditedSuccessfully: "პროდუქტი წარმატებით იქნა რედაქტირებული!",
    fileTooLargeLessThan4Mb:
      "ფაილის ზომა ძალიან დიდია! ფაილის ზომა უნდა იყოს 4 მბ -ზე ნაკლები.",
    suchVariantAlreadyExist: "ასეთი ვარიანტი უკვე არსებობს",
    addVariants: "დაამატეთ ვარიანტები",
    editProduct: "პროდუქტის რედაქტირება",
    pleaseEnterProductName: "გთხოვთ შეიყვანოთ პროდუქტის სახელი",
    pleaseEnterProductPrice: "გთხოვთ შეიყვანოთ პროდუქტის ფასი",
    pleaseEnterProductOriginalPrice:
      "გთხოვთ შეიყვანოთ პროდუქტის ორიგინალური ფასი",
    productDescription: "პროდუქტის აღწერა",
    selectProductCategory: "აირჩიეთ პროდუქტის კატეგორია",
    pleaseSelectProductCategory: "გთხოვთ აირჩიოთ პროდუქტის კატეგორია",
    productCostPrice: "პროდუქტის ღირებულება ფასი",
    productSellingPrice: "პროდუქტის გასაყიდი ფასი",
    productOriginalPrice: "პროდუქტის ორიგინალური ფასი",
    maxFileSizeAllowedIs4Mb: "მაქს. დასაშვებია ფაილის ზომა 4 mb",
    productsWithPicturesArePublished:
      "სურათების მქონე პროდუქტები გამოქვეყნებულია Discover– ზე შეკვეთების მისაღებად",
    shouldThisProductBeTracked: "უნდა იყოს თვალყური ამ პროდუქტს?",
    pleaseSelectStockUnit: "გთხოვთ აირჩიოთ საფონდო ერთეული",
    stockUnit: "საფონდო ერთეული",
    bag: "ჩანთა",
    bottle: "ბოთლი",
    bunch: "მტევანი",
    can: "შეუძლია",
    carton: "კარტონი",
    crate: "კრიტერიუმი",
    cup: "CUP",
    dozen: "დოზენი",
    gigabytes: "გიგაბიტები",
    gram: "გრამი",
    kilogram: "კილოგრამი",
    litre: "ლიტერატურა",
    pack: "პაკეტი",
    pair: "წყვილი",
    pieces: "ᲜᲐᲭᲠᲔᲑᲘ",
    plate: "დაფა",
    tonne: "ტონი (MT)",
    uNIT: "UNIT",
    yard: "ეზო",
    pleaseEnterProductQuantity: "გთხოვთ შეიყვანოთ პროდუქტის რაოდენობა",
    productQuantity: "პროდუქტის რაოდენობა",
    isThisProductTaxed: "ეს პროდუქტი იბეგრება?",
    selectTaxType: "აირჩიეთ საგადასახადო ტიპი",
    pleaseSelectTaxType: "გთხოვთ აირჩიოთ საგადასახადო ტიპი",
    progressive: "პროგრესული",
    proportional: "პროპორციული",
    pleaseEnterProductTaxRate:
      "გთხოვთ შეიყვანოთ პროდუქტის გადასახადის განაკვეთი",
    doesProductHaveVariants: "აქვს თუ არა ამ პროდუქტს ვარიაციები?",
    type: "ტიპი",
    value: "ღირებულება",
    pleaseEnterVariantType: "გთხოვთ შეიყვანოთ ვარიანტის ტიპი",
    pleaseEnterVariantValue: "გთხოვთ შეიყვანოთ ვარიანტის მნიშვნელობა",
    pleaseEnterVariantPrice: "გთხოვთ შეიყვანოთ ვარიანტის ფასი",
    pleaseEnterVariantQuantity: "გთხოვთ შეიყვანოთ ვარიანტის რაოდენობა",
    productDeletedSuccessfully: "პროდუქტი წარმატებით წაიშალა!",
    categoryDeletedSuccessfully: "კატეგორია წარმატებით წაიშალა!",
    toDeleteThisProduct: "ამ პროდუქტის წაშლა",
    invalidProductQuantity: "პროდუქტის არასწორი რაოდენობა",
    quantityAddedIsOverStock:
      "თქვენ მიერ დამატებული რაოდენობა აღემატება თქვენს საწყობში.",
    itemInventoryNotTracked: "ერთეულის ინვენტარიზაცია არ არის თვალყური",
    addBulkQuantity: "დაამატეთ ნაყარი რაოდენობა",
    enterBulkQuantity: "შეიყვანეთ ნაყარი რაოდენობა",
    pleaseEnterBulkQuantity: "გთხოვთ შეიყვანოთ ნაყარი რაოდენობა",
    youveSuccessfullyAddedANewProduct:
      "თქვენ წარმატებით დაამატეთ ახალი პროდუქტი!",
    pleaseEnterProductSellingPrice: "გთხოვთ მიუთითოთ პროდუქტის გასაყიდი ფასი",
    doYouWantToTrackProductStock: "გსურთ თვალყური ადევნოთ პროდუქციის მარაგს?",
    sellingPrice: "Გასაყიდი ფასი",
    setProductExpiryReminder: "დააყენეთ პროდუქტის ვადის გასვლის შეხსენება",
    productExpiryDate: "პროდუქტის ვადის გასვლის თარიღი",
    startRemindingFrom: "დაიწყეთ შეხსენება დან",
    productSuppliesAddedSuccessfully:
      "თქვენ წარმატებით დაამატეთ პროდუქტის მარაგი.",
    addProductSupplies: "დაამატეთ პროდუქტის მასალები",
    pleaseSelectSupplier: "გთხოვთ აირჩიოთ მიმწოდებელი",
    nameOfProduct: "პროდუქტის დასახელება",
    pleaseSelectProduct: "გთხოვთ აირჩიოთ პროდუქტი",
    productVariant: "პროდუქტის ვარიანტი",
    pleaseSelectAVariant: "გთხოვთ აირჩიოთ ვარიანტი",
    pleaseEnterUnitPrice: "გთხოვთ შეიყვანოთ ერთეულის ფასი",
    businessBranch: "ბიზნეს ფილიალი",
    pleaseSelectBranch: "გთხოვთ აირჩიოთ ფილიალი",
    youveSuccessfullyAddedANewSupplier:
      "თქვენ წარმატებით დაამატეთ ახალი მიმწოდებელი",
    addSupplier: "მომწოდებლის დამატება",
    pleaseEnterSupplierEmail: "გთხოვთ შეიყვანოთ მიმწოდებლის ელ",
    pleaseAddADescription: "გთხოვთ დაამატოთ აღწერა",
    anErrorOccuredProductsDeleted:
      "ოპერაციის შესრულებისას მოხდა შეცდომა. გთხოვთ გაითვალისწინოთ, შესაძლოა რამდენიმე პროდუქტი წაიშალა პროცესში",
    bulkDelete: "ნაყარი წაშლა",
    youAreAboutToDelete: "თქვენ აპირებთ წაშლას",
    product: "პროდუქტი",
    isDueToRedeem: "არის გამოსყიდვის გამო",
    aReward: "ჯილდო",
    pleaseSelectCustomerToReeemReward:
      "გთხოვთ შეარჩიოთ კლიენტი ჯილდოს გამოსაყენებლად.",
    youHaveNoLoyaltyProgramRunning:
      "თქვენ არ გაქვთ აქტიური ერთგულების პროგრამა",
    customerHhasNoPointsInLoyaltyProgram:
      "მომხმარებელს არ აქვს ქულები ამ ერთგულების პროგრამაში",
    proceedWithPayment: "გააგრძელე გადახდა?",
    youAreAboutToPayForTransactionUsingPoints:
      "თქვენ აპირებთ გადაიხადოთ გარიგება ქულების გამოყენებით.",
    customerHas: "მომხმარებელს აქვს",
    worth: "ღირს",
    andIsNotSufficientToPayForTransaction:
      "და არ არის საკმარისი ამ გარიგების გადახდა. მათ სურთ ბალანსის დამატება გადახდის სხვა მეთოდის გამოყენებით?",
    addCustomerLoyalty: "დაამატეთ მომხმარებელთა ერთგულება",
    pleaseAddCustomerFirst: "გთხოვთ, ჯერ დაამატოთ ან შეარჩიოთ კლიენტი.",
    pleaseWaitWhileWeGetReady: "გთხოვთ დაელოდოთ სანამ ჩვენ მოვემზადებით",
    lowStock: "დაბალი საფონდო",
    pleaseEnterAmountTendered: "გთხოვთ მიუთითოთ ტენდერის თანხა",
    areYouSureToBookSaleOffline:
      "დარწმუნებული ხართ, რომ გსურთ დაჯავშნოთ ეს გაყიდვა ხაზგარეშე?",
    saleWouldBeBookedAutomatically:
      "გაყიდვა ავტომატურად დაჯავშნილი იქნება, როდესაც ინტერნეტს ჩართავთ",
    offlineSalesBookingCancelled: "გაყიდვების ხაზგარეშე დაჯავშნა გაუქმდა",
    covid19Message:
      "COVID19: დაიბანეთ ხელები საპნით ან გაწმინდეთ, რომ შეაჩეროთ გავრცელება. იყავით ყოველთვის უსაფრთხო",
    saleSuccessfullyRecorded: "გაყიდვა წარმატებით ჩაწერილია!",
    sendReceiptToEmail: "გაგზავნეთ ქვითარი ელ.ფოსტაზე",
    sendThankYouSms: "გაგზავნეთ მადლობის SMS",
    sendShippingDetails: "გაგზავნეთ გადაზიდვის დეტალები",
    addLoyalty: "დაამატეთ ერთგულება",
    searchCustomerNameOrNumber: "მოძებნეთ მომხმარებლის სახელი ან ნომერი",
    clickTheSearchCustomerBox:
      "დააწკაპუნეთ ძიების მომხმარებლის ყუთზე და სკანირების ბარათზე",
    enterProductPrice: "შეიყვანეთ პროდუქტის ფასი",
    enterPriceFor: "შეიყვანეთ ფასი ამისთვის",
    searchForProduct: "მოძებნეთ პროდუქტი",
    all: "ყველა",
    backToDashboard: "დაუბრუნდი დაფაზე",
    viewDraftSales: "ნახეთ გაყიდვების პროექტი",
    variantSelected: "ვარიანტი არჩეულია",
    variant: "ვარიანტი",
    thePreviousVariantSelectionNotAvailable:
      "ფასის მიხედვით არჩეული ახალი ვარიანტისთვის წინა ვარიანტის არჩევანი არ არის ხელმისაწვდომი, გთხოვთ შეცვალოთ თქვენი არჩევანი.",
    pleaseSupplyPositiveQuantityNumber:
      "გთხოვთ, მიუთითოთ პოსტიური რაოდენობის ნომერი",
    lowStockFor: "დაბალი მარაგი ამისთვის",
    clearVariants: "ნათელი ვარიანტები",
    pleaseEnterQuantity: "გთხოვთ შეიყვანოთ რაოდენობა",
    picture: "Სურათი",
    redemptionToken: "გამოსყიდვის ნიშანი",
    token: "Ნიშანი",
    totalSpent: "სულ გაატარა",
    confirmPayment: "დაადასტურეთ გადახდა",
    hasPaymentBeenMade: "გადახდა წარმატებით დამუშავდა?",
    enterTransactionReference:
      "შეიყვანეთ გარიგების მითითება, რომლითაც გადაიხადეთ",
    pleaseSelectACustomer: "გთხოვთ შეარჩიოთ მომხმარებელი!",
    areYouSureToApplyDiscount:
      "დარწმუნებული ხართ, რომ გსურთ გამოიყენოთ ფასდაკლება?",
    addYourBankAccountToEnableUssd:
      "დაამატეთ თქვენი საბანკო ანგარიში, რათა ჩართოთ მყისიერი USSD გადაცემა uPay– ით",
    totalAmountToPay: "გადახდის მთლიანი თანხა",
    doYouWantToCancelTransaction: "გსურთ გააუქმოთ ეს გარიგება?",
    savePrintBill: "შენახვა/დაბეჭდვა კანონპროექტი",
    enterAmountCollectedForCustomer: "შეიყვანეთ კლიენტისთვის შეგროვებული თანხა",
    recordSale: "ჩანაწერის გაყიდვა",
    checkOutWith: "შეამოწმეთ ერთად",
    instantTransfer: "მყისიერი გადაცემა",
    dialTheUSSDCode: "აკრიფეთ USSD კოდი",
    pleaseSelectABank: "გთხოვთ აირჩიოთ ბანკი",
    payWithUSSD: "გადაიხადეთ USSD– ით",
    sendBillTo: " - გააგზავნე ბილი",
    waitingForUSSDTransfer: "ველოდები USSD გადაცემას",
    percent: "პროცენტი",
    applyDiscount: "გამოიყენეთ ფასდაკლება",
    thisBillHasBeenSaved: "ეს კანონპროექტი შენახულია",
    saveDraft: "მონახაზის შენახვა",
    pleaseTypeANameToIdentifyCustomer:
      "გთხოვთ, შეიყვანოთ სახელი მომხმარებლის იდენტიფიცირების მიზნით",
    paymentDetails: "Გადახდის დეტალები",
    basePrice: "საბაზისო ფასი",
    staff: "პერსონალი",
    subTotal: "ქვეჯამური",
    durationMonths: "ხანგრძლივობა (თვე)",
    selectADuration: "აირჩიეთ ხანგრძლივობა",
    oneMonth: "1 თვე",
    twoMonths: "2 თვე",
    threeMonths: "3 თვე",
    sixMonths: "6 თვე",
    twelveMonths: "12 თვე",
    eighteenMonths: "18 თვე",
    twentyFourMonths: "24 თვე",
    twoMonthsFree: "(2 თვე უფასოდ)",
    threeMonthsFree: "(3 თვე უფასოდ)",
    fiveMonthsFree: "(5 თვე უფასოდ)",
    yourAccountHasBeen: "თქვენი ანგარიში იყო",
    renewed: "განახლებულია",
    upgraded: "განახლდა",
    successfully: "წარმატებით",
    yourSubscription: "თქვენი გამოწერა",
    youAreCurrentlyEnrolledOnA: "თქვენ ამჟამად ხართ დარეგისტრირებული ა",
    pleaseChooseAPaymentOption: "გთხოვთ აირჩიოთ გადახდის ვარიანტი",
    planRenewal: "გეგმის განახლება",
    planUpgrade: "დაგეგმეთ განახლება",
    pleaseSelectDurationToPayFor:
      "გთხოვთ აირჩიოთ ხანგრძლივობა, რომლის გადახდასაც აპირებთ",
    staffAccounts: "პერსონალის ანგარიშები",
    ecommerce: "კომერცია",
    pleaseSelectAPlan: "გთხოვთ აირჩიოთ გეგმა",
    includeAddons: "ჩართეთ დანამატები",
    viewTransactions: "ტრანზაქციების ნახვა",
    customerHasNoCompletedTansactions:
      "მომხმარებელს ჯერ არ აქვს დასრულებული თანაზაქციები",
    branch: "ფილიალი",
    enterNumberOfEcommerceBranches:
      "შეიყვანეთ ელექტრონული კომერციის ფილიალების რაოდენობა",
    enterNumberOfEcommerceBranchesToPay:
      "შეიყვანეთ ელექტრონული კომერციის ფილიალების რაოდენობა, რომლის გადახდასაც აპირებთ",
    ecommerceIntegration: "კომერციის ინტეგრაცია",
    enterNumberOfBranches: "შეიყვანეთ ფილიალების რაოდენობა",
    enterNumberOfAdditionalBranchesToPay:
      "შეიყვანეთ დამატებითი ფილიალების რაოდენობა, რომლის გადახდასაც აპირებთ",
    enterNumberOfStaffs: "შეიყვანეთ პერსონალის რაოდენობა",
    enterNumberOfStaffsToPayFor:
      "შეიყვანეთ პერსონალის რაოდენობა, რომლის გადახდასაც აპირებთ",
    discountApplies: "მოქმედებს ფასდაკლება",
    starsOnThe: "ვარსკვლავები",
    offer: "შეთავაზება",
    get: "მიიღეთ",
    moreStarsToRedeem: "მეტი ვარსკვლავი გამოისყიდოს",
    taxVat: "გადასახადი (დღგ)",
    callCashierToCompletePayment: "დარეკეთ მოლარე გადახდის დასასრულებლად",
    receipt: "ქვითარი",
    dear: "ძვირფასო",
    thankYouForYourOrderFindGoods:
      "Გმადლობთ შეკვეთისთვის. გთხოვთ იპოვოთ მიწოდებული შემდეგი საქონელი, როგორც შეთანხმებულია.",
    shippingNote: "გადაზიდვის შენიშვნა",
    enterShippingNote: "შეიყვანეთ გადაზიდვის შენიშვნა",
    shippingAddress: "Მიწოდების მისამართი",
    enterShippingAddress: "შეიყვანეთ გადაზიდვის მისამართი",
    wellDoneYouAreDueToRedeem: "კარგად გააკეთე! თქვენ გამოისყიდით",
    toGetYourRewardNextVisit:
      "რომ მიიღოთ თქვენი ჯილდო თქვენს მომავალ ვიზიტზე. Გმადლობთ",
    pointsOnThe: "ქულები",
    morePointsToRedeem: "მეტი ქულა გამოსასყიდად",
    showCode: "კოდის ჩვენება",
    toGetYourRewardOnNextVisit:
      "რომ მიიღოთ თქვენი ჯილდო თქვენს მომავალ ვიზიტზე. Გმადლობთ",
    earn: "იშოვე",
    delivaryNote: "მშობიარობის შენიშვნა",
    draftSales: "გაყიდვების პროექტი",
    startDate: "Დაწყების თარიღი",
    endDate: "Დასრულების თარიღი",
    orders: "შეკვეთები",
    checkout: "გასვლა",
    noProductItem: "პროდუქტის ერთეული არ არის",
    selectProductImage: "აირჩიეთ პროდუქტის სურათი",
    selectCountry: "აირჩიეთ ქვეყანა",
    selectRegion: "აირჩიეთ სახელმწიფო/რეგიონი",
    printProductTag: "პროდუქტის ამობეჭდვა",
    transactionReference: "გარიგების მითითება",
    Cashier: "მოლარე",
    Manager: "მენეჯერი",
    Owner: "მფლობელი",
    Admin: "ადმინისტრატორი",
    addPartners: "პარტნიორების დამატება",
    addNewLoyaltyPartner: "დაამატეთ ახალი ლოიალობის პარტნიორი",
    pleaseEnterCompanyName: "გთხოვთ შეიყვანოთ კომპანიის სახელი",
    companyName: "კომპანიის სახელი",
    pleaseEnterCompanyRepName:
      "გთხოვთ შეიყვანოთ კომპანიის წარმომადგენლის სახელი",
    companyRepName: "კომპანიის წარმომადგენლის დასახელება",
    pleaseEnterCompanyRepEmail:
      "გთხოვთ შეიყვანოთ კომპანიის წარმომადგენლის ელ.ფოსტა",
    companyRepEmail: "კომპანიის წარმომადგენლის ფოსტა",
    pleaseEnterCompanyRepPhone:
      "გთხოვთ შეიყვანოთ კომპანიის წარმომადგენლის ტელეფონის ნომერი",
    companyRepPhone: "კომპანიის წარმომადგენლის ტელეფონის ნომერი",
    addReward: "დაამატე ჯილდო",
    pleaseEnterRewardName: "გთხოვთ, შეიყვანოთ ჯილდოს სახელი",
    rewardName: "ჯილდოს სახელი",
    rewardQuantity: "ჯილდოს რაოდენობა",
    rewardDescription: "ჯილდოს აღწერა",
    rewardType: "ჯილდოს ტიპი",
    pleaseEnterRewardType: "გთხოვთ, შეიყვანოთ ჯილდოს ტიპი",
    pleaseEnterRewardQuantity: "გთხოვთ, შეიყვანოთ ჯილდოს რაოდენობა",
    pleaseEnterRewardDescription: "გთხოვთ შეიყვანოთ ჯილდოს აღწერა",
    fineDining: "კარგი სასადილო",
    luxuryFashion: "ძვირადღირებული მოდა",
    hotels: "სასტუმროები",
    travel: "მოგზაურობა",
    foodAndBeverage: "Საკვები და სასმელი",
    fashion: "მოდა",
    health: "ჯანმრთელობა",
    furniture: "ავეჯი",
    entertainment: "Გასართობი",
    automobile: "ავტომობილი",
    education: "Განათლება",
    beautyAndSpa: "სილამაზე და სპა",
    staycation: "დარჩენა",
    events: "Ივენთი",
    trips: "მოგზაურობები",
    oilAndGas: "ნავთობი და გაზი",
    laundry: "სამრეცხაო",
    partnerCategory: "პარტნიორის კატეგორია",
    freeItem: "უფასო ნივთი",
  },
  "Haitian Creole": {
    cashier: "kesye",
    manager: "manadjè",
    owner: "mèt kay",
    online: "sou entènèt",
    offline: "offline",
    changePassword: "Chanje Modpas",
    currentPasswordMessage: "Tanpri antre modpas ou ye kounye a",
    passwordMessage: "Tanpri antre modpas ou",
    currentPassword: "Modpas Kouran",
    password: "modpas",
    confirmPasswordMessage: "Tanpri konfime modpas ou!",
    confirmPassword: "Konfime modpas",
    sendViaEmail: "Voye Via Imèl",
    sendViaWhatsapp: "Voye Via WhatsApp",
    downloadPdf: "Telechaje PDF",
    paid: "peye",
    unpaid: "ki poko peye",
    partial: "pasyèl",
    closeInvoice: "Èske ou vle fèmen fakti a?",
    closeInvoiceConfirmation: "Facture pa ka sove. Èske ou vle fèmen?",
    yes: "wi",
    no: "non",
    invoice: "Facture",
    wasDeducted: "te dedwi",
    for: "pou",
    item: "Atik",
    addProduct: "Ajoute pwodwi",
    paymentReference: "Peman Referans",
    amountPaid: "Montan Peye",
    discountAmount: "Kantite rabè",
    amountDue: "Kantite lajan ou dwe",
    amount: "Kantite lajan",
    dueDate: "Dat pou renmet",
    paymentType: "Kalite Peman",
    card: "Kat",
    cash: "Lajan Kach",
    bankTransfer: "Transfè labank",
    paymentMessage: "Mesaj Peman",
    description: "Deskripsyon",
    sendReceipt: "Voye Resi",
    delete: "Efase",
    save: "Sove",
    resend: "Re-voye",
    saveAndSend: "Sove",
    invoiceSaved: "Bòdwo sove!",
    selectPaymentMethod: "Tanpri chwazi yon Metòd Peman!",
    selectCustomer: "Tanpri chwazi yon Kliyan!",
    cartEmptyError:
      "Lis Cart pa ka vid, fèmen fakti a epi ajoute atik nan kabwa!",
    subscriptionExpired:
      "Abònman ou ekspire epi kont ou an kounye a limite. Klike sou bouton ki anba a pou renouvle kont ou",
    renew: "Renouvle",
    holdOn: "Kenbe",
    customerBank: "Bank Kliyan",
    cancel: "Anile",
    selectACustomer: "Chwazi Kliyan",
    invoiceSuccessfulPdfError:
      "Bòdwo kreye avèk siksè, men PDF Jenerasyon ap pran pi lontan pase nòmal. Tanpri tcheke tounen yon ti tan.",
    downloadingInvoice: "Telechaje fakti",
    downloadingReceipt: "Telechaje Resi",
    whatsappReceiptError:
      "Yon erè rive voye resi a via WhatsApp, Tanpri eseye ankò.",
    receiptToWhatsapp: "Resi voye bay WhatsApp",
    thankYouForPatronage: "Mèsi pou patwonaj ou",
    hereIsYourReceipt: "Isit la se resi peman ou",
    errorSendingEmailReceipt:
      "Yon erè rive voye resi a via e-mail, tanpri eseye ankò oswa kontakte sipò",
    receiptSentToEmail: "Resi te voye nan imèl kliyan an",
    invoiceSentToEmail: "Facture te voye nan imèl kliyan an",
    invoiceSuccessWhatsappError:
      "Bòdwo kreye avèk siksè men yon erè rive voye WhatsApp. Eseye ankò sou lis fakti a",
    invoiceSuccessfulEmailError:
      "Bòdwo ki te kreye avèk siksè men yon erè te rive pandan y ap voye kòm e-mail. Tanpri eseye ankò nan lis fakti a",
    invoiceSentToWhatsapp: "Facture voye WhatsApp",
    hello: "Bonjou",
    pleaseDownloadInvoice: "Tanpri telechaje fakti",
    pleaseDownloadReceipt: "Tanpri telechaje resi",
    from: "soti nan",
    email: "Imèl",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Ou sou yon plan gratis.",
    clickOn: "Klike sou",
    yourPlanInFewSteps: " abònman ou nan kèk etap rapid.",
    to: "a",
    yourSubscriptionHasExpired:
      "Abònman ou ekspire epi kont ou an kounye a limite.",
    days: "jou",
    yourSubscriptionExpiresIn: "Abònman Loystar ou ekspire nan",
    createAcount: "Kreye yon kont",
    signIn: "Konekte",
    continue: "Kontinye",
    enterOtp: "Mete PIN OTP",
    enterValidOtp: "Tanpri antre yon PIN valab",
    pin: "PIN",
    tokenSentToMail: "Yon siy te voye nan imèl ou",
    passwordResetSuccessful: "Reyajiste modpas la te reyisi",
    somethingWentWrong: "Yon bagay ale mal!",
    resetPassword: "Reyajiste modpas",
    iHaveResetCode: "Mwen gen yon kòd Reyajiste modpas",
    pleaseEnterEmail: "Tanpri antre imel ou",
    aTokenWillBeSentToEmail: "Yon siy ta voye nan imèl ou",
    enterEmail: "Antre nan imèl ou",
    sendinYourToken: "Voye siy ou ...",
    makeSureItMatchesPassword: "Asire w ke li matche ak nouvo modpas ou",
    pleaseChooseNewPassword: "Tanpri chwazi yon nouvo modpas",
    chooseNewPassword: "Chwazi yon nouvo modpas",
    enterNewPassword: "Antre nouvo modpas ou a konfime",
    enterTokenSent: "Antre siy la ki te voye lapòs ou",
    resetToken: "Reyajiste Jeton",
    resettingPassword: "Reyajiste modpas ou ...",
    signUp: "Enskri",
    adminSignInWithEmail:
      " Admin siyen an ak imèl pandan y ap Anplwaye siyen an ak non itilizatè.",
    pleaseEnterEmailOrUsername: "Tanpri antre imèl ou oswa non itilizatè",
    emailOrUsername: "Imèl oswa non itilizatè",
    pleaseEnterPassword: "Tanpri antre modpas",
    createAnAccount: "Kreye yon kont",
    forgotPassword: "Bliye modpas?",
    enterPhoneNumber: "Antre Nimewo Telefòn",
    personalData: "Done pèsonèl",
    validateConfirmationCOde: "Valide Kòd Konfimasyon",
    pleaseEnterFirstName: "Tanpri antre premye non ou",
    pleaseEnterPhoneNumber: "Tanpri antre nimewo telefòn ou",
    pleaseEnterLastName: "Tanpri antre non ou",
    pleaseEnterBusinessName: "Tanpri antre non biznis ou",
    firstName: "Premye Non",
    lastName: "Siyati",
    businessName: "Non Biznis",
    previous: "Previous",
    next: "Next",
    pleaseSelectBusinessCategory: "Tanpri chwazi kategori biznis ou",
    pleaseEnterValidEmail: "Tanpri antre yon imèl valab",
    pleaseEnterPostCode: "Tanpri antre kòd postal",
    postCode: "Post Postal",
    phoneNumberInUse: "Nimewo telefòn sa a deja itilize!",
    emailInUse: "Imèl sa a deja itilize!",
    foodAndDrinks: "Manje ak bwason",
    salonAndBeauty: "Salon ak Bote",
    fashionAndAccessories: "Mòd ak Pwodwi pou Telefòn",
    gymAndFitness: "Gym ak Fòm",
    travelAndHotel: "Vwayaje ak Hotel",
    homeAndGifts: "Kay ak kado",
    washingAndCleaning: "Lave ak netwayaj",
    gadgetsAndElectronics: "Gadjèt ak Elektwonik",
    groceries: "Episri",
    others: "Lòt moun",
    submit: "Soumèt",
    accountCreatedSuccessful: "Kont ou te kreye avèk siksè.",
    pleaseEnterAddress: "Tanpri antre adrès ou",
    addressLine1: "Adrès Liy 1",
    addressLine2: "Adrès Liy 2",
    choosePassword: "Chwazi Modpas",
    passwordMustBe6Characters: "Modpas dwe omwen 6 karaktè.",
    howDidYouHearLoystar: "Ki jan ou te tande pale de Loystar?",
    referralCode: "referans Kòd",
    byClickingTheButton: " Lè ou klike sou bouton ki anba a, ou dakò ak",
    termsAndSevice: "Tèm yo",
    wereCreatingAccount: "Nou ap kreye kont ou",
    proceed: "Kontinye",
    verificationCodeMustBe6: "Kòd Verifikasyon dwe gen 6 chif",
    pleaseEnter6DigitCode: "Tanpri Antre kòd 6 chif",
    enterVerificationCode: "Antre kōd Verifikasyon an",
    resendToken: "Retounen Jeton",
    verify: "Verifye",
    transactions: "Tranzaksyon yo",
    todaySales: "Komèsyal jodi a",
    salesHistory: "Komèsyal Istwa",
    supplyHistory: "Istwa Pwovizyon pou",
    new: "Nouvo",
    invoices: "Factures",
    disbursements: "Debousman",
    offlineSales: "Komèsyal offline",
    products: "Pwodwi",
    customers: "Kliyan",
    multiLevelLoyalty: "Multi-Nivo lwayote",
    loyaltyPrograms: "Pwogram lwayote",
    members: "Manm yo",
    appStore: "App Store",
    orderMenu: "Menu Lòd",
    settings: "Anviwònman",
    staffAndBranches: "Baton ak branch",
    myAccount: "Kont mwen",
    switchToSellMode: "Chanje nan Mode Vann",
    signOut: "Dekonekte",
    getFreeSubscription: "Jwenn Abònman gratis",
    onlyNumbersAllowed: "Se sèlman nimewo yo pèmèt",
    yourAccountMustBe10Digits: "nimewo kont ou dwe yon nimewo 10 chif",
    yourBvnMustBe11: "BVN ou dwe yon nimewo 11 chif",
    pleaseSelectBank: "Tanpri chwazi bank ou",
    selectYourBank: "Chwazi Bank ou",
    enterBankAccountNumber: "Mete nimewo kont labank",
    enterBvnNumber: "Antre nan BVN ou",
    connectBankAccount: "Konekte kont labank",
    passwordResetSuccessfulAndSignOut:
      "Modpas ou te Reyajiste avèk siksè. Klike sou bouton an soti anba a siyen ankò",
    enterCurrentPassword: "Mete modpas aktyèl la",
    pleaseEnterCurrentPassword: "Tanpri antre modpas aktyèl la",
    phoneNumber: "Nimewo telefòn",
    sex: "Sèks",
    dateOfBirth: "Dat nesans",
    state: "Leta",
    country: "Peyi",
    loyaltyId: "ID lwayote",
    createdAt: "Kreye nan",
    noOfTransactionsMade: "Nimewo tranzaksyon yo te fè",
    yourDownloadWillStart: "Download ou a ap kòmanse nan yon moman",
    exportCustomers: "Ekspòtasyon Kliyan",
    youhaveSuccessfullyToppedUp: "Ou avèk siksè antèt Inite SMS ou yo.",
    youNowHave: "Ou gen kounye a",
    smsUnits: "SMS Inite",
    enterNumberOfSmsUnits: "Antre Kantite Inite SMS ou gen entansyon achte",
    pleaseEnterANumericValue: "Tanpri antre yon valè nimerik",
    pay: "Peye",
    accountEditedSuccessfully: "Kont ou te edite avèk siksè.",
    youAeCurrentlyOn: "Ou ap kounye a sou",
    plan: "Plan",
    userData: "Done Itilizatè",
    businessData: "DONE BIZNIS",
    businessCategory: "Biznis Catergory",
    pleaseSelectCurrency: "Tanpri chwazi lajan ou",
    selectYourCurrency: "Chwazi lajan ou",
    purchaseMoreSmsUnits: "Achte plis SMS Inite lè l sèvi avèk fòm ki anba a",
    youHave: "Ou genyen",
    atLeast4SmsUnitsRrequired:
      "Omwen 4 sms inite yo obligatwa pou verifikasyon, tanpri ranpli!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Tanpri verifye kont labank ou pou konfòme li avèk kondisyon 'Konnen Kliyan ou' (KYC). Sa a pral pèmèt ou kolekte peman atravè USSD oswa transfè enstantane, resevwa lòd nan men kliyan ak pwosesis règleman tranzaksyon yo. Aplikab a machann Nijeryen sèlman. Tanpri peze bouton ki anba a pou kòmanse.",
    reConnectBankAccount: "Re-konekte kont labank",
    accountName: "Non Kont",
    accountNumber: "Nimewo KONT",
    bankName: "Non Bank",
    verified: "Verifye",
    accountDetails: "Detay Kont",
    kycBankAccount: "KYC",
    createTier: "Kreye Tier",
    fileUploadSuccessful: "dosye telechaje avèk siksè",
    fileUploadFailed: "Upload dosye echwe",
    createLoyaltyProgram: "Kreye yon pwogram lwayote",
    createLoyalty: "Kreye lwayote",
    name: "Non",
    loyaltyArtwork: "Atizay lwayote",
    clickToUpload: "Klike pou telechaje",
    amountToPointsRatio: "Kantite Pwen Pwopòsyon",
    points: "Pwen",
    recommendedAmountToPointRatio:
      "Rekòmande: ₦ 1 a 1point. sa vle di pou chak 1 Naira depanse, kliyan ou yo jwenn 1 pwen",
    pleaseTypeIn: "Tanpri tape",
    toDeleteLoyalty: "efase lwayote sa a",
    deleteLoyalty: "Efase lwayote",
    toConfirm: "konfime",
    edit: "Edite",
    pointsAwardedSuccessfully: "Pwen yo bay avèk siksè.",
    enterPointValueToAward: "Mete Valè Pwen Ou ta renmen prim Kliyan yo",
    award: "Prim",
    awardPointValuesToCustomer: "Valè Pwen Prim pou Kliyan",
    enrollMembersToLoyalty: "Enskri Manm yo sou Lwayote",
    awardPoints: "Pwen Prim",
    enroll: "Enskri",
    home: "Lakay",
    loyalty: "Lwayote",
    enrollCustomers: "Enskri Kliyan yo",
    selected: "Chwazi",
    customer: "Kliyan",
    loyaltyActivationSuccessful: "Aktivite lwayote reyisi.",
    loyaltyDeactivationSuccessful: "Dezaktivasyon lwayote a reyisi.",
    viewTier: "View Nivo",
    deactivate: "De-Aktive",
    activate: "Aktive",
    actions: "Aksyon",
    nameOfLoyalty: "Non lwayote",
    loyaltyStatus: "Estati lwayote Estati lwayote",
    numberOfTiers: "Kantite Nivo",
    createdOn: "Kreye sou",
    createATier: "Kreye yon Tier",
    stopCreatingTierConfirmation: "Èske ou vle sispann kreye yon niveau?",
    stopEditingTierConfirmation: "Èske ou vle sispann koreksyon sa a?",
    nameOfTier: "Non Tier",
    minimumSpendingTarget: "Sib minimòm depans",
    maximumSpendingTarget: "Sib maksimòm depans",
    minimumSpendingTargetRequired: "sib depans minimòm obligatwa",
    maximumSpendingTargetRequired: "sib depans maksimòm obligatwa",
    rewardSponsor: "Rekonpanse Sponsor",
    pleaseChooseARewardSponsor: "Tanpri chwazi yon parenn rekonpans",
    self: "Oto",
    partner: "Patnè",
    rewardPartner: "Patnè rekonpans",
    pleaseSelectRewardPartner: "Tanpri chwazi patnè rekonpans ou",
    rewards: "Rekonpans",
    pleaseSelectAReward: "Tanpri chwazi yon rekonpans",
    instructionsOnRedeemingReward:
      "Enstriksyon sou Kijan Kliyan Dwe Rachte Rekonpans",
    pleaseFillInThisField: "Tanpri ranpli jaden sa a!",
    toDeleteThisTier: " efase nivo sa a",
    deleteTier: "Efase Nivo",
    viewMembers: "Gade Manm yo",
    membersEnrolled: "Manm enskri",
    instruction: "Enstriksyon",
    membersIn: "Manm nan",
    availableTiersInLoyalty: "Disponib Nivo (yo) nan Pwogram Lwayote",
    tiers: "Nivo",
    totalTier: "TOTAL NIVO",
    availableLoyaltyProgramme: "Pwogram lwayote ki disponib",
    totalLoyalties: "TOTAL FIDELITE",
    memberDetails: "Manm Detay",
    nameOfCustomer: "Non Kliyan an",
    address: "Adrès",
    allEnrolledMembers: "Tout Manm enskri yo",
    thisIsToWishYouHappyBirthday:
      "Sa a se vle ou yon anivèsè nesans trè kontan ak lavi gremesi. Mèsi pou tout sa ou se Loyster. Bòn fèt!",
    inputAnOfferPlease: "Antre yon òf tanpri",
    pleaseSelectTheInsertPoint:
      "Tanpri chwazi pwen an insert nan mesaj la, epi klike ankò",
    birthdayOfferAndMessage: "Ofri anivèsè nesans ak mesaj",
    birthdayOffer: "Ofri anivèsè nesans",
    birthdayMessage: "Mesaj Anivèsè",
    noOfferFound: "Pa jwenn okenn òf",
    settingABirthdayOffer:
      "Mete yon òf anivèsè nesans pèmèt kliyan yo resevwa òf sa a via SMS sou anivèsè nesans yo",
    createOffer: "Kreye Ofri",
    whatIsTheOffer: "Ki òf la?",
    editMessage: "Edit mesaj",
    insert: "Mete",
    theNameOfCustomerInserted: "Non kliyan an ap antre isit la",
    theBirtdayOfferInserted: "Ofri a anivèsè nesans yo pral eleman isit la",
    youSuccessfullyAddedNewBranch: "Ou te ajoute yon nouvo branch avèk siksè!",
    addNewBranch: "Add Nouvo Branch",
    addBranch: "Add Branch",
    additionalBranchWillIncur: "Lòt Branch ta antrene",
    perBranch: "pou chak branch",
    ecommerceBranchCosts: "Depans èkomèrs Branch",
    pleaseEnterBranchName: "Tanpri antre non branch lan",
    pleaseEnterBranchAddress1: "Tanpri antre liy adrès branch lan 1",
    enterBranchAddress1: "Mete liy adrès branch lan 1",
    enterBranchAddress2: "Mete liy adrès branch lan 1",
    pleaseEnterBranchAddress2: "Tanpri antre adrès liy branch lan 2",
    enterBranchName: "Mete non branch lan",
    successfullyAddedStaff: "Ou te avèk siksè ajoute yon nouvo anplwaye!",
    addNewStaff: "Ajoute nouvo anplwaye yo",
    addStaff: "Add Anplwaye",
    additionalStaffWillIncur: "Anplwaye Lòt ta antrene",
    perStaff: "pou chak anplwaye.",
    pleaseEnterStaffEmail: "Tanpri antre imèl anplwaye a",
    pleaseEnterStaffUsername: "Tanpri antre non itilizatè anplwaye a",
    pleaseEnterStaffPassword: "Tanpri antre modpas anplwaye a",
    pleaseSelectStaffRole: "Tanpri chwazi wòl anplwaye a",
    selectStaffRole: "Chwazi wòl anplwaye a",
    enterStaffEmail: "Antre nan imèl anplwaye a",
    enterStaffUsername: "Antre non itilizatè anplwaye a",
    enterStaffPassword: "Antre modpas anplwaye a",
    spacesNotAllowedInUsername: "espas pa pèmèt nan non itilizatè",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Tanpri chwazi biznis pou atache anplwaye yo",
    searchForBranchToAttachStaff: "Rechèch pou branch yo tache anplwaye yo",
    username: "Non itilizatè",
    role: "Wòl",
    areYouSureToDeleteThis: "Èske ou asire w ke ou efase sa a",
    branches: "Branch",
    upgradeYourPlan: "Mete ajou Plan ou.",
    add: "ADD",
    addNew: "Ajoute nouvo",
    customerWithEmailAlreadyExists:
      "Kliyan ki gen imèl / nimewo telefòn deja egziste!",
    successfullyAddedNewCustomer: "Ou te avèk siksè ajoute yon nouvo kliyan!",
    addCustomer: "Add Kliyan",
    pleaseEnterCustomerFirstName: "Tanpri antre premye non kliyan an",
    pleaseEnterCustomerLastName: "Tanpri antre nan non kliyan an",
    pleaseEnterCustomerPhoneNumber: "Tanpri antre nimewo telefòn kliyan an",
    pleaseEnterCustomerEmail: "Tanpri antre imèl kliyan an",
    pleaseEnterCustomerAddressLine: "Tanpri antre Liy Adrès kliyan an",
    pleaseEnterCustomerOtherAddress: "Tanpri antre lòt adrès kliyan an",
    pleaseSelectCustomerGender: "Tanpri chwazi sèks kliyan an",
    gender: "Sèks",
    male: "Gason",
    female: "Fi",
    bank: "Bank",
    selectBank: "Chwazi Bank",
    stateOrRegionOrProvince: "Eta / Rejyon / Pwovens",
    customerNotes: "Nòt Kliyan",
    sendSms: "Voye SMS",
    editCustomer: "Edit Kliyan",
    redeemReward: "Delivre Rekonpans",
    issueLoyaltyCard: "Nimewo Kat lwayote",
    deleteCustomer: "Efase Kliyan",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ou te avèk siksè asiyen yon ID Manm lwayote",
    noMembershipIdAvailable:
      "Pa gen ID manm ki disponib. Tanpri kontakte nou sou hello@loystar.co",
    sendEmail: "Voye Imèl",
    membershipPoints: "Pwen manm yo",
    customerDetails: "Detay Kliyan",
    close: "Fèmen",
    loyaltyBalance: "Balans lwayote",
    pointsBalance: "Pwen Balans",
    starBalance: "Star Balans",
    requiredPoints: "Pwen obligatwa",
    requiredStars: "Zetwal Obligatwa",
    reddemCode: "Delivre Kòd",
    toDeleteThisCustomer: "efase kliyan sa a",
    customerHasBeenDeletedSuccessfully: "Kliyan an te efase avèk siksè!",
    customerWithPhoneAlreadyExists: "Kliyan ki gen nimewo telefòn deja egziste",
    customerWasSuccessfullyEdited: "Kliyan te avèk siksè modifye",
    anErrorOccured: "Yon erè te fèt",
    phoneNumberIncludeCountry: "Nimewo Telefòn (Mete Kòd Peyi)",
    yourFileQueuedForUpload:
      "Fichye ou te file pou telechaje. Tanpri rafrechi paj la apre kèk segond.",
    thereWasAnErrorPerformingOperation: "Te gen yon erè nan fè operasyon an!",
    pleaseSelectFile: "Tanpri chwazi yon dosye!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Se sèlman dosye CSV pèmèt. Tanpri telechaje yon dosye .csv.",
    fileShouldBeSmallerThan5Mb:
      "Dosye yo ta dwe pi piti pase 5MB. Si ou gen yon dosye pi gwo, tanpri imèl support@loystar.co. Mèsi",
    customerFirstNameIsRequired: "premye non kliyan yo mande sou ranje",
    customerPhoneNumberIsRequired: "nimewo telefòn kliyan yo mande sou ranje",
    importCustomers: "Enpòte Kliyan",
    upload: "Voye",
    clickIconToDownloadCsv:
      "Klike sou ikòn sa a pou telechaje modèl dosye CSV la.",
    getGoogleSheetVersion: "Jwenn vèsyon fèy Google la",
    clickOrDragFileToUpload:
      "Klike oswa trennen dosye nan zòn sa a pou telechaje",
    missingOrInvalidColumnHeader:
      "Tèt kolòn ki manke oswa ki pa valab. Tanpri swiv fòma modèl la. Mèsi.",
    youHaveImported: "Ou Enpòte",
    youSuccessfullyRedeemedReward: "Ou te avèk siksè delivre rekonpans ou!",
    sixDigitCode: "Kòd sis chif",
    pleaseEnterCustomerRewardCode: "Tanpri antre kòd rekonpans kliyan an",
    enterRewardCode:
      "Antre kòd rekonpans lan. (Kòd rekonpans lan ka sansib a ka)",
    selectLoyaltyProgram: "Chwazi pwogram lwayote",
    stamps: "Koupon pou Achte",
    smsUnitsLow: "SMS Inite ki ba",
    pleaseTopUpSmsUnits: "Tanpri Top Inite SMS",
    smsSuccessfullySentForDelivery: "SMS voye avèk siksè pou livrezon!",
    sendSmsTo: "Voye SMS bay",
    allCustomers: "Tout Kliyan",
    unitsAvailable: "Inite ki disponib",
    pleaseTypeInTheMessage: "Tanpri tape mesaj la",
    message: "Mesaj",
    charactersRemaining: "karaktè ki rete",
    avoidUseOfSpecialCharacters:
      "Evite itilize karaktè espesyal ak emoji pou konsève inite SMS yo.",
    note: "Remak",
    errorFetchingCustomersMultilevelDetail:
      "Erè chache kliyan MultiLevel Detay",
    search: "Rechèch",
    reset: "Reyajiste",
    importCustomer: "Enpòte Kliyan",
    addNewCustomer: "Ajoute nouvo kliyan",
    sendSmsBroadcast: "Voye emisyon SMS",
    totalCustomers: "TOTAL KLIYAN",
    disbursementDetails: "Detay debousman",
    paidBy: "Peye pa",
    disbursed: "Debouse",
    bankAccountName: "Non kont labank",
    bankAccountNumber: "Nimewo kont labank",
    transferInitiated: "Transfè Inisye",
    transferCompleted: "Transfè Konplete",
    pleaseEnterAValid: "Tanpri antre yon valab",
    begin: "kòmanse",
    end: "fini",
    date: "dat",
    paymentDate: "Dat Peman",
    selectDisbursementDuration: "Chwazi Durée Déboursement",
    totalSettled: "Total rete",
    totalUnsettled: "Total instabilize",
    toDeleteThisSale: "efase vant sa a",
    draftSaleDeletedSuccessfully: "Draft Sale te efase avèk siksè!",
    deleteSale: "Efase Vann",
    pleaseTypeInYourTotal: "Tanpri tape total ou pou konfime sipresyon an",
    billDetails: "Detay Bill",
    printBill: "Ekri an lèt detache Bill",
    servedBy: "Sèvi pa",
    total: "Total",
    createdDate: "Dat ki te kreye",
    createdTime: "Tan kreye",
    status: "Estati",
    loginSuccessful: "Login siksè",
    pleaseWaitWhileWeConnectAccount:
      "Tanpri rete tann pandan nou konekte kont ou",
    connectionFailedTryAgain: "Koneksyon echwe. Tanpri eseye ankò.",
    connectionSuccessful: "Koneksyon siksè",
    viewDetails: "View Detay",
    enable: "Pèmèt",
    free: "Gratis",
    cost: "Pri",
    visitWebsite: "Vizite Sit wèb",
    pleaseUpgradeYourPlanToPro:
      "Tanpri ajou plan ou a Pro Plus yo ki ap pèmèt app sa a",
    connectYourBankAccount:
      "Konekte Kont labank ou pou kapab resevwa peman yo.",
    disable: "Enfim",
    enableApp: "Pèmèt App",
    addNewProductToInvoice: "Ajoute nouvo pwodwi nan fakti",
    toDeleteThisInvoice: "efase fakti sa a",
    invoiceDeletedSuccessfully: "Factur te efase avèk siksè!",
    deleteInvoice: "Efase fakti",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ou te avèk siksè ajoute yon mesaj peman fakti!",
    pleaseEnterPaymentInstructions: "Tanpri antre enstriksyon peman",
    invoiceId: "ID fakti",
    paidDate: "Dat Peye",
    reference: "Referans",
    productAdded: "Pwodwi ajoute",
    productOutOfStock: "Pwodwi soti nan stock. Tanpri re-stock.",
    totalInvoices: "FACTURES TOTAL",
    totalPaidInvoices: "TOTAL FAKTÈ PEYE",
    totalUnpaidInvoices: "TOTAL FAKTI PA Peye",
    loyaltyProgramDeleted: "Pwogram lwayote efase",
    thisLoyaltyProgramDeletedSuccessfully:
      "Pwogram lwayote sa a efase avèk siksè",
    thisLoyaltyProgramEditedSuccessfully:
      "Pwogram lwayote sa a te modifye avèk siksè",
    loyaltyProgramAddedSuccessfully: "Pwogram lwayote te ajoute avèk siksè",
    loyaltyProgramEdited: "Pwogram lwayote modifye",
    loyaltyProgramAdded: "Pwogram lwayote te ajoute",
    loyaltyDetails: "Detay lwayote",
    doYouWantToCloseDialog: "Èske ou vle fèmen dyalòg sa a?",
    pleaseEnterLoyaltyName: "Tanpri antre non fidelite ou",
    programType: "Kalite Pwogram",
    pleaseSelectProgramType: "Tanpri chwazi yon kalite pwogram",
    simplePoints: "Pwen senp",
    stampsProgram: "Pwogram Koupon pou Achte",
    threshold: "Limit",
    pleaseEnterLoyaltyThreshold: "Tanpri antre papòt lwayote a",
    reward: "Rekonpans",
    pleaseEnterLoyaltyReward: "Tanpri antre rekonpans la nan lwayote a",
    totalUserPoints: "Pwen Itilizatè Total",
    totalUserStamps: "Koupon pou Itilizatè Total",
    spendingTarget: "Depans sib",
    spendingTargetHint1:
      "Depans sib se konbyen yon kliyan dwe depanse pou jwenn rekonpans lan. 1 valè lajan = 1 pwen.",
    spendingTargetHint2:
      "Koupon pou Achte sib se konbyen koupon pou yon kliyan dwe kolekte yo touche rekonpans lan. egzanp. 5",
    addNewLoyaltyProgram: "Ajoute nouvo pwogram lwayote",
    addLoyaltyProgram: "Ajoute Pwogram lwayote",
    loyaltyProgramType: "Kalite Pwogram lwayote",
    pleaseSelectLoyaltyProgramType: "Tanpri chwazi kalite pwogram lwayote",
    nameOfProgram: "Non pwogram lan",
    pleaseEnterProgramName: "Tanpri antre non pwogram lan",
    whatIsTheReward: "Ki rekonpans lan?",
    egNextPurchaseIsFree: "Eg. Pwochen acha a gratis",
    customerName: "Non Kliyan",
    guestCustomer: "Guest Kliyan",
    orderConfirmedSuccessfully: "Lòd konfime avèk siksè",
    orderCancelledSuccessfully: "Lòd anile avèk siksè",
    confirmOrder: "Konfime lòd",
    cancelOrder: "Anile lòd",
    allOrders: "Tout lòd",
    totalOrders: "L ORD TOTAL",
    doYouWantToAcceptOrder: "Èske ou vle aksepte lòd sa a?",
    doYouWantToCancelOrder: "Èske ou vle anile lòd sa a?",
    orderDetails: "Lòd Detay",
    orderCreatedAt: "Lòd ki te kreye nan",
    supplier: "Founisè",
    productName: "Non pwodwi",
    quantity: "Kantite",
    unitPrice: "Pri Inite",
    receivedBy: "Resevwa pa",
    reportFrom: "Rapò soti nan",
    totalSupplies: "Pwodwi pou Total",
    allRightsReserved: "Tout dwa rezève",
    toDeleteThisTransaction: "efase Tranzaksyon sa a",
    transactionDeletedSuccessfully:
      "Tranzaksyon te efase avèk siksè. Stock te retounen nan envantè.",
    deleteTransaction: "Efase Tranzaksyon",
    transactionDetails: "Detay tranzaksyon yo",
    printReceipt: "Ekri an lèt detache Resi",
    channel: "Chèn",
    discount: "Rabè",
    profit: "Pwofi",
    discountedSales: "Komèsyal rabè",
    errorFetchingRecord: "Erè chache dosye",
    exportTransactions: "Transaksyon ekspòtasyon",
    errorFetchingSalesRecord: "Erè chache dosye Komèsyal pou ekspòtasyon.",
    totalSellingPrice: "Pri Vann Total",
    totalCostPrice: "Pri Pri Total",
    appliedDiscount: "Aplike rabè",
    noOfItems: "Nimewo nan atik yo",
    sales: "Komèsyal",
    export: "Ekspòtasyon",
    totalProfit: "Total pwofi",
    totalBalanceInPeriod: "Total Balans nan Peryòd",
    allTimeSales: "Tout tan Komèsyal",
    records: "Dosye",
    todaysSales: "Komèsyal jodi a",
    transaction: "tranzaksyon",
    youHaveExceededTotalNumberOfProducts:
      "Ou depase kantite total pwodwi ki pèmèt sou plan ou an. Mete ajou plan ou a jwi yon pi wo yon limit.",
    youNeedToHaveLoyaltyProgram:
      "Ou bezwen gen yon pwogram lwayote yo sèvi ak karakteristik sa a!",
    price: "Pri",
    category: "Kategori",
    stockTracking: "Suivi Stock",
    stockCount: "Stock Konte",
    taxed: "Taks",
    originalPrice: "Pri orijinal la",
    costPrice: "Pri Pri",
    unit: "Inite",
    productImage: "Pwodwi Imaj",
    taxRate: "To taks",
    taxType: "Kalite taks",
    trackInventory: "Swiv Envantè",
    variants: "Variantes",
    hasVariants: "Gen Variantes",
    importProduct: "Enpòte pwodwi",
    exportProducts: "Pwodwi ekspòtasyon",
    addNewProduct: "Ajoute nouvo pwodwi",
    viewCategory: "View Kategori",
    viewSuppliers: "View Founisè",
    receiveInventory: "Resevwa envantè",
    printAllProductsTag: "Enprime tout pwodwi Tag",
    deleteAll: "Efase tout",
    totalProducts: "TOTAL PWODWI",
    youveSuccessfullyAddedANewCategory:
      "Ou te ajoute yon nouvo kategori avèk siksè",
    addNewCategory: "Ajoute nouvo kategori",
    addCategory: "Ajoute Kategori",
    categoryName: "Kategori Non",
    pleaseEnterCategoryName: "Tanpri antre non kategori a",
    stampsTarget: "Koupon pou Achte Sib",
    sendInventory: "Voye envantè",
    productDetails: "Pwodwi Detay",
    youveSuccessfullyEditedThisCategory: "Ou te edite avèk siksè kategori sa a",
    update: "Mizajou",
    categoryList: "Lis Kategori",
    categories: "Kategori",
    enterQuantityToUpdate: "Mete yon kantite aktyalizasyon",
    inventorySentSuccessfully: "Envantè a te voye avèk siksè!",
    updateInventory: "Mizajou Envantè",
    currentQuantity: "Kantite Kouran",
    pleaseEnterQuantityToAdd: "Tanpri antre kantite ou vle ajoute",
    pleaseSelectABranch: "Tanpri chwazi yon Branch",
    searchForBranch: "Rechèch pou branch",
    youCantSendMoreThanStock: "Ou pa ka voye plis pase sa ou genyen nan stock",
    send: "Voye",
    pleaseEnterQuantityToSend: "Tanpri antre kantite ou vle voye a",
    productNameIsRequiredOnRow: "non pwodwi obligatwa sou ranje",
    productCategoryIsRequiredOnRow: "se kategori pwodwi oblije sou ranje",
    productPriceIsRequiredOnRow: "pri pwodwi yo mande sou ranje",
    productUnitIsRequiredOnRow: "inite pwodwi obligatwa sou ranje",
    productQuantityIsRequiredOnRow: "kantite pwodwi yo mande sou ranje",
    productVariantsRequireTracking: "Variant pwodwi mande pou swiv!",
    pleaseAddVariantClickButton:
      "Tanpri ajoute yon Variant pa klike sou bouton an ajoute!",
    totalVariantsMoreThanSelectedQuantity:
      "Variantes Total yo pi plis pase kantite pwodwi chwazi, Pls diminye kantite Variant",
    productEditedSuccessfully: "Pwodwi te modifye avèk siksè!",
    fileTooLargeLessThan4Mb:
      "Gwosè dosye a twò gwo! Gwosè dosye yo ta dwe mwens pase 4MB.",
    suchVariantAlreadyExist: "Variant sa yo deja egziste",
    addVariants: "Ajoute Variants",
    editProduct: "Edite pwodwi",
    pleaseEnterProductName: "Tanpri antre non pwodwi",
    pleaseEnterProductPrice: "Tanpri Mete Pri Product",
    pleaseEnterProductOriginalPrice: "Tanpri antre pwodwi orijinal pri",
    productDescription: "Deskripsyon pwodwi",
    selectProductCategory: "Chwazi Kategori Pwodwi",
    pleaseSelectProductCategory: "Tanpri chwazi kategori pwodwi",
    productCostPrice: "Pri Pri pwodwi",
    productSellingPrice: "Pwodwi vann pri",
    productOriginalPrice: "Pwodwi Original Pri",
    maxFileSizeAllowedIs4Mb: "Maks. Gwosè dosye pèmèt se 4mb",
    productsWithPicturesArePublished:
      "Pwodwi ki gen foto yo pibliye sou Dekouvri pou resevwa lòd",
    shouldThisProductBeTracked: "Èske yo ta dwe swiv pwodwi sa a?",
    pleaseSelectStockUnit: "Tanpri chwazi inite stock",
    stockUnit: "Inite Stock",
    bag: "SAC",
    bottle: "BOUTÈY",
    bunch: "Pakèt",
    can: "KAPAB",
    carton: "KATON",
    crate: "KESYON",
    cup: "GODE",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRE",
    pack: "PAKÈ",
    pair: "PÈ",
    pieces: "MOSES",
    plate: "PLAK",
    tonne: "TONNE (MT)",
    uNIT: "INITE",
    yard: "LAKOU",
    pleaseEnterProductQuantity: "Tanpri antre kantite pwodwi",
    productQuantity: "Kantite pwodwi",
    isThisProductTaxed: "Èske pwodwi sa a papye ekri?",
    selectTaxType: "Chwazi Kalite Taks",
    pleaseSelectTaxType: "Tanpri chwazi Kalite taks",
    progressive: "Pwogresis",
    proportional: "Pwopòsyonèl",
    pleaseEnterProductTaxRate: "Tanpri antre pousantaj taks sou pwodwi",
    doesProductHaveVariants: "Èske pwodui sa a gen variantes?",
    type: "Kalite",
    value: "Valè",
    pleaseEnterVariantType: "Tanpri antre nan kalite Variant la",
    pleaseEnterVariantValue: "Tanpri antre valè Variant la",
    pleaseEnterVariantPrice: "Tanpri antre nan pri Variant la",
    pleaseEnterVariantQuantity: "Tanpri antre kantite Variant la",
    productDeletedSuccessfully: "Pwodwi te efase avèk siksè!",
    categoryDeletedSuccessfully: "Kategori efase avèk siksè!",
    toDeleteThisProduct: "efase pwodui sa a",
    invalidProductQuantity: "Kantite pwodwi ki pa valab",
    quantityAddedIsOverStock:
      "Kantite w ap ajoute a se sou sa ou genyen nan stock.",
    itemInventoryNotTracked: "Atik envantè pa swiv",
    addBulkQuantity: "Add Kantite esansyèl",
    enterBulkQuantity: "Mete Kantite Bulk",
    pleaseEnterBulkQuantity: "Tanpri antre Kantite Bulk",
    youveSuccessfullyAddedANewProduct:
      "Ou te avèk siksè ajoute yon nouvo pwodwi!",
    pleaseEnterProductSellingPrice: "Tanpri antre pwodwi vann pri",
    doYouWantToTrackProductStock: "Èske ou vle swiv stock pwodwi?",
    sellingPrice: "Vann Pri",
    setProductExpiryReminder: "Mete pwodwi ekspirasyon rapèl",
    productExpiryDate: "Pwodwi dat ekspirasyon",
    startRemindingFrom: "Kòmanse Raple Soti nan",
    productSuppliesAddedSuccessfully: "Ou te ajoute Pwodwi Pwodwi avèk siksè.",
    addProductSupplies: "Ajoute Pwodwi Pwodwi",
    pleaseSelectSupplier: "Tanpri Chwazi Founisè",
    nameOfProduct: "Non pwodwi",
    pleaseSelectProduct: "Tanpri chwazi pwodwi",
    productVariant: "Variant pwodwi",
    pleaseSelectAVariant: "Tanpri Chwazi Yon Variant",
    pleaseEnterUnitPrice: "Tanpri Mete Pri Inite",
    businessBranch: "Branch biznis",
    pleaseSelectBranch: "Tanpri chwazi branch",
    youveSuccessfullyAddedANewSupplier:
      "Ou te avèk siksè ajoute yon nouvo founisè",
    addSupplier: "Add Founisè",
    pleaseEnterSupplierEmail: "Tanpri antre imèl founisè a",
    pleaseAddADescription: "Tanpri ajoute yon deskripsyon",
    anErrorOccuredProductsDeleted:
      "Yon erè ki te fèt pandan y ap fè operasyon an. Tanpri pran nòt, kèk pwodwi ta ka vinn efase nan pwosesis la",
    bulkDelete: "Efase esansyèl",
    youAreAboutToDelete: "Ou se sou efase",
    product: "Pwodwi",
    isDueToRedeem: "se akòz delivre",
    aReward: "yon rekonpans",
    pleaseSelectCustomerToReeemReward:
      "Tanpri chwazi yon kliyan vin delivre rekonpans.",
    youHaveNoLoyaltyProgramRunning:
      "Ou pa gen okenn pwogram lwayote aktif kouri",
    customerHhasNoPointsInLoyaltyProgram:
      "Kliyan pa gen okenn pwen nan pwogram lwayote sa a",
    proceedWithPayment: "Kontinye ak Peman?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ou se sou pou peye pou tranzaksyon an lè l sèvi avèk pwen.",
    customerHas: "Kliyan Gen",
    worth: "vo",
    andIsNotSufficientToPayForTransaction:
      "epi li pa ase pou peye pou tranzaksyon sa a. Èske yo ta vle ajoute balans lan lè l sèvi avèk yon lòt metòd peman?",
    addCustomerLoyalty: "Add Lwayote Kliyan",
    pleaseAddCustomerFirst: "Tanpri ajoute oswa chwazi yon kliyan an premye.",
    pleaseWaitWhileWeGetReady: "Tanpri rete tann, pandan ke nou pare",
    lowStock: "Low Stock",
    pleaseEnterAmountTendered: "Tanpri antre kantite lajan ki nan òf la",
    areYouSureToBookSaleOffline: "Èske ou sèten ou vle liv sa a offline?",
    saleWouldBeBookedAutomatically:
      "Vann ta dwe rezerve otomatikman lè lè ou vire sou entènèt ou an",
    offlineSalesBookingCancelled: "Offline anrjistreman lavant anile",
    covid19Message:
      "COVID19: Lave men ou avèk savon oswa Sanitize yo sispann gaye la. Rete an sekirite toujou",
    saleSuccessfullyRecorded: "Vann avèk siksè anrejistre!",
    sendReceiptToEmail: "Voye resi nan Imèl",
    sendThankYouSms: "Voye SMS mèsi",
    sendShippingDetails: "Voye detay Shipping",
    addLoyalty: "Ajoute lwayote",
    searchCustomerNameOrNumber: "Search non kliyan oswa nimewo",
    clickTheSearchCustomerBox: "Klike sou bwat rechèch kliyan an ak kat Scan",
    enterProductPrice: "Antre nan pri pwodwi",
    enterPriceFor: "Mete Pri pou",
    searchForProduct: "Rechèch pou pwodwi",
    all: "Tout",
    backToDashboard: "Retounen nan tablodbò",
    viewDraftSales: "View Draft Komèsyal",
    variantSelected: "Variant chwazi",
    variant: "Variant",
    thePreviousVariantSelectionNotAvailable:
      "Seleksyon Variant anvan an pa disponib pou nouvo Variant la chwazi ki baze sou pri, tanpri chanje seleksyon ou an.",
    pleaseSupplyPositiveQuantityNumber: "Tanpri bay yon nimewo kantite postive",
    lowStockFor: "Stock ki ba pou",
    clearVariants: "Variantes klè",
    pleaseEnterQuantity: "Tanpri antre Kantite",
    picture: "Foto",
    redemptionToken: "Jeton Redanmsyon",
    token: "Jeton",
    totalSpent: "Total depanse",
    confirmPayment: "Konfime Peman",
    hasPaymentBeenMade: "Èske Peman te trete avèk siksè?",
    enterTransactionReference: "Antre referans tranzaksyon ou te peye avèk la",
    pleaseSelectACustomer: "Tanpri chwazi yon kliyan!",
    areYouSureToApplyDiscount: "Èske ou sèten ou vle aplike rabè?",
    addYourBankAccountToEnableUssd:
      "Add kont labank ou yo ki ap pèmèt Instant USSD transfè pa uPay",
    totalAmountToPay: "Kantite total pou peye",
    doYouWantToCancelTransaction: "Èske ou vle anile tranzaksyon sa a?",
    savePrintBill: "Sove / Enprime Bill",
    enterAmountCollectedForCustomer:
      "Mete kantite lajan yo kolekte pou kliyan an",
    recordSale: "Dosye Vann",
    checkOutWith: "Tcheke avèk",
    instantTransfer: "Transfè Instant",
    dialTheUSSDCode: "Rele kòd la USSD",
    pleaseSelectABank: "Tanpri chwazi yon bank",
    payWithUSSD: "Peye Avèk USSD",
    sendBillTo: " - Voye Bill nan",
    waitingForUSSDTransfer: "Ap tann pou transfè USSD",
    percent: "Pousantaj",
    applyDiscount: "Aplike rabè",
    thisBillHasBeenSaved: "Pwojè lwa sa a te sove",
    saveDraft: "Sove Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Tanpri tape yon non pou idantifye kliyan an",
    paymentDetails: "Peman Detay",
    basePrice: "Pri Sèvi",
    staff: "Anplwaye",
    subTotal: "SubTotal",
    durationMonths: "Duration (mwa)",
    selectADuration: "Chwazi yon Duration",
    oneMonth: "1 Mwa",
    twoMonths: "2 Mwa",
    threeMonths: "3 Mwa",
    sixMonths: "6 Mwa",
    twelveMonths: "12 Mwa",
    eighteenMonths: "18 mwa",
    twentyFourMonths: "24 Mwa",
    twoMonthsFree: "(2 Mwa gratis)",
    threeMonthsFree: "(3 Mwa gratis)",
    fiveMonthsFree: "(5 Mwa gratis)",
    yourAccountHasBeen: "Kont ou te",
    renewed: "renouvle",
    upgraded: "modènize",
    successfully: "avèk siksè",
    yourSubscription: "Abònman ou",
    youAreCurrentlyEnrolledOnA: "Ou se kounye a enskri sou yon",
    pleaseChooseAPaymentOption: "Tanpri Chwazi yon Opsyon Peman",
    planRenewal: "Plan Renouvèlman",
    planUpgrade: "Plan Upgrade",
    pleaseSelectDurationToPayFor:
      "Tanpri chwazi Duration ou gen entansyon pou peye pou",
    staffAccounts: "Kont anplwaye yo",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Tanpri chwazi yon plan",
    includeAddons: "Mete Add-ons",
    viewTransactions: "View Tranzaksyon yo",
    customerHasNoCompletedTansactions:
      "Kliyan an pa gen okenn tansactions ranpli ankò",
    branch: "Branch",
    enterNumberOfEcommerceBranches: "Antre nan kantite èkomèrs Branch",
    enterNumberOfEcommerceBranchesToPay:
      "Mete Kantite Branch èkomèrs ou gen entansyon peye pou",
    ecommerceIntegration: "Entegrasyon èkomèrs",
    enterNumberOfBranches: "Antre Kantite Branch",
    enterNumberOfAdditionalBranchesToPay:
      "Antre Kantite Branch Siplemantè ou gen entansyon peye pou",
    enterNumberOfStaffs: "Antre nan kantite baton",
    enterNumberOfStaffsToPayFor:
      "Antre Kantite anplwaye ou gen entansyon peye pou",
    discountApplies: "Rabè Aplike",
    starsOnThe: "zetwal sou la",
    offer: "ofri",
    get: "Jwenn",
    moreStarsToRedeem: "plis zetwal delivre",
    taxVat: "Taks (VAT)",
    callCashierToCompletePayment: "Rele Kesye pou Ranpli Peman",
    receipt: "Resi",
    dear: "Mezanmi",
    thankYouForYourOrderFindGoods:
      "Mèsi pou lòd ou yo. Tanpri jwenn machandiz sa yo apwovizyone, jan yo te dakò.",
    shippingNote: "Remak Shipping",
    enterShippingNote: "Antre nan Shipping Remak",
    shippingAddress: "Adres livrezon",
    enterShippingAddress: "Mete adrès Shipping",
    wellDoneYouAreDueToRedeem: "Byen fè! Ou dwe delivre",
    toGetYourRewardNextVisit:
      "pou jwenn rekonpans ou nan pwochen vizit ou. Mèsi",
    pointsOnThe: "Pwen sou la",
    morePointsToRedeem: "plis pwen vin delivre",
    showCode: "Montre kòd",
    toGetYourRewardOnNextVisit:
      "pou jwenn rekonpans ou nan pwochen vizit ou. Mèsi",
    earn: "Touche",
    delivaryNote: "Remak Delivary",
    draftSales: "Draft Komèsyal",
    startDate: "Dat kòmansman",
    endDate: "Dat fen",
    orders: "Lòd",
    checkout: "kesye",
    noProductItem: "Pa gen atik pwodwi",
    printProductTag: "Ekri an lèt detache Product Tag",
    transactionReference: "Referans tranzaksyon",
    Cashier: "Kesye",
    Manager: "Manadjè",
    Owner: "Pwopriyetè",
    Admin: "Admin",
    addPartners: "Ajoute Patnè",
    addNewLoyaltyPartner: "Ajoute nouvo patnè lwayote",
    pleaseEnterCompanyName: "Tanpri antre non konpayi an",
    companyName: "Non konpayi",
    pleaseEnterCompanyRepName: "Tanpri antre non reprezantan konpayi an",
    companyRepName: "Non Reprezantan Konpayi an",
    pleaseEnterCompanyRepEmail: "Tanpri antre imel reprezantan konpayi an",
    companyRepEmail: "Imèl reprezantan konpayi an",
    pleaseEnterCompanyRepPhone:
      "Tanpri antre nimewo telefòn reprezantan konpayi an",
    companyRepPhone: "Nimewo telefòn nan reprezantan konpayi",
    addReward: "Ajoute rekonpans",
    pleaseEnterRewardName: "Tanpri antre non rekonpans lan",
    rewardName: "Non rekonpans",
    rewardQuantity: "Kantite rekonpans",
    rewardDescription: "Deskripsyon rekonpans",
    rewardType: "Kalite rekonpans",
    pleaseEnterRewardType: "Tanpri antre kalite rekonpans",
    pleaseEnterRewardQuantity: "Tanpri antre kantite rekonpans",
    pleaseEnterRewardDescription: "Tanpri antre deskripsyon rekonpans lan",
    fineDining: "Fine Gastronomie",
    luxuryFashion: "Sou entènèt jwèt Luxury Fashion",
    hotels: "Otèl",
    travel: "Vwayaje",
    foodAndBeverage: "Manje ak bwason",
    fashion: "Fashion",
    health: "Sante",
    furniture: "Mèb",
    entertainment: "Divètisman",
    automobile: "Otomobil",
    education: "Edikasyon",
    beautyAndSpa: "Bote ak Spa",
    staycation: "Staycation",
    events: "Evènman",
    trips: "Vwayaj",
    oilAndGas: "Lwil oliv ak gaz",
    laundry: "Lesiv",
    partnerCategory: "Kategori patnè",
    freeItem: "Atik gratis",
  },
  Gujarati: {
    cashier: "કેશિયર",
    manager: "મેનેજર",
    owner: "માલિક",
    online: "ઓનલાઇન",
    offline: "offlineફલાઇન",
    changePassword: "પાસવર્ડ બદલો",
    currentPasswordMessage: "કૃપા કરીને તમારો વર્તમાન પાસવર્ડ દાખલ કરો",
    passwordMessage: "મહેરબાની કરીને તમારો પાસવર્ડ નાખો",
    currentPassword: "અત્યારનો પાસવર્ડ",
    password: "પાસવર્ડ",
    confirmPasswordMessage: "કૃપા કરીને તમારા પાસવર્ડની પુષ્ટિ કરો!",
    confirmPassword: "પાસવર્ડની પુષ્ટિ કરો",
    sendViaEmail: "ઇમેઇલ દ્વારા મોકલો",
    sendViaWhatsapp: "WhatsApp મારફતે મોકલો",
    downloadPdf: "PDF ડાઉનલોડ કરો",
    paid: "ચૂકવેલ",
    unpaid: "અવેતન",
    partial: "આંશિક",
    closeInvoice: "શું તમે ઇન્વoiceઇસ બંધ કરવા માંગો છો?",
    closeInvoiceConfirmation:
      "ઇન્વoiceઇસ સાચવી શકાશે નહીં. શું તમે બંધ કરવા માંગો છો?",
    yes: "હા",
    no: "ના",
    invoice: "ઇન્વoiceઇસ",
    wasDeducted: "કપાત કરવામાં આવી હતી",
    for: "માટે",
    item: "આઇટમ",
    addProduct: "ઉત્પાદન ઉમેરો",
    paymentReference: "ચુકવણી સંદર્ભ",
    amountPaid: "ચૂકવેલ રકમ",
    discountAmount: "ડિસ્કાઉન્ટની રકમ",
    amountDue: "બાકી નીકળતી રકમ",
    amount: "રકમ",
    dueDate: "નિયત તારીખ",
    paymentType: "ચુકવણીનો પ્રકાર",
    card: "કાર્ડ",
    cash: "રોકડ",
    bankTransfer: "બેન્ક ટ્રાન્સફર",
    paymentMessage: "ચુકવણી સંદેશ",
    description: "વર્ણન",
    sendReceipt: "રસીદ મોકલો",
    delete: "કાી નાખો",
    save: "સાચવો",
    resend: "ફરી મોકલો",
    saveAndSend: "સાચવો",
    invoiceSaved: "ઇન્વoiceઇસ સાચવ્યું!",
    selectPaymentMethod: "કૃપા કરીને ચુકવણી પદ્ધતિ પસંદ કરો!",
    selectCustomer: "કૃપા કરીને ગ્રાહક પસંદ કરો!",
    cartEmptyError:
      "કાર્ટ સૂચિ ખાલી ન હોઈ શકે, ભરતિયું બંધ કરો અને કાર્ટમાં આઇટમ ઉમેરો!",
    subscriptionExpired:
      "તમારું સબ્સ્ક્રિપ્શન સમાપ્ત થઈ ગયું છે અને તમારું એકાઉન્ટ હવે મર્યાદિત છે. તમારા ખાતાને રિન્યૂ કરવા માટે નીચે આપેલા બટન પર ક્લિક કરો",
    renew: "નવીકરણ કરો",
    holdOn: "રાહ જુઓ",
    customerBank: "ગ્રાહક બેંક",
    cancel: "રદ કરો",
    selectACustomer: "ગ્રાહક પસંદ કરો",
    invoiceSuccessfulPdfError:
      "ભરતિયું સફળતાપૂર્વક બનાવ્યું પરંતુ PDF જનરેશન સામાન્ય કરતાં વધુ સમય લઈ રહ્યું છે. કૃપા કરીને ટૂંક સમયમાં પાછા તપાસો.",
    downloadingInvoice: "ભરતિયું ડાઉનલોડ કરી રહ્યું છે",
    downloadingReceipt: "રસીદ ડાઉનલોડ કરી રહ્યા છીએ",
    whatsappReceiptError:
      "WhatsApp દ્વારા રસીદ મોકલવામાં ભૂલ આવી, કૃપા કરીને ફરી પ્રયાસ કરો.",
    receiptToWhatsapp: "રસીદ WhatsApp ને ફોરવર્ડ કરી",
    thankYouForPatronage: "તમારા સમર્થન માટે આભાર",
    hereIsYourReceipt: "અહીં તમારી ચુકવણીની રસીદ છે",
    errorSendingEmailReceipt:
      "ઈ-મેલ દ્વારા રસીદ મોકલવામાં ભૂલ આવી, કૃપા કરીને ફરી પ્રયાસ કરો અથવા સપોર્ટનો સંપર્ક કરો",
    receiptSentToEmail: "ગ્રાહકની ઇમેઇલ પર રસીદ મોકલવામાં આવી છે",
    invoiceSentToEmail: "ગ્રાહકના ઇમેઇલ પર ઇન્વoiceઇસ મોકલવામાં આવ્યું છે",
    invoiceSuccessWhatsappError:
      "ભરતિયું સફળતાપૂર્વક બનાવ્યું પરંતુ WhatsApp પર મોકલવામાં ભૂલ આવી. ભરતિયું સૂચિ પર ફરી પ્રયાસ કરો",
    invoiceSuccessfulEmailError:
      "ભરતિયું સફળતાપૂર્વક બનાવ્યું પરંતુ ઈ-મેલ તરીકે મોકલતી વખતે ભૂલ આવી. કૃપા કરીને ઇન્વoiceઇસ સૂચિમાંથી ફરી પ્રયાસ કરો",
    invoiceSentToWhatsapp: "વોટ્સએપ પર ઈન્વોઈસ ફોરવર્ડ કર્યું",
    hello: "નમસ્તે",
    pleaseDownloadInvoice: "મહેરબાની કરીને ભરતિયું ડાઉનલોડ કરો",
    pleaseDownloadReceipt: "કૃપા કરીને રસીદ ડાઉનલોડ કરો",
    from: "થી",
    email: "ઇમેઇલ",
    upgrade: "અપગ્રેડ કરો",
    youAreOnFreePlan: "તમે મફત યોજના પર છો.",
    clickOn: "ઉપર ક્લિક કરો",
    yourPlanInFewSteps: " થોડા ઝડપી પગલાંઓમાં તમારું સબ્સ્ક્રિપ્શન.",
    to: "પ્રતિ",
    yourSubscriptionHasExpired:
      "તમારું સબ્સ્ક્રિપ્શન સમાપ્ત થઈ ગયું છે અને તમારું એકાઉન્ટ હવે મર્યાદિત છે.",
    days: "દિવસ",
    yourSubscriptionExpiresIn:
      "તમારું લોયસ્ટાર સબ્સ્ક્રિપ્શન આમાં સમાપ્ત થાય છે",
    createAcount: "એક ખાતુ બનાવો",
    signIn: "સાઇન ઇન કરો",
    continue: "ચાલુ રાખો",
    enterOtp: "OTP પિન દાખલ કરો",
    enterValidOtp: "કૃપા કરીને માન્ય PIN દાખલ કરો",
    pin: "પિન",
    tokenSentToMail: "તમારા ઇમેઇલ પર એક ટોકન મોકલવામાં આવ્યું છે",
    passwordResetSuccessful: "પાસવર્ડ રીસેટ સફળ રહ્યો",
    somethingWentWrong: "કંઈક ખોટું થયું!",
    resetPassword: "પાસવર્ડ રીસેટ કરો",
    iHaveResetCode: "મારી પાસે પાસવર્ડ રીસેટ કોડ છે",
    pleaseEnterEmail: "કૃપા કરીને તમારું ઇમેઇલ દાખલ કરો",
    aTokenWillBeSentToEmail: "તમારા ઇમેઇલ પર એક ટોકન મોકલવામાં આવશે",
    enterEmail: "તમારું ઈમેલ એડ્રેસ લખો",
    sendinYourToken: "તમારું ટોકન મોકલી રહ્યું છે ...",
    makeSureItMatchesPassword:
      "ખાતરી કરો કે તે તમારા નવા પાસવર્ડ સાથે મેળ ખાય છે",
    pleaseChooseNewPassword: "કૃપા કરીને નવો પાસવર્ડ પસંદ કરો",
    chooseNewPassword: "નવો પાસવર્ડ પસંદ કરો",
    enterNewPassword: "ખાતરી કરવા માટે તમારો નવો પાસવર્ડ દાખલ કરો",
    enterTokenSent: "તમારો મેઇલ મોકલ્યો હતો તે ટોકન દાખલ કરો",
    resetToken: "ટોકન રીસેટ કરો",
    resettingPassword: "તમારો પાસવર્ડ ફરીથી સેટ કરી રહ્યા છીએ ...",
    signUp: "સાઇન અપ કરો",
    adminSignInWithEmail:
      " એડમિન ઇમેઇલ સાથે સાઇન ઇન કરે છે જ્યારે સ્ટાફ વપરાશકર્તાનામ સાથે સાઇન ઇન કરે છે.",
    pleaseEnterEmailOrUsername:
      "કૃપા કરીને તમારું ઇમેઇલ અથવા વપરાશકર્તા નામ દાખલ કરો",
    emailOrUsername: "ઇમેઇલ અથવા વપરાશકર્તા નામ",
    pleaseEnterPassword: "કૃપા કરીને પાસવર્ડ દાખલ કરો",
    createAnAccount: "એક ખાતુ બનાવો",
    forgotPassword: "પાસવર્ડ ભૂલી ગયા છો?",
    enterPhoneNumber: "ફોન નંબર દાખલ કરો",
    personalData: "વ્યક્તિગત માહિતી",
    validateConfirmationCOde: "પુષ્ટિ કોડ માન્ય કરો",
    pleaseEnterFirstName: "કૃપા કરીને તમારું પ્રથમ નામ દાખલ કરો",
    pleaseEnterPhoneNumber: "કૃપા કરીને તમારો ફોન નંબર દાખલ કરો",
    pleaseEnterLastName: "કૃપા કરીને તમારું છેલ્લું નામ દાખલ કરો",
    pleaseEnterBusinessName: "કૃપા કરીને તમારા વ્યવસાયનું નામ દાખલ કરો",
    firstName: "પ્રથમ નામ",
    lastName: "છેલ્લું નામ",
    businessName: "વ્યવસાયનું નામ",
    previous: "અગાઉના",
    next: "આગળ",
    pleaseSelectBusinessCategory: "કૃપા કરીને તમારી વ્યવસાય શ્રેણી પસંદ કરો",
    pleaseEnterValidEmail: "કૃપા કરીને માન્ય ઇમેઇલ દાખલ કરો",
    pleaseEnterPostCode: "કૃપા કરીને પોસ્ટ કોડ દાખલ કરો",
    postCode: "પોસ્ટ કોડ",
    phoneNumberInUse: "આ ફોન નંબર પહેલેથી જ ઉપયોગમાં છે!",
    emailInUse: "આ ઇમેઇલ પહેલેથી જ ઉપયોગમાં છે!",
    foodAndDrinks: "જમવાનું અને પીવાનું",
    salonAndBeauty: "સલૂન અને સુંદરતા",
    fashionAndAccessories: "ફેશન અને એસેસરીઝ",
    gymAndFitness: "જિમ અને ફિટનેસ",
    travelAndHotel: "મુસાફરી અને હોટેલ",
    homeAndGifts: "ઘર અને ભેટ",
    washingAndCleaning: "ધોવા અને સફાઈ",
    gadgetsAndElectronics: "ગેજેટ્સ અને ઇલેક્ટ્રોનિક્સ",
    groceries: "કરિયાણા",
    others: "અન્ય",
    submit: "સબમિટ કરો",
    accountCreatedSuccessful: "તમારું એકાઉન્ટ સફળતાપૂર્વક બનાવવામાં આવ્યું છે.",
    pleaseEnterAddress: "કૃપા કરીને તમારું સરનામું દાખલ કરો",
    addressLine1: "સરનામું રેખા 1",
    addressLine2: "સરનામાની લાઇન નં 2",
    choosePassword: "પાસવર્ડ પસંદ કરો",
    passwordMustBe6Characters: "પાસવર્ડ ઓછામાં ઓછા 6 અક્ષરોનો હોવો જોઈએ.",
    howDidYouHearLoystar: "તમે લોયસ્ટાર વિશે કેવી રીતે સાંભળ્યું?",
    referralCode: "રેફરલ કોડ",
    byClickingTheButton: " નીચેના બટન પર ક્લિક કરીને, તમે સંમત થાઓ છો",
    termsAndSevice: "શરતો",
    wereCreatingAccount: "અમે તમારું એકાઉન્ટ બનાવી રહ્યા છીએ",
    proceed: "આગળ વધો",
    verificationCodeMustBe6: "ચકાસણી કોડ 6 અંકોનો હોવો જોઈએ",
    pleaseEnter6DigitCode: "કૃપા કરીને 6 અંકનો કોડ દાખલ કરો",
    enterVerificationCode: "ચકાસણી કોડ દાખલ કરો",
    resendToken: "ટોકન ફરીથી મોકલો",
    verify: "ચકાસો",
    transactions: "વ્યવહારો",
    todaySales: "આજનું વેચાણ",
    salesHistory: "વેચાણ ઇતિહાસ",
    supplyHistory: "પુરવઠો ઇતિહાસ",
    new: "નવું",
    invoices: "ઇન્વicesઇસ",
    disbursements: "વિતરણ",
    offlineSales: "Lineફલાઇન વેચાણ",
    products: "પ્રોડક્ટ્સ",
    customers: "ગ્રાહકો",
    multiLevelLoyalty: "બહુ-સ્તરની વફાદારી",
    loyaltyPrograms: "વફાદારી કાર્યક્રમો",
    members: "સભ્યો",
    appStore: "એપ્લિકેશન ની દુકાન",
    orderMenu: "ઓર્ડર મેનુ",
    settings: "સેટિંગ્સ",
    staffAndBranches: "સ્ટાફ અને શાખાઓ",
    myAccount: "મારું ખાતું",
    switchToSellMode: "વેચાણ મોડ પર સ્વિચ કરો",
    signOut: "સાઇન આઉટ",
    getFreeSubscription: "મફત સબ્સ્ક્રિપ્શન મેળવો",
    onlyNumbersAllowed: "માત્ર નંબરોની મંજૂરી છે",
    yourAccountMustBe10Digits: "તમારો એકાઉન્ટ નંબર 10 અંકનો હોવો જોઈએ",
    yourBvnMustBe11: "તમારો BVN 11 અંકનો નંબર હોવો જોઈએ",
    pleaseSelectBank: "કૃપા કરીને તમારી બેંક પસંદ કરો",
    selectYourBank: "તમારી બેંક પસંદ કરો",
    enterBankAccountNumber: "બેંક એકાઉન્ટ નંબર દાખલ કરો",
    enterBvnNumber: "તમારું BVN દાખલ કરો",
    connectBankAccount: "બેંક ખાતું જોડો",
    passwordResetSuccessfulAndSignOut:
      "તમારો પાસવર્ડ સફળતાપૂર્વક રીસેટ કરવામાં આવ્યો છે. ફરીથી સાઇન ઇન કરવા માટે નીચે સાઇન આઉટ બટન પર ક્લિક કરો",
    enterCurrentPassword: "વર્તમાન પાસવર્ડ દાખલ કરો",
    pleaseEnterCurrentPassword: "કૃપા કરીને વર્તમાન પાસવર્ડ દાખલ કરો",
    phoneNumber: "ફોન નંબર",
    sex: "સેક્સ",
    dateOfBirth: "જન્મ તારીખ",
    state: "રાજ્ય",
    country: "દેશ",
    loyaltyId: "વફાદારી ID",
    createdAt: "ખાતે બનાવ્યું",
    noOfTransactionsMade: "કરેલા વ્યવહારોની સંખ્યા",
    yourDownloadWillStart: "તમારું ડાઉનલોડ એક ક્ષણમાં શરૂ થશે",
    exportCustomers: "નિકાસ ગ્રાહકો",
    youhaveSuccessfullyToppedUp: "તમે તમારા SMS એકમોમાં સફળતાપૂર્વક ટોચ પર છે.",
    youNowHave: "હવે તમારી પાસે છે",
    smsUnits: "SMS એકમો",
    enterNumberOfSmsUnits:
      "તમે ખરીદવાનો ઇરાદો ધરાવતા એસએમએસ એકમોની સંખ્યા દાખલ કરો",
    pleaseEnterANumericValue: "કૃપા કરીને એક આંકડાકીય મૂલ્ય દાખલ કરો",
    pay: "પે",
    accountEditedSuccessfully:
      "તમારું એકાઉન્ટ સફળતાપૂર્વક સંપાદિત કરવામાં આવ્યું હતું.",
    youAeCurrentlyOn: "તમે હાલમાં ચાલુ છો",
    plan: "યોજના",
    userData: "વપરાશકર્તા ડેટા",
    businessData: "બિઝનેસ ડેટા",
    businessCategory: "બિઝનેસ કેટરગરી",
    pleaseSelectCurrency: "કૃપા કરીને તમારું ચલણ પસંદ કરો",
    selectYourCurrency: "તમારું ચલણ પસંદ કરો",
    purchaseMoreSmsUnits: "નીચેના ફોર્મનો ઉપયોગ કરીને વધુ SMS એકમો ખરીદો",
    youHave: "તમારી પાસે છે",
    atLeast4SmsUnitsRrequired:
      "ચકાસણી માટે ઓછામાં ઓછા 4 એસએમએસ યુનિટ જરૂરી છે, કૃપા કરીને ટોપ અપ કરો!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "કૃપા કરીને 'તમારા ગ્રાહકને જાણો' (KYC) જરૂરિયાતોનું પાલન કરવા માટે તમારા બેંક ખાતાને ચકાસો. આ તમને યુએસએસડી અથવા ઇન્સ્ટન્ટ ટ્રાન્સફર મારફતે ચુકવણી એકત્રિત કરવાની, ગ્રાહકો પાસેથી ઓર્ડર પ્રાપ્ત કરવાની અને વ્યવહારની પતાવટની પ્રક્રિયા કરવાની મંજૂરી આપશે. નાઇજિરિયન વેપારીઓને જ લાગુ પડે છે. શરૂ કરવા માટે કૃપા કરીને નીચે આપેલ બટન દબાવો.",
    reConnectBankAccount: "બેંક ખાતાને ફરીથી જોડો",
    accountName: "ખાતાનું નામ",
    accountNumber: "ખાતા નંબર",
    bankName: "બેંકનું નામ",
    verified: "ચકાસણી",
    accountDetails: "ખાતાની માહિતી",
    kycBankAccount: "કેવાયસી",
    createTier: "ટાયર બનાવો",
    fileUploadSuccessful: "ફાઇલ સફળતાપૂર્વક અપલોડ કરી",
    fileUploadFailed: "ફાઇલ અપલોડ નિષ્ફળ",
    createLoyaltyProgram: "લોયલ્ટી પ્રોગ્રામ બનાવો",
    createLoyalty: "વફાદારી બનાવો",
    name: "નામ",
    loyaltyArtwork: "વફાદારી આર્ટવર્ક",
    clickToUpload: "અપલોડ કરવા માટે ક્લિક કરો",
    amountToPointsRatio: "ગુણોત્તરની રકમ",
    points: "પોઈન્ટ",
    recommendedAmountToPointRatio:
      "ભલામણ કરેલ: ₦ 1 થી 1 પોઇન્ટ. એટલે કે ખર્ચ કરેલા દરેક 1 નાયરા માટે, તમારા ગ્રાહકોને 1 પોઇન્ટ મળે છે",
    pleaseTypeIn: "કૃપા કરીને લખો",
    toDeleteLoyalty: "આ વફાદારી કા deleteી નાખવા માટે",
    deleteLoyalty: "વફાદારી કાી નાખો",
    toConfirm: "ખાતરી કરવા માટે",
    edit: "સંપાદિત કરો",
    pointsAwardedSuccessfully: "પોઇન્ટ સફળતાપૂર્વક એનાયત થયા.",
    enterPointValueToAward:
      "તમે ગ્રાહકોને એવોર્ડ આપવા માંગતા હો તે બિંદુ મૂલ્ય દાખલ કરો",
    award: "એવોર્ડ",
    awardPointValuesToCustomer: "ગ્રાહકને એવોર્ડ પોઇન્ટ મૂલ્ય",
    enrollMembersToLoyalty: "લોયલ્ટીમાં સભ્યોની નોંધણી કરો",
    awardPoints: "એવોર્ડ પોઇન્ટ",
    enroll: "નોંધણી કરો",
    home: "ઘર",
    loyalty: "વફાદારી",
    enrollCustomers: "ગ્રાહકોની નોંધણી કરો",
    selected: "પસંદ કરેલ",
    customer: "ગ્રાહક",
    loyaltyActivationSuccessful: "વફાદારી સક્રિયકરણ સફળ છે.",
    loyaltyDeactivationSuccessful: "વફાદારી નિષ્ક્રિયકરણ સફળ છે.",
    viewTier: "ટ્યુર જુઓ",
    deactivate: "નિષ્ક્રિય કરો",
    activate: "સક્રિય કરો",
    actions: "ક્રિયાઓ",
    nameOfLoyalty: "વફાદારીનું નામ",
    loyaltyStatus: "વફાદારી સ્થિતિ વફાદારી સ્થિતિ",
    numberOfTiers: "સ્તરની સંખ્યા",
    createdOn: "બનાવનાર",
    createATier: "એક સ્તર બનાવો",
    stopCreatingTierConfirmation: "શું તમે સ્તર બનાવવાનું બંધ કરવા માંગો છો?",
    stopEditingTierConfirmation:
      "શું તમે આ સ્તરને સંપાદિત કરવાનું બંધ કરવા માંગો છો?",
    nameOfTier: "ટાયરનું નામ",
    minimumSpendingTarget: "ન્યૂનતમ ખર્ચ લક્ષ્ય",
    maximumSpendingTarget: "મહત્તમ ખર્ચ લક્ષ્ય",
    minimumSpendingTargetRequired: "ન્યૂનતમ ખર્ચ લક્ષ્ય જરૂરી છે",
    maximumSpendingTargetRequired: "મહત્તમ ખર્ચ લક્ષ્ય જરૂરી છે",
    rewardSponsor: "પુરસ્કાર પ્રાયોજક",
    pleaseChooseARewardSponsor: "કૃપા કરીને પુરસ્કાર પ્રાયોજક પસંદ કરો",
    self: "સ્વ",
    partner: "ભાગીદાર",
    rewardPartner: "પુરસ્કાર ભાગીદાર",
    pleaseSelectRewardPartner: "કૃપા કરીને તમારા પુરસ્કાર ભાગીદારને પસંદ કરો",
    rewards: "પુરસ્કારો",
    pleaseSelectAReward: "કૃપા કરીને પુરસ્કાર પસંદ કરો",
    instructionsOnRedeemingReward:
      "ગ્રાહકે પુરસ્કાર કેવી રીતે રિડીમ કરવો તે અંગેની સૂચનાઓ",
    pleaseFillInThisField: "કૃપા કરીને આ ક્ષેત્ર ભરો!",
    toDeleteThisTier: " આ સ્તરને કા deleteી નાખવા માટે",
    deleteTier: "ટિયર કાleteી નાખો",
    viewMembers: "સભ્યો જુઓ",
    membersEnrolled: "સભ્યો નોંધાયા",
    instruction: "સૂચના",
    membersIn: "માં સભ્યો",
    availableTiersInLoyalty: "લોયલ્ટી પ્રોગ્રામમાં ઉપલબ્ધ ટાયર (ઓ)",
    tiers: "ટાયર્સ",
    totalTier: "કુલ ટાયર",
    availableLoyaltyProgramme: "ઉપલબ્ધ લોયલ્ટી પ્રોગ્રામ",
    totalLoyalties: "કુલ વફાદારીઓ",
    memberDetails: "સભ્યની વિગતો",
    nameOfCustomer: "ગ્રાહકનું નામ",
    address: "સરનામું",
    allEnrolledMembers: "બધા નોંધાયેલા સભ્યો",
    thisIsToWishYouHappyBirthday:
      "આ તમને જન્મદિવસની ખૂબ ખૂબ શુભેચ્છાઓ અને સમૃદ્ધ જીવનની ઇચ્છા છે. તમે લોયસ્ટર માટે જે છો તેના માટે આભાર. હેપી સેલિબ્રેશન્સ!",
    inputAnOfferPlease: "કૃપા કરીને ઓફર દાખલ કરો",
    pleaseSelectTheInsertPoint:
      "કૃપા કરીને સંદેશમાં શામેલ બિંદુ પસંદ કરો અને ફરીથી ક્લિક કરો",
    birthdayOfferAndMessage: "જન્મદિવસ ઓફર અને સંદેશ",
    birthdayOffer: "જન્મદિવસની ઓફર",
    birthdayMessage: "જન્મદિવસ સંદેશ",
    noOfferFound: "કોઈ ઓફર મળી નથી",
    settingABirthdayOffer:
      "જન્મદિવસની ઓફર સેટ કરવાથી ગ્રાહકોને તેમના જન્મદિવસ પર એસએમએસ દ્વારા આ ઓફર પ્રાપ્ત કરવાની મંજૂરી મળે છે",
    createOffer: "ઓફર બનાવો",
    whatIsTheOffer: "શું ઓફર છે?",
    editMessage: "સંદેશ સંપાદિત કરો",
    insert: "દાખલ કરો",
    theNameOfCustomerInserted: "ગ્રાહકનું નામ અહીં દાખલ કરવામાં આવશે",
    theBirtdayOfferInserted: "જન્મદિવસની ઓફર અહીં દાખલ કરવામાં આવશે",
    youSuccessfullyAddedNewBranch: "તમે સફળતાપૂર્વક નવી શાખા ઉમેરી છે!",
    addNewBranch: "નવી શાખા ઉમેરો",
    addBranch: "શાખા ઉમેરો",
    additionalBranchWillIncur: "વધારાની શાખાનો ખર્ચ થશે",
    perBranch: "શાખા દીઠ",
    ecommerceBranchCosts: "ઈકોમર્સ શાખાનો ખર્ચ",
    pleaseEnterBranchName: "કૃપા કરીને શાખાનું નામ દાખલ કરો",
    pleaseEnterBranchAddress1: "કૃપા કરીને શાખાની સરનામું રેખા 1 દાખલ કરો",
    enterBranchAddress1: "શાખાની એડ્રેસ લાઇન 1 દાખલ કરો",
    enterBranchAddress2: "શાખાની એડ્રેસ લાઇન 1 દાખલ કરો",
    pleaseEnterBranchAddress2: "કૃપા કરીને શાખાની સરનામું રેખા 2 દાખલ કરો",
    enterBranchName: "શાખાનું નામ દાખલ કરો",
    successfullyAddedStaff: "તમે સફળતાપૂર્વક નવો સ્ટાફ ઉમેર્યો છે!",
    addNewStaff: "નવો સ્ટાફ ઉમેરો",
    addStaff: "સ્ટાફ ઉમેરો",
    additionalStaffWillIncur: "વધારાનો સ્ટાફ લાગશે",
    perStaff: "સ્ટાફ દીઠ.",
    pleaseEnterStaffEmail: "કૃપા કરીને સ્ટાફનું ઇમેઇલ દાખલ કરો",
    pleaseEnterStaffUsername: "કૃપા કરીને સ્ટાફનું વપરાશકર્તાનામ દાખલ કરો",
    pleaseEnterStaffPassword: "કૃપા કરીને સ્ટાફનો પાસવર્ડ દાખલ કરો",
    pleaseSelectStaffRole: "કૃપા કરીને સ્ટાફની ભૂમિકા પસંદ કરો",
    selectStaffRole: "સ્ટાફની ભૂમિકા પસંદ કરો",
    enterStaffEmail: "સ્ટાફનું ઇમેઇલ દાખલ કરો",
    enterStaffUsername: "સ્ટાફનું યુઝરનેમ દાખલ કરો",
    enterStaffPassword: "સ્ટાફનો પાસવર્ડ દાખલ કરો",
    spacesNotAllowedInUsername: "વપરાશકર્તાનામમાં જગ્યાઓની મંજૂરી નથી",
    admin: "સંચાલક",
    pleaseSelectBusinessToAttachStaff:
      "સ્ટાફ જોડવા માટે કૃપા કરીને વ્યવસાય પસંદ કરો",
    searchForBranchToAttachStaff: "સ્ટાફ જોડવા માટે શાખા શોધો",
    username: "વપરાશકર્તાનામ",
    role: "ભૂમિકા",
    areYouSureToDeleteThis: "શું તમે આને કા deleteી નાખવાની ખાતરી કરો છો?",
    branches: "શાખાઓ",
    upgradeYourPlan: "તમારી યોજના અપગ્રેડ કરો.",
    add: "ઉમેરો",
    addNew: "નવો ઉમેરો",
    customerWithEmailAlreadyExists:
      "ઇમેઇલ/ફોન નંબર સાથે ગ્રાહક પહેલેથી અસ્તિત્વમાં છે!",
    successfullyAddedNewCustomer: "તમે સફળતાપૂર્વક એક નવો ગ્રાહક ઉમેર્યો છે!",
    addCustomer: "ગ્રાહક ઉમેરો",
    pleaseEnterCustomerFirstName: "કૃપા કરીને ગ્રાહકનું પ્રથમ નામ દાખલ કરો",
    pleaseEnterCustomerLastName: "કૃપા કરીને ગ્રાહકનું છેલ્લું નામ દાખલ કરો",
    pleaseEnterCustomerPhoneNumber: "કૃપા કરીને ગ્રાહકનો ફોન નંબર દાખલ કરો",
    pleaseEnterCustomerEmail: "કૃપા કરીને ગ્રાહકનું ઇમેઇલ દાખલ કરો",
    pleaseEnterCustomerAddressLine: "કૃપા કરીને ગ્રાહકની એડ્રેસ લાઇન દાખલ કરો",
    pleaseEnterCustomerOtherAddress:
      "કૃપા કરીને ગ્રાહકનું અન્ય સરનામું દાખલ કરો",
    pleaseSelectCustomerGender: "કૃપા કરીને ગ્રાહકનું લિંગ પસંદ કરો",
    gender: "જાતિ",
    male: "પુરુષ",
    female: "સ્ત્રી",
    bank: "બેંક",
    selectBank: "બેંક પસંદ કરો",
    stateOrRegionOrProvince: "રાજ્ય/પ્રદેશ/પ્રાંત",
    customerNotes: "ગ્રાહક નોંધો",
    sendSms: "SMS મોકલો",
    editCustomer: "ગ્રાહક સંપાદિત કરો",
    redeemReward: "ઈનામ રિડીમ કરો",
    issueLoyaltyCard: "લોયલ્ટી કાર્ડ જારી કરો",
    deleteCustomer: "ગ્રાહકને કાી નાખો",
    youveSuccessfullyAssignedLoyaltyMembership:
      "તમે સફળતાપૂર્વક લોયલ્ટી મેમ્બરશિપ ID સોંપી છે",
    noMembershipIdAvailable:
      "કોઈ સભ્યપદ ID ઉપલબ્ધ નથી. કૃપા કરીને hello@loystar.co પર અમારો સંપર્ક કરો",
    sendEmail: "ઈ - મેલ મોકલો",
    membershipPoints: "સભ્યપદ પોઈન્ટ",
    customerDetails: "ગ્રાહક વિગતો",
    close: "બંધ",
    loyaltyBalance: "લોયલ્ટી બેલેન્સ",
    pointsBalance: "પોઈન્ટ બેલેન્સ",
    starBalance: "સ્ટાર બેલેન્સ",
    requiredPoints: "જરૂરી પોઈન્ટ",
    requiredStars: "જરૂરી સ્ટાર્સ",
    reddemCode: "કોડ રિડીમ કરો",
    toDeleteThisCustomer: "આ ગ્રાહકને કા deleteી નાખવા માટે",
    customerHasBeenDeletedSuccessfully:
      "ગ્રાહક સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યો છે!",
    customerWithPhoneAlreadyExists:
      "ફોન નંબર સાથે ગ્રાહક પહેલેથી અસ્તિત્વમાં છે",
    customerWasSuccessfullyEdited: "ગ્રાહક સફળતાપૂર્વક સંપાદિત થયો",
    anErrorOccured: "એક ભૂલ આવી",
    phoneNumberIncludeCountry: "ફોન નંબર (દેશનો કોડ શામેલ કરો)",
    yourFileQueuedForUpload:
      "તમારી ફાઇલ અપલોડ કરવા માટે કતારબદ્ધ છે. કૃપા કરીને થોડી સેકંડ પછી પૃષ્ઠને તાજું કરો.",
    thereWasAnErrorPerformingOperation: "ઓપરેશન કરવામાં ભૂલ આવી હતી!",
    pleaseSelectFile: "કૃપા કરીને ફાઇલ પસંદ કરો!",
    oopsOnlyCsvFilesAllowed:
      "અરેરે! માત્ર CSV ફાઇલોને જ મંજૂરી છે. કૃપા કરીને .csv ફાઇલ અપલોડ કરો.",
    fileShouldBeSmallerThan5Mb:
      "ફાઇલ 5MB કરતા નાની હોવી જોઈએ. જો તમારી પાસે મોટી ફાઇલ હોય, તો કૃપા કરીને support emailloystar.co પર ઇમેઇલ કરો. આભાર",
    customerFirstNameIsRequired: "પંક્તિ પર ગ્રાહકનું પ્રથમ નામ આવશ્યક છે",
    customerPhoneNumberIsRequired: "પંક્તિ પર ગ્રાહકનો ફોન નંબર જરૂરી છે",
    importCustomers: "આયાત ગ્રાહકો",
    upload: "અપલોડ કરો",
    clickIconToDownloadCsv:
      "CSV ફાઇલ ટેમ્પલેટ ડાઉનલોડ કરવા માટે આ આયકન પર ક્લિક કરો.",
    getGoogleSheetVersion: "ગૂગલ શીટ વર્ઝન મેળવો",
    clickOrDragFileToUpload:
      "અપલોડ કરવા માટે ફાઇલને આ વિસ્તારમાં ક્લિક કરો અથવા ખેંચો",
    missingOrInvalidColumnHeader:
      "ગુમ અથવા અમાન્ય સ્તંભ મથાળું. કૃપા કરીને ટેમ્પલેટ ફોર્મેટને અનુસરો. આભાર.",
    youHaveImported: "તમે આયાત કરી છે",
    youSuccessfullyRedeemedReward:
      "તમે સફળતાપૂર્વક તમારો પુરસ્કાર રિડીમ કર્યો છે!",
    sixDigitCode: "છ અંકનો કોડ",
    pleaseEnterCustomerRewardCode: "કૃપા કરીને ગ્રાહકનો પુરસ્કાર કોડ દાખલ કરો",
    enterRewardCode: "પુરસ્કાર કોડ દાખલ કરો. (પુરસ્કાર કોડ કેસ સંવેદનશીલ છે)",
    selectLoyaltyProgram: "વફાદારી કાર્યક્રમ પસંદ કરો",
    stamps: "સ્ટેમ્પ્સ",
    smsUnitsLow: "SMS એકમો ઓછા",
    pleaseTopUpSmsUnits: "કૃપા કરીને એસએમએસ એકમોને ઉપર કરો",
    smsSuccessfullySentForDelivery:
      "SMS સફળતાપૂર્વક ડિલિવરી માટે મોકલવામાં આવ્યો!",
    sendSmsTo: "ને SMS મોકલો",
    allCustomers: "બધા ગ્રાહકો",
    unitsAvailable: "એકમો ઉપલબ્ધ છે",
    pleaseTypeInTheMessage: "કૃપા કરીને સંદેશ લખો",
    message: "સંદેશ",
    charactersRemaining: "અક્ષરો બાકી",
    avoidUseOfSpecialCharacters:
      "SMS એકમોને બચાવવા માટે ખાસ અક્ષરો અને ઇમોજીનો ઉપયોગ ટાળો.",
    note: "નૉૅધ",
    errorFetchingCustomersMultilevelDetail:
      "ગ્રાહકોને મલ્ટિલેવલની વિગતો મેળવવામાં ભૂલ",
    search: "શોધો",
    reset: "ફરીથી સેટ કરો",
    importCustomer: "આયાત ગ્રાહક",
    addNewCustomer: "નવા ગ્રાહક ઉમેરો",
    sendSmsBroadcast: "SMS પ્રસારણ મોકલો",
    totalCustomers: "કુલ ગ્રાહકો",
    disbursementDetails: "વિતરણની વિગતો",
    paidBy: "દ્વારા ચૂકવણી",
    disbursed: "વિતરિત",
    bankAccountName: "બેંક ખાતાનું નામ",
    bankAccountNumber: "બેંક એકાઉન્ટ નંબર",
    transferInitiated: "ટ્રાન્સફર શરૂ",
    transferCompleted: "ટ્રાન્સફર પૂર્ણ થયું",
    pleaseEnterAValid: "કૃપા કરીને માન્ય દાખલ કરો",
    begin: "શરૂઆત",
    end: "અંત",
    date: "તારીખ",
    paymentDate: "ચુકવણીની તારીખ",
    selectDisbursementDuration: "વિતરણ અવધિ પસંદ કરો",
    totalSettled: "કુલ પતાવટ",
    totalUnsettled: "કુલ અસ્થિર",
    toDeleteThisSale: "આ વેચાણ કા deleteી નાખવા માટે",
    draftSaleDeletedSuccessfully:
      "ડ્રાફ્ટ વેચાણ સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યું છે!",
    deleteSale: "વેચાણ કાleteી નાખો",
    pleaseTypeInYourTotal:
      "કા totalી નાખવાની ખાતરી કરવા માટે કૃપા કરીને તમારું કુલ લખો",
    billDetails: "બિલની વિગતો",
    printBill: "બિલ છાપો",
    servedBy: "દ્વારા સેવા આપી હતી",
    total: "કુલ",
    createdDate: "બનાવવાની તારીખ",
    createdTime: "બનાવ્યો સમય",
    status: "સ્થિતિ",
    loginSuccessful: "પ્રવેશ સફળ થયો",
    pleaseWaitWhileWeConnectAccount:
      "અમે તમારા એકાઉન્ટને કનેક્ટ કરીએ ત્યાં સુધી રાહ જુઓ",
    connectionFailedTryAgain:
      "જોડાણ નિષ્ફળ. મેહરબાની કરી ને ફરી થી પ્રયાસ કરો.",
    connectionSuccessful: "જોડાણ સફળ થયું",
    viewDetails: "વિગતો જુઓ",
    enable: "સક્ષમ કરો",
    free: "મુક્ત",
    cost: "ખર્ચ",
    visitWebsite: "વેબસાઈટની મુલાકાત લો",
    pleaseUpgradeYourPlanToPro:
      "કૃપા કરીને આ એપ્લિકેશનને સક્ષમ કરવા માટે તમારી યોજનાને પ્રો પ્લસમાં અપગ્રેડ કરો",
    connectYourBankAccount: "ચુકવણી પ્રાપ્ત કરવા માટે તમારા બેંક ખાતાને જોડો.",
    disable: "નિષ્ક્રિય કરો",
    enableApp: "એપ્લિકેશન સક્ષમ કરો",
    addNewProductToInvoice: "ઇન્વoiceઇસમાં નવું ઉત્પાદન ઉમેરો",
    toDeleteThisInvoice: "આ ઇન્વoiceઇસ કા deleteી નાખવા માટે",
    invoiceDeletedSuccessfully:
      "ભરતિયું સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યું છે!",
    deleteInvoice: "ઇન્વoiceઇસ કા Deી નાખો",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "તમે સફળતાપૂર્વક ભરતિયું ચુકવણી સંદેશ ઉમેર્યો છે!",
    pleaseEnterPaymentInstructions: "કૃપા કરીને ચુકવણી સૂચનાઓ દાખલ કરો",
    invoiceId: "ઇન્વoiceઇસ ID",
    paidDate: "ચૂકવણીની તારીખ",
    reference: "સંદર્ભ",
    productAdded: "ઉત્પાદન ઉમેર્યું",
    productOutOfStock: "ઉત્પાદન આઉટ ઓફ સ્ટોક. કૃપા કરીને ફરીથી સ્ટોક કરો.",
    totalInvoices: "કુલ ઇન્વોઇસ",
    totalPaidInvoices: "કુલ પેઇડ ઇન્વોઇસ",
    totalUnpaidInvoices: "કુલ અનપેઇડ ઇન્વોઇસ",
    loyaltyProgramDeleted: "લોયલ્ટી પ્રોગ્રામ કાી નાખ્યો",
    thisLoyaltyProgramDeletedSuccessfully:
      "આ વફાદારી કાર્યક્રમ સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યો છે",
    thisLoyaltyProgramEditedSuccessfully:
      "આ વફાદારી કાર્યક્રમ સફળતાપૂર્વક સંપાદિત કરવામાં આવ્યો છે",
    loyaltyProgramAddedSuccessfully:
      "લોયલ્ટી પ્રોગ્રામ સફળતાપૂર્વક ઉમેરવામાં આવ્યો છે",
    loyaltyProgramEdited: "વફાદારી કાર્યક્રમ સંપાદિત",
    loyaltyProgramAdded: "લોયલ્ટી પ્રોગ્રામ ઉમેર્યો",
    loyaltyDetails: "વફાદારીની વિગતો",
    doYouWantToCloseDialog: "શું તમે આ સંવાદ બંધ કરવા માંગો છો?",
    pleaseEnterLoyaltyName: "કૃપા કરીને તમારી વફાદારીનું નામ દાખલ કરો",
    programType: "પ્રોગ્રામનો પ્રકાર",
    pleaseSelectProgramType: "કૃપા કરીને પ્રોગ્રામનો પ્રકાર પસંદ કરો",
    simplePoints: "સરળ બિંદુઓ",
    stampsProgram: "સ્ટેમ્પ્સ પ્રોગ્રામ",
    threshold: "થ્રેશોલ્ડ",
    pleaseEnterLoyaltyThreshold: "કૃપા કરીને લોયલ્ટી થ્રેશોલ્ડ દાખલ કરો",
    reward: "પુરસ્કાર",
    pleaseEnterLoyaltyReward: "કૃપા કરીને વફાદારીનો પુરસ્કાર દાખલ કરો",
    totalUserPoints: "કુલ વપરાશકર્તા પોઇન્ટ",
    totalUserStamps: "કુલ વપરાશકર્તા સ્ટેમ્પ્સ",
    spendingTarget: "લક્ષ્ય ખર્ચ",
    spendingTargetHint1:
      "ખર્ચનું લક્ષ્ય એ છે કે ગ્રાહકે પુરસ્કાર મેળવવા માટે કેટલો ખર્ચ કરવો જોઈએ. 1 ચલણ મૂલ્ય = 1 પોઇન્ટ.",
    spendingTargetHint2:
      "સ્ટેમ્પનું લક્ષ્ય એ છે કે ગ્રાહકે પુરસ્કાર મેળવવા માટે કેટલી સ્ટેમ્પ એકત્રિત કરવી જોઈએ. દા.ત. 5",
    addNewLoyaltyProgram: "નવો લોયલ્ટી પ્રોગ્રામ ઉમેરો",
    addLoyaltyProgram: "લોયલ્ટી પ્રોગ્રામ ઉમેરો",
    loyaltyProgramType: "લોયલ્ટી પ્રોગ્રામનો પ્રકાર",
    pleaseSelectLoyaltyProgramType:
      "કૃપા કરીને લોયલ્ટી પ્રોગ્રામ પ્રકાર પસંદ કરો",
    nameOfProgram: "કાર્યક્રમનું નામ",
    pleaseEnterProgramName: "કૃપા કરીને પ્રોગ્રામ નામ દાખલ કરો",
    whatIsTheReward: "ઈનામ શું છે?",
    egNextPurchaseIsFree: "દા.ત. આગામી ખરીદી મફત છે",
    customerName: "ગ્રાહક નું નામ",
    guestCustomer: "મહેમાન ગ્રાહક",
    orderConfirmedSuccessfully: "ઓર્ડરની સફળતાપૂર્વક પુષ્ટિ થઈ",
    orderCancelledSuccessfully: "ઓર્ડર સફળતાપૂર્વક રદ થયો",
    confirmOrder: "ઓર્ડરની પુષ્ટિ કરો",
    cancelOrder: "આદેશ રદ",
    allOrders: "બધા ઓર્ડર",
    totalOrders: "કુલ ઓર્ડર",
    doYouWantToAcceptOrder: "શું તમે આ ઓર્ડર સ્વીકારવા માંગો છો?",
    doYouWantToCancelOrder: "શું તમે આ ઓર્ડર રદ કરવા માંગો છો?",
    orderDetails: "ઓર્ડરની વિગતો",
    orderCreatedAt: "પર બનાવેલ ઓર્ડર",
    supplier: "સપ્લાયર",
    productName: "ઉત્પાદન નામ",
    quantity: "જથ્થો",
    unitPrice: "એકમ કિંમત",
    receivedBy: "દ્વારા પ્રાપ્ત",
    reportFrom: "તરફથી અહેવાલ",
    totalSupplies: "કુલ પુરવઠો",
    allRightsReserved: "બધા હકો અમારી પાસે રાખેલા છે",
    toDeleteThisTransaction: "આ વ્યવહારને કાી નાખવા માટે",
    transactionDeletedSuccessfully:
      "વ્યવહાર સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યો છે. સ્ટોક ઈન્વેન્ટરીમાં પાછો ફર્યો છે.",
    deleteTransaction: "વ્યવહાર કા Deી નાખો",
    transactionDetails: "વ્યવહારની વિગતો",
    printReceipt: "રસીદ છાપો",
    channel: "ચેનલ",
    discount: "ડિસ્કાઉન્ટ",
    profit: "નફો",
    discountedSales: "ડિસ્કાઉન્ટેડ વેચાણ",
    errorFetchingRecord: "રેકોર્ડ લાવવામાં ભૂલ",
    exportTransactions: "નિકાસ વ્યવહારો",
    errorFetchingSalesRecord: "નિકાસ માટે વેચાણ રેકોર્ડ લાવવામાં ભૂલ.",
    totalSellingPrice: "કુલ વેચાણ કિંમત",
    totalCostPrice: "કુલ ખર્ચ કિંમત",
    appliedDiscount: "એપ્લાઇડ ડિસ્કાઉન્ટ",
    noOfItems: "વસ્તુઓની સંખ્યા",
    sales: "વેચાણ",
    export: "નિકાસ",
    totalProfit: "કુલ નફો",
    totalBalanceInPeriod: "સમયગાળામાં કુલ બેલેન્સ",
    allTimeSales: "બધા સમય વેચાણ",
    records: "રેકોર્ડ્સ",
    todaysSales: "આજનું વેચાણ",
    transaction: "સોદા",
    youHaveExceededTotalNumberOfProducts:
      "તમે તમારી યોજના પર મંજૂર કરેલા ઉત્પાદનોની કુલ સંખ્યાને વટાવી ગયા છો. Planંચી મર્યાદા માણવા માટે તમારી યોજનાને અપગ્રેડ કરો.",
    youNeedToHaveLoyaltyProgram:
      "આ સુવિધાનો ઉપયોગ કરવા માટે તમારી પાસે લોયલ્ટી પ્રોગ્રામ હોવો જરૂરી છે!",
    price: "કિંમત",
    category: "શ્રેણી",
    stockTracking: "સ્ટોક ટ્રેકિંગ",
    stockCount: "સ્ટોક કાઉન્ટ",
    taxed: "કરવેરા",
    originalPrice: "મૂળ કિંમત",
    costPrice: "પડતી કિંમત",
    unit: "એકમ",
    productImage: "ઉત્પાદન છબી",
    taxRate: "કર દર",
    taxType: "કર પ્રકાર",
    trackInventory: "ટ્રેક ઈન્વેન્ટરી",
    variants: "ચલો",
    hasVariants: "ચલો છે",
    importProduct: "આયાત ઉત્પાદન",
    exportProducts: "નિકાસ ઉત્પાદનો",
    addNewProduct: "નવી પ્રોડક્ટ ઉમેરો",
    viewCategory: "શ્રેણી જુઓ",
    viewSuppliers: "સપ્લાયર્સ જુઓ",
    receiveInventory: "ઈન્વેન્ટરી મેળવો",
    printAllProductsTag: "બધા ઉત્પાદનો ટેગ છાપો",
    deleteAll: "બધું કાઢી નાંખો",
    totalProducts: "કુલ ઉત્પાદનો",
    youveSuccessfullyAddedANewCategory: "તમે સફળતાપૂર્વક નવી કેટેગરી ઉમેરી છે",
    addNewCategory: "નવી કેટેગરી ઉમેરો",
    addCategory: "શ્રેણી ઉમેરો",
    categoryName: "કેટેગરીનું નામ",
    pleaseEnterCategoryName: "કૃપા કરીને શ્રેણીનું નામ દાખલ કરો",
    stampsTarget: "સ્ટેમ્પ્સ લક્ષ્યાંક",
    sendInventory: "ઈન્વેન્ટરી મોકલો",
    productDetails: "ઉત્પાદન વિગતો",
    youveSuccessfullyEditedThisCategory:
      "તમે આ શ્રેણીને સફળતાપૂર્વક સંપાદિત કરી છે",
    update: "અપડેટ",
    categoryList: "શ્રેણી યાદી",
    categories: "શ્રેણીઓ",
    enterQuantityToUpdate: "અપડેટ કરવા માટે જથ્થો દાખલ કરો",
    inventorySentSuccessfully: "ઈન્વેન્ટરી સફળતાપૂર્વક મોકલવામાં આવી હતી!",
    updateInventory: "ઇન્વેન્ટરી અપડેટ કરો",
    currentQuantity: "વર્તમાન જથ્થો",
    pleaseEnterQuantityToAdd: "તમે ઉમેરવા માંગો છો તે જથ્થો દાખલ કરો",
    pleaseSelectABranch: "કૃપા કરીને એક શાખા પસંદ કરો",
    searchForBranch: "શાખા માટે શોધો",
    youCantSendMoreThanStock:
      "તમારી પાસે સ્ટોક છે તેના કરતા વધારે તમે મોકલી શકતા નથી",
    send: "મોકલો",
    pleaseEnterQuantityToSend: "તમે મોકલવા માંગો છો તે જથ્થો દાખલ કરો",
    productNameIsRequiredOnRow: "પંક્તિ પર ઉત્પાદનનું નામ આવશ્યક છે",
    productCategoryIsRequiredOnRow: "પંક્તિ પર ઉત્પાદન શ્રેણી જરૂરી છે",
    productPriceIsRequiredOnRow: "પંક્તિ પર ઉત્પાદનની કિંમત જરૂરી છે",
    productUnitIsRequiredOnRow: "પંક્તિ પર ઉત્પાદન એકમ જરૂરી છે",
    productQuantityIsRequiredOnRow: "પંક્તિ પર ઉત્પાદનની માત્રા જરૂરી છે",
    productVariantsRequireTracking: "ઉત્પાદન ચલોને ટ્રેકિંગની જરૂર છે!",
    pleaseAddVariantClickButton:
      "કૃપા કરીને ઉમેરો બટનને ક્લિક કરીને એક પ્રકાર ઉમેરો!",
    totalVariantsMoreThanSelectedQuantity:
      "કુલ ચલો પસંદ કરેલ ઉત્પાદનની માત્રા કરતાં વધુ છે, Pls ચલ જથ્થો ઘટાડે છે",
    productEditedSuccessfully: "ઉત્પાદન સફળતાપૂર્વક સંપાદિત કરવામાં આવ્યું છે!",
    fileTooLargeLessThan4Mb:
      "ફાઇલનું કદ ખૂબ મોટું છે! ફાઇલનું કદ 4MB કરતા ઓછું હોવું જોઈએ.",
    suchVariantAlreadyExist: "આવા પ્રકાર પહેલેથી જ અસ્તિત્વમાં છે",
    addVariants: "ચલો ઉમેરો",
    editProduct: "ઉત્પાદન સંપાદિત કરો",
    pleaseEnterProductName: "કૃપા કરીને ઉત્પાદન નામ દાખલ કરો",
    pleaseEnterProductPrice: "કૃપા કરીને ઉત્પાદનની કિંમત દાખલ કરો",
    pleaseEnterProductOriginalPrice: "કૃપા કરીને ઉત્પાદનની મૂળ કિંમત દાખલ કરો",
    productDescription: "ઉત્પાદન વર્ણન",
    selectProductCategory: "ઉત્પાદન શ્રેણી પસંદ કરો",
    pleaseSelectProductCategory: "કૃપા કરીને ઉત્પાદન શ્રેણી પસંદ કરો",
    productCostPrice: "ઉત્પાદન કિંમત",
    productSellingPrice: "ઉત્પાદન વેચાણ કિંમત",
    productOriginalPrice: "ઉત્પાદન મૂળ કિંમત",
    maxFileSizeAllowedIs4Mb: "મહત્તમ ફાઇલનું કદ 4 એમબી છે",
    productsWithPicturesArePublished:
      "ઓર્ડર મેળવવા માટે ડિસ્કવર પર ચિત્રોવાળી પ્રોડક્ટ્સ પ્રકાશિત થાય છે",
    shouldThisProductBeTracked: "શું આ પ્રોડક્ટને ટ્રેક કરવી જોઈએ?",
    pleaseSelectStockUnit: "કૃપા કરીને સ્ટોક યુનિટ પસંદ કરો",
    stockUnit: "સ્ટોક યુનિટ",
    bag: "બેગ",
    bottle: "બોટલ",
    bunch: "ટોળું",
    can: "CAN",
    carton: "કાર્ટન",
    crate: "ક્રેટ",
    cup: "CUP",
    dozen: "ડઝન",
    gigabytes: "ગીગાબાઇટ્સ",
    gram: "ગ્રામ",
    kilogram: "કિલોગ્રામ",
    litre: "લીટર",
    pack: "પેક",
    pair: "જોડી",
    pieces: "ટુકડાઓ",
    plate: "પ્લેટ",
    tonne: "ટોન (MT)",
    uNIT: "યુનિ",
    yard: "યાર્ડ",
    pleaseEnterProductQuantity: "કૃપા કરીને ઉત્પાદનની માત્રા દાખલ કરો",
    productQuantity: "ઉત્પાદન જથ્થો",
    isThisProductTaxed: "શું આ પ્રોડક્ટ પર ટેક્સ લાગે છે?",
    selectTaxType: "ટેક્સનો પ્રકાર પસંદ કરો",
    pleaseSelectTaxType: "કૃપા કરીને કર પ્રકાર પસંદ કરો",
    progressive: "પ્રગતિશીલ",
    proportional: "પ્રમાણસર",
    pleaseEnterProductTaxRate: "કૃપા કરીને ઉત્પાદન કર દર દાખલ કરો",
    doesProductHaveVariants: "શું આ ઉત્પાદનમાં ચલો છે?",
    type: "પ્રકાર",
    value: "મૂલ્ય",
    pleaseEnterVariantType: "કૃપા કરીને વેરિઅન્ટનો પ્રકાર દાખલ કરો",
    pleaseEnterVariantValue: "કૃપા કરીને વેરિઅન્ટનું મૂલ્ય દાખલ કરો",
    pleaseEnterVariantPrice: "કૃપા કરીને વેરિએન્ટની કિંમત દાખલ કરો",
    pleaseEnterVariantQuantity: "કૃપા કરીને વેરિઅન્ટનો જથ્થો દાખલ કરો",
    productDeletedSuccessfully:
      "ઉત્પાદન સફળતાપૂર્વક કા deletedી નાખવામાં આવ્યું છે!",
    categoryDeletedSuccessfully:
      "શ્રેણી સફળતાપૂર્વક કા deletedી નાખવામાં આવી છે!",
    toDeleteThisProduct: "આ ઉત્પાદનને કા deleteી નાખવા માટે",
    invalidProductQuantity: "અમાન્ય ઉત્પાદન જથ્થો",
    quantityAddedIsOverStock:
      "તમે જે જથ્થો ઉમેરી રહ્યા છો તે તમારી સ્ટોકમાં છે તેના કરતા વધારે છે.",
    itemInventoryNotTracked: "આઇટમ ઇન્વેન્ટરી ટ્રેક નથી",
    addBulkQuantity: "બલ્ક જથ્થો ઉમેરો",
    enterBulkQuantity: "બલ્ક જથ્થો દાખલ કરો",
    pleaseEnterBulkQuantity: "કૃપા કરીને બલ્ક જથ્થો દાખલ કરો",
    youveSuccessfullyAddedANewProduct:
      "તમે સફળતાપૂર્વક એક નવું ઉત્પાદન ઉમેર્યું છે!",
    pleaseEnterProductSellingPrice: "કૃપા કરીને ઉત્પાદન વેચાણ કિંમત દાખલ કરો",
    doYouWantToTrackProductStock:
      "શું તમે ઉત્પાદન સ્ટોક ટ્ર trackક કરવા માંગો છો?",
    sellingPrice: "વેચાણ કિંમત",
    setProductExpiryReminder: "ઉત્પાદન સમાપ્તિ રીમાઇન્ડર સેટ કરો",
    productExpiryDate: "ઉત્પાદનની સમાપ્તિ તારીખ",
    startRemindingFrom: "થી યાદ કરાવવાનું શરૂ કરો",
    productSuppliesAddedSuccessfully:
      "તમે સફળતાપૂર્વક ઉત્પાદન પુરવઠો ઉમેર્યો છે.",
    addProductSupplies: "ઉત્પાદન પુરવઠો ઉમેરો",
    pleaseSelectSupplier: "કૃપા કરીને સપ્લાયર પસંદ કરો",
    nameOfProduct: "ઉત્પાદનનું નામ",
    pleaseSelectProduct: "કૃપા કરીને ઉત્પાદન પસંદ કરો",
    productVariant: "ઉત્પાદન ચલ",
    pleaseSelectAVariant: "કૃપા કરીને એક ચલ પસંદ કરો",
    pleaseEnterUnitPrice: "કૃપા કરીને એકમની કિંમત દાખલ કરો",
    businessBranch: "વ્યાપાર શાખા",
    pleaseSelectBranch: "કૃપા કરીને શાખા પસંદ કરો",
    youveSuccessfullyAddedANewSupplier:
      "તમે સફળતાપૂર્વક એક નવો સપ્લાયર ઉમેર્યો છે",
    addSupplier: "સપ્લાયર ઉમેરો",
    pleaseEnterSupplierEmail: "કૃપા કરીને સપ્લાયરનું ઇમેઇલ દાખલ કરો",
    pleaseAddADescription: "કૃપા કરીને વર્ણન ઉમેરો",
    anErrorOccuredProductsDeleted:
      "ઓપરેશન કરતી વખતે ભૂલ આવી. મહેરબાની કરીને નોંધ લો, પ્રક્રિયામાં કેટલાક ઉત્પાદનો કા deletedી નાખવામાં આવ્યા હશે",
    bulkDelete: "બલ્ક ડિલીટ",
    youAreAboutToDelete: "તમે કાી નાખવાના છો",
    product: "ઉત્પાદન",
    isDueToRedeem: "રિડીમ કરવાના છે",
    aReward: "એક પુરસ્કાર",
    pleaseSelectCustomerToReeemReward:
      "પુરસ્કાર રિડીમ કરવા માટે કૃપા કરીને ગ્રાહક પસંદ કરો.",
    youHaveNoLoyaltyProgramRunning:
      "તમારી પાસે કોઈ સક્રિય લોયલ્ટી પ્રોગ્રામ નથી",
    customerHhasNoPointsInLoyaltyProgram:
      "આ લોયલ્ટી પ્રોગ્રામમાં ગ્રાહકનો કોઈ પોઈન્ટ નથી",
    proceedWithPayment: "ચુકવણી સાથે આગળ વધો?",
    youAreAboutToPayForTransactionUsingPoints:
      "તમે પોઈન્ટનો ઉપયોગ કરીને વ્યવહાર માટે ચૂકવણી કરવાના છો.",
    customerHas: "ગ્રાહક પાસે છે",
    worth: "મૂલ્ય",
    andIsNotSufficientToPayForTransaction:
      "અને આ વ્યવહાર માટે ચૂકવણી કરવા માટે પૂરતું નથી. શું તેઓ અન્ય ચુકવણી પદ્ધતિનો ઉપયોગ કરીને બેલેન્સ ઉમેરવા માગે છે?",
    addCustomerLoyalty: "ગ્રાહક વફાદારી ઉમેરો",
    pleaseAddCustomerFirst: "કૃપા કરીને પહેલા ગ્રાહક ઉમેરો અથવા પસંદ કરો.",
    pleaseWaitWhileWeGetReady: "કૃપા કરીને રાહ જુઓ, જ્યારે અમે તૈયાર થઈએ",
    lowStock: "ઓછો જથ્થો",
    pleaseEnterAmountTendered: "કૃપા કરીને ટેન્ડર કરેલ રકમ દાખલ કરો",
    areYouSureToBookSaleOffline:
      "શું તમે ખરેખર આ વેચાણ ઓફલાઇન બુક કરવા માંગો છો?",
    saleWouldBeBookedAutomatically:
      "જ્યારે તમે તમારું ઇન્ટરનેટ ચાલુ કરશો ત્યારે વેચાણ આપમેળે બુક થશે",
    offlineSalesBookingCancelled: "ઓફલાઈન વેચાણ બુકિંગ રદ",
    covid19Message:
      "કોવિડ 19: ફેલાવાને રોકવા માટે તમારા હાથ સાબુથી ધોવા અથવા સેનિટાઇઝ કરો. હંમેશા સુરક્ષિત રહો",
    saleSuccessfullyRecorded: "વેચાણ સફળતાપૂર્વક રેકોર્ડ થયું!",
    sendReceiptToEmail: "ઇમેઇલ પર રસીદ મોકલો",
    sendThankYouSms: "આભાર એસએમએસ મોકલો",
    sendShippingDetails: "શિપિંગ વિગતો મોકલો",
    addLoyalty: "વફાદારી ઉમેરો",
    searchCustomerNameOrNumber: "ગ્રાહકનું નામ અથવા નંબર શોધો",
    clickTheSearchCustomerBox: "સર્ચ કસ્ટમર બોક્સ અને સ્કેન કાર્ડ પર ક્લિક કરો",
    enterProductPrice: "ઉત્પાદનની કિંમત દાખલ કરો",
    enterPriceFor: "માટે કિંમત દાખલ કરો",
    searchForProduct: "ઉત્પાદન માટે શોધો",
    all: "બધા",
    backToDashboard: "ડેશબોર્ડ પર પાછા જાઓ",
    viewDraftSales: "ડ્રાફ્ટ સેલ્સ જુઓ",
    variantSelected: "ચલ પસંદ કરેલ",
    variant: "ચલ",
    thePreviousVariantSelectionNotAvailable:
      "કિંમતના આધારે પસંદ કરેલા નવા વેરિઅન્ટ માટે અગાઉના વેરિઅન્ટની પસંદગી ઉપલબ્ધ નથી, કૃપા કરીને તમારી પસંદગી બદલો.",
    pleaseSupplyPositiveQuantityNumber: "કૃપા કરીને પોસ્ટિવ જથ્થો નંબર આપો",
    lowStockFor: "માટે ઓછો સ્ટોક",
    clearVariants: "સ્પષ્ટ ચલો",
    pleaseEnterQuantity: "કૃપા કરીને જથ્થો દાખલ કરો",
    picture: "ચિત્ર",
    redemptionToken: "રિડેમ્પશન ટોકન",
    token: "ટોકન",
    totalSpent: "કુલ ખર્ચ",
    confirmPayment: "ચુકવણીની પુષ્ટિ કરો",
    hasPaymentBeenMade: "શું ચુકવણીની સફળતાપૂર્વક પ્રક્રિયા કરવામાં આવી છે?",
    enterTransactionReference:
      "તમે જેની સાથે ચૂકવણી કરી છે તે ટ્રાન્ઝેક્શન સંદર્ભ દાખલ કરો",
    pleaseSelectACustomer: "કૃપા કરીને ગ્રાહક પસંદ કરો!",
    areYouSureToApplyDiscount: "શું તમે ખરેખર ડિસ્કાઉન્ટ લાગુ કરવા માંગો છો?",
    addYourBankAccountToEnableUssd:
      "યુપે દ્વારા ઇન્સ્ટન્ટ યુએસએસડી ટ્રાન્સફર સક્ષમ કરવા માટે તમારું બેંક એકાઉન્ટ ઉમેરો",
    totalAmountToPay: "ચૂકવવાની કુલ રકમ",
    doYouWantToCancelTransaction: "શું તમે આ વ્યવહારને રદ કરવા માંગો છો?",
    savePrintBill: "બિલ સાચવો/છાપો",
    enterAmountCollectedForCustomer: "ગ્રાહક માટે એકત્રિત રકમ દાખલ કરો",
    recordSale: "રેકોર્ડ વેચાણ",
    checkOutWith: "સાથે તપાસો",
    instantTransfer: "ઇન્સ્ટન્ટ ટ્રાન્સફર",
    dialTheUSSDCode: "યુએસએસડી કોડ ડાયલ કરો",
    pleaseSelectABank: "કૃપા કરીને એક બેંક પસંદ કરો",
    payWithUSSD: "યુએસએસડી સાથે ચૂકવણી કરો",
    sendBillTo: " - ને બિલ મોકલો",
    waitingForUSSDTransfer: "યુએસએસડી ટ્રાન્સફરની રાહ જોવી",
    percent: "ટકા",
    applyDiscount: "ડિસ્કાઉન્ટ લાગુ કરો",
    thisBillHasBeenSaved: "આ બિલ સાચવવામાં આવ્યું છે",
    saveDraft: "રૂપરેખા સાચવો",
    pleaseTypeANameToIdentifyCustomer: "કૃપા કરીને ગ્રાહકને ઓળખવા માટે નામ લખો",
    paymentDetails: "ચુકવણીની વિગતો",
    basePrice: "આધાર કિંમત",
    staff: "સ્ટાફ",
    subTotal: "સબટોટલ",
    durationMonths: "અવધિ (મહિનાઓ)",
    selectADuration: "સમયગાળો પસંદ કરો",
    oneMonth: "1 મહિનો",
    twoMonths: "2 મહિના",
    threeMonths: "3 મહિના",
    sixMonths: "6 મહિના",
    twelveMonths: "12 મહિના",
    eighteenMonths: "18 મહિના",
    twentyFourMonths: "24 મહિના",
    twoMonthsFree: "(2 મહિના મફત)",
    threeMonthsFree: "(3 મહિના મફત)",
    fiveMonthsFree: "(5 મહિના મફત)",
    yourAccountHasBeen: "તમારું એકાઉન્ટ રહ્યું છે",
    renewed: "નવીકરણ",
    upgraded: "અપગ્રેડ કરેલ",
    successfully: "સફળતાપૂર્વક",
    yourSubscription: "તમારું લવાજમ",
    youAreCurrentlyEnrolledOnA: "તમે હાલમાં a પર નોંધાયેલા છો",
    pleaseChooseAPaymentOption: "કૃપા કરીને ચુકવણી વિકલ્પ પસંદ કરો",
    planRenewal: "નવીકરણની યોજના બનાવો",
    planUpgrade: "યોજના અપગ્રેડ કરો",
    pleaseSelectDurationToPayFor:
      "કૃપા કરીને તે સમયગાળો પસંદ કરો કે જેના માટે તમે ચૂકવણી કરવા માગો છો",
    staffAccounts: "સ્ટાફ એકાઉન્ટ્સ",
    ecommerce: "ઈકોમર્સ",
    pleaseSelectAPlan: "કૃપા કરીને એક યોજના પસંદ કરો",
    includeAddons: "-ડ-ludeન્સ શામેલ કરો",
    viewTransactions: "વ્યવહારો જુઓ",
    customerHasNoCompletedTansactions:
      "ગ્રાહક પાસે હજી સુધી કોઈ પૂર્ણ કરાયેલ વ્યવહાર નથી",
    branch: "શાખા",
    enterNumberOfEcommerceBranches: "ઈકોમર્સ શાખાઓની સંખ્યા દાખલ કરો",
    enterNumberOfEcommerceBranchesToPay:
      "તમે જે ઈકોમર્સ શાખાઓ માટે ચૂકવણી કરવા માંગો છો તેની સંખ્યા દાખલ કરો",
    ecommerceIntegration: "ઈકોમર્સ એકીકરણ",
    enterNumberOfBranches: "શાખાઓની સંખ્યા દાખલ કરો",
    enterNumberOfAdditionalBranchesToPay:
      "વધારાની શાખાઓની સંખ્યા દાખલ કરો જેના માટે તમે ચૂકવણી કરવા માગો છો",
    enterNumberOfStaffs: "સ્ટાફની સંખ્યા દાખલ કરો",
    enterNumberOfStaffsToPayFor:
      "સ્ટાફની સંખ્યા દાખલ કરો જેના માટે તમે ચૂકવણી કરવા માંગો છો",
    discountApplies: "ડિસ્કાઉન્ટ લાગુ પડે છે",
    starsOnThe: "પર તારાઓ",
    offer: "ઓફર",
    get: "મેળવો",
    moreStarsToRedeem: "રિડીમ કરવા માટે વધુ તારા",
    taxVat: "કર (વેટ)",
    callCashierToCompletePayment: "ચુકવણી પૂર્ણ કરવા માટે કેશિયરને કલ કરો",
    receipt: "રસીદ",
    dear: "પ્રિય",
    thankYouForYourOrderFindGoods:
      "તમારા ઓર્ડર માટે આભાર. કૃપા કરીને આપેલ નીચે આપેલ માલ શોધો, સંમતિ મુજબ.",
    shippingNote: "શિપિંગ નોંધ",
    enterShippingNote: "શિપિંગ નોંધ દાખલ કરો",
    shippingAddress: "પહોંચાડવાનું સરનામું",
    enterShippingAddress: "શિપિંગ સરનામું દાખલ કરો",
    wellDoneYouAreDueToRedeem: "શાબ્બાશ! તમે રિડીમ કરવાના છો",
    toGetYourRewardNextVisit:
      "તમારી આગલી મુલાકાતમાં તમારો પુરસ્કાર મેળવવા માટે. આભાર",
    pointsOnThe: "પર પોઇન્ટ",
    morePointsToRedeem: "રિડીમ કરવા માટે વધુ પોઈન્ટ",
    showCode: "કોડ બતાવો",
    toGetYourRewardOnNextVisit:
      "તમારી આગલી મુલાકાતમાં તમારો પુરસ્કાર મેળવવા માટે. આભાર",
    earn: "કમાઓ",
    delivaryNote: "ડિલિવરી નોંધ",
    draftSales: "ડ્રાફ્ટ સેલ્સ",
    startDate: "પ્રારંભ તારીખ",
    endDate: "અંતિમ તારીખ",
    orders: "ઓર્ડર",
    checkout: "ચેકઆઉટ",
    noProductItem: "કોઈ પ્રોડક્ટ આઇટમ નથી",
    selectProductImage: "ઉત્પાદન છબી પસંદ કરો",
    selectCountry: "દેશ પસંદ કરો",
    selectRegion: "રાજ્ય/પ્રદેશ પસંદ કરો",
    printProductTag: "ઉત્પાદન ટેગ છાપો",
    transactionReference: "વ્યવહાર સંદર્ભ",
    Cashier: "કેશિયર",
    Manager: "મેનેજર",
    Owner: "માલિક",
    Admin: "સંચાલક",
    addPartners: "ભાગીદારો ઉમેરો",
    addNewLoyaltyPartner: "નવો લોયલ્ટી પાર્ટનર ઉમેરો",
    pleaseEnterCompanyName: "કૃપા કરીને કંપનીનું નામ દાખલ કરો",
    companyName: "કંપની નું નામ",
    pleaseEnterCompanyRepName: "કૃપા કરીને કંપનીના પ્રતિનિધિનું નામ દાખલ કરો",
    companyRepName: "કંપનીના પ્રતિનિધિનું નામ",
    pleaseEnterCompanyRepEmail: "કૃપા કરીને કંપનીના પ્રતિનિધિનો ઈમેલ દાખલ કરો",
    companyRepEmail: "કંપનીના પ્રતિનિધિનો ઈમેલ",
    pleaseEnterCompanyRepPhone:
      "કૃપા કરીને કંપનીના પ્રતિનિધિનો ફોન નંબર દાખલ કરો",
    companyRepPhone: "કંપનીના પ્રતિનિધિનો ફોન નંબર",
    addReward: "પુરસ્કાર ઉમેરો",
    pleaseEnterRewardName: "કૃપા કરીને પુરસ્કારનું નામ દાખલ કરો",
    rewardName: "પુરસ્કારનું નામ",
    rewardQuantity: "પુરસ્કાર જથ્થો",
    rewardDescription: "પુરસ્કારનું વર્ણન",
    rewardType: "પુરસ્કારનો પ્રકાર",
    pleaseEnterRewardType: "કૃપા કરીને પુરસ્કારનો પ્રકાર દાખલ કરો",
    pleaseEnterRewardQuantity: "કૃપા કરીને પુરસ્કારની માત્રા દાખલ કરો",
    pleaseEnterRewardDescription: "કૃપા કરીને પુરસ્કારનું વર્ણન દાખલ કરો",
    fineDining: "સારુ જમણ",
    luxuryFashion: "લક્ઝરી ફેશન",
    hotels: "હોટેલ્સ",
    travel: "પ્રવાસ",
    foodAndBeverage: "ખોરાક અને પીણા",
    fashion: "ફેશન",
    health: "આરોગ્ય",
    furniture: "ફર્નિચર",
    entertainment: "મનોરંજન",
    automobile: "ઓટોમોબાઈલ",
    education: "શિક્ષણ",
    beautyAndSpa: "સુંદરતા અને સ્પા",
    staycation: "સ્ટેકેશન",
    events: "ઘટનાઓ",
    trips: "પ્રવાસો",
    oilAndGas: "તેલ અને ગેસ",
    laundry: "લોન્ડ્રી",
    partnerCategory: "ભાગીદાર શ્રેણી",
    freeItem: "મફત આઇટમ",
  },
  German: {
    cashier: "Kassierer",
    manager: "Manager",
    owner: "Eigentümer",
    online: "online",
    offline: "offline",
    changePassword: "Passwort ändern",
    currentPasswordMessage: "Bitte geben Sie Ihr aktuelles Passwort ein",
    passwordMessage: "Bitte geben Sie Ihr Passwort ein",
    currentPassword: "Aktuelles Passwort",
    password: "Passwort",
    confirmPasswordMessage: "Bitte bestätigen Sie Ihr Passwort!",
    confirmPassword: "Kennwort bestätigen",
    sendViaEmail: "Per E-Mail senden",
    sendViaWhatsapp: "Per WhatsApp senden",
    downloadPdf: "PDF Herunterladen",
    paid: "bezahlt",
    unpaid: "unbezahlt",
    partial: "teilweise",
    closeInvoice: "Möchten Sie die Rechnung schließen?",
    closeInvoiceConfirmation:
      "Rechnung darf nicht gespeichert werden. Möchten Sie schließen?",
    yes: "Jawohl",
    no: "Nein",
    invoice: "Rechnung",
    wasDeducted: "wurde abgezogen",
    for: "zum",
    item: "Artikel",
    addProduct: "Produkt hinzufügen",
    paymentReference: "Zahlungsreferenz",
    amountPaid: "Bezahlter Betrag",
    discountAmount: "Rabattbetrag",
    amountDue: "Offener Betrag",
    amount: "Betragen",
    dueDate: "Geburtstermin",
    paymentType: "Zahlungsart",
    card: "Karte",
    cash: "Kasse",
    bankTransfer: "Banküberweisung",
    paymentMessage: "Zahlungsnachricht",
    description: "Beschreibung",
    sendReceipt: "Quittung senden",
    delete: "Löschen",
    save: "Speichern",
    resend: "Erneut senden",
    saveAndSend: "Speichern",
    invoiceSaved: "Rechnung gespeichert!",
    selectPaymentMethod: "Bitte wähle eine Zahlmethode!",
    selectCustomer: "Bitte wählen Sie einen Kunden aus!",
    cartEmptyError:
      "Warenkorbliste darf nicht leer sein, Rechnung schließen und Artikel in den Warenkorb legen!",
    subscriptionExpired:
      "Ihr Abonnement ist abgelaufen und Ihr Konto ist jetzt eingeschränkt. Klicken Sie auf die Schaltfläche unten, um Ihr Konto zu erneuern",
    renew: "Erneuern",
    holdOn: "Festhalten",
    customerBank: "Kundenbank",
    cancel: "Abbrechen",
    selectACustomer: "Kunde auswählen",
    invoiceSuccessfulPdfError:
      "Rechnung erfolgreich erstellt, aber die PDF-Generierung dauert länger als üblich. Bitte schauen Sie in Kürze wieder vorbei.",
    downloadingInvoice: "Rechnung herunterladen",
    downloadingReceipt: "Quittung herunterladen",
    whatsappReceiptError:
      "Beim Senden der Quittung über WhatsApp ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    receiptToWhatsapp: "Quittung an WhatsApp weitergeleitet",
    thankYouForPatronage: "Vielen Dank für Ihre Schirmherrschaft",
    hereIsYourReceipt: "Hier ist Ihr Zahlungsbeleg",
    errorSendingEmailReceipt:
      "Beim Senden der Quittung per E-Mail ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support",
    receiptSentToEmail: "Quittung wurde an die E-Mail des Kunden gesendet",
    invoiceSentToEmail: "Rechnung wurde an die E-Mail des Kunden gesendet",
    invoiceSuccessWhatsappError:
      "Rechnung erfolgreich erstellt, aber beim Senden an WhatsApp ist ein Fehler aufgetreten. Versuchen Sie es erneut auf der Rechnungsliste",
    invoiceSuccessfulEmailError:
      "Rechnung erfolgreich erstellt, aber beim Senden als E-Mail ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut über die Rechnungsliste",
    invoiceSentToWhatsapp: "Rechnung an WhatsApp weitergeleitet",
    hello: "Hallo",
    pleaseDownloadInvoice: "Bitte Rechnung herunterladen",
    pleaseDownloadReceipt: "Bitte Quittung herunterladen",
    from: "von",
    email: "Email",
    upgrade: "Aktualisierung",
    youAreOnFreePlan: "Sie haben einen kostenlosen Plan.",
    clickOn: "Klicke auf",
    yourPlanInFewSteps: " Ihr Abonnement in wenigen Schritten.",
    to: "zu",
    yourSubscriptionHasExpired:
      "Ihr Abonnement ist abgelaufen und Ihr Konto ist jetzt eingeschränkt.",
    days: "Tage",
    yourSubscriptionExpiresIn: "Ihr Loystar-Abonnement läuft in . ab",
    createAcount: "Ein Konto erstellen",
    signIn: "Einloggen",
    continue: "Weitermachen",
    enterOtp: "OTP-PIN eingeben",
    enterValidOtp: "Bitte geben Sie eine gültige PIN ein",
    pin: "STIFT",
    tokenSentToMail: "Ein Token wurde an Ihre E-Mail gesendet",
    passwordResetSuccessful: "Passwort zurücksetzen war erfolgreich",
    somethingWentWrong: "Etwas ist schief gelaufen!",
    resetPassword: "Passwort zurücksetzen",
    iHaveResetCode: "Ich habe einen Passwort-Reset-Code",
    pleaseEnterEmail: "Bitte geben Sie ihre E-Mail-Adresse ein",
    aTokenWillBeSentToEmail: "Ein Token wird an Ihre E-Mail gesendet",
    enterEmail: "Geben sie ihre E-Mail Adresse ein",
    sendinYourToken: "Senden Ihres Tokens...",
    makeSureItMatchesPassword:
      "Stellen Sie sicher, dass es mit Ihrem neuen Passwort übereinstimmt",
    pleaseChooseNewPassword: "Bitte wählen Sie ein neues Passwort",
    chooseNewPassword: "Wähle ein neues Passwort",
    enterNewPassword: "Geben Sie Ihr neues Passwort zur Bestätigung ein",
    enterTokenSent:
      "Geben Sie das Token ein, an das Ihre E-Mail gesendet wurde",
    resetToken: "Token zurücksetzen",
    resettingPassword: "Passwort zurücksetzen...",
    signUp: "Anmeldung",
    adminSignInWithEmail:
      " Der Administrator meldet sich mit E-Mail an, während sich der Mitarbeiter mit dem Benutzernamen anmeldet.",
    pleaseEnterEmailOrUsername:
      "Bitte geben Sie Ihre E-Mail oder Ihren Benutzernamen ein",
    emailOrUsername: "E-Mail Adresse oder Benutzername",
    pleaseEnterPassword: "Bitte Passwort eingeben",
    createAnAccount: "Ein Konto erstellen",
    forgotPassword: "Passwort vergessen?",
    enterPhoneNumber: "Telefonnummer eingeben",
    personalData: "Persönliche Daten",
    validateConfirmationCOde: "Bestätigungscode validieren",
    pleaseEnterFirstName: "Bitte geben Sie Ihren Vornamen ein",
    pleaseEnterPhoneNumber: "Bitte geben sie ihre Telefonnummer ein",
    pleaseEnterLastName: "Bitte geben Sie ihren Nachnamen ein",
    pleaseEnterBusinessName: "Bitte geben Sie Ihren Firmennamen ein",
    firstName: "Vorname",
    lastName: "Nachname",
    businessName: "Firmenname",
    previous: "Vorherige",
    next: "Nächste",
    pleaseSelectBusinessCategory: "Bitte wählen Sie Ihre Unternehmenskategorie",
    pleaseEnterValidEmail: "Bitte geben Sie eine gültige Email-Adresse ein",
    pleaseEnterPostCode: "Bitte Postleitzahl eingeben",
    postCode: "Postleitzahl",
    phoneNumberInUse: "Diese Telefonnummer wird bereits verwendet!",
    emailInUse: "Diese E-Mail wird bereits verwendet!",
    foodAndDrinks: "Essen und Trinken",
    salonAndBeauty: "Salon und Schönheit",
    fashionAndAccessories: "Mode und Accessoires",
    gymAndFitness: "Fitnessstudio und Fitness",
    travelAndHotel: "Reisen und Hotel",
    homeAndGifts: "Zuhause und Geschenke",
    washingAndCleaning: "Waschen und Reinigen",
    gadgetsAndElectronics: "Geräte und Elektronik",
    groceries: "Lebensmittel",
    others: "Andere",
    submit: "einreichen",
    accountCreatedSuccessful: "Ihr Konto wurde erfolgreich angelegt.",
    pleaseEnterAddress: "Bitte geben Sie Ihre Adresse ein",
    addressLine1: "Anschrift Zeile 1",
    addressLine2: "Adresszeile 2",
    choosePassword: "Wählen sie ein Passwort",
    passwordMustBe6Characters: "Passwort muss mindestens 6 Zeichen lang sein.",
    howDidYouHearLoystar: "Wie sind Sie auf Loystar aufmerksam geworden?",
    referralCode: "Referenzcode",
    byClickingTheButton:
      " Indem Sie auf den untenstehenden Button klicken, stimmen Sie den",
    termsAndSevice: "Bedingungen",
    wereCreatingAccount: "Wir erstellen Ihr Konto",
    proceed: "Fortfahren",
    verificationCodeMustBe6: "Der Bestätigungscode muss 6-stellig sein",
    pleaseEnter6DigitCode: "Bitte 6-stelligen Code eingeben",
    enterVerificationCode: "Bestätigungscode eingeben",
    resendToken: "Token erneut senden",
    verify: "Verifizieren",
    transactions: "Transaktionen",
    todaySales: "Der Verkauf von heute",
    salesHistory: "Verkaufshistorie",
    supplyHistory: "Lieferhistorie",
    new: "Neu",
    invoices: "Rechnungen",
    disbursements: "Auszahlungen",
    offlineSales: "Offline-Verkäufe",
    products: "Produkte",
    customers: "Kunden",
    multiLevelLoyalty: "Mehrstufige Loyalität",
    loyaltyPrograms: "Treueprogramme",
    members: "Mitglieder",
    appStore: "Appstore",
    orderMenu: "Menü bestellen",
    settings: "Einstellungen",
    staffAndBranches: "Mitarbeiter und Niederlassungen",
    myAccount: "Mein Konto",
    switchToSellMode: "Wechseln Sie in den Verkaufsmodus",
    signOut: "Austragen",
    getFreeSubscription: "Holen Sie sich ein kostenloses Abonnement",
    onlyNumbersAllowed: "Es sind nur Zahlen erlaubt",
    yourAccountMustBe10Digits:
      "Ihre Kontonummer muss eine 10-stellige Zahl sein",
    yourBvnMustBe11: "Ihre BVN muss eine 11-stellige Nummer sein",
    pleaseSelectBank: "Bitte wählen Sie Ihre Bank",
    selectYourBank: "Wählen Sie Ihre Bank",
    enterBankAccountNumber: "Bankkontonummer eingeben",
    enterBvnNumber: "Geben Sie Ihre BVN ein",
    connectBankAccount: "Bankkonto verbinden",
    passwordResetSuccessfulAndSignOut:
      "Ihr Passwort wurde erfolgreich zurückgesetzt. Klicken Sie unten auf die Schaltfläche Abmelden, um sich erneut anzumelden",
    enterCurrentPassword: "Aktuelles Passwort eingeben",
    pleaseEnterCurrentPassword: "Bitte aktuelles Passwort eingeben",
    phoneNumber: "Telefonnummer",
    sex: "Sex",
    dateOfBirth: "Geburtsdatum",
    state: "Bundesland",
    country: "Land",
    loyaltyId: "Treue-ID",
    createdAt: "Hergestellt in",
    noOfTransactionsMade: "Anzahl der getätigten Transaktionen",
    yourDownloadWillStart: "Ihr Download beginnt in Kürze",
    exportCustomers: "Kunden exportieren",
    youhaveSuccessfullyToppedUp:
      "Sie haben Ihre SMS Units erfolgreich aufgeladen.",
    youNowHave: "Sie haben jetzt",
    smsUnits: "SMS-Einheiten",
    enterNumberOfSmsUnits:
      "Geben Sie die Anzahl der SMS-Einheiten ein, die Sie kaufen möchten",
    pleaseEnterANumericValue: "Bitte geben Sie einen numerischen Wert ein",
    pay: "Zahlen",
    accountEditedSuccessfully: "Ihr Konto wurde erfolgreich bearbeitet.",
    youAeCurrentlyOn: "Du bist gerade dabei",
    plan: "Planen",
    userData: "Benutzerdaten",
    businessData: "GESCHÄFTSDATEN",
    businessCategory: "Business-Kategorie",
    pleaseSelectCurrency: "Bitte wählen Sie Ihre Währung",
    selectYourCurrency: "Wählen Sie Ihre Währung",
    purchaseMoreSmsUnits:
      "Kaufen Sie weitere SMS-Einheiten mit dem untenstehenden Formular",
    youHave: "Sie haben",
    atLeast4SmsUnitsRrequired:
      "Zur Verifizierung werden mindestens 4 SMS-Einheiten benötigt, bitte aufladen!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Bitte überprüfen Sie Ihr Bankkonto, um die Anforderungen von "Know Your Customer" (KYC) zu erfüllen. Auf diese Weise können Sie Zahlungen per USSD oder Sofortüberweisung einziehen, Bestellungen von Kunden entgegennehmen und die Transaktionsabwicklung abwickeln. Gilt nur für nigerianische Händler. Bitte drücken Sie die Schaltfläche unten, um zu beginnen.',
    reConnectBankAccount: "Bankkonto wieder verbinden",
    accountName: "Kontobezeichnung",
    accountNumber: "Kontonummer",
    bankName: "Bank Name",
    verified: "Verifiziert",
    accountDetails: "Kontodetails",
    kycBankAccount: "KYC",
    createTier: "Stufe erstellen",
    fileUploadSuccessful: "Datei erfolgreich hochgeladen",
    fileUploadFailed: "Datei-Upload fehlgeschlagen",
    createLoyaltyProgram: "Erstellen Sie ein Treueprogramm",
    createLoyalty: "Schaffen Sie Loyalität",
    name: "Name",
    loyaltyArtwork: "Treue-Kunstwerk",
    clickToUpload: "Zum Hochladen klicken",
    amountToPointsRatio: "Verhältnis von Betrag zu Punkten",
    points: "Punkte",
    recommendedAmountToPointRatio:
      "Empfohlen : ₦1 bis 1Punkt. d.h. für jede ausgegebene Naira erhalten Ihre Kunden 1 Punkt",
    pleaseTypeIn: "Bitte eintippen",
    toDeleteLoyalty: "diese Loyalität löschen",
    deleteLoyalty: "Loyalität löschen",
    toConfirm: "bestätigen",
    edit: "Bearbeiten",
    pointsAwardedSuccessfully: "Erfolgreich vergebene Punkte.",
    enterPointValueToAward:
      "Geben Sie den Punktwert ein, den Sie an Kunden vergeben möchten",
    award: "Vergeben",
    awardPointValuesToCustomer: "Prämienpunktwert für den Kunden",
    enrollMembersToLoyalty: "Registrieren Sie Mitglieder für Loyalty",
    awardPoints: "Prämienpunkte",
    enroll: "Einschreiben",
    home: "Heim",
    loyalty: "Loyalität",
    enrollCustomers: "Kunden einschreiben",
    selected: "Ausgewählt",
    customer: "Kunde",
    loyaltyActivationSuccessful: "Die Treueaktivierung ist erfolgreich.",
    loyaltyDeactivationSuccessful:
      "Die Loyalitätsdeaktivierung ist erfolgreich.",
    viewTier: "Ebene anzeigen",
    deactivate: "Deaktivieren",
    activate: "aktivieren Sie",
    actions: "Aktionen",
    nameOfLoyalty: "Name der Treue",
    loyaltyStatus: "LoyalitätsstatusLoyalitätsstatus",
    numberOfTiers: "Anzahl der Stufen",
    createdOn: "Erstellt am",
    createATier: "Eine Stufe erstellen",
    stopCreatingTierConfirmation:
      "Möchten Sie das Erstellen einer Stufe beenden?",
    stopEditingTierConfirmation:
      "Möchten Sie die Bearbeitung dieser Stufe beenden?",
    nameOfTier: "Name der Stufe",
    minimumSpendingTarget: "Mindestausgabenziel",
    maximumSpendingTarget: "Maximales Ausgabenziel",
    minimumSpendingTargetRequired: "Mindestausgabenziel ist erforderlich",
    maximumSpendingTargetRequired: "maximales Ausgabenziel ist erforderlich",
    rewardSponsor: "Belohnungssponsor",
    pleaseChooseARewardSponsor: "Bitte wählen Sie einen Prämiensponsor",
    self: "Selbst",
    partner: "Partner",
    rewardPartner: "Prämienpartner",
    pleaseSelectRewardPartner: "Bitte wählen Sie Ihren Prämienpartner aus",
    rewards: "Belohnung",
    pleaseSelectAReward: "Bitte wähle eine Belohnung",
    instructionsOnRedeemingReward:
      "Anweisungen zum Einlösen der Prämie durch den Kunden",
    pleaseFillInThisField: "Bitte füllen Sie dieses Feld aus!",
    toDeleteThisTier: " diese Stufe löschen",
    deleteTier: "Stufe löschen",
    viewMembers: "Mitglieder anzeigen",
    membersEnrolled: "Eingeschriebene Mitglieder",
    instruction: "Anweisung",
    membersIn: "Mitglieder in",
    availableTiersInLoyalty: "Verfügbare Stufe(n) im Treueprogramm",
    tiers: "Stufen",
    totalTier: "GESAMT STUFE",
    availableLoyaltyProgramme: "Verfügbares Treueprogramm",
    totalLoyalties: "GESAMT-LOYALITÄTEN",
    memberDetails: "Mitgliedsdetails",
    nameOfCustomer: "Name des Kunden",
    address: "Die Anschrift",
    allEnrolledMembers: "Alle eingeschriebenen Mitglieder",
    thisIsToWishYouHappyBirthday:
      "Dies ist, um Ihnen alles Gute zum Geburtstag und ein erfolgreiches Leben zu wünschen. Danke für alles, was Sie an Loyster sind. Frohe Feiern!",
    inputAnOfferPlease: "Geben Sie bitte ein Angebot ein",
    pleaseSelectTheInsertPoint:
      "Bitte wählen Sie die Einfügemarke in der Nachricht und klicken Sie erneut",
    birthdayOfferAndMessage: "Geburtstagsangebot und Nachricht",
    birthdayOffer: "Geburtstagsangebot",
    birthdayMessage: "Geburtstagsnachricht",
    noOfferFound: "Kein Angebot gefunden",
    settingABirthdayOffer:
      "Das Festlegen eines Geburtstagsangebots ermöglicht es Kunden, dieses Angebot an ihrem Geburtstag per SMS zu erhalten",
    createOffer: "Angebot erstellen",
    whatIsTheOffer: "Was ist das Angebot?",
    editMessage: "Nachricht bearbeiten",
    insert: "Einfügung",
    theNameOfCustomerInserted: "Der Name des Kunden wird hier eingefügt",
    theBirtdayOfferInserted: "Das Geburtstagsangebot wird hier eingefügt",
    youSuccessfullyAddedNewBranch:
      "Sie haben erfolgreich eine neue Filiale hinzugefügt!",
    addNewBranch: "Neue Filiale hinzufügen",
    addBranch: "Filiale hinzufügen",
    additionalBranchWillIncur: "Zusätzliche Verzweigung würde anfallen",
    perBranch: "pro Filiale",
    ecommerceBranchCosts: "Kosten für E-Commerce-Filialen",
    pleaseEnterBranchName: "Bitte geben Sie den Filialnamen ein",
    pleaseEnterBranchAddress1:
      "Bitte geben Sie die Adresszeile 1 der Filiale ein",
    enterBranchAddress1: "Geben Sie die Adresszeile der Filiale ein 1",
    enterBranchAddress2: "Geben Sie die Adresszeile der Filiale ein 1",
    pleaseEnterBranchAddress2:
      "Bitte geben Sie die Adresszeile der Filiale 2 ein",
    enterBranchName: "Geben Sie den Filialnamen ein",
    successfullyAddedStaff:
      "Sie haben erfolgreich einen neuen Mitarbeiter hinzugefügt!",
    addNewStaff: "Neue Mitarbeiter hinzufügen",
    addStaff: "Mitarbeiter hinzufügen",
    additionalStaffWillIncur: "Zusätzliches Personal würde anfallen",
    perStaff: "pro Mitarbeiter.",
    pleaseEnterStaffEmail: "Bitte geben Sie die E-Mail des Personals ein",
    pleaseEnterStaffUsername:
      "Bitte geben Sie den Benutzernamen des Personals ein",
    pleaseEnterStaffPassword: "Bitte geben Sie das Passwort des Personals ein",
    pleaseSelectStaffRole: "Bitte wählen Sie die Rolle des Mitarbeiters",
    selectStaffRole: "Wählen Sie die Rolle des Mitarbeiters aus",
    enterStaffEmail: "Geben Sie die E-Mail des Mitarbeiters ein",
    enterStaffUsername: "Geben Sie den Benutzernamen des Mitarbeiters ein",
    enterStaffPassword: "Geben Sie das Passwort des Personals ein",
    spacesNotAllowedInUsername: "Leerzeichen im Benutzernamen nicht erlaubt",
    admin: "Administrator",
    pleaseSelectBusinessToAttachStaff:
      "Bitte wählen Sie ein Unternehmen aus, um Mitarbeiter anzuhängen",
    searchForBranchToAttachStaff:
      "Nach Filiale suchen, um Mitarbeiter anzuhängen",
    username: "Nutzername",
    role: "Rolle",
    areYouSureToDeleteThis: "Möchtest du das wirklich löschen",
    branches: "Geäst",
    upgradeYourPlan: "Aktualisieren Sie Ihren Plan.",
    add: "HINZUFÜGEN",
    addNew: "Neue hinzufügen",
    customerWithEmailAlreadyExists:
      "Kunde mit E-Mail/Telefonnummer existiert bereits!",
    successfullyAddedNewCustomer:
      "Sie haben erfolgreich einen neuen Kunden hinzugefügt!",
    addCustomer: "Kunden hinzufügen",
    pleaseEnterCustomerFirstName: "Bitte Vornamen des Kunden eingeben",
    pleaseEnterCustomerLastName: "Bitte geben Sie den Nachnamen des Kunden ein",
    pleaseEnterCustomerPhoneNumber:
      "Bitte geben Sie die Telefonnummer des Kunden ein",
    pleaseEnterCustomerEmail: "Bitte geben Sie die E-Mail des Kunden ein",
    pleaseEnterCustomerAddressLine:
      "Bitte geben Sie die Adresszeile des Kunden ein",
    pleaseEnterCustomerOtherAddress:
      "Bitte geben Sie die andere Adresse des Kunden ein",
    pleaseSelectCustomerGender: "Bitte wählen Sie das Geschlecht des Kunden",
    gender: "Geschlecht",
    male: "Männlich",
    female: "Weiblich",
    bank: "Bank",
    selectBank: "Bank auswählen",
    stateOrRegionOrProvince: "Bundesland/Region/Provinz",
    customerNotes: "Kundenhinweise",
    sendSms: "SMS senden",
    editCustomer: "Kunde bearbeiten",
    redeemReward: "Belohnung einlösen",
    issueLoyaltyCard: "Treuekarte ausstellen",
    deleteCustomer: "Kunden löschen",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Sie haben erfolgreich eine Treuemitgliedschafts-ID zugewiesen",
    noMembershipIdAvailable:
      "Keine Mitgliedsausweise vorhanden. Bitte kontaktieren Sie uns unter hello@loystar.co",
    sendEmail: "E-Mail senden",
    membershipPoints: "Mitgliedschaftspunkte",
    customerDetails: "Kundendetails",
    close: "Nah dran",
    loyaltyBalance: "Treueguthaben",
    pointsBalance: "Punktestand",
    starBalance: "Star-Guthaben",
    requiredPoints: "Erforderliche Punkte",
    requiredStars: "Erforderliche Sterne",
    reddemCode: "Code einlösen",
    toDeleteThisCustomer: "diesen Kunden löschen",
    customerHasBeenDeletedSuccessfully: "Kunde wurde erfolgreich gelöscht!",
    customerWithPhoneAlreadyExists: "Kunde mit Telefonnummer existiert bereits",
    customerWasSuccessfullyEdited: "Kunde wurde erfolgreich bearbeitet",
    anErrorOccured: "Es ist ein Fehler aufgetreten",
    phoneNumberIncludeCountry: "Telefonnummer (mit Ländervorwahl)",
    yourFileQueuedForUpload:
      "Ihre Datei wurde zum Hochladen in die Warteschlange gestellt. Bitte aktualisieren Sie die Seite nach einigen Sekunden.",
    thereWasAnErrorPerformingOperation:
      "Beim Ausführen des Vorgangs ist ein Fehler aufgetreten!",
    pleaseSelectFile: "Bitte wählen Sie eine Datei aus!",
    oopsOnlyCsvFilesAllowed:
      "Hoppla! Nur CSV-Dateien zulässig. Bitte laden Sie eine .csv-Datei hoch.",
    fileShouldBeSmallerThan5Mb:
      "Die Datei sollte kleiner als 5 MB sein. Wenn Sie eine größere Datei haben, senden Sie bitte eine E-Mail an support@loystar.co. Dankeschön",
    customerFirstNameIsRequired: "Vorname des Kunden ist in Zeile erforderlich",
    customerPhoneNumberIsRequired:
      "Kundentelefonnummer ist in Zeile erforderlich",
    importCustomers: "Kunden importieren",
    upload: "Hochladen",
    clickIconToDownloadCsv:
      "Klicken Sie auf dieses Symbol, um die CSV-Dateivorlage herunterzuladen.",
    getGoogleSheetVersion: "Holen Sie sich die Google-Tabellenversion",
    clickOrDragFileToUpload:
      "Klicken oder ziehen Sie die Datei in diesen Bereich, um sie hochzuladen",
    missingOrInvalidColumnHeader:
      "Fehlende oder ungültige Spaltenüberschrift. Bitte folgen Sie dem Vorlagenformat. Dankeschön.",
    youHaveImported: "Sie haben importiert",
    youSuccessfullyRedeemedReward:
      "Sie haben Ihre Prämie erfolgreich eingelöst!",
    sixDigitCode: "Sechsstelliger Code",
    pleaseEnterCustomerRewardCode:
      "Bitte geben Sie den Prämiencode des Kunden ein",
    enterRewardCode:
      "Geben Sie den Prämiencode ein. (Bei dem Prämiencode muss die Groß-/Kleinschreibung beachtet werden)",
    selectLoyaltyProgram: "Treueprogramm auswählen",
    stamps: "Briefmarken",
    smsUnitsLow: "SMS-Einheiten niedrig",
    pleaseTopUpSmsUnits: "Bitte SMS-Einheiten aufladen",
    smsSuccessfullySentForDelivery: "SMS erfolgreich zur Zustellung gesendet!",
    sendSmsTo: "SMS senden an",
    allCustomers: "Alle Kunden",
    unitsAvailable: "Verfügbare Einheiten",
    pleaseTypeInTheMessage: "Bitte geben Sie die Nachricht ein",
    message: "Nachricht",
    charactersRemaining: "verbleibende Zeichen",
    avoidUseOfSpecialCharacters:
      "Vermeiden Sie die Verwendung von Sonderzeichen und Emojis, um SMS-Einheiten zu sparen.",
    note: "Notiz",
    errorFetchingCustomersMultilevelDetail:
      "Fehler beim Abrufen von mehrstufigen Kundendetails",
    search: "Suche",
    reset: "Zurücksetzen",
    importCustomer: "Kunden importieren",
    addNewCustomer: "Neuen Kunden hinzufügen",
    sendSmsBroadcast: "SMS-Broadcast senden",
    totalCustomers: "GESAMTKUNDEN",
    disbursementDetails: "Auszahlungsdetails",
    paidBy: "Bezahlt von",
    disbursed: "Ausgezahlt",
    bankAccountName: "Kontoname",
    bankAccountNumber: "Kontonummer",
    transferInitiated: "Übertragung eingeleitet",
    transferCompleted: "Übertragung abgeschlossen",
    pleaseEnterAValid: "Bitte geben Sie ein gültiges ein",
    begin: "Start",
    end: "Ende",
    date: "Datum",
    paymentDate: "Zahlungsdatum",
    selectDisbursementDuration: "Auszahlungsdauer auswählen",
    totalSettled: "Gesamt abgerechnet",
    totalUnsettled: "Total verunsichert",
    toDeleteThisSale: "um diesen Verkauf zu löschen",
    draftSaleDeletedSuccessfully: "Verkaufsentwurf wurde erfolgreich gelöscht!",
    deleteSale: "Verkauf löschen",
    pleaseTypeInYourTotal:
      "Bitte geben Sie Ihre Gesamtsumme ein, um das Löschen zu bestätigen",
    billDetails: "Rechnungsdetails",
    printBill: "Rechnung drucken",
    servedBy: "Serviert von",
    total: "Gesamt",
    createdDate: "Erstellungsdatum",
    createdTime: "Erstellte Zeit",
    status: "Status",
    loginSuccessful: "Anmeldung erfolgreich",
    pleaseWaitWhileWeConnectAccount:
      "Bitte warten Sie, während wir Ihr Konto verbinden",
    connectionFailedTryAgain:
      "Verbindung fehlgeschlagen. Bitte versuche es erneut.",
    connectionSuccessful: "Verbindung erfolgreich",
    viewDetails: "Details anzeigen",
    enable: "Aktivieren",
    free: "Kostenlos",
    cost: "Kosten",
    visitWebsite: "Besuche die Website",
    pleaseUpgradeYourPlanToPro:
      "Bitte aktualisieren Sie Ihren Plan auf Pro Plus, um diese App zu aktivieren",
    connectYourBankAccount:
      "Verbinden Sie Ihr Bankkonto, um Zahlungen empfangen zu können.",
    disable: "Deaktivieren",
    enableApp: "App aktivieren",
    addNewProductToInvoice: "Neues Produkt zur Rechnung hinzufügen",
    toDeleteThisInvoice: "diese Rechnung löschen",
    invoiceDeletedSuccessfully: "Rechnung wurde erfolgreich gelöscht!",
    deleteInvoice: "Rechnung löschen",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Sie haben erfolgreich eine Rechnungszahlungsnachricht hinzugefügt!",
    pleaseEnterPaymentInstructions: "Bitte geben Sie Zahlungsanweisungen ein",
    invoiceId: "Rechnungs-ID",
    paidDate: "Zahlungsdatum",
    reference: "Referenz",
    productAdded: "Produkt hinzugefügt",
    productOutOfStock: "Produkt ausverkauft. Bitte aufstocken.",
    totalInvoices: "GESAMTRECHNUNGEN",
    totalPaidInvoices: "GESAMT BEZAHLTER RECHNUNGEN",
    totalUnpaidInvoices: "INSGESAMT UNBEZAHLTER RECHNUNGEN",
    loyaltyProgramDeleted: "Treueprogramm gelöscht",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dieses Treueprogramm wurde erfolgreich gelöscht",
    thisLoyaltyProgramEditedSuccessfully:
      "Dieses Treueprogramm wurde erfolgreich bearbeitet",
    loyaltyProgramAddedSuccessfully:
      "Treueprogramm wurde erfolgreich hinzugefügt",
    loyaltyProgramEdited: "Treueprogramm bearbeitet",
    loyaltyProgramAdded: "Treueprogramm hinzugefügt",
    loyaltyDetails: "Loyalitätsdetails",
    doYouWantToCloseDialog: "Möchten Sie diesen Dialog schließen?",
    pleaseEnterLoyaltyName: "Bitte geben Sie den Namen Ihrer Treue ein",
    programType: "Programmtyp",
    pleaseSelectProgramType: "Bitte wählen Sie einen Programmtyp",
    simplePoints: "Einfache Punkte",
    stampsProgram: "Briefmarkenprogramm",
    threshold: "Schwelle",
    pleaseEnterLoyaltyThreshold: "Bitte geben Sie die Treueschwelle ein",
    reward: "Belohnen",
    pleaseEnterLoyaltyReward: "Bitte geben Sie die Belohnung der Treue ein",
    totalUserPoints: "Gesamtzahl der Benutzerpunkte",
    totalUserStamps: "Gesamtzahl der Benutzerstempel",
    spendingTarget: "Ausgabenziel",
    spendingTargetHint1:
      "Das Ausgabenziel gibt an, wie viel ein Kunde ausgeben muss, um die Prämie zu verdienen. 1 Währungswert = 1 Punkt.",
    spendingTargetHint2:
      "Das Briefmarkenziel gibt an, wie viele Briefmarken ein Kunde sammeln muss, um die Prämie zu erhalten. z.B. 5",
    addNewLoyaltyProgram: "Neues Treueprogramm hinzufügen",
    addLoyaltyProgram: "Treueprogramm hinzufügen",
    loyaltyProgramType: "Treueprogrammtyp",
    pleaseSelectLoyaltyProgramType:
      "Bitte wählen Sie die Art des Treueprogramms",
    nameOfProgram: "Name des Programms",
    pleaseEnterProgramName: "Bitte Programmnamen eingeben",
    whatIsTheReward: "Was ist die Belohnung?",
    egNextPurchaseIsFree: "Z.B. Der nächste Einkauf ist kostenlos",
    customerName: "Kundenname",
    guestCustomer: "Gastkunde",
    orderConfirmedSuccessfully: "Bestellung erfolgreich bestätigt",
    orderCancelledSuccessfully: "Bestellung erfolgreich storniert",
    confirmOrder: "Bestellung bestätigen",
    cancelOrder: "Bestellung stornieren",
    allOrders: "Alle Bestellungen",
    totalOrders: "GESAMTBESTELLUNGEN",
    doYouWantToAcceptOrder: "Möchten Sie diese Bestellung annehmen?",
    doYouWantToCancelOrder: "Möchten Sie diese Bestellung stornieren?",
    orderDetails: "Bestelldetails",
    orderCreatedAt: "Bestellung erstellt am",
    supplier: "Anbieter",
    productName: "Produktname",
    quantity: "Menge",
    unitPrice: "Stückpreis",
    receivedBy: "Empfangen von",
    reportFrom: "Bericht von",
    totalSupplies: "Gesamtvorräte",
    allRightsReserved: "Alle Rechte vorbehalten",
    toDeleteThisTransaction: "um diese Transaktion zu löschen",
    transactionDeletedSuccessfully:
      "Transaktion wurde erfolgreich gelöscht. Der Bestand wurde in den Bestand zurückgeführt.",
    deleteTransaction: "Transaktion löschen",
    transactionDetails: "Transaktionsdetails",
    printReceipt: "Druckeingang",
    channel: "Kanal",
    discount: "Rabatt",
    profit: "Profitieren",
    discountedSales: "Ermäßigte Verkäufe",
    errorFetchingRecord: "Fehler beim Abrufen des Datensatzes",
    exportTransactions: "Exporttransaktionen",
    errorFetchingSalesRecord:
      "Fehler beim Abrufen des Verkaufsdatensatzes für den Export.",
    totalSellingPrice: "Gesamtverkaufspreis",
    totalCostPrice: "Gesamtkostenpreis",
    appliedDiscount: "Angewendeter Rabatt",
    noOfItems: "Anzahl der Artikel",
    sales: "Der Umsatz",
    export: "Export",
    totalProfit: "Gesamtgewinn",
    totalBalanceInPeriod: "Gesamtsaldo in Periode",
    allTimeSales: "All-Time-Sales",
    records: "Aufzeichnungen",
    todaysSales: "Der Verkauf von heute",
    transaction: "Transaktion",
    youHaveExceededTotalNumberOfProducts:
      "Sie haben die in Ihrem Plan zulässige Gesamtzahl der Produkte überschritten. Aktualisieren Sie Ihren Plan, um ein höheres Limit zu genießen.",
    youNeedToHaveLoyaltyProgram:
      "Sie benötigen ein Treueprogramm, um diese Funktion nutzen zu können!",
    price: "Preis",
    category: "Kategorie",
    stockTracking: "Bestandsverfolgung",
    stockCount: "Bestandsanzahl",
    taxed: "Besteuert",
    originalPrice: "Original Preis",
    costPrice: "Selbstkostenpreis",
    unit: "Einheit",
    productImage: "Produktbild",
    taxRate: "Steuersatz",
    taxType: "Steuertyp",
    trackInventory: "Inventar verfolgen",
    variants: "Varianten",
    hasVariants: "Hat Varianten",
    importProduct: "Produkt importieren",
    exportProducts: "Produkte exportieren",
    addNewProduct: "Neues Produkt hinzufügen",
    viewCategory: "Kategorie anzeigen",
    viewSuppliers: "Lieferanten anzeigen",
    receiveInventory: "Inventar erhalten",
    printAllProductsTag: "Alle Produkte drucken",
    deleteAll: "Alles löschen",
    totalProducts: "GESAMTPRODUKTE",
    youveSuccessfullyAddedANewCategory:
      "Sie haben erfolgreich eine neue Kategorie hinzugefügt",
    addNewCategory: "neue Kategorie hinzufügen",
    addCategory: "Kategorie hinzufügen",
    categoryName: "Kategoriename",
    pleaseEnterCategoryName: "Bitte Kategorienamen eingeben",
    stampsTarget: "Briefmarken Ziel",
    sendInventory: "Inventar senden",
    productDetails: "Produktdetails",
    youveSuccessfullyEditedThisCategory:
      "Sie haben diese Kategorie erfolgreich bearbeitet",
    update: "Aktualisieren",
    categoryList: "Kategorieliste",
    categories: "Kategorien",
    enterQuantityToUpdate: "Geben Sie eine zu aktualisierende Menge ein",
    inventorySentSuccessfully: "Das Inventar wurde erfolgreich gesendet!",
    updateInventory: "Inventar aktualisieren",
    currentQuantity: "Aktuelle Menge",
    pleaseEnterQuantityToAdd:
      "Bitte geben Sie die Menge ein, die Sie hinzufügen möchten",
    pleaseSelectABranch: "Bitte wählen Sie eine Filiale",
    searchForBranch: "Filiale suchen",
    youCantSendMoreThanStock:
      "Sie können nicht mehr versenden, als Sie auf Lager haben",
    send: "Senden",
    pleaseEnterQuantityToSend:
      "Bitte geben Sie die Menge ein, die Sie versenden möchten",
    productNameIsRequiredOnRow: "Produktname ist in Zeile erforderlich",
    productCategoryIsRequiredOnRow:
      "Produktkategorie ist in Zeile erforderlich",
    productPriceIsRequiredOnRow: "Produktpreis ist in Zeile erforderlich",
    productUnitIsRequiredOnRow: "Produkteinheit ist in Reihe erforderlich",
    productQuantityIsRequiredOnRow: "Produktmenge ist in Zeile erforderlich",
    productVariantsRequireTracking: "Produktvarianten erfordern Tracking!",
    pleaseAddVariantClickButton:
      "Bitte fügen Sie eine Variante hinzu, indem Sie auf den Hinzufügen-Button klicken!",
    totalVariantsMoreThanSelectedQuantity:
      "Gesamtvarianten sind mehr als die ausgewählte Produktmenge. Bitte reduzieren Sie die Variantenmenge",
    productEditedSuccessfully: "Produkt wurde erfolgreich bearbeitet!",
    fileTooLargeLessThan4Mb:
      "Dateigröße ist zu groß! Die Dateigröße sollte weniger als 4 MB betragen.",
    suchVariantAlreadyExist: "Eine solche Variante gibt es bereits",
    addVariants: "Varianten hinzufügen",
    editProduct: "Produkt bearbeiten",
    pleaseEnterProductName: "Bitte Produktnamen eingeben",
    pleaseEnterProductPrice: "Bitte Produktpreis eingeben",
    pleaseEnterProductOriginalPrice:
      "Bitte geben Sie den Originalpreis des Produkts ein",
    productDescription: "Produktbeschreibung",
    selectProductCategory: "Produktkategorie auswählen",
    pleaseSelectProductCategory: "Bitte Produktkategorie auswählen",
    productCostPrice: "Produktkostenpreis",
    productSellingPrice: "Produktverkaufspreis",
    productOriginalPrice: "Produkt Originalpreis",
    maxFileSizeAllowedIs4Mb: "max. Die zulässige Dateigröße beträgt 4 MB",
    productsWithPicturesArePublished:
      "Produkte mit Bildern werden auf Discover veröffentlicht, um Bestellungen zu erhalten",
    shouldThisProductBeTracked: "Soll dieses Produkt verfolgt werden?",
    pleaseSelectStockUnit: "Bitte Lagereinheit auswählen",
    stockUnit: "Lagereinheit",
    bag: "TASCHE",
    bottle: "FLASCHE",
    bunch: "BÜNDEL",
    can: "KANN",
    carton: "KARTON",
    crate: "KISTE",
    cup: "TASSE",
    dozen: "DUTZEND",
    gigabytes: "GIGABYTES",
    gram: "GRAMM",
    kilogram: "KILOGRAMM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAAR",
    pieces: "STÜCKE",
    plate: "TELLER",
    tonne: "Tonne (MT)",
    uNIT: "EINHEIT",
    yard: "GARTEN",
    pleaseEnterProductQuantity: "Bitte Produktmenge eingeben",
    productQuantity: "Produktmenge",
    isThisProductTaxed: "Wird dieses Produkt besteuert?",
    selectTaxType: "Steuerart auswählen",
    pleaseSelectTaxType: "Bitte wählen Sie die Steuerart",
    progressive: "Progressiv",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Bitte Produktsteuersatz eingeben",
    doesProductHaveVariants: "Gibt es bei diesem Produkt Varianten?",
    type: "Typ",
    value: "Wert",
    pleaseEnterVariantType: "Bitte Variantentyp eingeben",
    pleaseEnterVariantValue: "Bitte geben Sie den Wert der Variante ein",
    pleaseEnterVariantPrice: "Bitte geben Sie den Preis der Variante ein",
    pleaseEnterVariantQuantity: "Bitte Menge der Variante eingeben",
    productDeletedSuccessfully: "Produkt wurde erfolgreich gelöscht!",
    categoryDeletedSuccessfully: "Kategorie wurde erfolgreich gelöscht!",
    toDeleteThisProduct: "um dieses Produkt zu löschen",
    invalidProductQuantity: "Ungültige Produktmenge",
    quantityAddedIsOverStock:
      "Die Menge, die Sie hinzufügen, übersteigt Ihren Lagerbestand.",
    itemInventoryNotTracked: "Artikelbestand wird nicht verfolgt",
    addBulkQuantity: "Massenmenge hinzufügen",
    enterBulkQuantity: "Massenmenge eingeben",
    pleaseEnterBulkQuantity: "Bitte Massenmenge eingeben",
    youveSuccessfullyAddedANewProduct:
      "Sie haben erfolgreich ein neues Produkt hinzugefügt!",
    pleaseEnterProductSellingPrice:
      "Bitte geben Sie den Verkaufspreis des Produkts ein",
    doYouWantToTrackProductStock: "Möchten Sie den Produktbestand verfolgen?",
    sellingPrice: "Verkaufspreis",
    setProductExpiryReminder: "Erinnerung an Produktablauf festlegen",
    productExpiryDate: "Produktablaufdatum",
    startRemindingFrom: "Beginnen Sie mit der Erinnerung von",
    productSuppliesAddedSuccessfully:
      "Sie haben Produktzubehör erfolgreich hinzugefügt.",
    addProductSupplies: "Produktzubehör hinzufügen",
    pleaseSelectSupplier: "Bitte Lieferanten auswählen",
    nameOfProduct: "Produktname",
    pleaseSelectProduct: "Bitte Produkt auswählen",
    productVariant: "Produktvariante",
    pleaseSelectAVariant: "Bitte wählen Sie eine Variante",
    pleaseEnterUnitPrice: "Bitte Stückpreis eingeben",
    businessBranch: "Geschäftszweig",
    pleaseSelectBranch: "Bitte Filiale auswählen",
    youveSuccessfullyAddedANewSupplier:
      "Sie haben erfolgreich einen neuen Lieferanten hinzugefügt",
    addSupplier: "Lieferanten hinzufügen",
    pleaseEnterSupplierEmail: "Bitte geben Sie die E-Mail des Lieferanten ein",
    pleaseAddADescription: "Bitte fügen Sie eine Beschreibung hinzu",
    anErrorOccuredProductsDeleted:
      "Beim Ausführen des Vorgangs ist ein Fehler aufgetreten. Bitte beachten Sie, dass möglicherweise einige Produkte dabei gelöscht wurden",
    bulkDelete: "Massenlöschung",
    youAreAboutToDelete: "Du bist im Begriff zu löschen",
    product: "Produkt",
    isDueToRedeem: "ist zu erlösen",
    aReward: "eine Belohnung",
    pleaseSelectCustomerToReeemReward:
      "Bitte wählen Sie einen Kunden aus, um die Prämie einzulösen.",
    youHaveNoLoyaltyProgramRunning:
      "Sie haben kein aktives Treueprogramm am Laufen",
    customerHhasNoPointsInLoyaltyProgram:
      "Der Kunde hat keine Punkte in diesem Treueprogramm",
    proceedWithPayment: "Mit Zahlung fortfahren?",
    youAreAboutToPayForTransactionUsingPoints:
      "Sie sind dabei, die Transaktion mit Punkten zu bezahlen.",
    customerHas: "Kunde hat",
    worth: "Wert",
    andIsNotSufficientToPayForTransaction:
      "und reicht nicht aus, um diese Transaktion zu bezahlen. Möchten sie das Guthaben mit einer anderen Zahlungsmethode hinzufügen?",
    addCustomerLoyalty: "Kundenbindung hinzufügen",
    pleaseAddCustomerFirst:
      "Bitte fügen Sie zuerst einen Kunden hinzu oder wählen Sie ihn aus.",
    pleaseWaitWhileWeGetReady:
      "Bitte warten Sie, während wir uns fertig machen",
    lowStock: "Geringer Bestand",
    pleaseEnterAmountTendered:
      "Bitte geben Sie den ausgeschriebenen Betrag ein",
    areYouSureToBookSaleOffline:
      "Möchten Sie diesen Verkauf wirklich offline buchen?",
    saleWouldBeBookedAutomatically:
      "Der Verkauf wird automatisch gebucht, wenn Sie Ihr Internet einschalten",
    offlineSalesBookingCancelled: "Offline-Verkaufsbuchung storniert",
    covid19Message:
      "COVID19: Waschen Sie Ihre Hände mit Seife oder desinfizieren Sie, um die Ausbreitung zu stoppen. Bleiben Sie immer sicher",
    saleSuccessfullyRecorded: "Verkauf erfolgreich erfasst!",
    sendReceiptToEmail: "Quittung an E-Mail senden",
    sendThankYouSms: "Dankes-SMS senden",
    sendShippingDetails: "Versanddetails senden",
    addLoyalty: "Treue hinzufügen",
    searchCustomerNameOrNumber: "Kundenname oder -nummer suchen",
    clickTheSearchCustomerBox:
      "Klicken Sie auf Kundenbox suchen und Karte scannen",
    enterProductPrice: "Produktpreis eingeben",
    enterPriceFor: "Preis eingeben für",
    searchForProduct: "Produkt suchen",
    all: "Alle",
    backToDashboard: "Zurück zum Dashboard",
    viewDraftSales: "Verkaufsentwürfe anzeigen",
    variantSelected: "Variante ausgewählt",
    variant: "Variante",
    thePreviousVariantSelectionNotAvailable:
      "Die bisherige Variantenauswahl steht für die neue preislich ausgewählte Variante nicht zur Verfügung, bitte ändern Sie Ihre Auswahl.",
    pleaseSupplyPositiveQuantityNumber:
      "Bitte geben Sie eine positive Mengennummer an",
    lowStockFor: "Geringer Lagerbestand für",
    clearVariants: "Übersichtliche Varianten",
    pleaseEnterQuantity: "Bitte Menge eingeben",
    picture: "Bild",
    redemptionToken: "Einlösungs-Token",
    token: "Zeichen",
    totalSpent: "Gesamtausgaben",
    confirmPayment: "Bestätige Zahlung",
    hasPaymentBeenMade: "Wurde die Zahlung erfolgreich verarbeitet?",
    enterTransactionReference:
      "Geben Sie die Transaktionsreferenz ein, mit der Sie bezahlt haben",
    pleaseSelectACustomer: "Bitte wählen Sie einen Kunden aus!",
    areYouSureToApplyDiscount: "Möchten Sie den Rabatt wirklich anwenden?",
    addYourBankAccountToEnableUssd:
      "Fügen Sie Ihr Bankkonto hinzu, um die sofortige USSD-Überweisung per uPay zu aktivieren",
    totalAmountToPay: "Zu zahlender Gesamtbetrag",
    doYouWantToCancelTransaction: "Möchten Sie diese Transaktion stornieren?",
    savePrintBill: "Rechnung speichern/drucken",
    enterAmountCollectedForCustomer:
      "Geben Sie den für den Kunden eingezogenen Betrag ein",
    recordSale: "Plattenverkauf",
    checkOutWith: "Schaut vorbei mit",
    instantTransfer: "Sofortüberweisung",
    dialTheUSSDCode: "Wählen Sie den USSD-Code",
    pleaseSelectABank: "Bitte wählen Sie eine Bank",
    payWithUSSD: "Bezahlen mit USSD",
    sendBillTo: " - Rechnung senden an",
    waitingForUSSDTransfer: "Warten auf USSD-Transfer",
    percent: "Prozent",
    applyDiscount: "Rabatt anwenden",
    thisBillHasBeenSaved: "Diese Rechnung wurde gespeichert",
    saveDraft: "Entwurf speichern",
    pleaseTypeANameToIdentifyCustomer:
      "Bitte geben Sie einen Namen ein, um den Kunden zu identifizieren",
    paymentDetails: "Zahlungsdetails",
    basePrice: "Grundpreis",
    staff: "Mitarbeiter",
    subTotal: "Zwischensumme",
    durationMonths: "Dauer (Monate)",
    selectADuration: "Wählen Sie eine Dauer",
    oneMonth: "1 Monat",
    twoMonths: "2 Monate",
    threeMonths: "3 Monate",
    sixMonths: "6 Monate",
    twelveMonths: "12 Monate",
    eighteenMonths: "18 Monate",
    twentyFourMonths: "24 Monate",
    twoMonthsFree: "(2 Monate kostenlos)",
    threeMonthsFree: "(3 Monate kostenlos)",
    fiveMonthsFree: "(5 Monate kostenlos)",
    yourAccountHasBeen: "Ihr Konto wurde",
    renewed: "erneuert",
    upgraded: "aktualisiert",
    successfully: "erfolgreich",
    yourSubscription: "Ihr Abonnement",
    youAreCurrentlyEnrolledOnA: "Sie sind derzeit bei a . eingeschrieben",
    pleaseChooseAPaymentOption: "Bitte wählen Sie eine Zahlungsoption",
    planRenewal: "Verlängerung planen",
    planUpgrade: "Plan-Upgrade",
    pleaseSelectDurationToPayFor:
      "Bitte wählen Sie die Dauer, für die Sie bezahlen möchten",
    staffAccounts: "Mitarbeiterkonten",
    ecommerce: "E-Commerce",
    pleaseSelectAPlan: "Bitte wählen Sie einen Plan",
    includeAddons: "Add-ons einschließen",
    viewTransactions: "Transaktionen anzeigen",
    customerHasNoCompletedTansactions:
      "Der Kunde hat noch keine abgeschlossenen Transaktionen",
    branch: "Zweig",
    enterNumberOfEcommerceBranches:
      "Geben Sie die Anzahl der E-Commerce-Filialen ein",
    enterNumberOfEcommerceBranchesToPay:
      "Geben Sie die Anzahl der E-Commerce-Filialen ein, für die Sie bezahlen möchten",
    ecommerceIntegration: "E-Commerce-Integration",
    enterNumberOfBranches: "Geben Sie die Anzahl der Filialen ein",
    enterNumberOfAdditionalBranchesToPay:
      "Geben Sie die Anzahl der zusätzlichen Filialen ein, für die Sie bezahlen möchten",
    enterNumberOfStaffs: "Anzahl der Mitarbeiter eingeben",
    enterNumberOfStaffsToPayFor:
      "Geben Sie die Anzahl der Mitarbeiter ein, für die Sie bezahlen möchten",
    discountApplies: "Rabatt gilt",
    starsOnThe: "Sterne auf dem",
    offer: "Angebot",
    get: "Werden",
    moreStarsToRedeem: "mehr Sterne zum Einlösen",
    taxVat: "Steuer (MwSt.)",
    callCashierToCompletePayment:
      "Kassierer anrufen, um die Zahlung abzuschließen",
    receipt: "Erhalt",
    dear: "lieb",
    thankYouForYourOrderFindGoods:
      "Vielen Dank für Ihren Auftrag. Nachfolgend finden Sie die gelieferten Waren, wie vereinbart.",
    shippingNote: "Lieferschein",
    enterShippingNote: "Lieferschein eingeben",
    shippingAddress: "Lieferanschrift",
    enterShippingAddress: "Lieferadresse eingeben",
    wellDoneYouAreDueToRedeem: "Gut erledigt! Sie müssen einlösen",
    toGetYourRewardNextVisit:
      "um Ihre Belohnung bei Ihrem nächsten Besuch zu erhalten. Dankeschön",
    pointsOnThe: "Punkte auf dem",
    morePointsToRedeem: "mehr Punkte zum Einlösen",
    showCode: "Code anzeigen",
    toGetYourRewardOnNextVisit:
      "um Ihre Belohnung bei Ihrem nächsten Besuch zu erhalten. Dankeschön",
    earn: "Verdiene",
    delivaryNote: "Lieferschein",
    draftSales: "Verkaufsentwurf",
    startDate: "Startdatum",
    endDate: "Endtermin",
    orders: "Aufträge",
    checkout: "Auschecken",
    noProductItem: "Kein Produktartikel",
    selectProductImage: "Produktbild auswählen",
    selectCountry: "Land auswählen",
    selectRegion: "Bundesland/Region auswählen",
    printProductTag: "Produktetikett drucken",
    transactionReference: "Transaktionsreferenz",
    Cashier: "Kassierer",
    Manager: "Manager",
    Owner: "Eigentümer",
    Admin: "Administrator",
    addPartners: "Partner hinzufügen",
    addNewLoyaltyPartner: "Neuen Treuepartner hinzufügen",
    pleaseEnterCompanyName: "Bitte geben Sie den Firmennamen ein",
    companyName: "Name der Firma",
    pleaseEnterCompanyRepName:
      "Bitte geben Sie den Namen des Firmenvertreters ein",
    companyRepName: "Name des Unternehmensvertreters",
    pleaseEnterCompanyRepEmail:
      "Bitte geben Sie die E-Mail-Adresse des Unternehmensvertreters ein",
    companyRepEmail: "E-Mail des Firmenvertreters",
    pleaseEnterCompanyRepPhone:
      "Bitte geben Sie die Telefonnummer des Unternehmensvertreters ein",
    companyRepPhone: "Telefonnummer des Firmenvertreters",
    addReward: "Belohnung hinzufügen",
    pleaseEnterRewardName: "Bitte geben Sie den Namen der Prämie ein",
    rewardName: "Belohnungsname",
    rewardQuantity: "Belohnungsmenge",
    rewardDescription: "Beschreibung der Belohnung",
    rewardType: "Belohnungstyp",
    pleaseEnterRewardType: "Bitte geben Sie den Prämientyp ein",
    pleaseEnterRewardQuantity: "Bitte geben Sie die Prämienmenge ein",
    pleaseEnterRewardDescription: "Bitte geben Sie die Prämienbeschreibung ein",
    fineDining: "Vornehmes Speisen",
    luxuryFashion: "Luxusmode",
    hotels: "Hotels",
    travel: "Reisen",
    foodAndBeverage: "Nahrungsmittel und Getränke",
    fashion: "Mode",
    health: "Gesundheit",
    furniture: "Möbel",
    entertainment: "Entertainment",
    automobile: "Automobil",
    education: "Ausbildung",
    beautyAndSpa: "Schönheit und Wellness",
    staycation: "Aufenthalt",
    events: "Veranstaltungen",
    trips: "Reisen",
    oilAndGas: "Öl und Gas",
    laundry: "Wäscherei",
    partnerCategory: "Partnerkategorie",
    freeItem: "Kostenloser Artikel",
  },
  Greek: {
    cashier: "ταμίας",
    manager: "διευθυντής",
    owner: "ιδιοκτήτης",
    online: "Σε σύνδεση",
    offline: "χωρίς σύνδεση",
    changePassword: "Άλλαξε κωδικό",
    currentPasswordMessage: "Εισαγάγετε τον τρέχοντα κωδικό πρόσβασής σας",
    passwordMessage: "Παρακαλώ εισάγετε τον κωδικό σας",
    currentPassword: "Τρέχων κωδικός πρόσβασης",
    password: "Κωδικός πρόσβασης",
    confirmPasswordMessage: "Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασής σας!",
    confirmPassword: "Επιβεβαίωση Κωδικού",
    sendViaEmail: "Αποστολή μέσω email",
    sendViaWhatsapp: "Στείλτε μέσω WhatsApp",
    downloadPdf: "Λήψη PDF",
    paid: "επί πληρωμή",
    unpaid: "απλήρωτος",
    partial: "μερικός",
    closeInvoice: "Θέλετε να κλείσετε το Τιμολόγιο;",
    closeInvoiceConfirmation:
      "Το τιμολόγιο μπορεί να μην αποθηκευτεί. Θέλετε να κλείσετε;",
    yes: "Ναί",
    no: "όχι",
    invoice: "Τιμολόγιο",
    wasDeducted: "αφαιρέθηκε",
    for: "Για",
    item: "Είδος",
    addProduct: "Προσθήκη Προϊόντος",
    paymentReference: "Αναφορά πληρωμής",
    amountPaid: "Ποσό που καταβάλλεται",
    discountAmount: "Ποσό έκπτωσης",
    amountDue: "Οφειλόμενο ποσό",
    amount: "Ποσό",
    dueDate: "Ημερομηνία λήξης",
    paymentType: "Τρόπος πληρωμής",
    card: "Κάρτα",
    cash: "Μετρητά",
    bankTransfer: "Τραπεζική μεταφορά",
    paymentMessage: "Μήνυμα πληρωμής",
    description: "Περιγραφή",
    sendReceipt: "Αποστολή απόδειξης",
    delete: "Διαγράφω",
    save: "Αποθηκεύσετε",
    resend: "Επανάληψη αποστολής",
    saveAndSend: "Αποθηκεύσετε",
    invoiceSaved: "Το τιμολόγιο αποθηκεύτηκε!",
    selectPaymentMethod: "Επιλέξτε τρόπο πληρωμής!",
    selectCustomer: "Επιλέξτε έναν Πελάτη!",
    cartEmptyError:
      "Η λίστα καλαθιών δεν μπορεί να είναι κενή, κλείστε το τιμολόγιο και προσθέστε το στοιχείο στο καλάθι!",
    subscriptionExpired:
      "Η συνδρομή σας έχει λήξει και ο λογαριασμός σας είναι πλέον περιορισμένος. Κάντε κλικ στο κουμπί παρακάτω για να ανανεώσετε τον λογαριασμό σας",
    renew: "Ανανεώνω",
    holdOn: "Περίμενε",
    customerBank: "Τράπεζα Πελατών",
    cancel: "Ματαίωση",
    selectACustomer: "Επιλέξτε Πελάτης",
    invoiceSuccessfulPdfError:
      "Το τιμολόγιο δημιουργήθηκε με επιτυχία, αλλά η δημιουργία PDF διαρκεί περισσότερο από το συνηθισμένο. Παρακαλώ ελέγξτε ξανά σύντομα.",
    downloadingInvoice: "Λήψη τιμολογίου",
    downloadingReceipt: "Λήψη απόδειξης",
    whatsappReceiptError:
      "Παρουσιάστηκε σφάλμα κατά την αποστολή της απόδειξης μέσω WhatsApp. Δοκιμάστε ξανά.",
    receiptToWhatsapp: "Η απόδειξη προωθήθηκε στο WhatsApp",
    thankYouForPatronage: "Σας ευχαριστούμε για την υποστήριξή σας",
    hereIsYourReceipt: "Εδώ είναι η απόδειξη πληρωμής σας",
    errorSendingEmailReceipt:
      "Παρουσιάστηκε σφάλμα κατά την αποστολή της απόδειξης μέσω e-mail. Δοκιμάστε ξανά ή επικοινωνήστε με την υποστήριξη",
    receiptSentToEmail: "Η απόδειξη στάλθηκε στο email του πελάτη",
    invoiceSentToEmail: "Το τιμολόγιο έχει σταλεί στο email του πελάτη",
    invoiceSuccessWhatsappError:
      "Το τιμολόγιο δημιουργήθηκε με επιτυχία, αλλά προέκυψε σφάλμα κατά την αποστολή στο WhatsApp. Δοκιμάστε ξανά στη λίστα τιμολογίων",
    invoiceSuccessfulEmailError:
      "Το τιμολόγιο δημιουργήθηκε με επιτυχία, αλλά προέκυψε σφάλμα κατά την αποστολή ως e-mail. Δοκιμάστε ξανά από τη λίστα τιμολογίων",
    invoiceSentToWhatsapp: "Το τιμολόγιο προωθήθηκε στο WhatsApp",
    hello: "γεια σας",
    pleaseDownloadInvoice: "Παρακαλώ κατεβάστε το τιμολόγιο",
    pleaseDownloadReceipt: "Παρακαλώ κατεβάστε την απόδειξη",
    from: "από",
    email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
    upgrade: "Αναβαθμίζω",
    youAreOnFreePlan: "Είστε σε Δωρεάν Πρόγραμμα.",
    clickOn: "Κάντε κλικ στο",
    yourPlanInFewSteps: " τη συνδρομή σας σε μερικά γρήγορα βήματα.",
    to: "προς το",
    yourSubscriptionHasExpired:
      "Η συνδρομή σας έχει λήξει και ο λογαριασμός σας είναι πλέον περιορισμένος.",
    days: "μέρες",
    yourSubscriptionExpiresIn: "Η συνδρομή σας στο Loystar λήγει το",
    createAcount: "Δημιουργία λογαριασμού",
    signIn: "Συνδεθείτε",
    continue: "Να συνεχίσει",
    enterOtp: "Εισαγάγετε τον κωδικό OTP PIN",
    enterValidOtp: "Εισαγάγετε έναν έγκυρο κωδικό PIN",
    pin: "ΚΑΡΦΙΤΣΑ",
    tokenSentToMail: "Ένα μήνυμα έχει σταλεί στο email σας",
    passwordResetSuccessful: "Η επαναφορά κωδικού πρόσβασης ήταν επιτυχής",
    somethingWentWrong: "Κάτι πήγε στραβά!",
    resetPassword: "Επαναφέρετε τον κωδικό πρόσβασης",
    iHaveResetCode: "Έχω έναν κωδικό επαναφοράς κωδικού πρόσβασης",
    pleaseEnterEmail: "Παρακαλώ εισάγετε το email σας",
    aTokenWillBeSentToEmail: "Ένα μήνυμα θα σταλεί στο email σας",
    enterEmail: "Συμπληρώστε το email σας",
    sendinYourToken: "Αποστολή του διακριτικού σας ...",
    makeSureItMatchesPassword:
      "Βεβαιωθείτε ότι ταιριάζει με τον νέο κωδικό πρόσβασής σας",
    pleaseChooseNewPassword: "Επιλέξτε νέο κωδικό πρόσβασης",
    chooseNewPassword: "Επιλέξτε νέο κωδικό πρόσβασης",
    enterNewPassword: "Εισαγάγετε τον νέο κωδικό πρόσβασής σας για επιβεβαίωση",
    enterTokenSent: "Εισαγάγετε το διακριτικό που στάλθηκε η αλληλογραφία σας",
    resetToken: "Επαναφορά κουπονιού",
    resettingPassword: "Επαναφορά κωδικού πρόσβασης ...",
    signUp: "Εγγραφείτε",
    adminSignInWithEmail:
      " Ο διαχειριστής συνδέεται με email ενώ το προσωπικό συνδέεται με όνομα χρήστη.",
    pleaseEnterEmailOrUsername:
      "Παρακαλώ εισάγετε το email ή το όνομα χρήστη σας",
    emailOrUsername: "E-mail ή το όνομα χρήστη",
    pleaseEnterPassword: "Παρακαλώ εισάγετε κωδικό πρόσβασης",
    createAnAccount: "Δημιουργία λογαριασμού",
    forgotPassword: "Ξεχάσατε τον κωδικό;",
    enterPhoneNumber: "Εισάγετε τον αριθμό τηλεφώνου",
    personalData: "Προσωπικά δεδομένα",
    validateConfirmationCOde: "Επικύρωση κωδικού επιβεβαίωσης",
    pleaseEnterFirstName: "Παρακαλώ εισάγετε το ονομα σας",
    pleaseEnterPhoneNumber: "Παρακαλώ εισάγετε τον αριθμό τηλεφώνου σας",
    pleaseEnterLastName: "Παρακαλώ εισάγετε το επώνυμό σας",
    pleaseEnterBusinessName: "Εισαγάγετε το όνομα της επιχείρησής σας",
    firstName: "Ονομα",
    lastName: "Επίθετο",
    businessName: "Επωνυμία Επιχείρησης",
    previous: "Προηγούμενος",
    next: "Επόμενο",
    pleaseSelectBusinessCategory: "Επιλέξτε την κατηγορία της επιχείρησής σας",
    pleaseEnterValidEmail: "Παρακαλώ εισάγετε μια έγκυρη διεύθυνση email",
    pleaseEnterPostCode: "Παρακαλώ εισάγετε τον ταχυδρομικό κώδικα",
    postCode: "ΤΑΧΥΔΡΟΜΙΚΟΣ ΚΩΔΙΚΟΣ",
    phoneNumberInUse: "Αυτός ο αριθμός τηλεφώνου χρησιμοποιείται ήδη!",
    emailInUse: "Αυτό το email χρησιμοποιείται ήδη!",
    foodAndDrinks: "Φαγητό και ποτά",
    salonAndBeauty: "Σαλόνι και ομορφιά",
    fashionAndAccessories: "Μόδα και αξεσουάρ",
    gymAndFitness: "Γυμναστήριο και Fitness",
    travelAndHotel: "Ταξίδια και ξενοδοχείο",
    homeAndGifts: "Σπίτι και Δώρα",
    washingAndCleaning: "Πλύσιμο και Καθαρισμός",
    gadgetsAndElectronics: "Gadgets και Ηλεκτρονικά",
    groceries: "Είδη παντοπωλείου",
    others: "Οι υπολοιποι",
    submit: "υποβάλλουν",
    accountCreatedSuccessful: "Ο λογαριασμός σας δημιουργήθηκε με επιτυχία.",
    pleaseEnterAddress: "Παρακαλώ εισάγετε τη διεύθυνσή σας",
    addressLine1: "Διεύθυνση 1",
    addressLine2: "Γραμμή Διεύθυνσης 2",
    choosePassword: "Επιλέξτε κωδικό",
    passwordMustBe6Characters:
      "Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες.",
    howDidYouHearLoystar: "Πώς ακούσατε για το Loystar;",
    referralCode: "κωδικός παραπομπής",
    byClickingTheButton: " Κάνοντας κλικ στο παρακάτω κουμπί, συμφωνείτε με το",
    termsAndSevice: "Οροι",
    wereCreatingAccount: "Δημιουργούμε τον λογαριασμό σας",
    proceed: "Προχωρώ",
    verificationCodeMustBe6:
      "Ο κωδικός επαλήθευσης πρέπει να αποτελείται από 6 ψηφία",
    pleaseEnter6DigitCode: "Παρακαλώ εισάγετε 6ψήφιο κωδικό",
    enterVerificationCode: "Βάλτε κωδικό επιβεβαίωσης",
    resendToken: "Επανάληψη αποστολής κουπονιού",
    verify: "Επαληθεύω",
    transactions: "Συναλλαγές",
    todaySales: "Οι σημερινές πωλήσεις",
    salesHistory: "Ιστορικό πωλήσεων",
    supplyHistory: "Ιστορικό εφοδιασμού",
    new: "Νέος",
    invoices: "Τιμολόγια",
    disbursements: "Εκταμιεύσεις",
    offlineSales: "Πωλήσεις εκτός σύνδεσης",
    products: "Προϊόντα",
    customers: "Οι πελάτες",
    multiLevelLoyalty: "Πιστότητα πολλαπλών επιπέδων",
    loyaltyPrograms: "Προγράμματα Πιστότητας",
    members: "Μέλη",
    appStore: "App Store",
    orderMenu: "Μενού Παραγγελίας",
    settings: "Ρυθμίσεις",
    staffAndBranches: "Προσωπικά και Υποκαταστήματα",
    myAccount: "Ο λογαριασμός μου",
    switchToSellMode: "Μετάβαση στη λειτουργία πώλησης",
    signOut: "Αποσύνδεση",
    getFreeSubscription: "Λάβετε δωρεάν συνδρομή",
    onlyNumbersAllowed: "Επιτρέπονται μόνο αριθμοί",
    yourAccountMustBe10Digits:
      "ο αριθμός του λογαριασμού σας πρέπει να είναι 10ψήφιος αριθμός",
    yourBvnMustBe11: "Το BVN πρέπει να είναι 11ψήφιος αριθμός",
    pleaseSelectBank: "Επιλέξτε την τράπεζά σας",
    selectYourBank: "Επιλέξτε την Τράπεζά σας",
    enterBankAccountNumber: "Εισαγάγετε τον Αριθμό Τραπεζικού Λογαριασμού",
    enterBvnNumber: "Εισαγάγετε το BVN σας",
    connectBankAccount: "Συνδέστε τον τραπεζικό λογαριασμό",
    passwordResetSuccessfulAndSignOut:
      "Ο κωδικός πρόσβασής σας έχει επαναφερθεί με επιτυχία. Κάντε κλικ στο κουμπί Αποσύνδεση παρακάτω για να συνδεθείτε ξανά",
    enterCurrentPassword: "Πληκτρολογείστε τον υπάρχοντα κωδικό",
    pleaseEnterCurrentPassword:
      "Παρακαλώ εισάγετε τον τρέχοντα κωδικό πρόσβασης",
    phoneNumber: "Τηλεφωνικό νούμερο",
    sex: "Φύλο",
    dateOfBirth: "Ημερομηνια γεννησης",
    state: "κατάσταση",
    country: "Χώρα",
    loyaltyId: "Αναγνωριστικό πίστης",
    createdAt: "Δημιουργήθηκε στο",
    noOfTransactionsMade: "Αριθμός συναλλαγών που πραγματοποιήθηκαν",
    yourDownloadWillStart: "Η λήψη σας θα ξεκινήσει σε λίγο",
    exportCustomers: "Εξαγωγή πελατών",
    youhaveSuccessfullyToppedUp:
      "Έχετε συμπληρώσει επιτυχώς τις μονάδες SMS σας.",
    youNowHave: "Τώρα έχετε",
    smsUnits: "Μονάδες SMS",
    enterNumberOfSmsUnits:
      "Εισαγάγετε τον αριθμό των μονάδων SMS που σκοπεύετε να αγοράσετε",
    pleaseEnterANumericValue: "Εισαγάγετε μια αριθμητική τιμή",
    pay: "Πληρωμή",
    accountEditedSuccessfully: "Ο λογαριασμός σας επεξεργάστηκε με επιτυχία.",
    youAeCurrentlyOn: "Αυτή τη στιγμή είστε ενεργός",
    plan: "Σχέδιο",
    userData: "Δεδομένα χρήστη",
    businessData: "ΣΤΟΙΧΕΙΑ ΕΠΙΧΕΙΡΗΣΗΣ",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Επιλέξτε το νόμισμά σας",
    selectYourCurrency: "Επιλέξτε το νόμισμά σας",
    purchaseMoreSmsUnits:
      "Αγοράστε περισσότερες μονάδες SMS χρησιμοποιώντας την παρακάτω φόρμα",
    youHave: "Εχεις",
    atLeast4SmsUnitsRrequired:
      "Απαιτούνται τουλάχιστον 4 μονάδες sms για επαλήθευση, συμπληρώστε!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Επαληθεύστε τον τραπεζικό λογαριασμό σας για να συμμορφώνεστε με τις απαιτήσεις «Γνωρίστε τον πελάτη σας» (KYC). Αυτό θα σας επιτρέψει να συλλέξετε πληρωμές μέσω USSD ή άμεση μεταφορά, να λάβετε παραγγελίες από πελάτες και να επεξεργαστείτε τον διακανονισμό συναλλαγών. Ισχύει μόνο για Νιγηριανούς εμπόρους. Πατήστε το κουμπί παρακάτω για να ξεκινήσετε.",
    reConnectBankAccount: "Συνδέστε ξανά τον τραπεζικό λογαριασμό",
    accountName: "Ονομα λογαριασμού",
    accountNumber: "Αριθμός λογαριασμού",
    bankName: "Ονομα τράπεζας",
    verified: "Επαληθευμένο",
    accountDetails: "λεπτομέρειες λογαριασμού",
    kycBankAccount: "KYC",
    createTier: "Δημιουργία Βαθμίδας",
    fileUploadSuccessful: "το αρχείο ανέβηκε επιτυχώς",
    fileUploadFailed: "η αποστολή αρχείου απέτυχε",
    createLoyaltyProgram: "Δημιουργήστε ένα πρόγραμμα αφοσίωσης",
    createLoyalty: "Δημιουργήστε Πιστότητα",
    name: "Ονομα",
    loyaltyArtwork: "Έργο τέχνης πίστης",
    clickToUpload: "Κάντε κλικ για μεταφόρτωση",
    amountToPointsRatio: "Ποσοστό Ποσοστώσεων",
    points: "Πόντοι",
    recommendedAmountToPointRatio:
      "Συνιστάται: to 1 έως 1 βαθμός. Δηλαδή για κάθε 1 Naira που ξοδεύεται, οι πελάτες σας παίρνουν 1 πόντο",
    pleaseTypeIn: "Παρακαλώ πληκτρολογήστε",
    toDeleteLoyalty: "για να διαγράψετε αυτήν την αφοσίωση",
    deleteLoyalty: "Διαγραφή Πιστότητας",
    toConfirm: "για να επιβεβαιώσετε",
    edit: "Επεξεργασία",
    pointsAwardedSuccessfully: "Οι πόντοι απονεμήθηκαν με επιτυχία.",
    enterPointValueToAward:
      "Εισαγάγετε την αξία του σημείου που θα θέλατε να απονείμετε στους πελάτες",
    award: "Βραβείο",
    awardPointValuesToCustomer: "Αξία πόντου απονομής στον πελάτη",
    enrollMembersToLoyalty: "Εγγραφή μελών στην αφοσίωση",
    awardPoints: "Πόντοι απονομής",
    enroll: "Εγγράφω",
    home: "Σπίτι",
    loyalty: "Αφοσίωση",
    enrollCustomers: "Εγγραφή Πελατών",
    selected: "Επιλεγμένο",
    customer: "Πελάτης",
    loyaltyActivationSuccessful: "Η ενεργοποίηση της πίστης είναι επιτυχής.",
    loyaltyDeactivationSuccessful:
      "Η Απενεργοποίηση Πιστότητας είναι επιτυχής.",
    viewTier: "Επίπεδο προβολής",
    deactivate: "Απενεργοποίηση",
    activate: "Θέτω εις ενέργειαν",
    actions: "Ενέργειες",
    nameOfLoyalty: "Όνομα Πιστότητας",
    loyaltyStatus: "Κατάσταση πιστότητας",
    numberOfTiers: "Αριθμός βαθμίδων",
    createdOn: "Δημιουργήθηκε στο",
    createATier: "Δημιουργήστε μια Βαθμίδα",
    stopCreatingTierConfirmation:
      "Θέλετε να σταματήσετε να δημιουργείτε μια βαθμίδα;",
    stopEditingTierConfirmation:
      "Θέλετε να σταματήσετε την επεξεργασία αυτής της βαθμίδας;",
    nameOfTier: "Όνομα βαθμίδας",
    minimumSpendingTarget: "Στόχος ελάχιστων δαπανών",
    maximumSpendingTarget: "Στόχος μέγιστης δαπάνης",
    minimumSpendingTargetRequired: "απαιτείται ελάχιστος στόχος δαπανών",
    maximumSpendingTargetRequired: "απαιτείται ο μέγιστος στόχος δαπανών",
    rewardSponsor: "Χορηγός ανταμοιβής",
    pleaseChooseARewardSponsor: "Επιλέξτε χορηγό ανταμοιβής",
    self: "Εαυτός",
    partner: "Εταίρος",
    rewardPartner: "Συνεργάτης ανταμοιβής",
    pleaseSelectRewardPartner: "Επιλέξτε τον συνεργάτη ανταμοιβής σας",
    rewards: "Ανταμοιβές",
    pleaseSelectAReward: "Επιλέξτε μια ανταμοιβή",
    instructionsOnRedeemingReward:
      "Οδηγίες για το πώς ο πελάτης πρέπει να εξαργυρώσει την ανταμοιβή",
    pleaseFillInThisField: "Συμπληρώστε αυτό το πεδίο!",
    toDeleteThisTier: " για διαγραφή αυτής της βαθμίδας",
    deleteTier: "Διαγραφή βαθμίδας",
    viewMembers: "Προβολή μελών",
    membersEnrolled: "Τα μέλη εγγράφηκαν",
    instruction: "Εντολή",
    membersIn: "Μέλη στο",
    availableTiersInLoyalty: "Διαθέσιμο επίπεδο (α) στο Πρόγραμμα Πιστότητας",
    tiers: "Βαθμίδες",
    totalTier: "ΣΥΝΟΛΙΚΗ ΤΑΞΗ",
    availableLoyaltyProgramme: "Διαθέσιμο Πρόγραμμα Πιστότητας",
    totalLoyalties: "ΣΥΝΟΛΙΚΕΣ ΠΙΣΤΕΣ",
    memberDetails: "Λεπτομέρειες μελών",
    nameOfCustomer: "Όνομα Πελάτη",
    address: "Διεύθυνση",
    allEnrolledMembers: "Όλα τα εγγεγραμμένα μέλη",
    thisIsToWishYouHappyBirthday:
      "Αυτό είναι για να σας ευχηθώ χρόνια πολλά και ευημερούσα ζωή. Ευχαριστώ για όλα όσα είσαι στο Loyster. Καλές Γιορτές!",
    inputAnOfferPlease: "Παρακαλώ εισάγετε μια προσφορά",
    pleaseSelectTheInsertPoint:
      "Επιλέξτε το σημείο εισαγωγής στο μήνυμα και κάντε ξανά κλικ",
    birthdayOfferAndMessage: "Προσφορά γενεθλίων και μήνυμα",
    birthdayOffer: "Προσφορά γενεθλίων",
    birthdayMessage: "Μήνυμα γενεθλίων",
    noOfferFound: "Δεν βρέθηκε προσφορά",
    settingABirthdayOffer:
      "Ο καθορισμός προσφοράς γενεθλίων επιτρέπει στους πελάτες να λαμβάνουν αυτήν την προσφορά μέσω SMS στα γενέθλιά τους",
    createOffer: "Δημιουργία προσφοράς",
    whatIsTheOffer: "Ποια είναι η προσφορά;",
    editMessage: "Επεξεργασία μηνύματος",
    insert: "Εισάγετε",
    theNameOfCustomerInserted: "Το όνομα του πελάτη θα εισαχθεί εδώ",
    theBirtdayOfferInserted: "Η προσφορά γενεθλίων θα εισαχθεί εδώ",
    youSuccessfullyAddedNewBranch: "Προσθέσατε με επιτυχία έναν νέο κλάδο!",
    addNewBranch: "Προσθήκη νέου κλάδου",
    addBranch: "Προσθήκη κλάδου",
    additionalBranchWillIncur: "Θα υπάρξει πρόσθετο υποκατάστημα",
    perBranch: "ανά κλάδο",
    ecommerceBranchCosts: "Κόστος υποκαταστήματος ηλεκτρονικού εμπορίου",
    pleaseEnterBranchName: "Παρακαλώ εισάγετε το όνομα του υποκαταστήματος",
    pleaseEnterBranchAddress1:
      "Παρακαλώ εισάγετε τη γραμμή διεύθυνσης 1 του υποκαταστήματος",
    enterBranchAddress1:
      "Εισαγάγετε τη γραμμή διεύθυνσης 1 του υποκαταστήματος",
    enterBranchAddress2:
      "Εισαγάγετε τη γραμμή διεύθυνσης 1 του υποκαταστήματος",
    pleaseEnterBranchAddress2:
      "Παρακαλώ εισάγετε τη γραμμή διευθύνσεων του υποκαταστήματος 2",
    enterBranchName: "Εισαγάγετε το όνομα του υποκαταστήματος",
    successfullyAddedStaff: "Προσθέσατε με επιτυχία νέο προσωπικό!",
    addNewStaff: "Προσθήκη νέου προσωπικού",
    addStaff: "Προσθήκη προσωπικού",
    additionalStaffWillIncur: "Θα υπάρξει επιπλέον προσωπικό",
    perStaff: "ανά προσωπικό.",
    pleaseEnterStaffEmail: "Παρακαλώ εισάγετε το email του προσωπικού",
    pleaseEnterStaffUsername:
      "Παρακαλώ εισάγετε το όνομα χρήστη του προσωπικού",
    pleaseEnterStaffPassword:
      "Παρακαλώ εισάγετε τον κωδικό πρόσβασης του προσωπικού",
    pleaseSelectStaffRole: "Επιλέξτε τον ρόλο του προσωπικού",
    selectStaffRole: "Επιλέξτε το ρόλο του προσωπικού",
    enterStaffEmail: "Εισαγάγετε το email του προσωπικού",
    enterStaffUsername: "Εισαγάγετε το όνομα χρήστη του προσωπικού",
    enterStaffPassword: "Εισαγάγετε τον κωδικό πρόσβασης του προσωπικού",
    spacesNotAllowedInUsername: "κενά που δεν επιτρέπονται στο όνομα χρήστη",
    admin: "διαχειριστής",
    pleaseSelectBusinessToAttachStaff:
      "Επιλέξτε επιχείρηση για να επισυνάψετε προσωπικό",
    searchForBranchToAttachStaff:
      "Αναζητήστε υποκατάστημα για να επισυνάψετε προσωπικό",
    username: "Όνομα χρήστη",
    role: "Ρόλος",
    areYouSureToDeleteThis: "Είστε βέβαιοι ότι θα το διαγράψετε",
    branches: "Κλαδια δεντρου",
    upgradeYourPlan: "Αναβαθμίστε το Σχέδιο σας.",
    add: "ΠΡΟΣΘΗΚΗ",
    addNew: "Προσθεσε νεο",
    customerWithEmailAlreadyExists:
      "Πελάτης με email/αριθμό τηλεφώνου υπάρχει ήδη!",
    successfullyAddedNewCustomer: "Προσθέσατε με επιτυχία έναν νέο πελάτη!",
    addCustomer: "Προσθήκη πελάτη",
    pleaseEnterCustomerFirstName: "Παρακαλώ εισάγετε το όνομα του πελάτη",
    pleaseEnterCustomerLastName: "Παρακαλώ εισάγετε το επώνυμο του πελάτη",
    pleaseEnterCustomerPhoneNumber:
      "Παρακαλώ εισάγετε τον αριθμό τηλεφώνου του πελάτη",
    pleaseEnterCustomerEmail: "Παρακαλώ εισάγετε το email του πελάτη",
    pleaseEnterCustomerAddressLine:
      "Εισαγάγετε τη γραμμή διευθύνσεων του πελάτη",
    pleaseEnterCustomerOtherAddress: "Εισαγάγετε την άλλη διεύθυνση του πελάτη",
    pleaseSelectCustomerGender: "Επιλέξτε το φύλο του πελάτη",
    gender: "Γένος",
    male: "Αρσενικός",
    female: "Θηλυκός",
    bank: "Τράπεζα",
    selectBank: "Επιλέξτε Τράπεζα",
    stateOrRegionOrProvince: "Πολιτεία/Περιφέρεια/Επαρχία",
    customerNotes: "Σημειώσεις Πελατών",
    sendSms: "Αποστολή SMS",
    editCustomer: "Επεξεργασία πελάτη",
    redeemReward: "Εξαργυρώστε την ανταμοιβή",
    issueLoyaltyCard: "Έκδοση Κάρτας Πιστότητας",
    deleteCustomer: "Διαγραφή πελάτη",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Εκχωρήσατε με επιτυχία ένα αναγνωριστικό μέλους πίστης",
    noMembershipIdAvailable:
      "Δεν υπάρχουν διαθέσιμα αναγνωριστικά μέλους. Επικοινωνήστε μαζί μας στο hello@loystar.co",
    sendEmail: "Να στείλετε e-mail",
    membershipPoints: "Σημεία συμμετοχής",
    customerDetails: "Πληροφορίες Πελάτη",
    close: "Κλείσε",
    loyaltyBalance: "Υπόλοιπο πίστης",
    pointsBalance: "Υπόλοιπο πόντων",
    starBalance: "Star Balance",
    requiredPoints: "Απαιτούμενα σημεία",
    requiredStars: "Απαιτούμενα αστέρια",
    reddemCode: "Εξαργύρωση κωδικού",
    toDeleteThisCustomer: "για διαγραφή αυτού του πελάτη",
    customerHasBeenDeletedSuccessfully: "Ο πελάτης διαγράφηκε με επιτυχία!",
    customerWithPhoneAlreadyExists: "Υπάρχει ήδη πελάτης με αριθμό τηλεφώνου",
    customerWasSuccessfullyEdited: "Ο πελάτης επεξεργάστηκε με επιτυχία",
    anErrorOccured: "Προέκυψε ένα σφάλμα",
    phoneNumberIncludeCountry:
      "Αριθμός τηλεφώνου (Συμπεριλάβετε τον κωδικό χώρας)",
    yourFileQueuedForUpload:
      "Το αρχείο σας έχει τεθεί σε αναμονή για μεταφόρτωση. Ανανεώστε τη σελίδα μετά από μερικά δευτερόλεπτα.",
    thereWasAnErrorPerformingOperation:
      "Παρουσιάστηκε σφάλμα κατά την εκτέλεση της λειτουργίας!",
    pleaseSelectFile: "Επιλέξτε ένα αρχείο!",
    oopsOnlyCsvFilesAllowed:
      "Ωχ! Επιτρέπονται μόνο αρχεία CSV. Ανεβάστε ένα αρχείο .csv.",
    fileShouldBeSmallerThan5Mb:
      "Το αρχείο πρέπει να είναι μικρότερο από 5 MB. Εάν έχετε μεγαλύτερο αρχείο, στείλτε email στη διεύθυνση support@loystar.co. Σας ευχαριστώ",
    customerFirstNameIsRequired: "το όνομα του πελάτη απαιτείται στη σειρά",
    customerPhoneNumberIsRequired:
      "απαιτείται αριθμός τηλεφώνου πελάτη στη σειρά",
    importCustomers: "Εισαγωγή πελατών",
    upload: "Μεταφόρτωση",
    clickIconToDownloadCsv:
      "Κάντε κλικ σε αυτό το εικονίδιο για λήψη του προτύπου αρχείου CSV.",
    getGoogleSheetVersion: "Αποκτήστε την έκδοση φύλλου Google",
    clickOrDragFileToUpload:
      "Κάντε κλικ ή σύρετε το αρχείο σε αυτήν την περιοχή για μεταφόρτωση",
    missingOrInvalidColumnHeader:
      "Λείπει ή δεν είναι έγκυρη κεφαλίδα στήλης. Ακολουθήστε τη μορφή προτύπου. Σας ευχαριστώ.",
    youHaveImported: "Έχετε εισαγάγει",
    youSuccessfullyRedeemedReward:
      "Εξαργυρώσατε με επιτυχία την ανταμοιβή σας!",
    sixDigitCode: "Εξαψήφιος κωδικός",
    pleaseEnterCustomerRewardCode:
      "Εισαγάγετε τον κωδικό ανταμοιβής του πελάτη",
    enterRewardCode:
      "Εισαγάγετε τον κωδικό ανταμοιβής. (Ο κωδικός ανταμοιβής είναι διάκριση πεζών -κεφαλαίων)",
    selectLoyaltyProgram: "Επιλέξτε πρόγραμμα αφοσίωσης",
    stamps: "Γραμματόσημα",
    smsUnitsLow: "Χαμηλή μονάδα SMS",
    pleaseTopUpSmsUnits: "Συμπληρώστε μονάδες SMS",
    smsSuccessfullySentForDelivery: "SMS στάλθηκε επιτυχώς για παράδοση!",
    sendSmsTo: "Αποστολή SMS σε",
    allCustomers: "Όλοι οι Πελάτες",
    unitsAvailable: "Διαθέσιμες μονάδες",
    pleaseTypeInTheMessage: "Πληκτρολογήστε το μήνυμα",
    message: "Μήνυμα",
    charactersRemaining: "χαρακτήρες που απομένουν",
    avoidUseOfSpecialCharacters:
      "Αποφύγετε τη χρήση Ειδικών Χαρακτήρων και Emoji για τη διατήρηση των Μονάδων SMS.",
    note: "Σημείωση",
    errorFetchingCustomersMultilevelDetail:
      "Σφάλμα κατά τη λήψη στοιχείων πελατών πολλαπλών επιπέδων",
    search: "Αναζήτηση",
    reset: "Επαναφορά",
    importCustomer: "Εισαγωγή πελάτη",
    addNewCustomer: "Προσθήκη νέου πελάτη",
    sendSmsBroadcast: "Αποστολή SMS Broadcast",
    totalCustomers: "ΣΥΝΟΛΟΙ ΠΕΛΑΤΕΣ",
    disbursementDetails: "Λεπτομέρειες εκταμίευσης",
    paidBy: "Πληρωμένο από",
    disbursed: "Εκταμιεύτηκε",
    bankAccountName: "Ονομα τραπεζικού λογαριασμού",
    bankAccountNumber: "Αριθμός τραπεζικού λογαριασμού",
    transferInitiated: "Η μεταφορά ξεκίνησε",
    transferCompleted: "Η μεταφορά ολοκληρώθηκε",
    pleaseEnterAValid: "Παρακαλώ εισάγετε ένα έγκυρο",
    begin: "να αρχίσει",
    end: "τέλος",
    date: "ημερομηνία",
    paymentDate: "Ημερομηνία πληρωμής",
    selectDisbursementDuration: "Επιλέξτε Διάρκεια εκταμίευσης",
    totalSettled: "Συνολικά τακτοποιημένα",
    totalUnsettled: "Συνολικά ασταθής",
    toDeleteThisSale: "για διαγραφή αυτής της πώλησης",
    draftSaleDeletedSuccessfully:
      "Η προσφορά προσφοράς διαγράφηκε με επιτυχία!",
    deleteSale: "Διαγραφή πώλησης",
    pleaseTypeInYourTotal:
      "Πληκτρολογήστε το σύνολο σας για να επιβεβαιώσετε τη διαγραφή",
    billDetails: "Λεπτομέρειες λογαριασμού",
    printBill: "Εκτύπωση λογαριασμού",
    servedBy: "Εξυπηρετείται από",
    total: "Σύνολο",
    createdDate: "Ημερομηνία δημιουργίας",
    createdTime: "Δημιουργημένος Χρόνος",
    status: "Κατάσταση",
    loginSuccessful: "Επιτυχής σύνδεση",
    pleaseWaitWhileWeConnectAccount:
      "Περιμένετε μέχρι να συνδέσουμε τον λογαριασμό σας",
    connectionFailedTryAgain: "Αποτυχημένη σύνδεση. ΠΑΡΑΚΑΛΩ προσπαθησε ξανα.",
    connectionSuccessful: "Η σύνδεση ήταν επιτυχής",
    viewDetails: "Δείτε λεπτομέρειες",
    enable: "επιτρέπω",
    free: "Ελεύθερος",
    cost: "Κόστος",
    visitWebsite: "Επισκέψου την ιστοσελίδα",
    pleaseUpgradeYourPlanToPro:
      "Αναβαθμίστε το σχέδιό σας σε Pro Plus για να ενεργοποιήσετε αυτήν την εφαρμογή",
    connectYourBankAccount:
      "Συνδέστε τον τραπεζικό σας λογαριασμό για να μπορείτε να λαμβάνετε πληρωμές.",
    disable: "Καθιστώ ανίκανο",
    enableApp: "Ενεργοποίηση εφαρμογής",
    addNewProductToInvoice: "Προσθήκη νέου προϊόντος στο τιμολόγιο",
    toDeleteThisInvoice: "για διαγραφή αυτού του Τιμολογίου",
    invoiceDeletedSuccessfully: "Το τιμολόγιο διαγράφηκε με επιτυχία!",
    deleteInvoice: "Διαγραφή τιμολογίου",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Προσθέσατε με επιτυχία ένα μήνυμα πληρωμής τιμολογίου!",
    pleaseEnterPaymentInstructions: "Εισαγάγετε οδηγίες πληρωμής",
    invoiceId: "Αναγνωριστικό τιμολογίου",
    paidDate: "Πληρωμένη Ημερομηνία",
    reference: "Αναφορά",
    productAdded: "Το προϊόν προστέθηκε",
    productOutOfStock: "Εξαντλημένο προϊόν. Παρακαλώ κάντε ξανά απόθεμα.",
    totalInvoices: "ΣΥΝΟΛΟ ΤΙΜΟΛΟΓΙΩΝ",
    totalPaidInvoices: "ΣΥΝΟΛΟ ΠΛΗΡΩΜΕΝΩΝ ΤΙΜΟΛΟΓΙΩΝ",
    totalUnpaidInvoices: "ΣΥΝΟΛΟ ΤΙΜΟΛΟΓΙΩΝ ΑΠΕΡΙΦΟΡΑ",
    loyaltyProgramDeleted: "Διαγράφηκε το πρόγραμμα αφοσίωσης",
    thisLoyaltyProgramDeletedSuccessfully:
      "Αυτό το πρόγραμμα αφοσίωσης διαγράφηκε με επιτυχία",
    thisLoyaltyProgramEditedSuccessfully:
      "Αυτό το πρόγραμμα αφοσίωσης επεξεργάστηκε με επιτυχία",
    loyaltyProgramAddedSuccessfully:
      "Το πρόγραμμα αφοσίωσης προστέθηκε με επιτυχία",
    loyaltyProgramEdited: "Το πρόγραμμα πίστης επεξεργάστηκε",
    loyaltyProgramAdded: "Προστέθηκε Πρόγραμμα Πιστότητας",
    loyaltyDetails: "Λεπτομέρειες αφοσίωσης",
    doYouWantToCloseDialog: "Θέλετε να κλείσετε αυτόν τον διάλογο;",
    pleaseEnterLoyaltyName: "Παρακαλώ εισάγετε το όνομα της πίστης σας",
    programType: "Τύπος προγράμματος",
    pleaseSelectProgramType: "Επιλέξτε έναν τύπο προγράμματος",
    simplePoints: "Απλά σημεία",
    stampsProgram: "Πρόγραμμα γραμματοσήμων",
    threshold: "Κατώφλι",
    pleaseEnterLoyaltyThreshold: "Εισαγάγετε το όριο πιστότητας",
    reward: "Ανταμοιβή",
    pleaseEnterLoyaltyReward: "Παρακαλώ εισάγετε την ανταμοιβή της πίστης",
    totalUserPoints: "Σύνολο πόντων χρήστη",
    totalUserStamps: "Συνολικές σφραγίδες χρηστών",
    spendingTarget: "Στόχος δαπανών",
    spendingTargetHint1:
      "Στόχος δαπάνης είναι το ποσό που πρέπει να ξοδέψει ένας πελάτης για να κερδίσει την ανταμοιβή. 1 αξία νομίσματος = 1 πόντος.",
    spendingTargetHint2:
      "Στόχος γραμματοσήμων είναι πόσα γραμματόσημα πρέπει να συλλέξει ένας πελάτης για να κερδίσει την ανταμοιβή. π.χ. 5",
    addNewLoyaltyProgram: "Προσθήκη νέου προγράμματος αφοσίωσης",
    addLoyaltyProgram: "Προσθήκη προγράμματος αφοσίωσης",
    loyaltyProgramType: "Τύπος Προγράμματος Πιστότητας",
    pleaseSelectLoyaltyProgramType: "Επιλέξτε τύπο προγράμματος αφοσίωσης",
    nameOfProgram: "Όνομα προγράμματος",
    pleaseEnterProgramName: "Παρακαλώ εισάγετε το όνομα του προγράμματος",
    whatIsTheReward: "Ποια είναι η ανταμοιβή;",
    egNextPurchaseIsFree: "Π.χ. Η επόμενη αγορά είναι δωρεάν",
    customerName: "Όνομα πελάτη",
    guestCustomer: "Επισκέπτης Πελάτης",
    orderConfirmedSuccessfully: "Η παραγγελία επιβεβαιώθηκε με επιτυχία",
    orderCancelledSuccessfully: "Η παραγγελία ακυρώθηκε με επιτυχία",
    confirmOrder: "Επιβεβαίωση παραγγελίας",
    cancelOrder: "Ακύρωση παραγγελίας",
    allOrders: "Όλες οι παραγγελίες",
    totalOrders: "ΣΥΝΟΛΙΚΕΣ ΠΑΡΑΓΓΕΛΙΕΣ",
    doYouWantToAcceptOrder: "Θέλετε να αποδεχτείτε αυτήν την παραγγελία;",
    doYouWantToCancelOrder: "Θέλετε να ακυρώσετε αυτήν την παραγγελία;",
    orderDetails: "Λεπτομέρειες Παραγγελίας",
    orderCreatedAt: "Η παραγγελία δημιουργήθηκε στις",
    supplier: "Προμηθευτής",
    productName: "Ονομασία προϊόντος",
    quantity: "Ποσότητα",
    unitPrice: "Τιμή μονάδας",
    receivedBy: "Που λαμβάνονται από",
    reportFrom: "Αναφορά από",
    totalSupplies: "Σύνολο Προμηθειών",
    allRightsReserved: "Όλα τα δικαιώματα διατηρούνται",
    toDeleteThisTransaction: "για διαγραφή αυτής της Συναλλαγής",
    transactionDeletedSuccessfully:
      "Η συναλλαγή διαγράφηκε με επιτυχία. Το απόθεμα επέστρεψε στο απόθεμα.",
    deleteTransaction: "Διαγραφή συναλλαγής",
    transactionDetails: "Λεπτομέρειες Συναλλαγής",
    printReceipt: "Εκτύπωση απόδειξης",
    channel: "Κανάλι",
    discount: "Εκπτωση",
    profit: "Κέρδος",
    discountedSales: "Πωλήσεις με έκπτωση",
    errorFetchingRecord: "Σφάλμα κατά την ανάκτηση της εγγραφής",
    exportTransactions: "Συναλλαγές εξαγωγής",
    errorFetchingSalesRecord:
      "Σφάλμα κατά την ανάκτηση της εγγραφής πωλήσεων για εξαγωγή.",
    totalSellingPrice: "Συνολική Τιμή Πώλησης",
    totalCostPrice: "Συνολικό Κόστος Τιμής",
    appliedDiscount: "Εφαρμοσμένη έκπτωση",
    noOfItems: "Αριθμός ειδών",
    sales: "Εκπτώσεις",
    export: "Εξαγωγή",
    totalProfit: "Συνολικό κέρδος",
    totalBalanceInPeriod: "Συνολικό υπόλοιπο σε περίοδο",
    allTimeSales: "Πωλήσεις όλων των εποχών",
    records: "Εγγραφές",
    todaysSales: "Οι σημερινές πωλήσεις",
    transaction: "συναλλαγή",
    youHaveExceededTotalNumberOfProducts:
      "Έχετε υπερβεί τον συνολικό αριθμό προϊόντων που επιτρέπονται στο σχέδιό σας. Αναβαθμίστε το σχέδιό σας για να απολαύσετε υψηλότερο όριο.",
    youNeedToHaveLoyaltyProgram:
      "Πρέπει να έχετε Πρόγραμμα Πιστότητας για να χρησιμοποιήσετε αυτήν τη δυνατότητα!",
    price: "Τιμή",
    category: "Κατηγορία",
    stockTracking: "Παρακολούθηση αποθεμάτων",
    stockCount: "Καταμέτρηση αποθεμάτων",
    taxed: "Φορολογημένος",
    originalPrice: "Αρχική τιμή",
    costPrice: "ΚΟΣΤΟΣ",
    unit: "Μονάδα",
    productImage: "Εικόνα προϊόντος",
    taxRate: "Φορολογικός συντελεστής",
    taxType: "Φορολογικός τύπος",
    trackInventory: "Παρακολούθηση αποθέματος",
    variants: "Παραλλαγές",
    hasVariants: "Έχει παραλλαγές",
    importProduct: "Εισαγωγή Προϊόντος",
    exportProducts: "Εξαγωγή προϊόντων",
    addNewProduct: "Προσθήκη νέου προϊόντος",
    viewCategory: "Προβολή Κατηγορίας",
    viewSuppliers: "Προβολή Προμηθευτών",
    receiveInventory: "Λήψη Αποθέματος",
    printAllProductsTag: "Εκτύπωση όλων των προϊόντων Ετικέτα",
    deleteAll: "Διαγραφή όλων",
    totalProducts: "ΣΥΝΟΛΙΚΑ ΠΡΟDΟΝΤΑ",
    youveSuccessfullyAddedANewCategory:
      "Προσθέσατε με επιτυχία μια νέα κατηγορία",
    addNewCategory: "Προσθήκη νέας κατηγορίας",
    addCategory: "Προσθήκη κατηγορίας",
    categoryName: "όνομα κατηγορίας",
    pleaseEnterCategoryName: "Παρακαλώ εισάγετε το όνομα της κατηγορίας",
    stampsTarget: "Στόχοι γραμματοσήμων",
    sendInventory: "Αποστολή Αποθέματος",
    productDetails: "λεπτομέρειες προιόντος",
    youveSuccessfullyEditedThisCategory:
      "Έχετε επεξεργαστεί επιτυχώς αυτήν την κατηγορία",
    update: "Εκσυγχρονίζω",
    categoryList: "Λίστα κατηγοριών",
    categories: "Κατηγορίες",
    enterQuantityToUpdate: "Εισαγάγετε μια ποσότητα προς ενημέρωση",
    inventorySentSuccessfully: "Το απόθεμα στάλθηκε με επιτυχία!",
    updateInventory: "Ενημέρωση αποθέματος",
    currentQuantity: "Τρέχουσα Ποσότητα",
    pleaseEnterQuantityToAdd:
      "Παρακαλώ εισάγετε την ποσότητα που θέλετε να προσθέσετε",
    pleaseSelectABranch: "Επιλέξτε ένα Υποκατάστημα",
    searchForBranch: "Αναζήτηση για υποκατάστημα",
    youCantSendMoreThanStock:
      "Δεν μπορείτε να στείλετε περισσότερα από όσα έχετε στο απόθεμα",
    send: "Στείλετε",
    pleaseEnterQuantityToSend:
      "Παρακαλώ εισάγετε την ποσότητα που θέλετε να στείλετε",
    productNameIsRequiredOnRow: "απαιτείται όνομα προϊόντος στη σειρά",
    productCategoryIsRequiredOnRow: "απαιτείται κατηγορία προϊόντος στη σειρά",
    productPriceIsRequiredOnRow: "απαιτείται τιμή προϊόντος στη σειρά",
    productUnitIsRequiredOnRow: "απαιτείται μονάδα προϊόντος στη σειρά",
    productQuantityIsRequiredOnRow: "απαιτείται ποσότητα προϊόντος στη σειρά",
    productVariantsRequireTracking:
      "Οι παραλλαγές προϊόντων απαιτούν παρακολούθηση!",
    pleaseAddVariantClickButton:
      "Προσθέστε μια παραλλαγή κάνοντας κλικ στο κουμπί προσθήκης!",
    totalVariantsMoreThanSelectedQuantity:
      "Οι συνολικές παραλλαγές είναι περισσότερες από την επιλεγμένη ποσότητα προϊόντος, τα Pls μειώνουν την ποσότητα παραλλαγής",
    productEditedSuccessfully: "Το προϊόν επεξεργάστηκε με επιτυχία!",
    fileTooLargeLessThan4Mb:
      "Το μέγεθος του αρχείου είναι πολύ μεγάλο! Το μέγεθος του αρχείου πρέπει να είναι μικρότερο από 4 MB.",
    suchVariantAlreadyExist: "Τέτοια παραλλαγή υπάρχει ήδη",
    addVariants: "Προσθήκη παραλλαγών",
    editProduct: "Επεξεργασία προϊόντος",
    pleaseEnterProductName: "Παρακαλώ εισάγετε το όνομα προϊόντος",
    pleaseEnterProductPrice: "Παρακαλώ εισάγετε την τιμή προϊόντος",
    pleaseEnterProductOriginalPrice:
      "Παρακαλώ εισάγετε την αρχική τιμή του προϊόντος",
    productDescription: "περιγραφή προϊόντος",
    selectProductCategory: "Επιλέξτε Κατηγορία Προϊόντος",
    pleaseSelectProductCategory: "Επιλέξτε κατηγορία προϊόντος",
    productCostPrice: "Τιμή Κόστους Προϊόντος",
    productSellingPrice: "Τιμή πώλησης προϊόντος",
    productOriginalPrice: "Αρχική τιμή προϊόντος",
    maxFileSizeAllowedIs4Mb:
      "Μέγιστη. Το επιτρεπόμενο μέγεθος αρχείου είναι 4mb",
    productsWithPicturesArePublished:
      "Τα προϊόντα με εικόνες δημοσιεύονται στο Discover για να λαμβάνετε παραγγελίες",
    shouldThisProductBeTracked: "Πρέπει να παρακολουθείται αυτό το προϊόν;",
    pleaseSelectStockUnit: "Επιλέξτε μονάδα αποθέματος",
    stockUnit: "Μονάδα Μετοχών",
    bag: "ΤΣΑΝΤΑ",
    bottle: "ΜΠΟΥΚΑΛΙ",
    bunch: "ΔΕΣΜΗ",
    can: "ΜΠΟΡΩ",
    carton: "ΧΑΡΤΟΚΙΒΩΤΙΟ",
    crate: "ΣΑΚΑΡΑΚΑ",
    cup: "ΦΛΙΤΖΑΝΙ",
    dozen: "ΝΤΟΥΖΙΝΑ",
    gigabytes: "GIGABYTES",
    gram: "ΓΡΑΜΜΑΡΙΟ",
    kilogram: "ΧΙΛΙΟΓΡΑΜΜΟ",
    litre: "ΛΙΤΡΟ",
    pack: "ΠΑΚΕΤΟ",
    pair: "ΖΕΥΓΟΣ",
    pieces: "ΤΕΜΑΧΙΑ",
    plate: "ΠΛΑΚΑ",
    tonne: "ΤΟΝΟΣ (ΜΤ)",
    uNIT: "ΜΟΝΑΔΑ",
    yard: "ΑΥΛΗ",
    pleaseEnterProductQuantity: "Παρακαλώ εισάγετε την ποσότητα του προϊόντος",
    productQuantity: "Ποσότητα προϊόντος",
    isThisProductTaxed: "Φορολογείται αυτό το προϊόν;",
    selectTaxType: "Επιλέξτε Τύπος φόρου",
    pleaseSelectTaxType: "Επιλέξτε Φορολογικός τύπος",
    progressive: "Προοδευτικός",
    proportional: "Αναλογικά",
    pleaseEnterProductTaxRate: "Εισαγάγετε το συντελεστή φόρου προϊόντος",
    doesProductHaveVariants: "Έχει αυτό το προϊόν παραλλαγές;",
    type: "Τύπος",
    value: "αξία",
    pleaseEnterVariantType: "Παρακαλώ εισάγετε τον τύπο της παραλλαγής",
    pleaseEnterVariantValue: "Παρακαλώ εισάγετε την τιμή της παραλλαγής",
    pleaseEnterVariantPrice: "Παρακαλώ εισάγετε την τιμή της παραλλαγής",
    pleaseEnterVariantQuantity: "Παρακαλώ εισάγετε την ποσότητα της παραλλαγής",
    productDeletedSuccessfully: "Το προϊόν διαγράφηκε με επιτυχία!",
    categoryDeletedSuccessfully: "Η κατηγορία διαγράφηκε με επιτυχία!",
    toDeleteThisProduct: "για διαγραφή αυτού του Προϊόντος",
    invalidProductQuantity: "Μη έγκυρη ποσότητα προϊόντος",
    quantityAddedIsOverStock:
      "Η ποσότητα που προσθέτετε είναι μεγαλύτερη από αυτήν που έχετε στο απόθεμα.",
    itemInventoryNotTracked: "Το απόθεμα στοιχείων δεν παρακολουθείται",
    addBulkQuantity: "Προσθήκη μαζικής ποσότητας",
    enterBulkQuantity: "Εισαγάγετε Μαζική Ποσότητα",
    pleaseEnterBulkQuantity: "Παρακαλώ εισάγετε Μαζική Ποσότητα",
    youveSuccessfullyAddedANewProduct: "Προσθέσατε με επιτυχία ένα νέο προϊόν!",
    pleaseEnterProductSellingPrice:
      "Παρακαλώ εισάγετε την τιμή πώλησης του προϊόντος",
    doYouWantToTrackProductStock:
      "Θέλετε να παρακολουθείτε το απόθεμα προϊόντων;",
    sellingPrice: "Τιμή πωλήσεως",
    setProductExpiryReminder: "Ορισμός υπενθύμισης λήξης προϊόντος",
    productExpiryDate: "Ημερομηνία λήξης προϊόντος",
    startRemindingFrom: "Ξεκινήστε την υπενθύμιση από",
    productSuppliesAddedSuccessfully:
      "Έχετε προσθέσει επιτυχώς προμήθειες προϊόντων.",
    addProductSupplies: "Προσθήκη Προμηθειών Προϊόντος",
    pleaseSelectSupplier: "Επιλέξτε Προμηθευτής",
    nameOfProduct: "Όνομα Προϊόντος",
    pleaseSelectProduct: "Επιλέξτε Προϊόν",
    productVariant: "Παραλλαγή προϊόντος",
    pleaseSelectAVariant: "Επιλέξτε μια παραλλαγή",
    pleaseEnterUnitPrice: "Παρακαλώ εισάγετε τιμή μονάδας",
    businessBranch: "Υποκατάστημα Επιχειρήσεων",
    pleaseSelectBranch: "Επιλέξτε υποκατάστημα",
    youveSuccessfullyAddedANewSupplier:
      "Προσθέσατε με επιτυχία έναν νέο προμηθευτή",
    addSupplier: "Προσθήκη Προμηθευτή",
    pleaseEnterSupplierEmail: "Παρακαλώ εισάγετε το email του προμηθευτή",
    pleaseAddADescription: "Προσθέστε μια περιγραφή",
    anErrorOccuredProductsDeleted:
      "Παρουσιάστηκε σφάλμα κατά την εκτέλεση της λειτουργίας. Λάβετε υπόψη, λίγα προϊόντα ενδέχεται να έχουν διαγραφεί κατά τη διαδικασία",
    bulkDelete: "Μαζική Διαγραφή",
    youAreAboutToDelete: "Πρόκειται να διαγράψετε",
    product: "Προϊόν",
    isDueToRedeem: "οφείλεται σε εξαργύρωση",
    aReward: "μια ανταμοιβή",
    pleaseSelectCustomerToReeemReward:
      "Επιλέξτε έναν πελάτη για εξαργύρωση ανταμοιβής.",
    youHaveNoLoyaltyProgramRunning: "Δεν έχετε ενεργό πρόγραμμα επιβράβευσης",
    customerHhasNoPointsInLoyaltyProgram:
      "Ο πελάτης δεν έχει πόντους σε αυτό το πρόγραμμα αφοσίωσης",
    proceedWithPayment: "Συνέχιση πληρωμής;",
    youAreAboutToPayForTransactionUsingPoints:
      "Πρόκειται να πληρώσετε για τη συναλλαγή χρησιμοποιώντας πόντους.",
    customerHas: "Πελάτης Έχει",
    worth: "αξία",
    andIsNotSufficientToPayForTransaction:
      "και δεν επαρκεί για την πληρωμή αυτής της συναλλαγής. Θα ήθελαν να προσθέσουν το υπόλοιπο χρησιμοποιώντας έναν άλλο τρόπο πληρωμής;",
    addCustomerLoyalty: "Προσθέστε την αφοσίωση πελατών",
    pleaseAddCustomerFirst: "Προσθέστε ή επιλέξτε έναν πελάτη πρώτα.",
    pleaseWaitWhileWeGetReady: "Παρακαλώ περιμένετε, όσο ετοιμαζόμαστε",
    lowStock: "Σε περιορισμένο απόθεμα",
    pleaseEnterAmountTendered: "Παρακαλώ εισάγετε το προσφερόμενο ποσό",
    areYouSureToBookSaleOffline:
      "Είστε βέβαιοι ότι θέλετε να κάνετε κράτηση για αυτήν την πώληση εκτός σύνδεσης;",
    saleWouldBeBookedAutomatically:
      "Η πώληση θα κλείσει αυτόματα όταν ενεργοποιήσετε το διαδίκτυο",
    offlineSalesBookingCancelled: "Η κράτηση πωλήσεων εκτός σύνδεσης ακυρώθηκε",
    covid19Message:
      "COVID19: Πλύνετε τα χέρια σας με σαπούνι ή απολυμάνετε για να σταματήσετε την εξάπλωση. Μείνετε ασφαλείς πάντα",
    saleSuccessfullyRecorded: "Η πώληση καταγράφηκε επιτυχώς!",
    sendReceiptToEmail: "Αποστολή απόδειξης στο Email",
    sendThankYouSms: "Στείλτε ευχαριστήρια SMS",
    sendShippingDetails: "Αποστολή στοιχείων αποστολής",
    addLoyalty: "Προσθέστε την Πιστότητα",
    searchCustomerNameOrNumber: "Αναζήτηση ονόματος ή αριθμού πελάτη",
    clickTheSearchCustomerBox:
      "Κάντε κλικ στο πλαίσιο αναζήτησης πελάτη και κάρτα σάρωσης",
    enterProductPrice: "Εισαγάγετε την τιμή προϊόντος",
    enterPriceFor: "Εισαγάγετε την τιμή για",
    searchForProduct: "Αναζήτηση προϊόντος",
    all: "Ολα",
    backToDashboard: "Επιστροφή στον Πίνακα ελέγχου",
    viewDraftSales: "Προβολή προσχεδίων πωλήσεων",
    variantSelected: "επιλεγμένη παραλλαγή",
    variant: "παραλαγή",
    thePreviousVariantSelectionNotAvailable:
      "Η προηγούμενη επιλογή παραλλαγής δεν είναι διαθέσιμη για τη νέα παραλλαγή που επιλέχθηκε με βάση την τιμή, αλλάξτε την επιλογή σας.",
    pleaseSupplyPositiveQuantityNumber:
      "Καταχωρίστε έναν αριθμό θέσης ποσότητας",
    lowStockFor: "Χαμηλό απόθεμα για",
    clearVariants: "Σαφείς παραλλαγές",
    pleaseEnterQuantity: "Παρακαλώ εισάγετε Ποσότητα",
    picture: "Εικόνα",
    redemptionToken: "Κουπόνι εξαργύρωσης",
    token: "Ενδειξη",
    totalSpent: "Συνολικό Δαπανημένο",
    confirmPayment: "Επιβεβαίωση πληρωμής",
    hasPaymentBeenMade: "Η επεξεργασία της πληρωμής έγινε με επιτυχία;",
    enterTransactionReference:
      "Εισαγάγετε την αναφορά συναλλαγής με την οποία πληρώσατε",
    pleaseSelectACustomer: "Επιλέξτε πελάτη!",
    areYouSureToApplyDiscount:
      "Είστε βέβαιοι ότι θέλετε να εφαρμόσετε έκπτωση;",
    addYourBankAccountToEnableUssd:
      "Προσθέστε τον τραπεζικό σας λογαριασμό για να ενεργοποιήσετε την άμεση μεταφορά USSD μέσω uPay",
    totalAmountToPay: "Συνολικό ποσό προς πληρωμή",
    doYouWantToCancelTransaction: "Θέλετε να ακυρώσετε αυτήν τη συναλλαγή;",
    savePrintBill: "Αποθήκευση/Εκτύπωση λογαριασμού",
    enterAmountCollectedForCustomer:
      "Εισαγάγετε το ποσό που συλλέχθηκε για τον πελάτη",
    recordSale: "Πώληση δίσκων",
    checkOutWith: "Αναχώρηση με",
    instantTransfer: "Αμεση μεταφορά",
    dialTheUSSDCode: "Καλέστε τον κωδικό USSD",
    pleaseSelectABank: "Επιλέξτε μια τράπεζα",
    payWithUSSD: "Πληρώστε με USSD",
    sendBillTo: " - Στείλτε το Bill στο",
    waitingForUSSDTransfer: "Αναμονή για μεταφορά USSD",
    percent: "Τοις εκατό",
    applyDiscount: "Εφαρμογή έκπτωσης",
    thisBillHasBeenSaved: "Αυτό το νομοσχέδιο σώθηκε",
    saveDraft: "Αποθηκεύσετε το σχέδιο",
    pleaseTypeANameToIdentifyCustomer:
      "Πληκτρολογήστε ένα όνομα για να προσδιορίσετε τον πελάτη",
    paymentDetails: "Οι λεπτομέρειες πληρωμής",
    basePrice: "Βασική Τιμή",
    staff: "Προσωπικό",
    subTotal: "ΜΕΡΙΚΟ ΣΥΝΟΛΟ",
    durationMonths: "Διάρκεια (μήνες)",
    selectADuration: "Επιλέξτε Διάρκεια",
    oneMonth: "1 μήνα",
    twoMonths: "2 μήνες",
    threeMonths: "3 μήνες",
    sixMonths: "6 μήνες",
    twelveMonths: "12 μήνες",
    eighteenMonths: "18 Μήνες",
    twentyFourMonths: "24 Μήνες",
    twoMonthsFree: "(2 μήνες δωρεάν)",
    threeMonthsFree: "(3 μήνες δωρεάν)",
    fiveMonthsFree: "(5 μήνες δωρεάν)",
    yourAccountHasBeen: "Ο λογαριασμός σας ήταν",
    renewed: "ανανεωμένο",
    upgraded: "αναβαθμισμένο",
    successfully: "επιτυχώς",
    yourSubscription: "Η συνδρομή σας",
    youAreCurrentlyEnrolledOnA: "Είστε εγγεγραμμένοι αυτήν τη στιγμή σε α",
    pleaseChooseAPaymentOption: "Επιλέξτε μια επιλογή πληρωμής",
    planRenewal: "Ανανέωση Σχεδίου",
    planUpgrade: "Αναβάθμιση σχεδίου",
    pleaseSelectDurationToPayFor:
      "Επιλέξτε Διάρκεια για την οποία σκοπεύετε να πληρώσετε",
    staffAccounts: "Λογαριασμοί προσωπικού",
    ecommerce: "ΗΛΕΚΤΡΟΝΙΚΟ ΕΜΠΟΡΙΟ",
    pleaseSelectAPlan: "Επιλέξτε ένα σχέδιο",
    includeAddons: "Συμπεριλάβετε πρόσθετα",
    viewTransactions: "Προβολή συναλλαγών",
    customerHasNoCompletedTansactions:
      "Ο πελάτης δεν έχει ολοκληρώσει ακόμη τις αντιδράσεις",
    branch: "Κλαδί",
    enterNumberOfEcommerceBranches:
      "Εισαγάγετε τον αριθμό των υποκαταστημάτων ηλεκτρονικού εμπορίου",
    enterNumberOfEcommerceBranchesToPay:
      "Εισαγάγετε τον αριθμό των υποκαταστημάτων ηλεκτρονικού εμπορίου για τα οποία σκοπεύετε να πληρώσετε",
    ecommerceIntegration: "Ενσωμάτωση ηλεκτρονικού εμπορίου",
    enterNumberOfBranches: "Εισαγάγετε τον αριθμό των υποκαταστημάτων",
    enterNumberOfAdditionalBranchesToPay:
      "Εισαγάγετε τον αριθμό επιπλέον υποκαταστημάτων για τα οποία σκοπεύετε να πληρώσετε",
    enterNumberOfStaffs: "Εισαγάγετε τον αριθμό προσωπικού",
    enterNumberOfStaffsToPayFor:
      "Εισαγάγετε τον αριθμό των υπαλλήλων για τους οποίους σκοπεύετε να πληρώσετε",
    discountApplies: "Ισχύει έκπτωση",
    starsOnThe: "αστέρια στο",
    offer: "προσφορά",
    get: "Παίρνω",
    moreStarsToRedeem: "περισσότερα αστέρια για εξαργύρωση",
    taxVat: "Φόρος (ΦΠΑ)",
    callCashierToCompletePayment:
      "Καλέστε το ταμείο για να ολοκληρώσετε την πληρωμή",
    receipt: "Παραλαβή",
    dear: "αγαπητός",
    thankYouForYourOrderFindGoods:
      "Ευχαριστούμε για την παραγγελία σας. Βρείτε τα παρακάτω προϊόντα που παρέχονται, όπως συμφωνήθηκε.",
    shippingNote: "Σημείωση αποστολής",
    enterShippingNote: "Πληκτρολογήστε Σημείωση αποστολής",
    shippingAddress: "Διεύθυνση αποστολής",
    enterShippingAddress: "Εισαγάγετε τη διεύθυνση αποστολής",
    wellDoneYouAreDueToRedeem: "Μπράβο! Οφείλετε να εξαργυρώσετε",
    toGetYourRewardNextVisit:
      "για να λάβετε την ανταμοιβή σας στην επόμενη επίσκεψή σας. Σας ευχαριστώ",
    pointsOnThe: "Σημεία στο",
    morePointsToRedeem: "περισσότερους πόντους για εξαργύρωση",
    showCode: "Εμφάνιση κωδικού",
    toGetYourRewardOnNextVisit:
      "για να λάβετε την ανταμοιβή σας στην επόμενη επίσκεψή σας. Σας ευχαριστώ",
    earn: "Κερδίζω",
    delivaryNote: "Σημείωση παράδοσης",
    draftSales: "Πρόχειρες πωλήσεις",
    startDate: "Ημερομηνία έναρξης",
    endDate: "Ημερομηνία λήξης",
    orders: "Παραγγελίες",
    checkout: "ολοκλήρωση παραγγελίας",
    noProductItem: "Δεν υπάρχει στοιχείο προϊόντος",
    selectProductImage: "Επιλέξτε Εικόνα προϊόντος",
    selectCountry: "Επιλέξτε χώρα",
    selectRegion: "Επιλέξτε Πολιτεία/Περιφέρεια",
    printProductTag: "Εκτύπωση ετικέτας προϊόντος",
    transactionReference: "Αναφορά συναλλαγής",
    Cashier: "Ταμίας",
    Manager: "Διευθυντής",
    Owner: "Ιδιοκτήτης",
    Admin: "διαχειριστής",
    addPartners: "Προσθήκη συνεργατών",
    addNewLoyaltyPartner: "Προσθήκη νέου συνεργάτη αφοσίωσης",
    pleaseEnterCompanyName: "Εισαγάγετε το όνομα της εταιρείας",
    companyName: "Όνομα εταιρείας",
    pleaseEnterCompanyRepName: "Εισαγάγετε Όνομα αντιπροσώπου εταιρείας",
    companyRepName: "Όνομα Εκπροσώπου Εταιρείας",
    pleaseEnterCompanyRepEmail:
      "Εισαγάγετε το email του εκπροσώπου της εταιρείας",
    companyRepEmail: "Email του αντιπροσώπου της εταιρείας",
    pleaseEnterCompanyRepPhone:
      "Εισαγάγετε τον αριθμό τηλεφώνου του αντιπροσώπου της εταιρείας",
    companyRepPhone: "Αριθμός τηλεφώνου του αντιπροσώπου της εταιρείας",
    addReward: "Προσθήκη ανταμοιβής",
    pleaseEnterRewardName: "Εισαγάγετε το όνομα της ανταμοιβής",
    rewardName: "Όνομα επιβράβευσης",
    rewardQuantity: "Ποσότητα επιβράβευσης",
    rewardDescription: "Περιγραφή ανταμοιβής",
    rewardType: "Τύπος ανταμοιβής",
    pleaseEnterRewardType: "Εισαγάγετε τον τύπο ανταμοιβής",
    pleaseEnterRewardQuantity: "Εισαγάγετε την ποσότητα ανταμοιβής",
    pleaseEnterRewardDescription: "Εισαγάγετε την περιγραφή της ανταμοιβής",
    fineDining: "Καλό φαγητό",
    luxuryFashion: "Μόδα πολυτελείας",
    hotels: "Ξενοδοχεία",
    travel: "Ταξίδι",
    foodAndBeverage: "Φαγητο και ΠΟΤΟ",
    fashion: "Μόδα",
    health: "Υγεία",
    furniture: "Επιπλα",
    entertainment: "Ψυχαγωγία",
    automobile: "Αυτοκίνητο",
    education: "Εκπαίδευση",
    beautyAndSpa: "Ομορφιά και Spa",
    staycation: "Διαμονή",
    events: "Εκδηλώσεις",
    trips: "Ταξίδια",
    oilAndGas: "Πετρέλαιο και Αέριο",
    laundry: "Πλυντήριο",
    partnerCategory: "Κατηγορία συνεργάτη",
    freeItem: "Δωρεάν Αντικείμενο",
  },
  Hausa: {
    cashier: "mai karbar kudi",
    manager: "manaja",
    owner: "mai gida",
    online: "akan layi",
    offline: "a layi",
    changePassword: "Canza kalmar shiga",
    currentPasswordMessage: "Da fatan a shigar da kalmar wucewa ta yanzu",
    passwordMessage: "Da fatan a shigar da kalmar wucewa",
    currentPassword: "kalmar shiga na halin yanzu",
    password: "kalmar sirri",
    confirmPasswordMessage: "Da fatan za a tabbatar da kalmar sirrin ku!",
    confirmPassword: "tabbata kalmar shiga",
    sendViaEmail: "Aika Ta Email",
    sendViaWhatsapp: "Aika Ta WhatsApp",
    downloadPdf: "Sauke PDF",
    paid: "biya",
    unpaid: "wanda ba a biya ba",
    partial: "m",
    closeInvoice: "Kuna so ku rufe daftarin?",
    closeInvoiceConfirmation:
      "Wataƙila ba za a iya ajiye daftari ba. Kuna so ku rufe?",
    yes: "iya",
    no: "a'a",
    invoice: "Daftari",
    wasDeducted: "aka cire",
    for: "don",
    item: "Abu",
    addProduct: "Ƙara Samfura",
    paymentReference: "Maganar Biyan Kuɗi",
    amountPaid: "Adadin Kuɗi",
    discountAmount: "Adadin Kuɗi",
    amountDue: "Adadin Da Ya Kamata",
    amount: "Adadi",
    dueDate: "Kwanan Wata",
    paymentType: "Nau'in Biya",
    card: "Katin",
    cash: "Tsabar kuɗi",
    bankTransfer: "Canja wurin Banki",
    paymentMessage: "Saƙon Biya",
    description: "Bayani",
    sendReceipt: "Aika rasit",
    delete: "Share",
    save: "Ajiye",
    resend: "Sake Aika",
    saveAndSend: "Ajiye",
    invoiceSaved: "An adana daftari!",
    selectPaymentMethod: "Da fatan za a zaɓi Hanyar Biyan Kuɗi!",
    selectCustomer: "Da fatan za a zaɓi Abokin ciniki!",
    cartEmptyError:
      "Jerin Mota ba zai iya zama fanko ba, rufe daftari kuma ƙara Abun da ke cikin keken!",
    subscriptionExpired:
      "Biyan kuɗinka ya ƙare kuma asusunka yanzu yana da iyaka. Danna maɓallin da ke ƙasa don sabunta asusunka",
    renew: "Sabunta",
    holdOn: "Jira",
    customerBank: "Bankin Abokin ciniki",
    cancel: "Soke",
    selectACustomer: "Zaɓi Abokin ciniki",
    invoiceSuccessfulPdfError:
      "An ƙirƙiri daftari cikin nasara amma Tsarin PDF yana ɗaukar fiye da yadda aka saba. Da fatan za a duba nan ba da jimawa ba.",
    downloadingInvoice: "Sauke Daftari",
    downloadingReceipt: "Sauke rasit",
    whatsappReceiptError:
      "An sami kuskure aika aika ta WhatsApp, Da fatan a sake gwadawa.",
    receiptToWhatsapp: "An aika da karɓa zuwa WhatsApp",
    thankYouForPatronage: "Na gode da taimakon ku",
    hereIsYourReceipt: "Ga rasit ɗin biyan ku",
    errorSendingEmailReceipt:
      "An sami kuskure wajen aika rasit ɗin ta imel, don Allah a sake gwadawa ko a tuntuɓi tallafi",
    receiptSentToEmail: "An aika da wasiƙa zuwa imel ɗin abokin ciniki",
    invoiceSentToEmail: "An aika da daftari zuwa imel ɗin abokin ciniki",
    invoiceSuccessWhatsappError:
      "An ƙirƙiri daftari cikin nasara amma an sami kuskure aikawa zuwa WhatsApp. Sake gwadawa akan lissafin daftari",
    invoiceSuccessfulEmailError:
      "An ƙirƙiri daftari cikin nasara amma an sami kuskure yayin aikawa azaman imel. Da fatan a sake gwadawa daga lissafin daftari",
    invoiceSentToWhatsapp: "Invoice ya tura zuwa WhatsApp",
    hello: "Sannu",
    pleaseDownloadInvoice: "Da fatan za a sauke daftari",
    pleaseDownloadReceipt: "Da fatan za a sauke rasit",
    from: "daga",
    email: "Imel",
    upgrade: "Ingantawa",
    youAreOnFreePlan: "Kuna kan Tsarin Kyauta.",
    clickOn: "Danna kan",
    yourPlanInFewSteps: " biyan kuɗinka cikin quickan matakai masu sauri.",
    to: "zuwa",
    yourSubscriptionHasExpired:
      "Biyan kuɗinka ya ƙare kuma asusunka yanzu yana da iyaka.",
    days: "kwanaki",
    yourSubscriptionExpiresIn: "Biyan kuɗin ku na Loystar ya ƙare",
    createAcount: "Ƙirƙiri Asusu",
    signIn: "Shiga",
    continue: "Ci gaba",
    enterOtp: "Shigar da PIN na OTP",
    enterValidOtp: "Da fatan a shigar da PIN mai aiki",
    pin: "PIN",
    tokenSentToMail: "An aika alamar alama zuwa imel ɗin ku",
    passwordResetSuccessful: "An yi nasara sake saita kalmar sirri",
    somethingWentWrong: "Wani abu ya ɓace!",
    resetPassword: "Sake saita Kalmar wucewa",
    iHaveResetCode: "Ina da lambar sake saita kalmar sirri",
    pleaseEnterEmail: "Da fatan a shigar da imel ɗin ku",
    aTokenWillBeSentToEmail: "Za a aika da alama zuwa imel ɗin ku",
    enterEmail: "Shigar da imel",
    sendinYourToken: "Aika alamar ku ...",
    makeSureItMatchesPassword:
      "Tabbatar cewa yayi daidai da sabon kalmar wucewa",
    pleaseChooseNewPassword: "Da fatan za a zaɓi sabon kalmar sirri",
    chooseNewPassword: "Zaɓi sabon kalmar sirri",
    enterNewPassword: "Shigar da sabon kalmar wucewa don tabbatarwa",
    enterTokenSent: "Shigar da alamar da aka aiko maka da wasiƙar",
    resetToken: "Sake saita Token",
    resettingPassword: "Sake saita Kalmar wucewa ...",
    signUp: "Shiga",
    adminSignInWithEmail:
      " Admin yana shiga tare da imel yayin da Ma'aikaci ke shiga tare da sunan mai amfani.",
    pleaseEnterEmailOrUsername: "Da fatan a shigar da imel ko sunan mai amfani",
    emailOrUsername: "Email ko Sunan mai amfani",
    pleaseEnterPassword: "Da fatan a shigar da kalmar wucewa",
    createAnAccount: "Ƙirƙiri Asusu",
    forgotPassword: "An manta Kalmar wucewa?",
    enterPhoneNumber: "Shigar da Lambar Waya",
    personalData: "Keɓaɓɓen Bayanin",
    validateConfirmationCOde: "Tabbatar da Lambar Tabbatarwa",
    pleaseEnterFirstName: "Da fatan a shigar da sunanku na farko",
    pleaseEnterPhoneNumber: "Da fatan a shigar da lambar wayarku",
    pleaseEnterLastName: "Da fatan a shigar da sunanka na ƙarshe",
    pleaseEnterBusinessName: "Da fatan a shigar da sunan kasuwancin ku",
    firstName: "Sunan rana",
    lastName: "Sunan mahaifa",
    businessName: "Sunan Kasuwanci",
    previous: "Na baya",
    next: "Na gaba",
    pleaseSelectBusinessCategory: "Da fatan za a zaɓi rukunin kasuwancin ku",
    pleaseEnterValidEmail: "Da fatan a shigar da imel mai inganci",
    pleaseEnterPostCode: "Da fatan a shigar da lambar gidan waya",
    postCode: "Lambar Post",
    phoneNumberInUse: "An fara amfani da wannan lambar wayar!",
    emailInUse: "Ana amfani da wannan imel ɗin tuni!",
    foodAndDrinks: "Abinci da Abin sha",
    salonAndBeauty: "Salon da Kyau",
    fashionAndAccessories: "Fashion da Na'urorin haɗi",
    gymAndFitness: "Gym da Lafiya",
    travelAndHotel: "Tafiya da Hotel",
    homeAndGifts: "Gida da Kyauta",
    washingAndCleaning: "Wanke da Tsaftacewa",
    gadgetsAndElectronics: "Gadgets da Lantarki",
    groceries: "Kayan abinci",
    others: "Wasu",
    submit: "Sallama",
    accountCreatedSuccessful: "An ƙirƙiri asusunka cikin nasara.",
    pleaseEnterAddress: "Da fatan a Shigar da Adireshin ku",
    addressLine1: "Layin adireshi 1",
    addressLine2: "Layin adireshi 2",
    choosePassword: "Zaɓi Kalmar wucewa",
    passwordMustBe6Characters:
      "Dole ne kalmar wucewa ta kasance aƙalla haruffa 6.",
    howDidYouHearLoystar: "Yaya kuka ji game da Loystar?",
    referralCode: "Lambar aikawa",
    byClickingTheButton: " Ta danna maɓallin da ke ƙasa, kun yarda da",
    termsAndSevice: "Sharuɗɗa",
    wereCreatingAccount: "Muna ƙirƙirar asusunka",
    proceed: "Ci gaba",
    verificationCodeMustBe6: "Lambar tabbatarwa dole ta zama lambobi 6",
    pleaseEnter6DigitCode: "Da fatan a Shigar da lambar lamba 6",
    enterVerificationCode: "Shigar da Lambar Tabbatarwa",
    resendToken: "Resend Token",
    verify: "Tabbatar",
    transactions: "Ma'amaloli",
    todaySales: "Tallan Yau",
    salesHistory: "Tarihin Talla",
    supplyHistory: "Tarihin Kaya",
    new: "Sabuwar",
    invoices: "Daftari",
    disbursements: "Bayarwa",
    offlineSales: "Tallace -tallace na layi",
    products: "Kayayyaki",
    customers: "Abokan ciniki",
    multiLevelLoyalty: "Amintattun Mataki-mataki",
    loyaltyPrograms: "Shirye -shiryen Amintattu",
    members: "Membobi",
    appStore: "App Store",
    orderMenu: "Menu na oda",
    settings: "Saituna",
    staffAndBranches: "Ma'aikata da rassa",
    myAccount: "Asusu na",
    switchToSellMode: "Canja zuwa Yanayin Sayarwa",
    signOut: "Fita",
    getFreeSubscription: "Samu Biyan Kuɗi Kyauta",
    onlyNumbersAllowed: "Lambobi ne kawai aka yarda",
    yourAccountMustBe10Digits: "dole ne lambar asusunka ta zama lamba 10",
    yourBvnMustBe11: "BVN ɗinku dole ne ya zama lamba 11",
    pleaseSelectBank: "Da fatan za a zaɓi bankin ku",
    selectYourBank: "Zaɓi Bankin ku",
    enterBankAccountNumber: "Shigar da Lambar Asusun Banki",
    enterBvnNumber: "Shigar da BVN ɗin ku",
    connectBankAccount: "Haɗa Asusun Banki",
    passwordResetSuccessfulAndSignOut:
      "An sake saita kalmar sirrinku cikin nasara. Danna maballin Fita daga ƙasa don sake shiga",
    enterCurrentPassword: "Shigar da kalmar wucewa ta yanzu",
    pleaseEnterCurrentPassword: "Da fatan a shigar da kalmar wucewa ta yanzu",
    phoneNumber: "Lambar tarho",
    sex: "Jima'i",
    dateOfBirth: "Ranar haifuwa",
    state: "Jiha",
    country: "Ƙasa",
    loyaltyId: "ID na aminci",
    createdAt: "An ƙirƙira A",
    noOfTransactionsMade: "A'a na ma'amaloli da aka yi",
    yourDownloadWillStart: "Zazzagewarku zata fara cikin ɗan lokaci kaɗan",
    exportCustomers: "Fitar da Abokan ciniki",
    youhaveSuccessfullyToppedUp:
      "An yi nasarar ƙaddamar da Rukunin SMS ɗin ku.",
    youNowHave: "Yanzu kuna da",
    smsUnits: "Rukunin SMS",
    enterNumberOfSmsUnits: "Shigar da Lambobin Rukunin SMS da kuke Nufin Sayi",
    pleaseEnterANumericValue: "Da fatan a shigar da ƙimar lamba",
    pay: "Biya",
    accountEditedSuccessfully: "An gyara asusunka cikin nasara.",
    youAeCurrentlyOn: "Kana a halin yanzu",
    plan: "Shirya",
    userData: "Bayanin Mai Amfani",
    businessData: "DATA KASUWANCI",
    businessCategory: "Kasuwancin Kasuwanci",
    pleaseSelectCurrency: "Da fatan za a zaɓi kuɗin ku",
    selectYourCurrency: "Zaɓi kuɗin ku",
    purchaseMoreSmsUnits:
      "Sayi ƙarin Rukunin SMS ta amfani da fom ɗin da ke ƙasa",
    youHave: "Kina da",
    atLeast4SmsUnitsRrequired:
      "Ana buƙatar aƙalla raka'a sms 4 don tabbatarwa, da fatan za a ɗaga sama!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Da fatan za a tabbatar da asusunka na banki don biyan buƙatun 'San Abokin Ciniki' (KYC). Wannan zai ba ku damar tattara kuɗi ta hanyar USSD ko canja wuri nan take, karɓar umarni daga abokan ciniki da aiwatar da sasanta ma'amala. Ya dace da 'yan kasuwar Najeriya kawai. Da fatan a danna maɓallin da ke ƙasa don farawa.",
    reConnectBankAccount: "Sake haɗa Asusun Banki",
    accountName: "Sunan Asusu",
    accountNumber: "Lambar akant",
    bankName: "Sunan Banki",
    verified: "Tabbatarwa",
    accountDetails: "Bayanan Asusun",
    kycBankAccount: "KYC",
    createTier: "Ƙirƙiri Tier",
    fileUploadSuccessful: "An ɗora fayil ɗin cikin nasara",
    fileUploadFailed: "An kasa aika fayil",
    createLoyaltyProgram: "Ƙirƙiri Shirin Aminci",
    createLoyalty: "Ƙirƙiri Aminci",
    name: "Suna",
    loyaltyArtwork: "Aikin Aminci",
    clickToUpload: "Danna don lodawa",
    amountToPointsRatio: "Adadin Matsakaicin Maki",
    points: "Maki",
    recommendedAmountToPointRatio:
      "An ba da shawarar: ₦ 1 zuwa 1point. watau kowane Naira 1 da aka kashe, abokan cinikin ku suna samun maki 1",
    pleaseTypeIn: "Da fatan a shigar",
    toDeleteLoyalty: "don goge wannan aminci",
    deleteLoyalty: "Share Aminci",
    toConfirm: "don tabbatarwa",
    edit: "Shirya",
    pointsAwardedSuccessfully: "An Ba da Kyaututtuka Makin Nasara.",
    enterPointValueToAward:
      "Shigar da Darajar Makin da kuke so ku ba Abokan ciniki Kyauta",
    award: "Kyauta",
    awardPointValuesToCustomer: "Darajar Matsayin Kyauta ga Abokin ciniki",
    enrollMembersToLoyalty: "Shiga Membobi akan aminci",
    awardPoints: "Abubuwan Kyauta",
    enroll: "Shiga",
    home: "Gida",
    loyalty: "Aminci",
    enrollCustomers: "Shiga Abokan ciniki",
    selected: "An zaɓa",
    customer: "Abokin ciniki",
    loyaltyActivationSuccessful: "Kunna Aminci ya yi nasara.",
    loyaltyDeactivationSuccessful: "An kashe cin amana.",
    viewTier: "Duba Matsayi",
    deactivate: "De-Kunna",
    activate: "Kunna",
    actions: "Ayyuka",
    nameOfLoyalty: "Sunan Aminci",
    loyaltyStatus: "Matsayin Aminci Matsayin Aminci",
    numberOfTiers: "Yawan Tiers",
    createdOn: "An ƙirƙiri On",
    createATier: "Ƙirƙiri Tier",
    stopCreatingTierConfirmation: "Kuna so ku daina ƙirƙirar matakin?",
    stopEditingTierConfirmation: "Kuna so ku daina gyara wannan matakin?",
    nameOfTier: "Sunan Darasi",
    minimumSpendingTarget: "Mafi qarancin Kudaden Kuɗi",
    maximumSpendingTarget: "Matsakaicin Kudin Maƙasudi",
    minimumSpendingTargetRequired:
      "Ana buƙatar mafi ƙarancin maƙasudin kashe kuɗi",
    maximumSpendingTargetRequired:
      "Ana buƙatar matsakaicin maƙasudin kashe kuɗi",
    rewardSponsor: "Mai bada Tallafi",
    pleaseChooseARewardSponsor: "Da fatan za a zaɓi mai ba da lada",
    self: "Kai",
    partner: "Abokin tarayya",
    rewardPartner: "Abokin Tukuici",
    pleaseSelectRewardPartner: "Da fatan za a zaɓi abokin haɗin lada",
    rewards: "Lada",
    pleaseSelectAReward: "Da fatan za a zaɓi lada",
    instructionsOnRedeemingReward:
      "Umarni kan Yadda Abokin ciniki Ya Kamata Ya Bada Lada",
    pleaseFillInThisField: "Da fatan za a cika wannan Filin!",
    toDeleteThisTier: " don share wannan matakin",
    deleteTier: "Share Mataki",
    viewMembers: "Duba Membobi",
    membersEnrolled: "An Shiga Membobi",
    instruction: "Umarni",
    membersIn: "Membobi a ciki",
    availableTiersInLoyalty: "Akwai Matsayi (s) a cikin Shirin Aminci",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Samar da Shirin Aminci",
    totalLoyalties: "GASKIYA DAYA",
    memberDetails: "Bayanin Memba",
    nameOfCustomer: "Sunan Abokin ciniki",
    address: "Adireshin",
    allEnrolledMembers: "Duk membobin da aka yi rajista",
    thisIsToWishYouHappyBirthday:
      "Wannan shine don yi muku murnar zagayowar ranar haihuwa da rayuwa mai albarka. Godiya ga duk abin da kuke zuwa Loyster. Bikin murna!",
    inputAnOfferPlease: "Shigar da Kyauta don Allah",
    pleaseSelectTheInsertPoint:
      "Da fatan za a zaɓi wurin sakawa a cikin saƙon kuma danna sake",
    birthdayOfferAndMessage: "Baiko Ranar Haihuwa da Saƙo",
    birthdayOffer: "Tayin Ranar Haihuwa",
    birthdayMessage: "Sakon Haihuwa",
    noOfferFound: "Ba a samu tayin ba",
    settingABirthdayOffer:
      "Kafa tayin ranar haihuwa yana bawa abokan ciniki damar karɓar wannan tayin ta hanyar SMS akan ranar haihuwarsu",
    createOffer: "Ƙirƙiri Tayin",
    whatIsTheOffer: "Menene tayin?",
    editMessage: "Shirya Saƙo",
    insert: "Saka",
    theNameOfCustomerInserted: "Za a saka sunan abokin ciniki a nan",
    theBirtdayOfferInserted: "Za a saka tayin ranar haihuwa a nan",
    youSuccessfullyAddedNewBranch: "Kun yi nasarar ƙara sabon reshe!",
    addNewBranch: "Ƙara Sabon reshe",
    addBranch: "Ƙara reshe",
    additionalBranchWillIncur: "Ƙarin reshe zai jawo",
    perBranch: "ta kowane reshe",
    ecommerceBranchCosts: "Kudin Kasuwancin Ecommerce",
    pleaseEnterBranchName: "Da fatan za a shigar da sunan reshe",
    pleaseEnterBranchAddress1: "Da fatan a shigar da layin adireshin reshe 1",
    enterBranchAddress1: "Shigar da layin adireshin reshe 1",
    enterBranchAddress2: "Shigar da layin adireshin reshe 1",
    pleaseEnterBranchAddress2: "Da fatan a shigar da layin adireshin reshe 2",
    enterBranchName: "Shigar da sunan reshe",
    successfullyAddedStaff: "Kun yi nasarar ƙara sabon ma'aikata!",
    addNewStaff: "Ƙara Sabbin Ma'aikata",
    addStaff: "Ƙara Ma'aikata",
    additionalStaffWillIncur: "Ƙarin Ma'aikata za su jawo",
    perStaff: "ta ma'aikata.",
    pleaseEnterStaffEmail: "Da fatan za a shigar da imel ɗin ma'aikatan",
    pleaseEnterStaffUsername:
      "Da fatan a shigar da sunan mai amfani na ma'aikatan",
    pleaseEnterStaffPassword: "Da fatan a shigar da kalmar sirri ta ma'aikatan",
    pleaseSelectStaffRole: "Da fatan za a zaɓi rawar ma'aikatan",
    selectStaffRole: "Zaɓi matsayin ma'aikatan",
    enterStaffEmail: "Shigar da imel ɗin ma'aikatan",
    enterStaffUsername: "Shigar da sunan mai amfani na ma'aikatan",
    enterStaffPassword: "Shigar da kalmar sirri ta ma'aikata",
    spacesNotAllowedInUsername: "ba a yarda da sarari a sunan mai amfani ba",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Da fatan za a zaɓi kasuwanci don haɗa ma'aikata",
    searchForBranchToAttachStaff: "Bincika reshe don haɗa ma'aikatan",
    username: "Sunan mai amfani",
    role: "Matsayi",
    areYouSureToDeleteThis: "Shin kun tabbata kun goge wannan",
    branches: "Rassan",
    upgradeYourPlan: "Haɓaka Shirin ku.",
    add: "ƘARA",
    addNew: "Ƙara Sabuwa",
    customerWithEmailAlreadyExists:
      "Abokin ciniki tare da imel/lambar waya ya riga ya wanzu!",
    successfullyAddedNewCustomer: "Kun yi nasarar ƙara sabon abokin ciniki!",
    addCustomer: "Ƙara Abokin ciniki",
    pleaseEnterCustomerFirstName:
      "Da fatan a shigar da sunan abokin ciniki na farko",
    pleaseEnterCustomerLastName:
      "Da fatan a shigar da sunan abokin ciniki na ƙarshe",
    pleaseEnterCustomerPhoneNumber:
      "Da fatan a shigar da lambar wayar abokin ciniki",
    pleaseEnterCustomerEmail: "Da fatan za a shigar da imel na abokin ciniki",
    pleaseEnterCustomerAddressLine:
      "Da fatan a shigar da layin adireshin abokin ciniki",
    pleaseEnterCustomerOtherAddress:
      "Da fatan a shigar da sauran adireshin abokin ciniki",
    pleaseSelectCustomerGender: "Da fatan za a zaɓi jinsi na abokin ciniki",
    gender: "Jinsi",
    male: "Namiji",
    female: "Mace",
    bank: "Banki",
    selectBank: "Zaɓi Banki",
    stateOrRegionOrProvince: "Jiha/Yanki/Lardin",
    customerNotes: "Bayanan Abokin ciniki",
    sendSms: "Aika SMS",
    editCustomer: "Shirya Abokin ciniki",
    redeemReward: "Bada Lada",
    issueLoyaltyCard: "Bayar da Katin Aminci",
    deleteCustomer: "Share Abokin ciniki",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Kun yi nasarar sanya ID na membobin aminci",
    noMembershipIdAvailable:
      "Babu ID na membobi. Da fatan za a tuntube mu a hello@loystar.co",
    sendEmail: "Aika Imel",
    membershipPoints: "Points membobi",
    customerDetails: "Bayanin Abokin ciniki",
    close: "Kusa",
    loyaltyBalance: "Daidaitan Aminci",
    pointsBalance: "Daidaitaccen Maki",
    starBalance: "Daidaita Taurari",
    requiredPoints: "Abubuwan da ake buƙata",
    requiredStars: "Taurarin da ake bukata",
    reddemCode: "Lambar Fansa",
    toDeleteThisCustomer: "don share wannan abokin ciniki",
    customerHasBeenDeletedSuccessfully: "An goge abokin ciniki cikin nasara!",
    customerWithPhoneAlreadyExists:
      "Abokin ciniki tare da lambar waya ya wanzu",
    customerWasSuccessfullyEdited: "An yi nasarar gyara abokin ciniki",
    anErrorOccured: "An sami kuskure",
    phoneNumberIncludeCountry: "Lambar waya (Ciki Lambar Ƙasa)",
    yourFileQueuedForUpload:
      "An yi jerin gwano don fayil ɗin ku. Da fatan za a sabunta shafin bayan secondsan daƙiƙa kaɗan.",
    thereWasAnErrorPerformingOperation: "An sami kuskure wajen yin aikin!",
    pleaseSelectFile: "Da fatan Zaɓi Fayil!",
    oopsOnlyCsvFilesAllowed:
      "Kash! Fayilolin CSV kawai aka yarda. Da fatan za a loda fayil .csv.",
    fileShouldBeSmallerThan5Mb:
      "Fayil ya zama ƙasa da 5MB. Idan kuna da babban fayil, da fatan za a yi imel support@loystar.co. na gode",
    customerFirstNameIsRequired: "ana buƙatar sunan abokin ciniki a jere",
    customerPhoneNumberIsRequired:
      "ana buƙatar lambar wayar abokin ciniki a jere",
    importCustomers: "Shigo da Abokan ciniki",
    upload: "Loda",
    clickIconToDownloadCsv:
      "Danna wannan alamar don saukar da samfurin fayil ɗin CSV.",
    getGoogleSheetVersion: "Samu sigar takardar Google",
    clickOrDragFileToUpload: "Danna ko ja fayil zuwa wannan yanki don lodawa",
    missingOrInvalidColumnHeader:
      "Rubutun shafi da ya ɓace ko mara inganci. Da fatan za a bi tsarin samfuri. Na gode.",
    youHaveImported: "Kun Shigo",
    youSuccessfullyRedeemedReward: "Kun yi nasarar fansar ladar ku!",
    sixDigitCode: "Lambar lamba shida",
    pleaseEnterCustomerRewardCode:
      "Da fatan a shigar da lambar ladar abokin ciniki",
    enterRewardCode: "Shigar da lambar lada. (Lambar ladan tana da lahani)",
    selectLoyaltyProgram: "Zaɓi shirin aminci",
    stamps: "Tambayoyi",
    smsUnitsLow: "Rukunin SMS Ƙasa",
    pleaseTopUpSmsUnits: "Da fatan za a Haɗa Rukunin SMS",
    smsSuccessfullySentForDelivery:
      "An aika saƙon SMS cikin nasara don isarwa!",
    sendSmsTo: "Aika SMS zuwa",
    allCustomers: "Duk Abokan ciniki",
    unitsAvailable: "Akwai Raka'a",
    pleaseTypeInTheMessage: "Da fatan za a rubuta saƙon",
    message: "Saƙo",
    charactersRemaining: "haruffa da suka rage",
    avoidUseOfSpecialCharacters:
      "Guji amfani da Haruffa na Musamman da Emojis don adana Rukunin SMS.",
    note: "Lura",
    errorFetchingCustomersMultilevelDetail:
      "Kuskuren Samun Abokan Ciniki MultiLevel",
    search: "Bincika",
    reset: "Sake saitin",
    importCustomer: "Shigo da Abokin ciniki",
    addNewCustomer: "Ƙara Sabon Abokin ciniki",
    sendSmsBroadcast: "Aika Sanarwar SMS",
    totalCustomers: "JARIDAN CINTA",
    disbursementDetails: "Bayanin Bayarwa",
    paidBy: "Biya Ta",
    disbursed: "An raba",
    bankAccountName: "Sunan Asusun Banki",
    bankAccountNumber: "Lambar Asusun Banki",
    transferInitiated: "An Fara Canja wurin",
    transferCompleted: "An Kammala Canja wurin",
    pleaseEnterAValid: "Da fatan a shigar da inganci",
    begin: "fara",
    end: "ƙare",
    date: "kwanan wata",
    paymentDate: "Kwanan Biyan Kuɗi",
    selectDisbursementDuration: "Zaɓi Lokacin Bayarwa",
    totalSettled: "Jimlar Zama",
    totalUnsettled: "Jimlar Rikicewa",
    toDeleteThisSale: "don goge wannan siyarwa",
    draftSaleDeletedSuccessfully: "An goge Sayar daftarin cikin nasara!",
    deleteSale: "Share Sayarwa",
    pleaseTypeInYourTotal:
      "Da fatan a shigar da jimlar ku don tabbatar da sharewa",
    billDetails: "Bayanin Bill",
    printBill: "Buga Bill",
    servedBy: "Bauta ta",
    total: "Jimlar",
    createdDate: "Ƙirƙiri Kwanan wata",
    createdTime: "Ƙirƙiri Lokaci",
    status: "Matsayi",
    loginSuccessful: "Shiga Ya Yi Nasara",
    pleaseWaitWhileWeConnectAccount:
      "Da fatan a jira yayin da za mu haɗa asusunka",
    connectionFailedTryAgain: "Haɗin ya kasa. Da fatan a sake gwadawa.",
    connectionSuccessful: "Haɗin Nasara",
    viewDetails: "Duba Bayanai",
    enable: "A kunna",
    free: "Kyauta",
    cost: "Kudin",
    visitWebsite: "Ziyarci Yanar Gizo",
    pleaseUpgradeYourPlanToPro:
      "Da fatan za a haɓaka shirin ku zuwa Pro Plus don kunna wannan app",
    connectYourBankAccount: "Haɗa Asusun Bankin ku don samun damar biyan kuɗi.",
    disable: "A kashe",
    enableApp: "Enable App",
    addNewProductToInvoice: "Ƙara Sabuwar Samfura zuwa Daftari",
    toDeleteThisInvoice: "don share wannan daftari",
    invoiceDeletedSuccessfully: "An goge daftari cikin nasara!",
    deleteInvoice: "Share Daftari",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Kun yi nasarar ƙara saƙon biyan kuɗi!",
    pleaseEnterPaymentInstructions: "Da fatan a shigar da umarnin biyan kuɗi",
    invoiceId: "ID na daftari",
    paidDate: "Biyan Kwanan wata",
    reference: "Magana",
    productAdded: "An ƙara samfur",
    productOutOfStock: "Samfurin ya ƙare Da fatan za a sake haja.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "GASKIYAR BAYANIN DA AKA BIYA",
    totalUnpaidInvoices: "TOTAL JARRABO NA GASKIYA",
    loyaltyProgramDeleted: "An Goge Shirin Aminci",
    thisLoyaltyProgramDeletedSuccessfully: "An goge wannan shirin na aminci",
    thisLoyaltyProgramEditedSuccessfully: "An shirya wannan shirin aminci",
    loyaltyProgramAddedSuccessfully: "An ƙara shirin aminci cikin nasara",
    loyaltyProgramEdited: "An Shirya Shirin Aminci",
    loyaltyProgramAdded: "An Ƙara Shirin Aminci",
    loyaltyDetails: "Cikakken Bayani",
    doYouWantToCloseDialog: "Kuna so ku rufe wannan zancen?",
    pleaseEnterLoyaltyName: "Da fatan a shigar da sunan amincin ku",
    programType: "Nau'in Shirin",
    pleaseSelectProgramType: "Da fatan za a zaɓi nau'in shirin",
    simplePoints: "Maɓalli Masu Sauki",
    stampsProgram: "Shirin Stamps",
    threshold: "Kofa",
    pleaseEnterLoyaltyThreshold: "Da fatan a shigar da ƙofar aminci",
    reward: "Lada",
    pleaseEnterLoyaltyReward: "Da fatan a shigar da ladan aminci",
    totalUserPoints: "Ƙididdigar Ma'anar Masu Amfani",
    totalUserStamps: "Jimillar tambarin Mai amfani",
    spendingTarget: "Ciyar da manufa",
    spendingTargetHint1:
      "Manufar kashe kuɗi shine nawa abokin ciniki zai kashe don samun ladan. 1 darajar kuɗi = aya 1.",
    spendingTargetHint2:
      "Manufar hatimi shine tambari nawa abokin ciniki ya tattara don samun ladan. mis. 5",
    addNewLoyaltyProgram: "Ƙara Sabon Shirin Aminci",
    addLoyaltyProgram: "Ƙara Shirin Aminci",
    loyaltyProgramType: "Nau'in Shirin Aminci",
    pleaseSelectLoyaltyProgramType: "Da fatan za a zaɓi nau'in shirin aminci",
    nameOfProgram: "Sunan shirin",
    pleaseEnterProgramName: "Da fatan a shigar da sunan shirin",
    whatIsTheReward: "Menene ladan?",
    egNextPurchaseIsFree: "Misali. Sayen gaba kyauta ne",
    customerName: "Sunan Abokin ciniki",
    guestCustomer: "Abokin Aboki",
    orderConfirmedSuccessfully: "An tabbatar da oda cikin nasara",
    orderCancelledSuccessfully: "An soke oda cikin nasara",
    confirmOrder: "Tabbatar da oda",
    cancelOrder: "Soke oda",
    allOrders: "Duk Umarni",
    totalOrders: "WADANNAN TAKWAS",
    doYouWantToAcceptOrder: "Kuna so ku yarda da wannan oda?",
    doYouWantToCancelOrder: "Kuna so a soke wannan odar?",
    orderDetails: "Bayanin oda",
    orderCreatedAt: "An ƙirƙiri oda a",
    supplier: "Mai samarwa",
    productName: "Sunan samfur",
    quantity: "Yawa",
    unitPrice: "Farashin Ƙari",
    receivedBy: "An karbe ta",
    reportFrom: "Rahoto daga",
    totalSupplies: "Jimlar Kaya",
    allRightsReserved: "An Kiyaye Dukkan Hakkoki",
    toDeleteThisTransaction: "don share wannan Ma'amala",
    transactionDeletedSuccessfully:
      "An goge ma'amala cikin nasara. An mayar da hannun jari zuwa kaya.",
    deleteTransaction: "Share Ma'amala",
    transactionDetails: "Bayanin Ciniki",
    printReceipt: "Bugun Buga",
    channel: "Tasha",
    discount: "Rage rangwame",
    profit: "Riba",
    discountedSales: "Tallace -tallace masu rangwame",
    errorFetchingRecord: "Kuskuren Samun Riko",
    exportTransactions: "Fitar da Mu'amaloli",
    errorFetchingSalesRecord: "Kuskuren kawo rikodin Sayarwa don Fitarwa.",
    totalSellingPrice: "Jimlar Farashin Sayarwa",
    totalCostPrice: "Jimlar Farashin Kuɗi",
    appliedDiscount: "Aiwatar da rangwame",
    noOfItems: "No. na Abubuwa",
    sales: "Tallace -tallace",
    export: "Fitarwa",
    totalProfit: "Jimlar Riba",
    totalBalanceInPeriod: "Jimlar Daidaitawa a Lokaci",
    allTimeSales: "Duk Lokacin Talla",
    records: "Rubuce -rubuce",
    todaysSales: "Tallan Yau",
    transaction: "ma'amala",
    youHaveExceededTotalNumberOfProducts:
      "Kun wuce adadin adadin samfuran da aka yarda akan shirin ku. Haɓaka shirin ku don jin daɗin iyaka mafi girma.",
    youNeedToHaveLoyaltyProgram:
      "Kuna buƙatar samun Shirin Amintacce don amfani da wannan fasalin!",
    price: "Farashin",
    category: "Nau'i",
    stockTracking: "Bin sawu",
    stockCount: "Ƙididdigar Ƙidaya",
    taxed: "Mai haraji",
    originalPrice: "Farashin Asali",
    costPrice: "Farashin Farashi",
    unit: "Naúra",
    productImage: "Hoton samfur",
    taxRate: "Darajar Haraji",
    taxType: "Nau'in Haraji",
    trackInventory: "Inventory Track",
    variants: "Iri -iri",
    hasVariants: "Yana da Iri -iri",
    importProduct: "Shigo da samfur",
    exportProducts: "Fitar da samfura",
    addNewProduct: "Ƙara Sabon Samfura",
    viewCategory: "Duba Bangare",
    viewSuppliers: "Duba Masu Kawo",
    receiveInventory: "Karɓi Inventory",
    printAllProductsTag: "Buga Duk samfuran Tag",
    deleteAll: "Share Duk",
    totalProducts: "TOTAL PRODUCTS",
    youveSuccessfullyAddedANewCategory: "Kun yi nasarar ƙara sabon rukuni",
    addNewCategory: "Ƙara Sabon Bangare",
    addCategory: "Ƙara Rukuni",
    categoryName: "Sunan Rukunin",
    pleaseEnterCategoryName: "Da fatan a shigar da sunan rukuni",
    stampsTarget: "Tarurrukan Tarbiyya",
    sendInventory: "Aika Kaya",
    productDetails: "Bayanin samfur",
    youveSuccessfullyEditedThisCategory: "Kun yi nasarar gyara wannan rukunin",
    update: "Sabuntawa",
    categoryList: "Jerin Jeri",
    categories: "Kategorien",
    enterQuantityToUpdate: "Shigar da yawa don sabuntawa",
    inventorySentSuccessfully: "An aika da kaya cikin nasara!",
    updateInventory: "Sabunta Kaya",
    currentQuantity: "Yawan Yanzu",
    pleaseEnterQuantityToAdd: "Da fatan a shigar da adadin da kuke son ƙarawa",
    pleaseSelectABranch: "Da fatan za a zaɓi reshe",
    searchForBranch: "Bincika reshe",
    youCantSendMoreThanStock:
      "Ba za ku iya aika fiye da abin da kuke da shi ba",
    send: "Aika",
    pleaseEnterQuantityToSend: "Da fatan a shigar da adadin da kuke son aikawa",
    productNameIsRequiredOnRow: "Ana buƙatar sunan samfurin a jere",
    productCategoryIsRequiredOnRow: "Ana buƙatar nau'in samfurin a jere",
    productPriceIsRequiredOnRow: "ana buƙatar farashin samfuri a jere",
    productUnitIsRequiredOnRow: "Ana buƙatar samfurin samfurin a jere",
    productQuantityIsRequiredOnRow: "ana buƙatar yawan samfurin a jere",
    productVariantsRequireTracking:
      "bambance -bambancen samfur yana buƙatar sa ido!",
    pleaseAddVariantClickButton:
      "Da fatan za a ƙara bambancin ta danna maɓallin ƙara!",
    totalVariantsMoreThanSelectedQuantity:
      "Jimlar Bambance -bambancen sun fi yawan adadin samfuran da aka zaɓa, Pls rage yawan bambancin",
    productEditedSuccessfully: "An shirya samfur cikin nasara!",
    fileTooLargeLessThan4Mb:
      "Girman fayil ya yi yawa! Girman fayil ya zama ƙasa da 4MB.",
    suchVariantAlreadyExist: "Irin wannan bambancin ya wanzu",
    addVariants: "Ƙara Bambanci",
    editProduct: "Shirya samfur",
    pleaseEnterProductName: "Da fatan a Shigar da Sunan Samfurin",
    pleaseEnterProductPrice: "Da fatan a Shigar da Farashin samfur",
    pleaseEnterProductOriginalPrice:
      "Da fatan a Shigar da Farashin Samfurin na asali",
    productDescription: "Bayanin samfur",
    selectProductCategory: "Zaɓi Nau'in samfur",
    pleaseSelectProductCategory: "Da fatan za a zaɓi nau'in samfur",
    productCostPrice: "Farashin Farashin samfur",
    productSellingPrice: "Farashin siyar da samfur",
    productOriginalPrice: "Farashin Farashin Samfurin",
    maxFileSizeAllowedIs4Mb: "Max. An ba da izinin Girman fayil shine 4mb",
    productsWithPicturesArePublished:
      "Ana buga samfura masu hotuna akan Discover don karɓar umarni",
    shouldThisProductBeTracked: "Shin yakamata a bi wannan samfurin?",
    pleaseSelectStockUnit: "Da fatan za a zaɓi na hannun jari",
    stockUnit: "Rukunin Hannun Jari",
    bag: "JAGORA",
    bottle: "KWALLON KAFA",
    bunch: "BUNCH",
    can: "CAN",
    carton: "CARTON",
    crate: "KULA",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITTAFI",
    pack: "PACK",
    pair: "HANKALI",
    pieces: "WURI",
    plate: "FASAHA",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Da fatan a shigar da adadin samfur",
    productQuantity: "Yawan samfur",
    isThisProductTaxed: "An yi wa wannan samfurin haraji?",
    selectTaxType: "Zaɓi Nau'in Haraji",
    pleaseSelectTaxType: "Da fatan za a zaɓi Nau'in Haraji",
    progressive: "Ci gaba",
    proportional: "Daidaitacce",
    pleaseEnterProductTaxRate: "Da fatan a shigar da ƙimar harajin samfur",
    doesProductHaveVariants:
      "Shin wannan samfurin yana da bambance -bambancen?",
    type: "Rubuta",
    value: "Darajar",
    pleaseEnterVariantType: "Da fatan a shigar da nau'in bambance -bambancen",
    pleaseEnterVariantValue: "Da fatan a shigar da ƙimar bambancin",
    pleaseEnterVariantPrice: "Da fatan a shigar da farashin bambancin",
    pleaseEnterVariantQuantity: "Da fatan a shigar da adadi mai yawa",
    productDeletedSuccessfully: "An goge samfur cikin nasara!",
    categoryDeletedSuccessfully: "An goge rukuni cikin nasara!",
    toDeleteThisProduct: "don share wannan samfur",
    invalidProductQuantity: "Yawan samfur mara inganci",
    quantityAddedIsOverStock:
      "Yawan da kuke ƙarawa ya wuce abin da kuke da shi.",
    itemInventoryNotTracked: "Ba a bin diddigin kayan abu",
    addBulkQuantity: "Ƙara Yawan Adadi",
    enterBulkQuantity: "Shigar da Yawan Yawa",
    pleaseEnterBulkQuantity: "Da fatan za a shigar da Adadi mai yawa",
    youveSuccessfullyAddedANewProduct: "Kun yi nasarar ƙara sabon samfurin!",
    pleaseEnterProductSellingPrice:
      "Da fatan a shigar da farashin siyar da samfur",
    doYouWantToTrackProductStock: "Kuna son bin diddigin samfur?",
    sellingPrice: "Farashin Sayarwa",
    setProductExpiryReminder: "Saita Tunatarwar Ƙare samfur",
    productExpiryDate: "Ranar ƙarewar samfur",
    startRemindingFrom: "Fara Tunatarwa Daga",
    productSuppliesAddedSuccessfully: "Kun ƙara kayan aikin cikin nasara.",
    addProductSupplies: "Ƙara Samfuran Samfura",
    pleaseSelectSupplier: "Da fatan Zaɓi Mai Bayarwa",
    nameOfProduct: "Sunan Samfura",
    pleaseSelectProduct: "Da fatan Zaɓi samfur",
    productVariant: "Bambancin samfur",
    pleaseSelectAVariant: "Da fatan Zaɓi Bambanci",
    pleaseEnterUnitPrice: "Da fatan za a Shigar da Farashin Rukunin",
    businessBranch: "Reshen Kasuwanci",
    pleaseSelectBranch: "Da fatan za a zaɓi reshe",
    youveSuccessfullyAddedANewSupplier: "Kun yi nasarar ƙara sabon maroki",
    addSupplier: "Ƙara Mai Bayarwa",
    pleaseEnterSupplierEmail: "Da fatan za a shigar da imel na mai siyarwa",
    pleaseAddADescription: "Da fatan a ƙara bayanin",
    anErrorOccuredProductsDeleted:
      "An sami kuskure yayin yin aikin. Da fatan za a lura, samfuran ƙalilan sun sami gogewa yayin aiwatarwa",
    bulkDelete: "Babban Share",
    youAreAboutToDelete: "Kuna gab da sharewa",
    product: "Samfurin",
    isDueToRedeem: "shine saboda fansa",
    aReward: "lada",
    pleaseSelectCustomerToReeemReward:
      "Da fatan za a zaɓi abokin ciniki don fansar lada.",
    youHaveNoLoyaltyProgramRunning: "Ba ku da wani shiri na biyayya mai gudana",
    customerHhasNoPointsInLoyaltyProgram:
      "Abokin ciniki ba shi da maki a cikin wannan shirin aminci",
    proceedWithPayment: "Ci gaba da Biyan Kuɗi?",
    youAreAboutToPayForTransactionUsingPoints:
      "Kuna gab da biyan kuɗin ma'amala ta amfani da maki.",
    customerHas: "Abokin ciniki Yana",
    worth: "daraja",
    andIsNotSufficientToPayForTransaction:
      "kuma bai isa ya biya wannan ma'amala ba. Za su so su ƙara ma'auni ta amfani da wata hanyar biyan kuɗi?",
    addCustomerLoyalty: "Ƙara amincin Abokin ciniki",
    pleaseAddCustomerFirst:
      "Da fatan za a ƙara ko zaɓi abokin ciniki da farko.",
    pleaseWaitWhileWeGetReady: "Da fatan a jira, yayin da muke shirin",
    lowStock: "Ƙananan Ƙasa",
    pleaseEnterAmountTendered: "Da fatan a shigar da adadin da aka bayar",
    areYouSureToBookSaleOffline:
      "Shin kun tabbata kuna son yin littafin wannan siyarwa a layi?",
    saleWouldBeBookedAutomatically:
      "Za a yi siyarwa ta atomatik lokacin da kun kunna intanet ɗin ku",
    offlineSalesBookingCancelled: "An soke yin siyarwa na layi na waje",
    covid19Message:
      "COVID19: Wanke hannayenku da sabulu ko Sanitize don dakatar da yaduwa. Kasance Cikin Aminci Koyaushe",
    saleSuccessfullyRecorded: "An Yi Nasarar Sayarwa Cikin Nasara!",
    sendReceiptToEmail: "Aika rasit zuwa Email",
    sendThankYouSms: "Aika SMS na gode",
    sendShippingDetails: "Aika cikakkun bayanai",
    addLoyalty: "Ƙara Aminci",
    searchCustomerNameOrNumber: "Bincika sunan abokin ciniki ko lamba",
    clickTheSearchCustomerBox: "Danna Akwatin Abokin Ciniki da Katin dubawa",
    enterProductPrice: "Shigar da Farashin samfur",
    enterPriceFor: "Shigar Farashin don",
    searchForProduct: "Nemo samfur",
    all: "Duk",
    backToDashboard: "Komawa Dashboard",
    viewDraftSales: "Duba Tallace -tallace",
    variantSelected: "an zaɓi bambancin",
    variant: "bambance -bambancen",
    thePreviousVariantSelectionNotAvailable:
      "Ba'a samun zaɓin bambancin baya don sabon bambancin da aka zaɓa bisa farashi, da fatan za a canza zaɓin ku.",
    pleaseSupplyPositiveQuantityNumber: "Da fatan za a ba da adadi mai yawa",
    lowStockFor: "Ƙananan jari don",
    clearVariants: "Bayyana bambance -bambancen",
    pleaseEnterQuantity: "Da fatan a shigar da yawa",
    picture: "Hoto",
    redemptionToken: "Alamar Redemption",
    token: "Alamar",
    totalSpent: "Jimlar An Kashe",
    confirmPayment: "Tabbatar da Biya",
    hasPaymentBeenMade: "An aiwatar da Biyan cikin nasara?",
    enterTransactionReference: "Shigar da bayanin ma'amala da kuka biya tare",
    pleaseSelectACustomer: "Da fatan za a zaɓi abokin ciniki!",
    areYouSureToApplyDiscount: "Shin kun tabbata kuna son amfani da rangwame?",
    addYourBankAccountToEnableUssd:
      "Ƙara asusun bankin ku don ba da damar canja wurin Instant USSD ta uPay",
    totalAmountToPay: "Jimlar Adadin Kuɗi",
    doYouWantToCancelTransaction: "Kuna so ku soke wannan ma'amala?",
    savePrintBill: "Bill na Ajiye/Buga",
    enterAmountCollectedForCustomer:
      "Shigar da adadin da aka tattara don abokin ciniki",
    recordSale: "Rubuce Rubuce",
    checkOutWith: "Duba tare da",
    instantTransfer: "Canja wurin Nan take",
    dialTheUSSDCode: "Danna lambar USSD",
    pleaseSelectABank: "Da fatan za a zaɓi banki",
    payWithUSSD: "Biya Tare da USSD",
    sendBillTo: " - Aika Bill zuwa",
    waitingForUSSDTransfer: "Jiran Canja wurin USSD",
    percent: "Kashi",
    applyDiscount: "Aiwatar da rangwame",
    thisBillHasBeenSaved: "An adana wannan lissafin",
    saveDraft: "Ajiye Tsarin",
    pleaseTypeANameToIdentifyCustomer:
      "Da fatan a rubuta sunan don gane abokin ciniki",
    paymentDetails: "Bayanai Biya",
    basePrice: "Farashin Ƙasa",
    staff: "Ma'aikata",
    subTotal: "Ƙididdiga",
    durationMonths: "Tsawon (watanni)",
    selectADuration: "Zaɓi Tsawon Lokaci",
    oneMonth: "1 Watan",
    twoMonths: "2 Watanni",
    threeMonths: "3 Watanni",
    sixMonths: "6 Watanni",
    twelveMonths: "12 Watanni",
    eighteenMonths: "Watanni 18",
    twentyFourMonths: "Watanni 24",
    twoMonthsFree: "(Watanni 2 Kyauta)",
    threeMonthsFree: "(Watanni 3 Kyauta)",
    fiveMonthsFree: "(Watanni 5 Kyauta)",
    yourAccountHasBeen: "Asusunka ya kasance",
    renewed: "sabunta",
    upgraded: "inganta",
    successfully: "cikin nasara",
    yourSubscription: "Biyan Kuɗin ku",
    youAreCurrentlyEnrolledOnA: "A halin yanzu an yi muku rajista a kan",
    pleaseChooseAPaymentOption: "Da fatan Zaɓi Zaɓin Biyan Kuɗi",
    planRenewal: "Sabunta Shirin",
    planUpgrade: "Shirin Haɓakawa",
    pleaseSelectDurationToPayFor:
      "Da fatan Zaɓi Tsawon lokacin da kuke son biya",
    staffAccounts: "Asusun Ma'aikata",
    ecommerce: "Kasuwanci",
    pleaseSelectAPlan: "Da fatan za a zaɓi tsari",
    includeAddons: "Haɗa Ƙari",
    viewTransactions: "Duba Ma'amaloli",
    customerHasNoCompletedTansactions:
      "Abokin ciniki bai kammala tansactions ba tukuna",
    branch: "Reshe",
    enterNumberOfEcommerceBranches: "Shigar da Lambobin Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Shigar da Adadin Bankunan Ecommerce Da kuke Nufin Biya",
    ecommerceIntegration: "Haɗin Ecommerce",
    enterNumberOfBranches: "Shigar da Lambobi",
    enterNumberOfAdditionalBranchesToPay:
      "Shigar da Lambar Ƙarin Reshen da kuke Nufin Ku biya",
    enterNumberOfStaffs: "Shigar da Yawan Ma'aikata",
    enterNumberOfStaffsToPayFor:
      "Shigar da Adadin Ma’aikatan da Kuke Nufin Ku biya",
    discountApplies: "Rangwame Aiwatar",
    starsOnThe: "taurari a kan",
    offer: "tayin",
    get: "Samu",
    moreStarsToRedeem: "karin taurari don fansa",
    taxVat: "Haraji (VAT)",
    callCashierToCompletePayment: "Kira Cashier don Kammala Biyan Kuɗi",
    receipt: "Karba",
    dear: "Masoyi",
    thankYouForYourOrderFindGoods:
      "Na gode da odar ku. Da fatan za a samar da waɗannan abubuwan masu zuwa, kamar yadda aka amince.",
    shippingNote: "Bayanin jigilar kaya",
    enterShippingNote: "Shigar da Bayanan Kula",
    shippingAddress: "Adireshin sufuri",
    enterShippingAddress: "Shigar da adireshin sufuri",
    wellDoneYouAreDueToRedeem: "Sannu da aikatawa! Dole ne ku fanshe",
    toGetYourRewardNextVisit:
      "don samun ladan ku a ziyarar ku ta gaba. na gode",
    pointsOnThe: "Points a kan",
    morePointsToRedeem: "ƙarin maki don fansa",
    showCode: "Nuna lambar",
    toGetYourRewardOnNextVisit:
      "don samun ladan ku a ziyarar ku ta gaba. na gode",
    earn: "Sami",
    delivaryNote: "Bayanan Bayarwa",
    draftSales: "Daftarin Talla",
    startDate: "Kwanan farawa",
    endDate: "Kwanan ƙarshe",
    orders: "Umarni",
    checkout: "wurin biya",
    noProductItem: "Babu Kayan samfur",
    selectProductImage: "Zaɓi Hoton samfur",
    selectCountry: "Zaɓi ƙasa",
    selectRegion: "Zaɓi Jiha/Yanki",
    printProductTag: "Buga Alamar Samfura",
    transactionReference: "Maganar ma'amala",
    Cashier: "Cashier",
    Manager: "Manaja",
    Owner: "Mai gida",
    Admin: "Admin",
    addPartners: "Ƙara Abokan Hulɗa",
    addNewLoyaltyPartner: "Ƙara Sabon Abokin Aminci",
    pleaseEnterCompanyName: "Da fatan za a shigar da Sunan Kamfanin",
    companyName: "Sunan Kamfanin",
    pleaseEnterCompanyRepName: "Da fatan za a shigar da sunan wakilin kamfani",
    companyRepName: "Sunan Wakilin Kamfanin",
    pleaseEnterCompanyRepEmail:
      "Da fatan za a shigar da imel na wakilin kamfani",
    companyRepEmail: "Imel na wakilin kamfani",
    pleaseEnterCompanyRepPhone:
      "Da fatan za a shigar da lambar wayar wakilin kamfani",
    companyRepPhone: "Lambar waya na wakilin kamfani",
    addReward: "Ƙara lada",
    pleaseEnterRewardName: "Da fatan za a shigar da sunan lada",
    rewardName: "Sunan Lada",
    rewardQuantity: "Yawan Lada",
    rewardDescription: "Bayanin Lada",
    rewardType: "Nau'in Lada",
    pleaseEnterRewardType: "Da fatan za a shigar da nau'in lada",
    pleaseEnterRewardQuantity: "Da fatan za a shigar da adadin ladan",
    pleaseEnterRewardDescription: "Da fatan za a shigar da bayanin lada",
    fineDining: "Abincin Abinci",
    luxuryFashion: "Luxury Fashion",
    hotels: "Otal-otal",
    travel: "Tafiya",
    foodAndBeverage: "Abinci da Abin sha",
    fashion: "Fashion",
    health: "Lafiya",
    furniture: "Kayan daki",
    entertainment: "Nishaɗi",
    automobile: "Motoci",
    education: "Ilimi",
    beautyAndSpa: "Beauty da Spa",
    staycation: "Tsayawa",
    events: "Abubuwan da suka faru",
    trips: "Tafiya",
    oilAndGas: "Mai da Gas",
    laundry: "Wanki",
    partnerCategory: "Kashi na Abokin Hulɗa",
    freeItem: "Abun Kyauta",
  },
  Hawaiian: {
    cashier: "mea mālama kālā",
    manager: "luna hoʻokele",
    owner: "mea ʻona",
    online: "pūnaewele",
    offline: "pahemo",
    changePassword: "Hoʻololi ʻōlelo huna",
    currentPasswordMessage:
      "E ʻoluʻolu e hoʻokomo i kāu ʻōlelo huna o kēia manawa",
    passwordMessage: "E ʻoluʻolu e hoʻokomo i kāu ʻōlelo huna",
    currentPassword: "ōlelo huna i kēia manawa",
    password: "hua huna",
    confirmPasswordMessage: "E ʻoluʻolu e hōʻoia i kāu ʻōlelo huna!",
    confirmPassword: "Hōʻoia i ka 'Ōlelo Huna",
    sendViaEmail: "E hoʻouna Via leka uila",
    sendViaWhatsapp: "E hoʻouna Via WhatsApp",
    downloadPdf: "Hoʻoiho iā PDF",
    paid: "uku ʻia",
    unpaid: "uku ʻole ʻia",
    partial: "hapa hapa",
    closeInvoice: "Makemake ʻoe e pani i ka Invoice?",
    closeInvoiceConfirmation:
      "ʻAʻole hiki ke mālama ʻia nā invoice. Makemake ʻoe e pani?",
    yes: "ae",
    no: "aʻole",
    invoice: "Hoʻoikaika",
    wasDeducted: "unuhi ʻia",
    for: "no ka mea",
    item: "'Ikamu",
    addProduct: "Hoʻohui Huahana",
    paymentReference: "Palapala Uku",
    amountPaid: "Uku uku",
    discountAmount: "Huina uku hoʻēmi",
    amountDue: "Huina Kūpono",
    amount: "Huina",
    dueDate: "Palena pau",
    paymentType: "ʻAno uku",
    card: "Kāleka",
    cash: "Kālā Waiwai",
    bankTransfer: "Hoolilo Panakō",
    paymentMessage: "Palapala Uku",
    description: "Wehewehe",
    sendReceipt: "E hoʻouna palapala",
    delete: "Holoi",
    save: "Mālama",
    resend: "Hoʻouna Hou",
    saveAndSend: "Mālama",
    invoiceSaved: "Mālama ʻia nā invoice!",
    selectPaymentMethod: "E ʻoluʻolu e koho i kahi hana uku!",
    selectCustomer: "E ʻoluʻolu e koho i kahi mea kūʻai aku!",
    cartEmptyError:
      "ʻAʻole hiki i ka papa inoa kaʻa ke hakahaka, pani i ka invoice a hoʻohui i ka Item i ke kaʻa.",
    subscriptionExpired:
      "Ua pau kāu kau inoa a ua kaupalena ʻia kāu waihona. Kaomi ma ka Pihi ma lalo e hoʻohou i kāu moʻokāki",
    renew: "Hou",
    holdOn: "Ālia iki",
    customerBank: "Panakō mea kūʻai aku",
    cancel: "Hoʻopau",
    selectACustomer: "Koho i ka mea kūʻai aku",
    invoiceSuccessfulPdfError:
      "Ua kūleʻa ʻia nā invoice akā ʻoi aku ka lōʻihi o ka hanauna PDF ma mua o ka maʻamau. E ʻoluʻolu e hoʻi koke.",
    downloadingInvoice: "Ke hoʻoiho nei i nā uku pila",
    downloadingReceipt: "Ke hoʻoiho nei i ka loaʻa",
    whatsappReceiptError:
      "Ua loaʻa kahi hemahema i ka hoʻouna ʻana i ka loaʻa ma o WhatsApp, e ʻoluʻolu e hoʻāʻo hou.",
    receiptToWhatsapp: "Ua hoʻouna ʻia ka loaʻa iā WhatsApp",
    thankYouForPatronage: "Mahalo no kou kōkua ʻana mai",
    hereIsYourReceipt: "Eia kāu loaʻa uku",
    errorSendingEmailReceipt:
      "Ua loaʻa kahi hemahema i ka hoʻouna ʻana i ka loaʻa ma o ka leka uila, e ʻoluʻolu e hoʻāʻo hou a hoʻokaʻaʻike i ke kākoʻo",
    receiptSentToEmail: "Ua hoʻouna ʻia ka loaʻa i ka leka uila a ka mea kūʻai",
    invoiceSentToEmail:
      "Ua hoʻouna ʻia nā invoice i ka leka uila a ka mea kūʻai aku",
    invoiceSuccessWhatsappError:
      "Hana maikaʻi ʻia nā invoice akā ua hewa ka hoʻouna ʻana i WhatsApp. E hoʻāʻo hou i ka papa inoa invoice",
    invoiceSuccessfulEmailError:
      "Ua kūleʻa ʻia nā invoice akā ua loaʻa kahi hemahema i ka hoʻouna ʻana ma ke ʻano he leka uila. E ʻoluʻolu e hoʻāʻo hou mai ka papa inoa invoice",
    invoiceSentToWhatsapp: "Hoʻouna ʻia nā invoice iā WhatsApp",
    hello: "aloha",
    pleaseDownloadInvoice: "E ʻoluʻolu e hoʻoiho i ka invoice",
    pleaseDownloadReceipt: "E ʻoluʻolu e hoʻoiho i ka loaʻa",
    from: "mai",
    email: "Leka uila",
    upgrade: "Hoʻohou",
    youAreOnFreePlan: "Aia ʻoe ma kahi hoʻolālā manuahi.",
    clickOn: "Kaomi aku",
    yourPlanInFewSteps: " kou kau inoa i loko o kekahi mau kaʻina wikiwiki.",
    to: "i",
    yourSubscriptionHasExpired:
      "Ua pau kāu kau inoa a ua kaupalena ʻia kāu waihona.",
    days: "nā lā",
    yourSubscriptionExpiresIn: "Pau kāu kau inoa Loystar i",
    createAcount: "Hana i kahi moʻokāki",
    signIn: "Eʻe",
    continue: "Hoʻomau",
    enterOtp: "E hoʻokomo iā OTP PIN",
    enterValidOtp: "E ʻoluʻolu e hoʻokomo i kahi PIN kūpono",
    pin: "PIN",
    tokenSentToMail: "Ua hoʻouna ʻia kahi hōʻailona i kāu leka uila",
    passwordResetSuccessful: "Ua kūleʻa ka ʻōlelo huna",
    somethingWentWrong: "Ua hewa kekahi mea!",
    resetPassword: "Hoʻihoʻi ʻōlelo huna",
    iHaveResetCode: "He pāʻālua hoʻonohonoho ʻōlelo huna kaʻu",
    pleaseEnterEmail: "E ʻoluʻolu e hoʻokomo i kāu leka uila",
    aTokenWillBeSentToEmail: "E hoʻouna ʻia kahi hōʻailona i kāu leka uila",
    enterEmail: "E hoʻokomo i kāu leka uila",
    sendinYourToken: "Ke hoʻouna nei i kāu hōʻailona ...",
    makeSureItMatchesPassword: "E nānā pono e pili ana i kāu ʻōlelo huna hou",
    pleaseChooseNewPassword: "E ʻoluʻolu e koho i kahi huaʻōlelo hou",
    chooseNewPassword: "Koho i ka ʻōlelo huna hou",
    enterNewPassword: "E hoʻokomo i kāu ʻōlelo huna hou e hōʻoia",
    enterTokenSent: "E hoʻokomo i ka hōʻailona i hoʻouna ʻia i kāu leka uila",
    resetToken: "Hoʻihoʻi Hou",
    resettingPassword: "Ke hoʻonohonoho nei i kāu ʻōlelo huna ...",
    signUp: "Kau inoa",
    adminSignInWithEmail:
      " Kau inoa ʻo Admin me ka leka uila ʻoiai e kau inoa ana nā limahana me ka inoa inoa.",
    pleaseEnterEmailOrUsername:
      "E ʻoluʻolu e hoʻokomo i kāu leka uila a inoa inoa inoa paha",
    emailOrUsername: "Leka uila a i ʻole Username",
    pleaseEnterPassword: "E ʻoluʻolu e hoʻokomo i ka ʻōlelo huna",
    createAnAccount: "Hana i kahi moʻokāki",
    forgotPassword: "Poina password?",
    enterPhoneNumber: "E hoʻokomo i ka helu kelepona",
    personalData: "ʻIkepili Pilikino",
    validateConfirmationCOde: "ʻ .lelo hōʻoia hōʻoia",
    pleaseEnterFirstName: "E ʻoluʻolu e hoʻokomo i kou inoa mua",
    pleaseEnterPhoneNumber: "E ʻoluʻolu e hoʻokomo i kāu helu kelepona",
    pleaseEnterLastName: "E ʻoluʻolu e hoʻokomo i kou inoa hope",
    pleaseEnterBusinessName: "E ʻoluʻolu e hoʻokomo i kāu inoa ʻoihana",
    firstName: "Inoa mua",
    lastName: "Inoa hope",
    businessName: "Inoa ʻoihana",
    previous: "Mua",
    next: "Aʻe aʻe",
    pleaseSelectBusinessCategory: "E ʻoluʻolu e koho i kāu mahele ʻoihana",
    pleaseEnterValidEmail: "E ʻoluʻolu e hoʻokomo i kahi leka uila kūpono",
    pleaseEnterPostCode: "E ʻoluʻolu e hoʻokomo i ka pāʻālua pou",
    postCode: "Palapala Kālā",
    phoneNumberInUse: "Ke hoʻohana nei kēia helu kelepona!",
    emailInUse: "Ke hoʻohana ʻia nei kēia leka uila!",
    foodAndDrinks: "Mea ʻai a me nā mea inu",
    salonAndBeauty: "Salon a me ka nani",
    fashionAndAccessories: "Nā Hana Hou a me nā Pono",
    gymAndFitness: "Hale hoʻoikaika kino a me ka hoʻoikaika kino",
    travelAndHotel: "Huakaʻi a Hotel",
    homeAndGifts: "Ka home a me nā makana",
    washingAndCleaning: "Holoi a hoʻomaʻemaʻe",
    gadgetsAndElectronics: "Nā Hāmeʻa a me nā uila",
    groceries: "Nā meaʻai",
    others: "ʻO haʻi ʻē aʻe",
    submit: "Waiho",
    accountCreatedSuccessful: "Ua hana kūleʻa ʻia kāu waihona.",
    pleaseEnterAddress: "E ʻoluʻolu e hoʻokomo i kāu helu wahi",
    addressLine1: "Laina Kuhi 1",
    addressLine2: "Lā Kūlana 2",
    choosePassword: "Koho i ka ʻōlelo huna",
    passwordMustBe6Characters:
      "Pono ka huaʻōlelo huna ma kahi o 6 mau hua paʻi.",
    howDidYouHearLoystar: "Pehea ʻoe i lohe ai e pili ana iā Loystar?",
    referralCode: "hāmeʻa kuhikuhi",
    byClickingTheButton: " Ma ke kaomi ʻana i ke pihi ma lalo, ʻae ʻoe i ka",
    termsAndSevice: "Nā ʻlelo",
    wereCreatingAccount: "Ke hana nei mākou i kāu waihona",
    proceed: "Holomua",
    verificationCodeMustBe6: "Pono nā helu hōʻoia he 6 mau helu",
    pleaseEnter6DigitCode: "E ʻoluʻolu e hoʻokomo i ka pāʻālua helu 6",
    enterVerificationCode: "E hoʻokomo i ka pāʻālua hōʻoia",
    resendToken: "Hoʻouna Hou iā Token",
    verify: "Hōʻoia",
    transactions: "Nā hana",
    todaySales: "Nā kūʻai aku o kēia lā",
    salesHistory: "Mōʻaukala kūʻai",
    supplyHistory: "Mōʻaukala hoʻolako",
    new: "Hou",
    invoices: "Nā Invoice",
    disbursements: "Nā hoʻolilo kālā",
    offlineSales: "Kūʻai Kūwaʻi",
    products: "Nā huahana",
    customers: "Nā mea kūʻai mai",
    multiLevelLoyalty: "Kūlana Kūlana Kūlana Nui",
    loyaltyPrograms: "Nā Paʻa Kūpono",
    members: "Nā lālā",
    appStore: "Hale Kūʻai Kūʻai",
    orderMenu: "Papa Kuhikuhi Kauoha",
    settings: "Nā koho",
    staffAndBranches: "Nā limahana a me nā lālā",
    myAccount: "ʻO kaʻu waihona",
    switchToSellMode: "E kuapo i ke Kūʻai Kūʻai",
    signOut: "Lele",
    getFreeSubscription: "E kiʻi inoa manuahi",
    onlyNumbersAllowed: "ʻAe ʻia nā helu",
    yourAccountMustBe10Digits: "ʻo kāu helu helu he helu helu 10",
    yourBvnMustBe11: "pono kāu BVN he helu helu 11",
    pleaseSelectBank: "E ʻoluʻolu e koho i kāu panakō",
    selectYourBank: "Koho i kāu panakō",
    enterBankAccountNumber: "E hoʻokomo i ka helu moʻokāki panakō",
    enterBvnNumber: "E hoʻokomo i kāu BVN",
    connectBankAccount: "E hoʻohui i kā Waihona Bank",
    passwordResetSuccessfulAndSignOut:
      "Ua hoʻonohonoho hou ʻia kāu ʻōlelo huna. Kaomi ma ka pihi haʻalele ma lalo e komo hou",
    enterCurrentPassword: "E hoʻokomo i ka ʻōlelo huna o kēia manawa",
    pleaseEnterCurrentPassword:
      "E ʻoluʻolu e hoʻokomo i ka ʻōlelo huna o kēia manawa",
    phoneNumber: "Helu kelepona",
    sex: "Moekolohe",
    dateOfBirth: "Lā hānau",
    state: "Mokuʻāina",
    country: "ʻĀina",
    loyaltyId: "ID kūpaʻa",
    createdAt: "Hana ʻia ma",
    noOfTransactionsMade: "Helu o nā hana i hana ʻia",
    yourDownloadWillStart: "E hoʻomaka kāu hoʻoiho i kahi manawa",
    exportCustomers: "Mea hoʻolilo mea kūʻai mai",
    youhaveSuccessfullyToppedUp: "Ua kīleʻa i kāu mau Unit SMS.",
    youNowHave: "Loaʻa iā ʻoe i kēia manawa",
    smsUnits: "Units SMS",
    enterNumberOfSmsUnits:
      "E hoʻokomo i ka helu o nā Units SMS āu e manaʻo ai e kūʻai",
    pleaseEnterANumericValue: "E ʻoluʻolu e hoʻokomo i kahi helu helu",
    pay: "Uku",
    accountEditedSuccessfully: "Ua hoʻoponopono kūleʻa ʻia kāu moʻokāki.",
    youAeCurrentlyOn: "Aia ʻoe i kēia manawa",
    plan: "Hoʻolālā",
    userData: "ʻIkepili Mea hoʻohana",
    businessData: "ʻIkepili BUSINESS",
    businessCategory: "ʻOihana Catergory",
    pleaseSelectCurrency: "E ʻoluʻolu e koho i kāu kālā",
    selectYourCurrency: "Koho i kāu kālā",
    purchaseMoreSmsUnits:
      "Kūʻai i nā Units SMS hou me ka hoʻohana ʻana i ka palapala ma lalo",
    youHave: "Aia iā 'oe",
    atLeast4SmsUnitsRrequired:
      "Ma ka liʻiliʻi he 4 mau ʻāpana sms e koi ʻia no ka hōʻoia ʻana, e ʻoluʻolu e hoʻokau i luna.",
    pleaseVerifyYourAccountToComplyWithKyc:
      "E ʻoluʻolu e hōʻoia i kāu waihona pēke e hoʻokō i nā koina 'ʻIke i kāu mea kūʻai aku' (KYC). E ʻae kēia iā ʻoe e hōʻiliʻili i nā uku ma o USSD a i ʻole ka hoʻololi koke ʻana, loaʻa nā kauoha mai nā mea kūʻai aku a hana i ka hoʻoponopono ʻana i ka ʻoihana. Pili i nā mea kalepa ʻo Nigeria wale nō. E ʻoluʻolu e kaomi i ke pihi ma lalo e hoʻomaka.",
    reConnectBankAccount: "Hoʻohui hou i ka Waihona Bank",
    accountName: "Inoa inoa",
    accountNumber: "Helu moʻokāki",
    bankName: "Ka inoa panakō",
    verified: "Hooiaioia",
    accountDetails: "ʻIke kikoʻī",
    kycBankAccount: "KYC",
    createTier: "Hana iā Tier",
    fileUploadSuccessful: "ua hoʻoili kūleʻa ʻia",
    fileUploadFailed: "ua holo pono ʻole ka faila",
    createLoyaltyProgram: "Hana i kahi Polokalamu Loyalty",
    createLoyalty: "Hana Loyalty",
    name: "Inoa",
    loyaltyArtwork: "Hana Kaha ʻo Loyalty",
    clickToUpload: "Kaomi aku e hoʻouka",
    amountToPointsRatio: "Ka nui i nā helu helu",
    points: "Nā helu",
    recommendedAmountToPointRatio:
      "Paipai ʻia: ₦ 1 a 1point. ie no kēlā me kēia 1 Naira i lilo, loaʻa i kāu mau mea kūʻai aku ka helu 1",
    pleaseTypeIn: "E ʻoluʻolu e kikokiko",
    toDeleteLoyalty: "e kāpae i kēia kūpaʻa",
    deleteLoyalty: "Holoi i ka Loyalty",
    toConfirm: "e hoʻokūpaʻa",
    edit: "Hoʻoponopono",
    pointsAwardedSuccessfully: "Nā Kiʻi i hāʻawi kūleʻa ʻia.",
    enterPointValueToAward:
      "E hoʻokomo i ka waiwai nui āu e makemake ai e hāʻawi i nā mea kūʻai aku",
    award: "ʻO ka makana",
    awardPointValuesToCustomer: "Kahi waiwai i hāʻawi ʻia i ka mea kūʻai aku",
    enrollMembersToLoyalty: "Kākau inoa i nā lālā ma ke aloha",
    awardPoints: "Nā Makana Award",
    enroll: "Kākau inoa",
    home: "Ka home",
    loyalty: "Kūpaʻa",
    enrollCustomers: "Kākau inoa mea kūʻai aku",
    selected: "Koho ʻia",
    customer: "Mea kūʻai aku",
    loyaltyActivationSuccessful: "Kūpono ka Loyalty Activation.",
    loyaltyDeactivationSuccessful: "Kūleʻa ka Loyalty Deactivation.",
    viewTier: "Nānā Tier",
    deactivate: "De-haʻalele",
    activate: "Hoʻoikaika",
    actions: "Nā hana",
    nameOfLoyalty: "Ka inoa o ke aloha",
    loyaltyStatus: "Kūlana Kūpono",
    numberOfTiers: "Ka helu o nā pae",
    createdOn: "Hana ʻia",
    createATier: "Hana i kahi Tier",
    stopCreatingTierConfirmation:
      "Makemake ʻoe e hōʻoki i ka hana ʻana i kahi pae?",
    stopEditingTierConfirmation:
      "Makemake ʻoe e hōʻoki i ka hoʻoponopono ʻana i kēia pae?",
    nameOfTier: "Ka inoa o Tier",
    minimumSpendingTarget: "Pahuhopu Noho Minimum",
    maximumSpendingTarget: "Pahuhopu hoʻolimalima kiʻekiʻe",
    minimumSpendingTargetRequired: "koi ʻia ka pahu hopu palena iki",
    maximumSpendingTargetRequired: "koi ʻia ka pahuhopu nui loa",
    rewardSponsor: "Kākoʻo makana",
    pleaseChooseARewardSponsor: "E ʻoluʻolu e koho i kahi mea kākoʻo uku",
    self: "Pakiko",
    partner: "Hoapili",
    rewardPartner: "Hoa Makana",
    pleaseSelectRewardPartner: "E ʻoluʻolu e koho i kāu hoa uku",
    rewards: "Nā Makana",
    pleaseSelectAReward: "E ʻoluʻolu e koho i kahi uku",
    instructionsOnRedeemingReward:
      "Nā ʻōkuhi e pili ana i ka mea e kūʻai aku ai ka mea kūʻai aku i ka makana",
    pleaseFillInThisField: "E ʻoluʻolu e hoʻopihapiha i kēia kahua!",
    toDeleteThisTier: " e kāpae i kēia pae",
    deleteTier: "Holoi i ka Tier",
    viewMembers: "Nānā i nā Lala",
    membersEnrolled: "Kākau inoa ʻia nā lālā",
    instruction: "Aʻo",
    membersIn: "Nā lālā i loko",
    availableTiersInLoyalty: "Loaʻa nā Tier (s) i ka Polokalamu Loyalty",
    tiers: "ʻO nā pae kiʻekiʻe",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Polokalamu Loyalty Loaʻa",
    totalLoyalties: "NA HUINA HOLO",
    memberDetails: "Nā kikoʻī lālā",
    nameOfCustomer: "Ka inoa o ka mea kūʻai aku",
    address: "Wahi kamaʻilio",
    allEnrolledMembers: "Nā lālā i kākau inoa ʻia",
    thisIsToWishYouHappyBirthday:
      "ʻO kēia ke makemake nei iā ʻoe i kahi lā hauʻoli a me ke ola holomua. Mahalo no nā mea āpau iā Loyster. Hauʻoli hauʻoli!",
    inputAnOfferPlease: "E hoʻokomo i kahi ʻoluʻolu",
    pleaseSelectTheInsertPoint:
      "E ʻoluʻolu e koho i kahi kiko hoʻokomo i ka leka a kaomi hou",
    birthdayOfferAndMessage: "ʻO ka mōhai hānau a me ka leka",
    birthdayOffer: "ʻO ka hāʻawi lā hānau",
    birthdayMessage: "ʻLelo Leka",
    noOfferFound: "ʻAʻohe hāʻawi i loaʻa",
    settingABirthdayOffer:
      "Ke hoʻonohonoho nei i kahi hāʻawi lā hānau hiki i nā mea kūʻai aku ke loaʻa i kēia makana ma o SMS i ko lākou lā hānau",
    createOffer: "Hana i ka hāʻawi",
    whatIsTheOffer: "He aha ka hāʻawi?",
    editMessage: "Hoʻoponopono i ka leka",
    insert: "Hoʻokomo",
    theNameOfCustomerInserted:
      "E hoʻokomo ʻia ka inoa o ka mea kūʻai aku ma aneʻi",
    theBirtdayOfferInserted: "E hoʻokomo ʻia ka makana lā hānau ma aneʻi",
    youSuccessfullyAddedNewBranch: "Ua hoʻohui maikaʻi ʻoe i lālā hou!",
    addNewBranch: "Pākuʻi i Lala Hou",
    addBranch: "Pākuʻi Lālā",
    additionalBranchWillIncur: "E loaʻa nā lālā hou",
    perBranch: "no kēlā me kēia lālā",
    ecommerceBranchCosts: "Nā kumukūʻai Ecommerce Branch",
    pleaseEnterBranchName: "E ʻoluʻolu e hoʻokomo i ka inoa lālā",
    pleaseEnterBranchAddress1:
      "E ʻoluʻolu e hoʻokomo i ka laina helu 1 o ka lālā",
    enterBranchAddress1: "E hoʻokomo i ka laina helu 1 o ka lālā",
    enterBranchAddress2: "E hoʻokomo i ka laina helu 1 o ka lālā",
    pleaseEnterBranchAddress2:
      "E ʻoluʻolu e hoʻokomo i ka leka uila 2 o ka lālā",
    enterBranchName: "E hoʻokomo i ka inoa lālā",
    successfullyAddedStaff: "Ua hoʻohui maikaʻi ʻoe i kahi limahana hou!",
    addNewStaff: "Hoʻohui i nā limahana hou",
    addStaff: "Pākuʻi Staff",
    additionalStaffWillIncur: "E loaʻa nā limahana hou aʻe",
    perStaff: "no kēlā me kēia koʻokoʻo.",
    pleaseEnterStaffEmail: "E ʻoluʻolu e hoʻokomo i ka leka uila o nā limahana",
    pleaseEnterStaffUsername:
      "E ʻoluʻolu e hoʻokomo i ka inoa inoa o nā limahana",
    pleaseEnterStaffPassword:
      "E ʻoluʻolu e hoʻokomo i ka ʻōlelo huna a nā limahana",
    pleaseSelectStaffRole: "E ʻoluʻolu e koho i ke kuleana o nā limahana",
    selectStaffRole: "Koho i ka hana a nā limahana",
    enterStaffEmail: "E hoʻokomo i ka leka uila o nā limahana",
    enterStaffUsername: "E hoʻokomo i ka inoa inoa o nā limahana",
    enterStaffPassword: "E hoʻokomo i ka ʻōlelo huna a nā limahana",
    spacesNotAllowedInUsername: "ʻaʻole ʻae ʻia nā hakahaka i ka inoa inoa",
    admin: "Luna Hoʻokele",
    pleaseSelectBusinessToAttachStaff:
      "E ʻoluʻolu e koho i kahi ʻoihana e hoʻopili ai i nā limahana",
    searchForBranchToAttachStaff: "Huli i nā lālā e hoʻopili i nā limahana",
    username: "Inoa mea hoʻohana",
    role: "Ke kuleana",
    areYouSureToDeleteThis: "Holoi paha ʻoe i kēia",
    branches: "Nā lālā",
    upgradeYourPlan: "Hoʻonui i kāu Kālā.",
    add: "Hoʻohui",
    addNew: "Pākuʻi Hou",
    customerWithEmailAlreadyExists:
      "ʻO ka mea kūʻai aku me ka helu leka uila / kelepona i kēia manawa!",
    successfullyAddedNewCustomer:
      "Ua hoʻohui maikaʻi ʻoe i kahi mea kūʻai aku hou!",
    addCustomer: "Pākuʻi mea kūʻai aku",
    pleaseEnterCustomerFirstName:
      "E ʻoluʻolu e hoʻokomo i ka inoa o ka mea kūʻai aku",
    pleaseEnterCustomerLastName:
      "E ʻoluʻolu e hoʻokomo i ka inoa hope o ka mea kūʻai aku",
    pleaseEnterCustomerPhoneNumber:
      "E ʻoluʻolu e hoʻokomo i ka helu kelepona o ka mea kūʻai aku",
    pleaseEnterCustomerEmail:
      "E ʻoluʻolu e hoʻokomo i ka leka uila a ka mea kūʻai",
    pleaseEnterCustomerAddressLine:
      "E ʻoluʻolu e hoʻokomo i ka laina helu o ka mea kūʻai aku",
    pleaseEnterCustomerOtherAddress:
      "E ʻoluʻolu e hoʻokomo i ka helu ʻē aʻe o ka mea kūʻai aku",
    pleaseSelectCustomerGender: "E ʻoluʻolu e koho i ka wahine kāne a wahine",
    gender: "Hoohanau",
    male: "Kāne kāne",
    female: "Wahine",
    bank: "Panakō",
    selectBank: "Koho Bank",
    stateOrRegionOrProvince: "Moku'āina / ʻĀpana / Panalāʻau",
    customerNotes: "Nā memo mea kūʻai aku",
    sendSms: "E hoʻouna SMS",
    editCustomer: "Hoʻoponopono mea kūʻai aku",
    redeemReward: "E panaʻi i ka makana",
    issueLoyaltyCard: "Puka Loyalty Card",
    deleteCustomer: "Holoi i ka mea kūʻai aku",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ua hāʻawi kūleʻa ʻoe i kahi ID Loyalty Membership",
    noMembershipIdAvailable:
      "ʻAʻole loaʻa nā ID lālā. E ʻoluʻolu e leka uila iā mākou ma hello@loystar.co",
    sendEmail: "E hoʻouna leka uila",
    membershipPoints: "Nā helu lālā",
    customerDetails: "Nāʻike o ka mea kūʻai aku",
    close: "Pani",
    loyaltyBalance: "Palena kūpaʻa",
    pointsBalance: "Kānāwai kaulike",
    starBalance: "Kaulike Hōkū",
    requiredPoints: "Nā Manaʻo Koi",
    requiredStars: "Nā Hōkū i koi ʻia",
    reddemCode: "Lanakila Code",
    toDeleteThisCustomer: "e kāpae i kēia mea kūʻai",
    customerHasBeenDeletedSuccessfully:
      "Ua kāpae maikaʻi ʻia ka mea kūʻai aku!",
    customerWithPhoneAlreadyExists: "ʻO ka mea kūʻai aku me ka helu kelepona",
    customerWasSuccessfullyEdited:
      "Ua hoʻoponopono maikaʻi ʻia ka mea kūʻai aku",
    anErrorOccured: "Ua loaʻa kekahi hewa",
    phoneNumberIncludeCountry: "Helu kelepona (komo pū ka helu ʻāina)",
    yourFileQueuedForUpload:
      "Ua kau ʻia kāu faila no ka hoʻouka ʻana. E ʻoluʻolu e hōʻoluʻolu i ka ʻaoʻao ma hope o kekahi mau kekona.",
    thereWasAnErrorPerformingOperation: "Ua loaʻa kekahi hemahema i ka hana!",
    pleaseSelectFile: "E ʻoluʻolu e koho i kahi faila!",
    oopsOnlyCsvFilesAllowed:
      "Aloha! ʻAe ʻia nā faila CSV wale nō. E ʻoluʻolu e hoʻoiho i kahi faila .csv.",
    fileShouldBeSmallerThan5Mb:
      "E liʻiliʻi ka faila ma mua o 5MB. Inā he faila nui aʻe kāu, e ʻoluʻolu e leka uila iā support@loystar.co. Mahalo",
    customerFirstNameIsRequired:
      "Koi ʻia ka inoa mua o ka mea kūʻai aku ma ka lālani",
    customerPhoneNumberIsRequired:
      "Koi ʻia ka helu kelepona mea kūʻai aku ma ka lālani",
    importCustomers: "Import nā mea kūʻai mai",
    upload: "Hoʻouka",
    clickIconToDownloadCsv: "Kaomi i kēia ikona e hoʻoiho i ka anakuhi CSV.",
    getGoogleSheetVersion: "E kiʻi i ka mana palapala Google",
    clickOrDragFileToUpload: "Kaomi a kauō paha i kēia wahi e hoʻouka ai",
    missingOrInvalidColumnHeader:
      "Poʻomanaʻo kolamu hemahema a hewa ʻole paha. E ʻoluʻolu e hāhai i ka palapala anakuhi. Mahalo.",
    youHaveImported: "Oe i lawe mai",
    youSuccessfullyRedeemedReward: "Ua kūleʻa aku ʻoe i kāu uku!",
    sixDigitCode: "ʻEono hua helu helu",
    pleaseEnterCustomerRewardCode:
      "E ʻoluʻolu e hoʻokomo i ka pānaʻi uku o ka mea kūʻai aku",
    enterRewardCode: "E hoʻokomo i ka pālua uku. (Pilikia ka helu pānaʻi)",
    selectLoyaltyProgram: "Koho i ka papahana kūpaʻa",
    stamps: "Nā peʻa",
    smsUnitsLow: "Nā Pūnaewele SMS Low",
    pleaseTopUpSmsUnits: "E ʻoluʻolu e hoʻopiha i nā Units SMS",
    smsSuccessfullySentForDelivery:
      "Ua hoʻouna maikaʻi ʻia ʻo SMS no ka hoʻouna ʻana!",
    sendSmsTo: "E hoʻouna SMS iā",
    allCustomers: "Nā mea kūʻai mai āpau",
    unitsAvailable: "Loaʻa nā Units",
    pleaseTypeInTheMessage: "E ʻoluʻolu e kikokiko i ka leka",
    message: "Palapala",
    charactersRemaining: "mau huapalapala i koe",
    avoidUseOfSpecialCharacters:
      "Hōʻalo i ka hoʻohana ʻana o nā Pūʻali Kūlana Kūlana a me nā Emojis e mālama i nā Units SMS.",
    note: "Kaha",
    errorFetchingCustomersMultilevelDetail:
      "Hewa i ka lawe ʻana aku i nā mea kūʻai aku i nā kikoʻī MultiLevel",
    search: "Huli",
    reset: "Hoʻihoʻi hou",
    importCustomer: "Import mea kūʻai mai",
    addNewCustomer: "Hoʻohui i ka mea kūʻai aku hou",
    sendSmsBroadcast: "E hoʻouna i ka hoʻolaha SMS",
    totalCustomers: "NĀ KĀLĀWAI LOA",
    disbursementDetails: "Nāʻike kikoʻī",
    paidBy: "Uku ʻia e",
    disbursed: "Hoʻouna ʻia",
    bankAccountName: "Ka inoa inoa waihona panakō",
    bankAccountNumber: "Helu Waihona Waihona",
    transferInitiated: "Hoʻomaka Hoʻolilo",
    transferCompleted: "Hoʻopau Hoʻololi",
    pleaseEnterAValid: "E ʻoluʻolu e hoʻokomo i kahi kūpono",
    begin: "hoʻomaka",
    end: "hoʻopau",
    date: "lā",
    paymentDate: "Lā uku",
    selectDisbursementDuration: "Koho i ka lōʻihi hoʻolilo",
    totalSettled: "Huina Noho",
    totalUnsettled: "Huina unsettled",
    toDeleteThisSale: "e kāpae i kēia kūʻai",
    draftSaleDeletedSuccessfully: "Ua kāpae maikaʻi ʻia ke kikoʻī Draft!",
    deleteSale: "Holoi kūʻai",
    pleaseTypeInYourTotal:
      "E ʻoluʻolu e kikokiko i kāu huina e hōʻoia i ka holoi ʻana",
    billDetails: "Nā kikoʻī bila",
    printBill: "Bila Paʻi",
    servedBy: "Lawelawe ʻia e",
    total: "Huina",
    createdDate: "Lā i haku ʻia",
    createdTime: "Manawa i haku ʻia",
    status: "Kūlana",
    loginSuccessful: "Login kūleʻa",
    pleaseWaitWhileWeConnectAccount:
      "E ʻoluʻolu e kali mākou i ka wā e hoʻohui ana i kāu moʻokāki",
    connectionFailedTryAgain: "Ua kūleʻa ka pilina. E ʻoluʻolu e hoʻāʻo hou.",
    connectionSuccessful: "Ua kūleʻa ka pilina",
    viewDetails: "E nānā i nā kikoʻī",
    enable: "Hiki iā",
    free: "Manuahi",
    cost: "Kumukūʻai",
    visitWebsite: "E kipa pūnaewele",
    pleaseUpgradeYourPlanToPro:
      "E ʻoluʻolu e hoʻonui i kāu hoʻolālā iā Pro Plus e hiki ai i kēia polokalamu",
    connectYourBankAccount:
      "E hoʻohui i kāu Waihona Bank e hiki ai ke loaʻa nā uku.",
    disable: "Hoʻopau ʻole",
    enableApp: "Hoʻā i ka App",
    addNewProductToInvoice: "Hoʻohui Huahana Hou i ka Invoice",
    toDeleteThisInvoice: "e kāpae i kēia Invoice",
    invoiceDeletedSuccessfully: "Ua kāpae ʻia ka invoice!",
    deleteInvoice: "Holoi i ka pila",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ua hoʻohui maikaʻi ʻoe i kahi leka uku Invoice!",
    pleaseEnterPaymentInstructions: "E ʻoluʻolu e hoʻokomo i nā ʻōkuhi uku",
    invoiceId: "ID hōʻano",
    paidDate: "Lā uku",
    reference: "Kākauʻōlelo",
    productAdded: "Pākuʻi ʻia ka huahana",
    productOutOfStock:
      "Huahana ma waho o ka waihona. E ʻoluʻolu e hoʻouka hou.",
    totalInvoices: "NA INVOIKA LOA",
    totalPaidInvoices: "INVOICES UAHU LOA",
    totalUnpaidInvoices: "INVOICES TOTAL UNPAID",
    loyaltyProgramDeleted: "Holoi ʻia ka Polokalamu Loyalty",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ua holoi holoi ʻia kēia polokalamu kūpaʻa",
    thisLoyaltyProgramEditedSuccessfully:
      "Ua hoʻoponopono kūleʻa ʻia kēia polokalamu kūpaʻa",
    loyaltyProgramAddedSuccessfully:
      "Ua hoʻohui maikaʻi ʻia ka papahana kūpaʻa",
    loyaltyProgramEdited: "Hoʻonohonoho ʻia ka Polokalamu Loyalty",
    loyaltyProgramAdded: "Hoʻohui ʻia ka papahana kūpaʻa",
    loyaltyDetails: "Nā Kūʻai Kūpono",
    doYouWantToCloseDialog: "Makemake ʻoe e pani i kēia kamaʻilio?",
    pleaseEnterLoyaltyName: "E ʻoluʻolu e hoʻokomo i ka inoa o kāu kūpaʻa",
    programType: "ʻAno Polokalamu",
    pleaseSelectProgramType: "E ʻoluʻolu e koho i kahi ʻano papahana",
    simplePoints: "Nā Punana Maʻalahi",
    stampsProgram: "Palapala Sila Nui",
    threshold: "Paepae",
    pleaseEnterLoyaltyThreshold: "E ʻoluʻolu e hoʻokomo i ka paepae kūpaʻa",
    reward: "Makana",
    pleaseEnterLoyaltyReward: "E ʻoluʻolu e hoʻokomo i ka uku o ke kūpaʻa",
    totalUserPoints: "Huina helu o nā mea hoʻohana",
    totalUserStamps: "Huina hoailona o nā mea hoʻohana",
    spendingTarget: "Pahuhopu hoʻolilo kālā",
    spendingTargetHint1:
      "ʻO ka māka hoʻolālā ka nui o ka mea kūʻai aku e lilo ai i ka uku. 1 kālā waiwai = 1 puan.",
    spendingTargetHint2:
      "ʻO ka pahuhopu paʻi ka nui o nā peʻa a ka mea kūʻai mai e ʻohiʻohi ai e loaʻa ka uku. eg 5",
    addNewLoyaltyProgram: "Hoʻohui i ka Polokalamu Loyalty Hou",
    addLoyaltyProgram: "Pākuʻi i ka Polokalamu Loyalty",
    loyaltyProgramType: "ʻAno Polokalamu Loyalty",
    pleaseSelectLoyaltyProgramType:
      "E ʻoluʻolu e koho i kahi ʻano papahana kūpaʻa",
    nameOfProgram: "Ka inoa o ka papahana",
    pleaseEnterProgramName: "E ʻoluʻolu e hoʻokomo i ka inoa polokalamu",
    whatIsTheReward: "He aha ka uku?",
    egNextPurchaseIsFree: "E like me ʻAʻohe manuahi ke kūʻai ʻana mai",
    customerName: "Inoa mea kūʻai mai",
    guestCustomer: "Mea kūʻai aku hoʻokipa",
    orderConfirmedSuccessfully: "Ua hōʻoia maikaʻi ʻia ke kauoha",
    orderCancelledSuccessfully: "Ua hoʻopau kūleʻa ʻia ke kauoha",
    confirmOrder: "E hōʻoia i ke kauoha",
    cancelOrder: "Hoʻoiho i ke kauoha",
    allOrders: "Nā Kauoha āpau",
    totalOrders: "NA KALAIAINA TOTAL",
    doYouWantToAcceptOrder: "Makemake ʻoe e ʻae i kēia kauoha?",
    doYouWantToCancelOrder: "Makemake ʻoe e hoʻopau i kēia kauoha?",
    orderDetails: "ʻIke kikoʻī",
    orderCreatedAt: "Kauoha ʻia ma",
    supplier: "Mea hoʻolako",
    productName: "inoa mea kūʻai",
    quantity: "Mea",
    unitPrice: "Kumukūʻai Kūʻai",
    receivedBy: "Loaa e",
    reportFrom: "Hoike mai",
    totalSupplies: "Huina lako",
    allRightsReserved: "Waiho ʻia nā kuleana āpau",
    toDeleteThisTransaction: "e kāpae i kēia Hana",
    transactionDeletedSuccessfully:
      "Ua kāpae ʻia ka hana me ke kūleʻa. Ua hoʻihoʻi ʻia ka waihona i ka papa inoa.",
    deleteTransaction: "Holoi i ka hana",
    transactionDetails: "Nāʻike kikoʻī",
    printReceipt: "Palapala i loaʻa ʻia",
    channel: "Kanal",
    discount: "Hoʻoiho",
    profit: "Ka loaʻa kālā",
    discountedSales: "Nā Kūʻai Hoʻoiho",
    errorFetchingRecord: "Hewa",
    exportTransactions: "Kuhi i nā hana",
    errorFetchingSalesRecord:
      "Kuʻia ka hewa i ke kiʻi ʻana i nā moʻolelo kūʻai no ka mea hoʻolilo.",
    totalSellingPrice: "Huina Kūʻai Kūʻai",
    totalCostPrice: "Kumukuai Kumukuai",
    appliedDiscount: "Uku Hoʻoiho",
    noOfItems: "Helu o nā huahana",
    sales: "Kūʻai kūʻai",
    export: "Hoʻouna aku",
    totalProfit: "Huina Loaʻa kālā",
    totalBalanceInPeriod: "Huina koena i loko o ka wā",
    allTimeSales: "Kūʻai Manawa Manawa āpau",
    records: "Nā moʻolelo",
    todaysSales: "Nā kūʻai aku o kēia lā",
    transaction: "kālepa",
    youHaveExceededTotalNumberOfProducts:
      "Ua ʻoi aku ʻoe i ka huina o nā huahana i ʻae ʻia i kāu hoʻolālā. E hoʻonui i kāu hoʻolālā e ʻoliʻoli i kahi palena kiʻekiʻe.",
    youNeedToHaveLoyaltyProgram:
      "Pono ʻoe i kahi Polokalamu Loyalty e hoʻohana i kēia hiʻohiʻona!",
    price: "Kumukūʻai",
    category: "Māhele",
    stockTracking: "Kaha Kūʻai",
    stockCount: "Helu Waiwai",
    taxed: "Hoʻolālā ʻia",
    originalPrice: "Kumukūʻai",
    costPrice: "Kumukuai kumukūʻai",
    unit: "ʻĀpana",
    productImage: "Kiʻi Huahana",
    taxRate: "Helu ʻauhau",
    taxType: "ʻAno ʻauhau",
    trackInventory: "Inventory Track",
    variants: "Nā ʻano ʻē",
    hasVariants: "Loaʻa nā ʻano ʻē",
    importProduct: "Huahana Import",
    exportProducts: "Mea hoʻolilo huahana",
    addNewProduct: "Hoʻohui Huahana Hou",
    viewCategory: "Nānā Māhele",
    viewSuppliers: "Nānā Mea hoʻolako",
    receiveInventory: "Loaʻa Inventory",
    printAllProductsTag: "Pai i nā huahana huahana āpau",
    deleteAll: "Holoi i nā mea āpau",
    totalProducts: "Huahana Huahana",
    youveSuccessfullyAddedANewCategory:
      "Ua hoʻohui maikaʻi ʻoe i kahi ʻāpana hou",
    addNewCategory: "Hoʻohui Māhele Hou",
    addCategory: "Pākuʻi Māhele",
    categoryName: "Inoa waeʻano",
    pleaseEnterCategoryName: "E ʻoluʻolu e hoʻokomo i ka inoa waeʻano",
    stampsTarget: "Pahuhopu kuhi",
    sendInventory: "E hoʻouna Inventory",
    productDetails: "ʻIke kikoʻī",
    youveSuccessfullyEditedThisCategory:
      "Ua hoʻoponopono maikaʻi ʻoe i kēia mahele",
    update: "Hoʻohou",
    categoryList: "Papa inoa Māhele",
    categories: "Māhele",
    enterQuantityToUpdate: "E hoʻokomo i kahi mea e hoʻomaikaʻi ai",
    inventorySentSuccessfully: "Ua hoʻouna kūleʻa ʻia ka papa helu waiwai!",
    updateInventory: "Hōʻano Hou Inventory",
    currentQuantity: "ʻO ka nui o kēia manawa",
    pleaseEnterQuantityToAdd:
      "E ʻoluʻolu e hoʻokomo i ka nui āu e makemake ai e hoʻohui",
    pleaseSelectABranch: "E ʻoluʻolu e koho i kahi Lālā",
    searchForBranch: "E ʻimi no ka lālā",
    youCantSendMoreThanStock:
      "ʻAʻole hiki iā ʻoe ke hoʻouna i nā mea hou aʻe ma mua o kāu i ka waihona",
    send: "Hoʻouna",
    pleaseEnterQuantityToSend:
      "E ʻoluʻolu e hoʻokomo i ka nui āu e makemake ai e hoʻouna",
    productNameIsRequiredOnRow: "koi ʻia ka inoa huahana ma ka lālani",
    productCategoryIsRequiredOnRow: "koi ʻia ka huahana ma ka lālani",
    productPriceIsRequiredOnRow: "koi ʻia ke kumukūʻai huahana ma ka lālani",
    productUnitIsRequiredOnRow: "koi anakahi huahana ma ka lalani",
    productQuantityIsRequiredOnRow: "koi ʻia ka nui o ka huahana ma ka lālani",
    productVariantsRequireTracking: "Pono nā ʻano huahana e nānā pono!",
    pleaseAddVariantClickButton:
      "E ʻoluʻolu e hoʻohui i kahi ʻokoʻa ma ke kaomi ʻana i ke pihi hoʻohui!",
    totalVariantsMoreThanSelectedQuantity:
      "ʻOi aku ka nui o nā ʻano like ʻole ma mua o ka nui o ka huahana i koho ʻia, Pls hoʻemi i ka nui o nā ʻano",
    productEditedSuccessfully: "Ua hoʻoponopono maikaʻi ʻia ka huahana!",
    fileTooLargeLessThan4Mb:
      "Nui loa ka faila! ʻO ka nui o ka faile ma lalo o 4MB.",
    suchVariantAlreadyExist: "ʻO kēlā ʻano ʻano ʻē",
    addVariants: "Hoʻohui i nā ʻano",
    editProduct: "Hoʻoponopono Huahana",
    pleaseEnterProductName: "E ʻoluʻolu e hoʻokomo i ka inoa huahana",
    pleaseEnterProductPrice: "E ʻoluʻolu e hoʻokomo i ke kumukūʻai huahana",
    pleaseEnterProductOriginalPrice:
      "E ʻoluʻolu e hoʻokomo i ke kumu kūʻai kumu",
    productDescription: "Hōʻike Huahana",
    selectProductCategory: "Koho i ka mahele huahana",
    pleaseSelectProductCategory: "E ʻoluʻolu e koho i ka mahele huahana",
    productCostPrice: "Kumukuai kumukūʻai huahana",
    productSellingPrice: "Kumukuai kūʻai huahana",
    productOriginalPrice: "Huahana Kumumua Kūʻai",
    maxFileSizeAllowedIs4Mb: "Max. ʻAe ʻia ka nui o ka faila ma 4mb",
    productsWithPicturesArePublished:
      "Hoʻopuka ʻia nā huahana me nā kiʻi ma Discover e loaʻa ai nā kauoha",
    shouldThisProductBeTracked: "Pono e nānā i kēia huahana?",
    pleaseSelectStockUnit: "E ʻoluʻolu e koho i kahi anakuhi",
    stockUnit: "ʻĀpana Waihona",
    bag: "EKE",
    bottle: "ʻOTLELO BOTOLE",
    bunch: "BUNCH",
    can: "CAN",
    carton: "KALEKA",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "ʻO GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKU",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "Pālaha",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "PALAPALA",
    pleaseEnterProductQuantity: "E ʻoluʻolu e hoʻokomo i ka nui o ka huahana",
    productQuantity: "Huahana Huahana",
    isThisProductTaxed: "ʻAuhau ʻia kēia huahana?",
    selectTaxType: "Koho ʻAno ʻauhau",
    pleaseSelectTaxType: "E ʻoluʻolu e koho i kahi ʻano ʻauhau",
    progressive: "Holomua",
    proportional: "Kūlike kaulike",
    pleaseEnterProductTaxRate: "E ʻoluʻolu e hoʻokomo i ka helu ʻauhau huahana",
    doesProductHaveVariants: "Loaʻa nā mea ʻokoʻa i kēia huahana?",
    type: "ʻAno",
    value: "Waiwai",
    pleaseEnterVariantType: "E ʻoluʻolu e hoʻokomo i ka ʻano o nā ʻano",
    pleaseEnterVariantValue: "E ʻoluʻolu e hoʻokomo i ka waiwai o variant",
    pleaseEnterVariantPrice: "E ʻoluʻolu e hoʻokomo i ke kumu kūʻai o variant",
    pleaseEnterVariantQuantity: "E ʻoluʻolu e hoʻokomo i ka nui o ka variant",
    productDeletedSuccessfully: "Ua kāpae maikaʻi ʻia ka huahana!",
    categoryDeletedSuccessfully: "Ua kāpae maikaʻi ʻia ka mahele!",
    toDeleteThisProduct: "e kāpae i kēia Huahana",
    invalidProductQuantity: "Huina Huahana kūpono ʻole",
    quantityAddedIsOverStock:
      "ʻO ka nui āu e hoʻohui nei ma luna o kāu mea i ka waihona.",
    itemInventoryNotTracked: "ʻAʻole nānā ʻia ka waihona ukana",
    addBulkQuantity: "Hoʻohui i ka nui o ka nui",
    enterBulkQuantity: "E komo i ka nui nui",
    pleaseEnterBulkQuantity: "E ʻoluʻolu e hoʻokomo i ka nui o ka nui",
    youveSuccessfullyAddedANewProduct:
      "Ua hoʻohui maikaʻi ʻoe i kahi huahana hou!",
    pleaseEnterProductSellingPrice:
      "E ʻoluʻolu e hoʻokomo i ke kumukūʻai kūʻai huahana",
    doYouWantToTrackProductStock: "Makemake ʻoe e nānā i ka waihona huahana?",
    sellingPrice: "Kumukuai kūʻai",
    setProductExpiryReminder: "Hoʻonohonoho Hoʻomanaʻo Hoʻomālā Huahana",
    productExpiryDate: "Lā pau o ka huahana",
    startRemindingFrom: "Hoʻomaka Hoʻomanaʻo Mai",
    productSuppliesAddedSuccessfully:
      "Ua hoʻohui maikaʻi ʻoe i nā lako huahana.",
    addProductSupplies: "Hoʻohui i nā lako huahana",
    pleaseSelectSupplier: "E ʻoluʻolu e koho i ka mea hoʻolako",
    nameOfProduct: "Inoa o ka huahana",
    pleaseSelectProduct: "E koho i ka huahana",
    productVariant: "Lolina Huahana",
    pleaseSelectAVariant: "E ʻoluʻolu e koho i kahi ʻano ʻē",
    pleaseEnterUnitPrice: "E ʻoluʻolu e hoʻokomo i ka uku kumukūʻai",
    businessBranch: "Lala ʻOihana",
    pleaseSelectBranch: "E ʻoluʻolu e koho i lālā",
    youveSuccessfullyAddedANewSupplier:
      "Ua hoʻohui maikaʻi ʻoe i kahi mea hoʻolako hou",
    addSupplier: "Pākuʻi Mea hoʻolako",
    pleaseEnterSupplierEmail:
      "E ʻoluʻolu e hoʻokomo i ka leka uila a ka mea hoʻolako",
    pleaseAddADescription: "E ʻoluʻolu e hoʻohui i kahi wehewehe",
    anErrorOccuredProductsDeleted:
      "Ua loaʻa kekahi hemahema i ka hana ʻana i kēia hana. E ʻoluʻolu e hoʻomaopopo, he kakaikahi paha nā huahana i kiʻi ʻia i ke kaʻina",
    bulkDelete: "Holoi nui",
    youAreAboutToDelete: "E kāpae ana ʻoe",
    product: "Huahana",
    isDueToRedeem: "pono e panai",
    aReward: "he uku",
    pleaseSelectCustomerToReeemReward:
      "E ʻoluʻolu e koho i kahi mea kūʻai aku e kūʻai i ka uku.",
    youHaveNoLoyaltyProgramRunning:
      "ʻAʻohe ou polokalamu hana kūpaʻa e holo nei",
    customerHhasNoPointsInLoyaltyProgram:
      "ʻAʻohe helu o ka mea kūʻai aku i kēia papahana kūpaʻa",
    proceedWithPayment: "Ke hoʻomau nei me ka uku?",
    youAreAboutToPayForTransactionUsingPoints:
      "E uku ana ʻoe i ke kālepa e hoʻohana nei i nā helu.",
    customerHas: "Loaʻa iā Customer",
    worth: "waiwai",
    andIsNotSufficientToPayForTransaction:
      "a ʻaʻole lawa e uku no kēia hana. Makemake lākou e hoʻohui i ke kaulike me ka hoʻohana ʻana i kahi ʻano uku?",
    addCustomerLoyalty: "Pākuʻi i ka Loyalty Mea kūʻai",
    pleaseAddCustomerFirst:
      "E ʻoluʻolu e hoʻohui a koho paha i kahi mea kūʻai aku ma mua.",
    pleaseWaitWhileWeGetReady: "E ʻoluʻolu e kali, ʻoiai mākou e mākaukau",
    lowStock: "Kumukū haʻahaʻa",
    pleaseEnterAmountTendered: "E ʻoluʻolu e hoʻokomo i ka nui i hāʻawi ʻia",
    areYouSureToBookSaleOffline:
      "Makemake ʻoe makemake e puke i kēia kūʻai aku nei ma kahi hoʻopahemo?",
    saleWouldBeBookedAutomatically:
      "E hoʻopaʻa inoa ʻia ke kūʻai aku ke huli ʻoe i kāu pūnaewele",
    offlineSalesBookingCancelled:
      "Ua hoʻopau ʻia ka hoʻokoe kūʻai aku ʻana ʻana ma waho pūnaewele",
    covid19Message:
      "COVID19: E holoi i kou mau lima me ke kopa a hoʻomaʻemaʻe paha e hoʻōki i ka pālahalaha. Noho palekana mau",
    saleSuccessfullyRecorded: "Kūkala kūleʻa ʻia ke kūʻai aku!",
    sendReceiptToEmail: "E hoʻouna palapala i ka leka uila",
    sendThankYouSms: "E hoʻouna mahalo iā ʻoe e SMS",
    sendShippingDetails: "E hoʻouna i nā kikoʻī hoʻouna",
    addLoyalty: "Pākuʻi Loyalty",
    searchCustomerNameOrNumber:
      "E ʻimi i ka inoa a i ʻole helu helu mea kūʻai aku",
    clickTheSearchCustomerBox:
      "Kaomi i ka pahu mea kūʻai aku huli a me ka Kāleka Scan Scan",
    enterProductPrice: "E hoʻokomo i ke kumukūʻai huahana",
    enterPriceFor: "E hoʻokomo i ke kumukūʻai no",
    searchForProduct: "Huli i ka huahana",
    all: "Pau",
    backToDashboard: "E hoʻi i ka papa kuhikuhi",
    viewDraftSales: "Nānā Kūʻai Kūʻai",
    variantSelected: "koho ʻokoʻa",
    variant: "ʻano ʻē",
    thePreviousVariantSelectionNotAvailable:
      "ʻAʻole i loaʻa ke koho ʻokoʻa ma mua no ka loli hou i koho ʻia ma muli o ke kumu kūʻai, e ʻoluʻolu e hoʻololi i kāu koho.",
    pleaseSupplyPositiveQuantityNumber:
      "E ʻoluʻolu e hāʻawi i kahi helu helu postive",
    lowStockFor: "Kumupaʻa haʻahaʻa no",
    clearVariants: "Hoʻomaopopo i nā ʻano ʻē",
    pleaseEnterQuantity: "E ʻoluʻolu e hoʻokomo i ka nui",
    picture: "Kiʻi",
    redemptionToken: "Hōʻailona Hōʻola",
    token: "Kaha",
    totalSpent: "Huina lilo",
    confirmPayment: "E hōʻoia uku",
    hasPaymentBeenMade: "Ua hoʻokō maikaʻi ʻia ka uku?",
    enterTransactionReference: "E hoʻokomo i ka kuhikuhi kūkākūkā āu i uku ai",
    pleaseSelectACustomer: "E ʻoluʻolu e koho i kahi mea kūʻai aku!",
    areYouSureToApplyDiscount: "Makemake ʻoe makemake e noi i ka uku hoʻēmi?",
    addYourBankAccountToEnableUssd:
      "E hoʻohui i kāu waihona pēke e hiki ai i ka hoʻoili Instan USSD e uPay",
    totalAmountToPay: "Huina Huina e uku",
    doYouWantToCancelTransaction: "Makemake ʻoe e hoʻopau i kēia hana?",
    savePrintBill: "Mālama / paʻi pepa",
    enterAmountCollectedForCustomer:
      "E hoʻokomo i ka nui i hōʻiliʻili ʻia no ka mea kūʻai",
    recordSale: "Kuai Kope",
    checkOutWith: "E nānā me",
    instantTransfer: "Hoʻolilo koke",
    dialTheUSSDCode: "Waiho i ka helu USSD",
    pleaseSelectABank: "E ʻoluʻolu e koho i panakō",
    payWithUSSD: "Uku Me USSD",
    sendBillTo: " - E hoʻouna iā Bila i",
    waitingForUSSDTransfer: "Ke kali nei no ka hoʻoiho USSD",
    percent: "Pālākēna",
    applyDiscount: "Pili i ka uku ho'ēmi",
    thisBillHasBeenSaved: "Ua mālama ʻia kēia pila",
    saveDraft: "Mālama Draft",
    pleaseTypeANameToIdentifyCustomer:
      "E ʻoluʻolu e kikokiko i kahi inoa e hoʻomaopopo ai i ka mea kūʻai aku",
    paymentDetails: "Nā kikoʻī uku",
    basePrice: "Kumukūʻai kumu",
    staff: "Nā limahana",
    subTotal: "Kumumanaʻo",
    durationMonths: "Duration (mau mahina)",
    selectADuration: "Koho i kahi Duration",
    oneMonth: "1 Mahina",
    twoMonths: "2 Mahina",
    threeMonths: "3 Mahina",
    sixMonths: "6 Mahina",
    twelveMonths: "12 Mahina",
    eighteenMonths: "18 Mahina",
    twentyFourMonths: "24 Mahina",
    twoMonthsFree: "(2 Makahiki Manuahi)",
    threeMonthsFree: "(3 Makahiki Manuahi)",
    fiveMonthsFree: "(5 Mahina manuahi)",
    yourAccountHasBeen: "ʻO kāu waihona",
    renewed: "hou",
    upgraded: "hōʻano hou ʻia",
    successfully: "kūleʻa",
    yourSubscription: "Kou kau inoa",
    youAreCurrentlyEnrolledOnA: "Ua kākau inoa ʻia ʻoe i kēia manawa a",
    pleaseChooseAPaymentOption: "E ʻoluʻolu koho i kahi koho uku",
    planRenewal: "Hoʻolālā Hou",
    planUpgrade: "Hoʻolālā Hoʻolālā",
    pleaseSelectDurationToPayFor:
      "E ʻoluʻolu e koho i ka lōʻihi āu e manaʻo ai e uku",
    staffAccounts: "Heluhelu limahana",
    ecommerce: "ʻO Ecommerce",
    pleaseSelectAPlan: "E ʻoluʻolu e koho i kahi hoʻolālā",
    includeAddons: "Hoʻopili i nā mea hoʻohui",
    viewTransactions: "Nānā i nā hana",
    customerHasNoCompletedTansactions:
      "ʻAʻole i pau nā mea kūʻai aku i nā mea kūʻai aku",
    branch: "Lala",
    enterNumberOfEcommerceBranches: "E hoʻokomo i ka helu o nā lālā Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "E hoʻokomo i ka helu o nā lālā Ecommerce āu e manaʻo ai e uku",
    ecommerceIntegration: "Hoʻohui Ecommerce",
    enterNumberOfBranches: "Komo i ka helu o nā lālā",
    enterNumberOfAdditionalBranchesToPay:
      "E hoʻokomo i ka helu o nā lālā hou āu e manaʻo ai e uku",
    enterNumberOfStaffs: "E hoʻokomo i ka helu o nā limahana",
    enterNumberOfStaffsToPayFor:
      "E hoʻokomo i ka helu o nā limahana āu e manaʻo ai e uku",
    discountApplies: "Pili i nā uku ho'ēmi",
    starsOnThe: "hoku ma ka",
    offer: "hāʻawi",
    get: "E kiʻi",
    moreStarsToRedeem: "hou hoku e kūʻai",
    taxVat: "Auhau (VAT)",
    callCashierToCompletePayment: "Kāhea i ka Cashier e hoʻopau i ka uku",
    receipt: "Loaa",
    dear: "Aloha",
    thankYouForYourOrderFindGoods:
      "Mahalo no kāu ʻoka. E ʻoluʻolu e ʻimi i nā ukana e hiki mai ana, e like me ka ʻaelike.",
    shippingNote: "Palapala Hoʻouna",
    enterShippingNote: "E hoʻokomo i ka memo hoʻouna",
    shippingAddress: "ʻO ka helu wahi hoʻouna",
    enterShippingAddress: "E hoʻokomo i ka leka uila",
    wellDoneYouAreDueToRedeem: "Hana maikaʻi! Pono ʻoe e kūʻai",
    toGetYourRewardNextVisit: "e kiʻi i kāu uku i kāu kipa aʻe. Mahalo",
    pointsOnThe: "Nā kiko ma ka",
    morePointsToRedeem: "nā helu hou aku e kūʻai",
    showCode: "Hōʻike pāʻālua",
    toGetYourRewardOnNextVisit: "e kiʻi i kāu uku i kāu kipa aʻe. Mahalo",
    earn: "Loaʻa",
    delivaryNote: "Kaha Delivary",
    draftSales: "Kūʻai Kūʻai",
    startDate: "Lā hoʻomaka",
    endDate: "Lā hoʻopau",
    orders: "Kauoha",
    checkout: "huli aku",
    noProductItem: "ʻAʻohe huahana huahana",
    selectProductImage: "Koho i ke kiʻi huahana",
    selectCountry: "Aupuni koho",
    selectRegion: "Koho Mokuʻāina / ʻĀpana",
    printProductTag: "Palapala Huahana Print",
    transactionReference: "Ke kūmole hana",
    Cashier: "Kānāwai kālā",
    Manager: "Luna Hoʻokele",
    Owner: "Onaona",
    Admin: "Luna Hoʻokele",
    addPartners: "Hoʻohui i nā hoa",
    addNewLoyaltyPartner: "Hoʻohui hou i ka Hoa Loyalty",
    pleaseEnterCompanyName: "E ʻoluʻolu e hoʻokomo i ka inoa ʻoihana",
    companyName: "Inoa Hui",
    pleaseEnterCompanyRepName: "E ʻoluʻolu e hoʻokomo i ka inoa luna ʻoihana",
    companyRepName: "Ka inoa o ka Lunamakaainana Hui",
    pleaseEnterCompanyRepEmail:
      "E ʻoluʻolu e hoʻokomo i ka leka uila o ka luna ʻoihana",
    companyRepEmail: "Leka uila o ka hui rep",
    pleaseEnterCompanyRepPhone:
      "E ʻoluʻolu e hoʻokomo i ka helu kelepona o ka luna ʻoihana",
    companyRepPhone: "Helu kelepona o ka rep",
    addReward: "Hoʻohui i ka uku",
    pleaseEnterRewardName: "E ʻoluʻolu e hoʻokomo i ka inoa uku",
    rewardName: "Ka inoa makana",
    rewardQuantity: "Ka nui o ka uku",
    rewardDescription: "ʻO ka wehewehe ʻana i ka uku",
    rewardType: "ʻAno uku",
    pleaseEnterRewardType: "E ʻoluʻolu e hoʻokomo i ke ʻano uku",
    pleaseEnterRewardQuantity: "E ʻoluʻolu e hoʻokomo i ka nui o ka uku",
    pleaseEnterRewardDescription: "E ʻoluʻolu e hoʻokomo i ka wehewehena uku",
    fineDining: "ʻAi ʻAina",
    luxuryFashion: "ʻAi Kūʻē",
    hotels: "Hotele",
    travel: "Kaahele",
    foodAndBeverage: "Meaʻai a me ka mea inu",
    fashion: "Kaulana",
    health: "Ola kino",
    furniture: "Lako hale",
    entertainment: "Hoonanea",
    automobile: "kaʻa kaʻa",
    education: "Hoʻonaʻauao",
    beautyAndSpa: "Nani a me Spa",
    staycation: "Noho noho",
    events: "Nā hanana",
    trips: "Nā huakaʻi",
    oilAndGas: "Aila a me Gas",
    laundry: "Holoi holoi",
    partnerCategory: "Māhele Hoa",
    freeItem: "ʻImi manuahi",
  },
  Hmong: {
    cashier: "tus neeg khaws nyiaj",
    manager: "tus thawj coj",
    owner: "tus tswv",
    online: "online",
    offline: "tsis tau",
    changePassword: "Hloov Password",
    currentPasswordMessage: "Thov sau koj tus password tam sim no",
    passwordMessage: "Thov sau koj tus password",
    currentPassword: "Lo lus zais tam sim no",
    password: "lo lus zais",
    confirmPasswordMessage: "Thov paub meej tias koj tus password!",
    confirmPassword: "Paub meej Password",
    sendViaEmail: "Xa los ntawm Email",
    sendViaWhatsapp: "Xa los ntawm WhatsApp",
    downloadPdf: "Rub tawm PDF",
    paid: "them nyiaj",
    unpaid: "tsis them nyiaj",
    partial: "ib nrab",
    closeInvoice: "Koj puas xav kaw Daim Ntawv Them Nqi?",
    closeInvoiceConfirmation:
      "Cov ntawv xa nyiaj yuav tsis raug cawm. Koj puas xav kaw?",
    yes: "yog",
    no: "tsis muaj",
    invoice: "Xa ntawv",
    wasDeducted: "tau txiav tawm",
    for: "rau",
    item: "Yam khoom",
    addProduct: "Ntxiv Khoom",
    paymentReference: "Kev Them Nqi",
    amountPaid: "Tus Nqi Them",
    discountAmount: "Nyiaj Txiag",
    amountDue: "Tus Nqi Them",
    amount: "Tus nqi",
    dueDate: "Hnub Tim",
    paymentType: "Hom Kev Them Nqi",
    card: "Daim npav",
    cash: "Nyiaj ntsuab",
    bankTransfer: "Txhab Nyiaj Txiag",
    paymentMessage: "Cov Nyiaj Them Nqi",
    description: "Nqe lus piav qhia",
    sendReceipt: "Xa Ntawv Xa Ntawv",
    delete: "Rho tawm",
    save: "Txuag",
    resend: "Rov Xa",
    saveAndSend: "Txuag",
    invoiceSaved: "Cov nyiaj tau txais kev cawmdim!",
    selectPaymentMethod: "Thov xaiv Txoj Kev Them Nqi!",
    selectCustomer: "Thov xaiv Cov Neeg Siv Khoom!",
    cartEmptyError:
      "Daim Ntawv Teev Npe tsis tuaj yeem khoob, kaw daim ntawv xa nyiaj thiab ntxiv Cov Khoom rau lub laub!",
    subscriptionExpired:
      "Koj qhov kev tso npe tau tas sijhawm thiab koj tus lej nyiaj tam sim no txwv. Nyem rau ntawm lub pob hauv qab no txhawm rau txuas ntxiv koj tus account",
    renew: "Rov Ntxiv Tshiab",
    holdOn: "Tuav",
    customerBank: "Cov Neeg Siv Nyiaj Txiag",
    cancel: "Ncua tseg",
    selectACustomer: "Xaiv Neeg",
    invoiceSuccessfulPdfError:
      "Cov ntawv xa nyiaj tau ua tiav tab sis PDF Generation tau siv sijhawm ntev dua li ib txwm. Thov rov qab los sai sai.",
    downloadingInvoice: "Rub tawm daim ntawv them nqi",
    downloadingReceipt: "Rub tawm daim ntawv txais nyiaj",
    whatsappReceiptError:
      "Ib qho yuam kev tshwm sim xa daim ntawv txais nyiaj ntawm WhatsApp, Thov sim dua.",
    receiptToWhatsapp: "Cov ntawv xa mus rau WhatsApp",
    thankYouForPatronage: "Ua tsaug rau koj kev txhawb nqa",
    hereIsYourReceipt: "Nov yog koj daim ntawv them nyiaj",
    errorSendingEmailReceipt:
      "Ib qho yuam kev tshwm sim xa daim ntawv txais nyiaj ntawm e-mail, thov sim dua lossis hu rau kev txhawb nqa",
    receiptSentToEmail:
      "Daim ntawv txais nyiaj tau xa mus rau tus neeg siv khoom email",
    invoiceSentToEmail:
      "Cov ntawv xa nyiaj tau xa mus rau tus neeg siv khoom email",
    invoiceSuccessWhatsappError:
      "Cov ntawv xa nyiaj tau ua tiav tab sis muaj qhov yuam kev tshwm sim xa mus rau WhatsApp. Sim dua ntawm daim ntawv teev npe nyiaj",
    invoiceSuccessfulEmailError:
      "Invoice tsim tau tiav tab sis muaj qhov yuam kev tshwm sim thaum xa raws li e-mail. Thov sim dua los ntawm daim ntawv teev npe nyiaj",
    invoiceSentToWhatsapp: "Cov ntawv thov xa mus rau WhatsApp",
    hello: "Nyob zoo",
    pleaseDownloadInvoice: "Thov rub daim ntawv sau nqi",
    pleaseDownloadReceipt: "Thov download tau daim ntawv txais nyiaj",
    from: "los ntawm",
    email: "Email",
    upgrade: "Txhim kho",
    youAreOnFreePlan: "Koj nyob ntawm Txoj Kev Npaj Dawb.",
    clickOn: "Nyem rau",
    yourPlanInFewSteps: " koj daim ntawv xaj hauv ob peb kauj ruam nrawm.",
    to: "rau",
    yourSubscriptionHasExpired:
      "Koj qhov kev tso npe tau tas sijhawm thiab koj tus lej nyiaj tam sim no txwv.",
    days: "hnub",
    yourSubscriptionExpiresIn: "Koj qhov Loystar kev tso npe yuav tas sijhawm",
    createAcount: "Tsim tus Account",
    signIn: "Sau Npe",
    continue: "Txuas ntxiv",
    enterOtp: "Sau tus lej OTP",
    enterValidOtp: "Thov sau tus lej PIN siv tau",
    pin: "PIN",
    tokenSentToMail: "Ib tus cim tau raug xa mus rau koj tus email",
    passwordResetSuccessful: "Rov pib dua tus password tau ua tiav",
    somethingWentWrong: "Muaj qee yam tsis raug!",
    resetPassword: "Pib dua Password",
    iHaveResetCode: "Kuv muaj tus password rov pib dua tus lej",
    pleaseEnterEmail: "Thov sau koj tus email",
    aTokenWillBeSentToEmail: "Ib tus cim yuav raug xa mus rau koj tus email",
    enterEmail: "Sau koj tus email",
    sendinYourToken: "Xa koj token ...",
    makeSureItMatchesPassword: "Xyuas kom nws phim koj tus password tshiab",
    pleaseChooseNewPassword: "Thov xaiv tus password tshiab",
    chooseNewPassword: "Xaiv tus password tshiab",
    enterNewPassword: "Sau koj tus password tshiab kom paub meej",
    enterTokenSent: "Nkag mus rau token uas tau xa koj li ntawv",
    resetToken: "Pib dua Token",
    resettingPassword: "Pib dua koj tus Password ...",
    signUp: "Sau Npe",
    adminSignInWithEmail:
      " Admin kos npe nrog email thaum Cov Neeg Ua Haujlwm kos npe nrog tus neeg siv.",
    pleaseEnterEmailOrUsername: "Thov sau koj tus email lossis lub npe siv",
    emailOrUsername: "Email lossis Username",
    pleaseEnterPassword: "Thov sau tus password",
    createAnAccount: "Tsim tus Account",
    forgotPassword: "Tsis nco qab lo lus zais?",
    enterPhoneNumber: "Sau Tus Xov Tooj",
    personalData: "Cov Ntaub Ntawv Tus Kheej",
    validateConfirmationCOde: "Txheeb Xyuas Txoj Cai Pom Zoo",
    pleaseEnterFirstName: "Thov sau koj lub npe",
    pleaseEnterPhoneNumber: "Thov sau koj tus xov tooj",
    pleaseEnterLastName: "Thov sau koj lub xeem",
    pleaseEnterBusinessName: "Thov sau koj lub npe lag luam",
    firstName: "Lub Npe",
    lastName: "Lub Xeem",
    businessName: "Lub Npe Lag Luam",
    previous: "Yav dhau los",
    next: "Tom ntej no",
    pleaseSelectBusinessCategory: "Thov xaiv koj pawg lag luam",
    pleaseEnterValidEmail: "Thov sau email uas siv tau",
    pleaseEnterPostCode: "Thov sau tus lej xa ntawv",
    postCode: "Post Code",
    phoneNumberInUse: "Tus xov tooj no tau siv lawm!",
    emailInUse: "Tus email no tau siv lawm!",
    foodAndDrinks: "Khoom noj thiab dej haus",
    salonAndBeauty: "Salon thiab Kev Zoo Nkauj",
    fashionAndAccessories: "Zam thiab Accessories",
    gymAndFitness: "Qoj thiab Qoj",
    travelAndHotel: "Mus Ncig thiab Tsev So",
    homeAndGifts: "Tsev thiab Khoom Plig",
    washingAndCleaning: "Ntxuav thiab Ntxuav",
    gadgetsAndElectronics: "Gadgets thiab Electronics",
    groceries: "Khoom noj khoom haus",
    others: "Lwm tus",
    submit: "Xa",
    accountCreatedSuccessful: "Koj tus account tau tsim tiav.",
    pleaseEnterAddress: "Thov Sau Koj Qhov Chaw Nyob",
    addressLine1: "Chaw Nyob Kab 1",
    addressLine2: "Chaw Nyob Kab 2",
    choosePassword: "Xaiv Password",
    passwordMustBe6Characters:
      "Lo lus zais yuav tsum muaj tsawg kawg 6 tus cim.",
    howDidYouHearLoystar: "Koj tau hnov txog Loystar li cas?",
    referralCode: "Txoj Cai xa mus",
    byClickingTheButton:
      " Los ntawm kev nyem lub pob hauv qab no, koj pom zoo rau lub",
    termsAndSevice: "Cov ntsiab lus",
    wereCreatingAccount: "Peb tab tom tsim koj tus account",
    proceed: "Ua mus ntxiv",
    verificationCodeMustBe6:
      "Kev Txheeb Xyuas Txoj Cai yuav tsum yog 6 tus lej",
    pleaseEnter6DigitCode: "Thov sau 6 tus lej lej",
    enterVerificationCode: "Nkag Pov Thawj Txoj Cai",
    resendToken: "Rov qab Token",
    verify: "Pom tseeb",
    transactions: "Kev ua lag luam",
    todaySales: "Kev Muag Khoom Hnub no",
    salesHistory: "Keeb Kwm Muag",
    supplyHistory: "Keeb Kwm Khoom",
    new: "Tshiab",
    invoices: "Cov ntawv sau nyiaj",
    disbursements: "Kev them nyiaj",
    offlineSales: "Muag Tsis Tau",
    products: "Khoom",
    customers: "Cov neeg siv khoom",
    multiLevelLoyalty: "Multi-Level Loyalty",
    loyaltyPrograms: "Txoj Haujlwm Loyalty",
    members: "Cov tswv cuab",
    appStore: "App Khw",
    orderMenu: "Xaj ntawv qhia zaub mov",
    settings: "Chaw",
    staffAndBranches: "Cov neeg ua haujlwm thiab cov ceg ntoo",
    myAccount: "Kuv Tus Account",
    switchToSellMode: "Hloov mus rau Muag Hom",
    signOut: "Sau Npe Tawm",
    getFreeSubscription: "Tau Txais Kev Sau Npe Dawb",
    onlyNumbersAllowed: "Tsuas yog tus lej raug tso cai",
    yourAccountMustBe10Digits: "koj tus lej account yuav tsum yog 10 tus lej",
    yourBvnMustBe11: "koj BVN yuav tsum yog 11 tus lej",
    pleaseSelectBank: "Thov xaiv koj lub txhab nyiaj",
    selectYourBank: "Xaiv koj lub txhab nyiaj",
    enterBankAccountNumber: "Sau tus lej nyiaj hauv tuam txhab",
    enterBvnNumber: "Sau koj li BVN",
    connectBankAccount: "Txuas Bank Account",
    passwordResetSuccessfulAndSignOut:
      "Koj tus Password tau rov pib ua tiav. Nyem rau ntawm Sign Out khawm hauv qab no kom rov nkag tau dua",
    enterCurrentPassword: "Sau tus password tam sim no",
    pleaseEnterCurrentPassword: "Thov sau tus password tam sim no",
    phoneNumber: "Tus Xov Tooj",
    sex: "Sib deev",
    dateOfBirth: "Hnub Yug",
    state: "Xeev",
    country: "Lub teb chaws",
    loyaltyId: "Loyalty ID",
    createdAt: "Tsim Los Ntawm",
    noOfTransactionsMade: "Tsis muaj kev pauv pauv",
    yourDownloadWillStart: "Koj rub tawm yuav pib hauv ib pliag",
    exportCustomers: "Export Cov Neeg Siv Khoom",
    youhaveSuccessfullyToppedUp:
      "Koj tau ua tiav tau zoo tshaj koj li SMS Chav.",
    youNowHave: "Koj tam sim no muaj",
    smsUnits: "Chav nyob SMS",
    enterNumberOfSmsUnits: "Sau Tus lej ntawm Cov Chav SMS Koj Xav Xav Yuav",
    pleaseEnterANumericValue: "Thov sau tus lej lej",
    pay: "Them",
    accountEditedSuccessfully: "Koj tus as khauj raug kho tiav.",
    youAeCurrentlyOn: "Tam sim no koj nyob ntawm",
    plan: "Npaj",
    userData: "Cov Ntaub Ntawv Siv",
    businessData: "Cov ntaub ntawv ua lag luam",
    businessCategory: "Catergory lag luam",
    pleaseSelectCurrency: "Thov xaiv koj cov txiaj",
    selectYourCurrency: "Xaiv koj cov txiaj",
    purchaseMoreSmsUnits: "Yuav ntau lub Chav SMS siv daim foos hauv qab no",
    youHave: "Koj muaj",
    atLeast4SmsUnitsRrequired:
      "Tsawg kawg 4 chav sms xav tau rau kev txheeb xyuas, thov sau nyiaj!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Thov txheeb xyuas koj tus as -qhauj hauv txhab nyiaj kom ua raws li 'Paub Koj Tus Neeg Siv Khoom' (KYC) xav tau. Qhov no yuav tso cai rau koj sau cov nyiaj them los ntawm USSD lossis hloov pauv sai, tau txais kev xaj los ntawm cov neeg siv khoom thiab ua cov txheej txheem sib hais haum. Muaj feem xyuam rau Nigerian cov tub lag luam nkaus xwb. Thov nias lub pob hauv qab no kom pib.",
    reConnectBankAccount: "Rov txuas tus lej Nyiaj Txiag",
    accountName: "Npe Npe",
    accountNumber: "Tus lej naj npawb",
    bankName: "Npe Npe",
    verified: "Pom tseeb",
    accountDetails: "Cov Nyiaj Txiag",
    kycBankAccount: "KYC",
    createTier: "Tsim Tier",
    fileUploadSuccessful: "cov ntaub ntawv uploaded tiav",
    fileUploadFailed: "cov ntaub ntawv upload ua tsis tau tejyam",
    createLoyaltyProgram: "Tsim Txoj Haujlwm Loyalty",
    createLoyalty: "Tsim Loyalty",
    name: "Lub Npe",
    loyaltyArtwork: "Loyalty Artwork",
    clickToUpload: "Nyem rau upload",
    amountToPointsRatio: "Tus Nqi Rau Cov Ntsiab Lus",
    points: "Cov ntsiab lus",
    recommendedAmountToPointRatio:
      "Pom zoo: ₦ 1 txog 1 kis. piv txwv li rau txhua 1 Naira siv, koj cov neeg siv khoom tau txais 1 point",
    pleaseTypeIn: "Thov ntaus",
    toDeleteLoyalty: "kom tshem tawm qhov kev ncaj ncees no",
    deleteLoyalty: "Rho tawm Loyalty",
    toConfirm: "kom paub meej",
    edit: "Kho kom raug",
    pointsAwardedSuccessfully: "Cov Ntsiab Lus Tau Txais Ua tiav.",
    enterPointValueToAward:
      "Nkag mus rau Tus Nqi Tus Nqi Koj Xav Muab Rau Cov Neeg Siv Khoom",
    award: "Khoom plig",
    awardPointValuesToCustomer: "Khoom Plig Tus Nqi Rau Cov Neeg Siv Khoom",
    enrollMembersToLoyalty: "Sau npe Cov tswv cuab rau Loyalty",
    awardPoints: "Cov Ntsiab Lus Ntsiab Lus",
    enroll: "Sau npe",
    home: "Tsev",
    loyalty: "Siab ncaj",
    enrollCustomers: "Sau npe Cov Neeg Siv Khoom",
    selected: "Xaiv",
    customer: "Neeg",
    loyaltyActivationSuccessful: "Loyalty Activation tau ua tiav.",
    loyaltyDeactivationSuccessful: "Loyalty Deactivation ua tiav.",
    viewTier: "Saib Tier",
    deactivate: "De-Qhib",
    activate: "Qhib",
    actions: "Kev ua",
    nameOfLoyalty: "Lub Npe Loyalty",
    loyaltyStatus: "Cov Loyalty Status",
    numberOfTiers: "Cov Tier",
    createdOn: "Tsim Nyob",
    createATier: "Tsim Tier",
    stopCreatingTierConfirmation: "Koj puas xav tso tseg kev tsim qib?",
    stopEditingTierConfirmation: "Koj puas xav kom tsis txhob kho qhov qib no?",
    nameOfTier: "Lub npe Tier",
    minimumSpendingTarget: "Lub Hom Phiaj Tsawg kawg",
    maximumSpendingTarget: "Lub Hom Phiaj Ntau Tshaj",
    minimumSpendingTargetRequired: "hom phiaj siv nyiaj tsawg kawg yog xav tau",
    maximumSpendingTargetRequired:
      "lub hom phiaj siv nyiaj ntau tshaj yog xav tau",
    rewardSponsor: "Tus Txhawb Nqa",
    pleaseChooseARewardSponsor: "Thov xaiv tus txhawb nqa khoom plig",
    self: "Tus kheej",
    partner: "Tus khub",
    rewardPartner: "Tus Phooj Ywg Tus Phooj Ywg",
    pleaseSelectRewardPartner: "Thov xaiv koj tus khub nqi zog",
    rewards: "Khoom plig",
    pleaseSelectAReward: "Thov xaiv qhov khoom plig",
    instructionsOnRedeemingReward:
      "Cov lus qhia Yuav Ua Li Cas Cov Neeg Siv Khoom Yuav Tsum Tau Txais Khoom Plig",
    pleaseFillInThisField: "Thov sau rau daim teb no!",
    toDeleteThisTier: " kom rho tawm qib no",
    deleteTier: "Rho tawm Tier",
    viewMembers: "Saib Cov Tswv Cuab",
    membersEnrolled: "Cov tswv cuab tau cuv npe",
    instruction: "Qhia",
    membersIn: "Cov tswv cuab hauv",
    availableTiersInLoyalty: "Muaj Tier (s) hauv Txoj Haujlwm Loyalty",
    tiers: "Tier",
    totalTier: "ZOO TSHAJ PLAWS",
    availableLoyaltyProgramme: "Muaj Txoj Haujlwm Loyalty",
    totalLoyalties: "ZOO TSHAJ LOYALTIES",
    memberDetails: "Cov Tswv Yim Paub",
    nameOfCustomer: "Lub Npe Ntawm Cov Neeg Siv Khoom",
    address: "Chaw nyob",
    allEnrolledMembers: "Txhua Tus Neeg Koom Npe",
    thisIsToWishYouHappyBirthday:
      "Qhov no yog xav kom koj muaj kev zoo siab hnub yug thiab lub neej vam meej. Ua tsaug rau txhua yam uas koj yog Loyster. Zoo Siab Ua Ke!",
    inputAnOfferPlease: "Thov muab tswv yim",
    pleaseSelectTheInsertPoint:
      "Thov xaiv lub ntsiab lus ntxig rau hauv cov lus thiab nyem dua",
    birthdayOfferAndMessage: "Hnub Yug Muab thiab Xov Xwm",
    birthdayOffer: "Hnub Yug Muab",
    birthdayMessage: "Xov Xwm Hnub Yug",
    noOfferFound: "Tsis pom kev pom",
    settingABirthdayOffer:
      "Kev teeb tsa hnub yug pub rau cov neeg siv khoom tau txais qhov kev thov no los ntawm SMS ntawm lawv lub hnub yug",
    createOffer: "Tsim Kev",
    whatIsTheOffer: "Qhov kev thov yog dab tsi?",
    editMessage: "Kho lus",
    insert: "Ntxig",
    theNameOfCustomerInserted:
      "Lub npe ntawm tus neeg yuav khoom yuav muab tso rau ntawm no",
    theBirtdayOfferInserted: "Cov hnub yug yuav muab tso rau ntawm no",
    youSuccessfullyAddedNewBranch: "Koj tau ua tiav ntxiv ib ceg tshiab!",
    addNewBranch: "Ntxiv New Branch",
    addBranch: "Ntxiv ceg",
    additionalBranchWillIncur: "Lwm ceg yuav tshwm sim",
    perBranch: "ib ceg",
    ecommerceBranchCosts: "Ecommerce ceg nqi",
    pleaseEnterBranchName: "Thov sau lub npe ceg",
    pleaseEnterBranchAddress1: "Thov nkag mus rau ceg kab lus chaw nyob kab 1",
    enterBranchAddress1: "Nkag mus rau ceg kab lus chaw nyob kab 1",
    enterBranchAddress2: "Nkag mus rau ceg kab lus chaw nyob kab 1",
    pleaseEnterBranchAddress2: "Thov nkag mus rau ceg kab lus chaw nyob kab 2",
    enterBranchName: "Sau lub npe ceg",
    successfullyAddedStaff: "Koj tau ua tiav ntxiv cov neeg ua haujlwm tshiab!",
    addNewStaff: "Ntxiv Cov Neeg Ua Haujlwm Tshiab",
    addStaff: "Ntxiv Cov Neeg Ua Haujlwm",
    additionalStaffWillIncur: "Cov Neeg Ua Haujlwm Ntxiv yuav raug",
    perStaff: "ib tus neeg ua haujlwm.",
    pleaseEnterStaffEmail: "Thov sau tus neeg ua haujlwm email",
    pleaseEnterStaffUsername: "Thov sau tus neeg ua haujlwm lub npe siv",
    pleaseEnterStaffPassword: "Thov sau tus neeg ua haujlwm tus password",
    pleaseSelectStaffRole: "Thov xaiv tus neeg ua haujlwm lub luag haujlwm",
    selectStaffRole: "Xaiv cov neeg ua haujlwm lub luag haujlwm",
    enterStaffEmail: "Sau tus neeg ua haujlwm tus email",
    enterStaffUsername: "Sau tus neeg ua haujlwm lub npe siv",
    enterStaffPassword: "Nkag mus rau tus neeg ua haujlwm tus password",
    spacesNotAllowedInUsername:
      "tsis pub muaj chaw nyob hauv tus neeg siv lub npe",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Thov xaiv kev lag luam los txuas cov neeg ua haujlwm",
    searchForBranchToAttachStaff:
      "Tshawb nrhiav ceg kom xa cov neeg ua haujlwm",
    username: "Tus neeg siv lub npe",
    role: "Lub luag haujlwm",
    areYouSureToDeleteThis: "Koj puas paub tshem tawm qhov no",
    branches: "Ceg ntoo",
    upgradeYourPlan: "Txhim kho koj li phiaj xwm.",
    add: "ADD",
    addNew: "Ntxiv Tshiab",
    customerWithEmailAlreadyExists:
      "Cov neeg siv nrog email/tus lej xov tooj twb muaj lawm!",
    successfullyAddedNewCustomer:
      "Koj tau ua tiav ntxiv cov neeg siv khoom tshiab!",
    addCustomer: "Ntxiv Neeg",
    pleaseEnterCustomerFirstName: "Thov sau tus neeg siv khoom lub npe",
    pleaseEnterCustomerLastName: "Thov sau tus neeg siv lub xeem",
    pleaseEnterCustomerPhoneNumber: "Thov sau tus neeg siv tus lej xov tooj",
    pleaseEnterCustomerEmail: "Thov sau tus neeg siv email",
    pleaseEnterCustomerAddressLine:
      "Thov nkag mus rau tus neeg siv Cov Chaw Nyob Kab",
    pleaseEnterCustomerOtherAddress:
      "Thov nkag mus rau tus neeg siv lwm qhov chaw nyob",
    pleaseSelectCustomerGender: "Thov xaiv cov neeg siv khoom sib deev",
    gender: "Tub los ntxhais",
    male: "Txiv neej",
    female: "Poj niam",
    bank: "Txhab nyiaj",
    selectBank: "Xaiv Bank",
    stateOrRegionOrProvince: "Xeev/Cheeb Tsam/Xeev",
    customerNotes: "Cov Neeg Sau Ntawv",
    sendSms: "Xa SMS",
    editCustomer: "Kho Neeg",
    redeemReward: "Txhiv Tau Khoom Plig",
    issueLoyaltyCard: "Tshaj Loyalty Card",
    deleteCustomer: "Rho tawm Cov Neeg Siv Khoom",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Koj tau ua tiav Txoj Cai Loyalty Membership ID",
    noMembershipIdAvailable:
      "Tsis muaj tus lej ID ua tswv cuab. Thov hu rau peb ntawm hello@loystar.co",
    sendEmail: "Xa Email",
    membershipPoints: "Cov ntsiab lus ua tswv cuab",
    customerDetails: "Neeg Paub meej",
    close: "Kaw",
    loyaltyBalance: "Loyalty Tshuav",
    pointsBalance: "Cov Ntsiab Lus Tshuav",
    starBalance: "Lub Hnub Qub Tshuav",
    requiredPoints: "Cov Ntsiab Lus Xav Tau",
    requiredStars: "Cov Hnub Qub Yuav Tsum Tau",
    reddemCode: "Txhiv Code",
    toDeleteThisCustomer: "kom tshem tawm cov neeg siv khoom no",
    customerHasBeenDeletedSuccessfully:
      "Cov neeg siv khoom tau raug tshem tawm ua tiav!",
    customerWithPhoneAlreadyExists:
      "Cov neeg siv nrog tus lej xov tooj twb muaj lawm",
    customerWasSuccessfullyEdited: "Cov neeg siv khoom tau ua tiav kho",
    anErrorOccured: "Ib qho yuam kev tshwm sim",
    phoneNumberIncludeCountry: "Tus Xov Tooj (Xws Li Txoj Cai Hauv Tebchaws)",
    yourFileQueuedForUpload:
      "Koj cov ntaub ntawv tau nyob ua ke rau upload. Thov rov kho nplooj ntawv tom qab ob peb feeb.",
    thereWasAnErrorPerformingOperation: "Muaj qhov ua yuam kev ua haujlwm!",
    pleaseSelectFile: "Thov Xaiv Cov Ntaub Ntawv!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Tsuas yog cov ntawv CSV tso cai. Thov upload ib daim ntawv .csv.",
    fileShouldBeSmallerThan5Mb:
      "Cov ntawv yuav tsum me dua 5MB. Yog tias koj muaj cov ntawv loj dua, thov email support@loystar.co. Ua tsaug",
    customerFirstNameIsRequired:
      "cov neeg siv khoom lub npe yuav tsum tau ua ntawm kab",
    customerPhoneNumberIsRequired:
      "tus neeg siv tus lej xov tooj xav tau ntawm kab",
    importCustomers: "Ntshuam Cov Neeg Siv Khoom",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Nyem rau lub cim no txhawm rau rub tawm daim ntawv CSV.",
    getGoogleSheetVersion: "Tau txais daim ntawv Google version",
    clickOrDragFileToUpload:
      "Nyem lossis rub cov ntawv rau thaj tsam no txhawm rau rub tawm",
    missingOrInvalidColumnHeader:
      "Tsis muaj los yog siv tsis tau lub taub hau kab. Thov ua raws tus qauv. Ua tsaug.",
    youHaveImported: "Koj Tau Txais",
    youSuccessfullyRedeemedReward: "Koj tau ua tiav kev txhiv koj cov nqi zog!",
    sixDigitCode: "Rau tus lej lej",
    pleaseEnterCustomerRewardCode:
      "Thov sau tus neeg siv khoom tus lej khoom plig",
    enterRewardCode: "Sau tus lej khoom plig.",
    selectLoyaltyProgram: "Xaiv txoj haujlwm ncaj ncees",
    stamps: "Nyiaj muas",
    smsUnitsLow: "SMS Chav Qis",
    pleaseTopUpSmsUnits: "Thov Top Up SMS Chav",
    smsSuccessfullySentForDelivery: "SMS tau xa tiav rau kev xa khoom!",
    sendSmsTo: "Xa SMS rau",
    allCustomers: "Txhua Tus Neeg Siv Khoom",
    unitsAvailable: "Chav Nyob Muaj",
    pleaseTypeInTheMessage: "Thov ntaus ntawv",
    message: "Xov",
    charactersRemaining: "cim ntxiv",
    avoidUseOfSpecialCharacters:
      "Zam kev siv Tus Cwj Pwm Tshwj Xeeb thiab Emojis txhawm rau txuag SMS Chav.",
    note: "Nco tseg",
    errorFetchingCustomersMultilevelDetail:
      "Yuam Kev Fetching Cov Neeg Siv Khoom MultiLevel Paub meej",
    search: "Nrhiav",
    reset: "Pib dua",
    importCustomer: "Ntshuam Neeg",
    addNewCustomer: "Ntxiv Neeg Tshiab",
    sendSmsBroadcast: "Xa SMS Tshaj Tawm",
    totalCustomers: "ZOO TSHAJ PLAWS",
    disbursementDetails: "Cov Nyiaj Them Nqi",
    paidBy: "Them Los Ntawm",
    disbursed: "Them nyiaj",
    bankAccountName: "Lub Tuam Txhab Npe Npe",
    bankAccountNumber: "Tus lej Nyiaj Txiag",
    transferInitiated: "Pib Pib",
    transferCompleted: "Hloov Tiav lawm",
    pleaseEnterAValid: "Thov sau qhov siv tau",
    begin: "pib",
    end: "kawg",
    date: "hnub tim",
    paymentDate: "Hnub Them Nyiaj",
    selectDisbursementDuration: "Xaiv Lub Sijhawm Them Nyiaj",
    totalSettled: "Tag Nrho Kev Txiav Txim Siab",
    totalUnsettled: "Tag Nrho Tsis Txaus Siab",
    toDeleteThisSale: "kom tshem tawm qhov muag no",
    draftSaleDeletedSuccessfully: "Draft Sale tau raug tshem tawm ua tiav!",
    deleteSale: "Rho tawm Muag",
    pleaseTypeInYourTotal:
      "Thov ntaus koj tus lej tag nrho kom paub meej tias tshem tawm",
    billDetails: "Nqe Lus Qhia",
    printBill: "Sau Bill",
    servedBy: "Pab los ntawm",
    total: "Tag nrho",
    createdDate: "Tsim Hnub",
    createdTime: "Tsim Sijhawm",
    status: "Xwm txheej",
    loginSuccessful: "Nkag mus tau zoo",
    pleaseWaitWhileWeConnectAccount:
      "Thov tos thaum peb txuas koj tus lej nyiaj",
    connectionFailedTryAgain: "Kev sib txuas tsis tau Thov sim dua.",
    connectionSuccessful: "Kev Sib Txuas Zoo",
    viewDetails: "Saib Cov Lus Qhia",
    enable: "Pab kom",
    free: "Pub dawb",
    cost: "Nqi",
    visitWebsite: "Mus ntsib Lub Vev Xaib",
    pleaseUpgradeYourPlanToPro:
      "Thov Txhim Kho koj li phiaj xwm rau Pro Plus kom pab tau lub app no",
    connectYourBankAccount:
      "Txuas koj tus lej Nyiaj Txiag kom tuaj yeem tau txais kev them nyiaj.",
    disable: "Lov tes taw",
    enableApp: "Qhib App",
    addNewProductToInvoice: "Ntxiv Cov Khoom Tshiab rau Invoice",
    toDeleteThisInvoice: "kom tshem tawm Daim Ntawv Pom Zoo no",
    invoiceDeletedSuccessfully:
      "Cov ntawv xa nyiaj tau raug tshem tawm ua tiav!",
    deleteInvoice: "Rho Tshem Tawm",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Koj tau ua tiav kev ntxiv Invoice cov lus them nyiaj!",
    pleaseEnterPaymentInstructions: "Thov sau cov lus qhia them nyiaj",
    invoiceId: "Daim Npav ID",
    paidDate: "Hnub Them Nyiaj",
    reference: "Siv",
    productAdded: "Khoom ntxiv",
    productOutOfStock: "Khoom tawm ntawm Tshuag. Thov rov ntim khoom dua.",
    totalInvoices: "ZOO TSHAJ PLAWS",
    totalPaidInvoices: "ZOO TSHAJ PLAWS YUAV TSHUAJ",
    totalUnpaidInvoices: "ZOO TSHAJ PLAWS ZOO TSHAJ PLAWS",
    loyaltyProgramDeleted: "Loyalty Program Rho Tawm",
    thisLoyaltyProgramDeletedSuccessfully:
      "Txoj haujlwm ncaj ncees no tau raug tshem tawm ua tiav",
    thisLoyaltyProgramEditedSuccessfully:
      "Txoj haujlwm ncaj ncees no tau kho tiav",
    loyaltyProgramAddedSuccessfully: "Qhov kev ncaj ncees tau ntxiv ua tiav",
    loyaltyProgramEdited: "Loyalty Program Edited",
    loyaltyProgramAdded: "Txoj Haujlwm Loyalty Ntxiv",
    loyaltyDetails: "Loyalty Paub meej",
    doYouWantToCloseDialog: "Koj puas xav kaw qhov kev sib tham no?",
    pleaseEnterLoyaltyName: "Thov sau lub npe ntawm koj kev ncaj ncees",
    programType: "Hom Kev Pabcuam",
    pleaseSelectProgramType: "Thov xaiv hom haujlwm",
    simplePoints: "Cov Ntsiab Lus Yooj Yim",
    stampsProgram: "Nyiaj Muas Noj",
    threshold: "Qhov pib",
    pleaseEnterLoyaltyThreshold:
      "Thov nkag mus rau qhov pib qhov kev ncaj ncees",
    reward: "Khoom plig",
    pleaseEnterLoyaltyReward: "Thov sau qhov khoom plig ntawm kev ncaj ncees",
    totalUserPoints: "Cov Neeg Siv Cov Ntsiab Lus",
    totalUserStamps: "Tag Nrho Cov Neeg Siv Nyiaj",
    spendingTarget: "Lub hom phiaj siv",
    spendingTargetHint1:
      "Lub hom phiaj siv yog ntau npaum li cas cov neeg siv khoom yuav tsum siv kom tau txais khoom plig. 1 txiaj txiaj = 1 kis.",
    spendingTargetHint2:
      "Lub hom phiaj nyiaj muas yog pes tsawg tus neeg muas zaub yuav tsum sau kom tau txais khoom plig. np. 5",
    addNewLoyaltyProgram: "Ntxiv Txoj Haujlwm Loyalty Tshiab",
    addLoyaltyProgram: "Ntxiv Loyalty Program",
    loyaltyProgramType: "Hom Loyalty Program Hom",
    pleaseSelectLoyaltyProgramType: "Thov xaiv hom phiaj kev ncaj ncees",
    nameOfProgram: "Lub npe ntawm txoj haujlwm",
    pleaseEnterProgramName: "Thov sau lub npe program",
    whatIsTheReward: "Qhov khoom plig yog dab tsi?",
    egNextPurchaseIsFree: "Piv txwv li Kev yuav khoom tom ntej yog pub dawb",
    customerName: "Neeg Npe",
    guestCustomer: "Qhua qhua",
    orderConfirmedSuccessfully: "Kev txiav txim tau lees paub ua tiav",
    orderCancelledSuccessfully: "Kev txiav txim tso tseg ua tiav",
    confirmOrder: "Paub meej tias xaj",
    cancelOrder: "Ncua kev txiav txim",
    allOrders: "Txhua Yam Kev Txiav Txim",
    totalOrders: "TSO TSHUAJ",
    doYouWantToAcceptOrder: "Koj puas xav lees txais daim ntawv xaj no?",
    doYouWantToCancelOrder: "Koj puas xav thim qhov kev txiav txim no?",
    orderDetails: "Kev Txiav Txim Siab",
    orderCreatedAt: "Kev txiav txim tsim ntawm",
    supplier: "Tsum tsum",
    productName: "Khoom Lub Npe",
    quantity: "Ntau npaum li cas",
    unitPrice: "Nqe Nqe",
    receivedBy: "Tau Txais Los Ntawm",
    reportFrom: "Qhia los ntawm",
    totalSupplies: "Cov Khoom Siv Tag Nrho",
    allRightsReserved: "Tag nrho cov cai qhaib",
    toDeleteThisTransaction: "kom tshem tawm Cov Kev Lag Luam no",
    transactionDeletedSuccessfully:
      "Kev ua lag luam tau raug tshem tawm ua tiav. Tshuag tau raug xa rov qab mus rau cov khoom lag luam.",
    deleteTransaction: "Rho tawm kev ua lag luam",
    transactionDetails: "Cov Lus Qhia Ua Ntej",
    printReceipt: "Luam Ntawv",
    channel: "Channel",
    discount: "Luv nqi",
    profit: "Nyiaj tau",
    discountedSales: "Txo Nqi Muag",
    errorFetchingRecord: "Yuam Kev Sau Cov Ntaub Ntawv",
    exportTransactions: "Export Kev Lag Luam",
    errorFetchingSalesRecord: "Yuam kev nqa cov ntaub ntawv muag rau Export.",
    totalSellingPrice: "Tus Nqi Muag Tag Nrho",
    totalCostPrice: "Tus Nqi Tus Nqi Tag Nrho",
    appliedDiscount: "Thov txo nqi",
    noOfItems: "Tsis Muaj Cov Khoom",
    sales: "Muag",
    export: "Export",
    totalProfit: "Cov Nyiaj Tau Los Tag Nrho",
    totalBalanceInPeriod: "Tshuav Tag Nrho Hauv Lub Sijhawm",
    allTimeSales: "Txhua Lub Sijhawm Muag",
    records: "Cov ntaub ntawv",
    todaysSales: "Kev Muag Khoom Hnub no",
    transaction: "kev sib pauv",
    youHaveExceededTotalNumberOfProducts:
      "Koj tau tshaj cov naj npawb ntawm cov khoom tso cai ntawm koj li phiaj xwm. Txhim kho koj li phiaj xwm kom txaus siab rau qhov txwv ntau dua.",
    youNeedToHaveLoyaltyProgram:
      "Koj Yuav Tsum Muaj Txoj Haujlwm Loyalty los siv cov yam ntxwv no!",
    price: "Nqe",
    category: "Qeb",
    stockTracking: "Kev Tshawb Fawb",
    stockCount: "Suav Suav",
    taxed: "Them se",
    originalPrice: "Thawj Nqe",
    costPrice: "Nqi Nqi",
    unit: "Chav nyob",
    productImage: "Khoom Duab",
    taxRate: "Tus Nqi Se",
    taxType: "Hom se",
    trackInventory: "Taug qab cov khoom lag luam",
    variants: "Variants",
    hasVariants: "Muaj Variants",
    importProduct: "Khoom Ntshuam",
    exportProducts: "Cov Khoom Muag",
    addNewProduct: "Ntxiv Khoom Tshiab",
    viewCategory: "Saib Pawg",
    viewSuppliers: "Saib Cov Neeg Muag Khoom",
    receiveInventory: "Tau Txais Cov Lus Nug",
    printAllProductsTag: "Luam Tag Txhua Yam Khoom",
    deleteAll: "Rho Tawm Txhua",
    totalProducts: "ZOO TSHAJ PLAWS",
    youveSuccessfullyAddedANewCategory: "Koj tau ua tiav ntxiv ib pawg tshiab",
    addNewCategory: "Ntxiv Qeb Tshiab",
    addCategory: "Ntxiv Qeb",
    categoryName: "Qeb Lub Npe",
    pleaseEnterCategoryName: "Thov sau lub npe qeb",
    stampsTarget: "Cov Nyiaj Khwv Tau Los",
    sendInventory: "Xa Cov Khoom Muag",
    productDetails: "Khoom Paub meej",
    youveSuccessfullyEditedThisCategory: "Koj tau ua tiav kho pawg no",
    update: "Hloov tshiab",
    categoryList: "Qeb Sau",
    categories: "Pawg",
    enterQuantityToUpdate: "Sau kom muaj nuj nqis los hloov tshiab",
    inventorySentSuccessfully: "Cov khoom muag tau xa tiav!",
    updateInventory: "Hloov kho cov khoom lag luam",
    currentQuantity: "Cov Naj Npawb Tam Sim No",
    pleaseEnterQuantityToAdd: "Thov sau qhov ntau koj xav ntxiv",
    pleaseSelectABranch: "Thov xaiv ib ceg",
    searchForBranch: "Nrhiav rau ceg ntseeg",
    youCantSendMoreThanStock:
      "Koj tsis tuaj yeem xa ntau dua li koj muaj hauv Tshuag",
    send: "Xa",
    pleaseEnterQuantityToSend: "Thov sau qhov ntau koj xav xa",
    productNameIsRequiredOnRow: "yuav tsum muaj lub npe khoom ntawm kab",
    productCategoryIsRequiredOnRow: "cov khoom lag luam xav tau ntawm kab",
    productPriceIsRequiredOnRow: "tus nqi khoom lag luam xav tau ntawm kab",
    productUnitIsRequiredOnRow: "cov khoom lag luam xav tau ntawm kab",
    productQuantityIsRequiredOnRow: "cov khoom ntau yog xav tau ntawm kab",
    productVariantsRequireTracking:
      "cov khoom sib txawv yuav tsum tau taug qab!",
    pleaseAddVariantClickButton:
      "Thov ntxiv qhov hloov pauv los ntawm kev nyem lub pob ntxiv!",
    totalVariantsMoreThanSelectedQuantity:
      "Tag Nrho Cov Variants ntau dua li xaiv cov khoom lag luam, Pls txo qhov sib txawv",
    productEditedSuccessfully: "Cov khoom tau raug kho kom ua tiav!",
    fileTooLargeLessThan4Mb:
      "Cov ntaub ntawv loj heev! Cov ntaub ntawv loj yuav tsum tsawg dua 4MB.",
    suchVariantAlreadyExist: "Xws li kev hloov pauv twb muaj lawm",
    addVariants: "Ntxiv Variants",
    editProduct: "Kho Khoom",
    pleaseEnterProductName: "Thov Sau Lub Npe Khoom",
    pleaseEnterProductPrice: "Thov Sau Tus Nqi Khoom",
    pleaseEnterProductOriginalPrice: "Thov Sau Cov Khoom Thawj Nqe",
    productDescription: "Khoom Piav Qhia",
    selectProductCategory: "Xaiv Pawg Khoom",
    pleaseSelectProductCategory: "Thov xaiv pawg khoom",
    productCostPrice: "Khoom Nqi Nqi",
    productSellingPrice: "Khoom Muag Nqe",
    productOriginalPrice: "Khoom Thawj Nqe",
    maxFileSizeAllowedIs4Mb: "Max. Cov Ntaub Ntawv Loj tso cai yog 4mb",
    productsWithPicturesArePublished:
      "Cov khoom lag luam nrog duab tau luam tawm ntawm Tshawb nrhiav kom tau txais kev xaj",
    shouldThisProductBeTracked: "Puas yog cov khoom no yuav raug taug qab?",
    pleaseSelectStockUnit: "Thov xaiv cov khoom lag luam",
    stockUnit: "Chav Nyob",
    bag: "Hnab",
    bottle: "Lub raj mis",
    bunch: "TUAJ",
    can: "YOG",
    carton: "Daim duab",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "POJ NIAM",
    pieces: "PIECES",
    plate: "Phaj",
    tonne: "TONNE (TSO)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Thov sau cov khoom kom muaj nuj nqis",
    productQuantity: "Khoom Quantity",
    isThisProductTaxed: "Cov khoom no puas tau them se?",
    selectTaxType: "Xaiv Hom Ua Se",
    pleaseSelectTaxType: "Thov xaiv hom se",
    progressive: "Kev vam meej",
    proportional: "Qhov sib npaug",
    pleaseEnterProductTaxRate: "Thov sau tus nqi se khoom",
    doesProductHaveVariants: "Cov khoom no puas muaj qhov sib txawv?",
    type: "Hom",
    value: "Tus nqi",
    pleaseEnterVariantType: "Thov sau hom kev hloov pauv",
    pleaseEnterVariantValue: "Thov sau tus nqi sib txawv",
    pleaseEnterVariantPrice: "Thov sau tus nqi sib txawv",
    pleaseEnterVariantQuantity: "Thov sau qhov sib txawv kom muaj nuj nqis",
    productDeletedSuccessfully: "Cov khoom tau raug tshem tawm ua tiav!",
    categoryDeletedSuccessfully: "Qeb tau raug tshem tawm ua tiav!",
    toDeleteThisProduct: "kom tshem tawm Cov Khoom no",
    invalidProductQuantity: "Yam Khoom Tsis Txaus",
    quantityAddedIsOverStock:
      "Qhov ntau koj ntxiv yog tshaj qhov koj muaj hauv Tshuag.",
    itemInventoryNotTracked: "Cov khoom lag luam tsis raug taug qab",
    addBulkQuantity: "Ntxiv Tej Zaum",
    enterBulkQuantity: "Nkag mus rau Ntau Yam",
    pleaseEnterBulkQuantity: "Thov nkag rau Bulk Quantity",
    youveSuccessfullyAddedANewProduct:
      "Koj tau ua tiav cov khoom lag luam tshiab!",
    pleaseEnterProductSellingPrice: "Thov sau tus nqi muag khoom",
    doYouWantToTrackProductStock: "Koj puas xav taug qab cov khoom lag luam?",
    sellingPrice: "Muag Nqe",
    setProductExpiryReminder: "Teem Cov Khoom Qhia Tawm Sijhawm",
    productExpiryDate: "Hnub tas sij hawm khoom",
    startRemindingFrom: "Pib Nco Los Ntawm",
    productSuppliesAddedSuccessfully:
      "Koj tau ntxiv cov khoom lag luam ua tiav.",
    addProductSupplies: "Ntxiv Cov Khoom Siv Khoom",
    pleaseSelectSupplier: "Thov Xaiv Tus Muag Khoom",
    nameOfProduct: "Lub Npe Ntawm Cov Khoom",
    pleaseSelectProduct: "Thov Xaiv Khoom",
    productVariant: "Khoom Variant",
    pleaseSelectAVariant: "Thov Xaiv Ib Hom",
    pleaseEnterUnitPrice: "Thov Sau Tus Nqi",
    businessBranch: "Cov Lag Luam Lag Luam",
    pleaseSelectBranch: "Thov xaiv ceg",
    youveSuccessfullyAddedANewSupplier:
      "Koj tau ua tiav ntxiv tus neeg muag khoom tshiab",
    addSupplier: "Ntxiv Tus Neeg Muag Khoom",
    pleaseEnterSupplierEmail: "Thov sau tus neeg muag khoom email",
    pleaseAddADescription: "Thov ntxiv cov lus piav qhia",
    anErrorOccuredProductsDeleted:
      "Ib qho yuam kev tshwm sim thaum ua haujlwm. Thov nco ntsoov, ob peb yam khoom yuav raug tshem tawm hauv tus txheej txheem",
    bulkDelete: "Tej Rho Tawm",
    youAreAboutToDelete: "Koj tab tom yuav rho tawm",
    product: "Khoom",
    isDueToRedeem: "yog vim yuav txhiv",
    aReward: "nqi zog",
    pleaseSelectCustomerToReeemReward:
      "Thov xaiv tus neeg siv khoom kom tau txais khoom plig.",
    youHaveNoLoyaltyProgramRunning:
      "Koj tsis muaj txoj haujlwm ncaj ncees ncaj ncees ua haujlwm",
    customerHhasNoPointsInLoyaltyProgram:
      "Cov neeg siv khoom tsis muaj cov ntsiab lus hauv txoj haujlwm ncaj ncees no",
    proceedWithPayment: "Teem Nrog Kev Them Nyiaj?",
    youAreAboutToPayForTransactionUsingPoints:
      "Koj tab tom yuav them rau qhov kev sib pauv siv cov ntsiab lus.",
    customerHas: "Customer Muaj",
    worth: "muaj nqis",
    andIsNotSufficientToPayForTransaction:
      "thiab tsis txaus los them rau qhov kev sib pauv no. Lawv puas xav ntxiv qhov nyiaj tshuav siv lwm txoj hauv kev them nqi?",
    addCustomerLoyalty: "Ntxiv Cov Neeg Loyalty",
    pleaseAddCustomerFirst:
      "Thov ntxiv lossis xaiv tus neeg siv khoom ua ntej.",
    pleaseWaitWhileWeGetReady: "Thov tos, thaum peb npaj tau",
    lowStock: "Tsawg Tshuag",
    pleaseEnterAmountTendered: "Thov sau tus nqi sib tw",
    areYouSureToBookSaleOffline:
      "Koj puas paub tseeb tias koj xav tau phau ntawv muag no offline?",
    saleWouldBeBookedAutomatically:
      "Kev muag khoom yuav tau sau cia thaum thaum koj qhib koj li internet",
    offlineSalesBookingCancelled: "Kev muag khoom muag tsis raug tso tseg",
    covid19Message:
      "COVID19: Ntxuav koj txhais tes nrog xab npum lossis ntxuav kom tsis txhob kis mus ntxiv. Nyob Nyab Xeeb Ib Leeg",
    saleSuccessfullyRecorded: "Kev Muag Khoom Ua tiav Sau!",
    sendReceiptToEmail: "Xa daim ntawv txais nyiaj mus rau Email",
    sendThankYouSms: "Xa ua tsaug rau SMS",
    sendShippingDetails: "Xa cov ntsiab lus Shipping",
    addLoyalty: "Ntxiv Loyalty",
    searchCustomerNameOrNumber: "Tshawb nrhiav lub npe lossis tus lej",
    clickTheSearchCustomerBox:
      "Nyem rau Tshawb Nrhiav Cov Neeg Siv Khoom thiab Luam Ntawv",
    enterProductPrice: "Nkag Nqi Khoom",
    enterPriceFor: "Nkag Nqi rau",
    searchForProduct: "Nrhiav khoom",
    all: "Txhua",
    backToDashboard: "Rov qab mus rau Dashboard",
    viewDraftSales: "Saib Daim Ntawv Muag Khoom",
    variantSelected: "xaiv xaiv",
    variant: "sib txawv",
    thePreviousVariantSelectionNotAvailable:
      "Qhov kev xaiv sib txawv yav dhau los tsis muaj rau qhov hloov pauv tshiab xaiv raws tus nqi, thov hloov koj qhov kev xaiv.",
    pleaseSupplyPositiveQuantityNumber: "Thov muab tus lej xa tuaj tom qab",
    lowStockFor: "Tsawg Tshuag rau",
    clearVariants: "Ntshiab sib txawv",
    pleaseEnterQuantity: "Thov nkag rau Quantity",
    picture: "Duab",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Tag Nrho Siv",
    confirmPayment: "Paub meej tias Kev Them Nyiaj",
    hasPaymentBeenMade: "Puas tau them nyiaj tiav ua tiav?",
    enterTransactionReference:
      "Nkag mus rau qhov kev thov pauv koj tau them nrog",
    pleaseSelectACustomer: "Thov xaiv tus neeg siv khoom!",
    areYouSureToApplyDiscount: "Koj puas paub tseeb tias koj xav thov txo nqi?",
    addYourBankAccountToEnableUssd:
      "Ntxiv koj tus as -qhauj hauv txhab nyiaj kom qhib tau USSD hloov tam sim los ntawm uPay",
    totalAmountToPay: "Tag Nrho Cov Nqi Them",
    doYouWantToCancelTransaction: "Koj puas xav thim qhov kev pauv no?",
    savePrintBill: "Txuag/Luam Bill",
    enterAmountCollectedForCustomer: "Sau tus nqi sau rau cov neeg siv khoom",
    recordSale: "Cov Muag Khoom",
    checkOutWith: "Txheeb nrog",
    instantTransfer: "Hloov Chaw",
    dialTheUSSDCode: "Dial tus USSD code",
    pleaseSelectABank: "Thov xaiv lub txhab nyiaj",
    payWithUSSD: "Them Nrog USSD",
    sendBillTo: " - Xa Bill rau",
    waitingForUSSDTransfer: "Tos USSD Hloov",
    percent: "Feem pua",
    applyDiscount: "Thov Nyiaj Txiag",
    thisBillHasBeenSaved: "Daim nqi no tau txais kev cawmdim",
    saveDraft: "Txuag Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Thov ntaus lub npe kom txheeb xyuas cov neeg siv khoom",
    paymentDetails: "Cov Nyiaj Them Nqi",
    basePrice: "Nqe Nqe",
    staff: "Cov neeg ua haujlwm",
    subTotal: "Tag Nrho",
    durationMonths: "Ntev (hli)",
    selectADuration: "Xaiv Lub Sijhawm",
    oneMonth: "1 Hlis",
    twoMonths: "2 Hlis",
    threeMonths: "3 Hlis",
    sixMonths: "6 Hlis",
    twelveMonths: "12 Hlis",
    eighteenMonths: "18 Hlis",
    twentyFourMonths: "24 Hlis",
    twoMonthsFree: "(2 Hlis Dawb)",
    threeMonthsFree: "(3 Hlis Dawb)",
    fiveMonthsFree: "(5 Hlis Dawb)",
    yourAccountHasBeen: "Koj tus Account tau lawm",
    renewed: "rov ua dua tshiab",
    upgraded: "hloov dua tshiab",
    successfully: "ua tiav",
    yourSubscription: "Koj Subscription",
    youAreCurrentlyEnrolledOnA: "Tam sim no koj tau cuv npe hauv a",
    pleaseChooseAPaymentOption: "Thov Xaiv Kev Them Nqi",
    planRenewal: "Npaj Rov Ntxiv Tshiab",
    planUpgrade: "Npaj Txhim Kho",
    pleaseSelectDurationToPayFor:
      "Thov Xaiv Lub Sijhawm uas koj npaj siab yuav them rau",
    staffAccounts: "Cov Neeg Ua Haujlwm Cov Nyiaj",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Thov xaiv txoj kev npaj",
    includeAddons: "Suav nrog Add-ons",
    viewTransactions: "Saib Kev Ua Lag Luam",
    customerHasNoCompletedTansactions:
      "Cov neeg siv khoom tseem tsis tau ua tiav qhov kev xav tau",
    branch: "Ceg",
    enterNumberOfEcommerceBranches: "Sau Tus Zauv ntawm Ecommerce Branches",
    enterNumberOfEcommerceBranchesToPay:
      "Sau Tus lej ntawm Ecommerce Branches Koj Xav Kom Them Rau",
    ecommerceIntegration: "Kev Lag Luam Ecommerce",
    enterNumberOfBranches: "Ntaus Tus Naj Npawb",
    enterNumberOfAdditionalBranchesToPay:
      "Sau Tus Naj Npawb Ntawm Cov ceg ntoo ntxiv uas koj npaj siab yuav them rau",
    enterNumberOfStaffs: "Sau Cov Neeg Ua Haujlwm",
    enterNumberOfStaffsToPayFor:
      "Sau Tus Naj Npawb ntawm Cov Neeg Ua Haujlwm Koj Xav Kom Them Rau",
    discountApplies: "Kev Siv Nyiaj Txiag",
    starsOnThe: "cov hnub qub ntawm",
    offer: "muab",
    get: "Tau",
    moreStarsToRedeem: "ntau lub hnub qub los txhiv",
    taxVat: "Se (VAT)",
    callCashierToCompletePayment:
      "Hu rau tus Kws Sau Nyiaj kom tiav Kev Them Nyiaj",
    receipt: "Daim ntawv txais nyiaj",
    dear: "Nyob zoo",
    thankYouForYourOrderFindGoods:
      "Ua tsaug rau koj qhov kev txiav txim. Thov nrhiav cov khoom lag luam hauv qab no, raws li tau pom zoo.",
    shippingNote: "Lus Ceeb Toom",
    enterShippingNote: "Sau Cov Lus Ceeb Toom",
    shippingAddress: "Chaw nyob xa khoom",
    enterShippingAddress: "Nkag mus rau Chaw xa khoom",
    wellDoneYouAreDueToRedeem: "Ua tau zoo! Koj yuav tsum tau txhiv",
    toGetYourRewardNextVisit:
      "kom tau txais koj cov khoom plig thaum koj mus ntsib tom ntej. Ua tsaug",
    pointsOnThe: "Cov ntsiab lus ntawm",
    morePointsToRedeem: "ntau cov ntsiab lus los txhiv",
    showCode: "Qhia cov cai",
    toGetYourRewardOnNextVisit:
      "kom tau txais koj cov khoom plig thaum koj mus ntsib tom ntej. Ua tsaug",
    earn: "Khwv tau",
    delivaryNote: "Delivary Nco tseg",
    draftSales: "Draft Muag",
    startDate: "Hnub pib",
    endDate: "Hnub kawg",
    orders: "Kev txiav txim",
    checkout: "checkout",
    noProductItem: "Tsis Muaj Cov Khoom Muag",
    selectProductImage: "Xaiv Khoom Duab",
    selectCountry: "Xaiv lub tebchaws",
    selectRegion: "Xaiv Xeev/Cheeb Tsam",
    printProductTag: "Luam Khoom Tag",
    transactionReference: "Kev ua lag luam siv",
    Cashier: "Tus neeg khaws nyiaj",
    Manager: "Tus Thawj Coj",
    Owner: "Tus tswv",
    Admin: "Admin",
    addPartners: "Ntxiv Partners",
    addNewLoyaltyPartner: "Ntxiv Tshiab Loyalty Partner",
    pleaseEnterCompanyName: "Thov sau Lub Tuam Txhab Lub Npe",
    companyName: "Lub tuam txhab npe",
    pleaseEnterCompanyRepName:
      "Thov sau lub tuam txhab tus neeg sawv cev lub npe",
    companyRepName: "Lub npe ntawm Tuam Txhab Tus Neeg Sawv Cev",
    pleaseEnterCompanyRepEmail:
      "Thov sau email ntawm tuam txhab tus neeg sawv cev",
    companyRepEmail: "Email ntawm tuam txhab rep",
    pleaseEnterCompanyRepPhone:
      "Thov sau tus xov tooj ntawm tuam txhab tus neeg sawv cev",
    companyRepPhone: "Xov tooj ntawm tuam txhab rep",
    addReward: "Ntxiv nqi zog",
    pleaseEnterRewardName: "Thov sau npe khoom plig",
    rewardName: "Lub npe khoom plig",
    rewardQuantity: "Yam khoom nqi zog",
    rewardDescription: "Nqe lus piav qhia",
    rewardType: "Yam khoom plig",
    pleaseEnterRewardType: "Thov sau hom khoom plig",
    pleaseEnterRewardQuantity: "Thov sau nqi zog ntau",
    pleaseEnterRewardDescription: "Thov sau nqe lus piav qhia",
    fineDining: "Noj hmo zoo",
    luxuryFashion: "Khoom kim heev zam",
    hotels: "Tsev so",
    travel: "Mus ncig",
    foodAndBeverage: "Khoom noj khoom haus thiab dej haus",
    fashion: "Zam",
    health: "Kev noj qab haus huv",
    furniture: "Rooj tog",
    entertainment: "Kev lom zem",
    automobile: "Tsheb",
    education: "Kev kawm",
    beautyAndSpa: "Kev zoo nkauj thiab Spa",
    staycation: "Nyob twj ywm",
    events: "Cov xwm txheej",
    trips: "Mus ncig ua si",
    oilAndGas: "Roj thiab Roj",
    laundry: "Khaub ncaws",
    partnerCategory: "Pawg neeg koom tes",
    freeItem: "Yam khoom dawb",
  },
  Hindi: {
    cashier: "केशियर",
    manager: "प्रबंधक",
    owner: "मालिक",
    online: "ऑनलाइन",
    offline: "ऑफ़लाइन",
    changePassword: "पासवर्ड बदलें",
    currentPasswordMessage: "कृपया अपना वर्तमान पासवर्ड दर्ज करें",
    passwordMessage: "अपना पासवर्ड दर्ज करें",
    currentPassword: "वर्तमान पासवर्ड",
    password: "पासवर्ड",
    confirmPasswordMessage: "कृपया अपने पासवर्ड की पुष्टि करें!",
    confirmPassword: "पासवर्ड की पुष्टि कीजिये",
    sendViaEmail: "ईमेल के माध्यम से भेजें",
    sendViaWhatsapp: "व्हाट्सएप के माध्यम से भेजें",
    downloadPdf: "डाउनलोड पीडीऍफ़",
    paid: "भुगतान किया है",
    unpaid: "अवैतनिक",
    partial: "आंशिक",
    closeInvoice: "क्या आप चालान बंद करना चाहते हैं?",
    closeInvoiceConfirmation:
      "चालान सहेजा नहीं जा सकता है। क्या आप बंद करना चाहते हैं?",
    yes: "हां",
    no: "नहीं",
    invoice: "बीजक",
    wasDeducted: "काटा गया था",
    for: "के लिये",
    item: "मद",
    addProduct: "उत्पाद जोड़ें",
    paymentReference: "भुगतान संदर्भ",
    amountPaid: "राशि का भुगतान",
    discountAmount: "छूट राशि",
    amountDue: "देय राशि",
    amount: "राशि",
    dueDate: "नियत तारीख",
    paymentType: "भुगतान के प्रकार",
    card: "कार्ड",
    cash: "नकद",
    bankTransfer: "बैंक ट्रांसफर",
    paymentMessage: "भुगतान संदेश",
    description: "विवरण",
    sendReceipt: "रसीद भेजें",
    delete: "हटाएं",
    save: "सहेजें",
    resend: "पुन: संदेश",
    saveAndSend: "सहेजें",
    invoiceSaved: "चालान सहेजा गया!",
    selectPaymentMethod: "अपने भुगतान के तरीके का चुनाव करें!",
    selectCustomer: "कृपया एक ग्राहक चुनें!",
    cartEmptyError:
      "कार्ट सूची खाली नहीं हो सकती, चालान बंद करें और कार्ट में आइटम जोड़ें!",
    subscriptionExpired:
      "आपकी सदस्यता समाप्त हो गई है और आपका खाता अब सीमित है। अपना खाता नवीनीकृत करने के लिए नीचे दिए गए बटन पर क्लिक करें",
    renew: "नवीकरण",
    holdOn: "रूको",
    customerBank: "ग्राहक बैंक",
    cancel: "रद्द करें",
    selectACustomer: "ग्राहक का चयन करें",
    invoiceSuccessfulPdfError:
      "चालान सफलतापूर्वक बनाया गया लेकिन पीडीएफ जनरेशन में सामान्य से अधिक समय लग रहा है। कृपया शीघ्र ही वापस जांचें।",
    downloadingInvoice: "चालान डाउनलोड करना",
    downloadingReceipt: "रसीद डाउनलोड करना",
    whatsappReceiptError:
      "व्हाट्सएप के माध्यम से रसीद भेजने में एक त्रुटि हुई, कृपया पुनः प्रयास करें।",
    receiptToWhatsapp: "रसीद व्हाट्सएप पर भेजी गई",
    thankYouForPatronage: "आपके संरक्षण के लिए धन्यवाद",
    hereIsYourReceipt: "ये है आपकी भुगतान रसीद",
    errorSendingEmailReceipt:
      "ई-मेल के माध्यम से रसीद भेजने में त्रुटि हुई, कृपया पुन: प्रयास करें या समर्थन से संपर्क करें",
    receiptSentToEmail: "रसीद ग्राहक के ईमेल पर भेज दी गई है",
    invoiceSentToEmail: "ग्राहक के ईमेल पर चालान भेज दिया गया है",
    invoiceSuccessWhatsappError:
      "चालान सफलतापूर्वक बनाया गया लेकिन व्हाट्सएप पर भेजने में त्रुटि हुई। चालान सूची पर पुन: प्रयास करें",
    invoiceSuccessfulEmailError:
      "चालान सफलतापूर्वक बनाया गया लेकिन ई-मेल के रूप में भेजते समय एक त्रुटि हुई। कृपया चालान सूची से पुन: प्रयास करें",
    invoiceSentToWhatsapp: "चालान व्हाट्सएप पर भेजा गया",
    hello: "नमस्ते",
    pleaseDownloadInvoice: "कृपया चालान डाउनलोड करें",
    pleaseDownloadReceipt: "कृपया रसीद डाउनलोड करें",
    from: "से",
    email: "ईमेल",
    upgrade: "अपग्रेड",
    youAreOnFreePlan: "आप एक फ्री प्लान पर हैं।",
    clickOn: "पर क्लिक करें",
    yourPlanInFewSteps: " कुछ त्वरित चरणों में आपकी सदस्यता।",
    to: "प्रति",
    yourSubscriptionHasExpired:
      "आपकी सदस्यता समाप्त हो गई है और आपका खाता अब सीमित है।",
    days: "दिन",
    yourSubscriptionExpiresIn: "आपकी लॉयस्टार सदस्यता समाप्त हो रही है",
    createAcount: "खाता बनाएं",
    signIn: "साइन इन करें",
    continue: "जारी रखना",
    enterOtp: "ओटीपी पिन दर्ज करें",
    enterValidOtp: "कृपया एक मान्य पिन दर्ज करें",
    pin: "पिन",
    tokenSentToMail: "आपके ईमेल पर एक टोकन भेज दिया गया है",
    passwordResetSuccessful: "पासवर्ड रीसेट सफल रहा",
    somethingWentWrong: "कुछ गलत हो गया!",
    resetPassword: "पासवर्ड रीसेट",
    iHaveResetCode: "मेरे पास पासवर्ड रीसेट कोड है",
    pleaseEnterEmail: "अपना ईमेल दर्ज करें",
    aTokenWillBeSentToEmail: "आपके ईमेल पर एक टोकन भेजा जाएगा",
    enterEmail: "अपना ईमेल दर्ज करें",
    sendinYourToken: "आपका टोकन भेजा जा रहा है...",
    makeSureItMatchesPassword:
      "सुनिश्चित करें कि यह आपके नए पासवर्ड से मेल खाता है",
    pleaseChooseNewPassword: "कृपया एक नया पासवर्ड चुनें",
    chooseNewPassword: "एक नया पासवर्ड चुनें",
    enterNewPassword: "पुष्टि करने के लिए अपना नया पासवर्ड दर्ज करें",
    enterTokenSent: "वह टोकन दर्ज करें जिसे आपका मेल भेजा गया था",
    resetToken: "टोकन रीसेट करें",
    resettingPassword: "आपका पासवर्ड रीसेट किया जा रहा है...",
    signUp: "साइन अप करें",
    adminSignInWithEmail:
      " एडमिन ईमेल से साइन इन करता है जबकि स्टाफ यूजरनेम से साइन इन करता है।",
    pleaseEnterEmailOrUsername: "कृपया अपना ईमेल या उपयोगकर्ता नाम दर्ज करें",
    emailOrUsername: "ईमेल या उपयोगकर्ता का नाम",
    pleaseEnterPassword: "कृप्या पास्वर्ड भरो",
    createAnAccount: "खाता बनाएं",
    forgotPassword: "पासवर्ड भूल गए?",
    enterPhoneNumber: "फोन नंबर दर्ज",
    personalData: "व्यक्तिगत डेटा",
    validateConfirmationCOde: "पुष्टिकरण कोड मान्य करें",
    pleaseEnterFirstName: "कृपया अपना प्रथम नाम प्रविष्ट करें",
    pleaseEnterPhoneNumber: "कृपया अपना फोन नंबर दर्ज करें",
    pleaseEnterLastName: "कृपया अपना अंतिम नाम दर्ज करें",
    pleaseEnterBusinessName: "कृपया अपना व्यवसाय नाम दर्ज करें",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    businessName: "व्यवसाय का नाम",
    previous: "पहले का",
    next: "अगला",
    pleaseSelectBusinessCategory: "कृपया अपनी व्यवसाय श्रेणी चुनें",
    pleaseEnterValidEmail: "कृपया एक मान्य ईमेल पता प्रवेश करें",
    pleaseEnterPostCode: "कृपया पोस्ट कोड दर्ज करें",
    postCode: "पोस्ट कोड",
    phoneNumberInUse: "यह फ़ोन नंबर पहले से उपयोग में है!",
    emailInUse: "यह ईमेल पहले से प्रयोग में है!",
    foodAndDrinks: "खाना पीना",
    salonAndBeauty: "सैलून और सौंदर्य",
    fashionAndAccessories: "फैशन और सहायक उपकरण",
    gymAndFitness: "जिम और फिटनेस",
    travelAndHotel: "यात्रा और होटल",
    homeAndGifts: "घर और उपहार",
    washingAndCleaning: "धुलाई और सफाई",
    gadgetsAndElectronics: "गैजेट्स और इलेक्ट्रॉनिक्स",
    groceries: "किराने का सामान",
    others: "अन्य",
    submit: "प्रस्तुत करना",
    accountCreatedSuccessful: "आपका खाता कुशलतापुरवक बना दिया गया है।",
    pleaseEnterAddress: "कृपया अपना पता दर्ज करें",
    addressLine1: "पता पंक्ति 1",
    addressLine2: "पता पंक्ति नं। 2",
    choosePassword: "पासवर्ड चुनें",
    passwordMustBe6Characters: "पासवर्ड कम से कम 6 अंकों का होना चाहिए।",
    howDidYouHearLoystar: "आपने लॉयस्टार के बारे में कैसे सुना?",
    referralCode: "रेफरल कोड",
    byClickingTheButton: " नीचे दिए गए बटन पर क्लिक करके, आप इससे सहमत हैं",
    termsAndSevice: "मामले",
    wereCreatingAccount: "हम आपका खाता बना रहे हैं",
    proceed: "आगे बढ़ना",
    verificationCodeMustBe6: "सत्यापन कोड 6 अंकों का होना चाहिए",
    pleaseEnter6DigitCode: "कृपया ६ अंकों का कोड दर्ज करें",
    enterVerificationCode: "सत्यापन कोड दर्ज करें",
    resendToken: "टोकन पुनः भेजें",
    verify: "सत्यापित करें",
    transactions: "लेनदेन",
    todaySales: "आज की बिक्री",
    salesHistory: "बिक्री इतिहास",
    supplyHistory: "आपूर्ति इतिहास",
    new: "नया",
    invoices: "चालान",
    disbursements: "संवितरण",
    offlineSales: "ऑफलाइन बिक्री",
    products: "उत्पादों",
    customers: "ग्राहकों",
    multiLevelLoyalty: "बहु-स्तरीय वफादारी",
    loyaltyPrograms: "विश्वसनीयता कार्यक्रम",
    members: "सदस्यों",
    appStore: "ऐप स्टोर",
    orderMenu: "आदेश मेनू",
    settings: "समायोजन",
    staffAndBranches: "कर्मचारी और शाखाएं",
    myAccount: "मेरा खाता",
    switchToSellMode: "बिक्री मोड में स्विच करें",
    signOut: "साइन आउट",
    getFreeSubscription: "मुफ़्त सदस्यता प्राप्त करें",
    onlyNumbersAllowed: "केवल नंबरों की अनुमति है",
    yourAccountMustBe10Digits: "आपका खाता नंबर 10 अंकों का होना चाहिए",
    yourBvnMustBe11: "आपका बीवीएन 11 अंकों का नंबर होना चाहिए",
    pleaseSelectBank: "कृपया अपना बैंक चुनें",
    selectYourBank: "अपना बैंक चुनें",
    enterBankAccountNumber: "बैंक खाता संख्या दर्ज करें",
    enterBvnNumber: "अपना बीवीएन दर्ज करें",
    connectBankAccount: "बैंक खाता कनेक्ट करें",
    passwordResetSuccessfulAndSignOut:
      "आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है। फिर से साइन इन करने के लिए नीचे दिए गए साइन आउट बटन पर क्लिक करें",
    enterCurrentPassword: "वर्तमान पासवर्ड दर्ज करो",
    pleaseEnterCurrentPassword: "कृपया वर्तमान पासवर्ड दर्ज करें",
    phoneNumber: "फ़ोन नंबर",
    sex: "लिंग",
    dateOfBirth: "जन्म की तारीख",
    state: "राज्य",
    country: "देश",
    loyaltyId: "वफादारी आईडी",
    createdAt: "पर बनाया गया",
    noOfTransactionsMade: "किए गए लेनदेन की संख्या",
    yourDownloadWillStart: "आपका डाउनलोड एक पल में शुरू हो जाएगा",
    exportCustomers: "निर्यात ग्राहक",
    youhaveSuccessfullyToppedUp:
      "आपने अपनी एसएमएस इकाइयों को सफलतापूर्वक टॉप अप कर लिया है।",
    youNowHave: "अब आपके पास है",
    smsUnits: "एसएमएस इकाइयां",
    enterNumberOfSmsUnits:
      "उन एसएमएस इकाइयों की संख्या दर्ज करें जिन्हें आप खरीदना चाहते हैं",
    pleaseEnterANumericValue: "कृपया एक अंकीय मान दर्ज करें",
    pay: "वेतन",
    accountEditedSuccessfully: "आपका खाता सफलतापूर्वक संपादित किया गया था।",
    youAeCurrentlyOn: "आप अभी चालू हैं",
    plan: "योजना",
    userData: "उपयोगकर्ता का डेटा",
    businessData: "व्यापार डेटा",
    businessCategory: "व्यापार श्रेणी",
    pleaseSelectCurrency: "कृपया अपनी मुद्रा चुनें",
    selectYourCurrency: "अपनी मुद्रा चुनें",
    purchaseMoreSmsUnits:
      "नीचे दिए गए फॉर्म का उपयोग करके अधिक एसएमएस इकाइयां खरीदें",
    youHave: "आपके पास",
    atLeast4SmsUnitsRrequired:
      "सत्यापन के लिए कम से कम 4 एसएमएस इकाइयों की आवश्यकता है, कृपया टॉप अप करें!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "कृपया 'अपने ग्राहक को जानें' (केवाईसी) आवश्यकताओं का अनुपालन करने के लिए अपना बैंक खाता सत्यापित करें। यह आपको यूएसएसडी या तत्काल हस्तांतरण के माध्यम से भुगतान एकत्र करने, ग्राहकों से आदेश प्राप्त करने और लेनदेन निपटान की प्रक्रिया करने की अनुमति देगा। केवल नाइजीरियाई व्यापारियों के लिए लागू। कृपया शुरू करने के लिए नीचे दिए गए बटन को दबाएं।",
    reConnectBankAccount: "बैंक खाते को फिर से कनेक्ट करें",
    accountName: "खाता नाम",
    accountNumber: "खाता संख्या",
    bankName: "बैंक का नाम",
    verified: "सत्यापित",
    accountDetails: "खाता विवरण",
    kycBankAccount: "केवाईसी",
    createTier: "टियर बनाएं",
    fileUploadSuccessful: "फाईल सफलता पूर्वक अपलोड हुई",
    fileUploadFailed: "फ़ाइल अपलोड विफल",
    createLoyaltyProgram: "एक वफादारी कार्यक्रम बनाएँ",
    createLoyalty: "वफादारी बनाएँ",
    name: "नाम",
    loyaltyArtwork: "वफादारी कलाकृति",
    clickToUpload: "अपलोड करने के लिए क्लिक करें",
    amountToPointsRatio: "अंक अनुपात से राशि",
    points: "अंक",
    recommendedAmountToPointRatio:
      "अनुशंसित: 1 से 1 बिंदु। यानी खर्च किए गए प्रत्येक 1 नायरा के लिए, आपके ग्राहकों को 1 अंक मिलता है",
    pleaseTypeIn: "कृपया टाइप करें",
    toDeleteLoyalty: "इस वफादारी को मिटाने के लिए",
    deleteLoyalty: "वफादारी हटाएं",
    toConfirm: "पुष्टि करने के लिए",
    edit: "संपादित करें",
    pointsAwardedSuccessfully: "अंक सफलतापूर्वक प्रदान किए गए।",
    enterPointValueToAward:
      "पॉइंट वैल्यू दर्ज करें जिसे आप ग्राहकों को पुरस्कृत करना चाहेंगे",
    award: "पुरस्कार",
    awardPointValuesToCustomer: "ग्राहक को पुरस्कार बिंदु मूल्य",
    enrollMembersToLoyalty: "वफादारी पर सदस्यों को नामांकित करें",
    awardPoints: "पुरस्कार अंक",
    enroll: "नामांकन",
    home: "घर",
    loyalty: "निष्ठा",
    enrollCustomers: "ग्राहकों का नामांकन करें",
    selected: "गिने चुने",
    customer: "ग्राहक",
    loyaltyActivationSuccessful: "लॉयल्टी सक्रियण सफल है।",
    loyaltyDeactivationSuccessful: "वफादारी निष्क्रियता सफल है।",
    viewTier: "टियर देखें",
    deactivate: "निष्क्रिय करें",
    activate: "सक्रिय",
    actions: "कार्रवाई",
    nameOfLoyalty: "वफादारी का नाम",
    loyaltyStatus: "वफादारी की स्थितिवफादारी की स्थिति",
    numberOfTiers: "स्तरों की संख्या",
    createdOn: "को निर्मित",
    createATier: "एक टियर बनाएं",
    stopCreatingTierConfirmation: "क्या आप टियर बनाना बंद करना चाहते हैं?",
    stopEditingTierConfirmation:
      "क्या आप इस स्तर का संपादन बंद करना चाहते हैं?",
    nameOfTier: "टियर का नाम",
    minimumSpendingTarget: "न्यूनतम खर्च लक्ष्य",
    maximumSpendingTarget: "अधिकतम खर्च लक्ष्य",
    minimumSpendingTargetRequired: "न्यूनतम खर्च लक्ष्य आवश्यक है",
    maximumSpendingTargetRequired: "अधिकतम खर्च लक्ष्य आवश्यक है",
    rewardSponsor: "इनाम प्रायोजक",
    pleaseChooseARewardSponsor: "कृपया एक इनाम प्रायोजक चुनें",
    self: "स्वयं",
    partner: "साथी",
    rewardPartner: "इनाम साथी",
    pleaseSelectRewardPartner: "कृपया अपना इनाम साथी चुनें",
    rewards: "पुरस्कार",
    pleaseSelectAReward: "एक पुरस्कार का चयन करें",
    instructionsOnRedeemingReward:
      "ग्राहक को इनाम कैसे भुनाना चाहिए, इस पर निर्देश",
    pleaseFillInThisField: "कृपया इस फ़ील्ड को भरें!",
    toDeleteThisTier: " इस स्तर को हटाने के लिए",
    deleteTier: "टियर हटाएं",
    viewMembers: "सदस्यों को देखें",
    membersEnrolled: "नामांकित सदस्य",
    instruction: "अनुदेश",
    membersIn: "में सदस्य",
    availableTiersInLoyalty: "वफादारी कार्यक्रम में उपलब्ध स्तर",
    tiers: "स्तरों",
    totalTier: "कुल टियर",
    availableLoyaltyProgramme: "उपलब्ध वफादारी कार्यक्रम",
    totalLoyalties: "कुल वफादारी",
    memberDetails: "सदस्य विवरण",
    nameOfCustomer: "ग्राहक का नाम",
    address: "पता",
    allEnrolledMembers: "सभी नामांकित सदस्य",
    thisIsToWishYouHappyBirthday:
      "यह आपके जन्मदिन की बहुत-बहुत बधाई और समृद्ध जीवन की कामना करता है। आप लॉयस्टर के लिए जो कुछ भी हैं, उसके लिए धन्यवाद। शुभ उत्सव!",
    inputAnOfferPlease: "कृपया एक प्रस्ताव दर्ज करें",
    pleaseSelectTheInsertPoint:
      "कृपया संदेश में सम्मिलित करें बिंदु चुनें और फिर से क्लिक करें",
    birthdayOfferAndMessage: "जन्मदिन की पेशकश और संदेश",
    birthdayOffer: "जन्मदिन की पेशकश",
    birthdayMessage: "जन्मदिन संदेश",
    noOfferFound: "कोई प्रस्ताव नहीं मिला",
    settingABirthdayOffer:
      "जन्मदिन का ऑफ़र सेट करने से ग्राहक अपने जन्मदिन पर SMS के माध्यम से यह ऑफ़र प्राप्त कर सकते हैं",
    createOffer: "ऑफ़र बनाएं",
    whatIsTheOffer: "प्रस्ताव क्या है?",
    editMessage: "संदेश संपादित करें",
    insert: "डालने",
    theNameOfCustomerInserted: "ग्राहक का नाम यहां डाला जाएगा",
    theBirtdayOfferInserted: "बर्थडे ऑफर यहां डाला जाएगा",
    youSuccessfullyAddedNewBranch: "आपने सफलतापूर्वक एक नई शाखा जोड़ ली है!",
    addNewBranch: "नई शाखा जोड़ें",
    addBranch: "शाखा जोड़ें",
    additionalBranchWillIncur: "अतिरिक्त शाखा लगेगी",
    perBranch: "प्रति शाखा",
    ecommerceBranchCosts: "ईकॉमर्स शाखा की लागत",
    pleaseEnterBranchName: "कृपया शाखा का नाम दर्ज करें",
    pleaseEnterBranchAddress1: "कृपया शाखा की पता पंक्ति दर्ज करें 1",
    enterBranchAddress1: "शाखा की पता पंक्ति दर्ज करें 1",
    enterBranchAddress2: "शाखा की पता पंक्ति दर्ज करें 1",
    pleaseEnterBranchAddress2: "कृपया शाखा की पता पंक्ति दर्ज करें 2",
    enterBranchName: "शाखा का नाम दर्ज करें",
    successfullyAddedStaff: "आपने सफलतापूर्वक एक नया स्टाफ़ जोड़ लिया है!",
    addNewStaff: "नया स्टाफ जोड़ें",
    addStaff: "स्टाफ जोड़ें",
    additionalStaffWillIncur: "अतिरिक्त स्टाफ लगेगा",
    perStaff: "प्रति कर्मचारी।",
    pleaseEnterStaffEmail: "कृपया स्टाफ का ईमेल दर्ज करें",
    pleaseEnterStaffUsername: "कृपया स्टाफ का उपयोगकर्ता नाम दर्ज करें",
    pleaseEnterStaffPassword: "कृपया स्टाफ का पासवर्ड दर्ज करें",
    pleaseSelectStaffRole: "कृपया स्टाफ की भूमिका चुनें",
    selectStaffRole: "स्टाफ की भूमिका का चयन करें",
    enterStaffEmail: "कर्मचारी का ईमेल दर्ज करें",
    enterStaffUsername: "कर्मचारी का उपयोगकर्ता नाम दर्ज करें",
    enterStaffPassword: "कर्मचारी का पासवर्ड दर्ज करें",
    spacesNotAllowedInUsername:
      "उपयोगकर्ता नाम में रिक्त स्थान की अनुमति नहीं है",
    admin: "व्यवस्थापक",
    pleaseSelectBusinessToAttachStaff:
      "कर्मचारियों को संलग्न करने के लिए कृपया व्यवसाय का चयन करें",
    searchForBranchToAttachStaff: "स्टाफ संलग्न करने के लिए शाखा खोजें",
    username: "उपयोगकर्ता नाम",
    role: "भूमिका",
    areYouSureToDeleteThis: "क्या आप इसे हटाना सुनिश्चित कर रहे हैं",
    branches: "शाखाओं",
    upgradeYourPlan: "अपनी योजना को अपग्रेड करें।",
    add: "जोड़ें",
    addNew: "नया जोड़ो",
    customerWithEmailAlreadyExists:
      "ईमेल/फोन नंबर वाला ग्राहक पहले से मौजूद है!",
    successfullyAddedNewCustomer:
      "आपने सफलतापूर्वक एक नया ग्राहक जोड़ लिया है!",
    addCustomer: "ग्राहक जोड़ें",
    pleaseEnterCustomerFirstName: "कृपया ग्राहक का प्रथम नाम दर्ज करें",
    pleaseEnterCustomerLastName: "कृपया ग्राहक का उपनाम दर्ज करें",
    pleaseEnterCustomerPhoneNumber: "कृपया ग्राहक का फ़ोन नंबर दर्ज करें",
    pleaseEnterCustomerEmail: "कृपया ग्राहक का ईमेल दर्ज करें",
    pleaseEnterCustomerAddressLine: "कृपया ग्राहक की पता पंक्ति दर्ज करें",
    pleaseEnterCustomerOtherAddress: "कृपया ग्राहक का अन्य पता दर्ज करें",
    pleaseSelectCustomerGender: "कृपया ग्राहक का लिंग चुनें",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    bank: "बैंक",
    selectBank: "बैंक चुनें",
    stateOrRegionOrProvince: "राज्य/क्षेत्र/प्रांत",
    customerNotes: "ग्राहक नोट",
    sendSms: "संदेश भेजो",
    editCustomer: "ग्राहक संपादित करें",
    redeemReward: "रिडीम रिवॉर्ड",
    issueLoyaltyCard: "लॉयल्टी कार्ड जारी करें",
    deleteCustomer: "ग्राहक हटाएं",
    youveSuccessfullyAssignedLoyaltyMembership:
      "आपने लॉयल्टी सदस्यता आईडी सफलतापूर्वक असाइन कर दी है",
    noMembershipIdAvailable:
      "कोई सदस्यता आईडी उपलब्ध नहीं है। कृपया हमसे hello@loystar.co पर संपर्क करें",
    sendEmail: "ईमेल भेजें",
    membershipPoints: "सदस्यता अंक",
    customerDetails: "उपभोक्ता विवरण",
    close: "बंद करे",
    loyaltyBalance: "वफादारी संतुलन",
    pointsBalance: "अंक संतुलन",
    starBalance: "स्टार बैलेंस",
    requiredPoints: "आवश्यक अंक",
    requiredStars: "आवश्यक सितारे",
    reddemCode: "रीडीम कोड",
    toDeleteThisCustomer: "इस ग्राहक को हटाने के लिए",
    customerHasBeenDeletedSuccessfully:
      "ग्राहक को सफलतापूर्वक हटा दिया गया है!",
    customerWithPhoneAlreadyExists: "फ़ोन नंबर वाला ग्राहक पहले से मौजूद है",
    customerWasSuccessfullyEdited: "ग्राहक को सफलतापूर्वक संपादित किया गया था",
    anErrorOccured: "एक त्रुटि हुई",
    phoneNumberIncludeCountry: "फ़ोन नंबर (देश कोड शामिल करें)",
    yourFileQueuedForUpload:
      "आपकी फ़ाइल अपलोड के लिए कतार में है। कृपया कुछ सेकंड के बाद पेज को रिफ्रेश करें।",
    thereWasAnErrorPerformingOperation: "ऑपरेशन करने में एक त्रुटि हुई!",
    pleaseSelectFile: "कृपया एक फ़ाइल चुनें!",
    oopsOnlyCsvFilesAllowed:
      "उफ़! केवल सीएसवी फाइलों की अनुमति है। कृपया एक .csv फ़ाइल अपलोड करें।",
    fileShouldBeSmallerThan5Mb:
      "फ़ाइल 5MB से छोटी होनी चाहिए. यदि आपके पास बड़ी फ़ाइल है, तो कृपया support@loystar.co पर ईमेल करें। शुक्रिया",
    customerFirstNameIsRequired: "पंक्ति में ग्राहक का प्रथम नाम आवश्यक है",
    customerPhoneNumberIsRequired: "पंक्ति में ग्राहक का फ़ोन नंबर आवश्यक है",
    importCustomers: "आयात ग्राहक",
    upload: "डालना",
    clickIconToDownloadCsv:
      "CSV फ़ाइल टेम्प्लेट डाउनलोड करने के लिए इस आइकन पर क्लिक करें।",
    getGoogleSheetVersion: "Google शीट संस्करण प्राप्त करें",
    clickOrDragFileToUpload:
      "अपलोड करने के लिए फ़ाइल को इस क्षेत्र में क्लिक करें या खींचें",
    missingOrInvalidColumnHeader:
      "अनुपलब्ध या अमान्य स्तंभ शीर्षलेख. कृपया टेम्पलेट प्रारूप का पालन करें। धन्यवाद।",
    youHaveImported: "आपने आयात किया है",
    youSuccessfullyRedeemedReward: "आपने अपना इनाम सफलतापूर्वक भुना लिया है!",
    sixDigitCode: "छह अंकों का कोड",
    pleaseEnterCustomerRewardCode: "कृपया ग्राहक का इनाम कोड दर्ज करें",
    enterRewardCode: "इनाम कोड दर्ज करें। (इनाम कोड केस संवेदनशील है)",
    selectLoyaltyProgram: "लॉयल्टी प्रोग्राम चुनें",
    stamps: "टिकटों",
    smsUnitsLow: "एसएमएस इकाइयाँ कम",
    pleaseTopUpSmsUnits: "कृपया टॉप अप एसएमएस इकाइयां",
    smsSuccessfullySentForDelivery:
      "डिलीवरी के लिए एसएमएस सफलतापूर्वक भेजा गया!",
    sendSmsTo: "को एसएमएस भेजें",
    allCustomers: "सभी ग्राहक",
    unitsAvailable: "उपलब्ध इकाइयाँ",
    pleaseTypeInTheMessage: "कृपया संदेश टाइप करें",
    message: "संदेश",
    charactersRemaining: "शेष वर्ण",
    avoidUseOfSpecialCharacters:
      "एसएमएस इकाइयों को संरक्षित करने के लिए विशेष वर्णों और इमोजी के उपयोग से बचें।",
    note: "ध्यान दें",
    errorFetchingCustomersMultilevelDetail:
      "ग्राहकों को बहुस्तरीय विवरण प्राप्त करने में त्रुटि",
    search: "खोज",
    reset: "रीसेट",
    importCustomer: "आयात ग्राहक",
    addNewCustomer: "नया ग्राहक जोड़ें",
    sendSmsBroadcast: "एसएमएस प्रसारण भेजें",
    totalCustomers: "कुल ग्राहक",
    disbursementDetails: "संवितरण विवरण",
    paidBy: "द्वारा भुगतान",
    disbursed: "संवितरित",
    bankAccountName: "बैंक खाता नाम",
    bankAccountNumber: "बैंक खाता संख्या",
    transferInitiated: "स्थानांतरण शुरू किया गया",
    transferCompleted: "स्थानांतरण पूर्ण",
    pleaseEnterAValid: "कृपया कोई मान्य दर्ज करें",
    begin: "शुरू",
    end: "समाप्त",
    date: "दिनांक",
    paymentDate: "भुगतान तिथि",
    selectDisbursementDuration: "संवितरण अवधि का चयन करें",
    totalSettled: "कुल बसे",
    totalUnsettled: "कुल अस्थिर",
    toDeleteThisSale: "इस बिक्री को हटाने के लिए",
    draftSaleDeletedSuccessfully: "ड्राफ़्ट बिक्री सफलतापूर्वक हटा दी गई है!",
    deleteSale: "बिक्री हटाएं",
    pleaseTypeInYourTotal:
      "हटाने की पुष्टि करने के लिए कृपया अपना कुल टाइप करें",
    billDetails: "बिल विवरण",
    printBill: "प्रिंट बिल",
    servedBy: "द्वारा सेवा",
    total: "कुल",
    createdDate: "रचना तिथि",
    createdTime: "बनाया गया समय",
    status: "स्थिति",
    loginSuccessful: "लॉग इन सफल",
    pleaseWaitWhileWeConnectAccount:
      "कृपया प्रतीक्षा करें जब तक हम आपका खाता कनेक्ट करते हैं",
    connectionFailedTryAgain: "कनेक्शन विफल। कृपया पुन: प्रयास करें।",
    connectionSuccessful: "कनेक्शन सफल",
    viewDetails: "विवरण देखें",
    enable: "सक्षम",
    free: "नि: शुल्क",
    cost: "लागत",
    visitWebsite: "बेवसाइट देखना",
    pleaseUpgradeYourPlanToPro:
      "कृपया इस ऐप को सक्षम करने के लिए अपनी योजना को प्रो प्लस में अपग्रेड करें",
    connectYourBankAccount:
      "भुगतान प्राप्त करने में सक्षम होने के लिए अपना बैंक खाता कनेक्ट करें।",
    disable: "अक्षम करना",
    enableApp: "ऐप सक्षम करें",
    addNewProductToInvoice: "चालान में नया उत्पाद जोड़ें",
    toDeleteThisInvoice: "इस चालान को हटाने के लिए",
    invoiceDeletedSuccessfully: "चालान सफलतापूर्वक हटा दिया गया है!",
    deleteInvoice: "चालान हटाएं",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "आपने सफलतापूर्वक एक चालान भुगतान संदेश जोड़ लिया है!",
    pleaseEnterPaymentInstructions: "कृपया भुगतान निर्देश दर्ज करें",
    invoiceId: "चालान आईडी",
    paidDate: "भुगतान तिथि",
    reference: "संदर्भ",
    productAdded: "उत्पाद जोड़ा गया",
    productOutOfStock: "उत्पाद स्टॉक में नहीं है। कृपया पुनः स्टॉक करें।",
    totalInvoices: "कुल चालान",
    totalPaidInvoices: "कुल भुगतान चालान",
    totalUnpaidInvoices: "कुल अवैतनिक चालान",
    loyaltyProgramDeleted: "लॉयल्टी प्रोग्राम मिटाया गया",
    thisLoyaltyProgramDeletedSuccessfully:
      "यह लॉयल्टी कार्यक्रम सफलतापूर्वक हटा दिया गया है",
    thisLoyaltyProgramEditedSuccessfully:
      "इस लॉयल्टी कार्यक्रम को सफलतापूर्वक संपादित किया गया है",
    loyaltyProgramAddedSuccessfully:
      "वफादारी कार्यक्रम सफलतापूर्वक जोड़ा गया है",
    loyaltyProgramEdited: "वफादारी कार्यक्रम संपादित",
    loyaltyProgramAdded: "वफादारी कार्यक्रम जोड़ा गया",
    loyaltyDetails: "वफादारी विवरण",
    doYouWantToCloseDialog: "क्या आप इस संवाद को बंद करना चाहते हैं?",
    pleaseEnterLoyaltyName: "कृपया अपनी वफादारी का नाम दर्ज करें",
    programType: "कार्यक्रम का प्रकार",
    pleaseSelectProgramType: "कृपया एक प्रोग्राम प्रकार चुनें",
    simplePoints: "सरल अंक",
    stampsProgram: "टिकट कार्यक्रम",
    threshold: "सीमा",
    pleaseEnterLoyaltyThreshold: "कृपया लॉयल्टी सीमा दर्ज करें",
    reward: "इनाम",
    pleaseEnterLoyaltyReward: "कृपया वफादारी का इनाम दर्ज करें",
    totalUserPoints: "कुल उपयोगकर्ता अंक",
    totalUserStamps: "कुल उपयोगकर्ता टिकट",
    spendingTarget: "खर्च का लक्ष्य",
    spendingTargetHint1:
      "खर्च करने का लक्ष्य यह है कि इनाम पाने के लिए ग्राहक को कितना खर्च करना चाहिए। 1 मुद्रा मूल्य = 1 अंक।",
    spendingTargetHint2:
      "टिकटों का लक्ष्य यह है कि इनाम पाने के लिए ग्राहक को कितने स्टैम्प जमा करने होंगे। उदाहरण के लिए 5",
    addNewLoyaltyProgram: "नया वफादारी कार्यक्रम जोड़ें",
    addLoyaltyProgram: "वफादारी कार्यक्रम जोड़ें",
    loyaltyProgramType: "वफादारी कार्यक्रम प्रकार",
    pleaseSelectLoyaltyProgramType: "कृपया लॉयल्टी कार्यक्रम प्रकार चुनें",
    nameOfProgram: "कार्यक्रम का नाम",
    pleaseEnterProgramName: "कृपया प्रोग्राम का नाम दर्ज करें",
    whatIsTheReward: "इनाम क्या है?",
    egNextPurchaseIsFree: "उदा. अगली खरीदारी मुफ़्त है",
    customerName: "ग्राहक का नाम",
    guestCustomer: "अतिथि ग्राहक",
    orderConfirmedSuccessfully: "आदेश की पुष्टि सफलतापूर्वक हुई",
    orderCancelledSuccessfully: "आदेश सफलतापूर्वक रद्द किया गया",
    confirmOrder: "आदेश की पुष्टि",
    cancelOrder: "आदेश रद्द",
    allOrders: "सभी आदेश",
    totalOrders: "कुल आदेश",
    doYouWantToAcceptOrder: "क्या आप इस आदेश को स्वीकार करना चाहते हैं?",
    doYouWantToCancelOrder: "क्या आप इस आदेश को रद्द करना चाहते हैं?",
    orderDetails: "ऑर्डर का विवरण",
    orderCreatedAt: "आदेश बनाया गया",
    supplier: "प्रदायक",
    productName: "प्रोडक्ट का नाम",
    quantity: "मात्रा",
    unitPrice: "यूनिट मूल्य",
    receivedBy: "द्वारा प्राप्त",
    reportFrom: "से रिपोर्ट करें",
    totalSupplies: "कुल आपूर्ति",
    allRightsReserved: "सर्वाधिकार सुरक्षित",
    toDeleteThisTransaction: "इस लेनदेन को हटाने के लिए",
    transactionDeletedSuccessfully:
      "लेन-देन सफलतापूर्वक हटा दिया गया है। स्टॉक को इन्वेंट्री में वापस कर दिया गया है।",
    deleteTransaction: "लेन-देन हटाएं",
    transactionDetails: "लेनदेन का विवरण",
    printReceipt: "प्रिंट रसीद",
    channel: "चैनल",
    discount: "छूट",
    profit: "फायदा",
    discountedSales: "रियायती बिक्री",
    errorFetchingRecord: "रिकॉर्ड लाने में त्रुटि",
    exportTransactions: "निर्यात लेनदेन",
    errorFetchingSalesRecord: "निर्यात के लिए बिक्री रिकॉर्ड लाने में त्रुटि।",
    totalSellingPrice: "कुल बिक्री मूल्य",
    totalCostPrice: "कुल लागत मूल्य",
    appliedDiscount: "लागू छूट",
    noOfItems: "मदों की संख्या",
    sales: "बिक्री",
    export: "निर्यात",
    totalProfit: "कुल लाभ",
    totalBalanceInPeriod: "अवधि में कुल शेष",
    allTimeSales: "ऑल टाइम सेल्स",
    records: "अभिलेख",
    todaysSales: "आज की बिक्री",
    transaction: "लेन - देन",
    youHaveExceededTotalNumberOfProducts:
      "आपने अपनी योजना में अनुमत उत्पादों की कुल संख्या को पार कर लिया है। उच्च सीमा का आनंद लेने के लिए अपनी योजना को अपग्रेड करें।",
    youNeedToHaveLoyaltyProgram:
      "इस सुविधा का उपयोग करने के लिए आपके पास एक वफादारी कार्यक्रम होना चाहिए!",
    price: "कीमत",
    category: "श्रेणी",
    stockTracking: "स्टॉक ट्रैकिंग",
    stockCount: "शेयर गिनती",
    taxed: "कर लगाया",
    originalPrice: "मूल कीमत",
    costPrice: "लागत मूल्य",
    unit: "इकाई",
    productImage: "उत्पाद का चित्र",
    taxRate: "कर की दर",
    taxType: "कर प्रकार",
    trackInventory: "ट्रैक इन्वेंटरी",
    variants: "वेरिएंट",
    hasVariants: "वेरिएंट हैं",
    importProduct: "आयात उत्पाद",
    exportProducts: "निर्यात उत्पाद",
    addNewProduct: "नया उत्पाद जोड़ें",
    viewCategory: "श्रेणी देखें",
    viewSuppliers: "आपूर्तिकर्ता देखें",
    receiveInventory: "इन्वेंटरी प्राप्त करें",
    printAllProductsTag: "सभी उत्पादों को प्रिंट करें टैग",
    deleteAll: "सभी हटा दो",
    totalProducts: "कुल उत्पाद",
    youveSuccessfullyAddedANewCategory:
      "आपने सफलतापूर्वक एक नई श्रेणी जोड़ ली है",
    addNewCategory: "नई श्रेणी जोड़ें",
    addCategory: "कैटेगरी जोड़े",
    categoryName: "श्रेणी नाम",
    pleaseEnterCategoryName: "कृपया श्रेणी का नाम दर्ज करें",
    stampsTarget: "टिकट लक्ष्य",
    sendInventory: "इन्वेंटरी भेजें",
    productDetails: "उत्पाद विवरण",
    youveSuccessfullyEditedThisCategory:
      "आपने इस श्रेणी को सफलतापूर्वक संपादित कर लिया है",
    update: "अद्यतन",
    categoryList: "श्रेणी सूची",
    categories: "श्रेणियाँ",
    enterQuantityToUpdate: "अपडेट करने के लिए मात्रा दर्ज करें",
    inventorySentSuccessfully: "इन्वेंट्री सफलतापूर्वक भेज दी गई थी!",
    updateInventory: "अद्यतन सूची",
    currentQuantity: "वर्तमान मात्रा",
    pleaseEnterQuantityToAdd:
      "कृपया वह मात्रा दर्ज करें जिसे आप जोड़ना चाहते हैं",
    pleaseSelectABranch: "कृपया एक शाखा चुनें",
    searchForBranch: "शाखा के लिए खोजें",
    youCantSendMoreThanStock:
      "आपके पास जितना स्टॉक है उससे अधिक आप नहीं भेज सकते",
    send: "भेजना",
    pleaseEnterQuantityToSend:
      "कृपया वह मात्रा दर्ज करें जिसे आप भेजना चाहते हैं",
    productNameIsRequiredOnRow: "पंक्ति में उत्पाद का नाम आवश्यक है",
    productCategoryIsRequiredOnRow: "उत्पाद श्रेणी पंक्ति पर आवश्यक है",
    productPriceIsRequiredOnRow: "उत्पाद की कीमत पंक्ति में आवश्यक है",
    productUnitIsRequiredOnRow: "पंक्ति में उत्पाद इकाई आवश्यक है",
    productQuantityIsRequiredOnRow: "पंक्ति में उत्पाद की मात्रा आवश्यक है",
    productVariantsRequireTracking:
      "उत्पाद प्रकारों को ट्रैक करने की आवश्यकता है!",
    pleaseAddVariantClickButton:
      "कृपया जोड़ें बटन पर क्लिक करके एक प्रकार जोड़ें!",
    totalVariantsMoreThanSelectedQuantity:
      "कुल प्रकार चयनित उत्पाद मात्रा से अधिक हैं, कृपया विविधता मात्रा कम करें",
    productEditedSuccessfully: "उत्पाद सफलतापूर्वक संपादित किया गया है!",
    fileTooLargeLessThan4Mb:
      "फ़ाइल का आकार बहुत बड़ा है! फ़ाइल का आकार 4MB से कम होना चाहिए।",
    suchVariantAlreadyExist: "ऐसा वैरिएंट पहले से मौजूद है",
    addVariants: "वेरिएंट जोड़ें",
    editProduct: "उत्पाद संपादित करें",
    pleaseEnterProductName: "कृपया उत्पाद का नाम दर्ज करें",
    pleaseEnterProductPrice: "कृपया उत्पाद मूल्य दर्ज करें",
    pleaseEnterProductOriginalPrice: "कृपया उत्पाद की मूल कीमत दर्ज करें",
    productDescription: "उत्पाद वर्णन",
    selectProductCategory: "उत्पाद श्रेणी का चयन करें",
    pleaseSelectProductCategory: "कृपया उत्पाद श्रेणी चुनें",
    productCostPrice: "उत्पाद लागत मूल्य",
    productSellingPrice: "उत्पाद बेचना मूल्य",
    productOriginalPrice: "उत्पाद मूल मूल्य",
    maxFileSizeAllowedIs4Mb: "मैक्स। फ़ाइल आकार की अनुमति 4mb . है",
    productsWithPicturesArePublished:
      "चित्रों वाले उत्पाद ऑर्डर प्राप्त करने के लिए डिस्कवर पर प्रकाशित किए जाते हैं",
    shouldThisProductBeTracked: "क्या इस उत्पाद को ट्रैक किया जाना चाहिए?",
    pleaseSelectStockUnit: "कृपया स्टॉक इकाई का चयन करें",
    stockUnit: "स्टॉक यूनिट",
    bag: "थैला",
    bottle: "बोतल",
    bunch: "झुंड",
    can: "कर सकते हैं",
    carton: "दफ़्ती",
    crate: "टोकरा",
    cup: "कप",
    dozen: "दर्जन",
    gigabytes: "गीगाबाइट",
    gram: "चना",
    kilogram: "किलोग्राम",
    litre: "लीटर",
    pack: "पैक",
    pair: "जोड़ा",
    pieces: "टुकड़े टुकड़े",
    plate: "प्लेट",
    tonne: "टन (एमटी)",
    uNIT: "इकाई",
    yard: "यार्ड",
    pleaseEnterProductQuantity: "कृपया उत्पाद मात्रा दर्ज करें",
    productQuantity: "उत्पाद गुणवत्ता",
    isThisProductTaxed: "क्या इस उत्पाद पर कर लगाया गया है?",
    selectTaxType: "कर प्रकार चुनें",
    pleaseSelectTaxType: "कृपया कर प्रकार चुनें",
    progressive: "प्रगतिशील",
    proportional: "आनुपातिक",
    pleaseEnterProductTaxRate: "कृपया उत्पाद कर की दर दर्ज करें",
    doesProductHaveVariants: "क्या इस उत्पाद के वेरिएंट हैं?",
    type: "प्रकार",
    value: "मूल्य",
    pleaseEnterVariantType: "कृपया प्रकार का प्रकार दर्ज करें",
    pleaseEnterVariantValue: "कृपया विविधता का मान दर्ज करें",
    pleaseEnterVariantPrice: "कृपया प्रकार की कीमत दर्ज करें",
    pleaseEnterVariantQuantity: "कृपया प्रकार की मात्रा दर्ज करें",
    productDeletedSuccessfully: "उत्पाद सफलतापूर्वक हटा दिया गया है!",
    categoryDeletedSuccessfully: "श्रेणी को सफलतापूर्वक हटा दिया गया है!",
    toDeleteThisProduct: "इस उत्पाद को हटाने के लिए",
    invalidProductQuantity: "अमान्य उत्पाद मात्रा",
    quantityAddedIsOverStock:
      "आप जो मात्रा जोड़ रहे हैं वह आपके स्टॉक में मौजूद मात्रा से अधिक है।",
    itemInventoryNotTracked: "आइटम इन्वेंट्री ट्रैक नहीं की जाती है",
    addBulkQuantity: "थोक मात्रा जोड़ें",
    enterBulkQuantity: "थोक मात्रा दर्ज करें",
    pleaseEnterBulkQuantity: "कृपया थोक मात्रा दर्ज करें",
    youveSuccessfullyAddedANewProduct:
      "आपने सफलतापूर्वक एक नया उत्पाद जोड़ लिया है!",
    pleaseEnterProductSellingPrice: "कृपया उत्पाद विक्रय मूल्य दर्ज करें",
    doYouWantToTrackProductStock:
      "क्या आप उत्पाद स्टॉक को ट्रैक करना चाहते हैं?",
    sellingPrice: "विक्रय मूल्य",
    setProductExpiryReminder: "उत्पाद समाप्ति अनुस्मारक सेट करें",
    productExpiryDate: "उत्पाद समाप्ति तिथि",
    startRemindingFrom: "से याद दिलाना शुरू करें",
    productSuppliesAddedSuccessfully:
      "आपने उत्पाद आपूर्ति सफलतापूर्वक जोड़ ली है।",
    addProductSupplies: "उत्पाद आपूर्ति जोड़ें",
    pleaseSelectSupplier: "कृपया आपूर्तिकर्ता का चयन करें",
    nameOfProduct: "उत्पाद का नाम",
    pleaseSelectProduct: "कृपया उत्पाद चुनें",
    productVariant: "उत्पाद प्रकार",
    pleaseSelectAVariant: "कृपया एक प्रकार चुनें",
    pleaseEnterUnitPrice: "कृपया इकाई मूल्य दर्ज करें",
    businessBranch: "व्यापार शाखा",
    pleaseSelectBranch: "कृपया शाखा का चयन करें",
    youveSuccessfullyAddedANewSupplier:
      "आपने सफलतापूर्वक एक नया आपूर्तिकर्ता जोड़ लिया है",
    addSupplier: "आपूर्तिकर्ता जोड़ें",
    pleaseEnterSupplierEmail: "कृपया आपूर्तिकर्ता का ईमेल दर्ज करें",
    pleaseAddADescription: "कृपया विवरण जोड़ें",
    anErrorOccuredProductsDeleted:
      "कार्रवाई करते समय एक त्रुटि हुई। कृपया ध्यान दें, हो सकता है कि इस प्रक्रिया में कुछ उत्पाद हटा दिए गए हों",
    bulkDelete: "बल्क डिलीट",
    youAreAboutToDelete: "आप मिटाने वाले हैं",
    product: "उत्पाद",
    isDueToRedeem: "भुनाने के कारण है",
    aReward: "इनाम",
    pleaseSelectCustomerToReeemReward:
      "इनाम रिडीम करने के लिए कृपया एक ग्राहक चुनें।",
    youHaveNoLoyaltyProgramRunning:
      "आपका कोई सक्रिय लॉयल्टी कार्यक्रम नहीं चल रहा है",
    customerHhasNoPointsInLoyaltyProgram:
      "इस लॉयल्टी कार्यक्रम में ग्राहक का कोई अंक नहीं है",
    proceedWithPayment: "भुगतान के साथ आगे बढ़ें?",
    youAreAboutToPayForTransactionUsingPoints:
      "आप पॉइंट्स का उपयोग करके लेनदेन के लिए भुगतान करने वाले हैं।",
    customerHas: "ग्राहक है",
    worth: "लायक",
    andIsNotSufficientToPayForTransaction:
      "और इस लेनदेन के लिए भुगतान करने के लिए पर्याप्त नहीं है। क्या वे किसी अन्य भुगतान विधि का उपयोग करके शेष राशि जोड़ना चाहेंगे?",
    addCustomerLoyalty: "ग्राहक वफादारी जोड़ें",
    pleaseAddCustomerFirst: "कृपया पहले एक ग्राहक जोड़ें या चुनें।",
    pleaseWaitWhileWeGetReady: "कृपया प्रतीक्षा करें, जब तक हम तैयार हो जाएं",
    lowStock: "कम स्टॉक",
    pleaseEnterAmountTendered: "कृपया निविदा की गई राशि दर्ज करें",
    areYouSureToBookSaleOffline:
      "क्या आप वाकई इस बिक्री को ऑफ़लाइन बुक करना चाहते हैं?",
    saleWouldBeBookedAutomatically:
      "जब आप अपना इंटरनेट चालू करेंगे तो बिक्री अपने आप बुक हो जाएगी",
    offlineSalesBookingCancelled: "ऑफलाइन बिक्री बुकिंग रद्द",
    covid19Message:
      "COVID19: प्रसार को रोकने के लिए अपने हाथों को साबुन से धोएं या साफ करें। हमेशा सुरक्षित रहें",
    saleSuccessfullyRecorded: "बिक्री सफलतापूर्वक रिकॉर्ड की गई!",
    sendReceiptToEmail: "ईमेल पर रसीद भेजें",
    sendThankYouSms: "धन्यवाद एसएमएस भेजें",
    sendShippingDetails: "शिपिंग विवरण भेजें",
    addLoyalty: "वफादारी जोड़ें",
    searchCustomerNameOrNumber: "ग्राहक का नाम या नंबर खोजें",
    clickTheSearchCustomerBox: "खोज ग्राहक बॉक्स और स्कैन कार्ड पर क्लिक करें",
    enterProductPrice: "उत्पाद मूल्य दर्ज करें",
    enterPriceFor: "के लिए मूल्य दर्ज करें",
    searchForProduct: "उत्पाद के लिए खोजें",
    all: "सभी",
    backToDashboard: "डैशबोर्ड पर वापस जाएं",
    viewDraftSales: "ड्राफ्ट बिक्री देखें",
    variantSelected: "वैरिएंट चुना गया",
    variant: "प्रकार",
    thePreviousVariantSelectionNotAvailable:
      "कीमत के आधार पर चुने गए नए प्रकार के लिए पिछला संस्करण चयन उपलब्ध नहीं है, कृपया अपना चयन बदलें।",
    pleaseSupplyPositiveQuantityNumber:
      "कृपया एक पोस्टिव मात्रा संख्या प्रदान करें",
    lowStockFor: "के लिए कम स्टॉक",
    clearVariants: "स्पष्ट प्रकार",
    pleaseEnterQuantity: "कृपया मात्रा दर्ज करें",
    picture: "चित्र",
    redemptionToken: "मोचन टोकन",
    token: "टोकन",
    totalSpent: "कुल खर्च किया",
    confirmPayment: "भुगतान की पुष्टि करें",
    hasPaymentBeenMade: "क्या भुगतान सफलतापूर्वक संसाधित किया गया है?",
    enterTransactionReference:
      "आपके द्वारा भुगतान किया गया लेन-देन संदर्भ दर्ज करें",
    pleaseSelectACustomer: "कृपया एक ग्राहक चुनें!",
    areYouSureToApplyDiscount: "क्या आप वाकई छूट लागू करना चाहते हैं?",
    addYourBankAccountToEnableUssd:
      "uPay द्वारा तत्काल यूएसएसडी हस्तांतरण सक्षम करने के लिए अपना बैंक खाता जोड़ें",
    totalAmountToPay: "भुगतान करने के लिए कुल राशि",
    doYouWantToCancelTransaction: "क्या आप इस लेन-देन को रद्द करना चाहते हैं?",
    savePrintBill: "सेव/प्रिंट बिल",
    enterAmountCollectedForCustomer: "ग्राहक के लिए एकत्रित राशि दर्ज करें",
    recordSale: "रिकॉर्ड बिक्री",
    checkOutWith: "के साथ चेक आउट करें",
    instantTransfer: "तत्काल हस्तांतरण",
    dialTheUSSDCode: "यूएसएसडी कोड डायल करें",
    pleaseSelectABank: "कृपया एक बैंक चुनें",
    payWithUSSD: "यूएसएसडी के साथ भुगतान करें",
    sendBillTo: " - बिल भेजें",
    waitingForUSSDTransfer: "यूएसएसडी ट्रांसफर की प्रतीक्षा में",
    percent: "प्रतिशत",
    applyDiscount: "छूट लागू करें",
    thisBillHasBeenSaved: "यह विधेयक सहेज लिया गया है",
    saveDraft: "मसौदा सेव करें",
    pleaseTypeANameToIdentifyCustomer:
      "कृपया ग्राहक की पहचान करने के लिए एक नाम टाइप करें",
    paymentDetails: "भुगतान विवरण",
    basePrice: "आधार मूल्य",
    staff: "कर्मचारी",
    subTotal: "उप-योग",
    durationMonths: "अवधि (महीने)",
    selectADuration: "एक अवधि चुनें",
    oneMonth: "1 महीना",
    twoMonths: "2 महीने",
    threeMonths: "3 महीने",
    sixMonths: "6 महीने",
    twelveMonths: "12 महीने",
    eighteenMonths: "18 महीने",
    twentyFourMonths: "24 माह",
    twoMonthsFree: "(२ महीने मुफ्त)",
    threeMonthsFree: "(३ महीने मुफ्त)",
    fiveMonthsFree: "(५ महीने मुफ्त)",
    yourAccountHasBeen: "आपका खाता हो गया है",
    renewed: "नवीकृत",
    upgraded: "उन्नत बनाया",
    successfully: "सफलतापूर्वक",
    yourSubscription: "आपकी सदस्यता",
    youAreCurrentlyEnrolledOnA: "आप वर्तमान में a . पर नामांकित हैं",
    pleaseChooseAPaymentOption: "कृपया भुगतान विकल्प चुनें",
    planRenewal: "योजना नवीनीकरण",
    planUpgrade: "योजना उन्नयन",
    pleaseSelectDurationToPayFor:
      "कृपया उस अवधि का चयन करें जिसके लिए आप भुगतान करना चाहते हैं",
    staffAccounts: "कर्मचारी खाते",
    ecommerce: "ई-कॉमर्स",
    pleaseSelectAPlan: "कृपया एक योजना चुनें",
    includeAddons: "ऐड-ऑन शामिल करें",
    viewTransactions: "लेन-देन देखें",
    customerHasNoCompletedTansactions:
      "ग्राहक ने अभी तक कोई पूर्ण लेनदेन नहीं किया है",
    branch: "डाली",
    enterNumberOfEcommerceBranches: "ईकॉमर्स शाखाओं की संख्या दर्ज करें",
    enterNumberOfEcommerceBranchesToPay:
      "उन ईकॉमर्स शाखाओं की संख्या दर्ज करें जिनके लिए आप भुगतान करना चाहते हैं",
    ecommerceIntegration: "ईकॉमर्स एकीकरण",
    enterNumberOfBranches: "शाखाओं की संख्या दर्ज करें",
    enterNumberOfAdditionalBranchesToPay:
      "उन अतिरिक्त शाखाओं की संख्या दर्ज करें जिनके लिए आप भुगतान करना चाहते हैं",
    enterNumberOfStaffs: "कर्मचारियों की संख्या दर्ज करें",
    enterNumberOfStaffsToPayFor:
      "उन कर्मचारियों की संख्या दर्ज करें जिनके लिए आप भुगतान करना चाहते हैं",
    discountApplies: "छूट लागू होती है",
    starsOnThe: "पर सितारे",
    offer: "प्रस्ताव",
    get: "पाना",
    moreStarsToRedeem: "भुनाने के लिए और सितारे",
    taxVat: "कर (वैट)",
    callCashierToCompletePayment: "भुगतान पूरा करने के लिए कैशियर को कॉल करें",
    receipt: "रसीद",
    dear: "प्रिय",
    thankYouForYourOrderFindGoods:
      "आपके ऑर्डर के लिए धन्यवाद। कृपया सहमति के अनुसार आपूर्ति की गई निम्नलिखित वस्तुओं का पता लगाएं।",
    shippingNote: "जलयात्रा लेख",
    enterShippingNote: "शिपिंग नोट दर्ज करें",
    shippingAddress: "शिपिंग पता",
    enterShippingAddress: "शिपिंग पता दर्ज करें",
    wellDoneYouAreDueToRedeem: "बहुत बढ़िया! आप रिडीम करने वाले हैं",
    toGetYourRewardNextVisit:
      "अपनी अगली यात्रा पर अपना इनाम पाने के लिए। शुक्रिया",
    pointsOnThe: "पर अंक",
    morePointsToRedeem: "भुनाने के लिए अधिक अंक",
    showCode: "कोड दिखाएं",
    toGetYourRewardOnNextVisit:
      "अपनी अगली यात्रा पर अपना इनाम पाने के लिए। शुक्रिया",
    earn: "कमाना",
    delivaryNote: "डिलीवरी नोट",
    draftSales: "ड्राफ्ट बिक्री",
    startDate: "आरंभ करने की तिथि",
    endDate: "अंतिम तिथि",
    orders: "आदेश",
    checkout: "चेक आउट",
    noProductItem: "कोई उत्पाद आइटम नहीं",
    selectProductImage: "उत्पाद छवि का चयन करें",
    selectCountry: "देश चुनें",
    selectRegion: "राज्य/क्षेत्र चुनें",
    printProductTag: "प्रिंट उत्पाद टैग",
    transactionReference: "लेनदेन संदर्भ",
    Cashier: "केशियर",
    Manager: "प्रबंधक",
    Owner: "मालिक",
    Admin: "व्यवस्थापक",
    addPartners: "भागीदार जोड़ें",
    addNewLoyaltyPartner: "नया लॉयल्टी पार्टनर जोड़ें",
    pleaseEnterCompanyName: "कृपया कंपनी का नाम दर्ज करें",
    companyName: "कंपनी का नाम",
    pleaseEnterCompanyRepName: "कृपया कंपनी प्रतिनिधि का नाम दर्ज करें",
    companyRepName: "कंपनी प्रतिनिधि का नाम",
    pleaseEnterCompanyRepEmail: "कृपया कंपनी के प्रतिनिधि का ईमेल दर्ज करें",
    companyRepEmail: "कंपनी प्रतिनिधि का ईमेल",
    pleaseEnterCompanyRepPhone: "कृपया कंपनी प्रतिनिधि का फ़ोन नंबर दर्ज करें",
    companyRepPhone: "कंपनी प्रतिनिधि का फोन नंबर",
    addReward: "इनाम जोड़ें",
    pleaseEnterRewardName: "कृपया इनाम का नाम दर्ज करें",
    rewardName: "इनाम का नाम",
    rewardQuantity: "इनाम मात्रा",
    rewardDescription: "इनाम विवरण",
    rewardType: "इनाम का प्रकार",
    pleaseEnterRewardType: "कृपया इनाम का प्रकार दर्ज करें",
    pleaseEnterRewardQuantity: "कृपया इनाम की मात्रा दर्ज करें",
    pleaseEnterRewardDescription: "कृपया इनाम विवरण दर्ज करें",
    fineDining: "ठीक भोजन",
    luxuryFashion: "विलासिता फैशन",
    hotels: "होटल",
    travel: "यात्रा",
    foodAndBeverage: "खाद्य और पेय पदार्थ",
    fashion: "पहनावा",
    health: "स्वास्थ्य",
    furniture: "फर्नीचर",
    entertainment: "मनोरंजन",
    automobile: "ऑटोमोबाइल",
    education: "शिक्षा",
    beautyAndSpa: "सौंदर्य और स्पा",
    staycation: "ठहराव",
    events: "आयोजन",
    trips: "ट्रिप्स",
    oilAndGas: "तेल और गैस",
    laundry: "धोबीघर",
    partnerCategory: "साथी श्रेणी",
    freeItem: "मुफ़्त आइटम",
  },
  Hungarian: {
    cashier: "pénztáros",
    manager: "menedzser",
    owner: "tulajdonos",
    online: "online",
    offline: "offline",
    changePassword: "Jelszó módosítása",
    currentPasswordMessage: "Kérjük, adja meg jelenlegi jelszavát",
    passwordMessage: "Kérem írja be a jelszavát",
    currentPassword: "jelenlegi jelszó",
    password: "Jelszó",
    confirmPasswordMessage: "Kérjük, erősítse meg jelszavát!",
    confirmPassword: "Jelszó megerősítése",
    sendViaEmail: "Küldés e -mailben",
    sendViaWhatsapp: "Küldés a WhatsAppon keresztül",
    downloadPdf: "PDF letöltése",
    paid: "fizetett",
    unpaid: "Kifizetetlen",
    partial: "részleges",
    closeInvoice: "Le szeretné zárni a számlát?",
    closeInvoiceConfirmation: "A számla nem menthető. Bezárja?",
    yes: "Igen",
    no: "nem",
    invoice: "Számla",
    wasDeducted: "levonták",
    for: "számára",
    item: "Tétel",
    addProduct: "Termék hozzáadása",
    paymentReference: "Fizetési hivatkozás",
    amountPaid: "Kifizetett összeg",
    discountAmount: "Kedvezmény mértéke",
    amountDue: "Esedékes összeg",
    amount: "Összeg",
    dueDate: "Esedékesség",
    paymentType: "Fizetési mód",
    card: "Kártya",
    cash: "Készpénz",
    bankTransfer: "Banki átutalás",
    paymentMessage: "Fizetési üzenet",
    description: "Leírás",
    sendReceipt: "Nyugta küldése",
    delete: "Töröl",
    save: "Megment",
    resend: "Újraküldés",
    saveAndSend: "Megment",
    invoiceSaved: "Számla mentve!",
    selectPaymentMethod: "Kérjük, válasszon fizetési módot!",
    selectCustomer: "Kérjük, válasszon Ügyfelet!",
    cartEmptyError:
      "A kosárlista nem lehet üres, zárja be a számlát, és tegye a terméket a kosárba!",
    subscriptionExpired:
      "Előfizetésed lejárt, és fiókod korlátozott. A fiók megújításához kattintson az alábbi gombra",
    renew: "Megújítás",
    holdOn: "Kitartás",
    customerBank: "Ügyfélbank",
    cancel: "Megszünteti",
    selectACustomer: "Válassza az Ügyfél lehetőséget",
    invoiceSuccessfulPdfError:
      "A számla létrehozása sikeresen megtörtént, de a PDF generálása a szokásosnál tovább tart. Kérjük, nézzen vissza rövidesen.",
    downloadingInvoice: "Számla letöltése",
    downloadingReceipt: "Nyugta letöltése",
    whatsappReceiptError:
      "Hiba történt a nyugta WhatsApp -on keresztüli küldésekor. Kérjük, próbálja újra.",
    receiptToWhatsapp: "A nyugtát továbbítottuk a WhatsApp -ra",
    thankYouForPatronage: "Köszönöm a pártfogásodat",
    hereIsYourReceipt: "Itt a fizetési nyugtája",
    errorSendingEmailReceipt:
      "Hiba történt a nyugta e-mailben történő elküldésekor. Kérjük, próbálja újra, vagy lépjen kapcsolatba az ügyfélszolgálattal",
    receiptSentToEmail: "A nyugtát elküldtük az ügyfél e -mail címére",
    invoiceSentToEmail: "A számlát elküldtük az ügyfél e -mail címére",
    invoiceSuccessWhatsappError:
      "A számla létrehozása sikeresen megtörtént, de hiba történt a WhatsApp -ra történő küldés során. Próbálja újra a számlalistán",
    invoiceSuccessfulEmailError:
      "A számla létrehozása sikeresen megtörtént, de hiba történt e-mail küldés közben. Kérjük, próbálja újra a számla listából",
    invoiceSentToWhatsapp: "A számlát továbbították a WhatsApp -hoz",
    hello: "Helló",
    pleaseDownloadInvoice: "Kérjük, töltse le a számlát",
    pleaseDownloadReceipt: "Kérjük, töltse le a nyugtát",
    from: "tól től",
    email: "Email",
    upgrade: "Frissítés",
    youAreOnFreePlan: "Ingyenes tervvel rendelkezik.",
    clickOn: "Kattintson",
    yourPlanInFewSteps: " feliratkozását néhány gyors lépésben.",
    to: "nak nek",
    yourSubscriptionHasExpired: "Előfizetésed lejárt, és fiókod korlátozott.",
    days: "napok",
    yourSubscriptionExpiresIn: "A Loystar -előfizetés ben jár le",
    createAcount: "Hozzon létre egy fiókot",
    signIn: "Bejelentkezés",
    continue: "Folytatni",
    enterOtp: "Írja be az OTP PIN -kódot",
    enterValidOtp: "Kérjük, adjon meg érvényes PIN -kódot",
    pin: "PIN kód",
    tokenSentToMail: "A tokent elküldtük az e -mail címére",
    passwordResetSuccessful: "A jelszó visszaállítása sikeres volt",
    somethingWentWrong: "Valami elromlott!",
    resetPassword: "Jelszó visszaállítása",
    iHaveResetCode: "Van egy jelszó -visszaállító kódom",
    pleaseEnterEmail: "Kérem, írja be az e-mail címét",
    aTokenWillBeSentToEmail: "Egy jelzőt küldünk az e -mail címére",
    enterEmail: "Írd be az email címed",
    sendinYourToken: "A token küldése ...",
    makeSureItMatchesPassword:
      "Győződjön meg arról, hogy megfelel az új jelszavának",
    pleaseChooseNewPassword: "Kérjük, válasszon új jelszót",
    chooseNewPassword: "Válasszon új jelszót",
    enterNewPassword: "A megerősítéshez adja meg új jelszavát",
    enterTokenSent: "Írja be a levélben elküldött jogkivonatot",
    resetToken: "Token visszaállítása",
    resettingPassword: "Jelszó visszaállítása ...",
    signUp: "Regisztrálj",
    adminSignInWithEmail:
      " Az adminisztrátor e -mailben jelentkezik be, míg a személyzet felhasználónévvel.",
    pleaseEnterEmailOrUsername:
      "Kérjük, adja meg e -mail címét vagy felhasználónevét",
    emailOrUsername: "E-mail vagy felhasználónév",
    pleaseEnterPassword: "Kérjük, írja be a jelszót",
    createAnAccount: "Hozzon létre egy fiókot",
    forgotPassword: "Elfelejtette a jelszavát?",
    enterPhoneNumber: "Adja meg a telefonszámot",
    personalData: "Személyes adatok",
    validateConfirmationCOde: "Erősítse meg a megerősítő kódot",
    pleaseEnterFirstName: "Kérjük, adja meg keresztnevét",
    pleaseEnterPhoneNumber: "Kérlek add meg a telefonszámod",
    pleaseEnterLastName: "Kérlek írd be a vezetékneved",
    pleaseEnterBusinessName: "Kérjük, adja meg vállalkozása nevét",
    firstName: "Keresztnév",
    lastName: "Vezetéknév",
    businessName: "Vállalkozás neve",
    previous: "Előző",
    next: "Következő",
    pleaseSelectBusinessCategory:
      "Kérjük, válassza ki vállalkozási kategóriáját",
    pleaseEnterValidEmail: "Kérlek létező email címet adj meg",
    pleaseEnterPostCode: "Kérjük, adja meg az irányítószámot",
    postCode: "Irányítószám",
    phoneNumberInUse: "Ez a telefonszám már használatban van!",
    emailInUse: "Ez az e -mail már használatban van!",
    foodAndDrinks: "Étel és ital",
    salonAndBeauty: "Szalon és szépség",
    fashionAndAccessories: "Divat és kiegészítők",
    gymAndFitness: "Edzőterem és Fitness",
    travelAndHotel: "Utazás és szálloda",
    homeAndGifts: "Otthon és ajándékok",
    washingAndCleaning: "Mosás és tisztítás",
    gadgetsAndElectronics: "Modulok és elektronika",
    groceries: "Élelmiszer",
    others: "Mások",
    submit: "Beküldés",
    accountCreatedSuccessful: "Sikeresen létrehoztad a felhasználói fiókodat.",
    pleaseEnterAddress: "Kérjük, adja meg címét",
    addressLine1: "Címsor 1",
    addressLine2: "Cím sor 2",
    choosePassword: "Válassz jelszót",
    passwordMustBe6Characters:
      "A jelszónak legalább 6 karakternek kell lennie.",
    howDidYouHearLoystar: "Honnan hallott a Loystarról?",
    referralCode: "hivatkozási kód",
    byClickingTheButton: " Az alábbi gombra kattintva elfogadja a",
    termsAndSevice: "Feltételek",
    wereCreatingAccount: "Létrehozzuk a fiókját",
    proceed: "Folytassa",
    verificationCodeMustBe6: "Az ellenőrző kódnak 6 számjegyből kell állnia",
    pleaseEnter6DigitCode: "Kérjük, adjon meg 6 számjegyű kódot",
    enterVerificationCode: "Adja meg a megerősítő kódot",
    resendToken: "Token újraküldése",
    verify: "Ellenőrizze",
    transactions: "Tranzakciók",
    todaySales: "Mai értékesítés",
    salesHistory: "Értékesítési előzmények",
    supplyHistory: "Ellátási előzmények",
    new: "Új",
    invoices: "Számlák",
    disbursements: "Kifizetések",
    offlineSales: "Offline értékesítés",
    products: "Termékek",
    customers: "Ügyfelek",
    multiLevelLoyalty: "Többszintű hűség",
    loyaltyPrograms: "Hűségprogramok",
    members: "Tagok",
    appStore: "Alkalmazásbolt",
    orderMenu: "Rendelési menü",
    settings: "Beállítások",
    staffAndBranches: "Botok és fióktelepek",
    myAccount: "Fiókom",
    switchToSellMode: "Váltás Eladási módba",
    signOut: "Kijelentkezés",
    getFreeSubscription: "Ingyenes előfizetés",
    onlyNumbersAllowed: "Csak számok megengedettek",
    yourAccountMustBe10Digits: "a számlaszámnak 10 számjegyből kell állnia",
    yourBvnMustBe11: "a BVN -nek 11 számjegyű számnak kell lennie",
    pleaseSelectBank: "Kérjük, válassza ki bankját",
    selectYourBank: "Válassza ki bankját",
    enterBankAccountNumber: "Írja be a bankszámlaszámot",
    enterBvnNumber: "Adja meg a BVN -t",
    connectBankAccount: "Bankszámla csatlakoztatása",
    passwordResetSuccessfulAndSignOut:
      "Jelszava sikeresen visszaállítva. Kattintson az alábbi Kijelentkezés gombra az újbóli bejelentkezéshez",
    enterCurrentPassword: "Add meg a jelenlegi jelszavad",
    pleaseEnterCurrentPassword: "Kérjük, adja meg az aktuális jelszót",
    phoneNumber: "Telefonszám",
    sex: "Szex",
    dateOfBirth: "Születési dátum",
    state: "Állapot",
    country: "Ország",
    loyaltyId: "Hűség -azonosító",
    createdAt: "Létrehozva:",
    noOfTransactionsMade: "A végrehajtott tranzakciók száma",
    yourDownloadWillStart: "A letöltés egy pillanat múlva megkezdődik",
    exportCustomers: "Vevők exportálása",
    youhaveSuccessfullyToppedUp: "Sikeresen feltöltötte SMS -egységeit.",
    youNowHave: "Most megvan",
    smsUnits: "SMS egységek",
    enterNumberOfSmsUnits: "Írja be a megvásárolni kívánt SMS -egységek számát",
    pleaseEnterANumericValue: "Kérjük, adjon meg egy számértéket",
    pay: "Fizetés",
    accountEditedSuccessfully: "Fiókjának szerkesztése sikeresen megtörtént.",
    youAeCurrentlyOn: "Jelenleg rajta vagy",
    plan: "Terv",
    userData: "Felhasználói adat",
    businessData: "ÜZLETI ADATOK",
    businessCategory: "Üzleti Catergory",
    pleaseSelectCurrency: "Kérjük, válassza ki a pénznemet",
    selectYourCurrency: "Válassza ki a pénznemet",
    purchaseMoreSmsUnits:
      "Vásároljon további SMS -egységeket az alábbi űrlap segítségével",
    youHave: "Neked van",
    atLeast4SmsUnitsRrequired:
      "Legalább 4 sms egység szükséges az ellenőrzéshez, kérjük töltse fel!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Kérjük, ellenőrizze bankszámláját, hogy megfeleljen az „Ismerje meg ügyfelét” (KYC) követelményeinek. Ez lehetővé teszi, hogy USSD -n vagy azonnali átutalással fizetéseket gyűjtsön, megrendeléseket fogadjon az ügyfelektől, és feldolgozza a tranzakciók kiegyenlítését. Csak nigériai kereskedőkre vonatkozik. A kezdéshez nyomja meg az alábbi gombot.",
    reConnectBankAccount: "Csatlakoztassa újra a bankszámlát",
    accountName: "Felhasználónév",
    accountNumber: "Számlaszám",
    bankName: "A bank neve",
    verified: "Ellenőrzött",
    accountDetails: "Számla részletei",
    kycBankAccount: "KYC",
    createTier: "Tier létrehozása",
    fileUploadSuccessful: "fájl sikeresen feltöltve",
    fileUploadFailed: "a fájl feltöltése nem sikerült",
    createLoyaltyProgram: "Hozzon létre hűségprogramot",
    createLoyalty: "Hűség létrehozása",
    name: "Név",
    loyaltyArtwork: "Hűség grafika",
    clickToUpload: "Kattintson a Feltöltés gombra",
    amountToPointsRatio: "Összeg és pont arány",
    points: "Pontok",
    recommendedAmountToPointRatio:
      "Ajánlott: to 1-1 pont. azaz minden 1 elköltött Naira után az ügyfelek 1 pontot kapnak",
    pleaseTypeIn: "Kérjük, írja be",
    toDeleteLoyalty: "törölni ezt a hűséget",
    deleteLoyalty: "Törölje a hűséget",
    toConfirm: "megerősítéséhez",
    edit: "Szerkesztés",
    pointsAwardedSuccessfully: "Sikeresen odaítélt pontok.",
    enterPointValueToAward:
      "Adja meg azt a pontértéket, amelyet szeretne díjazni az ügyfeleknek",
    award: "Díj",
    awardPointValuesToCustomer: "Díjpont érték az Ügyfélnek",
    enrollMembersToLoyalty: "Regisztráljon tagokat a hűségre",
    awardPoints: "Díjpontok",
    enroll: "Jelentkezzen",
    home: "itthon",
    loyalty: "Hűség",
    enrollCustomers: "Regisztrálja az ügyfeleket",
    selected: "Kiválasztott",
    customer: "Vevő",
    loyaltyActivationSuccessful: "A hűség aktiválása sikeres.",
    loyaltyDeactivationSuccessful: "A hűség deaktiválása sikeres.",
    viewTier: "Tier megtekintése",
    deactivate: "Deaktiválás",
    activate: "Aktiválja",
    actions: "Műveletek",
    nameOfLoyalty: "A hűség neve",
    loyaltyStatus: "Hűség állapota",
    numberOfTiers: "Szintek száma",
    createdOn: "Készült ekkor",
    createATier: "Tier létrehozása",
    stopCreatingTierConfirmation: "Le szeretné állítani a réteg létrehozását?",
    stopEditingTierConfirmation: "Leállítja ennek a szintnek a szerkesztését?",
    nameOfTier: "Tier neve",
    minimumSpendingTarget: "Minimális költési cél",
    maximumSpendingTarget: "Maximális költési cél",
    minimumSpendingTargetRequired: "minimális kiadási cél szükséges",
    maximumSpendingTargetRequired: "maximális kiadási cél szükséges",
    rewardSponsor: "Jutalomszponzor",
    pleaseChooseARewardSponsor: "Kérjük, válasszon jutalomszponzort",
    self: "Maga",
    partner: "Partner",
    rewardPartner: "Jutalompartner",
    pleaseSelectRewardPartner: "Kérjük, válassza ki jutalompartnerét",
    rewards: "Jutalmak",
    pleaseSelectAReward: "Kérjük, válasszon jutalmat",
    instructionsOnRedeemingReward:
      "Utasítások arra vonatkozóan, hogy az Ügyfél hogyan válthatja be a jutalmat",
    pleaseFillInThisField: "Kérjük, töltse ki ezt a mezőt!",
    toDeleteThisTier: " ennek a szintnek a törléséhez",
    deleteTier: "Tier törlése",
    viewMembers: "Tagok megtekintése",
    membersEnrolled: "Regisztrált tagok",
    instruction: "Utasítás",
    membersIn: "Tagok bent",
    availableTiersInLoyalty: "Elérhető szint (ek) a hűségprogramban",
    tiers: "Szintek",
    totalTier: "ÖSSZES TIER",
    availableLoyaltyProgramme: "Elérhető hűségprogram",
    totalLoyalties: "ÖSSZES LOJALITÁS",
    memberDetails: "Tag adatok",
    nameOfCustomer: "Ügyfél neve",
    address: "Cím",
    allEnrolledMembers: "Minden regisztrált tag",
    thisIsToWishYouHappyBirthday:
      "Ezzel kívánok boldog születésnapot és sikeres életet. Köszönöm mindazt, amit vagy Loysternek. Boldog Ünnepeket!",
    inputAnOfferPlease: "Adjon meg egy ajánlatot, kérem",
    pleaseSelectTheInsertPoint:
      "Kérjük, válassza ki a beszúrási pontot az üzenetben, majd kattintson újra",
    birthdayOfferAndMessage: "Születésnapi ajánlat és üzenet",
    birthdayOffer: "Születésnapi ajánlat",
    birthdayMessage: "Születésnapi üzenet",
    noOfferFound: "Nem található ajánlat",
    settingABirthdayOffer:
      "A születésnapi ajánlat beállításával az ügyfelek ezt az ajánlatot SMS -ben kaphatják meg születésnapjukon",
    createOffer: "Ajánlat létrehozása",
    whatIsTheOffer: "Mi az ajánlat?",
    editMessage: "Üzenet szerkesztése",
    insert: "Beszúrás",
    theNameOfCustomerInserted: "Ide kerül az ügyfél neve",
    theBirtdayOfferInserted: "A születésnapi ajánlat ide lesz beillesztve",
    youSuccessfullyAddedNewBranch: "Sikeresen hozzáadtál egy új ágat!",
    addNewBranch: "Új fiók hozzáadása",
    addBranch: "Fiók hozzáadása",
    additionalBranchWillIncur: "További ágra lenne szükség",
    perBranch: "ágonként",
    ecommerceBranchCosts: "Az e -kereskedelmi fiók költségei",
    pleaseEnterBranchName: "Kérjük, adja meg a fiók nevét",
    pleaseEnterBranchAddress1: "Kérjük, adja meg a fiók címsorát 1",
    enterBranchAddress1: "Írja be a fiók címsorát 1",
    enterBranchAddress2: "Írja be a fiók címsorát 1",
    pleaseEnterBranchAddress2: "Kérjük, adja meg a fiók címsorát 2",
    enterBranchName: "Adja meg a fiók nevét",
    successfullyAddedStaff: "Sikeresen felvett egy új személyzetet!",
    addNewStaff: "Új személyzet hozzáadása",
    addStaff: "Személyzet hozzáadása",
    additionalStaffWillIncur: "További személyzet járna",
    perStaff: "személyzetenként.",
    pleaseEnterStaffEmail: "Kérjük, adja meg a személyzet e -mail címét",
    pleaseEnterStaffUsername: "Kérjük, adja meg a személyzet felhasználónevét",
    pleaseEnterStaffPassword: "Kérjük, adja meg a személyzet jelszavát",
    pleaseSelectStaffRole: "Kérjük, válassza ki a személyzet szerepét",
    selectStaffRole: "Válassza ki a személyzet szerepét",
    enterStaffEmail: "Írja be a személyzet e -mail címét",
    enterStaffUsername: "Adja meg a személyzet felhasználónevét",
    enterStaffPassword: "Írja be a személyzet jelszavát",
    spacesNotAllowedInUsername:
      "a szóközök nem engedélyezettek a felhasználónévben",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Kérjük, válassza ki a vállalkozást a személyzet hozzárendeléséhez",
    searchForBranchToAttachStaff:
      "Keressen fiókot a személyzet hozzárendeléséhez",
    username: "Felhasználónév",
    role: "Szerep",
    areYouSureToDeleteThis: "Biztosan törli ezt",
    branches: "Ágak",
    upgradeYourPlan: "Frissítse tervét.",
    add: "FÜGG",
    addNew: "Új hozzáadása",
    customerWithEmailAlreadyExists: "E -mail/telefonszámú ügyfél már létezik!",
    successfullyAddedNewCustomer: "Sikeresen felvett egy új ügyfelet!",
    addCustomer: "Ügyfél hozzáadása",
    pleaseEnterCustomerFirstName: "Kérjük, adja meg az ügyfél keresztnevét",
    pleaseEnterCustomerLastName: "Kérjük, adja meg az ügyfél vezetéknevét",
    pleaseEnterCustomerPhoneNumber: "Kérjük, adja meg az ügyfél telefonszámát",
    pleaseEnterCustomerEmail: "Kérjük, adja meg az ügyfél e -mail címét",
    pleaseEnterCustomerAddressLine: "Kérjük, adja meg az ügyfél címsorát",
    pleaseEnterCustomerOtherAddress: "Kérjük, adja meg az ügyfél másik címét",
    pleaseSelectCustomerGender: "Kérjük, válassza ki az ügyfél nemét",
    gender: "Nem",
    male: "Férfi",
    female: "Női",
    bank: "Bank",
    selectBank: "Válassza a Bank lehetőséget",
    stateOrRegionOrProvince: "Állam/régió/tartomány",
    customerNotes: "Vásárlói megjegyzések",
    sendSms: "SMS-t küldeni",
    editCustomer: "Ügyfél szerkesztése",
    redeemReward: "Jutalom beváltása",
    issueLoyaltyCard: "Hűségkártya kiállítása",
    deleteCustomer: "Ügyfél törlése",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Sikeresen hozzárendelt egy hűség tagsági azonosítót",
    noMembershipIdAvailable:
      "Nincsenek tagsági azonosítók. Kérjük, vegye fel velünk a kapcsolatot a hello@loystar.co címen",
    sendEmail: "Küldjön e-mailt",
    membershipPoints: "Tagsági pontok",
    customerDetails: "Vásárló adatai",
    close: "Bezárás",
    loyaltyBalance: "Hűségmérleg",
    pointsBalance: "Pont egyensúly",
    starBalance: "Csillagmérleg",
    requiredPoints: "Szükséges pontok",
    requiredStars: "Kötelező csillagok",
    reddemCode: "Kód beváltása",
    toDeleteThisCustomer: "hogy törölje ezt az ügyfelet",
    customerHasBeenDeletedSuccessfully: "Az ügyfelet sikeresen törölte!",
    customerWithPhoneAlreadyExists:
      "A telefonszámmal rendelkező ügyfél már létezik",
    customerWasSuccessfullyEdited: "Az ügyfél szerkesztése sikeres volt",
    anErrorOccured: "Hiba történt",
    phoneNumberIncludeCountry: "Telefonszám (tartalmazza az országkódot)",
    yourFileQueuedForUpload:
      "A fájl sorba került feltöltésre. Kérjük, frissítse az oldalt néhány másodperc múlva.",
    thereWasAnErrorPerformingOperation:
      "Hiba történt a művelet végrehajtásakor!",
    pleaseSelectFile: "Kérjük, válasszon egy fájlt!",
    oopsOnlyCsvFilesAllowed:
      "Hoppá! Csak CSV fájlok engedélyezettek. Kérjük, töltsön fel .csv fájlt.",
    fileShouldBeSmallerThan5Mb:
      "A fájlnak kisebbnek kell lennie, mint 5 MB. Ha nagyobb fájlja van, kérjük, küldjön e -mailt a support@loystar.co címre. Köszönöm",
    customerFirstNameIsRequired:
      "sorban kötelező megadni az ügyfél keresztnevét",
    customerPhoneNumberIsRequired:
      "sorban kötelező megadni az ügyfél telefonszámát",
    importCustomers: "Ügyfelek importálása",
    upload: "Feltöltés",
    clickIconToDownloadCsv:
      "Kattintson erre az ikonra a CSV -fájl sablon letöltéséhez.",
    getGoogleSheetVersion: "Szerezze be a Google munkalap verzióját",
    clickOrDragFileToUpload:
      "A feltöltéshez kattintson vagy húzza a fájlt erre a területre",
    missingOrInvalidColumnHeader:
      "Hiányzó vagy érvénytelen oszlopfejléc. Kérjük, kövesse a sablon formátumát. Köszönöm.",
    youHaveImported: "Ön importált",
    youSuccessfullyRedeemedReward: "Sikeresen beváltotta jutalmát!",
    sixDigitCode: "Hatjegyű kód",
    pleaseEnterCustomerRewardCode: "Kérjük, adja meg az ügyfél jutalomkódját",
    enterRewardCode:
      "Írja be a jutalomkódot. (A jutalomkód megkülönbözteti a kis- és nagybetűket)",
    selectLoyaltyProgram: "Válassza ki a hűségprogramot",
    stamps: "Bélyegek",
    smsUnitsLow: "SMS -egységek alacsonyak",
    pleaseTopUpSmsUnits: "Kérjük, töltse fel az SMS -egységeket",
    smsSuccessfullySentForDelivery: "SMS sikeresen elküldve!",
    sendSmsTo: "SMS küldése a címre",
    allCustomers: "Minden ügyfél",
    unitsAvailable: "Elérhető egységek",
    pleaseTypeInTheMessage: "Kérjük, írja be az üzenetet",
    message: "Üzenet",
    charactersRemaining: "fennmaradó karakterek",
    avoidUseOfSpecialCharacters:
      "Kerülje a különleges karakterek és hangulatjelek használatát az SMS -egységek megőrzése érdekében.",
    note: "jegyzet",
    errorFetchingCustomersMultilevelDetail:
      "Hiba az ügyfelek többszintű részleteinek lekérésekor",
    search: "Keresés",
    reset: "Visszaállítás",
    importCustomer: "Ügyfél importálása",
    addNewCustomer: "Új ügyfél hozzáadása",
    sendSmsBroadcast: "SMS -küldés",
    totalCustomers: "ÖSSZES ÜGYFÉL",
    disbursementDetails: "A folyósítás részletei",
    paidBy: "Által fizetett",
    disbursed: "Kifizetve",
    bankAccountName: "Bankszámla neve",
    bankAccountNumber: "Bankszámlaszám",
    transferInitiated: "Transzfer elindítva",
    transferCompleted: "Átvitel befejezve",
    pleaseEnterAValid: "Kérjük, adjon meg érvényes értéket",
    begin: "kezdődik",
    end: "vége",
    date: "dátum",
    paymentDate: "Fizetés nap",
    selectDisbursementDuration: "Válassza a Folyósítás időtartama lehetőséget",
    totalSettled: "Összesen rendezve",
    totalUnsettled: "Összesen rendezetlen",
    toDeleteThisSale: "törli ezt az értékesítést",
    draftSaleDeletedSuccessfully: "A vázlatos értékesítés sikeresen törölve!",
    deleteSale: "Eladás törlése",
    pleaseTypeInYourTotal:
      "Kérjük, írja be a végső összeget a törlés megerősítéséhez",
    billDetails: "Számla részletei",
    printBill: "Nyomtatás Bill",
    servedBy: "Szolgálja",
    total: "Teljes",
    createdDate: "Létrehozás dátuma",
    createdTime: "Létrehozott idő",
    status: "Állapot",
    loginSuccessful: "Sikeres bejelentkezés",
    pleaseWaitWhileWeConnectAccount:
      "Kérjük, várjon, amíg összekapcsoljuk fiókját",
    connectionFailedTryAgain: "Kapcsolat nem sikerült. Kérlek próbáld újra.",
    connectionSuccessful: "A csatlakozás sikeres",
    viewDetails: "Részletek megtekintése",
    enable: "Engedélyezze",
    free: "Ingyenes",
    cost: "Költség",
    visitWebsite: "Látogassa meg a webhelyet",
    pleaseUpgradeYourPlanToPro:
      "Kérjük, frissítse tervét Pro Plus -ra, hogy engedélyezze ezt az alkalmazást",
    connectYourBankAccount:
      "Csatlakoztassa bankszámláját a kifizetések fogadásához.",
    disable: "Letiltás",
    enableApp: "Engedélyezze az alkalmazást",
    addNewProductToInvoice: "Új termék hozzáadása a számlához",
    toDeleteThisInvoice: "hogy törölje ezt a számlát",
    invoiceDeletedSuccessfully: "A számla törlése sikeres!",
    deleteInvoice: "Számla törlése",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Sikeresen hozzáadott egy számlafizetési üzenetet!",
    pleaseEnterPaymentInstructions: "Kérjük, adja meg a fizetési utasításokat",
    invoiceId: "Számla azonosítója",
    paidDate: "Fizetett dátum",
    reference: "Referencia",
    productAdded: "Termék hozzáadva",
    productOutOfStock: "A termék elfogyott. Kérjük, készletezze újra.",
    totalInvoices: "SZÁMLÁK ÖSSZESEN",
    totalPaidInvoices: "Fizetett számlák ÖSSZESEN",
    totalUnpaidInvoices: "Fizetetlen számlák ÖSSZESEN",
    loyaltyProgramDeleted: "Hűségprogram törölve",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ezt a hűségprogramot sikeresen töröltük",
    thisLoyaltyProgramEditedSuccessfully:
      "Ezt a hűségprogramot sikeresen szerkesztettük",
    loyaltyProgramAddedSuccessfully: "A hűségprogram sikeresen hozzáadva",
    loyaltyProgramEdited: "Hűségprogram szerkesztve",
    loyaltyProgramAdded: "Hűségprogram hozzáadva",
    loyaltyDetails: "Hűség részletei",
    doYouWantToCloseDialog: "Bezárja ezt a párbeszédpanelt?",
    pleaseEnterLoyaltyName: "Kérjük, adja meg hűségének nevét",
    programType: "Program típusa",
    pleaseSelectProgramType: "Kérjük, válassza ki a program típusát",
    simplePoints: "Egyszerű pontok",
    stampsProgram: "Bélyegző program",
    threshold: "Küszöb",
    pleaseEnterLoyaltyThreshold: "Kérjük, adja meg a hűségküszöböt",
    reward: "Jutalom",
    pleaseEnterLoyaltyReward: "Kérjük, adja meg a hűség jutalmát",
    totalUserPoints: "Felhasználói pontok összesen",
    totalUserStamps: "Összes felhasználói bélyegző",
    spendingTarget: "Költési cél",
    spendingTargetHint1:
      "A költési cél az, hogy mennyit kell költenie az ügyfélnek a jutalom megszerzéséhez. 1 pénznem értéke = 1 pont.",
    spendingTargetHint2:
      "A bélyegek célja az, hogy az ügyfeleknek hány bélyeget kell gyűjteniük a jutalom megszerzéséhez. például. 5",
    addNewLoyaltyProgram: "Új hűségprogram hozzáadása",
    addLoyaltyProgram: "Hűségprogram hozzáadása",
    loyaltyProgramType: "Hűségprogram típusa",
    pleaseSelectLoyaltyProgramType:
      "Kérjük, válassza ki a hűségprogram típusát",
    nameOfProgram: "Program neve",
    pleaseEnterProgramName: "Kérjük, adja meg a program nevét",
    whatIsTheReward: "Mi a jutalom?",
    egNextPurchaseIsFree: "Például. A következő vásárlás ingyenes",
    customerName: "Ügyfél neve",
    guestCustomer: "Vendég Ügyfél",
    orderConfirmedSuccessfully: "A rendelés sikeresen megerősítve",
    orderCancelledSuccessfully: "Rendelés sikeresen törölve",
    confirmOrder: "Rendelés megerősítése",
    cancelOrder: "Rendelés törlése",
    allOrders: "Minden rendelés",
    totalOrders: "RENDELÉSEK ÖSSZESEN",
    doYouWantToAcceptOrder: "El akarja fogadni ezt a megrendelést?",
    doYouWantToCancelOrder: "Visszavonja ezt a megrendelést?",
    orderDetails: "Rendelés részletei",
    orderCreatedAt: "Rendelés létrehozva:",
    supplier: "Támogató",
    productName: "termék név",
    quantity: "Mennyiség",
    unitPrice: "Egységár",
    receivedBy: "Megkapta",
    reportFrom: "Jelentés tőle",
    totalSupplies: "Kellékek összesen",
    allRightsReserved: "Minden jog fenntartva",
    toDeleteThisTransaction: "hogy törölje ezt a tranzakciót",
    transactionDeletedSuccessfully:
      "A tranzakció sikeresen törölve. A készletek visszatértek a készletbe.",
    deleteTransaction: "Tranzakció törlése",
    transactionDetails: "tranzakció részletek",
    printReceipt: "Nyomtatási nyugta",
    channel: "Csatorna",
    discount: "Kedvezmény",
    profit: "Nyereség",
    discountedSales: "Kedvezményes értékesítés",
    errorFetchingRecord: "Hiba a rekord beolvasásakor",
    exportTransactions: "Tranzakciók exportálása",
    errorFetchingSalesRecord:
      "Hiba történt az értékesítési rekord lekérésekor az exportáláshoz.",
    totalSellingPrice: "Teljes eladási ár",
    totalCostPrice: "Teljes költség",
    appliedDiscount: "Alkalmazott kedvezmény",
    noOfItems: "Elemek száma",
    sales: "Értékesítés",
    export: "Export",
    totalProfit: "Teljes haszon",
    totalBalanceInPeriod: "Teljes egyenleg a periódusban",
    allTimeSales: "Minden idők értékesítése",
    records: "Rekordok",
    todaysSales: "Mai értékesítés",
    transaction: "tranzakció",
    youHaveExceededTotalNumberOfProducts:
      "Túllépte a tervben megengedett teljes termékek számát. Frissítse tervét, hogy magasabb korlátot élvezhessen.",
    youNeedToHaveLoyaltyProgram:
      "A szolgáltatás használatához hűségprogramra van szükség!",
    price: "Ár",
    category: "Kategória",
    stockTracking: "Állománykövetés",
    stockCount: "Készletszám",
    taxed: "Adózott",
    originalPrice: "Eredeti ár",
    costPrice: "Kiadás",
    unit: "Mértékegység",
    productImage: "Termékkép",
    taxRate: "Adókulcs",
    taxType: "Adótípus",
    trackInventory: "Leltár nyomon követése",
    variants: "Változatok",
    hasVariants: "Változatai vannak",
    importProduct: "Termék importálása",
    exportProducts: "Termékek exportálása",
    addNewProduct: "Új termék hozzáadása",
    viewCategory: "Kategória megtekintése",
    viewSuppliers: "Szállítók megtekintése",
    receiveInventory: "Leltár fogadása",
    printAllProductsTag: "Minden termék nyomtatása címke",
    deleteAll: "Mindet törölni",
    totalProducts: "TERMÉKEK ÖSSZESEN",
    youveSuccessfullyAddedANewCategory:
      "Sikeresen hozzáadott egy új kategóriát",
    addNewCategory: "Új kategória hozzáadása",
    addCategory: "Kategória hozzáadása",
    categoryName: "kategória név",
    pleaseEnterCategoryName: "Kérjük, adja meg a kategória nevét",
    stampsTarget: "Bélyegek Cél",
    sendInventory: "Készlet küldése",
    productDetails: "termék leírás",
    youveSuccessfullyEditedThisCategory:
      "Sikeresen szerkesztette ezt a kategóriát",
    update: "Frissítés",
    categoryList: "Kategórialista",
    categories: "Kategóriák",
    enterQuantityToUpdate: "Adja meg a frissítendő mennyiséget",
    inventorySentSuccessfully: "A leltárt sikeresen elküldtük!",
    updateInventory: "Készlet frissítése",
    currentQuantity: "Jelenlegi mennyiség",
    pleaseEnterQuantityToAdd: "Kérjük, adja meg a hozzáadni kívánt mennyiséget",
    pleaseSelectABranch: "Kérjük, válasszon ágat",
    searchForBranch: "Fiók keresése",
    youCantSendMoreThanStock: "Nem küldhet többet, mint amennyi készleten van",
    send: "Küld",
    pleaseEnterQuantityToSend: "Kérjük, adja meg a küldeni kívánt mennyiséget",
    productNameIsRequiredOnRow: "sorban kötelező megadni a termék nevét",
    productCategoryIsRequiredOnRow: "sorban termékkategória szükséges",
    productPriceIsRequiredOnRow: "termék árát kell megadni a sorban",
    productUnitIsRequiredOnRow: "terméksegység szükséges a sorban",
    productQuantityIsRequiredOnRow: "termékmennyiséget kell megadni a sorban",
    productVariantsRequireTracking: "termékváltozatok követést igényelnek!",
    pleaseAddVariantClickButton:
      "Kérjük, adjon hozzá egy változatot a Hozzáadás gombra kattintva!",
    totalVariantsMoreThanSelectedQuantity:
      "A teljes változat több, mint a kiválasztott termékmennyiség, a Pls csökkenti a változat mennyiségét",
    productEditedSuccessfully: "A termék szerkesztése sikeresen megtörtént!",
    fileTooLargeLessThan4Mb:
      "A fájl mérete túl nagy! A fájlméretnek kevesebbnek kell lennie, mint 4 MB.",
    suchVariantAlreadyExist: "Ilyen változat már létezik",
    addVariants: "Változatok hozzáadása",
    editProduct: "Termék szerkesztése",
    pleaseEnterProductName: "Kérjük, adja meg a termék nevét",
    pleaseEnterProductPrice: "Kérjük, adja meg a termék árát",
    pleaseEnterProductOriginalPrice: "Kérjük, adja meg a termék eredeti árát",
    productDescription: "termékleírás",
    selectProductCategory: "Válassza ki a termékkategóriát",
    pleaseSelectProductCategory: "Kérjük, válassza ki a termékkategóriát",
    productCostPrice: "Termék Költség Ár",
    productSellingPrice: "A termék eladási ára",
    productOriginalPrice: "A termék eredeti ára",
    maxFileSizeAllowedIs4Mb: "Max. A megengedett fájlméret 4mb",
    productsWithPicturesArePublished:
      "A képekkel ellátott termékeket a Discover oldalon teszik közzé a megrendelések fogadásához",
    shouldThisProductBeTracked: "Követni kell ezt a terméket?",
    pleaseSelectStockUnit: "Kérjük, válassza ki a készlet egységet",
    stockUnit: "Részvényegység",
    bag: "TÁSKA",
    bottle: "ÜVEG",
    bunch: "CSOKOR",
    can: "TUD",
    carton: "KARTONDOBOZ",
    crate: "CRATE",
    cup: "CSÉSZE",
    dozen: "TUCAT",
    gigabytes: "GIGABYTES",
    gram: "GRAMM",
    kilogram: "KILOGRAMM",
    litre: "LITER",
    pack: "CSOMAG",
    pair: "PÁR",
    pieces: "DARABOK",
    plate: "TÁNYÉR",
    tonne: "TONNE (MT)",
    uNIT: "MÉRTÉKEGYSÉG",
    yard: "UDVAR",
    pleaseEnterProductQuantity: "Kérjük, adja meg a termék mennyiségét",
    productQuantity: "Termékmennyiség",
    isThisProductTaxed: "Ez a termék adóköteles?",
    selectTaxType: "Válassza ki az adónemet",
    pleaseSelectTaxType: "Kérjük, válassza ki az adónemet",
    progressive: "Haladó",
    proportional: "Arányos",
    pleaseEnterProductTaxRate: "Kérjük, adja meg a termékadó mértékét",
    doesProductHaveVariants: "Ennek a terméknek vannak változatai?",
    type: "típus",
    value: "Érték",
    pleaseEnterVariantType: "Kérjük, adja meg a változat típusát",
    pleaseEnterVariantValue: "Kérjük, adja meg a változat értékét",
    pleaseEnterVariantPrice: "Kérjük, adja meg a változat árát",
    pleaseEnterVariantQuantity: "Kérjük, adja meg a változat mennyiségét",
    productDeletedSuccessfully: "A terméket sikeresen törölte!",
    categoryDeletedSuccessfully: "A kategória sikeresen törölve!",
    toDeleteThisProduct: "a termék törléséhez",
    invalidProductQuantity: "Érvénytelen termékmennyiség",
    quantityAddedIsOverStock:
      "A hozzáadott mennyiség meghaladja a készleten lévő mennyiséget.",
    itemInventoryNotTracked: "A cikkkészletet nem követik nyomon",
    addBulkQuantity: "Tömeges mennyiség hozzáadása",
    enterBulkQuantity: "Adja meg a tömeges mennyiséget",
    pleaseEnterBulkQuantity: "Kérjük, adja meg a tömeges mennyiséget",
    youveSuccessfullyAddedANewProduct: "Sikeresen hozzáadtál egy új terméket!",
    pleaseEnterProductSellingPrice: "Kérjük, adja meg a termék eladási árát",
    doYouWantToTrackProductStock: "Szeretné nyomon követni a termékkészletet?",
    sellingPrice: "Eladási ár",
    setProductExpiryReminder: "Állítsa be a termék lejárati emlékeztetőjét",
    productExpiryDate: "A termék lejárati dátuma",
    startRemindingFrom: "Indítsa el az emlékeztetőt innen",
    productSuppliesAddedSuccessfully:
      "Sikeresen hozzáadta a termékkészleteket.",
    addProductSupplies: "Termékkellékek hozzáadása",
    pleaseSelectSupplier: "Kérjük, válasszon szállítót",
    nameOfProduct: "A termék neve",
    pleaseSelectProduct: "Kérjük, válassza ki a terméket",
    productVariant: "Termékváltozat",
    pleaseSelectAVariant: "Kérjük, válasszon egy változatot",
    pleaseEnterUnitPrice: "Kérjük, adja meg az egységárat",
    businessBranch: "Üzleti ág",
    pleaseSelectBranch: "Kérjük, válassza ki az ágat",
    youveSuccessfullyAddedANewSupplier:
      "Sikeresen hozzáadott egy új beszállítót",
    addSupplier: "Beszállító hozzáadása",
    pleaseEnterSupplierEmail: "Kérjük, adja meg a szállító e -mail címét",
    pleaseAddADescription: "Kérjük, adjon hozzá leírást",
    anErrorOccuredProductsDeleted:
      "Hiba történt a művelet végrehajtása közben. Kérjük, vegye figyelembe, hogy néhány termék törlődhetett a folyamat során",
    bulkDelete: "Tömeges törlés",
    youAreAboutToDelete: "Törlésre készül",
    product: "Termék",
    isDueToRedeem: "megváltás miatt van",
    aReward: "egy jutalom",
    pleaseSelectCustomerToReeemReward:
      "Kérjük, válasszon ki egy ügyfelet a jutalom beváltásához.",
    youHaveNoLoyaltyProgramRunning: "Nem fut aktív hűségprogramja",
    customerHhasNoPointsInLoyaltyProgram:
      "Az ügyfélnek nincs pontja ebben a hűségprogramban",
    proceedWithPayment: "Folytassa a fizetéssel?",
    youAreAboutToPayForTransactionUsingPoints:
      "Fizetni készül a tranzakcióért pontok segítségével.",
    customerHas: "Ügyfél rendelkezik",
    worth: "érdemes",
    andIsNotSufficientToPayForTransaction:
      "és nem elegendő a tranzakció kifizetéséhez. Szeretnék hozzáadni az egyenleget egy másik fizetési móddal?",
    addCustomerLoyalty: "Vásárlói hűség hozzáadása",
    pleaseAddCustomerFirst:
      "Kérjük, először vegyen fel vagy válasszon ki egy ügyfelet.",
    pleaseWaitWhileWeGetReady: "Kérem, várjon, amíg készülünk",
    lowStock: "Alacsony készletek",
    pleaseEnterAmountTendered: "Kérjük, adja meg a kiírt összeget",
    areYouSureToBookSaleOffline:
      "Biztos, hogy offline szeretné lefoglalni ezt az akciót?",
    saleWouldBeBookedAutomatically:
      "Az akció automatikusan le lesz foglalva, amikor bekapcsolja az internetet",
    offlineSalesBookingCancelled: "Az offline értékesítési foglalás törölve",
    covid19Message:
      "COVID19: Mosson kezet szappannal vagy fertőtlenítse, hogy megakadályozza a terjedést. Maradjon mindig biztonságban",
    saleSuccessfullyRecorded: "Eladás sikeresen rögzítve!",
    sendReceiptToEmail: "Küldje el a nyugtát az e -mail címre",
    sendThankYouSms: "Küldj köszönő SMS -t",
    sendShippingDetails: "Szállítási adatok küldése",
    addLoyalty: "Hűség hozzáadása",
    searchCustomerNameOrNumber: "Keresse meg az ügyfél nevét vagy számát",
    clickTheSearchCustomerBox:
      "Kattintson a Keresés az ügyfélfiókban és a Szkennelési kártya elemre",
    enterProductPrice: "Adja meg a termék árát",
    enterPriceFor: "Adja meg az árat",
    searchForProduct: "Termék keresése",
    all: "Összes",
    backToDashboard: "Vissza a műszerfalhoz",
    viewDraftSales: "Vázlatértékesítések megtekintése",
    variantSelected: "variáns kiválasztva",
    variant: "változat",
    thePreviousVariantSelectionNotAvailable:
      "A korábbi változat nem érhető el az ár alapján kiválasztott új változathoz, kérjük, módosítsa a választást.",
    pleaseSupplyPositiveQuantityNumber:
      "Kérjük, adjon meg egy postai mennyiségszámot",
    lowStockFor: "Alacsony készlet",
    clearVariants: "Világos változatok",
    pleaseEnterQuantity: "Kérjük, írja be a mennyiséget",
    picture: "Kép",
    redemptionToken: "Redemption Token",
    token: "Jelképes",
    totalSpent: "Összesen elköltve",
    confirmPayment: "Fizetés megerősítése",
    hasPaymentBeenMade: "A fizetés sikeres volt?",
    enterTransactionReference:
      "Adja meg a tranzakció hivatkozását, amellyel fizetett",
    pleaseSelectACustomer: "Kérjük, válasszon egy ügyfelet!",
    areYouSureToApplyDiscount: "Biztos, hogy kedvezményt szeretne alkalmazni?",
    addYourBankAccountToEnableUssd:
      "Adja hozzá bankszámláját, hogy engedélyezze az USP azonnali átutalását az uPay segítségével",
    totalAmountToPay: "Összes fizetendő összeg",
    doYouWantToCancelTransaction: "Törli ezt a tranzakciót?",
    savePrintBill: "Számla mentése/nyomtatása",
    enterAmountCollectedForCustomer: "Adja meg az ügyfélnek beszedett összeget",
    recordSale: "Rekord eladás",
    checkOutWith: "Ellenőrizze a",
    instantTransfer: "Azonnali átvitel",
    dialTheUSSDCode: "Tárcsázza az USSD kódot",
    pleaseSelectABank: "Kérjük, válasszon bankot",
    payWithUSSD: "Fizetés USSD -vel",
    sendBillTo: " - Küldje Billt a címre",
    waitingForUSSDTransfer: "Várakozás az USSD átvitelre",
    percent: "Százalék",
    applyDiscount: "Kedvezmény alkalmazása",
    thisBillHasBeenSaved: "Ezt a törvényjavaslatot megmentették",
    saveDraft: "Piszkozat mentése",
    pleaseTypeANameToIdentifyCustomer:
      "Kérjük, írjon be egy nevet az ügyfél azonosításához",
    paymentDetails: "Fizetési részletek",
    basePrice: "Alapár",
    staff: "Személyzet",
    subTotal: "Részösszeg",
    durationMonths: "Időtartam (hónap)",
    selectADuration: "Válasszon egy időtartamot",
    oneMonth: "1 hónap",
    twoMonths: "2 hónap",
    threeMonths: "3 hónap",
    sixMonths: "6 hónap",
    twelveMonths: "12 hónap",
    eighteenMonths: "18 hónap",
    twentyFourMonths: "24 hónap",
    twoMonthsFree: "(2 hónap ingyenes)",
    threeMonthsFree: "(3 hónap ingyenes)",
    fiveMonthsFree: "(5 hónap ingyenes)",
    yourAccountHasBeen: "Fiókja megvolt",
    renewed: "megújult",
    upgraded: "korszerűsítették",
    successfully: "sikeresen",
    yourSubscription: "Az Ön előfizetése",
    youAreCurrentlyEnrolledOnA: "Jelenleg beiratkozott a",
    pleaseChooseAPaymentOption: "Kérjük, válasszon fizetési lehetőséget",
    planRenewal: "Tervezze meg a megújítást",
    planUpgrade: "Tervezze meg a frissítést",
    pleaseSelectDurationToPayFor:
      "Kérjük, válassza ki azt az időtartamot, amelyért fizetni kíván",
    staffAccounts: "Személyi számlák",
    ecommerce: "E -kereskedelem",
    pleaseSelectAPlan: "Kérjük, válasszon tervet",
    includeAddons: "Bővítmények hozzáadása",
    viewTransactions: "Tranzakciók megtekintése",
    customerHasNoCompletedTansactions:
      "Az ügyfélnek még nincs befejezett szakszerű művelete",
    branch: "Ág",
    enterNumberOfEcommerceBranches: "Adja meg az e -kereskedelmi fiókok számát",
    enterNumberOfEcommerceBranchesToPay:
      "Adja meg az e -kereskedelmi fiókok számát, amelyekért fizetni kíván",
    ecommerceIntegration: "E -kereskedelmi integráció",
    enterNumberOfBranches: "Adja meg az ágak számát",
    enterNumberOfAdditionalBranchesToPay:
      "Adja meg a további fiókok számát, amelyekért fizetni kíván",
    enterNumberOfStaffs: "Adja meg a személyzet számát",
    enterNumberOfStaffsToPayFor:
      "Adja meg a személyzet számát, amelyért fizetni kíván",
    discountApplies: "Kedvezmény érvényes",
    starsOnThe: "csillagok a",
    offer: "ajánlat",
    get: "Kap",
    moreStarsToRedeem: "több csillagot kell megváltani",
    taxVat: "Adó (ÁFA)",
    callCashierToCompletePayment: "Hívja a pénztárost a fizetés befejezéséhez",
    receipt: "Nyugta",
    dear: "kedves",
    thankYouForYourOrderFindGoods:
      "Köszönöm a rendelését. Kérjük, a megállapodás szerint találja meg az alábbi termékeket.",
    shippingNote: "Szállítási megjegyzés",
    enterShippingNote: "Adja meg a szállítási megjegyzést",
    shippingAddress: "Szállítási cím",
    enterShippingAddress: "Adja meg a szállítási címet",
    wellDoneYouAreDueToRedeem: "Szép munka! Önnek kell megváltania",
    toGetYourRewardNextVisit:
      "hogy a következő látogatáskor megkapja jutalmát. Köszönöm",
    pointsOnThe: "Pontok a",
    morePointsToRedeem: "több pontot kell beváltani",
    showCode: "Kód megjelenítése",
    toGetYourRewardOnNextVisit:
      "hogy a következő látogatáskor megkapja jutalmát. Köszönöm",
    earn: "Pénzt keres",
    delivaryNote: "Szállítási megjegyzés",
    draftSales: "Vázlatértékesítés",
    startDate: "Kezdő dátum",
    endDate: "Befejezés dátuma",
    orders: "Rendelések",
    checkout: "pénztár",
    noProductItem: "Nincs termék",
    selectProductImage: "Válassza ki a termékképet",
    selectCountry: "Válassz országot",
    selectRegion: "Válassza ki az államot/régiót",
    printProductTag: "Termékcímke nyomtatása",
    transactionReference: "Tranzakciós hivatkozás",
    Cashier: "Pénztáros",
    Manager: "Menedzser",
    Owner: "Tulajdonos",
    Admin: "Admin",
    addPartners: "Partnerek hozzáadása",
    addNewLoyaltyPartner: "Új hűségpartner hozzáadása",
    pleaseEnterCompanyName: "Kérjük, adja meg a cég nevét",
    companyName: "Cégnév",
    pleaseEnterCompanyRepName: "Kérjük, adja meg a cég képviselőjének nevét",
    companyRepName: "A cég képviselőjének neve",
    pleaseEnterCompanyRepEmail:
      "Kérjük, adja meg a cég képviselőjének e-mail-címét",
    companyRepEmail: "A cég képviselőjének e-mailje",
    pleaseEnterCompanyRepPhone:
      "Kérjük, adja meg a cég képviselőjének telefonszámát",
    companyRepPhone: "A cég képviselőjének telefonszáma",
    addReward: "Adjon hozzá jutalmat",
    pleaseEnterRewardName: "Kérjük, adja meg a jutalom nevét",
    rewardName: "Jutalom neve",
    rewardQuantity: "Jutalom Mennyiség",
    rewardDescription: "Jutalom leírása",
    rewardType: "Jutalom típusa",
    pleaseEnterRewardType: "Kérjük, adja meg a jutalom típusát",
    pleaseEnterRewardQuantity: "Kérjük, adja meg a jutalom mennyiségét",
    pleaseEnterRewardDescription: "Kérjük, adja meg a jutalom leírását",
    fineDining: "Jó étkezés",
    luxuryFashion: "Luxus Divat",
    hotels: "Szállodák",
    travel: "Utazás",
    foodAndBeverage: "Étel és ital",
    fashion: "Divat",
    health: "Egészség",
    furniture: "Bútor",
    entertainment: "Szórakozás",
    automobile: "Autó",
    education: "Oktatás",
    beautyAndSpa: "Szépség és gyógyfürdő",
    staycation: "Staycation",
    events: "Események",
    trips: "Utazások",
    oilAndGas: "Olaj és gáz",
    laundry: "Mosoda",
    partnerCategory: "Partner kategória",
    freeItem: "Ingyenes tétel",
  },
  Hebrew: {
    cashier: "קופאית",
    manager: "מנהל",
    owner: "בעלים",
    online: "באינטרנט",
    offline: "לא מקוון",
    changePassword: "שנה סיסמא",
    currentPasswordMessage: "אנא הזן את הסיסמה הנוכחית שלך",
    passwordMessage: "הזן את סיסמתך בבקשה",
    currentPassword: "סיסמה נוכחית",
    password: "סיסמה",
    confirmPasswordMessage: "אנא אשר את סיסמתך!",
    confirmPassword: "אשר סיסמה",
    sendViaEmail: "שלח באמצעות מייל",
    sendViaWhatsapp: "שלח באמצעות WhatsApp",
    downloadPdf: "הורד PDF",
    paid: "שולם",
    unpaid: "ללא תשלום",
    partial: "חלקי",
    closeInvoice: "האם אתה רוצה לסגור את החשבונית?",
    closeInvoiceConfirmation: "לא ניתן לשמור חשבונית. האם אתה רוצה לסגור?",
    yes: "כן",
    no: "לא",
    invoice: "חשבונית מס",
    wasDeducted: "הופחת",
    for: "ל",
    item: "פריט",
    addProduct: "הוסף מוצר",
    paymentReference: "הפניה לתשלום",
    amountPaid: "סכום ששולם",
    discountAmount: "כמות ההנחה",
    amountDue: "סכום התשלום",
    amount: "כמות",
    dueDate: "תאריך להגשה",
    paymentType: "סוג תשלום",
    card: "כַּרְטִיס",
    cash: "כסף מזומן",
    bankTransfer: "העברה בנקאית",
    paymentMessage: "הודעת תשלום",
    description: "תיאור",
    sendReceipt: "שלח קבלה",
    delete: "לִמְחוֹק",
    save: "להציל",
    resend: "שלח מחדש",
    saveAndSend: "להציל",
    invoiceSaved: "חשבונית נשמרה!",
    selectPaymentMethod: "אנא בחר אמצעי תשלום!",
    selectCustomer: "אנא בחר לקוח!",
    cartEmptyError:
      "רשימת עגלות לא יכולה להיות ריקה, סגור את החשבונית והוסף פריט לעגלה!",
    subscriptionExpired:
      "פג תוקף המנוי והחשבון שלך מוגבל כעת. לחץ על הלחצן למטה כדי לחדש את חשבונך",
    renew: "לְחַדֵשׁ",
    holdOn: "תחזיק מעמד",
    customerBank: "בנק לקוחות",
    cancel: "לְבַטֵל",
    selectACustomer: "בחר לקוח",
    invoiceSuccessfulPdfError:
      "חשבונית נוצרה בהצלחה אך ייצור PDF לוקח יותר זמן מהרגיל. אנא בדוק שוב בקרוב.",
    downloadingInvoice: "הורדת חשבונית",
    downloadingReceipt: "הורדת קבלה",
    whatsappReceiptError: "אירעה שגיאה בשליחת הקבלה באמצעות WhatsApp. נסה שוב.",
    receiptToWhatsapp: "הקבלה הועברה לוואטסאפ",
    thankYouForPatronage: "תודה לך על החסות שלך",
    hereIsYourReceipt: "הנה קבלת התשלום שלך",
    errorSendingEmailReceipt:
      "אירעה שגיאה בשליחת הקבלה באמצעות דואר אלקטרוני. נסה שוב או צור קשר עם התמיכה",
    receiptSentToEmail: "הקבלה נשלחה למייל של הלקוח",
    invoiceSentToEmail: "חשבונית נשלחה למייל של הלקוח",
    invoiceSuccessWhatsappError:
      "החשבונית נוצרה בהצלחה אך אירעה שגיאה בשליחת WhatsApp. נסה שוב ברשימת החשבוניות",
    invoiceSuccessfulEmailError:
      "החשבונית נוצרה בהצלחה אך אירעה שגיאה בעת השליחה בדואר אלקטרוני. נסה שוב מרשימת החשבוניות",
    invoiceSentToWhatsapp: "החשבונית הועברה לוואטסאפ",
    hello: "שלום",
    pleaseDownloadInvoice: "אנא הורד חשבונית",
    pleaseDownloadReceipt: "אנא הורד קבלה",
    from: "מ",
    email: "אימייל",
    upgrade: "שדרוג",
    youAreOnFreePlan: "אתה על תוכנית חינם.",
    clickOn: "לחץ על",
    yourPlanInFewSteps: " את המנוי שלך בכמה צעדים מהירים.",
    to: "ל",
    yourSubscriptionHasExpired: "פג תוקף המנוי והחשבון שלך מוגבל כעת.",
    days: "ימים",
    yourSubscriptionExpiresIn: "תוקף המנוי שלך ל- Loystar יפוג ב-",
    createAcount: "צור חשבון",
    signIn: "להתחבר",
    continue: "לְהַמשִׁיך",
    enterOtp: "הזן PIN OTP",
    enterValidOtp: "אנא הזן קוד גישה חוקי",
    pin: "פִּין",
    tokenSentToMail: 'נשלח אסימון לדוא"ל שלך',
    passwordResetSuccessful: "איפוס הסיסמה הצליח",
    somethingWentWrong: "משהו השתבש!",
    resetPassword: "לאפס את הסיסמה",
    iHaveResetCode: "יש לי קוד לאיפוס סיסמה",
    pleaseEnterEmail: 'אנא הכנס את הדוא"ל שלך',
    aTokenWillBeSentToEmail: 'אסימון יישלח לדוא"ל שלך',
    enterEmail: "הכנס את האימייל שלך",
    sendinYourToken: "שולח את האסימון שלך ...",
    makeSureItMatchesPassword: "וודא שהיא תואמת את הסיסמה החדשה שלך",
    pleaseChooseNewPassword: "אנא בחר סיסמה חדשה",
    chooseNewPassword: "בחר סיסמה חדשה",
    enterNewPassword: "הזן את הסיסמה החדשה שלך לאישור",
    enterTokenSent: "הזן את האסימון שנשלח לדואר שלך",
    resetToken: "אפס אסימון",
    resettingPassword: "איפוס הסיסמה שלך ...",
    signUp: "הירשם",
    adminSignInWithEmail:
      ' מנהל המערכת נכנס באמצעות דוא"ל בעוד הצוות נכנס עם שם משתמש.',
    pleaseEnterEmailOrUsername: 'אנא הכנס את כתובת הדוא"ל או שם המשתמש שלך',
    emailOrUsername: 'דוא"ל או שם משתמש',
    pleaseEnterPassword: "אנא הזן סיסמה",
    createAnAccount: "צור חשבון",
    forgotPassword: "שכחת ססמא?",
    enterPhoneNumber: "הכנס מספר טלפון",
    personalData: "מידע אישי",
    validateConfirmationCOde: "אמת קוד אישור",
    pleaseEnterFirstName: "אנא הכנס את שמך הפרטי",
    pleaseEnterPhoneNumber: "אנא הכניסו את מספר הטלפון שלכם",
    pleaseEnterLastName: "אנא הכנס את שם המשפחה שלך",
    pleaseEnterBusinessName: "אנא הזן את שם העסק שלך",
    firstName: "שם פרטי",
    lastName: "שם משפחה",
    businessName: "שם עסק",
    previous: "קודם",
    next: "הַבָּא",
    pleaseSelectBusinessCategory: "אנא בחר את קטגוריית העסק שלך",
    pleaseEnterValidEmail: 'אנא הזן דוא"ל חוקי',
    pleaseEnterPostCode: "אנא הזן את מיקוד",
    postCode: "מיקוד",
    phoneNumberInUse: "מספר הטלפון הזה כבר בשימוש!",
    emailInUse: "מייל זה כבר בשימוש!",
    foodAndDrinks: "אוכל ומשקאות",
    salonAndBeauty: "סלון ויופי",
    fashionAndAccessories: "אופנה ואקססוריז",
    gymAndFitness: "חדר כושר וכושר",
    travelAndHotel: "נסיעות ומלון",
    homeAndGifts: "בית ומתנות",
    washingAndCleaning: "כביסה וניקיון",
    gadgetsAndElectronics: "גאדג'טים ואלקטרוניקה",
    groceries: "מצרכים",
    others: "אחרים",
    submit: "שלח",
    accountCreatedSuccessful: "המשתמש שלך נוצר בהצלחה.",
    pleaseEnterAddress: "אנא הכנס את כתובתך",
    addressLine1: "שורת כתובת 1",
    addressLine2: "שורת כתובת 2",
    choosePassword: "בחר סיסמה",
    passwordMustBe6Characters: "סיסמא חייבת להיות לפחות בת 6 תווים.",
    howDidYouHearLoystar: "איך שמעת על לויסטאר?",
    referralCode: "קוד הפניה",
    byClickingTheButton: " בלחיצה על הכפתור למטה, אתה מסכים ל",
    termsAndSevice: "תנאים",
    wereCreatingAccount: "אנו יוצרים את חשבונך",
    proceed: "להמשיך",
    verificationCodeMustBe6: "קוד האימות חייב להיות בן 6 ספרות",
    pleaseEnter6DigitCode: "אנא הזן קוד בן 6 ספרות",
    enterVerificationCode: "הכנס קוד אימות",
    resendToken: "שלח שוב אסימון",
    verify: "תאשר",
    transactions: "עסקאות",
    todaySales: "מכירות היום",
    salesHistory: "היסטוריית מכירות",
    supplyHistory: "היסטוריית אספקה",
    new: "חָדָשׁ",
    invoices: "חשבוניות",
    disbursements: "תשלומים",
    offlineSales: "מכירות לא מקוונות",
    products: "מוצרים",
    customers: "לקוחות",
    multiLevelLoyalty: "נאמנות מרובת רמות",
    loyaltyPrograms: "תוכניות נאמנות",
    members: "חברים",
    appStore: "חנות אפליקציות",
    orderMenu: "תפריט הזמנה",
    settings: "הגדרות",
    staffAndBranches: "צוותים וענפים",
    myAccount: "החשבון שלי",
    switchToSellMode: "עבור למצב מכירה",
    signOut: "התנתק",
    getFreeSubscription: "קבל מנוי חינם",
    onlyNumbersAllowed: "מותר רק מספרים",
    yourAccountMustBe10Digits: "מספר החשבון שלך חייב להיות מספר בן 10 ספרות",
    yourBvnMustBe11: "BVN שלך חייב להיות מספר בן 11 ספרות",
    pleaseSelectBank: "אנא בחר את הבנק שלך",
    selectYourBank: "בחר את הבנק שלך",
    enterBankAccountNumber: "הזן את מספר חשבון הבנק",
    enterBvnNumber: "הזן את ה- BVN שלך",
    connectBankAccount: "חבר חשבון בנק",
    passwordResetSuccessfulAndSignOut:
      "הסיסמה שלך התאפסה בהצלחה. לחץ על כפתור צא למטה כדי להיכנס שוב",
    enterCurrentPassword: "הכנס סיסמא נוכחית",
    pleaseEnterCurrentPassword: "אנא הזן את הסיסמה הנוכחית",
    phoneNumber: "מספר טלפון",
    sex: "מִין",
    dateOfBirth: "תאריך לידה",
    state: "מדינה",
    country: "מדינה",
    loyaltyId: "תעודת נאמנות",
    createdAt: "נוצר ב",
    noOfTransactionsMade: "מספר העסקאות שבוצעו",
    yourDownloadWillStart: "ההורדה שלך תתחיל בעוד רגע",
    exportCustomers: "ייצוא לקוחות",
    youhaveSuccessfullyToppedUp: "מילאת בהצלחה את יחידות ה- SMS שלך.",
    youNowHave: "עכשיו יש לך",
    smsUnits: "יחידות SMS",
    enterNumberOfSmsUnits: "הזן את מספר יחידות ה- SMS שאתה מתכוון לרכוש",
    pleaseEnterANumericValue: "אנא הזן ערך מספרי",
    pay: "לְשַׁלֵם",
    accountEditedSuccessfully: "החשבון שלך נערך בהצלחה.",
    youAeCurrentlyOn: "אתה כרגע מופעל",
    plan: "לְתַכְנֵן",
    userData: "מידע משתמש",
    businessData: "נתוני עסקים",
    businessCategory: "קייטרינג לעסקים",
    pleaseSelectCurrency: "אנא בחר את המטבע שלך",
    selectYourCurrency: "בחר את המטבע שלך",
    purchaseMoreSmsUnits: "רכשו יחידות SMS נוספות באמצעות הטופס שלהלן",
    youHave: "יש לך",
    atLeast4SmsUnitsRrequired: "נדרשת לפחות 4 יחידות SMS לאימות, אנא טען!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "אנא אמת את חשבון הבנק שלך כדי לעמוד בדרישות 'הכיר את הלקוח שלך' (KYC). זה יאפשר לך לאסוף תשלומים באמצעות USSD או העברה מיידית, לקבל הזמנות מלקוחות ולעבד הסדר עסקאות. תקף לסוחרים בניגריה בלבד. אנא לחץ על הכפתור למטה כדי להתחיל.",
    reConnectBankAccount: "חבר מחדש את חשבון הבנק",
    accountName: "שם החשבון",
    accountNumber: "מספר חשבון",
    bankName: "שם הבנק",
    verified: "מְאוּמָת",
    accountDetails: "פרטי חשבון",
    kycBankAccount: "KYC",
    createTier: "צור שכבה",
    fileUploadSuccessful: "הקובץ הועלה בהצלחה",
    fileUploadFailed: "העלאת הקובץ נכשלה",
    createLoyaltyProgram: "צור תוכנית נאמנות",
    createLoyalty: "צור נאמנות",
    name: "שֵׁם",
    loyaltyArtwork: "נאמנות נאמנות",
    clickToUpload: "לחץ להעלאה",
    amountToPointsRatio: "סכום ליחס נקודות",
    points: "נקודות",
    recommendedAmountToPointRatio:
      "מומלץ: point 1 עד 1 נקודה. כלומר על כל הוצאה של 1 נאירה, הלקוחות שלך מקבלים נקודה אחת",
    pleaseTypeIn: "אנא הקלד",
    toDeleteLoyalty: "למחוק נאמנות זו",
    deleteLoyalty: "מחק נאמנות",
    toConfirm: "כדי לאשר",
    edit: "לַעֲרוֹך",
    pointsAwardedSuccessfully: "נקודות הוענקו בהצלחה.",
    enterPointValueToAward: "הזן ערך נקודה שתרצה להעניק ללקוחות",
    award: "פרס",
    awardPointValuesToCustomer: "ערך נקודת פרס ללקוח",
    enrollMembersToLoyalty: "רשום חברים לנאמנות",
    awardPoints: "נקודות הענקת פרסים",
    enroll: "לְהִרָשֵׁם",
    home: "בית",
    loyalty: "נֶאֱמָנוּת",
    enrollCustomers: "רשמו לקוחות",
    selected: "נבחר",
    customer: "צרכן",
    loyaltyActivationSuccessful: "הפעלת נאמנות מוצלחת.",
    loyaltyDeactivationSuccessful: "ביטול נאמנות מצליח.",
    viewTier: "צפה ברמה",
    deactivate: "בטל הפעלה",
    activate: "לְהַפְעִיל",
    actions: "פעולות",
    nameOfLoyalty: "שם הנאמנות",
    loyaltyStatus: "סטטוס נאמנות מעמד נאמנות",
    numberOfTiers: "מספר שכבות",
    createdOn: "נוצר ב",
    createATier: "צור שכבה",
    stopCreatingTierConfirmation: "האם אתה רוצה להפסיק ליצור שכבה?",
    stopEditingTierConfirmation: "האם אתה רוצה להפסיק לערוך את הרובד הזה?",
    nameOfTier: "שם הרמה",
    minimumSpendingTarget: "יעד הוצאה מינימלי",
    maximumSpendingTarget: "יעד ההוצאה המרבי",
    minimumSpendingTargetRequired: "יעד ההוצאה המינימלי נדרש",
    maximumSpendingTargetRequired: "יעד ההוצאה המרבי נדרש",
    rewardSponsor: "נותן חסות",
    pleaseChooseARewardSponsor: "אנא בחר נותן חסות לתגמול",
    self: "עצמי",
    partner: "בת זוג",
    rewardPartner: "שותף תגמול",
    pleaseSelectRewardPartner: "אנא בחר את שותף הפרס שלך",
    rewards: "תגמולים",
    pleaseSelectAReward: "אנא בחר פרס",
    instructionsOnRedeemingReward: "הוראות כיצד הלקוח צריך לפדות תגמול",
    pleaseFillInThisField: "אנא מלא את השדה הזה!",
    toDeleteThisTier: " כדי למחוק נדבך זה",
    deleteTier: "מחק את הרמה",
    viewMembers: "צפה חברים",
    membersEnrolled: "חברים רשומים",
    instruction: "הוראה",
    membersIn: "חברים ב",
    availableTiersInLoyalty: "רובדים זמינים בתכנית הנאמנות",
    tiers: "שכבות",
    totalTier: "סך הכל שכבות",
    availableLoyaltyProgramme: "תוכנית נאמנות זמינה",
    totalLoyalties: "סך כל נאמנות",
    memberDetails: "פרטי חבר",
    nameOfCustomer: "שם של לקוח",
    address: "כתובת",
    allEnrolledMembers: "כל החברים הרשומים",
    thisIsToWishYouHappyBirthday:
      "זה לאחל לך יום הולדת שמח מאוד וחיים פורחים. תודה על כל מה שאתה ללויסטר. חגיגות שמח!",
    inputAnOfferPlease: "הזן הצעה בבקשה",
    pleaseSelectTheInsertPoint: "אנא בחר את נקודת ההכנסה בהודעה ולחץ שוב",
    birthdayOfferAndMessage: "הצעת יום הולדת והודעה",
    birthdayOffer: "הצעת יום הולדת",
    birthdayMessage: "הודעת יום הולדת",
    noOfferFound: "לא נמצאה הצעה",
    settingABirthdayOffer:
      "קביעת הצעת יום הולדת מאפשרת ללקוחות לקבל הצעה זו באמצעות SMS ביום הולדתם",
    createOffer: "צור הצעה",
    whatIsTheOffer: "מה ההצעה?",
    editMessage: "ערוך הודעה",
    insert: "לְהַכנִיס",
    theNameOfCustomerInserted: "שם הלקוח יוכנס כאן",
    theBirtdayOfferInserted: "הצעת יום ההולדת תוכנס כאן",
    youSuccessfullyAddedNewBranch: "הוספת סניף חדש בהצלחה!",
    addNewBranch: "הוסף סניף חדש",
    addBranch: "הוסף סניף",
    additionalBranchWillIncur: "סניף נוסף יגרור",
    perBranch: "לכל סניף",
    ecommerceBranchCosts: "עלויות סניף מסחר אלקטרוני",
    pleaseEnterBranchName: "אנא הזן את שם הסניף",
    pleaseEnterBranchAddress1: "אנא הזן את שורת הכתובת של הסניף 1",
    enterBranchAddress1: "הזן את שורת הכתובת של הסניף 1",
    enterBranchAddress2: "הזן את שורת הכתובת של הסניף 1",
    pleaseEnterBranchAddress2: "אנא הזן את שורת הכתובת של הסניף 2",
    enterBranchName: "הזן את שם הסניף",
    successfullyAddedStaff: "הוספת צוות חדש בהצלחה!",
    addNewStaff: "הוסף צוות חדש",
    addStaff: "הוסף צוות",
    additionalStaffWillIncur: "צוות נוסף יגרור",
    perStaff: "לכל צוות.",
    pleaseEnterStaffEmail: "אנא הכנס את המייל של הצוות",
    pleaseEnterStaffUsername: "אנא הכנס את שם המשתמש של הצוות",
    pleaseEnterStaffPassword: "אנא הזן את סיסמת הצוות",
    pleaseSelectStaffRole: "אנא בחר את תפקיד הצוות",
    selectStaffRole: "בחר את תפקיד הצוות",
    enterStaffEmail: "הזן את המייל של הצוות",
    enterStaffUsername: "הזן את שם המשתמש של הצוות",
    enterStaffPassword: "הזן את סיסמת הצוות",
    spacesNotAllowedInUsername: "רווחים אינם מותרים בשם המשתמש",
    admin: "מנהל מערכת",
    pleaseSelectBusinessToAttachStaff: "אנא בחר עסק לצירוף צוות",
    searchForBranchToAttachStaff: "חפש סניף לצירוף צוות",
    username: "שם משתמש",
    role: "תַפְקִיד",
    areYouSureToDeleteThis: "האם אתה בטוח למחוק זאת",
    branches: "ענפים",
    upgradeYourPlan: "שדרג את התוכנית שלך.",
    add: "לְהוֹסִיף",
    addNew: "הוסף חדש",
    customerWithEmailAlreadyExists: "לקוח עם מייל/מספר טלפון כבר קיים!",
    successfullyAddedNewCustomer: "הוספת לקוח חדש בהצלחה!",
    addCustomer: "הוסף לקוח",
    pleaseEnterCustomerFirstName: "אנא הזן את שמו הפרטי של הלקוח",
    pleaseEnterCustomerLastName: "אנא הזן את שם המשפחה של הלקוח",
    pleaseEnterCustomerPhoneNumber: "אנא הזן את מספר הטלפון של הלקוח",
    pleaseEnterCustomerEmail: 'אנא הכנס את הדוא"ל של הלקוח',
    pleaseEnterCustomerAddressLine: "אנא הזן את שורת הכתובת של הלקוח",
    pleaseEnterCustomerOtherAddress: "אנא הזן את כתובת אחרת של הלקוח",
    pleaseSelectCustomerGender: "אנא בחר את מין הלקוח",
    gender: "מִין",
    male: "זָכָר",
    female: "נְקֵבָה",
    bank: "בַּנק",
    selectBank: "בחר בנק",
    stateOrRegionOrProvince: "מדינה/אזור/פרובינציה",
    customerNotes: "הערות לקוחות",
    sendSms: "שלח מסרון",
    editCustomer: "ערוך לקוח",
    redeemReward: "גאול תגמול",
    issueLoyaltyCard: "הנפקת כרטיס נאמנות",
    deleteCustomer: "מחק את הלקוח",
    youveSuccessfullyAssignedLoyaltyMembership:
      "הקצאת בהצלחה מזהה חברות בנאמנות",
    noMembershipIdAvailable:
      "אין מזהי חברות זמינים. אנא צור איתנו קשר בכתובת hello@loystar.co",
    sendEmail: "שלח אימייל",
    membershipPoints: "נקודות חברות",
    customerDetails: "פרטי לקוח",
    close: "סגור",
    loyaltyBalance: "איזון נאמנות",
    pointsBalance: "איזון נקודות",
    starBalance: "כוכב איזון",
    requiredPoints: "נקודות חובה",
    requiredStars: "כוכבים נדרשים",
    reddemCode: "לפדות קוד",
    toDeleteThisCustomer: "למחוק לקוח זה",
    customerHasBeenDeletedSuccessfully: "הלקוח נמחק בהצלחה!",
    customerWithPhoneAlreadyExists: "לקוח עם מספר טלפון כבר קיים",
    customerWasSuccessfullyEdited: "הלקוח נערך בהצלחה",
    anErrorOccured: "חלה שגיאה",
    phoneNumberIncludeCountry: "מספר טלפון (כלול קוד מדינה)",
    yourFileQueuedForUpload:
      "הקובץ שלך הועמד בתור להעלאה. רענן את הדף לאחר מספר שניות.",
    thereWasAnErrorPerformingOperation: "אירעה שגיאה בביצוע הפעולה!",
    pleaseSelectFile: "אנא בחר קובץ!",
    oopsOnlyCsvFilesAllowed: "אופס! מותר רק קבצי CSV. אנא העלה קובץ .csv.",
    fileShouldBeSmallerThan5Mb:
      'הקובץ צריך להיות קטן מ- 5MB. אם יש לך קובץ גדול יותר, אנא שלח דוא"ל support@loystar.co. תודה',
    customerFirstNameIsRequired: "שם פרטי של הלקוח נדרש בשורה",
    customerPhoneNumberIsRequired: "מספר הטלפון של הלקוח נדרש בשורה",
    importCustomers: "ייבוא לקוחות",
    upload: "העלה",
    clickIconToDownloadCsv: "לחץ על סמל זה כדי להוריד את תבנית קובץ ה- CSV.",
    getGoogleSheetVersion: "קבל את גרסת הגיליון של Google",
    clickOrDragFileToUpload: "לחץ או גרור קובץ לאזור זה להעלאה",
    missingOrInvalidColumnHeader:
      "כותרת עמודה חסרה או לא חוקית. אנא עקוב אחר פורמט התבנית. תודה.",
    youHaveImported: "ייבאת",
    youSuccessfullyRedeemedReward: "מימשת את הפרס שלך בהצלחה!",
    sixDigitCode: "קוד בן שש ספרות",
    pleaseEnterCustomerRewardCode: "אנא הזן את קוד התגמול של הלקוח",
    enterRewardCode: "הזן קוד תגמול. (קוד התגמול תלוי באותיות רישיות)",
    selectLoyaltyProgram: "בחר תוכנית נאמנות",
    stamps: "בולים",
    smsUnitsLow: "יחידות SMS נמוכות",
    pleaseTopUpSmsUnits: "אנא טען יחידות SMS",
    smsSuccessfullySentForDelivery: "SMS נשלח בהצלחה למשלוח!",
    sendSmsTo: "שלח SMS אל",
    allCustomers: "כל הלקוחות",
    unitsAvailable: "יחידות זמינות",
    pleaseTypeInTheMessage: "אנא הקלד את ההודעה",
    message: "הוֹדָעָה",
    charactersRemaining: "תווים נותרים",
    avoidUseOfSpecialCharacters:
      "הימנע משימוש בתווים מיוחדים ובאמוג'י כדי לשמור על יחידות SMS.",
    note: "הערה",
    errorFetchingCustomersMultilevelDetail:
      "שגיאה בהשגת פרטים מרובי שכבות של לקוחות",
    search: "לחפש",
    reset: "אִתחוּל",
    importCustomer: "ייבוא לקוח",
    addNewCustomer: "הוסף לקוח חדש",
    sendSmsBroadcast: "שלח שידור SMS",
    totalCustomers: "סך כל הלקוחות",
    disbursementDetails: "פרטי ההוצאה",
    paidBy: "שולם על ידי",
    disbursed: "שולמו",
    bankAccountName: "שם חשבון הבנק",
    bankAccountNumber: "מספר חשבון בנק",
    transferInitiated: "העברת יוזמה",
    transferCompleted: "ההעברה הושלמה",
    pleaseEnterAValid: "אנא הכנס חוקי",
    begin: "התחל",
    end: "סוֹף",
    date: "תַאֲרִיך",
    paymentDate: "תאריך תשלום",
    selectDisbursementDuration: "בחר משך התשלום",
    totalSettled: 'סה"כ מיושב',
    totalUnsettled: 'סה"כ לא מיושב',
    toDeleteThisSale: "כדי למחוק את המכירה הזו",
    draftSaleDeletedSuccessfully: "טיוטת מכירה נמחקה בהצלחה!",
    deleteSale: "מחק מבצע",
    pleaseTypeInYourTotal: "אנא הקלד את הסכום הכולל שלך כדי לאשר את המחיקה",
    billDetails: "פרטי ביל",
    printBill: "הדפס את ביל",
    servedBy: "מוגש על ידי",
    total: 'סה"כ',
    createdDate: "תאריך יצירה",
    createdTime: "זמן שנוצר",
    status: "סטָטוּס",
    loginSuccessful: "התחברת בהצלחה",
    pleaseWaitWhileWeConnectAccount: "המתן בזמן שאנו מחברים את חשבונך",
    connectionFailedTryAgain: "חיבור נכשל. בבקשה נסה שוב.",
    connectionSuccessful: "החיבור הצליח",
    viewDetails: "הצג פרטים",
    enable: "לְאַפשֵׁר",
    free: "חינם",
    cost: "עֲלוּת",
    visitWebsite: "בקר באתר",
    pleaseUpgradeYourPlanToPro:
      "אנא שדרג את התוכנית שלך ל- Pro Plus כדי לאפשר יישום זה",
    connectYourBankAccount: "חבר את חשבון הבנק שלך כדי לקבל תשלומים.",
    disable: "השבת",
    enableApp: "אפשר אפליקציה",
    addNewProductToInvoice: "הוסף מוצר חדש לחשבונית",
    toDeleteThisInvoice: "למחוק חשבונית זו",
    invoiceDeletedSuccessfully: "החשבונית נמחקה בהצלחה!",
    deleteInvoice: "מחק חשבונית",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "הוספת בהצלחה הודעת תשלום בחשבונית!",
    pleaseEnterPaymentInstructions: "אנא הזן את הוראות התשלום",
    invoiceId: "מזהה חשבונית",
    paidDate: "תאריך בתשלום",
    reference: "התייחסות",
    productAdded: "המוצר נוסף",
    productOutOfStock: "המוצר אזל מהמלאי. אנא מלאי מחדש.",
    totalInvoices: "חשבונות בסך הכל",
    totalPaidInvoices: "חשבונות בתשלום כולל",
    totalUnpaidInvoices: 'סה"כ חשבונות ללא תשלום',
    loyaltyProgramDeleted: "תוכנית הנאמנות נמחקה",
    thisLoyaltyProgramDeletedSuccessfully: "תוכנית נאמנות זו נמחקה בהצלחה",
    thisLoyaltyProgramEditedSuccessfully: "תוכנית נאמנות זו נערכה בהצלחה",
    loyaltyProgramAddedSuccessfully: "תוכנית הנאמנות נוספה בהצלחה",
    loyaltyProgramEdited: "תוכנית הנאמנות נערכה",
    loyaltyProgramAdded: "נוספה תוכנית נאמנות",
    loyaltyDetails: "פרטי נאמנות",
    doYouWantToCloseDialog: "האם ברצונך לסגור דיאלוג זה?",
    pleaseEnterLoyaltyName: "אנא הזן את שם נאמנותך",
    programType: "סוג התוכנית",
    pleaseSelectProgramType: "אנא בחר סוג תוכנית",
    simplePoints: "נקודות פשוטות",
    stampsProgram: "תוכנית חותמות",
    threshold: "מפתן",
    pleaseEnterLoyaltyThreshold: "אנא הזן את סף הנאמנות",
    reward: "פרס",
    pleaseEnterLoyaltyReward: "אנא הכנס את תגמול הנאמנות",
    totalUserPoints: "סך כל נקודות המשתמש",
    totalUserStamps: "סך כל חותמות משתמשים",
    spendingTarget: "יעד ההוצאה",
    spendingTargetHint1:
      "יעד ההוצאה הוא כמה לקוח חייב להוציא כדי להרוויח את הפרס. ערך מטבע אחד = נקודה אחת.",
    spendingTargetHint2:
      "מטרת החותמות היא כמה חותמות שעל הלקוח לאסוף כדי לזכות בפרס. לְמָשָׁל. 5",
    addNewLoyaltyProgram: "הוסף תוכנית נאמנות חדשה",
    addLoyaltyProgram: "הוסף תוכנית נאמנות",
    loyaltyProgramType: "סוג תוכנית נאמנות",
    pleaseSelectLoyaltyProgramType: "בחר סוג תוכנית נאמנות",
    nameOfProgram: "שם התוכנית",
    pleaseEnterProgramName: "אנא הזן את שם התוכנית",
    whatIsTheReward: "מהו הפרס?",
    egNextPurchaseIsFree: "לְמָשָׁל. הרכישה הבאה היא בחינם",
    customerName: "שם לקוח",
    guestCustomer: "לקוח אורח",
    orderConfirmedSuccessfully: "ההזמנה אושרה בהצלחה",
    orderCancelledSuccessfully: "ההזמנה בוטלה בהצלחה",
    confirmOrder: "אשר הזמנה",
    cancelOrder: "בטל הזמנה",
    allOrders: "כל ההזמנות",
    totalOrders: "הזמנות בסך הכל",
    doYouWantToAcceptOrder: "האם אתה רוצה לקבל הזמנה זו?",
    doYouWantToCancelOrder: "האם ברצונך לבטל הזמנה זו?",
    orderDetails: "פרטי הזמנה",
    orderCreatedAt: "ההזמנה נוצרה ב",
    supplier: "ספק",
    productName: "שם מוצר",
    quantity: "כַּמוּת",
    unitPrice: "מחיר ליחידה",
    receivedBy: "שהתקבל על ידי",
    reportFrom: "דיווח מ",
    totalSupplies: "סך האספקה",
    allRightsReserved: "כל הזכויות שמורות",
    toDeleteThisTransaction: "למחוק עסקה זו",
    transactionDeletedSuccessfully: "העסקה נמחקה בהצלחה. המלאי הוחזר למלאי.",
    deleteTransaction: "מחק עסקה",
    transactionDetails: "פרטי העברה",
    printReceipt: "הדפס קבלה",
    channel: "עָרוּץ",
    discount: "הנחה",
    profit: "רווח",
    discountedSales: "מכירות מוזלות",
    errorFetchingRecord: "שגיאה בעת אחזור הרשומה",
    exportTransactions: "עסקאות ייצוא",
    errorFetchingSalesRecord: "שגיאה בשליפת רשומת מכירות לייצוא.",
    totalSellingPrice: "מחיר מכירה כולל",
    totalCostPrice: "מחיר עלות כולל",
    appliedDiscount: "הנחה מיושמת",
    noOfItems: "מספר פריטים",
    sales: "מכירות",
    export: "יְצוּא",
    totalProfit: "רווח כולל",
    totalBalanceInPeriod: "יתרה כוללת בתקופה",
    allTimeSales: "מכירות בכל הזמנים",
    records: "רשומות",
    todaysSales: "מכירות היום",
    transaction: "עִסקָה",
    youHaveExceededTotalNumberOfProducts:
      "חרגת מהמספר הכולל של המוצרים המותרים בתוכנית שלך. שדרג את התוכנית שלך כדי ליהנות ממגבלה גבוהה יותר.",
    youNeedToHaveLoyaltyProgram:
      "עליך להשתמש בתוכנית נאמנות כדי להשתמש בתכונה זו!",
    price: "מחיר",
    category: "קטגוריה",
    stockTracking: "מעקב אחר מניות",
    stockCount: "ספירת מלאי",
    taxed: "חייב במס",
    originalPrice: "מחיר מקורי",
    costPrice: "מחיר עלות",
    unit: "יחידה",
    productImage: "תמונת המוצר",
    taxRate: "שיעור מס",
    taxType: "סוג מס",
    trackInventory: "עקוב אחר מלאי",
    variants: "גרסאות",
    hasVariants: "בעל וריאציות",
    importProduct: "ייבא מוצר",
    exportProducts: "ייצוא מוצרים",
    addNewProduct: "הוסף מוצר חדש",
    viewCategory: "צפה בקטגוריה",
    viewSuppliers: "צפה בספקים",
    receiveInventory: "קבל מלאי",
    printAllProductsTag: "הדפס את תג המוצרים",
    deleteAll: "מחק הכל",
    totalProducts: "מוצרים בסך הכל",
    youveSuccessfullyAddedANewCategory: "הוספת קטגוריה חדשה בהצלחה",
    addNewCategory: "הוסף קטגוריה חדשה",
    addCategory: "הוסף קטגוריה",
    categoryName: "שם קטגוריה",
    pleaseEnterCategoryName: "אנא הזן את שם הקטגוריה",
    stampsTarget: "מטרת חותמות",
    sendInventory: "שלח מלאי",
    productDetails: "פרטי מוצר",
    youveSuccessfullyEditedThisCategory: "ערכת בהצלחה את הקטגוריה הזו",
    update: "עדכון",
    categoryList: "רשימת קטגוריות",
    categories: "קטגוריות",
    enterQuantityToUpdate: "הזן כמות לעדכון",
    inventorySentSuccessfully: "המלאי נשלח בהצלחה!",
    updateInventory: "עדכן מלאי",
    currentQuantity: "כמות נוכחית",
    pleaseEnterQuantityToAdd: "אנא הכנס את הכמות שברצונך להוסיף",
    pleaseSelectABranch: "אנא בחר סניף",
    searchForBranch: "חפש סניף",
    youCantSendMoreThanStock: "אתה לא יכול לשלוח יותר ממה שיש לך במלאי",
    send: "לִשְׁלוֹחַ",
    pleaseEnterQuantityToSend: "אנא הכנס את הכמות שברצונך לשלוח",
    productNameIsRequiredOnRow: "שם המוצר נדרש בשורה",
    productCategoryIsRequiredOnRow: "קטגוריית המוצר נדרשת בשורה",
    productPriceIsRequiredOnRow: "מחיר המוצר נדרש בשורה",
    productUnitIsRequiredOnRow: "יחידת המוצר נדרשת בשורה",
    productQuantityIsRequiredOnRow: "כמות המוצר נדרשת בשורה",
    productVariantsRequireTracking: "גרסאות המוצר דורשות מעקב!",
    pleaseAddVariantClickButton: "אנא הוסף גרסה על ידי לחיצה על כפתור ההוספה!",
    totalVariantsMoreThanSelectedQuantity:
      "סך כל הגרסאות הן יותר מכמות המוצר שנבחרה, Pls מפחיתות את כמות הגרסאות",
    productEditedSuccessfully: "המוצר נערך בהצלחה!",
    fileTooLargeLessThan4Mb:
      "גודל הקובץ גדול מדי! גודל הקובץ צריך להיות קטן מ- 4MB.",
    suchVariantAlreadyExist: "גרסה כזו כבר קיימת",
    addVariants: "הוסף גרסאות",
    editProduct: "ערוך את המוצר",
    pleaseEnterProductName: "אנא הזן את שם המוצר",
    pleaseEnterProductPrice: "אנא הזן את מחיר המוצר",
    pleaseEnterProductOriginalPrice: "אנא הזן את המחיר המקורי של המוצר",
    productDescription: "תיאור מוצר",
    selectProductCategory: "בחר קטגוריית מוצרים",
    pleaseSelectProductCategory: "אנא בחר קטגוריית מוצרים",
    productCostPrice: "מחיר עלות המוצר",
    productSellingPrice: "מחיר מכירת המוצר",
    productOriginalPrice: "מחיר מקורי של המוצר",
    maxFileSizeAllowedIs4Mb: "מקסימום. גודל הקובץ המותר הוא 4Mb",
    productsWithPicturesArePublished:
      "מוצרים עם תמונות מתפרסמים ב- Discover כדי לקבל הזמנות",
    shouldThisProductBeTracked: "האם יש לעקוב אחר המוצר הזה?",
    pleaseSelectStockUnit: "אנא בחר יחידת מלאי",
    stockUnit: "יחידת מלאי",
    bag: "תיק",
    bottle: "בקבוק",
    bunch: "צְרוֹר",
    can: "פחית",
    carton: "קרטון",
    crate: "אַרְגָז",
    cup: "גָבִיעַ",
    dozen: "תְרֵיסַר",
    gigabytes: "GIGABYTES",
    gram: "גְרַם",
    kilogram: "קִילוֹגרָם",
    litre: "לִיטר",
    pack: "חבילה",
    pair: "זוג",
    pieces: "חתיכות",
    plate: "צַלַחַת",
    tonne: "TONNE (MT)",
    uNIT: "יחידה",
    yard: "חָצֵר",
    pleaseEnterProductQuantity: "אנא הכנס את כמות המוצר",
    productQuantity: "כמות המוצר",
    isThisProductTaxed: "האם על מוצר זה יש מס?",
    selectTaxType: "בחר סוג מס",
    pleaseSelectTaxType: "אנא בחר סוג מס",
    progressive: "פּרוֹגרֵסִיבִי",
    proportional: "יַחֲסִי",
    pleaseEnterProductTaxRate: "אנא הזן את שיעור מס המוצר",
    doesProductHaveVariants: "האם למוצר זה יש גרסאות?",
    type: "סוּג",
    value: "ערך",
    pleaseEnterVariantType: "אנא הזן את סוג הגרסה",
    pleaseEnterVariantValue: "אנא הזן את ערך הגרסה",
    pleaseEnterVariantPrice: "אנא הזן את המחיר של הגרסה",
    pleaseEnterVariantQuantity: "אנא הכנס את כמות הגרסה",
    productDeletedSuccessfully: "המוצר נמחק בהצלחה!",
    categoryDeletedSuccessfully: "הקטגוריה נמחקה בהצלחה!",
    toDeleteThisProduct: "כדי למחוק מוצר זה",
    invalidProductQuantity: "כמות מוצר לא חוקית",
    quantityAddedIsOverStock: "הכמות שאתה מוסיף עולה על מה שיש לך במלאי.",
    itemInventoryNotTracked: "לא מתבצע מעקב אחר מלאי הפריטים",
    addBulkQuantity: "הוסף כמות בתפזורת",
    enterBulkQuantity: "הזן כמות בתפזורת",
    pleaseEnterBulkQuantity: "אנא הזן כמות בכמות גדולה",
    youveSuccessfullyAddedANewProduct: "הוספת מוצר חדש בהצלחה!",
    pleaseEnterProductSellingPrice: "אנא הכנס את מחיר המכירה של המוצר",
    doYouWantToTrackProductStock: "האם אתה רוצה לעקוב אחר מלאי המוצרים?",
    sellingPrice: "מחיר המכירה",
    setProductExpiryReminder: "הגדר תזכורת לפקיעת המוצר",
    productExpiryDate: "תאריך תפוגה של המוצר",
    startRemindingFrom: "התחל להזכיר מאת",
    productSuppliesAddedSuccessfully: "הוספתם מוצרי מזון בהצלחה.",
    addProductSupplies: "הוסף ציוד מוצרים",
    pleaseSelectSupplier: "אנא בחר ספק",
    nameOfProduct: "שם המוצר",
    pleaseSelectProduct: "אנא בחר מוצר",
    productVariant: "גרסת מוצר",
    pleaseSelectAVariant: "אנא בחר גרסה",
    pleaseEnterUnitPrice: "אנא הזן מחיר יחידה",
    businessBranch: "סניף עסקים",
    pleaseSelectBranch: "אנא בחר סניף",
    youveSuccessfullyAddedANewSupplier: "הוספת בהצלחה ספק חדש",
    addSupplier: "הוסף ספק",
    pleaseEnterSupplierEmail: 'אנא הכנס את כתובת הדוא"ל של הספק',
    pleaseAddADescription: "אנא הוסף תיאור",
    anErrorOccuredProductsDeleted:
      "אירעה שגיאה בעת ביצוע הפעולה. שים לב, מעט מוצרים עשויים להימחק בתהליך",
    bulkDelete: "מחק בכמות גדולה",
    youAreAboutToDelete: "אתה עומד למחוק",
    product: "מוצר",
    isDueToRedeem: "אמור לפדות",
    aReward: "פרס",
    pleaseSelectCustomerToReeemReward: "אנא בחר לקוח למימוש הפרס.",
    youHaveNoLoyaltyProgramRunning: "אין לך תוכנית נאמנות פעילה",
    customerHhasNoPointsInLoyaltyProgram: "ללקוח אין נקודות בתוכנית נאמנות זו",
    proceedWithPayment: "להמשיך עם התשלום?",
    youAreAboutToPayForTransactionUsingPoints:
      "אתה עומד לשלם עבור העסקה באמצעות נקודות.",
    customerHas: "יש ללקוח",
    worth: "שִׁוּוּי",
    andIsNotSufficientToPayForTransaction:
      "ואינו מספיק לשלם עבור עסקה זו. האם הם ירצו להוסיף את היתרה באמצעי תשלום אחר?",
    addCustomerLoyalty: "הוסף נאמנות לקוחות",
    pleaseAddCustomerFirst: "אנא הוסף או בחר לקוח תחילה.",
    pleaseWaitWhileWeGetReady: "אנא המתן, עד שנתכונן",
    lowStock: "מלאי נמוך",
    pleaseEnterAmountTendered: "אנא הזן את הסכום שנמסר",
    areYouSureToBookSaleOffline:
      "האם אתה בטוח שברצונך להזמין מבצע זה במצב לא מקוון?",
    saleWouldBeBookedAutomatically:
      "המכירה תתבצע באופן אוטומטי כאשר תפעיל את האינטרנט שלך",
    offlineSalesBookingCancelled: "הזמנת מכירות לא מקוונות בוטלה",
    covid19Message:
      "COVID19: שטפו את ידיכם בסבון או חיטרו כדי לעצור את ההתפשטות. הישאר בטוח תמיד",
    saleSuccessfullyRecorded: "המכירה נרשמה בהצלחה!",
    sendReceiptToEmail: "שלח קבלה למייל",
    sendThankYouSms: "שלח הודעת SMS",
    sendShippingDetails: "שלח פרטי משלוח",
    addLoyalty: "הוסף נאמנות",
    searchCustomerNameOrNumber: "חפש שם לקוחות או מספר",
    clickTheSearchCustomerBox: "לחץ על תיבת החיפוש של לקוחות וכרטיס הסריקה",
    enterProductPrice: "הזן מחיר מוצר",
    enterPriceFor: "הזן מחיר עבור",
    searchForProduct: "חפש מוצר",
    all: "את כל",
    backToDashboard: "חזרה ללוח המחוונים",
    viewDraftSales: "צפה בטיוטות מכירות",
    variantSelected: "נבחר גרסה",
    variant: "גִרְסָה אַחֶרֶת",
    thePreviousVariantSelectionNotAvailable:
      "מבחר הגרסאות הקודם אינו זמין עבור הגרסה החדשה שנבחרה בהתאם למחיר, אנא שנה את בחירתך.",
    pleaseSupplyPositiveQuantityNumber: "אנא ספק מספר כמות פוזיטיבי",
    lowStockFor: "מלאי נמוך ל",
    clearVariants: "גרסאות ברורות",
    pleaseEnterQuantity: "אנא הכנס כמות",
    picture: "תְמוּנָה",
    redemptionToken: "אסימון גאולה",
    token: "אֲסִימוֹן",
    totalSpent: "סך ההוצאה",
    confirmPayment: "אשר תשלום",
    hasPaymentBeenMade: "האם התשלום עבר בהצלחה?",
    enterTransactionReference: "הזן את הפניה לעסקה ששילמת איתה",
    pleaseSelectACustomer: "אנא בחר לקוח!",
    areYouSureToApplyDiscount: "האם אתה בטוח שאתה רוצה להחיל הנחה?",
    addYourBankAccountToEnableUssd:
      "הוסף את חשבון הבנק שלך כדי לאפשר העברת USSD מיידית באמצעות uPay",
    totalAmountToPay: "סכום כולל לתשלום",
    doYouWantToCancelTransaction: "האם ברצונך לבטל עסקה זו?",
    savePrintBill: "שמור/הדפס הצעת חוק",
    enterAmountCollectedForCustomer: "הזן סכום שנאסף עבור הלקוח",
    recordSale: "מכירת תקליטים",
    checkOutWith: "בדוק עם",
    instantTransfer: "העברה מיידית",
    dialTheUSSDCode: "חייג את קוד USSD",
    pleaseSelectABank: "אנא בחר בנק",
    payWithUSSD: "שלם באמצעות USSD",
    sendBillTo: " - שלח את ביל אל",
    waitingForUSSDTransfer: "מחכה להעברת USSD",
    percent: "אָחוּז",
    applyDiscount: "החל הנחה",
    thisBillHasBeenSaved: "הצעת חוק זו נשמרה",
    saveDraft: "שמור טיוטה",
    pleaseTypeANameToIdentifyCustomer: "אנא הקלד שם לזיהוי הלקוח",
    paymentDetails: "פרטי תשלום",
    basePrice: "מחיר בסיסי",
    staff: "צוות",
    subTotal: "סכום משנה",
    durationMonths: "משך (חודשים)",
    selectADuration: "בחר משך זמן",
    oneMonth: "חודש 1",
    twoMonths: "2 חודשים",
    threeMonths: "3 חודשים",
    sixMonths: "6 חודשים",
    twelveMonths: "12 חודשים",
    eighteenMonths: "18 חודשים",
    twentyFourMonths: "24 חודשים",
    twoMonthsFree: "(חודשיים חינם)",
    threeMonthsFree: "(3 חודשים חינם)",
    fiveMonthsFree: "(5 חודשים חינם)",
    yourAccountHasBeen: "החשבון שלך היה",
    renewed: "מְחוּדָשׁ",
    upgraded: "משודרג",
    successfully: "בְּהַצלָחָה",
    yourSubscription: "המנוי שלך",
    youAreCurrentlyEnrolledOnA: "כרגע אתה רשום ב-",
    pleaseChooseAPaymentOption: "אנא בחר באפשרות תשלום",
    planRenewal: "התחדשות תכנית",
    planUpgrade: "תוכנית שדרוג",
    pleaseSelectDurationToPayFor: "אנא בחר משך זמן שאתה מתכוון לשלם עבורו",
    staffAccounts: "חשבונות צוות",
    ecommerce: "מסחר אלקטרוני",
    pleaseSelectAPlan: "אנא בחר תוכנית",
    includeAddons: "כלול תוספות",
    viewTransactions: "הצג עסקאות",
    customerHasNoCompletedTansactions: "ללקוח טרם הושלמו פעולות הטנס",
    branch: "ענף",
    enterNumberOfEcommerceBranches: "הזן מספר ענפי מסחר אלקטרוני",
    enterNumberOfEcommerceBranchesToPay:
      "הזן את מספר ענפי המסחר האלקטרוני שאתה מתכוון לשלם עבורם",
    ecommerceIntegration: "שילוב מסחר אלקטרוני",
    enterNumberOfBranches: "הזן מספר סניפים",
    enterNumberOfAdditionalBranchesToPay:
      "הזן את מספר הענפים הנוספים שאתה מתכוון לשלם עבורם",
    enterNumberOfStaffs: "הזן מספר צוותים",
    enterNumberOfStaffsToPayFor: "הזן את מספר הצוותים שאתה מתכוון לשלם עבורם",
    discountApplies: "ההנחה חלה",
    starsOnThe: "כוכבים על",
    offer: "הַצָעָה",
    get: "לקבל",
    moreStarsToRedeem: "עוד כוכבים למימוש",
    taxVat: 'מס (מע"מ)',
    callCashierToCompletePayment: "התקשר לקופה להשלמת התשלום",
    receipt: "קַבָּלָה",
    dear: "יָקָר",
    thankYouForYourOrderFindGoods:
      "תודה על הזמנתך. אנא מצא את הסחורה הבאה המסופקת, כפי שהוסכם.",
    shippingNote: "משלוח רשום",
    enterShippingNote: "הזן הערת משלוח",
    shippingAddress: "כתובת למשלוח",
    enterShippingAddress: "הזן כתובת למשלוח",
    wellDoneYouAreDueToRedeem: "כל הכבוד! אתה אמור לפדות",
    toGetYourRewardNextVisit: "כדי לקבל את הפרס שלך בביקור הבא שלך. תודה",
    pointsOnThe: "נקודות על ה",
    morePointsToRedeem: "נקודות נוספות למימוש",
    showCode: "הצג קוד",
    toGetYourRewardOnNextVisit: "כדי לקבל את הפרס שלך בביקור הבא שלך. תודה",
    earn: "להרוויח",
    delivaryNote: "הערת מסירה",
    draftSales: "טיוטת מכירות",
    startDate: "תאריך התחלה",
    endDate: "תאריך סיום",
    orders: "הזמנות",
    checkout: "לבדוק",
    noProductItem: "אין פריט מוצר",
    selectProductImage: "בחר תמונת מוצר",
    selectCountry: "בחר מדינה",
    selectRegion: "בחר מדינה/אזור",
    printProductTag: "הדפס תג מוצר",
    transactionReference: "התייחסות עסקה",
    Cashier: "קופאית",
    Manager: "מנהל",
    Owner: "בעלים",
    Admin: "מנהל מערכת",
    addPartners: "הוסף שותפים",
    addNewLoyaltyPartner: "הוסף שותף נאמנות חדש",
    pleaseEnterCompanyName: "נא להזין את שם החברה",
    companyName: "שם החברה",
    pleaseEnterCompanyRepName: "נא להזין את שם נציג החברה",
    companyRepName: "שם נציג החברה",
    pleaseEnterCompanyRepEmail: "נא להזין את האימייל של נציג החברה",
    companyRepEmail: "אימייל של נציג החברה",
    pleaseEnterCompanyRepPhone: "נא להזין את מספר הטלפון של נציג החברה",
    companyRepPhone: "מספר טלפון של נציג החברה",
    addReward: "הוסף פרס",
    pleaseEnterRewardName: "נא להזין את שם הפרס",
    rewardName: "שם פרס",
    rewardQuantity: "כמות תגמול",
    rewardDescription: "תיאור התגמול",
    rewardType: "סוג תגמול",
    pleaseEnterRewardType: "נא להזין את סוג הפרס",
    pleaseEnterRewardQuantity: "נא להזין את כמות התגמול",
    pleaseEnterRewardDescription: "נא להזין את תיאור הפרס",
    fineDining: "אוכל משובח",
    luxuryFashion: "אופנה יוקרתית",
    hotels: "בתי מלון",
    travel: "לִנְסוֹעַ",
    foodAndBeverage: "מזון ומשקאות",
    fashion: "אופנה",
    health: "בְּרִיאוּת",
    furniture: "רְהִיטִים",
    entertainment: "בידור",
    automobile: "מְכוֹנִית",
    education: "חינוך",
    beautyAndSpa: "יופי וספא",
    staycation: "Staycation",
    events: "אירועים",
    trips: "טיולים",
    oilAndGas: "נפט וגז",
    laundry: "כְּבִיסָה",
    partnerCategory: "קטגוריית שותפים",
    freeItem: "פריט חינם",
  },
  Icelandic: {
    cashier: "gjaldkeri",
    manager: "framkvæmdastjóri",
    owner: "eigandi",
    online: "á netinu",
    offline: "án nettengingar",
    changePassword: "Breyta lykilorði",
    currentPasswordMessage: "Sláðu inn núverandi aðgangsorð",
    passwordMessage: "Sláðu inn lykilorðið þitt",
    currentPassword: "Núverandi lykilorð",
    password: "lykilorð",
    confirmPasswordMessage: "Vinsamlegast staðfestu lykilorðið þitt!",
    confirmPassword: "Staðfesta lykilorð",
    sendViaEmail: "Senda með tölvupósti",
    sendViaWhatsapp: "Sendu í gegnum WhatsApp",
    downloadPdf: "Sækja PDF",
    paid: "greitt",
    unpaid: "launalaust",
    partial: "að hluta",
    closeInvoice: "Viltu loka reikningnum?",
    closeInvoiceConfirmation:
      "Ekki er víst að reikningur sé vistaður. Viltu loka?",
    yes: "Já",
    no: "nei",
    invoice: "Reikningur",
    wasDeducted: "var dregið frá",
    for: "fyrir",
    item: "Atriði",
    addProduct: "Bæta við vöru",
    paymentReference: "Greiðsluviðmiðun",
    amountPaid: "Greidd upphæð",
    discountAmount: "Afsláttarupphæð",
    amountDue: "Upphæð til greiðslu",
    amount: "Magn",
    dueDate: "Gjalddagi",
    paymentType: "Greiðslugerð",
    card: "Spil",
    cash: "Reiðufé",
    bankTransfer: "Millifærsla",
    paymentMessage: "Greiðsluskilaboð",
    description: "Lýsing",
    sendReceipt: "Senda kvittun",
    delete: "Eyða",
    save: "Vista",
    resend: "Senda aftur",
    saveAndSend: "Vista",
    invoiceSaved: "Reikningur vistaður!",
    selectPaymentMethod: "Vinsamlegast veldu greiðslumáta!",
    selectCustomer: "Vinsamlegast veldu viðskiptavin!",
    cartEmptyError:
      "Karfa listi getur ekki verið tómur, lokaðu reikningnum og bættu vörunni við körfuna!",
    subscriptionExpired:
      "Áskrift þín er útrunnin og reikningurinn þinn er nú takmarkaður. Smelltu á hnappinn hér að neðan til að endurnýja reikninginn þinn",
    renew: "Endurnýja",
    holdOn: "Bíddu",
    customerBank: "Viðskiptavinabanki",
    cancel: "Hætta við",
    selectACustomer: "Veldu Viðskiptavinur",
    invoiceSuccessfulPdfError:
      "Reikningur var búinn til en PDF kynslóð tekur lengri tíma en venjulega. Vinsamlegast athugaðu aftur fljótlega.",
    downloadingInvoice: "Sækja reikning",
    downloadingReceipt: "Sækir kvittun",
    whatsappReceiptError:
      "Villa kom upp við að senda kvittunina með WhatsApp. Reyndu aftur.",
    receiptToWhatsapp: "Kvittun send áfram til WhatsApp",
    thankYouForPatronage: "Þakka þér fyrir vernd þína",
    hereIsYourReceipt: "Hér er greiðslukvittun þín",
    errorSendingEmailReceipt:
      "Villa kom upp við að senda kvittunina með tölvupósti. Reyndu aftur eða hafðu samband við þjónustudeild",
    receiptSentToEmail:
      "Kvittun hefur verið send í tölvupósti viðskiptavinarins",
    invoiceSentToEmail:
      "Reikningur hefur verið sendur í tölvupóst viðskiptavinar",
    invoiceSuccessWhatsappError:
      "Reikningur var búinn til en villa kom upp við að senda til WhatsApp. Reyndu aftur á reikningalistanum",
    invoiceSuccessfulEmailError:
      "Reikningur var búinn til en villa kom upp við sendingu sem tölvupóst. Reyndu aftur af reikningalistanum",
    invoiceSentToWhatsapp: "Reikningur sendur til WhatsApp",
    hello: "Halló",
    pleaseDownloadInvoice: "Vinsamlegast hlaðið niður reikningi",
    pleaseDownloadReceipt: "Vinsamlegast hlaðið niður kvittun",
    from: "frá",
    email: "Netfang",
    upgrade: "Uppfærsla",
    youAreOnFreePlan: "Þú ert með ókeypis áætlun.",
    clickOn: "Smelltu á",
    yourPlanInFewSteps: " áskriftina þinni í nokkrum skjótum skrefum.",
    to: "til",
    yourSubscriptionHasExpired:
      "Áskrift þín er útrunnin og reikningurinn þinn er nú takmarkaður.",
    days: "daga",
    yourSubscriptionExpiresIn: "Loystar áskrift þín rennur út",
    createAcount: "Búðu til reikning",
    signIn: "Skráðu þig inn",
    continue: "Áfram",
    enterOtp: "Sláðu inn OTP PIN",
    enterValidOtp: "Sláðu inn gilt PIN -númer",
    pin: "PIN -númer",
    tokenSentToMail: "Tákn hefur verið sent í tölvupóstinn þinn",
    passwordResetSuccessful: "Endurstilling lykilorðs tókst",
    somethingWentWrong: "Eitthvað fór úrskeiðis!",
    resetPassword: "Endur stilla lykilorð",
    iHaveResetCode: "Ég er með núllstillingarlykil",
    pleaseEnterEmail: "Sláðu inn netfangið þitt",
    aTokenWillBeSentToEmail: "Tákn yrði sent í tölvupóstinn þinn",
    enterEmail: "Sláðu inn netfangið þitt",
    sendinYourToken: "Sendi táknið þitt ...",
    makeSureItMatchesPassword:
      "Gakktu úr skugga um að það passi við nýja lykilorðið þitt",
    pleaseChooseNewPassword: "Vinsamlegast veldu nýtt lykilorð",
    chooseNewPassword: "Veldu nýtt lykilorð",
    enterNewPassword: "Sláðu inn nýja lykilorðið þitt til að staðfesta",
    enterTokenSent: "Sláðu inn táknið sem sent var póstinn þinn",
    resetToken: "Endurstilla auðkenni",
    resettingPassword: "Endurstillir lykilorðið þitt ...",
    signUp: "Skráðu þig",
    adminSignInWithEmail:
      " Stjórnandi skráir sig inn með tölvupósti á meðan starfsfólk skráir sig inn með notendanafni.",
    pleaseEnterEmailOrUsername: "Sláðu inn netfangið þitt eða notendanafn",
    emailOrUsername: "Netfang eða notendanafn",
    pleaseEnterPassword: "Sláðu inn lykilorð",
    createAnAccount: "Búðu til reikning",
    forgotPassword: "Gleymt lykilorð?",
    enterPhoneNumber: "Sláðu inn símanúmer",
    personalData: "Persónulegar upplýsingar",
    validateConfirmationCOde: "Staðfestu staðfestingarkóða",
    pleaseEnterFirstName: "Sláðu inn eiginnafnið þitt",
    pleaseEnterPhoneNumber: "Sláðu inn símanúmerið þitt",
    pleaseEnterLastName: "Vinsamlegast sláðu inn eftirnafnið þitt",
    pleaseEnterBusinessName: "Sláðu inn nafn fyrirtækis þíns",
    firstName: "Fyrsta nafn",
    lastName: "Eftirnafn",
    businessName: "viðskiptanafn",
    previous: "Fyrri",
    next: "Næst",
    pleaseSelectBusinessCategory: "Vinsamlegast veldu fyrirtækisflokkinn þinn",
    pleaseEnterValidEmail: "Sláðu inn gilt netfang",
    pleaseEnterPostCode: "Sláðu inn póstnúmer",
    postCode: "Póstnúmer",
    phoneNumberInUse: "Þetta símanúmer er þegar í notkun!",
    emailInUse: "Þessi tölvupóstur er þegar í notkun!",
    foodAndDrinks: "Matur og drykkir",
    salonAndBeauty: "Snyrtistofa og fegurð",
    fashionAndAccessories: "Tíska og fylgihlutir",
    gymAndFitness: "Líkamsrækt og líkamsrækt",
    travelAndHotel: "Ferðalög og hótel",
    homeAndGifts: "Heim og gjafir",
    washingAndCleaning: "Þvottur og þrif",
    gadgetsAndElectronics: "Græjur og rafeindatækni",
    groceries: "Matvörur",
    others: "Aðrir",
    submit: "Sendu",
    accountCreatedSuccessful: "Reikningurinn þinn var búinn til.",
    pleaseEnterAddress: "Vinsamlegast sláðu inn heimilisfangið þitt",
    addressLine1: "Heimilisfang 1",
    addressLine2: "Heimilisfang lína 2",
    choosePassword: "Veldu lykilorð",
    passwordMustBe6Characters:
      "Lykilorð verður að vera að minnsta kosti 6 stafir.",
    howDidYouHearLoystar: "Hvernig fréttirðu af Loystar?",
    referralCode: "tilvísunarkóði",
    byClickingTheButton:
      " Með því að smella á hnappinn hér að neðan samþykkir þú",
    termsAndSevice: "Skilmálar",
    wereCreatingAccount: "Við erum að búa til reikninginn þinn",
    proceed: "Haltu áfram",
    verificationCodeMustBe6: "Staðfestingarkóði verður að vera 6 tölustafir",
    pleaseEnter6DigitCode: "Sláðu inn 6 stafa kóða",
    enterVerificationCode: "Sláðu inn staðfestingarkóða",
    resendToken: "Sendu aftur auðkenni",
    verify: "Staðfestu",
    transactions: "Viðskipti",
    todaySales: "Sala í dag",
    salesHistory: "Sölusaga",
    supplyHistory: "Framboðssaga",
    new: "Nýtt",
    invoices: "Reikningar",
    disbursements: "Útgreiðslur",
    offlineSales: "Sala utan nets",
    products: "Vörur",
    customers: "Viðskiptavinir",
    multiLevelLoyalty: "Hollusta á mörgum stigum",
    loyaltyPrograms: "Hollustuáætlanir",
    members: "Meðlimir",
    appStore: "App Store",
    orderMenu: "Panta matseðil",
    settings: "Stillingar",
    staffAndBranches: "Starfsfólk og útibú",
    myAccount: "Minn reikningur",
    switchToSellMode: "Skiptu yfir í söluham",
    signOut: "Útskrá",
    getFreeSubscription: "Fáðu ókeypis áskrift",
    onlyNumbersAllowed: "Aðeins tölur eru leyfðar",
    yourAccountMustBe10Digits:
      "reikningsnúmerið þitt verður að vera 10 stafa númer",
    yourBvnMustBe11: "BVN þitt verður að vera 11 stafa tala",
    pleaseSelectBank: "Vinsamlegast veldu bankann þinn",
    selectYourBank: "Veldu bankann þinn",
    enterBankAccountNumber: "Sláðu inn bankareikningsnúmer",
    enterBvnNumber: "Sláðu inn BVN þitt",
    connectBankAccount: "Tengdu bankareikning",
    passwordResetSuccessfulAndSignOut:
      "Lykilorði þínu hefur verið endurstillt með góðum árangri. Smelltu á hnappinn Skráðu þig út hér að neðan til að skrá þig inn aftur",
    enterCurrentPassword: "Sláðu inn núverandi lykilorð",
    pleaseEnterCurrentPassword: "Sláðu inn núverandi lykilorð",
    phoneNumber: "Símanúmer",
    sex: "Kynlíf",
    dateOfBirth: "Fæðingardagur",
    state: "Ríki",
    country: "Land",
    loyaltyId: "Hollusta auðkenni",
    createdAt: "Búið til kl",
    noOfTransactionsMade: "Fjöldi viðskipta",
    yourDownloadWillStart: "Niðurhalið þitt byrjar eftir augnablik",
    exportCustomers: "Flytja út viðskiptavini",
    youhaveSuccessfullyToppedUp: "Þú hefur fyllt upp SMS einingar þínar.",
    youNowHave: "Þú hefur nú",
    smsUnits: "SMS einingar",
    enterNumberOfSmsUnits:
      "Sláðu inn fjölda SMS -eininga sem þú ætlar að kaupa",
    pleaseEnterANumericValue: "Sláðu inn tölulegt gildi",
    pay: "Borga",
    accountEditedSuccessfully: "Reikningnum þínum var breytt.",
    youAeCurrentlyOn: "Þú ert núna",
    plan: "Áætlun",
    userData: "Notandagögn",
    businessData: "VIÐSKIPTAGÖGN",
    businessCategory: "Viðskiptaverslun",
    pleaseSelectCurrency: "Vinsamlegast veldu gjaldmiðilinn þinn",
    selectYourCurrency: "Veldu gjaldmiðilinn þinn",
    purchaseMoreSmsUnits:
      "Kauptu fleiri SMS einingar með því að nota formið hér að neðan",
    youHave: "Þú hefur",
    atLeast4SmsUnitsRrequired:
      "Að minnsta kosti 4 sms einingar eru nauðsynlegar til staðfestingar, vinsamlegast fylltu á!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Vinsamlegast staðfestu bankareikninginn þinn til að fara að kröfunum „Þekkja viðskiptavininn“ (KYC). Þetta gerir þér kleift að innheimta greiðslur með USSD eða tafarlausri millifærslu, taka á móti pöntunum frá viðskiptavinum og vinna úr uppgjörum viðskipta. Gildir aðeins fyrir kaupmenn frá Nígeríu. Vinsamlegast ýttu á hnappinn hér að neðan til að byrja.",
    reConnectBankAccount: "Tengdu bankareikning aftur",
    accountName: "Nafn reiknings",
    accountNumber: "Reikningsnúmer",
    bankName: "Nafn banka",
    verified: "Staðfest",
    accountDetails: "Reikningsupplýsingar",
    kycBankAccount: "KYC",
    createTier: "Búa til stig",
    fileUploadSuccessful: "skrá var hlaðið upp",
    fileUploadFailed: "upphleðslu skráar mistókst",
    createLoyaltyProgram: "Búðu til hollustuáætlun",
    createLoyalty: "Búðu til hollustu",
    name: "Nafn",
    loyaltyArtwork: "Hollusta listaverk",
    clickToUpload: "Smelltu til að hlaða upp",
    amountToPointsRatio: "Upphæð í punktahlutfalli",
    points: "Stig",
    recommendedAmountToPointRatio:
      "Mælt með: ₦ 1 til 1 stig. þ.e.a.s. fyrir hverja 1 Naira sem eytt er fá viðskiptavinir þínir 1 stig",
    pleaseTypeIn: "Vinsamlegast sláðu inn",
    toDeleteLoyalty: "að eyða þessari hollustu",
    deleteLoyalty: "Eyða hollustu",
    toConfirm: "að staðfesta",
    edit: "Breyta",
    pointsAwardedSuccessfully: "Stig veitt með góðum árangri.",
    enterPointValueToAward:
      "Sláðu inn punktagildi sem þú vilt veita viðskiptavinum",
    award: "Verðlaun",
    awardPointValuesToCustomer: "Verðlaunapunktsverðmæti til viðskiptavinar",
    enrollMembersToLoyalty: "Skráðu félaga í hollustu",
    awardPoints: "Verðlaunastig",
    enroll: "Skráðu þig",
    home: "Heim",
    loyalty: "Hollusta",
    enrollCustomers: "Skráðu viðskiptavini",
    selected: "Valið",
    customer: "Viðskiptavinur",
    loyaltyActivationSuccessful: "Hollusta virkjunin heppnast vel.",
    loyaltyDeactivationSuccessful: "Hollustuhættir að slökkva á árangri.",
    viewTier: "Skoða Tier",
    deactivate: "Afvirkja",
    activate: "Virkja",
    actions: "Aðgerðir",
    nameOfLoyalty: "Nafn hollustu",
    loyaltyStatus: "Hollusta Staða Hollusta Staða",
    numberOfTiers: "Fjöldi flokka",
    createdOn: "Búið til",
    createATier: "Búðu til stig",
    stopCreatingTierConfirmation: "Viltu hætta að búa til þrep?",
    stopEditingTierConfirmation: "Viltu hætta að breyta þessu stigi?",
    nameOfTier: "Nafn Tier",
    minimumSpendingTarget: "Lágmarks eyðslumark",
    maximumSpendingTarget: "Hámarks eyðslumark",
    minimumSpendingTargetRequired: "lágmarksútgjaldamarkmið er krafist",
    maximumSpendingTargetRequired: "hámarksútgjaldamarkmið er krafist",
    rewardSponsor: "Verðlaunastuðningsaðili",
    pleaseChooseARewardSponsor: "Veldu endurgjaldsstyrktaraðila",
    self: "Sjálf",
    partner: "Félagi",
    rewardPartner: "Verðlaunafélagi",
    pleaseSelectRewardPartner: "Vinsamlegast veldu verðlaunafélaga þinn",
    rewards: "Verðlaun",
    pleaseSelectAReward: "Vinsamlegast veldu verðlaun",
    instructionsOnRedeemingReward:
      "Leiðbeiningar um hvernig viðskiptavinur á að innleysa umbun",
    pleaseFillInThisField: "Vinsamlegast fylltu út þennan reit!",
    toDeleteThisTier: " að eyða þessu þrepi",
    deleteTier: "Eyða Tier",
    viewMembers: "Skoða meðlimi",
    membersEnrolled: "Félagar skráðir",
    instruction: "Kennsla",
    membersIn: "Meðlimir í",
    availableTiersInLoyalty: "Laus stig (s) í vildaráætlun",
    tiers: "Stigum",
    totalTier: "HEILDAR TIG",
    availableLoyaltyProgramme: "Tryggingaráætlun í boði",
    totalLoyalties: "HEILDAR TRÚAR",
    memberDetails: "Upplýsingar um félaga",
    nameOfCustomer: "Nafn viðskiptavinar",
    address: "Heimilisfang",
    allEnrolledMembers: "Allir skráðir meðlimir",
    thisIsToWishYouHappyBirthday:
      "Þetta er að óska þér til hamingju með afmælið og farsælt líf. Takk fyrir allt sem þú ert til Loyster. Gleðilega hátíð!",
    inputAnOfferPlease: "Sláðu inn tilboð takk",
    pleaseSelectTheInsertPoint:
      "Veldu innsetningarpunktinn í skilaboðunum og smelltu aftur",
    birthdayOfferAndMessage: "Afmælistilboð og skilaboð",
    birthdayOffer: "Afmælistilboð",
    birthdayMessage: "Afmælisskilaboð",
    noOfferFound: "Ekkert tilboð fannst",
    settingABirthdayOffer:
      "Með því að setja upp afmælistilboð geta viðskiptavinir fengið þetta tilboð með SMS á afmælisdaginn",
    createOffer: "Búa til tilboð",
    whatIsTheOffer: "Hvert er tilboðið?",
    editMessage: "Breyta skilaboðum",
    insert: "Setja inn",
    theNameOfCustomerInserted: "Nafn viðskiptavinarins verður sett inn hér",
    theBirtdayOfferInserted: "Afmælistilboðið verður sett inn hér",
    youSuccessfullyAddedNewBranch: "Þú hefur bætt við nýrri grein!",
    addNewBranch: "Bæta við nýrri útibúi",
    addBranch: "Bæta við útibúi",
    additionalBranchWillIncur: "Viðbótargrein myndi hafa í för með sér",
    perBranch: "á útibú",
    ecommerceBranchCosts: "Kostnaður við netverslun",
    pleaseEnterBranchName: "Sláðu inn heiti útibúsins",
    pleaseEnterBranchAddress1: "Sláðu inn heimilisfangslínu útibúsins 1",
    enterBranchAddress1: "Sláðu inn vistlínu 1 útibúsins",
    enterBranchAddress2: "Sláðu inn vistlínu 1 útibúsins",
    pleaseEnterBranchAddress2: "Sláðu inn heimilisfangslínu útibúsins 2",
    enterBranchName: "Sláðu inn nafn útibúsins",
    successfullyAddedStaff: "Þú hefur bætt nýju starfsfólki við!",
    addNewStaff: "Bæta við nýju starfsfólki",
    addStaff: "Bæta við starfsfólki",
    additionalStaffWillIncur: "Viðbótarstarfsmenn myndu hafa í för með sér",
    perStaff: "á starfsmann.",
    pleaseEnterStaffEmail: "Sláðu inn tölvupóst starfsmanna",
    pleaseEnterStaffUsername: "Sláðu inn notandanafn starfsfólksins",
    pleaseEnterStaffPassword: "Sláðu inn lykilorð starfsmanna",
    pleaseSelectStaffRole: "Vinsamlegast veldu hlutverk starfsfólksins",
    selectStaffRole: "Veldu hlutverk starfsmanna",
    enterStaffEmail: "Sláðu inn tölvupóst starfsmanna",
    enterStaffUsername: "Sláðu inn notendanafn starfsmanna",
    enterStaffPassword: "Sláðu inn lykilorð starfsmanna",
    spacesNotAllowedInUsername: "bil ekki leyfilegt í notendanafni",
    admin: "Stjórnandi",
    pleaseSelectBusinessToAttachStaff:
      "Vinsamlegast veldu fyrirtæki til að tengja starfsfólk",
    searchForBranchToAttachStaff: "Leitaðu að útibúi til að tengja starfsfólk",
    username: "Notandanafn",
    role: "Hlutverk",
    areYouSureToDeleteThis: "Ertu viss um að eyða þessu",
    branches: "Útibú",
    upgradeYourPlan: "Uppfærðu áætlun þína.",
    add: "BÆTA VIÐ",
    addNew: "Bæta við nýju",
    customerWithEmailAlreadyExists:
      "Viðskiptavinur með netfang/símanúmer er þegar til!",
    successfullyAddedNewCustomer: "Þú hefur bætt nýjum viðskiptavini við!",
    addCustomer: "Bæta við viðskiptavini",
    pleaseEnterCustomerFirstName: "Sláðu inn eiginnafn viðskiptavinarins",
    pleaseEnterCustomerLastName: "Sláðu inn eftirnafn viðskiptavinar",
    pleaseEnterCustomerPhoneNumber: "Sláðu inn símanúmer viðskiptavinarins",
    pleaseEnterCustomerEmail: "Sláðu inn netfang viðskiptavinar",
    pleaseEnterCustomerAddressLine:
      "Sláðu inn heimilisfangslínu viðskiptavinar",
    pleaseEnterCustomerOtherAddress:
      "Sláðu inn annað heimilisfang viðskiptavinarins",
    pleaseSelectCustomerGender: "Vinsamlegast veldu kyn viðskiptavinarins",
    gender: "Kyn",
    male: "Karlmaður",
    female: "Kvenkyns",
    bank: "Banki",
    selectBank: "Veldu Bank",
    stateOrRegionOrProvince: "Ríki/svæði/hérað",
    customerNotes: "Skýringar viðskiptavina",
    sendSms: "Sendu SMS",
    editCustomer: "Breyta viðskiptavini",
    redeemReward: "Innleysa verðlaun",
    issueLoyaltyCard: "Útgáfu vildarkort",
    deleteCustomer: "Eyða viðskiptavini",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Þú hefur úthlutað auðkenni fyrir hollustu við aðild",
    noMembershipIdAvailable:
      "Engin aðildarauðkenni eru til staðar. Vinsamlegast hafðu samband við okkur á hello@loystar.co",
    sendEmail: "Senda tölvupóst",
    membershipPoints: "Aðildarpunktar",
    customerDetails: "Upplýsingar um viðskiptavini",
    close: "Loka",
    loyaltyBalance: "Tryggðarjafnvægi",
    pointsBalance: "Stig jafnvægi",
    starBalance: "Star Balance",
    requiredPoints: "Nauðsynlegir punktar",
    requiredStars: "Nauðsynlegar stjörnur",
    reddemCode: "Innleysa kóða",
    toDeleteThisCustomer: "að eyða þessum viðskiptavini",
    customerHasBeenDeletedSuccessfully:
      "Viðskiptavininum hefur verið eytt með góðum árangri!",
    customerWithPhoneAlreadyExists: "Viðskiptavinur með símanúmer er þegar til",
    customerWasSuccessfullyEdited: "Viðskiptavininum var breytt",
    anErrorOccured: "Villa kom upp",
    phoneNumberIncludeCountry: "Símanúmer (innihalda landsnúmer)",
    yourFileQueuedForUpload:
      "Skráin þín hefur verið sett í biðröð til að hlaða henni upp. Endurnýjaðu síðuna eftir nokkrar sekúndur.",
    thereWasAnErrorPerformingOperation: "Villa kom upp við aðgerðina!",
    pleaseSelectFile: "Vinsamlegast veldu skrá!",
    oopsOnlyCsvFilesAllowed:
      "Úbbs! Aðeins CSV skrár leyfðar. Vinsamlegast hlaðið upp .csv skrá.",
    fileShouldBeSmallerThan5Mb:
      "Skráin ætti að vera minni en 5MB. Ef þú ert með stærri skrá, vinsamlegast sendu tölvupóst til support@loystar.co. Þakka þér fyrir",
    customerFirstNameIsRequired: "fornafn viðskiptavinar er krafist í röð",
    customerPhoneNumberIsRequired: "símanúmer viðskiptavinar er krafist í röð",
    importCustomers: "Flytja inn viðskiptavini",
    upload: "Hlaða inn",
    clickIconToDownloadCsv:
      "Smelltu á þetta tákn til að hlaða niður CSV skráarsniðmátinu.",
    getGoogleSheetVersion: "Sæktu útgáfu Google blaðsins",
    clickOrDragFileToUpload:
      "Smelltu eða dragðu skrána á þetta svæði til að hlaða upp",
    missingOrInvalidColumnHeader:
      "Vantar eða ógildan dálkahaus. Vinsamlegast fylgdu sniðmátinu. Þakka þér fyrir.",
    youHaveImported: "Þú hefur flutt inn",
    youSuccessfullyRedeemedReward:
      "Þú hefur innleyst laun þín með góðum árangri!",
    sixDigitCode: "Sex stafa kóði",
    pleaseEnterCustomerRewardCode: "Sláðu inn umbunarkóða viðskiptavinarins",
    enterRewardCode:
      "Sláðu inn umbunarkóða. (Verðlaunakóðinn er hástafastærður)",
    selectLoyaltyProgram: "Veldu vildarkerfi",
    stamps: "Frímerki",
    smsUnitsLow: "SMS einingar lágar",
    pleaseTopUpSmsUnits: "Vinsamlegast fylltu SMS -einingar",
    smsSuccessfullySentForDelivery: "SMS tókst að senda!",
    sendSmsTo: "Sendu SMS til",
    allCustomers: "Allir viðskiptavinir",
    unitsAvailable: "Einingar í boði",
    pleaseTypeInTheMessage: "Vinsamlegast sláðu inn skilaboðin",
    message: "Skilaboð",
    charactersRemaining: "stafir eftir",
    avoidUseOfSpecialCharacters:
      "Forðastu að nota sérstafi og emojis til að varðveita SMS -einingar.",
    note: "Athugið",
    errorFetchingCustomersMultilevelDetail:
      "Villa við að sækja upplýsingar margra stiga viðskiptavina",
    search: "Leit",
    reset: "Endurstilla",
    importCustomer: "Flytja inn viðskiptavin",
    addNewCustomer: "Bættu við nýjum viðskiptavini",
    sendSmsBroadcast: "Senda SMS útsendingu",
    totalCustomers: "ALLS Viðskiptavinir",
    disbursementDetails: "Greiðsluupplýsingar",
    paidBy: "Borgað af",
    disbursed: "Útborgað",
    bankAccountName: "Nafn bankareiknings",
    bankAccountNumber: "Bankareikningsnúmer",
    transferInitiated: "Flutningur hafinn",
    transferCompleted: "Flutningi lokið",
    pleaseEnterAValid: "Sláðu inn gilt",
    begin: "byrja",
    end: "enda",
    date: "dagsetning",
    paymentDate: "Greiðsludagur",
    selectDisbursementDuration: "Veldu útborgunartíma",
    totalSettled: "Heildaruppgjör",
    totalUnsettled: "Samtals óuppgerður",
    toDeleteThisSale: "að eyða þessari sölu",
    draftSaleDeletedSuccessfully: "Drög að sölu hefur verið eytt!",
    deleteSale: "Eyða sölu",
    pleaseTypeInYourTotal:
      "Sláðu inn heildartöluna þína til að staðfesta eyðingu",
    billDetails: "Bill Details",
    printBill: "Prenta Bill",
    servedBy: "Afgreitt af",
    total: "Samtals",
    createdDate: "Búið til dagsetningu",
    createdTime: "Skapaður tími",
    status: "Staða",
    loginSuccessful: "Innskráning tókst",
    pleaseWaitWhileWeConnectAccount: "Bíddu meðan við tengjum reikninginn þinn",
    connectionFailedTryAgain: "Tenging mistókst. Vinsamlegast reyndu aftur.",
    connectionSuccessful: "Tenging tókst",
    viewDetails: "Skoða smáatriði",
    enable: "Virkja",
    free: "Ókeypis",
    cost: "Kostnaður",
    visitWebsite: "Heimsæktu vefsíðu",
    pleaseUpgradeYourPlanToPro:
      "Uppfærðu áætlun þína í Pro Plus til að virkja þetta forrit",
    connectYourBankAccount:
      "Tengdu bankareikninginn þinn til að geta tekið á móti greiðslum.",
    disable: "Slökkva",
    enableApp: "Virkja forrit",
    addNewProductToInvoice: "Bæta nýrri vöru við reikning",
    toDeleteThisInvoice: "að eyða þessum reikningi",
    invoiceDeletedSuccessfully: "Reikningi hefur verið eytt með góðum árangri!",
    deleteInvoice: "Eyða reikningi",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Þú hefur bætt við reikningsgreiðsluskilaboðum!",
    pleaseEnterPaymentInstructions: "Sláðu inn greiðsluleiðbeiningar",
    invoiceId: "Reikningsauðkenni",
    paidDate: "Greidd dagsetning",
    reference: "Tilvísun",
    productAdded: "Vöru bætt við",
    productOutOfStock: "Vara ekki til á lager. Vinsamlega birgðir aftur.",
    totalInvoices: "HEILDARREIKNINGAR",
    totalPaidInvoices: "ALLS BETJAÐAR SKULDREIKNINGAR",
    totalUnpaidInvoices: "ALLS Ógreiddar reikningar",
    loyaltyProgramDeleted: "Hollustuáætlun eytt",
    thisLoyaltyProgramDeletedSuccessfully: "Þessu vildarkerfi hefur verið eytt",
    thisLoyaltyProgramEditedSuccessfully:
      "Þessu vildarkerfi hefur verið breytt með góðum árangri",
    loyaltyProgramAddedSuccessfully:
      "Hollustuáætlun hefur verið bætt við með góðum árangri",
    loyaltyProgramEdited: "Hollustuáætlun breytt",
    loyaltyProgramAdded: "Hollustuáætlun bætt við",
    loyaltyDetails: "Hollustuupplýsingar",
    doYouWantToCloseDialog: "Viltu loka þessum glugga?",
    pleaseEnterLoyaltyName: "Sláðu inn nafn tryggðar þinnar",
    programType: "Forritagerð",
    pleaseSelectProgramType: "Vinsamlegast veldu forritategund",
    simplePoints: "Einfaldir punktar",
    stampsProgram: "Forrit frímerkja",
    threshold: "Þröskuldur",
    pleaseEnterLoyaltyThreshold: "Sláðu inn vildarmörk",
    reward: "Verðlaun",
    pleaseEnterLoyaltyReward: "Vinsamlegast sláðu inn verðlaun hollustu",
    totalUserPoints: "Heildarstig notenda",
    totalUserStamps: "Samtals frímerki notenda",
    spendingTarget: "Eyðslumark",
    spendingTargetHint1:
      "Markmið eyðslu er hversu mikið viðskiptavinur þarf að eyða til að vinna sér inn verðlaunin. 1 gjaldmiðilgildi = 1 stig.",
    spendingTargetHint2:
      "Frímerki miða er hversu mörg frímerki viðskiptavinur þarf að safna til að vinna sér inn verðlaunin. td. 5",
    addNewLoyaltyProgram: "Bættu við nýju hollustuáætlun",
    addLoyaltyProgram: "Bættu við hollustuáætlun",
    loyaltyProgramType: "Tryggingaráætlunargerð",
    pleaseSelectLoyaltyProgramType: "Veldu tegund vildarkerfis",
    nameOfProgram: "Nafn dagskrár",
    pleaseEnterProgramName: "Sláðu inn heiti forritsins",
    whatIsTheReward: "Hver er umbunin?",
    egNextPurchaseIsFree: "T.d. Næstu kaup eru ókeypis",
    customerName: "Nafn viðskiptavinar",
    guestCustomer: "Gestaviðskiptavinur",
    orderConfirmedSuccessfully: "Pöntun staðfest",
    orderCancelledSuccessfully: "Pöntun hætt",
    confirmOrder: "Staðfesta pöntun",
    cancelOrder: "Hætta við pöntun",
    allOrders: "Allar pantanir",
    totalOrders: "HEILDAR Pöntun",
    doYouWantToAcceptOrder: "Viltu samþykkja þessa pöntun?",
    doYouWantToCancelOrder: "Viltu hætta við þessa pöntun?",
    orderDetails: "Pöntunarupplýsingar",
    orderCreatedAt: "Pöntun búin til kl",
    supplier: "Birgir",
    productName: "vöru Nafn",
    quantity: "Magn",
    unitPrice: "Einingaverð",
    receivedBy: "Móttekið af",
    reportFrom: "Skýrsla frá",
    totalSupplies: "Heildarbirgðir",
    allRightsReserved: "Allur réttur áskilinn",
    toDeleteThisTransaction: "að eyða þessum viðskiptum",
    transactionDeletedSuccessfully:
      "Viðskiptum hefur verið eytt. Birgðum hefur verið skilað til birgða.",
    deleteTransaction: "Eyða viðskiptum",
    transactionDetails: "Viðskiptaupplýsingar",
    printReceipt: "Prenta kvittun",
    channel: "Rás",
    discount: "Afsláttur",
    profit: "Hagnaður",
    discountedSales: "Afsláttur með afslætti",
    errorFetchingRecord: "Villa við að sækja færslu",
    exportTransactions: "Útflutningsviðskipti",
    errorFetchingSalesRecord: "Villa við að sækja söluskrá fyrir útflutning.",
    totalSellingPrice: "Heildarsöluverð",
    totalCostPrice: "Heildarkostnaðarverð",
    appliedDiscount: "Notaður afsláttur",
    noOfItems: "Fjöldi hluta",
    sales: "Sala",
    export: "Útflutningur",
    totalProfit: "Heildarhagnaður",
    totalBalanceInPeriod: "Heildarjöfnuður á tímabili",
    allTimeSales: "Sala allan tímann",
    records: "Skrár",
    todaysSales: "Sala í dag",
    transaction: "viðskipti",
    youHaveExceededTotalNumberOfProducts:
      "Þú hefur farið yfir heildarfjölda vara sem er leyfður í áætlun þinni. Uppfærðu áætlun þína til að njóta hærra marka.",
    youNeedToHaveLoyaltyProgram:
      "Þú þarft að hafa hollustuáætlun til að nota þennan eiginleika!",
    price: "Verð",
    category: "Flokkur",
    stockTracking: "Rekja spor einhvers",
    stockCount: "Fjöldi hlutabréfa",
    taxed: "Skattlagður",
    originalPrice: "Upprunalegt verð",
    costPrice: "Kostnaðarverð",
    unit: "Eining",
    productImage: "Vörumynd",
    taxRate: "Skatthlutfall",
    taxType: "Skatttegund",
    trackInventory: "Fylgstu með birgðum",
    variants: "Afbrigði",
    hasVariants: "Er með afbrigðum",
    importProduct: "Flytja inn vöru",
    exportProducts: "Flytja út vörur",
    addNewProduct: "Bæta við nýrri vöru",
    viewCategory: "Skoða flokk",
    viewSuppliers: "Skoða birgja",
    receiveInventory: "Fáðu birgðir",
    printAllProductsTag: "Prentaðu allar vörumerki",
    deleteAll: "Eyða öllu",
    totalProducts: "HEILDARVÖRUR",
    youveSuccessfullyAddedANewCategory: "Þú hefur bætt nýjum flokki við",
    addNewCategory: "Bæta við nýjum flokki",
    addCategory: "Bæta við flokki",
    categoryName: "Nafn flokks",
    pleaseEnterCategoryName: "Sláðu inn heiti flokks",
    stampsTarget: "Frímerki Markmið",
    sendInventory: "Senda birgða",
    productDetails: "Vöruupplýsingar",
    youveSuccessfullyEditedThisCategory: "Þú hefur breytt þessum flokki",
    update: "Uppfærsla",
    categoryList: "Flokkaskrá",
    categories: "Flokkar",
    enterQuantityToUpdate: "Sláðu inn magn til að uppfæra",
    inventorySentSuccessfully: "Birgðirnar voru sendar með góðum árangri!",
    updateInventory: "Uppfæra skrá",
    currentQuantity: "Núverandi Magn",
    pleaseEnterQuantityToAdd: "Sláðu inn magnið sem þú vilt bæta við",
    pleaseSelectABranch: "Vinsamlegast veldu útibú",
    searchForBranch: "Leita að útibúi",
    youCantSendMoreThanStock: "Þú getur ekki sent meira en þú ert með á lager",
    send: "Senda",
    pleaseEnterQuantityToSend: "Sláðu inn magnið sem þú vilt senda",
    productNameIsRequiredOnRow: "vöruheiti er krafist í röð",
    productCategoryIsRequiredOnRow: "vöruflokkur er krafist í röð",
    productPriceIsRequiredOnRow: "vöruverð er krafist í röð",
    productUnitIsRequiredOnRow: "varaeining er krafist í röð",
    productQuantityIsRequiredOnRow: "magn vöru er krafist í röð",
    productVariantsRequireTracking: "vöruafbrigði krefst mælingar!",
    pleaseAddVariantClickButton:
      "Vinsamlegast bættu afbrigði við með því að smella á bæta við hnappinn!",
    totalVariantsMoreThanSelectedQuantity:
      "Heildarafbrigði eru meira en valið afurðamagn, Pls minnka afbrigðismagn",
    productEditedSuccessfully: "Vörunni hefur verið breytt með góðum árangri!",
    fileTooLargeLessThan4Mb:
      "Skráastærð er of stór! Skráastærð ætti að vera minni en 4MB.",
    suchVariantAlreadyExist: "Slík afbrigði er þegar til",
    addVariants: "Bæta við afbrigðum",
    editProduct: "Breyta vöru",
    pleaseEnterProductName: "Vinsamlegast sláðu inn vöruheiti",
    pleaseEnterProductPrice: "Sláðu inn vöruverð",
    pleaseEnterProductOriginalPrice: "Sláðu inn upprunalega verð vörunnar",
    productDescription: "Vörulýsing",
    selectProductCategory: "Veldu vöruflokk",
    pleaseSelectProductCategory: "Vinsamlegast veldu vöruflokk",
    productCostPrice: "Kostnaður vöruverðs",
    productSellingPrice: "Söluverð vöru",
    productOriginalPrice: "Upprunalega verð vöru",
    maxFileSizeAllowedIs4Mb: "Max. Leyfð skráarstærð er 4mb",
    productsWithPicturesArePublished:
      "Vörur með myndum eru birtar á Discover til að taka á móti pöntunum",
    shouldThisProductBeTracked: "Á að rekja þessa vöru?",
    pleaseSelectStockUnit: "Vinsamlegast veldu lagereiningu",
    stockUnit: "Stock Unit",
    bag: "TASKA",
    bottle: "FLASKI",
    bunch: "BUNCH",
    can: "DÓS",
    carton: "KARTON",
    crate: "KASTA",
    cup: "BIKAR",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKI",
    pair: "PARI",
    pieces: "STYKKI",
    plate: "Diskur",
    tonne: "TONNE (MT)",
    uNIT: "EINING",
    yard: "YARD",
    pleaseEnterProductQuantity: "Vinsamlegast sláðu inn magn vöru",
    productQuantity: "Vörumagn",
    isThisProductTaxed: "Er þessi vara skattlögð?",
    selectTaxType: "Veldu Skatttegund",
    pleaseSelectTaxType: "Vinsamlegast veldu Skatttegund",
    progressive: "Framsækinn",
    proportional: "Hlutfallslega",
    pleaseEnterProductTaxRate: "Sláðu inn vörugjaldsgjald",
    doesProductHaveVariants: "Er þessi vara með afbrigðum?",
    type: "Gerð",
    value: "Verðmæti",
    pleaseEnterVariantType: "Sláðu inn tegund afbrigða",
    pleaseEnterVariantValue: "Sláðu inn gildi afbrigða",
    pleaseEnterVariantPrice: "Sláðu inn verð afbrigða",
    pleaseEnterVariantQuantity: "Sláðu inn magn afbrigða",
    productDeletedSuccessfully: "Vörunni hefur verið eytt með góðum árangri!",
    categoryDeletedSuccessfully: "Flokki hefur verið eytt með góðum árangri!",
    toDeleteThisProduct: "að eyða þessari vöru",
    invalidProductQuantity: "Ógilt magn vöru",
    quantityAddedIsOverStock:
      "Magnið sem þú bætir við er yfir því sem þú hefur á lager.",
    itemInventoryNotTracked: "Ekki er fylgst með hlutaskrá",
    addBulkQuantity: "Bæta við magni",
    enterBulkQuantity: "Sláðu inn magn magn",
    pleaseEnterBulkQuantity: "Sláðu inn magn magn",
    youveSuccessfullyAddedANewProduct: "Þú hefur bætt nýrri vöru við!",
    pleaseEnterProductSellingPrice: "Sláðu inn söluverð vörunnar",
    doYouWantToTrackProductStock: "Viltu rekja vörulager?",
    sellingPrice: "Söluverð",
    setProductExpiryReminder: "Stilltu áminningu vöruloka",
    productExpiryDate: "Gildistími vöru",
    startRemindingFrom: "Byrja að minna á",
    productSuppliesAddedSuccessfully:
      "Þú hefur bætt vörubirgðum við með góðum árangri.",
    addProductSupplies: "Bættu við vöruvörum",
    pleaseSelectSupplier: "Vinsamlegast veldu birgir",
    nameOfProduct: "Heiti vöru",
    pleaseSelectProduct: "Vinsamlegast veldu vöru",
    productVariant: "Vöruafbrigði",
    pleaseSelectAVariant: "Vinsamlegast veldu afbrigði",
    pleaseEnterUnitPrice: "Sláðu inn einingarverð",
    businessBranch: "Viðskiptaútibú",
    pleaseSelectBranch: "Vinsamlegast veldu útibú",
    youveSuccessfullyAddedANewSupplier: "Þú hefur bætt nýjum birgi við",
    addSupplier: "Bæta við birgir",
    pleaseEnterSupplierEmail: "Sláðu inn netfang birgja",
    pleaseAddADescription: "Vinsamlegast bættu við lýsingu",
    anErrorOccuredProductsDeleted:
      "Villa kom upp við aðgerðina. Vinsamlegast athugaðu að fáum vörum gæti verið eytt í ferlinu",
    bulkDelete: "Magn eytt",
    youAreAboutToDelete: "Þú ert að fara að eyða",
    product: "Vara",
    isDueToRedeem: "á að innleysa",
    aReward: "verðlaun",
    pleaseSelectCustomerToReeemReward:
      "Vinsamlegast veldu viðskiptavin til að innleysa umbun.",
    youHaveNoLoyaltyProgramRunning:
      "Þú hefur ekkert virkt hollustuforrit í gangi",
    customerHhasNoPointsInLoyaltyProgram:
      "Viðskiptavinur hefur enga punkta í þessu hollustuáætlun",
    proceedWithPayment: "Halda áfram með greiðslu?",
    youAreAboutToPayForTransactionUsingPoints:
      "Þú ert að fara að borga fyrir viðskiptin með punktum.",
    customerHas: "Viðskiptavinur hefur",
    worth: "virði",
    andIsNotSufficientToPayForTransaction:
      "og er ekki nóg til að greiða fyrir þessi viðskipti. Myndu þeir vilja bæta við jafnvæginu með öðrum greiðslumáta?",
    addCustomerLoyalty: "Bættu tryggð viðskiptavina við",
    pleaseAddCustomerFirst:
      "Vinsamlegast bættu við eða veldu viðskiptavin fyrst.",
    pleaseWaitWhileWeGetReady: "Bíddu, meðan við verðum tilbúin",
    lowStock: "Lítið til á lager",
    pleaseEnterAmountTendered:
      "Vinsamlegast sláðu inn upphæðina sem boðin var út",
    areYouSureToBookSaleOffline:
      "Ertu viss um að þú viljir bóka þessa sölu án nettengingar?",
    saleWouldBeBookedAutomatically:
      "Sala yrði bókuð sjálfkrafa þegar þú kveikir á internetinu þínu",
    offlineSalesBookingCancelled: "Sölubókun ótengd felld niður",
    covid19Message:
      "COVID19: Þvoðu hendurnar með sápu eða sótthreinsaðu til að stöðva útbreiðslu. Vertu öruggur alltaf",
    saleSuccessfullyRecorded: "Sala tekin upp!",
    sendReceiptToEmail: "Sendu kvittun í tölvupóst",
    sendThankYouSms: "Sendu takk takk SMS",
    sendShippingDetails: "Sendu upplýsingar um sendingar",
    addLoyalty: "Bættu við hollustu",
    searchCustomerNameOrNumber: "Leitaðu nafn viðskiptavinar eða númer",
    clickTheSearchCustomerBox: "Smelltu á Leitarkassa og skannakort",
    enterProductPrice: "Sláðu inn vöruverð",
    enterPriceFor: "Sláðu inn verð fyrir",
    searchForProduct: "Leitaðu að vöru",
    all: "Allt",
    backToDashboard: "Aftur á mælaborð",
    viewDraftSales: "Skoða drög að sölu",
    variantSelected: "afbrigði valið",
    variant: "afbrigði",
    thePreviousVariantSelectionNotAvailable:
      "Fyrra afbrigðaúrvalið er ekki í boði fyrir nýja afbrigðið sem valið er eftir verði, vinsamlegast breyttu úrvalinu.",
    pleaseSupplyPositiveQuantityNumber:
      "Vinsamlegast gefðu upp postiv magnnúmer",
    lowStockFor: "Lítið lager fyrir",
    clearVariants: "Skýr afbrigði",
    pleaseEnterQuantity: "Sláðu inn magn",
    picture: "Mynd",
    redemptionToken: "Innlausnarmerki",
    token: "Merki",
    totalSpent: "Heildar eytt",
    confirmPayment: "Staðfesta greiðslu",
    hasPaymentBeenMade: "Hefur greiðsla verið unnin með góðum árangri?",
    enterTransactionReference:
      "Sláðu inn færsluviðmiðunina sem þú greiddir með",
    pleaseSelectACustomer: "Vinsamlegast veldu viðskiptavin!",
    areYouSureToApplyDiscount: "Ertu viss um að þú viljir beita afslætti?",
    addYourBankAccountToEnableUssd:
      "Bættu við bankareikningnum þínum til að gera augnablik USSD millifærslu kleift með uPay",
    totalAmountToPay: "Heildarupphæð til greiðslu",
    doYouWantToCancelTransaction: "Viltu hætta við þessi viðskipti?",
    savePrintBill: "Vista/prenta reikning",
    enterAmountCollectedForCustomer:
      "Sláðu inn upphæð sem safnað er fyrir viðskiptavininn",
    recordSale: "Plötusala",
    checkOutWith: "Skoðaðu með",
    instantTransfer: "Augnablik flutningur",
    dialTheUSSDCode: "Sláðu inn USSD kóða",
    pleaseSelectABank: "Vinsamlegast veldu banka",
    payWithUSSD: "Borgaðu með USSD",
    sendBillTo: " - Sendu Bill til",
    waitingForUSSDTransfer: "Bíð eftir USSD flutningi",
    percent: "Hlutfall",
    applyDiscount: "Sækja um afslátt",
    thisBillHasBeenSaved: "Þetta frumvarp hefur verið vistað",
    saveDraft: "Vista uppkast",
    pleaseTypeANameToIdentifyCustomer:
      "Vinsamlegast sláðu inn nafn til að bera kennsl á viðskiptavininn",
    paymentDetails: "Greiðsluupplýsingar",
    basePrice: "Grunnverð",
    staff: "Starfsfólk",
    subTotal: "SubTotal",
    durationMonths: "Lengd (mánuðir)",
    selectADuration: "Veldu lengd",
    oneMonth: "1 mánuður",
    twoMonths: "2 mánuðir",
    threeMonths: "3 mánuðir",
    sixMonths: "6 mánuðir",
    twelveMonths: "12 mánuðir",
    eighteenMonths: "18 Mánuðir",
    twentyFourMonths: "24 Mánuðir",
    twoMonthsFree: "(2 mánuðir ókeypis)",
    threeMonthsFree: "(3 mánuðir ókeypis)",
    fiveMonthsFree: "(5 mánuðir ókeypis)",
    yourAccountHasBeen: "Reikningurinn þinn hefur verið",
    renewed: "endurnýjuð",
    upgraded: "uppfærð",
    successfully: "með góðum árangri",
    yourSubscription: "Áskriftin þín",
    youAreCurrentlyEnrolledOnA: "Þú ert nú skráð (ur) á",
    pleaseChooseAPaymentOption: "Vinsamlegast veldu greiðslumáta",
    planRenewal: "Endurnýjun áætlunar",
    planUpgrade: "Skipuleggja uppfærslu",
    pleaseSelectDurationToPayFor:
      "Vinsamlegast veldu Lengd sem þú ætlar að borga fyrir",
    staffAccounts: "Starfsreikningar",
    ecommerce: "Netverslun",
    pleaseSelectAPlan: "Vinsamlegast veldu áætlun",
    includeAddons: "Hafa viðbætur með",
    viewTransactions: "Skoða viðskipti",
    customerHasNoCompletedTansactions:
      "Viðskiptavinur hefur enn ekki lokið viðskiptaaðgerðir",
    branch: "Útibú",
    enterNumberOfEcommerceBranches: "Sláðu inn fjölda netverslunargreina",
    enterNumberOfEcommerceBranchesToPay:
      "Sláðu inn fjölda netverslunargreina sem þú ætlar að borga fyrir",
    ecommerceIntegration: "Sameining netverslunar",
    enterNumberOfBranches: "Sláðu inn fjölda útibúa",
    enterNumberOfAdditionalBranchesToPay:
      "Sláðu inn fjölda viðbótargreina sem þú ætlar að borga fyrir",
    enterNumberOfStaffs: "Sláðu inn fjölda starfsmanna",
    enterNumberOfStaffsToPayFor:
      "Sláðu inn fjölda starfsmanna sem þú ætlar að borga fyrir",
    discountApplies: "Afsláttur gildir",
    starsOnThe: "stjörnur á",
    offer: "bjóða",
    get: "Fá",
    moreStarsToRedeem: "fleiri stjörnur til að innleysa",
    taxVat: "Skattur (VSK)",
    callCashierToCompletePayment:
      "Hringdu í gjaldkera til að ganga frá greiðslu",
    receipt: "Kvittun",
    dear: "Kæri",
    thankYouForYourOrderFindGoods:
      "Takk fyrir pöntunina. Vinsamlegast finndu eftirfarandi vörur sem fylgja með, eins og samið er um.",
    shippingNote: "Senda athugasemd",
    enterShippingNote: "Sláðu inn sendingarnótu",
    shippingAddress: "Póstfang",
    enterShippingAddress: "Sláðu inn póstfang",
    wellDoneYouAreDueToRedeem: "Vel gert! Þú átt að innleysa",
    toGetYourRewardNextVisit:
      "að fá verðlaunin þín í næstu heimsókn. Þakka þér fyrir",
    pointsOnThe: "Stig á",
    morePointsToRedeem: "fleiri stig til að innleysa",
    showCode: "Sýna kóða",
    toGetYourRewardOnNextVisit:
      "að fá verðlaunin þín í næstu heimsókn. Þakka þér fyrir",
    earn: "Græða",
    delivaryNote: "Afhendingartilkynning",
    draftSales: "Drög að sölu",
    startDate: "Upphafsdagur",
    endDate: "Loka dagsetning",
    orders: "Pantanir",
    checkout: "athuga",
    noProductItem: "Engin varahlutur",
    selectProductImage: "Veldu vörumynd",
    selectCountry: "Veldu land",
    selectRegion: "Veldu Ríki/svæði",
    printProductTag: "Prentaðu vörumerki",
    transactionReference: "Viðmiðun viðskipta",
    Cashier: "Gjaldkeri",
    Manager: "Framkvæmdastjóri",
    Owner: "Eigandi",
    Admin: "Stjórnandi",
    addPartners: "Bæta við samstarfsaðilum",
    addNewLoyaltyPartner: "Bæta við nýjum tryggðarfélaga",
    pleaseEnterCompanyName: "Vinsamlegast sláðu inn nafn fyrirtækis",
    companyName: "nafn fyrirtækis",
    pleaseEnterCompanyRepName:
      "Vinsamlega sláðu inn nafn fulltrúa fyrirtækisins",
    companyRepName: "Nafn fulltrúa fyrirtækisins",
    pleaseEnterCompanyRepEmail:
      "Vinsamlegast sláðu inn netfang fulltrúa fyrirtækisins",
    companyRepEmail: "Tölvupóstur fulltrúa fyrirtækisins",
    pleaseEnterCompanyRepPhone:
      "Vinsamlegast sláðu inn símanúmer fulltrúa fyrirtækisins",
    companyRepPhone: "Símanúmer fulltrúa fyrirtækisins",
    addReward: "Bættu við verðlaunum",
    pleaseEnterRewardName: "Vinsamlega sláðu inn nafn verðlauna",
    rewardName: "Nafn verðlauna",
    rewardQuantity: "Verðlaunamagn",
    rewardDescription: "Verðlaunalýsing",
    rewardType: "Tegund verðlauna",
    pleaseEnterRewardType: "Vinsamlegast sláðu inn verðlaunategund",
    pleaseEnterRewardQuantity: "Vinsamlega sláðu inn verðlaunamagn",
    pleaseEnterRewardDescription: "Vinsamlegast sláðu inn verðlaunalýsingu",
    fineDining: "Fínn Veitingastaður",
    luxuryFashion: "Lúxus tíska",
    hotels: "Hótel",
    travel: "Ferðalög",
    foodAndBeverage: "Matur og drykkur",
    fashion: "Tíska",
    health: "Heilsa",
    furniture: "Húsgögn",
    entertainment: "Skemmtun",
    automobile: "Bíll",
    education: "Menntun",
    beautyAndSpa: "Fegurð og Spa",
    staycation: "Staycation",
    events: "Viðburðir",
    trips: "Ferðir",
    oilAndGas: "Olía og gas",
    laundry: "Þvottahús",
    partnerCategory: "Samstarfsflokkur",
    freeItem: "Ókeypis hlutur",
  },
  Igbo: {
    cashier: "onye okwu ugwo",
    manager: "onye njikwa",
    owner: "onye nwe ya",
    online: "online",
    offline: "na -anọghị n'ịntanetị",
    changePassword: "Gbanwee okwuntughe",
    currentPasswordMessage: "Biko tinye paswọọdụ gị ugbu a",
    passwordMessage: "Biko tinye paswọọdụ gị",
    currentPassword: "Okwuntughe Ugbu a",
    password: "okwuntughe",
    confirmPasswordMessage: "Biko gosi okwuntughe gị!",
    confirmPassword: "Kwenye Na Okwuntughe",
    sendViaEmail: "Ziga site na Email",
    sendViaWhatsapp: "Ziga site na WhatsApp",
    downloadPdf: "Budata PDF",
    paid: "kwụrụ ụgwọ",
    unpaid: "akwụghị ụgwọ",
    partial: "ele mmadụ anya n'ihu",
    closeInvoice: "Ị chọrọ imechi akwụkwọ ọnụahịa?",
    closeInvoiceConfirmation:
      "Enwere ike ịchekwa akwụkwọ ọnụahịa. Ị chọrọ imechi?",
    yes: "ee",
    no: "mba",
    invoice: "Akwụkwọ ọnụahịa",
    wasDeducted: "ewepụrụ ya",
    for: "maka",
    item: "Ihe",
    addProduct: "Tinye Ngwaahịa",
    paymentReference: "Ntughari ugwo",
    amountPaid: "Ọnụ ego a kwụrụ",
    discountAmount: "Ọnụ ego",
    amountDue: "Oke ego",
    amount: "Ọnụ ego",
    dueDate: "Oge ngwụcha",
    paymentType: "Ụdị ịkwụ ụgwọ",
    card: "Kaadị",
    cash: "Ego nkịtị",
    bankTransfer: "Nyefe ego n'ụlọ akụ",
    paymentMessage: "Ozi Ịkwụ Ụgwọ",
    description: "Nkọwa",
    sendReceipt: "Zipu akwụkwọ nnata",
    delete: "Hichapụ",
    save: "Chekwa",
    resend: "Re-Ziga",
    saveAndSend: "Chekwa",
    invoiceSaved: "Echekwara akwụkwọ ọnụahịa!",
    selectPaymentMethod: "Biko họrọ usoro ịkwụ ụgwọ!",
    selectCustomer: "Biko họrọ onye ahịa!",
    cartEmptyError:
      "Ndepụta ụgbọ ala enweghị ike tọgbọ chakoo, mechie akwụkwọ ọnụahịa wee gbakwunye ihe na ụgbọ ibu!",
    subscriptionExpired:
      "Ndenye aha gị agwụla ma akaụntụ gị nwere oke ugbu a. Pịa bọtịnụ dị n'okpuru iji megharịa akaụntụ gị",
    renew: "Ọhụrụ",
    holdOn: "Jigide",
    customerBank: "Ụlọ ahịa ndị ahịa",
    cancel: "Kagbuo",
    selectACustomer: "Họrọ Ahịa",
    invoiceSuccessfulPdfError:
      "Edepụtara akwụkwọ ọnụahịa nke ọma mana Ọgbọ PDF na -ewe ogologo oge karịa ka ọ dị na mbụ. Biko lelee azụ n'oge na -adịghị anya.",
    downloadingInvoice: "Na -ebudata akwụkwọ ọnụahịa",
    downloadingReceipt: "Na -ebudata nnata",
    whatsappReceiptError:
      "Mperi mere site na iziga nnata site na WhatsApp, Biko nwaa ọzọ.",
    receiptToWhatsapp: "E zigara ndị nnata ozi na WhatsApp",
    thankYouForPatronage: "Daalụ maka nkwado gị",
    hereIsYourReceipt: "Nke a bụ akwụkwọ nkwụnye ụgwọ gị",
    errorSendingEmailReceipt:
      "Mperi mere site na iziga nnata site na e-mail, biko nwaa ọzọ ma ọ bụ kpọtụrụ nkwado",
    receiptSentToEmail: "E zigala akwụkwọ ozi na email ndị ahịa",
    invoiceSentToEmail: "E zigala akwụkwọ ọnụahịa na email ndị ahịa",
    invoiceSuccessWhatsappError:
      "Edepụtara akwụkwọ ọnụahịa nke ọma mana enwere mperi zitere na WhatsApp. Nwaa ọzọ na ndepụta akwụkwọ ọnụahịa",
    invoiceSuccessfulEmailError:
      "Edepụtara akwụkwọ ọnụahịa nke ọma mana enwere mperi mgbe ị na-ezipụ dị ka ozi-e. Biko nwaa ọzọ site na ndepụta akwụkwọ ọnụahịa",
    invoiceSentToWhatsapp: "E zigara akwụkwọ ịkpọ òkù na WhatsApp",
    hello: "Nnọọ",
    pleaseDownloadInvoice: "Biko budata akwụkwọ ọnụahịa",
    pleaseDownloadReceipt: "Biko budata akwụkwọ azụmahịa",
    from: "site",
    email: "Email",
    upgrade: "Kwalite",
    youAreOnFreePlan: "Ị nọ na Atụmatụ efu.",
    clickOn: "Pịa na",
    yourPlanInFewSteps: " ndebanye aha gị na usoro ole na ole.",
    to: "na",
    yourSubscriptionHasExpired:
      "Ndenye aha gị agwụla ma akaụntụ gị nwere oke ugbu a.",
    days: "ụbọchị",
    yourSubscriptionExpiresIn: "Ndenye aha Loystar gị ga -agwụ",
    createAcount: "Mepụta akaụntụ",
    signIn: "Banye",
    continue: "Gaa n'ihu",
    enterOtp: "Tinye PIN OTP",
    enterValidOtp: "Biko tinye PIN ziri ezi",
    pin: "PIN",
    tokenSentToMail: "E zigala akara na email gị",
    passwordResetSuccessful: "Ntọgharị paswọọdụ gara nke ọma",
    somethingWentWrong: "Ọ nwere ihe na agaghị nke ọma!",
    resetPassword: "Tọgharia Paswọdu",
    iHaveResetCode: "Enwere m koodu nrụpụta paswọọdụ",
    pleaseEnterEmail: "Biko tinye email gị",
    aTokenWillBeSentToEmail: "A ga -eziga akara na email gị",
    enterEmail: "Tinye email gị",
    sendinYourToken: "Na -eziga akara gị ...",
    makeSureItMatchesPassword: "Hụ na ọ dabara na paswọọdụ ọhụrụ gị",
    pleaseChooseNewPassword: "Biko họrọ okwuntughe ọhụrụ",
    chooseNewPassword: "Họrọ okwuntughe ọhụrụ",
    enterNewPassword: "Tinye paswọọdụ ọhụrụ gị iji gosi",
    enterTokenSent: "Tinye akara nke ezitere ozi gị",
    resetToken: "Tọgharia Token",
    resettingPassword: "Ịtọgharị okwuntughe gị ...",
    signUp: "Debanye aha",
    adminSignInWithEmail:
      " Onye nchịkwa na -eji email abanye ebe ndị ọrụ ji aha njirimara bata.",
    pleaseEnterEmailOrUsername: "Biko tinye email ma ọ bụ aha njirimara gị",
    emailOrUsername: "Email ma ọ bụ aha njirimara",
    pleaseEnterPassword: "Biko tinye paswọọdụ",
    createAnAccount: "Mepụta akaụntụ",
    forgotPassword: "Chefuru okwuntughe?",
    enterPhoneNumber: "Tinye nọmba ekwentị",
    personalData: "Data nkeonwe",
    validateConfirmationCOde: "Kwenye Koodu Nkwenye",
    pleaseEnterFirstName: "Biko tinye aha mbụ gị",
    pleaseEnterPhoneNumber: "Biko tinye nọmba ekwentị gị",
    pleaseEnterLastName: "Biko tinye aha ikpeazụ gị",
    pleaseEnterBusinessName: "Biko tinye aha azụmahịa gị",
    firstName: "Aha mbụ",
    lastName: "Aha ikpeazụ",
    businessName: "Aha azụmahịa",
    previous: "Nke gara aga",
    next: "Osote",
    pleaseSelectBusinessCategory: "Biko họrọ ngalaba azụmahịa gị",
    pleaseEnterValidEmail: "Biko tinye ezigbo email",
    pleaseEnterPostCode: "Biko tinye koodu post",
    postCode: "Koodu nzipu ozi",
    phoneNumberInUse: "Nọmba ekwentị a na -arụ ọrụ ugbua!",
    emailInUse: "E -mail a na -arụ ọrụ ugbua!",
    foodAndDrinks: "Nri na ihe ọrinụrinụ",
    salonAndBeauty: "Ụlọ Mmanya na Mma",
    fashionAndAccessories: "Ejiji na ngwa",
    gymAndFitness: "Mgbatị ahụ na Mma",
    travelAndHotel: "Njem na nkwari akụ",
    homeAndGifts: "Ụlọ na Onyinye",
    washingAndCleaning: "Ịsa na nhicha",
    gadgetsAndElectronics: "Ngwa na Eletrọniki",
    groceries: "Ihe oriri",
    others: "Ndị ọzọ",
    submit: "Nyefere",
    accountCreatedSuccessful: "Emepụtara akaụntụ gị nke ọma.",
    pleaseEnterAddress: "Biko Tinye Adreesị gị",
    addressLine1: "Ahịrị Adreesị 1",
    addressLine2: "Ahịrị adresị 2",
    choosePassword: "Họrọ Okwuntughe",
    passwordMustBe6Characters:
      "Okwuntughe ga -abụrịrị opekata mpe mkpụrụedemede 6.",
    howDidYouHearLoystar: "Kedu ka ị siri nụ maka Loystar?",
    referralCode: "Koodu Ntuziaka",
    byClickingTheButton: " Site na ịpị bọtịnụ dị n'okpuru, ị kwenyere na",
    termsAndSevice: "Usoro",
    wereCreatingAccount: "Anyị na -eke akaụntụ gị",
    proceed: "Gaa n'ihu",
    verificationCodeMustBe6: "Koodu nkwenye ga -abụ ọnụọgụ 6",
    pleaseEnter6DigitCode: "Biko tinye koodu ọnụọgụ 6",
    enterVerificationCode: "Tinye Koodu Nyocha",
    resendToken: "Resend Token",
    verify: "Nyochaa",
    transactions: "Azụmahịa",
    todaySales: "Ahịa taa",
    salesHistory: "Akụkọ ahịa",
    supplyHistory: "Akụkọ ọkọnọ",
    new: "Ọhụrụ",
    invoices: "Akwụkwọ ọnụahịa",
    disbursements: "Mgbapụta",
    offlineSales: "Ahịa anọghị n'ịntanetị",
    products: "Ngwaahịa",
    customers: "Ndị ahịa",
    multiLevelLoyalty: "Iguzosi ike n'ihe n'ọtụtụ",
    loyaltyPrograms: "Mmemme Iguzosi Ike n'Ihe",
    members: "Ndị otu",
    appStore: "Ụlọ ahịa ngwa",
    orderMenu: "Nhazi menu",
    settings: "Ntọala",
    staffAndBranches: "Ndị ọrụ na ngalaba",
    myAccount: "Akaụntụ M",
    switchToSellMode: "Gbanwee na ire ere",
    signOut: "Pụọ",
    getFreeSubscription: "Nweta ndebanye aha n'efu",
    onlyNumbersAllowed: "Naanị ọnụọgụ ka enyere",
    yourAccountMustBe10Digits: "nọmba akaụntụ gị ga -abụ nọmba ọnụọgụ iri",
    yourBvnMustBe11: "BVN gị ga -abụrịrị ọnụọgụ ọnụọgụ iri na otu",
    pleaseSelectBank: "Biko họrọ ụlọ akụ gị",
    selectYourBank: "Họrọ Ụlọ akụ gị",
    enterBankAccountNumber: "Tinye nọmba Akaụntụ Bank",
    enterBvnNumber: "Tinye BVN gị",
    connectBankAccount: "Jikọọ Akaụntụ Bank",
    passwordResetSuccessfulAndSignOut:
      "Emegharịala okwuntughe gị nke ọma. Pịa bọtịnụ ọpụpụ dị n'okpuru ka ị banye ọzọ",
    enterCurrentPassword: "Tinye paswọọdụ ugbu a",
    pleaseEnterCurrentPassword: "Biko tinye paswọọdụ ugbu a",
    phoneNumber: "Nọmba ekwentị",
    sex: "Mmekọahụ",
    dateOfBirth: "Ụbọchị ọmụmụ",
    state: "Steeti",
    country: "Obodo",
    loyaltyId: "NJ iguzosi ike n'ihe",
    createdAt: "Ejiri na",
    noOfTransactionsMade: "Mba nke azụmahịa emere",
    yourDownloadWillStart: "Nbudata gị ga -amalite n'otu ntabi anya",
    exportCustomers: "Ndị ahịa mbupụ",
    youhaveSuccessfullyToppedUp: "Ị buliri nkeji SMS gị nke ọma.",
    youNowHave: "Ị nwere ugbu a",
    smsUnits: "SMS nkeji",
    enterNumberOfSmsUnits: "Tinye nọmba ngalaba nkeji SMS nke ịchọrọ ịzụta",
    pleaseEnterANumericValue: "Biko tinye ọnụọgụ ọnụọgụ",
    pay: "Kwụọ ụgwọ",
    accountEditedSuccessfully: "E deziri akaụntụ gị nke ọma.",
    youAeCurrentlyOn: "Ị nọ ugbu a",
    plan: "Mee atụmatụ",
    userData: "Data onye ọrụ",
    businessData: "DATA Azụmahịa",
    businessCategory: "Ụlọ oriri na ọ Businessụ Businessụ Business",
    pleaseSelectCurrency: "Biko họrọ ego gị",
    selectYourCurrency: "Họrọ ego gị",
    purchaseMoreSmsUnits: "Jiri mpempe dị n'okpuru zụta ọtụtụ SMS",
    youHave: "Ị nwere",
    atLeast4SmsUnitsRrequired:
      "Ọ dịkarịa ala nkeji sms 4 achọrọ maka nkwenye, biko bulie elu!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Biko nyochaa akaụntụ ụlọ akụ gị ka o kwekọọ 'chọrọ onye ahịa gị' (KYC) chọrọ. Nke a ga -enye gị ohere ịnakọta ụgwọ site na USSD ma ọ bụ nyefe ozugbo, nata iwu n'aka ndị ahịa yana ịhazi mmezi azụmahịa. Na -emetụta naanị ndị ahịa Naịjirịa. Biko pịa bọtịnụ dị n'okpuru ka ịmalite.",
    reConnectBankAccount: "Re-jikọọ Akaụntụ Bank",
    accountName: "Aha akaụntụ",
    accountNumber: "Nọmba akaụntụ",
    bankName: "Aha Ụlọ akụ",
    verified: "Enwetara",
    accountDetails: "Nkọwa Akaụntụ",
    kycBankAccount: "KYC",
    createTier: "Mepụta ọkwa",
    fileUploadSuccessful: "ebugo faịlụ nke ọma",
    fileUploadFailed: "ntinye faịlụ agaghị",
    createLoyaltyProgram: "Mepụta Mmemme Iguzosi Ike n'Ihe",
    createLoyalty: "Mepụta Iguzosi Ike n'Ihe",
    name: "Aha",
    loyaltyArtwork: "Nka iguzosi ike n'ihe",
    clickToUpload: "Pịa bulite",
    amountToPointsRatio: "Ọnụ ego ruru ihe ruru",
    points: "Ihe",
    recommendedAmountToPointRatio:
      "Akwadoro: ₦ 1 ruo 1point. ntụgharị maka 1 Naira ọ bụla, ndị ahịa gị na -enweta otu mkpụrụ ego",
    pleaseTypeIn: "Biko pịnye",
    toDeleteLoyalty: "ka ihichapụ iguzosi ike n'ihe a",
    deleteLoyalty: "Hichapụ Iguzosi Ike n'Ihe",
    toConfirm: "iji kwenye",
    edit: "Dezie",
    pointsAwardedSuccessfully: "E nyere Ihe Akara nke Ọma.",
    enterPointValueToAward: "Tinye uru bara uru ị ga -achọ inye ndị ahịa",
    award: "Onyinye",
    awardPointValuesToCustomer: "Ọnụ ahịa onyinye maka onye ahịa",
    enrollMembersToLoyalty: "Debanye aha ndị otu na iguzosi ike n'ihe",
    awardPoints: "Ihe onyinye",
    enroll: "Debanye aha",
    home: "Ụlọ",
    loyalty: "Iguzosi ike n'ihe",
    enrollCustomers: "Debanye aha ndị ahịa",
    selected: "Ahọpụtara",
    customer: "Onye ahịa",
    loyaltyActivationSuccessful:
      "Ịrụ ọrụ n'ikwesị ntụkwasị obi na -aga nke ọma.",
    loyaltyDeactivationSuccessful: "Akwụsịla ikwesị ntụkwasị obi.",
    viewTier: "Lelee Tier",
    deactivate: "Na-arụ ọrụ",
    activate: "Mee ọ rụọ ọrụ",
    actions: "Omume",
    nameOfLoyalty: "Aha Iguzosi Ike n'Ihe",
    loyaltyStatus: "Ọnọdụ ịnọgidesi ike n'ihe Ọnọdụ",
    numberOfTiers: "Ọnụ ọgụgụ nke Tiers",
    createdOn: "Ejiri On",
    createATier: "Mepụta ọkwa",
    stopCreatingTierConfirmation: "Ị chọrọ ịkwụsị imepụta ọkwa?",
    stopEditingTierConfirmation: "Ị chọrọ ịkwụsị ịhazigharị ọkwa a?",
    nameOfTier: "Aha Ọkwa",
    minimumSpendingTarget: "Ebumnuche itinye ego kacha nta",
    maximumSpendingTarget: "Ebumnuche na -emefu oke",
    minimumSpendingTargetRequired: "a na -achọ ihe mgbaru ọsọ kacha nta",
    maximumSpendingTargetRequired: "a na -achọ ihe mgbaru ọsọ kachasị",
    rewardSponsor: "Onye Nkwado Ụgwọ",
    pleaseChooseARewardSponsor: "Biko họrọ onye nkwado ụgwọ ọrụ",
    self: "Onwe",
    partner: "Onye Mmekọ",
    rewardPartner: "Onye Mmekọ Ọrụ",
    pleaseSelectRewardPartner: "Biko họrọ onye mmekọ ụgwọ ọrụ gị",
    rewards: "Ụgwọ ọrụ",
    pleaseSelectAReward: "Biko họrọ ụgwọ ọrụ",
    instructionsOnRedeemingReward:
      "Ntuziaka maka ka onye ahịa kwesịrị ịgbapụta ụgwọ ọrụ",
    pleaseFillInThisField: "Biko Dejupụta n'Ubi a!",
    toDeleteThisTier: " ka ihichapụ ọkwa a",
    deleteTier: "Hichapụ Ọkwa",
    viewMembers: "Lelee ndị otu",
    membersEnrolled: "Debara aha ndị otu",
    instruction: "Ntụziaka",
    membersIn: "Ndị otu nọ",
    availableTiersInLoyalty: "Tier (ndị) dị na Mmemme Iguzosi Ike n'Ihe",
    tiers: "Ọdịdị",
    totalTier: "Mkpokọta ọkwa",
    availableLoyaltyProgramme: "Mmemme Iguzosi Ike n'Ihe Dị",
    totalLoyalties: "MGBE NTỤTARA",
    memberDetails: "Nkọwa ndị otu",
    nameOfCustomer: "Aha onye ahịa",
    address: "Adreesị",
    allEnrolledMembers: "Ndị niile edebanyere aha",
    thisIsToWishYouHappyBirthday:
      "Nke a bụ ị na -achọ gị ụbọchị ọmụmụ na ezi ndụ. Daalụ maka ihe niile ị bụ Loyster. Mmemme Ọ Happyụ!",
    inputAnOfferPlease: "Tinye onyinye biko",
    pleaseSelectTheInsertPoint: "Biko họrọ ebe ntinye na ozi wee pịa ọzọ",
    birthdayOfferAndMessage: "Onyinye ụbọchị ọmụmụ na ozi",
    birthdayOffer: "Onyinye ụbọchị ọmụmụ",
    birthdayMessage: "Ozi ụbọchị ọmụmụ",
    noOfferFound: "Onweghị onyinye a hụrụ",
    settingABirthdayOffer:
      "Isetinye onyinye ụbọchị ọmụmụ na -enye ndị ahịa ohere ịnata onyinye a site na SMS na ụbọchị ọmụmụ ha",
    createOffer: "Mepụta Nye",
    whatIsTheOffer: "Kedu ihe a na -enye?",
    editMessage: "Dezie ozi",
    insert: "Fanye",
    theNameOfCustomerInserted: "A ga -etinyere aha onye ahịa ebe a",
    theBirtdayOfferInserted: "A ga -etinyere onyinye ụbọchị ọmụmụ ebe a",
    youSuccessfullyAddedNewBranch: "Ị tinyela ngalaba ọhụrụ nke ọma!",
    addNewBranch: "Tinye Alaka Ọhụrụ",
    addBranch: "Tinye Alaka",
    additionalBranchWillIncur: "Alaka agbakwunyere ga -apụta",
    perBranch: "kwa alaka",
    ecommerceBranchCosts: "Ngalaba Ala Ecommerce na -akwụ ụgwọ",
    pleaseEnterBranchName: "Biko tinye aha ngalaba",
    pleaseEnterBranchAddress1: "Biko tinye ahịrị adreesị alaka ahụ 1",
    enterBranchAddress1: "Tinye akara adreesị alaka ahụ 1",
    enterBranchAddress2: "Tinye akara adreesị alaka ahụ 1",
    pleaseEnterBranchAddress2: "Biko tinye ahịrị adreesị alaka 2",
    enterBranchName: "Tinye aha alaka",
    successfullyAddedStaff: "Ị tinyela onye ọrụ ọhụrụ nke ọma!",
    addNewStaff: "Tinye ndị ọrụ ọhụrụ",
    addStaff: "Tinye ndị ọrụ",
    additionalStaffWillIncur: "Ndị ọrụ ndị ọzọ ga -eweta",
    perStaff: "kwa mkpara.",
    pleaseEnterStaffEmail: "Biko tinye ozi ndị ọrụ",
    pleaseEnterStaffUsername: "Biko tinye aha njirimara ndị ọrụ",
    pleaseEnterStaffPassword: "Biko tinye paswọọdụ onye ọrụ",
    pleaseSelectStaffRole: "Biko họrọ ọrụ ndị ọrụ",
    selectStaffRole: "Họrọ ọrụ ndị ọrụ",
    enterStaffEmail: "Tinye ozi onye ọrụ",
    enterStaffUsername: "Tinye aha njirimara ndị ọrụ",
    enterStaffPassword: "Tinye paswọọdụ onye ọrụ",
    spacesNotAllowedInUsername: "anabataghị oghere na aha njirimara",
    admin: "Onye nlekọta",
    pleaseSelectBusinessToAttachStaff:
      "Biko họrọ azụmahịa ka ịgbakwunye ndị ọrụ",
    searchForBranchToAttachStaff: "Chọọ ngalaba iji tinye ndị ọrụ",
    username: "Aha njirimara",
    role: "Ọrụ",
    areYouSureToDeleteThis: "O doro gị anya na ị ga -ehichapụ ihe a",
    branches: "Alaka",
    upgradeYourPlan: "Kwalite atụmatụ gị.",
    add: "Tinye",
    addNew: "Tinye Ọhụrụ",
    customerWithEmailAlreadyExists:
      "Onye ahịa nwere email/akara ekwentị adịlarị!",
    successfullyAddedNewCustomer: "Ị tinyela onye ahịa ọhụrụ nke ọma!",
    addCustomer: "Tinye onye ahịa",
    pleaseEnterCustomerFirstName: "Biko tinye aha onye ahịa",
    pleaseEnterCustomerLastName: "Biko tinye aha ikpeazụ ndị ahịa",
    pleaseEnterCustomerPhoneNumber: "Biko tinye nọmba ekwentị ndị ahịa",
    pleaseEnterCustomerEmail: "Biko tinye email onye ahịa",
    pleaseEnterCustomerAddressLine: "Biko tinye Line Address nke ndị ahịa",
    pleaseEnterCustomerOtherAddress: "Biko tinye adreesị ndị ọzọ nke ndị ahịa",
    pleaseSelectCustomerGender: "Biko họrọ okike onye ahịa",
    gender: "Okike",
    male: "Nwoke",
    female: "Nwanyị",
    bank: "Ụlọ akụ",
    selectBank: "Họrọ Bank",
    stateOrRegionOrProvince: "Steeti/Mpaghara/Mpaghara",
    customerNotes: "Ihe edeturu ndị ahịa",
    sendSms: "Zipu SMS",
    editCustomer: "Dezie ahịa",
    redeemReward: "Gbapụta Ụgwọ Ọrụ",
    issueLoyaltyCard: "Nye Kaadị Iguzosi Ike n'Ihe",
    deleteCustomer: "Hichapụ onye ahịa",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ị nyeferela NJ ndị otu iguzosi ike n'ihe nke ọma",
    noMembershipIdAvailable:
      "Enweghị NJ ndị otu dị. Biko kpọtụrụ anyị na hello@loystar.co",
    sendEmail: "Send Email",
    membershipPoints: "Ihe Ndị Otu",
    customerDetails: "Nkọwa ndị ahịa",
    close: "Mechie",
    loyaltyBalance: "Iguzosi Ike n'Ihe",
    pointsBalance: "Ntụle Ihe",
    starBalance: "Ụda kpakpando",
    requiredPoints: "Isi ihe achọrọ",
    requiredStars: "Kpakpando ndị achọrọ",
    reddemCode: "Mgbapụta Koodu",
    toDeleteThisCustomer: "ka ihichapụ onye ahịa a",
    customerHasBeenDeletedSuccessfully: "Ehichapụla onye ahịa nke ọma!",
    customerWithPhoneAlreadyExists: "Onye ahịa nwere akara ekwentị adịlarị",
    customerWasSuccessfullyEdited: "E deziri onye ahịa nke ọma",
    anErrorOccured: "Mperi mere",
    phoneNumberIncludeCountry: "Nọmba ekwentị (Gụnye koodu obodo)",
    yourFileQueuedForUpload:
      "E debere faịlụ gị n'ahịrị maka ibugo. Biko mee ka ibe ahụ dị ọhụrụ mgbe sekọnd ole na ole gasịrị.",
    thereWasAnErrorPerformingOperation: "Enwere mperi n'ịrụ ọrụ ahụ!",
    pleaseSelectFile: "Biko Họrọ faịlụ!",
    oopsOnlyCsvFilesAllowed:
      "Acha! Naanị faịlụ CSV ka akwadoro. Biko bulite faịlụ .csv.",
    fileShouldBeSmallerThan5Mb:
      "Faịlụ kwesịrị pere mpe karịa 5MB. Ọ bụrụ na ị nwere faịlụ buru ibu, biko zitere email support@loystar.co. Daalụ",
    customerFirstNameIsRequired: "Achọrọ aha onye ahịa n'ahịrị",
    customerPhoneNumberIsRequired: "achọrọ nọmba ekwentị ndị ahịa n'usoro",
    importCustomers: "Bubata ndị ahịa",
    upload: "Bulite",
    clickIconToDownloadCsv: "Pịa akara ngosi a ka ibudata ndebiri faịlụ CSV.",
    getGoogleSheetVersion: "Nweta ụdị mpempe akwụkwọ Google",
    clickOrDragFileToUpload: "Pịa ma ọ bụ dọrọ faịlụ na mpaghara a ka ị bulite",
    missingOrInvalidColumnHeader:
      "Isi ihe kọlụm na -efu ma ọ bụ na -adịghị mma. Biko soro usoro ndebiri. Daalụ.",
    youHaveImported: "Ị Bubatala",
    youSuccessfullyRedeemedReward: "Ị gbapụtala ụgwọ ọrụ gị nke ọma!",
    sixDigitCode: "Koodu ọnụọgụ isii",
    pleaseEnterCustomerRewardCode: "Biko tinye koodu ụgwọ ndị ahịa",
    enterRewardCode:
      "Tinye koodu nkwụghachi ụgwọ. (Koodu ụgwọ ọrụ ahụ nwere mmetụta dị nhịahụ)",
    selectLoyaltyProgram: "Họrọ mmemme iguzosi ike n'ihe",
    stamps: "Akara",
    smsUnitsLow: "Nkeji SMS dị ala",
    pleaseTopUpSmsUnits: "Biko bulite nkeji SMS",
    smsSuccessfullySentForDelivery: "Ezipụla SMS nke ọma maka nnyefe!",
    sendSmsTo: "Zipu SMS na",
    allCustomers: "Ndị ahịa niile",
    unitsAvailable: "Nkeji dị",
    pleaseTypeInTheMessage: "Biko pịnye ozi",
    message: "Ozi",
    charactersRemaining: "mkpụrụedemede fọdụrụ",
    avoidUseOfSpecialCharacters:
      "Zere iji mkpụrụedemede pụrụ iche na Emojis chekwaa nkeji SMS.",
    note: "Rịba ama",
    errorFetchingCustomersMultilevelDetail:
      "Njehie Nweta nkọwa ndị ahịa ọtụtụ ọkwa",
    search: "Chọọ",
    reset: "Tọgharia",
    importCustomer: "Bubata Onye ahịa",
    addNewCustomer: "Tinye onye ahịa ọhụrụ",
    sendSmsBroadcast: "Zipu Mgbasa Ozi SMS",
    totalCustomers: "Mkpokọta ndị ahịa",
    disbursementDetails: "Nkọwa ego",
    paidBy: "Akwụ ụgwọ site",
    disbursed: "Enyefere",
    bankAccountName: "Aha Akaụntụ Bank",
    bankAccountNumber: "Nọmba Akaụntụ Bank",
    transferInitiated: "Mbido Amalitere",
    transferCompleted: "Nyefe Emechara",
    pleaseEnterAValid: "Biko tinye nke ziri ezi",
    begin: "malite",
    end: "ọgwụgwụ",
    date: "ụbọchị",
    paymentDate: "Ụbọchị ịkwụ ụgwọ",
    selectDisbursementDuration: "Họrọ Oge ịkwụ ụgwọ",
    totalSettled: "Enwela ngụkọta",
    totalUnsettled: "Mgbakasị agbanyeghị",
    toDeleteThisSale: "ka ihichapu ire ere a",
    draftSaleDeletedSuccessfully: "Ehichapụla Draft Sale nke ọma!",
    deleteSale: "Hichapụ Ọrịre",
    pleaseTypeInYourTotal: "Biko pịnye mkpokọta gị iji gosi nhichapụ",
    billDetails: "Nkọwa Bill",
    printBill: "Bill Bipụta",
    servedBy: "Ejere ya ozi",
    total: "Mgbakọta",
    createdDate: "Ụbọchị emepụtara",
    createdTime: "Oge Emepụtara",
    status: "Ọkwa",
    loginSuccessful: "Nbanye gara nke ọma",
    pleaseWaitWhileWeConnectAccount: "Biko chere ka anyị jikọọ akaụntụ gị",
    connectionFailedTryAgain: "Njikọ agaghị. Biko nwaa ọzọ.",
    connectionSuccessful: "Njikọ gara nke ọma",
    viewDetails: "Lee nkọwa",
    enable: "Kwado",
    free: "Free",
    cost: "Ọnụ",
    visitWebsite: "Gaa na weebụsaịtị",
    pleaseUpgradeYourPlanToPro:
      "Biko kwalite atụmatụ gị na Pro Plus iji mee ka ngwa a nwee ike",
    connectYourBankAccount:
      "Jikọọ akaụntụ Bank gị ka ị nwee ike nweta ịkwụ ụgwọ.",
    disable: "Gbanyụọ",
    enableApp: "Kwado Ngwa",
    addNewProductToInvoice: "Tinye ngwaahịa ọhụrụ na akwụkwọ ọnụahịa",
    toDeleteThisInvoice: "ka ihichapu akwụkwọ ọnụahịa a",
    invoiceDeletedSuccessfully: "Ehichapụla akwụkwọ ọnụahịa nke ọma!",
    deleteInvoice: "Hichapụ akwụkwọ ọnụahịa",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ị gbakwunyere ozi ịkwụ ụgwọ akwụkwọ ọnụahịa nke ọma!",
    pleaseEnterPaymentInstructions: "Biko tinye ntuziaka ịkwụ ụgwọ",
    invoiceId: "NJ akwụkwọ ọnụahịa",
    paidDate: "Ụbọchị akwụ ụgwọ",
    reference: "Ntụaka",
    productAdded: "Ngwaahịa agbakwunyere",
    productOutOfStock: "Ngwaahịa agwụla. Biko re-ngwaahịa.",
    totalInvoices: "Mkpokọta ego",
    totalPaidInvoices: "Mkpokọta ego akwụ ụgwọ",
    totalUnpaidInvoices: "Mkpokọta ego anaghị akwụ ụgwọ",
    loyaltyProgramDeleted: "Ehichapụrụ Mmemme Iguzosi Ike n'Ihe",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ehichapụla mmemme ikwesị ntụkwasị obi a nke ọma",
    thisLoyaltyProgramEditedSuccessfully:
      "Edeziela mmemme ikwesị ntụkwasị obi a nke ọma",
    loyaltyProgramAddedSuccessfully:
      "E tinyela mmemme ikwesị ntụkwasị obi nke ọma",
    loyaltyProgramEdited: "Edeziri Mmemme Iguzosi Ike n'Ihe",
    loyaltyProgramAdded: "Agbakwunyere Mmemme Iguzosi Ike n'Ihe",
    loyaltyDetails: "Nkọwa iguzosi ike n'ihe",
    doYouWantToCloseDialog: "Ị chọrọ imechi mkparịta ụka a?",
    pleaseEnterLoyaltyName: "Biko tinye aha iguzosi ike n'ihe gị",
    programType: "Ụdị Mmemme",
    pleaseSelectProgramType: "Biko họrọ ụdị mmemme",
    simplePoints: "Isi Ihe Ndị Dị Mfe",
    stampsProgram: "Mmemme stampụ",
    threshold: "Ọnụ ụzọ",
    pleaseEnterLoyaltyThreshold: "Biko tinye n'ọnụ ntụkwasị obi",
    reward: "Ụgwọ ọrụ",
    pleaseEnterLoyaltyReward: "Biko tinye ụgwọ ọrụ nke iguzosi ike n'ihe",
    totalUserPoints: "Mkpokọta Ihe Ndị Ọrụ",
    totalUserStamps: "Mgbakọta stampụ onye ọrụ",
    spendingTarget: "Imefu ebumnuche",
    spendingTargetHint1:
      "Ego a na -emefu bụ ego onye ahịa ga -emefu iji nweta ụgwọ ọrụ ahụ. Ọnụ ego 1 = 1 isi.",
    spendingTargetHint2:
      "Ebumnuche stampụ bụ stampụ ole onye ahịa ga -anakọta iji nweta ụgwọ ọrụ. ịmaatụ. 5",
    addNewLoyaltyProgram: "Tinye mmemme iguzosi ike n'ihe ọhụrụ",
    addLoyaltyProgram: "Tinye Mmemme Iguzosi Ike n'Ihe",
    loyaltyProgramType: "Ụdị Mmemme Iguzosi Ike n'Ihe",
    pleaseSelectLoyaltyProgramType: "Biko họrọ ụdị mmemme iguzosi ike n'ihe",
    nameOfProgram: "Aha mmemme",
    pleaseEnterProgramName: "Biko tinye aha mmemme",
    whatIsTheReward: "Gịnị bụ ụgwọ ọrụ ya?",
    egNextPurchaseIsFree: "Dịka Ịzụta ọzọ bụ n'efu",
    customerName: "Aha ndị ahịa",
    guestCustomer: "Onye ahịa ndị ọbịa",
    orderConfirmedSuccessfully: "Enwetara usoro nke ọma",
    orderCancelledSuccessfully: "A kagbuola iwu nke ọma",
    confirmOrder: "Kwenye usoro",
    cancelOrder: "Kagbuo usoro",
    allOrders: "Iwu niile",
    totalOrders: "MGBE NTỌTA",
    doYouWantToAcceptOrder: "Ị chọrọ ịnabata iwu a?",
    doYouWantToCancelOrder: "Ị chọrọ ịkagbu iwu a?",
    orderDetails: "Nkọwa nkọwa",
    orderCreatedAt: "Emere ịtụ na",
    supplier: "Onye na -ebubata ihe",
    productName: "Aha ngwaahịa",
    quantity: "Ọnụ ọgụgụ",
    unitPrice: "Ọnụahịa otu",
    receivedBy: "Natara site",
    reportFrom: "Kpesa si",
    totalSupplies: "Mgbakọta Ngwa",
    allRightsReserved: "Ikike niile echekwabara",
    toDeleteThisTransaction: "ka ihichapụ Azụmahịa a",
    transactionDeletedSuccessfully:
      "Ehichapụla azụmahịa nke ọma. Eweghachila ngwaahịa ahụ n'akwụkwọ ndekọ ego.",
    deleteTransaction: "Hichapụ Mmekọrịta",
    transactionDetails: "Nkọwa azụmahịa",
    printReceipt: "Mbipụta Bipụta",
    channel: "Ọwa",
    discount: "Ego",
    profit: "Uru",
    discountedSales: "Ahịa ego efu",
    errorFetchingRecord: "Mperi Ịweta Ndekọ",
    exportTransactions: "Ahịa mbupụ",
    errorFetchingSalesRecord: "Mperi na -ebute ndekọ ndekọ ahịa maka mbupụ.",
    totalSellingPrice: "Ọnụahịa ire ere",
    totalCostPrice: "Ọnụ ego efu",
    appliedDiscount: "Ntinye ego etinyere",
    noOfItems: "Mba nke ihe",
    sales: "Ahịa",
    export: "Mbupụ",
    totalProfit: "Mkpokọta Uru",
    totalBalanceInPeriod: "Mgbakọta zuru oke na oge",
    allTimeSales: "Ahịa Oge Niile",
    records: "Ndekọ",
    todaysSales: "Ahịa taa",
    transaction: "azụmahịa",
    youHaveExceededTotalNumberOfProducts:
      "Ị gaferela ọnụ ọgụgụ ngwaahịa enyere na atụmatụ gị. Kwalite atụmatụ gị ka ị nweta oke oke.",
    youNeedToHaveLoyaltyProgram:
      "Ịkwesịrị ịnwe Mmemme Iguzosi Ike n'Ihe iji njirimara a!",
    price: "Ahịa",
    category: "Udi",
    stockTracking: "Ndepụta ngwaahịa",
    stockCount: "Ọnụ Ọgụgụ",
    taxed: "Ụtụ isi",
    originalPrice: "Ọnụahịa mbụ",
    costPrice: "Ọnụ Ahịa",
    unit: "Nkeji",
    productImage: "Foto ngwaahịa",
    taxRate: "Ọnụego ụtụ",
    taxType: "Ụdị Ụtụ",
    trackInventory: "Sochie Ndepụta Ngwa",
    variants: "Ọdịiche",
    hasVariants: "Nwere Variants",
    importProduct: "Bubata Ngwaahịa",
    exportProducts: "Ngwaahịa mbupụ",
    addNewProduct: "Tinye Ngwaahịa Ọhụrụ",
    viewCategory: "Lelee udi",
    viewSuppliers: "Lelee ndị na -eweta ya",
    receiveInventory: "Nata Ndepụta Ngwaahịa",
    printAllProductsTag: "Bipute Ngwaahịa niile Kpido",
    deleteAll: "Hichapụ ihe niile",
    totalProducts: "Mkpokọta ngwaahịa",
    youveSuccessfullyAddedANewCategory: "I tinyela ngalaba ọhụrụ nke ọma",
    addNewCategory: "Tinye udi ohuru",
    addCategory: "Tinye udi",
    categoryName: "Aha ngalaba",
    pleaseEnterCategoryName: "Biko tinye aha ngalaba",
    stampsTarget: "Ebumnuche Stampụ",
    sendInventory: "Zipu Ndepụta Ngwaahịa",
    productDetails: "Nkọwa ngwaahịa",
    youveSuccessfullyEditedThisCategory: "Ị deziela ụdị nke a nke ọma",
    update: "Melite",
    categoryList: "Ndepụta udi",
    categories: "Ngalaba",
    enterQuantityToUpdate: "Tinye oke ka imelite",
    inventorySentSuccessfully: "E zigara ngwa ahịa nke ọma!",
    updateInventory: "Melite Ndepụta Ngwa",
    currentQuantity: "Ọnụ ọgụgụ ugbu a",
    pleaseEnterQuantityToAdd: "Biko tinye ọnụọgụ ịchọrọ ịgbakwunye",
    pleaseSelectABranch: "Biko họrọ Alaka",
    searchForBranch: "Chọọ alaka",
    youCantSendMoreThanStock: "Ị nweghị ike izipu karịa ihe ị nwere",
    send: "Zipụ",
    pleaseEnterQuantityToSend: "Biko tinye ọnụọgụ ịchọrọ iziga",
    productNameIsRequiredOnRow: "achọrọ ngwaahịa aha n'ahịrị",
    productCategoryIsRequiredOnRow: "Achọrọ ngwaahịa ngwaahịa n'ahịrị",
    productPriceIsRequiredOnRow: "achọrọ ọnụahịa ngwaahịa n'ahịrị",
    productUnitIsRequiredOnRow: "achọrọ otu ngwaahịa n'ahịrị",
    productQuantityIsRequiredOnRow: "achọrọ ọnụọgụ ngwaahịa n'ahịrị",
    productVariantsRequireTracking: "ụdị ngwaahịa chọrọ nsuso!",
    pleaseAddVariantClickButton:
      "Biko tinye ihe dị iche site na ịpị bọtịnụ ịgbakwunye!",
    totalVariantsMoreThanSelectedQuantity:
      "Mgbakwunye dị iche iche karịrị ọnụọgụ ngwaahịa ahọpụtara, Pls belata ọnụọgụ dịgasị iche",
    productEditedSuccessfully: "Edeziela ngwaahịa nke ọma!",
    fileTooLargeLessThan4Mb:
      "Nha faịlụ buru oke ibu! Ogo faịlụ kwesịrị erughị 4MB.",
    suchVariantAlreadyExist: "Ọdịiche dị otu a adịlarị",
    addVariants: "Tinye Ọdịiche",
    editProduct: "Dezie Ngwaahịa",
    pleaseEnterProductName: "Biko Tinye Aha Aha",
    pleaseEnterProductPrice: "Biko Tinye Ọnụahịa Ngwaahịa",
    pleaseEnterProductOriginalPrice: "Biko Tinye Product Original Price",
    productDescription: "Nkọwa ngwaahịa",
    selectProductCategory: "Họrọ udi ngwaahịa",
    pleaseSelectProductCategory: "Biko họrọ ụdị ngwaahịa",
    productCostPrice: "Ọnụahịa ọnụ ahịa ngwaahịa",
    productSellingPrice: "Ọnụahịa ire ngwaahịa",
    productOriginalPrice: "Ọnụahịa mbụ nke ngwaahịa",
    maxFileSizeAllowedIs4Mb: "Max. Ogo faịlụ enyere bụ 4mb",
    productsWithPicturesArePublished:
      "A na -ebipụta ngwaahịa nwere foto na Discover iji nweta iwu",
    shouldThisProductBeTracked: "Ekwesịrị inyocha ngwaahịa a?",
    pleaseSelectStockUnit: "Biko họrọ otu ngwaahịa",
    stockUnit: "Otu ngwaahịa",
    bag: "Akpa",
    bottle: "Karama",
    bunch: "Nri",
    can: "Ike",
    carton: "AKỤKỌ",
    crate: "EKPERE",
    cup: "Iko",
    dozen: "DOZEN",
    gigabytes: "Ndị GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "AKWỤKWỌ",
    pack: "Ngwugwu",
    pair: "ỤWA",
    pieces: "Iberibe",
    plate: "EKPERE",
    tonne: "TONNE (MT)",
    uNIT: "Nkeji",
    yard: "YARD",
    pleaseEnterProductQuantity: "Biko tinye ọnụọgụ ngwaahịa",
    productQuantity: "Ọnụ ọgụgụ ngwaahịa",
    isThisProductTaxed: "A na -atụ ụtụ ngwaahịa a?",
    selectTaxType: "Họrọ Ụdị Ụtụ",
    pleaseSelectTaxType: "Biko họrọ Ụdị Ụtụ",
    progressive: "Na -aga n'ihu",
    proportional: "Ọha nhata",
    pleaseEnterProductTaxRate: "Biko tinye ọnụego ụtụ ngwaahịa",
    doesProductHaveVariants: "Ngwaahịa a enwere ndịiche?",
    type: "Ụdị",
    value: "Uru",
    pleaseEnterVariantType: "Biko tinye ụdị ụdị",
    pleaseEnterVariantValue: "Biko tinye uru dị iche",
    pleaseEnterVariantPrice: "Biko tinye ọnụ ahịa ndịiche",
    pleaseEnterVariantQuantity: "Biko tinye ọnụọgụ ọnụọgụ",
    productDeletedSuccessfully: "Ehichapụla ngwaahịa nke ọma!",
    categoryDeletedSuccessfully: "Ehichapụla ngalaba nke ọma!",
    toDeleteThisProduct: "ka ihichapụ Ngwaahịa a",
    invalidProductQuantity: "Ọnụahịa Ngwaahịa ezighi ezi",
    quantityAddedIsOverStock: "Ọnụ ọgụgụ ị na -agbakwụnye karịrị ihe ị nwere.",
    itemInventoryNotTracked: "Achọpụtaghị ngwongwo ihe",
    addBulkQuantity: "Tinye nnukwu ibu",
    enterBulkQuantity: "Tinye nnukwu ibu",
    pleaseEnterBulkQuantity: "Biko tinye nnukwu ibu",
    youveSuccessfullyAddedANewProduct: "Ị tinyela ngwaahịa ọhụrụ nke ọma!",
    pleaseEnterProductSellingPrice: "Biko tinye ọnụahịa ire ngwaahịa",
    doYouWantToTrackProductStock: "Ị chọrọ soro ngwaahịa ngwaahịa?",
    sellingPrice: "Ọnụ Ahịa",
    setProductExpiryReminder: "Tọọ ncheta Mmebi Ngwaahịa",
    productExpiryDate: "Ụbọchị Mmebi ngwaahịa",
    startRemindingFrom: "Malite Icheta Site",
    productSuppliesAddedSuccessfully:
      "Ị gbakwunyere ngwongwo ngwaahịa nke ọma.",
    addProductSupplies: "Tinye Ngwaahịa Ngwaahịa",
    pleaseSelectSupplier: "Biko Họrọ Soplaya",
    nameOfProduct: "Aha Ngwaahịa",
    pleaseSelectProduct: "Biko Họrọ Ngwaahịa",
    productVariant: "Ọdịiche ngwaahịa",
    pleaseSelectAVariant: "Biko Họrọ Ọdịiche",
    pleaseEnterUnitPrice: "Biko Tinye Ọnụahịa otu",
    businessBranch: "Alaka azụmahịa",
    pleaseSelectBranch: "Biko họrọ ngalaba",
    youveSuccessfullyAddedANewSupplier:
      "I tinyela onye na -eweta ngwaahịa ọhụrụ",
    addSupplier: "Tinye soplaya",
    pleaseEnterSupplierEmail: "Biko tinye email soplaya",
    pleaseAddADescription: "Biko tinye nkowa",
    anErrorOccuredProductsDeleted:
      "Mperi mere mgbe ị na -arụ ọrụ ahụ. Biko mara, ngwaahịa ole na ole nwere ike ehichapụla na usoro a",
    bulkDelete: "Nnukwu Hichapụ",
    youAreAboutToDelete: "Ị na -achọ ihichapụ",
    product: "Ngwaahịa",
    isDueToRedeem: "bụ maka ịgbapụta",
    aReward: "ụgwọ ọrụ",
    pleaseSelectCustomerToReeemReward:
      "Biko họrọ onye ahịa ka ọ gbapụta ụgwọ ọrụ.",
    youHaveNoLoyaltyProgramRunning:
      "Ị nweghị mmemme iguzosi ike n'ihe na -arụ ọrụ",
    customerHhasNoPointsInLoyaltyProgram:
      "Onye ahịa enweghị isi na mmemme iguzosi ike n'ihe a",
    proceedWithPayment: "Gaa n'ihu na Ịkwụ Ụgwọ?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ị na -achọ ịkwụ ụgwọ maka azụmahịa site na iji isi ihe.",
    customerHas: "Onye ahịa nwere",
    worth: "uru",
    andIsNotSufficientToPayForTransaction:
      "na ezughị ezu ịkwụ ụgwọ maka azụmahịa a. Ha ga -achọ ịgbakwunye itule site na iji usoro ịkwụ ụgwọ ọzọ?",
    addCustomerLoyalty: "Tinye Ikwesị ntụkwasị obi nke ndị ahịa",
    pleaseAddCustomerFirst: "Biko tinye ma ọ bụ họrọ onye ahịa na mbụ.",
    pleaseWaitWhileWeGetReady: "Biko chere, ka anyị na -akwado",
    lowStock: "Ngwaahịa dị ala",
    pleaseEnterAmountTendered: "Biko tinye ego enyere",
    areYouSureToBookSaleOffline:
      "O doro gị anya na ị chọrọ ịde akwụkwọ ọrịre a n'ịntanetị?",
    saleWouldBeBookedAutomatically:
      "A ga -edere ire ere na akpaghị aka mgbe ị gbanyere ịntanetị",
    offlineSalesBookingCancelled:
      "A kagbuola ntinye akwụkwọ ịre ahịa n'ịntanetị",
    covid19Message:
      "COVID19: Jiri ncha sachaa aka gị iji kwụsị mgbasa. Nọrọ na nchekwa mgbe niile",
    saleSuccessfullyRecorded: "E Dekọrọ Ọrịre nke Ọma!",
    sendReceiptToEmail: "Zipu akwụkwọ ozi na Email",
    sendThankYouSms: "Zipu ozi ekele SMS",
    sendShippingDetails: "Zipu nkọwa Mbupu",
    addLoyalty: "Tinye Iguzosi Ike n'Ihe",
    searchCustomerNameOrNumber: "Chọọ aha onye ahịa ma ọ bụ nọmba",
    clickTheSearchCustomerBox: "Pịa igbe ndị ahịa ọchụchọ na kaadị nyocha",
    enterProductPrice: "Tinye Ọnụahịa Ahịa",
    enterPriceFor: "Tinye Ahịa maka",
    searchForProduct: "Chọọ ngwaahịa",
    all: "Niile",
    backToDashboard: "Laghachi na dashboard",
    viewDraftSales: "Lelee Ahịa Draft",
    variantSelected: "nhọrọ ahọpụtara",
    variant: "iche",
    thePreviousVariantSelectionNotAvailable:
      "Nhọrọ dị iche iche gara aga adịghị maka ụdị ọhụrụ ahọpụtara dabere na ọnụahịa, biko gbanwee nhọrọ gị.",
    pleaseSupplyPositiveQuantityNumber: "Biko nye nọmba ọnụọgụ ọnụọgụ",
    lowStockFor: "Obere ngwaahịa maka",
    clearVariants: "Ụdị dị iche",
    pleaseEnterQuantity: "Biko tinye Oke",
    picture: "Foto",
    redemptionToken: "Token mgbapụta",
    token: "Akara",
    totalSpent: "Mgbakọta Ego",
    confirmPayment: "Kwenye ịkwụ ụgwọ",
    hasPaymentBeenMade: "A haziela ịkwụ ụgwọ nke ọma?",
    enterTransactionReference: "Tinye akwụkwọ nrụtụaka azụmahịa ị kwụrụ",
    pleaseSelectACustomer: "Biko họrọ onye ahịa!",
    areYouSureToApplyDiscount: "O doro gị anya na ịchọrọ itinye ego?",
    addYourBankAccountToEnableUssd:
      "Tinye akaụntụ akụ gị iji mee ka nnyefe USSD ozugbo site na uPay",
    totalAmountToPay: "Mgbakọta ego iji kwụọ ụgwọ",
    doYouWantToCancelTransaction: "Ị chọrọ ịkagbu azụmahịa a?",
    savePrintBill: "Bill Chekwa/Bipụta",
    enterAmountCollectedForCustomer: "Tinye ego anakọtara maka onye ahịa",
    recordSale: "Ahịa ndekọ",
    checkOutWith: "Lelee ya",
    instantTransfer: "Nyefe ozugbo",
    dialTheUSSDCode: "Kpọọ koodu USSD",
    pleaseSelectABank: "Biko họrọ ụlọ akụ",
    payWithUSSD: "Jiri USSD kwụọ ụgwọ",
    sendBillTo: " - Ziga Bill na",
    waitingForUSSDTransfer: "Na -eche nnyefe USSD",
    percent: "Pasent",
    applyDiscount: "Tinye ego",
    thisBillHasBeenSaved: "Echekwara Bill a",
    saveDraft: "Chekwaa Draft",
    pleaseTypeANameToIdentifyCustomer: "Biko pịnye aha iji mata onye ahịa",
    paymentDetails: "Nkọwa ịkwụ ụgwọ",
    basePrice: "Ọnụahịa ala",
    staff: "Ndị ọrụ",
    subTotal: "Mkpokọta",
    durationMonths: "Oge (ọnwa)",
    selectADuration: "Họrọ Oge",
    oneMonth: "1 Ọnwa",
    twoMonths: "2 Ọnwa",
    threeMonths: "3 Ọnwa",
    sixMonths: "6 Ọnwa",
    twelveMonths: "12 Ọnwa",
    eighteenMonths: "18 Ọnwa",
    twentyFourMonths: "24 Ọnwa",
    twoMonthsFree: "(Ọnwa abụọ n'efu)",
    threeMonthsFree: "(Ọnwa 3 n'efu)",
    fiveMonthsFree: "(Ọnwa ise efu)",
    yourAccountHasBeen: "Akaụntụ gị adịla",
    renewed: "ọhụrụ",
    upgraded: "kwalitere",
    successfully: "nke ọma",
    yourSubscription: "Ndenye aha gị",
    youAreCurrentlyEnrolledOnA: "Ị debanyere aha ugbu a na a",
    pleaseChooseAPaymentOption: "Biko Họrọ Nhọrọ Ịkwụ Ụgwọ",
    planRenewal: "Emeghari atụmatụ",
    planUpgrade: "Atụmatụ nkwalite",
    pleaseSelectDurationToPayFor: "Biko Họrọ Oge ị ga -akwụ ụgwọ",
    staffAccounts: "Akaụntụ Ndị Ọrụ",
    ecommerce: "Azụmahịa",
    pleaseSelectAPlan: "Biko họrọ atụmatụ",
    includeAddons: "Gụnye mgbakwunye",
    viewTransactions: "Lelee Mmekọrịta",
    customerHasNoCompletedTansactions:
      "Onye ahịa enwetabeghị mmecha mmecha ọ bụla",
    branch: "Alaka",
    enterNumberOfEcommerceBranches: "Tinye nọmba ngalaba Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Tinye ọnụ ọgụgụ ngalaba ngalaba azụmahịa e -commerce nke ị bu n'obi ịkwụ ụgwọ",
    ecommerceIntegration: "Njikọta Ecommerce",
    enterNumberOfBranches: "Tinye nọmba nke ngalaba",
    enterNumberOfAdditionalBranchesToPay:
      "Tinye nọmba ngalaba ndị ọzọ ị ga -akwụ ụgwọ maka ya",
    enterNumberOfStaffs: "Tinye nọmba ndị ọrụ",
    enterNumberOfStaffsToPayFor: "Tinye ọnụ ọgụgụ ndị ọrụ ị chọrọ ịkwụ ụgwọ",
    discountApplies: "Ego itinye",
    starsOnThe: "kpakpando na",
    offer: "enye",
    get: "Nweta",
    moreStarsToRedeem: "ọtụtụ kpakpando ị gbapụta",
    taxVat: "Ụtụ (VAT)",
    callCashierToCompletePayment: "Kpọọ Cashier iji kwụọ ụgwọ zuru oke",
    receipt: "Nnata",
    dear: "Ezigbo",
    thankYouForYourOrderFindGoods:
      "Daalụ maka ịtụ ahịa gị. Biko chọta ngwongwo ndị a dị ka ekwenyero.",
    shippingNote: "Mara Mbupu",
    enterShippingNote: "Tinye Mbupu Cheta",
    shippingAddress: "Adreesị Mbupu",
    enterShippingAddress: "Tinye adreesị Mbupu",
    wellDoneYouAreDueToRedeem: "Ị mere nke ọma! Ị ga -agbapụta",
    toGetYourRewardNextVisit: "iji nweta ụgwọ ọrụ gị na nleta gị ọzọ. Daalụ",
    pointsOnThe: "Ihe ndị dị na",
    morePointsToRedeem: "ọtụtụ ihe ị ga -agbapụta",
    showCode: "Gosi koodu",
    toGetYourRewardOnNextVisit: "iji nweta ụgwọ ọrụ gị na nleta gị ọzọ. Daalụ",
    earn: "Nweta",
    delivaryNote: "Ihe edeturu",
    draftSales: "Ahịa Draft",
    startDate: "Ụbọchị mmalite",
    endDate: "Ụbọchị ngwụcha",
    orders: "Iwu",
    checkout: "lelee",
    noProductItem: "Enweghị ngwaahịa ngwaahịa",
    selectProductImage: "Họrọ Onyonyo Ngwaahịa",
    selectCountry: "Họrọ obodo",
    selectRegion: "Họrọ Steeti/Mpaghara",
    printProductTag: "Mbipụta Ngwaahịa",
    transactionReference: "Ntughari azụmahịa",
    Cashier: "Cashier",
    Manager: "Onye njikwa",
    Owner: "Onye nwe ya",
    Admin: "Onye nlekọta",
    addPartners: "Tinye ndị mmekọ",
    addNewLoyaltyPartner: "Tinye onye mmekọ iguzosi ike n'ihe ọhụrụ",
    pleaseEnterCompanyName: "Biko tinye aha ụlọ ọrụ",
    companyName: "Aha Ụlọ Ọrụ",
    pleaseEnterCompanyRepName: "Biko tinye aha onye nnọchi anya ụlọ ọrụ",
    companyRepName: "Aha onye nnọchi anya ụlọ ọrụ",
    pleaseEnterCompanyRepEmail: "Biko tinye email nke onye nnọchi anya ụlọ ọrụ",
    companyRepEmail: "Email nke ụlọ ọrụ rep",
    pleaseEnterCompanyRepPhone:
      "Biko tinye nọmba ekwentị onye nnọchi anya ụlọ ọrụ",
    companyRepPhone: "Nọmba ekwentị nke onye nnọchi anya ụlọ ọrụ",
    addReward: "Tinye ụgwọ ọrụ",
    pleaseEnterRewardName: "Biko tinye aha ụgwọ ọrụ",
    rewardName: "Aha ụgwọ ọrụ",
    rewardQuantity: "Ọnụ ego ụgwọ ọrụ",
    rewardDescription: "Nkọwa ụgwọ ọrụ",
    rewardType: "Ụdị ụgwọ ọrụ",
    pleaseEnterRewardType: "Biko tinye ụdị ụgwọ ọrụ",
    pleaseEnterRewardQuantity: "Biko tinye ọnụọgụ ụgwọ ọrụ",
    pleaseEnterRewardDescription: "Biko tinye nkọwa ụgwọ ọrụ",
    fineDining: "Nri dị mma",
    luxuryFashion: "Ejiji okomoko",
    hotels: "Ụlọ oriri na ọṅụṅụ",
    travel: "Njem",
    foodAndBeverage: "Nri na ihe ọṅụṅụ",
    fashion: "Ejiji",
    health: "Ahụike",
    furniture: "Ngwongwo ụlọ",
    entertainment: "Ntụrụndụ",
    automobile: "Ụgbọ ala",
    education: "Agụmakwụkwọ",
    beautyAndSpa: "Mma na Spa",
    staycation: "Nnọọ",
    events: "Ihe omume",
    trips: "Njem",
    oilAndGas: "Mmanụ na gas",
    laundry: "Ịsa akwa",
    partnerCategory: "Otu onye mmekọ",
    freeItem: "Ihe efu",
  },
  Irish: {
    cashier: "airgeadóir",
    manager: "bainisteoir",
    owner: "úinéir",
    online: "ar líne",
    offline: "as líne",
    changePassword: "Athraigh do phasfhocal",
    currentPasswordMessage: "Iontráil do phasfhocal reatha",
    passwordMessage: "Iontráil do phasfhocal le do thoil",
    currentPassword: "Pasfhocal reatha",
    password: "pasfhocal",
    confirmPasswordMessage: "Deimhnigh do phasfhocal le do thoil!",
    confirmPassword: "Deimhnigh Pasfhocal",
    sendViaEmail: "Seol Trí R-phost",
    sendViaWhatsapp: "Seol Trí WhatsApp",
    downloadPdf: "Íoslódáil PDF",
    paid: "íoctha",
    unpaid: "gan phá",
    partial: "páirteach",
    closeInvoice: "Ar mhaith leat an Sonrasc a dhúnadh?",
    closeInvoiceConfirmation:
      "Ní féidir sonrasc a shábháil. Ar mhaith leat dúnadh?",
    yes: "sea",
    no: "níl",
    invoice: "Sonrasc",
    wasDeducted: "asbhaineadh",
    for: "le haghaidh",
    item: "Mír",
    addProduct: "Cuir Táirge leis",
    paymentReference: "Tagairt Íocaíochta",
    amountPaid: "Méid a d'íoc",
    discountAmount: "Méid Lascaine",
    amountDue: "Mhéid a bheidh dlite",
    amount: "Méid",
    dueDate: "Dáta dlite",
    paymentType: "Cineál Íocaíochta",
    card: "Cárta",
    cash: "Airgead",
    bankTransfer: "Aistriú bainc",
    paymentMessage: "Teachtaireacht Íocaíochta",
    description: "Cur síos",
    sendReceipt: "Seol Admháil",
    delete: "Scrios",
    save: "Sábháil",
    resend: "Athsheol",
    saveAndSend: "Sábháil",
    invoiceSaved: "Sonrasc sábháilte!",
    selectPaymentMethod: "Roghnaigh Modh Íocaíochta le do thoil!",
    selectCustomer: "Roghnaigh Custaiméir le do thoil!",
    cartEmptyError:
      "Ní féidir Liosta Cart a bheith folamh, an sonrasc a dhúnadh agus Mír a chur le cart!",
    subscriptionExpired:
      "Tá do shíntiús imithe in éag agus tá do chuntas teoranta anois. Cliceáil ar an gCnaipe thíos chun do chuntas a athnuachan",
    renew: "Athnuachan",
    holdOn: "Fán",
    customerBank: "Banc Custaiméirí",
    cancel: "Cealaigh",
    selectACustomer: "Roghnaigh Custaiméir",
    invoiceSuccessfulPdfError:
      "Cruthaíodh sonrasc go rathúil ach tá Giniúint PDF ag glacadh níos faide ná mar is gnách. Seiceáil siar go gairid, le do thoil.",
    downloadingInvoice: "Sonrasc a Íoslódáil",
    downloadingReceipt: "Admháil a Íoslódáil",
    whatsappReceiptError:
      "Tharla earráid agus an admháil á sheoladh trí WhatsApp, Bain triail eile as.",
    receiptToWhatsapp: "Admháil curtha ar aghaidh chuig WhatsApp",
    thankYouForPatronage: "Go raibh maith agat as do phátrúnacht",
    hereIsYourReceipt: "Seo d’admháil íocaíochta",
    errorSendingEmailReceipt:
      "Tharla earráid agus an admháil á sheoladh trí r-phost, bain triail eile as nó déan teagmháil le tacaíocht",
    receiptSentToEmail: "Tá admháil seolta chuig ríomhphost an chustaiméara",
    invoiceSentToEmail: "Tá sonrasc seolta chuig ríomhphost an chustaiméara",
    invoiceSuccessWhatsappError:
      "Cruthaíodh sonrasc go rathúil ach tharla earráid agus é á sheoladh chuig WhatsApp. Bain triail eile as ar liosta na sonrasc",
    invoiceSuccessfulEmailError:
      "Cruthaíodh sonrasc go rathúil ach tharla earráid agus é á sheoladh mar r-phost. Bain triail eile as liosta na sonrasc",
    invoiceSentToWhatsapp: "Cuireadh sonrasc ar aghaidh chuig WhatsApp",
    hello: "Dia dhuit",
    pleaseDownloadInvoice: "Íoslódáil sonrasc le do thoil",
    pleaseDownloadReceipt: "Íoslódáil an admháil le do thoil",
    from: "ó",
    email: "Ríomhphost",
    upgrade: "Uasghrádú",
    youAreOnFreePlan: "Tá tú ar Phlean In Aisce.",
    clickOn: "Cliceáil ar",
    yourPlanInFewSteps: " do shíntiús i roinnt céimeanna tapa.",
    to: "chun",
    yourSubscriptionHasExpired:
      "Tá do shíntiús imithe in éag agus tá do chuntas teoranta anois.",
    days: "laethanta",
    yourSubscriptionExpiresIn: "Rachaidh do shíntiús Loystar in éag i",
    createAcount: "Cruthaigh Cuntas",
    signIn: "Sínigh isteach",
    continue: "Leanúint ar aghaidh",
    enterOtp: "Iontráil PIN OTP",
    enterValidOtp: "Iontráil UAP bailí le do thoil",
    pin: "PIN",
    tokenSentToMail: "Tá comhartha seolta chuig do r-phost",
    passwordResetSuccessful: "D'éirigh le athshocrú pasfhocal",
    somethingWentWrong: "Chuaigh rud eigin mícheart!",
    resetPassword: "Athshocraigh Pasfhocal",
    iHaveResetCode: "Tá cód athshocraithe pasfhocal agam",
    pleaseEnterEmail: "Iontráil do r-phost le do thoil",
    aTokenWillBeSentToEmail: "Chuirfí comhartha chuig do r-phost",
    enterEmail: "Iontráil do r-phost",
    sendinYourToken: "Ag seoladh do chomhartha ...",
    makeSureItMatchesPassword: "Déan cinnte go n-oireann sé do phasfhocal nua",
    pleaseChooseNewPassword: "Roghnaigh pasfhocal nua le do thoil",
    chooseNewPassword: "Roghnaigh pasfhocal nua",
    enterNewPassword: "Iontráil do phasfhocal nua chun a dhearbhú",
    enterTokenSent: "Cuir isteach an comhartha a seoladh do phost",
    resetToken: "Athshocraigh Taisce",
    resettingPassword: "Do phasfhocal a athshocrú ...",
    signUp: "Clarú",
    adminSignInWithEmail:
      " Síníonn Riarachán isteach le ríomhphost agus síníonn an Fhoireann an t-ainm úsáideora isteach.",
    pleaseEnterEmailOrUsername: "Iontráil do r-phost nó ainm úsáideora",
    emailOrUsername: "Ríomhphost nó Ainm Úsáideora",
    pleaseEnterPassword: "Iontráil pasfhocal le do thoil",
    createAnAccount: "Cruthaigh Cuntas",
    forgotPassword: "Dearmad ar pasfhocal?",
    enterPhoneNumber: "Iontráil Uimhir Fón",
    personalData: "Sonraí Pearsanta",
    validateConfirmationCOde: "Cód Deimhnithe a bhailíochtú",
    pleaseEnterFirstName: "Iontráil do chéad ainm le do thoil",
    pleaseEnterPhoneNumber: "Iontráil d’uimhir theileafóin le do thoil",
    pleaseEnterLastName: "Iontráil d’ainm deireanach le do thoil",
    pleaseEnterBusinessName: "Iontráil d’ainm gnó le do thoil",
    firstName: "Ainm",
    lastName: "Ainm Deireanach",
    businessName: "Ainm Gnó",
    previous: "Roimhe seo",
    next: "Ar Aghaidh",
    pleaseSelectBusinessCategory: "Roghnaigh do chatagóir ghnó le do thoil",
    pleaseEnterValidEmail: "Iontráil r-phost bailí",
    pleaseEnterPostCode: "Iontráil cód poist le do thoil",
    postCode: "Cód Poist",
    phoneNumberInUse: "Tá an uimhir theileafóin seo in úsáid cheana féin!",
    emailInUse: "Tá an ríomhphost seo in úsáid cheana féin!",
    foodAndDrinks: "Bia agus deochanna",
    salonAndBeauty: "Salon agus Áilleacht",
    fashionAndAccessories: "Faisean agus Gabhálais",
    gymAndFitness: "Giomnáisiam agus Aclaíocht",
    travelAndHotel: "Taisteal agus Óstán",
    homeAndGifts: "Baile agus Bronntanais",
    washingAndCleaning: "Níochán agus Glanadh",
    gadgetsAndElectronics: "Giuirléidí agus Leictreonaic",
    groceries: "Earraí Grósaera",
    others: "Daoine eile",
    submit: "Cuir isteach",
    accountCreatedSuccessful: "Cruthaíodh do chuntas go rathúil.",
    pleaseEnterAddress: "Iontráil Do sheoladh le do thoil",
    addressLine1: "Seoladh Líne 1",
    addressLine2: "Líne Seoladh 2",
    choosePassword: "Roghnaigh Pasfhocal",
    passwordMustBe6Characters:
      "Caithfidh 6 charachtar ar a laghad a bheith sa phasfhocal.",
    howDidYouHearLoystar: "Conas a chuala tú faoi Loystar?",
    referralCode: "Cód atreoraithe",
    byClickingTheButton:
      " Trí chliceáil ar an gcnaipe thíos, aontaíonn tú leis an",
    termsAndSevice: "Téarmaí",
    wereCreatingAccount: "Táimid ag cruthú do chuntas",
    proceed: "Lean ar aghaidh",
    verificationCodeMustBe6: "Caithfidh 6 dhigit a bheith sa Chód Fíoraithe",
    pleaseEnter6DigitCode: "Iontráil cód 6 dhigit le do thoil",
    enterVerificationCode: "Iontráil Cód Fíoraithe",
    resendToken: "Resend Token",
    verify: "Fíoraigh",
    transactions: "Idirbhearta",
    todaySales: "Díolacháin an lae inniu",
    salesHistory: "Stair Díolacháin",
    supplyHistory: "Stair Soláthair",
    new: "Nua",
    invoices: "Sonraisc",
    disbursements: "Eisíocaíochtaí",
    offlineSales: "Díolacháin As Líne",
    products: "Táirgí",
    customers: "Custaiméirí",
    multiLevelLoyalty: "Dílseacht Il-Leibhéal",
    loyaltyPrograms: "Cláir Dílseachta",
    members: "Baill",
    appStore: "Siopa app",
    orderMenu: "Roghchlár Ordú",
    settings: "Socruithe",
    staffAndBranches: "Foirne agus Brainsí",
    myAccount: "Mo chuntas",
    switchToSellMode: "Athraigh go Mód Díol",
    signOut: "Logáil Amach",
    getFreeSubscription: "Faigh Síntiús In Aisce",
    onlyNumbersAllowed: "Ní cheadaítear ach uimhreacha",
    yourAccountMustBe10Digits:
      "caithfidh d’uimhir chuntais a bheith ina huimhir 10 ndigit",
    yourBvnMustBe11: "caithfidh uimhir 11 dhigit a bheith i do BVN",
    pleaseSelectBank: "Roghnaigh do bhanc le do thoil",
    selectYourBank: "Roghnaigh do Bhanc",
    enterBankAccountNumber: "Iontráil Uimhir Chuntais Bhainc",
    enterBvnNumber: "Cuir isteach do BVN",
    connectBankAccount: "Ceangail Cuntas Bainc",
    passwordResetSuccessfulAndSignOut:
      "Rinneadh do phasfhocal a athshocrú go rathúil. Cliceáil ar an gcnaipe Sínigh Amach thíos chun síniú isteach arís",
    enterCurrentPassword: "Iontráil pasfhocal reatha",
    pleaseEnterCurrentPassword: "Iontráil pasfhocal reatha",
    phoneNumber: "Uimhir teileafón",
    sex: "Gnéas",
    dateOfBirth: "Dáta breithe",
    state: "Luaigh",
    country: "Tír",
    loyaltyId: "Aitheantas Dílseachta",
    createdAt: "Cruthaithe Ag",
    noOfTransactionsMade: "Líon na n-idirbheart a rinneadh",
    yourDownloadWillStart: "Tosóidh d’íoslódáil i nóiméad",
    exportCustomers: "Custaiméirí Easpórtála",
    youhaveSuccessfullyToppedUp: "D'éirigh leat do Aonaid SMS a mhéadú.",
    youNowHave: "Tá agat anois",
    smsUnits: "Aonaid SMS",
    enterNumberOfSmsUnits:
      "Iontráil Líon na nAonad SMS a Bheartaíonn tú a Cheannach",
    pleaseEnterANumericValue: "Iontráil luach uimhriúil le do thoil",
    pay: "Pá",
    accountEditedSuccessfully: "Cuireadh do chuntas in eagar go rathúil.",
    youAeCurrentlyOn: "Tá tú ar aghaidh faoi láthair",
    plan: "Plean",
    userData: "Sonraí Úsáideora",
    businessData: "SONRAÍ GNÓ",
    businessCategory: "Catergory Gnó",
    pleaseSelectCurrency: "Roghnaigh d’airgeadra le do thoil",
    selectYourCurrency: "Roghnaigh d’airgeadra",
    purchaseMoreSmsUnits:
      "Ceannaigh níos mó Aonad SMS ag úsáid na foirme thíos",
    youHave: "Tá _ agat",
    atLeast4SmsUnitsRrequired:
      "Teastaíonn 4 aonad sms ar a laghad chun iad a fhíorú, cuir le do thoil é!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Déan do chuntas bainc a fhíorú le do thoil chun na riachtanais ‘Know Your Customer’ (KYC) a chomhlíonadh. Ligfidh sé seo duit íocaíochtaí a bhailiú trí USSD nó aistriú láithreach, orduithe a fháil ó chustaiméirí agus socrú idirbheart a phróiseáil. Infheidhme maidir le ceannaithe ón Nigéir amháin. Brúigh an cnaipe thíos le do thoil chun tosú.",
    reConnectBankAccount: "Cuntas Bainc a athcheangal",
    accountName: "Ainm chuntais",
    accountNumber: "Uimhir chuntais",
    bankName: "Ainm Bainc",
    verified: "Fíoraithe",
    accountDetails: "Sonraí Cuntais",
    kycBankAccount: "KYC",
    createTier: "Cruthaigh Sraith",
    fileUploadSuccessful: "uaslódáil comhad go rathúil",
    fileUploadFailed: "theip ar uaslódáil comhad",
    createLoyaltyProgram: "Cruthaigh Clár Dílseachta",
    createLoyalty: "Cruthaigh Dílseacht",
    name: "Ainm",
    loyaltyArtwork: "Saothar Ealaíne Dílseachta",
    clickToUpload: "Cliceáil chun uaslódáil",
    amountToPointsRatio: "Méid an Chóimheas Pointí",
    points: "Pointí",
    recommendedAmountToPointRatio:
      "Molta: ₦ 1 go 1point. i.e as gach 1 Naira a chaitear, faigheann do chustaiméirí 1 phointe",
    pleaseTypeIn: "Clóscríobh isteach le do thoil",
    toDeleteLoyalty: "an dílseacht seo a scriosadh",
    deleteLoyalty: "Scrios Dílseacht",
    toConfirm: "a dhearbhú",
    edit: "Cuir in Eagar",
    pointsAwardedSuccessfully: "Pointí a Dámhadh go rathúil.",
    enterPointValueToAward:
      "Iontráil Luach Pointe Ba mhaith leat Custaiméirí a Dhámhachtain",
    award: "Gradam",
    awardPointValuesToCustomer: "Luach Pointe Dámhachtana don Chustaiméir",
    enrollMembersToLoyalty: "Baill a Chlárú ar Dhílseacht",
    awardPoints: "Pointí Dámhachtana",
    enroll: "Cláraigh",
    home: "Baile",
    loyalty: "Dílseacht",
    enrollCustomers: "Custaiméirí a Chlárú",
    selected: "Roghnaithe",
    customer: "Custaiméir",
    loyaltyActivationSuccessful:
      "Tá ag éirí go maith le Gníomhachtú Dílseachta.",
    loyaltyDeactivationSuccessful: "Éiríonn le Díghníomhachtú Dílseachta.",
    viewTier: "Féach Sraith",
    deactivate: "Díghníomhachtú",
    activate: "Gníomhachtaigh",
    actions: "Caingne",
    nameOfLoyalty: "Ainm na Dílseachta",
    loyaltyStatus: "Stádas Dílseachta Stádas Dílseachta",
    numberOfTiers: "Líon na Sraitheanna",
    createdOn: "Cruthaithe Ar",
    createATier: "Cruthaigh Sraith",
    stopCreatingTierConfirmation:
      "Ar mhaith leat stop a chur le sraith a chruthú?",
    stopEditingTierConfirmation:
      "Ar mhaith leat stop a chur leis an tsraith seo a chur in eagar?",
    nameOfTier: "Ainm na Sraithe",
    minimumSpendingTarget: "Sprioc Caiteachais Íosta",
    maximumSpendingTarget: "Sprioc Caiteachais Uasta",
    minimumSpendingTargetRequired: "éilítear an sprioc caiteachais íosta",
    maximumSpendingTargetRequired: "éilítear an sprioc caiteachais uasta",
    rewardSponsor: "Urraitheoir Luaíochta",
    pleaseChooseARewardSponsor: "Roghnaigh urraitheoir luaíochta le do thoil",
    self: "Féin",
    partner: "Comhpháirtí",
    rewardPartner: "Comhpháirtí luaíochta",
    pleaseSelectRewardPartner: "Roghnaigh do pháirtí luaíochta le do thoil",
    rewards: "Duaiseanna",
    pleaseSelectAReward: "Roghnaigh luaíocht le do thoil",
    instructionsOnRedeemingReward:
      "Treoracha maidir le Conas ba Chóir do Chustaiméirí Luaíocht a Fháil",
    pleaseFillInThisField: "Líon isteach an Réimse seo le do thoil!",
    toDeleteThisTier: " chun an tsraith seo a scriosadh",
    deleteTier: "Scrios Sraith",
    viewMembers: "Féach ar Bhaill",
    membersEnrolled: "Comhaltaí Cláraithe",
    instruction: "Treoir",
    membersIn: "Baill i",
    availableTiersInLoyalty: "Sraith (í) atá ar fáil sa Chlár Dílseachta",
    tiers: "Sraitheanna",
    totalTier: "IOMLÁN TIER",
    availableLoyaltyProgramme: "An Clár Dílseachta atá ar Fáil",
    totalLoyalties: "IOMLÁN IASACHTAÍ",
    memberDetails: "Sonraí Comhaltaí",
    nameOfCustomer: "Ainm an Chustaiméara",
    address: "Seoladh",
    allEnrolledMembers: "Gach Ball Cláraithe",
    thisIsToWishYouHappyBirthday:
      "Déantar é seo chun lá breithe an-sásta agus saol rathúil a ghuí ort. Go raibh maith agat as gach a bhfuil tú a Loyster. Ceiliúradh Sona!",
    inputAnOfferPlease: "Ionchur Tairiscint le do thoil",
    pleaseSelectTheInsertPoint:
      "Roghnaigh an pointe isteach sa teachtaireacht le do thoil agus cliceáil arís",
    birthdayOfferAndMessage: "Tairiscint agus Teachtaireacht Breithlá",
    birthdayOffer: "Tairiscint Breithlá",
    birthdayMessage: "Teachtaireacht Breithlá",
    noOfferFound: "Ní bhfuarthas tairiscint",
    settingABirthdayOffer:
      "Trí thairiscint lá breithe a shocrú ligtear do chustaiméirí an tairiscint seo a fháil trí SMS ar a lá breithe",
    createOffer: "Cruthaigh Tairiscint",
    whatIsTheOffer: "Cad é an tairiscint?",
    editMessage: "Cuir Teachtaireacht in Eagar",
    insert: "Ionsáigh",
    theNameOfCustomerInserted: "Cuirfear ainm an chustaiméara isteach anseo",
    theBirtdayOfferInserted: "Cuirfear an tairiscint lá breithe isteach anseo",
    youSuccessfullyAddedNewBranch: "D’éirigh leat brainse nua a chur leis!",
    addNewBranch: "Cuir Brainse Nua leis",
    addBranch: "Cuir Brainse leis",
    additionalBranchWillIncur: "Thabhódh Brainse Breise",
    perBranch: "in aghaidh an bhrainse",
    ecommerceBranchCosts: "Costais an Bhrainse Ríomhthráchtála",
    pleaseEnterBranchName: "Iontráil ainm an bhrainse le do thoil",
    pleaseEnterBranchAddress1: "Iontráil líne seoladh 1 an bhrainse",
    enterBranchAddress1: "Iontráil líne seoltaí 1 an bhrainse",
    enterBranchAddress2: "Iontráil líne seoltaí 1 an bhrainse",
    pleaseEnterBranchAddress2: "Iontráil líne seoltaí 2 an bhrainse",
    enterBranchName: "Iontráil ainm an bhrainse",
    successfullyAddedStaff: "D'éirigh leat foireann nua a chur leis!",
    addNewStaff: "Cuir Foireann Nua leis",
    addStaff: "Cuir Foireann leis",
    additionalStaffWillIncur: "Thabhódh Foireann Bhreise",
    perStaff: "in aghaidh na foirne.",
    pleaseEnterStaffEmail: "Iontráil r-phost na foirne le do thoil",
    pleaseEnterStaffUsername: "Iontráil ainm úsáideora na foirne",
    pleaseEnterStaffPassword: "Iontráil pasfhocal na foirne le do thoil",
    pleaseSelectStaffRole: "Roghnaigh ról na foirne le do thoil",
    selectStaffRole: "Roghnaigh ról na foirne",
    enterStaffEmail: "Iontráil r-phost na foirne",
    enterStaffUsername: "Iontráil ainm úsáideora na foirne",
    enterStaffPassword: "Iontráil pasfhocal na foirne",
    spacesNotAllowedInUsername: "spásanna nach gceadaítear in ainm úsáideora",
    admin: "Riarachán",
    pleaseSelectBusinessToAttachStaff:
      "Roghnaigh gnó le do thoil chun baill foirne a cheangal",
    searchForBranchToAttachStaff:
      "Cuardaigh brainse chun an fhoireann a cheangal",
    username: "Ainm úsáideora",
    role: "Ról",
    areYouSureToDeleteThis: "An bhfuil tú cinnte é seo a scriosadh",
    branches: "Brainsí",
    upgradeYourPlan: "Uasghrádú ar do Phlean.",
    add: "ADD",
    addNew: "Cuir Nua leis",
    customerWithEmailAlreadyExists:
      "Tá custaiméir le uimhir ríomhphoist / teileafóin ann cheana féin!",
    successfullyAddedNewCustomer: "D'éirigh leat custaiméir nua a chur leis!",
    addCustomer: "Cuir Custaiméir leis",
    pleaseEnterCustomerFirstName:
      "Iontráil céad ainm an chustaiméara le do thoil",
    pleaseEnterCustomerLastName: "Iontráil ainm deireanach an chustaiméara",
    pleaseEnterCustomerPhoneNumber:
      "Iontráil uimhir theileafóin an chustaiméara",
    pleaseEnterCustomerEmail: "Iontráil r-phost an chustaiméara",
    pleaseEnterCustomerAddressLine: "Iontráil Líne Seoltaí an chustaiméara",
    pleaseEnterCustomerOtherAddress: "Iontráil Seoladh eile an chustaiméara",
    pleaseSelectCustomerGender: "Roghnaigh inscne an chustaiméara le do thoil",
    gender: "Inscne",
    male: "Fireann",
    female: "Mná",
    bank: "Banc",
    selectBank: "Roghnaigh Banc",
    stateOrRegionOrProvince: "Stát / Réigiún / Cúige",
    customerNotes: "Nótaí Custaiméirí",
    sendSms: "Seol SMS",
    editCustomer: "Cuir Custaiméir in Eagar",
    redeemReward: "Luaíocht Fuascailte",
    issueLoyaltyCard: "Cárta Dílseachta a eisiúint",
    deleteCustomer: "Scrios Custaiméir",
    youveSuccessfullyAssignedLoyaltyMembership:
      "D'éirigh leat Aitheantas Ballraíochta Dílseachta a shannadh",
    noMembershipIdAvailable:
      "Níl IDanna ballraíochta ar fáil. Téigh i dteagmháil linn ar hello@loystar.co",
    sendEmail: "Seol Ríomhphost",
    membershipPoints: "Pointí Ballraíochta",
    customerDetails: "Sonraí do Chustaiméirí",
    close: "Dún",
    loyaltyBalance: "Iarmhéid Dílseachta",
    pointsBalance: "Cothromaíocht Pointí",
    starBalance: "Cothromaíocht Réalta",
    requiredPoints: "Pointí Riachtanacha",
    requiredStars: "Réaltaí Riachtanacha",
    reddemCode: "Cód Fuascailte",
    toDeleteThisCustomer: "chun an custaiméir seo a scriosadh",
    customerHasBeenDeletedSuccessfully: "Scriosadh an custaiméir go rathúil!",
    customerWithPhoneAlreadyExists:
      "Tá custaiméir le huimhir teileafóin ann cheana féin",
    customerWasSuccessfullyEdited: "Cuireadh an custaiméir in eagar go rathúil",
    anErrorOccured: "Tharla earráid",
    phoneNumberIncludeCountry: "Uimhir Fón (Cuir Cód na Tíre san áireamh)",
    yourFileQueuedForUpload:
      "Scuaineíodh do chomhad lena uaslódáil. Déan an leathanach a athnuachan tar éis cúpla soicind le do thoil.",
    thereWasAnErrorPerformingOperation:
      "Tharla earráid agus an oibríocht á déanamh!",
    pleaseSelectFile: "Roghnaigh Comhad le do thoil!",
    oopsOnlyCsvFilesAllowed:
      "Úps! Ní cheadaítear ach comhaid CSV. Uaslódáil comhad .csv le do thoil.",
    fileShouldBeSmallerThan5Mb:
      "Ba chóir go mbeadh an comhad níos lú ná 5MB. Má tá comhad níos mó agat, seol ríomhphost chuig support@loystar.co. Go raibh maith agat",
    customerFirstNameIsRequired:
      "teastaíonn céadainm an chustaiméara as a chéile",
    customerPhoneNumberIsRequired:
      "teastaíonn uimhir theileafóin an chustaiméara i ndiaidh a chéile",
    importCustomers: "Custaiméirí Iompórtála",
    upload: "Uaslódáil",
    clickIconToDownloadCsv:
      "Cliceáil ar an deilbhín seo chun teimpléad an chomhaid CSV a íoslódáil.",
    getGoogleSheetVersion: "Faigh an leagan bileog Google",
    clickOrDragFileToUpload:
      "Cliceáil nó tarraing comhad chuig an réimse seo chun é a uaslódáil",
    missingOrInvalidColumnHeader:
      "Ceanntásc colún ar iarraidh nó neamhbhailí. Lean formáid an teimpléid le do thoil. Go raibh maith agat.",
    youHaveImported: "Tá Allmhairiú déanta agat",
    youSuccessfullyRedeemedReward: "D’éirigh leat do luaíocht a fhuascailt!",
    sixDigitCode: "Cód sé dhigit",
    pleaseEnterCustomerRewardCode:
      "Iontráil cód luaíochta an chustaiméara le do thoil",
    enterRewardCode: "Iontráil cód luaíochta. (Tá an cód luaíochta cás-íogair)",
    selectLoyaltyProgram: "Roghnaigh clár dílseachta",
    stamps: "Stampaí",
    smsUnitsLow: "Aonaid SMS Íseal",
    pleaseTopUpSmsUnits: "Aonaid SMS Breisithe le do thoil",
    smsSuccessfullySentForDelivery: "D'éirigh le SMS a sheoladh le seachadadh!",
    sendSmsTo: "Seol SMS chuig",
    allCustomers: "Gach Custaiméir",
    unitsAvailable: "Aonaid Ar Fáil",
    pleaseTypeInTheMessage: "Clóscríobh an teachtaireacht le do thoil",
    message: "Teachtaireacht",
    charactersRemaining: "carachtair fágtha",
    avoidUseOfSpecialCharacters:
      "Seachain Carachtair Speisialta agus Emojis a úsáid chun Aonaid SMS a chaomhnú.",
    note: "Nóta",
    errorFetchingCustomersMultilevelDetail:
      "Earráid Sonraí Ilchuspóireacha Custaiméirí a Chomhdú",
    search: "Cuardaigh",
    reset: "Athshocraigh",
    importCustomer: "Custaiméir Iompórtála",
    addNewCustomer: "Cuir Custaiméir Nua leis",
    sendSmsBroadcast: "Seol Craoladh SMS",
    totalCustomers: "IOMLÁN DO CHUSTAIMÉIRÍ",
    disbursementDetails: "Sonraí Eisíocaíochta",
    paidBy: "Íoctha Le",
    disbursed: "Íoctha",
    bankAccountName: "Ainm an Chuntais Bhainc",
    bankAccountNumber: "Uimhir Chuntais Bhainc",
    transferInitiated: "Aistriú Tionscanta",
    transferCompleted: "Aistriú Críochnaithe",
    pleaseEnterAValid: "Iontráil bailí le do thoil",
    begin: "tosú",
    end: "deireadh",
    date: "dáta",
    paymentDate: "Dáta Íocaíochta",
    selectDisbursementDuration: "Roghnaigh Fad Eisíocaíochta",
    totalSettled: "Iomlán Socraithe",
    totalUnsettled: "Iomlán Neamhshonraithe",
    toDeleteThisSale: "chun an díol seo a scriosadh",
    draftSaleDeletedSuccessfully: "Scriosadh Dréacht-Díolachán go rathúil!",
    deleteSale: "Scrios Díol",
    pleaseTypeInYourTotal:
      "Clóscríobh isteach d’uimhir iomlán chun scriosadh a dhearbhú",
    billDetails: "Sonraí an Bhille",
    printBill: "Bille Priontála",
    servedBy: "Seirbheáil ag",
    total: "Iomlán",
    createdDate: "Dáta Cruthaithe",
    createdTime: "Am Cruthaithe",
    status: "Stádas",
    loginSuccessful: "D'éirigh le Logáil Isteach",
    pleaseWaitWhileWeConnectAccount:
      "Fan le do thoil agus muid ag nascadh do chuntas",
    connectionFailedTryAgain: "Theip ar an nasc. Arís, le d'thoil.",
    connectionSuccessful: "D'éirigh leis an nasc",
    viewDetails: "Féach Sonraí",
    enable: "Cumasaigh",
    free: "Saor",
    cost: "Costas",
    visitWebsite: "Tabhair cuairt ar Suíomh Gréasáin",
    pleaseUpgradeYourPlanToPro:
      "Uasghrádú le do thoil ar do phlean le Pro Plus chun an aip seo a chumasú",
    connectYourBankAccount:
      "Ceangail do Chuntas Bainc le go mbeidh tú in ann íocaíochtaí a fháil.",
    disable: "Díchumasaithe",
    enableApp: "Cumasaigh Aip",
    addNewProductToInvoice: "Cuir Táirge Nua le Sonrasc",
    toDeleteThisInvoice: "chun an Sonrasc seo a scriosadh",
    invoiceDeletedSuccessfully: "Scriosadh sonrasc go rathúil!",
    deleteInvoice: "Scrios Sonrasc",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "D'éirigh leat teachtaireacht íocaíochta Sonraisc a chur leis!",
    pleaseEnterPaymentInstructions: "Iontráil treoracha íocaíochta le do thoil",
    invoiceId: "Aitheantas Sonraisc",
    paidDate: "Dáta Íoctha",
    reference: "Tagairt",
    productAdded: "Táirge curtha leis",
    productOutOfStock: "Táirge as stoc. Déan athstocáil le do thoil.",
    totalInvoices: "IOMLÁN IOMLÁN",
    totalPaidInvoices: "IOMLÁN ÍOC IOMLÁN",
    totalUnpaidInvoices: "IOMLÁN INFHEISTÍOCHTAÍ UNPAID",
    loyaltyProgramDeleted: "Scriosadh an Clár Dílseachta",
    thisLoyaltyProgramDeletedSuccessfully:
      "Scriosadh an clár dílseachta seo go rathúil",
    thisLoyaltyProgramEditedSuccessfully:
      "Tá an clár dílseachta seo curtha in eagar go rathúil",
    loyaltyProgramAddedSuccessfully:
      "Cuireadh leis an gclár dílseachta go rathúil",
    loyaltyProgramEdited: "An Clár Dílseachta Curtha in eagar",
    loyaltyProgramAdded: "Cuireadh leis an gClár Dílseachta",
    loyaltyDetails: "Sonraí Dílseachta",
    doYouWantToCloseDialog: "Ar mhaith leat an dialóg seo a dhúnadh?",
    pleaseEnterLoyaltyName: "Iontráil ainm do dhílseachta",
    programType: "Cineál Cláir",
    pleaseSelectProgramType: "Roghnaigh cineál cláir le do thoil",
    simplePoints: "Pointí Simplí",
    stampsProgram: "Clár Stampaí",
    threshold: "Tairseach",
    pleaseEnterLoyaltyThreshold: "Iontráil tairseach na dílseachta le do thoil",
    reward: "Duais",
    pleaseEnterLoyaltyReward:
      "Cuir isteach luach saothair na dílseachta le do thoil",
    totalUserPoints: "Pointí Úsáideora Iomlán",
    totalUserStamps: "Stampaí Úsáideora Iomlán",
    spendingTarget: "Sprioc caiteachais",
    spendingTargetHint1:
      "Is é an sprioc caiteachais an méid a chaithfidh custaiméir a chaitheamh chun an luach saothair a thuilleamh. 1 luach airgeadra = 1 phointe.",
    spendingTargetHint2:
      "Is é sprioc stampaí an líon stampaí a chaithfidh custaiméir a bhailiú chun an luach saothair a thuilleamh. m.sh. 5",
    addNewLoyaltyProgram: "Cuir Clár Dílseachta Nua leis",
    addLoyaltyProgram: "Cuir Clár Dílseachta leis",
    loyaltyProgramType: "Cineál Cláir Dílseachta",
    pleaseSelectLoyaltyProgramType:
      "Roghnaigh cineál cláir dílseachta le do thoil",
    nameOfProgram: "Ainm an chláir",
    pleaseEnterProgramName: "Iontráil ainm an chláir",
    whatIsTheReward: "Cad é an luach saothair?",
    egNextPurchaseIsFree: "E.g. Tá an chéad cheannachán eile saor in aisce",
    customerName: "Ainm an Chustaiméara",
    guestCustomer: "Aoi-Chustaiméir",
    orderConfirmedSuccessfully: "Ordú daingnithe go rathúil",
    orderCancelledSuccessfully: "Ordú curtha ar ceal go rathúil",
    confirmOrder: "Deimhnigh ordú",
    cancelOrder: "Cealaigh ordú",
    allOrders: "Gach Ordú",
    totalOrders: "ORDUITHE IOMLÁN",
    doYouWantToAcceptOrder: "Ar mhaith leat glacadh leis an ordú seo?",
    doYouWantToCancelOrder: "Ar mhaith leat an t-ordú seo a chealú?",
    orderDetails: "Sonraí Ordaithe",
    orderCreatedAt: "Ordú cruthaithe ag",
    supplier: "Soláthraí",
    productName: "Ainm Táirge",
    quantity: "Cainníocht",
    unitPrice: "Praghas aonaid",
    receivedBy: "Faighte ag",
    reportFrom: "Tuarascáil ó",
    totalSupplies: "Soláthairtí Iomlán",
    allRightsReserved: "Gach ceart ar cosaint",
    toDeleteThisTransaction: "chun an Idirbheart seo a scriosadh",
    transactionDeletedSuccessfully:
      "Scriosadh an t-idirbheart go rathúil. Tá stoc curtha ar ais chuig an bhfardal.",
    deleteTransaction: "Scrios Idirbheart",
    transactionDetails: "Sonraí Idirbheart",
    printReceipt: "Admháil Priontála",
    channel: "Cainéal",
    discount: "Lascaine",
    profit: "Brabús",
    discountedSales: "Díolacháin Lascaine",
    errorFetchingRecord: "Earráid Taifead Fetching",
    exportTransactions: "Idirbhearta Easpórtála",
    errorFetchingSalesRecord:
      "Earráid ag fáil Taifead díolacháin le haghaidh Easpórtála.",
    totalSellingPrice: "Praghas Díol Iomlán",
    totalCostPrice: "Praghas Iomlán an Chostas",
    appliedDiscount: "Lascaine Feidhmeach",
    noOfItems: "Líon Míreanna",
    sales: "Díolacháin",
    export: "Easpórtáil",
    totalProfit: "Brabús Iomlán",
    totalBalanceInPeriod: "Iarmhéid Iomlán sa Tréimhse",
    allTimeSales: "Gach Díolachán Ama",
    records: "Taifid",
    todaysSales: "Díolacháin an lae inniu",
    transaction: "idirbheart",
    youHaveExceededTotalNumberOfProducts:
      "Sháraigh tú líon iomlán na dtáirgí a cheadaítear ar do phlean. Uasghrádú ar do phlean chun taitneamh a bhaint as teorainn níos airde.",
    youNeedToHaveLoyaltyProgram:
      "Ní mór duit Clár Dílseachta a bheith agat chun an ghné seo a úsáid!",
    price: "Praghas",
    category: "Catagóir",
    stockTracking: "Rianú Stoic",
    stockCount: "Líon na Stoc",
    taxed: "Gearrtar cáin",
    originalPrice: "Praghas Bunaidh",
    costPrice: "Praghas Costas",
    unit: "Aonad",
    productImage: "Íomhá Táirgí",
    taxRate: "Ráta Cánach",
    taxType: "Cineál Cánach",
    trackInventory: "Fardal Rianaithe",
    variants: "Leaganacha",
    hasVariants: "An bhfuil Athruithe",
    importProduct: "Táirge Iompórtála",
    exportProducts: "Táirgí Easpórtála",
    addNewProduct: "Cuir Táirge Nua leis",
    viewCategory: "Féach ar an gCatagóir",
    viewSuppliers: "Féach ar Sholáthraithe",
    receiveInventory: "Fardal a Fháil",
    printAllProductsTag: "Clib Gach Táirgí",
    deleteAll: "Scrios Gach",
    totalProducts: "TÁIRGÍ IOMLÁN",
    youveSuccessfullyAddedANewCategory:
      "D'éirigh leat catagóir nua a chur leis",
    addNewCategory: "Cuir Catagóir Nua leis",
    addCategory: "Cuir Catagóir leis",
    categoryName: "Ainm Catagóir",
    pleaseEnterCategoryName: "Iontráil ainm na catagóire",
    stampsTarget: "Sprioc Stampaí",
    sendInventory: "Seol Fardal",
    productDetails: "Sonraí Táirgí",
    youveSuccessfullyEditedThisCategory:
      "D'éirigh leat an chatagóir seo a chur in eagar",
    update: "Nuashonrú",
    categoryList: "Liosta Catagóir",
    categories: "Catagóirí",
    enterQuantityToUpdate: "Iontráil cainníocht le nuashonrú",
    inventorySentSuccessfully: "Cuireadh an fardal go rathúil!",
    updateInventory: "Fardal Nuashonraithe",
    currentQuantity: "Cainníocht Reatha",
    pleaseEnterQuantityToAdd:
      "Iontráil an chainníocht is mian leat a chur leis",
    pleaseSelectABranch: "Roghnaigh Brainse le do thoil",
    searchForBranch: "Cuardaigh brainse",
    youCantSendMoreThanStock:
      "Ní féidir leat níos mó a sheoladh ná atá agat i stoc",
    send: "Seol",
    pleaseEnterQuantityToSend:
      "Iontráil an chainníocht is mian leat a sheoladh",
    productNameIsRequiredOnRow: "teastaíonn ainm an táirge as a chéile",
    productCategoryIsRequiredOnRow:
      "teastaíonn catagóir táirge i ndiaidh a chéile",
    productPriceIsRequiredOnRow: "teastaíonn praghas an táirge as a chéile",
    productUnitIsRequiredOnRow: "teastaíonn aonad táirge as a chéile",
    productQuantityIsRequiredOnRow:
      "teastaíonn cainníocht an táirge as a chéile",
    productVariantsRequireTracking:
      "ní mór rianú a dhéanamh ar leaganacha táirgí!",
    pleaseAddVariantClickButton:
      "Cuir malairt le do thoil trí chliceáil ar an gcnaipe add!",
    totalVariantsMoreThanSelectedQuantity:
      "Tá Athruithe Iomlán níos mó ná cainníocht roghnaithe táirgí, laghdaíonn Pls cainníocht athraitheacha",
    productEditedSuccessfully: "Tá an táirge curtha in eagar go rathúil!",
    fileTooLargeLessThan4Mb:
      "Tá méid an chomhaid ró-mhór! Ba chóir go mbeadh méid an chomhaid níos lú ná 4MB.",
    suchVariantAlreadyExist: "Tá a leithéid de leagan ann cheana féin",
    addVariants: "Cuir Leaganacha leis",
    editProduct: "Cuir Táirge in Eagar",
    pleaseEnterProductName: "Iontráil Ainm an Táirge",
    pleaseEnterProductPrice: "Iontráil Praghas Táirgí le do thoil",
    pleaseEnterProductOriginalPrice: "Iontráil Praghas Bunaidh an Táirge",
    productDescription: "Cur síos ar an Táirge",
    selectProductCategory: "Roghnaigh Catagóir Táirgí",
    pleaseSelectProductCategory: "Roghnaigh catagóir an táirge le do thoil",
    productCostPrice: "Praghas Costas Táirgí",
    productSellingPrice: "Praghas Díol Táirgí",
    productOriginalPrice: "Praghas Bunaidh an Táirge",
    maxFileSizeAllowedIs4Mb: "Max. Is é 4mb an méid comhaid a cheadaítear",
    productsWithPicturesArePublished:
      "Foilsítear táirgí le pictiúir ar Discover chun orduithe a fháil",
    shouldThisProductBeTracked: "Ar chóir an táirge seo a rianú?",
    pleaseSelectStockUnit: "Roghnaigh aonad stoic le do thoil",
    stockUnit: "Aonad Stoic",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "FÉIDIR",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRE",
    pack: "PACÁISTE",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLEAN",
    tonne: "TONNE (MT)",
    uNIT: "AONAD",
    yard: "YARD",
    pleaseEnterProductQuantity: "Iontráil cainníocht an táirge le do thoil",
    productQuantity: "Cainníocht Táirgí",
    isThisProductTaxed: "An ngearrtar cáin ar an táirge seo?",
    selectTaxType: "Roghnaigh Cineál Cánach",
    pleaseSelectTaxType: "Roghnaigh Cineál Cánach le do thoil",
    progressive: "Forásach",
    proportional: "Comhréireach",
    pleaseEnterProductTaxRate: "Iontráil ráta cánach táirgí le do thoil",
    doesProductHaveVariants: "An bhfuil malairtí ag an táirge seo?",
    type: "Cineál",
    value: "Luach",
    pleaseEnterVariantType: "Iontráil cineál athraitheora",
    pleaseEnterVariantValue: "Iontráil luach athraitheach",
    pleaseEnterVariantPrice: "Cuir isteach praghas an athraitheora",
    pleaseEnterVariantQuantity:
      "Iontráil cainníocht an athraitheora le do thoil",
    productDeletedSuccessfully: "Scriosadh an táirge go rathúil!",
    categoryDeletedSuccessfully: "Scriosadh an chatagóir go rathúil!",
    toDeleteThisProduct: "chun an Táirge seo a scriosadh",
    invalidProductQuantity: "Cainníocht Neamhbhailí Táirgí",
    quantityAddedIsOverStock:
      "Tá an chainníocht atá á cur agat os cionn an méid atá agat i stoc.",
    itemInventoryNotTracked: "Ní rianaítear fardal míreanna",
    addBulkQuantity: "Cuir Cainníocht Bulc leis",
    enterBulkQuantity: "Iontráil Cainníocht Bulc",
    pleaseEnterBulkQuantity: "Iontráil Bulc Cainníochta le do thoil",
    youveSuccessfullyAddedANewProduct: "D'éirigh leat táirge nua a chur leis!",
    pleaseEnterProductSellingPrice:
      "Cuir isteach praghas díola an táirge, le do thoil",
    doYouWantToTrackProductStock: "Ar mhaith leat stoc táirgí a rianú?",
    sellingPrice: "Praghas Díol",
    setProductExpiryReminder: "Meabhrúchán Éaga Táirgí Socraithe",
    productExpiryDate: "Dáta éaga an táirge",
    startRemindingFrom: "Tosaigh ag meabhrú ó",
    productSuppliesAddedSuccessfully:
      "Chuir tú soláthairtí táirgí leis go rathúil.",
    addProductSupplies: "Cuir Soláthairtí Táirgí leis",
    pleaseSelectSupplier: "Roghnaigh Soláthraí le do thoil",
    nameOfProduct: "Ainm an Táirge",
    pleaseSelectProduct: "Roghnaigh Táirge le do thoil",
    productVariant: "Athraitheoir Táirgí",
    pleaseSelectAVariant: "Roghnaigh Athróg le do thoil",
    pleaseEnterUnitPrice: "Iontráil Praghas Aonaid le do thoil",
    businessBranch: "Brainse Gnó",
    pleaseSelectBranch: "Roghnaigh brainse le do thoil",
    youveSuccessfullyAddedANewSupplier:
      "D'éirigh leat soláthróir nua a chur leis",
    addSupplier: "Cuir Soláthraí leis",
    pleaseEnterSupplierEmail: "Iontráil r-phost an tsoláthraí",
    pleaseAddADescription: "Cuir tuairisc leis, le do thoil",
    anErrorOccuredProductsDeleted:
      "Tharla earráid agus an oibríocht á déanamh. Tabhair faoi deara le do thoil, is beag táirge a d’fhéadfadh a bheith scriosta sa phróiseas",
    bulkDelete: "Scriosadh Bulc",
    youAreAboutToDelete: "Tá tú ar tí scriosadh",
    product: "Táirge",
    isDueToRedeem: "le fuascailt",
    aReward: "luaíocht",
    pleaseSelectCustomerToReeemReward:
      "Roghnaigh custaiméir le do thoil chun luach saothair a fhuascailt.",
    youHaveNoLoyaltyProgramRunning:
      "Níl aon chlár dílseachta gníomhach á rith agat",
    customerHhasNoPointsInLoyaltyProgram:
      "Níl aon phointí ag an gcustaiméir sa chlár dílseachta seo",
    proceedWithPayment: "Lean ar aghaidh leis an Íocaíocht?",
    youAreAboutToPayForTransactionUsingPoints:
      "Tá tú ar tí íoc as an idirbheart ag úsáid pointí.",
    customerHas: "Tá ag Custaiméir",
    worth: "fiú",
    andIsNotSufficientToPayForTransaction:
      "agus ní leor é chun íoc as an idirbheart seo. Ar mhaith leo an t-iarmhéid a chur leis ag úsáid modh íocaíochta eile?",
    addCustomerLoyalty: "Cuir Dílseacht do Chustaiméirí leis",
    pleaseAddCustomerFirst: "Cuir custaiméir leis nó roghnaigh é ar dtús.",
    pleaseWaitWhileWeGetReady: "Fan le do thoil, agus muid réidh",
    lowStock: "Stoc Íseal",
    pleaseEnterAmountTendered: "Iontráil an méid a tairgeadh le do thoil",
    areYouSureToBookSaleOffline:
      "An bhfuil tú cinnte gur mhaith leat an díolachán seo a chur in áirithe as líne?",
    saleWouldBeBookedAutomatically:
      "Chuirfí an díol in áirithe go huathoibríoch nuair a chasann tú ar do idirlíon",
    offlineSalesBookingCancelled: "Cealaíodh áirithint díolacháin as líne",
    covid19Message:
      "COVID19: Nigh do lámha le gallúnach nó Sláintigh chun an scaipeadh a stopadh. Bí Sábháilte i gcónaí",
    saleSuccessfullyRecorded: "Díol Taifeadta go rathúil!",
    sendReceiptToEmail: "Seol admháil chuig R-phost",
    sendThankYouSms: "Seol SMS go raibh maith agat",
    sendShippingDetails: "Seol sonraí Loingseoireachta",
    addLoyalty: "Cuir Dílseacht leis",
    searchCustomerNameOrNumber: "Cuardaigh ainm nó uimhir an chustaiméara",
    clickTheSearchCustomerBox:
      "Cliceáil an Bosca Cuardaigh Cuardaigh agus an Cárta Scanadh",
    enterProductPrice: "Iontráil Praghas Táirgí",
    enterPriceFor: "Iontráil Praghas do",
    searchForProduct: "Cuardaigh táirge",
    all: "Gach",
    backToDashboard: "Ar ais ar an bPainéal",
    viewDraftSales: "Féach ar Dhréacht-Díolacháin",
    variantSelected: "athróg roghnaithe",
    variant: "athraitheach",
    thePreviousVariantSelectionNotAvailable:
      "Níl an rogha athraitheach roimhe seo ar fáil don leagan nua a roghnaíodh bunaithe ar phraghas, athraigh do rogha le do thoil.",
    pleaseSupplyPositiveQuantityNumber:
      "Tabhair uimhir chainníochta postála le do thoil",
    lowStockFor: "Stoc íseal do",
    clearVariants: "Athruithe soiléire",
    pleaseEnterQuantity: "Iontráil Cainníocht le do thoil",
    picture: "Pictiúr",
    redemptionToken: "Taisce Fuascailte",
    token: "Token",
    totalSpent: "Iomlán Caite",
    confirmPayment: "Deimhnigh an Íocaíocht",
    hasPaymentBeenMade: "Ar próiseáladh an íocaíocht go rathúil?",
    enterTransactionReference:
      "Cuir isteach an tagairt idirbhirt ar íoc tú leis",
    pleaseSelectACustomer: "Roghnaigh custaiméir le do thoil!",
    areYouSureToApplyDiscount:
      "An bhfuil tú cinnte gur mhaith leat lascaine a chur i bhfeidhm?",
    addYourBankAccountToEnableUssd:
      "Cuir do chuntas bainc leis chun aistriú Láithreach USSD a chumasú le uPay",
    totalAmountToPay: "Méid Iomlán le hÍoc",
    doYouWantToCancelTransaction:
      "Ar mhaith leat an t-idirbheart seo a chealú?",
    savePrintBill: "Bille Sábháil / Priontáil",
    enterAmountCollectedForCustomer:
      "Cuir isteach an méid a bailíodh don chustaiméir",
    recordSale: "Díol Taifead",
    checkOutWith: "Seiceáil le",
    instantTransfer: "Aistriú Láithreach",
    dialTheUSSDCode: "Diailigh an cód USSD",
    pleaseSelectABank: "Roghnaigh banc le do thoil",
    payWithUSSD: "Íoc Le USSD",
    sendBillTo: " - Bille a sheoladh chuig",
    waitingForUSSDTransfer: "Ag feitheamh le hAistriú USSD",
    percent: "Céatadán",
    applyDiscount: "Cuir Lascaine i bhfeidhm",
    thisBillHasBeenSaved: "Sábháladh an Bille seo",
    saveDraft: "Sábháil Dréacht",
    pleaseTypeANameToIdentifyCustomer:
      "Clóscríobh ainm le do thoil chun an custaiméir a aithint",
    paymentDetails: "Sonraí Íocaíochta",
    basePrice: "Bunphraghas",
    staff: "Foireann",
    subTotal: "Fo-iomlán",
    durationMonths: "Fad (míonna)",
    selectADuration: "Roghnaigh Fad",
    oneMonth: "1 mhí",
    twoMonths: "2 mhí",
    threeMonths: "3 mhí",
    sixMonths: "6 mhí",
    twelveMonths: "12 mhí",
    eighteenMonths: "18 Mí",
    twentyFourMonths: "24 Mí",
    twoMonthsFree: "(2 mhí saor in aisce)",
    threeMonthsFree: "(3 mhí saor in aisce)",
    fiveMonthsFree: "(5 mhí saor in aisce)",
    yourAccountHasBeen: "Tá do Chuntas curtha",
    renewed: "athnuaite",
    upgraded: "uasghrádaithe",
    successfully: "go rathúil",
    yourSubscription: "Do Síntiús",
    youAreCurrentlyEnrolledOnA: "Tá tú cláraithe faoi láthair ar a",
    pleaseChooseAPaymentOption: "Roghnaigh Rogha Íocaíochta le do thoil",
    planRenewal: "Athnuachan Plean",
    planUpgrade: "Uasghrádú Plean",
    pleaseSelectDurationToPayFor:
      "Roghnaigh Fad a bhfuil sé ar intinn agat íoc as",
    staffAccounts: "Cuntais Foirne",
    ecommerce: "Ríomhthráchtáil",
    pleaseSelectAPlan: "Roghnaigh plean le do thoil",
    includeAddons: "Cuir Breiseáin san áireamh",
    viewTransactions: "Féach ar Idirbhearta",
    customerHasNoCompletedTansactions:
      "Níl aon bhearta críochnaithe ag an gcustaiméir go fóill",
    branch: "Géaga",
    enterNumberOfEcommerceBranches: "Iontráil Líon na mBrainsí Ríomhthráchtála",
    enterNumberOfEcommerceBranchesToPay:
      "Iontráil Líon na mBrainsí Ríomhthráchtála a bhfuil sé ar intinn agat íoc astu",
    ecommerceIntegration: "Comhtháthú Ríomhthráchtála",
    enterNumberOfBranches: "Iontráil Líon na mBrainsí",
    enterNumberOfAdditionalBranchesToPay:
      "Iontráil Líon na mBrainsí Breise a bhfuil sé ar intinn agat Íoc astu",
    enterNumberOfStaffs: "Iontráil Líon na Foirne",
    enterNumberOfStaffsToPayFor:
      "Iontráil Líon na Foirne a bhfuil sé ar intinn agat íoc astu",
    discountApplies: "Baineann Lascaine leis",
    starsOnThe: "réaltaí ar an",
    offer: "tairiscint",
    get: "Faigh",
    moreStarsToRedeem: "níos mó réaltaí le fuascailt",
    taxVat: "Cáin (CBL)",
    callCashierToCompletePayment:
      "Glaoigh ar Airgeadóir chun Íocaíocht a Chomhlánú",
    receipt: "Admháil",
    dear: "A chara",
    thankYouForYourOrderFindGoods:
      "Go raibh maith agat as d'ordú. Faigh na hearraí seo a leanas a soláthraíodh, mar a aontaíodh.",
    shippingNote: "Nóta Loingseoireachta",
    enterShippingNote: "Iontráil Nóta Loingseoireachta",
    shippingAddress: "Seoladh Loingseoireacht",
    enterShippingAddress: "Iontráil seoladh Loingseoireachta",
    wellDoneYouAreDueToRedeem: "An-mhaith! Tá tú le fuascailt",
    toGetYourRewardNextVisit:
      "chun do luaíocht a fháil ar do chéad chuairt eile. Go raibh maith agat",
    pointsOnThe: "Pointí ar an",
    morePointsToRedeem: "níos mó pointí le fuascailt",
    showCode: "Taispeáin cód",
    toGetYourRewardOnNextVisit:
      "chun do luaíocht a fháil ar do chéad chuairt eile. Go raibh maith agat",
    earn: "Thuilleamh",
    delivaryNote: "Nóta Delivary",
    draftSales: "Dréacht-Díolacháin",
    startDate: "Dáta tosaigh",
    endDate: "Dáta deiridh",
    orders: "Orduithe",
    checkout: "Seiceáil",
    noProductItem: "Gan Mír Táirgí",
    selectProductImage: "Roghnaigh Íomhá Táirgí",
    selectCountry: "Roghnaigh tír",
    selectRegion: "Roghnaigh Stát / Réigiún",
    printProductTag: "Clib Táirgí Priontála",
    transactionReference: "Tagairt idirbhirt",
    Cashier: "Airgeadóir",
    Manager: "Bainisteoir",
    Owner: "Úinéir",
    Admin: "Riarachán",
    addPartners: "Cuir Comhpháirtithe leis",
    addNewLoyaltyPartner: "Cuir Comhpháirtí Dílseachta Nua leis",
    pleaseEnterCompanyName: "Iontráil Ainm na Cuideachta",
    companyName: "Ainm na Cuideachta",
    pleaseEnterCompanyRepName: "Iontráil Ainm ionadaí na cuideachta",
    companyRepName: "Ainm Ionadaí na Cuideachta",
    pleaseEnterCompanyRepEmail: "Iontráil r-phost chuig ionadaí na cuideachta",
    companyRepEmail: "R-phost chuig ionadaí na cuideachta",
    pleaseEnterCompanyRepPhone:
      "Iontráil uimhir theileafóin ionadaí na cuideachta",
    companyRepPhone: "Líon Teileafóin ionadaí na cuideachta",
    addReward: "Cuir luach saothair leis",
    pleaseEnterRewardName: "Iontráil ainm luaíochta",
    rewardName: "Ainm luaíochta",
    rewardQuantity: "Cainníocht luaíochta",
    rewardDescription: "Cur síos ar Luaíocht",
    rewardType: "Cineál luaíochta",
    pleaseEnterRewardType: "Iontráil cineál luaíochta",
    pleaseEnterRewardQuantity: "Cuir isteach cainníocht luaíochta",
    pleaseEnterRewardDescription: "Iontráil tuairisc luaíochta",
    fineDining: "Itheacháin Mhín",
    luxuryFashion: "Faisean só",
    hotels: "Óstáin",
    travel: "Taisteal",
    foodAndBeverage: "Bia agus Dí",
    fashion: "Faisean",
    health: "Sláinte",
    furniture: "Troscán",
    entertainment: "Siamsaíocht",
    automobile: "Gluaisteán",
    education: "Oideachas",
    beautyAndSpa: "Áilleacht agus Spa",
    staycation: "Fanacht",
    events: "Imeachtaí",
    trips: "Turais",
    oilAndGas: "Ola agus Gás",
    laundry: "Níocháin",
    partnerCategory: "Catagóir Comhpháirtí",
    freeItem: "Mír In Aisce",
  },
  Indonesian: {
    cashier: "kasir",
    manager: "Pengelola",
    owner: "pemilik",
    online: "on line",
    offline: "offline",
    changePassword: "Ganti kata sandi",
    currentPasswordMessage: "Silakan masukkan kata sandi Anda saat ini",
    passwordMessage: "Silakan masukkan kata sandi Anda",
    currentPassword: "kata sandi saat ini",
    password: "kata sandi",
    confirmPasswordMessage: "Harap konfirmasi kata sandi Anda!",
    confirmPassword: "konfirmasi sandi",
    sendViaEmail: "Kirim Melalui Email",
    sendViaWhatsapp: "Kirim Via WhatsApp",
    downloadPdf: "Unduh PDF",
    paid: "dibayar",
    unpaid: "belum dibayar",
    partial: "sebagian",
    closeInvoice: "Apakah Anda ingin menutup Faktur?",
    closeInvoiceConfirmation:
      "Faktur mungkin tidak disimpan. Apakah Anda ingin menutup?",
    yes: "Ya",
    no: "tidak",
    invoice: "Faktur",
    wasDeducted: "telah dikurangi",
    for: "untuk",
    item: "Barang",
    addProduct: "Tambahkan Produk",
    paymentReference: "Referensi pembayaran",
    amountPaid: "Jumlah yang dibayarkan",
    discountAmount: "Jumlah diskon",
    amountDue: "Jumlah yang harus dibayar",
    amount: "Jumlah",
    dueDate: "Tenggat waktu",
    paymentType: "Tipe pembayaran",
    card: "Kartu",
    cash: "Uang tunai",
    bankTransfer: "Transfer Bank",
    paymentMessage: "Pesan Pembayaran",
    description: "Keterangan",
    sendReceipt: "Kirim Tanda Terima",
    delete: "Menghapus",
    save: "Menyimpan",
    resend: "Kirim Ulang",
    saveAndSend: "Menyimpan",
    invoiceSaved: "Faktur disimpan!",
    selectPaymentMethod: "Silakan pilih Metode Pembayaran!",
    selectCustomer: "Silakan pilih Pelanggan!",
    cartEmptyError:
      "Daftar Keranjang tidak boleh kosong, tutup faktur dan tambahkan Item ke keranjang!",
    subscriptionExpired:
      "Langganan Anda telah kedaluwarsa dan akun Anda sekarang dibatasi. Klik Tombol di bawah ini untuk memperbarui akun Anda",
    renew: "Memperbarui",
    holdOn: "Tunggu",
    customerBank: "Bank Pelanggan",
    cancel: "Membatalkan",
    selectACustomer: "Pilih Pelanggan",
    invoiceSuccessfulPdfError:
      "Faktur berhasil dibuat tetapi Pembuatan PDF memakan waktu lebih lama dari biasanya. Silakan periksa kembali sebentar lagi.",
    downloadingInvoice: "Mengunduh Faktur",
    downloadingReceipt: "Mengunduh Tanda Terima",
    whatsappReceiptError:
      "Terjadi kesalahan pengiriman tanda terima melalui WhatsApp, Silakan coba lagi.",
    receiptToWhatsapp: "Tanda terima diteruskan ke WhatsApp",
    thankYouForPatronage: "Terima kasih atas patronase Anda",
    hereIsYourReceipt: "Ini bukti pembayaran Anda",
    errorSendingEmailReceipt:
      "Terjadi kesalahan saat mengirim tanda terima melalui email, silakan coba lagi atau hubungi dukungan",
    receiptSentToEmail: "Resi sudah dikirim ke email pelanggan",
    invoiceSentToEmail: "Faktur telah dikirim ke email pelanggan",
    invoiceSuccessWhatsappError:
      "Faktur berhasil dibuat tetapi terjadi kesalahan saat mengirim ke WhatsApp. Coba lagi di daftar faktur",
    invoiceSuccessfulEmailError:
      "Faktur berhasil dibuat tetapi terjadi kesalahan saat mengirim sebagai email. Silakan coba lagi dari daftar faktur",
    invoiceSentToWhatsapp: "Faktur diteruskan ke WhatsApp",
    hello: "Halo",
    pleaseDownloadInvoice: "Silakan unduh faktur",
    pleaseDownloadReceipt: "Silakan unduh tanda terima",
    from: "dari",
    email: "Surel",
    upgrade: "Meningkatkan",
    youAreOnFreePlan: "Anda menggunakan Paket Gratis.",
    clickOn: "Klik",
    yourPlanInFewSteps: " langganan Anda dalam beberapa langkah cepat.",
    to: "ke",
    yourSubscriptionHasExpired:
      "Langganan Anda telah kedaluwarsa dan akun Anda sekarang dibatasi.",
    days: "hari",
    yourSubscriptionExpiresIn: "Langganan Loystar Anda kedaluwarsa dalam",
    createAcount: "Buat sebuah akun",
    signIn: "Masuk",
    continue: "Melanjutkan",
    enterOtp: "Masukkan PIN OTP",
    enterValidOtp: "Harap masukkan PIN yang valid",
    pin: "PIN",
    tokenSentToMail: "Token telah dikirim ke email Anda",
    passwordResetSuccessful: "Reset kata sandi berhasil",
    somethingWentWrong: "Ada yang salah!",
    resetPassword: "Setel Ulang Kata Sandi",
    iHaveResetCode: "Saya memiliki kode reset kata sandi",
    pleaseEnterEmail: "Masukkan email Anda",
    aTokenWillBeSentToEmail: "Token akan dikirim ke email Anda",
    enterEmail: "Masukkan email Anda",
    sendinYourToken: "Mengirim token Anda...",
    makeSureItMatchesPassword: "Pastikan itu cocok dengan kata sandi baru Anda",
    pleaseChooseNewPassword: "Silakan pilih kata sandi baru",
    chooseNewPassword: "Pilih kata sandi baru",
    enterNewPassword: "Masukkan kata sandi baru Anda untuk mengonfirmasi",
    enterTokenSent: "Masukkan token yang dikirimkan ke email Anda",
    resetToken: "Setel Ulang Token",
    resettingPassword: "Menyetel Ulang Kata Sandi Anda...",
    signUp: "Mendaftar",
    adminSignInWithEmail:
      " Admin masuk dengan email sementara Staf masuk dengan nama pengguna.",
    pleaseEnterEmailOrUsername:
      "Silakan masukkan email atau nama pengguna Anda",
    emailOrUsername: "Email atau nama pengguna",
    pleaseEnterPassword: "Silakan masukkan kata sandi",
    createAnAccount: "Buat sebuah akun",
    forgotPassword: "Tidak ingat kata sandi?",
    enterPhoneNumber: "Masukkan nomor telepon",
    personalData: "Data pribadi",
    validateConfirmationCOde: "Validasi Kode Konfirmasi",
    pleaseEnterFirstName: "Silakan masukkan nama depan Anda",
    pleaseEnterPhoneNumber: "Masukkan nomor telepon anda",
    pleaseEnterLastName: "Silakan masukkan nama belakang Anda",
    pleaseEnterBusinessName: "Silakan masukkan nama bisnis Anda",
    firstName: "Nama depan",
    lastName: "Nama keluarga",
    businessName: "Nama Bisnis",
    previous: "Sebelumnya",
    next: "Lanjut",
    pleaseSelectBusinessCategory: "Silakan pilih kategori bisnis Anda",
    pleaseEnterValidEmail: "tolong masukkan email yang benar",
    pleaseEnterPostCode: "Silakan masukkan kode pos",
    postCode: "Kode Pos",
    phoneNumberInUse: "Nomor telepon ini sudah digunakan!",
    emailInUse: "Email ini sudah digunakan!",
    foodAndDrinks: "Makanan dan minuman",
    salonAndBeauty: "Salon dan Kecantikan",
    fashionAndAccessories: "Mode dan Aksesoris",
    gymAndFitness: "Gym dan Kebugaran",
    travelAndHotel: "Perjalanan dan Hotel",
    homeAndGifts: "Rumah dan Hadiah",
    washingAndCleaning: "Mencuci dan Membersihkan",
    gadgetsAndElectronics: "Gadget dan Elektronik",
    groceries: "Bahan makanan",
    others: "Yang lain",
    submit: "Kirim",
    accountCreatedSuccessful: "Akun Anda berhasil dibuat.",
    pleaseEnterAddress: "Silahkan Masukkan Alamat Anda",
    addressLine1: "Baris Alamat 1",
    addressLine2: "Alamat Baris 2",
    choosePassword: "Memilih sandi",
    passwordMustBe6Characters: "Kata sandi harus minimal 6 karakter.",
    howDidYouHearLoystar: "Bagaimana Anda mendengar tentang Loystar?",
    referralCode: "kode rujukan",
    byClickingTheButton:
      " Dengan mengklik tombol di bawah ini, Anda menyetujui",
    termsAndSevice: "Ketentuan",
    wereCreatingAccount: "Kami sedang membuat akun Anda",
    proceed: "Memproses",
    verificationCodeMustBe6: "Kode Verifikasi harus 6 digit",
    pleaseEnter6DigitCode: "Silakan Masukkan 6 digit kode",
    enterVerificationCode: "Masukkan kode verifikasi",
    resendToken: "Kirim Ulang Token",
    verify: "Memeriksa",
    transactions: "Transaksi",
    todaySales: "Penjualan hari ini",
    salesHistory: "Sejarah Penjualan",
    supplyHistory: "Sejarah Pasokan",
    new: "Baru",
    invoices: "Faktur",
    disbursements: "Pencairan",
    offlineSales: "Penjualan Offline",
    products: "Produk",
    customers: "Pelanggan",
    multiLevelLoyalty: "Loyalitas Multi-Level",
    loyaltyPrograms: "Program Loyalitas",
    members: "Anggota",
    appStore: "Toko aplikasi",
    orderMenu: "Pesan Menu",
    settings: "Pengaturan",
    staffAndBranches: "Staf dan Cabang",
    myAccount: "Akun saya",
    switchToSellMode: "Beralih ke Mode Jual",
    signOut: "Keluar",
    getFreeSubscription: "Dapatkan Berlangganan Gratis",
    onlyNumbersAllowed: "Hanya angka yang diperbolehkan",
    yourAccountMustBe10Digits: "nomor rekening Anda harus 10 digit nomor",
    yourBvnMustBe11: "BVN Anda harus berupa 11 digit angka",
    pleaseSelectBank: "Silakan pilih bank Anda",
    selectYourBank: "Pilih Bank Anda",
    enterBankAccountNumber: "Masukkan Nomor Rekening Bank",
    enterBvnNumber: "Masukkan BVN Anda",
    connectBankAccount: "Hubungkan Rekening Bank",
    passwordResetSuccessfulAndSignOut:
      "Password Anda telah berhasil direset. Klik tombol Keluar di bawah untuk masuk lagi",
    enterCurrentPassword: "Masukkan kata sandi saat ini",
    pleaseEnterCurrentPassword: "Silakan masukkan kata sandi saat ini",
    phoneNumber: "Nomor telepon",
    sex: "Seks",
    dateOfBirth: "Tanggal lahir",
    state: "Negara",
    country: "Negara",
    loyaltyId: "ID Loyalitas",
    createdAt: "Dibuat di",
    noOfTransactionsMade: "Jumlah transaksi yang dilakukan",
    yourDownloadWillStart: "Unduhan Anda akan segera dimulai",
    exportCustomers: "Ekspor Pelanggan",
    youhaveSuccessfullyToppedUp:
      "Anda telah berhasil mengisi ulang Unit SMS Anda.",
    youNowHave: "Anda sekarang memiliki",
    smsUnits: "Unit SMS",
    enterNumberOfSmsUnits: "Masukkan Jumlah Unit SMS yang Ingin Anda Beli",
    pleaseEnterANumericValue: "Silakan masukkan nilai numerik",
    pay: "Membayar",
    accountEditedSuccessfully: "Akun Anda berhasil diedit.",
    youAeCurrentlyOn: "Anda sedang aktif",
    plan: "Rencana",
    userData: "Data pengguna",
    businessData: "DATA BISNIS",
    businessCategory: "Kategori Bisnis",
    pleaseSelectCurrency: "Silakan pilih mata uang Anda",
    selectYourCurrency: "Pilih mata uang Anda",
    purchaseMoreSmsUnits:
      "Beli lebih banyak Unit SMS menggunakan formulir di bawah ini",
    youHave: "Kamu punya",
    atLeast4SmsUnitsRrequired:
      "Diperlukan setidaknya 4 unit sms untuk verifikasi, silakan isi ulang!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Harap verifikasi rekening bank Anda untuk memenuhi persyaratan 'Kenali Pelanggan Anda' (KYC). Ini akan memungkinkan Anda mengumpulkan pembayaran melalui USSD atau transfer instan, menerima pesanan dari pelanggan dan memproses penyelesaian transaksi. Hanya berlaku untuk pedagang Nigeria. Silakan tekan tombol di bawah ini untuk memulai.",
    reConnectBankAccount: "Hubungkan kembali Rekening Bank",
    accountName: "Nama akun",
    accountNumber: "Nomor rekening",
    bankName: "Nama Bank",
    verified: "Diverifikasi",
    accountDetails: "Detail akun",
    kycBankAccount: "KYC",
    createTier: "Buat Tingkat",
    fileUploadSuccessful: "berkas berhasil diunggah",
    fileUploadFailed: "unggahan file gagal",
    createLoyaltyProgram: "Buat Program Loyalitas",
    createLoyalty: "Buat Loyalitas",
    name: "Nama",
    loyaltyArtwork: "Karya Kesetiaan",
    clickToUpload: "Klik untuk mengunggah",
    amountToPointsRatio: "Rasio Jumlah Poin",
    points: "Poin",
    recommendedAmountToPointRatio:
      "Direkomendasikan: 1 hingga 1 poin. yaitu untuk setiap 1 Naira yang dibelanjakan, pelanggan Anda mendapatkan 1 poin",
    pleaseTypeIn: "Silakan ketik",
    toDeleteLoyalty: "untuk menghapus kesetiaan ini",
    deleteLoyalty: "Hapus Loyalitas",
    toConfirm: "untuk mengkonfirmasi",
    edit: "Sunting",
    pointsAwardedSuccessfully: "Poin Diberikan Berhasil.",
    enterPointValueToAward:
      "Masukkan Nilai Poin yang Ingin Anda Berikan Kepada Pelanggan",
    award: "Menghadiahkan",
    awardPointValuesToCustomer: "Nilai Poin Penghargaan kepada Pelanggan",
    enrollMembersToLoyalty: "Daftarkan Anggota ke Loyalty",
    awardPoints: "Poin Penghargaan",
    enroll: "Mendaftar",
    home: "Rumah",
    loyalty: "Loyalitas",
    enrollCustomers: "Daftarkan Pelanggan",
    selected: "Terpilih",
    customer: "Pelanggan",
    loyaltyActivationSuccessful: "Aktivasi Loyalitas berhasil.",
    loyaltyDeactivationSuccessful: "Penonaktifan Loyalitas berhasil.",
    viewTier: "Lihat Tingkat",
    deactivate: "Menonaktifkan",
    activate: "Mengaktifkan",
    actions: "tindakan",
    nameOfLoyalty: "Nama Kesetiaan",
    loyaltyStatus: "Status LoyalitasStatus Loyalitas",
    numberOfTiers: "Jumlah Tingkat",
    createdOn: "Dibuat pada",
    createATier: "Buat Tingkat",
    stopCreatingTierConfirmation:
      "Apakah Anda ingin berhenti membuat tingkatan?",
    stopEditingTierConfirmation:
      "Apakah Anda ingin berhenti mengedit tingkat ini?",
    nameOfTier: "Nama Tier",
    minimumSpendingTarget: "Target Pengeluaran Minimum",
    maximumSpendingTarget: "Target Pembelanjaan Maksimum",
    minimumSpendingTargetRequired: "target pembelanjaan minimum diperlukan",
    maximumSpendingTargetRequired: "target pembelanjaan maksimum diperlukan",
    rewardSponsor: "Hadiah Sponsor",
    pleaseChooseARewardSponsor: "Silakan pilih sponsor hadiah",
    self: "Diri sendiri",
    partner: "Mitra",
    rewardPartner: "Mitra Hadiah",
    pleaseSelectRewardPartner: "Silakan pilih mitra hadiah Anda",
    rewards: "Hadiah",
    pleaseSelectAReward: "Silahkan pilih sebuah hadiah",
    instructionsOnRedeemingReward:
      "Petunjuk tentang Bagaimana Pelanggan Harus Menebus Hadiah",
    pleaseFillInThisField: "Silahkan Isi Kolom ini!",
    toDeleteThisTier: " untuk menghapus tingkat ini",
    deleteTier: "Hapus Tingkat",
    viewMembers: "Lihat Anggota",
    membersEnrolled: "Anggota Terdaftar",
    instruction: "Petunjuk",
    membersIn: "Anggota di",
    availableTiersInLoyalty: "Tingkat yang Tersedia dalam Program Loyalitas",
    tiers: "Tingkatan",
    totalTier: "JUMLAH TINGKAT",
    availableLoyaltyProgramme: "Program Loyalitas yang Tersedia",
    totalLoyalties: "LOYALITAS JUMLAH",
    memberDetails: "Detail Anggota",
    nameOfCustomer: "Nama Pelanggan",
    address: "Alamat",
    allEnrolledMembers: "Semua Anggota Terdaftar",
    thisIsToWishYouHappyBirthday:
      "Ini untuk mengucapkan selamat ulang tahun dan kehidupan yang sejahtera. Terima kasih untuk semua yang Anda lakukan untuk Loyster. Selamat Perayaan!",
    inputAnOfferPlease: "Silakan masukkan Penawaran",
    pleaseSelectTheInsertPoint:
      "Silakan pilih titik sisipkan dalam pesan dan klik lagi",
    birthdayOfferAndMessage: "Penawaran dan Pesan Ulang Tahun",
    birthdayOffer: "Penawaran Ulang Tahun",
    birthdayMessage: "Pesan Ulang Tahun",
    noOfferFound: "Tidak ada penawaran yang ditemukan",
    settingABirthdayOffer:
      "Menetapkan penawaran ulang tahun memungkinkan pelanggan untuk menerima penawaran ini melalui SMS pada hari ulang tahun mereka",
    createOffer: "Buat Penawaran",
    whatIsTheOffer: "Apa tawarannya?",
    editMessage: "Sunting Pesan",
    insert: "Memasukkan",
    theNameOfCustomerInserted: "Nama pelanggan akan dimasukkan di sini",
    theBirtdayOfferInserted: "Penawaran ulang tahun akan dimasukkan di sini",
    youSuccessfullyAddedNewBranch:
      "Anda telah berhasil menambahkan cabang baru!",
    addNewBranch: "Tambah Cabang Baru",
    addBranch: "Tambahkan Cabang",
    additionalBranchWillIncur: "Cabang tambahan akan dikenakan",
    perBranch: "per cabang",
    ecommerceBranchCosts: "Biaya Cabang E-niaga",
    pleaseEnterBranchName: "Silakan masukkan nama cabang",
    pleaseEnterBranchAddress1: "Silakan masukkan baris alamat cabang 1",
    enterBranchAddress1: "Masukkan baris alamat cabang 1",
    enterBranchAddress2: "Masukkan baris alamat cabang 1",
    pleaseEnterBranchAddress2: "Silakan masukkan baris alamat cabang 2",
    enterBranchName: "Masukkan nama cabang",
    successfullyAddedStaff: "Anda telah berhasil menambahkan staf baru!",
    addNewStaff: "Tambahkan Staf Baru",
    addStaff: "Tambahkan Staf",
    additionalStaffWillIncur: "Staf tambahan akan dikenakan",
    perStaff: "per staf.",
    pleaseEnterStaffEmail: "Silakan masukkan email staf",
    pleaseEnterStaffUsername: "Silakan masukkan nama pengguna staf",
    pleaseEnterStaffPassword: "Silakan masukkan kata sandi staf",
    pleaseSelectStaffRole: "Silakan pilih peran staf",
    selectStaffRole: "Pilih peran staf",
    enterStaffEmail: "Masukkan email staf",
    enterStaffUsername: "Masukkan nama pengguna staf",
    enterStaffPassword: "Masukkan kata sandi staf",
    spacesNotAllowedInUsername: "spasi tidak diperbolehkan di nama pengguna",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Silakan pilih bisnis untuk melampirkan staf",
    searchForBranchToAttachStaff: "Cari cabang untuk melampirkan staf",
    username: "Nama pengguna",
    role: "Peran",
    areYouSureToDeleteThis: "Apakah Anda yakin untuk menghapus ini?",
    branches: "Ranting",
    upgradeYourPlan: "Tingkatkan Paket Anda.",
    add: "MENAMBAHKAN",
    addNew: "Tambah baru",
    customerWithEmailAlreadyExists:
      "Pelanggan dengan email/nomor telepon sudah ada!",
    successfullyAddedNewCustomer:
      "Anda telah berhasil menambahkan pelanggan baru!",
    addCustomer: "Tambahkan Pelanggan",
    pleaseEnterCustomerFirstName: "Silakan masukkan nama depan pelanggan",
    pleaseEnterCustomerLastName: "Silakan masukkan nama belakang pelanggan",
    pleaseEnterCustomerPhoneNumber: "Silakan masukkan nomor telepon pelanggan",
    pleaseEnterCustomerEmail: "Silakan masukkan email pelanggan",
    pleaseEnterCustomerAddressLine: "Silakan masukkan Baris Alamat pelanggan",
    pleaseEnterCustomerOtherAddress:
      "Silakan masukkan Alamat pelanggan lainnya",
    pleaseSelectCustomerGender: "Silakan pilih jenis kelamin pelanggan",
    gender: "Jenis kelamin",
    male: "Pria",
    female: "Perempuan",
    bank: "Bank",
    selectBank: "Pilih Bank",
    stateOrRegionOrProvince: "Negara Bagian/Wilayah/Provinsi",
    customerNotes: "Catatan Pelanggan",
    sendSms: "Kirim SMS",
    editCustomer: "Sunting Pelanggan",
    redeemReward: "Tukarkan Hadiah",
    issueLoyaltyCard: "Keluarkan Kartu Loyalitas",
    deleteCustomer: "Hapus Pelanggan",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Anda telah berhasil menetapkan ID Keanggotaan Loyalitas",
    noMembershipIdAvailable:
      "Tidak ada ID keanggotaan yang tersedia. Silakan hubungi kami di hello@loystar.co",
    sendEmail: "Mengirim email",
    membershipPoints: "Poin Keanggotaan",
    customerDetails: "detil pelanggan",
    close: "Menutup",
    loyaltyBalance: "Keseimbangan Loyalitas",
    pointsBalance: "Saldo Poin",
    starBalance: "Saldo Bintang",
    requiredPoints: "Poin yang Diperlukan",
    requiredStars: "Bintang yang Diperlukan",
    reddemCode: "Menebus kode",
    toDeleteThisCustomer: "untuk menghapus pelanggan ini",
    customerHasBeenDeletedSuccessfully: "Pelanggan telah berhasil dihapus!",
    customerWithPhoneAlreadyExists: "Pelanggan dengan nomor telepon sudah ada",
    customerWasSuccessfullyEdited: "Pelanggan berhasil diedit",
    anErrorOccured: "Kesalahan terjadi",
    phoneNumberIncludeCountry: "Nomor Telepon (Sertakan Kode Negara)",
    yourFileQueuedForUpload:
      "File Anda telah diantrekan untuk diunggah. Harap segarkan halaman setelah beberapa detik.",
    thereWasAnErrorPerformingOperation:
      "Terjadi kesalahan saat melakukan operasi!",
    pleaseSelectFile: "Silakan Pilih File!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Hanya file CSV yang diizinkan. Harap unggah file .csv.",
    fileShouldBeSmallerThan5Mb:
      "File harus lebih kecil dari 5MB. Jika Anda memiliki file yang lebih besar, silakan kirim email ke support@loystar.co. Terima kasih",
    customerFirstNameIsRequired: "nama depan pelanggan diperlukan pada baris",
    customerPhoneNumberIsRequired:
      "nomor telepon pelanggan diperlukan pada baris",
    importCustomers: "Impor Pelanggan",
    upload: "Mengunggah",
    clickIconToDownloadCsv: "Klik ikon ini untuk mengunduh template file CSV.",
    getGoogleSheetVersion: "Dapatkan versi lembar Google",
    clickOrDragFileToUpload:
      "Klik atau seret file ke area ini untuk mengunggah",
    missingOrInvalidColumnHeader:
      "Header kolom tidak ada atau tidak valid. Silakan ikuti format template. Terima kasih.",
    youHaveImported: "Anda Telah Mengimpor",
    youSuccessfullyRedeemedReward:
      "Anda telah berhasil menukarkan hadiah Anda!",
    sixDigitCode: "Kode enam digit",
    pleaseEnterCustomerRewardCode: "Silakan masukkan kode hadiah pelanggan",
    enterRewardCode:
      "Masukkan kode hadiah. (Kode hadiah peka huruf besar-kecil)",
    selectLoyaltyProgram: "Pilih program loyalitas",
    stamps: "Perangko",
    smsUnitsLow: "Unit SMS Rendah",
    pleaseTopUpSmsUnits: "Silakan Isi Ulang Unit SMS",
    smsSuccessfullySentForDelivery: "SMS berhasil dikirim untuk pengiriman!",
    sendSmsTo: "Kirim SMS ke",
    allCustomers: "Semua Pelanggan",
    unitsAvailable: "Unit Tersedia",
    pleaseTypeInTheMessage: "Silakan ketik pesan",
    message: "Pesan",
    charactersRemaining: "karakter tersisa",
    avoidUseOfSpecialCharacters:
      "Hindari penggunaan Karakter Khusus dan Emoji untuk menghemat Unit SMS.",
    note: "Catatan",
    errorFetchingCustomersMultilevelDetail:
      "Kesalahan Mengambil Detail MultiLevel Pelanggan",
    search: "Mencari",
    reset: "Mengatur ulang",
    importCustomer: "Impor Pelanggan",
    addNewCustomer: "Tambahkan Pelanggan Baru",
    sendSmsBroadcast: "Kirim Siaran SMS",
    totalCustomers: "JUMLAH PELANGGAN",
    disbursementDetails: "Detail Pencairan",
    paidBy: "Dibayar oleh",
    disbursed: "Dicairkan",
    bankAccountName: "Nama akun bank",
    bankAccountNumber: "Nomor rekening bank",
    transferInitiated: "Transfer Dimulai",
    transferCompleted: "Transfer Selesai",
    pleaseEnterAValid: "Harap masukkan yang valid",
    begin: "mulai",
    end: "akhir",
    date: "tanggal",
    paymentDate: "Tanggal pembayaran",
    selectDisbursementDuration: "Pilih Durasi Pencairan",
    totalSettled: "Total Diselesaikan",
    totalUnsettled: "Total Tidak Tenang",
    toDeleteThisSale: "untuk menghapus penjualan ini",
    draftSaleDeletedSuccessfully: "Draf Penjualan telah berhasil dihapus!",
    deleteSale: "Hapus Penjualan",
    pleaseTypeInYourTotal:
      "Silakan ketik total Anda untuk mengonfirmasi penghapusan",
    billDetails: "Rincian tagihan",
    printBill: "Cetak Tagihan",
    servedBy: "Dilayani oleh",
    total: "Total",
    createdDate: "Tanggal Dibuat",
    createdTime: "Waktu yang Dibuat",
    status: "Status",
    loginSuccessful: "Login berhasil",
    pleaseWaitWhileWeConnectAccount:
      "Harap tunggu sementara kami menghubungkan akun Anda",
    connectionFailedTryAgain: "Koneksi gagal. Silakan coba lagi.",
    connectionSuccessful: "Koneksi Berhasil",
    viewDetails: "Melihat rincian",
    enable: "Memungkinkan",
    free: "Gratis",
    cost: "Biaya",
    visitWebsite: "Kunjungi Situs Web",
    pleaseUpgradeYourPlanToPro:
      "Harap Tingkatkan paket Anda ke Pro Plus untuk mengaktifkan aplikasi ini",
    connectYourBankAccount:
      "Hubungkan Rekening Bank Anda untuk dapat menerima pembayaran.",
    disable: "Cacat",
    enableApp: "Aktifkan Aplikasi",
    addNewProductToInvoice: "Tambahkan Produk Baru ke Faktur",
    toDeleteThisInvoice: "untuk menghapus Faktur ini",
    invoiceDeletedSuccessfully: "Faktur telah berhasil dihapus!",
    deleteInvoice: "Hapus Faktur",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Anda telah berhasil menambahkan pesan pembayaran Faktur!",
    pleaseEnterPaymentInstructions: "Silakan masukkan instruksi pembayaran",
    invoiceId: "ID faktur",
    paidDate: "Tanggal Pembayaran",
    reference: "Referensi",
    productAdded: "Produk ditambahkan",
    productOutOfStock: "Produk kehabisan stok. Silahkan re-stock.",
    totalInvoices: "TOTAL INVOICE",
    totalPaidInvoices: "TOTAL INVOICE DIBAYAR",
    totalUnpaidInvoices: "TOTAL INVOICE YANG BELUM DIBAYAR",
    loyaltyProgramDeleted: "Program Loyalitas Dihapus",
    thisLoyaltyProgramDeletedSuccessfully:
      "Program loyalitas ini telah berhasil dihapus",
    thisLoyaltyProgramEditedSuccessfully:
      "Program loyalitas ini telah berhasil diedit",
    loyaltyProgramAddedSuccessfully:
      "Program loyalitas telah berhasil ditambahkan",
    loyaltyProgramEdited: "Program Loyalitas Diedit",
    loyaltyProgramAdded: "Program Loyalitas Ditambahkan",
    loyaltyDetails: "Detail Loyalitas",
    doYouWantToCloseDialog: "Apakah Anda ingin menutup dialog ini?",
    pleaseEnterLoyaltyName: "Silakan masukkan nama loyalitas Anda",
    programType: "Jenis Program",
    pleaseSelectProgramType: "Silakan pilih jenis program",
    simplePoints: "Poin Sederhana",
    stampsProgram: "Program Perangko",
    threshold: "Ambang",
    pleaseEnterLoyaltyThreshold: "Silakan masukkan ambang batas loyalitas",
    reward: "Hadiah",
    pleaseEnterLoyaltyReward: "Silakan masukkan hadiah kesetiaan",
    totalUserPoints: "Total Poin Pengguna",
    totalUserStamps: "Total Stempel Pengguna",
    spendingTarget: "Target pembelanjaan",
    spendingTargetHint1:
      "Target pembelanjaan adalah berapa banyak yang harus dikeluarkan pelanggan untuk mendapatkan imbalan. 1 nilai mata uang = 1 poin.",
    spendingTargetHint2:
      "Target perangko adalah berapa banyak perangko yang harus dikumpulkan pelanggan untuk mendapatkan hadiah. misalnya. 5",
    addNewLoyaltyProgram: "Tambahkan Program Loyalitas Baru",
    addLoyaltyProgram: "Tambahkan Program Loyalitas",
    loyaltyProgramType: "Jenis Program Loyalitas",
    pleaseSelectLoyaltyProgramType: "Silakan pilih jenis program loyalitas",
    nameOfProgram: "Nama program",
    pleaseEnterProgramName: "Silakan masukkan nama program",
    whatIsTheReward: "Apa imbalannya?",
    egNextPurchaseIsFree: "Misalnya. Pembelian berikutnya gratis",
    customerName: "Nama Pelanggan",
    guestCustomer: "Pelanggan Tamu",
    orderConfirmedSuccessfully: "Pesanan berhasil dikonfirmasi",
    orderCancelledSuccessfully: "Pesanan berhasil dibatalkan",
    confirmOrder: "Konfirmasi pemesanan",
    cancelOrder: "Batalkan pesanan",
    allOrders: "Semua perintah",
    totalOrders: "JUMLAH PESANAN",
    doYouWantToAcceptOrder: "Apakah Anda ingin menerima pesanan ini?",
    doYouWantToCancelOrder: "Apakah Anda ingin membatalkan pesanan ini?",
    orderDetails: "Detail pesanan",
    orderCreatedAt: "Pesanan dibuat di",
    supplier: "pemasok",
    productName: "Nama Produk",
    quantity: "Kuantitas",
    unitPrice: "Harga satuan",
    receivedBy: "Diterima oleh",
    reportFrom: "Laporan dari",
    totalSupplies: "Jumlah Persediaan",
    allRightsReserved: "Seluruh hak cipta",
    toDeleteThisTransaction: "untuk menghapus Transaksi ini",
    transactionDeletedSuccessfully:
      "Transaksi berhasil dihapus. Stok telah dikembalikan ke inventaris.",
    deleteTransaction: "Hapus Transaksi",
    transactionDetails: "Detil transaksi",
    printReceipt: "Cetak Tanda Terima",
    channel: "Saluran",
    discount: "Diskon",
    profit: "Laba",
    discountedSales: "Diskon Penjualan",
    errorFetchingRecord: "Kesalahan Mengambil Rekaman",
    exportTransactions: "Transaksi Ekspor",
    errorFetchingSalesRecord:
      "Terjadi kesalahan saat mengambil data Penjualan untuk Ekspor.",
    totalSellingPrice: "Total Harga Jual",
    totalCostPrice: "Total Harga Biaya",
    appliedDiscount: "Diskon yang Berlaku",
    noOfItems: "Jumlah Item",
    sales: "Penjualan",
    export: "Ekspor",
    totalProfit: "Total keuntungan",
    totalBalanceInPeriod: "Total Saldo dalam Periode",
    allTimeSales: "Penjualan Sepanjang Waktu",
    records: "Catatan",
    todaysSales: "Penjualan hari ini",
    transaction: "transaksi",
    youHaveExceededTotalNumberOfProducts:
      "Anda telah melampaui jumlah total produk yang diizinkan dalam paket Anda. Tingkatkan paket Anda untuk menikmati batas yang lebih tinggi.",
    youNeedToHaveLoyaltyProgram:
      "Anda Harus memiliki Program Loyalitas untuk menggunakan fitur ini!",
    price: "Harga",
    category: "Kategori",
    stockTracking: "Pelacakan Stok",
    stockCount: "Jumlah Stok",
    taxed: "Dikenakan pajak",
    originalPrice: "Harga asli",
    costPrice: "Harga Biaya",
    unit: "Satuan",
    productImage: "gambar produk",
    taxRate: "Persentase pajak",
    taxType: "Jenis Pajak",
    trackInventory: "Lacak Inventaris",
    variants: "Varian",
    hasVariants: "Memiliki Varian",
    importProduct: "Impor Produk",
    exportProducts: "Ekspor Produk",
    addNewProduct: "Tambahkan Produk Baru",
    viewCategory: "Lihat Kategori",
    viewSuppliers: "Lihat Pemasok",
    receiveInventory: "Terima Inventaris",
    printAllProductsTag: "Cetak Semua Produk Tag",
    deleteAll: "Hapus semua",
    totalProducts: "JUMLAH PRODUK",
    youveSuccessfullyAddedANewCategory:
      "Anda telah berhasil menambahkan kategori baru",
    addNewCategory: "Tambahkan kategori baru",
    addCategory: "Tambahkan kategori",
    categoryName: "Nama Kategori",
    pleaseEnterCategoryName: "Silakan masukkan nama kategori",
    stampsTarget: "Target perangko",
    sendInventory: "Kirim Inventaris",
    productDetails: "Rincian Produk",
    youveSuccessfullyEditedThisCategory:
      "Anda telah berhasil mengedit kategori ini",
    update: "Memperbarui",
    categoryList: "Daftar Kategori",
    categories: "Kategori",
    enterQuantityToUpdate: "Masukkan kuantitas untuk diperbarui",
    inventorySentSuccessfully: "Inventaris berhasil dikirim!",
    updateInventory: "Perbarui Inventaris",
    currentQuantity: "Kuantitas saat ini",
    pleaseEnterQuantityToAdd:
      "Silakan masukkan jumlah yang ingin Anda tambahkan",
    pleaseSelectABranch: "Silakan pilih Cabang",
    searchForBranch: "Cari cabang",
    youCantSendMoreThanStock:
      "Anda tidak dapat mengirim lebih dari yang Anda miliki",
    send: "Mengirim",
    pleaseEnterQuantityToSend: "Silakan masukkan jumlah yang ingin Anda kirim",
    productNameIsRequiredOnRow: "nama produk wajib diisi di baris",
    productCategoryIsRequiredOnRow: "kategori produk wajib diisi pada baris",
    productPriceIsRequiredOnRow: "harga produk diperlukan pada baris",
    productUnitIsRequiredOnRow: "unit produk diperlukan pada baris",
    productQuantityIsRequiredOnRow: "kuantitas produk diperlukan pada baris",
    productVariantsRequireTracking: "varian produk membutuhkan pelacakan!",
    pleaseAddVariantClickButton:
      "Silakan tambahkan varian dengan mengklik tombol tambah!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Varian lebih dari kuantitas produk yang dipilih, Tolong kurangi kuantitas varian",
    productEditedSuccessfully: "Produk telah berhasil diedit!",
    fileTooLargeLessThan4Mb:
      "Ukuran file terlalu besar! Ukuran file harus kurang dari 4 MB.",
    suchVariantAlreadyExist: "Varian seperti itu sudah ada",
    addVariants: "Tambahkan Varian",
    editProduct: "Sunting Produk",
    pleaseEnterProductName: "Silakan Masukkan Nama Produk",
    pleaseEnterProductPrice: "Silahkan Masukkan Harga Produk",
    pleaseEnterProductOriginalPrice: "Silakan Masukkan Harga Asli Produk",
    productDescription: "Deskripsi Produk",
    selectProductCategory: "Pilih Kategori Produk",
    pleaseSelectProductCategory: "Silakan pilih kategori produk",
    productCostPrice: "Harga Biaya Produk",
    productSellingPrice: "Harga Jual Produk",
    productOriginalPrice: "Harga Produk Asli",
    maxFileSizeAllowedIs4Mb: "Maks. Ukuran File yang diizinkan adalah 4mb",
    productsWithPicturesArePublished:
      "Produk dengan gambar dipublikasikan di Discover untuk menerima pesanan",
    shouldThisProductBeTracked: "Haruskah produk ini dilacak?",
    pleaseSelectStockUnit: "Silahkan pilih stok unit",
    stockUnit: "Satuan Stok",
    bag: "TAS",
    bottle: "BOTOL",
    bunch: "KELOMPOK",
    can: "BISA",
    carton: "KARTON",
    crate: "peti",
    cup: "CANGKIR",
    dozen: "LUSIN",
    gigabytes: "GIGABYTE",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "MENGEMAS",
    pair: "PASANGAN",
    pieces: "BAGIAN-BAGIAN",
    plate: "PIRING",
    tonne: "TON (MT)",
    uNIT: "SATUAN",
    yard: "HALAMAN",
    pleaseEnterProductQuantity: "Silakan masukkan jumlah produk",
    productQuantity: "Kuantitas Produk",
    isThisProductTaxed: "Apakah produk ini dikenakan pajak?",
    selectTaxType: "Pilih Jenis Pajak",
    pleaseSelectTaxType: "Silakan pilih Jenis Pajak",
    progressive: "Progresif",
    proportional: "Sebanding",
    pleaseEnterProductTaxRate: "Silakan masukkan tarif pajak produk",
    doesProductHaveVariants: "Apakah produk ini memiliki varian?",
    type: "Jenis",
    value: "Nilai",
    pleaseEnterVariantType: "Silakan masukkan jenis varian",
    pleaseEnterVariantValue: "Silakan masukkan nilai varian",
    pleaseEnterVariantPrice: "Silakan masukkan harga varian",
    pleaseEnterVariantQuantity: "Silakan masukkan jumlah varian",
    productDeletedSuccessfully: "Produk telah berhasil dihapus!",
    categoryDeletedSuccessfully: "Kategori berhasil dihapus!",
    toDeleteThisProduct: "untuk menghapus Produk ini",
    invalidProductQuantity: "Kuantitas Produk Tidak Valid",
    quantityAddedIsOverStock:
      "Jumlah yang Anda tambahkan melebihi stok yang Anda miliki.",
    itemInventoryNotTracked: "Persediaan barang tidak terlacak",
    addBulkQuantity: "Tambahkan Kuantitas Massal",
    enterBulkQuantity: "Masukkan Jumlah Massal",
    pleaseEnterBulkQuantity: "Silakan masukkan Jumlah Massal",
    youveSuccessfullyAddedANewProduct:
      "Anda telah berhasil menambahkan produk baru!",
    pleaseEnterProductSellingPrice: "Silakan masukkan harga jual produk",
    doYouWantToTrackProductStock: "Apakah Anda ingin melacak stok produk?",
    sellingPrice: "Harga penjualan",
    setProductExpiryReminder: "Setel Pengingat Kedaluwarsa Produk",
    productExpiryDate: "Tanggal kedaluwarsa produk",
    startRemindingFrom: "Mulai Mengingatkan Dari",
    productSuppliesAddedSuccessfully:
      "Anda telah berhasil menambahkan persediaan produk.",
    addProductSupplies: "Tambahkan Persediaan Produk",
    pleaseSelectSupplier: "Silakan Pilih Pemasok",
    nameOfProduct: "Nama Produk",
    pleaseSelectProduct: "Silahkan Pilih Produk",
    productVariant: "Varian Produk",
    pleaseSelectAVariant: "Silahkan Pilih Varian",
    pleaseEnterUnitPrice: "Silahkan Masukkan Harga Satuan",
    businessBranch: "Cabang Bisnis",
    pleaseSelectBranch: "Silakan pilih cabang",
    youveSuccessfullyAddedANewSupplier:
      "Anda telah berhasil menambahkan pemasok baru",
    addSupplier: "Tambahkan Pemasok",
    pleaseEnterSupplierEmail: "Silakan masukkan email pemasok",
    pleaseAddADescription: "Tolong tambahkan deskripsi",
    anErrorOccuredProductsDeleted:
      "Terjadi kesalahan saat melakukan operasi. Harap perhatikan, beberapa produk mungkin telah dihapus dalam prosesnya",
    bulkDelete: "Hapus Massal",
    youAreAboutToDelete: "Anda akan menghapus",
    product: "Produk",
    isDueToRedeem: "akan ditebus",
    aReward: "hadiah",
    pleaseSelectCustomerToReeemReward:
      "Silakan pilih pelanggan untuk menebus hadiah.",
    youHaveNoLoyaltyProgramRunning:
      "Anda tidak memiliki program loyalitas aktif yang sedang berjalan",
    customerHhasNoPointsInLoyaltyProgram:
      "Pelanggan tidak memiliki poin dalam program loyalitas ini",
    proceedWithPayment: "Lanjutkan dengan Pembayaran?",
    youAreAboutToPayForTransactionUsingPoints:
      "Anda akan membayar transaksi menggunakan poin.",
    customerHas: "Pelanggan Memiliki",
    worth: "bernilai",
    andIsNotSufficientToPayForTransaction:
      "dan tidak cukup untuk membayar transaksi ini. Apakah mereka ingin menambah saldo menggunakan metode pembayaran lain?",
    addCustomerLoyalty: "Tambahkan Loyalitas Pelanggan",
    pleaseAddCustomerFirst:
      "Silakan tambahkan atau pilih pelanggan terlebih dahulu.",
    pleaseWaitWhileWeGetReady: "Harap tunggu, sementara kami bersiap-siap",
    lowStock: "Stoknya sedikit",
    pleaseEnterAmountTendered: "Silakan masukkan jumlah yang ditender",
    areYouSureToBookSaleOffline:
      "Yakin ingin memesan obral ini secara offline?",
    saleWouldBeBookedAutomatically:
      "Penjualan akan dipesan secara otomatis ketika Anda menghidupkan internet Anda",
    offlineSalesBookingCancelled: "Pemesanan penjualan offline dibatalkan",
    covid19Message:
      "COVID19: Cuci tangan dengan sabun atau Sanitize untuk menghentikan penyebaran. Tetap Aman Selalu",
    saleSuccessfullyRecorded: "Penjualan Berhasil Direkam!",
    sendReceiptToEmail: "Kirim tanda terima ke Email",
    sendThankYouSms: "Kirim SMS terima kasih",
    sendShippingDetails: "Kirim rincian Pengiriman",
    addLoyalty: "Tambahkan Loyalitas",
    searchCustomerNameOrNumber: "Cari nama atau nomor pelanggan",
    clickTheSearchCustomerBox: "Klik Kotak Cari Pelanggan dan Pindai Kartu",
    enterProductPrice: "Masukkan Harga Produk",
    enterPriceFor: "Masukkan Harga untuk",
    searchForProduct: "Cari produk",
    all: "Semua",
    backToDashboard: "Kembali ke Dasbor",
    viewDraftSales: "Lihat Draf Penjualan",
    variantSelected: "varian yang dipilih",
    variant: "varian",
    thePreviousVariantSelectionNotAvailable:
      "Pilihan varian sebelumnya tidak tersedia untuk varian baru yang dipilih berdasarkan harga, harap ubah pilihan Anda.",
    pleaseSupplyPositiveQuantityNumber: "Harap berikan nomor kuantitas postive",
    lowStockFor: "Stok rendah untuk",
    clearVariants: "Hapus varian",
    pleaseEnterQuantity: "Silakan masukkan Kuantitas",
    picture: "Gambar",
    redemptionToken: "Token Penukaran",
    token: "Token",
    totalSpent: "Total Pengeluaran",
    confirmPayment: "Konfirmasi pembayaran",
    hasPaymentBeenMade: "Apakah Pembayaran telah berhasil diproses?",
    enterTransactionReference:
      "Masukkan referensi transaksi yang Anda gunakan untuk membayar",
    pleaseSelectACustomer: "Silakan pilih pelanggan!",
    areYouSureToApplyDiscount: "Apakah Anda yakin ingin menerapkan diskon?",
    addYourBankAccountToEnableUssd:
      "Tambahkan rekening bank Anda untuk mengaktifkan transfer USSD Instan melalui uPay",
    totalAmountToPay: "Jumlah Total yang Harus Dibayar",
    doYouWantToCancelTransaction:
      "Apakah Anda ingin membatalkan transaksi ini?",
    savePrintBill: "Simpan/Cetak Tagihan",
    enterAmountCollectedForCustomer:
      "Masukkan jumlah yang dikumpulkan untuk pelanggan",
    recordSale: "Penjualan Rekam",
    checkOutWith: "Periksa dengan",
    instantTransfer: "Transfer Instan",
    dialTheUSSDCode: "Tekan kode USSD",
    pleaseSelectABank: "Silakan pilih bank",
    payWithUSSD: "Bayar Dengan USSD",
    sendBillTo: " - Kirim Tagihan ke",
    waitingForUSSDTransfer: "Menunggu Transfer USSD",
    percent: "Persen",
    applyDiscount: "Terapkan Diskon",
    thisBillHasBeenSaved: "Tagihan ini telah disimpan",
    saveDraft: "Menyimpan konsep",
    pleaseTypeANameToIdentifyCustomer:
      "Silakan ketik nama untuk mengidentifikasi pelanggan",
    paymentDetails: "Rincian Pembayaran",
    basePrice: "Harga dasar",
    staff: "Staf",
    subTotal: "SubTotal",
    durationMonths: "Durasi (bulan)",
    selectADuration: "Pilih Durasi",
    oneMonth: "1 bulan",
    twoMonths: "2 bulan",
    threeMonths: "3 bulan",
    sixMonths: "6 bulan",
    twelveMonths: "12 bulan",
    eighteenMonths: "18 bulan",
    twentyFourMonths: "24 Bulan",
    twoMonthsFree: "(2 Bulan Gratis)",
    threeMonthsFree: "(3 Bulan Gratis)",
    fiveMonthsFree: "(5 Bulan Gratis)",
    yourAccountHasBeen: "Akun Anda telah",
    renewed: "diperbarui",
    upgraded: "ditingkatkan",
    successfully: "berhasil",
    yourSubscription: "Langganan Anda",
    youAreCurrentlyEnrolledOnA: "Anda saat ini terdaftar di",
    pleaseChooseAPaymentOption: "Silakan Pilih Opsi Pembayaran",
    planRenewal: "Pembaruan Rencana",
    planUpgrade: "Peningkatan Paket",
    pleaseSelectDurationToPayFor: "Silakan Pilih Durasi yang ingin Anda bayar",
    staffAccounts: "Akun Staf",
    ecommerce: "Perdagangan elektronik",
    pleaseSelectAPlan: "Silakan pilih paket",
    includeAddons: "Sertakan Pengaya",
    viewTransactions: "Lihat Transaksi",
    customerHasNoCompletedTansactions:
      "Pelanggan belum menyelesaikan transaksi",
    branch: "Cabang",
    enterNumberOfEcommerceBranches: "Masukkan Jumlah Cabang E-niaga",
    enterNumberOfEcommerceBranchesToPay:
      "Masukkan Jumlah Cabang E-niaga yang Ingin Anda Bayar",
    ecommerceIntegration: "Integrasi E-niaga",
    enterNumberOfBranches: "Masukkan Jumlah Cabang",
    enterNumberOfAdditionalBranchesToPay:
      "Masukkan Jumlah Cabang Tambahan yang Ingin Anda Bayar",
    enterNumberOfStaffs: "Masukkan Jumlah Staf",
    enterNumberOfStaffsToPayFor: "Masukkan Jumlah Staf yang Anda Ingin Bayar",
    discountApplies: "Diskon Berlaku",
    starsOnThe: "bintang di",
    offer: "menawarkan",
    get: "Mendapatkan",
    moreStarsToRedeem: "lebih banyak bintang untuk ditebus",
    taxVat: "Pajak (PPN)",
    callCashierToCompletePayment:
      "Hubungi Kasir untuk Menyelesaikan Pembayaran",
    receipt: "Kuitansi",
    dear: "Sayang",
    thankYouForYourOrderFindGoods:
      "Terima kasih atas pesanan Anda. Silakan temukan barang-barang berikut yang disediakan, sesuai kesepakatan.",
    shippingNote: "Nota pengiriman",
    enterShippingNote: "Masukkan Catatan Pengiriman",
    shippingAddress: "Alamat Pengiriman",
    enterShippingAddress: "Masukkan alamat Pengiriman",
    wellDoneYouAreDueToRedeem:
      "Sudah selesai dilakukan dengan baik! Anda harus menebus",
    toGetYourRewardNextVisit:
      "untuk mendapatkan hadiah Anda pada kunjungan Anda berikutnya. Terima kasih",
    pointsOnThe: "Poin pada",
    morePointsToRedeem: "lebih banyak poin untuk ditukarkan",
    showCode: "Tampilkan kode",
    toGetYourRewardOnNextVisit:
      "untuk mendapatkan hadiah Anda pada kunjungan Anda berikutnya. Terima kasih",
    earn: "Menghasilkan",
    delivaryNote: "Catatan pengiriman",
    draftSales: "Draf Penjualan",
    startDate: "Mulai tanggal",
    endDate: "Tanggal akhir",
    orders: "Pesanan",
    checkout: "Periksa",
    noProductItem: "Tidak Ada Barang Produk",
    selectProductImage: "Pilih Gambar Produk",
    selectCountry: "Pilih negara",
    selectRegion: "Pilih Negara Bagian/Wilayah",
    printProductTag: "Cetak Label Produk",
    transactionReference: "Referensi transaksi",
    Cashier: "Kasir",
    Manager: "Pengelola",
    Owner: "Pemilik",
    Admin: "Admin",
    addPartners: "Tambahkan Mitra",
    addNewLoyaltyPartner: "Tambahkan Mitra Loyalitas Baru",
    pleaseEnterCompanyName: "Silakan masukkan Nama Perusahaan",
    companyName: "Nama perusahaan",
    pleaseEnterCompanyRepName: "Silakan masukkan Nama perwakilan perusahaan",
    companyRepName: "Nama Perwakilan Perusahaan",
    pleaseEnterCompanyRepEmail: "Silakan masukkan email perwakilan perusahaan",
    companyRepEmail: "Email perwakilan perusahaan",
    pleaseEnterCompanyRepPhone:
      "Silakan masukkan nomor telepon perwakilan perusahaan",
    companyRepPhone: "Nomor Telepon perwakilan perusahaan",
    addReward: "Tambahkan hadiah",
    pleaseEnterRewardName: "Silakan masukkan nama hadiah",
    rewardName: "Nama Hadiah",
    rewardQuantity: "Kuantitas Hadiah",
    rewardDescription: "Deskripsi Hadiah",
    rewardType: "Jenis Hadiah",
    pleaseEnterRewardType: "Silakan masukkan jenis hadiah",
    pleaseEnterRewardQuantity: "Silakan masukkan jumlah hadiah",
    pleaseEnterRewardDescription: "Silakan masukkan deskripsi hadiah",
    fineDining: "Restoran mewah",
    luxuryFashion: "Mode Mewah",
    hotels: "Hotel",
    travel: "Bepergian",
    foodAndBeverage: "Makanan dan minuman",
    fashion: "Mode",
    health: "Kesehatan",
    furniture: "Mebel",
    entertainment: "Hiburan",
    automobile: "Mobil",
    education: "Pendidikan",
    beautyAndSpa: "Kecantikan dan Spa",
    staycation: "Liburan di hotel",
    events: "Acara",
    trips: "Perjalanan",
    oilAndGas: "Minyak dan gas",
    laundry: "Cucian",
    partnerCategory: "Kategori Mitra",
    freeItem: "Barang Gratis",
  },
  Japanese: {
    cashier: "レジ",
    manager: "マネジャー",
    owner: "オーナー",
    online: "オンライン",
    offline: "オフライン",
    changePassword: "パスワードを変更する",
    currentPasswordMessage: "現在のパスワードを入力してください",
    passwordMessage: "パスワードを入力してください",
    currentPassword: "現在のパスワード",
    password: "パスワード",
    confirmPasswordMessage: "パスワードを確認してください！",
    confirmPassword: "パスワードを認証する",
    sendViaEmail: "メールで送信",
    sendViaWhatsapp: "WhatsApp経由で送信",
    downloadPdf: "PDFをダウンロード",
    paid: "有料",
    unpaid: "未払い",
    partial: "部分的",
    closeInvoice: "請求書を閉じますか？",
    closeInvoiceConfirmation:
      "請求書が保存されない場合があります。閉じますか？",
    yes: "はい",
    no: "番号",
    invoice: "請求書",
    wasDeducted: "差し引かれました",
    for: "にとって",
    item: "アイテム",
    addProduct: "製品を追加する",
    paymentReference: "支払いリファレンス",
    amountPaid: "払込金額",
    discountAmount: "割引額",
    amountDue: "料金",
    amount: "額",
    dueDate: "期日",
    paymentType: "払いの種類",
    card: "カード",
    cash: "現金",
    bankTransfer: "銀行振込",
    paymentMessage: "支払いメッセージ",
    description: "説明",
    sendReceipt: "領収書を送信する",
    delete: "消去",
    save: "保存する",
    resend: "再送信",
    saveAndSend: "保存する",
    invoiceSaved: "請求書が保存されました！",
    selectPaymentMethod: "お支払い方法を選択してください！",
    selectCustomer: "お客様をお選びください！",
    cartEmptyError:
      "カートリストを空にすることはできません。請求書を閉じて、カートにアイテムを追加してください。",
    subscriptionExpired:
      "サブスクリプションの有効期限が切れ、アカウントが制限されました。アカウントを更新するには、下のボタンをクリックしてください",
    renew: "更新",
    holdOn: "持続する",
    customerBank: "カスタマーバンク",
    cancel: "キャンセル",
    selectACustomer: "顧客を選択",
    invoiceSuccessfulPdfError:
      "請求書は正常に作成されましたが、PDFの生成に通常より時間がかかっています。しばらくしてからもう一度確認してください。",
    downloadingInvoice: "請求書のダウンロード",
    downloadingReceipt: "領収書のダウンロード",
    whatsappReceiptError:
      "WhatsApp経由で領収書を送信するときにエラーが発生しました。もう一度やり直してください。",
    receiptToWhatsapp: "領収書はWhatsAppに転送されました",
    thankYouForPatronage: "ご愛顧いただき、誠にありがとうございます。",
    hereIsYourReceipt: "こちらが領収書です",
    errorSendingEmailReceipt:
      "電子メールでの領収書の送信中にエラーが発生しました。再試行するか、サポートに連絡してください",
    receiptSentToEmail: "領収書がお客様のメールに送信されました",
    invoiceSentToEmail: "請求書は顧客の電子メールに送信されました",
    invoiceSuccessWhatsappError:
      "請求書は正常に作成されましたが、WhatsAppへの送信中にエラーが発生しました。請求書リストで再試行してください",
    invoiceSuccessfulEmailError:
      "請求書は正常に作成されましたが、電子メールとして送信中にエラーが発生しました。請求書リストから再試行してください",
    invoiceSentToWhatsapp: "WhatsAppに転送された請求書",
    hello: "こんにちは",
    pleaseDownloadInvoice: "請求書をダウンロードしてください",
    pleaseDownloadReceipt: "領収書をダウンロードしてください",
    from: "から",
    email: "Eメール",
    upgrade: "アップグレード",
    youAreOnFreePlan: "あなたは無料プランを利用しています。",
    clickOn: "クリック",
    yourPlanInFewSteps: " いくつかの簡単な手順でサブスクリプション。",
    to: "に",
    yourSubscriptionHasExpired:
      "サブスクリプションの有効期限が切れ、アカウントが制限されました。",
    days: "日々",
    yourSubscriptionExpiresIn: "Loystarサブスクリプションの有効期限は",
    createAcount: "アカウントを作成する",
    signIn: "ログイン",
    continue: "継続する",
    enterOtp: "OTPPINを入力してください",
    enterValidOtp: "有効なPINを入力してください",
    pin: "ピン",
    tokenSentToMail: "トークンがメールに送信されました",
    passwordResetSuccessful: "パスワードのリセットに成功しました",
    somethingWentWrong: "何かがうまくいかなかった！",
    resetPassword: "パスワードを再設定する",
    iHaveResetCode: "パスワードリセットコードを持っています",
    pleaseEnterEmail: "あなたのメールアドレスを入力してください",
    aTokenWillBeSentToEmail: "トークンがメールに送信されます",
    enterEmail: "メールアドレスを入力",
    sendinYourToken: "トークンを送信しています...",
    makeSureItMatchesPassword:
      "新しいパスワードと一致することを確認してください",
    pleaseChooseNewPassword: "新しいパスワードを選択してください",
    chooseNewPassword: "新しいパスワードを選択してください",
    enterNewPassword: "新しいパスワードを入力して確認します",
    enterTokenSent: "メールを送信したトークンを入力してください",
    resetToken: "トークンをリセット",
    resettingPassword: "パスワードをリセットしています...",
    signUp: "サインアップ",
    adminSignInWithEmail:
      " 管理者はメールでサインインし、スタッフはユーザー名でサインインします。",
    pleaseEnterEmailOrUsername:
      "メールアドレスまたはユーザー名を入力してください",
    emailOrUsername: "メールアドレスまたはユーザ名",
    pleaseEnterPassword: "パスワードを入力してください",
    createAnAccount: "アカウントを作成する",
    forgotPassword: "パスワードをお忘れですか？",
    enterPhoneNumber: "電話番号を入力してください",
    personalData: "個人データ",
    validateConfirmationCOde: "確認コードを検証する",
    pleaseEnterFirstName: "名を入力してください",
    pleaseEnterPhoneNumber: "電話番号を入力してください",
    pleaseEnterLastName: "あなたの姓を入力",
    pleaseEnterBusinessName: "あなたの会社名を入力してください",
    firstName: "ファーストネーム",
    lastName: "苗字",
    businessName: "商号",
    previous: "前",
    next: "次",
    pleaseSelectBusinessCategory: "あなたのビジネスカテゴリを選択してください",
    pleaseEnterValidEmail: "正しいメールアドレスを入力してください",
    pleaseEnterPostCode: "郵便番号を入力してください",
    postCode: "郵便番号",
    phoneNumberInUse: "この電話番号はすでに使用されています！",
    emailInUse: "このメールはすでに使用されています！",
    foodAndDrinks: "食べ物や飲み物",
    salonAndBeauty: "サロンと美容",
    fashionAndAccessories: "ファッションとアクセサリー",
    gymAndFitness: "ジムとフィットネス",
    travelAndHotel: "旅行とホテル",
    homeAndGifts: "ホームとギフト",
    washingAndCleaning: "洗濯と掃除",
    gadgetsAndElectronics: "ガジェットと電子機器",
    groceries: "食料品",
    others: "その他",
    submit: "送信",
    accountCreatedSuccessful: "あなたのアカウントは正常に作成されました。",
    pleaseEnterAddress: "住所を入力してください",
    addressLine1: "住所1",
    addressLine2: "住所2",
    choosePassword: "パスワードを選ぶ",
    passwordMustBe6Characters:
      "パスワードは少なくとも6文字でなければなりません。",
    howDidYouHearLoystar: "ロイスターについてどうやって知りましたか？",
    referralCode: "紹介コード",
    byClickingTheButton: " 下のボタンをクリックすると、",
    termsAndSevice: "条項",
    wereCreatingAccount: "アカウントを作成しています",
    proceed: "続行",
    verificationCodeMustBe6: "確認コードは6桁である必要があります",
    pleaseEnter6DigitCode: "6桁のコードを入力してください",
    enterVerificationCode: "認証コードを入力してください",
    resendToken: "トークンを再送",
    verify: "確認",
    transactions: "トランザクション",
    todaySales: "今日の売り上げ",
    salesHistory: "販売履歴",
    supplyHistory: "供給履歴",
    new: "新しい",
    invoices: "請求書",
    disbursements: "支払い",
    offlineSales: "オフライン販売",
    products: "製品",
    customers: "お客様",
    multiLevelLoyalty: "マルチレベルの忠誠心",
    loyaltyPrograms: "ロイヤルティプログラム",
    members: "メンバー",
    appStore: "App Store",
    orderMenu: "注文メニュー",
    settings: "設定",
    staffAndBranches: "スタッフと支店",
    myAccount: "マイアカウント",
    switchToSellMode: "販売モードに切り替えます",
    signOut: "サインアウト",
    getFreeSubscription: "無料サブスクリプションを取得",
    onlyNumbersAllowed: "数字のみが許可されます",
    yourAccountMustBe10Digits: "アカウント番号は10桁の番号である必要があります",
    yourBvnMustBe11: "BVNは11桁の数字である必要があります",
    pleaseSelectBank: "銀行を選択してください",
    selectYourBank: "銀行を選択してください",
    enterBankAccountNumber: "銀行口座番号を入力してください",
    enterBvnNumber: "BVNを入力してください",
    connectBankAccount: "銀行口座を接続する",
    passwordResetSuccessfulAndSignOut:
      "パスワードは正常にリセットされました。下の[サインアウト]ボタンをクリックして、再度サインインしてください",
    enterCurrentPassword: "現在のパスワードを入力してください",
    pleaseEnterCurrentPassword: "現在のパスワードを入力してください",
    phoneNumber: "電話番号",
    sex: "セックス",
    dateOfBirth: "生年月日",
    state: "州",
    country: "国",
    loyaltyId: "ポイントID",
    createdAt: "作成場所",
    noOfTransactionsMade: "取引件数",
    yourDownloadWillStart: "ダウンロードはすぐに始まります",
    exportCustomers: "顧客の輸出",
    youhaveSuccessfullyToppedUp: "SMSユニットの補充に成功しました。",
    youNowHave: "あなたは今持っています",
    smsUnits: "SMSユニット",
    enterNumberOfSmsUnits: "購入するSMSユニットの数を入力してください",
    pleaseEnterANumericValue: "数値を入力してください",
    pay: "支払い",
    accountEditedSuccessfully: "アカウントは正常に編集されました。",
    youAeCurrentlyOn: "あなたは現在",
    plan: "プラン",
    userData: "ユーザーデータ",
    businessData: "ビジネスデータ",
    businessCategory: "ビジネスカテゴリ",
    pleaseSelectCurrency: "通貨を選択してください",
    selectYourCurrency: "通貨を選択してください",
    purchaseMoreSmsUnits:
      "以下のフォームを使用して、SMSユニットをさらに購入してください",
    youHave: "あなたが持っている",
    atLeast4SmsUnitsRrequired:
      "確認には少なくとも4つのSMSユニットが必要です。補充してください。",
    pleaseVerifyYourAccountToComplyWithKyc:
      "「KnowYourCustomer」（KYC）の要件に準拠していることを確認してください。これにより、USSDまたは即時転送を介して支払いを収集し、顧客から注文を受け取り、トランザクションの決済を処理できます。ナイジェリアの商人にのみ適用されます。下のボタンを押して開始してください。",
    reConnectBankAccount: "銀行口座を再接続します",
    accountName: "アカウント名",
    accountNumber: "口座番号",
    bankName: "銀行名",
    verified: "確認済み",
    accountDetails: "アカウント詳細",
    kycBankAccount: "KYC",
    createTier: "ティアを作成する",
    fileUploadSuccessful: "ファイルが正常にアップロードされました",
    fileUploadFailed: "ファイルのアップロードに失敗しました",
    createLoyaltyProgram: "ロイヤルティプログラムを作成する",
    createLoyalty: "忠誠心を築く",
    name: "名前",
    loyaltyArtwork: "忠誠のアートワーク",
    clickToUpload: "クリックしてアップロード",
    amountToPointsRatio: "ポイントに対する金額の比率",
    points: "ポイント",
    recommendedAmountToPointRatio:
      "推奨：₦1から1ポイント。つまり、ナイラを1回使うごとに、顧客は1ポイントを獲得します",
    pleaseTypeIn: "入力してください",
    toDeleteLoyalty: "この忠誠心を削除するには",
    deleteLoyalty: "忠誠心を削除する",
    toConfirm: "確認するために",
    edit: "編集",
    pointsAwardedSuccessfully: "正常にポイントが付与されました。",
    enterPointValueToAward: "顧客に授与したいポイント値を入力してください",
    award: "賞",
    awardPointValuesToCustomer: "顧客へのアワードポイント値",
    enrollMembersToLoyalty: "メンバーを忠誠に登録する",
    awardPoints: "アワードポイント",
    enroll: "登録",
    home: "家",
    loyalty: "忠誠心",
    enrollCustomers: "顧客を登録する",
    selected: "選択済み",
    customer: "お客様",
    loyaltyActivationSuccessful: "忠誠のアクティベーションは成功しました。",
    loyaltyDeactivationSuccessful: "忠誠の非アクティブ化は成功しました。",
    viewTier: "階層を表示",
    deactivate: "非アクティブ化",
    activate: "活性化",
    actions: "行動",
    nameOfLoyalty: "忠誠の名前",
    loyaltyStatus: "忠誠のステータス忠誠のステータス",
    numberOfTiers: "ティア数",
    createdOn: "作成日",
    createATier: "ティアを作成する",
    stopCreatingTierConfirmation: "ティアの作成を停止しますか？",
    stopEditingTierConfirmation: "この層の編集を停止しますか？",
    nameOfTier: "ティアの名前",
    minimumSpendingTarget: "最小支出目標",
    maximumSpendingTarget: "最大支出目標",
    minimumSpendingTargetRequired: "最小支出目標が必要です",
    maximumSpendingTargetRequired: "最大支出目標が必要です",
    rewardSponsor: "報酬スポンサー",
    pleaseChooseARewardSponsor: "報酬スポンサーを選択してください",
    self: "自己",
    partner: "相棒",
    rewardPartner: "リワードパートナー",
    pleaseSelectRewardPartner: "報酬パートナーを選択してください",
    rewards: "報酬",
    pleaseSelectAReward: "報酬を選択してください",
    instructionsOnRedeemingReward: "顧客が報酬を引き換える方法に関する指示",
    pleaseFillInThisField: "このフィールドに入力してください！",
    toDeleteThisTier: " この層を削除するには",
    deleteTier: "ティアを削除",
    viewMembers: "メンバーを見る",
    membersEnrolled: "登録メンバー",
    instruction: "命令",
    membersIn: "のメンバー",
    availableTiersInLoyalty: "ロイヤルティプログラムで利用可能な階層",
    tiers: "ティア",
    totalTier: "合計ティア",
    availableLoyaltyProgramme: "利用可能なロイヤルティプログラム",
    totalLoyalties: "総忠誠心",
    memberDetails: "メンバーの詳細",
    nameOfCustomer: "お客様のお名前",
    address: "住所",
    allEnrolledMembers: "すべての登録メンバー",
    thisIsToWishYouHappyBirthday:
      "これはあなたにとても幸せな誕生日と豊かな人生を願うことです。ロイスターに感謝します。ハッピーセレブレーション！",
    inputAnOfferPlease: "オファーを入力してください",
    pleaseSelectTheInsertPoint:
      "メッセージ内の挿入ポイントを選択して、もう一度クリックしてください",
    birthdayOfferAndMessage: "誕生日のオファーとメッセージ",
    birthdayOffer: "誕生日のオファー",
    birthdayMessage: "誕生日メッセージ",
    noOfferFound: "オファーが見つかりません",
    settingABirthdayOffer:
      "誕生日のオファーを設定すると、顧客は誕生日にSMS経由でこのオファーを受け取ることができます",
    createOffer: "オファーを作成する",
    whatIsTheOffer: "オファーは何ですか？",
    editMessage: "メッセージの編集",
    insert: "入れる",
    theNameOfCustomerInserted: "顧客の名前がここに挿入されます",
    theBirtdayOfferInserted: "誕生日のオファーはここに挿入されます",
    youSuccessfullyAddedNewBranch: "新しいブランチが正常に追加されました。",
    addNewBranch: "新しいブランチを追加する",
    addBranch: "ブランチを追加",
    additionalBranchWillIncur: "追加のブランチが発生します",
    perBranch: "ブランチごと",
    ecommerceBranchCosts: "eコマースブランチの費用",
    pleaseEnterBranchName: "支店名を入力してください",
    pleaseEnterBranchAddress1: "支店の住所行1を入力してください",
    enterBranchAddress1: "支店の住所行1を入力します",
    enterBranchAddress2: "支店の住所行1を入力します",
    pleaseEnterBranchAddress2: "支店の住所行2を入力してください",
    enterBranchName: "支店名を入力してください",
    successfullyAddedStaff: "新しいスタッフが正常に追加されました。",
    addNewStaff: "新しいスタッフを追加する",
    addStaff: "スタッフを追加",
    additionalStaffWillIncur: "追加のスタッフが発生します",
    perStaff: "スタッフあたり。",
    pleaseEnterStaffEmail: "スタッフのメールアドレスを入力してください",
    pleaseEnterStaffUsername: "スタッフのユーザー名を入力してください",
    pleaseEnterStaffPassword: "スタッフのパスワードを入力してください",
    pleaseSelectStaffRole: "スタッフの役割を選択してください",
    selectStaffRole: "スタッフの役割を選択してください",
    enterStaffEmail: "スタッフのメールアドレスを入力してください",
    enterStaffUsername: "スタッフのユーザー名を入力してください",
    enterStaffPassword: "スタッフのパスワードを入力してください",
    spacesNotAllowedInUsername: "ユーザー名にスペースは使用できません",
    admin: "管理者",
    pleaseSelectBusinessToAttachStaff: "スタッフを付ける事業を選択してください",
    searchForBranchToAttachStaff: "スタッフをアタッチするブランチを検索",
    username: "ユーザー名",
    role: "役割",
    areYouSureToDeleteThis: "これを削除してもよろしいですか",
    branches: "ブランチ",
    upgradeYourPlan: "プランをアップグレードします。",
    add: "追加",
    addNew: "新しく追加する",
    customerWithEmailAlreadyExists:
      "メール/電話番号をお持ちのお客様は既に存在します！",
    successfullyAddedNewCustomer: "新しい顧客が正常に追加されました。",
    addCustomer: "顧客を追加",
    pleaseEnterCustomerFirstName: "お客様の名を入力してください",
    pleaseEnterCustomerLastName: "お客様の姓を入力してください",
    pleaseEnterCustomerPhoneNumber: "お客様の電話番号を入力してください",
    pleaseEnterCustomerEmail: "お客様のメールアドレスを入力してください",
    pleaseEnterCustomerAddressLine: "お客様の住所行を入力してください",
    pleaseEnterCustomerOtherAddress: "お客様の他の住所を入力してください",
    pleaseSelectCustomerGender: "お客様の性別を選択してください",
    gender: "性別",
    male: "男",
    female: "女性",
    bank: "銀行",
    selectBank: "銀行を選択",
    stateOrRegionOrProvince: "州/地域/県",
    customerNotes: "カスタマーノート",
    sendSms: "SMSを送信",
    editCustomer: "顧客の編集",
    redeemReward: "特典を利用する",
    issueLoyaltyCard: "ポイントカードを発行する",
    deleteCustomer: "顧客を削除する",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ロイヤルティメンバーシップIDが正常に割り当てられました",
    noMembershipIdAvailable:
      "利用可能なメンバーシップIDはありません。 hello@loystar.coまでお問い合わせください",
    sendEmail: "メールを送る",
    membershipPoints: "メンバーシップポイント",
    customerDetails: "顧客の詳細",
    close: "選ぶ",
    loyaltyBalance: "忠誠心のバランス",
    pointsBalance: "ポイントバランス",
    starBalance: "スターバランス",
    requiredPoints: "必要なポイント",
    requiredStars: "必要な星",
    reddemCode: "コードを引き換える",
    toDeleteThisCustomer: "この顧客を削除するには",
    customerHasBeenDeletedSuccessfully: "顧客は正常に削除されました！",
    customerWithPhoneAlreadyExists: "電話番号をお持ちのお客様は既に存在します",
    customerWasSuccessfullyEdited: "顧客は正常に編集されました",
    anErrorOccured: "エラーが発生した",
    phoneNumberIncludeCountry: "電話番号（国コードを含む）",
    yourFileQueuedForUpload:
      "ファイルはアップロードのためにキューに入れられました。数秒後にページを更新してください。",
    thereWasAnErrorPerformingOperation: "操作の実行中にエラーが発生しました！",
    pleaseSelectFile: "ファイルを選択してください！",
    oopsOnlyCsvFilesAllowed:
      "おっとっと！ CSVファイルのみが許可されます。 .csvファイルをアップロードしてください。",
    fileShouldBeSmallerThan5Mb:
      "ファイルは5MB未満である必要があります。より大きなファイルがある場合は、support @ loystar.coにメールしてください。ありがとうございました",
    customerFirstNameIsRequired: "行には顧客の名が必要です",
    customerPhoneNumberIsRequired: "行に顧客の電話番号が必要です",
    importCustomers: "顧客のインポート",
    upload: "アップロード",
    clickIconToDownloadCsv:
      "このアイコンをクリックして、CSVファイルテンプレートをダウンロードします。",
    getGoogleSheetVersion: "Googleスプレッドシートバージョンを入手する",
    clickOrDragFileToUpload:
      "ファイルをクリックするか、この領域にドラッグしてアップロードします",
    missingOrInvalidColumnHeader:
      "列ヘッダーがないか無効です。テンプレート形式に従ってください。ありがとうございました。",
    youHaveImported: "インポートしました",
    youSuccessfullyRedeemedReward: "特典の引き換えに成功しました。",
    sixDigitCode: "6桁のコード",
    pleaseEnterCustomerRewardCode: "お客様の特典コードを入力してください",
    enterRewardCode:
      "特典コードを入力してください（特典コードでは大文字と小文字が区別されます）",
    selectLoyaltyProgram: "ロイヤルティプログラムを選択する",
    stamps: "切手",
    smsUnitsLow: "SMSユニットが少ない",
    pleaseTopUpSmsUnits: "SMSユニットを補充してください",
    smsSuccessfullySentForDelivery: "SMSが正常に配信されました。",
    sendSmsTo: "SMSをに送信する",
    allCustomers: "すべてのお客様",
    unitsAvailable: "利用可能なユニット",
    pleaseTypeInTheMessage: "メッセージを入力してください",
    message: "メッセージ",
    charactersRemaining: "残りの文字",
    avoidUseOfSpecialCharacters:
      "SMSユニットを節約するために、特殊文字や絵文字の使用は避けてください。",
    note: "ノート",
    errorFetchingCustomersMultilevelDetail:
      "顧客のマルチレベル詳細の取得中にエラーが発生しました",
    search: "検索",
    reset: "リセット",
    importCustomer: "顧客のインポート",
    addNewCustomer: "新規顧客の追加",
    sendSmsBroadcast: "SMSブロードキャストを送信する",
    totalCustomers: "総顧客",
    disbursementDetails: "支払いの詳細",
    paidBy: "によって支払われました",
    disbursed: "支払い済み",
    bankAccountName: "銀行口座名",
    bankAccountNumber: "銀行の口座番号",
    transferInitiated: "転送開始",
    transferCompleted: "転送が完了しました",
    pleaseEnterAValid: "正しく入力してください",
    begin: "始める",
    end: "終わり",
    date: "日にち",
    paymentDate: "支払期日",
    selectDisbursementDuration: "支払い期間を選択します",
    totalSettled: "合計決済済み",
    totalUnsettled: "未決済合計",
    toDeleteThisSale: "このセールを削除するには",
    draftSaleDeletedSuccessfully: "ドラフトセールは正常に削除されました！",
    deleteSale: "セールを削除",
    pleaseTypeInYourTotal: "削除を確認するために合計を入力してください",
    billDetails: "請求書の詳細",
    printBill: "請求書を印刷する",
    servedBy: "提供",
    total: "合計",
    createdDate: "作成日",
    createdTime: "作成時間",
    status: "状態",
    loginSuccessful: "ログイン成功",
    pleaseWaitWhileWeConnectAccount: "アカウントに接続するまでお待ちください",
    connectionFailedTryAgain:
      "接続に失敗しました。もう一度やり直してください。",
    connectionSuccessful: "接続に成功しました",
    viewDetails: "詳細を見る",
    enable: "有効",
    free: "無料",
    cost: "費用",
    visitWebsite: "サイトを訪れる",
    pleaseUpgradeYourPlanToPro:
      "このアプリを有効にするには、プランをProPlusにアップグレードしてください",
    connectYourBankAccount:
      "銀行口座を接続して、支払いを受け取ることができるようにします。",
    disable: "無効にする",
    enableApp: "アプリを有効にする",
    addNewProductToInvoice: "請求書に新製品を追加する",
    toDeleteThisInvoice: "この請求書を削除するには",
    invoiceDeletedSuccessfully: "請求書は正常に削除されました！",
    deleteInvoice: "請求書を削除する",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "請求書支払いメッセージが正常に追加されました。",
    pleaseEnterPaymentInstructions: "お支払い方法を入力してください",
    invoiceId: "請求書ID",
    paidDate: "支払日",
    reference: "リファレンス",
    productAdded: "追加された製品",
    productOutOfStock: "商品が在庫切れです。補充してください。",
    totalInvoices: "総請求書",
    totalPaidInvoices: "支払い済みの請求書の合計",
    totalUnpaidInvoices: "未払いの請求書の合計",
    loyaltyProgramDeleted: "ロイヤルティプログラムが削除されました",
    thisLoyaltyProgramDeletedSuccessfully:
      "このロイヤルティプログラムは正常に削除されました",
    thisLoyaltyProgramEditedSuccessfully:
      "このロイヤルティプログラムは正常に編集されました",
    loyaltyProgramAddedSuccessfully:
      "ロイヤルティプログラムが正常に追加されました",
    loyaltyProgramEdited: "編集されたロイヤルティプログラム",
    loyaltyProgramAdded: "ロイヤルティプログラムが追加されました",
    loyaltyDetails: "忠誠の詳細",
    doYouWantToCloseDialog: "このダイアログを閉じますか？",
    pleaseEnterLoyaltyName: "あなたの忠誠心の名前を入力してください",
    programType: "プログラムタイプ",
    pleaseSelectProgramType: "プログラムの種類を選択してください",
    simplePoints: "簡単なポイント",
    stampsProgram: "切手プログラム",
    threshold: "しきい値",
    pleaseEnterLoyaltyThreshold: "忠誠のしきい値を入力してください",
    reward: "褒美",
    pleaseEnterLoyaltyReward: "忠誠の報酬を入力してください",
    totalUserPoints: "合計ユーザーポイント",
    totalUserStamps: "合計ユーザースタンプ",
    spendingTarget: "支出目標",
    spendingTargetHint1:
      "支出目標は、顧客が報酬を獲得するために費やさなければならない金額です。 1通貨値= 1ポイント。",
    spendingTargetHint2:
      "スタンプの目標は、顧客が報酬を獲得するために収集しなければならないスタンプの数です。例えば。 5",
    addNewLoyaltyProgram: "新しいロイヤルティプログラムを追加する",
    addLoyaltyProgram: "ロイヤルティプログラムを追加する",
    loyaltyProgramType: "ロイヤルティプログラムの種類",
    pleaseSelectLoyaltyProgramType:
      "ポイントプログラムの種類を選択してください",
    nameOfProgram: "プログラム名",
    pleaseEnterProgramName: "プログラム名を入力してください",
    whatIsTheReward: "報酬は何ですか？",
    egNextPurchaseIsFree: "例えば。次の購入は無料です",
    customerName: "顧客名",
    guestCustomer: "ゲストのお客様",
    orderConfirmedSuccessfully: "注文が正常に確認されました",
    orderCancelledSuccessfully: "注文は正常にキャンセルされました",
    confirmOrder: "注文を確認する",
    cancelOrder: "注文をキャンセルする",
    allOrders: "すべての注文",
    totalOrders: "総注文数",
    doYouWantToAcceptOrder: "この注文を受け入れますか？",
    doYouWantToCancelOrder: "この注文をキャンセルしますか？",
    orderDetails: "注文詳細",
    orderCreatedAt: "で作成された注文",
    supplier: "サプライヤー",
    productName: "商品名",
    quantity: "量",
    unitPrice: "単価",
    receivedBy: "が受信した",
    reportFrom: "からの報告",
    totalSupplies: "総供給",
    allRightsReserved: "全著作権所有",
    toDeleteThisTransaction: "このトランザクションを削除するには",
    transactionDeletedSuccessfully:
      "トランザクションは正常に削除されました。在庫が在庫に戻されました。",
    deleteTransaction: "トランザクションの削除",
    transactionDetails: "取引の詳細",
    printReceipt: "領収書を印刷する",
    channel: "チャネル",
    discount: "割引",
    profit: "利益",
    discountedSales: "割引販売",
    errorFetchingRecord: "レコードのフェッチ中にエラーが発生しました",
    exportTransactions: "輸出取引",
    errorFetchingSalesRecord:
      "エクスポートの販売レコードのフェッチ中にエラーが発生しました。",
    totalSellingPrice: "総販売価格",
    totalCostPrice: "総費用価格",
    appliedDiscount: "適用された割引",
    noOfItems: "アイテム数",
    sales: "販売",
    export: "書き出す",
    totalProfit: "利益総額",
    totalBalanceInPeriod: "期間中の合計残高",
    allTimeSales: "オールタイムセールス",
    records: "記録",
    todaysSales: "今日の売り上げ",
    transaction: "取引",
    youHaveExceededTotalNumberOfProducts:
      "プランで許可されている製品の総数を超えました。より高い制限を楽しむためにあなたの計画をアップグレードしてください。",
    youNeedToHaveLoyaltyProgram:
      "この機能を使用するには、ロイヤルティプログラムが必要です。",
    price: "価格",
    category: "カテゴリー",
    stockTracking: "在庫追跡",
    stockCount: "在庫数",
    taxed: "課税",
    originalPrice: "元値",
    costPrice: "原価",
    unit: "単位",
    productImage: "商品画像",
    taxRate: "税率",
    taxType: "税タイプ",
    trackInventory: "在庫の追跡",
    variants: "バリアント",
    hasVariants: "バリエーションがあります",
    importProduct: "製品のインポート",
    exportProducts: "製品の輸出",
    addNewProduct: "新製品の追加",
    viewCategory: "カテゴリを表示",
    viewSuppliers: "サプライヤーを見る",
    receiveInventory: "在庫を受け取る",
    printAllProductsTag: "すべての製品タグを印刷",
    deleteAll: "すべて削除",
    totalProducts: "総製品",
    youveSuccessfullyAddedANewCategory: "新しいカテゴリが正常に追加されました",
    addNewCategory: "新しいカテゴリを追加",
    addCategory: "カテゴリを追加",
    categoryName: "種別名",
    pleaseEnterCategoryName: "カテゴリ名を入力してください",
    stampsTarget: "スタンプターゲット",
    sendInventory: "インベントリを送信する",
    productDetails: "製品詳細",
    youveSuccessfullyEditedThisCategory: "このカテゴリは正常に編集されました",
    update: "アップデート",
    categoryList: "カテゴリリスト",
    categories: "カテゴリ",
    enterQuantityToUpdate: "更新する数量を入力してください",
    inventorySentSuccessfully: "在庫は正常に送信されました！",
    updateInventory: "インベントリの更新",
    currentQuantity: "現在の数量",
    pleaseEnterQuantityToAdd: "追加したい数量を入力してください",
    pleaseSelectABranch: "支店を選択してください",
    searchForBranch: "ブランチを検索する",
    youCantSendMoreThanStock: "在庫以上の発送はできません",
    send: "送信",
    pleaseEnterQuantityToSend: "送信したい数量を入力してください",
    productNameIsRequiredOnRow: "行には製品名が必要です",
    productCategoryIsRequiredOnRow: "製品カテゴリは行に必要です",
    productPriceIsRequiredOnRow: "製品価格は列に必要です",
    productUnitIsRequiredOnRow: "製品ユニットは列に必要です",
    productQuantityIsRequiredOnRow: "製品の数量は行に必要です",
    productVariantsRequireTracking: "製品のバリエーションには追跡が必要です。",
    pleaseAddVariantClickButton:
      "追加ボタンをクリックしてバリアントを追加してください！",
    totalVariantsMoreThanSelectedQuantity:
      "バリアントの合計が選択した製品の数量を超えている場合、Plsはバリアントの数量を減らします",
    productEditedSuccessfully: "製品が正常に編集されました！",
    fileTooLargeLessThan4Mb:
      "ファイルサイズが大きすぎます！ファイルサイズは4MB未満である必要があります。",
    suchVariantAlreadyExist: "そのような変種はすでに存在します",
    addVariants: "バリアントを追加",
    editProduct: "製品の編集",
    pleaseEnterProductName: "製品名を入力してください",
    pleaseEnterProductPrice: "製品価格を入力してください",
    pleaseEnterProductOriginalPrice: "製品の元の価格を入力してください",
    productDescription: "製品説明",
    selectProductCategory: "製品カテゴリを選択します",
    pleaseSelectProductCategory: "製品カテゴリを選択してください",
    productCostPrice: "製品原価",
    productSellingPrice: "製品販売価格",
    productOriginalPrice: "製品の元の価格",
    maxFileSizeAllowedIs4Mb: "最大許可されるファイルサイズは4MBです",
    productsWithPicturesArePublished:
      "写真付きの製品は、注文を受けるためにDiscoverに公開されます",
    shouldThisProductBeTracked: "この製品を追跡する必要がありますか？",
    pleaseSelectStockUnit: "在庫単位を選択してください",
    stockUnit: "ストックユニット",
    bag: "バッグ",
    bottle: "ボトル",
    bunch: "束",
    can: "できる",
    carton: "カートン",
    crate: "クレート",
    cup: "カップ",
    dozen: "ダース",
    gigabytes: "ギガバイト",
    gram: "グラム",
    kilogram: "キログラム",
    litre: "リットル",
    pack: "パック",
    pair: "ペア",
    pieces: "ピース",
    plate: "皿",
    tonne: "トン（MT）",
    uNIT: "単位",
    yard: "ヤード",
    pleaseEnterProductQuantity: "製品の数量を入力してください",
    productQuantity: "製品数量",
    isThisProductTaxed: "この商品は課税されますか？",
    selectTaxType: "税タイプを選択",
    pleaseSelectTaxType: "税タイプを選択してください",
    progressive: "プログレッシブ",
    proportional: "比例",
    pleaseEnterProductTaxRate: "製品税率を入力してください",
    doesProductHaveVariants: "この製品にはバリエーションがありますか？",
    type: "タイプ",
    value: "価値",
    pleaseEnterVariantType: "バリアントのタイプを入力してください",
    pleaseEnterVariantValue: "バリアントの値を入力してください",
    pleaseEnterVariantPrice: "バリアントの価格を入力してください",
    pleaseEnterVariantQuantity: "バリアントの数量を入力してください",
    productDeletedSuccessfully: "製品は正常に削除されました！",
    categoryDeletedSuccessfully: "カテゴリが正常に削除されました！",
    toDeleteThisProduct: "この製品を削除するには",
    invalidProductQuantity: "無効な製品数量",
    quantityAddedIsOverStock: "追加する数量は、在庫がある数量を超えています。",
    itemInventoryNotTracked: "アイテムの在庫は追跡されません",
    addBulkQuantity: "バルク数量を追加",
    enterBulkQuantity: "バルク数量を入力してください",
    pleaseEnterBulkQuantity: "バルク数量を入力してください",
    youveSuccessfullyAddedANewProduct: "新製品の追加に成功しました。",
    pleaseEnterProductSellingPrice: "商品の販売価格を入力してください",
    doYouWantToTrackProductStock: "製品在庫を追跡しますか？",
    sellingPrice: "販売価格",
    setProductExpiryReminder: "製品の有効期限のリマインダーを設定する",
    productExpiryDate: "製品の有効期限",
    startRemindingFrom: "からリマインダーを開始",
    productSuppliesAddedSuccessfully: "製品サプライが正常に追加されました。",
    addProductSupplies: "製品用品の追加",
    pleaseSelectSupplier: "サプライヤーを選択してください",
    nameOfProduct: "製品名",
    pleaseSelectProduct: "製品を選択してください",
    productVariant: "製品バリアント",
    pleaseSelectAVariant: "バリアントを選択してください",
    pleaseEnterUnitPrice: "単価を入力してください",
    businessBranch: "事業所",
    pleaseSelectBranch: "支店を選択してください",
    youveSuccessfullyAddedANewSupplier:
      "新しいサプライヤが正常に追加されました",
    addSupplier: "サプライヤーを追加",
    pleaseEnterSupplierEmail: "サプライヤーのメールアドレスを入力してください",
    pleaseAddADescription: "説明を追加してください",
    anErrorOccuredProductsDeleted:
      "操作中にエラーが発生しました。その過程で削除された製品はほとんどない可能性があることに注意してください",
    bulkDelete: "一括削除",
    youAreAboutToDelete: "削除しようとしています",
    product: "製品",
    isDueToRedeem: "償還によるものです",
    aReward: "報酬",
    pleaseSelectCustomerToReeemReward:
      "特典を利用するお客様を選択してください。",
    youHaveNoLoyaltyProgramRunning:
      "アクティブなロイヤルティプログラムが実行されていません",
    customerHhasNoPointsInLoyaltyProgram:
      "顧客はこのロイヤルティプログラムにポイントがありません",
    proceedWithPayment: "支払いを続行しますか？",
    youAreAboutToPayForTransactionUsingPoints:
      "ポイントを使用してトランザクションの支払いを行おうとしています。",
    customerHas: "お客様が持っている",
    worth: "価値",
    andIsNotSufficientToPayForTransaction:
      "そして、この取引の支払いには十分ではありません。別の支払い方法を使用して残高を追加しますか？",
    addCustomerLoyalty: "顧客の忠誠心を追加する",
    pleaseAddCustomerFirst: "最初に顧客を追加または選択してください。",
    pleaseWaitWhileWeGetReady: "準備が整うまでお待ちください",
    lowStock: "在庫が少ない",
    pleaseEnterAmountTendered: "入札金額を入力してください",
    areYouSureToBookSaleOffline:
      "このセールをオフラインで予約してもよろしいですか？",
    saleWouldBeBookedAutomatically:
      "インターネットをオンにすると、セールが自動的に予約されます",
    offlineSalesBookingCancelled: "オフライン販売予約がキャンセルされました",
    covid19Message:
      "COVID19：石鹸または消毒剤で手を洗い、広がりを止めます。常に安全を保つ",
    saleSuccessfullyRecorded: "セールが成功裏に記録されました！",
    sendReceiptToEmail: "領収書をメールで送信",
    sendThankYouSms: "ありがとうSMSを送信します",
    sendShippingDetails: "配送の詳細を送信する",
    addLoyalty: "忠誠心を追加する",
    searchCustomerNameOrNumber: "顧客の名前または番号を検索する",
    clickTheSearchCustomerBox:
      "[カスタマーボックスの検索]と[カードのスキャン]をクリックします",
    enterProductPrice: "製品価格を入力してください",
    enterPriceFor: "価格を入力してください",
    searchForProduct: "製品を検索する",
    all: "全て",
    backToDashboard: "ダッシュボードに戻る",
    viewDraftSales: "ドラフト販売を表示",
    variantSelected: "バリアントが選択されました",
    variant: "変異体",
    thePreviousVariantSelectionNotAvailable:
      "価格に基づいて選択された新しいバリアントでは、以前のバリアントの選択は利用できません。選択を変更してください。",
    pleaseSupplyPositiveQuantityNumber: "正の数量番号を入力してください",
    lowStockFor: "在庫が少ない",
    clearVariants: "明確なバリアント",
    pleaseEnterQuantity: "数量を入力してください",
    picture: "写真",
    redemptionToken: "償還トークン",
    token: "トークン",
    totalSpent: "総使用量",
    confirmPayment: "支払いの確認",
    hasPaymentBeenMade: "支払いは正常に処理されましたか？",
    enterTransactionReference: "支払った取引参照を入力します",
    pleaseSelectACustomer: "お客様をお選びください！",
    areYouSureToApplyDiscount: "割引を適用してもよろしいですか？",
    addYourBankAccountToEnableUssd:
      "銀行口座を追加して、uPayによるインスタントUSSD転送を有効にします",
    totalAmountToPay: "支払う合計金額",
    doYouWantToCancelTransaction: "このトランザクションをキャンセルしますか？",
    savePrintBill: "請求書の保存/印刷",
    enterAmountCollectedForCustomer: "顧客のために収集された金額を入力します",
    recordSale: "レコード販売",
    checkOutWith: "でチェックアウト",
    instantTransfer: "インスタント転送",
    dialTheUSSDCode: "USSDコードをダイヤルします",
    pleaseSelectABank: "銀行を選択してください",
    payWithUSSD: "USSDで支払う",
    sendBillTo: " -請求書を",
    waitingForUSSDTransfer: "USSD転送を待っています",
    percent: "パーセント",
    applyDiscount: "割引を適用する",
    thisBillHasBeenSaved: "この法案は保存されました",
    saveDraft: "下書きを保存",
    pleaseTypeANameToIdentifyCustomer:
      "顧客を識別するために名前を入力してください",
    paymentDetails: "支払詳細",
    basePrice: "本体価格",
    staff: "スタッフ",
    subTotal: "小計",
    durationMonths: "期間（月）",
    selectADuration: "期間を選択します",
    oneMonth: "1ヶ月",
    twoMonths: "2ヶ月",
    threeMonths: "3ヶ月",
    sixMonths: "6ヵ月",
    twelveMonths: "12ヶ月",
    eighteenMonths: "18ヶ月",
    twentyFourMonths: "24ヶ月",
    twoMonthsFree: "（2ヶ月無料）",
    threeMonthsFree: "（3ヶ月無料）",
    fiveMonthsFree: "（5ヶ月無料）",
    yourAccountHasBeen: "あなたのアカウントは",
    renewed: "更新されました",
    upgraded: "アップグレード",
    successfully: "正常に",
    yourSubscription: "あなたのサブスクリプション",
    youAreCurrentlyEnrolledOnA: "あなたは現在、",
    pleaseChooseAPaymentOption: "支払いオプションを選択してください",
    planRenewal: "計画の更新",
    planUpgrade: "アップグレードを計画する",
    pleaseSelectDurationToPayFor: "支払う予定の期間を選択してください",
    staffAccounts: "スタッフアカウント",
    ecommerce: "eコマース",
    pleaseSelectAPlan: "プランをお選びください",
    includeAddons: "アドオンを含める",
    viewTransactions: "トランザクションの表示",
    customerHasNoCompletedTansactions:
      "お客様はまだトランザクションを完了していません",
    branch: "ブランチ",
    enterNumberOfEcommerceBranches: "eコマースブランチの数を入力してください",
    enterNumberOfEcommerceBranchesToPay:
      "あなたが支払うつもりのeコマースブランチの数を入力してください",
    ecommerceIntegration: "eコマースの統合",
    enterNumberOfBranches: "支店数を入力してください",
    enterNumberOfAdditionalBranchesToPay:
      "あなたが支払うつもりの追加の支店の数を入力してください",
    enterNumberOfStaffs: "スタッフ数を入力してください",
    enterNumberOfStaffsToPayFor:
      "あなたが支払うつもりのスタッフの数を入力してください",
    discountApplies: "割引が適用されます",
    starsOnThe: "上の星",
    offer: "オファー",
    get: "取得する",
    moreStarsToRedeem: "償還するより多くの星",
    taxVat: "税金（VAT）",
    callCashierToCompletePayment: "キャッシャーに電話して支払いを完了する",
    receipt: "レシート",
    dear: "親愛なる",
    thankYouForYourOrderFindGoods:
      "注文いただきありがとうございます。合意された通り、供給された以下の商品を見つけてください。",
    shippingNote: "配送メモ",
    enterShippingNote: "出荷メモを入力してください",
    shippingAddress: "お届け先の住所",
    enterShippingAddress: "配送先住所を入力してください",
    wellDoneYouAreDueToRedeem: "素晴らしい！あなたは償還する予定です",
    toGetYourRewardNextVisit:
      "あなたの次の訪問であなたの報酬を得るために。ありがとうございました",
    pointsOnThe: "上のポイント",
    morePointsToRedeem: "引き換えるポイントが増える",
    showCode: "コードを表示",
    toGetYourRewardOnNextVisit:
      "あなたの次の訪問であなたの報酬を得るために。ありがとうございました",
    earn: "得る",
    delivaryNote: "納品書",
    draftSales: "ドラフト販売",
    startDate: "開始日",
    endDate: "終了日",
    orders: "注文",
    checkout: "チェックアウト",
    noProductItem: "商品アイテムなし",
    selectProductImage: "商品画像を選択",
    selectCountry: "国を選択",
    selectRegion: "州/地域を選択",
    printProductTag: "製品タグを印刷する",
    transactionReference: "トランザクションリファレンス",
    Cashier: "レジ",
    Manager: "マネジャー",
    Owner: "オーナー",
    Admin: "管理者",
    addPartners: "パートナーを追加",
    addNewLoyaltyPartner: "新しいロイヤルティパートナーを追加する",
    pleaseEnterCompanyName: "会社名を入力してください",
    companyName: "会社名",
    pleaseEnterCompanyRepName: "会社の代表者名を入力してください",
    companyRepName: "会社代表者名",
    pleaseEnterCompanyRepEmail:
      "会社の代表者のメールアドレスを入力してください",
    companyRepEmail: "会社の担当者のメール",
    pleaseEnterCompanyRepPhone: "会社の代表者の電話番号を入力してください",
    companyRepPhone: "会社の担当者の電話番号",
    addReward: "報酬を追加する",
    pleaseEnterRewardName: "報酬名を入力してください",
    rewardName: "報酬名",
    rewardQuantity: "報酬量",
    rewardDescription: "報酬の説明",
    rewardType: "報酬タイプ",
    pleaseEnterRewardType: "報酬タイプを入力してください",
    pleaseEnterRewardQuantity: "報酬の量を入力してください",
    pleaseEnterRewardDescription: "報酬の説明を入力してください",
    fineDining: "高級料理",
    luxuryFashion: "ラグジュアリーファッション",
    hotels: "ホテル",
    travel: "旅行",
    foodAndBeverage: "食品および飲料",
    fashion: "ファッション",
    health: "健康",
    furniture: "家具",
    entertainment: "エンターテイメント",
    automobile: "自動車",
    education: "教育",
    beautyAndSpa: "ビューティー＆スパ",
    staycation: "ステイケーション",
    events: "イベント",
    trips: "旅行",
    oilAndGas: "オイルとガス",
    laundry: "ランドリー",
    partnerCategory: "パートナーカテゴリ",
    freeItem: "無料アイテム",
  },
  Italian: {
    cashier: "cassiere",
    manager: "manager",
    owner: "proprietario",
    online: "in linea",
    offline: "disconnesso",
    changePassword: "Cambia la password",
    currentPasswordMessage: "Per favore inserisci la tua password attuale",
    passwordMessage: "per favore inserisci LA TUA password",
    currentPassword: "password attuale",
    password: "parola d'ordine",
    confirmPasswordMessage: "Si prega di confermare la password!",
    confirmPassword: "conferma password",
    sendViaEmail: "Invia tramite e-mail",
    sendViaWhatsapp: "Invia tramite WhatsApp",
    downloadPdf: "Scarica il pdf",
    paid: "pagato",
    unpaid: "non pagato",
    partial: "parziale",
    closeInvoice: "Vuoi chiudere la fattura?",
    closeInvoiceConfirmation:
      "La fattura potrebbe non essere salvata. Vuoi chiudere?",
    yes: "sì",
    no: "no",
    invoice: "Fattura",
    wasDeducted: "è stato detratto",
    for: "per",
    item: "Articolo",
    addProduct: "Aggiungi prodotto",
    paymentReference: "Referenza di pagamento",
    amountPaid: "Importo pagato",
    discountAmount: "Totale sconto",
    amountDue: "Importo dovuto",
    amount: "Quantità",
    dueDate: "Scadenza",
    paymentType: "Modalità di pagamento",
    card: "Carta",
    cash: "Contanti",
    bankTransfer: "Trasferimento bancario",
    paymentMessage: "Messaggio di pagamento",
    description: "Descrizione",
    sendReceipt: "Invia ricevuta",
    delete: "Elimina",
    save: "Salva",
    resend: "Re-invia",
    saveAndSend: "Salva",
    invoiceSaved: "Fattura salvata!",
    selectPaymentMethod: "Seleziona un metodo di pagamento!",
    selectCustomer: "Si prega di selezionare un cliente!",
    cartEmptyError:
      "L'elenco del carrello non può essere vuoto, chiudi la fattura e aggiungi l'articolo al carrello!",
    subscriptionExpired:
      "Il tuo abbonamento è scaduto e il tuo account è ora limitato. Clicca sul pulsante qui sotto per rinnovare il tuo account",
    renew: "Rinnovare",
    holdOn: "Aspettare",
    customerBank: "Banca cliente",
    cancel: "Annulla",
    selectACustomer: "Seleziona cliente",
    invoiceSuccessfulPdfError:
      "Fattura creata correttamente ma la generazione di PDF richiede più tempo del solito. Si prega di ricontrollare a breve.",
    downloadingInvoice: "Download della fattura",
    downloadingReceipt: "Download della ricevuta",
    whatsappReceiptError:
      "Si è verificato un errore durante l'invio della ricevuta tramite WhatsApp. Riprova.",
    receiptToWhatsapp: "Ricevuta inoltrata a WhatsApp",
    thankYouForPatronage: "Grazie per il vostro patrocinio",
    hereIsYourReceipt: "Ecco la ricevuta di pagamento",
    errorSendingEmailReceipt:
      "Si è verificato un errore durante l'invio della ricevuta via e-mail, riprovare o contattare l'assistenza",
    receiptSentToEmail: "La ricevuta è stata inviata all'e-mail del cliente",
    invoiceSentToEmail: "La fattura è stata inviata all'e-mail del cliente",
    invoiceSuccessWhatsappError:
      "Fattura creata correttamente ma si è verificato un errore durante l'invio a WhatsApp. Riprova nell'elenco delle fatture",
    invoiceSuccessfulEmailError:
      "Fattura creata correttamente ma si è verificato un errore durante l'invio come e-mail. Riprova dall'elenco delle fatture",
    invoiceSentToWhatsapp: "Fattura inoltrata a WhatsApp",
    hello: "Ciao",
    pleaseDownloadInvoice: "Si prega di scaricare la fattura",
    pleaseDownloadReceipt: "Si prega di scaricare la ricevuta",
    from: "a partire dal",
    email: "E-mail",
    upgrade: "Aggiornamento",
    youAreOnFreePlan: "Sei su un piano gratuito.",
    clickOn: "Clicca su",
    yourPlanInFewSteps: " il tuo abbonamento in pochi rapidi passaggi.",
    to: "a",
    yourSubscriptionHasExpired:
      "Il tuo abbonamento è scaduto e il tuo account è ora limitato.",
    days: "giorni",
    yourSubscriptionExpiresIn: "Il tuo abbonamento Loystar scade tra",
    createAcount: "Creare un account",
    signIn: "Registrazione",
    continue: "Continua",
    enterOtp: "Inserisci il PIN OTP",
    enterValidOtp: "Si prega di inserire un PIN valido",
    pin: "SPILLO",
    tokenSentToMail: "È stato inviato un token alla tua email",
    passwordResetSuccessful:
      "La reimpostazione della password è andata a buon fine",
    somethingWentWrong: "Qualcosa è andato storto!",
    resetPassword: "Resetta la password",
    iHaveResetCode: "Ho un codice di reimpostazione della password",
    pleaseEnterEmail: "Per favore inserisci la tua email",
    aTokenWillBeSentToEmail: "Verrà inviato un token alla tua email",
    enterEmail: "Inserisci la tua email",
    sendinYourToken: "Invio del tuo token...",
    makeSureItMatchesPassword:
      "Assicurati che corrisponda alla tua nuova password",
    pleaseChooseNewPassword: "Scegli una nuova password",
    chooseNewPassword: "Scegli una nuova password",
    enterNewPassword: "Inserisci la tua nuova password per confermare",
    enterTokenSent: "Inserisci il token a cui è stata inviata la tua posta",
    resetToken: "Reimposta token",
    resettingPassword: "Reimpostazione della password...",
    signUp: "Iscriviti",
    adminSignInWithEmail:
      " L'amministratore accede con l'e-mail mentre lo staff accede con il nome utente.",
    pleaseEnterEmailOrUsername:
      "Per favore inserisci la tua email o il tuo nome utente",
    emailOrUsername: "Email o nome utente",
    pleaseEnterPassword: "Per favore, inserisci la password",
    createAnAccount: "Creare un account",
    forgotPassword: "Ha dimenticato la password?",
    enterPhoneNumber: "Inserisci il numero di telefono",
    personalData: "Dati personali",
    validateConfirmationCOde: "Convalida codice di conferma",
    pleaseEnterFirstName: "Per favore immettere il proprio Nome",
    pleaseEnterPhoneNumber: "Per favore inserisci il tuo numero di telefono",
    pleaseEnterLastName: "Per favore inserisci il tuo cognome",
    pleaseEnterBusinessName: "Per favore inserisci il nome della tua attività",
    firstName: "Nome di battesimo",
    lastName: "Cognome",
    businessName: "Nome commerciale",
    previous: "Precedente",
    next: "Prossimo",
    pleaseSelectBusinessCategory:
      "Seleziona la categoria della tua attività commerciale",
    pleaseEnterValidEmail: "Inserisci una email valida",
    pleaseEnterPostCode: "Si prega di inserire il codice postale",
    postCode: "Codice postale",
    phoneNumberInUse: "Questo numero di telefono è già in uso!",
    emailInUse: "Questa email è già in uso!",
    foodAndDrinks: "Cibo e bevande",
    salonAndBeauty: "Salone e bellezza",
    fashionAndAccessories: "Moda e accessori",
    gymAndFitness: "Palestra e Fitness",
    travelAndHotel: "Viaggi e Hotel",
    homeAndGifts: "Casa e regali",
    washingAndCleaning: "Lavaggio e pulizia",
    gadgetsAndElectronics: "Gadget ed elettronica",
    groceries: "Drogheria",
    others: "Altri",
    submit: "Invia",
    accountCreatedSuccessful: "Il tuo account è stato creato con successo.",
    pleaseEnterAddress: "Per favore inserisci il tuo indirizzo",
    addressLine1: "Indirizzo Linea 1",
    addressLine2: "indirizzo 2",
    choosePassword: "Scegli la password",
    passwordMustBe6Characters: "La password deve essere di almeno 6 caratteri.",
    howDidYouHearLoystar: "Come hai saputo di Loystar?",
    referralCode: "codice di riferimento",
    byClickingTheButton: " Facendo clic sul pulsante qui sotto, accetti il",
    termsAndSevice: "Termini",
    wereCreatingAccount: "Stiamo creando il tuo account",
    proceed: "Procedere",
    verificationCodeMustBe6: "Il codice di verifica deve essere di 6 cifre",
    pleaseEnter6DigitCode: "Si prega di inserire il codice a 6 cifre",
    enterVerificationCode: "Inserisci il codice di verifica",
    resendToken: "Reinvia token",
    verify: "Verificare",
    transactions: "Transazioni",
    todaySales: "Saldi di oggi",
    salesHistory: "Storia delle vendite",
    supplyHistory: "Storia delle forniture",
    new: "Nuovo",
    invoices: "Fatture",
    disbursements: "esborsi",
    offlineSales: "Vendite offline",
    products: "Prodotti",
    customers: "Clienti",
    multiLevelLoyalty: "Fedeltà multilivello",
    loyaltyPrograms: "Programmi fedeltà",
    members: "Membri",
    appStore: "App Store",
    orderMenu: "Ordina Menu",
    settings: "Impostazioni",
    staffAndBranches: "Personale e rami",
    myAccount: "Il mio account",
    switchToSellMode: "Passa alla modalità di vendita",
    signOut: "Disconnessione",
    getFreeSubscription: "Ottieni l'abbonamento gratuito",
    onlyNumbersAllowed: "Sono ammessi solo numeri",
    yourAccountMustBe10Digits:
      "il tuo numero di conto deve essere un numero di 10 cifre",
    yourBvnMustBe11: "il tuo BVN deve essere un numero di 11 cifre",
    pleaseSelectBank: "Seleziona la tua banca",
    selectYourBank: "Seleziona la tua banca",
    enterBankAccountNumber: "Inserisci il numero di conto bancario",
    enterBvnNumber: "Inserisci il tuo BVN",
    connectBankAccount: "Collega conto bancario",
    passwordResetSuccessfulAndSignOut:
      "La tua password è stata reimpostata con successo. Fare clic sul pulsante Esci in basso per accedere di nuovo",
    enterCurrentPassword: "Immetti la password corrente",
    pleaseEnterCurrentPassword: "Si prega di inserire la password corrente",
    phoneNumber: "Numero di telefono",
    sex: "Sesso",
    dateOfBirth: "Data di nascita",
    state: "Stato",
    country: "Nazione",
    loyaltyId: "ID fedeltà",
    createdAt: "Creato a",
    noOfTransactionsMade: "N. di transazioni effettuate",
    yourDownloadWillStart: "Il download inizierà tra un momento",
    exportCustomers: "Esporta clienti",
    youhaveSuccessfullyToppedUp:
      "Hai ricaricato con successo le tue unità SMS.",
    youNowHave: "ora hai",
    smsUnits: "Unità SMS",
    enterNumberOfSmsUnits:
      "Inserisci il numero di unità SMS che intendi acquistare",
    pleaseEnterANumericValue: "Si prega di inserire un valore numerico",
    pay: "Paga",
    accountEditedSuccessfully:
      "Il tuo account è stato modificato con successo.",
    youAeCurrentlyOn: "Al momento sei attivo",
    plan: "Piano",
    userData: "Dati utente",
    businessData: "DATI AZIENDALI",
    businessCategory: "Categoria aziendale",
    pleaseSelectCurrency: "Seleziona la tua valuta",
    selectYourCurrency: "Seleziona la tua valuta",
    purchaseMoreSmsUnits:
      "Acquista più unità SMS utilizzando il modulo sottostante",
    youHave: "Hai",
    atLeast4SmsUnitsRrequired:
      "Sono necessarie almeno 4 unità SMS per la verifica, si prega di ricaricare!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verifica che il tuo conto bancario sia conforme ai requisiti "Know Your Customer" (KYC). Ciò ti consentirà di riscuotere pagamenti tramite USSD o trasferimento istantaneo, ricevere ordini dai clienti ed elaborare il regolamento delle transazioni. Applicabile solo ai commercianti nigeriani. Si prega di premere il pulsante qui sotto per iniziare.',
    reConnectBankAccount: "Ricollegare il conto bancario",
    accountName: "Nome utente",
    accountNumber: "Numero di conto",
    bankName: "Nome della banca",
    verified: "verificato",
    accountDetails: "Dettagli account",
    kycBankAccount: "KYC",
    createTier: "Crea livello",
    fileUploadSuccessful: "il file è stato caricato con successo",
    fileUploadFailed: "caricamento del file non riuscito",
    createLoyaltyProgram: "Crea un programma fedeltà",
    createLoyalty: "Crea fedeltà",
    name: "Nome",
    loyaltyArtwork: "Opere d'arte fedeltà",
    clickToUpload: "Clicca per caricare",
    amountToPointsRatio: "Rapporto tra importo e punti",
    points: "Punti",
    recommendedAmountToPointRatio:
      "Raccomandato: ₦1 a 1 punto. cioè per ogni 1 Naira spesa, i tuoi clienti ottengono 1 punto",
    pleaseTypeIn: "Si prega di digitare",
    toDeleteLoyalty: "per cancellare questa fedeltà",
    deleteLoyalty: "Elimina fedeltà",
    toConfirm: "per confermare",
    edit: "Modificare",
    pointsAwardedSuccessfully: "Punti assegnati con successo.",
    enterPointValueToAward:
      "Inserisci il valore in punti che vorresti assegnare ai clienti",
    award: "Premio",
    awardPointValuesToCustomer: "Valore in punti premio per il cliente",
    enrollMembersToLoyalty: "Iscrivi i membri a Loyalty",
    awardPoints: "Punti premio",
    enroll: "Iscriversi",
    home: "Casa",
    loyalty: "Lealtà",
    enrollCustomers: "Iscrivi clienti",
    selected: "Selezionato",
    customer: "Cliente",
    loyaltyActivationSuccessful:
      "L'attivazione della fedeltà è andata a buon fine.",
    loyaltyDeactivationSuccessful:
      "La disattivazione della fedeltà è riuscita.",
    viewTier: "Visualizza livello",
    deactivate: "Disattivare",
    activate: "Attivare",
    actions: "Azioni",
    nameOfLoyalty: "Nome della fedeltà",
    loyaltyStatus: "Stato fedeltàStato fedeltà",
    numberOfTiers: "Numero di livelli",
    createdOn: "Creato",
    createATier: "Crea un livello",
    stopCreatingTierConfirmation:
      "Vuoi interrompere la creazione di un livello?",
    stopEditingTierConfirmation:
      "Vuoi interrompere la modifica di questo livello?",
    nameOfTier: "Nome del livello",
    minimumSpendingTarget: "Obiettivo di spesa minima",
    maximumSpendingTarget: "Obiettivo di spesa massima",
    minimumSpendingTargetRequired: "è richiesto un obiettivo di spesa minima",
    maximumSpendingTargetRequired: "è richiesto un obiettivo di spesa massimo",
    rewardSponsor: "Sponsor della ricompensa",
    pleaseChooseARewardSponsor: "Scegli uno sponsor premio",
    self: "Se stesso",
    partner: "Compagno",
    rewardPartner: "Partner premio",
    pleaseSelectRewardPartner: "Seleziona il tuo partner premio",
    rewards: "Ricompense",
    pleaseSelectAReward: "Seleziona un premio",
    instructionsOnRedeemingReward:
      "Istruzioni su come il cliente dovrebbe riscattare il premio",
    pleaseFillInThisField: "Si prega di compilare questo campo!",
    toDeleteThisTier: " per eliminare questo livello",
    deleteTier: "Elimina livello",
    viewMembers: "Visualizza membri",
    membersEnrolled: "Membri iscritti",
    instruction: "Istruzione",
    membersIn: "Membri in",
    availableTiersInLoyalty: "Livelli disponibili nel programma fedeltà",
    tiers: "livelli",
    totalTier: "LIVELLO TOTALE",
    availableLoyaltyProgramme: "Programma fedeltà disponibile",
    totalLoyalties: "FEDELTÀ TOTALI",
    memberDetails: "Dettagli del membro",
    nameOfCustomer: "Nome del cliente",
    address: "Indirizzo",
    allEnrolledMembers: "Tutti i membri iscritti",
    thisIsToWishYouHappyBirthday:
      "Questo è per augurarti un felice compleanno e una vita prospera. Grazie per tutto quello che sei a Loyster. Buone Feste!",
    inputAnOfferPlease: "Inserisci un'offerta per favore",
    pleaseSelectTheInsertPoint:
      "Si prega di selezionare il punto di inserimento nel messaggio e fare nuovamente clic",
    birthdayOfferAndMessage: "Offerta di compleanno e messaggio",
    birthdayOffer: "Offerta di compleanno",
    birthdayMessage: "Messaggio di compleanno",
    noOfferFound: "Nessuna offerta trovata",
    settingABirthdayOffer:
      "L'impostazione di un'offerta di compleanno consente ai clienti di ricevere questa offerta tramite SMS il giorno del loro compleanno",
    createOffer: "Crea offerta",
    whatIsTheOffer: "Qual è l'offerta?",
    editMessage: "Modifica messaggio",
    insert: "Inserire",
    theNameOfCustomerInserted: "Il nome del cliente verrà inserito qui",
    theBirtdayOfferInserted: "L'offerta di compleanno verrà inserita qui",
    youSuccessfullyAddedNewBranch: "Hai aggiunto con successo un nuovo ramo!",
    addNewBranch: "Aggiungi nuovo ramo",
    addBranch: "Aggiungi ramo",
    additionalBranchWillIncur: "Sarebbe necessario un ramo aggiuntivo",
    perBranch: "per ramo",
    ecommerceBranchCosts: "Costi filiale e-commerce",
    pleaseEnterBranchName: "Inserisci il nome della filiale",
    pleaseEnterBranchAddress1:
      "Si prega di inserire l'indirizzo della filiale nella riga 1",
    enterBranchAddress1: "Inserisci l'indirizzo della filiale nella riga 1",
    enterBranchAddress2: "Inserisci l'indirizzo della filiale nella riga 1",
    pleaseEnterBranchAddress2:
      "Si prega di inserire l'indirizzo della filiale nella riga 2",
    enterBranchName: "Inserisci il nome della filiale",
    successfullyAddedStaff: "Hai aggiunto con successo un nuovo staff!",
    addNewStaff: "Aggiungi nuovo personale",
    addStaff: "Aggiungi personale",
    additionalStaffWillIncur: "Il personale aggiuntivo incorrerebbe",
    perStaff: "per personale.",
    pleaseEnterStaffEmail: "Inserisci l'email del personale",
    pleaseEnterStaffUsername: "Inserisci il nome utente dello staff",
    pleaseEnterStaffPassword: "Si prega di inserire la password del personale",
    pleaseSelectStaffRole: "Seleziona il ruolo dello staff",
    selectStaffRole: "Seleziona il ruolo dello staff",
    enterStaffEmail: "Inserisci l'email dello staff",
    enterStaffUsername: "Inserisci il nome utente dello staff",
    enterStaffPassword: "Inserisci la password dello staff",
    spacesNotAllowedInUsername: "spazi non consentiti in username",
    admin: "amministratore",
    pleaseSelectBusinessToAttachStaff:
      "Seleziona l'attività da allegare al personale",
    searchForBranchToAttachStaff: "Cerca filiale per allegare personale",
    username: "Nome utente",
    role: "Ruolo",
    areYouSureToDeleteThis: "Sei sicuro di eliminarlo?",
    branches: "Rami",
    upgradeYourPlan: "Aggiorna il tuo piano.",
    add: "INSERISCI",
    addNew: "Aggiungere nuova",
    customerWithEmailAlreadyExists:
      "Esiste già un cliente con e-mail/numero di telefono!",
    successfullyAddedNewCustomer: "Hai aggiunto con successo un nuovo cliente!",
    addCustomer: "Aggiungi cliente",
    pleaseEnterCustomerFirstName: "Si prega di inserire il nome del cliente",
    pleaseEnterCustomerLastName: "Si prega di inserire il cognome del cliente",
    pleaseEnterCustomerPhoneNumber:
      "Inserisci il numero di telefono del cliente",
    pleaseEnterCustomerEmail: "Inserisci l'email del cliente",
    pleaseEnterCustomerAddressLine:
      "Si prega di inserire la riga dell'indirizzo del cliente",
    pleaseEnterCustomerOtherAddress: "Inserisci l'altro indirizzo del cliente",
    pleaseSelectCustomerGender: "Seleziona il sesso del cliente",
    gender: "Genere",
    male: "Maschio",
    female: "Femmina",
    bank: "Banca",
    selectBank: "Seleziona banca",
    stateOrRegionOrProvince: "Stato/Regione/Provincia",
    customerNotes: "Note del cliente",
    sendSms: "Inviare SMS",
    editCustomer: "Modifica cliente",
    redeemReward: "Riscatta il premio",
    issueLoyaltyCard: "Emetti carta fedeltà",
    deleteCustomer: "Elimina cliente",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Hai assegnato con successo un ID abbonamento fedeltà",
    noMembershipIdAvailable:
      "Nessun ID abbonamento disponibile. Per favore contattaci su hello@loystar.co",
    sendEmail: "Invia una email",
    membershipPoints: "Punti abbonamento",
    customerDetails: "Dettagli cliente",
    close: "Chiudere",
    loyaltyBalance: "Saldo fedeltà",
    pointsBalance: "Saldo punti",
    starBalance: "Saldo delle stelle",
    requiredPoints: "Punti richiesti",
    requiredStars: "Stelle richieste",
    reddemCode: "Riscatta codice",
    toDeleteThisCustomer: "per eliminare questo cliente",
    customerHasBeenDeletedSuccessfully:
      "Il cliente è stato eliminato con successo!",
    customerWithPhoneAlreadyExists:
      "Esiste già un cliente con numero di telefono",
    customerWasSuccessfullyEdited: "Il cliente è stato modificato con successo",
    anErrorOccured: "Si è verificato un errore",
    phoneNumberIncludeCountry:
      "Numero di telefono (includi prefisso internazionale)",
    yourFileQueuedForUpload:
      "Il tuo file è stato messo in coda per il caricamento. Aggiorna la pagina dopo alcuni secondi.",
    thereWasAnErrorPerformingOperation:
      "Si è verificato un errore durante l'esecuzione dell'operazione!",
    pleaseSelectFile: "Si prega di selezionare un file!",
    oopsOnlyCsvFilesAllowed:
      "Ops! Sono consentiti solo file CSV. Carica un file .csv.",
    fileShouldBeSmallerThan5Mb:
      "Il file deve essere inferiore a 5 MB. Se hai un file più grande, invia un'e-mail a support@loystar.co. Grazie",
    customerFirstNameIsRequired: "il nome del cliente è richiesto sulla riga",
    customerPhoneNumberIsRequired:
      "il numero di telefono del cliente è richiesto sulla riga",
    importCustomers: "Importa clienti",
    upload: "Caricamento",
    clickIconToDownloadCsv:
      "Fare clic su questa icona per scaricare il modello di file CSV.",
    getGoogleSheetVersion: "Ottieni la versione del foglio di Google",
    clickOrDragFileToUpload:
      "Fare clic o trascinare il file in quest'area per caricarlo",
    missingOrInvalidColumnHeader:
      "Intestazione di colonna mancante o non valida. Si prega di seguire il formato del modello. Grazie.",
    youHaveImported: "Hai importato",
    youSuccessfullyRedeemedReward: "Hai riscattato con successo il tuo premio!",
    sixDigitCode: "Codice a sei cifre",
    pleaseEnterCustomerRewardCode: "Inserisci il codice premio del cliente",
    enterRewardCode:
      "Inserisci il codice del premio. (Il codice del premio fa distinzione tra maiuscole e minuscole)",
    selectLoyaltyProgram: "Seleziona il programma fedeltà",
    stamps: "Francobolli",
    smsUnitsLow: "Unità SMS basse",
    pleaseTopUpSmsUnits: "Si prega di ricaricare le unità SMS",
    smsSuccessfullySentForDelivery: "SMS inviato con successo per la consegna!",
    sendSmsTo: "Invia SMS a",
    allCustomers: "Tutti i clienti",
    unitsAvailable: "Unità disponibili",
    pleaseTypeInTheMessage: "Si prega di digitare il messaggio",
    message: "Messaggio",
    charactersRemaining: "caratteri rimanenti",
    avoidUseOfSpecialCharacters:
      "Evita l'uso di caratteri speciali ed emoji per conservare le unità SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Errore durante il recupero dei dettagli multilivello dei clienti",
    search: "Ricerca",
    reset: "Ripristina",
    importCustomer: "Importa cliente",
    addNewCustomer: "Aggiungi nuovo cliente",
    sendSmsBroadcast: "Invia trasmissione SMS",
    totalCustomers: "CLIENTI TOTALI",
    disbursementDetails: "Dettagli sull'esborso",
    paidBy: "Pagato da",
    disbursed: "erogato",
    bankAccountName: "Nome del conto bancario",
    bankAccountNumber: "Numero di conto bancario",
    transferInitiated: "Trasferimento avviato",
    transferCompleted: "Trasferimento completato",
    pleaseEnterAValid: "Si prega di inserire un valido",
    begin: "inizio",
    end: "fine",
    date: "Data",
    paymentDate: "Data di pagamento",
    selectDisbursementDuration: "Seleziona la durata dell'esborso",
    totalSettled: "Totale saldato",
    totalUnsettled: "Totale instabile",
    toDeleteThisSale: "per eliminare questa vendita",
    draftSaleDeletedSuccessfully:
      "La bozza di vendita è stata eliminata con successo!",
    deleteSale: "Elimina vendita",
    pleaseTypeInYourTotal:
      "Si prega di digitare il totale per confermare l'eliminazione",
    billDetails: "Dettagli fattura",
    printBill: "Stampa fattura",
    servedBy: "Servito da",
    total: "Totale",
    createdDate: "Data di Creazione",
    createdTime: "Tempo di creazione",
    status: "Stato",
    loginSuccessful: "Accesso riuscito",
    pleaseWaitWhileWeConnectAccount:
      "Attendi mentre colleghiamo il tuo account",
    connectionFailedTryAgain: "Connessione fallita. Per favore riprova.",
    connectionSuccessful: "Connessione riuscita",
    viewDetails: "Visualizza dettagli",
    enable: "Abilitare",
    free: "Gratuito",
    cost: "Costo",
    visitWebsite: "Visita il sito web",
    pleaseUpgradeYourPlanToPro:
      "Aggiorna il tuo piano a Pro Plus per abilitare questa app",
    connectYourBankAccount:
      "Collega il tuo Conto Bancario per poter ricevere i pagamenti.",
    disable: "disattivare",
    enableApp: "Abilita app",
    addNewProductToInvoice: "Aggiungi nuovo prodotto alla fattura",
    toDeleteThisInvoice: "per eliminare questa fattura",
    invoiceDeletedSuccessfully: "La fattura è stata eliminata con successo!",
    deleteInvoice: "Elimina fattura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Hai aggiunto con successo un messaggio di pagamento della fattura!",
    pleaseEnterPaymentInstructions:
      "Si prega di inserire le istruzioni di pagamento",
    invoiceId: "codice di identificazione della fattura",
    paidDate: "Data di pagamento",
    reference: "Riferimento",
    productAdded: "Prodotto aggiunto",
    productOutOfStock: "Prodotto esaurito. Si prega di rifornire.",
    totalInvoices: "TOTALE FATTURE",
    totalPaidInvoices: "TOTALE FATTURE PAGATE",
    totalUnpaidInvoices: "TOTALE FATTURE NON PAGATE",
    loyaltyProgramDeleted: "Programma fedeltà eliminato",
    thisLoyaltyProgramDeletedSuccessfully:
      "Questo programma fedeltà è stato eliminato con successo",
    thisLoyaltyProgramEditedSuccessfully:
      "Questo programma fedeltà è stato modificato con successo",
    loyaltyProgramAddedSuccessfully:
      "Il programma fedeltà è stato aggiunto con successo",
    loyaltyProgramEdited: "Programma fedeltà modificato",
    loyaltyProgramAdded: "Programma fedeltà aggiunto",
    loyaltyDetails: "Dettagli fedeltà",
    doYouWantToCloseDialog: "Vuoi chiudere questa finestra di dialogo?",
    pleaseEnterLoyaltyName: "Inserisci il nome della tua fedeltà",
    programType: "Tipo di programma",
    pleaseSelectProgramType: "Seleziona un tipo di programma",
    simplePoints: "Punti semplici",
    stampsProgram: "Programma francobolli",
    threshold: "Soglia",
    pleaseEnterLoyaltyThreshold: "Inserisci la soglia fedeltà",
    reward: "Ricompensa",
    pleaseEnterLoyaltyReward: "Inserisci il premio della fedeltà",
    totalUserPoints: "Punti utente totali",
    totalUserStamps: "Timbri utente totali",
    spendingTarget: "Obiettivo di spesa",
    spendingTargetHint1:
      "L'obiettivo di spesa è quanto un cliente deve spendere per guadagnare il premio. 1 valore di valuta = 1 punto.",
    spendingTargetHint2:
      "L'obiettivo dei francobolli è il numero di francobolli che un cliente deve raccogliere per ottenere il premio. per esempio. 5",
    addNewLoyaltyProgram: "Aggiungi nuovo programma fedeltà",
    addLoyaltyProgram: "Aggiungi programma fedeltà",
    loyaltyProgramType: "Tipo di programma fedeltà",
    pleaseSelectLoyaltyProgramType: "Seleziona il tipo di programma fedeltà",
    nameOfProgram: "Nome del programma",
    pleaseEnterProgramName: "Si prega di inserire il nome del programma",
    whatIsTheReward: "Qual è la ricompensa?",
    egNextPurchaseIsFree: "Per esempio. Il prossimo acquisto è gratuito",
    customerName: "Nome del cliente",
    guestCustomer: "Cliente Ospite",
    orderConfirmedSuccessfully: "Ordine confermato con successo",
    orderCancelledSuccessfully: "Ordine annullato con successo",
    confirmOrder: "Confermare l'ordine",
    cancelOrder: "Annulla Ordine",
    allOrders: "Tutti gli ordini",
    totalOrders: "ORDINI TOTALI",
    doYouWantToAcceptOrder: "Vuoi accettare questo ordine?",
    doYouWantToCancelOrder: "Vuoi annullare questo ordine?",
    orderDetails: "Dettagli dell'ordine",
    orderCreatedAt: "Ordine creato su",
    supplier: "Fornitore",
    productName: "nome del prodotto",
    quantity: "Quantità",
    unitPrice: "Prezzo unitario",
    receivedBy: "Ricevuto da",
    reportFrom: "Segnala da",
    totalSupplies: "Rifornimenti totali",
    allRightsReserved: "Tutti i diritti riservati",
    toDeleteThisTransaction: "per eliminare questa transazione",
    transactionDeletedSuccessfully:
      "La transazione è stata eliminata con successo. Lo stock è stato restituito all'inventario.",
    deleteTransaction: "Elimina transazione",
    transactionDetails: "Dettagli di Transazione",
    printReceipt: "Stampa ricevuta",
    channel: "Canale",
    discount: "Sconto",
    profit: "Profitto",
    discountedSales: "Vendite scontate",
    errorFetchingRecord: "Errore durante il recupero del record",
    exportTransactions: "Transazioni di esportazione",
    errorFetchingSalesRecord:
      "Errore durante il recupero del record delle vendite per l'esportazione.",
    totalSellingPrice: "Prezzo di vendita totale",
    totalCostPrice: "Prezzo di costo totale",
    appliedDiscount: "Sconto applicato",
    noOfItems: "Numero di articoli",
    sales: "Saldi",
    export: "Esportare",
    totalProfit: "Profitto totale",
    totalBalanceInPeriod: "Saldo totale nel periodo",
    allTimeSales: "Saldi di tutti i tempi",
    records: "record",
    todaysSales: "Saldi di oggi",
    transaction: "transazione",
    youHaveExceededTotalNumberOfProducts:
      "Hai superato il numero totale di prodotti consentiti nel tuo piano. Aggiorna il tuo piano per usufruire di un limite più alto.",
    youNeedToHaveLoyaltyProgram:
      "Devi avere un programma fedeltà per utilizzare questa funzione!",
    price: "Prezzo",
    category: "Categoria",
    stockTracking: "Monitoraggio delle scorte",
    stockCount: "Inventario",
    taxed: "tassato",
    originalPrice: "Prezzo originale",
    costPrice: "Prezzo di costo",
    unit: "Unità",
    productImage: "Immagine del prodotto",
    taxRate: "Aliquota fiscale",
    taxType: "Tipo di imposta",
    trackInventory: "Tieni traccia dell'inventario",
    variants: "varianti",
    hasVariants: "Ha varianti",
    importProduct: "Importa prodotto",
    exportProducts: "Esporta prodotti",
    addNewProduct: "Aggiungi nuovo prodotto",
    viewCategory: "Visualizza categoria",
    viewSuppliers: "Visualizza fornitori",
    receiveInventory: "Ricevi inventario",
    printAllProductsTag: "Stampa tutti i prodotti tag",
    deleteAll: "Cancella tutto",
    totalProducts: "PRODOTTI TOTALI",
    youveSuccessfullyAddedANewCategory:
      "Hai aggiunto con successo una nuova categoria",
    addNewCategory: "Aggiungi nuova categoria",
    addCategory: "Aggiungi categoria",
    categoryName: "Nome della categoria",
    pleaseEnterCategoryName: "Si prega di inserire il nome della categoria",
    stampsTarget: "Timbri bersaglio",
    sendInventory: "Invia inventario",
    productDetails: "Dettagli del prodotto",
    youveSuccessfullyEditedThisCategory:
      "Hai modificato con successo questa categoria",
    update: "Aggiornare",
    categoryList: "Elenco delle categorie",
    categories: "Categorie",
    enterQuantityToUpdate: "Inserisci una quantità da aggiornare",
    inventorySentSuccessfully: "L'inventario è stato inviato con successo!",
    updateInventory: "Aggiorna inventario",
    currentQuantity: "Quantità attuale",
    pleaseEnterQuantityToAdd:
      "Si prega di inserire la quantità che si desidera aggiungere",
    pleaseSelectABranch: "Seleziona una filiale",
    searchForBranch: "Cerca filiale",
    youCantSendMoreThanStock:
      "Non puoi inviare più di quello che hai in magazzino",
    send: "Spedire",
    pleaseEnterQuantityToSend:
      "Si prega di inserire la quantità che si desidera inviare",
    productNameIsRequiredOnRow: "il nome del prodotto è richiesto sulla riga",
    productCategoryIsRequiredOnRow:
      "la categoria del prodotto è obbligatoria sulla riga",
    productPriceIsRequiredOnRow:
      "il prezzo del prodotto è richiesto sulla riga",
    productUnitIsRequiredOnRow: "l'unità di prodotto è richiesta sulla riga",
    productQuantityIsRequiredOnRow:
      "la quantità di prodotto è richiesta sulla riga",
    productVariantsRequireTracking:
      "le varianti del prodotto richiedono il monitoraggio!",
    pleaseAddVariantClickButton:
      "Aggiungi una variante facendo clic sul pulsante Aggiungi!",
    totalVariantsMoreThanSelectedQuantity:
      "Le varianti totali sono più della quantità di prodotto selezionata, si prega di ridurre la quantità di varianti",
    productEditedSuccessfully: "Il prodotto è stato modificato con successo!",
    fileTooLargeLessThan4Mb:
      "La dimensione del file è troppo grande! La dimensione del file deve essere inferiore a 4 MB.",
    suchVariantAlreadyExist: "Tale variante esiste già",
    addVariants: "Aggiungi varianti",
    editProduct: "Modifica prodotto",
    pleaseEnterProductName: "Si prega di inserire il nome del prodotto",
    pleaseEnterProductPrice: "Si prega di inserire il prezzo del prodotto",
    pleaseEnterProductOriginalPrice:
      "Si prega di inserire il prezzo originale del prodotto",
    productDescription: "Descrizione del prodotto",
    selectProductCategory: "Seleziona la categoria del prodotto",
    pleaseSelectProductCategory:
      "Si prega di selezionare la categoria del prodotto",
    productCostPrice: "Prezzo di costo del prodotto",
    productSellingPrice: "Prezzo di vendita del prodotto",
    productOriginalPrice: "Prezzo originale del prodotto",
    maxFileSizeAllowedIs4Mb: "massimo La dimensione del file consentita è 4mb",
    productsWithPicturesArePublished:
      "I prodotti con le immagini vengono pubblicati su Scopri per ricevere gli ordini",
    shouldThisProductBeTracked: "Questo prodotto deve essere tracciato?",
    pleaseSelectStockUnit: "Si prega di selezionare l'unità di scorta",
    stockUnit: "Unità di scorta",
    bag: "BORSA",
    bottle: "BOTTIGLIA",
    bunch: "GRAPPOLO",
    can: "POTERE",
    carton: "CARTONE",
    crate: "CASSA",
    cup: "TAZZA",
    dozen: "DOZZINA",
    gigabytes: "GIGABYTE",
    gram: "GRAMMO",
    kilogram: "CHILOGRAMMO",
    litre: "LITRO",
    pack: "PACCHETTO",
    pair: "COPPIA",
    pieces: "PEZZI",
    plate: "PIATTO",
    tonne: "TONNELLATE (MT)",
    uNIT: "UNITÀ",
    yard: "IARDA",
    pleaseEnterProductQuantity: "Si prega di inserire la quantità del prodotto",
    productQuantity: "Quantità del prodotto",
    isThisProductTaxed: "Questo prodotto è tassato?",
    selectTaxType: "Seleziona il tipo di imposta",
    pleaseSelectTaxType: "Si prega di selezionare il tipo di imposta",
    progressive: "Progressivo",
    proportional: "Proporzionale",
    pleaseEnterProductTaxRate:
      "Si prega di inserire l'aliquota fiscale del prodotto",
    doesProductHaveVariants: "Questo prodotto ha varianti?",
    type: "Tipo",
    value: "Valore",
    pleaseEnterVariantType: "Inserisci il tipo di variante",
    pleaseEnterVariantValue: "Inserisci il valore della variante",
    pleaseEnterVariantPrice: "Inserisci il prezzo della variante",
    pleaseEnterVariantQuantity: "Inserisci la quantità della variante",
    productDeletedSuccessfully: "Il prodotto è stato eliminato con successo!",
    categoryDeletedSuccessfully: "La categoria è stata eliminata con successo!",
    toDeleteThisProduct: "per eliminare questo prodotto",
    invalidProductQuantity: "Quantità di prodotto non valida",
    quantityAddedIsOverStock:
      "La quantità che stai aggiungendo è superiore a quella che hai in magazzino.",
    itemInventoryNotTracked: "L'inventario degli articoli non viene tracciato",
    addBulkQuantity: "Aggiungi quantità all'ingrosso",
    enterBulkQuantity: "Inserisci la quantità all'ingrosso",
    pleaseEnterBulkQuantity: "Si prega di inserire la quantità all'ingrosso",
    youveSuccessfullyAddedANewProduct:
      "Hai aggiunto con successo un nuovo prodotto!",
    pleaseEnterProductSellingPrice:
      "Si prega di inserire il prezzo di vendita del prodotto",
    doYouWantToTrackProductStock: "Vuoi monitorare lo stock di prodotti?",
    sellingPrice: "Prezzo di vendita",
    setProductExpiryReminder: "Imposta promemoria scadenza prodotto",
    productExpiryDate: "Data di scadenza del prodotto",
    startRemindingFrom: "Inizia a ricordare da",
    productSuppliesAddedSuccessfully:
      "Hai aggiunto correttamente le forniture del prodotto.",
    addProductSupplies: "Aggiungi le forniture del prodotto",
    pleaseSelectSupplier: "Si prega di selezionare il fornitore",
    nameOfProduct: "Nome del prodotto",
    pleaseSelectProduct: "Si prega di selezionare il prodotto",
    productVariant: "Variante del prodotto",
    pleaseSelectAVariant: "Si prega di selezionare una variante",
    pleaseEnterUnitPrice: "Si prega di inserire il prezzo unitario",
    businessBranch: "Ramo d'affari",
    pleaseSelectBranch: "Si prega di selezionare il ramo",
    youveSuccessfullyAddedANewSupplier:
      "Hai aggiunto con successo un nuovo fornitore",
    addSupplier: "Aggiungi fornitore",
    pleaseEnterSupplierEmail: "Inserisci l'email del fornitore",
    pleaseAddADescription: "Si prega di aggiungere una descrizione",
    anErrorOccuredProductsDeleted:
      "Si è verificato un errore durante l'esecuzione dell'operazione. Tieni presente che alcuni prodotti potrebbero essere stati eliminati durante il processo",
    bulkDelete: "Elimina in blocco",
    youAreAboutToDelete: "Stai per eliminare",
    product: "Prodotto",
    isDueToRedeem: "è dovuto al riscatto",
    aReward: "una ricompensa",
    pleaseSelectCustomerToReeemReward:
      "Seleziona un cliente per riscattare il premio.",
    youHaveNoLoyaltyProgramRunning:
      "Non hai un programma fedeltà attivo in esecuzione",
    customerHhasNoPointsInLoyaltyProgram:
      "Il cliente non ha punti in questo programma fedeltà",
    proceedWithPayment: "Procedere con il pagamento?",
    youAreAboutToPayForTransactionUsingPoints:
      "Stai per pagare la transazione utilizzando i punti.",
    customerHas: "Il cliente ha",
    worth: "di valore",
    andIsNotSufficientToPayForTransaction:
      "e non è sufficiente per pagare questa transazione. Vorrebbero aggiungere il saldo utilizzando un altro metodo di pagamento?",
    addCustomerLoyalty: "Aggiungi la fedeltà del cliente",
    pleaseAddCustomerFirst:
      "Si prega di aggiungere o selezionare prima un cliente.",
    pleaseWaitWhileWeGetReady: "Per favore aspetta, mentre ci prepariamo",
    lowStock: "Scorte scarse",
    pleaseEnterAmountTendered: "Si prega di inserire l'importo offerto",
    areYouSureToBookSaleOffline:
      "Sei sicuro di voler prenotare questa vendita offline?",
    saleWouldBeBookedAutomatically:
      "La vendita verrebbe prenotata automaticamente quando accendi Internet",
    offlineSalesBookingCancelled: "Prenotazione vendite offline annullata",
    covid19Message:
      "COVID19: lavarsi le mani con sapone o igienizzare per fermare la diffusione. Stai sempre al sicuro",
    saleSuccessfullyRecorded: "Vendita registrata con successo!",
    sendReceiptToEmail: "Invia ricevuta a e-mail",
    sendThankYouSms: "Invia SMS di ringraziamento",
    sendShippingDetails: "Invia dettagli di spedizione",
    addLoyalty: "Aggiungi fedeltà",
    searchCustomerNameOrNumber: "Cerca il nome o il numero del cliente",
    clickTheSearchCustomerBox:
      "Fare clic sulla casella Cerca cliente e scansionare la scheda",
    enterProductPrice: "Inserisci il prezzo del prodotto",
    enterPriceFor: "Inserisci il prezzo per",
    searchForProduct: "Cerca prodotto",
    all: "Tutto",
    backToDashboard: "Torna alla dashboard",
    viewDraftSales: "Visualizza bozze di vendita",
    variantSelected: "variante selezionata",
    variant: "variante",
    thePreviousVariantSelectionNotAvailable:
      "La selezione della variante precedente non è disponibile per la nuova variante selezionata in base al prezzo, modifica la selezione.",
    pleaseSupplyPositiveQuantityNumber:
      "Si prega di fornire un numero di quantità postivo",
    lowStockFor: "Scorte scarse per",
    clearVariants: "Varianti chiare",
    pleaseEnterQuantity: "Si prega di inserire la quantità",
    picture: "Foto",
    redemptionToken: "Gettone di riscatto",
    token: "Gettone",
    totalSpent: "Spesa totale",
    confirmPayment: "Conferma il pagamento",
    hasPaymentBeenMade: "Il pagamento è stato elaborato con successo?",
    enterTransactionReference:
      "Inserisci il riferimento della transazione con cui hai pagato",
    pleaseSelectACustomer: "Seleziona un cliente!",
    areYouSureToApplyDiscount: "Sei sicuro di voler applicare lo sconto?",
    addYourBankAccountToEnableUssd:
      "Aggiungi il tuo conto bancario per abilitare il trasferimento USSD istantaneo tramite uPay",
    totalAmountToPay: "Importo totale da pagare",
    doYouWantToCancelTransaction: "Vuoi annullare questa transazione?",
    savePrintBill: "Salva/Stampa fattura",
    enterAmountCollectedForCustomer:
      "Inserisci l'importo raccolto per il cliente",
    recordSale: "Vendita di dischi",
    checkOutWith: "Check out con",
    instantTransfer: "Trasferimento istantaneo",
    dialTheUSSDCode: "Componi il codice USSD",
    pleaseSelectABank: "Seleziona una banca",
    payWithUSSD: "Paga con USSD",
    sendBillTo: " - Invia fattura a",
    waitingForUSSDTransfer: "In attesa di trasferimento USSD",
    percent: "Per cento",
    applyDiscount: "Applicare lo sconto",
    thisBillHasBeenSaved: "Questa fattura è stata salvata",
    saveDraft: "Salva la bozza",
    pleaseTypeANameToIdentifyCustomer:
      "Si prega di digitare un nome per identificare il cliente",
    paymentDetails: "Dettagli di pagamento",
    basePrice: "Prezzo base",
    staff: "Personale",
    subTotal: "Totale parziale",
    durationMonths: "Durata (mesi)",
    selectADuration: "Seleziona una durata",
    oneMonth: "1 mese",
    twoMonths: "Due mesi",
    threeMonths: "3 mesi",
    sixMonths: "6 mesi",
    twelveMonths: "12 mesi",
    eighteenMonths: "18 mesi",
    twentyFourMonths: "24 mesi",
    twoMonthsFree: "(2 mesi gratuiti)",
    threeMonthsFree: "(3 mesi gratuiti)",
    fiveMonthsFree: "(5 mesi gratuiti)",
    yourAccountHasBeen: "Il tuo account è stato",
    renewed: "rinnovato",
    upgraded: "aggiornato",
    successfully: "con successo",
    yourSubscription: "Il tuo abbonamento",
    youAreCurrentlyEnrolledOnA: "Attualmente sei iscritto a a",
    pleaseChooseAPaymentOption: "Si prega di scegliere un'opzione di pagamento",
    planRenewal: "Rinnovo del piano",
    planUpgrade: "Aggiornamento del piano",
    pleaseSelectDurationToPayFor: "Seleziona la durata per cui intendi pagare",
    staffAccounts: "Conti del personale",
    ecommerce: "E-commerce",
    pleaseSelectAPlan: "Seleziona un piano",
    includeAddons: "Includi componenti aggiuntivi",
    viewTransactions: "Visualizza le transazioni",
    customerHasNoCompletedTansactions:
      "Il cliente non ha ancora completato le transazioni",
    branch: "Ramo",
    enterNumberOfEcommerceBranches: "Inserisci il numero di filiali e-commerce",
    enterNumberOfEcommerceBranchesToPay:
      "Inserisci il numero di filiali e-commerce che intendi pagare",
    ecommerceIntegration: "Integrazione e-commerce",
    enterNumberOfBranches: "Inserisci il numero di filiali",
    enterNumberOfAdditionalBranchesToPay:
      "Inserisci il numero di filiali aggiuntive che intendi pagare",
    enterNumberOfStaffs: "Inserisci il numero di aste",
    enterNumberOfStaffsToPayFor:
      "Inserisci il numero di dipendenti che intendi pagare",
    discountApplies: "Si applica lo sconto",
    starsOnThe: "stelle sul",
    offer: "offerta",
    get: "Ottenere",
    moreStarsToRedeem: "più stelle da riscattare",
    taxVat: "Imposta (IVA)",
    callCashierToCompletePayment:
      "Chiama il cassiere per completare il pagamento",
    receipt: "Ricevuta",
    dear: "Cara",
    thankYouForYourOrderFindGoods:
      "Grazie per il vostro ordine. Si prega di trovare i seguenti beni forniti, come concordato.",
    shippingNote: "Nota di spedizione",
    enterShippingNote: "Inserisci nota di spedizione",
    shippingAddress: "Indirizzo di spedizione",
    enterShippingAddress: "Inserisci l'indirizzo di spedizione",
    wellDoneYouAreDueToRedeem: "Molto bene! Devi riscattare",
    toGetYourRewardNextVisit:
      "per ricevere la tua ricompensa alla tua prossima visita. Grazie",
    pointsOnThe: "Punti sul",
    morePointsToRedeem: "più punti da riscattare",
    showCode: "Mostra codice",
    toGetYourRewardOnNextVisit:
      "per ricevere la tua ricompensa alla tua prossima visita. Grazie",
    earn: "Guadagnare",
    delivaryNote: "Nota di consegna",
    draftSales: "Bozze di vendita",
    startDate: "Data d'inizio",
    endDate: "Data di fine",
    orders: "Ordini",
    checkout: "guardare",
    noProductItem: "Nessun articolo del prodotto",
    selectProductImage: "Seleziona l'immagine del prodotto",
    selectCountry: "Seleziona il paese",
    selectRegion: "Seleziona stato/regione",
    printProductTag: "Stampa il tag del prodotto",
    transactionReference: "Riferimento transazione",
    Cashier: "Cassiere",
    Manager: "Manager",
    Owner: "Proprietario",
    Admin: "amministratore",
    addPartners: "Aggiungi partner",
    addNewLoyaltyPartner: "Aggiungi nuovo partner fedeltà",
    pleaseEnterCompanyName: "Si prega di inserire il nome dell'azienda",
    companyName: "Nome della ditta",
    pleaseEnterCompanyRepName:
      "Si prega di inserire il nome del rappresentante dell'azienda",
    companyRepName: "Nome del rappresentante dell'azienda",
    pleaseEnterCompanyRepEmail:
      "Si prega di inserire l'e-mail del rappresentante dell'azienda",
    companyRepEmail: "Email del rappresentante dell'azienda",
    pleaseEnterCompanyRepPhone:
      "Si prega di inserire il numero di telefono del rappresentante dell'azienda",
    companyRepPhone: "Numero di telefono del rappresentante dell'azienda",
    addReward: "Aggiungi ricompensa",
    pleaseEnterRewardName: "Inserisci il nome del premio",
    rewardName: "Nome ricompensa",
    rewardQuantity: "Quantità ricompensa",
    rewardDescription: "Descrizione del premio",
    rewardType: "Tipo di ricompensa",
    pleaseEnterRewardType: "Si prega di inserire il tipo di ricompensa",
    pleaseEnterRewardQuantity: "Si prega di inserire la quantità del premio",
    pleaseEnterRewardDescription: "Inserisci la descrizione del premio",
    fineDining: "Cucina raffinata",
    luxuryFashion: "Moda di lusso",
    hotels: "Alberghi",
    travel: "Viaggiare",
    foodAndBeverage: "Cibo e bevande",
    fashion: "Moda",
    health: "Salute",
    furniture: "Arredamento",
    entertainment: "Divertimento",
    automobile: "automobile",
    education: "Formazione scolastica",
    beautyAndSpa: "Bellezza e Spa",
    staycation: "soggiorno",
    events: "Eventi",
    trips: "viaggi",
    oilAndGas: "Olio e gas",
    laundry: "Lavanderia",
    partnerCategory: "Categoria partner",
    freeItem: "Articolo gratuito",
  },
  Kannada: {
    cashier: "ಕ್ಯಾಷಿಯರ್",
    manager: "ವ್ಯವಸ್ಥಾಪಕ",
    owner: "ಮಾಲೀಕ",
    online: "ಆನ್ಲೈನ್",
    offline: "ಆಫ್‌ಲೈನ್",
    changePassword: "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
    currentPasswordMessage: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    passwordMessage: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಗುಪ್ತಪದವನ್ನು ನಮೂದಿಸಿ",
    currentPassword: "ಪ್ರಸ್ತುತ ಗುಪ್ತಪದ",
    password: "ಗುಪ್ತಪದ",
    confirmPasswordMessage: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ದೃ confirmೀಕರಿಸಿ!",
    confirmPassword: "ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ",
    sendViaEmail: "ಇಮೇಲ್ ಮೂಲಕ ಕಳುಹಿಸಿ",
    sendViaWhatsapp: "WhatsApp ಮೂಲಕ ಕಳುಹಿಸಿ",
    downloadPdf: "ಪಿಡಿಎಫ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    paid: "ಪಾವತಿಸಲಾಗಿದೆ",
    unpaid: "ಪಾವತಿಸದ",
    partial: "ಭಾಗಶಃ",
    closeInvoice: "ನೀವು ಸರಕುಪಟ್ಟಿ ಮುಚ್ಚಲು ಬಯಸುವಿರಾ?",
    closeInvoiceConfirmation:
      "ಸರಕುಪಟ್ಟಿ ಉಳಿಸದೇ ಇರಬಹುದು. ನೀವು ಮುಚ್ಚಲು ಬಯಸುವಿರಾ?",
    yes: "ಹೌದು",
    no: "ಇಲ್ಲ",
    invoice: "ಸರಕುಪಟ್ಟಿ",
    wasDeducted: "ಕಡಿತಗೊಳಿಸಲಾಯಿತು",
    for: "ಫಾರ್",
    item: "ಐಟಂ",
    addProduct: "ಉತ್ಪನ್ನ ಸೇರಿಸಿ",
    paymentReference: "ಪಾವತಿ ಉಲ್ಲೇಖ",
    amountPaid: "ಪಾವತಿಸಿದ ಮೊತ್ತ",
    discountAmount: "ರಿಯಾಯಿತಿ ಮೊತ್ತ",
    amountDue: "ಬಾಕಿ",
    amount: "ಮೊತ್ತ",
    dueDate: "ಅಂತಿಮ ದಿನಾಂಕ",
    paymentType: "ಪಾವತಿ ವಿಧಾನ",
    card: "ಕಾರ್ಡ್",
    cash: "ನಗದು",
    bankTransfer: "ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ",
    paymentMessage: "ಪಾವತಿ ಸಂದೇಶ",
    description: "ವಿವರಣೆ",
    sendReceipt: "ರಶೀದಿ ಕಳುಹಿಸಿ",
    delete: "ಅಳಿಸು",
    save: "ಉಳಿಸಿ",
    resend: "ಮರು ಕಳುಹಿಸಿ",
    saveAndSend: "ಉಳಿಸಿ",
    invoiceSaved: "ಸರಕುಪಟ್ಟಿ ಉಳಿಸಲಾಗಿದೆ!",
    selectPaymentMethod: "ದಯವಿಟ್ಟು ಪಾವತಿ ವಿಧಾನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ!",
    selectCustomer: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ!",
    cartEmptyError:
      "ಕಾರ್ಟ್ ಲಿಸ್ಟ್ ಖಾಲಿಯಾಗಿರಬಾರದು, ಇನ್ವಾಯ್ಸ್ ಮುಚ್ಚಿ ಮತ್ತು ಕಾರ್ಟ್ ಗೆ ಐಟಂ ಸೇರಿಸಿ!",
    subscriptionExpired:
      "ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆ ಅವಧಿ ಮುಗಿದಿದೆ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಯು ಈಗ ಸೀಮಿತವಾಗಿದೆ. ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನವೀಕರಿಸಲು ಕೆಳಗಿನ ಬಟನ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
    renew: "ನವೀಕರಿಸಿ",
    holdOn: "ಸ್ವಲ್ಪ ತಡಿ",
    customerBank: "ಗ್ರಾಹಕ ಬ್ಯಾಂಕ್",
    cancel: "ರದ್ದುಗೊಳಿಸಿ",
    selectACustomer: "ಗ್ರಾಹಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    invoiceSuccessfulPdfError:
      "ಇನ್‌ವಾಯ್ಸ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ ಆದರೆ ಪಿಡಿಎಫ್ ಉತ್ಪಾದನೆಯು ಸಾಮಾನ್ಯಕ್ಕಿಂತ ಹೆಚ್ಚು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದೆ. ದಯವಿಟ್ಟು ಶೀಘ್ರದಲ್ಲೇ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ.",
    downloadingInvoice: "ಇನ್‌ವಾಯ್ಸ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
    downloadingReceipt: "ರಶೀದಿಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ",
    whatsappReceiptError:
      "WhatsApp ಮೂಲಕ ರಸೀದಿಯನ್ನು ಕಳುಹಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    receiptToWhatsapp: "WhatsApp ಗೆ ರಶೀದಿ ರವಾನಿಸಲಾಗಿದೆ",
    thankYouForPatronage: "ನಿಮ್ಮ ಪ್ರೋತ್ಸಾಹಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು",
    hereIsYourReceipt: "ನಿಮ್ಮ ಪಾವತಿಯ ರಸೀದಿ ಇಲ್ಲಿದೆ",
    errorSendingEmailReceipt:
      "ಇ-ಮೇಲ್ ಮೂಲಕ ರಶೀದಿಯನ್ನು ಕಳುಹಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ, ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ ಅಥವಾ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ",
    receiptSentToEmail: "ಗ್ರಾಹಕರ ಇಮೇಲ್‌ಗೆ ರಶೀದಿಯನ್ನು ಕಳುಹಿಸಲಾಗಿದೆ",
    invoiceSentToEmail: "ಗ್ರಾಹಕರ ಇಮೇಲ್‌ಗೆ ಸರಕುಪಟ್ಟಿ ಕಳುಹಿಸಲಾಗಿದೆ",
    invoiceSuccessWhatsappError:
      "ಇನ್‌ವಾಯ್ಸ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ ಆದರೆ ವಾಟ್ಸಾಪ್‌ಗೆ ಕಳುಹಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ. ಸರಕುಪಟ್ಟಿ ಪಟ್ಟಿಯಲ್ಲಿ ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    invoiceSuccessfulEmailError:
      "ಇನ್‌ವಾಯ್ಸ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ ಆದರೆ ಇ-ಮೇಲ್ ಆಗಿ ಕಳುಹಿಸುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ಇನ್ವಾಯ್ಸ್ ಪಟ್ಟಿಯಿಂದ ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
    invoiceSentToWhatsapp: "ಇನ್‌ವಾಯ್ಸ್ ಅನ್ನು WhatsApp ಗೆ ಫಾರ್ವರ್ಡ್ ಮಾಡಲಾಗಿದೆ",
    hello: "ನಮಸ್ಕಾರ",
    pleaseDownloadInvoice: "ದಯವಿಟ್ಟು ಇನ್‌ವಾಯ್ಸ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    pleaseDownloadReceipt: "ದಯವಿಟ್ಟು ರಶೀದಿಯನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿ",
    from: "ನಿಂದ",
    email: "ಇಮೇಲ್",
    upgrade: "ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ",
    youAreOnFreePlan: "ನೀವು ಉಚಿತ ಯೋಜನೆಯಲ್ಲಿದ್ದೀರಿ.",
    clickOn: "ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
    yourPlanInFewSteps: " ಕೆಲವು ತ್ವರಿತ ಹಂತಗಳಲ್ಲಿ ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆ.",
    to: "ಗೆ",
    yourSubscriptionHasExpired:
      "ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆ ಅವಧಿ ಮುಗಿದಿದೆ ಮತ್ತು ನಿಮ್ಮ ಖಾತೆಯು ಈಗ ಸೀಮಿತವಾಗಿದೆ.",
    days: "ದಿನಗಳು",
    yourSubscriptionExpiresIn: "ನಿಮ್ಮ ಲಾಯ್‌ಸ್ಟಾರ್ ಚಂದಾದಾರಿಕೆ ಅವಧಿ ಮುಗಿಯುತ್ತದೆ",
    createAcount: "ಖಾತೆಯನ್ನು ತೆರೆಯಿರಿ",
    signIn: "ಸೈನ್ ಇನ್ ಮಾಡಿ",
    continue: "ಮುಂದುವರಿಸಿ",
    enterOtp: "OTP ಪಿನ್ ನಮೂದಿಸಿ",
    enterValidOtp: "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಪಿನ್ ನಮೂದಿಸಿ",
    pin: "ಪಿನ್",
    tokenSentToMail: "ನಿಮ್ಮ ಇಮೇಲ್‌ಗೆ ಟೋಕನ್ ಕಳುಹಿಸಲಾಗಿದೆ",
    passwordResetSuccessful: "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸುವಿಕೆ ಯಶಸ್ವಿಯಾಗಿದೆ",
    somethingWentWrong: "ಏನೋ ತಪ್ಪಾಗಿದೆ!",
    resetPassword: "ಪಾಸ್ವರ್ಡ್ ಮರುಹೊಂದಿಸಿ",
    iHaveResetCode: "ನನ್ನ ಬಳಿ ಪಾಸ್‌ವರ್ಡ್ ರೀಸೆಟ್ ಕೋಡ್ ಇದೆ",
    pleaseEnterEmail: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    aTokenWillBeSentToEmail: "ನಿಮ್ಮ ಇಮೇಲ್‌ಗೆ ಟೋಕನ್ ಕಳುಹಿಸಲಾಗುತ್ತದೆ",
    enterEmail: "ನಿಮ್ಮ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    sendinYourToken: "ನಿಮ್ಮ ಟೋಕನ್ ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ ...",
    makeSureItMatchesPassword:
      "ಇದು ನಿಮ್ಮ ಹೊಸ ಪಾಸ್‌ವರ್ಡ್‌ಗೆ ಹೊಂದಿಕೆಯಾಗಿದೆಯೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ",
    pleaseChooseNewPassword: "ದಯವಿಟ್ಟು ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಆಯ್ಕೆ ಮಾಡಿ",
    chooseNewPassword: "ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ಆಯ್ಕೆಮಾಡಿ",
    enterNewPassword: "ಖಚಿತಪಡಿಸಲು ನಿಮ್ಮ ಹೊಸ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    enterTokenSent: "ನಿಮ್ಮ ಮೇಲ್ ಕಳುಹಿಸಿದ ಟೋಕನ್ ಅನ್ನು ನಮೂದಿಸಿ",
    resetToken: "ಟೋಕನ್ ಮರುಹೊಂದಿಸಿ",
    resettingPassword: "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಮರುಹೊಂದಿಸಲಾಗುತ್ತಿದೆ ...",
    signUp: "ಸೈನ್ ಅಪ್ ಮಾಡಿ",
    adminSignInWithEmail:
      " ನಿರ್ವಾಹಕರು ಇಮೇಲ್ ಮೂಲಕ ಸೈನ್ ಇನ್ ಮಾಡುತ್ತಾರೆ ಮತ್ತು ಸಿಬ್ಬಂದಿ ಬಳಕೆದಾರ ಹೆಸರಿನೊಂದಿಗೆ ಸೈನ್ ಇನ್ ಮಾಡುತ್ತಾರೆ.",
    pleaseEnterEmailOrUsername:
      "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಇಮೇಲ್ ಅಥವಾ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    emailOrUsername: "ಇಮೇಲ್ ಅಥವಾ ಬಳಕೆದಾರ ಹೆಸರು",
    pleaseEnterPassword: "ದಯವಿಟ್ಟು ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    createAnAccount: "ಖಾತೆಯನ್ನು ತೆರೆಯಿರಿ",
    forgotPassword: "ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?",
    enterPhoneNumber: "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    personalData: "ವಯಕ್ತಿಕ ವಿಷಯ",
    validateConfirmationCOde: "ದೃ Confೀಕರಣ ಕೋಡ್ ಅನ್ನು ಮಾನ್ಯ ಮಾಡಿ",
    pleaseEnterFirstName: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterPhoneNumber: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterLastName: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterBusinessName: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ವ್ಯಾಪಾರದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    firstName: "ಮೊದಲ ಹೆಸರು",
    lastName: "ಕೊನೆಯ ಹೆಸರು",
    businessName: "ವ್ಯಾಪಾರದ ಹೆಸರು",
    previous: "ಹಿಂದಿನ",
    next: "ಮುಂದೆ",
    pleaseSelectBusinessCategory: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ವ್ಯಾಪಾರ ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    pleaseEnterValidEmail: "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterPostCode: "ದಯವಿಟ್ಟು ಪೋಸ್ಟ್ ಕೋಡ್ ನಮೂದಿಸಿ",
    postCode: "ಪೋಸ್ಟ್ ಕೋಡ್",
    phoneNumberInUse: "ಈ ಫೋನ್ ಸಂಖ್ಯೆ ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ!",
    emailInUse: "ಈ ಇಮೇಲ್ ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ!",
    foodAndDrinks: "ಆಹಾರ ಮತ್ತು ಪಾನೀಯಗಳು",
    salonAndBeauty: "ಸಲೂನ್ ಮತ್ತು ಸೌಂದರ್ಯ",
    fashionAndAccessories: "ಫ್ಯಾಷನ್ ಮತ್ತು ಪರಿಕರಗಳು",
    gymAndFitness: "ಜಿಮ್ ಮತ್ತು ಫಿಟ್ನೆಸ್",
    travelAndHotel: "ಪ್ರಯಾಣ ಮತ್ತು ಹೋಟೆಲ್",
    homeAndGifts: "ಮನೆ ಮತ್ತು ಉಡುಗೊರೆಗಳು",
    washingAndCleaning: "ತೊಳೆಯುವುದು ಮತ್ತು ಸ್ವಚ್ಛಗೊಳಿಸುವುದು",
    gadgetsAndElectronics: "ಗ್ಯಾಜೆಟ್‌ಗಳು ಮತ್ತು ಎಲೆಕ್ಟ್ರಾನಿಕ್ಸ್",
    groceries: "ದಿನಸಿ ಸಾಮಾನುಗಳು",
    others: "ಇತರೆ",
    submit: "ಸಲ್ಲಿಸು",
    accountCreatedSuccessful: "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ.",
    pleaseEnterAddress: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    addressLine1: "ವಿಳಾಸ ಸಾಲು 1",
    addressLine2: "ವಿಳಾಸ ಸಾಲು 2",
    choosePassword: "ಗುಪ್ತಪದ ಆರಿಸಿ",
    passwordMustBe6Characters: "ಪಾಸ್ವರ್ಡ್ ಕನಿಷ್ಠ 6 ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬೇಕು.",
    howDidYouHearLoystar: "ಲಾಯ್‌ಸ್ಟಾರ್ ಬಗ್ಗೆ ನೀವು ಹೇಗೆ ಕೇಳಿದ್ದೀರಿ?",
    referralCode: "ರೆಫರಲ್ ಕೋಡ್",
    byClickingTheButton: " ಕೆಳಗಿನ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ, ನೀವು ಒಪ್ಪುತ್ತೀರಿ",
    termsAndSevice: "ನಿಯಮಗಳು",
    wereCreatingAccount: "ನಾವು ನಿಮ್ಮ ಖಾತೆಯನ್ನು ರಚಿಸುತ್ತಿದ್ದೇವೆ",
    proceed: "ಮುಂದುವರೆಯಲು",
    verificationCodeMustBe6: "ಪರಿಶೀಲನೆ ಕೋಡ್ 6 ಅಂಕಿಗಳಾಗಿರಬೇಕು",
    pleaseEnter6DigitCode: "ದಯವಿಟ್ಟು 6 ಅಂಕಿಯ ಕೋಡ್ ನಮೂದಿಸಿ",
    enterVerificationCode: "ಪರೀಶೀಲನಾ ಕೋಡನ್ನು ನಮೂದಿಸಿರಿ",
    resendToken: "ಟೋಕನ್ ಅನ್ನು ಮರು ಕಳುಹಿಸಿ",
    verify: "ಪರಿಶೀಲಿಸಿ",
    transactions: "ವಹಿವಾಟುಗಳು",
    todaySales: "ಇಂದಿನ ಮಾರಾಟ",
    salesHistory: "ಮಾರಾಟ ಇತಿಹಾಸ",
    supplyHistory: "ಪೂರೈಕೆ ಇತಿಹಾಸ",
    new: "ಹೊಸ",
    invoices: "ಇನ್‌ವಾಯ್ಸ್‌ಗಳು",
    disbursements: "ವಿತರಣೆಗಳು",
    offlineSales: "ಆಫ್‌ಲೈನ್ ಮಾರಾಟ",
    products: "ಉತ್ಪನ್ನಗಳು",
    customers: "ಗ್ರಾಹಕರು",
    multiLevelLoyalty: "ಬಹು ಮಟ್ಟದ ನಿಷ್ಠೆ",
    loyaltyPrograms: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮಗಳು",
    members: "ಸದಸ್ಯರು",
    appStore: "ಆಪ್ ಸ್ಟೋರ್",
    orderMenu: "ಆದೇಶ ಮೆನು",
    settings: "ಸಂಯೋಜನೆಗಳು",
    staffAndBranches: "ಸಿಬ್ಬಂದಿ ಮತ್ತು ಶಾಖೆಗಳು",
    myAccount: "ನನ್ನ ಖಾತೆ",
    switchToSellMode: "ಮಾರಾಟ ಮೋಡ್‌ಗೆ ಬದಲಿಸಿ",
    signOut: "ಸೈನ್ ಔಟ್ ಮಾಡಿ",
    getFreeSubscription: "ಉಚಿತ ಚಂದಾದಾರಿಕೆಯನ್ನು ಪಡೆಯಿರಿ",
    onlyNumbersAllowed: "ಸಂಖ್ಯೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ",
    yourAccountMustBe10Digits: "ನಿಮ್ಮ ಖಾತೆ ಸಂಖ್ಯೆ 10 ಅಂಕಿಯ ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು",
    yourBvnMustBe11: "ನಿಮ್ಮ BVN 11 ಅಂಕಿಗಳ ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು",
    pleaseSelectBank: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    selectYourBank: "ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    enterBankAccountNumber: "ಬ್ಯಾಂಕ್ ಖಾತೆ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    enterBvnNumber: "ನಿಮ್ಮ BVN ಅನ್ನು ನಮೂದಿಸಿ",
    connectBankAccount: "ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ",
    passwordResetSuccessfulAndSignOut:
      "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ. ಮತ್ತೊಮ್ಮೆ ಸೈನ್ ಇನ್ ಮಾಡಲು ಕೆಳಗಿನ ಸೈನ್ ಔಟ್ ಬಟನ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
    enterCurrentPassword: "ಪ್ರಸ್ತುತ ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
    pleaseEnterCurrentPassword: "ದಯವಿಟ್ಟು ಪ್ರಸ್ತುತ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    phoneNumber: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
    sex: "ಸೆಕ್ಸ್",
    dateOfBirth: "ಹುಟ್ತಿದ ದಿನ",
    state: "ರಾಜ್ಯ",
    country: "ದೇಶ",
    loyaltyId: "ನಿಷ್ಠೆ ID",
    createdAt: "ನಲ್ಲಿ ರಚಿಸಲಾಗಿದೆ",
    noOfTransactionsMade: "ಮಾಡಿದ ವಹಿವಾಟುಗಳ ಸಂಖ್ಯೆ",
    yourDownloadWillStart: "ನಿಮ್ಮ ಡೌನ್‌ಲೋಡ್ ಕ್ಷಣಾರ್ಧದಲ್ಲಿ ಆರಂಭವಾಗುತ್ತದೆ",
    exportCustomers: "ರಫ್ತು ಗ್ರಾಹಕರು",
    youhaveSuccessfullyToppedUp:
      "ನಿಮ್ಮ ಎಸ್‌ಎಂಎಸ್‌ ಯೂನಿಟ್‌ಗಳನ್ನು ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಅಗ್ರಸ್ಥಾನದಲ್ಲಿದ್ದೀರಿ.",
    youNowHave: "ನೀವು ಈಗ ಹೊಂದಿದ್ದೀರಿ",
    smsUnits: "SMS ಘಟಕಗಳು",
    enterNumberOfSmsUnits:
      "ನೀವು ಖರೀದಿಸಲು ಉದ್ದೇಶಿಸಿರುವ SMS ಘಟಕಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterANumericValue: "ದಯವಿಟ್ಟು ಸಂಖ್ಯಾ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
    pay: "ಪಾವತಿ",
    accountEditedSuccessfully: "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಎಡಿಟ್ ಮಾಡಲಾಗಿದೆ.",
    youAeCurrentlyOn: "ನೀವು ಪ್ರಸ್ತುತದಲ್ಲಿದ್ದೀರಿ",
    plan: "ಯೋಜನೆ",
    userData: "ಬಳಕೆದಾರ ಡೇಟಾ",
    businessData: "ವ್ಯಾಪಾರ ಡೇಟಾ",
    businessCategory: "ವ್ಯಾಪಾರ ಕ್ಯಾಟಗರಿ",
    pleaseSelectCurrency: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕರೆನ್ಸಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    selectYourCurrency: "ನಿಮ್ಮ ಕರೆನ್ಸಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    purchaseMoreSmsUnits:
      "ಕೆಳಗಿನ ನಮೂನೆಯನ್ನು ಬಳಸಿಕೊಂಡು ಹೆಚ್ಚಿನ SMS ಘಟಕಗಳನ್ನು ಖರೀದಿಸಿ",
    youHave: "ನಿನ್ನ ಬಳಿ",
    atLeast4SmsUnitsRrequired:
      "ಪರಿಶೀಲನೆಗಾಗಿ ಕನಿಷ್ಠ 4 sms ಘಟಕಗಳು ಅಗತ್ಯವಿದೆ, ದಯವಿಟ್ಟು ಟಾಪ್ ಅಪ್ ಮಾಡಿ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ತಿಳಿದುಕೊಳ್ಳಿ' (KYC) ಅವಶ್ಯಕತೆಗಳನ್ನು ಅನುಸರಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಿ. ಇದು ನಿಮಗೆ USSD ಅಥವಾ ತ್ವರಿತ ವರ್ಗಾವಣೆಯ ಮೂಲಕ ಪಾವತಿಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು, ಗ್ರಾಹಕರಿಂದ ಆದೇಶಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ಮತ್ತು ವಹಿವಾಟು ಇತ್ಯರ್ಥವನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಅನುಮತಿಸುತ್ತದೆ. ನೈಜೀರಿಯನ್ ವ್ಯಾಪಾರಿಗಳಿಗೆ ಮಾತ್ರ ಅನ್ವಯಿಸುತ್ತದೆ. ಪ್ರಾರಂಭಿಸಲು ದಯವಿಟ್ಟು ಕೆಳಗಿನ ಬಟನ್ ಒತ್ತಿರಿ.",
    reConnectBankAccount: "ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಮರುಸಂಪರ್ಕಿಸಿ",
    accountName: "ಖಾತೆಯ ಹೆಸರು",
    accountNumber: "ಖಾತೆ ಸಂಖ್ಯೆ",
    bankName: "ಬ್ಯಾಂಕ್ ಹೆಸರು",
    verified: "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
    accountDetails: "ಖಾತೆ ವಿವರಗಳು",
    kycBankAccount: "ಕೆವೈಸಿ",
    createTier: "ಶ್ರೇಣಿಯನ್ನು ರಚಿಸಿ",
    fileUploadSuccessful: "ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
    fileUploadFailed: "ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ವಿಫಲವಾಗಿದೆ",
    createLoyaltyProgram: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ರಚಿಸಿ",
    createLoyalty: "ನಿಷ್ಠೆಯನ್ನು ರಚಿಸಿ",
    name: "ಹೆಸರು",
    loyaltyArtwork: "ನಿಷ್ಠೆಯ ಕಲಾಕೃತಿ",
    clickToUpload: "ಅಪ್ಲೋಡ್ ಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
    amountToPointsRatio: "ಅಂಕಗಳ ಅನುಪಾತದ ಮೊತ್ತ",
    points: "ಅಂಕಗಳು",
    recommendedAmountToPointRatio:
      "ಶಿಫಾರಸು ಮಾಡಲಾಗಿದೆ: ₦ 1 ರಿಂದ 1 ಪಾಯಿಂಟ್. ಅಂದರೆ ಪ್ರತಿ 1 ನೈರಾ ಖರ್ಚು ಮಾಡಿದರೆ, ನಿಮ್ಮ ಗ್ರಾಹಕರು 1 ಪಾಯಿಂಟ್ ಪಡೆಯುತ್ತಾರೆ",
    pleaseTypeIn: "ದಯವಿಟ್ಟು ಟೈಪ್ ಮಾಡಿ",
    toDeleteLoyalty: "ಈ ನಿಷ್ಠೆಯನ್ನು ಅಳಿಸಲು",
    deleteLoyalty: "ನಿಷ್ಠೆಯನ್ನು ಅಳಿಸಿ",
    toConfirm: "ಖಚಿತಪಡಿಸಲು",
    edit: "ತಿದ್ದು",
    pointsAwardedSuccessfully: "ಅಂಕಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನೀಡಲಾಗಿದೆ.",
    enterPointValueToAward:
      "ನೀವು ಗ್ರಾಹಕರಿಗೆ ಪ್ರಶಸ್ತಿ ನೀಡಲು ಬಯಸುವ ಪಾಯಿಂಟ್ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
    award: "ಪ್ರಶಸ್ತಿ",
    awardPointValuesToCustomer: "ಗ್ರಾಹಕರಿಗೆ ಪ್ರಶಸ್ತಿ ಪಾಯಿಂಟ್ ಮೌಲ್ಯ",
    enrollMembersToLoyalty: "ಸದಸ್ಯರನ್ನು ನಿಷ್ಠೆಗೆ ದಾಖಲಿಸಿ",
    awardPoints: "ಪ್ರಶಸ್ತಿ ಅಂಕಗಳು",
    enroll: "ನೋಂದಣಿ",
    home: "ಮನೆ",
    loyalty: "ನಿಷ್ಠೆ",
    enrollCustomers: "ಗ್ರಾಹಕರನ್ನು ನೋಂದಾಯಿಸಿ",
    selected: "ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ",
    customer: "ಗ್ರಾಹಕ",
    loyaltyActivationSuccessful: "ನಿಷ್ಠೆ ಸಕ್ರಿಯಗೊಳಿಸುವಿಕೆ ಯಶಸ್ವಿಯಾಗಿದೆ.",
    loyaltyDeactivationSuccessful: "ನಿಷ್ಠೆ ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವಿಕೆ ಯಶಸ್ವಿಯಾಗಿದೆ.",
    viewTier: "ಶ್ರೇಣಿಯನ್ನು ವೀಕ್ಷಿಸಿ",
    deactivate: "ಡಿ-ಆಕ್ಟಿವೇಟ್",
    activate: "ಸಕ್ರಿಯಗೊಳಿಸಿ",
    actions: "ಕ್ರಿಯೆಗಳು",
    nameOfLoyalty: "ನಿಷ್ಠೆಯ ಹೆಸರು",
    loyaltyStatus: "ನಿಷ್ಠೆ ಸ್ಥಿತಿ ನಿಷ್ಠೆ ಸ್ಥಿತಿ",
    numberOfTiers: "ಶ್ರೇಣಿಗಳ ಸಂಖ್ಯೆ",
    createdOn: "ರಂದು ರಚಿಸಲಾಗಿದೆ",
    createATier: "ಒಂದು ಶ್ರೇಣಿಯನ್ನು ರಚಿಸಿ",
    stopCreatingTierConfirmation:
      "ಶ್ರೇಣಿಯನ್ನು ರಚಿಸುವುದನ್ನು ನಿಲ್ಲಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
    stopEditingTierConfirmation:
      "ಈ ಶ್ರೇಣಿಯನ್ನು ಸಂಪಾದಿಸುವುದನ್ನು ನಿಲ್ಲಿಸಲು ನೀವು ಬಯಸುವಿರಾ?",
    nameOfTier: "ಶ್ರೇಣಿಯ ಹೆಸರು",
    minimumSpendingTarget: "ಕನಿಷ್ಠ ಖರ್ಚು ಮಾಡುವ ಗುರಿ",
    maximumSpendingTarget: "ಗರಿಷ್ಠ ಖರ್ಚು ಮಾಡುವ ಗುರಿ",
    minimumSpendingTargetRequired: "ಕನಿಷ್ಠ ಖರ್ಚು ಗುರಿ ಅಗತ್ಯವಿದೆ",
    maximumSpendingTargetRequired: "ಗರಿಷ್ಠ ಖರ್ಚು ಗುರಿ ಅಗತ್ಯವಿದೆ",
    rewardSponsor: "ಬಹುಮಾನ ಪ್ರಾಯೋಜಕರು",
    pleaseChooseARewardSponsor: "ದಯವಿಟ್ಟು ಬಹುಮಾನ ಪ್ರಾಯೋಜಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    self: "ಸ್ವಯಂ",
    partner: "ಪಾಲುದಾರ",
    rewardPartner: "ಬಹುಮಾನ ಪಾಲುದಾರ",
    pleaseSelectRewardPartner: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ರಿವಾರ್ಡ್ ಸಂಗಾತಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    rewards: "ಪ್ರತಿಫಲಗಳು",
    pleaseSelectAReward: "ದಯವಿಟ್ಟು ಬಹುಮಾನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    instructionsOnRedeemingReward:
      "ಗ್ರಾಹಕರು ರಿವಾರ್ಡ್ ಅನ್ನು ಹೇಗೆ ಪಡೆದುಕೊಳ್ಳಬೇಕು ಎಂಬುದರ ಕುರಿತು ಸೂಚನೆಗಳು",
    pleaseFillInThisField: "ದಯವಿಟ್ಟು ಈ ಕ್ಷೇತ್ರವನ್ನು ಭರ್ತಿ ಮಾಡಿ!",
    toDeleteThisTier: " ಈ ಹಂತವನ್ನು ಅಳಿಸಲು",
    deleteTier: "ಶ್ರೇಣಿಯನ್ನು ಅಳಿಸಿ",
    viewMembers: "ಸದಸ್ಯರನ್ನು ವೀಕ್ಷಿಸಿ",
    membersEnrolled: "ಸದಸ್ಯರು ದಾಖಲಾಗಿದ್ದಾರೆ",
    instruction: "ಸೂಚನಾ",
    membersIn: "ಸದಸ್ಯರು",
    availableTiersInLoyalty: "ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂನಲ್ಲಿ ಲಭ್ಯವಿರುವ ಶ್ರೇಣಿ (ಗಳು)",
    tiers: "ಶ್ರೇಣಿಗಳು",
    totalTier: "ಒಟ್ಟು ಶ್ರೇಣಿ",
    availableLoyaltyProgramme: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮ ಲಭ್ಯವಿದೆ",
    totalLoyalties: "ಒಟ್ಟು ನಿಷ್ಠೆಗಳು",
    memberDetails: "ಸದಸ್ಯರ ವಿವರಗಳು",
    nameOfCustomer: "ಗ್ರಾಹಕರ ಹೆಸರು",
    address: "ವಿಳಾಸ",
    allEnrolledMembers: "ಎಲ್ಲಾ ನೋಂದಾಯಿತ ಸದಸ್ಯರು",
    thisIsToWishYouHappyBirthday:
      "ಇದು ನಿಮಗೆ ಜನ್ಮದಿನದ ಶುಭಾಶಯಗಳು ಮತ್ತು ಸಮೃದ್ಧ ಜೀವನವನ್ನು ಬಯಸುವುದು. ನೀವು ಲಾಯ್‌ಸ್ಟರ್‌ಗೆ ಎಲ್ಲದಕ್ಕೂ ಧನ್ಯವಾದಗಳು. ಸಂಭ್ರಮದ ಶುಭಾಶಯಗಳು!",
    inputAnOfferPlease: "ದಯವಿಟ್ಟು ಆಫರ್ ಅನ್ನು ನಮೂದಿಸಿ",
    pleaseSelectTheInsertPoint:
      "ಸಂದೇಶದಲ್ಲಿರುವ ಇನ್ಸರ್ಟ್ ಪಾಯಿಂಟ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ ಮತ್ತು ಮತ್ತೊಮ್ಮೆ ಕ್ಲಿಕ್ ಮಾಡಿ",
    birthdayOfferAndMessage: "ಜನ್ಮದಿನದ ಕೊಡುಗೆ ಮತ್ತು ಸಂದೇಶ",
    birthdayOffer: "ಜನ್ಮದಿನದ ಕೊಡುಗೆ",
    birthdayMessage: "ಜನ್ಮದಿನ ಸಂದೇಶ",
    noOfferFound: "ಯಾವುದೇ ಕೊಡುಗೆ ಕಂಡುಬಂದಿಲ್ಲ",
    settingABirthdayOffer:
      "ಹುಟ್ಟುಹಬ್ಬದ ಕೊಡುಗೆಯನ್ನು ಹೊಂದಿಸುವುದರಿಂದ ಗ್ರಾಹಕರು ತಮ್ಮ ಹುಟ್ಟುಹಬ್ಬದಂದು SMS ಮೂಲಕ ಈ ಕೊಡುಗೆಯನ್ನು ಸ್ವೀಕರಿಸಬಹುದು",
    createOffer: "ಕೊಡುಗೆಯನ್ನು ರಚಿಸಿ",
    whatIsTheOffer: "ಕೊಡುಗೆ ಏನು?",
    editMessage: "ಸಂದೇಶವನ್ನು ಸಂಪಾದಿಸಿ",
    insert: "ಸೇರಿಸಿ",
    theNameOfCustomerInserted: "ಗ್ರಾಹಕರ ಹೆಸರನ್ನು ಇಲ್ಲಿ ಸೇರಿಸಲಾಗುವುದು",
    theBirtdayOfferInserted: "ಹುಟ್ಟುಹಬ್ಬದ ಕೊಡುಗೆಯನ್ನು ಇಲ್ಲಿ ಸೇರಿಸಲಾಗುತ್ತದೆ",
    youSuccessfullyAddedNewBranch:
      "ನೀವು ಹೊಸ ಶಾಖೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ!",
    addNewBranch: "ಹೊಸ ಶಾಖೆಯನ್ನು ಸೇರಿಸಿ",
    addBranch: "ಶಾಖೆಯನ್ನು ಸೇರಿಸಿ",
    additionalBranchWillIncur: "ಹೆಚ್ಚುವರಿ ಶಾಖೆ ಉಂಟಾಗುತ್ತದೆ",
    perBranch: "ಪ್ರತಿ ಶಾಖೆಗೆ",
    ecommerceBranchCosts: "ಇಕಾಮರ್ಸ್ ಶಾಖೆಯ ವೆಚ್ಚಗಳು",
    pleaseEnterBranchName: "ದಯವಿಟ್ಟು ಶಾಖೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterBranchAddress1: "ದಯವಿಟ್ಟು ಶಾಖೆಯ ವಿಳಾಸ ಸಾಲು 1 ನಮೂದಿಸಿ",
    enterBranchAddress1: "ಶಾಖೆಯ ವಿಳಾಸ ಸಾಲು 1 ನಮೂದಿಸಿ",
    enterBranchAddress2: "ಶಾಖೆಯ ವಿಳಾಸ ಸಾಲು 1 ನಮೂದಿಸಿ",
    pleaseEnterBranchAddress2: "ಶಾಖೆಯ ವಿಳಾಸ ಸಾಲು 2 ನಮೂದಿಸಿ",
    enterBranchName: "ಶಾಖೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    successfullyAddedStaff: "ನೀವು ಹೊಸ ಸಿಬ್ಬಂದಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ!",
    addNewStaff: "ಹೊಸ ಸಿಬ್ಬಂದಿಯನ್ನು ಸೇರಿಸಿ",
    addStaff: "ಸಿಬ್ಬಂದಿಯನ್ನು ಸೇರಿಸಿ",
    additionalStaffWillIncur: "ಹೆಚ್ಚುವರಿ ಸಿಬ್ಬಂದಿಗಳು ಭರಿಸುತ್ತಾರೆ",
    perStaff: "ಪ್ರತಿ ಸಿಬ್ಬಂದಿಗೆ.",
    pleaseEnterStaffEmail: "ದಯವಿಟ್ಟು ಸಿಬ್ಬಂದಿಯ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterStaffUsername: "ದಯವಿಟ್ಟು ಸಿಬ್ಬಂದಿಯ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterStaffPassword: "ದಯವಿಟ್ಟು ಸಿಬ್ಬಂದಿಯ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    pleaseSelectStaffRole: "ದಯವಿಟ್ಟು ಸಿಬ್ಬಂದಿಯ ಪಾತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    selectStaffRole: "ಸಿಬ್ಬಂದಿಯ ಪಾತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    enterStaffEmail: "ಸಿಬ್ಬಂದಿ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    enterStaffUsername: "ಸಿಬ್ಬಂದಿಯ ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    enterStaffPassword: "ಸಿಬ್ಬಂದಿಯ ಪಾಸ್‌ವರ್ಡ್ ನಮೂದಿಸಿ",
    spacesNotAllowedInUsername: "ಬಳಕೆದಾರಹೆಸರಿನಲ್ಲಿ ಅವಕಾಶವಿಲ್ಲ",
    admin: "ನಿರ್ವಹಣೆ",
    pleaseSelectBusinessToAttachStaff:
      "ಸಿಬ್ಬಂದಿಯನ್ನು ಲಗತ್ತಿಸಲು ದಯವಿಟ್ಟು ವ್ಯಾಪಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    searchForBranchToAttachStaff: "ಸಿಬ್ಬಂದಿಯನ್ನು ಲಗತ್ತಿಸಲು ಶಾಖೆಗಾಗಿ ಹುಡುಕಿ",
    username: "ಬಳಕೆದಾರ ಹೆಸರು",
    role: "ಪಾತ್ರ",
    areYouSureToDeleteThis: "ನೀವು ಇದನ್ನು ಖಚಿತವಾಗಿ ಅಳಿಸುವಿರಾ",
    branches: "ಶಾಖೆಗಳು",
    upgradeYourPlan: "ನಿಮ್ಮ ಯೋಜನೆಯನ್ನು ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ.",
    add: "ಸೇರಿಸಿ",
    addNew: "ಹೊಸದನ್ನು ಸೇರಿಸಿ",
    customerWithEmailAlreadyExists:
      "ಇಮೇಲ್/ಫೋನ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಗ್ರಾಹಕರು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದ್ದಾರೆ!",
    successfullyAddedNewCustomer:
      "ನೀವು ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ!",
    addCustomer: "ಗ್ರಾಹಕರನ್ನು ಸೇರಿಸಿ",
    pleaseEnterCustomerFirstName: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterCustomerLastName: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterCustomerPhoneNumber: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterCustomerEmail: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    pleaseEnterCustomerAddressLine: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ವಿಳಾಸ ರೇಖೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterCustomerOtherAddress: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಇತರ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    pleaseSelectCustomerGender: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ಲಿಂಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    gender: "ಲಿಂಗ",
    male: "ಪುರುಷ",
    female: "ಹೆಣ್ಣು",
    bank: "ಬ್ಯಾಂಕ್",
    selectBank: "ಬ್ಯಾಂಕ್ ಆಯ್ಕೆ ಮಾಡಿ",
    stateOrRegionOrProvince: "ರಾಜ್ಯ/ಪ್ರದೇಶ/ಪ್ರಾಂತ್ಯ",
    customerNotes: "ಗ್ರಾಹಕರ ಟಿಪ್ಪಣಿಗಳು",
    sendSms: "SMS ಕಳುಹಿಸಿ",
    editCustomer: "ಗ್ರಾಹಕರನ್ನು ಸಂಪಾದಿಸಿ",
    redeemReward: "ಬಹುಮಾನವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
    issueLoyaltyCard: "ನಿಷ್ಠೆ ಕಾರ್ಡ್ ವಿತರಿಸಿ",
    deleteCustomer: "ಗ್ರಾಹಕರನ್ನು ಅಳಿಸಿ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ನೀವು ಲಾಯಲ್ಟಿ ಸದಸ್ಯತ್ವ ID ಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಯೋಜಿಸಿದ್ದೀರಿ",
    noMembershipIdAvailable:
      "ಯಾವುದೇ ಸದಸ್ಯತ್ವ ID ಗಳು ಲಭ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು hello@loystar.co ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಿ",
    sendEmail: "ಇಮೇಲ್ ಕಳುಹಿಸಿ",
    membershipPoints: "ಸದಸ್ಯತ್ವ ಅಂಕಗಳು",
    customerDetails: "ಗ್ರಾಹಕರ ವಿವರಗಳು",
    close: "ಮುಚ್ಚಿ",
    loyaltyBalance: "ನಿಷ್ಠೆ ಸಮತೋಲನ",
    pointsBalance: "ಪಾಯಿಂಟ್ಸ್ ಬ್ಯಾಲೆನ್ಸ್",
    starBalance: "ಸ್ಟಾರ್ ಬ್ಯಾಲೆನ್ಸ್",
    requiredPoints: "ಅಗತ್ಯವಿರುವ ಅಂಕಗಳು",
    requiredStars: "ಅಗತ್ಯವಿರುವ ನಕ್ಷತ್ರಗಳು",
    reddemCode: "ಕೋಡ್ ಪಡೆದುಕೊಳ್ಳಲು",
    toDeleteThisCustomer: "ಈ ಗ್ರಾಹಕರನ್ನು ಅಳಿಸಲು",
    customerHasBeenDeletedSuccessfully: "ಗ್ರಾಹಕರನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
    customerWithPhoneAlreadyExists:
      "ಫೋನ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಗ್ರಾಹಕರು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದ್ದಾರೆ",
    customerWasSuccessfullyEdited: "ಗ್ರಾಹಕರನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಎಡಿಟ್ ಮಾಡಲಾಗಿದೆ",
    anErrorOccured: "ದೋಷವೊಂದು ಸಂಭವಿಸಿದೆ",
    phoneNumberIncludeCountry: "ಫೋನ್ ಸಂಖ್ಯೆ (ದೇಶದ ಕೋಡ್ ಸೇರಿಸಿ)",
    yourFileQueuedForUpload:
      "ನಿಮ್ಮ ಫೈಲ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಕ್ಯೂ ಮಾಡಲಾಗಿದೆ. ದಯವಿಟ್ಟು ಕೆಲವು ಸೆಕೆಂಡುಗಳ ನಂತರ ಪುಟವನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡಿ.",
    thereWasAnErrorPerformingOperation:
      "ಕಾರ್ಯಾಚರಣೆಯನ್ನು ನಿರ್ವಹಿಸುವಲ್ಲಿ ದೋಷ ಕಂಡುಬಂದಿದೆ!",
    pleaseSelectFile: "ದಯವಿಟ್ಟು ಒಂದು ಫೈಲ್ ಆಯ್ಕೆ ಮಾಡಿ!",
    oopsOnlyCsvFilesAllowed:
      "ಅಯ್ಯೋ! CSV ಫೈಲ್‌ಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ. ದಯವಿಟ್ಟು .csv ಫೈಲ್ ಅನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ.",
    fileShouldBeSmallerThan5Mb:
      "ಫೈಲ್ 5MB ಗಿಂತ ಚಿಕ್ಕದಾಗಿರಬೇಕು. ನೀವು ದೊಡ್ಡ ಫೈಲ್ ಹೊಂದಿದ್ದರೆ, support@loystar.co ಗೆ ಇಮೇಲ್ ಮಾಡಿ. ಧನ್ಯವಾದ",
    customerFirstNameIsRequired: "ಸಾಲಿನಲ್ಲಿ ಗ್ರಾಹಕರ ಮೊದಲ ಹೆಸರು ಅಗತ್ಯವಿದೆ",
    customerPhoneNumberIsRequired: "ಸಾಲಿನಲ್ಲಿ ಗ್ರಾಹಕರ ಫೋನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ",
    importCustomers: "ಆಮದು ಗ್ರಾಹಕರು",
    upload: "ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    clickIconToDownloadCsv:
      "CSV ಫೈಲ್ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಈ ಐಕಾನ್ ಕ್ಲಿಕ್ ಮಾಡಿ.",
    getGoogleSheetVersion: "Google ಶೀಟ್ ಆವೃತ್ತಿಯನ್ನು ಪಡೆಯಿರಿ",
    clickOrDragFileToUpload:
      "ಅಪ್ಲೋಡ್ ಮಾಡಲು ಈ ಪ್ರದೇಶಕ್ಕೆ ಫೈಲ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ ಅಥವಾ ಡ್ರ್ಯಾಗ್ ಮಾಡಿ",
    missingOrInvalidColumnHeader:
      "ಕಾಣೆಯಾದ ಅಥವಾ ಅಮಾನ್ಯ ಕಾಲಮ್ ಹೆಡರ್. ದಯವಿಟ್ಟು ಟೆಂಪ್ಲೇಟ್ ಸ್ವರೂಪವನ್ನು ಅನುಸರಿಸಿ. ಧನ್ಯವಾದ.",
    youHaveImported: "ನೀವು ಆಮದು ಮಾಡಿಕೊಂಡಿದ್ದೀರಿ",
    youSuccessfullyRedeemedReward:
      "ನಿಮ್ಮ ಬಹುಮಾನವನ್ನು ನೀವು ಯಶಸ್ವಿಯಾಗಿ ರಿಡೀಮ್ ಮಾಡಿದ್ದೀರಿ!",
    sixDigitCode: "ಆರು ಅಂಕಿಯ ಕೋಡ್",
    pleaseEnterCustomerRewardCode: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರ ರಿವಾರ್ಡ್ ಕೋಡ್ ನಮೂದಿಸಿ",
    enterRewardCode:
      "ರಿವಾರ್ಡ್ ಕೋಡ್ ನಮೂದಿಸಿ. (ರಿವಾರ್ಡ್ ಕೋಡ್ ಕೇಸ್ ಸೆನ್ಸಿಟಿವ್ ಆಗಿದೆ)",
    selectLoyaltyProgram: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    stamps: "ಅಂಚೆಚೀಟಿಗಳು",
    smsUnitsLow: "SMS ಘಟಕಗಳು ಕಡಿಮೆ",
    pleaseTopUpSmsUnits: "ದಯವಿಟ್ಟು SMS ಘಟಕಗಳನ್ನು ಟಾಪ್ ಅಪ್ ಮಾಡಿ",
    smsSuccessfullySentForDelivery:
      "ವಿತರಣೆಗೆ ಎಸ್‌ಎಂಎಸ್ ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ!",
    sendSmsTo: "ಗೆ SMS ಕಳುಹಿಸಿ",
    allCustomers: "ಎಲ್ಲಾ ಗ್ರಾಹಕರು",
    unitsAvailable: "ಘಟಕಗಳು ಲಭ್ಯವಿದೆ",
    pleaseTypeInTheMessage: "ದಯವಿಟ್ಟು ಸಂದೇಶವನ್ನು ಟೈಪ್ ಮಾಡಿ",
    message: "ಸಂದೇಶ",
    charactersRemaining: "ಉಳಿದಿರುವ ಅಕ್ಷರಗಳು",
    avoidUseOfSpecialCharacters:
      "SMS ಘಟಕಗಳನ್ನು ಸಂರಕ್ಷಿಸಲು ವಿಶೇಷ ಪಾತ್ರಗಳು ಮತ್ತು ಎಮೋಜಿಗಳ ಬಳಕೆಯನ್ನು ತಪ್ಪಿಸಿ.",
    note: "ಸೂಚನೆ",
    errorFetchingCustomersMultilevelDetail:
      "ಗ್ರಾಹಕರ ಬಹುಮಟ್ಟದ ವಿವರಗಳನ್ನು ಪಡೆಯುವಲ್ಲಿ ದೋಷ",
    search: "ಹುಡುಕಿ Kannada",
    reset: "ಮರುಹೊಂದಿಸಿ",
    importCustomer: "ಆಮದು ಗ್ರಾಹಕ",
    addNewCustomer: "ಹೊಸ ಗ್ರಾಹಕರನ್ನು ಸೇರಿಸಿ",
    sendSmsBroadcast: "SMS ಪ್ರಸಾರವನ್ನು ಕಳುಹಿಸಿ",
    totalCustomers: "ಒಟ್ಟು ಗ್ರಾಹಕರು",
    disbursementDetails: "ವಿತರಣೆಯ ವಿವರಗಳು",
    paidBy: "ಮೂಲಕ ಪಾವತಿಸಲಾಗಿದೆ",
    disbursed: "ವಿತರಿಸಲಾಗಿದೆ",
    bankAccountName: "ಬ್ಯಾಂಕ್ ಖಾತೆ ಹೆಸರು",
    bankAccountNumber: "ಬ್ಯಾಂಕ್ ಖಾತೆ ಸಂಖ್ಯೆ",
    transferInitiated: "ವರ್ಗಾವಣೆ ಆರಂಭಿಸಲಾಗಿದೆ",
    transferCompleted: "ವರ್ಗಾವಣೆ ಪೂರ್ಣಗೊಂಡಿದೆ",
    pleaseEnterAValid: "ದಯವಿಟ್ಟು ಮಾನ್ಯವನ್ನು ನಮೂದಿಸಿ",
    begin: "ಆರಂಭಿಸಲು",
    end: "ಅಂತ್ಯ",
    date: "ದಿನಾಂಕ",
    paymentDate: "ಪಾವತಿ ದಿನಾಂಕ",
    selectDisbursementDuration: "ವಿತರಣಾ ಅವಧಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    totalSettled: "ಒಟ್ಟು ಇತ್ಯರ್ಥ",
    totalUnsettled: "ಒಟ್ಟು ಸ್ಥಿರವಾಗಿಲ್ಲ",
    toDeleteThisSale: "ಈ ಮಾರಾಟವನ್ನು ಅಳಿಸಲು",
    draftSaleDeletedSuccessfully: "ಡ್ರಾಫ್ಟ್ ಮಾರಾಟವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
    deleteSale: "ಮಾರಾಟವನ್ನು ಅಳಿಸಿ",
    pleaseTypeInYourTotal:
      "ಅಳಿಸುವಿಕೆಯನ್ನು ಖಚಿತಪಡಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಮೊತ್ತವನ್ನು ಟೈಪ್ ಮಾಡಿ",
    billDetails: "ಬಿಲ್ ವಿವರಗಳು",
    printBill: "ಮುದ್ರಣ ಬಿಲ್",
    servedBy: "ಇವರಿಂದ ಬಡಿಸಲಾಗುತ್ತದೆ",
    total: "ಒಟ್ಟು",
    createdDate: "ರಚಿಸಿದ ದಿನಾಂಕ",
    createdTime: "ರಚಿಸಿದ ಸಮಯ",
    status: "ಸ್ಥಿತಿ",
    loginSuccessful: "ಲಾಗಿನ್ ಯಶಸ್ವಿಯಾಗಿದೆ",
    pleaseWaitWhileWeConnectAccount:
      "ನಾವು ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಸಂಪರ್ಕಿಸುವವರೆಗೆ ದಯವಿಟ್ಟು ಕಾಯಿರಿ",
    connectionFailedTryAgain: "ಸಂಪರ್ಕ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ.",
    connectionSuccessful: "ಸಂಪರ್ಕ ಯಶಸ್ವಿಯಾಗಿದೆ",
    viewDetails: "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    enable: "ಸಕ್ರಿಯಗೊಳಿಸಿ",
    free: "ಉಚಿತ",
    cost: "ವೆಚ್ಚ",
    visitWebsite: "ವೆಬ್‌ಸೈಟ್‌ಗೆ ಭೇಟಿ ನೀಡಿ",
    pleaseUpgradeYourPlanToPro:
      "ಈ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಯೋಜನೆಯನ್ನು ಪ್ರೊ ಪ್ಲಸ್‌ಗೆ ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ",
    connectYourBankAccount:
      "ಪಾವತಿಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸಂಪರ್ಕಿಸಿ.",
    disable: "ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಿ",
    enableApp: "ಅಪ್ಲಿಕೇಶನ್ ಸಕ್ರಿಯಗೊಳಿಸಿ",
    addNewProductToInvoice: "ಇನ್‌ವಾಯ್ಸ್‌ಗೆ ಹೊಸ ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ",
    toDeleteThisInvoice: "ಈ ಸರಕುಪಟ್ಟಿ ಅಳಿಸಲು",
    invoiceDeletedSuccessfully: "ಸರಕುಪಟ್ಟಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
    deleteInvoice: "ಇನ್ವಾಯ್ಸ್ ಅಳಿಸಿ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ನೀವು ಸರಕುಪಟ್ಟಿ ಪಾವತಿ ಸಂದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ!",
    pleaseEnterPaymentInstructions: "ದಯವಿಟ್ಟು ಪಾವತಿ ಸೂಚನೆಗಳನ್ನು ನಮೂದಿಸಿ",
    invoiceId: "ಸರಕುಪಟ್ಟಿ ID",
    paidDate: "ಪಾವತಿಸಿದ ದಿನಾಂಕ",
    reference: "ಉಲ್ಲೇಖ",
    productAdded: "ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಲಾಗಿದೆ",
    productOutOfStock: "ಉತ್ಪನ್ನವು ದಾಸ್ತಾನು ಇಲ್ಲ. ದಯವಿಟ್ಟು ಮರು ಸ್ಟಾಕ್ ಮಾಡಿ.",
    totalInvoices: "ಒಟ್ಟು ಇನ್ವಾಯ್ಸ್",
    totalPaidInvoices: "ಒಟ್ಟು ಪಾವತಿಸಿದ ಇನ್ವಾಯ್ಸ್ಗಳು",
    totalUnpaidInvoices: "ಒಟ್ಟು UNPAID ಇನ್ವಾಯ್ಸ್",
    loyaltyProgramDeleted: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಅಳಿಸಲಾಗಿದೆ",
    thisLoyaltyProgramDeletedSuccessfully:
      "ಈ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
    thisLoyaltyProgramEditedSuccessfully:
      "ಈ ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಎಡಿಟ್ ಮಾಡಲಾಗಿದೆ",
    loyaltyProgramAddedSuccessfully:
      "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
    loyaltyProgramEdited: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಸಂಪಾದಿಸಲಾಗಿದೆ",
    loyaltyProgramAdded: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಸೇರಿಸಲಾಗಿದೆ",
    loyaltyDetails: "ನಿಷ್ಠೆಯ ವಿವರಗಳು",
    doYouWantToCloseDialog: "ನೀವು ಈ ಸಂವಾದವನ್ನು ಮುಚ್ಚಲು ಬಯಸುವಿರಾ?",
    pleaseEnterLoyaltyName: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ನಿಷ್ಠೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    programType: "ಕಾರ್ಯಕ್ರಮದ ಪ್ರಕಾರ",
    pleaseSelectProgramType: "ದಯವಿಟ್ಟು ಪ್ರೋಗ್ರಾಂ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    simplePoints: "ಸರಳ ಅಂಕಗಳು",
    stampsProgram: "ಅಂಚೆಚೀಟಿಗಳ ಕಾರ್ಯಕ್ರಮ",
    threshold: "ಹೊಸ್ತಿಲು",
    pleaseEnterLoyaltyThreshold: "ದಯವಿಟ್ಟು ನಿಷ್ಠೆಯ ಮಿತಿಯನ್ನು ನಮೂದಿಸಿ",
    reward: "ಬಹುಮಾನ",
    pleaseEnterLoyaltyReward: "ನಿಷ್ಠೆಯ ಪ್ರತಿಫಲವನ್ನು ದಯವಿಟ್ಟು ನಮೂದಿಸಿ",
    totalUserPoints: "ಒಟ್ಟು ಬಳಕೆದಾರ ಪಾಯಿಂಟುಗಳು",
    totalUserStamps: "ಒಟ್ಟು ಬಳಕೆದಾರ ಅಂಚೆಚೀಟಿಗಳು",
    spendingTarget: "ಖರ್ಚು ಮಾಡುವ ಗುರಿ",
    spendingTargetHint1:
      "ಪ್ರತಿಫಲವನ್ನು ಗಳಿಸಲು ಗ್ರಾಹಕರು ಎಷ್ಟು ಖರ್ಚು ಮಾಡಬೇಕು ಎಂಬುದು ಖರ್ಚು ಮಾಡುವ ಗುರಿಯಾಗಿದೆ. 1 ಕರೆನ್ಸಿ ಮೌಲ್ಯ = 1 ಪಾಯಿಂಟ್.",
    spendingTargetHint2:
      "ಪ್ರತಿಫಲವನ್ನು ಗಳಿಸಲು ಗ್ರಾಹಕರು ಎಷ್ಟು ಅಂಚೆಚೀಟಿಗಳನ್ನು ಸಂಗ್ರಹಿಸಬೇಕು ಎಂಬುದು ಸ್ಟ್ಯಾಂಪ್‌ಗಳ ಗುರಿಯಾಗಿದೆ. ಉದಾ. 5",
    addNewLoyaltyProgram: "ಹೊಸ ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಸೇರಿಸಿ",
    addLoyaltyProgram: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮವನ್ನು ಸೇರಿಸಿ",
    loyaltyProgramType: "ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮದ ವಿಧ",
    pleaseSelectLoyaltyProgramType:
      "ದಯವಿಟ್ಟು ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    nameOfProgram: "ಕಾರ್ಯಕ್ರಮದ ಹೆಸರು",
    pleaseEnterProgramName: "ದಯವಿಟ್ಟು ಕಾರ್ಯಕ್ರಮದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    whatIsTheReward: "ಪ್ರತಿಫಲ ಏನು?",
    egNextPurchaseIsFree: "ಉದಾ. ಮುಂದಿನ ಖರೀದಿ ಉಚಿತ",
    customerName: "ಗ್ರಾಹಕ ಹೆಸರು",
    guestCustomer: "ಅತಿಥಿ ಗ್ರಾಹಕ",
    orderConfirmedSuccessfully: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ದೃ confirmedಪಡಿಸಲಾಗಿದೆ",
    orderCancelledSuccessfully: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
    confirmOrder: "ಆದೇಶವನ್ನು ದೃmೀಕರಿಸಿ",
    cancelOrder: "ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಿ",
    allOrders: "ಎಲ್ಲಾ ಆದೇಶಗಳು",
    totalOrders: "ಒಟ್ಟು ಆದೇಶಗಳು",
    doYouWantToAcceptOrder: "ನೀವು ಈ ಆದೇಶವನ್ನು ಸ್ವೀಕರಿಸಲು ಬಯಸುವಿರಾ?",
    doYouWantToCancelOrder: "ನೀವು ಈ ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಲು ಬಯಸುವಿರಾ?",
    orderDetails: "ಆದೇಶದ ವಿವರಗಳು",
    orderCreatedAt: "ನಲ್ಲಿ ಆದೇಶವನ್ನು ರಚಿಸಲಾಗಿದೆ",
    supplier: "ಪೂರೈಕೆದಾರ",
    productName: "ಉತ್ಪನ್ನದ ಹೆಸರು",
    quantity: "ಪ್ರಮಾಣ",
    unitPrice: "ಘಟಕ ಬೆಲೆ",
    receivedBy: "ಇವರಿಂದ ಸ್ವೀಕರಿಸಲಾಗಿದೆ",
    reportFrom: "ನಿಂದ ವರದಿ",
    totalSupplies: "ಒಟ್ಟು ಸರಬರಾಜು",
    allRightsReserved: "ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ",
    toDeleteThisTransaction: "ಈ ವಹಿವಾಟನ್ನು ಅಳಿಸಲು",
    transactionDeletedSuccessfully:
      "ವಹಿವಾಟನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ. ದಾಸ್ತಾನು ದಾಸ್ತಾನಿಗೆ ಹಿಂತಿರುಗಿಸಲಾಗಿದೆ.",
    deleteTransaction: "ವಹಿವಾಟು ಅಳಿಸಿ",
    transactionDetails: "ವಹಿವಾಟು ವಿವರಗಳು",
    printReceipt: "ಮುದ್ರಣ ರಸೀದಿ",
    channel: "ಚಾನೆಲ್",
    discount: "ರಿಯಾಯಿತಿ",
    profit: "ಲಾಭ",
    discountedSales: "ರಿಯಾಯಿತಿ ಮಾರಾಟ",
    errorFetchingRecord: "ದಾಖಲೆ ಪಡೆಯುವಲ್ಲಿ ದೋಷ",
    exportTransactions: "ರಫ್ತು ವಹಿವಾಟುಗಳು",
    errorFetchingSalesRecord: "ರಫ್ತುಗಾಗಿ ಮಾರಾಟ ದಾಖಲೆಯನ್ನು ಪಡೆಯುವಲ್ಲಿ ದೋಷ.",
    totalSellingPrice: "ಒಟ್ಟು ಮಾರಾಟ ಬೆಲೆ",
    totalCostPrice: "ಒಟ್ಟು ವೆಚ್ಚದ ಬೆಲೆ",
    appliedDiscount: "ಅನ್ವಯಿಕ ರಿಯಾಯಿತಿ",
    noOfItems: "ವಸ್ತುಗಳ ಸಂಖ್ಯೆ",
    sales: "ಮಾರಾಟ",
    export: "ರಫ್ತು",
    totalProfit: "ಒಟ್ಟು ಲಾಭ",
    totalBalanceInPeriod: "ಅವಧಿಯಲ್ಲಿ ಒಟ್ಟು ಬ್ಯಾಲೆನ್ಸ್",
    allTimeSales: "ಸಾರ್ವಕಾಲಿಕ ಮಾರಾಟ",
    records: "ದಾಖಲೆಗಳು",
    todaysSales: "ಇಂದಿನ ಮಾರಾಟ",
    transaction: "ವ್ಯವಹಾರ",
    youHaveExceededTotalNumberOfProducts:
      "ನಿಮ್ಮ ಯೋಜನೆಯಲ್ಲಿ ಅನುಮತಿಸಲಾದ ಒಟ್ಟು ಉತ್ಪನ್ನಗಳ ಸಂಖ್ಯೆಯನ್ನು ನೀವು ಮೀರಿದ್ದೀರಿ. ಹೆಚ್ಚಿನ ಮಿತಿಯನ್ನು ಆನಂದಿಸಲು ನಿಮ್ಮ ಯೋಜನೆಯನ್ನು ಅಪ್‌ಗ್ರೇಡ್ ಮಾಡಿ.",
    youNeedToHaveLoyaltyProgram:
      "ಈ ವೈಶಿಷ್ಟ್ಯವನ್ನು ಬಳಸಲು ನೀವು ನಿಷ್ಠಾವಂತ ಕಾರ್ಯಕ್ರಮವನ್ನು ಹೊಂದಿರಬೇಕು!",
    price: "ಬೆಲೆ",
    category: "ವರ್ಗ",
    stockTracking: "ಸ್ಟಾಕ್ ಟ್ರ್ಯಾಕಿಂಗ್",
    stockCount: "ಸ್ಟಾಕ್ ಕೌಂಟ್",
    taxed: "ತೆರಿಗೆ ವಿಧಿಸಲಾಗಿದೆ",
    originalPrice: "ಮೂಲ ಬೆಲೆ",
    costPrice: "ಅಸಲಿನ ಬೆಲೆ",
    unit: "ಘಟಕ",
    productImage: "ಉತ್ಪನ್ನ ಚಿತ್ರ",
    taxRate: "ತೆರಿಗೆ ದರ",
    taxType: "ತೆರಿಗೆ ವಿಧ",
    trackInventory: "ದಾಸ್ತಾನು ಟ್ರ್ಯಾಕ್ ಮಾಡಿ",
    variants: "ರೂಪಾಂತರಗಳು",
    hasVariants: "ರೂಪಾಂತರಗಳನ್ನು ಹೊಂದಿದೆ",
    importProduct: "ಆಮದು ಉತ್ಪನ್ನ",
    exportProducts: "ರಫ್ತು ಉತ್ಪನ್ನಗಳು",
    addNewProduct: "ಹೊಸ ಉತ್ಪನ್ನವನ್ನು ಸೇರಿಸಿ",
    viewCategory: "ವರ್ಗವನ್ನು ವೀಕ್ಷಿಸಿ",
    viewSuppliers: "ಪೂರೈಕೆದಾರರನ್ನು ವೀಕ್ಷಿಸಿ",
    receiveInventory: "ದಾಸ್ತಾನು ಸ್ವೀಕರಿಸಿ",
    printAllProductsTag: "ಎಲ್ಲಾ ಉತ್ಪನ್ನಗಳ ಟ್ಯಾಗ್ ಅನ್ನು ಮುದ್ರಿಸಿ",
    deleteAll: "ಎಲ್ಲಾ ಅಳಿಸಿ",
    totalProducts: "ಒಟ್ಟು ಉತ್ಪನ್ನಗಳು",
    youveSuccessfullyAddedANewCategory:
      "ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಹೊಸ ವರ್ಗವನ್ನು ಸೇರಿಸಿದ್ದೀರಿ",
    addNewCategory: "ಹೊಸ ವರ್ಗವನ್ನು ಸೇರಿಸಿ",
    addCategory: "ವರ್ಗವನ್ನು ಸೇರಿಸಿ",
    categoryName: "ವರ್ಗದ ಹೆಸರು",
    pleaseEnterCategoryName: "ದಯವಿಟ್ಟು ವರ್ಗದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    stampsTarget: "ಅಂಚೆಚೀಟಿಗಳ ಗುರಿ",
    sendInventory: "ದಾಸ್ತಾನು ಕಳುಹಿಸಿ",
    productDetails: "ಉತ್ಪನ್ನ ವಿವರಗಳು",
    youveSuccessfullyEditedThisCategory:
      "ನೀವು ಈ ವರ್ಗವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಂಪಾದಿಸಿದ್ದೀರಿ",
    update: "ನವೀಕರಿಸಿ",
    categoryList: "ವರ್ಗ ಪಟ್ಟಿ",
    categories: "ವರ್ಗಗಳು",
    enterQuantityToUpdate: "ನವೀಕರಿಸಲು ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    inventorySentSuccessfully: "ದಾಸ್ತಾನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ!",
    updateInventory: "ದಾಸ್ತಾನು ನವೀಕರಿಸಿ",
    currentQuantity: "ಪ್ರಸ್ತುತ ಪ್ರಮಾಣ",
    pleaseEnterQuantityToAdd: "ದಯವಿಟ್ಟು ನೀವು ಸೇರಿಸಲು ಬಯಸುವ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    pleaseSelectABranch: "ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    searchForBranch: "ಶಾಖೆಗಾಗಿ ಹುಡುಕಿ",
    youCantSendMoreThanStock:
      "ನೀವು ಸ್ಟಾಕ್‌ನಲ್ಲಿರುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಿನದನ್ನು ಕಳುಹಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    send: "ಕಳುಹಿಸು",
    pleaseEnterQuantityToSend:
      "ದಯವಿಟ್ಟು ನೀವು ಕಳುಹಿಸಲು ಬಯಸುವ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    productNameIsRequiredOnRow: "ಉತ್ಪನ್ನದ ಹೆಸರು ಸಾಲಿನಲ್ಲಿ ಅಗತ್ಯವಿದೆ",
    productCategoryIsRequiredOnRow: "ಉತ್ಪನ್ನ ವರ್ಗವು ಸಾಲಿನಲ್ಲಿ ಅಗತ್ಯವಿದೆ",
    productPriceIsRequiredOnRow: "ಸಾಲಿನಲ್ಲಿ ಉತ್ಪನ್ನದ ಬೆಲೆ ಅಗತ್ಯವಿದೆ",
    productUnitIsRequiredOnRow: "ಉತ್ಪನ್ನ ಘಟಕವು ಸಾಲಿನಲ್ಲಿ ಅಗತ್ಯವಿದೆ",
    productQuantityIsRequiredOnRow: "ಉತ್ಪನ್ನದ ಪ್ರಮಾಣವು ಸಾಲಿನಲ್ಲಿ ಅಗತ್ಯವಿದೆ",
    productVariantsRequireTracking:
      "ಉತ್ಪನ್ನ ರೂಪಾಂತರಗಳಿಗೆ ಟ್ರ್ಯಾಕಿಂಗ್ ಅಗತ್ಯವಿದೆ!",
    pleaseAddVariantClickButton:
      "ದಯವಿಟ್ಟು ಸೇರಿಸು ಗುಂಡಿಯನ್ನು ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ಒಂದು ರೂಪಾಂತರವನ್ನು ಸೇರಿಸಿ!",
    totalVariantsMoreThanSelectedQuantity:
      "ಒಟ್ಟು ರೂಪಾಂತರಗಳು ಆಯ್ದ ಉತ್ಪನ್ನದ ಪ್ರಮಾಣಕ್ಕಿಂತ ಹೆಚ್ಚು, Pls ಭಿನ್ನ ಪ್ರಮಾಣವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ",
    productEditedSuccessfully: "ಉತ್ಪನ್ನವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಎಡಿಟ್ ಮಾಡಲಾಗಿದೆ!",
    fileTooLargeLessThan4Mb:
      "ಫೈಲ್ ಗಾತ್ರ ತುಂಬಾ ದೊಡ್ಡದಾಗಿದೆ! ಫೈಲ್ ಗಾತ್ರ 4MB ಗಿಂತ ಕಡಿಮೆ ಇರಬೇಕು.",
    suchVariantAlreadyExist: "ಅಂತಹ ರೂಪಾಂತರವು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
    addVariants: "ರೂಪಾಂತರಗಳನ್ನು ಸೇರಿಸಿ",
    editProduct: "ಉತ್ಪನ್ನವನ್ನು ಸಂಪಾದಿಸಿ",
    pleaseEnterProductName: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterProductPrice: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterProductOriginalPrice: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನದ ಮೂಲ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    productDescription: "ಉತ್ಪನ್ನ ವಿವರಣೆ",
    selectProductCategory: "ಉತ್ಪನ್ನ ವರ್ಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    pleaseSelectProductCategory: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನ ವರ್ಗವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    productCostPrice: "ಉತ್ಪನ್ನದ ಬೆಲೆ",
    productSellingPrice: "ಉತ್ಪನ್ನ ಮಾರಾಟ ಬೆಲೆ",
    productOriginalPrice: "ಉತ್ಪನ್ನದ ಮೂಲ ಬೆಲೆ",
    maxFileSizeAllowedIs4Mb: "ಗರಿಷ್ಠ ಅನುಮತಿಸಲಾದ ಫೈಲ್ ಗಾತ್ರ 4 ಎಂಬಿ",
    productsWithPicturesArePublished:
      "ಚಿತ್ರಗಳನ್ನು ಹೊಂದಿರುವ ಉತ್ಪನ್ನಗಳನ್ನು ಆದೇಶಗಳನ್ನು ಸ್ವೀಕರಿಸಲು ಡಿಸ್ಕವರ್‌ನಲ್ಲಿ ಪ್ರಕಟಿಸಲಾಗಿದೆ",
    shouldThisProductBeTracked: "ಈ ಉತ್ಪನ್ನವನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಬೇಕೇ?",
    pleaseSelectStockUnit: "ದಯವಿಟ್ಟು ಸ್ಟಾಕ್ ಘಟಕವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    stockUnit: "ಸ್ಟಾಕ್ ಘಟಕ",
    bag: "ಬಿಎಜಿ",
    bottle: "ಬಾಟಲ್",
    bunch: "ಗುಂಪನ್ನು",
    can: "ಕ್ಯಾನ್",
    carton: "ಪೆಟ್ಟಿಗೆ",
    crate: "ಕ್ರೇಟ್",
    cup: "CUP",
    dozen: "ಡಜನ್",
    gigabytes: "ಗಿಗಾಬೈಟ್ಸ್",
    gram: "ಗ್ರಾಂ",
    kilogram: "ಕಿಲೋಗ್ರಾಮ್",
    litre: "LITER",
    pack: "ಪ್ಯಾಕ್",
    pair: "ಪೇಯರ್",
    pieces: "ತುಂಡುಗಳು",
    plate: "ಪ್ಲೇಟ್",
    tonne: "ಟೋನ್ (ಎಂಟಿ)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನದ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    productQuantity: "ಉತ್ಪನ್ನ ಪ್ರಮಾಣ",
    isThisProductTaxed: "ಈ ಉತ್ಪನ್ನಕ್ಕೆ ತೆರಿಗೆ ವಿಧಿಸಲಾಗಿದೆಯೇ?",
    selectTaxType: "ತೆರಿಗೆ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    pleaseSelectTaxType: "ದಯವಿಟ್ಟು ತೆರಿಗೆ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    progressive: "ಪ್ರಗತಿಪರ",
    proportional: "ಪ್ರಮಾಣಾನುಗುಣ",
    pleaseEnterProductTaxRate: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನ ತೆರಿಗೆ ದರವನ್ನು ನಮೂದಿಸಿ",
    doesProductHaveVariants: "ಈ ಉತ್ಪನ್ನವು ರೂಪಾಂತರಗಳನ್ನು ಹೊಂದಿದೆಯೇ?",
    type: "ಮಾದರಿ",
    value: "ಮೌಲ್ಯ",
    pleaseEnterVariantType: "ದಯವಿಟ್ಟು ರೂಪಾಂತರದ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterVariantValue: "ದಯವಿಟ್ಟು ರೂಪಾಂತರದ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterVariantPrice: "ದಯವಿಟ್ಟು ರೂಪಾಂತರದ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterVariantQuantity: "ದಯವಿಟ್ಟು ರೂಪಾಂತರದ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    productDeletedSuccessfully: "ಉತ್ಪನ್ನವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
    categoryDeletedSuccessfully: "ವರ್ಗವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ!",
    toDeleteThisProduct: "ಈ ಉತ್ಪನ್ನವನ್ನು ಅಳಿಸಲು",
    invalidProductQuantity: "ಅಮಾನ್ಯ ಉತ್ಪನ್ನ ಪ್ರಮಾಣ",
    quantityAddedIsOverStock:
      "ನೀವು ಸೇರಿಸುವ ಪ್ರಮಾಣವು ನಿಮ್ಮ ಸ್ಟಾಕ್‌ನಲ್ಲಿರುವುದಕ್ಕಿಂತ ಹೆಚ್ಚಾಗಿದೆ.",
    itemInventoryNotTracked: "ಐಟಂ ದಾಸ್ತಾನು ಟ್ರ್ಯಾಕ್ ಆಗಿಲ್ಲ",
    addBulkQuantity: "ಬೃಹತ್ ಪ್ರಮಾಣವನ್ನು ಸೇರಿಸಿ",
    enterBulkQuantity: "ಬೃಹತ್ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterBulkQuantity: "ದಯವಿಟ್ಟು ಬೃಹತ್ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    youveSuccessfullyAddedANewProduct:
      "ನೀವು ಹೊಸ ಉತ್ಪನ್ನವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ!",
    pleaseEnterProductSellingPrice: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನ ಮಾರಾಟ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    doYouWantToTrackProductStock:
      "ನೀವು ಉತ್ಪನ್ನ ಸ್ಟಾಕ್ ಅನ್ನು ಟ್ರ್ಯಾಕ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
    sellingPrice: "ಮಾರಾಟ ಬೆಲೆ",
    setProductExpiryReminder: "ಉತ್ಪನ್ನದ ಮುಕ್ತಾಯ ಜ್ಞಾಪನೆಯನ್ನು ಹೊಂದಿಸಿ",
    productExpiryDate: "ಉತ್ಪನ್ನದ ಮುಕ್ತಾಯ ದಿನಾಂಕ",
    startRemindingFrom: "ಇಂದ ಜ್ಞಾಪಿಸಲು ಪ್ರಾರಂಭಿಸಿ",
    productSuppliesAddedSuccessfully:
      "ನೀವು ಉತ್ಪನ್ನ ಪೂರೈಕೆಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ.",
    addProductSupplies: "ಉತ್ಪನ್ನ ಪೂರೈಕೆಗಳನ್ನು ಸೇರಿಸಿ",
    pleaseSelectSupplier: "ದಯವಿಟ್ಟು ಪೂರೈಕೆದಾರರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    nameOfProduct: "ಉತ್ಪನ್ನದ ಹೆಸರು",
    pleaseSelectProduct: "ದಯವಿಟ್ಟು ಉತ್ಪನ್ನವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    productVariant: "ಉತ್ಪನ್ನ ವ್ಯತ್ಯಾಸ",
    pleaseSelectAVariant: "ದಯವಿಟ್ಟು ಒಂದು ವೇರಿಯಂಟ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    pleaseEnterUnitPrice: "ದಯವಿಟ್ಟು ಘಟಕದ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    businessBranch: "ವ್ಯಾಪಾರ ಶಾಖೆ",
    pleaseSelectBranch: "ದಯವಿಟ್ಟು ಶಾಖೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    youveSuccessfullyAddedANewSupplier:
      "ನೀವು ಹೊಸ ಪೂರೈಕೆದಾರರನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಿದ್ದೀರಿ",
    addSupplier: "ಪೂರೈಕೆದಾರರನ್ನು ಸೇರಿಸಿ",
    pleaseEnterSupplierEmail: "ದಯವಿಟ್ಟು ಪೂರೈಕೆದಾರರ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    pleaseAddADescription: "ದಯವಿಟ್ಟು ವಿವರಣೆಯನ್ನು ಸೇರಿಸಿ",
    anErrorOccuredProductsDeleted:
      "ಕಾರ್ಯಾಚರಣೆ ಮಾಡುವಾಗ ದೋಷ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ಗಮನಿಸಿ, ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ಕೆಲವು ಉತ್ಪನ್ನಗಳನ್ನು ಅಳಿಸಿರಬಹುದು",
    bulkDelete: "ಬೃಹತ್ ಅಳಿಸಿ",
    youAreAboutToDelete: "ನೀವು ಅಳಿಸಲು ಹೊರಟಿದ್ದೀರಿ",
    product: "ಉತ್ಪನ್ನ",
    isDueToRedeem: "ರಿಡೀಮ್ ಕಾರಣ",
    aReward: "ಒಂದು ಪ್ರತಿಫಲ",
    pleaseSelectCustomerToReeemReward:
      "ಬಹುಮಾನವನ್ನು ಪಡೆದುಕೊಳ್ಳಲು ದಯವಿಟ್ಟು ಗ್ರಾಹಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ.",
    youHaveNoLoyaltyProgramRunning:
      "ನೀವು ಯಾವುದೇ ಸಕ್ರಿಯ ಲಾಯಲ್ಟಿ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ನಡೆಸುತ್ತಿಲ್ಲ",
    customerHhasNoPointsInLoyaltyProgram:
      "ಈ ನಿಷ್ಠೆ ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಗ್ರಾಹಕರಿಗೆ ಯಾವುದೇ ಅಂಕಗಳಿಲ್ಲ",
    proceedWithPayment: "ಪಾವತಿಯೊಂದಿಗೆ ಮುಂದುವರಿಯುವುದೇ?",
    youAreAboutToPayForTransactionUsingPoints:
      "ನೀವು ಅಂಕಗಳನ್ನು ಬಳಸಿ ವಹಿವಾಟಿಗೆ ಪಾವತಿಸಲಿದ್ದೀರಿ.",
    customerHas: "ಗ್ರಾಹಕ ಹೊಂದಿದೆ",
    worth: "ಮೌಲ್ಯದ",
    andIsNotSufficientToPayForTransaction:
      "ಮತ್ತು ಈ ವಹಿವಾಟಿಗೆ ಪಾವತಿಸಲು ಸಾಕಾಗುವುದಿಲ್ಲ. ಅವರು ಇನ್ನೊಂದು ಪಾವತಿ ವಿಧಾನವನ್ನು ಬಳಸಿಕೊಂಡು ಬಾಕಿಯನ್ನು ಸೇರಿಸಲು ಬಯಸುತ್ತಾರೆಯೇ?",
    addCustomerLoyalty: "ಗ್ರಾಹಕರ ನಿಷ್ಠೆಯನ್ನು ಸೇರಿಸಿ",
    pleaseAddCustomerFirst:
      "ದಯವಿಟ್ಟು ಮೊದಲು ಗ್ರಾಹಕರನ್ನು ಸೇರಿಸಿ ಅಥವಾ ಆಯ್ಕೆ ಮಾಡಿ.",
    pleaseWaitWhileWeGetReady: "ನಾವು ತಯಾರಾಗುವಾಗ ದಯವಿಟ್ಟು ಕಾಯಿರಿ",
    lowStock: "ಕಡಿಮೆ ಸ್ಟಾಕ್",
    pleaseEnterAmountTendered: "ದಯವಿಟ್ಟು ಟೆಂಡರ್ ಮಾಡಿದ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    areYouSureToBookSaleOffline:
      "ನೀವು ಈ ಮಾರಾಟವನ್ನು ಆಫ್‌ಲೈನ್‌ನಲ್ಲಿ ಬುಕ್ ಮಾಡಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
    saleWouldBeBookedAutomatically:
      "ನಿಮ್ಮ ಇಂಟರ್ನೆಟ್ ಆನ್ ಮಾಡಿದಾಗ ಮಾರಾಟವನ್ನು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಬುಕ್ ಮಾಡಲಾಗುತ್ತದೆ",
    offlineSalesBookingCancelled: "ಆಫ್‌ಲೈನ್ ಮಾರಾಟ ಬುಕಿಂಗ್ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
    covid19Message:
      "ಕೋವಿಡ್ 19: ಹರಡುವುದನ್ನು ತಡೆಯಲು ನಿಮ್ಮ ಕೈಗಳನ್ನು ಸೋಪಿನಿಂದ ತೊಳೆಯಿರಿ ಅಥವಾ ಸ್ಯಾನಿಟೈಸ್ ಮಾಡಿ. ಯಾವಾಗಲೂ ಸುರಕ್ಷಿತವಾಗಿರಿ",
    saleSuccessfullyRecorded: "ಮಾರಾಟವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ದಾಖಲಿಸಲಾಗಿದೆ!",
    sendReceiptToEmail: "ಇಮೇಲ್ ಗೆ ರಶೀದಿ ಕಳುಹಿಸಿ",
    sendThankYouSms: "ಧನ್ಯವಾದ SMS ಕಳುಹಿಸಿ",
    sendShippingDetails: "ಶಿಪ್ಪಿಂಗ್ ವಿವರಗಳನ್ನು ಕಳುಹಿಸಿ",
    addLoyalty: "ನಿಷ್ಠೆಯನ್ನು ಸೇರಿಸಿ",
    searchCustomerNameOrNumber: "ಗ್ರಾಹಕರ ಹೆಸರು ಅಥವಾ ಸಂಖ್ಯೆಯನ್ನು ಹುಡುಕಿ",
    clickTheSearchCustomerBox:
      "ಸರ್ಚ್ ಕಸ್ಟಮರ್ ಬಾಕ್ಸ್ ಮತ್ತು ಸ್ಕ್ಯಾನ್ ಕಾರ್ಡ್ ಕ್ಲಿಕ್ ಮಾಡಿ",
    enterProductPrice: "ಉತ್ಪನ್ನ ಬೆಲೆಯನ್ನು ನಮೂದಿಸಿ",
    enterPriceFor: "ಬೆಲೆ ನಮೂದಿಸಿ",
    searchForProduct: "ಉತ್ಪನ್ನಕ್ಕಾಗಿ ಹುಡುಕಿ",
    all: "ಎಲ್ಲಾ",
    backToDashboard: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗೆ ಹಿಂತಿರುಗಿ",
    viewDraftSales: "ಡ್ರಾಫ್ಟ್ ಮಾರಾಟವನ್ನು ವೀಕ್ಷಿಸಿ",
    variantSelected: "ರೂಪಾಂತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲಾಗಿದೆ",
    variant: "ಭಿನ್ನ",
    thePreviousVariantSelectionNotAvailable:
      "ಬೆಲೆಯ ಆಧಾರದ ಮೇಲೆ ಆಯ್ಕೆ ಮಾಡಲಾದ ಹೊಸ ರೂಪಾಂತರಕ್ಕೆ ಹಿಂದಿನ ರೂಪಾಂತರದ ಆಯ್ಕೆ ಲಭ್ಯವಿಲ್ಲ, ದಯವಿಟ್ಟು ನಿಮ್ಮ ಆಯ್ಕೆಯನ್ನು ಬದಲಾಯಿಸಿ.",
    pleaseSupplyPositiveQuantityNumber:
      "ದಯವಿಟ್ಟು ಸಕಾರಾತ್ಮಕ ಪ್ರಮಾಣ ಸಂಖ್ಯೆಯನ್ನು ಒದಗಿಸಿ",
    lowStockFor: "ಗೆ ಕಡಿಮೆ ಸ್ಟಾಕ್",
    clearVariants: "ತೆರವುಗೊಳಿಸಿದ ರೂಪಾಂತರಗಳು",
    pleaseEnterQuantity: "ದಯವಿಟ್ಟು ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    picture: "ಚಿತ್ರ",
    redemptionToken: "ರಿಡೆಂಪ್ಶನ್ ಟೋಕನ್",
    token: "ಟೋಕನ್",
    totalSpent: "ಒಟ್ಟು ಖರ್ಚು",
    confirmPayment: "ಪಾವತಿಯನ್ನು ದೃmೀಕರಿಸಿ",
    hasPaymentBeenMade: "ಪಾವತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗಿದೆಯೇ?",
    enterTransactionReference: "ನೀವು ಪಾವತಿಸಿದ ವಹಿವಾಟು ಉಲ್ಲೇಖವನ್ನು ನಮೂದಿಸಿ",
    pleaseSelectACustomer: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ!",
    areYouSureToApplyDiscount: "ನೀವು ರಿಯಾಯಿತಿ ಅನ್ವಯಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
    addYourBankAccountToEnableUssd:
      "UPay ಮೂಲಕ ತ್ವರಿತ USSD ವರ್ಗಾವಣೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಸೇರಿಸಿ",
    totalAmountToPay: "ಪಾವತಿಸಬೇಕಾದ ಒಟ್ಟು ಮೊತ್ತ",
    doYouWantToCancelTransaction: "ನೀವು ಈ ವಹಿವಾಟನ್ನು ರದ್ದುಗೊಳಿಸಲು ಬಯಸುವಿರಾ?",
    savePrintBill: "ಬಿಲ್ ಉಳಿಸಿ/ಮುದ್ರಿಸಿ",
    enterAmountCollectedForCustomer:
      "ಗ್ರಾಹಕರಿಗಾಗಿ ಸಂಗ್ರಹಿಸಿದ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    recordSale: "ದಾಖಲೆ ಮಾರಾಟ",
    checkOutWith: "ಇದರೊಂದಿಗೆ ಪರಿಶೀಲಿಸಿ",
    instantTransfer: "ತ್ವರಿತ ವರ್ಗಾವಣೆ",
    dialTheUSSDCode: "USSD ಕೋಡ್ ಅನ್ನು ಡಯಲ್ ಮಾಡಿ",
    pleaseSelectABank: "ದಯವಿಟ್ಟು ಬ್ಯಾಂಕ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    payWithUSSD: "USSD ಮೂಲಕ ಪಾವತಿಸಿ",
    sendBillTo: " - ಗೆ ಬಿಲ್ ಕಳುಹಿಸಿ",
    waitingForUSSDTransfer: "USSD ವರ್ಗಾವಣೆಗಾಗಿ ಕಾಯಲಾಗುತ್ತಿದೆ",
    percent: "ಶೇಕಡಾ",
    applyDiscount: "ರಿಯಾಯಿತಿ ಅನ್ವಯಿಸಿ",
    thisBillHasBeenSaved: "ಈ ಬಿಲ್ ಅನ್ನು ಉಳಿಸಲಾಗಿದೆ",
    saveDraft: "ಕರಡು ಉಳಿಸು",
    pleaseTypeANameToIdentifyCustomer:
      "ಗ್ರಾಹಕರನ್ನು ಗುರುತಿಸಲು ದಯವಿಟ್ಟು ಹೆಸರನ್ನು ಟೈಪ್ ಮಾಡಿ",
    paymentDetails: "ಪಾವತಿ ವಿವರಗಳು",
    basePrice: "ಮೂಲ ಬೆಲೆ",
    staff: "ಸಿಬ್ಬಂದಿ",
    subTotal: "ಉಪಮೊತ್ತ",
    durationMonths: "ಅವಧಿ (ತಿಂಗಳುಗಳು)",
    selectADuration: "ಒಂದು ಅವಧಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    oneMonth: "1 ತಿಂಗಳು",
    twoMonths: "2 ತಿಂಗಳ",
    threeMonths: "3 ತಿಂಗಳುಗಳು",
    sixMonths: "6 ತಿಂಗಳು",
    twelveMonths: "12 ತಿಂಗಳು",
    eighteenMonths: "18 ತಿಂಗಳು",
    twentyFourMonths: "24 ತಿಂಗಳು",
    twoMonthsFree: "(2 ತಿಂಗಳು ಉಚಿತ)",
    threeMonthsFree: "(3 ತಿಂಗಳು ಉಚಿತ)",
    fiveMonthsFree: "(5 ತಿಂಗಳು ಉಚಿತ)",
    yourAccountHasBeen: "ನಿಮ್ಮ ಖಾತೆ ಇದೆ",
    renewed: "ನವೀಕೃತ",
    upgraded: "ಉನ್ನತೀಕರಿಸಲಾಗಿದೆ",
    successfully: "ಯಶಸ್ವಿಯಾಗಿ",
    yourSubscription: "ನಿಮ್ಮ ಚಂದಾದಾರಿಕೆ",
    youAreCurrentlyEnrolledOnA: "ನೀವು ಪ್ರಸ್ತುತ a ನಲ್ಲಿ ದಾಖಲಾಗಿದ್ದೀರಿ",
    pleaseChooseAPaymentOption: "ದಯವಿಟ್ಟು ಪಾವತಿ ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ",
    planRenewal: "ಯೋಜನೆ ನವೀಕರಣ",
    planUpgrade: "ಯೋಜನೆ ಅಪ್‌ಗ್ರೇಡ್",
    pleaseSelectDurationToPayFor:
      "ದಯವಿಟ್ಟು ನೀವು ಪಾವತಿಸಲು ಉದ್ದೇಶಿಸಿರುವ ಅವಧಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    staffAccounts: "ಸಿಬ್ಬಂದಿ ಖಾತೆಗಳು",
    ecommerce: "ಇಕಾಮರ್ಸ್",
    pleaseSelectAPlan: "ದಯವಿಟ್ಟು ಯೋಜನೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    includeAddons: "ಆಡ್-ಆನ್‌ಗಳನ್ನು ಸೇರಿಸಿ",
    viewTransactions: "ವಹಿವಾಟುಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    customerHasNoCompletedTansactions: "ಗ್ರಾಹಕರು ಇನ್ನೂ ಪೂರ್ಣಗೊಳಿಸಿಲ್ಲ",
    branch: "ಶಾಖೆ",
    enterNumberOfEcommerceBranches: "ಇಕಾಮರ್ಸ್ ಶಾಖೆಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    enterNumberOfEcommerceBranchesToPay:
      "ನೀವು ಪಾವತಿಸಲು ಉದ್ದೇಶಿಸಿರುವ ಇಕಾಮರ್ಸ್ ಶಾಖೆಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    ecommerceIntegration: "ಇಕಾಮರ್ಸ್ ಏಕೀಕರಣ",
    enterNumberOfBranches: "ಶಾಖೆಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    enterNumberOfAdditionalBranchesToPay:
      "ನೀವು ಪಾವತಿಸಲು ಉದ್ದೇಶಿಸಿರುವ ಹೆಚ್ಚುವರಿ ಶಾಖೆಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    enterNumberOfStaffs: "ಸಿಬ್ಬಂದಿ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    enterNumberOfStaffsToPayFor:
      "ನೀವು ಪಾವತಿಸಲು ಉದ್ದೇಶಿಸಿರುವ ಸಿಬ್ಬಂದಿಗಳ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    discountApplies: "ರಿಯಾಯಿತಿ ಅನ್ವಯಿಸುತ್ತದೆ",
    starsOnThe: "ಮೇಲೆ ನಕ್ಷತ್ರಗಳು",
    offer: "ಆಫರ್",
    get: "ಪಡೆಯಿರಿ",
    moreStarsToRedeem: "ರಿಡೀಮ್ ಮಾಡಲು ಹೆಚ್ಚು ನಕ್ಷತ್ರಗಳು",
    taxVat: "ತೆರಿಗೆ (ವ್ಯಾಟ್)",
    callCashierToCompletePayment:
      "ಪಾವತಿಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಕ್ಯಾಷಿಯರ್‌ಗೆ ಕರೆ ಮಾಡಿ",
    receipt: "ರಶೀದಿ",
    dear: "ಪ್ರೀತಿಯ",
    thankYouForYourOrderFindGoods:
      "ನಿಮ್ಮ ಆದೇಶಕ್ಕೆ ಧನ್ಯವಾದಗಳು. ದಯವಿಟ್ಟು ಒಪ್ಪಿದಂತೆ ಈ ಕೆಳಗಿನ ಸರಕುಗಳನ್ನು ಸರಬರಾಜು ಮಾಡಿ.",
    shippingNote: "ಶಿಪ್ಪಿಂಗ್ ಟಿಪ್ಪಣಿ",
    enterShippingNote: "ಶಿಪ್ಪಿಂಗ್ ಟಿಪ್ಪಣಿ ನಮೂದಿಸಿ",
    shippingAddress: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸ",
    enterShippingAddress: "ಶಿಪ್ಪಿಂಗ್ ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    wellDoneYouAreDueToRedeem: "ಚೆನ್ನಾಗಿದೆ! ನೀವು ವಿಮೋಚನೆಗೆ ಕಾರಣರಾಗಿದ್ದೀರಿ",
    toGetYourRewardNextVisit:
      "ನಿಮ್ಮ ಮುಂದಿನ ಭೇಟಿಯಲ್ಲಿ ನಿಮ್ಮ ಪ್ರತಿಫಲವನ್ನು ಪಡೆಯಲು. ಧನ್ಯವಾದ",
    pointsOnThe: "ಮೇಲೆ ಅಂಕಗಳು",
    morePointsToRedeem: "ರಿಡೀಮ್ ಮಾಡಲು ಹೆಚ್ಚಿನ ಅಂಕಗಳು",
    showCode: "ಕೋಡ್ ತೋರಿಸಿ",
    toGetYourRewardOnNextVisit:
      "ನಿಮ್ಮ ಮುಂದಿನ ಭೇಟಿಯಲ್ಲಿ ನಿಮ್ಮ ಪ್ರತಿಫಲವನ್ನು ಪಡೆಯಲು. ಧನ್ಯವಾದ",
    earn: "ಗಳಿಸು",
    delivaryNote: "ವಿತರಣಾ ಟಿಪ್ಪಣಿ",
    draftSales: "ಕರಡು ಮಾರಾಟ",
    startDate: "ಪ್ರಾರಂಭ ದಿನಾಂಕ",
    endDate: "ಅಂತಿಮ ದಿನಾಂಕ",
    orders: "ಆದೇಶಗಳು",
    checkout: "ಚೆಕ್ಔಟ್",
    noProductItem: "ಉತ್ಪನ್ನ ಐಟಂ ಇಲ್ಲ",
    selectProductImage: "ಉತ್ಪನ್ನ ಚಿತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    selectCountry: "ದೇಶವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    selectRegion: "ರಾಜ್ಯ/ಪ್ರದೇಶವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    printProductTag: "ಉತ್ಪನ್ನ ಟ್ಯಾಗ್ ಮುದ್ರಿಸಿ",
    transactionReference: "ವಹಿವಾಟು ಉಲ್ಲೇಖ",
    Cashier: "ಕ್ಯಾಷಿಯರ್",
    Manager: "ಮ್ಯಾನೇಜರ್",
    Owner: "ಮಾಲೀಕ",
    Admin: "ನಿರ್ವಹಣೆ",
    addPartners: "ಪಾಲುದಾರರನ್ನು ಸೇರಿಸಿ",
    addNewLoyaltyPartner: "ಹೊಸ ಲಾಯಲ್ಟಿ ಪಾಲುದಾರರನ್ನು ಸೇರಿಸಿ",
    pleaseEnterCompanyName: "ದಯವಿಟ್ಟು ಕಂಪನಿಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    companyName: "ಸಂಸ್ಥೆಯ ಹೆಸರು",
    pleaseEnterCompanyRepName: "ದಯವಿಟ್ಟು ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    companyRepName: "ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿಯ ಹೆಸರು",
    pleaseEnterCompanyRepEmail:
      "ದಯವಿಟ್ಟು ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿಯ ಇಮೇಲ್ ಅನ್ನು ನಮೂದಿಸಿ",
    companyRepEmail: "ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿಯ ಇಮೇಲ್",
    pleaseEnterCompanyRepPhone:
      "ದಯವಿಟ್ಟು ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿಯ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    companyRepPhone: "ಕಂಪನಿಯ ಪ್ರತಿನಿಧಿಯ ಫೋನ್ ಸಂಖ್ಯೆ",
    addReward: "ಬಹುಮಾನವನ್ನು ಸೇರಿಸಿ",
    pleaseEnterRewardName: "ದಯವಿಟ್ಟು ಬಹುಮಾನದ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    rewardName: "ಬಹುಮಾನದ ಹೆಸರು",
    rewardQuantity: "ಬಹುಮಾನದ ಪ್ರಮಾಣ",
    rewardDescription: "ಬಹುಮಾನದ ವಿವರಣೆ",
    rewardType: "ಬಹುಮಾನದ ಪ್ರಕಾರ",
    pleaseEnterRewardType: "ದಯವಿಟ್ಟು ಬಹುಮಾನದ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterRewardQuantity: "ದಯವಿಟ್ಟು ಬಹುಮಾನದ ಪ್ರಮಾಣವನ್ನು ನಮೂದಿಸಿ",
    pleaseEnterRewardDescription: "ದಯವಿಟ್ಟು ಬಹುಮಾನದ ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
    fineDining: "ಫೈನ್ ಡೈನಿಂಗ್",
    luxuryFashion: "ಐಷಾರಾಮಿ ಫ್ಯಾಷನ್",
    hotels: "ಹೋಟೆಲ್‌ಗಳು",
    travel: "ಪ್ರಯಾಣ",
    foodAndBeverage: "ಆಹಾರ ಮತ್ತು ಪಾನೀಯ",
    fashion: "ಫ್ಯಾಷನ್",
    health: "ಆರೋಗ್ಯ",
    furniture: "ಪೀಠೋಪಕರಣಗಳು",
    entertainment: "ಮನರಂಜನೆ",
    automobile: "ಆಟೋಮೊಬೈಲ್",
    education: "ಶಿಕ್ಷಣ",
    beautyAndSpa: "ಬ್ಯೂಟಿ ಮತ್ತು ಸ್ಪಾ",
    staycation: "ತಂಗುವಿಕೆ",
    events: "ಕಾರ್ಯಕ್ರಮಗಳು",
    trips: "ಪ್ರವಾಸಗಳು",
    oilAndGas: "ಎಣ್ಣೆ ಮತ್ತು ಅನಿಲ",
    laundry: "ಲಾಂಡ್ರಿ",
    partnerCategory: "ಪಾಲುದಾರ ವರ್ಗ",
    freeItem: "ಉಚಿತ ಐಟಂ",
  },
  Javanese: {
    cashier: "kasir",
    manager: "manager",
    owner: "sing duwe",
    online: "online",
    offline: "offline",
    changePassword: "Ganti Sandhi",
    currentPasswordMessage: "Ketik sandhi sampeyan saiki",
    passwordMessage: "Ketik sandhi",
    currentPassword: "Tembung Sandi Saiki",
    password: "sandhi",
    confirmPasswordMessage: "Tulung konfirmasi sandhi sampeyan!",
    confirmPassword: "Konfirmasi Sandi",
    sendViaEmail: "Kirim Liwat Email",
    sendViaWhatsapp: "Kirim Liwat WhatsApp",
    downloadPdf: "Download PDF",
    paid: "mbayar",
    unpaid: "ora dibayar",
    partial: "sebagean",
    closeInvoice: "Apa sampeyan pengin nutup Invoice?",
    closeInvoiceConfirmation:
      "Invoice bisa uga ora disimpen. Apa sampeyan pengin nutup?",
    yes: "iya",
    no: "ora",
    invoice: "Invoice",
    wasDeducted: "dipotong",
    for: "kanggo",
    item: "Barang",
    addProduct: "Tambah Produk",
    paymentReference: "Referensi Pembayaran",
    amountPaid: "Jumlah sing Dibayar",
    discountAmount: "Jumlah Diskon",
    amountDue: "Jumlah Amarga",
    amount: "Jumlah",
    dueDate: "Tanggal Akibaté",
    paymentType: "Jinis Pembayaran",
    card: "Kertu",
    cash: "Awis",
    bankTransfer: "Transfer Bank",
    paymentMessage: "Pesen Pembayaran",
    description: "Katrangan",
    sendReceipt: "Kirim Resi",
    delete: "Mbusak",
    save: "Ngirit",
    resend: "Ngirim maneh",
    saveAndSend: "Ngirit",
    invoiceSaved: "Invoice disimpen!",
    selectPaymentMethod: "Pilih metode Pembayaran!",
    selectCustomer: "Pilih Pelanggan!",
    cartEmptyError:
      "Dhaptar Kranjang ora bisa kosong, tutup invoice banjur tambahake Barang menyang kranjang!",
    subscriptionExpired:
      "Lengganan sampeyan wis kadaluwarsa lan akun sampeyan saiki wis diwatesi. Klik ing Tombol ing ngisor iki kanggo nganyari akun sampeyan",
    renew: "Gawe anyar",
    holdOn: "Terus",
    customerBank: "Bank Pelanggan",
    cancel: "Batal",
    selectACustomer: "Pilih Pelanggan",
    invoiceSuccessfulPdfError:
      "Invoice wis digawe kanthi sukses nanging Generasi PDF luwih suwe tinimbang biasane. Mangga dipriksa maneh sakcepete.",
    downloadingInvoice: "Ngundhuh Invoice",
    downloadingReceipt: "Ngundhuh Resi",
    whatsappReceiptError:
      "Ana kesalahan nalika ngirim kuitansi liwat WhatsApp, Coba maneh.",
    receiptToWhatsapp: "Resit diterusake menyang WhatsApp",
    thankYouForPatronage: "Matur nuwun kanggo patronase sampeyan",
    hereIsYourReceipt: "Iki kuitansi pembayaran sampeyan",
    errorSendingEmailReceipt:
      "Ana kesalahan nalika ngirim kuitansi liwat e-mail, coba coba maneh utawa hubungi dhukungan",
    receiptSentToEmail: "Resi wis dikirim menyang email pelanggan",
    invoiceSentToEmail: "Invoice wis dikirim menyang email pelanggan",
    invoiceSuccessWhatsappError:
      "Invoice digawe kanthi sukses nanging ana kesalahan nalika ngirim WhatsApp. Coba maneh ing dhaptar invoice",
    invoiceSuccessfulEmailError:
      "Invoice digawe kanthi sukses nanging ana kesalahan nalika ngirim email. Coba maneh saka dhaptar invoice",
    invoiceSentToWhatsapp: "Invoice diterusake menyang WhatsApp",
    hello: "Halo",
    pleaseDownloadInvoice: "Mangga download faktur",
    pleaseDownloadReceipt: "Mangga ndownload resi",
    from: "saka",
    email: "Email",
    upgrade: "Nganyarke",
    youAreOnFreePlan: "Sampeyan ana ing rencana gratis.",
    clickOn: "Klik ing",
    yourPlanInFewSteps: " lengganan sampeyan kanthi sawetara langkah cepet.",
    to: "kanggo",
    yourSubscriptionHasExpired:
      "Lengganan sampeyan wis kadaluwarsa lan akun sampeyan saiki wis diwatesi.",
    days: "dina",
    yourSubscriptionExpiresIn: "Langganan Loystar sampeyan kadaluwarsa ing",
    createAcount: "Gawe Akun",
    signIn: "Mlebu",
    continue: "Terusake",
    enterOtp: "Ketik PIN OTP",
    enterValidOtp: "Ketik PIN sing valid",
    pin: "PIN",
    tokenSentToMail: "Token wis dikirim menyang email sampeyan",
    passwordResetSuccessful: "Reset sandhi sukses",
    somethingWentWrong: "Ana sing salah!",
    resetPassword: "Reset Sandi",
    iHaveResetCode: "Aku duwe kode reset password",
    pleaseEnterEmail: "Ketik email sampeyan",
    aTokenWillBeSentToEmail: "Token bakal dikirim menyang email sampeyan",
    enterEmail: "Ketik email sampeyan",
    sendinYourToken: "Ngirim token sampeyan ...",
    makeSureItMatchesPassword: "Priksa manawa cocog karo sandhi anyar sampeyan",
    pleaseChooseNewPassword: "Pilih sandhi anyar",
    chooseNewPassword: "Pilih sandhi anyar",
    enterNewPassword: "Ketik sandhi anyar kanggo konfirmasi",
    enterTokenSent: "Ketik token sing dikirim menyang mail sampeyan",
    resetToken: "Reset Token",
    resettingPassword: "Nyetel maneh Sandhi ...",
    signUp: "Ndhaptar",
    adminSignInWithEmail:
      " Admin mlebu nganggo email nalika Staff mlebu nganggo jeneng pangguna.",
    pleaseEnterEmailOrUsername: "Ketik email utawa jeneng pangguna sampeyan",
    emailOrUsername: "Email utawa Jeneng Pangguna",
    pleaseEnterPassword: "Ketik sandhi",
    createAnAccount: "Gawe Akun",
    forgotPassword: "Kelalen Sandi?",
    enterPhoneNumber: "Ketik Nomer Telpon",
    personalData: "Data Pribadi",
    validateConfirmationCOde: "Validasi Kode Konfirmasi",
    pleaseEnterFirstName: "Ketik jeneng ngarep sampeyan",
    pleaseEnterPhoneNumber: "Ketik nomer telpon sampeyan",
    pleaseEnterLastName: "Ketik jeneng mburi sampeyan",
    pleaseEnterBusinessName: "Ketik jeneng bisnis sampeyan",
    firstName: "Jeneng Pertama",
    lastName: "Jeneng mburi",
    businessName: "Jeneng Bisnis",
    previous: "Sadurunge",
    next: "Sabanjure",
    pleaseSelectBusinessCategory: "Pilih kategori bisnis sampeyan",
    pleaseEnterValidEmail: "Ketik email sing valid",
    pleaseEnterPostCode: "Ketik kode pos",
    postCode: "Kode Pos",
    phoneNumberInUse: "Nomer telpon iki wis digunakake!",
    emailInUse: "Email iki wis digunakake!",
    foodAndDrinks: "Panganan lan Ombenan",
    salonAndBeauty: "Salon lan Kaendahan",
    fashionAndAccessories: "Mode lan Aksesoris",
    gymAndFitness: "Gym lan Fitness",
    travelAndHotel: "Plancongan lan Hotel",
    homeAndGifts: "Omah lan Hadiah",
    washingAndCleaning: "Cuci lan Reresik",
    gadgetsAndElectronics: "Gadget lan Elektronika",
    groceries: "Bahan bakar",
    others: "Liyane",
    submit: "Kirim",
    accountCreatedSuccessful: "Akun sampeyan digawe kanthi sukses.",
    pleaseEnterAddress: "Mangga Ketik Alamat Sampeyan",
    addressLine1: "Baris Alamat 1",
    addressLine2: "Baris Alamat 2",
    choosePassword: "Pilih Sandi",
    passwordMustBe6Characters: "Sandhi kudu paling ora 6 karakter.",
    howDidYouHearLoystar: "Kepiye sampeyan krungu babagan Loystar?",
    referralCode: "Kode referensi",
    byClickingTheButton:
      " Kanthi ngeklik tombol ing ngisor iki, sampeyan setuju",
    termsAndSevice: "Katemtuan",
    wereCreatingAccount: "Kita nggawe akun sampeyan",
    proceed: "Nerusake",
    verificationCodeMustBe6: "Kode Verifikasi kudu 6 digit",
    pleaseEnter6DigitCode: "Mangga Ketik kode 6 digit",
    enterVerificationCode: "Ketik Kode Verifikasi",
    resendToken: "Kirim ulang Token",
    verify: "Verifikasi",
    transactions: "Transaksi",
    todaySales: "Penjualan Dina Iki",
    salesHistory: "Sejarah Penjualan",
    supplyHistory: "Sejarah Pasokan",
    new: "Anyar",
    invoices: "Invoice",
    disbursements: "Pengeluaran",
    offlineSales: "Penjualan Offline",
    products: "Produk",
    customers: "Pelanggan",
    multiLevelLoyalty: "Kasetyan Multi-Level",
    loyaltyPrograms: "Program Kasetyan",
    members: "Anggota",
    appStore: "Toko App",
    orderMenu: "Pesen Menu",
    settings: "Setelan",
    staffAndBranches: "Staff lan Cabang",
    myAccount: "Akunku",
    switchToSellMode: "Ngalih menyang Mode Jual",
    signOut: "Mlebu",
    getFreeSubscription: "Entuk Lengganan Gratis",
    onlyNumbersAllowed: "Mung nomer sing diidini",
    yourAccountMustBe10Digits: "nomer akun sampeyan kudu nomer 10 digit",
    yourBvnMustBe11: "BVN sampeyan kudu nomer 11 digit",
    pleaseSelectBank: "Pilih bank sampeyan",
    selectYourBank: "Pilih Bank sampeyan",
    enterBankAccountNumber: "Ketik Nomer Akun Bank",
    enterBvnNumber: "Ketik BVN sampeyan",
    connectBankAccount: "Sambungake Akun Bank",
    passwordResetSuccessfulAndSignOut:
      "Sandi sampeyan wis disetel maneh kanthi sukses. Klik ing tombol Sign Out ing ngisor iki kanggo mlebu maneh",
    enterCurrentPassword: "Ketik sandhi saiki",
    pleaseEnterCurrentPassword: "Ketik sandhi saiki",
    phoneNumber: "Nomer Telpon",
    sex: "Jinis",
    dateOfBirth: "Tanggal Lair",
    state: "Negara",
    country: "Negara",
    loyaltyId: "ID Kasetyan",
    createdAt: "Digawe Ing",
    noOfTransactionsMade: "Nomer transaksi sing digawe",
    yourDownloadWillStart: "Unduhan sampeyan bakal diwiwiti sedhela",
    exportCustomers: "Ekspor Pelanggan",
    youhaveSuccessfullyToppedUp:
      "Sampeyan wis sukses nganyarke Unit SMS sampeyan.",
    youNowHave: "Sampeyan saiki duwe",
    smsUnits: "Unit SMS",
    enterNumberOfSmsUnits: "Ketik Jumlah Unit SMS sing Kudu Dituku",
    pleaseEnterANumericValue: "Ketik angka angka",
    pay: "Mbayar",
    accountEditedSuccessfully: "Akun sampeyan wis diowahi kanthi sukses.",
    youAeCurrentlyOn: "Sampeyan saiki lagi aktif",
    plan: "Rencana",
    userData: "Data Panganggo",
    businessData: "DATA BISNIS",
    businessCategory: "Bisnis Catergory",
    pleaseSelectCurrency: "Pilih mata uang sampeyan",
    selectYourCurrency: "Pilih mata uang sampeyan",
    purchaseMoreSmsUnits:
      "Tuku liyane Unit SMS nggunakake formulir ing ngisor iki",
    youHave: "Sampeyan duwe",
    atLeast4SmsUnitsRrequired:
      "Paling ora 4 unit sms dibutuhake kanggo verifikasi, mangga tambah ndhuwur!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Verifikasi akun bank sampeyan kanggo netepi persyaratan 'Ngerti Pelanggan' (KYC). Iki bakal ngidini sampeyan nglumpukake pembayaran liwat USSD utawa transfer cepet, nampa pesenan saka pelanggan lan ngatasi transaksi transaksi. Mung ditrapake kanggo pedagang Nigeria. Pencet tombol ing ngisor iki kanggo miwiti.",
    reConnectBankAccount: "Sambungake maneh Rekening Bank",
    accountName: "Jeneng Akun",
    accountNumber: "Nomer Akun",
    bankName: "Jeneng Bank",
    verified: "Diverifikasi",
    accountDetails: "Rincian Akun",
    kycBankAccount: "KYC",
    createTier: "Nggawe Undakan",
    fileUploadSuccessful: "file diunggah kanthi sukses",
    fileUploadFailed: "file upload gagal",
    createLoyaltyProgram: "Gawe Program Kasetyan",
    createLoyalty: "Nggawe Kasetyan",
    name: "Jeneng",
    loyaltyArtwork: "Karya Kasetyan",
    clickToUpload: "Klik kanggo ngunggah",
    amountToPointsRatio: "Jumlah kanggo Rasio Poin",
    points: "Poin",
    recommendedAmountToPointRatio:
      "Dianjurake: ₦ 1 nganti 1 poin. yaiku saben 1 Naira sing digunakake, para pelanggan entuk 1 poin",
    pleaseTypeIn: "Tulung ketik",
    toDeleteLoyalty: "kanggo mbusak kasetyan iki",
    deleteLoyalty: "Mbusak Kasetyan",
    toConfirm: "kanggo konfirmasi",
    edit: "Sunting",
    pointsAwardedSuccessfully: "Poin Ditampa Kanthi Sukses.",
    enterPointValueToAward: "Ketik Nilai Titik sing Sampeyan Goleki Pelanggan",
    award: "Penghargaan",
    awardPointValuesToCustomer: "Nilai Titik Award kanggo Pelanggan",
    enrollMembersToLoyalty: "Ndhaptar Anggota menyang Kasetyan",
    awardPoints: "Poin Penghargaan",
    enroll: "Ndhaptar",
    home: "Ngarep",
    loyalty: "Kasetyan",
    enrollCustomers: "Ndhaptar Pelanggan",
    selected: "Dipilih",
    customer: "Pelanggan",
    loyaltyActivationSuccessful: "Aktivasi Kasetyan sukses.",
    loyaltyDeactivationSuccessful: "Kasetyan Diaktifake sukses.",
    viewTier: "Ndeleng Tier",
    deactivate: "De-Aktifake",
    activate: "Aktifake",
    actions: "Tumindak",
    nameOfLoyalty: "Jeneng Kasetyan",
    loyaltyStatus: "Status Kasetyan",
    numberOfTiers: "Nomer Tiar",
    createdOn: "Digawe On",
    createATier: "Gawe Tier",
    stopCreatingTierConfirmation: "Apa sampeyan pengin mandheg nggawe undakan?",
    stopEditingTierConfirmation:
      "Apa sampeyan pengin mandheg nyunting undakan iki?",
    nameOfTier: "Jeneng Tier",
    minimumSpendingTarget: "Target Mbuwang Minimal",
    maximumSpendingTarget: "Target Mbuwang Maksimum",
    minimumSpendingTargetRequired: "target mbuwang minimal dibutuhake",
    maximumSpendingTargetRequired: "target mbuwang maksimum dibutuhake",
    rewardSponsor: "Sponsor Ganjaran",
    pleaseChooseARewardSponsor: "Pilih sponsor hadiah",
    self: "Dhewe",
    partner: "Mitra",
    rewardPartner: "Mitra Ganjaran",
    pleaseSelectRewardPartner: "Pilih mitra hadiah sampeyan",
    rewards: "Ganjaran",
    pleaseSelectAReward: "Pilih hadiah",
    instructionsOnRedeemingReward:
      "Pandhuan babagan Cara Pelanggan Kudu Ngatasi Ganjaran",
    pleaseFillInThisField: "Mangga Isi Bidang iki!",
    toDeleteThisTier: " kanggo mbusak undakan iki",
    deleteTier: "Delete Tier",
    viewMembers: "Ndeleng Anggota",
    membersEnrolled: "Anggota Ndhaptar",
    instruction: "Pitunjuk",
    membersIn: "Anggota ing",
    availableTiersInLoyalty: "Kasedhiya Tahap ing Program Kasetyan",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Program Kasetyan sing kasedhiya",
    totalLoyalties: "Jinis LOYALTIES",
    memberDetails: "Rincian Anggota",
    nameOfCustomer: "Jeneng Pelanggan",
    address: "Alamat",
    allEnrolledMembers: "Kabeh Anggota sing Ndhaptar",
    thisIsToWishYouHappyBirthday:
      "Iki kanggo ngucapake selamat ulang tahun lan urip sejahtera. Matur nuwun kanggo kabeh sing sampeyan Loyster. Pahargyan sing Seneng!",
    inputAnOfferPlease: "Masangake Penawaran",
    pleaseSelectTheInsertPoint:
      "Pilih titik insert ing pesen banjur klik maneh",
    birthdayOfferAndMessage: "Penawaran Ulang Tahun lan Pesen",
    birthdayOffer: "Penawaran Ulang tahun",
    birthdayMessage: "Pesen Ulang Tahun",
    noOfferFound: "Ora ditemokake tawaran",
    settingABirthdayOffer:
      "Nyetel tawaran ulang taun ngidini pelanggan nampa tawaran iki liwat SMS nalika ulang taune",
    createOffer: "Gawe Penawaran",
    whatIsTheOffer: "Apa sing ditawakake?",
    editMessage: "Sunting Pesen",
    insert: "Lebokake",
    theNameOfCustomerInserted: "Jeneng pelanggan bakal dilebokake ing kene",
    theBirtdayOfferInserted: "Tawaran ulang tahun bakal dilebokake ing kene",
    youSuccessfullyAddedNewBranch: "Sampeyan wis sukses nambah cabang anyar!",
    addNewBranch: "Tambah Cabang Anyar",
    addBranch: "Tambah Cabang",
    additionalBranchWillIncur: "Cabang tambahan bakal dikenani biaya",
    perBranch: "saben cabang",
    ecommerceBranchCosts: "Biaya Cabang E-niaga",
    pleaseEnterBranchName: "Ketik jeneng cabang",
    pleaseEnterBranchAddress1: "Mangga lebokake alamat alamat cabang 1",
    enterBranchAddress1: "Ketik baris alamat cabang 1",
    enterBranchAddress2: "Ketik baris alamat cabang 1",
    pleaseEnterBranchAddress2: "Mangga lebokake alamat alamat cabang 2",
    enterBranchName: "Ketik jeneng cabang",
    successfullyAddedStaff: "Sampeyan wis sukses nambah staf anyar!",
    addNewStaff: "Tambah Staff Anyar",
    addStaff: "Tambah Staff",
    additionalStaffWillIncur: "Staff tambahan bakal dikenani biaya",
    perStaff: "saben staf.",
    pleaseEnterStaffEmail: "Ketik email staf",
    pleaseEnterStaffUsername: "Ketik jeneng pangguna staf",
    pleaseEnterStaffPassword: "Ketik sandhi staf",
    pleaseSelectStaffRole: "Pilih peran staf",
    selectStaffRole: "Pilih peran staf",
    enterStaffEmail: "Ketik email staf",
    enterStaffUsername: "Ketik jeneng pangguna staf",
    enterStaffPassword: "Ketik sandhi staf",
    spacesNotAllowedInUsername: "spasi ora diidini nganggo jeneng pangguna",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff: "Pilih bisnis kanggo masang staf",
    searchForBranchToAttachStaff: "Telusuri cabang kanggo masang staf",
    username: "Jeneng pangguna",
    role: "Peran",
    areYouSureToDeleteThis: "Apa sampeyan yakin bakal mbusak iki",
    branches: "Cabang",
    upgradeYourPlan: "Upgrade Paket.",
    add: "NAMBAH",
    addNew: "Tambah Anyar",
    customerWithEmailAlreadyExists:
      "Pelanggan kanthi nomer email / telpon wis ana!",
    successfullyAddedNewCustomer: "Sampeyan wis sukses nambah pelanggan anyar!",
    addCustomer: "Tambah Pelanggan",
    pleaseEnterCustomerFirstName: "Ketik jeneng ngarep pelanggan",
    pleaseEnterCustomerLastName: "Ketik jeneng mburi pelanggan",
    pleaseEnterCustomerPhoneNumber: "Ketik nomer telpon pelanggan",
    pleaseEnterCustomerEmail: "Ketik email pelanggan",
    pleaseEnterCustomerAddressLine: "Ketik baris Alamat pelanggan",
    pleaseEnterCustomerOtherAddress: "Ketik Alamat liyane saka pelanggan",
    pleaseSelectCustomerGender: "Pilih jinis kelamin pelanggan",
    gender: "Jenis Kelamin",
    male: "Lanang",
    female: "Wanita",
    bank: "Bank",
    selectBank: "Pilih Bank",
    stateOrRegionOrProvince: "Negara / Wilayah / Propinsi",
    customerNotes: "Cathetan Pelanggan",
    sendSms: "Kirim SMS",
    editCustomer: "Sunting Pelanggan",
    redeemReward: "Ngluwari Ganjaran",
    issueLoyaltyCard: "Masalah Kertu Kasetyan",
    deleteCustomer: "Mbusak Pelanggan",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Sampeyan wis sukses Nemtokake ID Keanggotaan Kasetyan",
    noMembershipIdAvailable:
      "Ora kasedhiya ID anggota. Hubungi kita ing hello@loystar.co",
    sendEmail: "Kirim Email",
    membershipPoints: "Poin Keanggotaan",
    customerDetails: "Rincian Pelanggan",
    close: "Tutup",
    loyaltyBalance: "Kaseimbangan Kasetyan",
    pointsBalance: "Balance Points",
    starBalance: "Keseimbangan Bintang",
    requiredPoints: "Poin sing Dibutuhake",
    requiredStars: "Bintang sing dibutuhake",
    reddemCode: "Ngijolake Code",
    toDeleteThisCustomer: "kanggo mbusak pelanggan iki",
    customerHasBeenDeletedSuccessfully: "Pelanggan wis sukses dihapus!",
    customerWithPhoneAlreadyExists: "Pelanggan kanthi nomer telpon wis ana",
    customerWasSuccessfullyEdited: "Pelanggan sukses diowahi",
    anErrorOccured: "Ana kaluputan",
    phoneNumberIncludeCountry: "Nomer telpon (Kalebu Kode Negara)",
    yourFileQueuedForUpload:
      "File sampeyan wis antri kanggo diunggah. Mangga refresh kaca sawise sawetara detik.",
    thereWasAnErrorPerformingOperation:
      "Ana kesalahan nalika nindakake operasi!",
    pleaseSelectFile: "Pilih File!",
    oopsOnlyCsvFilesAllowed:
      "Wadhuh! Mung file CSV sing diidini. Mangga unggah file .csv.",
    fileShouldBeSmallerThan5Mb:
      "File kudu luwih cilik tinimbang 5MB. Yen sampeyan duwe file luwih gedhe, kirim email menyang support@loystar.co. Matur nuwun",
    customerFirstNameIsRequired: "jeneng ngarep pelanggan dibutuhake saurutan",
    customerPhoneNumberIsRequired: "nomer telpon pelanggan dibutuhake saurutan",
    importCustomers: "Pelanggan Impor",
    upload: "Unggah",
    clickIconToDownloadCsv:
      "Klik lambang iki kanggo ndownload template file CSV.",
    getGoogleSheetVersion: "Goleki versi sheet Google",
    clickOrDragFileToUpload:
      "Klik utawa seret file menyang wilayah iki kanggo ngunggah",
    missingOrInvalidColumnHeader:
      "Header kolom sing ilang utawa ora valid. Mangga tindakake format template. Matur nuwun",
    youHaveImported: "Sampeyan Wis Ngimpor",
    youSuccessfullyRedeemedReward: "Sampeyan wis sukses ngijolake ganjaran!",
    sixDigitCode: "Kode enem digit",
    pleaseEnterCustomerRewardCode: "Ketik kode hadiah pelanggan",
    enterRewardCode: "Ketik kode hadiah. (Kode hadiah kasebut sensitif banget)",
    selectLoyaltyProgram: "Pilih program kasetyan",
    stamps: "Prangko",
    smsUnitsLow: "Unit SMS Kurang",
    pleaseTopUpSmsUnits: "Mangga Top Up Unit SMS",
    smsSuccessfullySentForDelivery: "SMS sukses dikirim kanggo dikirim!",
    sendSmsTo: "Kirim SMS menyang",
    allCustomers: "Kabeh Pelanggan",
    unitsAvailable: "Unit Kasedhiya",
    pleaseTypeInTheMessage: "Tulung ketik pesen kasebut",
    message: "Pesen",
    charactersRemaining: "karakter sing isih ana",
    avoidUseOfSpecialCharacters:
      "Aja nggunakake Karakter Khusus lan Emoji kanggo ngreksa Unit SMS.",
    note: "Cathetan",
    errorFetchingCustomersMultilevelDetail:
      "Kesalahan Nggawe Rincian MultiLevel Pelanggan",
    search: "Nggoleki",
    reset: "Reset",
    importCustomer: "Pelanggan Impor",
    addNewCustomer: "Tambah Pelanggan Anyar",
    sendSmsBroadcast: "Kirim Broadcast SMS",
    totalCustomers: "JUMLAH PELANGGAN",
    disbursementDetails: "Rincian Pengeluaran",
    paidBy: "Mbayar dening",
    disbursed: "Disilihake",
    bankAccountName: "Jeneng Akun Bank",
    bankAccountNumber: "Nomer Rekening Bank",
    transferInitiated: "Transfer Diwiwiti",
    transferCompleted: "Transfer rampung",
    pleaseEnterAValid: "Ketik valid",
    begin: "miwiti",
    end: "pungkasan",
    date: "tanggal",
    paymentDate: "Tanggal Pembayaran",
    selectDisbursementDuration: "Pilih Duration Duration",
    totalSettled: "Total Ngatasi",
    totalUnsettled: "Gunggunge durung disetel",
    toDeleteThisSale: "kanggo mbusak adol iki",
    draftSaleDeletedSuccessfully: "Draft Sale wis sukses dicopot!",
    deleteSale: "Delete Sale",
    pleaseTypeInYourTotal: "Ketik total kanggo ngonfirmasi pambusakan",
    billDetails: "Rincian Bill",
    printBill: "RUU Cetak",
    servedBy: "Dadi dening",
    total: "Total",
    createdDate: "Tanggal digawe",
    createdTime: "Wektu Digawe",
    status: "Status",
    loginSuccessful: "Login Sukses",
    pleaseWaitWhileWeConnectAccount:
      "Ngenteni nalika nyambungake akun sampeyan",
    connectionFailedTryAgain: "Sambungan gagal. Coba maneh.",
    connectionSuccessful: "Sambungan Sukses",
    viewDetails: "Ndeleng Rincian",
    enable: "Aktifake",
    free: "Gratis",
    cost: "Ragad",
    visitWebsite: "Dolan maring situs web",
    pleaseUpgradeYourPlanToPro:
      "Mangga Upgrade rencana sampeyan menyang Pro Plus kanggo ngaktifake aplikasi iki",
    connectYourBankAccount:
      "Sambungake Akun Bank sampeyan supaya bisa nampa pembayaran.",
    disable: "Pateni",
    enableApp: "Aktifake App",
    addNewProductToInvoice: "Tambah Produk Anyar menyang Invoice",
    toDeleteThisInvoice: "kanggo mbusak Invoice iki",
    invoiceDeletedSuccessfully: "Invoice wis bisa dibusak kanthi sukses!",
    deleteInvoice: "Mbusak Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Sampeyan wis sukses nambah pesen mbayar Invoice!",
    pleaseEnterPaymentInstructions: "Ketik pandhuan pembayaran",
    invoiceId: "ID invoice",
    paidDate: "Tanggal Bayaran",
    reference: "Referensi",
    productAdded: "Produk ditambahake",
    productOutOfStock: "Produk kehabisan stok. Simpen maneh.",
    totalInvoices: "Jinis INVOISI",
    totalPaidInvoices: "INVOISI sing Dibayar TOTAL",
    totalUnpaidInvoices: "Jinis INVOISI UNPAID",
    loyaltyProgramDeleted: "Program Kasetyan Dibusak",
    thisLoyaltyProgramDeletedSuccessfully:
      "Program kasetyan iki wis sukses dibusak",
    thisLoyaltyProgramEditedSuccessfully:
      "Program kasetyan iki wis sukses diowahi",
    loyaltyProgramAddedSuccessfully:
      "Program kasetyan wis ditambahake kanthi sukses",
    loyaltyProgramEdited: "Program Kasetyan Diowahi",
    loyaltyProgramAdded: "Program Kasetyan Ditambahake",
    loyaltyDetails: "Rincian Kasetyan",
    doYouWantToCloseDialog: "Apa sampeyan pengin nutup dialog iki?",
    pleaseEnterLoyaltyName: "Ketik jeneng kasetyan sampeyan",
    programType: "Jinis Program",
    pleaseSelectProgramType: "Pilih jinis program",
    simplePoints: "Poin Sederhana",
    stampsProgram: "Program Perangko",
    threshold: "Ambang ambang",
    pleaseEnterLoyaltyThreshold: "Ketik ambang kasetyan",
    reward: "Ganjaran",
    pleaseEnterLoyaltyReward: "Mangga lebokake hadiah kasetyan",
    totalUserPoints: "Total Poin pangguna",
    totalUserStamps: "Total Prangko Panganggo",
    spendingTarget: "Target mbuwang",
    spendingTargetHint1:
      "Target belanja yaiku pinten sing kudu dibuwang pelanggan kanggo entuk hadiah. 1 nilai itungan = 1 poin.",
    spendingTargetHint2:
      "Target perangko yaiku pinten prangko sing kudu dikoleksi kanggo entuk hadiah. contone 5",
    addNewLoyaltyProgram: "Tambah Program Kasetyan Anyar",
    addLoyaltyProgram: "Tambah Program Kasetyan",
    loyaltyProgramType: "Jinis Program Kasetyan",
    pleaseSelectLoyaltyProgramType: "Pilih jinis program kasetyan",
    nameOfProgram: "Jeneng program",
    pleaseEnterProgramName: "Ketik jeneng program",
    whatIsTheReward: "Apa ganjarane?",
    egNextPurchaseIsFree: "Contone Tuku sabanjure gratis",
    customerName: "Jeneng Pelanggan",
    guestCustomer: "Pelanggan Tamu",
    orderConfirmedSuccessfully: "Pesenan dikonfirmasi kanthi sukses",
    orderCancelledSuccessfully: "Pesenan dibatalake kanthi sukses",
    confirmOrder: "Konfirmasi pesenan",
    cancelOrder: "Batal pesenan",
    allOrders: "Kabeh Pesenan",
    totalOrders: "JUMLAH PESANAN",
    doYouWantToAcceptOrder: "Apa sampeyan pengin nampa pesen iki?",
    doYouWantToCancelOrder: "Apa sampeyan pengin mbatalake pesenan iki?",
    orderDetails: "Rincian Pesenan",
    orderCreatedAt: "Pesenan digawe ing",
    supplier: "Panyedhiya",
    productName: "Jeneng Produk",
    quantity: "Jumlah",
    unitPrice: "Rega Satuan",
    receivedBy: "Ditampa dening",
    reportFrom: "Laporan saka",
    totalSupplies: "Total Penyetor",
    allRightsReserved: "Kabeh Hak Dilindhungi",
    toDeleteThisTransaction: "kanggo mbusak Transaksi iki",
    transactionDeletedSuccessfully:
      "Transaksi wis sukses dihapus. Simpenan wis dibalekake menyang inventaris.",
    deleteTransaction: "Mbusak Transaksi",
    transactionDetails: "Rincian Transaksi",
    printReceipt: "Panrimo Print",
    channel: "Saluran",
    discount: "Diskon",
    profit: "Bathi",
    discountedSales: "Penjualan Diskon",
    errorFetchingRecord: "Kesalahan njupuk cathetan",
    exportTransactions: "Transaksi Ekspor",
    errorFetchingSalesRecord:
      "Kesalahan nalika njupuk data Sales kanggo Ekspor.",
    totalSellingPrice: "Rega Adol Total",
    totalCostPrice: "Rega Biaya Total",
    appliedDiscount: "Diskon Terapan",
    noOfItems: "Nomer Barang",
    sales: "Adol",
    export: "Ekspor",
    totalProfit: "Total Bathi",
    totalBalanceInPeriod: "Total Saldo ing Periode",
    allTimeSales: "Kabeh Wektu Sales",
    records: "Cathetan",
    todaysSales: "Penjualan Dina Iki",
    transaction: "transaksi",
    youHaveExceededTotalNumberOfProducts:
      "Sampeyan wis ngluwihi total produk sing diidini ing rencana kasebut. Upgrade rencana sampeyan kanggo nikmati wates sing luwih dhuwur.",
    youNeedToHaveLoyaltyProgram:
      "Sampeyan kudu duwe Program Kasetyan kanggo nggunakake fitur iki!",
    price: "Regane",
    category: "Kategori",
    stockTracking: "Pelacakan Simpenan",
    stockCount: "Count Simpenan",
    taxed: "Diatur",
    originalPrice: "Rega Asli",
    costPrice: "Regane regane",
    unit: "Unit",
    productImage: "Gambar Produk",
    taxRate: "Tarif Pajak",
    taxType: "Jinis Pajak",
    trackInventory: "Inventaris Lacak",
    variants: "Varian",
    hasVariants: "Nduwe Varian",
    importProduct: "Produk Impor",
    exportProducts: "Produk Ekspor",
    addNewProduct: "Tambah Produk Anyar",
    viewCategory: "Ndeleng Kategori",
    viewSuppliers: "Ndeleng Pemasok",
    receiveInventory: "Nampa Inventory",
    printAllProductsTag: "Print Kabeh Tag Produk",
    deleteAll: "Mbusak Kabeh",
    totalProducts: "JUMLAH PRODUK",
    youveSuccessfullyAddedANewCategory:
      "Sampeyan wis sukses nambah kategori anyar",
    addNewCategory: "Tambah Kategori Anyar",
    addCategory: "Tambah Kategori",
    categoryName: "Jeneng Kategori",
    pleaseEnterCategoryName: "Ketik jeneng kategori",
    stampsTarget: "Target Stempel",
    sendInventory: "Kirim Inventory",
    productDetails: "Rincian Produk",
    youveSuccessfullyEditedThisCategory:
      "Sampeyan wis sukses nyunting kategori iki",
    update: "Nganyari",
    categoryList: "Dhaptar Kategori",
    categories: "Kategori",
    enterQuantityToUpdate: "Ketik jumlahe kanggo nganyari",
    inventorySentSuccessfully: "Inventarisasi wis dikirim kanthi sukses!",
    updateInventory: "Nganyari Inventori",
    currentQuantity: "Quantity Saiki",
    pleaseEnterQuantityToAdd: "Ketik jumlah sing pengin ditambahake",
    pleaseSelectABranch: "Pilih Cabang",
    searchForBranch: "Telusuri cabang",
    youCantSendMoreThanStock: "Sampeyan ora bisa ngirim luwih saka saham",
    send: "Ngirim",
    pleaseEnterQuantityToSend: "Ketik jumlah sing pengin dikirim",
    productNameIsRequiredOnRow: "jeneng produk dibutuhake saurutan",
    productCategoryIsRequiredOnRow: "kategori produk dibutuhake saurutan",
    productPriceIsRequiredOnRow: "rega produk dibutuhake saurutan",
    productUnitIsRequiredOnRow: "unit produk dibutuhake saurutan",
    productQuantityIsRequiredOnRow: "jumlah produk dibutuhake kanthi saurutan",
    productVariantsRequireTracking: "varian produk mbutuhake pelacakan!",
    pleaseAddVariantClickButton:
      "Mangga tambahake varian kanthi ngeklik tombol tambah!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Varian luwih saka jumlah produk sing dipilih, Pls nyuda jumlah varian",
    productEditedSuccessfully: "Produk wis sukses diowahi!",
    fileTooLargeLessThan4Mb:
      "Ukuran file gedhe banget! Ukuran file kudu kurang saka 4MB.",
    suchVariantAlreadyExist: "Varian kasebut wis ana",
    addVariants: "Tambah Varian",
    editProduct: "Sunting Produk",
    pleaseEnterProductName: "Ketik Jeneng Produk",
    pleaseEnterProductPrice: "Mangga Ketik Rega Produk",
    pleaseEnterProductOriginalPrice: "Mangga Ketik Rega Asli Produk",
    productDescription: "Katrangan Produk",
    selectProductCategory: "Pilih Kategori Produk",
    pleaseSelectProductCategory: "Pilih kategori produk",
    productCostPrice: "Rega Biaya Produk",
    productSellingPrice: "Rega Adol Produk",
    productOriginalPrice: "Rega Asli Produk",
    maxFileSizeAllowedIs4Mb: "Maks. Ukuran File sing diidini yaiku 4mb",
    productsWithPicturesArePublished:
      "Produk nganggo gambar diterbitake ing Discover kanggo nampa pesenan",
    shouldThisProductBeTracked: "Apa produk iki kudu dilacak?",
    pleaseSelectStockUnit: "Pilih unit saham",
    stockUnit: "Unit Simpenan",
    bag: "BAG",
    bottle: "BOTOL",
    bunch: "BUNCH",
    can: "CAN",
    carton: "KARTON",
    crate: "Peti",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKET",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "Yard",
    pleaseEnterProductQuantity: "Ketik jumlah produk",
    productQuantity: "Quantity Produk",
    isThisProductTaxed: "Apa produk iki kena pajak?",
    selectTaxType: "Pilih Jinis Pajak",
    pleaseSelectTaxType: "Pilih Tipe Pajak",
    progressive: "Progresif",
    proportional: "Proporsional",
    pleaseEnterProductTaxRate: "Ketik tarif pajak produk",
    doesProductHaveVariants: "Apa produk iki duwe varian?",
    type: "Jinis",
    value: "Nilai",
    pleaseEnterVariantType: "Ketik jinis varian",
    pleaseEnterVariantValue: "Ketik regane varian",
    pleaseEnterVariantPrice: "Ketik rega varian",
    pleaseEnterVariantQuantity: "Ketik jumlah varian",
    productDeletedSuccessfully: "Produk wis sukses dihapus!",
    categoryDeletedSuccessfully: "Kategori wis sukses dihapus!",
    toDeleteThisProduct: "kanggo mbusak Produk iki",
    invalidProductQuantity: "Jumlah Produk Ora Valid",
    quantityAddedIsOverStock:
      "Jumlahe sing sampeyan tambahake kalebu ing saham.",
    itemInventoryNotTracked: "Inventaris barang ora dilacak",
    addBulkQuantity: "Tambah Quantity Akeh",
    enterBulkQuantity: "Ketik Jumlah Akeh",
    pleaseEnterBulkQuantity: "Ketik Jumlah Akeh",
    youveSuccessfullyAddedANewProduct:
      "Sampeyan wis sukses nambah produk anyar!",
    pleaseEnterProductSellingPrice: "Ketik rega adol produk",
    doYouWantToTrackProductStock: "Apa sampeyan pengin nglacak stok produk?",
    sellingPrice: "Rega Adol",
    setProductExpiryReminder: "Setel Pangeling Kadaluwarsa Produk",
    productExpiryDate: "Tanggal Kadaluwarsa Produk",
    startRemindingFrom: "Mulai Ngelingake Saka",
    productSuppliesAddedSuccessfully:
      "Sampeyan wis nambah pasokan produk kanthi sukses.",
    addProductSupplies: "Tambah Pasokan Produk",
    pleaseSelectSupplier: "Pilih Pemasok",
    nameOfProduct: "Jeneng Produk",
    pleaseSelectProduct: "Pilih Produk",
    productVariant: "Varian Produk",
    pleaseSelectAVariant: "Mangga Pilih Varian",
    pleaseEnterUnitPrice: "Mangga Ketik Rega Satuan",
    businessBranch: "Cabang Bisnis",
    pleaseSelectBranch: "Pilih cabang",
    youveSuccessfullyAddedANewSupplier:
      "Sampeyan wis sukses nambah pemasok anyar",
    addSupplier: "Tambah Pemasok",
    pleaseEnterSupplierEmail: "Ketik email supplier",
    pleaseAddADescription: "Tulung tambahake katrangan",
    anErrorOccuredProductsDeleted:
      "Ana kesalahan nalika nindakake operasi kasebut. Elinga, sawetara produk sing bisa mbusak proses kasebut",
    bulkDelete: "Mbusak Akeh",
    youAreAboutToDelete: "Sampeyan arep mbusak",
    product: "Produk",
    isDueToRedeem: "amarga nebus",
    aReward: "ganjaran",
    pleaseSelectCustomerToReeemReward: "Pilih pelanggan kanggo nebus hadiah.",
    youHaveNoLoyaltyProgramRunning: "Sampeyan ora duwe program loyalitas aktif",
    customerHhasNoPointsInLoyaltyProgram:
      "Pelanggan ora duwe poin ing program kasetyan iki",
    proceedWithPayment: "Nerusake kanthi Pembayaran?",
    youAreAboutToPayForTransactionUsingPoints:
      "Sampeyan bakal mbayar transaksi kanthi nggunakake poin.",
    customerHas: "Pelanggan Nduwe",
    worth: "regane",
    andIsNotSufficientToPayForTransaction:
      "lan ora cukup kanggo mbayar transaksi iki. Apa dheweke pengin nambah saldo nggunakake metode pembayaran liyane?",
    addCustomerLoyalty: "Tambahake Kasetyan Pelanggan",
    pleaseAddCustomerFirst: "Mangga tambah utawa pilih pelanggan dhisik.",
    pleaseWaitWhileWeGetReady: "Ngenteni, nalika siyap",
    lowStock: "Simpenan Kurang",
    pleaseEnterAmountTendered: "Ketik jumlah sing ditawakake",
    areYouSureToBookSaleOffline:
      "Sampeyan yakin pengin Booking sale iki kanthi offline?",
    saleWouldBeBookedAutomatically:
      "Didol bakal dipesen kanthi otomatis nalika nguripake internet",
    offlineSalesBookingCancelled: "Pesenan sales offline dibatalake",
    covid19Message:
      "COVID19: Cuci tangan nganggo sabun utawa Sanitasi kanggo mungkasi panyebaran. Tetep Aman Tansah",
    saleSuccessfullyRecorded: "Didol Kasile Direkam!",
    sendReceiptToEmail: "Kirim kuitansi menyang Email",
    sendThankYouSms: "Kirimi matur nuwun SMS",
    sendShippingDetails: "Kirim rincian pengiriman",
    addLoyalty: "Tambah Kasetyan",
    searchCustomerNameOrNumber: "Telusuri jeneng utawa nomer pelanggan",
    clickTheSearchCustomerBox: "Klik Kothak Customer Search lan Card Scan",
    enterProductPrice: "Ketik Rega Produk",
    enterPriceFor: "Ketik Rega kanggo",
    searchForProduct: "Telusuri produk",
    all: "Kabeh",
    backToDashboard: "Bali menyang Dashboard",
    viewDraftSales: "Ndeleng Sales Draft",
    variantSelected: "varian dipilih",
    variant: "varian",
    thePreviousVariantSelectionNotAvailable:
      "Pilihan varian sadurunge ora kasedhiya kanggo varian anyar sing dipilih adhedhasar rega, mangga ganti pilihan sampeyan.",
    pleaseSupplyPositiveQuantityNumber: "Nyedhiyakake nomer jumlah pasif",
    lowStockFor: "Simpenan murah kanggo",
    clearVariants: "Varian sing jelas",
    pleaseEnterQuantity: "Ketik Quantity",
    picture: "Gambar",
    redemptionToken: "Token Pangentasan",
    token: "Token",
    totalSpent: "Total Nglampahi",
    confirmPayment: "Konfirmasi Pembayaran",
    hasPaymentBeenMade: "Apa Pembayaran wis diproses kanthi sukses?",
    enterTransactionReference: "Ketik referensi transaksi sing sampeyan mbayar",
    pleaseSelectACustomer: "Pilih pelanggan!",
    areYouSureToApplyDiscount: "Sampeyan yakin pengin aplikasi diskon?",
    addYourBankAccountToEnableUssd:
      "Tambahake akun bank sampeyan kanggo ngaktifake transfer USSD Instan nganggo uPay",
    totalAmountToPay: "Total Jumlah sing Mbayar",
    doYouWantToCancelTransaction:
      "Apa sampeyan pengin mbatalake transaksi iki?",
    savePrintBill: "Simpen / Cetak Bill",
    enterAmountCollectedForCustomer:
      "Ketik jumlah sing dikoleksi kanggo pelanggan",
    recordSale: "Didol Rekam",
    checkOutWith: "Priksa karo",
    instantTransfer: "Transfer Instan",
    dialTheUSSDCode: "Telpon kode USSD",
    pleaseSelectABank: "Pilih bank",
    payWithUSSD: "Mbayar Kanthi USSD",
    sendBillTo: " - Kirim Bill menyang",
    waitingForUSSDTransfer: "Ngenteni Transfer USSD",
    percent: "Persen",
    applyDiscount: "Aplikasi Diskon",
    thisBillHasBeenSaved: "RUU iki wis disimpen",
    saveDraft: "Simpen Draf",
    pleaseTypeANameToIdentifyCustomer: "Ketik jeneng kanggo ngenali pelanggan",
    paymentDetails: "Rincian Pembayaran",
    basePrice: "Rega Dasar",
    staff: "Staff",
    subTotal: "SubTotal",
    durationMonths: "Durasi (wulan)",
    selectADuration: "Pilih Durasi",
    oneMonth: "1 Wulan",
    twoMonths: "2 Wulan",
    threeMonths: "3 Wulan",
    sixMonths: "6 Wulan",
    twelveMonths: "12 Wulan",
    eighteenMonths: "18 Wulan",
    twentyFourMonths: "24 Wulan",
    twoMonthsFree: "(Gratis 2 Wulan)",
    threeMonthsFree: "(Gratis 3 Wulan)",
    fiveMonthsFree: "(Gratis 5 Wulan)",
    yourAccountHasBeen: "Akun sampeyan wis",
    renewed: "gawe anyar",
    upgraded: "nganyari",
    successfully: "sukses",
    yourSubscription: "Lengganan Sampeyan",
    youAreCurrentlyEnrolledOnA: "Sampeyan saiki mlebu ing a",
    pleaseChooseAPaymentOption: "Pilih Pilihan Pembayaran",
    planRenewal: "Rencana Nganyari",
    planUpgrade: "Rencana Nganyarke",
    pleaseSelectDurationToPayFor: "Mangga Pilih Durasi sing arep dibayar",
    staffAccounts: "Akun Staff",
    ecommerce: "E-commerce",
    pleaseSelectAPlan: "Pilih rencana",
    includeAddons: "Kalebu Add-ons",
    viewTransactions: "Ndeleng Transaksi",
    customerHasNoCompletedTansactions:
      "Pelanggan durung duwe tansaksi sing lengkap",
    branch: "Cabang",
    enterNumberOfEcommerceBranches: "Ketik Jumlah Cabang E-commerce",
    enterNumberOfEcommerceBranchesToPay:
      "Ketik Jumlah Cabang E-niaga sing Sampeyan Kudu Mbayar",
    ecommerceIntegration: "Integrasi E-commerce",
    enterNumberOfBranches: "Ketik Jumlah Cabang",
    enterNumberOfAdditionalBranchesToPay:
      "Ketik Jumlah Cabang Tambahan sing Bakal Dibayar",
    enterNumberOfStaffs: "Ketik Jumlah Staff",
    enterNumberOfStaffsToPayFor: "Ketik Jumlah Staff sing Bakal Dibayar",
    discountApplies: "Diskon Digunakake",
    starsOnThe: "lintang ing",
    offer: "nawarake",
    get: "Entuk",
    moreStarsToRedeem: "lintang liyane kanggo nebus",
    taxVat: "Pajak (PPN)",
    callCashierToCompletePayment: "Nelpon Kasir kanggo Ngrampungake Pembayaran",
    receipt: "Kwitansi",
    dear: "Dhuh",
    thankYouForYourOrderFindGoods:
      "Matur nuwun kanggo pesenan sampeyan. Mangga golek barang ing ngisor iki sing disedhiyakake, kaya sing wis disepakati.",
    shippingNote: "Cathetan Pengiriman",
    enterShippingNote: "Ketik Cathetan Pengiriman",
    shippingAddress: "Alamat pengiriman",
    enterShippingAddress: "Ketik alamat Pengiriman",
    wellDoneYouAreDueToRedeem: "Wis apik! Sampeyan kudu nebus",
    toGetYourRewardNextVisit:
      "kanggo entuk hadiah nalika riko sabanjure. Matur nuwun",
    pointsOnThe: "Poin ing",
    morePointsToRedeem: "poin liyane kanggo nebus",
    showCode: "Tampilake kode",
    toGetYourRewardOnNextVisit:
      "kanggo entuk hadiah nalika riko sabanjure. Matur nuwun",
    earn: "Entuk",
    delivaryNote: "Cathetan Delivari",
    draftSales: "Penjualan Draf",
    startDate: "Tanggal wiwitan",
    endDate: "Tanggal pungkasan",
    orders: "Pesenan",
    checkout: "checkout",
    noProductItem: "Ora Ana Barang Produk",
    selectProductImage: "Pilih Gambar Produk",
    selectCountry: "Pilih negara",
    selectRegion: "Pilih Negara / Wilayah",
    printProductTag: "Print Produk Print",
    transactionReference: "Referensi transaksi",
    Cashier: "Kasir",
    Manager: "Manajer",
    Owner: "Sing duwe",
    Admin: "Admin",
    addPartners: "Tambah Mitra",
    addNewLoyaltyPartner: "Tambah Mitra Kasetyan Anyar",
    pleaseEnterCompanyName: "Mangga ketik Jeneng Perusahaan",
    companyName: "Jeneng Perusahaan",
    pleaseEnterCompanyRepName: "Mangga ketik jeneng wakil perusahaan",
    companyRepName: "Jeneng Perwakilan Perusahaan",
    pleaseEnterCompanyRepEmail: "Mangga ketik email perwakilan perusahaan",
    companyRepEmail: "Email saka perwakilan perusahaan",
    pleaseEnterCompanyRepPhone: "Mangga ketik nomer telpon wakil perusahaan",
    companyRepPhone: "Nomer telpon perwakilan perusahaan",
    addReward: "Tambah ganjaran",
    pleaseEnterRewardName: "Mangga ketik jeneng ganjaran",
    rewardName: "Jeneng Ganjaran",
    rewardQuantity: "Ganjaran Jumlah",
    rewardDescription: "Ganjaran Deskripsi",
    rewardType: "Jinis Ganjaran",
    pleaseEnterRewardType: "Mangga ketik jinis ganjaran",
    pleaseEnterRewardQuantity: "Mangga ketik jumlah ganjaran",
    pleaseEnterRewardDescription: "Mangga ketik gambaran ganjaran",
    fineDining: "Fine Dining",
    luxuryFashion: "Fashion mewah",
    hotels: "Hotel",
    travel: "Lelungan",
    foodAndBeverage: "Panganan lan Wedang",
    fashion: "busana",
    health: "kesehatan",
    furniture: "Furnitur",
    entertainment: "Hiburan",
    automobile: "Mobil",
    education: "Pendidikan",
    beautyAndSpa: "Beauty lan Spa",
    staycation: "Staycation",
    events: "Acara",
    trips: "Lelungan",
    oilAndGas: "Minyak lan Gas",
    laundry: "umbah-umbah",
    partnerCategory: "Kategori Mitra",
    freeItem: "Item Gratis",
  },
  Kazakh: {
    cashier: "кассир",
    manager: "менеджер",
    owner: "иесі",
    online: "желіде",
    offline: "офлайн",
    changePassword: "Құпия сөзді өзгерту",
    currentPasswordMessage: "Ағымдағы құпия сөзді енгізіңіз",
    passwordMessage: "Құпия сөзді енгізіңіз",
    currentPassword: "Ағымдағы Құпия сөз",
    password: "пароль",
    confirmPasswordMessage: "Құпия сөзіңізді растаңыз!",
    confirmPassword: "Құпия сөзді Растау",
    sendViaEmail: "Электрондық пошта арқылы жіберу",
    sendViaWhatsapp: "WhatsApp арқылы жіберіңіз",
    downloadPdf: "PDF жүктеу",
    paid: "ақылы",
    unpaid: "төленбеген",
    partial: "ішінара",
    closeInvoice: "Шот -фактураны жапқыңыз келе ме?",
    closeInvoiceConfirmation:
      "Шот -фактура сақталмауы мүмкін. Жабуды қалайсыз ба?",
    yes: "иә",
    no: "жоқ",
    invoice: "Шот -фактура",
    wasDeducted: "шегерілді",
    for: "үшін",
    item: "Элемент",
    addProduct: "Өнімді қосу",
    paymentReference: "Төлем анықтамасы",
    amountPaid: "Төленген сома",
    discountAmount: "Жеңілдік сомасы",
    amountDue: "Төленетін сома",
    amount: "Сомасы",
    dueDate: "Мерзімнің өту күні",
    paymentType: "Төлем түрі",
    card: "Карточка",
    cash: "Ақша",
    bankTransfer: "Банктік аударым",
    paymentMessage: "Төлем туралы хабарлама",
    description: "Сипаттама",
    sendReceipt: "Түбіртекті жіберу",
    delete: "Жою",
    save: "Сақтау",
    resend: "Қайта жіберу",
    saveAndSend: "Сақтау",
    invoiceSaved: "Шот -фактура сақталды!",
    selectPaymentMethod: "Төлеу әдісін таңдаңыз!",
    selectCustomer: "Тапсырыс берушіні таңдаңыз!",
    cartEmptyError:
      "Себет тізімі бос болмауы керек, шот -фактураны жабыңыз және элементті себетке қосыңыз!",
    subscriptionExpired:
      "Жазылымыңыздың мерзімі бітті, енді сіздің есептік жазбаңыз шектеулі. Есептік жазбаны жаңарту үшін төмендегі батырманы басыңыз",
    renew: "Жаңарту",
    holdOn: "Күте тұр",
    customerBank: "Клиент банкі",
    cancel: "Бас тарту",
    selectACustomer: "Тұтынушы таңдаңыз",
    invoiceSuccessfulPdfError:
      "Шот -фактура сәтті жасалды, бірақ PDF генерациясы әдеттегіден ұзағырақ. Жақында қайта тексеріңіз.",
    downloadingInvoice: "Шот -фактура жүктелуде",
    downloadingReceipt: "Түбіртек жүктелуде",
    whatsappReceiptError:
      "WhatsApp арқылы түбіртекті жіберу кезінде қате пайда болды, қайталап көріңіз.",
    receiptToWhatsapp: "Түбіртек WhatsApp -қа жіберілді",
    thankYouForPatronage: "Сіздің қамқорлығыңызға рахмет",
    hereIsYourReceipt: "Міне, сіздің төлем түбіртегіңіз",
    errorSendingEmailReceipt:
      "Түбіртекті электрондық пошта арқылы жіберу кезінде қате пайда болды, әрекетті қайталаңыз немесе қолдау қызметіне хабарласыңыз",
    receiptSentToEmail: "Қолхат тұтынушының электронды поштасына жіберілді",
    invoiceSentToEmail: "Шот -фактура клиенттің электронды поштасына жіберілді",
    invoiceSuccessWhatsappError:
      "Шот -фактура сәтті жасалды, бірақ WhatsApp -қа жіберу кезінде қате пайда болды. Шот -фактуралар тізімінде қайталап көріңіз",
    invoiceSuccessfulEmailError:
      "Шот-фактура сәтті жасалды, бірақ электрондық пошта ретінде жіберу кезінде қате пайда болды. Шот -фактуралар тізімінен қайталап көріңіз",
    invoiceSentToWhatsapp: "WhatsApp -қа шот -фактура жіберілді",
    hello: "Сәлеметсіз бе",
    pleaseDownloadInvoice: "Шот -фактураны жүктеп алыңыз",
    pleaseDownloadReceipt: "Түбіртекті жүктеп алыңыз",
    from: "бастап",
    email: "Электрондық пошта",
    upgrade: "Жаңалау",
    youAreOnFreePlan: "Сіз тегін жоспардасыз.",
    clickOn: "Басыңыз",
    yourPlanInFewSteps: " жазылуды бірнеше жылдам қадаммен орындаңыз.",
    to: "дейін",
    yourSubscriptionHasExpired:
      "Жазылымыңыздың мерзімі бітті, енді сіздің есептік жазбаңыз шектеулі.",
    days: "күндер",
    yourSubscriptionExpiresIn:
      "Сіздің Loystar жазылымыңыздың мерзімі аяқталады",
    createAcount: "Тіркелу",
    signIn: "Кіру",
    continue: "Жалғастыру",
    enterOtp: "OTP PIN кодын енгізіңіз",
    enterValidOtp: "Жарамды PIN кодын енгізіңіз",
    pin: "PIN коды",
    tokenSentToMail: "Электрондық поштаңызға белгі жіберілді",
    passwordResetSuccessful: "Құпия сөзді қалпына келтіру сәтті болды",
    somethingWentWrong: "Бірдеңе дұрыс болмады!",
    resetPassword: "Құпия сөзді қалпына келтіру",
    iHaveResetCode: "Менде парольді қалпына келтіру коды бар",
    pleaseEnterEmail: "Электрондық поштаны енгізіңіз",
    aTokenWillBeSentToEmail: "Электрондық поштаңызға белгі жіберіледі",
    enterEmail: "Электрондық поштаны енгізіңіз",
    sendinYourToken: "Таңбалауышыңыз жіберілуде ...",
    makeSureItMatchesPassword:
      "Бұл сіздің жаңа құпия сөзіңізге сәйкес келетініне көз жеткізіңіз",
    pleaseChooseNewPassword: "Жаңа құпия сөзді таңдаңыз",
    chooseNewPassword: "Жаңа құпия сөзді таңдаңыз",
    enterNewPassword: "Растау үшін жаңа құпия сөзді енгізіңіз",
    enterTokenSent: "Поштаңызға жіберілген таңбалауышты енгізіңіз",
    resetToken: "Токенді қалпына келтіру",
    resettingPassword: "Құпия сөз қалпына келтірілуде ...",
    signUp: "Тіркелу",
    adminSignInWithEmail:
      " Әкімші электрондық пошта арқылы, ал қызметкерлер логинмен кіреді.",
    pleaseEnterEmailOrUsername:
      "Электрондық поштаны немесе пайдаланушы атын енгізіңіз",
    emailOrUsername: "Электрондық пошта немесе пайдаланушы аты",
    pleaseEnterPassword: "Құпия сөзді енгізіңіз",
    createAnAccount: "Тіркелу",
    forgotPassword: "Құпия сөзді ұмыттыңыз ба?",
    enterPhoneNumber: "Телефон нөмірін енгізіңіз",
    personalData: "Жеке мәліметтер",
    validateConfirmationCOde: "Растау кодын растау",
    pleaseEnterFirstName: "Атыңызды енгізіңіз",
    pleaseEnterPhoneNumber: "Телефон нөміріңізді енгізіңіз",
    pleaseEnterLastName: "Өз фамилияңызды енгізіңіз",
    pleaseEnterBusinessName: "Кәсіпорын атауын енгізіңіз",
    firstName: "Аты",
    lastName: "Тек",
    businessName: "Кәсіпорын атауы",
    previous: "Алдыңғы",
    next: "Келесі",
    pleaseSelectBusinessCategory: "Өз бизнесіңіздің санатын таңдаңыз",
    pleaseEnterValidEmail: "Жарамды электрондық поштаны енгізіңіз",
    pleaseEnterPostCode: "Пошта индексін енгізіңіз",
    postCode: "Пошта индексі",
    phoneNumberInUse: "Бұл телефон нөмірі бұрыннан бар!",
    emailInUse: "Бұл электрондық пошта қазірдің өзінде қолданылуда!",
    foodAndDrinks: "Тамақ пен сусындар",
    salonAndBeauty: "Салон мен сұлулық",
    fashionAndAccessories: "Сән және аксессуарлар",
    gymAndFitness: "Спорт залы мен фитнес",
    travelAndHotel: "Саяхат және қонақ үй",
    homeAndGifts: "Үй және сыйлықтар",
    washingAndCleaning: "Жуу және тазалау",
    gadgetsAndElectronics: "Гаджеттер мен электроника",
    groceries: "Азық -түлік",
    others: "Басқалар",
    submit: "Жіберу",
    accountCreatedSuccessful: "Есептік жазбаңыз сәтті құрылды.",
    pleaseEnterAddress: "Сіздің мекен -жайыңызды енгізіңіз",
    addressLine1: "Адрестік Жол 1",
    addressLine2: "Мекенжай жолы 2",
    choosePassword: "Құпия сөзді таңдаңыз",
    passwordMustBe6Characters: "Құпия сөз кемінде 6 таңбадан тұруы керек.",
    howDidYouHearLoystar: "Лойстар туралы қалай естідіңіз?",
    referralCode: "сілтеме коды",
    byClickingTheButton: " Төмендегі түймені басу арқылы сіз келісесіз",
    termsAndSevice: "Шарттар",
    wereCreatingAccount: "Біз сіздің есептік жазбаңызды жасаймыз",
    proceed: "Жалғастыру",
    verificationCodeMustBe6: "Тексеру коды 6 таңбадан тұруы керек",
    pleaseEnter6DigitCode: "6 таңбалы кодты енгізіңіз",
    enterVerificationCode: "Тексеру кодын енгізіңіз",
    resendToken: "Токенді қайта жіберіңіз",
    verify: "Тексеріңіз",
    transactions: "Мәмілелер",
    todaySales: "Бүгінгі сатылымдар",
    salesHistory: "Сату тарихы",
    supplyHistory: "Жеткізу тарихы",
    new: "Жаңа",
    invoices: "Шот -фактуралар",
    disbursements: "Төлемдер",
    offlineSales: "Офлайн сатылымдар",
    products: "Өнімдер",
    customers: "Клиенттер",
    multiLevelLoyalty: "Көп деңгейлі адалдық",
    loyaltyPrograms: "Адалдық бағдарламалары",
    members: "Мүшелер",
    appStore: "Қолданбалар дүкені",
    orderMenu: "Тапсырыс мәзірі",
    settings: "Параметрлер",
    staffAndBranches: "Штаттар мен филиалдар",
    myAccount: "Менің аккаунтым",
    switchToSellMode: "Сату режиміне ауысыңыз",
    signOut: "Шығу",
    getFreeSubscription: "Тегін жазылуды алыңыз",
    onlyNumbersAllowed: "Тек сандарға рұқсат етілген",
    yourAccountMustBe10Digits:
      "Сіздің шот нөміріңіз 10 таңбалы сан болуы керек",
    yourBvnMustBe11: "сіздің BVN 11 таңбалы сан болуы керек",
    pleaseSelectBank: "Банкіңізді таңдаңыз",
    selectYourBank: "Банкіңізді таңдаңыз",
    enterBankAccountNumber: "Банктік шот нөмірін енгізіңіз",
    enterBvnNumber: "BVN енгізіңіз",
    connectBankAccount: "Банктік шотты қосыңыз",
    passwordResetSuccessfulAndSignOut:
      "Сіздің құпия сөзіңіз сәтті қалпына келтірілді. Қайта кіру үшін төмендегі Шығу түймесін басыңыз",
    enterCurrentPassword: "Ағымдағы құпия сөзді енгізіңіз",
    pleaseEnterCurrentPassword: "Ағымдағы құпия сөзді енгізіңіз",
    phoneNumber: "Телефон нөмірі",
    sex: "Секс",
    dateOfBirth: "Туған жылы",
    state: "Мемлекет",
    country: "Ел",
    loyaltyId: "Адалдық идентификаторы",
    createdAt: "Атқарылған",
    noOfTransactionsMade: "Жасалған мәмілелердің саны",
    yourDownloadWillStart: "Бір сәтте жүктеу басталады",
    exportCustomers: "Клиенттерді экспорттау",
    youhaveSuccessfullyToppedUp: "Сіз SMS бірліктерін сәтті толықтырдыңыз.",
    youNowHave: "Сізде қазір бар",
    smsUnits: "SMS бірліктері",
    enterNumberOfSmsUnits:
      "Сатып алғыңыз келетін SMS бірліктерінің санын енгізіңіз",
    pleaseEnterANumericValue: "Сандық мәнді енгізіңіз",
    pay: "Төлеу",
    accountEditedSuccessfully: "Есептік жазбаңыз сәтті өңделді.",
    youAeCurrentlyOn: "Сіз қазір қосулысыз",
    plan: "Жоспарлау",
    userData: "Пайдаланушы деректері",
    businessData: "БИЗНЕС ДЕРЕКТЕРІ",
    businessCategory: "Іскерлік категория",
    pleaseSelectCurrency: "Өз валютаңызды таңдаңыз",
    selectYourCurrency: "Валютаңызды таңдаңыз",
    purchaseMoreSmsUnits:
      "Төмендегі форманы қолдана отырып, қосымша SMS бірліктерін сатып алыңыз",
    youHave: "Сенде бар",
    atLeast4SmsUnitsRrequired:
      "Тексеру үшін кемінде 4 смс бірлігі қажет, толтырыңыз!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "«Тұтынушыңызды білу» (KYC) талаптарына сәйкес болу үшін банк шотыңызды растаңыз. Бұл USSD немесе жедел аударым арқылы төлемдерді жинауға, клиенттерден тапсырыс алуға және транзакциялық есеп айырысуды өңдеуге мүмкіндік береді. Тек нигериялық саудагерлерге қолданылады. Бастау үшін төмендегі түймені басыңыз.",
    reConnectBankAccount: "Банктік шотты қайта қосыңыз",
    accountName: "Аккаунт аты",
    accountNumber: "Шот нөмірі",
    bankName: "Банк атауы",
    verified: "Тексерілді",
    accountDetails: "Есептік жазба мәліметтері",
    kycBankAccount: "KYC",
    createTier: "Деңгей құру",
    fileUploadSuccessful: "файл сәтті жүктелді",
    fileUploadFailed: "файлды жүктеу сәтсіз аяқталды",
    createLoyaltyProgram: "Адалдық бағдарламасын жасаңыз",
    createLoyalty: "Адалдықты құру",
    name: "Аты",
    loyaltyArtwork: "Адалдық өнер туындысы",
    clickToUpload: "Жүктеу үшін басыңыз",
    amountToPointsRatio: "Ұпайлар қатынасы сомасы",
    points: "Ұпайлар",
    recommendedAmountToPointRatio:
      "Ұсынылады: to 1 -ден 1 ұпайға дейін. Яғни, жұмсалған әр 1 Наира үшін сіздің тұтынушыларыңыз 1 ұпай алады",
    pleaseTypeIn: "Енгізіңіз",
    toDeleteLoyalty: "бұл адалдықты жою үшін",
    deleteLoyalty: "Адалдықты жою",
    toConfirm: "қабылдау",
    edit: "Өңдеу",
    pointsAwardedSuccessfully: "Ұпайлар сәтті берілді.",
    enterPointValueToAward:
      "Клиенттерді марапаттағыңыз келетін нүкте мәнін енгізіңіз",
    award: "Марапаттау",
    awardPointValuesToCustomer: "Клиентке берілетін ұпай бағасы",
    enrollMembersToLoyalty: "Мүшелерді адалдыққа тіркеңіз",
    awardPoints: "Марапаттау ұпайлары",
    enroll: "Тіркелу",
    home: "Үй",
    loyalty: "Адалдық",
    enrollCustomers: "Тұтынушыларды тіркеу",
    selected: "Таңдалды",
    customer: "Тұтынушы",
    loyaltyActivationSuccessful: "Адалдықты белсендіру сәтті аяқталды.",
    loyaltyDeactivationSuccessful: "Адалдықты өшіру сәтті аяқталды.",
    viewTier: "Деңгейді қарау",
    deactivate: "Өшіру",
    activate: "Іске қосу",
    actions: "Әрекеттер",
    nameOfLoyalty: "Адалдық атауы",
    loyaltyStatus: "Адалдық мәртебесі Адалдық күйі",
    numberOfTiers: "Деңгейлер саны",
    createdOn: "Құрылды",
    createATier: "Деңгей құру",
    stopCreatingTierConfirmation: "Деңгей жасауды тоқтатқыңыз келе ме?",
    stopEditingTierConfirmation: "Бұл деңгейді өңдеуді тоқтатқыңыз келе ме?",
    nameOfTier: "Деңгейдің атауы",
    minimumSpendingTarget: "Шығынның минималды мақсаты",
    maximumSpendingTarget: "Шығынның максималды мақсаты",
    minimumSpendingTargetRequired: "шығынның минималды мақсаты талап етіледі",
    maximumSpendingTargetRequired: "шығынның максималды мақсаты қажет",
    rewardSponsor: "Сыйлық демеушісі",
    pleaseChooseARewardSponsor: "Сыйақыға демеуші таңдаңыз",
    self: "Өзіндік",
    partner: "Серіктес",
    rewardPartner: "Сыйлық серіктесі",
    pleaseSelectRewardPartner: "Өтінемін, серіктесіңізді марапаттаңыз",
    rewards: "Марапаттар",
    pleaseSelectAReward: "Сыйақыны таңдаңыз",
    instructionsOnRedeemingReward:
      "Клиент сыйақыны қалай алу керектігі туралы нұсқаулар",
    pleaseFillInThisField: "Өтінемін, бұл өрісті толтырыңыз!",
    toDeleteThisTier: " бұл деңгейді жою үшін",
    deleteTier: "Деңгейді жою",
    viewMembers: "Мүшелерді қарау",
    membersEnrolled: "Тіркелген мүшелер",
    instruction: "Нұсқау",
    membersIn: "Мүшелер кірді",
    availableTiersInLoyalty: "Адалдық бағдарламасында қол жетімді деңгейлер",
    tiers: "Деңгейлер",
    totalTier: "ЖАЛПЫ ДЕҢГЕЙ",
    availableLoyaltyProgramme: "Қол жетімді адалдық бағдарламасы",
    totalLoyalties: "БАРЛЫҚ НАҒЫЗДАР",
    memberDetails: "Мүше туралы мәліметтер",
    nameOfCustomer: "Тапсырыс берушінің аты",
    address: "Мекенжай",
    allEnrolledMembers: "Барлық тіркелген мүшелер",
    thisIsToWishYouHappyBirthday:
      "Бұл сізді туған күніңізбен шын жүректен құттықтап, бақытты өмір тілеймін. Loyster -ге бергеніңіз үшін рахмет. Мерекелер құтты болсын!",
    inputAnOfferPlease: "Ұсынысты енгізіңіз",
    pleaseSelectTheInsertPoint:
      "Хабарламада кірістіру нүктесін таңдап, қайтадан басыңыз",
    birthdayOfferAndMessage: "Туған күнге арналған ұсыныс пен хабарлама",
    birthdayOffer: "Туған күнге арналған ұсыныс",
    birthdayMessage: "Туған күн туралы хабарлама",
    noOfferFound: "Ұсыныс табылмады",
    settingABirthdayOffer:
      "Туған күн туралы ұсынысты орнату клиенттерге бұл ұсынысты туған күнінде SMS арқылы алуға мүмкіндік береді",
    createOffer: "Ұсыныс жасау",
    whatIsTheOffer: "Ұсыныс дегеніміз не?",
    editMessage: "Хабарды өңдеу",
    insert: "Кірістіру",
    theNameOfCustomerInserted: "Мұнда тұтынушының аты -жөні жазылады",
    theBirtdayOfferInserted: "Туған күн туралы ұсыныс осында енгізіледі",
    youSuccessfullyAddedNewBranch: "Сіз жаңа филиалды сәтті қостыңыз!",
    addNewBranch: "Жаңа филиал қосу",
    addBranch: "Филиал қосу",
    additionalBranchWillIncur: "Қосымша филиал пайда болады",
    perBranch: "әр филиалға",
    ecommerceBranchCosts: "Электрондық коммерция бөлімінің шығындары",
    pleaseEnterBranchName: "Филиал атауын енгізіңіз",
    pleaseEnterBranchAddress1: "Филиалдың мекен -жай жолын 1 енгізіңіз",
    enterBranchAddress1: "Филиалдың адрестік жолын енгізіңіз 1",
    enterBranchAddress2: "Филиалдың адрестік жолын енгізіңіз 1",
    pleaseEnterBranchAddress2: "Филиалдың мекенжай жолын 2 енгізіңіз",
    enterBranchName: "Филиалдың атын енгізіңіз",
    successfullyAddedStaff: "Сіз жаңа құрамды сәтті қостыңыз!",
    addNewStaff: "Жаңа қызметкерлерді қосыңыз",
    addStaff: "Персонал қосу",
    additionalStaffWillIncur: "Қосымша қызметкерлер қажет болады",
    perStaff: "персоналға.",
    pleaseEnterStaffEmail: "Қызметкерлердің электрондық поштасын енгізіңіз",
    pleaseEnterStaffUsername: "Қызметкердің пайдаланушы атын енгізіңіз",
    pleaseEnterStaffPassword: "Қызметкерлердің паролін енгізіңіз",
    pleaseSelectStaffRole: "Қызметкерлердің рөлін таңдаңыз",
    selectStaffRole: "Қызметкерлердің рөлін таңдаңыз",
    enterStaffEmail: "Қызметкерлердің электрондық поштасын енгізіңіз",
    enterStaffUsername: "Қызметкердің пайдаланушы атын енгізіңіз",
    enterStaffPassword: "Қызметкерлердің паролін енгізіңіз",
    spacesNotAllowedInUsername:
      "пайдаланушы атында рұқсат етілмеген бос орындар",
    admin: "Әкімші",
    pleaseSelectBusinessToAttachStaff:
      "Қызметкерлерді қосу үшін бизнесті таңдаңыз",
    searchForBranchToAttachStaff: "Қызметкерлерді қосу үшін филиалды іздеңіз",
    username: "Пайдаланушы аты",
    role: "Рөл",
    areYouSureToDeleteThis: "Осыны міндетті түрде жоясыз ба?",
    branches: "Филиалдар",
    upgradeYourPlan: "Жоспарыңызды жаңартыңыз.",
    add: "ҚОСУ",
    addNew: "Жаңа қосу",
    customerWithEmailAlreadyExists:
      "Электрондық поштасы/телефон нөмірі бар тұтынушы бұрыннан бар!",
    successfullyAddedNewCustomer: "Сіз жаңа тұтынушыны сәтті қостыңыз!",
    addCustomer: "Тұтынушы қосу",
    pleaseEnterCustomerFirstName: "Клиенттің атын енгізіңіз",
    pleaseEnterCustomerLastName: "Клиенттің фамилиясын енгізіңіз",
    pleaseEnterCustomerPhoneNumber: "Клиенттің телефон нөмірін енгізіңіз",
    pleaseEnterCustomerEmail: "Клиенттің электрондық поштасын енгізіңіз",
    pleaseEnterCustomerAddressLine: "Клиенттің мекен -жай жолын енгізіңіз",
    pleaseEnterCustomerOtherAddress: "Клиенттің басқа мекенжайын енгізіңіз",
    pleaseSelectCustomerGender: "Клиенттің жынысын таңдаңыз",
    gender: "Жыныс",
    male: "Еркек",
    female: "Әйел",
    bank: "Банк",
    selectBank: "Банк таңдаңыз",
    stateOrRegionOrProvince: "Штат/аймақ/провинция",
    customerNotes: "Тұтынушы ескертпелері",
    sendSms: "SMS жіберу",
    editCustomer: "Тұтынушыны өңдеу",
    redeemReward: "Сыйлықты алу",
    issueLoyaltyCard: "Адалдық картасын шығару",
    deleteCustomer: "Тұтынушыны жою",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Сіз адалдық мүшелік идентификаторын сәтті тағайындадыңыз",
    noMembershipIdAvailable:
      "Мүшелік идентификаторлары жоқ. Бізге hello@loystar.co мекен -жайы бойынша хабарласыңыз",
    sendEmail: "Электрондық поштаны жіберу",
    membershipPoints: "Мүшелік ұпайлары",
    customerDetails: "Тұтынушы туралы мәліметтер",
    close: "Жабық",
    loyaltyBalance: "Адалдық балансы",
    pointsBalance: "Ұпай балансы",
    starBalance: "Жұлдыз балансы",
    requiredPoints: "Қажетті ұпайлар",
    requiredStars: "Қажетті жұлдыздар",
    reddemCode: "Кодты пайдалану",
    toDeleteThisCustomer: "бұл тұтынушыны жою үшін",
    customerHasBeenDeletedSuccessfully: "Тұтынушы сәтті жойылды!",
    customerWithPhoneAlreadyExists: "Телефон нөмірі бар тұтынушы бұрыннан бар",
    customerWasSuccessfullyEdited: "Тұтынушы сәтті өңделді",
    anErrorOccured: "Қате пайда болды",
    phoneNumberIncludeCountry: "Телефон нөмірі (ел кодын қосқанда)",
    yourFileQueuedForUpload:
      "Сіздің файл жүктеу үшін кезекке қойылды. Бірнеше секундтан кейін бетті жаңартыңыз.",
    thereWasAnErrorPerformingOperation:
      "Операцияны орындау кезінде қате болды!",
    pleaseSelectFile: "Файлды таңдаңыз!",
    oopsOnlyCsvFilesAllowed:
      "Қап! Тек CSV файлдарына рұқсат етілген. .Csv файлын жүктеңіз.",
    fileShouldBeSmallerThan5Mb:
      "Файл 5 МБ -тан аз болуы керек. Егер сізде үлкенірек файл болса, support@loystar.co электронды поштасына жіберіңіз. Рақмет сізге",
    customerFirstNameIsRequired:
      "клиенттің аты -жөні қатарда міндетті түрде көрсетіледі",
    customerPhoneNumberIsRequired: "кезекте тұтынушының телефон нөмірі қажет",
    importCustomers: "Клиенттерді импорттау",
    upload: "Жүктеу",
    clickIconToDownloadCsv:
      "CSV файл үлгісін жүктеу үшін осы белгішені басыңыз.",
    getGoogleSheetVersion: "Google парағының нұсқасын алыңыз",
    clickOrDragFileToUpload:
      "Файлды жүктеу үшін осы аймаққа басыңыз немесе сүйреңіз",
    missingOrInvalidColumnHeader:
      "Баған тақырыбы жоқ немесе жарамсыз. Үлгі форматын ұстаныңыз. Рақмет сізге.",
    youHaveImported: "Сіз импорттадыңыз",
    youSuccessfullyRedeemedReward: "Сіз сыйақыңызды сәтті сатып алдыңыз!",
    sixDigitCode: "Алты таңбалы код",
    pleaseEnterCustomerRewardCode: "Клиенттің сыйақы кодын енгізіңіз",
    enterRewardCode: "Сыйлық кодын енгізіңіз. (Сыйлық коды регистрді ескереді)",
    selectLoyaltyProgram: "Адалдық бағдарламасын таңдаңыз",
    stamps: "Маркалар",
    smsUnitsLow: "SMS бірліктері төмен",
    pleaseTopUpSmsUnits: "SMS бірліктерін толтырыңыз",
    smsSuccessfullySentForDelivery: "Жеткізу үшін SMS сәтті жіберілді!",
    sendSmsTo: "Смс жіберу",
    allCustomers: "Барлық клиенттер",
    unitsAvailable: "Қол жетімді бірліктер",
    pleaseTypeInTheMessage: "Хабарламаны енгізіңіз",
    message: "Хабар",
    charactersRemaining: "қалған таңбалар",
    avoidUseOfSpecialCharacters:
      "SMS бірліктерін сақтау үшін арнайы таңбалар мен эмодзилерді пайдаланудан аулақ болыңыз.",
    note: "Ескерту",
    errorFetchingCustomersMultilevelDetail:
      "Клиенттерге көп деңгейлі мәліметтерді алу қателігі",
    search: "Іздеу",
    reset: "Қалпына келтіру",
    importCustomer: "Клиентті импорттау",
    addNewCustomer: "Жаңа тұтынушыны қосыңыз",
    sendSmsBroadcast: "SMS хабар тарату",
    totalCustomers: "БАРЛЫҚ ТҰТЫНУШЫЛАР",
    disbursementDetails: "Төлем туралы мәліметтер",
    paidBy: "Төлеген",
    disbursed: "Төленді",
    bankAccountName: "Банк шотының атауы",
    bankAccountNumber: "Банктік шот нөмірі",
    transferInitiated: "Тасымалдау басталды",
    transferCompleted: "Тасымалдау аяқталды",
    pleaseEnterAValid: "Жарамды енгізіңіз",
    begin: "баста",
    end: "Соңы",
    date: "күні",
    paymentDate: "Төлем күні",
    selectDisbursementDuration: "Төлеу ұзақтығын таңдаңыз",
    totalSettled: "Жалпы есеп айырысу",
    totalUnsettled: "Барлығы реттелмеген",
    toDeleteThisSale: "бұл сатылымды жою үшін",
    draftSaleDeletedSuccessfully: "Сауда жобасы сәтті жойылды!",
    deleteSale: "Сатуды жою",
    pleaseTypeInYourTotal: "Жоюды растау үшін жалпы соманы енгізіңіз",
    billDetails: "Билл туралы мәліметтер",
    printBill: "Биллді басып шығару",
    servedBy: "Қызмет көрсететін",
    total: "Барлығы",
    createdDate: "Жасалған күні",
    createdTime: "Жасалған уақыт",
    status: "Күй",
    loginSuccessful: "Кіру сәтті болды",
    pleaseWaitWhileWeConnectAccount: "Есептік жазбаңызды қосқанша күте тұрыңыз",
    connectionFailedTryAgain: "Қосылу сәтсіз аяқталды. Қайталап көріңіз.",
    connectionSuccessful: "Байланыс сәтті",
    viewDetails: "Мәліметтерді қарау",
    enable: "Қосу",
    free: "Тегін",
    cost: "Құны",
    visitWebsite: "Веб -сайтқа кіріңіз",
    pleaseUpgradeYourPlanToPro:
      "Бұл бағдарламаны қосу үшін жоспарыңызды Pro Plus -қа жаңартыңыз",
    connectYourBankAccount: "Төлемдерді алу үшін банктік шотыңызды қосыңыз.",
    disable: "Өшіру",
    enableApp: "Қолданбаны қосу",
    addNewProductToInvoice: "Шот -фактураға жаңа өнім қосыңыз",
    toDeleteThisInvoice: "бұл шот -фактураны жою үшін",
    invoiceDeletedSuccessfully: "Шот -фактура сәтті жойылды!",
    deleteInvoice: "Шот -фактураны жою",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Сіз шот -фактура бойынша төлем хабарын сәтті қостыңыз!",
    pleaseEnterPaymentInstructions: "Төлем нұсқауларын енгізіңіз",
    invoiceId: "Шот -фактура идентификаторы",
    paidDate: "Төленген күн",
    reference: "Сілтеме",
    productAdded: "Өнім қосылды",
    productOutOfStock: "Өнім қорда жоқ. Қайта қойыңыз.",
    totalInvoices: "ЖАЛПЫ ШАҚЫРЛАР",
    totalPaidInvoices: "ЖАЛПЫ АҚЫЛДЫ ШАҚЫРЛАР",
    totalUnpaidInvoices: "ЖАЛПЫ КӨМЕКСІЗ ШАҚЫРМАЛАР",
    loyaltyProgramDeleted: "Адалдық бағдарламасы жойылды",
    thisLoyaltyProgramDeletedSuccessfully:
      "Бұл адалдық бағдарламасы сәтті жойылды",
    thisLoyaltyProgramEditedSuccessfully:
      "Бұл адалдық бағдарламасы сәтті өңделді",
    loyaltyProgramAddedSuccessfully: "Адалдық бағдарламасы сәтті қосылды",
    loyaltyProgramEdited: "Адалдық бағдарламасы өңделген",
    loyaltyProgramAdded: "Адалдық бағдарламасы қосылды",
    loyaltyDetails: "Адалдық туралы мәліметтер",
    doYouWantToCloseDialog: "Бұл диалогты жапқыңыз келе ме?",
    pleaseEnterLoyaltyName: "Сіздің адалдығыңыздың атын енгізіңіз",
    programType: "Бағдарлама түрі",
    pleaseSelectProgramType: "Бағдарлама түрін таңдаңыз",
    simplePoints: "Қарапайым ұпайлар",
    stampsProgram: "Маркалар бағдарламасы",
    threshold: "Табалдырық",
    pleaseEnterLoyaltyThreshold: "Адалдық шегін енгізіңіз",
    reward: "Сыйақы",
    pleaseEnterLoyaltyReward: "Адалдық сыйлығын енгізіңіз",
    totalUserPoints: "Пайдаланушылардың жалпы ұпайлары",
    totalUserStamps: "Жалпы пайдаланушы маркалары",
    spendingTarget: "Мақсатты жұмсау",
    spendingTargetHint1:
      "Шығынның мақсаты - бұл сыйақы алу үшін тұтынушы қанша ақша жұмсауы керек. 1 валюталық мән = 1 балл.",
    spendingTargetHint2:
      "Маркалардың мақсаты - сыйақы алу үшін тұтынушы қанша марка жинауы керек. мысалы 5",
    addNewLoyaltyProgram: "Жаңа адалдық бағдарламасын қосыңыз",
    addLoyaltyProgram: "Адалдық бағдарламасын қосыңыз",
    loyaltyProgramType: "Адалдық бағдарламасының түрі",
    pleaseSelectLoyaltyProgramType: "Адалдық бағдарламасының түрін таңдаңыз",
    nameOfProgram: "Бағдарламаның атауы",
    pleaseEnterProgramName: "Бағдарлама атауын енгізіңіз",
    whatIsTheReward: "Сыйақысы қандай?",
    egNextPurchaseIsFree: "Мысалы, Келесі сатып алу тегін",
    customerName: "Тұтынушының аты",
    guestCustomer: "Қонақ тұтынушы",
    orderConfirmedSuccessfully: "Тапсырыс сәтті расталды",
    orderCancelledSuccessfully: "Тапсырыс сәтті жойылды",
    confirmOrder: "Тапсырысты растаңыз",
    cancelOrder: "Тапсырысты болдырмау",
    allOrders: "Барлық тапсырыстар",
    totalOrders: "БАРЛЫҚ ЗАКАЗДАР",
    doYouWantToAcceptOrder: "Бұл тапсырысты қабылдағыңыз келе ме?",
    doYouWantToCancelOrder: "Бұл тапсырыстан бас тартқыңыз келе ме?",
    orderDetails: "Тапсырыс мәліметтері",
    orderCreatedAt: "Тапсырыс жасалған",
    supplier: "Жеткізуші",
    productName: "Өнім атауы",
    quantity: "Саны",
    unitPrice: "Тауар өлшемінің бағасы",
    receivedBy: "Алды",
    reportFrom: "Есеп",
    totalSupplies: "Жабдықтардың жалпы саны",
    allRightsReserved: "Барлық құқықтар сақталған",
    toDeleteThisTransaction: "осы транзакцияны жою үшін",
    transactionDeletedSuccessfully:
      "Транзакция сәтті жойылды. Акциялар тауарлық -материалдық қорларға қайтарылды.",
    deleteTransaction: "Транзакцияны жою",
    transactionDetails: "Мәміле туралы мәліметтер",
    printReceipt: "Баспа түбіртегі",
    channel: "Арна",
    discount: "Жеңілдік",
    profit: "Пайда",
    discountedSales: "Жеңілдікпен сату",
    errorFetchingRecord: "Жазбаны алу қатесі",
    exportTransactions: "Экспорттық операциялар",
    errorFetchingSalesRecord: "Экспорттау бойынша сату жазбасын алу қателігі.",
    totalSellingPrice: "Жалпы сату бағасы",
    totalCostPrice: "Жалпы шығын бағасы",
    appliedDiscount: "Қолданылатын жеңілдік",
    noOfItems: "Элементтер саны",
    sales: "Сату",
    export: "Экспорттау",
    totalProfit: "Жалпы пайда",
    totalBalanceInPeriod: "Кезеңдегі жалпы қалдық",
    allTimeSales: "Барлық уақыттағы сату",
    records: "Жазбалар",
    todaysSales: "Бүгінгі сатылымдар",
    transaction: "транзакция",
    youHaveExceededTotalNumberOfProducts:
      "Сіз жоспар бойынша рұқсат етілген өнімдердің жалпы санынан асып кеттіңіз. Лимитті жоғарылату үшін жоспарыңызды жаңартыңыз.",
    youNeedToHaveLoyaltyProgram:
      "Бұл мүмкіндікті пайдалану үшін адалдық бағдарламасы болуы керек!",
    price: "Бағасы",
    category: "Санат",
    stockTracking: "Акцияларды бақылау",
    stockCount: "Қор саны",
    taxed: "Салық салынған",
    originalPrice: "Бастапқы баға",
    costPrice: "Шығын бағасы",
    unit: "Бірлік",
    productImage: "Өнім кескіні",
    taxRate: "Салық ставкасы",
    taxType: "Салық түрі",
    trackInventory: "Жолды түгендеу",
    variants: "Нұсқалар",
    hasVariants: "Нұсқалары бар",
    importProduct: "Өнімді импорттау",
    exportProducts: "Өнімдерді экспорттау",
    addNewProduct: "Жаңа өнім қосу",
    viewCategory: "Санатты қарау",
    viewSuppliers: "Жеткізушілерді қарау",
    receiveInventory: "Түгендеу алу",
    printAllProductsTag: "Барлық өнімдер тегін басып шығарыңыз",
    deleteAll: "Барлығын жою",
    totalProducts: "ЖАЛПЫ ӨНІМДЕР",
    youveSuccessfullyAddedANewCategory: "Сіз жаңа санатты сәтті қостыңыз",
    addNewCategory: "Жаңа санат қосу",
    addCategory: "Санат қосу",
    categoryName: "Санат атауы",
    pleaseEnterCategoryName: "Санат атауын енгізіңіз",
    stampsTarget: "Маркалар мақсатты",
    sendInventory: "Түгендеу жіберу",
    productDetails: "Өнім туралы мәліметтер",
    youveSuccessfullyEditedThisCategory: "Сіз бұл санатты сәтті өңдедіңіз",
    update: "Жаңарту",
    categoryList: "Санаттар тізімі",
    categories: "Санаттар",
    enterQuantityToUpdate: "Жаңарту үшін мөлшерді енгізіңіз",
    inventorySentSuccessfully: "Инвентаризация сәтті жіберілді!",
    updateInventory: "Түгендеуді жаңарту",
    currentQuantity: "Ағымдағы саны",
    pleaseEnterQuantityToAdd: "Қосқыңыз келетін мөлшерді енгізіңіз",
    pleaseSelectABranch: "Филиалды таңдаңыз",
    searchForBranch: "Филиалды іздеңіз",
    youCantSendMoreThanStock: "Сіз қоймада бар мөлшерден артық жібере алмайсыз",
    send: "Жіберу",
    pleaseEnterQuantityToSend: "Жібергіңіз келетін соманы енгізіңіз",
    productNameIsRequiredOnRow: "өнімнің атауы жолда қажет",
    productCategoryIsRequiredOnRow: "өнім санаты қатарда қажет",
    productPriceIsRequiredOnRow: "өнімнің бағасы кезек бойынша қажет",
    productUnitIsRequiredOnRow: "өнім бірлігі қатарға қажет",
    productQuantityIsRequiredOnRow: "өнім саны қатарға қажет",
    productVariantsRequireTracking: "өнім нұсқалары бақылауды қажет етеді!",
    pleaseAddVariantClickButton: "Қосу түймесін басу арқылы нұсқаны қосыңыз!",
    totalVariantsMoreThanSelectedQuantity:
      "Жалпы нұсқалар таңдалған өнім санынан көп, Pls нұсқа санын азайтады",
    productEditedSuccessfully: "Өнім сәтті өңделді!",
    fileTooLargeLessThan4Mb:
      "Файл өлшемі тым үлкен! Файл өлшемі 4 МБ -тан аз болуы керек.",
    suchVariantAlreadyExist: "Мұндай нұсқа бұрыннан бар",
    addVariants: "Нұсқаларды қосыңыз",
    editProduct: "Өнімді өңдеу",
    pleaseEnterProductName: "Өнім атауын енгізіңіз",
    pleaseEnterProductPrice: "Өнім бағасын енгізіңіз",
    pleaseEnterProductOriginalPrice: "Өнімнің бастапқы бағасын енгізіңіз",
    productDescription: "Өнім Сипаттамасы",
    selectProductCategory: "Өнім санатын таңдаңыз",
    pleaseSelectProductCategory: "Өнім санатын таңдаңыз",
    productCostPrice: "Өнімнің өзіндік құны",
    productSellingPrice: "Өнімді сату бағасы",
    productOriginalPrice: "Өнімнің бастапқы бағасы",
    maxFileSizeAllowedIs4Mb: "Максимум Рұқсат етілген файл өлшемі - 4 Мб",
    productsWithPicturesArePublished:
      "Тапсырыс алу үшін суреттері бар өнімдер Discover -те жарияланады",
    shouldThisProductBeTracked: "Бұл өнімді қадағалау керек пе?",
    pleaseSelectStockUnit: "Акция бірлігін таңдаңыз",
    stockUnit: "Акция бірлігі",
    bag: "Сөмке",
    bottle: "Бөтелке",
    bunch: "БАНЧ",
    can: "БОЛАДЫ",
    carton: "КАРТОН",
    crate: "CRATE",
    cup: "КУБО",
    dozen: "ДОЗЕН",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "КИЛОГРАММА",
    litre: "LITER",
    pack: "ПАК",
    pair: "ЖҰП",
    pieces: "БӨЛІМДЕР",
    plate: "ПЛИТ",
    tonne: "ТОНН (MT)",
    uNIT: "БІРЛІК",
    yard: "Аула",
    pleaseEnterProductQuantity: "Өнім санын енгізіңіз",
    productQuantity: "Өнім саны",
    isThisProductTaxed: "Бұл өнімге салық салынған ба?",
    selectTaxType: "Салық түрін таңдаңыз",
    pleaseSelectTaxType: "Салық түрін таңдаңыз",
    progressive: "Прогрессивті",
    proportional: "Пропорционалды",
    pleaseEnterProductTaxRate: "Өнім салығының мөлшерлемесін енгізіңіз",
    doesProductHaveVariants: "Бұл өнімнің нұсқалары бар ма?",
    type: "Түрі",
    value: "Мән",
    pleaseEnterVariantType: "Нұсқа түрін енгізіңіз",
    pleaseEnterVariantValue: "Нұсқаның мәнін енгізіңіз",
    pleaseEnterVariantPrice: "Варианттың бағасын енгізіңіз",
    pleaseEnterVariantQuantity: "Вариант санын енгізіңіз",
    productDeletedSuccessfully: "Өнім сәтті жойылды!",
    categoryDeletedSuccessfully: "Санат сәтті жойылды!",
    toDeleteThisProduct: "бұл өнімді жою үшін",
    invalidProductQuantity: "Өнім саны жарамсыз",
    quantityAddedIsOverStock:
      "Сіз қосатын мөлшер сізде бар нәрседен асып түседі.",
    itemInventoryNotTracked: "Элементтердің инвентаризациясы бақыланбайды",
    addBulkQuantity: "Жаппай мөлшерді қосыңыз",
    enterBulkQuantity: "Жаппай санды енгізіңіз",
    pleaseEnterBulkQuantity: "Жаппай санды енгізіңіз",
    youveSuccessfullyAddedANewProduct: "Сіз жаңа өнімді сәтті қостыңыз!",
    pleaseEnterProductSellingPrice: "Өнімнің сату бағасын енгізіңіз",
    doYouWantToTrackProductStock: "Өнім қорын бақылауды қалайсыз ба?",
    sellingPrice: "Сату бағасы",
    setProductExpiryReminder:
      "Өнімнің жарамдылық мерзімі туралы еске салғышты орнатыңыз",
    productExpiryDate: "Өнімнің жарамдылық мерзімі",
    startRemindingFrom: "Еске салуды бастау",
    productSuppliesAddedSuccessfully:
      "Сіз өнім жеткізілімдерін сәтті қостыңыз.",
    addProductSupplies: "Өнім жабдықтарын қосыңыз",
    pleaseSelectSupplier: "Жабдықтаушыны таңдаңыз",
    nameOfProduct: "Өнімнің атауы",
    pleaseSelectProduct: "Өнімді таңдаңыз",
    productVariant: "Өнім нұсқасы",
    pleaseSelectAVariant: "Вариантты таңдаңыз",
    pleaseEnterUnitPrice: "Бірлік бағасын енгізіңіз",
    businessBranch: "Кәсіпкерлік бөлімше",
    pleaseSelectBranch: "Филиалды таңдаңыз",
    youveSuccessfullyAddedANewSupplier: "Сіз жаңа жеткізушіні сәтті қостыңыз",
    addSupplier: "Жеткізушіні қосу",
    pleaseEnterSupplierEmail: "Жабдықтаушының электрондық поштасын енгізіңіз",
    pleaseAddADescription: "Сипаттаманы қосыңыз",
    anErrorOccuredProductsDeleted:
      "Операцияны орындау кезінде қате пайда болды. Назар аударыңыз, бұл процесте бірнеше өнім жойылған болуы мүмкін",
    bulkDelete: "Жаппай жою",
    youAreAboutToDelete: "Сіз жойғалы жатырсыз",
    product: "Өнім",
    isDueToRedeem: "өтеуге байланысты",
    aReward: "сыйақы",
    pleaseSelectCustomerToReeemReward: "Сыйлықты алу үшін тұтынушыны таңдаңыз.",
    youHaveNoLoyaltyProgramRunning: "Сізде белсенді адалдық бағдарламасы жоқ",
    customerHhasNoPointsInLoyaltyProgram:
      "Клиенттің бұл адалдық бағдарламасында ұпайлары жоқ",
    proceedWithPayment: "Төлемді жалғастыру керек пе?",
    youAreAboutToPayForTransactionUsingPoints:
      "Сіз транзакция үшін ұпайларды төлейсіз.",
    customerHas: "Клиент бар",
    worth: "құнды",
    andIsNotSufficientToPayForTransaction:
      "және бұл операцияны төлеуге жеткіліксіз. Олар балансты басқа төлем әдісімен қосқысы келе ме?",
    addCustomerLoyalty: "Тұтынушылардың адалдығын қосыңыз",
    pleaseAddCustomerFirst: "Алдымен тұтынушыны қосыңыз немесе таңдаңыз.",
    pleaseWaitWhileWeGetReady: "Өтінемін, біз дайын болғанша",
    lowStock: "Төмен қор",
    pleaseEnterAmountTendered: "Ұсынылған соманы енгізіңіз",
    areYouSureToBookSaleOffline:
      "Бұл сатылымды офлайн түрде брондағыңыз келе ме?",
    saleWouldBeBookedAutomatically:
      "Интернетті қосқан кезде сату автоматты түрде брондалады",
    offlineSalesBookingCancelled: "Сатуға офлайн брондау тоқтатылды",
    covid19Message:
      "COVID19: таралуды тоқтату үшін қолыңызды сабынмен жуыңыз немесе тазартыңыз. Әрқашан қауіпсіз болыңыз",
    saleSuccessfullyRecorded: "Сатылым сәтті жазылды!",
    sendReceiptToEmail: "Электрондық поштаға түбіртекті жіберіңіз",
    sendThankYouSms: "Рахмет смс жіберіңіз",
    sendShippingDetails: "Жеткізу мәліметтерін жіберіңіз",
    addLoyalty: "Адалдықты қосыңыз",
    searchCustomerNameOrNumber: "Клиенттің атын немесе нөмірін іздеңіз",
    clickTheSearchCustomerBox:
      "Клиентті іздеу жолағын және сканерлеу картасын басыңыз",
    enterProductPrice: "Өнім бағасын енгізіңіз",
    enterPriceFor: "Бағасын енгізіңіз",
    searchForProduct: "Өнімді іздеңіз",
    all: "Барлық",
    backToDashboard: "Бақылау тақтасы дегенге қайта келу",
    viewDraftSales: "Сатылым жобасын қарау",
    variantSelected: "нұсқа таңдалды",
    variant: "нұсқа",
    thePreviousVariantSelectionNotAvailable:
      "Бағаның негізінде таңдалған жаңа нұсқа үшін алдыңғы нұсқаны таңдау мүмкін емес, таңдауыңызды өзгертіңіз.",
    pleaseSupplyPositiveQuantityNumber: "Позитивті санды енгізіңіз",
    lowStockFor: "Аз қор үшін",
    clearVariants: "Нақты нұсқалар",
    pleaseEnterQuantity: "Саны енгізіңіз",
    picture: "Сурет",
    redemptionToken: "Өтеу белгісі",
    token: "Токен",
    totalSpent: "Барлығы жұмсалды",
    confirmPayment: "Төлемді растау",
    hasPaymentBeenMade: "Төлем сәтті өңделді ме?",
    enterTransactionReference: "Төлеген транзакция туралы анықтаманы енгізіңіз",
    pleaseSelectACustomer: "Клиент таңдаңыз!",
    areYouSureToApplyDiscount:
      "Сіз жеңілдік қолданғыңыз келетініне сенімдісіз бе?",
    addYourBankAccountToEnableUssd:
      "UPay арқылы USSD жедел аударымын қосу үшін банк шотыңызды қосыңыз",
    totalAmountToPay: "Төлеуге жататын жалпы сома",
    doYouWantToCancelTransaction: "Бұл транзакциядан бас тартқыңыз келе ме?",
    savePrintBill: "Есепшотты сақтау/басып шығару",
    enterAmountCollectedForCustomer: "Клиент үшін жиналған соманы енгізіңіз",
    recordSale: "Жазбаларды сату",
    checkOutWith: "Бірге тексеріңіз",
    instantTransfer: "Лездік аударым",
    dialTheUSSDCode: "USSD кодын теріңіз",
    pleaseSelectABank: "Банк таңдаңыз",
    payWithUSSD: "USSD арқылы төлеу",
    sendBillTo: " - Биллді жіберіңіз",
    waitingForUSSDTransfer: "USSD аударымы күтілуде",
    percent: "Пайыз",
    applyDiscount: "Жеңілдік қолдану",
    thisBillHasBeenSaved: "Бұл шот сақталды",
    saveDraft: "Жобаны сақтау",
    pleaseTypeANameToIdentifyCustomer:
      "Тапсырыс берушіні анықтау үшін атын енгізіңіз",
    paymentDetails: "Төлем туралы мәліметтер",
    basePrice: "Негізгі баға",
    staff: "Персонал",
    subTotal: "Жиынтық",
    durationMonths: "Ұзақтығы (ай)",
    selectADuration: "Ұзақтығын таңдаңыз",
    oneMonth: "1 ай",
    twoMonths: "2 ай",
    threeMonths: "3 ай",
    sixMonths: "6 ай",
    twelveMonths: "12 ай",
    eighteenMonths: "18 ай",
    twentyFourMonths: "24 ай",
    twoMonthsFree: "(2 ай тегін)",
    threeMonthsFree: "(3 ай тегін)",
    fiveMonthsFree: "(5 ай тегін)",
    yourAccountHasBeen: "Сіздің есептік жазбаңыз болды",
    renewed: "жаңартылды",
    upgraded: "жаңартылды",
    successfully: "сәтті",
    yourSubscription: "Сіздің жазылымыңыз",
    youAreCurrentlyEnrolledOnA: "Сіз қазіргі уақытта a",
    pleaseChooseAPaymentOption: "Төлем опциясын таңдаңыз",
    planRenewal: "Жаңарту жоспары",
    planUpgrade: "Жоспарды жаңарту",
    pleaseSelectDurationToPayFor: "Сіз төлегіңіз келетін ұзақтықты таңдаңыз",
    staffAccounts: "Персоналды есепке алу",
    ecommerce: "Электронды сауда",
    pleaseSelectAPlan: "Жоспарды таңдаңыз",
    includeAddons: "Қосымшаларды қосыңыз",
    viewTransactions: "Транзакцияларды қарау",
    customerHasNoCompletedTansactions:
      "Клиенттің әлі аяқталған операциялары жоқ",
    branch: "Филиал",
    enterNumberOfEcommerceBranches:
      "Электрондық коммерция филиалдарының санын енгізіңіз",
    enterNumberOfEcommerceBranchesToPay:
      "Сіз төлегіңіз келетін электрондық коммерция филиалдарының санын енгізіңіз",
    ecommerceIntegration: "Электрондық коммерция интеграциясы",
    enterNumberOfBranches: "Филиалдар санын енгізіңіз",
    enterNumberOfAdditionalBranchesToPay:
      "Сіз төлегіңіз келетін қосымша филиалдардың санын енгізіңіз",
    enterNumberOfStaffs: "Штат санын енгізіңіз",
    enterNumberOfStaffsToPayFor:
      "Сіз төлегіңіз келетін қызметкерлер санын енгізіңіз",
    discountApplies: "Жеңілдік қолданылады",
    starsOnThe: "жұлдыздар",
    offer: "ұсыныс",
    get: "Алу",
    moreStarsToRedeem: "сатып алу үшін көбірек жұлдыздар",
    taxVat: "Салық (ҚҚС)",
    callCashierToCompletePayment: "Төлемді аяқтау үшін кассаға қоңырау шалыңыз",
    receipt: "Қолхат",
    dear: "Құрметті",
    thankYouForYourOrderFindGoods:
      "Тапсырысыңызға рахмет. Келісілгендей жеткізілетін келесі тауарларды табыңыз.",
    shippingNote: "Жеткізу туралы ескерту",
    enterShippingNote: "Жеткізу жазбасын енгізіңіз",
    shippingAddress: "Жеткізілім мекен-жайы",
    enterShippingAddress: "Жеткізу мекенжайын енгізіңіз",
    wellDoneYouAreDueToRedeem: "Жарайсың! Сіз өтеуіңіз керек",
    toGetYourRewardNextVisit:
      "келесі сапарыңызда сыйлық алу үшін. Рақмет сізге",
    pointsOnThe: "Ұпайлар",
    morePointsToRedeem: "өтеу үшін көбірек ұпай",
    showCode: "Кодты көрсету",
    toGetYourRewardOnNextVisit:
      "келесі сапарыңызда сыйлық алу үшін. Рақмет сізге",
    earn: "Табыңыз",
    delivaryNote: "Жеткізу туралы ескерту",
    draftSales: "Жобаның сатылымы",
    startDate: "Басталатын күн",
    endDate: "Аяқталу күні",
    orders: "Тапсырыстар",
    checkout: "шығу",
    noProductItem: "Өнім элементі жоқ",
    selectProductImage: "Өнім кескінін таңдаңыз",
    selectCountry: "Елді таңдаңыз",
    selectRegion: "Мемлекет/аймақ таңдаңыз",
    printProductTag: "Өнім белгісін басып шығару",
    transactionReference: "Транзакция туралы анықтама",
    Cashier: "Кассир",
    Manager: "Менеджер",
    Owner: "Иесі",
    Admin: "Әкімші",
    addPartners: "Серіктестер қосыңыз",
    addNewLoyaltyPartner: "Жаңа адал серіктес қосыңыз",
    pleaseEnterCompanyName: "Компания атауын енгізіңіз",
    companyName: "Компанияның Аты",
    pleaseEnterCompanyRepName: "Компания өкілінің атын енгізіңіз",
    companyRepName: "Компания өкілінің аты-жөні",
    pleaseEnterCompanyRepEmail:
      "Компания өкілінің электрондық поштасын енгізіңіз",
    companyRepEmail: "Компания өкілінің электрондық поштасы",
    pleaseEnterCompanyRepPhone: "Компания өкілінің телефон нөмірін енгізіңіз",
    companyRepPhone: "Компания өкілінің телефон нөмірі",
    addReward: "Сыйақы қосыңыз",
    pleaseEnterRewardName: "Сыйақы атауын енгізіңіз",
    rewardName: "Сыйлық атауы",
    rewardQuantity: "Сыйлық мөлшері",
    rewardDescription: "Сыйлық сипаттамасы",
    rewardType: "Сыйлық түрі",
    pleaseEnterRewardType: "Сыйақы түрін енгізіңіз",
    pleaseEnterRewardQuantity: "Сыйақы мөлшерін енгізіңіз",
    pleaseEnterRewardDescription: "Сыйақы сипаттамасын енгізіңіз",
    fineDining: "Керемет асхана",
    luxuryFashion: "Сәнді сән",
    hotels: "Қонақ үйлер",
    travel: "Саяхат",
    foodAndBeverage: "Тамақ және сусын",
    fashion: "Сән",
    health: "Денсаулық",
    furniture: "Жиһаз",
    entertainment: "Ойын-сауық",
    automobile: "Автомобиль",
    education: "Білім",
    beautyAndSpa: "Сұлулық және СПА",
    staycation: "Тұру",
    events: "Оқиғалар",
    trips: "Сапарлар",
    oilAndGas: "Мұнай және газ",
    laundry: "Кір",
    partnerCategory: "Серіктес санаты",
    freeItem: "Тегін элемент",
  },
  Korean: {
    cashier: "출납원",
    manager: "관리자",
    owner: "소유자",
    online: "온라인",
    offline: "오프라인",
    changePassword: "비밀번호 변경",
    currentPasswordMessage: "현재 비밀번호를 입력하세요",
    passwordMessage: "비밀번호를 입력하세요",
    currentPassword: "현재 비밀번호",
    password: "비밀번호",
    confirmPasswordMessage: "비밀번호를 확인해주세요!",
    confirmPassword: "비밀번호 확인",
    sendViaEmail: "이메일로 보내기",
    sendViaWhatsapp: "WhatsApp을 통해 보내기",
    downloadPdf: "PDF 다운로드",
    paid: "유급의",
    unpaid: "무급",
    partial: "부분적인",
    closeInvoice: "송장을 닫으시겠습니까?",
    closeInvoiceConfirmation:
      "인보이스가 저장되지 않을 수 있습니다. 닫으시겠습니까?",
    yes: "예",
    no: "아니요",
    invoice: "송장",
    wasDeducted: "공제되었다",
    for: "~을위한",
    item: "안건",
    addProduct: "제품 추가",
    paymentReference: "지불 참조",
    amountPaid: "금액 지급",
    discountAmount: "할인 량",
    amountDue: "지불액",
    amount: "양",
    dueDate: "마감일",
    paymentType: "지불 유형",
    card: "카드",
    cash: "현금",
    bankTransfer: "은행 송금",
    paymentMessage: "결제 메시지",
    description: "설명",
    sendReceipt: "영수증 보내기",
    delete: "삭제",
    save: "구하다",
    resend: "재전송",
    saveAndSend: "구하다",
    invoiceSaved: "인보이스가 저장되었습니다!",
    selectPaymentMethod: "결제수단을 선택해주세요!",
    selectCustomer: "고객을 선택하십시오!",
    cartEmptyError:
      "장바구니 목록은 비워둘 수 없습니다. 송장을 닫고 장바구니에 항목을 추가하세요!",
    subscriptionExpired:
      "구독이 만료되어 계정이 제한됩니다. 계정을 갱신하려면 아래 버튼을 클릭하세요.",
    renew: "고쳐 쓰다",
    holdOn: "기다려",
    customerBank: "고객 은행",
    cancel: "취소",
    selectACustomer: "고객 선택",
    invoiceSuccessfulPdfError:
      "인보이스가 성공적으로 생성되었지만 PDF 생성이 평소보다 오래 걸립니다. 잠시 후 다시 확인해 주세요.",
    downloadingInvoice: "인보이스 다운로드",
    downloadingReceipt: "영수증 다운로드 중",
    whatsappReceiptError:
      "WhatsApp을 통해 영수증을 보내는 중에 오류가 발생했습니다. 다시 시도해 주세요.",
    receiptToWhatsapp: "WhatsApp으로 영수증 전달",
    thankYouForPatronage: "당신의 후원에 감사드립니다",
    hereIsYourReceipt: "결제 영수증입니다.",
    errorSendingEmailReceipt:
      "이메일로 영수증을 보내는 중에 오류가 발생했습니다. 다시 시도하거나 지원팀에 문의하세요.",
    receiptSentToEmail: "영수증이 고객의 이메일로 발송되었습니다",
    invoiceSentToEmail: "청구서가 고객의 이메일로 발송되었습니다",
    invoiceSuccessWhatsappError:
      "인보이스가 성공적으로 생성되었지만 WhatsApp으로 보내는 동안 오류가 발생했습니다. 인보이스 목록에서 다시 시도하십시오.",
    invoiceSuccessfulEmailError:
      "인보이스가 성공적으로 생성되었지만 이메일로 보내는 동안 오류가 발생했습니다. 인보이스 목록에서 다시 시도하십시오.",
    invoiceSentToWhatsapp: "WhatsApp으로 송장 전달",
    hello: "안녕하십니까",
    pleaseDownloadInvoice: "인보이스를 다운로드하십시오",
    pleaseDownloadReceipt: "영수증을 다운받아주세요",
    from: "~에서",
    email: "이메일",
    upgrade: "업그레이드",
    youAreOnFreePlan: "무료 요금제를 사용 중입니다.",
    clickOn: "클릭",
    yourPlanInFewSteps: " 몇 가지 빠른 단계를 통해 구독할 수 있습니다.",
    to: "에게",
    yourSubscriptionHasExpired: "구독이 만료되어 계정이 제한됩니다.",
    days: "날",
    yourSubscriptionExpiresIn: "귀하의 Loystar 구독은 다음 날짜에 만료됩니다.",
    createAcount: "계정 만들기",
    signIn: "로그인",
    continue: "계속하다",
    enterOtp: "OTP 비밀번호 입력",
    enterValidOtp: "유효한 PIN을 입력하세요.",
    pin: "핀",
    tokenSentToMail: "귀하의 이메일로 토큰이 전송되었습니다",
    passwordResetSuccessful: "비밀번호 재설정 성공",
    somethingWentWrong: "문제가 발생했습니다!",
    resetPassword: "암호를 재설정",
    iHaveResetCode: "비밀번호 재설정 코드가 있습니다",
    pleaseEnterEmail: "이메일을 입력하세요",
    aTokenWillBeSentToEmail: "귀하의 이메일로 토큰이 전송됩니다",
    enterEmail: "이메일을 입력하세요",
    sendinYourToken: "토큰을 보내는 중...",
    makeSureItMatchesPassword: "새 비밀번호와 일치하는지 확인하세요.",
    pleaseChooseNewPassword: "새 비밀번호를 선택하세요",
    chooseNewPassword: "새 비밀번호 선택",
    enterNewPassword: "확인을 위해 새 비밀번호를 입력하세요.",
    enterTokenSent: "메일을 보낸 토큰을 입력하세요.",
    resetToken: "토큰 재설정",
    resettingPassword: "비밀번호 재설정 중...",
    signUp: "가입하기",
    adminSignInWithEmail:
      " 관리자는 이메일로 로그인하고 직원은 사용자 이름으로 로그인합니다.",
    pleaseEnterEmailOrUsername: "이메일 또는 사용자 이름을 입력하세요.",
    emailOrUsername: "이메일 또는 사용자 이름",
    pleaseEnterPassword: "비밀번호를 입력 해주세요",
    createAnAccount: "계정 만들기",
    forgotPassword: "비밀번호를 잊으 셨나요?",
    enterPhoneNumber: "전화번호 입력",
    personalData: "개인 정보",
    validateConfirmationCOde: "확인 코드 확인",
    pleaseEnterFirstName: "이름을 입력하십시오",
    pleaseEnterPhoneNumber: "전화번호를 입력하세요",
    pleaseEnterLastName: "당신의 성을 입력하십시오",
    pleaseEnterBusinessName: "업체명을 입력해주세요",
    firstName: "이름",
    lastName: "성",
    businessName: "비즈니스 이름",
    previous: "이전의",
    next: "다음",
    pleaseSelectBusinessCategory: "비즈니스 카테고리를 선택하세요.",
    pleaseEnterValidEmail: "유효한 이메일을 입력하세요",
    pleaseEnterPostCode: "우편번호를 입력해주세요",
    postCode: "우편번호",
    phoneNumberInUse: "이 전화번호는 이미 사용 중입니다!",
    emailInUse: "이 이메일은 이미 사용 중입니다!",
    foodAndDrinks: "음식과 음료",
    salonAndBeauty: "살롱 및 미용",
    fashionAndAccessories: "패션 및 액세서리",
    gymAndFitness: "체육관 및 피트니스",
    travelAndHotel: "여행과 호텔",
    homeAndGifts: "집과 선물",
    washingAndCleaning: "세척 및 청소",
    gadgetsAndElectronics: "가제트 및 전자 제품",
    groceries: "식료 잡화류",
    others: "기타",
    submit: "제출하다",
    accountCreatedSuccessful: "귀하의 계정이 성공적으로 생성되었습니다.",
    pleaseEnterAddress: "주소를 입력하세요",
    addressLine1: "주소 라인 1",
    addressLine2: "주소 2",
    choosePassword: "암호를 선택",
    passwordMustBe6Characters: "비밀번호는 6자 이상이어야 합니다.",
    howDidYouHearLoystar: "Loystar에 대해 어떻게 알게 되셨나요?",
    referralCode: "추천 코드",
    byClickingTheButton: " 아래 버튼을 클릭하면 동의하는 것으로 간주됩니다.",
    termsAndSevice: "자귀",
    wereCreatingAccount: "계정을 만드는 중입니다.",
    proceed: "진행하다",
    verificationCodeMustBe6: "인증 코드는 6자리여야 합니다.",
    pleaseEnter6DigitCode: "6자리 코드를 입력하세요",
    enterVerificationCode: "인증 코드 입력",
    resendToken: "토큰 재전송",
    verify: "검증",
    transactions: "업무",
    todaySales: "오늘의 판매",
    salesHistory: "판매 내역",
    supplyHistory: "공급 내역",
    new: "새로운",
    invoices: "송장",
    disbursements: "지출",
    offlineSales: "오프라인 판매",
    products: "제품",
    customers: "고객",
    multiLevelLoyalty: "다단계 충성도",
    loyaltyPrograms: "로열티 프로그램",
    members: "회원",
    appStore: "앱 스토어",
    orderMenu: "주문 메뉴",
    settings: "설정",
    staffAndBranches: "직원 및 지점",
    myAccount: "내 계정",
    switchToSellMode: "판매 모드로 전환",
    signOut: "로그아웃",
    getFreeSubscription: "무료 구독 받기",
    onlyNumbersAllowed: "숫자만 허용됩니다.",
    yourAccountMustBe10Digits: "귀하의 계좌 번호는 10자리 숫자여야 합니다.",
    yourBvnMustBe11: "BVN은 11자리 숫자여야 합니다.",
    pleaseSelectBank: "은행을 선택하세요.",
    selectYourBank: "은행 선택",
    enterBankAccountNumber: "은행 계좌 번호 입력",
    enterBvnNumber: "귀하의 BVN을 입력하십시오",
    connectBankAccount: "은행 계좌 연결",
    passwordResetSuccessfulAndSignOut:
      "비밀번호가 성공적으로 재설정되었습니다. 다시 로그인하려면 아래의 로그아웃 버튼을 클릭하세요.",
    enterCurrentPassword: "현재 비밀번호를 입력하세요",
    pleaseEnterCurrentPassword: "현재 비밀번호를 입력하세요",
    phoneNumber: "전화 번호",
    sex: "섹스",
    dateOfBirth: "생일",
    state: "상태",
    country: "국가",
    loyaltyId: "로열티 ID",
    createdAt: "만든 시간",
    noOfTransactionsMade: "거래 건수",
    yourDownloadWillStart: "잠시 후 다운로드가 시작됩니다",
    exportCustomers: "수출 고객",
    youhaveSuccessfullyToppedUp: "SMS 장치를 성공적으로 충전했습니다.",
    youNowHave: "당신은 이제",
    smsUnits: "SMS 단위",
    enterNumberOfSmsUnits: "구매하려는 SMS 장치의 수를 입력하십시오.",
    pleaseEnterANumericValue: "숫자 값을 입력하세요.",
    pay: "지불",
    accountEditedSuccessfully: "귀하의 계정이 성공적으로 수정되었습니다.",
    youAeCurrentlyOn: "현재 사용 중입니다.",
    plan: "계획",
    userData: "사용자 데이터",
    businessData: "비즈니스 데이터",
    businessCategory: "비즈니스 카테고리",
    pleaseSelectCurrency: "통화를 선택하세요.",
    selectYourCurrency: "통화 선택",
    purchaseMoreSmsUnits:
      "아래 양식을 사용하여 더 많은 SMS 장치를 구입하십시오.",
    youHave: "당신은",
    atLeast4SmsUnitsRrequired:
      "인증을 위해 최소 4개의 SMS가 필요합니다. 충전해주세요!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'고객 알기'(KYC) 요구 사항을 준수하도록 은행 계좌를 확인하십시오. 이를 통해 USSD 또는 즉시 송금을 통해 지불금을 수금하고 고객으로부터 주문을 받고 거래 정산을 처리할 수 있습니다. 나이지리아 상인에게만 적용됩니다. 시작하려면 아래 버튼을 누르십시오.",
    reConnectBankAccount: "은행 계좌 다시 연결",
    accountName: "계정 이름",
    accountNumber: "계좌 번호",
    bankName: "은행 이름",
    verified: "확인됨",
    accountDetails: "계정 세부정보",
    kycBankAccount: "KYC",
    createTier: "계층 생성",
    fileUploadSuccessful: "파일이 성공적으로 업로드되었습니다",
    fileUploadFailed: "파일 업로드 실패",
    createLoyaltyProgram: "로열티 프로그램 만들기",
    createLoyalty: "충성도 만들기",
    name: "이름",
    loyaltyArtwork: "로열티 삽화",
    clickToUpload: "업로드하려면 클릭",
    amountToPointsRatio: "포인트 비율",
    points: "포인트들",
    recommendedAmountToPointRatio:
      "권장사항 : ₦1~1포인트. 즉, 1 Naira를 소비할 때마다 고객은 1포인트를 얻습니다.",
    pleaseTypeIn: "입력하십시오",
    toDeleteLoyalty: "이 충성도를 삭제하려면",
    deleteLoyalty: "로열티 삭제",
    toConfirm: "확인",
    edit: "편집하다",
    pointsAwardedSuccessfully: "포인트가 성공적으로 수여되었습니다.",
    enterPointValueToAward: "고객에게 제공할 포인트 값을 입력하세요.",
    award: "상",
    awardPointValuesToCustomer: "고객에게 포인트 가치 부여",
    enrollMembersToLoyalty: "로열티에 회원 등록",
    awardPoints: "보너스 포인트",
    enroll: "싸다",
    home: "집",
    loyalty: "충의",
    enrollCustomers: "고객 등록",
    selected: "선택된",
    customer: "고객",
    loyaltyActivationSuccessful: "로열티 활성화에 성공했습니다.",
    loyaltyDeactivationSuccessful: "로열티 비활성화에 성공했습니다.",
    viewTier: "계층 보기",
    deactivate: "비활성화",
    activate: "활성화",
    actions: "행위",
    nameOfLoyalty: "충성도의 이름",
    loyaltyStatus: "로열티 상태 로열티 상태",
    numberOfTiers: "계층 수",
    createdOn: "에 만든",
    createATier: "계층 만들기",
    stopCreatingTierConfirmation: "계층 생성을 중단하시겠습니까?",
    stopEditingTierConfirmation: "이 계층 편집을 중지하시겠습니까?",
    nameOfTier: "계층 이름",
    minimumSpendingTarget: "최소 지출 목표",
    maximumSpendingTarget: "최대 지출 목표",
    minimumSpendingTargetRequired: "최소 지출 목표가 필요합니다",
    maximumSpendingTargetRequired: "최대 지출 목표가 필요합니다",
    rewardSponsor: "리워드 스폰서",
    pleaseChooseARewardSponsor: "리워드 스폰서를 선택하세요.",
    self: "본인",
    partner: "파트너",
    rewardPartner: "보상 파트너",
    pleaseSelectRewardPartner: "보상 파트너를 선택하세요.",
    rewards: "보상",
    pleaseSelectAReward: "보상을 선택해주세요",
    instructionsOnRedeemingReward: "고객이 보상을 사용하는 방법에 대한 지침",
    pleaseFillInThisField: "이 필드를 채워주세요!",
    toDeleteThisTier: " 이 계층을 삭제하려면",
    deleteTier: "계층 삭제",
    viewMembers: "회원 보기",
    membersEnrolled: "등록된 회원",
    instruction: "지침",
    membersIn: "회원",
    availableTiersInLoyalty: "로열티 프로그램에서 사용 가능한 등급",
    tiers: "계층",
    totalTier: "총 계층",
    availableLoyaltyProgramme: "사용 가능한 로열티 프로그램",
    totalLoyalties: "총 충성도",
    memberDetails: "회원 정보",
    nameOfCustomer: "고객의 이름",
    address: "주소",
    allEnrolledMembers: "등록된 모든 회원",
    thisIsToWishYouHappyBirthday:
      "이것은 당신에게 매우 행복한 생일과 번영하는 삶을 기원하는 것입니다. Loyster를 이용해 주셔서 감사합니다. 축하합니다!",
    inputAnOfferPlease: "제안을 입력하십시오",
    pleaseSelectTheInsertPoint:
      "메시지에서 삽입 지점을 선택하고 다시 클릭하십시오.",
    birthdayOfferAndMessage: "생일 제안 및 메시지",
    birthdayOffer: "생일 제안",
    birthdayMessage: "생일 메시지",
    noOfferFound: "제안을 찾을 수 없습니다",
    settingABirthdayOffer:
      "생일 제안을 설정하면 고객이 생일에 SMS를 통해 이 제안을 받을 수 있습니다.",
    createOffer: "제안 생성",
    whatIsTheOffer: "제안은 무엇입니까?",
    editMessage: "메시지 수정",
    insert: "끼워 넣다",
    theNameOfCustomerInserted: "여기에 고객의 이름이 삽입됩니다.",
    theBirtdayOfferInserted: "생일 제안이 여기에 삽입됩니다.",
    youSuccessfullyAddedNewBranch: "새 분기를 성공적으로 추가했습니다!",
    addNewBranch: "새 지점 추가",
    addBranch: "지점 추가",
    additionalBranchWillIncur: "추가 분기 발생",
    perBranch: "지점당",
    ecommerceBranchCosts: "전자상거래 지점 비용",
    pleaseEnterBranchName: "지점명을 입력해주세요",
    pleaseEnterBranchAddress1: "지점 주소 입력란 1을 입력하세요.",
    enterBranchAddress1: "지점 주소 입력란 1",
    enterBranchAddress2: "지점 주소 입력란 1",
    pleaseEnterBranchAddress2: "지점 주소 입력란 2를 입력하세요.",
    enterBranchName: "지점명을 입력하세요",
    successfullyAddedStaff: "새 직원을 성공적으로 추가했습니다!",
    addNewStaff: "새 직원 추가",
    addStaff: "직원 추가",
    additionalStaffWillIncur: "추가 직원 발생",
    perStaff: "직원 당.",
    pleaseEnterStaffEmail: "직원 이메일을 입력하세요.",
    pleaseEnterStaffUsername: "직원의 사용자 이름을 입력하세요.",
    pleaseEnterStaffPassword: "직원의 비밀번호를 입력하세요.",
    pleaseSelectStaffRole: "직원의 역할을 선택하세요.",
    selectStaffRole: "직원의 역할 선택",
    enterStaffEmail: "직원의 이메일을 입력하세요",
    enterStaffUsername: "직원의 사용자 이름을 입력하십시오",
    enterStaffPassword: "직원의 비밀번호를 입력하세요",
    spacesNotAllowedInUsername: "사용자 이름에 공백을 사용할 수 없습니다.",
    admin: "관리자",
    pleaseSelectBusinessToAttachStaff: "직원을 첨부할 업종을 선택하세요.",
    searchForBranchToAttachStaff: "직원을 붙일 지사 검색",
    username: "사용자 이름",
    role: "역할",
    areYouSureToDeleteThis: "삭제하시겠습니까?",
    branches: "지점",
    upgradeYourPlan: "플랜을 업그레이드하십시오.",
    add: "추가하다",
    addNew: "새로운 걸 더하다",
    customerWithEmailAlreadyExists:
      "이메일/전화번호를 가진 고객이 이미 존재합니다!",
    successfullyAddedNewCustomer: "새 고객을 성공적으로 추가했습니다!",
    addCustomer: "고객 추가",
    pleaseEnterCustomerFirstName: "고객의 이름을 입력하십시오",
    pleaseEnterCustomerLastName: "고객의 성을 입력하세요.",
    pleaseEnterCustomerPhoneNumber: "고객의 전화번호를 입력하세요",
    pleaseEnterCustomerEmail: "고객의 이메일을 입력하세요",
    pleaseEnterCustomerAddressLine: "고객의 주소 입력란을 입력하세요.",
    pleaseEnterCustomerOtherAddress: "고객의 다른 주소를 입력하십시오",
    pleaseSelectCustomerGender: "고객의 성별을 선택하세요.",
    gender: "성별",
    male: "남성",
    female: "여자",
    bank: "은행",
    selectBank: "은행 선택",
    stateOrRegionOrProvince: "시/도/도",
    customerNotes: "고객 메모",
    sendSms: "문자를 보내다",
    editCustomer: "고객 수정",
    redeemReward: "보상 사용",
    issueLoyaltyCard: "로열티 카드 발급",
    deleteCustomer: "고객 삭제",
    youveSuccessfullyAssignedLoyaltyMembership:
      "로열티 멤버십 ID를 성공적으로 할당했습니다.",
    noMembershipIdAvailable:
      "사용 가능한 회원 ID가 없습니다. hello@loystar.co로 문의하십시오.",
    sendEmail: "이메일을 보내",
    membershipPoints: "멤버십 포인트",
    customerDetails: "고객의 세부 사항",
    close: "닫다",
    loyaltyBalance: "로열티 균형",
    pointsBalance: "포인트 잔액",
    starBalance: "스타 밸런스",
    requiredPoints: "필수 포인트",
    requiredStars: "필수 별",
    reddemCode: "코드를 사용",
    toDeleteThisCustomer: "이 고객을 삭제하려면",
    customerHasBeenDeletedSuccessfully: "고객이 성공적으로 삭제되었습니다!",
    customerWithPhoneAlreadyExists: "전화번호가 있는 고객이 이미 존재합니다.",
    customerWasSuccessfullyEdited: "고객이 성공적으로 수정되었습니다.",
    anErrorOccured: "오류가 발생했습니다",
    phoneNumberIncludeCountry: "전화번호(국가 코드 포함)",
    yourFileQueuedForUpload:
      "파일이 업로드 대기 중입니다. 몇 초 후에 페이지를 새로고침하세요.",
    thereWasAnErrorPerformingOperation:
      "작업을 수행하는 동안 오류가 발생했습니다!",
    pleaseSelectFile: "파일을 선택하십시오!",
    oopsOnlyCsvFilesAllowed:
      "앗! CSV 파일만 허용됩니다. .csv 파일을 업로드하세요.",
    fileShouldBeSmallerThan5Mb:
      "파일은 5MB보다 작아야 합니다. 더 큰 파일이 있는 경우 support@loystar.co로 이메일을 보내주십시오. 감사합니다",
    customerFirstNameIsRequired: "행에 고객 이름이 필요합니다.",
    customerPhoneNumberIsRequired: "행에 고객 전화번호가 필요합니다.",
    importCustomers: "수입 고객",
    upload: "업로드",
    clickIconToDownloadCsv:
      "CSV 파일 템플릿을 다운로드하려면 이 아이콘을 클릭하십시오.",
    getGoogleSheetVersion: "Google 시트 버전 가져오기",
    clickOrDragFileToUpload:
      "업로드하려면 이 영역으로 파일을 클릭하거나 드래그하세요.",
    missingOrInvalidColumnHeader:
      "열 헤더가 없거나 잘못되었습니다. 템플릿 형식을 따르십시오. 감사합니다.",
    youHaveImported: "당신은 수입했습니다",
    youSuccessfullyRedeemedReward: "보상을 성공적으로 사용했습니다!",
    sixDigitCode: "6자리 코드",
    pleaseEnterCustomerRewardCode: "고객의 보상 코드를 입력하십시오",
    enterRewardCode:
      "보상 코드를 입력하십시오.(보상 코드는 대소문자를 구분합니다.)",
    selectLoyaltyProgram: "로열티 프로그램 선택",
    stamps: "우표",
    smsUnitsLow: "SMS 단위 낮음",
    pleaseTopUpSmsUnits: "SMS 장치를 충전하십시오",
    smsSuccessfullySentForDelivery: "SMS가 성공적으로 전송되었습니다!",
    sendSmsTo: "SMS 보내기",
    allCustomers: "모든 고객",
    unitsAvailable: "사용 가능한 단위",
    pleaseTypeInTheMessage: "메시지를 입력하세요",
    message: "메세지",
    charactersRemaining: "남은 문자",
    avoidUseOfSpecialCharacters:
      "SMS 단위를 절약하기 위해 특수 문자와 이모티콘을 사용하지 마십시오.",
    note: "메모",
    errorFetchingCustomersMultilevelDetail:
      "고객의 다단계 세부 정보를 가져오는 동안 오류가 발생했습니다.",
    search: "찾다",
    reset: "초기화",
    importCustomer: "고객 가져오기",
    addNewCustomer: "새 고객 추가",
    sendSmsBroadcast: "SMS 브로드캐스트 보내기",
    totalCustomers: "총 고객",
    disbursementDetails: "지출내역",
    paidBy: "를 통한 결제",
    disbursed: "지출",
    bankAccountName: "은행 계정 이름",
    bankAccountNumber: "은행 계좌 번호",
    transferInitiated: "전송 시작됨",
    transferCompleted: "전송 완료",
    pleaseEnterAValid: "유효한 것을 입력하십시오",
    begin: "시작하다",
    end: "끝",
    date: "데이트",
    paymentDate: "결제일",
    selectDisbursementDuration: "지불 기간 선택",
    totalSettled: "총 결제",
    totalUnsettled: "총 불안",
    toDeleteThisSale: "이 판매를 삭제하려면",
    draftSaleDeletedSuccessfully: "임시 판매가 성공적으로 삭제되었습니다!",
    deleteSale: "판매 삭제",
    pleaseTypeInYourTotal: "삭제를 확인하려면 총계를 입력하세요.",
    billDetails: "청구서 세부정보",
    printBill: "청구서 인쇄",
    servedBy: "제공자",
    total: "총",
    createdDate: "만든 날짜",
    createdTime: "생성 시간",
    status: "상태",
    loginSuccessful: "성공적 로그인",
    pleaseWaitWhileWeConnectAccount: "계정을 연결하는 동안 기다려 주십시오.",
    connectionFailedTryAgain: "연결에 실패. 다시 시도해 주세요.",
    connectionSuccessful: "연결 성공",
    viewDetails: "세부 정보보기",
    enable: "할 수있게하다",
    free: "무료",
    cost: "비용",
    visitWebsite: "웹사이트 방문",
    pleaseUpgradeYourPlanToPro:
      "이 앱을 활성화하려면 요금제를 Pro Plus로 업그레이드하세요.",
    connectYourBankAccount: "지급금을 받으려면 은행 계좌를 연결하세요.",
    disable: "장애를 입히다",
    enableApp: "앱 활성화",
    addNewProductToInvoice: "송장에 새 제품 추가",
    toDeleteThisInvoice: "이 인보이스를 삭제하려면",
    invoiceDeletedSuccessfully: "인보이스가 성공적으로 삭제되었습니다!",
    deleteInvoice: "인보이스 삭제",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "인보이스 결제 메시지를 성공적으로 추가했습니다!",
    pleaseEnterPaymentInstructions: "결제 방법을 입력해주세요",
    invoiceId: "인보이스 ID",
    paidDate: "지급일",
    reference: "참조",
    productAdded: "추가된 제품",
    productOutOfStock: "품절된 제품입니다. 재입고 부탁드립니다.",
    totalInvoices: "총 송장",
    totalPaidInvoices: "총 지불 인보이스",
    totalUnpaidInvoices: "총 미결제 인보이스",
    loyaltyProgramDeleted: "로열티 프로그램 삭제됨",
    thisLoyaltyProgramDeletedSuccessfully:
      "이 로열티 프로그램은 성공적으로 삭제되었습니다.",
    thisLoyaltyProgramEditedSuccessfully:
      "이 로열티 프로그램이 성공적으로 수정되었습니다.",
    loyaltyProgramAddedSuccessfully:
      "로열티 프로그램이 성공적으로 추가되었습니다.",
    loyaltyProgramEdited: "로열티 프로그램 수정됨",
    loyaltyProgramAdded: "로열티 프로그램 추가됨",
    loyaltyDetails: "로열티 세부정보",
    doYouWantToCloseDialog: "이 대화 상자를 닫으시겠습니까?",
    pleaseEnterLoyaltyName: "충성도의 이름을 입력하세요.",
    programType: "프로그램 유형",
    pleaseSelectProgramType: "프로그램 유형을 선택하세요.",
    simplePoints: "단순 포인트",
    stampsProgram: "우표 프로그램",
    threshold: "한계점",
    pleaseEnterLoyaltyThreshold: "충성도 임계값을 입력하세요.",
    reward: "보상",
    pleaseEnterLoyaltyReward: "충성도의 보상을 입력하세요",
    totalUserPoints: "총 사용자 포인트",
    totalUserStamps: "총 사용자 스탬프",
    spendingTarget: "지출 목표",
    spendingTargetHint1:
      "지출 목표는 고객이 보상을 받기 위해 지출해야 하는 금액입니다. 1 통화 가치 = 1 포인트.",
    spendingTargetHint2:
      "스탬프 목표는 고객이 보상을 받기 위해 수집해야 하는 스탬프의 수입니다. 예를 들어 5",
    addNewLoyaltyProgram: "새 로열티 프로그램 추가",
    addLoyaltyProgram: "로열티 프로그램 추가",
    loyaltyProgramType: "로열티 프로그램 유형",
    pleaseSelectLoyaltyProgramType: "로열티 프로그램 유형을 선택하세요.",
    nameOfProgram: "프로그램 이름",
    pleaseEnterProgramName: "프로그램 이름을 입력하세요",
    whatIsTheReward: "보상은 무엇입니까?",
    egNextPurchaseIsFree: "예를 들어 다음 구매는 무료입니다",
    customerName: "고객 이름",
    guestCustomer: "게스트 고객",
    orderConfirmedSuccessfully: "주문이 성공적으로 확인되었습니다",
    orderCancelledSuccessfully: "주문이 성공적으로 취소되었습니다.",
    confirmOrder: "주문 확인",
    cancelOrder: "주문 취소",
    allOrders: "모든 주문",
    totalOrders: "총 주문",
    doYouWantToAcceptOrder: "이 주문을 수락하시겠습니까?",
    doYouWantToCancelOrder: "이 주문을 취소하시겠습니까?",
    orderDetails: "주문 세부정보",
    orderCreatedAt: "주문 생성 시간",
    supplier: "공급업체",
    productName: "상품명",
    quantity: "수량",
    unitPrice: "단가",
    receivedBy: "수신",
    reportFrom: "보고",
    totalSupplies: "총 공급",
    allRightsReserved: "판권 소유",
    toDeleteThisTransaction: "이 거래를 삭제하려면",
    transactionDeletedSuccessfully:
      "거래가 성공적으로 삭제되었습니다. 재고가 재고로 반환되었습니다.",
    deleteTransaction: "거래 삭제",
    transactionDetails: "상세 거래 내역",
    printReceipt: "영수증 인쇄",
    channel: "채널",
    discount: "할인",
    profit: "이익",
    discountedSales: "할인 판매",
    errorFetchingRecord: "레코드를 가져오는 동안 오류가 발생했습니다.",
    exportTransactions: "수출 거래",
    errorFetchingSalesRecord:
      "내보내기에 대한 판매 레코드를 가져오는 중 오류가 발생했습니다.",
    totalSellingPrice: "총 판매 가격",
    totalCostPrice: "총 비용",
    appliedDiscount: "적용된 할인",
    noOfItems: "항목 수",
    sales: "매상",
    export: "내 보내다",
    totalProfit: "총 이윤",
    totalBalanceInPeriod: "기간의 총 잔액",
    allTimeSales: "상시 판매",
    records: "기록",
    todaysSales: "오늘의 판매",
    transaction: "거래",
    youHaveExceededTotalNumberOfProducts:
      "계획에 허용된 총 제품 수를 초과했습니다. 더 높은 한도를 즐기려면 요금제를 업그레이드하세요.",
    youNeedToHaveLoyaltyProgram:
      "이 기능을 사용하려면 로열티 프로그램이 필요합니다!",
    price: "가격",
    category: "범주",
    stockTracking: "재고 추적",
    stockCount: "재고 수",
    taxed: "과세",
    originalPrice: "원래 가격",
    costPrice: "가격",
    unit: "단위",
    productImage: "제품 이미지",
    taxRate: "세율",
    taxType: "세금 유형",
    trackInventory: "재고 추적",
    variants: "변형",
    hasVariants: "변형 있음",
    importProduct: "수입품",
    exportProducts: "수출 제품",
    addNewProduct: "새 제품 추가",
    viewCategory: "카테고리 보기",
    viewSuppliers: "공급자 보기",
    receiveInventory: "재고 수령",
    printAllProductsTag: "모든 제품 태그 인쇄",
    deleteAll: "모두 삭제",
    totalProducts: "총 제품",
    youveSuccessfullyAddedANewCategory:
      "새 카테고리를 성공적으로 추가했습니다.",
    addNewCategory: "새 카테고리 추가",
    addCategory: "카테고리 추가",
    categoryName: "카테고리 이름",
    pleaseEnterCategoryName: "카테고리 이름을 입력하세요",
    stampsTarget: "우표 대상",
    sendInventory: "인벤토리 보내기",
    productDetails: "제품 세부 정보",
    youveSuccessfullyEditedThisCategory:
      "이 카테고리를 성공적으로 수정했습니다.",
    update: "업데이트",
    categoryList: "카테고리 목록",
    categories: "카테고리",
    enterQuantityToUpdate: "업데이트할 수량을 입력하세요.",
    inventorySentSuccessfully: "인벤토리가 성공적으로 전송되었습니다!",
    updateInventory: "인벤토리 업데이트",
    currentQuantity: "현재 수량",
    pleaseEnterQuantityToAdd: "추가할 수량을 입력하세요.",
    pleaseSelectABranch: "지점을 선택해 주세요",
    searchForBranch: "지점 검색",
    youCantSendMoreThanStock: "재고가 있는 것보다 더 많이 보낼 수 없습니다.",
    send: "보내다",
    pleaseEnterQuantityToSend: "보내실 수량을 입력해주세요",
    productNameIsRequiredOnRow: "행에 제품 이름이 필요합니다.",
    productCategoryIsRequiredOnRow: "제품 카테고리는 행에 필수 항목입니다.",
    productPriceIsRequiredOnRow: "제품 가격은 행에 필수 항목입니다.",
    productUnitIsRequiredOnRow: "행에 제품 단위가 필요합니다.",
    productQuantityIsRequiredOnRow: "행에 제품 수량이 필요합니다.",
    productVariantsRequireTracking: "제품 변형에는 추적이 필요합니다!",
    pleaseAddVariantClickButton: "추가 버튼을 클릭하여 변형을 추가하세요!",
    totalVariantsMoreThanSelectedQuantity:
      "총 변형이 선택한 제품 수량보다 많습니다. 변형 수량을 줄입니다.",
    productEditedSuccessfully: "제품이 성공적으로 수정되었습니다!",
    fileTooLargeLessThan4Mb:
      "파일 크기가 너무 큽니다! 파일 크기는 4MB 미만이어야 합니다.",
    suchVariantAlreadyExist: "이러한 변형이 이미 존재합니다.",
    addVariants: "변형 추가",
    editProduct: "제품 수정",
    pleaseEnterProductName: "제품명을 입력해주세요",
    pleaseEnterProductPrice: "제품 가격을 입력하세요",
    pleaseEnterProductOriginalPrice: "제품 원래 가격을 입력하십시오",
    productDescription: "제품 설명",
    selectProductCategory: "제품 카테고리 선택",
    pleaseSelectProductCategory: "제품 카테고리를 선택하세요",
    productCostPrice: "제품 원가",
    productSellingPrice: "제품 판매 가격",
    productOriginalPrice: "제품 원래 가격",
    maxFileSizeAllowedIs4Mb: "최대 허용되는 파일 크기는 4MB입니다.",
    productsWithPicturesArePublished:
      "사진이 있는 제품은 Discover에 게시되어 주문을 받습니다.",
    shouldThisProductBeTracked: "이 제품을 추적해야 합니까?",
    pleaseSelectStockUnit: "재고 단위를 선택하십시오",
    stockUnit: "재고 단위",
    bag: "가방",
    bottle: "병",
    bunch: "다발",
    can: "할 수있다",
    carton: "판지 상자",
    crate: "나무 상자",
    cup: "컵",
    dozen: "다스",
    gigabytes: "기가바이트",
    gram: "그램",
    kilogram: "킬로그램",
    litre: "리터",
    pack: "팩",
    pair: "쌍",
    pieces: "조각",
    plate: "그릇",
    tonne: "톤(MT)",
    uNIT: "단위",
    yard: "마당",
    pleaseEnterProductQuantity: "제품 수량을 입력하세요.",
    productQuantity: "제품 수량",
    isThisProductTaxed: "이 제품은 세금이 부과되나요?",
    selectTaxType: "세금 유형 선택",
    pleaseSelectTaxType: "세금 유형을 선택하세요.",
    progressive: "프로그레시브",
    proportional: "비례항",
    pleaseEnterProductTaxRate: "상품세율을 입력해주세요",
    doesProductHaveVariants: "이 제품에 변형이 있습니까?",
    type: "유형",
    value: "값",
    pleaseEnterVariantType: "변형 유형을 입력하세요.",
    pleaseEnterVariantValue: "변형 값을 입력하세요.",
    pleaseEnterVariantPrice: "변형 가격을 입력하세요.",
    pleaseEnterVariantQuantity: "변형 수량을 입력하세요.",
    productDeletedSuccessfully: "제품이 성공적으로 삭제되었습니다!",
    categoryDeletedSuccessfully: "카테고리가 성공적으로 삭제되었습니다!",
    toDeleteThisProduct: "이 제품을 삭제하려면",
    invalidProductQuantity: "잘못된 제품 수량",
    quantityAddedIsOverStock: "추가하는 수량은 재고가 없습니다.",
    itemInventoryNotTracked: "품목 재고가 추적되지 않습니다.",
    addBulkQuantity: "대량 수량 추가",
    enterBulkQuantity: "대량 수량 입력",
    pleaseEnterBulkQuantity: "대량 수량을 입력하세요.",
    youveSuccessfullyAddedANewProduct: "새 제품을 성공적으로 추가했습니다!",
    pleaseEnterProductSellingPrice: "제품 판매 가격을 입력하세요.",
    doYouWantToTrackProductStock: "제품 재고를 추적하시겠습니까?",
    sellingPrice: "판매 가격",
    setProductExpiryReminder: "제품 만료 알림 설정",
    productExpiryDate: "제품 만료일",
    startRemindingFrom: "부터 알림 시작",
    productSuppliesAddedSuccessfully: "제품 소모품을 성공적으로 추가했습니다.",
    addProductSupplies: "제품 공급 추가",
    pleaseSelectSupplier: "공급업체를 선택하십시오",
    nameOfProduct: "제품명",
    pleaseSelectProduct: "상품을 선택해주세요",
    productVariant: "제품 변형",
    pleaseSelectAVariant: "변형을 선택하십시오",
    pleaseEnterUnitPrice: "단가를 입력하십시오",
    businessBranch: "영업점",
    pleaseSelectBranch: "지점을 선택하세요",
    youveSuccessfullyAddedANewSupplier:
      "새 공급업체를 성공적으로 추가했습니다.",
    addSupplier: "공급자 추가",
    pleaseEnterSupplierEmail: "공급업체의 이메일을 입력하세요.",
    pleaseAddADescription: "설명을 추가하세요",
    anErrorOccuredProductsDeleted:
      "작업을 수행하는 동안 오류가 발생했습니다. 이 과정에서 일부 제품이 삭제되었을 수 있습니다.",
    bulkDelete: "일괄 삭제",
    youAreAboutToDelete: "삭제하려고 합니다.",
    product: "제품",
    isDueToRedeem: "상환 예정이다",
    aReward: "보상",
    pleaseSelectCustomerToReeemReward: "보상을 받을 고객을 선택하세요.",
    youHaveNoLoyaltyProgramRunning:
      "현재 실행 중인 로열티 프로그램이 없습니다.",
    customerHhasNoPointsInLoyaltyProgram:
      "고객은 이 로열티 프로그램에 포인트가 없습니다.",
    proceedWithPayment: "결제를 진행하시겠습니까?",
    youAreAboutToPayForTransactionUsingPoints:
      "포인트를 사용하여 거래를 결제하려고 합니다.",
    customerHas: "고객이 가지고 있는",
    worth: "가치",
    andIsNotSufficientToPayForTransaction:
      "이 거래에 대해 지불하기에 충분하지 않습니다. 다른 결제 수단을 사용하여 잔액을 추가하시겠습니까?",
    addCustomerLoyalty: "고객 충성도 추가",
    pleaseAddCustomerFirst: "먼저 고객을 추가하거나 선택하십시오.",
    pleaseWaitWhileWeGetReady: "준비하는 동안 기다려 주십시오.",
    lowStock: "낮은 재고",
    pleaseEnterAmountTendered: "입찰금액을 입력해주세요",
    areYouSureToBookSaleOffline: "이 할인을 오프라인으로 예약하시겠습니까?",
    saleWouldBeBookedAutomatically: "인터넷을 켜면 판매가 자동으로 예약됩니다.",
    offlineSalesBookingCancelled: "오프라인 판매 예약 취소",
    covid19Message:
      "COVID19: 확산을 막기 위해 비누로 손을 씻거나 소독하십시오. 항상 안전 유지",
    saleSuccessfullyRecorded: "판매가 성공적으로 기록되었습니다!",
    sendReceiptToEmail: "이메일로 영수증 보내기",
    sendThankYouSms: "감사의 SMS 보내기",
    sendShippingDetails: "배송 세부 정보 보내기",
    addLoyalty: "충성도 추가",
    searchCustomerNameOrNumber: "고객 이름 또는 번호 검색",
    clickTheSearchCustomerBox: "고객 상자 검색 및 카드 스캔을 클릭합니다.",
    enterProductPrice: "제품 가격 입력",
    enterPriceFor: "가격 입력",
    searchForProduct: "제품 검색",
    all: "모두",
    backToDashboard: "대시보드로 돌아가기",
    viewDraftSales: "초안 판매 보기",
    variantSelected: "선택한 변형",
    variant: "변종",
    thePreviousVariantSelectionNotAvailable:
      "가격을 기준으로 선택한 새 변형에는 이전 변형 선택을 사용할 수 없습니다. 선택 항목을 변경하십시오.",
    pleaseSupplyPositiveQuantityNumber: "추가 수량 번호를 입력하세요.",
    lowStockFor: "낮은 재고",
    clearVariants: "변형 지우기",
    pleaseEnterQuantity: "수량을 입력하세요",
    picture: "그림",
    redemptionToken: "상환 토큰",
    token: "토큰",
    totalSpent: "총 지출",
    confirmPayment: "결제 확인",
    hasPaymentBeenMade: "결제가 성공적으로 처리되었습니까?",
    enterTransactionReference: "결제한 거래 참조를 입력하세요.",
    pleaseSelectACustomer: "고객을 선택해주세요!",
    areYouSureToApplyDiscount: "할인을 적용하시겠습니까?",
    addYourBankAccountToEnableUssd:
      "uPay를 통한 즉시 USSD 송금을 활성화하려면 은행 계좌를 추가하세요.",
    totalAmountToPay: "지불할 총 금액",
    doYouWantToCancelTransaction: "이 거래를 취소하시겠습니까?",
    savePrintBill: "청구서 저장/인쇄",
    enterAmountCollectedForCustomer: "고객을 위해 수집된 금액 입력",
    recordSale: "음반 판매",
    checkOutWith: "체크 아웃",
    instantTransfer: "즉시 이체",
    dialTheUSSDCode: "USSD 코드를 다이얼하십시오",
    pleaseSelectABank: "은행을 선택해 주세요",
    payWithUSSD: "USSD로 지불",
    sendBillTo: " - 청구서 보내기",
    waitingForUSSDTransfer: "USSD 이전 대기 중",
    percent: "퍼센트",
    applyDiscount: "할인 적용",
    thisBillHasBeenSaved: "이 청구서는 저장되었습니다",
    saveDraft: "임시 보관함에 저장",
    pleaseTypeANameToIdentifyCustomer:
      "고객을 식별할 수 있도록 이름을 입력하세요.",
    paymentDetails: "지불 세부 사항",
    basePrice: "기본 가격",
    staff: "직원",
    subTotal: "소계",
    durationMonths: "기간(개월)",
    selectADuration: "기간 선택",
    oneMonth: "1 개월",
    twoMonths: "2 개월",
    threeMonths: "3 개월",
    sixMonths: "6 개월",
    twelveMonths: "12 개월",
    eighteenMonths: "18개월",
    twentyFourMonths: "24개월",
    twoMonthsFree: "(2개월 무료)",
    threeMonthsFree: "(3개월 무료)",
    fiveMonthsFree: "(5개월 무료)",
    yourAccountHasBeen: "귀하의 계정은",
    renewed: "갱신",
    upgraded: "업그레이드",
    successfully: "성공적으로",
    yourSubscription: "구독",
    youAreCurrentlyEnrolledOnA: "귀하는 현재 에 등록되어 있습니다.",
    pleaseChooseAPaymentOption: "지불 옵션을 선택하십시오",
    planRenewal: "플랜 갱신",
    planUpgrade: "플랜 업그레이드",
    pleaseSelectDurationToPayFor: "지불할 기간을 선택하십시오",
    staffAccounts: "직원 계정",
    ecommerce: "전자상거래",
    pleaseSelectAPlan: "플랜을 선택해 주세요",
    includeAddons: "추가 기능 포함",
    viewTransactions: "거래 보기",
    customerHasNoCompletedTansactions: "고객이 아직 완료된 거래가 없습니다.",
    branch: "나뭇 가지",
    enterNumberOfEcommerceBranches: "전자상거래 지점 수 입력",
    enterNumberOfEcommerceBranchesToPay:
      "지불하려는 전자 상거래 지점의 수를 입력하십시오.",
    ecommerceIntegration: "전자상거래 통합",
    enterNumberOfBranches: "지점 수 입력",
    enterNumberOfAdditionalBranchesToPay:
      "지불하려는 추가 지점의 수를 입력하십시오.",
    enterNumberOfStaffs: "직원 수 입력",
    enterNumberOfStaffsToPayFor: "지불할 직원 수 입력",
    discountApplies: "할인 적용",
    starsOnThe: "별",
    offer: "권하다",
    get: "얻다",
    moreStarsToRedeem: "사용할 더 많은 별",
    taxVat: "세금(VAT)",
    callCashierToCompletePayment: "계산원에게 전화하여 결제 완료",
    receipt: "영수증",
    dear: "~에게",
    thankYouForYourOrderFindGoods:
      "주문해주셔서 감사합니다. 동의한 대로 제공된 다음 상품을 찾으십시오.",
    shippingNote: "배송 참고",
    enterShippingNote: "배송 메모 입력",
    shippingAddress: "배송 주소",
    enterShippingAddress: "배송 주소 입력",
    wellDoneYouAreDueToRedeem: "잘 했어요! 당신은 상환해야합니다",
    toGetYourRewardNextVisit: "다음 방문 시 보상을 받으려면 감사합니다",
    pointsOnThe: "포인트",
    morePointsToRedeem: "사용할 더 많은 포인트",
    showCode: "코드 표시",
    toGetYourRewardOnNextVisit: "다음 방문 시 보상을 받으려면 감사합니다",
    earn: "벌다",
    delivaryNote: "배달 메모",
    draftSales: "초안 판매",
    startDate: "시작일",
    endDate: "종료일",
    orders: "명령",
    checkout: "점검",
    noProductItem: "제품 항목 없음",
    selectProductImage: "제품 이미지 선택",
    selectCountry: "국가 선택",
    selectRegion: "주/지역 선택",
    printProductTag: "제품 태그 인쇄",
    transactionReference: "거래 참조",
    Cashier: "출납원",
    Manager: "관리자",
    Owner: "소유자",
    Admin: "관리자",
    addPartners: "파트너 추가",
    addNewLoyaltyPartner: "새 로열티 파트너 추가",
    pleaseEnterCompanyName: "회사 이름을 입력하십시오",
    companyName: "회사 이름",
    pleaseEnterCompanyRepName: "회사 대표자 이름을 입력하십시오",
    companyRepName: "회사 대표자 이름",
    pleaseEnterCompanyRepEmail: "회사 대표 이메일을 입력하세요",
    companyRepEmail: "회사 담당자 이메일",
    pleaseEnterCompanyRepPhone: "회사 대표 전화번호를 입력하세요.",
    companyRepPhone: "회사 대표 전화번호",
    addReward: "보상 추가",
    pleaseEnterRewardName: "보상 이름을 입력하세요.",
    rewardName: "보상 이름",
    rewardQuantity: "보상 수량",
    rewardDescription: "보상 설명",
    rewardType: "보상 유형",
    pleaseEnterRewardType: "보상 유형을 입력하세요.",
    pleaseEnterRewardQuantity: "보상 수량을 입력하세요.",
    pleaseEnterRewardDescription: "보상 설명을 입력하세요",
    fineDining: "고급 레스토랑",
    luxuryFashion: "럭셔리 패션",
    hotels: "호텔",
    travel: "여행하다",
    foodAndBeverage: "음식 및 음료",
    fashion: "패션",
    health: "건강",
    furniture: "가구",
    entertainment: "오락",
    automobile: "자동차",
    education: "교육",
    beautyAndSpa: "뷰티 앤 스파",
    staycation: "스테이케이션",
    events: "이벤트",
    trips: "여행",
    oilAndGas: "석유 및 가스",
    laundry: "빨래",
    partnerCategory: "파트너 카테고리",
    freeItem: "무료 아이템",
  },
  Lao: {
    cashier: "ພະນັກງານເກັບເງິນ",
    manager: "ຜູ້​ຈັດ​ການ",
    owner: "ເຈົ້າຂອງ",
    online: "ອອນໄລນ",
    offline: "ອອບລາຍ",
    changePassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    currentPasswordMessage: "ກະລຸນາໃສ່ລະຫັດຜ່ານປັດຈຸບັນຂອງເຈົ້າ",
    passwordMessage: "ກະລຸນາໃສ່ລະຫັດຜ່ານຂອງທ່ານ",
    currentPassword: "ລະ​ຫັດ​ປັດ​ຈຸ​ບັນ",
    password: "ລະຫັດຜ່ານ",
    confirmPasswordMessage: "ກະລຸນາຢືນຢັນລະຫັດຜ່ານຂອງທ່ານ!",
    confirmPassword: "ຢືນ​ຢັນ​ລະ​ຫັດ",
    sendViaEmail: "ສົ່ງຜ່ານ Email",
    sendViaWhatsapp: "ສົ່ງຜ່ານ WhatsApp",
    downloadPdf: "ດາວໂຫລດ PDF",
    paid: "ຈ່າຍ",
    unpaid: "ບໍ່ໄດ້ຈ່າຍ",
    partial: "ບາງສ່ວນ",
    closeInvoice: "ເຈົ້າຕ້ອງການປິດໃບແຈ້ງ ໜີ້ ບໍ?",
    closeInvoiceConfirmation:
      "ໃບເກັບເງິນອາດຈະບໍ່ຖືກບັນທຶກໄວ້. ເຈົ້າຕ້ອງການປິດບໍ?",
    yes: "ແມ່ນແລ້ວ",
    no: "ບໍ່",
    invoice: "ໃບແຈ້ງ ໜີ້",
    wasDeducted: "ຖືກຫັກອອກ",
    for: "ສໍາລັບ",
    item: "ລາຍການ",
    addProduct: "ເພີ່ມຜະລິດຕະພັນ",
    paymentReference: "ການອ້າງອີງການຊໍາລະເງິນ",
    amountPaid: "ຈຳ ນວນທີ່ຈ່າຍແລ້ວ",
    discountAmount: "ຈຳ ນວນສ່ວນຫຼຸດ",
    amountDue: "ຈຳ ນວນທີ່ຮອດ ກຳ ນົດຈ່າຍ",
    amount: "ຈໍາ​ນວນ",
    dueDate: "ວັນ​ຄົບ​ກໍາ​ນົດ",
    paymentType: "ປະເພດການຊໍາລະເງິນ",
    card: "ບັດ",
    cash: "ເງິນສົດ",
    bankTransfer: "ໂອນ​ເງິນ​ຜ່ານ​ທະ​ນາ​ຄານ",
    paymentMessage: "ຂໍ້ຄວາມການຊໍາລະເງິນ",
    description: "ລາຍລະອຽດ",
    sendReceipt: "ສົ່ງໃບຮັບເງິນ",
    delete: "ລຶບ",
    save: "ບັນທຶກ",
    resend: "ສົ່ງຄືນໃ່",
    saveAndSend: "ບັນທຶກ",
    invoiceSaved: "ບັນທຶກໃບຮຽກເກັບເງິນແລ້ວ!",
    selectPaymentMethod: "ກະລຸນາເລືອກວິທີການຊໍາລະ!",
    selectCustomer: "ກະລຸນາເລືອກລູກຄ້າ!",
    cartEmptyError:
      "ລາຍຊື່ກະຕ່າບໍ່ສາມາດຫວ່າງເປົ່າໄດ້, ປິດໃບຮຽກເກັບເງິນແລະເພີ່ມລາຍການໃສ່ກະຕ່າ!",
    subscriptionExpired:
      "ການສະັກໃຊ້ຂອງທ່ານexpົດອາຍຸແລ້ວແລະດຽວນີ້ບັນຊີຂອງທ່ານຖືກຈໍາກັດ. ຄລິກທີ່ປຸ່ມດ້ານລຸ່ມເພື່ອຕໍ່ອາຍຸບັນຊີຂອງເຈົ້າ",
    renew: "ຕໍ່ອາຍຸ",
    holdOn: "ຖື​ໄວ້",
    customerBank: "ທະນາຄານລູກຄ້າ",
    cancel: "ຍົກເລີກ",
    selectACustomer: "ເລືອກລູກຄ້າ",
    invoiceSuccessfulPdfError:
      "ສ້າງໃບຮຽກເກັບເງິນ ສຳ ເລັດແລ້ວແຕ່ການສ້າງ PDF ໃຊ້ເວລາດົນກວ່າປົກກະຕິ. ກະລຸນາກັບມາເບິ່ງໃshortly່ໃນໄວ shortly ນີ້.",
    downloadingInvoice: "ກຳ ລັງດາວໂຫຼດໃບແຈ້ງ ໜີ້",
    downloadingReceipt: "ກຳ ລັງດາວໂຫຼດໃບຮັບເງິນ",
    whatsappReceiptError:
      "ເກີດຄວາມຜິດພາດຂຶ້ນໃນການສົ່ງໃບຮັບເງິນຜ່ານທາງ WhatsApp, ກະລຸນາລອງໃagain່ອີກຄັ້ງ.",
    receiptToWhatsapp: "ໃບຮັບເງິນຖືກສົ່ງຕໍ່ໄປຫາ WhatsApp",
    thankYouForPatronage: "ຂອບໃຈສໍາລັບການສະຫນັບສະຫນູນຂອງທ່ານ",
    hereIsYourReceipt: "ນີ້ແມ່ນໃບຮັບເງິນຂອງເຈົ້າ",
    errorSendingEmailReceipt:
      "ເກີດຄວາມຜິດພາດຂຶ້ນໃນການສົ່ງໃບຮັບເງິນຜ່ານທາງອີເມລ, ກະລຸນາລອງໃagain່ຫຼືຕິດຕໍ່ຫາsupport່າຍຊ່ວຍເຫຼືອ",
    receiptSentToEmail: "ໃບຮັບເງິນໄດ້ຖືກສົ່ງໄປທີ່ອີເມວຂອງລູກຄ້າ",
    invoiceSentToEmail: "ໃບເກັບເງິນໄດ້ຖືກສົ່ງໄປທີ່ອີເມວຂອງລູກຄ້າ",
    invoiceSuccessWhatsappError:
      "ສ້າງໃບແຈ້ງ ໜີ້ ສຳ ເລັດແລ້ວແຕ່ເກີດຄວາມຜິດພາດຂຶ້ນໃນການສົ່ງຫາ WhatsApp. ລອງອີກຄັ້ງໃນລາຍການໃບຮຽກເກັບເງິນ",
    invoiceSuccessfulEmailError:
      "ສ້າງໃບແຈ້ງ ໜີ້ ສຳ ເລັດແລ້ວແຕ່ເກີດຄວາມຜິດພາດຂຶ້ນໃນຂະນະທີ່ສົ່ງເປັນອີເມລ. ກະລຸນາລອງໃfrom່ຈາກລາຍການໃບຮຽກເກັບເງິນ",
    invoiceSentToWhatsapp: "ສົ່ງໃບແຈ້ງ ໜີ້ ໄປຫາ WhatsApp ແລ້ວ",
    hello: "ສະບາຍດີ",
    pleaseDownloadInvoice: "ກະລຸນາດາວໂຫລດໃບແຈ້ງ ໜີ້",
    pleaseDownloadReceipt: "ກະລຸນາດາວໂຫຼດໃບຮັບເງິນ",
    from: "ຈາກ",
    email: "ອີເມລ",
    upgrade: "ຍົກລະດັບ",
    youAreOnFreePlan: "ເຈົ້າຢູ່ໃນແຜນການບໍ່ເສຍຄ່າ.",
    clickOn: "ຄລິກໃສ່",
    yourPlanInFewSteps: " ການສະັກໃຊ້ຂອງທ່ານໃນສອງສາມຂັ້ນຕອນໄວ.",
    to: "ເຖິງ",
    yourSubscriptionHasExpired:
      "ການສະັກໃຊ້ຂອງທ່ານexpົດອາຍຸແລ້ວແລະດຽວນີ້ບັນຊີຂອງທ່ານຖືກຈໍາກັດ.",
    days: "ມື້",
    yourSubscriptionExpiresIn: "ການສະLັກໃຊ້ Loystar ຂອງທ່ານiresົດອາຍຸໃນ",
    createAcount: "ສ້າງ​ບັນ​ຊີ",
    signIn: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    continue: "ສືບຕໍ່",
    enterOtp: "ໃສ່ລະຫັດ PIN OTP",
    enterValidOtp: "ກະລຸນາໃສ່ PIN ທີ່ຖືກຕ້ອງ",
    pin: "PIN",
    tokenSentToMail: "ໂທເຄັນໄດ້ຖືກສົ່ງໄປຫາອີເມລ your ຂອງທ່ານ",
    passwordResetSuccessful: "ການຣີເຊັດລະຫັດຜ່ານ ສຳ ເລັດແລ້ວ",
    somethingWentWrong: "ມີບາງອັນຜິດພາດ!",
    resetPassword: "ຣີເຊັດລະຫັດຜ່ານ",
    iHaveResetCode: "ຂ້ອຍມີລະຫັດປ່ຽນລະຫັດຜ່ານ",
    pleaseEnterEmail: "ກະລຸນາໃສ່ອີເມລຂອງທ່ານ",
    aTokenWillBeSentToEmail: "ໂທເຄັນຈະຖືກສົ່ງໄປຫາອີເມວຂອງເຈົ້າ",
    enterEmail: "ກະລຸນາໃສ່ອີເມລຂອງທ່ານ",
    sendinYourToken: "ກຳ ລັງສົ່ງໂທເຄັນຂອງເຈົ້າ ...",
    makeSureItMatchesPassword: "ກວດໃຫ້ແນ່ໃຈວ່າມັນກົງກັບລະຫັດຜ່ານໃyour່ຂອງເຈົ້າ",
    pleaseChooseNewPassword: "ກະລຸນາເລືອກລະຫັດຜ່ານໃ່",
    chooseNewPassword: "ເລືອກລະຫັດຜ່ານໃ່",
    enterNewPassword: "ປ້ອນລະຫັດຜ່ານໃyour່ຂອງເຈົ້າເພື່ອຢືນຢັນ",
    enterTokenSent: "ໃສ່ໂທເຄັນທີ່ໄດ້ສົ່ງຈົດyourາຍຂອງເຈົ້າ",
    resetToken: "ຕັ້ງຄ່າ Token ຄືນໃ່",
    resettingPassword: "ກຳ ລັງຣີເຊັດລະຫັດຜ່ານຂອງທ່ານ ...",
    signUp: "ລົງ​ທະ​ບຽນ",
    adminSignInWithEmail:
      " ຜູ້ເບິ່ງແຍງເຂົ້າສູ່ລະບົບດ້ວຍອີເມວໃນຂະນະທີ່ພະນັກງານເຂົ້າສູ່ລະບົບດ້ວຍຊື່ຜູ້ໃຊ້.",
    pleaseEnterEmailOrUsername: "ກະລຸນາໃສ່ອີເມລ or ຫຼືຊື່ຜູ້ໃຊ້ຂອງທ່ານ",
    emailOrUsername: "ອີ​ເມລ​ຫຼື​ຊື່​ຜູ້​ໃຊ້",
    pleaseEnterPassword: "ກະລຸນາໃສ່ລະຫັດຜ່ານ",
    createAnAccount: "ສ້າງ​ບັນ​ຊີ",
    forgotPassword: "ລືມລະຫັດຜ່ານ?",
    enterPhoneNumber: "ໃສ່ເບີໂທລະສັບ",
    personalData: "ຂໍ້​ມູນ​ສ່ວນ​ຕົວ",
    validateConfirmationCOde: "ກວດສອບລະຫັດຢືນຢັນ",
    pleaseEnterFirstName: "ກະລຸນາໃສ່ຊື່ຂອງເຈົ້າ",
    pleaseEnterPhoneNumber: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງເຈົ້າ",
    pleaseEnterLastName: "ກະລຸນາໃສ່ນາມສະກຸນຂອງເຈົ້າ",
    pleaseEnterBusinessName: "ກະລຸນາໃສ່ຊື່ທຸລະກິດຂອງເຈົ້າ",
    firstName: "ຊື່​ແທ້",
    lastName: "ນາມ​ສະ​ກຸນ",
    businessName: "ຊື່​ທຸ​ລະ​ກິດ",
    previous: "ກ່ອນ ໜ້າ ນີ້",
    next: "ຕໍ່ໄປ",
    pleaseSelectBusinessCategory: "ກະລຸນາເລືອກປະເພດທຸລະກິດຂອງເຈົ້າ",
    pleaseEnterValidEmail: "ກະລຸນາໃສ່ອີເມລ valid ທີ່ຖືກຕ້ອງ",
    pleaseEnterPostCode: "ກະລຸນາໃສ່ລະຫັດໄປສະນີ",
    postCode: "ລະຫັດໄປສະນີ",
    phoneNumberInUse: "ເບີໂທລະສັບນີ້ຖືກໃຊ້ຢູ່ແລ້ວ!",
    emailInUse: "ອີເມວນີ້ຖືກໃຊ້ຢູ່ແລ້ວ!",
    foodAndDrinks: "ອາຫານແລະເຄື່ອງດື່ມ",
    salonAndBeauty: "Salon ແລະຄວາມງາມ",
    fashionAndAccessories: "ແຟຊັ່ນແລະອຸປະກອນເສີມ",
    gymAndFitness: "ການອອກ ກຳ ລັງກາຍແລະການອອກ ກຳ ລັງກາຍ",
    travelAndHotel: "ການທ່ອງທ່ຽວແລະໂຮງແຮມ",
    homeAndGifts: "ເຮືອນແລະຂອງຂວັນ",
    washingAndCleaning: "ຊັກແລະທໍາຄວາມສະອາດ",
    gadgetsAndElectronics: "ແກດເຈັດແລະເຄື່ອງໃຊ້ໄຟຟ້າ",
    groceries: "ຮ້ານຂາຍເຄື່ອງຍ່ອຍ",
    others: "ອື່ນ",
    submit: "ສົ່ງ",
    accountCreatedSuccessful: "ສ້າງບັນຊີຂອງທ່ານ ສຳ ເລັດແລ້ວ.",
    pleaseEnterAddress: "ກະລຸນາໃສ່ທີ່ຢູ່ຂອງເຈົ້າ",
    addressLine1: "ທີ່ຢູ່ແຖວທີ 1",
    addressLine2: "ທີ່ຢູ່ແຖວ 2",
    choosePassword: "ເລືອກລະຫັດຜ່ານ",
    passwordMustBe6Characters: "ລະຫັດຜ່ານຕ້ອງມີຕົວອັກສອນຢ່າງ ໜ້ອຍ 6 ຕົວ.",
    howDidYouHearLoystar: "ເຈົ້າໄດ້ຍິນແນວໃດກ່ຽວກັບ Loystar?",
    referralCode: "ລະຫັດການສົ່ງຕໍ່",
    byClickingTheButton: " ໂດຍການຄລິກປຸ່ມຢູ່ລຸ່ມນີ້, ທ່ານເຫັນດີກັບ",
    termsAndSevice: "ເງື່ອນໄຂ",
    wereCreatingAccount: "ພວກເຮົາກໍາລັງສ້າງບັນຊີຂອງເຈົ້າ",
    proceed: "ດໍາເນີນການ",
    verificationCodeMustBe6: "ລະຫັດຢືນຢັນຈະຕ້ອງເປັນ 6 ຕົວເລກ",
    pleaseEnter6DigitCode: "ກະລຸນາໃສ່ລະຫັດ 6 ຕົວເລກ",
    enterVerificationCode: "ໃສ່ລະຫັດຢືນຢັນ",
    resendToken: "ສົ່ງ Token ຄືນໃ່",
    verify: "ຢັ້ງຢືນ",
    transactions: "ທຸລະກໍາ",
    todaySales: "ການຂາຍມື້ນີ້",
    salesHistory: "ປະຫວັດການຂາຍ",
    supplyHistory: "ປະຫວັດການສະ ໜອງ",
    new: "ໃຫມ່",
    invoices: "ໃບແຈ້ງ ໜີ້",
    disbursements: "ການເບີກຈ່າຍເງິນ",
    offlineSales: "ການຂາຍ Offline",
    products: "ຜະລິດຕະພັນ",
    customers: "ລູກຄ້າ",
    multiLevelLoyalty: "ຄວາມຈົງຮັກພັກດີຫຼາຍລະດັບ",
    loyaltyPrograms: "ໂຄງການຄວາມພັກດີ",
    members: "ສະມາຊິກ",
    appStore: "App Store",
    orderMenu: "ເມນູຄໍາສັ່ງ",
    settings: "ການຕັ້ງຄ່າ",
    staffAndBranches: "ພະນັກງານແລະສາຂາ",
    myAccount: "ບັນ​ຊີ​ຂອງ​ຂ້ອຍ",
    switchToSellMode: "ປ່ຽນເປັນໂSellດການຂາຍ",
    signOut: "ອອກ​ຈາກ​ລະ​ບົບ",
    getFreeSubscription: "ໄດ້ຮັບການສະຫມັກຟຣີ",
    onlyNumbersAllowed: "ອະນຸຍາດໃຫ້ໃຊ້ຕົວເລກເທົ່ານັ້ນ",
    yourAccountMustBe10Digits: "accountາຍເລກບັນຊີຂອງເຈົ້າຕ້ອງເປັນຕົວເລກ 10 ຕົວ",
    yourBvnMustBe11: "BVN ຂອງເຈົ້າຕ້ອງເປັນຕົວເລກ 11 ຕົວ",
    pleaseSelectBank: "ກະລຸນາເລືອກທະນາຄານຂອງທ່ານ",
    selectYourBank: "ເລືອກທະນາຄານຂອງທ່ານ",
    enterBankAccountNumber: "ໃສ່ເລກບັນຊີທະນາຄານ",
    enterBvnNumber: "ໃສ່ BVN ຂອງເຈົ້າ",
    connectBankAccount: "ເຊື່ອມຕໍ່ບັນຊີທະນາຄານ",
    passwordResetSuccessfulAndSignOut:
      "ລະຫັດຜ່ານຂອງທ່ານໄດ້ຖືກຕັ້ງໃsu່ສໍາເລັດ. ຄລິກໃສ່ປຸ່ມອອກຈາກລະບົບຂ້າງລຸ່ມເພື່ອເຂົ້າສູ່ລະບົບອີກຄັ້ງ",
    enterCurrentPassword: "ໃສ່ລະຫັດຜ່ານປັດຈຸບັນ",
    pleaseEnterCurrentPassword: "ກະລຸນາໃສ່ລະຫັດຜ່ານປັດຈຸບັນ",
    phoneNumber: "ເບີ​ໂທລະ​ສັບ",
    sex: "ເພດ",
    dateOfBirth: "ວັນ​ເດືອນ​ປີ​ເກີດ",
    state: "ລັດ",
    country: "ປະເທດ",
    loyaltyId: "ບັດສະມາຊິກ",
    createdAt: "ສ້າງຢູ່ທີ່",
    noOfTransactionsMade: "ຈຳ ນວນທຸລະ ກຳ ທີ່ເຮັດແລ້ວ",
    yourDownloadWillStart: "ການດາວໂຫຼດຂອງເຈົ້າຈະເລີ່ມຂຶ້ນໃນໄວ a ນີ້",
    exportCustomers: "ສົ່ງອອກລູກຄ້າ",
    youhaveSuccessfullyToppedUp:
      "ເຈົ້າໄດ້ຕື່ມເງິນໃສ່ ໜ່ວຍ SMS ຂອງເຈົ້າ ສຳ ເລັດແລ້ວ.",
    youNowHave: "ດຽວນີ້ເຈົ້າມີ",
    smsUnits: "ໜ່ວຍ SMS",
    enterNumberOfSmsUnits: "ໃສ່ ຈຳ ນວນຫົວ ໜ່ວຍ SMS ທີ່ເຈົ້າຕັ້ງໃຈຈະຊື້",
    pleaseEnterANumericValue: "ກະລຸນາປ້ອນຄ່າໂຕເລກ",
    pay: "ຈ່າຍ",
    accountEditedSuccessfully: "ບັນຊີຂອງທ່ານຖືກແກ້ໄຂ ສຳ ເລັດແລ້ວ.",
    youAeCurrentlyOn: "ຕອນນີ້ທ່ານເປີດໃຊ້ຢູ່",
    plan: "ແຜນການ",
    userData: "ຂໍ້ມູນຜູ້ໃຊ້",
    businessData: "ຂໍ້ມູນທຸລະກິດ",
    businessCategory: "Catergory ທຸລະກິດ",
    pleaseSelectCurrency: "ກະລຸນາເລືອກສະກຸນເງິນຂອງທ່ານ",
    selectYourCurrency: "ເລືອກສະກຸນເງິນຂອງທ່ານ",
    purchaseMoreSmsUnits: "ຊື້ ໜ່ວຍ SMS ເພີ່ມເຕີມໂດຍໃຊ້ແບບຟອມລຸ່ມນີ້",
    youHave: "ເຈົ້າ​ມີ",
    atLeast4SmsUnitsRrequired:
      "ຕ້ອງມີຢ່າງ ໜ້ອຍ 4 ໜ່ວຍ SMS ເພື່ອກວດສອບ, ກະລຸນາຕື່ມເງິນໃສ່!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "ກະລຸນາກວດສອບບັນຊີທະນາຄານຂອງເຈົ້າເພື່ອປະຕິບັດຕາມຂໍ້ ກຳ ນົດ 'ຮູ້ລູກຄ້າຂອງເຈົ້າ' (KYC). ອັນນີ້ຈະອະນຸຍາດໃຫ້ເຈົ້າເກັບເງິນຜ່ານ USSD ຫຼືໂອນເງິນທັນທີ, ຮັບຄໍາສັ່ງຈາກລູກຄ້າແລະດໍາເນີນການຊໍາລະການດໍາເນີນການ. ໃຊ້ໄດ້ກັບພໍ່ຄ້າຊາວໄນຈີເຣຍເທົ່ານັ້ນ. ກະລຸນາກົດປຸ່ມຂ້າງລຸ່ມເພື່ອເລີ່ມຕົ້ນ.",
    reConnectBankAccount: "ເຊື່ອມຕໍ່ບັນຊີທະນາຄານຄືນໃ່",
    accountName: "ຊື່​ບັນ​ຊີ",
    accountNumber: "ເລກບັນຊີ",
    bankName: "ຊື່ທະນາຄານ",
    verified: "ຢັ້ງຢືນແລ້ວ",
    accountDetails: "ລາຍລະອຽດບັນຊີ",
    kycBankAccount: "KYC",
    createTier: "ສ້າງ Tier",
    fileUploadSuccessful: "ອັບໂຫຼດໄຟລ ສຳ ເລັດແລ້ວ",
    fileUploadFailed: "ອັບໂຫຼດໄຟລບໍ່ ສຳ ເລັດ",
    createLoyaltyProgram: "ສ້າງໂຄງການຄວາມຈົງຮັກພັກດີ",
    createLoyalty: "ສ້າງຄວາມຈົງຮັກພັກດີ",
    name: "ຊື່",
    loyaltyArtwork: "ສິນລະປະຄວາມພັກດີ",
    clickToUpload: "ຄລິກເພື່ອອັບໂຫລດ",
    amountToPointsRatio: "ຈຳ ນວນຕໍ່ອັດຕາສ່ວນຄະແນນ",
    points: "ຈຸດ",
    recommendedAmountToPointRatio:
      "ແນະນໍາ: ₦ 1 ຫາ 1 ຄະແນນ. ຕົວຢ່າງ: ສໍາລັບທຸກ Nai 1 Naira ທີ່ໃຊ້, ລູກຄ້າຂອງເຈົ້າຈະໄດ້ຮັບ 1 ຄະແນນ",
    pleaseTypeIn: "ກະລຸນາພິມ",
    toDeleteLoyalty: "ເພື່ອລຶບຄວາມຈົງຮັກພັກດີນີ້ອອກ",
    deleteLoyalty: "ລຶບຄວາມຈົງຮັກພັກດີ",
    toConfirm: "ເພື່ອຢືນຢັນ",
    edit: "ແກ້ໄຂ",
    pointsAwardedSuccessfully: "ໄດ້ຮັບຄະແນນ ສຳ ເລັດແລ້ວ.",
    enterPointValueToAward: "ໃສ່ມູນຄ່າຈຸດທີ່ເຈົ້າຢາກໃຫ້ລາງວັນແກ່ລູກຄ້າ",
    award: "ລາງວັນ",
    awardPointValuesToCustomer: "ລາງວັນຈຸດມູນຄ່າໃຫ້ກັບລູກຄ້າ",
    enrollMembersToLoyalty: "ລົງທະບຽນສະມາຊິກເຂົ້າໃນຄວາມຈົງຮັກພັກດີ",
    awardPoints: "ຄະແນນລາງວັນ",
    enroll: "ລົງທະບຽນ",
    home: "ບ້ານ",
    loyalty: "ຄວາມຈົງຮັກພັກດີ",
    enrollCustomers: "ລົງທະບຽນລູກຄ້າ",
    selected: "ເລືອກແລ້ວ",
    customer: "ລູກຄ້າ",
    loyaltyActivationSuccessful:
      "ການເປີດ ນຳ ໃຊ້ຄວາມຈົງຮັກພັກດີປະສົບຜົນສໍາເລັດ.",
    loyaltyDeactivationSuccessful:
      "ການປິດ ນຳ ໃຊ້ຄວາມຈົງຮັກພັກດີປະສົບຜົນ ສຳ ເລັດ.",
    viewTier: "ເບິ່ງຂັ້ນ",
    deactivate: "ເຊົາໃຊ້",
    activate: "ເປີດ ນຳ ໃຊ້",
    actions: "ການກະທໍາ",
    nameOfLoyalty: "ຊື່ຂອງຄວາມຈົງຮັກພັກດີ",
    loyaltyStatus: "ສະຖານະພາບຄວາມຈົງຮັກພັກດີສະຖານະພາບຄວາມຈົງຮັກພັກດີ",
    numberOfTiers: "ຈຳ ນວນຂັ້ນ",
    createdOn: "ສ້າງເມື່ອ",
    createATier: "ສ້າງ Tier",
    stopCreatingTierConfirmation: "ເຈົ້າຕ້ອງການຢຸດການສ້າງຊັ້ນບໍ?",
    stopEditingTierConfirmation: "ເຈົ້າຕ້ອງການຢຸດການແກ້ໄຂຊັ້ນນີ້ບໍ?",
    nameOfTier: "ຊື່ຂອງ Tier",
    minimumSpendingTarget: "ເປົ້າndingາຍການໃຊ້ຈ່າຍຂັ້ນຕ່ ຳ",
    maximumSpendingTarget: "ເປົ້າndingາຍການໃຊ້ຈ່າຍສູງສຸດ",
    minimumSpendingTargetRequired:
      "ເປົ້າspendingາຍການໃຊ້ຈ່າຍຂັ້ນຕ່ ຳ ແມ່ນຕ້ອງການ",
    maximumSpendingTargetRequired: "ຕ້ອງການເປົ້າspendingາຍການໃຊ້ຈ່າຍສູງສຸດ",
    rewardSponsor: "ຜູ້ສະ ໜັບ ສະ ໜູນ ລາງວັນ",
    pleaseChooseARewardSponsor: "ກະລຸນາເລືອກຜູ້ສະ ໜັບ ສະ ໜູນ ລາງວັນ",
    self: "ຕົນເອງ",
    partner: "ຄູ່ຮ່ວມງານ",
    rewardPartner: "ຮຸ້ນສ່ວນລາງວັນ",
    pleaseSelectRewardPartner: "ກະລຸນາເລືອກຮຸ້ນສ່ວນລາງວັນຂອງທ່ານ",
    rewards: "ລາງວັນ",
    pleaseSelectAReward: "ກະລຸນາເລືອກລາງວັນ",
    instructionsOnRedeemingReward: "ຄໍາແນະນໍາວິທີການລູກຄ້າຄວນແລກຂອງລາງວັນ",
    pleaseFillInThisField: "ກະລຸນາຕື່ມຂໍ້ມູນໃສ່ບ່ອນນີ້!",
    toDeleteThisTier: " ເພື່ອລຶບຊັ້ນນີ້",
    deleteTier: "ລຶບຊັ້ນ",
    viewMembers: "ເບິ່ງສະມາຊິກ",
    membersEnrolled: "ສະມາຊິກໄດ້ລົງທະບຽນ",
    instruction: "ຄໍາແນະນໍາ",
    membersIn: "ສະມາຊິກໃນ",
    availableTiersInLoyalty: "ຊັ້ນທີ່ມີຢູ່ໃນໂຄງການຄວາມຈົງຮັກພັກດີ",
    tiers: "ຊັ້ນ",
    totalTier: "ລະດັບທັງົດ",
    availableLoyaltyProgramme: "ໂຄງການຄວາມພັກດີທີ່ມີຢູ່",
    totalLoyalties: "ຄວາມສັດຊື່ທັງົດ",
    memberDetails: "ລາຍລະອຽດສະມາຊິກ",
    nameOfCustomer: "ຊື່ຂອງລູກຄ້າ",
    address: "ທີ່ຢູ່",
    allEnrolledMembers: "ສະມາຊິກທີ່ລົງທະບຽນທັງົດ",
    thisIsToWishYouHappyBirthday:
      "ນີ້ແມ່ນເພື່ອອວຍພອນໃຫ້ເຈົ້າມີຄວາມສຸກຫຼາຍວັນເກີດແລະຊີວິດຈະເລີນຮຸ່ງເຮືອງ. ຂອບໃຈສໍາລັບທຸກສິ່ງທີ່ເຈົ້າມີຕໍ່ Loyster. ສະເຫຼີມສະຫຼອງດີໃຈ!",
    inputAnOfferPlease: "ກະລຸນາປ້ອນຂໍ້ສະ ເໜີ",
    pleaseSelectTheInsertPoint: "ກະລຸນາເລືອກຈຸດໃສ່ໃນຂໍ້ຄວາມແລະຄລິກອີກຄັ້ງ",
    birthdayOfferAndMessage: "ຂໍ້ສະ ເໜີ ແລະຂໍ້ຄວາມວັນເກີດ",
    birthdayOffer: "ຂໍ້ສະ ເໜີ ວັນເກີດ",
    birthdayMessage: "ຂໍ້ຄວາມວັນເດືອນປີເກີດ",
    noOfferFound: "ບໍ່ພົບຂໍ້ສະ ເໜີ",
    settingABirthdayOffer:
      "ການຕັ້ງຂໍ້ສະ ເໜີ ວັນເກີດຊ່ວຍໃຫ້ລູກຄ້າໄດ້ຮັບຂໍ້ສະ ເໜີ ນີ້ຜ່ານທາງ SMS ໃນວັນເກີດຂອງເຂົາເຈົ້າ",
    createOffer: "ສ້າງຂໍ້ສະ ເໜີ",
    whatIsTheOffer: "ຂໍ້ສະ ເໜີ ແມ່ນຫຍັງ?",
    editMessage: "ແກ້ໄຂຂໍ້ຄວາມ",
    insert: "ໃສ່",
    theNameOfCustomerInserted: "ຊື່ຂອງລູກຄ້າຈະຖືກແຊກໃສ່ທີ່ນີ້",
    theBirtdayOfferInserted: "ຂໍ້ສະ ເໜີ ວັນເກີດຈະຖືກໃສ່ໃສ່ບ່ອນນີ້",
    youSuccessfullyAddedNewBranch:
      "ທ່ານໄດ້ເພີ່ມສາຂາໃsuccessfully່ ສຳ ເລັດແລ້ວ!",
    addNewBranch: "ເພີ່ມສາຂາໃ່",
    addBranch: "ເພີ່ມສາຂາ",
    additionalBranchWillIncur: "ສາຂາເພີ່ມເຕີມຈະເກີດຂຶ້ນ",
    perBranch: "ຕໍ່ສາຂາ",
    ecommerceBranchCosts: "ຄ່າໃຊ້ຈ່າຍຂອງສາຂາອີຄອມເມີຊ",
    pleaseEnterBranchName: "ກະລຸນາໃສ່ຊື່ສາຂາ",
    pleaseEnterBranchAddress1: "ກະລຸນາໃສ່ແຖວທີ່ຢູ່ຂອງສາຂາ 1",
    enterBranchAddress1: "ໃສ່ແຖວທີ່ຢູ່ຂອງສາຂາ 1",
    enterBranchAddress2: "ໃສ່ແຖວທີ່ຢູ່ຂອງສາຂາ 1",
    pleaseEnterBranchAddress2: "ກະລຸນາໃສ່ແຖວທີ່ຢູ່ຂອງສາຂາ 2",
    enterBranchName: "ໃສ່ຊື່ສາຂາ",
    successfullyAddedStaff: "ທ່ານໄດ້ເພີ່ມພະນັກງານໃsuccessfully່ ສຳ ເລັດແລ້ວ!",
    addNewStaff: "ເພີ່ມພະນັກງານໃ່",
    addStaff: "ເພີ່ມພະນັກງານ",
    additionalStaffWillIncur: "ຈະມີພະນັກງານເພີ່ມເຕີມ",
    perStaff: "ຕໍ່ພະນັກງານ.",
    pleaseEnterStaffEmail: "ກະລຸນາໃສ່ອີເມລ staff ຂອງພະນັກງານ",
    pleaseEnterStaffUsername: "ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້ຂອງພະນັກງານ",
    pleaseEnterStaffPassword: "ກະລຸນາໃສ່ລະຫັດລັບຂອງພະນັກງານ",
    pleaseSelectStaffRole: "ກະລຸນາເລືອກພາລະບົດບາດຂອງພະນັກງານ",
    selectStaffRole: "ເລືອກບົດບາດຂອງພະນັກງານ",
    enterStaffEmail: "ໃສ່ອີເມວຂອງພະນັກງານ",
    enterStaffUsername: "ໃສ່ຊື່ຜູ້ໃຊ້ຂອງພະນັກງານ",
    enterStaffPassword: "ໃສ່ລະຫັດລັບຂອງພະນັກງານ",
    spacesNotAllowedInUsername: "ບໍ່ອະນຸຍາດໃຫ້ມີຍະຫວ່າງຢູ່ໃນຊື່ຜູ້ໃຊ້",
    admin: "ຜູ້ບໍລິຫານ",
    pleaseSelectBusinessToAttachStaff: "ກະລຸນາເລືອກທຸລະກິດເພື່ອຕິດພະນັກງານ",
    searchForBranchToAttachStaff: "ຄົ້ນຫາສາຂາເພື່ອຕິດພະນັກງານ",
    username: "ຊື່ຜູ້ໃຊ້",
    role: "ພາລະບົດບາດ",
    areYouSureToDeleteThis: "ເຈົ້າແນ່ໃຈບໍ່ວ່າຈະລຶບອັນນີ້ອອກ",
    branches: "ສາຂາ",
    upgradeYourPlan: "ຍົກລະດັບແຜນການຂອງທ່ານ.",
    add: "ເພີ່ມ",
    addNew: "ເພີ່ມໃ່",
    customerWithEmailAlreadyExists: "ລູກຄ້າທີ່ມີອີເມວ/ເບີໂທລະສັບມີຢູ່ແລ້ວ!",
    successfullyAddedNewCustomer:
      "ທ່ານໄດ້ເພີ່ມລູກຄ້າໃsuccessfully່ ສຳ ເລັດແລ້ວ!",
    addCustomer: "ເພີ່ມລູກຄ້າ",
    pleaseEnterCustomerFirstName: "ກະລຸນາໃສ່ຊື່ຂອງລູກຄ້າ",
    pleaseEnterCustomerLastName: "ກະລຸນາໃສ່ນາມສະກຸນຂອງລູກຄ້າ",
    pleaseEnterCustomerPhoneNumber: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງລູກຄ້າ",
    pleaseEnterCustomerEmail: "ກະລຸນາໃສ່ອີເມລ customer ຂອງລູກຄ້າ",
    pleaseEnterCustomerAddressLine: "ກະລຸນາໃສ່ທີ່ຢູ່ຂອງລູກຄ້າ",
    pleaseEnterCustomerOtherAddress: "ກະລຸນາໃສ່ທີ່ຢູ່ອື່ນຂອງລູກຄ້າ",
    pleaseSelectCustomerGender: "ກະລຸນາເລືອກເພດຂອງລູກຄ້າ",
    gender: "ເພດ",
    male: "ຜູ້​ຊາຍ",
    female: "ເພດຍິງ",
    bank: "ທະນາຄານ",
    selectBank: "ເລືອກທະນາຄານ",
    stateOrRegionOrProvince: "ລັດ/ພາກພື້ນ/ແຂວງ",
    customerNotes: "ບັນທຶກຂອງລູກຄ້າ",
    sendSms: "ສົ່ງ SMS",
    editCustomer: "ແກ້ໄຂລູກຄ້າ",
    redeemReward: "ແລກຂອງລາງວັນ",
    issueLoyaltyCard: "ອອກບັດສະມາຊິກ",
    deleteCustomer: "ລຶບລູກຄ້າ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ທ່ານໄດ້ມອບIDາຍເລກບັດສະມາຊິກພັກດີ",
    noMembershipIdAvailable:
      "ບໍ່ມີ ID ສະມາຊິກ. ກະລຸນາຕິດຕໍ່ຫາພວກເຮົາທີ່ hello@loystar.co",
    sendEmail: "ສົ່ງອີເມລ",
    membershipPoints: "ຈຸດສະມາຊິກ",
    customerDetails: "ລາຍລະອຽດຂອງລູກຄ້າ",
    close: "ປິດ",
    loyaltyBalance: "ຍອດເຫຼືອຄວາມພັກດີ",
    pointsBalance: "ຍອດຄະແນນ",
    starBalance: "ສົມດຸນດາວ",
    requiredPoints: "ຈຸດທີ່ຕ້ອງການ",
    requiredStars: "ດາວທີ່ຕ້ອງການ",
    reddemCode: "ແລກລະຫັດຄືນ",
    toDeleteThisCustomer: "ເພື່ອລຶບລູກຄ້ານີ້",
    customerHasBeenDeletedSuccessfully: "ລຶບລູກຄ້າ ສຳ ເລັດແລ້ວ!",
    customerWithPhoneAlreadyExists: "ລູກຄ້າທີ່ມີເບີໂທລະສັບມີຢູ່ແລ້ວ",
    customerWasSuccessfullyEdited: "ແກ້ໄຂລູກຄ້າ ສຳ ເລັດແລ້ວ",
    anErrorOccured: "ຄວາມ​ຜິດ​ພາດ​ເກີດ​ຂຶ້ນ",
    phoneNumberIncludeCountry: "ເບີໂທລະສັບ (ລວມທັງລະຫັດປະເທດ)",
    yourFileQueuedForUpload:
      "ໄຟລ Your ຂອງທ່ານຢູ່ໃນຄິວອັບໂຫລດແລ້ວ. ກະລຸນາໂຫຼດ ໜ້າ ຄືນໃafter່ຫຼັງຈາກສອງສາມວິນາທີ.",
    thereWasAnErrorPerformingOperation: "ເກີດຄວາມຜິດພາດໃນການ ດຳ ເນີນການ!",
    pleaseSelectFile: "ກະລຸນາເລືອກໄຟລ!",
    oopsOnlyCsvFilesAllowed:
      "ອຸ້ຍ! ອະນຸຍາດສະເພາະໄຟລ CS CSV. ກະລຸນາອັບໂຫຼດໄຟລ. .csv.",
    fileShouldBeSmallerThan5Mb:
      "ໄຟລ should ຄວນນ້ອຍກວ່າ 5MB. ຖ້າເຈົ້າມີໄຟລ larger ໃຫຍ່ກວ່າ, ກະລຸນາສົ່ງອີເມວຫາ support@loystar.co. ຂອບ​ໃຈ",
    customerFirstNameIsRequired: "ຕ້ອງການຊື່ລູກຄ້າຢູ່ໃນແຖວ",
    customerPhoneNumberIsRequired: "ຕ້ອງການໃຫ້ມີເບີໂທລະສັບຂອງລູກຄ້າຢູ່ໃນແຖວ",
    importCustomers: "ນຳ ເຂົ້າລູກຄ້າ",
    upload: "ອັບໂຫລດ",
    clickIconToDownloadCsv: "ຄລິກໄອຄອນນີ້ເພື່ອດາວໂຫຼດແມ່ແບບໄຟລ CS CSV.",
    getGoogleSheetVersion: "ເອົາສະບັບ Google sheet",
    clickOrDragFileToUpload: "ຄລິກຫຼືລາກໄຟລ to ໃສ່ພື້ນທີ່ນີ້ເພື່ອອັບໂຫລດ",
    missingOrInvalidColumnHeader:
      "ບໍ່ມີຫຼືສ່ວນຫົວຖັນບໍ່ຖືກຕ້ອງ. ກະລຸນາປະຕິບັດຕາມຮູບແບບແມ່ແບບ. ຂອບ​ໃຈ.",
    youHaveImported: "ເຈົ້າໄດ້ ນຳ ເຂົ້າ",
    youSuccessfullyRedeemedReward: "ທ່ານໄດ້ແລກຮາງວັນຂອງທ່ານ ສຳ ເລັດແລ້ວ!",
    sixDigitCode: "ລະຫັດຫົກຕົວເລກ",
    pleaseEnterCustomerRewardCode: "ກະລຸນາໃສ່ລະຫັດລາງວັນຂອງລູກຄ້າ",
    enterRewardCode: "ໃສ່ລະຫັດລາງວັນ. (ລະຫັດລາງວັນແມ່ນມີຕົວພິມນ້ອຍໃຫຍ່ໃສ່)",
    selectLoyaltyProgram: "ເລືອກໂຄງການຄວາມພັກດີ",
    stamps: "ສະແຕມ",
    smsUnitsLow: "ໜ່ວຍ SMS ຕ່ ຳ",
    pleaseTopUpSmsUnits: "ກະລຸນາຕື່ມເງິນຫົວ ໜ່ວຍ SMS",
    smsSuccessfullySentForDelivery: "ສົ່ງ SMS ສຳ ເລັດການຈັດສົ່ງແລ້ວ!",
    sendSmsTo: "ສົ່ງ SMS ຫາ",
    allCustomers: "ລູກຄ້າທັງຫມົດ",
    unitsAvailable: "ມີ ໜ່ວຍ",
    pleaseTypeInTheMessage: "ກະລຸນາພິມຂໍ້ຄວາມ",
    message: "ຂໍ້ຄວາມ",
    charactersRemaining: "ຕົວອັກສອນທີ່ຍັງເຫຼືອ",
    avoidUseOfSpecialCharacters:
      "ຫຼີກເວັ້ນການໃຊ້ຕົວອັກສອນພິເສດແລະ Emojis ເພື່ອຮັກສາ ໜ່ວຍ SMS.",
    note: "ຫມາຍ​ເຫດ​",
    errorFetchingCustomersMultilevelDetail:
      "ເກີດຄວາມຜິດພາດໃນການດຶງລາຍລະອຽດຫຼາຍລະດັບຂອງລູກຄ້າ",
    search: "ຄົ້ນຫາ",
    reset: "ຣີເຊັດ",
    importCustomer: "ນຳ ເຂົ້າລູກຄ້າ",
    addNewCustomer: "ເພີ່ມລູກຄ້າໃ່",
    sendSmsBroadcast: "ສົ່ງ SMS ອອກອາກາດ",
    totalCustomers: "ລູກຄ້າທັງົດ",
    disbursementDetails: "ລາຍລະອຽດການເບີກຈ່າຍ",
    paidBy: "ຈ່າຍໂດຍ",
    disbursed: "ເບີກຈ່າຍແລ້ວ",
    bankAccountName: "ຊື່ບັນຊີທະນາຄານ",
    bankAccountNumber: "ເລກບັນຊີທະນາຄານ",
    transferInitiated: "ລິເລີ່ມການໂອນແລ້ວ",
    transferCompleted: "ການໂອນ ສຳ ເລັດແລ້ວ",
    pleaseEnterAValid: "ກະລຸນາລະບຸທີ່ຖືກຕ້ອງ",
    begin: "ເລີ່ມຕົ້ນ",
    end: "ສິ້ນສຸດ",
    date: "ວັນທີ",
    paymentDate: "ວັນທີການຊໍາລະເງິນ",
    selectDisbursementDuration: "ເລືອກໄລຍະເວລາການເບີກຈ່າຍ",
    totalSettled: "ການຕົກລົງທັງົດ",
    totalUnsettled: "ຄວາມບໍ່ສະຫງົບທັງົດ",
    toDeleteThisSale: "ເພື່ອລຶບການຂາຍນີ້ອອກ",
    draftSaleDeletedSuccessfully: "ການລຶບສະບັບຮ່າງຖືກລຶບອອກ ສຳ ເລັດແລ້ວ!",
    deleteSale: "ລຶບການຂາຍ",
    pleaseTypeInYourTotal: "ກະລຸນາພິມ ຈຳ ນວນທັງyourົດຂອງເຈົ້າເພື່ອຢືນຢັນການລຶບ",
    billDetails: "ລາຍລະອຽດໃບບິນ",
    printBill: "ພິມໃບບິນ",
    servedBy: "ໃຫ້ບໍລິການໂດຍ",
    total: "ລວມ",
    createdDate: "ວັນທີສ້າງ",
    createdTime: "ເວລາສ້າງ",
    status: "ສະຖານະພາບ",
    loginSuccessful: "ເຂົ້າສູ່ລະບົບ ສຳ ເລັດແລ້ວ",
    pleaseWaitWhileWeConnectAccount:
      "ກະລຸນາລໍຖ້າໃນຂະນະທີ່ພວກເຮົາເຊື່ອມຕໍ່ບັນຊີຂອງທ່ານ",
    connectionFailedTryAgain: "ການເຊື່ອມຕໍ່ບໍ່ ສຳ ເລັດ. ກະລຸນາລອງໃ່.",
    connectionSuccessful: "ການເຊື່ອມຕໍ່ ສຳ ເລັດແລ້ວ",
    viewDetails: "ເບິ່ງ​ລາຍ​ລະ​ອຽດ",
    enable: "ເປີດໃຊ້",
    free: "ບໍ່ເສຍຄ່າ",
    cost: "ຄ່າໃຊ້ຈ່າຍ",
    visitWebsite: "ຢ້ຽມຢາມເວັບໄຊທ",
    pleaseUpgradeYourPlanToPro:
      "ກະລຸນາອັບເກຣດແຜນການຂອງເຈົ້າເປັນ Pro Plus ເພື່ອເປີດໃຊ້ແອັບນີ້",
    connectYourBankAccount:
      "ເຊື່ອມຕໍ່ບັນຊີທະນາຄານຂອງເຈົ້າເພື່ອໃຫ້ສາມາດຮັບເງິນໄດ້.",
    disable: "ປິດການ ນຳ ໃຊ້",
    enableApp: "ເປີດໃຊ້ແອັບ",
    addNewProductToInvoice: "ເພີ່ມສິນຄ້າໃto່ໃສ່ໃນໃບແຈ້ງ ໜີ້",
    toDeleteThisInvoice: "ເພື່ອລຶບໃບຮຽກເກັບເງິນນີ້",
    invoiceDeletedSuccessfully: "ໃບແຈ້ງ ໜີ້ ຖືກລຶບອອກ ສຳ ເລັດແລ້ວ!",
    deleteInvoice: "ລຶບໃບແຈ້ງ ໜີ້",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ທ່ານໄດ້ເພີ່ມຂໍ້ຄວາມການຈ່າຍເງິນໃນໃບແຈ້ງ ໜີ້ ສຳ ເລັດແລ້ວ!",
    pleaseEnterPaymentInstructions: "ກະລຸນາໃສ່ຄໍາແນະນໍາການຊໍາລະເງິນ",
    invoiceId: "ID ໃບແຈ້ງ ໜີ້",
    paidDate: "ວັນທີຈ່າຍ",
    reference: "ເອກະສານອ້າງອີງ",
    productAdded: "ເພີ່ມຜະລິດຕະພັນແລ້ວ",
    productOutOfStock: "ສິນຄ້າstockົດ. ກະລຸນາເກັບຄືນໃ່.",
    totalInvoices: "ຂໍ້ມູນທັງົດ",
    totalPaidInvoices: "ໃບແຈ້ງຈ່າຍທັງົດ",
    totalUnpaidInvoices: "ລາຍໄດ້ທີ່ບໍ່ໄດ້ຮັບທັງົດ",
    loyaltyProgramDeleted: "ລຶບໂຄງການຄວາມພັກດີອອກແລ້ວ",
    thisLoyaltyProgramDeletedSuccessfully:
      "ໂຄງການສະສົມຄະແນນນີ້ຖືກລຶບອອກ ສຳ ເລັດແລ້ວ",
    thisLoyaltyProgramEditedSuccessfully:
      "ໂຄງການຄວາມພັກດີນີ້ໄດ້ຖືກແກ້ໄຂ ສຳ ເລັດແລ້ວ",
    loyaltyProgramAddedSuccessfully: "ເພີ່ມໂຄງການຄວາມຈົງຮັກພັກດີ ສຳ ເລັດແລ້ວ",
    loyaltyProgramEdited: "ແກ້ໄຂໂຄງການຄວາມພັກດີ",
    loyaltyProgramAdded: "ເພີ່ມໂຄງການຄວາມພັກດີແລ້ວ",
    loyaltyDetails: "ລາຍລະອຽດຄວາມພັກດີ",
    doYouWantToCloseDialog: "ເຈົ້າຕ້ອງການປິດກ່ອງໂຕ້ຕອບນີ້ບໍ?",
    pleaseEnterLoyaltyName: "ກະລຸນາໃສ່ຊື່ຂອງຄວາມສັດຊື່ຕໍ່ຂອງເຈົ້າ",
    programType: "ປະເພດໂຄງການ",
    pleaseSelectProgramType: "ກະລຸນາເລືອກປະເພດໂປຣແກຣມ",
    simplePoints: "ຈຸດງ່າຍ Simple",
    stampsProgram: "ໂຄງການສະແຕມ",
    threshold: "ເກນ",
    pleaseEnterLoyaltyThreshold: "ກະລຸນາປ້ອນເກນຄວາມພັກດີ",
    reward: "ລາງ​ວັນ",
    pleaseEnterLoyaltyReward: "ກະລຸນາໃສ່ລາງວັນຂອງຄວາມພັກດີ",
    totalUserPoints: "ຄະແນນຜູ້ໃຊ້ທັງົດ",
    totalUserStamps: "ສະແຕມຜູ້ໃຊ້ທັງົດ",
    spendingTarget: "ເປົ້າndingາຍການໃຊ້ຈ່າຍ",
    spendingTargetHint1:
      "ເປົ້າndingາຍການໃຊ້ຈ່າຍແມ່ນລູກຄ້າຕ້ອງໃຊ້ເງິນເທົ່າໃດເພື່ອຈະໄດ້ຮັບລາງວັນ. 1 ຄ່າສະກຸນເງິນ = 1 ຄະແນນ.",
    spendingTargetHint2:
      "ເປົ້າStາຍສະແຕມແມ່ນຈໍານວນສະແຕມທີ່ລູກຄ້າຕ້ອງເກັບກໍາເພື່ອຮັບເອົາລາງວັນ. ຕົວຢ່າງ: 5",
    addNewLoyaltyProgram: "ເພີ່ມໂຄງການຄວາມພັກດີໃNew່",
    addLoyaltyProgram: "ເພີ່ມໂຄງການຄວາມພັກດີ",
    loyaltyProgramType: "ປະເພດໂຄງການຄວາມພັກດີ",
    pleaseSelectLoyaltyProgramType: "ກະລຸນາເລືອກປະເພດໂຄງການຄວາມພັກດີ",
    nameOfProgram: "ຊື່ຂອງໂຄງການ",
    pleaseEnterProgramName: "ກະລຸນາໃສ່ຊື່ໂຄງການ",
    whatIsTheReward: "ລາງວັນແມ່ນຫຍັງ?",
    egNextPurchaseIsFree: "ຕົວຢ່າງ: ການຊື້ຄັ້ງຕໍ່ໄປແມ່ນບໍ່ໄດ້ເສຍຄ່າ",
    customerName: "ຊື່ລູກຄ້າ",
    guestCustomer: "ລູກຄ້າແຂກ",
    orderConfirmedSuccessfully: "ຢືນຢັນ ຄຳ ສັ່ງຊື້ ສຳ ເລັດແລ້ວ",
    orderCancelledSuccessfully: "ຍົກເລີກ ຄຳ ສັ່ງ ສຳ ເລັດແລ້ວ",
    confirmOrder: "ຢືນຢັນຄໍາສັ່ງ",
    cancelOrder: "ຍົກເລີກຄໍາສັ່ງ",
    allOrders: "ຄໍາສັ່ງທັງຫມົດ",
    totalOrders: "ການສັ່ງຊື້ທັງົດ",
    doYouWantToAcceptOrder: "ເຈົ້າຕ້ອງການຍອມຮັບ ຄຳ ສັ່ງຊື້ນີ້ບໍ?",
    doYouWantToCancelOrder: "ເຈົ້າຕ້ອງການຍົກເລີກ ຄຳ ສັ່ງຊື້ນີ້ບໍ?",
    orderDetails: "ລາຍລະອຽດການສັ່ງ",
    orderCreatedAt: "ສ້າງ ຄຳ ສັ່ງແລ້ວທີ່",
    supplier: "ຜູ້ສະ ໜອງ",
    productName: "ຊື່​ຜະ​ລິດ​ຕະ​ພັນ",
    quantity: "ປະລິມານ",
    unitPrice: "ລາ​ຄາ​ຕໍ່​ຫນ່ວຍ",
    receivedBy: "ໄດ້ຮັບໂດຍ",
    reportFrom: "ລາຍງານຈາກ",
    totalSupplies: "ອຸປະກອນທັງົດ",
    allRightsReserved: "ສະຫງວນສິດທັງົດ",
    toDeleteThisTransaction: "ເພື່ອລຶບທຸລະ ກຳ ນີ້",
    transactionDeletedSuccessfully:
      "ທຸລະ ກຳ ຖືກລຶບອອກ ສຳ ເລັດແລ້ວ. ຫຼັກຊັບໄດ້ຖືກສົ່ງກັບຄືນສູ່ສາງ.",
    deleteTransaction: "ລຶບທຸລະກໍາ",
    transactionDetails: "ລາຍລະອຽດທຸລະ ກຳ",
    printReceipt: "ໃບຮັບເງິນການພິມ",
    channel: "ຊ່ອງ",
    discount: "ສ່ວນຫຼຸດ",
    profit: "ກໍາໄລ",
    discountedSales: "ຂາຍຫຼຸດລາຄາ",
    errorFetchingRecord: "ເກີດຄວາມຜິດພາດໃນການດຶງບັນທຶກ",
    exportTransactions: "ທຸລະ ກຳ ການສົ່ງອອກ",
    errorFetchingSalesRecord: "ເກີດຄວາມຜິດພາດໃນການດຶງບັນທຶກການຂາຍເພື່ອສົ່ງອອກ.",
    totalSellingPrice: "ລາຄາຂາຍທັງົດ",
    totalCostPrice: "ລາຄາຕົ້ນທຶນທັງົດ",
    appliedDiscount: "ສ່ວນຫຼຸດທີ່ ນຳ ໃຊ້",
    noOfItems: "ຈຳ ນວນລາຍການ",
    sales: "ການຂາຍ",
    export: "ສົ່ງອອກ",
    totalProfit: "ກໍາໄລທັງຫມົດ",
    totalBalanceInPeriod: "ຍອດຄົງເຫຼືອທັງinົດໃນໄລຍະເວລາ",
    allTimeSales: "ການຂາຍທັງຫມົດທີ່ໃຊ້ເວລາ",
    records: "ບັນທຶກ",
    todaysSales: "ການຂາຍມື້ນີ້",
    transaction: "ທຸລະກໍາ",
    youHaveExceededTotalNumberOfProducts:
      "ທ່ານໄດ້ອະນຸຍາດໃຫ້ເກີນຈໍານວນຜະລິດຕະພັນທັງonົດໃນແຜນຂອງທ່ານ. ຍົກລະດັບແຜນການຂອງທ່ານເພື່ອມ່ວນກັບຂີດ ຈຳ ກັດທີ່ສູງກວ່າ.",
    youNeedToHaveLoyaltyProgram:
      "ເຈົ້າຕ້ອງມີໂຄງການຄວາມພັກດີເພື່ອໃຊ້ຄຸນສົມບັດນີ້!",
    price: "ລາຄາ",
    category: "ປະເພດ",
    stockTracking: "ການຕິດຕາມຫຸ້ນ",
    stockCount: "ຈຳ ນວນຫຸ້ນ",
    taxed: "ເກັບພາສີແລ້ວ",
    originalPrice: "ລາຄາເດີມ",
    costPrice: "ລາຄາຕົ້ນທຶນ",
    unit: "ໜ່ວຍ",
    productImage: "ຮູບພາບຜະລິດຕະພັນ",
    taxRate: "ອັດ​ຕາ​ພາ​ສີ",
    taxType: "ປະເພດພາສີ",
    trackInventory: "ຕິດຕາມສິນຄ້າຄົງຄັງ",
    variants: "ຕົວປ່ຽນແປງ",
    hasVariants: "ມີ Variants",
    importProduct: "ສິນຄ້າ ນຳ ເຂົ້າ",
    exportProducts: "ຜະລິດຕະພັນສົ່ງອອກ",
    addNewProduct: "ເພີ່ມຜະລິດຕະພັນໃຫມ່",
    viewCategory: "ເບິ່ງປະເພດ",
    viewSuppliers: "ເບິ່ງຜູ້ສະ ໜອງ",
    receiveInventory: "ຮັບສິນຄ້າຄົງຄັງ",
    printAllProductsTag: "ພິມປ້າຍຜະລິດຕະພັນທັງົດ",
    deleteAll: "ລົບ​ທັງ​ຫມົດ",
    totalProducts: "ຜະລິດຕະພັນທັງົດ",
    youveSuccessfullyAddedANewCategory: "ທ່ານເພີ່ມcategoryວດໃ່ ສຳ ເລັດແລ້ວ",
    addNewCategory: "ເພີ່ມCategoryວດໃ່",
    addCategory: "ເພີ່ມCategoryວດູ່",
    categoryName: "ຊື່ປະເພດ",
    pleaseEnterCategoryName: "ກະລຸນາໃສ່ຊື່ປະເພດ",
    stampsTarget: "ເປົ້າStາຍສະແຕມ",
    sendInventory: "ສົ່ງສິນຄ້າຄົງຄັງ",
    productDetails: "ລາຍລະອຽດຂອງຜະລິດຕະພັນ",
    youveSuccessfullyEditedThisCategory:
      "ທ່ານໄດ້ແກ້ໄຂcategoryວດນີ້ ສຳ ເລັດແລ້ວ",
    update: "ອັບເດດ",
    categoryList: "ລາຍການCategoryວດູ່",
    categories: "Categoriesວດູ່",
    enterQuantityToUpdate: "ໃສ່ປະລິມານເພື່ອອັບເດດ",
    inventorySentSuccessfully: "ບັນຊີລາຍການໄດ້ຖືກສົ່ງໄປສົບຜົນສໍາເລັດ!",
    updateInventory: "ອັບເດດລາຍການສິນຄ້າຄົງຄັງ",
    currentQuantity: "ປະລິມານປັດຈຸບັນ",
    pleaseEnterQuantityToAdd: "ກະລຸນາໃສ່ປະລິມານທີ່ເຈົ້າຕ້ອງການເພີ່ມ",
    pleaseSelectABranch: "ກະລຸນາເລືອກສາຂາ",
    searchForBranch: "ຄົ້ນຫາສາຂາ",
    youCantSendMoreThanStock: "ເຈົ້າບໍ່ສາມາດສົ່ງຫຼາຍກ່ວາເຈົ້າມີຢູ່ໃນຫຼັກຊັບ",
    send: "ສົ່ງ",
    pleaseEnterQuantityToSend: "ກະລຸນາໃສ່ປະລິມານທີ່ເຈົ້າຕ້ອງການສົ່ງ",
    productNameIsRequiredOnRow: "ຕ້ອງການຊື່ຜະລິດຕະພັນຢູ່ໃນແຖວ",
    productCategoryIsRequiredOnRow:
      "ຕ້ອງການcategoryວດproductູ່ຜະລິດຕະພັນຢູ່ໃນແຖວ",
    productPriceIsRequiredOnRow: "ຕ້ອງການລາຄາຜະລິດຕະພັນຢູ່ໃນແຖວ",
    productUnitIsRequiredOnRow: "ຕ້ອງການ ໜ່ວຍ ຜະລິດຕະພັນຢູ່ໃນແຖວ",
    productQuantityIsRequiredOnRow: "ຈຳ ນວນຜະລິດຕະພັນແມ່ນຕ້ອງການຢູ່ໃນແຖວ",
    productVariantsRequireTracking: "ຕົວປ່ຽນແປງຜະລິດຕະພັນຕ້ອງການການຕິດຕາມ!",
    pleaseAddVariantClickButton: "ກະລຸນາເພີ່ມຕົວແປໂດຍການຄລິກປຸ່ມເພີ່ມ!",
    totalVariantsMoreThanSelectedQuantity:
      "ຕົວປ່ຽນແປງທັງareົດແມ່ນຫຼາຍກວ່າປະລິມານຜະລິດຕະພັນທີ່ເລືອກ, ກະລຸນາຫຼຸດປະລິມານຕົວແປ",
    productEditedSuccessfully: "ຜະລິດຕະພັນໄດ້ຖືກແກ້ໄຂສໍາເລັດ!",
    fileTooLargeLessThan4Mb:
      "ຂະ ໜາດ ໄຟລ large ໃຫຍ່ເກີນໄປ! ຂະ ໜາດ ໄຟລ should ຄວນ ໜ້ອຍ ກວ່າ 4MB.",
    suchVariantAlreadyExist: "ຕົວແປດັ່ງກ່າວມີຢູ່ແລ້ວ",
    addVariants: "ເພີ່ມ Variants",
    editProduct: "ແກ້ໄຂຜະລິດຕະພັນ",
    pleaseEnterProductName: "ກະລຸນາໃສ່ຊື່ຜະລິດຕະພັນ",
    pleaseEnterProductPrice: "ກະລຸນາໃສ່ລາຄາຜະລິດຕະພັນ",
    pleaseEnterProductOriginalPrice: "ກະລຸນາໃສ່ລາຄາສິນຄ້າເດີມ",
    productDescription: "ລາຍລະອຽດຂອງຜະລິດຕະພັນ",
    selectProductCategory: "ເລືອກປະເພດຜະລິດຕະພັນ",
    pleaseSelectProductCategory: "ກະລຸນາເລືອກປະເພດຜະລິດຕະພັນ",
    productCostPrice: "ລາຄາຕົ້ນທຶນຜະລິດຕະພັນ",
    productSellingPrice: "ລາຄາຂາຍຜະລິດຕະພັນ",
    productOriginalPrice: "ລາຄາສິນຄ້າເດີມ",
    maxFileSizeAllowedIs4Mb: "ສູງສຸດ ຂະ ໜາດ ໄຟລ allowed ທີ່ອະນຸຍາດແມ່ນ 4mb",
    productsWithPicturesArePublished:
      "ຜະລິດຕະພັນທີ່ມີຮູບພາບໄດ້ຖືກຕີພິມໃນ Discover ເພື່ອຮັບຄໍາສັ່ງ",
    shouldThisProductBeTracked: "ຄວນຕິດຕາມຜະລິດຕະພັນນີ້ບໍ?",
    pleaseSelectStockUnit: "ກະລຸນາເລືອກຫົວ ໜ່ວຍ ຫຼັກຊັບ",
    stockUnit: "ໜ່ວຍ ສະຕັອກ",
    bag: "ຖົງ",
    bottle: "BOTTLE",
    bunch: "ຊໍ່",
    can: "ສາ​ມາດ",
    carton: "ກາຕູນ",
    crate: "CRATE",
    cup: "CUP",
    dozen: "ອາຍແກັສ",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "ຊອງ",
    pair: "ຈັບຄູ່",
    pieces: "PIECES",
    plate: "ຈານ",
    tonne: "ໂຕນ (MT)",
    uNIT: "ໜ່ວຍ",
    yard: "ເດີ່ນ",
    pleaseEnterProductQuantity: "ກະລຸນາໃສ່ປະລິມານຜະລິດຕະພັນ",
    productQuantity: "ປະລິມານຜະລິດຕະພັນ",
    isThisProductTaxed: "ສິນຄ້ານີ້ເສຍພາສີບໍ?",
    selectTaxType: "ເລືອກປະເພດພາສີ",
    pleaseSelectTaxType: "ກະລຸນາເລືອກປະເພດພາສີ",
    progressive: "ກ້າວ ໜ້າ",
    proportional: "ສັດສ່ວນ",
    pleaseEnterProductTaxRate: "ກະລຸນາໃສ່ອັດຕາພາສີຜະລິດຕະພັນ",
    doesProductHaveVariants: "ຜະລິດຕະພັນນີ້ມີຕົວແປບໍ່?",
    type: "ປະເພດ",
    value: "ມູນຄ່າ",
    pleaseEnterVariantType: "ກະລຸນາໃສ່ປະເພດຂອງຕົວປ່ຽນແປງ",
    pleaseEnterVariantValue: "ກະລຸນາປ້ອນຄ່າຂອງຕົວປ່ຽນແປງ",
    pleaseEnterVariantPrice: "ກະລຸນາໃສ່ລາຄາຂອງຕົວປ່ຽນແປງ",
    pleaseEnterVariantQuantity: "ກະລຸນາໃສ່ປະລິມານຂອງຕົວປ່ຽນ",
    productDeletedSuccessfully: "ສິນຄ້າຖືກລຶບອອກຮຽບຮ້ອຍແລ້ວ!",
    categoryDeletedSuccessfully: "ລຶບCategoryວດsuccessfullyູ່ອອກ ສຳ ເລັດແລ້ວ!",
    toDeleteThisProduct: "ເພື່ອລຶບຜະລິດຕະພັນນີ້",
    invalidProductQuantity: "ປະລິມານສິນຄ້າບໍ່ຖືກຕ້ອງ",
    quantityAddedIsOverStock:
      "ປະລິມານທີ່ເຈົ້າ ກຳ ລັງເພີ່ມແມ່ນຫຼາຍກວ່າທີ່ເຈົ້າມີຢູ່ໃນຫຼັກຊັບ.",
    itemInventoryNotTracked: "ສິນຄ້າຄົງຄັງສິນຄ້າບໍ່ໄດ້ຖືກຕິດຕາມ",
    addBulkQuantity: "ເພີ່ມປະລິມານ ຈຳ ນວນຫຼາຍ",
    enterBulkQuantity: "ໃສ່ ຈຳ ນວນຫຼາຍ",
    pleaseEnterBulkQuantity: "ກະລຸນາໃສ່ປະລິມານຫຼາຍ",
    youveSuccessfullyAddedANewProduct:
      "ທ່ານໄດ້ເພີ່ມຜະລິດຕະພັນໃsuccessfully່ ສຳ ເລັດແລ້ວ!",
    pleaseEnterProductSellingPrice: "ກະລຸນາໃສ່ລາຄາຂາຍຜະລິດຕະພັນ",
    doYouWantToTrackProductStock: "ເຈົ້າຕ້ອງການຕິດຕາມຫຸ້ນຜະລິດຕະພັນບໍ່?",
    sellingPrice: "ລາ​ຄາ​ຂາຍ",
    setProductExpiryReminder: "ຕັ້ງການແຈ້ງເຕືອນການProductົດອາຍຸຜະລິດຕະພັນ",
    productExpiryDate: "ວັນProductົດອາຍຸຂອງຜະລິດຕະພັນ",
    startRemindingFrom: "ເລີ່ມເຕືອນຈາກ",
    productSuppliesAddedSuccessfully:
      "ທ່ານໄດ້ເພີ່ມການສະ ໜອງ ຜະລິດຕະພັນ ສຳ ເລັດແລ້ວ.",
    addProductSupplies: "ຕື່ມການສະຫນອງຜະລິດຕະພັນ",
    pleaseSelectSupplier: "ກະລຸນາເລືອກຜູ້ສະ ໜອງ",
    nameOfProduct: "ຊື່ຜະລິດຕະພັນ",
    pleaseSelectProduct: "ກະລຸນາເລືອກຜະລິດຕະພັນ",
    productVariant: "Variant ຜະລິດຕະພັນ",
    pleaseSelectAVariant: "ກະລຸນາເລືອກຕົວປ່ຽນ",
    pleaseEnterUnitPrice: "ກະລຸນາໃສ່ລາຄາຫົວ ໜ່ວຍ",
    businessBranch: "ສາຂາທຸລະກິດ",
    pleaseSelectBranch: "ກະລຸນາເລືອກສາຂາ",
    youveSuccessfullyAddedANewSupplier:
      "ທ່ານໄດ້ເພີ່ມຜູ້ສະ ໜອງ ໃsuccessfully່ ສຳ ເລັດແລ້ວ",
    addSupplier: "ເພີ່ມຜູ້ສະ ໜອງ",
    pleaseEnterSupplierEmail: "ກະລຸນາໃສ່ອີເມວຂອງຜູ້ສະ ໜອງ",
    pleaseAddADescription: "ກະລຸນາເພີ່ມ ຄຳ ອະທິບາຍ",
    anErrorOccuredProductsDeleted:
      "ເກີດຄວາມຜິດພາດຂຶ້ນໃນຂະນະທີ່ດໍາເນີນການດໍາເນີນການ. ກະລຸນາຮັບຊາບວ່າ, ບາງຜະລິດຕະພັນອາດຈະຖືກລຶບໄປໃນຂະບວນການ",
    bulkDelete: "ລຶບ ຈຳ ນວນຫຼາຍ",
    youAreAboutToDelete: "ເຈົ້າ ກຳ ລັງຈະລຶບ",
    product: "ຜະລິດຕະພັນ",
    isDueToRedeem: "ແມ່ນເນື່ອງມາຈາກການໄຖ່ຄືນ",
    aReward: "ລາງວັນ",
    pleaseSelectCustomerToReeemReward: "ກະລຸນາເລືອກລູກຄ້າເພື່ອແລກເອົາລາງວັນ.",
    youHaveNoLoyaltyProgramRunning: "ທ່ານບໍ່ມີໂປຣແກຣມສົມມະນາຄຸນທີ່ ດຳ ເນີນຢູ່",
    customerHhasNoPointsInLoyaltyProgram: "ລູກຄ້າບໍ່ມີຈຸດໃນໂຄງການຄວາມພັກດີນີ້",
    proceedWithPayment: "ດໍາເນີນການຊໍາລະເງິນບໍ?",
    youAreAboutToPayForTransactionUsingPoints:
      "ເຈົ້າ ກຳ ລັງຈະຈ່າຍຄ່າທຸລະ ກຳ ໂດຍໃຊ້ຄະແນນ.",
    customerHas: "ລູກຄ້າມີ",
    worth: "ມູນຄ່າ",
    andIsNotSufficientToPayForTransaction:
      "ແລະບໍ່ພຽງພໍທີ່ຈະຈ່າຍສໍາລັບທຸລະກໍານີ້. ເຂົາເຈົ້າຕ້ອງການເພີ່ມຍອດເງິນດ້ວຍວິທີການຊໍາລະອື່ນບໍ?",
    addCustomerLoyalty: "ເພີ່ມຄວາມສັດຊື່ຕໍ່ລູກຄ້າ",
    pleaseAddCustomerFirst: "ກະລຸນາເພີ່ມຫຼືເລືອກລູກຄ້າກ່ອນ.",
    pleaseWaitWhileWeGetReady: "ກະລຸນາລໍຖ້າ, ໃນຂະນະທີ່ພວກເຮົາກຽມພ້ອມ",
    lowStock: "ຫຼັກຊັບຕ່ໍາ",
    pleaseEnterAmountTendered: "ກະລຸນາໃສ່ ຈຳ ນວນທີ່ໄດ້ສະ ເໜີ",
    areYouSureToBookSaleOffline:
      "ເຈົ້າແນ່ໃຈບໍ່ວ່າເຈົ້າຕ້ອງການຈອງການຂາຍນີ້ແບບອອບລາຍ?",
    saleWouldBeBookedAutomatically:
      "ການຂາຍຈະຖືກຈອງໂດຍອັດຕະໂນມັດເມື່ອເຈົ້າເປີດອິນເຕີເນັດຂອງເຈົ້າ",
    offlineSalesBookingCancelled: "ຍົກເລີກການຈອງການຂາຍແບບອອບລາຍແລ້ວ",
    covid19Message:
      "COVID19: ລ້າງມືຂອງເຈົ້າດ້ວຍສະບູຫຼືເຈວລ້າງມືເພື່ອຢຸດການແຜ່ລະບາດ. ຢູ່ຢ່າງປອດໄພສະເີ",
    saleSuccessfullyRecorded: "ບັນທຶກການຂາຍ ສຳ ເລັດແລ້ວ!",
    sendReceiptToEmail: "ສົ່ງໃບຮັບເງິນໄປທີ່ອີເມລ",
    sendThankYouSms: "ສົ່ງ SMS ຂໍຂອບໃຈ",
    sendShippingDetails: "ສົ່ງລາຍລະອຽດການຈັດສົ່ງ",
    addLoyalty: "ເພີ່ມຄວາມຈົງຮັກພັກດີ",
    searchCustomerNameOrNumber: "ຄົ້ນຫາຊື່ລູກຄ້າຫຼືຈໍານວນ",
    clickTheSearchCustomerBox: "ຄລິກທີ່ກ່ອງຄົ້ນຫາລູກຄ້າແລະສະແກນບັດ",
    enterProductPrice: "ໃສ່ລາຄາສິນຄ້າ",
    enterPriceFor: "ໃສ່ລາຄາ",
    searchForProduct: "ຄົ້ນຫາຜະລິດຕະພັນ",
    all: "ທັງົດ",
    backToDashboard: "ກັບໄປທີ່ Dashboard",
    viewDraftSales: "ເບິ່ງສະບັບຮ່າງການຂາຍ",
    variantSelected: "ເລືອກຕົວປ່ຽນແປງແລ້ວ",
    variant: "ຕົວປ່ຽນແປງ",
    thePreviousVariantSelectionNotAvailable:
      "ຕົວເລືອກຮຸ່ນກ່ອນແມ່ນບໍ່ມີໃຫ້ກັບຕົວປ່ຽນແປງໃselected່ທີ່ເລືອກໂດຍອີງໃສ່ລາຄາ, ກະລຸນາປ່ຽນການເລືອກຂອງເຈົ້າ.",
    pleaseSupplyPositiveQuantityNumber: "ກະລຸນາລະບຸຕົວເລກປະລິມານທີ່ຕັ້ງໄວ້",
    lowStockFor: "ຫຼັກຊັບຕໍ່າສໍາລັບ",
    clearVariants: "ຕົວປ່ຽນແປງທີ່ຈະແຈ້ງ",
    pleaseEnterQuantity: "ກະລຸນາໃສ່ປະລິມານ",
    picture: "ຮູບ",
    redemptionToken: "ເຄື່ອງຫມາຍການໄຖ່",
    token: "ໂທເຄັນ",
    totalSpent: "ໃຊ້ເວລາທັງົດ",
    confirmPayment: "ຢືນຢັນການຊໍາລະ",
    hasPaymentBeenMade: "ການຊໍາລະເງິນໄດ້ດໍາເນີນການສໍາເລັດແລ້ວບໍ?",
    enterTransactionReference:
      "ກະລຸນາໃສ່ການອ້າງອີງການເຮັດທຸລະກໍາທີ່ທ່ານໄດ້ຈ່າຍດ້ວຍ",
    pleaseSelectACustomer: "ກະລຸນາເລືອກລູກຄ້າ!",
    areYouSureToApplyDiscount: "ເຈົ້າແນ່ໃຈບໍ່ວ່າເຈົ້າຕ້ອງການ ນຳ ໃຊ້ສ່ວນຫຼຸດ?",
    addYourBankAccountToEnableUssd:
      "ເພີ່ມບັນຊີທະນາຄານຂອງເຈົ້າເພື່ອເປີດ ນຳ ໃຊ້ການໂອນເງິນ USSD ທັນທີໂດຍ uPay",
    totalAmountToPay: "ຈຳ ນວນທັງtoົດທີ່ຕ້ອງຈ່າຍ",
    doYouWantToCancelTransaction: "ເຈົ້າຕ້ອງການຍົກເລີກທຸລະ ກຳ ນີ້ບໍ?",
    savePrintBill: "ບັນທຶກ/ພິມໃບບິນຄ່າ",
    enterAmountCollectedForCustomer: "ໃສ່ ຈຳ ນວນທີ່ເກັບໄດ້ ສຳ ລັບລູກຄ້າ",
    recordSale: "ການຂາຍບັນທຶກ",
    checkOutWith: "ເຊັກເອົາກັບ",
    instantTransfer: "ການໂອນທັນທີ",
    dialTheUSSDCode: "ກົດລະຫັດ USSD",
    pleaseSelectABank: "ກະລຸນາເລືອກທະນາຄານ",
    payWithUSSD: "ຈ່າຍດ້ວຍ USSD",
    sendBillTo: " - ສົ່ງໃບບິນໃຫ້",
    waitingForUSSDTransfer: "ລໍຖ້າການໂອນຍ້າຍ USSD",
    percent: "ເປີເຊັນ",
    applyDiscount: "ນຳ ໃຊ້ສ່ວນຫຼຸດ",
    thisBillHasBeenSaved: "ບັນຊີລາຍການນີ້ໄດ້ຖືກບັນທຶກໄວ້",
    saveDraft: "ບັນທຶກສະບັບຮ່າງ",
    pleaseTypeANameToIdentifyCustomer: "ກະລຸນາພິມຊື່ເພື່ອລະບຸລູກຄ້າ",
    paymentDetails: "ລາຍ​ລະ​ອຽດ​ການ​ຊໍາ​ລະ​ເງິນ",
    basePrice: "ລາຄາພື້ນຖານ",
    staff: "ພະນັກງານ",
    subTotal: "ລວມທັງົດ",
    durationMonths: "ໄລຍະເວລາ (ເດືອນ)",
    selectADuration: "ເລືອກໄລຍະເວລາ",
    oneMonth: "1 ເດືອນ",
    twoMonths: "2 ເດືອນ",
    threeMonths: "3 ເດືອນ",
    sixMonths: "6 ເດືອນ",
    twelveMonths: "12 ເດືອນ",
    eighteenMonths: "18 ເດືອນ",
    twentyFourMonths: "24 ເດືອນ",
    twoMonthsFree: "(2 ເດືອນຟຣີ)",
    threeMonthsFree: "(3 ເດືອນຟຣີ)",
    fiveMonthsFree: "(5 ເດືອນຟຣີ)",
    yourAccountHasBeen: "ບັນຊີຂອງເຈົ້າເຄີຍເປັນ",
    renewed: "ຕໍ່ອາຍຸ",
    upgraded: "ຍົກລະດັບ",
    successfully: "ສຳ ເລັດແລ້ວ",
    yourSubscription: "ການສະັກໃຊ້ຂອງທ່ານ",
    youAreCurrentlyEnrolledOnA: "ປະຈຸບັນນີ້ເຈົ້າໄດ້ລົງທະບຽນຢູ່ໃນກ",
    pleaseChooseAPaymentOption: "ກະລຸນາເລືອກທາງເລືອກການຊໍາລະເງິນ",
    planRenewal: "ແຜນການຕໍ່ອາຍຸ",
    planUpgrade: "ວາງແຜນການຍົກລະດັບ",
    pleaseSelectDurationToPayFor: "ກະລຸນາເລືອກໄລຍະເວລາທີ່ເຈົ້າຕັ້ງໃຈຈະຈ່າຍໃຫ້",
    staffAccounts: "ບັນຊີພະນັກງານ",
    ecommerce: "ອີຄອມເມີຊ",
    pleaseSelectAPlan: "ກະລຸນາເລືອກແຜນ",
    includeAddons: "ລວມເອົາສ່ວນເສີມ",
    viewTransactions: "ເບິ່ງທຸລະກໍາ",
    customerHasNoCompletedTansactions: "ລູກຄ້າຍັງບໍ່ທັນ ສຳ ເລັດການແກ້ໄຂເທື່ອ",
    branch: "ສາຂາ",
    enterNumberOfEcommerceBranches: "ໃສ່ຈໍານວນຂອງສາຂາອີຄອມເມີຊ",
    enterNumberOfEcommerceBranchesToPay:
      "ໃສ່ ຈຳ ນວນສາຂາອີຄອມເມີຊທີ່ເຈົ້າຕັ້ງໃຈຈະຈ່າຍໃຫ້",
    ecommerceIntegration: "ການເຊື່ອມໂຍງອີຄອມເມີຊ",
    enterNumberOfBranches: "ກະລຸນາໃສ່ຈໍານວນຂອງສາຂາ",
    enterNumberOfAdditionalBranchesToPay:
      "ໃສ່ ຈຳ ນວນສາຂາເພີ່ມເຕີມທີ່ເຈົ້າຕັ້ງໃຈຈະຈ່າຍໃຫ້",
    enterNumberOfStaffs: "ໃສ່ ຈຳ ນວນພະນັກງານ",
    enterNumberOfStaffsToPayFor: "ໃສ່ ຈຳ ນວນພະນັກງານທີ່ເຈົ້າຕັ້ງໃຈຈະຈ່າຍໃຫ້",
    discountApplies: "ນຳ ໃຊ້ສ່ວນຫຼຸດ",
    starsOnThe: "ດາວຢູ່ເທິງ",
    offer: "ສະເຫນີ",
    get: "ໄດ້ຮັບ",
    moreStarsToRedeem: "ດາວຫຼາຍອັນເພື່ອແລກໃຊ້",
    taxVat: "ອາກອນ (VAT)",
    callCashierToCompletePayment:
      "ໂທຫາພະນັກງານຮັບຈ່າຍເງິນເພື່ອເຮັດ ສຳ ເລັດການຊໍາລະເງິນ",
    receipt: "ໃບຮັບເງິນ",
    dear: "ຮັກແພງ",
    thankYouForYourOrderFindGoods:
      "ຂອບໃຈສໍາລັບຄໍາສັ່ງຂອງເຈົ້າ. ກະລຸນາຊອກຫາສິນຄ້າຕໍ່ໄປນີ້ທີ່ສະ ໜອງ ໃຫ້, ຕາມທີ່ໄດ້ຕົກລົງກັນໄວ້.",
    shippingNote: "Shippingາຍເຫດການຂົນສົ່ງ",
    enterShippingNote: "ກະລຸນາໃສ່ບັນທຶກການຂົນສົ່ງ",
    shippingAddress: "ທີ່ຢູ່ສົ່ງ",
    enterShippingAddress: "ໃສ່ທີ່ຢູ່ການຈັດສົ່ງ",
    wellDoneYouAreDueToRedeem: "ເຮັດໄດ້ດີ! ເຈົ້າຮອດ ກຳ ນົດໃຫ້ແລກ",
    toGetYourRewardNextVisit:
      "ເພື່ອຮັບເອົາລາງວັນຂອງເຈົ້າໃນການຢ້ຽມຢາມຄັ້ງຕໍ່ໄປຂອງເຈົ້າ. ຂອບ​ໃຈ",
    pointsOnThe: "ຄະແນນຢູ່ທີ່",
    morePointsToRedeem: "ຄະແນນເພີ່ມເຕີມເພື່ອແລກໃຊ້",
    showCode: "ສະແດງລະຫັດ",
    toGetYourRewardOnNextVisit:
      "ເພື່ອຮັບເອົາລາງວັນຂອງເຈົ້າໃນການຢ້ຽມຢາມຄັ້ງຕໍ່ໄປຂອງເຈົ້າ. ຂອບ​ໃຈ",
    earn: "ມີລາຍໄດ້",
    delivaryNote: "ຫມາຍເຫດ Delivary",
    draftSales: "ຮ່າງການຂາຍ",
    startDate: "ວັນ​ທີ່​ເລີ່ມ",
    endDate: "ວັນທີສິ້ນສຸດ",
    orders: "ຄໍາສັ່ງ",
    checkout: "ຈ່າຍເງິນ",
    noProductItem: "ບໍ່ມີລາຍການສິນຄ້າ",
    selectProductImage: "ເລືອກຮູບພາບຜະລິດຕະພັນ",
    selectCountry: "ເລືອກປະເທດ",
    selectRegion: "ເລືອກລັດ/ພາກພື້ນ",
    printProductTag: "ພິມປ້າຍຜະລິດຕະພັນ",
    transactionReference: "ການອ້າງອີງທຸລະ ກຳ",
    Cashier: "ພະນັກງານເກັບເງິນ",
    Manager: "ຜູ້​ຈັດ​ການ",
    Owner: "ເຈົ້າຂອງ",
    Admin: "ຜູ້ບໍລິຫານ",
    addPartners: "ເພີ່ມຄູ່ຮ່ວມງານ",
    addNewLoyaltyPartner: "ເພີ່ມຄູ່ຮ່ວມງານຄວາມສັດຊື່ໃຫມ່",
    pleaseEnterCompanyName: "ກະລຸນາໃສ່ຊື່ບໍລິສັດ",
    companyName: "ຊື່​ບໍ​ລິ​ສັດ",
    pleaseEnterCompanyRepName: "ກະລຸນາໃສ່ຊື່ຕົວແທນຂອງບໍລິສັດ",
    companyRepName: "ຊື່ຜູ້ຕາງຫນ້າບໍລິສັດ",
    pleaseEnterCompanyRepEmail: "ກະລຸນາໃສ່ອີເມວຂອງຜູ້ຕາງຫນ້າຂອງບໍລິສັດ",
    companyRepEmail: "ອີເມວຕົວແທນຂອງບໍລິສັດ",
    pleaseEnterCompanyRepPhone: "ກະລຸນາໃສ່ເບີໂທລະສັບຂອງຕົວແທນຂອງບໍລິສັດ",
    companyRepPhone: "ເບີໂທລະສັບຂອງບໍລິສັດຕົວແທນ",
    addReward: "ເພີ່ມລາງວັນ",
    pleaseEnterRewardName: "ກະລຸນາໃສ່ຊື່ລາງວັນ",
    rewardName: "ຊື່ລາງວັນ",
    rewardQuantity: "ຈໍານວນລາງວັນ",
    rewardDescription: "ລາຍ​ລະ​ອຽດ​ຂອງ​ລາງ​ວັນ​",
    rewardType: "ປະເພດລາງວັນ",
    pleaseEnterRewardType: "ກະລຸນາໃສ່ປະເພດລາງວັນ",
    pleaseEnterRewardQuantity: "ກະລຸນາໃສ່ຈໍານວນລາງວັນ",
    pleaseEnterRewardDescription: "ກະລຸນາໃສ່ລາຍລະອຽດຂອງລາງວັນ",
    fineDining: "ອາຫານແຊບ",
    luxuryFashion: "ແຟຊັ່ນຫຼູຫຼາ",
    hotels: "ໂຮງແຮມ",
    travel: "ທ່ອງ​ທ່ຽວ",
    foodAndBeverage: "ອາຫານ ແລະ ເຄື່ອງດື່ມ",
    fashion: "ແຟຊັ່ນ",
    health: "ສຸ​ຂະ​ພາບ",
    furniture: "ເຟີນິເຈີ",
    entertainment: "ບັນເທີງ",
    automobile: "ລົດຍົນ",
    education: "ການສຶກສາ",
    beautyAndSpa: "ຄວາມງາມແລະສະປາ",
    staycation: "ພັກເຊົາ",
    events: "ເຫດການ",
    trips: "ການເດີນທາງ",
    oilAndGas: "ນ້ຳມັນ ແລະ ແກັສ",
    laundry: "ຊັກ",
    partnerCategory: "ໝວດໝູ່ຄູ່ຮ່ວມງານ",
    freeItem: "ລາຍການຟຣີ",
  },
  Kyrgyz: {
    cashier: "кассир",
    manager: "менеджер",
    owner: "ээси",
    online: "онлайн",
    offline: "оффлайн",
    changePassword: "Сырсөздү өзгөртүү",
    currentPasswordMessage: "Сураныч, учурдагы сырсөзүңүздү киргизиңиз",
    passwordMessage: "Сырсөзүңүздү киргизиңиз",
    currentPassword: "Учурдагы Сырсөз",
    password: "купуя сөз",
    confirmPasswordMessage: "Сураныч, сырсөзүңүздү ырастаңыз!",
    confirmPassword: "Сырсөздү ыраста",
    sendViaEmail: "Электрондук почта аркылуу жөнөтүү",
    sendViaWhatsapp: "WhatsApp аркылуу жөнөтүү",
    downloadPdf: "PDF жүктөп алуу",
    paid: "төлөнгөн",
    unpaid: "төлөнбөгөн",
    partial: "жарым -жартылай",
    closeInvoice: "Эсеп -фактураны жапкыңыз келеби?",
    closeInvoiceConfirmation:
      "Эсеп -фактура сакталбашы мүмкүн. Жабкыңыз келеби?",
    yes: "Ооба",
    no: "жок",
    invoice: "Эсеп -фактура",
    wasDeducted: "чегерилген",
    for: "үчүн",
    item: "Пункт",
    addProduct: "Продукт кошуу",
    paymentReference: "Төлөм маалымдамасы",
    amountPaid: "Төлөнгөн сумма",
    discountAmount: "Арзандатуу суммасы",
    amountDue: "Төлөнө турган сумма",
    amount: "Сумма",
    dueDate: "Бүтүмдүк дата",
    paymentType: "Төлөмдүн түрү",
    card: "Карта",
    cash: "Акча",
    bankTransfer: "Банктык которуу",
    paymentMessage: "Төлөм билдирүүсү",
    description: "Description",
    sendReceipt: "Дүмүрчөк жөнөтүү",
    delete: "Жок кылуу",
    save: "Сактоо",
    resend: "Кайра жөнөтүү",
    saveAndSend: "Сактоо",
    invoiceSaved: "Эсеп -фактура сакталды!",
    selectPaymentMethod: "Сураныч, төлөм ыкмасын тандаңыз!",
    selectCustomer: "Кардарды тандаңыз!",
    cartEmptyError:
      "Арабалардын тизмеси бош болбошу керек, эсеп -фактураны жаап, пунктка арабага кошуңуз!",
    subscriptionExpired:
      "Жазылууңуздун мөөнөтү бүтүп, аккаунтуңуз азыр чектелүү. Каттоо эсебиңизди жаңыртуу үчүн төмөнкү баскычты басыңыз",
    renew: "Жаңыртуу",
    holdOn: "Токто",
    customerBank: "Кардар банкы",
    cancel: "Жокко чыгаруу",
    selectACustomer: "Кардарды тандоо",
    invoiceSuccessfulPdfError:
      "Эсеп -фактура ийгиликтүү түзүлдү, бирок PDF Generation адаттагыдан көбүрөөк убакытка созулууда. Сураныч, бир аздан кийин кайра текшериңиз.",
    downloadingInvoice: "Эсеп -дүмүрчөк жүктөлүүдө",
    downloadingReceipt: "Дүмүрчөк жүктөлүүдө",
    whatsappReceiptError:
      "Дүмүрчөктү WhatsApp аркылуу жөнөтүүдө ката кетти, кайра аракет кылыңыз.",
    receiptToWhatsapp: "Дүмүрчөк WhatsAppка жөнөтүлдү",
    thankYouForPatronage: "Сиздин колдоо үчүн ыраазыбыз",
    hereIsYourReceipt: "Бул жерде сиздин төлөм дүмүрчөгүңүз",
    errorSendingEmailReceipt:
      "Дүмүрчөктү электрондук почта аркылуу жөнөтүүдө ката кетти, кайра аракет кылыңыз же колдоо кызматына кайрылыңыз",
    receiptSentToEmail: "Дүмүрчөк кардардын электрондук почтасына жөнөтүлдү",
    invoiceSentToEmail:
      "Эсеп -дүмүрчөктөр кардардын электрондук почтасына жөнөтүлдү",
    invoiceSuccessWhatsappError:
      "Эсеп -фактура ийгиликтүү түзүлдү, бирок WhatsAppка жөнөтүүдө ката кетти. Эсеп -фактура тизмесинде кайра аракет кылыңыз",
    invoiceSuccessfulEmailError:
      "Эсеп-фактура ийгиликтүү түзүлдү, бирок электрондук кат катары жөнөтүүдө ката кетти. Сураныч, эсеп -фактура тизмесинен кайра аракет кылыңыз",
    invoiceSentToWhatsapp: "Эсеп -фактура WhatsAppка жөнөтүлдү",
    hello: "салам",
    pleaseDownloadInvoice: "Сураныч, эсеп -фактураны жүктөп алыңыз",
    pleaseDownloadReceipt: "Сураныч, дүмүрчөк жүктөп алыңыз",
    from: "тартып",
    email: "Email",
    upgrade: "Жаңыртуу",
    youAreOnFreePlan: "Сиз бекер пландасыз.",
    clickOn: "Click",
    yourPlanInFewSteps: " жазылууңуз бир нече тез кадамдарда.",
    to: "чейин",
    yourSubscriptionHasExpired:
      "Жазылууңуздун мөөнөтү бүтүп, аккаунтуңуз азыр чектелүү.",
    days: "күндөр",
    yourSubscriptionExpiresIn: "Сиздин Loystar жазылууңуз бүтөт",
    createAcount: "Эсеп түзүү",
    signIn: "Кирүү",
    continue: "Улантуу",
    enterOtp: "OTP PIN кодун киргизиңиз",
    enterValidOtp: "Жарактуу PIN кодду киргизиңиз",
    pin: "PIN",
    tokenSentToMail: "Электрондук почтаңызга токен жөнөтүлдү",
    passwordResetSuccessful: "Сырсөздү калыбына келтирүү ийгиликтүү болду",
    somethingWentWrong: "Бир жерден ката кетти!",
    resetPassword: "Сырсөздү калыбына келтирүү",
    iHaveResetCode: "Менде сырсөздү калыбына келтирүүчү код бар",
    pleaseEnterEmail: "Сураныч, электрондук почтаңызды киргизиңиз",
    aTokenWillBeSentToEmail: "Электрондук почтаңызга токен жөнөтүлөт",
    enterEmail: "Электрондук почтаңызды киргизиңиз",
    sendinYourToken: "Белгиңиз жөнөтүлүүдө ...",
    makeSureItMatchesPassword: "Жаңы сырсөзүңүзгө дал келерин текшериңиз",
    pleaseChooseNewPassword: "Жаңы сырсөз тандаңыз",
    chooseNewPassword: "Жаңы сырсөз тандаңыз",
    enterNewPassword: "Ырастоо үчүн жаңы сырсөзүңүздү киргизиңиз",
    enterTokenSent: "Почтаңызга жөнөтүлгөн токенди киргизиңиз",
    resetToken: "Токенди баштапкы абалга келтирүү",
    resettingPassword: "Сырсөзүңүз баштапкы абалга келтирилүүдө ...",
    signUp: "Кирүү",
    adminSignInWithEmail:
      " Админ электрондук почта менен кирет, ал эми кызматкерлер колдонуучу аты менен кирет.",
    pleaseEnterEmailOrUsername:
      "Сураныч, электрондук почтаңызды же колдонуучу атыңызды киргизиңиз",
    emailOrUsername: "Электрондук почта же колдонуучунун аты",
    pleaseEnterPassword: "Сураныч, сырсөздү киргизиңиз",
    createAnAccount: "Эсеп түзүү",
    forgotPassword: "Сырсөздү унутуп калдыңызбы?",
    enterPhoneNumber: "Телефон номерин киргизиңиз",
    personalData: "Өздүк маалымат",
    validateConfirmationCOde: "Ырастоо кодун текшерүү",
    pleaseEnterFirstName: "Атыңызды киргизиңиз",
    pleaseEnterPhoneNumber: "Сураныч, номерин киргизип",
    pleaseEnterLastName: "Сураныч, фамилияңызды киргизиңиз",
    pleaseEnterBusinessName: "Компанияңыздын атын киргизиңиз",
    firstName: "Биринчи аты",
    lastName: "Акыркы Аты",
    businessName: "Бизнес аты",
    previous: "Мурунку",
    next: "Кийинки",
    pleaseSelectBusinessCategory:
      "Сураныч, бизнесиңиздин категориясын тандаңыз",
    pleaseEnterValidEmail: "Жарактуу электрондук почта дарегин киргизиңиз",
    pleaseEnterPostCode: "Почта кодун киргизиңиз",
    postCode: "Почта коду",
    phoneNumberInUse: "Бул телефон номери мурунтан эле колдонулууда!",
    emailInUse: "Бул электрондук почта мурунтан эле колдонууда!",
    foodAndDrinks: "Тамак -аш жана суусундуктар",
    salonAndBeauty: "Салон жана сулуулук",
    fashionAndAccessories: "Мода жана аксессуарлар",
    gymAndFitness: "Спорт залы жана фитнес",
    travelAndHotel: "Саякат жана мейманкана",
    homeAndGifts: "Үй жана белектер",
    washingAndCleaning: "Жуу жана тазалоо",
    gadgetsAndElectronics: "Гаджеттер жана электроника",
    groceries: "Азык -түлүк",
    others: "Башкалар",
    submit: "Тапшыруу",
    accountCreatedSuccessful: "Сиздин каттоо эсебиңиз ийгиликтүү түзүлдү.",
    pleaseEnterAddress: "Сураныч, дарегиңизди киргизиңиз",
    addressLine1: "Дарек линиясы 1",
    addressLine2: "Дарек линиясы 2",
    choosePassword: "Сырсөздү тандоо",
    passwordMustBe6Characters: "Сырсөз кеминде 6 белгиден турушу керек.",
    howDidYouHearLoystar: "Loystar жөнүндө кантип уктуңуз?",
    referralCode: "жолдомо коду",
    byClickingTheButton:
      " Төмөндөгү баскычты басуу менен, сиз макулдугуңузду билдиресиз",
    termsAndSevice: "Шарттар",
    wereCreatingAccount: "Биз сиздин каттоо эсебиңизди түзүп жатабыз",
    proceed: "Улантуу",
    verificationCodeMustBe6: "Текшерүү коду 6 сандан турушу керек",
    pleaseEnter6DigitCode: "Сураныч, 6 орундуу кодду киргизиңиз",
    enterVerificationCode: "Текшерүү кодун киргизиңиз",
    resendToken: "Токенди кайра жөнөтүү",
    verify: "Текшерүү",
    transactions: "Бүтүмдөр",
    todaySales: "Бүгүнкү сатуу",
    salesHistory: "Сатуу тарыхы",
    supplyHistory: "Сунуш таржымалы",
    new: "Жаңы",
    invoices: "Эсеп -фактуралар",
    disbursements: "Төлөмдөр",
    offlineSales: "Offline сатуу",
    products: "Продуктылар",
    customers: "Кардарлар",
    multiLevelLoyalty: "Көп деңгээлдүү лоялдуулук",
    loyaltyPrograms: "Лоялдуулук программалары",
    members: "Мүчөлөр",
    appStore: "App Store",
    orderMenu: "Заказ менюсу",
    settings: "Орнотуулар",
    staffAndBranches: "Штабдар жана филиалдар",
    myAccount: "Менин эсебим",
    switchToSellMode: "Сатуу режимине которулуу",
    signOut: "Чыгуу",
    getFreeSubscription: "Бекер жазылууну алыңыз",
    onlyNumbersAllowed: "Сандарга гана уруксат берилген",
    yourAccountMustBe10Digits:
      "Сиздин эсеп номериңиз 10 орундуу сан болушу керек",
    yourBvnMustBe11: "Сиздин BVN 11 орундуу сан болушу керек",
    pleaseSelectBank: "Сураныч, банкыңызды тандаңыз",
    selectYourBank: "Банкыңызды тандаңыз",
    enterBankAccountNumber: "Банк эсебинин номерин киргизиңиз",
    enterBvnNumber: "BVN номериңизди киргизиңиз",
    connectBankAccount: "Банк эсебин туташтыруу",
    passwordResetSuccessfulAndSignOut:
      "Сырсөзүңүз ийгиликтүү калыбына келтирилди. Кайра кирүү үчүн төмөндөгү Чыгуу баскычын басыңыз",
    enterCurrentPassword: "Учурдагы сырсөздү киргизиңиз",
    pleaseEnterCurrentPassword: "Сураныч, учурдагы сырсөздү киргизиңиз",
    phoneNumber: "Тел номери",
    sex: "Секс",
    dateOfBirth: "Туулган датасы",
    state: "Мамлекет",
    country: "Өлкө",
    loyaltyId: "Лоялдуулук ID",
    createdAt: "Түзүлгөн",
    noOfTransactionsMade: "Жасалган бүтүмдөрдүн саны",
    yourDownloadWillStart: "Жүктөөңүз бир аздан кийин башталат",
    exportCustomers: "Кардарларды экспорттоо",
    youhaveSuccessfullyToppedUp: "SMS бирдиктериңизди ийгиликтүү толтурдуңуз.",
    youNowHave: "Сизде азыр бар",
    smsUnits: "SMS бирдиктери",
    enterNumberOfSmsUnits:
      "Сатып алууну каалаган SMS бирдиктеринин санын киргизиңиз",
    pleaseEnterANumericValue: "Сандык маанини киргизиңиз",
    pay: "Төлөө",
    accountEditedSuccessfully: "Каттоо эсебиңиз ийгиликтүү түзөтүлдү.",
    youAeCurrentlyOn: "Учурда иштеп жатасыз",
    plan: "План",
    userData: "Колдонуучунун маалыматы",
    businessData: "БИЗНЕС МААЛЫМАТЫ",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Сураныч, валютаңызды тандаңыз",
    selectYourCurrency: "Валютаңызды тандаңыз",
    purchaseMoreSmsUnits:
      "Төмөндөгү форма аркылуу дагы SMS бирдиктерин сатып алыңыз",
    youHave: "Сенде бар",
    atLeast4SmsUnitsRrequired:
      "Текшерүү үчүн жок дегенде 4 смс бирдиги талап кылынат, толуктаңыз!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Сураныч, 'Кардарыңды Таан' (KYC) талаптарына ылайык келүү үчүн банк эсебиңди текшер. Бул USSD же тез которуу аркылуу төлөмдөрдү чогултууга, кардарлардан буйрутмаларды алууга жана транзакцияларды жөнгө салууга мүмкүндүк берет. Нигериялык соодагерлерге гана тиешелүү. Баштоо үчүн төмөнкү баскычты басыңыз.",
    reConnectBankAccount: "Банк эсебин кайра туташтыруу",
    accountName: "Аккаунттун аты",
    accountNumber: "Эсеп номери",
    bankName: "Банктын аты",
    verified: "Текшерилди",
    accountDetails: "Аккаунттун чоо -жайы",
    kycBankAccount: "KYC",
    createTier: "Катмар түзүү",
    fileUploadSuccessful: "файл ийгиликтүү жүктөлдү",
    fileUploadFailed: "файл жүктөлбөй калды",
    createLoyaltyProgram: "Лоялдуулук программасын түзүңүз",
    createLoyalty: "Лоялдуулукту жаратыңыз",
    name: "Аты",
    loyaltyArtwork: "Loyalty Artwork",
    clickToUpload: "Жүктөө үчүн чыкылдатыңыз",
    amountToPointsRatio: "Упайлардын катышына сумма",
    points: "Упайлар",
    recommendedAmountToPointRatio: "Сунушталат: ₦ 1ден 1ге чейин. б",
    pleaseTypeIn: "Сураныч, териңиз",
    toDeleteLoyalty: "бул берилгендикти жок кылуу",
    deleteLoyalty: "Лоялдуулукту жок кылуу",
    toConfirm: "ырастоо",
    edit: "Түзөтүү",
    pointsAwardedSuccessfully: "Упайлар ийгиликтүү тапшырылды.",
    enterPointValueToAward:
      "Кардарларга сыйлык берүүнү каалаган пункт маанисин киргизиңиз",
    award: "Сыйлык",
    awardPointValuesToCustomer: "Кардар үчүн упай баасы",
    enrollMembersToLoyalty: "Лоялдуулукка мүчөлөрдү каттаңыз",
    awardPoints: "Сыйлык упайлары",
    enroll: "Катталуу",
    home: "Үй",
    loyalty: "Берилгендик",
    enrollCustomers: "Кардарларды каттоо",
    selected: "Тандалды",
    customer: "Кардар",
    loyaltyActivationSuccessful: "Лоялдуулукту жандандыруу ийгиликтүү болду.",
    loyaltyDeactivationSuccessful: "Ишенимдүүлүктү өчүрүү ийгиликтүү.",
    viewTier: "Катмарды көрүү",
    deactivate: "Өчүрүү",
    activate: "Иштетүү",
    actions: "Аракеттер",
    nameOfLoyalty: "Лоялдуулуктун аты",
    loyaltyStatus: "Лоялдуулук СтатусуLoyalty Status",
    numberOfTiers: "Катмарлардын саны",
    createdOn: "Түзүлгөн күнү",
    createATier: "Катмар түзүү",
    stopCreatingTierConfirmation: "Катмар түзүүнү токтоткуңуз келеби?",
    stopEditingTierConfirmation: "Бул катмарды түзөтүүнү токтоткуңуз келеби?",
    nameOfTier: "Тиердин аты",
    minimumSpendingTarget: "Минималдуу чыгымдоо максаты",
    maximumSpendingTarget: "Максималдуу чыгымдоо максаты",
    minimumSpendingTargetRequired:
      "чыгымдардын минималдуу максаты талап кылынат",
    maximumSpendingTargetRequired:
      "максималдуу чыгымдардын максаты талап кылынат",
    rewardSponsor: "Сыйлыктын демөөрчүсү",
    pleaseChooseARewardSponsor: "Сураныч, сыйлыктын демөөрчүсүн тандаңыз",
    self: "Self",
    partner: "Өнөктөш",
    rewardPartner: "Сыйлык берүүчү өнөктөш",
    pleaseSelectRewardPartner: "Сураныч, сыйлык өнөктөшүңүздү тандаңыз",
    rewards: "Сыйлыктар",
    pleaseSelectAReward: "Сыйлыкты тандаңыз",
    instructionsOnRedeemingReward:
      "Кардар Сыйлыкты кантип колдонушу керектиги боюнча нускамалар",
    pleaseFillInThisField: "Сураныч, бул талааны толтуруңуз!",
    toDeleteThisTier: " бул катмарды жок кылуу үчүн",
    deleteTier: "Катмарды жок кылуу",
    viewMembers: "Мүчөлөрдү көрүү",
    membersEnrolled: "Катталган мүчөлөр",
    instruction: "Нускама",
    membersIn: "Колдонуучулар",
    availableTiersInLoyalty: "Лоялдуулук программасында жеткиликтүү деңгээлдер",
    tiers: "Катмарлар",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Жеткиликтүү лоялдуулук программасы",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Мүчөлөрдүн чоо -жайы",
    nameOfCustomer: "Кардардын аты",
    address: "Дарек",
    allEnrolledMembers: "Бардык катталган мүчөлөр",
    thisIsToWishYouHappyBirthday:
      "Бул сизди туулган күнүңүз менен куттуктап, бакубат жашоо каалайм. Loyster үчүн баардык нерсеңиз үчүн рахмат. Майрамынар менен!",
    inputAnOfferPlease: "Сураныч, Сунуш киргизиңиз",
    pleaseSelectTheInsertPoint:
      "Сураныч, билдирүүдөн киргизүү пунктун тандап, кайра басыңыз",
    birthdayOfferAndMessage: "Туулган Сунуш жана Билдирүү",
    birthdayOffer: "Туулган күн сунушу",
    birthdayMessage: "Туулган Кабар",
    noOfferFound: "Сунуш табылган жок",
    settingABirthdayOffer:
      "Туулган күндү сунуштоо кардарларга бул сунушту туулган күнүндө SMS аркылуу алууга мүмкүндүк берет",
    createOffer: "Сунуш түзүү",
    whatIsTheOffer: "Сунуш деген эмне?",
    editMessage: "Билдирүүнү түзөтүү",
    insert: "Кыстаруу",
    theNameOfCustomerInserted: "Кардардын аты бул жерге киргизилет",
    theBirtdayOfferInserted: "Туулган күн сунушу бул жерге киргизилет",
    youSuccessfullyAddedNewBranch: "Сиз жаңы филиалды ийгиликтүү коштуңуз!",
    addNewBranch: "Жаңы филиал кошуу",
    addBranch: "Филиалды кошуу",
    additionalBranchWillIncur: "Кошумча филиал пайда болот",
    perBranch: "бутак боюнча",
    ecommerceBranchCosts: "Электрондук соода филиалынын чыгымдары",
    pleaseEnterBranchName: "Сураныч, филиалдын атын киргизиңиз",
    pleaseEnterBranchAddress1:
      "Сураныч, филиалдын дарегинин сабын 1 киргизиңиз",
    enterBranchAddress1: "Филиалдын дарек сабын 1 киргизиңиз",
    enterBranchAddress2: "Филиалдын дарек сабын 1 киргизиңиз",
    pleaseEnterBranchAddress2: "Сураныч, филиалдын дарек сабын 2 киргизиңиз",
    enterBranchName: "Филиалдын атын киргизиңиз",
    successfullyAddedStaff: "Сиз жаңы кызматкерлерди ийгиликтүү коштуңуз!",
    addNewStaff: "Жаңы кызматкерлерди кошуу",
    addStaff: "Кызматкерлерди кошуу",
    additionalStaffWillIncur: "Кошумча персонал пайда болот",
    perStaff: "персоналына.",
    pleaseEnterStaffEmail:
      "Сураныч, кызматкерлердин электрондук почтасын киргизиңиз",
    pleaseEnterStaffUsername:
      "Сураныч, кызматкерлердин колдонуучу атын киргизиңиз",
    pleaseEnterStaffPassword: "Сураныч, кызматкерлердин сырсөзүн киргизиңиз",
    pleaseSelectStaffRole: "Сураныч, кызматкерлердин ролун тандаңыз",
    selectStaffRole: "Кызматкерлердин ролун тандоо",
    enterStaffEmail: "Кызматкерлердин электрондук почтасын киргизиңиз",
    enterStaffUsername: "Кызматкерлердин колдонуучу атын киргизиңиз",
    enterStaffPassword: "Кызматкерлердин сырсөзүн киргизиңиз",
    spacesNotAllowedInUsername:
      "боштуктарга колдонуучу аты менен уруксат берилген эмес",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Кадрларды тиркөө үчүн бизнести тандаңыз",
    searchForBranchToAttachStaff: "Кадрларды тиркөө үчүн филиал издеңиз",
    username: "Колдонуучунун аты",
    role: "Роль",
    areYouSureToDeleteThis: "Муну чын эле жок кыласызбы",
    branches: "Филиалдар",
    upgradeYourPlan: "Планыңызды жаңыртыңыз.",
    add: "КОШУУ",
    addNew: "Жаңы кошуу",
    customerWithEmailAlreadyExists:
      "Электрондук почта/телефон номери бар кардар мурунтан эле бар!",
    successfullyAddedNewCustomer: "Сиз жаңы кардарды ийгиликтүү коштуңуз!",
    addCustomer: "Кардар кошуу",
    pleaseEnterCustomerFirstName: "Сураныч, кардардын атын киргизиңиз",
    pleaseEnterCustomerLastName: "Кардардын фамилиясын киргизиңиз",
    pleaseEnterCustomerPhoneNumber:
      "Сураныч, кардардын телефон номерин киргизиңиз",
    pleaseEnterCustomerEmail: "Кардардын электрондук почтасын киргизиңиз",
    pleaseEnterCustomerAddressLine:
      "Сураныч, кардардын дарек линиясын киргизиңиз",
    pleaseEnterCustomerOtherAddress: "Кардардын башка дарегин киргизиңиз",
    pleaseSelectCustomerGender: "Кардардын жынысын тандаңыз",
    gender: "Гендер",
    male: "Эркек",
    female: "Аял",
    bank: "Банк",
    selectBank: "Банкты тандаңыз",
    stateOrRegionOrProvince: "Штат/Район/Провинция",
    customerNotes: "Кардарлардын эскертүүлөрү",
    sendSms: "SMS жөнөтүү",
    editCustomer: "Кардарды түзөтүү",
    redeemReward: "Сыйлыкты колдонуу",
    issueLoyaltyCard: "Лоялдуулук картасын чыгаруу",
    deleteCustomer: "Кардарды жок кылуу",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Сиз ишенимдүү мүчө IDсин ийгиликтүү тапшырдыңыз",
    noMembershipIdAvailable:
      "Мүчөлүк идентификаторлору жок. Сураныч, hello@loystar.co дарегине биз менен байланышыңыз",
    sendEmail: "Email жөнөтүү",
    membershipPoints: "Мүчөлүк упайлары",
    customerDetails: "Кардардын чоо -жайы",
    close: "Жабуу",
    loyaltyBalance: "Берилгендик балансы",
    pointsBalance: "Упай балансы",
    starBalance: "Жылдыз балансы",
    requiredPoints: "Милдеттүү упайлар",
    requiredStars: "Керектүү жылдыздар",
    reddemCode: "Кодду активдештирүү",
    toDeleteThisCustomer: "бул кардарды жок кылуу үчүн",
    customerHasBeenDeletedSuccessfully: "Кардар ийгиликтүү өчүрүлдү!",
    customerWithPhoneAlreadyExists:
      "Телефон номери бар кардар мурунтан эле бар",
    customerWasSuccessfullyEdited: "Кардар ийгиликтүү түзөтүлдү",
    anErrorOccured: "Ката кетти",
    phoneNumberIncludeCountry: "Телефон номери (өлкөнүн коду камтылган)",
    yourFileQueuedForUpload:
      "Сиздин файл жүктөө үчүн кезекке коюлду. Баракты бир нече секунддан кийин жаңыртыңыз.",
    thereWasAnErrorPerformingOperation: "Операцияны аткарууда ката кетти!",
    pleaseSelectFile: "Сураныч, Файлды тандаңыз!",
    oopsOnlyCsvFilesAllowed:
      "Ой! CSV файлдарына гана уруксат берилген. Сураныч .csv файлын жүктөңүз.",
    fileShouldBeSmallerThan5Mb:
      "Файлдын көлөмү 5 МБтан ашпашы керек. Эгерде сизде чоңураак файл болсо, support@loystar.co электрондук дарегине жазыңыз. Рахмат",
    customerFirstNameIsRequired: "кардардын аты катарында талап кылынат",
    customerPhoneNumberIsRequired:
      "кардардын телефон номери катарында талап кылынат",
    importCustomers: "Кардарларды импорттоо",
    upload: "Жүктөө",
    clickIconToDownloadCsv:
      "CSV файл үлгүсүн жүктөө үчүн бул сөлөкөттү басыңыз.",
    getGoogleSheetVersion: "Google баракчасынын версиясын алыңыз",
    clickOrDragFileToUpload:
      "Файлды жүктөө үчүн бул жерге чыкылдатыңыз же сүйрөңүз",
    missingOrInvalidColumnHeader:
      "Мамычанын аталышы жок же жараксыз. Сураныч, шаблон форматын ээрчиңиз. Рахмат.",
    youHaveImported: "Сиз импорттодуңуз",
    youSuccessfullyRedeemedReward: "Сыйлыгыңызды ийгиликтүү сатып алдыңыз!",
    sixDigitCode: "Алты орундуу код",
    pleaseEnterCustomerRewardCode: "Кардардын сыйлык кодун киргизиңиз",
    enterRewardCode:
      "Сыйлык кодун киргизиңиз. (Сыйлык коду чоң -кичине сезгич)",
    selectLoyaltyProgram: "Лоялдуулук программасын тандаңыз",
    stamps: "Маркалар",
    smsUnitsLow: "SMS бирдиктери төмөн",
    pleaseTopUpSmsUnits: "Сураныч, SMS бирдиктерин толуктаңыз",
    smsSuccessfullySentForDelivery: "Жеткирүү үчүн SMS ийгиликтүү жөнөтүлдү!",
    sendSmsTo: "Дегенге SMS жөнөтүү",
    allCustomers: "Бардык Кардарлар",
    unitsAvailable: "Бирдиктер бар",
    pleaseTypeInTheMessage: "Сураныч, билдирүүнү териңиз",
    message: "Билдирүү",
    charactersRemaining: "калган белгилер",
    avoidUseOfSpecialCharacters:
      "SMS бирдиктерин сактоо үчүн атайын белгилерди жана эмодзилерди колдонуудан алыс болуңуз.",
    note: "Эскертүү",
    errorFetchingCustomersMultilevelDetail:
      "Кардарларга көп деңгээлдеги деталдарды алуу катасы",
    search: "Издөө",
    reset: "Кайра коюу",
    importCustomer: "Кардарды импорттоо",
    addNewCustomer: "Жаңы Кардар кошуу",
    sendSmsBroadcast: "SMS уктурууну жөнөтүү",
    totalCustomers: "ЖАЛПЫ КАРДАРЛАР",
    disbursementDetails: "Төлөөнүн чоо -жайы",
    paidBy: "Төлөгөн",
    disbursed: "Төлөнгөн",
    bankAccountName: "Банк эсебинин аталышы",
    bankAccountNumber: "Банк эсебинин номери",
    transferInitiated: "Которуу демилгеленди",
    transferCompleted: "Өткөрүү аяктады",
    pleaseEnterAValid: "Жарактуу киргизиңиз",
    begin: "баштоо",
    end: "аяктоо",
    date: "дата",
    paymentDate: "Төлөө датасы",
    selectDisbursementDuration: "Төлөөнүн узактыгын тандаңыз",
    totalSettled: "Жалпы эсептешүү",
    totalUnsettled: "Бардыгы чечилбеген",
    toDeleteThisSale: "бул сатууну жок кылуу үчүн",
    draftSaleDeletedSuccessfully: "Сомонун сатылышы ийгиликтүү өчүрүлдү!",
    deleteSale: "Сатууну жок кылуу",
    pleaseTypeInYourTotal: "Жок кылууну ырастоо үчүн жалпы суммаңызды териңиз",
    billDetails: "Биллдин чоо -жайы",
    printBill: "Биллди басып чыгаруу",
    servedBy: "Кызмат кылган",
    total: "Бардыгы",
    createdDate: "Түзүлгөн күнү",
    createdTime: "Түзүлгөн убакыт",
    status: "Статус",
    loginSuccessful: "Кирүү ийгиликтүү",
    pleaseWaitWhileWeConnectAccount:
      "Сураныч, каттоо эсебиңизди туташтыргыча күтө туруңуз",
    connectionFailedTryAgain: "Туташпай калды. Кайра аракет кылыңыз.",
    connectionSuccessful: "Байланыш ийгиликтүү",
    viewDetails: "Чоо -жайын көрүү",
    enable: "Иштетүү",
    free: "Бекер",
    cost: "Баасы",
    visitWebsite: "Вебсайтка баш багыңыз",
    pleaseUpgradeYourPlanToPro:
      "Сураныч, бул колдонмону иштетүү үчүн планыңызды Pro Plusке көтөрүңүз",
    connectYourBankAccount:
      "Төлөмдөрдү алуу үчүн Банк эсебиңизди туташтырыңыз.",
    disable: "Өчүрүү",
    enableApp: "Колдонмону иштетүү",
    addNewProductToInvoice: "Эсеп -фактурага Жаңы Продукцияны кошуңуз",
    toDeleteThisInvoice: "бул эсеп -фактураны жок кылуу үчүн",
    invoiceDeletedSuccessfully: "Эсеп -фактура ийгиликтүү өчүрүлдү!",
    deleteInvoice: "Эсеп -фактураны жок кылуу",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Сиз эсеп -фактураны төлөө билдирүүсүн ийгиликтүү коштуңуз!",
    pleaseEnterPaymentInstructions: "Төлөм көрсөтмөлөрүн киргизиңиз",
    invoiceId: "Эсеп -фактуранын идентификатору",
    paidDate: "Төлөнгөн дата",
    reference: "Шилтеме",
    productAdded: "Продукт кошулду",
    productOutOfStock: "Продукт кампада жок. Сураныч, кайра камдап коюңуз.",
    totalInvoices: "ЖАЛПЫ ЧАКЫРЫКТАР",
    totalPaidInvoices: "ЖАЛПЫ ТӨЛӨНГӨН ЧАКЫРЫКТАР",
    totalUnpaidInvoices: "ЖАЛПЫ ЖАРДАМСЫЗ ЧАКЫРЫКТАР",
    loyaltyProgramDeleted: "Лоялдуулук программасы өчүрүлдү",
    thisLoyaltyProgramDeletedSuccessfully:
      "Бул лоялдуулук программасы ийгиликтүү жок кылынды",
    thisLoyaltyProgramEditedSuccessfully:
      "Бул лоялдуулук программасы ийгиликтүү түзөтүлдү",
    loyaltyProgramAddedSuccessfully:
      "Лоялдуулук программасы ийгиликтүү кошулду",
    loyaltyProgramEdited: "Лоялдуулук программасы түзөтүлдү",
    loyaltyProgramAdded: "Лоялдуулук программасы кошулду",
    loyaltyDetails: "Лоялдуулуктун чоо -жайы",
    doYouWantToCloseDialog: "Бул диалогду жапкыңыз келеби?",
    pleaseEnterLoyaltyName: "Сураныч, берилгендигиңиздин атын киргизиңиз",
    programType: "Программанын түрү",
    pleaseSelectProgramType: "Программанын түрүн тандаңыз",
    simplePoints: "Жөнөкөй упайлар",
    stampsProgram: "Маркалар программасы",
    threshold: "Босого",
    pleaseEnterLoyaltyThreshold: "Лоялдуулуктун босогосун киргизиңиз",
    reward: "Сыйлык",
    pleaseEnterLoyaltyReward: "Сураныч, берилгендиктин сыйлыгын киргизиңиз",
    totalUserPoints: "Жалпы колдонуучу упайлары",
    totalUserStamps: "Жалпы колдонуучу маркалары",
    spendingTarget: "Максат сарптоо",
    spendingTargetHint1:
      "Чыгымдын максаты - бул сыйлык алуу үчүн кардар канча акча коротушу керек. 1 валютанын баасы = 1 упай.",
    spendingTargetHint2:
      "Маркалардын максаты - сыйлык алуу үчүн кардар канча марка чогултушу керек. мис. 5",
    addNewLoyaltyProgram: "Жаңы лоялдуулук программасын кошуу",
    addLoyaltyProgram: "Лоялдуулук программасын кошуу",
    loyaltyProgramType: "Лоялдуулук программасынын түрү",
    pleaseSelectLoyaltyProgramType: "Лоялдуулук программасынын түрүн тандаңыз",
    nameOfProgram: "Программанын аты",
    pleaseEnterProgramName: "Программанын атын киргизиңиз",
    whatIsTheReward: "Сыйлык деген эмне?",
    egNextPurchaseIsFree: "Мисалы Кийинки сатып алуу бекер",
    customerName: "Кардардын аты",
    guestCustomer: "Конок Кардар",
    orderConfirmedSuccessfully: "Заказ ийгиликтүү ырасталды",
    orderCancelledSuccessfully: "Буйрутма жокко чыгарылды",
    confirmOrder: "Заказды ырастаңыз",
    cancelOrder: "Заказды жокко чыгаруу",
    allOrders: "Бардык Заказдар",
    totalOrders: "ЖАЛПЫ ЗАКАЗДАР",
    doYouWantToAcceptOrder: "Бул буйрукту кабыл алгыңыз келеби?",
    doYouWantToCancelOrder: "Бул буйрутманы жокко чыгаргыңыз келеби?",
    orderDetails: "Заказдын чоо -жайы",
    orderCreatedAt: "Түзүлгөн тартип",
    supplier: "Жеткирүүчү",
    productName: "Продукт Аты",
    quantity: "Саны",
    unitPrice: "Бирдик баасы",
    receivedBy: "Алган",
    reportFrom: "Репортаж",
    totalSupplies: "Жалпы камсыздоо",
    allRightsReserved: "Бардык укуктар корголгон",
    toDeleteThisTransaction: "бул транзакцияны жок кылуу үчүн",
    transactionDeletedSuccessfully:
      "Транзакция ийгиликтүү өчүрүлдү. Акциялар инвентаризацияга кайтарылды.",
    deleteTransaction: "Транзакцияны жок кылуу",
    transactionDetails: "Транзакциянын чоо -жайы",
    printReceipt: "Басып алуу дүмүрчөгү",
    channel: "Канал",
    discount: "Арзандатуу",
    profit: "Profit",
    discountedSales: "Арзандатылган сатуу",
    errorFetchingRecord: "Жазууну алуу катасы",
    exportTransactions: "Экспорттук бүтүмдөр",
    errorFetchingSalesRecord: "Экспорттоо үчүн Сатуу рекордун алуу катасы.",
    totalSellingPrice: "Жалпы сатуу баасы",
    totalCostPrice: "Жалпы нарк баасы",
    appliedDiscount: "Колдонулган арзандатуу",
    noOfItems: "Заттардын саны",
    sales: "Сатуу",
    export: "Экспорт",
    totalProfit: "Жалпы киреше",
    totalBalanceInPeriod: "Мезгилдеги жалпы баланс",
    allTimeSales: "Бардык убакта сатуу",
    records: "Records",
    todaysSales: "Бүгүнкү сатуу",
    transaction: "бүтүм",
    youHaveExceededTotalNumberOfProducts:
      "Планда уруксат берилген продукциянын жалпы санынан аштыңыз. Планыңызды жогорулатып, жогорку чекке ээ болуңуз.",
    youNeedToHaveLoyaltyProgram:
      "Бул мүмкүнчүлүктү колдонуу үчүн лоялдуулук программасы болушу керек!",
    price: "Баасы",
    category: "Категория",
    stockTracking: "Stock Tracking",
    stockCount: "Stock Count",
    taxed: "Салык",
    originalPrice: "Оригинал баасы",
    costPrice: "Баасы",
    unit: "Unit",
    productImage: "Продукт сүрөтү",
    taxRate: "Салык ставкасы",
    taxType: "Салыктын түрү",
    trackInventory: "Track Inventory",
    variants: "Варианттар",
    hasVariants: "Варианттары бар",
    importProduct: "Продукцияны импорттоо",
    exportProducts: "Продуктыларды Экспорттоо",
    addNewProduct: "Жаңы продукт кошуу",
    viewCategory: "Категорияны көрүү",
    viewSuppliers: "Жеткирүүчүлөрдү көрүү",
    receiveInventory: "Инвентаризация алуу",
    printAllProductsTag: "Бардык продуктуларды тегин басып чыгаруу",
    deleteAll: "Баарын жок кылуу",
    totalProducts: "TOTAL PRODUCTS",
    youveSuccessfullyAddedANewCategory: "Жаңы категорияны ийгиликтүү коштуңуз",
    addNewCategory: "Жаңы категория кошуу",
    addCategory: "Категория кошуу",
    categoryName: "Категориянын аты",
    pleaseEnterCategoryName: "Сураныч, категориянын атын киргизиңиз",
    stampsTarget: "Маркалар Максаты",
    sendInventory: "Инвентаризация жөнөтүү",
    productDetails: "Продукциянын чоо -жайы",
    youveSuccessfullyEditedThisCategory:
      "Бул категорияны ийгиликтүү түзөттүңүз",
    update: "Жаңыртуу",
    categoryList: "Категория тизмеси",
    categories: "Категориялар",
    enterQuantityToUpdate: "Жаңыртуу үчүн көлөмдү киргизиңиз",
    inventorySentSuccessfully: "Инвентаризация ийгиликтүү жөнөтүлдү!",
    updateInventory: "Тизмени жаңыртуу",
    currentQuantity: "Учурдагы Саны",
    pleaseEnterQuantityToAdd: "Сураныч, кошкуңуз келген санды киргизиңиз",
    pleaseSelectABranch: "Сураныч, Филиалды тандаңыз",
    searchForBranch: "Филиалды издөө",
    youCantSendMoreThanStock: "Сиз кампада болгондон ашык жөнөтө албайсыз",
    send: "Жөнөтүү",
    pleaseEnterQuantityToSend: "Сураныч, жөнөткүңүз келген сумманы киргизиңиз",
    productNameIsRequiredOnRow: "продукттун аты катарында талап кылынат",
    productCategoryIsRequiredOnRow:
      "продукт категориясы катарында талап кылынат",
    productPriceIsRequiredOnRow: "продукт баасы кезекте талап кылынат",
    productUnitIsRequiredOnRow: "продукт бирдиги катарында талап кылынат",
    productQuantityIsRequiredOnRow: "продукт саны катарында талап кылынат",
    productVariantsRequireTracking:
      "продукт варианттары көзөмөлдөөнү талап кылат!",
    pleaseAddVariantClickButton:
      "Кошуу баскычын чыкылдатып, вариантын кошуңуз!",
    totalVariantsMoreThanSelectedQuantity:
      "Жалпы варианттар тандалган продукциянын санынан көп, Pls варианттын санын азайтат",
    productEditedSuccessfully: "Продукт ийгиликтүү түзөтүлдү!",
    fileTooLargeLessThan4Mb:
      "Файлдын өлчөмү өтө чоң! Файлдын өлчөмү 4 Мб аз болушу керек.",
    suchVariantAlreadyExist: "Мындай вариант мурунтан эле бар",
    addVariants: "Варианттарды кошуу",
    editProduct: "Продукцияны түзөтүү",
    pleaseEnterProductName: "Сураныч, Продукт Атын киргизиңиз",
    pleaseEnterProductPrice: "Сураныч, Продукт Баасын киргизиңиз",
    pleaseEnterProductOriginalPrice:
      "Сураныч, Продукциянын баштапкы баасын киргизиңиз",
    productDescription: "Продукт Description",
    selectProductCategory: "Продукт Категориясын тандаңыз",
    pleaseSelectProductCategory: "Сураныч, продукт категориясын тандаңыз",
    productCostPrice: "Продукциянын наркы",
    productSellingPrice: "Продукцияны сатуу баасы",
    productOriginalPrice: "Продукциянын баштапкы баасы",
    maxFileSizeAllowedIs4Mb: "Макс. Файлдын өлчөмү - 4 Мб",
    productsWithPicturesArePublished:
      "Буйрутмаларды алуу үчүн сүрөттөрү бар продукциялар Discoverде жарыяланат",
    shouldThisProductBeTracked: "Бул продукт көзөмөлгө алынышы керекпи?",
    pleaseSelectStockUnit: "Сураныч, акция бирдигин тандаңыз",
    stockUnit: "Сток бирдиги",
    bag: "BAG",
    bottle: "Бөтөлкө",
    bunch: "БАНЧ",
    can: "БОЛОТ",
    carton: "КАРТОН",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "ПАК",
    pair: "ЖУБ",
    pieces: "БОЛУКТАР",
    plate: "ПЛИТА",
    tonne: "ТОННА (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Сураныч, продукттун санын киргизиңиз",
    productQuantity: "Продукт саны",
    isThisProductTaxed: "Бул продукцияга салык салынабы?",
    selectTaxType: "Салык түрүн тандаңыз",
    pleaseSelectTaxType: "Салык түрүн тандаңыз",
    progressive: "Прогрессивдүү",
    proportional: "Пропорционалдуу",
    pleaseEnterProductTaxRate:
      "Сураныч, продукциянын салык ставкасын киргизиңиз",
    doesProductHaveVariants: "Бул продукттун варианттары барбы?",
    type: "Түрү",
    value: "Мааниси",
    pleaseEnterVariantType: "Варианттын түрүн киргизиңиз",
    pleaseEnterVariantValue: "Варианттын маанисин киргизиңиз",
    pleaseEnterVariantPrice: "Варианттын баасын киргизиңиз",
    pleaseEnterVariantQuantity: "Варианттын санын киргизиңиз",
    productDeletedSuccessfully: "Продукт ийгиликтүү өчүрүлдү!",
    categoryDeletedSuccessfully: "Категория ийгиликтүү өчүрүлдү!",
    toDeleteThisProduct: "бул Продукцияны жок кылуу",
    invalidProductQuantity: "Продукциянын саны жараксыз",
    quantityAddedIsOverStock:
      "Сиз кошуп жаткан сан кампаңызда бар нерседен ашып түштү.",
    itemInventoryNotTracked: "Элемент инвентаризациясына көз салынган жок",
    addBulkQuantity: "Жапырт санды кошуу",
    enterBulkQuantity: "Жапырт санды киргизиңиз",
    pleaseEnterBulkQuantity: "Сураныч, жапырт санды киргизиңиз",
    youveSuccessfullyAddedANewProduct: "Сиз жаңы өнүмдү ийгиликтүү коштуңуз!",
    pleaseEnterProductSellingPrice: "Продукциянын сатуу баасын киргизиңиз",
    doYouWantToTrackProductStock:
      "Продукциянын запасын көзөмөлдөөнү каалайсызбы?",
    sellingPrice: "Сатуу баасы",
    setProductExpiryReminder: "Продукттун мөөнөтү жөнүндө эстеткичти коюңуз",
    productExpiryDate: "Продукциянын жарактуулук мөөнөтү",
    startRemindingFrom: "Эске алууну баштаңыз",
    productSuppliesAddedSuccessfully:
      "Продукциянын материалдарын ийгиликтүү коштуңуз.",
    addProductSupplies: "Продукт жабдууларын кошуу",
    pleaseSelectSupplier: "Сураныч, камсыздоочуну тандаңыз",
    nameOfProduct: "Буюмдун аталышы",
    pleaseSelectProduct: "Сураныч, Продукцияны тандаңыз",
    productVariant: "Продукт варианты",
    pleaseSelectAVariant: "Сураныч, Variant тандаңыз",
    pleaseEnterUnitPrice: "Сураныч, бирдик баасын киргизиңиз",
    businessBranch: "Бизнес филиалы",
    pleaseSelectBranch: "Сураныч, филиалды тандаңыз",
    youveSuccessfullyAddedANewSupplier: "Жаңы жеткирүүчү кошулду",
    addSupplier: "Жеткирүүчү кошуу",
    pleaseEnterSupplierEmail:
      "Сураныч, жеткирүүчүнүн электрондук почтасын киргизиңиз",
    pleaseAddADescription: "Сураныч, сүрөттөмө кошуңуз",
    anErrorOccuredProductsDeleted:
      "Операцияны аткарууда ката кетти. Көңүл буруңуз, бул процессте бир нече продукт өчүрүлгөн болушу мүмкүн",
    bulkDelete: "Жапырт жок кылуу",
    youAreAboutToDelete: "Сиз жок кылганы турасыз",
    product: "Продукт",
    isDueToRedeem: "кунуно байланыштуу болот",
    aReward: "сыйлык",
    pleaseSelectCustomerToReeemReward: "Сыйлыкты алуу үчүн кардарды тандаңыз.",
    youHaveNoLoyaltyProgramRunning:
      "Сизде активдүү лоялдуулук программасы иштебейт",
    customerHhasNoPointsInLoyaltyProgram:
      "Кардар бул лоялдуулук программасында эч кандай упайга ээ эмес",
    proceedWithPayment: "Төлөм менен уланта бересизби?",
    youAreAboutToPayForTransactionUsingPoints:
      "Упайларды колдонуу менен транзакция үчүн төлөйсүз.",
    customerHas: "Кардар бар",
    worth: "татыктуу",
    andIsNotSufficientToPayForTransaction:
      "жана бул транзакцияны төлөө үчүн жетишсиз. Алар башка төлөм ыкмасын колдонуу менен балансты кошкусу келеби?",
    addCustomerLoyalty: "Кардарлардын берилгендигин кошуңуз",
    pleaseAddCustomerFirst: "Сураныч, биринчи кардарды кошуңуз же тандаңыз.",
    pleaseWaitWhileWeGetReady: "Сураныч, биз даярданып жатканда күтө туруңуз",
    lowStock: "Төмөн Сток",
    pleaseEnterAmountTendered: "Сураныч, сунушталган сумманы киргизиңиз",
    areYouSureToBookSaleOffline: "Бул сатууну оффлайнда заказ кылгыңыз келеби?",
    saleWouldBeBookedAutomatically:
      "Интернетти күйгүзгөнүңүздө сатуу автоматтык түрдө брондолот",
    offlineSalesBookingCancelled:
      "Оффлайн сатуу боюнча брондоо жокко чыгарылды",
    covid19Message:
      "COVID19: Таралышын токтотуу үчүн колуңузду самын менен жууңуз же тазалаңыз. Ар дайым аман болуңуз",
    saleSuccessfullyRecorded: "Сатуу ийгиликтүү жазылды!",
    sendReceiptToEmail: "Электрондук почтага дүмүрчөктү жөнөтүү",
    sendThankYouSms: "Рахмат SMS жөнөтүү",
    sendShippingDetails: "Жеткирүү маалыматын жөнөтүү",
    addLoyalty: "Лоялдуулукту кошуу",
    searchCustomerNameOrNumber: "Кардардын атын же номерин издеңиз",
    clickTheSearchCustomerBox:
      "Издөө Кардар кутучасын жана Скандоо картасын басыңыз",
    enterProductPrice: "Продукциянын баасын киргизиңиз",
    enterPriceFor: "Баасын киргизиңиз",
    searchForProduct: "Продукцияны издөө",
    all: "Баары",
    backToDashboard: "Тактага кайтуу",
    viewDraftSales: "Долбоордун сатуусун көрүү",
    variantSelected: "вариант тандалды",
    variant: "вариант",
    thePreviousVariantSelectionNotAvailable:
      "Мурунку вариант тандоосу баанын негизинде тандалган жаңы вариант үчүн жеткиликтүү эмес, тандооңузду өзгөртүңүз.",
    pleaseSupplyPositiveQuantityNumber: "Позитивдүү сан номерин бериңиз",
    lowStockFor: "Үчүн запасы аз",
    clearVariants: "Таза варианттар",
    pleaseEnterQuantity: "Сураныч, Саны киргизиңиз",
    picture: "Сүрөт",
    redemptionToken: "Redemption Token",
    token: "Токен",
    totalSpent: "Жалпы сарпталган",
    confirmPayment: "Төлөмдү ырастаңыз",
    hasPaymentBeenMade: "Төлөм ийгиликтүү иштетилдиби?",
    enterTransactionReference:
      "Сиз төлөгөн транзакциянын маалымдамасын киргизиңиз",
    pleaseSelectACustomer: "Сураныч, кардарды тандаңыз!",
    areYouSureToApplyDiscount: "Чын эле арзандатууну колдоносузбу?",
    addYourBankAccountToEnableUssd:
      "UPay аркылуу USSD тез которууну иштетүү үчүн банк эсебиңизди кошуңуз",
    totalAmountToPay: "Төлөөнүн жалпы суммасы",
    doYouWantToCancelTransaction: "Бул транзакцияны жокко чыгаргыңыз келеби?",
    savePrintBill: "Биллди сактоо/басып чыгаруу",
    enterAmountCollectedForCustomer:
      "Кардар үчүн чогултулган сумманы киргизиңиз",
    recordSale: "Record Sale",
    checkOutWith: "Менен текшерүү",
    instantTransfer: "Ыкчам которуу",
    dialTheUSSDCode: "USSD кодун териңиз",
    pleaseSelectABank: "Банкты тандаңыз",
    payWithUSSD: "USSD менен төлөө",
    sendBillTo: " - Биллди жөнөт",
    waitingForUSSDTransfer: "USSD которуу күтүлүүдө",
    percent: "Пайыз",
    applyDiscount: "Арзандатууну колдонуу",
    thisBillHasBeenSaved: "Бул Билл сакталды",
    saveDraft: "Долбоорду сактоо",
    pleaseTypeANameToIdentifyCustomer: "Кардарды аныктоо үчүн атын жазыңыз",
    paymentDetails: "Төлөмдүн чоо -жайы",
    basePrice: "Негизги баа",
    staff: "Штаб",
    subTotal: "SubTotal",
    durationMonths: "Узактыгы (айлар)",
    selectADuration: "Узактыкты тандоо",
    oneMonth: "1 ай",
    twoMonths: "2 ай",
    threeMonths: "3 ай",
    sixMonths: "6 ай",
    twelveMonths: "12 ай",
    eighteenMonths: "18 Ай",
    twentyFourMonths: "24 Ай",
    twoMonthsFree: "(2 ай бекер)",
    threeMonthsFree: "(3 ай бекер)",
    fiveMonthsFree: "(5 ай бекер)",
    yourAccountHasBeen: "Сиздин эсебиңиз болду",
    renewed: "жаңыртылды",
    upgraded: "жаңыртылды",
    successfully: "ийгиликтүү",
    yourSubscription: "Сиздин жазылууңуз",
    youAreCurrentlyEnrolledOnA: "Сиз азыр a",
    pleaseChooseAPaymentOption: "Сураныч, төлөм опциясын тандаңыз",
    planRenewal: "Пландын узартылышы",
    planUpgrade: "Планды жогорулатуу",
    pleaseSelectDurationToPayFor:
      "Сураныч, сиз төлөй турган узактыгыңызды тандаңыз",
    staffAccounts: "Кызматкерлердин эсептери",
    ecommerce: "Электрондук соода",
    pleaseSelectAPlan: "План тандаңыз",
    includeAddons: "Кошумчаларды кошуңуз",
    viewTransactions: "Транзакцияларды көрүү",
    customerHasNoCompletedTansactions: "Кардар азырынча аягына чыга элек",
    branch: "Филиал",
    enterNumberOfEcommerceBranches:
      "Электрондук соода филиалдарынын санын киргизиңиз",
    enterNumberOfEcommerceBranchesToPay:
      "Сиз төлөй турган электрондук коммерциянын филиалдарынын санын киргизиңиз",
    ecommerceIntegration: "Электрондук соода интеграциясы",
    enterNumberOfBranches: "Филиалдардын санын киргизиңиз",
    enterNumberOfAdditionalBranchesToPay:
      "Сиз төлөй турган кошумча филиалдардын санын киргизиңиз",
    enterNumberOfStaffs: "Кадрлардын санын киргизиңиз",
    enterNumberOfStaffsToPayFor:
      "Сиз төлөй турган кызматкерлердин санын киргизиңиз",
    discountApplies: "Арзандатуу колдонулат",
    starsOnThe: "жылдыздар",
    offer: "сунуш",
    get: "Алуу",
    moreStarsToRedeem: "сатып алуу үчүн дагы жылдыздар",
    taxVat: "Салык (КНС)",
    callCashierToCompletePayment: "Төлөмдү аяктоо үчүн Кассирге чалыңыз",
    receipt: "Дүмүрчөк",
    dear: "Урматтуу",
    thankYouForYourOrderFindGoods:
      "Тапшырыгыңыз үчүн рахмат. Сураныч, келишилгендей, берилген төмөнкү товарларды табыңыз.",
    shippingNote: "Жеткирүү Эскертүүсү",
    enterShippingNote: "Жеткирүү Эскертүүсүн киргизиңиз",
    shippingAddress: "Жеткирүү дареги",
    enterShippingAddress: "Жеткирүү дарегин киргизиңиз",
    wellDoneYouAreDueToRedeem: "Мыкты жасалды! Сиз кун төлөйсүз",
    toGetYourRewardNextVisit: "кийинки сапарыңызда сыйлык алуу үчүн. Рахмат",
    pointsOnThe: "Боюнча упайлар",
    morePointsToRedeem: "сатып алуу үчүн көбүрөөк упайлар",
    showCode: "Кодду көрсөтүү",
    toGetYourRewardOnNextVisit: "кийинки сапарыңызда сыйлык алуу үчүн. Рахмат",
    earn: "Табуу",
    delivaryNote: "Жеткирүү нотасы",
    draftSales: "Долбоорду сатуу",
    startDate: "Башталган күнү",
    endDate: "Аяктоо күнү",
    orders: "Заказдар",
    checkout: "текшерүү",
    noProductItem: "Продукт жок",
    selectProductImage: "Продукт сүрөтүн тандоо",
    selectCountry: "Өлкөнү тандоо",
    selectRegion: "Штат/Регион тандаңыз",
    printProductTag: "Продукт тегин басып чыгаруу",
    transactionReference: "Транзакция маалымдамасы",
    Cashier: "Кассир",
    Manager: "Менеджер",
    Owner: "Ээси",
    Admin: "Admin",
    addPartners: "Өнөктөштөрдү кошуу",
    addNewLoyaltyPartner: "Жаңы ишенимдүү өнөктөш кошуу",
    pleaseEnterCompanyName: "Компаниянын атын киргизиңиз",
    companyName: "компаниянын аталышы",
    pleaseEnterCompanyRepName: "Сураныч, компаниянын өкүлүнүн атын киргизиңиз",
    companyRepName: "Компания өкүлүнүн аты-жөнү",
    pleaseEnterCompanyRepEmail:
      "Сураныч, компания өкүлүнүн электрондук почтасын киргизиңиз",
    companyRepEmail: "Компаниянын өкүлүнүн электрондук почтасы",
    pleaseEnterCompanyRepPhone:
      "Сураныч, компания өкүлүнүн телефон номерин киргизиңиз",
    companyRepPhone: "Компания өкүлүнүн телефон номери",
    addReward: "Сыйлык кошуу",
    pleaseEnterRewardName: "Сураныч, сыйлыктын атын киргизиңиз",
    rewardName: "Сыйлык аты",
    rewardQuantity: "Сыйлык саны",
    rewardDescription: "Сыйлык Description",
    rewardType: "Сыйлык түрү",
    pleaseEnterRewardType: "Сыйлык түрүн киргизиңиз",
    pleaseEnterRewardQuantity: "Сураныч, сыйлыктын санын киргизиңиз",
    pleaseEnterRewardDescription: "Сураныч, сыйлык сүрөттөмөсүн киргизиңиз",
    fineDining: "Fine Dining",
    luxuryFashion: "Luxury Fashion",
    hotels: "мейманканалар",
    travel: "Саякат",
    foodAndBeverage: "Тамак-аш жана суусундук",
    fashion: "Мода",
    health: "Ден соолук",
    furniture: "Эмерек",
    entertainment: "Көңүл ачуу",
    automobile: "Автомобиль",
    education: "Билим берүү",
    beautyAndSpa: "Сулуулук жана спа",
    staycation: "Туруу",
    events: "Окуялар",
    trips: "Сапарлар",
    oilAndGas: "Май жана газ",
    laundry: "Кир жуугуч",
    partnerCategory: "Өнөктөш категориясы",
    freeItem: "Free Item",
  },
  Khmer: {
    cashier: "អ្នកគិតលុយ",
    manager: "អ្នកគ្រប់គ្រង",
    owner: "ម្ចាស់",
    online: "លើអ៊ីនធឺណិត",
    offline: "ក្រៅបណ្តាញ",
    changePassword: "ផ្លាស់ប្តូរលេខសម្ងាត់",
    currentPasswordMessage: "សូមបញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្នរបស់អ្នក",
    passwordMessage: "សូម​បញ្ចូល​ពាក្យ​សម្ងាត់​របស់​អ្នក",
    currentPassword: "លេខសំងាត់​បច្ចុប្បន្ន",
    password: "ពាក្យសម្ងាត់",
    confirmPasswordMessage: "សូមបញ្ជាក់លេខសំងាត់របស់អ្នក!",
    confirmPassword: "បញ្ជាក់លេខសំងាត់",
    sendViaEmail: "ផ្ញើតាមអ៊ីមែល",
    sendViaWhatsapp: "ផ្ញើតាម WhatsApp",
    downloadPdf: "ទាញយកជា PDF",
    paid: "បានបង់",
    unpaid: "មិនបានបង់ប្រាក់",
    partial: "មួយផ្នែក",
    closeInvoice: "តើអ្នកចង់បិទវិក្កយបត្រទេ?",
    closeInvoiceConfirmation:
      "វិក្កយបត្រប្រហែលជាមិនត្រូវបានរក្សាទុកទេ។ តើអ្នកចង់បិទទេ?",
    yes: "បាទ",
    no: "ទេ",
    invoice: "វិក្កយបត្រ",
    wasDeducted: "ត្រូវបានកាត់កង",
    for: "សម្រាប់",
    item: "ធាតុ",
    addProduct: "បន្ថែមផលិតផល",
    paymentReference: "ឯកសារយោងទូទាត់",
    amountPaid: "ចំនួនទឹកប្រាក់ដែលបានបង់",
    discountAmount: "ចំនួនបញ្ចុះតម្លៃ",
    amountDue: "ចំនួនដែលត្រូវបង់",
    amount: "ចំនួនទឹកប្រាក់",
    dueDate: "កាលបរិច្ឆេទ​កំណត់",
    paymentType: "ប្រភេទនៃការទូទាត់",
    card: "កាត",
    cash: "សាច់ប្រាក់",
    bankTransfer: "ការផ្ទេរប្រាក់តាមធនាគារ",
    paymentMessage: "សារទូទាត់",
    description: "ការពិពណ៌នា",
    sendReceipt: "ផ្ញើបង្កាន់ដៃ",
    delete: "លុប",
    save: "រក្សាទុក",
    resend: "បញ្ជូនម្តងទៀត",
    saveAndSend: "រក្សាទុក",
    invoiceSaved: "វិក្កយបត្របានរក្សាទុក!",
    selectPaymentMethod: "សូមជ្រើសរើសវិធីបង់ប្រាក់!",
    selectCustomer: "សូមជ្រើសរើសអតិថិជន!",
    cartEmptyError: "បញ្ជីរទេះមិនអាចទទេបានទេបិទវិក្កយបត្រហើយបន្ថែមធាតុទៅរទេះ!",
    subscriptionExpired:
      "ការជាវរបស់អ្នកបានផុតកំណត់ហើយឥឡូវនេះគណនីរបស់អ្នកមានកំណត់។ ចុចលើប៊ូតុងខាងក្រោមដើម្បីបន្តគណនីរបស់អ្នកឡើងវិញ",
    renew: "បន្តជាថ្មី",
    holdOn: "ចាំ​បន្តិច",
    customerBank: "ធនាគារអតិថិជន",
    cancel: "បោះបង់",
    selectACustomer: "ជ្រើសរើសអតិថិជន",
    invoiceSuccessfulPdfError:
      "វិក័យប័ត្រត្រូវបានបង្កើតដោយជោគជ័យប៉ុន្តែការបង្កើតឯកសារ PDF ចំណាយពេលយូរជាងធម្មតា។ សូមពិនិត្យឡើងវិញក្នុងពេលឆាប់ៗនេះ។",
    downloadingInvoice: "កំពុងទាញយកវិក្កយបត្រ",
    downloadingReceipt: "ទាញយកបង្កាន់ដៃ",
    whatsappReceiptError:
      "មានបញ្ហាកើតឡើងនៅពេលផ្ញើវិក័យប័ត្រតាមរយៈ WhatsApp សូមព្យាយាមម្តងទៀត។",
    receiptToWhatsapp: "បង្កាន់ដៃបញ្ជូនបន្តទៅ WhatsApp",
    thankYouForPatronage: "សូមអរគុណចំពោះការគាំទ្ររបស់អ្នក",
    hereIsYourReceipt: "នេះគឺជាបង្កាន់ដៃបង់ប្រាក់របស់អ្នក",
    errorSendingEmailReceipt:
      "មានបញ្ហាកើតឡើងនៅពេលផ្ញើវិក័យប័ត្រតាមរយៈអ៊ីមែលសូមព្យាយាមម្តងទៀតឬទាក់ទងផ្នែកគាំទ្រ",
    receiptSentToEmail: "បង្កាន់ដៃត្រូវបានផ្ញើទៅអ៊ីមែលរបស់អតិថិជន",
    invoiceSentToEmail: "វិក្កយបត្រត្រូវបានផ្ញើទៅអ៊ីមែលរបស់អតិថិជន",
    invoiceSuccessWhatsappError:
      "វិក័យប័ត្រត្រូវបានបង្កើតដោយជោគជ័យប៉ុន្តែមានកំហុសកើតឡើងនៅពេលផ្ញើទៅ WhatsApp ។ សូមព្យាយាមម្តងទៀតនៅក្នុងបញ្ជីវិក្កយបត្រ",
    invoiceSuccessfulEmailError:
      "បានបង្កើតវិក្កយបត្រដោយជោគជ័យប៉ុន្តែមានកំហុសមួយកើតឡើងនៅពេលផ្ញើជាអ៊ីមែល។ សូមព្យាយាមម្តងទៀតពីបញ្ជីវិក្កយបត្រ",
    invoiceSentToWhatsapp: "វិក័យប័ត្រត្រូវបានបញ្ជូនបន្តទៅ WhatsApp",
    hello: "សួស្តី",
    pleaseDownloadInvoice: "សូមទាញយកវិក្កយបត្រ",
    pleaseDownloadReceipt: "សូមទាញយកបង្កាន់ដៃ",
    from: "ពី",
    email: "អ៊ីមែល",
    upgrade: "ធ្វើឱ្យប្រសើរឡើង",
    youAreOnFreePlan: "អ្នកស្ថិតនៅលើផែនការឥតគិតថ្លៃ។",
    clickOn: "ចុចលើ",
    yourPlanInFewSteps: " ការជាវរបស់អ្នកក្នុងជំហានរហ័សពីរបី",
    to: "ទៅ",
    yourSubscriptionHasExpired:
      "ការជាវរបស់អ្នកបានផុតកំណត់ហើយឥឡូវនេះគណនីរបស់អ្នកមានកំណត់។",
    days: "ថ្ងៃ",
    yourSubscriptionExpiresIn: "ការជាវ Loystar របស់អ្នកផុតកំណត់នៅ",
    createAcount: "បង្កើត​គណនី",
    signIn: "ចូល",
    continue: "បន្ត",
    enterOtp: "បញ្ចូលលេខកូដសម្ងាត់ OTP",
    enterValidOtp: "សូមបញ្ចូលកូដ PIN ដែលត្រឹមត្រូវ",
    pin: "កូដ PIN",
    tokenSentToMail: "ថូខឹនត្រូវបានផ្ញើទៅអ៊ីមែលរបស់អ្នក",
    passwordResetSuccessful: "ការកំណត់ពាក្យសម្ងាត់ឡើងវិញបានជោគជ័យ",
    somethingWentWrong: "មាន​អ្វីមួយ​មិន​ប្រក្រតី!",
    resetPassword: "កំណត់ពាក្យសម្ងាត់ឡើងវិញ",
    iHaveResetCode: "ខ្ញុំមានលេខសម្ងាត់កំណត់ឡើងវិញ",
    pleaseEnterEmail: "សូមបញ្ចូលអ៊ីមែលរបស់អ្នក",
    aTokenWillBeSentToEmail: "ថូខឹននឹងត្រូវបានផ្ញើទៅអ៊ីមែលរបស់អ្នក",
    enterEmail: "បញ្ចូលអ៊ីមែលរបស់អ្នក",
    sendinYourToken: "កំពុងផ្ញើថូខឹនរបស់អ្នក ...",
    makeSureItMatchesPassword: "សូមប្រាកដថាវាត្រូវនឹងពាក្យសម្ងាត់ថ្មីរបស់អ្នក",
    pleaseChooseNewPassword: "សូមជ្រើសរើសពាក្យសម្ងាត់ថ្មី",
    chooseNewPassword: "ជ្រើសរើសពាក្យសម្ងាត់ថ្មី",
    enterNewPassword: "បញ្ចូលពាក្យសម្ងាត់ថ្មីរបស់អ្នកដើម្បីបញ្ជាក់",
    enterTokenSent: "បញ្ចូលថូខឹនដែលបានផ្ញើតាមអ៊ីមែលរបស់អ្នក",
    resetToken: "កំណត់ថូខឹនឡើងវិញ",
    resettingPassword: "កំពុងកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញ ...",
    signUp: "ចុះ​ឈ្មោះ",
    adminSignInWithEmail:
      " អ្នកគ្រប់គ្រងចូលជាមួយអ៊ីមែលខណៈបុគ្គលិកចូលដោយប្រើឈ្មោះអ្នកប្រើ។",
    pleaseEnterEmailOrUsername: "សូមបញ្ចូលអ៊ីមែលឬឈ្មោះអ្នកប្រើរបស់អ្នក",
    emailOrUsername: "អ៊ីមែលឬឈ្មោះអ្នកប្រើ",
    pleaseEnterPassword: "សូមបញ្ចូលពាក្យសម្ងាត់",
    createAnAccount: "បង្កើត​គណនី",
    forgotPassword: "ភ្លេច​លេខសំងាត់​?",
    enterPhoneNumber: "បញ្ចូលលេខទូរស័ព្ទ",
    personalData: "ទិន្នន័យ​ផ្ទាល់ខ្លួន",
    validateConfirmationCOde: "ធ្វើឱ្យមានសុពលភាពលេខកូដបញ្ជាក់",
    pleaseEnterFirstName: "សូមបញ្ចូលនាមខ្លួនរបស់អ្នក",
    pleaseEnterPhoneNumber: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
    pleaseEnterLastName: "សូមបញ្ចូលនាមត្រកូលរបស់អ្នក",
    pleaseEnterBusinessName: "សូមបញ្ចូលឈ្មោះអាជីវកម្មរបស់អ្នក",
    firstName: "នាមខ្លួន",
    lastName: "នាមត្រកូល",
    businessName: "ឈ្មោះ​អាជីវកម្ម",
    previous: "មុន",
    next: "បន្ទាប់",
    pleaseSelectBusinessCategory: "សូមជ្រើសរើសប្រភេទអាជីវកម្មរបស់អ្នក",
    pleaseEnterValidEmail: "សូមបញ្ចូលអ៊ីមែលដែលត្រឹមត្រូវ",
    pleaseEnterPostCode: "សូមបញ្ចូលលេខកូដប្រៃសណីយ៍",
    postCode: "លេខកូដប្រៃសណីយ៍",
    phoneNumberInUse: "លេខទូរស័ព្ទនេះកំពុងប្រើរួចហើយ!",
    emailInUse: "អ៊ីមែលនេះត្រូវបានប្រើរួចហើយ!",
    foodAndDrinks: "អាហារ​និង​ភេសជ្ជៈ",
    salonAndBeauty: "សាឡននិងសម្ផស្ស",
    fashionAndAccessories: "ម៉ូតនិងគ្រឿងបន្ថែម",
    gymAndFitness: "កន្លែងហាត់ប្រាណនិងកាយសម្បទា",
    travelAndHotel: "ទេសចរណ៍និងសណ្ឋាគារ",
    homeAndGifts: "ផ្ទះនិងអំណោយ",
    washingAndCleaning: "លាងសម្អាតនិងសម្អាត",
    gadgetsAndElectronics: "ឧបករណ៍និងអេឡិចត្រូនិក",
    groceries: "គ្រឿងទេស",
    others: "អ្នកផ្សេងទៀត",
    submit: "បញ្ជូន",
    accountCreatedSuccessful: "គណនីរបស់អ្នកត្រូវបានបង្កើតដោយជោគជ័យ។",
    pleaseEnterAddress: "សូមបញ្ចូលអាសយដ្ឋានរបស់អ្នក",
    addressLine1: "អាសយដ្ឋាន​បន្ទាត់​ទី 1",
    addressLine2: "អ​ស​យ​ដ្ឋាន​ខ្សែ​រ​ទី 2",
    choosePassword: "ជ្រើសរើស​លេខសំងាត់",
    passwordMustBe6Characters: "ពាក្យសម្ងាត់ត្រូវមានយ៉ាងហោចណាស់ ៦ តួ។",
    howDidYouHearLoystar: "តើអ្នក hear អំពីឡូយស្តារយ៉ាងដូចម្តេច?",
    referralCode: "លេខកូដបញ្ជូន",
    byClickingTheButton: " ដោយចុចប៊ូតុងខាងក្រោមអ្នកយល់ព្រមតាម",
    termsAndSevice: "លក្ខខណ្ឌ",
    wereCreatingAccount: "យើងកំពុងបង្កើតគណនីរបស់អ្នក",
    proceed: "បន្ត",
    verificationCodeMustBe6: "លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែមាន ៦ ខ្ទង់",
    pleaseEnter6DigitCode: "សូមបញ្ចូលលេខកូដ ៦ ខ្ទង់",
    enterVerificationCode: "សូម​បញ្ចូល​កូដ",
    resendToken: "ផ្ញើថូខឹនឡើងវិញ",
    verify: "ផ្ទៀង​ផ្ទាត់",
    transactions: "ប្រតិបត្តិការ",
    todaySales: "ការលក់ថ្ងៃនេះ",
    salesHistory: "ប្រវត្តិនៃការលក់",
    supplyHistory: "ប្រវត្តិផ្គត់ផ្គង់",
    new: "ថ្មី",
    invoices: "វិក្កយបត្រ",
    disbursements: "ការទូទាត់",
    offlineSales: "ការលក់ក្រៅបណ្តាញ",
    products: "ផលិតផល",
    customers: "អតិថិជន",
    multiLevelLoyalty: "ភាពស្មោះត្រង់ពហុកម្រិត",
    loyaltyPrograms: "កម្មវិធីភក្ដីភាព",
    members: "សមាជិក",
    appStore: "App Store",
    orderMenu: "ម៉ឺនុយបញ្ជាទិញ",
    settings: "ការកំណត់",
    staffAndBranches: "បុគ្គលិកនិងសាខា",
    myAccount: "គណនី​របស់ខ្ញុំ",
    switchToSellMode: "ប្តូរទៅរបៀបលក់",
    signOut: "ចាកចេញ",
    getFreeSubscription: "ទទួលបានការជាវឥតគិតថ្លៃ",
    onlyNumbersAllowed: "មានតែលេខប៉ុណ្ណោះដែលត្រូវបានអនុញ្ញាត",
    yourAccountMustBe10Digits: "លេខគណនីរបស់អ្នកត្រូវតែជាលេខ ១០ ខ្ទង់",
    yourBvnMustBe11: "BVN របស់អ្នកត្រូវតែជាលេខ ១១ ខ្ទង់",
    pleaseSelectBank: "សូមជ្រើសរើសធនាគាររបស់អ្នក",
    selectYourBank: "ជ្រើសរើសធនាគាររបស់អ្នក",
    enterBankAccountNumber: "បញ្ចូលលេខគណនីធនាគារ",
    enterBvnNumber: "បញ្ចូល BVN របស់អ្នក",
    connectBankAccount: "ភ្ជាប់គណនីធនាគារ",
    passwordResetSuccessfulAndSignOut:
      "ពាក្យសម្ងាត់របស់អ្នកត្រូវបានកំណត់ឡើងវិញដោយជោគជ័យ។ ចុចលើប៊ូតុងចេញនៅខាងក្រោមដើម្បីចូលម្តងទៀត",
    enterCurrentPassword: "បញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្ន",
    pleaseEnterCurrentPassword: "សូមបញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្ន",
    phoneNumber: "លេខទូរសព្ទ",
    sex: "ភេទ",
    dateOfBirth: "ថ្ងៃខែ​ឆ្នាំ​កំណើត",
    state: "រដ្ឋ",
    country: "ប្រទេស",
    loyaltyId: "លេខសម្គាល់ភាពស្មោះត្រង់",
    createdAt: "បានបង្កើតនៅ",
    noOfTransactionsMade: "ចំនួនប្រតិបត្តិការដែលបានធ្វើ",
    yourDownloadWillStart: "ការទាញយករបស់អ្នកនឹងចាប់ផ្តើមក្នុងពេលបន្តិចទៀតនេះ",
    exportCustomers: "នាំចេញអតិថិជន",
    youhaveSuccessfullyToppedUp:
      "អ្នកបានបំពេញបន្ថែមអង្គភាពសារ SMS របស់អ្នកដោយជោគជ័យ។",
    youNowHave: "ឥឡូវនេះអ្នកមាន",
    smsUnits: "អង្គភាពផ្ញើសារ",
    enterNumberOfSmsUnits: "បញ្ចូលចំនួនសារ SMS ដែលអ្នកមានបំណងទិញ",
    pleaseEnterANumericValue: "សូមបញ្ចូលតម្លៃជាលេខ",
    pay: "បង់",
    accountEditedSuccessfully: "គណនីរបស់អ្នកត្រូវបានកែសម្រួលដោយជោគជ័យ។",
    youAeCurrentlyOn: "បច្ចុប្បន្នអ្នកកំពុងប្រើហើយ",
    plan: "ផែនការ",
    userData: "ទិន្នន័យអ្នកប្រើប្រាស់",
    businessData: "ទិន្នន័យអាជីវកម្ម",
    businessCategory: "Catergory អាជីវកម្ម",
    pleaseSelectCurrency: "សូមជ្រើសរើសរូបិយប័ណ្ណរបស់អ្នក",
    selectYourCurrency: "ជ្រើសរើសរូបិយប័ណ្ណរបស់អ្នក",
    purchaseMoreSmsUnits: "ទិញឯកតា SMS បន្ថែមដោយប្រើទម្រង់ខាងក្រោម",
    youHave: "អ្នក​មាន",
    atLeast4SmsUnitsRrequired:
      "យ៉ាងហោចណាស់ត្រូវការសារអេសអេសចំនួន ៤ សម្រាប់ការផ្ទៀងផ្ទាត់សូមបញ្ចូលទឹកប្រាក់!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "សូមផ្ទៀងផ្ទាត់គណនីធនាគាររបស់អ្នកដើម្បីអនុលោមតាមតម្រូវការ 'ស្គាល់អតិថិជនរបស់អ្នក' (KYC) នេះនឹងអនុញ្ញាតឱ្យអ្នកប្រមូលការទូទាត់តាមរយៈយូអេសឌីឬផ្ទេរភ្លាមៗទទួលការបញ្ជាទិញពីអតិថិជននិងដំណើរការប្រតិបត្តិការទូទាត់។ អនុវត្តចំពោះឈ្មួញនីហ្សេរីយ៉ាតែប៉ុណ្ណោះ។ សូមចុចប៊ូតុងខាងក្រោមដើម្បីចាប់ផ្តើម។",
    reConnectBankAccount: "ភ្ជាប់គណនីធនាគារឡើងវិញ",
    accountName: "ឈ្មោះ​គណនី",
    accountNumber: "លេខ​គណនី",
    bankName: "ឈ្មោះ​របស់​ធនាគារ",
    verified: "បានផ្ទៀងផ្ទាត់",
    accountDetails: "ព័ត៌មានលម្អិតគណនី",
    kycBankAccount: "ខេ។ ស៊ី។ ស៊ី",
    createTier: "បង្កើតថ្នាក់",
    fileUploadSuccessful: "ឯកសារបានផ្ទុកឡើងដោយជោគជ័យ",
    fileUploadFailed: "ការផ្ទុកឯកសារបរាជ័យ",
    createLoyaltyProgram: "បង្កើតកម្មវិធីភាពស្មោះត្រង់",
    createLoyalty: "បង្កើតភាពស្មោះត្រង់",
    name: "ឈ្មោះ",
    loyaltyArtwork: "សិល្បៈភាពស្មោះត្រង់",
    clickToUpload: "ចុចដើម្បីផ្ទុកឡើង",
    amountToPointsRatio: "សមាមាត្រទៅនឹងសមាមាត្រពិន្ទុ",
    points: "ពិន្ទុ",
    recommendedAmountToPointRatio:
      "បានណែនាំ៖ ₦ ១ ទល់ ១ ។ ពោលគឺរាល់ការចំណាយ ១ ណៃរ៉ាអតិថិជនរបស់អ្នកនឹងទទួលបាន ១ ពិន្ទុ",
    pleaseTypeIn: "សូមវាយបញ្ចូល",
    toDeleteLoyalty: "ដើម្បីលុបភាពស្មោះត្រង់នេះ",
    deleteLoyalty: "លុបភាពស្មោះត្រង់",
    toConfirm: "បញ្ជាក់",
    edit: "កែសម្រួល",
    pointsAwardedSuccessfully: "ពិន្ទុទទួលបានជោគជ័យ។",
    enterPointValueToAward: "បញ្ចូលតម្លៃដែលអ្នកចង់ផ្តល់ជូនអតិថិជន",
    award: "ពានរង្វាន់",
    awardPointValuesToCustomer: "តម្លៃរង្វាន់ដល់អតិថិជន",
    enrollMembersToLoyalty: "ចុះឈ្មោះសមាជិកភាពស្មោះត្រង់",
    awardPoints: "ពិន្ទុរង្វាន់",
    enroll: "ចុះឈ្មោះ",
    home: "ផ្ទះ",
    loyalty: "ភាពស្មោះត្រង់",
    enrollCustomers: "ចុះឈ្មោះអតិថិជន",
    selected: "បានជ្រើសរើស",
    customer: "អតិថិជន",
    loyaltyActivationSuccessful: "ការធ្វើឱ្យភាពស្មោះត្រង់មានភាពជោគជ័យ។",
    loyaltyDeactivationSuccessful: "ការបញ្ឈប់ភាពស្មោះត្រង់បានជោគជ័យ។",
    viewTier: "មើលថ្នាក់",
    deactivate: "ធ្វើឱ្យសកម្ម",
    activate: "ធ្វើឱ្យសកម្ម",
    actions: "សកម្មភាព",
    nameOfLoyalty: "ឈ្មោះភាពស្មោះត្រង់",
    loyaltyStatus: "ស្ថានភាពភាពស្មោះត្រង់ស្ថានភាពភាពស្មោះត្រង់",
    numberOfTiers: "ចំនួនថ្នាក់",
    createdOn: "បានបង្កើតនៅលើ",
    createATier: "បង្កើតថ្នាក់",
    stopCreatingTierConfirmation: "តើអ្នកចង់បញ្ឈប់ការបង្កើតថ្នាក់ទេ?",
    stopEditingTierConfirmation: "តើអ្នកចង់បញ្ឈប់ការកែសម្រួលថ្នាក់នេះទេ?",
    nameOfTier: "ឈ្មោះថ្នាក់",
    minimumSpendingTarget: "គោលដៅចំណាយអប្បបរមា",
    maximumSpendingTarget: "គោលដៅចំណាយអតិបរមា",
    minimumSpendingTargetRequired: "គោលដៅចំណាយអប្បបរមាត្រូវបានទាមទារ",
    maximumSpendingTargetRequired: "គោលដៅចំណាយអតិបរមាត្រូវបានទាមទារ",
    rewardSponsor: "អ្នកឧបត្ថម្ភរង្វាន់",
    pleaseChooseARewardSponsor: "សូមជ្រើសរើសអ្នកឧបត្ថម្ភរង្វាន់",
    self: "ខ្លួនឯង",
    partner: "ដៃគូ",
    rewardPartner: "ដៃគូផ្តល់រង្វាន់",
    pleaseSelectRewardPartner: "សូមជ្រើសរើសដៃគូរង្វាន់របស់អ្នក",
    rewards: "រង្វាន់",
    pleaseSelectAReward: "សូមជ្រើសរើសរង្វាន់",
    instructionsOnRedeemingReward:
      "សេចក្តីណែនាំអំពីរបៀបដែលអតិថិជនគួរប្តូរយករង្វាន់",
    pleaseFillInThisField: "សូមបំពេញវាលនេះ!",
    toDeleteThisTier: " ដើម្បីលុបថ្នាក់នេះ",
    deleteTier: "លុបថ្នាក់",
    viewMembers: "មើលសមាជិក",
    membersEnrolled: "សមាជិកបានចុះឈ្មោះ",
    instruction: "ការណែនាំ",
    membersIn: "សមាជិកនៅក្នុង",
    availableTiersInLoyalty: "ថ្នាក់ដែលអាចរកបាននៅក្នុងកម្មវិធីភាពស្មោះត្រង់",
    tiers: "ថ្នាក់",
    totalTier: "កម្រិតសរុប",
    availableLoyaltyProgramme: "កម្មវិធីភាពស្មោះត្រង់ដែលអាចរកបាន",
    totalLoyalties: "ភាពស្មោះត្រង់សរុប",
    memberDetails: "ព័ត៌មានលម្អិតសមាជិក",
    nameOfCustomer: "ឈ្មោះអតិថិជន",
    address: "អាស័យដ្ឋាន",
    allEnrolledMembers: "សមាជិកដែលបានចុះឈ្មោះទាំងអស់",
    thisIsToWishYouHappyBirthday:
      "នេះគឺដើម្បីជូនពរអ្នកឱ្យរីករាយថ្ងៃកំណើតនិងជីវិតរុងរឿង។ សូមអរគុណចំពោះអ្វីទាំងអស់ដែលអ្នកមានចំពោះឡូយស្ទឺរ។ រីករាយបុណ្យភ្ជុំបិណ្ឌ!",
    inputAnOfferPlease: "សូមបញ្ចូលការផ្តល់ជូន",
    pleaseSelectTheInsertPoint: "សូមជ្រើសរើសចំណុចបញ្ចូលក្នុងសារហើយចុចម្តងទៀត",
    birthdayOfferAndMessage: "ការផ្តល់ជូនខួបកំណើតនិងសារ",
    birthdayOffer: "ការផ្តល់ជូនខួបកំណើត",
    birthdayMessage: "សារខួបកំណើត",
    noOfferFound: "រកមិនឃើញការផ្តល់ជូន",
    settingABirthdayOffer:
      "ការកំណត់ការផ្តល់ជូនខួបកំណើតអនុញ្ញាតឱ្យអតិថិជនទទួលបានការផ្តល់ជូននេះតាមរយៈសារ SMS នៅថ្ងៃខួបកំណើតរបស់ពួកគេ",
    createOffer: "បង្កើតការផ្តល់ជូន",
    whatIsTheOffer: "តើអ្វីទៅជាការផ្តល់ជូន?",
    editMessage: "កែសម្រួលសារ",
    insert: "បញ្ចូល",
    theNameOfCustomerInserted: "ឈ្មោះរបស់អតិថិជននឹងត្រូវបានបញ្ចូលនៅទីនេះ",
    theBirtdayOfferInserted: "ការផ្តល់ជូនខួបកំណើតនឹងត្រូវបានបញ្ចូលនៅទីនេះ",
    youSuccessfullyAddedNewBranch: "អ្នកបានបន្ថែមសាខាថ្មីដោយជោគជ័យ!",
    addNewBranch: "បន្ថែមសាខាថ្មី",
    addBranch: "បន្ថែមសាខា",
    additionalBranchWillIncur: "សាខាបន្ថែមនឹងកើតឡើង",
    perBranch: "ក្នុងមួយសាខា",
    ecommerceBranchCosts: "ថ្លៃដើមសាខាអេឡិចត្រូនិក",
    pleaseEnterBranchName: "សូមបញ្ចូលឈ្មោះសាខា",
    pleaseEnterBranchAddress1: "សូមបញ្ចូលបន្ទាត់អាសយដ្ឋានរបស់សាខា ១",
    enterBranchAddress1: "បញ្ចូលបន្ទាត់អាសយដ្ឋានរបស់សាខា ១",
    enterBranchAddress2: "បញ្ចូលបន្ទាត់អាសយដ្ឋានរបស់សាខា ១",
    pleaseEnterBranchAddress2: "សូមបញ្ចូលបន្ទាត់អាសយដ្ឋានរបស់សាខា ២",
    enterBranchName: "បញ្ចូលឈ្មោះសាខា",
    successfullyAddedStaff: "អ្នកបានបន្ថែមបុគ្គលិកថ្មីដោយជោគជ័យ!",
    addNewStaff: "បន្ថែមបុគ្គលិកថ្មី",
    addStaff: "បន្ថែមបុគ្គលិក",
    additionalStaffWillIncur: "បុគ្គលិកបន្ថែមនឹងកើតឡើង",
    perStaff: "ក្នុងមួយបុគ្គលិក។",
    pleaseEnterStaffEmail: "សូមបញ្ចូលអ៊ីមែលរបស់បុគ្គលិក",
    pleaseEnterStaffUsername: "សូមបញ្ចូលឈ្មោះអ្នកប្រើរបស់បុគ្គលិក",
    pleaseEnterStaffPassword: "សូមបញ្ចូលលេខសំងាត់របស់បុគ្គលិក",
    pleaseSelectStaffRole: "សូមជ្រើសរើសតួនាទីបុគ្គលិក",
    selectStaffRole: "ជ្រើសរើសតួនាទីរបស់បុគ្គលិក",
    enterStaffEmail: "បញ្ចូលអ៊ីមែលរបស់បុគ្គលិក",
    enterStaffUsername: "បញ្ចូលឈ្មោះអ្នកប្រើរបស់បុគ្គលិក",
    enterStaffPassword: "បញ្ចូលលេខសម្ងាត់របស់បុគ្គលិក",
    spacesNotAllowedInUsername: "ដកឃ្លាមិនអនុញ្ញាតក្នុងឈ្មោះអ្នកប្រើ",
    admin: "អ្នកគ្រប់គ្រង",
    pleaseSelectBusinessToAttachStaff:
      "សូមជ្រើសរើសអាជីវកម្មដើម្បីភ្ជាប់បុគ្គលិក",
    searchForBranchToAttachStaff: "ស្វែងរកសាខាដើម្បីភ្ជាប់បុគ្គលិក",
    username: "ឈ្មោះ​អ្នកប្រើប្រាស់",
    role: "តួនាទី",
    areYouSureToDeleteThis: "តើអ្នកប្រាកដថាលុបរឿងនេះ",
    branches: "សាខា",
    upgradeYourPlan: "ធ្វើឱ្យប្រសើរផែនការរបស់អ្នក។",
    add: "បន្ថែម",
    addNew: "បន្ថែម​ថ្មី",
    customerWithEmailAlreadyExists: "អតិថិជនដែលមានអ៊ីមែល/លេខទូរស័ព្ទមានរួចហើយ!",
    successfullyAddedNewCustomer: "អ្នកបានបន្ថែមអតិថិជនថ្មីដោយជោគជ័យ!",
    addCustomer: "បន្ថែមអតិថិជន",
    pleaseEnterCustomerFirstName: "សូមបញ្ចូលឈ្មោះអតិថិជន",
    pleaseEnterCustomerLastName: "សូមបញ្ចូលនាមត្រកូលរបស់អតិថិជន",
    pleaseEnterCustomerPhoneNumber: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អតិថិជន",
    pleaseEnterCustomerEmail: "សូមបញ្ចូលអ៊ីមែលរបស់អតិថិជន",
    pleaseEnterCustomerAddressLine: "សូមបញ្ចូលបន្ទាត់អាសយដ្ឋានរបស់អតិថិជន",
    pleaseEnterCustomerOtherAddress: "សូមបញ្ចូលអាសយដ្ឋានផ្សេងទៀតរបស់អតិថិជន",
    pleaseSelectCustomerGender: "សូមជ្រើសរើសភេទរបស់អតិថិជន",
    gender: "យេនឌ័រ",
    male: "ប្រុស",
    female: "ស្រី",
    bank: "ធនាគារ",
    selectBank: "ជ្រើសរើសធនាគារ",
    stateOrRegionOrProvince: "រដ្ឋ/តំបន់/ខេត្ត",
    customerNotes: "កំណត់សំគាល់អតិថិជន",
    sendSms: "ផ្ញើសារ SMS",
    editCustomer: "កែសម្រួលអតិថិជន",
    redeemReward: "លោះរង្វាន់",
    issueLoyaltyCard: "ចេញប័ណ្ណភក្ដីភាព",
    deleteCustomer: "លុបអតិថិជន",
    youveSuccessfullyAssignedLoyaltyMembership:
      "អ្នកបានកំណត់អត្តសញ្ញាណសមាជិកភាពស្មោះត្រង់ដោយជោគជ័យ",
    noMembershipIdAvailable:
      "មិនមានលេខសម្គាល់សមាជិកភាពទេ។ សូមទាក់ទងមកយើងខ្ញុំតាមអ៊ីមែល hello@loystar.co",
    sendEmail: "ផ្ញើអ៊ីមែល",
    membershipPoints: "ពិន្ទុសមាជិកភាព",
    customerDetails: "ព័ត៌មានលម្អិតអតិថិជន",
    close: "បិទ",
    loyaltyBalance: "តុល្យភាពភាពស្មោះត្រង់",
    pointsBalance: "តុល្យភាពពិន្ទុ",
    starBalance: "តុល្យភាពផ្កាយ",
    requiredPoints: "ពិន្ទុដែលត្រូវការ",
    requiredStars: "ផ្កាយដែលត្រូវការ",
    reddemCode: "ផ្តោះប្តូរយកលេខកូដ",
    toDeleteThisCustomer: "ដើម្បីលុបអតិថិជននេះ",
    customerHasBeenDeletedSuccessfully: "អតិថិជនត្រូវបានលុបចោលដោយជោគជ័យ!",
    customerWithPhoneAlreadyExists: "អតិថិជនដែលមានលេខទូរស័ព្ទមានរួចហើយ",
    customerWasSuccessfullyEdited: "អតិថិជនត្រូវបានកែសម្រួលដោយជោគជ័យ",
    anErrorOccured: "កំហុស​មួយ​បាន​កើត​ឡើង",
    phoneNumberIncludeCountry: "លេខទូរស័ព្ទ (រួមបញ្ចូលលេខកូដប្រទេស)",
    yourFileQueuedForUpload:
      "ឯកសាររបស់អ្នកត្រូវបានដាក់ជាជួរដើម្បីផ្ទុកឡើង។ សូមធ្វើឱ្យទំព័រស្រស់ឡើងវិញបន្ទាប់ពីពីរបីវិនាទី។",
    thereWasAnErrorPerformingOperation:
      "មានកំហុសក្នុងការដំណើរការប្រតិបត្តិការ!",
    pleaseSelectFile: "សូមជ្រើសរើសឯកសារ!",
    oopsOnlyCsvFilesAllowed:
      "អូ! អនុញ្ញាតតែឯកសារ CSV ប៉ុណ្ណោះ។ សូមបញ្ចូលឯកសារ .csv ។",
    fileShouldBeSmallerThan5Mb:
      "ឯកសារគួរតែតូចជាង ៥ មេកាបៃ ប្រសិនបើអ្នកមានឯកសារធំជាងនេះសូមអ៊ីមែលមក support@loystar.co សូមអរគុណ",
    customerFirstNameIsRequired: "ឈ្មោះអតិថិជនត្រូវបានទាមទារនៅជួរ",
    customerPhoneNumberIsRequired: "ត្រូវការលេខទូរស័ព្ទរបស់អតិថិជននៅជួរ",
    importCustomers: "អតិថិជននាំចូល",
    upload: "ផ្ទុកឡើង",
    clickIconToDownloadCsv: "ចុចរូបតំណាងនេះដើម្បីទាញយកគំរូឯកសារស៊ីអេសវី។",
    getGoogleSheetVersion: "យកកំណែសន្លឹកហ្គូហ្គល",
    clickOrDragFileToUpload: "ចុចឬអូសឯកសារទៅតំបន់នេះដើម្បីផ្ទុកឡើង",
    missingOrInvalidColumnHeader:
      "បាត់ឬមិនត្រឹមត្រូវបឋមកថាជួរឈរ។ សូមធ្វើតាមទម្រង់គំរូ។ សូមអរគុណ។",
    youHaveImported: "អ្នកបាននាំចូល",
    youSuccessfullyRedeemedReward: "អ្នកបានលោះរង្វាន់របស់អ្នកដោយជោគជ័យ!",
    sixDigitCode: "លេខកូដ ៦ ខ្ទង់",
    pleaseEnterCustomerRewardCode: "សូមបញ្ចូលលេខកូដរង្វាន់របស់អតិថិជន",
    enterRewardCode: "បញ្ចូលលេខកូដរង្វាន់។ (លេខកូដរង្វាន់គឺប្រកាន់អក្សរតូចធំ)",
    selectLoyaltyProgram: "ជ្រើសរើសកម្មវិធីស្មោះត្រង់",
    stamps: "តែម",
    smsUnitsLow: "អង្គភាព SMS ទាប",
    pleaseTopUpSmsUnits: "សូមបញ្ចូលលេខផ្ញើសារជាអក្សរ",
    smsSuccessfullySentForDelivery: "ផ្ញើសារជាអក្សរដោយជោគជ័យសម្រាប់ការចែកចាយ!",
    sendSmsTo: "ផ្ញើសារ SMS ទៅ",
    allCustomers: "អតិថិជនទាំងអស់",
    unitsAvailable: "មានគ្រឿង",
    pleaseTypeInTheMessage: "សូមវាយបញ្ចូលក្នុងសារ",
    message: "សារ",
    charactersRemaining: "តួអក្សរនៅសល់",
    avoidUseOfSpecialCharacters:
      "ជៀសវាងការប្រើប្រាស់តួអក្សរពិសេសនិងសញ្ញាអារម្មណ៍ដើម្បីអភិរក្សអង្គភាពផ្ញើសារ។",
    note: "ចំណាំ",
    errorFetchingCustomersMultilevelDetail:
      "កំហុសក្នុងការទៅយកព័ត៌មានលម្អិតពហុកម្រិតរបស់អតិថិជន",
    search: "ស្វែងរក",
    reset: "កំណត់ឡើងវិញ",
    importCustomer: "អតិថិជននាំចូល",
    addNewCustomer: "បន្ថែមអតិថិជនថ្មី",
    sendSmsBroadcast: "ផ្ញើសារ SMS",
    totalCustomers: "អតិថិជនសរុប",
    disbursementDetails: "ព័ត៌មានលម្អិតនៃការទូទាត់",
    paidBy: "បង់ប្រាក់ដោយ",
    disbursed: "បានទូទាត់",
    bankAccountName: "ឈ្មោះគណនីធនាគារ",
    bankAccountNumber: "លេខ​គណនី​ធនាគារ",
    transferInitiated: "ផ្តួចផ្តើមផ្ទេរ",
    transferCompleted: "ការផ្ទេរបានបញ្ចប់",
    pleaseEnterAValid: "សូមបញ្ចូលសុពលភាព",
    begin: "ចាប់ផ្តើម",
    end: "បញ្ចប់",
    date: "កាលបរិច្ឆេទ",
    paymentDate: "កាលបរិច្ឆេទទូទាត់",
    selectDisbursementDuration: "ជ្រើសរើសរយៈពេលទូទាត់",
    totalSettled: "ការទូទាត់សរុប",
    totalUnsettled: "សរុបមិនស្ងប់",
    toDeleteThisSale: "ដើម្បីលុបការលក់នេះ",
    draftSaleDeletedSuccessfully:
      "ការលក់សេចក្តីព្រាងច្បាប់ត្រូវបានលុបចោលដោយជោគជ័យ!",
    deleteSale: "លុបការលក់",
    pleaseTypeInYourTotal: "សូមវាយបញ្ចូលចំនួនសរុបរបស់អ្នកដើម្បីបញ្ជាក់ពីការលុប",
    billDetails: "ព័ត៌មានលម្អិតប៊ីល",
    printBill: "បោះពុម្ពវិក័យប័ត្រ",
    servedBy: "បម្រើដោយ",
    total: "សរុប",
    createdDate: "កាលបរិច្ឆេទបង្កើត",
    createdTime: "ពេលវេលាដែលបានបង្កើត",
    status: "ស្ថានភាព",
    loginSuccessful: "ចូលដោយជោគជ័យ",
    pleaseWaitWhileWeConnectAccount: "សូមរង់ចាំខណៈពេលដែលយើងភ្ជាប់គណនីរបស់អ្នក",
    connectionFailedTryAgain: "ការ​ត​ភ្ជាប់​បាន​បរាជ័យ។ សូម​ព្យាយាម​ម្តង​ទៀត។",
    connectionSuccessful: "ការតភ្ជាប់ជោគជ័យ",
    viewDetails: "មើលព័ត៌មានលម្អិត",
    enable: "បើកដំណើរការ",
    free: "ឥតគិតថ្លៃ",
    cost: "ថ្លៃដើម",
    visitWebsite: "ទស្សនាគេហទំព័រ",
    pleaseUpgradeYourPlanToPro:
      "សូមដំឡើងគម្រោងរបស់អ្នកទៅ Pro Plus ដើម្បីបើកដំណើរការកម្មវិធីនេះ",
    connectYourBankAccount:
      "ភ្ជាប់គណនីធនាគាររបស់អ្នកដើម្បីអាចទទួលបានការទូទាត់។",
    disable: "បិទដំណើរការ",
    enableApp: "បើកដំណើរការកម្មវិធី",
    addNewProductToInvoice: "បន្ថែមផលិតផលថ្មីទៅក្នុងវិក្កយបត្រ",
    toDeleteThisInvoice: "ដើម្បីលុបវិក្កយបត្រនេះ",
    invoiceDeletedSuccessfully: "វិក្កយបត្រត្រូវបានលុបដោយជោគជ័យ!",
    deleteInvoice: "លុបវិក្កយបត្រ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "អ្នកបានបន្ថែមសារទូទាត់វិក្កយបត្រដោយជោគជ័យ!",
    pleaseEnterPaymentInstructions: "សូមបញ្ចូលការណែនាំអំពីការទូទាត់",
    invoiceId: "លេខសម្គាល់វិក្កយបត្រ",
    paidDate: "កាលបរិច្ឆេទបង់ប្រាក់",
    reference: "សេចក្តីយោង",
    productAdded: "ផលិតផលបានបន្ថែម",
    productOutOfStock: "ផលិតផលអស់ពីស្តុក។ សូមចូលស្តុកវិញ។",
    totalInvoices: "ព័ត៌មានសរុប",
    totalPaidInvoices: "វិក័យប័ត្របង់ប្រាក់សរុប",
    totalUnpaidInvoices: "ព័ត៌មានគ្មានការអនុញ្ញាតសរុប",
    loyaltyProgramDeleted: "បានលុបកម្មវិធីភក្ដីភាព",
    thisLoyaltyProgramDeletedSuccessfully:
      "កម្មវិធីភក្ដីភាពនេះត្រូវបានលុបចោលដោយជោគជ័យ",
    thisLoyaltyProgramEditedSuccessfully:
      "កម្មវិធីភាពស្មោះត្រង់នេះត្រូវបានកែសម្រួលដោយជោគជ័យ",
    loyaltyProgramAddedSuccessfully:
      "កម្មវិធីភាពស្មោះត្រង់ត្រូវបានបន្ថែមដោយជោគជ័យ",
    loyaltyProgramEdited: "កម្មវិធីភាពស្មោះត្រង់បានកែសម្រួល",
    loyaltyProgramAdded: "កម្មវិធីភាពស្មោះត្រង់ត្រូវបានបន្ថែម",
    loyaltyDetails: "ព័ត៌មានលម្អិតភក្ដីភាព",
    doYouWantToCloseDialog: "តើអ្នកចង់បិទប្រអប់នេះទេ?",
    pleaseEnterLoyaltyName: "សូមបញ្ចូលឈ្មោះភាពស្មោះត្រង់របស់អ្នក",
    programType: "ប្រភេទកម្មវិធី",
    pleaseSelectProgramType: "សូមជ្រើសរើសប្រភេទកម្មវិធី",
    simplePoints: "ចំណុចសាមញ្ញ",
    stampsProgram: "កម្មវិធីត្រា",
    threshold: "កម្រិត",
    pleaseEnterLoyaltyThreshold: "សូមបញ្ចូលកម្រិតភាពស្មោះត្រង់",
    reward: "រង្វាន់",
    pleaseEnterLoyaltyReward: "សូមបញ្ចូលរង្វាន់នៃភាពស្មោះត្រង់",
    totalUserPoints: "ពិន្ទុអ្នកប្រើប្រាស់សរុប",
    totalUserStamps: "ត្រាអ្នកប្រើសរុប",
    spendingTarget: "គោលដៅចំណាយ",
    spendingTargetHint1:
      "គោលដៅចំណាយគឺថាតើអតិថិជនត្រូវចំណាយប៉ុន្មានដើម្បីទទួលបានរង្វាន់។ តម្លៃរូបិយប័ណ្ណ ១ = ១ ពិន្ទុ។",
    spendingTargetHint2:
      "គោលដៅតែមគឺជាចំនួនតែមដែលអតិថិជនត្រូវប្រមូលដើម្បីទទួលបានរង្វាន់។ ឧ។ ៥",
    addNewLoyaltyProgram: "បន្ថែមកម្មវិធីភាពស្មោះត្រង់ថ្មី",
    addLoyaltyProgram: "បន្ថែមកម្មវិធីភាពស្មោះត្រង់",
    loyaltyProgramType: "ប្រភេទកម្មវិធីភាពស្មោះត្រង់",
    pleaseSelectLoyaltyProgramType: "សូមជ្រើសរើសប្រភេទកម្មវិធីស្មោះត្រង់",
    nameOfProgram: "ឈ្មោះកម្មវិធី",
    pleaseEnterProgramName: "សូមបញ្ចូលឈ្មោះកម្មវិធី",
    whatIsTheReward: "តើអ្វីទៅជារង្វាន់?",
    egNextPurchaseIsFree: "ឧ។ ការទិញបន្ទាប់គឺឥតគិតថ្លៃ",
    customerName: "ឈ្មោះ​អតិថិជន",
    guestCustomer: "អតិថិជនភ្ញៀវ",
    orderConfirmedSuccessfully: "ការបញ្ជាទិញបានបញ្ជាក់ដោយជោគជ័យ",
    orderCancelledSuccessfully: "ការបញ្ជាទិញត្រូវបានលុបចោលដោយជោគជ័យ",
    confirmOrder: "បញ្ជាក់​ការ​បញ្ជាទិញ",
    cancelOrder: "បោះបង់ការបញ្ជាទិញ",
    allOrders: "រាល់ការបញ្ជាទិញ",
    totalOrders: "ការបញ្ជាទិញសរុប",
    doYouWantToAcceptOrder: "តើអ្នកចង់ទទួលយកការបញ្ជាទិញនេះទេ?",
    doYouWantToCancelOrder: "តើអ្នកចង់បោះបង់ការបញ្ជាទិញនេះទេ?",
    orderDetails: "ព័ត៌មានលម្អិតបញ្ជាទិញ",
    orderCreatedAt: "ការបញ្ជាទិញបានបង្កើតនៅ",
    supplier: "អ្នកផ្គត់ផ្គង់ 0011184554",
    productName: "ឈ្មោះ​ផលិតផល",
    quantity: "បរិមាណ",
    unitPrice: "តម្លៃ​ឯកតា",
    receivedBy: "ទទួលបានដោយ",
    reportFrom: "រាយការណ៍ពី",
    totalSupplies: "ការផ្គត់ផ្គង់សរុប",
    allRightsReserved: "រក្សា​រ​សិទ្ធ​គ្រប់យ៉ាង",
    toDeleteThisTransaction: "ដើម្បីលុបប្រតិបត្តិការនេះ",
    transactionDeletedSuccessfully:
      "ប្រតិបត្តិការត្រូវបានលុបចោលដោយជោគជ័យ។ ភាគហ៊ុនត្រូវបានប្រគល់ជូនសារពើភ័ណ្ឌវិញ។",
    deleteTransaction: "លុបប្រតិបត្តិការ",
    transactionDetails: "ព័ត៌មានលម្អិតអំពីប្រតិបត្តិការ",
    printReceipt: "បង្កាន់ដៃបោះពុម្ព",
    channel: "ឆានែល",
    discount: "បញ្ចុះតម្លៃ",
    profit: "ប្រាក់ចំណេញ",
    discountedSales: "ការលក់បញ្ចុះតម្លៃ",
    errorFetchingRecord: "កំហុសក្នុងការទៅយកកំណត់ត្រា",
    exportTransactions: "ប្រតិបត្តិការនាំចេញ",
    errorFetchingSalesRecord: "មានបញ្ហាក្នុងការទៅយកកំណត់ត្រាលក់សម្រាប់នាំចេញ។",
    totalSellingPrice: "តម្លៃលក់សរុប",
    totalCostPrice: "តម្លៃសរុប",
    appliedDiscount: "ការបញ្ចុះតម្លៃអនុវត្ត",
    noOfItems: "ចំនួនធាតុ",
    sales: "ការលក់",
    export: "នាំចេញ",
    totalProfit: "ប្រាក់ចំណេញសរុប",
    totalBalanceInPeriod: "សមតុល្យសរុបក្នុងរយៈពេល",
    allTimeSales: "ការលក់គ្រប់ពេលវេលា",
    records: "កំណត់ត្រា",
    todaysSales: "ការលក់ថ្ងៃនេះ",
    transaction: "ប្រតិបត្តិការ",
    youHaveExceededTotalNumberOfProducts:
      "អ្នកបានលើសចំនួនផលិតផលសរុបដែលអនុញ្ញាតនៅលើផែនការរបស់អ្នក។ ធ្វើឱ្យប្រសើរផែនការរបស់អ្នកដើម្បីរីករាយជាមួយដែនកំណត់ខ្ពស់។",
    youNeedToHaveLoyaltyProgram:
      "អ្នកត្រូវមានកម្មវិធីភក្ដីភាពដើម្បីប្រើលក្ខណៈនេះ!",
    price: "តម្លៃ",
    category: "ប្រភេទ",
    stockTracking: "ការតាមដានភាគហ៊ុន",
    stockCount: "ចំនួនភាគហ៊ុន",
    taxed: "បង់ពន្ធ",
    originalPrice: "តម្លៃ​ដើម",
    costPrice: "តម្លៃថ្លៃដើម",
    unit: "ឯកតា",
    productImage: "រូបភាពផលិតផល",
    taxRate: "អត្រាពន្ធ",
    taxType: "ប្រភេទពន្ធ",
    trackInventory: "តាមដានសារពើភ័ណ្ឌ",
    variants: "វ៉ារ្យ៉ង់",
    hasVariants: "មានវ៉ារ្យ៉ង់",
    importProduct: "ផលិតផលនាំចូល",
    exportProducts: "ផលិតផលនាំចេញ",
    addNewProduct: "បន្ថែមផលិតផលថ្មី",
    viewCategory: "មើលប្រភេទ",
    viewSuppliers: "មើលអ្នកផ្គត់ផ្គង់",
    receiveInventory: "ទទួលបានសារពើភ័ណ្ឌ",
    printAllProductsTag: "បោះពុម្ពស្លាកផលិតផលទាំងអស់",
    deleteAll: "លុប​ទាំងអស់",
    totalProducts: "ផលិតផលសរុប",
    youveSuccessfullyAddedANewCategory: "អ្នកបានបន្ថែមប្រភេទថ្មីដោយជោគជ័យ",
    addNewCategory: "បន្ថែមប្រភេទថ្មី",
    addCategory: "បន្ថែមប្រភេទ",
    categoryName: "ឈ្មោះប្រភេទ",
    pleaseEnterCategoryName: "សូមបញ្ចូលឈ្មោះប្រភេទ",
    stampsTarget: "គោលដៅត្រា",
    sendInventory: "ផ្ញើសារពើភ័ណ្ឌ",
    productDetails: "ព​ត៌​មាន​លម្អិត​នៃ​ផលិតផល",
    youveSuccessfullyEditedThisCategory: "អ្នកបានកែសម្រួលប្រភេទនេះដោយជោគជ័យ",
    update: "ធ្វើឱ្យទាន់សម័យ",
    categoryList: "បញ្ជីប្រភេទ",
    categories: "ប្រភេទ",
    enterQuantityToUpdate: "បញ្ចូលបរិមាណដើម្បីធ្វើបច្ចុប្បន្នភាព",
    inventorySentSuccessfully: "សារពើភ័ណ្ឌត្រូវបានបញ្ជូនដោយជោគជ័យ!",
    updateInventory: "ធ្វើបច្ចុប្បន្នភាពសារពើភ័ណ្ឌ",
    currentQuantity: "បរិមាណបច្ចុប្បន្ន",
    pleaseEnterQuantityToAdd: "សូមបញ្ចូលបរិមាណដែលអ្នកចង់បន្ថែម",
    pleaseSelectABranch: "សូមជ្រើសរើសសាខា",
    searchForBranch: "ស្វែងរកសាខា",
    youCantSendMoreThanStock:
      "អ្នកមិនអាចផ្ញើលើសពីអ្វីដែលអ្នកមាននៅក្នុងស្តុកបានទេ",
    send: "ផ្ញើ",
    pleaseEnterQuantityToSend: "សូមបញ្ចូលបរិមាណដែលអ្នកចង់ផ្ញើ",
    productNameIsRequiredOnRow: "ឈ្មោះផលិតផលត្រូវបានទាមទារនៅជួរ",
    productCategoryIsRequiredOnRow: "ប្រភេទផលិតផលត្រូវបានទាមទារនៅជួរ",
    productPriceIsRequiredOnRow: "តម្លៃផលិតផលត្រូវបានទាមទារជាជួរ",
    productUnitIsRequiredOnRow: "ឯកតាផលិតផលត្រូវបានទាមទារនៅជួរ",
    productQuantityIsRequiredOnRow: "បរិមាណផលិតផលត្រូវបានទាមទារនៅជួរ",
    productVariantsRequireTracking: "វ៉ារ្យ៉ង់ផលិតផលតម្រូវឱ្យមានការតាមដាន!",
    pleaseAddVariantClickButton: "សូមបន្ថែមវ៉ារ្យ៉ង់ដោយចុចប៊ូតុងបន្ថែម!",
    totalVariantsMoreThanSelectedQuantity:
      "វ៉ារ្យ៉ង់សរុបគឺច្រើនជាងបរិមាណផលិតផលដែលបានជ្រើសរើសសូមកាត់បន្ថយបរិមាណវ៉ារ្យ៉ង់",
    productEditedSuccessfully: "ផលិតផលត្រូវបានកែសម្រួលដោយជោគជ័យ!",
    fileTooLargeLessThan4Mb: "ទំហំឯកសារធំពេក! ទំហំឯកសារគួរតែតិចជាង ៤ មេកាបៃ",
    suchVariantAlreadyExist: "វ៉ារ្យ៉ង់បែបនេះមានរួចហើយ",
    addVariants: "បន្ថែមវ៉ារ្យ៉ង់",
    editProduct: "កែសម្រួលផលិតផល",
    pleaseEnterProductName: "សូមបញ្ចូលឈ្មោះផលិតផល",
    pleaseEnterProductPrice: "សូមបញ្ចូលតម្លៃផលិតផល",
    pleaseEnterProductOriginalPrice: "សូមបញ្ចូលតម្លៃផលិតផលដើម",
    productDescription: "ពណ៌នា​ពី​ផលិតផល",
    selectProductCategory: "ជ្រើសរើសប្រភេទផលិតផល",
    pleaseSelectProductCategory: "សូមជ្រើសរើសប្រភេទផលិតផល",
    productCostPrice: "តម្លៃថ្លៃដើមផលិតផល",
    productSellingPrice: "តម្លៃលក់ផលិតផល",
    productOriginalPrice: "តម្លៃផលិតផលដើម",
    maxFileSizeAllowedIs4Mb: "អតិបរមា ទំហំឯកសារអនុញ្ញាតគឺ ៤ មេកាបៃ",
    productsWithPicturesArePublished:
      "ផលិតផលដែលមានរូបភាពត្រូវបានបោះពុម្ពផ្សាយនៅលើឌីស្កូដើម្បីទទួលការបញ្ជាទិញ",
    shouldThisProductBeTracked: "តើគួរតាមដានផលិតផលនេះទេ?",
    pleaseSelectStockUnit: "សូមជ្រើសរើសឯកតាស្តុក",
    stockUnit: "អង្គភាពភាគហ៊ុន",
    bag: "កាបូប",
    bottle: "ដប",
    bunch: "បាច់",
    can: "អាច",
    carton: "កាតាឡុក",
    crate: "ក្រេត",
    cup: "CUP",
    dozen: "ឌឺហ្សេន",
    gigabytes: "ហ្គីហ្គាប៊ីធីស",
    gram: "ហ្គ្រេម",
    kilogram: "គីលុក",
    litre: "LITER",
    pack: "កញ្ចប់",
    pair: "គូ",
    pieces: "ភី",
    plate: "ចាន",
    tonne: "តូនី (MT)",
    uNIT: "យូនីធី",
    yard: "ទីធ្លា",
    pleaseEnterProductQuantity: "សូមបញ្ចូលបរិមាណផលិតផល",
    productQuantity: "បរិមាណផលិតផល",
    isThisProductTaxed: "តើផលិតផលនេះត្រូវបានគេយកពន្ធទេ?",
    selectTaxType: "ជ្រើសរើសប្រភេទពន្ធ",
    pleaseSelectTaxType: "សូមជ្រើសរើសប្រភេទពន្ធ",
    progressive: "វឌ្នភាព",
    proportional: "សមាមាត្រ",
    pleaseEnterProductTaxRate: "សូមបញ្ចូលអត្រាពន្ធផលិតផល",
    doesProductHaveVariants: "តើផលិតផលនេះមានវ៉ារ្យ៉ង់ដែរឬទេ?",
    type: "ប្រភេទ",
    value: "តម្លៃ",
    pleaseEnterVariantType: "សូមបញ្ចូលប្រភេទវ៉ារ្យ៉ង់",
    pleaseEnterVariantValue: "សូមបញ្ចូលតម្លៃវ៉ារ្យ៉ង់",
    pleaseEnterVariantPrice: "សូមបញ្ចូលតម្លៃវ៉ារ្យ៉ង់",
    pleaseEnterVariantQuantity: "សូមបញ្ចូលបរិមាណវ៉ារ្យ៉ង់",
    productDeletedSuccessfully: "ផលិតផលត្រូវបានលុបចោលដោយជោគជ័យ!",
    categoryDeletedSuccessfully: "ប្រភេទត្រូវបានលុបដោយជោគជ័យ!",
    toDeleteThisProduct: "ដើម្បីលុបផលិតផលនេះ",
    invalidProductQuantity: "បរិមាណផលិតផលមិនត្រឹមត្រូវ",
    quantityAddedIsOverStock:
      "បរិមាណដែលអ្នកកំពុងបន្ថែមគឺលើសពីអ្វីដែលអ្នកមានក្នុងស្តុក។",
    itemInventoryNotTracked: "សារពើភ័ណ្ឌទំនិញមិនត្រូវបានតាមដានទេ",
    addBulkQuantity: "បន្ថែមបរិមាណច្រើន",
    enterBulkQuantity: "បញ្ចូលបរិមាណច្រើន",
    pleaseEnterBulkQuantity: "សូមបញ្ចូលបរិមាណច្រើន",
    youveSuccessfullyAddedANewProduct: "អ្នកបានបន្ថែមផលិតផលថ្មីដោយជោគជ័យ!",
    pleaseEnterProductSellingPrice: "សូមបញ្ចូលតម្លៃលក់ផលិតផល",
    doYouWantToTrackProductStock: "តើអ្នកចង់តាមដានស្តុកផលិតផលទេ?",
    sellingPrice: "តម្លៃលក់",
    setProductExpiryReminder: "កំណត់ការរំលឹកផលិតផលផុតកំណត់",
    productExpiryDate: "កាលបរិច្ឆេទផុតកំណត់ផលិតផល",
    startRemindingFrom: "ចាប់ផ្តើមរំលឹកពី",
    productSuppliesAddedSuccessfully:
      "អ្នកបានបន្ថែមការផ្គត់ផ្គង់ផលិតផលដោយជោគជ័យ។",
    addProductSupplies: "បន្ថែមការផ្គត់ផ្គង់ផលិតផល",
    pleaseSelectSupplier: "សូមជ្រើសរើសអ្នកផ្គត់ផ្គង់",
    nameOfProduct: "ឈ្មោះផលិតផល",
    pleaseSelectProduct: "សូមជ្រើសរើសផលិតផល",
    productVariant: "វ៉ារ្យ៉ង់ផលិតផល",
    pleaseSelectAVariant: "សូមជ្រើសរើសវ៉ារ្យ៉ង់",
    pleaseEnterUnitPrice: "សូមបញ្ចូលតម្លៃឯកតា",
    businessBranch: "សាខាពាណិជ្ជកម្ម",
    pleaseSelectBranch: "សូមជ្រើសរើសសាខា",
    youveSuccessfullyAddedANewSupplier:
      "អ្នកបានបន្ថែមអ្នកផ្គត់ផ្គង់ថ្មីដោយជោគជ័យ",
    addSupplier: "បន្ថែមអ្នកផ្គត់ផ្គង់",
    pleaseEnterSupplierEmail: "សូមបញ្ចូលអ៊ីមែលរបស់អ្នកផ្គត់ផ្គង់",
    pleaseAddADescription: "សូមបន្ថែមការពិពណ៌នា",
    anErrorOccuredProductsDeleted:
      "កំហុសមួយបានកើតឡើងខណៈពេលកំពុងប្រតិបត្តិការ។ សូមកត់សម្គាល់ថាផលិតផលមួយចំនួនប្រហែលជាត្រូវបានលុបចោលនៅក្នុងដំណើរការ",
    bulkDelete: "លុបច្រើន",
    youAreAboutToDelete: "អ្នករៀបនឹងលុបហើយ",
    product: "ផលិតផល",
    isDueToRedeem: "គឺដោយសារតែការប្រោសលោះ",
    aReward: "រង្វាន់មួយ",
    pleaseSelectCustomerToReeemReward:
      "សូមជ្រើសរើសអតិថិជនដើម្បីប្តូរយករង្វាន់។",
    youHaveNoLoyaltyProgramRunning:
      "អ្នកមិនមានកម្មវិធីភក្ដីភាពសកម្មកំពុងដំណើរការទេ",
    customerHhasNoPointsInLoyaltyProgram:
      "អតិថិជនគ្មានចំណុចនៅក្នុងកម្មវិធីភក្ដីភាពនេះទេ",
    proceedWithPayment: "បន្តការទូទាត់?",
    youAreAboutToPayForTransactionUsingPoints:
      "អ្នកហៀបនឹងបង់ប្រាក់សម្រាប់ប្រតិបត្តិការដោយប្រើពិន្ទុ។",
    customerHas: "អតិថិជនមាន",
    worth: "តម្លៃ",
    andIsNotSufficientToPayForTransaction:
      "ហើយមិនគ្រប់គ្រាន់ដើម្បីទូទាត់សម្រាប់ប្រតិបត្តិការនេះទេ។ តើពួកគេចង់បន្ថែមសមតុល្យដោយប្រើវិធីបង់ប្រាក់ផ្សេងទៀតទេ?",
    addCustomerLoyalty: "បន្ថែមភាពស្មោះត្រង់របស់អតិថិជន",
    pleaseAddCustomerFirst: "សូមបន្ថែមឬជ្រើសរើសអតិថិជនជាមុន។",
    pleaseWaitWhileWeGetReady: "សូមរង់ចាំខណៈពេលដែលយើងត្រៀមខ្លួន",
    lowStock: "ភាគហ៊ុនទាប",
    pleaseEnterAmountTendered: "សូមបញ្ចូលចំនួនទឹកប្រាក់ដែលបានដាក់ដេញថ្លៃ",
    areYouSureToBookSaleOffline:
      "តើអ្នកប្រាកដថាអ្នកចង់កក់ការលក់ក្រៅបណ្តាញនេះទេ?",
    saleWouldBeBookedAutomatically:
      "ការលក់នឹងត្រូវបានកក់ដោយស្វ័យប្រវត្តិនៅពេលអ្នកបើកអ៊ីនធឺណិតរបស់អ្នក",
    offlineSalesBookingCancelled: "ការកក់ការលក់ក្រៅបណ្តាញត្រូវបានលុបចោល",
    covid19Message:
      "កូវីដ ១៩៖ លាងដៃជាមួយសាប៊ូឬជែលដើម្បីបញ្ឈប់ការរីករាលដាល។ រក្សាសុវត្ថិភាពជានិច្ច",
    saleSuccessfullyRecorded: "ការលក់បានកត់ត្រាដោយជោគជ័យ!",
    sendReceiptToEmail: "ផ្ញើវិក័យប័ត្រទៅអ៊ីមែល",
    sendThankYouSms: "ផ្ញើសារ SMS អរគុណ",
    sendShippingDetails: "ផ្ញើព័ត៌មានលម្អិតអំពីការដឹកជញ្ជូន",
    addLoyalty: "បន្ថែមភាពស្មោះត្រង់",
    searchCustomerNameOrNumber: "ស្វែងរកឈ្មោះអតិថិជនឬលេខ",
    clickTheSearchCustomerBox: "ចុចប្រអប់ស្វែងរកអតិថិជននិងស្កេនកាត",
    enterProductPrice: "បញ្ចូលតម្លៃផលិតផល",
    enterPriceFor: "បញ្ចូលតម្លៃសម្រាប់",
    searchForProduct: "ស្វែងរកផលិតផល",
    all: "ទាំងអស់",
    backToDashboard: "ត្រលប់ទៅផ្ទាំងគ្រប់គ្រង",
    viewDraftSales: "មើលសេចក្តីព្រាងការលក់",
    variantSelected: "ជម្រើសដែលបានជ្រើសរើស",
    variant: "វ៉ារ្យ៉ង់",
    thePreviousVariantSelectionNotAvailable:
      "ជម្រើសវ៉ារ្យ៉ង់មុនមិនអាចរកបានសម្រាប់វ៉ារ្យ៉ង់ថ្មីដែលបានជ្រើសរើសអាស្រ័យលើតម្លៃសូមផ្លាស់ប្តូរជម្រើសរបស់អ្នក។",
    pleaseSupplyPositiveQuantityNumber: "សូមផ្តល់លេខបរិមាណក្រោយប្រៃសណីយ៍",
    lowStockFor: "ភាគហ៊ុនទាបសម្រាប់",
    clearVariants: "វ៉ារ្យ៉ង់ច្បាស់លាស់",
    pleaseEnterQuantity: "សូមបញ្ចូលបរិមាណ",
    picture: "រូបភាព",
    redemptionToken: "និមិត្តសញ្ញានៃការប្រោសលោះ",
    token: "ថូខឹន",
    totalSpent: "ចំណាយសរុប",
    confirmPayment: "បញ្ជាក់ការទូទាត់",
    hasPaymentBeenMade: "តើការទូទាត់ត្រូវបានដំណើរការដោយជោគជ័យទេ?",
    enterTransactionReference: "បញ្ចូលសេចក្តីយោងប្រតិបត្តិការដែលអ្នកបានបង់",
    pleaseSelectACustomer: "សូមជ្រើសរើសអតិថិជន!",
    areYouSureToApplyDiscount: "តើអ្នកប្រាកដថាចង់អនុវត្តការបញ្ចុះតម្លៃទេ?",
    addYourBankAccountToEnableUssd:
      "បន្ថែមគណនីធនាគាររបស់អ្នកដើម្បីបើកការផ្ទេរប្រាក់ USSD ភ្លាមៗដោយ uPay",
    totalAmountToPay: "ចំនួនសរុបដែលត្រូវបង់",
    doYouWantToCancelTransaction: "តើអ្នកចង់លុបចោលប្រតិបត្តិការនេះទេ?",
    savePrintBill: "រក្សាទុក/បោះពុម្ពវិក័យប័ត្រ",
    enterAmountCollectedForCustomer:
      "បញ្ចូលចំនួនទឹកប្រាក់ដែលប្រមូលបានសម្រាប់អតិថិជន",
    recordSale: "ការលក់កំណត់ត្រា",
    checkOutWith: "ពិនិត្យចេញជាមួយ",
    instantTransfer: "ការផ្ទេរបន្ទាន់",
    dialTheUSSDCode: "ចុចលេខកូដយូអេសឌី",
    pleaseSelectABank: "សូមជ្រើសរើសធនាគារ",
    payWithUSSD: "ទូទាត់ជាមួយយូអេសឌី",
    sendBillTo: " - ផ្ញើវិក័យប័ត្រទៅ",
    waitingForUSSDTransfer: "កំពុងរង់ចាំការផ្ទេរ USSD",
    percent: "ភាគរយ",
    applyDiscount: "អនុវត្តការបញ្ចុះតម្លៃ",
    thisBillHasBeenSaved: "វិក័យប័ត្រនេះត្រូវបានរក្សាទុក",
    saveDraft: "រក្សាទុកសេចក្តីព្រាង",
    pleaseTypeANameToIdentifyCustomer:
      "សូមវាយឈ្មោះដើម្បីកំណត់អត្តសញ្ញាណអតិថិជន",
    paymentDetails: "ព័ត៌មានលម្អិតនៃការទូទាត់",
    basePrice: "តម្លៃមូលដ្ឋាន",
    staff: "បុគ្គលិក",
    subTotal: "សរុបរង",
    durationMonths: "រយៈពេល (ខែ)",
    selectADuration: "ជ្រើសរើសរយៈពេល",
    oneMonth: "1 ខែ",
    twoMonths: "2 ខែ",
    threeMonths: "3 ខែ",
    sixMonths: "6 ខែ",
    twelveMonths: "12 ខែ",
    eighteenMonths: "១៨ ខែ",
    twentyFourMonths: "24 ខែ",
    twoMonthsFree: "(ឥតគិតថ្លៃ ២ ខែ)",
    threeMonthsFree: "(ឥតគិតថ្លៃ ៣ ខែ)",
    fiveMonthsFree: "(ឥតគិតថ្លៃ ៥ ខែ)",
    yourAccountHasBeen: "គណនីរបស់អ្នកបានហើយ",
    renewed: "ជាថ្មី",
    upgraded: "ធ្វើឱ្យប្រសើរឡើង",
    successfully: "ដោយជោគជ័យ",
    yourSubscription: "ការជាវរបស់អ្នក",
    youAreCurrentlyEnrolledOnA: "បច្ចុប្បន្នអ្នកបានចុះឈ្មោះនៅលើអេ",
    pleaseChooseAPaymentOption: "សូមជ្រើសរើសជម្រើសបង់ប្រាក់",
    planRenewal: "ផែនការបន្តឡើងវិញ",
    planUpgrade: "ធ្វើឱ្យប្រសើរផែនការ",
    pleaseSelectDurationToPayFor: "សូមជ្រើសរើសរយៈពេលដែលអ្នកចង់ចំណាយ",
    staffAccounts: "គណនីបុគ្គលិក",
    ecommerce: "ពាណិជ្ជកម្មអេឡិចត្រូនិក",
    pleaseSelectAPlan: "សូមជ្រើសរើសគម្រោង",
    includeAddons: "រួមបញ្ចូលកម្មវិធីបន្ថែម",
    viewTransactions: "មើលប្រតិបត្តិការ",
    customerHasNoCompletedTansactions:
      "អតិថិជនមិនទាន់បានបញ្ចប់ការសម្រុះសម្រួលនៅឡើយទេ",
    branch: "សាខា",
    enterNumberOfEcommerceBranches: "បញ្ចូលចំនួនសាខាអេឡិចត្រូនិច",
    enterNumberOfEcommerceBranchesToPay:
      "បញ្ចូលចំនួនសាខាអេឡិចត្រូនិកដែលអ្នកចង់បង់ប្រាក់",
    ecommerceIntegration: "សមាហរណកម្មអេឡិចត្រូនិច",
    enterNumberOfBranches: "បញ្ចូលចំនួនសាខា",
    enterNumberOfAdditionalBranchesToPay:
      "បញ្ចូលចំនួនសាខាបន្ថែមដែលអ្នកចង់បង់ប្រាក់",
    enterNumberOfStaffs: "បញ្ចូលចំនួនបុគ្គលិក",
    enterNumberOfStaffsToPayFor: "បញ្ចូលចំនួនបុគ្គលិកដែលអ្នកចង់ចំណាយ",
    discountApplies: "ការបញ្ចុះតម្លៃអនុវត្ត",
    starsOnThe: "ផ្កាយនៅលើ",
    offer: "ការផ្តល់ជូន",
    get: "ទទួល",
    moreStarsToRedeem: "ផ្កាយជាច្រើនដើម្បីលោះ",
    taxVat: "ពន្ធ (អាករ)",
    callCashierToCompletePayment: "ហៅអ្នកគិតលុយដើម្បីបំពេញការទូទាត់",
    receipt: "បង្កាន់ដៃ",
    dear: "សូមគោរព",
    thankYouForYourOrderFindGoods:
      "សូមអរគុណចំពោះការបញ្ជាទិញរបស់អ្នក។ សូមស្វែងរកទំនិញដែលបានផ្តល់ជូនដូចបានព្រមព្រៀងគ្នា។",
    shippingNote: "ចំណាំដឹកជញ្ជូន",
    enterShippingNote: "បញ្ចូលចំណាំដឹកជញ្ជូន",
    shippingAddress: "អាសយដ្ឋានដឹកជញ្ជូន",
    enterShippingAddress: "បញ្ចូលអាសយដ្ឋានដឹកជញ្ជូន",
    wellDoneYouAreDueToRedeem: "ល្អ​ណាស់! អ្នកត្រូវសង",
    toGetYourRewardNextVisit:
      "ដើម្បីទទួលបានរង្វាន់នៅដំណើរទស្សនកិច្ចបន្ទាប់របស់អ្នក។ សូមអរគុណ",
    pointsOnThe: "ពិន្ទុនៅលើ",
    morePointsToRedeem: "ពិន្ទុបន្ថែមដើម្បីប្រោសលោះ",
    showCode: "បង្ហាញលេខកូដ",
    toGetYourRewardOnNextVisit:
      "ដើម្បីទទួលបានរង្វាន់នៅដំណើរទស្សនកិច្ចបន្ទាប់របស់អ្នក។ សូមអរគុណ",
    earn: "រកបាន",
    delivaryNote: "កំណត់សំគាល់បណ្ណសារ",
    draftSales: "សេចក្តីព្រាងការលក់",
    startDate: "ថ្ងៃ​ចាប់ផ្តើម",
    endDate: "កាលបរិច្ឆេទបញ្ចប់",
    orders: "ការបញ្ជាទិញ",
    checkout: "ពិនិត្យ​មុន​ពេល​ចេញ",
    noProductItem: "គ្មានធាតុផលិតផល",
    selectProductImage: "ជ្រើសរើសរូបភាពផលិតផល",
    selectCountry: "ជ្រើសរើស​ប្រទេស",
    selectRegion: "ជ្រើសរើសរដ្ឋ/តំបន់",
    printProductTag: "បោះពុម្ពស្លាកផលិតផល",
    transactionReference: "សេចក្តីយោងប្រតិបត្តិការ",
    Cashier: "អ្នកគិតលុយ",
    Manager: "អ្នកគ្រប់គ្រង",
    Owner: "ម្ចាស់",
    Admin: "អ្នកគ្រប់គ្រង",
    addPartners: "បន្ថែមដៃគូ",
    addNewLoyaltyPartner: "បន្ថែមដៃគូភក្ដីភាពថ្មី។",
    pleaseEnterCompanyName: "សូមបញ្ចូលឈ្មោះក្រុមហ៊ុន",
    companyName: "ឈ្មោះ​ក្រុម​ហ៊ុន",
    pleaseEnterCompanyRepName: "សូមបញ្ចូលឈ្មោះតំណាងក្រុមហ៊ុន",
    companyRepName: "ឈ្មោះអ្នកតំណាងក្រុមហ៊ុន",
    pleaseEnterCompanyRepEmail: "សូមបញ្ចូលអ៊ីមែលរបស់អ្នកតំណាងក្រុមហ៊ុន",
    companyRepEmail: "អ៊ីមែលតំណាងក្រុមហ៊ុន",
    pleaseEnterCompanyRepPhone: "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នកតំណាងក្រុមហ៊ុន",
    companyRepPhone: "លេខទូរស័ព្ទតំណាងក្រុមហ៊ុន",
    addReward: "បន្ថែមរង្វាន់",
    pleaseEnterRewardName: "សូមបញ្ចូលឈ្មោះរង្វាន់",
    rewardName: "ឈ្មោះរង្វាន់",
    rewardQuantity: "បរិមាណរង្វាន់",
    rewardDescription: "ការពិពណ៌នាអំពីរង្វាន់",
    rewardType: "ប្រភេទរង្វាន់",
    pleaseEnterRewardType: "សូមបញ្ចូលប្រភេទរង្វាន់",
    pleaseEnterRewardQuantity: "សូមបញ្ចូលបរិមាណរង្វាន់",
    pleaseEnterRewardDescription: "សូមបញ្ចូលការពិពណ៌នាអំពីរង្វាន់",
    fineDining: "អាហារឆ្ងាញ់",
    luxuryFashion: "ម៉ូតប្រណិត",
    hotels: "សណ្ឋាគារ",
    travel: "ការធ្វើដំណើរ",
    foodAndBeverage: "អាហារ​នឹង​ភេសជ្ជៈ",
    fashion: "ម៉ូដ",
    health: "សុខភាព",
    furniture: "គ្រឿងសង្ហារិម",
    entertainment: "ការកំសាន្ត",
    automobile: "រថយន្ត",
    education: "ការអប់រំ",
    beautyAndSpa: "សម្រស់ និងស្ប៉ា",
    staycation: "ការស្នាក់នៅ",
    events: "ព្រឹត្តិការណ៍",
    trips: "ការ​ធ្វើដំណើរ",
    oilAndGas: "ប្រេងនិងឧស្ម័ន",
    laundry: "បោកគក់",
    partnerCategory: "ប្រភេទដៃគូ",
    freeItem: "ធាតុឥតគិតថ្លៃ",
  },
  Kinyarwanda: {
    cashier: "kashi",
    manager: "umuyobozi",
    owner: "nyirayo",
    online: "kumurongo",
    offline: "kumurongo",
    changePassword: "Hindura ijambo ryibanga",
    currentPasswordMessage: "Nyamuneka andika ijambo ryibanga",
    passwordMessage: "Nyamuneka andika ijambo ryibanga",
    currentPassword: "Ijambobanga ryubu",
    password: "ijambo ryibanga",
    confirmPasswordMessage: "Nyamuneka wemeze ijambo ryibanga!",
    confirmPassword: "Emeza ijambo ryibanga",
    sendViaEmail: "Kohereza ukoresheje imeri",
    sendViaWhatsapp: "Ohereza Binyuze kuri WhatsApp",
    downloadPdf: "Kuramo PDF",
    paid: "yishyuwe",
    unpaid: "adahembwa",
    partial: "igice",
    closeInvoice: "Urashaka gufunga Inyemezabuguzi?",
    closeInvoiceConfirmation:
      "Inyemezabuguzi ntishobora kubikwa. Urashaka gufunga?",
    yes: "yego",
    no: "oya",
    invoice: "Inyemezabuguzi",
    wasDeducted: "yakuweho",
    for: "Kuri",
    item: "Ingingo",
    addProduct: "Ongeraho ibicuruzwa",
    paymentReference: "Ibyerekeye Kwishura",
    amountPaid: "Amafaranga yishyuwe",
    discountAmount: "Amafaranga yagabanutse",
    amountDue: "Umubare Ukwiye",
    amount: "Umubare",
    dueDate: "Itariki ntarengwa",
    paymentType: "Ubwoko bwo Kwishura",
    card: "Ikarita",
    cash: "Amafaranga",
    bankTransfer: "Kohereza Banki",
    paymentMessage: "Ubutumwa bwo Kwishura",
    description: "Ibisobanuro",
    sendReceipt: "Kohereza inyemezabwishyu",
    delete: "Siba",
    save: "Bika",
    resend: "Ongera wohereze",
    saveAndSend: "Bika",
    invoiceSaved: "Inyemezabuguzi yazigamye!",
    selectPaymentMethod: "Nyamuneka hitamo uburyo bwo Kwishura!",
    selectCustomer: "Nyamuneka hitamo Umukiriya!",
    cartEmptyError:
      "Urutonde rwikarita ntirushobora kuba ubusa, funga inyemezabuguzi hanyuma wongere Ikintu mukigare!",
    subscriptionExpired:
      "Kwiyandikisha kwawe kurangiye kandi konte yawe irahari. Kanda kuri Buto hepfo kugirango wongere konti yawe",
    renew: "Kuvugurura",
    holdOn: "Komera",
    customerBank: "Banki y'abakiriya",
    cancel: "Kureka",
    selectACustomer: "Hitamo Umukiriya",
    invoiceSuccessfulPdfError:
      "Inyemezabuguzi yakozwe neza ariko Igisekuru cya PDF gifata igihe kirenze icyari gisanzwe. Nyamuneka reba vuba.",
    downloadingInvoice: "Gukuramo inyemezabuguzi",
    downloadingReceipt: "Gukuramo inyemezabwishyu",
    whatsappReceiptError:
      "Ikosa ryabaye ryohereza inyemezabuguzi ukoresheje WhatsApp, Nyamuneka gerageza.",
    receiptToWhatsapp: "Inyemezabwishyu yoherejwe kuri WhatsApp",
    thankYouForPatronage: "Urakoze kubufasha bwawe",
    hereIsYourReceipt: "Dore inyemezabwishyu yawe",
    errorSendingEmailReceipt:
      "Ikosa ryabaye ryohereza inyemezabuguzi ukoresheje imeri, nyamuneka gerageza cyangwa usabe inkunga",
    receiptSentToEmail: "Inyemezabwishyu yoherejwe kuri imeri y'abakiriya",
    invoiceSentToEmail: "Inyemezabuguzi yoherejwe kuri imeri y'abakiriya",
    invoiceSuccessWhatsappError:
      "Inyemezabuguzi yakozwe neza ariko habaye ikosa ryohereza kuri WhatsApp. Gerageza nanone kurutonde rwa fagitire",
    invoiceSuccessfulEmailError:
      "Inyemezabuguzi yakozwe neza ariko habaye ikosa mugihe wohereje nka e-imeri. Nyamuneka gerageza nanone uhereye kuri fagitire",
    invoiceSentToWhatsapp: "Inyemezabuguzi yoherejwe kuri WhatsApp",
    hello: "Mwaramutse",
    pleaseDownloadInvoice: "Nyamuneka kura inyemezabuguzi",
    pleaseDownloadReceipt: "Nyamuneka kura inyemezabwishyu",
    from: "Kuva",
    email: "Imeri",
    upgrade: "Kuzamura",
    youAreOnFreePlan: "Uri kuri Gahunda Yubusa.",
    clickOn: "Kanda kuri",
    yourPlanInFewSteps: " kwiyandikisha kwawe muntambwe nke zihuse.",
    to: "Kuri",
    yourSubscriptionHasExpired:
      "Kwiyandikisha kwawe kurangiye kandi konte yawe irahari.",
    days: "iminsi",
    yourSubscriptionExpiresIn: "Kwiyandikisha kwa Loystar birarangiye",
    createAcount: "Kora Konti",
    signIn: "Injira",
    continue: "Komeza",
    enterOtp: "Injira OTP PIN",
    enterValidOtp: "Nyamuneka andika PIN yemewe",
    pin: "PIN",
    tokenSentToMail: "Ikimenyetso cyoherejwe kuri imeri yawe",
    passwordResetSuccessful: "Gusubiramo ijambo ryibanga byagenze neza",
    somethingWentWrong: "Ikintu kitagenze neza!",
    resetPassword: "Ongera usubize ijambo ryibanga",
    iHaveResetCode: "Mfite ijambo ryibanga ryibanga",
    pleaseEnterEmail: "Nyamuneka andika imeri yawe",
    aTokenWillBeSentToEmail: "Ikimenyetso cyoherezwa kuri imeri yawe",
    enterEmail: "Injira imeri yawe",
    sendinYourToken: "Kohereza ikimenyetso cyawe ...",
    makeSureItMatchesPassword: "Menya neza ko bihuye n'ijambo ryibanga rishya",
    pleaseChooseNewPassword: "Nyamuneka hitamo ijambo ryibanga rishya",
    chooseNewPassword: "Hitamo ijambo ryibanga rishya",
    enterNewPassword: "Injira ijambo ryibanga rishya kugirango wemeze",
    enterTokenSent: "Injira ikimenyetso cyoherejwe na mail yawe",
    resetToken: "Ongera uhagarike",
    resettingPassword: "Kugarura ijambo ryibanga ...",
    signUp: "Iyandikishe",
    adminSignInWithEmail:
      " Admin yinjira hamwe na imeri mugihe abakozi binjiye hamwe nizina ryukoresha.",
    pleaseEnterEmailOrUsername:
      "Nyamuneka andika imeri yawe cyangwa izina ukoresha",
    emailOrUsername: "Imeri cyangwa izina ryukoresha",
    pleaseEnterPassword: "Nyamuneka andika ijambo ryibanga",
    createAnAccount: "Kora Konti",
    forgotPassword: "Wibagiwe ijambo ryibanga?",
    enterPhoneNumber: "Injiza nimero ya terefone",
    personalData: "Amakuru yihariye",
    validateConfirmationCOde: "Kwemeza Kode Yemeza",
    pleaseEnterFirstName: "Nyamuneka andika izina ryawe",
    pleaseEnterPhoneNumber: "Nyamuneka andika numero yawe ya terefone",
    pleaseEnterLastName: "Nyamuneka andika izina ryawe ryanyuma",
    pleaseEnterBusinessName: "Nyamuneka andika izina ryubucuruzi",
    firstName: "Izina",
    lastName: "Izina ryanyuma",
    businessName: "Izina ryubucuruzi",
    previous: "Mbere",
    next: "Ibikurikira",
    pleaseSelectBusinessCategory: "Nyamuneka hitamo icyiciro cyawe",
    pleaseEnterValidEmail: "Nyamuneka andika imeri yemewe",
    pleaseEnterPostCode: "Nyamuneka andika kode y'iposita",
    postCode: "Kode y'iposita",
    phoneNumberInUse: "Iyi nimero ya terefone irakoreshwa!",
    emailInUse: "Iyi imeri irakoreshwa!",
    foodAndDrinks: "Ibiryo n'ibinyobwa",
    salonAndBeauty: "Salon n'Ubwiza",
    fashionAndAccessories: "Imyambarire n'ibikoresho",
    gymAndFitness: "Imikino ngororamubiri",
    travelAndHotel: "Urugendo na Hotel",
    homeAndGifts: "Urugo n'impano",
    washingAndCleaning: "Gukaraba no kweza",
    gadgetsAndElectronics: "Ibikoresho na Electronics",
    groceries: "Ibiribwa",
    others: "Abandi",
    submit: "Tanga",
    accountCreatedSuccessful: "Konti yawe yashizweho neza.",
    pleaseEnterAddress: "Nyamuneka Andika Aderesi yawe",
    addressLine1: "Umurongo wa aderesi 1",
    addressLine2: "Umurongo wa aderesi 2",
    choosePassword: "Hitamo ijambo ryibanga",
    passwordMustBe6Characters: "Ijambobanga rigomba kuba byibuze inyuguti 6.",
    howDidYouHearLoystar: "Wumvise ute ibya Loystar?",
    referralCode: "Kode yoherejwe",
    byClickingTheButton: " Ukanze buto hepfo, wemera kuri",
    termsAndSevice: "Amagambo",
    wereCreatingAccount: "Turimo gukora konti yawe",
    proceed: "Komeza",
    verificationCodeMustBe6: "Kode yo kugenzura igomba kuba imibare 6",
    pleaseEnter6DigitCode: "Nyamuneka Andika kode 6",
    enterVerificationCode: "Injira Kode yo Kugenzura",
    resendToken: "Subiza Token",
    verify: "Kugenzura",
    transactions: "Gucuruza",
    todaySales: "Kugurisha Uyu munsi",
    salesHistory: "Amateka yo kugurisha",
    supplyHistory: "Tanga Amateka",
    new: "Gishya",
    invoices: "Inyemezabuguzi",
    disbursements: "Amafaranga yatanzwe",
    offlineSales: "Kugurisha kumurongo",
    products: "Ibicuruzwa",
    customers: "Abakiriya",
    multiLevelLoyalty: "Ubudahemuka Bwinshi",
    loyaltyPrograms: "Gahunda Zubudahemuka",
    members: "Abanyamuryango",
    appStore: "Ububiko bwa porogaramu",
    orderMenu: "Tegeka Ibikubiyemo",
    settings: "Igenamiterere",
    staffAndBranches: "Abakozi n'amashami",
    myAccount: "Konti yanjye",
    switchToSellMode: "Hindura kugurisha uburyo",
    signOut: "Sohoka",
    getFreeSubscription: "Kubona Kwiyandikisha Kubuntu",
    onlyNumbersAllowed: "Imibare yonyine iremewe",
    yourAccountMustBe10Digits: "nimero ya konte yawe igomba kuba nimero 10",
    yourBvnMustBe11: "BVN yawe igomba kuba numero 11",
    pleaseSelectBank: "Nyamuneka hitamo banki yawe",
    selectYourBank: "Hitamo Banki yawe",
    enterBankAccountNumber: "Injiza nimero ya konti ya banki",
    enterBvnNumber: "Injira BVN yawe",
    connectBankAccount: "Huza Konti ya Banki",
    passwordResetSuccessfulAndSignOut:
      "Ijambobanga ryawe ryasubiwemo neza. Kanda kuri bouton yo gusohoka hepfo kugirango wongere winjire",
    enterCurrentPassword: "Injira ijambo ryibanga",
    pleaseEnterCurrentPassword: "Nyamuneka andika ijambo ryibanga",
    phoneNumber: "Numero ya terefone",
    sex: "Imibonano mpuzabitsina",
    dateOfBirth: "Itariki y'amavuko",
    state: "Leta",
    country: "Igihugu",
    loyaltyId: "Indangamuntu",
    createdAt: "Byaremewe At",
    noOfTransactionsMade: "Oya",
    yourDownloadWillStart: "Gukuramo kwawe bizatangira mu kanya",
    exportCustomers: "Kohereza abakiriya",
    youhaveSuccessfullyToppedUp: "Watsinze neza SMS yawe.",
    youNowHave: "Ubu ufite",
    smsUnits: "Ibice bya SMS",
    enterNumberOfSmsUnits: "Injiza Umubare wa SMS Units Uteganya Kugura",
    pleaseEnterANumericValue: "Nyamuneka andika umubare",
    pay: "Kwishura",
    accountEditedSuccessfully: "Konti yawe yahinduwe neza.",
    youAeCurrentlyOn: "Ubu uriho",
    plan: "Tegura",
    userData: "Umukoresha Amakuru",
    businessData: "BUSINESS DATA",
    businessCategory: "Catergory",
    pleaseSelectCurrency: "Nyamuneka hitamo ifaranga ryawe",
    selectYourCurrency: "Hitamo ifaranga ryawe",
    purchaseMoreSmsUnits: "Gura andi makuru ya SMS ukoresheje ifishi ikurikira",
    youHave: "Ufite",
    atLeast4SmsUnitsRrequired:
      "Nibura ibice 4 bya sms birakenewe kugirango bigenzurwe, nyamuneka hejuru!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Nyamuneka reba konti yawe kugirango ukurikize 'Menya umukiriya wawe' (KYC). Ibi bizagufasha gukusanya binyuze muri USSD cyangwa kwimura ako kanya, kwakira ibicuruzwa byabakiriya no gutunganya ibicuruzwa. Bikurikizwa kubacuruzi bo muri Nigeriya gusa. Nyamuneka kanda buto hepfo kugirango utangire.",
    reConnectBankAccount: "Ongera uhuze Konti ya Banki",
    accountName: "Izina rya Konti",
    accountNumber: "Inomero ya Konti",
    bankName: "Izina rya Banki",
    verified: "Byemejwe",
    accountDetails: "Ibisobanuro bya Konti",
    kycBankAccount: "KYC",
    createTier: "Kurema Urwego",
    fileUploadSuccessful: "dosiye yoherejwe neza",
    fileUploadFailed: "kohereza dosiye byarananiranye",
    createLoyaltyProgram: "Kora Gahunda Yubudahemuka",
    createLoyalty: "Shiraho ubudahemuka",
    name: "Izina",
    loyaltyArtwork: "Ubudahemuka",
    clickToUpload: "Kanda kugirango wohereze",
    amountToPointsRatio: "Umubare w'amanota",
    points: "Ingingo",
    recommendedAmountToPointRatio:
      "Basabwe: ₦ 1 kugeza 1 point. ni ukuvuga kuri buri 1 Naira yakoresheje, abakiriya bawe babona amanota 1",
    pleaseTypeIn: "Nyamuneka andika",
    toDeleteLoyalty: "gusiba ubudahemuka",
    deleteLoyalty: "Siba Ubudahemuka",
    toConfirm: "kwemeza",
    edit: "Hindura",
    pointsAwardedSuccessfully: "Amanota yatanzwe neza.",
    enterPointValueToAward: "Injira Agaciro Agaciro Wifuza Gutanga Abakiriya",
    award: "Igihembo",
    awardPointValuesToCustomer: "Agaciro Ingingo Agaciro kubakiriya",
    enrollMembersToLoyalty: "Andika Abanyamuryango Kudahemukira",
    awardPoints: "Amanota",
    enroll: "Iyandikishe",
    home: "Murugo",
    loyalty: "Ubudahemuka",
    enrollCustomers: "Andika abakiriya",
    selected: "Byahiswemo",
    customer: "Umukiriya",
    loyaltyActivationSuccessful: "Gukora ubudahemuka biratsinda.",
    loyaltyDeactivationSuccessful: "Gukuraho ubudahemuka biratsinda.",
    viewTier: "Reba Urwego",
    deactivate: "Kureka",
    activate: "Kora",
    actions: "Ibikorwa",
    nameOfLoyalty: "Izina ry'ubudahemuka",
    loyaltyStatus: "Imiterere y'Ubudahemuka",
    numberOfTiers: "Umubare w'ibyiciro",
    createdOn: "Byaremewe",
    createATier: "Kora urwego",
    stopCreatingTierConfirmation: "Urashaka guhagarika kurema urwego?",
    stopEditingTierConfirmation: "Urashaka guhagarika guhindura iki cyiciro?",
    nameOfTier: "Izina ryurwego",
    minimumSpendingTarget: "Intego ntarengwa yo gukoresha",
    maximumSpendingTarget: "Intego ntarengwa yo gukoresha",
    minimumSpendingTargetRequired: "intego ntarengwa yo gukoresha irasabwa",
    maximumSpendingTargetRequired: "intego ntarengwa yo gukoresha irasabwa",
    rewardSponsor: "Umuterankunga",
    pleaseChooseARewardSponsor: "Nyamuneka hitamo umuterankunga",
    self: "Kwigenga",
    partner: "Umufatanyabikorwa",
    rewardPartner: "Umufatanyabikorwa",
    pleaseSelectRewardPartner: "Nyamuneka hitamo ibihembo byawe",
    rewards: "Ingororano",
    pleaseSelectAReward: "Nyamuneka hitamo igihembo",
    instructionsOnRedeemingReward:
      "Amabwiriza yukuntu Umukiriya agomba gucungura ibihembo",
    pleaseFillInThisField: "Nyamuneka Uzuza uyu murima!",
    toDeleteThisTier: " gusiba urwego",
    deleteTier: "Siba Urwego",
    viewMembers: "Reba Abanyamuryango",
    membersEnrolled: "Abanyamuryango Biyandikishije",
    instruction: "Amabwiriza",
    membersIn: "Abanyamuryango muri",
    availableTiersInLoyalty: "Urwego ruboneka muri gahunda yo Kudahemuka",
    tiers: "Urwego",
    totalTier: "URUPAPURO RUGENDE",
    availableLoyaltyProgramme: "Gahunda Yubudahemuka",
    totalLoyalties: "INDAHEMUKA",
    memberDetails: "Ibisobanuro by'abanyamuryango",
    nameOfCustomer: "Izina ry'umukiriya",
    address: "Aderesi",
    allEnrolledMembers: "Abanyamuryango bose biyandikishije",
    thisIsToWishYouHappyBirthday:
      "Nukwifuriza isabukuru nziza cyane nubuzima bwiza. Urakoze kubyo uri byose kuri Loyster. Ibirori byiza!",
    inputAnOfferPlease: "Shyiramo igitekerezo nyamuneka",
    pleaseSelectTheInsertPoint:
      "Nyamuneka hitamo gushyiramo ingingo mubutumwa hanyuma ukande",
    birthdayOfferAndMessage: "Isabukuru y'amavuko n'ubutumwa",
    birthdayOffer: "Isabukuru y'amavuko",
    birthdayMessage: "Ubutumwa bw'amavuko",
    noOfferFound: "Nta cyifuzo cyabonetse",
    settingABirthdayOffer:
      "Gushiraho isabukuru y'amavuko yemerera abakiriya kwakira iki cyifuzo bakoresheje SMS kumunsi w'amavuko",
    createOffer: "Kurema Ituro",
    whatIsTheOffer: "Ni ubuhe butumwa?",
    editMessage: "Hindura ubutumwa",
    insert: "Shyiramo",
    theNameOfCustomerInserted: "Izina ryumukiriya rizashyirwa hano",
    theBirtdayOfferInserted: "Isabukuru y'amavuko izashyirwa hano",
    youSuccessfullyAddedNewBranch: "Wongeyeho neza ishami rishya!",
    addNewBranch: "Ongeraho Ishami Rishya",
    addBranch: "Ongeraho Ishami",
    additionalBranchWillIncur: "Ishami ry'inyongera ryakora",
    perBranch: "ku ishami",
    ecommerceBranchCosts: "Ibiciro by'ishami rya Ecommerce",
    pleaseEnterBranchName: "Nyamuneka andika izina ryishami",
    pleaseEnterBranchAddress1:
      "Nyamuneka andika umurongo wa aderesi yumurongo 1",
    enterBranchAddress1: "Injira umurongo wa aderesi yumurongo 1",
    enterBranchAddress2: "Injira umurongo wa aderesi yumurongo 1",
    pleaseEnterBranchAddress2: "Nyamuneka andika adresse yumurongo wa 2",
    enterBranchName: "Injiza izina ryishami",
    successfullyAddedStaff: "Wongeyeho neza abakozi bashya!",
    addNewStaff: "Ongeraho Abakozi bashya",
    addStaff: "Ongeraho Abakozi",
    additionalStaffWillIncur: "Abakozi b'inyongera bari",
    perStaff: "ku bakozi.",
    pleaseEnterStaffEmail: "Nyamuneka andika imeri y'abakozi",
    pleaseEnterStaffUsername: "Nyamuneka andika izina ryabakozi",
    pleaseEnterStaffPassword: "Nyamuneka andika ijambo ryibanga ryabakozi",
    pleaseSelectStaffRole: "Nyamuneka hitamo uruhare rw'abakozi",
    selectStaffRole: "Hitamo uruhare rw'abakozi",
    enterStaffEmail: "Injira imeri y'abakozi",
    enterStaffUsername: "Injira izina ryabakozi",
    enterStaffPassword: "Injira ijambo ryibanga ryabakozi",
    spacesNotAllowedInUsername: "imyanya ntabwo yemerewe mwizina ryumukoresha",
    admin: "Umuyobozi",
    pleaseSelectBusinessToAttachStaff:
      "Nyamuneka hitamo ubucuruzi kugirango uhuze abakozi",
    searchForBranchToAttachStaff: "Shakisha ishami ryo guhuza abakozi",
    username: "Izina ryukoresha",
    role: "Uruhare",
    areYouSureToDeleteThis: "Uzi neza gusiba ibi",
    branches: "Amashami",
    upgradeYourPlan: "Kuzamura gahunda yawe.",
    add: "ADD",
    addNew: "Ongeraho Gishya",
    customerWithEmailAlreadyExists:
      "Umukiriya ufite imeri / nimero ya terefone asanzweho!",
    successfullyAddedNewCustomer: "Wongeyeho neza umukiriya mushya!",
    addCustomer: "Ongeraho Umukiriya",
    pleaseEnterCustomerFirstName: "Nyamuneka andika izina ryumukiriya",
    pleaseEnterCustomerLastName: "Nyamuneka andika izina ryanyuma ryabakiriya",
    pleaseEnterCustomerPhoneNumber:
      "Nyamuneka andika nimero ya terefone y'abakiriya",
    pleaseEnterCustomerEmail: "Nyamuneka andika imeri y'abakiriya",
    pleaseEnterCustomerAddressLine:
      "Nyamuneka andika umurongo wa aderesi y'abakiriya",
    pleaseEnterCustomerOtherAddress: "Nyamuneka andika andi Aderesi yabakiriya",
    pleaseSelectCustomerGender: "Nyamuneka hitamo igitsina cy'abakiriya",
    gender: "Uburinganire",
    male: "Umugabo",
    female: "Umugore",
    bank: "Banki",
    selectBank: "Hitamo Banki",
    stateOrRegionOrProvince: "Intara / Intara / Intara",
    customerNotes: "Inyandiko z'abakiriya",
    sendSms: "Kohereza SMS",
    editCustomer: "Hindura umukiriya",
    redeemReward: "Gucungura ibihembo",
    issueLoyaltyCard: "Tanga Ikarita Yubudahemuka",
    deleteCustomer: "Siba Umukiriya",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Watsinze neza Indangamuntu Yabanyamuryango",
    noMembershipIdAvailable:
      "Nta ndangamuntu zabanyamuryango zihari. Nyamuneka twandikire kuri salo@loystar.co",
    sendEmail: "Ohereza imeri",
    membershipPoints: "Ingingo z'Abanyamuryango",
    customerDetails: "Ibisobanuro by'abakiriya",
    close: "Funga",
    loyaltyBalance: "Kuringaniza Ubudahemuka",
    pointsBalance: "Impirimbanyi",
    starBalance: "Iringaniza ry'inyenyeri",
    requiredPoints: "Ingingo zisabwa",
    requiredStars: "Inyenyeri Zisabwa",
    reddemCode: "Gucungura Kode",
    toDeleteThisCustomer: "gusiba uyu mukiriya",
    customerHasBeenDeletedSuccessfully: "Umukiriya yasibwe neza!",
    customerWithPhoneAlreadyExists:
      "Umukiriya ufite numero ya terefone asanzweho",
    customerWasSuccessfullyEdited: "Umukiriya yahinduwe neza",
    anErrorOccured: "Habayeho ikosa",
    phoneNumberIncludeCountry: "Numero ya terefone (Shyiramo kode yigihugu)",
    yourFileQueuedForUpload:
      "Idosiye yawe yatonze umurongo kugirango wohereze. Nyamuneka reba page nyuma yamasegonda make.",
    thereWasAnErrorPerformingOperation: "Habayeho kwibeshya gukora operasiyo!",
    pleaseSelectFile: "Nyamuneka Hitamo Idosiye!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Gusa dosiye ya CSV iremewe. Nyamuneka ohereza dosiye .csv.",
    fileShouldBeSmallerThan5Mb:
      "Idosiye igomba kuba nto kurenza 5MB. Niba ufite dosiye nini, nyamuneka andikira inkunga@loystar.co. Murakoze",
    customerFirstNameIsRequired: "umukiriya izina ryambere risabwa kumurongo",
    customerPhoneNumberIsRequired:
      "nimero ya terefone yumukiriya irasabwa kumurongo",
    importCustomers: "Kuzana abakiriya",
    upload: "Kuramo",
    clickIconToDownloadCsv:
      "Kanda iyi shusho kugirango ukuremo icyitegererezo cya dosiye ya CSV.",
    getGoogleSheetVersion: "Shakisha urupapuro rwa Google",
    clickOrDragFileToUpload:
      "Kanda cyangwa ukurura dosiye muri kariya gace kugirango wohereze",
    missingOrInvalidColumnHeader:
      "Kubura cyangwa gutesha agaciro inkingi y'umutwe. Nyamuneka kurikira imiterere yicyitegererezo. Murakoze.",
    youHaveImported: "Winjije mu mahanga",
    youSuccessfullyRedeemedReward: "Wacunguye neza ibihembo byawe!",
    sixDigitCode: "Kode y'imibare itandatu",
    pleaseEnterCustomerRewardCode:
      "Nyamuneka andika kode yigihembo cyabakiriya",
    enterRewardCode: "Injira kode yigihembo. (Kode yigihembo iroroshye)",
    selectLoyaltyProgram: "Hitamo gahunda y'ubudahemuka",
    stamps: "Kashe",
    smsUnitsLow: "SMS Ibice biri hasi",
    pleaseTopUpSmsUnits: "Nyamuneka Hejuru Hejuru ya SMS",
    smsSuccessfullySentForDelivery: "SMS yoherejwe neza kubitanga!",
    sendSmsTo: "Ohereza SMS kuri",
    allCustomers: "Abakiriya bose",
    unitsAvailable: "Ibice birahari",
    pleaseTypeInTheMessage: "Nyamuneka andika ubutumwa",
    message: "Ubutumwa",
    charactersRemaining: "Inyuguti zisigaye",
    avoidUseOfSpecialCharacters:
      "Irinde gukoresha inyuguti zidasanzwe na Emojis kugirango ubungabunge SMS.",
    note: "Icyitonderwa",
    errorFetchingCustomersMultilevelDetail:
      "Ikosa Kubona Abakiriya MultiLevel Ibisobanuro",
    search: "Shakisha",
    reset: "Gusubiramo",
    importCustomer: "Kuzana umukiriya",
    addNewCustomer: "Ongeraho Umukiriya mushya",
    sendSmsBroadcast: "Kohereza ubutumwa bugufi",
    totalCustomers: "ABAKUNZI BOSE",
    disbursementDetails: "Ibisobanuro birambuye",
    paidBy: "Yishyuwe na",
    disbursed: "Yatanzwe",
    bankAccountName: "Izina rya Konti ya Banki",
    bankAccountNumber: "Inomero ya konti ya banki",
    transferInitiated: "Iyimurwa ryatangijwe",
    transferCompleted: "Iyimurwa ryarangiye",
    pleaseEnterAValid: "Nyamuneka andika byemewe",
    begin: "tangira",
    end: "iherezo",
    date: "itariki",
    paymentDate: "Itariki yo Kwishura",
    selectDisbursementDuration: "Hitamo igihe cyo gutanga",
    totalSettled: "Byose Byakemuwe",
    totalUnsettled: "Igiteranyo cyose",
    toDeleteThisSale: "gusiba kugurisha",
    draftSaleDeletedSuccessfully: "Inyandiko yo kugurisha yasibwe neza!",
    deleteSale: "Gusiba Igurisha",
    pleaseTypeInYourTotal:
      "Nyamuneka andika muri rusange kugirango wemeze gusiba",
    billDetails: "Umushinga w'itegeko",
    printBill: "Shira ahagaragara",
    servedBy: "Byakorewe na",
    total: "Igiteranyo",
    createdDate: "Itariki Yashyizweho",
    createdTime: "Igihe cyaremewe",
    status: "Imiterere",
    loginSuccessful: "Injira Byagenze neza",
    pleaseWaitWhileWeConnectAccount:
      "Nyamuneka tegereza mugihe duhuza konte yawe",
    connectionFailedTryAgain: "Kwihuza byarananiranye. Nyamuneka gerageza.",
    connectionSuccessful: "Kwihuza byagenze neza",
    viewDetails: "Reba Ibisobanuro",
    enable: "Gushoboza",
    free: "Ubuntu",
    cost: "Igiciro",
    visitWebsite: "Sura Urubuga",
    pleaseUpgradeYourPlanToPro:
      "Nyamuneka Kuzamura gahunda yawe kuri Pro Plus kugirango ushoboze iyi porogaramu",
    connectYourBankAccount:
      "Huza Konti yawe ya Banki kugirango ubashe kwakira ubwishyu.",
    disable: "Hagarika",
    enableApp: "Gushoboza porogaramu",
    addNewProductToInvoice: "Ongeraho Ibicuruzwa bishya kuri fagitire",
    toDeleteThisInvoice: "gusiba iyi fagitire",
    invoiceDeletedSuccessfully: "Inyemezabuguzi yasibwe neza!",
    deleteInvoice: "Siba Inyemezabuguzi",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Wongeyeho ubutumwa bwo kwishyura fagitire!",
    pleaseEnterPaymentInstructions: "Nyamuneka andika amabwiriza yo kwishyura",
    invoiceId: "Inyemezabuguzi",
    paidDate: "Itariki Yishyuwe",
    reference: "Reba",
    productAdded: "Ibicuruzwa byongeyeho",
    productOutOfStock: "Ibicuruzwa bitabitswe. Nyamuneka ongera ubike.",
    totalInvoices: "AMAFARANGA YOSE",
    totalPaidInvoices: "AMAFARANGA YISHYUWE",
    totalUnpaidInvoices: "INGINGO ZIDASANZWE",
    loyaltyProgramDeleted: "Gahunda yo Kudahemuka Yasibwe",
    thisLoyaltyProgramDeletedSuccessfully:
      "Iyi gahunda yubudahemuka yasibwe neza",
    thisLoyaltyProgramEditedSuccessfully:
      "Iyi gahunda yubudahemuka yahinduwe neza",
    loyaltyProgramAddedSuccessfully: "Gahunda y'ubudahemuka yongeyeho neza",
    loyaltyProgramEdited: "Gahunda yo Kudahemuka Yahinduwe",
    loyaltyProgramAdded: "Gahunda yo Kudahemuka Yongeyeho",
    loyaltyDetails: "Ubudahemuka",
    doYouWantToCloseDialog: "Urashaka gufunga iki kiganiro?",
    pleaseEnterLoyaltyName: "Nyamuneka andika izina ry'ubudahemuka bwawe",
    programType: "Ubwoko bwa Porogaramu",
    pleaseSelectProgramType: "Nyamuneka hitamo ubwoko bwa porogaramu",
    simplePoints: "Ingingo zoroshye",
    stampsProgram: "Gahunda ya kashe",
    threshold: "Imipaka",
    pleaseEnterLoyaltyThreshold: "Nyamuneka andika ubudahemuka",
    reward: "Igihembo",
    pleaseEnterLoyaltyReward: "Nyamuneka andika ibihembo byubudahemuka",
    totalUserPoints: "Ingingo zose zabakoresha",
    totalUserStamps: "Ikimenyetso Cyuzuye Cyabakoresha",
    spendingTarget: "Intego yo gukoresha",
    spendingTargetHint1:
      "Intego yo gukoresha ni amafaranga umukiriya agomba gukoresha kugirango abone ibihembo. Agaciro k'ifaranga = amanota 1.",
    spendingTargetHint2:
      "Intego ya kashe ni kashe zingahe umukiriya agomba kwegeranya kugirango abone ibihembo. urugero. 5",
    addNewLoyaltyProgram: "Ongeraho Gahunda Ndahemuka",
    addLoyaltyProgram: "Ongeraho Gahunda Yubudahemuka",
    loyaltyProgramType: "Ubwoko bwa Gahunda Yubudahemuka",
    pleaseSelectLoyaltyProgramType:
      "Nyamuneka hitamo ubwoko bwa gahunda y'ubudahemuka",
    nameOfProgram: "Izina rya gahunda",
    pleaseEnterProgramName: "Nyamuneka andika izina rya porogaramu",
    whatIsTheReward: "Igihembo ni ikihe?",
    egNextPurchaseIsFree: "E.g. Kugura ubutaha ni ubuntu",
    customerName: "Izina ryabakiriya",
    guestCustomer: "Umukiriya",
    orderConfirmedSuccessfully: "Iteka ryemejwe neza",
    orderCancelledSuccessfully: "Ibicuruzwa byahagaritswe neza",
    confirmOrder: "Emeza gahunda",
    cancelOrder: "Kuraho itegeko",
    allOrders: "Amabwiriza yose",
    totalOrders: "AMATEGEKO YOSE",
    doYouWantToAcceptOrder: "Urashaka kwemera iri teka?",
    doYouWantToCancelOrder: "Urashaka guhagarika iri teka?",
    orderDetails: "Tegeka Ibisobanuro",
    orderCreatedAt: "Iteka ryashyizweho kuri",
    supplier: "Utanga isoko",
    productName: "izina RY'IGICURUZWA",
    quantity: "Umubare",
    unitPrice: "Igiciro",
    receivedBy: "Yakiriwe na",
    reportFrom: "Raporo kuva",
    totalSupplies: "Ibikoresho byose",
    allRightsReserved: "Uburenganzira bwose burabitswe",
    toDeleteThisTransaction: "gusiba iyi Transaction",
    transactionDeletedSuccessfully:
      "Igicuruzwa cyasibwe neza. Ububiko bwasubijwe kubarwa.",
    deleteTransaction: "Siba Igicuruzwa",
    transactionDetails: "Ibisobanuro birambuye",
    printReceipt: "Inyemezabwishyu",
    channel: "Umuyoboro",
    discount: "Kugabanuka",
    profit: "Inyungu",
    discountedSales: "Igurishwa ryagabanijwe",
    errorFetchingRecord: "Kubona Ikosa",
    exportTransactions: "Ibicuruzwa byoherezwa mu mahanga",
    errorFetchingSalesRecord: "Ikosa ryo kuzana ibicuruzwa byoherejwe hanze.",
    totalSellingPrice: "Igiciro cyose cyo kugurisha",
    totalCostPrice: "Igiciro cyose",
    appliedDiscount: "Kugabanuka",
    noOfItems: "Oya",
    sales: "Kugurisha",
    export: "Kohereza hanze",
    totalProfit: "Inyungu Yose",
    totalBalanceInPeriod: "Impirimbanyi zose mugihe",
    allTimeSales: "Igihe cyose cyo kugurisha",
    records: "Inyandiko",
    todaysSales: "Kugurisha Uyu munsi",
    transaction: "gucuruza",
    youHaveExceededTotalNumberOfProducts:
      "Warenze umubare wibicuruzwa byemewe kuri gahunda yawe. Kuzamura gahunda yawe kugirango wishimire imipaka irenze.",
    youNeedToHaveLoyaltyProgram:
      "Ukeneye kugira Gahunda Yubudahemuka kugirango ukoreshe iyi miterere!",
    price: "Igiciro",
    category: "Icyiciro",
    stockTracking: "Gukurikirana imigabane",
    stockCount: "Kubara",
    taxed: "Umusoro",
    originalPrice: "Igiciro cyumwimerere",
    costPrice: "Igiciro",
    unit: "Igice",
    productImage: "Ishusho y'ibicuruzwa",
    taxRate: "Igipimo cy'Imisoro",
    taxType: "Ubwoko bw'Imisoro",
    trackInventory: "Kurikirana Ibarura",
    variants: "Ibihinduka",
    hasVariants: "Ifite Ibihinduka",
    importProduct: "Kuzana ibicuruzwa",
    exportProducts: "Kohereza ibicuruzwa hanze",
    addNewProduct: "Ongeraho Ibicuruzwa bishya",
    viewCategory: "Reba Icyiciro",
    viewSuppliers: "Reba Abaguzi",
    receiveInventory: "Akira Ibarura",
    printAllProductsTag: "Shira ibicuruzwa byose Tag",
    deleteAll: "Siba Byose",
    totalProducts: "IBICURUZWA BYINSHI",
    youveSuccessfullyAddedANewCategory: "Wongeyeho neza icyiciro gishya",
    addNewCategory: "Ongeraho Icyiciro gishya",
    addCategory: "Ongeraho Icyiciro",
    categoryName: "Izina ry'icyiciro",
    pleaseEnterCategoryName: "Nyamuneka andika izina ryicyiciro",
    stampsTarget: "Intego ya kashe",
    sendInventory: "Kohereza Ibarura",
    productDetails: "Ibisobanuro birambuye",
    youveSuccessfullyEditedThisCategory: "Watsinze neza iki cyiciro",
    update: "Kuvugurura",
    categoryList: "Urutonde",
    categories: "Ibyiciro",
    enterQuantityToUpdate: "Injiza ingano yo kuvugurura",
    inventorySentSuccessfully: "Ibarura ryoherejwe neza!",
    updateInventory: "Kuvugurura Ibarura",
    currentQuantity: "Umubare w'iki gihe",
    pleaseEnterQuantityToAdd: "Nyamuneka andika umubare ushaka kongeramo",
    pleaseSelectABranch: "Nyamuneka hitamo Ishami",
    searchForBranch: "Shakisha ishami",
    youCantSendMoreThanStock:
      "Ntushobora kohereza ibirenze ibyo ufite muri stock",
    send: "Ohereza",
    pleaseEnterQuantityToSend: "Nyamuneka andika umubare ushaka kohereza",
    productNameIsRequiredOnRow: "izina ryibicuruzwa bisabwa kumurongo",
    productCategoryIsRequiredOnRow: "icyiciro cyibicuruzwa bisabwa kumurongo",
    productPriceIsRequiredOnRow: "igiciro cyibicuruzwa bisabwa kumurongo",
    productUnitIsRequiredOnRow: "Ibicuruzwa bisabwa kumurongo",
    productQuantityIsRequiredOnRow: "ingano y'ibicuruzwa irakenewe kumurongo",
    productVariantsRequireTracking: "ibicuruzwa bihinduka bisaba gukurikirana!",
    pleaseAddVariantClickButton:
      "Nyamuneka ongeraho variant ukanze buto yo kongeramo!",
    totalVariantsMoreThanSelectedQuantity:
      "Ibihinduka byose birenze ibicuruzwa byatoranijwe, Pls igabanya ingano",
    productEditedSuccessfully: "Ibicuruzwa byahinduwe neza!",
    fileTooLargeLessThan4Mb:
      "Ingano ya dosiye nini cyane! Ingano ya dosiye igomba kuba munsi ya 4MB.",
    suchVariantAlreadyExist: "Ihinduka nkiryo rimaze kubaho",
    addVariants: "Ongeraho Ibihinduka",
    editProduct: "Hindura ibicuruzwa",
    pleaseEnterProductName: "Nyamuneka Andika Izina ryibicuruzwa",
    pleaseEnterProductPrice: "Nyamuneka Injiza Igiciro",
    pleaseEnterProductOriginalPrice: "Nyamuneka Injiza Igicuruzwa cyumwimerere",
    productDescription: "Ibisobanuro ku bicuruzwa",
    selectProductCategory: "Hitamo Icyiciro cyibicuruzwa",
    pleaseSelectProductCategory: "Nyamuneka hitamo icyiciro cyibicuruzwa",
    productCostPrice: "Igiciro cyibicuruzwa",
    productSellingPrice: "Igiciro cyo kugurisha ibicuruzwa",
    productOriginalPrice: "Igicuruzwa Igiciro cyumwimerere",
    maxFileSizeAllowedIs4Mb: "Icyiza. Ingano ya dosiye yemerewe ni 4mb",
    productsWithPicturesArePublished:
      "Ibicuruzwa bifite amashusho byasohotse kuri Discover kugirango wakire ibicuruzwa",
    shouldThisProductBeTracked: "Iki gicuruzwa gikwiye gukurikiranwa?",
    pleaseSelectStockUnit: "Nyamuneka hitamo igice cyimigabane",
    stockUnit: "Igice cyimigabane",
    bag: "BAG",
    bottle: "URUBUGA",
    bunch: "BUNCH",
    can: "URASHOBORA",
    carton: "CARTON",
    crate: "CRATE",
    cup: "Igikombe",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "UMWANDITSI",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "URUPAPURO",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Nyamuneka andika ibicuruzwa",
    productQuantity: "Umubare wibicuruzwa",
    isThisProductTaxed: "Iki gicuruzwa gisoreshwa?",
    selectTaxType: "Hitamo Ubwoko bw'Imisoro",
    pleaseSelectTaxType: "Nyamuneka hitamo ubwoko bw'imisoro",
    progressive: "Iterambere",
    proportional: "Ingano",
    pleaseEnterProductTaxRate:
      "Nyamuneka andika igipimo cy'umusoro ku bicuruzwa",
    doesProductHaveVariants: "Iki gicuruzwa gifite impinduka?",
    type: "Andika",
    value: "Agaciro",
    pleaseEnterVariantType: "Nyamuneka andika ubwoko bwa variant",
    pleaseEnterVariantValue: "Nyamuneka andika agaciro ka variant",
    pleaseEnterVariantPrice: "Nyamuneka andika igiciro cya variant",
    pleaseEnterVariantQuantity: "Nyamuneka andika ingano",
    productDeletedSuccessfully: "Ibicuruzwa byasibwe neza!",
    categoryDeletedSuccessfully: "Icyiciro cyasibwe neza!",
    toDeleteThisProduct: "gusiba iki gicuruzwa",
    invalidProductQuantity: "Umubare wibicuruzwa bitemewe",
    quantityAddedIsOverStock: "Ingano wongeyeho irenze ibyo ufite mububiko.",
    itemInventoryNotTracked: "Ibarura ryibintu ntabwo rikurikiranwa",
    addBulkQuantity: "Ongeraho Ubwinshi",
    enterBulkQuantity: "Injira Ubwinshi",
    pleaseEnterBulkQuantity: "Nyamuneka andika umubare munini",
    youveSuccessfullyAddedANewProduct: "Wongeyeho neza ibicuruzwa bishya!",
    pleaseEnterProductSellingPrice: "Nyamuneka andika ibicuruzwa byo kugurisha",
    doYouWantToTrackProductStock: "Urashaka gukurikirana ibicuruzwa?",
    sellingPrice: "Igiciro cyo kugurisha",
    setProductExpiryReminder: "Shiraho Ibicuruzwa Byarangiye",
    productExpiryDate: "Itariki izarangiriraho",
    startRemindingFrom: "Tangira Kwibutsa Kuva",
    productSuppliesAddedSuccessfully: "Wongeyeho ibicuruzwa neza.",
    addProductSupplies: "Ongeraho Ibicuruzwa",
    pleaseSelectSupplier: "Nyamuneka Hitamo Utanga isoko",
    nameOfProduct: "Izina ryibicuruzwa",
    pleaseSelectProduct: "Nyamuneka Hitamo Ibicuruzwa",
    productVariant: "Ibicuruzwa bitandukanye",
    pleaseSelectAVariant: "Nyamuneka Hitamo Ibitandukanye",
    pleaseEnterUnitPrice: "Nyamuneka Injiza Igiciro",
    businessBranch: "Ishami ry'ubucuruzi",
    pleaseSelectBranch: "Nyamuneka hitamo ishami",
    youveSuccessfullyAddedANewSupplier: "Wongeyeho neza uwaguhaye isoko",
    addSupplier: "Ongeraho Utanga isoko",
    pleaseEnterSupplierEmail: "Nyamuneka andika imeri yabatanga",
    pleaseAddADescription: "Nyamuneka ongeraho ibisobanuro",
    anErrorOccuredProductsDeleted:
      "Ikosa ryabaye mugihe cyo kubaga. Nyamuneka witondere, ibicuruzwa bike bishobora kuba byarasibwe mubikorwa",
    bulkDelete: "Gusiba byinshi",
    youAreAboutToDelete: "Ugiye gusiba",
    product: "Ibicuruzwa",
    isDueToRedeem: "ni Kuri Gucungura",
    aReward: "igihembo",
    pleaseSelectCustomerToReeemReward:
      "Nyamuneka hitamo umukiriya kugirango ucungure ibihembo.",
    youHaveNoLoyaltyProgramRunning: "Ntabwo ufite gahunda yubudahemuka ikora",
    customerHhasNoPointsInLoyaltyProgram:
      "Umukiriya nta ngingo afite muriyi gahunda yubudahemuka",
    proceedWithPayment: "Komeza wishyure?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ugiye kwishyura ibicuruzwa ukoresheje amanota.",
    customerHas: "Umukiriya afite",
    worth: "agaciro",
    andIsNotSufficientToPayForTransaction:
      "kandi ntibihagije kurihira iki gikorwa. Bashaka kongeramo amafaranga asigaye bakoresheje ubundi buryo bwo kwishyura?",
    addCustomerLoyalty: "Ongeraho Ubudahemuka bw'abakiriya",
    pleaseAddCustomerFirst: "Nyamuneka ongera cyangwa uhitemo umukiriya mbere.",
    pleaseWaitWhileWeGetReady: "Nyamuneka tegereza, mugihe twiteguye",
    lowStock: "Ububiko buke",
    pleaseEnterAmountTendered: "Nyamuneka andika amafaranga yatanzwe",
    areYouSureToBookSaleOffline:
      "Uzi neza ko ushaka gutondekanya kugurisha kumurongo?",
    saleWouldBeBookedAutomatically:
      "Igurisha ryandikwa mu buryo bwikora mugihe ufunguye interineti yawe",
    offlineSalesBookingCancelled: "Gutumiza kugurisha kumurongo byahagaritswe",
    covid19Message:
      "COVID19: Karaba intoki ukoresheje isabune cyangwa Isuku kugirango uhagarike ikwirakwizwa. Gumana Umutekano Buri gihe",
    saleSuccessfullyRecorded: "Igurisha ryanditswe neza!",
    sendReceiptToEmail: "Ohereza inyemezabwishyu kuri imeri",
    sendThankYouSms: "Ohereza ubutumwa bugufi",
    sendShippingDetails: "Kohereza ibisobanuro birambuye",
    addLoyalty: "Ongeraho Ubudahemuka",
    searchCustomerNameOrNumber: "Shakisha izina ryabakiriya cyangwa numero",
    clickTheSearchCustomerBox:
      "Kanda Shakisha Umukiriya Agasanduku na Scan Ikarita",
    enterProductPrice: "Injira Igiciro",
    enterPriceFor: "Injira Igiciro Kuri",
    searchForProduct: "Shakisha ibicuruzwa",
    all: "Byose",
    backToDashboard: "Subira kuri Dashboard",
    viewDraftSales: "Reba Inyandiko yo kugurisha",
    variantSelected: "Guhitamo",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Ihitamo ryambere ryatoranijwe ntiriboneka kubintu bishya byatoranijwe ukurikije igiciro, nyamuneka hindura ibyo wahisemo.",
    pleaseSupplyPositiveQuantityNumber: "Nyamuneka tanga umubare wanyuma",
    lowStockFor: "Ububiko buke kuri",
    clearVariants: "Ibisobanuro bisobanutse",
    pleaseEnterQuantity: "Nyamuneka andika umubare",
    picture: "Ishusho",
    redemptionToken: "Gucungurwa",
    token: "Token",
    totalSpent: "Amafaranga yose yakoreshejwe",
    confirmPayment: "Emeza ubwishyu",
    hasPaymentBeenMade: "Ubwishyu bwatunganijwe neza?",
    enterTransactionReference: "Injira ibikorwa byubucuruzi wishyuye hamwe",
    pleaseSelectACustomer: "Nyamuneka hitamo umukiriya!",
    areYouSureToApplyDiscount: "Uzi neza ko ushaka gusaba kugabanyirizwa?",
    addYourBankAccountToEnableUssd:
      "Ongeraho konti yawe kugirango ushoboze kohereza USSD ako kanya na uPay",
    totalAmountToPay: "Amafaranga yose yo kwishyura",
    doYouWantToCancelTransaction: "Urashaka guhagarika iki gikorwa?",
    savePrintBill: "Bika / Icapa fagitire",
    enterAmountCollectedForCustomer:
      "Injiza amafaranga yakusanyijwe kubakiriya",
    recordSale: "Kugurisha inyandiko",
    checkOutWith: "Reba hamwe",
    instantTransfer: "Kwimura ako kanya",
    dialTheUSSDCode: "Hamagara kode ya USSD",
    pleaseSelectABank: "Nyamuneka hitamo banki",
    payWithUSSD: "Kwishura hamwe na USSD",
    sendBillTo: " - Kohereza Bill kuri",
    waitingForUSSDTransfer: "Gutegereza kohereza USSD",
    percent: "Ijanisha",
    applyDiscount: "Koresha Kugabanuka",
    thisBillHasBeenSaved: "Uyu mushinga w'itegeko wakijijwe",
    saveDraft: "Bika Inyandiko",
    pleaseTypeANameToIdentifyCustomer:
      "Nyamuneka andika izina kugirango umenye abakiriya",
    paymentDetails: "Ibisobanuro byo Kwishura",
    basePrice: "Igiciro fatizo",
    staff: "Abakozi",
    subTotal: "SubTotal",
    durationMonths: "Igihe (amezi)",
    selectADuration: "Hitamo igihe",
    oneMonth: "Ukwezi",
    twoMonths: "Amezi 2",
    threeMonths: "Amezi 3",
    sixMonths: "Amezi 6",
    twelveMonths: "Amezi 12",
    eighteenMonths: "Amezi 18",
    twentyFourMonths: "Amezi 24",
    twoMonthsFree: "(Amezi 2 Yubusa)",
    threeMonthsFree: "(Amezi 3 Yubusa)",
    fiveMonthsFree: "(Amezi 5 Yubusa)",
    yourAccountHasBeen: "Konti yawe yabaye",
    renewed: "gishya",
    upgraded: "kuzamura",
    successfully: "gutsinda",
    yourSubscription: "Kwiyandikisha kwawe",
    youAreCurrentlyEnrolledOnA: "Kuri ubu wiyandikishije kuri a",
    pleaseChooseAPaymentOption: "Nyamuneka Hitamo uburyo bwo Kwishura",
    planRenewal: "Gahunda yo Kuvugurura",
    planUpgrade: "Gahunda yo Kuzamura",
    pleaseSelectDurationToPayFor: "Nyamuneka Hitamo Igihe uteganya kwishyura",
    staffAccounts: "Konti y'abakozi",
    ecommerce: "Ubucuruzi",
    pleaseSelectAPlan: "Nyamuneka hitamo gahunda",
    includeAddons: "Shyiramo On-on",
    viewTransactions: "Reba Ibikorwa",
    customerHasNoCompletedTansactions: "Umukiriya ntabwo arangije gukuramo",
    branch: "Ishami",
    enterNumberOfEcommerceBranches: "Injiza Umubare wamashami yubucuruzi",
    enterNumberOfEcommerceBranchesToPay:
      "Injiza Umubare w'amashami ya Ecommerce Uteganya Kwishura",
    ecommerceIntegration: "Kwishyira hamwe",
    enterNumberOfBranches: "Injiza Umubare w'Amashami",
    enterNumberOfAdditionalBranchesToPay:
      "Injiza Umubare wamashami yinyongera Uteganya kwishyura",
    enterNumberOfStaffs: "Injiza Umubare w'abakozi",
    enterNumberOfStaffsToPayFor: "Injiza Umubare w'abakozi Uteganya Kwishura",
    discountApplies: "Kugabanuka",
    starsOnThe: "inyenyeri kuri",
    offer: "gutanga",
    get: "Kubona",
    moreStarsToRedeem: "inyenyeri nyinshi zo gucungura",
    taxVat: "Umusoro (TVA)",
    callCashierToCompletePayment: "Hamagara Cashier kugirango Wishyure Byuzuye",
    receipt: "Inyemezabwishyu",
    dear: "Nshuti",
    thankYouForYourOrderFindGoods:
      "Urakoze kubyo watumije. Nyamuneka shakisha ibicuruzwa bikurikira byatanzwe, nkuko byumvikanyweho.",
    shippingNote: "Icyitonderwa",
    enterShippingNote: "Injira Icyitonderwa",
    shippingAddress: "Aderesi yoherejwe",
    enterShippingAddress: "Injira aderesi",
    wellDoneYouAreDueToRedeem: "Muraho neza! Ugomba gucungurwa",
    toGetYourRewardNextVisit:
      "kubona ibihembo byawe muruzinduko ruzakurikiraho. Murakoze",
    pointsOnThe: "Ingingo ku",
    morePointsToRedeem: "ingingo nyinshi zo gucungura",
    showCode: "Erekana kode",
    toGetYourRewardOnNextVisit:
      "kubona ibihembo byawe muruzinduko ruzakurikiraho. Murakoze",
    earn: "Shaka",
    delivaryNote: "Icyitonderwa",
    draftSales: "Umushinga wo kugurisha",
    startDate: "Itariki yo gutangiriraho",
    endDate: "Itariki yo kurangiriraho",
    orders: "Amabwiriza",
    checkout: "cheque",
    noProductItem: "Nta kintu cyibicuruzwa",
    selectProductImage: "Hitamo Ishusho Ibicuruzwa",
    selectCountry: "Hitamo igihugu",
    selectRegion: "Hitamo Leta / Intara",
    printProductTag: "Shira ibicuruzwa",
    transactionReference: "Ibicuruzwa",
    Cashier: "Cashier",
    Manager: "Umuyobozi",
    Owner: "Nyirubwite",
    Admin: "Umuyobozi",
    addPartners: "Ongeraho Abafatanyabikorwa",
    addNewLoyaltyPartner: "Ongeraho Umufatanyabikorwa mushya",
    pleaseEnterCompanyName: "Nyamuneka andika Izina ryisosiyete",
    companyName: "Izina ryisosiyete",
    pleaseEnterCompanyRepName: "Nyamuneka andika izina uhagarariye isosiyete",
    companyRepName: "Izina ry'uhagarariye Isosiyete",
    pleaseEnterCompanyRepEmail: "Nyamuneka andika imeri y'uhagarariye sosiyete",
    companyRepEmail: "Imeri ya sosiyete rep",
    pleaseEnterCompanyRepPhone:
      "Nyamuneka andika numero ya terefone uhagarariye sosiyete",
    companyRepPhone: "Terefone Umubare wa sosiyete rep",
    addReward: "Ongeraho ibihembo",
    pleaseEnterRewardName: "Nyamuneka andika izina ryigihembo",
    rewardName: "Izina ry'igihembo",
    rewardQuantity: "Umubare w'ingororano",
    rewardDescription: "Ibisobanuro",
    rewardType: "Ubwoko bw'ibihembo",
    pleaseEnterRewardType: "Nyamuneka andika ubwoko bwibihembo",
    pleaseEnterRewardQuantity: "Nyamuneka andika ibihembo",
    pleaseEnterRewardDescription: "Nyamuneka andika ibisobanuro",
    fineDining: "Kurya neza",
    luxuryFashion: "Imyambarire yimyambarire",
    hotels: "Amahoteri",
    travel: "Urugendo",
    foodAndBeverage: "Ibiribwa n'ibinyobwa",
    fashion: "Imyambarire",
    health: "Ubuzima",
    furniture: "Ibikoresho",
    entertainment: "Imyidagaduro",
    automobile: "Imodoka",
    education: "Uburezi",
    beautyAndSpa: "Ubwiza na Spa",
    staycation: "Guma",
    events: "Ibyabaye",
    trips: "Ingendo",
    oilAndGas: "Amavuta na gaze",
    laundry: "Imesero",
    partnerCategory: "Icyiciro cy'abafatanyabikorwa",
    freeItem: "Ikintu cy'ubuntu",
  },
  "Kurdish (Kurmanji)": {
    cashier: "diravgir",
    manager: "rêvebir",
    owner: "xwedî",
    online: "liserxetê",
    offline: "offline",
    changePassword: "Passwordîfre biguherînin",
    currentPasswordMessage: "Ji kerema xwe şîfreya xweya heyî binivîsin",
    passwordMessage: "Ji kerema xwe şîfreya xwe binivîse",
    currentPassword: "Şîfreya heyî",
    password: "Şîfre",
    confirmPasswordMessage: "Ji kerema xwe şîfreya xwe piştrast bikin!",
    confirmPassword: "di pêşîyê da em sipas dikin",
    sendViaEmail: "Bi E -nameyê bişînin",
    sendViaWhatsapp: "Bi WhatsApp re bişînin",
    downloadPdf: "PDF dakêşin",
    paid: "dayîn",
    unpaid: "bêmeaş",
    partial: "parpar",
    closeInvoice: "Ma tu dixwazî Fatûreyê bigire?",
    closeInvoiceConfirmation:
      "Dibe ku fatûre neyê tomarkirin. Ma tu dixwazî bigire?",
    yes: "Erê",
    no: "na",
    invoice: "Biha",
    wasDeducted: "hate jêbirin",
    for: "bo",
    item: "Şanî",
    addProduct: "Hilber zêde bikin",
    paymentReference: "Reference Payment",
    amountPaid: "Mîqdara Peredayînê",
    discountAmount: "Tenzîlat Tenzîlat",
    amountDue: "Heqê Deynê",
    amount: "Biha",
    dueDate: "Serdem",
    paymentType: "Payment Type",
    card: "Qert",
    cash: "Perê pêşîn",
    bankTransfer: "Veguheztina Bankê",
    paymentMessage: "Peyam Payment",
    description: "Terîf",
    sendReceipt: "Wergirtinê Bişîne",
    delete: "Jêbirin",
    save: "Rizgarkirin",
    resend: "Ji nû ve andin",
    saveAndSend: "Rizgarkirin",
    invoiceSaved: "Fatore xilas bû!",
    selectPaymentMethod: "Ji kerema xwe Rêbazek dravdanê hilbijêrin!",
    selectCustomer: "Ji kerema xwe Mişteriyek hilbijêrin!",
    cartEmptyError:
      "Lîsteya Kartê nikare vala be, fatûreyê bigire û Babetê li selikê zêde bike!",
    subscriptionExpired:
      "Abonetiya we qediya ye û hesabê we naha tixûbdar e. Bişkojka jêrîn bikirtînin ku hûn hesabê xwe nûve bikin",
    renew: "Nukirin",
    holdOn: "Bisekine",
    customerBank: "Banka Mişterî",
    cancel: "Bişûndekirin",
    selectACustomer: "Mişterî Hilbijêre",
    invoiceSuccessfulPdfError:
      "Fatûra bi serfirazî hate afirandin lê Nifşa PDF -ê ji ya berê dirêjtir digire. Ji kerema xwe di demek kurt de paşde vegerin.",
    downloadingInvoice: "Daxistina Fatûra",
    downloadingReceipt: "Daxistina Qebûlkirinê",
    whatsappReceiptError:
      "Çewtiyek di şandina meqbûzê de bi WhatsApp -ê çêbû, Ji kerema xwe dîsa biceribînin.",
    receiptToWhatsapp: "Meqbûz ji WhatsApp re hate şandin",
    thankYouForPatronage: "Spas ji bo piştevaniya we",
    hereIsYourReceipt: "Li vir meqbûza dravdana we heye",
    errorSendingEmailReceipt:
      "Çewtiyek di şandina meqbûzê de bi e-nameyê çêbû, ji kerema xwe dîsa biceribînin an bi piştgiriyê re têkilî daynin",
    receiptSentToEmail: "Meqbûz ji e -nameya xerîdar re hatî şandin",
    invoiceSentToEmail: "Fatûra ji e -nameya xerîdar re hatî şandin",
    invoiceSuccessWhatsappError:
      "Fatûra bi serkeftî hate afirandin lê di şandina WhatsApp -ê de xeletiyek çêbû. Li ser navnîşa fatûreyê dîsa biceribînin",
    invoiceSuccessfulEmailError:
      "Fatûre bi serkeftî hate afirandin lê dema şandina wekî e-name xeletiyek çêbû. Ji kerema xwe dîsa ji navnîşa fatûreyê biceribînin",
    invoiceSentToWhatsapp: "Fatore ji WhatsApp re hate şandin",
    hello: "slav",
    pleaseDownloadInvoice: "Ji kerema xwe fatûreyê dakêşin",
    pleaseDownloadReceipt: "Ji kerema xwe meqbûzê daxînin",
    from: "ji",
    email: "Email",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Hûn li ser Planek belaş in.",
    clickOn: "Kılik LI SER",
    yourPlanInFewSteps: " abonetiya xwe di çend gavên bilez de.",
    to: "ber",
    yourSubscriptionHasExpired:
      "Abonetiya we qediya ye û hesabê we naha tixûbdar e.",
    days: "rojan",
    yourSubscriptionExpiresIn: "Abonetiya weya Loystar qediya",
    createAcount: "Hesabek çêbikin",
    signIn: "Têketin",
    continue: "Berdewamkirin",
    enterOtp: "PIN -a OTP -ê binivîse",
    enterValidOtp: "Ji kerema xwe PIN -a derbasdar binivîsin",
    pin: "DERZÎ",
    tokenSentToMail: "Nîşanek ji e -nameya we re hatî şandin",
    passwordResetSuccessful: "Veguheztina şîfreyê serketî bû",
    somethingWentWrong: "Tiştek xelet çû!",
    resetPassword: "Etîfreya Verast bike",
    iHaveResetCode: "Min kodek vesazkirina şîfreyê heye",
    pleaseEnterEmail: "Ji kerema xwe e -nameya xwe binivîsin",
    aTokenWillBeSentToEmail: "Nîşanek dê ji e -nameya we re were şandin",
    enterEmail: "E -nameya xwe binivîse",
    sendinYourToken: "Nîşana xwe dişîne ...",
    makeSureItMatchesPassword:
      "Bawer bikin ku ew bi şîfreya weya nû re têkildar e",
    pleaseChooseNewPassword: "Ji kerema xwe şîfreyek nû hilbijêrin",
    chooseNewPassword: "Passwordîfreyek nû hilbijêrin",
    enterNewPassword: "Ji bo piştrastkirinê şîfreya xweya nû têkevin",
    enterTokenSent: "Nîşana ku nameya we hatî şandin binivîse",
    resetToken: "Reset Token",
    resettingPassword: "Passwordîfreya xwe nûve dike ...",
    signUp: "Tomar kirin",
    adminSignInWithEmail:
      " Rêvebir bi e -nameyê têkeve dema ku Karmend bi navê bikarhêner têkevinê.",
    pleaseEnterEmailOrUsername:
      "Ji kerema xwe e -name an navê bikarhêner binivîse",
    emailOrUsername: "E -name an Navê bikarhêner",
    pleaseEnterPassword: "Ji kerema xwe şîfreyê binivîse",
    createAnAccount: "Hesabek çêbikin",
    forgotPassword: "Passwordîfre ji bîr kir?",
    enterPhoneNumber: "Hejmara Telefonê binivîse",
    personalData: "Daneyên Kesane",
    validateConfirmationCOde: "Kodê Piştrastkirinê Derbas Bike",
    pleaseEnterFirstName: "Ji kerema xwe paşnavê xwe binivîsin",
    pleaseEnterPhoneNumber: "Ji kerema xwe hejmara telefona xwe binivîsin",
    pleaseEnterLastName: "Ji kerema xwe paşnavê xwe binivîsin",
    pleaseEnterBusinessName: "Ji kerema xwe navê karsaziya xwe binivîsin",
    firstName: "Nav",
    lastName: "Paşnav",
    businessName: "Navê Karsaziyê",
    previous: "Pêşî",
    next: "Piştî",
    pleaseSelectBusinessCategory:
      "Ji kerema xwe kategoriya karsaziya xwe hilbijêrin",
    pleaseEnterValidEmail: "Ji kerema xwe e -nameyek derbasdar binivîsin",
    pleaseEnterPostCode: "Ji kerema xwe koda postê binivîsin",
    postCode: "Post Code",
    phoneNumberInUse: "Ev jimareya têlefonê jixwe tê bikar anîn!",
    emailInUse: "Ev e -name jixwe tê bikar anîn!",
    foodAndDrinks: "Xwarin û Vexwarin",
    salonAndBeauty: "Salon û Beauty",
    fashionAndAccessories: "Fashion û Accessories",
    gymAndFitness: "Gym û Fitness",
    travelAndHotel: "Rêwîtî û Hotel",
    homeAndGifts: "Mal û Diyarî",
    washingAndCleaning: "Washûştin û Paqijkirin",
    gadgetsAndElectronics: "Gadgets û Elektronîk",
    groceries: "Xûrek",
    others: "Others",
    submit: "Nermijîn",
    accountCreatedSuccessful: "Hesabê we bi serkeftî hate afirandin.",
    pleaseEnterAddress: "Ji kerema xwe Navnîşana Xwe Binivîse",
    addressLine1: "Rêzeya Navnîşan 1",
    addressLine2: "Navnîşa Navnîşan 2",
    choosePassword: ".Îfre hilbijêrin",
    passwordMustBe6Characters: "Passwordîfre divê herî kêm 6 tîpan be.",
    howDidYouHearLoystar: "We çawa Loystar bihîst?",
    referralCode: "hewaleyê Code",
    byClickingTheButton: " Bi tikandina bişkoja jêrîn, hûn bi ya",
    termsAndSevice: "Termên",
    wereCreatingAccount: "Em hesabê we diafirînin",
    proceed: "Pêşçûn",
    verificationCodeMustBe6: "Koda Verastkirinê divê 6 reqem be",
    pleaseEnter6DigitCode: "Ji kerema xwe koda 6 reqemî têkevin",
    enterVerificationCode: "Koda Verastkirinê binivîse",
    resendToken: "Resend Token",
    verify: "Lidûsekirin",
    transactions: "Transactions",
    todaySales: "Firotanên îro",
    salesHistory: "Dîroka Firotanê",
    supplyHistory: "Supply History",
    new: "Nşh",
    invoices: "Fatoreyên",
    disbursements: "Veqetandin",
    offlineSales: "Firotanên negirêdayî",
    products: "Berhemên",
    customers: "Muşteriyan",
    multiLevelLoyalty: "Dilsoziya Pir-Astî",
    loyaltyPrograms: "Bernameyên Dilsoziyê",
    members: "Endam",
    appStore: "App Store",
    orderMenu: "Order Menu",
    settings: "Settings",
    staffAndBranches: "Staffs û Branaxên",
    myAccount: "My Account",
    switchToSellMode: "Veguherin Moda Firotanê",
    signOut: "Guhê xwe nedene",
    getFreeSubscription: "Abonetiya belaş bistînin",
    onlyNumbersAllowed: "Tenê hejmar têne destûr kirin",
    yourAccountMustBe10Digits: "divê hejmara hesabê we jimareyek 10 reqemî be",
    yourBvnMustBe11: "BVN -ya we divê jimareyek 11 reqemî be",
    pleaseSelectBank: "Ji kerema xwe banka xwe hilbijêrin",
    selectYourBank: "Banka xwe hilbijêrin",
    enterBankAccountNumber: "Hejmara Hesabê Bankê binivîse",
    enterBvnNumber: "BVN -ya xwe binivîse",
    connectBankAccount: "Hesabê Bankê ve girêdin",
    passwordResetSuccessfulAndSignOut:
      "Passwordîfreya we bi serketî hate sererast kirin. Li ser bişkojka Sign Out a jêrîn bikirtînin ku dîsa têkevinê",
    enterCurrentPassword: "Passwordîfreya heyî binivîse",
    pleaseEnterCurrentPassword: "Ji kerema xwe şîfreya heyî binivîse",
    phoneNumber: "Jimare telefon",
    sex: "Cinsîyet",
    dateOfBirth: "Rojbûn",
    state: "Rewş",
    country: "Welat",
    loyaltyId: "Nasnameya dilsoziyê",
    createdAt: "Li At hatiye afirandin",
    noOfTransactionsMade: "Hejmara danûstandinên hatine kirin",
    yourDownloadWillStart: "Daxistina we dê di demekê de dest pê bike",
    exportCustomers: "Muşteriyan Export",
    youhaveSuccessfullyToppedUp:
      "We Yekîneyên SMS -a xwe bi serfirazî top kir.",
    youNowHave: "Tu niha heye",
    smsUnits: "Yekîneyên SMS",
    enterNumberOfSmsUnits:
      "Hejmara Yekîneyên SMS -a ku hûn dixwazin bikirin bikirtînin",
    pleaseEnterANumericValue: "Ji kerema xwe nirxek hejmarî binivîse",
    pay: "Diravdanî",
    accountEditedSuccessfully: "Hesabê we bi serkeftî hate guheztin.",
    youAeCurrentlyOn: "Hûn niha li ser in",
    plan: "Pîlan",
    userData: "Daneyên bikarhêner",
    businessData: "BATXWNE BATXWNE",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Ji kerema xwe dravê xwe hilbijêrin",
    selectYourCurrency: "Pereyê xwe hilbijêrin",
    purchaseMoreSmsUnits:
      "Bi karanîna forma jêrîn bêtir Yekîneyên SMS -ê bikirin",
    youHave: "We heye",
    atLeast4SmsUnitsRrequired:
      "Ji bo verastkirinê herî kêm 4 yekîneyên sms hewce ne, ji kerema xwe berhev bikin!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ji kerema xwe hesabê banka xwe verast bikin da ku li gorî daxwazên 'Xerîdarê Xwe Bizanin' (KYC) tevbigerin. Ev ê dihêle hûn dravdanan bi riya USSD an veguheztina tavilê berhev bikin, fermanan ji xerîdaran bistînin û çareseriya danûstendinê pêvajoyê bikin. Tenê ji bo bazirganên Nîjeryayî tê sepandin. Ji kerema xwe pêl bişkoka jêrîn bikin ku dest pê bikin.",
    reConnectBankAccount: "Hesabê Bankê ji nû ve girêdin",
    accountName: "Navê Hesabê",
    accountNumber: "Hejmara Hesabê",
    bankName: "Navê bankê",
    verified: "Verast kirin",
    accountDetails: "Details Account",
    kycBankAccount: "KYC",
    createTier: "Rêzeyê çêbikin",
    fileUploadSuccessful: "pel bi serkeftî hat barkirin",
    fileUploadFailed: "barkirina pelê têk çû",
    createLoyaltyProgram: "Bernameyek Dilsoziyê Bikin",
    createLoyalty: "Dilsoziyê biafirînin",
    name: "Nav",
    loyaltyArtwork: "Karê Hunerî yê Dilsoziyê",
    clickToUpload: "Ji bo barkirinê bikirtînin",
    amountToPointsRatio: "Hejmara Rêjeya Pûanan",
    points: "Points",
    recommendedAmountToPointRatio:
      "Pêşniyar kirin: 1 heta 1 xal. yanî ji bo her 1 Naira ku tê xerckirin, xerîdarên we 1 pûan distînin",
    pleaseTypeIn: "Ji kerema xwe binivîse",
    toDeleteLoyalty: "vê dilsoziyê jêbirin",
    deleteLoyalty: "Dilsoziya Jêbirin",
    toConfirm: "piştrast kirin",
    edit: "Weşandin",
    pointsAwardedSuccessfully: "Pûanên Serketî Xelat kirin.",
    enterPointValueToAward:
      "Nirxa Pûanê Binivîse Hûn Dixwazin Xerîdar Xelat Bikin",
    award: "Xelatkirin",
    awardPointValuesToCustomer: "Xelata Xelata Xerîdarê",
    enrollMembersToLoyalty: "Endaman tomar bikin ser Dilsoziyê",
    awardPoints: "Xelatên Points",
    enroll: "Nivîsîn",
    home: "Xane",
    loyalty: "Pêvgirêdayî",
    enrollCustomers: "Xerîdar tomar bikin",
    selected: "Hilbijartî",
    customer: "Miştirî",
    loyaltyActivationSuccessful: "Çalakiya Dilsoziyê serketî ye.",
    loyaltyDeactivationSuccessful: "Deaktîvkirina Dilsoziyê serketî ye.",
    viewTier: "Asta Binêre",
    deactivate: "De-Çalak bike",
    activate: "Çalak bike",
    actions: "Actions",
    nameOfLoyalty: "Navê Dilsoziyê",
    loyaltyStatus: "Rewşa Dilsoziya Rewşa Dilsoziyê",
    numberOfTiers: "Hejmara Qonaxan",
    createdOn: "Created On",
    createATier: "Qonaxek çêbikin",
    stopCreatingTierConfirmation:
      "Ma hûn dixwazin afirandina astek rawestînin?",
    stopEditingTierConfirmation:
      "Ma hûn dixwazin sererastkirina vê astê rawestînin?",
    nameOfTier: "Navê Tier",
    minimumSpendingTarget: "Kêmtirîn Armanca Xerckirinê",
    maximumSpendingTarget: "Mezintirîn Saving Target",
    minimumSpendingTargetRequired: "hedefa lêçûnên kêmtirîn tê xwestin",
    maximumSpendingTargetRequired: "hedefa lêçûnê ya herî zêde tê xwestin",
    rewardSponsor: "Sponsor Xelat",
    pleaseChooseARewardSponsor: "Ji kerema xwe sponsorek xelatê hilbijêrin",
    self: "Wekhev",
    partner: "Dost",
    rewardPartner: "Xelat Partner",
    pleaseSelectRewardPartner: "Ji kerema xwe hevkarê xelata xwe hilbijêrin",
    rewards: "Xelat",
    pleaseSelectAReward: "Ji kerema xwe xelatek hilbijêrin",
    instructionsOnRedeemingReward:
      "Rêbernameyên Li ser Çawa Divê Xerîdar Xelatê Xilas bike",
    pleaseFillInThisField: "Ji kerema xwe vê Zeviyê dagirin!",
    toDeleteThisTier: " ji bo rakirina vê astê",
    deleteTier: "Asta Jêbirin",
    viewMembers: "Endaman bibînin",
    membersEnrolled: "Endam qeyd kirin",
    instruction: "Ders",
    membersIn: "Endamên di",
    availableTiersInLoyalty: "Di Bernameya Dilsoziyê de Pile (yên) Berdest in",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Bernameya Dilsoziya Available",
    totalLoyalties: "TOTAL BAWERN",
    memberDetails: "Details endam",
    nameOfCustomer: "Navê Mişterî",
    address: "Navnîşan",
    allEnrolledMembers: "Hemî Endamên Qeydkirî",
    thisIsToWishYouHappyBirthday:
      "Ev tê vê wateyê ku hûn ji we re rojbûnek pir xweş û jiyanek serfiraz dixwazin. Spas ji bo her tiştê ku hûn ji Loyster re ne. Pîrozbahiyên Pîroz!",
    inputAnOfferPlease: "Ji kerema xwe Pêşniyarek binivîsin",
    pleaseSelectTheInsertPoint:
      "Ji kerema xwe xala têxe nav peyamê hilbijêrin û dîsa bikirtînin",
    birthdayOfferAndMessage: "Pêşniyar û Peyama Rojbûnê",
    birthdayOffer: "Birthday Pêşkêşiya",
    birthdayMessage: "Birthday Message",
    noOfferFound: "Pêşniyar nehat dîtin",
    settingABirthdayOffer:
      "Danîna pêşniyara jidayikbûnê dihêle mişterî vê salvegera xwe bi SMS -ê bistînin",
    createOffer: "Pêşniyarê biafirînin",
    whatIsTheOffer: "Pêşniyar çi ye?",
    editMessage: "Peyamê Biguherîne",
    insert: "Lêzêdekirin",
    theNameOfCustomerInserted: "Navê xerîdar dê li vir were lêxistin",
    theBirtdayOfferInserted: "Pêşniyara rojbûnê dê li vir were lêkirin",
    youSuccessfullyAddedNewBranch: "We şaxek nû bi serfirazî lê zêde kir!",
    addNewBranch: "Newaxa Nû lê zêde bikin",
    addBranch: "Addaxê zêde bikin",
    additionalBranchWillIncur: "Branaxa Additional dê çêbibe",
    perBranch: "per şaxek",
    ecommerceBranchCosts: "Mesrefên chaxa Ecommerce",
    pleaseEnterBranchName: "Ji kerema xwe navê şaxê binivîsin",
    pleaseEnterBranchAddress1: "Ji kerema xwe xeta navnîşana şaxê 1 binivîse",
    enterBranchAddress1: "Navnîşa navnîşana şaxê 1 binivîse",
    enterBranchAddress2: "Navnîşa navnîşana şaxê 1 binivîse",
    pleaseEnterBranchAddress2: "Ji kerema xwe xeta navnîşana şaxê 2 têkevin",
    enterBranchName: "Navê şaxê binivîse",
    successfullyAddedStaff: "We karmendek nû bi serfirazî lê zêde kir!",
    addNewStaff: "Karmendên Nû zêde bikin",
    addStaff: "Karmendan zêde bikin",
    additionalStaffWillIncur: "Karmendên Zêde dê biserkevin",
    perStaff: "per karmendan.",
    pleaseEnterStaffEmail: "Ji kerema xwe e -nameya karmendan binivîsin",
    pleaseEnterStaffUsername:
      "Ji kerema xwe navê bikarhêner a karmendan binivîsin",
    pleaseEnterStaffPassword: "Ji kerema xwe şîfreya karmendan binivîsin",
    pleaseSelectStaffRole: "Ji kerema xwe rola karmendan hilbijêrin",
    selectStaffRole: "Rola karmendan hilbijêrin",
    enterStaffEmail: "E -nameya karmendan binivîse",
    enterStaffUsername: "Navê bikarhêner ê karmendê binivîse",
    enterStaffPassword: ".Îfreya karmendan binivîse",
    spacesNotAllowedInUsername:
      "cihên ku di navê bikarhêner de nayê destûr kirin",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Ji kerema xwe karsaziyê hilbijêrin ku karmendan pêve bikin",
    searchForBranchToAttachStaff: "Li şaxa ku karmendan pêve bikin bigerin",
    username: "Navê bikarhêner",
    role: "Role",
    areYouSureToDeleteThis: "Ma hûn bi guman vê yekê jêbirin",
    branches: "Branaxên",
    upgradeYourPlan: "Plana xwe nûve bikin.",
    add: "LÊZÊDEKIRIN",
    addNew: "Nû lê zêde bike",
    customerWithEmailAlreadyExists:
      "Xerîdar bi e -name/jimareya têlefonê jixwe heye!",
    successfullyAddedNewCustomer: "We xerîdarek nû bi serfirazî lê zêde kir!",
    addCustomer: "Mişterî zêde bikin",
    pleaseEnterCustomerFirstName: "Ji kerema xwe navê mişterî têkevin",
    pleaseEnterCustomerLastName: "Ji kerema xwe paşnavê xerîdar binivîse",
    pleaseEnterCustomerPhoneNumber:
      "Ji kerema xwe hejmara têlefona xerîdar binivîse",
    pleaseEnterCustomerEmail: "Ji kerema xwe e -nameya xerîdar binivîse",
    pleaseEnterCustomerAddressLine:
      "Ji kerema xwe Rêzeya Navnîşana Xerîdar binivîse",
    pleaseEnterCustomerOtherAddress:
      "Ji kerema xwe Navnîşana din a xerîdar binivîse",
    pleaseSelectCustomerGender: "Ji kerema xwe zayenda xerîdar hilbijêrin",
    gender: "Zayend",
    male: "Nêrî",
    female: "Mê",
    bank: "Banke",
    selectBank: "Bank hilbijêrin",
    stateOrRegionOrProvince: "Dewlet/Herêm/Parêzgeh",
    customerNotes: "Notes Mişterî",
    sendSms: "SMS bişînin",
    editCustomer: "Xerîdar Biguherîne",
    redeemReward: "Xelat Xilas Bike",
    issueLoyaltyCard: "Karta Dilsoziyê Derxînin",
    deleteCustomer: "Xerîdar Jê Bike",
    youveSuccessfullyAssignedLoyaltyMembership:
      "We Nasnameyek Endamtiya Dilsoziyê bi serfirazî destnîşan kir",
    noMembershipIdAvailable:
      "Nasnameyên endamtiyê tune. Ji kerema xwe bi me re têkilî daynin hello@loystar.co",
    sendEmail: "Email bişînin",
    membershipPoints: "Xalên Endamtiyê",
    customerDetails: "Details Mişterî",
    close: "Nêzîkî",
    loyaltyBalance: "Hevsengiya Dilsoziyê",
    pointsBalance: "Xalên Balansa",
    starBalance: "Balansa Star",
    requiredPoints: "Require Points",
    requiredStars: "Stêrkên Pêwîst",
    reddemCode: "Kodê Xilas Bike",
    toDeleteThisCustomer: "ji bo jêbirina vê mişterî",
    customerHasBeenDeletedSuccessfully: "Xerîdar bi serkeftî hate jêbirin!",
    customerWithPhoneAlreadyExists: "Xerîdar bi jimara têlefonê jixwe heye",
    customerWasSuccessfullyEdited: "Xerîdar bi serfirazî hate guheztin",
    anErrorOccured: "Çewtiyek çêbû",
    phoneNumberIncludeCountry: "Hejmara Telefonê (Koda Welat Têde Bike)",
    yourFileQueuedForUpload:
      "Pelê we ji bo barkirinê ketiye rêzê. Ji kerema xwe rûpel piştî çend hûrdeman nûve bikin.",
    thereWasAnErrorPerformingOperation:
      "Di pêkanîna operasyonê de xeletiyek çêbû!",
    pleaseSelectFile: "Ji kerema xwe Pelê Hilbijêre!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Tenê pelên CSV têne destûr kirin. Ji kerema xwe pelê .csv barkirin.",
    fileShouldBeSmallerThan5Mb:
      "Divê pel ji 5 MB kêmtir be. Heke pelê we mezintir heye, ji kerema xwe e -nameyê bişînin support@loystar.co. Spas dikim",
    customerFirstNameIsRequired: "navê xerîdar li ser rêzê hewce ye",
    customerPhoneNumberIsRequired:
      "hejmara telefonê ya xerîdar li ser rêzê hewce ye",
    importCustomers: "Mişteriyên Import",
    upload: "Upload",
    clickIconToDownloadCsv:
      "Vê îkonê bikirtînin da ku şablona pelê CSV dakêşin.",
    getGoogleSheetVersion: "Guhertoya rûpela Google -ê bistînin",
    clickOrDragFileToUpload:
      "Ji bo barkirinê pelê bikirtînin an bikişînin vê deverê",
    missingOrInvalidColumnHeader:
      "Sernivîsa stûnê kêm an jî nederbasdar e. Ji kerema xwe forma şablonê bişopînin. Spas dikim.",
    youHaveImported: "We îthal kiriye",
    youSuccessfullyRedeemedReward: "We xelata xwe bi serfirazî xilas kir!",
    sixDigitCode: "Kodê şeş hejmarî",
    pleaseEnterCustomerRewardCode: "Ji kerema xwe kodê xelata xerîdar binivîse",
    enterRewardCode:
      "Kodê xelatê binivîse. (Koda xelatê bi tîpên mezin hesas e)",
    selectLoyaltyProgram: "Bernameya dilsoziyê hilbijêrin",
    stamps: "Pûman",
    smsUnitsLow: "SMS Yekîneyên Low",
    pleaseTopUpSmsUnits: "Ji kerema xwe Yekîneyên SMS -ê Serî bikin",
    smsSuccessfullySentForDelivery:
      "SMS bi serkeftî ji bo radestkirinê hate şandin!",
    sendSmsTo: "SMS bişînin",
    allCustomers: "Hemû Mişterî",
    unitsAvailable: "Yekîneyên Available",
    pleaseTypeInTheMessage: "Ji kerema xwe peyamê binivîsin",
    message: "Agah",
    charactersRemaining: "tîpên mayî",
    avoidUseOfSpecialCharacters:
      "Ji bo parastina Yekîneyên SMS -ê ji karanîna Karakterên Taybet û Emojiyan dûr bisekinin.",
    note: "Not",
    errorFetchingCustomersMultilevelDetail:
      "Çewtî Di Vekirina Mişteriyan de Daneyên Pirjimar",
    search: "Gerr",
    reset: "Reset bike",
    importCustomer: "Import Mişterî",
    addNewCustomer: "Xerîdarê Nû zêde bikin",
    sendSmsBroadcast: "Weşana SMSê bişînin",
    totalCustomers: "TOTAL Mişterî",
    disbursementDetails: "Hûrguliyên Belavkirinê",
    paidBy: "Paid By",
    disbursed: "Belav kirin",
    bankAccountName: "Navê hesabê bankê",
    bankAccountNumber: "Hejmara Hesabê Bankê",
    transferInitiated: "Veguhastin Dest pê kir",
    transferCompleted: "Veguhestin Temam Bû",
    pleaseEnterAValid: "Ji kerema xwe derbasdar binivîse",
    begin: "destpêkirin",
    end: "dawî",
    date: "rojek",
    paymentDate: "Payment Date",
    selectDisbursementDuration: "Demdirêjiya Veqetandinê Hilbijêrin",
    totalSettled: "Total Settled",
    totalUnsettled: "Total Unsettled",
    toDeleteThisSale: "ji bo vê firotanê jêbirin",
    draftSaleDeletedSuccessfully: "Pêşnûmeya Firotanê bi serketî hate jêbirin!",
    deleteSale: "Firotanê Jê Bikin",
    pleaseTypeInYourTotal:
      "Ji kerema xwe jimara xwe binivîsin da ku jêbirinê piştrast bikin",
    billDetails: "Details Bill",
    printBill: "Bill çap bike",
    servedBy: "Served by",
    total: "Hemî",
    createdDate: "Dîroka Çêkirî",
    createdTime: "Time Created",
    status: "Cî",
    loginSuccessful: "Têketin Serketî",
    pleaseWaitWhileWeConnectAccount:
      "Ji kerema xwe li bendê bimînin heya ku em hesabê we girêdin",
    connectionFailedTryAgain: "Girêdan têk çû. Ji kerema xwe dîsa biceribînin.",
    connectionSuccessful: "Girêdan Serkeftî",
    viewDetails: "View Details",
    enable: "Bikêrkirin",
    free: "Belaş",
    cost: "Nirx",
    visitWebsite: "Serdana Malperê bikin",
    pleaseUpgradeYourPlanToPro:
      "Ji kerema xwe nexşeya xwe bi Pro Plus nûve bikin ku vê sepanê çalak bikin",
    connectYourBankAccount:
      "Hesabê Banka xwe ve girêdin da ku hûn karibin dravdanan bistînin.",
    disable: "Neçalak bike",
    enableApp: "App çalak bike",
    addNewProductToInvoice: "Hilbera Nû li Fatûreyê zêde bikin",
    toDeleteThisInvoice: "ji bo jêbirina vê Fatûreyê",
    invoiceDeletedSuccessfully: "Fatûra bi serkeftî hate jêbirin!",
    deleteInvoice: "Fatûra Jêbirin",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "We bi serfirazî peyamek dravdana Invoice zêde kir!",
    pleaseEnterPaymentInstructions: "Ji kerema xwe talîmatên dravdanê binivîse",
    invoiceId: "Nasnameya fatûreyê",
    paidDate: "Payed Date",
    reference: "Balkêşî",
    productAdded: "Hilber zêde kirin",
    productOutOfStock: "Berhem ji stokê derket. Ji kerema xwe re-stock.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "TOTAL FATOVAYN PAYID",
    totalUnpaidInvoices: "TOTAL FATOVAYN NEXWE",
    loyaltyProgramDeleted: "Bernameya Dilsoziyê Hat Vekirin",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ev bernameya dilsoziyê bi serkeftî hate jêbirin",
    thisLoyaltyProgramEditedSuccessfully:
      "Ev bernameya dilsoziyê bi serfirazî hate guheztin",
    loyaltyProgramAddedSuccessfully:
      "Bernameya Dilsoziyê bi serfirazî hate zêdekirin",
    loyaltyProgramEdited: "Bernameya Dilsoziyê Edited",
    loyaltyProgramAdded: "Bernameya Dilsoziyê Zêde Bû",
    loyaltyDetails: "Details dilsoziya",
    doYouWantToCloseDialog: "Ma tu dixwazî vê diyalogê bigire?",
    pleaseEnterLoyaltyName: "Ji kerema xwe navê dilsoziya xwe binivîsin",
    programType: "Cureya Bernameyê",
    pleaseSelectProgramType: "Ji kerema xwe celebek bernameyê hilbijêrin",
    simplePoints: "Simple Points",
    stampsProgram: "Bernameya Pûlan",
    threshold: "Nepxok",
    pleaseEnterLoyaltyThreshold: "Ji kerema xwe sînorê dilsoziyê têkevin",
    reward: "Xelat",
    pleaseEnterLoyaltyReward: "Ji kerema xwe xelata dilsoziyê têkevin",
    totalUserPoints: "Total Points User",
    totalUserStamps: "Total Pûanên Bikarhêner",
    spendingTarget: "Armanca lêçûnê",
    spendingTargetHint1:
      "Armanca lêçûnê ev e ku xerîdar çiqas pêdivî ye ku xerc bike da ku xelatê bigire. 1 nirxa diravan = 1 xal.",
    spendingTargetHint2:
      "Armanca pûlan ew e ku çend mûçe divê xerîdar berhev bike da ku xelatê bigire. mînak. 5",
    addNewLoyaltyProgram: "Bernameya Dilsoziya Nû zêde bikin",
    addLoyaltyProgram: "Bernameya Dilsoziyê zêde bikin",
    loyaltyProgramType: "Cureya Bernameya Dilsoziyê",
    pleaseSelectLoyaltyProgramType:
      "Ji kerema xwe celebê bernameya dilsoziyê hilbijêrin",
    nameOfProgram: "Navê bernameyê",
    pleaseEnterProgramName: "Ji kerema xwe navê bernameyê binivîsin",
    whatIsTheReward: "Xelat çi ye?",
    egNextPurchaseIsFree: "Mînak Kirîna paşîn belaş e",
    customerName: "Navê Xerîdar",
    guestCustomer: "Mêvan Mişterî",
    orderConfirmedSuccessfully: "Ferman bi serkeftî hate piştrast kirin",
    orderCancelledSuccessfully: "Siparîş bi serkeftî hate betalkirin",
    confirmOrder: "Fermana piştrast bikin",
    cancelOrder: "Fermanê betal bike",
    allOrders: "Hemû Orders",
    totalOrders: "TOTAL FERMAN",
    doYouWantToAcceptOrder: "Ma hûn dixwazin vê fermanê qebûl bikin?",
    doYouWantToCancelOrder: "Ma hûn dixwazin vê fermanê betal bikin?",
    orderDetails: "Order Details",
    orderCreatedAt: "Order afirandin li",
    supplier: "Şandevan",
    productName: "Navê hilberê",
    quantity: "Jimarî",
    unitPrice: "Unit Price",
    receivedBy: "Received By",
    reportFrom: "Rapor ji",
    totalSupplies: "Total Supplies",
    allRightsReserved: "Hemû maf parastî ne",
    toDeleteThisTransaction: "ji bo rakirina vê Veguheztinê",
    transactionDeletedSuccessfully:
      "Danûstandin bi serkeftî hate jêbirin. Stok li envanterê vegeriya.",
    deleteTransaction: "Danûstandinan Jêbirin",
    transactionDetails: "Daneyên Veguheztinê",
    printReceipt: "Wergirtina Çapkirinê",
    channel: "Qenal",
    discount: "Kêmkirinî",
    profit: "Birin",
    discountedSales: "Tenzîlat Sales",
    errorFetchingRecord: "Çewtiya Vegirtina Tomar",
    exportTransactions: "Danûstendinên Export",
    errorFetchingSalesRecord:
      "Çewtiya girtina tomara firotanê ya ji bo Export.",
    totalSellingPrice: "Total Selling Price",
    totalCostPrice: "Total Cost Price",
    appliedDiscount: "Tenzîlat Applied",
    noOfItems: "Hejmara Tiştan",
    sales: "Sales",
    export: "Eksport",
    totalProfit: "Total Profit",
    totalBalanceInPeriod: "Tevahiya Bilaniya Serdemê",
    allTimeSales: "All Time Sales",
    records: "Records",
    todaysSales: "Firotanên îro",
    transaction: "şandindayinî",
    youHaveExceededTotalNumberOfProducts:
      "We ji jimara giştî ya hilberên ku li ser nexşeya xwe destûr dane derbas kiriye. Plana xwe nûve bikin ku hûn ji tixûbek bilindtir kêf bikin.",
    youNeedToHaveLoyaltyProgram:
      "Pêdivî ye ku hûn Bernameyek Dilsoziyê hebe da ku hûn vê taybetmendiyê bikar bînin!",
    price: "Biha",
    category: "Liq",
    stockTracking: "Stock ckopandina",
    stockCount: "Stock Count",
    taxed: "Bac kirin",
    originalPrice: "Original Price",
    costPrice: "Cost Price",
    unit: "Yekbûn",
    productImage: "Wêneyê Hilberê",
    taxRate: "Rêjeya Bacê",
    taxType: "Tîpa Bacê",
    trackInventory: "Track Inventory",
    variants: "Variants",
    hasVariants: "Guhertoyên wê hene",
    importProduct: "Import Product",
    exportProducts: "Berhemên portxracatê",
    addNewProduct: "Hilbera Nû Zêde Bikin",
    viewCategory: "View Kategorî",
    viewSuppliers: "View Suppliers",
    receiveInventory: "Inventory bistînin",
    printAllProductsTag: "Hemî Berhemên Tagê çap bikin",
    deleteAll: "Hemî Vemirînin",
    totalProducts: "BERHEMN BERHEMN",
    youveSuccessfullyAddedANewCategory:
      "We kategoriyek nû bi serfirazî lê zêde kir",
    addNewCategory: "Kategoriya Nû zêde bikin",
    addCategory: "Kategorî zêde bikin",
    categoryName: "Navê Kategorî",
    pleaseEnterCategoryName: "Ji kerema xwe navê kategoriyê binivîsin",
    stampsTarget: "Stamps Target",
    sendInventory: "Inventory bişînin",
    productDetails: "Agahiyên Hilberê",
    youveSuccessfullyEditedThisCategory:
      "We ev kategorî bi serfirazî sererast kir",
    update: "Rojanekirin",
    categoryList: "Lîsteya Kategorî",
    categories: "Kategorî",
    enterQuantityToUpdate: "Hejmarek nûve bikin",
    inventorySentSuccessfully: "Envantera bi serkeftî hate şandin!",
    updateInventory: "Inventory Update",
    currentQuantity: "Quantity Current",
    pleaseEnterQuantityToAdd:
      "Ji kerema xwe miqdara ku hûn dixwazin lê zêde bikin binivîsin",
    pleaseSelectABranch: "Ji kerema xwe aaxek hilbijêrin",
    searchForBranch: "Li şaxê bigerin",
    youCantSendMoreThanStock:
      "Hûn nikarin ji ya ku we di stokê de heye zêdetir bişînin",
    send: "Şandin",
    pleaseEnterQuantityToSend:
      "Ji kerema xwe miqdara ku hûn dixwazin bişînin binivîsin",
    productNameIsRequiredOnRow: "navê hilberê li ser rêzê hewce ye",
    productCategoryIsRequiredOnRow: "kategoriya hilberê li ser rêzê hewce ye",
    productPriceIsRequiredOnRow: "bihayê hilberê li ser rêzê hewce ye",
    productUnitIsRequiredOnRow: "yekîneya hilberê li ser rêzê hewce ye",
    productQuantityIsRequiredOnRow: "Hejmara hilberê li ser rêzê hewce ye",
    productVariantsRequireTracking: "guhertoyên hilberê şopandinê hewce dike!",
    pleaseAddVariantClickButton:
      "Ji kerema xwe bi tikandina bişkoja lê zêde bike vebijarkek zêde bikin!",
    totalVariantsMoreThanSelectedQuantity:
      "Guhertoyên Giştî ji hêjeya hilbera bijartî pirtir in, Pls mîqyasa variant kêm dike",
    productEditedSuccessfully: "Hilber bi serfirazî hate guheztin!",
    fileTooLargeLessThan4Mb:
      "Mezinahiya pelê pir mezin e! Mezinahiya pelê divê ji 4 MB kêmtir nebe.",
    suchVariantAlreadyExist: "Variantek wusa jixwe heye",
    addVariants: "Variantan zêde bikin",
    editProduct: "Product Edit",
    pleaseEnterProductName: "Ji kerema xwe Navê Hilberê binivîse",
    pleaseEnterProductPrice: "Ji kerema xwe bihayê hilberê binivîse",
    pleaseEnterProductOriginalPrice:
      "Ji kerema xwe Berhemê Bihayê Berhemê binivîse",
    productDescription: "Danasîna Hilberê",
    selectProductCategory: "Kategoriya Hilberê Hilbijêrin",
    pleaseSelectProductCategory: "Ji kerema xwe kategoriya hilberê hilbijêrin",
    productCostPrice: "Bihayê Hilbera Hilberê",
    productSellingPrice: "Bihayê Firotanê Berhem",
    productOriginalPrice: "Berhemê Bihayê Berê",
    maxFileSizeAllowedIs4Mb: "Max. Mezinahiya pelê 4mb e",
    productsWithPicturesArePublished:
      "Berhemên bi wêne ji bo wergirtina fermanan li Discover têne weşandin",
    shouldThisProductBeTracked: "Ma pêdivî ye ku ev hilberê were şopandin?",
    pleaseSelectStockUnit: "Ji kerema xwe yekîneya stokê hilbijêrin",
    stockUnit: "Yekîneya Stock",
    bag: "TÛR",
    bottle: "ŞÛŞE",
    bunch: "BUNCH",
    can: "QÛTÎK",
    carton: "KARTON",
    crate: "QEDER",
    cup: "TAS",
    dozen: "DESTE",
    gigabytes: "GIGABYTES",
    gram: "XIRAM",
    kilogram: "KILOGRAM",
    litre: "ÇARIT",
    pack: "HEVDAN",
    pair: "COT",
    pieces: "PARES",
    plate: "TEMSÎK",
    tonne: "TONNE (MT)",
    uNIT: "YEKBÛN",
    yard: "HEWŞ",
    pleaseEnterProductQuantity: "Ji kerema xwe mîqdara hilberê binivîsin",
    productQuantity: "Hejmara Hilberê",
    isThisProductTaxed: "Ma ev hilberê bacê ye?",
    selectTaxType: "Hilbijartina Tîpa Bacê",
    pleaseSelectTaxType: "Ji kerema xwe Tîpa Bacê hilbijêrin",
    progressive: "Pêşverû",
    proportional: "Rêjeyî",
    pleaseEnterProductTaxRate: "Ji kerema xwe rêjeya baca hilberê binivîse",
    doesProductHaveVariants: "Ma guhertoyên vê hilberê hene?",
    type: "Awa",
    value: "Giranî",
    pleaseEnterVariantType: "Ji kerema xwe tîpa variant binivîse",
    pleaseEnterVariantValue: "Ji kerema xwe nirxa variant binivîse",
    pleaseEnterVariantPrice: "Ji kerema xwe bihayê variantê têkevin",
    pleaseEnterVariantQuantity: "Ji kerema xwe mîqyasa variant binivîse",
    productDeletedSuccessfully: "Hilber bi serkeftî hate jêbirin!",
    categoryDeletedSuccessfully: "Kategorî bi serkeftî hate jêbirin!",
    toDeleteThisProduct: "ji bo jêbirina vê Hilberê",
    invalidProductQuantity: "Hejmara Hilbera Nerast",
    quantityAddedIsOverStock:
      "Hejmara ku hûn lê zêde dikin ji ya ku we di stokê de heye zêdetir e.",
    itemInventoryNotTracked: "Envantera hêman nayê şopandin",
    addBulkQuantity: "Mîqdara Zêde zêde bikin",
    enterBulkQuantity: "Hejmara Girseyî Binivîse",
    pleaseEnterBulkQuantity: "Ji kerema xwe Hejmara Girseyî binivîse",
    youveSuccessfullyAddedANewProduct:
      "We hilberek nû bi serfirazî lê zêde kir!",
    pleaseEnterProductSellingPrice:
      "Ji kerema xwe bihayê firotina hilberê binivîse",
    doYouWantToTrackProductStock: "Ma hûn dixwazin stoka hilberê bişopînin?",
    sellingPrice: "Firotina Bihayê",
    setProductExpiryReminder: "Bîra Qedexeya Hilberê Bikin",
    productExpiryDate: "Dîroka qedandina hilberê",
    startRemindingFrom: "Destpêkirina Bîranînê Ji",
    productSuppliesAddedSuccessfully:
      "We amûrên hilberê bi serfirazî zêde kir.",
    addProductSupplies: "Pêdiviyên Hilberê zêde bikin",
    pleaseSelectSupplier: "Ji kerema xwe Pêşkêşker hilbijêrin",
    nameOfProduct: "Navê Hilberê",
    pleaseSelectProduct: "Ji kerema xwe Hilberê Hilbijêrin",
    productVariant: "Guhertoya Hilberê",
    pleaseSelectAVariant: "Ji kerema xwe Variantek Hilbijêrin",
    pleaseEnterUnitPrice: "Ji kerema xwe bihayê yekîneyê binivîse",
    businessBranch: "Businessaxa Karsaziyê",
    pleaseSelectBranch: "Ji kerema xwe şaxê hilbijêrin",
    youveSuccessfullyAddedANewSupplier:
      "We dabînkerê nû bi serfirazî lê zêde kir",
    addSupplier: "Pêşkêşker zêde bikin",
    pleaseEnterSupplierEmail: "Ji kerema xwe e -nameya dabînkerê têkevin",
    pleaseAddADescription: "Ji kerema xwe vegotinek lê zêde bike",
    anErrorOccuredProductsDeleted:
      "Di dema pêkanîna operasyonê de xeletiyek çêbû. Ji kerema xwe notê bigirin, dibe ku çend hilber di pêvajoyê de hatine jêbirin",
    bulkDelete: "Bulk Delete",
    youAreAboutToDelete: "Hûn li ser jêbirinê ne",
    product: "Mal",
    isDueToRedeem: "ji ber xilasbûnê ye",
    aReward: "xelatek",
    pleaseSelectCustomerToReeemReward:
      "Ji kerema xwe xerîdarek hilbijêrin ku xelatê bikirtînin.",
    youHaveNoLoyaltyProgramRunning:
      "Tu bernameya dilsoziya çalak a ku dimeşe tune",
    customerHhasNoPointsInLoyaltyProgram:
      "Xerîdar di vê bernameya dilsoziyê de xalên wê tune",
    proceedWithPayment: "Bi Peredanê Berdewam Bike?",
    youAreAboutToPayForTransactionUsingPoints:
      "Hûn nêzîkê dravdana bi karanîna xalan in.",
    customerHas: "Mişterî Has",
    worth: "giranbiha",
    andIsNotSufficientToPayForTransaction:
      "û têra dayîna vê danûstendinê nake. Ma ew dixwazin bi karanîna rêbaza dravdana din balansê zêde bikin?",
    addCustomerLoyalty: "Dilsoziya Xerîdar zêde bikin",
    pleaseAddCustomerFirst:
      "Ji kerema xwe pêşî xerîdar zêde bikin an hilbijêrin.",
    pleaseWaitWhileWeGetReady:
      "Ji kerema xwe bisekinin, heya ku em amade bibin",
    lowStock: "Low Stock",
    pleaseEnterAmountTendered: "Ji kerema xwe mîqdara îhalekirî têkevin",
    areYouSureToBookSaleOffline:
      "Ma hûn guman dikin ku hûn dixwazin vê firotanê offline tomar bikin?",
    saleWouldBeBookedAutomatically:
      "Gava ku hûn înterneta xwe vekin dê firotan bixweber were vegirtin",
    offlineSalesBookingCancelled: "Veqetandina firotanê ya offline betal kir",
    covid19Message:
      "COVID19: Destên xwe bi sabûnê bişon an Paqij bikin da ku belavbûnê rawestînin. Herdem Ewle bimînin",
    saleSuccessfullyRecorded: "Firotin bi serfirazî hate tomarkirin!",
    sendReceiptToEmail: "Meqbûzê ji E -nameyê re bişînin",
    sendThankYouSms: "SMSek spas bişînin",
    sendShippingDetails: "Hûrguliyên Barkirinê bişînin",
    addLoyalty: "Dilsoziyê lê zêde bike",
    searchCustomerNameOrNumber: "Nav an jimareya xerîdar bigerin",
    clickTheSearchCustomerBox:
      "Qutiya Mişterî ya Lêgerînê û Qerta Lêkolînê bikirtînin",
    enterProductPrice: "Bihayê Hilberê binivîse",
    enterPriceFor: "Bihayê Enter ji bo",
    searchForProduct: "Li hilberê bigerin",
    all: "Gişt",
    backToDashboard: "Vegere Dashboard",
    viewDraftSales: "Dîtina Pêşniyarê Firotanê",
    variantSelected: "variant hilbijartî",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Hilbijartina vebijarka berê ji bo guhertoya nû ya ku li gorî bihayê hatî hilbijartin peyda nabe, ji kerema xwe vebijarka xwe biguhezînin.",
    pleaseSupplyPositiveQuantityNumber:
      "Ji kerema xwe hejmarek mîqdara pozîtîf peyda bikin",
    lowStockFor: "Stoka kêm ji bo",
    clearVariants: "Variantên zelal",
    pleaseEnterQuantity: "Ji kerema xwe Hejmarek binivîse",
    picture: "Sûret",
    redemptionToken: "Redemption Token",
    token: "Token",
    totalSpent: "Tev Serf kirin",
    confirmPayment: "Payment piştrast bikin",
    hasPaymentBeenMade: "Ma Tezmînat bi serfirazî hatî meşandin?",
    enterTransactionReference:
      "Referansa danûstendinê ya ku we lê daye binivîse",
    pleaseSelectACustomer: "Ji kerema xwe xerîdar hilbijêrin!",
    areYouSureToApplyDiscount:
      "Ma hûn guman dikin ku hûn dixwazin daxistinê bidin?",
    addYourBankAccountToEnableUssd:
      "Hesabê banka xwe zêde bikin ku veguheztina Bilez a USSD bi uPay çalak bike",
    totalAmountToPay: "Tevahiya Heqê Pay",
    doYouWantToCancelTransaction:
      "Ma hûn dixwazin vê danûstendinê betal bikin?",
    savePrintBill: "Tomar bike/Bill çap bike",
    enterAmountCollectedForCustomer:
      "Hejmara ku ji bo xerîdar hatî berhev kirin binivîse",
    recordSale: "Record Sale",
    checkOutWith: "Bi kontrol bikin",
    instantTransfer: "Veguheztina Bilez",
    dialTheUSSDCode: "Koda USSD bikirtînin",
    pleaseSelectABank: "Ji kerema xwe bankek hilbijêrin",
    payWithUSSD: "Bi USSD bidin",
    sendBillTo: " - Bill bişîne",
    waitingForUSSDTransfer: "Li benda Veguheztina USSD ye",
    percent: "Ji sedî",
    applyDiscount: "Discount bicîh bikin",
    thisBillHasBeenSaved: "Ev Qanûn hate xilas kirin",
    saveDraft: "Pêşnuma tomar kirin",
    pleaseTypeANameToIdentifyCustomer:
      "Ji kerema xwe navek binivîsin da ku xerîdar nas bikin",
    paymentDetails: "Details Payment",
    basePrice: "Bihayê Bingehî",
    staff: "Darik",
    subTotal: "SubTotal",
    durationMonths: "Demjimêr (meh)",
    selectADuration: "Demek Hilbijêrin",
    oneMonth: "1 Meh",
    twoMonths: "2 meh",
    threeMonths: "3 meh",
    sixMonths: "6 meh",
    twelveMonths: "12 meh",
    eighteenMonths: "18 Meh",
    twentyFourMonths: "24 meh",
    twoMonthsFree: "(2 meh belaş)",
    threeMonthsFree: "(3 meh belaş)",
    fiveMonthsFree: "(5 meh belaş)",
    yourAccountHasBeen: "Hesabê we hatî",
    renewed: "nû kirin",
    upgraded: "nûjen kirin",
    successfully: "bi serkeftî",
    yourSubscription: "Abonetiya We",
    youAreCurrentlyEnrolledOnA: "Hûn naha li ser qeydkirî ne",
    pleaseChooseAPaymentOption: "Ji kerema xwe vebijarkek dravdanê hilbijêrin",
    planRenewal: "Plana Nûvekirinê",
    planUpgrade: "Plan Upgrade",
    pleaseSelectDurationToPayFor:
      "Ji kerema xwe Demdirêja ku hûn dixwazin drav bidin hilbijêrin",
    staffAccounts: "Accounts Staff",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Ji kerema xwe nexşeyek hilbijêrin",
    includeAddons: "Add-on-ê Tevlê bikin",
    viewTransactions: "Danûstandinan bibînin",
    customerHasNoCompletedTansactions: "Xerîdar hêj tansiyonên xwe qedandiye",
    branch: "Liq",
    enterNumberOfEcommerceBranches: "Hejmara Branaxên Ecommerce binivîse",
    enterNumberOfEcommerceBranchesToPay:
      "Hejmara Branaxên Bazirganiya Ecommerce ya ku Hûn Dixwazin Pay Bikin Binivîse",
    ecommerceIntegration: "Ecommerce Integration",
    enterNumberOfBranches: "Hejmara chesaxan Binivîse",
    enterNumberOfAdditionalBranchesToPay:
      "Hejmara chesaxên Zêde yên Ku Hûn Naxwazin Bidin Ji Bo Wan Bikin",
    enterNumberOfStaffs: "Hejmara Karmendan binivîse",
    enterNumberOfStaffsToPayFor:
      "Hejmara karmendên ku hûn dixwazin ji bo wan bidin bidin binivîse",
    discountApplies: "Discount Applies",
    starsOnThe: "stêrk li ser",
    offer: "pêşnîyar",
    get: "Stendin",
    moreStarsToRedeem: "bêtir stêrk xilas bikin",
    taxVat: "Bac (Bac)",
    callCashierToCompletePayment:
      "Ji bo Temamkirina Tezmînatê bang li Xazîno bikin",
    receipt: "Meqbûz",
    dear: "Birêz",
    thankYouForYourOrderFindGoods:
      "Spas ji bo fermana we. Ji kerema xwe tiştên ku li jêr hatine peydakirin, li gorî lihevkirinê, bibînin.",
    shippingNote: "Ppingandina Têbînî",
    enterShippingNote: "Têkeve Shipping Shipping",
    shippingAddress: "Navnîşana barkirinê",
    enterShippingAddress: "Navnîşana Barkirinê binivîse",
    wellDoneYouAreDueToRedeem: "Aferîn! Hûn neçar in ku xilas bikin",
    toGetYourRewardNextVisit:
      "da ku hûn di serdana xweya paşîn de xelata xwe bigirin. Spas dikim",
    pointsOnThe: "Xalên li ser",
    morePointsToRedeem: "bêtir xalên ku werin xilas kirin",
    showCode: "Kodê nîşan bide",
    toGetYourRewardOnNextVisit:
      "da ku hûn di serdana xweya paşîn de xelata xwe bigirin. Spas dikim",
    earn: "Qezenckirin",
    delivaryNote: "Delivary Not",
    draftSales: "Pêşnûmeya Sales",
    startDate: "Dîroka destpêkirinê",
    endDate: "Roja dawî",
    orders: "Orders",
    checkout: "lêkolîn",
    noProductItem: "Tiştek Hilberê tune",
    selectProductImage: "Hilbijartina Wêneya Hilberê",
    selectCountry: "Welat hilbijêre",
    selectRegion: "Dewlet/Herêm hilbijêrin",
    printProductTag: "Etîketa Hilberê çap bikin",
    transactionReference: "Referansa danûstendinê",
    Cashier: "Diravgir",
    Manager: "Rêvebir",
    Owner: "Xwedî",
    Admin: "Admin",
    addPartners: "Hevkar zêde bikin",
    addNewLoyaltyPartner: "Hevkarê Dilsoziya Nû lê zêde bikin",
    pleaseEnterCompanyName: "Ji kerema xwe Navê Pargîdaniyê binivîse",
    companyName: "Navê Şirketa",
    pleaseEnterCompanyRepName: "Ji kerema xwe Navê nûnerê pargîdaniyê binivîse",
    companyRepName: "Navê Nûnerê Pargîdaniyê",
    pleaseEnterCompanyRepEmail:
      "Ji kerema xwe e-nameya nûnerê pargîdaniyê têkevin",
    companyRepEmail: "E-nameya nûnerê pargîdanî",
    pleaseEnterCompanyRepPhone:
      "Ji kerema xwe jimara têlefona nûnerê pargîdaniyê têkevin",
    companyRepPhone: "Hejmara Telefonê nûnerê pargîdanî",
    addReward: "Xelat zêde bikin",
    pleaseEnterRewardName: "Ji kerema xwe navê xelatê binivîse",
    rewardName: "Xelat Name",
    rewardQuantity: "Xelat Quantity",
    rewardDescription: "Xelat Danasîna",
    rewardType: "Tîpa xelatê",
    pleaseEnterRewardType: "Ji kerema xwe cureyê xelatê binivîse",
    pleaseEnterRewardQuantity: "Ji kerema xwe mîqdara xelatê binivîse",
    pleaseEnterRewardDescription: "Ji kerema xwe şiroveya xelatê binivîse",
    fineDining: "Fine Dining",
    luxuryFashion: "Fashion Luxury",
    hotels: "Hotels",
    travel: "Gerrîn",
    foodAndBeverage: "Xwarin û Vexwarin",
    fashion: "Mode",
    health: "Tendûrûstî",
    furniture: "Navmalî",
    entertainment: "Axaftin",
    automobile: "Trombêl",
    education: "Zanyarî",
    beautyAndSpa: "Bedew û Spa",
    staycation: "Staycation",
    events: "Events",
    trips: "Trips",
    oilAndGas: "Petrol û Gaz",
    laundry: "Cil",
    partnerCategory: "Kategoriya Partner",
    freeItem: "Babetê Belaş",
  },
  Latvian: {
    cashier: "kasieris",
    manager: "menedžeris",
    owner: "īpašnieks",
    online: "tiešsaistē",
    offline: "bezsaistē",
    changePassword: "Mainīt paroli",
    currentPasswordMessage: "Lūdzu, ievadiet savu pašreizējo paroli",
    passwordMessage: "Lūdzu ievadiet savu paroli",
    currentPassword: "Pašreizējā parole",
    password: "parole",
    confirmPasswordMessage: "Lūdzu, apstipriniet savu paroli!",
    confirmPassword: "apstipriniet paroli",
    sendViaEmail: "Sūtīt pa e -pastu",
    sendViaWhatsapp: "Sūtīt caur WhatsApp",
    downloadPdf: "Lejupielādēt PDF",
    paid: "samaksāts",
    unpaid: "neapmaksāts",
    partial: "daļējs",
    closeInvoice: "Vai vēlaties slēgt rēķinu?",
    closeInvoiceConfirmation:
      "Rēķins var netikt saglabāts. Vai vēlaties slēgt?",
    yes: "Jā",
    no: "Nē",
    invoice: "Rēķins",
    wasDeducted: "tika atskaitīts",
    for: "priekš",
    item: "Lieta",
    addProduct: "Pievienot produktu",
    paymentReference: "Maksājuma atsauce",
    amountPaid: "Samaksātā summa",
    discountAmount: "Atlaides summa",
    amountDue: "Jāsamaksā",
    amount: "Summa",
    dueDate: "Termiņš",
    paymentType: "Maksājuma veids",
    card: "Karte",
    cash: "Skaidra nauda",
    bankTransfer: "Bankas pārskaitījums",
    paymentMessage: "Maksājuma ziņojums",
    description: "Apraksts",
    sendReceipt: "Sūtīt kvīti",
    delete: "Dzēst",
    save: "Saglabāt",
    resend: "Nosūtīt atkārtoti",
    saveAndSend: "Saglabāt",
    invoiceSaved: "Rēķins saglabāts!",
    selectPaymentMethod: "Lūdzu, izvēlieties maksājuma veidu!",
    selectCustomer: "Lūdzu, izvēlieties klientu!",
    cartEmptyError:
      "Groza saraksts nevar būt tukšs, aizveriet rēķinu un pievienojiet preci grozam!",
    subscriptionExpired:
      "Jūsu abonementam ir beidzies derīguma termiņš, un jūsu konts tagad ir ierobežots. Lai atjaunotu savu kontu, noklikšķiniet uz tālāk esošās pogas",
    renew: "Atjaunot",
    holdOn: "Uzgaidi",
    customerBank: "Klientu banka",
    cancel: "Atcelt",
    selectACustomer: "Atlasiet Klients",
    invoiceSuccessfulPdfError:
      "Rēķins ir veiksmīgi izveidots, taču PDF ģenerēšana prasa ilgāku laiku nekā parasti. Lūdzu, drīz pārbaudiet vēlreiz.",
    downloadingInvoice: "Notiek rēķina lejupielāde",
    downloadingReceipt: "Kvīts lejupielāde",
    whatsappReceiptError:
      "Sūtot kvīti, izmantojot WhatsApp, radās kļūda. Lūdzu, mēģiniet vēlreiz.",
    receiptToWhatsapp: "Kvīts pārsūtīts uz WhatsApp",
    thankYouForPatronage: "Paldies par jūsu patronāžu",
    hereIsYourReceipt: "Šeit ir jūsu maksājuma kvīts",
    errorSendingEmailReceipt:
      "Sūtot kvīti pa e-pastu, radās kļūda. Lūdzu, mēģiniet vēlreiz vai sazinieties ar atbalsta dienestu",
    receiptSentToEmail: "Kvīts ir nosūtīta uz klienta e -pastu",
    invoiceSentToEmail: "Rēķins ir nosūtīts uz klienta e -pastu",
    invoiceSuccessWhatsappError:
      "Rēķins tika izveidots veiksmīgi, taču, nosūtot uz WhatsApp, radās kļūda. Mēģiniet vēlreiz rēķinu sarakstā",
    invoiceSuccessfulEmailError:
      "Rēķins tika izveidots veiksmīgi, bet nosūtot kā e-pastu, radās kļūda. Lūdzu, mēģiniet vēlreiz no rēķinu saraksta",
    invoiceSentToWhatsapp: "Rēķins pārsūtīts uz WhatsApp",
    hello: "Sveiki",
    pleaseDownloadInvoice: "Lūdzu, lejupielādējiet rēķinu",
    pleaseDownloadReceipt: "Lūdzu, lejupielādējiet kvīti",
    from: "no",
    email: "E -pasts",
    upgrade: "Jaunināt",
    youAreOnFreePlan: "Jūs izmantojat bezmaksas plānu.",
    clickOn: "Klikšķiniet uz",
    yourPlanInFewSteps: " abonementu, veicot dažas ātras darbības.",
    to: "uz",
    yourSubscriptionHasExpired:
      "Jūsu abonementam ir beidzies derīguma termiņš, un jūsu konts tagad ir ierobežots.",
    days: "dienas",
    yourSubscriptionExpiresIn:
      "Jūsu Loystar abonementa derīguma termiņš beigsies pēc",
    createAcount: "Izveidot kontu",
    signIn: "Ielogoties",
    continue: "Turpināt",
    enterOtp: "Ievadiet OTP PIN",
    enterValidOtp: "Lūdzu, ievadiet derīgu PIN",
    pin: "PIN",
    tokenSentToMail: "Uz jūsu e -pastu ir nosūtīts marķieris",
    passwordResetSuccessful: "Paroles atiestatīšana bija veiksmīga",
    somethingWentWrong: "Kaut kas nogāja greizi!",
    resetPassword: "Atiestatīt paroli",
    iHaveResetCode: "Man ir paroles atiestatīšanas kods",
    pleaseEnterEmail: "Lūdzu, ievadiet savu e -pastu",
    aTokenWillBeSentToEmail: "Uz jūsu e -pastu tiks nosūtīts marķieris",
    enterEmail: "Ievadi savu epastu",
    sendinYourToken: "Notiek jūsu pilnvaras sūtīšana ...",
    makeSureItMatchesPassword:
      "Pārliecinieties, vai tā atbilst jūsu jaunajai parolei",
    pleaseChooseNewPassword: "Lūdzu, izvēlieties jaunu paroli",
    chooseNewPassword: "Izvēlieties jaunu paroli",
    enterNewPassword: "Lai apstiprinātu, ievadiet savu jauno paroli",
    enterTokenSent: "Ievadiet marķieri, kas nosūtīja jūsu pastu",
    resetToken: "Atiestatīt marķieri",
    resettingPassword: "Notiek paroles atiestatīšana ...",
    signUp: "Pierakstīties",
    adminSignInWithEmail:
      " Administrators pierakstās ar e -pastu, savukārt darbinieki pierakstās ar lietotājvārdu.",
    pleaseEnterEmailOrUsername:
      "Lūdzu, ievadiet savu e -pastu vai lietotājvārdu",
    emailOrUsername: "E-pasts vai lietotājvārds",
    pleaseEnterPassword: "Lūdzu, ievadiet paroli",
    createAnAccount: "Izveidot kontu",
    forgotPassword: "Aizmirsi paroli?",
    enterPhoneNumber: "Ievadiet tālruņa numuru",
    personalData: "Personas dati",
    validateConfirmationCOde: "Apstipriniet apstiprinājuma kodu",
    pleaseEnterFirstName: "Lūdzu, ievadiet savu vārdu",
    pleaseEnterPhoneNumber: "Lūdzu, ievadiet savu tālruņa numuru",
    pleaseEnterLastName: "Lūdzu, ievadiet savu uzvārdu",
    pleaseEnterBusinessName: "Lūdzu, ievadiet sava uzņēmuma nosaukumu",
    firstName: "Vārds",
    lastName: "Uzvārds",
    businessName: "Uzņēmuma nosaukums",
    previous: "Iepriekšējais",
    next: "Nākamais",
    pleaseSelectBusinessCategory: "Lūdzu, izvēlieties sava uzņēmuma kategoriju",
    pleaseEnterValidEmail: "Lūdzu, ievadiet derīgu e -pasta adresi",
    pleaseEnterPostCode: "Lūdzu, ievadiet pasta indeksu",
    postCode: "Pasta indekss",
    phoneNumberInUse: "Šis tālruņa numurs jau tiek izmantots!",
    emailInUse: "Šis e -pasts jau tiek izmantots!",
    foodAndDrinks: "Ēdiens un dzērieni",
    salonAndBeauty: "Salons un skaistums",
    fashionAndAccessories: "Mode un aksesuāri",
    gymAndFitness: "Trenažieru zāle un Fitness",
    travelAndHotel: "Ceļojumi un viesnīca",
    homeAndGifts: "Mājas un dāvanas",
    washingAndCleaning: "Mazgāšana un tīrīšana",
    gadgetsAndElectronics: "Sīkrīki un elektronika",
    groceries: "Pārtikas preces",
    others: "Citi",
    submit: "Iesniegt",
    accountCreatedSuccessful: "Jūsu konts tika veiksmīgi izveidots.",
    pleaseEnterAddress: "Lūdzu, ievadiet savu adresi",
    addressLine1: "Adreses 1. rindiņa",
    addressLine2: "Adreses 2. rindiņa",
    choosePassword: "Izvēlēties paroli",
    passwordMustBe6Characters: "Parolei jābūt vismaz 6 rakstzīmēm.",
    howDidYouHearLoystar: "Kā jūs uzzinājāt par Loystar?",
    referralCode: "novirzīšanas kods",
    byClickingTheButton: " Noklikšķinot uz tālāk esošās pogas, jūs piekrītat",
    termsAndSevice: "Noteikumi",
    wereCreatingAccount: "Mēs izveidojam jūsu kontu",
    proceed: "Turpiniet",
    verificationCodeMustBe6: "Verifikācijas kodam ir jābūt 6 cipariem",
    pleaseEnter6DigitCode: "Lūdzu, ievadiet 6 ciparu kodu",
    enterVerificationCode: "Ievadiet apstiprinājuma kodu",
    resendToken: "Atkārtoti nosūtīt marķieri",
    verify: "Pārbaudīt",
    transactions: "Darījumi",
    todaySales: "Šodienas pārdošana",
    salesHistory: "Pārdošanas vēsture",
    supplyHistory: "Piegādes vēsture",
    new: "Jauns",
    invoices: "Rēķini",
    disbursements: "Izmaksas",
    offlineSales: "Pārdošana bezsaistē",
    products: "Produkti",
    customers: "Klienti",
    multiLevelLoyalty: "Daudzlīmeņu lojalitāte",
    loyaltyPrograms: "Lojalitātes programmas",
    members: "Locekļi",
    appStore: "Aplikāciju veikals",
    orderMenu: "Pasūtījumu izvēlne",
    settings: "Iestatījumi",
    staffAndBranches: "Personāls un filiāles",
    myAccount: "Mans Konts",
    switchToSellMode: "Pārslēdzieties uz pārdošanas režīmu",
    signOut: "Izrakstīties",
    getFreeSubscription: "Iegūstiet bezmaksas abonementu",
    onlyNumbersAllowed: "Ir atļauti tikai skaitļi",
    yourAccountMustBe10Digits: "jūsu konta numuram ir jābūt 10 cipariem",
    yourBvnMustBe11: "jūsu BVN ir jābūt 11 ciparu skaitlim",
    pleaseSelectBank: "Lūdzu, izvēlieties savu banku",
    selectYourBank: "Izvēlieties savu banku",
    enterBankAccountNumber: "Ievadiet bankas konta numuru",
    enterBvnNumber: "Ievadiet savu BVN",
    connectBankAccount: "Pievienojiet bankas kontu",
    passwordResetSuccessfulAndSignOut:
      "Jūsu parole ir sekmīgi atiestatīta. Lai pierakstītos vēlreiz, noklikšķiniet uz tālāk esošās pogas Izrakstīties",
    enterCurrentPassword: "Ievadiet pašreizējo paroli",
    pleaseEnterCurrentPassword: "Lūdzu, ievadiet pašreizējo paroli",
    phoneNumber: "Telefona numurs",
    sex: "Sekss",
    dateOfBirth: "Dzimšanas datums",
    state: "Valsts",
    country: "Valsts",
    loyaltyId: "Lojalitātes ID",
    createdAt: "Izveidots plkst",
    noOfTransactionsMade: "Veikto darījumu skaits",
    yourDownloadWillStart: "Lejupielāde sāksies pēc brīža",
    exportCustomers: "Eksportēt klientus",
    youhaveSuccessfullyToppedUp:
      "Jūs esat veiksmīgi papildinājis savas SMS vienības.",
    youNowHave: "Tagad jums ir",
    smsUnits: "SMS vienības",
    enterNumberOfSmsUnits:
      "Ievadiet to SMS vienību skaitu, kuras plānojat iegādāties",
    pleaseEnterANumericValue: "Lūdzu, ievadiet skaitlisku vērtību",
    pay: "Maksāt",
    accountEditedSuccessfully: "Jūsu konts tika veiksmīgi rediģēts.",
    youAeCurrentlyOn: "Jūs pašlaik ieslēdzat",
    plan: "Plāns",
    userData: "Lietotāja dati",
    businessData: "BIZNESA DATI",
    businessCategory: "Biznesa kategorija",
    pleaseSelectCurrency: "Lūdzu, izvēlieties savu valūtu",
    selectYourCurrency: "Izvēlieties savu valūtu",
    purchaseMoreSmsUnits:
      "Iegādājieties vairāk SMS vienību, izmantojot zemāk esošo veidlapu",
    youHave: "Tev ir",
    atLeast4SmsUnitsRrequired:
      "Lai pārbaudītu, ir nepieciešamas vismaz 4 sms vienības, lūdzu, papildiniet!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Lūdzu, pārbaudiet savu bankas kontu, lai tas atbilstu “Pazīsti savu klientu” (KYC) prasībām. Tas ļaus jums savākt maksājumus, izmantojot USSD vai tūlītēju pārskaitījumu, saņemt pasūtījumus no klientiem un apstrādāt darījumu norēķinus. Attiecas tikai uz Nigērijas tirgotājiem. Lai sāktu, lūdzu, nospiediet zemāk esošo pogu.",
    reConnectBankAccount: "Atkārtoti savienojiet bankas kontu",
    accountName: "Konta vārds",
    accountNumber: "Konta numurs",
    bankName: "Bankas nosaukums",
    verified: "Verificēts",
    accountDetails: "konta informācija",
    kycBankAccount: "KYC",
    createTier: "Izveidot līmeni",
    fileUploadSuccessful: "fails ir veiksmīgi augšupielādēts",
    fileUploadFailed: "faila augšupielāde neizdevās",
    createLoyaltyProgram: "Izveidojiet lojalitātes programmu",
    createLoyalty: "Izveidojiet lojalitāti",
    name: "Vārds",
    loyaltyArtwork: "Lojalitātes mākslas darbs",
    clickToUpload: "Noklikšķiniet, lai augšupielādētu",
    amountToPointsRatio: "Summas un punktu attiecība",
    points: "Punkti",
    recommendedAmountToPointRatio:
      "Ieteicams: ₦ 1 līdz 1 punkts. ti, par katru iztērēto 1 Naira jūsu klienti saņem 1 punktu",
    pleaseTypeIn: "Lūdzu, ierakstiet",
    toDeleteLoyalty: "lai izdzēstu šo lojalitāti",
    deleteLoyalty: "Lojalitātes dzēšana",
    toConfirm: "apstiprināt",
    edit: "Rediģēt",
    pointsAwardedSuccessfully: "Punkti piešķirti veiksmīgi.",
    enterPointValueToAward:
      "Ievadiet punktu vērtību, kuru vēlaties piešķirt klientiem",
    award: "Balva",
    awardPointValuesToCustomer: "Piešķiriet punktu vērtību klientam",
    enrollMembersToLoyalty: "Reģistrējiet dalībniekus lojalitātei",
    awardPoints: "Apbalvošanas punkti",
    enroll: "Reģistrēties",
    home: "Mājas",
    loyalty: "Lojalitāte",
    enrollCustomers: "Reģistrējiet klientus",
    selected: "Atlasīts",
    customer: "Klients",
    loyaltyActivationSuccessful: "Lojalitātes aktivizēšana ir veiksmīga.",
    loyaltyDeactivationSuccessful: "Lojalitātes deaktivizēšana ir veiksmīga.",
    viewTier: "Skatīt līmeni",
    deactivate: "Deaktivizēt",
    activate: "Aktivizēt",
    actions: "Darbības",
    nameOfLoyalty: "Lojalitātes nosaukums",
    loyaltyStatus: "Lojalitātes statuss",
    numberOfTiers: "Līmeņu skaits",
    createdOn: "Izveidots",
    createATier: "Izveidojiet līmeni",
    stopCreatingTierConfirmation: "Vai vēlaties pārtraukt līmeņa izveidi?",
    stopEditingTierConfirmation: "Vai vēlaties pārtraukt šī līmeņa rediģēšanu?",
    nameOfTier: "Līmeņa nosaukums",
    minimumSpendingTarget: "Minimālais izdevumu mērķis",
    maximumSpendingTarget: "Maksimālais izdevumu mērķis",
    minimumSpendingTargetRequired: "ir nepieciešams minimālais izdevumu mērķis",
    maximumSpendingTargetRequired: "ir noteikts maksimālais izdevumu mērķis",
    rewardSponsor: "Atlīdzības sponsors",
    pleaseChooseARewardSponsor: "Lūdzu, izvēlieties atlīdzības sponsoru",
    self: "Es",
    partner: "Partneris",
    rewardPartner: "Atlīdzības partneris",
    pleaseSelectRewardPartner: "Lūdzu, izvēlieties savu atlīdzības partneri",
    rewards: "Atlīdzības",
    pleaseSelectAReward: "Lūdzu, izvēlieties atlīdzību",
    instructionsOnRedeemingReward:
      "Norādījumi par to, kā klientam jāizpērk atlīdzība",
    pleaseFillInThisField: "Lūdzu, aizpildiet šo lauku!",
    toDeleteThisTier: " lai dzēstu šo līmeni",
    deleteTier: "Dzēst līmeni",
    viewMembers: "Skatīt dalībniekus",
    membersEnrolled: "Reģistrētie dalībnieki",
    instruction: "Instrukcija",
    membersIn: "Dalībnieki",
    availableTiersInLoyalty: "Pieejams līmenis (-i) lojalitātes programmā",
    tiers: "Līmeņi",
    totalTier: "KOPĀ LĪDZEKĻS",
    availableLoyaltyProgramme: "Pieejama lojalitātes programma",
    totalLoyalties: "KOPĀ LOJALITĀTE",
    memberDetails: "Dalībnieka informācija",
    nameOfCustomer: "Klienta vārds",
    address: "Adrese",
    allEnrolledMembers: "Visi reģistrētie dalībnieki",
    thisIsToWishYouHappyBirthday:
      "Tas ir novēlēt jums daudz laimes dzimšanas dienā un pārtikušu dzīvi. Paldies par visu, kas esat Loyster. Priecīgus svētkus!",
    inputAnOfferPlease: "Lūdzu, ievadiet piedāvājumu",
    pleaseSelectTheInsertPoint:
      "Lūdzu, atlasiet ievietošanas punktu ziņojumā un noklikšķiniet vēlreiz",
    birthdayOfferAndMessage: "Dzimšanas dienas piedāvājums un ziņa",
    birthdayOffer: "Dzimšanas dienas piedāvājums",
    birthdayMessage: "Dzimšanas dienas ziņa",
    noOfferFound: "Netika atrasts neviens piedāvājums",
    settingABirthdayOffer:
      "Iestatot dzimšanas dienas piedāvājumu, klienti dzimšanas dienā var saņemt šo piedāvājumu, izmantojot SMS",
    createOffer: "Izveidot piedāvājumu",
    whatIsTheOffer: "Kāds ir piedāvājums?",
    editMessage: "Rediģēt ziņojumu",
    insert: "Ievietot",
    theNameOfCustomerInserted: "Šeit tiks ievietots klienta vārds",
    theBirtdayOfferInserted: "Dzimšanas dienas piedāvājums tiks ievietots šeit",
    youSuccessfullyAddedNewBranch: "Jūs veiksmīgi pievienojāt jaunu filiāli!",
    addNewBranch: "Pievienot jaunu filiāli",
    addBranch: "Pievienot filiāli",
    additionalBranchWillIncur: "Tiks radīta papildu filiāle",
    perBranch: "par filiāli",
    ecommerceBranchCosts: "E -komercijas filiāles izmaksas",
    pleaseEnterBranchName: "Lūdzu, ievadiet filiāles nosaukumu",
    pleaseEnterBranchAddress1: "Lūdzu, ievadiet filiāles adreses rindu 1",
    enterBranchAddress1: "Ievadiet filiāles adreses rindu 1",
    enterBranchAddress2: "Ievadiet filiāles adreses rindu 1",
    pleaseEnterBranchAddress2: "Lūdzu, ievadiet filiāles adreses 2. rindiņu",
    enterBranchName: "Ievadiet filiāles nosaukumu",
    successfullyAddedStaff: "Jūs veiksmīgi pievienojāt jaunu personālu!",
    addNewStaff: "Pievienot jaunus darbiniekus",
    addStaff: "Pievienot darbiniekus",
    additionalStaffWillIncur: "Papildu personāls būtu jāmaksā",
    perStaff: "uz vienu personālu.",
    pleaseEnterStaffEmail: "Lūdzu, ievadiet personāla e -pastu",
    pleaseEnterStaffUsername: "Lūdzu, ievadiet personāla lietotājvārdu",
    pleaseEnterStaffPassword: "Lūdzu, ievadiet personāla paroli",
    pleaseSelectStaffRole: "Lūdzu, izvēlieties personāla lomu",
    selectStaffRole: "Izvēlieties personāla lomu",
    enterStaffEmail: "Ievadiet personāla e -pastu",
    enterStaffUsername: "Ievadiet personāla lietotājvārdu",
    enterStaffPassword: "Ievadiet personāla paroli",
    spacesNotAllowedInUsername: "lietotājvārdā nav atļautas atstarpes",
    admin: "Administrators",
    pleaseSelectBusinessToAttachStaff:
      "Lūdzu, izvēlieties uzņēmumu, kuram piesaistīt darbiniekus",
    searchForBranchToAttachStaff:
      "Meklējiet filiāli, lai piesaistītu darbiniekus",
    username: "Lietotājvārds",
    role: "Loma",
    areYouSureToDeleteThis: "Vai tiešām vēlaties to izdzēst",
    branches: "Filiāles",
    upgradeYourPlan: "Jauniniet savu plānu.",
    add: "PIEVIENOT",
    addNew: "Pievieno jaunu",
    customerWithEmailAlreadyExists:
      "Klients ar e -pastu/tālruņa numuru jau pastāv!",
    successfullyAddedNewCustomer: "Jūs veiksmīgi pievienojāt jaunu klientu!",
    addCustomer: "Pievienot klientu",
    pleaseEnterCustomerFirstName: "Lūdzu, ievadiet klienta vārdu",
    pleaseEnterCustomerLastName: "Lūdzu, ievadiet klienta uzvārdu",
    pleaseEnterCustomerPhoneNumber: "Lūdzu, ievadiet klienta tālruņa numuru",
    pleaseEnterCustomerEmail: "Lūdzu, ievadiet klienta e -pastu",
    pleaseEnterCustomerAddressLine: "Lūdzu, ievadiet klienta adreses rindiņu",
    pleaseEnterCustomerOtherAddress: "Lūdzu, ievadiet citu klienta adresi",
    pleaseSelectCustomerGender: "Lūdzu, izvēlieties klienta dzimumu",
    gender: "Dzimums",
    male: "Vīrietis",
    female: "Sieviete",
    bank: "Banka",
    selectBank: "Izvēlieties Banka",
    stateOrRegionOrProvince: "Valsts/reģions/province",
    customerNotes: "Klienta piezīmes",
    sendSms: "Sūtīt SMS",
    editCustomer: "Rediģēt klientu",
    redeemReward: "Izpirkt atlīdzību",
    issueLoyaltyCard: "Izsniedziet lojalitātes karti",
    deleteCustomer: "Dzēst klientu",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Jūs esat veiksmīgi piešķīris lojalitātes dalības ID",
    noMembershipIdAvailable:
      "Nav pieejami dalības ID. Lūdzu, sazinieties ar mums pa e -pastu hello@loystar.co",
    sendEmail: "Sūtīt e-pastu",
    membershipPoints: "Dalības punkti",
    customerDetails: "Informācija par klientu",
    close: "Aizvērt",
    loyaltyBalance: "Lojalitātes bilance",
    pointsBalance: "Punktu līdzsvars",
    starBalance: "Zvaigžņu līdzsvars",
    requiredPoints: "Nepieciešamie punkti",
    requiredStars: "Nepieciešamās zvaigznes",
    reddemCode: "Izpirkt kodu",
    toDeleteThisCustomer: "lai dzēstu šo klientu",
    customerHasBeenDeletedSuccessfully: "Klients ir veiksmīgi izdzēsts!",
    customerWithPhoneAlreadyExists: "Klients ar tālruņa numuru jau pastāv",
    customerWasSuccessfullyEdited: "Klients tika veiksmīgi rediģēts",
    anErrorOccured: "Radās kļūda",
    phoneNumberIncludeCountry: "Tālruņa numurs (iekļaujiet valsts kodu)",
    yourFileQueuedForUpload:
      "Jūsu fails ir ievietots augšupielādes rindā. Lūdzu, atsvaidziniet lapu pēc dažām sekundēm.",
    thereWasAnErrorPerformingOperation: "Veicot darbību, radās kļūda!",
    pleaseSelectFile: "Lūdzu, izvēlieties failu!",
    oopsOnlyCsvFilesAllowed:
      "Hmm! Atļauti tikai CSV faili. Lūdzu, augšupielādējiet .csv failu.",
    fileShouldBeSmallerThan5Mb:
      "Failam jābūt mazākam par 5 MB. Ja jums ir lielāks fails, lūdzu, nosūtiet e -pastu uz support@loystar.co. Paldies",
    customerFirstNameIsRequired: "rindā ir jānorāda klienta vārds",
    customerPhoneNumberIsRequired: "rindā ir jānorāda klienta tālruņa numurs",
    importCustomers: "Importēt klientus",
    upload: "Augšupielādēt",
    clickIconToDownloadCsv:
      "Noklikšķiniet uz šīs ikonas, lai lejupielādētu CSV faila veidni.",
    getGoogleSheetVersion: "Iegūstiet Google lapas versiju",
    clickOrDragFileToUpload:
      "Lai augšupielādētu, noklikšķiniet vai velciet failu uz šo apgabalu",
    missingOrInvalidColumnHeader:
      "Trūkst slejas galvenes vai tā nav derīga. Lūdzu, ievērojiet veidnes formātu. Paldies.",
    youHaveImported: "Jūs esat importējis",
    youSuccessfullyRedeemedReward:
      "Jūs esat veiksmīgi izmantojis savu atlīdzību!",
    sixDigitCode: "Sešu ciparu kods",
    pleaseEnterCustomerRewardCode: "Lūdzu, ievadiet klienta atlīdzības kodu",
    enterRewardCode:
      "Ievadiet atlīdzības kodu. (Atalgojuma kods ir reģistrjutīgs)",
    selectLoyaltyProgram: "Izvēlieties lojalitātes programmu",
    stamps: "Zīmogi",
    smsUnitsLow: "SMS vienību skaits ir zems",
    pleaseTopUpSmsUnits: "Lūdzu, papildiniet SMS vienības",
    smsSuccessfullySentForDelivery: "SMS veiksmīgi nosūtīts piegādei!",
    sendSmsTo: "Sūtīt SMS uz",
    allCustomers: "Visi klienti",
    unitsAvailable: "Pieejamās vienības",
    pleaseTypeInTheMessage: "Lūdzu, ierakstiet ziņojumu",
    message: "Ziņa",
    charactersRemaining: "atlikušās rakstzīmes",
    avoidUseOfSpecialCharacters:
      "Izvairieties no īpašo rakstzīmju un emocijzīmju izmantošanas, lai saglabātu SMS vienības.",
    note: "Piezīme",
    errorFetchingCustomersMultilevelDetail:
      "Ienesot klientu daudzlīmeņu informāciju, radās kļūda",
    search: "Meklēt",
    reset: "Atiestatīt",
    importCustomer: "Importēt klientu",
    addNewCustomer: "Pievienot jaunu klientu",
    sendSmsBroadcast: "Sūtīt SMS apraidi",
    totalCustomers: "KOPĀ KLIENTI",
    disbursementDetails: "Sīkāka informācija par izmaksām",
    paidBy: "Apmaksāja",
    disbursed: "Izmaksāts",
    bankAccountName: "Bankas konta nosaukums",
    bankAccountNumber: "Bankas konta numurs",
    transferInitiated: "Pāreja uzsākta",
    transferCompleted: "Pārsūtīšana pabeigta",
    pleaseEnterAValid: "Lūdzu, ievadiet derīgu",
    begin: "sākt",
    end: "beigas",
    date: "datums",
    paymentDate: "Maksājuma datums",
    selectDisbursementDuration: "Atlasiet Izmaksas ilgums",
    totalSettled: "Kopā nokārtots",
    totalUnsettled: "Kopā nenokārtots",
    toDeleteThisSale: "lai dzēstu šo izpārdošanu",
    draftSaleDeletedSuccessfully:
      "Pārdošanas melnraksts ir veiksmīgi izdzēsts!",
    deleteSale: "Izdzēst izpārdošanu",
    pleaseTypeInYourTotal:
      "Lūdzu, ierakstiet savu kopsummu, lai apstiprinātu dzēšanu",
    billDetails: "Rēķina detaļas",
    printBill: "Drukāt Bill",
    servedBy: "Pasniedz",
    total: "Kopā",
    createdDate: "Izveidošanas datums",
    createdTime: "Radītais laiks",
    status: "Statuss",
    loginSuccessful: "Pieteikšanās veiksmīga",
    pleaseWaitWhileWeConnectAccount:
      "Lūdzu, uzgaidiet, kamēr mēs savienosim jūsu kontu",
    connectionFailedTryAgain: "Savienojums neizdevās. Lūdzu mēģiniet vēlreiz.",
    connectionSuccessful: "Savienojums ir veiksmīgs",
    viewDetails: "Skatīt detaļas",
    enable: "Iespējot",
    free: "Bezmaksas",
    cost: "Izmaksas",
    visitWebsite: "Apmeklējiet vietni",
    pleaseUpgradeYourPlanToPro:
      "Lūdzu, jauniniet savu plānu uz Pro Plus, lai iespējotu šo lietotni",
    connectYourBankAccount:
      "Pievienojiet savu bankas kontu, lai varētu saņemt maksājumus.",
    disable: "Atspējot",
    enableApp: "Iespējot lietotni",
    addNewProductToInvoice: "Pievienot rēķinam jaunu produktu",
    toDeleteThisInvoice: "lai izdzēstu šo rēķinu",
    invoiceDeletedSuccessfully: "Rēķins ir veiksmīgi izdzēsts!",
    deleteInvoice: "Rēķina dzēšana",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Jūs esat veiksmīgi pievienojis rēķina apmaksas ziņojumu!",
    pleaseEnterPaymentInstructions: "Lūdzu, ievadiet maksājuma norādījumus",
    invoiceId: "Rēķina ID",
    paidDate: "Apmaksāts datums",
    reference: "Atsauce",
    productAdded: "Produkts pievienots",
    productOutOfStock: "Produkts nav pieejams. Lūdzu, papildiniet krājumus.",
    totalInvoices: "KOPĀ Rēķini",
    totalPaidInvoices: "KOPĀ APMAKSĀTIE RĒĶINI",
    totalUnpaidInvoices: "KOPĀ NEMAKSĀTIE RĒĶINI",
    loyaltyProgramDeleted: "Lojalitātes programma ir izdzēsta",
    thisLoyaltyProgramDeletedSuccessfully:
      "Šī lojalitātes programma ir veiksmīgi izdzēsta",
    thisLoyaltyProgramEditedSuccessfully:
      "Šī lojalitātes programma ir veiksmīgi rediģēta",
    loyaltyProgramAddedSuccessfully:
      "Lojalitātes programma ir veiksmīgi pievienota",
    loyaltyProgramEdited: "Lojalitātes programma rediģēta",
    loyaltyProgramAdded: "Pievienota lojalitātes programma",
    loyaltyDetails: "Lojalitātes informācija",
    doYouWantToCloseDialog: "Vai vēlaties aizvērt šo dialoglodziņu?",
    pleaseEnterLoyaltyName: "Lūdzu, ievadiet savas lojalitātes vārdu",
    programType: "Programmas veids",
    pleaseSelectProgramType: "Lūdzu, izvēlieties programmas veidu",
    simplePoints: "Vienkārši punkti",
    stampsProgram: "Pastmarku programma",
    threshold: "Slieksnis",
    pleaseEnterLoyaltyThreshold: "Lūdzu, ievadiet lojalitātes slieksni",
    reward: "Atlīdzība",
    pleaseEnterLoyaltyReward: "Lūdzu, ievadiet atlīdzību par lojalitāti",
    totalUserPoints: "Lietotāja punkti kopā",
    totalUserStamps: "Lietotāja zīmogu kopskaits",
    spendingTarget: "Izdevumu mērķis",
    spendingTargetHint1:
      "Izdevumu mērķis ir tas, cik klientam ir jātērē, lai nopelnītu atlīdzību. 1 valūtas vērtība = 1 punkts.",
    spendingTargetHint2:
      "Pastmarku mērķis ir, cik zīmogu klientam ir jāsavāc, lai nopelnītu atlīdzību. piem. 5",
    addNewLoyaltyProgram: "Pievienot jaunu lojalitātes programmu",
    addLoyaltyProgram: "Pievienojiet lojalitātes programmu",
    loyaltyProgramType: "Lojalitātes programmas veids",
    pleaseSelectLoyaltyProgramType:
      "Lūdzu, izvēlieties lojalitātes programmas veidu",
    nameOfProgram: "Programmas nosaukums",
    pleaseEnterProgramName: "Lūdzu, ievadiet programmas nosaukumu",
    whatIsTheReward: "Kāda ir atlīdzība?",
    egNextPurchaseIsFree: "Piem. Nākamais pirkums ir bez maksas",
    customerName: "Klienta vārds",
    guestCustomer: "Viesu klients",
    orderConfirmedSuccessfully: "Pasūtījums veiksmīgi apstiprināts",
    orderCancelledSuccessfully: "Pasūtījums atcelts veiksmīgi",
    confirmOrder: "Apstipriniet pasūtījumu",
    cancelOrder: "Atcelt pasūtījumu",
    allOrders: "Visi pasūtījumi",
    totalOrders: "KOPĀ PASŪTĪJUMI",
    doYouWantToAcceptOrder: "Vai vēlaties pieņemt šo pasūtījumu?",
    doYouWantToCancelOrder: "Vai vēlaties atcelt šo pasūtījumu?",
    orderDetails: "pasūtījuma detaļas",
    orderCreatedAt: "Pasūtījums izveidots plkst",
    supplier: "Piegādātājs",
    productName: "produkta nosaukums",
    quantity: "Daudzums",
    unitPrice: "Vienības cena",
    receivedBy: "Saņēmis",
    reportFrom: "Ziņot no",
    totalSupplies: "Piegādes kopā",
    allRightsReserved: "Visas tiesības aizsargātas",
    toDeleteThisTransaction: "lai dzēstu šo darījumu",
    transactionDeletedSuccessfully:
      "Darījums ir veiksmīgi izdzēsts. Krājumi ir atgriezti krājumos.",
    deleteTransaction: "Dzēst darījumu",
    transactionDetails: "Informācija par darījumu",
    printReceipt: "Drukas kvīts",
    channel: "Kanāls",
    discount: "Atlaide",
    profit: "Peļņa",
    discountedSales: "Pārdošana ar atlaidi",
    errorFetchingRecord: "Ienesot ierakstu, radās kļūda",
    exportTransactions: "Eksporta darījumi",
    errorFetchingSalesRecord:
      "Ienesot pārdošanas ierakstu eksportam, radās kļūda.",
    totalSellingPrice: "Kopējā pārdošanas cena",
    totalCostPrice: "Kopējās izmaksas",
    appliedDiscount: "Piemērotā atlaide",
    noOfItems: "Vienumu skaits",
    sales: "Pārdošana",
    export: "Eksportēt",
    totalProfit: "Kopējā peļņa",
    totalBalanceInPeriod: "Kopējais atlikums periodā",
    allTimeSales: "Visu laiku pārdošana",
    records: "Ieraksti",
    todaysSales: "Šodienas pārdošana",
    transaction: "darījumu",
    youHaveExceededTotalNumberOfProducts:
      "Jūs esat pārsniedzis jūsu plānā atļauto produktu kopskaitu. Jauniniet savu plānu, lai izbaudītu augstāku limitu.",
    youNeedToHaveLoyaltyProgram:
      "Lai izmantotu šo funkciju, jums ir nepieciešama lojalitātes programma!",
    price: "Cena",
    category: "Kategorija",
    stockTracking: "Krājumu izsekošana",
    stockCount: "Krājumu skaits",
    taxed: "Nodoklis",
    originalPrice: "Sākotnējā cena",
    costPrice: "Izmaksas Cena",
    unit: "Vienība",
    productImage: "Produkta attēls",
    taxRate: "Nodokļa likme",
    taxType: "Nodokļu veids",
    trackInventory: "Izsekot inventāru",
    variants: "Varianti",
    hasVariants: "Ir varianti",
    importProduct: "Importēt produktu",
    exportProducts: "Eksportēt produktus",
    addNewProduct: "Pievienot jaunu produktu",
    viewCategory: "Skatīt kategoriju",
    viewSuppliers: "Skatīt piegādātājus",
    receiveInventory: "Saņemt inventāru",
    printAllProductsTag: "Drukāt visu produktu tagu",
    deleteAll: "Dzēst visus",
    totalProducts: "KOPĀ PRODUKTI",
    youveSuccessfullyAddedANewCategory:
      "Jūs esat veiksmīgi pievienojis jaunu kategoriju",
    addNewCategory: "Pievienot jaunu kategoriju",
    addCategory: "Pievienot kategoriju",
    categoryName: "Kategorijas nosaukums",
    pleaseEnterCategoryName: "Lūdzu, ievadiet kategorijas nosaukumu",
    stampsTarget: "Zīmogu mērķis",
    sendInventory: "Sūtīt inventāru",
    productDetails: "Produkta informācija",
    youveSuccessfullyEditedThisCategory:
      "Jūs esat veiksmīgi rediģējis šo kategoriju",
    update: "Atjaunināt",
    categoryList: "Kategoriju saraksts",
    categories: "Kategorijas",
    enterQuantityToUpdate: "Ievadiet atjaunināmo daudzumu",
    inventorySentSuccessfully: "Inventārs tika veiksmīgi nosūtīts!",
    updateInventory: "Atjaunināt inventāru",
    currentQuantity: "Pašreizējais daudzums",
    pleaseEnterQuantityToAdd:
      "Lūdzu, ievadiet daudzumu, kuru vēlaties pievienot",
    pleaseSelectABranch: "Lūdzu, izvēlieties filiāli",
    searchForBranch: "Meklēt filiāli",
    youCantSendMoreThanStock:
      "Jūs nevarat nosūtīt vairāk, nekā jums ir noliktavā",
    send: "Sūtīt",
    pleaseEnterQuantityToSend: "Lūdzu, ievadiet sūtāmo daudzumu",
    productNameIsRequiredOnRow: "rindā ir jānorāda produkta nosaukums",
    productCategoryIsRequiredOnRow: "rindā ir jānorāda produkta kategorija",
    productPriceIsRequiredOnRow: "rindā ir jānorāda produkta cena",
    productUnitIsRequiredOnRow: "rindā ir jānorāda produkta vienība",
    productQuantityIsRequiredOnRow: "rindā ir jānorāda produkta daudzums",
    productVariantsRequireTracking:
      "produktu variantiem nepieciešama izsekošana!",
    pleaseAddVariantClickButton:
      "Lūdzu, pievienojiet variantu, noklikšķinot uz pogas Pievienot!",
    totalVariantsMoreThanSelectedQuantity:
      "Kopējie varianti ir vairāk nekā izvēlētais produkta daudzums, Pls samazina variantu daudzumu",
    productEditedSuccessfully: "Produkts ir veiksmīgi rediģēts!",
    fileTooLargeLessThan4Mb:
      "Faila izmērs ir pārāk liels! Faila lielumam jābūt mazākam par 4 MB.",
    suchVariantAlreadyExist: "Šāds variants jau pastāv",
    addVariants: "Pievienot variantus",
    editProduct: "Rediģēt produktu",
    pleaseEnterProductName: "Lūdzu, ievadiet produkta nosaukumu",
    pleaseEnterProductPrice: "Lūdzu, ievadiet produkta cenu",
    pleaseEnterProductOriginalPrice: "Lūdzu, ievadiet produkta sākotnējo cenu",
    productDescription: "Produkta apraksts",
    selectProductCategory: "Atlasiet Produkta kategorija",
    pleaseSelectProductCategory: "Lūdzu, izvēlieties produktu kategoriju",
    productCostPrice: "Produkta izmaksas Cena",
    productSellingPrice: "Produkta pārdošanas cena",
    productOriginalPrice: "Produkta sākotnējā cena",
    maxFileSizeAllowedIs4Mb: "Maks. Atļautais faila lielums ir 4 MB",
    productsWithPicturesArePublished:
      "Produkti ar attēliem tiek publicēti vietnē Discover, lai saņemtu pasūtījumus",
    shouldThisProductBeTracked: "Vai šis produkts ir jāseko?",
    pleaseSelectStockUnit: "Lūdzu, izvēlieties akciju vienību",
    stockUnit: "Akciju vienība",
    bag: "SOMA",
    bottle: "PUDELE",
    bunch: "BUNCH",
    can: "VAR",
    carton: "KARTONS",
    crate: "KRĀTS",
    cup: "KAUSS",
    dozen: "DUZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAMMA",
    litre: "LITER",
    pack: "KOMPLEKTS",
    pair: "PĀRĪ",
    pieces: "GABALI",
    plate: "PLĀKSNE",
    tonne: "TONS (MT)",
    uNIT: "VIENĪBA",
    yard: "Pagalms",
    pleaseEnterProductQuantity: "Lūdzu, ievadiet produkta daudzumu",
    productQuantity: "Produkta daudzums",
    isThisProductTaxed: "Vai šis produkts tiek aplikts ar nodokli?",
    selectTaxType: "Atlasiet Nodokļa veids",
    pleaseSelectTaxType: "Lūdzu, atlasiet nodokļu veidu",
    progressive: "Progresīvs",
    proportional: "Proporcionāls",
    pleaseEnterProductTaxRate: "Lūdzu, ievadiet produkta nodokļa likmi",
    doesProductHaveVariants: "Vai šim produktam ir varianti?",
    type: "Tips",
    value: "Vērtība",
    pleaseEnterVariantType: "Lūdzu, ievadiet varianta veidu",
    pleaseEnterVariantValue: "Lūdzu, ievadiet varianta vērtību",
    pleaseEnterVariantPrice: "Lūdzu, ievadiet varianta cenu",
    pleaseEnterVariantQuantity: "Lūdzu, ievadiet varianta daudzumu",
    productDeletedSuccessfully: "Produkts ir veiksmīgi izdzēsts!",
    categoryDeletedSuccessfully: "Kategorija ir veiksmīgi izdzēsta!",
    toDeleteThisProduct: "lai dzēstu šo produktu",
    invalidProductQuantity: "Nederīgs produkta daudzums",
    quantityAddedIsOverStock: "Pievienotais daudzums pārsniedz krājumā esošo.",
    itemInventoryNotTracked: "Vienumu krājumi netiek izsekoti",
    addBulkQuantity: "Pievienot lielapjoma daudzumu",
    enterBulkQuantity: "Ievadiet lielapjoma daudzumu",
    pleaseEnterBulkQuantity: "Lūdzu, ievadiet lielapjoma daudzumu",
    youveSuccessfullyAddedANewProduct:
      "Jūs esat veiksmīgi pievienojis jaunu produktu!",
    pleaseEnterProductSellingPrice: "Lūdzu, ievadiet produkta pārdošanas cenu",
    doYouWantToTrackProductStock: "Vai vēlaties izsekot produktu krājumiem?",
    sellingPrice: "Pārdošanas cena",
    setProductExpiryReminder:
      "Iestatiet atgādinājumu par produkta derīguma termiņu",
    productExpiryDate: "Produkta derīguma termiņš",
    startRemindingFrom: "Sāciet atgādināt no",
    productSuppliesAddedSuccessfully:
      "Jūs esat veiksmīgi pievienojis produktu krājumus.",
    addProductSupplies: "Pievienojiet produktu piederumus",
    pleaseSelectSupplier: "Lūdzu, izvēlieties piegādātāju",
    nameOfProduct: "Produkta nosaukums",
    pleaseSelectProduct: "Lūdzu, izvēlieties produktu",
    productVariant: "Produkta variants",
    pleaseSelectAVariant: "Lūdzu, izvēlieties variantu",
    pleaseEnterUnitPrice: "Lūdzu, ievadiet vienības cenu",
    businessBranch: "Biznesa filiāle",
    pleaseSelectBranch: "Lūdzu, izvēlieties filiāli",
    youveSuccessfullyAddedANewSupplier:
      "Jūs esat veiksmīgi pievienojis jaunu piegādātāju",
    addSupplier: "Pievienot piegādātāju",
    pleaseEnterSupplierEmail: "Lūdzu, ievadiet piegādātāja e -pastu",
    pleaseAddADescription: "Lūdzu, pievienojiet aprakstu",
    anErrorOccuredProductsDeleted:
      "Veicot darbību, radās kļūda. Lūdzu, ņemiet vērā, ka daži produkti, iespējams, tika izdzēsti",
    bulkDelete: "Lielapjoma dzēšana",
    youAreAboutToDelete: "Jūs gatavojaties dzēst",
    product: "Produkts",
    isDueToRedeem: "ir jāizpērk",
    aReward: "atlīdzība",
    pleaseSelectCustomerToReeemReward:
      "Lūdzu, atlasiet klientu, lai izpirktu atlīdzību.",
    youHaveNoLoyaltyProgramRunning: "Jums nav aktīvas lojalitātes programmas",
    customerHhasNoPointsInLoyaltyProgram:
      "Šajā lojalitātes programmā klientam nav punktu",
    proceedWithPayment: "Vai turpināt maksājumu?",
    youAreAboutToPayForTransactionUsingPoints:
      "Jūs gatavojaties maksāt par darījumu, izmantojot punktus.",
    customerHas: "Klientam ir",
    worth: "vērts",
    andIsNotSufficientToPayForTransaction:
      "un ar to nepietiek, lai samaksātu par šo darījumu. Vai viņi vēlas pievienot atlikumu, izmantojot citu maksājuma veidu?",
    addCustomerLoyalty: "Pievienojiet klientu lojalitāti",
    pleaseAddCustomerFirst:
      "Lūdzu, vispirms pievienojiet vai atlasiet klientu.",
    pleaseWaitWhileWeGetReady: "Lūdzu, uzgaidiet, kamēr gatavojamies",
    lowStock: "Zems krājums",
    pleaseEnterAmountTendered: "Lūdzu, ievadiet piedāvāto summu",
    areYouSureToBookSaleOffline:
      "Vai tiešām vēlaties rezervēt šo izpārdošanu bezsaistē?",
    saleWouldBeBookedAutomatically:
      "Pārdošana tiks rezervēta automātiski, ieslēdzot internetu",
    offlineSalesBookingCancelled:
      "Bezsaistes pārdošanas rezervācija ir atcelta",
    covid19Message:
      "COVID19: nomazgājiet rokas ar ziepēm vai dezinficējiet, lai apturētu izplatīšanos. Vienmēr esiet drošībā",
    saleSuccessfullyRecorded: "Izpārdošana veiksmīgi ierakstīta!",
    sendReceiptToEmail: "Nosūtiet kvīti uz e -pastu",
    sendThankYouSms: "Nosūtiet paldies SMS",
    sendShippingDetails: "Nosūtiet informāciju par piegādi",
    addLoyalty: "Pievienojiet lojalitāti",
    searchCustomerNameOrNumber: "Meklējiet klienta vārdu vai numuru",
    clickTheSearchCustomerBox:
      "Noklikšķiniet uz Meklēt klientu lodziņu un Skenēt karti",
    enterProductPrice: "Ievadiet produkta cenu",
    enterPriceFor: "Ievadiet cenu par",
    searchForProduct: "Meklēt produktu",
    all: "Visi",
    backToDashboard: "Atpakaļ uz informācijas paneli",
    viewDraftSales: "Skatīt pārdošanas melnrakstu",
    variantSelected: "variants izvēlēts",
    variant: "variants",
    thePreviousVariantSelectionNotAvailable:
      "Iepriekšējā varianta izvēle nav pieejama jaunajam variantam, kas izvēlēts, pamatojoties uz cenu, lūdzu, mainiet savu izvēli.",
    pleaseSupplyPositiveQuantityNumber: "Lūdzu, norādiet pasta daudzumu",
    lowStockFor: "Zems krājums priekš",
    clearVariants: "Skaidri varianti",
    pleaseEnterQuantity: "Lūdzu, ievadiet daudzumu",
    picture: "Bilde",
    redemptionToken: "Izpirkšanas žetons",
    token: "Žetons",
    totalSpent: "Kopā iztērēts",
    confirmPayment: "Apstipriniet maksājumu",
    hasPaymentBeenMade: "Vai maksājums ir veiksmīgi apstrādāts?",
    enterTransactionReference: "Ievadiet darījuma atsauci, ar kuru samaksājāt",
    pleaseSelectACustomer: "Lūdzu, izvēlieties klientu!",
    areYouSureToApplyDiscount: "Vai tiešām vēlaties piemērot atlaidi?",
    addYourBankAccountToEnableUssd:
      "Pievienojiet savu bankas kontu, lai iespējotu tūlītēju USSD pārskaitījumu, izmantojot uPay",
    totalAmountToPay: "Kopējā samaksājamā summa",
    doYouWantToCancelTransaction: "Vai vēlaties atcelt šo darījumu?",
    savePrintBill: "Saglabāt/izdrukāt rēķinu",
    enterAmountCollectedForCustomer: "Ievadiet klientam iekasēto summu",
    recordSale: "Ierakstu izpārdošana",
    checkOutWith: "Pārbaudiet ar",
    instantTransfer: "Tūlītēja pārsūtīšana",
    dialTheUSSDCode: "Ievadiet USSD kodu",
    pleaseSelectABank: "Lūdzu, izvēlieties banku",
    payWithUSSD: "Maksājiet ar USSD",
    sendBillTo: " - Nosūtiet Bilu uz",
    waitingForUSSDTransfer: "Gaida USSD pārsūtīšanu",
    percent: "Procenti",
    applyDiscount: "Piemērot atlaidi",
    thisBillHasBeenSaved: "Šis likumprojekts ir saglabāts",
    saveDraft: "Saglabāt melnrakstu",
    pleaseTypeANameToIdentifyCustomer:
      "Lūdzu, ierakstiet vārdu, lai identificētu klientu",
    paymentDetails: "Maksājumu informācija",
    basePrice: "Bāzes cena",
    staff: "Personāls",
    subTotal: "Starpsumma",
    durationMonths: "Ilgums (mēneši)",
    selectADuration: "Izvēlieties ilgumu",
    oneMonth: "1 mēnesis",
    twoMonths: "2 mēneši",
    threeMonths: "3 mēneši",
    sixMonths: "6 mēneši",
    twelveMonths: "12 mēneši",
    eighteenMonths: "18 mēneši",
    twentyFourMonths: "24 mēneši",
    twoMonthsFree: "(2 mēneši bez maksas)",
    threeMonthsFree: "(3 mēneši bez maksas)",
    fiveMonthsFree: "(5 mēneši bez maksas)",
    yourAccountHasBeen: "Jūsu konts ir bijis",
    renewed: "atjaunots",
    upgraded: "uzlabots",
    successfully: "veiksmīgi",
    yourSubscription: "Jūsu abonements",
    youAreCurrentlyEnrolledOnA: "Jūs pašlaik esat reģistrējies a",
    pleaseChooseAPaymentOption: "Lūdzu, izvēlieties maksājuma iespēju",
    planRenewal: "Plāno atjaunošanu",
    planUpgrade: "Plānot jaunināšanu",
    pleaseSelectDurationToPayFor:
      "Lūdzu, atlasiet ilgumu, par kuru plānojat maksāt",
    staffAccounts: "Personāla konti",
    ecommerce: "E -komercija",
    pleaseSelectAPlan: "Lūdzu, izvēlieties plānu",
    includeAddons: "Iekļaut papildinājumus",
    viewTransactions: "Skatīt darījumus",
    customerHasNoCompletedTansactions:
      "Klientam vēl nav pabeigtas miecēšanas darbības",
    branch: "Filiāle",
    enterNumberOfEcommerceBranches: "Ievadiet e -komercijas filiāļu skaitu",
    enterNumberOfEcommerceBranchesToPay:
      "Ievadiet to e -komercijas filiāļu skaitu, par kurām plānojat maksāt",
    ecommerceIntegration: "E -komercijas integrācija",
    enterNumberOfBranches: "Ievadiet filiāļu skaitu",
    enterNumberOfAdditionalBranchesToPay:
      "Ievadiet papildu filiāļu skaitu, par kurām plānojat maksāt",
    enterNumberOfStaffs: "Ievadiet personāla skaitu",
    enterNumberOfStaffsToPayFor:
      "Ievadiet darbinieku skaitu, par kuru plānojat maksāt",
    discountApplies: "Piemērota atlaide",
    starsOnThe: "zvaigznes uz",
    offer: "piedāvājums",
    get: "gūt",
    moreStarsToRedeem: "vairāk zvaigžņu, ko izpirkt",
    taxVat: "Nodoklis (PVN)",
    callCashierToCompletePayment: "Zvaniet kasierim, lai pabeigtu maksājumu",
    receipt: "Kvīts",
    dear: "dārgs",
    thankYouForYourOrderFindGoods:
      "Paldies par jūsu pasutījumu. Lūdzu, atrodiet šādas piegādātās preces, kā vienojāties.",
    shippingNote: "Piegādes piezīme",
    enterShippingNote: "Ievadiet piegādes piezīmi",
    shippingAddress: "Piegādes adrese",
    enterShippingAddress: "Ievadiet piegādes adresi",
    wellDoneYouAreDueToRedeem: "Labi padarīts! Jums ir jāizpērk",
    toGetYourRewardNextVisit:
      "lai saņemtu atlīdzību nākamajā apmeklējumā. Paldies",
    pointsOnThe: "Punkti uz",
    morePointsToRedeem: "vairāk punktu, lai izpirktu",
    showCode: "Rādīt kodu",
    toGetYourRewardOnNextVisit:
      "lai saņemtu atlīdzību nākamajā apmeklējumā. Paldies",
    earn: "Nopelnīt",
    delivaryNote: "Piegādes piezīme",
    draftSales: "Pārdošanas projekts",
    startDate: "Sākuma datums",
    endDate: "Beigu datums",
    orders: "Pasūtījumi",
    checkout: "izrakstīties",
    noProductItem: "Nav preces",
    selectProductImage: "Atlasiet produkta attēlu",
    selectCountry: "Izvēlies valsti",
    selectRegion: "Atlasiet valsti/reģionu",
    printProductTag: "Drukāt produkta tagu",
    transactionReference: "Darījuma atsauce",
    Cashier: "Kasiere",
    Manager: "Vadītājs",
    Owner: "Īpašnieks",
    Admin: "Administrators",
    addPartners: "Pievienojiet partnerus",
    addNewLoyaltyPartner: "Pievienojiet jaunu lojalitātes partneri",
    pleaseEnterCompanyName: "Lūdzu, ievadiet uzņēmuma nosaukumu",
    companyName: "Kompānijas nosaukums",
    pleaseEnterCompanyRepName: "Lūdzu, ievadiet uzņēmuma pārstāvja vārdu",
    companyRepName: "Uzņēmuma pārstāvja vārds",
    pleaseEnterCompanyRepEmail: "Lūdzu, ievadiet uzņēmuma pārstāvja e-pastu",
    companyRepEmail: "Uzņēmuma pārstāvja e-pasts",
    pleaseEnterCompanyRepPhone:
      "Lūdzu, ievadiet uzņēmuma pārstāvja tālruņa numuru",
    companyRepPhone: "Uzņēmuma pārstāvja tālruņa numurs",
    addReward: "Pievienojiet atlīdzību",
    pleaseEnterRewardName: "Lūdzu, ievadiet balvas nosaukumu",
    rewardName: "Atlīdzības nosaukums",
    rewardQuantity: "Atlīdzības daudzums",
    rewardDescription: "Atlīdzības apraksts",
    rewardType: "Atlīdzības veids",
    pleaseEnterRewardType: "Lūdzu, ievadiet atlīdzības veidu",
    pleaseEnterRewardQuantity: "Lūdzu, ievadiet atlīdzības daudzumu",
    pleaseEnterRewardDescription: "Lūdzu, ievadiet atlīdzības aprakstu",
    fineDining: "Smalkas pusdienas",
    luxuryFashion: "Luksusa mode",
    hotels: "Viesnīcas",
    travel: "Ceļot",
    foodAndBeverage: "Pārtikas un dzērienu",
    fashion: "Mode",
    health: "Veselība",
    furniture: "Mēbeles",
    entertainment: "Izklaide",
    automobile: "Automašīna",
    education: "Izglītība",
    beautyAndSpa: "Skaistums un spa",
    staycation: "Uzturēšanās",
    events: "Pasākumi",
    trips: "Ceļojumi",
    oilAndGas: "Eļļa un gāze",
    laundry: "Veļas mazgāšana",
    partnerCategory: "Partneru kategorija",
    freeItem: "Bezmaksas prece",
  },
  Macedonian: {
    cashier: "касиерка",
    manager: "менаџер",
    owner: "сопственик",
    online: "онлајн",
    offline: "офлајн",
    changePassword: "Промени го пасвордот",
    currentPasswordMessage: "Ве молиме внесете ја вашата тековна лозинка",
    passwordMessage: "Ве молиме внесете ја вашата лозинка",
    currentPassword: "Сегашна лозинка",
    password: "лозинка",
    confirmPasswordMessage: "Ве молиме потврдете ја вашата лозинка!",
    confirmPassword: "Потврди ја лозинката",
    sendViaEmail: "Испрати преку е -пошта",
    sendViaWhatsapp: "Испратете преку WhatsApp",
    downloadPdf: "Преземете PDF",
    paid: "платени",
    unpaid: "неплатен",
    partial: "делумно",
    closeInvoice: "Дали сакате да ја затворите фактурата?",
    closeInvoiceConfirmation:
      "Фактурата може да не се зачува. Дали сакате да се затворите?",
    yes: "да",
    no: "бр",
    invoice: "Фактура",
    wasDeducted: "беше одземен",
    for: "за",
    item: "Ставка",
    addProduct: "Додадете производ",
    paymentReference: "Референца за плаќање",
    amountPaid: "Износот платен",
    discountAmount: "Попуст Износ",
    amountDue: "Износот што го должи",
    amount: "износ",
    dueDate: "Рок на достасување",
    paymentType: "Начин на плаќање",
    card: "Картичка",
    cash: "Парични средства",
    bankTransfer: "Банкарски трансфер",
    paymentMessage: "Порака за плаќање",
    description: "Опис",
    sendReceipt: "Испрати потврда",
    delete: "Избриши",
    save: "Зачувај",
    resend: "Повторно испрати",
    saveAndSend: "Зачувај",
    invoiceSaved: "Фактурата е зачувана!",
    selectPaymentMethod: "Ве молиме изберете начин на плаќање!",
    selectCustomer: "Ве молиме изберете клиент!",
    cartEmptyError:
      "Листата со кошнички не може да биде празна, затворете ја фактурата и додадете ставка во кошничка!",
    subscriptionExpired:
      "Вашата претплата истече и вашата сметка сега е ограничена. Кликнете на копчето подолу за да ја обновите вашата сметка",
    renew: "Обнови",
    holdOn: "Почекај",
    customerBank: "Клиентска банка",
    cancel: "Откажи",
    selectACustomer: "Изберете Клиент",
    invoiceSuccessfulPdfError:
      "Фактурата е успешно креирана, но генерацијата PDF трае подолго од вообичаеното. Ве молиме проверете наскоро.",
    downloadingInvoice: "Се презема фактура",
    downloadingReceipt: "Преземање потврда",
    whatsappReceiptError:
      "Настана грешка при испраќањето на потврдата преку WhatsApp. Обидете се повторно.",
    receiptToWhatsapp: "Потврдата е испратена до WhatsApp",
    thankYouForPatronage: "Ви благодариме за покровителството",
    hereIsYourReceipt: "Еве ја вашата потврда за плаќање",
    errorSendingEmailReceipt:
      "Настана грешка при испраќањето на потврдата преку е-пошта, обидете се повторно или контактирајте со поддршката",
    receiptSentToEmail: "Потврдата е испратена на е -пошта на клиентот",
    invoiceSentToEmail: "Фактурата е испратена на е -пошта на клиентот",
    invoiceSuccessWhatsappError:
      "Фактурата е успешно креирана, но се појави грешка при испраќање на WhatsApp. Обидете се повторно на списокот со фактури",
    invoiceSuccessfulEmailError:
      "Фактурата е успешно креирана, но се појави грешка при испраќање како е-пошта. Обидете се повторно од списокот со фактури",
    invoiceSentToWhatsapp: "Фактурата е проследена до WhatsApp",
    hello: "Здраво",
    pleaseDownloadInvoice: "Ве молиме преземете фактура",
    pleaseDownloadReceipt: "Ве молиме преземете ја сметката",
    from: "од",
    email: "Е -пошта",
    upgrade: "Надградба",
    youAreOnFreePlan: "Вие сте на бесплатен план.",
    clickOn: "Кликнете на",
    yourPlanInFewSteps: " вашата претплата во неколку брзи чекори.",
    to: "до",
    yourSubscriptionHasExpired:
      "Вашата претплата истече и вашата сметка сега е ограничена.",
    days: "денови",
    yourSubscriptionExpiresIn: "Вашата претплата за Loystar истекува во",
    createAcount: "Создадете сметка",
    signIn: "Најави се",
    continue: "Продолжете",
    enterOtp: "Внесете OTP PIN",
    enterValidOtp: "Внесете важечки PIN",
    pin: "ПИН",
    tokenSentToMail: "Знак е испратен на вашата е -пошта",
    passwordResetSuccessful: "Ресетирањето на лозинката беше успешно",
    somethingWentWrong: "Нешто тргна наопаку!",
    resetPassword: "Ресетирање на Лозинка",
    iHaveResetCode: "Имам код за ресетирање лозинка",
    pleaseEnterEmail: "Ве молиме внесете ја вашата е -пошта",
    aTokenWillBeSentToEmail: "Знак ќе биде испратен на вашата е -пошта",
    enterEmail: "Внесете ја вашата е -пошта",
    sendinYourToken: "Се испраќа вашиот знак ...",
    makeSureItMatchesPassword: "Проверете дали одговара на вашата нова лозинка",
    pleaseChooseNewPassword: "Ве молиме изберете нова лозинка",
    chooseNewPassword: "Изберете нова лозинка",
    enterNewPassword: "Внесете ја вашата нова лозинка за да потврдите",
    enterTokenSent: "Внесете го токенот што ви е испратен пошта",
    resetToken: "Ресетирај токен",
    resettingPassword: "Се ресетира лозинката ...",
    signUp: "Пријавете се",
    adminSignInWithEmail:
      " Администраторот се најавува со е -пошта додека персоналот се најавува со корисничко име.",
    pleaseEnterEmailOrUsername:
      "Ве молиме внесете ја вашата е -пошта или корисничко име",
    emailOrUsername: "Е-пошта или корисничко име",
    pleaseEnterPassword: "Ве молиме внесете лозинка",
    createAnAccount: "Создадете сметка",
    forgotPassword: "Ја заборави лозинката?",
    enterPhoneNumber: "Внесете телефонски број",
    personalData: "Лични податоци",
    validateConfirmationCOde: "Потврдете го кодот за потврда",
    pleaseEnterFirstName: "Ве молиме внесете го вашето име",
    pleaseEnterPhoneNumber: "Ве молиме внесете го вашиот телефонски број",
    pleaseEnterLastName: "Ве молиме внесете го вашето презиме",
    pleaseEnterBusinessName: "Внесете го името на вашиот бизнис",
    firstName: "Име",
    lastName: "Презиме",
    businessName: "Име на бизнис",
    previous: "Претходна",
    next: "Следно",
    pleaseSelectBusinessCategory:
      "Ве молиме, изберете ја категоријата на вашиот бизнис",
    pleaseEnterValidEmail: "Ве молиме внесете валидна е -пошта",
    pleaseEnterPostCode: "Ве молиме внесете го поштенскиот број",
    postCode: "Поштенски код",
    phoneNumberInUse: "Овој телефонски број веќе се користи!",
    emailInUse: "Оваа е -пошта веќе се користи!",
    foodAndDrinks: "Храна и пијалоци",
    salonAndBeauty: "Салон и убавина",
    fashionAndAccessories: "Мода и додатоци",
    gymAndFitness: "Фитнес и фитнес",
    travelAndHotel: "Патување и хотел",
    homeAndGifts: "Дом и подароци",
    washingAndCleaning: "Перење и чистење",
    gadgetsAndElectronics: "Гаџети и електроника",
    groceries: "Намирници",
    others: "Други",
    submit: "Поднеси",
    accountCreatedSuccessful: "Вашата сметка е успешно креирана.",
    pleaseEnterAddress: "Ве молиме внесете ја вашата адреса",
    addressLine1: "Адреса Линија 1",
    addressLine2: "Адреса Линија 2",
    choosePassword: "Изберете лозинка",
    passwordMustBe6Characters: "Лозинката мора да содржи најмалку 6 знаци.",
    howDidYouHearLoystar: "Како слушнавте за Лојстар?",
    referralCode: "референтен код",
    byClickingTheButton: " Со кликнување на копчето подолу, се согласувате со",
    termsAndSevice: "Услови",
    wereCreatingAccount: "Ние ја креираме вашата сметка",
    proceed: "Продолжи",
    verificationCodeMustBe6: "Кодот за верификација мора да има 6 цифри",
    pleaseEnter6DigitCode: "Ве молиме внесете 6 -цифрен код",
    enterVerificationCode: "Внесете го кодот за верификација",
    resendToken: "Повторно испрати токен",
    verify: "Потврди",
    transactions: "Трансакции",
    todaySales: "Денешната продажба",
    salesHistory: "Историја на продажба",
    supplyHistory: "Историја на снабдување",
    new: "Ново",
    invoices: "Фактури",
    disbursements: "Исплатата",
    offlineSales: "Офлајн продажба",
    products: "Производи",
    customers: "Клиенти",
    multiLevelLoyalty: "Лојалност на повеќе нивоа",
    loyaltyPrograms: "Програми за лојалност",
    members: "Членови",
    appStore: "Продавница за Апликации",
    orderMenu: "Мени за нарачки",
    settings: "Подесувања",
    staffAndBranches: "Вработени и филијали",
    myAccount: "Мојот акаунт",
    switchToSellMode: "Префрлете се на режим на продажба",
    signOut: "Одјави се",
    getFreeSubscription: "Добијте бесплатна претплата",
    onlyNumbersAllowed: "Дозволени се само бројки",
    yourAccountMustBe10Digits:
      "бројот на вашата сметка мора да биде 10 -цифрен број",
    yourBvnMustBe11: "вашиот BVN мора да биде 11 -цифрен број",
    pleaseSelectBank: "Ве молиме изберете ја вашата банка",
    selectYourBank: "Изберете ја вашата банка",
    enterBankAccountNumber: "Внесете број на банкарска сметка",
    enterBvnNumber: "Внесете го вашиот BVN",
    connectBankAccount: "Поврзете банкарска сметка",
    passwordResetSuccessfulAndSignOut:
      "Вашата лозинка е успешно ресетирана. Кликнете на копчето за одјавување подолу за повторно да се најавите",
    enterCurrentPassword: "Внесете ја тековната лозинка",
    pleaseEnterCurrentPassword: "Ве молиме внесете ја тековната лозинка",
    phoneNumber: "Телефонски број",
    sex: "Секс",
    dateOfBirth: "Дата на раѓање",
    state: "Држава",
    country: "Земја",
    loyaltyId: "ИД на лојалност",
    createdAt: "Создадено во",
    noOfTransactionsMade: "Број на извршени трансакции",
    yourDownloadWillStart: "Преземањето ќе започне за момент",
    exportCustomers: "Извоз на клиенти",
    youhaveSuccessfullyToppedUp: "Успешно ги надополнивте вашите СМС -единици.",
    youNowHave: "Сега имате",
    smsUnits: "СМС Единици",
    enterNumberOfSmsUnits:
      "Внесете број на СМС -единици што имате намера да ги купите",
    pleaseEnterANumericValue: "Ве молиме внесете нумеричка вредност",
    pay: "Плати",
    accountEditedSuccessfully: "Вашата сметка е успешно изменета.",
    youAeCurrentlyOn: "Моментално сте вклучени",
    plan: "План",
    userData: "Податоци за корисникот",
    businessData: "БИЗНИС ПОДАТОЦИ",
    businessCategory: "Бизнис Катеригорија",
    pleaseSelectCurrency: "Ве молиме изберете ја вашата валута",
    selectYourCurrency: "Изберете ја вашата валута",
    purchaseMoreSmsUnits:
      "Купете повеќе СМС -единици користејќи ја формата подолу",
    youHave: "Имаш",
    atLeast4SmsUnitsRrequired:
      "Потребни се најмалку 4 единици за смс за верификација, пополнете!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ве молиме, потврдете ја вашата банкарска сметка за да се усогласат со барањата „Знај го својот клиент“ (KYC). Ова ќе ви овозможи да собирате плаќања преку USSD или моментален трансфер, да примате нарачки од клиенти и да обработувате трансакција. Се применува само за нигериските трговци. Ве молиме притиснете го копчето подолу за да започнете.",
    reConnectBankAccount: "Поврзете повторно банкарска сметка",
    accountName: "Име на сметка",
    accountNumber: "Број на сметка",
    bankName: "Име на банка",
    verified: "Потврдено",
    accountDetails: "детали за сметка",
    kycBankAccount: "KYC",
    createTier: "Создадете ниво",
    fileUploadSuccessful: "датотеката е успешно поставена",
    fileUploadFailed: "прикачувањето на датотеката не успеа",
    createLoyaltyProgram: "Создадете програма за лојалност",
    createLoyalty: "Создадете лојалност",
    name: "Име",
    loyaltyArtwork: "Уметничко дело за лојалност",
    clickToUpload: "Кликнете за да испратите",
    amountToPointsRatio: "Сооднос на износ на поени",
    points: "Поени",
    recommendedAmountToPointRatio:
      "Препорачано: ₦ 1 до 1 поен. односно за секоја потрошена 1 Наира, вашите клиенти добиваат 1 поен",
    pleaseTypeIn: "Ве молиме внесете",
    toDeleteLoyalty: "да ја избришете оваа лојалност",
    deleteLoyalty: "Избриши ја лојалноста",
    toConfirm: "да се потврди",
    edit: "Уредување",
    pointsAwardedSuccessfully: "Поени доделени успешно.",
    enterPointValueToAward:
      "Внесете ја вредноста на точката што сакате да ја доделите на клиентите",
    award: "Награда",
    awardPointValuesToCustomer: "Вредност на наградата за купувачи",
    enrollMembersToLoyalty: "Запишете членови на лојалност",
    awardPoints: "Поени за доделување",
    enroll: "Запишете се",
    home: "Дома",
    loyalty: "Лојалност",
    enrollCustomers: "Запишете клиенти",
    selected: "Избрано",
    customer: "Клиент",
    loyaltyActivationSuccessful: "Активирањето за лојалност е успешно.",
    loyaltyDeactivationSuccessful: "Деактивирање на лојалност е успешна.",
    viewTier: "Преглед на ниво",
    deactivate: "Деактивирај",
    activate: "Активирај",
    actions: "Дејства",
    nameOfLoyalty: "Име на лојалност",
    loyaltyStatus: "Статус на лојалностСтатус на лојалност",
    numberOfTiers: "Број на нивоа",
    createdOn: "Создадено на",
    createATier: "Создадете ниво",
    stopCreatingTierConfirmation:
      "Дали сакате да престанете да создавате ниво?",
    stopEditingTierConfirmation:
      "Дали сакате да престанете да го уредувате ова ниво?",
    nameOfTier: "Име на ниво",
    minimumSpendingTarget: "Цел за минимално трошење",
    maximumSpendingTarget: "Цел за максимално трошење",
    minimumSpendingTargetRequired: "се бара минимална цел за трошење",
    maximumSpendingTargetRequired: "се бара максимална цел за трошење",
    rewardSponsor: "Спонзор за награда",
    pleaseChooseARewardSponsor: "Ве молиме изберете спонзор за награда",
    self: "Себе",
    partner: "Партнер",
    rewardPartner: "Награден партнер",
    pleaseSelectRewardPartner:
      "Ве молиме изберете го вашиот партнер за награда",
    rewards: "Награди",
    pleaseSelectAReward: "Ве молиме изберете награда",
    instructionsOnRedeemingReward:
      "Инструкции за тоа како клиентот треба да откупи награда",
    pleaseFillInThisField: "Ве молиме пополнете го ова поле!",
    toDeleteThisTier: " да го избришете ова ниво",
    deleteTier: "Избриши ниво",
    viewMembers: "Прегледајте ги членовите",
    membersEnrolled: "Запишани членови",
    instruction: "Инструкција",
    membersIn: "Членовите во",
    availableTiersInLoyalty: "Достапно ниво (и) во програмата за лојалност",
    tiers: "Нивоа",
    totalTier: "Вкупно ниво",
    availableLoyaltyProgramme: "Достапна програма за лојалност",
    totalLoyalties: "ВКУПНИ ВЕРВИ",
    memberDetails: "Детали за член",
    nameOfCustomer: "Име на клиентот",
    address: "Адреса",
    allEnrolledMembers: "Сите запишани членови",
    thisIsToWishYouHappyBirthday:
      "Ова ви посакува многу среќен роденден и просперитетен живот. Ви благодариме за сето она што сте на Лојстер. Среќни прослави!",
    inputAnOfferPlease: "Ве молиме, внесете понуда",
    pleaseSelectTheInsertPoint:
      "Ве молиме изберете точка за вметнување во пораката и кликнете повторно",
    birthdayOfferAndMessage: "Роденденска понуда и порака",
    birthdayOffer: "Роденденска понуда",
    birthdayMessage: "Роденденска порака",
    noOfferFound: "Не е пронајдена понуда",
    settingABirthdayOffer:
      "Поставување роденденска понуда им овозможува на клиентите да ја добијат оваа понуда преку СМС порака на нивниот роденден",
    createOffer: "Создадете понуда",
    whatIsTheOffer: "Која е понудата?",
    editMessage: "Уреди порака",
    insert: "Вметнете",
    theNameOfCustomerInserted: "Името на клиентот ќе биде внесено овде",
    theBirtdayOfferInserted: "Понудата за роденден ќе биде вметната овде",
    youSuccessfullyAddedNewBranch: "Успешно додадовте нова гранка!",
    addNewBranch: "Додај нова гранка",
    addBranch: "Додај филијала",
    additionalBranchWillIncur: ".Е настане дополнителна филијала",
    perBranch: "по гранка",
    ecommerceBranchCosts: "Трошоци за филијала за трговија",
    pleaseEnterBranchName: "Ве молиме внесете го името на филијалата",
    pleaseEnterBranchAddress1:
      "Ве молиме внесете ја адресата линија 1 на филијалата",
    enterBranchAddress1: "Внесете ја линијата за адреса на филијалата 1",
    enterBranchAddress2: "Внесете ја линијата за адреса на филијалата 1",
    pleaseEnterBranchAddress2:
      "Ве молиме внесете ја адресата линија 2 на филијалата",
    enterBranchName: "Внесете го името на филијалата",
    successfullyAddedStaff: "Успешно додадовте нов персонал!",
    addNewStaff: "Додадете нов кадар",
    addStaff: "Додадете персонал",
    additionalStaffWillIncur: ".Е настане дополнителен персонал",
    perStaff: "по персонал.",
    pleaseEnterStaffEmail: "Ве молиме внесете ја е -поштата на персоналот",
    pleaseEnterStaffUsername:
      "Ве молиме внесете го корисничкото име на персоналот",
    pleaseEnterStaffPassword: "Ве молиме внесете ја лозинката на персоналот",
    pleaseSelectStaffRole: "Ве молиме изберете ја улогата на персоналот",
    selectStaffRole: "Изберете ја улогата на персоналот",
    enterStaffEmail: "Внесете ја е -поштата на персоналот",
    enterStaffUsername: "Внесете го корисничкото име на персоналот",
    enterStaffPassword: "Внесете ја лозинката на персоналот",
    spacesNotAllowedInUsername:
      "празни места не се дозволени во корисничкото име",
    admin: "Администратор",
    pleaseSelectBusinessToAttachStaff:
      "Ве молиме, изберете бизнис за прикачување персонал",
    searchForBranchToAttachStaff:
      "Пребарајте филијала за да прикачите персонал",
    username: "Корисничко име",
    role: "Улога",
    areYouSureToDeleteThis: "Дали сте сигурни дека ќе го избришете ова",
    branches: "Гранки",
    upgradeYourPlan: "Надградете го вашиот план.",
    add: "ДОДАЈ",
    addNew: "Додади ново",
    customerWithEmailAlreadyExists:
      "Клиент со е -пошта/телефонски број веќе постои!",
    successfullyAddedNewCustomer: "Успешно додадовте нов клиент!",
    addCustomer: "Додадете клиент",
    pleaseEnterCustomerFirstName: "Внесете го името на клиентот",
    pleaseEnterCustomerLastName: "Внесете го презимето на клиентот",
    pleaseEnterCustomerPhoneNumber: "Внесете го телефонскиот број на клиентот",
    pleaseEnterCustomerEmail: "Ве молиме внесете ја е -поштата на клиентот",
    pleaseEnterCustomerAddressLine: "Ве молиме внесете ја адресата на клиентот",
    pleaseEnterCustomerOtherAddress: "Внесете ја другата адреса на клиентот",
    pleaseSelectCustomerGender: "Ве молиме изберете го полот на клиентот",
    gender: "Пол",
    male: "Машки",
    female: "Женски",
    bank: "Банка",
    selectBank: "Изберете Банка",
    stateOrRegionOrProvince: "Држава/регион/провинција",
    customerNotes: "Забелешки за клиенти",
    sendSms: "Испрати СМС",
    editCustomer: "Измени клиент",
    redeemReward: "Откуп на награда",
    issueLoyaltyCard: "Издадете картичка за лојалност",
    deleteCustomer: "Избриши клиент",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Успешно доделивте ИД за членство за лојалност",
    noMembershipIdAvailable:
      "Нема достапни лични карти за членство. Ве молиме контактирајте не на hello@loystar.co",
    sendEmail: "Испрати е-маил",
    membershipPoints: "Поени за членство",
    customerDetails: "Детали за клиентите",
    close: "Затвори",
    loyaltyBalance: "Биланс на лојалност",
    pointsBalance: "Баланс на поени",
    starBalance: "Star Balance",
    requiredPoints: "Потребни поени",
    requiredStars: "Потребни везди",
    reddemCode: "Откупи код",
    toDeleteThisCustomer: "да го избришете овој клиент",
    customerHasBeenDeletedSuccessfully: "Клиентот е успешно избришан!",
    customerWithPhoneAlreadyExists: "Клиент со телефонски број веќе постои",
    customerWasSuccessfullyEdited: "Клиентот беше успешно изменет",
    anErrorOccured: "Се случи грешка",
    phoneNumberIncludeCountry: "Телефонски број (Вклучи код на земја)",
    yourFileQueuedForUpload:
      "Вашата датотека е ставена во ред за прикачување. Ве молиме освежете ја страницата по неколку секунди.",
    thereWasAnErrorPerformingOperation:
      "Настана грешка при извршување на операцијата!",
    pleaseSelectFile: "Ве молиме, изберете датотека!",
    oopsOnlyCsvFilesAllowed:
      "Упс! Дозволени се само CSV -датотеки. Поставете .csv датотека.",
    fileShouldBeSmallerThan5Mb:
      "Датотеката треба да биде помала од 5MB. Ако имате поголема датотека, испратете е -пошта на support@loystar.co. Ви благодарам",
    customerFirstNameIsRequired: "името на клиентот се бара во редот",
    customerPhoneNumberIsRequired:
      "телефонскиот број на клиентот е потребен на ред",
    importCustomers: "Увезете клиенти",
    upload: "Поставете",
    clickIconToDownloadCsv:
      "Кликнете на оваа икона за да ја преземете шаблонот за датотека CSV.",
    getGoogleSheetVersion: "Преземете ја верзијата на листот на Google",
    clickOrDragFileToUpload:
      "Кликнете или влечете датотека во оваа област за да ја прикачите",
    missingOrInvalidColumnHeader:
      "Недостасува или неважечки заглавие на колона. Ве молиме следете го форматот на шаблонот. Ви благодарам.",
    youHaveImported: "Сте увезени",
    youSuccessfullyRedeemedReward: "Успешно ја откупивте наградата!",
    sixDigitCode: "Шефцифрен код",
    pleaseEnterCustomerRewardCode: "Внесете го кодот за награда на клиентот",
    enterRewardCode:
      "Внесете код за награда. (Кодот за награда е осетлив на големи букви)",
    selectLoyaltyProgram: "Изберете програма за лојалност",
    stamps: "Печати",
    smsUnitsLow: "СМС Единици ниски",
    pleaseTopUpSmsUnits: "Пополнете СМС -единици",
    smsSuccessfullySentForDelivery:
      "СМС -пораката е успешно испратена за испорака!",
    sendSmsTo: "Испрати СМС порака до",
    allCustomers: "Сите клиенти",
    unitsAvailable: "Достапни единици",
    pleaseTypeInTheMessage: "Ве молиме внесете ја пораката",
    message: "Порака",
    charactersRemaining: "преостанатите знаци",
    avoidUseOfSpecialCharacters:
      "Избегнувајте употреба на специјални знаци и емоџи за зачувување на СМС -единици.",
    note: "Забелешка",
    errorFetchingCustomersMultilevelDetail:
      "Грешка при преземање детали за повеќе клиенти на клиенти",
    search: "Барај",
    reset: "Ресетирај",
    importCustomer: "Увези клиент",
    addNewCustomer: "Додај нов клиент",
    sendSmsBroadcast: "Испрати СМС емитување",
    totalCustomers: "ВКУПНИ КОРИСНИЦИ",
    disbursementDetails: "Детали за исплата",
    paidBy: "Платено од",
    disbursed: "Исплатени",
    bankAccountName: "Име на банкарска сметка",
    bankAccountNumber: "Жиро сметка број",
    transferInitiated: "Започна трансферот",
    transferCompleted: "Трансферот е завршен",
    pleaseEnterAValid: "Ве молиме внесете валидна",
    begin: "започне",
    end: "крај",
    date: "датум",
    paymentDate: "Датум на плаќање",
    selectDisbursementDuration: "Изберете Времетраење на исплатата",
    totalSettled: "Вкупно подмирено",
    totalUnsettled: "Тотално Нерасчистено",
    toDeleteThisSale: "да ја избришете оваа продажба",
    draftSaleDeletedSuccessfully: "Нацрт -продажбата е успешно избришана!",
    deleteSale: "Избриши ја продажбата",
    pleaseTypeInYourTotal:
      "Внесете го вашиот број за да го потврдите бришењето",
    billDetails: "Детали за сметката",
    printBill: "Печати Бил",
    servedBy: "Се служи од",
    total: "Вкупно",
    createdDate: "Создаден датум",
    createdTime: "Создадено време",
    status: "Статус",
    loginSuccessful: "Најавувањето е успешно",
    pleaseWaitWhileWeConnectAccount:
      "Ве молиме почекајте додека ја поврзуваме вашата сметка",
    connectionFailedTryAgain:
      "Поврзувањето не успеа. Ве молиме обидете се повторно.",
    connectionSuccessful: "Врската е успешна",
    viewDetails: "Погледнете детали",
    enable: "Овозможи",
    free: "Бесплатно",
    cost: "Трошоци",
    visitWebsite: "Посетете ја веб -страницата",
    pleaseUpgradeYourPlanToPro:
      "Ве молиме надградете го вашиот план на Про Плус за да ја овозможите оваа апликација",
    connectYourBankAccount:
      "Поврзете ја вашата банкарска сметка за да можете да примате плаќања.",
    disable: "Оневозможи",
    enableApp: "Овозможи апликација",
    addNewProductToInvoice: "Додадете нов производ во фактура",
    toDeleteThisInvoice: "да ја избришете оваа фактура",
    invoiceDeletedSuccessfully: "Фактурата е успешно избришана!",
    deleteInvoice: "Избриши фактура",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Успешно додадовте порака за плаќање фактура!",
    pleaseEnterPaymentInstructions: "Внесете упатства за плаќање",
    invoiceId: "ИД на фактура",
    paidDate: "Платен датум",
    reference: "Референца",
    productAdded: "Додаден производ",
    productOutOfStock:
      "Производот е надвор од залиха. Ве молиме, повторно направете акции.",
    totalInvoices: "ВКУПНИ СМЕТКИ",
    totalPaidInvoices: "ВКУПНО ПЛАТЕНИ СМЕТКИ",
    totalUnpaidInvoices: "Вкупно неплатени фактури",
    loyaltyProgramDeleted: "Програмата за лојалност е избришана",
    thisLoyaltyProgramDeletedSuccessfully:
      "Оваа програма за лојалност е успешно избришана",
    thisLoyaltyProgramEditedSuccessfully:
      "Оваа програма за лојалност е успешно изменета",
    loyaltyProgramAddedSuccessfully:
      "Програмата за лојалност е успешно додадена",
    loyaltyProgramEdited: "Програмата за лојалност е изменета",
    loyaltyProgramAdded: "Додадена е програма за лојалност",
    loyaltyDetails: "Детали за лојалност",
    doYouWantToCloseDialog: "Дали сакате да го затворите овој дијалог?",
    pleaseEnterLoyaltyName: "Ве молиме внесете го името на вашата лојалност",
    programType: "Тип на програма",
    pleaseSelectProgramType: "Ве молиме изберете тип на програма",
    simplePoints: "Едноставни точки",
    stampsProgram: "Програма за поштенски марки",
    threshold: "Праг",
    pleaseEnterLoyaltyThreshold: "Внесете го прагот на лојалност",
    reward: "Награда",
    pleaseEnterLoyaltyReward: "Ве молиме внесете ја наградата за лојалност",
    totalUserPoints: "Вкупно кориснички поени",
    totalUserStamps: "Вкупно марки за корисници",
    spendingTarget: "Цел на трошење",
    spendingTargetHint1:
      "Цел на трошење е колку клиентот мора да потроши за да ја добие наградата. 1 валутна вредност = 1 поен.",
    spendingTargetHint2:
      "Целта на поштенските марки е колку марки мора да собере клиентот за да ја добие наградата. на пр. 5",
    addNewLoyaltyProgram: "Додадете нова програма за лојалност",
    addLoyaltyProgram: "Додадете програма за лојалност",
    loyaltyProgramType: "Тип на програма за лојалност",
    pleaseSelectLoyaltyProgramType:
      "Ве молиме изберете тип на програма за лојалност",
    nameOfProgram: "Име на програмата",
    pleaseEnterProgramName: "Ве молиме внесете го името на програмата",
    whatIsTheReward: "Која е наградата?",
    egNextPurchaseIsFree: "На пр. Следното купување е бесплатно",
    customerName: "Име на клиент",
    guestCustomer: "Гостин клиент",
    orderConfirmedSuccessfully: "Нарачката е успешно потврдена",
    orderCancelledSuccessfully: "Нарачката е успешно откажана",
    confirmOrder: "Потврдете ја Нарачката",
    cancelOrder: "Откажи нарачка",
    allOrders: "Сите нарачки",
    totalOrders: "ВКУПНИ НАРАЧКИ",
    doYouWantToAcceptOrder: "Дали сакате да ја прифатите оваа нарачка?",
    doYouWantToCancelOrder: "Дали сакате да ја откажете оваа нарачка?",
    orderDetails: "детали за НАРАЧКА",
    orderCreatedAt: "Нарачка создадена во",
    supplier: "Снабдувач",
    productName: "Име на производот",
    quantity: "Квантитет",
    unitPrice: "Единечната цена",
    receivedBy: "Добиени од",
    reportFrom: "Извештај од",
    totalSupplies: "Вкупно снабдување",
    allRightsReserved: "Сите права се задржани",
    toDeleteThisTransaction: "да ја избришете оваа трансакција",
    transactionDeletedSuccessfully:
      "Трансакцијата е успешно избришана. Акциите се вратени на попис.",
    deleteTransaction: "Избриши трансакција",
    transactionDetails: "Детали за трансакцијата",
    printReceipt: "Печатење потврда",
    channel: "Канал",
    discount: "Попуст",
    profit: "Профит",
    discountedSales: "Продажба со попуст",
    errorFetchingRecord: "Грешка при преземање на записот",
    exportTransactions: "Трансакции за извоз",
    errorFetchingSalesRecord:
      "Грешка при преземање рекорд за продажба за извоз.",
    totalSellingPrice: "Вкупна продажна цена",
    totalCostPrice: "Вкупна цена на чинење",
    appliedDiscount: "Применлив попуст",
    noOfItems: "Број на предмети",
    sales: "Продажба",
    export: "Извоз",
    totalProfit: "Вкупен профит",
    totalBalanceInPeriod: "Вкупно салдо во период",
    allTimeSales: "Продажба на сите времиња",
    records: "Рекорди",
    todaysSales: "Денешната продажба",
    transaction: "трансакција",
    youHaveExceededTotalNumberOfProducts:
      "Го надминавте вкупниот број дозволени производи на вашиот план. Надградете го вашиот план за да уживате во повисока граница.",
    youNeedToHaveLoyaltyProgram:
      "Треба да имате програма за лојалност за да ја користите оваа функција!",
    price: "Цена",
    category: "Категорија",
    stockTracking: "Следење на акции",
    stockCount: "Број на акции",
    taxed: "Се оданочува",
    originalPrice: "Оригинална цена",
    costPrice: "Цена на чинење",
    unit: "Единица",
    productImage: "Слика на производот",
    taxRate: "Даночна стапка",
    taxType: "Даночен тип",
    trackInventory: "Следете инвентар",
    variants: "Варијанти",
    hasVariants: "Има варијанти",
    importProduct: "Увези производ",
    exportProducts: "Извозни производи",
    addNewProduct: "Додадете нов производ",
    viewCategory: "Прегледај категорија",
    viewSuppliers: "Погледнете ги добавувачите",
    receiveInventory: "Добијте инвентар",
    printAllProductsTag: "Отпечатете ја ознаката за сите производи",
    deleteAll: "Избриши ги сите",
    totalProducts: "ВКУПНИ ПРОИЗВОДИ",
    youveSuccessfullyAddedANewCategory: "Успешно додадовте нова категорија",
    addNewCategory: "Додај нова категорија",
    addCategory: "Додај категорија",
    categoryName: "Име на категорија",
    pleaseEnterCategoryName: "Ве молиме внесете го името на категоријата",
    stampsTarget: "Цели за поштенски марки",
    sendInventory: "Испрати инвентар",
    productDetails: "Детали за производот",
    youveSuccessfullyEditedThisCategory: "Успешно ја уредивте оваа категорија",
    update: "Ажурирај",
    categoryList: "Листа на категории",
    categories: "Категории",
    enterQuantityToUpdate: "Внесете количина за ажурирање",
    inventorySentSuccessfully: "Инвентарот е испратен успешно!",
    updateInventory: "Ажурирање на инвентарот",
    currentQuantity: "Тековна количина",
    pleaseEnterQuantityToAdd:
      "Ве молиме внесете ја количината што сакате да ја додадете",
    pleaseSelectABranch: "Ве молиме изберете филијала",
    searchForBranch: "Пребарај за филијала",
    youCantSendMoreThanStock:
      "Не можете да испратите повеќе отколку што имате на залиха",
    send: "Испрати",
    pleaseEnterQuantityToSend:
      "Ве молиме внесете ја количината што сакате да ја испратите",
    productNameIsRequiredOnRow: "името на производот е потребно на ред",
    productCategoryIsRequiredOnRow:
      "категоријата на производи е потребна по ред",
    productPriceIsRequiredOnRow: "цената на производот е потребна по ред",
    productUnitIsRequiredOnRow: "единицата за производи е потребна на ред",
    productQuantityIsRequiredOnRow:
      "количината на производот е потребна на ред",
    productVariantsRequireTracking: "варијантите на производот бараат следење!",
    pleaseAddVariantClickButton:
      "Ве молиме додадете варијанта со кликнување на копчето за додавање!",
    totalVariantsMoreThanSelectedQuantity:
      "Вкупните варијанти се повеќе од избраната количина на производ, Pls ја намалуваат количината на варијантата",
    productEditedSuccessfully: "Производот е успешно изменет!",
    fileTooLargeLessThan4Mb:
      "Големината на датотеката е премногу голема! Големината на датотеката треба да биде помала од 4MB.",
    suchVariantAlreadyExist: "Таква варијанта веќе постои",
    addVariants: "Додадете варијанти",
    editProduct: "Уреди производ",
    pleaseEnterProductName: "Ве молиме внесете го името на производот",
    pleaseEnterProductPrice: "Ве молиме внесете ја цената на производот",
    pleaseEnterProductOriginalPrice:
      "Ве молиме внесете ја оригиналната цена на производот",
    productDescription: "Опис на производот",
    selectProductCategory: "Изберете Категорија на производи",
    pleaseSelectProductCategory: "Ве молиме изберете категорија на производи",
    productCostPrice: "Цена на производот Цена",
    productSellingPrice: "Цена за продажба на производ",
    productOriginalPrice: "Оригинална цена на производот",
    maxFileSizeAllowedIs4Mb: "Макс Дозволена големина на датотека е 4 mb",
    productsWithPicturesArePublished:
      "Производите со слики се објавуваат на Discover за да примате нарачки",
    shouldThisProductBeTracked: "Дали треба да се следи овој производ?",
    pleaseSelectStockUnit: "Ве молиме изберете единица за залиха",
    stockUnit: "Единица за акции",
    bag: "БАА",
    bottle: "ШИШЕ",
    bunch: "КУП",
    can: "МОANЕ",
    carton: "ЦРТАН",
    crate: "КАРТИРАЕ",
    cup: "КУП",
    dozen: "ДОЗНАЧКА",
    gigabytes: "ГИГАБИТЕС",
    gram: "ГРАМ",
    kilogram: "КИЛОГРАМ",
    litre: "ПОСЛАВНО",
    pack: "ПАКУВАЈ",
    pair: "СПАР",
    pieces: "ПАРЦИ",
    plate: "ЧИНИЈА",
    tonne: "Тон (МТ)",
    uNIT: "ЕДИНИЦА",
    yard: "ДВОР",
    pleaseEnterProductQuantity: "Ве молиме внесете ја количината на производот",
    productQuantity: "Количина на производ",
    isThisProductTaxed: "Дали овој производ се оданочува?",
    selectTaxType: "Изберете тип на данок",
    pleaseSelectTaxType: "Ве молиме изберете Тип на данок",
    progressive: "Прогресивно",
    proportional: "Пропорционално",
    pleaseEnterProductTaxRate:
      "Ве молиме внесете ја даночната стапка на производот",
    doesProductHaveVariants: "Дали овој производ има варијанти?",
    type: "Тип",
    value: "Вредност",
    pleaseEnterVariantType: "Внесете го типот на варијантата",
    pleaseEnterVariantValue: "Внесете ја вредноста на варијантата",
    pleaseEnterVariantPrice: "Внесете ја цената на варијантата",
    pleaseEnterVariantQuantity:
      "Ве молиме внесете ја количината на варијантата",
    productDeletedSuccessfully: "Производот е успешно избришан!",
    categoryDeletedSuccessfully: "Категоријата е успешно избришана!",
    toDeleteThisProduct: "да го избришете овој Производ",
    invalidProductQuantity: "Неважечка количина на производ",
    quantityAddedIsOverStock:
      "Количината што ја додавате е над она што го имате на залиха.",
    itemInventoryNotTracked: "Инвентарот на ставка не се следи",
    addBulkQuantity: "Додадете масовно количество",
    enterBulkQuantity: "Внесете Масовно количество",
    pleaseEnterBulkQuantity: "Ве молиме внесете Масовно количество",
    youveSuccessfullyAddedANewProduct: "Успешно додадовте нов производ!",
    pleaseEnterProductSellingPrice:
      "Ве молиме внесете ја продажната цена на производот",
    doYouWantToTrackProductStock:
      "Дали сакате да ги следите залихите на производи?",
    sellingPrice: "Продажна Цена",
    setProductExpiryReminder: "Поставете потсетник за истекување на производот",
    productExpiryDate: "Датум на истекување на производот",
    startRemindingFrom: "Започнете со потсетување од",
    productSuppliesAddedSuccessfully:
      "Успешно ги додадовте залихите на производи.",
    addProductSupplies: "Додадете додатоци за производи",
    pleaseSelectSupplier: "Ве молиме изберете Добавувач",
    nameOfProduct: "Име на производот",
    pleaseSelectProduct: "Ве молиме изберете производ",
    productVariant: "Варијанта на производот",
    pleaseSelectAVariant: "Ве молиме изберете А варијанта",
    pleaseEnterUnitPrice: "Ве молиме внесете ја цената на единицата",
    businessBranch: "Деловна филијала",
    pleaseSelectBranch: "Ве молиме изберете филијала",
    youveSuccessfullyAddedANewSupplier: "Успешно додадовте нов снабдувач",
    addSupplier: "Додадете снабдувач",
    pleaseEnterSupplierEmail: "Ве молиме внесете ја е -поштата на добавувачот",
    pleaseAddADescription: "Ве молиме додадете опис",
    anErrorOccuredProductsDeleted:
      "Се појави грешка при изведување на операцијата. Ве молиме имајте предвид, неколку производи можеби се избришани во процесот",
    bulkDelete: "Масовно бришење",
    youAreAboutToDelete: "Aboutе избришете",
    product: "Производ",
    isDueToRedeem: "се должи на откуп",
    aReward: "награда",
    pleaseSelectCustomerToReeemReward:
      "Ве молиме изберете клиент за откуп на наградата.",
    youHaveNoLoyaltyProgramRunning: "Немате активна програма за лојалност",
    customerHhasNoPointsInLoyaltyProgram:
      "Клиентот нема поени во оваа програма за лојалност",
    proceedWithPayment: "Продолжи со плаќање?",
    youAreAboutToPayForTransactionUsingPoints:
      "Aboutе платите за трансакцијата користејќи поени.",
    customerHas: "Клиентот има",
    worth: "вреди",
    andIsNotSufficientToPayForTransaction:
      "и не е доволно да се плати за оваа трансакција. Дали би сакале да го додадат салдото користејќи друг начин на плаќање?",
    addCustomerLoyalty: "Додадете лојалност на клиентите",
    pleaseAddCustomerFirst: "Ве молиме, прво додадете или изберете клиент.",
    pleaseWaitWhileWeGetReady: "Ве молиме почекајте додека не се подготвиме",
    lowStock: "Ниска залиха",
    pleaseEnterAmountTendered: "Ве молиме внесете ја понудената сума",
    areYouSureToBookSaleOffline:
      "Дали сте сигурни дека сакате да ја резервирате оваа продажба офлајн?",
    saleWouldBeBookedAutomatically:
      "Продажбата ќе се резервира автоматски кога ќе го вклучите вашиот интернет",
    offlineSalesBookingCancelled: "Офлајн резервацијата за продажба е откажана",
    covid19Message:
      "COVID19: Измијте ги рацете со сапун или дезинфицирајте за да го спречите ширењето. Останете безбедни секогаш",
    saleSuccessfullyRecorded: "Продажбата е успешно снимена!",
    sendReceiptToEmail: "Испратете потврда до е -пошта",
    sendThankYouSms: "Испратете СМС -пораки за благодарност",
    sendShippingDetails: "Испрати детали за испорака",
    addLoyalty: "Додадете лојалност",
    searchCustomerNameOrNumber: "Пребарајте име или број на клиент",
    clickTheSearchCustomerBox:
      "Кликнете на полето за пребарување клиенти и картичка за скенирање",
    enterProductPrice: "Внесете ја цената на производот",
    enterPriceFor: "Внесете Цена за",
    searchForProduct: "Пребарајте производ",
    all: "Сите",
    backToDashboard: "Назад на Контролна табла",
    viewDraftSales: "Погледнете нацрт -продажба",
    variantSelected: "избрана варијанта",
    variant: "варијанта",
    thePreviousVariantSelectionNotAvailable:
      "Претходниот избор на варијанти не е достапен за новата варијанта избрана врз основа на цената, ве молиме сменете го вашиот избор.",
    pleaseSupplyPositiveQuantityNumber:
      "Ве молиме наведете број за постуална количина",
    lowStockFor: "Ниска залиха за",
    clearVariants: "Јасни варијанти",
    pleaseEnterQuantity: "Ве молиме внесете Количина",
    picture: "Слика",
    redemptionToken: "Токмент за откуп",
    token: "Токен",
    totalSpent: "Вкупно потрошено",
    confirmPayment: "Потврдете го плаќањето",
    hasPaymentBeenMade: "Дали плаќањето е успешно обработено?",
    enterTransactionReference:
      "Внесете ја референцата за трансакција со која сте платиле",
    pleaseSelectACustomer: "Ве молиме изберете клиент!",
    areYouSureToApplyDiscount:
      "Дали сте сигурни дека сакате да примените попуст?",
    addYourBankAccountToEnableUssd:
      "Додадете ја вашата банкарска сметка за да овозможите моментален трансфер на USSD преку uPay",
    totalAmountToPay: "Вкупна сума за плаќање",
    doYouWantToCancelTransaction:
      "Дали сакате да ја откажете оваа трансакција?",
    savePrintBill: "Зачувај/отпечати сметка",
    enterAmountCollectedForCustomer: "Внесете го собраниот износ за клиентот",
    recordSale: "Рекордна продажба",
    checkOutWith: "Проверете со",
    instantTransfer: "Инстант трансфер",
    dialTheUSSDCode: "Бирајте го кодот USSD",
    pleaseSelectABank: "Ве молиме изберете банка",
    payWithUSSD: "Плаќајте со USSD",
    sendBillTo: " - Испрати Бил до",
    waitingForUSSDTransfer: "Се чека трансфер на USSD",
    percent: "Процент",
    applyDiscount: "Примени попуст",
    thisBillHasBeenSaved: "Овој предлог -закон е зачуван",
    saveDraft: "Зачувај нацрт",
    pleaseTypeANameToIdentifyCustomer:
      "Внесете име за да го идентификувате клиентот",
    paymentDetails: "Детали за плаќањата",
    basePrice: "Основна цена",
    staff: "Персонал",
    subTotal: "Под вкупно",
    durationMonths: "Времетраење (месеци)",
    selectADuration: "Изберете Времетраење",
    oneMonth: "1 месец",
    twoMonths: "2 месеци",
    threeMonths: "3 месеци",
    sixMonths: "6 месеци",
    twelveMonths: "12 месеци",
    eighteenMonths: "18 месеци",
    twentyFourMonths: "24 месеци",
    twoMonthsFree: "(2 месеци бесплатно)",
    threeMonthsFree: "(3 месеци бесплатно)",
    fiveMonthsFree: "(5 месеци бесплатно)",
    yourAccountHasBeen: "Вашата сметка е",
    renewed: "обновено",
    upgraded: "надградена",
    successfully: "успешно",
    yourSubscription: "Вашата претплата",
    youAreCurrentlyEnrolledOnA: "Во моментов сте запишани на а",
    pleaseChooseAPaymentOption: "Ве молиме, изберете Опција за плаќање",
    planRenewal: "План за обновување",
    planUpgrade: "Планирајте надградба",
    pleaseSelectDurationToPayFor:
      "Ве молиме изберете Времетраење за кое имате намера да платите",
    staffAccounts: "Сметки за вработени",
    ecommerce: "Е -трговија",
    pleaseSelectAPlan: "Ве молиме изберете план",
    includeAddons: "Вклучете додатоци",
    viewTransactions: "Погледнете ги трансакциите",
    customerHasNoCompletedTansactions:
      "Клиентот с has уште нема завршено трансакции",
    branch: "Филијала",
    enterNumberOfEcommerceBranches: "Внесете број на филијали за трговија",
    enterNumberOfEcommerceBranchesToPay:
      "Внесете број на филијали за трговија за кои имате намера да платите",
    ecommerceIntegration: "Е -трговија интеграција",
    enterNumberOfBranches: "Внесете број на филијали",
    enterNumberOfAdditionalBranchesToPay:
      "Внесете го бројот на дополнителни филијали за кои имате намера да платите",
    enterNumberOfStaffs: "Внесете број на вработени",
    enterNumberOfStaffsToPayFor:
      "Внесете го бројот на вработени за кои имате намера да платите",
    discountApplies: "Се применува попуст",
    starsOnThe: "starsвезди на",
    offer: "понуда",
    get: "Земи",
    moreStarsToRedeem: "повеќе starsвезди за откуп",
    taxVat: "Данок (ДДВ)",
    callCashierToCompletePayment:
      "Повикајте касиер за да завршите со плаќањето",
    receipt: "Потврда",
    dear: "мил",
    thankYouForYourOrderFindGoods:
      "Ви благодариме за вашата нарачка. Ве молиме најдете ги следните стоки доставени, како што е договорено.",
    shippingNote: "Забелешка за испорака",
    enterShippingNote: "Внесете белешка за испорака",
    shippingAddress: "Адреса за испорака",
    enterShippingAddress: "Внесете адреса за испорака",
    wellDoneYouAreDueToRedeem: "Добро сторено! Ви претстои откуп",
    toGetYourRewardNextVisit:
      "за да ја добиете вашата награда при следната посета. Ви благодарам",
    pointsOnThe: "Поени на",
    morePointsToRedeem: "повеќе поени за откуп",
    showCode: "Прикажи код",
    toGetYourRewardOnNextVisit:
      "за да ја добиете вашата награда при следната посета. Ви благодарам",
    earn: "Заработете",
    delivaryNote: "Белешка за испорака",
    draftSales: "Нацрт продажба",
    startDate: "Почетен датум",
    endDate: "Крајна дата",
    orders: "Нарачки",
    checkout: "одјавување",
    noProductItem: "Нема ставка за производ",
    selectProductImage: "Изберете слика на производот",
    selectCountry: "Избери земја",
    selectRegion: "Изберете држава/регион",
    printProductTag: "Печатете ознака за производот",
    transactionReference: "Референца за трансакција",
    Cashier: "Касиерка",
    Manager: "Менаџер",
    Owner: "Сопственик",
    Admin: "Администратор",
    addPartners: "Додадете партнери",
    addNewLoyaltyPartner: "Додадете нов партнер за лојалност",
    pleaseEnterCompanyName: "Ве молиме внесете име на компанијата",
    companyName: "име на компанија",
    pleaseEnterCompanyRepName:
      "Ве молиме внесете Име на претставникот на компанијата",
    companyRepName: "Име на претставникот на компанијата",
    pleaseEnterCompanyRepEmail:
      "Внесете е-пошта на претставникот на компанијата",
    companyRepEmail: "Е-пошта на претставник на компанијата",
    pleaseEnterCompanyRepPhone:
      "Ве молиме внесете го телефонскиот број на претставникот на компанијата",
    companyRepPhone: "Телефонски број на претставник на компанијата",
    addReward: "Додадете награда",
    pleaseEnterRewardName: "Ве молиме внесете го името на наградата",
    rewardName: "Награда Име",
    rewardQuantity: "Количина на награда",
    rewardDescription: "Опис на наградата",
    rewardType: "Тип на награда",
    pleaseEnterRewardType: "Ве молиме внесете го типот на награда",
    pleaseEnterRewardQuantity: "Ве молиме внесете ја количината на награда",
    pleaseEnterRewardDescription: "Внесете опис на наградата",
    fineDining: "Добро јадење",
    luxuryFashion: "Луксузна мода",
    hotels: "Хотели",
    travel: "Патува",
    foodAndBeverage: "Храна и пијалаци",
    fashion: "Мода",
    health: "Здравје",
    furniture: "Мебел",
    entertainment: "Забава",
    automobile: "Автомобил",
    education: "Образование",
    beautyAndSpa: "Убавина и Спа",
    staycation: "Престој",
    events: "Настани",
    trips: "Патувања",
    oilAndGas: "Нафта и гас",
    laundry: "Перење",
    partnerCategory: "Категорија на партнери",
    freeItem: "Бесплатна ставка",
  },
  Luxembourgish: {
    cashier: "Keesseberäich",
    manager: "Manager",
    owner: "Besëtzer",
    online: "online",
    offline: "offline",
    changePassword: "Passwuert änneren",
    currentPasswordMessage: "Gitt w.e.g. Äert aktuellt Passwuert an",
    passwordMessage: "Gitt w.e.g. Äert Passwuert an",
    currentPassword: "Aktuellt Passwuert",
    password: "Passwuert",
    confirmPasswordMessage: "Bestätegt w.e.g. Äert Passwuert!",
    confirmPassword: "Bestätegt d'Passwuert",
    sendViaEmail: "Schéckt Iwwer E -Mail",
    sendViaWhatsapp: "Schéckt Via WhatsApp",
    downloadPdf: "Download PDF",
    paid: "bezuelt",
    unpaid: "onbezuelten",
    partial: "partiell",
    closeInvoice: "Wëllt Dir d'Rechnung zoumaachen?",
    closeInvoiceConfirmation:
      "Rechnung däerf net gespäichert ginn. Wëllt Dir zoumaachen?",
    yes: "Jo",
    no: "Nee",
    invoice: "Rechnung",
    wasDeducted: "ofgezu gouf",
    for: "fir",
    item: "Artikel",
    addProduct: "Produkt addéieren",
    paymentReference: "Bezuelungsreferenz",
    amountPaid: "Betrag bezuelt",
    discountAmount: "Remise Betrag",
    amountDue: "Betrag u",
    amount: "Betrag",
    dueDate: "Fällegkeetsdatum",
    paymentType: "Bezuelungsart",
    card: "Kaart",
    cash: "Cash",
    bankTransfer: "Iwwerweisung",
    paymentMessage: "Bezuelung Message",
    description: "Beschreiwung",
    sendReceipt: "Schéckt Empfang",
    delete: "Läschen",
    save: "Späicheren",
    resend: "Re-Send",
    saveAndSend: "Späicheren",
    invoiceSaved: "Rechnung gespäichert!",
    selectPaymentMethod: "Wielt w.e.g. eng Bezuelmethod!",
    selectCustomer: "Wielt w.e.g. e Client!",
    cartEmptyError:
      "Wuerekuerflëscht kann net eidel sinn, zou der Rechnung a füügt Artikel an de Weenchen!",
    subscriptionExpired:
      "Ären Abonnement ass ofgelaf an Äre Kont ass elo limitéiert. Klickt op de Knäppchen hei ënnen fir Äre Kont ze erneieren",
    renew: "Erneieren",
    holdOn: "Waart",
    customerBank: "Client Bank",
    cancel: "Ofbriechen",
    selectACustomer: "Wielt Client",
    invoiceSuccessfulPdfError:
      "Rechnung erfollegräich erstallt awer PDF Generatioun dauert méi laang wéi soss. Kuckt w.e.g. kuerz zréck.",
    downloadingInvoice: "Rechnung eroflueden",
    downloadingReceipt: "Download Empfang",
    whatsappReceiptError:
      "E Feeler ass geschitt beim Empfang iwwer WhatsApp ze schécken, probéiert et nach eng Kéier.",
    receiptToWhatsapp: "Empfang weidergeleet op WhatsApp",
    thankYouForPatronage: "Merci fir Äre Patronat",
    hereIsYourReceipt: "Hei ass Är Bezuelungsquittung",
    errorSendingEmailReceipt:
      "E Feeler ass geschitt wann de Empfang per E-Mail verschéckt gouf, probéiert et nach eng Kéier oder kontaktéiert Ënnerstëtzung",
    receiptSentToEmail: "D'Receptioun gouf op d'E -Mail vum Client geschéckt",
    invoiceSentToEmail: "Rechnung gouf op d'Client E -Mail geschéckt",
    invoiceSuccessWhatsappError:
      "Rechnung erfollegräich erstallt awer e Feeler ass geschitt beim Schécken op WhatsApp. Probéiert nach eng Kéier op der Rechnungslëscht",
    invoiceSuccessfulEmailError:
      "Rechnung erfollegräich erstallt awer e Feeler ass opgetrueden beim Schécken als E-Mail. Probéiert w.e.g. nach eng Kéier vun der Rechnungslëscht",
    invoiceSentToWhatsapp: "Rechnung weidergeleet op WhatsApp",
    hello: "Hallo",
    pleaseDownloadInvoice: "W.e.g. lued d'Rechnung erof",
    pleaseDownloadReceipt: "W.e.g. download de Empfang",
    from: "vun",
    email: "Email",
    upgrade: "Upgrade",
    youAreOnFreePlan: "Dir sidd op engem Gratis Plang.",
    clickOn: "Klickt op",
    yourPlanInFewSteps: " Ären Abonnement an e puer séiere Schrëtt.",
    to: "zu",
    yourSubscriptionHasExpired:
      "Ären Abonnement ass ofgelaf an Äre Kont ass elo limitéiert.",
    days: "Deeg",
    yourSubscriptionExpiresIn: "Äre Loystar Abonnement leeft of",
    createAcount: "En Account maachen",
    signIn: "Umellen",
    continue: "Fuert weider",
    enterOtp: "Gitt OTP PIN an",
    enterValidOtp: "Gitt w.e.g. eng valabel PIN an",
    pin: "PIN",
    tokenSentToMail: "En Token gouf op Är E -Mail geschéckt",
    passwordResetSuccessful: "Passwuert zréckgesat gouf erfollegräich",
    somethingWentWrong: "Eppes ass falsch gaang!",
    resetPassword: "Passwuert zrécksetzen",
    iHaveResetCode: "Ech hunn e Passwuert zréckgesat Code",
    pleaseEnterEmail: "Gitt w.e.g. Är E -Mail an",
    aTokenWillBeSentToEmail: "En Token géif op Är E -Mail geschéckt ginn",
    enterEmail: "Gitt Är E -Mail an",
    sendinYourToken: "Schéckt Ären Token ...",
    makeSureItMatchesPassword:
      "Gitt sécher datt et mat Ärem neie Passwuert passt",
    pleaseChooseNewPassword: "Wielt w.e.g. en neit Passwuert",
    chooseNewPassword: "Wielt en neit Passwuert",
    enterNewPassword: "Gitt Äert neit Passwuert fir ze bestätegen",
    enterTokenSent: "Gitt den Token an deen Är Mail geschéckt gouf",
    resetToken: "Reset Token",
    resettingPassword: "Äert Passwuert zréckgesat ...",
    signUp: "Umellen",
    adminSignInWithEmail:
      " Den Admin ënnerschreift sech mat E -Mail wärend d'Personal sech mam Benotzernumm umellt.",
    pleaseEnterEmailOrUsername:
      "Gitt w.e.g. Är E -Mail oder Äre Benotzernumm an",
    emailOrUsername: "E -Mail oder Benotzernumm",
    pleaseEnterPassword: "Gitt w.e.g. Äert Passwuert an",
    createAnAccount: "En Account maachen",
    forgotPassword: "Passwuert vergiess?",
    enterPhoneNumber: "Gitt Telefonsnummer an",
    personalData: "Perséinlech Daten",
    validateConfirmationCOde: "Confirmatiouns Code validéieren",
    pleaseEnterFirstName: "Gitt w.e.g. Ären Virnumm an",
    pleaseEnterPhoneNumber: "Gitt w.e.g. Är Telefonsnummer an",
    pleaseEnterLastName: "Gitt w.e.g. Äre Virnumm an",
    pleaseEnterBusinessName: "Gitt w.e.g. Äre Geschäftsnumm an",
    firstName: "Virnumm",
    lastName: "Familljennumm",
    businessName: "Business Numm",
    previous: "Virdrun",
    next: "Nächst",
    pleaseSelectBusinessCategory: "Wielt w.e.g. Är Geschäftskategorie",
    pleaseEnterValidEmail: "Gitt w.e.g. eng valabel E -Mail an",
    pleaseEnterPostCode: "Gitt w.e.g. Postcode an",
    postCode: "Postleitzuel",
    phoneNumberInUse: "Dës Telefonsnummer ass scho benotzt!",
    emailInUse: "Dës E -Mail ass scho benotzt!",
    foodAndDrinks: "Iessen an Gedrénks",
    salonAndBeauty: "Salon a Schéinheet",
    fashionAndAccessories: "Moud an Accessoiren",
    gymAndFitness: "Gym a Fitness",
    travelAndHotel: "Rees an Hotel",
    homeAndGifts: "Heem a Kaddoen",
    washingAndCleaning: "Wäschen a Botzen",
    gadgetsAndElectronics: "Gadgeten an Elektronik",
    groceries: "Epicerien",
    others: "Anerer",
    submit: "Ofginn",
    accountCreatedSuccessful: "Äre Kont gouf erfollegräich erstallt.",
    pleaseEnterAddress: "Gitt w.e.g. Är Adress an",
    addressLine1: "Adress Linn 1",
    addressLine2: "Adress Linn 2",
    choosePassword: "Wielt Passwuert",
    passwordMustBe6Characters: "D'Passwuert muss op d'mannst 6 Zeeche sinn.",
    howDidYouHearLoystar: "Wéi hutt Dir iwwer Loystar héieren?",
    referralCode: "Referral Code",
    byClickingTheButton:
      " Andeems Dir op de Knäppchen hei drënner klickt, averstanen Dir Iech mat der",
    termsAndSevice: "Konditiounen",
    wereCreatingAccount: "Mir kreéieren Äre Kont",
    proceed: "Fuert weider",
    verificationCodeMustBe6: "Verifikatiounscode muss 6 Zifferen sinn",
    pleaseEnter6DigitCode: "Gitt w.e.g. 6 Zifferen Code an",
    enterVerificationCode: "Gidd den Bestätegungscode an",
    resendToken: "Schéckt Token erëm",
    verify: "Verifizéieren",
    transactions: "Transaktiounen",
    todaySales: "Haut de Verkaf",
    salesHistory: "Sales Geschicht",
    supplyHistory: "Versuergung Geschicht",
    new: "Nei",
    invoices: "Rechnungen",
    disbursements: "Ausgaben",
    offlineSales: "Offline Verkaf",
    products: "Produkter",
    customers: "Clienten",
    multiLevelLoyalty: "Multi-Level Loyalitéit",
    loyaltyPrograms: "Loyalitéit Programmer",
    members: "Memberen",
    appStore: "App Store",
    orderMenu: "Uerdnung Menu",
    settings: "Astellungen",
    staffAndBranches: "Personal a Filialen",
    myAccount: "Mäi Kont",
    switchToSellMode: "Wiesselt op de Verkafsmodus",
    signOut: "Ofmellen",
    getFreeSubscription: "Kritt Gratis Abonnement",
    onlyNumbersAllowed: "Nëmmen Zuelen sinn erlaabt",
    yourAccountMustBe10Digits: "Är Kontosnummer muss eng 10 Ziffer Nummer sinn",
    yourBvnMustBe11: "Äre BVN muss eng 11 Ziffer Nummer sinn",
    pleaseSelectBank: "Wielt w.e.g. Är Bank",
    selectYourBank: "Wielt Är Bank",
    enterBankAccountNumber: "Gitt Bankkontosnummer an",
    enterBvnNumber: "Gitt Äre BVN an",
    connectBankAccount: "Connect de Bankkonto",
    passwordResetSuccessfulAndSignOut:
      "Äert Passwuert gouf erfollegräich zréckgesat. Klickt op den Umeldungsknop hei ënnen fir erëm z'ënnerschreiwen",
    enterCurrentPassword: "Gitt dat aktuellt Passwuert an",
    pleaseEnterCurrentPassword: "Gitt w.e.g. dat aktuellt Passwuert an",
    phoneNumber: "Telefonsnummer",
    sex: "Sex",
    dateOfBirth: "Gebuertsdatum",
    state: "Staat",
    country: "Land",
    loyaltyId: "Loyalitéit ID",
    createdAt: "Erstellt At",
    noOfTransactionsMade: "Zuel vun den Transaktiounen gemaach",
    yourDownloadWillStart: "Ären Download fänkt an engem Moment un",
    exportCustomers: "Export Clienten",
    youhaveSuccessfullyToppedUp:
      "Dir hutt Är SMS Eenheeten erfollegräich opgehalen.",
    youNowHave: "Dir hutt elo",
    smsUnits: "SMS Unitéiten",
    enterNumberOfSmsUnits:
      "Gitt d'Zuel vun SMS Eenheeten un déi Dir wëllt kafen",
    pleaseEnterANumericValue: "Gitt w.e.g. en numeresche Wäert an",
    pay: "Bezuelen",
    accountEditedSuccessfully: "Äre Kont gouf erfollegräich geännert.",
    youAeCurrentlyOn: "Dir sidd de Moment op",
    plan: "Plang",
    userData: "Benotzer Daten",
    businessData: "BUSINESS DATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Wielt w.e.g. Är Währung",
    selectYourCurrency: "Wielt Är Währung",
    purchaseMoreSmsUnits: "Kaaft méi SMS Eenheeten mam Formulaire hei ënnen",
    youHave: "Dir hutt",
    atLeast4SmsUnitsRrequired:
      "Op d'mannst 4 SMS Eenheeten ass fir d'Verifikatioun noutwendeg, luet w.e.g. op!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Préift w.e.g. Äre Bankkonto fir un de 'Know Your Customer' (KYC) Ufuerderunge gerecht ze ginn. Dëst erlaabt Iech Bezuelungen iwwer USSD oder Direktiwwerweisung ze sammelen, Bestellunge vu Clienten ze kréien a Transaktiounssiedlung ze verschaffen. Uwendbar nëmme fir nigerianesch Händler. Dréckt w.e.g. op de Knäppchen hei ënnen fir unzefänken.",
    reConnectBankAccount: "Re-Connect Bankkonto",
    accountName: "Benotzernumm",
    accountNumber: "Kontosnummer",
    bankName: "Bank Numm",
    verified: "Verifizéiert",
    accountDetails: "Kont Detailer",
    kycBankAccount: "KYC",
    createTier: "Tier erstellen",
    fileUploadSuccessful: "Datei erfollegräich eropgelueden",
    fileUploadFailed: "Eroplueden vum Fichier ass net gelongen",
    createLoyaltyProgram: "Erstellt e Loyalitéit Programm",
    createLoyalty: "Loyalitéit erstellen",
    name: "Numm",
    loyaltyArtwork: "Loyalitéit Konschtwierker",
    clickToUpload: "Klickt fir eropzelueden",
    amountToPointsRatio: "Betrag u Punkte Verhältnis",
    points: "Punkten",
    recommendedAmountToPointRatio:
      "Recommandéiert: ₦ 1 bis 1 Punkt. dh fir all 1 Naira ausginn, kréien Är Clienten 1 Punkt",
    pleaseTypeIn: "Gitt w.e.g. an",
    toDeleteLoyalty: "fir dës Loyalitéit ze läschen",
    deleteLoyalty: "Loyalitéit läschen",
    toConfirm: "ze bestätegen",
    edit: "Editéieren",
    pointsAwardedSuccessfully: "Punkte erfollegräich ausgezeechent.",
    enterPointValueToAward:
      "Gitt de Punktwäert an deen Dir Clienten wëllt auszeechnen",
    award: "Präis",
    awardPointValuesToCustomer: "Award Point Wäert fir de Client",
    enrollMembersToLoyalty: "Aschreiwen Memberen op Loyalitéit",
    awardPoints: "Award Punkten",
    enroll: "Aschreiwen",
    home: "Doheem",
    loyalty: "Loyalitéit",
    enrollCustomers: "Aschreiwen Clienten",
    selected: "Ausgewielt",
    customer: "Client",
    loyaltyActivationSuccessful: "Loyalitéit Aktivéierung ass erfollegräich.",
    loyaltyDeactivationSuccessful:
      "Loyalitéit Deaktivéierung ass erfollegräich.",
    viewTier: "Kuckt Tier",
    deactivate: "De-Aktivéieren",
    activate: "Aktivéieren",
    actions: "Aktiounen",
    nameOfLoyalty: "Numm vun der Loyalitéit",
    loyaltyStatus: "Loyalitéit Status Loyalitéit Status",
    numberOfTiers: "Zuel vun den Tiers",
    createdOn: "Erstellt Op",
    createATier: "Erstellt en Tier",
    stopCreatingTierConfirmation: "Wëllt Dir ophalen en Tier ze kreéieren?",
    stopEditingTierConfirmation: "Wëllt Dir ophalen dës Tier z'änneren?",
    nameOfTier: "Numm Tier",
    minimumSpendingTarget: "Minimum Ausgab Zil",
    maximumSpendingTarget: "Maximum Ausgab Zil",
    minimumSpendingTargetRequired: "Minimum Ausgaben Zil ass erfuerderlech",
    maximumSpendingTargetRequired: "maximal Ausgabenziel erfuerdert",
    rewardSponsor: "Belounungssponsor",
    pleaseChooseARewardSponsor: "Wielt w.e.g. e Belounungssponsor",
    self: "Selwer",
    partner: "Partner",
    rewardPartner: "Belounung Partner",
    pleaseSelectRewardPartner: "Wielt w.e.g. Äre Belounungspartner",
    rewards: "Belounungen",
    pleaseSelectAReward: "Wielt w.e.g. eng Belounung",
    instructionsOnRedeemingReward:
      "Instruktiounen iwwer wéi de Client d'Belounung soll ofsetzen",
    pleaseFillInThisField: "Fëllt w.e.g. dëst Feld aus!",
    toDeleteThisTier: " fir dësen Niveau ze läschen",
    deleteTier: "Läscht Tier",
    viewMembers: "View Memberen",
    membersEnrolled: "Memberen ageschriwwen",
    instruction: "Uweisung",
    membersIn: "Memberen an",
    availableTiersInLoyalty: "Verfügbare Tier (en) am Loyalitéit Programm",
    tiers: "Tierm",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Verfügbare Loyalitéit Programm",
    totalLoyalties: "TOTAL Loyalitéiten",
    memberDetails: "Member Detailer",
    nameOfCustomer: "Numm vum Client",
    address: "Adress",
    allEnrolledMembers: "All ageschriwwen Memberen",
    thisIsToWishYouHappyBirthday:
      "Dëst ass fir Iech e ganz gléckleche Gebuertsdag a wënschenswäert Liewen ze wënschen. Merci fir alles wat Dir dem Loyster sidd. Glécklech Feierdeeg!",
    inputAnOfferPlease: "Gitt eng Offer an",
    pleaseSelectTheInsertPoint:
      "Wielt w.e.g. den Insert Punkt an der Noriicht a klickt nach eng Kéier",
    birthdayOfferAndMessage: "Gebuertsdag Offer a Message",
    birthdayOffer: "Gebuertsdag Offer",
    birthdayMessage: "Gebuertsdag Message",
    noOfferFound: "Keng Offer fonnt",
    settingABirthdayOffer:
      "E Gebuertsdag Offer ofsetzen erlaabt Clienten dës Offer per SMS op hire Gebuertsdag ze kréien",
    createOffer: "Offer erstellen",
    whatIsTheOffer: "Wat ass d'Offer?",
    editMessage: "Message editéieren",
    insert: "Insert",
    theNameOfCustomerInserted: "Den Numm vum Client gëtt hei agefouert",
    theBirtdayOfferInserted: "D'Gebuertsdag Offer gëtt hei agebaut",
    youSuccessfullyAddedNewBranch:
      "Dir hutt erfollegräich eng nei Filial bäigefüügt!",
    addNewBranch: "Neie Branche derbäi",
    addBranch: "Add Branch",
    additionalBranchWillIncur: "Zousätzlech Branch géif entstoen",
    perBranch: "pro Branche",
    ecommerceBranchCosts: "Ecommerce Branch Käschten",
    pleaseEnterBranchName: "Gitt w.e.g. de Filialnumm un",
    pleaseEnterBranchAddress1:
      "Gitt w.e.g. der Adresslinn vun der Branche 1 an",
    enterBranchAddress1: "Gitt d'Branche Adresslinn 1 an",
    enterBranchAddress2: "Gitt d'Branche Adresslinn 1 an",
    pleaseEnterBranchAddress2:
      "Gitt w.e.g. der Adresslinn 2 vun der Branche an",
    enterBranchName: "Gitt de Branchennumm an",
    successfullyAddedStaff:
      "Dir hutt erfollegräich en neit Personal derbäigesat!",
    addNewStaff: "Neit Personal bäizefügen",
    addStaff: "Dobäizemaachen Personal",
    additionalStaffWillIncur: "Zousätzlech Personal géif opkommen",
    perStaff: "pro Personal.",
    pleaseEnterStaffEmail: "Gitt w.e.g. d'E -Mail vum Personal un",
    pleaseEnterStaffUsername: "Gitt w.e.g. de Benotzernumm vum Personal an",
    pleaseEnterStaffPassword: "Gitt w.e.g. d'Passwuert vum Personal an",
    pleaseSelectStaffRole: "Wielt w.e.g. d'Roll vum Personal",
    selectStaffRole: "Wielt d'Roll vum Personal",
    enterStaffEmail: "Gitt d'E -Mail vum Personal an",
    enterStaffUsername: "Gitt de Benotzernumm vum Personal an",
    enterStaffPassword: "Gitt d'Passwuert vum Personal an",
    spacesNotAllowedInUsername: "Plazen net am Benotzernumm erlaabt",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Wielt w.e.g. Geschäft fir Personal ze befestegen",
    searchForBranchToAttachStaff: "Sich no Filial fir Personal ze befestegen",
    username: "Benotzernumm",
    role: "Roll",
    areYouSureToDeleteThis: "Sidd Dir sécher dëst ze läschen",
    branches: "Branchen",
    upgradeYourPlan: "Upgrade Äre Plang.",
    add: "ADD",
    addNew: "Neie bäizefügen",
    customerWithEmailAlreadyExists:
      "Client mat E -Mail/Telefonsnummer existéiert scho!",
    successfullyAddedNewCustomer:
      "Dir hutt erfollegräich en neie Client derbäigesat!",
    addCustomer: "Client addéieren",
    pleaseEnterCustomerFirstName: "Gitt w.e.g. dem Virnumm vum Client an",
    pleaseEnterCustomerLastName: "Gitt w.e.g. dem Clientsnumm an",
    pleaseEnterCustomerPhoneNumber:
      "Gitt w.e.g. d'Telefonsnummer vum Client an",
    pleaseEnterCustomerEmail: "Gitt w.e.g. dem Client seng E -Mail an",
    pleaseEnterCustomerAddressLine: "Gitt w.e.g. d'Adresslinn vum Client an",
    pleaseEnterCustomerOtherAddress:
      "Gitt w.e.g. dem Client seng aner Adress an",
    pleaseSelectCustomerGender: "Wielt w.e.g. dem Geschlecht vum Client",
    gender: "Geschlecht",
    male: "Männlech",
    female: "Weiblech",
    bank: "Bank",
    selectBank: "Wielt Bank",
    stateOrRegionOrProvince: "Staat/Regioun/Provënz",
    customerNotes: "Client Notizen",
    sendSms: "SMS schécken",
    editCustomer: "Client änneren",
    redeemReward: "Belounung eroflueden",
    issueLoyaltyCard: "Loyalitéitskaart erausginn",
    deleteCustomer: "Client läschen",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Dir hutt erfollegräich eng Loyalitéit Memberschaft ID zougewisen",
    noMembershipIdAvailable:
      "Keng Members IDs verfügbar. Kontaktéiert eis w.e.g. hello@loystar.co",
    sendEmail: "E -Mail schécken",
    membershipPoints: "Memberschaft Punkten",
    customerDetails: "Client Detailer",
    close: "Zoumaachen",
    loyaltyBalance: "Loyalitéit Gläichgewiicht",
    pointsBalance: "Punkten Gläichgewiicht",
    starBalance: "Star Gläichgewiicht",
    requiredPoints: "Noutwendeg Punkte",
    requiredStars: "Noutwendeg Stären",
    reddemCode: "Code läschen",
    toDeleteThisCustomer: "dëse Client ze läschen",
    customerHasBeenDeletedSuccessfully:
      "De Client gouf erfollegräich geläscht!",
    customerWithPhoneAlreadyExists: "Client mat Telefonsnummer existéiert scho",
    customerWasSuccessfullyEdited: "De Client gouf erfollegräich geännert",
    anErrorOccured: "E Feeler ass geschitt",
    phoneNumberIncludeCountry: "Telefonsnummer (Landcode abegraff)",
    yourFileQueuedForUpload:
      "Är Datei gouf an der Schlaang geluecht fir eropzelueden. W.e.g. erfrëscht d'Säit no e puer Sekonnen.",
    thereWasAnErrorPerformingOperation:
      "Et war e Feeler beim Ausféieren vun der Operatioun!",
    pleaseSelectFile: "Wielt w.e.g. eng Datei!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Nëmmen CSV Dateien erlaabt. Eroplueden w.e.g. eng .csv Datei.",
    fileShouldBeSmallerThan5Mb:
      "Datei sollt méi kleng wéi 5MB sinn. Wann Dir eng méi grouss Datei hutt, schéckt w.e.g. support@loystar.co. Merci",
    customerFirstNameIsRequired: "Client Virnumm ass erfuerdert op der Rei",
    customerPhoneNumberIsRequired:
      "Client Telefonsnummer ass op der Rei noutwendeg",
    importCustomers: "Import Clienten",
    upload: "Eroplueden",
    clickIconToDownloadCsv:
      "Klickt op dës Ikon fir d'CSV Dateiformat erofzelueden.",
    getGoogleSheetVersion: "Kritt d'Google Blat Versioun",
    clickOrDragFileToUpload:
      "Klickt oder zitt d'Datei an dëst Gebitt fir eropzelueden",
    missingOrInvalidColumnHeader:
      "Vermësst oder ongëlteg Kolonnekop. Follegt w.e.g. d'Schablounformat. Merci.",
    youHaveImported: "Dir hutt importéiert",
    youSuccessfullyRedeemedReward:
      "Dir hutt Är Belounung erfollegräich erléist!",
    sixDigitCode: "Sechszifferen Code",
    pleaseEnterCustomerRewardCode:
      "Gitt w.e.g. dem Client säi Belounungscode an",
    enterRewardCode:
      "Gitt Belounungscode an. (De Belounungscode ass grouss an de Grëff)",
    selectLoyaltyProgram: "Wielt Loyalitéit Programm",
    stamps: "Timberen",
    smsUnitsLow: "SMS Unitéiten niddereg",
    pleaseTopUpSmsUnits: "W.e.g. Top Up SMS Eenheeten",
    smsSuccessfullySentForDelivery:
      "SMS erfollegräich fir d'Liwwerung geschéckt!",
    sendSmsTo: "Schéckt SMS un",
    allCustomers: "All Clienten",
    unitsAvailable: "Eenheeten verfügbar",
    pleaseTypeInTheMessage: "Gitt w.e.g. de Message an",
    message: "Message",
    charactersRemaining: "Zeeche bleiwen",
    avoidUseOfSpecialCharacters:
      "Vermeit d'Benotzung vu Spezialfiguren an Emojis fir SMS Eenheeten ze konservéieren.",
    note: "Notiz",
    errorFetchingCustomersMultilevelDetail:
      "Feeler beim Hëlle Client MultiLevel Detailer",
    search: "Sichen",
    reset: "Reset",
    importCustomer: "Import Client",
    addNewCustomer: "Neie Client derbäisetzen",
    sendSmsBroadcast: "Schéckt SMS Sendung",
    totalCustomers: "TOTAL Clienten",
    disbursementDetails: "Ausbezuelungsdetailer",
    paidBy: "Bezuelt Vun",
    disbursed: "Ausbezuelt",
    bankAccountName: "Bankkonto Numm",
    bankAccountNumber: "Bankkontosnummer",
    transferInitiated: "Transfer Initiéiert",
    transferCompleted: "Transfert fäerdeg",
    pleaseEnterAValid: "Gitt w.e.g. eng valabel",
    begin: "ufänken",
    end: "Enn",
    date: "daten",
    paymentDate: "Bezuelungsdatum",
    selectDisbursementDuration: "Wielt Ausbezuelungsdauer",
    totalSettled: "Total ofgesot",
    totalUnsettled: "Total Ongerecht",
    toDeleteThisSale: "fir dëse Verkaf ze läschen",
    draftSaleDeletedSuccessfully: "Entworf Verkaf gouf erfollegräich geläscht!",
    deleteSale: "Läscht Verkaf",
    pleaseTypeInYourTotal:
      "Gitt w.e.g. Ären Total an fir d'Läschung ze bestätegen",
    billDetails: "Bill Detailer",
    printBill: "Drécken Bill",
    servedBy: "Servéiert vun",
    total: "Insgesamt",
    createdDate: "Datum erstallt",
    createdTime: "Zäit erstallt",
    status: "Status",
    loginSuccessful: "Login erfollegräich",
    pleaseWaitWhileWeConnectAccount: "Waart w.e.g. wa mir Äre Kont verbannen",
    connectionFailedTryAgain:
      "D'Verbindung ass gescheitert. Probéiert w.e.g. nach eng Kéier.",
    connectionSuccessful: "Verbindung erfollegräich",
    viewDetails: "View Detailer",
    enable: "Aktivéieren",
    free: "Fräi",
    cost: "Käschte",
    visitWebsite: "Besicht Websäit",
    pleaseUpgradeYourPlanToPro:
      "Upgrade w.e.g. Äre Plang op Pro Plus fir dës App z'aktivéieren",
    connectYourBankAccount:
      "Connectéiert Äre Bankkonto fir Bezuelungen ze kréien.",
    disable: "Auszeschalten",
    enableApp: "App aktivéieren",
    addNewProductToInvoice: "Füügt Neit Produkt zu Rechnung",
    toDeleteThisInvoice: "fir dës Rechnung ze läschen",
    invoiceDeletedSuccessfully: "Rechnung gouf erfollegräich geläscht!",
    deleteInvoice: "Rechnung läschen",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Dir hutt erfollegräich eng Rechnung Bezuelung Noriicht bäigefüügt!",
    pleaseEnterPaymentInstructions: "Gitt w.e.g. d'Bezuelungsinstruktiounen an",
    invoiceId: "Rechnung ID",
    paidDate: "Bezuelten Datum",
    reference: "Referenz",
    productAdded: "Produkt bäigefüügt",
    productOutOfStock: "Produkt net op Lager. W.e.g. nei op Lager.",
    totalInvoices: "TOTAL Rechnungen",
    totalPaidInvoices: "TOTAL BEZUELT Rechnungen",
    totalUnpaidInvoices: "TOTAL OUNZZUELEN Rechnungen",
    loyaltyProgramDeleted: "Loyalitéit Programm Geläscht",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dëse Loyalitéit Programm gouf erfollegräich geläscht",
    thisLoyaltyProgramEditedSuccessfully:
      "Dëse Loyalitéit Programm gouf erfollegräich geännert",
    loyaltyProgramAddedSuccessfully:
      "Loyalitéit Programm gouf erfollegräich derbäigesat",
    loyaltyProgramEdited: "Loyalitéit Programm geännert",
    loyaltyProgramAdded: "Loyalitéit Programm derbäigesat",
    loyaltyDetails: "Loyalitéit Detailer",
    doYouWantToCloseDialog: "Wëllt Dir dësen Dialog zoumaachen?",
    pleaseEnterLoyaltyName: "Gitt w.e.g. den Numm vun Ärer Loyalitéit an",
    programType: "Programm Typ",
    pleaseSelectProgramType: "Wielt w.e.g. e Programmtyp",
    simplePoints: "Einfach Punkten",
    stampsProgram: "Timberen Programm",
    threshold: "Threshold",
    pleaseEnterLoyaltyThreshold: "Gitt w.e.g. d'Loyalitéitsgrenz an",
    reward: "Belounung",
    pleaseEnterLoyaltyReward: "Gitt w.e.g. d'Belounung vun der Loyalitéit an",
    totalUserPoints: "Total Benotzer Punkte",
    totalUserStamps: "Total Benotzerstempel",
    spendingTarget: "Zil ausginn",
    spendingTargetHint1:
      "Zil ausginn ass wéi vill e Client muss ausginn fir d'Belounung ze verdéngen. 1 Währungswäert = 1 Punkt.",
    spendingTargetHint2:
      "Timberen Zil ass wéi vill Timberen e Client muss sammelen fir d'Belounung ze verdéngen. zB. 5",
    addNewLoyaltyProgram: "Neie Loyalitéit Programm derbäi",
    addLoyaltyProgram: "Loyalitéit Programm derbäi",
    loyaltyProgramType: "Loyalitéit Programm Typ",
    pleaseSelectLoyaltyProgramType: "Wielt w.e.g. Loyalitéit Programm Typ",
    nameOfProgram: "Numm vum Programm",
    pleaseEnterProgramName: "Gitt w.e.g. de Programmnumm an",
    whatIsTheReward: "Wat ass d'Belounung?",
    egNextPurchaseIsFree: "Z.B. Den nächste Kaf ass gratis",
    customerName: "Client Numm",
    guestCustomer: "Gaascht Client",
    orderConfirmedSuccessfully: "Bestellung erfollegräich bestätegt",
    orderCancelledSuccessfully: "Bestellung erfollegräich annuléiert",
    confirmOrder: "Bestellung bestätegen",
    cancelOrder: "Bestellung ofbriechen",
    allOrders: "All Bestellungen",
    totalOrders: "TOTAL UERDER",
    doYouWantToAcceptOrder: "Wëllt Dir dës Bestellung akzeptéieren?",
    doYouWantToCancelOrder: "Wëllt Dir dës Bestellung annuléieren?",
    orderDetails: "Bestellungsdetailer",
    orderCreatedAt: "Bestellung erstallt am",
    supplier: "Fournisseur",
    productName: "Produit Numm",
    quantity: "Quantitéit",
    unitPrice: "Eenzelpräis",
    receivedBy: "Kritt Vun",
    reportFrom: "Bericht vun",
    totalSupplies: "Ganzen Ëmgeréits",
    allRightsReserved: "All Rechter Reservéiert",
    toDeleteThisTransaction: "fir dës Transaktioun ze läschen",
    transactionDeletedSuccessfully:
      "D'Transaktioun gouf erfollegräich geläscht. Stock gouf an den Inventaire zréckgezunn.",
    deleteTransaction: "Transaktioun läschen",
    transactionDetails: "Transaktioun Detailer",
    printReceipt: "Drécken Empfang",
    channel: "Kanal",
    discount: "Remise",
    profit: "Gewënn",
    discountedSales: "Ofgeséchert Verkaf",
    errorFetchingRecord: "Feeler beim Recuperéieren",
    exportTransactions: "Export Transaktiounen",
    errorFetchingSalesRecord: "Feeler beim Verkaf vum Rekord fir den Export.",
    totalSellingPrice: "Total Verkafspräis",
    totalCostPrice: "Gesamtkäschte Präis",
    appliedDiscount: "Ugewannt Rabatt",
    noOfItems: "Zuel vun Artikelen",
    sales: "Verkaf",
    export: "Exportéieren",
    totalProfit: "Total Gewënn",
    totalBalanceInPeriod: "Gesamtsaldo an der Period",
    allTimeSales: "All Time Verkaf",
    records: "Records",
    todaysSales: "Haut de Verkaf",
    transaction: "Transaktioun",
    youHaveExceededTotalNumberOfProducts:
      "Dir hutt d'Gesamtzuel vun de Produkter erlaabt op Ärem Plang iwwerschratt. Upgrade Äre Plang fir eng méi héich Limit ze genéissen.",
    youNeedToHaveLoyaltyProgram:
      "Dir musst e Loyalitéit Programm hunn fir dës Feature ze benotzen!",
    price: "Präis",
    category: "Kategorie",
    stockTracking: "Stock Tracking",
    stockCount: "Stock Grof",
    taxed: "Besteiert",
    originalPrice: "Original Präis",
    costPrice: "Käschte Präis",
    unit: "Eenheet",
    productImage: "Produktbild",
    taxRate: "Steiersaz",
    taxType: "Steier Typ",
    trackInventory: "Streck Inventaire",
    variants: "Varianten",
    hasVariants: "Huet Varianten",
    importProduct: "Produkt importéieren",
    exportProducts: "Export Produkter",
    addNewProduct: "Neie Produkt derbäi",
    viewCategory: "Kategorie kucken",
    viewSuppliers: "View Liwweranten",
    receiveInventory: "Kréien Inventaire",
    printAllProductsTag: "Dréckt All Produkter Tag",
    deleteAll: "All läschen",
    totalProducts: "TOTAL PRODUKTER",
    youveSuccessfullyAddedANewCategory:
      "Dir hutt erfollegräich eng nei Kategorie derbäigesat",
    addNewCategory: "Neie Kategorie derbäisetzen",
    addCategory: "Kategorie derbäisetzen",
    categoryName: "Kategorie Numm",
    pleaseEnterCategoryName: "Gitt w.e.g. Kategorienumm an",
    stampsTarget: "Timberen Zil",
    sendInventory: "Schéckt Inventaire",
    productDetails: "Produit Detailer",
    youveSuccessfullyEditedThisCategory:
      "Dir hutt dës Kategorie erfollegräich geännert",
    update: "Update",
    categoryList: "Kategorie Lëscht",
    categories: "Kategorien",
    enterQuantityToUpdate: "Gitt eng Quantitéit un fir ze aktualiséieren",
    inventorySentSuccessfully: "D'Inventar gouf erfollegräich geschéckt!",
    updateInventory: "Update Inventaire",
    currentQuantity: "Aktuell Quantitéit",
    pleaseEnterQuantityToAdd:
      "Gitt w.e.g. d'Quantitéit un déi Dir derbäi wëllt",
    pleaseSelectABranch: "Wielt w.e.g. eng Filial",
    searchForBranch: "Sich no Filial",
    youCantSendMoreThanStock:
      "Dir kënnt net méi schécken wéi Dir op Lager hutt",
    send: "Schécken",
    pleaseEnterQuantityToSend:
      "Gitt w.e.g. d'Quantitéit un déi Dir wëllt schécken",
    productNameIsRequiredOnRow: "Produktnumm ass op der Rei noutwendeg",
    productCategoryIsRequiredOnRow:
      "Produktkategorie ass op der Rei erfuerderlech",
    productPriceIsRequiredOnRow: "Produktpräis ass op der Rei erfuerderlech",
    productUnitIsRequiredOnRow: "Produkt Eenheet ass op der Rei erfuerderlech",
    productQuantityIsRequiredOnRow:
      "Produktquantitéit ass op der Rei erfuerderlech",
    productVariantsRequireTracking: "Produktvarianten erfuerderen Tracking!",
    pleaseAddVariantClickButton:
      "Füügt w.e.g. eng Variant un andeems Dir op de Knäppchen addéieren!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Varianten si méi wéi ausgewielt Produktquantitéit, Pls reduzéiert Variant Quantitéit",
    productEditedSuccessfully: "Produkt gouf erfollegräich geännert!",
    fileTooLargeLessThan4Mb:
      "Dateigréisst ass ze grouss! Dateigréisst sollt manner wéi 4MB sinn.",
    suchVariantAlreadyExist: "Esou Variant existéiert scho",
    addVariants: "Varianten derbäisetzen",
    editProduct: "Produkt änneren",
    pleaseEnterProductName: "Gitt w.e.g. Produktnumm an",
    pleaseEnterProductPrice: "Gitt w.e.g. Produktpräis an",
    pleaseEnterProductOriginalPrice: "Gitt w.e.g. Produkt Original Präis",
    productDescription: "Produkt beschreiwung",
    selectProductCategory: "Wielt Produktkategorie",
    pleaseSelectProductCategory: "Wielt w.e.g. Produktkategorie",
    productCostPrice: "Produkt Käschte Präis",
    productSellingPrice: "Produkt Verkafspräis",
    productOriginalPrice: "Produit Original Präis",
    maxFileSizeAllowedIs4Mb: "Max. Dateigréisst erlaabt ass 4mb",
    productsWithPicturesArePublished:
      "Produkter mat Biller ginn op Discover verëffentlecht fir Bestellungen ze kréien",
    shouldThisProductBeTracked: "Soll dëst Produkt verfollegt ginn?",
    pleaseSelectStockUnit: "Wielt w.e.g. Stock Eenheet",
    stockUnit: "Stock Eenheet",
    bag: "BAG",
    bottle: "Fläsch",
    bunch: "BUNCH",
    can: "KANN",
    carton: "KARTON",
    crate: "KRATZ",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Gitt d'Produktquantitéit un",
    productQuantity: "Produkt Quantitéit",
    isThisProductTaxed: "Ass dëst Produkt besteiert?",
    selectTaxType: "Wielt Steiertyp",
    pleaseSelectTaxType: "Wielt w.e.g. Steiertyp",
    progressive: "Progressiv",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Gitt w.e.g. Produktsteiersaz of",
    doesProductHaveVariants: "Huet dëst Produkt Varianten?",
    type: "Typ",
    value: "Wäert",
    pleaseEnterVariantType: "Gitt w.e.g. Varianttyp an",
    pleaseEnterVariantValue: "Gitt weg de Wäert vun der Variant an",
    pleaseEnterVariantPrice: "Gitt weg de Präis vun der Variant an",
    pleaseEnterVariantQuantity: "Gitt weg d'Quantitéit vun der Variant an",
    productDeletedSuccessfully: "Produkt gouf erfollegräich geläscht!",
    categoryDeletedSuccessfully: "Kategorie gouf erfollegräich geläscht!",
    toDeleteThisProduct: "fir dëse Produkt ze läschen",
    invalidProductQuantity: "Ongëlteg Produktquantitéit",
    quantityAddedIsOverStock:
      "D'Quantitéit déi Dir derbäigesat ass iwwer dat wat Dir op Lager hutt.",
    itemInventoryNotTracked: "Artikelinventar gëtt net verfollegt",
    addBulkQuantity: "Füügt Bulk Quantitéit derbäi",
    enterBulkQuantity: "Gitt Bulk Quantitéit an",
    pleaseEnterBulkQuantity: "Gitt w.e.g. Bulk Quantitéit un",
    youveSuccessfullyAddedANewProduct:
      "Dir hutt erfollegräich en neit Produkt derbäigesat!",
    pleaseEnterProductSellingPrice: "Gitt w.e.g. Produkt Verkafspräis an",
    doYouWantToTrackProductStock: "Wëllt Dir Produktlager verfollegen?",
    sellingPrice: "Verkafspräis",
    setProductExpiryReminder: "Set Produkt Verfalls Erënnerung",
    productExpiryDate: "Produkt Verfallsdatum",
    startRemindingFrom: "Start Erënnert Vun",
    productSuppliesAddedSuccessfully:
      "Dir hutt Produktlieferungen erfollegräich derbäigesat.",
    addProductSupplies: "Produkt Liwwerungen derbäi",
    pleaseSelectSupplier: "Wielt w.e.g. Liwwerant",
    nameOfProduct: "Numm vum Produkt",
    pleaseSelectProduct: "Wielt w.e.g. Produkt",
    productVariant: "Produit Variant",
    pleaseSelectAVariant: "Wielt w.e.g. eng Variant",
    pleaseEnterUnitPrice: "Gitt w.e.g. Eenheetspräis an",
    businessBranch: "Business Branch",
    pleaseSelectBranch: "Wielt w.e.g. Filial",
    youveSuccessfullyAddedANewSupplier:
      "Dir hutt erfollegräich en neie Liwwerant derbäigesat",
    addSupplier: "Zouliwwerer derbäisetzen",
    pleaseEnterSupplierEmail: "Gitt w.e.g. den E -Mail vum Liwwerant an",
    pleaseAddADescription: "Füügt w.e.g. eng Beschreiwung un",
    anErrorOccuredProductsDeleted:
      "E Feeler ass geschitt wärend der Operatioun. Notéiert w.e.g., e puer Produkter hu vläicht am Prozess geläscht ginn",
    bulkDelete: "Bulk läschen",
    youAreAboutToDelete: "Dir sidd amgaang ze läschen",
    product: "Produkt",
    isDueToRedeem: "ass wéinst der Erléisung",
    aReward: "eng Belounung",
    pleaseSelectCustomerToReeemReward:
      "Wielt w.e.g. e Client fir d'Belounung ze erléisen.",
    youHaveNoLoyaltyProgramRunning:
      "Dir hutt keen aktive Loyalitéit Programm lafen",
    customerHhasNoPointsInLoyaltyProgram:
      "De Client huet keng Punkte an dësem Loyalitéit Programm",
    proceedWithPayment: "Fuert weider mat Bezuelen?",
    youAreAboutToPayForTransactionUsingPoints:
      "Dir sidd amgaang fir d'Transaktioun mat Punkte ze bezuelen.",
    customerHas: "Client Huet",
    worth: "wäert",
    andIsNotSufficientToPayForTransaction:
      "an ass net genuch fir dës Transaktioun ze bezuelen. Wëlle se de Saldo derbäi mat enger anerer Bezuelmethod?",
    addCustomerLoyalty: "Füügt Clients Loyalitéit derbäi",
    pleaseAddCustomerFirst: "Füügt w.e.g. e Client un oder wielt als éischt.",
    pleaseWaitWhileWeGetReady: "W.e.g. waart, wa mir prett sinn",
    lowStock: "Niddereg Stock",
    pleaseEnterAmountTendered: "Gitt w.e.g. de Betrag of",
    areYouSureToBookSaleOffline:
      "Sidd Dir sécher datt Dir dëse Verkaf offline wëllt buchen?",
    saleWouldBeBookedAutomatically:
      "De Verkaf wier automatesch gebucht wann Dir Ären Internet ausschalt",
    offlineSalesBookingCancelled: "Offline Verkafsbuchung annuléiert",
    covid19Message:
      "COVID19: Wäscht Är Hänn mat Seef oder Sanitize fir d'Verbreedung ze stoppen. Bleift sécher ëmmer",
    saleSuccessfullyRecorded: "Verkaf erfollegräich opgeholl!",
    sendReceiptToEmail: "Schéckt Empfang op E -Mail",
    sendThankYouSms: "Schéckt merci SMS",
    sendShippingDetails: "Schéckt Versanddetailer",
    addLoyalty: "Loyalitéit derbäisetzen",
    searchCustomerNameOrNumber: "Sich Client Numm oder Nummer",
    clickTheSearchCustomerBox: "Klickt op d'Sich Client Box a Scan Card",
    enterProductPrice: "Gitt Produktpräis an",
    enterPriceFor: "Gitt Präis fir",
    searchForProduct: "Sich no Produkt",
    all: "All",
    backToDashboard: "Zréck op Dashboard",
    viewDraftSales: "Kuckt Entworf Verkaf",
    variantSelected: "Variant ausgewielt",
    variant: "Variant",
    thePreviousVariantSelectionNotAvailable:
      "Déi vireg Variant Auswiel ass net verfügbar fir déi nei Variant ausgewielt op Basis vum Präis, ännert w.e.g. Är Auswiel.",
    pleaseSupplyPositiveQuantityNumber:
      "Gitt w.e.g. eng postiv Quantitéitsnummer un",
    lowStockFor: "Niddereg Lager fir",
    clearVariants: "Kloer Varianten",
    pleaseEnterQuantity: "Gitt w.e.g. Quantitéit un",
    picture: "Bild",
    redemptionToken: "Erléisung Token",
    token: "Token",
    totalSpent: "Total Ausgaben",
    confirmPayment: "Bestätegt d'Bezuelung",
    hasPaymentBeenMade: "Ass d'Bezuelung erfollegräich veraarbecht ginn?",
    enterTransactionReference:
      "Gitt d'Transaktiounsreferenz mat där Dir bezuelt hutt",
    pleaseSelectACustomer: "Wielt w.e.g. e Client!",
    areYouSureToApplyDiscount: "Sidd Dir sécher datt Dir Rabatt gëlle wëllt?",
    addYourBankAccountToEnableUssd:
      "Fügt Äre Bankkonto derbäigesat fir Instant USSD Transfer mat uPay z'erméiglechen",
    totalAmountToPay: "Gesamtbetrag ze bezuelen",
    doYouWantToCancelTransaction: "Wëllt Dir dës Transaktioun annuléieren?",
    savePrintBill: "Späicheren/Drécken Bill",
    enterAmountCollectedForCustomer: "Gitt de Betrag u fir de Client gesammelt",
    recordSale: "Rekord Verkaf",
    checkOutWith: "Check mat",
    instantTransfer: "Direkt Transfer",
    dialTheUSSDCode: "Wielt den USSD Code",
    pleaseSelectABank: "Wielt w.e.g. eng Bank",
    payWithUSSD: "Bezuelt Mat USSD",
    sendBillTo: " - Schéckt de Bill un",
    waitingForUSSDTransfer: "Waart op USSD Transfer",
    percent: "Prozentsaz",
    applyDiscount: "Rabatt uwenden",
    thisBillHasBeenSaved: "Dëse Gesetzesprojet gouf gerett",
    saveDraft: "Entworf späicheren",
    pleaseTypeANameToIdentifyCustomer:
      "Gitt w.e.g. en Numm an fir de Client z'identifizéieren",
    paymentDetails: "Bezuelen Detailer",
    basePrice: "Basis Präis",
    staff: "Personal",
    subTotal: "SubTotal",
    durationMonths: "Dauer (Méint)",
    selectADuration: "Wielt eng Dauer",
    oneMonth: "1 Mount",
    twoMonths: "2 Méint",
    threeMonths: "3 Méint",
    sixMonths: "6 Méint",
    twelveMonths: "12 Méint",
    eighteenMonths: "18 Méint",
    twentyFourMonths: "24 Méint",
    twoMonthsFree: "(2 Méint gratis)",
    threeMonthsFree: "(3 Méint gratis)",
    fiveMonthsFree: "(5 Méint gratis)",
    yourAccountHasBeen: "Äre Kont war",
    renewed: "erneiert",
    upgraded: "upgradéiert",
    successfully: "erfollegräich",
    yourSubscription: "Ären Abonnement",
    youAreCurrentlyEnrolledOnA: "Dir sidd de Moment ageschriwwen op engem",
    pleaseChooseAPaymentOption: "Wielt w.e.g. eng Bezuelméiglechkeet",
    planRenewal: "Plang Erneierung",
    planUpgrade: "Plan Upgrade",
    pleaseSelectDurationToPayFor:
      "Wielt w.e.g. Dauer fir déi Dir wëllt bezuelen",
    staffAccounts: "Staff Accounts",
    ecommerce: "E -Commerce",
    pleaseSelectAPlan: "Wielt w.e.g. e Plang",
    includeAddons: "Enthält Add-ons",
    viewTransactions: "Kuckt Transaktiounen",
    customerHasNoCompletedTansactions:
      "De Client huet nach keng fäerdeg Transaktiounen",
    branch: "Branche",
    enterNumberOfEcommerceBranches: "Gitt d'Zuel vun den Ecommerce Filialen an",
    enterNumberOfEcommerceBranchesToPay:
      "Gitt d'Zuel vun den Ecommerce Branchen un, fir déi Dir wëllt bezuelen",
    ecommerceIntegration: "Ecommerce Integratioun",
    enterNumberOfBranches: "Gitt d'Zuel vun de Branchen an",
    enterNumberOfAdditionalBranchesToPay:
      "Gitt d'Zuel vun zousätzleche Filialen un déi Dir wëllt bezuelen",
    enterNumberOfStaffs: "Gitt d'Zuel vun de Staffs an",
    enterNumberOfStaffsToPayFor:
      "Gitt d'Zuel vu Personal un, fir déi Dir wëllt bezuelen",
    discountApplies: "Rabatt gëllt",
    starsOnThe: "Stären op der",
    offer: "Offer",
    get: "Kréien",
    moreStarsToRedeem: "méi Stären ze erléisen",
    taxVat: "Steier (TVA)",
    callCashierToCompletePayment:
      "Call Caissier fir d'Bezuelung komplett ze maachen",
    receipt: "Empfang",
    dear: "Léif",
    thankYouForYourOrderFindGoods:
      "Merci fir Är Bestellung. Fannt w.e.g. déi folgend geliwwert Wueren, wéi ausgemaach.",
    shippingNote: "Versand Notiz",
    enterShippingNote: "Gitt Verschécken Notiz an",
    shippingAddress: "Liwweradress",
    enterShippingAddress: "Gitt d'Liwweradress un",
    wellDoneYouAreDueToRedeem: "Gutt gemaach! Dir sidd ze läschen",
    toGetYourRewardNextVisit:
      "fir Är Belounung bei Ärem nächste Besuch ze kréien. Merci",
    pointsOnThe: "Punkten op der",
    morePointsToRedeem: "méi Punkten ze erléisen",
    showCode: "Code weisen",
    toGetYourRewardOnNextVisit:
      "fir Är Belounung bei Ärem nächste Besuch ze kréien. Merci",
    earn: "Verdéngen",
    delivaryNote: "Liwwerung Notiz",
    draftSales: "Entworf Verkaf",
    startDate: "Ufanks Datum",
    endDate: "Enn Datum",
    orders: "Bestellungen",
    checkout: "ofmellen",
    noProductItem: "Kee Produktartikel",
    selectProductImage: "Wielt Produktbild",
    selectCountry: "Wielt Land",
    selectRegion: "Wielt Staat/Regioun",
    printProductTag: "Dréckt Produkt Tag",
    transactionReference: "Transaktiounsreferenz",
    Cashier: "Keesseberäich",
    Manager: "Manager",
    Owner: "Besëtzer",
    Admin: "Admin",
    addPartners: "Add Partners",
    addNewLoyaltyPartner: "Add New Loyalitéit Partner",
    pleaseEnterCompanyName: "Gitt weg Firma Numm",
    companyName: "Firmen Numm",
    pleaseEnterCompanyRepName: "Gitt weg Firma Vertrieder Numm",
    companyRepName: "Numm vun der Firma Vertrieder",
    pleaseEnterCompanyRepEmail: "Gitt w.e.g. E-Mail vum Firmevertrieder un",
    companyRepEmail: "E-Mail vun der Firma Rep",
    pleaseEnterCompanyRepPhone: "Gitt weg Telefonsnummer vun Firma Vertrieder",
    companyRepPhone: "Telefonsnummer vun der Firma Rep",
    addReward: "Dobäizemaachen Belounung",
    pleaseEnterRewardName: "Gitt weg Belounung Numm",
    rewardName: "Belounung Numm",
    rewardQuantity: "Belounung Quantitéit",
    rewardDescription: "Belounung Beschreiwung",
    rewardType: "Belounung Typ",
    pleaseEnterRewardType: "Gitt weg Belounungstyp",
    pleaseEnterRewardQuantity: "Gitt w.e.g. Belounungsquantitéit un",
    pleaseEnterRewardDescription: "Gitt w.e.g. belonningsbeschreiwung",
    fineDining: "Schéin Iessen",
    luxuryFashion: "Luxus Moud",
    hotels: "Hoteler",
    travel: "Reesen",
    foodAndBeverage: "Iessen a Gedrénks",
    fashion: "Moud",
    health: "Gesondheet",
    furniture: "Miwwelen",
    entertainment: "Ënnerhaalung",
    automobile: "Automobil",
    education: "Educatioun",
    beautyAndSpa: "Schéinheet a Spa",
    staycation: "Staycation",
    events: "Evenementer",
    trips: "Reesen",
    oilAndGas: "Ueleg a Gas",
    laundry: "Wäsch",
    partnerCategory: "Partner Kategorie",
    freeItem: "Gratis Artikel",
  },
  Lithuanian: {
    cashier: "kasininkas",
    manager: "vadybininkas",
    owner: "savininkas",
    online: "prisijungęs",
    offline: "neprisijungęs",
    changePassword: "Pakeisti slaptažodį",
    currentPasswordMessage: "Įveskite dabartinį slaptažodį",
    passwordMessage: "Prašome įvesti savo slaptažodį",
    currentPassword: "Dabartinis slaptažodis",
    password: "Slaptažodis",
    confirmPasswordMessage: "Prašome patvirtinti slaptažodį!",
    confirmPassword: "Patvirtinti slaptažodį",
    sendViaEmail: "Siųsti el. Paštu",
    sendViaWhatsapp: "Siųsti per „WhatsApp“",
    downloadPdf: "Parsisiųsti PDF",
    paid: "mokama",
    unpaid: "neapmokėtas",
    partial: "dalinis",
    closeInvoice: "Ar norite uždaryti sąskaitą faktūrą?",
    closeInvoiceConfirmation:
      "Sąskaita faktūra gali būti neišsaugota. Ar norite uždaryti?",
    yes: "taip",
    no: "ne",
    invoice: "Sąskaita",
    wasDeducted: "buvo atimta",
    for: "dėl",
    item: "Prekė",
    addProduct: "Pridėti produktą",
    paymentReference: "Mokėjimo tvarka",
    amountPaid: "Sumokėta suma",
    discountAmount: "Nuolaidos suma",
    amountDue: "Mokėtina suma",
    amount: "Suma",
    dueDate: "Terminas",
    paymentType: "Mokėjimo tipas",
    card: "Kortelė",
    cash: "Pinigai",
    bankTransfer: "Banko pervedimas",
    paymentMessage: "Mokėjimo žinutė",
    description: "apibūdinimas",
    sendReceipt: "Siųsti kvitą",
    delete: "Ištrinti",
    save: "Sutaupyti",
    resend: "Persiūsti",
    saveAndSend: "Sutaupyti",
    invoiceSaved: "Sąskaita faktūra išsaugota!",
    selectPaymentMethod: "Prašome pasirinkti mokėjimo būdą!",
    selectCustomer: "Prašome pasirinkti klientą!",
    cartEmptyError:
      "Krepšelio sąrašas negali būti tuščias, uždarykite sąskaitą faktūrą ir įdėkite prekę į krepšelį!",
    subscriptionExpired:
      "Jūsų prenumeratos galiojimo laikas baigėsi, o jūsų paskyra dabar apribota. Norėdami atnaujinti paskyrą, spustelėkite žemiau esantį mygtuką",
    renew: "Atnaujinti",
    holdOn: "Palauk",
    customerBank: "Klientų bankas",
    cancel: "Atšaukti",
    selectACustomer: "Pasirinkite Klientas",
    invoiceSuccessfulPdfError:
      "Sąskaita faktūra sukurta sėkmingai, tačiau PDF generavimas užtrunka ilgiau nei įprastai. Netrukus patikrinkite.",
    downloadingInvoice: "Atsisiunčiama sąskaita faktūra",
    downloadingReceipt: "Atsisiunčiamas kvitas",
    whatsappReceiptError:
      "Siunčiant kvitą per „WhatsApp“ įvyko klaida. Bandykite dar kartą.",
    receiptToWhatsapp: "Kvitas persiųstas į „WhatsApp“",
    thankYouForPatronage: "Dėkojame už jūsų globą",
    hereIsYourReceipt: "Čia yra jūsų mokėjimo kvitas",
    errorSendingEmailReceipt:
      "Siunčiant kvitą el. Paštu įvyko klaida. Bandykite dar kartą arba susisiekite su palaikymo komanda",
    receiptSentToEmail: "Kvitas išsiųstas kliento el",
    invoiceSentToEmail: "Sąskaita buvo išsiųsta kliento el",
    invoiceSuccessWhatsappError:
      "Sąskaita faktūra sėkmingai sukurta, tačiau siunčiant į „WhatsApp“ įvyko klaida. Bandykite dar kartą sąskaitų faktūrų sąraše",
    invoiceSuccessfulEmailError:
      "Sąskaita faktūra sėkmingai sukurta, tačiau siunčiant el. Laišką įvyko klaida. Bandykite dar kartą iš sąskaitų faktūrų sąrašo",
    invoiceSentToWhatsapp: "Sąskaita persiųsta į „WhatsApp“",
    hello: "Sveiki",
    pleaseDownloadInvoice: "Prašome atsisiųsti sąskaitą faktūrą",
    pleaseDownloadReceipt: "Prašome atsisiųsti kvitą",
    from: "nuo",
    email: "Paštu",
    upgrade: "Patobulinti",
    youAreOnFreePlan: "Turite nemokamą planą.",
    clickOn: "Spustelėkite",
    yourPlanInFewSteps: " prenumeratą atlikdami kelis greitus veiksmus.",
    to: "į",
    yourSubscriptionHasExpired:
      "Jūsų prenumeratos galiojimo laikas baigėsi, o jūsų paskyra dabar apribota.",
    days: "dienų",
    yourSubscriptionExpiresIn:
      "„Loystar“ prenumeratos galiojimo laikas baigiasi",
    createAcount: "Sukurti paskyrą",
    signIn: "Prisijungti",
    continue: "Tęsti",
    enterOtp: "Įveskite OTP PIN kodą",
    enterValidOtp: "Įveskite galiojantį PIN kodą",
    pin: "PIN kodą",
    tokenSentToMail: "Jūsų el. Pašto adresas buvo išsiųstas",
    passwordResetSuccessful: "Slaptažodžio atstatymas buvo sėkmingas",
    somethingWentWrong: "Kažkas nepavyko!",
    resetPassword: "Atstatyti slaptažodį",
    iHaveResetCode: "Turiu slaptažodžio atkūrimo kodą",
    pleaseEnterEmail: "Įveskite savo el. Pašto adresą",
    aTokenWillBeSentToEmail: "Jūsų el. Pašto adresas bus išsiųstas",
    enterEmail: "Įvesk savo elektroninio pašto adresą",
    sendinYourToken: "Siunčiamas jūsų ženklas ...",
    makeSureItMatchesPassword:
      "Įsitikinkite, kad jis atitinka jūsų naują slaptažodį",
    pleaseChooseNewPassword: "Pasirinkite naują slaptažodį",
    chooseNewPassword: "Pasirinkite naują slaptažodį",
    enterNewPassword: "Įveskite naują slaptažodį, kad patvirtintumėte",
    enterTokenSent:
      "Įveskite prieigos raktą, kuriuo buvo išsiųstas jūsų laiškas",
    resetToken: "Iš naujo nustatyti raktą",
    resettingPassword: "Iš naujo nustatomas slaptažodis ...",
    signUp: "Registruotis",
    adminSignInWithEmail:
      " Administratorius prisijungia el. Paštu, o darbuotojai prisijungia naudodami vartotojo vardą.",
    pleaseEnterEmailOrUsername:
      "Įveskite savo el. Pašto adresą arba vartotojo vardą",
    emailOrUsername: "El. pastas arba vartotojo vardas",
    pleaseEnterPassword: "Įveskite slaptažodį",
    createAnAccount: "Sukurti paskyrą",
    forgotPassword: "Pamiršote slaptažodį?",
    enterPhoneNumber: "Įveskite telefono numerį",
    personalData: "Asmeniniai duomenys",
    validateConfirmationCOde: "Patvirtinkite patvirtinimo kodą",
    pleaseEnterFirstName: "Įveskite savo vardą",
    pleaseEnterPhoneNumber: "Įveskite savo telefono numerį",
    pleaseEnterLastName: "Įveskite savo pavardę",
    pleaseEnterBusinessName: "Įveskite savo įmonės pavadinimą",
    firstName: "Pirmas vardas",
    lastName: "Pavardė",
    businessName: "Verslo pavadinimas",
    previous: "Ankstesnis",
    next: "Kitas",
    pleaseSelectBusinessCategory: "Pasirinkite savo verslo kategoriją",
    pleaseEnterValidEmail: "Įveskite tinkamą el. Pašto adresą",
    pleaseEnterPostCode: "Įveskite pašto kodą",
    postCode: "Pašto kodas",
    phoneNumberInUse: "Šis telefono numeris jau naudojamas!",
    emailInUse: "Šis el. Paštas jau naudojamas!",
    foodAndDrinks: "Maistas ir gėrimai",
    salonAndBeauty: "Salonas ir grožis",
    fashionAndAccessories: "Mada ir aksesuarai",
    gymAndFitness: "Sporto salė ir fitnesas",
    travelAndHotel: "Kelionės ir viešbutis",
    homeAndGifts: "Namai ir dovanos",
    washingAndCleaning: "Skalbimas ir valymas",
    gadgetsAndElectronics: "Programėlės ir elektronika",
    groceries: "Maisto produktai",
    others: "Kiti",
    submit: "Pateikti",
    accountCreatedSuccessful: "Jūsų paskyra sėkmingai sukurta.",
    pleaseEnterAddress: "Įveskite savo adresą",
    addressLine1: "Adreso eilutė 1",
    addressLine2: "Adreso eilutė 2",
    choosePassword: "Pasirinkti slaptažodį",
    passwordMustBe6Characters:
      "Slaptažodį turi sudaryti mažiausiai 6 simboliai.",
    howDidYouHearLoystar: "Kaip sužinojote apie „Loystar“?",
    referralCode: "persiuntimo kodas",
    byClickingTheButton: " Spustelėję žemiau esantį mygtuką, sutinkate su",
    termsAndSevice: "Sąlygos",
    wereCreatingAccount: "Mes kuriame jūsų paskyrą",
    proceed: "Tęsti",
    verificationCodeMustBe6: "Patvirtinimo kodą turi sudaryti 6 skaitmenys",
    pleaseEnter6DigitCode: "Įveskite 6 skaitmenų kodą",
    enterVerificationCode: "Įveskite patvirtinimo kodą",
    resendToken: "Iš naujo siųsti žetoną",
    verify: "Patvirtinti",
    transactions: "Sandoriai",
    todaySales: "Šiandienos išpardavimai",
    salesHistory: "Pardavimų istorija",
    supplyHistory: "Tiekimo istorija",
    new: "Naujas",
    invoices: "Sąskaitos",
    disbursements: "Išmokos",
    offlineSales: "Pardavimas neprisijungus",
    products: "Produktai",
    customers: "Klientai",
    multiLevelLoyalty: "Daugiapakopis lojalumas",
    loyaltyPrograms: "Lojalumo programos",
    members: "Nariai",
    appStore: "Programėlių parduotuvė",
    orderMenu: "Užsakymų meniu",
    settings: "Nustatymai",
    staffAndBranches: "Štabai ir filialai",
    myAccount: "Mano sąskaita",
    switchToSellMode: "Perjunkite į pardavimo režimą",
    signOut: "Atsijungti",
    getFreeSubscription: "Gaukite nemokamą prenumeratą",
    onlyNumbersAllowed: "Leidžiami tik skaičiai",
    yourAccountMustBe10Digits: "jūsų sąskaitos numeris turi būti 10 skaitmenų",
    yourBvnMustBe11: "jūsų BVN turi būti 11 skaitmenų",
    pleaseSelectBank: "Pasirinkite banką",
    selectYourBank: "Pasirinkite savo banką",
    enterBankAccountNumber: "Įveskite banko sąskaitos numerį",
    enterBvnNumber: "Įveskite savo BVN",
    connectBankAccount: "Prijunkite banko sąskaitą",
    passwordResetSuccessfulAndSignOut:
      "Jūsų slaptažodis sėkmingai nustatytas iš naujo. Jei norite vėl prisijungti, spustelėkite žemiau esantį mygtuką Atsijungti",
    enterCurrentPassword: "Įveskite dabartinį slaptažodį",
    pleaseEnterCurrentPassword: "Įveskite dabartinį slaptažodį",
    phoneNumber: "Telefono numeris",
    sex: "Seksas",
    dateOfBirth: "Gimimo data",
    state: "Valstija",
    country: "Šalis",
    loyaltyId: "Lojalumo ID",
    createdAt: "Sukurta At",
    noOfTransactionsMade: "Atliktų sandorių skaičius",
    yourDownloadWillStart: "Atsisiuntimas prasidės akimirksniu",
    exportCustomers: "Eksportuoti klientus",
    youhaveSuccessfullyToppedUp: "Sėkmingai papildėte savo SMS vienetus.",
    youNowHave: "Dabar turi",
    smsUnits: "SMS vienetai",
    enterNumberOfSmsUnits:
      "Įveskite SMS vienetų, kuriuos ketinate įsigyti, skaičių",
    pleaseEnterANumericValue: "Įveskite skaitinę reikšmę",
    pay: "Mokėti",
    accountEditedSuccessfully: "Jūsų paskyra sėkmingai redaguota.",
    youAeCurrentlyOn: "Šiuo metu esate įjungtas",
    plan: "Planuoti",
    userData: "Vartotojo duomenys",
    businessData: "VERSLO DUOMENYS",
    businessCategory: "Verslo kategorija",
    pleaseSelectCurrency: "Pasirinkite savo valiutą",
    selectYourCurrency: "Pasirinkite valiutą",
    purchaseMoreSmsUnits:
      "Įsigykite daugiau SMS įrenginių naudodami žemiau esančią formą",
    youHave: "Tu turi",
    atLeast4SmsUnitsRrequired:
      "Patvirtinimui reikia bent 4 sms vienetų, papildykite!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Patikrinkite savo banko sąskaitą, kad ji atitiktų „Pažink savo klientą“ (KYC) reikalavimus. Tai leis jums rinkti mokėjimus per USSD arba momentinį pervedimą, gauti užsakymus iš klientų ir apdoroti atsiskaitymus už operacijas. Taikoma tik Nigerijos prekybininkams. Norėdami pradėti, paspauskite žemiau esantį mygtuką.",
    reConnectBankAccount: "Iš naujo prijunkite banko sąskaitą",
    accountName: "Paskyros vardas",
    accountNumber: "Paskyros numeris",
    bankName: "Banko pavadinimas",
    verified: "Patvirtinta",
    accountDetails: "Išsami paskyros informacija",
    kycBankAccount: "KYC",
    createTier: "Sukurti pakopą",
    fileUploadSuccessful: "failas sėkmingai įkeltas",
    fileUploadFailed: "failo įkėlimas nepavyko",
    createLoyaltyProgram: "Sukurkite lojalumo programą",
    createLoyalty: "Sukurti lojalumą",
    name: "vardas",
    loyaltyArtwork: "Lojalumo kūrinys",
    clickToUpload: "Spustelėkite norėdami įkelti",
    amountToPointsRatio: "Sumos ir taškų santykis",
    points: "Taškai",
    recommendedAmountToPointRatio:
      "Rekomenduojama: nuo 1 iki 1 taško. y., už kiekvieną išleistą 1 „Naira“ jūsų klientai gauna 1 tašką",
    pleaseTypeIn: "Prašome įvesti",
    toDeleteLoyalty: "panaikinti šią lojalumą",
    deleteLoyalty: "Ištrinti lojalumą",
    toConfirm: "patvirtinti",
    edit: "Redaguoti",
    pointsAwardedSuccessfully: "Sėkmingai įteikti taškai.",
    enterPointValueToAward:
      "Įveskite taško vertę, kurią norėtumėte apdovanoti klientus",
    award: "Apdovanojimas",
    awardPointValuesToCustomer: "Suteikite tašką klientui",
    enrollMembersToLoyalty: "Užregistruokite narius lojalumui",
    awardPoints: "Apdovanojimo taškai",
    enroll: "Užsiregistruoti",
    home: "Namai",
    loyalty: "Lojalumas",
    enrollCustomers: "Užsiregistruokite klientus",
    selected: "Pasirinkta",
    customer: "Klientas",
    loyaltyActivationSuccessful: "Lojalumo aktyvinimas sėkmingas.",
    loyaltyDeactivationSuccessful: "Lojalumo deaktyvavimas sėkmingas.",
    viewTier: "Peržiūrėti pakopą",
    deactivate: "Išjungti",
    activate: "Suaktyvinti",
    actions: "Veiksmai",
    nameOfLoyalty: "Lojalumo vardas",
    loyaltyStatus: "Lojalumo būsena",
    numberOfTiers: "Pakopų skaičius",
    createdOn: "Sukurtas",
    createATier: "Sukurkite pakopą",
    stopCreatingTierConfirmation: "Ar norite nustoti kurti pakopą?",
    stopEditingTierConfirmation: "Ar norite nustoti redaguoti šią pakopą?",
    nameOfTier: "Pakopos pavadinimas",
    minimumSpendingTarget: "Minimalus išlaidų tikslas",
    maximumSpendingTarget: "Maksimalus išlaidų tikslas",
    minimumSpendingTargetRequired: "reikalingas minimalus išlaidų tikslas",
    maximumSpendingTargetRequired: "reikalingas maksimalus išlaidų tikslas",
    rewardSponsor: "Apdovanojimų rėmėjas",
    pleaseChooseARewardSponsor: "Prašome pasirinkti apdovanojimo rėmėją",
    self: "Savarankiškai",
    partner: "Partneris",
    rewardPartner: "Atlygio partneris",
    pleaseSelectRewardPartner: "Pasirinkite atlygio partnerį",
    rewards: "Apdovanojimai",
    pleaseSelectAReward: "Pasirinkite atlygį",
    instructionsOnRedeemingReward:
      "Instrukcijos, kaip klientas turėtų išpirkti premiją",
    pleaseFillInThisField: "Prašome užpildyti šį lauką!",
    toDeleteThisTier: " ištrinti šią pakopą",
    deleteTier: "Ištrinti pakopą",
    viewMembers: "Peržiūrėti narius",
    membersEnrolled: "Užsiregistravę nariai",
    instruction: "Instrukcija",
    membersIn: "Įeinantys nariai",
    availableTiersInLoyalty: "Pasiekiama lojalumo programos pakopa (-os)",
    tiers: "Pakopos",
    totalTier: "IŠ VISO PASIŪLYMO",
    availableLoyaltyProgramme: "Galima lojalumo programa",
    totalLoyalties: "IŠ VISO LOJALUMO",
    memberDetails: "Išsami informacija apie narį",
    nameOfCustomer: "Kliento vardas",
    address: "Adresas",
    allEnrolledMembers: "Visi užsiregistravę nariai",
    thisIsToWishYouHappyBirthday:
      "Tai linkiu jums labai laimingo gimtadienio ir klestinčio gyvenimo. Dėkojame už viską, ką esate Loyster. Linksmų švenčių!",
    inputAnOfferPlease: "Prašome įvesti pasiūlymą",
    pleaseSelectTheInsertPoint:
      "Pranešime pasirinkite įterpimo tašką ir spustelėkite dar kartą",
    birthdayOfferAndMessage: "Gimtadienio pasiūlymas ir žinutė",
    birthdayOffer: "Gimtadienio pasiūlymas",
    birthdayMessage: "Gimtadienio žinutė",
    noOfferFound: "Pasiūlymo nerasta",
    settingABirthdayOffer:
      "Nustatę gimtadienio pasiūlymą, klientai per gimtadienį gali gauti šį pasiūlymą SMS žinute",
    createOffer: "Sukurti pasiūlymą",
    whatIsTheOffer: "Koks yra pasiūlymas?",
    editMessage: "Redaguoti pranešimą",
    insert: "Įdėti",
    theNameOfCustomerInserted: "Čia bus įterptas kliento vardas",
    theBirtdayOfferInserted: "Gimtadienio pasiūlymas bus įterptas čia",
    youSuccessfullyAddedNewBranch: "Sėkmingai pridėjote naują filialą!",
    addNewBranch: "Pridėti naują filialą",
    addBranch: "Pridėti filialą",
    additionalBranchWillIncur: "Atsirastų papildomas skyrius",
    perBranch: "už šaką",
    ecommerceBranchCosts: "Elektroninės prekybos filialo išlaidos",
    pleaseEnterBranchName: "Įveskite filialo pavadinimą",
    pleaseEnterBranchAddress1: "Įveskite filialo adreso eilutę 1",
    enterBranchAddress1: "Įveskite filialo adreso eilutę 1",
    enterBranchAddress2: "Įveskite filialo adreso eilutę 1",
    pleaseEnterBranchAddress2: "Įveskite filialo adreso eilutę 2",
    enterBranchName: "Įveskite filialo pavadinimą",
    successfullyAddedStaff: "Sėkmingai pridėjote naują personalą!",
    addNewStaff: "Pridėti naujų darbuotojų",
    addStaff: "Pridėti darbuotojų",
    additionalStaffWillIncur: "Atsirastų papildomų darbuotojų",
    perStaff: "vienam personalui.",
    pleaseEnterStaffEmail: "Įveskite darbuotojų el",
    pleaseEnterStaffUsername: "Įveskite personalo vartotojo vardą",
    pleaseEnterStaffPassword: "Įveskite personalo slaptažodį",
    pleaseSelectStaffRole: "Prašome pasirinkti personalo vaidmenį",
    selectStaffRole: "Pasirinkite personalo vaidmenį",
    enterStaffEmail: "Įveskite darbuotojų el",
    enterStaffUsername: "Įveskite personalo vartotojo vardą",
    enterStaffPassword: "Įveskite personalo slaptažodį",
    spacesNotAllowedInUsername: "tarpai neleidžiami naudotojo varde",
    admin: "Administratorius",
    pleaseSelectBusinessToAttachStaff:
      "Pasirinkite įmonę, kuriai pridėti darbuotojų",
    searchForBranchToAttachStaff: "Ieškokite filialo darbuotojams prijungti",
    username: "Vartotojo vardas",
    role: "Vaidmuo",
    areYouSureToDeleteThis: "Ar tikrai tai ištrinsite",
    branches: "Filialai",
    upgradeYourPlan: "Atnaujinkite savo planą.",
    add: "PAPILDYTI",
    addNew: "Pridėti naują",
    customerWithEmailAlreadyExists:
      "Klientas su el. Pašto adresu/telefono numeriu jau yra!",
    successfullyAddedNewCustomer: "Sėkmingai pridėjote naują klientą!",
    addCustomer: "Pridėti klientą",
    pleaseEnterCustomerFirstName: "Įveskite kliento vardą",
    pleaseEnterCustomerLastName: "Įveskite kliento pavardę",
    pleaseEnterCustomerPhoneNumber: "Įveskite kliento telefono numerį",
    pleaseEnterCustomerEmail: "Įveskite kliento el",
    pleaseEnterCustomerAddressLine: "Įveskite kliento adreso eilutę",
    pleaseEnterCustomerOtherAddress: "Įveskite kitą kliento adresą",
    pleaseSelectCustomerGender: "Pasirinkite kliento lytį",
    gender: "Lytis",
    male: "Patinas",
    female: "Moteris",
    bank: "bankas",
    selectBank: "Pasirinkite Bankas",
    stateOrRegionOrProvince: "Valstybė/regionas/provincija",
    customerNotes: "Kliento pastabos",
    sendSms: "Siųsti SMS",
    editCustomer: "Redaguoti klientą",
    redeemReward: "Išpirkti premiją",
    issueLoyaltyCard: "Išduokite lojalumo kortelę",
    deleteCustomer: "Ištrinti klientą",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Sėkmingai priskyrėte lojalumo narystės ID",
    noMembershipIdAvailable:
      "Nėra narystės ID. Prašome susisiekti su mumis hello@loystar.co",
    sendEmail: "Siųsti laišką",
    membershipPoints: "Narystės taškai",
    customerDetails: "Informacija apie klientą",
    close: "Uždaryti",
    loyaltyBalance: "Lojalumo balansas",
    pointsBalance: "Taškų balansas",
    starBalance: "Žvaigždžių balansas",
    requiredPoints: "Reikalingi taškai",
    requiredStars: "Būtinos žvaigždės",
    reddemCode: "Išpirkti kodą",
    toDeleteThisCustomer: "ištrinti šį klientą",
    customerHasBeenDeletedSuccessfully: "Klientas sėkmingai ištrintas!",
    customerWithPhoneAlreadyExists: "Klientas su telefono numeriu jau yra",
    customerWasSuccessfullyEdited: "Klientas sėkmingai redaguotas",
    anErrorOccured: "Įvyko klaida",
    phoneNumberIncludeCountry: "Telefono numeris (įtraukite šalies kodą)",
    yourFileQueuedForUpload:
      "Jūsų failas buvo įkeltas į eilę. Po kelių sekundžių atnaujinkite puslapį.",
    thereWasAnErrorPerformingOperation: "Atliekant operaciją įvyko klaida!",
    pleaseSelectFile: "Prašome pasirinkti failą!",
    oopsOnlyCsvFilesAllowed:
      "Oi! Leidžiami tik CSV failai. Įkelkite .csv failą.",
    fileShouldBeSmallerThan5Mb:
      "Failas turi būti mažesnis nei 5 MB. Jei turite didesnį failą, rašykite el. Paštu support@loystar.co. Ačiū",
    customerFirstNameIsRequired: "eilutėje reikia nurodyti kliento vardą",
    customerPhoneNumberIsRequired:
      "eilutėje reikia nurodyti kliento telefono numerį",
    importCustomers: "Importuoti klientus",
    upload: "Įkelti",
    clickIconToDownloadCsv:
      "Spustelėkite šią piktogramą, kad atsisiųstumėte CSV failo šabloną.",
    getGoogleSheetVersion: "Gaukite „Google“ lapo versiją",
    clickOrDragFileToUpload:
      "Norėdami įkelti, spustelėkite arba vilkite failą į šią sritį",
    missingOrInvalidColumnHeader:
      "Trūksta arba netinkama stulpelio antraštė. Prašome laikytis šablono formato. Ačiū.",
    youHaveImported: "Jūs importavote",
    youSuccessfullyRedeemedReward: "Sėkmingai išpirkote premiją!",
    sixDigitCode: "Šešių skaitmenų kodas",
    pleaseEnterCustomerRewardCode: "Įveskite kliento premijos kodą",
    enterRewardCode:
      "Įveskite atlygio kodą. (Atlyginimo kodas skiria didžiąsias ir mažąsias raides)",
    selectLoyaltyProgram: "Pasirinkite lojalumo programą",
    stamps: "Antspaudai",
    smsUnitsLow: "Žema SMS vienetų dalis",
    pleaseTopUpSmsUnits: "Prašome papildyti SMS vienetus",
    smsSuccessfullySentForDelivery: "SMS sėkmingai išsiųstas pristatymui!",
    sendSmsTo: "Siųsti SMS į",
    allCustomers: "Visi klientai",
    unitsAvailable: "Galimi vienetai",
    pleaseTypeInTheMessage: "Prašome įvesti pranešimą",
    message: "Pranešimas",
    charactersRemaining: "likę simboliai",
    avoidUseOfSpecialCharacters:
      "Nenaudokite specialių simbolių ir jaustukų, kad išsaugotumėte SMS vienetus.",
    note: "Pastaba",
    errorFetchingCustomersMultilevelDetail:
      "Klaida gaunant klientų daugiapakopę informaciją",
    search: "Paieška",
    reset: "Iš naujo nustatyti",
    importCustomer: "Importuoti klientą",
    addNewCustomer: "Pridėti naują klientą",
    sendSmsBroadcast: "Siųsti SMS transliaciją",
    totalCustomers: "IŠ VISO KLIENTŲ",
    disbursementDetails: "Išsami informacija apie išmokėjimą",
    paidBy: "Mokėjo",
    disbursed: "Išmokėta",
    bankAccountName: "Banko sąskaitos pavadinimas",
    bankAccountNumber: "Banko sąskaitos numeris",
    transferInitiated: "Pradėtas perdavimas",
    transferCompleted: "Perkėlimas baigtas",
    pleaseEnterAValid: "Įveskite galiojantį",
    begin: "pradėti",
    end: "galas",
    date: "data",
    paymentDate: "Mokėjimo diena",
    selectDisbursementDuration: "Pasirinkite Išmokos trukmė",
    totalSettled: "Iš viso nusistovėjęs",
    totalUnsettled: "Iš viso nesutvarkyta",
    toDeleteThisSale: "ištrinti šį išpardavimą",
    draftSaleDeletedSuccessfully: "Pardavimo juodraštis sėkmingai ištrintas!",
    deleteSale: "Ištrinti išpardavimą",
    pleaseTypeInYourTotal: "Įveskite savo sumą, kad patvirtintumėte ištrynimą",
    billDetails: "Sąskaitos detalės",
    printBill: "Spausdinti Bilą",
    servedBy: "Tarnauja",
    total: "Iš viso",
    createdDate: "Sukūrimo data",
    createdTime: "Sukurtas laikas",
    status: "Būsena",
    loginSuccessful: "Prisijungimas sėkmingas",
    pleaseWaitWhileWeConnectAccount: "Palaukite, kol prijungsime jūsų paskyrą",
    connectionFailedTryAgain:
      "Nepavyko prisijungti. Prašau, pabandykite dar kartą.",
    connectionSuccessful: "Ryšys sėkmingas",
    viewDetails: "Išsamiau",
    enable: "Įgalinti",
    free: "Laisvas",
    cost: "Kaina",
    visitWebsite: "Apsilankykite svetainėje",
    pleaseUpgradeYourPlanToPro:
      "Atnaujinkite savo planą į „Pro Plus“, kad įgalintumėte šią programą",
    connectYourBankAccount:
      "Prijunkite savo banko sąskaitą, kad galėtumėte priimti mokėjimus.",
    disable: "Išjungti",
    enableApp: "Įgalinti programą",
    addNewProductToInvoice: "Pridėti naują produktą prie sąskaitos faktūros",
    toDeleteThisInvoice: "ištrinti šią sąskaitą faktūrą",
    invoiceDeletedSuccessfully: "Sąskaita faktūra sėkmingai ištrinta!",
    deleteInvoice: "Ištrinti sąskaitą faktūrą",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Sėkmingai pridėjote pranešimą apie sąskaitą faktūrą!",
    pleaseEnterPaymentInstructions: "Įveskite mokėjimo nurodymus",
    invoiceId: "Sąskaitos faktūros ID",
    paidDate: "Mokama data",
    reference: "Nuoroda",
    productAdded: "Produktas pridėtas",
    productOutOfStock: "Prekės nėra. Prašome papildyti atsargas.",
    totalInvoices: "IŠ VISO SĄSKAITŲ",
    totalPaidInvoices: "IŠ VISO MOKAMŲ SĄSKAITŲ",
    totalUnpaidInvoices: "IŠ VISO neapmokėtų sąskaitų",
    loyaltyProgramDeleted: "Lojalumo programa ištrinta",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ši lojalumo programa sėkmingai ištrinta",
    thisLoyaltyProgramEditedSuccessfully:
      "Ši lojalumo programa buvo sėkmingai redaguota",
    loyaltyProgramAddedSuccessfully: "Lojalumo programa sėkmingai pridėta",
    loyaltyProgramEdited: "Redaguota lojalumo programa",
    loyaltyProgramAdded: "Pridėta lojalumo programa",
    loyaltyDetails: "Išsami informacija apie lojalumą",
    doYouWantToCloseDialog: "Ar norite uždaryti šį dialogo langą?",
    pleaseEnterLoyaltyName: "Įveskite savo lojalumo pavadinimą",
    programType: "Programos tipas",
    pleaseSelectProgramType: "Pasirinkite programos tipą",
    simplePoints: "Paprasti taškai",
    stampsProgram: "Pašto ženklų programa",
    threshold: "Slenkstis",
    pleaseEnterLoyaltyThreshold: "Įveskite lojalumo slenkstį",
    reward: "Atlygis",
    pleaseEnterLoyaltyReward: "Įveskite lojalumo atlygį",
    totalUserPoints: "Iš viso naudotojo taškų",
    totalUserStamps: "Visi vartotojo antspaudai",
    spendingTarget: "Išlaidų tikslas",
    spendingTargetHint1:
      "Išlaidų tikslas yra tai, kiek klientas turi išleisti, kad gautų atlygį. 1 valiutos vertė = 1 taškas.",
    spendingTargetHint2:
      "Antspaudų tikslas - kiek pašto ženklų klientas turi surinkti, kad gautų atlygį. pvz. 5",
    addNewLoyaltyProgram: "Pridėti naują lojalumo programą",
    addLoyaltyProgram: "Pridėti lojalumo programą",
    loyaltyProgramType: "Lojalumo programos tipas",
    pleaseSelectLoyaltyProgramType: "Pasirinkite lojalumo programos tipą",
    nameOfProgram: "Programos pavadinimas",
    pleaseEnterProgramName: "Įveskite programos pavadinimą",
    whatIsTheReward: "Koks atlygis?",
    egNextPurchaseIsFree: "Pvz. Kitas pirkinys nemokamas",
    customerName: "Kliento vardas",
    guestCustomer: "Svečias klientas",
    orderConfirmedSuccessfully: "Užsakymas sėkmingai patvirtintas",
    orderCancelledSuccessfully: "Užsakymas sėkmingai atšauktas",
    confirmOrder: "Patvirtinti užsakymą",
    cancelOrder: "Atšaukti užsakymą",
    allOrders: "Visi užsakymai",
    totalOrders: "IŠ VISO UŽSAKYMŲ",
    doYouWantToAcceptOrder: "Ar norite priimti šį užsakymą?",
    doYouWantToCancelOrder: "Ar norite atšaukti šį užsakymą?",
    orderDetails: "Užsakymo informacija",
    orderCreatedAt: "Užsakymas sukurtas",
    supplier: "Tiekėjas",
    productName: "produkto pavadinimas",
    quantity: "Kiekis",
    unitPrice: "Vieneto kaina",
    receivedBy: "Gauta nuo",
    reportFrom: "Pranešimas iš",
    totalSupplies: "Iš viso atsargų",
    allRightsReserved: "Visos teisės saugomos",
    toDeleteThisTransaction: "ištrinti šią operaciją",
    transactionDeletedSuccessfully:
      "Operacija sėkmingai ištrinta. Atsargos grąžintos į atsargas.",
    deleteTransaction: "Ištrinti operaciją",
    transactionDetails: "Pervedimo duomenys",
    printReceipt: "Spausdinimo kvitas",
    channel: "Kanalas",
    discount: "Nuolaida",
    profit: "Pelnas",
    discountedSales: "Pardavimas su nuolaida",
    errorFetchingRecord: "Klaida gaunant įrašą",
    exportTransactions: "Eksporto operacijos",
    errorFetchingSalesRecord: "Klaida gaunant eksporto pardavimo įrašą.",
    totalSellingPrice: "Bendra pardavimo kaina",
    totalCostPrice: "Bendra savikainos kaina",
    appliedDiscount: "Taikoma nuolaida",
    noOfItems: "Daiktų skaičius",
    sales: "Pardavimai",
    export: "Eksportuoti",
    totalProfit: "Bendras pelnas",
    totalBalanceInPeriod: "Bendras laikotarpio likutis",
    allTimeSales: "Visų laikų pardavimai",
    records: "Įrašai",
    todaysSales: "Šiandienos išpardavimai",
    transaction: "sandorį",
    youHaveExceededTotalNumberOfProducts:
      "Viršijote bendrą plane leidžiamų produktų skaičių. Atnaujinkite savo planą, kad galėtumėte mėgautis didesne riba.",
    youNeedToHaveLoyaltyProgram:
      "Norėdami naudotis šia funkcija, turite turėti lojalumo programą!",
    price: "Kaina",
    category: "Kategorija",
    stockTracking: "Akcijų stebėjimas",
    stockCount: "Akcijų skaičius",
    taxed: "Apmokestintas",
    originalPrice: "Pradinė kaina",
    costPrice: "Kaina Kaina",
    unit: "Vienetas",
    productImage: "Produkto vaizdas",
    taxRate: "Mokesčio tarifas",
    taxType: "Mokesčių rūšis",
    trackInventory: "Stebėti inventorių",
    variants: "Variantai",
    hasVariants: "Turi Variantų",
    importProduct: "Importuoti produktą",
    exportProducts: "Eksportuoti produktus",
    addNewProduct: "Pridėti naują produktą",
    viewCategory: "Peržiūrėti kategoriją",
    viewSuppliers: "Peržiūrėkite tiekėjus",
    receiveInventory: "Gaukite inventorių",
    printAllProductsTag: "Spausdinti visų produktų žymą",
    deleteAll: "Ištrinti viską",
    totalProducts: "IŠ VISO PRODUKTŲ",
    youveSuccessfullyAddedANewCategory: "Sėkmingai pridėjote naują kategoriją",
    addNewCategory: "Pridėti naują kategoriją",
    addCategory: "Pridėti kategoriją",
    categoryName: "Kategorijos pavadinimas",
    pleaseEnterCategoryName: "Įveskite kategorijos pavadinimą",
    stampsTarget: "Antspaudai Tikslas",
    sendInventory: "Siųsti inventorių",
    productDetails: "Produkto Aprašymas",
    youveSuccessfullyEditedThisCategory: "Sėkmingai redagavote šią kategoriją",
    update: "Atnaujinti",
    categoryList: "Kategorijų sąrašas",
    categories: "Kategorijos",
    enterQuantityToUpdate: "Įveskite atnaujintiną kiekį",
    inventorySentSuccessfully: "Inventorius sėkmingai išsiųstas!",
    updateInventory: "Atnaujinti inventorių",
    currentQuantity: "Dabartinis kiekis",
    pleaseEnterQuantityToAdd: "Įveskite norimą pridėti kiekį",
    pleaseSelectABranch: "Pasirinkite filialą",
    searchForBranch: "Ieškoti filialo",
    youCantSendMoreThanStock: "Negalite siųsti daugiau, nei turite sandėlyje",
    send: "Siųsti",
    pleaseEnterQuantityToSend: "Įveskite kiekį, kurį norite išsiųsti",
    productNameIsRequiredOnRow: "eilutėje būtinas produkto pavadinimas",
    productCategoryIsRequiredOnRow:
      "eilutėje būtina nurodyti produkto kategoriją",
    productPriceIsRequiredOnRow: "eilutėje būtina nurodyti produkto kainą",
    productUnitIsRequiredOnRow: "eilutėje būtinas produkto vienetas",
    productQuantityIsRequiredOnRow: "eilutėje reikia nurodyti produkto kiekį",
    productVariantsRequireTracking: "produkto variantus reikia stebėti!",
    pleaseAddVariantClickButton:
      "Prašome pridėti variantą spustelėdami mygtuką Pridėti!",
    totalVariantsMoreThanSelectedQuantity:
      "Iš viso variantų yra daugiau nei pasirinktas produkto kiekis, Pls sumažina variantų kiekį",
    productEditedSuccessfully: "Produktas sėkmingai redaguotas!",
    fileTooLargeLessThan4Mb:
      "Failo dydis per didelis! Failo dydis turi būti mažesnis nei 4 MB.",
    suchVariantAlreadyExist: "Toks variantas jau yra",
    addVariants: "Pridėti variantų",
    editProduct: "Redaguoti produktą",
    pleaseEnterProductName: "Įveskite produkto pavadinimą",
    pleaseEnterProductPrice: "Įveskite produkto kainą",
    pleaseEnterProductOriginalPrice: "Įveskite pradinę produkto kainą",
    productDescription: "Prekės aprašymas",
    selectProductCategory: "Pasirinkite Produkto kategorija",
    pleaseSelectProductCategory: "Prašome pasirinkti produkto kategoriją",
    productCostPrice: "Produkto savikaina",
    productSellingPrice: "Produkto pardavimo kaina",
    productOriginalPrice: "Produkto pradinė kaina",
    maxFileSizeAllowedIs4Mb: "Maks. Leidžiamas failo dydis yra 4 MB",
    productsWithPicturesArePublished:
      "Produktai su nuotraukomis skelbiami „Discover“, kad gautumėte užsakymus",
    shouldThisProductBeTracked: "Ar šį produktą reikėtų stebėti?",
    pleaseSelectStockUnit: "Pasirinkite akcijų vienetą",
    stockUnit: "Akcijų vienetas",
    bag: "MAIŠAS",
    bottle: "BUTELIS",
    bunch: "BUNCH",
    can: "GALI",
    carton: "DĖŽUTĖ",
    crate: "KRATAS",
    cup: "TAURĖ",
    dozen: "DEZINAS",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAMA",
    litre: "LITER",
    pack: "PAKETAS",
    pair: "PORA",
    pieces: "DALYS",
    plate: "PLOKŠTĖ",
    tonne: "TONAS (MT)",
    uNIT: "VIENETAS",
    yard: "KIEMAS",
    pleaseEnterProductQuantity: "Įveskite produkto kiekį",
    productQuantity: "Produkto kiekis",
    isThisProductTaxed: "Ar šis produktas yra apmokestinamas?",
    selectTaxType: "Pasirinkite Mokesčių rūšis",
    pleaseSelectTaxType: "Pasirinkite mokesčio tipą",
    progressive: "Progresyvus",
    proportional: "Proporcingas",
    pleaseEnterProductTaxRate: "Įveskite produkto mokesčio tarifą",
    doesProductHaveVariants: "Ar šis produktas turi variantų?",
    type: "Tipas",
    value: "Vertė",
    pleaseEnterVariantType: "Įveskite varianto tipą",
    pleaseEnterVariantValue: "Įveskite varianto vertę",
    pleaseEnterVariantPrice: "Įveskite varianto kainą",
    pleaseEnterVariantQuantity: "Įveskite varianto kiekį",
    productDeletedSuccessfully: "Produktas sėkmingai ištrintas!",
    categoryDeletedSuccessfully: "Kategorija sėkmingai ištrinta!",
    toDeleteThisProduct: "ištrinti šį produktą",
    invalidProductQuantity: "Netinkamas produkto kiekis",
    quantityAddedIsOverStock:
      "Pridedamas kiekis viršija sandėlyje turimą kiekį.",
    itemInventoryNotTracked: "Prekių inventorius nestebimas",
    addBulkQuantity: "Pridėti masinį kiekį",
    enterBulkQuantity: "Įveskite masinį kiekį",
    pleaseEnterBulkQuantity: "Įveskite masinį kiekį",
    youveSuccessfullyAddedANewProduct: "Sėkmingai pridėjote naują produktą!",
    pleaseEnterProductSellingPrice: "Įveskite produkto pardavimo kainą",
    doYouWantToTrackProductStock: "Ar norite stebėti produktų atsargas?",
    sellingPrice: "Pardavimo kaina",
    setProductExpiryReminder:
      "Nustatykite produkto galiojimo pabaigos priminimą",
    productExpiryDate: "Produkto galiojimo data",
    startRemindingFrom: "Pradėti priminti nuo",
    productSuppliesAddedSuccessfully: "Sėkmingai pridėjote produktų atsargų.",
    addProductSupplies: "Pridėkite produktų atsargų",
    pleaseSelectSupplier: "Prašome pasirinkti tiekėją",
    nameOfProduct: "Produkto pavadinimas",
    pleaseSelectProduct: "Prašome pasirinkti produktą",
    productVariant: "Produkto variantas",
    pleaseSelectAVariant: "Prašome pasirinkti variantą",
    pleaseEnterUnitPrice: "Įveskite vieneto kainą",
    businessBranch: "Verslo skyrius",
    pleaseSelectBranch: "Prašome pasirinkti filialą",
    youveSuccessfullyAddedANewSupplier: "Sėkmingai pridėjote naują tiekėją",
    addSupplier: "Pridėti tiekėją",
    pleaseEnterSupplierEmail: "Įveskite tiekėjo el. Pašto adresą",
    pleaseAddADescription: "Pridėkite aprašymą",
    anErrorOccuredProductsDeleted:
      "Atliekant operaciją įvyko klaida. Atminkite, kad proceso metu galėjo būti ištrinti keli produktai",
    bulkDelete: "Masinis ištrynimas",
    youAreAboutToDelete: "Ketinate ištrinti",
    product: "Produktas",
    isDueToRedeem: "yra dėl išpirkimo",
    aReward: "apdovanojimas",
    pleaseSelectCustomerToReeemReward:
      "Pasirinkite klientą, kuris išpirks premiją.",
    youHaveNoLoyaltyProgramRunning: "Neturite aktyvios lojalumo programos",
    customerHhasNoPointsInLoyaltyProgram:
      "Klientas neturi taškų šioje lojalumo programoje",
    proceedWithPayment: "Tęsti mokėjimą?",
    youAreAboutToPayForTransactionUsingPoints:
      "Jūs ketinate sumokėti už operaciją naudodami taškus.",
    customerHas: "Klientas turi",
    worth: "verta",
    andIsNotSufficientToPayForTransaction:
      "ir to nepakanka sumokėti už šią operaciją. Ar jie norėtų pridėti likutį naudodami kitą mokėjimo metodą?",
    addCustomerLoyalty: "Pridėkite klientų lojalumą",
    pleaseAddCustomerFirst: "Pirmiausia pridėkite arba pasirinkite klientą.",
    pleaseWaitWhileWeGetReady: "Prašome palaukti, kol ruošiamės",
    lowStock: "Mažos atsargos",
    pleaseEnterAmountTendered: "Įveskite pasiūlytą sumą",
    areYouSureToBookSaleOffline:
      "Ar tikrai norite užsisakyti šį pardavimą neprisijungus?",
    saleWouldBeBookedAutomatically:
      "Pardavimas bus užsisakytas automatiškai, kai įjungsite internetą",
    offlineSalesBookingCancelled: "Pardavimų neprisijungus užsakymas atšauktas",
    covid19Message:
      "COVID19: Nusiplaukite rankas muilu arba dezinfekuokite, kad sustabdytumėte plitimą. Visada būkite saugūs",
    saleSuccessfullyRecorded: "Išpardavimas sėkmingai įrašytas!",
    sendReceiptToEmail: "Siųsti kvitą el",
    sendThankYouSms: "Siųsti ačiū SMS",
    sendShippingDetails: "Siųsti išsamią pristatymo informaciją",
    addLoyalty: "Pridėkite lojalumo",
    searchCustomerNameOrNumber: "Ieškokite kliento vardo ar numerio",
    clickTheSearchCustomerBox:
      "Spustelėkite Ieškoti kliento laukelyje ir nuskaitykite kortelę",
    enterProductPrice: "Įveskite produkto kainą",
    enterPriceFor: "Įveskite kainą",
    searchForProduct: "Ieškoti produkto",
    all: "Visi",
    backToDashboard: "Grįžti į informacijos suvestinę",
    viewDraftSales: "Peržiūrėti pardavimo juodraščius",
    variantSelected: "pasirinktas variantas",
    variant: "variantas",
    thePreviousVariantSelectionNotAvailable:
      "Ankstesnis variantas nepasiekiamas naujam variantui, pasirinktam pagal kainą, pakeiskite pasirinkimą.",
    pleaseSupplyPositiveQuantityNumber: "Pateikite pašto kiekį",
    lowStockFor: "Mažos atsargos",
    clearVariants: "Aiškūs variantai",
    pleaseEnterQuantity: "Įveskite kiekį",
    picture: "Paveikslas",
    redemptionToken: "Išpirkimo žetonas",
    token: "Žetonas",
    totalSpent: "Iš viso išleista",
    confirmPayment: "Patvirtinkite mokėjimą",
    hasPaymentBeenMade: "Ar mokėjimas buvo sėkmingai apdorotas?",
    enterTransactionReference: "Įveskite operacijos nuorodą, kuria mokėjote",
    pleaseSelectACustomer: "Prašome pasirinkti klientą!",
    areYouSureToApplyDiscount: "Ar tikrai norite taikyti nuolaidą?",
    addYourBankAccountToEnableUssd:
      "Pridėkite savo banko sąskaitą, kad įgalintumėte momentinį USSD pervedimą naudojant „uPay“",
    totalAmountToPay: "Bendra mokėtina suma",
    doYouWantToCancelTransaction: "Ar norite atšaukti šią operaciją?",
    savePrintBill: "Išsaugoti/spausdinti sąskaitą",
    enterAmountCollectedForCustomer: "Įveskite klientui surinktą sumą",
    recordSale: "Įrašų išpardavimas",
    checkOutWith: "Patikrinkite su",
    instantTransfer: "Momentinis perkėlimas",
    dialTheUSSDCode: "Surinkite USSD kodą",
    pleaseSelectABank: "Pasirinkite banką",
    payWithUSSD: "Mokėti naudojant USSD",
    sendBillTo: " - Siųsti Billą",
    waitingForUSSDTransfer: "Laukiama USSD perdavimo",
    percent: "Procentas",
    applyDiscount: "Taikyti nuolaidą",
    thisBillHasBeenSaved: "Šis sąskaita buvo išsaugota",
    saveDraft: "Išsaugoti juodraštį",
    pleaseTypeANameToIdentifyCustomer:
      "Įveskite vardą, kad identifikuotumėte klientą",
    paymentDetails: "Mokėjimo detalės",
    basePrice: "Bazinė kaina",
    staff: "Personalas",
    subTotal: "Tarpinė suma",
    durationMonths: "Trukmė (mėnesiai)",
    selectADuration: "Pasirinkite trukmę",
    oneMonth: "1 mėnuo",
    twoMonths: "2 mėnesiai",
    threeMonths: "3 mėnesiai",
    sixMonths: "6 mėnesiai",
    twelveMonths: "12 mėnesių",
    eighteenMonths: "18 mėnesių",
    twentyFourMonths: "24 mėnesiai",
    twoMonthsFree: "(2 mėnesiai nemokamai)",
    threeMonthsFree: "(3 mėnesiai nemokamai)",
    fiveMonthsFree: "(5 mėnesiai nemokamai)",
    yourAccountHasBeen: "Jūsų sąskaita buvo",
    renewed: "atnaujintas",
    upgraded: "atnaujintas",
    successfully: "sėkmingai",
    yourSubscription: "Jūsų prenumerata",
    youAreCurrentlyEnrolledOnA: "Šiuo metu esate užsiregistravęs a",
    pleaseChooseAPaymentOption: "Prašome pasirinkti mokėjimo parinktį",
    planRenewal: "Plano atnaujinimas",
    planUpgrade: "Plano atnaujinimas",
    pleaseSelectDurationToPayFor:
      "Pasirinkite trukmę, už kurią ketinate mokėti",
    staffAccounts: "Personalo sąskaitos",
    ecommerce: "El. Prekyba",
    pleaseSelectAPlan: "Pasirinkite planą",
    includeAddons: "Įtraukti priedus",
    viewTransactions: "Peržiūrėti operacijas",
    customerHasNoCompletedTansactions:
      "Klientas dar neatliko jokių dezinfekcijų",
    branch: "Filialas",
    enterNumberOfEcommerceBranches: "Įveskite el. Prekybos skyrių skaičių",
    enterNumberOfEcommerceBranchesToPay:
      "Įveskite el. Prekybos skyrių, už kuriuos ketinate mokėti, skaičių",
    ecommerceIntegration: "El. Prekybos integracija",
    enterNumberOfBranches: "Įveskite filialų skaičių",
    enterNumberOfAdditionalBranchesToPay:
      "Įveskite papildomų skyrių, už kuriuos ketinate mokėti, skaičių",
    enterNumberOfStaffs: "Įveskite personalo skaičių",
    enterNumberOfStaffsToPayFor:
      "Įveskite darbuotojų, už kuriuos ketinate mokėti, skaičių",
    discountApplies: "Taikoma nuolaida",
    starsOnThe: "žvaigždės ant",
    offer: "pasiūlymas",
    get: "Gauti",
    moreStarsToRedeem: "daugiau žvaigždžių išpirkti",
    taxVat: "Mokestis (PVM)",
    callCashierToCompletePayment:
      "Skambinkite kasininkui, kad atliktumėte mokėjimą",
    receipt: "Kvitas",
    dear: "Brangusis",
    thankYouForYourOrderFindGoods:
      "Ačiū už jūsų užsakymą. Pagal susitarimą ieškokite toliau pateiktų prekių.",
    shippingNote: "Pristatymo pastaba",
    enterShippingNote: "Įveskite pristatymo pastabą",
    shippingAddress: "Pristatymo adresas",
    enterShippingAddress: "Įveskite pristatymo adresą",
    wellDoneYouAreDueToRedeem: "Šauniai padirbėta! Jūs turite išpirkti",
    toGetYourRewardNextVisit:
      "kad gautumėte atlygį kito apsilankymo metu. Ačiū",
    pointsOnThe: "Taškai ant",
    morePointsToRedeem: "daugiau taškų, kuriuos reikia išpirkti",
    showCode: "Rodyti kodą",
    toGetYourRewardOnNextVisit:
      "kad gautumėte atlygį kito apsilankymo metu. Ačiū",
    earn: "Uždirbti",
    delivaryNote: "Pristatymo pastaba",
    draftSales: "Pardavimo juodraštis",
    startDate: "Pradžios data",
    endDate: "Pabaigos data",
    orders: "Užsakymai",
    checkout: "atsiskaityti",
    noProductItem: "Nėra produkto elemento",
    printProductTag: "Spausdinti produkto žymą",
    transactionReference: "Sandorio nuoroda",
    Cashier: "Kasininkė",
    Manager: "Vadybininkas",
    Owner: "Savininkas",
    Admin: "Administratorius",
    addPartners: "Pridėti partnerių",
    addNewLoyaltyPartner: "Pridėti naują lojalumo partnerį",
    pleaseEnterCompanyName: "Įveskite įmonės pavadinimą",
    companyName: "Įmonės pavadinimas",
    pleaseEnterCompanyRepName: "Įveskite įmonės atstovo vardą",
    companyRepName: "Įmonės atstovo vardas",
    pleaseEnterCompanyRepEmail: "Įveskite įmonės atstovo el",
    companyRepEmail: "Įmonės atstovo el",
    pleaseEnterCompanyRepPhone: "Įveskite įmonės atstovo telefono numerį",
    companyRepPhone: "Įmonės atstovo telefono numeris",
    addReward: "Pridėkite atlygį",
    pleaseEnterRewardName: "Įveskite premijos pavadinimą",
    rewardName: "Atlygio pavadinimas",
    rewardQuantity: "Atlygio kiekis",
    rewardDescription: "Atlygio aprašymas",
    rewardType: "Atlygio tipas",
    pleaseEnterRewardType: "Įveskite premijos tipą",
    pleaseEnterRewardQuantity: "Įveskite premijos kiekį",
    pleaseEnterRewardDescription: "Įveskite premijos aprašymą",
    fineDining: "Puikus pietūs",
    luxuryFashion: "Prabangi mada",
    hotels: "Viešbučiai",
    travel: "Kelionė",
    foodAndBeverage: "Maisto ir gėrimų",
    fashion: "Mada",
    health: "Sveikata",
    furniture: "Baldai",
    entertainment: "Pramogos",
    automobile: "Automobilis",
    education: "Išsilavinimas",
    beautyAndSpa: "Grožis ir SPA",
    staycation: "Apgyvendinimas",
    events: "Renginiai",
    trips: "Kelionės",
    oilAndGas: "Nafta ir dujos",
    laundry: "Skalbiniai",
    partnerCategory: "Partnerio kategorija",
    freeItem: "Nemokama prekė",
  },
  Malagasy: {
    cashier: "mpanambola vola",
    manager: "mpitantana",
    owner: "TOMPON'NY",
    online: "-tserasera",
    offline: "ivelan'ny aterineto",
    changePassword: "Hanova tenimiafina",
    currentPasswordMessage: "Ampidiro azafady ny tenimiafinao ankehitriny",
    passwordMessage: "Ampidiro azafady ny teny miafinao",
    currentPassword: "Tenimiafina ankehitriny",
    password: "Password",
    confirmPasswordMessage: "Azafady mba hamafiso ny teny miafinao!",
    confirmPassword: "Hamafiso ny tenimiafina",
    sendViaEmail: "Alefaso amin'ny alàlan'ny mailaka",
    sendViaWhatsapp: "Alefaso amin'ny alàlan'ny WhatsApp",
    downloadPdf: "Misintona PDF",
    paid: "karama",
    unpaid: "handraisam-bola",
    partial: "ampahany",
    closeInvoice: "Te hanidy ny faktiora ve ianao?",
    closeInvoiceConfirmation:
      "Mety tsy ho voatahiry ny faktiora. Te hikatona ve ianao?",
    yes: "ENY",
    no: "tsy misy",
    invoice: "faktiora",
    wasDeducted: "nesorina",
    for: "HO AN'NY",
    item: "zavatra",
    addProduct: "Add Product",
    paymentReference: "Fanovozan-karama",
    amountPaid: "Vola naloa",
    discountAmount: "Isan'ny fihenam-bidy",
    amountDue: "Vola tokony aloa",
    amount: "vola",
    dueDate: "Daty farany",
    paymentType: "Karazan-karama",
    card: "KARATRA ARA-BAIBOLY",
    cash: "Vola",
    bankTransfer: "Famindrana banky",
    paymentMessage: "Hafatra fandoavam-bola",
    description: "Description",
    sendReceipt: "Mandefa tapakila",
    delete: "Mamafa",
    save: "afa-tsy",
    resend: "Mandefa indray",
    saveAndSend: "afa-tsy",
    invoiceSaved: "Voatahiry ny faktiora!",
    selectPaymentMethod: "Misafidiana fomba fandoavam-bola azafady!",
    selectCustomer: "Misafidiana mpanjifa azafady!",
    cartEmptyError:
      "Tsy foana ny lisitry ny sarety, akio ny faktiora ary ampio Item amin'ny sarety!",
    subscriptionExpired:
      "Tapitra ny famandrihanao ary voafetra ny kaontinao izao. Tsindrio ny bokotra etsy ambany hanavao ny kaontinao",
    renew: "manavao",
    holdOn: "Andraso",
    customerBank: "Banky mpanjifa",
    cancel: "hanafoana",
    selectACustomer: "Safidio ny mpanjifa",
    invoiceSuccessfulPdfError:
      "Noforonina soa aman-tsara ny faktiora fa ny PDF Generation dia elaela kokoa noho ny mahazatra. Azafady mba avereno jerena vetivety.",
    downloadingInvoice: "Misintona faktiora",
    downloadingReceipt: "Misintona ny rosia",
    whatsappReceiptError:
      "Nisy lesoka nitranga tamin'ny fandefasana ny tapakila tamin'ny alàlan'ny WhatsApp, andramo indray.",
    receiptToWhatsapp: "Naroso tamin'ny WhatsApp ny rosia",
    thankYouForPatronage: "Misaotra tamin'ny fanohanananao",
    hereIsYourReceipt: "Ity ny tapakila fandoavam-bola",
    errorSendingEmailReceipt:
      "Nisy lesoka nitranga tamin'ny fandefasana ny tapakila tamin'ny alàlan'ny mailaka, andramo indray na mifandraisa fanampiana",
    receiptSentToEmail:
      "Ny tapakila dia nalefa tany amin'ny mailaky ny mpanjifa",
    invoiceSentToEmail: "Nalefa tamin'ny mailaky ny mpanjifa ny faktiora",
    invoiceSuccessWhatsappError:
      "Nahomby ny faktiora saingy nisy ny tsy fetezana nandefa ny WhatsApp. Manandrama indray amin'ny lisitry ny faktiora",
    invoiceSuccessfulEmailError:
      "Noforonina soa aman-tsara ny faktiora saingy nisy ny lesoka teo am-pandefasana azy ho mailaka. Andramo indray avy eo ny lisitra faktiora",
    invoiceSentToWhatsapp: "Navoaka tamin'ny WhatsApp ny faktiora",
    hello: "Salama",
    pleaseDownloadInvoice: "Azafady ampidino faktiora",
    pleaseDownloadReceipt: "Alefaso azafady ny tapakila",
    from: "From",
    email: "Mailaka",
    upgrade: "hanatsarana",
    youAreOnFreePlan: "Ao amin'ny drafitra maimaim-poana ianao.",
    clickOn: "Tsindrio eo",
    yourPlanInFewSteps: " ny famandrihanao amin'ny dingana fohy vitsivitsy.",
    to: "ny",
    yourSubscriptionHasExpired:
      "Tapitra ny famandrihanao ary voafetra ny kaontinao izao.",
    days: "andro",
    yourSubscriptionExpiresIn: "Tapitra ny fisoratana anarana Loystar ao",
    createAcount: "Hamorona kaonty",
    signIn: "Hiditra",
    continue: "hanohy",
    enterOtp: "Ampidiro ny PIN OTP",
    enterValidOtp: "Ampidiro ny PIN manan-kery",
    pin: "PIN",
    tokenSentToMail: "Nisy famantarana nalefa tamin'ny mailakao",
    passwordResetSuccessful: "Nahomby ny famerenana ny teny miafina",
    somethingWentWrong: "Nisy zavatra tsy nety!",
    resetPassword: "Reset Password",
    iHaveResetCode: "Manana kaody famerenana teny miafina aho",
    pleaseEnterEmail: "Ampidiro azafady ny mailakao",
    aTokenWillBeSentToEmail: "Hisy famantarana halefa amin'ny mailakao",
    enterEmail: "Ampidiro ny mailakao",
    sendinYourToken: "Mandefa ny tokenanao ...",
    makeSureItMatchesPassword:
      "Hamarino tsara fa mifanaraka amin'ny teny miafinao vaovao io",
    pleaseChooseNewPassword: "Safidio azafady tenimiafina vaovao",
    chooseNewPassword: "Misafidiana teny miafina vaovao",
    enterNewPassword: "Ampidiro ny tenimiafinao vaovao hanamafisana",
    enterTokenSent: "Ampidiro ny famantarana nalefa tamin'ny mailanao",
    resetToken: "Reset Token",
    resettingPassword: "Mamerina ny teny miafinao ...",
    signUp: "Hiditra Mpikambana",
    adminSignInWithEmail:
      " Misoratra anarana amin'ny mailaka ny mpitantana raha miditra amin'ny solonanarana ny Staff.",
    pleaseEnterEmailOrUsername:
      "Ampidiro azafady ny mailakao na ny solonanarana",
    emailOrUsername: "Email na Username",
    pleaseEnterPassword: "Ampidiro ny tenimiafina",
    createAnAccount: "Hamorona kaonty",
    forgotPassword: "Hadino ny tenimiafina?",
    enterPhoneNumber: "Ampidiro ny nomeraon-telefaona",
    personalData: "Data manokana",
    validateConfirmationCOde: "Fanamarinana kaody fanamafisana",
    pleaseEnterFirstName: "Ampidiro ny anaranao azafady",
    pleaseEnterPhoneNumber: "Ampidiro azafady ny nomeraon-telefaonanao",
    pleaseEnterLastName: "Ampidiro azafady ny anaranao",
    pleaseEnterBusinessName: "Ampidiro azafady ny anaranao",
    firstName: "Fanampin'anarana",
    lastName: "Anarana",
    businessName: "Anaran'ny orinasa",
    previous: "Teo aloha",
    next: "Manaraka",
    pleaseSelectBusinessCategory: "Safidio azafady ny sokajy asanao",
    pleaseEnterValidEmail: "Mampidira mailaka mety",
    pleaseEnterPostCode: "Ampidiro ny kaody post",
    postCode: "Kaody Post",
    phoneNumberInUse: "Efa ampiasaina io nomeraon-telefaona io!",
    emailInUse: "Efa ampiasaina io mailaka io!",
    foodAndDrinks: "Sakafo sy zava-pisotro",
    salonAndBeauty: "Salon sy Beauty",
    fashionAndAccessories: "Lamaody sy kojakoja",
    gymAndFitness: "Gym sy Fitness",
    travelAndHotel: "Travel sy Hotely",
    homeAndGifts: "Trano sy fanomezana",
    washingAndCleaning: "Manasa sy manadio",
    gadgetsAndElectronics: "Gajety sy fitaovana elektronika",
    groceries: "entana",
    others: "Ny hafa",
    submit: "manaiky",
    accountCreatedSuccessful: "Nahomby ny famoronana kaontinao.",
    pleaseEnterAddress: "Ampidiro azafady ny adiresinao",
    addressLine1: "Adiresy andalana 1",
    addressLine2: "Andalana faha-2",
    choosePassword: "Safidio ny teny miafina",
    passwordMustBe6Characters:
      "Ny teny miafina dia tokony ho litera 6 farafaharatsiny.",
    howDidYouHearLoystar: "Ahoana no nandrenesanao ny momba an'i Loystar?",
    referralCode: "Code referral",
    byClickingTheButton:
      " Amin'ny fipihana ny bokotra etsy ambany dia manaiky ny",
    termsAndSevice: "Anarana iombonana",
    wereCreatingAccount: "Mamorona ny kaontinao izahay",
    proceed: "ivoahan'ny",
    verificationCodeMustBe6: "Ny kaody fanamarinana dia tokony ho 6 isa",
    pleaseEnter6DigitCode: "Ampidiro kaody 6 isa azafady",
    enterVerificationCode: "Ampidiro ny kaody fanamarinana",
    resendToken: "Avereno ny famantarana",
    verify: "manamarina",
    transactions: "varotra",
    todaySales: "Varotra androany",
    salesHistory: "Tantaran'ny varotra",
    supplyHistory: "Tantaran'ny famatsiana",
    new: "Vaovao",
    invoices: "faktiora",
    disbursements: "Fanambarana",
    offlineSales: "Varotra ivelany",
    products: "Products",
    customers: "Mpanjifa",
    multiLevelLoyalty: "Fahatokiana amin'ny sehatra maro",
    loyaltyPrograms: "Fandaharana tsy mivadika",
    members: "Ireo mpikambana",
    appStore: "App Store",
    orderMenu: "Order Menu",
    settings: "Fikirana",
    staffAndBranches: "Ny mpiasa sy ny sampana",
    myAccount: "Ny kaontiko",
    switchToSellMode: "Mivadika ho Mode Sell",
    signOut: "Hivoaka",
    getFreeSubscription: "Makà famandrihana maimaim-poana",
    onlyNumbersAllowed: "Isa ihany no avela",
    yourAccountMustBe10Digits: "ny kaontinao dia tsy maintsy isa 10 isa",
    yourBvnMustBe11: "ny BVN anao dia tokony ho isa 11 isa",
    pleaseSelectBank: "Safidio azafady ny bankinao",
    selectYourBank: "Safidio ny Bankinao",
    enterBankAccountNumber: "Ampidiro ny kaonty Banky",
    enterBvnNumber: "Ampidiro ny BVN-nao",
    connectBankAccount: "Ampifandraiso ny kaonty Banky",
    passwordResetSuccessfulAndSignOut:
      "Ny tenimiafinao dia voaverina soa aman-tsara. Tsindrio ny bokotra fivoahana etsy ambany raha te hisoratra anarana indray",
    enterCurrentPassword: "Ampidiro ny teny miafina ankehitriny",
    pleaseEnterCurrentPassword:
      "Ampidiro ny tenimiafiko amin'izao fotoana izao",
    phoneNumber: "Nomeraon-telefaona",
    sex: "Firaisana Ara-nofo",
    dateOfBirth: "Daty nahaterahana",
    state: "FANJAKANA",
    country: "Firenena",
    loyaltyId: "ID tsy mivadika",
    createdAt: "Noforonina tamin'ny",
    noOfTransactionsMade: "No. ny fifampiraharahana natao",
    yourDownloadWillStart: "Hanomboka afaka fotoana fohy ny fampidinanao",
    exportCustomers: "Manondrana mpanjifa",
    youhaveSuccessfullyToppedUp:
      "Nahomby tamin'ny fanatsarana ny Units SMS anao ny anao.",
    youNowHave: "Manana izao ianao",
    smsUnits: "Units SMS",
    enterNumberOfSmsUnits: "Ampidiro ny isan'ny singa SMS kasainao hividy",
    pleaseEnterANumericValue: "Ampidiro azafady isa",
    pay: "vola",
    accountEditedSuccessfully: "Nahomby ny fanovana ny kaontinao.",
    youAeCurrentlyOn: "Mandeha ianao izao",
    plan: "ALAMINO MIALOHA",
    userData: "Data an'ny mpampiasa",
    businessData: "DATA BUSINESS",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Safidio azafady ny volanao",
    selectYourCurrency: "Safidio ny volanao",
    purchaseMoreSmsUnits:
      "Vidio Units SMS misimisy kokoa amin'ny fampiasana ny endrika etsy ambany",
    youHave: "Ianao dia manana",
    atLeast4SmsUnitsRrequired:
      "Farafahakeliny singa sms 4 farafahakeliny ilaina amin'ny fanamarinana, azafady mba ampio!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Azafady mba hamarino ny kaontinao amin'ny banky raha mifanaraka amin'ny fepetra takiana amin'ny 'Fantaro ny mpanjifanao' (KYC). Io dia hahafahanao manangona fandoavam-bola amin'ny alàlan'ny USSD na famindrana eo no ho eo, mahazo baiko avy amin'ny mpanjifa ary handamina ny fifampiraharahana. Azo ampiharina amin'ny mpivarotra nizeriana ihany. Tsindrio azafady ny bokotra etsy ambany hanombohana.",
    reConnectBankAccount: "Avereno ampifandraiso ny kaonty Banky",
    accountName: "Anaran'ny kaonty",
    accountNumber: "Nomeraon-kaonty",
    bankName: "Anaran'ny banky",
    verified: "Voamarina",
    accountDetails: "Antsipirian'ny kaonty",
    kycBankAccount: "KYC",
    createTier: "Mamorona Tier",
    fileUploadSuccessful: "nahomby ny fampidinana rakitra",
    fileUploadFailed: "tsy nahomby ny fampidinana rakitra",
    createLoyaltyProgram: "Mamorona programa tsy fivadihana",
    createLoyalty: "Mamorona Fahatokiana",
    name: "Anarana",
    loyaltyArtwork: "Asa tanana tsy mivadika",
    clickToUpload: "Tsindrio raha hampakatra",
    amountToPointsRatio: "Vidin'ny isa Ratio",
    points: "hevitra",
    recommendedAmountToPointRatio:
      "Recommended: ₦ 1 ka hatramin'ny 1point. ie isaky ny 1 Naira lany dia mahazo isa 1 ny mpanjifanao",
    pleaseTypeIn: "Azafady sorato",
    toDeleteLoyalty: "hamafana an'io fahatokiana io",
    deleteLoyalty: "Fafao ny tsy fivadihana",
    toConfirm: "manamarina",
    edit: "Ovay",
    pointsAwardedSuccessfully: "Hevitra nomena soa aman-tsara.",
    enterPointValueToAward: "Ampidiro ny sanda tadiavinao homena ny mpanjifa",
    award: "mari-pankasitrahana",
    awardPointValuesToCustomer: "Sarobidy ho an'ny mpanjifa",
    enrollMembersToLoyalty: "Ampidiro ny mpikambana ao amin'ny Fahatokiana",
    awardPoints: "Isa azo",
    enroll: "hosoratana",
    home: "an-trano",
    loyalty: "tsy fivadihana",
    enrollCustomers: "Ampidiro ny mpanjifa",
    selected: "voafantina",
    customer: "mpanjifa",
    loyaltyActivationSuccessful:
      "Fahombiazana ny fampandehanana ny tsy fivadihana.",
    loyaltyDeactivationSuccessful: "Mahomby ny famonoana ny tsy fivadihana.",
    viewTier: "Hijery Tier",
    deactivate: "De-Activate",
    activate: "hampavitrika",
    actions: "asa",
    nameOfLoyalty: "Anaran'ny Fahatokiana",
    loyaltyStatus: "Toetran'ny fahatokiana",
    numberOfTiers: "Isan'ny tady",
    createdOn: "Noforonina tamin'ny",
    createATier: "Mamorona Tier",
    stopCreatingTierConfirmation:
      "Te hijanona tsy hamorona ambaratonga ve ianao?",
    stopEditingTierConfirmation:
      "Te hampitsahatra ny fanovana an'ity dingana ity ve ianao?",
    nameOfTier: "Anaran'ny Tier",
    minimumSpendingTarget: "Tanjona fandaniana farafahakeliny",
    maximumSpendingTarget: "Tanjona fandaniana farany ambony",
    minimumSpendingTargetRequired:
      "Ilaina ny lasibatra fandaniana farafahakeliny",
    maximumSpendingTargetRequired: "ilaina ny tanjona kendrena indrindra",
    rewardSponsor: "Mpanohana valisoa",
    pleaseChooseARewardSponsor: "Misafidiana mpanohana valisoa azafady",
    self: "tena",
    partner: "Mpiara-miasa",
    rewardPartner: "Mpanome valisoa",
    pleaseSelectRewardPartner: "Safidio azafady ny namanao valisoa",
    rewards: "valisoa",
    pleaseSelectAReward: "Misafidiana valisoa azafady",
    instructionsOnRedeemingReward:
      "Torolàlana momba ny fomba tokony hanavotan'ny mpanjifa valisoa",
    pleaseFillInThisField: "Azafady fenoy ity saha ity!",
    toDeleteThisTier: " hamafana an'ity ambaratonga ity",
    deleteTier: "Fafao ny Tier",
    viewMembers: "Jereo ny mpikambana",
    membersEnrolled: "Mpikambana nisoratra anarana",
    instruction: "fampianarana",
    membersIn: "Mpikambana ao amin'ny",
    availableTiersInLoyalty: "Misy ny Tier (s) ao amin'ny Program Loyalty",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Fandaharana tsy mivadika",
    totalLoyalties: "FAHAMARINANA TOTAL",
    memberDetails: "Antsipirian'ny mpikambana",
    nameOfCustomer: "Anaran'ny mpanjifa",
    address: "Adiresy",
    allEnrolledMembers: "Mpikambana voasoratra anarana rehetra",
    thisIsToWishYouHappyBirthday:
      "Izany dia ny firariana anao ho tsingerintaona nahaterahana sy fiainana miroborobo. Misaotra noho izay rehetra anananao ho an'i Loyster. Fankalazana sambatra!",
    inputAnOfferPlease: "Ampidiro ny tolotra azafady",
    pleaseSelectTheInsertPoint:
      "Safidio azafady ny teboka ampidiro ao anaty hafatra ary kitiho indray",
    birthdayOfferAndMessage:
      "Tolotra sy hafatra amin'ny tsingerintaona nahaterahana",
    birthdayOffer: "Tolotra fitsingerenan'ny andro nahaterahana",
    birthdayMessage: "Hafatra fitsingerenan'ny andro nahaterahana",
    noOfferFound: "Tsy misy tolotra hita",
    settingABirthdayOffer:
      "Ny fametrahana tolotra fitsingerenan'ny andro nahaterahana dia ahafahan'ny mpanjifa mandray io tolotra io amin'ny alàlan'ny SMS amin'ny tsingerintaona nahaterahan'izy ireo",
    createOffer: "Mamorona tolotra",
    whatIsTheOffer: "Inona ny tolotra?",
    editMessage: "Hanova hafatra",
    insert: "Mampidira",
    theNameOfCustomerInserted: "Ampidirina eto ny anaran'ny mpanjifa",
    theBirtdayOfferInserted: "Ampidirina eto ny tolotra nahaterahana",
    youSuccessfullyAddedNewBranch: "Nahavita nanisy sampana vaovao ianao!",
    addNewBranch: "Manampia Sampana Vaovao",
    addBranch: "Manampia Sampana",
    additionalBranchWillIncur: "Ny sampana fanampiny dia tsy maintsy mijaly",
    perBranch: "isaky ny sampana",
    ecommerceBranchCosts: "Sarany ny sampana ecommerce",
    pleaseEnterBranchName: "Ampidiro ny anaran'ny sampana",
    pleaseEnterBranchAddress1: "Ampidiro azafady ny adiresy sampana 1",
    enterBranchAddress1: "Ampidiro ny tsipika adiresin'ny sampana 1",
    enterBranchAddress2: "Ampidiro ny tsipika adiresin'ny sampana 1",
    pleaseEnterBranchAddress2: "Ampidiro azafady ny andalana 2 an'ny adiresy",
    enterBranchName: "Ampidiro ny anaran'ny sampana",
    successfullyAddedStaff: "Nahavita nanampy mpiasa vaovao ianao!",
    addNewStaff: "Manampy Staff vaovao",
    addStaff: "Manampy Staff",
    additionalStaffWillIncur: "Mpiasa fanampiny no hitranga",
    perStaff: "isaky ny mpiasa.",
    pleaseEnterStaffEmail: "Ampidiro azafady ny mailaky ny mpiasa",
    pleaseEnterStaffUsername: "Ampidiro azafady ny anaran'ny mpampiasa",
    pleaseEnterStaffPassword: "Ampidiro azafady ny tenimiafin'ny mpiasa",
    pleaseSelectStaffRole: "Safidio azafady ny andraikitry ny mpiasa",
    selectStaffRole: "Safidio ny andraikitry ny mpiasa",
    enterStaffEmail: "Ampidiro ny mailaky ny mpiasa",
    enterStaffUsername: "Ampidiro ny anaran'ny mpampiasa",
    enterStaffPassword: "Ampidiro ny tenimiafin'ny mpiasa",
    spacesNotAllowedInUsername: "toerana tsy avela amin'ny solonanarana",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Misafidiana raharaham-barotra azafady mba ampidiro mpiasa",
    searchForBranchToAttachStaff: "Mikaroha sampana hampidirina mpiasa",
    username: "Anaran'ny mpampiasa",
    role: "anjara asa",
    areYouSureToDeleteThis: "Azonao antoka fa hamafa an'ity",
    branches: "sampany",
    upgradeYourPlan: "Amboary ny drafitrao.",
    add: "hametraka",
    addNew: "Manampia Vaovao",
    customerWithEmailAlreadyExists:
      "Efa misy ny mpanjifa manana nomeraon-mailaka / telefaona!",
    successfullyAddedNewCustomer:
      "Nanampy mpanjifa vaovao soa aman-tsara ianao!",
    addCustomer: "Manampia mpanjifa",
    pleaseEnterCustomerFirstName: "Ampidiro ny anaran'ny mpanjifa azafady",
    pleaseEnterCustomerLastName:
      "Ampidiro azafady ny anarana farany an'ny mpanjifa",
    pleaseEnterCustomerPhoneNumber:
      "Ampidiro azafady ny nomeraon-telefaona an'ny mpanjifa",
    pleaseEnterCustomerEmail: "Ampidiro azafady ny mailaky ny mpanjifa",
    pleaseEnterCustomerAddressLine:
      "Ampidiro azafady ny tsipika adiresy an'ny mpanjifa",
    pleaseEnterCustomerOtherAddress: "Ampidiro ny adiresy hafa an'ny mpanjifa",
    pleaseSelectCustomerGender: "Safidio azafady ny lahy sy ny vavy mpanjifa",
    gender: "lahy sy ny vavy",
    male: "Lehilahy",
    female: "vehivavy",
    bank: "Banky",
    selectBank: "Select Bank",
    stateOrRegionOrProvince: "Fanjakana / Faritra / Faritany",
    customerNotes: "Fanamarihana ho an'ny mpanjifa",
    sendSms: "Mandefasa SMS",
    editCustomer: "Amboary ny mpanjifa",
    redeemReward: "Avoty ny valisoa",
    issueLoyaltyCard: "Mametraha karatra fahatokisana",
    deleteCustomer: "Fafao ny mpanjifa",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Nahavita nanendry karatra maha-mpikambana tsy mivadika ianao",
    noMembershipIdAvailable:
      "Tsy misy karapanondro mpikambana. Azafady mifandraisa aminay hello@loystar.co",
    sendEmail: "Alefaso mailaka",
    membershipPoints: "Isa ho mpikambana",
    customerDetails: "Antsipirian'ny mpanjifa",
    close: "AKAIKY",
    loyaltyBalance: "Fandanjana tsy fivadihana",
    pointsBalance: "Balance Points",
    starBalance: "Star Balance",
    requiredPoints: "Hevitra ilaina",
    requiredStars: "Kintana takiana",
    reddemCode: "Kaody fanavotana",
    toDeleteThisCustomer: "hamafana an'ity mpanjifa ity",
    customerHasBeenDeletedSuccessfully: "Nahomby ny famafana ny mpanjifa!",
    customerWithPhoneAlreadyExists:
      "Efa misy ny mpanjifa manana nomeraon-telefaona",
    customerWasSuccessfullyEdited: "Nahomby ny fanitsiana ny mpanjifa",
    anErrorOccured: "Nisy lesoka nitranga",
    phoneNumberIncludeCountry: "Laharana telefaona (Ampidiro ny kaody Country)",
    yourFileQueuedForUpload:
      "Ny filanao dia nalahatra raha hampakatra. Avereno azafady ny pejy aorian'ny segondra vitsy.",
    thereWasAnErrorPerformingOperation:
      "Nisy lesoka tamin'ny fanatanterahana ny fandidiana!",
    pleaseSelectFile: "Misafidiana rakitra iray azafady!",
    oopsOnlyCsvFilesAllowed:
      "Oad! Ny fisie CSV ihany no avela. Ampidiro azafady ny rakitra .csv.",
    fileShouldBeSmallerThan5Mb:
      "Ny rakitra dia tokony ho kely kokoa noho ny 5MB. Raha manana rakitra lehibe kokoa ianao dia andefaso mailaka ny support@loystar.co. Misaotra anao",
    customerFirstNameIsRequired:
      "ny anaran'ny mpanjifa dia takiana amin'ny laharana",
    customerPhoneNumberIsRequired: "laharana finday mpanjifa no takiana misesy",
    importCustomers: "Import mpanjifa",
    upload: "fampitana",
    clickIconToDownloadCsv:
      "Kitiho ity kisary ity raha misintona ny modely fisie CSV.",
    getGoogleSheetVersion: "Raiso ny kinova Google sheet",
    clickOrDragFileToUpload:
      "Tsindrio na sintomy ny fisie mankany amin'ity faritra ity raha hampiakarinao",
    missingOrInvalidColumnHeader:
      "Lohatenin'ny tsanganana tsy hita na tsy mety. Azafady mba araho ny endrika modely. Misaotra anao.",
    youHaveImported: "Nanafatra ianao",
    youSuccessfullyRedeemedReward: "Vitanao tsara ny valisoanao!",
    sixDigitCode: "Kaody nomerika enina",
    pleaseEnterCustomerRewardCode:
      "Ampidiro azafady ny kaody valisoan'ny mpanjifa",
    enterRewardCode: "Ampidiro kaody valisoa. (Sarotra ny kaody valim-pitia)",
    selectLoyaltyProgram: "Safidio ny programa tsy fivadihana",
    stamps: "hajia",
    smsUnitsLow: "Unité SMS ambany",
    pleaseTopUpSmsUnits: "Azafady Top Up SMS Units",
    smsSuccessfullySentForDelivery:
      "Nandefasana soa aman-tsara ny fandefasana SMS!",
    sendSmsTo: "Mandefasa SMS any",
    allCustomers: "Mpanjifa rehetra",
    unitsAvailable: "Units misy",
    pleaseTypeInTheMessage: "Soraty azafady ny hafatra",
    message: "hAFATRA",
    charactersRemaining: "endri-tsoratra sisa",
    avoidUseOfSpecialCharacters:
      "Halaviro ny fampiasana ny endri-tsoratra manokana sy ny Emoji mba hitehirizana ireo singa SMS.",
    note: "Fanamarihana",
    errorFetchingCustomersMultilevelDetail:
      "Fahadisoana amin'ny fakana ny antsipirian'ny mpanjifa MultiLevel",
    search: "karohy",
    reset: "Mamerina",
    importCustomer: "Manafatra Mpanjifa",
    addNewCustomer: "Manampia mpanjifa vaovao",
    sendSmsBroadcast: "Mandefasa SMS Broadcast",
    totalCustomers: "MPIVAROTRA TOTAL",
    disbursementDetails: "Antsipirian'ny famoahana",
    paidBy: "Paid By",
    disbursed: "mivoaka",
    bankAccountName: "Anaran'ny kaonty banky",
    bankAccountNumber: "Kaonty banky",
    transferInitiated: "Natomboka ny famindrana",
    transferCompleted: "Vita ny famindrana",
    pleaseEnterAValid: "Ampidiro azafady",
    begin: "manomboka",
    end: "tapitra",
    date: "daty",
    paymentDate: "Daty fandoavana",
    selectDisbursementDuration: "Safidio ny faharetan'ny famoahana",
    totalSettled: "Voafetra tanteraka",
    totalUnsettled: "Tsy voalamina tanteraka",
    toDeleteThisSale: "hamafa ity varotra ity",
    draftSaleDeletedSuccessfully: "Nahomby ny famafana ny drafitra fivarotana!",
    deleteSale: "Fafao ny fivarotana",
    pleaseTypeInYourTotal:
      "Azafady soraty amin'ny totalinao mba hanamafisana ny famafana anao",
    billDetails: "Antsipiriany momba ny volavolan-dalàna",
    printBill: "Volavolan-dalàna",
    servedBy: "Notompoin'i",
    total: "sokajy",
    createdDate: "Daty noforonina",
    createdTime: "Fotoana noforonina",
    status: "sata",
    loginSuccessful: "Login Nahomby",
    pleaseWaitWhileWeConnectAccount:
      "Miandrasa azafady raha mampifandray ny kaontinao izahay",
    connectionFailedTryAgain:
      "Tsy nahomby ny fifandraisana. Manandrama indray azafady.",
    connectionSuccessful: "Fifandraisana mahomby",
    viewDetails: "Hijery antsipiriany",
    enable: "Tadiavo",
    free: "maimaim-poana",
    cost: "vidin'ny",
    visitWebsite: "Tsidiho ny tranokala",
    pleaseUpgradeYourPlanToPro:
      "Azafady mba havaozy ny drafitrao amin'ny Pro Plus hahafahan'ity fampiharana ity",
    connectYourBankAccount:
      "Ampifandraiso ny kaontinao amin'ny Banky hahafahanao mahazo fandoavam-bola.",
    disable: "Atsaharo",
    enableApp: "Alefaso ny App",
    addNewProductToInvoice: "Manampia vokatra vaovao amin'ny faktiora",
    toDeleteThisInvoice: "hamafana ity faktiora ity",
    invoiceDeletedSuccessfully: "Tafavoaka soa aman-tsara ny faktiora!",
    deleteInvoice: "Fafao ny faktiora",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Vitanao tsara ny nanoratra hafatra fandoavana faktiora!",
    pleaseEnterPaymentInstructions:
      "Ampidiro ny torolàlana momba ny fandoavam-bola",
    invoiceId: "ID faktiora",
    paidDate: "Daty naloa",
    reference: "Reference",
    productAdded: "Niampy ny vokatra",
    productOutOfStock: "Vokatra lany tahiry. Avereno jerena indray azafady.",
    totalInvoices: "INVOICES TOTAL",
    totalPaidInvoices: "INVOISO VOLA TANY",
    totalUnpaidInvoices: "INVOISA TAPALASA TSY TAPAKA",
    loyaltyProgramDeleted: "Nofoanana ny programa tsy fivadihana",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ity programa tsy fivadihana ity dia voafafa soa aman-tsara",
    thisLoyaltyProgramEditedSuccessfully:
      "Nahomby ny fanitsiana an'ity programa tsy fivadihana ity",
    loyaltyProgramAddedSuccessfully:
      "Ny programa tsy fivadihana dia nanampy soa aman-tsara",
    loyaltyProgramEdited: "Fandaharam-pahatokiana voahitsy",
    loyaltyProgramAdded: "Niampy ny programa tsy fivadihana",
    loyaltyDetails: "Antsipirian'ny fahatokiana",
    doYouWantToCloseDialog: "Te-hanidy an'ity dinika ity ve ianao?",
    pleaseEnterLoyaltyName: "Ampidiro azafady ny anaran'ny fahatokianao",
    programType: "Karazana programa",
    pleaseSelectProgramType: "Misafidiana karazana programa azafady",
    simplePoints: "Hevitra tsotra",
    stampsProgram: "Stamping Program",
    threshold: "tokonam-baravarana",
    pleaseEnterLoyaltyThreshold: "Ampidiro azafady ny tokonam-pahatokisana",
    reward: "Valisoa",
    pleaseEnterLoyaltyReward: "Ampidiro azafady ny valisoan'ny fahatokiana",
    totalUserPoints: "Isa ho an'ny mpampiasa",
    totalUserStamps: "Tombo-kase ho an'ny mpampiasa",
    spendingTarget: "Lasibatra fandaniana",
    spendingTargetHint1:
      "Ny lasibatra fandaniana dia ohatrinona no tsy maintsy lanin'ny mpanjifa hahazoana ny valisoa. Sandam-bola 1 = isa 1.",
    spendingTargetHint2:
      "Ny tanjon'ny hajia dia ny isan'ireo hajia tsy maintsy angonin'ny mpanjifa hahazoana ny valisoa. oh. 5",
    addNewLoyaltyProgram: "Ampio Fandaharam-pahatokisana vaovao",
    addLoyaltyProgram: "Ampio Fandaharana tsy mivadika",
    loyaltyProgramType: "Karazana programa tsy fivadihana",
    pleaseSelectLoyaltyProgramType:
      "Safidio azafady ny karazana programa tsy fivadihana",
    nameOfProgram: "Anaran'ny programa",
    pleaseEnterProgramName: "Ampidiro ny anaran'ny programa",
    whatIsTheReward: "Inona no valisoa?",
    egNextPurchaseIsFree: "Ohg Maimaimpoana ny fividianana manaraka",
    customerName: "Anaran'ny mpanjifa",
    guestCustomer: "Mpanjifa vahiny",
    orderConfirmedSuccessfully: "Nahomby ny kaomandy",
    orderCancelledSuccessfully: "Nahomby ny kaomandy",
    confirmOrder: "Hamafiso ny filaharana",
    cancelOrder: "Hanafoana ny filaharana",
    allOrders: "Ny kaomandy rehetra",
    totalOrders: "MPANDRAY TOTAL",
    doYouWantToAcceptOrder: "Te hanaiky ity baiko ity ve ianao?",
    doYouWantToCancelOrder: "Te hanafoana ity baiko ity ve ianao?",
    orderDetails: "Antsipiriany momba ny kaomandy",
    orderCreatedAt: "Namboarina ny baiko amin'ny",
    supplier: "mpamatsy",
    productName: "Anaran'ny vokatra",
    quantity: "Quantity",
    unitPrice: "Vidin'ny singa",
    receivedBy: "Nahazo an'i",
    reportFrom: "Tatitra avy amin'ny",
    totalSupplies: "Fitaovana feno",
    allRightsReserved: "Zo rehetra voatokana",
    toDeleteThisTransaction: "hamafana ity Transaction ity",
    transactionDeletedSuccessfully:
      "Nahomby ny fifampiraharahana. Niverina amin'ny tahiry ny tahiry.",
    deleteTransaction: "Fafao ny fifanakalozana",
    transactionDetails: "Antsipirian'ny fifampiraharahana",
    printReceipt: "Fanamarihana Print",
    channel: "fantsona",
    discount: "Discount",
    profit: "mahasoa",
    discountedSales: "Varotra mihena",
    errorFetchingRecord: "Hadisoana ny firaketana ny firaketana",
    exportTransactions: "Manondrana fifanakalozana",
    errorFetchingSalesRecord:
      "Fahadisoana rehefa naka ny firaketana ny varotra ho an'ny fanondranana.",
    totalSellingPrice: "Vidiny feno amidy",
    totalCostPrice: "Vidiny vidiny tanteraka",
    appliedDiscount: "Fihenam-bidy ampiharina",
    noOfItems: "Isan'ny entana",
    sales: "Varotra",
    export: "fanondranana",
    totalProfit: "Tombom-barotra tanteraka",
    totalBalanceInPeriod: "Fandanjana tanteraka amin'ny vanim-potoana",
    allTimeSales: "Varotra rehetra",
    records: "firaketana an-tsoratra",
    todaysSales: "Varotra androany",
    transaction: "raharaham-barotra",
    youHaveExceededTotalNumberOfProducts:
      "Nihoatra ny totalin'ny vokatra avela amin'ny drafitrao ianao. Amboary ny drafitrao hankafizanao fetra ambonimbony kokoa.",
    youNeedToHaveLoyaltyProgram:
      "Mila manana Program Loyalty ianao hampiasa an'ity endri-javatra ity!",
    price: "Vidiny",
    category: "Sokajy",
    stockTracking: "Fanarahana ny tahiry",
    stockCount: "Kaonty stock",
    taxed: "hetra",
    originalPrice: "Vidiny voalohany",
    costPrice: "Vidiny vidiny",
    unit: "Unit",
    productImage: "Sarin'ny vokatra",
    taxRate: "Hetra",
    taxType: "Karazan-ketra",
    trackInventory: "Track Inventory",
    variants: "Variants",
    hasVariants: "Manana karazana",
    importProduct: "Vokatra fanafarana",
    exportProducts: "Vokatra fanondranana",
    addNewProduct: "Manampia vokatra vaovao",
    viewCategory: "Hijery sokajy",
    viewSuppliers: "Jereo ny mpamatsy",
    receiveInventory: "Mahazoa Inventory",
    printAllProductsTag: "Ataovy pirinty ny tagan'ny vokatra rehetra",
    deleteAll: "Fafao daholo",
    totalProducts: "VOKATRA TOTAL",
    youveSuccessfullyAddedANewCategory: "Nanampy sokajy vaovao ianao",
    addNewCategory: "Manampy sokajy vaovao",
    addCategory: "Ampio Sokajy",
    categoryName: "Anaran'ny sokajy",
    pleaseEnterCategoryName: "Ampidiro ny anaran'ny sokajy",
    stampsTarget: "Tanjon'ny hajia",
    sendInventory: "Mandefasa Inventory",
    productDetails: "Antsipirian'ny vokatra",
    youveSuccessfullyEditedThisCategory:
      "Vitanao tsara ny nanitsy an'io sokajy io",
    update: "vaovao farany",
    categoryList: "Lisitry ny sokajy",
    categories: "Sokajy",
    enterQuantityToUpdate: "Mampidira habetsahana havaozina",
    inventorySentSuccessfully: "Nalefa soa aman-tsara ilay lisitra!",
    updateInventory: "Vaovao farany Inventory",
    currentQuantity: "Quantity ankehitriny",
    pleaseEnterQuantityToAdd: "Ampidiro azafady ny habetsaka tianao ampiana",
    pleaseSelectABranch: "Misafidiana Sampana azafady",
    searchForBranch: "Mikaroha sampana",
    youCantSendMoreThanStock:
      "Tsy afaka mandefa mihoatra noho izay anananao ny tahiry",
    send: "Alefaso",
    pleaseEnterQuantityToSend: "Ampidiro azafady ny habetsaka tianao halefa",
    productNameIsRequiredOnRow: "ny anaran'ny vokatra dia takiana misesy",
    productCategoryIsRequiredOnRow: "sokajy vokatra dia takiana misesy",
    productPriceIsRequiredOnRow: "ny vidin'ny vokatra dia takiana misesy",
    productUnitIsRequiredOnRow: "sampana vokatra no takiana misesy",
    productQuantityIsRequiredOnRow: "vokatra habetsahana takiana misesy",
    productVariantsRequireTracking: "ny variants vokatra dia mila fanarahana!",
    pleaseAddVariantClickButton:
      "Azafady ampio variant amin'ny fipihana ny bokotra add!",
    totalVariantsMoreThanSelectedQuantity:
      "Ny Variants total dia mihoatra ny habetsaky ny vokatra voafantina, Pls mampihena ny habetsaky ny karazany",
    productEditedSuccessfully: "Nahomby ny fanitsiana ny vokatra!",
    fileTooLargeLessThan4Mb:
      "Be loatra ny haben'ny rakitra! Ny haben'ny rakitra dia tokony ho 4MB.",
    suchVariantAlreadyExist: "Efa misy ny variant toy izany",
    addVariants: "Manampy Variants",
    editProduct: "Amboary ny vokatra",
    pleaseEnterProductName: "Ampidiro ny anaran'ny vokatra",
    pleaseEnterProductPrice: "Ampidiro azafady ny vidin'ny vokatra",
    pleaseEnterProductOriginalPrice: "Ampidiro azafady ny vidin'ny vokatra",
    productDescription: "Mombamomba ny vokatra",
    selectProductCategory: "Safidio ny sokajy vokatra",
    pleaseSelectProductCategory: "Safidio azafady sokajy vokatra",
    productCostPrice: "Vidiny vidin'ny vokatra",
    productSellingPrice: "Vidiny famarotana vokatra",
    productOriginalPrice: "Vidiny vidiny voalohany",
    maxFileSizeAllowedIs4Mb: "Max. Ny haben'ny rakitra avela dia 4mb",
    productsWithPicturesArePublished:
      "Ny vokatra misy sary dia navoaka tao amin'ny Discover mba hahazoana baiko",
    shouldThisProductBeTracked: "Tokony hojerena ve ity vokatra ity?",
    pleaseSelectStockUnit: "Safidio azafady stock unit",
    stockUnit: "Stock Unit",
    bag: "BAG",
    bottle: "tavoahangy",
    bunch: "BUNCH",
    can: "afaka",
    carton: "CARTON",
    crate: "entana feno",
    cup: "kapoaka",
    dozen: "ampolony",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "kilao",
    litre: "litatra",
    pack: "entana",
    pair: "Miaraka tsiroaroa",
    pieces: "sekely",
    plate: "vilia",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Ampidiro azafady ny habetsahan'ny vokatra",
    productQuantity: "Product Quantity",
    isThisProductTaxed: "Hetra-ketra ve ity vokatra ity?",
    selectTaxType: "Safidio ny karazana hetra",
    pleaseSelectTaxType: "Safidio azafady ny karazana hetra",
    progressive: "mivoatra",
    proportional: "mitanila",
    pleaseEnterProductTaxRate: "Ampidiro azafady ny tahan'ny hetra vokatra",
    doesProductHaveVariants: "Misy karazany ve io vokatra io?",
    type: "Karazana",
    value: "sarobidy",
    pleaseEnterVariantType: "Ampidiro ny karazana variant azafady",
    pleaseEnterVariantValue: "Ampidiro azafady ny sandan'ny variant",
    pleaseEnterVariantPrice: "Ampidiro ny vidin'ny variant",
    pleaseEnterVariantQuantity: "Ampidiro ny habetsaky ny variant",
    productDeletedSuccessfully: "Vokatra voafafa soa aman-tsara!",
    categoryDeletedSuccessfully: "Nahomby ny fanesorana ny sokajy!",
    toDeleteThisProduct: "hamafana an'ity vokatra ity",
    invalidProductQuantity: "Habetsaky ny vokatra tsy mety",
    quantityAddedIsOverStock:
      "Ny habetsanao ampiana dia mihoatra ny ao amin'ny tahiry.",
    itemInventoryNotTracked: "Ny fanisana entana dia tsy arahana",
    addBulkQuantity: "Ampio habetsaky ny be",
    enterBulkQuantity: "Ampidiro ny habetsaky ny be",
    pleaseEnterBulkQuantity: "Ampidiro ny habetsaky ny be indrindra",
    youveSuccessfullyAddedANewProduct: "Nahita vokatra vaovao ianao!",
    pleaseEnterProductSellingPrice:
      "Ampidiro azafady ny vidin'ny fivarotana vokatra",
    doYouWantToTrackProductStock: "Te-hanaraka ny tahiry vokatra ve ianao?",
    sellingPrice: "Varotra amidy",
    setProductExpiryReminder:
      "Mametraha fampahatsiahivana momba ny famaranana ny vokatra",
    productExpiryDate: "Daty famaranana ny vokatra",
    startRemindingFrom: "Atombohy ny fampahatsiahivana",
    productSuppliesAddedSuccessfully:
      "Nanampy soa aman-tsara ny famatsiana vokatra ianao.",
    addProductSupplies: "Manampia famatsiana vokatra",
    pleaseSelectSupplier: "Misafidiana mpamatsy",
    nameOfProduct: "Anaran'ny vokatra",
    pleaseSelectProduct: "Safidio ny vokatra",
    productVariant: "Product Variant",
    pleaseSelectAVariant: "Misafidiana karazana hafa azafady",
    pleaseEnterUnitPrice: "Ampidiro azafady ny vidin'ny unit",
    businessBranch: "Sampana fandraharahana",
    pleaseSelectBranch: "Misafidiana sampana azafady",
    youveSuccessfullyAddedANewSupplier:
      "Nahavita namatsy mpamatsy vaovao ianao",
    addSupplier: "Ampio Mpamatsy",
    pleaseEnterSupplierEmail: "Ampidiro azafady ny mailaky ny mpamatsy",
    pleaseAddADescription: "Manampia famaritana azafady",
    anErrorOccuredProductsDeleted:
      "Nisy lesoka nitranga nandritra ny fandidiana. Azafady mba mariho, vitsivitsy ny vokatra mety voafafa tao anatin'ilay dingana",
    bulkDelete: "Delete be dia be",
    youAreAboutToDelete: "Hamafa ianao izao",
    product: "Vokatra",
    isDueToRedeem: "dia tokony hanavotra",
    aReward: "valisoa",
    pleaseSelectCustomerToReeemReward:
      "Safidio azafady ny mpanjifa hanavotra valisoa.",
    youHaveNoLoyaltyProgramRunning:
      "Tsy manana programa fandriam-pahalemana mavitrika ianao",
    customerHhasNoPointsInLoyaltyProgram:
      "Tsy misy isa ny mpanjifa amin'ity programa tsy fivadihana ity",
    proceedWithPayment: "Miroso amin'ny fandoavana?",
    youAreAboutToPayForTransactionUsingPoints:
      "Handoa vola amin'ny fifanakalozana hevitra ianao izao.",
    customerHas: "Manana ny mpanjifa",
    worth: "manan-danja",
    andIsNotSufficientToPayForTransaction:
      "ary tsy ampy handoavana izany fifanakalozana izany. Te-hanampy ny fifandanjana amin'ny alàlan'ny fomba fandoavam-bola hafa ve izy ireo?",
    addCustomerLoyalty: "Ampio Fahatokisana ny mpanjifa",
    pleaseAddCustomerFirst: "Azafady ampio na safidio aloha ny mpanjifa.",
    pleaseWaitWhileWeGetReady: "Miandrasa azafady, raha mbola miomana izahay",
    lowStock: "Tahiry ambany",
    pleaseEnterAmountTendered: "Ampidiro azafady ny vola natolotra",
    areYouSureToBookSaleOffline:
      "Tena te-hamandrika an'ity varotra ivelan'ny trano ity ve ianao?",
    saleWouldBeBookedAutomatically:
      "Ny fivarotana dia hamandrika ho azy rehefa mandeha amin'ny Internet ianao",
    offlineSalesBookingCancelled:
      "Nofoanana ny famandrihana fivarotana an-tserasera",
    covid19Message:
      "COVID19: Sasao amin'ny savony na manadio tanana ny tananao mba hampijanona ny fiparitahana. Mijanona ho azo antoka foana",
    saleSuccessfullyRecorded: "Varotra voarakitra an-tsoratra soa aman-tsara!",
    sendReceiptToEmail: "Mandefasa tapakila amin'ny mailaka",
    sendThankYouSms: "Mandefasa misaotra SMS",
    sendShippingDetails: "Alefaso ny antsipirian'ny fandefasana",
    addLoyalty: "Ampio Fahatokiana",
    searchCustomerNameOrNumber:
      "Mikaroha anarana na laharana ho an'ny mpanjifa",
    clickTheSearchCustomerBox:
      "Tsindrio ny boaty Search Customer and Card Scan",
    enterProductPrice: "Ampidiro ny vidin'ny vokatra",
    enterPriceFor: "Ampidiro ny vidiny",
    searchForProduct: "Mikaroha vokatra",
    all: "rehetra",
    backToDashboard: "Hiverina amin'ny Dashboard",
    viewDraftSales: "Jereo ny Draft Sales",
    variantSelected: "variant voafantina",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Ny safidin'ny variant teo aloha dia tsy misy amin'ny variant vaovao voafantina mifototra amin'ny vidiny, azafady mba ovao ny safidinao.",
    pleaseSupplyPositiveQuantityNumber: "Azafady mba manomeza isa postive",
    lowStockFor: "Tahiry ambany ho an'ny",
    clearVariants: "Mazava ny fanovana",
    pleaseEnterQuantity: "Ampidiro ny Quantity",
    picture: "Sary",
    redemptionToken: "Tombom-panavotana",
    token: "famantarana",
    totalSpent: "Nandany tanteraka",
    confirmPayment: "Hamafiso ny fandoavana",
    hasPaymentBeenMade: "Voahodina tamim-pahombiazana ve ny fandoavam-bola?",
    enterTransactionReference:
      "Ampidiro ny referansa fifanakalozana izay naloanao",
    pleaseSelectACustomer: "Misafidiana mpanjifa azafady!",
    areYouSureToApplyDiscount:
      "Azonao antoka ve fa mampihatra fihenam-bidy ianao?",
    addYourBankAccountToEnableUssd:
      "Ampio ny kaontinao amin'ny banky hahafahana mamindra Instant USSD amin'ny uPay",
    totalAmountToPay: "Tontalin'ny vola aloanao",
    doYouWantToCancelTransaction:
      "Te hanafoana an'ity fifanakalozana ity ve ianao?",
    savePrintBill: "Mitahiry / Manonta volavolan-dalàna",
    enterAmountCollectedForCustomer:
      "Ampidiro ny vola nangonina ho an'ny mpanjifa",
    recordSale: "Fivarotana firaketana",
    checkOutWith: "Zahao ny",
    instantTransfer: "Famindrana eo noho eo",
    dialTheUSSDCode: "Tsindrio ny kaody USSD",
    pleaseSelectABank: "Misafidiana banky azafady",
    payWithUSSD: "Aloavy amin'ny USSD",
    sendBillTo: " - Alefaso i Bill any",
    waitingForUSSDTransfer: "Miandry ny famindrana USSD",
    percent: "isan-jato",
    applyDiscount: "Ampiharo ny fihenam-bidy",
    thisBillHasBeenSaved: "Voatahiry ity volavolan-dalàna ity",
    saveDraft: "Save Draft",
    pleaseTypeANameToIdentifyCustomer:
      "Azafady manorata anarana hahafantarana ny mpanjifa",
    paymentDetails: "Antsipirian'ny fandoavam-bola",
    basePrice: "Vidiny ifotony",
    staff: "Mpiasa",
    subTotal: "SubTotal",
    durationMonths: "Duration (volana)",
    selectADuration: "Misafidiana Duration:",
    oneMonth: "1 volana",
    twoMonths: "2 Volana",
    threeMonths: "3 volana",
    sixMonths: "6 Volana",
    twelveMonths: "12 Volana",
    eighteenMonths: "18 Volana",
    twentyFourMonths: "24 Volana",
    twoMonthsFree: "(Maimaim-poana 2 volana)",
    threeMonthsFree: "(Maimaim-poana 3 volana)",
    fiveMonthsFree: "(Maimaim-poana 5 volana)",
    yourAccountHasBeen: "Ny kaontinao dia",
    renewed: "indray",
    upgraded: "nohavaozina",
    successfully: "soa aman-tsara",
    yourSubscription: "Ny fisoratana anarana",
    youAreCurrentlyEnrolledOnA: "Misoratra anarana amin'ny a ianao izao",
    pleaseChooseAPaymentOption: "Misafidiana safidy fandoavam-bola azafady",
    planRenewal: "Fanavaozana ny drafitra",
    planUpgrade: "Manatsara ny drafitra",
    pleaseSelectDurationToPayFor: "Safidio azafady Fidio izay kasainao aloa",
    staffAccounts: "Kaontin'ny mpiasa",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Misafidiana drafitra azafady",
    includeAddons: "Ampidiro ny Add-ons",
    viewTransactions: "Jereo ny fifanakalozana",
    customerHasNoCompletedTansactions:
      "Ny mpanjifa dia mbola tsy nahavita fifandimbiasana tanteraka",
    branch: "SAMPANA",
    enterNumberOfEcommerceBranches: "Ampidiro ny isan'ny sampana ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Ampidiro ny isan'ny sampana ecommerce kasainao aloanao",
    ecommerceIntegration: "Fampidirana ecommerce",
    enterNumberOfBranches: "Ampidiro ny isan'ny sampana",
    enterNumberOfAdditionalBranchesToPay:
      "Ampidiro ny isan'ny sampana fanampiny kasainao aloa",
    enterNumberOfStaffs: "Ampidiro ny isan'ny mpiasa",
    enterNumberOfStaffsToPayFor: "Ampidiro ny isan'ny mpiasa kasainao aloa",
    discountApplies: "Fampiharana fihenam-bidy",
    starsOnThe: "kintana amin'ny",
    offer: "tolotra",
    get: "Mahazo",
    moreStarsToRedeem: "kintana bebe kokoa hanavotana",
    taxVat: "Haba (VAT)",
    callCashierToCompletePayment: "Miantso Cashier hamita feno",
    receipt: "rosia",
    dear: "Ry",
    thankYouForYourOrderFindGoods:
      "Misaotra anao noho ny baiko nomeny. Azafady mba zahao ireto entana manaraka ireto, araka ny nifanarahana.",
    shippingNote: "Fanamarihana fandefasana",
    enterShippingNote: "Ampidiro ny fanamarihana fandefasana",
    shippingAddress: "Adiresy handefasana",
    enterShippingAddress: "Ampidiro ny adiresy fandefasana",
    wellDoneYouAreDueToRedeem: "Vita tsara! Tokony hanavotra ianao",
    toGetYourRewardNextVisit:
      "hahazoana ny valisoanao amin'ny fitsidihanao manaraka. Misaotra anao",
    pointsOnThe: "Hevitra momba ny",
    morePointsToRedeem: "teboka bebe kokoa hanavotana",
    showCode: "Asehoy kaody",
    toGetYourRewardOnNextVisit:
      "hahazoana ny valisoanao amin'ny fitsidihanao manaraka. Misaotra anao",
    earn: "hahazoana",
    delivaryNote: "Fanamarihana Delivary",
    draftSales: "Varotra varotra",
    startDate: "Daty nanombohana",
    endDate: "Daty famaranana",
    orders: "Filaharana",
    checkout: "Zahao hoe",
    noProductItem: "Tsy misy entana entana",
    printProductTag: "Print Product Tag",
    transactionReference: "Fanovozan-kevitra momba ny fifanakalozana",
    Cashier: "Cashier",
    Manager: "Mpitantana",
    Owner: "TOMPON'NY",
    Admin: "Admin",
    addPartners: "Ampio mpiara-miasa",
    addNewLoyaltyPartner: "Ampio mpiara-miombon'antoka tsy mivadika vaovao",
    pleaseEnterCompanyName: "Ampidiro azafady ny anaran'ny orinasa",
    companyName: "Anaran'ny orinasa",
    pleaseEnterCompanyRepName:
      "Ampidiro azafady ny anaran'ny solontenan'ny orinasa",
    companyRepName: "Anaran'ny solontenan'ny orinasa",
    pleaseEnterCompanyRepEmail:
      "Ampidiro azafady ny mailaka an'ny solontenan'ny orinasa",
    companyRepEmail: "Email an'ny solontenan'ny orinasa",
    pleaseEnterCompanyRepPhone:
      "Ampidiro azafady ny nomeraon-telefaonan'ny solontenan'ny orinasa",
    companyRepPhone: "Laharana telefaona an'ny solontenan'ny orinasa",
    addReward: "Ampio valisoa",
    pleaseEnterRewardName: "Ampidiro azafady anarana valisoa",
    rewardName: "Anaran'ny valisoa",
    rewardQuantity: "Habetsan'ny valisoa",
    rewardDescription: "Famaritana valisoa",
    rewardType: "Karazana valisoa",
    pleaseEnterRewardType: "Ampidiro azafady karazana valisoa",
    pleaseEnterRewardQuantity: "Ampidiro azafady ny isan'ny valisoa",
    pleaseEnterRewardDescription: "Ampidiro azafady ny famaritana valisoa",
    fineDining: "Fisakafoana tsara",
    luxuryFashion: "Luxury Fashion",
    hotels: "Hotels",
    travel: "Tsangatsangana",
    foodAndBeverage: "Sakafo sy zava-pisotro",
    fashion: "Fashion",
    health: "FAHASALAMANA",
    furniture: "Furniture",
    entertainment: "Fialam-boly",
    automobile: "fiara",
    education: "fampianarana",
    beautyAndSpa: "Beauty sy Spa",
    staycation: "Staycation",
    events: "zava-mitranga",
    trips: "nivezivezy",
    oilAndGas: "Solika sy entona",
    laundry: "fanasan-damba",
    partnerCategory: "Sokajy mpiara-miasa",
    freeItem: "Free Item",
  },
  Malay: {
    cashier: "juruwang",
    manager: "pengurus",
    owner: "pemilik",
    online: "dalam talian",
    offline: "di luar talian",
    changePassword: "Tukar kata laluan",
    currentPasswordMessage: "Sila masukkan kata laluan semasa anda",
    passwordMessage: "Sila masukkan kata laluan anda",
    currentPassword: "Kata Laluan Semasa",
    password: "kata laluan",
    confirmPasswordMessage: "Sila sahkan kata laluan anda!",
    confirmPassword: "Sahkan Kata Laluan",
    sendViaEmail: "Hantar Melalui E-mel",
    sendViaWhatsapp: "Hantar Melalui WhatsApp",
    downloadPdf: "Muat turun PDF",
    paid: "dibayar",
    unpaid: "tidak dibayar",
    partial: "separa",
    closeInvoice: "Adakah anda mahu menutup Invois?",
    closeInvoiceConfirmation:
      "Invois mungkin tidak disimpan. Adakah anda mahu menutup?",
    yes: "iya",
    no: "tidak",
    invoice: "Invois",
    wasDeducted: "telah ditolak",
    for: "untuk",
    item: "Item",
    addProduct: "Tambah Produk",
    paymentReference: "Rujukan pembayaran",
    amountPaid: "Amaun yang dibayar",
    discountAmount: "Jumlah diskaun",
    amountDue: "Jumlah perlu dibayar",
    amount: "Jumlah",
    dueDate: "Tarikh Akhir",
    paymentType: "Jenis pembayaran",
    card: "Kad",
    cash: "Wang Tunai",
    bankTransfer: "Pemindahan bank",
    paymentMessage: "Mesej Pembayaran",
    description: "Penerangan",
    sendReceipt: "Hantar Resit",
    delete: "Padam",
    save: "Jimat",
    resend: "Hantar semula",
    saveAndSend: "Jimat",
    invoiceSaved: "Invois disimpan!",
    selectPaymentMethod: "Sila pilih Kaedah Pembayaran!",
    selectCustomer: "Sila pilih Pelanggan!",
    cartEmptyError:
      "Daftar Troli tidak boleh kosong, tutup invois dan tambahkan Item ke troli!",
    subscriptionExpired:
      "Langganan anda telah tamat dan akaun anda kini terhad. Klik pada Butang di bawah untuk memperbaharui akaun anda",
    renew: "Perbaharui",
    holdOn: "Tahan",
    customerBank: "Bank Pelanggan",
    cancel: "Batal",
    selectACustomer: "Pilih Pelanggan",
    invoiceSuccessfulPdfError:
      "Invois berjaya dibuat tetapi Penjanaan PDF memerlukan masa lebih lama daripada biasa. Sila semak sebentar lagi.",
    downloadingInvoice: "Memuat turun Invois",
    downloadingReceipt: "Memuat turun Resit",
    whatsappReceiptError:
      "Berlaku ralat semasa menghantar resit melalui WhatsApp. Sila cuba lagi.",
    receiptToWhatsapp: "Resit dihantar ke WhatsApp",
    thankYouForPatronage: "Terima kasih atas sokongan anda",
    hereIsYourReceipt: "Berikut adalah resit pembayaran anda",
    errorSendingEmailReceipt:
      "Berlaku ralat semasa menghantar resit melalui e-mel, sila cuba lagi atau hubungi sokongan",
    receiptSentToEmail: "Resit telah dihantar ke e-mel pelanggan",
    invoiceSentToEmail: "Invois telah dihantar ke e-mel pelanggan",
    invoiceSuccessWhatsappError:
      "Invois berjaya dibuat tetapi berlaku ralat semasa menghantar ke WhatsApp. Cuba lagi pada senarai invois",
    invoiceSuccessfulEmailError:
      "Invois berjaya dibuat tetapi berlaku ralat semasa menghantar sebagai e-mel. Sila cuba lagi dari senarai invois",
    invoiceSentToWhatsapp: "Invois dihantar ke WhatsApp",
    hello: "Helo",
    pleaseDownloadInvoice: "Sila muat turun invois",
    pleaseDownloadReceipt: "Sila muat turun resit",
    from: "dari",
    email: "E-mel",
    upgrade: "Naik taraf",
    youAreOnFreePlan: "Anda menggunakan Pelan Percuma.",
    clickOn: "Klik pada",
    yourPlanInFewSteps: " langganan anda dalam beberapa langkah pantas.",
    to: "ke",
    yourSubscriptionHasExpired:
      "Langganan anda telah tamat dan akaun anda kini terhad.",
    days: "hari-hari",
    yourSubscriptionExpiresIn: "Langganan Loystar anda akan berakhir pada",
    createAcount: "Buat Akaun",
    signIn: "Log masuk",
    continue: "Teruskan",
    enterOtp: "Masukkan PIN OTP",
    enterValidOtp: "Sila masukkan PIN yang sah",
    pin: "PIN",
    tokenSentToMail: "Token telah dihantar ke e-mel anda",
    passwordResetSuccessful: "Set semula kata laluan berjaya",
    somethingWentWrong: "Ada yang tidak kena!",
    resetPassword: "Menetapkan semula kata laluan",
    iHaveResetCode: "Saya mempunyai kod tetapan semula kata laluan",
    pleaseEnterEmail: "Sila masukkan e-mel anda",
    aTokenWillBeSentToEmail: "Token akan dihantar ke e-mel anda",
    enterEmail: "Masukkan emel anda",
    sendinYourToken: "Menghantar token anda ...",
    makeSureItMatchesPassword:
      "Pastikan ia sesuai dengan kata laluan baru anda",
    pleaseChooseNewPassword: "Sila pilih kata laluan baru",
    chooseNewPassword: "Pilih kata laluan baru",
    enterNewPassword: "Masukkan kata laluan baru anda untuk mengesahkan",
    enterTokenSent: "Masukkan token yang dihantar mel anda",
    resetToken: "Tetapkan Semula Token",
    resettingPassword: "Menetapkan semula Kata Laluan anda ...",
    signUp: "Mendaftar",
    adminSignInWithEmail:
      " Pentadbir log masuk dengan e-mel sementara Kakitangan log masuk dengan nama pengguna.",
    pleaseEnterEmailOrUsername: "Sila masukkan e-mel atau nama pengguna anda",
    emailOrUsername: "E-mel atau Nama pengguna",
    pleaseEnterPassword: "Sila masukkan kata laluan",
    createAnAccount: "Buat Akaun",
    forgotPassword: "Lupa kata laluan?",
    enterPhoneNumber: "Masukkan Nombor Telefon",
    personalData: "Data peribadi",
    validateConfirmationCOde: "Mengesahkan Kod Pengesahan",
    pleaseEnterFirstName: "Sila masukkan nama depan anda",
    pleaseEnterPhoneNumber: "Sila masukkan nombor telefon anda",
    pleaseEnterLastName: "Sila masukkan nama belakang anda",
    pleaseEnterBusinessName: "Sila masukkan nama perniagaan anda",
    firstName: "Nama pertama",
    lastName: "Nama terakhir",
    businessName: "Nama Perniagaan",
    previous: "Sebelumnya",
    next: "Seterusnya",
    pleaseSelectBusinessCategory: "Sila pilih kategori perniagaan anda",
    pleaseEnterValidEmail: "Sila masukkan e-mel yang sah",
    pleaseEnterPostCode: "Sila masukkan kod pos",
    postCode: "Poskod",
    phoneNumberInUse: "Nombor telefon ini sudah digunakan!",
    emailInUse: "E-mel ini sudah digunakan!",
    foodAndDrinks: "Makanan dan minuman",
    salonAndBeauty: "Salon dan Kecantikan",
    fashionAndAccessories: "Fesyen dan Aksesori",
    gymAndFitness: "Gim dan Kecergasan",
    travelAndHotel: "Perjalanan dan Hotel",
    homeAndGifts: "Rumah dan Hadiah",
    washingAndCleaning: "Mencuci dan Membersihkan",
    gadgetsAndElectronics: "Alat dan Elektronik",
    groceries: "Barangan runcit",
    others: "Yang lain",
    submit: "Hantar",
    accountCreatedSuccessful: "Akaun anda berjaya dibuat.",
    pleaseEnterAddress: "Sila Masukkan Alamat Anda",
    addressLine1: "Alamat Baris 1",
    addressLine2: "Alamat 2",
    choosePassword: "Pilih kata laluan",
    passwordMustBe6Characters:
      "Kata laluan mestilah sekurang-kurangnya 6 aksara.",
    howDidYouHearLoystar: "Bagaimana anda mendengar tentang Loystar?",
    referralCode: "Kod rujukan",
    byClickingTheButton:
      " Dengan mengklik butang di bawah, anda bersetuju dengan",
    termsAndSevice: "Syarat",
    wereCreatingAccount: "Kami membuat akaun anda",
    proceed: "Teruskan",
    verificationCodeMustBe6: "Kod Pengesahan mestilah 6 digit",
    pleaseEnter6DigitCode: "Sila masukkan kod 6 digit",
    enterVerificationCode: "Masukkan Kod Pengesahan",
    resendToken: "Hantar semula Token",
    verify: "Sahkan",
    transactions: "Urus Niaga",
    todaySales: "Jualan Hari Ini",
    salesHistory: "Sejarah Jualan",
    supplyHistory: "Sejarah Bekalan",
    new: "Baru",
    invoices: "Invois",
    disbursements: "Pembayaran",
    offlineSales: "Jualan Luar Talian",
    products: "Produk",
    customers: "Pelanggan",
    multiLevelLoyalty: "Kesetiaan Pelbagai Tahap",
    loyaltyPrograms: "Program Kesetiaan",
    members: "Ahli",
    appStore: "Stor aplikasi",
    orderMenu: "Menu Pesanan",
    settings: "Tetapan",
    staffAndBranches: "Kakitangan dan Cawangan",
    myAccount: "Akaun saya",
    switchToSellMode: "Tukar ke Mod Jual",
    signOut: "Keluar",
    getFreeSubscription: "Dapatkan Langganan Percuma",
    onlyNumbersAllowed: "Hanya nombor yang dibenarkan",
    yourAccountMustBe10Digits: "nombor akaun anda mestilah nombor 10 digit",
    yourBvnMustBe11: "BVN anda mestilah nombor 11 digit",
    pleaseSelectBank: "Sila pilih bank anda",
    selectYourBank: "Pilih Bank anda",
    enterBankAccountNumber: "Masukkan Nombor Akaun Bank",
    enterBvnNumber: "Masukkan BVN anda",
    connectBankAccount: "Sambungkan Akaun Bank",
    passwordResetSuccessfulAndSignOut:
      "Kata Laluan anda telah ditetapkan semula dengan berjaya. Klik pada butang Log Keluar di bawah untuk log masuk semula",
    enterCurrentPassword: "Masukkan kata laluan semasa",
    pleaseEnterCurrentPassword: "Sila masukkan kata laluan semasa",
    phoneNumber: "Nombor telefon",
    sex: "Seks",
    dateOfBirth: "Tarikh lahir",
    state: "Nyatakan",
    country: "Negara",
    loyaltyId: "ID Kesetiaan",
    createdAt: "Dibuat pada",
    noOfTransactionsMade: "Jumlah urus niaga yang dibuat",
    yourDownloadWillStart: "Muat turun anda akan bermula sebentar lagi",
    exportCustomers: "Pelanggan Eksport",
    youhaveSuccessfullyToppedUp: "Anda berjaya menambah Unit SMS anda.",
    youNowHave: "Anda kini mempunyai",
    smsUnits: "Unit SMS",
    enterNumberOfSmsUnits:
      "Masukkan Bilangan Unit SMS Yang Anda Inginkan Membeli",
    pleaseEnterANumericValue: "Sila masukkan nilai angka",
    pay: "Bayar",
    accountEditedSuccessfully: "Akaun anda berjaya diedit.",
    youAeCurrentlyOn: "Anda sedang aktif",
    plan: "Rancang",
    userData: "Data pengguna",
    businessData: "DATA PERNIAGAAN",
    businessCategory: "Perniagaan Catergory",
    pleaseSelectCurrency: "Sila pilih mata wang anda",
    selectYourCurrency: "Pilih mata wang anda",
    purchaseMoreSmsUnits:
      "Beli lebih banyak Unit SMS menggunakan borang di bawah",
    youHave: "Kamu ada",
    atLeast4SmsUnitsRrequired:
      "Sekurang-kurangnya 4 unit sms diperlukan untuk pengesahan, sila tambah nilai!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Sila sahkan akaun bank anda untuk mematuhi syarat 'Kenali Pelanggan Anda' (KYC). Ini akan membolehkan anda mengumpulkan pembayaran melalui USSD atau pindahan segera, menerima pesanan dari pelanggan dan memproses penyelesaian transaksi. Berlaku untuk pedagang Nigeria sahaja. Sila tekan butang di bawah untuk memulakan.",
    reConnectBankAccount: "Sambungkan semula Akaun Bank",
    accountName: "Nama akaun",
    accountNumber: "Nombor akaun",
    bankName: "Nama bank",
    verified: "Disahkan",
    accountDetails: "Butiran Akaun",
    kycBankAccount: "KYC",
    createTier: "Buat Tahap",
    fileUploadSuccessful: "fail berjaya dimuat naik",
    fileUploadFailed: "muat naik fail gagal",
    createLoyaltyProgram: "Buat Program Kesetiaan",
    createLoyalty: "Buat Kesetiaan",
    name: "Nama",
    loyaltyArtwork: "Karya Seni Kesetiaan",
    clickToUpload: "Klik untuk memuat naik",
    amountToPointsRatio: "Nisbah Jumlah hingga Poin",
    points: "Mata",
    recommendedAmountToPointRatio:
      "Disyorkan: ₦ 1 hingga 1 mata. iaitu untuk setiap 1 Naira yang dibelanjakan, pelanggan anda mendapat 1 mata",
    pleaseTypeIn: "Sila taip",
    toDeleteLoyalty: "untuk menghapus kesetiaan ini",
    deleteLoyalty: "Padamkan Kesetiaan",
    toConfirm: "untuk mengesahkan",
    edit: "Edit",
    pointsAwardedSuccessfully: "Poin Dikurniakan dengan Berjaya.",
    enterPointValueToAward:
      "Masukkan Nilai Poin yang Anda Inginkan kepada Pelanggan",
    award: "Anugerah",
    awardPointValuesToCustomer: "Nilai Titik Penghargaan kepada Pelanggan",
    enrollMembersToLoyalty: "Daftar Ahli ke Kesetiaan",
    awardPoints: "Mata Anugerah",
    enroll: "Mendaftar",
    home: "Rumah",
    loyalty: "Kesetiaan",
    enrollCustomers: "Mendaftar Pelanggan",
    selected: "Terpilih",
    customer: "Pelanggan",
    loyaltyActivationSuccessful: "Pengaktifan Kesetiaan berjaya.",
    loyaltyDeactivationSuccessful: "Penonaktifan Kesetiaan berjaya.",
    viewTier: "Lihat Tahap",
    deactivate: "Nyahaktifkan",
    activate: "Aktifkan",
    actions: "Tindakan",
    nameOfLoyalty: "Nama Kesetiaan",
    loyaltyStatus: "Status Kesetiaan Status Kesetiaan",
    numberOfTiers: "Bilangan Tahap",
    createdOn: "Dicipta pada",
    createATier: "Buat Tahap",
    stopCreatingTierConfirmation:
      "Adakah anda mahu berhenti membuat peringkat?",
    stopEditingTierConfirmation:
      "Adakah anda mahu berhenti mengedit peringkat ini?",
    nameOfTier: "Nama Tahap",
    minimumSpendingTarget: "Sasaran Perbelanjaan Minimum",
    maximumSpendingTarget: "Sasaran Perbelanjaan Maksimum",
    minimumSpendingTargetRequired: "sasaran perbelanjaan minimum diperlukan",
    maximumSpendingTargetRequired: "sasaran perbelanjaan maksimum diperlukan",
    rewardSponsor: "Penaja Ganjaran",
    pleaseChooseARewardSponsor: "Sila pilih penaja ganjaran",
    self: "Diri",
    partner: "Rakan kongsi",
    rewardPartner: "Rakan Ganjaran",
    pleaseSelectRewardPartner: "Sila pilih pasangan ganjaran anda",
    rewards: "Ganjaran",
    pleaseSelectAReward: "Sila pilih ganjaran",
    instructionsOnRedeemingReward:
      "Arahan mengenai Cara Pelanggan Perlu Menebus Ganjaran",
    pleaseFillInThisField: "Sila Isi Bidang ini!",
    toDeleteThisTier: " untuk memadamkan tahap ini",
    deleteTier: "Padamkan Tahap",
    viewMembers: "Lihat Ahli",
    membersEnrolled: "Ahli Mendaftar",
    instruction: "Arahan",
    membersIn: "Ahli di",
    availableTiersInLoyalty: "Terdapat Tahap dalam Program Kesetiaan",
    tiers: "Tahap",
    totalTier: "JUMLAH TIER",
    availableLoyaltyProgramme: "Program Kesetiaan yang Ada",
    totalLoyalties: "JUMLAH LOYALTI",
    memberDetails: "Maklumat Ahli",
    nameOfCustomer: "Nama Pelanggan",
    address: "Alamat",
    allEnrolledMembers: "Semua Ahli Yang Mendaftar",
    thisIsToWishYouHappyBirthday:
      "Ini adalah untuk mengucapkan selamat ulang tahun dan kehidupan yang sejahtera kepada anda. Terima kasih untuk semua yang anda lakukan kepada Loyster. Selamat Menyambut!",
    inputAnOfferPlease: "Masukkan Tawaran sila",
    pleaseSelectTheInsertPoint:
      "Pilih titik sisipan dalam mesej dan klik sekali lagi",
    birthdayOfferAndMessage: "Tawaran dan Mesej Hari Lahir",
    birthdayOffer: "Tawaran Hari Lahir",
    birthdayMessage: "Mesej Hari Lahir",
    noOfferFound: "Tiada tawaran dijumpai",
    settingABirthdayOffer:
      "Menetapkan tawaran hari jadi membolehkan pelanggan menerima tawaran ini melalui SMS pada hari lahir mereka",
    createOffer: "Buat Tawaran",
    whatIsTheOffer: "Apa tawarannya?",
    editMessage: "Edit Mesej",
    insert: "Masukkan",
    theNameOfCustomerInserted: "Nama pelanggan akan dimasukkan di sini",
    theBirtdayOfferInserted: "Tawaran hari jadi akan dimasukkan di sini",
    youSuccessfullyAddedNewBranch: "Anda berjaya menambahkan cawangan baru!",
    addNewBranch: "Tambah Cawangan Baru",
    addBranch: "Tambah Cawangan",
    additionalBranchWillIncur: "Cawangan Tambahan akan dikenakan",
    perBranch: "setiap cawangan",
    ecommerceBranchCosts: "Kos Cawangan E-dagang",
    pleaseEnterBranchName: "Sila masukkan nama cawangan",
    pleaseEnterBranchAddress1: "Sila masukkan baris alamat cawangan 1",
    enterBranchAddress1: "Masukkan baris alamat cawangan 1",
    enterBranchAddress2: "Masukkan baris alamat cawangan 1",
    pleaseEnterBranchAddress2: "Sila masukkan baris alamat cawangan 2",
    enterBranchName: "Masukkan nama cawangan",
    successfullyAddedStaff: "Anda berjaya menambahkan kakitangan baru!",
    addNewStaff: "Tambah Kakitangan Baru",
    addStaff: "Tambah Kakitangan",
    additionalStaffWillIncur: "Kakitangan Tambahan akan dikenakan",
    perStaff: "setiap kakitangan.",
    pleaseEnterStaffEmail: "Sila masukkan e-mel kakitangan",
    pleaseEnterStaffUsername: "Sila masukkan nama pengguna kakitangan",
    pleaseEnterStaffPassword: "Sila masukkan kata laluan kakitangan",
    pleaseSelectStaffRole: "Sila pilih peranan kakitangan",
    selectStaffRole: "Pilih peranan kakitangan",
    enterStaffEmail: "Masukkan e-mel kakitangan",
    enterStaffUsername: "Masukkan nama pengguna kakitangan",
    enterStaffPassword: "Masukkan kata laluan kakitangan",
    spacesNotAllowedInUsername: "ruang tidak dibenarkan dalam nama pengguna",
    admin: "Pentadbir",
    pleaseSelectBusinessToAttachStaff:
      "Sila pilih perniagaan untuk melampirkan kakitangan",
    searchForBranchToAttachStaff:
      "Mencari cawangan untuk melampirkan kakitangan",
    username: "Nama pengguna",
    role: "Peranan",
    areYouSureToDeleteThis: "Adakah anda pasti memadamkannya",
    branches: "Cawangan",
    upgradeYourPlan: "Tingkatkan Pelan anda.",
    add: "TAMBAH",
    addNew: "Tambah baru",
    customerWithEmailAlreadyExists:
      "Pelanggan dengan nombor e-mel / telefon sudah ada!",
    successfullyAddedNewCustomer: "Anda berjaya menambahkan pelanggan baru!",
    addCustomer: "Tambah Pelanggan",
    pleaseEnterCustomerFirstName: "Sila masukkan nama pertama pelanggan",
    pleaseEnterCustomerLastName: "Sila masukkan nama belakang pelanggan",
    pleaseEnterCustomerPhoneNumber: "Sila masukkan nombor telefon pelanggan",
    pleaseEnterCustomerEmail: "Sila masukkan e-mel pelanggan",
    pleaseEnterCustomerAddressLine: "Sila masukkan Talian Alamat pelanggan",
    pleaseEnterCustomerOtherAddress: "Sila masukkan Alamat pelanggan yang lain",
    pleaseSelectCustomerGender: "Sila pilih jantina pelanggan",
    gender: "Jantina",
    male: "Lelaki",
    female: "Perempuan",
    bank: "Bank",
    selectBank: "Pilih Bank",
    stateOrRegionOrProvince: "Negeri / Wilayah / Wilayah",
    customerNotes: "Nota Pelanggan",
    sendSms: "Hantar SMS",
    editCustomer: "Edit Pelanggan",
    redeemReward: "Tebus Ganjaran",
    issueLoyaltyCard: "Keluarkan Kad Kesetiaan",
    deleteCustomer: "Padamkan Pelanggan",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Anda berjaya Menetapkan ID Keahlian Kesetiaan",
    noMembershipIdAvailable:
      "Tidak ada ID keahlian. Sila hubungi kami di hello@loystar.co",
    sendEmail: "Menghantar e-mel",
    membershipPoints: "Mata Keahlian",
    customerDetails: "butiran pelanggan",
    close: "Tutup",
    loyaltyBalance: "Imbangan Kesetiaan",
    pointsBalance: "Imbangan Mata",
    starBalance: "Imbangan Bintang",
    requiredPoints: "Mata yang Diperlukan",
    requiredStars: "Bintang yang Diperlukan",
    reddemCode: "Tebus Kod",
    toDeleteThisCustomer: "untuk memadam pelanggan ini",
    customerHasBeenDeletedSuccessfully: "Pelanggan berjaya dipadamkan!",
    customerWithPhoneAlreadyExists: "Pelanggan dengan nombor telefon sudah ada",
    customerWasSuccessfullyEdited: "Pelanggan berjaya diedit",
    anErrorOccured: "Berlaku ralat",
    phoneNumberIncludeCountry: "Nombor Telefon (Sertakan Kod Negara)",
    yourFileQueuedForUpload:
      "Fail anda telah diantri untuk dimuat naik. Muat semula halaman selepas beberapa saat.",
    thereWasAnErrorPerformingOperation:
      "Terdapat ralat semasa menjalankan operasi!",
    pleaseSelectFile: "Sila Pilih Fail!",
    oopsOnlyCsvFilesAllowed:
      "Alamak! Hanya fail CSV yang dibenarkan. Sila muat naik fail .csv.",
    fileShouldBeSmallerThan5Mb:
      "Fail hendaklah lebih kecil daripada 5MB. Sekiranya anda mempunyai fail yang lebih besar, sila hantarkan email ke support@loystar.co. Terima kasih",
    customerFirstNameIsRequired:
      "nama pertama pelanggan diperlukan secara berturut-turut",
    customerPhoneNumberIsRequired:
      "nombor telefon pelanggan diperlukan secara berturut-turut",
    importCustomers: "Pelanggan Import",
    upload: "Muat naik",
    clickIconToDownloadCsv:
      "Klik ikon ini untuk memuat turun templat fail CSV.",
    getGoogleSheetVersion: "Dapatkan versi helaian Google",
    clickOrDragFileToUpload:
      "Klik atau seret fail ke kawasan ini untuk dimuat naik",
    missingOrInvalidColumnHeader:
      "Tajuk lajur tiada atau tidak sah. Sila ikuti format templat. Terima kasih.",
    youHaveImported: "Anda Telah Mengimport",
    youSuccessfullyRedeemedReward: "Anda berjaya menebus ganjaran anda!",
    sixDigitCode: "Kod enam digit",
    pleaseEnterCustomerRewardCode: "Sila masukkan kod ganjaran pelanggan",
    enterRewardCode:
      "Masukkan kod ganjaran. (Kod ganjaran peka huruf besar kecil)",
    selectLoyaltyProgram: "Pilih program kesetiaan",
    stamps: "Setem",
    smsUnitsLow: "Unit SMS Rendah",
    pleaseTopUpSmsUnits: "Sila Tambah Unit SMS",
    smsSuccessfullySentForDelivery: "SMS berjaya dihantar untuk penghantaran!",
    sendSmsTo: "Hantar SMS ke",
    allCustomers: "Semua Pelanggan",
    unitsAvailable: "Unit Terdapat",
    pleaseTypeInTheMessage: "Sila taipkan mesej",
    message: "Mesej",
    charactersRemaining: "watak yang tinggal",
    avoidUseOfSpecialCharacters:
      "Elakkan penggunaan Watak Khas dan Emoji untuk memulihara Unit SMS.",
    note: "Catatan",
    errorFetchingCustomersMultilevelDetail:
      "Ralat Mengambil Butiran Pelbagai Tingkat Pelanggan",
    search: "Cari",
    reset: "Tetapkan semula",
    importCustomer: "Import Pelanggan",
    addNewCustomer: "Tambah Pelanggan Baru",
    sendSmsBroadcast: "Hantar Siaran SMS",
    totalCustomers: "JUMLAH PELANGGAN",
    disbursementDetails: "Butiran Pembayaran",
    paidBy: "Dibayar oleh",
    disbursed: "Dikeluarkan",
    bankAccountName: "Nama Akaun Bank",
    bankAccountNumber: "Nombor akaun bank",
    transferInitiated: "Pemindahan Dimulakan",
    transferCompleted: "Pemindahan Selesai",
    pleaseEnterAValid: "Sila masukkan yang sah",
    begin: "bermula",
    end: "akhir",
    date: "Tarikh",
    paymentDate: "Tarikh pembayaran",
    selectDisbursementDuration: "Pilih Tempoh Pembayaran",
    totalSettled: "Jumlah Selesai",
    totalUnsettled: "Jumlah Tidak Selesai",
    toDeleteThisSale: "untuk memadam penjualan ini",
    draftSaleDeletedSuccessfully: "Jualan Draf berjaya dipadamkan!",
    deleteSale: "Padamkan Jualan",
    pleaseTypeInYourTotal:
      "Sila masukkan jumlah anda untuk mengesahkan penghapusan",
    billDetails: "Butiran Bil",
    printBill: "Cetak Bil",
    servedBy: "Dilayan oleh",
    total: "Jumlah",
    createdDate: "Tarikh Dibuat",
    createdTime: "Masa Dibuat",
    status: "Status",
    loginSuccessful: "Log Masuk Berjaya",
    pleaseWaitWhileWeConnectAccount:
      "Tunggu sementara kami menyambungkan akaun anda",
    connectionFailedTryAgain: "Sambungan telah gagal. Sila cuba lagi.",
    connectionSuccessful: "Sambungan Berjaya",
    viewDetails: "Lihat butiran",
    enable: "Aktifkan",
    free: "Percuma",
    cost: "Kos",
    visitWebsite: "Melawat laman web",
    pleaseUpgradeYourPlanToPro:
      "Tingkatkan rancangan anda ke Pro Plus untuk mengaktifkan aplikasi ini",
    connectYourBankAccount:
      "Sambungkan Akaun Bank anda untuk dapat menerima pembayaran.",
    disable: "Lumpuhkan",
    enableApp: "Dayakan Aplikasi",
    addNewProductToInvoice: "Tambahkan Produk Baru ke Invois",
    toDeleteThisInvoice: "untuk memadam Invois ini",
    invoiceDeletedSuccessfully: "Invois berjaya dipadamkan!",
    deleteInvoice: "Padamkan Invois",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Anda berjaya menambahkan mesej pembayaran Invois!",
    pleaseEnterPaymentInstructions: "Sila masukkan arahan pembayaran",
    invoiceId: "ID invois",
    paidDate: "Tarikh Dibayar",
    reference: "Rujukan",
    productAdded: "Produk ditambah",
    productOutOfStock: "Produk kehabisan stok. Sila buat stok semula.",
    totalInvoices: "JUMLAH INFO",
    totalPaidInvoices: "JUMLAH INVOVID YANG DIBAYAR",
    totalUnpaidInvoices: "JUMLAH INVIDIK YANG TIDAK DIBENARKAN",
    loyaltyProgramDeleted: "Program Kesetiaan Dihapus",
    thisLoyaltyProgramDeletedSuccessfully:
      "Program kesetiaan ini berjaya dihapuskan",
    thisLoyaltyProgramEditedSuccessfully:
      "Program kesetiaan ini telah berjaya diedit",
    loyaltyProgramAddedSuccessfully:
      "Program kesetiaan telah berjaya ditambahkan",
    loyaltyProgramEdited: "Program Kesetiaan Disunting",
    loyaltyProgramAdded: "Program Kesetiaan Ditambah",
    loyaltyDetails: "Butiran Kesetiaan",
    doYouWantToCloseDialog: "Adakah anda mahu menutup dialog ini?",
    pleaseEnterLoyaltyName: "Sila masukkan nama kesetiaan anda",
    programType: "Jenis Program",
    pleaseSelectProgramType: "Sila pilih jenis program",
    simplePoints: "Mata Mudah",
    stampsProgram: "Program Setem",
    threshold: "Ambang",
    pleaseEnterLoyaltyThreshold: "Sila masukkan ambang kesetiaan",
    reward: "Ganjaran",
    pleaseEnterLoyaltyReward: "Sila masukkan pahala kesetiaan",
    totalUserPoints: "Jumlah Mata Pengguna",
    totalUserStamps: "Jumlah Setem Pengguna",
    spendingTarget: "Sasaran perbelanjaan",
    spendingTargetHint1:
      "Sasaran perbelanjaan adalah berapa banyak yang mesti dibelanjakan oleh pelanggan untuk mendapatkan ganjaran. 1 nilai mata wang = 1 mata.",
    spendingTargetHint2:
      "Sasaran setem ialah berapa banyak cap yang mesti dikumpulkan oleh pelanggan untuk mendapatkan hadiah. cth. 5",
    addNewLoyaltyProgram: "Tambah Program Kesetiaan Baru",
    addLoyaltyProgram: "Tambah Program Kesetiaan",
    loyaltyProgramType: "Jenis Program Kesetiaan",
    pleaseSelectLoyaltyProgramType: "Sila pilih jenis program kesetiaan",
    nameOfProgram: "Nama program",
    pleaseEnterProgramName: "Sila masukkan nama program",
    whatIsTheReward: "Apa ganjarannya?",
    egNextPurchaseIsFree: "Cth. Pembelian seterusnya adalah percuma",
    customerName: "Nama Pelanggan",
    guestCustomer: "Pelanggan Tetamu",
    orderConfirmedSuccessfully: "Pesanan berjaya disahkan",
    orderCancelledSuccessfully: "Pesanan berjaya dibatalkan",
    confirmOrder: "Mengesahkan pesanan",
    cancelOrder: "Batalkan pesanan",
    allOrders: "Semua Pesanan",
    totalOrders: "JUMLAH PESANAN",
    doYouWantToAcceptOrder: "Adakah anda mahu menerima pesanan ini?",
    doYouWantToCancelOrder: "Adakah anda mahu membatalkan pesanan ini?",
    orderDetails: "Maklumat-maklumat Pesanan",
    orderCreatedAt: "Pesanan dibuat pada",
    supplier: "Pembekal",
    productName: "Nama Produk",
    quantity: "Kuantiti",
    unitPrice: "Harga seunit",
    receivedBy: "Diterima oleh",
    reportFrom: "Laporan dari",
    totalSupplies: "Jumlah Bekalan",
    allRightsReserved: "Hak cipta terpelihara",
    toDeleteThisTransaction: "untuk menghapus Transaksi ini",
    transactionDeletedSuccessfully:
      "Transaksi berjaya dipadamkan. Stok telah dikembalikan ke inventori.",
    deleteTransaction: "Padamkan Transaksi",
    transactionDetails: "butiran transaksi",
    printReceipt: "Cetakan Resit",
    channel: "Saluran",
    discount: "Diskaun",
    profit: "Keuntungan",
    discountedSales: "Jualan Diskaun",
    errorFetchingRecord: "Ralat Mengambil Rekod",
    exportTransactions: "Urus Niaga Eksport",
    errorFetchingSalesRecord:
      "Ralat semasa mengambil rekod Jualan untuk Eksport.",
    totalSellingPrice: "Jumlah Harga Jual",
    totalCostPrice: "Jumlah Harga Kos",
    appliedDiscount: "Potongan Gunaan",
    noOfItems: "Bilangan Item",
    sales: "Jualan",
    export: "Eksport",
    totalProfit: "Jumlah Keuntungan",
    totalBalanceInPeriod: "Jumlah Baki dalam Tempoh",
    allTimeSales: "Jualan Sepanjang Masa",
    records: "Rekod",
    todaysSales: "Jualan Hari Ini",
    transaction: "transaksi",
    youHaveExceededTotalNumberOfProducts:
      "Anda telah melebihi jumlah produk yang dibenarkan dalam rancangan anda. Tingkatkan rancangan anda untuk menikmati had yang lebih tinggi.",
    youNeedToHaveLoyaltyProgram:
      "Anda Perlu Mempunyai Program Kesetiaan untuk menggunakan ciri ini!",
    price: "Harga",
    category: "Kategori",
    stockTracking: "Penjejakan Stok",
    stockCount: "Kira Stok",
    taxed: "Dikenakan cukai",
    originalPrice: "Harga asal",
    costPrice: "Harga Kos",
    unit: "Unit",
    productImage: "Imej Produk",
    taxRate: "Kadar cukai",
    taxType: "Jenis Cukai",
    trackInventory: "Jejaki Inventori",
    variants: "Varian",
    hasVariants: "Mempunyai Varian",
    importProduct: "Import Produk",
    exportProducts: "Eksport Produk",
    addNewProduct: "Tambah Produk Baru",
    viewCategory: "Lihat Kategori",
    viewSuppliers: "Lihat Pembekal",
    receiveInventory: "Terima Inventori",
    printAllProductsTag: "Cetak Tag Semua Produk",
    deleteAll: "Memadam semua",
    totalProducts: "JUMLAH PRODUK",
    youveSuccessfullyAddedANewCategory:
      "Anda berjaya menambahkan kategori baru",
    addNewCategory: "Tambah Kategori Baru",
    addCategory: "Tambah Kategori",
    categoryName: "Nama kategori",
    pleaseEnterCategoryName: "Sila masukkan nama kategori",
    stampsTarget: "Sasaran Setem",
    sendInventory: "Hantar Inventori",
    productDetails: "Maklumat produk",
    youveSuccessfullyEditedThisCategory: "Anda berjaya mengedit kategori ini",
    update: "Kemas kini",
    categoryList: "Senarai Kategori",
    categories: "Kategori",
    enterQuantityToUpdate: "Masukkan kuantiti untuk dikemas kini",
    inventorySentSuccessfully: "Inventori berjaya dihantar!",
    updateInventory: "Kemas kini Inventori",
    currentQuantity: "Kuantiti Semasa",
    pleaseEnterQuantityToAdd: "Sila masukkan kuantiti yang ingin anda tambah",
    pleaseSelectABranch: "Sila pilih Cawangan",
    searchForBranch: "Cari cawangan",
    youCantSendMoreThanStock:
      "Anda tidak boleh menghantar lebih banyak daripada yang anda ada dalam stok",
    send: "Hantar",
    pleaseEnterQuantityToSend: "Sila masukkan kuantiti yang ingin anda hantar",
    productNameIsRequiredOnRow: "nama produk diperlukan secara berturut-turut",
    productCategoryIsRequiredOnRow:
      "kategori produk diperlukan secara berturut-turut",
    productPriceIsRequiredOnRow:
      "harga produk diperlukan secara berturut-turut",
    productUnitIsRequiredOnRow: "unit produk diperlukan secara berturut-turut",
    productQuantityIsRequiredOnRow:
      "kuantiti produk diperlukan secara berturut-turut",
    productVariantsRequireTracking: "varian produk memerlukan penjejakan!",
    pleaseAddVariantClickButton:
      "Sila tambahkan varian dengan mengklik butang tambah!",
    totalVariantsMoreThanSelectedQuantity:
      "Jumlah Varian lebih banyak daripada kuantiti produk yang dipilih, Pls mengurangkan kuantiti varian",
    productEditedSuccessfully: "Produk berjaya diedit!",
    fileTooLargeLessThan4Mb:
      "Saiz fail terlalu besar! Saiz fail mestilah kurang daripada 4MB.",
    suchVariantAlreadyExist: "Varian seperti itu sudah ada",
    addVariants: "Tambah Varian",
    editProduct: "Edit Produk",
    pleaseEnterProductName: "Sila Masukkan Nama Produk",
    pleaseEnterProductPrice: "Sila Masukkan Harga Produk",
    pleaseEnterProductOriginalPrice: "Sila Masukkan Harga Asal Produk",
    productDescription: "Penerangan Produk",
    selectProductCategory: "Pilih Kategori Produk",
    pleaseSelectProductCategory: "Sila pilih kategori produk",
    productCostPrice: "Harga Kos Produk",
    productSellingPrice: "Harga Jual Produk",
    productOriginalPrice: "Harga Asal Produk",
    maxFileSizeAllowedIs4Mb: "Maks. Ukuran Fail yang dibenarkan ialah 4mb",
    productsWithPicturesArePublished:
      "Produk dengan gambar diterbitkan di Discover untuk menerima pesanan",
    shouldThisProductBeTracked: "Sekiranya produk ini dijejaki?",
    pleaseSelectStockUnit: "Sila pilih unit stok",
    stockUnit: "Unit Stok",
    bag: "TAS",
    bottle: "BOTOL",
    bunch: "BANYAK",
    can: "BOLEH",
    carton: "KARTON",
    crate: "KRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PEK",
    pair: "PAIR",
    pieces: "PAKAIAN",
    plate: "PINGGANG",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "KEDAI",
    pleaseEnterProductQuantity: "Sila masukkan kuantiti produk",
    productQuantity: "Kuantiti Produk",
    isThisProductTaxed: "Adakah produk ini dikenakan cukai?",
    selectTaxType: "Pilih Jenis Cukai",
    pleaseSelectTaxType: "Sila pilih Jenis Cukai",
    progressive: "Progresif",
    proportional: "Berkadar",
    pleaseEnterProductTaxRate: "Sila masukkan kadar cukai produk",
    doesProductHaveVariants: "Adakah produk ini mempunyai varian?",
    type: "Jenis",
    value: "Nilai",
    pleaseEnterVariantType: "Sila masukkan jenis varian",
    pleaseEnterVariantValue: "Sila masukkan nilai varian",
    pleaseEnterVariantPrice: "Sila masukkan harga varian",
    pleaseEnterVariantQuantity: "Sila masukkan kuantiti varian",
    productDeletedSuccessfully: "Produk berjaya dipadamkan!",
    categoryDeletedSuccessfully: "Kategori berjaya dipadamkan!",
    toDeleteThisProduct: "untuk memadam Produk ini",
    invalidProductQuantity: "Kuantiti Produk Tidak Sah",
    quantityAddedIsOverStock:
      "Kuantiti yang anda tambah melebihi jumlah yang anda ada.",
    itemInventoryNotTracked: "Inventori item tidak dijejaki",
    addBulkQuantity: "Tambah Kuantiti Pukal",
    enterBulkQuantity: "Masukkan Kuantiti Pukal",
    pleaseEnterBulkQuantity: "Sila masukkan Kuantiti Pukal",
    youveSuccessfullyAddedANewProduct: "Anda berjaya menambahkan produk baru!",
    pleaseEnterProductSellingPrice: "Sila masukkan harga jualan produk",
    doYouWantToTrackProductStock: "Adakah anda ingin mengesan stok produk?",
    sellingPrice: "Harga jualan",
    setProductExpiryReminder: "Tetapkan Peringatan Tamat Tempoh Produk",
    productExpiryDate: "Tarikh luput produk",
    startRemindingFrom: "Mula Mengingatkan Dari",
    productSuppliesAddedSuccessfully:
      "Anda berjaya menambahkan bekalan produk.",
    addProductSupplies: "Tambah Bekalan Produk",
    pleaseSelectSupplier: "Sila Pilih Pembekal",
    nameOfProduct: "Nama Produk",
    pleaseSelectProduct: "Sila Pilih Produk",
    productVariant: "Varian Produk",
    pleaseSelectAVariant: "Sila Pilih Varian",
    pleaseEnterUnitPrice: "Sila Masukkan Harga Unit",
    businessBranch: "Cawangan Perniagaan",
    pleaseSelectBranch: "Sila pilih cawangan",
    youveSuccessfullyAddedANewSupplier:
      "Anda berjaya menambahkan pembekal baru",
    addSupplier: "Tambah Pembekal",
    pleaseEnterSupplierEmail: "Sila masukkan e-mel pembekal",
    pleaseAddADescription: "Sila tambah keterangan",
    anErrorOccuredProductsDeleted:
      "Ralat berlaku semasa menjalankan operasi. Harap perhatikan, beberapa produk mungkin telah dihapus dalam prosesnya",
    bulkDelete: "Padam Pukal",
    youAreAboutToDelete: "Anda akan memadam",
    product: "Produk",
    isDueToRedeem: "adalah kerana penebusan",
    aReward: "ganjaran",
    pleaseSelectCustomerToReeemReward:
      "Pilih pelanggan untuk menebus ganjaran.",
    youHaveNoLoyaltyProgramRunning:
      "Anda tidak menjalankan program kesetiaan aktif",
    customerHhasNoPointsInLoyaltyProgram:
      "Pelanggan tidak mempunyai mata dalam program kesetiaan ini",
    proceedWithPayment: "Teruskan dengan Pembayaran?",
    youAreAboutToPayForTransactionUsingPoints:
      "Anda akan membayar transaksi menggunakan mata.",
    customerHas: "Pelanggan Mempunyai",
    worth: "bernilai",
    andIsNotSufficientToPayForTransaction:
      "dan tidak mencukupi untuk membayar transaksi ini. Adakah mereka ingin menambahkan baki menggunakan kaedah pembayaran lain?",
    addCustomerLoyalty: "Tambahkan Kesetiaan Pelanggan",
    pleaseAddCustomerFirst: "Sila tambah atau pilih pelanggan terlebih dahulu.",
    pleaseWaitWhileWeGetReady: "Tunggu, sementara kami bersiap sedia",
    lowStock: "Saham rendah",
    pleaseEnterAmountTendered: "Sila masukkan jumlah yang ditender",
    areYouSureToBookSaleOffline:
      "Adakah anda pasti mahu menempah penjualan ini di luar talian?",
    saleWouldBeBookedAutomatically:
      "Jualan akan dibuat secara automatik apabila anda menghidupkan internet",
    offlineSalesBookingCancelled: "Tempahan penjualan luar talian dibatalkan",
    covid19Message:
      "COVID19: Basuh tangan anda dengan sabun atau Bersihkan untuk menghentikan penyebaran. Sentiasa Selamat Selalu",
    saleSuccessfullyRecorded: "Jualan Berjaya Dicatat!",
    sendReceiptToEmail: "Hantar resit ke E-mel",
    sendThankYouSms: "Hantar SMS terima kasih",
    sendShippingDetails: "Hantar maklumat Penghantaran",
    addLoyalty: "Tambah Kesetiaan",
    searchCustomerNameOrNumber: "Cari nama atau nombor pelanggan",
    clickTheSearchCustomerBox: "Klik Kotak Carian dan Kad Imbas Pelanggan",
    enterProductPrice: "Masukkan Harga Produk",
    enterPriceFor: "Masukkan Harga untuk",
    searchForProduct: "Cari produk",
    all: "Semua",
    backToDashboard: "Kembali ke Papan Pemuka",
    viewDraftSales: "Lihat Draf Jualan",
    variantSelected: "varian dipilih",
    variant: "pelbagai",
    thePreviousVariantSelectionNotAvailable:
      "Pilihan varian sebelumnya tidak tersedia untuk varian baru yang dipilih berdasarkan harga, sila ubah pilihan anda.",
    pleaseSupplyPositiveQuantityNumber: "Sila berikan nombor kuantiti posif",
    lowStockFor: "Stok rendah untuk",
    clearVariants: "Varian yang jelas",
    pleaseEnterQuantity: "Sila masukkan Kuantiti",
    picture: "Gambar",
    redemptionToken: "Token Penebusan",
    token: "Token",
    totalSpent: "Jumlah yang Dibelanjakan",
    confirmPayment: "Sahkan Pembayaran",
    hasPaymentBeenMade: "Adakah Pembayaran berjaya diproses?",
    enterTransactionReference: "Masukkan rujukan transaksi yang anda bayar",
    pleaseSelectACustomer: "Sila pilih pelanggan!",
    areYouSureToApplyDiscount: "Adakah anda pasti mahu menggunakan diskaun?",
    addYourBankAccountToEnableUssd:
      "Tambahkan akaun bank anda untuk mengaktifkan pemindahan USSD Segera melalui uPay",
    totalAmountToPay: "Jumlah Amaun yang Perlu Dibayar",
    doYouWantToCancelTransaction:
      "Adakah anda ingin membatalkan transaksi ini?",
    savePrintBill: "Simpan / Cetak Bil",
    enterAmountCollectedForCustomer:
      "Masukkan jumlah yang dikumpulkan untuk pelanggan",
    recordSale: "Rekod Jualan",
    checkOutWith: "Lihat dengan",
    instantTransfer: "Pemindahan Segera",
    dialTheUSSDCode: "Dail kod USSD",
    pleaseSelectABank: "Sila pilih bank",
    payWithUSSD: "Bayar Dengan USSD",
    sendBillTo: " - Hantar Bil ke",
    waitingForUSSDTransfer: "Menunggu Pemindahan USSD",
    percent: "Peratus",
    applyDiscount: "Memohon Diskaun",
    thisBillHasBeenSaved: "Rang Undang-Undang ini telah disimpan",
    saveDraft: "Menyimpan draf",
    pleaseTypeANameToIdentifyCustomer:
      "Sila masukkan nama untuk mengenal pasti pelanggan",
    paymentDetails: "Butiran pembayaran",
    basePrice: "Harga Asas",
    staff: "Kakitangan",
    subTotal: "Jumlah kecil",
    durationMonths: "Tempoh (bulan)",
    selectADuration: "Pilih Tempoh",
    oneMonth: "1 bulan",
    twoMonths: "2 bulan",
    threeMonths: "3 bulan",
    sixMonths: "6 bulan",
    twelveMonths: "12 bulan",
    eighteenMonths: "18 Bulan",
    twentyFourMonths: "24 Bulan",
    twoMonthsFree: "(Percuma 2 Bulan)",
    threeMonthsFree: "(Percuma 3 Bulan)",
    fiveMonthsFree: "(Percuma 5 Bulan)",
    yourAccountHasBeen: "Akaun anda telah",
    renewed: "diperbaharui",
    upgraded: "dinaik taraf",
    successfully: "berjaya",
    yourSubscription: "Langganan Anda",
    youAreCurrentlyEnrolledOnA: "Anda kini mendaftar di",
    pleaseChooseAPaymentOption: "Sila Pilih Pilihan Pembayaran",
    planRenewal: "Pembaharuan Rancangan",
    planUpgrade: "Rancang Peningkatan",
    pleaseSelectDurationToPayFor: "Pilih Tempoh yang ingin anda bayar",
    staffAccounts: "Akaun Kakitangan",
    ecommerce: "E-dagang",
    pleaseSelectAPlan: "Sila pilih rancangan",
    includeAddons: "Sertakan Alat Tambah",
    viewTransactions: "Lihat Urus Niaga",
    customerHasNoCompletedTansactions:
      "Pelanggan belum menyelesaikan transaksi",
    branch: "Cawangan",
    enterNumberOfEcommerceBranches: "Masukkan Bilangan Cawangan E-dagang",
    enterNumberOfEcommerceBranchesToPay:
      "Masukkan Bilangan Cawangan E-dagang yang ingin anda Bayar",
    ecommerceIntegration: "Integrasi E-dagang",
    enterNumberOfBranches: "Masukkan Bilangan Cawangan",
    enterNumberOfAdditionalBranchesToPay:
      "Masukkan Bilangan Cawangan Tambahan Yang Anda Ingin Bayar",
    enterNumberOfStaffs: "Masukkan Bilangan Kakitangan",
    enterNumberOfStaffsToPayFor:
      "Masukkan Bilangan Kakitangan Yang Anda Ingin Bayar",
    discountApplies: "Diskaun Berlaku",
    starsOnThe: "bintang di",
    offer: "tawaran",
    get: "Dapatkan",
    moreStarsToRedeem: "lebih banyak bintang untuk ditebus",
    taxVat: "Cukai (PPN)",
    callCashierToCompletePayment:
      "Hubungi Juruwang untuk Melengkapkan Pembayaran",
    receipt: "Resit",
    dear: "Sayang",
    thankYouForYourOrderFindGoods:
      "Terima kasih atas pesanan anda. Cari barang berikut yang dibekalkan, seperti yang dipersetujui.",
    shippingNote: "Nota Penghantaran",
    enterShippingNote: "Masukkan Nota Penghantaran",
    shippingAddress: "Alamat penghantaran",
    enterShippingAddress: "Masukkan alamat Penghantaran",
    wellDoneYouAreDueToRedeem: "Bagus! Anda akan ditebus",
    toGetYourRewardNextVisit:
      "untuk mendapatkan ganjaran anda pada lawatan anda yang seterusnya. Terima kasih",
    pointsOnThe: "Titik pada",
    morePointsToRedeem: "lebih banyak mata untuk ditebus",
    showCode: "Tunjukkan kod",
    toGetYourRewardOnNextVisit:
      "untuk mendapatkan ganjaran anda pada lawatan anda yang seterusnya. Terima kasih",
    earn: "Perolehi",
    delivaryNote: "Nota Penghantaran",
    draftSales: "Jualan Draf",
    startDate: "Tarikh mula",
    endDate: "Tarikh tamat",
    orders: "Pesanan",
    checkout: "daftar keluar",
    noProductItem: "Tiada Item Produk",
    selectProductImage: "Pilih Imej Produk",
    selectCountry: "Pilih negara",
    selectRegion: "Pilih Negeri / Wilayah",
    printProductTag: "Cetak Tag Produk",
    transactionReference: "Rujukan transaksi",
    Cashier: "Juruwang",
    Manager: "Pengurus",
    Owner: "Pemilik",
    Admin: "Pentadbir",
    addPartners: "Tambah Rakan Kongsi",
    addNewLoyaltyPartner: "Tambah Rakan Kongsi Kesetiaan Baharu",
    pleaseEnterCompanyName: "Sila masukkan Nama Syarikat",
    companyName: "nama syarikat",
    pleaseEnterCompanyRepName: "Sila masukkan Nama wakil syarikat",
    companyRepName: "Nama Wakil Syarikat",
    pleaseEnterCompanyRepEmail: "Sila masukkan e-mel wakil syarikat",
    companyRepEmail: "E-mel wakil syarikat",
    pleaseEnterCompanyRepPhone: "Sila masukkan nombor telefon wakil syarikat",
    companyRepPhone: "Nombor Telefon wakil syarikat",
    addReward: "Tambah ganjaran",
    pleaseEnterRewardName: "Sila masukkan nama ganjaran",
    rewardName: "Nama Ganjaran",
    rewardQuantity: "Kuantiti Ganjaran",
    rewardDescription: "Penerangan Ganjaran",
    rewardType: "Jenis Ganjaran",
    pleaseEnterRewardType: "Sila masukkan jenis ganjaran",
    pleaseEnterRewardQuantity: "Sila masukkan kuantiti ganjaran",
    pleaseEnterRewardDescription: "Sila masukkan perihalan ganjaran",
    fineDining: "Tempat makan yang baik",
    luxuryFashion: "Fesyen Mewah",
    hotels: "Hotel",
    travel: "Perjalanan",
    foodAndBeverage: "Makanan dan minuman",
    fashion: "Fesyen",
    health: "Kesihatan",
    furniture: "Perabot",
    entertainment: "Hiburan",
    automobile: "kereta",
    education: "Pendidikan",
    beautyAndSpa: "Kecantikan dan Spa",
    staycation: "Staycation",
    events: "Peristiwa",
    trips: "Perjalanan",
    oilAndGas: "Minyak dan gas",
    laundry: "Dobi",
    partnerCategory: "Kategori Rakan Kongsi",
    freeItem: "Item Percuma",
  },
  Malayalam: {
    cashier: "കാഷ്യർ",
    manager: "മാനേജർ",
    owner: "ഉടമ",
    online: "ഓൺലൈൻ",
    offline: "ഓഫ്‌ലൈൻ",
    changePassword: "പാസ്വേഡ് മാറ്റുക",
    currentPasswordMessage: "ദയവായി നിങ്ങളുടെ നിലവിലെ പാസ്‌വേഡ് നൽകുക",
    passwordMessage: "ദയവായി നിങ്ങളുടെ പാസ്‌വേഡ് നൽകുക",
    currentPassword: "ഇപ്പോഴത്തെ പാസ്സ്വേർഡ്",
    password: "password",
    confirmPasswordMessage: "ദയവായി നിങ്ങളുടെ പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക!",
    confirmPassword: "പാസ്വേഡ് സ്ഥിരീകരിക്കുക",
    sendViaEmail: "ഇമെയിൽ വഴി അയയ്ക്കുക",
    sendViaWhatsapp: "WhatsApp വഴി അയയ്ക്കുക",
    downloadPdf: "PDF ഡൗൺലോഡ് ചെയ്യുക",
    paid: "പണമടച്ചു",
    unpaid: "ശമ്പളമില്ലാത്ത",
    partial: "ഭാഗികമായ",
    closeInvoice: "നിങ്ങൾക്ക് ഇൻവോയ്സ് അടയ്‌ക്കണോ?",
    closeInvoiceConfirmation:
      "ഇൻവോയ്സ് സംരക്ഷിക്കപ്പെടണമെന്നില്ല. നിങ്ങൾക്ക് അടയ്ക്കണോ?",
    yes: "അതെ",
    no: "ഇല്ല",
    invoice: "ഇൻവോയ്സ്",
    wasDeducted: "കുറച്ചിരുന്നു",
    for: "വേണ്ടി",
    item: "ഇനം",
    addProduct: "ഉൽപ്പന്നം ചേർക്കുക",
    paymentReference: "പേയ്മെന്റ് റഫറൻസ്",
    amountPaid: "അടച്ച തുക",
    discountAmount: "ഡിസ്കൗണ്ട് തുക",
    amountDue: "കുടിശ്ശിക തുക",
    amount: "തുക",
    dueDate: "അവസാന തീയതി",
    paymentType: "പേയ്മെന്റ് തരം",
    card: "കാർഡ്",
    cash: "കാശ്",
    bankTransfer: "ബാങ്ക് കൈമാറ്റം",
    paymentMessage: "പേയ്മെന്റ് സന്ദേശം",
    description: "വിവരണം",
    sendReceipt: "രസീത് അയയ്ക്കുക",
    delete: "ഇല്ലാതാക്കുക",
    save: "രക്ഷിക്കും",
    resend: "വീണ്ടും അയയ്ക്കുക",
    saveAndSend: "രക്ഷിക്കും",
    invoiceSaved: "ഇൻവോയ്സ് സംരക്ഷിച്ചു!",
    selectPaymentMethod: "ഒരു പേയ്മെന്റ് രീതി തിരഞ്ഞെടുക്കുക!",
    selectCustomer: "ദയവായി ഒരു ഉപഭോക്താവിനെ തിരഞ്ഞെടുക്കുക!",
    cartEmptyError:
      "കാർട്ട് ലിസ്റ്റ് ശൂന്യമായിരിക്കരുത്, ഇൻവോയ്സ് അടച്ച് കാർട്ടിലേക്ക് ഇനം ചേർക്കുക!",
    subscriptionExpired:
      "നിങ്ങളുടെ സബ്സ്ക്രിപ്ഷൻ കാലഹരണപ്പെട്ടു, നിങ്ങളുടെ അക്കൗണ്ട് ഇപ്പോൾ പരിമിതമാണ്. നിങ്ങളുടെ അക്കൗണ്ട് പുതുക്കുന്നതിന് താഴെയുള്ള ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുക",
    renew: "പുതുക്കുക",
    holdOn: "ഹോൾഡ് ഓൺ ചെയ്യുക",
    customerBank: "ഉപഭോക്തൃ ബാങ്ക്",
    cancel: "റദ്ദാക്കുക",
    selectACustomer: "ഉപഭോക്താവിനെ തിരഞ്ഞെടുക്കുക",
    invoiceSuccessfulPdfError:
      "ഇൻവോയ്സ് വിജയകരമായി സൃഷ്ടിച്ചു, പക്ഷേ PDF ജനറേഷൻ പതിവിലും കൂടുതൽ സമയമെടുക്കുന്നു. ദയവായി ഉടൻ തന്നെ വീണ്ടും പരിശോധിക്കുക.",
    downloadingInvoice: "ഇൻവോയ്സ് ഡൗൺലോഡ് ചെയ്യുന്നു",
    downloadingReceipt: "രസീത് ഡൗൺലോഡ് ചെയ്യുന്നു",
    whatsappReceiptError:
      "WhatsApp വഴി രസീത് അയയ്ക്കുന്നതിൽ ഒരു പിശക് സംഭവിച്ചു, വീണ്ടും ശ്രമിക്കുക.",
    receiptToWhatsapp: "രസീത് WhatsApp- ലേക്ക് കൈമാറി",
    thankYouForPatronage: "നിങ്ങളുടെ രക്ഷാകർതൃത്വത്തിന് നന്ദി",
    hereIsYourReceipt: "നിങ്ങളുടെ പേയ്മെന്റ് രസീത് ഇതാ",
    errorSendingEmailReceipt:
      "ഇ-മെയിൽ വഴി രസീത് അയയ്ക്കുന്നതിൽ ഒരു പിശക് സംഭവിച്ചു, ദയവായി വീണ്ടും ശ്രമിക്കുക അല്ലെങ്കിൽ പിന്തുണയുമായി ബന്ധപ്പെടുക",
    receiptSentToEmail: "ഉപഭോക്താവിന്റെ ഇമെയിലിലേക്ക് രസീത് അയച്ചിട്ടുണ്ട്",
    invoiceSentToEmail: "ഉപഭോക്താവിന്റെ ഇമെയിലിലേക്ക് ഇൻവോയ്സ് അയച്ചിട്ടുണ്ട്",
    invoiceSuccessWhatsappError:
      "ഇൻവോയ്സ് വിജയകരമായി സൃഷ്‌ടിച്ചെങ്കിലും വാട്ട്‌സ്ആപ്പിലേക്ക് അയയ്ക്കുന്നതിൽ ഒരു പിശക് സംഭവിച്ചു. ഇൻവോയ്സ് പട്ടികയിൽ വീണ്ടും ശ്രമിക്കുക",
    invoiceSuccessfulEmailError:
      "ഇൻവോയ്സ് വിജയകരമായി സൃഷ്ടിച്ചു, പക്ഷേ ഇ-മെയിലായി അയയ്ക്കുമ്പോൾ ഒരു പിശക് സംഭവിച്ചു. ഇൻവോയ്സ് ലിസ്റ്റിൽ നിന്ന് വീണ്ടും ശ്രമിക്കുക",
    invoiceSentToWhatsapp: "ഇൻവോയ്സ് WhatsApp- ലേക്ക് കൈമാറി",
    hello: "ഹലോ",
    pleaseDownloadInvoice: "ദയവായി ഇൻവോയ്സ് ഡൗൺലോഡ് ചെയ്യുക",
    pleaseDownloadReceipt: "ദയവായി രസീത് ഡൗൺലോഡ് ചെയ്യുക",
    from: "മുതൽ",
    email: "ഇമെയിൽ",
    upgrade: "നവീകരിക്കുക",
    youAreOnFreePlan: "നിങ്ങൾ ഒരു സൗജന്യ പ്ലാനിലാണ്.",
    clickOn: "ക്ലിക്ക് ചെയ്യുക",
    yourPlanInFewSteps: " നിങ്ങളുടെ സബ്സ്ക്രിപ്ഷൻ കുറച്ച് ദ്രുത ഘട്ടങ്ങളിൽ.",
    to: "വരെ",
    yourSubscriptionHasExpired:
      "നിങ്ങളുടെ സബ്സ്ക്രിപ്ഷൻ കാലഹരണപ്പെട്ടു, നിങ്ങളുടെ അക്കൗണ്ട് ഇപ്പോൾ പരിമിതമാണ്.",
    days: "ദിവസങ്ങളിൽ",
    yourSubscriptionExpiresIn:
      "നിങ്ങളുടെ ലോയ്സ്റ്റാർ സബ്സ്ക്രിപ്ഷൻ കാലഹരണപ്പെടുന്നു",
    createAcount: "ഒരു അക്കൗണ്ട് സൃഷ്ടിക്കുക",
    signIn: "സൈൻ ഇൻ",
    continue: "തുടരുക",
    enterOtp: "OTP പിൻ നൽകുക",
    enterValidOtp: "ദയവായി ഒരു സാധുവായ PIN നൽകുക",
    pin: "പിൻ",
    tokenSentToMail: "നിങ്ങളുടെ ഇമെയിലിലേക്ക് ഒരു ടോക്കൺ അയച്ചിട്ടുണ്ട്",
    passwordResetSuccessful: "പാസ്‌വേഡ് പുനtസജ്ജീകരണം വിജയകരമായിരുന്നു",
    somethingWentWrong: "എന്തോ കുഴപ്പം സംഭവിച്ചു!",
    resetPassword: "പാസ്‌വേഡ് പുനസജ്ജമാക്കുക",
    iHaveResetCode: "എനിക്ക് ഒരു പാസ്വേഡ് റീസെറ്റ് കോഡ് ഉണ്ട്",
    pleaseEnterEmail: "ദയവായി നിങ്ങളുടെ ഇമെയിൽ നൽകുക",
    aTokenWillBeSentToEmail: "നിങ്ങളുടെ ഇമെയിലിലേക്ക് ഒരു ടോക്കൺ അയയ്ക്കും",
    enterEmail: "നിങ്ങളുടെ ഇമെയിൽ നൽകുക",
    sendinYourToken: "നിങ്ങളുടെ ടോക്കൺ അയയ്ക്കുന്നു ...",
    makeSureItMatchesPassword:
      "ഇത് നിങ്ങളുടെ പുതിയ പാസ്‌വേഡുമായി പൊരുത്തപ്പെടുന്നുവെന്ന് ഉറപ്പാക്കുക",
    pleaseChooseNewPassword: "ദയവായി ഒരു പുതിയ പാസ്‌വേഡ് തിരഞ്ഞെടുക്കുക",
    chooseNewPassword: "ഒരു പുതിയ പാസ്‌വേഡ് തിരഞ്ഞെടുക്കുക",
    enterNewPassword: "സ്ഥിരീകരിക്കാൻ നിങ്ങളുടെ പുതിയ പാസ്‌വേഡ് നൽകുക",
    enterTokenSent: "നിങ്ങളുടെ മെയിൽ അയച്ച ടോക്കൺ നൽകുക",
    resetToken: "ടോക്കൺ റീസെറ്റ് ചെയ്യുക",
    resettingPassword: "നിങ്ങളുടെ പാസ്‌വേഡ് പുനsetസജ്ജമാക്കുന്നു ...",
    signUp: "സൈൻ അപ്പ് ചെയ്യുക",
    adminSignInWithEmail:
      " അഡ്മിൻ ഇമെയിൽ ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യുമ്പോൾ സ്റ്റാഫ് ഉപയോക്തൃനാമത്തിൽ സൈൻ ഇൻ ചെയ്യുന്നു.",
    pleaseEnterEmailOrUsername:
      "ദയവായി നിങ്ങളുടെ ഇമെയിൽ അല്ലെങ്കിൽ ഉപയോക്തൃനാമം നൽകുക",
    emailOrUsername: "ഇമെയിൽ അല്ലെങ്കിൽ ഉപയോക്തൃനാമം",
    pleaseEnterPassword: "ദയവായി പാസ്‌വേഡ് നൽകുക",
    createAnAccount: "ഒരു അക്കൗണ്ട് സൃഷ്ടിക്കുക",
    forgotPassword: "പാസ്വേഡ് മറന്നോ?",
    enterPhoneNumber: "ഫോൺ നമ്പർ നൽകുക",
    personalData: "വ്യക്തിപരമായ വിവരങ്ങള്",
    validateConfirmationCOde: "സ്ഥിരീകരണ കോഡ് സാധൂകരിക്കുക",
    pleaseEnterFirstName: "ദയവായി നിങ്ങളുടെ ആദ്യ പേര് നൽകുക",
    pleaseEnterPhoneNumber: "ദയവായി നിങ്ങളുടെ ഫോൺ നമ്പർ നൽകുക",
    pleaseEnterLastName: "ദയവായി നിങ്ങളുടെ അവസാന നാമം നൽകുക",
    pleaseEnterBusinessName: "നിങ്ങളുടെ ബിസിനസ്സ് പേര് നൽകുക",
    firstName: "പേരിന്റെ ആദ്യഭാഗം",
    lastName: "പേരിന്റെ അവസാന ഭാഗം",
    businessName: "ബിസിനസ് നാമം",
    previous: "മുമ്പത്തെ",
    next: "അടുത്തത്",
    pleaseSelectBusinessCategory: "നിങ്ങളുടെ ബിസിനസ് വിഭാഗം തിരഞ്ഞെടുക്കുക",
    pleaseEnterValidEmail: "ദയവായി സാധുവായ ഒരു ഇമെയിൽ നൽകുക",
    pleaseEnterPostCode: "ദയവായി പോസ്റ്റ് കോഡ് നൽകുക",
    postCode: "പോസ്റ്റ് കോഡ്",
    phoneNumberInUse: "ഈ ഫോൺ നമ്പർ ഇതിനകം ഉപയോഗത്തിലാണ്!",
    emailInUse: "ഈ ഇമെയിൽ ഇതിനകം ഉപയോഗത്തിലാണ്!",
    foodAndDrinks: "ഭക്ഷണപാനീയങ്ങൾ",
    salonAndBeauty: "സലൂണും സൗന്ദര്യവും",
    fashionAndAccessories: "ഫാഷനും അനുബന്ധ ഉപകരണങ്ങളും",
    gymAndFitness: "ജിമ്മും ഫിറ്റ്നസും",
    travelAndHotel: "യാത്രയും ഹോട്ടലും",
    homeAndGifts: "വീടും സമ്മാനങ്ങളും",
    washingAndCleaning: "കഴുകലും വൃത്തിയാക്കലും",
    gadgetsAndElectronics: "ഗാഡ്ജറ്റുകളും ഇലക്ട്രോണിക്സും",
    groceries: "പലചരക്ക് സാധനങ്ങൾ",
    others: "മറ്റുള്ളവർ",
    submit: "സമർപ്പിക്കുക",
    accountCreatedSuccessful: "നിങ്ങളുടെ അക്കൗണ്ട് വിജയകരമായി സൃഷ്ടിച്ചു.",
    pleaseEnterAddress: "ദയവായി നിങ്ങളുടെ വിലാസം നൽകുക",
    addressLine1: "വിലാസ ലൈൻ 1",
    addressLine2: "വിലാസം വരി 2",
    choosePassword: "ഒരു പാസ്വേഡ് തിരഞ്ഞെടുക്കുക",
    passwordMustBe6Characters: "പാസ്‌വേഡ് കുറഞ്ഞത് 6 പ്രതീകങ്ങൾ ആയിരിക്കണം.",
    howDidYouHearLoystar: "ലോയ്സ്റ്റാറിനെക്കുറിച്ച് നിങ്ങൾ എങ്ങനെ കേട്ടു?",
    referralCode: "റഫറൽ കോഡ്",
    byClickingTheButton:
      " ചുവടെയുള്ള ബട്ടൺ ക്ലിക്കുചെയ്യുന്നതിലൂടെ, നിങ്ങൾ ഇത് അംഗീകരിക്കുന്നു",
    termsAndSevice: "നിബന്ധനകൾ",
    wereCreatingAccount: "ഞങ്ങൾ നിങ്ങളുടെ അക്കൗണ്ട് സൃഷ്ടിക്കുന്നു",
    proceed: "തുടരുക",
    verificationCodeMustBe6: "പരിശോധനാ കോഡ് 6 അക്കങ്ങളായിരിക്കണം",
    pleaseEnter6DigitCode: "ദയവായി 6 അക്ക കോഡ് നൽകുക",
    enterVerificationCode: "സ്ഥിരീകരണ കോഡ് നൽകുക",
    resendToken: "ടോക്കൺ വീണ്ടും അയയ്ക്കുക",
    verify: "പരിശോധിക്കുക",
    transactions: "ഇടപാടുകൾ",
    todaySales: "ഇന്നത്തെ വിൽപ്പന",
    salesHistory: "വിൽപ്പന ചരിത്രം",
    supplyHistory: "വിതരണ ചരിത്രം",
    new: "പുതിയ",
    invoices: "ഇൻവോയ്സുകൾ",
    disbursements: "വിതരണങ്ങൾ",
    offlineSales: "ഓഫ്‌ലൈൻ വിൽപ്പന",
    products: "ഉൽപ്പന്നങ്ങൾ",
    customers: "ഉപഭോക്താക്കൾ",
    multiLevelLoyalty: "മൾട്ടി ലെവൽ ലോയൽറ്റി",
    loyaltyPrograms: "ലോയൽറ്റി പ്രോഗ്രാമുകൾ",
    members: "അംഗങ്ങൾ",
    appStore: "അപ്ലിക്കേഷൻ സ്റ്റോർ",
    orderMenu: "ഓർഡർ മെനു",
    settings: "ക്രമീകരണങ്ങൾ",
    staffAndBranches: "ജീവനക്കാരും ശാഖകളും",
    myAccount: "എന്റെ അക്കൗണ്ട്",
    switchToSellMode: "സെൽ മോഡിലേക്ക് മാറുക",
    signOut: "സൈൻ ഔട്ട്",
    getFreeSubscription: "സൗജന്യ സബ്സ്ക്രിപ്ഷൻ നേടുക",
    onlyNumbersAllowed: "നമ്പറുകൾ മാത്രമേ അനുവദിക്കൂ",
    yourAccountMustBe10Digits:
      "നിങ്ങളുടെ അക്കൗണ്ട് നമ്പർ 10 അക്ക നമ്പർ ആയിരിക്കണം",
    yourBvnMustBe11: "നിങ്ങളുടെ ബിവിഎൻ 11 അക്ക നമ്പർ ആയിരിക്കണം",
    pleaseSelectBank: "ദയവായി നിങ്ങളുടെ ബാങ്ക് തിരഞ്ഞെടുക്കുക",
    selectYourBank: "നിങ്ങളുടെ ബാങ്ക് തിരഞ്ഞെടുക്കുക",
    enterBankAccountNumber: "ബാങ്ക് അക്കൗണ്ട് നമ്പർ നൽകുക",
    enterBvnNumber: "നിങ്ങളുടെ BVN നൽകുക",
    connectBankAccount: "ബാങ്ക് അക്കൗണ്ട് ബന്ധിപ്പിക്കുക",
    passwordResetSuccessfulAndSignOut:
      "നിങ്ങളുടെ പാസ്‌വേഡ് വിജയകരമായി പുനtസജ്ജമാക്കി. വീണ്ടും സൈൻ ഇൻ ചെയ്യുന്നതിന് താഴെയുള്ള സൈൻ buttonട്ട് ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുക",
    enterCurrentPassword: "നിലവിലെ പാസ്‌വേഡ് നൽകുക",
    pleaseEnterCurrentPassword: "ദയവായി നിലവിലെ പാസ്‌വേഡ് നൽകുക",
    phoneNumber: "ഫോൺ നമ്പർ",
    sex: "ലൈംഗികത",
    dateOfBirth: "ജനനത്തീയതി",
    state: "സംസ്ഥാനം",
    country: "രാജ്യം",
    loyaltyId: "ലോയൽറ്റി ഐഡി",
    createdAt: "ൽ സൃഷ്ടിച്ചത്",
    noOfTransactionsMade: "നടത്തിയ ഇടപാടുകളുടെ എണ്ണം",
    yourDownloadWillStart: "നിങ്ങളുടെ ഡൗൺലോഡ് ഒരു നിമിഷം തുടങ്ങും",
    exportCustomers: "കയറ്റുമതി ഉപഭോക്താക്കൾ",
    youhaveSuccessfullyToppedUp:
      "നിങ്ങളുടെ എസ്എംഎസ് യൂണിറ്റുകൾ വിജയകരമായി പൂർത്തിയായി.",
    youNowHave: "നിങ്ങൾക്ക് ഇപ്പോൾ ഉണ്ട്",
    smsUnits: "എസ്എംഎസ് യൂണിറ്റുകൾ",
    enterNumberOfSmsUnits:
      "നിങ്ങൾ വാങ്ങാൻ ഉദ്ദേശിക്കുന്ന SMS യൂണിറ്റുകളുടെ എണ്ണം നൽകുക",
    pleaseEnterANumericValue: "ഒരു സംഖ്യാ മൂല്യം നൽകുക",
    pay: "പണമടയ്ക്കുക",
    accountEditedSuccessfully: "നിങ്ങളുടെ അക്കൗണ്ട് വിജയകരമായി എഡിറ്റ് ചെയ്തു.",
    youAeCurrentlyOn: "നിങ്ങൾ നിലവിൽ ആണ്",
    plan: "പ്ലാൻ",
    userData: "ഉപയോക്തൃ ഡാറ്റ",
    businessData: "ബിസിനസ്സ് ഡാറ്റ",
    businessCategory: "ബിസിനസ് കാറ്റഗറി",
    pleaseSelectCurrency: "ദയവായി നിങ്ങളുടെ കറൻസി തിരഞ്ഞെടുക്കുക",
    selectYourCurrency: "നിങ്ങളുടെ കറൻസി തിരഞ്ഞെടുക്കുക",
    purchaseMoreSmsUnits:
      "ചുവടെയുള്ള ഫോം ഉപയോഗിച്ച് കൂടുതൽ SMS യൂണിറ്റുകൾ വാങ്ങുക",
    youHave: "നിങ്ങൾക്ക് ഉണ്ട്",
    atLeast4SmsUnitsRrequired:
      "സ്ഥിരീകരണത്തിന് കുറഞ്ഞത് 4 sms യൂണിറ്റുകളെങ്കിലും ആവശ്യമാണ്, ദയവായി ടോപ്പ് അപ്പ് ചെയ്യുക!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'നിങ്ങളുടെ ഉപഭോക്താവിനെ അറിയുക' (KYC) ആവശ്യകതകൾക്ക് അനുസൃതമായി നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് പരിശോധിക്കുക. USSD അല്ലെങ്കിൽ തൽക്ഷണ കൈമാറ്റം വഴി പേയ്‌മെന്റുകൾ ശേഖരിക്കാനും ഉപഭോക്താക്കളിൽ നിന്ന് ഓർഡറുകൾ സ്വീകരിക്കാനും ഇടപാട് സെറ്റിൽമെന്റ് പ്രോസസ്സ് ചെയ്യാനും ഇത് നിങ്ങളെ അനുവദിക്കുന്നു. നൈജീരിയൻ വ്യാപാരികൾക്ക് മാത്രം ബാധകം. ആരംഭിക്കുന്നതിന് ദയവായി ചുവടെയുള്ള ബട്ടൺ അമർത്തുക.",
    reConnectBankAccount: "ബാങ്ക് അക്കൗണ്ട് വീണ്ടും ബന്ധിപ്പിക്കുക",
    accountName: "അക്കൗണ്ട് നാമം",
    accountNumber: "അക്കൗണ്ട് നമ്പർ",
    bankName: "ബാങ്കിന്റെ പേര്",
    verified: "പരിശോധിച്ചു",
    accountDetails: "അക്കൗണ്ട് വിശദാംശങ്ങൾ",
    kycBankAccount: "കെ.വൈ.സി.",
    createTier: "ടയർ സൃഷ്ടിക്കുക",
    fileUploadSuccessful: "ഫയൽ അപ്‌ലോഡ് ചെയ്തു",
    fileUploadFailed: "ഫയൽ അപ്‌ലോഡ് പരാജയപ്പെട്ടു",
    createLoyaltyProgram: "ഒരു ലോയൽറ്റി പ്രോഗ്രാം സൃഷ്ടിക്കുക",
    createLoyalty: "വിശ്വസ്തത സൃഷ്ടിക്കുക",
    name: "പേര്",
    loyaltyArtwork: "വിശ്വസ്ത കലാസൃഷ്‌ടി",
    clickToUpload: "അപ്‌ലോഡ് ചെയ്യാൻ ക്ലിക്ക് ചെയ്യുക",
    amountToPointsRatio: "പോയിന്റുകൾ അനുപാതം",
    points: "പോയിന്റുകൾ",
    recommendedAmountToPointRatio:
      "ശുപാർശ ചെയ്യുന്നത്: ₦ 1 മുതൽ 1 പോയിന്റ് വരെ. അതായത് ഓരോ 1 നായരയും ചെലവഴിക്കുമ്പോൾ, നിങ്ങളുടെ ഉപഭോക്താക്കൾക്ക് 1 പോയിന്റ് ലഭിക്കും",
    pleaseTypeIn: "ദയവായി ടൈപ്പ് ചെയ്യുക",
    toDeleteLoyalty: "ഈ വിശ്വസ്തത ഇല്ലാതാക്കാൻ",
    deleteLoyalty: "വിശ്വസ്തത ഇല്ലാതാക്കുക",
    toConfirm: "സ്ഥിരീകരിക്കാൻ",
    edit: "എഡിറ്റ് ചെയ്യുക",
    pointsAwardedSuccessfully: "വിജയകരമായി ലഭിച്ച പോയിന്റുകൾ.",
    enterPointValueToAward:
      "ഉപഭോക്താക്കൾക്ക് അവാർഡ് നൽകാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്ന പോയിന്റ് മൂല്യം നൽകുക",
    award: "അവാർഡ്",
    awardPointValuesToCustomer: "ഉപഭോക്താവിനുള്ള അവാർഡ് പോയിന്റ് മൂല്യം",
    enrollMembersToLoyalty: "ലോയൽറ്റിയിലേക്ക് അംഗങ്ങളെ എൻറോൾ ചെയ്യുക",
    awardPoints: "അവാർഡ് പോയിന്റുകൾ",
    enroll: "എൻറോൾ ചെയ്യുക",
    home: "വീട്",
    loyalty: "സത്യസന്ധത",
    enrollCustomers: "ഉപഭോക്താക്കളെ എൻറോൾ ചെയ്യുക",
    selected: "തിരഞ്ഞെടുത്തു",
    customer: "ഉപഭോക്താവ്",
    loyaltyActivationSuccessful: "ലോയൽറ്റി ആക്ടിവേഷൻ വിജയകരമാണ്.",
    loyaltyDeactivationSuccessful: "വിശ്വസ്തത നിർജ്ജീവമാക്കുന്നത് വിജയകരമാണ്.",
    viewTier: "ടയർ കാണുക",
    deactivate: "പ്രവർത്തനരഹിതമാക്കുക",
    activate: "സജീവമാക്കുക",
    actions: "പ്രവർത്തനങ്ങൾ",
    nameOfLoyalty: "വിശ്വസ്തതയുടെ പേര്",
    loyaltyStatus: "ലോയൽറ്റി സ്റ്റാറ്റസ് ലോയൽറ്റി സ്റ്റാറ്റസ്",
    numberOfTiers: "നിരകളുടെ എണ്ണം",
    createdOn: "ഓൺ സൃഷ്ടിച്ചത്",
    createATier: "ഒരു നിര സൃഷ്ടിക്കുക",
    stopCreatingTierConfirmation: "ഒരു ശ്രേണി സൃഷ്ടിക്കുന്നത് നിർത്തണോ?",
    stopEditingTierConfirmation: "ഈ ശ്രേണി എഡിറ്റുചെയ്യുന്നത് നിർത്തണോ?",
    nameOfTier: "ടയറിന്റെ പേര്",
    minimumSpendingTarget: "മിനിമം ചെലവ് ലക്ഷ്യം",
    maximumSpendingTarget: "പരമാവധി ചെലവഴിക്കൽ ലക്ഷ്യം",
    minimumSpendingTargetRequired: "മിനിമം ചെലവ് ലക്ഷ്യം ആവശ്യമാണ്",
    maximumSpendingTargetRequired: "പരമാവധി ചെലവ് ലക്ഷ്യം ആവശ്യമാണ്",
    rewardSponsor: "റിവാർഡ് സ്പോൺസർ",
    pleaseChooseARewardSponsor: "ഒരു റിവാർഡ് സ്പോൺസറെ തിരഞ്ഞെടുക്കുക",
    self: "സ്വയം",
    partner: "പങ്കാളി",
    rewardPartner: "റിവാർഡ് പങ്കാളി",
    pleaseSelectRewardPartner:
      "ദയവായി നിങ്ങളുടെ റിവാർഡ് പങ്കാളിയെ തിരഞ്ഞെടുക്കുക",
    rewards: "റിവാർഡുകൾ",
    pleaseSelectAReward: "ഒരു റിവാർഡ് തിരഞ്ഞെടുക്കുക",
    instructionsOnRedeemingReward:
      "ഉപഭോക്താവ് എങ്ങനെയാണ് റിവാർഡ് റിഡീം ചെയ്യേണ്ടതെന്നതിനുള്ള നിർദ്ദേശങ്ങൾ",
    pleaseFillInThisField: "ദയവായി ഈ ഫീൽഡ് പൂരിപ്പിക്കുക!",
    toDeleteThisTier: " ഈ ശ്രേണി ഇല്ലാതാക്കാൻ",
    deleteTier: "ടയർ ഇല്ലാതാക്കുക",
    viewMembers: "അംഗങ്ങളെ കാണുക",
    membersEnrolled: "അംഗങ്ങൾ ചേർന്നു",
    instruction: "നിർദ്ദേശം",
    membersIn: "അംഗങ്ങൾ",
    availableTiersInLoyalty: "ലോയൽറ്റി പ്രോഗ്രാമിൽ ലഭ്യമായ ടയർ (കൾ)",
    tiers: "നിരകൾ",
    totalTier: "മൊത്തം ടയർ",
    availableLoyaltyProgramme: "ലഭ്യമായ ലോയൽറ്റി പ്രോഗ്രാം",
    totalLoyalties: "മൊത്തം ലോയൽറ്റികൾ",
    memberDetails: "അംഗങ്ങളുടെ വിശദാംശങ്ങൾ",
    nameOfCustomer: "ഉപഭോക്താവിന്റെ പേര്",
    address: "വിലാസം",
    allEnrolledMembers: "എല്ലാ എൻറോൾ ചെയ്ത അംഗങ്ങളും",
    thisIsToWishYouHappyBirthday:
      "ഇത് നിങ്ങൾക്ക് ജന്മദിനാശംസകളും സമൃദ്ധമായ ജീവിതവും ആശംസിക്കുന്നു. ലോയ്‌സ്റ്ററിന് നിങ്ങൾ നൽകിയ എല്ലാത്തിനും നന്ദി. സന്തോഷകരമായ ആഘോഷങ്ങൾ!",
    inputAnOfferPlease: "ദയവായി ഒരു ഓഫർ നൽകുക",
    pleaseSelectTheInsertPoint:
      "സന്ദേശത്തിലെ ഇൻസെർട്ട് പോയിന്റ് തിരഞ്ഞെടുത്ത് വീണ്ടും ക്ലിക്കുചെയ്യുക",
    birthdayOfferAndMessage: "ജന്മദിന ഓഫറും സന്ദേശവും",
    birthdayOffer: "ജന്മദിന ഓഫർ",
    birthdayMessage: "ജന്മദിന സന്ദേശം",
    noOfferFound: "ഓഫർ കണ്ടെത്തിയില്ല",
    settingABirthdayOffer:
      "ഒരു ജന്മദിന ഓഫർ ക്രമീകരിക്കുന്നത് ഉപഭോക്താക്കൾക്ക് അവരുടെ ജന്മദിനത്തിൽ SMS വഴി ഈ ഓഫർ സ്വീകരിക്കാൻ അനുവദിക്കുന്നു",
    createOffer: "ഓഫർ സൃഷ്ടിക്കുക",
    whatIsTheOffer: "എന്താണ് ഓഫർ?",
    editMessage: "സന്ദേശം എഡിറ്റ് ചെയ്യുക",
    insert: "തിരുകുക",
    theNameOfCustomerInserted: "ഉപഭോക്താവിന്റെ പേര് ഇവിടെ ചേർക്കും",
    theBirtdayOfferInserted: "ജന്മദിന ഓഫർ ഇവിടെ ചേർക്കും",
    youSuccessfullyAddedNewBranch:
      "നിങ്ങൾ വിജയകരമായി ഒരു പുതിയ ശാഖ ചേർത്തിരിക്കുന്നു!",
    addNewBranch: "പുതിയ ബ്രാഞ്ച് ചേർക്കുക",
    addBranch: "ബ്രാഞ്ച് ചേർക്കുക",
    additionalBranchWillIncur: "അധിക ബ്രാഞ്ച് സംഭവിക്കും",
    perBranch: "ഓരോ ശാഖയിലും",
    ecommerceBranchCosts: "ഇ -കൊമേഴ്സ് ബ്രാഞ്ച് ചെലവ്",
    pleaseEnterBranchName: "ശാഖയുടെ പേര് നൽകുക",
    pleaseEnterBranchAddress1: "ബ്രാഞ്ചിന്റെ അഡ്രസ് ലൈൻ 1 നൽകുക",
    enterBranchAddress1: "ബ്രാഞ്ചിന്റെ അഡ്രസ് ലൈൻ 1 നൽകുക",
    enterBranchAddress2: "ബ്രാഞ്ചിന്റെ അഡ്രസ് ലൈൻ 1 നൽകുക",
    pleaseEnterBranchAddress2: "ബ്രാഞ്ചിന്റെ അഡ്രസ് ലൈൻ 2 നൽകുക",
    enterBranchName: "ശാഖയുടെ പേര് നൽകുക",
    successfullyAddedStaff:
      "നിങ്ങൾ ഒരു പുതിയ സ്റ്റാഫിനെ വിജയകരമായി ചേർത്തിരിക്കുന്നു!",
    addNewStaff: "പുതിയ ജീവനക്കാരെ ചേർക്കുക",
    addStaff: "ജീവനക്കാരെ ചേർക്കുക",
    additionalStaffWillIncur: "അധിക സ്റ്റാഫ് വരും",
    perStaff: "ഓരോ ജീവനക്കാരനും.",
    pleaseEnterStaffEmail: "ജീവനക്കാരുടെ ഇമെയിൽ നൽകുക",
    pleaseEnterStaffUsername: "ജീവനക്കാരുടെ ഉപയോക്തൃനാമം നൽകുക",
    pleaseEnterStaffPassword: "ജീവനക്കാരുടെ പാസ്‌വേഡ് ദയവായി നൽകുക",
    pleaseSelectStaffRole: "ജീവനക്കാരുടെ റോൾ തിരഞ്ഞെടുക്കുക",
    selectStaffRole: "ജീവനക്കാരുടെ റോൾ തിരഞ്ഞെടുക്കുക",
    enterStaffEmail: "ജീവനക്കാരുടെ ഇമെയിൽ നൽകുക",
    enterStaffUsername: "ജീവനക്കാരുടെ ഉപയോക്തൃനാമം നൽകുക",
    enterStaffPassword: "ജീവനക്കാരുടെ പാസ്‌വേഡ് നൽകുക",
    spacesNotAllowedInUsername: "ഉപയോക്തൃനാമത്തിൽ സ്പെയ്സുകൾ അനുവദനീയമല്ല",
    admin: "അഡ്മിൻ",
    pleaseSelectBusinessToAttachStaff:
      "ജീവനക്കാരെ അറ്റാച്ചുചെയ്യാൻ ദയവായി ബിസിനസ്സ് തിരഞ്ഞെടുക്കുക",
    searchForBranchToAttachStaff:
      "ജീവനക്കാരെ അറ്റാച്ചുചെയ്യാൻ ബ്രാഞ്ചിനായി തിരയുക",
    username: "ഉപയോക്തൃനാമം",
    role: "പങ്ക്",
    areYouSureToDeleteThis: "നിങ്ങൾ ഇത് ഇല്ലാതാക്കുമെന്ന് ഉറപ്പാണോ?",
    branches: "ശാഖകൾ",
    upgradeYourPlan: "നിങ്ങളുടെ പ്ലാൻ നവീകരിക്കുക.",
    add: "ചേർക്കുക",
    addNew: "പുതിയത് ചേർക്കുക",
    customerWithEmailAlreadyExists:
      "ഇമെയിൽ/ഫോൺ നമ്പർ ഉള്ള ഉപഭോക്താവ് ഇതിനകം നിലവിലുണ്ട്!",
    successfullyAddedNewCustomer:
      "നിങ്ങൾ ഒരു പുതിയ ഉപഭോക്താവിനെ വിജയകരമായി ചേർത്തിരിക്കുന്നു!",
    addCustomer: "ഉപഭോക്താവിനെ ചേർക്കുക",
    pleaseEnterCustomerFirstName: "ഉപഭോക്താവിന്റെ ആദ്യ പേര് നൽകുക",
    pleaseEnterCustomerLastName: "ഉപഭോക്താവിന്റെ അവസാന നാമം നൽകുക",
    pleaseEnterCustomerPhoneNumber: "ഉപഭോക്താവിന്റെ ഫോൺ നമ്പർ നൽകുക",
    pleaseEnterCustomerEmail: "ഉപഭോക്താവിന്റെ ഇമെയിൽ നൽകുക",
    pleaseEnterCustomerAddressLine: "ഉപഭോക്താവിന്റെ വിലാസ രേഖ നൽകുക",
    pleaseEnterCustomerOtherAddress: "ഉപഭോക്താവിന്റെ മറ്റൊരു വിലാസം നൽകുക",
    pleaseSelectCustomerGender: "ഉപഭോക്താവിന്റെ ലിംഗഭേദം തിരഞ്ഞെടുക്കുക",
    gender: "ലിംഗഭേദം",
    male: "ആൺ",
    female: "സ്ത്രീ",
    bank: "ബാങ്ക്",
    selectBank: "ബാങ്ക് തിരഞ്ഞെടുക്കുക",
    stateOrRegionOrProvince: "സംസ്ഥാനം/പ്രദേശം/പ്രവിശ്യ",
    customerNotes: "ഉപഭോക്തൃ കുറിപ്പുകൾ",
    sendSms: "എസ്എംഎസ് അയയ്ക്കുക",
    editCustomer: "ഉപഭോക്താവിനെ എഡിറ്റുചെയ്യുക",
    redeemReward: "റിവാർഡ് റിഡീം ചെയ്യുക",
    issueLoyaltyCard: "ലോയൽറ്റി കാർഡ് നൽകുക",
    deleteCustomer: "ഉപഭോക്താവിനെ ഇല്ലാതാക്കുക",
    youveSuccessfullyAssignedLoyaltyMembership:
      "നിങ്ങൾ വിജയകരമായി ഒരു ലോയൽറ്റി അംഗത്വ ഐഡി നൽകി",
    noMembershipIdAvailable:
      "അംഗത്വ ഐഡികൾ ലഭ്യമല്ല. ദയവായി ഞങ്ങളെ hello@loystar.co- ൽ ബന്ധപ്പെടുക",
    sendEmail: "ഇമെയിൽ അയയ്ക്കുക",
    membershipPoints: "അംഗത്വ പോയിന്റുകൾ",
    customerDetails: "ഉപഭോക്തൃ വിശദാംശങ്ങൾ",
    close: "അടയ്ക്കുക",
    loyaltyBalance: "ലോയൽറ്റി ബാലൻസ്",
    pointsBalance: "പോയിന്റ് ബാലൻസ്",
    starBalance: "സ്റ്റാർ ബാലൻസ്",
    requiredPoints: "ആവശ്യമായ പോയിന്റുകൾ",
    requiredStars: "ആവശ്യമായ നക്ഷത്രങ്ങൾ",
    reddemCode: "കോഡ് റിഡീം ചെയ്യുക",
    toDeleteThisCustomer: "ഈ ഉപഭോക്താവിനെ ഇല്ലാതാക്കാൻ",
    customerHasBeenDeletedSuccessfully: "ഉപഭോക്താവിനെ വിജയകരമായി ഇല്ലാതാക്കി!",
    customerWithPhoneAlreadyExists:
      "ഫോൺ നമ്പറുള്ള ഉപഭോക്താവ് ഇതിനകം നിലവിലുണ്ട്",
    customerWasSuccessfullyEdited: "ഉപഭോക്താവിനെ വിജയകരമായി എഡിറ്റ് ചെയ്തു",
    anErrorOccured: "ഒരു പിശക് സംഭവിച്ചു",
    phoneNumberIncludeCountry: "ഫോൺ നമ്പർ (രാജ്യ കോഡ് ഉൾപ്പെടുത്തുക)",
    yourFileQueuedForUpload:
      "നിങ്ങളുടെ ഫയൽ അപ്‌ലോഡ് ചെയ്യുന്നതിനായി ക്യൂ ചെയ്തിരിക്കുന്നു. കുറച്ച് നിമിഷങ്ങൾക്ക് ശേഷം ദയവായി പേജ് പുതുക്കുക.",
    thereWasAnErrorPerformingOperation:
      "പ്രവർത്തനം നടത്തുന്നതിൽ ഒരു പിശക് ഉണ്ടായിരുന്നു!",
    pleaseSelectFile: "ദയവായി ഒരു ഫയൽ തിരഞ്ഞെടുക്കുക!",
    oopsOnlyCsvFilesAllowed:
      "ശ്ശോ! CSV ഫയലുകൾ മാത്രമേ അനുവദിക്കൂ. ദയവായി .csv ഫയൽ അപ്‌ലോഡ് ചെയ്യുക.",
    fileShouldBeSmallerThan5Mb:
      "ഫയൽ 5MB- നേക്കാൾ ചെറുതായിരിക്കണം. നിങ്ങൾക്ക് ഒരു വലിയ ഫയൽ ഉണ്ടെങ്കിൽ, support@loystar.co ഇമെയിൽ ചെയ്യുക. നന്ദി",
    customerFirstNameIsRequired: "ഉപഭോക്താവിന്റെ ആദ്യനാമം വരിയിൽ ആവശ്യമാണ്",
    customerPhoneNumberIsRequired: "വരിയിൽ ഉപഭോക്തൃ ഫോൺ നമ്പർ ആവശ്യമാണ്",
    importCustomers: "ഉപഭോക്താക്കളെ ഇറക്കുമതി ചെയ്യുക",
    upload: "അപ്‌ലോഡ് ചെയ്യുക",
    clickIconToDownloadCsv:
      "CSV ഫയൽ ടെംപ്ലേറ്റ് ഡൗൺലോഡ് ചെയ്യാൻ ഈ ഐക്കണിൽ ക്ലിക്ക് ചെയ്യുക.",
    getGoogleSheetVersion: "Google ഷീറ്റ് പതിപ്പ് നേടുക",
    clickOrDragFileToUpload:
      "അപ്‌ലോഡ് ചെയ്യുന്നതിന് ഈ മേഖലയിലേക്ക് ഫയൽ ക്ലിക്കുചെയ്യുക അല്ലെങ്കിൽ വലിച്ചിടുക",
    missingOrInvalidColumnHeader:
      "കോളം ഹെഡർ കാണുന്നില്ല അല്ലെങ്കിൽ അസാധുവാണ്. ദയവായി ടെംപ്ലേറ്റ് ഫോർമാറ്റ് പിന്തുടരുക. നന്ദി.",
    youHaveImported: "നിങ്ങൾ ഇറക്കുമതി ചെയ്തു",
    youSuccessfullyRedeemedReward:
      "നിങ്ങൾ നിങ്ങളുടെ റിവാർഡ് വിജയകരമായി വീണ്ടെടുത്തു!",
    sixDigitCode: "ആറ് അക്ക കോഡ്",
    pleaseEnterCustomerRewardCode: "ഉപഭോക്താവിന്റെ റിവാർഡ് കോഡ് നൽകുക",
    enterRewardCode: "റിവാർഡ് കോഡ് നൽകുക. (റിവാർഡ് കോഡ് കേസ് സെൻസിറ്റീവ് ആണ്)",
    selectLoyaltyProgram: "ലോയൽറ്റി പ്രോഗ്രാം തിരഞ്ഞെടുക്കുക",
    stamps: "സ്റ്റാമ്പുകൾ",
    smsUnitsLow: "SMS യൂണിറ്റുകൾ കുറവാണ്",
    pleaseTopUpSmsUnits: "ദയവായി എസ്എംഎസ് യൂണിറ്റുകൾ ടോപ്പ് അപ്പ് ചെയ്യുക",
    smsSuccessfullySentForDelivery: "ഡെലിവറിക്ക് എസ്എംഎസ് വിജയകരമായി അയച്ചു!",
    sendSmsTo: "ലേക്ക് എസ്എംഎസ് അയയ്ക്കുക",
    allCustomers: "എല്ലാ ഉപഭോക്താക്കളും",
    unitsAvailable: "യൂണിറ്റുകൾ ലഭ്യമാണ്",
    pleaseTypeInTheMessage: "ദയവായി സന്ദേശം ടൈപ്പ് ചെയ്യുക",
    message: "സന്ദേശം",
    charactersRemaining: "ശേഷിക്കുന്ന പ്രതീകങ്ങൾ",
    avoidUseOfSpecialCharacters:
      "എസ്എംഎസ് യൂണിറ്റുകൾ സംരക്ഷിക്കാൻ പ്രത്യേക പ്രതീകങ്ങളും ഇമോജികളും ഉപയോഗിക്കുന്നത് ഒഴിവാക്കുക.",
    note: "കുറിപ്പ്",
    errorFetchingCustomersMultilevelDetail:
      "ഉപഭോക്താക്കളുടെ മൾട്ടി ലെവൽ വിശദാംശങ്ങൾ ലഭ്യമാക്കുന്നതിൽ പിശക്",
    search: "തിരയുക",
    reset: "റീസെറ്റ് ചെയ്യുക",
    importCustomer: "ഉപഭോക്താവിനെ ഇറക്കുമതി ചെയ്യുക",
    addNewCustomer: "പുതിയ ഉപഭോക്താവിനെ ചേർക്കുക",
    sendSmsBroadcast: "SMS ബ്രോഡ്കാസ്റ്റ് അയയ്ക്കുക",
    totalCustomers: "മൊത്തം ഉപഭോക്താക്കൾ",
    disbursementDetails: "വിതരണ വിശദാംശങ്ങൾ",
    paidBy: "പണമടച്ചത്",
    disbursed: "വിതരണം ചെയ്തു",
    bankAccountName: "ബാങ്ക് അക്കൗണ്ട് പേര്",
    bankAccountNumber: "ബാങ്ക് അക്കൗണ്ട് നമ്പർ",
    transferInitiated: "കൈമാറ്റം ആരംഭിച്ചു",
    transferCompleted: "കൈമാറ്റം പൂർത്തിയായി",
    pleaseEnterAValid: "ദയവായി ഒരു സാധുത നൽകുക",
    begin: "ആരംഭിക്കുന്നു",
    end: "അവസാനിക്കുന്നു",
    date: "തീയതി",
    paymentDate: "പേയ്മെന്റ് തീയതി",
    selectDisbursementDuration: "വിതരണ കാലയളവ് തിരഞ്ഞെടുക്കുക",
    totalSettled: "ആകെ സെറ്റിൽ ചെയ്തു",
    totalUnsettled: "ആകെ അസ്വസ്ഥമായത്",
    toDeleteThisSale: "ഈ വിൽപ്പന ഇല്ലാതാക്കാൻ",
    draftSaleDeletedSuccessfully: "ഡ്രാഫ്റ്റ് സെയിൽ വിജയകരമായി ഇല്ലാതാക്കി!",
    deleteSale: "വിൽപ്പന ഇല്ലാതാക്കുക",
    pleaseTypeInYourTotal:
      "ഇല്ലാതാക്കൽ സ്ഥിരീകരിക്കുന്നതിന് നിങ്ങളുടെ മൊത്തം ടൈപ്പ് ചെയ്യുക",
    billDetails: "ബിൽ വിശദാംശങ്ങൾ",
    printBill: "പ്രിന്റ് ബിൽ",
    servedBy: "സേവിച്ചത്",
    total: "ആകെ",
    createdDate: "സൃഷ്ടിച്ച തീയതി",
    createdTime: "സൃഷ്ടിച്ച സമയം",
    status: "പദവി",
    loginSuccessful: "ലോഗിൻ വിജയകരമായി",
    pleaseWaitWhileWeConnectAccount:
      "ഞങ്ങൾ നിങ്ങളുടെ അക്കൗണ്ട് കണക്റ്റുചെയ്യുമ്പോൾ ദയവായി കാത്തിരിക്കുക",
    connectionFailedTryAgain:
      "കണക്ഷൻ പരാജയപ്പെട്ടു. ദയവായി വീണ്ടും ശ്രമിക്കുക.",
    connectionSuccessful: "കണക്ഷൻ വിജയകരമായി",
    viewDetails: "വിശദാംശങ്ങൾ കാണുക",
    enable: "പ്രവർത്തനക്ഷമമാക്കുക",
    free: "സൗ ജന്യം",
    cost: "ചെലവ്",
    visitWebsite: "വെബ്സൈറ്റ് സന്ദർശിക്കുക",
    pleaseUpgradeYourPlanToPro:
      "ഈ ആപ്പ് പ്രവർത്തനക്ഷമമാക്കുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ പ്രോ പ്ലസിലേക്ക് അപ്‌ഗ്രേഡ് ചെയ്യുക",
    connectYourBankAccount:
      "പേയ്‌മെന്റുകൾ സ്വീകരിക്കുന്നതിന് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് ബന്ധിപ്പിക്കുക.",
    disable: "അപ്രാപ്തമാക്കുക",
    enableApp: "ആപ്പ് പ്രവർത്തനക്ഷമമാക്കുക",
    addNewProductToInvoice: "ഇൻവോയ്സിൽ പുതിയ ഉൽപ്പന്നം ചേർക്കുക",
    toDeleteThisInvoice: "ഈ ഇൻവോയ്സ് ഇല്ലാതാക്കാൻ",
    invoiceDeletedSuccessfully: "ഇൻവോയ്സ് വിജയകരമായി ഇല്ലാതാക്കി!",
    deleteInvoice: "ഇൻവോയ്സ് ഇല്ലാതാക്കുക",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "നിങ്ങൾ വിജയകരമായി ഒരു ഇൻവോയ്സ് പേയ്മെന്റ് സന്ദേശം ചേർത്തിരിക്കുന്നു!",
    pleaseEnterPaymentInstructions: "പേയ്മെന്റ് നിർദ്ദേശങ്ങൾ നൽകുക",
    invoiceId: "ഇൻവോയ്സ് ഐഡി",
    paidDate: "പണമടച്ച തീയതി",
    reference: "റഫറൻസ്",
    productAdded: "ഉൽപ്പന്നം ചേർത്തു",
    productOutOfStock: "ഉൽപ്പന്നം സ്റ്റോക്കില്ല. ദയവായി വീണ്ടും സംഭരിക്കുക.",
    totalInvoices: "മൊത്തം ഇൻവോയ്സുകൾ",
    totalPaidInvoices: "മൊത്തം പണമടച്ചുള്ള ഇൻവോയ്സുകൾ",
    totalUnpaidInvoices: "മൊത്തം യു‌എൻ‌പെയ്ഡ് ഇൻവോയ്സുകൾ",
    loyaltyProgramDeleted: "ലോയൽറ്റി പ്രോഗ്രാം ഇല്ലാതാക്കി",
    thisLoyaltyProgramDeletedSuccessfully:
      "ഈ ലോയൽറ്റി പ്രോഗ്രാം വിജയകരമായി ഇല്ലാതാക്കി",
    thisLoyaltyProgramEditedSuccessfully:
      "ഈ ലോയൽറ്റി പ്രോഗ്രാം വിജയകരമായി എഡിറ്റുചെയ്തു",
    loyaltyProgramAddedSuccessfully: "ലോയൽറ്റി പ്രോഗ്രാം വിജയകരമായി ചേർത്തു",
    loyaltyProgramEdited: "ലോയൽറ്റി പ്രോഗ്രാം എഡിറ്റ് ചെയ്തു",
    loyaltyProgramAdded: "ലോയൽറ്റി പ്രോഗ്രാം ചേർത്തു",
    loyaltyDetails: "വിശ്വസ്തതയുടെ വിശദാംശങ്ങൾ",
    doYouWantToCloseDialog: "ഈ ഡയലോഗ് അടയ്‌ക്കണോ?",
    pleaseEnterLoyaltyName: "നിങ്ങളുടെ വിശ്വസ്തതയുടെ പേര് ദയവായി നൽകുക",
    programType: "പ്രോഗ്രാം തരം",
    pleaseSelectProgramType: "ദയവായി ഒരു പ്രോഗ്രാം തരം തിരഞ്ഞെടുക്കുക",
    simplePoints: "ലളിതമായ പോയിന്റുകൾ",
    stampsProgram: "സ്റ്റാമ്പ് പ്രോഗ്രാം",
    threshold: "ഉമ്മരപ്പടി",
    pleaseEnterLoyaltyThreshold: "ലോയൽറ്റി ത്രെഷോൾഡ് നൽകുക",
    reward: "പ്രതിഫലം",
    pleaseEnterLoyaltyReward: "വിശ്വസ്തതയുടെ പ്രതിഫലം ദയവായി നൽകുക",
    totalUserPoints: "മൊത്തം ഉപയോക്തൃ പോയിന്റുകൾ",
    totalUserStamps: "മൊത്തം ഉപയോക്തൃ സ്റ്റാമ്പുകൾ",
    spendingTarget: "ചെലവഴിക്കുന്ന ലക്ഷ്യം",
    spendingTargetHint1:
      "പ്രതിഫലം നേടാൻ ഒരു ഉപഭോക്താവ് എത്രമാത്രം ചെലവഴിക്കണമെന്നതാണ് ചെലവഴിക്കൽ ലക്ഷ്യം. 1 കറൻസി മൂല്യം = 1 പോയിന്റ്.",
    spendingTargetHint2:
      "പ്രതിഫലം നേടാൻ ഒരു ഉപഭോക്താവ് എത്ര സ്റ്റാമ്പുകൾ ശേഖരിക്കണം എന്നതാണ് സ്റ്റാമ്പ് ലക്ഷ്യം. ഉദാ. 5",
    addNewLoyaltyProgram: "പുതിയ ലോയൽറ്റി പ്രോഗ്രാം ചേർക്കുക",
    addLoyaltyProgram: "ലോയൽറ്റി പ്രോഗ്രാം ചേർക്കുക",
    loyaltyProgramType: "ലോയൽറ്റി പ്രോഗ്രാം തരം",
    pleaseSelectLoyaltyProgramType: "ലോയൽറ്റി പ്രോഗ്രാം തരം തിരഞ്ഞെടുക്കുക",
    nameOfProgram: "പ്രോഗ്രാമിന്റെ പേര്",
    pleaseEnterProgramName: "ദയവായി പ്രോഗ്രാമിന്റെ പേര് നൽകുക",
    whatIsTheReward: "പ്രതിഫലം എന്താണ്?",
    egNextPurchaseIsFree: "ഉദാ. അടുത്ത വാങ്ങൽ സൗജന്യമാണ്",
    customerName: "ഉപഭോക്താവിന്റെ പേര്",
    guestCustomer: "അതിഥി ഉപഭോക്താവ്",
    orderConfirmedSuccessfully: "ഓർഡർ വിജയകരമായി സ്ഥിരീകരിച്ചു",
    orderCancelledSuccessfully: "ഓർഡർ റദ്ദാക്കി",
    confirmOrder: "ഓർഡർ സ്ഥിരീകരിക്കുക",
    cancelOrder: "ഓർഡർ റദ്ദാക്കാൻ",
    allOrders: "എല്ലാ ഉത്തരവുകളും",
    totalOrders: "മൊത്തം ഓർഡറുകൾ",
    doYouWantToAcceptOrder: "ഈ ഓർഡർ സ്വീകരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    doYouWantToCancelOrder: "ഈ ഓർഡർ റദ്ദാക്കണോ?",
    orderDetails: "ഓർഡർ വിശദാംശങ്ങൾ",
    orderCreatedAt: "എന്നതിൽ ഓർഡർ സൃഷ്ടിച്ചു",
    supplier: "വിതരണക്കാരൻ",
    productName: "ഉത്പന്നത്തിന്റെ പേര്",
    quantity: "അളവ്",
    unitPrice: "യൂണിറ്റ് വില",
    receivedBy: "സ്വീകരിച്ചത്",
    reportFrom: "നിന്ന് റിപ്പോർട്ട്",
    totalSupplies: "മൊത്തം സപ്ലൈസ്",
    allRightsReserved: "എല്ലാ അവകാശങ്ങളും നിക്ഷിപ്തം",
    toDeleteThisTransaction: "ഈ ഇടപാട് ഇല്ലാതാക്കാൻ",
    transactionDeletedSuccessfully:
      "ഇടപാട് വിജയകരമായി ഇല്ലാതാക്കി. സ്റ്റോക്ക് സാധനങ്ങൾക്ക് തിരികെ നൽകി.",
    deleteTransaction: "ഇടപാട് ഇല്ലാതാക്കുക",
    transactionDetails: "ഇടപാട് വിശദാംശങ്ങൾ",
    printReceipt: "പ്രിന്റ് രസീത്",
    channel: "ചാനൽ",
    discount: "കിഴിവ്",
    profit: "ലാഭം",
    discountedSales: "ഡിസ്കൗണ്ട് വിൽപ്പന",
    errorFetchingRecord: "റെക്കോർഡ് ലഭ്യമാക്കുന്നതിൽ പിശക്",
    exportTransactions: "കയറ്റുമതി ഇടപാടുകൾ",
    errorFetchingSalesRecord:
      "കയറ്റുമതിക്കായി വിൽപ്പന റെക്കോർഡ് ലഭ്യമാക്കുന്നതിൽ പിശക്.",
    totalSellingPrice: "മൊത്തം വിൽപ്പന വില",
    totalCostPrice: "മൊത്തം ചെലവ് വില",
    appliedDiscount: "ബാധകമായ കിഴിവ്",
    noOfItems: "ഇനങ്ങളുടെ എണ്ണം",
    sales: "വിൽപ്പന",
    export: "കയറ്റുമതി ചെയ്യുക",
    totalProfit: "മൊത്തം ലാഭം",
    totalBalanceInPeriod: "കാലയളവിലെ മൊത്തം ബാലൻസ്",
    allTimeSales: "എല്ലാ സമയ വിൽപ്പനയും",
    records: "രേഖകള്",
    todaysSales: "ഇന്നത്തെ വിൽപ്പന",
    transaction: "ഇടപാട്",
    youHaveExceededTotalNumberOfProducts:
      "നിങ്ങളുടെ പ്ലാനിൽ അനുവദിച്ചിട്ടുള്ള മൊത്തം ഉൽപ്പന്നങ്ങളുടെ എണ്ണം നിങ്ങൾ കവിഞ്ഞു. ഉയർന്ന പരിധി ആസ്വദിക്കാൻ നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡുചെയ്യുക.",
    youNeedToHaveLoyaltyProgram:
      "ഈ സവിശേഷത ഉപയോഗിക്കുന്നതിന് നിങ്ങൾക്ക് ഒരു ലോയൽറ്റി പ്രോഗ്രാം ആവശ്യമാണ്!",
    price: "വില",
    category: "വിഭാഗം",
    stockTracking: "സ്റ്റോക്ക് ട്രാക്കിംഗ്",
    stockCount: "സ്റ്റോക്ക് കൗണ്ട്",
    taxed: "നികുതി ചുമത്തി",
    originalPrice: "യഥാർത്ഥ വില",
    costPrice: "ചെലവ് വില",
    unit: "യൂണിറ്റ്",
    productImage: "ഉൽപ്പന്ന ചിത്രം",
    taxRate: "നികുതി നിരക്ക്",
    taxType: "നികുതി തരം",
    trackInventory: "ട്രാക്ക് ഇൻവെന്ററി",
    variants: "വേരിയന്റുകൾ",
    hasVariants: "വേരിയന്റുകൾ ഉണ്ട്",
    importProduct: "ഇറക്കുമതി ഉൽപ്പന്നം",
    exportProducts: "കയറ്റുമതി ഉൽപ്പന്നങ്ങൾ",
    addNewProduct: "പുതിയ ഉൽപ്പന്നം ചേർക്കുക",
    viewCategory: "വിഭാഗം കാണുക",
    viewSuppliers: "വിതരണക്കാരെ കാണുക",
    receiveInventory: "ഇൻവെന്ററി സ്വീകരിക്കുക",
    printAllProductsTag: "എല്ലാ ഉൽപ്പന്ന ടാഗും അച്ചടിക്കുക",
    deleteAll: "എല്ലാം നീക്കം ചെയ്യുക",
    totalProducts: "മൊത്തം ഉൽപ്പന്നങ്ങൾ",
    youveSuccessfullyAddedANewCategory:
      "നിങ്ങൾ വിജയകരമായി ഒരു പുതിയ വിഭാഗം ചേർത്തിരിക്കുന്നു",
    addNewCategory: "പുതിയ വിഭാഗം ചേർക്കുക",
    addCategory: "വിഭാഗം ചേർക്കുക",
    categoryName: "വിഭാഗത്തിന്റെ പേര്",
    pleaseEnterCategoryName: "വിഭാഗത്തിന്റെ പേര് ദയവായി നൽകുക",
    stampsTarget: "സ്റ്റാമ്പ് ലക്ഷ്യം",
    sendInventory: "ഇൻവെന്ററി അയയ്ക്കുക",
    productDetails: "ഉൽപ്പന്നത്തിന്റെ വിവരം",
    youveSuccessfullyEditedThisCategory:
      "നിങ്ങൾ ഈ വിഭാഗം വിജയകരമായി എഡിറ്റുചെയ്‌തു",
    update: "അപ്ഡേറ്റ് ചെയ്യുക",
    categoryList: "വിഭാഗ പട്ടിക",
    categories: "വിഭാഗങ്ങൾ",
    enterQuantityToUpdate: "അപ്ഡേറ്റ് ചെയ്യാൻ ഒരു അളവ് നൽകുക",
    inventorySentSuccessfully: "സാധനങ്ങൾ വിജയകരമായി അയച്ചു!",
    updateInventory: "ഇൻവെന്ററി അപ്ഡേറ്റ് ചെയ്യുക",
    currentQuantity: "നിലവിലെ അളവ്",
    pleaseEnterQuantityToAdd: "നിങ്ങൾ ചേർക്കാൻ ആഗ്രഹിക്കുന്ന അളവ് ദയവായി നൽകുക",
    pleaseSelectABranch: "ദയവായി ഒരു ശാഖ തിരഞ്ഞെടുക്കുക",
    searchForBranch: "ശാഖയ്ക്കായി തിരയുക",
    youCantSendMoreThanStock:
      "സ്റ്റോക്കിലുള്ളതിനേക്കാൾ കൂടുതൽ നിങ്ങൾക്ക് അയയ്ക്കാൻ കഴിയില്ല",
    send: "അയയ്ക്കുക",
    pleaseEnterQuantityToSend: "നിങ്ങൾ അയയ്‌ക്കേണ്ട അളവ് നൽകുക",
    productNameIsRequiredOnRow: "ഉൽപ്പന്നത്തിന്റെ പേര് വരിയിൽ ആവശ്യമാണ്",
    productCategoryIsRequiredOnRow: "വരിയിൽ ഉൽപ്പന്ന വിഭാഗം ആവശ്യമാണ്",
    productPriceIsRequiredOnRow: "ഉൽപ്പന്ന വില നിരയിൽ ആവശ്യമാണ്",
    productUnitIsRequiredOnRow: "വരിയിൽ ഉൽപ്പന്ന യൂണിറ്റ് ആവശ്യമാണ്",
    productQuantityIsRequiredOnRow: "വരിയിൽ ഉൽപ്പന്നത്തിന്റെ അളവ് ആവശ്യമാണ്",
    productVariantsRequireTracking:
      "ഉൽപ്പന്ന വേരിയന്റുകൾക്ക് ട്രാക്കിംഗ് ആവശ്യമാണ്!",
    pleaseAddVariantClickButton:
      "ചേർക്കുക ബട്ടൺ ക്ലിക്കുചെയ്ത് ഒരു വകഭേദം ചേർക്കുക!",
    totalVariantsMoreThanSelectedQuantity:
      "മൊത്തം വേരിയന്റുകൾ തിരഞ്ഞെടുത്ത ഉൽപ്പന്ന അളവിനേക്കാൾ കൂടുതലാണ്, Pls വേരിയന്റ് അളവ് കുറയ്ക്കുന്നു",
    productEditedSuccessfully: "ഉൽപ്പന്നം വിജയകരമായി എഡിറ്റുചെയ്‌തു!",
    fileTooLargeLessThan4Mb:
      "ഫയൽ വലുപ്പം വളരെ വലുതാണ്! ഫയൽ വലുപ്പം 4MB- യിൽ കുറവായിരിക്കണം.",
    suchVariantAlreadyExist: "അത്തരം വകഭേദം ഇതിനകം നിലവിലുണ്ട്",
    addVariants: "വേരിയന്റുകൾ ചേർക്കുക",
    editProduct: "ഉൽപ്പന്നം എഡിറ്റ് ചെയ്യുക",
    pleaseEnterProductName: "ദയവായി ഉൽപ്പന്നത്തിന്റെ പേര് നൽകുക",
    pleaseEnterProductPrice: "ഉൽപ്പന്ന വില നൽകുക",
    pleaseEnterProductOriginalPrice: "ഉൽപ്പന്നത്തിന്റെ യഥാർത്ഥ വില നൽകുക",
    productDescription: "ഉൽപ്പന്ന വിവരണം",
    selectProductCategory: "ഉൽപ്പന്ന വിഭാഗം തിരഞ്ഞെടുക്കുക",
    pleaseSelectProductCategory: "ഉൽപ്പന്ന വിഭാഗം തിരഞ്ഞെടുക്കുക",
    productCostPrice: "ഉൽപ്പന്ന ചെലവ് വില",
    productSellingPrice: "ഉൽപ്പന്ന വിൽപ്പന വില",
    productOriginalPrice: "ഉൽപ്പന്നത്തിന്റെ യഥാർത്ഥ വില",
    maxFileSizeAllowedIs4Mb: "പരമാവധി അനുവദനീയമായ ഫയൽ വലുപ്പം 4 എംബി ആണ്",
    productsWithPicturesArePublished:
      "ഓർഡറുകൾ ലഭിക്കുന്നതിന് ചിത്രങ്ങളുള്ള ഉൽപ്പന്നങ്ങൾ ഡിസ്കവറിൽ പ്രസിദ്ധീകരിക്കുന്നു",
    shouldThisProductBeTracked: "ഈ ഉൽപ്പന്നം ട്രാക്ക് ചെയ്യേണ്ടതുണ്ടോ?",
    pleaseSelectStockUnit: "സ്റ്റോക്ക് യൂണിറ്റ് തിരഞ്ഞെടുക്കുക",
    stockUnit: "സ്റ്റോക്ക് യൂണിറ്റ്",
    bag: "BAG",
    bottle: "കുപ്പി",
    bunch: "ബഞ്ച്",
    can: "കഴിയും",
    carton: "കാർട്ടൺ",
    crate: "ക്രേറ്റ്",
    cup: "CUP",
    dozen: "ഡസൻ",
    gigabytes: "ഗിഗാബൈറ്റുകൾ",
    gram: "ഗ്രാം",
    kilogram: "കിലോഗ്രാം",
    litre: "ലിറ്റർ",
    pack: "PACK",
    pair: "PAIR",
    pieces: "പീസുകൾ",
    plate: "പാത്രം",
    tonne: "ടോൺ (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "ഉൽപ്പന്ന അളവ് നൽകുക",
    productQuantity: "ഉൽപ്പന്നത്തിന്റെ അളവ്",
    isThisProductTaxed: "ഈ ഉൽപ്പന്നത്തിന് നികുതി ഏർപ്പെടുത്തിയിട്ടുണ്ടോ?",
    selectTaxType: "നികുതി തരം തിരഞ്ഞെടുക്കുക",
    pleaseSelectTaxType: "നികുതി തരം തിരഞ്ഞെടുക്കുക",
    progressive: "പുരോഗമനപരമായ",
    proportional: "ആനുപാതികമായി",
    pleaseEnterProductTaxRate: "ഉൽപ്പന്ന നികുതി നിരക്ക് നൽകുക",
    doesProductHaveVariants: "ഈ ഉൽപ്പന്നത്തിന് വേരിയന്റുകൾ ഉണ്ടോ?",
    type: "ടൈപ്പ് ചെയ്യുക",
    value: "മൂല്യം",
    pleaseEnterVariantType: "വേരിയന്റിന്റെ തരം നൽകുക",
    pleaseEnterVariantValue: "വേരിയന്റിന്റെ മൂല്യം നൽകുക",
    pleaseEnterVariantPrice: "വേരിയന്റിന്റെ വില നൽകുക",
    pleaseEnterVariantQuantity: "വകഭേദത്തിന്റെ അളവ് നൽകുക",
    productDeletedSuccessfully: "ഉൽപ്പന്നം വിജയകരമായി ഇല്ലാതാക്കി!",
    categoryDeletedSuccessfully: "വിഭാഗം വിജയകരമായി ഇല്ലാതാക്കി!",
    toDeleteThisProduct: "ഈ ഉൽപ്പന്നം ഇല്ലാതാക്കാൻ",
    invalidProductQuantity: "സാധനങ്ങളുടെ അളവ് അസാധുവാണ്",
    quantityAddedIsOverStock:
      "നിങ്ങൾ ചേർക്കുന്ന അളവ് സ്റ്റോക്കിലുള്ളതിനേക്കാൾ കൂടുതലാണ്.",
    itemInventoryNotTracked: "ഇനം ഇൻവെന്ററി ട്രാക്ക് ചെയ്തിട്ടില്ല",
    addBulkQuantity: "ബൾക്ക് ക്വാണ്ടിറ്റി ചേർക്കുക",
    enterBulkQuantity: "ബൾക്ക് ക്വാണ്ടിറ്റി നൽകുക",
    pleaseEnterBulkQuantity: "ദയവായി ബൾക്ക് ക്വാണ്ടിറ്റി നൽകുക",
    youveSuccessfullyAddedANewProduct:
      "നിങ്ങൾ ഒരു പുതിയ ഉൽപ്പന്നം വിജയകരമായി ചേർത്തു!",
    pleaseEnterProductSellingPrice: "ഉൽപ്പന്ന വിൽപ്പന വില നൽകുക",
    doYouWantToTrackProductStock:
      "നിങ്ങൾക്ക് ഉൽപ്പന്ന സ്റ്റോക്ക് ട്രാക്ക് ചെയ്യണോ?",
    sellingPrice: "വിൽക്കുന്ന വില",
    setProductExpiryReminder:
      "ഉൽപ്പന്ന കാലഹരണപ്പെടൽ ഓർമ്മപ്പെടുത്തൽ സജ്ജമാക്കുക",
    productExpiryDate: "ഉൽപ്പന്ന കാലഹരണ തീയതി",
    startRemindingFrom: "നിന്ന് ഓർമ്മപ്പെടുത്തൽ ആരംഭിക്കുക",
    productSuppliesAddedSuccessfully:
      "നിങ്ങൾ ഉൽപ്പന്ന വിതരണങ്ങൾ വിജയകരമായി ചേർത്തു.",
    addProductSupplies: "ഉൽപ്പന്ന വിതരണം ചേർക്കുക",
    pleaseSelectSupplier: "ദയവായി വിതരണക്കാരനെ തിരഞ്ഞെടുക്കുക",
    nameOfProduct: "ഉൽപ്പന്നത്തിന്റെ പേര്",
    pleaseSelectProduct: "ദയവായി ഉൽപ്പന്നം തിരഞ്ഞെടുക്കുക",
    productVariant: "ഉൽപ്പന്ന വേരിയന്റ്",
    pleaseSelectAVariant: "ദയവായി ഒരു വേരിയന്റ് തിരഞ്ഞെടുക്കുക",
    pleaseEnterUnitPrice: "യൂണിറ്റ് വില നൽകുക",
    businessBranch: "ബിസിനസ് ശാഖ",
    pleaseSelectBranch: "ശാഖ തിരഞ്ഞെടുക്കുക",
    youveSuccessfullyAddedANewSupplier:
      "നിങ്ങൾ ഒരു പുതിയ വിതരണക്കാരനെ വിജയകരമായി ചേർത്തിരിക്കുന്നു",
    addSupplier: "വിതരണക്കാരനെ ചേർക്കുക",
    pleaseEnterSupplierEmail: "ദയവായി വിതരണക്കാരന്റെ ഇമെയിൽ നൽകുക",
    pleaseAddADescription: "ദയവായി ഒരു വിവരണം ചേർക്കുക",
    anErrorOccuredProductsDeleted:
      "ഓപ്പറേഷൻ ചെയ്യുമ്പോൾ ഒരു പിശക് സംഭവിച്ചു. ദയവായി ശ്രദ്ധിക്കുക, ചില ഉൽപ്പന്നങ്ങൾ പ്രക്രിയയിൽ ഇല്ലാതാക്കിയിരിക്കാം",
    bulkDelete: "ബൾക്ക് ഇല്ലാതാക്കുക",
    youAreAboutToDelete: "നിങ്ങൾ ഇല്ലാതാക്കാൻ പോവുകയാണ്",
    product: "ഉൽപ്പന്നം",
    isDueToRedeem: "വീണ്ടെടുക്കൽ മൂലമാണ്",
    aReward: "ഒരു പ്രതിഫലം",
    pleaseSelectCustomerToReeemReward:
      "റിവാർഡ് റിഡീം ചെയ്യാൻ ഒരു ഉപഭോക്താവിനെ തിരഞ്ഞെടുക്കുക.",
    youHaveNoLoyaltyProgramRunning:
      "നിങ്ങൾക്ക് സജീവമായ ലോയൽറ്റി പ്രോഗ്രാം പ്രവർത്തിക്കുന്നില്ല",
    customerHhasNoPointsInLoyaltyProgram:
      "ഈ ലോയൽറ്റി പ്രോഗ്രാമിൽ ഉപഭോക്താവിന് പോയിന്റുകളൊന്നുമില്ല",
    proceedWithPayment: "പേയ്‌മെന്റുമായി മുന്നോട്ട് പോകണോ?",
    youAreAboutToPayForTransactionUsingPoints:
      "പോയിന്റുകൾ ഉപയോഗിച്ചുള്ള ഇടപാടിന് നിങ്ങൾ പണം നൽകുകയാണ്.",
    customerHas: "ഉപഭോക്താവ് ഉണ്ട്",
    worth: "മൂല്യം",
    andIsNotSufficientToPayForTransaction:
      "കൂടാതെ ഈ ഇടപാടിന് പണം നൽകാൻ പര്യാപ്തമല്ല. മറ്റൊരു പേയ്മെന്റ് രീതി ഉപയോഗിച്ച് ബാലൻസ് ചേർക്കാൻ അവർ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    addCustomerLoyalty: "ഉപഭോക്തൃ വിശ്വസ്തത ചേർക്കുക",
    pleaseAddCustomerFirst:
      "ആദ്യം ഒരു ഉപഭോക്താവിനെ ചേർക്കുകയോ തിരഞ്ഞെടുക്കുകയോ ചെയ്യുക.",
    pleaseWaitWhileWeGetReady: "കാത്തിരിക്കൂ, ഞങ്ങൾ തയ്യാറാകുമ്പോൾ",
    lowStock: "കുറഞ്ഞ സ്റ്റോക്ക്",
    pleaseEnterAmountTendered: "ടെൻഡർ ചെയ്ത തുക ദയവായി നൽകുക",
    areYouSureToBookSaleOffline:
      "നിങ്ങൾക്ക് ഈ വിൽപ്പന ഓഫ്‌ലൈനിൽ ബുക്ക് ചെയ്യണമെന്ന് തീർച്ചയാണോ?",
    saleWouldBeBookedAutomatically:
      "നിങ്ങൾ ഇന്റർനെറ്റ് ഓൺ ചെയ്യുമ്പോൾ വിൽപ്പന യാന്ത്രികമായി ബുക്ക് ചെയ്യപ്പെടും",
    offlineSalesBookingCancelled: "ഓഫ്‌ലൈൻ വിൽപ്പന ബുക്കിംഗ് റദ്ദാക്കി",
    covid19Message:
      "കോവിഡ് 19: വ്യാപനം തടയാൻ കൈകൾ സോപ്പുപയോഗിച്ച് കഴുകുക അല്ലെങ്കിൽ അണുവിമുക്തമാക്കുക. എപ്പോഴും സുരക്ഷിതരായിരിക്കുക",
    saleSuccessfullyRecorded: "വിൽപ്പന വിജയകരമായി രേഖപ്പെടുത്തി!",
    sendReceiptToEmail: "ഇമെയിലിലേക്ക് രസീത് അയയ്ക്കുക",
    sendThankYouSms: "നന്ദി എസ്എംഎസ് അയയ്ക്കുക",
    sendShippingDetails: "ഷിപ്പിംഗ് വിശദാംശങ്ങൾ അയയ്ക്കുക",
    addLoyalty: "വിശ്വസ്തത ചേർക്കുക",
    searchCustomerNameOrNumber: "ഉപഭോക്താവിന്റെ പേരോ നമ്പറോ തിരയുക",
    clickTheSearchCustomerBox:
      "സെർച്ച് കസ്റ്റമർ ബോക്സും സ്കാൻ കാർഡും ക്ലിക്ക് ചെയ്യുക",
    enterProductPrice: "ഉൽപ്പന്ന വില നൽകുക",
    enterPriceFor: "ഇതിനായുള്ള വില നൽകുക",
    searchForProduct: "ഉൽപ്പന്നത്തിനായി തിരയുക",
    all: "എല്ലാം",
    backToDashboard: "തിരികെ ഡാഷ്‌ബോർഡിലേക്ക്",
    viewDraftSales: "ഡ്രാഫ്റ്റ് സെയിൽസ് കാണുക",
    variantSelected: "വേരിയന്റ് തിരഞ്ഞെടുത്തു",
    variant: "വേരിയന്റ്",
    thePreviousVariantSelectionNotAvailable:
      "വിലയെ അടിസ്ഥാനമാക്കി തിരഞ്ഞെടുത്ത പുതിയ വേരിയന്റിന് മുമ്പത്തെ വേരിയന്റ് സെലക്ഷൻ ലഭ്യമല്ല, ദയവായി നിങ്ങളുടെ സെലക്ഷൻ മാറ്റുക.",
    pleaseSupplyPositiveQuantityNumber:
      "ദയവായി ഒരു പോസിറ്റീവ് ക്വാണ്ടിറ്റി നമ്പർ നൽകുക",
    lowStockFor: "കുറഞ്ഞ സ്റ്റോക്ക്",
    clearVariants: "വ്യക്തമായ വേരിയന്റുകൾ",
    pleaseEnterQuantity: "ദയവായി അളവ് നൽകുക",
    picture: "ചിത്രം",
    redemptionToken: "വീണ്ടെടുക്കൽ ടോക്കൺ",
    token: "ടോക്കൺ",
    totalSpent: "ആകെ ചിലവഴിച്ചത്",
    confirmPayment: "പേയ്മെന്റ് സ്ഥിരീകരിക്കുക",
    hasPaymentBeenMade: "പേയ്മെന്റ് വിജയകരമായി പ്രോസസ്സ് ചെയ്തിട്ടുണ്ടോ?",
    enterTransactionReference: "നിങ്ങൾ പണമടച്ച ഇടപാട് റഫറൻസ് നൽകുക",
    pleaseSelectACustomer: "ദയവായി ഒരു ഉപഭോക്താവിനെ തിരഞ്ഞെടുക്കുക!",
    areYouSureToApplyDiscount:
      "നിങ്ങൾക്ക് കിഴിവ് പ്രയോഗിക്കണമെന്ന് തീർച്ചയാണോ?",
    addYourBankAccountToEnableUssd:
      "UPay മുഖേന തൽക്ഷണ USSD കൈമാറ്റം പ്രാപ്തമാക്കുന്നതിന് നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് ചേർക്കുക",
    totalAmountToPay: "അടയ്ക്കാനുള്ള ആകെ തുക",
    doYouWantToCancelTransaction: "ഈ ഇടപാട് റദ്ദാക്കണോ?",
    savePrintBill: "ബിൽ സംരക്ഷിക്കുക/അച്ചടിക്കുക",
    enterAmountCollectedForCustomer: "ഉപഭോക്താവിനായി ശേഖരിച്ച തുക നൽകുക",
    recordSale: "റെക്കോർഡ് വിൽപ്പന",
    checkOutWith: "ഉപയോഗിച്ച് പരിശോധിക്കുക",
    instantTransfer: "തൽക്ഷണ കൈമാറ്റം",
    dialTheUSSDCode: "USSD കോഡ് ഡയൽ ചെയ്യുക",
    pleaseSelectABank: "ദയവായി ഒരു ബാങ്ക് തിരഞ്ഞെടുക്കുക",
    payWithUSSD: "USSD ഉപയോഗിച്ച് പണമടയ്ക്കുക",
    sendBillTo: " - ബിൽ അയയ്ക്കുക",
    waitingForUSSDTransfer: "USSD കൈമാറ്റത്തിനായി കാത്തിരിക്കുന്നു",
    percent: "ശതമാനം",
    applyDiscount: "കിഴിവ് പ്രയോഗിക്കുക",
    thisBillHasBeenSaved: "ഈ ബിൽ സംരക്ഷിച്ചു",
    saveDraft: "ഡ്രാഫ്റ്റ് സേവ് ചെയ്യുക",
    pleaseTypeANameToIdentifyCustomer:
      "ഉപഭോക്താവിനെ തിരിച്ചറിയാൻ ദയവായി ഒരു പേര് ടൈപ്പ് ചെയ്യുക",
    paymentDetails: "പേയ്മെന്റ് വിശദാംശങ്ങൾ",
    basePrice: "അടിസ്ഥാന വില",
    staff: "ജീവനക്കാർ",
    subTotal: "ഉപ ടോട്ടൽ",
    durationMonths: "കാലാവധി (മാസം)",
    selectADuration: "ഒരു കാലയളവ് തിരഞ്ഞെടുക്കുക",
    oneMonth: "1 മാസം",
    twoMonths: "2 മാസം",
    threeMonths: "3 മാസം",
    sixMonths: "6 മാസം",
    twelveMonths: "12 മാസം",
    eighteenMonths: "18 മാസം",
    twentyFourMonths: "24 മാസം",
    twoMonthsFree: "(2 മാസം സൗജന്യമാണ്)",
    threeMonthsFree: "(3 മാസം സൗജന്യമാണ്)",
    fiveMonthsFree: "(5 മാസം സൗജന്യമാണ്)",
    yourAccountHasBeen: "നിങ്ങളുടെ അക്കൗണ്ട് കഴിഞ്ഞു",
    renewed: "പുതുക്കി",
    upgraded: "നവീകരിച്ചത്",
    successfully: "വിജയകരമായി",
    yourSubscription: "നിങ്ങളുടെ സബ്സ്ക്രിപ്ഷൻ",
    youAreCurrentlyEnrolledOnA: "നിങ്ങൾ നിലവിൽ എയിൽ ചേർന്നിരിക്കുന്നു",
    pleaseChooseAPaymentOption: "ഒരു പേയ്മെന്റ് ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക",
    planRenewal: "പുതുക്കൽ ആസൂത്രണം ചെയ്യുക",
    planUpgrade: "പ്ലാൻ അപ്ഗ്രേഡ്",
    pleaseSelectDurationToPayFor:
      "നിങ്ങൾ പണമടയ്ക്കാൻ ഉദ്ദേശിക്കുന്ന സമയം തിരഞ്ഞെടുക്കുക",
    staffAccounts: "സ്റ്റാഫ് അക്കൗണ്ടുകൾ",
    ecommerce: "ഇകൊമേഴ്സ്",
    pleaseSelectAPlan: "ദയവായി ഒരു പ്ലാൻ തിരഞ്ഞെടുക്കുക",
    includeAddons: "ആഡ്-ഓണുകൾ ഉൾപ്പെടുത്തുക",
    viewTransactions: "ഇടപാടുകൾ കാണുക",
    customerHasNoCompletedTansactions:
      "ഉപഭോക്താവിന് ഇതുവരെ ടാൻസാക്ഷനുകൾ പൂർത്തിയായിട്ടില്ല",
    branch: "ശാഖ",
    enterNumberOfEcommerceBranches: "ഇ -കൊമേഴ്സ് ബ്രാഞ്ചുകളുടെ എണ്ണം നൽകുക",
    enterNumberOfEcommerceBranchesToPay:
      "നിങ്ങൾ പണമടയ്ക്കാൻ ഉദ്ദേശിക്കുന്ന ഇ -കൊമേഴ്സ് ബ്രാഞ്ചുകളുടെ എണ്ണം നൽകുക",
    ecommerceIntegration: "ഇകൊമേഴ്സ് സംയോജനം",
    enterNumberOfBranches: "ശാഖകളുടെ എണ്ണം നൽകുക",
    enterNumberOfAdditionalBranchesToPay:
      "നിങ്ങൾ പണമടയ്ക്കാൻ ഉദ്ദേശിക്കുന്ന അധിക ബ്രാഞ്ചുകളുടെ എണ്ണം നൽകുക",
    enterNumberOfStaffs: "ജീവനക്കാരുടെ എണ്ണം നൽകുക",
    enterNumberOfStaffsToPayFor:
      "നിങ്ങൾ പണമടയ്ക്കാൻ ഉദ്ദേശിക്കുന്ന ജീവനക്കാരുടെ എണ്ണം നൽകുക",
    discountApplies: "കിഴിവ് ബാധകമാണ്",
    starsOnThe: "നക്ഷത്രങ്ങൾ",
    offer: "ഓഫർ",
    get: "നേടുക",
    moreStarsToRedeem: "വീണ്ടെടുക്കാൻ കൂടുതൽ നക്ഷത്രങ്ങൾ",
    taxVat: "നികുതി (VAT)",
    callCashierToCompletePayment: "പണമടയ്ക്കൽ പൂർത്തിയാക്കാൻ കാഷ്യറെ വിളിക്കുക",
    receipt: "രസീത്",
    dear: "പ്രിയ",
    thankYouForYourOrderFindGoods:
      "നിങ്ങളുടെ ഓർഡറിന് നന്ദി. സമ്മതിച്ച പ്രകാരം താഴെ കൊടുത്തിരിക്കുന്ന സാധനങ്ങൾ കണ്ടെത്തുക.",
    shippingNote: "ഷിപ്പിംഗ് കുറിപ്പ്",
    enterShippingNote: "ഷിപ്പിംഗ് കുറിപ്പ് നൽകുക",
    shippingAddress: "ഷിപ്പിംഗ് വിലാസം",
    enterShippingAddress: "ഷിപ്പിംഗ് വിലാസം നൽകുക",
    wellDoneYouAreDueToRedeem: "നന്നായി ചെയ്തു! നിങ്ങൾ വീണ്ടെടുക്കേണ്ടതാണ്",
    toGetYourRewardNextVisit:
      "നിങ്ങളുടെ അടുത്ത സന്ദർശനത്തിൽ നിങ്ങളുടെ പ്രതിഫലം ലഭിക്കാൻ. നന്ദി",
    pointsOnThe: "പോയിന്റുകൾ",
    morePointsToRedeem: "വീണ്ടെടുക്കാൻ കൂടുതൽ പോയിന്റുകൾ",
    showCode: "കോഡ് കാണിക്കുക",
    toGetYourRewardOnNextVisit:
      "നിങ്ങളുടെ അടുത്ത സന്ദർശനത്തിൽ നിങ്ങളുടെ പ്രതിഫലം ലഭിക്കാൻ. നന്ദി",
    earn: "സമ്പാദിക്കുക",
    delivaryNote: "ഡെലിവറി കുറിപ്പ്",
    draftSales: "കരട് വിൽപ്പന",
    startDate: "ആരംഭിക്കുന്ന തീയതി",
    endDate: "അവസാന ദിവസം",
    orders: "ഓർഡറുകൾ",
    checkout: "ചെക്ക് ഔട്ട്",
    noProductItem: "ഉൽപ്പന്ന ഇനം ഇല്ല",
    selectProductImage: "ഉൽപ്പന്ന ചിത്രം തിരഞ്ഞെടുക്കുക",
    selectCountry: "രാജ്യം തിരഞ്ഞെടുക്കുക",
    selectRegion: "സംസ്ഥാനം/പ്രദേശം തിരഞ്ഞെടുക്കുക",
    printProductTag: "ഉൽപ്പന്ന ടാഗ് അച്ചടിക്കുക",
    transactionReference: "ഇടപാട് റഫറൻസ്",
    Cashier: "കാഷ്യർ",
    Manager: "മാനേജർ",
    Owner: "ഉടമ",
    Admin: "അഡ്മിൻ",
    addPartners: "പങ്കാളികളെ ചേർക്കുക",
    addNewLoyaltyPartner: "പുതിയ ലോയൽറ്റി പങ്കാളിയെ ചേർക്കുക",
    pleaseEnterCompanyName: "കമ്പനിയുടെ പേര് നൽകുക",
    companyName: "കമ്പനി പേര്",
    pleaseEnterCompanyRepName: "കമ്പനി പ്രതിനിധിയുടെ പേര് നൽകുക",
    companyRepName: "കമ്പനി പ്രതിനിധിയുടെ പേര്",
    pleaseEnterCompanyRepEmail: "കമ്പനി പ്രതിനിധിയുടെ ഇമെയിൽ നൽകുക",
    companyRepEmail: "കമ്പനി പ്രതിനിധിയുടെ ഇമെയിൽ",
    pleaseEnterCompanyRepPhone: "കമ്പനി പ്രതിനിധിയുടെ ഫോൺ നമ്പർ നൽകുക",
    companyRepPhone: "കമ്പനി പ്രതിനിധിയുടെ ഫോൺ നമ്പർ",
    addReward: "പ്രതിഫലം ചേർക്കുക",
    pleaseEnterRewardName: "റിവാർഡിന്റെ പേര് നൽകുക",
    rewardName: "റിവാർഡ് പേര്",
    rewardQuantity: "റിവാർഡ് അളവ്",
    rewardDescription: "റിവാർഡ് വിവരണം",
    rewardType: "റിവാർഡ് തരം",
    pleaseEnterRewardType: "റിവാർഡ് തരം നൽകുക",
    pleaseEnterRewardQuantity: "റിവാർഡ് അളവ് നൽകുക",
    pleaseEnterRewardDescription: "റിവാർഡ് വിവരണം നൽകുക",
    fineDining: "ഫൈൻ ഡൈനിംഗ്",
    luxuryFashion: "ലക്ഷ്വറി ഫാഷൻ",
    hotels: "ഹോട്ടലുകൾ",
    travel: "യാത്ര",
    foodAndBeverage: "ഭക്ഷ്യ പാനീയം",
    fashion: "ഫാഷൻ",
    health: "ആരോഗ്യം",
    furniture: "ഫർണിച്ചർ",
    entertainment: "വിനോദം",
    automobile: "ഓട്ടോമൊബൈൽ",
    education: "വിദ്യാഭ്യാസം",
    beautyAndSpa: "സൗന്ദര്യവും സ്പായും",
    staycation: "താമസം",
    events: "ഇവന്റുകൾ",
    trips: "യാത്രകൾ",
    oilAndGas: "എണ്ണയും വാതകവും",
    laundry: "അലക്കൽ",
    partnerCategory: "പങ്കാളി വിഭാഗം",
    freeItem: "സൗജന്യ ഇനം",
  },
  "Myanmar (Burmese)": {
    cashier: "ငွေကိုင်",
    manager: "မန်နေဂျာ",
    owner: "ပိုင်ရှင်",
    online: "အွန်လိုင်း",
    offline: "အော့ဖ်လိုင်း",
    changePassword: "စကားဝှက်ကိုပြောင်းရန်",
    currentPasswordMessage: "ကျေးဇူးပြု၍ သင်၏လက်ရှိစကားဝှက်ကိုထည့်ပါ",
    passwordMessage: "ကျေးဇူးပြု၍ သင်၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
    currentPassword: "လက်ရှိစကားဝှက်",
    password: "password ပါ",
    confirmPasswordMessage: "ကျေးဇူးပြု၍ သင်၏စကားဝှက်ကိုအတည်ပြုပါ။",
    confirmPassword: "စကားဝှက်အတည်ပြုခြင်း",
    sendViaEmail: "Email မှတဆင့်ပို့ပါ",
    sendViaWhatsapp: "WhatsApp မှတဆင့်ပို့ပါ",
    downloadPdf: "PDF ကို download လုပ်ပါ",
    paid: "ပေးဆပ်ခဲ့သည်",
    unpaid: "မပေးဆပ်ရ",
    partial: "တစ်ပိုင်းတစ်စ",
    closeInvoice: "ငွေတောင်းခံလွှာကိုပိတ်လိုပါသလား။",
    closeInvoiceConfirmation:
      "ငွေတောင်းခံလွှာကိုမသိမ်းဆည်းနိုင်ပါ။ သင်ပိတ်လိုပါသလား။",
    yes: "ဟုတ်တယ်",
    no: "မဟုတ်ဘူး",
    invoice: "ပြေစာ",
    wasDeducted: "နုတ်ယူခဲ့ပါသည်",
    for: "အဘို့",
    item: "ပစ္စည်း",
    addProduct: "ကုန်ပစ္စည်းထည့်ပါ",
    paymentReference: "ငွေပေးချေမှုအကိုးအကား",
    amountPaid: "ပေးဆပ်ရသည့်ပမာဏ",
    discountAmount: "လျှော့စျေးပမာဏ",
    amountDue: "ပေးရမည့်ပမာဏ",
    amount: "ပမာဏ",
    dueDate: "သတ်မှတ်ချိန်းရက်",
    paymentType: "ငွေပေးချေမှုအမျိုးအစား",
    card: "ကတ်",
    cash: "ငွေသား",
    bankTransfer: "ဘဏ်လွှဲပြောင်း",
    paymentMessage: "ငွေပေးချေမှုသတင်း",
    description: "ဖော်ပြချက်",
    sendReceipt: "ပြေစာပို့ပါ",
    delete: "ဖျက်ပါ",
    save: "ကယ်ပါ",
    resend: "ပြန်လည်ပေးပို့ပါ",
    saveAndSend: "ကယ်ပါ",
    invoiceSaved: "ပြေစာကိုသိမ်းပြီးပါပြီ။",
    selectPaymentMethod: "ကျေးဇူးပြု၍ ငွေပေးချေမှုနည်းလမ်းကိုရွေးပါ။",
    selectCustomer: "ကျေးဇူးပြု၍ Customer တစ်ခုကိုရွေးပါ။",
    cartEmptyError:
      "လှည်းစာရင်းအလွတ်မရပါ၊ ငွေတောင်းခံလွှာကိုပိတ်ပြီးပစ္စည်းထဲသို့လှည်းထဲသို့ထည့်ပါ။",
    subscriptionExpired:
      "သင်၏စာရင်းပေးသွင်းမှုသက်တမ်းကုန်သွားပြီးသင်၏အကောင့်သည်ယခုအခါအကန့်အသတ်ရှိသည်။ သင်၏အကောင့်ကိုသက်တမ်းတိုးရန်အောက်ပါ Button ကိုနှိပ်ပါ",
    renew: "သက်တမ်းတိုးပါ",
    holdOn: "နပေါဦး",
    customerBank: "Customer ဘဏ်",
    cancel: "ပယ်ဖျက်ပါ",
    selectACustomer: "Customer ရွေးပါ",
    invoiceSuccessfulPdfError:
      "ငွေတောင်းခံလွှာကိုအောင်မြင်စွာဖန်တီးနိုင်သော်လည်း PDF Generation သည်ပုံမှန်ထက်ပိုကြာနေသည်။ ကျေးဇူးပြု၍ ခဏနေပြန်စစ်ကြည့်ပါ။",
    downloadingInvoice: "ငွေတောင်းခံလွှာကိုဒေါင်းလုပ်ဆွဲနေသည်",
    downloadingReceipt: "ပြေစာကိုဒေါင်းလုပ်ဆွဲနေသည်",
    whatsappReceiptError:
      "ပြေစာကို WhatsApp မှတဆင့်ပြေစာပို့။ အမှားကြုံခဲ့သည်၊ ကျေးဇူးပြု၍ ထပ်ကြိုးစားပါ။",
    receiptToWhatsapp: "လက်ခံဖြတ်ပိုင်းကို WhatsApp သို့ပို့လိုက်သည်",
    thankYouForPatronage: "သင့်အားပေးမှုအတွက်ကျေးဇူးတင်ပါတယ်",
    hereIsYourReceipt: "ဒီမှာမင်းရဲ့ငွေပေးချေမှုပြေစာ",
    errorSendingEmailReceipt:
      "ပြေစာကိုအီးမေးလ်မှတဆင့်ပေးပို့ရာတွင်အမှားတစ်ခုပေါ်ပေါက်ခဲ့သည်၊ ကျေးဇူးပြု၍ ထပ်ကြိုးစားပါသို့မဟုတ်အကူအညီကိုဆက်သွယ်ပါ",
    receiptSentToEmail: "ပြေစာကိုဖောက်သည်၏အီးမေးလ်သို့ပို့ပြီးပါပြီ",
    invoiceSentToEmail: "ပြေစာကိုဖောက်သည်၏အီးမေးလ်သို့ပို့ပြီးပါပြီ",
    invoiceSuccessWhatsappError:
      "ငွေတောင်းခံလွှာကိုအောင်မြင်စွာပြုလုပ်ပြီးဖြစ်သော်လည်း WhatsApp သို့အမှားတစ်ခုဖြစ်ပွားခဲ့သည်။ ငွေတောင်းခံလွှာတွင်ထပ်မံကြိုးစားပါ",
    invoiceSuccessfulEmailError:
      "ငွေတောင်းခံလွှာကိုအောင်မြင်စွာပြုလုပ်ပြီးသော်လည်းအီးမေးလ်ပို့စဉ်အမှားတစ်ခုကြုံခဲ့သည်။ ငွေတောင်းခံလွှာစာရင်းမှထပ်ကြိုးစားပါ",
    invoiceSentToWhatsapp: "ငွေတောင်းခံလွှာကို WhatsApp သို့ပို့လိုက်သည်",
    hello: "မင်္ဂလာပါ",
    pleaseDownloadInvoice: "ငွေတောင်းခံလွှာကို download လုပ်ပါ",
    pleaseDownloadReceipt: "ပြေစာကို download လုပ်ပါ",
    from: "ထံမှ",
    email: "အီးမေးလ်",
    upgrade: "အဆင့်မြှင့်ပါ",
    youAreOnFreePlan: "သင်ဟာအခမဲ့အစီအစဉ်တစ်ခုတွင်ရှိသည်။",
    clickOn: "ကိုနှိပ်ပါ",
    yourPlanInFewSteps: " အဆင့်အနည်းငယ်ဖြင့်သင်၏စာရင်းပေးသွင်းမှု",
    to: "သို့",
    yourSubscriptionHasExpired:
      "သင်၏စာရင်းပေးသွင်းမှုသက်တမ်းကုန်သွားပြီးသင်၏အကောင့်သည်ယခုအခါအကန့်အသတ်ရှိသည်။",
    days: "ရက်ပေါင်း",
    yourSubscriptionExpiresIn:
      "သင်၏ Loystar စာရင်းပေးသွင်းမှုသည်သက်တမ်းကုန်ဆုံးသည်",
    createAcount: "အကောင့်တစ်ခုဖန်တီးသည်",
    signIn: "ဆိုင်းအင်လုပ်ခြင်း",
    continue: "ဆက်လက်",
    enterOtp: "OTP PIN ကိုရိုက်ထည့်ပါ",
    enterValidOtp: "ကျေးဇူးပြု၍ မှန်ကန်သော PIN တစ်ခုထည့်ပါ",
    pin: "ပင်နံပါတ်",
    tokenSentToMail: "တိုကင်တစ်ခုသင့်အီးမေးလ်သို့ပို့လိုက်သည်",
    passwordResetSuccessful: "စကားဝှက်ပြန်လည်သတ်မှတ်ခြင်းအောင်မြင်သည်",
    somethingWentWrong: "တစ်ခုခုမှားနေပြီ။",
    resetPassword: "လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်",
    iHaveResetCode: "ငါ့မှာစကားဝှက်ပြန်ညှိတဲ့ကုဒ်ရှိတယ်",
    pleaseEnterEmail: "ကျေးဇူးပြု၍ သင်၏အီးမေးလ်ကိုထည့်ပါ",
    aTokenWillBeSentToEmail: "တိုကင်တစ်ခုသင့်အီးမေးလ်သို့ပို့လိမ့်မည်",
    enterEmail: "သင့်၏အီးမေးလ်လိပ်စာထည့်ပါ",
    sendinYourToken: "မင်းရဲ့သင်္ကေတကိုပို့နေတာ ...",
    makeSureItMatchesPassword:
      "၎င်းသည်သင်၏စကားဝှက်အသစ်နှင့်ကိုက်ညီကြောင်းသေချာပါစေ",
    pleaseChooseNewPassword: "ကျေးဇူးပြု၍ စကားဝှက်အသစ်ကိုရွေးပါ",
    chooseNewPassword: "စကားဝှက်အသစ်ကိုရွေးပါ",
    enterNewPassword: "အတည်ပြုရန်သင်၏စကားဝှက်အသစ်ကိုရိုက်ထည့်ပါ",
    enterTokenSent: "မင်းမေးလ်ပို့လိုက်တဲ့တိုကင်ကိုရိုက်ထည့်ပါ",
    resetToken: "Token ကိုပြန်လည်သတ်မှတ်ပါ",
    resettingPassword: "သင်၏စကားဝှက်ကိုပြန်လည်သတ်မှတ်နေသည် ...",
    signUp: "ဆိုင်းအပ်",
    adminSignInWithEmail:
      " ၀ န်ထမ်းသည် username ဖြင့် ၀ င်နေစဉ် Admin သည်အီးမေးလ်ဖြင့် ၀ င်သည်။",
    pleaseEnterEmailOrUsername:
      "ကျေးဇူးပြု၍ သင်၏အီးမေးလ် (သို့) အသုံးပြုသူအမည်ကိုရိုက်ထည့်ပါ",
    emailOrUsername: "အီးမေးလ်သို့မဟုတ်အသုံးပြုသူအမည်",
    pleaseEnterPassword: "ကျေးဇူးပြု၍ စကားဝှက်ထည့်ပါ",
    createAnAccount: "အကောင့်တစ်ခုဖန်တီးသည်",
    forgotPassword: "စကားဝှက်ကိုမေ့နေပါသလား?",
    enterPhoneNumber: "ဖုန်းနံပါတ်ထည့်ပါ",
    personalData: "ကိုယ်ပိုင်ဒေတာ",
    validateConfirmationCOde: "အတည်ပြုကုဒ်အတည်ပြုပါ",
    pleaseEnterFirstName: "ကျေးဇူးပြု၍ သင်၏ပထမအမည်ကိုထည့်ပါ",
    pleaseEnterPhoneNumber: "ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
    pleaseEnterLastName: "ကျေးဇူးပြု၍ သင်၏နောက်ဆုံးအမည်ကိုထည့်ပါ",
    pleaseEnterBusinessName: "ကျေးဇူးပြု၍ သင်၏လုပ်ငန်းအမည်ထည့်ပါ",
    firstName: "နာမည်",
    lastName: "မျိုးနွယ်အမည်",
    businessName: "လုပ်ငန်းအမည်",
    previous: "ယခင်",
    next: "နောက်တစ်ခု",
    pleaseSelectBusinessCategory: "ကျေးဇူးပြု၍ သင်၏လုပ်ငန်းအမျိုးအစားကိုရွေးပါ",
    pleaseEnterValidEmail: "ကျေးဇူးပြု၍ မှန်ကန်သောအီးမေးလ်ကိုထည့်ပါ",
    pleaseEnterPostCode: "ကျေးဇူးပြု၍ ပို့စ်ကုဒ်ထည့်ပါ",
    postCode: "Post Code ပါ",
    phoneNumberInUse: "ဒီဖုန်းနံပါတ်ကိုသုံးနေပြီ။",
    emailInUse: "ဒီအီးမေးလ်ကိုသုံးနေပြီ။",
    foodAndDrinks: "အစားအစာနှင့်အချိုရည်များ",
    salonAndBeauty: "အလှပြင်ဆိုင်နှင့်အလှအပ",
    fashionAndAccessories: "ဖက်ရှင်နှင့်ဆက်စပ်ပစ္စည်းများ",
    gymAndFitness: "Gym နှင့် Fitness",
    travelAndHotel: "ခရီးသွားနှင့်ဟိုတယ်",
    homeAndGifts: "အိမ်နှင့်လက်ဆောင်များ",
    washingAndCleaning: "အဝတ်လျှော်ခြင်းနှင့်သန့်ရှင်းရေးပြုလုပ်ခြင်း",
    gadgetsAndElectronics: "Gadgets များနှင့် Electronics များ",
    groceries: "ကုန်စုံဆိုင်များ",
    others: "အခြားသူများ",
    submit: "တင်ပေးပါ",
    accountCreatedSuccessful: "သင်၏အကောင့်ကိုအောင်မြင်စွာပြုလုပ်ပြီးပါပြီ။",
    pleaseEnterAddress: "ကျေးဇူးပြု၍ သင်၏လိပ်စာကိုထည့်ပါ",
    addressLine1: "လိပ်စာစာကြောင်း 1",
    addressLine2: "လိပ်စာလိုင်း ၂",
    choosePassword: "Password ကိုရွေးပါ",
    passwordMustBe6Characters: "စကားဝှက်သည်အနည်းဆုံးအက္ခရာ ၆ လုံးရှိရမည်။",
    howDidYouHearLoystar: "Loystar အကြောင်းမင်းဘယ်လိုကြားဖူးလဲ။",
    referralCode: "ရည်ညွန်းချက်",
    byClickingTheButton: " အောက်ပါခလုတ်ကိုနှိပ်ခြင်းအားဖြင့်သင်သဘောတူသည်",
    termsAndSevice: "စည်းကမ်းချက်များ",
    wereCreatingAccount: "ငါတို့မင်းရဲ့အကောင့်ကိုဖန်တီးနေတယ်",
    proceed: "ရှေ့ဆက်ပါ",
    verificationCodeMustBe6: "Verification Code သည်ဂဏန်း ၆ လုံးပါရမည်",
    pleaseEnter6DigitCode: "ကျေးဇူးပြု၍ ဂဏန်း ၆ လုံးကုဒ်ကိုရိုက်ထည့်ပါ",
    enterVerificationCode: "Verification Code ကိုရိုက်ထည့်ပါ",
    resendToken: "တိုကင်ပြန်ပို့ပါ",
    verify: "စိစစ်ပါ",
    transactions: "အရောင်းအဝယ်",
    todaySales: "ယနေ့အရောင်း",
    salesHistory: "အရောင်းမှတ်တမ်း",
    supplyHistory: "ထောက်ပံ့ရေးသမိုင်း",
    new: "အသစ်",
    invoices: "ပြေစာများ",
    disbursements: "ငွေပေးချေမှုများ",
    offlineSales: "အော့ဖ်လိုင်းအရောင်း",
    products: "ထုတ်ကုန်များ",
    customers: "ဝယ်သည်",
    multiLevelLoyalty: "ဘက်စုံသစ္စာရှိမှု",
    loyaltyPrograms: "သစ္စာရှိမှုအစီအစဉ်များ",
    members: "အဖွဲ့ဝင်များ",
    appStore: "အပ္ပလီကေးရှင်းစတိုး",
    orderMenu: "အော်ဒါမီနူး",
    settings: "ဆက်တင်များ",
    staffAndBranches: "ဝန်ထမ်းများနှင့်ဌာနခွဲများ",
    myAccount: "ငါ့အကောင့်",
    switchToSellMode: "အရောင်းပုံစံသို့ပြောင်းပါ",
    signOut: "ဆိုင်းအောက်",
    getFreeSubscription: "အခမဲ့စာရင်းပေးသွင်းမှုကိုရယူပါ",
    onlyNumbersAllowed: "ဂဏန်းများသာခွင့်ပြုသည်",
    yourAccountMustBe10Digits: "သင်၏အကောင့်နံပါတ်သည်ဂဏန်း ၁၀ လုံးဖြစ်ရမည်",
    yourBvnMustBe11: "သင်၏ BVN သည်ဂဏန်း ၁၁ လုံးဖြစ်ရမည်",
    pleaseSelectBank: "ကျေးဇူးပြု၍ သင်၏ဘဏ်ကိုရွေးပါ",
    selectYourBank: "သင်၏ဘဏ်ကိုရွေးပါ",
    enterBankAccountNumber: "ဘဏ်အကောင့်နံပါတ်ထည့်ပါ",
    enterBvnNumber: "သင်၏ BVN ကိုရိုက်ထည့်ပါ",
    connectBankAccount: "ဘဏ်အကောင့်ချိတ်ဆက်ပါ",
    passwordResetSuccessfulAndSignOut:
      "သင်၏စကားဝှက်ကိုအောင်မြင်စွာပြန်လည်ပြင်ဆင်ပြီးပါပြီ။ ထပ်မံလက်မှတ်ထိုးရန်အောက်ပါထွက်ရန်ခလုတ်ကိုနှိပ်ပါ",
    enterCurrentPassword: "လက်ရှိစကားဝှက်ကိုရိုက်ထည့်ပါ",
    pleaseEnterCurrentPassword: "ကျေးဇူးပြု၍ လက်ရှိစကားဝှက်ကိုထည့်ပါ",
    phoneNumber: "ဖုန်းနံပါတ်",
    sex: "လိင်ဆက်ဆံခြင်း",
    dateOfBirth: "မွေးနေ့",
    state: "ပြည်နယ်",
    country: "တိုင်းပြည်",
    loyaltyId: "သစ္စာရှိမှု ID",
    createdAt: "တွင်ဖန်တီးခဲ့သည်",
    noOfTransactionsMade: "အရောင်းအဝယ်လုပ်သောအရေအတွက်",
    yourDownloadWillStart: "သင်၏ဒေါင်းလုပ်သည်ခဏအတွင်းစတင်လိမ့်မည်",
    exportCustomers: "ဖောက်သည်များတင်ပို့ပါ",
    youhaveSuccessfullyToppedUp:
      "မင်းရဲ့ SMS Units တွေကိုအောင်မြင်စွာဖြည့်ပြီးပြီ။",
    youNowHave: "ခုတော့ရပြီ",
    smsUnits: "SMS ယူနစ်များ",
    enterNumberOfSmsUnits: "သင် ၀ ယ်ယူလိုသော SMS ယူနစ်အရေအတွက်ကိုရိုက်ထည့်ပါ",
    pleaseEnterANumericValue: "ကျေးဇူးပြု၍ ဂဏန်းတန်ဖိုးတစ်ခုထည့်ပါ",
    pay: "ပေးဆပ်ရတယ်",
    accountEditedSuccessfully: "သင်၏အကောင့်ကိုအောင်မြင်စွာတည်းဖြတ်ပြီးပါပြီ။",
    youAeCurrentlyOn: "သင်လက်ရှိသုံးနေပါသည်",
    plan: "အစီအစဉ်",
    userData: "အသုံးပြုသူဒေတာ",
    businessData: "စီးပွားရေးအချက်အလက်",
    businessCategory: "စီးပွားရေး Catergory",
    pleaseSelectCurrency: "ကျေးဇူးပြု၍ သင်၏ငွေကြေးကိုရွေးပါ",
    selectYourCurrency: "သင်၏ငွေကြေးကိုရွေးပါ",
    purchaseMoreSmsUnits:
      "အောက်ပါပုံစံကိုသုံးပြီးနောက်ထပ် SMS ယူနစ်များ ၀ ယ်ယူပါ",
    youHave: "သင်မှာ .... ရှိသည်",
    atLeast4SmsUnitsRrequired:
      "အတည်ပြုရန်အနည်းဆုံး sms ယူနစ် ၄ ခုလိုအပ်သည်၊ ကျေးဇူးပြု၍ ငွေဖြည့်ပါ။",
    pleaseVerifyYourAccountToComplyWithKyc:
      "ကျေးဇူးပြု၍ သင်၏ဘဏ်အကောင့်ကိုအတည်ပြုပါ။ ၎င်းသည်သင် USSD သို့ချက်ချင်းငွေလွှဲခြင်းမှတဆင့်ငွေပေးချေမှုများ၊ ဖောက်သည်များထံမှမှာယူမှုများနှင့်ငွေပေးငွေယူဖြေရှင်းခြင်းလုပ်ငန်းစဉ်များကိုခွင့်ပြုလိမ့်မည်။ နိုင်ဂျီးရီးယားကုန်သည်များအတွက်သာသက်ဆိုင်သည်။ ကျေးဇူးပြု၍ စတင်ရန်အောက်ပါခလုတ်ကိုနှိပ်ပါ။",
    reConnectBankAccount: "ဘဏ်အကောင့်ပြန်ချိတ်ပါ",
    accountName: "အကောင့်အမည်",
    accountNumber: "အကောင့်နံပါတ်",
    bankName: "ဘဏ်အမည်",
    verified: "စိစစ်ပြီးဖြစ်သည်",
    accountDetails: "အကောင့်အသေးစိတ်",
    kycBankAccount: "KYC",
    createTier: "အဆင့်ကိုဖန်တီးပါ",
    fileUploadSuccessful: "ဖိုင်တင်ပြီးပါပြီ",
    fileUploadFailed: "ဖိုင်တင်ခြင်းမအောင်မြင်ပါ",
    createLoyaltyProgram: "သစ္စာရှိမှုအစီအစဉ်တစ်ခုကိုဖန်တီးပါ",
    createLoyalty: "သစ္စာရှိမှုကိုဖန်တီးပါ",
    name: "နာမည်",
    loyaltyArtwork: "သစ္စာရှိမှုအနုပညာ",
    clickToUpload: "တင်ရန်နှိပ်ပါ",
    amountToPointsRatio: "Amount to Points အချိုး",
    points: "အချက်များ",
    recommendedAmountToPointRatio:
      "အကြံပြုထားသည်။ ₦ ၁ မှ ၁ မှတ် ဆိုလိုသည်မှာ Naira ၁ ခုတိုင်းအတွက်သင်၏ဖောက်သည်များသည် ၁ မှတ်ရနိုင်သည်",
    pleaseTypeIn: "ကျေးဇူးပြု၍ ရိုက်ထည့်ပါ",
    toDeleteLoyalty: "ဤသစ္စာရှိမှုကိုဖျက်ရန်",
    deleteLoyalty: "သစ္စာရှိမှုကိုဖျက်ပါ",
    toConfirm: "အတည်ပြုရန်",
    edit: "တည်းဖြတ်ပါ",
    pointsAwardedSuccessfully: "အမှတ်များအောင်မြင်စွာချီးမြှင့်ခဲ့သည်။",
    enterPointValueToAward:
      "၀ ယ်သူများအားသင်ချီးမြှင့်လိုသော Point Value ကိုထည့်ပါ",
    award: "ဆု",
    awardPointValuesToCustomer: "Customer များအတွက် Point Point တန်ဖိုး",
    enrollMembersToLoyalty: "အဖွဲ့ဝင်များကိုသစ္စာရှိမှုသို့စာရင်းသွင်းပါ",
    awardPoints: "ဆုအမှတ်များ",
    enroll: "စာရင်းပေးသွင်းပါ",
    home: "အိမ်ပြန်",
    loyalty: "သစ္စာစောင့်သိမှု",
    enrollCustomers: "ဖောက်သည်များကိုစာရင်းပေးသွင်းပါ",
    selected: "ရွေးချယ်ထားသည်",
    customer: "ဖောက်သည်",
    loyaltyActivationSuccessful: "သစ္စာရှိမှုအသက်သွင်းမှုအောင်မြင်သည်။",
    loyaltyDeactivationSuccessful: "Loyalty Deactivation အောင်မြင်သည်။",
    viewTier: "Tier ကိုကြည့်ပါ",
    deactivate: "De-Activate လုပ်ပါ",
    activate: "Activate လုပ်ပါ",
    actions: "လုပ်ဆောင်ချက်များ",
    nameOfLoyalty: "သစ္စာစောင့်သိမှုအမည်",
    loyaltyStatus: "သစ္စာစောင့်သိမှုအဆင့်သစ္စာရှိမှုအဆင့်",
    numberOfTiers: "အဆင့်အရေအတွက်",
    createdOn: "တွင်ဖန်တီးခဲ့သည်",
    createATier: "အဆင့်တစ်ခုဖန်တီးပါ",
    stopCreatingTierConfirmation:
      "အဆင့်တစ်ခုဖန်တီးခြင်းကိုသင်ရပ်တန့်လိုပါသလား။",
    stopEditingTierConfirmation: "ဤအဆင့်ကိုတည်းဖြတ်ခြင်းကိုရပ်လိုပါသလား။",
    nameOfTier: "အဆင့်အမည်",
    minimumSpendingTarget: "အနည်းဆုံးအသုံးစရိတ်ပစ်မှတ်",
    maximumSpendingTarget: "အများဆုံးအသုံးစရိတ်ပစ်မှတ်",
    minimumSpendingTargetRequired: "အနည်းဆုံးအသုံးစရိတ်ပစ်မှတ်လိုအပ်သည်",
    maximumSpendingTargetRequired: "အများဆုံးအသုံးစရိတ်ပစ်မှတ်လိုအပ်သည်",
    rewardSponsor: "Reward Sponsor ပါ",
    pleaseChooseARewardSponsor: "ကျေးဇူးပြု၍ ပံ့ပိုးကူညီသူကိုရွေးချယ်ပါ",
    self: "ကိုယ့်ကိုယ်ကို",
    partner: "အဖော်",
    rewardPartner: "ဆုလာဘ်ပါတနာ",
    pleaseSelectRewardPartner: "ကျေးဇူးပြု၍ သင်၏ဆုလာဘ်ပါတနာကိုရွေးပါ",
    rewards: "ဆုလာဘ်",
    pleaseSelectAReward: "ကျေးဇူးပြု၍ ဆုတစ်ခုကိုရွေးပါ",
    instructionsOnRedeemingReward:
      "ဖောက်သည်အားဆုလာဘ်မည်သို့ပြန်လည်ပေးဆပ်ရမည်ဟူသောလမ်းညွှန်ချက်များ",
    pleaseFillInThisField: "ကျေးဇူးပြု၍ ဤကွက်လပ်ကိုဖြည့်ပါ။",
    toDeleteThisTier: " ဤအဆင့်ကိုဖျက်ရန်",
    deleteTier: "အဆင့်ကိုဖျက်ပါ",
    viewMembers: "အဖွဲ့ဝင်များကိုကြည့်ပါ",
    membersEnrolled: "အသင်းဝင်စာရင်းသွင်းပြီးပါပြီ",
    instruction: "ပို့ချသည်",
    membersIn: "အဖွဲ့ဝင်များ",
    availableTiersInLoyalty: "သစ္စာရှိမှုအစီအစဉ်တွင်ရနိုင်သောအဆင့်များ",
    tiers: "အလျှိုလျှို",
    totalTier: "စုစုပေါင်းအဆင့်",
    availableLoyaltyProgramme: "ရနိုင်သောသစ္စာရှိမှုအစီအစဉ်",
    totalLoyalties: "စုစုပေါင်းသစ္စာရှိမှု",
    memberDetails: "အဖွဲ့ဝင်အသေးစိတ်",
    nameOfCustomer: "Customer အမည်",
    address: "လိပ်စာ",
    allEnrolledMembers: "စာရင်းပေးသွင်းထားသောအသင်းဝင်များအားလုံး",
    thisIsToWishYouHappyBirthday:
      "ဒါကမင်းကိုအရမ်းပျော်ရွှင်တဲ့မွေးနေ့နဲ့သာယာပျော်ရွှင်တဲ့ဘ ၀ ကိုဆုမွန်ကောင်းတောင်းတာပါ။ သင် Loyster အတွက်အရာအားလုံးအတွက်ကျေးဇူးတင်ပါတယ်။ ပျော်ရွှင်စရာအခမ်းအနားများ",
    inputAnOfferPlease: "ကျေးဇူးပြု၍ ကမ်းလှမ်းချက်တစ်ခုထည့်ပါ",
    pleaseSelectTheInsertPoint:
      "ကျေးဇူးပြု၍ မက်ဆေ့ဂျ်၌ထည့်သွင်းရမည့်နေရာကိုရွေးပြီးထပ်နှိပ်ပါ",
    birthdayOfferAndMessage: "မွေးနေ့ကမ်းလှမ်းချက်နှင့်သတင်းစကား",
    birthdayOffer: "မွေးနေ့အလှူ",
    birthdayMessage: "မွေးနေ့သတင်းစကား",
    noOfferFound: "ကမ်းလှမ်းချက်မတွေ့ပါ",
    settingABirthdayOffer:
      "မွေးနေ့ကမ်းလှမ်းမှုတစ်ခုသတ်မှတ်ခြင်းအားဖြင့်ဖောက်သည်များသည်၎င်းတို့၏မွေးနေ့၌ SMS ဖြင့်ဤကမ်းလှမ်းချက်ကိုလက်ခံရရှိသည်",
    createOffer: "ကမ်းလှမ်းချက်ကိုဖန်တီးပါ",
    whatIsTheOffer: "ကမ်းလှမ်းချက်ကဘာလဲ။",
    editMessage: "Message ကိုတည်းဖြတ်ပါ",
    insert: "ထည့်ပါ",
    theNameOfCustomerInserted: "၀ ယ်သူနာမည်ကိုဒီနေရာမှာထည့်ပေးပါမယ်",
    theBirtdayOfferInserted:
      "မွေးနေ့ကမ်းလှမ်းချက်ကိုဤနေရာတွင်ထည့်သွင်းလိမ့်မည်",
    youSuccessfullyAddedNewBranch:
      "သင်ဘဏ်ခွဲသစ်တစ်ခုကိုအောင်မြင်စွာထည့်ပြီးပါပြီ။",
    addNewBranch: "ဘဏ်ခွဲအသစ်ထည့်ပါ",
    addBranch: "ဘဏ်ခွဲထည့်ပါ",
    additionalBranchWillIncur: "နောက်ထပ်ဘဏ်ခွဲရှိလိမ့်မည်",
    perBranch: "ဌာနခွဲတစ်ခုလျှင်",
    ecommerceBranchCosts: "Ecommerce ဌာနခွဲကုန်ကျစရိတ်",
    pleaseEnterBranchName: "ကျေးဇူးပြု၍ ဌာနခွဲအမည်ထည့်ပါ",
    pleaseEnterBranchAddress1:
      "ကျေးဇူးပြု၍ ဌာနခွဲ၏လိပ်စာစာကြောင်း ၁ ကိုရိုက်ထည့်ပါ",
    enterBranchAddress1: "ဌာနခွဲ၏လိပ်စာစာကြောင်း ၁ ကိုရိုက်ထည့်ပါ",
    enterBranchAddress2: "ဌာနခွဲ၏လိပ်စာစာကြောင်း ၁ ကိုရိုက်ထည့်ပါ",
    pleaseEnterBranchAddress2:
      "ကျေးဇူးပြု၍ ဌာနခွဲ၏လိပ်စာစာကြောင်း ၂ ကိုရိုက်ထည့်ပါ",
    enterBranchName: "ဌာနခွဲအမည်ထည့်ပါ",
    successfullyAddedStaff: "မင်းဝန်ထမ်းအသစ်ကိုအောင်မြင်စွာထည့်ပြီးပါပြီ။",
    addNewStaff: "ဝန်ထမ်းအသစ်ထပ်တိုးပါ",
    addStaff: "ဝန်ထမ်းထည့်ပါ",
    additionalStaffWillIncur: "နောက်ထပ် ၀ န်ထမ်းတွေထပ်တိုးလိမ့်မယ်",
    perStaff: "ဝန်ထမ်းတစ်ယောက်အတွက်",
    pleaseEnterStaffEmail: "ကျေးဇူးပြု၍ ၀ န်ထမ်း၏အီးမေးလ်ကိုထည့်ပါ",
    pleaseEnterStaffUsername: "ကျေးဇူးပြု၍ ၀ န်ထမ်း၏ username ကိုထည့်ပါ",
    pleaseEnterStaffPassword: "ကျေးဇူးပြု၍ ၀ န်ထမ်း၏စကားဝှက်ကိုထည့်ပါ",
    pleaseSelectStaffRole: "ကျေးဇူးပြု၍ ၀ န်ထမ်း၏အခန်းကဏ္ကိုရွေးပါ",
    selectStaffRole: "ဝန်ထမ်းရဲ့အခန်းကဏ္ကိုရွေးပါ",
    enterStaffEmail: "၀ န်ထမ်း၏အီးမေးလ်ကိုရိုက်ထည့်ပါ",
    enterStaffUsername: "၀ န်ထမ်း၏ username ကိုရိုက်ထည့်ပါ",
    enterStaffPassword: "၀ န်ထမ်း၏စကားဝှက်ကိုရိုက်ထည့်ပါ",
    spacesNotAllowedInUsername: "username တွင် space များကိုခွင့်မပြုပါ",
    admin: "အက်မင်",
    pleaseSelectBusinessToAttachStaff:
      "ကျေးဇူးပြု၍ ၀ န်ထမ်းများကိုပူးတွဲရန်လုပ်ငန်းကိုရွေးပါ",
    searchForBranchToAttachStaff: "၀ န်ထမ်းများပူးတွဲရန်ဌာနခွဲကိုရှာပါ",
    username: "အသုံးပြုသူအမည်",
    role: "အခန်းကဏ္",
    areYouSureToDeleteThis: "ဤအရာကိုဖျက်ရန်သေချာသလား",
    branches: "ကိုင်း",
    upgradeYourPlan: "သင်၏အစီအစဉ်ကိုအဆင့်မြှင့်ပါ။",
    add: "ထည့်ပါ",
    addNew: "အသစ်ထည့်ပါ",
    customerWithEmailAlreadyExists: "အီးမေးလ်/ဖုန်းနံပါတ်ပါသော ၀ ယ်သူရှိနေပြီ။",
    successfullyAddedNewCustomer:
      "သင်ဖောက်သည်အသစ်ကိုအောင်မြင်စွာထည့်ပြီးပါပြီ။",
    addCustomer: "Customer ထည့်ပါ",
    pleaseEnterCustomerFirstName: "ကျေးဇူးပြု၍ ၀ ယ်သူ၏အမည်ကိုထည့်ပါ",
    pleaseEnterCustomerLastName: "ကျေးဇူးပြု၍ ၀ ယ်သူ၏နာမည်ကိုထည့်ပါ",
    pleaseEnterCustomerPhoneNumber: "ကျေးဇူးပြု၍ ၀ ယ်သူ၏ဖုန်းနံပါတ်ကိုထည့်ပါ",
    pleaseEnterCustomerEmail: "ကျေးဇူးပြုပြီး customer ရဲ့ email ကိုထည့်ပေးပါ",
    pleaseEnterCustomerAddressLine: "ကျေးဇူးပြု၍ ၀ ယ်သူ၏လိပ်စာလိုင်းကိုထည့်ပါ",
    pleaseEnterCustomerOtherAddress:
      "ကျေးဇူးပြု၍ ဖောက်သည်၏အခြားလိပ်စာကိုထည့်ပါ",
    pleaseSelectCustomerGender: "ကျေးဇူးပြု၍ ၀ ယ်သူ၏လိင်ကိုရွေးပါ",
    gender: "ကျားမ",
    male: "ယောကျာ်းလေး",
    female: "အပျို",
    bank: "ဘဏ်",
    selectBank: "ဘဏ်ကိုရွေးပါ",
    stateOrRegionOrProvince: "ပြည်နယ်/တိုင်းဒေသကြီး/ပြည်နယ်",
    customerNotes: "ဖောက်သည်မှတ်စုများ",
    sendSms: "SMS ပို့ပါ",
    editCustomer: "Customer ကိုတည်းဖြတ်ပါ",
    redeemReward: "ဆုကိုရွေးပါ",
    issueLoyaltyCard: "Loyalty Card ထုတ်ပေးပါ",
    deleteCustomer: "Customer ကိုဖျက်ပါ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "သင်သည်သစ္စာရှိမှုအသင်းဝင် ID ကိုအောင်မြင်စွာပေးအပ်ပြီးပါပြီ",
    noMembershipIdAvailable:
      "အသင်းဝင် ID များမရှိပါ။ hello@loystar.co တွင်ကျွန်ုပ်တို့ကိုဆက်သွယ်ပါ",
    sendEmail: "အီးမေးလ်ပို။ ပါ",
    membershipPoints: "အသင်းဝင်အမှတ်များ",
    customerDetails: "Customer အသေးစိတ်",
    close: "ပိတ်လိုက်တယ်",
    loyaltyBalance: "သစ္စာစောင့်သိမှုလက်ကျန်",
    pointsBalance: "ပွိုင့်လက်ကျန်",
    starBalance: "ကြယ်လက်ကျန်",
    requiredPoints: "လိုအပ်သောအမှတ်များ",
    requiredStars: "လိုအပ်သောကြယ်များ",
    reddemCode: "Code ကိုရွေးနှုတ်ပါ",
    toDeleteThisCustomer: "ဤဖောက်သည်ကိုဖျက်ရန်",
    customerHasBeenDeletedSuccessfully: "ဝယ်ယူသူကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။",
    customerWithPhoneAlreadyExists: "ဖုန်းနံပါတ်ရှိသော ၀ ယ်သူရှိပြီးသားဖြစ်သည်",
    customerWasSuccessfullyEdited: "ဝယ်ယူသူကိုအောင်မြင်စွာတည်းဖြတ်ပြီးပါပြီ",
    anErrorOccured: "အမှားတစ်ခုဖြစ်ပွားခဲ့သည်",
    phoneNumberIncludeCountry: "ဖုန်းနံပါတ် (နိုင်ငံကုဒ်ထည့်ပါ)",
    yourFileQueuedForUpload:
      "သင်၏ဖိုင်ကိုအပ်လုဒ်တင်ရန်တန်းစီထားသည်။ စက္ကန့်အနည်းငယ်အကြာတွင်စာမျက်နှာကိုပြန်လည်စတင်ပါ။",
    thereWasAnErrorPerformingOperation:
      "စစ်ဆင်ရေးလုပ်ဆောင်ရာတွင်အမှားအယွင်းရှိနေသည်။",
    pleaseSelectFile: "ကျေးဇူးပြု၍ ဖိုင်တစ်ခုကိုရွေးပါ။",
    oopsOnlyCsvFilesAllowed:
      "သည်းခံပါ။ CSV ဖိုင်များကိုသာခွင့်ပြုသည်။ ကျေးဇူးပြု၍ .csv ဖိုင်တစ်ခုတင်ပါ။",
    fileShouldBeSmallerThan5Mb:
      "ဖိုင်ဆိုဒ်သည် 5MB ထက်ငယ်ရမည်။ သင့်တွင်ပိုကြီးသောဖိုင်ရှိပါက support@loystar.co သို့အီးမေးလ်ပို့ပါ။ ကျေးဇူးတင်ပါတယ်",
    customerFirstNameIsRequired: "အတန်းတွင် ၀ ယ်သူအမည်ပထမ ဦး စွာလိုအပ်သည်",
    customerPhoneNumberIsRequired: "အတန်းတွင် ၀ ယ်သူ၏ဖုန်းနံပါတ်လိုအပ်သည်",
    importCustomers: "သွင်းကုန်များဝယ်ပါ",
    upload: "တင်သည်",
    clickIconToDownloadCsv:
      "CSV file template ကို download လုပ်ရန်ဒီ icon ကိုနှိပ်ပါ။",
    getGoogleSheetVersion: "Google စာရွက်ဗားရှင်းကိုရယူပါ",
    clickOrDragFileToUpload:
      "အပ်လုဒ်လုပ်ရန်ဤဧရိယာသို့ဖိုင်ကိုနှိပ်ပါသို့မဟုတ်ဆွဲယူပါ",
    missingOrInvalidColumnHeader:
      "ကော်လံခေါင်းစီးပျောက်နေသည် (သို့) မမှန်ပါ။ ကျေးဇူးပြု၍ ပုံစံပုံစံကိုလိုက်နာပါ။ ကျေးဇူးတင်ပါတယ်။",
    youHaveImported: "သင်တင်သွင်းခဲ့သည်",
    youSuccessfullyRedeemedReward:
      "သင်၏ဆုလာဘ်ကိုအောင်မြင်စွာပြန်လည်ရွေးယူပြီးပြီ။",
    sixDigitCode: "ဂဏန်းခြောက်လုံးကုဒ်",
    pleaseEnterCustomerRewardCode:
      "ကျေးဇူးပြု၍ ဖောက်သည်၏ဆုလာဘ်ကုဒ်ကိုရိုက်ထည့်ပါ",
    enterRewardCode: "ဆုလာဘ်ကုဒ်ကိုရိုက်ထည့်ပါ။ (ဆုလာဘ်ကုဒ်သည်စာလုံးအကြီးအသေး)",
    selectLoyaltyProgram: "သစ္စာရှိမှုအစီအစဉ်ကိုရွေးချယ်ပါ",
    stamps: "တံဆိပ်ခေါင်းများ",
    smsUnitsLow: "SMS ယူနစ်များနည်းသည်",
    pleaseTopUpSmsUnits: "ကျေးဇူးပြု၍ SMS ယူနစ်များကိုငွေဖြည့်ပါ",
    smsSuccessfullySentForDelivery:
      "ပေးပို့ရန် SMS အောင်မြင်စွာပေးပို့ပြီးပါပြီ။",
    sendSmsTo: "သို့ SMS ပို့ပါ",
    allCustomers: "Customer များအားလုံး",
    unitsAvailable: "ယူနစ်များရနိုင်သည်",
    pleaseTypeInTheMessage: "ကျေးဇူးပြု၍ message ကိုရိုက်ပါ",
    message: "စာတို",
    charactersRemaining: "အက္ခရာများကျန်သေးသည်",
    avoidUseOfSpecialCharacters:
      "SMS ယူနစ်များကိုထိန်းသိမ်းရန်အထူးစာလုံးများနှင့် Emojis များအသုံးပြုခြင်းကိုရှောင်ကြဉ်ပါ။",
    note: "မှတ်ချက်",
    errorFetchingCustomersMultilevelDetail:
      "MultiLevel အသေးစိတ်များကို ၀ ယ်ယူသူများအမှား",
    search: "ရှာဖွေပါ",
    reset: "ပြင်ဆင်သတ်မှတ်ပါ",
    importCustomer: "Customer ကိုတင်သွင်းပါ",
    addNewCustomer: "Customer အသစ်ထည့်ပါ",
    sendSmsBroadcast: "SMS ထုတ်လွှင့်ပါ",
    totalCustomers: "စုစုပေါင်းဖောက်သည်",
    disbursementDetails: "ငွေပေးချေမှုအသေးစိတ်",
    paidBy: "ပေးဆပ်ခဲ့သည်",
    disbursed: "ငွေထုတ်သည်",
    bankAccountName: "ဘဏ်အကောင့်အမည်",
    bankAccountNumber: "ဘဏ်အကောင့်နံပါတ်",
    transferInitiated: "လွှဲပြောင်းခြင်းစတင်ခဲ့သည်",
    transferCompleted: "လွှဲပြောင်းပြီးစီးခဲ့သည်",
    pleaseEnterAValid: "ကျေးဇူးပြု၍ မှန်ကန်သောတစ်ခုကိုထည့်ပါ",
    begin: "အစ",
    end: "အဆုံး",
    date: "ရက်စွဲ",
    paymentDate: "ငွေပေးချေသည့်နေ့ရက်",
    selectDisbursementDuration: "Disbursement Duration ကိုရွေးပါ",
    totalSettled: "စုစုပေါင်းသတ်မှတ်ထားသည်",
    totalUnsettled: "စိတ်မတည်ငြိမ်မှုစုစုပေါင်း",
    toDeleteThisSale: "ဤအရောင်းကိုဖျက်ရန်",
    draftSaleDeletedSuccessfully:
      "မူကြမ်းရောင်းချခြင်းကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။",
    deleteSale: "အရောင်းကိုဖျက်ပါ",
    pleaseTypeInYourTotal: "ဖျက်ခြင်းကိုအတည်ပြုရန်သင်၏စုစုပေါင်းစာကိုရိုက်ပါ",
    billDetails: "Bill အသေးစိတ်",
    printBill: "ပုံနှိပ်ဥပဒေကြမ်း",
    servedBy: "ကဆောင်ရွက်ပေးသည်",
    total: "စုစုပေါင်း",
    createdDate: "ဖန်တီးခဲ့သည့်ရက်စွဲ",
    createdTime: "ဖန်တီးထားတဲ့အချိန်",
    status: "အဆင့်အတန်း",
    loginSuccessful: "Login အောင်မြင်သည်",
    pleaseWaitWhileWeConnectAccount:
      "ကျေးဇူးပြု၍ သင်၏အကောင့်ကိုကျွန်ုပ်တို့ချိတ်ဆက်နေစဉ်ခဏစောင့်ပါ",
    connectionFailedTryAgain:
      "ချိတ်ဆက်မှုမအောင်မြင်ပါ။ ကျေးဇူးပြု၍ ထပ်ကြိုးစားပါ။",
    connectionSuccessful: "ချိတ်ဆက်မှုအောင်မြင်သည်",
    viewDetails: "အသေးစိတ်ကြည့်ပါ",
    enable: "ဖွင့်ပေးသည်",
    free: "အခမဲ့ဖြစ်သည်",
    cost: "ကုန်ကျစရိတ်",
    visitWebsite: "Website ကိုသွားကြည့်ပါ",
    pleaseUpgradeYourPlanToPro:
      "ကျေးဇူးပြု၍ သင်၏အစီအစဉ်ကို Pro Plus သို့အဆင့်မြှင့်တင်ပါ",
    connectYourBankAccount:
      "ငွေပေးချေမှုကိုလက်ခံနိုင်ရန်သင်၏ဘဏ်အကောင့်နှင့်ချိတ်ဆက်ပါ။",
    disable: "ပိတ်ထားပါ",
    enableApp: "App ကို Enable လုပ်ပါ",
    addNewProductToInvoice: "ငွေတောင်းခံလွှာထဲသို့ကုန်ပစ္စည်းအသစ်ထည့်ပါ",
    toDeleteThisInvoice: "ဤပြေစာကိုဖျက်ရန်",
    invoiceDeletedSuccessfully: "ငွေတောင်းခံလွှာကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။",
    deleteInvoice: "ငွေတောင်းခံလွှာကိုဖျက်ပါ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ပြေစာငွေပေးချေမှုစာကိုသင်အောင်မြင်စွာထည့်ပြီးပါပြီ။",
    pleaseEnterPaymentInstructions:
      "ကျေးဇူးပြု၍ ငွေပေးချေမှုညွှန်ကြားချက်များထည့်ပါ",
    invoiceId: "ငွေတောင်းခံလွှာ ID",
    paidDate: "ပေးဆပ်ရမည့်ရက်",
    reference: "ကိုးကား",
    productAdded: "ထုတ်ကုန်ထည့်ထားသည်",
    productOutOfStock: "ကုန်ပစ္စည်းမှာကုန်ဆုံးသွားပြီ။ ကျေးဇူးပြုပြီးထပ်တင်ပါ။",
    totalInvoices: "စုစုပေါင်းငွေတောင်းခံလွှာ",
    totalPaidInvoices: "စုစုပေါင်းငွေပေးချေမှုမှတ်တမ်းများ",
    totalUnpaidInvoices: "စုစုပေါင်းငွေတောင်းခံလွှာများ",
    loyaltyProgramDeleted: "သစ္စာရှိမှုအစီအစဉ်ကိုဖျက်လိုက်သည်",
    thisLoyaltyProgramDeletedSuccessfully:
      "ဤသစ္စာရှိမှုအစီအစဉ်ကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ",
    thisLoyaltyProgramEditedSuccessfully:
      "ဤသစ္စာရှိမှုအစီအစဉ်ကိုအောင်မြင်စွာတည်းဖြတ်ပြီးပါပြီ",
    loyaltyProgramAddedSuccessfully:
      "သစ္စာရှိမှုအစီအစဉ်ကိုအောင်မြင်စွာထည့်ပြီးပါပြီ",
    loyaltyProgramEdited: "သစ္စာရှိမှုအစီအစဉ်ကိုတည်းဖြတ်ခဲ့သည်",
    loyaltyProgramAdded: "သစ္စာရှိမှုအစီအစဉ်ကိုထည့်သွင်းခဲ့သည်",
    loyaltyDetails: "သစ္စာရှိမှုအသေးစိတ်",
    doYouWantToCloseDialog: "ဒီဒိုင်ယာလော့ခ်ကိုပိတ်ချင်လား။",
    pleaseEnterLoyaltyName: "ကျေးဇူးပြု၍ သင်၏သစ္စာရှိမှုအမည်ကိုရိုက်ထည့်ပါ",
    programType: "အစီအစဉ်အမျိုးအစား",
    pleaseSelectProgramType: "ကျေးဇူးပြု၍ အစီအစဉ်အမျိုးအစားကိုရွေးပါ",
    simplePoints: "ရိုးရှင်းသောအချက်များ",
    stampsProgram: "တံဆိပ်ခေါင်းအစီအစဉ်",
    threshold: "တံခါးခုံ",
    pleaseEnterLoyaltyThreshold: "ကျေးဇူးပြု၍ သစ္စာရှိမှုအဆင့်ကိုထည့်ပါ",
    reward: "ဆုလာဘ်",
    pleaseEnterLoyaltyReward: "ကျေးဇူးပြု၍ သစ္စာရှိမှု၏ဆုလာဘ်ကိုထည့်ပါ",
    totalUserPoints: "စုစုပေါင်းအသုံးပြုသူရမှတ်",
    totalUserStamps: "စုစုပေါင်းအသုံးပြုသူတံဆိပ်ခေါင်းများ",
    spendingTarget: "အသုံးစရိတ်ပစ်မှတ်",
    spendingTargetHint1:
      "သုံးစွဲရန်ပစ်မှတ်သည်ဆုလာဘ်တစ်ခုရရှိရန်သုံးစွဲသူမည်မျှသုံးစွဲရမည်နည်း။ ၁ ငွေကြေးတန်ဖိုး = ၁ မှတ်",
    spendingTargetHint2:
      "တံဆိပ်ခေါင်းပစ်မှတ်သည်ဆုလာဘ်တစ်ခုရယူရန်ဖောက်သည်တစ် ဦး စုဆောင်းရမည့်တံဆိပ်ခေါင်းမည်မျှဖြစ်သည်။ ဥပမာ ၅",
    addNewLoyaltyProgram: "သစ္စာရှိမှုအစီအစဉ်အသစ်ထည့်ပါ",
    addLoyaltyProgram: "သစ္စာရှိမှုအစီအစဉ်ကိုထည့်ပါ",
    loyaltyProgramType: "သစ္စာရှိမှုအစီအစဉ်အမျိုးအစား",
    pleaseSelectLoyaltyProgramType:
      "ကျေးဇူးပြု၍ သစ္စာရှိမှုအစီအစဉ်အမျိုးအစားကိုရွေးပါ",
    nameOfProgram: "အစီအစဉ်အမည်",
    pleaseEnterProgramName: "ကျေးဇူးပြု၍ အစီအစဉ်အမည်ထည့်ပါ",
    whatIsTheReward: "ဆုလာဘ်ကဘာလဲ။",
    egNextPurchaseIsFree: "ဥပမာ နောက်ထပ် ၀ ယ်ယူမှုသည်အခမဲ့ဖြစ်သည်",
    customerName: "Customer နာမည်",
    guestCustomer: "ဧည့်သည်",
    orderConfirmedSuccessfully: "မှာယူမှုကိုအတည်ပြုပြီးပါပြီ",
    orderCancelledSuccessfully: "မှာယူမှုကိုအောင်မြင်စွာပယ်ဖျက်လိုက်သည်",
    confirmOrder: "အမိန့်ကိုအတည်ပြု",
    cancelOrder: "အော်ဒါဖျက်သိမ်းသည်",
    allOrders: "အော်ဒါများအားလုံး",
    totalOrders: "စုစုပေါင်းအော်ဒါ",
    doYouWantToAcceptOrder: "သင်ဤအမိန့်ကိုလက်ခံလိုပါသလား။",
    doYouWantToCancelOrder: "ဤအမိန့်ကိုသင်ပယ်ဖျက်လိုပါသလား။",
    orderDetails: "အော်ဒါအသေးစိတ်",
    orderCreatedAt: "အော်ဒါမှာပြီးပါပြီ",
    supplier: "ပေးသွင်းသည်",
    productName: "ထုတ်ကုန်အမည်",
    quantity: "ပမာဏ",
    unitPrice: "တစ်ခုချင်းစျေးနှုန်း",
    receivedBy: "မှလက်ခံရရှိသည်",
    reportFrom: "မှအစီရင်ခံသည်",
    totalSupplies: "စုစုပေါင်းထောက်ပံ့ရေးပစ္စည်းများ",
    allRightsReserved: "မူပိုင်ခွင့်များရယူပြီး",
    toDeleteThisTransaction: "ဤအရောင်းအ ၀ ယ်ကိုဖျက်ရန်",
    transactionDeletedSuccessfully:
      "အရောင်းအဝယ်ကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။ စတော့ရှယ်ယာများကိုစာရင်းသို့ပြန်အပ်ပြီးပါပြီ။",
    deleteTransaction: "ငွေသွင်းငွေထုတ်ဖျက်ပါ",
    transactionDetails: "ငွေလွှဲလုပ်ငန်းအသေးစိတ်",
    printReceipt: "လက်ခံဖြတ်ပိုင်း",
    channel: "လက်ပံ",
    discount: "လျှော့စျေး",
    profit: "အမြတ်အစွန်း",
    discountedSales: "လျှော့စျေးရောင်းချခြင်း",
    errorFetchingRecord: "မှတ်တမ်းရယူခြင်းအမှား",
    exportTransactions: "ပို့ကုန်အရောင်းအဝယ်များ",
    errorFetchingSalesRecord: "ပို့ကုန်အတွက်အရောင်းမှတ်တမ်းရယူရာတွင်အမှား",
    totalSellingPrice: "စုစုပေါင်းရောင်းဈေး",
    totalCostPrice: "စုစုပေါင်းကုန်ကျစရိတ်",
    appliedDiscount: "အသုံးချလျှော့စျေး",
    noOfItems: "ပစ္စည်းအရေအတွက်",
    sales: "အရောင်း",
    export: "တင်ပို့သည်",
    totalProfit: "စုစုပေါင်းအမြတ်",
    totalBalanceInPeriod: "ကာလ၌စုစုပေါင်းလက်ကျန်ငွေ",
    allTimeSales: "အချိန်တိုင်းအရောင်း",
    records: "မှတ်တမ်းများ",
    todaysSales: "ယနေ့အရောင်း",
    transaction: "အရောင်းအဝယ်",
    youHaveExceededTotalNumberOfProducts:
      "မင်းအစီအစဉ်မှာခွင့်ပြုထားတဲ့ထုတ်ကုန်အရေအတွက်စုစုပေါင်းကိုကျော်လွန်သွားပြီ။ အကန့်အသတ်ကိုခံစားရန်သင်၏အစီအစဉ်ကိုအဆင့်မြှင့်ပါ။",
    youNeedToHaveLoyaltyProgram:
      "ဤအင်္ဂါရပ်ကိုသုံးရန်သင်၌သစ္စာရှိမှုအစီအစဉ်တစ်ခုရှိရန်လိုသည်။",
    price: "စျေးနှုန်း",
    category: "အမျိုးအစား",
    stockTracking: "စတော့ခြေရာကောက်",
    stockCount: "စတော့အရေအတွက်",
    taxed: "အခွန်ကောက်တယ်",
    originalPrice: "မူရင်းဈေး",
    costPrice: "ကုန်ကျစရိတ်စျေးနှုန်း",
    unit: "ယူနစ်",
    productImage: "ကုန်ပစ္စည်းပုံရိပ်",
    taxRate: "အခွန်နှုန်း",
    taxType: "အခွန်အမျိုးအစား",
    trackInventory: "Inventory ကိုခြေရာခံပါ",
    variants: "မူကွဲများ",
    hasVariants: "မူကွဲများရှိသည်",
    importProduct: "ကုန်ပစ္စည်းတင်သွင်းပါ",
    exportProducts: "ထုတ်ကုန်များတင်ပို့ပါ",
    addNewProduct: "ကုန်ပစ္စည်းအသစ်ထည့်ပါ",
    viewCategory: "အမျိုးအစားကိုကြည့်ပါ",
    viewSuppliers: "ပေးသွင်းသူများကိုကြည့်ပါ",
    receiveInventory: "Inventory ရယူပါ",
    printAllProductsTag: "ကုန်ပစ္စည်းအားလုံးကို Tag ရိုက်နှိပ်ပါ",
    deleteAll: "အားလုံးဖျက်ပါ",
    totalProducts: "စုစုပေါင်းထုတ်ကုန်များ",
    youveSuccessfullyAddedANewCategory:
      "အမျိုးအစားအသစ်တစ်ခုကိုသင်အောင်မြင်စွာထည့်ပြီးပါပြီ",
    addNewCategory: "အမျိုးအစားအသစ်ထည့်ပါ",
    addCategory: "အမျိုးအစားထည့်ပါ",
    categoryName: "အမျိုးအစားအမည်",
    pleaseEnterCategoryName: "ကျေးဇူးပြု၍ အမျိုးအစားအမည်ထည့်ပါ",
    stampsTarget: "တံဆိပ်ခေါင်းများပစ်မှတ်",
    sendInventory: "Inventory ပို့ပါ",
    productDetails: "ကုန်ပစ္စည်းအသေးစိတ်",
    youveSuccessfullyEditedThisCategory:
      "သင်ဤကဏ္ category ကိုအောင်မြင်စွာတည်းဖြတ်ပြီးပါပြီ",
    update: "Update လုပ်ပါ",
    categoryList: "အမျိုးအစားစာရင်း",
    categories: "အမျိုးအစားများ",
    enterQuantityToUpdate: "အပ်ဒိတ်လုပ်ရန်ပမာဏထည့်ပါ",
    inventorySentSuccessfully: "စာရင်းကိုအောင်မြင်စွာပေးပို့ပြီးပါပြီ။",
    updateInventory: "Inventory ကို Update လုပ်ပါ",
    currentQuantity: "လက်ရှိအရေအတွက်",
    pleaseEnterQuantityToAdd: "ကျေးဇူးပြု၍ သင်ထည့်လိုသောပမာဏကိုထည့်ပါ",
    pleaseSelectABranch: "ကျေးဇူးပြု၍ ဘဏ်ခွဲတစ်ခုကိုရွေးပါ",
    searchForBranch: "ဌာနခွဲကိုရှာပါ",
    youCantSendMoreThanStock: "မင်းမှာရှိတာထက်ပိုမပို့နိုင်ဘူး",
    send: "ပို့ပါ",
    pleaseEnterQuantityToSend: "ကျေးဇူးပြု၍ သင်ပို့လိုသောပမာဏကိုရိုက်ထည့်ပါ",
    productNameIsRequiredOnRow: "အတန်းတွင်ကုန်ပစ္စည်းအမည်လိုအပ်သည်",
    productCategoryIsRequiredOnRow: "အတန်းတွင်ကုန်ပစ္စည်းအမျိုးအစားလိုအပ်သည်",
    productPriceIsRequiredOnRow: "အတန်းတွင်ကုန်ပစ္စည်းစျေးနှုန်းလိုအပ်သည်",
    productUnitIsRequiredOnRow: "အတန်းတွင်ထုတ်ကုန်ယူနစ်လိုအပ်သည်",
    productQuantityIsRequiredOnRow: "အတန်းတွင်ထုတ်ကုန်အရေအတွက်လိုအပ်သည်",
    productVariantsRequireTracking:
      "ထုတ်ကုန်မျိုးကွဲများသည်ခြေရာခံရန်လိုအပ်သည်။",
    pleaseAddVariantClickButton:
      "ကျေးဇူးပြု၍ add ခလုတ်ကိုနှိပ်ခြင်းဖြင့်မူကွဲတစ်ခုထည့်ပါ။",
    totalVariantsMoreThanSelectedQuantity:
      "Variants များသည်ရွေးချယ်ထားသောထုတ်ကုန်အရေအတွက်ထက်ပိုများသည်၊ Pls မူကွဲအရေအတွက်ကိုလျှော့ချသည်",
    productEditedSuccessfully: "ထုတ်ကုန်ကိုအောင်မြင်စွာတည်းဖြတ်ပြီးပါပြီ။",
    fileTooLargeLessThan4Mb:
      "ဖိုင်အရွယ်အစားကြီးလွန်းသည်။ ဖိုင်အရွယ်အစားသည် 4MB ထက်နည်းရမည်။",
    suchVariantAlreadyExist: "ထိုကဲ့သို့သောမူကွဲရှိပြီးသားဖြစ်သည်",
    addVariants: "Variants များထည့်ပါ",
    editProduct: "ကုန်ပစ္စည်းကိုတည်းဖြတ်ပါ",
    pleaseEnterProductName: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းအမည်ထည့်ပါ",
    pleaseEnterProductPrice: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းဈေးနှုန်းထည့်ပါ",
    pleaseEnterProductOriginalPrice:
      "ကျေးဇူးပြု၍ ကုန်ပစ္စည်း၏မူရင်းစျေးနှုန်းကိုထည့်ပါ",
    productDescription: "ကုန်ပစ္စည်းအကြောင်းအရာ",
    selectProductCategory: "ကုန်ပစ္စည်းအမျိုးအစားရွေးပါ",
    pleaseSelectProductCategory: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းအမျိုးအစားကိုရွေးပါ",
    productCostPrice: "ကုန်ပစ္စည်းကုန်ကျစရိတ်စျေးနှုန်း",
    productSellingPrice: "ကုန်ပစ္စည်းရောင်းဈေး",
    productOriginalPrice: "ကုန်ပစ္စည်း၏မူရင်းစျေးနှုန်း",
    maxFileSizeAllowedIs4Mb:
      "မက်စ်။ ခွင့်ပြုထားသောဖိုင်အရွယ်အစားမှာ 4mb ဖြစ်သည်",
    productsWithPicturesArePublished:
      "အော်ဒါလက်ခံရန်ရုပ်ပုံများပါသောထုတ်ကုန်များကို Discover တွင်ပုံနှိပ်ထုတ်ဝေသည်",
    shouldThisProductBeTracked: "ဤထုတ်ကုန်ကိုခြေရာခံသင့်သလား။",
    pleaseSelectStockUnit: "ကျေးဇူးပြု၍ စတော့ယူနစ်ကိုရွေးပါ",
    stockUnit: "စတော့ယူနစ်",
    bag: "အိတ်",
    bottle: "ပုလင်း",
    bunch: "BUNCH",
    can: "နိုင်သလား",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "ဇင်",
    gigabytes: "GIGABYTES",
    gram: "ကျက်သရေ",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "အထုပ်",
    pair: "လက်တွဲပါ",
    pieces: "PIECES",
    plate: "ပန်းကန်ပြား",
    tonne: "TONNE (MT)",
    uNIT: "ယူနစ်",
    yard: "ခြံဝင်း",
    pleaseEnterProductQuantity: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းအရေအတွက်ထည့်ပါ",
    productQuantity: "ကုန်ပစ္စည်းအရေအတွက်",
    isThisProductTaxed: "ဤထုတ်ကုန်သည်အခွန်ရှိပါသလား။",
    selectTaxType: "အခွန်အမျိုးအစားကိုရွေးချယ်ပါ",
    pleaseSelectTaxType: "ကျေးဇူးပြု၍ အခွန်အမျိုးအစားကိုရွေးပါ",
    progressive: "တိုးတက်သော",
    proportional: "အချိုးကျ",
    pleaseEnterProductTaxRate: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းခွန်နှုန်းကိုထည့်ပါ",
    doesProductHaveVariants: "ဒီထုတ်ကုန်မှာမူကွဲရှိပါသလား။",
    type: "ရိုက်ပါ",
    value: "တန်ဖိုး",
    pleaseEnterVariantType: "မူကွဲအမျိုးအစားကိုရိုက်ထည့်ပါ",
    pleaseEnterVariantValue: "မူကွဲ၏တန်ဖိုးကိုထည့်ပါ",
    pleaseEnterVariantPrice: "မူကွဲ၏စျေးနှုန်းထည့်ပါ",
    pleaseEnterVariantQuantity: "မူကွဲပမာဏကို ကျေးဇူးပြု၍ ထည့်ပါ",
    productDeletedSuccessfully: "ထုတ်ကုန်ကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။",
    categoryDeletedSuccessfully: "အမျိုးအစားကိုအောင်မြင်စွာဖျက်လိုက်ပါပြီ။",
    toDeleteThisProduct: "ဤထုတ်ကုန်အားဖျက်ရန်",
    invalidProductQuantity: "ကုန်ပစ္စည်းအရေအတွက်မမှန်ကန်ပါ",
    quantityAddedIsOverStock: "မင်းထည့်လိုက်တဲ့ပမာဏကမင်းမှာရှိတာထက်ပိုပါတယ်။",
    itemInventoryNotTracked: "ပစ္စည်းစာရင်းကိုခြေရာခံမထားပါ",
    addBulkQuantity: "အစုလိုက်ပမာဏထည့်ပါ",
    enterBulkQuantity: "Bulk Quantity ကိုရိုက်ထည့်ပါ",
    pleaseEnterBulkQuantity: "ကျေးဇူးပြု၍ အစုလိုက်ထည့်ပါ",
    youveSuccessfullyAddedANewProduct:
      "ထုတ်ကုန်အသစ်တစ်ခုကိုသင်အောင်မြင်စွာထည့်ပြီးပါပြီ။",
    pleaseEnterProductSellingPrice: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းရောင်းဈေးကိုထည့်ပါ",
    doYouWantToTrackProductStock: "သင်ကုန်ပစ္စည်းစတော့ကိုခြေရာခံလိုသလား။",
    sellingPrice: "ရောင်းဈေး",
    setProductExpiryReminder:
      "ကုန်ပစ္စည်းသက်တမ်းကုန်ဆုံးမှုသတိပေးချက်သတ်မှတ်ပါ",
    productExpiryDate: "ကုန်ပစ္စည်းသက်တမ်းကုန်ဆုံးရက်",
    startRemindingFrom: "ကနေစပြီးသတိပေးပါ",
    productSuppliesAddedSuccessfully:
      "သင်ကုန်ပစ္စည်းအထောက်အပံ့များကိုအောင်မြင်စွာထည့်ပြီးပါပြီ။",
    addProductSupplies: "ကုန်ပစ္စည်းအထောက်အပံ့များထည့်ပါ",
    pleaseSelectSupplier: "ကျေးဇူးပြု၍ ပေးသွင်းသူကိုရွေးချယ်ပါ",
    nameOfProduct: "ကုန်ပစ္စည်းအမည်",
    pleaseSelectProduct: "ကျေးဇူးပြု၍ ကုန်ပစ္စည်းရွေးပါ",
    productVariant: "ကုန်ပစ္စည်းမူကွဲ",
    pleaseSelectAVariant: "ကျေးဇူးပြု၍ မူကွဲတစ်ခုကိုရွေးပါ",
    pleaseEnterUnitPrice: "ကျေးဇူးပြု၍ ယူနစ်စျေးနှုန်းထည့်ပါ",
    businessBranch: "စီးပွားရေးဌာနခွဲ",
    pleaseSelectBranch: "ကျေးဇူးပြု၍ ဌာနခွဲရွေးပါ",
    youveSuccessfullyAddedANewSupplier:
      "သင်ပေးသွင်းသူအသစ်ကိုအောင်မြင်စွာထည့်ပြီးပါပြီ",
    addSupplier: "Supplier ထည့်ပါ",
    pleaseEnterSupplierEmail: "ကျေးဇူးပြု၍ ပေးသွင်းသူ၏အီးမေးလ်ကိုထည့်ပါ",
    pleaseAddADescription: "ကျေးဇူးပြု၍ ဖော်ပြချက်ထည့်ပါ",
    anErrorOccuredProductsDeleted:
      "လုပ်ငန်းလည်ပတ်နေစဉ်အမှားတစ်ခုဖြစ်ပွားခဲ့သည်။ ကျေးဇူးပြု၍ သတိပြုပါ၊ လုပ်ငန်းစဉ်အနည်းငယ်၌ထုတ်ကုန်များဖျက်လိုက်ခြင်းဖြစ်နိုင်သည်",
    bulkDelete: "အစုလိုက်ဖျက်ပါ",
    youAreAboutToDelete: "သင်ဖျက်တော့မည်",
    product: "ကုန်ပစ္စည်း",
    isDueToRedeem: "ရွေးနှုတ်ခြင်းကြောင့်ဖြစ်သည်",
    aReward: "ဆုလာဘ်တစ်ခု",
    pleaseSelectCustomerToReeemReward:
      "ကျေးဇူးပြု၍ ဆုထုတ်ယူရန် ၀ ယ်ယူသူတစ် ဦး ကိုရွေးချယ်ပါ။",
    youHaveNoLoyaltyProgramRunning: "မင်းမှာတက်ကြွတဲ့သစ္စာရှိမှုအစီအစဉ်မရှိဘူး",
    customerHhasNoPointsInLoyaltyProgram:
      "ဤဖောက်သည်အစီအစဉ်တွင် ၀ ယ်သူ၌အမှတ်မရှိပါ",
    proceedWithPayment: "ငွေပေးချေမှုကိုဆက်လုပ်မလား။",
    youAreAboutToPayForTransactionUsingPoints:
      "သင်အမှတ်များ သုံး၍ အရောင်းအဝယ်အတွက်ငွေပေးချေတော့မည်။",
    customerHas: "ဖောက်သည်ရှိသည်",
    worth: "တန်ပါတယ်",
    andIsNotSufficientToPayForTransaction:
      "ဤငွေပေးချေမှုအတွက်ငွေပေးချေရန်မလုံလောက်ပါ။ သူတို့ကအခြားငွေပေးချေမှုနည်းလမ်းတစ်ခုသုံးပြီးလက်ကျန်ငွေကိုထပ်ထည့်ချင်လား။",
    addCustomerLoyalty: "Customer Loyalty ကိုထည့်ပါ",
    pleaseAddCustomerFirst:
      "ကျေးဇူးပြု၍ ၀ ယ်သူကိုထည့်ပါ (သို့) ရှေး ဦး စွာရွေးချယ်ပါ။",
    pleaseWaitWhileWeGetReady: "ကျေးဇူးပြုပြီးခဏစောင့်ပါ",
    lowStock: "Stock နည်းသည်",
    pleaseEnterAmountTendered: "တင်ဒါပမာဏထည့်ပါ",
    areYouSureToBookSaleOffline:
      "သင်ဤအရောင်းအ ၀ ယ်ကိုကြိုတင်မှာယူလိုသည်မှာသေချာသလား။",
    saleWouldBeBookedAutomatically:
      "မင်းအင်တာနက်ဖွင့်လိုက်တာနဲ့အလိုအလျောက်ဘွတ်ကင်ရောင်းလိမ့်မယ်",
    offlineSalesBookingCancelled:
      "အော့ဖ်လိုင်းအရောင်းဘွတ်ကင်ကိုပယ်ဖျက်လိုက်သည်",
    covid19Message:
      "COVID -19 ကူးစက်မှုကိုရပ်တန့်ရန်သင်၏လက်ကိုဆပ်ပြာနှင့်ဆေးပါ။ အမြဲလုံခြုံနေပါ",
    saleSuccessfullyRecorded: "ရောင်းပွဲအောင်မြင်စွာမှတ်တမ်းတင်နိုင်ပါပြီ။",
    sendReceiptToEmail: "ပြေစာကို Email သို့ပို့ပါ",
    sendThankYouSms: "ကျေးဇူးတင်ကြောင်း SMS ပို့ပါ",
    sendShippingDetails: "ပို့ဆောင်ရေးအသေးစိတ်ကိုပို့ပါ",
    addLoyalty: "သစ္စာရှိမှုထည့်ပါ",
    searchCustomerNameOrNumber: "၀ ယ်သူအမည် (သို့) နံပါတ်ကိုရှာပါ",
    clickTheSearchCustomerBox: "Search Customer Box နှင့် Scan Card ကိုနှိပ်ပါ",
    enterProductPrice: "ကုန်ပစ္စည်းစျေးနှုန်းထည့်ပါ",
    enterPriceFor: "အတွက်စျေးနှုန်းထည့်ပါ",
    searchForProduct: "ထုတ်ကုန်ကိုရှာပါ",
    all: "အားလုံး",
    backToDashboard: "Dashboard သို့ပြန်သွားရန်",
    viewDraftSales: "ရောင်းအားကိုကြည့်ပါ",
    variantSelected: "မူကွဲရွေးထားသည်",
    variant: "မူကွဲ",
    thePreviousVariantSelectionNotAvailable:
      "စျေးနှုန်းပေါ်မူတည်ပြီးရွေးချယ်ထားသောမူကွဲအသစ်အတွက်ယခင်မူကွဲရွေးချယ်မှုကိုမရနိုင်ပါ၊ ကျေးဇူးပြု၍ သင်၏ရွေးချယ်မှုကိုပြောင်းပါ။",
    pleaseSupplyPositiveQuantityNumber:
      "ကျေးဇူးပြုပြီးပို့တဲ့အရေအတွက်ကိုကျေးဇူးပြုပြီးပေးပါ",
    lowStockFor: "များအတွက် stock အနိမ့်",
    clearVariants: "မူကွဲများကိုရှင်းပါ",
    pleaseEnterQuantity: "ကျေးဇူးပြု၍ အရေအတွက်ထည့်ပါ",
    picture: "ရုပ်ပုံလွှာ",
    redemptionToken: "ရွေးနှုတ်ခြင်းသင်္ကေတ",
    token: "တိုကင်",
    totalSpent: "စုစုပေါင်းသုံးစွဲခဲ့သည်",
    confirmPayment: "ငွေပေးချေမှုကိုအတည်ပြုပါ",
    hasPaymentBeenMade: "ငွေပေးချေမှုကိုအောင်မြင်စွာစီမံပြီးပြီလား။",
    enterTransactionReference:
      "သင်ပေးချေထားသောငွေလွှဲလုပ်ငန်းရည်ညွှန်းချက်ကိုထည့်ပါ",
    pleaseSelectACustomer: "ကျေးဇူးပြုပြီး ၀ ယ်သူကိုရွေးပါ။",
    areYouSureToApplyDiscount: "လျှော့စျေးလျှောက်ထားလိုသည်မှာသေချာပါသလား။",
    addYourBankAccountToEnableUssd:
      "uPay ဖြင့် Instant USSD လွှဲပြောင်းရန်သင်၏ဘဏ်စာရင်းကိုထည့်ပါ",
    totalAmountToPay: "ပေးချေရမည့်စုစုပေါင်းပမာဏ",
    doYouWantToCancelTransaction: "ဤအရောင်းအ ၀ ယ်ကိုသင်ဖျက်လိုပါသလား။",
    savePrintBill: "Bill သိမ်းပါ",
    enterAmountCollectedForCustomer: "၀ ယ်သူအတွက်စုဆောင်းထားသောပမာဏကိုထည့်ပါ",
    recordSale: "စံချိန်တင်ရောင်းချခြင်း",
    checkOutWith: "အတူထွက်ကြည့်ပါ",
    instantTransfer: "ချက်ချင်းငွေလွှဲ",
    dialTheUSSDCode: "USSD ကုဒ်ကိုခေါ်ပါ",
    pleaseSelectABank: "ကျေးဇူးပြု၍ ဘဏ်တစ်ခုကိုရွေးပါ",
    payWithUSSD: "USSD ဖြင့်ငွေပေးချေပါ",
    sendBillTo: " - ဘီလ်ကိုပို့ပါ",
    waitingForUSSDTransfer: "USSD လွှဲပြောင်းမှုကိုစောင့်နေသည်",
    percent: "ရာခိုင်နှုန်း",
    applyDiscount: "လျှော့စျေးလျှောက်ထားပါ",
    thisBillHasBeenSaved: "ဤဥပဒေကြမ်းကိုသိမ်းဆည်းပြီးပါပြီ",
    saveDraft: "မူကြမ်းကိုသိမ်းပါ",
    pleaseTypeANameToIdentifyCustomer:
      "ကျေးဇူးပြု၍ ၀ ယ်သူကိုဖော်ထုတ်ရန်နာမည်တစ်ခုရိုက်ပါ",
    paymentDetails: "ငွေပေးချေမှုအသေးစိတ်",
    basePrice: "အခြေခံစျေးနှုန်း",
    staff: "ဝန်ထမ်း",
    subTotal: "စုစုပေါင်းခွဲ",
    durationMonths: "သင်တန်းကာလ (လ)",
    selectADuration: "Duration တစ်ခုရွေးပါ",
    oneMonth: "၁ လ",
    twoMonths: "2 လ",
    threeMonths: "3 လ",
    sixMonths: "6 လ",
    twelveMonths: "၁၂ လ",
    eighteenMonths: "၁၈ လ",
    twentyFourMonths: "၂၄ လ",
    twoMonthsFree: "(၂ လအခမဲ့)",
    threeMonthsFree: "(၃ လအခမဲ့)",
    fiveMonthsFree: "(၅ လအခမဲ့)",
    yourAccountHasBeen: "မင်းရဲ့အကောင့်ဖြစ်သွားပြီ",
    renewed: "အသစ်တဖန်",
    upgraded: "အဆင့်မြှင့်တင်ခဲ့သည်",
    successfully: "အောင်မြင်စွာ",
    yourSubscription: "သင်၏စာရင်းပေးသွင်းမှု",
    youAreCurrentlyEnrolledOnA: "သင်လက်ရှိတွင်စာရင်းသွင်းသည်",
    pleaseChooseAPaymentOption:
      "ကျေးဇူးပြု၍ ငွေပေးချေမှုနည်းလမ်းတစ်ခုကိုရွေးပါ",
    planRenewal: "သက်တမ်းတိုးအစီအစဉ်",
    planUpgrade: "အဆင့်မြှင့်တင်မှုအစီအစဉ်",
    pleaseSelectDurationToPayFor:
      "ကျေးဇူးပြု၍ သင်ပေးချေလိုသောကြာချိန်ကိုရွေးပါ",
    staffAccounts: "ဝန်ထမ်းစာရင်းများ",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "ကျေးဇူးပြု၍ အစီအစဉ်တစ်ခုကိုရွေးပါ",
    includeAddons: "အပိုပရိုဂရမ်များထည့်သွင်းပါ",
    viewTransactions: "ငွေသွင်းငွေထုတ်များကိုကြည့်ပါ",
    customerHasNoCompletedTansactions: "၀ ယ်သူသည် tansactions မပြီးမြောက်သေးပါ",
    branch: "ဌာနခွဲ",
    enterNumberOfEcommerceBranches: "Ecommerce ဘဏ်ခွဲအရေအတွက်ကိုထည့်ပါ",
    enterNumberOfEcommerceBranchesToPay:
      "သင်ပေးချေလိုသော Ecommerce ဌာနခွဲအရေအတွက်ကိုထည့်ပါ",
    ecommerceIntegration: "Ecommerce ပေါင်းစည်းမှု",
    enterNumberOfBranches: "ဌာနခွဲအရေအတွက်ထည့်ပါ",
    enterNumberOfAdditionalBranchesToPay:
      "သင်ပေးချေရန်ရည်ရွယ်ထားသောနောက်ထပ်ဘဏ်ခွဲများအရေအတွက်ကိုထည့်ပါ",
    enterNumberOfStaffs: "၀ န်ထမ်းအရေအတွက်ထည့်ပါ",
    enterNumberOfStaffsToPayFor: "သင်ပေးချေလိုသော ၀ န်ထမ်းအရေအတွက်ကိုထည့်ပါ",
    discountApplies: "လျှော့စျေးတွေပေါ့",
    starsOnThe: "ပေါ်ကကြယ်လေးတွေ",
    offer: "ကမ်းလှမ်း",
    get: "ရယူပါ",
    moreStarsToRedeem: "နောက်ထပ်ရွေးရန်ကြယ်များ",
    taxVat: "အခွန် (VAT)",
    callCashierToCompletePayment: "ငွေဖြည့်ရန်ငွေကိုင်ကိုခေါ်ပါ",
    receipt: "ပြေစာ",
    dear: "မြတ်နိုးသည်",
    thankYouForYourOrderFindGoods:
      "မှာယူမှုအတွက်ကျေးဇူးတင်ပါတယ်။ သဘောတူထားတဲ့အတိုင်းအောက်ပါကုန်စည်ကို ကျေးဇူးပြု၍ ရှာပါ။",
    shippingNote: "ပို့ဆောင်ရေးမှတ်ချက်",
    enterShippingNote: "ပို့ဆောင်ရေးမှတ်ချက်ထည့်ပါ",
    shippingAddress: "ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ",
    enterShippingAddress: "ပို့ဆောင်ရေးလိပ်စာထည့်ပါ",
    wellDoneYouAreDueToRedeem:
      "ကောင်းစွာပြီးဆုံးသည်! သင်ရွေးနှုတ်ခြင်းကြောင့်ဖြစ်သည်",
    toGetYourRewardNextVisit:
      "သင်၏နောက်ခရီးစဉ်တွင်သင်၏ဆုလာဘ်ကိုရယူရန် ကျေးဇူးတင်ပါတယ်",
    pointsOnThe: "အမှတ်များပေါ်တွင်",
    morePointsToRedeem: "နောက်ထပ်အမှတ်များရယူရန်",
    showCode: "ကုဒ်ပြပါ",
    toGetYourRewardOnNextVisit:
      "သင်၏နောက်ခရီးစဉ်တွင်သင်၏ဆုလာဘ်ကိုရယူရန် ကျေးဇူးတင်ပါတယ်",
    earn: "ဝင်ငွေ",
    delivaryNote: "Delivary မှတ်ချက်",
    draftSales: "မူကြမ်းအရောင်း",
    startDate: "စတင်သည့်ရက်စွဲ",
    endDate: "ရက်စွဲ",
    orders: "အမိန့်",
    checkout: "ထွက်ခွာသည်",
    noProductItem: "ကုန်ပစ္စည်းအကြောင်းအရာမရှိပါ",
    selectProductImage: "ကုန်ပစ္စည်းပုံကိုရွေးပါ",
    selectCountry: "နိုင်ငံကိုရွေးချယ်ပါ",
    selectRegion: "ပြည်နယ်/တိုင်းဒေသကြီးရွေးပါ",
    printProductTag: "ကုန်ပစ္စည်းတံဆိပ်ရိုက်နှိပ်ပါ",
    transactionReference: "အရောင်းအဝယ်ကိုးကား",
    Cashier: "ငွေကိုင်",
    Manager: "မန်နေဂျာ",
    Owner: "ပိုင်ရှင်",
    Admin: "အက်မင်",
    addPartners: "ပါတနာများထည့်ပါ။",
    addNewLoyaltyPartner: "သစ္စာစောင့်သိသော ပါတနာအသစ်ကို ထည့်ပါ။",
    pleaseEnterCompanyName: "ကုမ္ပဏီအမည်ကို ထည့်သွင်းပါ။",
    companyName: "ကုမ္ပဏီအမည်",
    pleaseEnterCompanyRepName:
      "ကုမ္ပဏီ ကိုယ်စားလှယ်အမည် ကျေးဇူးပြု၍ ထည့်သွင်းပါ။",
    companyRepName: "ကုမ္ပဏီကိုယ်စားလှယ်အမည်",
    pleaseEnterCompanyRepEmail:
      "ကုမ္ပဏီကိုယ်စားလှယ်၏ အီးမေးလ်ကို ကျေးဇူးပြု၍ ထည့်သွင်းပါ။",
    companyRepEmail: "ကုမ္ပဏီကိုယ်စားလှယ်၏အီးမေးလ်",
    pleaseEnterCompanyRepPhone:
      "ကုမ္ပဏီကိုယ်စားလှယ်၏ ဖုန်းနံပါတ်ကို ထည့်သွင်းပါ။",
    companyRepPhone: "ကုမ္ပဏီကိုယ်စားလှယ်ဖုန်းနံပါတ်",
    addReward: "ဆုကြေးငွေထည့်ပါ။",
    pleaseEnterRewardName: "ကျေးဇူးပြု၍ ဆုကြေးအမည်ထည့်ပါ။",
    rewardName: "ဆုအမည်",
    rewardQuantity: "ဆုကြေးပမာဏ",
    rewardDescription: "ဆုကြေးငွေဖော်ပြချက်",
    rewardType: "ဆုအမျိုးအစား",
    pleaseEnterRewardType: "ကျေးဇူးပြု၍ ဆုအမျိုးအစားထည့်ပါ။",
    pleaseEnterRewardQuantity: "ကျေးဇူးပြု၍ ဆုငွေပမာဏကို ထည့်ပါ။",
    pleaseEnterRewardDescription: "ကျေးဇူးပြု၍ ဆုငွေဖော်ပြချက်ကို ထည့်သွင်းပါ။",
    fineDining: "Fine Dining",
    luxuryFashion: "ဇိမ်ခံဖက်ရှင်",
    hotels: "ဟိုတယ်များ",
    travel: "ခရီးသွား",
    foodAndBeverage: "အစားအသောက်နှင့် အဖျော်ယမကာ",
    fashion: "ဖက်ရှင်",
    health: "ကျန်းမာရေး",
    furniture: "ပရိဘောဂ",
    entertainment: "ဖျော်ဖြေရေး",
    automobile: "မော်တော်ကား",
    education: "ပညာရေး",
    beautyAndSpa: "အလှအပနှင့် Spa",
    staycation: "တည်းခိုနေထိုင်ခြင်း။",
    events: "အဲ့ဒါနဲ့",
    trips: "ခရီးများ",
    oilAndGas: "ရေနံနှင့် သဘာဝဓာတ်ငွေ့",
    laundry: "အဝတ်တွေ",
    partnerCategory: "ပါတနာ အမျိုးအစား",
    freeItem: "အခမဲ့ပစ္စည်း",
  },
  Maltese: {
    cashier: "kaxxier",
    manager: "maniġer",
    owner: "sid",
    online: "online",
    offline: "offline",
    changePassword: "Biddel il-password",
    currentPasswordMessage: "Jekk jogħġbok daħħal il-password attwali tiegħek",
    passwordMessage: "Jekk jogħġbok daħħal il-password tiegħek",
    currentPassword: "Password kurrenti",
    password: "password",
    confirmPasswordMessage: "Jekk jogħġbok ikkonferma l-password tiegħek!",
    confirmPassword: "Ikkonferma il-Password",
    sendViaEmail: "Ibgħat Via Email",
    sendViaWhatsapp: "Ibgħat Via WhatsApp",
    downloadPdf: "Niżżel il-PDF",
    paid: "imħallas",
    unpaid: "mhux imħallas",
    partial: "parzjali",
    closeInvoice: "Trid tagħlaq il-Fattura?",
    closeInvoiceConfirmation: "Fattura tista 'ma tiġix salvata. Trid tagħlaq?",
    yes: "iva",
    no: "le",
    invoice: "Fattura",
    wasDeducted: "ġie mnaqqas",
    for: "għal",
    item: "Punt",
    addProduct: "Żid Prodott",
    paymentReference: "Referenza għall-Ħlas",
    amountPaid: "Ammont Imħallas",
    discountAmount: "Ammont ta 'Skont",
    amountDue: "Ammont dovut",
    amount: "Ammont",
    dueDate: "Data ta 'Skadenza",
    paymentType: "Tip ta 'Ħlas",
    card: "Kard",
    cash: "Flus kontanti",
    bankTransfer: "Trasferiment Bankarju",
    paymentMessage: "Messaġġ ta 'Ħlas",
    description: "Deskrizzjoni",
    sendReceipt: "Ibgħat Irċevuta",
    delete: "Ħassar",
    save: "Ħlief",
    resend: "Ibgħat mill-ġdid",
    saveAndSend: "Ħlief",
    invoiceSaved: "Fattura salvata!",
    selectPaymentMethod: "Jekk jogħġbok agħżel Metodu ta 'Ħlas!",
    selectCustomer: "Jekk jogħġbok agħżel Klijent!",
    cartEmptyError:
      "Il-Lista tal-Karti ma tistax tkun vojta, għalaq il-fattura u żid l-Oġġett fil-karrettun!",
    subscriptionExpired:
      "L-abbonament tiegħek skada u l-kont tiegħek issa huwa limitat. Ikklikkja fuq il-Buttuna t'hawn taħt biex iġġedded il-kont tiegħek",
    renew: "Ġedded",
    holdOn: "Zomm",
    customerBank: "Bank tal-Klijent",
    cancel: "Ikkanċella",
    selectACustomer: "Agħżel Klijent",
    invoiceSuccessfulPdfError:
      "Fattura maħluqa b'suċċess iżda l-Ġenerazzjoni PDF qed tieħu aktar mis-soltu. Jekk jogħġbok iċċekkja lura dalwaqt.",
    downloadingInvoice: "Tniżżil tal-Fattura",
    downloadingReceipt: "Tniżżil tal-Irċevuta",
    whatsappReceiptError:
      "Ġie żball li bagħtet l-irċevuta permezz ta 'WhatsApp, Jekk jogħġbok erġa' pprova.",
    receiptToWhatsapp: "Irċevuta mibgħuta lil WhatsApp",
    thankYouForPatronage: "Grazzi tal-patroċinju tiegħek",
    hereIsYourReceipt: "Hawn hi l-irċevuta tal-ħlas tiegħek",
    errorSendingEmailReceipt:
      "Ġie żball li bagħtet l-irċevuta permezz tal-posta elettronika, jekk jogħġbok erġa 'pprova jew ikkuntattja lis-sapport",
    receiptSentToEmail: "Irċevuta ntbagħtet lill-email tal-klijent",
    invoiceSentToEmail: "Fattura ntbagħtet lill-email tal-klijent",
    invoiceSuccessWhatsappError:
      "Fattura maħluqa b'suċċess imma seħħ żball li bagħat lil WhatsApp. Erġa 'pprova fuq il-lista tal-fatturi",
    invoiceSuccessfulEmailError:
      "Fattura maħluqa b'suċċess iżda seħħ żball waqt li bagħtet bħala e-mail. Jekk jogħġbok erġa 'pprova mil-lista tal-fatturi",
    invoiceSentToWhatsapp: "Fattura mibgħuta lil WhatsApp",
    hello: "Bongu",
    pleaseDownloadInvoice: "Jekk jogħġbok niżżel il-fattura",
    pleaseDownloadReceipt: "Jekk jogħġbok niżżel l-irċevuta",
    from: "minn",
    email: "Email",
    upgrade: "Aġġorna",
    youAreOnFreePlan: "Int qiegħed fuq Pjan Ħieles.",
    clickOn: "Ikklikkja fuq",
    yourPlanInFewSteps: " l-abbonament tiegħek fi ftit passi mgħaġġla.",
    to: "għal",
    yourSubscriptionHasExpired:
      "L-abbonament tiegħek skada u l-kont tiegħek issa huwa limitat.",
    days: "jiem",
    yourSubscriptionExpiresIn: "L-abbonament Loystar tiegħek jiskadi fi",
    createAcount: "Oħloq Kont",
    signIn: "Iffirma",
    continue: "Kompli",
    enterOtp: "Daħħal OTP PIN",
    enterValidOtp: "Jekk jogħġbok daħħal PIN validu",
    pin: "PIN",
    tokenSentToMail: "Ġiet mibgħuta token lill-email tiegħek",
    passwordResetSuccessful: "Irrisettja l-password irnexxa",
    somethingWentWrong: "Xi ħaġa marret ħażin!",
    resetPassword: "Irrisettja l-Password",
    iHaveResetCode: "Għandi kodiċi ta 'reset tal-password",
    pleaseEnterEmail: "Jekk jogħġbok daħħal l-email tiegħek",
    aTokenWillBeSentToEmail: "Token jintbagħat lill-email tiegħek",
    enterEmail: "Daħħal l-email tiegħek",
    sendinYourToken: "Jibgħat it-token tiegħek ...",
    makeSureItMatchesPassword:
      "Kun żgur li taqbel mal-password il-ġdida tiegħek",
    pleaseChooseNewPassword: "Jekk jogħġbok agħżel password ġdida",
    chooseNewPassword: "Agħżel password ġdida",
    enterNewPassword: "Daħħal il-password il-ġdida tiegħek biex tikkonferma",
    enterTokenSent: "Daħħal it-token li ntbagħat il-posta tiegħek",
    resetToken: "Irrisettja t-Token",
    resettingPassword: "Irrisettja l-Password tiegħek ...",
    signUp: "Irregistra",
    adminSignInWithEmail:
      " L-Amministratur jiffirma bl-email waqt li l-Persunal jiffirma bl-username.",
    pleaseEnterEmailOrUsername:
      "Jekk jogħġbok daħħal l-email jew l-username tiegħek",
    emailOrUsername: "Email jew Username",
    pleaseEnterPassword: "Jekk jogħġbok daħħal il-password",
    createAnAccount: "Oħloq Kont",
    forgotPassword: "Insejt il-password?",
    enterPhoneNumber: "Daħħal in-Numru tat-Telefon",
    personalData: "Dejta Personali",
    validateConfirmationCOde: "Ivvalida l-Kodiċi tal-Konferma",
    pleaseEnterFirstName: "Jekk jogħġbok daħħal l-ewwel isem tiegħek",
    pleaseEnterPhoneNumber: "Jekk jogħġbok daħħal in-numru tat-telefon tiegħek",
    pleaseEnterLastName: "Jekk jogħġbok daħħal l-kunjom tiegħek",
    pleaseEnterBusinessName: "Jekk jogħġbok daħħal l-isem tan-negozju tiegħek",
    firstName: "Isem",
    lastName: "Kunjom",
    businessName: "Isem tan-Negozju",
    previous: "Preċedenti",
    next: "Li jmiss",
    pleaseSelectBusinessCategory:
      "Jekk jogħġbok agħżel il-kategorija tan-negozju tiegħek",
    pleaseEnterValidEmail: "Jekk jogħġbok daħħal email valida",
    pleaseEnterPostCode: "Jekk jogħġbok daħħal il-kodiċi postali",
    postCode: "Kodiċi Postali",
    phoneNumberInUse: "Dan in-numru tat-telefon diġà qed jintuża!",
    emailInUse: "Din l-email diġà qed tintuża!",
    foodAndDrinks: "Ikel u Xorb",
    salonAndBeauty: "Salon u Beauty",
    fashionAndAccessories: "Moda u Aċċessorji",
    gymAndFitness: "Gym u Fitness",
    travelAndHotel: "Ivvjaġġar u Lukanda",
    homeAndGifts: "Dar u Rigali",
    washingAndCleaning: "Ħasil u Tindif",
    gadgetsAndElectronics: "Aġġeġġi u Elettronika",
    groceries: "Ikel",
    others: "Oħrajn",
    submit: "Issottometti",
    accountCreatedSuccessful: "Il-kont tiegħek inħoloq b'suċċess.",
    pleaseEnterAddress: "Jekk jogħġbok Daħħal l-Indirizz Tiegħek",
    addressLine1: "Indirizza Linja 1",
    addressLine2: "Indirizza Linja 2",
    choosePassword: "Agħżel Password",
    passwordMustBe6Characters: "Il-password trid tkun mill-inqas 6 karattri.",
    howDidYouHearLoystar: "Kif smajt dwar Loystar?",
    referralCode: "Kodiċi ta 'riferiment",
    byClickingTheButton: " Billi tikklikkja l-buttuna hawn taħt, taqbel mal-",
    termsAndSevice: "Termini",
    wereCreatingAccount: "Aħna qed noħolqu l-kont tiegħek",
    proceed: "Ipproċedi",
    verificationCodeMustBe6: "Il-Kodiċi tal-Verifika għandu jkun ta '6 numri",
    pleaseEnter6DigitCode: "Jekk jogħġbok Daħħal kodiċi b'6 ċifri",
    enterVerificationCode: "Daħħal il-Kodiċi tal-Verifika",
    resendToken: "Ibgħat mill-ġdid it-Token",
    verify: "Ivverifika",
    transactions: "Tranżazzjonijiet",
    todaySales: "Bejgħ tal-lum",
    salesHistory: "Storja tal-Bejgħ",
    supplyHistory: "Storja tal-Provvista",
    new: "Ġdid",
    invoices: "Fatturi",
    disbursements: "Ħlasijiet",
    offlineSales: "Bejgħ Offline",
    products: "Prodotti",
    customers: "Klijenti",
    multiLevelLoyalty: "Lealtà fuq Bosta Livelli",
    loyaltyPrograms: "Programmi ta ’Lealtà",
    members: "Membri",
    appStore: "App Store",
    orderMenu: "Menu tal-Ordni",
    settings: "Settings",
    staffAndBranches: "Persunal u Fergħat",
    myAccount: "Il-Kont Tiegħi",
    switchToSellMode: "Aqleb għall-Mod ta 'Bejgħ",
    signOut: "Iffirma",
    getFreeSubscription: "Ikseb Abbonament Ħieles",
    onlyNumbersAllowed: "Numri biss huma permessi",
    yourAccountMustBe10Digits:
      "in-numru tal-kont tiegħek għandu jkun numru ta '10 numri",
    yourBvnMustBe11: "il-BVN tiegħek għandu jkun numru ta '11-il ċifra",
    pleaseSelectBank: "Jekk jogħġbok agħżel il-bank tiegħek",
    selectYourBank: "Agħżel il-Bank tiegħek",
    enterBankAccountNumber: "Daħħal in-Numru tal-Kont Bankarju",
    enterBvnNumber: "Daħħal il-BVN tiegħek",
    connectBankAccount: "Qabbad Kont Bankarju",
    passwordResetSuccessfulAndSignOut:
      "Il-Password tiegħek ġiet irrisettjata b'suċċess. Ikklikkja fuq il-buttuna Sign Out hawn taħt biex terġa 'tidħol",
    enterCurrentPassword: "Daħħal il-password kurrenti",
    pleaseEnterCurrentPassword: "Jekk jogħġbok daħħal il-password kurrenti",
    phoneNumber: "Numru tat-telefon",
    sex: "Sess",
    dateOfBirth: "Data tat-twelid",
    state: "Stat",
    country: "Pajjiż",
    loyaltyId: "Lealtà ID",
    createdAt: "Maħluq Fuq",
    noOfTransactionsMade: "Numru ta 'transazzjonijiet magħmula",
    yourDownloadWillStart: "It-tniżżil tiegħek jibda f'mument",
    exportCustomers: "Esportazzjoni Klijenti",
    youhaveSuccessfullyToppedUp:
      "Tiegħek kkompletajt b'suċċess l-Unitajiet tal-SMS tiegħek.",
    youNowHave: "Issa għandek",
    smsUnits: "Unitajiet SMS",
    enterNumberOfSmsUnits:
      "Daħħal in-Numru ta 'Unitajiet ta' SMS li bi ħsiebek tixtri",
    pleaseEnterANumericValue: "Jekk jogħġbok daħħal valur numeriku",
    pay: "Ħallas",
    accountEditedSuccessfully: "Il-kont tiegħek ġie editjat b'suċċess.",
    youAeCurrentlyOn: "Int qiegħed bħalissa",
    plan: "Pjan",
    userData: "Dejta tal-Utent",
    businessData: "DATA TAN-NEGOZJU",
    businessCategory: "Negozju Catergory",
    pleaseSelectCurrency: "Jekk jogħġbok agħżel il-munita tiegħek",
    selectYourCurrency: "Agħżel il-munita tiegħek",
    purchaseMoreSmsUnits:
      "Ixtri aktar Unitajiet SMS billi tuża l-formola hawn taħt",
    youHave: "Int għandek",
    atLeast4SmsUnitsRrequired:
      "Għall-verifika huma meħtieġa mill-inqas 4 unitajiet sms, jekk jogħġbok żid!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Jekk jogħġbok ivverifika l-kont bankarju tiegħek biex tikkonforma mar-rekwiżiti 'Kun af il-Klijent Tiegħek' (KYC). Dan jippermettilek tiġbor pagamenti permezz ta 'USSD jew trasferiment immedjat, tirċievi ordnijiet mingħand klijenti u tipproċessa s-saldu tat-tranżazzjoni. Applikabbli għan-negozjanti Niġerjani biss. Jekk jogħġbok agħfas il-buttuna hawn taħt biex tibda.",
    reConnectBankAccount: "Ikkonnettja mill-ġdid il-Kont tal-Bank",
    accountName: "Isem tal-kont",
    accountNumber: "Numru tal-kont",
    bankName: "Isem il-Bank",
    verified: "Verifikat",
    accountDetails: "Dettalji tal-Kont",
    kycBankAccount: "KYC",
    createTier: "Oħloq Livell",
    fileUploadSuccessful: "fajl imtella ’b’suċċess",
    fileUploadFailed: "it-tlugħ tal-fajl falla",
    createLoyaltyProgram: "Oħloq Programm ta 'Lealtà",
    createLoyalty: "Oħloq Lealtà",
    name: "Isem",
    loyaltyArtwork: "Xogħol artistiku ta 'Lealtà",
    clickToUpload: "Ikklikkja biex ittella '",
    amountToPointsRatio: "Ammont għal Punti Proporzjon",
    points: "Punti",
    recommendedAmountToPointRatio:
      "Rakkomandat: ₦ 1 sa 1 punt. jiġifieri għal kull 1 Naira minfuq, il-klijenti tiegħek jiksbu punt 1",
    pleaseTypeIn: "Jekk jogħġbok ikteb",
    toDeleteLoyalty: "biex tħassar din il-lealtà",
    deleteLoyalty: "Ħassar Lealtà",
    toConfirm: "biex tikkonferma",
    edit: "Editja",
    pointsAwardedSuccessfully: "Punti Mogħtija B’suċċess.",
    enterPointValueToAward:
      "Daħħal il-Valur tal-Punt li Tixtieq Tagħti lill-Klijenti",
    award: "Premju",
    awardPointValuesToCustomer: "Valur tal-Punt tal-Għoti lill-Klijent",
    enrollMembersToLoyalty: "Irreġistra l-Membri għal-Lealtà",
    awardPoints: "Punti ta 'Premju",
    enroll: "Irreġistra",
    home: "Id-dar",
    loyalty: "Lealtà",
    enrollCustomers: "Irreġistra lill-Klijenti",
    selected: "Magħżula",
    customer: "Klijent",
    loyaltyActivationSuccessful: "L-Attivazzjoni tal-Lealtà tirnexxi.",
    loyaltyDeactivationSuccessful: "Id-Diżattivazzjoni tal-Lealtà tirnexxi.",
    viewTier: "Ara l-Livell",
    deactivate: "De-Attiva",
    activate: "Attiva",
    actions: "Azzjonijiet",
    nameOfLoyalty: "Isem tal-Lealtà",
    loyaltyStatus: "Status ta ’Lealtà Status ta’ Lealtà",
    numberOfTiers: "Numru ta 'Livelli",
    createdOn: "Maħluq Fuq",
    createATier: "Oħloq Livell",
    stopCreatingTierConfirmation: "Trid tieqaf toħloq saff?",
    stopEditingTierConfirmation: "Trid tieqaf teditja dan is-saff?",
    nameOfTier: "Isem il-Livell",
    minimumSpendingTarget: "Mira Minima ta 'Nefqa",
    maximumSpendingTarget: "Mira ta 'Nefqa Massima",
    minimumSpendingTargetRequired: "il-mira minima tan-nefqa hija meħtieġa",
    maximumSpendingTargetRequired: "il-mira tal-infiq massimu hija meħtieġa",
    rewardSponsor: "Sponsor tal-Premju",
    pleaseChooseARewardSponsor: "Jekk jogħġbok agħżel sponsor ta 'premju",
    self: "Jien stess",
    partner: "Sieħeb",
    rewardPartner: "Sieħeb Premju",
    pleaseSelectRewardPartner:
      "Jekk jogħġbok agħżel is-sieħeb tal-premju tiegħek",
    rewards: "Premji",
    pleaseSelectAReward: "Jekk jogħġbok agħżel premju",
    instructionsOnRedeemingReward:
      "Istruzzjonijiet dwar Kif Il-Klijent għandu Jifdi Premju",
    pleaseFillInThisField: "Jekk jogħġbok Imla dan il-Qasam!",
    toDeleteThisTier: " biex tħassar dan il-livell",
    deleteTier: "Ħassar il-Livell",
    viewMembers: "Ara l-Membri",
    membersEnrolled: "Membri Rreġistrati",
    instruction: "Istruzzjoni",
    membersIn: "Membri fi",
    availableTiersInLoyalty: "Livell (i) Disponibbli fil-Programm ta 'Lealtà",
    tiers: "Livelli",
    totalTier: "SAFAR TOTALI",
    availableLoyaltyProgramme: "Programm ta 'Lealtà Disponibbli",
    totalLoyalties: "Lealtajiet TOTALI",
    memberDetails: "Dettalji tal-Membru",
    nameOfCustomer: "Isem il-Klijent",
    address: "Indirizz",
    allEnrolledMembers: "Il-Membri Rreġistrati kollha",
    thisIsToWishYouHappyBirthday:
      "Dan biex nawguralkom l-għeluq tas-sena t-tajjeb u ħajja prospera. Grazzi għal dak kollu li int għal Loyster. Ċelebrazzjonijiet Tajba!",
    inputAnOfferPlease: "Daħħal Offerta jekk jogħġbok",
    pleaseSelectTheInsertPoint:
      "Jekk jogħġbok agħżel il-punt tal-inserzjoni fil-messaġġ u kklikkja mill-ġdid",
    birthdayOfferAndMessage: "Offerta ta ’Għeluq is-Sena u Messaġġ",
    birthdayOffer: "Offerta ta ’Għeluq is-Sena",
    birthdayMessage: "Messaġġ ta 'Għeluq is-Sena",
    noOfferFound: "Ma nstabet l-ebda offerta",
    settingABirthdayOffer:
      "L-issettjar ta ’offerta ta’ għeluq snin jippermetti lill-klijenti li jirċievu din l-offerta permezz ta ’SMS f’għeluq sninhom",
    createOffer: "Oħloq Offerta",
    whatIsTheOffer: "X'inhi l-offerta?",
    editMessage: "Edit Messaġġ",
    insert: "Daħħal",
    theNameOfCustomerInserted: "L-isem tal-klijent se jiddaħħal hawn",
    theBirtdayOfferInserted: "L-offerta ta 'għeluq snin tiddaħħal hawn",
    youSuccessfullyAddedNewBranch: "Żid b'suċċess fergħa ġdida!",
    addNewBranch: "Żid Fergħa Ġdida",
    addBranch: "Żid Fergħa",
    additionalBranchWillIncur: "Fergħa Addizzjonali tinħoloq",
    perBranch: "għal kull fergħa",
    ecommerceBranchCosts: "Spejjeż tal-Fergħa tal-eCommerce",
    pleaseEnterBranchName: "Jekk jogħġbok daħħal l-isem tal-fergħa",
    pleaseEnterBranchAddress1:
      "Jekk jogħġbok daħħal l-indirizz tal-fergħa linja 1",
    enterBranchAddress1: "Daħħal l-indirizz tal-fergħa linja 1",
    enterBranchAddress2: "Daħħal l-indirizz tal-fergħa linja 1",
    pleaseEnterBranchAddress2: "Jekk jogħġbok daħħal l-indirizz 2 tal-fergħa",
    enterBranchName: "Daħħal l-isem tal-fergħa",
    successfullyAddedStaff: "Int żid b'suċċess staff ġdid!",
    addNewStaff: "Żid Persunal Ġdid",
    addStaff: "Żid Staff",
    additionalStaffWillIncur: "Persunal Addizzjonali jġarrab",
    perStaff: "għal kull impjegat.",
    pleaseEnterStaffEmail: "Jekk jogħġbok daħħal l-email tal-persunal",
    pleaseEnterStaffUsername: "Jekk jogħġbok daħħal l-username tal-persunal",
    pleaseEnterStaffPassword: "Jekk jogħġbok daħħal il-password tal-persunal",
    pleaseSelectStaffRole: "Jekk jogħġbok agħżel ir-rwol tal-persunal",
    selectStaffRole: "Agħżel ir-rwol tal-persunal",
    enterStaffEmail: "Daħħal l-email tal-persunal",
    enterStaffUsername: "Daħħal l-username tal-persunal",
    enterStaffPassword: "Daħħal il-password tal-persunal",
    spacesNotAllowedInUsername: "spazji mhux permessi fil-username",
    admin: "Ammin",
    pleaseSelectBusinessToAttachStaff:
      "Jekk jogħġbok agħżel negozju biex tehmeż persunal",
    searchForBranchToAttachStaff: "Fittex fergħa biex tehmeż persunal",
    username: "Username",
    role: "Rwol",
    areYouSureToDeleteThis: "Żgur li tħassar dan",
    branches: "Fergħat",
    upgradeYourPlan: "Aġġorna l-Pjan tiegħek.",
    add: "ŻID",
    addNew: "Żid Ġdid",
    customerWithEmailAlreadyExists:
      "Klijent b'email / numru tat-telefon diġà jeżisti!",
    successfullyAddedNewCustomer: "Int żidt b'suċċess klijent ġdid!",
    addCustomer: "Żid Klijent",
    pleaseEnterCustomerFirstName:
      "Jekk jogħġbok daħħal l-ewwel isem tal-klijent",
    pleaseEnterCustomerLastName: "Jekk jogħġbok daħħal l-kunjom tal-klijent",
    pleaseEnterCustomerPhoneNumber:
      "Jekk jogħġbok daħħal in-numru tat-telefon tal-klijent",
    pleaseEnterCustomerEmail: "Jekk jogħġbok daħħal l-email tal-klijent",
    pleaseEnterCustomerAddressLine:
      "Jekk jogħġbok daħħal il-Linja tal-Indirizz tal-klijent",
    pleaseEnterCustomerOtherAddress:
      "Jekk jogħġbok daħħal l-Indirizz l-ieħor tal-klijent",
    pleaseSelectCustomerGender: "Jekk jogħġbok agħżel is-sess tal-klijent",
    gender: "Sess",
    male: "Irġiel",
    female: "Mara",
    bank: "Bank",
    selectBank: "Agħżel Bank",
    stateOrRegionOrProvince: "Stat / Reġjun / Provinċja",
    customerNotes: "Noti tal-Klijent",
    sendSms: "Ibgħat SMS",
    editCustomer: "Editja l-Klijent",
    redeemReward: "Iffred il-Premju",
    issueLoyaltyCard: "Oħroġ Karta tal-Lealtà",
    deleteCustomer: "Ħassar il-Klijent",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Int assenjajt b'suċċess ID ta 'Sħubija għal-Lealtà",
    noMembershipIdAvailable:
      "M'hemm l-ebda ID ta 'sħubija disponibbli. Jekk jogħġbok ikkuntattjana fuq hello@loystar.co",
    sendEmail: "Ibgħat Email",
    membershipPoints: "Punti ta 'Sħubija",
    customerDetails: "Dettalji tal-Klijent",
    close: "Agħlaq",
    loyaltyBalance: "Bilanċ tal-Lealtà",
    pointsBalance: "Bilanċ tal-Punti",
    starBalance: "Bilanċ tal-Istilel",
    requiredPoints: "Punti Meħtieġa",
    requiredStars: "Stilel Meħtieġa",
    reddemCode: "Ibdel il-Kodiċi",
    toDeleteThisCustomer: "biex tħassar dan il-klijent",
    customerHasBeenDeletedSuccessfully: "Il-klijent tħassar b'suċċess!",
    customerWithPhoneAlreadyExists:
      "Klijent bin-numru tat-telefon diġà jeżisti",
    customerWasSuccessfullyEdited: "Il-klijent ġie editjat b'suċċess",
    anErrorOccured: "Sar żball",
    phoneNumberIncludeCountry: "Numru tat-Telefon (Inkludi Kodiċi tal-Pajjiż)",
    yourFileQueuedForUpload:
      "Il-fajl tiegħek ġie fil-kju biex jittella '. Jekk jogħġbok aggornata l-paġna wara ftit sekondi.",
    thereWasAnErrorPerformingOperation: "Kien hemm żball waqt l-operazzjoni!",
    pleaseSelectFile: "Jekk jogħġbok Agħżel Fajl!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Fajls CSV permessi biss. Jekk jogħġbok ittella 'fajl .csv.",
    fileShouldBeSmallerThan5Mb:
      "Il-fajl għandu jkun iżgħar minn 5MB. Jekk għandek fajl ikbar, jekk jogħġbok ibgħat email lil support@loystar.co. Grazzi",
    customerFirstNameIsRequired:
      "l-ewwel isem tal-klijent huwa meħtieġ fir-ringiela",
    customerPhoneNumberIsRequired:
      "in-numru tat-telefon tal-klijent huwa meħtieġ fir-ringiela",
    importCustomers: "Importa Klijenti",
    upload: "Tella '",
    clickIconToDownloadCsv:
      "Ikklikkja din l-ikona biex tniżżel il-mudell tal-fajl CSV.",
    getGoogleSheetVersion: "Ikseb il-verżjoni tal-folja tal-Google",
    clickOrDragFileToUpload:
      "Ikklikkja jew drag il-fajl f'din iż-żona biex ittella '",
    missingOrInvalidColumnHeader:
      "Intestatura tal-kolonna nieqsa jew invalida. Jekk jogħġbok segwi l-format tal-mudell. Grazzi.",
    youHaveImported: "Int Importajt",
    youSuccessfullyRedeemedReward: "Int fdejt b'suċċess il-premju tiegħek!",
    sixDigitCode: "Kodiċi b'sitt ċifri",
    pleaseEnterCustomerRewardCode:
      "Jekk jogħġbok daħħal il-kodiċi tal-premju tal-klijent",
    enterRewardCode:
      "Daħħal il-kodiċi tal-premju. (Il-kodiċi tal-premju huwa sensittiv għall-każijiet)",
    selectLoyaltyProgram: "Agħżel programm ta 'lealtà",
    stamps: "Bolol",
    smsUnitsLow: "Unitajiet SMS Baxxi",
    pleaseTopUpSmsUnits: "Jekk jogħġbok Aqbad Unitajiet SMS",
    smsSuccessfullySentForDelivery: "SMS mibgħut b’suċċess għall-kunsinna!",
    sendSmsTo: "Ibgħat SMS lil",
    allCustomers: "Il-Klijenti Kollha",
    unitsAvailable: "Unitajiet Disponibbli",
    pleaseTypeInTheMessage: "Jekk jogħġbok ikteb il-messaġġ",
    message: "Messaġġ",
    charactersRemaining: "karattri li fadal",
    avoidUseOfSpecialCharacters:
      "Evita l-użu ta 'Karattri Speċjali u Emojis biex tikkonserva Unitajiet SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Żball waqt li Ġibt Dettalji tal-Livell Multi-Klijenti",
    search: "Fittex",
    reset: "Irrisettja",
    importCustomer: "Importa l-Klijent",
    addNewCustomer: "Żid Klijent Ġdid",
    sendSmsBroadcast: "Ibgħat Xandir bl-SMS",
    totalCustomers: "KLIJENTI TOTALI",
    disbursementDetails: "Dettalji tal-Ħlas",
    paidBy: "Imħallas minn",
    disbursed: "Imħallas",
    bankAccountName: "Isem tal-Kont Bankarju",
    bankAccountNumber: "Numru tal-Kont Bankarju",
    transferInitiated: "Trasferiment Mibdi",
    transferCompleted: "Trasferiment Lest",
    pleaseEnterAValid: "Jekk jogħġbok daħħal valida",
    begin: "tibda",
    end: "tmiem",
    date: "data",
    paymentDate: "Data tal-Ħlas",
    selectDisbursementDuration: "Agħżel Tul ta 'Żborż",
    totalSettled: "Total Saldat",
    totalUnsettled: "Totali Mhux Stabbilita",
    toDeleteThisSale: "biex tħassar dan il-bejgħ",
    draftSaleDeletedSuccessfully: "Abbozz ta 'Bejgħ tħassar b'suċċess!",
    deleteSale: "Ħassar Bejgħ",
    pleaseTypeInYourTotal:
      "Jekk jogħġbok ikteb it-total tiegħek biex tikkonferma t-tħassir",
    billDetails: "Dettalji tal-Abbozz",
    printBill: "Ipprintja Bill",
    servedBy: "Serva minn",
    total: "Total",
    createdDate: "Data tal-Ħolqien",
    createdTime: "Ħin Maħluq",
    status: "Status",
    loginSuccessful: "Idħol b'suċċess",
    pleaseWaitWhileWeConnectAccount:
      "Jekk jogħġbok stenna waqt li ngħaqqdu l-kont tiegħek",
    connectionFailedTryAgain:
      "Il-konnessjoni falliet. Jekk jogħġbok erġa pprova.",
    connectionSuccessful: "Konnessjoni Rnexxiet",
    viewDetails: "Ara d-Dettalji",
    enable: "Enable",
    free: "Libera",
    cost: "Spiża",
    visitWebsite: "Żur il-websajt",
    pleaseUpgradeYourPlanToPro:
      "Jekk jogħġbok Aġġorna l-pjan tiegħek għal Pro Plus biex tippermetti din l-app",
    connectYourBankAccount:
      "Qabbad il-Kont Bankarju tiegħek biex tkun tista 'tirċievi ħlasijiet.",
    disable: "Itfi",
    enableApp: "Ippermetti l-App",
    addNewProductToInvoice: "Żid Prodott Ġdid mal-Fattura",
    toDeleteThisInvoice: "biex tħassar din il-Fattura",
    invoiceDeletedSuccessfully: "Il-Fattura tħassret b'suċċess!",
    deleteInvoice: "Ħassar Fattura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Żied b’suċċess messaġġ ta ’ħlas tal-Fattura!",
    pleaseEnterPaymentInstructions:
      "Jekk jogħġbok daħħal l-istruzzjonijiet tal-ħlas",
    invoiceId: "ID tal-Fattura",
    paidDate: "Data tal-Ħlas",
    reference: "Referenza",
    productAdded: "Prodott miżjud",
    productOutOfStock:
      "Prodott barra mill-ħażna. Jekk jogħġbok stokk mill-ġdid.",
    totalInvoices: "FATTURI TOTALI",
    totalPaidInvoices: "FATTURI MĦALLSA TOTALI",
    totalUnpaidInvoices: "FATTURI MHUX IMĦALLSA",
    loyaltyProgramDeleted: "Programm ta 'Lealtà Imħassar",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dan il-programm ta 'lealtà tħassar b'suċċess",
    thisLoyaltyProgramEditedSuccessfully:
      "Dan il-programm ta 'lealtà ġie editjat b'suċċess",
    loyaltyProgramAddedSuccessfully:
      "Il-programm ta 'lealtà ġie miżjud b'suċċess",
    loyaltyProgramEdited: "Programm ta 'Lealtà Editjat",
    loyaltyProgramAdded: "Programm ta 'Lealtà Miżjud",
    loyaltyDetails: "Dettalji tal-Lealtà",
    doYouWantToCloseDialog: "Trid tagħlaq din id-djalogu?",
    pleaseEnterLoyaltyName: "Jekk jogħġbok daħħal l-isem tal-lealtà tiegħek",
    programType: "Tip ta 'Programm",
    pleaseSelectProgramType: "Jekk jogħġbok agħżel tip ta 'programm",
    simplePoints: "Punti Sempliċi",
    stampsProgram: "Programm tal-Bolol",
    threshold: "Limitu",
    pleaseEnterLoyaltyThreshold: "Jekk jogħġbok daħħal il-limitu ta 'lealtà",
    reward: "Premju",
    pleaseEnterLoyaltyReward: "Jekk jogħġbok daħħal il-premju tal-lealtà",
    totalUserPoints: "Punti Totali tal-Utent",
    totalUserStamps: "Bolli Totali tal-Utenti",
    spendingTarget: "Mira tal-infiq",
    spendingTargetHint1:
      "Il-mira tal-infiq hija kemm klijent irid jonfoq biex jaqla 'l-premju. 1 valur tal-munita = 1 punt.",
    spendingTargetHint2:
      "Il-mira tal-Bolol hija kemm il-bolol klijent irid jiġbor biex jaqla ’l-premju. eż. 5",
    addNewLoyaltyProgram: "Żid Programm Ġdid ta ’Lealtà",
    addLoyaltyProgram: "Żid Programm ta 'Lealtà",
    loyaltyProgramType: "Tip ta 'Programm ta' Lealtà",
    pleaseSelectLoyaltyProgramType:
      "Jekk jogħġbok agħżel it-tip ta 'programm ta' lealtà",
    nameOfProgram: "Isem il-programm",
    pleaseEnterProgramName: "Jekk jogħġbok daħħal l-isem tal-programm",
    whatIsTheReward: "X'inhu l-premju?",
    egNextPurchaseIsFree: "Eż. Ix-xiri li jmiss huwa b'xejn",
    customerName: "Isem il-Klijent",
    guestCustomer: "Klijent Mistieden",
    orderConfirmedSuccessfully: "Ordni kkonfermata b'suċċess",
    orderCancelledSuccessfully: "Ordni kkanċellata b'suċċess",
    confirmOrder: "Ikkonferma l-ordni",
    cancelOrder: "Ikkanċella l-ordni",
    allOrders: "L-Ordnijiet Kollha",
    totalOrders: "ORDNIJIET TOTALI",
    doYouWantToAcceptOrder: "Trid taċċetta din l-ordni?",
    doYouWantToCancelOrder: "Trid tikkanċella din l-ordni?",
    orderDetails: "Dettalji tal-Ordni",
    orderCreatedAt: "Ordni maħluqa fi",
    supplier: "Fornitur",
    productName: "Isem tal-Prodott",
    quantity: "Kwantità",
    unitPrice: "Prezz Unitarju",
    receivedBy: "Riċevut minn",
    reportFrom: "Rapport minn",
    totalSupplies: "Provvisti Totali",
    allRightsReserved: "Id-Drittijiet kollha Riservati",
    toDeleteThisTransaction: "biex tħassar din it-Tranżazzjoni",
    transactionDeletedSuccessfully:
      "It-tranżazzjoni ġiet imħassra b'suċċess. L-istokk ġie rritornat għall-inventarju.",
    deleteTransaction: "Ħassar Transazzjoni",
    transactionDetails: "Dettalji tat-Tranżazzjoni",
    printReceipt: "Irċevuta tal-Istampar",
    channel: "Kanal",
    discount: "Skont",
    profit: "Profitt",
    discountedSales: "Bejgħ skontat",
    errorFetchingRecord: "Żball li Ġib ir-Rekord",
    exportTransactions: "Transazzjonijiet ta 'Esportazzjoni",
    errorFetchingSalesRecord:
      "Żball meta ġibt ir-Rekord tal-bejgħ għall-Esportazzjoni.",
    totalSellingPrice: "Prezz Totali tal-Bejgħ",
    totalCostPrice: "Prezz tal-Ispiża Totali",
    appliedDiscount: "Skont Applikat",
    noOfItems: "Numru ta 'Oġġetti",
    sales: "Bejgħ",
    export: "Esportazzjoni",
    totalProfit: "Profitt Totali",
    totalBalanceInPeriod: "Bilanċ Totali fil-Perjodu",
    allTimeSales: "Bejgħ Kollha",
    records: "Rekords",
    todaysSales: "Bejgħ tal-lum",
    transaction: "transazzjoni",
    youHaveExceededTotalNumberOfProducts:
      "Qbiżt in-numru totali ta 'prodotti permessi fuq il-pjan tiegħek. Aġġorna l-pjan tiegħek biex tgawdi minn limitu ogħla.",
    youNeedToHaveLoyaltyProgram:
      "Trid ikollok Programm ta 'Lealtà biex tuża din il-karatteristika!",
    price: "Prezz",
    category: "Kategorija",
    stockTracking: "Traċċar tal-Istokk",
    stockCount: "Għadd tal-Istokk",
    taxed: "Intaxxat",
    originalPrice: "Prezz Oriġinali",
    costPrice: "Prezz tal-Ispejjeż",
    unit: "Unità",
    productImage: "Immaġni tal-Prodott",
    taxRate: "Rata tat-Taxxa",
    taxType: "Tip ta 'Taxxa",
    trackInventory: "Inventarju tal-Binarji",
    variants: "Varjanti",
    hasVariants: "Għandu Varjanti",
    importProduct: "Importa l-Prodott",
    exportProducts: "Prodotti għall-Esportazzjoni",
    addNewProduct: "Żid Prodott Ġdid",
    viewCategory: "Ara l-Kategorija",
    viewSuppliers: "Ara l-Fornituri",
    receiveInventory: "Irċievi Inventarju",
    printAllProductsTag: "Stampa Tag tal-Prodotti Kollha",
    deleteAll: "Ħassar Kollha",
    totalProducts: "PRODOTTI TOTALI",
    youveSuccessfullyAddedANewCategory: "Żiedet b'suċċess kategorija ġdida",
    addNewCategory: "Żid Kategorija Ġdida",
    addCategory: "Żid Kategorija",
    categoryName: "Isem tal-Kategorija",
    pleaseEnterCategoryName: "Jekk jogħġbok daħħal l-isem tal-kategorija",
    stampsTarget: "Miri tal-Bolol",
    sendInventory: "Ibgħat Inventarju",
    productDetails: "Dettalji tal-Prodott",
    youveSuccessfullyEditedThisCategory:
      "Int editjajt b'suċċess din il-kategorija",
    update: "Aġġornament",
    categoryList: "Lista tal-Kategorija",
    categories: "Kategoriji",
    enterQuantityToUpdate: "Daħħal kwantità biex taġġorna",
    inventorySentSuccessfully: "L-inventarju ntbagħat b'suċċess!",
    updateInventory: "Aġġorna l-Inventarju",
    currentQuantity: "Kwantità Kurrenti",
    pleaseEnterQuantityToAdd: "Jekk jogħġbok daħħal il-kwantità li trid iżżid",
    pleaseSelectABranch: "Jekk jogħġbok agħżel Fergħa",
    searchForBranch: "Fittex fergħa",
    youCantSendMoreThanStock: "Ma tistax tibgħat aktar milli għandek fl-istokk",
    send: "Ibgħat",
    pleaseEnterQuantityToSend:
      "Jekk jogħġbok daħħal il-kwantità li trid tibgħat",
    productNameIsRequiredOnRow: "l-isem tal-prodott huwa meħtieġ fir-ringiela",
    productCategoryIsRequiredOnRow:
      "il-kategorija tal-prodott hija meħtieġa fir-ringiela",
    productPriceIsRequiredOnRow:
      "il-prezz tal-prodott huwa meħtieġ fuq ringiela",
    productUnitIsRequiredOnRow: "unità tal-prodott hija meħtieġa fuq ringiela",
    productQuantityIsRequiredOnRow:
      "il-kwantità tal-prodott hija meħtieġa fir-ringiela",
    productVariantsRequireTracking: "varjanti tal-prodott jirrikjedu traċċar!",
    pleaseAddVariantClickButton:
      "Jekk jogħġbok żid varjant billi tikklikkja l-buttuna Żid!",
    totalVariantsMoreThanSelectedQuantity:
      "Il-Varjanti Totali huma aktar mill-kwantità tal-prodott magħżul, Pls inaqqsu l-kwantità tal-varjanti",
    productEditedSuccessfully: "Il-prodott ġie editjat b'suċċess!",
    fileTooLargeLessThan4Mb:
      "Id-daqs tal-fajl huwa kbir wisq! Id-daqs tal-fajl għandu jkun inqas minn 4MB.",
    suchVariantAlreadyExist: "Varjant bħal dan diġà jeżisti",
    addVariants: "Żid Varjanti",
    editProduct: "Editja l-Prodott",
    pleaseEnterProductName: "Jekk jogħġbok Daħħal Isem il-Prodott",
    pleaseEnterProductPrice: "Jekk jogħġbok Daħħal il-Prezz tal-Prodott",
    pleaseEnterProductOriginalPrice:
      "Jekk jogħġbok Daħħal il-Prezz Oriġinali tal-Prodott",
    productDescription: "Deskrizzjoni tal-prodott",
    selectProductCategory: "Agħżel Kategorija tal-Prodott",
    pleaseSelectProductCategory:
      "Jekk jogħġbok agħżel il-kategorija tal-prodott",
    productCostPrice: "Prezz tal-Ispiża tal-Prodott",
    productSellingPrice: "Prezz tal-Bejgħ tal-Prodott",
    productOriginalPrice: "Prezz Oriġinali tal-Prodott",
    maxFileSizeAllowedIs4Mb: "Massimu Id-Daqs tal-Fajl permess huwa 4mb",
    productsWithPicturesArePublished:
      "Prodotti bi stampi huma ppubblikati fuq Discover biex tirċievi ordnijiet",
    shouldThisProductBeTracked: "Dan il-prodott għandu jkun traċċat?",
    pleaseSelectStockUnit: "Jekk jogħġbok agħżel unità tal-ħażna",
    stockUnit: "Unità tal-Istokk",
    bag: "BAG",
    bottle: "FLIXKUN",
    bunch: "GĦAN",
    can: "JISTA '",
    carton: "KARTUNA",
    crate: "KAXXERA",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRU",
    pack: "PAKKETT",
    pair: "PAR",
    pieces: "Biċċiet",
    plate: "PJANĊA",
    tonne: "TONNE (MT)",
    uNIT: "UNITÀ",
    yard: "BIDDA",
    pleaseEnterProductQuantity: "Jekk jogħġbok daħħal il-kwantità tal-prodott",
    productQuantity: "Kwantità tal-Prodott",
    isThisProductTaxed: "Dan il-prodott huwa intaxxat?",
    selectTaxType: "Agħżel Tip ta 'Taxxa",
    pleaseSelectTaxType: "Jekk jogħġbok agħżel Tip tat-Taxxa",
    progressive: "Progressiva",
    proportional: "Proporzjonali",
    pleaseEnterProductTaxRate:
      "Jekk jogħġbok daħħal ir-rata tat-taxxa fuq il-prodott",
    doesProductHaveVariants: "Dan il-prodott għandu varjanti?",
    type: "Tip",
    value: "Valur",
    pleaseEnterVariantType: "Jekk jogħġbok daħħal it-tip tal-varjant",
    pleaseEnterVariantValue: "Jekk jogħġbok daħħal il-valur tal-varjant",
    pleaseEnterVariantPrice: "Jekk jogħġbok daħħal il-prezz tal-varjant",
    pleaseEnterVariantQuantity: "Jekk jogħġbok daħħal il-kwantità tal-varjant",
    productDeletedSuccessfully: "Il-prodott tħassar b’suċċess!",
    categoryDeletedSuccessfully: "Kategorija tħassret b'suċċess!",
    toDeleteThisProduct: "biex tħassar dan il-Prodott",
    invalidProductQuantity: "Kwantità tal-Prodott Invalida",
    quantityAddedIsOverStock:
      "Il-kwantità li qed iżżid hija fuq dak li għandek fil-ħażna.",
    itemInventoryNotTracked: "L-inventarju tal-oġġetti mhuwiex traċċat",
    addBulkQuantity: "Żid Kwantità Bulk",
    enterBulkQuantity: "Daħħal il-Kwantità Bulk",
    pleaseEnterBulkQuantity: "Jekk jogħġbok daħħal il-Kwantità Massa",
    youveSuccessfullyAddedANewProduct: "Żied prodott b'suċċess b'suċċess!",
    pleaseEnterProductSellingPrice:
      "Jekk jogħġbok daħħal il-prezz tal-bejgħ tal-prodott",
    doYouWantToTrackProductStock: "Trid issegwi l-istokk tal-prodott?",
    sellingPrice: "Prezz tal-Bejgħ",
    setProductExpiryReminder: "Issettja T-Tifkira tal-Iskadenza tal-Prodott",
    productExpiryDate: "Data ta 'skadenza tal-Prodott",
    startRemindingFrom: "Ibda Ifakkar Minn",
    productSuppliesAddedSuccessfully:
      "Żidt il-provvisti tal-prodott b'suċċess.",
    addProductSupplies: "Żid Provvisti tal-Prodott",
    pleaseSelectSupplier: "Jekk jogħġbok Agħżel Fornitur",
    nameOfProduct: "Isem tal-Prodott",
    pleaseSelectProduct: "Jekk jogħġbok Agħżel Prodott",
    productVariant: "Varjant tal-Prodott",
    pleaseSelectAVariant: "Jekk jogħġbok Agħżel Varjant",
    pleaseEnterUnitPrice: "Jekk jogħġbok Daħħal il-Prezz Unitarju",
    businessBranch: "Fergħa tan-Negozju",
    pleaseSelectBranch: "Jekk jogħġbok agħżel fergħa",
    youveSuccessfullyAddedANewSupplier: "Żied fornitur ġdid b'suċċess",
    addSupplier: "Żid il-Fornitur",
    pleaseEnterSupplierEmail: "Jekk jogħġbok daħħal l-email tal-fornitur",
    pleaseAddADescription: "Jekk jogħġbok żid deskrizzjoni",
    anErrorOccuredProductsDeleted:
      "Żball seħħ waqt it-twettiq tal-operazzjoni. Jekk jogħġbok ħu nota, ftit prodotti setgħu tħassru fil-proċess",
    bulkDelete: "Ħassar bl-ingrossa",
    youAreAboutToDelete: "Int se tħassar",
    product: "Prodott",
    isDueToRedeem: "għandu jifdi",
    aReward: "premju",
    pleaseSelectCustomerToReeemReward:
      "Jekk jogħġbok agħżel klijent biex tifdi l-premju.",
    youHaveNoLoyaltyProgramRunning:
      "M'għandek l-ebda programm ta 'lealtà attiv għaddej",
    customerHhasNoPointsInLoyaltyProgram:
      "Il-Klijent m'għandux punti f'dan il-programm ta 'lealtà",
    proceedWithPayment: "Ipproċedi bil-Ħlas?",
    youAreAboutToPayForTransactionUsingPoints:
      "Int se tħallas għat-tranżazzjoni billi tuża punti.",
    customerHas: "Il-Klijent Għandu",
    worth: "jiswa",
    andIsNotSufficientToPayForTransaction:
      "u mhux biżżejjed biex tħallas għal din it-tranżazzjoni. Huma jkunu jridu jżidu l-bilanċ billi jużaw metodu ieħor ta 'ħlas?",
    addCustomerLoyalty: "Żid il-Lealtà tal-Klijent",
    pleaseAddCustomerFirst: "Jekk jogħġbok żid jew agħżel klijent l-ewwel.",
    pleaseWaitWhileWeGetReady: "Jekk jogħġbok stenna, waqt li nkunu lesti",
    lowStock: "Stokk Baxx",
    pleaseEnterAmountTendered: "Jekk jogħġbok daħħal l-ammont offrut",
    areYouSureToBookSaleOffline:
      "Int żgur li trid tibbukkja dan il-bejgħ offline?",
    saleWouldBeBookedAutomatically:
      "Il-bejgħ ikun ibbukkjat awtomatikament meta tixgħel l-internet tiegħek",
    offlineSalesBookingCancelled: "Ibbukkjar tal-bejgħ offline huwa kkanċellat",
    covid19Message:
      "COVID19: Aħsel idejk bis-sapun jew Sanitize biex twaqqaf il-firxa. Ibqa 'Sikur Dejjem",
    saleSuccessfullyRecorded: "Bejgħ Irrekordjat b'suċċess!",
    sendReceiptToEmail: "Ibgħat irċevuta lill-Email",
    sendThankYouSms: "Ibgħat SMS grazzi",
    sendShippingDetails: "Ibgħat id-dettalji tat-Tbaħħir",
    addLoyalty: "Żid Lealtà",
    searchCustomerNameOrNumber: "Fittex l-isem jew in-numru tal-klijent",
    clickTheSearchCustomerBox:
      "Ikklikkja fuq il-Kaxxa tal-Klijent tat-Tiftix u l-Karta tal-Iskannjar",
    enterProductPrice: "Daħħal il-Prezz tal-Prodott",
    enterPriceFor: "Daħħal il-Prezz għal",
    searchForProduct: "Fittex il-prodott",
    all: "Kollha",
    backToDashboard: "Lura lejn id-Dashboard",
    viewDraftSales: "Ara l-Abbozz tal-Bejgħ",
    variantSelected: "varjant magħżul",
    variant: "varjant",
    thePreviousVariantSelectionNotAvailable:
      "L-għażla tal-varjant preċedenti mhix disponibbli għall-varjant il-ġdid magħżul abbażi tal-prezz, jekk jogħġbok ibdel l-għażla tiegħek.",
    pleaseSupplyPositiveQuantityNumber:
      "Jekk jogħġbok ipprovdi numru ta 'kwantità pożittiva",
    lowStockFor: "Stokk baxx għal",
    clearVariants: "Varjanti ċari",
    pleaseEnterQuantity: "Jekk jogħġbok daħħal il-Kwantità",
    picture: "Stampa",
    redemptionToken: "Token tal-Fidwa",
    token: "Token",
    totalSpent: "Nefqet Totali",
    confirmPayment: "Ikkonferma l-Ħlas",
    hasPaymentBeenMade: "Il-Ħlas ġie proċessat b'suċċess?",
    enterTransactionReference:
      "Daħħal ir-referenza tat-tranżazzjoni li ħallast magħha",
    pleaseSelectACustomer: "Jekk jogħġbok agħżel klijent!",
    areYouSureToApplyDiscount: "Int żgur li trid tapplika skont?",
    addYourBankAccountToEnableUssd:
      "Żid il-kont bankarju tiegħek biex tippermetti t-trasferiment Instant USSD permezz ta 'uPay",
    totalAmountToPay: "Ammont Totali li Ħallas",
    doYouWantToCancelTransaction: "Trid tikkanċella din it-tranżazzjoni?",
    savePrintBill: "Ħlief / Stampa Abbozz",
    enterAmountCollectedForCustomer: "Daħħal l-ammont miġbur għall-klijent",
    recordSale: "Rekord Bejgħ",
    checkOutWith: "Iċċekkja ma '",
    instantTransfer: "Trasferiment Instant",
    dialTheUSSDCode: "Ċempel il-kodiċi USSD",
    pleaseSelectABank: "Jekk jogħġbok agħżel bank",
    payWithUSSD: "Ħallas Bil-USSD",
    sendBillTo: " - Ibgħat Bill lil",
    waitingForUSSDTransfer: "Stennija għat-Trasferiment tal-USSD",
    percent: "Perċentwali",
    applyDiscount: "Applika Skont",
    thisBillHasBeenSaved: "Dan l-Abbozz ġie salvat",
    saveDraft: "Ħlief Abbozz",
    pleaseTypeANameToIdentifyCustomer:
      "Jekk jogħġbok ikteb isem biex tidentifika l-klijent",
    paymentDetails: "Dettalji tal-Ħlas",
    basePrice: "Prezz Bażi",
    staff: "Persunal",
    subTotal: "SubTotal",
    durationMonths: "Tul (xhur)",
    selectADuration: "Agħżel Tul",
    oneMonth: "Xahar",
    twoMonths: "2 Xhur",
    threeMonths: "3 Xhur",
    sixMonths: "6 Xhur",
    twelveMonths: "12-il Xahar",
    eighteenMonths: "18-il Xahar",
    twentyFourMonths: "24 Xahar",
    twoMonthsFree: "(Xahrejn Ħieles)",
    threeMonthsFree: "(3 Xhur Ħieles)",
    fiveMonthsFree: "(5 Xhur Ħieles)",
    yourAccountHasBeen: "Il-Kont tiegħek kien",
    renewed: "imġedda",
    upgraded: "aġġornati",
    successfully: "b'suċċess",
    yourSubscription: "L-Abbonament Tiegħek",
    youAreCurrentlyEnrolledOnA: "Int attwalment irreġistrat fuq",
    pleaseChooseAPaymentOption: "Jekk jogħġbok Agħżel Għażla ta 'Ħlas",
    planRenewal: "Tiġdid tal-Pjan",
    planUpgrade: "Aġġorna l-Pjan",
    pleaseSelectDurationToPayFor:
      "Jekk jogħġbok Agħżel it-Tul li bi ħsiebek tħallas għalih",
    staffAccounts: "Kontijiet tal-Persunal",
    ecommerce: "Kummerċ elettroniku",
    pleaseSelectAPlan: "Jekk jogħġbok agħżel pjan",
    includeAddons: "Inkludi Żidiet",
    viewTransactions: "Ara Tranżazzjonijiet",
    customerHasNoCompletedTansactions:
      "Il-Klijent għadu ma għamilx tansazzjonijiet",
    branch: "Fergħa",
    enterNumberOfEcommerceBranches:
      "Daħħal in-Numru ta 'Fergħat tal-Kummerċ Elettroniku",
    enterNumberOfEcommerceBranchesToPay:
      "Daħħal in-Numru ta 'Fergħat tal-Kummerċ Elettroniku li bi ħsiebek tħallas għalihom",
    ecommerceIntegration: "Integrazzjoni tal-kummerċ elettroniku",
    enterNumberOfBranches: "Daħħal in-Numru ta 'Fergħat",
    enterNumberOfAdditionalBranchesToPay:
      "Daħħal in-Numru ta 'Fergħat Addizzjonali li bi ħsiebek tħallas",
    enterNumberOfStaffs: "Daħħal in-Numru ta 'Persunal",
    enterNumberOfStaffsToPayFor:
      "Daħħal in-Numru ta 'Persunal li bi ħsiebek tħallas għalih",
    discountApplies: "Japplika l-Iskont",
    starsOnThe: "stilel fuq",
    offer: "offerta",
    get: "Ikseb",
    moreStarsToRedeem: "aktar stilel biex tifdi",
    taxVat: "Taxxa (VAT)",
    callCashierToCompletePayment: "Ċempel lill-Kaxxier biex Tlesti l-Ħlas",
    receipt: "Irċevuta",
    dear: "Għeżież",
    thankYouForYourOrderFindGoods:
      "Grazzi għall-ordni tiegħek. Jekk jogħġbok sib l-oġġetti li ġejjin fornuti, kif miftiehem.",
    shippingNote: "Nota tat-Tbaħħir",
    enterShippingNote: "Daħħal in-Nota tat-Tbaħħir",
    shippingAddress: "Indirizz tat-tbaħħir",
    enterShippingAddress: "Daħħal l-indirizz tat-Tbaħħir",
    wellDoneYouAreDueToRedeem: "Proset! Għandek tifdi",
    toGetYourRewardNextVisit:
      "biex tikseb il-premju tiegħek fiż-żjara li jmiss tiegħek. Grazzi",
    pointsOnThe: "Punti fuq",
    morePointsToRedeem: "iktar punti biex tifdi",
    showCode: "Uri kodiċi",
    toGetYourRewardOnNextVisit:
      "biex tikseb il-premju tiegħek fiż-żjara li jmiss tiegħek. Grazzi",
    earn: "Aqla '",
    delivaryNote: "Nota Delivary",
    draftSales: "Abbozz ta 'Bejgħ",
    startDate: "Data tal-bidu",
    endDate: "Data tat-tmiem",
    orders: "Ordnijiet",
    checkout: "checkout",
    noProductItem: "Ebda Oġġett tal-Prodott",
    selectProductImage: "Agħżel Immaġni tal-Prodott",
    selectCountry: "Agħżel pajjiż",
    selectRegion: "Agħżel Stat / Reġjun",
    printProductTag: "Stampa Tag tal-Prodott",
    transactionReference: "Referenza tat-transazzjoni",
    Cashier: "Kaxxier",
    Manager: "Maniġer",
    Owner: "Sid",
    Admin: "Ammin",
    addPartners: "Żid Imsieħba",
    addNewLoyaltyPartner: "Żid Imsieħeb ta' Lealtà Ġdid",
    pleaseEnterCompanyName: "Jekk jogħġbok daħħal Isem tal-Kumpanija",
    companyName: "Isem tal-Kumpanija",
    pleaseEnterCompanyRepName:
      "Jekk jogħġbok daħħal l-Isem tar-rappreżentant tal-kumpanija",
    companyRepName: "Isem tar-Rappreżentant tal-Kumpanija",
    pleaseEnterCompanyRepEmail:
      "Jekk jogħġbok daħħal l-email tar-rappreżentant tal-kumpanija",
    companyRepEmail: "Email tar-rappreżentant tal-kumpanija",
    pleaseEnterCompanyRepPhone:
      "Jekk jogħġbok daħħal in-numru tat-telefon tar-rappreżentant tal-kumpanija",
    companyRepPhone: "Numru tat-telefon tar-rappreżentant tal-kumpanija",
    addReward: "Żid premju",
    pleaseEnterRewardName: "Jekk jogħġbok daħħal l-isem tal-premju",
    rewardName: "Isem tal-Premju",
    rewardQuantity: "Kwantità tal-Premju",
    rewardDescription: "Deskrizzjoni tal-Premju",
    rewardType: "Tip ta' Premju",
    pleaseEnterRewardType: "Jekk jogħġbok daħħal it-tip ta' premju",
    pleaseEnterRewardQuantity: "Jekk jogħġbok daħħal il-kwantità tal-premju",
    pleaseEnterRewardDescription:
      "Jekk jogħġbok daħħal deskrizzjoni tal-premju",
    fineDining: "Fine Dining",
    luxuryFashion: "Moda lussu",
    hotels: "Lukandi",
    travel: "Ivvjaġġar",
    foodAndBeverage: "Ikel u Xorb",
    fashion: "Moda",
    health: "Saħħa",
    furniture: "Għamara",
    entertainment: "Divertiment",
    automobile: "Karozzi",
    education: "Edukazzjoni",
    beautyAndSpa: "Sbuħija u Spa",
    staycation: "Staycation",
    events: "Avvenimenti",
    trips: "Vjaġġi",
    oilAndGas: "Żejt u Gass",
    laundry: "Laundry",
    partnerCategory: "Kategorija tal-Imsieħeb",
    freeItem: "Oġġett Ħieles",
  },
  Maori: {
    cashier: "kaikohi moni",
    manager: "kaiwhakahaere",
    owner: "rangatira",
    online: "ā-ipurangi",
    offline: "tuimotu",
    changePassword: "Huri Kupuhipa",
    currentPasswordMessage: "Tena koa whakauruhia to kupu huna inaianei",
    passwordMessage: "Tomo koa to kupu huna",
    currentPassword: "Kupuhipa o Naianei",
    password: "kupuhipa",
    confirmPasswordMessage: "Tena koa whakaukia to kupuhipa!",
    confirmPassword: "Whakaū Kupuhipa",
    sendViaEmail: "Tukuna Ma te Imeera",
    sendViaWhatsapp: "Tukuna Ma WhatsApp",
    downloadPdf: "Tangohia te PDF",
    paid: "utua",
    unpaid: "kaore i utua",
    partial: "wehewehe",
    closeInvoice: "Kei te hiahia kati koe i te Nama",
    closeInvoiceConfirmation:
      "Kaore pea nga nama e penapena. Kei te hiahia kati koe?",
    yes: "āe",
    no: "kāo",
    invoice: "Inoice",
    wasDeducted: "i tangohia",
    for: "hoki",
    item: "Tūemi",
    addProduct: "Taapirihia te Hua",
    paymentReference: "Tohutoro utu",
    amountPaid: "Te Utu Kua Utu",
    discountAmount: "Tapeke Utu",
    amountDue: "Te utunga e tika ana",
    amount: "Tapeke",
    dueDate: "Te Ra Tuturu",
    paymentType: "Momo Utu",
    card: "Kaari",
    cash: "Moni Moni",
    bankTransfer: "Whakawhiti Peeke",
    paymentMessage: "Karere Utu",
    description: "Whakaahuatanga",
    sendReceipt: "Tukuna te Nama",
    delete: "Mukua",
    save: "Penapena",
    resend: "Tukuna Ano",
    saveAndSend: "Penapena",
    invoiceSaved: "Kua tiakina te nama!",
    selectPaymentMethod: "Tohua koa he Tikanga Utunga!",
    selectCustomer: "Tohua koa he Kaihoko!",
    cartEmptyError:
      "E kore e taea te Rarangi Kaa kia noho kau, kati te nama me te taapiri i te Taonga ki te kata.",
    subscriptionExpired:
      "Kua pau to hainatanga a kua iti noa to kaute. Patohia te Paatene i raro nei hei whakahou i to kaute",
    renew: "Whakahou",
    holdOn: "Kia Mau",
    customerBank: "Peeke Kaihoko",
    cancel: "Whakakore",
    selectACustomer: "Tīpakohia te Kaihoko",
    invoiceSuccessfulPdfError:
      "He angitu te hanga i nga nama engari he roa ke te Huringa PDF i te waa noa. Tena koa tirohia mai nei",
    downloadingInvoice: "Kei te tango i nga nama",
    downloadingReceipt: "Kei te Tangohia Whiwhi",
    whatsappReceiptError: "I hapa i te tuku rihīti mā WhatsApp, Ngana koa koa.",
    receiptToWhatsapp: "Kua tukuna te rihīti ki a WhatsApp",
    thankYouForPatronage: "Mauruuru mo to kaitautoko",
    hereIsYourReceipt: "Anei to rihīti utu",
    errorSendingEmailReceipt:
      "I hapa i te tukunga mai o te nama mai i te imeera, ngana koa ngana ki te tautoko atu ranei",
    receiptSentToEmail: "Kua tukuna te rihīti ki te imeera a te kaihoko",
    invoiceSentToEmail: "Kua tukuna nga nama ki te imeera a te kaihoko",
    invoiceSuccessWhatsappError:
      "I angitu te mahi nama engari he hapa i tukuna mai ki a WhatsApp. Ngana ano ki te raarangi nama",
    invoiceSuccessfulEmailError:
      "I angitu te mahi nama engari he hapa i puta i te wa e tukuna ana he i-meera. Tena koa ngana mai i te raarangi nama",
    invoiceSentToWhatsapp: "I tukuna atu nga nama ki WhatsApp",
    hello: "Kia ora",
    pleaseDownloadInvoice: "Tangohia koa te nama",
    pleaseDownloadReceipt: "Tangohia koa te nama",
    from: "Tuhinga ka whai mai",
    email: "Imeera",
    upgrade: "Whakapai ake",
    youAreOnFreePlan: "Kei runga koe i te Mahere Koreutu.",
    clickOn: "Paatohia",
    yourPlanInFewSteps: " to ohaurunga i roto i nga waahanga tere.",
    to: "ki",
    yourSubscriptionHasExpired: "Kua pau to hainatanga a kua iti noa to kaute.",
    days: "ra",
    yourSubscriptionExpiresIn: "Kua pahemo to hainatanga Loystar i roto i",
    createAcount: "Hangaia he Kaute",
    signIn: "Waitohu Whakauru",
    continue: "Haere tonu",
    enterOtp: "Whakauruhia te OTP PIN",
    enterValidOtp: "Tomo koa he PIN tika",
    pin: "PIN",
    tokenSentToMail: "Kua tukuna mai he tohu ki to imeera",
    passwordResetSuccessful: "I angitu te tautuhi kupuhipa",
    somethingWentWrong: "Kua he tetahi mea!",
    resetPassword: "Tautuhi Kupuhipa",
    iHaveResetCode: "He waehere tautuhi kupuhipa ano taku",
    pleaseEnterEmail: "Tena koa whakauruhia to imeera",
    aTokenWillBeSentToEmail: "Ka tukuna he tohu ki to imeera",
    enterEmail: "Whakauruhia to imeera",
    sendinYourToken: "Te tuku i to tohu ...",
    makeSureItMatchesPassword: "Kia mahara ki te paatata ki to kupu huna hou",
    pleaseChooseNewPassword: "Tena koa kowhiria he kupu huna hou",
    chooseNewPassword: "Kōwhiria he kupuhipa hōu",
    enterNewPassword: "Whakauruhia to kupuhipa hou hei whakaū",
    enterTokenSent: "Whakauruhia te tohu i tukuna mai ki o miera",
    resetToken: "Tautuhi Tohu",
    resettingPassword: "Tautuhi Anō i to Kupuhipa ...",
    signUp: "Waitohu Up",
    adminSignInWithEmail:
      " Ka haina te Kaiwhakahaere me te imeera i te wa e waitohu ana nga Kaimahi me te ingoa kaiwhakamahi.",
    pleaseEnterEmailOrUsername:
      "Tena koa whakauruhia to imeera ingoa kaiwhakamahi ranei",
    emailOrUsername: "Imeera Ingoa Kaiwhakamahi ranei",
    pleaseEnterPassword: "Tomo koa mai te kupu huna",
    createAnAccount: "Hangaia he Kaute",
    forgotPassword: "Wareware i te Kupuhipa?",
    enterPhoneNumber: "Whakauru Tau Waea",
    personalData: "Raraunga Whaiaro",
    validateConfirmationCOde: "Whakamana Waehere Whakaae",
    pleaseEnterFirstName: "Tomo koa to ingoa tuatahi",
    pleaseEnterPhoneNumber: "Tomo koa to nama waea",
    pleaseEnterLastName: "Tomo koa to ingoa whakamutunga",
    pleaseEnterBusinessName: "Tena koa whakauruhia to ingoa pakihi",
    firstName: "Ingoa Tuatahi",
    lastName: "Ingoa Whakamutunga",
    businessName: "Ingoa Pakihi",
    previous: "Tuhinga o mua",
    next: "Panuku",
    pleaseSelectBusinessCategory: "Tohua mai to waahanga pakihi",
    pleaseEnterValidEmail: "Tena koa whakauruhia he imeera tika",
    pleaseEnterPostCode: "Tena koa whakauruhia te waehere whakairinga",
    postCode: "Waehere Poutāpeta",
    phoneNumberInUse: "Kei te whakamahia tenei nama waea!",
    emailInUse: "Kei te whakamahia tenei imeera!",
    foodAndDrinks: "Te Kai me te Inu",
    salonAndBeauty: "Salon me te Ataahua",
    fashionAndAccessories: "Nga Hoahoa me nga Taonga",
    gymAndFitness: "Gym me te Hauora",
    travelAndHotel: "Haerere me te Hotera",
    homeAndGifts: "Kaainga me nga Taonga",
    washingAndCleaning: "Te horoi me te horoi horoi",
    gadgetsAndElectronics: "Nga taputapu me nga Hiko",
    groceries: "Kai hoko",
    others: "Etahi",
    submit: "Tukuna",
    accountCreatedSuccessful: "I angitu te hanga i to kaute.",
    pleaseEnterAddress: "Tena koa Whakauruhia To Wāhitau",
    addressLine1: "Raina Wāhitau 1",
    addressLine2: "Raina Wāhitau 2",
    choosePassword: "Whiriwhiria te Kupuhipa",
    passwordMustBe6Characters: "Ko te Kupuhipa me neke ake i te 6 nga tohu.",
    howDidYouHearLoystar: "Nahea koe i rongo ai mo Loystar?",
    referralCode: "Waehere tuku",
    byClickingTheButton:
      " Ma te paatene i te paatene i raro nei, ka whakaae koe ki te",
    termsAndSevice: "Kupu",
    wereCreatingAccount: "Kei te hangaia e maatau to kaute",
    proceed: "Haere tonu",
    verificationCodeMustBe6: "Ko te Wae Whakaaturanga me tatau kia 6 mati",
    pleaseEnter6DigitCode: "Tena koa Whakauruhia te waehere 6 mati",
    enterVerificationCode: "Whakauru Waehere Whakaū",
    resendToken: "Tukua Te Tohu",
    verify: "Manatoko",
    transactions: "Nga whakawhitiwhitinga",
    todaySales: "Nga Hokona o tenei ra",
    salesHistory: "Hoko Hoko",
    supplyHistory: "Tuhinga o mua",
    new: "Hou",
    invoices: "Nga nama nama",
    disbursements: "Tuhinga",
    offlineSales: "Hokonga Tuimotu",
    products: "Hua",
    customers: "Kaihoko",
    multiLevelLoyalty: "Tuuturu Taumata-Maha",
    loyaltyPrograms: "Papatono Pono",
    members: "Nga Mema",
    appStore: "Toa Toa",
    orderMenu: "Tahua Tono",
    settings: "Tautuhinga",
    staffAndBranches: "Nga Kaimahi me nga Peka",
    myAccount: "Taku Kaute",
    switchToSellMode: "Whakawhiti ki te Hokona Aratau",
    signOut: "Waitohu Atu",
    getFreeSubscription: "Tiki Ohaurunga Koreutu",
    onlyNumbersAllowed: "Ko nga nama anake e whakaaetia ana",
    yourAccountMustBe10Digits: "me tau 10 tau te nama kaute",
    yourBvnMustBe11: "ko to BVN he tau 11 mati",
    pleaseSelectBank: "Tohua koa to peeke",
    selectYourBank: "Tohua to Peeke",
    enterBankAccountNumber: "Whakauruhia te Nama Kaute Peeke",
    enterBvnNumber: "Whakauruhia to BVN",
    connectBankAccount: "Tūhono Pūkete Peeke",
    passwordResetSuccessfulAndSignOut:
      "Kua pai te whakaotinga o to Kupuhipa. Paatohia te paatene Waitohu Atu i raro nei hei waitohu ano",
    enterCurrentPassword: "Whakauruhia te kupuhipa o naianei",
    pleaseEnterCurrentPassword: "Tena koa whakauruhia te kupuhipa o tenei wa",
    phoneNumber: "Tau Waea",
    sex: "Sex",
    dateOfBirth: "Ra whanau",
    state: "State",
    country: "Whenua",
    loyaltyId: "ID Pono",
    createdAt: "I hangaia I",
    noOfTransactionsMade: "Nama o nga whakawhitiwhitinga i mahia",
    yourDownloadWillStart: "Ka tiimata kau to tango ake",
    exportCustomers: "Kaweake Kaihoko",
    youhaveSuccessfullyToppedUp:
      "Kua oti pai i a koe to whakakii ake i o Units SMS.",
    youNowHave: "Kei a koe inaianei",
    smsUnits: "Units SMS",
    enterNumberOfSmsUnits:
      "Whakauru Tau o Units SMS E Tono Ana Koe ki te Hokonga",
    pleaseEnterANumericValue: "Tomo koa ki te uara tau",
    pay: "Utu",
    accountEditedSuccessfully: "I tika te whakatika o to kaute.",
    youAeCurrentlyOn: "Kei te haere koe i tenei wa",
    plan: "Mahere",
    userData: "Raraunga Kaiwhakamahi",
    businessData: "RARAUNGA TARI",
    businessCategory: "Pakihi Kaihokohoko",
    pleaseSelectCurrency: "Tohua koa to moni",
    selectYourCurrency: "Tohua to moni",
    purchaseMoreSmsUnits:
      "Hokona etahi atu Units SMS ma te whakamahi i te pepa i raro nei",
    youHave: "Kei a koe",
    atLeast4SmsUnitsRrequired:
      "I te iti rawa e 4 nga waeine sms e hiahiatia ana mo te manatoko, tena koa whakaritehia!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Tirohia koa to kaute peeke kia ea ki nga whakaritenga 'Kia mohio ki to Kaihoko' (KYC) whakaritenga. Ma tenei ka taea e koe te kohikohi utu ma te USSD, ma te whakawhiti tonu ranei, ka tono ota mai i nga kaihoko me te whakarite i nga whakataunga whakawhiti. He tika ki nga kaihokohoko Nigeria anake. Tena koa paatohia te paatene i raro nei kia tiimata.",
    reConnectBankAccount: "Whakauruhia ano te Kaute Peeke",
    accountName: "Ingoa Kaute",
    accountNumber: "Tau Kaute",
    bankName: "Ingoa Peeke",
    verified: "Kua Whakamanahia",
    accountDetails: "Taipitopito Pūkete",
    kycBankAccount: "KYC",
    createTier: "Hangaia te Tier",
    fileUploadSuccessful: "pai te tukuake i te konae",
    fileUploadFailed: "i rahua te tukunga ake o te konae",
    createLoyaltyProgram: "Hangaia he Kaupapa Tuuturu",
    createLoyalty: "Hangaia te Pono",
    name: "Ingoa",
    loyaltyArtwork: "Mahi Toi Pono",
    clickToUpload: "Patohia ki te tuku ake",
    amountToPointsRatio: "Te Utu ki te Tatauranga Piro",
    points: "Piro",
    recommendedAmountToPointRatio:
      "E taunaki ana: ₦ 1 ki te 1point. ie mo nga 1 Naira kua pau, ka whiwhi nga kaihoko i te 1 tohu",
    pleaseTypeIn: "Patohia koa",
    toDeleteLoyalty: "ki te whakakore i tenei pono",
    deleteLoyalty: "Mukua te Pono",
    toConfirm: "whakaū",
    edit: "Whakatika",
    pointsAwardedSuccessfully: "Tohu I Tutuki Tutukia.",
    enterPointValueToAward:
      "Whakauru i te Uara Tohu Kei te hiahia koe ki te Tohu i nga Kaihoko",
    award: "Tohu",
    awardPointValuesToCustomer: "Tohu Uara Tohu ki te Kaihoko",
    enrollMembersToLoyalty: "Whakauru Nga Mema ki te Pono",
    awardPoints: "Tohu Tohu",
    enroll: "Whakauru",
    home: "Kaainga",
    loyalty: "Pono",
    enrollCustomers: "Whakauru ki nga Kaihoko",
    selected: "Kua kowhiria",
    customer: "Kaihoko",
    loyaltyActivationSuccessful: "He angitu te whakahohenga pono.",
    loyaltyDeactivationSuccessful: "Angitu Whakakoretanga he angitu.",
    viewTier: "Tirohia te Tier",
    deactivate: "De-Whakahohe",
    activate: "Whakahohe",
    actions: "Nga Hohenga",
    nameOfLoyalty: "Tuhinga o mua",
    loyaltyStatus: "Tūnga Tuturu",
    numberOfTiers: "Tuhinga o mua",
    createdOn: "Hangaia Ana",
    createATier: "Hangaia he Tier",
    stopCreatingTierConfirmation:
      "Kei te hiahia mutu koe ki te hanga taumata taumata?",
    stopEditingTierConfirmation:
      "Kei te hiahia mutu te whakatika i tenei reanga?",
    nameOfTier: "Tuhinga o mua",
    minimumSpendingTarget: "Whāinga Whakapau Iti",
    maximumSpendingTarget: "Target Whakapau Moni",
    minimumSpendingTargetRequired: "he iti rawa te utu whakapau kua hiahiatia",
    maximumSpendingTargetRequired: "ko te whaainga whakapau moni ka hiahiatia",
    rewardSponsor: "Kaitautoko Tohu",
    pleaseChooseARewardSponsor: "Tiwhiria koa tetahi kaitautoko utu",
    self: "Whaiaro",
    partner: "Hoa",
    rewardPartner: "Hoa Reward",
    pleaseSelectRewardPartner: "Tohua koa to hoa rangatira",
    rewards: "Tuhinga",
    pleaseSelectAReward: "Tohua koa he utu",
    instructionsOnRedeemingReward: "Tohutohu mo Me pehea te utu a te Kaihoko",
    pleaseFillInThisField: "Tena Whakakiihia tenei mara!",
    toDeleteThisTier: " ki te muku i tenei reanga",
    deleteTier: "Mukua te Tier",
    viewMembers: "Tirohia nga Mema",
    membersEnrolled: "Nga Mema kua Whakauru",
    instruction: "Tohutohu",
    membersIn: "Nga mema o roto",
    availableTiersInLoyalty: "Wātea Tier (s) kei roto i te Kaupapa Tuuturu",
    tiers: "Tiers",
    totalTier: "Tuhinga o mua",
    availableLoyaltyProgramme: "Kaupapa Whakapono Pono",
    totalLoyalties: "Tuhinga o mua",
    memberDetails: "Taipitopito Mema",
    nameOfCustomer: "Ingoa o te Kaihoko",
    address: "Wāhitau",
    allEnrolledMembers: "Nga Mema Kua Whakauru Katoa",
    thisIsToWishYouHappyBirthday:
      "Ko te hiahia tenei ki a koe kia tino harikoa to ra whanau me to oranga pai. Nga mihi mo nga mea katoa kei a koe ki a Loyster. Nga Mahi Whakanui!",
    inputAnOfferPlease: "Whakauruhia he Tuku koa",
    pleaseSelectTheInsertPoint:
      "Tohua koa te tohu whakauru ki te panui ka paato ano",
    birthdayOfferAndMessage: "Takoha Ra whanau me te Karere",
    birthdayOffer: "Takoha Ra whanau",
    birthdayMessage: "Karere Ra whanau",
    noOfferFound: "Kaore i kitea he tuku",
    settingABirthdayOffer:
      "Ko te whakarite i te toha whanau ka taea e nga kaihoko te tango i tenei tono ma te SMS i to ra whanau",
    createOffer: "Hanga Tuku",
    whatIsTheOffer: "He aha te tuku?",
    editMessage: "Whakatika Karere",
    insert: "Whakauruhia",
    theNameOfCustomerInserted:
      "Ko te ingoa o te kaihoko ka whakauruhia ki konei",
    theBirtdayOfferInserted: "Ka whakauruhia te tuku ra whanau i konei",
    youSuccessfullyAddedNewBranch:
      "Kua angitu koe ki te taapiri i tetahi manga hou!",
    addNewBranch: "Taapirihia he Peka Hou",
    addBranch: "Taapiri Peka",
    additionalBranchWillIncur: "He Peka Taapiri ka whiu",
    perBranch: "ia peka",
    ecommerceBranchCosts: "Nga utu o te Peka Ecommerce",
    pleaseEnterBranchName: "Tena koa whakauruhia te ingoa peka",
    pleaseEnterBranchAddress1:
      "Tena koa whakauruhia te raarangi wahitau o te peka 1",
    enterBranchAddress1: "Whakauruhia te raarangi wahitau o te peka 1",
    enterBranchAddress2: "Whakauruhia te raarangi wahitau o te peka 1",
    pleaseEnterBranchAddress2:
      "Tena koa whakauruhia te raarangi wahitau 2 o te peka",
    enterBranchName: "Whakauruhia te ingoa peka",
    successfullyAddedStaff: "Kua angitu koe ki te whakauru i nga kaimahi hou!",
    addNewStaff: "Taapirihia Nga Kaimahi Hou",
    addStaff: "Taapirihia Nga Kaimahi",
    additionalStaffWillIncur: "Ka uru mai etahi Kaimahi Ano",
    perStaff: "ia kaimahi.",
    pleaseEnterStaffEmail: "Tena koa whakauruhia te imeera a nga kaimahi",
    pleaseEnterStaffUsername: "Tomo koa ki te ingoa kaiwhakamahi o nga kaimahi",
    pleaseEnterStaffPassword: "Tomo koa ki te kupu huna a nga kaimahi",
    pleaseSelectStaffRole: "Tēnā tohua te tūranga o ngā kaimahi",
    selectStaffRole: "Tohua te mahi a nga kaimahi",
    enterStaffEmail: "Whakauruhia te imeera a nga kaimahi",
    enterStaffUsername: "Whakauruhia te ingoa kaiwhakamahi o nga kaimahi",
    enterStaffPassword: "Whakauruhia te kupu huna a nga kaimahi",
    spacesNotAllowedInUsername:
      "kaore i whakaaetia nga waahi ki te ingoa kaiwhakamahi",
    admin: "Kaiwhakahaere",
    pleaseSelectBusinessToAttachStaff:
      "Tohua mai he pakihi hei whakapiri i nga kaimahi",
    searchForBranchToAttachStaff: "Rapua he manga hei whakapiri i nga kaimahi",
    username: "Ingoa Kaiwhakamahi",
    role: "Mahi",
    areYouSureToDeleteThis: "Ka tutuki tenei i a koe",
    branches: "Peka",
    upgradeYourPlan: "Whakapai ake i to Mahere.",
    add: "WHAKAPONO",
    addNew: "Taapirihia he Hou",
    customerWithEmailAlreadyExists:
      "Ko te Kaihoko me te nama imeera / nama kei te kitea!",
    successfullyAddedNewCustomer:
      "Kua angitu koe ki te whakauru i tetahi kaihoko hou!",
    addCustomer: "Taapirihia te Kaihoko",
    pleaseEnterCustomerFirstName:
      "Tena koa whakauruhia te ingoa tuatahi o te kaihoko",
    pleaseEnterCustomerLastName:
      "Tena koa whakauruhia te ingoa whakamutunga o te kaihoko",
    pleaseEnterCustomerPhoneNumber:
      "Tena koa whakauruhia te nama waea a te kaihoko",
    pleaseEnterCustomerEmail: "Tena koa whakauruhia te imeera a te kaihoko",
    pleaseEnterCustomerAddressLine:
      "Tena koa whakauruhia te raina Wāhitau a te kaihoko",
    pleaseEnterCustomerOtherAddress:
      "Tena koa whakauruhia tetahi atu Wāhitau o te kaihoko",
    pleaseSelectCustomerGender: "Tohua koa te ira tangata o te kaihoko",
    gender: "Ira Ira",
    male: "Tane",
    female: "Wahine",
    bank: "Peeke",
    selectBank: "Tohua Peeke",
    stateOrRegionOrProvince: "State / Rohe / Porowini",
    customerNotes: "Kupu Awhina Kaihoko",
    sendSms: "Tukuna SMS",
    editCustomer: "Whakatika Kaihoko",
    redeemReward: "Hokona te Utu",
    issueLoyaltyCard: "Putanga Kaari Pono",
    deleteCustomer: "Mukua te Kaihoko",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Kua oti angitu te toha i tetahi ID Mema Pono",
    noMembershipIdAvailable:
      "Kaore he mema ID e waatea. Tena koa whakapiri mai ki a maatau hello@loystar.co",
    sendEmail: "Tukuna Imeera",
    membershipPoints: "Piro Mema",
    customerDetails: "Taipitopito Kaihoko",
    close: "Katia",
    loyaltyBalance: "Taurite Tuturu",
    pointsBalance: "Tika Taurite",
    starBalance: "Taurite Whetu",
    requiredPoints: "Piro e hiahiatia ana",
    requiredStars: "Whetu e hiahiatia ana",
    reddemCode: "Waehere Whakaora",
    toDeleteThisCustomer: "ki te muku i tenei kaihoko",
    customerHasBeenDeletedSuccessfully: "Kua oti pai te muku i te kaihoko!",
    customerWithPhoneAlreadyExists: "Ko te Kaihoko me te nama waea kua kitea",
    customerWasSuccessfullyEdited: "I angitu te whakatika a te Kaihoko",
    anErrorOccured: "I hapa tetahi",
    phoneNumberIncludeCountry: "Tau Waea (Whakauruhia te Waehere Whenua)",
    yourFileQueuedForUpload:
      "Kua tohaina to konae hei tukuatu. Whakahoutia koa te whaarangi i muri i etahi hēkona.",
    thereWasAnErrorPerformingOperation: "I hapa te mahi o te mahi!",
    pleaseSelectFile: "Tīpakohia koa he Kōnae!",
    oopsOnlyCsvFilesAllowed:
      "Aue! Ko nga konae CSV anake e whakaaetia ana. Tukuna koa he konae .csv.",
    fileShouldBeSmallerThan5Mb:
      "Kia iti ake i te 5MB te konae. Mena he nui ake to konae, me imeera ki te tautoko@loystar.co. Mauruuru",
    customerFirstNameIsRequired:
      "ingoa tuatahi kiritaki te i runga i te rarangi",
    customerPhoneNumberIsRequired:
      "nama waea a te kaihoko e hiahiatia ana i te rarangi",
    importCustomers: "Kawemai Kaihoko",
    upload: "Tukuatu",
    clickIconToDownloadCsv: "Paatohia tenei tohu hei tango i te tauira CSV.",
    getGoogleSheetVersion: "Tangohia te pepa pepa a Google",
    clickOrDragFileToUpload:
      "Patohia, toia ranei te konae ki tenei rohe hei tukuatu",
    missingOrInvalidColumnHeader:
      "Kei te ngaro, kei te tika ranei te pane o te pou. Tena koa whai i te whakatakotoranga tauira. Mauruuru.",
    youHaveImported: "Kua Kawemai E Koe",
    youSuccessfullyRedeemedReward: "Kua hokona pai e koe to utu!",
    sixDigitCode: "Ono waehere mati",
    pleaseEnterCustomerRewardCode:
      "Tena koa whakauruhia te waehere utu a te kaihoko",
    enterRewardCode:
      "Whakauruhia te waehere utu. (Ko te tohu utu he take taumaha)",
    selectLoyaltyProgram: "Tohua he kaupapa pono",
    stamps: "Pane Kuini",
    smsUnitsLow: "Units SMS Iti",
    pleaseTopUpSmsUnits: "Tena koa Whakaritehia nga Units SMS",
    smsSuccessfullySentForDelivery: "I angitu te tuku a SMS mo te tuku!",
    sendSmsTo: "Tukuna te SMS ki",
    allCustomers: "Katoa Kaihoko",
    unitsAvailable: "Wae Wātea",
    pleaseTypeInTheMessage: "Patohia koa te panui",
    message: "Karere",
    charactersRemaining: "pūāhua e toe ana",
    avoidUseOfSpecialCharacters:
      "Aukati i te whakamahinga o nga Tohu Motuhake me nga Emoji hei tiaki i nga Units SMS.",
    note: "Panui",
    errorFetchingCustomersMultilevelDetail:
      "Hapa i te Kii Kii Taipitopito Taumata Maha",
    search: "Rapu",
    reset: "Tautuhi Anō",
    importCustomer: "Kawemai Kaihoko",
    addNewCustomer: "Taapirihia te Kaihoko Hou",
    sendSmsBroadcast: "Tukuna te Panui SMS",
    totalCustomers: "Tuhinga o mua",
    disbursementDetails: "Nga Taipitopito mo te Tukunga",
    paidBy: "Kua utua e",
    disbursed: "Kua tohaina",
    bankAccountName: "Ingoa Kaute Peeke",
    bankAccountNumber: "Tau Kaute Peeke",
    transferInitiated: "Whakawhiti Ka tiimata",
    transferCompleted: "Kua Oti te Whakawhiti",
    pleaseEnterAValid: "Tomo koa he whaimana",
    begin: "tiimata",
    end: "mutunga",
    date: "rā",
    paymentDate: "Te Ra utu",
    selectDisbursementDuration: "Tīpakohia te Roa Whakaroha",
    totalSettled: "Tapeke Kua Whakatauhia",
    totalUnsettled: "Tapeke Wāhikore",
    toDeleteThisSale: "ki te muku i tenei hoko",
    draftSaleDeletedSuccessfully: "Kua oti te whakakore i te Hoko Hoko!",
    deleteSale: "Mukua te Hoko",
    pleaseTypeInYourTotal: "Patohia koa to tapeke ki te whakaū i to mukunga",
    billDetails: "Taipitopito Pire",
    printBill: "Pire Taarua",
    servedBy: "I mahia e",
    total: "Tapeke",
    createdDate: "Ra I Hangaia",
    createdTime: "Te Wa I Hangaia",
    status: "Tūnga",
    loginSuccessful: "Angitu te takiuru",
    pleaseWaitWhileWeConnectAccount:
      "Tatari koa i te wa e hono ana taatau kaute",
    connectionFailedTryAgain: "I rahua te hononga Tena koa ngana.",
    connectionSuccessful: "Angitu te Hononga",
    viewDetails: "Tirohia nga Taipitopito",
    enable: "Whakahohea",
    free: "Koreutu",
    cost: "Utu",
    visitWebsite: "Tirohia te Paetukutuku",
    pleaseUpgradeYourPlanToPro:
      "Tena Whakahoutia to mahere ki Pro Plus kia taea ai te whakamahi i tenei taupānga",
    connectYourBankAccount: "Whakauruhia to Kaute Peeke kia taea ai te utu.",
    disable: "Monokia",
    enableApp: "Whakahohea te App",
    addNewProductToInvoice: "Taapirihia he Hua Hou ki te Nama",
    toDeleteThisInvoice: "ki te muku i tenei Nama",
    invoiceDeletedSuccessfully: "I tutuki pai te whakakore i nga nama!",
    deleteInvoice: "Mukua te nama",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Kua oti i a koe te whakauru i tetahi korero utu nama!",
    pleaseEnterPaymentInstructions: "Tena koa whakauruhia nga tohutohu utu",
    invoiceId: "ID nama",
    paidDate: "Ra utua",
    reference: "Tohutoro",
    productAdded: "Hua kua taapirihia",
    productOutOfStock: "Hua kua kore o te kararehe. Tena koa-whakato.",
    totalInvoices: "Tuhinga o mua",
    totalPaidInvoices: "Tuhinga o mua",
    totalUnpaidInvoices: "Tuhinga o mua",
    loyaltyProgramDeleted: "Kua Mukua te Kaupapa Tuuturu",
    thisLoyaltyProgramDeletedSuccessfully:
      "Kua tutuki angitu tenei papatono pono",
    thisLoyaltyProgramEditedSuccessfully:
      "Kua oti te whakatika i tenei kaupapa pono",
    loyaltyProgramAddedSuccessfully: "Kua angitu te taapiri i te hotaka pono",
    loyaltyProgramEdited: "Program Tuuturu Kua Whakatika",
    loyaltyProgramAdded: "Kua Taapirihia te Kaupapa Tuuturu",
    loyaltyDetails: "Taipitopito Tuuturu",
    doYouWantToCloseDialog: "Kei te hiahia kati koe i tenei korero?",
    pleaseEnterLoyaltyName: "Tena koa whakauruhia te ingoa o to pono",
    programType: "Momo Papatono",
    pleaseSelectProgramType: "Tohua koa he momo papatono",
    simplePoints: "Piro Maamaa",
    stampsProgram: "Kaupapa Pane",
    threshold: "Paepae",
    pleaseEnterLoyaltyThreshold: "Tena koa whakauruhia te paepae pono",
    reward: "Reward",
    pleaseEnterLoyaltyReward: "Tena koa whakauruhia te utu mo te pono",
    totalUserPoints: "Tapeke Piro Kaiwhakamahi",
    totalUserStamps: "Tapeke Panui Kaiwhakamahi",
    spendingTarget: "Whāinga whakapau",
    spendingTargetHint1:
      "Ko te whaainga whakapau ko te utu mo te kaihoko kia whiwhi ia i te utu. 1 uara moni = 1 ira.",
    spendingTargetHint2:
      "Ko te whaainga pane ko te maha o nga pane kuini me kohikohi e te kaihoko kia whiwhi ia i te utu. hei tauira 5",
    addNewLoyaltyProgram: "Taapirihia he Kaupapa Tuuturu Hou",
    addLoyaltyProgram: "Taapirihia te Kaupapa Tuuturu",
    loyaltyProgramType: "Momo Papatono Pono",
    pleaseSelectLoyaltyProgramType: "Tēnā tīpakohia te momo papatono pono",
    nameOfProgram: "Ingoa o te hotaka",
    pleaseEnterProgramName: "Whakauruhia koa te ingoa papatono",
    whatIsTheReward: "He aha te utu?",
    egNextPurchaseIsFree: "Tauira He koreutu te hoko o muri",
    customerName: "Ingoa Kaihoko",
    guestCustomer: "Kaihoko Manuhiri",
    orderConfirmedSuccessfully: "Kua tutuki te whakatuturutanga o te ota",
    orderCancelledSuccessfully: "Kua whakakorea te tono",
    confirmOrder: "Whakaū i te ota",
    cancelOrder: "Whakakorea te ota",
    allOrders: "Katoa nga Ota",
    totalOrders: "Tuhinga o mua",
    doYouWantToAcceptOrder: "Kei te hiahia koe ki te whakaae ki tenei ota?",
    doYouWantToCancelOrder: "Kei te hiahia whakakore i tenei ota?",
    orderDetails: "Taipitopito Tono",
    orderCreatedAt: "Ko te ota i hangaia i",
    supplier: "Kaiwhakarato",
    productName: "Ingoa Hua",
    quantity: "Rahi",
    unitPrice: "Utu Utu",
    receivedBy: "Kua riro mai i a",
    reportFrom: "Tuhinga ka whai mai",
    totalSupplies: "Tapeke Taputapu",
    allRightsReserved: "Tika katoa",
    toDeleteThisTransaction: "ki te whakakore i tenei Whakawhiti",
    transactionDeletedSuccessfully:
      "Kua angitu te tango i nga whakawhitiwhitinga. Kua whakahokia te taonga ki te raarangi taonga.",
    deleteTransaction: "Mukua te Whakawhiti",
    transactionDetails: "Taipitopito Whakawhitinga",
    printReceipt: "Whiwhi Taarua",
    channel: "Channel",
    discount: "Utu",
    profit: "Moni",
    discountedSales: "Hokonga kua whakahekehia",
    errorFetchingRecord: "Hapa Tiki Kete",
    exportTransactions: "Kaweake Whakawhitinga",
    errorFetchingSalesRecord:
      "I hapa te tango i nga rekoata hoko mo te Kaweake.",
    totalSellingPrice: "Utu Hokona Katoa",
    totalCostPrice: "Utu Utu Tapeke",
    appliedDiscount: "Utu kua Hoatuhia",
    noOfItems: "Tuhinga o mua",
    sales: "Hokonga",
    export: "Kaweake",
    totalProfit: "Painga Katoa",
    totalBalanceInPeriod: "Te Katoa o te Taurite i te Wa",
    allTimeSales: "Nga Hoko Katoa",
    records: "Pukapuka",
    todaysSales: "Nga Hokona o tenei ra",
    transaction: "tauwhitinga",
    youHaveExceededTotalNumberOfProducts:
      "Kua hipa koe i te katoa o nga hua e whakaaetia ana i runga i to mahere. Whakapai ake i to mahere kia pai ai to rohe.",
    youNeedToHaveLoyaltyProgram:
      "Me whiwhi koe i tetahi Kaupapa Tuuturu hei whakamahi i tenei ahuatanga!",
    price: "Utu",
    category: "Kāwai",
    stockTracking: "Arotake Stock",
    stockCount: "Kaute Hoko",
    taxed: "Taxed",
    originalPrice: "Utu Taketake",
    costPrice: "Utu Utu",
    unit: "Waeine",
    productImage: "Atahanga Hua",
    taxRate: "Reiti Taake",
    taxType: "Momo Taake",
    trackInventory: "Inventory Arotaki",
    variants: "Nga rereke",
    hasVariants: "He rereke",
    importProduct: "Hua Kawemai",
    exportProducts: "Hua Kaweake",
    addNewProduct: "Taapirihia he Hua Hou",
    viewCategory: "Tirohia te Kāwai",
    viewSuppliers: "Tirohia nga Kaituku",
    receiveInventory: "Whiwhi Inventory",
    printAllProductsTag: "Tāngia te Tūtohu Hua Katoa",
    deleteAll: "Mukua Katoa",
    totalProducts: "Tuhinga o mua",
    youveSuccessfullyAddedANewCategory:
      "Kua pai te whakauru i tetahi waahanga hou",
    addNewCategory: "Taapirihia te Kāwai Hou",
    addCategory: "Taapirihia te Kāwai",
    categoryName: "Ingoa Kāwai",
    pleaseEnterCategoryName: "Tena koa whakauruhia te ingoa o te waahanga",
    stampsTarget: "Whāinga Paarua",
    sendInventory: "Tukuna Inventory",
    productDetails: "Taipitopito Hua",
    youveSuccessfullyEditedThisCategory:
      "Kua pai te whakatika o tenei waahanga",
    update: "Whakahou",
    categoryList: "Rarangi Kāwai",
    categories: "Kāwai",
    enterQuantityToUpdate: "Whakauruhia he rahinga hei whakahou",
    inventorySentSuccessfully: "I pai te tuku o te raarangi taonga!",
    updateInventory: "Whakahou Inventory",
    currentQuantity: "Te Rahi o Naianei",
    pleaseEnterQuantityToAdd:
      "Tena koa whakauruhia te rahinga e hiahia ana koe ki te taapiri",
    pleaseSelectABranch: "Tohua mai he Peka",
    searchForBranch: "Rapua he peka",
    youCantSendMoreThanStock:
      "Kaore e taea e koe te tuku atu i nga mea kua putu ki a koe",
    send: "Tukuna",
    pleaseEnterQuantityToSend:
      "Tena koa whakauruhia te rahinga e hiahia ana koe ki te tuku",
    productNameIsRequiredOnRow:
      "ingoa hua e hiahiatia ana i runga i te rarangi",
    productCategoryIsRequiredOnRow:
      "kāwai hua e hiahiatia ana i runga i te rarangi",
    productPriceIsRequiredOnRow: "utu hua e hiahiatia ana i runga i te rarangi",
    productUnitIsRequiredOnRow: "waeine hua e hiahiatia ana i te rarangi",
    productQuantityIsRequiredOnRow: "rahinga hua e hiahiatia ana i te rarangi",
    productVariantsRequireTracking: "rerekē hua me te aroturuki!",
    pleaseAddVariantClickButton:
      "Tena koa tapirihia he rereke ma te paatene i te paatene taapiri!",
    totalVariantsMoreThanSelectedQuantity:
      "Ko nga Tapeke Katoa he nui ake i te rahinga hua kua tohua, Pls ka whakaheke i te rahinga rereke",
    productEditedSuccessfully: "Kua oti te whakatika i te hua!",
    fileTooLargeLessThan4Mb:
      "He nui rawa te rahi o te konae! Kia iti ake te rahi o te konae ki te 4MB.",
    suchVariantAlreadyExist: "Kei te noho ke tetahi momo rereke",
    addVariants: "Taapirihia he rereke",
    editProduct: "Whakatika Hua",
    pleaseEnterProductName: "Tena koa Whakauruhia te Ingoa Hua",
    pleaseEnterProductPrice: "Tomo koa Utu Hua",
    pleaseEnterProductOriginalPrice: "Tena koa Whakauruhia te Utu Taketake Utu",
    productDescription: "Whakaahuatanga Hua",
    selectProductCategory: "Tīpako Kāwai Hua",
    pleaseSelectProductCategory: "Tēnā tīpakohia te kāwai hua",
    productCostPrice: "Utu Utu Hua",
    productSellingPrice: "Utu Hokona Hua",
    productOriginalPrice: "Hua Utu Taketake",
    maxFileSizeAllowedIs4Mb:
      "Max. Ko te Rahi o te konae e whakaaehia ana ko 4mb",
    productsWithPicturesArePublished:
      "Ko nga hua me nga pikitia e whakaputaina ana i runga i te Tirohanga kia riro mai ai nga ota",
    shouldThisProductBeTracked: "Me aroturukihia tenei hua?",
    pleaseSelectStockUnit: "Tēnā tohua koa wae",
    stockUnit: "Wahanga Stock",
    bag: "Tohu",
    bottle: "PANUI",
    bunch: "PUNI",
    can: "CAN",
    carton: "KARTON",
    crate: "CRATE",
    cup: "KAPU",
    dozen: "DOZEN",
    gigabytes: "NGARU",
    gram: "KINI",
    kilogram: "KILOGRAM",
    litre: "PAITANGA",
    pack: "PAKE",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PANUI",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Tena koa whakauruhia te rahinga hua",
    productQuantity: "Hua Rahi",
    isThisProductTaxed: "Kei te taake tenei hua?",
    selectTaxType: "Tohua te Momo Taake",
    pleaseSelectTaxType: "Tohua koa te Momo Taake",
    progressive: "Whakamua",
    proportional: "Taurite",
    pleaseEnterProductTaxRate: "Tena koa whakauruhia te reanga taake hua",
    doesProductHaveVariants: "He rereke nga rereketanga o tenei hua?",
    type: "Momo",
    value: "Uara",
    pleaseEnterVariantType: "Tena koa whakauruhia te momo rereke",
    pleaseEnterVariantValue: "Tomo koa ki te uara rereke",
    pleaseEnterVariantPrice: "Tomo koa ki te utu ke",
    pleaseEnterVariantQuantity: "Tomo koa ki te rahinga rereke",
    productDeletedSuccessfully: "Kua mukua angitu te hua!",
    categoryDeletedSuccessfully: "I oti pai te whakakore i te waahanga!",
    toDeleteThisProduct: "ki te muku i tenei Hua",
    invalidProductQuantity: "Te Rahi Hua Muhu",
    quantityAddedIsOverStock:
      "Ko te rahinga e taapirihia ana e koe kei runga ake i nga mea kei roto i a koe.",
    itemInventoryNotTracked: "Kaore i te whaiwhai i nga taonga taonga",
    addBulkQuantity: "Taapirihia te Rahi Nui",
    enterBulkQuantity: "Whakauruhia te Rahi Nui",
    pleaseEnterBulkQuantity: "Tena koa whakauruhia te Rahi Nui",
    youveSuccessfullyAddedANewProduct: "Kua oti i a koe te whakauru hua hou!",
    pleaseEnterProductSellingPrice: "Tena koa whakauruhia te utu hoko hua",
    doYouWantToTrackProductStock:
      "Kei te hiahia koe ki te whai i nga taonga hua?",
    sellingPrice: "Utu Hoko",
    setProductExpiryReminder:
      "Whakatakotoria he Whakamaumahara mo te Paunga Hua",
    productExpiryDate: "Te Ra Paunga Hua",
    startRemindingFrom: "Tīmata Whakamaumahara Mai i",
    productSuppliesAddedSuccessfully:
      "Kua angitu e koe te whakauru atu i nga taonga hua.",
    addProductSupplies: "Taapirihia nga Taputapu Hua",
    pleaseSelectSupplier: "Tīpakohia koa te Kaiwhakarato",
    nameOfProduct: "Ingoa o te Hua",
    pleaseSelectProduct: "Tīpakohia te Hua",
    productVariant: "Momo Hua",
    pleaseSelectAVariant: "Tīpakohia koa he momo",
    pleaseEnterUnitPrice: "Tena Tomo Mai Tau Utu",
    businessBranch: "Peka Pakihi",
    pleaseSelectBranch: "Tohua koa te peka",
    youveSuccessfullyAddedANewSupplier:
      "Kua angitu koe ki te whakauru i tetahi kaiwhakarato hou",
    addSupplier: "Taapiri Kaiwhakarato",
    pleaseEnterSupplierEmail:
      "Tena koa whakauruhia te imeera a te kaiwhakarato",
    pleaseAddADescription: "Tena koa taapirihia he whakaahuatanga",
    anErrorOccuredProductsDeleted:
      "I puta he raru i te wa e mahi ana koe. Kia mahara, he iti noa nga hua kua mukua i roto i tenei mahi",
    bulkDelete: "Muku Maha",
    youAreAboutToDelete: "Ka tata koe ki te muku",
    product: "Hua",
    isDueToRedeem: "e tika ana kia hokona",
    aReward: "he utu",
    pleaseSelectCustomerToReeemReward:
      "Tohua mai koa he kaihoko ki te hoko utu.",
    youHaveNoLoyaltyProgramRunning: "Kaore he kaupapa pono e mahi ana koe",
    customerHhasNoPointsInLoyaltyProgram:
      "Kaore he tohu a te Kaihoko i tenei kaupapa pono",
    proceedWithPayment: "Haere tonu me te Utu?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ka utua e koe te tauwhitinga ma te whakamahi i nga tohu.",
    customerHas: "Kaihoko",
    worth: "utu",
    andIsNotSufficientToPayForTransaction:
      "a kaore e ranea hei utu mo tenei tauwhitinga. Kei te hiahia ratou ki te taapiri i te toenga ma te whakamahi i tetahi atu tikanga utu?",
    addCustomerLoyalty: "Taapirihia te Pono a te Kaihoko",
    pleaseAddCustomerFirst:
      "Tena koa taapirihia kia kowhiri ranei i te kaihoko tuatahi.",
    pleaseWaitWhileWeGetReady: "Tatari koa, kia rite taatau",
    lowStock: "Stock iti",
    pleaseEnterAmountTendered: "Tena koa whakauruhia te moni i tukuna",
    areYouSureToBookSaleOffline:
      "Kei te tino hiahia koe ki te tono i tenei hoko tuimotu?",
    saleWouldBeBookedAutomatically:
      "Ka hokona aunoatia te hoko ina ka huri ana koe i to ipurangi",
    offlineSalesBookingCancelled: "Kua whakakorea te tono hokohoko tuimotu",
    covid19Message:
      "COVID19: Horoihia o ringa ki te hopi horoi horoi ranei i o ringaringa kia mutu ai te horapa. Noho Haumaru i Nga Wa Katoa",
    saleSuccessfullyRecorded: "Hoko angitu te Tuhipoka!",
    sendReceiptToEmail: "Tukuna te nama ki te Imeera",
    sendThankYouSms: "Tukuna mihi SMS",
    sendShippingDetails: "Tukuna nga korero tuku",
    addLoyalty: "Taapirihia te Pono",
    searchCustomerNameOrNumber: "Rapu ingoa kaihoko tau ranei",
    clickTheSearchCustomerBox:
      "Patohia te Pouaka Kaihoko Rapu me te Kaari Karapa",
    enterProductPrice: "Whakauruhia te Utu Hua",
    enterPriceFor: "Whakauru Utu mo",
    searchForProduct: "Rapua te hua",
    all: "Katoa",
    backToDashboard: "Hoki ki te Papatohu",
    viewDraftSales: "Tirohia te Hoko Hoko",
    variantSelected: "momo i tohua",
    variant: "rerekē",
    thePreviousVariantSelectionNotAvailable:
      "Ko te waahanga rereke o mua kaore i te waatea mo te momo hou kua tohua i runga i te utu, tena koa whakarerekehia to whiringa.",
    pleaseSupplyPositiveQuantityNumber: "Tukuna mai koa he nama nama nui",
    lowStockFor: "Kohinga iti mo",
    clearVariants: "Maama nga rereke",
    pleaseEnterQuantity: "Tena koa whakauruhia te Rahi",
    picture: "Pikitia",
    redemptionToken: "Tohu Whakaoranga",
    token: "Tohu",
    totalSpent: "Te Katoa o te Whakapau",
    confirmPayment: "Whakaū Utu",
    hasPaymentBeenMade: "Kua oti pai te tukatuka utu?",
    enterTransactionReference:
      "Whakauruhia te korero whakawhitiwhitinga i utua e koe",
    pleaseSelectACustomer: "Tohua mai koa he kaihoko!",
    areYouSureToApplyDiscount: "Kei te tino hiahia koe ki te tuku utu?",
    addYourBankAccountToEnableUssd:
      "Taapirihia to peeke peeke kia taea ai te whakawhiti Tere USSD na uPay",
    totalAmountToPay: "Te Tapeke Utu hei Utu",
    doYouWantToCancelTransaction:
      "Kei te hiahia whakakore i tenei tauwhitinga?",
    savePrintBill: "Tiaki / Pire Taarua",
    enterAmountCollectedForCustomer:
      "Whakauruhia te moni kua kohia mo te kaihoko",
    recordSale: "Hoko Hoko",
    checkOutWith: "Tirohia me",
    instantTransfer: "Whakawhiti Inamata",
    dialTheUSSDCode: "Waea i te waehere USSD",
    pleaseSelectABank: "Tohua koa he peeke",
    payWithUSSD: "Utua Ma te USSD",
    sendBillTo: " - Tukuna Pire ki",
    waitingForUSSDTransfer: "Kei te tatari mo te Whakawhiti USSD",
    percent: "Entrau",
    applyDiscount: "Hoatu te utu",
    thisBillHasBeenSaved: "Kua tiakina tenei Pire",
    saveDraft: "Tiaki Tuhinga",
    pleaseTypeANameToIdentifyCustomer:
      "Patohia he ingoa ki te tohu i te kaihoko",
    paymentDetails: "Taipitopito utu",
    basePrice: "Utu Taketake",
    staff: "Kaimahi",
    subTotal: "TapekeTohu",
    durationMonths: "Roanga (marama)",
    selectADuration: "Tohua he Roanga",
    oneMonth: "1 Marama",
    twoMonths: "2 Marama",
    threeMonths: "3 Marama",
    sixMonths: "6 Marama",
    twelveMonths: "12 Marama",
    eighteenMonths: "18 Marama",
    twentyFourMonths: "24 Marama",
    twoMonthsFree: "(2 Marama Koreutu)",
    threeMonthsFree: "(3 Marama Koreutu)",
    fiveMonthsFree: "(5 Marama Koreutu)",
    yourAccountHasBeen: "Ko to Kaute kua",
    renewed: "whakahoutia",
    upgraded: "whakahoutia",
    successfully: "angitu",
    yourSubscription: "To Ohaurunga",
    youAreCurrentlyEnrolledOnA: "Kua whakauruhia koe ki tenei wa i runga i te",
    pleaseChooseAPaymentOption: "Kōwhiria he Kōwhiri Utunga",
    planRenewal: "Whakahoutanga Mahere",
    planUpgrade: "Whakamaherehia te Whakapai",
    pleaseSelectDurationToPayFor:
      "Tēnā Tīpakohia te Roanga e hiahia ana koe ki te utu",
    staffAccounts: "Kaute Kaimahi",
    ecommerce: "Hokohoko",
    pleaseSelectAPlan: "Tohua mai he mahere",
    includeAddons: "Whakauruhia nga Taapiringa",
    viewTransactions: "Tirohia nga Whakawhitinga",
    customerHasNoCompletedTansactions:
      "Kaore ano kia oti nga whakautu a te Kaihoko",
    branch: "Peka",
    enterNumberOfEcommerceBranches: "Whakauru Tau o nga Peka Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Whakauruhia te Tau o nga Peka Kaihokohoko E Tono Ana Koe Ki Te utu",
    ecommerceIntegration: "Whakakotahitanga Ecommerce",
    enterNumberOfBranches: "Whakauru Tau o nga Peka",
    enterNumberOfAdditionalBranchesToPay:
      "Whakauru Tau o Nga Peka Taapiri E hiahia ana koe ki te utu",
    enterNumberOfStaffs: "Whakauru Tau o nga Kaimahi",
    enterNumberOfStaffsToPayFor:
      "Whakauru Tau o Nga Kaimahi Ka Whai Koe Ki Te Utunga",
    discountApplies: "Ka pa te hekenga",
    starsOnThe: "whetu i runga i te",
    offer: "tuku",
    get: "Whiwhi",
    moreStarsToRedeem: "etahi whetu hei hoko",
    taxVat: "Taake (VAT)",
    callCashierToCompletePayment: "Karangahia te Kaawhi mo te Whakaoti Utu",
    receipt: "Rihīti",
    dear: "Aroha",
    thankYouForYourOrderFindGoods:
      "Mauruuru mo to ota. Tena rapua nga taonga e whai ake nei i tukuna mai, i runga i te whakaae.",
    shippingNote: "Panui Panui",
    enterShippingNote: "Whakauruhia te Panui Panui",
    shippingAddress: "Wāhitau kaipuke",
    enterShippingAddress: "Whakauruhia te wahitau kaipuke",
    wellDoneYouAreDueToRedeem: "Kia ora! Me hoko e koe",
    toGetYourRewardNextVisit: "ki te tiki i to utu i to haerenga mai. Mauruuru",
    pointsOnThe: "Tuhinga i runga i te",
    morePointsToRedeem: "atu tohu ki te hoko",
    showCode: "Whakaatu waehere",
    toGetYourRewardOnNextVisit:
      "ki te tiki i to utu i to haerenga mai. Mauruuru",
    earn: "Whiwhi",
    delivaryNote: "Panui Delivary",
    draftSales: "Hoko Hoko",
    startDate: "Ra timatanga",
    endDate: "Ra mutunga",
    orders: "Ota",
    checkout: "takoha",
    noProductItem: "Kaore he Taonga Hua",
    printProductTag: "Tohu Hua Hua",
    transactionReference: "Tohutoro whakawhiti",
    Cashier: "Kaihokohoko",
    Manager: "Kaiwhakahaere",
    Owner: "Rangatira",
    Admin: "Kaiwhakahaere",
    addPartners: "Tāpiri hoa",
    addNewLoyaltyPartner: "Tāpirihia he hoa piripono hou",
    pleaseEnterCompanyName: "Whakaurua koa te Ingoa Kamupene",
    companyName: "Ingoa Kamupene",
    pleaseEnterCompanyRepName:
      "Whakaurua koa te ingoa o te kanohi o te kamupene",
    companyRepName: "Ingoa o te Mangai Kamupene",
    pleaseEnterCompanyRepEmail:
      "Whakauruhia koa te imeera a te kanohi o te kamupene",
    companyRepEmail: "Īmēra o te kamupene rep",
    pleaseEnterCompanyRepPhone:
      "Whakauruhia koa te nama waea o te kanohi o te kamupene",
    companyRepPhone: "Tau Waea o te kamupene rep",
    addReward: "Tāpiri utu",
    pleaseEnterRewardName: "Whakauruhia koa te ingoa utu",
    rewardName: "Ingoa Utu",
    rewardQuantity: "Te Rahi Utu",
    rewardDescription: "Whakaahuatanga utu",
    rewardType: "Momo utu",
    pleaseEnterRewardType: "Whakauruhia koa te momo utu",
    pleaseEnterRewardQuantity: "Whakauruhia koa te rahinga utu",
    pleaseEnterRewardDescription: "Whakauruhia koa te whakaahuatanga utu",
    fineDining: "Kai pai",
    luxuryFashion: "Huahua Huhua",
    hotels: "Hotera",
    travel: "Haerenga",
    foodAndBeverage: "Kai me te Inu",
    fashion: "Huahua",
    health: "Hauora",
    furniture: "Nga taonga",
    entertainment: "Whakangahau",
    automobile: "Automobile",
    education: "Mātauranga",
    beautyAndSpa: "Ataahua me te Spa",
    staycation: "Nohonga",
    events: "Nga Takahanga",
    trips: "Nga haerenga",
    oilAndGas: "Te hinu me te hau",
    laundry: "horoi horoi",
    partnerCategory: "Kāwai Hoa",
    freeItem: "Kore utu",
  },
  Mongolian: {
    cashier: "кассчин",
    manager: "менежер",
    owner: "эзэн",
    online: "онлайн",
    offline: "офлайн",
    changePassword: "Нууц үгээ өөрчлөх",
    currentPasswordMessage: "Одоогийн нууц үгээ оруулна уу",
    passwordMessage: "Нууц үгээ оруулна уу",
    currentPassword: "Одоогын Нууц Үг",
    password: "нууц үг",
    confirmPasswordMessage: "Нууц үгээ баталгаажуулна уу!",
    confirmPassword: "Нууц үгээ баталгаажуулна уу",
    sendViaEmail: "Имэйлээр илгээх",
    sendViaWhatsapp: "WhatsApp -р илгээх",
    downloadPdf: "PDF татаж авах",
    paid: "төлсөн",
    unpaid: "цалингүй",
    partial: "хэсэгчилсэн",
    closeInvoice: "Та нэхэмжлэхийг хаахыг хүсч байна уу?",
    closeInvoiceConfirmation:
      "Нэхэмжлэхийг хадгалахгүй байж магадгүй. Та хаахыг хүсч байна уу?",
    yes: "тийм ээ",
    no: "үгүй",
    invoice: "Нэхэмжлэх",
    wasDeducted: "хасагдсан",
    for: "-ийн хувьд",
    item: "Зүйл",
    addProduct: "Бүтээгдэхүүн нэмэх",
    paymentReference: "Төлбөрийн лавлагаа",
    amountPaid: "Төлбөр төлсөн дүн",
    discountAmount: "Хөнгөлөлтийн хэмжээ",
    amountDue: "Хүрэх хэмжээ",
    amount: "Хэмжээ",
    dueDate: "Эцсийн хугацаа",
    paymentType: "Төлбөрийн хэлбэр",
    card: "Карт",
    cash: "Бэлэн мөнгө",
    bankTransfer: "Банкны шилжүүлэг",
    paymentMessage: "Төлбөрийн мессеж",
    description: "Тодорхойлолт",
    sendReceipt: "Баримт бичгийг илгээх",
    delete: "Устгах",
    save: "Хадгалах",
    resend: "Дахин илгээх",
    saveAndSend: "Хадгалах",
    invoiceSaved: "Нэхэмжлэхийг хадгалсан!",
    selectPaymentMethod: "Төлбөрийн аргыг сонгоно уу!",
    selectCustomer: "Хэрэглэгчээ сонгоно уу!",
    cartEmptyError:
      "Сагсны жагсаалт хоосон байж болохгүй, нэхэмжлэхийг хааж, зүйлийг сагсанд нэмж оруулаарай!",
    subscriptionExpired:
      "Таны захиалгын хугацаа дууссан бөгөөд таны данс хязгаарлагдмал байна. Дансаа шинэчлэхийн тулд доорх товчлуур дээр дарна уу",
    renew: "Шинэчлэх",
    holdOn: "Түр хүлээгээрэй",
    customerBank: "Харилцагчийн банк",
    cancel: "Цуцлах",
    selectACustomer: "Хэрэглэгчийг сонгоно уу",
    invoiceSuccessfulPdfError:
      "Нэхэмжлэхийг амжилттай үүсгэсэн боловч PDF үүсгэх нь ердийнхөөс илүү удаан үргэлжилж байна. Удахгүй дахин шалгана уу.",
    downloadingInvoice: "Нэхэмжлэхийг татаж авч байна",
    downloadingReceipt: "Баримтыг татаж авч байна",
    whatsappReceiptError:
      "Баримт бичгийг WhatsApp -р илгээхэд алдаа гарлаа. Дахин оролдоно уу.",
    receiptToWhatsapp: "Баримт бичгийг WhatsApp руу илгээсэн",
    thankYouForPatronage: "Ивээн тэтгэсэн танд баярлалаа",
    hereIsYourReceipt: "Энд таны төлбөрийн баримт байна",
    errorSendingEmailReceipt:
      "Баримт бичгийг имэйлээр илгээхэд алдаа гарлаа. Дахин оролдох эсвэл дэмжлэгтэй холбоо барина уу",
    receiptSentToEmail:
      "Баримт бичгийг үйлчлүүлэгчийн имэйл рүү илгээсэн болно",
    invoiceSentToEmail: "Нэхэмжлэхийг үйлчлүүлэгчийн имэйл рүү илгээсэн болно",
    invoiceSuccessWhatsappError:
      "Нэхэмжлэхийг амжилттай үүсгэсэн боловч WhatsApp руу илгээхэд алдаа гарлаа. Нэхэмжлэхийн жагсаалт дээр дахин оролдоно уу",
    invoiceSuccessfulEmailError:
      "Нэхэмжлэхийг амжилттай үүсгэсэн боловч имэйлээр илгээх явцад алдаа гарлаа. Нэхэмжлэхийн жагсаалтаас дахин оролдоно уу",
    invoiceSentToWhatsapp: "Нэхэмжлэхийг WhatsApp руу илгээсэн",
    hello: "Сайн уу",
    pleaseDownloadInvoice: "Нэхэмжлэхийг татаж авна уу",
    pleaseDownloadReceipt: "Баримт бичгийг татаж авна уу",
    from: "-аас",
    email: "Имэйл",
    upgrade: "Шинэчлэх",
    youAreOnFreePlan: "Та үнэгүй төлөвлөгөөтэй байна.",
    clickOn: "Дээр товшино уу",
    yourPlanInFewSteps: " таны захиалгыг хэдхэн алхамаар хийнэ.",
    to: "руу",
    yourSubscriptionHasExpired:
      "Таны захиалгын хугацаа дууссан бөгөөд таны данс хязгаарлагдмал байна.",
    days: "өдрүүд",
    yourSubscriptionExpiresIn: "Таны Loystar захиалгын хугацаа дуусна",
    createAcount: "Бүртгэл үүсгэх",
    signIn: "Нэвтрэх",
    continue: "Үргэлжлүүлэх",
    enterOtp: "OTP PIN оруулна уу",
    enterValidOtp: "Зөв ПИН оруулна уу",
    pin: "ПИН",
    tokenSentToMail: "Таны имэйлд токен илгээсэн байна",
    passwordResetSuccessful: "Нууц үг шинэчлэлт амжилттай боллоо",
    somethingWentWrong: "Ямар нэгэн зүйл буруугаар эргэсэн!",
    resetPassword: "Нууц үгээ анхны байдалд нь оруулж",
    iHaveResetCode: "Надад нууц үг шинэчлэх код байна",
    pleaseEnterEmail: "Имэйлээ оруулна уу",
    aTokenWillBeSentToEmail: "Таны имэйлд токен илгээх болно",
    enterEmail: "Имэйлээ оруулна уу",
    sendinYourToken: "Токеноо илгээж байна ...",
    makeSureItMatchesPassword:
      "Энэ нь таны шинэ нууц үгтэй тохирч байгаа эсэхийг шалгаарай",
    pleaseChooseNewPassword: "Шинэ нууц үг сонгоно уу",
    chooseNewPassword: "Шинэ нууц үг сонгоно уу",
    enterNewPassword: "Баталгаажуулахын тулд шинэ нууц үгээ оруулна уу",
    enterTokenSent: "Таны имэйл илгээсэн жетоныг оруулна уу",
    resetToken: "Токеныг дахин тохируулах",
    resettingPassword: "Нууц үгээ шинэчилж байна ...",
    signUp: "Бүртгүүлэх",
    adminSignInWithEmail:
      " Админ нь имэйлээр нэвтэрдэг бол ажилтнууд хэрэглэгчийн нэрээр нэвтэрдэг.",
    pleaseEnterEmailOrUsername: "Имэйл эсвэл хэрэглэгчийн нэрээ оруулна уу",
    emailOrUsername: "И-мэйл эсвэл Хэрэглэгчийн нэр",
    pleaseEnterPassword: "Нууц үгээ оруулна уу",
    createAnAccount: "Бүртгэл үүсгэх",
    forgotPassword: "Нууц үгээ мартсан?",
    enterPhoneNumber: "Утасны дугаараа оруулна уу",
    personalData: "Хувийн мэдээлэл",
    validateConfirmationCOde: "Баталгаажуулах кодыг баталгаажуулах",
    pleaseEnterFirstName: "Нэрээ оруулна уу",
    pleaseEnterPhoneNumber: "Утасны дугаараа оруулна уу",
    pleaseEnterLastName: "Та овог нэрээ оруулна уу",
    pleaseEnterBusinessName: "Бизнесийнхээ нэрийг оруулна уу",
    firstName: "Нэр",
    lastName: "Овог",
    businessName: "Бизнес нэр",
    previous: "Өмнөх",
    next: "Дараачийн",
    pleaseSelectBusinessCategory: "Бизнесийнхээ ангиллыг сонгоно уу",
    pleaseEnterValidEmail: "Зөв имэйл оруулна уу",
    pleaseEnterPostCode: "Шуудангийн кодыг оруулна уу",
    postCode: "Шуудангийн код",
    phoneNumberInUse: "Энэ утасны дугаар аль хэдийн ашиглагдаж байна!",
    emailInUse: "Энэ имэйлийг аль хэдийн ашиглаж байна!",
    foodAndDrinks: "Хоол хүнс, ундаа",
    salonAndBeauty: "Салон ба гоо сайхан",
    fashionAndAccessories: "Загвар ба дагалдах хэрэгсэл",
    gymAndFitness: "Биеийн тамир, фитнесс",
    travelAndHotel: "Аялал ба зочид буудал",
    homeAndGifts: "Гэр ба бэлэг",
    washingAndCleaning: "Угаах ба цэвэрлэх",
    gadgetsAndElectronics: "Гаджет ба электроник",
    groceries: "Хүнсний бүтээгдэхүүн",
    others: "Бусад",
    submit: "Илгээх",
    accountCreatedSuccessful: "Таны бүртгэл амжилттай боллоо.",
    pleaseEnterAddress: "Та хаягаа оруулна уу",
    addressLine1: "Хаягийн мөр 1",
    addressLine2: "Хаягийн мөр 2",
    choosePassword: "Нууц үг сонгох",
    passwordMustBe6Characters: "Нууц үг хамгийн багадаа 6 тэмдэгт байх ёстой.",
    howDidYouHearLoystar: "Та Loystar -ийн талаар хэрхэн сонссон бэ?",
    referralCode: "лавлах код",
    byClickingTheButton:
      " Доорх товчлуур дээр дарснаар та үүнийг зөвшөөрч байна",
    termsAndSevice: "Нөхцөл",
    wereCreatingAccount: "Бид таны дансыг үүсгэж байна",
    proceed: "Үргэлжлүүлэх",
    verificationCodeMustBe6: "Баталгаажуулах код нь 6 оронтой байх ёстой",
    pleaseEnter6DigitCode: "6 оронтой кодыг оруулна уу",
    enterVerificationCode: "Баталгаажуулах кодыг оруулна уу",
    resendToken: "Токеныг дахин илгээх",
    verify: "Баталгаажуулах",
    transactions: "Гүйлгээ",
    todaySales: "Өнөөдрийн борлуулалт",
    salesHistory: "Борлуулалтын түүх",
    supplyHistory: "Нийлүүлэлтийн түүх",
    new: "Шинэ",
    invoices: "Нэхэмжлэх",
    disbursements: "Төлбөр",
    offlineSales: "Офлайн борлуулалт",
    products: "Бүтээгдэхүүн",
    customers: "Үйлчлүүлэгчид",
    multiLevelLoyalty: "Олон түвшний үнэнч байдал",
    loyaltyPrograms: "Үнэнч байдлын хөтөлбөрүүд",
    members: "Гишүүд",
    appStore: "Апп дэлгүүр",
    orderMenu: "Захиалгын цэс",
    settings: "Тохиргоо",
    staffAndBranches: "Ажилтнууд ба салбарууд",
    myAccount: "Миний данс",
    switchToSellMode: "Борлуулах горим руу шилжих",
    signOut: "Гарах",
    getFreeSubscription: "Үнэгүй захиалга авах",
    onlyNumbersAllowed: "Зөвхөн тоог зөвшөөрнө",
    yourAccountMustBe10Digits: "таны дансны дугаар 10 оронтой тоо байх ёстой",
    yourBvnMustBe11: "Таны BVN нь 11 оронтой тоо байх ёстой",
    pleaseSelectBank: "Банкаа сонгоно уу",
    selectYourBank: "Банкаа сонгоно уу",
    enterBankAccountNumber: "Банкны дансны дугаарыг оруулна уу",
    enterBvnNumber: "BVN оруулна уу",
    connectBankAccount: "Банкны данс холбох",
    passwordResetSuccessfulAndSignOut:
      "Таны Нууц үг амжилттай шинэчлэгдсэн байна. Дахин нэвтрэхийн тулд доорх Гарах товчийг дарна уу",
    enterCurrentPassword: "Одоогийн нууц үгээ оруулна уу",
    pleaseEnterCurrentPassword: "Одоогийн нууц үгээ оруулна уу",
    phoneNumber: "Утасны дугаар",
    sex: "Секс",
    dateOfBirth: "Төрсөн өдөр",
    state: "Муж",
    country: "Улс",
    loyaltyId: "Үнэнч байдлын ID",
    createdAt: "At үүсгэсэн",
    noOfTransactionsMade: "Хийсэн гүйлгээний тоо",
    yourDownloadWillStart: "Таны татан авалт хэсэг хугацааны дараа эхлэх болно",
    exportCustomers: "Үйлчлүүлэгчдийг экспортлох",
    youhaveSuccessfullyToppedUp: "Та SMS нэгжээ амжилттай дүүргэсэн байна.",
    youNowHave: "Та одоо байна",
    smsUnits: "SMS нэгжүүд",
    enterNumberOfSmsUnits: "Худалдан авах гэж буй SMS нэгжийн тоог оруулна уу",
    pleaseEnterANumericValue: "Тоон утгыг оруулна уу",
    pay: "Төлбөр хийх",
    accountEditedSuccessfully: "Таны бүртгэлийг амжилттай засварлав.",
    youAeCurrentlyOn: "Та одоогоор асаалттай байна",
    plan: "Төлөвлөх",
    userData: "Хэрэглэгчийн мэдээлэл",
    businessData: "БИЗНЕСИЙН ДАТА",
    businessCategory: "Бизнесийн Катори",
    pleaseSelectCurrency: "Та валютаа сонгоно уу",
    selectYourCurrency: "Валютаа сонгоно уу",
    purchaseMoreSmsUnits:
      "Доорх маягтыг ашиглан илүү олон SMS нэгж худалдаж аваарай",
    youHave: "Танд байна",
    atLeast4SmsUnitsRrequired:
      "Баталгаажуулахын тулд дор хаяж 4 SMS нэгж шаардлагатай тул цэнэглэнэ үү!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'Хэрэглэгчээ мэдэх' (KYC) шаардлагыг дагаж мөрдөхийн тулд банкны дансаа баталгаажуулна уу. Энэ нь танд USSD эсвэл шууд шилжүүлгээр дамжуулан төлбөр цуглуулах, үйлчлүүлэгчдээс захиалга хүлээн авах, гүйлгээний төлбөр тооцоог хийх боломжийг олгоно. Зөвхөн Нигерийн худалдаачдад хамаарна. Эхлэхийн тулд доорх товчийг дарна уу.",
    reConnectBankAccount: "Банкны дансаа дахин холбоно уу",
    accountName: "Дансны нэр",
    accountNumber: "Дансны дугаар",
    bankName: "Банкны нэр",
    verified: "Баталгаажсан",
    accountDetails: "дансны дэлгэрэнгүй",
    kycBankAccount: "KYC",
    createTier: "Түвшин үүсгэх",
    fileUploadSuccessful: "файлыг амжилттай байршууллаа",
    fileUploadFailed: "файл байршуулж чадсангүй",
    createLoyaltyProgram: "Үнэнч байдлын хөтөлбөрийг бий болгох",
    createLoyalty: "Үнэнч байдлыг бий болгох",
    name: "Нэр",
    loyaltyArtwork: "Үнэнч байдлын уран бүтээл",
    clickToUpload: "Байршуулахын тулд дарна уу",
    amountToPointsRatio: "Онооны харьцаа",
    points: "Оноо",
    recommendedAmountToPointRatio:
      "Санал болгож буй: to 1 -ээс 1 оноо хүртэл. өөрөөр хэлбэл зарцуулсан 1 Наира тутамд таны үйлчлүүлэгчид 1 оноо авдаг",
    pleaseTypeIn: "Оруулна уу",
    toDeleteLoyalty: "энэ үнэнч байдлыг устгах",
    deleteLoyalty: "Үнэнч байдлыг устгах",
    toConfirm: "баталгаажуулах",
    edit: "Засах",
    pointsAwardedSuccessfully: "Амжилттай авсан оноо.",
    enterPointValueToAward:
      "Үйлчлүүлэгчдийг шагнахыг хүсч буй цэгийнхээ утгыг оруулна уу",
    award: "Шагнал",
    awardPointValuesToCustomer: "Үйлчлүүлэгчдэд өгөх онооны үнэ цэнэ",
    enrollMembersToLoyalty: "Лояалтид гишүүдээ бүртгүүлээрэй",
    awardPoints: "Шагналын оноо",
    enroll: "Бүртгүүлэх",
    home: "Гэр",
    loyalty: "Үнэнч байдал",
    enrollCustomers: "Хэрэглэгчдийг бүртгүүлэх",
    selected: "Сонгосон",
    customer: "Үйлчлүүлэгч",
    loyaltyActivationSuccessful: "Үнэнч байдлын идэвхжүүлэлт амжилттай боллоо.",
    loyaltyDeactivationSuccessful:
      "Үнэнч байдлыг идэвхгүй болгох нь амжилттай болсон.",
    viewTier: "Түвшин харах",
    deactivate: "Идэвхгүй болгох",
    activate: "Идэвхжүүлэх",
    actions: "Үйлдлүүд",
    nameOfLoyalty: "Үнэнч байдлын нэр",
    loyaltyStatus: "Үнэнч байдлын байдал",
    numberOfTiers: "Давхаргын тоо",
    createdOn: "Үүсгэсэн",
    createATier: "Түвшин үүсгэх",
    stopCreatingTierConfirmation:
      "Та шатлал үүсгэхийг зогсоохыг хүсч байна уу?",
    stopEditingTierConfirmation:
      "Та энэ түвшинг засварлахаа зогсоохыг хүсч байна уу?",
    nameOfTier: "Түвшингийн нэр",
    minimumSpendingTarget: "Зарлагын хамгийн бага зорилго",
    maximumSpendingTarget: "Зарлагын хамгийн дээд зорилт",
    minimumSpendingTargetRequired:
      "зардлын хамгийн бага зорилтыг хийх шаардлагатай байна",
    maximumSpendingTargetRequired:
      "зарцуулалтын хамгийн дээд зорилгыг хийх шаардлагатай байна",
    rewardSponsor: "Шагналын ивээн тэтгэгч",
    pleaseChooseARewardSponsor: "Шагналын ивээн тэтгэгчийг сонгоно уу",
    self: "Өөрөө",
    partner: "Түнш",
    rewardPartner: "Шагналын түнш",
    pleaseSelectRewardPartner: "Шагналын хамтрагчаа сонгоно уу",
    rewards: "Шагнал",
    pleaseSelectAReward: "Шагналаа сонгоно уу",
    instructionsOnRedeemingReward:
      "Үйлчлүүлэгч урамшууллыг хэрхэн яаж авах тухай заавар",
    pleaseFillInThisField: "Энэ талбарыг бөглөнө үү!",
    toDeleteThisTier: " энэ давхаргыг устгахын тулд",
    deleteTier: "Давхаргыг устгах",
    viewMembers: "Гишүүдийг харах",
    membersEnrolled: "Гишүүд бүртгүүллээ",
    instruction: "Зааварчилгаа",
    membersIn: "Гишүүд",
    availableTiersInLoyalty:
      "Үнэнч байдлын хөтөлбөрт хамрагдах боломжтой түвшин",
    tiers: "Давхаргууд",
    totalTier: "НИЙТ ШАТ",
    availableLoyaltyProgramme: "Боломжит үнэнч байдлын хөтөлбөр",
    totalLoyalties: "НИЙТ ҮНЭНЧЛЭЛ",
    memberDetails: "Гишүүний дэлгэрэнгүй мэдээлэл",
    nameOfCustomer: "Үйлчлүүлэгчийн нэр",
    address: "Хаяг",
    allEnrolledMembers: "Бүртгүүлсэн бүх гишүүд",
    thisIsToWishYouHappyBirthday:
      "Энэ нь танд төрсөн өдрийн мэнд хүргэж, чинээлэг амьдралыг хүсэн ерөөе. Лойстерт өгсөн бүх зүйлд баярлалаа. Баярын мэнд хүргэе!",
    inputAnOfferPlease: "Санал оруулна уу",
    pleaseSelectTheInsertPoint:
      "Зурвас дахь оруулах цэгийг сонгоод дахин дарна уу",
    birthdayOfferAndMessage: "Төрсөн өдрийн санал ба мессеж",
    birthdayOffer: "Төрсөн өдрийн санал",
    birthdayMessage: "Төрсөн өдрийн мессеж",
    noOfferFound: "Санал олдсонгүй",
    settingABirthdayOffer:
      "Төрсөн өдрийн саналыг тохируулах нь үйлчлүүлэгчид төрсөн өдрөөрөө энэ саналыг SMS -ээр хүлээн авах боломжийг олгодог",
    createOffer: "Санал үүсгэх",
    whatIsTheOffer: "Ямар санал байна вэ?",
    editMessage: "Мессежийг засах",
    insert: "Оруулах",
    theNameOfCustomerInserted: "Энд үйлчлүүлэгчийн нэрийг оруулах болно",
    theBirtdayOfferInserted: "Төрсөн өдрийн саналыг энд оруулах болно",
    youSuccessfullyAddedNewBranch: "Та шинэ салбараа амжилттай нэмлээ!",
    addNewBranch: "Шинэ салбар нэмэх",
    addBranch: "Салбар нэмэх",
    additionalBranchWillIncur: "Нэмэлт салбар бий болно",
    perBranch: "салбар бүрт",
    ecommerceBranchCosts: "Цахим худалдааны салбарын зардал",
    pleaseEnterBranchName: "Салбарын нэрийг оруулна уу",
    pleaseEnterBranchAddress1: "Салбарын хаягийн мөр 1 -ийг оруулна уу",
    enterBranchAddress1: "Салбарын хаягийн мөр 1 -ийг оруулна уу",
    enterBranchAddress2: "Салбарын хаягийн мөр 1 -ийг оруулна уу",
    pleaseEnterBranchAddress2: "Салбарын хаягийн 2 -р мөрийг оруулна уу",
    enterBranchName: "Салбарын нэрийг оруулна уу",
    successfullyAddedStaff: "Та шинэ ажилтнуудаа амжилттай нэмлээ!",
    addNewStaff: "Шинэ ажилтан нэмэх",
    addStaff: "Ажилтнуудыг нэмэх",
    additionalStaffWillIncur: "Нэмэлт ажилтан авах шаардлагатай болно",
    perStaff: "ажилтан бүрт.",
    pleaseEnterStaffEmail: "Ажилтнуудын имэйлийг оруулна уу",
    pleaseEnterStaffUsername: "Ажилтнуудын хэрэглэгчийн нэрийг оруулна уу",
    pleaseEnterStaffPassword: "Ажилтнуудын нууц үгийг оруулна уу",
    pleaseSelectStaffRole: "Ажилтнуудын үүргийг сонгоно уу",
    selectStaffRole: "Ажилтнуудын үүргийг сонгоно уу",
    enterStaffEmail: "Ажилтнуудын имэйлийг оруулна уу",
    enterStaffUsername: "Ажилтнуудын хэрэглэгчийн нэрийг оруулна уу",
    enterStaffPassword: "Ажилтнуудын нууц үгийг оруулна уу",
    spacesNotAllowedInUsername: "хэрэглэгчийн нэрээр зөвшөөрөгдөөгүй орон зай",
    admin: "админ",
    pleaseSelectBusinessToAttachStaff:
      "Ажилтнуудаа холбохын тулд бизнесээ сонгоно уу",
    searchForBranchToAttachStaff: "Ажилтнуудаа холбох салбар хайх",
    username: "Хэрэглэгчийн нэр",
    role: "Үүрэг",
    areYouSureToDeleteThis: "Та үүнийг устгахдаа итгэлтэй байна уу",
    branches: "Салбарууд",
    upgradeYourPlan: "Төлөвлөгөөгөө сайжруулах.",
    add: "НЭМЭХ",
    addNew: "Шинээр нэмэх",
    customerWithEmailAlreadyExists:
      "Имэйл/утасны дугаартай хэрэглэгч аль хэдийн байна!",
    successfullyAddedNewCustomer: "Та шинэ үйлчлүүлэгчээ амжилттай нэмлээ!",
    addCustomer: "Хэрэглэгч нэмэх",
    pleaseEnterCustomerFirstName: "Үйлчлүүлэгчийн нэрийг оруулна уу",
    pleaseEnterCustomerLastName: "Үйлчлүүлэгчийн овог нэрийг оруулна уу",
    pleaseEnterCustomerPhoneNumber: "Үйлчлүүлэгчийн утасны дугаарыг оруулна уу",
    pleaseEnterCustomerEmail: "Хэрэглэгчийн имэйлийг оруулна уу",
    pleaseEnterCustomerAddressLine: "Хэрэглэгчийн хаягийн мөрөнд оруулна уу",
    pleaseEnterCustomerOtherAddress: "Үйлчлүүлэгчийн өөр хаягийг оруулна уу",
    pleaseSelectCustomerGender: "Үйлчлүүлэгчийн хүйсийг сонгоно уу",
    gender: "Хүйс",
    male: "Эр",
    female: "Эмэгтэй",
    bank: "Банк",
    selectBank: "Банк сонгоно уу",
    stateOrRegionOrProvince: "Муж/Бүс/Аймаг",
    customerNotes: "Хэрэглэгчийн тэмдэглэл",
    sendSms: "SMS илгээх",
    editCustomer: "Хэрэглэгчийг засах",
    redeemReward: "Шагнал авах",
    issueLoyaltyCard: "Үнэнч байдлын карт гаргах",
    deleteCustomer: "Хэрэглэгчийг устгах",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Та үнэнч гишүүнчлэлийн ID -г амжилттай хуваариллаа",
    noMembershipIdAvailable:
      "Гишүүнчлэлийн ID байхгүй байна. Бидэнтэй hello@loystar.co хаягаар холбоо барина уу",
    sendEmail: "И-мэйл илгээх",
    membershipPoints: "Гишүүнчлэлийн оноо",
    customerDetails: "Хэрэглэгчийн дэлгэрэнгүй мэдээлэл",
    close: "Хаах",
    loyaltyBalance: "Үнэнч байдлын тэнцэл",
    pointsBalance: "Оноо тэнцэл",
    starBalance: "Оддын тэнцвэр",
    requiredPoints: "Шаардлагатай оноо",
    requiredStars: "Шаардлагатай одууд",
    reddemCode: "Кодыг ашиглах",
    toDeleteThisCustomer: "энэ үйлчлүүлэгчийг устгах",
    customerHasBeenDeletedSuccessfully: "Үйлчлүүлэгчийг амжилттай устгалаа!",
    customerWithPhoneAlreadyExists:
      "Утасны дугаартай хэрэглэгч аль хэдийн байна",
    customerWasSuccessfullyEdited: "Үйлчлүүлэгчийг амжилттай засварлав",
    anErrorOccured: "Алдаа гарлаа",
    phoneNumberIncludeCountry: "Утасны дугаар (Улсын кодыг оруулна уу)",
    yourFileQueuedForUpload:
      "Таны файлыг байршуулахаар дараалалд орууллаа. Хэдэн секундын дараа хуудсыг дахин сэргээнэ үү.",
    thereWasAnErrorPerformingOperation: "Үйлдлийг гүйцэтгэхэд алдаа гарлаа!",
    pleaseSelectFile: "Файл сонгоно уу!",
    oopsOnlyCsvFilesAllowed:
      "Өө! Зөвхөн CSV файлыг зөвшөөрдөг. .Csv файлыг байршуулна уу.",
    fileShouldBeSmallerThan5Mb:
      "Файлын хэмжээ 5 MB -аас бага байх ёстой. Хэрэв танд илүү том файл байгаа бол support@loystar.co руу имэйлээр илгээнэ үү. Баярлалаа",
    customerFirstNameIsRequired:
      "үйлчлүүлэгчийн нэрийг эгнээнд оруулах шаардлагатай",
    customerPhoneNumberIsRequired:
      "хэрэглэгчийн утасны дугаарыг дараалан оруулах шаардлагатай",
    importCustomers: "Хэрэглэгчдийг импортлох",
    upload: "Байршуулах",
    clickIconToDownloadCsv:
      "CSV файлын загварыг татаж авахын тулд энэ дүрс дээр дарна уу.",
    getGoogleSheetVersion: "Google хуудасны хувилбарыг татаж аваарай",
    clickOrDragFileToUpload:
      "Файлыг байршуулахын тулд энэ хэсэгт дарна уу эсвэл чирнэ үү",
    missingOrInvalidColumnHeader:
      "Баганын толгой алга эсвэл буруу байна. Загварын форматыг дагаж мөрдөөрэй. Баярлалаа.",
    youHaveImported: "Та импорт хийсэн",
    youSuccessfullyRedeemedReward: "Та шагналаа амжилттай гэтэлгэлээ!",
    sixDigitCode: "Зургаан оронтой код",
    pleaseEnterCustomerRewardCode:
      "Үйлчлүүлэгчийн урамшууллын кодыг оруулна уу",
    enterRewardCode: "Шагналын кодыг оруулна уу.",
    selectLoyaltyProgram: "Үнэнч байдлын хөтөлбөрийг сонгоно уу",
    stamps: "Марк",
    smsUnitsLow: "SMS нэгж бага байна",
    pleaseTopUpSmsUnits: "SMS нэгжийг цэнэглэнэ үү",
    smsSuccessfullySentForDelivery: "SMS амжилттай илгээгдлээ!",
    sendSmsTo: "SMS илгээх",
    allCustomers: "Бүх үйлчлүүлэгчид",
    unitsAvailable: "Боломжит нэгжүүд",
    pleaseTypeInTheMessage: "Зурвасыг оруулна уу",
    message: "Захиа",
    charactersRemaining: "үлдсэн тэмдэгтүүд",
    avoidUseOfSpecialCharacters:
      "SMS нэгжийг хадгалахын тулд тусгай тэмдэгт, эможи ашиглахаас зайлсхий.",
    note: "Тэмдэглэл",
    errorFetchingCustomersMultilevelDetail:
      "Олон түвшний дэлгэрэнгүй мэдээллийг худалдан авагчдад хүргэхэд алдаа гарлаа",
    search: "Хайх",
    reset: "Дахин тохируулах",
    importCustomer: "Хэрэглэгчийг импортлох",
    addNewCustomer: "Шинэ үйлчлүүлэгч нэмэх",
    sendSmsBroadcast: "SMS өргөн нэвтрүүлэг илгээх",
    totalCustomers: "НИЙТ ХЭРЭГЛЭГЧИД",
    disbursementDetails: "Төлбөрийн дэлгэрэнгүй",
    paidBy: "Төлбөр төлсөн",
    disbursed: "Төлсөн",
    bankAccountName: "Банкны дансны нэр",
    bankAccountNumber: "Банкны дансны дугаар",
    transferInitiated: "Шилжүүлгийг эхлүүлсэн",
    transferCompleted: "Шилжүүлэг дууссан",
    pleaseEnterAValid: "Хүчинтэй оруулна уу",
    begin: "Эхлэх",
    end: "Төгсгөл",
    date: "огноо",
    paymentDate: "Төлбөрийн хугацаа",
    selectDisbursementDuration: "Төлбөрийн үргэлжлэх хугацааг сонгоно уу",
    totalSettled: "Нийт суурьшсан",
    totalUnsettled: "Нийт тогтворгүй байна",
    toDeleteThisSale: "энэ борлуулалтыг устгах",
    draftSaleDeletedSuccessfully: "Нооргийн худалдаа амжилттай устлаа!",
    deleteSale: "Борлуулалтыг устгах",
    pleaseTypeInYourTotal:
      "Устгасан гэдгээ баталгаажуулахын тулд нийт дүнг оруулна уу",
    billDetails: "Билл дэлгэрэнгүй",
    printBill: "Биллийг хэвлэх",
    servedBy: "Үйлчилсэн",
    total: "Нийт",
    createdDate: "Үүсгэсэн огноо",
    createdTime: "Бүтээсэн цаг",
    status: "Статус",
    loginSuccessful: "Нэвтрэлт амжилттай боллоо",
    pleaseWaitWhileWeConnectAccount: "Таны дансыг холбох хүртэл хүлээнэ үү",
    connectionFailedTryAgain: "Холболт амжилтгүй болсон. Дахин оролдоно уу.",
    connectionSuccessful: "Холболт амжилттай боллоо",
    viewDetails: "Дэлгэрэнгүй мэдээллийг харах",
    enable: "Идэвхжүүлэх",
    free: "Үнэгүй",
    cost: "Зардал",
    visitWebsite: "Вэбсайтад зочлох",
    pleaseUpgradeYourPlanToPro:
      "Энэ програмыг идэвхжүүлэхийн тулд төлөвлөгөөгөө Pro Plus болгон сайжруулна уу",
    connectYourBankAccount:
      "Төлбөр хүлээн авахын тулд банкны дансаа холбоно уу.",
    disable: "Идэвхгүй болгох",
    enableApp: "Аппыг идэвхжүүлэх",
    addNewProductToInvoice: "Нэхэмжлэх дээр шинэ бүтээгдэхүүн нэмнэ үү",
    toDeleteThisInvoice: "энэ нэхэмжлэхийг устгахын тулд",
    invoiceDeletedSuccessfully: "Нэхэмжлэхийг амжилттай устгалаа!",
    deleteInvoice: "Нэхэмжлэхийг устгах",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Та нэхэмжлэхийн төлбөрийн мессежийг амжилттай нэмлээ!",
    pleaseEnterPaymentInstructions: "Төлбөр хийх зааврыг оруулна уу",
    invoiceId: "Нэхэмжлэхийн ID",
    paidDate: "Төлбөртэй огноо",
    reference: "Лавлагаа",
    productAdded: "Бүтээгдэхүүн нэмсэн",
    productOutOfStock: "Бүтээгдэхүүн дууссан байна. Дахин нөөцлөөрэй.",
    totalInvoices: "НИЙТ НИЙЛЭЛ",
    totalPaidInvoices: "НИЙТ Төлбөртэй нэхэмжлэл",
    totalUnpaidInvoices: "НЭГДСЭН НЭГДСЭН ДУУДЛАГА",
    loyaltyProgramDeleted: "Үнэнч байдлын хөтөлбөрийг устгасан",
    thisLoyaltyProgramDeletedSuccessfully:
      "Энэхүү үнэнч байдлын хөтөлбөрийг амжилттай устгалаа",
    thisLoyaltyProgramEditedSuccessfully:
      "Энэхүү үнэнч байдлын хөтөлбөрийг амжилттай засварлав",
    loyaltyProgramAddedSuccessfully:
      "Үнэнч байдлын хөтөлбөр амжилттай нэмэгдлээ",
    loyaltyProgramEdited: "Үнэнч байдлын хөтөлбөрийг засварлав",
    loyaltyProgramAdded: "Үнэнч байдлын хөтөлбөр нэмэгдсэн",
    loyaltyDetails: "Үнэнч байдлын дэлгэрэнгүй мэдээлэл",
    doYouWantToCloseDialog: "Та энэ харилцах цонхыг хаахыг хүсч байна уу?",
    pleaseEnterLoyaltyName: "Үнэнч байдлынхаа нэрийг оруулна уу",
    programType: "Програмын төрөл",
    pleaseSelectProgramType: "Програмын төрлийг сонгоно уу",
    simplePoints: "Энгийн оноо",
    stampsProgram: "Маркны хөтөлбөр",
    threshold: "Босго",
    pleaseEnterLoyaltyThreshold: "Үнэнч байдлын босгыг оруулна уу",
    reward: "Шагнал",
    pleaseEnterLoyaltyReward: "Үнэнч байдлын шагналыг оруулна уу",
    totalUserPoints: "Хэрэглэгчийн нийт оноо",
    totalUserStamps: "Нийт хэрэглэгчийн тамга",
    spendingTarget: "Зарлага зарцуулж байна",
    spendingTargetHint1:
      "Зарцуулах зорилт бол үйлчлүүлэгч шагнал авахын тулд хичнээн их мөнгө зарцуулах ёстойг хэлнэ. 1 валютын үнэ = 1 оноо.",
    spendingTargetHint2:
      "Маркны зорилт бол шагнал авахын тулд үйлчлүүлэгч хэдэн тамга цуглуулах ёстойг хэлнэ. жишээ нь. 5",
    addNewLoyaltyProgram: "Шинэ үнэнч байдлын хөтөлбөрийг нэмнэ үү",
    addLoyaltyProgram: "Үнэнч байдлын хөтөлбөрийг нэмнэ үү",
    loyaltyProgramType: "Үнэнч байдлын хөтөлбөрийн төрөл",
    pleaseSelectLoyaltyProgramType:
      "Үнэнч байдлын хөтөлбөрийн төрлийг сонгоно уу",
    nameOfProgram: "Хөтөлбөрийн нэр",
    pleaseEnterProgramName: "Програмын нэрийг оруулна уу",
    whatIsTheReward: "Шагнал гэж юу вэ?",
    egNextPurchaseIsFree: "Жишээлбэл Дараагийн худалдан авалт үнэгүй",
    customerName: "Хэрэглэгчийн нэр",
    guestCustomer: "Зочин үйлчлүүлэгч",
    orderConfirmedSuccessfully: "Захиалга амжилттай баталгаажлаа",
    orderCancelledSuccessfully: "Захиалга амжилттай цуцлагдлаа",
    confirmOrder: "Захиалгыг зөвшөөрөх",
    cancelOrder: "Захиалгыг цуцлах",
    allOrders: "Бүх захиалга",
    totalOrders: "НИЙТ ЗАХИАЛГА",
    doYouWantToAcceptOrder: "Та энэ захиалгыг хүлээн авахыг хүсч байна уу?",
    doYouWantToCancelOrder: "Та энэ захиалгыг цуцлахыг хүсч байна уу?",
    orderDetails: "захиалгын мэдээлэл",
    orderCreatedAt: "Дараах хаягаар үүсгэсэн захиалга",
    supplier: "Нийлүүлэгч",
    productName: "Бүтээгдэхүүний нэр",
    quantity: "Тоо хэмжээ",
    unitPrice: "Нэгжийн үнэ",
    receivedBy: "Хүлээн авсан",
    reportFrom: "-Аас мэдээлэх",
    totalSupplies: "Нийт нийлүүлэлт",
    allRightsReserved: "Бүх эрх хуулиар хамгаалагдсан",
    toDeleteThisTransaction: "Энэ гүйлгээг устгахын тулд",
    transactionDeletedSuccessfully:
      "Гүйлгээг амжилттай устгалаа. Хувьцааг бараа материалд буцааж өгсөн.",
    deleteTransaction: "Гүйлгээг устгах",
    transactionDetails: "Гүйлгээний дэлгэрэнгүй мэдээлэл",
    printReceipt: "Баримт бичгийг хэвлэх",
    channel: "Суваг",
    discount: "Хөнгөлөлт",
    profit: "Ашиг",
    discountedSales: "Хямдралтай худалдаа",
    errorFetchingRecord: "Бичлэгийг татаж авахад алдаа гарлаа",
    exportTransactions: "Экспортын гүйлгээ",
    errorFetchingSalesRecord:
      "Экспорт хийх борлуулалтын бичлэгийг авахад алдаа гарлаа.",
    totalSellingPrice: "Борлуулалтын нийт үнэ",
    totalCostPrice: "Зардлын нийт үнэ",
    appliedDiscount: "Хэрэглэсэн хөнгөлөлт",
    noOfItems: "Зүйлийн тоо",
    sales: "Борлуулалт",
    export: "Экспортлох",
    totalProfit: "Нийт ашиг",
    totalBalanceInPeriod: "Тухайн үеийн нийт үлдэгдэл",
    allTimeSales: "Бүх цаг үеийн борлуулалт",
    records: "Бичлэгүүд",
    todaysSales: "Өнөөдрийн борлуулалт",
    transaction: "гүйлгээ",
    youHaveExceededTotalNumberOfProducts:
      "Та төлөвлөгөөндөө зөвшөөрөгдсөн нийт бүтээгдэхүүний тооноос хэтэрсэн байна. Илүү өндөр хязгаар авахын тулд төлөвлөгөөгөө сайжруулаарай.",
    youNeedToHaveLoyaltyProgram:
      "Энэ функцийг ашиглахын тулд танд үнэнч байх хөтөлбөр хэрэгтэй!",
    price: "Үнэ",
    category: "Ангилал",
    stockTracking: "Хувьцааны хяналт",
    stockCount: "Хувьцааны тоо",
    taxed: "Татвартай",
    originalPrice: "Жинхэнэ үнэ",
    costPrice: "Зардлын үнэ",
    unit: "Нэгж",
    productImage: "Бүтээгдэхүүний зураг",
    taxRate: "Татварын хувь хэмжээ",
    taxType: "Татварын төрөл",
    trackInventory: "Бараа материалын тооллого",
    variants: "Хувилбарууд",
    hasVariants: "Хувилбартай",
    importProduct: "Бүтээгдэхүүн импортлох",
    exportProducts: "Бүтээгдэхүүн экспортлох",
    addNewProduct: "Шинэ бүтээгдэхүүн нэмэх",
    viewCategory: "Ангилал үзэх",
    viewSuppliers: "Нийлүүлэгчдийг үзэх",
    receiveInventory: "Бараа материал хүлээн авах",
    printAllProductsTag: "Бүх бүтээгдэхүүний шошгыг хэвлэх",
    deleteAll: "Бүгдийг устгах",
    totalProducts: "НИЙТ БҮТЭЭГДЭХҮҮН",
    youveSuccessfullyAddedANewCategory: "Та шинэ категорийг амжилттай нэмлээ",
    addNewCategory: "Шинэ ангилал нэмэх",
    addCategory: "Ангилал нэмэх",
    categoryName: "Ангиллын нэр",
    pleaseEnterCategoryName: "Ангиллын нэрийг оруулна уу",
    stampsTarget: "Зорилтот тамга",
    sendInventory: "Бараа материал илгээх",
    productDetails: "Бүтээгдэхүүний дэлгэрэнгүй мэдээлэл",
    youveSuccessfullyEditedThisCategory: "Та энэ ангиллыг амжилттай засварлав",
    update: "Шинэчлэх",
    categoryList: "Ангиллын жагсаалт",
    categories: "Ангилалууд",
    enterQuantityToUpdate: "Шинэчлэх хэмжээг оруулна уу",
    inventorySentSuccessfully: "Бараа материалыг амжилттай илгээсэн!",
    updateInventory: "Бараа материалыг шинэчлэх",
    currentQuantity: "Одоогийн тоо хэмжээ",
    pleaseEnterQuantityToAdd: "Нэмэхийг хүсч буй тоо хэмжээг оруулна уу",
    pleaseSelectABranch: "Та салбараа сонгоно уу",
    searchForBranch: "Салбар хайх",
    youCantSendMoreThanStock:
      "Та нөөцөд байгаа хэмжээнээсээ илүүг илгээх боломжгүй",
    send: "Илгээх",
    pleaseEnterQuantityToSend: "Илгээхийг хүсч буй тоо хэмжээг оруулна уу",
    productNameIsRequiredOnRow:
      "бүтээгдэхүүний нэрийг мөрөнд оруулах шаардлагатай",
    productCategoryIsRequiredOnRow:
      "бүтээгдэхүүний ангилал нь эгнээнд байх шаардлагатай",
    productPriceIsRequiredOnRow:
      "бүтээгдэхүүний үнэ дараалсан байх шаардлагатай",
    productUnitIsRequiredOnRow:
      "бүтээгдэхүүний нэгжийг эгнээнд оруулах шаардлагатай",
    productQuantityIsRequiredOnRow:
      "Бүтээгдэхүүний тоо хэмжээг дараалан авах шаардлагатай",
    productVariantsRequireTracking:
      "Бүтээгдэхүүний хувилбаруудыг хянах шаардлагатай!",
    pleaseAddVariantClickButton: "Нэмэх товчийг дарж хувилбарыг нэмнэ үү!",
    totalVariantsMoreThanSelectedQuantity:
      "Нийт хувилбарууд нь сонгосон бүтээгдэхүүний хэмжээнээс их байдаг тул Pls хувилбарын хэмжээг багасгадаг",
    productEditedSuccessfully: "Бүтээгдэхүүнийг амжилттай засварлав!",
    fileTooLargeLessThan4Mb:
      "Файлын хэмжээ хэт том байна! Файлын хэмжээ 4 MB -аас бага байх ёстой.",
    suchVariantAlreadyExist: "Ийм хувилбар аль хэдийн бий болсон",
    addVariants: "Хувилбаруудыг нэмнэ үү",
    editProduct: "Бүтээгдэхүүнийг засах",
    pleaseEnterProductName: "Бүтээгдэхүүний нэрийг оруулна уу",
    pleaseEnterProductPrice: "Бүтээгдэхүүний үнийг оруулна уу",
    pleaseEnterProductOriginalPrice: "Бүтээгдэхүүний анхны үнийг оруулна уу",
    productDescription: "Бүтээгдэхүүний тодорхойлолт",
    selectProductCategory: "Бүтээгдэхүүний ангиллыг сонгоно уу",
    pleaseSelectProductCategory: "Бүтээгдэхүүний ангиллыг сонгоно уу",
    productCostPrice: "Бүтээгдэхүүний өртөг",
    productSellingPrice: "Бүтээгдэхүүний борлуулалтын үнэ",
    productOriginalPrice: "Бүтээгдэхүүний анхны үнэ",
    maxFileSizeAllowedIs4Mb:
      "Хамгийн их Зөвшөөрөгдсөн файлын хэмжээ 4 MB байна",
    productsWithPicturesArePublished:
      "Захиалга авахын тулд зурагтай бүтээгдэхүүнийг Discover дээр нийтэлдэг",
    shouldThisProductBeTracked: "Энэ бүтээгдэхүүнийг хянах шаардлагатай юу?",
    pleaseSelectStockUnit: "Хувьцааны нэгжийг сонгоно уу",
    stockUnit: "Хувьцааны нэгж",
    bag: "Цүнх",
    bottle: "ЛОНХ",
    bunch: "BUNCH",
    can: "БОЛНО",
    carton: "КАРТОН",
    crate: "Крат",
    cup: "ЦОМ",
    dozen: "ДОЗОН",
    gigabytes: "GIGABYTES",
    gram: "ГРАМ",
    kilogram: "КИЛОГРАМ",
    litre: "LITER",
    pack: "БАГЦ",
    pair: "ХОС",
    pieces: "Ширхэг",
    plate: "ХАВТАН",
    tonne: "ТОНН (MT)",
    uNIT: "НЭГДСЭН",
    yard: "YARD",
    pleaseEnterProductQuantity: "Бүтээгдэхүүний тоо хэмжээг оруулна уу",
    productQuantity: "Бүтээгдэхүүний тоо хэмжээ",
    isThisProductTaxed: "Энэ бүтээгдэхүүнд татвар ногдуулдаг уу?",
    selectTaxType: "Татварын төрлийг сонгоно уу",
    pleaseSelectTaxType: "Татварын төрлийг сонгоно уу",
    progressive: "Дэвшилтэт",
    proportional: "Пропорциональ",
    pleaseEnterProductTaxRate:
      "Бүтээгдэхүүний татварын хувь хэмжээг оруулна уу",
    doesProductHaveVariants: "Энэ бүтээгдэхүүн өөр хувилбартай юу?",
    type: "Төрөл",
    value: "Үнэ цэнэ",
    pleaseEnterVariantType: "Хувилбарын төрлийг оруулна уу",
    pleaseEnterVariantValue: "Хувилбарын утгыг оруулна уу",
    pleaseEnterVariantPrice: "Хувилбарын үнийг оруулна уу",
    pleaseEnterVariantQuantity: "Хувилбарын тоог оруулна уу",
    productDeletedSuccessfully: "Бүтээгдэхүүнийг амжилттай устгалаа!",
    categoryDeletedSuccessfully: "Ангилал амжилттай устлаа!",
    toDeleteThisProduct: "энэ бүтээгдэхүүнийг устгахын тулд",
    invalidProductQuantity: "Бүтээгдэхүүний тоо хэмжээ буруу байна",
    quantityAddedIsOverStock:
      "Таны нэмж буй тоо хэмжээ таны нөөцөнд байгаа хэмжээнээс давсан байна.",
    itemInventoryNotTracked: "Барааны тооллогыг хянадаггүй",
    addBulkQuantity: "Бөөн тоо хэмжээг нэмэх",
    enterBulkQuantity: "Бөөн тоо хэмжээг оруулна уу",
    pleaseEnterBulkQuantity: "Бөөн тоо хэмжээг оруулна уу",
    youveSuccessfullyAddedANewProduct:
      "Та шинэ бүтээгдэхүүнээ амжилттай нэмлээ!",
    pleaseEnterProductSellingPrice: "Бүтээгдэхүүний борлуулах үнийг оруулна уу",
    doYouWantToTrackProductStock:
      "Бүтээгдэхүүний нөөцийг хянахыг хүсч байна уу?",
    sellingPrice: "Худалдах үнэ",
    setProductExpiryReminder: "Бүтээгдэхүүний дуусах хугацааг сануулах",
    productExpiryDate: "Бүтээгдэхүүний дуусах хугацаа",
    startRemindingFrom: "Сануулж эхэл",
    productSuppliesAddedSuccessfully:
      "Та бүтээгдэхүүний хангамжийг амжилттай нэмлээ.",
    addProductSupplies: "Бүтээгдэхүүний хангамж нэмэх",
    pleaseSelectSupplier: "Нийлүүлэгчийг сонгоно уу",
    nameOfProduct: "Бүтээгдэхүүний нэр",
    pleaseSelectProduct: "Бүтээгдэхүүнийг сонгоно уу",
    productVariant: "Бүтээгдэхүүний хувилбар",
    pleaseSelectAVariant: "Хувилбарыг сонгоно уу",
    pleaseEnterUnitPrice: "Нэгжийн үнийг оруулна уу",
    businessBranch: "Бизнесийн салбар",
    pleaseSelectBranch: "Та салбараа сонгоно уу",
    youveSuccessfullyAddedANewSupplier: "Та шинэ нийлүүлэгчээ амжилттай нэмлээ",
    addSupplier: "Нийлүүлэгчийг нэмэх",
    pleaseEnterSupplierEmail: "Нийлүүлэгчийн имэйлийг оруулна уу",
    pleaseAddADescription: "Тодорхойлолт оруулна уу",
    anErrorOccuredProductsDeleted:
      "Үйлдлийг гүйцэтгэх явцад алдаа гарлаа. Энэ явцад цөөн хэдэн бүтээгдэхүүнийг устгасан байж болзошгүйг анхаарна уу",
    bulkDelete: "Бөөнөөр устгах",
    youAreAboutToDelete: "Та устгах гэж байна",
    product: "Бүтээгдэхүүн",
    isDueToRedeem: "гэтэлгэхтэй холбоотой юм",
    aReward: "шагнал",
    pleaseSelectCustomerToReeemReward:
      "Шагнал авахын тулд үйлчлүүлэгчээ сонгоно уу.",
    youHaveNoLoyaltyProgramRunning:
      "Танд идэвхтэй үнэнч байдлын хөтөлбөр байхгүй байна",
    customerHhasNoPointsInLoyaltyProgram:
      "Энэхүү үнэнч байдлын хөтөлбөрт үйлчлүүлэгч ямар ч оноо аваагүй болно",
    proceedWithPayment: "Төлбөрөө үргэлжлүүлэх үү?",
    youAreAboutToPayForTransactionUsingPoints:
      "Та гүйлгээний төлбөрийг оноо ашиглан төлөх гэж байна.",
    customerHas: "Үйлчлүүлэгч байна",
    worth: "үнэ цэнэтэй",
    andIsNotSufficientToPayForTransaction:
      "бөгөөд энэ гүйлгээг төлөхөд хангалтгүй юм. Тэд өөр төлбөрийн хэрэгслийг ашиглан үлдэгдлийг нэмэхийг хүсч байна уу?",
    addCustomerLoyalty: "Хэрэглэгчийн үнэнч байдлыг нэмэх",
    pleaseAddCustomerFirst: "Эхлээд үйлчлүүлэгчээ нэмж эсвэл сонгоно уу.",
    pleaseWaitWhileWeGetReady: "Биднийг бэлэн болтол хүлээнэ үү",
    lowStock: "Бага нөөц",
    pleaseEnterAmountTendered: "Тендерт хамрагдах дүнг оруулна уу",
    areYouSureToBookSaleOffline:
      "Та энэхүү хямдралаа офлайнаар захиалахдаа итгэлтэй байна уу?",
    saleWouldBeBookedAutomatically:
      "Та интернетээ асаахад борлуулалтыг автоматаар захиалах болно",
    offlineSalesBookingCancelled: "Офлайн борлуулалтын захиалгыг цуцалсан",
    covid19Message:
      "COVID19: Тархалтыг зогсоохын тулд гараа савангаар угаах эсвэл ариутгана. Үргэлж аюулгүй байгаарай",
    saleSuccessfullyRecorded: "Хямдрал амжилттай бүртгэгдлээ!",
    sendReceiptToEmail: "Баримт бичгийг имэйл рүү илгээх",
    sendThankYouSms: "Баярлалаа SMS илгээнэ үү",
    sendShippingDetails: "Хүргэлтийн дэлгэрэнгүй мэдээллийг илгээнэ үү",
    addLoyalty: "Үнэнч байдлыг нэмэх",
    searchCustomerNameOrNumber: "Үйлчлүүлэгчийн нэр эсвэл дугаарыг хайж олох",
    clickTheSearchCustomerBox:
      "Хэрэглэгчийн хайлт хийх хайрцаг болон картыг хайх дээр дарна уу",
    enterProductPrice: "Бүтээгдэхүүний үнийг оруулна уу",
    enterPriceFor: "Үнийг оруулна уу",
    searchForProduct: "Бүтээгдэхүүн хайх",
    all: "Бүгд",
    backToDashboard: "Хяналтын самбар руу буцах",
    viewDraftSales: "Ноорог борлуулалтыг үзэх",
    variantSelected: "хувилбарыг сонгосон",
    variant: "хувилбар",
    thePreviousVariantSelectionNotAvailable:
      "Өмнөх хувилбарын сонголтыг үнэд үндэслэн сонгосон шинэ хувилбарт ашиглах боломжгүй тул сонголтоо өөрчилнө үү.",
    pleaseSupplyPositiveQuantityNumber: "Пости тоо хэмжээний тоог оруулна уу",
    lowStockFor: "Хувьцаа багатай",
    clearVariants: "Тодорхой хувилбарууд",
    pleaseEnterQuantity: "Тоо хэмжээг оруулна уу",
    picture: "Зураг",
    redemptionToken: "Аврах токен",
    token: "Токен",
    totalSpent: "Нийт зарцуулсан",
    confirmPayment: "Төлбөрөө баталгаажуулна уу",
    hasPaymentBeenMade: "Төлбөр амжилттай хийгдсэн үү?",
    enterTransactionReference: "Төлбөр хийсэн гүйлгээний лавлагаа оруулна уу",
    pleaseSelectACustomer: "Та үйлчлүүлэгчээ сонгоно уу!",
    areYouSureToApplyDiscount: "Та хөнгөлөлт үзүүлэхдээ итгэлтэй байна уу?",
    addYourBankAccountToEnableUssd:
      "UPay -ээр USSD шилжүүлгийг идэвхжүүлэхийн тулд банкны дансаа нэмнэ үү",
    totalAmountToPay: "Төлбөр хийх нийт дүн",
    doYouWantToCancelTransaction: "Та энэ гүйлгээг цуцлахыг хүсч байна уу?",
    savePrintBill: "Биллийг хадгалах/хэвлэх",
    enterAmountCollectedForCustomer:
      "Үйлчлүүлэгчээс цуглуулсан дүнг оруулна уу",
    recordSale: "Бичлэгийн худалдаа",
    checkOutWith: "-Тай хамт үзээрэй",
    instantTransfer: "Шууд дамжуулалт",
    dialTheUSSDCode: "USSD кодыг залгаарай",
    pleaseSelectABank: "Банк сонгоно уу",
    payWithUSSD: "USSD ашиглан төлбөр хийх",
    sendBillTo: " - Билл рүү илгээх",
    waitingForUSSDTransfer: "USSD шилжүүлгийг хүлээж байна",
    percent: "Хувь",
    applyDiscount: "Хөнгөлөлт үзүүлэх",
    thisBillHasBeenSaved: "Энэ Билл хадгалагдлаа",
    saveDraft: "Ноорог Хадгалах",
    pleaseTypeANameToIdentifyCustomer:
      "Үйлчлүүлэгчийг тодорхойлохын тулд нэрээ бичнэ үү",
    paymentDetails: "Төлбөрийн мэдээлэл",
    basePrice: "Үндсэн үнэ",
    staff: "Ажилтнууд",
    subTotal: "Нийт дүн",
    durationMonths: "Үргэлжлэх хугацаа (сар)",
    selectADuration: "Үргэлжлэх хугацааг сонгоно уу",
    oneMonth: "1 сар",
    twoMonths: "2 сар",
    threeMonths: "3 сар",
    sixMonths: "6 сар",
    twelveMonths: "12 сар",
    eighteenMonths: "18 сар",
    twentyFourMonths: "24 сар",
    twoMonthsFree: "(2 сар үнэгүй)",
    threeMonthsFree: "(3 сар үнэгүй)",
    fiveMonthsFree: "(5 сар үнэгүй)",
    yourAccountHasBeen: "Таны данс байсан",
    renewed: "шинэчлэгдсэн",
    upgraded: "сайжруулсан",
    successfully: "амжилттай",
    yourSubscription: "Таны захиалга",
    youAreCurrentlyEnrolledOnA: "Та одоогоор a дээр бүртгүүлж байна",
    pleaseChooseAPaymentOption: "Төлбөрийн сонголтыг сонгоно уу",
    planRenewal: "Шинэчлэлт хийх төлөвлөгөө",
    planUpgrade: "Төлөвлөгөөг сайжруулах",
    pleaseSelectDurationToPayFor: "Та төлөх гэж буй хугацаагаа сонгоно уу",
    staffAccounts: "Ажилтнуудын данс",
    ecommerce: "Цахим худалдаа",
    pleaseSelectAPlan: "Төлөвлөгөөг сонгоно уу",
    includeAddons: "Нэмэлтүүдийг оруулах",
    viewTransactions: "Гүйлгээг үзэх",
    customerHasNoCompletedTansactions: "Үйлчлүүлэгч хараахан дуусаагүй байна",
    branch: "Салбар",
    enterNumberOfEcommerceBranches:
      "Цахим худалдааны салбаруудын тоог оруулна уу",
    enterNumberOfEcommerceBranchesToPay:
      "Та төлөх гэж буй цахим худалдааны салбаруудын тоог оруулна уу",
    ecommerceIntegration: "Цахим худалдааны интеграцчлал",
    enterNumberOfBranches: "Салбарын тоог оруулна уу",
    enterNumberOfAdditionalBranchesToPay:
      "Та төлөх гэж буй нэмэлт салбаруудын тоог оруулна уу",
    enterNumberOfStaffs: "Ажилчдын тоог оруулна уу",
    enterNumberOfStaffsToPayFor: "Таны төлөх гэж буй ажилчдын тоог оруулна уу",
    discountApplies: "Хөнгөлөлт хамаарна",
    starsOnThe: "дээрх одууд",
    offer: "санал болгох",
    get: "Аваарай",
    moreStarsToRedeem: "илүү олон оддыг гэтэлгэх болно",
    taxVat: "Татвар (НӨАТ)",
    callCashierToCompletePayment:
      "Төлбөрөө дуусгахын тулд кассчин руу залгаарай",
    receipt: "Баримт",
    dear: "Эрхэм хүндэт",
    thankYouForYourOrderFindGoods:
      "Захиалга өгсөнд баярлалаа. Дараах барааг тохиролцсоны дагуу нийлүүлнэ үү.",
    shippingNote: "Хүргэлтийн тэмдэглэл",
    enterShippingNote: "Хүргэлтийн тэмдэглэл оруулна уу",
    shippingAddress: "Хүргэлтийн хаяг",
    enterShippingAddress: "Хүргэлтийн хаягийг оруулна уу",
    wellDoneYouAreDueToRedeem: "Сайн хийлээ! Та гэтэлгэх ёстой",
    toGetYourRewardNextVisit:
      "Дараагийн айлчлалаар шагналаа авах болно. Баярлалаа",
    pointsOnThe: "Дээр байгаа оноо",
    morePointsToRedeem: "гэтэлгэх илүү оноо",
    showCode: "Кодыг харуулах",
    toGetYourRewardOnNextVisit:
      "Дараагийн айлчлалаар шагналаа авах болно. Баярлалаа",
    earn: "Орлого олох",
    delivaryNote: "Хүргэлтийн тэмдэглэл",
    draftSales: "Ноорог борлуулалт",
    startDate: "Эхлэх өдөр",
    endDate: "Дуусах огноо",
    orders: "Захиалга",
    checkout: "төлбөр хийх",
    noProductItem: "Бүтээгдэхүүний зүйл байхгүй",
    selectProductImage: "Бүтээгдэхүүний зургийг сонгоно уу",
    selectCountry: "Улсаа сонгоно уу",
    selectRegion: "Муж/бүсийг сонгоно уу",
    printProductTag: "Бүтээгдэхүүний шошгыг хэвлэх",
    transactionReference: "Гүйлгээний лавлагаа",
    Cashier: "Нярав",
    Manager: "Менежер",
    Owner: "Эзэмшигч",
    Admin: "админ",
    addPartners: "Түншүүд нэмэх",
    addNewLoyaltyPartner: "Шинэ үнэнч түнш нэмнэ үү",
    pleaseEnterCompanyName: "Компанийн нэрийг оруулна уу",
    companyName: "Компанийн нэр",
    pleaseEnterCompanyRepName: "Компанийн төлөөлөгчийн нэрийг оруулна уу",
    companyRepName: "Компанийн төлөөлөгчийн нэр",
    pleaseEnterCompanyRepEmail: "Компанийн төлөөлөгчийн имэйлийг оруулна уу",
    companyRepEmail: "Компанийн төлөөлөгчийн имэйл",
    pleaseEnterCompanyRepPhone:
      "Компанийн төлөөлөгчийн утасны дугаарыг оруулна уу",
    companyRepPhone: "Компанийн төлөөлөгчийн утасны дугаар",
    addReward: "Шагнал нэмэх",
    pleaseEnterRewardName: "Шагналын нэрийг оруулна уу",
    rewardName: "Шагналын нэр",
    rewardQuantity: "Шагналын тоо",
    rewardDescription: "Шагналын тодорхойлолт",
    rewardType: "Шагналын төрөл",
    pleaseEnterRewardType: "Шагналын төрлийг оруулна уу",
    pleaseEnterRewardQuantity: "Шагналын хэмжээг оруулна уу",
    pleaseEnterRewardDescription: "Шагналын тайлбарыг оруулна уу",
    fineDining: "Сайхан хооллолт",
    luxuryFashion: "Тансаг зэрэглэлийн загвар",
    hotels: "Зочид буудал",
    travel: "Аялал",
    foodAndBeverage: "Хоол хүнс, ундаа",
    fashion: "Загвар",
    health: "Эрүүл мэнд",
    furniture: "Тавилга",
    entertainment: "Үзвэр үйлчилгээ",
    automobile: "Автомашин",
    education: "Боловсрол",
    beautyAndSpa: "Гоо сайхан, рашаан сувилал",
    staycation: "Зогсолт",
    events: "Үйл явдал",
    trips: "Аялал",
    oilAndGas: "Тос ба хий",
    laundry: "Угаалгын газар",
    partnerCategory: "Түншийн ангилал",
    freeItem: "Үнэгүй зүйл",
  },
  Nepali: {
    cashier: "खजांची",
    manager: "प्रबन्धक",
    owner: "मालिक",
    online: "अनलाइन",
    offline: "अफलाइन",
    changePassword: "पासवर्ड परिवर्तन गर्नुहोस्",
    currentPasswordMessage: "कृपया तपाइँको वर्तमान पासवर्ड प्रविष्ट गर्नुहोस्",
    passwordMessage: "कृपया तपाइँको पासवर्ड प्रविष्ट गर्नुहोस्",
    currentPassword: "वर्तमान पासवर्ड",
    password: "पासवर्ड",
    confirmPasswordMessage: "कृपया तपाइँको पासवर्ड पुष्टि गर्नुहोस्!",
    confirmPassword: "पासवर्ड सुनिश्चित गर्नुहोस",
    sendViaEmail: "ईमेल मार्फत पठाउनुहोस्",
    sendViaWhatsapp: "WhatsApp मार्फत पठाउनुहोस्",
    downloadPdf: "PDF डाउनलोड गर्नुहोस्",
    paid: "तिरेको",
    unpaid: "अवैतनिक",
    partial: "आंशिक",
    closeInvoice: "के तपाइँ इनभ्वाइस बन्द गर्न चाहानुहुन्छ?",
    closeInvoiceConfirmation:
      "बीजक सुरक्षित नहुन सक्छ। के तपाइँ बन्द गर्न चाहानुहुन्छ?",
    yes: "हो",
    no: "छैन",
    invoice: "बीजक",
    wasDeducted: "काटिएको थियो",
    for: "को लागी",
    item: "वस्तु",
    addProduct: "उत्पादन जोड्नुहोस्",
    paymentReference: "भुक्तानी सन्दर्भ",
    amountPaid: "तिरीएको रकम",
    discountAmount: "छुट रकम",
    amountDue: "तिर्नुपर्ने रकम",
    amount: "रकम",
    dueDate: "देय मिति",
    paymentType: "भुक्तानी प्रकार",
    card: "कार्ड",
    cash: "नगद",
    bankTransfer: "बैंक स्थानान्तरण",
    paymentMessage: "भुक्तानी सन्देश",
    description: "विवरण",
    sendReceipt: "रसिद पठाउनुहोस्",
    delete: "मेटाउन",
    save: "सुरक्षित गर्नुहोस्",
    resend: "पुन: पठाउनुहोस्",
    saveAndSend: "सुरक्षित गर्नुहोस्",
    invoiceSaved: "चलानी सुरक्षित भयो!",
    selectPaymentMethod: "कृपया एक भुक्तानी विधि चयन गर्नुहोस्!",
    selectCustomer: "कृपया एक ग्राहक छान्नुहोस्!",
    cartEmptyError:
      "कार्ट सूची खाली हुन सक्दैन, इनभ्वाइस बन्द गर्नुहोस् र कार्टमा आइटम थप्नुहोस्!",
    subscriptionExpired:
      "तपाइँको सदस्यता समाप्त भएको छ र तपाइँको खाता अब सीमित छ। तपाइँको खाता नवीकरण गर्न तलको बटनमा क्लिक गर्नुहोस्",
    renew: "नवीकरण गर्नुहोस्",
    holdOn: "होल्ड",
    customerBank: "ग्राहक बैंक",
    cancel: "रद्द गर्नुहोस्",
    selectACustomer: "ग्राहक चयन गर्नुहोस्",
    invoiceSuccessfulPdfError:
      "इनभ्वाइस सफलतापूर्वक बनाईएको छ तर पीडीएफ जेनेरेसन सामान्य भन्दा लामो समय लाग्दैछ। कृपया चाँडै फिर्ता जाँच गर्नुहोस्।",
    downloadingInvoice: "चलानी डाउनलोड गर्दै",
    downloadingReceipt: "रसिद डाउनलोड गर्दै",
    whatsappReceiptError:
      "एउटा त्रुटि WhatsApp मार्फत रसिद पठाउने भयो, कृपया पुन: प्रयास गर्नुहोस्।",
    receiptToWhatsapp: "रसीद WhatsApp मा पठाइयो",
    thankYouForPatronage: "तपाइँको संरक्षण को लागी धन्यवाद",
    hereIsYourReceipt: "यहाँ तपाइँको भुक्तानी रसिद छ",
    errorSendingEmailReceipt:
      "एउटा त्रुटि ई-मेल को माध्यम बाट रसिद पठाउने भयो, कृपया पुन: प्रयास गर्नुहोस् वा समर्थन लाई सम्पर्क गर्नुहोस्",
    receiptSentToEmail: "रसिद ग्राहकको ईमेल पठाइएको छ",
    invoiceSentToEmail: "बीजक ग्राहकको इमेल पठाइएको छ",
    invoiceSuccessWhatsappError:
      "इनभ्वाइस सफलतापूर्वक बनाईएको छ तर एक त्रुटि WhatsApp मा पठाउने भयो। बीजक सूची मा फेरि प्रयास गर्नुहोस्",
    invoiceSuccessfulEmailError:
      "इनभ्वाइस सफलतापूर्वक बनाईएको छ तर एक त्रुटि ई-मेल को रूप मा पठाउँदा भयो। कृपया बीजक सूची बाट पुन: प्रयास गर्नुहोस्",
    invoiceSentToWhatsapp: "बीजक WhatsApp मा पठाइयो",
    hello: "नमस्कार",
    pleaseDownloadInvoice: "कृपया चालान डाउनलोड गर्नुहोस्",
    pleaseDownloadReceipt: "कृपया रसिद डाउनलोड गर्नुहोस्",
    from: "बाट",
    email: "इमेल",
    upgrade: "अपग्रेड गर्नुहोस्",
    youAreOnFreePlan: "तपाइँ एक नि: शुल्क योजना मा हुनुहुन्छ।",
    clickOn: "थिच्नुस",
    yourPlanInFewSteps: " केहि छिटो चरणहरुमा तपाइँको सदस्यता।",
    to: "लाई",
    yourSubscriptionHasExpired:
      "तपाइँको सदस्यता समाप्त भएको छ र तपाइँको खाता अब सीमित छ।",
    days: "दिनहरु",
    yourSubscriptionExpiresIn: "तपाइँको Loystar सदस्यता मा समाप्त हुन्छ",
    createAcount: "खाता खोल्नुहोस्",
    signIn: "साइन इन गर्नुहोस्",
    continue: "जारी राख्नुहोस्",
    enterOtp: "OTP पिन प्रविष्ट गर्नुहोस्",
    enterValidOtp: "कृपया एक मान्य पिन प्रविष्ट गर्नुहोस्",
    pin: "पिन",
    tokenSentToMail: "एक टोकन तपाइँको ईमेल पठाइएको छ",
    passwordResetSuccessful: "पासवर्ड रिसेट सफल भयो",
    somethingWentWrong: "केहि गलत भयो!",
    resetPassword: "पासवर्ड रिसेट",
    iHaveResetCode: "मसँग पासवर्ड रिसेट कोड छ",
    pleaseEnterEmail: "कृपया तपाइँको ईमेल प्रविष्ट गर्नुहोस्",
    aTokenWillBeSentToEmail: "एक टोकन तपाइँको ईमेल मा पठाइनेछ",
    enterEmail: "तपाइँको ईमेल प्रविष्ट गर्नुहोस्",
    sendinYourToken: "तपाइँको टोकन पठाउँदै ...",
    makeSureItMatchesPassword:
      "सुनिश्चित गर्नुहोस् कि यो तपाइँको नयाँ पासवर्ड संग मेल खान्छ",
    pleaseChooseNewPassword: "कृपया नयाँ पासवर्ड छान्नुहोस्",
    chooseNewPassword: "नयाँ पासवर्ड छान्नुहोस्",
    enterNewPassword:
      "पुष्टि गर्न को लागी तपाइँको नयाँ पासवर्ड प्रविष्ट गर्नुहोस्",
    enterTokenSent: "तपाइँको मेल पठाइएको टोकन प्रविष्ट गर्नुहोस्",
    resetToken: "टोकन रिसेट गर्नुहोस्",
    resettingPassword: "तपाइँको पासवर्ड रिसेट गर्दै ...",
    signUp: "साइन अप",
    adminSignInWithEmail:
      " प्रशासक ईमेल को साथ मा साइन इन गर्दछ जबकि स्टाफ प्रयोगकर्ता नाम संग साइन इन गर्दछ।",
    pleaseEnterEmailOrUsername:
      "कृपया तपाइँको ईमेल वा प्रयोगकर्ता नाम प्रविष्ट गर्नुहोस्",
    emailOrUsername: "ईमेल वा प्रयोगकर्ताको नाम",
    pleaseEnterPassword: "कृपया पासवर्ड प्रविष्ट गर्नुहोस्",
    createAnAccount: "खाता खोल्नुहोस्",
    forgotPassword: "पासवर्ड भुल्नु भयो?",
    enterPhoneNumber: "फोन नम्बर प्रविष्ट गर्नुहोस्",
    personalData: "व्यक्तिगत डेटा",
    validateConfirmationCOde: "पुष्टि कोड मान्य गर्नुहोस्",
    pleaseEnterFirstName: "कृपया तपाइँको पहिलो नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterPhoneNumber: "कृपया आफ्नो फोन नम्बर प्रविष्ट गर्नुहोस्",
    pleaseEnterLastName: "कृपया तपाइँको अन्तिम नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterBusinessName: "कृपया तपाइँको व्यवसाय को नाम प्रविष्ट गर्नुहोस्",
    firstName: "पहिलो नाम",
    lastName: "थर",
    businessName: "व्यवसायिक नाम",
    previous: "अघिल्लो",
    next: "अर्को",
    pleaseSelectBusinessCategory: "कृपया तपाइँको ब्यापारिक श्रेणी छान्नुहोस्",
    pleaseEnterValidEmail: "कृपया एक मान्य ईमेल प्रविष्ट गर्नुहोस्",
    pleaseEnterPostCode: "कृपया पोस्ट कोड प्रविष्ट गर्नुहोस्",
    postCode: "पोस्ट कोड",
    phoneNumberInUse: "यो फोन नम्बर पहिले नै प्रयोगमा छ!",
    emailInUse: "यो ईमेल पहिले नै प्रयोगमा छ!",
    foodAndDrinks: "खाना र पेय",
    salonAndBeauty: "सैलून र सौन्दर्य",
    fashionAndAccessories: "फेसन र सहायक उपकरण",
    gymAndFitness: "जिम र फिटनेस",
    travelAndHotel: "यात्रा र होटल",
    homeAndGifts: "घर र उपहार",
    washingAndCleaning: "धुने र सफाई",
    gadgetsAndElectronics: "ग्याजेट्स र इलेक्ट्रोनिक्स",
    groceries: "किराना सामान",
    others: "अरु",
    submit: "पेस गर्नुहोस्",
    accountCreatedSuccessful: "तपाइँको खाता सफलतापूर्वक बनाईएको थियो।",
    pleaseEnterAddress: "कृपया तपाइँको ठेगाना प्रविष्ट गर्नुहोस्",
    addressLine1: "ठेगाना लाइन १",
    addressLine2: "ठेगाना लाइन २",
    choosePassword: "पासवर्ड छान्नुहोस्",
    passwordMustBe6Characters: "पासवर्ड कम्तीमा 6 वर्णको हुनु पर्छ।",
    howDidYouHearLoystar: "तपाइँ Loystar को बारे मा कसरी सुन्नुभयो?",
    referralCode: "सन्दर्भ कोड",
    byClickingTheButton: " तलको बटन क्लिक गरेर, तपाइँ सहमत हुनुहुन्छ",
    termsAndSevice: "सर्तहरु",
    wereCreatingAccount: "हामी तपाइँको खाता बनाइरहेका छौं",
    proceed: "अगाडि बढ्नुहोस्",
    verificationCodeMustBe6: "प्रमाणिकरण कोड 6 अंकको हुनु पर्छ",
    pleaseEnter6DigitCode: "कृपया digit अंकको कोड प्रविष्ट गर्नुहोस्",
    enterVerificationCode: "प्रमाणिकरण कोड प्रविष्ट गर्नुहोस्",
    resendToken: "टोकन पुन: पठाउनुहोस्",
    verify: "प्रमाणित गर्नुहोस्",
    transactions: "लेनदेन",
    todaySales: "आजको बिक्री",
    salesHistory: "बिक्री इतिहास",
    supplyHistory: "आपूर्ति इतिहास",
    new: "नयाँ",
    invoices: "इनभ्वाइस",
    disbursements: "वितरण",
    offlineSales: "अफलाइन बिक्री",
    products: "उत्पादनहरु",
    customers: "ग्राहकहरु",
    multiLevelLoyalty: "बहु-स्तर वफादारी",
    loyaltyPrograms: "वफादारी कार्यक्रम",
    members: "सदस्यहरु",
    appStore: "एप स्टोर",
    orderMenu: "अर्डर मेनु",
    settings: "सेटिंग्स",
    staffAndBranches: "कर्मचारी र शाखाहरु",
    myAccount: "मेरो खाता",
    switchToSellMode: "बिक्रि मोडमा स्विच गर्नुहोस्",
    signOut: "साइन आउट",
    getFreeSubscription: "नि: शुल्क सदस्यता प्राप्त गर्नुहोस्",
    onlyNumbersAllowed: "मात्र संख्या अनुमति छ",
    yourAccountMustBe10Digits: "तपाइँको खाता नम्बर १० अंक को संख्या हुनु पर्छ",
    yourBvnMustBe11: "तपाइँको BVN एक ११ अंक को संख्या हुनु पर्छ",
    pleaseSelectBank: "कृपया तपाइँको बैंक छान्नुहोस्",
    selectYourBank: "तपाइँको बैंक छान्नुहोस्",
    enterBankAccountNumber: "बैंक खाता नम्बर प्रविष्ट गर्नुहोस्",
    enterBvnNumber: "तपाइँको BVN प्रविष्ट गर्नुहोस्",
    connectBankAccount: "बैंक खाता जोड्नुहोस्",
    passwordResetSuccessfulAndSignOut:
      "तपाइँको पासवर्ड सफलतापूर्वक रिसेट गरिएको छ। फेरि साइन इन गर्न तल साइन आउट बटन मा क्लिक गर्नुहोस्",
    enterCurrentPassword: "वर्तमान पासवर्ड प्रविष्ट गर्नुहोस्",
    pleaseEnterCurrentPassword: "कृपया वर्तमान पासवर्ड प्रविष्ट गर्नुहोस्",
    phoneNumber: "फोन नम्बर",
    sex: "सेक्स",
    dateOfBirth: "जन्म मिति",
    state: "राज्य",
    country: "देश",
    loyaltyId: "वफादारी आईडी",
    createdAt: "मा सिर्जना गरियो",
    noOfTransactionsMade: "बनेको लेनदेनको संख्या",
    yourDownloadWillStart: "तपाइँको डाउनलोड एक पल मा शुरू हुनेछ",
    exportCustomers: "निर्यात ग्राहकहरु",
    youhaveSuccessfullyToppedUp:
      "तपाइँको सफलतापूर्वक तपाइँको एसएमएस एकाइहरु माथि छ।",
    youNowHave: "तपाइँसँग अब छ",
    smsUnits: "एसएमएस एकाइहरु",
    enterNumberOfSmsUnits:
      "एसएमएस एकाइहरु को संख्या तपाइँ खरीद गर्न को लागी प्रविष्ट गर्नुहोस्",
    pleaseEnterANumericValue: "कृपया एक संख्यात्मक मान प्रविष्ट गर्नुहोस्",
    pay: "तिर्नुहोस्",
    accountEditedSuccessfully: "तपाइँको खाता सफलतापूर्वक सम्पादन गरियो।",
    youAeCurrentlyOn: "तपाइँ हाल मा हुनुहुन्छ",
    plan: "योजना",
    userData: "प्रयोगकर्ता डाटा",
    businessData: "बिजनेस डाटा",
    businessCategory: "व्यापार क्याटरगोरी",
    pleaseSelectCurrency: "कृपया तपाइँको मुद्रा छान्नुहोस्",
    selectYourCurrency: "तपाइँको मुद्रा छान्नुहोस्",
    purchaseMoreSmsUnits:
      "तल फारम को उपयोग गरेर अधिक एसएमएस एकाइहरु किन्नुहोस्",
    youHave: "तपाईँ सँग छ",
    atLeast4SmsUnitsRrequired:
      "कम्तीमा 4 एसएमएस इकाई प्रमाणिकरण को लागी आवश्यक छ, कृपया माथि माथि!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "कृपया तपाइँको बैंक खाता प्रमाणित गर्नुहोस् 'तपाइँको ग्राहक जान्नुहोस्' (KYC) आवश्यकताहरु संग अनुपालन गर्न। यो तपाइँ USSD वा तत्काल स्थानान्तरण को माध्यम बाट भुक्तानी स collect्कलन गर्न अनुमति दिन्छ, ग्राहकहरु बाट आदेश प्राप्त र लेनदेन निपटान प्रक्रिया। नाइजेरियाली व्यापारीहरुलाई मात्र लागू हुन्छ। कृपया सुरु गर्नको लागी तलको बटन थिच्नुहोस्।",
    reConnectBankAccount: "बैंक खाता पुनः जोड्नुहोस्",
    accountName: "खाताको नाम",
    accountNumber: "खाता नम्बर",
    bankName: "बैंक को नाम",
    verified: "प्रमाणित",
    accountDetails: "खाता विवरण",
    kycBankAccount: "KYC",
    createTier: "टियर सिर्जना गर्नुहोस्",
    fileUploadSuccessful: "फाइल सफलतापूर्वक अपलोड भयो",
    fileUploadFailed: "फाइल अपलोड असफल भयो",
    createLoyaltyProgram: "एक वफादारी कार्यक्रम बनाउनुहोस्",
    createLoyalty: "वफादारी सिर्जना गर्नुहोस्",
    name: "नाम",
    loyaltyArtwork: "वफादारी कलाकृति",
    clickToUpload: "अपलोड गर्न क्लिक गर्नुहोस्",
    amountToPointsRatio: "बिन्दु अनुपात को राशि",
    points: "अंक",
    recommendedAmountToPointRatio:
      "सिफारिश: ₦ १ देखि १ बिन्दु। अर्थात् प्रत्येक १ Naira खर्च को लागी, तपाइँका ग्राहकहरु १ बिन्दु पाउँछन्",
    pleaseTypeIn: "कृपया टाइप गर्नुहोस्",
    toDeleteLoyalty: "यो वफादारी मेटाउन",
    deleteLoyalty: "वफादारी मेटाउनुहोस्",
    toConfirm: "पुष्टि गर्न",
    edit: "सम्पादन गर्नुहोस्",
    pointsAwardedSuccessfully: "अंक सफलतापूर्वक पुरस्कृत।",
    enterPointValueToAward:
      "बिन्दु मान प्रविष्ट गर्नुहोस् तपाइँ ग्राहकहरु लाई पुरस्कार दिन चाहानुहुन्छ",
    award: "पुरस्कार",
    awardPointValuesToCustomer: "ग्राहकलाई पुरस्कार बिन्दु मूल्य",
    enrollMembersToLoyalty: "वफादारी मा सदस्य भर्ना",
    awardPoints: "पुरस्कार अंक",
    enroll: "भर्ना गर्नुहोस्",
    home: "घर",
    loyalty: "वफादारी",
    enrollCustomers: "ग्राहक भर्ना गर्नुहोस्",
    selected: "चयन गरियो",
    customer: "ग्राहक",
    loyaltyActivationSuccessful: "वफादारी सक्रियता सफल छ।",
    loyaltyDeactivationSuccessful: "वफादारी निष्क्रियता सफल छ।",
    viewTier: "टियर हेर्नुहोस्",
    deactivate: "सक्रिय गर्नुहोस्",
    activate: "सक्रिय गर्नुहोस्",
    actions: "कार्यहरु",
    nameOfLoyalty: "वफादारी को नाम",
    loyaltyStatus: "वफादारी स्थिति वफादारी स्थिति",
    numberOfTiers: "तहको संख्या",
    createdOn: "मा सिर्जना गरियो",
    createATier: "एक तह बनाउनुहोस्",
    stopCreatingTierConfirmation:
      "के तपाइँ एक स्तर बनाउन बन्द गर्न चाहानुहुन्छ?",
    stopEditingTierConfirmation: "के तपाइँ यो स्तर सम्पादन रोक्न चाहानुहुन्छ?",
    nameOfTier: "तहको नाम",
    minimumSpendingTarget: "न्यूनतम खर्च लक्ष्य",
    maximumSpendingTarget: "अधिकतम खर्च लक्ष्य",
    minimumSpendingTargetRequired: "न्यूनतम खर्च लक्ष्य आवश्यक छ",
    maximumSpendingTargetRequired: "अधिकतम खर्च लक्ष्य आवश्यक छ",
    rewardSponsor: "पुरस्कार प्रायोजक",
    pleaseChooseARewardSponsor: "कृपया एक पुरस्कार प्रायोजक छान्नुहोस्",
    self: "स्व",
    partner: "साथी",
    rewardPartner: "पुरस्कार साझेदार",
    pleaseSelectRewardPartner: "कृपया तपाइँको इनाम साझेदार छान्नुहोस्",
    rewards: "पुरस्कार",
    pleaseSelectAReward: "कृपया एक पुरस्कार चयन गर्नुहोस्",
    instructionsOnRedeemingReward:
      "कसरी ग्राहक रिडिम पुरस्कृत गर्न को लागी निर्देश",
    pleaseFillInThisField: "कृपया यो फिल्ड भर्नुहोस्!",
    toDeleteThisTier: " यो स्तर मेटाउन",
    deleteTier: "टियर मेट्नुहोस्",
    viewMembers: "सदस्यहरु हेर्नुहोस्",
    membersEnrolled: "सदस्य भर्ना",
    instruction: "निर्देशन",
    membersIn: "सदस्यहरु मा",
    availableTiersInLoyalty: "वफादारी कार्यक्रम मा उपलब्ध टियर (हरू)",
    tiers: "तहहरु",
    totalTier: "कुल टायर",
    availableLoyaltyProgramme: "उपलब्ध वफादारी कार्यक्रम",
    totalLoyalties: "कुल वफादारी",
    memberDetails: "सदस्य विवरण",
    nameOfCustomer: "ग्राहक को नाम",
    address: "ठेगाना",
    allEnrolledMembers: "सबै भर्ना सदस्यहरु",
    thisIsToWishYouHappyBirthday:
      "यो तपाइँ एक धेरै खुशी को जन्मदिन र समृद्ध जीवन को कामना गर्न को लागी हो। तपाइँ Loyster को लागी हुनुहुन्छ कि सबै को लागी धन्यवाद। शुभकामना उत्सव!",
    inputAnOfferPlease: "कृपया एक प्रस्ताव इनपुट गर्नुहोस्",
    pleaseSelectTheInsertPoint:
      "कृपया सन्देश मा सम्मिलित बिन्दु चयन गर्नुहोस् र फेरि क्लिक गर्नुहोस्",
    birthdayOfferAndMessage: "जन्मदिन प्रस्ताव र सन्देश",
    birthdayOffer: "जन्मदिन प्रस्ताव",
    birthdayMessage: "जन्मदिन सन्देश",
    noOfferFound: "कुनै प्रस्ताव भेटिएन",
    settingABirthdayOffer:
      "एक जन्मदिन प्रस्ताव सेट ग्राहकहरु लाई आफ्नो जन्मदिन मा एसएमएस को माध्यम बाट यो प्रस्ताव प्राप्त गर्न अनुमति दिन्छ",
    createOffer: "प्रस्ताव सिर्जना गर्नुहोस्",
    whatIsTheOffer: "प्रस्ताव के हो?",
    editMessage: "सन्देश सम्पादन गर्नुहोस्",
    insert: "घुसाउनुहोस्",
    theNameOfCustomerInserted: "ग्राहक को नाम यहाँ सम्मिलित गरिनेछ",
    theBirtdayOfferInserted: "जन्मदिनको प्रस्ताव यहाँ सम्मिलित गरिनेछ",
    youSuccessfullyAddedNewBranch: "तपाइँ सफलतापूर्वक एक नयाँ शाखा थपेको छ!",
    addNewBranch: "नयाँ शाखा जोड्नुहोस्",
    addBranch: "शाखा जोड्नुहोस्",
    additionalBranchWillIncur: "अतिरिक्त शाखा हुनेछ",
    perBranch: "प्रति शाखा",
    ecommerceBranchCosts: "ईकमर्स शाखा लागत",
    pleaseEnterBranchName: "कृपया शाखा को नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterBranchAddress1: "कृपया शाखा को ठेगाना लाइन १ प्रविष्ट गर्नुहोस्",
    enterBranchAddress1: "शाखा को ठेगाना लाइन १ प्रविष्ट गर्नुहोस्",
    enterBranchAddress2: "शाखा को ठेगाना लाइन १ प्रविष्ट गर्नुहोस्",
    pleaseEnterBranchAddress2: "कृपया शाखा को ठेगाना लाइन २ प्रविष्ट गर्नुहोस्",
    enterBranchName: "शाखा को नाम प्रविष्ट गर्नुहोस्",
    successfullyAddedStaff: "तपाइँ सफलतापूर्वक एक नयाँ स्टाफ थपिएको छ!",
    addNewStaff: "नयाँ कर्मचारी थप्नुहोस्",
    addStaff: "कर्मचारी थप्नुहोस्",
    additionalStaffWillIncur: "अतिरिक्त कर्मचारी खर्च हुनेछ",
    perStaff: "प्रति कर्मचारी।",
    pleaseEnterStaffEmail: "कृपया कर्मचारीको इमेल प्रविष्ट गर्नुहोस्",
    pleaseEnterStaffUsername:
      "कृपया कर्मचारीको प्रयोगकर्ता नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterStaffPassword: "कृपया कर्मचारीको पासवर्ड प्रविष्ट गर्नुहोस्",
    pleaseSelectStaffRole: "कृपया कर्मचारीको भूमिका छान्नुहोस्",
    selectStaffRole: "कर्मचारीको भूमिका छान्नुहोस्",
    enterStaffEmail: "कर्मचारीको इमेल प्रविष्ट गर्नुहोस्",
    enterStaffUsername: "कर्मचारीको प्रयोगकर्ता नाम प्रविष्ट गर्नुहोस्",
    enterStaffPassword: "कर्मचारीको पासवर्ड प्रविष्ट गर्नुहोस्",
    spacesNotAllowedInUsername: "रिक्त स्थान प्रयोगकर्ता नाम मा अनुमति छैन",
    admin: "व्यवस्थापक",
    pleaseSelectBusinessToAttachStaff:
      "कृपया स्टाफ संलग्न गर्न ब्यापार चयन गर्नुहोस्",
    searchForBranchToAttachStaff: "शाखा संलग्न कर्मचारीहरु संलग्न गर्न को लागी",
    username: "प्रयोगकर्ता नाम",
    role: "भूमिका",
    areYouSureToDeleteThis: "के तपाइँ यो मेटाउन निश्चित हुनुहुन्छ?",
    branches: "शाखाहरु",
    upgradeYourPlan: "तपाइँको योजना अपग्रेड गर्नुहोस्।",
    add: "थप्नुहोस्",
    addNew: "नयाँ थप्नुहोस्",
    customerWithEmailAlreadyExists:
      "ईमेल/फोन नम्बर संग ग्राहक पहिले नै अवस्थित छ!",
    successfullyAddedNewCustomer: "तपाइँ सफलतापूर्वक एक नयाँ ग्राहक थपेको छ!",
    addCustomer: "ग्राहक जोड्नुहोस्",
    pleaseEnterCustomerFirstName: "कृपया ग्राहकको नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterCustomerLastName: "कृपया ग्राहकको अन्तिम नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterCustomerPhoneNumber:
      "कृपया ग्राहकको फोन नम्बर प्रविष्ट गर्नुहोस्",
    pleaseEnterCustomerEmail: "कृपया ग्राहकको ईमेल प्रविष्ट गर्नुहोस्",
    pleaseEnterCustomerAddressLine:
      "कृपया ग्राहकको ठेगाना लाइन प्रविष्ट गर्नुहोस्",
    pleaseEnterCustomerOtherAddress:
      "कृपया ग्राहकको अन्य ठेगाना प्रविष्ट गर्नुहोस्",
    pleaseSelectCustomerGender: "कृपया ग्राहकको लि select्ग चयन गर्नुहोस्",
    gender: "लि्ग",
    male: "पुरुष",
    female: "महिला",
    bank: "बैंक",
    selectBank: "बैंक चयन गर्नुहोस्",
    stateOrRegionOrProvince: "राज्य/क्षेत्र/प्रान्त",
    customerNotes: "ग्राहक नोट्स",
    sendSms: "एसएमएस पठाउनुहोस्",
    editCustomer: "ग्राहक सम्पादन गर्नुहोस्",
    redeemReward: "पुरस्कृत गर्नुहोस्",
    issueLoyaltyCard: "वफादारी कार्ड जारी गर्नुहोस्",
    deleteCustomer: "ग्राहक मेटाउनुहोस्",
    youveSuccessfullyAssignedLoyaltyMembership:
      "तपाइँ सफलतापूर्वक एक वफादारी सदस्यता आईडी असाइन गर्नुभयो",
    noMembershipIdAvailable:
      "कुनै सदस्यता आईडी उपलब्ध छैन। कृपया हामीलाई hello@loystar.co मा सम्पर्क गर्नुहोस्",
    sendEmail: "ईमेल पठाउनुहोस्",
    membershipPoints: "सदस्यता अंक",
    customerDetails: "ग्राहक विवरण",
    close: "बन्द गर्नुहोस्",
    loyaltyBalance: "वफादारी सन्तुलन",
    pointsBalance: "अंक सन्तुलन",
    starBalance: "तारा ब्यालेन्स",
    requiredPoints: "आवश्यक बिन्दुहरु",
    requiredStars: "आवश्यक ताराहरु",
    reddemCode: "कोड रिडिम गर्नुहोस्",
    toDeleteThisCustomer: "यो ग्राहक मेटाउन",
    customerHasBeenDeletedSuccessfully: "ग्राहक सफलतापूर्वक मेटिएको छ!",
    customerWithPhoneAlreadyExists: "फोन नम्बर संग ग्राहक पहिले नै अवस्थित छ",
    customerWasSuccessfullyEdited: "ग्राहक सफलतापूर्वक सम्पादन गरिएको थियो",
    anErrorOccured: "एउटा त्रुटि भयो",
    phoneNumberIncludeCountry: "फोन नम्बर (देश कोड सहित)",
    yourFileQueuedForUpload:
      "तपाइँको फाइल अपलोड को लागी लामबद्ध गरिएको छ। कृपया केहि सेकेन्ड पछि पेज रिफ्रेस गर्नुहोस्।",
    thereWasAnErrorPerformingOperation:
      "त्यहाँ सञ्चालन प्रदर्शन एउटा त्रुटि थियो!",
    pleaseSelectFile: "कृपया एउटा फाइल छान्नुहोस्!",
    oopsOnlyCsvFilesAllowed:
      "उफ्! मात्र CSV फाइलहरु लाई अनुमति छ। कृपया एक .csv फाइल अपलोड गर्नुहोस्।",
    fileShouldBeSmallerThan5Mb:
      "फाइल ५ एमबी भन्दा सानो हुनु पर्छ। यदि तपाइँ एक ठूलो फाइल छ, support@loystar.co ईमेल गर्नुहोस्। धन्यवाद",
    customerFirstNameIsRequired: "ग्राहक को नाम प row्क्ति मा आवश्यक छ",
    customerPhoneNumberIsRequired: "ग्राहक फोन नम्बर प .्क्ति मा आवश्यक छ",
    importCustomers: "ग्राहकहरु आयात गर्नुहोस्",
    upload: "अपलोड गर्नुहोस्",
    clickIconToDownloadCsv:
      "CSV फाइल टेम्प्लेट डाउनलोड गर्न यो आइकनमा क्लिक गर्नुहोस्।",
    getGoogleSheetVersion: "गुगल पाना संस्करण पाउनुहोस्",
    clickOrDragFileToUpload:
      "क्लिक गर्नुहोस् वा अपलोड गर्न को लागी यस क्षेत्र मा फाइल तान्नुहोस्",
    missingOrInvalidColumnHeader:
      "छुटेको वा अमान्य स्तम्भ हेडर। कृपया टेम्पलेट ढाँचा पालन गर्नुहोस्। धन्यवाद।",
    youHaveImported: "तपाइँले आयात गर्नुभयो",
    youSuccessfullyRedeemedReward:
      "तपाइँ सफलतापूर्वक तपाइँको इनाम रिडिम गर्नुभयो!",
    sixDigitCode: "छ अ digit्कको कोड",
    pleaseEnterCustomerRewardCode: "कृपया ग्राहकको इनाम कोड प्रविष्ट गर्नुहोस्",
    enterRewardCode: "इनाम कोड प्रविष्ट गर्नुहोस्। (इनाम कोड केस संवेदनशील छ)",
    selectLoyaltyProgram: "वफादारी कार्यक्रम छान्नुहोस्",
    stamps: "टिकटहरु",
    smsUnitsLow: "एसएमएस एकाइहरु कम",
    pleaseTopUpSmsUnits: "कृपया एसएमएस एकाइहरु माथि",
    smsSuccessfullySentForDelivery: "एसएमएस सफलतापूर्वक वितरण को लागी पठाइयो!",
    sendSmsTo: "मा एसएमएस पठाउनुहोस्",
    allCustomers: "सबै ग्राहकहरु",
    unitsAvailable: "एकाइहरु उपलब्ध छन्",
    pleaseTypeInTheMessage: "कृपया सन्देश टाइप गर्नुहोस्",
    message: "सन्देश",
    charactersRemaining: "क्यारेक्टर बाँकी",
    avoidUseOfSpecialCharacters:
      "एसएमएस एकाइहरु को संरक्षण को लागी विशेष वर्ण र इमोजी को उपयोग बाट बच्न।",
    note: "नोट",
    errorFetchingCustomersMultilevelDetail:
      "त्रुटि ग्राहकहरु MultiLevel विवरण ल्याउने",
    search: "खोज",
    reset: "रिसेट गर्नुहोस्",
    importCustomer: "ग्राहक आयात गर्नुहोस्",
    addNewCustomer: "नयाँ ग्राहक जोड्नुहोस्",
    sendSmsBroadcast: "एसएमएस प्रसारण पठाउनुहोस्",
    totalCustomers: "कुल ग्राहकहरु",
    disbursementDetails: "वितरण विवरण",
    paidBy: "द्वारा तिरेको",
    disbursed: "वितरित",
    bankAccountName: "बैंक खाता को नाम",
    bankAccountNumber: "बैँक खाता नम्बर",
    transferInitiated: "स्थानान्तरण सुरु भयो",
    transferCompleted: "स्थानान्तरण पूरा भयो",
    pleaseEnterAValid: "कृपया एक मान्य प्रविष्ट गर्नुहोस्",
    begin: "सुरु",
    end: "अन्त्य",
    date: "मिति",
    paymentDate: "भुक्तानी मिति",
    selectDisbursementDuration: "वितरण अवधि चयन गर्नुहोस्",
    totalSettled: "कुल बसोबास",
    totalUnsettled: "कुल अस्थिर",
    toDeleteThisSale: "यो बिक्री मेटाउन",
    draftSaleDeletedSuccessfully: "ड्राफ्ट बिक्री सफलतापूर्वक मेटिएको छ!",
    deleteSale: "बिक्री मेटाउनुहोस्",
    pleaseTypeInYourTotal:
      "कृपया तपाइँको कुल मा टाइप गर्नुहोस् मेटाउने को पुष्टि गर्न को लागी",
    billDetails: "बिल विवरण",
    printBill: "प्रिन्ट बिल",
    servedBy: "द्वारा सेवा",
    total: "कुल",
    createdDate: "सिर्जना मिति",
    createdTime: "सिर्जना समय",
    status: "स्थिति",
    loginSuccessful: "लगइन सफल",
    pleaseWaitWhileWeConnectAccount:
      "कृपया पर्खनुहोस् जब सम्म हामी तपाइँको खाता लाई जोड्छौं",
    connectionFailedTryAgain: "सम्पर्क विच्छेद। फेरि प्रयास गर्नुहोस।",
    connectionSuccessful: "जडान सफल",
    viewDetails: "विवरण हेर्नुहोस्",
    enable: "सक्षम गर्नुहोस्",
    free: "मुक्त",
    cost: "लागत",
    visitWebsite: "वेबसाइट मा जानुहोस्",
    pleaseUpgradeYourPlanToPro:
      "यो अनुप्रयोग सक्षम गर्न को लागी प्रो प्लस को लागी तपाइँको योजना अपग्रेड गर्नुहोस्",
    connectYourBankAccount:
      "भुक्तानी प्राप्त गर्न सक्षम हुनको लागी तपाइँको बैंक खाता जोड्नुहोस्।",
    disable: "असक्षम गर्नुहोस्",
    enableApp: "अनुप्रयोग सक्षम गर्नुहोस्",
    addNewProductToInvoice: "इनभ्वाइसमा नयाँ उत्पादन थप्नुहोस्",
    toDeleteThisInvoice: "यो बीजक मेटाउन",
    invoiceDeletedSuccessfully: "बीजक सफलतापूर्वक मेटिएको छ!",
    deleteInvoice: "बीजक मेटाउनुहोस्",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "तपाइँ सफलतापूर्वक एक इनभ्वाइस भुक्तानी सन्देश जोड्नुभएको छ!",
    pleaseEnterPaymentInstructions:
      "कृपया भुक्तानी निर्देशन प्रविष्ट गर्नुहोस्",
    invoiceId: "बीजक आईडी",
    paidDate: "तिरेको मिति",
    reference: "सन्दर्भ",
    productAdded: "उत्पादन थपियो",
    productOutOfStock: "उत्पादन स्टक बाहिर छ। कृपया पुनः स्टक गर्नुहोस्।",
    totalInvoices: "कुल चालान",
    totalPaidInvoices: "कुल भुक्तानी चालान",
    totalUnpaidInvoices: "कुल अनपेड इनभ्वाइस",
    loyaltyProgramDeleted: "वफादारी कार्यक्रम मेटाइयो",
    thisLoyaltyProgramDeletedSuccessfully:
      "यो वफादारी कार्यक्रम सफलतापूर्वक मेटिएको छ",
    thisLoyaltyProgramEditedSuccessfully:
      "यो वफादारी कार्यक्रम सफलतापूर्वक सम्पादन गरिएको छ",
    loyaltyProgramAddedSuccessfully: "वफादारी कार्यक्रम सफलतापूर्वक थपिएको छ",
    loyaltyProgramEdited: "वफादारी कार्यक्रम सम्पादित",
    loyaltyProgramAdded: "वफादारी कार्यक्रम थपियो",
    loyaltyDetails: "वफादारी विवरण",
    doYouWantToCloseDialog: "के तपाइँ यो संवाद बन्द गर्न चाहानुहुन्छ?",
    pleaseEnterLoyaltyName: "कृपया तपाइँको वफादारी को नाम प्रविष्ट गर्नुहोस्",
    programType: "कार्यक्रम को प्रकार",
    pleaseSelectProgramType: "कृपया एक कार्यक्रम प्रकार चयन गर्नुहोस्",
    simplePoints: "सरल अंक",
    stampsProgram: "टिकट कार्यक्रम",
    threshold: "थ्रेसहोल्ड",
    pleaseEnterLoyaltyThreshold: "कृपया वफादारी थ्रेसहोल्ड प्रविष्ट गर्नुहोस्",
    reward: "इनाम",
    pleaseEnterLoyaltyReward: "कृपया वफादारी को इनाम प्रविष्ट गर्नुहोस्",
    totalUserPoints: "कुल प्रयोगकर्ता अंक",
    totalUserStamps: "कुल प्रयोगकर्ता टिकटहरु",
    spendingTarget: "लक्ष्य खर्च",
    spendingTargetHint1:
      "खर्च लक्ष्य यो हो कि एक ग्राहक इनाम कमाउन को लागी खर्च गर्नु पर्छ। 1 मुद्रा मूल्य = 1 बिन्दु।",
    spendingTargetHint2:
      "टिकट लक्ष्य हो कि एक ग्राहक इनाम कमाउन को लागी धेरै टिकटहरु स collect्कलन गर्नु पर्छ। जस्तै ५",
    addNewLoyaltyProgram: "नयाँ वफादारी कार्यक्रम जोड्नुहोस्",
    addLoyaltyProgram: "वफादारी कार्यक्रम जोड्नुहोस्",
    loyaltyProgramType: "वफादारी कार्यक्रम को प्रकार",
    pleaseSelectLoyaltyProgramType:
      "कृपया वफादारी कार्यक्रम प्रकार चयन गर्नुहोस्",
    nameOfProgram: "कार्यक्रम को नाम",
    pleaseEnterProgramName: "कृपया कार्यक्रम को नाम प्रविष्ट गर्नुहोस्",
    whatIsTheReward: "इनाम के हो?",
    egNextPurchaseIsFree: "जस्तै अर्को खरीद नि: शुल्क छ",
    customerName: "ग्राहकको नाम",
    guestCustomer: "अतिथि ग्राहक",
    orderConfirmedSuccessfully: "आदेश सफलतापूर्वक पुष्टि भयो",
    orderCancelledSuccessfully: "अर्डर सफलतापूर्वक रद्द गरियो",
    confirmOrder: "आदेश पुष्टि गर्नुहोस्",
    cancelOrder: "अर्डर रद्द गर्नुहोस्",
    allOrders: "सबै आदेश",
    totalOrders: "कुल आदेश",
    doYouWantToAcceptOrder: "के तपाइँ यो आदेश स्वीकार गर्न चाहानुहुन्छ?",
    doYouWantToCancelOrder: "के तपाइँ यो अर्डर रद्द गर्न चाहानुहुन्छ?",
    orderDetails: "आदेश विवरण",
    orderCreatedAt: "मा सिर्जना आदेश",
    supplier: "आपूर्तिकर्ता",
    productName: "उत्पादन को नाम",
    quantity: "मात्रा",
    unitPrice: "एकाइ मूल्य",
    receivedBy: "द्वारा प्राप्त",
    reportFrom: "बाट रिपोर्ट",
    totalSupplies: "कुल आपूर्तिहरु",
    allRightsReserved: "सबै अधिकार सुरक्षित",
    toDeleteThisTransaction: "यो लेनदेन मेटाउन",
    transactionDeletedSuccessfully:
      "लेनदेन सफलतापूर्वक मेटिएको छ। शेयर सूची मा फिर्ता गरिएको छ।",
    deleteTransaction: "लेनदेन मेटाउनुहोस्",
    transactionDetails: "लेनदेन विवरण",
    printReceipt: "रसिद प्रिन्ट गर्नुहोस्",
    channel: "च्यानल",
    discount: "छुट",
    profit: "नाफा",
    discountedSales: "बिक्री छुट",
    errorFetchingRecord: "त्रुटि ल्याउने रेकर्ड",
    exportTransactions: "निर्यात लेनदेन",
    errorFetchingSalesRecord:
      "निर्यात को लागी बिक्री रेकर्ड ल्याउने क्रममा त्रुटि।",
    totalSellingPrice: "कुल बिक्री मूल्य",
    totalCostPrice: "कुल लागत मूल्य",
    appliedDiscount: "लागू छुट",
    noOfItems: "आइटम को संख्या",
    sales: "बिक्री",
    export: "निर्यात गर्नुहोस्",
    totalProfit: "कुल नाफा",
    totalBalanceInPeriod: "अवधि मा कुल ब्यालेन्स",
    allTimeSales: "सबै समय बिक्री",
    records: "रेकर्ड",
    todaysSales: "आजको बिक्री",
    transaction: "लेनदेन",
    youHaveExceededTotalNumberOfProducts:
      "तपाइँ तपाइँको योजना मा अनुमति उत्पादनहरु को कुल संख्या पार गर्नुभएको छ। एक उच्च सीमा को आनन्द लिन को लागी तपाइँको योजना अपग्रेड गर्नुहोस्।",
    youNeedToHaveLoyaltyProgram:
      "तपाइँ यो सुविधा को उपयोग गर्न को लागी एक वफादारी कार्यक्रम को आवश्यकता छ!",
    price: "मूल्य",
    category: "श्रेणी",
    stockTracking: "शेयर ट्रयाकि",
    stockCount: "स्टक गणना",
    taxed: "कर लगाइयो",
    originalPrice: "मूल मूल्य",
    costPrice: "लागत मूल्य",
    unit: "एकाइ",
    productImage: "उत्पादन छवि",
    taxRate: "कर दर",
    taxType: "कर प्रकार",
    trackInventory: "ट्र्याक सूची",
    variants: "भेरियन्ट्स",
    hasVariants: "भेरियन्ट छन्",
    importProduct: "आयात उत्पादन",
    exportProducts: "उत्पादनहरु निर्यात गर्नुहोस्",
    addNewProduct: "नयाँ उत्पादन जोड्नुहोस्",
    viewCategory: "श्रेणी हेर्नुहोस्",
    viewSuppliers: "आपूर्तिकर्ताहरु हेर्नुहोस्",
    receiveInventory: "सूची प्राप्त गर्नुहोस्",
    printAllProductsTag: "सबै उत्पादन ट्याग प्रिन्ट गर्नुहोस्",
    deleteAll: "सबै मेट्नुहोस्",
    totalProducts: "कुल उत्पादनहरु",
    youveSuccessfullyAddedANewCategory:
      "तपाइँ सफलतापूर्वक एक नयाँ श्रेणी थपिएको छ",
    addNewCategory: "नयाँ श्रेणी जोड्नुहोस्",
    addCategory: "श्रेणी जोड्नुहोस्",
    categoryName: "श्रेणी नाम",
    pleaseEnterCategoryName: "कृपया श्रेणी को नाम प्रविष्ट गर्नुहोस्",
    stampsTarget: "टिकट लक्ष्य",
    sendInventory: "सूची पठाउनुहोस्",
    productDetails: "उत्पादन विवरण",
    youveSuccessfullyEditedThisCategory:
      "तपाइँ सफलतापूर्वक यो श्रेणी सम्पादन गर्नुभयो",
    update: "अपडेट गर्नुहोस्",
    categoryList: "श्रेणी सूची",
    categories: "कोटिहरु",
    enterQuantityToUpdate: "अपडेट गर्न को लागी एक मात्रा प्रविष्ट गर्नुहोस्",
    inventorySentSuccessfully: "सूची सफलतापूर्वक पठाइयो!",
    updateInventory: "सूची अपडेट गर्नुहोस्",
    currentQuantity: "वर्तमान मात्रा",
    pleaseEnterQuantityToAdd:
      "कृपया मात्रा तपाइँ प्रविष्ट गर्न चाहनुहुन्छ प्रविष्ट गर्नुहोस्",
    pleaseSelectABranch: "कृपया एउटा शाखा छान्नुहोस्",
    searchForBranch: "शाखा को लागी खोजी गर्नुहोस्",
    youCantSendMoreThanStock: "तपाइँ स्टक मा भन्दा धेरै पठाउन सक्नुहुन्न",
    send: "पठाउनुहोस्",
    pleaseEnterQuantityToSend:
      "कृपया पठाउनुहोस् मात्रा तपाइँ पठाउन चाहानुहुन्छ",
    productNameIsRequiredOnRow: "उत्पादन को नाम प row्क्ति मा आवश्यक छ",
    productCategoryIsRequiredOnRow: "उत्पादन श्रेणी प on्क्ति मा आवश्यक छ",
    productPriceIsRequiredOnRow: "उत्पादन मूल्य प row्क्ति मा आवश्यक छ",
    productUnitIsRequiredOnRow: "उत्पादन इकाई प row्क्ति मा आवश्यक छ",
    productQuantityIsRequiredOnRow: "उत्पादन मात्रा प row्क्ति मा आवश्यक छ",
    productVariantsRequireTracking:
      "उत्पादन भेरिएन्ट ट्र्याकि requires आवश्यक छ!",
    pleaseAddVariantClickButton:
      "थप्नुहोस् बटन क्लिक गरेर एक प्रकार जोड्नुहोस्!",
    totalVariantsMoreThanSelectedQuantity:
      "कुल भेरिएन्ट चयनित उत्पादन मात्रा भन्दा बढी छन्, Pls संस्करण मात्रा घटाउनुहोस्",
    productEditedSuccessfully: "उत्पादन सफलतापूर्वक सम्पादन गरिएको छ!",
    fileTooLargeLessThan4Mb:
      "फाइल आकार धेरै ठूलो छ! फाइल आकार 4MB भन्दा कम हुनु पर्छ।",
    suchVariantAlreadyExist: "यस्तो प्रकार पहिले नै अवस्थित छ",
    addVariants: "भेरियन्ट जोड्नुहोस्",
    editProduct: "उत्पादन सम्पादन गर्नुहोस्",
    pleaseEnterProductName: "कृपया उत्पादन को नाम प्रविष्ट गर्नुहोस्",
    pleaseEnterProductPrice: "कृपया उत्पादन मूल्य प्रविष्ट गर्नुहोस्",
    pleaseEnterProductOriginalPrice:
      "कृपया उत्पादन मूल मूल्य प्रविष्ट गर्नुहोस्",
    productDescription: "उत्पादन विवरण",
    selectProductCategory: "उत्पादन श्रेणी चयन गर्नुहोस्",
    pleaseSelectProductCategory: "कृपया उत्पादन श्रेणी चयन गर्नुहोस्",
    productCostPrice: "उत्पादन लागत मूल्य",
    productSellingPrice: "उत्पादन बिक्री मूल्य",
    productOriginalPrice: "उत्पादन मूल मूल्य",
    maxFileSizeAllowedIs4Mb: "अधिकतम अनुमति फाइल आकार 4mb छ",
    productsWithPicturesArePublished:
      "चित्रहरु संग उत्पादनहरु डिस्कवर मा अर्डर प्राप्त गर्न को लागी प्रकाशित गरीन्छ",
    shouldThisProductBeTracked: "यो उत्पादन ट्रयाक गर्नुपर्छ?",
    pleaseSelectStockUnit: "कृपया शेयर एकाइ चयन गर्नुहोस्",
    stockUnit: "शेयर एकाइ",
    bag: "ब्याग",
    bottle: "बोतल",
    bunch: "गुच्छा",
    can: "CAN",
    carton: "कार्टन",
    crate: "CRATE",
    cup: "कप",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "ग्राम",
    kilogram: "किलोग्राम",
    litre: "लिटर",
    pack: "प्याक",
    pair: "जोडी",
    pieces: "टुक्राहरु",
    plate: "प्लेट",
    tonne: "टन (MT)",
    uNIT: "युनिट",
    yard: "यार्ड",
    pleaseEnterProductQuantity: "कृपया उत्पादन मात्रा प्रविष्ट गर्नुहोस्",
    productQuantity: "उत्पादन मात्रा",
    isThisProductTaxed: "के यो उत्पादन कर छ?",
    selectTaxType: "कर प्रकार छान्नुहोस्",
    pleaseSelectTaxType: "कृपया कर प्रकार छान्नुहोस्",
    progressive: "प्रगतिशील",
    proportional: "समानुपातिक",
    pleaseEnterProductTaxRate: "कृपया उत्पादन कर दर प्रविष्ट गर्नुहोस्",
    doesProductHaveVariants: "के यो उत्पादन भेरियन्ट छ?",
    type: "प्रकार",
    value: "मूल्य",
    pleaseEnterVariantType: "कृपया प्रकार को प्रकार प्रविष्ट गर्नुहोस्",
    pleaseEnterVariantValue: "कृपया संस्करण को मान प्रविष्ट गर्नुहोस्",
    pleaseEnterVariantPrice: "कृपया संस्करण को मूल्य प्रविष्ट गर्नुहोस्",
    pleaseEnterVariantQuantity: "कृपया संस्करण को मात्रा प्रविष्ट गर्नुहोस्",
    productDeletedSuccessfully: "उत्पादन सफलतापूर्वक मेटिएको छ!",
    categoryDeletedSuccessfully: "श्रेणी सफलतापूर्वक मेटिएको छ!",
    toDeleteThisProduct: "यो उत्पादन मेटाउन",
    invalidProductQuantity: "अमान्य उत्पादन मात्रा",
    quantityAddedIsOverStock:
      "मात्रा तपाइँ जोड्दै हुनुहुन्छ तपाइँ के स्टक मा छ।",
    itemInventoryNotTracked: "वस्तु सूची ट्र्याक गरिएको छैन",
    addBulkQuantity: "थोक मात्रा थप्नुहोस्",
    enterBulkQuantity: "थोक मात्रा प्रविष्ट गर्नुहोस्",
    pleaseEnterBulkQuantity: "कृपया थोक मात्रा प्रविष्ट गर्नुहोस्",
    youveSuccessfullyAddedANewProduct:
      "तपाइँ सफलतापूर्वक एक नयाँ उत्पादन थपेको छ!",
    pleaseEnterProductSellingPrice:
      "कृपया उत्पादन बिक्री मूल्य प्रविष्ट गर्नुहोस्",
    doYouWantToTrackProductStock:
      "के तपाइँ उत्पादन स्टक ट्रयाक गर्न चाहानुहुन्छ?",
    sellingPrice: "बिक्री मूल्य",
    setProductExpiryReminder: "उत्पादन समाप्ति रिमाइन्डर सेट गर्नुहोस्",
    productExpiryDate: "उत्पादन समाप्ति मिति",
    startRemindingFrom: "बाट सम्झाउन सुरु गर्नुहोस्",
    productSuppliesAddedSuccessfully:
      "तपाइँ सफलतापूर्वक उत्पादन आपूर्ति थपिएको छ।",
    addProductSupplies: "उत्पादन आपूर्ति जोड्नुहोस्",
    pleaseSelectSupplier: "कृपया आपूर्तिकर्ता चयन गर्नुहोस्",
    nameOfProduct: "उत्पादन को नाम",
    pleaseSelectProduct: "कृपया उत्पादन छान्नुहोस्",
    productVariant: "उत्पादन संस्करण",
    pleaseSelectAVariant: "कृपया एक प्रकार चयन गर्नुहोस्",
    pleaseEnterUnitPrice: "कृपया एकाई मूल्य प्रविष्ट गर्नुहोस्",
    businessBranch: "व्यापार शाखा",
    pleaseSelectBranch: "कृपया शाखा छान्नुहोस्",
    youveSuccessfullyAddedANewSupplier:
      "तपाइँ सफलतापूर्वक एक नयाँ आपूर्तिकर्ता थपिएको छ",
    addSupplier: "आपूर्तिकर्ता जोड्नुहोस्",
    pleaseEnterSupplierEmail: "कृपया आपूर्तिकर्ताको ईमेल प्रविष्ट गर्नुहोस्",
    pleaseAddADescription: "कृपया एउटा विवरण थप्नुहोस्",
    anErrorOccuredProductsDeleted:
      "अपरेशन प्रदर्शन गर्दा एउटा त्रुटि भयो। कृपया ध्यान दिनुहोस्, केहि उत्पादनहरु प्रक्रिया मा मेटिएको हुन सक्छ",
    bulkDelete: "थोक मेटाउनुहोस्",
    youAreAboutToDelete: "तपाइँ मेटाउन को लागी हुनुहुन्छ",
    product: "उत्पादन",
    isDueToRedeem: "छुटकाराको कारण हो",
    aReward: "एक पुरस्कार",
    pleaseSelectCustomerToReeemReward:
      "कृपया एक ग्राहक इनाम रिडिम गर्न को लागी चयन गर्नुहोस्।",
    youHaveNoLoyaltyProgramRunning:
      "तपाइँसँग कुनै सक्रिय वफादारी कार्यक्रम चलिरहेको छैन",
    customerHhasNoPointsInLoyaltyProgram:
      "ग्राहक यो वफादारी कार्यक्रम मा कुनै अंक छैन",
    proceedWithPayment: "भुक्तानीको साथ अगाडि बढ्नुहोस्?",
    youAreAboutToPayForTransactionUsingPoints:
      "तपाइँ बिन्दुहरु को उपयोग लेनदेन को लागी तिर्न को लागी हुनुहुन्छ।",
    customerHas: "ग्राहक छ",
    worth: "लायक",
    andIsNotSufficientToPayForTransaction:
      "र यो लेनदेन को लागी तिर्न को लागी पर्याप्त छैन। के उनीहरु अर्को भुक्तानी विधि को उपयोग गरेर ब्यालेन्स थप्न चाहान्छन्?",
    addCustomerLoyalty: "ग्राहक वफादारी जोड्नुहोस्",
    pleaseAddCustomerFirst:
      "कृपया जोड्नुहोस् वा पहिले एक ग्राहक चयन गर्नुहोस्।",
    pleaseWaitWhileWeGetReady: "कृपया पर्खनुहोस्, जब हामी तयार छौं",
    lowStock: "थोरै सामान",
    pleaseEnterAmountTendered: "टेन्डर गरिएको रकम प्रविष्ट गर्नुहोस्",
    areYouSureToBookSaleOffline:
      "के तपाइँ पक्का हुनुहुन्छ कि तपाइँ यो बिक्री अफलाइन बुक गर्न चाहानुहुन्छ?",
    saleWouldBeBookedAutomatically:
      "बिक्री स्वचालित रूपमा बुक हुनेछ जब तपाइँ तपाइँको इन्टरनेट मा बारी",
    offlineSalesBookingCancelled: "अफलाइन बिक्री बुकिंग रद्द गरियो",
    covid19Message:
      "कोभिड १:: साबुन पानीले हात धुनुहोस् वा स्यानिटाइज गर्नुहोस् प्रसार रोक्न। सधैं सुरक्षित रहनुहोस्",
    saleSuccessfullyRecorded: "बिक्री सफलतापूर्वक रेकर्ड गरियो!",
    sendReceiptToEmail: "ईमेल मा रसिद पठाउनुहोस्",
    sendThankYouSms: "धन्यवाद एसएमएस पठाउनुहोस्",
    sendShippingDetails: "ढुवानी विवरण पठाउनुहोस्",
    addLoyalty: "वफादारी जोड्नुहोस्",
    searchCustomerNameOrNumber: "ग्राहक नाम वा नम्बर खोज्नुहोस्",
    clickTheSearchCustomerBox:
      "खोज ग्राहक बक्स र स्क्यान कार्ड क्लिक गर्नुहोस्",
    enterProductPrice: "उत्पादन मूल्य प्रविष्ट गर्नुहोस्",
    enterPriceFor: "को लागी मूल्य प्रविष्ट गर्नुहोस्",
    searchForProduct: "उत्पादन को लागी खोज",
    all: "सबै",
    backToDashboard: "ड्यासबोर्ड मा फर्कनुहोस्",
    viewDraftSales: "ड्राफ्ट बिक्री हेर्नुहोस्",
    variantSelected: "भेरिएन्ट चयन गरियो",
    variant: "विविधता",
    thePreviousVariantSelectionNotAvailable:
      "अघिल्लो संस्करण चयन मूल्य को आधार मा चयन नयाँ संस्करण को लागी उपलब्ध छैन, कृपया तपाइँको चयन परिवर्तन गर्नुहोस्।",
    pleaseSupplyPositiveQuantityNumber:
      "एक postive मात्रा संख्या आपूर्ति गर्नुहोस्",
    lowStockFor: "को लागी कम स्टक",
    clearVariants: "स्पष्ट रूपहरु",
    pleaseEnterQuantity: "कृपया मात्रा प्रविष्ट गर्नुहोस्",
    picture: "तस्वीर",
    redemptionToken: "मोचन टोकन",
    token: "टोकन",
    totalSpent: "कुल खर्च",
    confirmPayment: "भुक्तानी पुष्टि गर्नुहोस्",
    hasPaymentBeenMade: "के भुक्तानी सफलतापूर्वक प्रशोधन गरिएको छ?",
    enterTransactionReference:
      "लेनदेन सन्दर्भ तपाइँ संग भुक्तानी प्रविष्ट गर्नुहोस्",
    pleaseSelectACustomer: "कृपया एक ग्राहक छान्नुहोस्!",
    areYouSureToApplyDiscount:
      "के तपाइँ पक्का हुनुहुन्छ कि तपाइँ छुट लागू गर्न चाहानुहुन्छ?",
    addYourBankAccountToEnableUssd:
      "UPay द्वारा तत्काल USSD स्थानान्तरण सक्षम गर्न आफ्नो बैंक खाता जोड्नुहोस्",
    totalAmountToPay: "तिर्नु पर्ने कुल रकम",
    doYouWantToCancelTransaction: "के तपाइँ यो लेनदेन रद्द गर्न चाहानुहुन्छ?",
    savePrintBill: "बचत/प्रिन्ट बिल",
    enterAmountCollectedForCustomer:
      "ग्राहक को लागी संकलित रकम प्रविष्ट गर्नुहोस्",
    recordSale: "बिक्री बिक्री",
    checkOutWith: "संग जाँच गर्नुहोस्",
    instantTransfer: "तत्काल स्थानान्तरण",
    dialTheUSSDCode: "USSD कोड डायल गर्नुहोस्",
    pleaseSelectABank: "कृपया एउटा बैंक छान्नुहोस्",
    payWithUSSD: "USSD संग तिर्नुहोस्",
    sendBillTo: " - बिल पठाउनुहोस्",
    waitingForUSSDTransfer: "USSD स्थानान्तरण को लागी प्रतीक्षा गर्दै",
    percent: "प्रतिशत",
    applyDiscount: "छुट लागू गर्नुहोस्",
    thisBillHasBeenSaved: "यो विधेयक सुरक्षित गरिएको छ",
    saveDraft: "ड्राफ्ट सुरक्षित गर्नुहोस्",
    pleaseTypeANameToIdentifyCustomer:
      "ग्राहक को पहिचान गर्न को लागी एक नाम टाइप गर्नुहोस्",
    paymentDetails: "भुक्तानी विवरण",
    basePrice: "आधार मूल्य",
    staff: "कर्मचारी",
    subTotal: "उप योग",
    durationMonths: "अवधि (महिना)",
    selectADuration: "एक अवधि छान्नुहोस्",
    oneMonth: "1 महिना",
    twoMonths: "२ महिना",
    threeMonths: "3 महिना",
    sixMonths: "Mon महिना",
    twelveMonths: "12 महिना",
    eighteenMonths: "१ Mon महिना",
    twentyFourMonths: "24 महिना",
    twoMonthsFree: "(२ महिना नि: शुल्क)",
    threeMonthsFree: "(3 महिना नि: शुल्क)",
    fiveMonthsFree: "(५ महिना नि: शुल्क)",
    yourAccountHasBeen: "तपाइँको खाता भएको छ",
    renewed: "नवीकरण",
    upgraded: "अपग्रेड गरियो",
    successfully: "सफलतापूर्वक",
    yourSubscription: "तपाइँको सदस्यता",
    youAreCurrentlyEnrolledOnA: "तपाइँ हाल एक मा भर्ना हुनुहुन्छ",
    pleaseChooseAPaymentOption: "कृपया एक भुक्तानी विकल्प छान्नुहोस्",
    planRenewal: "नवीकरण योजना",
    planUpgrade: "योजना अपग्रेड",
    pleaseSelectDurationToPayFor:
      "कृपया अवधि को लागी तपाइँ तिर्न चाहानुहुन्छ चयन गर्नुहोस्",
    staffAccounts: "कर्मचारी लेखा",
    ecommerce: "ईकमर्स",
    pleaseSelectAPlan: "कृपया एउटा योजना छान्नुहोस्",
    includeAddons: "Add-ons समावेश गर्नुहोस्",
    viewTransactions: "लेनदेन हेर्नुहोस्",
    customerHasNoCompletedTansactions:
      "ग्राहक अझै सम्म कुनै पूरा tansactions छ",
    branch: "साखा",
    enterNumberOfEcommerceBranches:
      "Ecommerce शाखाहरु को संख्या प्रविष्ट गर्नुहोस्",
    enterNumberOfEcommerceBranchesToPay:
      "Ecommerce शाखाहरु को संख्या तपाइँ को लागी तिर्न को लागी प्रविष्ट गर्नुहोस्",
    ecommerceIntegration: "ईकमर्स एकीकरण",
    enterNumberOfBranches: "शाखाहरुको संख्या प्रविष्ट गर्नुहोस्",
    enterNumberOfAdditionalBranchesToPay:
      "थप शाखाहरु को संख्या तपाइँ को लागी तिर्न को लागी प्रविष्ट गर्नुहोस्",
    enterNumberOfStaffs: "कर्मचारी संख्या प्रविष्ट गर्नुहोस्",
    enterNumberOfStaffsToPayFor:
      "कर्मचारीहरु को संख्या तपाइँ को लागी तिर्न को लागी प्रविष्ट गर्नुहोस्",
    discountApplies: "छुट लागू हुन्छ",
    starsOnThe: "मा ताराहरु",
    offer: "प्रस्ताव",
    get: "पाउनुहोस्",
    moreStarsToRedeem: "रिडिम गर्न को लागी अधिक ताराहरु",
    taxVat: "कर (VAT)",
    callCashierToCompletePayment: "भुक्तानी पूरा गर्न कैशियर कल गर्नुहोस्",
    receipt: "रसिद",
    dear: "प्रिय",
    thankYouForYourOrderFindGoods:
      "तपाईंको अर्डर लागि धन्यवाद। कृपया निम्न सामानहरु आपूर्ति गरीएको पाउनुहोस्, सहमति अनुसार।",
    shippingNote: "ढुवानी नोट",
    enterShippingNote: "शिपिंग नोट प्रविष्ट गर्नुहोस्",
    shippingAddress: "ढुवानी ठेगाना",
    enterShippingAddress: "ढुवानी ठेगाना प्रविष्ट गर्नुहोस्",
    wellDoneYouAreDueToRedeem: "राम्रो काम! तपाइँ रिडिम गर्न को लागी हुनुहुन्छ",
    toGetYourRewardNextVisit:
      "तपाइँको अर्को यात्रा मा तपाइँको इनाम प्राप्त गर्न को लागी। धन्यवाद",
    pointsOnThe: "मा अंक",
    morePointsToRedeem: "रिडिम गर्न को लागी अधिक अंक",
    showCode: "कोड देखाउनुहोस्",
    toGetYourRewardOnNextVisit:
      "तपाइँको अर्को यात्रा मा तपाइँको इनाम प्राप्त गर्न को लागी। धन्यवाद",
    earn: "कमाउनुहोस्",
    delivaryNote: "वितरण नोट",
    draftSales: "ड्राफ्ट बिक्री",
    startDate: "सुरू मिति",
    endDate: "अन्त्य मिति",
    orders: "आदेश",
    checkout: "चेकआउट",
    noProductItem: "कुनै उत्पादन आइटम छैन",
    selectProductImage: "उत्पादन छवि चयन गर्नुहोस्",
    selectCountry: "देश चयन गर्नुहोस्",
    selectRegion: "राज्य/क्षेत्र छान्नुहोस्",
    printProductTag: "उत्पादन ट्याग प्रिन्ट गर्नुहोस्",
    transactionReference: "लेनदेन सन्दर्भ",
    Cashier: "खजांची",
    Manager: "प्रबन्धक",
    Owner: "मालिक",
    Admin: "व्यवस्थापक",
    addPartners: "साझेदारहरू थप्नुहोस्",
    addNewLoyaltyPartner: "नयाँ वफादार साझेदार थप्नुहोस्",
    pleaseEnterCompanyName: "कृपया कम्पनीको नाम प्रविष्ट गर्नुहोस्",
    companyName: "कम्पनीको नाम",
    pleaseEnterCompanyRepName:
      "कृपया कम्पनी प्रतिनिधिको नाम प्रविष्ट गर्नुहोस्",
    companyRepName: "कम्पनी प्रतिनिधिको नाम",
    pleaseEnterCompanyRepEmail:
      "कृपया कम्पनी प्रतिनिधिको इमेल प्रविष्ट गर्नुहोस्",
    companyRepEmail: "कम्पनी प्रतिनिधिको इमेल",
    pleaseEnterCompanyRepPhone:
      "कृपया कम्पनी प्रतिनिधिको फोन नम्बर प्रविष्ट गर्नुहोस्",
    companyRepPhone: "कम्पनी प्रतिनिधिको फोन नम्बर",
    addReward: "इनाम थप्नुहोस्",
    pleaseEnterRewardName: "कृपया पुरस्कारको नाम प्रविष्ट गर्नुहोस्",
    rewardName: "पुरस्कार नाम",
    rewardQuantity: "पुरस्कार मात्रा",
    rewardDescription: "पुरस्कार विवरण",
    rewardType: "पुरस्कार प्रकार",
    pleaseEnterRewardType: "कृपया पुरस्कार प्रकार प्रविष्ट गर्नुहोस्",
    pleaseEnterRewardQuantity: "कृपया पुरस्कार मात्रा प्रविष्ट गर्नुहोस्",
    pleaseEnterRewardDescription: "कृपया इनाम विवरण प्रविष्ट गर्नुहोस्",
    fineDining: "राम्रो भोजन",
    luxuryFashion: "लक्जरी फेसन",
    hotels: "होटलहरू",
    travel: "यात्रा",
    foodAndBeverage: "खाना र पेय पदार्थ",
    fashion: "फेसन",
    health: "स्वास्थ्य",
    furniture: "फर्निचर",
    entertainment: "मनोरञ्जन",
    automobile: "अटोमोबाइल",
    education: "शिक्षा",
    beautyAndSpa: "सौन्दर्य र स्पा",
    staycation: "बसाई",
    events: "घटनाहरू",
    trips: "यात्राहरू",
    oilAndGas: "तेल र ग्यास",
    laundry: "लुगा धुने",
    partnerCategory: "साझेदार कोटि",
    freeItem: "नि: शुल्क वस्तु",
  },
  Norwegian: {
    cashier: "kasserer",
    manager: "sjef",
    owner: "Eieren",
    online: "på nett",
    offline: "offline",
    changePassword: "Bytt passord",
    currentPasswordMessage: "Skriv inn ditt nåværende passord",
    passwordMessage: "Skriv inn passordet ditt",
    currentPassword: "Nåværende passord",
    password: "passord",
    confirmPasswordMessage: "Bekreft passordet ditt!",
    confirmPassword: "bekreft passord",
    sendViaEmail: "Send via e -post",
    sendViaWhatsapp: "Send via WhatsApp",
    downloadPdf: "Last ned PDF",
    paid: "betalt",
    unpaid: "ulønnet",
    partial: "delvis",
    closeInvoice: "Vil du lukke fakturaen?",
    closeInvoiceConfirmation: "Faktura kan ikke lagres. Vil du lukke?",
    yes: "ja",
    no: "Nei",
    invoice: "Faktura",
    wasDeducted: "ble trukket fra",
    for: "til",
    item: "Punkt",
    addProduct: "Legg til produkt",
    paymentReference: "Betalingsreferanse",
    amountPaid: "Beløpet betalt",
    discountAmount: "Rabattbeløp",
    amountDue: "Skyldig beløp",
    amount: "Beløp",
    dueDate: "Tidsfrist",
    paymentType: "Betalings type",
    card: "Kort",
    cash: "Penger",
    bankTransfer: "Bankoverføring",
    paymentMessage: "Betalingsmelding",
    description: "Beskrivelse",
    sendReceipt: "Send kvittering",
    delete: "Slett",
    save: "Lagre",
    resend: "Send på nytt",
    saveAndSend: "Lagre",
    invoiceSaved: "Faktura lagret!",
    selectPaymentMethod: "Velg en betalingsmåte!",
    selectCustomer: "Velg en kunde!",
    cartEmptyError:
      "Handlekurvliste kan ikke være tom, lukk fakturaen og legg elementet til handlekurven!",
    subscriptionExpired:
      "Abonnementet ditt er utløpt, og kontoen din er nå begrenset. Klikk på knappen nedenfor for å fornye kontoen din",
    renew: "Fornye",
    holdOn: "Vent litt",
    customerBank: "Kundebank",
    cancel: "Avbryt",
    selectACustomer: "Velg Kunde",
    invoiceSuccessfulPdfError:
      "Faktura er opprettet, men PDF -generering tar lengre tid enn vanlig. Kom tilbake snart.",
    downloadingInvoice: "Last ned faktura",
    downloadingReceipt: "Last ned kvittering",
    whatsappReceiptError:
      "Det oppstod en feil under sending av kvitteringen via WhatsApp. Prøv igjen.",
    receiptToWhatsapp: "Kvittering videresendt til WhatsApp",
    thankYouForPatronage: "Takk for din beskyttelse",
    hereIsYourReceipt: "Her er betalingskvitteringen din",
    errorSendingEmailReceipt:
      "Det oppstod en feil under sending av kvitteringen via e-post. Prøv igjen eller kontakt kundestøtte",
    receiptSentToEmail: "Kvitteringen er sendt til kundens e -post",
    invoiceSentToEmail: "Faktura er sendt til kundens e -post",
    invoiceSuccessWhatsappError:
      "Fakturaen ble opprettet, men det oppstod en feil under sending til WhatsApp. Prøv igjen på fakturalisten",
    invoiceSuccessfulEmailError:
      "Fakturaen ble opprettet, men det oppstod en feil under sending som e-post. Prøv på nytt fra fakturalisten",
    invoiceSentToWhatsapp: "Faktura videresendt til WhatsApp",
    hello: "Hallo",
    pleaseDownloadInvoice: "Last ned faktura",
    pleaseDownloadReceipt: "Last ned kvittering",
    from: "fra",
    email: "E -post",
    upgrade: "Oppgradering",
    youAreOnFreePlan: "Du er på en gratis plan.",
    clickOn: "Klikk på",
    yourPlanInFewSteps: " abonnementet ditt i noen få raske trinn.",
    to: "til",
    yourSubscriptionHasExpired:
      "Abonnementet ditt er utløpt, og kontoen din er nå begrenset.",
    days: "dager",
    yourSubscriptionExpiresIn: "Loystar -abonnementet ditt utløper om",
    createAcount: "Opprett en konto",
    signIn: "Logg inn",
    continue: "Fortsette",
    enterOtp: "Skriv inn OTP -PIN",
    enterValidOtp: "Skriv inn en gyldig PIN -kode",
    pin: "PIN -kode",
    tokenSentToMail: "Et tegn er sendt til e -posten din",
    passwordResetSuccessful: "Tilbakestilling av passord var vellykket",
    somethingWentWrong: "Noe gikk galt!",
    resetPassword: "Tilbakestille passord",
    iHaveResetCode: "Jeg har en kode for tilbakestilling av passord",
    pleaseEnterEmail: "Skriv inn e -posten din",
    aTokenWillBeSentToEmail: "Et tegn vil bli sendt til e -posten din",
    enterEmail: "Skriv inn din email",
    sendinYourToken: "Sender ditt token ...",
    makeSureItMatchesPassword:
      "Sørg for at det samsvarer med det nye passordet ditt",
    pleaseChooseNewPassword: "Velg et nytt passord",
    chooseNewPassword: "Velg et nytt passord",
    enterNewPassword: "Skriv inn det nye passordet for å bekrefte",
    enterTokenSent: "Skriv inn tokenet som ble sendt din e -post",
    resetToken: "Tilbakestill token",
    resettingPassword: "Tilbakestiller passordet ditt ...",
    signUp: "Melde deg på",
    adminSignInWithEmail:
      " Administrator logger på med e -post mens ansatte logger på med brukernavn.",
    pleaseEnterEmailOrUsername: "Skriv inn din e -postadresse eller brukernavn",
    emailOrUsername: "E-post eller brukernavn",
    pleaseEnterPassword: "Skriv inn passord",
    createAnAccount: "Opprett en konto",
    forgotPassword: "Glemt passord?",
    enterPhoneNumber: "Skriv inn telefonnummer",
    personalData: "Personlig informasjon",
    validateConfirmationCOde: "Bekreft bekreftelseskoden",
    pleaseEnterFirstName: "Skriv inn fornavnet ditt",
    pleaseEnterPhoneNumber: "Skriv inn telefonnummeret ditt",
    pleaseEnterLastName: "Vennligst fyll inn ditt etternavn",
    pleaseEnterBusinessName: "Skriv inn bedriftsnavnet ditt",
    firstName: "Fornavn",
    lastName: "Etternavn",
    businessName: "Firmanavn",
    previous: "Tidligere",
    next: "Neste",
    pleaseSelectBusinessCategory: "Velg din bedriftskategori",
    pleaseEnterValidEmail: "Vennligst skriv inn en gyldig e-postadresse",
    pleaseEnterPostCode: "Skriv inn postnummer",
    postCode: "Postnummer",
    phoneNumberInUse: "Dette telefonnummeret er allerede i bruk!",
    emailInUse: "Denne e -posten er allerede i bruk!",
    foodAndDrinks: "Mat og Drikke",
    salonAndBeauty: "Salong og skjønnhet",
    fashionAndAccessories: "Mote og tilbehør",
    gymAndFitness: "Treningsstudio og trening",
    travelAndHotel: "Reise og hotell",
    homeAndGifts: "Hjem og gaver",
    washingAndCleaning: "Vask og rengjøring",
    gadgetsAndElectronics: "Gadgets og elektronikk",
    groceries: "Dagligvarer",
    others: "Andre",
    submit: "Sende inn",
    accountCreatedSuccessful: "Kontoen din ble opprettet.",
    pleaseEnterAddress: "Vennligst skriv inn adressen din",
    addressLine1: "Adresselinje 1",
    addressLine2: "Adresselinje 2",
    choosePassword: "Velge passord",
    passwordMustBe6Characters: "Passordet må inneholde minst 6 tegn.",
    howDidYouHearLoystar: "Hvordan hørte du om Loystar?",
    referralCode: "Henvisningskode",
    byClickingTheButton: " Ved å klikke på knappen nedenfor godtar du",
    termsAndSevice: "Vilkår",
    wereCreatingAccount: "Vi oppretter kontoen din",
    proceed: "Fortsette",
    verificationCodeMustBe6: "Bekreftelseskoden må inneholde 6 sifre",
    pleaseEnter6DigitCode: "Skriv inn 6 -sifret kode",
    enterVerificationCode: "Angi verifikasjonskode",
    resendToken: "Send Token på nytt",
    verify: "Bekrefte",
    transactions: "Transaksjoner",
    todaySales: "Dagens salg",
    salesHistory: "Salgshistorikk",
    supplyHistory: "Forsyningshistorie",
    new: "Ny",
    invoices: "Fakturaer",
    disbursements: "Utbetalinger",
    offlineSales: "Frakoblet salg",
    products: "Produkter",
    customers: "Kunder",
    multiLevelLoyalty: "Lojalitet på flere nivåer",
    loyaltyPrograms: "Lojalitetsprogrammer",
    members: "Medlemmer",
    appStore: "App Store",
    orderMenu: "Bestill meny",
    settings: "Innstillinger",
    staffAndBranches: "Staber og grener",
    myAccount: "Min konto",
    switchToSellMode: "Bytt til salgsmodus",
    signOut: "Logg ut",
    getFreeSubscription: "Få gratis abonnement",
    onlyNumbersAllowed: "Bare tall er tillatt",
    yourAccountMustBe10Digits:
      "kontonummeret ditt må være et 10 -sifret nummer",
    yourBvnMustBe11: "BVN må være et 11 -sifret nummer",
    pleaseSelectBank: "Velg banken din",
    selectYourBank: "Velg din bank",
    enterBankAccountNumber: "Skriv inn bankkontonummer",
    enterBvnNumber: "Skriv inn BVN",
    connectBankAccount: "Koble til bankkonto",
    passwordResetSuccessfulAndSignOut:
      "Passordet ditt har blitt tilbakestilt. Klikk på Logg av -knappen nedenfor for å logge på igjen",
    enterCurrentPassword: "Skriv inn nåværende passord",
    pleaseEnterCurrentPassword: "Skriv inn gjeldende passord",
    phoneNumber: "Telefonnummer",
    sex: "Kjønn",
    dateOfBirth: "Fødselsdato",
    state: "Stat",
    country: "Land",
    loyaltyId: "Lojalitets -ID",
    createdAt: "Opprettet kl",
    noOfTransactionsMade: "Antall transaksjoner utført",
    yourDownloadWillStart: "Nedlastingen starter om et øyeblikk",
    exportCustomers: "Eksporter kunder",
    youhaveSuccessfullyToppedUp: "Du har fylt opp SMS -enhetene dine.",
    youNowHave: "Du har nå",
    smsUnits: "SMS -enheter",
    enterNumberOfSmsUnits: "Skriv inn antall SMS -enheter du har tenkt å kjøpe",
    pleaseEnterANumericValue: "Skriv inn en numerisk verdi",
    pay: "Betale",
    accountEditedSuccessfully: "Kontoen din ble redigert.",
    youAeCurrentlyOn: "Du er på nå",
    plan: "Plan",
    userData: "Brukerdata",
    businessData: "FORRETNINGSDATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Velg valutaen din",
    selectYourCurrency: "Velg valuta",
    purchaseMoreSmsUnits:
      "Kjøp flere SMS -enheter ved å bruke skjemaet nedenfor",
    youHave: "Du har",
    atLeast4SmsUnitsRrequired:
      "Minst 4 smsenheter kreves for bekreftelse, fyll på!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Bekreft bankkontoen din for å overholde kravene til "Kjenn din kunde" (KYC). Dette vil tillate deg å samle inn betalinger via USSD eller umiddelbar overføring, motta bestillinger fra kunder og behandle transaksjonsoppgjør. Gjelder kun nigerianske kjøpmenn. Trykk på knappen nedenfor for å begynne.',
    reConnectBankAccount: "Koble til bankkontoen på nytt",
    accountName: "Brukernavn",
    accountNumber: "Kontonummer",
    bankName: "Bank navn",
    verified: "Verifisert",
    accountDetails: "kontodetaljer",
    kycBankAccount: "KYC",
    createTier: "Lag nivå",
    fileUploadSuccessful: "filen er lastet opp",
    fileUploadFailed: "filopplasting mislyktes",
    createLoyaltyProgram: "Lag et lojalitetsprogram",
    createLoyalty: "Lag lojalitet",
    name: "Navn",
    loyaltyArtwork: "Lojalitetskunstverk",
    clickToUpload: "Klikk for å laste opp",
    amountToPointsRatio: "Beløp til poengforhold",
    points: "Poeng",
    recommendedAmountToPointRatio:
      "Anbefalt: to 1 til 1 poeng. dvs. for hver 1 Naira som brukes, får kundene dine 1 poeng",
    pleaseTypeIn: "Skriv inn",
    toDeleteLoyalty: "å slette denne lojaliteten",
    deleteLoyalty: "Slett lojalitet",
    toConfirm: "å bekrefte",
    edit: "Redigere",
    pointsAwardedSuccessfully: "Poeng tildelt vellykket.",
    enterPointValueToAward: "Angi poengverdi du vil tildele kunder",
    award: "Tildele",
    awardPointValuesToCustomer: "Tildel poengverdi til kunden",
    enrollMembersToLoyalty: "Registrer medlemmer til lojalitet",
    awardPoints: "Tildel poeng",
    enroll: "Registrere",
    home: "Hjem",
    loyalty: "Lojalitet",
    enrollCustomers: "Registrer kunder",
    selected: "Valgt",
    customer: "Kunde",
    loyaltyActivationSuccessful: "Lojalitetsaktivering er vellykket.",
    loyaltyDeactivationSuccessful: "Lojalitetsdeaktivering er vellykket.",
    viewTier: "Se Tier",
    deactivate: "Avaktiver",
    activate: "Aktiver",
    actions: "Handlinger",
    nameOfLoyalty: "Lojalitetens navn",
    loyaltyStatus: "Lojalitetsstatus Lojalitetsstatus",
    numberOfTiers: "Antall nivåer",
    createdOn: "Laget på",
    createATier: "Lag et nivå",
    stopCreatingTierConfirmation: "Vil du slutte å lage et nivå?",
    stopEditingTierConfirmation: "Vil du slutte å redigere dette nivået?",
    nameOfTier: "Navn på Tier",
    minimumSpendingTarget: "Minimum forbruksmål",
    maximumSpendingTarget: "Maksimalt forbruksmål",
    minimumSpendingTargetRequired: "minsteforbruksmål kreves",
    maximumSpendingTargetRequired: "maksimalt utgiftsmål kreves",
    rewardSponsor: "Belønningssponsor",
    pleaseChooseARewardSponsor: "Velg en belønningssponsor",
    self: "Selv",
    partner: "Samboer",
    rewardPartner: "Belønningspartner",
    pleaseSelectRewardPartner: "Velg belønningspartneren din",
    rewards: "Belønninger",
    pleaseSelectAReward: "Velg en belønning",
    instructionsOnRedeemingReward:
      "Instruksjoner om hvordan kunden skal innløse belønning",
    pleaseFillInThisField: "Vennligst fyll ut dette feltet!",
    toDeleteThisTier: " for å slette dette nivået",
    deleteTier: "Slett nivå",
    viewMembers: "Se medlemmer",
    membersEnrolled: "Medlemmer påmeldt",
    instruction: "Instruksjon",
    membersIn: "Medlemmer i",
    availableTiersInLoyalty: "Tilgjengelige nivåer i lojalitetsprogrammet",
    tiers: "Lag",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Tilgjengelig lojalitetsprogram",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "Medlemsdetaljer",
    nameOfCustomer: "Navn på kunde",
    address: "Adresse",
    allEnrolledMembers: "Alle registrerte medlemmer",
    thisIsToWishYouHappyBirthday:
      "Dette er for å ønske deg en veldig god bursdag og et velstående liv. Takk for alt du er for Loyster. God feiring!",
    inputAnOfferPlease: "Legg inn et tilbud",
    pleaseSelectTheInsertPoint:
      "Velg innsettingspunktet i meldingen og klikk igjen",
    birthdayOfferAndMessage: "Bursdagstilbud og melding",
    birthdayOffer: "Bursdagstilbud",
    birthdayMessage: "Bursdagsmelding",
    noOfferFound: "Ingen tilbud funnet",
    settingABirthdayOffer:
      "Når du angir et bursdagstilbud, kan kundene motta dette tilbudet via SMS på bursdagen",
    createOffer: "Lag tilbud",
    whatIsTheOffer: "Hva er tilbudet?",
    editMessage: "Rediger melding",
    insert: "Sett inn",
    theNameOfCustomerInserted: "Navnet på kunden blir satt inn her",
    theBirtdayOfferInserted: "Bursdagstilbudet settes inn her",
    youSuccessfullyAddedNewBranch: "Du har lagt til en ny gren!",
    addNewBranch: "Legg til ny gren",
    addBranch: "Legg til gren",
    additionalBranchWillIncur: "Ytterligere filial vil pådra seg",
    perBranch: "per gren",
    ecommerceBranchCosts: "Kostnader for netthandel",
    pleaseEnterBranchName: "Skriv inn filialnavnet",
    pleaseEnterBranchAddress1: "Skriv inn filialens adresselinje 1",
    enterBranchAddress1: "Skriv inn filialens adresselinje 1",
    enterBranchAddress2: "Skriv inn filialens adresselinje 1",
    pleaseEnterBranchAddress2: "Skriv inn filialens adresselinje 2",
    enterBranchName: "Skriv inn filialnavnet",
    successfullyAddedStaff: "Du har lagt til en ny stab!",
    addNewStaff: "Legg til nytt personale",
    addStaff: "Legg til ansatte",
    additionalStaffWillIncur: "Ytterligere ansatte vil pådra seg",
    perStaff: "per stab.",
    pleaseEnterStaffEmail: "Skriv inn personalets e -post",
    pleaseEnterStaffUsername: "Skriv inn personalets brukernavn",
    pleaseEnterStaffPassword: "Skriv inn personalets passord",
    pleaseSelectStaffRole: "Velg personalets rolle",
    selectStaffRole: "Velg personalets rolle",
    enterStaffEmail: "Skriv inn personalets e -post",
    enterStaffUsername: "Skriv inn personalets brukernavn",
    enterStaffPassword: "Skriv inn personalets passord",
    spacesNotAllowedInUsername: "mellomrom som ikke er tillatt i brukernavnet",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff: "Velg bedrift for å knytte ansatte",
    searchForBranchToAttachStaff: "Søk etter filial for å feste ansatte",
    username: "Brukernavn",
    role: "Rolle",
    areYouSureToDeleteThis: "Er du sikker på å slette dette",
    branches: "Grener",
    upgradeYourPlan: "Oppgrader planen din.",
    add: "LEGGE TIL",
    addNew: "Legg til ny",
    customerWithEmailAlreadyExists:
      "Kunde med e -post/telefonnummer finnes allerede!",
    successfullyAddedNewCustomer: "Du har lagt til en ny kunde!",
    addCustomer: "Legg til kunde",
    pleaseEnterCustomerFirstName: "Skriv inn kundens fornavn",
    pleaseEnterCustomerLastName: "Skriv inn kundens etternavn",
    pleaseEnterCustomerPhoneNumber: "Angi kundens telefonnummer",
    pleaseEnterCustomerEmail: "Skriv inn kundens e -postadresse",
    pleaseEnterCustomerAddressLine: "Skriv inn kundens adresselinje",
    pleaseEnterCustomerOtherAddress: "Skriv inn kundens andre adresse",
    pleaseSelectCustomerGender: "Velg kundens kjønn",
    gender: "Kjønn",
    male: "Hann",
    female: "Hunn",
    bank: "Bank",
    selectBank: "Velg Bank",
    stateOrRegionOrProvince: "Stat/region/provins",
    customerNotes: "Kundenotater",
    sendSms: "Send tekstmelding",
    editCustomer: "Rediger kunde",
    redeemReward: "Innløs belønning",
    issueLoyaltyCard: "Utsted lojalitetskort",
    deleteCustomer: "Slett kunde",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Du har tilordnet en ID for lojalitetsmedlemskap",
    noMembershipIdAvailable:
      "Ingen medlems -ID -er tilgjengelig. Ta kontakt med oss på hello@loystar.co",
    sendEmail: "Send e-post",
    membershipPoints: "Medlemskapspoeng",
    customerDetails: "kundedetaljer",
    close: "Lukk",
    loyaltyBalance: "Lojalitetsbalanse",
    pointsBalance: "Poengbalanse",
    starBalance: "Star Balance",
    requiredPoints: "Påkrevde poeng",
    requiredStars: "Nødvendige stjerner",
    reddemCode: "Innløse kode",
    toDeleteThisCustomer: "å slette denne kunden",
    customerHasBeenDeletedSuccessfully: "Kunden er slettet!",
    customerWithPhoneAlreadyExists: "Kunde med telefonnummer finnes allerede",
    customerWasSuccessfullyEdited: "Kunden ble redigert",
    anErrorOccured: "En feil oppstod",
    phoneNumberIncludeCountry: "Telefonnummer (Inkluder landskode)",
    yourFileQueuedForUpload:
      "Filen din har stått i kø for opplasting. Oppdater siden etter noen sekunder.",
    thereWasAnErrorPerformingOperation:
      "Det oppstod en feil under operasjonen!",
    pleaseSelectFile: "Velg en fil!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Bare CSV -filer er tillatt. Last opp en .csv -fil.",
    fileShouldBeSmallerThan5Mb:
      "Filen skal være mindre enn 5 MB. Hvis du har en større fil, kan du sende en e -post til support@loystar.co. Takk skal du ha",
    customerFirstNameIsRequired: "kundens fornavn kreves på rad",
    customerPhoneNumberIsRequired: "kundetelefonnummer kreves på rad",
    importCustomers: "Importer kunder",
    upload: "Laste opp",
    clickIconToDownloadCsv:
      "Klikk på dette ikonet for å laste ned CSV -filmal.",
    getGoogleSheetVersion: "Last ned Google -arkversjonen",
    clickOrDragFileToUpload:
      "Klikk eller dra filen til dette området for å laste opp",
    missingOrInvalidColumnHeader:
      "Manglende eller ugyldig kolonneoverskrift. Følg malformatet. Takk skal du ha.",
    youHaveImported: "Du har importert",
    youSuccessfullyRedeemedReward: "Du har løst inn belønningen din!",
    sixDigitCode: "Sekssifret kode",
    pleaseEnterCustomerRewardCode: "Skriv inn kundens belønningskode",
    enterRewardCode:
      "Skriv inn belønningskode. (Belønningskoden er bokstavfølsom)",
    selectLoyaltyProgram: "Velg lojalitetsprogram",
    stamps: "Frimerker",
    smsUnitsLow: "SMS -enheter lav",
    pleaseTopUpSmsUnits: "Vennligst fyll opp SMS -enheter",
    smsSuccessfullySentForDelivery: "SMS ble sendt for levering!",
    sendSmsTo: "Send SMS til",
    allCustomers: "Alle kunder",
    unitsAvailable: "Enheter tilgjengelig",
    pleaseTypeInTheMessage: "Skriv inn meldingen",
    message: "Beskjed",
    charactersRemaining: "gjenstående karakterer",
    avoidUseOfSpecialCharacters:
      "Unngå bruk av spesialtegn og emoji for å bevare SMS -enheter.",
    note: "Merk",
    errorFetchingCustomersMultilevelDetail:
      "Feil ved henting av detaljer på flere nivåer for kunder",
    search: "Søk",
    reset: "Nullstille",
    importCustomer: "Importer kunde",
    addNewCustomer: "Legg til ny kunde",
    sendSmsBroadcast: "Send SMS -sending",
    totalCustomers: "TOTAL KUNDER",
    disbursementDetails: "Utbetalingsdetaljer",
    paidBy: "Betalt av",
    disbursed: "Utbetalt",
    bankAccountName: "Navn på bankkonto",
    bankAccountNumber: "Bankkontonummer",
    transferInitiated: "Overføring påbegynt",
    transferCompleted: "Overføringen er fullført",
    pleaseEnterAValid: "Vennligst skriv inn en gyldig",
    begin: "begynne",
    end: "slutt",
    date: "Dato",
    paymentDate: "Betalingsdato",
    selectDisbursementDuration: "Velg Utbetalingstid",
    totalSettled: "Totalt oppgjort",
    totalUnsettled: "Totalt usett",
    toDeleteThisSale: "for å slette dette salget",
    draftSaleDeletedSuccessfully: "Utkastssalget er slettet!",
    deleteSale: "Slett salg",
    pleaseTypeInYourTotal: "Skriv inn summen din for å bekrefte slettingen",
    billDetails: "Regningsdetaljer",
    printBill: "Skriv ut regning",
    servedBy: "Serveres av",
    total: "Total",
    createdDate: "opprettet dato",
    createdTime: "Opprettet tid",
    status: "Status",
    loginSuccessful: "Vellykket innlogging",
    pleaseWaitWhileWeConnectAccount: "Vent mens vi kobler til kontoen din",
    connectionFailedTryAgain:
      "Tilkoblingen mislyktes. Vær så snill, prøv på nytt.",
    connectionSuccessful: "Tilkobling vellykket",
    viewDetails: "Vis detaljer",
    enable: "Muliggjøre",
    free: "Gratis",
    cost: "Koste",
    visitWebsite: "Besøk nettsiden",
    pleaseUpgradeYourPlanToPro:
      "Oppgrader planen din til Pro Plus for å aktivere denne appen",
    connectYourBankAccount:
      "Koble til bankkontoen din for å kunne motta betalinger.",
    disable: "Deaktiver",
    enableApp: "Aktiver app",
    addNewProductToInvoice: "Legg til nytt produkt på faktura",
    toDeleteThisInvoice: "for å slette denne fakturaen",
    invoiceDeletedSuccessfully: "Fakturaen er slettet!",
    deleteInvoice: "Slett faktura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Du har lagt til en faktura -betalingsmelding!",
    pleaseEnterPaymentInstructions: "Skriv inn betalingsinstruksjoner",
    invoiceId: "Faktura -ID",
    paidDate: "Betalt dato",
    reference: "Henvisning",
    productAdded: "Produkt lagt til",
    productOutOfStock: "Produktet er utsolgt. Vennligst lager på lager.",
    totalInvoices: "TOTALE FAKTURER",
    totalPaidInvoices: "TOTALT BETALTE FAKTURER",
    totalUnpaidInvoices: "TOTALE UBETALTE FAKTURER",
    loyaltyProgramDeleted: "Lojalitetsprogram slettet",
    thisLoyaltyProgramDeletedSuccessfully:
      "Dette lojalitetsprogrammet har blitt slettet",
    thisLoyaltyProgramEditedSuccessfully:
      "Dette lojalitetsprogrammet har blitt redigert",
    loyaltyProgramAddedSuccessfully: "Lojalitetsprogrammet er lagt til",
    loyaltyProgramEdited: "Lojalitetsprogram redigert",
    loyaltyProgramAdded: "Lojalitetsprogram lagt til",
    loyaltyDetails: "Lojalitetsdetaljer",
    doYouWantToCloseDialog: "Vil du lukke denne dialogboksen?",
    pleaseEnterLoyaltyName: "Skriv inn navnet på din lojalitet",
    programType: "Programtype",
    pleaseSelectProgramType: "Velg en programtype",
    simplePoints: "Enkle poeng",
    stampsProgram: "Program for frimerker",
    threshold: "Terskel",
    pleaseEnterLoyaltyThreshold: "Angi lojalitetsgrensen",
    reward: "Belønning",
    pleaseEnterLoyaltyReward: "Skriv inn lojalitetsbelønningen",
    totalUserPoints: "Totalt antall brukerpoeng",
    totalUserStamps: "Totale brukerstempler",
    spendingTarget: "Forbruksmål",
    spendingTargetHint1:
      "Forbruksmål er hvor mye en kunde må bruke for å tjene belønningen. 1 valutaverdi = 1 poeng.",
    spendingTargetHint2:
      "Frimerkemål er hvor mange frimerker en kunde må samle for å tjene belønningen. f.eks. 5",
    addNewLoyaltyProgram: "Legg til nytt lojalitetsprogram",
    addLoyaltyProgram: "Legg til lojalitetsprogram",
    loyaltyProgramType: "Lojalitetsprogramtype",
    pleaseSelectLoyaltyProgramType: "Velg lojalitetsprogramtype",
    nameOfProgram: "Navn på program",
    pleaseEnterProgramName: "Skriv inn programnavn",
    whatIsTheReward: "Hva er belønningen?",
    egNextPurchaseIsFree: "F.eks. Neste kjøp er gratis",
    customerName: "Kundenavn",
    guestCustomer: "Gjestekunde",
    orderConfirmedSuccessfully: "Bestilling bekreftet",
    orderCancelledSuccessfully: "Bestillingen ble kansellert",
    confirmOrder: "Bekreft ordre",
    cancelOrder: "Avbryt bestillingen",
    allOrders: "Alle bestillinger",
    totalOrders: "TOTALE BESTILLINGER",
    doYouWantToAcceptOrder: "Vil du godta denne bestillingen?",
    doYouWantToCancelOrder: "Vil du kansellere denne bestillingen?",
    orderDetails: "Ordre detaljer",
    orderCreatedAt: "Ordre opprettet kl",
    supplier: "Leverandør",
    productName: "Produktnavn",
    quantity: "Mengde",
    unitPrice: "Enhetspris",
    receivedBy: "Mottatt av",
    reportFrom: "Rapport fra",
    totalSupplies: "Totalt antall forsyninger",
    allRightsReserved: "Alle rettigheter forbeholdt",
    toDeleteThisTransaction: "for å slette denne transaksjonen",
    transactionDeletedSuccessfully:
      "Transaksjonen er slettet. Lageret er returnert til lageret.",
    deleteTransaction: "Slett transaksjonen",
    transactionDetails: "Transaksjonsdetaljer",
    printReceipt: "Skriv ut kvittering",
    channel: "Kanal",
    discount: "Rabatt",
    profit: "Profitt",
    discountedSales: "Rabatterte salg",
    errorFetchingRecord: "Feil ved henting av post",
    exportTransactions: "Eksporter transaksjoner",
    errorFetchingSalesRecord: "Feil ved henting av salgsrekord for eksport.",
    totalSellingPrice: "Total salgspris",
    totalCostPrice: "Total kostpris",
    appliedDiscount: "Anvendt rabatt",
    noOfItems: "Antall varer",
    sales: "Salg",
    export: "Eksport",
    totalProfit: "Total profitt",
    totalBalanceInPeriod: "Total saldo i periode",
    allTimeSales: "Hele tiden salg",
    records: "Rekorder",
    todaysSales: "Dagens salg",
    transaction: "transaksjon",
    youHaveExceededTotalNumberOfProducts:
      "Du har overskredet det totale antallet produkter som er tillatt i planen din. Oppgrader planen din for å nyte en høyere grense.",
    youNeedToHaveLoyaltyProgram:
      "Du må ha et lojalitetsprogram for å bruke denne funksjonen!",
    price: "Pris",
    category: "Kategori",
    stockTracking: "Sporing av aksjer",
    stockCount: "Antall aksjer",
    taxed: "Skattet",
    originalPrice: "Original pris",
    costPrice: "Innkjøpspris",
    unit: "Enhet",
    productImage: "Produktbilde",
    taxRate: "Skattesats",
    taxType: "Skattetype",
    trackInventory: "Spor beholdning",
    variants: "Varianter",
    hasVariants: "Har varianter",
    importProduct: "Importer produkt",
    exportProducts: "Eksporter produkter",
    addNewProduct: "Legg til nytt produkt",
    viewCategory: "Se kategori",
    viewSuppliers: "Se leverandører",
    receiveInventory: "Motta beholdning",
    printAllProductsTag: "Skriv ut alle produkter",
    deleteAll: "Slette alle",
    totalProducts: "TOTALE PRODUKTER",
    youveSuccessfullyAddedANewCategory: "Du har lagt til en ny kategori",
    addNewCategory: "Legg til ny kategori",
    addCategory: "Legg til kategori",
    categoryName: "kategori navn",
    pleaseEnterCategoryName: "Skriv inn kategorinavn",
    stampsTarget: "Frimerker Mål",
    sendInventory: "Send beholdning",
    productDetails: "produkt detaljer",
    youveSuccessfullyEditedThisCategory: "Du har redigert denne kategorien",
    update: "Oppdater",
    categoryList: "Kategoriliste",
    categories: "Kategorier",
    enterQuantityToUpdate: "Skriv inn en mengde som skal oppdateres",
    inventorySentSuccessfully: "Beholdningen ble sendt.",
    updateInventory: "Oppdater beholdning",
    currentQuantity: "Gjeldende mengde",
    pleaseEnterQuantityToAdd: "Skriv inn mengden du vil legge til",
    pleaseSelectABranch: "Velg en gren",
    searchForBranch: "Søk etter gren",
    youCantSendMoreThanStock: "Du kan ikke sende mer enn du har på lager",
    send: "Sende",
    pleaseEnterQuantityToSend: "Skriv inn mengden du vil sende",
    productNameIsRequiredOnRow: "produktnavn kreves på rad",
    productCategoryIsRequiredOnRow: "produktkategori er påkrevd på rad",
    productPriceIsRequiredOnRow: "produktpris kreves på rad",
    productUnitIsRequiredOnRow: "produktsenhet kreves på rad",
    productQuantityIsRequiredOnRow: "produktmengde kreves på rad",
    productVariantsRequireTracking: "produktvarianter krever sporing!",
    pleaseAddVariantClickButton:
      "Legg til en variant ved å klikke på Legg til -knappen!",
    totalVariantsMoreThanSelectedQuantity:
      "Totale varianter er mer enn valgt produktmengde, Pls reduserer variantmengde",
    productEditedSuccessfully: "Produktet har blitt redigert!",
    fileTooLargeLessThan4Mb:
      "Filstørrelsen er for stor! Filstørrelsen skal være mindre enn 4 MB.",
    suchVariantAlreadyExist: "En slik variant finnes allerede",
    addVariants: "Legg til varianter",
    editProduct: "Rediger produkt",
    pleaseEnterProductName: "Vennligst skriv inn produktnavn",
    pleaseEnterProductPrice: "Skriv inn produktpris",
    pleaseEnterProductOriginalPrice: "Angi produktets opprinnelige pris",
    productDescription: "produktbeskrivelse",
    selectProductCategory: "Velg produktkategori",
    pleaseSelectProductCategory: "Velg produktkategori",
    productCostPrice: "Produktkostnadspris",
    productSellingPrice: "Produktsalgspris",
    productOriginalPrice: "Produktets opprinnelige pris",
    maxFileSizeAllowedIs4Mb: "Maks. Tillatt filstørrelse er 4 MB",
    productsWithPicturesArePublished:
      "Produkter med bilder publiseres på Discover for å motta bestillinger",
    shouldThisProductBeTracked: "Bør dette produktet spores?",
    pleaseSelectStockUnit: "Velg lagerenhet",
    stockUnit: "Lagerenhet",
    bag: "BAG",
    bottle: "FLASKE",
    bunch: "BUNCH",
    can: "KAN",
    carton: "KARTONG",
    crate: "KASSE",
    cup: "KOPP",
    dozen: "DUSIN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKKE",
    pair: "PAR",
    pieces: "STYKKER",
    plate: "TALLERKEN",
    tonne: "TONNE (MT)",
    uNIT: "ENHET",
    yard: "YARD",
    pleaseEnterProductQuantity: "Angi produktmengde",
    productQuantity: "Produktantall",
    isThisProductTaxed: "Er dette produktet beskattet?",
    selectTaxType: "Velg Skattetype",
    pleaseSelectTaxType: "Velg skattetype",
    progressive: "Progressiv",
    proportional: "Proporsjonalt",
    pleaseEnterProductTaxRate: "Angi produktsats",
    doesProductHaveVariants: "Har dette produktet varianter?",
    type: "Type",
    value: "Verdi",
    pleaseEnterVariantType: "Angi variantens type",
    pleaseEnterVariantValue: "Angi variantens verdi",
    pleaseEnterVariantPrice: "Angi variantens pris",
    pleaseEnterVariantQuantity: "Angi variantens mengde",
    productDeletedSuccessfully: "Produktet er slettet!",
    categoryDeletedSuccessfully: "Kategorien er slettet!",
    toDeleteThisProduct: "for å slette dette produktet",
    invalidProductQuantity: "Ugyldig produktmengde",
    quantityAddedIsOverStock:
      "Mengden du legger til er over det du har på lager.",
    itemInventoryNotTracked: "Varelageret spores ikke",
    addBulkQuantity: "Legg til mengde",
    enterBulkQuantity: "Angi bulk mengde",
    pleaseEnterBulkQuantity: "Angi Bulk Mengde",
    youveSuccessfullyAddedANewProduct: "Du har lagt til et nytt produkt!",
    pleaseEnterProductSellingPrice: "Angi produktsalgspris",
    doYouWantToTrackProductStock: "Vil du spore produktlager?",
    sellingPrice: "Salgspris",
    setProductExpiryReminder: "Angi påminnelse om produktutløp",
    productExpiryDate: "Produktets utløpsdato",
    startRemindingFrom: "Start påminnelse fra",
    productSuppliesAddedSuccessfully: "Du har lagt til produktrekvisita.",
    addProductSupplies: "Legg til produktrekvisita",
    pleaseSelectSupplier: "Velg leverandør",
    nameOfProduct: "Navn på produkt",
    pleaseSelectProduct: "Velg produkt",
    productVariant: "Produktvariant",
    pleaseSelectAVariant: "Velg en variant",
    pleaseEnterUnitPrice: "Angi enhetspris",
    businessBranch: "Forretningsfilial",
    pleaseSelectBranch: "Velg gren",
    youveSuccessfullyAddedANewSupplier: "Du har lagt til en ny leverandør",
    addSupplier: "Legg til leverandør",
    pleaseEnterSupplierEmail: "Skriv inn leverandørens e -postadresse",
    pleaseAddADescription: "Legg til en beskrivelse",
    anErrorOccuredProductsDeleted:
      "Det oppstod en feil under operasjonen. Vær oppmerksom på at få produkter kan ha blitt slettet i prosessen",
    bulkDelete: "Masse sletting",
    youAreAboutToDelete: "Du er i ferd med å slette",
    product: "Produkt",
    isDueToRedeem: "skyldes innløsning",
    aReward: "en belønning",
    pleaseSelectCustomerToReeemReward:
      "Velg en kunde for å løse inn belønningen.",
    youHaveNoLoyaltyProgramRunning: "Du har ikke noe aktivt lojalitetsprogram",
    customerHhasNoPointsInLoyaltyProgram:
      "Kunden har ingen poeng i dette lojalitetsprogrammet",
    proceedWithPayment: "Fortsette med betaling?",
    youAreAboutToPayForTransactionUsingPoints:
      "Du er i ferd med å betale for transaksjonen med poeng.",
    customerHas: "Kunden har",
    worth: "verdi",
    andIsNotSufficientToPayForTransaction:
      "og er ikke tilstrekkelig til å betale for denne transaksjonen. Vil de legge til saldoen ved hjelp av en annen betalingsmåte?",
    addCustomerLoyalty: "Legg til kundelojalitet",
    pleaseAddCustomerFirst: "Legg til eller velg en kunde først.",
    pleaseWaitWhileWeGetReady: "Vent litt mens vi gjør oss klare",
    lowStock: "Lavt lager",
    pleaseEnterAmountTendered: "Angi anbudssummen",
    areYouSureToBookSaleOffline:
      "Er du sikker på at du vil bestille dette salget offline?",
    saleWouldBeBookedAutomatically:
      "Salget vil bli booket automatisk når du slår på internett",
    offlineSalesBookingCancelled: "Offline salgsbestilling kansellert",
    covid19Message:
      "COVID19: Vask hendene med såpe eller desinfiser for å stoppe spredningen. Vær alltid trygg",
    saleSuccessfullyRecorded: "Salget ble registrert!",
    sendReceiptToEmail: "Send kvittering til e -post",
    sendThankYouSms: "Send takk SMS",
    sendShippingDetails: "Send forsendelsesdetaljer",
    addLoyalty: "Legg til lojalitet",
    searchCustomerNameOrNumber: "Søk etter kundenavn eller nummer",
    clickTheSearchCustomerBox: "Klikk på Søk kundeboks og skannekort",
    enterProductPrice: "Skriv inn produktpris",
    enterPriceFor: "Skriv inn pris for",
    searchForProduct: "Søk etter produkt",
    all: "Alle",
    backToDashboard: "Tilbake til oversikten",
    viewDraftSales: "Se utkast til salg",
    variantSelected: "variant valgt",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Det forrige variantutvalget er ikke tilgjengelig for den nye varianten som er valgt basert på pris, vennligst endre valget.",
    pleaseSupplyPositiveQuantityNumber: "Oppgi et postivt mengdenummer",
    lowStockFor: "Lite lager for",
    clearVariants: "Tydelige varianter",
    pleaseEnterQuantity: "Skriv inn antall",
    picture: "Bilde",
    redemptionToken: "Innløsningstoken",
    token: "Token",
    totalSpent: "Totalt brukt",
    confirmPayment: "Bekreft betaling",
    hasPaymentBeenMade: "Har betalingen blitt behandlet vellykket?",
    enterTransactionReference:
      "Skriv inn transaksjonsreferansen du betalte med",
    pleaseSelectACustomer: "Velg en kunde!",
    areYouSureToApplyDiscount: "Er du sikker på at du vil bruke rabatt?",
    addYourBankAccountToEnableUssd:
      "Legg til bankkontoen din for å aktivere umiddelbar USSD -overføring med uPay",
    totalAmountToPay: "Totalt beløp å betale",
    doYouWantToCancelTransaction: "Vil du kansellere denne transaksjonen?",
    savePrintBill: "Lagre/Skriv ut regning",
    enterAmountCollectedForCustomer:
      "Skriv inn beløpet som er samlet inn for kunden",
    recordSale: "Platesalg",
    checkOutWith: "Sjekk ut med",
    instantTransfer: "Umiddelbar overføring",
    dialTheUSSDCode: "Slå USSD -koden",
    pleaseSelectABank: "Velg en bank",
    payWithUSSD: "Betal med USSD",
    sendBillTo: " - Send Bill til",
    waitingForUSSDTransfer: "Venter på USSD -overføring",
    percent: "Prosent",
    applyDiscount: "Bruk rabatt",
    thisBillHasBeenSaved: "Denne regningen er lagret",
    saveDraft: "Lagre utkast",
    pleaseTypeANameToIdentifyCustomer:
      "Skriv inn et navn for å identifisere kunden",
    paymentDetails: "Betalingsinformasjon",
    basePrice: "Grunnpris",
    staff: "Personale",
    subTotal: "Delsum",
    durationMonths: "Varighet (måneder)",
    selectADuration: "Velg en varighet",
    oneMonth: "1 måned",
    twoMonths: "2 måneder",
    threeMonths: "3 måneder",
    sixMonths: "6 måneder",
    twelveMonths: "12 måneder",
    eighteenMonths: "18 måneder",
    twentyFourMonths: "24 måneder",
    twoMonthsFree: "(2 måneder gratis)",
    threeMonthsFree: "(3 måneder gratis)",
    fiveMonthsFree: "(5 måneder gratis)",
    yourAccountHasBeen: "Kontoen din har vært",
    renewed: "fornyet",
    upgraded: "oppgradert",
    successfully: "vellykket",
    yourSubscription: "Abonnementet ditt",
    youAreCurrentlyEnrolledOnA: "Du er for tiden påmeldt på en",
    pleaseChooseAPaymentOption: "Velg et betalingsalternativ",
    planRenewal: "Planfornyelse",
    planUpgrade: "Planlegg oppgradering",
    pleaseSelectDurationToPayFor: "Velg varighet du har tenkt å betale for",
    staffAccounts: "Personalregnskap",
    ecommerce: "Netthandel",
    pleaseSelectAPlan: "Velg en plan",
    includeAddons: "Inkluder tillegg",
    viewTransactions: "Se transaksjoner",
    customerHasNoCompletedTansactions:
      "Kunden har ikke fullført transaksjoner ennå",
    branch: "Gren",
    enterNumberOfEcommerceBranches: "Skriv inn antall netthandelsgrener",
    enterNumberOfEcommerceBranchesToPay:
      "Skriv inn antall netthandelsgrener du har tenkt å betale for",
    ecommerceIntegration: "Integrering av netthandel",
    enterNumberOfBranches: "Skriv inn antall grener",
    enterNumberOfAdditionalBranchesToPay:
      "Skriv inn antall ekstra grener du har tenkt å betale for",
    enterNumberOfStaffs: "Skriv inn antall ansatte",
    enterNumberOfStaffsToPayFor:
      "Skriv inn antall ansatte du har tenkt å betale for",
    discountApplies: "Rabatt gjelder",
    starsOnThe: "stjerner på",
    offer: "by på",
    get: "Få",
    moreStarsToRedeem: "flere stjerner å løse inn",
    taxVat: "Skatt (moms)",
    callCashierToCompletePayment: "Ring kassereren for å fullføre betalingen",
    receipt: "Kvittering",
    dear: "Kjære",
    thankYouForYourOrderFindGoods:
      "Takk for din bestilling. Vennligst finn følgende varer levert, som avtalt.",
    shippingNote: "Fraktmerknad",
    enterShippingNote: "Skriv inn forsendelsesnotat",
    shippingAddress: "Sendingsadresse",
    enterShippingAddress: "Skriv inn leveringsadresse",
    wellDoneYouAreDueToRedeem: "Bra gjort! Du skal innløse",
    toGetYourRewardNextVisit:
      "for å få belønningen din ved ditt neste besøk. Takk skal du ha",
    pointsOnThe: "Poeng på",
    morePointsToRedeem: "flere poeng å innløse",
    showCode: "Vis kode",
    toGetYourRewardOnNextVisit:
      "for å få belønningen din ved ditt neste besøk. Takk skal du ha",
    earn: "Tjene",
    delivaryNote: "Leveringsnotat",
    draftSales: "Utkast til salg",
    startDate: "Startdato",
    endDate: "Sluttdato",
    orders: "Ordrene",
    checkout: "Sjekk ut",
    noProductItem: "Ingen produktvare",
    selectProductImage: "Velg produktbilde",
    selectCountry: "Velg land",
    selectRegion: "Velg stat/region",
    printProductTag: "Skriv ut produktmerke",
    transactionReference: "Transaksjonsreferanse",
    Cashier: "Kasserer",
    Manager: "sjef",
    Owner: "Eieren",
    Admin: "Admin",
    addPartners: "Legg til partnere",
    addNewLoyaltyPartner: "Legg til ny lojalitetspartner",
    pleaseEnterCompanyName: "Vennligst skriv inn firmanavn",
    companyName: "selskapsnavn",
    pleaseEnterCompanyRepName: "Vennligst skriv inn firmarepresentantens navn",
    companyRepName: "Navn på selskapets representant",
    pleaseEnterCompanyRepEmail:
      "Vennligst skriv inn e-postadressen til selskapets representant",
    companyRepEmail: "E-post til selskapets rep",
    pleaseEnterCompanyRepPhone:
      "Vennligst skriv inn telefonnummeret til selskapets representant",
    companyRepPhone: "Telefonnummer til firmarepresentant",
    addReward: "Legg til belønning",
    pleaseEnterRewardName: "Skriv inn belønningsnavnet",
    rewardName: "Belønningsnavn",
    rewardQuantity: "Belønningsmengde",
    rewardDescription: "Belønningsbeskrivelse",
    rewardType: "Type belønning",
    pleaseEnterRewardType: "Skriv inn belønningstype",
    pleaseEnterRewardQuantity: "Vennligst skriv inn belønningsantall",
    pleaseEnterRewardDescription: "Vennligst skriv inn belønningsbeskrivelse",
    fineDining: "Fine Dining",
    luxuryFashion: "Luksus mote",
    hotels: "Hoteller",
    travel: "Reise",
    foodAndBeverage: "Mat og Drikke",
    fashion: "Mote",
    health: "Helse",
    furniture: "Møbler",
    entertainment: "Underholdning",
    automobile: "Bil",
    education: "utdanning",
    beautyAndSpa: "Skjønnhet og Spa",
    staycation: "Staycation",
    events: "arrangementer",
    trips: "Turer",
    oilAndGas: "Olje og gass",
    laundry: "Klesvask",
    partnerCategory: "Partnerkategori",
    freeItem: "Gratis vare",
  },
  Marathi: {
    cashier: "रोखपाल",
    manager: "व्यवस्थापक",
    owner: "मालक",
    online: "ऑनलाइन",
    offline: "ऑफलाइन",
    changePassword: "पासवर्ड बदला",
    currentPasswordMessage: "कृपया तुमचा वर्तमान संकेतशब्द प्रविष्ट करा",
    passwordMessage: "कृपया तुमचा पासवर्ड एंटर करा",
    currentPassword: "सध्याचा गुप्त शब्द",
    password: "पासवर्ड",
    confirmPasswordMessage: "कृपया आपल्या संकेतशब्दाची पुष्टी करा!",
    confirmPassword: "पासवर्डची पुष्टी करा",
    sendViaEmail: "ईमेल द्वारे पाठवा",
    sendViaWhatsapp: "WhatsApp द्वारे पाठवा",
    downloadPdf: "PDF डाउनलोड करा",
    paid: "पैसे दिले",
    unpaid: "न भरलेले",
    partial: "आंशिक",
    closeInvoice: "तुम्हाला बीजक बंद करायचे आहे का?",
    closeInvoiceConfirmation:
      "बीजक जतन केले जाऊ शकत नाही. तुम्हाला बंद करायचे आहे का?",
    yes: "होय",
    no: "नाही",
    invoice: "बीजक",
    wasDeducted: "वजा केले होते",
    for: "च्या साठी",
    item: "आयटम",
    addProduct: "उत्पादन जोडा",
    paymentReference: "दिलेल्या रकमेचा संदर्भ",
    amountPaid: "भरलेली रक्कम",
    discountAmount: "सवलत रक्कम",
    amountDue: "देय रक्कम",
    amount: "रक्कम",
    dueDate: "देय तारीख",
    paymentType: "पैसे भरण्याची पध्दत",
    card: "कार्ड",
    cash: "रोख",
    bankTransfer: "बँक हस्तांतरण",
    paymentMessage: "पेमेंट संदेश",
    description: "वर्णन",
    sendReceipt: "पावती पाठवा",
    delete: "हटवा",
    save: "जतन करा",
    resend: "पुन्हा पाठवा",
    saveAndSend: "जतन करा",
    invoiceSaved: "चलन जतन केले!",
    selectPaymentMethod: "कृपया पेमेंट पद्धत निवडा!",
    selectCustomer: "कृपया ग्राहक निवडा!",
    cartEmptyError:
      "कार्ट सूची रिक्त असू शकत नाही, चलन बंद करा आणि कार्टमध्ये आयटम जोडा!",
    subscriptionExpired:
      "तुमची सदस्यता कालबाह्य झाली आहे आणि तुमचे खाते आता मर्यादित आहे. तुमचे खाते रिन्यू करण्यासाठी खालील बटणावर क्लिक करा",
    renew: "नूतनीकरण करा",
    holdOn: "थांबा",
    customerBank: "ग्राहक बँक",
    cancel: "रद्द करा",
    selectACustomer: "ग्राहक निवडा",
    invoiceSuccessfulPdfError:
      "चालान यशस्वीरित्या तयार केले परंतु PDF जनरेशन नेहमीपेक्षा जास्त वेळ घेत आहे. कृपया थोड्या वेळाने परत तपासा.",
    downloadingInvoice: "बीजक डाउनलोड करत आहे",
    downloadingReceipt: "पावती डाउनलोड करत आहे",
    whatsappReceiptError:
      "व्हॉट्सअॅपद्वारे पावती पाठवताना एरर आली, कृपया पुन्हा प्रयत्न करा.",
    receiptToWhatsapp: "पावती व्हॉट्सअॅपला पाठवली",
    thankYouForPatronage: "आपल्या संरक्षणासाठी धन्यवाद",
    hereIsYourReceipt: "ही तुमची पेमेंट पावती आहे",
    errorSendingEmailReceipt:
      "ई-मेलद्वारे पावती पाठवताना एक त्रुटी आली, कृपया पुन्हा प्रयत्न करा किंवा समर्थनाशी संपर्क साधा",
    receiptSentToEmail: "ग्राहकाच्या ईमेलवर पावती पाठवली गेली आहे",
    invoiceSentToEmail: "ग्राहकाच्या ईमेलवर चलन पाठवण्यात आले आहे",
    invoiceSuccessWhatsappError:
      "चालान यशस्वीरित्या तयार केले परंतु व्हॉट्सअॅपवर पाठवताना एक त्रुटी आली. बीजक सूचीवर पुन्हा प्रयत्न करा",
    invoiceSuccessfulEmailError:
      "चलन यशस्वीरित्या तयार केले परंतु ई-मेल म्हणून पाठवताना एक त्रुटी आली. कृपया बीजक सूचीमधून पुन्हा प्रयत्न करा",
    invoiceSentToWhatsapp: "व्हॉट्सअॅपला फॉरवर्ड केलेले बीजक",
    hello: "नमस्कार",
    pleaseDownloadInvoice: "कृपया पावती डाउनलोड करा",
    pleaseDownloadReceipt: "कृपया पावती डाउनलोड करा",
    from: "कडून",
    email: "ईमेल",
    upgrade: "श्रेणीसुधारित करा",
    youAreOnFreePlan: "आपण विनामूल्य योजनेवर आहात.",
    clickOn: "वर क्लिक करा",
    yourPlanInFewSteps: " काही जलद चरणांमध्ये आपली सदस्यता.",
    to: "ला",
    yourSubscriptionHasExpired:
      "तुमची सदस्यता कालबाह्य झाली आहे आणि तुमचे खाते आता मर्यादित आहे.",
    days: "दिवस",
    yourSubscriptionExpiresIn: "तुमची लॉयस्टार सदस्यता कालबाह्य होईल",
    createAcount: "एक खाते तयार करा",
    signIn: "साइन इन करा",
    continue: "सुरू",
    enterOtp: "OTP पिन टाका",
    enterValidOtp: "कृपया एक वैध पिन प्रविष्ट करा",
    pin: "पिन",
    tokenSentToMail: "तुमच्या ईमेलवर एक टोकन पाठवण्यात आले आहे",
    passwordResetSuccessful: "पासवर्ड रीसेट यशस्वी झाला",
    somethingWentWrong: "काहीतरी चूक झाली!",
    resetPassword: "पासवर्ड रीसेट करा",
    iHaveResetCode: "माझ्याकडे पासवर्ड रीसेट कोड आहे",
    pleaseEnterEmail: "कृपया तुमचे ईमेल एंटर करा",
    aTokenWillBeSentToEmail: "तुमच्या ईमेलवर एक टोकन पाठवले जाईल",
    enterEmail: "तुमचा ईमेल टाका",
    sendinYourToken: "तुमचे टोकन पाठवत आहे ...",
    makeSureItMatchesPassword:
      "तो तुमच्या नवीन पासवर्डशी जुळत असल्याची खात्री करा",
    pleaseChooseNewPassword: "कृपया नवीन पासवर्ड निवडा",
    chooseNewPassword: "नवीन पासवर्ड निवडा",
    enterNewPassword: "पुष्टी करण्यासाठी आपला नवीन संकेतशब्द प्रविष्ट करा",
    enterTokenSent: "तुमचा मेल पाठवलेला टोकन एंटर करा",
    resetToken: "टोकन रीसेट करा",
    resettingPassword: "तुमचा पासवर्ड रीसेट करत आहे ...",
    signUp: "साइन अप करा",
    adminSignInWithEmail:
      " प्रशासक ईमेलसह साइन इन करतो तर कर्मचारी वापरकर्तानावासह साइन इन करतात.",
    pleaseEnterEmailOrUsername:
      "कृपया आपले ईमेल किंवा वापरकर्तानाव प्रविष्ट करा",
    emailOrUsername: "ईमेल किंवा वापरकर्तानाव",
    pleaseEnterPassword: "कृपया पासवर्ड एंटर करा",
    createAnAccount: "एक खाते तयार करा",
    forgotPassword: "पासवर्ड विसरलात?",
    enterPhoneNumber: "फोन नंबर प्रविष्ट करा",
    personalData: "वैयक्तिक माहिती",
    validateConfirmationCOde: "पुष्टीकरण कोड सत्यापित करा",
    pleaseEnterFirstName: "कृपया तुमचे नाव प्रविष्ट करा",
    pleaseEnterPhoneNumber: "कृपया तुमचा फोन नंबर टाका",
    pleaseEnterLastName: "कृपया तुमचे आडनाव टाका",
    pleaseEnterBusinessName: "कृपया तुमच्या व्यवसायाचे नाव एंटर करा",
    firstName: "पहिले नाव",
    lastName: "आडनाव",
    businessName: "व्यवसायाचे नाव",
    previous: "मागील",
    next: "पुढे",
    pleaseSelectBusinessCategory: "कृपया तुमची व्यवसाय श्रेणी निवडा",
    pleaseEnterValidEmail: "एक वैध ईमेल प्रविष्ट करा",
    pleaseEnterPostCode: "कृपया पोस्ट कोड प्रविष्ट करा",
    postCode: "पिनकोड",
    phoneNumberInUse: "हा फोन नंबर आधीच वापरात आहे!",
    emailInUse: "हा ईमेल आधीच वापरात आहे!",
    foodAndDrinks: "अन्न आणि पेय",
    salonAndBeauty: "सलून आणि सौंदर्य",
    fashionAndAccessories: "फॅशन आणि अॅक्सेसरीज",
    gymAndFitness: "जिम आणि फिटनेस",
    travelAndHotel: "प्रवास आणि हॉटेल",
    homeAndGifts: "घर आणि भेटवस्तू",
    washingAndCleaning: "धुणे आणि साफ करणे",
    gadgetsAndElectronics: "गॅझेट आणि इलेक्ट्रॉनिक्स",
    groceries: "किराणा माल",
    others: "इतर",
    submit: "प्रस्तुत करणे",
    accountCreatedSuccessful: "आपले खाते यशस्वीरित्या तयार केले गेले.",
    pleaseEnterAddress: "कृपया तुमचा पत्ता एंटर करा",
    addressLine1: "पत्ता ओळ 1",
    addressLine2: "पत्ता ओळ 2",
    choosePassword: "पासवर्ड निवडा",
    passwordMustBe6Characters: "पासवर्ड किमान 6 वर्णांचा असणे आवश्यक आहे.",
    howDidYouHearLoystar: "आपण लॉयस्टारबद्दल कसे ऐकले?",
    referralCode: "रेफरल कोड",
    byClickingTheButton: " खालील बटणावर क्लिक करून, तुम्ही सहमत आहात",
    termsAndSevice: "अटी",
    wereCreatingAccount: "आम्ही तुमचे खाते तयार करत आहोत",
    proceed: "पुढे जा",
    verificationCodeMustBe6: "पडताळणी कोड 6 अंकी असणे आवश्यक आहे",
    pleaseEnter6DigitCode: "कृपया 6 अंकी कोड एंटर करा",
    enterVerificationCode: "सत्यापन कोड प्रविष्ट करा",
    resendToken: "टोकन पुन्हा पाठवा",
    verify: "पडताळणी करा",
    transactions: "व्यवहार",
    todaySales: "आजची विक्री",
    salesHistory: "विक्री इतिहास",
    supplyHistory: "पुरवठा इतिहास",
    new: "नवीन",
    invoices: "पावत्या",
    disbursements: "वितरण",
    offlineSales: "ऑफलाइन विक्री",
    products: "उत्पादने",
    customers: "ग्राहक",
    multiLevelLoyalty: "बहुस्तरीय निष्ठा",
    loyaltyPrograms: "निष्ठा कार्यक्रम",
    members: "सदस्य",
    appStore: "अॅप स्टोअर",
    orderMenu: "मेनू ऑर्डर करा",
    settings: "सेटिंग्ज",
    staffAndBranches: "कर्मचारी आणि शाखा",
    myAccount: "माझे खाते",
    switchToSellMode: "सेल मोडवर स्विच करा",
    signOut: "साइन आउट करा",
    getFreeSubscription: "मोफत सदस्यता मिळवा",
    onlyNumbersAllowed: "फक्त संख्यांना परवानगी आहे",
    yourAccountMustBe10Digits: "तुमचा खाते क्रमांक 10 अंकी असणे आवश्यक आहे",
    yourBvnMustBe11: "तुमचा BVN हा 11 अंकी क्रमांक असणे आवश्यक आहे",
    pleaseSelectBank: "कृपया तुमची बँक निवडा",
    selectYourBank: "तुमची बँक निवडा",
    enterBankAccountNumber: "बँक खाते क्रमांक प्रविष्ट करा",
    enterBvnNumber: "तुमचे BVN प्रविष्ट करा",
    connectBankAccount: "बँक खाते कनेक्ट करा",
    passwordResetSuccessfulAndSignOut:
      "तुमचा पासवर्ड यशस्वी रीसेट झाला आहे. पुन्हा साइन इन करण्यासाठी खालील साइन आउट बटणावर क्लिक करा",
    enterCurrentPassword: "वर्तमान संकेतशब्द प्रविष्ट करा",
    pleaseEnterCurrentPassword: "कृपया वर्तमान संकेतशब्द प्रविष्ट करा",
    phoneNumber: "फोन नंबर",
    sex: "सेक्स",
    dateOfBirth: "जन्मतारीख",
    state: "राज्य",
    country: "देश",
    loyaltyId: "लॉयल्टी आयडी",
    createdAt: "येथे तयार केले",
    noOfTransactionsMade: "केलेल्या व्यवहारांची संख्या",
    yourDownloadWillStart: "तुमचे डाउनलोड एका क्षणात सुरू होईल",
    exportCustomers: "निर्यात ग्राहक",
    youhaveSuccessfullyToppedUp:
      "तुम्ही तुमचे एसएमएस युनिट यशस्वीरित्या अव्वल केले आहे.",
    youNowHave: "आपल्याकडे आता आहे",
    smsUnits: "एसएमएस युनिट्स",
    enterNumberOfSmsUnits:
      "आपण खरेदी करण्याचा हेतू असलेल्या एसएमएस युनिट्सची संख्या प्रविष्ट करा",
    pleaseEnterANumericValue: "कृपया एक अंकीय मूल्य प्रविष्ट करा",
    pay: "पे",
    accountEditedSuccessfully: "तुमचे खाते यशस्वीरित्या संपादित केले गेले.",
    youAeCurrentlyOn: "तुम्ही सध्या चालू आहात",
    plan: "योजना",
    userData: "वापरकर्त्याची माहिती",
    businessData: "व्यवसाय डेटा",
    businessCategory: "व्यवसाय कॅटरगोरी",
    pleaseSelectCurrency: "कृपया तुमचे चलन निवडा",
    selectYourCurrency: "आपले चलन निवडा",
    purchaseMoreSmsUnits: "खालील फॉर्म वापरून अधिक एसएमएस युनिट्स खरेदी करा",
    youHave: "तुझ्याकडे आहे",
    atLeast4SmsUnitsRrequired:
      "पडताळणीसाठी किमान 4 एसएमएस युनिट्स आवश्यक आहेत, कृपया टॉप अप करा!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "कृपया 'तुमचे ग्राहक जाणून घ्या' (KYC) आवश्यकतांचे पालन करण्यासाठी तुमचे बँक खाते सत्यापित करा. हे आपल्याला यूएसएसडी किंवा झटपट हस्तांतरणाद्वारे देयके गोळा करण्यास, ग्राहकांकडून ऑर्डर प्राप्त करण्यास आणि व्यवहार निपटारावर प्रक्रिया करण्यास अनुमती देईल. फक्त नायजेरियन व्यापाऱ्यांना लागू. कृपया सुरू करण्यासाठी खालील बटण दाबा.",
    reConnectBankAccount: "बँक खाते पुन्हा कनेक्ट करा",
    accountName: "खात्याचे नाव",
    accountNumber: "खाते क्रमांक",
    bankName: "बँकेचे नाव",
    verified: "पडताळणी केली",
    accountDetails: "खात्याचा तपशील",
    kycBankAccount: "केवायसी",
    createTier: "टियर तयार करा",
    fileUploadSuccessful: "फाइल यशस्वीरित्या अपलोड केली",
    fileUploadFailed: "फाइल अपलोड अयशस्वी",
    createLoyaltyProgram: "एक निष्ठा कार्यक्रम तयार करा",
    createLoyalty: "निष्ठा निर्माण करा",
    name: "नाव",
    loyaltyArtwork: "निष्ठा कलाकृती",
    clickToUpload: "अपलोड करण्यासाठी क्लिक करा",
    amountToPointsRatio: "गुणोत्तर गुणोत्तर",
    points: "गुण",
    recommendedAmountToPointRatio:
      "शिफारस केलेले: ₦ 1 ते 1 पॉइंट. म्हणजे खर्च केलेल्या प्रत्येक 1 नायरासाठी, आपल्या ग्राहकांना 1 गुण मिळतो",
    pleaseTypeIn: "कृपया टाईप करा",
    toDeleteLoyalty: "ही निष्ठा हटवण्यासाठी",
    deleteLoyalty: "निष्ठा हटवा",
    toConfirm: "पुष्टी करण्यासाठी",
    edit: "सुधारणे",
    pointsAwardedSuccessfully: "गुण यशस्वीरित्या प्रदान केले.",
    enterPointValueToAward:
      "तुम्हाला ग्राहकांना पुरस्कार देऊ इच्छित बिंदू मूल्य प्रविष्ट करा",
    award: "पुरस्कार",
    awardPointValuesToCustomer: "ग्राहकाला पुरस्कार गुण",
    enrollMembersToLoyalty: "लॉयल्टीवर सदस्यांची नोंदणी करा",
    awardPoints: "पुरस्कार गुण",
    enroll: "नावनोंदणी करा",
    home: "मुख्यपृष्ठ",
    loyalty: "निष्ठा",
    enrollCustomers: "ग्राहकांची नोंदणी करा",
    selected: "निवडले",
    customer: "ग्राहक",
    loyaltyActivationSuccessful: "निष्ठा सक्रिय करणे यशस्वी आहे.",
    loyaltyDeactivationSuccessful: "निष्ठा निष्क्रिय करणे यशस्वी आहे.",
    viewTier: "टियर पहा",
    deactivate: "डी-अॅक्टिव्हेट करा",
    activate: "सक्रिय करा",
    actions: "क्रिया",
    nameOfLoyalty: "निष्ठेचे नाव",
    loyaltyStatus: "निष्ठा स्थिती निष्ठा स्थिती",
    numberOfTiers: "स्तरांची संख्या",
    createdOn: "तयार केले",
    createATier: "एक स्तर तयार करा",
    stopCreatingTierConfirmation: "आपण एक स्तर तयार करणे थांबवू इच्छिता?",
    stopEditingTierConfirmation: "तुम्हाला या स्तराचे संपादन थांबवायचे आहे का?",
    nameOfTier: "टियरचे नाव",
    minimumSpendingTarget: "किमान खर्चाचे लक्ष्य",
    maximumSpendingTarget: "जास्तीत जास्त खर्च करण्याचे लक्ष्य",
    minimumSpendingTargetRequired: "किमान खर्चाचे लक्ष्य आवश्यक आहे",
    maximumSpendingTargetRequired: "जास्तीत जास्त खर्चाचे लक्ष्य आवश्यक आहे",
    rewardSponsor: "बक्षीस प्रायोजक",
    pleaseChooseARewardSponsor: "कृपया बक्षीस प्रायोजक निवडा",
    self: "स्व",
    partner: "भागीदार",
    rewardPartner: "रिवॉर्ड पार्टनर",
    pleaseSelectRewardPartner: "कृपया तुमचा रिवॉर्ड पार्टनर निवडा",
    rewards: "बक्षिसे",
    pleaseSelectAReward: "कृपया एक बक्षीस निवडा",
    instructionsOnRedeemingReward:
      "ग्राहकाने रिवॉर्ड कसे रिडीम करावे याच्या सूचना",
    pleaseFillInThisField: "कृपया हे फील्ड भरा!",
    toDeleteThisTier: " हा स्तर हटवण्यासाठी",
    deleteTier: "टियर हटवा",
    viewMembers: "सदस्य पहा",
    membersEnrolled: "सदस्य नोंदणी केली",
    instruction: "सूचना",
    membersIn: "मधील सदस्य",
    availableTiersInLoyalty: "निष्ठा कार्यक्रमात उपलब्ध स्तरावर",
    tiers: "टायर्स",
    totalTier: "एकूण टायर",
    availableLoyaltyProgramme: "उपलब्ध निष्ठा कार्यक्रम",
    totalLoyalties: "एकूण निष्ठा",
    memberDetails: "सदस्य तपशील",
    nameOfCustomer: "ग्राहकाचे नाव",
    address: "पत्ता",
    allEnrolledMembers: "सर्व नोंदणीकृत सदस्य",
    thisIsToWishYouHappyBirthday:
      "हे तुम्हाला वाढदिवसाच्या आणि समृद्ध आयुष्यासाठी शुभेच्छा देण्यासाठी आहे. आपण लॉयस्टरचे सर्व धन्यवाद. आनंदोत्सव!",
    inputAnOfferPlease: "कृपया ऑफर प्रविष्ट करा",
    pleaseSelectTheInsertPoint:
      "कृपया संदेशातील अंतर्भूत बिंदू निवडा आणि पुन्हा क्लिक करा",
    birthdayOfferAndMessage: "वाढदिवसाची ऑफर आणि संदेश",
    birthdayOffer: "वाढदिवसाची ऑफर",
    birthdayMessage: "वाढदिवसाचा संदेश",
    noOfferFound: "कोणतीही ऑफर आढळली नाही",
    settingABirthdayOffer:
      "वाढदिवसाची ऑफर सेट करणे ग्राहकांना त्यांच्या वाढदिवसाच्या दिवशी एसएमएसद्वारे ही ऑफर प्राप्त करण्यास अनुमती देते",
    createOffer: "ऑफर तयार करा",
    whatIsTheOffer: "ऑफर काय आहे?",
    editMessage: "संदेश संपादित करा",
    insert: "घाला",
    theNameOfCustomerInserted: "येथे ग्राहकाचे नाव टाकले जाईल",
    theBirtdayOfferInserted: "वाढदिवसाची ऑफर येथे घातली जाईल",
    youSuccessfullyAddedNewBranch: "आपण यशस्वीरित्या एक नवीन शाखा जोडली आहे!",
    addNewBranch: "नवीन शाखा जोडा",
    addBranch: "शाखा जोडा",
    additionalBranchWillIncur: "अतिरिक्त शाखा लागतील",
    perBranch: "प्रति शाखा",
    ecommerceBranchCosts: "ईकॉमर्स शाखेचा खर्च",
    pleaseEnterBranchName: "कृपया शाखेचे नाव प्रविष्ट करा",
    pleaseEnterBranchAddress1: "कृपया शाखेची पत्ता ओळ 1 प्रविष्ट करा",
    enterBranchAddress1: "शाखेची पत्ता ओळ 1 प्रविष्ट करा",
    enterBranchAddress2: "शाखेची पत्ता ओळ 1 प्रविष्ट करा",
    pleaseEnterBranchAddress2: "कृपया शाखेची पत्ता ओळ 2 प्रविष्ट करा",
    enterBranchName: "शाखेचे नाव प्रविष्ट करा",
    successfullyAddedStaff: "आपण यशस्वीरित्या एक नवीन कर्मचारी जोडला आहे!",
    addNewStaff: "नवीन कर्मचारी जोडा",
    addStaff: "कर्मचारी जोडा",
    additionalStaffWillIncur: "अतिरिक्त कर्मचारी लागतील",
    perStaff: "प्रति कर्मचारी.",
    pleaseEnterStaffEmail: "कृपया कर्मचाऱ्यांचे ईमेल प्रविष्ट करा",
    pleaseEnterStaffUsername: "कृपया कर्मचारी वापरकर्तानाव प्रविष्ट करा",
    pleaseEnterStaffPassword: "कृपया कर्मचाऱ्यांचा पासवर्ड एंटर करा",
    pleaseSelectStaffRole: "कृपया कर्मचाऱ्यांची भूमिका निवडा",
    selectStaffRole: "कर्मचाऱ्यांची भूमिका निवडा",
    enterStaffEmail: "कर्मचाऱ्यांचा ईमेल टाका",
    enterStaffUsername: "कर्मचाऱ्यांचे वापरकर्तानाव प्रविष्ट करा",
    enterStaffPassword: "कर्मचाऱ्यांचा पासवर्ड टाका",
    spacesNotAllowedInUsername: "वापरकर्तानावात रिक्त स्थानांना परवानगी नाही",
    admin: "प्रशासक",
    pleaseSelectBusinessToAttachStaff:
      "कृपया कर्मचारी जोडण्यासाठी व्यवसाय निवडा",
    searchForBranchToAttachStaff: "कर्मचारी जोडण्यासाठी शाखा शोधा",
    username: "वापरकर्तानाव",
    role: "भूमिका",
    areYouSureToDeleteThis: "तुम्हाला हे हटवण्याची खात्री आहे का?",
    branches: "शाखा",
    upgradeYourPlan: "तुमची योजना श्रेणीसुधारित करा.",
    add: "जोडा",
    addNew: "नवीन जोडा",
    customerWithEmailAlreadyExists:
      "ईमेल/फोन नंबर असलेला ग्राहक आधीच अस्तित्वात आहे!",
    successfullyAddedNewCustomer: "आपण यशस्वीरित्या नवीन ग्राहक जोडला आहे!",
    addCustomer: "ग्राहक जोडा",
    pleaseEnterCustomerFirstName: "कृपया ग्राहकाचे नाव प्रविष्ट करा",
    pleaseEnterCustomerLastName: "कृपया ग्राहकाचे आडनाव टाका",
    pleaseEnterCustomerPhoneNumber: "कृपया ग्राहकाचा फोन नंबर प्रविष्ट करा",
    pleaseEnterCustomerEmail: "कृपया ग्राहकाचा ईमेल प्रविष्ट करा",
    pleaseEnterCustomerAddressLine: "कृपया ग्राहकांची पत्ता ओळ प्रविष्ट करा",
    pleaseEnterCustomerOtherAddress: "कृपया ग्राहकाचा दुसरा पत्ता टाका",
    pleaseSelectCustomerGender: "कृपया ग्राहकाचे लिंग निवडा",
    gender: "लिंग",
    male: "नर",
    female: "स्त्री",
    bank: "बँक",
    selectBank: "बँक निवडा",
    stateOrRegionOrProvince: "राज्य/प्रदेश/प्रांत",
    customerNotes: "ग्राहक नोट्स",
    sendSms: "एसएमएस पाठवा",
    editCustomer: "ग्राहक संपादित करा",
    redeemReward: "बक्षीस रिडीम करा",
    issueLoyaltyCard: "लॉयल्टी कार्ड जारी करा",
    deleteCustomer: "ग्राहक हटवा",
    youveSuccessfullyAssignedLoyaltyMembership:
      "तुम्ही लॉयल्टी मेंबरशिप आयडी यशस्वीरित्या नियुक्त केला आहे",
    noMembershipIdAvailable:
      "सदस्यत्व आयडी उपलब्ध नाही. कृपया आमच्याशी hello@loystar.co वर संपर्क साधा",
    sendEmail: "ई - मेल पाठवा",
    membershipPoints: "सदस्यत्व गुण",
    customerDetails: "ग्राहक तपशील",
    close: "बंद",
    loyaltyBalance: "निष्ठा शिल्लक",
    pointsBalance: "गुण शिल्लक",
    starBalance: "स्टार बॅलन्स",
    requiredPoints: "आवश्यक गुण",
    requiredStars: "आवश्यक तारे",
    reddemCode: "कोडची पूर्तता",
    toDeleteThisCustomer: "हा ग्राहक हटवण्यासाठी",
    customerHasBeenDeletedSuccessfully: "ग्राहक यशस्वीरित्या हटवला गेला आहे!",
    customerWithPhoneAlreadyExists:
      "फोन नंबर असलेला ग्राहक आधीच अस्तित्वात आहे",
    customerWasSuccessfullyEdited: "ग्राहक यशस्वीरित्या संपादित केला गेला",
    anErrorOccured: "त्रुटी आढळली",
    phoneNumberIncludeCountry: "फोन नंबर (देश कोड समाविष्ट करा)",
    yourFileQueuedForUpload:
      "तुमची फाईल अपलोड करण्यासाठी रांगेत आहे. कृपया काही सेकंदांनंतर पृष्ठ रीफ्रेश करा.",
    thereWasAnErrorPerformingOperation: "ऑपरेशन करताना एरर आली!",
    pleaseSelectFile: "कृपया एक फाइल निवडा!",
    oopsOnlyCsvFilesAllowed:
      "अरेरे! केवळ CSV फायलींना परवानगी आहे. कृपया .csv फाइल अपलोड करा.",
    fileShouldBeSmallerThan5Mb:
      "फाइल 5MB पेक्षा लहान असावी. आपल्याकडे मोठी फाइल असल्यास, कृपया support@loystar.co वर ईमेल करा. धन्यवाद",
    customerFirstNameIsRequired: "पंक्तीवर ग्राहकाचे नाव आवश्यक आहे",
    customerPhoneNumberIsRequired: "पंक्तीवर ग्राहक फोन नंबर आवश्यक आहे",
    importCustomers: "ग्राहक आयात करा",
    upload: "अपलोड करा",
    clickIconToDownloadCsv:
      "CSV फाइल टेम्पलेट डाउनलोड करण्यासाठी या चिन्हावर क्लिक करा.",
    getGoogleSheetVersion: "Google पत्रक आवृत्ती मिळवा",
    clickOrDragFileToUpload:
      "अपलोड करण्यासाठी या भागात फाइल क्लिक करा किंवा ड्रॅग करा",
    missingOrInvalidColumnHeader:
      "गहाळ किंवा अवैध स्तंभ शीर्षलेख. कृपया टेम्पलेट फॉरमॅट फॉलो करा. धन्यवाद.",
    youHaveImported: "आपण आयात केले आहे",
    youSuccessfullyRedeemedReward:
      "तुम्ही तुमचे रिवॉर्ड यशस्वीरित्या रिडीम केले आहे!",
    sixDigitCode: "सहा अंकी कोड",
    pleaseEnterCustomerRewardCode: "कृपया ग्राहकाचा रिवॉर्ड कोड एंटर करा",
    enterRewardCode: "रिवॉर्ड कोड एंटर करा. (रिवॉर्ड कोड केस संवेदनशील आहे)",
    selectLoyaltyProgram: "निष्ठा कार्यक्रम निवडा",
    stamps: "शिक्के",
    smsUnitsLow: "एसएमएस युनिट्स कमी",
    pleaseTopUpSmsUnits: "कृपया एसएमएस युनिट्स टॉप अप करा",
    smsSuccessfullySentForDelivery: "एसएमएस यशस्वीरित्या वितरणासाठी पाठविला!",
    sendSmsTo: "यांना एसएमएस पाठवा",
    allCustomers: "सर्व ग्राहक",
    unitsAvailable: "युनिट्स उपलब्ध",
    pleaseTypeInTheMessage: "कृपया संदेश टाईप करा",
    message: "संदेश",
    charactersRemaining: "उर्वरित वर्ण",
    avoidUseOfSpecialCharacters:
      "एसएमएस युनिट्सचे संरक्षण करण्यासाठी विशेष वर्ण आणि इमोजींचा वापर टाळा.",
    note: "टीप",
    errorFetchingCustomersMultilevelDetail:
      "ग्राहकांना बहुस्तरीय तपशील मिळवताना त्रुटी",
    search: "शोधा",
    reset: "रीसेट करा",
    importCustomer: "ग्राहक आयात करा",
    addNewCustomer: "नवीन ग्राहक जोडा",
    sendSmsBroadcast: "एसएमएस ब्रॉडकास्ट पाठवा",
    totalCustomers: "एकूण ग्राहक",
    disbursementDetails: "वितरण तपशील",
    paidBy: "द्वारे दिले",
    disbursed: "वितरित केले",
    bankAccountName: "बँक खात्याचे नाव",
    bankAccountNumber: "बँक खाते क्रमांक",
    transferInitiated: "हस्तांतरण सुरू केले",
    transferCompleted: "हस्तांतरण पूर्ण झाले",
    pleaseEnterAValid: "कृपया वैध प्रविष्ट करा",
    begin: "सुरू",
    end: "शेवट",
    date: "तारीख",
    paymentDate: "पगाराची तारीख",
    selectDisbursementDuration: "वितरण कालावधी निवडा",
    totalSettled: "एकूण सेटल झाले",
    totalUnsettled: "एकूण अस्वस्थ",
    toDeleteThisSale: "ही विक्री हटवण्यासाठी",
    draftSaleDeletedSuccessfully: "मसुदा विक्री यशस्वीरित्या हटविली गेली आहे!",
    deleteSale: "विक्री हटवा",
    pleaseTypeInYourTotal:
      "हटवण्याची पुष्टी करण्यासाठी कृपया आपले एकूण टाइप करा",
    billDetails: "बिल तपशील",
    printBill: "बिल प्रिंट करा",
    servedBy: "द्वारे सेवा केली",
    total: "एकूण",
    createdDate: "तयार करण्याची तारीख",
    createdTime: "निर्माण वेळ",
    status: "स्थिती",
    loginSuccessful: "लॉगिन यशस्वी",
    pleaseWaitWhileWeConnectAccount:
      "कृपया आम्ही आपले खाते कनेक्ट करत असताना प्रतीक्षा करा",
    connectionFailedTryAgain: "संपर्क खंडित. कृपया पुन्हा प्रयत्न करा.",
    connectionSuccessful: "कनेक्शन यशस्वी",
    viewDetails: "तपशील पहा",
    enable: "सक्षम करा",
    free: "फुकट",
    cost: "खर्च",
    visitWebsite: "वेबसाइटला भेट द्या",
    pleaseUpgradeYourPlanToPro:
      "कृपया हे अॅप सक्षम करण्यासाठी तुमची योजना प्रो प्लस वर श्रेणीसुधारित करा",
    connectYourBankAccount:
      "देयके प्राप्त करण्यास सक्षम होण्यासाठी आपले बँक खाते कनेक्ट करा.",
    disable: "अक्षम करा",
    enableApp: "अॅप सक्षम करा",
    addNewProductToInvoice: "इनव्हॉइसमध्ये नवीन उत्पादन जोडा",
    toDeleteThisInvoice: "हे चलन हटवण्यासाठी",
    invoiceDeletedSuccessfully: "चलन यशस्वीरित्या हटवले गेले आहे!",
    deleteInvoice: "बीजक हटवा",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "आपण यशस्वीरित्या एक चलन देयक संदेश जोडला आहे!",
    pleaseEnterPaymentInstructions: "कृपया पेमेंट सूचना प्रविष्ट करा",
    invoiceId: "बीजक आयडी",
    paidDate: "देय तारीख",
    reference: "संदर्भ",
    productAdded: "उत्पादन जोडले",
    productOutOfStock: "उत्पादन स्टॉकमध्ये नाही. कृपया पुन्हा स्टॉक करा.",
    totalInvoices: "एकूण पावत्या",
    totalPaidInvoices: "एकूण पेड इनवॉइस",
    totalUnpaidInvoices: "एकूण न भरलेली पावती",
    loyaltyProgramDeleted: "निष्ठा कार्यक्रम हटवला",
    thisLoyaltyProgramDeletedSuccessfully:
      "हा निष्ठा कार्यक्रम यशस्वीरित्या हटविला गेला आहे",
    thisLoyaltyProgramEditedSuccessfully:
      "हा निष्ठा कार्यक्रम यशस्वीरित्या संपादित केला गेला आहे",
    loyaltyProgramAddedSuccessfully:
      "निष्ठा कार्यक्रम यशस्वीरित्या जोडला गेला आहे",
    loyaltyProgramEdited: "निष्ठा कार्यक्रम संपादित",
    loyaltyProgramAdded: "निष्ठा कार्यक्रम जोडला",
    loyaltyDetails: "निष्ठा तपशील",
    doYouWantToCloseDialog: "तुम्हाला हा संवाद बंद करायचा आहे का?",
    pleaseEnterLoyaltyName: "कृपया तुमच्या निष्ठेचे नाव प्रविष्ट करा",
    programType: "कार्यक्रमाचा प्रकार",
    pleaseSelectProgramType: "कृपया प्रोग्राम प्रकार निवडा",
    simplePoints: "साधे गुण",
    stampsProgram: "शिक्के कार्यक्रम",
    threshold: "उंबरठा",
    pleaseEnterLoyaltyThreshold: "कृपया लॉयल्टी थ्रेशोल्ड एंटर करा",
    reward: "प्रतिफळ भरून पावले",
    pleaseEnterLoyaltyReward: "कृपया निष्ठेचे बक्षीस प्रविष्ट करा",
    totalUserPoints: "एकूण वापरकर्ता गुण",
    totalUserStamps: "एकूण वापरकर्ता स्टॅम्प",
    spendingTarget: "खर्च करण्याचे लक्ष्य",
    spendingTargetHint1:
      "बक्षीस मिळवण्यासाठी ग्राहकाने किती खर्च करावा हे लक्ष्य आहे. 1 चलन मूल्य = 1 गुण.",
    spendingTargetHint2:
      "स्टॅम्पचे लक्ष्य म्हणजे बक्षीस मिळवण्यासाठी ग्राहकाने किती स्टॅम्प गोळा केले पाहिजेत. उदा. 5",
    addNewLoyaltyProgram: "नवीन निष्ठा कार्यक्रम जोडा",
    addLoyaltyProgram: "निष्ठा कार्यक्रम जोडा",
    loyaltyProgramType: "निष्ठा कार्यक्रम प्रकार",
    pleaseSelectLoyaltyProgramType: "कृपया लॉयल्टी प्रोग्राम प्रकार निवडा",
    nameOfProgram: "कार्यक्रमाचे नाव",
    pleaseEnterProgramName: "कृपया प्रोग्रामचे नाव प्रविष्ट करा",
    whatIsTheReward: "बक्षीस काय आहे?",
    egNextPurchaseIsFree: "उदा. पुढील खरेदी विनामूल्य आहे",
    customerName: "ग्राहकाचे नाव",
    guestCustomer: "पाहुणे ग्राहक",
    orderConfirmedSuccessfully: "ऑर्डर यशस्वीरित्या पुष्टी केली",
    orderCancelledSuccessfully: "ऑर्डर यशस्वीरित्या रद्द केली",
    confirmOrder: "ऑर्डरची पुष्टी करा",
    cancelOrder: "ऑर्डर रद्द करा",
    allOrders: "सर्व आदेश",
    totalOrders: "एकूण ऑर्डर",
    doYouWantToAcceptOrder: "तुम्हाला ही ऑर्डर स्वीकारायची आहे का?",
    doYouWantToCancelOrder: "तुम्हाला ही ऑर्डर रद्द करायची आहे का?",
    orderDetails: "ऑर्डर तपशील",
    orderCreatedAt: "येथे ऑर्डर तयार केली",
    supplier: "पुरवठादार",
    productName: "उत्पादनाचे नांव",
    quantity: "प्रमाण",
    unitPrice: "युनिट किंमत",
    receivedBy: "द्वारे प्राप्त",
    reportFrom: "कडून अहवाल",
    totalSupplies: "एकूण पुरवठा",
    allRightsReserved: "सर्व हक्क राखीव",
    toDeleteThisTransaction: "हा व्यवहार हटवण्यासाठी",
    transactionDeletedSuccessfully:
      "व्यवहार यशस्वीरित्या हटविला गेला आहे. स्टॉक इन्व्हेंटरीमध्ये परत केला गेला आहे.",
    deleteTransaction: "व्यवहार हटवा",
    transactionDetails: "व्यवहाराचा तपशील",
    printReceipt: "प्रिंट पावती",
    channel: "चॅनल",
    discount: "सवलत",
    profit: "नफा",
    discountedSales: "सवलतीची विक्री",
    errorFetchingRecord: "रेकॉर्ड आणण्यात त्रुटी",
    exportTransactions: "निर्यात व्यवहार",
    errorFetchingSalesRecord: "निर्यातीसाठी विक्री रेकॉर्ड आणण्यात त्रुटी.",
    totalSellingPrice: "एकूण विक्री किंमत",
    totalCostPrice: "एकूण खर्च किंमत",
    appliedDiscount: "लागू सवलत",
    noOfItems: "वस्तूंची संख्या",
    sales: "विक्री",
    export: "निर्यात करा",
    totalProfit: "एकूण नफा",
    totalBalanceInPeriod: "कालावधीत एकूण शिल्लक",
    allTimeSales: "सर्व वेळ विक्री",
    records: "नोंदी",
    todaysSales: "आजची विक्री",
    transaction: "व्यवहार",
    youHaveExceededTotalNumberOfProducts:
      "तुम्ही तुमच्या योजनेवर अनुमत उत्पादनांची एकूण संख्या ओलांडली आहे. उच्च मर्यादेचा आनंद घेण्यासाठी आपली योजना श्रेणीसुधारित करा.",
    youNeedToHaveLoyaltyProgram:
      "हे वैशिष्ट्य वापरण्यासाठी तुमच्याकडे लॉयल्टी प्रोग्राम असणे आवश्यक आहे!",
    price: "किंमत",
    category: "श्रेणी",
    stockTracking: "स्टॉक ट्रॅकिंग",
    stockCount: "स्टॉक गणना",
    taxed: "कर लावला",
    originalPrice: "मूळ किंमत",
    costPrice: "किंमत किंमत",
    unit: "युनिट",
    productImage: "उत्पादन प्रतिमा",
    taxRate: "कर दर",
    taxType: "कर प्रकार",
    trackInventory: "ट्रॅक इन्व्हेंटरी",
    variants: "रूपे",
    hasVariants: "रूपे आहेत",
    importProduct: "आयात उत्पादन",
    exportProducts: "उत्पादने निर्यात करा",
    addNewProduct: "नवीन उत्पादन जोडा",
    viewCategory: "श्रेणी पहा",
    viewSuppliers: "पुरवठादार पहा",
    receiveInventory: "यादी प्राप्त करा",
    printAllProductsTag: "सर्व उत्पादने टॅग मुद्रित करा",
    deleteAll: "सर्व हटवा",
    totalProducts: "एकूण उत्पादने",
    youveSuccessfullyAddedANewCategory:
      "आपण यशस्वीरित्या एक नवीन श्रेणी जोडली आहे",
    addNewCategory: "नवीन श्रेणी जोडा",
    addCategory: "श्रेणी जोडा",
    categoryName: "श्रेणीचे नाव",
    pleaseEnterCategoryName: "कृपया श्रेणीचे नाव प्रविष्ट करा",
    stampsTarget: "शिक्के लक्ष्य",
    sendInventory: "यादी पाठवा",
    productDetails: "उत्पादन तपशील",
    youveSuccessfullyEditedThisCategory:
      "आपण ही श्रेणी यशस्वीरित्या संपादित केली आहे",
    update: "अपडेट करा",
    categoryList: "श्रेणी यादी",
    categories: "श्रेणी",
    enterQuantityToUpdate: "अपडेट करण्यासाठी प्रमाण एंटर करा",
    inventorySentSuccessfully: "यादी यशस्वीरित्या पाठविली गेली!",
    updateInventory: "यादी अद्यतनित करा",
    currentQuantity: "वर्तमान प्रमाण",
    pleaseEnterQuantityToAdd:
      "कृपया आपण जोडू इच्छित असलेले प्रमाण प्रविष्ट करा",
    pleaseSelectABranch: "कृपया एक शाखा निवडा",
    searchForBranch: "शाखा शोधा",
    youCantSendMoreThanStock:
      "तुमच्याकडे असलेल्या स्टॉकपेक्षा जास्त तुम्ही पाठवू शकत नाही",
    send: "पाठवा",
    pleaseEnterQuantityToSend:
      "कृपया आपण पाठवू इच्छित असलेले प्रमाण प्रविष्ट करा",
    productNameIsRequiredOnRow: "पंक्तीवर उत्पादनाचे नाव आवश्यक आहे",
    productCategoryIsRequiredOnRow: "पंक्तीवर उत्पादन श्रेणी आवश्यक आहे",
    productPriceIsRequiredOnRow: "पंक्तीवर उत्पादनाची किंमत आवश्यक आहे",
    productUnitIsRequiredOnRow: "पंक्तीवर उत्पादन युनिट आवश्यक आहे",
    productQuantityIsRequiredOnRow: "पंक्तीवर उत्पादनाचे प्रमाण आवश्यक आहे",
    productVariantsRequireTracking: "उत्पादन प्रकारांसाठी ट्रॅकिंग आवश्यक आहे!",
    pleaseAddVariantClickButton: "कृपया जोडा बटणावर क्लिक करून एक प्रकार जोडा!",
    totalVariantsMoreThanSelectedQuantity:
      "एकूण रूपे निवडलेल्या उत्पादनाच्या प्रमाणापेक्षा जास्त आहेत, Pls वेरिएंटचे प्रमाण कमी करतात",
    productEditedSuccessfully: "उत्पादन यशस्वीरित्या संपादित केले गेले आहे!",
    fileTooLargeLessThan4Mb:
      "फाइल आकार खूप मोठा आहे! फाइलचा आकार 4MB पेक्षा कमी असावा.",
    suchVariantAlreadyExist: "असे प्रकार आधीच अस्तित्वात आहेत",
    addVariants: "रूपे जोडा",
    editProduct: "उत्पादन संपादित करा",
    pleaseEnterProductName: "कृपया उत्पादनाचे नाव प्रविष्ट करा",
    pleaseEnterProductPrice: "कृपया उत्पादनाची किंमत प्रविष्ट करा",
    pleaseEnterProductOriginalPrice: "कृपया उत्पादनाची मूळ किंमत एंटर करा",
    productDescription: "उत्पादन वर्णन",
    selectProductCategory: "उत्पादन श्रेणी निवडा",
    pleaseSelectProductCategory: "कृपया उत्पादन श्रेणी निवडा",
    productCostPrice: "उत्पादन खर्च किंमत",
    productSellingPrice: "उत्पादन विक्री किंमत",
    productOriginalPrice: "उत्पादनाची मूळ किंमत",
    maxFileSizeAllowedIs4Mb: "कमाल. फाइल आकार 4 एमबी आहे",
    productsWithPicturesArePublished:
      "ऑर्डर प्राप्त करण्यासाठी चित्रे असलेली उत्पादने डिस्कवर प्रकाशित केली जातात",
    shouldThisProductBeTracked: "या उत्पादनाचा मागोवा घ्यावा का?",
    pleaseSelectStockUnit: "कृपया स्टॉक युनिट निवडा",
    stockUnit: "स्टॉक युनिट",
    bag: "बॅग",
    bottle: "बाटली",
    bunch: "गुच्छ",
    can: "कॅन",
    carton: "कार्टन",
    crate: "क्रेट",
    cup: "कप",
    dozen: "डोझन",
    gigabytes: "गीगाबाइट्स",
    gram: "ग्राम",
    kilogram: "किलोग्राम",
    litre: "लिटर",
    pack: "पॅक",
    pair: "जोडी",
    pieces: "PIECES",
    plate: "प्लेट",
    tonne: "टन (MT)",
    uNIT: "युनिट",
    yard: "अंगण",
    pleaseEnterProductQuantity: "कृपया उत्पादन प्रमाण प्रविष्ट करा",
    productQuantity: "उत्पादन प्रमाण",
    isThisProductTaxed: "या उत्पादनावर कर आहे का?",
    selectTaxType: "कर प्रकार निवडा",
    pleaseSelectTaxType: "कृपया कर प्रकार निवडा",
    progressive: "पुरोगामी",
    proportional: "आनुपातिक",
    pleaseEnterProductTaxRate: "कृपया उत्पादन कर दर प्रविष्ट करा",
    doesProductHaveVariants: "या उत्पादनाची रूपे आहेत का?",
    type: "प्रकार",
    value: "मूल्य",
    pleaseEnterVariantType: "कृपया प्रकाराचा प्रकार प्रविष्ट करा",
    pleaseEnterVariantValue: "कृपया व्हेरिएंटचे मूल्य एंटर करा",
    pleaseEnterVariantPrice: "कृपया व्हेरिएंटची किंमत एंटर करा",
    pleaseEnterVariantQuantity: "कृपया व्हेरिएंटचे प्रमाण एंटर करा",
    productDeletedSuccessfully: "उत्पादन यशस्वीरित्या हटवले गेले आहे!",
    categoryDeletedSuccessfully: "श्रेणी यशस्वीरित्या हटवली गेली आहे!",
    toDeleteThisProduct: "हे उत्पादन हटवण्यासाठी",
    invalidProductQuantity: "अवैध उत्पादन प्रमाण",
    quantityAddedIsOverStock:
      "तुम्ही जोडत असलेले प्रमाण तुमच्या स्टॉकमध्ये आहे त्यापेक्षा जास्त आहे.",
    itemInventoryNotTracked: "आयटम इन्व्हेंटरीचा मागोवा घेतला जात नाही",
    addBulkQuantity: "मोठ्या प्रमाणात जोडा",
    enterBulkQuantity: "मोठ्या प्रमाणात प्रविष्ट करा",
    pleaseEnterBulkQuantity: "कृपया मोठ्या प्रमाणात प्रविष्ट करा",
    youveSuccessfullyAddedANewProduct:
      "तुम्ही यशस्वीरित्या नवीन उत्पादन जोडले आहे!",
    pleaseEnterProductSellingPrice: "कृपया उत्पादन विक्री किंमत प्रविष्ट करा",
    doYouWantToTrackProductStock: "आपण उत्पादन स्टॉक ट्रॅक करू इच्छिता?",
    sellingPrice: "विक्री किंमत",
    setProductExpiryReminder: "उत्पादन समाप्ती स्मरणपत्र सेट करा",
    productExpiryDate: "उत्पादन कालबाह्यता तारीख",
    startRemindingFrom: "पासून स्मरण प्रारंभ करा",
    productSuppliesAddedSuccessfully:
      "आपण उत्पादन पुरवठा यशस्वीरित्या जोडला आहे.",
    addProductSupplies: "उत्पादन पुरवठा जोडा",
    pleaseSelectSupplier: "कृपया पुरवठादार निवडा",
    nameOfProduct: "उत्पादनाचे नाव",
    pleaseSelectProduct: "कृपया उत्पादन निवडा",
    productVariant: "उत्पादन प्रकार",
    pleaseSelectAVariant: "कृपया एक प्रकार निवडा",
    pleaseEnterUnitPrice: "कृपया युनिट किंमत एंटर करा",
    businessBranch: "व्यवसाय शाखा",
    pleaseSelectBranch: "कृपया शाखा निवडा",
    youveSuccessfullyAddedANewSupplier:
      "तुम्ही यशस्वीरित्या नवीन पुरवठादार जोडला आहे",
    addSupplier: "पुरवठादार जोडा",
    pleaseEnterSupplierEmail: "कृपया पुरवठादाराचा ईमेल प्रविष्ट करा",
    pleaseAddADescription: "कृपया वर्णन जोडा",
    anErrorOccuredProductsDeleted:
      "ऑपरेशन करताना त्रुटी आली. कृपया लक्षात घ्या, प्रक्रियेत काही उत्पादने हटवली गेली असतील",
    bulkDelete: "बल्क डिलीट",
    youAreAboutToDelete: "तुम्ही हटवणार आहात",
    product: "उत्पादन",
    isDueToRedeem: "रिडीम करणे आहे",
    aReward: "एक बक्षीस",
    pleaseSelectCustomerToReeemReward:
      "कृपया रिवॉर्ड रिडीम करण्यासाठी ग्राहक निवडा.",
    youHaveNoLoyaltyProgramRunning:
      "आपल्याकडे कोणताही सक्रिय निष्ठा कार्यक्रम चालू नाही",
    customerHhasNoPointsInLoyaltyProgram:
      "या लॉयल्टी प्रोग्राममध्ये ग्राहकाला कोणतेही गुण नाहीत",
    proceedWithPayment: "पेमेंटसह पुढे जा?",
    youAreAboutToPayForTransactionUsingPoints:
      "तुम्ही पॉइंट्स वापरून व्यवहारासाठी पैसे देणार आहात.",
    customerHas: "ग्राहक आहे",
    worth: "किमतीची",
    andIsNotSufficientToPayForTransaction:
      "आणि या व्यवहारासाठी पैसे देण्यासाठी पुरेसे नाही. त्यांना दुसरी पेमेंट पद्धत वापरून शिल्लक जोडायचे आहे का?",
    addCustomerLoyalty: "ग्राहक निष्ठा जोडा",
    pleaseAddCustomerFirst: "कृपया आधी ग्राहक जोडा किंवा निवडा.",
    pleaseWaitWhileWeGetReady: "कृपया तयार होईपर्यंत प्रतीक्षा करा",
    lowStock: "कमी साठा",
    pleaseEnterAmountTendered: "कृपया निविदा केलेली रक्कम प्रविष्ट करा",
    areYouSureToBookSaleOffline:
      "तुम्हाला खात्री आहे की तुम्ही ही विक्री ऑफलाइन बुक करू इच्छिता?",
    saleWouldBeBookedAutomatically:
      "जेव्हा तुम्ही तुमचे इंटरनेट चालू करता तेव्हा विक्री आपोआप बुक केली जाईल",
    offlineSalesBookingCancelled: "ऑफलाइन विक्री बुकिंग रद्द",
    covid19Message:
      "कोविड १:: प्रसार थांबवण्यासाठी आपले हात साबणाने धुवा किंवा सॅनिटाईझ करा. नेहमी सुरक्षित राहा",
    saleSuccessfullyRecorded: "विक्री यशस्वीरीत्या रेकॉर्ड झाली!",
    sendReceiptToEmail: "ईमेलवर पावती पाठवा",
    sendThankYouSms: "धन्यवाद एसएमएस पाठवा",
    sendShippingDetails: "शिपिंग तपशील पाठवा",
    addLoyalty: "निष्ठा जोडा",
    searchCustomerNameOrNumber: "ग्राहकांचे नाव किंवा नंबर शोधा",
    clickTheSearchCustomerBox: "सर्च कस्टमर बॉक्स आणि स्कॅन कार्डवर क्लिक करा",
    enterProductPrice: "उत्पादनाची किंमत प्रविष्ट करा",
    enterPriceFor: "साठी किंमत प्रविष्ट करा",
    searchForProduct: "उत्पादन शोधा",
    all: "सर्व",
    backToDashboard: "डॅशबोर्डवर परत",
    viewDraftSales: "मसुदा विक्री पहा",
    variantSelected: "पर्याय निवडला",
    variant: "प्रकार",
    thePreviousVariantSelectionNotAvailable:
      "किंमतीच्या आधारावर निवडलेल्या नवीन प्रकारासाठी मागील प्रकार निवड उपलब्ध नाही, कृपया आपली निवड बदला.",
    pleaseSupplyPositiveQuantityNumber: "कृपया पोस्टिव्ह संख्या क्रमांक द्या",
    lowStockFor: "साठी कमी साठा",
    clearVariants: "रूपे साफ करा",
    pleaseEnterQuantity: "कृपया प्रमाण प्रविष्ट करा",
    picture: "चित्र",
    redemptionToken: "विमोचन टोकन",
    token: "टोकन",
    totalSpent: "एकूण खर्च",
    confirmPayment: "पेमेंटची पुष्टी करा",
    hasPaymentBeenMade: "पेमेंटची यशस्वीपणे प्रक्रिया झाली आहे का?",
    enterTransactionReference: "तुम्ही पैसे भरलेले व्यवहार संदर्भ एंटर करा",
    pleaseSelectACustomer: "कृपया ग्राहक निवडा!",
    areYouSureToApplyDiscount:
      "तुम्हाला खात्री आहे की तुम्ही सवलत लागू करू इच्छिता?",
    addYourBankAccountToEnableUssd:
      "UPay द्वारे त्वरित USSD हस्तांतरण सक्षम करण्यासाठी आपले बँक खाते जोडा",
    totalAmountToPay: "एकूण देय रक्कम",
    doYouWantToCancelTransaction: "तुम्हाला हा व्यवहार रद्द करायचा आहे का?",
    savePrintBill: "बिल जतन करा/प्रिंट करा",
    enterAmountCollectedForCustomer:
      "ग्राहकांसाठी जमा केलेली रक्कम प्रविष्ट करा",
    recordSale: "विक्रमी विक्री",
    checkOutWith: "सह तपासा",
    instantTransfer: "झटपट हस्तांतरण",
    dialTheUSSDCode: "यूएसएसडी कोड डायल करा",
    pleaseSelectABank: "कृपया एक बँक निवडा",
    payWithUSSD: "USSD सह पेमेंट करा",
    sendBillTo: " - यांना बिल पाठवा",
    waitingForUSSDTransfer: "यूएसएसडी हस्तांतरणाची वाट पाहत आहे",
    percent: "टक्के",
    applyDiscount: "सवलत लागू करा",
    thisBillHasBeenSaved: "हे विधेयक जतन केले गेले आहे",
    saveDraft: "ड्राफ्ट सेव्ह करा",
    pleaseTypeANameToIdentifyCustomer: "कृपया ग्राहक ओळखण्यासाठी नाव टाईप करा",
    paymentDetails: "पेमेंट तपशील",
    basePrice: "मूळ किंमत",
    staff: "कर्मचारी",
    subTotal: "सब टोटल",
    durationMonths: "कालावधी (महिने)",
    selectADuration: "कालावधी निवडा",
    oneMonth: "1 महिना",
    twoMonths: "2 महिने",
    threeMonths: "3 महिने",
    sixMonths: "6 महिने",
    twelveMonths: "12 महिने",
    eighteenMonths: "18 महिने",
    twentyFourMonths: "24 महिने",
    twoMonthsFree: "(2 महिने मोफत)",
    threeMonthsFree: "(3 महिने मोफत)",
    fiveMonthsFree: "(5 महिने मोफत)",
    yourAccountHasBeen: "तुमचे खाते झाले आहे",
    renewed: "नूतनीकरण",
    upgraded: "सुधारीत",
    successfully: "यशस्वीरित्या",
    yourSubscription: "तुमचे वर्गणी",
    youAreCurrentlyEnrolledOnA: "आपण सध्या a वर नोंदणी केली आहे",
    pleaseChooseAPaymentOption: "कृपया पेमेंट पर्याय निवडा",
    planRenewal: "नूतनीकरणाची योजना करा",
    planUpgrade: "योजना अपग्रेड करा",
    pleaseSelectDurationToPayFor:
      "कृपया तुम्ही ज्या कालावधीसाठी पैसे देऊ इच्छित आहात ते निवडा",
    staffAccounts: "कर्मचारी खाती",
    ecommerce: "ईकॉमर्स",
    pleaseSelectAPlan: "कृपया एक योजना निवडा",
    includeAddons: "अॅड-ऑन समाविष्ट करा",
    viewTransactions: "व्यवहार पहा",
    customerHasNoCompletedTansactions:
      "ग्राहकाकडे अद्याप पूर्ण झालेले व्यवहार नाहीत",
    branch: "शाखा",
    enterNumberOfEcommerceBranches: "ईकॉमर्स शाखांची संख्या प्रविष्ट करा",
    enterNumberOfEcommerceBranchesToPay:
      "ज्या ईकॉमर्स शाखांसाठी तुम्हाला पैसे द्यायचे आहेत त्यांची संख्या प्रविष्ट करा",
    ecommerceIntegration: "ईकॉमर्स एकत्रीकरण",
    enterNumberOfBranches: "शाखांची संख्या प्रविष्ट करा",
    enterNumberOfAdditionalBranchesToPay:
      "आपण ज्या अतिरिक्त शाखांची भरपाई करू इच्छित आहात त्यांची संख्या प्रविष्ट करा",
    enterNumberOfStaffs: "कर्मचारी संख्या प्रविष्ट करा",
    enterNumberOfStaffsToPayFor:
      "आपण ज्या कर्मचाऱ्यांना पैसे द्यायचे आहेत त्यांची संख्या प्रविष्ट करा",
    discountApplies: "सवलत लागू",
    starsOnThe: "वर तारे",
    offer: "ऑफर",
    get: "मिळवा",
    moreStarsToRedeem: "रिडीम करण्यासाठी अधिक तारे",
    taxVat: "कर (व्हॅट)",
    callCashierToCompletePayment: "पेमेंट पूर्ण करण्यासाठी कॅशियरला कॉल करा",
    receipt: "पावती",
    dear: "प्रिय",
    thankYouForYourOrderFindGoods:
      "तुमच्या ऑर्डरबद्दल धन्यवाद. कृपया खालील वस्तू पुरवल्याप्रमाणे, सहमतीनुसार शोधा.",
    shippingNote: "शिपिंग टीप",
    enterShippingNote: "शिपिंग नोट प्रविष्ट करा",
    shippingAddress: "पाठवण्याचा पत्ता",
    enterShippingAddress: "शिपिंग पत्ता प्रविष्ट करा",
    wellDoneYouAreDueToRedeem: "बरं झालं! तुम्ही रिडीम करणार आहात",
    toGetYourRewardNextVisit:
      "तुमच्या पुढील भेटीत तुमचे बक्षीस मिळवण्यासाठी. धन्यवाद",
    pointsOnThe: "वर गुण",
    morePointsToRedeem: "रिडीम करण्यासाठी अधिक गुण",
    showCode: "कोड दाखवा",
    toGetYourRewardOnNextVisit:
      "तुमच्या पुढील भेटीत तुमचे बक्षीस मिळवण्यासाठी. धन्यवाद",
    earn: "कमवा",
    delivaryNote: "वितरण नोट",
    draftSales: "मसुदा विक्री",
    startDate: "प्रारंभ तारीख",
    endDate: "शेवटची तारीख",
    orders: "आदेश",
    checkout: "तपासा",
    noProductItem: "उत्पादन आयटम नाही",
    printProductTag: "उत्पादन टॅग मुद्रित करा",
    transactionReference: "व्यवहार संदर्भ",
    Cashier: "रोखपाल",
    Manager: "व्यवस्थापक",
    Owner: "मालक",
    Admin: "प्रशासक",
    addPartners: "भागीदार जोडा",
    addNewLoyaltyPartner: "नवीन निष्ठा भागीदार जोडा",
    pleaseEnterCompanyName: "कृपया कंपनीचे नाव प्रविष्ट करा",
    companyName: "कंपनीचे नाव",
    pleaseEnterCompanyRepName: "कृपया कंपनी प्रतिनिधीचे नाव प्रविष्ट करा",
    companyRepName: "कंपनी प्रतिनिधीचे नाव",
    pleaseEnterCompanyRepEmail: "कृपया कंपनी प्रतिनिधीचा ईमेल प्रविष्ट करा",
    companyRepEmail: "कंपनी प्रतिनिधीचा ईमेल",
    pleaseEnterCompanyRepPhone: "कृपया कंपनी प्रतिनिधीचा फोन नंबर प्रविष्ट करा",
    companyRepPhone: "कंपनी प्रतिनिधीचा फोन नंबर",
    addReward: "बक्षीस जोडा",
    pleaseEnterRewardName: "कृपया पुरस्काराचे नाव प्रविष्ट करा",
    rewardName: "पुरस्काराचे नाव",
    rewardQuantity: "बक्षीस प्रमाण",
    rewardDescription: "बक्षीस वर्णन",
    rewardType: "पुरस्कार प्रकार",
    pleaseEnterRewardType: "कृपया पुरस्कार प्रकार प्रविष्ट करा",
    pleaseEnterRewardQuantity: "कृपया बक्षीस प्रमाण प्रविष्ट करा",
    pleaseEnterRewardDescription: "कृपया बक्षीस वर्णन प्रविष्ट करा",
    fineDining: "छान जेवण",
    luxuryFashion: "लक्झरी फॅशन",
    hotels: "हॉटेल्स",
    travel: "प्रवास",
    foodAndBeverage: "अन्न व पेय",
    fashion: "फॅशन",
    health: "आरोग्य",
    furniture: "फर्निचर",
    entertainment: "मनोरंजन",
    automobile: "ऑटोमोबाईल",
    education: "शिक्षण",
    beautyAndSpa: "सौंदर्य आणि स्पा",
    staycation: "मुक्काम",
    events: "कार्यक्रम",
    trips: "सहली",
    oilAndGas: "तेल आणि वायू",
    laundry: "कपडे धुऊन मिळण्याचे ठिकाण",
    partnerCategory: "भागीदार श्रेणी",
    freeItem: "मोफत आयटम",
  },
  Pashto: {
    cashier: "کیشیر",
    manager: "مدیر",
    owner: "مالک",
    online: "آنلاین",
    offline: "آفلاین",
    changePassword: "پټ نوم بدل کړی",
    currentPasswordMessage: "مهرباني وکړئ خپل اوسنی رمز دننه کړئ",
    passwordMessage: "مهرباني وکړئ خپل رمز دننه کړئ",
    currentPassword: "اوسنی رمز",
    password: "رمز",
    confirmPasswordMessage: "مهرباني وکړئ خپل رمز تایید کړئ!",
    confirmPassword: "پاسورډ تایید کړه",
    sendViaEmail: "د بریښنالیک له لارې لیږل",
    sendViaWhatsapp: "د WhatsApp له لارې لیږل",
    downloadPdf: "PDF ډاونلوډ کړئ",
    paid: "ورکړل شوې",
    unpaid: "بې تنخواه",
    partial: "جزوي",
    closeInvoice: "ایا تاسو غواړئ انوائس بند کړئ؟",
    closeInvoiceConfirmation: "رسید ممکن خوندي نشي. ایا تاسو تړل غواړئ؟",
    yes: "هو",
    no: "نه",
    invoice: "انوائس",
    wasDeducted: "تخفیف شوی",
    for: "لپاره",
    item: "توکي",
    addProduct: "محصول اضافه کړئ",
    paymentReference: "د تادیې حواله",
    amountPaid: "پيسې ورکړل شوې",
    discountAmount: "د تخفیف اندازه",
    amountDue: "د پیسو مقدار",
    amount: "مقدار",
    dueDate: "د تاریخ په مطابق",
    paymentType: "د تادیې ډول",
    card: "کارت",
    cash: "نغدي",
    bankTransfer: "بانکي لېږد",
    paymentMessage: "د تادیې پیغام",
    description: "تفصیل",
    sendReceipt: "رسید لیږل",
    delete: "ړنګول",
    save: "خوندي کول",
    resend: "بیا لیږل",
    saveAndSend: "خوندي کول",
    invoiceSaved: "رسید خوندي شو!",
    selectPaymentMethod: "مهرباني وکړئ د تادیې میتود غوره کړئ!",
    selectCustomer: "مهرباني وکړئ یو پیرودونکی غوره کړئ!",
    cartEmptyError:
      "د کارټ لیست نشي خالي کیدی ، انوائس بند کړئ او کارټ کې توکي اضافه کړئ!",
    subscriptionExpired:
      "ستاسو ګډون پای ته رسیدلی او ستاسو حساب اوس محدود دی. د خپل حساب نوي کولو لپاره لاندې ت button ۍ باندې کلیک وکړئ",
    renew: "نوي کول",
    holdOn: "نیول",
    customerBank: "د پیرودونکي بانک",
    cancel: "لغوه کول",
    selectACustomer: "پیرودونکی غوره کړئ",
    invoiceSuccessfulPdfError:
      "رسید په بریالیتوب سره رامینځته شوی مګر د PDF تولید د معمول څخه ډیر وخت نیسي. مهرباني وکړئ لږ وروسته بیرته وګورئ.",
    downloadingInvoice: "د انوائس ډاونلوډ کول",
    downloadingReceipt: "د رسیدلو ډاونلوډ کول",
    whatsappReceiptError:
      "د WhatsApp له لارې د رسید لیږلو کې یوه تېروتنه وشوه ، مهرباني وکړئ بیا هڅه وکړئ.",
    receiptToWhatsapp: "رسید WhatsApp ته لیږل شوی",
    thankYouForPatronage: "ستاسو د ملاتړ څخه مننه",
    hereIsYourReceipt: "دلته ستاسو د تادیې رسید دی",
    errorSendingEmailReceipt:
      "د بریښنالیک له لارې د رسید لیږلو کې یوه ستونزه رامینځته شوه ، مهرباني وکړئ بیا هڅه وکړئ یا ملاتړ سره اړیکه ونیسئ",
    receiptSentToEmail: "رسید د پیرودونکي بریښنالیک ته لیږل شوی",
    invoiceSentToEmail: "رسید د پیرودونکي بریښنالیک ته لیږل شوی",
    invoiceSuccessWhatsappError:
      "انوائس په بریالیتوب سره رامینځته شو مګر WhatsApp ته د لیږلو پرمهال یوه تېروتنه وشوه. د رسید لیست کې بیا هڅه وکړئ",
    invoiceSuccessfulEmailError:
      "رسید په بریالیتوب سره رامینځته شو مګر د بریښنالیک په توګه لیږلو پرمهال یوه ستونزه رامینځته شوه. مهرباني وکړئ د رسید لیست څخه بیا هڅه وکړئ",
    invoiceSentToWhatsapp: "رسید WhatsApp ته لیږل شوی",
    hello: "سلام",
    pleaseDownloadInvoice: "مهرباني وکړئ انوائس ډاونلوډ کړئ",
    pleaseDownloadReceipt: "مهرباني وکړئ رسید ډاونلوډ کړئ",
    from: "څخه",
    email: "بریښنالیک",
    upgrade: "لوړول",
    youAreOnFreePlan: "تاسو په وړیا پلان کې یاست.",
    clickOn: "کلیک وکړه",
    yourPlanInFewSteps: " ستاسو ګډون په څو ګړندي مرحلو کې.",
    to: "ته",
    yourSubscriptionHasExpired:
      "ستاسو ګډون پای ته رسیدلی او ستاسو حساب اوس محدود دی.",
    days: "ورځې",
    yourSubscriptionExpiresIn: "ستاسو د Loystar ګډون پای ته رسیږي",
    createAcount: "ګڼون پرانیستل",
    signIn: "ننوزئ",
    continue: "ادامه ورکړه",
    enterOtp: "د OTP PIN دننه کړئ",
    enterValidOtp: "مهرباني وکړئ یو معتبر PIN دننه کړئ",
    pin: "پن",
    tokenSentToMail: "ستاسو بریښنالیک ته یو نښه لیږل شوې",
    passwordResetSuccessful: "د رمز بیا تنظیم بریالی و",
    somethingWentWrong: "کومه تیروتنه وشوه!",
    resetPassword: "پاسورډ بیا تنظیم کړئ",
    iHaveResetCode: "زه د پاسورډ ری سیٹ کوډ لرم",
    pleaseEnterEmail: "مهرباني وکړئ خپل بریښنالیک دننه کړئ",
    aTokenWillBeSentToEmail: "یو نښه به ستاسو بریښنالیک ته واستول شي",
    enterEmail: "خپل بریښنالیک دننه کړئ",
    sendinYourToken: "ستاسو نښه لیږل ...",
    makeSureItMatchesPassword:
      "ډاډ ترلاسه کړئ چې دا ستاسو نوی رمز سره سمون خوري",
    pleaseChooseNewPassword: "مهرباني وکړئ نوی رمز غوره کړئ",
    chooseNewPassword: "نوی رمز غوره کړئ",
    enterNewPassword: "د تایید لپاره خپل نوی رمز دننه کړئ",
    enterTokenSent: "هغه نښه دننه کړئ چې ستاسو بریښنالیک لیږل شوی و",
    resetToken: "ټوکن بیا تنظیم کړئ",
    resettingPassword: "خپل رمز بیا تنظیم کول ...",
    signUp: "ګډون کول",
    adminSignInWithEmail:
      " اداره د بریښنالیک سره لاسلیک کوي پداسې حال کې چې کارمندان د کارونکي نوم سره لاسلیک کوي.",
    pleaseEnterEmailOrUsername:
      "مهرباني وکړئ خپل بریښنالیک یا کارونکي نوم دننه کړئ",
    emailOrUsername: "بریښنالیک یا کارونکي نوم",
    pleaseEnterPassword: "مهرباني وکړئ پټنوم دننه کړئ",
    createAnAccount: "ګڼون پرانیستل",
    forgotPassword: "خپل پټ نوم درڅخه هیر دی؟",
    enterPhoneNumber: "د تلیفون شمیره دننه کړئ",
    personalData: "شخصي معلومات",
    validateConfirmationCOde: "د تایید کوډ تایید کړئ",
    pleaseEnterFirstName: "مهرباني وکړئ خپل لومړی نوم دننه کړئ",
    pleaseEnterPhoneNumber: "مهرباني وکړئ خپل د تلیفون شمیره دننه کړئ",
    pleaseEnterLastName: "مهرباني وکړئ خپل وروستی نوم دننه کړئ",
    pleaseEnterBusinessName: "مهرباني وکړئ د خپل سوداګرۍ نوم دننه کړئ",
    firstName: "اول نوم",
    lastName: "تخلص",
    businessName: "تجارتي نوم",
    previous: "مخکینی",
    next: "بل",
    pleaseSelectBusinessCategory: "مهرباني وکړئ د خپلې سوداګرۍ کټګوري غوره کړئ",
    pleaseEnterValidEmail: "مهرباني وکړئ یو باوري بریښنالیک دننه کړئ",
    pleaseEnterPostCode: "مهرباني وکړئ د پوسټ کوډ دننه کړئ",
    postCode: "پوسټ کوډ",
    phoneNumberInUse: "دا د تلیفون شمیره دمخه کارول شوې ده!",
    emailInUse: "دا بریښنالیک دمخه کارول شوی!",
    foodAndDrinks: "خواړه او څښاک",
    salonAndBeauty: "سیلون او ښکلا",
    fashionAndAccessories: "فیشن او لوازمات",
    gymAndFitness: "جم او فټنس",
    travelAndHotel: "سفر او هوټل",
    homeAndGifts: "کور او ډالۍ",
    washingAndCleaning: "مینځل او پاکول",
    gadgetsAndElectronics: "وسایل او برقیات",
    groceries: "ګروسري",
    others: "نور",
    submit: "سپارل",
    accountCreatedSuccessful: "ستاسو حساب په بریالیتوب سره جوړ شو.",
    pleaseEnterAddress: "مهرباني وکړئ خپل ادرس دننه کړئ",
    addressLine1: "د ادرس کرښه",
    addressLine2: "د ادرس کرښه 2",
    choosePassword: "پټنوم غوره کړئ",
    passwordMustBe6Characters: "پاسورډ باید لږترلږه 6 توري ولري.",
    howDidYouHearLoystar: "تاسو د لویسټر په اړه څنګه اوریدلي؟",
    referralCode: "د راجع کولو کوډ",
    byClickingTheButton:
      " د لاندې ت buttonۍ په کلیک کولو سره ، تاسو د دې سره موافق یاست",
    termsAndSevice: "شرایط",
    wereCreatingAccount: "موږ ستاسو حساب جوړوو",
    proceed: "پرمخ ځي",
    verificationCodeMustBe6: "د تایید کوډ باید 6 عددي وي",
    pleaseEnter6DigitCode: "مهرباني وکړئ د 6 عددي کوډ دننه کړئ",
    enterVerificationCode: "د تایید کوډ دننه کړئ",
    resendToken: "ټوکن بیرته واستوئ",
    verify: "تایید کړئ",
    transactions: "راکړه ورکړه",
    todaySales: "د نن ورځې پلور",
    salesHistory: "د پلور تاریخ",
    supplyHistory: "د عرضه کولو تاریخ",
    new: "نوی",
    invoices: "رسیدونه",
    disbursements: "لګول",
    offlineSales: "آفلاین پلور",
    products: "محصولات",
    customers: "پیرودونکي",
    multiLevelLoyalty: "څو اړخیزه وفاداري",
    loyaltyPrograms: "د وفادارۍ برنامې",
    members: "غړي",
    appStore: "د اپل سټور",
    orderMenu: "امر مینو",
    settings: "ترتیبات",
    staffAndBranches: "کارکوونکي او څانګې",
    myAccount: "زما حساب",
    switchToSellMode: "د پلور حالت ته لاړشئ",
    signOut: "وتون",
    getFreeSubscription: "وړیا ګډون ترلاسه کړئ",
    onlyNumbersAllowed: "یوازې شمیرې اجازه لري",
    yourAccountMustBe10Digits: "ستاسو د حساب شمیره باید د 10 عددي شمیره وي",
    yourBvnMustBe11: "ستاسو BVN باید د 11 عددي شمیره وي",
    pleaseSelectBank: "مهرباني وکړئ خپل بانک وټاکئ",
    selectYourBank: "خپل بانک غوره کړئ",
    enterBankAccountNumber: "د بانکي حساب شمیره دننه کړئ",
    enterBvnNumber: "خپل BVN دننه کړئ",
    connectBankAccount: "د بانکي حساب سره وصل شئ",
    passwordResetSuccessfulAndSignOut:
      "ستاسو رمز په بریالیتوب سره تنظیم شوی. د بیا لاسلیک کولو لپاره لاندې د وتلو ت buttonۍ باندې کلیک وکړئ",
    enterCurrentPassword: "اوسنی رمز دننه کړئ",
    pleaseEnterCurrentPassword: "مهرباني وکړئ اوسنی رمز دننه کړئ",
    phoneNumber: "د تلیفون شمیره",
    sex: "جنس",
    dateOfBirth: "د زیږیدو نیټه",
    state: "ایالت",
    country: "هیواد",
    loyaltyId: "د وفادارۍ ID",
    createdAt: "په کې جوړ شوی",
    noOfTransactionsMade: "د ترسره شویو معاملو شمیره",
    yourDownloadWillStart: "ستاسو ډاونلوډ به په یوه شیبه کې پیل شي",
    exportCustomers: "پیرودونکي صادر کړئ",
    youhaveSuccessfullyToppedUp:
      "تاسو په بریالیتوب سره ستاسو د SMS واحدونه سرته رسولي دي.",
    youNowHave: "تاسو اوس لرئ",
    smsUnits: "د SMS واحدونه",
    enterNumberOfSmsUnits:
      "د SMS واحدونو شمیره دننه کړئ چې تاسو یې د پیرودلو اراده لرئ",
    pleaseEnterANumericValue: "مهرباني وکړئ یو عددي ارزښت دننه کړئ",
    pay: "ورکړه",
    accountEditedSuccessfully: "ستاسو حساب په بریالیتوب سره ترمیم شو.",
    youAeCurrentlyOn: "تاسو اوس مهال یاست",
    plan: "پلان",
    userData: "د کارونکي ډاټا",
    businessData: "د سوداګرۍ ډاټا",
    businessCategory: "سوداګریز کټګوري",
    pleaseSelectCurrency: "مهرباني وکړئ خپل اسعار وټاکئ",
    selectYourCurrency: "خپل اسعار غوره کړئ",
    purchaseMoreSmsUnits: "د لاندې فورمې په کارولو سره د SMS ډیر واحدونه وپیرئ",
    youHave: "تاسو لرئ",
    atLeast4SmsUnitsRrequired:
      "د تایید لپاره لږترلږه 4 sms واحدونو ته اړتیا ده ، مهرباني وکړئ پورته یې کړئ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "مهرباني وکړئ د 'خپل پیرودونکي پیژنئ' (KYC) اړتیاو سره مطابقت لپاره خپل بانکي حساب تایید کړئ. دا به تاسو ته اجازه درکړي د USSD یا فوري لیږد له لارې تادیات راټول کړئ ، د پیرودونکو څخه امرونه ترلاسه کړئ او د راکړې ورکړې تصفیه پروسس کړئ. یوازې د نایجیریا سوداګرو لپاره د تطبیق وړ. مهرباني وکړئ د پیل کولو لپاره لاندې ت buttonۍ فشار ورکړئ.",
    reConnectBankAccount: "د بانک حساب بیا وصل کړئ",
    accountName: "د حساب نوم",
    accountNumber: "ګڼون شمېره",
    bankName: "د بانک نوم",
    verified: "تایید شوی",
    accountDetails: "د حساب توضیحات",
    kycBankAccount: "KYC",
    createTier: "ټایر جوړ کړئ",
    fileUploadSuccessful: "فایل په بریالیتوب سره پورته شو",
    fileUploadFailed: "د فایل اپلوډ ناکام شو",
    createLoyaltyProgram: "د وفادارۍ برنامه جوړه کړئ",
    createLoyalty: "وفاداري رامینځته کړئ",
    name: "نوم",
    loyaltyArtwork: "د وفادارۍ هنر",
    clickToUpload: "د پورته کولو لپاره کلیک وکړئ",
    amountToPointsRatio: "د ټکو تناسب مقدار",
    points: "ټکي",
    recommendedAmountToPointRatio:
      "وړاندیز شوی: له 1 څخه تر 1 ټکي پورې. د هر 1 نایرا مصرف لپاره ، ستاسو پیرودونکي 1 ټکی ترلاسه کوي",
    pleaseTypeIn: "مهرباني وکړئ دننه یې کړئ",
    toDeleteLoyalty: "د دې وفادارۍ حذف کول",
    deleteLoyalty: "وفاداري حذف کړئ",
    toConfirm: "تاييدول",
    edit: "سمول",
    pointsAwardedSuccessfully: "ټکي په بریالیتوب سره ورکړل شوي.",
    enterPointValueToAward:
      "د پوائنټ ارزښت دننه کړئ تاسو غواړئ پیرودونکو ته جایزه ورکړئ",
    award: "جایزه",
    awardPointValuesToCustomer: "پیرودونکي ته د جایزې ارزښت ارزښت",
    enrollMembersToLoyalty: "په وفادارۍ کې غړي ثبت کړئ",
    awardPoints: "جایزې ټکي",
    enroll: "نوم لیکنه",
    home: "کور",
    loyalty: "وفاداري",
    enrollCustomers: "پیرودونکي راجستر کړئ",
    selected: "ټاکل شوی",
    customer: "پیرودونکی",
    loyaltyActivationSuccessful: "د وفادارۍ فعالیت بریالی دی.",
    loyaltyDeactivationSuccessful: "د وفادارۍ نشتون بریالی دی.",
    viewTier: "ټایر وګورئ",
    deactivate: "غیر فعال کړئ",
    activate: "فعال کړئ",
    actions: "کړنې",
    nameOfLoyalty: "د وفادارۍ نوم",
    loyaltyStatus: "د وفادارۍ حالت د وفادارۍ حالت",
    numberOfTiers: "د ټایرونو شمیر",
    createdOn: "پر جوړ شوی",
    createATier: "یوه درجه جوړه کړئ",
    stopCreatingTierConfirmation:
      "ایا تاسو غواړئ د یوې درجې رامینځته کول ودروئ؟",
    stopEditingTierConfirmation: "ایا تاسو غواړئ د دې درجې ترمیم ودروئ؟",
    nameOfTier: "د ټایر نوم",
    minimumSpendingTarget: "د لږترلږه لګښت هدف",
    maximumSpendingTarget: "د اعظمي لګښت هدف",
    minimumSpendingTargetRequired: "د لږترلږه لګښت هدف اړین دی",
    maximumSpendingTargetRequired: "د اعظمي لګښت هدف اړین دی",
    rewardSponsor: "د انعام سپانسر",
    pleaseChooseARewardSponsor: "مهرباني وکړئ د انعام سپانسر غوره کړئ",
    self: "ځان",
    partner: "ملګری",
    rewardPartner: "د انعام ملګری",
    pleaseSelectRewardPartner: "مهرباني وکړئ خپل د انعام ملګری وټاکئ",
    rewards: "انعامونه",
    pleaseSelectAReward: "مهرباني وکړئ یو انعام وټاکئ",
    instructionsOnRedeemingReward:
      "پدې اړه لارښوونې چې پیرودونکی باید څنګه انعام ترلاسه کړي",
    pleaseFillInThisField: "مهرباني وکړئ دا ساحه ډکه کړئ!",
    toDeleteThisTier: " د دې درجې حذف کولو لپاره",
    deleteTier: "ټایر حذف کړئ",
    viewMembers: "غړي وګورئ",
    membersEnrolled: "غړي ثبت شوي",
    instruction: "لارښوونه",
    membersIn: "غړي په کې",
    availableTiersInLoyalty: "د وفادارۍ برنامې کې شتون لرونکي درجې (s)",
    tiers: "ټایرونه",
    totalTier: "ټول ټیر",
    availableLoyaltyProgramme: "د وفادارۍ برنامه شتون لري",
    totalLoyalties: "کلنۍ وفادارۍ",
    memberDetails: "د غړي توضیحات",
    nameOfCustomer: "د پیرودونکي نوم",
    address: "ادرس",
    allEnrolledMembers: "ټول نومول شوي غړي",
    thisIsToWishYouHappyBirthday:
      "دا تاسو ته د زیږون کلیزه او سوکاله ژوند هیله کوي. د هغه ټولو لپاره مننه چې تاسو لویسټر ته یاست. اختر مو مبارک شه!",
    inputAnOfferPlease: "مهرباني وکړئ یو وړاندیز دننه کړئ",
    pleaseSelectTheInsertPoint:
      "مهرباني وکړئ په پیغام کې د داخل ټکی غوره کړئ او بیا کلیک وکړئ",
    birthdayOfferAndMessage: "د زیږون وړاندیز او پیغام",
    birthdayOffer: "د زیږون وړاندیز",
    birthdayMessage: "د زوکړې پیغام",
    noOfferFound: "هیڅ وړاندیز ونه موندل شو",
    settingABirthdayOffer:
      "د زوکړې وړاندیز تنظیم کول پیرودونکو ته اجازه ورکوي دا وړاندیز د دوی د زیږون په ورځ د SMS له لارې ترلاسه کړي",
    createOffer: "وړاندیز جوړ کړئ",
    whatIsTheOffer: "وړاندیز څه شی دی؟",
    editMessage: "د پیغام سمول",
    insert: "داخل کړئ",
    theNameOfCustomerInserted: "د پیرودونکي نوم به دلته دننه شي",
    theBirtdayOfferInserted: "د زوکړې وړاندیز به دلته دننه شي",
    youSuccessfullyAddedNewBranch: "تاسو په بریالیتوب سره نوې څانګه اضافه کړه!",
    addNewBranch: "نوې څانګه اضافه کړئ",
    addBranch: "څانګه اضافه کړئ",
    additionalBranchWillIncur: "اضافي څانګه به په غاړه ولري",
    perBranch: "په یوه څانګه",
    ecommerceBranchCosts: "د ای کامرس څانګې لګښتونه",
    pleaseEnterBranchName: "مهرباني وکړئ د څانګې نوم دننه کړئ",
    pleaseEnterBranchAddress1: "مهرباني وکړئ د څانګې ادرس لاین 1 دننه کړئ",
    enterBranchAddress1: "د څانګې ادرس لاین 1 دننه کړئ",
    enterBranchAddress2: "د څانګې ادرس لاین 1 دننه کړئ",
    pleaseEnterBranchAddress2: "مهرباني وکړئ د څانګې ادرس لاین 2 دننه کړئ",
    enterBranchName: "د څانګې نوم دننه کړئ",
    successfullyAddedStaff: "تاسو په بریالیتوب سره نوی کارمند اضافه کړی!",
    addNewStaff: "نوي کارمندان اضافه کړئ",
    addStaff: "کارمندان اضافه کړئ",
    additionalStaffWillIncur: "اضافي کارمندان به راشي",
    perStaff: "په هر کارمند.",
    pleaseEnterStaffEmail: "مهرباني وکړئ د کارمند بریښنالیک دننه کړئ",
    pleaseEnterStaffUsername: "مهرباني وکړئ د کارمند کارونکي نوم دننه کړئ",
    pleaseEnterStaffPassword: "مهرباني وکړئ د کارمند رمز دننه کړئ",
    pleaseSelectStaffRole: "مهرباني وکړئ د کارمندانو رول غوره کړئ",
    selectStaffRole: "د کارمند رول غوره کړئ",
    enterStaffEmail: "د کارمند بریښنالیک دننه کړئ",
    enterStaffUsername: "د کارونکي کارونکي نوم دننه کړئ",
    enterStaffPassword: "د کارمند رمز دننه کړئ",
    spacesNotAllowedInUsername: "په کارونکي نوم کې ځایونو ته اجازه نشته",
    admin: "اداره",
    pleaseSelectBusinessToAttachStaff:
      "مهرباني وکړئ د کارمندانو ضمیمه کولو لپاره سوداګرۍ غوره کړئ",
    searchForBranchToAttachStaff: "د کارمندانو ضمیمه کولو لپاره د څانګې لټون",
    username: "د کارونکي نوم",
    role: "رول",
    areYouSureToDeleteThis: "ایا تاسو ډاډه یاست چې دا حذف کړئ",
    branches: "څانګې",
    upgradeYourPlan: "خپل پلان لوړ کړئ.",
    add: "اضافه کول",
    addNew: "نوی اضافه کړئ",
    customerWithEmailAlreadyExists:
      "پیرودونکی د بریښنالیک/تلیفون شمیره دمخه شتون لري!",
    successfullyAddedNewCustomer:
      "تاسو په بریالیتوب سره نوی پیرودونکی اضافه کړی!",
    addCustomer: "پیرودونکي اضافه کړئ",
    pleaseEnterCustomerFirstName: "مهرباني وکړئ د پیرودونکي لومړی نوم دننه کړئ",
    pleaseEnterCustomerLastName: "مهرباني وکړئ د پیرودونکي وروستی نوم دننه کړئ",
    pleaseEnterCustomerPhoneNumber:
      "مهرباني وکړئ د پیرودونکي تلیفون شمیره دننه کړئ",
    pleaseEnterCustomerEmail: "مهرباني وکړئ د پیرودونکي بریښنالیک دننه کړئ",
    pleaseEnterCustomerAddressLine:
      "مهرباني وکړئ د پیرودونکي ادرس لاین دننه کړئ",
    pleaseEnterCustomerOtherAddress:
      "مهرباني وکړئ د پیرودونکي بل آدرس دننه کړئ",
    pleaseSelectCustomerGender: "مهرباني وکړئ د پیرودونکي جنس غوره کړئ",
    gender: "جنس",
    male: "نارینه",
    female: "ښځه",
    bank: "بانک",
    selectBank: "بانک غوره کړئ",
    stateOrRegionOrProvince: "ایالت/سیمه/ولایت",
    customerNotes: "د پیرودونکي یادداشتونه",
    sendSms: "SMS واستوئ",
    editCustomer: "پیرودونکي ترمیم کړئ",
    redeemReward: "انعام ترلاسه کړئ",
    issueLoyaltyCard: "د وفادارۍ کارت صادر کړئ",
    deleteCustomer: "پیرودونکی حذف کړئ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "تاسو په بریالیتوب سره د وفادارۍ غړیتوب ID ټاکلی دی",
    noMembershipIdAvailable:
      "د غړیتوب ID شتون نلري. مهرباني وکړئ موږ سره په hello@loystar.co اړیکه ونیسئ",
    sendEmail: "برېښنا لیک ولېږه",
    membershipPoints: "د غړیتوب ټکي",
    customerDetails: "د پیرودونکي توضیحات",
    close: "تړل",
    loyaltyBalance: "د وفادارۍ توازن",
    pointsBalance: "ټکي توازن",
    starBalance: "د ستوري بیلانس",
    requiredPoints: "اړین ټکي",
    requiredStars: "اړین ستوري",
    reddemCode: "کوډ خلاص کړئ",
    toDeleteThisCustomer: "د دې پیرودونکي حذف کول",
    customerHasBeenDeletedSuccessfully: "پیرودونکی په بریالیتوب سره حذف شوی!",
    customerWithPhoneAlreadyExists: "پیرودونکی د تلیفون شمیره دمخه شتون لري",
    customerWasSuccessfullyEdited: "پیرودونکی په بریالیتوب سره ترمیم شو",
    anErrorOccured: "یوه تېروتنه رامنځته شوه",
    phoneNumberIncludeCountry: "د تلیفون شمیره (د هیواد کوډ پکې شامل کړئ)",
    yourFileQueuedForUpload:
      "ستاسو فایل د اپلوډ لپاره قطار کې دی. مهرباني وکړئ د څو ثانیو وروسته پا refه تازه کړئ.",
    thereWasAnErrorPerformingOperation:
      "د عملیاتو په ترسره کولو کې یوه تېروتنه وه!",
    pleaseSelectFile: "مهرباني وکړئ یو فایل غوره کړئ!",
    oopsOnlyCsvFilesAllowed:
      "اوف! یوازې د CSV فایلونو ته اجازه ورکړل شوې. مهرباني وکړئ د .csv فایل اپلوډ کړئ.",
    fileShouldBeSmallerThan5Mb:
      "فایل باید له 5MB څخه کوچنی وي. که تاسو لوی فایل لرئ ، مهرباني وکړئ بریښنالیک وکړئ support@loystar.co. مننه",
    customerFirstNameIsRequired: "د پیرودونکي لومړی نوم په قطار کې اړین دی",
    customerPhoneNumberIsRequired:
      "په قطار کې د پیرودونکي تلیفون شمیره اړینه ده",
    importCustomers: "پیرودونکي وارد کړئ",
    upload: "پورته کول",
    clickIconToDownloadCsv:
      "د CSV فایل ټیمپلیټ ډاونلوډ کولو لپاره پدې آیکون باندې کلیک وکړئ.",
    getGoogleSheetVersion: "د ګوګل شیټ نسخه ترلاسه کړئ",
    clickOrDragFileToUpload:
      "د پورته کولو لپاره دې سیمې ته فایل کلیک یا ډریګ کړئ",
    missingOrInvalidColumnHeader:
      "ورک شوی یا ناسم کالم سرلیک. مهرباني وکړئ د ټیمپلیټ ب followه تعقیب کړئ. مننه.",
    youHaveImported: "تاسو وارد کړي",
    youSuccessfullyRedeemedReward: "تاسو خپل انعام په بریالیتوب سره ترلاسه کړ!",
    sixDigitCode: "شپږ عددي کوډ",
    pleaseEnterCustomerRewardCode:
      "مهرباني وکړئ د پیرودونکي انعام کوډ دننه کړئ",
    enterRewardCode: "د انعام کوډ دننه کړئ. (د انعام کوډ د قضیې حساس دی)",
    selectLoyaltyProgram: "د وفادارۍ برنامه غوره کړئ",
    stamps: "ټاپې",
    smsUnitsLow: "د SMS واحدونه ټیټ",
    pleaseTopUpSmsUnits: "مهرباني وکړئ د SMS واحدونه پورته کړئ",
    smsSuccessfullySentForDelivery:
      "SMS په بریالیتوب سره د تحویلي لپاره لیږل شوی!",
    sendSmsTo: "ته SMS واستوئ",
    allCustomers: "ټول پیرودونکي",
    unitsAvailable: "واحدونه شتون لري",
    pleaseTypeInTheMessage: "مهرباني وکړئ پیغام ولیکئ",
    message: "پیغام",
    charactersRemaining: "پاتې حروف",
    avoidUseOfSpecialCharacters:
      "د SMS واحدونو خوندي کولو لپاره د ځانګړو کرکټرونو او ایموجیس کارولو څخه مخنیوی وکړئ.",
    note: "یادونه",
    errorFetchingCustomersMultilevelDetail:
      "د پیرودونکو ملټي لیول توضیحاتو ترلاسه کولو کې تېروتنه",
    search: "لټون",
    reset: "بیا تنظیم کړئ",
    importCustomer: "د پیرودونکي واردول",
    addNewCustomer: "نوی پیرودونکی اضافه کړئ",
    sendSmsBroadcast: "د ایس ایم ایس نشرات واستوئ",
    totalCustomers: "ټول پیرودونکي",
    disbursementDetails: "د توزیع توضیحات",
    paidBy: "لخوا تادیه شوې",
    disbursed: "توزیع شوی",
    bankAccountName: "د بانکي حساب نوم",
    bankAccountNumber: "د بانکي حساب شمیره",
    transferInitiated: "لیږد پیل شوی",
    transferCompleted: "لیږد بشپړ شو",
    pleaseEnterAValid: "مهرباني وکړئ یو باوري داخل کړئ",
    begin: "پيل كيدل؛ شروع كيدل: او چنېدل، راوتل",
    end: "پای",
    date: "نیټه",
    paymentDate: "د تادیې نیټه",
    selectDisbursementDuration: "د تحویل موده وټاکئ",
    totalSettled: "ټول میشته",
    totalUnsettled: "ټول بې ثباته",
    toDeleteThisSale: "د دې پلور حذف کول",
    draftSaleDeletedSuccessfully: "د مسودې پلور په بریالیتوب سره حذف شوی!",
    deleteSale: "خرڅلاو ړنګول",
    pleaseTypeInYourTotal: "مهرباني وکړئ د حذف کولو تایید لپاره خپل ټول ولیکئ",
    billDetails: "د بیل توضیحات",
    printBill: "بیل چاپ کړئ",
    servedBy: "لخوا خدمت شوی",
    total: "ټول",
    createdDate: "د جوړیدو نیټه",
    createdTime: "جوړ شوی وخت",
    status: "حالت",
    loginSuccessful: "ننوتل بریالي",
    pleaseWaitWhileWeConnectAccount:
      "مهرباني وکړئ انتظار وکړئ کله چې موږ ستاسو حساب وصل کړو",
    connectionFailedTryAgain: "اړیکه پري شوه. مهرباني وکړئ بیا هڅه وکړئ.",
    connectionSuccessful: "پیوستون بریالی شو",
    viewDetails: "جزیات وګورئ",
    enable: "فعال کړئ",
    free: "وړیا",
    cost: "لګښت",
    visitWebsite: "ویب پا Visitې څخه لیدنه وکړئ",
    pleaseUpgradeYourPlanToPro:
      "مهرباني وکړئ د دې اپلیکیشن فعالولو لپاره خپل پلان پرو پلس ته لوړ کړئ",
    connectYourBankAccount:
      "خپل بانکي حساب وصل کړئ ترڅو د تادیاتو ترلاسه کولو وړ اوسئ.",
    disable: "غیر فعال کول",
    enableApp: "اپلیکیشن فعال کړئ",
    addNewProductToInvoice: "په انوائس کې نوی محصول اضافه کړئ",
    toDeleteThisInvoice: "دا انوائس حذف کولو لپاره",
    invoiceDeletedSuccessfully: "رسید په بریالیتوب سره حذف شوی!",
    deleteInvoice: "انوائس حذف کړئ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "تاسو په بریالیتوب سره د انوائس تادیې پیغام اضافه کړی!",
    pleaseEnterPaymentInstructions: "مهرباني وکړئ د تادیې لارښوونې دننه کړئ",
    invoiceId: "د رسید ID",
    paidDate: "د ورکړې نیټه",
    reference: "حواله",
    productAdded: "محصول اضافه شوی",
    productOutOfStock: "محصول له سټاک څخه بهر. مهرباني وکړئ بیا ذخیره کړئ.",
    totalInvoices: "ټول حسابونه",
    totalPaidInvoices: "ټول تادیه شوي رسیدونه",
    totalUnpaidInvoices: "ټول نه تادیه شوي رسیدونه",
    loyaltyProgramDeleted: "د وفادارۍ پروګرام حذف شو",
    thisLoyaltyProgramDeletedSuccessfully:
      "دا د وفادارۍ برنامه په بریالیتوب سره حذف شوې",
    thisLoyaltyProgramEditedSuccessfully:
      "دا د وفادارۍ برنامه په بریالیتوب سره ایډیټ شوې",
    loyaltyProgramAddedSuccessfully:
      "د وفادارۍ برنامه په بریالیتوب سره اضافه شوې",
    loyaltyProgramEdited: "د وفادارۍ پروګرام ایډیټ شوی",
    loyaltyProgramAdded: "د وفادارۍ برنامه اضافه شوه",
    loyaltyDetails: "د وفادارۍ توضیحات",
    doYouWantToCloseDialog: "ایا تاسو غواړئ دا ډیالوګ وتړئ؟",
    pleaseEnterLoyaltyName: "مهرباني وکړئ د خپل وفادارۍ نوم دننه کړئ",
    programType: "د پروګرام ډول",
    pleaseSelectProgramType: "مهرباني وکړئ د برنامې ډول غوره کړئ",
    simplePoints: "ساده ټکي",
    stampsProgram: "د ټاپونو برنامه",
    threshold: "حد",
    pleaseEnterLoyaltyThreshold: "مهرباني وکړئ د وفادارۍ حد داخل کړئ",
    reward: "ثواب",
    pleaseEnterLoyaltyReward: "مهرباني وکړئ د وفادارۍ انعام درج کړئ",
    totalUserPoints: "د کارونکي ټول ټکي",
    totalUserStamps: "د کارونکي ټول ټاپې",
    spendingTarget: "هدف لګول",
    spendingTargetHint1:
      "د لګښت هدف دا دی چې پیرودونکی باید د انعام ترلاسه کولو لپاره څومره مصرف کړي. د 1 اسعارو ارزښت = 1 ټکی.",
    spendingTargetHint2:
      "د ټاپونو هدف دا دی چې پیرودونکي باید د جایزې ترلاسه کولو لپاره څومره ټاپې راټول کړي. د مثال په توګه 5",
    addNewLoyaltyProgram: "د نوي وفادارۍ برنامه اضافه کړئ",
    addLoyaltyProgram: "د وفادارۍ برنامه اضافه کړئ",
    loyaltyProgramType: "د وفادارۍ پروګرام ډول",
    pleaseSelectLoyaltyProgramType:
      "مهرباني وکړئ د وفادارۍ پروګرام ډول غوره کړئ",
    nameOfProgram: "د پروګرام نوم",
    pleaseEnterProgramName: "مهرباني وکړئ د برنام نوم دننه کړئ",
    whatIsTheReward: "ثواب څه شی دی؟",
    egNextPurchaseIsFree: "د مثال په توګه راتلونکی پیرود وړیا دی",
    customerName: "پیریدونکي نوم",
    guestCustomer: "میلمه پیرودونکی",
    orderConfirmedSuccessfully: "امر په بریالیتوب سره تایید شو",
    orderCancelledSuccessfully: "امر په بریالیتوب سره لغوه شو",
    confirmOrder: "امر تایید کړئ",
    cancelOrder: "امر لغوه کړئ",
    allOrders: "ټول امرونه",
    totalOrders: "ټول امرونه",
    doYouWantToAcceptOrder: "ایا تاسو غواړئ دا امر ومني؟",
    doYouWantToCancelOrder: "ایا تاسو غواړئ دا امر لغوه کړئ؟",
    orderDetails: "د امر توضیحات",
    orderCreatedAt: "امر په کې جوړ شوی",
    supplier: "عرضه کونکی",
    productName: "د محصول نوم",
    quantity: "مقدار",
    unitPrice: "د واحد قیمت",
    receivedBy: "لخوا ترلاسه شوی",
    reportFrom: "څخه راپور",
    totalSupplies: "ټول سامانونه",
    allRightsReserved: "ټول حقونه خوندي دي",
    toDeleteThisTransaction: "د دې راکړې ورکړې حذف کول",
    transactionDeletedSuccessfully:
      "معامله په بریالیتوب سره حذف شوې. ذخیره بیرته انوینٹری ته راستون شوی.",
    deleteTransaction: "راکړه ورکړه حذف کړئ",
    transactionDetails: "د راکړې ورکړې توضیحات",
    printReceipt: "د چاپ رسید",
    channel: "چینل",
    discount: "تخفیف",
    profit: "ګټه",
    discountedSales: "تخفیف پلور",
    errorFetchingRecord: "د ریکارډ په ترلاسه کولو کې تېروتنه",
    exportTransactions: "د صادراتو راکړه ورکړه",
    errorFetchingSalesRecord:
      "د صادراتو لپاره د پلور ریکارډ ترلاسه کولو کې تېروتنه.",
    totalSellingPrice: "د پلورلو ټول قیمت",
    totalCostPrice: "د ټول لګښت قیمت",
    appliedDiscount: "پلي شوي تخفیف",
    noOfItems: "د توکو شمیره",
    sales: "خرڅلاو",
    export: "صادرات",
    totalProfit: "ټوله ګټه",
    totalBalanceInPeriod: "په دوره کې ټول توازن",
    allTimeSales: "ټول وخت پلور",
    records: "ریکارډونه",
    todaysSales: "د نن ورځې پلور",
    transaction: "راکړه ورکړه",
    youHaveExceededTotalNumberOfProducts:
      "تاسو په خپل پلان کې اجازه ورکړل شوي محصولاتو ټول شمیر څخه تجاوز کړی. د لوړ حد څخه خوند اخیستو لپاره خپل پلان لوړ کړئ.",
    youNeedToHaveLoyaltyProgram:
      "تاسو اړتیا لرئ د دې ب useې کارولو لپاره د وفادارۍ برنامه ولرئ!",
    price: "نرخ",
    category: "کټګوري",
    stockTracking: "د سټاک تعقیب",
    stockCount: "د ذخیره شمیره",
    taxed: "مالیه",
    originalPrice: "اصلي قیمت",
    costPrice: "د لګښت قیمت",
    unit: "واحد",
    productImage: "د محصول انځور",
    taxRate: "د مالیې نرخ",
    taxType: "د مالیې ډول",
    trackInventory: "د تعقیب انوینٹری",
    variants: "وریځو",
    hasVariants: "ډولونه لري",
    importProduct: "د وارداتو محصول",
    exportProducts: "د صادراتو محصولات",
    addNewProduct: "نوی محصول اضافه کړئ",
    viewCategory: "کتګورۍ وګورئ",
    viewSuppliers: "عرضه کونکي وګورئ",
    receiveInventory: "انوینٹری ترلاسه کړئ",
    printAllProductsTag: "د ټولو محصولاتو ټګ چاپ کړئ",
    deleteAll: "ټول حذف کړئ",
    totalProducts: "ټول محصولات",
    youveSuccessfullyAddedANewCategory:
      "تاسو په بریالیتوب سره یوه نوې کټګوري اضافه کړه",
    addNewCategory: "نوې کټګوري اضافه کړئ",
    addCategory: "کټګوري اضافه کړئ",
    categoryName: "د کټګورۍ نوم",
    pleaseEnterCategoryName: "مهرباني وکړئ د کټګورۍ نوم دننه کړئ",
    stampsTarget: "د ټاپې هدف",
    sendInventory: "د توکو لیږل",
    productDetails: "د محصول توضیحات",
    youveSuccessfullyEditedThisCategory:
      "تاسو دا کټګوري په بریالیتوب سره ترمیم کړې",
    update: "تازه کول",
    categoryList: "د کټګورۍ لیست",
    categories: "کټګورۍ",
    enterQuantityToUpdate: "د تازه کولو لپاره مقدار دننه کړئ",
    inventorySentSuccessfully: "انوینٹری په بریالیتوب سره لیږل شوی!",
    updateInventory: "انوینٹری تازه کړئ",
    currentQuantity: "اوسنی مقدار",
    pleaseEnterQuantityToAdd:
      "مهرباني وکړئ هغه مقدار دننه کړئ چې تاسو یې اضافه کول غواړئ",
    pleaseSelectABranch: "مهرباني وکړئ یوه څانګه غوره کړئ",
    searchForBranch: "د څانګې لټون",
    youCantSendMoreThanStock:
      "تاسو نشئ کولی له هغه څخه ډیر چې تاسو په ذخیره کې لرئ ولیږئ",
    send: "لیږل",
    pleaseEnterQuantityToSend:
      "مهرباني وکړئ هغه مقدار دننه کړئ چې تاسو یې لیږل غواړئ",
    productNameIsRequiredOnRow: "د محصول نوم په قطار کې اړین دی",
    productCategoryIsRequiredOnRow: "په قطار کې د محصول کټګورۍ ته اړتیا ده",
    productPriceIsRequiredOnRow: "د محصول قیمت په قطار کې اړین دی",
    productUnitIsRequiredOnRow: "په قطار کې د محصول واحد ته اړتیا ده",
    productQuantityIsRequiredOnRow: "د محصول مقدار په قطار کې اړین دی",
    productVariantsRequireTracking: "د محصول ډولونه تعقیب ته اړتیا لري!",
    pleaseAddVariantClickButton:
      "مهرباني وکړئ د اضافه ت buttonۍ په کلیک کولو سره یو ډول اضافه کړئ!",
    totalVariantsMoreThanSelectedQuantity:
      "ټول ډولونه د ټاکل شوي محصول مقدار څخه ډیر دي ، Pls د مختلف مقدار کموي",
    productEditedSuccessfully: "محصول په بریالیتوب سره ترمیم شوی!",
    fileTooLargeLessThan4Mb:
      "د فایل اندازه خورا لویه ده! د فایل اندازه باید له 4MB څخه کم وي.",
    suchVariantAlreadyExist: "دا ډول ډول دمخه شتون لري",
    addVariants: "ډولونه اضافه کړئ",
    editProduct: "محصول ترمیم کړئ",
    pleaseEnterProductName: "مهرباني وکړئ د محصول نوم دننه کړئ",
    pleaseEnterProductPrice: "مهرباني وکړئ د محصول قیمت دننه کړئ",
    pleaseEnterProductOriginalPrice: "مهرباني وکړئ د محصول اصلي قیمت دننه کړئ",
    productDescription: "د تولید ځانګړتیاوې",
    selectProductCategory: "د محصول کټګوري غوره کړئ",
    pleaseSelectProductCategory: "مهرباني وکړئ د محصول کټګوري غوره کړئ",
    productCostPrice: "د محصول لګښت قیمت",
    productSellingPrice: "د محصول پلور قیمت",
    productOriginalPrice: "د محصول اصلي قیمت",
    maxFileSizeAllowedIs4Mb: "اعظمي د فایل اندازه اجازه ورکړل شوې 4mb ده",
    productsWithPicturesArePublished:
      "د عکسونو سره محصولات په ډیسکور کې د امرونو ترلاسه کولو لپاره خپاره شوي",
    shouldThisProductBeTracked: "ایا دا محصول باید تعقیب شي؟",
    pleaseSelectStockUnit: "مهرباني وکړئ د سټاک واحد غوره کړئ",
    stockUnit: "د سټاک واحد",
    bag: "بیګ",
    bottle: "بوتل",
    bunch: "بنچ",
    can: "CAN",
    carton: "کارتون",
    crate: "CRATE",
    cup: "CUP",
    dozen: "دوزن",
    gigabytes: "ګیګابایټس",
    gram: "ګرام",
    kilogram: "کیلوګرام",
    litre: "LITER",
    pack: "پیک",
    pair: "جوړه",
    pieces: "PIECES",
    plate: "پلیټ",
    tonne: "ټون (MT)",
    uNIT: "UNIT",
    yard: "یارډ",
    pleaseEnterProductQuantity: "مهرباني وکړئ د محصول مقدار دننه کړئ",
    productQuantity: "د محصول مقدار",
    isThisProductTaxed: "ایا دا محصول مالیه کیږي؟",
    selectTaxType: "د مالیې ډول غوره کړئ",
    pleaseSelectTaxType: "مهرباني وکړئ د مالیې ډول غوره کړئ",
    progressive: "پرمختګ کوونکی",
    proportional: "تناسب",
    pleaseEnterProductTaxRate: "مهرباني وکړئ د محصول مالیې نرخ دننه کړئ",
    doesProductHaveVariants: "ایا دا محصول ډولونه لري؟",
    type: "ډول",
    value: "ارزښت",
    pleaseEnterVariantType: "مهرباني وکړئ د ډول ډول دننه کړئ",
    pleaseEnterVariantValue: "مهرباني وکړئ د متغیر ارزښت دننه کړئ",
    pleaseEnterVariantPrice: "مهرباني وکړئ د متغیر قیمت دننه کړئ",
    pleaseEnterVariantQuantity: "مهرباني وکړئ د متغیر مقدار دننه کړئ",
    productDeletedSuccessfully: "محصول په بریالیتوب سره حذف شوی!",
    categoryDeletedSuccessfully: "کټګوري په بریالیتوب سره حذف شوې!",
    toDeleteThisProduct: "د دې محصول حذف کولو لپاره",
    invalidProductQuantity: "د محصول ناسم مقدار",
    quantityAddedIsOverStock:
      "هغه مقدار چې تاسو یې اضافه کوئ هغه څه دي چې تاسو یې په سټاک کې لرئ.",
    itemInventoryNotTracked: "د توکو فهرست ندی تعقیب شوی",
    addBulkQuantity: "لوی مقدار اضافه کړئ",
    enterBulkQuantity: "لوی مقدار داخل کړئ",
    pleaseEnterBulkQuantity: "مهرباني وکړئ لوی مقدار دننه کړئ",
    youveSuccessfullyAddedANewProduct:
      "تاسو په بریالیتوب سره نوی محصول اضافه کړی!",
    pleaseEnterProductSellingPrice: "مهرباني وکړئ د محصول پلور نرخ دننه کړئ",
    doYouWantToTrackProductStock: "ایا تاسو غواړئ د محصول ذخیره تعقیب کړئ؟",
    sellingPrice: "د پلور قیمت",
    setProductExpiryReminder: "د محصول پای ته رسیدو یادونه تنظیم کړئ",
    productExpiryDate: "د محصول پای نیټه",
    startRemindingFrom: "څخه یادونه پیل کړئ",
    productSuppliesAddedSuccessfully:
      "تاسو په بریالیتوب سره د محصول اکمالات اضافه کړي.",
    addProductSupplies: "د محصول تجهیزات اضافه کړئ",
    pleaseSelectSupplier: "مهرباني وکړئ عرضه کونکی غوره کړئ",
    nameOfProduct: "د محصول نوم",
    pleaseSelectProduct: "مهرباني وکړئ محصول غوره کړئ",
    productVariant: "د محصول ډول",
    pleaseSelectAVariant: "مهرباني وکړئ یو ډول غوره کړئ",
    pleaseEnterUnitPrice: "مهرباني وکړئ د واحد قیمت دننه کړئ",
    businessBranch: "د سوداګرۍ څانګه",
    pleaseSelectBranch: "مهرباني وکړئ څانګه غوره کړئ",
    youveSuccessfullyAddedANewSupplier:
      "تاسو په بریالیتوب سره نوی عرضه کونکی اضافه کړی",
    addSupplier: "عرضه کونکی اضافه کړئ",
    pleaseEnterSupplierEmail: "مهرباني وکړئ د عرضه کونکي بریښنالیک دننه کړئ",
    pleaseAddADescription: "مهرباني وکړئ یو تفصیل اضافه کړئ",
    anErrorOccuredProductsDeleted:
      "د عملیاتو ترسره کولو پرمهال یوه ستونزه رامینځته شوه. مهرباني وکړئ په یاد ولرئ ، ځینې محصولات ممکن په پروسه کې حذف شوي وي",
    bulkDelete: "بلک ړنګول",
    youAreAboutToDelete: "تاسو د ړنګیدو په حال کې یاست",
    product: "محصول",
    isDueToRedeem: "د بیرته ورکولو له امله دی",
    aReward: "یو انعام",
    pleaseSelectCustomerToReeemReward:
      "مهرباني وکړئ د انعام ترلاسه کولو لپاره پیرودونکی وټاکئ.",
    youHaveNoLoyaltyProgramRunning: "تاسو د وفادارۍ کوم فعال برنامه نلرئ",
    customerHhasNoPointsInLoyaltyProgram:
      "پیرودونکی پدې وفادارۍ برنامې کې هیڅ ټکي نلري",
    proceedWithPayment: "د تادیې سره پرمخ ځئ؟",
    youAreAboutToPayForTransactionUsingPoints:
      "تاسو د ټکو په کارولو سره د معاملې لپاره تادیه کوئ.",
    customerHas: "پیرودونکی لري",
    worth: "ارزښت",
    andIsNotSufficientToPayForTransaction:
      "او د دې راکړې ورکړې لپاره کافي ندي. ایا دوی غواړي د تادیې بل میتود په کارولو سره توازن اضافه کړي؟",
    addCustomerLoyalty: "د پیرودونکي وفاداري اضافه کړئ",
    pleaseAddCustomerFirst:
      "مهرباني وکړئ لومړی یو پیرودونکی اضافه کړئ یا غوره کړئ.",
    pleaseWaitWhileWeGetReady:
      "مهرباني وکړئ انتظار وکړئ ، پداسې حال کې چې موږ چمتو کیږو",
    lowStock: "ټیټ ذخیره",
    pleaseEnterAmountTendered: "مهرباني وکړئ ورکړل شوې اندازه دننه کړئ",
    areYouSureToBookSaleOffline: "ایا تاسو واقعیا غواړئ دا پلور آنلاین بک کړئ؟",
    saleWouldBeBookedAutomatically:
      "پلور به په اوتومات ډول بک شي کله چې تاسو خپل انټرنیټ چالان کړئ",
    offlineSalesBookingCancelled: "د آنلاین پلور بکینګ لغوه شو",
    covid19Message:
      "کوویډ 19: خپل لاسونه په صابون ومینځئ یا پاک کړئ ترڅو د خپریدو مخه ونیسئ. تل خوندي اوسئ",
    saleSuccessfullyRecorded: "پلور په بریالیتوب سره ثبت شو!",
    sendReceiptToEmail: "بریښنالیک ته رسید ولیږئ",
    sendThankYouSms: "مننه SMS واستوئ",
    sendShippingDetails: "د بار وړلو توضیحات واستوئ",
    addLoyalty: "وفاداري زیاته کړئ",
    searchCustomerNameOrNumber: "د پیرودونکي نوم یا شمیره وپلټئ",
    clickTheSearchCustomerBox: "د لټون پیرودونکي بکس او سکین کارت کلیک وکړئ",
    enterProductPrice: "د محصول قیمت دننه کړئ",
    enterPriceFor: "لپاره قیمت دننه کړئ",
    searchForProduct: "د محصول لټون",
    all: "ټول",
    backToDashboard: "بیرته ډشبورډ ته",
    viewDraftSales: "د مسودې پلور وګورئ",
    variantSelected: "ډول غوره شوی",
    variant: "بiantه",
    thePreviousVariantSelectionNotAvailable:
      "د نرخ پراساس غوره شوي نوي ډول لپاره پخوانی متغیر انتخاب شتون نلري ، مهرباني وکړئ خپل انتخاب بدل کړئ.",
    pleaseSupplyPositiveQuantityNumber:
      "مهرباني وکړئ د پوستي مقدار شمیره وړاندې کړئ",
    lowStockFor: "لپاره کم ذخیره",
    clearVariants: "تغیرات پاک کړئ",
    pleaseEnterQuantity: "مهرباني وکړئ مقدار دننه کړئ",
    picture: "انځور",
    redemptionToken: "د خلاصون نښه",
    token: "نښه",
    totalSpent: "ټول مصرف شوی",
    confirmPayment: "تادیه تایید کړئ",
    hasPaymentBeenMade: "ایا تادیه په بریالیتوب سره پروسس شوې؟",
    enterTransactionReference:
      "د راکړې ورکړې حواله دننه کړئ چې تاسو ورسره تادیه کړې",
    pleaseSelectACustomer: "مهرباني وکړئ یو پیرودونکی وټاکئ!",
    areYouSureToApplyDiscount: "ایا تاسو واقعیا غواړئ د تخفیف غوښتنه وکړئ؟",
    addYourBankAccountToEnableUssd:
      "د uPay لخوا د USSD فوري لیږد فعالولو لپاره خپل بانکي حساب اضافه کړئ",
    totalAmountToPay: "د تادیې ټول مقدار",
    doYouWantToCancelTransaction: "ایا تاسو غواړئ دا معامله لغوه کړئ؟",
    savePrintBill: "بل خوندي کړئ/چاپ کړئ",
    enterAmountCollectedForCustomer:
      "د پیرودونکي لپاره راټول شوي مقدار دننه کړئ",
    recordSale: "د پلور ریکارډ",
    checkOutWith: "سره وګوره",
    instantTransfer: "فوري انتقال",
    dialTheUSSDCode: "د USSD کوډ ډایل کړئ",
    pleaseSelectABank: "مهرباني وکړئ یو بانک غوره کړئ",
    payWithUSSD: "د USSD سره تادیه وکړئ",
    sendBillTo: " - بل ته ولیږئ",
    waitingForUSSDTransfer: "د USSD لیږد ته انتظار",
    percent: "سلنه",
    applyDiscount: "تخفیف پلي کړئ",
    thisBillHasBeenSaved: "دا بل خوندي شوی",
    saveDraft: "مسوده خوندي کړئ",
    pleaseTypeANameToIdentifyCustomer:
      "مهرباني وکړئ د پیرودونکي پیژندلو لپاره نوم ولیکئ",
    paymentDetails: "د تاديې جزئيات",
    basePrice: "اساس قیمت",
    staff: "کارکوونکي",
    subTotal: "فرعي مجموعه",
    durationMonths: "موده (میاشتې)",
    selectADuration: "موده وټاکئ",
    oneMonth: "1 مياشت",
    twoMonths: "2 میاشتې",
    threeMonths: "3 میاشتې",
    sixMonths: "6 مياشتې",
    twelveMonths: "12 میاشتې",
    eighteenMonths: "18 میاشتې",
    twentyFourMonths: "24 میاشتې",
    twoMonthsFree: "(2 میاشتې وړیا)",
    threeMonthsFree: "(3 میاشتې وړیا)",
    fiveMonthsFree: "(5 میاشتې وړیا)",
    yourAccountHasBeen: "ستاسو حساب و",
    renewed: "نوی شوی",
    upgraded: "لوړ شوی",
    successfully: "په بریالیتوب سره",
    yourSubscription: "ستاسو ګډون",
    youAreCurrentlyEnrolledOnA: "تاسو دا مهال په a کې نوم لیکنه کوئ",
    pleaseChooseAPaymentOption: "مهرباني وکړئ د تادیې اختیار غوره کړئ",
    planRenewal: "د نوي کولو پلان",
    planUpgrade: "د پلان لوړول",
    pleaseSelectDurationToPayFor:
      "مهرباني وکړئ هغه موده وټاکئ چې تاسو یې تادیه کولو اراده لرئ",
    staffAccounts: "د کارمندانو حسابونه",
    ecommerce: "ای کامرس",
    pleaseSelectAPlan: "مهرباني وکړئ یو پلان غوره کړئ",
    includeAddons: "اضافه کول شامل کړئ",
    viewTransactions: "راکړه ورکړه وګورئ",
    customerHasNoCompletedTansactions:
      "پیرودونکي تر دې دمه بشپړ تحلیلونه ندي کړي",
    branch: "څانګه",
    enterNumberOfEcommerceBranches: "د ای کامرس څانګو شمیره دننه کړئ",
    enterNumberOfEcommerceBranchesToPay:
      "د ای کامرس څانګو شمیره دننه کړئ چې تاسو یې تادیه کولو اراده لرئ",
    ecommerceIntegration: "د ای کامرس ادغام",
    enterNumberOfBranches: "د څانګو شمیره دننه کړئ",
    enterNumberOfAdditionalBranchesToPay:
      "د اضافي څانګو شمیره دننه کړئ چې تاسو یې تادیه کولو اراده لرئ",
    enterNumberOfStaffs: "د کارمندانو شمیره دننه کړئ",
    enterNumberOfStaffsToPayFor:
      "د هغه کارمندانو شمیره دننه کړئ چې تاسو یې تادیه کولو اراده لرئ",
    discountApplies: "تخفیف پلي کیږي",
    starsOnThe: "ستوري په",
    offer: "وړاندیز",
    get: "ترلاسه کړئ",
    moreStarsToRedeem: "د خلاصولو لپاره نور ستوري",
    taxVat: "مالیه (VAT)",
    callCashierToCompletePayment: "د تادیې بشپړولو لپاره کیشیر ته زنګ ووهئ",
    receipt: "رسید",
    dear: "ګرانه",
    thankYouForYourOrderFindGoods:
      "ستاسو د امر لپاره مننه. مهرباني وکړئ لاندې ورکړل شوي توکي ومومئ ، لکه څنګه چې موافقه شوې.",
    shippingNote: "د بار وړلو یادونه",
    enterShippingNote: "د بار وړلو یادداشت دننه کړئ",
    shippingAddress: "د وړلو او راوړلو پته",
    enterShippingAddress: "د بار وړلو ادرس دننه کړئ",
    wellDoneYouAreDueToRedeem: "ډیر ښه! تاسو د خلاصولو له امله یاست",
    toGetYourRewardNextVisit:
      "په راتلونکي لیدنه کې ستاسو انعام ترلاسه کول. مننه",
    pointsOnThe: "په باندې ټکي",
    morePointsToRedeem: "د خلاصولو لپاره نور ټکي",
    showCode: "کوډ ښکاره کړئ",
    toGetYourRewardOnNextVisit:
      "په راتلونکي لیدنه کې ستاسو انعام ترلاسه کول. مننه",
    earn: "ګټل",
    delivaryNote: "د سپارلو یادښت",
    draftSales: "د مسودې پلور",
    startDate: "پیل نېټه",
    endDate: "د پای نیټه",
    orders: "امرونه",
    checkout: "بشپړ ی وګوره",
    noProductItem: "د محصول توکي نشته",
    selectProductImage: "د محصول عکس غوره کړئ",
    selectCountry: "هیواد غوره کړئ",
    selectRegion: "ایالت/سیمه غوره کړئ",
    printProductTag: "د محصول ټګ چاپ کړئ",
    transactionReference: "د راکړې ورکړې حواله",
    Cashier: "کیشیر",
    Manager: "مدیر",
    Owner: "مالک",
    Admin: "اداره",
    addPartners: "شریکان اضافه کړئ",
    addNewLoyaltyPartner: "نوی وفادار ملګری اضافه کړئ",
    pleaseEnterCompanyName: "مهرباني وکړئ د شرکت نوم دننه کړئ",
    companyName: "دکمپنی نوم",
    pleaseEnterCompanyRepName: "مهرباني وکړئ د شرکت استازی نوم ولیکئ",
    companyRepName: "د شرکت د استازي نوم",
    pleaseEnterCompanyRepEmail: "مهرباني وکړئ د شرکت استازي بریښنالیک دننه کړئ",
    companyRepEmail: "د شرکت استازی بریښنالیک",
    pleaseEnterCompanyRepPhone:
      "مهرباني وکړئ د شرکت استازي د تلیفون شمیره دننه کړئ",
    companyRepPhone: "د شرکت استازی د تلیفون شمیره",
    addReward: "انعام اضافه کړئ",
    pleaseEnterRewardName: "مهرباني وکړئ د انعام نوم ولیکئ",
    rewardName: "د انعام نوم",
    rewardQuantity: "د انعام مقدار",
    rewardDescription: "د انعام تفصیل",
    rewardType: "د انعام ډول",
    pleaseEnterRewardType: "مهرباني وکړئ د انعام ډول داخل کړئ",
    pleaseEnterRewardQuantity: "مهرباني وکړئ د انعام مقدار ولیکئ",
    pleaseEnterRewardDescription: "مهرباني وکړئ د انعام توضیحات دننه کړئ",
    fineDining: "ښه ډوډۍ",
    luxuryFashion: "لوکس فیشن",
    hotels: "هوټلونه",
    travel: "سفر",
    foodAndBeverage: "خواړه او څښاک",
    fashion: "فیشن",
    health: "روغتیا",
    furniture: "فرنیچر",
    entertainment: "تفریح",
    automobile: "موټروان",
    education: "زده کړه",
    beautyAndSpa: "ښکلا او سپا",
    staycation: "پاتې کیدل",
    events: "پیښې",
    trips: "سفرونه",
    oilAndGas: "تېل او ګاز",
    laundry: "د کالو مینځل",
    partnerCategory: "د ملګري کټګوري",
    freeItem: "وړيا توکي",
  },
  "Odia (Oriya)": {
    cashier: "କ୍ୟାସିଅର୍",
    manager: "ପରିଚାଳକ",
    owner: "ମାଲିକ",
    online: "ଅନଲାଇନ୍ |",
    offline: "ଅଫଲାଇନ୍",
    changePassword: "ପାସୱାର୍ଡ ପରିବର୍ତ୍ତନ କରନ୍ତୁ",
    currentPasswordMessage:
      "ଦୟାକରି ଆପଣଙ୍କର ସାମ୍ପ୍ରତିକ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    passwordMessage: "ଦୟାକରି ଆପଣଙ୍କର ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    currentPassword: "ଏବେକାର ପାସୱାର୍ଡ଼",
    password: "ପାସୱାର୍ଡ",
    confirmPasswordMessage: "ଦୟାକରି ଆପଣଙ୍କର ପାସୱାର୍ଡ ନିଶ୍ଚିତ କରନ୍ତୁ!",
    confirmPassword: "ପାସ୍ବାଡ଼ ନିସ୍ଚିତ କର",
    sendViaEmail: "ଇମେଲ୍ ମାଧ୍ୟମରେ ପଠାନ୍ତୁ |",
    sendViaWhatsapp: "ହ୍ ats ାଟସ୍ ଆପ୍ ମାଧ୍ୟମରେ ପଠାନ୍ତୁ |",
    downloadPdf: "PDF ଡାଉନଲୋଡ୍ କରନ୍ତୁ |",
    paid: "ଦେୟ",
    unpaid: "ଦେୟମୁକ୍ତ",
    partial: "ଆଂଶିକ",
    closeInvoice: "ଆପଣ ଇନଭଏସ୍ ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    closeInvoiceConfirmation:
      "ଇନଭଏସ୍ ସଞ୍ଚୟ ହୋଇନପାରେ | ଆପଣ ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    yes: "ହଁ",
    no: "ନା",
    invoice: "ଇନଭଏସ୍ |",
    wasDeducted: "କଟାଯାଇଥିଲା |",
    for: "ପାଇଁ",
    item: "ଆଇଟମ୍ |",
    addProduct: "ଉତ୍ପାଦ ଯୋଡନ୍ତୁ |",
    paymentReference: "ଦେୟ ସନ୍ଦର୍ଭ",
    amountPaid: "ଦେୟ ପରିମାଣ",
    discountAmount: "ରିହାତି ପରିମାଣ",
    amountDue: "ଦେୟ ପରିମାଣ",
    amount: "ପରିମାଣ",
    dueDate: "ଧାର୍ଯ୍ୟ ତାରିଖ",
    paymentType: "ଦେୟ ପ୍ରକାର",
    card: "କାର୍ଡ",
    cash: "ନଗଦ",
    bankTransfer: "ବ୍ୟାଙ୍କ ସ୍ଥାନାନ୍ତର |",
    paymentMessage: "ଦେୟ ବାର୍ତ୍ତା |",
    description: "ବର୍ଣ୍ଣନା",
    sendReceipt: "ରସିଦ ପଠାନ୍ତୁ |",
    delete: "ବିଲୋପ କରନ୍ତୁ |",
    save: "ସଞ୍ଚୟ କରନ୍ତୁ |",
    resend: "ପୁନ-ପଠାନ୍ତୁ |",
    saveAndSend: "ସଞ୍ଚୟ କରନ୍ତୁ |",
    invoiceSaved: "ଇନଭଏସ୍ ସଞ୍ଚୟ ହୋଇଛି!",
    selectPaymentMethod: "ଦୟାକରି ଏକ ଦେୟ ପଦ୍ଧତି ବାଛନ୍ତୁ!",
    selectCustomer: "ଦୟାକରି ଏକ ଗ୍ରାହକ ଚୟନ କରନ୍ତୁ!",
    cartEmptyError:
      "କାର୍ଟ ତାଲିକା ଖାଲି ହୋଇପାରିବ ନାହିଁ, ଇନଭଏସ୍ ବନ୍ଦ କରନ୍ତୁ ଏବଂ କାର୍ଟରେ ଆଇଟମ୍ ଯୋଡନ୍ତୁ!",
    subscriptionExpired:
      "ଆପଣଙ୍କର ସଦସ୍ୟତା ସମାପ୍ତ ହୋଇଛି ଏବଂ ଆପଣଙ୍କର ଖାତା ବର୍ତ୍ତମାନ ସୀମିତ ଅଟେ | ଆପଣଙ୍କର ଖାତା ନବୀକରଣ କରିବାକୁ ନିମ୍ନରେ ଥିବା ବଟନ୍ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
    renew: "ନବୀକରଣ କରନ୍ତୁ |",
    holdOn: "ରହିଯାଅ",
    customerBank: "ଗ୍ରାହକ ବ୍ୟାଙ୍କ",
    cancel: "ବାତିଲ୍ କରନ୍ତୁ |",
    selectACustomer: "ଗ୍ରାହକ ଚୟନ କରନ୍ତୁ |",
    invoiceSuccessfulPdfError:
      "ଇନଭଏସ୍ ସଫଳତାର ସହିତ ସୃଷ୍ଟି ହୋଇଛି କିନ୍ତୁ ପିଡିଏଫ୍ ଜେନେରେସନ୍ ସାଧାରଣ ଅପେକ୍ଷା ଅଧିକ ସମୟ ନେଉଛି | ଦୟାକରି ଶୀଘ୍ର ଯାଞ୍ଚ କରନ୍ତୁ |",
    downloadingInvoice: "ଇନଭଏସ୍ ଡାଉନଲୋଡ୍ କରୁଛି |",
    downloadingReceipt: "ରସିଦ ଡାଉନଲୋଡ୍ କରୁଛି |",
    whatsappReceiptError:
      "ହ୍ ats ାଟସ୍ ଆପ୍ ମାଧ୍ୟମରେ ରସିଦ ପଠାଇବାରେ ଏକ ତ୍ରୁଟି ଘଟିଛି, ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    receiptToWhatsapp: "ରସିଦ ହ୍ ats ାଟସ୍ ଆପ୍କୁ ପଠାଗଲା |",
    thankYouForPatronage: "ତୁମର ପୃଷ୍ଠପୋଷକତା ପାଇଁ ଧନ୍ୟବାଦ |",
    hereIsYourReceipt: "ଏଠାରେ ଆପଣଙ୍କର ଦେୟ ରସିଦ ଅଛି |",
    errorSendingEmailReceipt:
      "ଇ-ମେଲ ମାଧ୍ୟମରେ ରସିଦ ପଠାଇବାରେ ଏକ ତ୍ରୁଟି ଘଟିଛି, ଦୟାକରି ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ କିମ୍ବା ସମର୍ଥନ ଯୋଗାଯୋଗ କରନ୍ତୁ |",
    receiptSentToEmail: "ଗ୍ରାହକଙ୍କ ଇମେଲକୁ ରସିଦ ପଠାଯାଇଛି |",
    invoiceSentToEmail: "ଗ୍ରାହକଙ୍କ ଇମେଲକୁ ଇନଭଏସ୍ ପଠାଯାଇଛି |",
    invoiceSuccessWhatsappError:
      "ଇନଭଏସ୍ ସଫଳତାର ସହିତ ସୃଷ୍ଟି ହେଲା କିନ୍ତୁ ହ୍ ats ାଟସ୍ ଆପ୍କୁ ପଠାଇବାରେ ଏକ ତ୍ରୁଟି ଘଟିଗଲା | ଇନଭଏସ୍ ତାଲିକାରେ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    invoiceSuccessfulEmailError:
      "ଇନଭଏସ୍ ସଫଳତାର ସହିତ ସୃଷ୍ଟି ହେଲା କିନ୍ତୁ ଇ-ମେଲ୍ ଭାବରେ ପଠାଇବା ସମୟରେ ଏକ ତ୍ରୁଟି ଘଟିଗଲା | ଦୟାକରି ଇନଭଏସ୍ ତାଲିକାରୁ ପୁନର୍ବାର ଚେଷ୍ଟା କରନ୍ତୁ |",
    invoiceSentToWhatsapp: "ଇନଭଏସ୍ ହ୍ ats ାଟସ୍ ଆପ୍କୁ ପଠାଗଲା |",
    hello: "ନମସ୍କାର",
    pleaseDownloadInvoice: "ଦୟାକରି ଇନଭଏସ୍ ଡାଉନଲୋଡ୍ କରନ୍ତୁ |",
    pleaseDownloadReceipt: "ଦୟାକରି ରସିଦ ଡାଉନଲୋଡ୍ କରନ୍ତୁ |",
    from: "ଠାରୁ",
    email: "ଇମେଲ୍ କରନ୍ତୁ |",
    upgrade: "ନବୀକରଣ କରନ୍ତୁ |",
    youAreOnFreePlan: "ଆପଣ ଏକ ମାଗଣା ଯୋଜନାରେ ଅଛନ୍ତି |",
    clickOn: "ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
    yourPlanInFewSteps: " କିଛି ଶୀଘ୍ର ପଦକ୍ଷେପରେ ତୁମର ସଦସ୍ୟତା |",
    to: "କୁ",
    yourSubscriptionHasExpired:
      "ଆପଣଙ୍କର ସଦସ୍ୟତା ସମାପ୍ତ ହୋଇଛି ଏବଂ ଆପଣଙ୍କର ଖାତା ବର୍ତ୍ତମାନ ସୀମିତ ଅଟେ |",
    days: "ଦିନ",
    yourSubscriptionExpiresIn: "ତୁମର ଲୟଷ୍ଟାର ସଦସ୍ୟତା ସମାପ୍ତ ହେବ |",
    createAcount: "ଗୋଟିଏ ଖାତା ଖୋଲ",
    signIn: "ଚୁକ୍ତି କରିବା",
    continue: "ଜାରି ରଖ |",
    enterOtp: "OTP PIN ପ୍ରବେଶ କରନ୍ତୁ |",
    enterValidOtp: "ଦୟାକରି ଏକ ବ valid ଧ PIN ପ୍ରବେଶ କରନ୍ତୁ |",
    pin: "ପିନ୍",
    tokenSentToMail: "ଆପଣଙ୍କ ଇମେଲକୁ ଏକ ଟୋକନ୍ ପଠାଯାଇଛି |",
    passwordResetSuccessful: "ପାସୱାର୍ଡ ପୁନ et ସେଟ୍ ସଫଳ ହେଲା |",
    somethingWentWrong: "କିଛି ଭୁଲ ହୋଇ ଗଲା!",
    resetPassword: "ପାସୱାର୍ଡ ପୁନ Res ସେଟ୍ କରନ୍ତୁ |",
    iHaveResetCode: "ମୋର ଏକ ପାସୱାର୍ଡ ପୁନ res ସେଟ୍ କୋଡ୍ ଅଛି |",
    pleaseEnterEmail: "ଦୟାକରି ଆପଣଙ୍କର ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    aTokenWillBeSentToEmail: "ଆପଣଙ୍କ ଇମେଲକୁ ଏକ ଟୋକନ୍ ପଠାଯିବ |",
    enterEmail: "ଆପଣଙ୍କର ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    sendinYourToken: "ତୁମର ଟୋକନ୍ ପଠାଉଛି ...",
    makeSureItMatchesPassword:
      "ନିଶ୍ଚିତ କରନ୍ତୁ ଯେ ଏହା ଆପଣଙ୍କର ନୂତନ ପାସୱାର୍ଡ ସହିତ ମେଳ ଖାଉଛି |",
    pleaseChooseNewPassword: "ଦୟାକରି ଏକ ନୂତନ ପାସୱାର୍ଡ ବାଛନ୍ତୁ |",
    chooseNewPassword: "ଏକ ନୂତନ ପାସୱାର୍ଡ ବାଛନ୍ତୁ |",
    enterNewPassword: "ନିଶ୍ଚିତ କରିବାକୁ ଆପଣଙ୍କର ନୂତନ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterTokenSent: "ଆପଣଙ୍କର ମେଲ୍ ପଠାଯାଇଥିବା ଟୋକେନ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    resetToken: "ଟୋକେନ୍ ପୁନ Res ସେଟ୍ କରନ୍ତୁ |",
    resettingPassword: "ଆପଣଙ୍କର ପାସୱାର୍ଡ ପୁନ Res ସେଟ୍ କରୁଛି ...",
    signUp: "ସାଇନ୍ ଅପ୍ କରନ୍ତୁ",
    adminSignInWithEmail:
      " କର୍ମଚାରୀମାନେ ଚାଳକନାମ ସହିତ ସାଇନ୍ ଇନ୍ କରୁଥିବାବେଳେ ଆଡମିନି ଇମେଲ ସହିତ ସାଇନ୍ ଇନ୍ କରନ୍ତି |",
    pleaseEnterEmailOrUsername:
      "ଦୟାକରି ଆପଣଙ୍କର ଇମେଲ୍ କିମ୍ବା ଉପଯୋଗକର୍ତ୍ତା ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    emailOrUsername: "ଇମେଲ୍ କିମ୍ବା ଉପଯୋଗକର୍ତ୍ତା ନାମ |",
    pleaseEnterPassword: "ଦୟାକରି ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    createAnAccount: "ଗୋଟିଏ ଖାତା ଖୋଲ",
    forgotPassword: "ପାସୱାର୍ଡ଼ ଭୁଲି ଗଲେ କି?",
    enterPhoneNumber: "ଫୋନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    personalData: "ବ୍ୟକ୍ତିଗତ ତଥ୍ୟ",
    validateConfirmationCOde: "ନିଶ୍ଚିତକରଣ କୋଡ୍ ବ Val ଧ କରନ୍ତୁ |",
    pleaseEnterFirstName: "ଦୟାକରି ଆପଣଙ୍କର ପ୍ରଥମ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterPhoneNumber: "ଦୟାକରି ଆପଣଙ୍କର ଫୋନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterLastName: "ଦୟାକରି ଆପଣଙ୍କର ଶେଷ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterBusinessName: "ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟବସାୟ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    firstName: "ପ୍ରଥମ ନାମ",
    lastName: "ଶେଷ ନାମ",
    businessName: "ବ୍ୟବସାୟ ନାମ",
    previous: "ପୂର୍ବ",
    next: "ପରବର୍ତ୍ତୀ",
    pleaseSelectBusinessCategory: "ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟବସାୟ ବର୍ଗ ଚୟନ କରନ୍ତୁ |",
    pleaseEnterValidEmail: "ଦୟାକରି ଏକ ବ valid ଧ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterPostCode: "ଦୟାକରି ପୋଷ୍ଟ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    postCode: "ପୋଷ୍ଟ କୋଡ୍ |",
    phoneNumberInUse: "ଏହି ଫୋନ୍ ନମ୍ବର ପୂର୍ବରୁ ବ୍ୟବହାରରେ ଅଛି!",
    emailInUse: "ଏହି ଇମେଲ୍ ପୂର୍ବରୁ ବ୍ୟବହାରରେ ଅଛି!",
    foodAndDrinks: "ଖାଦ୍ୟ ଏବଂ ପାନୀୟ",
    salonAndBeauty: "ସାଲନ୍ ଏବଂ ସ Beauty ନ୍ଦର୍ଯ୍ୟ |",
    fashionAndAccessories: "ଫ୍ୟାଶନ୍ ଏବଂ ଆସେସୋରିଜ୍ |",
    gymAndFitness: "ଜିମ୍ ଏବଂ ଫିଟନେସ୍ |",
    travelAndHotel: "ଭ୍ରମଣ ଏବଂ ହୋଟେଲ |",
    homeAndGifts: "ଘର ଏବଂ ଉପହାର |",
    washingAndCleaning: "ଧୋଇବା ଏବଂ ସଫା କରିବା |",
    gadgetsAndElectronics: "ଗ୍ୟାଜେଟ୍ ଏବଂ ଇଲେକ୍ଟ୍ରୋନିକ୍ସ |",
    groceries: "ଖାଦ୍ୟ ସାମଗ୍ରୀ",
    others: "ଅନ୍ୟମାନେ |",
    submit: "ଦାଖଲ କରନ୍ତୁ |",
    accountCreatedSuccessful: "ତୁମର ଖାତା ସଫଳତାର ସହିତ ସୃଷ୍ଟି ହେଲା |",
    pleaseEnterAddress: "ଦୟାକରି ଆପଣଙ୍କର ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ |",
    addressLine1: "ଠିକଣା ରେଖା 1",
    addressLine2: "ଠିକଣା ରେଖା 2",
    choosePassword: "ପାସୱାର୍ଡ ବାଛନ୍ତୁ |",
    passwordMustBe6Characters: "ପାସୱାର୍ଡ ଅତିକମରେ 6 ଅକ୍ଷର ହେବା ଜରୁରୀ |",
    howDidYouHearLoystar: "ଆପଣ ଲୟଷ୍ଟାର ବିଷୟରେ କିପରି ଶୁଣିଲେ?",
    referralCode: "ରେଫରାଲ୍ କୋଡ୍ |",
    byClickingTheButton:
      " ନିମ୍ନରେ ଥିବା ବଟନ୍ କ୍ଲିକ୍ କରି, ଆପଣ ଏଥିରେ ରାଜି ହୁଅନ୍ତି |",
    termsAndSevice: "ସର୍ତ୍ତାବଳୀ",
    wereCreatingAccount: "ଆମେ ଆପଣଙ୍କର ଖାତା ସୃଷ୍ଟି କରୁଛୁ |",
    proceed: "ଅଗ୍ରଗତି କର |",
    verificationCodeMustBe6: "ଯାଞ୍ଚ ସଂକେତ 6 ଅଙ୍କ ହେବା ଜରୁରୀ |",
    pleaseEnter6DigitCode: "ଦୟାକରି 6 ଅଙ୍କ ବିଶିଷ୍ଟ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterVerificationCode: "ଯାଞ୍ଚ କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    resendToken: "ଟୋକେନ୍ ପଠାନ୍ତୁ |",
    verify: "ଯାଞ୍ଚ କରନ୍ତୁ |",
    transactions: "କାରବାର",
    todaySales: "ଆଜିର ବିକ୍ରୟ |",
    salesHistory: "ବିକ୍ରୟ ଇତିହାସ",
    supplyHistory: "ଯୋଗାଣ ଇତିହାସ",
    new: "ନୂତନ",
    invoices: "ଇନଭଏସ୍ |",
    disbursements: "ବଣ୍ଟନ",
    offlineSales: "ଅଫଲାଇନ୍ ବିକ୍ରୟ |",
    products: "ଉତ୍ପାଦଗୁଡିକ",
    customers: "ଗ୍ରାହକ",
    multiLevelLoyalty: "ବହୁ ସ୍ତରୀୟ ବିଶ୍ୱସ୍ତତା |",
    loyaltyPrograms: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ |",
    members: "ସଦସ୍ୟମାନେ |",
    appStore: "ଆପ୍ ଷ୍ଟୋର୍‌ |",
    orderMenu: "ମେନୁ ଅର୍ଡର କରନ୍ତୁ |",
    settings: "ସେଟିଂସମୂହ",
    staffAndBranches: "କର୍ମଚାରୀ ଏବଂ ଶାଖା |",
    myAccount: "ମୋ ଖାତା",
    switchToSellMode: "ବିକ୍ରୟ ମୋଡ୍ କୁ ସୁଇଚ୍ କରନ୍ତୁ |",
    signOut: "ବାହାରି ଯାନ୍ତୁ",
    getFreeSubscription: "ମାଗଣା ସଦସ୍ୟତା ପ୍ରାପ୍ତ କରନ୍ତୁ |",
    onlyNumbersAllowed: "କେବଳ ସଂଖ୍ୟାଗୁଡିକ ଅନୁମତିପ୍ରାପ୍ତ |",
    yourAccountMustBe10Digits:
      "ଆପଣଙ୍କର ଖାତା ନମ୍ବର ନିଶ୍ଚିତ ଭାବରେ 10 ଅଙ୍କ ବିଶିଷ୍ଟ ସଂଖ୍ୟା ହେବା ଆବଶ୍ୟକ |",
    yourBvnMustBe11:
      "ଆପଣଙ୍କର BVN ନିଶ୍ଚିତ ଭାବରେ 11 ଅଙ୍କ ବିଶିଷ୍ଟ ସଂଖ୍ୟା ହେବା ଆବଶ୍ୟକ |",
    pleaseSelectBank: "ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଚୟନ କରନ୍ତୁ |",
    selectYourBank: "ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଚୟନ କରନ୍ତୁ |",
    enterBankAccountNumber: "ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    enterBvnNumber: "ଆପଣଙ୍କର BVN ପ୍ରବେଶ କରନ୍ତୁ |",
    connectBankAccount: "ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟକୁ ସଂଯୋଗ କରନ୍ତୁ |",
    passwordResetSuccessfulAndSignOut:
      "ତୁମର ପାସୱାର୍ଡ ସଫଳତାର ସହିତ ପୁନ res ସେଟ୍ ହୋଇଛି | ପୁନର୍ବାର ସାଇନ୍ ଇନ୍ କରିବାକୁ ନିମ୍ନରେ ସାଇନ୍ ଆଉଟ୍ ବଟନ୍ ଉପରେ କ୍ଲିକ୍ କରନ୍ତୁ |",
    enterCurrentPassword: "ସାମ୍ପ୍ରତିକ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCurrentPassword: "ଦୟାକରି ସାମ୍ପ୍ରତିକ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    phoneNumber: "ଫୋନ୍ ନମ୍ବର",
    sex: "ଯ Sex ନ ସମ୍ପର୍କ |",
    dateOfBirth: "ଜନ୍ମ ତାରିଖ",
    state: "ରାଜ୍ୟ",
    country: "ଦେଶ",
    loyaltyId: "ବିଶ୍ୱସ୍ତତା ID",
    createdAt: "ସୃଷ୍ଟି ହୋଇଛି",
    noOfTransactionsMade: "କରାଯାଇଥିବା କାରବାରର ନା",
    yourDownloadWillStart: "ତୁମର ଡାଉନଲୋଡ୍ ଏକ ମୁହୂର୍ତ୍ତରେ ଆରମ୍ଭ ହେବ |",
    exportCustomers: "ଗ୍ରାହକ ରପ୍ତାନି କରନ୍ତୁ |",
    youhaveSuccessfullyToppedUp: "ତୁମେ ସଫଳତାର ସହିତ ତୁମର SMS ୟୁନିଟ୍ ଟପ୍ କରିଛ |",
    youNowHave: "ତୁମର ବର୍ତ୍ତମାନ ଅଛି |",
    smsUnits: "SMS ୟୁନିଟ୍ ଗୁଡିକ |",
    enterNumberOfSmsUnits:
      "ଆପଣ କିଣିବାକୁ ଚାହୁଁଥିବା SMS ୟୁନିଟ୍ ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterANumericValue: "ଦୟାକରି ଏକ ସାଂଖ୍ୟିକ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    pay: "ଦେୟ ଦିଅ |",
    accountEditedSuccessfully: "ଆପଣଙ୍କର ଖାତା ସଫଳତାର ସହିତ ସମ୍ପାଦିତ ହେଲା |",
    youAeCurrentlyOn: "ଆପଣ ବର୍ତ୍ତମାନ ଅଛନ୍ତି",
    plan: "ଯୋଜନା କରନ୍ତୁ |",
    userData: "ଉପଯୋଗକର୍ତ୍ତା ତଥ୍ୟ |",
    businessData: "ବ୍ୟବସାୟ ତଥ୍ୟ",
    businessCategory: "ବ୍ୟବସାୟ କ୍ୟାଟଗୋରୀ |",
    pleaseSelectCurrency: "ଦୟାକରି ଆପଣଙ୍କର ମୁଦ୍ରା ଚୟନ କରନ୍ତୁ |",
    selectYourCurrency: "ତୁମର ମୁଦ୍ରା ବାଛ |",
    purchaseMoreSmsUnits:
      "ନିମ୍ନରେ ଫର୍ମ ବ୍ୟବହାର କରି ଅଧିକ SMS ୟୁନିଟ୍ କ୍ରୟ କରନ୍ତୁ |",
    youHave: "ତୁମର ଅଛି",
    atLeast4SmsUnitsRrequired:
      "ଯାଞ୍ଚ ପାଇଁ ଅତି କମରେ 4 ଟି ଏସ୍ଏମ୍ ୟୁନିଟ୍ ଆବଶ୍ୟକ, ଦୟାକରି ଟପ୍ ଅପ୍ କରନ୍ତୁ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'ଆପଣଙ୍କର ଗ୍ରାହକଙ୍କୁ ଜାଣନ୍ତୁ' (KYC) ଆବଶ୍ୟକତା ପାଳନ କରିବାକୁ ଦୟାକରି ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ଯାଞ୍ଚ କରନ୍ତୁ | ଏହା ଆପଣଙ୍କୁ USSD କିମ୍ବା ତତକ୍ଷଣାତ୍ ସ୍ଥାନାନ୍ତର ମାଧ୍ୟମରେ ଦେୟ ସଂଗ୍ରହ କରିବାକୁ, ଗ୍ରାହକଙ୍କଠାରୁ ଅର୍ଡର ଗ୍ରହଣ କରିବାକୁ ଏବଂ କାରବାର ସମାଧାନ ପ୍ରକ୍ରିୟାକୁ ଅନୁମତି ଦେବ | କେବଳ ନାଇଜେରିଆ ବ୍ୟବସାୟୀମାନଙ୍କ ପାଇଁ ପ୍ରଯୁଜ୍ୟ | ଆରମ୍ଭ କରିବାକୁ ଦୟାକରି ନିମ୍ନରେ ଥିବା ବଟନ୍ ଦବାନ୍ତୁ |",
    reConnectBankAccount: "ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟକୁ ପୁନ connect ସଂଯୋଗ କରନ୍ତୁ |",
    accountName: "ଖାତା ନାମ",
    accountNumber: "ଖାତା ସଂଖ୍ୟା",
    bankName: "ବ୍ୟାଙ୍କ ନାମ",
    verified: "ଯାଞ୍ଚ ହୋଇଛି |",
    accountDetails: "ଖାତା ବିବରଣୀ",
    kycBankAccount: "KYC",
    createTier: "ସ୍ତର ସୃଷ୍ଟି କରନ୍ତୁ |",
    fileUploadSuccessful: "ଫାଇଲ୍ ସଫଳତାର ସହିତ ଅପଲୋଡ୍ ହେଲା |",
    fileUploadFailed: "ଫାଇଲ୍ ଅପଲୋଡ୍ ବିଫଳ ହେଲା |",
    createLoyaltyProgram: "ଏକ ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ ସୃଷ୍ଟି କରନ୍ତୁ |",
    createLoyalty: "ବିଶ୍ୱସ୍ତତା ସୃଷ୍ଟି କରନ୍ତୁ |",
    name: "ନାମ",
    loyaltyArtwork: "ବିଶ୍ୱସ୍ତ କଳା",
    clickToUpload: "ଅପଲୋଡ୍ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ |",
    amountToPointsRatio: "ପଏଣ୍ଟ ଅନୁପାତର ପରିମାଣ |",
    points: "ପଏଣ୍ଟସ୍",
    recommendedAmountToPointRatio:
      "ପରାମର୍ଶିତ: ₦ 1 ରୁ 1 ପଏଣ୍ଟ | ଯଥା ପ୍ରତ୍ୟେକ 1 ନାଇରା ଖର୍ଚ୍ଚ ପାଇଁ, ଆପଣଙ୍କର ଗ୍ରାହକମାନେ 1 ପଏଣ୍ଟ ପାଆନ୍ତି |",
    pleaseTypeIn: "ଦୟାକରି ଟାଇପ୍ କରନ୍ତୁ |",
    toDeleteLoyalty: "ଏହି ବିଶ୍ୱସ୍ତତା ବିଲୋପ କରିବାକୁ |",
    deleteLoyalty: "ବିଶ୍ୱସ୍ତତା ବିଲୋପ କରନ୍ତୁ |",
    toConfirm: "ନିଶ୍ଚିତ କରିବାକୁ",
    edit: "ସଂପାଦନା କରନ୍ତୁ |",
    pointsAwardedSuccessfully: "ପଏଣ୍ଟଗୁଡିକ ସଫଳତାର ସହିତ ପୁରସ୍କୃତ |",
    enterPointValueToAward:
      "ଆପଣ ଗ୍ରାହକଙ୍କୁ ପୁରସ୍କାର ଦେବାକୁ ଚାହୁଁଥିବା ପଏଣ୍ଟ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    award: "ପୁରସ୍କାର",
    awardPointValuesToCustomer: "ଗ୍ରାହକଙ୍କୁ ପୁରସ୍କାର ବିନ୍ଦୁ ମୂଲ୍ୟ |",
    enrollMembersToLoyalty: "ସଦସ୍ୟମାନଙ୍କୁ ବିଶ୍ୱସ୍ତତା ଉପରେ ନାମ ଲେଖାନ୍ତୁ |",
    awardPoints: "ପୁରସ୍କାର ବିନ୍ଦୁ",
    enroll: "ନାମ ଲେଖାନ୍ତୁ |",
    home: "ଘର",
    loyalty: "ନିଷ୍ଠା",
    enrollCustomers: "ଗ୍ରାହକମାନଙ୍କୁ ନାମ ଲେଖାନ୍ତୁ |",
    selected: "ମନୋନୀତ |",
    customer: "ଗ୍ରାହକ",
    loyaltyActivationSuccessful: "ବିଶ୍ୱସ୍ତତା ସକ୍ରିୟ ଅଟେ |",
    loyaltyDeactivationSuccessful: "ବିଶ୍ୱସ୍ତତା ନିଷ୍କ୍ରିୟକରଣ ସଫଳ ଅଟେ |",
    viewTier: "ସ୍ତର ଦର୍ଶନ କରନ୍ତୁ |",
    deactivate: "ଡି-ଆକ୍ଟିଭେଟ କରନ୍ତୁ |",
    activate: "ସକ୍ରିୟ କରନ୍ତୁ |",
    actions: "କାର୍ଯ୍ୟ",
    nameOfLoyalty: "ବିଶ୍ୱସ୍ତତାର ନାମ",
    loyaltyStatus: "ବିଶ୍ୱସ୍ତତା ସ୍ଥିତି ବିଶ୍ୱସ୍ତତା ସ୍ଥିତି |",
    numberOfTiers: "ସ୍ତର ସଂଖ୍ୟା",
    createdOn: "ସୃଷ୍ଟି ହୋଇଛି",
    createATier: "ଏକ ସ୍ତର ସୃଷ୍ଟି କରନ୍ତୁ |",
    stopCreatingTierConfirmation:
      "ଆପଣ ଏକ ସ୍ତର ସୃଷ୍ଟି ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    stopEditingTierConfirmation:
      "ଆପଣ ଏହି ପର୍ଯ୍ୟାୟ ସମ୍ପାଦନା ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    nameOfTier: "ସ୍ତରର ନାମ",
    minimumSpendingTarget: "ସର୍ବନିମ୍ନ ଖର୍ଚ୍ଚ ଲକ୍ଷ୍ୟ |",
    maximumSpendingTarget: "ସର୍ବାଧିକ ଖର୍ଚ୍ଚ ଲକ୍ଷ୍ୟ |",
    minimumSpendingTargetRequired: "ସର୍ବନିମ୍ନ ଖର୍ଚ୍ଚ ଲକ୍ଷ୍ୟ ଧାର୍ଯ୍ୟ କରାଯାଇଛି |",
    maximumSpendingTargetRequired: "ସର୍ବାଧିକ ଖର୍ଚ୍ଚ ଲକ୍ଷ୍ୟ ଆବଶ୍ୟକ |",
    rewardSponsor: "ପୁରସ୍କାର ପ୍ରଯୋଜକ",
    pleaseChooseARewardSponsor: "ଦୟାକରି ଏକ ପୁରସ୍କାର ପ୍ରଯୋଜକ ବାଛନ୍ତୁ |",
    self: "ଆତ୍ମ",
    partner: "ସହଭାଗୀ",
    rewardPartner: "ପୁରସ୍କାର ସହଭାଗୀ",
    pleaseSelectRewardPartner: "ଦୟାକରି ଆପଣଙ୍କର ପୁରସ୍କାର ସାଥୀ ଚୟନ କରନ୍ତୁ |",
    rewards: "ପୁରସ୍କାର",
    pleaseSelectAReward: "ଦୟାକରି ଏକ ପୁରସ୍କାର ଚୟନ କରନ୍ତୁ |",
    instructionsOnRedeemingReward:
      "ଗ୍ରାହକ କିପରି ପୁରସ୍କାର ମୁକ୍ତ କରିବେ ସେ ସମ୍ବନ୍ଧରେ ନିର୍ଦ୍ଦେଶାବଳୀ |",
    pleaseFillInThisField: "ଦୟାକରି ଏହି କ୍ଷେତ୍ରରେ ପୁରଣ କରନ୍ତୁ!",
    toDeleteThisTier: " ଏହି ପର୍ଯ୍ୟାୟ ବିଲୋପ କରିବାକୁ |",
    deleteTier: "ସ୍ତର ବିଲୋପ କରନ୍ତୁ |",
    viewMembers: "ସଦସ୍ୟମାନଙ୍କୁ ଦେଖନ୍ତୁ |",
    membersEnrolled: "ସଦସ୍ୟମାନେ ନାମଲେଖାଇଲେ |",
    instruction: "ନିର୍ଦ୍ଦେଶ",
    membersIn: "ସଦସ୍ୟମାନେ",
    availableTiersInLoyalty: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମରେ ଉପଲବ୍ଧ ସ୍ତର (ଗୁଡିକ) |",
    tiers: "ସ୍ତରଗୁଡିକ",
    totalTier: "ସମୁଦାୟ ସ୍ତର |",
    availableLoyaltyProgramme: "ଉପଲବ୍ଧ ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ |",
    totalLoyalties: "ସମୁଦାୟ ବିଶ୍ୱସ୍ତତା |",
    memberDetails: "ସଦସ୍ୟ ବିବରଣୀ",
    nameOfCustomer: "ଗ୍ରାହକଙ୍କ ନାମ",
    address: "ଠିକଣା",
    allEnrolledMembers: "ସମସ୍ତ ନାମଲେଖା ସଦସ୍ୟ |",
    thisIsToWishYouHappyBirthday:
      "ଜନ୍ମଦିନ ଏବଂ ସମୃଦ୍ଧ ଜୀବନ କାମନା କରିବା ପାଇଁ ଏହା ହେଉଛି | ତୁମେ ଲୟଷ୍ଟର ପାଇଁ ଯାହା ଅଛି ସେଥିପାଇଁ ଧନ୍ୟବାଦ | ଶୁଭ ଉତ୍ସବ!",
    inputAnOfferPlease: "ଦୟାକରି ଏକ ଅଫର୍ ଇନପୁଟ୍ କରନ୍ତୁ |",
    pleaseSelectTheInsertPoint:
      "ଦୟାକରି ବାର୍ତ୍ତାରେ ସନ୍ନିବେଶ ପଏଣ୍ଟ ଚୟନ କରନ୍ତୁ ଏବଂ ପୁନର୍ବାର କ୍ଲିକ୍ କରନ୍ତୁ |",
    birthdayOfferAndMessage: "ଜନ୍ମଦିନ ଅଫର୍ ଏବଂ ବାର୍ତ୍ତା |",
    birthdayOffer: "ଜନ୍ମଦିନ ଅଫର୍",
    birthdayMessage: "ଜନ୍ମଦିନ ବାର୍ତ୍ତା |",
    noOfferFound: "କ offer ଣସି ଅଫର ମିଳିଲା ନାହିଁ |",
    settingABirthdayOffer:
      "ଜନ୍ମଦିନର ଅଫର୍ ସେଟ୍ କରିବା ଗ୍ରାହକମାନଙ୍କୁ ସେମାନଙ୍କର ଜନ୍ମଦିନରେ SMS ମାଧ୍ୟମରେ ଏହି ଅଫର ଗ୍ରହଣ କରିବାକୁ ଅନୁମତି ଦେଇଥାଏ |",
    createOffer: "ଅଫର୍ ସୃଷ୍ଟି କରନ୍ତୁ |",
    whatIsTheOffer: "ଅଫର୍ କ’ଣ?",
    editMessage: "ବାର୍ତ୍ତା ସଂପାଦନ କରନ୍ତୁ |",
    insert: "ସନ୍ନିବେଶ କରନ୍ତୁ |",
    theNameOfCustomerInserted: "ଗ୍ରାହକଙ୍କ ନାମ ଏଠାରେ ଭର୍ତ୍ତି କରାଯିବ |",
    theBirtdayOfferInserted: "ଜନ୍ମଦିନ ଅଫର ଏଠାରେ ଭର୍ତ୍ତି କରାଯିବ |",
    youSuccessfullyAddedNewBranch: "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ ଶାଖା ଯୋଡିଛନ୍ତି!",
    addNewBranch: "ନୂତନ ଶାଖା ଯୋଡନ୍ତୁ |",
    addBranch: "ଶାଖା ଯୋଡନ୍ତୁ |",
    additionalBranchWillIncur: "ଅତିରିକ୍ତ ଶାଖା ବହନ କରିବ |",
    perBranch: "ଶାଖା ପ୍ରତି",
    ecommerceBranchCosts: "ଇକମର୍ସ ଶାଖା ଖର୍ଚ୍ଚ |",
    pleaseEnterBranchName: "ଦୟାକରି ଶାଖା ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterBranchAddress1: "ଦୟାକରି ଶାଖାର ଠିକଣା ଲାଇନ 1 ପ୍ରବେଶ କରନ୍ତୁ |",
    enterBranchAddress1: "ଶାଖାର ଠିକଣା ରେଖା 1 ପ୍ରବେଶ କରନ୍ତୁ |",
    enterBranchAddress2: "ଶାଖାର ଠିକଣା ରେଖା 1 ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterBranchAddress2: "ଦୟାକରି ଶାଖାର ଠିକଣା ରେଖା 2 ପ୍ରବେଶ କରନ୍ତୁ |",
    enterBranchName: "ଶାଖା ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    successfullyAddedStaff: "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ କର୍ମଚାରୀ ଯୋଡିଛନ୍ତି!",
    addNewStaff: "ନୂତନ କର୍ମଚାରୀ ଯୋଡନ୍ତୁ |",
    addStaff: "କର୍ମଚାରୀ ଯୋଡନ୍ତୁ |",
    additionalStaffWillIncur: "ଅତିରିକ୍ତ କର୍ମଚାରୀମାନେ ବହନ କରିବେ |",
    perStaff: "କର୍ମଚାରୀଙ୍କ ପାଇଁ",
    pleaseEnterStaffEmail: "ଦୟାକରି କର୍ମଚାରୀଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterStaffUsername:
      "ଦୟାକରି କର୍ମଚାରୀଙ୍କ ଉପଯୋଗକର୍ତ୍ତା ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterStaffPassword: "ଦୟାକରି କର୍ମଚାରୀଙ୍କ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseSelectStaffRole: "ଦୟାକରି କର୍ମଚାରୀଙ୍କ ଭୂମିକା ଚୟନ କରନ୍ତୁ |",
    selectStaffRole: "କର୍ମଚାରୀଙ୍କ ଭୂମିକା ଚୟନ କରନ୍ତୁ |",
    enterStaffEmail: "କର୍ମଚାରୀଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterStaffUsername: "କର୍ମଚାରୀଙ୍କ ଉପଯୋଗକର୍ତ୍ତା ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterStaffPassword: "କର୍ମଚାରୀଙ୍କ ପାସୱାର୍ଡ ପ୍ରବେଶ କରନ୍ତୁ |",
    spacesNotAllowedInUsername:
      "ଉପଯୋଗକର୍ତ୍ତା ନାମରେ ଖାଲି ସ୍ଥାନଗୁଡିକ ଅନୁମତିପ୍ରାପ୍ତ ନୁହେଁ |",
    admin: "ଆଡମିନି",
    pleaseSelectBusinessToAttachStaff:
      "କର୍ମଚାରୀ ସଂଲଗ୍ନ କରିବାକୁ ଦୟାକରି ବ୍ୟବସାୟ ଚୟନ କରନ୍ତୁ |",
    searchForBranchToAttachStaff: "କର୍ମଚାରୀ ସଂଲଗ୍ନ କରିବାକୁ ଶାଖା ଖୋଜ |",
    username: "ଉପଯୋଗକର୍ତ୍ତା ନାମ",
    role: "ଭୂମିକା",
    areYouSureToDeleteThis: "ଆପଣ ଏହାକୁ ବିଲୋପ କରିବାକୁ ନିଶ୍ଚିତ କି?",
    branches: "ଶାଖା",
    upgradeYourPlan: "ଆପଣଙ୍କର ଯୋଜନା ଅପଗ୍ରେଡ୍ କରନ୍ତୁ |",
    add: "ADD",
    addNew: "ନୂତନ ଯୋଡନ୍ତୁ |",
    customerWithEmailAlreadyExists:
      "ଇମେଲ୍ / ଫୋନ୍ ନମ୍ବର ସହିତ ଗ୍ରାହକ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛନ୍ତି!",
    successfullyAddedNewCustomer: "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ ଗ୍ରାହକ ଯୋଡିଛନ୍ତି!",
    addCustomer: "ଗ୍ରାହକ ଯୋଡନ୍ତୁ |",
    pleaseEnterCustomerFirstName: "ଦୟାକରି ଗ୍ରାହକଙ୍କ ପ୍ରଥମ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCustomerLastName: "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଶେଷ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCustomerPhoneNumber:
      "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଫୋନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCustomerEmail: "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCustomerAddressLine:
      "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଠିକଣା ରେଖା ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterCustomerOtherAddress:
      "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଅନ୍ୟ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseSelectCustomerGender: "ଦୟାକରି ଗ୍ରାହକଙ୍କ ଲିଙ୍ଗ ଚୟନ କରନ୍ତୁ |",
    gender: "ଲିଙ୍ଗ",
    male: "ପୁରୁଷ",
    female: "ମହିଳା",
    bank: "ବ୍ୟାଙ୍କ",
    selectBank: "ବ୍ୟାଙ୍କ ଚୟନ କରନ୍ତୁ |",
    stateOrRegionOrProvince: "ରାଜ୍ୟ / ଅଞ୍ଚଳ / ପ୍ରଦେଶ",
    customerNotes: "ଗ୍ରାହକ ଟିପ୍ପଣୀ |",
    sendSms: "SMS ପଠାନ୍ତୁ |",
    editCustomer: "ଗ୍ରାହକ ସଂପାଦନ କରନ୍ତୁ |",
    redeemReward: "ପୁରସ୍କାର ମୁକ୍ତ କରନ୍ତୁ |",
    issueLoyaltyCard: "ବିଶ୍ୱସ୍ତତା କାର୍ଡ ପ୍ରଦାନ କରନ୍ତୁ |",
    deleteCustomer: "ଗ୍ରାହକ ବିଲୋପ କରନ୍ତୁ |",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ଆପଣ ସଫଳତାର ସହିତ ଏକ ବିଶ୍ୱସ୍ତତା ସଦସ୍ୟତା ID ନ୍ୟସ୍ତ କରିଛନ୍ତି |",
    noMembershipIdAvailable:
      "କ membership ଣସି ସଦସ୍ୟତା ID ଉପଲବ୍ଧ ନାହିଁ | ଦୟାକରି hello@loystar.co ରେ ଆମ ସହିତ ଯୋଗାଯୋଗ କରନ୍ତୁ |",
    sendEmail: "ଇମେଲ୍ ପଠାନ୍ତୁ |",
    membershipPoints: "ସଦସ୍ୟତା ପଏଣ୍ଟ |",
    customerDetails: "ଗ୍ରାହକ ବିବରଣୀ",
    close: "ବନ୍ଦ",
    loyaltyBalance: "ବିଶ୍ୱସ୍ତତା ସନ୍ତୁଳନ",
    pointsBalance: "ପଏଣ୍ଟ ବାଲାନ୍ସ |",
    starBalance: "ଷ୍ଟାର୍ ବାଲାନ୍ସ |",
    requiredPoints: "ଆବଶ୍ୟକ ପଏଣ୍ଟଗୁଡିକ |",
    requiredStars: "ଆବଶ୍ୟକ ତାରାଗଣ |",
    reddemCode: "କୋଡ୍ ମୁକ୍ତ କରନ୍ତୁ |",
    toDeleteThisCustomer: "ଏହି ଗ୍ରାହକ ବିଲୋପ କରିବାକୁ |",
    customerHasBeenDeletedSuccessfully: "ଗ୍ରାହକ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି!",
    customerWithPhoneAlreadyExists:
      "ଫୋନ୍ ନମ୍ବର ଥିବା ଗ୍ରାହକ ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛନ୍ତି |",
    customerWasSuccessfullyEdited: "ଗ୍ରାହକ ସଫଳତାର ସହିତ ସମ୍ପାଦିତ ହୋଇଥିଲେ |",
    anErrorOccured: "ଏକ ତ୍ରୁଟି ଘଟିଲା |",
    phoneNumberIncludeCountry: "ଫୋନ୍ ନମ୍ବର (ଦେଶ କୋଡ୍ ଅନ୍ତର୍ଭୂକ୍ତ କରନ୍ତୁ)",
    yourFileQueuedForUpload:
      "ଆପଣଙ୍କର ଫାଇଲ୍ ଅପଲୋଡ୍ ପାଇଁ ଧାଡି ହୋଇଛି | ଦୟାକରି କିଛି ସେକେଣ୍ଡ ପରେ ପୃଷ୍ଠା ସତେଜ କରନ୍ତୁ |",
    thereWasAnErrorPerformingOperation: "ଅପରେସନ୍ କରିବାରେ ଏକ ତ୍ରୁଟି ଥିଲା!",
    pleaseSelectFile: "ଦୟାକରି ଏକ ଫାଇଲ୍ ଚୟନ କରନ୍ତୁ!",
    oopsOnlyCsvFilesAllowed:
      "ଓହୋ! କେବଳ CSV ଫାଇଲଗୁଡିକ ଅନୁମତିପ୍ରାପ୍ତ | ଦୟାକରି ଏକ .csv ଫାଇଲ୍ ଅପଲୋଡ୍ କରନ୍ତୁ |",
    fileShouldBeSmallerThan5Mb:
      "ଫାଇଲ 5MB ରୁ ଛୋଟ ହେବା ଉଚିତ | ଯଦି ଆପଣଙ୍କର ଏକ ବଡ ଫାଇଲ୍ ଅଛି, ଦୟାକରି support@loystar.co କୁ ଇମେଲ୍ କରନ୍ତୁ | ଧନ୍ୟବାଦ",
    customerFirstNameIsRequired: "ଧାଡିରେ ଗ୍ରାହକଙ୍କ ପ୍ରଥମ ନାମ ଆବଶ୍ୟକ |",
    customerPhoneNumberIsRequired: "ଧାଡିରେ ଗ୍ରାହକ ଫୋନ୍ ନମ୍ବର ଆବଶ୍ୟକ |",
    importCustomers: "ଗ୍ରାହକ ଆମଦାନି କରନ୍ତୁ |",
    upload: "ଅପଲୋଡ୍ କରନ୍ତୁ |",
    clickIconToDownloadCsv:
      "CSV ଫାଇଲ୍ ଟେମ୍ପଲେଟ୍ ଡାଉନଲୋଡ୍ କରିବାକୁ ଏହି ଆଇକନ୍ କ୍ଲିକ୍ କରନ୍ତୁ |",
    getGoogleSheetVersion: "ଗୁଗୁଲ୍ ସିଟ୍ ସଂସ୍କରଣ ପାଆନ୍ତୁ |",
    clickOrDragFileToUpload:
      "ଅପଲୋଡ୍ କରିବାକୁ ଫାଇଲ୍ କ୍ଲିକ୍ କରନ୍ତୁ କିମ୍ବା ଡ୍ରାଗ୍ କରନ୍ତୁ |",
    missingOrInvalidColumnHeader:
      "ନିଖୋଜ କିମ୍ବା ଅବ alid ଧ ସ୍ତମ୍ଭ ହେଡର୍ | ଦୟାକରି ଟେମ୍ପଲେଟ୍ ଫର୍ମାଟ୍ ଅନୁସରଣ କରନ୍ତୁ | ଧନ୍ୟବାଦ।",
    youHaveImported: "ଆପଣ ଆମଦାନି କରିଛନ୍ତି |",
    youSuccessfullyRedeemedReward:
      "ତୁମେ ତୁମର ପୁରସ୍କାରକୁ ସଫଳତାର ସହିତ ମୁକ୍ତ କରିଛ!",
    sixDigitCode: "ଛଅ ଅଙ୍କ ବିଶିଷ୍ଟ କୋଡ୍ |",
    pleaseEnterCustomerRewardCode:
      "ଦୟାକରି ଗ୍ରାହକଙ୍କ ପୁରସ୍କାର କୋଡ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterRewardCode:
      "ପୁରସ୍କାର କୋଡ୍ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ (ପୁରସ୍କାର କୋଡ୍ କେସ୍ ସମ୍ବେଦନଶୀଳ)",
    selectLoyaltyProgram: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ ଚୟନ କରନ୍ତୁ |",
    stamps: "ଷ୍ଟାମ୍ପଗୁଡିକ",
    smsUnitsLow: "SMS ୟୁନିଟ୍ କମ୍ |",
    pleaseTopUpSmsUnits: "ଦୟାକରି ଟପ୍ ଅପ୍ SMS ୟୁନିଟ୍ ଗୁଡିକ |",
    smsSuccessfullySentForDelivery: "ବିତରଣ ପାଇଁ SMS ସଫଳତାର ସହିତ ପଠାଗଲା!",
    sendSmsTo: "କୁ SMS ପଠାନ୍ତୁ |",
    allCustomers: "ସମସ୍ତ ଗ୍ରାହକ |",
    unitsAvailable: "ୟୁନିଟ୍ ଉପଲବ୍ଧ |",
    pleaseTypeInTheMessage: "ଦୟାକରି ମେସେଜ୍ ଟାଇପ୍ କରନ୍ତୁ |",
    message: "ବାର୍ତ୍ତା |",
    charactersRemaining: "ଅକ୍ଷରଗୁଡିକ ଅବଶିଷ୍ଟ |",
    avoidUseOfSpecialCharacters:
      "SMS ୟୁନିଟ୍ ସଂରକ୍ଷଣ ପାଇଁ ସ୍ୱତନ୍ତ୍ର ଅକ୍ଷର ଏବଂ ଇମୋଜି ବ୍ୟବହାରରୁ ଦୂରେଇ ରୁହନ୍ତୁ |",
    note: "ଧ୍ୟାନ ଦିଅନ୍ତୁ",
    errorFetchingCustomersMultilevelDetail:
      "ଗ୍ରାହକ ମଲ୍ଟି ଲେଭଲ୍ ସବିଶେଷ ତଥ୍ୟ ପାଇବାରେ ତ୍ରୁଟି |",
    search: "ସନ୍ଧାନ କର |",
    reset: "ପୁନ et ସେଟ୍ କରନ୍ତୁ |",
    importCustomer: "ଗ୍ରାହକ ଆମଦାନୀ କରନ୍ତୁ |",
    addNewCustomer: "ନୂତନ ଗ୍ରାହକ ଯୋଡନ୍ତୁ |",
    sendSmsBroadcast: "SMS ବ୍ରୋଡକାଷ୍ଟ ପଠାନ୍ତୁ |",
    totalCustomers: "ସମୁଦାୟ ଗ୍ରାହକ",
    disbursementDetails: "ବିତରଣ ବିବରଣୀ",
    paidBy: "ଦେୟ",
    disbursed: "ବିତରଣ",
    bankAccountName: "ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ନାମ",
    bankAccountNumber: "ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ନମ୍ବର |",
    transferInitiated: "ସ୍ଥାନାନ୍ତର ଆରମ୍ଭ",
    transferCompleted: "ସ୍ଥାନାନ୍ତର ସମାପ୍ତ ହୋଇଛି |",
    pleaseEnterAValid: "ଦୟାକରି ଏକ ବ valid ଧ ପ୍ରବେଶ କରନ୍ତୁ |",
    begin: "ଆରମ୍ଭ କର |",
    end: "ଶେଷ",
    date: "ତାରିଖ",
    paymentDate: "ଦେୟ ତାରିଖ",
    selectDisbursementDuration: "ବିତରଣ ସମୟ ଚୟନ କରନ୍ତୁ |",
    totalSettled: "ସମୁଦାୟ ସମାଧାନ ହୋଇଛି |",
    totalUnsettled: "ସମୁଦାୟ ଅସନ୍ତୁଷ୍ଟ |",
    toDeleteThisSale: "ଏହି ବିକ୍ରୟ ବିଲୋପ କରିବାକୁ |",
    draftSaleDeletedSuccessfully: "ଡ୍ରାଫ୍ଟ ବିକ୍ରୟ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି!",
    deleteSale: "ବିକ୍ରୟ ବିଲୋପ କରନ୍ତୁ |",
    pleaseTypeInYourTotal:
      "ବିଲୋପନ ନିଶ୍ଚିତ କରିବାକୁ ଦୟାକରି ଆପଣଙ୍କର ସମୁଦାୟ ଟାଇପ୍ କରନ୍ତୁ |",
    billDetails: "ବିଲ୍ ବିବରଣୀ",
    printBill: "ବିଲ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
    servedBy: "ଦ୍ୱାରା ପରିବେଷିତ |",
    total: "ସମୁଦାୟ",
    createdDate: "ସୃଷ୍ଟି ତାରିଖ |",
    createdTime: "ସୃଷ୍ଟି ସମୟ |",
    status: "ସ୍ଥିତି",
    loginSuccessful: "ଲଗଇନ୍ ସଫଳ |",
    pleaseWaitWhileWeConnectAccount:
      "ଆମେ ତୁମର ଖାତା ସଂଯୋଗ କରିବାବେଳେ ଦୟାକରି ଅପେକ୍ଷା କର |",
    connectionFailedTryAgain: "ସଂଯୋଗ ବିଫଳ ହେଲା | ଦୟାକରି ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ।",
    connectionSuccessful: "ସଂଯୋଗ ସଫଳ ହୋଇଛି |",
    viewDetails: "ବିବରଣୀ ଦେଖନ୍ତୁ |",
    enable: "ସକ୍ଷମ କରନ୍ତୁ |",
    free: "ମାଗଣା |",
    cost: "ମୂଲ୍ୟ",
    visitWebsite: "ୱେବସାଇଟ୍ ପରିଦର୍ଶନ କରନ୍ତୁ |",
    pleaseUpgradeYourPlanToPro:
      "ଏହି ଆପ୍ ସକ୍ଷମ କରିବାକୁ ଦୟାକରି ଆପଣଙ୍କର ଯୋଜନାକୁ Pro Plus କୁ ଅପଗ୍ରେଡ୍ କରନ୍ତୁ |",
    connectYourBankAccount:
      "ଦେୟ ଗ୍ରହଣ କରିବାକୁ ସକ୍ଷମ ହେବାକୁ ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟକୁ ସଂଯୋଗ କରନ୍ତୁ |",
    disable: "ଅକ୍ଷମ କରନ୍ତୁ |",
    enableApp: "ଆପ୍ ସକ୍ଷମ କରନ୍ତୁ |",
    addNewProductToInvoice: "ଇନଭଏସରେ ନୂତନ ଉତ୍ପାଦ ଯୋଡନ୍ତୁ |",
    toDeleteThisInvoice: "ଏହି ଇନଭଏସ୍ ଡିଲିଟ୍ କରିବାକୁ |",
    invoiceDeletedSuccessfully: "ଇନଭଏସ୍ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି!",
    deleteInvoice: "ଇନଭଏସ୍ ଡିଲିଟ୍ କରନ୍ତୁ |",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ଆପଣ ସଫଳତାର ସହିତ ଏକ ଇନଭଏସ୍ ପେମେଣ୍ଟ ବାର୍ତ୍ତା ଯୋଡିଛନ୍ତି!",
    pleaseEnterPaymentInstructions: "ଦୟାକରି ଦେୟ ନିର୍ଦ୍ଦେଶନାମା ପ୍ରବେଶ କରନ୍ତୁ |",
    invoiceId: "ଇନଭଏସ୍ ID |",
    paidDate: "ଦେୟ ତାରିଖ",
    reference: "ସନ୍ଦର୍ଭ",
    productAdded: "ଉତ୍ପାଦ ଯୋଗ କରାଯାଇଛି |",
    productOutOfStock: "ଉତ୍ପାଦ ଷ୍ଟକ୍ ବାହାରେ | ଦୟାକରି ପୁନ stock ଷ୍ଟକ୍ କରନ୍ତୁ |",
    totalInvoices: "ସମୁଦାୟ ସୂଚନା",
    totalPaidInvoices: "ସମୁଦାୟ ପେଡ୍ ଇନଭଏସ୍ |",
    totalUnpaidInvoices: "ସମୁଦାୟ ଅନାବଶ୍ୟକ ସୂଚନା |",
    loyaltyProgramDeleted: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ବିଲୋପ ହେଲା |",
    thisLoyaltyProgramDeletedSuccessfully:
      "ଏହି ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି |",
    thisLoyaltyProgramEditedSuccessfully:
      "ଏହି ବିଶ୍ୱସ୍ତତା କାର୍ଯ୍ୟକ୍ରମ ସଫଳତାର ସହିତ ସମ୍ପାଦିତ ହୋଇଛି |",
    loyaltyProgramAddedSuccessfully:
      "ବିଶ୍ୱସ୍ତତା କାର୍ଯ୍ୟକ୍ରମ ସଫଳତାର ସହିତ ଯୋଡା ଯାଇଛି |",
    loyaltyProgramEdited: "ବିଶ୍ୱସ୍ତତା କାର୍ଯ୍ୟକ୍ରମ ସମ୍ପାଦିତ |",
    loyaltyProgramAdded: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ଯୋଗ କରାଯାଇଛି |",
    loyaltyDetails: "ବିଶ୍ୱସ୍ତତା ବିବରଣୀ",
    doYouWantToCloseDialog: "ଆପଣ ଏହି ସଂଳାପକୁ ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    pleaseEnterLoyaltyName: "ଦୟାକରି ଆପଣଙ୍କର ବିଶ୍ୱସ୍ତତାର ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    programType: "ପ୍ରୋଗ୍ରାମ୍ ପ୍ରକାର",
    pleaseSelectProgramType: "ଦୟାକରି ଏକ ପ୍ରୋଗ୍ରାମ୍ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
    simplePoints: "ସରଳ ବିନ୍ଦୁ |",
    stampsProgram: "ଷ୍ଟାମ୍ପସ୍ ପ୍ରୋଗ୍ରାମ୍ |",
    threshold: "ଥ୍ରେସହୋଲ୍ଡ",
    pleaseEnterLoyaltyThreshold: "ଦୟାକରି ବିଶ୍ୱସ୍ତତା ସୀମା ପ୍ରବେଶ କରନ୍ତୁ |",
    reward: "ପୁରସ୍କାର",
    pleaseEnterLoyaltyReward: "ଦୟାକରି ବିଶ୍ୱସ୍ତତାର ପୁରସ୍କାର ପ୍ରବେଶ କରନ୍ତୁ |",
    totalUserPoints: "ମୋଟ ବ୍ୟବହାରକାରୀ ପଏଣ୍ଟ |",
    totalUserStamps: "ମୋଟ ୟୁଜର୍ ଷ୍ଟାମ୍ପ୍ |",
    spendingTarget: "ଖର୍ଚ୍ଚ ଲକ୍ଷ୍ୟ",
    spendingTargetHint1:
      "ଗ୍ରାହକଙ୍କୁ ପୁରସ୍କାର ପାଇବା ପାଇଁ କେତେ ଖର୍ଚ୍ଚ କରିବାକୁ ପଡିବ, ତାହା ଲକ୍ଷ୍ୟ ଧାର୍ଯ୍ୟ କରିବା | 1 ମୁଦ୍ରା ମୂଲ୍ୟ = 1 ପଏଣ୍ଟ |",
    spendingTargetHint2:
      "ଷ୍ଟାମ୍ପ୍ ଟାର୍ଗେଟ୍ ହେଉଛି ପୁରସ୍କାର ପାଇବା ପାଇଁ ଜଣେ ଗ୍ରାହକ କେତେ ଷ୍ଟାମ୍ପ୍ ସଂଗ୍ରହ କରିବେ | ଯଥା। 5",
    addNewLoyaltyProgram: "ନୂତନ ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ ଯୋଡନ୍ତୁ |",
    addLoyaltyProgram: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ୍ ଯୋଡନ୍ତୁ |",
    loyaltyProgramType: "ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ପ୍ରକାର |",
    pleaseSelectLoyaltyProgramType:
      "ଦୟାକରି ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
    nameOfProgram: "ପ୍ରୋଗ୍ରାମର ନାମ",
    pleaseEnterProgramName: "ଦୟାକରି ପ୍ରୋଗ୍ରାମ୍ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    whatIsTheReward: "ପୁରସ୍କାର କ’ଣ?",
    egNextPurchaseIsFree: "ଯଥା ପରବର୍ତ୍ତୀ କ୍ରୟ ମାଗଣା |",
    customerName: "ଗ୍ରାହକଙ୍କ ନାମ",
    guestCustomer: "ଅତିଥି ଗ୍ରାହକ |",
    orderConfirmedSuccessfully: "ଅର୍ଡର ସଫଳତାର ସହିତ ନିଶ୍ଚିତ ହୋଇଛି |",
    orderCancelledSuccessfully: "ଅର୍ଡର ସଫଳତାର ସହିତ ବାତିଲ ହୋଇଛି |",
    confirmOrder: "କ୍ରମ ନିଶ୍ଚିତ କରନ୍ତୁ |",
    cancelOrder: "ଅର୍ଡର ବାତିଲ୍ କରନ୍ତୁ |",
    allOrders: "ସମସ୍ତ ଅର୍ଡର |",
    totalOrders: "ସମୁଦାୟ ଆଦେଶ |",
    doYouWantToAcceptOrder: "ଆପଣ ଏହି ଆଦେଶ ଗ୍ରହଣ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    doYouWantToCancelOrder: "ଆପଣ ଏହି କ୍ରମକୁ ବାତିଲ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    orderDetails: "ସବିଶେଷ ବିବରଣୀ ଅର୍ଡର କରନ୍ତୁ |",
    orderCreatedAt: "ଅର୍ଡର ସୃଷ୍ଟି ହୋଇଛି |",
    supplier: "ଯୋଗାଣକାରୀ |",
    productName: "ଉତ୍ପାଦର ନାମ",
    quantity: "ପରିମାଣ",
    unitPrice: "ୟୁନିଟ୍ ମୂଲ୍ୟ",
    receivedBy: "ଦ୍ ived ାରା ପ୍ରାପ୍ତ",
    reportFrom: "ରୁ ରିପୋର୍ଟ କରନ୍ତୁ |",
    totalSupplies: "ସମୁଦାୟ ଯୋଗାଣ",
    allRightsReserved: "ସମସ୍ତ ଅଧିକାର ସଂରକ୍ଷିତ",
    toDeleteThisTransaction: "ଏହି କାରବାର ବିଲୋପ କରିବାକୁ |",
    transactionDeletedSuccessfully:
      "କାରବାର ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି | ଭଣ୍ଡାରକୁ ଷ୍ଟକ୍ ଫେରସ୍ତ କରାଯାଇଛି |",
    deleteTransaction: "କାରବାର ବିଲୋପ କରନ୍ତୁ |",
    transactionDetails: "କାରବାର ବିବରଣୀ",
    printReceipt: "ରସିଦ ମୁଦ୍ରଣ କରନ୍ତୁ |",
    channel: "ଚ୍ୟାନେଲ୍ |",
    discount: "ରିହାତି",
    profit: "ଲାଭ",
    discountedSales: "ରିହାତି ବିକ୍ରୟ |",
    errorFetchingRecord: "ରେକର୍ଡ ଆଣିବାରେ ତ୍ରୁଟି |",
    exportTransactions: "ରପ୍ତାନି କାରବାର |",
    errorFetchingSalesRecord: "ରପ୍ତାନି ପାଇଁ ବିକ୍ରୟ ରେକର୍ଡ ଆଣିବାରେ ତ୍ରୁଟି |",
    totalSellingPrice: "ମୋଟ ବିକ୍ରୟ ମୂଲ୍ୟ |",
    totalCostPrice: "ମୋଟ ମୂଲ୍ୟ ମୂଲ୍ୟ",
    appliedDiscount: "ପ୍ରୟୋଗ ରିହାତି |",
    noOfItems: "ବସ୍ତୁଗୁଡ଼ିକର ନା",
    sales: "ବିକ୍ରୟ",
    export: "ରପ୍ତାନି କରନ୍ତୁ |",
    totalProfit: "ମୋଟ ଲାଭ",
    totalBalanceInPeriod: "ଅବଧିରେ ସମୁଦାୟ ବାଲାନ୍ସ |",
    allTimeSales: "ସମସ୍ତ ସମୟ ବିକ୍ରୟ |",
    records: "ରେକର୍ଡଗୁଡିକ",
    todaysSales: "ଆଜିର ବିକ୍ରୟ |",
    transaction: "କାରବାର",
    youHaveExceededTotalNumberOfProducts:
      "ତୁମର ଯୋଜନାରେ ଅନୁମୋଦିତ ଉତ୍ପାଦଗୁଡିକର ମୋଟ ସଂଖ୍ୟାକୁ ତୁମେ ଅତିକ୍ରମ କରିଛ | ଏକ ଉଚ୍ଚ ସୀମା ଉପଭୋଗ କରିବାକୁ ଆପଣଙ୍କର ଯୋଜନାକୁ ଅପଗ୍ରେଡ୍ କରନ୍ତୁ |",
    youNeedToHaveLoyaltyProgram:
      "ଏହି ବ feature ଶିଷ୍ଟ୍ୟ ବ୍ୟବହାର କରିବାକୁ ତୁମର ଏକ ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ରହିବା ଆବଶ୍ୟକ!",
    price: "ମୂଲ୍ୟ",
    category: "ବର୍ଗ",
    stockTracking: "ଷ୍ଟକ୍ ଟ୍ରାକିଂ |",
    stockCount: "ଷ୍ଟକ୍ ଗଣନା |",
    taxed: "କର ଆଦାୟ",
    originalPrice: "ମୂଳ ମୂଲ୍ୟ",
    costPrice: "ମୂଲ୍ୟ ମୂଲ୍ୟ",
    unit: "ୟୁନିଟ୍",
    productImage: "ଉତ୍ପାଦ ପ୍ରତିଛବି |",
    taxRate: "ଟିକସ ହାର",
    taxType: "କର ପ୍ରକାର |",
    trackInventory: "ଇନଭେଣ୍ଟୋରୀ ଟ୍ରାକ୍ କରନ୍ତୁ |",
    variants: "ପ୍ରକାରଗୁଡିକ |",
    hasVariants: "ବିଭିନ୍ନ ପ୍ରକାର ଅଛି |",
    importProduct: "ଉତ୍ପାଦ ଆମଦାନୀ କରନ୍ତୁ |",
    exportProducts: "ଉତ୍ପାଦ ରପ୍ତାନି କରନ୍ତୁ |",
    addNewProduct: "ନୂତନ ଉତ୍ପାଦ ଯୋଡନ୍ତୁ |",
    viewCategory: "ବର୍ଗ ଦର୍ଶନ କରନ୍ତୁ |",
    viewSuppliers: "ଯୋଗାଣକାରୀଙ୍କୁ ଦେଖନ୍ତୁ |",
    receiveInventory: "ଭଣ୍ଡାର ଗ୍ରହଣ କରନ୍ତୁ |",
    printAllProductsTag: "ସମସ୍ତ ଉତ୍ପାଦ ଟ୍ୟାଗ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
    deleteAll: "ସମସ୍ତ ବିଲୋପ କରନ୍ତୁ |",
    totalProducts: "ସମୁଦାୟ ଉତ୍ପାଦଗୁଡିକ |",
    youveSuccessfullyAddedANewCategory:
      "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ ବର୍ଗ ଯୋଡିଛନ୍ତି |",
    addNewCategory: "ନୂତନ ବର୍ଗ ଯୋଡନ୍ତୁ |",
    addCategory: "ବର୍ଗ ଯୋଡନ୍ତୁ |",
    categoryName: "ବର୍ଗ ନାମ",
    pleaseEnterCategoryName: "ଦୟାକରି ବର୍ଗ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    stampsTarget: "ଷ୍ଟାମ୍ପ୍ ଟାର୍ଗେଟ୍ |",
    sendInventory: "ଭଣ୍ଡାର ପଠାନ୍ତୁ |",
    productDetails: "ଉତ୍ପାଦ ବିବରଣୀ",
    youveSuccessfullyEditedThisCategory:
      "ଆପଣ ସଫଳତାର ସହିତ ଏହି ବର୍ଗକୁ ସମ୍ପାଦନା କରିଛନ୍ତି |",
    update: "ଅଦ୍ୟତନ କରନ୍ତୁ |",
    categoryList: "ବର୍ଗ ତାଲିକା",
    categories: "ବର୍ଗଗୁଡିକ",
    enterQuantityToUpdate: "ଅଦ୍ୟତନ କରିବାକୁ ଏକ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    inventorySentSuccessfully: "ତାଲିକା ସଫଳତାର ସହିତ ପଠାଗଲା!",
    updateInventory: "ଭଣ୍ଡାର ଅଦ୍ୟତନ କରନ୍ତୁ |",
    currentQuantity: "ସାମ୍ପ୍ରତିକ ପରିମାଣ",
    pleaseEnterQuantityToAdd:
      "ଆପଣ ଯୋଗ କରିବାକୁ ଚାହୁଁଥିବା ପରିମାଣ ଦୟାକରି ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseSelectABranch: "ଦୟାକରି ଏକ ଶାଖା ଚୟନ କରନ୍ତୁ |",
    searchForBranch: "ଶାଖା ପାଇଁ ଖୋଜ |",
    youCantSendMoreThanStock: "ତୁମର ଷ୍ଟକ୍ରେ ଥିବା ଠାରୁ ଅଧିକ ପଠାଇ ପାରିବ ନାହିଁ |",
    send: "ପଠାନ୍ତୁ |",
    pleaseEnterQuantityToSend:
      "ଆପଣ ପଠାଇବାକୁ ଚାହୁଁଥିବା ପରିମାଣ ଦୟାକରି ପ୍ରବେଶ କରନ୍ତୁ |",
    productNameIsRequiredOnRow: "ଧାଡିରେ ଉତ୍ପାଦ ନାମ ଆବଶ୍ୟକ |",
    productCategoryIsRequiredOnRow: "ଧାଡିରେ ଉତ୍ପାଦ ବର୍ଗ ଆବଶ୍ୟକ |",
    productPriceIsRequiredOnRow: "ଧାଡିରେ ଉତ୍ପାଦ ମୂଲ୍ୟ ଆବଶ୍ୟକ |",
    productUnitIsRequiredOnRow: "ଧାଡିରେ ଉତ୍ପାଦ ୟୁନିଟ୍ ଆବଶ୍ୟକ |",
    productQuantityIsRequiredOnRow: "ଧାଡିରେ ଉତ୍ପାଦ ପରିମାଣ ଆବଶ୍ୟକ |",
    productVariantsRequireTracking: "ଉତ୍ପାଦ ପ୍ରକାରଗୁଡିକ ଟ୍ରାକିଂ ଆବଶ୍ୟକ କରେ!",
    pleaseAddVariantClickButton:
      "ଆଡ୍ ବଟନ୍ କ୍ଲିକ୍ କରି ଦୟାକରି ଏକ ପ୍ରକାର ଯୋଡନ୍ତୁ!",
    totalVariantsMoreThanSelectedQuantity:
      "ସମୁଦାୟ ଉତ୍ପାଦଗୁଡିକ ମନୋନୀତ ଉତ୍ପାଦ ପରିମାଣଠାରୁ ଅଧିକ, Pls ପ୍ରକାର ପରିମାଣକୁ ହ୍ରାସ କରେ |",
    productEditedSuccessfully: "ଉତ୍ପାଦ ସଫଳତାର ସହିତ ସମ୍ପାଦିତ ହୋଇଛି!",
    fileTooLargeLessThan4Mb:
      "ଫାଇଲ୍ ଆକାର ବହୁତ ବଡ! ଫାଇଲ୍ ଆକାର 4MB ରୁ କମ୍ ହେବା ଉଚିତ୍ |",
    suchVariantAlreadyExist: "ଏହିପରି ପ୍ରକାର ପୂର୍ବରୁ ବିଦ୍ୟମାନ ଅଛି |",
    addVariants: "ଭାରିଏଣ୍ଟସ୍ ଯୋଡନ୍ତୁ |",
    editProduct: "ଉତ୍ପାଦ ସଂପାଦନ କରନ୍ତୁ |",
    pleaseEnterProductName: "ଦୟାକରି ଉତ୍ପାଦ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterProductPrice: "ଦୟାକରି ଉତ୍ପାଦ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterProductOriginalPrice: "ଦୟାକରି ଉତ୍ପାଦର ମୂଳ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    productDescription: "ଉତ୍ପାଦ ବର୍ଣ୍ଣନା",
    selectProductCategory: "ଉତ୍ପାଦ ବର୍ଗ ଚୟନ କରନ୍ତୁ |",
    pleaseSelectProductCategory: "ଦୟାକରି ଉତ୍ପାଦ ବର୍ଗ ଚୟନ କରନ୍ତୁ |",
    productCostPrice: "ଉତ୍ପାଦ ମୂଲ୍ୟ ମୂଲ୍ୟ",
    productSellingPrice: "ଉତ୍ପାଦ ବିକ୍ରୟ ମୂଲ୍ୟ",
    productOriginalPrice: "ଉତ୍ପାଦର ମୂଳ ମୂଲ୍ୟ |",
    maxFileSizeAllowedIs4Mb: "ସର୍ବାଧିକ ଅନୁମୋଦିତ ଫାଇଲ୍ ଆକାର ହେଉଛି 4mb |",
    productsWithPicturesArePublished:
      "ଚିତ୍ର ସହିତ ଉତ୍ପାଦଗୁଡିକ ଅର୍ଡର ପାଇବା ପାଇଁ ଆବିଷ୍କାରରେ ପ୍ରକାଶିତ |",
    shouldThisProductBeTracked: "ଏହି ଉତ୍ପାଦକୁ ଟ୍ରାକ କରାଯିବା ଉଚିତ କି?",
    pleaseSelectStockUnit: "ଦୟାକରି ଷ୍ଟକ୍ ୟୁନିଟ୍ ଚୟନ କରନ୍ତୁ |",
    stockUnit: "ଷ୍ଟକ୍ ୟୁନିଟ୍ |",
    bag: "BAG",
    bottle: "ବୋତଲ",
    bunch: "BUNCH",
    can: "CAN",
    carton: "କାର୍ଟନ୍ |",
    crate: "CRATE",
    cup: "CUP",
    dozen: "ଡୋଜେନ୍",
    gigabytes: "ଗିଗାବାଇଟସ୍ |",
    gram: "GRAM",
    kilogram: "କିଲୋଗ୍ରାମ୍ |",
    litre: "ଲିଟର",
    pack: "ପ୍ୟାକ୍",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "ଥାଳି",
    tonne: "ଟନ୍ (MT)",
    uNIT: "ୟୁନିଟ୍",
    yard: "YARD",
    pleaseEnterProductQuantity: "ଦୟାକରି ଉତ୍ପାଦ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    productQuantity: "ଉତ୍ପାଦ ପରିମାଣ |",
    isThisProductTaxed: "ଏହି ଉତ୍ପାଦକୁ ଟ୍ୟାକ୍ସ ଦିଆଯାଇଛି କି?",
    selectTaxType: "ଟ୍ୟାକ୍ସ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
    pleaseSelectTaxType: "ଦୟାକରି ଟ୍ୟାକ୍ସ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
    progressive: "ପ୍ରଗତିଶୀଳ |",
    proportional: "ଆନୁପାତିକ |",
    pleaseEnterProductTaxRate: "ଦୟାକରି ଉତ୍ପାଦ ଟିକସ ହାର ପ୍ରବେଶ କରନ୍ତୁ |",
    doesProductHaveVariants: "ଏହି ଉତ୍ପାଦର ପ୍ରକାର ଅଛି କି?",
    type: "ଟାଇପ୍ କରନ୍ତୁ |",
    value: "ମୂଲ୍ୟ",
    pleaseEnterVariantType: "ଦୟାକରି ପ୍ରକାରର ପ୍ରକାର ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterVariantValue: "ଦୟାକରି ଭାରିଆଣ୍ଟର ଭାଲ୍ୟୁ ଏଣ୍ଟର୍ କରନ୍ତୁ |",
    pleaseEnterVariantPrice: "ଦୟାକରି ପ୍ରକାରର ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterVariantQuantity: "ଦୟାକରି ପ୍ରକାରର ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    productDeletedSuccessfully: "ଉତ୍ପାଦ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି!",
    categoryDeletedSuccessfully: "ବର୍ଗ ସଫଳତାର ସହିତ ବିଲୋପ ହୋଇଯାଇଛି!",
    toDeleteThisProduct: "ଏହି ଉତ୍ପାଦ ବିଲୋପ କରିବାକୁ |",
    invalidProductQuantity: "ଅବ val ଧ ଉତ୍ପାଦ ପରିମାଣ |",
    quantityAddedIsOverStock:
      "ତୁମେ ଯୋଗ କରୁଥିବା ପରିମାଣ ତୁମର ଷ୍ଟକ୍ରେ ଥିବା ବିଷୟ ଉପରେ |",
    itemInventoryNotTracked: "ଆଇଟମ୍ ଭଣ୍ଡାର ଟ୍ରାକ୍ ହୋଇନାହିଁ |",
    addBulkQuantity: "ବଲ୍କ ପରିମାଣ ଯୋଡନ୍ତୁ |",
    enterBulkQuantity: "ବଲ୍କ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterBulkQuantity: "ଦୟାକରି ବଲ୍କ ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    youveSuccessfullyAddedANewProduct:
      "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ ଉତ୍ପାଦ ଯୋଡିଛନ୍ତି!",
    pleaseEnterProductSellingPrice:
      "ଦୟାକରି ଉତ୍ପାଦ ବିକ୍ରୟ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    doYouWantToTrackProductStock:
      "ଆପଣ ଉତ୍ପାଦ ଷ୍ଟକ୍ ଟ୍ରାକ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    sellingPrice: "ବିକ୍ରୟ ମୂଲ୍ୟ",
    setProductExpiryReminder: "ଉତ୍ପାଦ ସମାପ୍ତି ସ୍ମାରକ ସେଟ୍ କରନ୍ତୁ |",
    productExpiryDate: "ଉତ୍ପାଦର ସମାପ୍ତି ତାରିଖ |",
    startRemindingFrom: "ଠାରୁ ସ୍ମରଣ କରିବା ଆରମ୍ଭ କରନ୍ତୁ |",
    productSuppliesAddedSuccessfully:
      "ଆପଣ ସଫଳତାର ସହିତ ଉତ୍ପାଦ ଯୋଗାଣ ଯୋଡିଛନ୍ତି |",
    addProductSupplies: "ଉତ୍ପାଦ ଯୋଗାଣ ଯୋଗକର |",
    pleaseSelectSupplier: "ଦୟାକରି ଯୋଗାଣକାରୀ ଚୟନ କରନ୍ତୁ |",
    nameOfProduct: "ଉତ୍ପାଦର ନାମ",
    pleaseSelectProduct: "ଦୟାକରି ଉତ୍ପାଦ ଚୟନ କରନ୍ତୁ |",
    productVariant: "ଉତ୍ପାଦ ପ୍ରକାର",
    pleaseSelectAVariant: "ଦୟାକରି ଏକ ପ୍ରକାର ଚୟନ କରନ୍ତୁ |",
    pleaseEnterUnitPrice: "ଦୟାକରି ୟୁନିଟ୍ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    businessBranch: "ବ୍ୟବସାୟ ଶାଖା",
    pleaseSelectBranch: "ଦୟାକରି ଶାଖା ଚୟନ କରନ୍ତୁ |",
    youveSuccessfullyAddedANewSupplier:
      "ଆପଣ ସଫଳତାର ସହିତ ଏକ ନୂତନ ଯୋଗାଣକାରୀ ଯୋଡିଛନ୍ତି |",
    addSupplier: "ଯୋଗାଣକାରୀ ଯୋଡନ୍ତୁ |",
    pleaseEnterSupplierEmail: "ଦୟାକରି ଯୋଗାଣକାରୀଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseAddADescription: "ଦୟାକରି ଏକ ବର୍ଣ୍ଣନା ଯୋଡନ୍ତୁ |",
    anErrorOccuredProductsDeleted:
      "ଅପରେସନ୍ କରିବା ସମୟରେ ଏକ ତ୍ରୁଟି ଘଟିଗଲା | ଦୟାକରି ଧ୍ୟାନ ଦିଅନ୍ତୁ, ଏହି ପ୍ରକ୍ରିୟାରେ କିଛି ଉତ୍ପାଦ ବିଲୋପ ହୋଇଥାଇପାରେ |",
    bulkDelete: "ବଲ୍କ ଡିଲିଟ୍ |",
    youAreAboutToDelete: "ଆପଣ ବିଲୋପ କରିବାକୁ ଯାଉଛନ୍ତି |",
    product: "ଉତ୍ପାଦ",
    isDueToRedeem: "ମୁକ୍ତି ହେତୁ",
    aReward: "ପୁରସ୍କାର",
    pleaseSelectCustomerToReeemReward:
      "ପୁରସ୍କାର ମୁକ୍ତ କରିବାକୁ ଦୟାକରି ଏକ ଗ୍ରାହକ ଚୟନ କରନ୍ତୁ |",
    youHaveNoLoyaltyProgramRunning:
      "ଆପଣଙ୍କର କ active ଣସି ସକ୍ରିୟ ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମ ଚାଲୁ ନାହିଁ |",
    customerHhasNoPointsInLoyaltyProgram:
      "ଏହି ବିଶ୍ୱସ୍ତତା ପ୍ରୋଗ୍ରାମରେ ଗ୍ରାହକଙ୍କ କ points ଣସି ପଏଣ୍ଟ ନାହିଁ |",
    proceedWithPayment: "ଦେୟ ସହିତ ଅଗ୍ରଗତି କରନ୍ତୁ?",
    youAreAboutToPayForTransactionUsingPoints:
      "ଆପଣ ପଏଣ୍ଟ ବ୍ୟବହାର କରି କାରବାର ପାଇଁ ଦେୟ ଦେବାକୁ ଯାଉଛନ୍ତି |",
    customerHas: "ଗ୍ରାହକ ଅଛନ୍ତି |",
    worth: "ମୂଲ୍ୟ",
    andIsNotSufficientToPayForTransaction:
      "ଏବଂ ଏହି କାରବାର ପାଇଁ ଦେୟ ଯଥେଷ୍ଟ ନୁହେଁ | ସେମାନେ ଅନ୍ୟ ଦେୟ ପଦ୍ଧତି ବ୍ୟବହାର କରି ବାଲାନ୍ସ ଯୋଡିବାକୁ ଚାହାଁନ୍ତି କି?",
    addCustomerLoyalty: "ଗ୍ରାହକ ବିଶ୍ୱସ୍ତତା ଯୋଡନ୍ତୁ |",
    pleaseAddCustomerFirst:
      "ଦୟାକରି ପ୍ରଥମେ ଏକ ଗ୍ରାହକ ଯୋଡନ୍ତୁ କିମ୍ବା ଚୟନ କରନ୍ତୁ |",
    pleaseWaitWhileWeGetReady: "ଆମେ ପ୍ରସ୍ତୁତ ହେବାବେଳେ ଦୟାକରି ଅପେକ୍ଷା କରନ୍ତୁ |",
    lowStock: "କମ୍ ଷ୍ଟକ୍ |",
    pleaseEnterAmountTendered: "ଦୟାକରି ଟେଣ୍ଡର ହୋଇଥିବା ରାଶି ପ୍ରବେଶ କରନ୍ତୁ |",
    areYouSureToBookSaleOffline:
      "ଆପଣ ନିଶ୍ଚିତ କି ଆପଣ ଏହି ବିକ୍ରିକୁ ଅଫଲାଇନରେ ବୁକ୍ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    saleWouldBeBookedAutomatically:
      "ଯେତେବେଳେ ତୁମେ ତୁମର ଇଣ୍ଟରନେଟ୍ ଟର୍ନ୍ ଅନ୍ କର, ବିକ୍ରୟ ସ୍ୱୟଂଚାଳିତ ଭାବରେ ବୁକ୍ ହେବ |",
    offlineSalesBookingCancelled: "ଅଫଲାଇନ୍ ବିକ୍ରୟ ବୁକିଂ ବାତିଲ ହୋଇଛି |",
    covid19Message:
      "COVID19: ବିସ୍ତାରକୁ ବନ୍ଦ କରିବା ପାଇଁ ସାବୁନ୍ କିମ୍ବା ସାନିଟାଇଜ୍ ସହିତ ହାତ ଧୋଇ ଦିଅନ୍ତୁ | ସର୍ବଦା ନିରାପଦରେ ରୁହନ୍ତୁ |",
    saleSuccessfullyRecorded: "ବିକ୍ରୟ ସଫଳତାର ସହିତ ରେକର୍ଡ ହୋଇଛି!",
    sendReceiptToEmail: "ଇମେଲକୁ ରସିଦ ପଠାନ୍ତୁ |",
    sendThankYouSms: "ଧନ୍ୟବାଦ SMS ପଠାନ୍ତୁ |",
    sendShippingDetails: "ପଠାଇବା ବିବରଣୀ ପଠାନ୍ତୁ |",
    addLoyalty: "ବିଶ୍ୱସ୍ତତା ଯୋଡନ୍ତୁ |",
    searchCustomerNameOrNumber: "ଗ୍ରାହକଙ୍କ ନାମ କିମ୍ବା ନମ୍ବର ଖୋଜ |",
    clickTheSearchCustomerBox:
      "ସନ୍ଧାନ ଗ୍ରାହକ ବାକ୍ସ ଏବଂ ସ୍କାନ୍ କାର୍ଡ କ୍ଲିକ୍ କରନ୍ତୁ |",
    enterProductPrice: "ଉତ୍ପାଦ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    enterPriceFor: "ପାଇଁ ମୂଲ୍ୟ ପ୍ରବେଶ କରନ୍ତୁ |",
    searchForProduct: "ଉତ୍ପାଦ ପାଇଁ ସନ୍ଧାନ କର |",
    all: "ସମସ୍ତ",
    backToDashboard: "ଡ୍ୟାସବୋର୍ଡକୁ ଫେରନ୍ତୁ |",
    viewDraftSales: "ଡ୍ରାଫ୍ଟ ବିକ୍ରୟ ଦେଖନ୍ତୁ |",
    variantSelected: "ପ୍ରକାର ଚୟନ କରାଯାଇଛି |",
    variant: "ପ୍ରକାର",
    thePreviousVariantSelectionNotAvailable:
      "ମୂଲ୍ୟ ଉପରେ ଆଧାର କରି ମନୋନୀତ ନୂତନ ପ୍ରକାର ପାଇଁ ପୂର୍ବ ପ୍ରକାର ଚୟନ ଉପଲବ୍ଧ ନୁହେଁ, ଦୟାକରି ଆପଣଙ୍କର ଚୟନ ପରିବର୍ତ୍ତନ କରନ୍ତୁ |",
    pleaseSupplyPositiveQuantityNumber:
      "ଦୟାକରି ଏକ ପୋଷ୍ଟିଭ୍ ପରିମାଣ ସଂଖ୍ୟା ଯୋଗାନ୍ତୁ |",
    lowStockFor: "ପାଇଁ କମ୍ ଷ୍ଟକ୍ |",
    clearVariants: "ପ୍ରକାରଗୁଡିକ ସଫା କରନ୍ତୁ |",
    pleaseEnterQuantity: "ଦୟାକରି ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    picture: "ଛବି",
    redemptionToken: "ମୁକ୍ତି ଟୋକେନ୍ |",
    token: "ଟୋକେନ୍ |",
    totalSpent: "ସମୁଦାୟ ଖର୍ଚ୍ଚ",
    confirmPayment: "ଦେୟ ନିଶ୍ଚିତ କରନ୍ତୁ |",
    hasPaymentBeenMade: "ଦେୟ ସଫଳତାର ସହିତ ପ୍ରକ୍ରିୟାକରଣ ହୋଇଛି କି?",
    enterTransactionReference: "ଆପଣ ଦେଇଥିବା କାରବାର ରେଫରେନ୍ସ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseSelectACustomer: "ଦୟାକରି ଏକ ଗ୍ରାହକ ଚୟନ କରନ୍ତୁ!",
    areYouSureToApplyDiscount: "ଆପଣ ରିହାତି ଲାଗୁ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    addYourBankAccountToEnableUssd:
      "UPay ଦ୍ୱାରା ତତକ୍ଷଣାତ୍ USSD ସ୍ଥାନାନ୍ତରଣକୁ ସକ୍ଷମ କରିବାକୁ ଆପଣଙ୍କର ବ୍ୟାଙ୍କ ଆକାଉଣ୍ଟ ଯୋଡନ୍ତୁ |",
    totalAmountToPay: "ଦେୟ ଦେବାକୁ ସମୁଦାୟ ରାଶି |",
    doYouWantToCancelTransaction:
      "ଆପଣ ଏହି କାରବାରକୁ ବାତିଲ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    savePrintBill: "ସେଭ୍ / ପ୍ରିଣ୍ଟ୍ ବିଲ୍ |",
    enterAmountCollectedForCustomer:
      "ଗ୍ରାହକଙ୍କ ପାଇଁ ସଂଗୃହିତ ରାଶି ପ୍ରବେଶ କରନ୍ତୁ |",
    recordSale: "ରେକର୍ଡ ବିକ୍ରୟ |",
    checkOutWith: "ସହିତ ଯାଞ୍ଚ କରନ୍ତୁ |",
    instantTransfer: "ତତକ୍ଷଣାତ୍ ସ୍ଥାନାନ୍ତର |",
    dialTheUSSDCode: "USSD କୋଡ୍ ଡାଏଲ୍ କରନ୍ତୁ |",
    pleaseSelectABank: "ଦୟାକରି ଏକ ବ୍ୟାଙ୍କ ଚୟନ କରନ୍ତୁ |",
    payWithUSSD: "USSD ସହିତ ଦେୟ |",
    sendBillTo: " - ବିଲ୍ କୁ ପଠାନ୍ତୁ |",
    waitingForUSSDTransfer: "USSD ସ୍ଥାନାନ୍ତର ପାଇଁ ଅପେକ୍ଷା |",
    percent: "ଶତକଡା",
    applyDiscount: "ରିହାତି ପ୍ରୟୋଗ କରନ୍ତୁ |",
    thisBillHasBeenSaved: "ଏହି ବିଲ୍ ସଞ୍ଚୟ ହୋଇଛି |",
    saveDraft: "ଡ୍ରାଫ୍ଟ ସଞ୍ଚୟ କରନ୍ତୁ |",
    pleaseTypeANameToIdentifyCustomer:
      "ଗ୍ରାହକଙ୍କୁ ଚିହ୍ନିବା ପାଇଁ ଦୟାକରି ଏକ ନାମ ଟାଇପ୍ କରନ୍ତୁ |",
    paymentDetails: "ଦେୟ ବିବରଣୀ",
    basePrice: "ମୂଳ ମୂଲ୍ୟ",
    staff: "କର୍ମଚାରୀ",
    subTotal: "ସବଟୋଟାଲ୍ |",
    durationMonths: "ଅବଧି (ମାସ)",
    selectADuration: "ଏକ ଅବଧି ଚୟନ କରନ୍ତୁ |",
    oneMonth: "1 ମାସ",
    twoMonths: "2 ମାସ |",
    threeMonths: "3 ମାସ",
    sixMonths: "6 ମାସ",
    twelveMonths: "12 ମାସ",
    eighteenMonths: "18 ମାସ",
    twentyFourMonths: "24 ମାସ",
    twoMonthsFree: "(2 ମାସ ମାଗଣା)",
    threeMonthsFree: "(3 ମାସ ମାଗଣା)",
    fiveMonthsFree: "(5 ମାସ ମାଗଣା)",
    yourAccountHasBeen: "ତୁମର ଖାତା ହୋଇଛି |",
    renewed: "ନବୀକରଣ",
    upgraded: "ନବୀକରଣ",
    successfully: "ସଫଳତାର ସହିତ |",
    yourSubscription: "ତୁମର ସଦସ୍ୟତା",
    youAreCurrentlyEnrolledOnA: "ଆପଣ ବର୍ତ୍ତମାନ a ରେ ନାମ ଲେଖାଇଛନ୍ତି |",
    pleaseChooseAPaymentOption: "ଦୟାକରି ଏକ ଦେୟ ବିକଳ୍ପ ବାଛନ୍ତୁ |",
    planRenewal: "ନବୀକରଣ ଯୋଜନା |",
    planUpgrade: "ଯୋଜନା ଅପଗ୍ରେଡ୍ |",
    pleaseSelectDurationToPayFor:
      "ଦୟାକରି ସମୟ ଦେବାକୁ ଚୟନ କରନ୍ତୁ ଯାହା ପାଇଁ ଆପଣ ଦେବାକୁ ଚାହୁଁଛନ୍ତି |",
    staffAccounts: "କର୍ମଚାରୀ ଖାତା",
    ecommerce: "ଇ-କମର୍ସ",
    pleaseSelectAPlan: "ଦୟାକରି ଏକ ଯୋଜନା ଚୟନ କରନ୍ତୁ |",
    includeAddons: "ଆଡ୍-ଅନ୍ ଅନ୍ତର୍ଭୂକ୍ତ କରନ୍ତୁ |",
    viewTransactions: "କାରବାରଗୁଡିକ ଦେଖନ୍ତୁ |",
    customerHasNoCompletedTansactions:
      "ଗ୍ରାହକଙ୍କ ଏପର୍ଯ୍ୟନ୍ତ କ completed ଣସି ସଂପୂର୍ଣ୍ଣ ଟ୍ୟାଙ୍କସନ୍ ନାହିଁ |",
    branch: "ଶାଖା",
    enterNumberOfEcommerceBranches: "ଇ-କମର୍ସ ଶାଖା ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    enterNumberOfEcommerceBranchesToPay:
      "ଆପଣ ଦେୟ ଦେବାକୁ ଇଚ୍ଛା କରୁଥିବା ଇ-କମର୍ସ ଶାଖା ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    ecommerceIntegration: "ଇ-କମର୍ସ ଏକୀକରଣ |",
    enterNumberOfBranches: "ଶାଖା ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    enterNumberOfAdditionalBranchesToPay:
      "ଆପଣ ଦେବାକୁ ଚାହୁଁଥିବା ଅତିରିକ୍ତ ଶାଖା ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    enterNumberOfStaffs: "କର୍ମଚାରୀଙ୍କ ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    enterNumberOfStaffsToPayFor:
      "ଆପଣ ଦେୟ ଦେବାକୁ ଇଚ୍ଛା କରୁଥିବା କର୍ମଚାରୀଙ୍କ ସଂଖ୍ୟା ପ୍ରବେଶ କରନ୍ତୁ |",
    discountApplies: "ରିହାତି ପ୍ରଯୁଜ୍ୟ |",
    starsOnThe: "ଉପରେ ତାରାଗଣ |",
    offer: "ଅଫର୍",
    get: "ପାଅ |",
    moreStarsToRedeem: "ମୁକ୍ତ କରିବାକୁ ଅଧିକ ତାରା |",
    taxVat: "କର (ଭ୍ୟାଟ୍)",
    callCashierToCompletePayment:
      "ସମ୍ପୂର୍ଣ୍ଣ ଦେୟ ପାଇଁ କ୍ୟାସିଅର୍ କୁ କଲ୍ କରନ୍ତୁ |",
    receipt: "ରସିଦ",
    dear: "ପ୍ରିୟ",
    thankYouForYourOrderFindGoods:
      "ତୁମର ଅର୍ଡର ପାଇଁ ଧନ୍ୟବାଦ | ସହମତି ଅନୁଯାୟୀ ଦୟାକରି ନିମ୍ନଲିଖିତ ଦ୍ରବ୍ୟ ଯୋଗାଣ କରନ୍ତୁ |",
    shippingNote: "ପଠାଇବା ଟିପ୍ପଣୀ |",
    enterShippingNote: "ସିପିଂ ନୋଟ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    shippingAddress: "ପହଞ୍ଚାଇବା ସ୍ଥାନ",
    enterShippingAddress: "ସିପିଂ ଠିକଣା ପ୍ରବେଶ କରନ୍ତୁ |",
    wellDoneYouAreDueToRedeem: "ବହୁତ ବଢିଆ! ତୁମେ ମୁକ୍ତି ହେବାର ଅଛି |",
    toGetYourRewardNextVisit:
      "ତୁମର ପରବର୍ତ୍ତୀ ଭ୍ରମଣରେ ତୁମର ପୁରସ୍କାର ପାଇବାକୁ | ଧନ୍ୟବାଦ",
    pointsOnThe: "ଉପରେ ପଏଣ୍ଟସ୍",
    morePointsToRedeem: "ମୁକ୍ତ କରିବାକୁ ଅଧିକ ପଏଣ୍ଟ |",
    showCode: "କୋଡ୍ ଦେଖାନ୍ତୁ |",
    toGetYourRewardOnNextVisit:
      "ତୁମର ପରବର୍ତ୍ତୀ ଭ୍ରମଣରେ ତୁମର ପୁରସ୍କାର ପାଇବାକୁ | ଧନ୍ୟବାଦ",
    earn: "ରୋଜଗାର କର |",
    delivaryNote: "ବିତରଣ ଟିପ୍ପଣୀ |",
    draftSales: "ଡ୍ରାଫ୍ଟ ବିକ୍ରୟ |",
    startDate: "ପ୍ରାରମ୍ଭ ତାରିଖ",
    endDate: "ଶେଷ ତାରିଖ",
    orders: "ଆଦେଶ",
    checkout: "ଚେକଆଉଟ୍",
    noProductItem: "କ Product ଣସି ଉତ୍ପାଦ ଆଇଟମ୍ ନାହିଁ |",
    selectProductImage: "ଉତ୍ପାଦ ପ୍ରତିଛବି ଚୟନ କରନ୍ତୁ |",
    selectCountry: "ଦେଶ ଚୟନ କରନ୍ତୁ |",
    selectRegion: "ରାଜ୍ୟ / ଅଞ୍ଚଳ ଚୟନ କରନ୍ତୁ |",
    printProductTag: "ପ୍ରଡକ୍ଟ ଟ୍ୟାଗ୍ ପ୍ରିଣ୍ଟ୍ କରନ୍ତୁ |",
    transactionReference: "କାରବାର ସନ୍ଦର୍ଭ",
    Cashier: "କ୍ୟାସିଅର୍ |",
    Manager: "ପରିଚାଳକ",
    Owner: "ମାଲିକ",
    Admin: "ଆଡମିନି",
    addPartners: "ସହଭାଗୀମାନଙ୍କୁ ଯୋଡନ୍ତୁ |",
    addNewLoyaltyPartner: "ନୂତନ ବିଶ୍ୱସ୍ତତା ସହଭାଗୀ ଯୋଡନ୍ତୁ |",
    pleaseEnterCompanyName: "ଦୟାକରି କମ୍ପାନୀ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    companyName: "କମ୍ପାନି ନାଁ",
    pleaseEnterCompanyRepName: "ଦୟାକରି କମ୍ପାନୀ ପ୍ରତିନିଧୀ ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    companyRepName: "କମ୍ପାନୀ ପ୍ରତିନିଧୀଙ୍କ ନାମ |",
    pleaseEnterCompanyRepEmail:
      "ଦୟାକରି କମ୍ପାନୀ ପ୍ରତିନିଧୀଙ୍କ ଇମେଲ୍ ପ୍ରବେଶ କରନ୍ତୁ |",
    companyRepEmail: "କମ୍ପାନୀ ପ୍ରତିନିଧୀଙ୍କ ଇମେଲ୍ |",
    pleaseEnterCompanyRepPhone:
      "ଦୟାକରି କମ୍ପାନୀ ପ୍ରତିନିଧୀଙ୍କ ଫୋନ୍ ନମ୍ବର ପ୍ରବେଶ କରନ୍ତୁ |",
    companyRepPhone: "କମ୍ପାନୀର ପ୍ରତିନିଧୀଙ୍କ ଫୋନ୍ ନମ୍ବର |",
    addReward: "ପୁରସ୍କାର ଯୋଗକର |",
    pleaseEnterRewardName: "ଦୟାକରି ପୁରସ୍କାର ନାମ ପ୍ରବେଶ କରନ୍ତୁ |",
    rewardName: "ପୁରସ୍କାର ନାମ",
    rewardQuantity: "ପୁରସ୍କାର ପରିମାଣ",
    rewardDescription: "ପୁରସ୍କାର ବର୍ଣ୍ଣନା",
    rewardType: "ପୁରସ୍କାର ପ୍ରକାର",
    pleaseEnterRewardType: "ଦୟାକରି ପୁରସ୍କାର ପ୍ରକାର ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterRewardQuantity: "ଦୟାକରି ପୁରସ୍କାର ପରିମାଣ ପ୍ରବେଶ କରନ୍ତୁ |",
    pleaseEnterRewardDescription: "ଦୟାକରି ପୁରସ୍କାର ବର୍ଣ୍ଣନା ପ୍ରବେଶ କରନ୍ତୁ |",
    fineDining: "ଭଲ ଭୋଜନ |",
    luxuryFashion: "ବିଳାସପୂର୍ଣ୍ଣ ଫ୍ୟାଶନ୍ |",
    hotels: "ହୋଟେଲଗୁଡିକ",
    travel: "ଭ୍ରମଣ |",
    foodAndBeverage: "ଖାଦ୍ୟ ଏବଂ ପାନୀୟ",
    fashion: "ଫ୍ୟାଶନ୍",
    health: "ସ୍ୱାସ୍ଥ୍ୟ",
    furniture: "ଆସବାବପତ୍ର",
    entertainment: "ଚିତ୍ତବିନୋଦନ |",
    automobile: "ଅଟୋମୋବାଇଲ୍ |",
    education: "ଶିକ୍ଷା",
    beautyAndSpa: "ସ Beauty ନ୍ଦର୍ଯ୍ୟ ଏବଂ ସ୍ପା",
    staycation: "ଷ୍ଟାଇକେସନ୍ |",
    events: "ଘଟଣା",
    trips: "ଯାତ୍ରା",
    oilAndGas: "ତେଲ ଏବଂ ଗ୍ୟାସ୍ |",
    laundry: "ଧୋଇବା",
    partnerCategory: "ସହଭାଗୀ ବର୍ଗ",
    freeItem: "ମାଗଣା ଆଇଟମ୍ |",
  },
  Polish: {
    cashier: "kasjer",
    manager: "menedżer",
    owner: "właściciel",
    online: "online",
    offline: "offline",
    changePassword: "Zmień hasło",
    currentPasswordMessage: "Wprowadź swoje aktualne hasło",
    passwordMessage: "Wprowadź hasło",
    currentPassword: "Aktualne hasło",
    password: "hasło",
    confirmPasswordMessage: "Proszę, potwierdź swoje hasło!",
    confirmPassword: "Potwierdź hasło",
    sendViaEmail: "Wyślij przez e-mail",
    sendViaWhatsapp: "Wyślij przez WhatsApp",
    downloadPdf: "ściągnij PDF",
    paid: "płatny",
    unpaid: "nie zapłacony",
    partial: "częściowy",
    closeInvoice: "Czy chcesz zamknąć fakturę?",
    closeInvoiceConfirmation:
      "Faktura może nie zostać zapisana. Czy chcesz zamknąć?",
    yes: "tak",
    no: "nie",
    invoice: "Faktura",
    wasDeducted: "został odliczony",
    for: "dla",
    item: "Przedmiot",
    addProduct: "Dodaj produkt",
    paymentReference: "Referencje płatności",
    amountPaid: "Opłata zapłacona",
    discountAmount: "Kwota rabatu",
    amountDue: "Do zapłaty",
    amount: "Ilość",
    dueDate: "Termin płatności",
    paymentType: "Typ płatności",
    card: "Karta",
    cash: "Gotówka",
    bankTransfer: "Przelew bankowy",
    paymentMessage: "Wiadomość o płatności",
    description: "Opis",
    sendReceipt: "Wyślij potwierdzenie",
    delete: "Kasować",
    save: "Zapisać",
    resend: "Wysłać ponownie",
    saveAndSend: "Zapisać",
    invoiceSaved: "Faktura zapisana!",
    selectPaymentMethod: "Zaznacz metodę płatności!",
    selectCustomer: "Wybierz Klienta!",
    cartEmptyError:
      "Lista koszyka nie może być pusta, zamknij fakturę i dodaj Produkt do koszyka!",
    subscriptionExpired:
      "Twoja subskrypcja wygasła, a Twoje konto jest teraz ograniczone. Kliknij przycisk poniżej, aby odnowić swoje konto",
    renew: "Odnowić",
    holdOn: "Trzymać się",
    customerBank: "Bank klienta",
    cancel: "Anulować",
    selectACustomer: "Wybierz klienta",
    invoiceSuccessfulPdfError:
      "Faktura utworzona pomyślnie, ale generowanie PDF trwa dłużej niż zwykle. Sprawdź ponownie wkrótce.",
    downloadingInvoice: "Pobieranie faktury",
    downloadingReceipt: "Pobieranie rachunku",
    whatsappReceiptError:
      "Wystąpił błąd podczas wysyłania potwierdzenia przez WhatsApp. Spróbuj ponownie.",
    receiptToWhatsapp: "Potwierdzenie przekazane do WhatsApp",
    thankYouForPatronage: "Dziękuję za patronat",
    hereIsYourReceipt: "Oto pokwitowanie płatności",
    errorSendingEmailReceipt:
      "Wystąpił błąd podczas wysyłania rachunku przez e-mail, spróbuj ponownie lub skontaktuj się z obsługą",
    receiptSentToEmail: "Potwierdzenie zostało wysłane na adres e-mail klienta",
    invoiceSentToEmail: "Faktura została wysłana na adres e-mail klienta",
    invoiceSuccessWhatsappError:
      "Faktura została utworzona pomyślnie, ale wystąpił błąd podczas wysyłania do WhatsApp. Spróbuj ponownie na liście faktur",
    invoiceSuccessfulEmailError:
      "Faktura została utworzona pomyślnie, ale wystąpił błąd podczas wysyłania jako e-mail. Spróbuj ponownie z listy faktur",
    invoiceSentToWhatsapp: "Faktura przekazana do WhatsApp",
    hello: "dzień dobry",
    pleaseDownloadInvoice: "Proszę pobrać fakturę",
    pleaseDownloadReceipt: "Proszę pobrać rachunek",
    from: "z",
    email: "E-mail",
    upgrade: "Aktualizacja",
    youAreOnFreePlan: "Korzystasz z darmowego abonamentu.",
    clickOn: "Kliknij",
    yourPlanInFewSteps: " Twoja subskrypcja w kilku szybkich krokach.",
    to: "do",
    yourSubscriptionHasExpired:
      "Twoja subskrypcja wygasła, a Twoje konto jest teraz ograniczone.",
    days: "dni",
    yourSubscriptionExpiresIn: "Twoja subskrypcja Loystar wygasa za",
    createAcount: "Utwórz konto",
    signIn: "Zaloguj",
    continue: "Kontyntynuj",
    enterOtp: "Wprowadź kod PIN OTP",
    enterValidOtp: "Wprowadź poprawny kod PIN",
    pin: "SZPILKA",
    tokenSentToMail: "Token został wysłany na Twój e-mail",
    passwordResetSuccessful: "Resetowanie hasła powiodło się",
    somethingWentWrong: "Coś poszło nie tak!",
    resetPassword: "Zresetuj hasło",
    iHaveResetCode: "Mam kod resetowania hasła",
    pleaseEnterEmail: "Podaj swój adres e-mail",
    aTokenWillBeSentToEmail: "Token zostanie wysłany na Twój e-mail",
    enterEmail: "Wprowadź swój email",
    sendinYourToken: "Wysyłam Twój token...",
    makeSureItMatchesPassword: "Upewnij się, że pasuje do Twojego nowego hasła",
    pleaseChooseNewPassword: "Proszę wybrać nowe hasło",
    chooseNewPassword: "Wybierz nowe hasło",
    enterNewPassword: "Wprowadź nowe hasło, aby potwierdzić",
    enterTokenSent: "Wpisz token, który został wysłany na Twoją pocztę",
    resetToken: "Resetuj token",
    resettingPassword: "Resetowanie hasła...",
    signUp: "Zapisać się",
    adminSignInWithEmail:
      " Administrator loguje się za pomocą poczty e-mail, a personel loguje się przy użyciu nazwy użytkownika.",
    pleaseEnterEmailOrUsername:
      "Wprowadź swój adres e-mail lub nazwę użytkownika",
    emailOrUsername: "Email lub nazwa użytkownika",
    pleaseEnterPassword: "Proszę wpisać hasło",
    createAnAccount: "Utwórz konto",
    forgotPassword: "Zapomniałeś hasła?",
    enterPhoneNumber: "Wpisz numer telefonu",
    personalData: "Dane osobiste",
    validateConfirmationCOde: "Zweryfikuj kod potwierdzający",
    pleaseEnterFirstName: "Proszę podaj swoje imię",
    pleaseEnterPhoneNumber: "Podaj swój numer telefonu",
    pleaseEnterLastName: "Proszę podać swoje nazwisko",
    pleaseEnterBusinessName: "Wprowadź nazwę swojej firmy",
    firstName: "Imię",
    lastName: "Nazwisko",
    businessName: "Nazwa firmy",
    previous: "Poprzedni",
    next: "Następny",
    pleaseSelectBusinessCategory: "Wybierz kategorię swojej działalności",
    pleaseEnterValidEmail: "Proszę podać poprawny adres e-mail",
    pleaseEnterPostCode: "Proszę wpisać kod pocztowy",
    postCode: "Kod pocztowy",
    phoneNumberInUse: "Ten numer telefonu jest już używany!",
    emailInUse: "Ten e-mail jest już używany!",
    foodAndDrinks: "Jedzenie i napoje",
    salonAndBeauty: "Salon i Uroda",
    fashionAndAccessories: "Moda i dodatki",
    gymAndFitness: "Siłownia i fitness",
    travelAndHotel: "Podróże i hotel",
    homeAndGifts: "Dom i prezenty",
    washingAndCleaning: "Mycie i czyszczenie",
    gadgetsAndElectronics: "Gadżety i elektronika",
    groceries: "Artykuły spożywcze",
    others: "Inni",
    submit: "Składać",
    accountCreatedSuccessful: "Twoje konto zostało pomyślnie utworzone.",
    pleaseEnterAddress: "Podaj swój adres",
    addressLine1: "Wiersz adresu 1",
    addressLine2: "Wiersz adresu 2",
    choosePassword: "Wybierz hasło",
    passwordMustBe6Characters: "Hasło musi mieć co najmniej 6 znaków.",
    howDidYouHearLoystar: "Jak dowiedziałeś się o Loystar?",
    referralCode: "kod polecającego",
    byClickingTheButton: " Klikając poniższy przycisk, wyrażasz zgodę na",
    termsAndSevice: "Warunki",
    wereCreatingAccount: "Tworzymy Twoje konto",
    proceed: "Przystępować",
    verificationCodeMustBe6: "Kod weryfikacyjny musi mieć 6 cyfr",
    pleaseEnter6DigitCode: "Proszę wpisać 6-cyfrowy kod",
    enterVerificationCode: "Wpisz kod weryfikacyjny",
    resendToken: "Wyślij ponownie token",
    verify: "Zweryfikować",
    transactions: "Transakcje",
    todaySales: "Dzisiejsze wyprzedaże",
    salesHistory: "Historia sprzedaży",
    supplyHistory: "Historia dostaw",
    new: "Nowy",
    invoices: "Faktury",
    disbursements: "Wypłaty pieniędzy",
    offlineSales: "Sprzedaż offline",
    products: "Produkty",
    customers: "Klienci",
    multiLevelLoyalty: "Wielopoziomowa lojalność",
    loyaltyPrograms: "Programy lojalnościowe",
    members: "Członkowie",
    appStore: "Sklep z aplikacjami",
    orderMenu: "Zamów menu",
    settings: "Ustawienia",
    staffAndBranches: "Sztaby i Oddziały",
    myAccount: "Moje konto",
    switchToSellMode: "Przełącz na tryb sprzedaży",
    signOut: "Wyloguj się",
    getFreeSubscription: "Uzyskaj bezpłatną subskrypcję",
    onlyNumbersAllowed: "Dozwolone są tylko liczby",
    yourAccountMustBe10Digits: "Twój numer konta musi składać się z 10 cyfr",
    yourBvnMustBe11: "Twój BVN musi być 11-cyfrowym numerem",
    pleaseSelectBank: "Wybierz swój bank",
    selectYourBank: "Wybierz swój bank",
    enterBankAccountNumber: "Wprowadź numer konta bankowego",
    enterBvnNumber: "Wpisz swój BVN",
    connectBankAccount: "Połącz konto bankowe",
    passwordResetSuccessfulAndSignOut:
      "Twoje hasło zostało pomyślnie zresetowane. Kliknij przycisk Wyloguj się poniżej, aby zalogować się ponownie",
    enterCurrentPassword: "Wprowadź bieżące hasło",
    pleaseEnterCurrentPassword: "Proszę podać aktualne hasło",
    phoneNumber: "Numer telefonu",
    sex: "Seks",
    dateOfBirth: "Data urodzenia",
    state: "Stan",
    country: "Kraj",
    loyaltyId: "Identyfikator lojalności",
    createdAt: "Utworzono w",
    noOfTransactionsMade: "Liczba dokonanych transakcji",
    yourDownloadWillStart: "Pobieranie rozpocznie się za chwilę",
    exportCustomers: "Klienci eksportowi",
    youhaveSuccessfullyToppedUp: "Pomyślnie doładowałeś swoje jednostki SMS.",
    youNowHave: "Masz teraz",
    smsUnits: "Jednostki SMS",
    enterNumberOfSmsUnits:
      "Wprowadź liczbę jednostek SMS, które zamierzasz kupić",
    pleaseEnterANumericValue: "Wprowadź wartość liczbową",
    pay: "Płacić",
    accountEditedSuccessfully: "Twoje konto zostało pomyślnie edytowane.",
    youAeCurrentlyOn: "Jesteś obecnie włączony",
    plan: "Plan",
    userData: "Dane użytkownika",
    businessData: "DANE BIZNESOWE",
    businessCategory: "Kategoria biznesowa",
    pleaseSelectCurrency: "Wybierz swoją walutę",
    selectYourCurrency: "Wybierz swoją walutę",
    purchaseMoreSmsUnits:
      "Kup więcej jednostek SMS, korzystając z poniższego formularza",
    youHave: "Ty masz",
    atLeast4SmsUnitsRrequired:
      "Do weryfikacji wymagane są co najmniej 4 jednostki sms, proszę doładować!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Zweryfikuj swoje konto bankowe, aby spełnić wymagania „Poznaj swojego klienta” (KYC). Umożliwi to zbieranie płatności za pośrednictwem USSD lub przelewu natychmiastowego, otrzymywanie zamówień od klientów oraz proces rozliczania transakcji. Dotyczy tylko kupców nigeryjskich. Aby rozpocząć, naciśnij poniższy przycisk.",
    reConnectBankAccount: "Połącz ponownie konto bankowe",
    accountName: "Nazwa konta",
    accountNumber: "Numer konta",
    bankName: "Nazwa banku",
    verified: "Zweryfikowany",
    accountDetails: "Szczegóły Konta",
    kycBankAccount: "KYC",
    createTier: "Utwórz poziom",
    fileUploadSuccessful: "plik przesłano pomyślnie",
    fileUploadFailed: "nie udało się przesłać pliku",
    createLoyaltyProgram: "Utwórz program lojalnościowy",
    createLoyalty: "Stwórz Lojalność",
    name: "Nazwa",
    loyaltyArtwork: "Grafiki lojalnościowe",
    clickToUpload: "Kliknij, aby przesłać",
    amountToPointsRatio: "Stosunek ilości do punktów",
    points: "Zwrotnica",
    recommendedAmountToPointRatio:
      "Zalecane: ₦1 do 1 punktu. tj. za każdą wydaną 1 Nairę Twoi klienci otrzymują 1 punkt",
    pleaseTypeIn: "Proszę wpisać",
    toDeleteLoyalty: "usunąć tę lojalność",
    deleteLoyalty: "Usuń Lojalność",
    toConfirm: "potwierdzać",
    edit: "Edytować",
    pointsAwardedSuccessfully: "Punkty przyznane pomyślnie.",
    enterPointValueToAward:
      "Podaj wartość punktową, którą chciałbyś przyznać klientom",
    award: "Nagroda",
    awardPointValuesToCustomer: "Nagroda Punktowa Wartość dla Klienta",
    enrollMembersToLoyalty: "Zarejestruj członków w programie lojalnościowym",
    awardPoints: "Punkty przyznawania",
    enroll: "Zapisać",
    home: "Dom",
    loyalty: "Lojalność",
    enrollCustomers: "Zarejestruj klientów",
    selected: "Wybrany",
    customer: "Klient",
    loyaltyActivationSuccessful: "Aktywacja Lojalności się powiodła.",
    loyaltyDeactivationSuccessful:
      "Dezaktywacja lojalności zakończyła się pomyślnie.",
    viewTier: "Wyświetl poziom",
    deactivate: "Dezaktywować",
    activate: "Aktywuj",
    actions: "działania",
    nameOfLoyalty: "Nazwa Lojalności",
    loyaltyStatus: "Status Lojalności Status Lojalności",
    numberOfTiers: "Liczba poziomów",
    createdOn: "Utworzono dnia",
    createATier: "Utwórz poziom",
    stopCreatingTierConfirmation: "Czy chcesz przestać tworzyć poziom?",
    stopEditingTierConfirmation: "Czy chcesz przestać edytować ten poziom?",
    nameOfTier: "Nazwa poziomu",
    minimumSpendingTarget: "Minimalny cel wydatków",
    maximumSpendingTarget: "Maksymalny cel wydatków",
    minimumSpendingTargetRequired: "minimalny cel wydatków jest wymagany",
    maximumSpendingTargetRequired: "wymagany maksymalny cel wydatków",
    rewardSponsor: "Sponsor nagrody",
    pleaseChooseARewardSponsor: "Wybierz sponsora nagrody",
    self: "Samego siebie",
    partner: "Partner",
    rewardPartner: "Partner nagrody",
    pleaseSelectRewardPartner: "Wybierz partnera do nagrody",
    rewards: "Nagrody",
    pleaseSelectAReward: "Proszę, wybierz nagrodę",
    instructionsOnRedeemingReward:
      "Instrukcje dotyczące tego, jak klient powinien wykorzystać nagrodę",
    pleaseFillInThisField: "Proszę wypełnić to pole!",
    toDeleteThisTier: " aby usunąć ten poziom",
    deleteTier: "Usuń poziom",
    viewMembers: "Zobacz członków",
    membersEnrolled: "Zarejestrowani członkowie",
    instruction: "Instrukcja",
    membersIn: "Członkowie w",
    availableTiersInLoyalty: "Dostępne poziomy w programie lojalnościowym",
    tiers: "Schody",
    totalTier: "POZIOM CAŁKOWITY",
    availableLoyaltyProgramme: "Dostępny program lojalnościowy",
    totalLoyalties: "RAZEM LOJALNOŚCI",
    memberDetails: "Dane członka",
    nameOfCustomer: "Imię klienta",
    address: "Adres",
    allEnrolledMembers: "Wszyscy zarejestrowani członkowie",
    thisIsToWishYouHappyBirthday:
      "To życzę ci bardzo szczęśliwych urodzin i dostatniego życia. Dzięki za wszystko, czym jesteś dla Loyster. Wesołych Świąt!",
    inputAnOfferPlease: "Wprowadź ofertę proszę",
    pleaseSelectTheInsertPoint:
      "Wybierz punkt wstawienia w wiadomości i kliknij ponownie",
    birthdayOfferAndMessage: "Oferta urodzinowa i wiadomość",
    birthdayOffer: "Oferta urodzinowa",
    birthdayMessage: "Wiadomość urodzinowa",
    noOfferFound: "Nie znaleziono oferty",
    settingABirthdayOffer:
      "Ustawienie oferty urodzinowej umożliwia klientom otrzymywanie tej oferty przez SMS w dniu urodzin",
    createOffer: "Utwórz ofertę",
    whatIsTheOffer: "Jaka jest oferta?",
    editMessage: "Edytuj wiadomość",
    insert: "Wstawić",
    theNameOfCustomerInserted:
      "Tutaj zostanie wstawione imię i nazwisko klienta",
    theBirtdayOfferInserted: "Oferta urodzinowa zostanie wstawiona tutaj",
    youSuccessfullyAddedNewBranch: "Pomyślnie dodałeś nowy oddział!",
    addNewBranch: "Dodaj nowy oddział",
    addBranch: "Dodaj oddział",
    additionalBranchWillIncur: "Dodatkowy oddział poniósłby",
    perBranch: "na oddział",
    ecommerceBranchCosts: "Koszty oddziału e-commerce",
    pleaseEnterBranchName: "Proszę podać nazwę oddziału",
    pleaseEnterBranchAddress1: "Proszę wpisać adres oddziału w wierszu 1",
    enterBranchAddress1: "Wpisz wiersz adresu oddziału 1",
    enterBranchAddress2: "Wpisz wiersz adresu oddziału 1",
    pleaseEnterBranchAddress2: "Proszę wpisać wiersz adresu oddziału 2",
    enterBranchName: "Wpisz nazwę oddziału",
    successfullyAddedStaff: "Pomyślnie dodałeś nową laskę!",
    addNewStaff: "Dodaj nowy personel",
    addStaff: "Dodaj personel",
    additionalStaffWillIncur: "Poniesie to dodatkowy personel",
    perStaff: "na pracownika.",
    pleaseEnterStaffEmail: "Wprowadź adres e-mail personelu",
    pleaseEnterStaffUsername: "Proszę podać nazwę użytkownika personelu",
    pleaseEnterStaffPassword: "Proszę podać hasło personelu",
    pleaseSelectStaffRole: "Proszę wybrać rolę personelu",
    selectStaffRole: "Wybierz rolę personelu",
    enterStaffEmail: "Wpisz adres e-mail personelu",
    enterStaffUsername: "Wpisz nazwę użytkownika personelu",
    enterStaffPassword: "Wpisz hasło personelu",
    spacesNotAllowedInUsername: "spacje niedozwolone w nazwie użytkownika",
    admin: "Administrator",
    pleaseSelectBusinessToAttachStaff: "Wybierz firmę do dołączenia personelu",
    searchForBranchToAttachStaff: "Wyszukaj oddział do dołączenia personelu",
    username: "Nazwa użytkownika",
    role: "Rola",
    areYouSureToDeleteThis: "Czy na pewno chcesz to usunąć?",
    branches: "Gałęzie",
    upgradeYourPlan: "Uaktualnij swój plan.",
    add: "DODAJ",
    addNew: "Dodaj nowe",
    customerWithEmailAlreadyExists:
      "Klient z e-mailem/numerem telefonu już istnieje!",
    successfullyAddedNewCustomer: "Pomyślnie dodałeś nowego klienta!",
    addCustomer: "Dodaj klienta",
    pleaseEnterCustomerFirstName: "Wprowadź imię klienta",
    pleaseEnterCustomerLastName: "Proszę podać nazwisko klienta",
    pleaseEnterCustomerPhoneNumber: "Podaj numer telefonu klienta",
    pleaseEnterCustomerEmail: "Wprowadź adres e-mail klienta",
    pleaseEnterCustomerAddressLine: "Wprowadź wiersz adresu klienta",
    pleaseEnterCustomerOtherAddress: "Wprowadź inny adres klienta",
    pleaseSelectCustomerGender: "Wybierz płeć klienta",
    gender: "Płeć",
    male: "Męski",
    female: "Płeć żeńska",
    bank: "Bank",
    selectBank: "Wybierz bank",
    stateOrRegionOrProvince: "Stan/region/prowincja",
    customerNotes: "Uwagi klienta",
    sendSms: "Wyślij SMS",
    editCustomer: "Edytuj klienta",
    redeemReward: "Odbierz nagrodę",
    issueLoyaltyCard: "Wydaj kartę lojalnościową",
    deleteCustomer: "Usuń klienta",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Pomyślnie przypisałeś identyfikator członkostwa lojalnościowego",
    noMembershipIdAvailable:
      "Brak dostępnych identyfikatorów członkostwa. Skontaktuj się z nami na hello@loystar.co",
    sendEmail: "Wysłać email",
    membershipPoints: "Punkty członkowskie",
    customerDetails: "Szczegóły klienta",
    close: "Blisko",
    loyaltyBalance: "Saldo lojalnościowe",
    pointsBalance: "Saldo punktów",
    starBalance: "Równowaga gwiezdna",
    requiredPoints: "Wymagane punkty",
    requiredStars: "Wymagane gwiazdki",
    reddemCode: "Zrealizuj kod",
    toDeleteThisCustomer: "aby usunąć tego klienta",
    customerHasBeenDeletedSuccessfully: "Klient został pomyślnie usunięty!",
    customerWithPhoneAlreadyExists: "Klient z numerem telefonu już istnieje",
    customerWasSuccessfullyEdited: "Klient został pomyślnie edytowany",
    anErrorOccured: "Wystąpił błąd",
    phoneNumberIncludeCountry: "Numer telefonu (wraz z kodem kraju)",
    yourFileQueuedForUpload:
      "Twój plik został umieszczony w kolejce do przesłania. Odśwież stronę po kilku sekundach.",
    thereWasAnErrorPerformingOperation:
      "Wystąpił błąd podczas wykonywania operacji!",
    pleaseSelectFile: "Wybierz plik!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Dozwolone są tylko pliki CSV. Prześlij plik .csv.",
    fileShouldBeSmallerThan5Mb:
      "Plik powinien być mniejszy niż 5 MB. Jeśli masz większy plik, wyślij e-mail na adres support@loystar.co. Dziękuję Ci",
    customerFirstNameIsRequired: "imię klienta jest wymagane w wierszu",
    customerPhoneNumberIsRequired:
      "numer telefonu klienta jest wymagany w wierszu",
    importCustomers: "Importuj klientów",
    upload: "Wgrywać",
    clickIconToDownloadCsv: "Kliknij tę ikonę, aby pobrać szablon pliku CSV.",
    getGoogleSheetVersion: "Pobierz wersję arkusza Google",
    clickOrDragFileToUpload:
      "Kliknij lub przeciągnij plik do tego obszaru, aby przesłać",
    missingOrInvalidColumnHeader:
      "Brakujący lub nieprawidłowy nagłówek kolumny. Proszę postępować zgodnie z formatem szablonu. Dziękuję Ci.",
    youHaveImported: "Zaimportowałeś",
    youSuccessfullyRedeemedReward: "Udało Ci się odebrać nagrodę!",
    sixDigitCode: "Sześciocyfrowy kod",
    pleaseEnterCustomerRewardCode: "Wprowadź kod nagrody klienta",
    enterRewardCode:
      "Wpisz kod nagrody.(W kodzie nagrody rozróżniana jest wielkość liter)",
    selectLoyaltyProgram: "Wybierz program lojalnościowy",
    stamps: "Znaczki pocztowe",
    smsUnitsLow: "Niskie jednostki SMS",
    pleaseTopUpSmsUnits: "Doładuj jednostki SMS",
    smsSuccessfullySentForDelivery: "SMS pomyślnie wysłany do doręczenia!",
    sendSmsTo: "Wyślij SMS do",
    allCustomers: "Wszyscy klienci",
    unitsAvailable: "Dostępne jednostki",
    pleaseTypeInTheMessage: "Proszę wpisać wiadomość",
    message: "Wiadomość",
    charactersRemaining: "pozostałe znaki",
    avoidUseOfSpecialCharacters:
      "Unikaj używania znaków specjalnych i emotikonów, aby oszczędzać jednostki SMS.",
    note: "Notatka",
    errorFetchingCustomersMultilevelDetail:
      "Błąd podczas pobierania wielopoziomowych szczegółów klientów",
    search: "Szukaj",
    reset: "Resetowanie",
    importCustomer: "Importuj klienta",
    addNewCustomer: "Dodaj nowego klienta",
    sendSmsBroadcast: "Wyślij transmisję SMS",
    totalCustomers: "RAZEM KLIENCI",
    disbursementDetails: "Szczegóły wypłaty",
    paidBy: "Opłacone przez",
    disbursed: "Wypłacone",
    bankAccountName: "Nazwa konta bankowego",
    bankAccountNumber: "Numer konta bankowego",
    transferInitiated: "Rozpoczęto transfer",
    transferCompleted: "Transfer zakończony",
    pleaseEnterAValid: "Proszę wpisać poprawny",
    begin: "rozpocząć",
    end: "kończyć się",
    date: "Data",
    paymentDate: "Termin płatności",
    selectDisbursementDuration: "Wybierz czas trwania wypłaty",
    totalSettled: "Razem rozliczone",
    totalUnsettled: "Razem nierozliczone",
    toDeleteThisSale: "usunąć tę sprzedaż",
    draftSaleDeletedSuccessfully:
      "Wyprzedaż robocza została pomyślnie usunięta!",
    deleteSale: "Usuń wyprzedaż",
    pleaseTypeInYourTotal: "Wpisz sumę, aby potwierdzić usunięcie",
    billDetails: "Szczegóły rachunku",
    printBill: "Wydrukuj rachunek",
    servedBy: "Obsługiwany przez",
    total: "Całkowity",
    createdDate: "stworz Date",
    createdTime: "Utworzono czas",
    status: "Status",
    loginSuccessful: "Zalogowano poprawnie",
    pleaseWaitWhileWeConnectAccount: "Poczekaj, aż połączymy Twoje konto",
    connectionFailedTryAgain: "Połączenie nieudane. Proszę spróbuj ponownie.",
    connectionSuccessful: "Połączenie powiodło się",
    viewDetails: "Pokaż szczegóły",
    enable: "Włączyć",
    free: "Darmowy",
    cost: "Koszt",
    visitWebsite: "Odwiedź stronę",
    pleaseUpgradeYourPlanToPro:
      "Uaktualnij swój plan do Pro Plus, aby włączyć tę aplikację",
    connectYourBankAccount:
      "Połącz swoje konto bankowe, aby móc otrzymywać płatności.",
    disable: "Wyłączyć",
    enableApp: "Włącz aplikację",
    addNewProductToInvoice: "Dodaj nowy produkt do faktury",
    toDeleteThisInvoice: "aby usunąć tę fakturę",
    invoiceDeletedSuccessfully: "Faktura została pomyślnie usunięta!",
    deleteInvoice: "Usuń fakturę",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Pomyślnie dodałeś wiadomość o płatności faktury!",
    pleaseEnterPaymentInstructions: "Wprowadź instrukcje dotyczące płatności",
    invoiceId: "Identyfikator faktury",
    paidDate: "Data płatności",
    reference: "Referencja",
    productAdded: "Dodano produkt",
    productOutOfStock: "Produkt niedostępny. Prosimy o uzupełnienie zapasów.",
    totalInvoices: "RAZEM FAKTURY",
    totalPaidInvoices: "RAZEM OPŁACONE FAKTURY",
    totalUnpaidInvoices: "RAZEM NIEOPŁACONE FAKTURY",
    loyaltyProgramDeleted: "Program lojalnościowy usunięty",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ten program lojalnościowy został pomyślnie usunięty",
    thisLoyaltyProgramEditedSuccessfully:
      "Ten program lojalnościowy został pomyślnie edytowany",
    loyaltyProgramAddedSuccessfully:
      "Program lojalnościowy został pomyślnie dodany",
    loyaltyProgramEdited: "Edytowano program lojalnościowy",
    loyaltyProgramAdded: "Dodano program lojalnościowy",
    loyaltyDetails: "Szczegóły dotyczące lojalności",
    doYouWantToCloseDialog: "Czy chcesz zamknąć to okno dialogowe?",
    pleaseEnterLoyaltyName: "Podaj nazwę swojej lojalności",
    programType: "Typ programu",
    pleaseSelectProgramType: "Wybierz typ programu",
    simplePoints: "Proste punkty",
    stampsProgram: "Program znaczków",
    threshold: "Próg",
    pleaseEnterLoyaltyThreshold: "Wprowadź próg lojalnościowy",
    reward: "Nagroda",
    pleaseEnterLoyaltyReward: "Podaj nagrodę lojalnościową",
    totalUserPoints: "Suma punktów użytkownika",
    totalUserStamps: "Łączna liczba znaczków użytkowników",
    spendingTarget: "Cel wydatków",
    spendingTargetHint1:
      "Cel wydatków określa, ile klient musi wydać, aby otrzymać nagrodę. 1 wartość waluty = 1 punkt.",
    spendingTargetHint2:
      "Cel znaczków to liczba znaczków, które klient musi zebrać, aby otrzymać nagrodę. np. 5",
    addNewLoyaltyProgram: "Dodaj nowy program lojalnościowy",
    addLoyaltyProgram: "Dodaj program lojalnościowy",
    loyaltyProgramType: "Rodzaj programu lojalnościowego",
    pleaseSelectLoyaltyProgramType: "Wybierz typ programu lojalnościowego",
    nameOfProgram: "Nazwa programu",
    pleaseEnterProgramName: "Proszę wpisać nazwę programu",
    whatIsTheReward: "Jaka jest nagroda?",
    egNextPurchaseIsFree: "Np. Następny zakup jest bezpłatny",
    customerName: "Nazwa klienta",
    guestCustomer: "Klient gość",
    orderConfirmedSuccessfully: "Zamówienie potwierdzone pomyślnie",
    orderCancelledSuccessfully: "Zamówienie anulowane pomyślnie",
    confirmOrder: "Potwierdzić zamówienie",
    cancelOrder: "Anuluj zamówienie",
    allOrders: "Wszystkie zamówienia",
    totalOrders: "SUMA ZAMÓWIEŃ",
    doYouWantToAcceptOrder: "Czy chcesz przyjąć to zamówienie?",
    doYouWantToCancelOrder: "Czy chcesz anulować to zamówienie?",
    orderDetails: "Szczegóły zamówienia",
    orderCreatedAt: "Zamówienie utworzone w",
    supplier: "Dostawca",
    productName: "Nazwa produktu",
    quantity: "Ilość",
    unitPrice: "Cena jednostkowa",
    receivedBy: "Otrzymane przez",
    reportFrom: "Raport z",
    totalSupplies: "Dostawy ogółem",
    allRightsReserved: "Wszelkie prawa zastrzeżone",
    toDeleteThisTransaction: "aby usunąć tę transakcję",
    transactionDeletedSuccessfully:
      "Transakcja została pomyślnie usunięta. Zapasy zostały zwrócone do magazynu.",
    deleteTransaction: "Usuń transakcję",
    transactionDetails: "szczegóły transakcji",
    printReceipt: "Wydrukuj potwierdzenie",
    channel: "Kanał",
    discount: "Zniżka",
    profit: "Zysk",
    discountedSales: "Sprzedaż z rabatem",
    errorFetchingRecord: "Błąd podczas pobierania rekordu",
    exportTransactions: "Transakcje eksportowe",
    errorFetchingSalesRecord:
      "Błąd podczas pobierania rekordu sprzedaży do eksportu.",
    totalSellingPrice: "Całkowita cena sprzedaży",
    totalCostPrice: "Całkowita cena kosztu",
    appliedDiscount: "Zastosowana zniżka",
    noOfItems: "Liczba przedmiotów",
    sales: "Sprzedaż",
    export: "Eksport",
    totalProfit: "Całkowity zysk",
    totalBalanceInPeriod: "Całkowite saldo w okresie",
    allTimeSales: "Sprzedaż przez cały czas",
    records: "Dokumentacja",
    todaysSales: "Dzisiejsze wyprzedaże",
    transaction: "transakcja",
    youHaveExceededTotalNumberOfProducts:
      "Przekroczyłeś łączną liczbę produktów dozwoloną w Twoim abonamencie. Ulepsz swój plan, aby cieszyć się wyższym limitem.",
    youNeedToHaveLoyaltyProgram:
      "Aby korzystać z tej funkcji, musisz mieć program lojalnościowy!",
    price: "Cena",
    category: "Kategoria",
    stockTracking: "Śledzenie zapasów",
    stockCount: "Ilość zapasów",
    taxed: "Opodatkowane",
    originalPrice: "Oryginalna cena",
    costPrice: "Cena fabryczna",
    unit: "Jednostka",
    productImage: "obraz produktu",
    taxRate: "Wysokość podatku",
    taxType: "Rodzaj podatku",
    trackInventory: "Śledź inwentarz",
    variants: "Warianty",
    hasVariants: "Ma warianty",
    importProduct: "Importuj produkt",
    exportProducts: "Eksportuj produkty",
    addNewProduct: "Dodaj nowy produkt",
    viewCategory: "Zobacz kategorię",
    viewSuppliers: "Zobacz dostawców",
    receiveInventory: "Odbierz ekwipunek",
    printAllProductsTag: "Drukuj wszystkie produkty Tag",
    deleteAll: "Usuń wszystko",
    totalProducts: "RAZEM PRODUKTY",
    youveSuccessfullyAddedANewCategory: "Pomyślnie dodałeś nową kategorię",
    addNewCategory: "Dodaj nową kategorię",
    addCategory: "Dodaj kategorię",
    categoryName: "Nazwa Kategorii",
    pleaseEnterCategoryName: "Proszę podać nazwę kategorii",
    stampsTarget: "Znaczki Cel",
    sendInventory: "Wyślij ekwipunek",
    productDetails: "Szczegóły Produktu",
    youveSuccessfullyEditedThisCategory: "Udało Ci się edytować tę kategorię",
    update: "Aktualizacja",
    categoryList: "Lista kategorii",
    categories: "Kategorie",
    enterQuantityToUpdate: "Wprowadź ilość do aktualizacji",
    inventorySentSuccessfully: "Ekwipunek został pomyślnie wysłany!",
    updateInventory: "Aktualizuj ekwipunek",
    currentQuantity: "Aktualna ilość",
    pleaseEnterQuantityToAdd: "Wprowadź ilość, którą chcesz dodać",
    pleaseSelectABranch: "Wybierz oddział",
    searchForBranch: "Wyszukaj oddział",
    youCantSendMoreThanStock: "Nie możesz wysłać więcej niż masz na stanie",
    send: "Wysłać",
    pleaseEnterQuantityToSend: "Podaj ilość, którą chcesz wysłać",
    productNameIsRequiredOnRow: "w wierszu wymagana jest nazwa produktu",
    productCategoryIsRequiredOnRow:
      "kategoria produktu jest wymagana w wierszu",
    productPriceIsRequiredOnRow: "cena produktu jest wymagana w rzędzie",
    productUnitIsRequiredOnRow: "jednostka produktu jest wymagana w rzędzie",
    productQuantityIsRequiredOnRow: "ilość produktu jest wymagana w rzędzie",
    productVariantsRequireTracking: "warianty produktów wymagają śledzenia!",
    pleaseAddVariantClickButton: "Dodaj wariant, klikając przycisk Dodaj!",
    totalVariantsMoreThanSelectedQuantity:
      "Całkowite warianty są większe niż wybrana ilość produktu, Pls zmniejsz ilość wariantów!",
    productEditedSuccessfully: "Produkt został pomyślnie edytowany!",
    fileTooLargeLessThan4Mb:
      "Rozmiar pliku jest za duży! Rozmiar pliku powinien być mniejszy niż 4 MB.",
    suchVariantAlreadyExist: "Taki wariant już istnieje",
    addVariants: "Dodaj warianty",
    editProduct: "Edytuj produkt",
    pleaseEnterProductName: "Wprowadź nazwę produktu",
    pleaseEnterProductPrice: "Wprowadź cenę produktu",
    pleaseEnterProductOriginalPrice: "Wprowadź oryginalną cenę produktu",
    productDescription: "Opis produktu",
    selectProductCategory: "Wybierz kategorię produktu",
    pleaseSelectProductCategory: "Proszę wybrać kategorię produktu",
    productCostPrice: "Koszt produktu Cena",
    productSellingPrice: "Cena sprzedaży produktu",
    productOriginalPrice: "Oryginalna cena produktu",
    maxFileSizeAllowedIs4Mb: "Maks. Dozwolony rozmiar pliku to 4mb",
    productsWithPicturesArePublished:
      "Produkty ze zdjęciami są publikowane w Discover, aby otrzymywać zamówienia",
    shouldThisProductBeTracked: "Czy ten produkt powinien być śledzony?",
    pleaseSelectStockUnit: "Proszę wybrać jednostkę magazynową",
    stockUnit: "Jednostka magazynowa",
    bag: "TORBA",
    bottle: "BUTELKA",
    bunch: "GARŚĆ",
    can: "MÓC",
    carton: "KARTON",
    crate: "SKRZYNIA",
    cup: "FILIŻANKA",
    dozen: "TUZIN",
    gigabytes: "GIGABYTY",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITR",
    pack: "PAKIET",
    pair: "PARA",
    pieces: "SZTUKI",
    plate: "TALERZ",
    tonne: "TONA (MT)",
    uNIT: "JEDNOSTKA",
    yard: "DZIEDZINIEC",
    pleaseEnterProductQuantity: "Proszę podać ilość produktu",
    productQuantity: "Ilość produktu",
    isThisProductTaxed: "Czy ten produkt jest opodatkowany?",
    selectTaxType: "Wybierz rodzaj podatku",
    pleaseSelectTaxType: "Wybierz rodzaj podatku",
    progressive: "Progresywny",
    proportional: "Proporcjonalny",
    pleaseEnterProductTaxRate: "Podaj stawkę podatku od produktu",
    doesProductHaveVariants: "Czy ten produkt ma warianty?",
    type: "Rodzaj",
    value: "Wartość",
    pleaseEnterVariantType: "Proszę podać typ wariantu",
    pleaseEnterVariantValue: "Podaj wartość wariantu",
    pleaseEnterVariantPrice: "Podaj cenę wariantu",
    pleaseEnterVariantQuantity: "Proszę podać ilość wariantu",
    productDeletedSuccessfully: "Produkt został pomyślnie usunięty!",
    categoryDeletedSuccessfully: "Kategoria została pomyślnie usunięta!",
    toDeleteThisProduct: "aby usunąć ten produkt",
    invalidProductQuantity: "Nieprawidłowa ilość produktu",
    quantityAddedIsOverStock:
      "Ilość, którą dodajesz, przekracza to, co masz na stanie.",
    itemInventoryNotTracked: "Zapasy przedmiotów nie są śledzone",
    addBulkQuantity: "Dodaj ilość luzem",
    enterBulkQuantity: "Wprowadź ilość luzem",
    pleaseEnterBulkQuantity: "Wprowadź ilość luzem",
    youveSuccessfullyAddedANewProduct: "Pomyślnie dodałeś nowy produkt!",
    pleaseEnterProductSellingPrice: "Podaj cenę sprzedaży produktu",
    doYouWantToTrackProductStock:
      "Czy chcesz śledzić stan magazynowy produktów?",
    sellingPrice: "Cena sprzedaży",
    setProductExpiryReminder: "Ustaw przypomnienie o wygaśnięciu produktu",
    productExpiryDate: "Data ważności produktu",
    startRemindingFrom: "Zacznij przypominać od",
    productSuppliesAddedSuccessfully: "Pomyślnie dodałeś zapasy produktów.",
    addProductSupplies: "Dodaj zapasy produktów",
    pleaseSelectSupplier: "Proszę wybrać dostawcę",
    nameOfProduct: "Nazwa produktu",
    pleaseSelectProduct: "Proszę wybrać produkt",
    productVariant: "Wariant produktu",
    pleaseSelectAVariant: "Wybierz wariant",
    pleaseEnterUnitPrice: "Wprowadź cenę jednostkową",
    businessBranch: "Oddział biznesowy",
    pleaseSelectBranch: "Proszę wybrać oddział",
    youveSuccessfullyAddedANewSupplier: "Pomyślnie dodałeś nowego dostawcę",
    addSupplier: "Dodaj dostawcę",
    pleaseEnterSupplierEmail: "Wprowadź adres e-mail dostawcy",
    pleaseAddADescription: "Proszę dodać opis",
    anErrorOccuredProductsDeleted:
      "Wystąpił błąd podczas wykonywania operacji. Pamiętaj, że kilka produktów mogło zostać usuniętych w trakcie tego procesu",
    bulkDelete: "Usuń zbiorczo",
    youAreAboutToDelete: "Zamierzasz usunąć",
    product: "Produkt",
    isDueToRedeem: "jest do wykupu",
    aReward: "nagroda",
    pleaseSelectCustomerToReeemReward: "Wybierz klienta, aby odebrać nagrodę.",
    youHaveNoLoyaltyProgramRunning:
      "Nie masz aktywnego programu lojalnościowego",
    customerHhasNoPointsInLoyaltyProgram:
      "Klient nie ma punktów w tym programie lojalnościowym",
    proceedWithPayment: "Kontynuować płatność?",
    youAreAboutToPayForTransactionUsingPoints:
      "Za chwilę zapłacisz za transakcję punktami.",
    customerHas: "Klient ma",
    worth: "wartość",
    andIsNotSufficientToPayForTransaction:
      "i nie wystarczy na opłacenie tej transakcji. Czy chcieliby dodać saldo przy użyciu innej metody płatności?",
    addCustomerLoyalty: "Dodaj lojalność klienta",
    pleaseAddCustomerFirst: "Najpierw dodaj lub wybierz klienta.",
    pleaseWaitWhileWeGetReady: "Proszę czekać, aż się przygotujemy",
    lowStock: "Niskie zasoby",
    pleaseEnterAmountTendered: "Proszę podać oferowaną kwotę",
    areYouSureToBookSaleOffline:
      "Czy na pewno chcesz zarezerwować tę wyprzedaż offline?",
    saleWouldBeBookedAutomatically:
      "Sprzedaż zostanie zarezerwowana automatycznie po włączeniu Internetu",
    offlineSalesBookingCancelled:
      "Rezerwacja sprzedaży offline została anulowana",
    covid19Message:
      "COVID19: Umyj ręce mydłem lub środkiem odkażającym, aby powstrzymać rozprzestrzenianie się. Bądź zawsze bezpieczny",
    saleSuccessfullyRecorded: "Sprzedaż została pomyślnie zarejestrowana!",
    sendReceiptToEmail: "Wyślij potwierdzenie na e-mail",
    sendThankYouSms: "Wyślij SMS-a z podziękowaniem",
    sendShippingDetails: "Wyślij szczegóły wysyłki",
    addLoyalty: "Dodaj lojalność",
    searchCustomerNameOrNumber: "Wyszukaj nazwę lub numer klienta",
    clickTheSearchCustomerBox: "Kliknij pole Wyszukaj klienta i zeskanuj kartę",
    enterProductPrice: "Wprowadź cenę produktu",
    enterPriceFor: "Wprowadź cenę za",
    searchForProduct: "Wyszukaj produkt",
    all: "Wszystko",
    backToDashboard: "Powrót do pulpitu nawigacyjnego",
    viewDraftSales: "Zobacz wersję roboczą sprzedaży",
    variantSelected: "wybrany wariant",
    variant: "wariant",
    thePreviousVariantSelectionNotAvailable:
      "Poprzedni wybór wariantu nie jest dostępny dla nowego wariantu wybranego na podstawie ceny, zmień swój wybór.",
    pleaseSupplyPositiveQuantityNumber: "Proszę podać numer ilości dodatniej",
    lowStockFor: "Niski stan magazynowy dla",
    clearVariants: "Jasne warianty",
    pleaseEnterQuantity: "Wprowadź ilość",
    picture: "Zdjęcie",
    redemptionToken: "Token wykupu",
    token: "Znak",
    totalSpent: "Całkowite wydatki",
    confirmPayment: "Potwierdź płatność",
    hasPaymentBeenMade: "Czy płatność została pomyślnie przetworzona?",
    enterTransactionReference:
      "Wprowadź numer referencyjny transakcji, za pomocą której zapłaciłeś",
    pleaseSelectACustomer: "Wybierz klienta!",
    areYouSureToApplyDiscount: "Czy na pewno chcesz zastosować zniżkę?",
    addYourBankAccountToEnableUssd:
      "Dodaj swoje konto bankowe, aby umożliwić błyskawiczny przelew USSD przez uPay",
    totalAmountToPay: "Całkowita kwota do zapłaty",
    doYouWantToCancelTransaction: "Czy chcesz anulować tę transakcję?",
    savePrintBill: "Zapisz/drukuj rachunek",
    enterAmountCollectedForCustomer: "Wpisz kwotę pobraną dla klienta",
    recordSale: "Rekordowa sprzedaż",
    checkOutWith: "Sprawdzić",
    instantTransfer: "Natychmiastowy przelew",
    dialTheUSSDCode: "Wybierz kod USSD",
    pleaseSelectABank: "Proszę wybrać bank",
    payWithUSSD: "Zapłać z USSD",
    sendBillTo: " - Wyślij rachunek do",
    waitingForUSSDTransfer: "Oczekiwanie na transfer USSD",
    percent: "Procent",
    applyDiscount: "Zastosuj rabat",
    thisBillHasBeenSaved: "Ten rachunek został zapisany",
    saveDraft: "Zapisz Projekt",
    pleaseTypeANameToIdentifyCustomer:
      "Proszę wpisać nazwę, aby zidentyfikować klienta",
    paymentDetails: "Szczegóły płatności",
    basePrice: "Cena podstawowa",
    staff: "Personel",
    subTotal: "Suma częściowa",
    durationMonths: "Czas trwania (miesiące)",
    selectADuration: "Wybierz czas trwania",
    oneMonth: "1 miesiąc",
    twoMonths: "2 miesiące",
    threeMonths: "3 miesiące",
    sixMonths: "6 miesięcy",
    twelveMonths: "12 miesięcy",
    eighteenMonths: "18 miesięcy",
    twentyFourMonths: "24 miesiące",
    twoMonthsFree: "(2 miesiące za darmo)",
    threeMonthsFree: "(3 miesiące za darmo)",
    fiveMonthsFree: "(5 miesięcy za darmo)",
    yourAccountHasBeen: "Twoje konto zostało",
    renewed: "odnowiony",
    upgraded: "ulepszony",
    successfully: "z powodzeniem",
    yourSubscription: "Twoja subskrypcja",
    youAreCurrentlyEnrolledOnA: "Jesteś obecnie zapisany na",
    pleaseChooseAPaymentOption: "Proszę wybrać opcję płatności",
    planRenewal: "Odnowienie planu",
    planUpgrade: "Aktualizacja planu",
    pleaseSelectDurationToPayFor:
      "Wybierz czas trwania, za który zamierzasz zapłacić",
    staffAccounts: "Konta pracowników",
    ecommerce: "E-commerce",
    pleaseSelectAPlan: "Wybierz plan",
    includeAddons: "Dołącz dodatki",
    viewTransactions: "Zobacz transakcje",
    customerHasNoCompletedTansactions: "Klient nie ukończył jeszcze transakcji",
    branch: "Oddział",
    enterNumberOfEcommerceBranches: "Wprowadź liczbę oddziałów e-commerce",
    enterNumberOfEcommerceBranchesToPay:
      "Wprowadź liczbę oddziałów e-commerce, za które zamierzasz zapłacić",
    ecommerceIntegration: "Integracja e-commerce",
    enterNumberOfBranches: "Wprowadź liczbę oddziałów",
    enterNumberOfAdditionalBranchesToPay:
      "Wprowadź liczbę dodatkowych oddziałów, za które zamierzasz zapłacić",
    enterNumberOfStaffs: "Wprowadź liczbę sztabów",
    enterNumberOfStaffsToPayFor:
      "Wprowadź liczbę sztabów, za które zamierzasz zapłacić",
    discountApplies: "Obowiązuje zniżka",
    starsOnThe: "gwiazdy na",
    offer: "oferta",
    get: "Dostwać",
    moreStarsToRedeem: "więcej gwiazdek do odkupienia",
    taxVat: "Podatek (VAT)",
    callCashierToCompletePayment: "Zadzwoń do kasjera, aby dokończyć płatność",
    receipt: "Paragon fiskalny",
    dear: "droga",
    thankYouForYourOrderFindGoods:
      "Dziękuję za Twoje zamówienie. Prosimy o zapoznanie się z następującymi dostarczonymi towarami, zgodnie z ustaleniami.",
    shippingNote: "Uwaga dotycząca wysyłki",
    enterShippingNote: "Wpisz notę wysyłkową",
    shippingAddress: "Adres wysyłki",
    enterShippingAddress: "Podaj adres wysyłkowy",
    wellDoneYouAreDueToRedeem: "Bardzo dobrze! Masz do wykupu",
    toGetYourRewardNextVisit:
      "aby otrzymać nagrodę podczas następnej wizyty. Dziękuję Ci",
    pointsOnThe: "Punkty na",
    morePointsToRedeem: "więcej punktów do odebrania",
    showCode: "Pokaż kod",
    toGetYourRewardOnNextVisit:
      "aby otrzymać nagrodę podczas następnej wizyty. Dziękuję Ci",
    earn: "Zarabiać",
    delivaryNote: "Uwaga dotycząca dostawy",
    draftSales: "Projekt sprzedaży",
    startDate: "Data rozpoczęcia",
    endDate: "Data końcowa",
    orders: "Zamówienia",
    checkout: "Sprawdź",
    noProductItem: "Brak pozycji produktu",
    selectProductImage: "Wybierz obraz produktu",
    selectCountry: "Wybierz kraj",
    selectRegion: "Wybierz stan/region",
    printProductTag: "Wydrukuj tag produktu",
    transactionReference: "Odniesienie do transakcji",
    Cashier: "Kasjer",
    Manager: "Menedżer",
    Owner: "Właściciel",
    Admin: "Administrator",
    addPartners: "Dodaj partnerów",
    addNewLoyaltyPartner: "Dodaj nowego partnera lojalnościowego",
    pleaseEnterCompanyName: "Proszę podać nazwę firmy",
    companyName: "Nazwa firmy",
    pleaseEnterCompanyRepName:
      "Proszę podać imię i nazwisko przedstawiciela firmy",
    companyRepName: "Imię i nazwisko przedstawiciela firmy",
    pleaseEnterCompanyRepEmail: "Proszę wpisać e-mail przedstawiciela firmy",
    companyRepEmail: "E-mail przedstawiciela firmy",
    pleaseEnterCompanyRepPhone:
      "Proszę podać numer telefonu przedstawiciela firmy",
    companyRepPhone: "Numer telefonu przedstawiciela firmy",
    addReward: "Dodaj nagrodę",
    pleaseEnterRewardName: "Proszę podać nazwę nagrody",
    rewardName: "Nazwa nagrody",
    rewardQuantity: "Ilość nagrody",
    rewardDescription: "Opis nagrody",
    rewardType: "Rodzaj nagrody",
    pleaseEnterRewardType: "Proszę podać rodzaj nagrody",
    pleaseEnterRewardQuantity: "Podaj ilość nagrody",
    pleaseEnterRewardDescription: "Wprowadź opis nagrody",
    fineDining: "Dobra jadalnia",
    luxuryFashion: "Luksusowa moda",
    hotels: "Hotele",
    travel: "Podróż",
    foodAndBeverage: "Żywność i napoje",
    fashion: "Moda",
    health: "Zdrowie",
    furniture: "Meble",
    entertainment: "Zabawa",
    automobile: "Samochód",
    education: "Edukacja",
    beautyAndSpa: "Uroda i Spa",
    staycation: "Pobyt",
    events: "Wydarzenia",
    trips: "Wycieczki",
    oilAndGas: "Olej i gaz",
    laundry: "Pranie",
    partnerCategory: "Kategoria partnera",
    freeItem: "Bezpłatna pozycja",
  },
  Persian: {
    cashier: "صندوقدار",
    manager: "مدیر",
    owner: "مالک",
    online: "برخط",
    offline: "آفلاین",
    changePassword: "تغییر رمز عبور",
    currentPasswordMessage: "لطفاً رمز فعلی خود را وارد کنید",
    passwordMessage: "لطفا رمز عبور خود را وارد کنید",
    currentPassword: "رمز عبور فعلی",
    password: "کلمه عبور",
    confirmPasswordMessage: "لطفاً رمز عبور خود را تأیید کنید!",
    confirmPassword: "رمز عبور را تأیید کنید",
    sendViaEmail: "ارسال از طریق ایمیل",
    sendViaWhatsapp: "از طریق واتساپ ارسال کنید",
    downloadPdf: "PDF را بارگیری کنید",
    paid: "پرداخت شده",
    unpaid: "بدون پرداخت",
    partial: "جزئي",
    closeInvoice: "آیا می خواهید فاکتور را ببندید؟",
    closeInvoiceConfirmation: "ممکن است فاکتور ذخیره نشود. میخوای ببندی؟",
    yes: "آره",
    no: "نه",
    invoice: "صورتحساب",
    wasDeducted: "کسر شد",
    for: "برای",
    item: "مورد",
    addProduct: "افزودن محصول",
    paymentReference: "مرجع پرداخت",
    amountPaid: "مبلغ پرداخت شده",
    discountAmount: "مبلغ تخفیف",
    amountDue: "مبلغ بدهی",
    amount: "میزان",
    dueDate: "سررسید",
    paymentType: "نوع پرداخت",
    card: "کارت",
    cash: "پول نقد",
    bankTransfer: "انتقال بانکی",
    paymentMessage: "پیام پرداخت",
    description: "شرح",
    sendReceipt: "ارسال رسید",
    delete: "حذف",
    save: "صرفه جویی",
    resend: "ارسال مجدد",
    saveAndSend: "صرفه جویی",
    invoiceSaved: "فاکتور ذخیره شد!",
    selectPaymentMethod: "لطفا روش پرداخت را انتخاب کنید!",
    selectCustomer: "لطفا مشتری انتخاب کنید!",
    cartEmptyError:
      "لیست سبد خرید نمی تواند خالی باشد ، فاکتور را ببندید و مورد را به سبد خرید اضافه کنید!",
    subscriptionExpired:
      "اشتراک شما به پایان رسیده است و حساب شما اکنون محدود است. برای تمدید حساب خود روی دکمه زیر کلیک کنید",
    renew: "تمدید کنید",
    holdOn: "صبر کن",
    customerBank: "بانک مشتری",
    cancel: "لغو کنید",
    selectACustomer: "مشتری را انتخاب کنید",
    invoiceSuccessfulPdfError:
      "فاکتور با موفقیت ایجاد شد اما تولید PDF بیشتر از حد معمول طول می کشد. لطفاً به زودی دوباره بررسی کنید.",
    downloadingInvoice: "بارگیری فاکتور",
    downloadingReceipt: "بارگیری رسید",
    whatsappReceiptError:
      "هنگام ارسال رسید از طریق WhatsApp خطایی روی داد ، لطفاً دوباره امتحان کنید.",
    receiptToWhatsapp: "رسید به واتس اپ ارسال شد",
    thankYouForPatronage: "ممنون از حمایت شما",
    hereIsYourReceipt: "اینجا رسید پرداخت شماست",
    errorSendingEmailReceipt:
      "هنگام ارسال رسید از طریق ایمیل خطایی روی داد ، لطفاً دوباره امتحان کنید یا با پشتیبانی تماس بگیرید",
    receiptSentToEmail: "رسید به ایمیل مشتری ارسال شده است",
    invoiceSentToEmail: "فاکتور به ایمیل مشتری ارسال شده است",
    invoiceSuccessWhatsappError:
      "فاکتور با موفقیت ایجاد شد اما هنگام ارسال به WhatsApp خطایی روی داد. دوباره در لیست فاکتورها امتحان کنید",
    invoiceSuccessfulEmailError:
      "فاکتور با موفقیت ایجاد شد اما هنگام ارسال به عنوان ایمیل خطایی رخ داد. لطفاً دوباره از لیست فاکتورها امتحان کنید",
    invoiceSentToWhatsapp: "فاکتور به واتس اپ ارسال شد",
    hello: "سلام",
    pleaseDownloadInvoice: "لطفاً فاکتور را بارگیری کنید",
    pleaseDownloadReceipt: "لطفا رسید را بارگیری کنید",
    from: "از جانب",
    email: "پست الکترونیک",
    upgrade: "ارتقا دهید",
    youAreOnFreePlan: "شما در یک برنامه رایگان هستید.",
    clickOn: "بر روی کلیک کنید",
    yourPlanInFewSteps: " اشتراک شما در چند مرحله سریع",
    to: "به",
    yourSubscriptionHasExpired:
      "اشتراک شما به پایان رسیده است و حساب شما اکنون محدود است.",
    days: "روزها",
    yourSubscriptionExpiresIn: "اشتراک Loystar شما در تاریخ منقضی می شود",
    createAcount: "ایجاد یک حساب کاربری",
    signIn: "ورود",
    continue: "ادامه هید",
    enterOtp: "پین OTP را وارد کنید",
    enterValidOtp: "لطفاً یک پین معتبر وارد کنید",
    pin: "پین",
    tokenSentToMail: "یک نشانه به ایمیل شما ارسال شده است",
    passwordResetSuccessful: "بازنشانی گذرواژه با موفقیت انجام شد",
    somethingWentWrong: "مشکلی پیش آمد!",
    resetPassword: "بازنشانی رمز عبور",
    iHaveResetCode: "من یک کد بازنشانی رمز دارم",
    pleaseEnterEmail: "لطفا ایمیل خود را وارد کنید",
    aTokenWillBeSentToEmail: "یک نشانه به ایمیل شما ارسال می شود",
    enterEmail: "ایمیل خود را وارد کنید",
    sendinYourToken: "ارسال رمز شما ...",
    makeSureItMatchesPassword: "مطمئن شوید که با رمز جدید شما مطابقت دارد",
    pleaseChooseNewPassword: "لطفاً یک رمز عبور جدید انتخاب کنید",
    chooseNewPassword: "رمز عبور جدیدی انتخاب کنید",
    enterNewPassword: "برای تأیید رمز جدید خود را وارد کنید",
    enterTokenSent: "توکنی که نامه شما ارسال شده است را وارد کنید",
    resetToken: "Reset Token",
    resettingPassword: "بازنشانی رمز عبور ...",
    signUp: "ثبت نام",
    adminSignInWithEmail:
      " مدیر با ایمیل وارد می شود در حالی که کارکنان با نام کاربری وارد می شوند.",
    pleaseEnterEmailOrUsername: "لطفا ایمیل یا نام کاربری خود را وارد کنید",
    emailOrUsername: "ایمیل یا نام کاربری",
    pleaseEnterPassword: "لطفا رمز عبور را وارد کنید",
    createAnAccount: "ایجاد یک حساب کاربری",
    forgotPassword: "رمز عبور را فراموش کرده اید؟",
    enterPhoneNumber: "شماره تلفن را وارد کنید",
    personalData: "اطلاعات شخصی",
    validateConfirmationCOde: "تأیید کد تأیید",
    pleaseEnterFirstName: "لطفا نام خود را وارد کنید",
    pleaseEnterPhoneNumber: "لطفا شماره تلفن خود را وارد کنید",
    pleaseEnterLastName: "لطفا نام خانوادگی خود را وارد کنید",
    pleaseEnterBusinessName: "لطفاً نام کسب و کار خود را وارد کنید",
    firstName: "نام کوچک",
    lastName: "نام خانوادگی",
    businessName: "نام کسب و کار",
    previous: "قبلی",
    next: "بعد",
    pleaseSelectBusinessCategory: "لطفاً دسته کسب و کار خود را انتخاب کنید",
    pleaseEnterValidEmail: "لطفا یک ایمیل معتبر وارد کنید",
    pleaseEnterPostCode: "لطفا کد پستی را وارد کنید",
    postCode: "کد پستی",
    phoneNumberInUse: "این شماره تلفن قبلاً در حال استفاده است!",
    emailInUse: "این ایمیل در حال استفاده است!",
    foodAndDrinks: "غذا و نوشیدنی",
    salonAndBeauty: "سالن و زیبایی",
    fashionAndAccessories: "مد و لوازم جانبی",
    gymAndFitness: "ورزش و تناسب اندام",
    travelAndHotel: "سفر و هتل",
    homeAndGifts: "خانه و هدایا",
    washingAndCleaning: "شستشو و نظافت",
    gadgetsAndElectronics: "گجت ها و الکترونیک",
    groceries: "مواد غذایی",
    others: "دیگران",
    submit: "ارسال",
    accountCreatedSuccessful: "حساب شما با موفقیت ساخته شد.",
    pleaseEnterAddress: "لطفا آدرس خود را وارد کنید",
    addressLine1: "خط آدرس 1",
    addressLine2: "خط آدرس 2",
    choosePassword: "رمز را انتخاب کنید",
    passwordMustBe6Characters: "رمز عبور باید حداقل 6 نویسه باشد.",
    howDidYouHearLoystar: "چطور درباره Loystar شنیدید؟",
    referralCode: "کد ارجاع",
    byClickingTheButton: " با کلیک روی دکمه زیر ، موافقت می کنید",
    termsAndSevice: "مقررات",
    wereCreatingAccount: "ما حساب شما را ایجاد می کنیم",
    proceed: "ادامه دهید",
    verificationCodeMustBe6: "کد تأیید باید 6 رقمی باشد",
    pleaseEnter6DigitCode: "لطفاً کد 6 رقمی را وارد کنید",
    enterVerificationCode: "کد تایید را وارد کنید",
    resendToken: "ارسال مجدد توکن",
    verify: "تأیید کنید",
    transactions: "معاملات",
    todaySales: "فروش امروز",
    salesHistory: "سابقه فروش",
    supplyHistory: "تاریخچه عرضه",
    new: "جدید",
    invoices: "فاکتورها",
    disbursements: "وجوه پرداختی",
    offlineSales: "فروش آفلاین",
    products: "محصولات",
    customers: "مشتریان",
    multiLevelLoyalty: "وفاداری چند سطحی",
    loyaltyPrograms: "برنامه های وفاداری",
    members: "اعضا",
    appStore: "فروشگاه برنامه",
    orderMenu: "منوی سفارش",
    settings: "تنظیمات",
    staffAndBranches: "کارکنان و شعب",
    myAccount: "حساب من",
    switchToSellMode: "به حالت فروش بروید",
    signOut: "خروج از سیستم",
    getFreeSubscription: "دریافت اشتراک رایگان",
    onlyNumbersAllowed: "فقط اعداد مجاز است",
    yourAccountMustBe10Digits: "شماره حساب شما باید یک عدد 10 رقمی باشد",
    yourBvnMustBe11: "BVN شما باید یک عدد 11 رقمی باشد",
    pleaseSelectBank: "لطفا بانک خود را انتخاب کنید",
    selectYourBank: "بانک خود را انتخاب کنید",
    enterBankAccountNumber: "شماره حساب بانکی را وارد کنید",
    enterBvnNumber: "BVN خود را وارد کنید",
    connectBankAccount: "اتصال حساب بانکی",
    passwordResetSuccessfulAndSignOut:
      "رمز عبور شما با موفقیت بازنشانی شد. برای ورود مجدد روی دکمه Sign Out در زیر کلیک کنید",
    enterCurrentPassword: "رمز عبور فعلی را وارد کنید",
    pleaseEnterCurrentPassword: "لطفاً رمز فعلی را وارد کنید",
    phoneNumber: "شماره تلفن",
    sex: "رابطه ی جنسی",
    dateOfBirth: "تاریخ تولد",
    state: "دولت",
    country: "کشور",
    loyaltyId: "شناسه وفاداری",
    createdAt: "ایجاد شده در",
    noOfTransactionsMade: "تعداد معاملات انجام شده",
    yourDownloadWillStart: "بارگیری شما در یک لحظه شروع می شود",
    exportCustomers: "صادرات مشتریان",
    youhaveSuccessfullyToppedUp:
      "شما با موفقیت واحد SMS خود را تکمیل کرده اید.",
    youNowHave: "شما اکنون دارید",
    smsUnits: "واحدهای پیامکی",
    enterNumberOfSmsUnits:
      "تعداد واحدهای پیامکی که قصد خرید آنها را دارید وارد کنید",
    pleaseEnterANumericValue: "لطفاً مقدار عددی وارد کنید",
    pay: "پرداخت",
    accountEditedSuccessfully: "حساب شما با موفقیت ویرایش شد.",
    youAeCurrentlyOn: "شما در حال حاضر در",
    plan: "طرح",
    userData: "داده های کاربر",
    businessData: "اطلاعات کسب و کار",
    businessCategory: "تجارت کاترگوری",
    pleaseSelectCurrency: "لطفا واحد پول خود را انتخاب کنید",
    selectYourCurrency: "واحد پول خود را انتخاب کنید",
    purchaseMoreSmsUnits:
      "با استفاده از فرم زیر ، تعداد بیشتری واحد پیامک خریداری کنید",
    youHave: "شما دارید",
    atLeast4SmsUnitsRrequired:
      "برای تأیید حداقل 4 واحد پیامک لازم است ، لطفاً پر کنید!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'لطفاً حساب بانکی خود را تأیید کنید تا از الزامات "مشتری خود را بشناسید" (KYC) پیروی کنید. این به شما امکان می دهد پرداخت ها را از طریق USSD یا انتقال فوری جمع آوری کنید ، سفارشات را از مشتریان دریافت کرده و تسویه تراکنش را پردازش کنید. فقط برای تجار نیجریه ای قابل استفاده است. لطفاً برای شروع دکمه زیر را فشار دهید.',
    reConnectBankAccount: "اتصال مجدد حساب بانکی",
    accountName: "نام کاربری",
    accountNumber: "شماره حساب",
    bankName: "نام بانک",
    verified: "تأیید شد",
    accountDetails: "جزئیات حساب",
    kycBankAccount: "KYC",
    createTier: "ایجاد Tier",
    fileUploadSuccessful: "فایل با موفقیت بارگذاری شد",
    fileUploadFailed: "بارگذاری فایل ناموفق بود",
    createLoyaltyProgram: "ایجاد برنامه وفاداری",
    createLoyalty: "ایجاد وفاداری",
    name: "نام",
    loyaltyArtwork: "آثار هنری وفاداری",
    clickToUpload: "برای بارگذاری کلیک کنید",
    amountToPointsRatio: "میزان نسبت به امتیاز",
    points: "نکته ها",
    recommendedAmountToPointRatio:
      "توصیه می شود: 1 تا 1 امتیاز. یعنی به ازای هر 1 نایرا خرج شده ، مشتریان شما 1 امتیاز دریافت می کنند",
    pleaseTypeIn: "لطفا تایپ کنید",
    toDeleteLoyalty: "برای حذف این وفاداری",
    deleteLoyalty: "حذف وفاداری",
    toConfirm: "برای تایید",
    edit: "ویرایش کنید",
    pointsAwardedSuccessfully: "امتیازات با موفقیت اهدا شد",
    enterPointValueToAward:
      "مقدار نقطه ای را که می خواهید به مشتریان جایزه دهید وارد کنید",
    award: "جایزه",
    awardPointValuesToCustomer: "امتیاز جایزه به مشتری",
    enrollMembersToLoyalty: "عضویت در وفاداری",
    awardPoints: "اعطای امتیاز",
    enroll: "ثبت نام کنید",
    home: "صفحه اصلی",
    loyalty: "وفاداری",
    enrollCustomers: "ثبت نام مشتریان",
    selected: "انتخاب شد",
    customer: "مشتری",
    loyaltyActivationSuccessful: "فعال سازی وفاداری موفقیت آمیز است.",
    loyaltyDeactivationSuccessful: "غیرفعال سازی وفاداری با موفقیت انجام شد.",
    viewTier: "مشاهده ردیف",
    deactivate: "از کار انداختن",
    activate: "فعال کنید",
    actions: "اقدامات",
    nameOfLoyalty: "نام وفاداری",
    loyaltyStatus: "وضعیت وفاداری وضعیت وفاداری",
    numberOfTiers: "تعداد ردیف ها",
    createdOn: "ایجاد شده در",
    createATier: "ایجاد یک ردیف",
    stopCreatingTierConfirmation: "آیا می خواهید ایجاد لایه را متوقف کنید؟",
    stopEditingTierConfirmation: "آیا می خواهید ویرایش این ردیف را متوقف کنید؟",
    nameOfTier: "نام ردیف",
    minimumSpendingTarget: "حداقل هزینه هزینه",
    maximumSpendingTarget: "حداکثر هزینه هزینه",
    minimumSpendingTargetRequired: "حداقل هزینه مورد نیاز است",
    maximumSpendingTargetRequired: "حداکثر هدف هزینه مورد نیاز است",
    rewardSponsor: "حامی پاداش",
    pleaseChooseARewardSponsor: "لطفاً حامی پاداش را انتخاب کنید",
    self: "خود",
    partner: "شریک",
    rewardPartner: "پاداش شریک",
    pleaseSelectRewardPartner: "لطفاً شریک پاداش خود را انتخاب کنید",
    rewards: "پاداش",
    pleaseSelectAReward: "لطفاً پاداش را انتخاب کنید",
    instructionsOnRedeemingReward:
      "دستورالعمل هایی در مورد نحوه استفاده مشتری از پاداش",
    pleaseFillInThisField: "لطفا این قسمت را پر کنید!",
    toDeleteThisTier: " برای حذف این ردیف",
    deleteTier: "حذف لایه",
    viewMembers: "مشاهده اعضا",
    membersEnrolled: "اعضا ثبت نام کردند",
    instruction: "دستورالعمل",
    membersIn: "اعضا در",
    availableTiersInLoyalty: "ردیف های موجود در برنامه وفاداری",
    tiers: "ردیف ها",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "برنامه وفاداری موجود",
    totalLoyalties: "کل وفاداری ها",
    memberDetails: "جزئیات اعضا",
    nameOfCustomer: "نام مشتری",
    address: "نشانی",
    allEnrolledMembers: "همه اعضای ثبت نام شده",
    thisIsToWishYouHappyBirthday:
      "این برای شما آرزوی تولد بسیار مبارک و زندگی مرفه است. با تشکر از تمام آنچه که برای لوستر دارید. جشن ها مبارک!",
    inputAnOfferPlease: "لطفاً یک پیشنهاد وارد کنید",
    pleaseSelectTheInsertPoint:
      "لطفاً قسمت درج را در پیام انتخاب کرده و دوباره کلیک کنید",
    birthdayOfferAndMessage: "پیشنهاد و پیام تولد",
    birthdayOffer: "پیشنهاد تولد",
    birthdayMessage: "پیام تولد",
    noOfferFound: "پیشنهادی یافت نشد",
    settingABirthdayOffer:
      "تنظیم پیشنهاد تولد به مشتریان امکان می دهد این پیشنهاد را در روز تولد خود از طریق پیامک دریافت کنند",
    createOffer: "ایجاد پیشنهاد",
    whatIsTheOffer: "پیشنهاد چیست؟",
    editMessage: "ویرایش پیام",
    insert: "درج کنید",
    theNameOfCustomerInserted: "نام مشتری در اینجا درج می شود",
    theBirtdayOfferInserted: "پیشنهاد تولد در اینجا درج می شود",
    youSuccessfullyAddedNewBranch: "شما با موفقیت یک شاخه جدید اضافه کردید!",
    addNewBranch: "افزودن شعبه جدید",
    addBranch: "افزودن شعبه",
    additionalBranchWillIncur: "شعبه اضافی متحمل می شود",
    perBranch: "در هر شاخه",
    ecommerceBranchCosts: "هزینه های شعبه تجارت الکترونیک",
    pleaseEnterBranchName: "لطفاً نام شعبه را وارد کنید",
    pleaseEnterBranchAddress1: "لطفاً آدرس 1 شعبه را وارد کنید",
    enterBranchAddress1: "خط 1 شعبه را وارد کنید",
    enterBranchAddress2: "خط 1 شعبه را وارد کنید",
    pleaseEnterBranchAddress2: "لطفاً آدرس 2 شعبه را وارد کنید",
    enterBranchName: "نام شعبه را وارد کنید",
    successfullyAddedStaff: "شما با موفقیت یک کارمند جدید اضافه کردید!",
    addNewStaff: "اضافه کردن کارکنان جدید",
    addStaff: "اضافه کردن کارکنان",
    additionalStaffWillIncur: "کارکنان اضافی متحمل می شوند",
    perStaff: "به ازای هر پرسنل",
    pleaseEnterStaffEmail: "لطفاً ایمیل کارکنان را وارد کنید",
    pleaseEnterStaffUsername: "لطفاً نام کاربری کارکنان را وارد کنید",
    pleaseEnterStaffPassword: "لطفاً رمز کارکنان را وارد کنید",
    pleaseSelectStaffRole: "لطفاً نقش کارکنان را انتخاب کنید",
    selectStaffRole: "نقش کارکنان را انتخاب کنید",
    enterStaffEmail: "ایمیل کارکنان را وارد کنید",
    enterStaffUsername: "نام کاربری کارکنان را وارد کنید",
    enterStaffPassword: "رمز کارکنان را وارد کنید",
    spacesNotAllowedInUsername: "فاصله در نام کاربری مجاز نیست",
    admin: "مدیر",
    pleaseSelectBusinessToAttachStaff:
      "لطفاً برای پیوستن کارکنان ، مشاغل را انتخاب کنید",
    searchForBranchToAttachStaff: "برای پیوستن کارکنان به دنبال شعبه باشید",
    username: "نام کاربری",
    role: "نقش",
    areYouSureToDeleteThis: "آیا مطمئن هستید که این را حذف می کنید",
    branches: "شاخه ها",
    upgradeYourPlan: "برنامه خود را ارتقا دهید",
    add: "اضافه کردن",
    addNew: "جدید اضافه کن",
    customerWithEmailAlreadyExists:
      "مشتری با ایمیل/شماره تلفن در حال حاضر وجود دارد!",
    successfullyAddedNewCustomer: "مشتری جدیدی را با موفقیت اضافه کردید!",
    addCustomer: "افزودن مشتری",
    pleaseEnterCustomerFirstName: "لطفاً نام مشتری را وارد کنید",
    pleaseEnterCustomerLastName: "لطفاً نام خانوادگی مشتری را وارد کنید",
    pleaseEnterCustomerPhoneNumber: "لطفاً شماره تلفن مشتری را وارد کنید",
    pleaseEnterCustomerEmail: "لطفاً ایمیل مشتری را وارد کنید",
    pleaseEnterCustomerAddressLine: "لطفاً خط آدرس مشتری را وارد کنید",
    pleaseEnterCustomerOtherAddress: "لطفاً آدرس دیگر مشتری را وارد کنید",
    pleaseSelectCustomerGender: "لطفا جنسیت مشتری را انتخاب کنید",
    gender: "جنسیت",
    male: "نر",
    female: "زن",
    bank: "بانک",
    selectBank: "بانک را انتخاب کنید",
    stateOrRegionOrProvince: "ایالت/منطقه/استان",
    customerNotes: "یادداشت های مشتری",
    sendSms: "ارسال پیامک",
    editCustomer: "ویرایش مشتری",
    redeemReward: "استفاده از پاداش",
    issueLoyaltyCard: "صدور کارت وفاداری",
    deleteCustomer: "حذف مشتری",
    youveSuccessfullyAssignedLoyaltyMembership:
      "شما با موفقیت یک شناسه عضویت وفاداری تعیین کرده اید",
    noMembershipIdAvailable:
      "شناسه عضویت در دسترس نیست لطفاً با ما در hello@loystar.co تماس بگیرید",
    sendEmail: "ایمیل بفرست",
    membershipPoints: "امتیازهای عضویت",
    customerDetails: "اطلاعات مشتری",
    close: "نزدیک",
    loyaltyBalance: "تراز وفاداری",
    pointsBalance: "موجودی امتیاز",
    starBalance: "ترازوی ستاره",
    requiredPoints: "نکات مورد نیاز",
    requiredStars: "ستاره های مورد نیاز",
    reddemCode: "بازخرید کد",
    toDeleteThisCustomer: "برای حذف این مشتری",
    customerHasBeenDeletedSuccessfully: "مشتری با موفقیت حذف شد!",
    customerWithPhoneAlreadyExists: "مشتری با شماره تلفن در حال حاضر وجود دارد",
    customerWasSuccessfullyEdited: "مشتری با موفقیت ویرایش شد",
    anErrorOccured: "خطایی روی داد",
    phoneNumberIncludeCountry: "شماره تلفن (شامل کد کشور)",
    yourFileQueuedForUpload:
      "فایل شما در صف بارگذاری قرار گرفته است. لطفاً صفحه را بعد از چند ثانیه تازه کنید.",
    thereWasAnErrorPerformingOperation: "هنگام انجام عملیات خطایی روی داد!",
    pleaseSelectFile: "لطفاً یک فایل را انتخاب کنید!",
    oopsOnlyCsvFilesAllowed:
      "وای! فقط فایل های CSV مجاز است. لطفاً یک فایل .csv بارگذاری کنید.",
    fileShouldBeSmallerThan5Mb:
      "حجم فایل باید کمتر از 5 مگابایت باشد. اگر فایل بزرگتری دارید ، لطفاً به support@loystar.co ایمیل بزنید. متشکرم",
    customerFirstNameIsRequired: "نام مشتری در ردیف الزامی است",
    customerPhoneNumberIsRequired: "شماره تلفن مشتری در ردیف مورد نیاز است",
    importCustomers: "وارد کردن مشتریان",
    upload: "بارگذاری",
    clickIconToDownloadCsv:
      "برای بارگیری قالب فایل CSV روی این نماد کلیک کنید.",
    getGoogleSheetVersion: "نسخه ورق Google را دریافت کنید",
    clickOrDragFileToUpload:
      "برای بارگذاری ، فایل را به این منطقه بکشید یا بکشید",
    missingOrInvalidColumnHeader:
      "سرصفحه ستون وجود ندارد یا نامعتبر است. لطفاً قالب قالب را دنبال کنید. متشکرم.",
    youHaveImported: "شما وارد کرده اید",
    youSuccessfullyRedeemedReward:
      "شما با موفقیت از پاداش خود استفاده کرده اید!",
    sixDigitCode: "کد شش رقمی",
    pleaseEnterCustomerRewardCode: "لطفاً کد پاداش مشتری را وارد کنید",
    enterRewardCode: "کد پاداش را وارد کنید. (کد پاداش به حروف بزرگ حساس است)",
    selectLoyaltyProgram: "برنامه وفاداری را انتخاب کنید",
    stamps: "تمبرها",
    smsUnitsLow: "واحدهای پیام کوتاه",
    pleaseTopUpSmsUnits: "لطفاً واحدهای پیامکی را پر کنید",
    smsSuccessfullySentForDelivery: "پیامک با موفقیت برای تحویل ارسال شد!",
    sendSmsTo: "ارسال پیامک به",
    allCustomers: "همه مشتریان",
    unitsAvailable: "واحدهای موجود",
    pleaseTypeInTheMessage: "لطفا پیام را تایپ کنید",
    message: "پیام",
    charactersRemaining: "شخصیت های باقی مانده",
    avoidUseOfSpecialCharacters:
      "برای حفظ واحدهای پیامکی از استفاده از کاراکترها و ایموجی های ویژه خودداری کنید.",
    note: "توجه داشته باشید",
    errorFetchingCustomersMultilevelDetail:
      "خطا در واکشی اطلاعات مشتریان چند سطحی",
    search: "جستجو کردن",
    reset: "بازنشانی",
    importCustomer: "وارد کردن مشتری",
    addNewCustomer: "افزودن مشتری جدید",
    sendSmsBroadcast: "ارسال پیامک پخش",
    totalCustomers: "کل مشتریان",
    disbursementDetails: "جزئیات پرداخت",
    paidBy: "پرداخت شده توسط",
    disbursed: "پرداخت شده",
    bankAccountName: "نام حساب بانکی",
    bankAccountNumber: "شماره حساب بانکی",
    transferInitiated: "انتقال آغاز شد",
    transferCompleted: "انتقال تکمیل شد",
    pleaseEnterAValid: "لطفاً یک نام معتبر وارد کنید",
    begin: "شروع",
    end: "پایان",
    date: "تاریخ",
    paymentDate: "تاریخ پرداخت",
    selectDisbursementDuration: "Disbursement Duration را انتخاب کنید",
    totalSettled: "کل تسویه شده",
    totalUnsettled: "در کل ناآرام",
    toDeleteThisSale: "برای حذف این فروش",
    draftSaleDeletedSuccessfully: "پیش نویس فروش با موفقیت حذف شد!",
    deleteSale: "حذف فروش",
    pleaseTypeInYourTotal: "لطفاً برای تأیید حذف کل خود را وارد کنید",
    billDetails: "جزئیات بیل",
    printBill: "چاپ بیل",
    servedBy: "خدمت توسط",
    total: "جمع",
    createdDate: "تاریخ ایجاد شده",
    createdTime: "زمان ایجاد شده",
    status: "وضعیت",
    loginSuccessful: "ورود با موفقیت انجام شد",
    pleaseWaitWhileWeConnectAccount:
      "لطفاً منتظر بمانید تا حساب شما را متصل کنیم",
    connectionFailedTryAgain: "ارتباط ناموفق بود. لطفا دوباره تلاش کنید.",
    connectionSuccessful: "اتصال موفقیت آمیز است",
    viewDetails: "دیدن جزئیات",
    enable: "فعال کنید",
    free: "رایگان",
    cost: "هزینه",
    visitWebsite: "به وب سایت مراجعه کنید",
    pleaseUpgradeYourPlanToPro:
      "لطفاً برنامه خود را به Pro Plus ارتقا دهید تا این برنامه فعال شود",
    connectYourBankAccount:
      "حساب بانکی خود را متصل کنید تا بتوانید پرداخت ها را دریافت کنید.",
    disable: "غیرفعال کنید",
    enableApp: "فعال کردن برنامه",
    addNewProductToInvoice: "افزودن محصول جدید به فاکتور",
    toDeleteThisInvoice: "برای حذف این فاکتور",
    invoiceDeletedSuccessfully: "فاکتور با موفقیت حذف شد!",
    deleteInvoice: "فاکتور را حذف کنید",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "شما با موفقیت یک پیام پرداخت فاکتور اضافه کردید!",
    pleaseEnterPaymentInstructions: "لطفا دستورالعمل پرداخت را وارد کنید",
    invoiceId: "شناسه فاکتور",
    paidDate: "تاریخ پرداخت",
    reference: "ارجاع",
    productAdded: "محصول اضافه شد",
    productOutOfStock: "موجودی موجود نیست. لطفا دوباره موجودی کنید",
    totalInvoices: "مجموع فاکتورها",
    totalPaidInvoices: "کل فاکتورهای پرداخت شده",
    totalUnpaidInvoices: "مجموع فاکتورهای پرداخت نشده",
    loyaltyProgramDeleted: "برنامه وفاداری حذف شد",
    thisLoyaltyProgramDeletedSuccessfully:
      "این برنامه وفاداری با موفقیت حذف شد",
    thisLoyaltyProgramEditedSuccessfully:
      "این برنامه وفاداری با موفقیت ویرایش شد",
    loyaltyProgramAddedSuccessfully: "برنامه وفاداری با موفقیت اضافه شد",
    loyaltyProgramEdited: "برنامه وفاداری ویرایش شد",
    loyaltyProgramAdded: "برنامه وفاداری اضافه شد",
    loyaltyDetails: "جزئیات وفاداری",
    doYouWantToCloseDialog: "آیا می خواهید این گفتگو را ببندید؟",
    pleaseEnterLoyaltyName: "لطفا نام وفاداری خود را وارد کنید",
    programType: "نوع برنامه",
    pleaseSelectProgramType: "لطفاً نوع برنامه را انتخاب کنید",
    simplePoints: "نکات ساده",
    stampsProgram: "برنامه تمبر",
    threshold: "آستانه",
    pleaseEnterLoyaltyThreshold: "لطفاً آستانه وفاداری را وارد کنید",
    reward: "جایزه",
    pleaseEnterLoyaltyReward: "لطفا پاداش وفاداری را وارد کنید",
    totalUserPoints: "مجموع امتیازات کاربر",
    totalUserStamps: "کل تمبرهای کاربر",
    spendingTarget: "هدف خرج کردن",
    spendingTargetHint1:
      "هدف خرج کردن این است که مشتری چقدر باید برای به دست آوردن پاداش هزینه کند. 1 ارزش ارز = 1 امتیاز",
    spendingTargetHint2:
      "هدف تمبرها تعداد تمبرهایی است که مشتری باید برای کسب پاداش جمع آوری کند. به عنوان مثال. 5",
    addNewLoyaltyProgram: "اضافه کردن برنامه وفاداری جدید",
    addLoyaltyProgram: "اضافه کردن برنامه وفاداری",
    loyaltyProgramType: "نوع برنامه وفاداری",
    pleaseSelectLoyaltyProgramType: "لطفاً نوع برنامه وفاداری را انتخاب کنید",
    nameOfProgram: "نام برنامه",
    pleaseEnterProgramName: "لطفا نام برنامه را وارد کنید",
    whatIsTheReward: "پاداش چیست؟",
    egNextPurchaseIsFree: "به عنوان مثال. خرید بعدی رایگان است",
    customerName: "نام مشتری",
    guestCustomer: "مشتری مهمان",
    orderConfirmedSuccessfully: "سفارش با موفقیت تأیید شد",
    orderCancelledSuccessfully: "سفارش با موفقیت لغو شد",
    confirmOrder: "تأیید سفارش",
    cancelOrder: "لغو سفارش",
    allOrders: "کلیه سفارشات",
    totalOrders: "مجموع سفارشات",
    doYouWantToAcceptOrder: "آیا می خواهید این سفارش را قبول کنید؟",
    doYouWantToCancelOrder: "آیا می خواهید این سفارش را لغو کنید؟",
    orderDetails: "جزئیات سفارش",
    orderCreatedAt: "سفارش ایجاد شده در",
    supplier: "تامین کننده",
    productName: "نام محصول",
    quantity: "تعداد",
    unitPrice: "قیمت واحد",
    receivedBy: "دریافت شده توسط",
    reportFrom: "گزارش از",
    totalSupplies: "کل لوازم",
    allRightsReserved: "همه حقوق محفوظ است",
    toDeleteThisTransaction: "برای حذف این تراکنش",
    transactionDeletedSuccessfully:
      "تراکنش با موفقیت حذف شد. موجودی به موجودی برگشت داده شد.",
    deleteTransaction: "حذف تراکنش",
    transactionDetails: "جزئیات معامله",
    printReceipt: "چاپ رسید",
    channel: "کانال",
    discount: "تخفیف",
    profit: "سود",
    discountedSales: "فروش با تخفیف",
    errorFetchingRecord: "خطا در واکشی رکورد",
    exportTransactions: "معاملات صادراتی",
    errorFetchingSalesRecord: "خطا در واکشی رکورد فروش Export.",
    totalSellingPrice: "قیمت کل فروش",
    totalCostPrice: "قیمت تمام شده",
    appliedDiscount: "تخفیف کاربردی",
    noOfItems: "تعداد موارد",
    sales: "حراجی",
    export: "صادرات",
    totalProfit: "سود کل",
    totalBalanceInPeriod: "موجودی کل در دوره",
    allTimeSales: "فروش تمام وقت",
    records: "سوابق",
    todaysSales: "فروش امروز",
    transaction: "معامله",
    youHaveExceededTotalNumberOfProducts:
      "شما از تعداد کل محصولات مجاز در برنامه خود فراتر رفته اید. برنامه خود را ارتقا دهید تا از محدودیت بیشتری برخوردار شوید.",
    youNeedToHaveLoyaltyProgram:
      "برای استفاده از این ویژگی باید برنامه وفاداری داشته باشید!",
    price: "قیمت",
    category: "دسته بندی",
    stockTracking: "ردیابی سهام",
    stockCount: "تعداد سهام",
    taxed: "اخذ مالیات",
    originalPrice: "قیمت اصلی",
    costPrice: "قیمت هزینه",
    unit: "واحد",
    productImage: "تصویر محصول",
    taxRate: "نرخ مالیات",
    taxType: "نوع مالیات",
    trackInventory: "پیگیری موجودی",
    variants: "انواع",
    hasVariants: "دارای انواع مختلف",
    importProduct: "واردات محصول",
    exportProducts: "صادرات محصولات",
    addNewProduct: "افزودن محصول جدید",
    viewCategory: "مشاهده دسته بندی",
    viewSuppliers: "مشاهده تامین کنندگان",
    receiveInventory: "دریافت موجودی",
    printAllProductsTag: "برچسب همه محصولات را چاپ کنید",
    deleteAll: "حذف همه",
    totalProducts: "کل محصولات",
    youveSuccessfullyAddedANewCategory:
      "شما با موفقیت دسته جدیدی را اضافه کردید",
    addNewCategory: "افزودن دسته جدید",
    addCategory: "افزودن دسته",
    categoryName: "نام دسته",
    pleaseEnterCategoryName: "لطفاً نام دسته را وارد کنید",
    stampsTarget: "تمبرها هدف",
    sendInventory: "ارسال موجودی",
    productDetails: "مشخصات محصول",
    youveSuccessfullyEditedThisCategory:
      "شما این دسته را با موفقیت ویرایش کردید",
    update: "به روز رسانی",
    categoryList: "لیست دسته بندی ها",
    categories: "دسته بندی ها",
    enterQuantityToUpdate: "مقدار را برای به روز رسانی وارد کنید",
    inventorySentSuccessfully: "موجودی با موفقیت ارسال شد!",
    updateInventory: "موجودی را به روز کنید",
    currentQuantity: "مقدار فعلی",
    pleaseEnterQuantityToAdd:
      "لطفاً مقداری را که می خواهید اضافه کنید وارد کنید",
    pleaseSelectABranch: "لطفاً یک شعبه را انتخاب کنید",
    searchForBranch: "جستجوی شعبه",
    youCantSendMoreThanStock:
      "شما نمی توانید بیش از آنچه در انبار دارید ارسال کنید",
    send: "ارسال",
    pleaseEnterQuantityToSend:
      "لطفاً مقداری را که می خواهید ارسال کنید وارد کنید",
    productNameIsRequiredOnRow: "نام محصول در ردیف الزامی است",
    productCategoryIsRequiredOnRow: "دسته محصول در ردیف مورد نیاز است",
    productPriceIsRequiredOnRow: "قیمت محصول در ردیف مورد نیاز است",
    productUnitIsRequiredOnRow: "واحد محصول در ردیف مورد نیاز است",
    productQuantityIsRequiredOnRow: "مقدار محصول در ردیف مورد نیاز است",
    productVariantsRequireTracking: "انواع محصول نیاز به ردیابی دارد!",
    pleaseAddVariantClickButton:
      "لطفاً با کلیک روی دکمه افزودن ، یک نوع اضافه کنید!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Variants بیش از مقدار محصول انتخاب شده است ، Pls مقدار انواع را کاهش می دهد",
    productEditedSuccessfully: "محصول با موفقیت ویرایش شد!",
    fileTooLargeLessThan4Mb:
      "حجم فایل خیلی بزرگ است! حجم فایل باید کمتر از 4 مگابایت باشد.",
    suchVariantAlreadyExist: "چنین گونه ای در حال حاضر وجود دارد",
    addVariants: "افزودن گزینه ها",
    editProduct: "ویرایش محصول",
    pleaseEnterProductName: "لطفا نام محصول را وارد کنید",
    pleaseEnterProductPrice: "لطفا قیمت محصول را وارد کنید",
    pleaseEnterProductOriginalPrice: "لطفا قیمت اصلی محصول را وارد کنید",
    productDescription: "توضیحات محصول",
    selectProductCategory: "دسته بندی محصول را انتخاب کنید",
    pleaseSelectProductCategory: "لطفاً دسته بندی محصول را انتخاب کنید",
    productCostPrice: "قیمت تمام شده محصول",
    productSellingPrice: "قیمت فروش محصول",
    productOriginalPrice: "قیمت اصلی محصول",
    maxFileSizeAllowedIs4Mb: "حداکثر حجم فایل مجاز 4 مگابایت است",
    productsWithPicturesArePublished:
      "برای دریافت سفارشات ، محصولات با تصویر در Discover منتشر می شوند",
    shouldThisProductBeTracked: "آیا این محصول باید ردیابی شود؟",
    pleaseSelectStockUnit: "لطفا واحد سهام را انتخاب کنید",
    stockUnit: "واحد سهام",
    bag: "کیسه",
    bottle: "بطری",
    bunch: "دسته",
    can: "می توان",
    carton: "کارتن",
    crate: "CRATE",
    cup: "CUP",
    dozen: "دوزن",
    gigabytes: "گیگابایت",
    gram: "گرام",
    kilogram: "KILOGRAM",
    litre: "لیتر",
    pack: "بسته",
    pair: "جفت",
    pieces: "قطعات",
    plate: "صفحه",
    tonne: "تن (MT)",
    uNIT: "واحد",
    yard: "حیاط",
    pleaseEnterProductQuantity: "لطفا مقدار محصول را وارد کنید",
    productQuantity: "مقدار محصول",
    isThisProductTaxed: "آیا این محصول مشمول مالیات می شود؟",
    selectTaxType: "نوع مالیات را انتخاب کنید",
    pleaseSelectTaxType: "لطفاً نوع مالیات را انتخاب کنید",
    progressive: "ترقی خواه",
    proportional: "متناسب",
    pleaseEnterProductTaxRate: "لطفاً نرخ مالیات محصول را وارد کنید",
    doesProductHaveVariants: "آیا این محصول انواع مختلفی دارد؟",
    type: "تایپ کنید",
    value: "ارزش",
    pleaseEnterVariantType: "لطفا نوع نوع را وارد کنید",
    pleaseEnterVariantValue: "لطفاً مقدار نوع را وارد کنید",
    pleaseEnterVariantPrice: "لطفا قیمت نوع را وارد کنید",
    pleaseEnterVariantQuantity: "لطفاً مقدار نوع را وارد کنید",
    productDeletedSuccessfully: "محصول با موفقیت حذف شد!",
    categoryDeletedSuccessfully: "دسته با موفقیت حذف شد!",
    toDeleteThisProduct: "برای حذف این محصول",
    invalidProductQuantity: "مقدار محصول نامعتبر است",
    quantityAddedIsOverStock:
      "مقداری که اضافه می کنید بیش از آن چیزی است که در انبار دارید.",
    itemInventoryNotTracked: "موجودی مورد پیگیری نمی شود",
    addBulkQuantity: "اضافه کردن مقدار انبوه",
    enterBulkQuantity: "مقدار عمده را وارد کنید",
    pleaseEnterBulkQuantity: "لطفاً مقدار عمده را وارد کنید",
    youveSuccessfullyAddedANewProduct:
      "شما با موفقیت محصول جدیدی را اضافه کرده اید!",
    pleaseEnterProductSellingPrice: "لطفا قیمت فروش محصول را وارد کنید",
    doYouWantToTrackProductStock:
      "آیا می خواهید موجودی محصولات را ردیابی کنید؟",
    sellingPrice: "قیمت فروش",
    setProductExpiryReminder: "تنظیم یادآوری انقضای محصول",
    productExpiryDate: "تاریخ انقضاء محصول",
    startRemindingFrom: "شروع به یادآوری از",
    productSuppliesAddedSuccessfully:
      "شما منابع محصول را با موفقیت اضافه کرده اید.",
    addProductSupplies: "لوازم مورد نیاز محصول را اضافه کنید",
    pleaseSelectSupplier: "لطفاً تامین کننده را انتخاب کنید",
    nameOfProduct: "نام محصول",
    pleaseSelectProduct: "لطفاً محصول را انتخاب کنید",
    productVariant: "نوع محصول",
    pleaseSelectAVariant: "لطفاً A Variant را انتخاب کنید",
    pleaseEnterUnitPrice: "لطفا قیمت واحد را وارد کنید",
    businessBranch: "شعبه بازرگانی",
    pleaseSelectBranch: "لطفاً شعبه را انتخاب کنید",
    youveSuccessfullyAddedANewSupplier:
      "شما با موفقیت یک تامین کننده جدید اضافه کردید",
    addSupplier: "افزودن تامین کننده",
    pleaseEnterSupplierEmail: "لطفاً ایمیل تأمین کننده را وارد کنید",
    pleaseAddADescription: "لطفاً توضیحات را اضافه کنید",
    anErrorOccuredProductsDeleted:
      "هنگام انجام عملیات خطایی روی داد. لطفاً توجه داشته باشید ، ممکن است تعداد کمی از محصولات در این فرآیند حذف شده باشند",
    bulkDelete: "حذف انبوه",
    youAreAboutToDelete: "در شرف حذف هستید",
    product: "تولید - محصول",
    isDueToRedeem: "به دلیل بازخرید است",
    aReward: "یک پاداش",
    pleaseSelectCustomerToReeemReward:
      "لطفاً مشتری را برای استفاده از پاداش انتخاب کنید.",
    youHaveNoLoyaltyProgramRunning:
      "شما هیچ برنامه وفاداری فعال در حال اجرا ندارید",
    customerHhasNoPointsInLoyaltyProgram:
      "مشتری در این برنامه وفاداری امتیاز ندارد",
    proceedWithPayment: "با پرداخت ادامه دهید؟",
    youAreAboutToPayForTransactionUsingPoints:
      "شما در حال پرداخت هزینه معامله با استفاده از امتیاز هستید.",
    customerHas: "مشتری دارد",
    worth: "ارزش",
    andIsNotSufficientToPayForTransaction:
      "و برای پرداخت این معامله کافی نیست. آیا آنها می خواهند موجودی را با استفاده از روش پرداخت دیگری اضافه کنند؟",
    addCustomerLoyalty: "اضافه کردن وفاداری مشتری",
    pleaseAddCustomerFirst: "لطفاً ابتدا مشتری را اضافه یا انتخاب کنید.",
    pleaseWaitWhileWeGetReady: "لطفاً منتظر بمانید تا ما آماده می شویم",
    lowStock: "سهام کم",
    pleaseEnterAmountTendered: "لطفا مبلغ پیشنهادی را وارد کنید",
    areYouSureToBookSaleOffline:
      "آیا مطمئن هستید که می خواهید این فروش را بصورت آفلاین رزرو کنید؟",
    saleWouldBeBookedAutomatically:
      "وقتی اینترنت خود را روشن می کنید ، فروش به صورت خودکار رزرو می شود",
    offlineSalesBookingCancelled: "رزرو فروش آفلاین لغو شد",
    covid19Message:
      "COVID19: برای جلوگیری از گسترش ، دستان خود را با صابون بشویید یا ضد عفونی کنید. همیشه ایمن بمانید",
    saleSuccessfullyRecorded: "فروش با موفقیت ثبت شد!",
    sendReceiptToEmail: "رسید را به ایمیل ارسال کنید",
    sendThankYouSms: "ارسال پیامک تشکر",
    sendShippingDetails: "ارسال جزئیات حمل و نقل",
    addLoyalty: "اضافه کردن وفاداری",
    searchCustomerNameOrNumber: "نام یا شماره مشتری را جستجو کنید",
    clickTheSearchCustomerBox: "روی جعبه جستجوی مشتری و کارت اسکن کلیک کنید",
    enterProductPrice: "قیمت محصول را وارد کنید",
    enterPriceFor: "قیمت را وارد کنید",
    searchForProduct: "محصول را جستجو کنید",
    all: "همه",
    backToDashboard: "بازگشت به داشبورد",
    viewDraftSales: "مشاهده پیش نویس فروش",
    variantSelected: "نوع انتخاب شده",
    variant: "گونه",
    thePreviousVariantSelectionNotAvailable:
      "انتخاب نوع قبلی برای نوع جدید انتخاب شده بر اساس قیمت در دسترس نیست ، لطفاً انتخاب خود را تغییر دهید.",
    pleaseSupplyPositiveQuantityNumber: "لطفاً یک عدد کمیت مثبت ارسال کنید",
    lowStockFor: "موجودی کم برای",
    clearVariants: "انواع روشن",
    pleaseEnterQuantity: "لطفاً مقدار را وارد کنید",
    picture: "تصویر",
    redemptionToken: "نشان رستگاری",
    token: "توکن",
    totalSpent: "جمع هزینه",
    confirmPayment: "پرداخت را تایید کنید",
    hasPaymentBeenMade: "آیا پرداخت با موفقیت انجام شده است؟",
    enterTransactionReference:
      "مرجع معامله ای را که با آن پرداخت کرده اید وارد کنید",
    pleaseSelectACustomer: "لطفا مشتری را انتخاب کنید!",
    areYouSureToApplyDiscount:
      "آیا مطمئن هستید که می خواهید از تخفیف استفاده کنید؟",
    addYourBankAccountToEnableUssd:
      "برای فعال کردن انتقال فوری USSD توسط uPay ، حساب بانکی خود را اضافه کنید",
    totalAmountToPay: "مبلغ کل برای پرداخت",
    doYouWantToCancelTransaction: "آیا می خواهید این تراکنش را لغو کنید؟",
    savePrintBill: "ذخیره/چاپ صورتحساب",
    enterAmountCollectedForCustomer:
      "مقدار جمع آوری شده برای مشتری را وارد کنید",
    recordSale: "فروش ضبط",
    checkOutWith: "با بررسی کنید",
    instantTransfer: "انتقال فوری",
    dialTheUSSDCode: "کد USSD را شماره گیری کنید",
    pleaseSelectABank: "لطفاً یک بانک انتخاب کنید",
    payWithUSSD: "پرداخت با USSD",
    sendBillTo: " - ارسال بیل به",
    waitingForUSSDTransfer: "منتظر انتقال USSD باشید",
    percent: "درصد",
    applyDiscount: "اعمال تخفیف",
    thisBillHasBeenSaved: "این لایحه ذخیره شده است",
    saveDraft: "ذخیره پیش نویس",
    pleaseTypeANameToIdentifyCustomer:
      "لطفاً نامی را وارد کنید تا مشتری مشخص شود",
    paymentDetails: "جزئیات پرداخت",
    basePrice: "قیمت پایه",
    staff: "پرسنل",
    subTotal: "فرعی",
    durationMonths: "مدت زمان (ماه)",
    selectADuration: "Duration را انتخاب کنید",
    oneMonth: "1 ماه",
    twoMonths: "2 ماه",
    threeMonths: "3 ماه",
    sixMonths: "6 ماه",
    twelveMonths: "12 ماه",
    eighteenMonths: "18 ماه",
    twentyFourMonths: "24 ماه",
    twoMonthsFree: "(2 ماه رایگان)",
    threeMonthsFree: "(3 ماه رایگان)",
    fiveMonthsFree: "(5 ماه رایگان)",
    yourAccountHasBeen: "حساب شما بوده است",
    renewed: "تجدید شده",
    upgraded: "ارتقا یافته",
    successfully: "با موفقیت",
    yourSubscription: "اشتراک شما",
    youAreCurrentlyEnrolledOnA: "شما در حال حاضر در a ثبت نام کرده اید",
    pleaseChooseAPaymentOption: "لطفاً گزینه پرداخت را انتخاب کنید",
    planRenewal: "تجدید برنامه",
    planUpgrade: "ارتقاء برنامه",
    pleaseSelectDurationToPayFor:
      "لطفاً مدت زمانی را که قصد دارید هزینه آن را بپردازید انتخاب کنید",
    staffAccounts: "حسابهای کارکنان",
    ecommerce: "تجارت الکترونیک",
    pleaseSelectAPlan: "لطفاً طرح را انتخاب کنید",
    includeAddons: "شامل افزونه ها باشد",
    viewTransactions: "مشاهده تراکنش ها",
    customerHasNoCompletedTansactions:
      "مشتری هنوز هیچگونه معامله کامل را انجام نداده است",
    branch: "شاخه",
    enterNumberOfEcommerceBranches: "تعداد شعب تجارت الکترونیک را وارد کنید",
    enterNumberOfEcommerceBranchesToPay:
      "تعداد شاخه های تجارت الکترونیکی را که قصد پرداخت آنها را دارید وارد کنید",
    ecommerceIntegration: "ادغام تجارت الکترونیک",
    enterNumberOfBranches: "تعداد شعب را وارد کنید",
    enterNumberOfAdditionalBranchesToPay:
      "تعداد شاخه های اضافی را که قصد پرداخت آنها را دارید وارد کنید",
    enterNumberOfStaffs: "تعداد پرسنل را وارد کنید",
    enterNumberOfStaffsToPayFor:
      "تعداد کارکنانی را که قصد پرداخت آنها را دارید وارد کنید",
    discountApplies: "تخفیف اعمال می شود",
    starsOnThe: "ستاره در",
    offer: "پیشنهاد",
    get: "گرفتن",
    moreStarsToRedeem: "ستاره های بیشتری برای بازخرید",
    taxVat: "مالیات (مالیات بر ارزش افزوده)",
    callCashierToCompletePayment: "برای تکمیل پرداخت با صندوقدار تماس بگیرید",
    receipt: "اعلام وصول",
    dear: "عزیز",
    thankYouForYourOrderFindGoods:
      "بابت سفارش شما متشکرم. لطفاً مطابق توافق ، کالاهای زیر را تهیه کنید.",
    shippingNote: "یادداشت حمل و نقل",
    enterShippingNote: "یادداشت حمل و نقل را وارد کنید",
    shippingAddress: "آدرس حمل و نقل",
    enterShippingAddress: "آدرس ارسال را وارد کنید",
    wellDoneYouAreDueToRedeem: "آفرین! قرار است بازخرید کنید",
    toGetYourRewardNextVisit:
      "تا در بازدید بعدی خود پاداش خود را دریافت کنید. متشکرم",
    pointsOnThe: "نکات روی",
    morePointsToRedeem: "امتیاز بیشتری برای استفاده",
    showCode: "نمایش کد",
    toGetYourRewardOnNextVisit:
      "تا در بازدید بعدی خود پاداش خود را دریافت کنید. متشکرم",
    earn: "بدست آوردن",
    delivaryNote: "یادداشت تحویل",
    draftSales: "پیش نویس فروش",
    startDate: "تاریخ شروع",
    endDate: "تاریخ پایان",
    orders: "سفارشات",
    checkout: "وارسی",
    noProductItem: "مورد محصول وجود ندارد",
    selectProductImage: "تصویر محصول را انتخاب کنید",
    selectCountry: "کشور را انتخاب کنید",
    selectRegion: "ایالت/منطقه را انتخاب کنید",
    printProductTag: "چاپ برچسب محصول",
    transactionReference: "مرجع معامله",
    Cashier: "صندوقدار",
    Manager: "مدیر",
    Owner: "مالک",
    Admin: "مدیر",
    addPartners: "شرکا را اضافه کنید",
    addNewLoyaltyPartner: "شریک وفاداری جدید اضافه کنید",
    pleaseEnterCompanyName: "لطفا نام شرکت را وارد کنید",
    companyName: "نام شرکت",
    pleaseEnterCompanyRepName: "لطفا نام نماینده شرکت را وارد کنید",
    companyRepName: "نام نماینده شرکت",
    pleaseEnterCompanyRepEmail: "لطفا ایمیل نماینده شرکت را وارد کنید",
    companyRepEmail: "ایمیل نماینده شرکت",
    pleaseEnterCompanyRepPhone: "لطفا شماره تلفن نماینده شرکت را وارد کنید",
    companyRepPhone: "شماره تلفن نماینده شرکت",
    addReward: "پاداش اضافه کنید",
    pleaseEnterRewardName: "لطفا نام جایزه را وارد کنید",
    rewardName: "نام جایزه",
    rewardQuantity: "مقدار پاداش",
    rewardDescription: "شرح پاداش",
    rewardType: "نوع پاداش",
    pleaseEnterRewardType: "لطفا نوع پاداش را وارد کنید",
    pleaseEnterRewardQuantity: "لطفا مقدار پاداش را وارد کنید",
    pleaseEnterRewardDescription: "لطفا توضیحات پاداش را وارد کنید",
    fineDining: "ناهار خوری خوب",
    luxuryFashion: "مد لوکس",
    hotels: "هتل ها",
    travel: "مسافرت رفتن",
    foodAndBeverage: "غذا و نوشیدنی",
    fashion: "روش",
    health: "سلامتی",
    furniture: "مبلمان",
    entertainment: "سرگرمی",
    automobile: "خودرو",
    education: "تحصیلات",
    beautyAndSpa: "زیبایی و اسپا",
    staycation: "اقامت",
    events: "مناسبت ها",
    trips: "سفرها",
    oilAndGas: "نفت و گاز",
    laundry: "خشکشویی",
    partnerCategory: "دسته شریک",
    freeItem: "آیتم رایگان",
  },
  Portuguese: {
    cashier: "caixa",
    manager: "Gerente",
    owner: "proprietário",
    online: "conectados",
    offline: "desligada",
    changePassword: "Mudar senha",
    currentPasswordMessage: "Por favor digite sua senha atual",
    passwordMessage: "Por favor, insira sua senha",
    currentPassword: "Senha atual",
    password: "senha",
    confirmPasswordMessage: "Por favor, confirme sua senha!",
    confirmPassword: "Confirme a Senha",
    sendViaEmail: "Enviar via e-mail",
    sendViaWhatsapp: "Enviar via WhatsApp",
    downloadPdf: "baixar PDF",
    paid: "pago",
    unpaid: "não pago",
    partial: "parcial",
    closeInvoice: "Quer fechar a Nota Fiscal?",
    closeInvoiceConfirmation: "A fatura não pode ser salva. Você quer fechar?",
    yes: "sim",
    no: "não",
    invoice: "Fatura",
    wasDeducted: "foi deduzido",
    for: "para",
    item: "Item",
    addProduct: "Adicionar Produto",
    paymentReference: "Referência de pagamento",
    amountPaid: "Quantia paga",
    discountAmount: "Valor do Desconto",
    amountDue: "Quantia devida",
    amount: "Quantia",
    dueDate: "Data de vencimento",
    paymentType: "Tipo de pagamento",
    card: "Cartão",
    cash: "Dinheiro",
    bankTransfer: "Transferência bancária",
    paymentMessage: "Mensagem de Pagamento",
    description: "Descrição",
    sendReceipt: "Enviar recibo",
    delete: "Excluir",
    save: "Salve ",
    resend: "Reenviar",
    saveAndSend: "Salve ",
    invoiceSaved: "Fatura salva!",
    selectPaymentMethod: "Selecione um método de pagamento!",
    selectCustomer: "Selecione um cliente!",
    cartEmptyError:
      "A lista do carrinho não pode ficar vazia, feche a fatura e adicione o item ao carrinho!",
    subscriptionExpired:
      "Sua assinatura expirou e sua conta agora está limitada. Clique no botão abaixo para renovar sua conta",
    renew: "Renovar",
    holdOn: "Aguentar",
    customerBank: "Banco do Cliente",
    cancel: "Cancelar",
    selectACustomer: "Selecione o cliente",
    invoiceSuccessfulPdfError:
      "A fatura foi criada com sucesso, mas a geração do PDF está demorando mais do que o normal. Verifique novamente em breve.",
    downloadingInvoice: "Baixando fatura",
    downloadingReceipt: "Baixando recibo",
    whatsappReceiptError:
      "Ocorreu um erro ao enviar o recibo via WhatsApp, tente novamente.",
    receiptToWhatsapp: "Recibo encaminhado para WhatsApp",
    thankYouForPatronage: "Obrigado pelo seu patrocínio",
    hereIsYourReceipt: "Aqui está o seu comprovante de pagamento",
    errorSendingEmailReceipt:
      "Ocorreu um erro ao enviar o recibo por e-mail, tente novamente ou entre em contato com o suporte",
    receiptSentToEmail: "O recibo foi enviado para o e-mail do cliente",
    invoiceSentToEmail: "A fatura foi enviada para o e-mail do cliente",
    invoiceSuccessWhatsappError:
      "Fatura criada com sucesso, mas ocorreu um erro ao enviar para o WhatsApp. Tente novamente na lista de faturas",
    invoiceSuccessfulEmailError:
      "Fatura criada com sucesso, mas ocorreu um erro ao enviar como e-mail. Por favor, tente novamente a partir da lista de faturas",
    invoiceSentToWhatsapp: "Fatura encaminhada para WhatsApp",
    hello: "Olá",
    pleaseDownloadInvoice: "Faça o download da fatura",
    pleaseDownloadReceipt: "Faça o download do recibo",
    from: "a partir de",
    email: "O email",
    upgrade: "Melhoria",
    youAreOnFreePlan: "Você está em um plano gratuito.",
    clickOn: "Clique em",
    yourPlanInFewSteps: " sua assinatura em algumas etapas rápidas.",
    to: "para",
    yourSubscriptionHasExpired:
      "Sua assinatura expirou e sua conta agora está limitada.",
    days: "dias",
    yourSubscriptionExpiresIn: "Sua assinatura Loystar expira em",
    createAcount: "Crie a sua conta aqui",
    signIn: "Entrar",
    continue: "Prosseguir",
    enterOtp: "Digite o PIN OTP",
    enterValidOtp: "Por favor insira um PIN válido",
    pin: "ALFINETE",
    tokenSentToMail: "Um token foi enviado para o seu e-mail",
    passwordResetSuccessful: "A redefinição da senha foi bem-sucedida",
    somethingWentWrong: "Algo deu errado!",
    resetPassword: "Redefinir senha",
    iHaveResetCode: "Eu tenho um código de redefinição de senha",
    pleaseEnterEmail: "Por favor introduza o seu e-mail",
    aTokenWillBeSentToEmail: "Um token seria enviado para o seu e-mail",
    enterEmail: "Digite seu e-mail",
    sendinYourToken: "Enviando seu token ...",
    makeSureItMatchesPassword:
      "Certifique-se de que corresponde à sua nova senha",
    pleaseChooseNewPassword: "Escolha uma nova senha",
    chooseNewPassword: "Escolha uma nova senha",
    enterNewPassword: "Digite sua nova senha para confirmar",
    enterTokenSent: "Insira o token que foi enviado para seu e-mail",
    resetToken: "Reiniciar token",
    resettingPassword: "Redefinindo sua senha ...",
    signUp: "Inscrever-se",
    adminSignInWithEmail:
      " O administrador faz login com e-mail enquanto a equipe faz login com nome de usuário.",
    pleaseEnterEmailOrUsername:
      "Por favor, digite seu e-mail ou nome de usuário",
    emailOrUsername: "Email ou nome de usuário",
    pleaseEnterPassword: "Por favor insira a senha",
    createAnAccount: "Crie a sua conta aqui",
    forgotPassword: "Esqueceu sua senha?",
    enterPhoneNumber: "Digite o número do telefone",
    personalData: "Dados pessoais",
    validateConfirmationCOde: "Validar código de confirmação",
    pleaseEnterFirstName: "por favor entre com seu primeiro nome",
    pleaseEnterPhoneNumber: "Por favor, digite seu número de telefone",
    pleaseEnterLastName: "Por favor insira seu sobrenome",
    pleaseEnterBusinessName: "Por favor, insira o nome da sua empresa",
    firstName: "Primeiro nome",
    lastName: "Último nome",
    businessName: "Nome da empresa",
    previous: "Anterior",
    next: "Próximo",
    pleaseSelectBusinessCategory: "Selecione sua categoria de negócios",
    pleaseEnterValidEmail: "por favor digite um email válido",
    pleaseEnterPostCode: "Por favor, insira o código postal",
    postCode: "Código Postal",
    phoneNumberInUse: "Este número de telefone já está em uso!",
    emailInUse: "Este e-mail já está em uso!",
    foodAndDrinks: "Alimentos e Bebidas",
    salonAndBeauty: "Salão de beleza e beleza",
    fashionAndAccessories: "Moda e Acessórios",
    gymAndFitness: "Ginásio e Fitness",
    travelAndHotel: "Viagem e hotel",
    homeAndGifts: "Casa e presentes",
    washingAndCleaning: "Lavagem e Limpeza",
    gadgetsAndElectronics: "Gadgets e eletrônicos",
    groceries: "Mercearias",
    others: "Outros",
    submit: "Enviar",
    accountCreatedSuccessful: "Sua conta foi criada com sucesso.",
    pleaseEnterAddress: "Por favor, digite seu endereço",
    addressLine1: "Endereço Linha 1",
    addressLine2: "endereço linha 2",
    choosePassword: "Escolha a senha",
    passwordMustBe6Characters: "A senha deve ter pelo menos 6 caracteres.",
    howDidYouHearLoystar: "Como você soube do Loystar?",
    referralCode: "Código de Referencia",
    byClickingTheButton: " Ao clicar no botão abaixo, você concorda com os",
    termsAndSevice: "Termos",
    wereCreatingAccount: "Estamos criando sua conta",
    proceed: "Continuar",
    verificationCodeMustBe6: "O código de verificação deve ter 6 dígitos",
    pleaseEnter6DigitCode: "Digite o código de 6 dígitos",
    enterVerificationCode: "Digite o código de verificação",
    resendToken: "Reenviar token",
    verify: "Verificar",
    transactions: "Transações",
    todaySales: "Vendas de Hoje",
    salesHistory: "Histórico de Vendas",
    supplyHistory: "Histórico de Abastecimento",
    new: "Novo",
    invoices: "Faturas",
    disbursements: "Desembolsos",
    offlineSales: "Vendas offline",
    products: "Produtos",
    customers: "Clientes",
    multiLevelLoyalty: "Lealdade multinível",
    loyaltyPrograms: "Programas de fidelidade",
    members: "Membros",
    appStore: "Loja de aplicativos",
    orderMenu: "Menu de Pedidos",
    settings: "Definições",
    staffAndBranches: "Funcionários e filiais",
    myAccount: "Minha conta",
    switchToSellMode: "Mudar para o modo de venda",
    signOut: "Sair",
    getFreeSubscription: "Obtenha uma assinatura grátis",
    onlyNumbersAllowed: "Somente números são permitidos",
    yourAccountMustBe10Digits:
      "o número da sua conta deve ser um número de 10 dígitos",
    yourBvnMustBe11: "seu BVN deve ser um número de 11 dígitos",
    pleaseSelectBank: "Selecione seu banco",
    selectYourBank: "Selecione seu banco",
    enterBankAccountNumber: "Digite o número da conta bancária",
    enterBvnNumber: "Digite seu BVN",
    connectBankAccount: "Conectar conta bancária",
    passwordResetSuccessfulAndSignOut:
      "Sua senha foi redefinida com sucesso. Clique no botão Sair abaixo para entrar novamente",
    enterCurrentPassword: "Digite a senha atual",
    pleaseEnterCurrentPassword: "Por favor, digite a senha atual",
    phoneNumber: "Número de telefone",
    sex: "Sexo",
    dateOfBirth: "Data de nascimento",
    state: "Estado",
    country: "País",
    loyaltyId: "ID de fidelidade",
    createdAt: "Criado em",
    noOfTransactionsMade: "Nº de transações feitas",
    yourDownloadWillStart: "Seu download começará em um momento",
    exportCustomers: "Clientes de exportação",
    youhaveSuccessfullyToppedUp:
      "Você completou com sucesso suas unidades de SMS.",
    youNowHave: "Você agora tem",
    smsUnits: "Unidades SMS",
    enterNumberOfSmsUnits:
      "Insira o número de unidades de SMS que você pretende comprar",
    pleaseEnterANumericValue: "Insira um valor numérico",
    pay: "Pagar",
    accountEditedSuccessfully: "Sua conta foi editada com sucesso.",
    youAeCurrentlyOn: "Você está atualmente em",
    plan: "Plano",
    userData: "Dados do usuário",
    businessData: "DADOS DE NEGÓCIO",
    businessCategory: "Categoria Empresarial",
    pleaseSelectCurrency: "Selecione sua moeda",
    selectYourCurrency: "Selecione sua moeda",
    purchaseMoreSmsUnits:
      "Compre mais unidades de SMS usando o formulário abaixo",
    youHave: "Você tem",
    atLeast4SmsUnitsRrequired:
      "São necessárias pelo menos 4 unidades de sms para verificação, recarregue!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Verifique se sua conta bancária está de acordo com os requisitos de 'Conheça seu cliente' (KYC). Isso permitirá que você receba pagamentos via USSD ou transferência instantânea, receba pedidos de clientes e processe a liquidação da transação. Aplicável apenas a comerciantes nigerianos. Por favor, pressione o botão abaixo para começar.",
    reConnectBankAccount: "Reconectar conta bancária",
    accountName: "Nome da conta",
    accountNumber: "Número de conta",
    bankName: "Nome do banco",
    verified: "Verificado",
    accountDetails: "Detalhes da conta",
    kycBankAccount: "KYC",
    createTier: "Criar camada",
    fileUploadSuccessful: "arquivo carregado com sucesso",
    fileUploadFailed: "upload de arquivo falhou",
    createLoyaltyProgram: "Crie um programa de fidelidade",
    createLoyalty: "Criar fidelidade",
    name: "Nome",
    loyaltyArtwork: "Obra de Fidelidade",
    clickToUpload: "Clique para fazer o upload",
    amountToPointsRatio: "Razão de quantidade para pontos",
    points: "Pontos",
    recommendedAmountToPointRatio:
      "Recomendado: ₦ 1 a 1 ponto. ou seja, para cada 1 Naira gasto, seus clientes ganham 1 ponto",
    pleaseTypeIn: "Por favor digite",
    toDeleteLoyalty: "para deletar esta lealdade",
    deleteLoyalty: "Excluir lealdade",
    toConfirm: "confirmar",
    edit: "Editar",
    pointsAwardedSuccessfully: "Pontos atribuídos com sucesso.",
    enterPointValueToAward:
      "Insira o valor do ponto que você gostaria de premiar os clientes",
    award: "Prêmio",
    awardPointValuesToCustomer: "Valor do ponto de prêmio para o cliente",
    enrollMembersToLoyalty: "Inscrever membros no programa de fidelidade",
    awardPoints: "Pontos de Prêmio",
    enroll: "Matricular",
    home: "Casa",
    loyalty: "Lealdade",
    enrollCustomers: "Inscrever clientes",
    selected: "Selecionado",
    customer: "Cliente",
    loyaltyActivationSuccessful: "A ativação do Loyalty foi bem-sucedida.",
    loyaltyDeactivationSuccessful:
      "A desativação de fidelidade foi bem-sucedida.",
    viewTier: "Exibir camada",
    deactivate: "Desativar",
    activate: "Ativar",
    actions: "Ações",
    nameOfLoyalty: "Nome de Fidelidade",
    loyaltyStatus: "Status de fidelidade Status de fidelidade",
    numberOfTiers: "Número de camadas",
    createdOn: "Criado em",
    createATier: "Crie uma camada",
    stopCreatingTierConfirmation: "Quer parar de criar uma camada?",
    stopEditingTierConfirmation: "Quer parar de editar este nível?",
    nameOfTier: "Nome da Camada",
    minimumSpendingTarget: "Meta de gasto mínimo",
    maximumSpendingTarget: "Meta de gasto máximo",
    minimumSpendingTargetRequired: "a meta de gasto mínimo é exigida",
    maximumSpendingTargetRequired: "a meta de gasto máximo é exigida",
    rewardSponsor: "Patrocinador de recompensa",
    pleaseChooseARewardSponsor: "Escolha um patrocinador de recompensa",
    self: "Auto",
    partner: "Parceiro",
    rewardPartner: "Parceiro de Recompensa",
    pleaseSelectRewardPartner: "Selecione seu parceiro de recompensa",
    rewards: "Recompensas",
    pleaseSelectAReward: "Selecione uma recompensa",
    instructionsOnRedeemingReward:
      "Instruções sobre como o cliente deve resgatar a recompensa",
    pleaseFillInThisField: "Por favor, preencha este campo!",
    toDeleteThisTier: " para deletar esta camada",
    deleteTier: "Excluir camada",
    viewMembers: "Ver membros",
    membersEnrolled: "Membros inscritos",
    instruction: "Instrução",
    membersIn: "Membros em",
    availableTiersInLoyalty: "Camadas disponíveis no programa de fidelidade",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "Programa de Fidelidade Disponível",
    totalLoyalties: "LEALDADES TOTAIS",
    memberDetails: "Detalhes do membro",
    nameOfCustomer: "Nome do cliente",
    address: "Endereço",
    allEnrolledMembers: "Todos os membros inscritos",
    thisIsToWishYouHappyBirthday:
      "Desejo a você um feliz aniversário e uma vida próspera. Obrigado por tudo o que você é para Loyster. Boas celebrações!",
    inputAnOfferPlease: "Insira uma oferta, por favor",
    pleaseSelectTheInsertPoint:
      "Selecione o ponto de inserção na mensagem e clique novamente",
    birthdayOfferAndMessage: "Oferta e mensagem de aniversário",
    birthdayOffer: "Oferta de aniversário",
    birthdayMessage: "Mensagem de aniversário",
    noOfferFound: "Nenhuma oferta encontrada",
    settingABirthdayOffer:
      "Definir uma oferta de aniversário permite que os clientes recebam esta oferta por SMS no dia do aniversário",
    createOffer: "Criar oferta",
    whatIsTheOffer: "Qual é a oferta?",
    editMessage: "Editar Mensagem",
    insert: "Inserir",
    theNameOfCustomerInserted: "O nome do cliente será inserido aqui",
    theBirtdayOfferInserted: "A oferta de aniversário será inserida aqui",
    youSuccessfullyAddedNewBranch: "Você adicionou com sucesso um novo branch!",
    addNewBranch: "Adicionar nova filial",
    addBranch: "Adicionar filial",
    additionalBranchWillIncur: "Filial adicional incorreria",
    perBranch: "por ramo",
    ecommerceBranchCosts: "Custos da filial de comércio eletrônico",
    pleaseEnterBranchName: "Por favor, insira o nome da filial",
    pleaseEnterBranchAddress1:
      "Por favor, insira a linha de endereço 1 da agência",
    enterBranchAddress1: "Digite a linha de endereço 1 da filial",
    enterBranchAddress2: "Digite a linha de endereço 1 da filial",
    pleaseEnterBranchAddress2: "Insira a linha de endereço 2 da filial",
    enterBranchName: "Insira o nome da filial",
    successfullyAddedStaff: "Você adicionou com sucesso uma nova equipe!",
    addNewStaff: "Adicionar nova equipe",
    addStaff: "Adicionar equipe",
    additionalStaffWillIncur: "Pessoal adicional incorreria",
    perStaff: "por equipe.",
    pleaseEnterStaffEmail: "Por favor, insira o e-mail da equipe",
    pleaseEnterStaffUsername: "Por favor, digite o nome de usuário da equipe",
    pleaseEnterStaffPassword: "Por favor, digite a senha do staff",
    pleaseSelectStaffRole: "Selecione a função da equipe",
    selectStaffRole: "Selecione a função da equipe",
    enterStaffEmail: "Digite o e-mail da equipe",
    enterStaffUsername: "Digite o nome de usuário da equipe",
    enterStaffPassword: "Digite a senha do staff",
    spacesNotAllowedInUsername: "espaços não permitidos no nome de usuário",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Selecione a empresa para anexar a equipe",
    searchForBranchToAttachStaff: "Procure uma filial para anexar a equipe",
    username: "Nome do usuário",
    role: "Função",
    areYouSureToDeleteThis: "Tem certeza que deseja deletar isto",
    branches: "Galhos",
    upgradeYourPlan: "Atualize seu plano.",
    add: "ADICIONAR",
    addNew: "Adicionar novo",
    customerWithEmailAlreadyExists:
      "Cliente com e-mail / número de telefone já existe!",
    successfullyAddedNewCustomer: "Você adicionou com sucesso um novo cliente!",
    addCustomer: "Adicionar Cliente",
    pleaseEnterCustomerFirstName:
      "Por favor, insira o primeiro nome do cliente",
    pleaseEnterCustomerLastName: "Insira o sobrenome do cliente",
    pleaseEnterCustomerPhoneNumber: "Insira o número de telefone do cliente",
    pleaseEnterCustomerEmail: "Por favor, insira o e-mail do cliente",
    pleaseEnterCustomerAddressLine: "Insira a linha de endereço do cliente",
    pleaseEnterCustomerOtherAddress: "Insira o outro endereço do cliente",
    pleaseSelectCustomerGender: "Selecione o sexo do cliente",
    gender: "Gênero",
    male: "Masculino",
    female: "Fêmea",
    bank: "Banco",
    selectBank: "Selecione Banco",
    stateOrRegionOrProvince: "Estado / Região / Província",
    customerNotes: "Notas do cliente",
    sendSms: "Enviar SMS",
    editCustomer: "Editar Cliente",
    redeemReward: "Resgatar recompensa",
    issueLoyaltyCard: "Emitir cartão de fidelidade",
    deleteCustomer: "Excluir cliente",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Você atribuiu com sucesso uma ID de membro de fidelidade",
    noMembershipIdAvailable:
      "Nenhum ID de membro disponível. Entre em contato conosco em hello@loystar.co",
    sendEmail: "Enviar email",
    membershipPoints: "Pontos de Sócios",
    customerDetails: "Detalhes do cliente",
    close: "Fechar",
    loyaltyBalance: "Saldo de fidelidade",
    pointsBalance: "Saldo de pontos",
    starBalance: "Star Balance",
    requiredPoints: "Pontos Requeridos",
    requiredStars: "Estrelas obrigatórias",
    reddemCode: "Código de resgate",
    toDeleteThisCustomer: "para deletar este cliente",
    customerHasBeenDeletedSuccessfully: "O cliente foi excluído com sucesso!",
    customerWithPhoneAlreadyExists: "Cliente com número de telefone já existe",
    customerWasSuccessfullyEdited: "O cliente foi editado com sucesso",
    anErrorOccured: "Um erro ocorreu",
    phoneNumberIncludeCountry: "Número de telefone (incluir código do país)",
    yourFileQueuedForUpload:
      "Seu arquivo foi colocado na fila para upload. Atualize a página após alguns segundos.",
    thereWasAnErrorPerformingOperation:
      "Ocorreu um erro ao executar a operação!",
    pleaseSelectFile: "Selecione um arquivo!",
    oopsOnlyCsvFilesAllowed:
      "Ops! Somente arquivos CSV permitidos. Faça upload de um arquivo .csv.",
    fileShouldBeSmallerThan5Mb:
      "O arquivo deve ter menos de 5 MB. Se você tiver um arquivo maior, envie um e-mail para support@loystar.co. Obrigado",
    customerFirstNameIsRequired:
      "o primeiro nome do cliente é obrigatório na linha",
    customerPhoneNumberIsRequired:
      "o número de telefone do cliente é obrigatório na linha",
    importCustomers: "Clientes Importados",
    upload: "Envio",
    clickIconToDownloadCsv:
      "Clique neste ícone para baixar o modelo de arquivo CSV.",
    getGoogleSheetVersion: "Obtenha a versão da planilha do Google",
    clickOrDragFileToUpload:
      "Clique ou arraste o arquivo para esta área para fazer o upload",
    missingOrInvalidColumnHeader:
      "Cabeçalho de coluna ausente ou inválido. Siga o formato do modelo. Obrigada.",
    youHaveImported: "Você importou",
    youSuccessfullyRedeemedReward: "Você resgatou sua recompensa com sucesso!",
    sixDigitCode: "Código de seis dígitos",
    pleaseEnterCustomerRewardCode: "Insira o código de recompensa do cliente",
    enterRewardCode:
      "Insira o código de recompensa. (O código de recompensa diferencia maiúsculas de minúsculas)",
    selectLoyaltyProgram: "Selecione o programa de fidelidade",
    stamps: "Selos",
    smsUnitsLow: "Unidades SMS baixas",
    pleaseTopUpSmsUnits: "Por favor, recarregue unidades de SMS",
    smsSuccessfullySentForDelivery: "SMS enviado com sucesso para entrega!",
    sendSmsTo: "Enviar SMS para",
    allCustomers: "Todos os clientes",
    unitsAvailable: "Unidades Disponíveis",
    pleaseTypeInTheMessage: "Por favor digite a mensagem",
    message: "Mensagem",
    charactersRemaining: "caracteres restantes",
    avoidUseOfSpecialCharacters:
      "Evite o uso de caracteres especiais e emojis para conservar unidades de SMS.",
    note: "Observação",
    errorFetchingCustomersMultilevelDetail:
      "Erro ao buscar detalhes multinível de clientes",
    search: "Procurar",
    reset: "Redefinir",
    importCustomer: "Cliente de Importação",
    addNewCustomer: "Adicionar Novo Cliente",
    sendSmsBroadcast: "Enviar transmissão de SMS",
    totalCustomers: "TOTAL DE CLIENTES",
    disbursementDetails: "Detalhes de Desembolso",
    paidBy: "Pago pelo",
    disbursed: "Desembolsado",
    bankAccountName: "Nome da conta do Banco",
    bankAccountNumber: "Número da conta de banco",
    transferInitiated: "Transferência iniciada",
    transferCompleted: "Transferência Concluída",
    pleaseEnterAValid: "Por favor, insira um válido",
    begin: "começar",
    end: "fim",
    date: "encontro",
    paymentDate: "Data de pagamento",
    selectDisbursementDuration: "Selecione a duração do desembolso",
    totalSettled: "Total liquidado",
    totalUnsettled: "Total não resolvido",
    toDeleteThisSale: "para deletar esta venda",
    draftSaleDeletedSuccessfully:
      "O rascunho de venda foi excluído com sucesso!",
    deleteSale: "Excluir venda",
    pleaseTypeInYourTotal: "Digite seu total para confirmar a exclusão",
    billDetails: "Detalhes da conta",
    printBill: "Imprimir Bill",
    servedBy: "Servido por",
    total: "Total",
    createdDate: "Data de criação",
    createdTime: "Hora de Criação",
    status: "Status",
    loginSuccessful: "Login bem sucedido",
    pleaseWaitWhileWeConnectAccount:
      "Por favor, espere enquanto conectamos sua conta",
    connectionFailedTryAgain: "A conexão falhou. Por favor, tente novamente.",
    connectionSuccessful: "Conexão bem sucedida",
    viewDetails: "Ver detalhes",
    enable: "Permitir",
    free: "Sem custos",
    cost: "Custo",
    visitWebsite: "Visite o site",
    pleaseUpgradeYourPlanToPro:
      "Atualize seu plano para Pro Plus para habilitar este aplicativo",
    connectYourBankAccount:
      "Conecte sua conta bancária para poder receber pagamentos.",
    disable: "Desabilitar",
    enableApp: "Habilitar aplicativo",
    addNewProductToInvoice: "Adicionar novo produto à fatura",
    toDeleteThisInvoice: "para deletar esta fatura",
    invoiceDeletedSuccessfully: "A fatura foi excluída com sucesso!",
    deleteInvoice: "Apagar a Fatura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Você adicionou com sucesso uma mensagem de pagamento de fatura!",
    pleaseEnterPaymentInstructions:
      "Por favor, insira as instruções de pagamento",
    invoiceId: "ID da fatura",
    paidDate: "Data de pagamento",
    reference: "Referência",
    productAdded: "Produto adicionado",
    productOutOfStock: "Produto esgotado. Por favor, re-estoque.",
    totalInvoices: "TOTAL DE FATURAS",
    totalPaidInvoices: "TOTAL DE FATURAS PAGAS",
    totalUnpaidInvoices: "TOTAL DE FATURAS NÃO PAGAS",
    loyaltyProgramDeleted: "Programa de fidelidade excluído",
    thisLoyaltyProgramDeletedSuccessfully:
      "Este programa de fidelidade foi excluído com sucesso",
    thisLoyaltyProgramEditedSuccessfully:
      "Este programa de fidelidade foi editado com sucesso",
    loyaltyProgramAddedSuccessfully:
      "O programa de fidelidade foi adicionado com sucesso",
    loyaltyProgramEdited: "Programa de fidelidade editado",
    loyaltyProgramAdded: "Programa de fidelidade adicionado",
    loyaltyDetails: "Detalhes de fidelidade",
    doYouWantToCloseDialog: "Você deseja fechar esta caixa de diálogo?",
    pleaseEnterLoyaltyName: "Por favor, digite o nome da sua fidelidade",
    programType: "Tipo de programa",
    pleaseSelectProgramType: "Selecione um tipo de programa",
    simplePoints: "Pontos Simples",
    stampsProgram: "Programa de Selos",
    threshold: "Limiar",
    pleaseEnterLoyaltyThreshold: "Por favor, insira o limite de fidelidade",
    reward: "Recompensa",
    pleaseEnterLoyaltyReward: "Por favor, insira a recompensa da fidelidade",
    totalUserPoints: "Total de pontos do usuário",
    totalUserStamps: "Total de selos do usuário",
    spendingTarget: "Alvo de gastos",
    spendingTargetHint1:
      "A meta de gastos é quanto um cliente deve gastar para receber a recompensa. 1 valor monetário = 1 ponto.",
    spendingTargetHint2:
      "A meta de selos é quantos selos um cliente deve coletar para receber a recompensa. por exemplo. 5",
    addNewLoyaltyProgram: "Adicionar novo programa de fidelidade",
    addLoyaltyProgram: "Adicionar programa de fidelidade",
    loyaltyProgramType: "Tipo de programa de fidelidade",
    pleaseSelectLoyaltyProgramType:
      "Selecione o tipo de programa de fidelidade",
    nameOfProgram: "Nome do programa",
    pleaseEnterProgramName: "Por favor, insira o nome do programa",
    whatIsTheReward: "Qual é a recompensa?",
    egNextPurchaseIsFree: "Por exemplo. A próxima compra é grátis",
    customerName: "nome do cliente",
    guestCustomer: "Cliente Convidado",
    orderConfirmedSuccessfully: "Pedido confirmado com sucesso",
    orderCancelledSuccessfully: "Pedido cancelado com sucesso",
    confirmOrder: "Confirmar pedido",
    cancelOrder: "Cancelar pedido",
    allOrders: "Todos os pedidos",
    totalOrders: "TOTAL DE PEDIDOS",
    doYouWantToAcceptOrder: "Você quer aceitar este pedido?",
    doYouWantToCancelOrder: "Quer cancelar este pedido?",
    orderDetails: "detalhes do pedido",
    orderCreatedAt: "Pedido criado em",
    supplier: "Fornecedor",
    productName: "Nome do Produto",
    quantity: "Quantidade",
    unitPrice: "Preço unitário",
    receivedBy: "Recebido por",
    reportFrom: "Relatório de",
    totalSupplies: "Suprimentos totais",
    allRightsReserved: "Todos os direitos reservados",
    toDeleteThisTransaction: "para excluir esta transação",
    transactionDeletedSuccessfully:
      "A transação foi excluída com sucesso. O estoque foi devolvido ao estoque.",
    deleteTransaction: "Excluir transação",
    transactionDetails: "Detalhes da transação",
    printReceipt: "Imprimir recibo",
    channel: "Canal",
    discount: "Desconto",
    profit: "Lucro",
    discountedSales: "Vendas com desconto",
    errorFetchingRecord: "Erro ao buscar registro",
    exportTransactions: "Exportar transações",
    errorFetchingSalesRecord:
      "Erro ao buscar registro de vendas para exportação.",
    totalSellingPrice: "Preço de venda total",
    totalCostPrice: "Preço de custo total",
    appliedDiscount: "Desconto Aplicado",
    noOfItems: "No. de itens",
    sales: "Vendas",
    export: "Exportar",
    totalProfit: "Lucro total",
    totalBalanceInPeriod: "Saldo Total no Período",
    allTimeSales: "Vendas de todos os tempos",
    records: "Registros",
    todaysSales: "Vendas de Hoje",
    transaction: "transação",
    youHaveExceededTotalNumberOfProducts:
      "Você excedeu o número total de produtos permitidos em seu plano. Atualize seu plano para desfrutar de um limite maior.",
    youNeedToHaveLoyaltyProgram:
      "Você precisa ter um Programa de Fidelidade para usar este recurso!",
    price: "Preço",
    category: "Categoria",
    stockTracking: "Rastreamento de estoque",
    stockCount: "Contagem de estoque",
    taxed: "Tributado",
    originalPrice: "Preço original",
    costPrice: "Preço de custo",
    unit: "Unidade",
    productImage: "Imagem do Produto",
    taxRate: "Taxa de imposto",
    taxType: "Tipo de Imposto",
    trackInventory: "Rastrear estoque",
    variants: "Variantes",
    hasVariants: "Tem variantes",
    importProduct: "Produto de importação",
    exportProducts: "Produtos de Exportação",
    addNewProduct: "Adicionar Novo Produto",
    viewCategory: "Ver categoria",
    viewSuppliers: "Ver fornecedores",
    receiveInventory: "Receber estoque",
    printAllProductsTag: "Imprimir etiqueta de todos os produtos",
    deleteAll: "Apagar tudo",
    totalProducts: "TOTAL DE PRODUTOS",
    youveSuccessfullyAddedANewCategory:
      "Você adicionou com sucesso uma nova categoria",
    addNewCategory: "Adicionar Nova Categoria",
    addCategory: "Adicionar categoria",
    categoryName: "Nome da Categoria",
    pleaseEnterCategoryName: "Por favor, insira o nome da categoria",
    stampsTarget: "Alvo de selos",
    sendInventory: "Enviar estoque",
    productDetails: "Detalhes do produto",
    youveSuccessfullyEditedThisCategory:
      "Você editou esta categoria com sucesso",
    update: "Atualizar",
    categoryList: "Lista de categorias",
    categories: "Categorias",
    enterQuantityToUpdate: "Insira uma quantidade para atualizar",
    inventorySentSuccessfully: "O inventário foi enviado com sucesso!",
    updateInventory: "Atualizar estoque",
    currentQuantity: "Quantidade Atual",
    pleaseEnterQuantityToAdd:
      "Por favor, insira a quantidade que você deseja adicionar",
    pleaseSelectABranch: "Selecione uma filial",
    searchForBranch: "Pesquisar filial",
    youCantSendMoreThanStock: "Você não pode enviar mais do que tem em estoque",
    send: "Mandar",
    pleaseEnterQuantityToSend:
      "Por favor insira a quantidade que deseja enviar",
    productNameIsRequiredOnRow: "o nome do produto é obrigatório na linha",
    productCategoryIsRequiredOnRow:
      "a categoria do produto é obrigatória na linha",
    productPriceIsRequiredOnRow: "o preço do produto é exigido na linha",
    productUnitIsRequiredOnRow: "unidade de produto é necessária na linha",
    productQuantityIsRequiredOnRow:
      "a quantidade do produto é necessária na linha",
    productVariantsRequireTracking:
      "as variantes do produto requerem rastreamento!",
    pleaseAddVariantClickButton:
      "Adicione uma variante clicando no botão Adicionar!",
    totalVariantsMoreThanSelectedQuantity:
      "As variantes totais são mais do que a quantidade do produto selecionado, por favor, reduza a quantidade da variante",
    productEditedSuccessfully: "O produto foi editado com sucesso!",
    fileTooLargeLessThan4Mb:
      "O tamanho do arquivo é muito grande! O tamanho do arquivo deve ser inferior a 4 MB.",
    suchVariantAlreadyExist: "Essa variante já existe",
    addVariants: "Adicionar Variantes",
    editProduct: "Editar Produto",
    pleaseEnterProductName: "Insira o nome do produto",
    pleaseEnterProductPrice: "Insira o preço do produto",
    pleaseEnterProductOriginalPrice: "Insira o preço original do produto",
    productDescription: "Descrição do Produto",
    selectProductCategory: "Selecione a categoria do produto",
    pleaseSelectProductCategory: "Selecione a categoria do produto",
    productCostPrice: "Preço de custo do produto",
    productSellingPrice: "Preço de venda do produto",
    productOriginalPrice: "Preço Original do Produto",
    maxFileSizeAllowedIs4Mb: "Máx. O tamanho do arquivo permitido é 4 MB",
    productsWithPicturesArePublished:
      "Produtos com fotos são publicados no Discover para receber pedidos",
    shouldThisProductBeTracked: "Este produto deve ser rastreado?",
    pleaseSelectStockUnit: "Selecione a unidade de estoque",
    stockUnit: "Unidade de estoque",
    bag: "BOLSA",
    bottle: "GARRAFA",
    bunch: "MONTE",
    can: "POSSO",
    carton: "CARTON",
    crate: "CRATE",
    cup: "XÍCARA",
    dozen: "DÚZIA",
    gigabytes: "GIGABYTES",
    gram: "GRAMA",
    kilogram: "QUILOGRAMA",
    litre: "LITRO",
    pack: "PACOTE",
    pair: "PAR",
    pieces: "PEÇAS",
    plate: "PLACA",
    tonne: "TONNE (MT)",
    uNIT: "UNIDADE",
    yard: "JARDIM",
    pleaseEnterProductQuantity: "Insira a quantidade do produto",
    productQuantity: "Quantidade de produto",
    isThisProductTaxed: "Este produto é tributado?",
    selectTaxType: "Selecione o tipo de imposto",
    pleaseSelectTaxType: "Selecione o tipo de imposto",
    progressive: "Progressivo",
    proportional: "Proporcional",
    pleaseEnterProductTaxRate: "Insira a taxa de imposto do produto",
    doesProductHaveVariants: "Este produto tem variantes?",
    type: "Modelo",
    value: "Valor",
    pleaseEnterVariantType: "Insira o tipo de variante",
    pleaseEnterVariantValue: "Insira o valor da variante",
    pleaseEnterVariantPrice: "Insira o preço da variante",
    pleaseEnterVariantQuantity: "Insira a quantidade da variante",
    productDeletedSuccessfully: "O produto foi excluído com sucesso!",
    categoryDeletedSuccessfully: "A categoria foi excluída com sucesso!",
    toDeleteThisProduct: "para deletar este produto",
    invalidProductQuantity: "Quantidade de produto inválida",
    quantityAddedIsOverStock:
      "A quantidade que você está adicionando é superior ao que você tem em estoque.",
    itemInventoryNotTracked: "O estoque de itens não é rastreado",
    addBulkQuantity: "Adicionar quantidade a granel",
    enterBulkQuantity: "Insira a quantidade a granel",
    pleaseEnterBulkQuantity: "Insira a quantidade em massa",
    youveSuccessfullyAddedANewProduct:
      "Você adicionou com sucesso um novo produto!",
    pleaseEnterProductSellingPrice: "Insira o preço de venda do produto",
    doYouWantToTrackProductStock: "Você quer rastrear o estoque do produto?",
    sellingPrice: "Preço de venda",
    setProductExpiryReminder: "Definir lembrete de validade do produto",
    productExpiryDate: "Data de validade do produto",
    startRemindingFrom: "Começar a lembrar de",
    productSuppliesAddedSuccessfully:
      "Você adicionou suprimentos de produto com sucesso.",
    addProductSupplies: "Adicionar suprimentos de produto",
    pleaseSelectSupplier: "Selecione o fornecedor",
    nameOfProduct: "Nome do produto",
    pleaseSelectProduct: "Selecione o produto",
    productVariant: "Variante de Produto",
    pleaseSelectAVariant: "Selecione uma variante",
    pleaseEnterUnitPrice: "Insira o preço unitário",
    businessBranch: "Filial de Negócios",
    pleaseSelectBranch: "Selecione o ramo",
    youveSuccessfullyAddedANewSupplier:
      "Você adicionou com sucesso um novo fornecedor",
    addSupplier: "Adicionar fornecedor",
    pleaseEnterSupplierEmail: "Por favor, insira o e-mail do fornecedor",
    pleaseAddADescription: "Por favor adicione uma descrição",
    anErrorOccuredProductsDeleted:
      "Ocorreu um erro ao executar a operação. Observe que alguns produtos podem ter sido excluídos no processo",
    bulkDelete: "Exclusão em massa",
    youAreAboutToDelete: "Você está prestes a deletar",
    product: "produtos",
    isDueToRedeem: "está para resgatar",
    aReward: "uma recompensa",
    pleaseSelectCustomerToReeemReward:
      "Selecione um cliente para resgatar a recompensa.",
    youHaveNoLoyaltyProgramRunning:
      "Você não tem nenhum programa de fidelidade ativo em execução",
    customerHhasNoPointsInLoyaltyProgram:
      "O cliente não tem pontos neste programa de fidelidade",
    proceedWithPayment: "Continuar com o pagamento?",
    youAreAboutToPayForTransactionUsingPoints:
      "Você está prestes a pagar pela transação usando pontos.",
    customerHas: "O cliente tem",
    worth: "que vale a pena",
    andIsNotSufficientToPayForTransaction:
      "e não é suficiente para pagar por esta transação. Eles gostariam de adicionar o saldo usando outro método de pagamento?",
    addCustomerLoyalty: "Adicionar fidelidade do cliente",
    pleaseAddCustomerFirst: "Adicione ou selecione um cliente primeiro.",
    pleaseWaitWhileWeGetReady: "Por favor espere enquanto nos arrumamos",
    lowStock: "Baixo estoque",
    pleaseEnterAmountTendered: "Por favor, insira o valor oferecido",
    areYouSureToBookSaleOffline:
      "Tem certeza de que deseja reservar esta venda off-line?",
    saleWouldBeBookedAutomatically:
      "A venda seria reservada automaticamente quando você ligar sua internet",
    offlineSalesBookingCancelled: "Reserva de vendas offline cancelada",
    covid19Message:
      "COVID19: Lave as mãos com sabão ou desinfetante para impedir a propagação. Fique sempre seguro",
    saleSuccessfullyRecorded: "Venda registrada com sucesso!",
    sendReceiptToEmail: "Enviar recibo para e-mail",
    sendThankYouSms: "Enviar SMS de agradecimento",
    sendShippingDetails: "Enviar detalhes de envio",
    addLoyalty: "Adicionar fidelidade",
    searchCustomerNameOrNumber: "Pesquise o nome ou número do cliente",
    clickTheSearchCustomerBox:
      "Clique na caixa Pesquisar cliente e digitalizar cartão",
    enterProductPrice: "Insira o preço do produto",
    enterPriceFor: "Insira o preço para",
    searchForProduct: "Pesquisar produto",
    all: "Tudo",
    backToDashboard: "Voltar para o painel",
    viewDraftSales: "Ver Rascunho de Vendas",
    variantSelected: "variante selecionada",
    variant: "variante",
    thePreviousVariantSelectionNotAvailable:
      "A seleção de variante anterior não está disponível para a nova variante selecionada com base no preço, altere sua seleção.",
    pleaseSupplyPositiveQuantityNumber:
      "Por favor, forneça um número de quantidade positiva",
    lowStockFor: "Estoque baixo para",
    clearVariants: "Variantes claras",
    pleaseEnterQuantity: "Por favor, insira a quantidade",
    picture: "Foto",
    redemptionToken: "Token de resgate",
    token: "Símbolo",
    totalSpent: "Total gasto",
    confirmPayment: "Confirme o pagamento",
    hasPaymentBeenMade: "O pagamento foi processado com sucesso?",
    enterTransactionReference:
      "Insira a referência da transação com a qual você pagou",
    pleaseSelectACustomer: "Selecione um cliente!",
    areYouSureToApplyDiscount: "Tem certeza que deseja aplicar o desconto?",
    addYourBankAccountToEnableUssd:
      "Adicione sua conta bancária para permitir a transferência instantânea de USSD por uPay",
    totalAmountToPay: "Valor total a pagar",
    doYouWantToCancelTransaction: "Quer cancelar esta transação?",
    savePrintBill: "Salvar / imprimir fatura",
    enterAmountCollectedForCustomer: "Insira o valor coletado para o cliente",
    recordSale: "Venda de discos",
    checkOutWith: "Verifique com",
    instantTransfer: "Transferência Instantânea",
    dialTheUSSDCode: "Disque o código USSD",
    pleaseSelectABank: "Selecione um banco",
    payWithUSSD: "Pague com USSD",
    sendBillTo: " - Enviar Bill para",
    waitingForUSSDTransfer: "Aguardando transferência USSD",
    percent: "Por cento",
    applyDiscount: "Aplicar desconto",
    thisBillHasBeenSaved: "Esta conta foi salva",
    saveDraft: "Salvar rascunho",
    pleaseTypeANameToIdentifyCustomer:
      "Digite um nome para identificar o cliente",
    paymentDetails: "Detalhes do pagamento",
    basePrice: "Preço base",
    staff: "Pessoal",
    subTotal: "Subtotal",
    durationMonths: "Duração (meses)",
    selectADuration: "Selecione uma duração",
    oneMonth: "1 mês",
    twoMonths: "2 meses",
    threeMonths: "3 meses",
    sixMonths: "6 meses",
    twelveMonths: "12 meses",
    eighteenMonths: "18 meses",
    twentyFourMonths: "24 meses",
    twoMonthsFree: "(2 meses grátis)",
    threeMonthsFree: "(3 meses grátis)",
    fiveMonthsFree: "(5 meses grátis)",
    yourAccountHasBeen: "Sua conta foi",
    renewed: "renovado",
    upgraded: "atualizado",
    successfully: "com sucesso",
    yourSubscription: "Sua assinatura",
    youAreCurrentlyEnrolledOnA: "Você está atualmente inscrito em um",
    pleaseChooseAPaymentOption: "Escolha uma opção de pagamento",
    planRenewal: "Renovação do Plano",
    planUpgrade: "Upgrade do Plano",
    pleaseSelectDurationToPayFor:
      "Selecione a duração pela qual pretende pagar",
    staffAccounts: "Contas da equipe",
    ecommerce: "Comércio eletrônico",
    pleaseSelectAPlan: "Selecione um plano",
    includeAddons: "Incluir complementos",
    viewTransactions: "Ver transações",
    customerHasNoCompletedTansactions:
      "O cliente ainda não completou transações",
    branch: "Filial",
    enterNumberOfEcommerceBranches:
      "Insira o número de filiais de comércio eletrônico",
    enterNumberOfEcommerceBranchesToPay:
      "Insira o número de filiais de comércio eletrônico que você pretende pagar",
    ecommerceIntegration: "Integração de comércio eletrônico",
    enterNumberOfBranches: "Insira o número de filiais",
    enterNumberOfAdditionalBranchesToPay:
      "Insira o número de filiais adicionais que você pretende pagar",
    enterNumberOfStaffs: "Digite o número de equipes",
    enterNumberOfStaffsToPayFor:
      "Digite o número de funcionários que você pretende pagar",
    discountApplies: "Desconto se aplica",
    starsOnThe: "estrelas no",
    offer: "oferta",
    get: "Pegue",
    moreStarsToRedeem: "mais estrelas para resgatar",
    taxVat: "Imposto (IVA)",
    callCashierToCompletePayment:
      "Ligue para o caixa para concluir o pagamento",
    receipt: "Recibo",
    dear: "querido",
    thankYouForYourOrderFindGoods:
      "Obrigado pelo seu pedido. Encontre os seguintes produtos fornecidos, conforme acordado.",
    shippingNote: "Nota de transporte",
    enterShippingNote: "Insira Nota de Remessa",
    shippingAddress: "Endereço para envio",
    enterShippingAddress: "Insira o endereço de entrega",
    wellDoneYouAreDueToRedeem: "Bem feito! Você deve resgatar",
    toGetYourRewardNextVisit:
      "para receber sua recompensa em sua próxima visita. Obrigado",
    pointsOnThe: "Pontos no",
    morePointsToRedeem: "mais pontos para resgatar",
    showCode: "Exibir código",
    toGetYourRewardOnNextVisit:
      "para receber sua recompensa em sua próxima visita. Obrigado",
    earn: "Ganhar",
    delivaryNote: "Nota Delivary",
    draftSales: "Rascunho de Vendas",
    startDate: "Data de início",
    endDate: "Data final",
    orders: "Pedidos",
    checkout: "Confira",
    noProductItem: "Nenhum item de produto",
    selectProductImage: "Selecione a imagem do produto",
    selectCountry: "Selecione o pais",
    selectRegion: "Selecione o estado / região",
    printProductTag: "Imprimir etiqueta de produto",
    transactionReference: "Referência de transação",
    Cashier: "Caixa",
    Manager: "Gerente",
    Owner: "Proprietário",
    Admin: "Admin",
    addPartners: "Adicionar Parceiros",
    addNewLoyaltyPartner: "Adicionar novo parceiro de fidelidade",
    pleaseEnterCompanyName: "Insira o nome da empresa",
    companyName: "Nome da empresa",
    pleaseEnterCompanyRepName: "Insira o nome do representante da empresa",
    companyRepName: "Nome do Representante da Empresa",
    pleaseEnterCompanyRepEmail:
      "Por favor, insira o e-mail do representante da empresa",
    companyRepEmail: "Email do representante da empresa",
    pleaseEnterCompanyRepPhone:
      "Por favor, insira o número de telefone do representante da empresa",
    companyRepPhone: "Número de telefone do representante da empresa",
    addReward: "Adicionar recompensa",
    pleaseEnterRewardName: "Insira o nome do prêmio",
    rewardName: "Nome da recompensa",
    rewardQuantity: "Quantidade de recompensa",
    rewardDescription: "Descrição da recompensa",
    rewardType: "Tipo de recompensa",
    pleaseEnterRewardType: "Insira o tipo de recompensa",
    pleaseEnterRewardQuantity: "Insira a quantidade de recompensa",
    pleaseEnterRewardDescription: "Insira a descrição do prêmio",
    fineDining: "Jantar fino",
    luxuryFashion: "Moda de Luxo",
    hotels: "Hotéis",
    travel: "Viajar por",
    foodAndBeverage: "Alimentos e Bebidas",
    fashion: "Moda",
    health: "Saúde",
    furniture: "Mobiliário",
    entertainment: "Entretenimento",
    automobile: "Automóvel",
    education: "Educação",
    beautyAndSpa: "Beleza e Spa",
    staycation: "Staycation",
    events: "Eventos",
    trips: "Viagens",
    oilAndGas: "Óleo e gás",
    laundry: "Lavanderia",
    partnerCategory: "Categoria de Parceiro",
    freeItem: "Artigo grátis",
  },
  Punjabi: {
    cashier: "ਕੈਸ਼ੀਅਰ",
    manager: "ਮੈਨੇਜਰ",
    owner: "ਮਾਲਕ",
    online: "ਆਨਲਾਈਨ",
    offline: "offlineਫਲਾਈਨ",
    changePassword: "ਪਾਸਵਰਡ ਬਦਲੋ",
    currentPasswordMessage: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਮੌਜੂਦਾ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    passwordMessage: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    currentPassword: "ਵਰਤਮਾਨ ਪਾਸਵਰਡ",
    password: "ਪਾਸਵਰਡ",
    confirmPasswordMessage: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਪਾਸਵਰਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ!",
    confirmPassword: "ਪਾਸਵਰਡ ਪੱਕਾ ਕਰੋ",
    sendViaEmail: "ਈਮੇਲ ਰਾਹੀਂ ਭੇਜੋ",
    sendViaWhatsapp: "ਵਟਸਐਪ ਰਾਹੀਂ ਭੇਜੋ",
    downloadPdf: "PDF ਡਾ Downloadਨਲੋਡ ਕਰੋ",
    paid: "ਦਾ ਭੁਗਤਾਨ",
    unpaid: "ਅਦਾਇਗੀ ਨਾ ਕੀਤੀ",
    partial: "ਅਧੂਰਾ",
    closeInvoice: "ਕੀ ਤੁਸੀਂ ਚਲਾਨ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    closeInvoiceConfirmation:
      "ਇਨਵੌਇਸ ਨੂੰ ਸੁਰੱਖਿਅਤ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ. ਕੀ ਤੁਸੀਂ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    yes: "ਹਾਂ",
    no: "ਨਹੀਂ",
    invoice: "ਚਲਾਨ",
    wasDeducted: "ਕੱਟਿਆ ਗਿਆ ਸੀ",
    for: "ਲਈ",
    item: "ਆਈਟਮ",
    addProduct: "ਉਤਪਾਦ ਸ਼ਾਮਲ ਕਰੋ",
    paymentReference: "ਭੁਗਤਾਨ ਸੰਦਰਭ",
    amountPaid: "ਅਦਾਇਗੀ ਕੀਤੀ ਰਕਮ",
    discountAmount: "ਛੂਟ ਦੀ ਰਕਮ",
    amountDue: "ਬਕਾਯਾ ਰਕਮ",
    amount: "ਦੀ ਰਕਮ",
    dueDate: "ਅਦਾਇਗੀ ਤਾਰੀਖ",
    paymentType: "ਭੁਗਤਾਨ ਦੀ ਕਿਸਮ",
    card: "ਕਾਰਡ",
    cash: "ਨਕਦ",
    bankTransfer: "ਬਕ ਤਬਾਦਲਾ",
    paymentMessage: "ਭੁਗਤਾਨ ਸੰਦੇਸ਼",
    description: "ਵਰਣਨ",
    sendReceipt: "ਰਸੀਦ ਭੇਜੋ",
    delete: "ਮਿਟਾਓ",
    save: "ਸੰਭਾਲੋ",
    resend: "ਦੁਬਾਰਾ ਭੇਜੋ",
    saveAndSend: "ਸੰਭਾਲੋ",
    invoiceSaved: "ਚਲਾਨ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ!",
    selectPaymentMethod: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਭੁਗਤਾਨ ਵਿਧੀ ਦੀ ਚੋਣ ਕਰੋ!",
    selectCustomer: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਗਾਹਕ ਚੁਣੋ!",
    cartEmptyError:
      "ਕਾਰਟ ਸੂਚੀ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦੀ, ਚਲਾਨ ਬੰਦ ਕਰੋ ਅਤੇ ਕਾਰਟ ਵਿੱਚ ਆਈਟਮ ਸ਼ਾਮਲ ਕਰੋ!",
    subscriptionExpired:
      "ਤੁਹਾਡੀ ਗਾਹਕੀ ਦੀ ਮਿਆਦ ਖਤਮ ਹੋ ਗਈ ਹੈ ਅਤੇ ਤੁਹਾਡਾ ਖਾਤਾ ਹੁਣ ਸੀਮਤ ਹੈ. ਆਪਣੇ ਖਾਤੇ ਨੂੰ ਨਵਿਆਉਣ ਲਈ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰੋ",
    renew: "ਨਵਿਆਉ",
    holdOn: "ਪਕੜਨਾ",
    customerBank: "ਗਾਹਕ ਬੈਂਕ",
    cancel: "ਰੱਦ ਕਰੋ",
    selectACustomer: "ਗਾਹਕ ਚੁਣੋ",
    invoiceSuccessfulPdfError:
      "ਇਨਵੌਇਸ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਇਆ ਗਿਆ ਪਰ ਪੀਡੀਐਫ ਜਨਰੇਸ਼ਨ ਨੂੰ ਆਮ ਨਾਲੋਂ ਜ਼ਿਆਦਾ ਸਮਾਂ ਲੱਗ ਰਿਹਾ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਜਲਦੀ ਹੀ ਵਾਪਸ ਜਾਂਚ ਕਰੋ.",
    downloadingInvoice: "ਚਲਾਨ ਡਾ Downloadਨਲੋਡ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ",
    downloadingReceipt: "ਰਸੀਦ ਡਾingਨਲੋਡ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ",
    whatsappReceiptError:
      "ਵਟਸਐਪ ਰਾਹੀਂ ਰਸੀਦ ਭੇਜਣ ਦੌਰਾਨ ਇੱਕ ਤਰੁੱਟੀ ਉਤਪੰਨ ਹੋਈ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.",
    receiptToWhatsapp: "ਰਸੀਦ ਵਟਸਐਪ ਨੂੰ ਭੇਜੀ ਗਈ",
    thankYouForPatronage: "ਤੁਹਾਡੀ ਸਰਪ੍ਰਸਤੀ ਲਈ ਧੰਨਵਾਦ",
    hereIsYourReceipt: "ਤੁਹਾਡੀ ਭੁਗਤਾਨ ਦੀ ਰਸੀਦ ਇਹ ਹੈ",
    errorSendingEmailReceipt:
      "ਈ-ਮੇਲ ਰਾਹੀਂ ਰਸੀਦ ਭੇਜਣ ਵੇਲੇ ਇੱਕ ਤਰੁੱਟੀ ਉਤਪੰਨ ਹੋਈ, ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜਾਂ ਸਹਾਇਤਾ ਨਾਲ ਸੰਪਰਕ ਕਰੋ",
    receiptSentToEmail: "ਗਾਹਕ ਦੀ ਈਮੇਲ ਤੇ ਰਸੀਦ ਭੇਜੀ ਗਈ ਹੈ",
    invoiceSentToEmail: "ਗਾਹਕ ਦੀ ਈਮੇਲ ਤੇ ਚਲਾਨ ਭੇਜਿਆ ਗਿਆ ਹੈ",
    invoiceSuccessWhatsappError:
      "ਇਨਵੌਇਸ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਇਆ ਗਿਆ ਪਰ ਵਟਸਐਪ ਨੂੰ ਭੇਜਣ ਵਿੱਚ ਇੱਕ ਗਲਤੀ ਆਈ. ਚਲਾਨ ਸੂਚੀ ਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    invoiceSuccessfulEmailError:
      "ਇਨਵੌਇਸ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਇਆ ਗਿਆ ਪਰ ਈਮੇਲ ਦੇ ਰੂਪ ਵਿੱਚ ਭੇਜਣ ਵੇਲੇ ਇੱਕ ਗਲਤੀ ਆਈ. ਕਿਰਪਾ ਕਰਕੇ ਚਲਾਨ ਸੂਚੀ ਤੋਂ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
    invoiceSentToWhatsapp: "ਵਟਸਐਪ ਨੂੰ ਚਲਾਨ ਭੇਜਿਆ ਗਿਆ",
    hello: "ਸਤ ਸ੍ਰੀ ਅਕਾਲ",
    pleaseDownloadInvoice: "ਕਿਰਪਾ ਕਰਕੇ ਚਲਾਨ ਡਾਉਨਲੋਡ ਕਰੋ",
    pleaseDownloadReceipt: "ਕਿਰਪਾ ਕਰਕੇ ਰਸੀਦ ਡਾਉਨਲੋਡ ਕਰੋ",
    from: "ਤੋਂ",
    email: "ਈ - ਮੇਲ",
    upgrade: "ਅਪਗ੍ਰੇਡ ਕਰੋ",
    youAreOnFreePlan: "ਤੁਸੀਂ ਇੱਕ ਮੁਫਤ ਯੋਜਨਾ ਤੇ ਹੋ.",
    clickOn: "ਤੇ ਕਲਿਕ ਕਰੋ",
    yourPlanInFewSteps: " ਕੁਝ ਤੇਜ਼ ਕਦਮਾਂ ਵਿੱਚ ਤੁਹਾਡੀ ਗਾਹਕੀ.",
    to: "ਨੂੰ",
    yourSubscriptionHasExpired:
      "ਤੁਹਾਡੀ ਗਾਹਕੀ ਦੀ ਮਿਆਦ ਖਤਮ ਹੋ ਗਈ ਹੈ ਅਤੇ ਤੁਹਾਡਾ ਖਾਤਾ ਹੁਣ ਸੀਮਤ ਹੈ.",
    days: "ਦਿਨ",
    yourSubscriptionExpiresIn:
      "ਤੁਹਾਡੀ ਲੋਇਸਟਾਰ ਗਾਹਕੀ ਦੀ ਮਿਆਦ ਇਸ ਵਿੱਚ ਸਮਾਪਤ ਹੋ ਰਹੀ ਹੈ",
    createAcount: "ਅਕਾਉਂਟ ਬਣਾਓ",
    signIn: "ਸਾਈਨ - ਇਨ",
    continue: "ਜਾਰੀ ਰੱਖੋ",
    enterOtp: "OTP ਪਿੰਨ ਦਾਖਲ ਕਰੋ",
    enterValidOtp: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਪਿੰਨ ਦਾਖਲ ਕਰੋ",
    pin: "ਪਿੰਨ",
    tokenSentToMail: "ਤੁਹਾਡੀ ਈਮੇਲ ਤੇ ਇੱਕ ਟੋਕਨ ਭੇਜਿਆ ਗਿਆ ਹੈ",
    passwordResetSuccessful: "ਪਾਸਵਰਡ ਰੀਸੈੱਟ ਸਫਲ ਰਿਹਾ",
    somethingWentWrong: "ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ!",
    resetPassword: "ਪਾਸਵਰਡ ਰੀਸੈਟ ਕਰੋ",
    iHaveResetCode: "ਮੇਰੇ ਕੋਲ ਪਾਸਵਰਡ ਰੀਸੈਟ ਕੋਡ ਹੈ",
    pleaseEnterEmail: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    aTokenWillBeSentToEmail: "ਤੁਹਾਡੀ ਈਮੇਲ ਤੇ ਇੱਕ ਟੋਕਨ ਭੇਜਿਆ ਜਾਵੇਗਾ",
    enterEmail: "ਆਪਣਾ ਈ - ਮੇਲ ਭਰੋ",
    sendinYourToken: "ਤੁਹਾਡਾ ਟੋਕਨ ਭੇਜਿਆ ਜਾ ਰਿਹਾ ਹੈ ...",
    makeSureItMatchesPassword:
      "ਯਕੀਨੀ ਬਣਾਉ ਕਿ ਇਹ ਤੁਹਾਡੇ ਨਵੇਂ ਪਾਸਵਰਡ ਨਾਲ ਮੇਲ ਖਾਂਦਾ ਹੈ",
    pleaseChooseNewPassword: "ਕਿਰਪਾ ਕਰਕੇ ਨਵਾਂ ਪਾਸਵਰਡ ਚੁਣੋ",
    chooseNewPassword: "ਨਵਾਂ ਪਾਸਵਰਡ ਚੁਣੋ",
    enterNewPassword: "ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਆਪਣਾ ਨਵਾਂ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    enterTokenSent: "ਉਹ ਟੋਕਨ ਦਾਖਲ ਕਰੋ ਜੋ ਤੁਹਾਡੀ ਮੇਲ ਭੇਜੀ ਗਈ ਸੀ",
    resetToken: "ਟੋਕਨ ਰੀਸੈਟ ਕਰੋ",
    resettingPassword: "ਤੁਹਾਡਾ ਪਾਸਵਰਡ ਰੀਸੈੱਟ ਕੀਤਾ ਜਾ ਰਿਹਾ ਹੈ ...",
    signUp: "ਸਾਇਨ ਅਪ",
    adminSignInWithEmail:
      " ਪ੍ਰਬੰਧਕ ਈਮੇਲ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰਦੇ ਹਨ ਜਦੋਂ ਕਿ ਸਟਾਫ ਉਪਭੋਗਤਾ ਨਾਮ ਨਾਲ ਸਾਈਨ ਇਨ ਕਰਦਾ ਹੈ.",
    pleaseEnterEmailOrUsername: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਈਮੇਲ ਜਾਂ ਉਪਭੋਗਤਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    emailOrUsername: "ਈਮੇਲ ਜਾਂ ਉਪਯੋਗਕਰਤਾ ਨਾਂ",
    pleaseEnterPassword: "ਕਿਰਪਾ ਕਰਕੇ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    createAnAccount: "ਅਕਾਉਂਟ ਬਣਾਓ",
    forgotPassword: "ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ?",
    enterPhoneNumber: "ਫ਼ੋਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    personalData: "ਨਿਜੀ ਸੂਚਨਾ",
    validateConfirmationCOde: "ਪੁਸ਼ਟੀਕਰਣ ਕੋਡ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    pleaseEnterFirstName: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਹਿਲਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    pleaseEnterPhoneNumber: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਫ਼ੋਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    pleaseEnterLastName: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਅਖੀਰਲਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    pleaseEnterBusinessName: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਕਾਰੋਬਾਰ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    firstName: "ਪਹਿਲਾ ਨਾਂ",
    lastName: "ਆਖਰੀ ਨਾਂਮ",
    businessName: "ਵਪਾਰ ਨਾਮ",
    previous: "ਪਿਛਲਾ",
    next: "ਅਗਲਾ",
    pleaseSelectBusinessCategory: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਕਾਰੋਬਾਰੀ ਸ਼੍ਰੇਣੀ ਦੀ ਚੋਣ ਕਰੋ",
    pleaseEnterValidEmail: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    pleaseEnterPostCode: "ਕਿਰਪਾ ਕਰਕੇ ਪੋਸਟ ਕੋਡ ਦਾਖਲ ਕਰੋ",
    postCode: "ਪੋਸਟ ਕੋਡ",
    phoneNumberInUse: "ਇਹ ਫ਼ੋਨ ਨੰਬਰ ਪਹਿਲਾਂ ਹੀ ਵਰਤੋਂ ਵਿੱਚ ਹੈ!",
    emailInUse: "ਇਹ ਈਮੇਲ ਪਹਿਲਾਂ ਹੀ ਵਰਤੋਂ ਵਿੱਚ ਹੈ!",
    foodAndDrinks: "ਭੋਜਨ ਅਤੇ ਪੀਣ ਵਾਲੇ ਪਦਾਰਥ",
    salonAndBeauty: "ਸੈਲੂਨ ਅਤੇ ਸੁੰਦਰਤਾ",
    fashionAndAccessories: "ਫੈਸ਼ਨ ਅਤੇ ਸਹਾਇਕ ਉਪਕਰਣ",
    gymAndFitness: "ਜਿਮ ਅਤੇ ਤੰਦਰੁਸਤੀ",
    travelAndHotel: "ਯਾਤਰਾ ਅਤੇ ਹੋਟਲ",
    homeAndGifts: "ਘਰ ਅਤੇ ਤੋਹਫ਼ੇ",
    washingAndCleaning: "ਧੋਣਾ ਅਤੇ ਸਫਾਈ",
    gadgetsAndElectronics: "ਗੈਜੇਟਸ ਅਤੇ ਇਲੈਕਟ੍ਰੌਨਿਕਸ",
    groceries: "ਕਰਿਆਨਾ",
    others: "ਹੋਰ",
    submit: "ਜਮ੍ਹਾਂ ਕਰੋ",
    accountCreatedSuccessful: "ਤੁਹਾਡਾ ਖਾਤਾ ਸਫਲਤਾਪੂਰਵਕ ਬਣਾਇਆ ਗਿਆ ਸੀ.",
    pleaseEnterAddress: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਤਾ ਦਾਖਲ ਕਰੋ",
    addressLine1: "ਪਤਾ ਲਾਈਨ 1",
    addressLine2: "ਪਤਾ ਲਾਈਨ 2",
    choosePassword: "ਪਾਸਵਰਡ ਚੁਣੋ",
    passwordMustBe6Characters: "ਪਾਸਵਰਡ ਘੱਟੋ -ਘੱਟ 6 ਅੱਖਰਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ.",
    howDidYouHearLoystar: "ਤੁਸੀਂ ਲੋਇਸਟਾਰ ਬਾਰੇ ਕਿਵੇਂ ਸੁਣਿਆ?",
    referralCode: "ਰੈਫ਼ਰਲ ਕੋਡ",
    byClickingTheButton: " ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰਕੇ, ਤੁਸੀਂ ਇਸ ਨਾਲ ਸਹਿਮਤ ਹੋ",
    termsAndSevice: "ਸ਼ਰਤਾਂ",
    wereCreatingAccount: "ਅਸੀਂ ਤੁਹਾਡਾ ਖਾਤਾ ਬਣਾ ਰਹੇ ਹਾਂ",
    proceed: "ਅੱਗੇ ਵਧੋ",
    verificationCodeMustBe6: "ਤਸਦੀਕ ਕੋਡ 6 ਅੰਕਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    pleaseEnter6DigitCode: "ਕਿਰਪਾ ਕਰਕੇ 6 ਅੰਕਾਂ ਦਾ ਕੋਡ ਦਾਖਲ ਕਰੋ",
    enterVerificationCode: "ਤਸਦੀਕ ਕੋਡ ਦਰਜ ਕਰੋ",
    resendToken: "ਟੋਕਨ ਦੁਬਾਰਾ ਭੇਜੋ",
    verify: "ਤਸਦੀਕ ਕਰੋ",
    transactions: "ਲੈਣ -ਦੇਣ",
    todaySales: "ਅੱਜ ਦੀ ਵਿਕਰੀ",
    salesHistory: "ਵਿਕਰੀ ਇਤਿਹਾਸ",
    supplyHistory: "ਸਪਲਾਈ ਇਤਿਹਾਸ",
    new: "ਨਵਾਂ",
    invoices: "ਚਲਾਨ",
    disbursements: "ਅਦਾਇਗੀ",
    offlineSales: "Lineਫਲਾਈਨ ਵਿਕਰੀ",
    products: "ਉਤਪਾਦ",
    customers: "ਗਾਹਕ",
    multiLevelLoyalty: "ਬਹੁ-ਪੱਧਰੀ ਵਫ਼ਾਦਾਰੀ",
    loyaltyPrograms: "ਵਫ਼ਾਦਾਰੀ ਦੇ ਪ੍ਰੋਗਰਾਮ",
    members: "ਮੈਂਬਰ",
    appStore: "ਐਪ ਸਟੋਰ",
    orderMenu: "ਆਰਡਰ ਮੇਨੂ",
    settings: "ਸੈਟਿੰਗਜ਼",
    staffAndBranches: "ਸਟਾਫ ਅਤੇ ਸ਼ਾਖਾਵਾਂ",
    myAccount: "ਮੇਰਾ ਖਾਤਾ",
    switchToSellMode: "ਵਿਕਰੀ ਮੋਡ ਤੇ ਸਵਿਚ ਕਰੋ",
    signOut: "ਸਾਇਨ ਆਉਟ",
    getFreeSubscription: "ਮੁਫਤ ਗਾਹਕੀ ਪ੍ਰਾਪਤ ਕਰੋ",
    onlyNumbersAllowed: "ਸਿਰਫ ਨੰਬਰਾਂ ਦੀ ਆਗਿਆ ਹੈ",
    yourAccountMustBe10Digits: "ਤੁਹਾਡਾ ਖਾਤਾ ਨੰਬਰ 10 ਅੰਕਾਂ ਦਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    yourBvnMustBe11: "ਤੁਹਾਡਾ BVN 11 ਅੰਕਾਂ ਦਾ ਨੰਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ",
    pleaseSelectBank: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਬੈਂਕ ਚੁਣੋ",
    selectYourBank: "ਆਪਣਾ ਬੈਂਕ ਚੁਣੋ",
    enterBankAccountNumber: "ਬੈਂਕ ਖਾਤਾ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    enterBvnNumber: "ਆਪਣਾ BVN ਦਾਖਲ ਕਰੋ",
    connectBankAccount: "ਬੈਂਕ ਖਾਤੇ ਨਾਲ ਜੁੜੋ",
    passwordResetSuccessfulAndSignOut:
      "ਤੁਹਾਡਾ ਪਾਸਵਰਡ ਸਫਲਤਾਪੂਰਵਕ ਰੀਸੈਟ ਕੀਤਾ ਗਿਆ ਹੈ. ਦੁਬਾਰਾ ਸਾਈਨ ਇਨ ਕਰਨ ਲਈ ਹੇਠਾਂ ਸਾਈਨ ਆਉਟ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰੋ",
    enterCurrentPassword: "ਮੌਜੂਦਾ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    pleaseEnterCurrentPassword: "ਕਿਰਪਾ ਕਰਕੇ ਮੌਜੂਦਾ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    phoneNumber: "ਫੋਨ ਨੰਬਰ",
    sex: "ਸੈਕਸ",
    dateOfBirth: "ਜਨਮ ਤਾਰੀਖ",
    state: "ਰਾਜ",
    country: "ਦੇਸ਼",
    loyaltyId: "ਵਫ਼ਾਦਾਰੀ ID",
    createdAt: "ਵਿਖੇ ਬਣਾਇਆ ਗਿਆ",
    noOfTransactionsMade: "ਕੀਤੇ ਗਏ ਟ੍ਰਾਂਜੈਕਸ਼ਨਾਂ ਦੀ ਸੰਖਿਆ",
    yourDownloadWillStart: "ਤੁਹਾਡਾ ਡਾਉਨਲੋਡ ਇੱਕ ਪਲ ਵਿੱਚ ਅਰੰਭ ਹੋ ਜਾਵੇਗਾ",
    exportCustomers: "ਨਿਰਯਾਤ ਗਾਹਕ",
    youhaveSuccessfullyToppedUp:
      "ਤੁਸੀਂ ਆਪਣੀਆਂ ਐਸਐਮਐਸ ਯੂਨਿਟਾਂ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸਿਖਰ ਤੇ ਪਹੁੰਚਾ ਦਿੱਤਾ ਹੈ.",
    youNowHave: "ਹੁਣ ਤੁਹਾਡੇ ਕੋਲ ਹੈ",
    smsUnits: "ਐਸਐਮਐਸ ਯੂਨਿਟਸ",
    enterNumberOfSmsUnits:
      "ਐਸਐਮਐਸ ਯੂਨਿਟਾਂ ਦੀ ਸੰਖਿਆ ਦਰਜ ਕਰੋ ਜੋ ਤੁਸੀਂ ਖਰੀਦਣ ਦਾ ਇਰਾਦਾ ਰੱਖਦੇ ਹੋ",
    pleaseEnterANumericValue: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਸੰਖਿਆਤਮਕ ਮੁੱਲ ਦਾਖਲ ਕਰੋ",
    pay: "ਭੁਗਤਾਨ ਕਰੋ",
    accountEditedSuccessfully: "ਤੁਹਾਡਾ ਖਾਤਾ ਸਫਲਤਾਪੂਰਵਕ ਸੰਪਾਦਿਤ ਕੀਤਾ ਗਿਆ ਸੀ.",
    youAeCurrentlyOn: "ਤੁਸੀਂ ਇਸ ਵੇਲੇ ਚਾਲੂ ਹੋ",
    plan: "ਯੋਜਨਾ",
    userData: "ਉਪਭੋਗਤਾ ਡਾਟਾ",
    businessData: "ਵਪਾਰ ਡਾਟਾ",
    businessCategory: "ਬਿਜ਼ਨਸ ਕੈਟਰਗਰੀ",
    pleaseSelectCurrency: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਮੁਦਰਾ ਦੀ ਚੋਣ ਕਰੋ",
    selectYourCurrency: "ਆਪਣੀ ਮੁਦਰਾ ਦੀ ਚੋਣ ਕਰੋ",
    purchaseMoreSmsUnits:
      "ਹੇਠਾਂ ਦਿੱਤੇ ਫਾਰਮ ਦੀ ਵਰਤੋਂ ਕਰਦਿਆਂ ਹੋਰ ਐਸਐਮਐਸ ਯੂਨਿਟਸ ਖਰੀਦੋ",
    youHave: "ਤੁਹਾਡੇ ਕੋਲ ਹੈ",
    atLeast4SmsUnitsRrequired:
      "ਤਸਦੀਕ ਲਈ ਘੱਟੋ ਘੱਟ 4 ਐਸਐਮਐਸ ਯੂਨਿਟਸ ਲੋੜੀਂਦੇ ਹਨ, ਕਿਰਪਾ ਕਰਕੇ ਟੌਪ ਅਪ ਕਰੋ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "ਕਿਰਪਾ ਕਰਕੇ 'ਆਪਣੇ ਗਾਹਕ ਨੂੰ ਜਾਣੋ' (ਕੇਵਾਈਸੀ) ਦੀਆਂ ਜ਼ਰੂਰਤਾਂ ਦੀ ਪਾਲਣਾ ਕਰਨ ਲਈ ਆਪਣੇ ਬੈਂਕ ਖਾਤੇ ਦੀ ਤਸਦੀਕ ਕਰੋ. ਇਹ ਤੁਹਾਨੂੰ ਯੂਐਸਐਸਡੀ ਜਾਂ ਤਤਕਾਲ ਟ੍ਰਾਂਸਫਰ ਦੁਆਰਾ ਭੁਗਤਾਨ ਇਕੱਠਾ ਕਰਨ, ਗਾਹਕਾਂ ਤੋਂ ਆਰਡਰ ਪ੍ਰਾਪਤ ਕਰਨ ਅਤੇ ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਸੈਟਲਮੈਂਟ ਦੀ ਪ੍ਰਕਿਰਿਆ ਕਰਨ ਦੀ ਆਗਿਆ ਦੇਵੇਗਾ. ਸਿਰਫ ਨਾਈਜੀਰੀਆ ਦੇ ਵਪਾਰੀਆਂ ਲਈ ਲਾਗੂ. ਸ਼ੁਰੂ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੇ ਬਟਨ ਨੂੰ ਦਬਾਉ.",
    reConnectBankAccount: "ਬੈਂਕ ਖਾਤੇ ਨੂੰ ਦੁਬਾਰਾ ਕਨੈਕਟ ਕਰੋ",
    accountName: "ਅਕਾਉਂਟ ਦਾ ਨਾਂ",
    accountNumber: "ਅਕਾਊਂਟ ਨੰਬਰ",
    bankName: "ਬੈਂਕ ਦਾ ਨਾਮ",
    verified: "ਤਸਦੀਕ ਕੀਤਾ",
    accountDetails: "ਖਾਤੇ ਦੇ ਵੇਰਵੇ",
    kycBankAccount: "ਕੇਵਾਈਸੀ",
    createTier: "ਟੀਅਰ ਬਣਾਉ",
    fileUploadSuccessful: "ਫਾਇਲ ਸਫਲਤਾਪੂਰਵਕ ਅਪਲੋਡ ਕੀਤੀ ਗਈ",
    fileUploadFailed: "ਫਾਈਲ ਅਪਲੋਡ ਅਸਫਲ",
    createLoyaltyProgram: "ਇੱਕ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਬਣਾਉ",
    createLoyalty: "ਵਫ਼ਾਦਾਰੀ ਬਣਾਉ",
    name: "ਨਾਮ",
    loyaltyArtwork: "ਵਫ਼ਾਦਾਰੀ ਕਲਾਕਾਰੀ",
    clickToUpload: "ਅਪਲੋਡ ਕਰਨ ਲਈ ਕਲਿਕ ਕਰੋ",
    amountToPointsRatio: "ਬਿੰਦੂ ਅਨੁਪਾਤ ਦੀ ਮਾਤਰਾ",
    points: "ਅੰਕ",
    recommendedAmountToPointRatio:
      "ਸਿਫਾਰਸ਼ੀ: ₦ 1 ਤੋਂ 1 ਪੁਆਇੰਟ. ਅਰਥਾਤ ਹਰ 1 ਨਾਇਰਾ ਖਰਚੇ ਲਈ, ਤੁਹਾਡੇ ਗ੍ਰਾਹਕਾਂ ਨੂੰ 1 ਅੰਕ ਮਿਲਦਾ ਹੈ",
    pleaseTypeIn: "ਕਿਰਪਾ ਕਰਕੇ ਟਾਈਪ ਕਰੋ",
    toDeleteLoyalty: "ਇਸ ਵਫ਼ਾਦਾਰੀ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    deleteLoyalty: "ਵਫ਼ਾਦਾਰੀ ਮਿਟਾਓ",
    toConfirm: "ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ",
    edit: "ਸੰਪਾਦਿਤ ਕਰੋ",
    pointsAwardedSuccessfully: "ਅੰਕ ਸਫਲਤਾਪੂਰਵਕ ਦਿੱਤੇ ਗਏ.",
    enterPointValueToAward:
      "ਪੁਆਇੰਟ ਮੁੱਲ ਦਾਖਲ ਕਰੋ ਜੋ ਤੁਸੀਂ ਗਾਹਕਾਂ ਨੂੰ ਅਵਾਰਡ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ",
    award: "ਪੁਰਸਕਾਰ",
    awardPointValuesToCustomer: "ਗਾਹਕ ਨੂੰ ਅਵਾਰਡ ਪੁਆਇੰਟ ਮੁੱਲ",
    enrollMembersToLoyalty: "ਵਫ਼ਾਦਾਰੀ 'ਤੇ ਮੈਂਬਰਾਂ ਨੂੰ ਦਾਖਲ ਕਰੋ",
    awardPoints: "ਪੁਰਸਕਾਰ ਅੰਕ",
    enroll: "ਦਰਜ ਕਰੋ",
    home: "ਘਰ",
    loyalty: "ਵਫ਼ਾਦਾਰੀ",
    enrollCustomers: "ਗਾਹਕਾਂ ਦੀ ਭਰਤੀ ਕਰੋ",
    selected: "ਚੁਣਿਆ ਹੋਇਆ",
    customer: "ਗਾਹਕ",
    loyaltyActivationSuccessful: "ਵਫ਼ਾਦਾਰੀ ਸਰਗਰਮੀ ਸਫਲ ਹੈ.",
    loyaltyDeactivationSuccessful: "ਵਫ਼ਾਦਾਰੀ ਨੂੰ ਸਰਗਰਮ ਕਰਨਾ ਸਫਲ ਹੈ.",
    viewTier: "ਟੀਅਰ ਵੇਖੋ",
    deactivate: "ਡੀ-ਐਕਟੀਵੇਟ ਕਰੋ",
    activate: "ਕਿਰਿਆਸ਼ੀਲ ਕਰੋ",
    actions: "ਕਾਰਵਾਈਆਂ",
    nameOfLoyalty: "ਵਫ਼ਾਦਾਰੀ ਦਾ ਨਾਮ",
    loyaltyStatus: "ਵਫ਼ਾਦਾਰੀ ਸਥਿਤੀ ਵਫ਼ਾਦਾਰੀ ਦੀ ਸਥਿਤੀ",
    numberOfTiers: "ਟੀਅਰਸ ਦੀ ਗਿਣਤੀ",
    createdOn: "'ਤੇ ਬਣਾਇਆ ਗਿਆ",
    createATier: "ਇੱਕ ਟੀਅਰ ਬਣਾਉ",
    stopCreatingTierConfirmation:
      "ਕੀ ਤੁਸੀਂ ਇੱਕ ਟੀਅਰ ਬਣਾਉਣਾ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    stopEditingTierConfirmation:
      "ਕੀ ਤੁਸੀਂ ਇਸ ਟੀਅਰ ਦਾ ਸੰਪਾਦਨ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    nameOfTier: "ਟੀਅਰ ਦਾ ਨਾਮ",
    minimumSpendingTarget: "ਘੱਟੋ ਘੱਟ ਖਰਚ ਦਾ ਟੀਚਾ",
    maximumSpendingTarget: "ਵੱਧ ਤੋਂ ਵੱਧ ਖਰਚ ਕਰਨ ਦਾ ਟੀਚਾ",
    minimumSpendingTargetRequired: "ਘੱਟੋ ਘੱਟ ਖਰਚ ਦਾ ਟੀਚਾ ਲੋੜੀਂਦਾ ਹੈ",
    maximumSpendingTargetRequired: "ਵੱਧ ਤੋਂ ਵੱਧ ਖਰਚ ਦਾ ਟੀਚਾ ਲੋੜੀਂਦਾ ਹੈ",
    rewardSponsor: "ਇਨਾਮ ਪ੍ਰਾਯੋਜਕ",
    pleaseChooseARewardSponsor: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਇਨਾਮ ਪ੍ਰਾਯੋਜਕ ਚੁਣੋ",
    self: "ਸਵੈ",
    partner: "ਸਾਥੀ",
    rewardPartner: "ਇਨਾਮ ਸਹਿਭਾਗੀ",
    pleaseSelectRewardPartner: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇਨਾਮ ਸਾਥੀ ਦੀ ਚੋਣ ਕਰੋ",
    rewards: "ਇਨਾਮ",
    pleaseSelectAReward: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਇਨਾਮ ਚੁਣੋ",
    instructionsOnRedeemingReward:
      "ਗ੍ਰਾਹਕ ਨੂੰ ਇਨਾਮ ਕਿਵੇਂ ਛੁਡਾਉਣਾ ਚਾਹੀਦਾ ਹੈ ਇਸ ਬਾਰੇ ਨਿਰਦੇਸ਼",
    pleaseFillInThisField: "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਖੇਤਰ ਨੂੰ ਭਰੋ!",
    toDeleteThisTier: " ਇਸ ਪੱਧਰ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    deleteTier: "ਟੀਅਰ ਮਿਟਾਓ",
    viewMembers: "ਮੈਂਬਰ ਵੇਖੋ",
    membersEnrolled: "ਮੈਂਬਰ ਭਰਤੀ ਕੀਤੇ ਗਏ",
    instruction: "ਹਿਦਾਇਤ",
    membersIn: "ਵਿੱਚ ਮੈਂਬਰ",
    availableTiersInLoyalty: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਵਿੱਚ ਉਪਲਬਧ ਟੀਅਰ (ਜ਼)",
    tiers: "ਟੀਅਰਸ",
    totalTier: "ਕੁੱਲ ਟੀਅਰ",
    availableLoyaltyProgramme: "ਉਪਲਬਧ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ",
    totalLoyalties: "ਕੁੱਲ ਵਫਾਦਾਰੀ",
    memberDetails: "ਮੈਂਬਰ ਵੇਰਵੇ",
    nameOfCustomer: "ਗਾਹਕ ਦਾ ਨਾਮ",
    address: "ਪਤਾ",
    allEnrolledMembers: "ਸਾਰੇ ਰਜਿਸਟਰਡ ਮੈਂਬਰ",
    thisIsToWishYouHappyBirthday:
      "ਇਹ ਤੁਹਾਡੇ ਲਈ ਜਨਮਦਿਨ ਅਤੇ ਖੁਸ਼ਹਾਲ ਜੀਵਨ ਦੀ ਕਾਮਨਾ ਕਰਦਾ ਹੈ. ਉਨ੍ਹਾਂ ਸਾਰਿਆਂ ਲਈ ਧੰਨਵਾਦ ਜੋ ਤੁਸੀਂ ਲੌਇਸਟਰ ਦੇ ਲਈ ਹੋ. ਖੁਸ਼ੀ ਦੇ ਜਸ਼ਨ!",
    inputAnOfferPlease: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪੇਸ਼ਕਸ਼ ਦਾਖਲ ਕਰੋ",
    pleaseSelectTheInsertPoint:
      "ਕਿਰਪਾ ਕਰਕੇ ਸੰਦੇਸ਼ ਵਿੱਚ ਸੰਮਿਲਤ ਬਿੰਦੂ ਦੀ ਚੋਣ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕਲਿਕ ਕਰੋ",
    birthdayOfferAndMessage: "ਜਨਮਦਿਨ ਦੀ ਪੇਸ਼ਕਸ਼ ਅਤੇ ਸੰਦੇਸ਼",
    birthdayOffer: "ਜਨਮਦਿਨ ਦੀ ਪੇਸ਼ਕਸ਼",
    birthdayMessage: "ਜਨਮਦਿਨ ਸੰਦੇਸ਼",
    noOfferFound: "ਕੋਈ ਪੇਸ਼ਕਸ਼ ਨਹੀਂ ਮਿਲੀ",
    settingABirthdayOffer:
      "ਜਨਮਦਿਨ ਦੀ ਪੇਸ਼ਕਸ਼ ਨਿਰਧਾਰਤ ਕਰਨ ਨਾਲ ਗਾਹਕ ਆਪਣੇ ਜਨਮਦਿਨ ਤੇ ਐਸਐਮਐਸ ਦੁਆਰਾ ਇਹ ਪੇਸ਼ਕਸ਼ ਪ੍ਰਾਪਤ ਕਰ ਸਕਦੇ ਹਨ",
    createOffer: "ਪੇਸ਼ਕਸ਼ ਬਣਾਉ",
    whatIsTheOffer: "ਪੇਸ਼ਕਸ਼ ਕੀ ਹੈ?",
    editMessage: "ਸੁਨੇਹਾ ਸੰਪਾਦਿਤ ਕਰੋ",
    insert: "ਸ਼ਾਮਲ ਕਰੋ",
    theNameOfCustomerInserted: "ਗਾਹਕ ਦਾ ਨਾਮ ਇੱਥੇ ਪਾਇਆ ਜਾਵੇਗਾ",
    theBirtdayOfferInserted: "ਜਨਮਦਿਨ ਦੀ ਪੇਸ਼ਕਸ਼ ਇੱਥੇ ਪਾਈ ਜਾਵੇਗੀ",
    youSuccessfullyAddedNewBranch:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵੀਂ ਸ਼ਾਖਾ ਸ਼ਾਮਲ ਕੀਤੀ ਹੈ!",
    addNewBranch: "ਨਵੀਂ ਸ਼ਾਖਾ ਸ਼ਾਮਲ ਕਰੋ",
    addBranch: "ਸ਼ਾਖਾ ਸ਼ਾਮਲ ਕਰੋ",
    additionalBranchWillIncur: "ਅਤਿਰਿਕਤ ਬ੍ਰਾਂਚ ਲਾਏਗੀ",
    perBranch: "ਪ੍ਰਤੀ ਸ਼ਾਖਾ",
    ecommerceBranchCosts: "ਈਕਾੱਮਰਸ ਬ੍ਰਾਂਚ ਦੇ ਖਰਚੇ",
    pleaseEnterBranchName: "ਕਿਰਪਾ ਕਰਕੇ ਸ਼ਾਖਾ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    pleaseEnterBranchAddress1: "ਕਿਰਪਾ ਕਰਕੇ ਬ੍ਰਾਂਚ ਦੀ ਐਡਰੈਸ ਲਾਈਨ 1 ਦਾਖਲ ਕਰੋ",
    enterBranchAddress1: "ਬ੍ਰਾਂਚ ਦੀ ਐਡਰੈਸ ਲਾਈਨ 1 ਦਰਜ ਕਰੋ",
    enterBranchAddress2: "ਬ੍ਰਾਂਚ ਦੀ ਐਡਰੈਸ ਲਾਈਨ 1 ਦਰਜ ਕਰੋ",
    pleaseEnterBranchAddress2: "ਕਿਰਪਾ ਕਰਕੇ ਬ੍ਰਾਂਚ ਦੀ ਐਡਰੈਸ ਲਾਈਨ 2 ਦਾਖਲ ਕਰੋ",
    enterBranchName: "ਸ਼ਾਖਾ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    successfullyAddedStaff: "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵਾਂ ਸਟਾਫ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ!",
    addNewStaff: "ਨਵਾਂ ਸਟਾਫ ਸ਼ਾਮਲ ਕਰੋ",
    addStaff: "ਸਟਾਫ ਸ਼ਾਮਲ ਕਰੋ",
    additionalStaffWillIncur: "ਅਤਿਰਿਕਤ ਸਟਾਫ ਹੋਵੇਗਾ",
    perStaff: "ਪ੍ਰਤੀ ਸਟਾਫ.",
    pleaseEnterStaffEmail: "ਕਿਰਪਾ ਕਰਕੇ ਸਟਾਫ ਦੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    pleaseEnterStaffUsername: "ਕਿਰਪਾ ਕਰਕੇ ਸਟਾਫ ਦਾ ਉਪਯੋਗਕਰਤਾ ਨਾਂ ਦਾਖਲ ਕਰੋ",
    pleaseEnterStaffPassword: "ਕਿਰਪਾ ਕਰਕੇ ਸਟਾਫ ਦਾ ਪਾਸਵਰਡ ਦਾਖਲ ਕਰੋ",
    pleaseSelectStaffRole: "ਕਿਰਪਾ ਕਰਕੇ ਸਟਾਫ ਦੀ ਭੂਮਿਕਾ ਦੀ ਚੋਣ ਕਰੋ",
    selectStaffRole: "ਸਟਾਫ ਦੀ ਭੂਮਿਕਾ ਦੀ ਚੋਣ ਕਰੋ",
    enterStaffEmail: "ਸਟਾਫ ਦੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    enterStaffUsername: "ਸਟਾਫ ਦਾ ਉਪਯੋਗਕਰਤਾ ਨਾਂ ਦਾਖਲ ਕਰੋ",
    enterStaffPassword: "ਸਟਾਫ ਦਾ ਪਾਸਵਰਡ ਦਰਜ ਕਰੋ",
    spacesNotAllowedInUsername:
      "ਉਪਯੋਗਕਰਤਾ ਨਾਂ ਵਿੱਚ ਖਾਲੀ ਥਾਂਵਾਂ ਦੀ ਆਗਿਆ ਨਹੀਂ ਹੈ",
    admin: "ਪ੍ਰਸ਼ਾਸਕ",
    pleaseSelectBusinessToAttachStaff:
      "ਸਟਾਫ ਨੂੰ ਜੋੜਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਕਾਰੋਬਾਰ ਦੀ ਚੋਣ ਕਰੋ",
    searchForBranchToAttachStaff: "ਸਟਾਫ ਨੂੰ ਜੋੜਨ ਲਈ ਸ਼ਾਖਾ ਦੀ ਖੋਜ ਕਰੋ",
    username: "ਉਪਯੋਗਕਰਤਾ ਨਾਂ",
    role: "ਭੂਮਿਕਾ",
    areYouSureToDeleteThis: "ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਤੋਂ ਇਸਨੂੰ ਮਿਟਾ ਰਹੇ ਹੋ?",
    branches: "ਸ਼ਾਖਾਵਾਂ",
    upgradeYourPlan: "ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ.",
    add: "ADD",
    addNew: "ਨਵਾਂ ਸ਼ਾਮਲ ਕਰੋ",
    customerWithEmailAlreadyExists:
      "ਈਮੇਲ/ਫ਼ੋਨ ਨੰਬਰ ਵਾਲਾ ਗਾਹਕ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ!",
    successfullyAddedNewCustomer:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵਾਂ ਗਾਹਕ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ!",
    addCustomer: "ਗਾਹਕ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseEnterCustomerFirstName: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਪਹਿਲਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    pleaseEnterCustomerLastName: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਆਖਰੀ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    pleaseEnterCustomerPhoneNumber: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਫ਼ੋਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    pleaseEnterCustomerEmail: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    pleaseEnterCustomerAddressLine: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦੀ ਐਡਰੈੱਸ ਲਾਈਨ ਦਾਖਲ ਕਰੋ",
    pleaseEnterCustomerOtherAddress: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਹੋਰ ਪਤਾ ਦਾਖਲ ਕਰੋ",
    pleaseSelectCustomerGender: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਲਿੰਗ ਚੁਣੋ",
    gender: "ਲਿੰਗ",
    male: "ਮਰਦ",
    female: "ਰਤ",
    bank: "ਬੈਂਕ",
    selectBank: "ਬੈਂਕ ਦੀ ਚੋਣ ਕਰੋ",
    stateOrRegionOrProvince: "ਰਾਜ/ਖੇਤਰ/ਪ੍ਰਾਂਤ",
    customerNotes: "ਗਾਹਕ ਨੋਟਸ",
    sendSms: "SMS ਭੇਜੋ",
    editCustomer: "ਗਾਹਕ ਦਾ ਸੰਪਾਦਨ ਕਰੋ",
    redeemReward: "ਇਨਾਮ ਛੁਡਾਓ",
    issueLoyaltyCard: "ਵਫ਼ਾਦਾਰੀ ਕਾਰਡ ਜਾਰੀ ਕਰੋ",
    deleteCustomer: "ਗਾਹਕ ਨੂੰ ਮਿਟਾਓ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਵਫ਼ਾਦਾਰੀ ਮੈਂਬਰਸ਼ਿਪ ਆਈਡੀ ਨਿਰਧਾਰਤ ਕੀਤੀ ਹੈ",
    noMembershipIdAvailable:
      "ਕੋਈ ਮੈਂਬਰਸ਼ਿਪ ਆਈਡੀ ਉਪਲਬਧ ਨਹੀਂ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ hello@loystar.co ਤੇ ਸੰਪਰਕ ਕਰੋ",
    sendEmail: "ਈਮੇਲ ਭੇਜੋ",
    membershipPoints: "ਮੈਂਬਰਸ਼ਿਪ ਅੰਕ",
    customerDetails: "ਗਾਹਕ ਵੇਰਵੇ",
    close: "ਬੰਦ ਕਰੋ",
    loyaltyBalance: "ਵਫ਼ਾਦਾਰੀ ਸੰਤੁਲਨ",
    pointsBalance: "ਅੰਕ ਸੰਤੁਲਨ",
    starBalance: "ਸਟਾਰ ਬੈਲੇਂਸ",
    requiredPoints: "ਲੋੜੀਂਦੇ ਅੰਕ",
    requiredStars: "ਲੋੜੀਂਦੇ ਸਿਤਾਰੇ",
    reddemCode: "ਕੋਡ ਰੀਡੀਮ ਕਰੋ",
    toDeleteThisCustomer: "ਇਸ ਗਾਹਕ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    customerHasBeenDeletedSuccessfully:
      "ਗਾਹਕ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ!",
    customerWithPhoneAlreadyExists: "ਫੋਨ ਨੰਬਰ ਵਾਲਾ ਗਾਹਕ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
    customerWasSuccessfullyEdited: "ਗਾਹਕ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੰਪਾਦਿਤ ਕੀਤਾ ਗਿਆ",
    anErrorOccured: "ਇੱਕ ਗੜਬੜ ਹੋ ਗਈ",
    phoneNumberIncludeCountry: "ਫ਼ੋਨ ਨੰਬਰ (ਦੇਸ਼ ਦਾ ਕੋਡ ਸ਼ਾਮਲ ਕਰੋ)",
    yourFileQueuedForUpload:
      "ਤੁਹਾਡੀ ਫਾਈਲ ਨੂੰ ਅਪਲੋਡ ਕਰਨ ਲਈ ਕਤਾਰਬੱਧ ਕੀਤਾ ਗਿਆ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਕਿੰਟਾਂ ਬਾਅਦ ਪੰਨੇ ਨੂੰ ਤਾਜ਼ਾ ਕਰੋ.",
    thereWasAnErrorPerformingOperation: "ਓਪਰੇਸ਼ਨ ਕਰਦੇ ਸਮੇਂ ਇੱਕ ਗੜਬੜ ਹੋ ਗਈ!",
    pleaseSelectFile: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਫਾਈਲ ਚੁਣੋ!",
    oopsOnlyCsvFilesAllowed:
      "ਉਫ! ਸਿਰਫ CSV ਫਾਈਲਾਂ ਦੀ ਆਗਿਆ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ .csv ਫਾਈਲ ਅਪਲੋਡ ਕਰੋ.",
    fileShouldBeSmallerThan5Mb:
      "ਫਾਈਲ 5MB ਤੋਂ ਛੋਟੀ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ. ਜੇ ਤੁਹਾਡੇ ਕੋਲ ਵੱਡੀ ਫਾਈਲ ਹੈ, ਤਾਂ ਕਿਰਪਾ ਕਰਕੇ support@loystar.co ਤੇ ਈਮੇਲ ਕਰੋ. ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    customerFirstNameIsRequired: "ਕਤਾਰ 'ਤੇ ਗਾਹਕ ਦਾ ਪਹਿਲਾ ਨਾਮ ਲੋੜੀਂਦਾ ਹੈ",
    customerPhoneNumberIsRequired: "ਕਤਾਰ 'ਤੇ ਗਾਹਕ ਦਾ ਫੋਨ ਨੰਬਰ ਲੋੜੀਂਦਾ ਹੈ",
    importCustomers: "ਗਾਹਕ ਆਯਾਤ ਕਰੋ",
    upload: "ਅਪਲੋਡ ਕਰੋ",
    clickIconToDownloadCsv:
      "CSV ਫਾਈਲ ਟੈਮਪਲੇਟ ਨੂੰ ਡਾਉਨਲੋਡ ਕਰਨ ਲਈ ਇਸ ਆਈਕਨ ਤੇ ਕਲਿਕ ਕਰੋ.",
    getGoogleSheetVersion: "ਗੂਗਲ ਸ਼ੀਟ ਵਰਜਨ ਪ੍ਰਾਪਤ ਕਰੋ",
    clickOrDragFileToUpload:
      "ਅਪਲੋਡ ਕਰਨ ਲਈ ਫਾਈਲ ਨੂੰ ਇਸ ਖੇਤਰ ਤੇ ਕਲਿਕ ਕਰੋ ਜਾਂ ਖਿੱਚੋ",
    missingOrInvalidColumnHeader:
      "ਗੁੰਮ ਜਾਂ ਅਵੈਧ ਕਾਲਮ ਸਿਰਲੇਖ. ਕਿਰਪਾ ਕਰਕੇ ਟੈਮਪਲੇਟ ਫਾਰਮੈਟ ਦੀ ਪਾਲਣਾ ਕਰੋ. ਤੁਹਾਡਾ ਧੰਨਵਾਦ.",
    youHaveImported: "ਤੁਸੀਂ ਆਯਾਤ ਕੀਤਾ ਹੈ",
    youSuccessfullyRedeemedReward:
      "ਤੁਸੀਂ ਆਪਣਾ ਇਨਾਮ ਸਫਲਤਾਪੂਰਵਕ ਰੀਡੀਮ ਕਰ ਲਿਆ ਹੈ!",
    sixDigitCode: "ਛੇ ਅੰਕਾਂ ਦਾ ਕੋਡ",
    pleaseEnterCustomerRewardCode: "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦਾ ਇਨਾਮ ਕੋਡ ਦਾਖਲ ਕਰੋ",
    enterRewardCode: "ਇਨਾਮ ਕੋਡ ਦਾਖਲ ਕਰੋ। (ਇਨਾਮ ਕੋਡ ਕੇਸ ਸੰਵੇਦਨਸ਼ੀਲ ਹੈ)",
    selectLoyaltyProgram: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਦੀ ਚੋਣ ਕਰੋ",
    stamps: "ਅਸ਼ਟਾਮ",
    smsUnitsLow: "SMS ਯੂਨਿਟਸ ਘੱਟ",
    pleaseTopUpSmsUnits: "ਕਿਰਪਾ ਕਰਕੇ ਐਸਐਮਐਸ ਯੂਨਿਟਸ ਨੂੰ ਟੌਪ ਅਪ ਕਰੋ",
    smsSuccessfullySentForDelivery: "ਐਸਐਮਐਸ ਸਫਲਤਾਪੂਰਵਕ ਸਪੁਰਦਗੀ ਲਈ ਭੇਜਿਆ ਗਿਆ!",
    sendSmsTo: "ਨੂੰ ਐਸਐਮਐਸ ਭੇਜੋ",
    allCustomers: "ਸਾਰੇ ਗਾਹਕ",
    unitsAvailable: "ਯੂਨਿਟਸ ਉਪਲਬਧ ਹਨ",
    pleaseTypeInTheMessage: "ਕਿਰਪਾ ਕਰਕੇ ਸੁਨੇਹਾ ਟਾਈਪ ਕਰੋ",
    message: "ਸੁਨੇਹਾ",
    charactersRemaining: "ਬਾਕੀ ਅੱਖਰ",
    avoidUseOfSpecialCharacters:
      "ਐਸਐਮਐਸ ਯੂਨਿਟਾਂ ਦੀ ਸੰਭਾਲ ਲਈ ਵਿਸ਼ੇਸ਼ ਅੱਖਰਾਂ ਅਤੇ ਇਮੋਜੀ ਦੀ ਵਰਤੋਂ ਤੋਂ ਬਚੋ.",
    note: "ਨੋਟ",
    errorFetchingCustomersMultilevelDetail:
      "ਗਾਹਕਾਂ ਦੇ ਬਹੁ -ਪੱਧਰੀ ਵੇਰਵੇ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਗਲਤੀ",
    search: "ਖੋਜ",
    reset: "ਰੀਸੈਟ ਕਰੋ",
    importCustomer: "ਗਾਹਕ ਆਯਾਤ ਕਰੋ",
    addNewCustomer: "ਨਵਾਂ ਗਾਹਕ ਸ਼ਾਮਲ ਕਰੋ",
    sendSmsBroadcast: "SMS ਪ੍ਰਸਾਰਣ ਭੇਜੋ",
    totalCustomers: "ਕੁੱਲ ਗਾਹਕ",
    disbursementDetails: "ਵੰਡਣ ਦੇ ਵੇਰਵੇ",
    paidBy: "ਦੁਆਰਾ ਭੁਗਤਾਨ ਕੀਤਾ ਗਿਆ",
    disbursed: "ਵੰਡਿਆ ਗਿਆ",
    bankAccountName: "ਬੈਂਕ ਖਾਤੇ ਦਾ ਨਾਮ",
    bankAccountNumber: "ਖਾਤਾ ਨੰਬਰ",
    transferInitiated: "ਟ੍ਰਾਂਸਫਰ ਅਰੰਭ ਕੀਤਾ ਗਿਆ",
    transferCompleted: "ਟ੍ਰਾਂਸਫਰ ਪੂਰਾ ਹੋਇਆ",
    pleaseEnterAValid: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਦਾਖਲ ਕਰੋ",
    begin: "ਸ਼ੁਰੂ",
    end: "ਅੰਤ",
    date: "ਤਾਰੀਖ਼",
    paymentDate: "ਭੁਗਤਾਨ ਦੀ ਮਿਤੀ",
    selectDisbursementDuration: "ਅਦਾਇਗੀ ਦੀ ਮਿਆਦ ਚੁਣੋ",
    totalSettled: "ਕੁੱਲ ਸੈਟਲਡ",
    totalUnsettled: "ਕੁੱਲ ਅਸਥਿਰ",
    toDeleteThisSale: "ਇਸ ਵਿਕਰੀ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    draftSaleDeletedSuccessfully:
      "ਡਰਾਫਟ ਵਿਕਰੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ!",
    deleteSale: "ਵਿਕਰੀ ਮਿਟਾਓ",
    pleaseTypeInYourTotal:
      "ਮਿਟਾਉਣ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕੁੱਲ ਟਾਈਪ ਕਰੋ",
    billDetails: "ਬਿੱਲ ਦੇ ਵੇਰਵੇ",
    printBill: "ਬਿੱਲ ਪ੍ਰਿੰਟ ਕਰੋ",
    servedBy: "ਦੁਆਰਾ ਸੇਵਾ ਕੀਤੀ ਗਈ",
    total: "ਕੁੱਲ",
    createdDate: "ਬਣਾਉਣ ਦੀ ਤਾਰੀਖ",
    createdTime: "ਬਣਾਇਆ ਸਮਾਂ",
    status: "ਸਥਿਤੀ",
    loginSuccessful: "ਲੌਗਇਨ ਸਫਲ ਰਿਹਾ",
    pleaseWaitWhileWeConnectAccount:
      "ਜਦੋਂ ਤੱਕ ਅਸੀਂ ਤੁਹਾਡੇ ਖਾਤੇ ਨਾਲ ਜੁੜਦੇ ਹਾਂ ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ",
    connectionFailedTryAgain: "ਕੁਨੈਕਸ਼ਨ ਫੇਲ੍ਹ ਹੈ. ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ.",
    connectionSuccessful: "ਕਨੈਕਸ਼ਨ ਸਫਲ ਰਿਹਾ",
    viewDetails: "ਵੇਰਵੇ ਵੇਖੋ",
    enable: "ਯੋਗ ਕਰੋ",
    free: "ਖਾਲੀ",
    cost: "ਲਾਗਤ",
    visitWebsite: "ਵੈਬਸਾਈਟ ਤੇ ਜਾਉ",
    pleaseUpgradeYourPlanToPro:
      "ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਐਪ ਨੂੰ ਸਮਰੱਥ ਬਣਾਉਣ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਪ੍ਰੋ ਪਲੱਸ ਵਿੱਚ ਅਪਗ੍ਰੇਡ ਕਰੋ",
    connectYourBankAccount:
      "ਭੁਗਤਾਨ ਪ੍ਰਾਪਤ ਕਰਨ ਦੇ ਯੋਗ ਹੋਣ ਲਈ ਆਪਣੇ ਬੈਂਕ ਖਾਤੇ ਨਾਲ ਜੁੜੋ.",
    disable: "ਅਯੋਗ ਕਰੋ",
    enableApp: "ਐਪ ਨੂੰ ਸਮਰੱਥ ਬਣਾਉ",
    addNewProductToInvoice: "ਚਲਾਨ ਵਿੱਚ ਨਵਾਂ ਉਤਪਾਦ ਸ਼ਾਮਲ ਕਰੋ",
    toDeleteThisInvoice: "ਇਸ ਚਲਾਨ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    invoiceDeletedSuccessfully: "ਚਲਾਨ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ!",
    deleteInvoice: "ਚਲਾਨ ਮਿਟਾਓ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਇਨਵੌਇਸ ਭੁਗਤਾਨ ਸੁਨੇਹਾ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ!",
    pleaseEnterPaymentInstructions: "ਕਿਰਪਾ ਕਰਕੇ ਭੁਗਤਾਨ ਨਿਰਦੇਸ਼ ਦਾਖਲ ਕਰੋ",
    invoiceId: "ਇਨਵੌਇਸ ਆਈ.ਡੀ",
    paidDate: "ਭੁਗਤਾਨ ਦੀ ਤਾਰੀਖ",
    reference: "ਹਵਾਲਾ",
    productAdded: "ਉਤਪਾਦ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ",
    productOutOfStock: "ਉਤਪਾਦ ਸਟਾਕ ਤੋਂ ਬਾਹਰ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਸਟਾਕ ਕਰੋ.",
    totalInvoices: "ਕੁੱਲ ਇਨਵੌਇਸ",
    totalPaidInvoices: "ਕੁੱਲ ਭੁਗਤਾਨ ਇਨਵੌਇਸ",
    totalUnpaidInvoices: "ਕੁੱਲ ਭੁਗਤਾਨ ਰਹਿਤ ਇਨਵੌਇਸ",
    loyaltyProgramDeleted: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਮਿਟਾਇਆ ਗਿਆ",
    thisLoyaltyProgramDeletedSuccessfully:
      "ਇਹ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ",
    thisLoyaltyProgramEditedSuccessfully:
      "ਇਹ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸਫਲਤਾਪੂਰਵਕ ਸੰਪਾਦਿਤ ਕੀਤਾ ਗਿਆ ਹੈ",
    loyaltyProgramAddedSuccessfully:
      "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ ਹੈ",
    loyaltyProgramEdited: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸੰਪਾਦਿਤ",
    loyaltyProgramAdded: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸ਼ਾਮਲ ਕੀਤਾ ਗਿਆ",
    loyaltyDetails: "ਵਫ਼ਾਦਾਰੀ ਦੇ ਵੇਰਵੇ",
    doYouWantToCloseDialog: "ਕੀ ਤੁਸੀਂ ਇਹ ਡਾਇਲਾਗ ਬੰਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    pleaseEnterLoyaltyName: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਵਫ਼ਾਦਾਰੀ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    programType: "ਪ੍ਰੋਗਰਾਮ ਦੀ ਕਿਸਮ",
    pleaseSelectProgramType: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪ੍ਰੋਗਰਾਮ ਦੀ ਕਿਸਮ ਚੁਣੋ",
    simplePoints: "ਸਧਾਰਨ ਅੰਕ",
    stampsProgram: "ਸਟੈਂਪਸ ਪ੍ਰੋਗਰਾਮ",
    threshold: "ਥ੍ਰੈਸ਼ਹੋਲਡ",
    pleaseEnterLoyaltyThreshold: "ਕਿਰਪਾ ਕਰਕੇ ਵਫ਼ਾਦਾਰੀ ਦੀ ਸੀਮਾ ਦਾਖਲ ਕਰੋ",
    reward: "ਇਨਾਮ",
    pleaseEnterLoyaltyReward: "ਕਿਰਪਾ ਕਰਕੇ ਵਫ਼ਾਦਾਰੀ ਦਾ ਇਨਾਮ ਦਾਖਲ ਕਰੋ",
    totalUserPoints: "ਕੁੱਲ ਉਪਭੋਗਤਾ ਅੰਕ",
    totalUserStamps: "ਕੁੱਲ ਉਪਭੋਗਤਾ ਸਟੈਂਪਸ",
    spendingTarget: "ਖਰਚ ਟੀਚਾ",
    spendingTargetHint1:
      "ਖਰਚ ਕਰਨ ਦਾ ਟੀਚਾ ਇਹ ਹੈ ਕਿ ਇਨਾਮ ਕਮਾਉਣ ਲਈ ਗਾਹਕ ਨੂੰ ਕਿੰਨਾ ਖਰਚ ਕਰਨਾ ਚਾਹੀਦਾ ਹੈ. 1 ਮੁਦਰਾ ਮੁੱਲ = 1 ਪੁਆਇੰਟ.",
    spendingTargetHint2:
      "ਸਟੈਂਪਸ ਦਾ ਟੀਚਾ ਇਹ ਹੈ ਕਿ ਇਨਾਮ ਕਮਾਉਣ ਲਈ ਗਾਹਕ ਨੂੰ ਕਿੰਨੇ ਸਟੈਂਪਸ ਇਕੱਠੇ ਕਰਨੇ ਚਾਹੀਦੇ ਹਨ. ਜਿਵੇਂ ਕਿ 5",
    addNewLoyaltyProgram: "ਨਵਾਂ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸ਼ਾਮਲ ਕਰੋ",
    addLoyaltyProgram: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਸ਼ਾਮਲ ਕਰੋ",
    loyaltyProgramType: "ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਦੀ ਕਿਸਮ",
    pleaseSelectLoyaltyProgramType: "ਕਿਰਪਾ ਕਰਕੇ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਦੀ ਕਿਸਮ ਚੁਣੋ",
    nameOfProgram: "ਪ੍ਰੋਗਰਾਮ ਦਾ ਨਾਮ",
    pleaseEnterProgramName: "ਕਿਰਪਾ ਕਰਕੇ ਪ੍ਰੋਗਰਾਮ ਦਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    whatIsTheReward: "ਇਨਾਮ ਕੀ ਹੈ?",
    egNextPurchaseIsFree: "ਜਿਵੇਂ ਕਿ ਅਗਲੀ ਖਰੀਦ ਮੁਫਤ ਹੈ",
    customerName: "ਗਾਹਕ ਦਾ ਨਾਮ",
    guestCustomer: "ਮਹਿਮਾਨ ਗਾਹਕ",
    orderConfirmedSuccessfully: "ਆਰਡਰ ਦੀ ਸਫਲਤਾਪੂਰਵਕ ਪੁਸ਼ਟੀ ਹੋਈ",
    orderCancelledSuccessfully: "ਆਰਡਰ ਸਫਲਤਾਪੂਰਵਕ ਰੱਦ ਕੀਤਾ ਗਿਆ",
    confirmOrder: "ਆਰਡਰ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    cancelOrder: "ਆਰਡਰ ਰੱਦ ਕਰੋ",
    allOrders: "ਸਾਰੇ ਆਦੇਸ਼",
    totalOrders: "ਕੁੱਲ ਆਰਡਰ",
    doYouWantToAcceptOrder: "ਕੀ ਤੁਸੀਂ ਇਸ ਆਦੇਸ਼ ਨੂੰ ਸਵੀਕਾਰ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    doYouWantToCancelOrder: "ਕੀ ਤੁਸੀਂ ਇਸ ਆਰਡਰ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    orderDetails: "ਆਰਡਰ ਵੇਰਵੇ",
    orderCreatedAt: "'ਤੇ ਆਰਡਰ ਬਣਾਇਆ ਗਿਆ",
    supplier: "ਸਪਲਾਇਰ",
    productName: "ਉਤਪਾਦ ਦਾ ਨਾਮ",
    quantity: "ਮਾਤਰਾ",
    unitPrice: "ਯੂਨਿਟ ਮੁੱਲ",
    receivedBy: "ਦੁਆਰਾ ਪ੍ਰਾਪਤ",
    reportFrom: "ਤੋਂ ਰਿਪੋਰਟ ਕਰੋ",
    totalSupplies: "ਕੁੱਲ ਸਪਲਾਈ",
    allRightsReserved: "ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ",
    toDeleteThisTransaction: "ਇਸ ਲੈਣ -ਦੇਣ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    transactionDeletedSuccessfully:
      "ਲੈਣ -ਦੇਣ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ. ਸਟਾਕ ਨੂੰ ਵਸਤੂ ਸੂਚੀ ਵਿੱਚ ਵਾਪਸ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ.",
    deleteTransaction: "ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਮਿਟਾਓ",
    transactionDetails: "ਲੈਣ -ਦੇਣ ਦੇ ਵੇਰਵੇ",
    printReceipt: "ਪ੍ਰਾਪਤੀ ਪ੍ਰਾਪਤੀ",
    channel: "ਚੈਨਲ",
    discount: "ਛੋਟ",
    profit: "ਲਾਭ",
    discountedSales: "ਛੂਟ ਵਾਲੀ ਵਿਕਰੀ",
    errorFetchingRecord: "ਰਿਕਾਰਡ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਗੜਬੜ",
    exportTransactions: "ਟ੍ਰਾਂਜੈਕਸ਼ਨਾਂ ਨੂੰ ਨਿਰਯਾਤ ਕਰੋ",
    errorFetchingSalesRecord: "ਨਿਰਯਾਤ ਲਈ ਵਿਕਰੀ ਰਿਕਾਰਡ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਗੜਬੜ.",
    totalSellingPrice: "ਕੁੱਲ ਵਿਕਰੀ ਮੁੱਲ",
    totalCostPrice: "ਕੁੱਲ ਲਾਗਤ ਮੁੱਲ",
    appliedDiscount: "ਲਾਗੂ ਛੂਟ",
    noOfItems: "ਆਈਟਮਾਂ ਦੀ ਸੰਖਿਆ",
    sales: "ਵਿਕਰੀ",
    export: "ਨਿਰਯਾਤ",
    totalProfit: "ਕੁੱਲ ਲਾਭ",
    totalBalanceInPeriod: "ਮਿਆਦ ਵਿੱਚ ਕੁੱਲ ਸੰਤੁਲਨ",
    allTimeSales: "ਸਾਰੇ ਸਮੇਂ ਦੀ ਵਿਕਰੀ",
    records: "ਰਿਕਾਰਡ",
    todaysSales: "ਅੱਜ ਦੀ ਵਿਕਰੀ",
    transaction: "ਲੈਣ -ਦੇਣ",
    youHaveExceededTotalNumberOfProducts:
      "ਤੁਸੀਂ ਆਪਣੀ ਯੋਜਨਾ ਵਿੱਚ ਮਨਜ਼ੂਰਸ਼ੁਦਾ ਉਤਪਾਦਾਂ ਦੀ ਕੁੱਲ ਸੰਖਿਆ ਨੂੰ ਪਾਰ ਕਰ ਗਏ ਹੋ. ਉੱਚੀ ਸੀਮਾ ਦਾ ਅਨੰਦ ਲੈਣ ਲਈ ਆਪਣੀ ਯੋਜਨਾ ਨੂੰ ਅਪਗ੍ਰੇਡ ਕਰੋ.",
    youNeedToHaveLoyaltyProgram:
      "ਇਸ ਵਿਸ਼ੇਸ਼ਤਾ ਦੀ ਵਰਤੋਂ ਕਰਨ ਲਈ ਤੁਹਾਡੇ ਕੋਲ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਹੋਣ ਦੀ ਜ਼ਰੂਰਤ ਹੈ!",
    price: "ਕੀਮਤ",
    category: "ਸ਼੍ਰੇਣੀ",
    stockTracking: "ਸਟਾਕ ਟਰੈਕਿੰਗ",
    stockCount: "ਸਟਾਕ ਦੀ ਗਿਣਤੀ",
    taxed: "ਟੈਕਸ ਲਗਾਇਆ ਗਿਆ",
    originalPrice: "ਮੂਲ ਕੀਮਤ",
    costPrice: "ਲਾਗਤ ਮੁੱਲ",
    unit: "ਯੂਨਿਟ",
    productImage: "ਉਤਪਾਦ ਚਿੱਤਰ",
    taxRate: "ਟੈਕਸ ਦੀ ਦਰ",
    taxType: "ਟੈਕਸ ਦੀ ਕਿਸਮ",
    trackInventory: "ਟਰੈਕ ਵਸਤੂ ਸੂਚੀ",
    variants: "ਰੂਪ",
    hasVariants: "ਰੂਪ ਹਨ",
    importProduct: "ਉਤਪਾਦ ਆਯਾਤ ਕਰੋ",
    exportProducts: "ਉਤਪਾਦ ਨਿਰਯਾਤ ਕਰੋ",
    addNewProduct: "ਨਵਾਂ ਉਤਪਾਦ ਸ਼ਾਮਲ ਕਰੋ",
    viewCategory: "ਸ਼੍ਰੇਣੀ ਵੇਖੋ",
    viewSuppliers: "ਸਪਲਾਇਰ ਵੇਖੋ",
    receiveInventory: "ਵਸਤੂ ਪ੍ਰਾਪਤ ਕਰੋ",
    printAllProductsTag: "ਸਾਰੇ ਉਤਪਾਦ ਟੈਗ ਪ੍ਰਿੰਟ ਕਰੋ",
    deleteAll: "ਸਭ ਮਿਟਾਓ",
    totalProducts: "ਕੁੱਲ ਉਤਪਾਦ",
    youveSuccessfullyAddedANewCategory:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵੀਂ ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕੀਤੀ ਹੈ",
    addNewCategory: "ਨਵੀਂ ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕਰੋ",
    addCategory: "ਸ਼੍ਰੇਣੀ ਸ਼ਾਮਲ ਕਰੋ",
    categoryName: "ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ",
    pleaseEnterCategoryName: "ਕਿਰਪਾ ਕਰਕੇ ਸ਼੍ਰੇਣੀ ਦਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    stampsTarget: "ਸਟੈਂਪਸ ਟੀਚਾ",
    sendInventory: "ਵਸਤੂ ਸੂਚੀ ਭੇਜੋ",
    productDetails: "ਉਤਪਾਦ ਵੇਰਵੇ",
    youveSuccessfullyEditedThisCategory:
      "ਤੁਸੀਂ ਇਸ ਸ਼੍ਰੇਣੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਸੰਪਾਦਿਤ ਕੀਤਾ ਹੈ",
    update: "ਅਪਡੇਟ",
    categoryList: "ਸ਼੍ਰੇਣੀ ਸੂਚੀ",
    categories: "ਵਰਗ",
    enterQuantityToUpdate: "ਅਪਡੇਟ ਕਰਨ ਲਈ ਇੱਕ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    inventorySentSuccessfully: "ਵਸਤੂ ਸੂਚੀ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜੀ ਗਈ ਸੀ!",
    updateInventory: "ਵਸਤੂ ਸੂਚੀ ਨੂੰ ਅਪਡੇਟ ਕਰੋ",
    currentQuantity: "ਮੌਜੂਦਾ ਮਾਤਰਾ",
    pleaseEnterQuantityToAdd:
      "ਕਿਰਪਾ ਕਰਕੇ ਉਹ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ ਜੋ ਤੁਸੀਂ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    pleaseSelectABranch: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਸ਼ਾਖਾ ਚੁਣੋ",
    searchForBranch: "ਸ਼ਾਖਾ ਦੀ ਖੋਜ ਕਰੋ",
    youCantSendMoreThanStock: "ਤੁਸੀਂ ਆਪਣੇ ਸਟਾਕ ਵਿੱਚ ਇਸ ਤੋਂ ਵੱਧ ਨਹੀਂ ਭੇਜ ਸਕਦੇ",
    send: "ਭੇਜੋ",
    pleaseEnterQuantityToSend:
      "ਕਿਰਪਾ ਕਰਕੇ ਉਹ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ ਜੋ ਤੁਸੀਂ ਭੇਜਣਾ ਚਾਹੁੰਦੇ ਹੋ",
    productNameIsRequiredOnRow: "ਕਤਾਰ 'ਤੇ ਉਤਪਾਦ ਦਾ ਨਾਮ ਲੋੜੀਂਦਾ ਹੈ",
    productCategoryIsRequiredOnRow: "ਕਤਾਰ 'ਤੇ ਉਤਪਾਦ ਸ਼੍ਰੇਣੀ ਲੋੜੀਂਦੀ ਹੈ",
    productPriceIsRequiredOnRow: "ਉਤਪਾਦ ਦੀ ਕੀਮਤ ਕਤਾਰ 'ਤੇ ਲੋੜੀਂਦੀ ਹੈ",
    productUnitIsRequiredOnRow: "ਕਤਾਰ 'ਤੇ ਉਤਪਾਦ ਇਕਾਈ ਲੋੜੀਂਦੀ ਹੈ",
    productQuantityIsRequiredOnRow: "ਕਤਾਰ 'ਤੇ ਉਤਪਾਦ ਦੀ ਮਾਤਰਾ ਲੋੜੀਂਦੀ ਹੈ",
    productVariantsRequireTracking: "ਉਤਪਾਦ ਰੂਪਾਂ ਨੂੰ ਟਰੈਕਿੰਗ ਦੀ ਲੋੜ ਹੁੰਦੀ ਹੈ!",
    pleaseAddVariantClickButton:
      "ਕਿਰਪਾ ਕਰਕੇ ਐਡ ਬਟਨ ਤੇ ਕਲਿਕ ਕਰਕੇ ਇੱਕ ਰੂਪ ਸ਼ਾਮਲ ਕਰੋ!",
    totalVariantsMoreThanSelectedQuantity:
      "ਕੁੱਲ ਵੇਰੀਐਂਟ ਚੁਣੀ ਗਈ ਉਤਪਾਦ ਮਾਤਰਾ ਤੋਂ ਵੱਧ ਹਨ, Pls ਵੇਰੀਐਂਟ ਦੀ ਮਾਤਰਾ ਨੂੰ ਘਟਾਉਂਦੇ ਹਨ",
    productEditedSuccessfully: "ਉਤਪਾਦ ਸਫਲਤਾਪੂਰਵਕ ਸੰਪਾਦਿਤ ਕੀਤਾ ਗਿਆ ਹੈ!",
    fileTooLargeLessThan4Mb:
      "ਫਾਈਲ ਦਾ ਆਕਾਰ ਬਹੁਤ ਵੱਡਾ ਹੈ! ਫਾਈਲ ਦਾ ਆਕਾਰ 4MB ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ.",
    suchVariantAlreadyExist: "ਅਜਿਹਾ ਰੂਪ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ",
    addVariants: "ਰੂਪ ਸ਼ਾਮਲ ਕਰੋ",
    editProduct: "ਉਤਪਾਦ ਸੰਪਾਦਿਤ ਕਰੋ",
    pleaseEnterProductName: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    pleaseEnterProductPrice: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਦੀ ਕੀਮਤ ਦਰਜ ਕਰੋ",
    pleaseEnterProductOriginalPrice: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਦੀ ਅਸਲ ਕੀਮਤ ਦਾਖਲ ਕਰੋ",
    productDescription: "ਉਤਪਾਦ ਵੇਰਵਾ",
    selectProductCategory: "ਉਤਪਾਦ ਸ਼੍ਰੇਣੀ ਦੀ ਚੋਣ ਕਰੋ",
    pleaseSelectProductCategory: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਸ਼੍ਰੇਣੀ ਦੀ ਚੋਣ ਕਰੋ",
    productCostPrice: "ਉਤਪਾਦ ਦੀ ਲਾਗਤ ਕੀਮਤ",
    productSellingPrice: "ਉਤਪਾਦ ਵੇਚਣ ਦੀ ਕੀਮਤ",
    productOriginalPrice: "ਉਤਪਾਦ ਦੀ ਮੂਲ ਕੀਮਤ",
    maxFileSizeAllowedIs4Mb: "ਅਧਿਕਤਮ ਫਾਈਲ ਅਕਾਰ ਦੀ ਆਗਿਆ 4mb ਹੈ",
    productsWithPicturesArePublished:
      "ਆਰਡਰ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਤਸਵੀਰਾਂ ਵਾਲੇ ਉਤਪਾਦ ਡਿਸਕਵਰ 'ਤੇ ਪ੍ਰਕਾਸ਼ਤ ਕੀਤੇ ਜਾਂਦੇ ਹਨ",
    shouldThisProductBeTracked: "ਕੀ ਇਸ ਉਤਪਾਦ ਨੂੰ ਟਰੈਕ ਕੀਤਾ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ?",
    pleaseSelectStockUnit: "ਕਿਰਪਾ ਕਰਕੇ ਸਟਾਕ ਯੂਨਿਟ ਦੀ ਚੋਣ ਕਰੋ",
    stockUnit: "ਸਟਾਕ ਯੂਨਿਟ",
    bag: "ਬੈਗ",
    bottle: "ਬੋਤਲ",
    bunch: "ਝੁੰਡ",
    can: "CAN",
    carton: "ਕਾਰਟਨ",
    crate: "ਕ੍ਰੇਟ",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "ਗੀਗਾਬਾਈਟਸ",
    gram: "ਗ੍ਰਾਮ",
    kilogram: "ਕਿਲੋਗ੍ਰਾਮ",
    litre: "ਲਿਟਰ",
    pack: "ਪੈਕ",
    pair: "ਜੋੜੀ",
    pieces: "ਟੁਕੜੇ",
    plate: "ਪਲੇਟ",
    tonne: "ਟੋਨ (ਐਮਟੀ)",
    uNIT: "ਯੂਨਿਟ",
    yard: "ਵਿਹੜਾ",
    pleaseEnterProductQuantity: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਦੀ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    productQuantity: "ਉਤਪਾਦ ਦੀ ਮਾਤਰਾ",
    isThisProductTaxed: "ਕੀ ਇਸ ਉਤਪਾਦ ਤੇ ਟੈਕਸ ਲਗਾਇਆ ਜਾਂਦਾ ਹੈ?",
    selectTaxType: "ਟੈਕਸ ਦੀ ਕਿਸਮ ਚੁਣੋ",
    pleaseSelectTaxType: "ਕਿਰਪਾ ਕਰਕੇ ਟੈਕਸ ਦੀ ਕਿਸਮ ਚੁਣੋ",
    progressive: "ਪ੍ਰਗਤੀਸ਼ੀਲ",
    proportional: "ਅਨੁਪਾਤਕ",
    pleaseEnterProductTaxRate: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਟੈਕਸ ਦਰ ਦਾਖਲ ਕਰੋ",
    doesProductHaveVariants: "ਕੀ ਇਸ ਉਤਪਾਦ ਦੇ ਰੂਪ ਹਨ?",
    type: "ਕਿਸਮ",
    value: "ਮੁੱਲ",
    pleaseEnterVariantType: "ਕਿਰਪਾ ਕਰਕੇ ਰੂਪ ਦੀ ਕਿਸਮ ਦਾਖਲ ਕਰੋ",
    pleaseEnterVariantValue: "ਕਿਰਪਾ ਕਰਕੇ ਰੂਪ ਦਾ ਮੁੱਲ ਦਾਖਲ ਕਰੋ",
    pleaseEnterVariantPrice: "ਕਿਰਪਾ ਕਰਕੇ ਵੇਰੀਐਂਟ ਦੀ ਕੀਮਤ ਦਾਖਲ ਕਰੋ",
    pleaseEnterVariantQuantity: "ਕਿਰਪਾ ਕਰਕੇ ਰੂਪ ਦੀ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    productDeletedSuccessfully: "ਉਤਪਾਦ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ!",
    categoryDeletedSuccessfully: "ਸ਼੍ਰੇਣੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ!",
    toDeleteThisProduct: "ਇਸ ਉਤਪਾਦ ਨੂੰ ਮਿਟਾਉਣ ਲਈ",
    invalidProductQuantity: "ਅਵੈਧ ਉਤਪਾਦ ਦੀ ਮਾਤਰਾ",
    quantityAddedIsOverStock:
      "ਜੋ ਮਾਤਰਾ ਤੁਸੀਂ ਜੋੜ ਰਹੇ ਹੋ ਉਹ ਤੁਹਾਡੇ ਕੋਲ ਸਟਾਕ ਵਿੱਚ ਹੈ.",
    itemInventoryNotTracked: "ਆਈਟਮ ਵਸਤੂ ਸੂਚੀ ਟਰੈਕ ਨਹੀਂ ਕੀਤੀ ਗਈ ਹੈ",
    addBulkQuantity: "ਥੋਕ ਮਾਤਰਾ ਸ਼ਾਮਲ ਕਰੋ",
    enterBulkQuantity: "ਥੋਕ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    pleaseEnterBulkQuantity: "ਕਿਰਪਾ ਕਰਕੇ ਥੋਕ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    youveSuccessfullyAddedANewProduct:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵਾਂ ਉਤਪਾਦ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ!",
    pleaseEnterProductSellingPrice: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਦੀ ਵਿਕਰੀ ਮੁੱਲ ਦਾਖਲ ਕਰੋ",
    doYouWantToTrackProductStock:
      "ਕੀ ਤੁਸੀਂ ਉਤਪਾਦ ਸਟਾਕ ਨੂੰ ਟਰੈਕ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    sellingPrice: "ਵੇਚਣ ਦੀ ਕੀਮਤ",
    setProductExpiryReminder: "ਉਤਪਾਦ ਦੀ ਮਿਆਦ ਸਮਾਪਤੀ ਰੀਮਾਈਂਡਰ ਸੈਟ ਕਰੋ",
    productExpiryDate: "ਉਤਪਾਦ ਦੀ ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਤਾਰੀਖ",
    startRemindingFrom: "ਤੋਂ ਯਾਦ ਕਰਾਉਣਾ ਅਰੰਭ ਕਰੋ",
    productSuppliesAddedSuccessfully:
      "ਤੁਸੀਂ ਉਤਪਾਦਾਂ ਦੀ ਸਪਲਾਈ ਸਫਲਤਾਪੂਰਵਕ ਸ਼ਾਮਲ ਕੀਤੀ ਹੈ.",
    addProductSupplies: "ਉਤਪਾਦ ਸਪਲਾਈ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseSelectSupplier: "ਕਿਰਪਾ ਕਰਕੇ ਸਪਲਾਇਰ ਦੀ ਚੋਣ ਕਰੋ",
    nameOfProduct: "ਉਤਪਾਦ ਦਾ ਨਾਮ",
    pleaseSelectProduct: "ਕਿਰਪਾ ਕਰਕੇ ਉਤਪਾਦ ਚੁਣੋ",
    productVariant: "ਉਤਪਾਦ ਰੂਪ",
    pleaseSelectAVariant: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਰੂਪ ਦੀ ਚੋਣ ਕਰੋ",
    pleaseEnterUnitPrice: "ਕਿਰਪਾ ਕਰਕੇ ਯੂਨਿਟ ਕੀਮਤ ਦਰਜ ਕਰੋ",
    businessBranch: "ਵਪਾਰ ਸ਼ਾਖਾ",
    pleaseSelectBranch: "ਕਿਰਪਾ ਕਰਕੇ ਸ਼ਾਖਾ ਦੀ ਚੋਣ ਕਰੋ",
    youveSuccessfullyAddedANewSupplier:
      "ਤੁਸੀਂ ਸਫਲਤਾਪੂਰਵਕ ਇੱਕ ਨਵਾਂ ਸਪਲਾਇਰ ਸ਼ਾਮਲ ਕੀਤਾ ਹੈ",
    addSupplier: "ਸਪਲਾਇਰ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseEnterSupplierEmail: "ਕਿਰਪਾ ਕਰਕੇ ਸਪਲਾਇਰ ਦੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    pleaseAddADescription: "ਕਿਰਪਾ ਕਰਕੇ ਵੇਰਵਾ ਸ਼ਾਮਲ ਕਰੋ",
    anErrorOccuredProductsDeleted:
      "ਕਾਰਵਾਈ ਕਰਦੇ ਸਮੇਂ ਇੱਕ ਗੜਬੜ ਹੋ ਗਈ. ਕਿਰਪਾ ਕਰਕੇ ਨੋਟ ਕਰੋ, ਪ੍ਰਕਿਰਿਆ ਵਿੱਚ ਕੁਝ ਉਤਪਾਦ ਮਿਟਾਏ ਜਾ ਸਕਦੇ ਹਨ",
    bulkDelete: "ਥੋਕ ਮਿਟਾਓ",
    youAreAboutToDelete: "ਤੁਸੀਂ ਮਿਟਾਉਣ ਜਾ ਰਹੇ ਹੋ",
    product: "ਉਤਪਾਦ",
    isDueToRedeem: "ਛੁਡਾਉਣ ਦੇ ਕਾਰਨ ਹੈ",
    aReward: "ਇੱਕ ਇਨਾਮ",
    pleaseSelectCustomerToReeemReward:
      "ਕਿਰਪਾ ਕਰਕੇ ਇਨਾਮ ਛੁਡਾਉਣ ਲਈ ਇੱਕ ਗਾਹਕ ਦੀ ਚੋਣ ਕਰੋ.",
    youHaveNoLoyaltyProgramRunning:
      "ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਸਰਗਰਮ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਨਹੀਂ ਚੱਲ ਰਿਹਾ",
    customerHhasNoPointsInLoyaltyProgram:
      "ਇਸ ਵਫ਼ਾਦਾਰੀ ਪ੍ਰੋਗਰਾਮ ਵਿੱਚ ਗਾਹਕ ਦਾ ਕੋਈ ਨੁਕਤਾ ਨਹੀਂ ਹੈ",
    proceedWithPayment: "ਭੁਗਤਾਨ ਦੇ ਨਾਲ ਅੱਗੇ ਵਧਣਾ ਹੈ?",
    youAreAboutToPayForTransactionUsingPoints:
      "ਤੁਸੀਂ ਪੁਆਇੰਟਾਂ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਲਈ ਭੁਗਤਾਨ ਕਰਨ ਜਾ ਰਹੇ ਹੋ.",
    customerHas: "ਗਾਹਕ ਕੋਲ ਹੈ",
    worth: "ਕੀਮਤ",
    andIsNotSufficientToPayForTransaction:
      "ਅਤੇ ਇਸ ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਲਈ ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਕਾਫੀ ਨਹੀਂ ਹੈ. ਕੀ ਉਹ ਕਿਸੇ ਹੋਰ ਭੁਗਤਾਨ ਵਿਧੀ ਦੀ ਵਰਤੋਂ ਕਰਕੇ ਬਕਾਇਆ ਜੋੜਨਾ ਚਾਹੁੰਦੇ ਹਨ?",
    addCustomerLoyalty: "ਗਾਹਕ ਵਫ਼ਾਦਾਰੀ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseAddCustomerFirst: "ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਇੱਕ ਗਾਹਕ ਸ਼ਾਮਲ ਕਰੋ ਜਾਂ ਚੁਣੋ.",
    pleaseWaitWhileWeGetReady:
      "ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ, ਜਦੋਂ ਤੱਕ ਅਸੀਂ ਤਿਆਰ ਹੁੰਦੇ ਹਾਂ",
    lowStock: "ਘੱਟ ਸਟਾਕ",
    pleaseEnterAmountTendered: "ਕਿਰਪਾ ਕਰਕੇ ਦਿੱਤੀ ਗਈ ਰਕਮ ਦਾਖਲ ਕਰੋ",
    areYouSureToBookSaleOffline:
      "ਕੀ ਤੁਸੀਂ ਪੱਕਾ ਇਸ ਵਿਕਰੀ ਨੂੰ offlineਫਲਾਈਨ ਬੁੱਕ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    saleWouldBeBookedAutomatically:
      "ਜਦੋਂ ਤੁਸੀਂ ਆਪਣਾ ਇੰਟਰਨੈਟ ਚਾਲੂ ਕਰਦੇ ਹੋ ਤਾਂ ਵਿਕਰੀ ਆਪਣੇ ਆਪ ਬੁੱਕ ਹੋ ਜਾਂਦੀ ਹੈ",
    offlineSalesBookingCancelled: "Lineਫਲਾਈਨ ਵਿਕਰੀ ਬੁਕਿੰਗ ਰੱਦ ਕੀਤੀ ਗਈ",
    covid19Message:
      "ਕੋਵਿਡ 19: ਫੈਲਣ ਨੂੰ ਰੋਕਣ ਲਈ ਆਪਣੇ ਹੱਥ ਸਾਬਣ ਨਾਲ ਧੋਵੋ ਜਾਂ ਰੋਗਾਣੂ ਮੁਕਤ ਕਰੋ. ਹਮੇਸ਼ਾ ਸੁਰੱਖਿਅਤ ਰਹੋ",
    saleSuccessfullyRecorded: "ਵਿਕਰੀ ਸਫਲਤਾਪੂਰਵਕ ਰਿਕਾਰਡ ਕੀਤੀ ਗਈ!",
    sendReceiptToEmail: "ਈਮੇਲ ਤੇ ਰਸੀਦ ਭੇਜੋ",
    sendThankYouSms: "ਧੰਨਵਾਦ ਐਸਐਮਐਸ ਭੇਜੋ",
    sendShippingDetails: "ਸ਼ਿਪਿੰਗ ਵੇਰਵੇ ਭੇਜੋ",
    addLoyalty: "ਵਫ਼ਾਦਾਰੀ ਸ਼ਾਮਲ ਕਰੋ",
    searchCustomerNameOrNumber: "ਗਾਹਕ ਦਾ ਨਾਮ ਜਾਂ ਨੰਬਰ ਖੋਜੋ",
    clickTheSearchCustomerBox: "ਖੋਜ ਗਾਹਕ ਬਾਕਸ ਅਤੇ ਸਕੈਨ ਕਾਰਡ ਤੇ ਕਲਿਕ ਕਰੋ",
    enterProductPrice: "ਉਤਪਾਦ ਦੀ ਕੀਮਤ ਦਰਜ ਕਰੋ",
    enterPriceFor: "ਲਈ ਕੀਮਤ ਦਰਜ ਕਰੋ",
    searchForProduct: "ਉਤਪਾਦ ਦੀ ਖੋਜ ਕਰੋ",
    all: "ਸਾਰੇ",
    backToDashboard: "ਡੈਸ਼ਬੋਰਡ ਤੇ ਵਾਪਸ ਜਾਓ",
    viewDraftSales: "ਡਰਾਫਟ ਵਿਕਰੀ ਵੇਖੋ",
    variantSelected: "ਰੂਪ ਚੁਣਿਆ ਗਿਆ",
    variant: "ਰੂਪ",
    thePreviousVariantSelectionNotAvailable:
      "ਕੀਮਤ ਦੇ ਅਧਾਰ ਤੇ ਚੁਣੇ ਗਏ ਨਵੇਂ ਵੇਰੀਐਂਟ ਲਈ ਪਿਛਲਾ ਵੇਰੀਐਂਟ ਚੋਣ ਉਪਲਬਧ ਨਹੀਂ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਚੋਣ ਬਦਲੋ.",
    pleaseSupplyPositiveQuantityNumber:
      "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਪੋਸਟਿਵ ਮਾਤਰਾ ਸੰਖਿਆ ਪ੍ਰਦਾਨ ਕਰੋ",
    lowStockFor: "ਲਈ ਘੱਟ ਸਟਾਕ",
    clearVariants: "ਰੂਪਾਂ ਨੂੰ ਸਾਫ਼ ਕਰੋ",
    pleaseEnterQuantity: "ਕਿਰਪਾ ਕਰਕੇ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    picture: "ਤਸਵੀਰ",
    redemptionToken: "ਛੁਟਕਾਰਾ ਟੋਕਨ",
    token: "ਟੋਕਨ",
    totalSpent: "ਕੁੱਲ ਖਰਚ",
    confirmPayment: "ਭੁਗਤਾਨ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ",
    hasPaymentBeenMade: "ਕੀ ਭੁਗਤਾਨ ਦੀ ਸਫਲਤਾਪੂਰਵਕ ਪ੍ਰਕਿਰਿਆ ਕੀਤੀ ਗਈ ਹੈ?",
    enterTransactionReference:
      "ਤੁਹਾਡੇ ਦੁਆਰਾ ਭੁਗਤਾਨ ਕੀਤੇ ਗਏ ਟ੍ਰਾਂਜੈਕਸ਼ਨ ਸੰਦਰਭ ਨੂੰ ਦਾਖਲ ਕਰੋ",
    pleaseSelectACustomer: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਗਾਹਕ ਚੁਣੋ!",
    areYouSureToApplyDiscount:
      "ਕੀ ਤੁਹਾਨੂੰ ਯਕੀਨ ਹੈ ਕਿ ਤੁਸੀਂ ਛੋਟ ਲਾਗੂ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    addYourBankAccountToEnableUssd:
      "ਯੂਪੇ ਦੁਆਰਾ ਤੁਰੰਤ ਯੂਐਸਐਸਡੀ ਟ੍ਰਾਂਸਫਰ ਨੂੰ ਸਮਰੱਥ ਬਣਾਉਣ ਲਈ ਆਪਣਾ ਬੈਂਕ ਖਾਤਾ ਸ਼ਾਮਲ ਕਰੋ",
    totalAmountToPay: "ਭੁਗਤਾਨ ਕਰਨ ਦੀ ਕੁੱਲ ਰਕਮ",
    doYouWantToCancelTransaction:
      "ਕੀ ਤੁਸੀਂ ਇਸ ਲੈਣ -ਦੇਣ ਨੂੰ ਰੱਦ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?",
    savePrintBill: "ਬਿੱਲ ਸੁਰੱਖਿਅਤ/ਪ੍ਰਿੰਟ ਕਰੋ",
    enterAmountCollectedForCustomer: "ਗਾਹਕ ਲਈ ਇਕੱਠੀ ਕੀਤੀ ਰਕਮ ਦਾਖਲ ਕਰੋ",
    recordSale: "ਰਿਕਾਰਡ ਵਿਕਰੀ",
    checkOutWith: "ਨਾਲ ਚੈੱਕ ਆਟ ਕਰੋ",
    instantTransfer: "ਤਤਕਾਲ ਤਬਾਦਲਾ",
    dialTheUSSDCode: "ਯੂਐਸਐਸਡੀ ਕੋਡ ਡਾਇਲ ਕਰੋ",
    pleaseSelectABank: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਬੈਂਕ ਚੁਣੋ",
    payWithUSSD: "USSD ਨਾਲ ਭੁਗਤਾਨ ਕਰੋ",
    sendBillTo: " - ਨੂੰ ਬਿੱਲ ਭੇਜੋ",
    waitingForUSSDTransfer: "ਯੂਐਸਐਸਡੀ ਟ੍ਰਾਂਸਫਰ ਦੀ ਉਡੀਕ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ",
    percent: "ਪ੍ਰਤੀਸ਼ਤ",
    applyDiscount: "ਛੋਟ ਲਾਗੂ ਕਰੋ",
    thisBillHasBeenSaved: "ਇਹ ਬਿੱਲ ਸੁਰੱਖਿਅਤ ਹੋ ਗਿਆ ਹੈ",
    saveDraft: "ਡਰਾਫਟ ਸੁਰੱਖਿਅਤ ਕਰੋ",
    pleaseTypeANameToIdentifyCustomer:
      "ਕਿਰਪਾ ਕਰਕੇ ਗਾਹਕ ਦੀ ਪਛਾਣ ਕਰਨ ਲਈ ਇੱਕ ਨਾਮ ਟਾਈਪ ਕਰੋ",
    paymentDetails: "ਭੁਗਤਾਨ ਵੇਰਵੇ",
    basePrice: "ਅਧਾਰ ਕੀਮਤ",
    staff: "ਸਟਾਫ",
    subTotal: "ਉਪ -ਕੁੱਲ",
    durationMonths: "ਮਿਆਦ (ਮਹੀਨੇ)",
    selectADuration: "ਇੱਕ ਮਿਆਦ ਚੁਣੋ",
    oneMonth: "1 ਮਹੀਨਾ",
    twoMonths: "2 ਮਹੀਨੇ",
    threeMonths: "3 ਮਹੀਨੇ",
    sixMonths: "6 ਮਹੀਨੇ",
    twelveMonths: "12 ਮਹੀਨੇ",
    eighteenMonths: "18 ਮਹੀਨੇ",
    twentyFourMonths: "24 ਮਹੀਨੇ",
    twoMonthsFree: "(2 ਮਹੀਨੇ ਮੁਫਤ)",
    threeMonthsFree: "(3 ਮਹੀਨੇ ਮੁਫਤ)",
    fiveMonthsFree: "(5 ਮਹੀਨੇ ਮੁਫਤ)",
    yourAccountHasBeen: "ਤੁਹਾਡਾ ਖਾਤਾ ਹੋ ਗਿਆ ਹੈ",
    renewed: "ਨਵਿਆਇਆ",
    upgraded: "ਅਪਗ੍ਰੇਡ ਕੀਤਾ",
    successfully: "ਸਫਲਤਾਪੂਰਵਕ",
    yourSubscription: "ਤੁਹਾਡੀ ਗਾਹਕੀ",
    youAreCurrentlyEnrolledOnA: "ਤੁਸੀਂ ਇਸ ਵੇਲੇ ਏ ਤੇ ਦਾਖਲ ਹੋ",
    pleaseChooseAPaymentOption: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਭੁਗਤਾਨ ਵਿਕਲਪ ਚੁਣੋ",
    planRenewal: "ਨਵਿਆਉਣ ਦੀ ਯੋਜਨਾ ਬਣਾਉ",
    planUpgrade: "ਯੋਜਨਾ ਅਪਗ੍ਰੇਡ ਕਰੋ",
    pleaseSelectDurationToPayFor:
      "ਕਿਰਪਾ ਕਰਕੇ ਉਹ ਮਿਆਦ ਚੁਣੋ ਜਿਸਦਾ ਤੁਸੀਂ ਭੁਗਤਾਨ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    staffAccounts: "ਸਟਾਫ ਖਾਤੇ",
    ecommerce: "ਈ -ਕਾਮਰਸ",
    pleaseSelectAPlan: "ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਯੋਜਨਾ ਚੁਣੋ",
    includeAddons: "ਐਡ-ਆਨ ਸ਼ਾਮਲ ਕਰੋ",
    viewTransactions: "ਲੈਣ -ਦੇਣ ਵੇਖੋ",
    customerHasNoCompletedTansactions:
      "ਗਾਹਕ ਨੇ ਅਜੇ ਤੱਕ ਕੋਈ ਸੰਪੂਰਨ ਕਾਰਜ ਨਹੀਂ ਕੀਤਾ ਹੈ",
    branch: "ਸ਼ਾਖਾ",
    enterNumberOfEcommerceBranches: "ਈ -ਕਾਮਰਸ ਸ਼ਾਖਾਵਾਂ ਦੀ ਸੰਖਿਆ ਦਰਜ ਕਰੋ",
    enterNumberOfEcommerceBranchesToPay:
      "ਉਹਨਾਂ ਈ -ਕਾਮਰਸ ਸ਼ਾਖਾਵਾਂ ਦੀ ਗਿਣਤੀ ਦਰਜ ਕਰੋ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਸੀਂ ਭੁਗਤਾਨ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    ecommerceIntegration: "ਈਕਾੱਮਰਸ ਏਕੀਕਰਣ",
    enterNumberOfBranches: "ਸ਼ਾਖਾਵਾਂ ਦੀ ਗਿਣਤੀ ਦਰਜ ਕਰੋ",
    enterNumberOfAdditionalBranchesToPay:
      "ਉਨ੍ਹਾਂ ਅਤਿਰਿਕਤ ਸ਼ਾਖਾਵਾਂ ਦੀ ਸੰਖਿਆ ਦਰਜ ਕਰੋ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਸੀਂ ਭੁਗਤਾਨ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    enterNumberOfStaffs: "ਸਟਾਫ ਦੀ ਗਿਣਤੀ ਦਰਜ ਕਰੋ",
    enterNumberOfStaffsToPayFor:
      "ਉਨ੍ਹਾਂ ਸਟਾਫਾਂ ਦੀ ਗਿਣਤੀ ਦਰਜ ਕਰੋ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਸੀਂ ਭੁਗਤਾਨ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ",
    discountApplies: "ਛੋਟ ਲਾਗੂ ਹੁੰਦੀ ਹੈ",
    starsOnThe: "'ਤੇ ਤਾਰੇ",
    offer: "ਪੇਸ਼ਕਸ਼",
    get: "ਪ੍ਰਾਪਤ ਕਰੋ",
    moreStarsToRedeem: "ਰਿਡੀਮ ਕਰਨ ਲਈ ਹੋਰ ਸਿਤਾਰੇ",
    taxVat: "ਟੈਕਸ (ਵੈਟ)",
    callCashierToCompletePayment: "ਭੁਗਤਾਨ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਕੈਸ਼ੀਅਰ ਨੂੰ ਕਾਲ ਕਰੋ",
    receipt: "ਰਸੀਦ",
    dear: "ਪਿਆਰੇ",
    thankYouForYourOrderFindGoods:
      "ਤੁਹਾਡੇ ਆਰਡਰ ਲਈ ਧੰਨਵਾਦ. ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੇ ਸਮਾਨ ਦੀ ਸਪਲਾਈ ਕਰੋ, ਜਿਵੇਂ ਸਹਿਮਤੀ ਦਿੱਤੀ ਗਈ ਹੈ.",
    shippingNote: "ਸ਼ਿਪਿੰਗ ਨੋਟ",
    enterShippingNote: "ਸ਼ਿਪਿੰਗ ਨੋਟ ਦਾਖਲ ਕਰੋ",
    shippingAddress: "ਸ਼ਿਪਿੰਗ ਪਤਾ",
    enterShippingAddress: "ਸ਼ਿਪਿੰਗ ਪਤਾ ਦਾਖਲ ਕਰੋ",
    wellDoneYouAreDueToRedeem: "ਬਹੁਤ ਖੂਬ! ਤੁਸੀਂ ਛੁਡਾਉਣ ਦੇ ਕਾਰਨ ਹੋ",
    toGetYourRewardNextVisit:
      "ਆਪਣੀ ਅਗਲੀ ਫੇਰੀ ਤੇ ਆਪਣਾ ਇਨਾਮ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ. ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    pointsOnThe: "'ਤੇ ਅੰਕ",
    morePointsToRedeem: "ਰਿਡੀਮ ਕਰਨ ਲਈ ਹੋਰ ਅੰਕ",
    showCode: "ਕੋਡ ਦਿਖਾਓ",
    toGetYourRewardOnNextVisit:
      "ਆਪਣੀ ਅਗਲੀ ਫੇਰੀ ਤੇ ਆਪਣਾ ਇਨਾਮ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ. ਤੁਹਾਡਾ ਧੰਨਵਾਦ",
    earn: "ਕਮਾਉ",
    delivaryNote: "ਡਿਲਿਵਰੀ ਨੋਟ",
    draftSales: "ਡਰਾਫਟ ਵਿਕਰੀ",
    startDate: "ਤਾਰੀਖ ਸ਼ੁਰੂ",
    endDate: "ਸਮਾਪਤੀ ਤਾਰੀਖ",
    orders: "ਆਦੇਸ਼",
    checkout: "ਕਮਰਾ ਛੱਡ ਦਿਓ",
    noProductItem: "ਕੋਈ ਉਤਪਾਦ ਆਈਟਮ ਨਹੀਂ",
    selectProductImage: "ਉਤਪਾਦ ਚਿੱਤਰ ਦੀ ਚੋਣ ਕਰੋ",
    selectCountry: "ਦੇਸ਼ ਚੁਣੋ",
    selectRegion: "ਰਾਜ/ਖੇਤਰ ਦੀ ਚੋਣ ਕਰੋ",
    printProductTag: "ਉਤਪਾਦ ਟੈਗ ਛਾਪੋ",
    transactionReference: "ਲੈਣ -ਦੇਣ ਸੰਦਰਭ",
    Cashier: "ਕੈਸ਼ੀਅਰ",
    Manager: "ਮੈਨੇਜਰ",
    Owner: "ਮਾਲਕ",
    Admin: "ਪ੍ਰਸ਼ਾਸਕ",
    addPartners: "ਭਾਈਵਾਲ ਸ਼ਾਮਲ ਕਰੋ",
    addNewLoyaltyPartner: "ਨਵਾਂ ਵਫ਼ਾਦਾਰ ਸਾਥੀ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseEnterCompanyName: "ਕਿਰਪਾ ਕਰਕੇ ਕੰਪਨੀ ਦਾ ਨਾਮ ਦਰਜ ਕਰੋ",
    companyName: "ਕੰਪਨੀ ਦਾ ਨਾਂ",
    pleaseEnterCompanyRepName: "ਕਿਰਪਾ ਕਰਕੇ ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    companyRepName: "ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦਾ ਨਾਮ",
    pleaseEnterCompanyRepEmail:
      "ਕਿਰਪਾ ਕਰਕੇ ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦੀ ਈਮੇਲ ਦਾਖਲ ਕਰੋ",
    companyRepEmail: "ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦੀ ਈਮੇਲ",
    pleaseEnterCompanyRepPhone:
      "ਕਿਰਪਾ ਕਰਕੇ ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦਾ ਫ਼ੋਨ ਨੰਬਰ ਦਾਖਲ ਕਰੋ",
    companyRepPhone: "ਕੰਪਨੀ ਦੇ ਪ੍ਰਤੀਨਿਧੀ ਦਾ ਫ਼ੋਨ ਨੰਬਰ",
    addReward: "ਇਨਾਮ ਸ਼ਾਮਲ ਕਰੋ",
    pleaseEnterRewardName: "ਕਿਰਪਾ ਕਰਕੇ ਇਨਾਮ ਦਾ ਨਾਮ ਦਾਖਲ ਕਰੋ",
    rewardName: "ਇਨਾਮ ਦਾ ਨਾਮ",
    rewardQuantity: "ਇਨਾਮ ਦੀ ਮਾਤਰਾ",
    rewardDescription: "ਇਨਾਮ ਦਾ ਵਰਣਨ",
    rewardType: "ਇਨਾਮ ਦੀ ਕਿਸਮ",
    pleaseEnterRewardType: "ਕਿਰਪਾ ਕਰਕੇ ਇਨਾਮ ਦੀ ਕਿਸਮ ਦਾਖਲ ਕਰੋ",
    pleaseEnterRewardQuantity: "ਕਿਰਪਾ ਕਰਕੇ ਇਨਾਮ ਦੀ ਮਾਤਰਾ ਦਾਖਲ ਕਰੋ",
    pleaseEnterRewardDescription: "ਕਿਰਪਾ ਕਰਕੇ ਇਨਾਮ ਦਾ ਵੇਰਵਾ ਦਾਖਲ ਕਰੋ",
    fineDining: "ਵਧੀਆ ਖਾਣਾ",
    luxuryFashion: "ਲਗਜ਼ਰੀ ਫੈਸ਼ਨ",
    hotels: "ਹੋਟਲ",
    travel: "ਯਾਤਰਾ",
    foodAndBeverage: "ਭੋਜਨ ਅਤੇ ਪੀਣ ਵਾਲੇ ਪਦਾਰਥ",
    fashion: "ਫੈਸ਼ਨ",
    health: "ਸਿਹਤ",
    furniture: "ਫਰਨੀਚਰ",
    entertainment: "ਮਨੋਰੰਜਨ",
    automobile: "ਆਟੋਮੋਬਾਈਲ",
    education: "ਸਿੱਖਿਆ",
    beautyAndSpa: "ਸੁੰਦਰਤਾ ਅਤੇ ਸਪਾ",
    staycation: "ਸਟੇਕੇਸ਼ਨ",
    events: "ਸਮਾਗਮ",
    trips: "ਯਾਤਰਾਵਾਂ",
    oilAndGas: "ਤੇਲ ਅਤੇ ਗੈਸ",
    laundry: "ਲਾਂਡਰੀ",
    partnerCategory: "ਸਾਥੀ ਸ਼੍ਰੇਣੀ",
    freeItem: "ਮੁਫ਼ਤ ਆਈਟਮ",
  },
  Samoan: {
    cashier: "kesia",
    manager: "pule",
    owner: "pule",
    online: "lugalaina",
    offline: "offline",
    changePassword: "Suia Upu Faʻamau",
    currentPasswordMessage: "Faʻamolemole faʻaulu mai lau faʻaupuga nei",
    passwordMessage: "Faʻamolemole faʻaulu lau password",
    currentPassword: "upu tuanai o le taimi nei",
    password: "numera e le iloa e sesi",
    confirmPasswordMessage: "Faʻamolemole faʻamaonia lau faʻaupuga!",
    confirmPassword: "faamaonia le upu faalilolilo",
    sendViaEmail: "Lafo Via Imeli",
    sendViaWhatsapp: "Lafo Via WhatsApp",
    downloadPdf: "Lalotoso PDF",
    paid: "totogi",
    unpaid: "lē totogia",
    partial: "faʻailoga",
    closeInvoice: "E te manaʻo e tapuni le pili?",
    closeInvoiceConfirmation:
      "E le mafai ona sefe aitalafu. E te manaʻo e tapuni?",
    yes: "ioe",
    no: "leai",
    invoice: "Tupe totogi",
    wasDeducted: "na toesea",
    for: "aua",
    item: "Aitema",
    addProduct: "Faʻaopopo oloa",
    paymentReference: "Totogi Totogi",
    amountPaid: "Aofaʻiga Totogi",
    discountAmount: "Aofaʻi pau",
    amountDue: "Aofaʻiga Totogi",
    amount: "Aofaʻiga",
    dueDate: "O afea",
    paymentType: "Totogi Totogi",
    card: "Card",
    cash: "Tupe",
    bankTransfer: "Faletupe Faletupe",
    paymentMessage: "Totogi Savali",
    description: "Faʻamatalaga",
    sendReceipt: "Lafo lisiti",
    delete: "Aveese",
    save: "Sefe",
    resend: "Toe lafo",
    saveAndSend: "Sefe",
    invoiceSaved: "Ua sefe aitalafu!",
    selectPaymentMethod: "Faʻamolemole filifili se Totogi Metotia!",
    selectCustomer: "Faʻamolemole filifili se Tagata Faʻatau!",
    cartEmptyError:
      "Lisi o kata e le mafai ona gaogao, tapuni le pili ma faʻaopoopo le aitema i le kata!",
    subscriptionExpired:
      "Ua maeʻa lau lesitala ma ua faʻatapulaʻa lau teuga tupe. Kiliki i le Faʻamau i lalo e faʻafou ai lau teuga tupe",
    renew: "Faafouina",
    holdOn: "Faatali laia",
    customerBank: "Faletupe Tagata Faʻatau",
    cancel: "Faalēaogāina",
    selectACustomer: "Filifili Tagata Faʻatau",
    invoiceSuccessfulPdfError:
      "Na lelei le fausiaina o pili ae o le PDF Generation e umi atu le umi nai lo le mea e masani ai. Faʻamolemole toe siaki mai lata mai.",
    downloadingInvoice: "Sii mai o pili",
    downloadingReceipt: "Lolomiina lau lisiti",
    whatsappReceiptError:
      "Se mea sese na tupu ile lafoina o le lisiti e ala i WhatsApp, Faʻamolemole toe taumafai.",
    receiptToWhatsapp: "Tupe maua lafo i WhatsApp",
    thankYouForPatronage: "Faʻafetai mo lau lagolago",
    hereIsYourReceipt: "Lenei o lau lisiti totogiina",
    errorSendingEmailReceipt:
      "O se mea sese na tupu i le lafoina o le lisiti e ala i le i-meli, faʻamolemole toe taumafai pe faʻafesoʻotaʻi le lagolago",
    receiptSentToEmail: "Lisi ua lafoina i le tagata imeli imeli",
    invoiceSentToEmail: "Ua lafoina aitalafu ile imeli a tagata faatau",
    invoiceSuccessWhatsappError:
      "Na faʻatupuina ma le manuia aitalafu ae o se mea sese na tupu i le lafoina i WhatsApp. Toe taumafai i luga o le lisi o pili",
    invoiceSuccessfulEmailError:
      "Na manuia le fausiaina o pili ae o se mea sese na tupu a o lafoina mai se i-meli. Faʻamolemole toe taumafai mai le lisi o pili",
    invoiceSentToWhatsapp: "Faʻasolo atu pepa i WhatsApp",
    hello: "talofa",
    pleaseDownloadInvoice: "Faʻamolemole laulau mai lau iniseti",
    pleaseDownloadReceipt: "Faʻamolemole aumai le lisiti",
    from: "mai",
    email: "Imeli",
    upgrade: "Faʻaleleia",
    youAreOnFreePlan: "O loʻo e i luga o se Fuafua fua.",
    clickOn: "Kiliki i luga",
    yourPlanInFewSteps: " lau lesitala i nai laasaga vave.",
    to: "i le",
    yourSubscriptionHasExpired:
      "Ua maeʻa lau lesitala ma ua faʻatapulaʻa lau teuga tupe.",
    days: "aso",
    yourSubscriptionExpiresIn: "Ua maeʻa lau lesitala Loystar i totonu",
    createAcount: "Fausia se Teugatupe",
    signIn: "Saini ese j",
    continue: "Faaauau",
    enterOtp: "Ulufale OTP PIN",
    enterValidOtp: "Faʻamolemole faʻaulu mai se PIN aoga",
    pin: "PIN",
    tokenSentToMail: "Ua lafo se faʻailoga i lau imeli",
    passwordResetSuccessful: "Ua manuia le toe setiina o le password",
    somethingWentWrong: "Sa i ai se mea na tupu!",
    resetPassword: "Toe setiina upu faataga",
    iHaveResetCode: "E i ai laʻu faʻailoga toe setiina o le password",
    pleaseEnterEmail: "Faʻamolemole ulufale i lau imeli",
    aTokenWillBeSentToEmail: "O le a lafoina se faʻailoga i lau imeli",
    enterEmail: "Ulufale lau imeli",
    sendinYourToken: "Auina atu lau faʻailoga ...",
    makeSureItMatchesPassword: "Ia mautinoa e fetaui ma lau faʻaupuga fou",
    pleaseChooseNewPassword: "Faʻamolemole filifili se upu faʻataga fou",
    chooseNewPassword: "Filifili se faʻaupuga fou",
    enterNewPassword: "Ulufale lau faʻaupuga fou e faʻamaonia ai",
    enterTokenSent: "Ulufale i le faʻailoga na lafoina i lau meli",
    resetToken: "Toe setiina Faʻailoga",
    resettingPassword: "Toe setiina lau upu pasi ...",
    signUp: "Saini loa",
    adminSignInWithEmail:
      " Saini e le pule le imeli a o saini le aufaigaluega ma le igoa igoa.",
    pleaseEnterEmailOrUsername: "Faʻamolemole tusi lau imeli poʻo le igoa igoa",
    emailOrUsername: "Imeli poʻo Username",
    pleaseEnterPassword: "Faʻamolemole ulufale upu faʻataga",
    createAnAccount: "Fausia se Teugatupe",
    forgotPassword: "Galo le Upu Faʻamau?",
    enterPhoneNumber: "Ulufale numera telefoni",
    personalData: "Faʻamaumauga a le Tagata Lava Ia",
    validateConfirmationCOde: "Faʻamaonia Tulafono Faamaonia",
    pleaseEnterFirstName: "Faʻamolemole faʻaulu mai lou igoa muamua",
    pleaseEnterPhoneNumber: "Faʻamolemole tusi lau numera telefoni",
    pleaseEnterLastName: "Faʻamolemole tusi lou faaiu",
    pleaseEnterBusinessName: "Faʻamolemole tusi lou igoa pisinisi",
    firstName: "Igoa muamua",
    lastName: "Faʻaiu",
    businessName: "Igoa pisinisi",
    previous: "Talu ai",
    next: "Le isi",
    pleaseSelectBusinessCategory: "Faʻamolemole filifili lau vaega pisinisi",
    pleaseEnterValidEmail: "Faʻamolemole ulufale i se imeli aoga",
    pleaseEnterPostCode: "Faʻamolemole ulufale post code",
    postCode: "Falemeli numera",
    phoneNumberInUse: "Lenei numera telefoni ua faʻaogaina nei!",
    emailInUse: "Lenei imeli ua uma ona faʻaogaina!",
    foodAndDrinks: "Meaʻai ma Mea inu",
    salonAndBeauty: "Salon ma Matagofie",
    fashionAndAccessories: "Fausaga ma Mea Faʻaoga",
    gymAndFitness: "Faletaalo ma Malosi",
    travelAndHotel: "Malaga ma Faletalimalo",
    homeAndGifts: "Fale ma Meaalofa",
    washingAndCleaning: "Fufuluina ma Faamama",
    gadgetsAndElectronics: "Gadget ma Eletise",
    groceries: "Meaʻai",
    others: "Isi",
    submit: "Tuʻuina atu",
    accountCreatedSuccessful: "O lau teuga tupe na faia ma le manuia.",
    pleaseEnterAddress: "Faʻamolemole Ulufale Lou Tuatusi",
    addressLine1: "Tuatusi Laina 1",
    addressLine2: "Tuatusi Laina 2",
    choosePassword: "Filifili upu faʻataga",
    passwordMustBe6Characters:
      "E tatau ona le itiiti ifo i le 6 mataitusi le upu fai upu.",
    howDidYouHearLoystar: "Na faʻapefea ona e faʻalogo e uiga ia Loystar?",
    referralCode: "referral Tulafono",
    byClickingTheButton: " I le kilikiina o le faʻamau i lalo, oe malie i le",
    termsAndSevice: "Faʻatonuga",
    wereCreatingAccount: "O loʻo matou fausiaina lau teuga tupe",
    proceed: "Faʻagasologa",
    verificationCodeMustBe6: "Faʻamaonia Tulafono tatau ona 6 numera",
    pleaseEnter6DigitCode: "Faʻamolemole ulufale i le 6 digit code",
    enterVerificationCode: "Ulufale Faʻamaonia Tulafono",
    resendToken: "Toe lafo Faailoga",
    verify: "Faʻamaonia",
    transactions: "Galuega faatino",
    todaySales: "Faʻatau o Aso Nei",
    salesHistory: "Talafaʻasolopito o Faʻatau",
    supplyHistory: "Sapalai Talafaʻasolopito",
    new: "Fou",
    invoices: "O aitalafu",
    disbursements: "Tupe Totogi",
    offlineSales: "Faʻatauga i fafo atu",
    products: "Oloa",
    customers: "Tagata faʻatau",
    multiLevelLoyalty: "Tele-Level Faamaoni",
    loyaltyPrograms: "Faʻamaoni Polokalama",
    members: "Sui Usufono",
    appStore: "Faleoloa Faleoloa",
    orderMenu: "Polokalama Menu",
    settings: "Tulaga",
    staffAndBranches: "Aufaigaluega ma Lala",
    myAccount: "Laʻu Teugatupe",
    switchToSellMode: "Suʻe e Faʻatau Atu Faiga",
    signOut: "Saini ese",
    getFreeSubscription: "Maua saofaga Totogi",
    onlyNumbersAllowed: "Naʻo numera e faʻatagaina",
    yourAccountMustBe10Digits:
      "o lau numera numera e tatau ona 10 numera numera",
    yourBvnMustBe11: "lau BVN tatau ona avea ma 11 numera numera",
    pleaseSelectBank: "Faʻamolemole filifili lau faletupe",
    selectYourBank: "Filifili lau Faletupe",
    enterBankAccountNumber: "Ulufale numera o teuga tupe",
    enterBvnNumber: "Ulufale i lau BVN",
    connectBankAccount: "Fesoʻotaʻi Faletupe Faletupe",
    passwordResetSuccessfulAndSignOut:
      "Ua maeʻa lelei ona toe setiina lau pasiupu. Kiliki le faʻailoga o le Saini Fafo i lalo e toe saini ai",
    enterCurrentPassword: "Ulufale upu faʻamau i le taimi nei",
    pleaseEnterCurrentPassword: "Faʻamolemole ulufale i le taimi nei password",
    phoneNumber: "Numera telefoni",
    sex: "Feusuaiga",
    dateOfBirth: "Aso fanau",
    state: "Setete",
    country: "Atunuʻu",
    loyaltyId: "ID faʻamaoni",
    createdAt: "Fausia I",
    noOfTransactionsMade: "Leai o feuiaiga na faia",
    yourDownloadWillStart: "O lau download o le a amata i sina taimi",
    exportCustomers: "Auina atu i fafo Tagata Faʻatau",
    youhaveSuccessfullyToppedUp: "Ua faʻamanuiaina i luga lau SMS Units.",
    youNowHave: "Ua e mauaina nei",
    smsUnits: "Iunite SMS",
    enterNumberOfSmsUnits:
      "Ulufale numera o iunite SMS e te faʻamoemoe e faʻatau",
    pleaseEnterANumericValue: "Faʻamolemole ulufale i se numera numera",
    pay: "Totogi",
    accountEditedSuccessfully: "Na manuia le faʻatonutonuina o lau teuga tupe.",
    youAeCurrentlyOn: "O loʻo e alu nei",
    plan: "Fuafua",
    userData: "Faʻamatalaga o tagata",
    businessData: "FAʻAMATALAGA O LE Pisinisi",
    businessCategory: "Fale pisinisi",
    pleaseSelectCurrency: "Faʻamolemole filifili lau tupe",
    selectYourCurrency: "Filifili lau tupe",
    purchaseMoreSmsUnits:
      "Faʻatau tele isi Unite SMS faʻaaogaina le fomu i lalo",
    youHave: "E iai auala",
    atLeast4SmsUnitsRrequired:
      "Le itiiti ifo ma le 4 sms iunite e manaʻomia mo le faʻamaoniga, faʻamolemole faʻalelei luga!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Faʻamolemole ia faʻamaonia lau teuga tupe e usitaʻia ai le 'Iloa Lau Tagata Faʻatau' (KYC) manaʻoga. Ole mea lea o le a faʻatagaina ai oe ona aoina peimeni e ala ile USSD poʻo le vave lafoina, maua ni faʻatonuga mai tagata faʻatau ma faʻagaioia le faʻatautaia o feutanaiga. E naʻo tagata faatauʻoloa a Nigeria. Faʻamolemole fetaomi le faʻamau i lalo e amata.",
    reConnectBankAccount: "Toe fesoʻotaʻi Faletupe Faletupe",
    accountName: "Teugatupe Igoa",
    accountNumber: "Teugatupe numera",
    bankName: "Igoa o le Faletupe",
    verified: "Faamaonia",
    accountDetails: "Faamatalaga auiliiliga",
    kycBankAccount: "KYC",
    createTier: "Fausia Tier",
    fileUploadSuccessful: "faila ma le manuia le faʻailoaina",
    fileUploadFailed: "faila ua le manuia",
    createLoyaltyProgram: "Fausia se Polokalama Faamaoni",
    createLoyalty: "Fausia le Faamaoni",
    name: "Igoa",
    loyaltyArtwork: "Tusitusiga Faamaoni Faamaoni",
    clickToUpload: "Kiliki e tuu i luga",
    amountToPointsRatio: "Aofai i togi Fua Faʻatusatusa",
    points: "Manatu",
    recommendedAmountToPointRatio:
      "Fautuaina: ₦ 1 i le 1point. ie mo 1 Naira uma faʻaalu, au tagata faʻatau maua 1 manatu",
    pleaseTypeIn: "Faʻamolemole ta i totonu",
    toDeleteLoyalty: "aveese lenei faamaoni",
    deleteLoyalty: "Aveese le Faamaoni",
    toConfirm: "e faʻamaonia",
    edit: "Faʻatonutonu",
    pointsAwardedSuccessfully: "Manatu Faʻamanuiaina.",
    enterPointValueToAward:
      "Ulufale Point Taua E Te Manao e Taui Tagata Faʻatau",
    award: "Taui",
    awardPointValuesToCustomer: "Taui Tulaga Taua i Tagata Faʻatau",
    enrollMembersToLoyalty: "Lesitala tagata auai i luga o le Faamaoni",
    awardPoints: "Faʻailoga mo togi",
    enroll: "Lesitala",
    home: "Fale",
    loyalty: "Faamaoni",
    enrollCustomers: "Lesitala Tagata Faʻatau",
    selected: "Filifilia",
    customer: "Tagata faʻatau",
    loyaltyActivationSuccessful: "Faʻamaoni Faʻagaoioiga e manuia.",
    loyaltyDeactivationSuccessful: "Loyalty Deactivation e manuia.",
    viewTier: "Vaʻai Tier",
    deactivate: "De-Faagaoioia",
    activate: "Faʻaola",
    actions: "Gaioiga",
    nameOfLoyalty: "Igoa o le Faamaoni",
    loyaltyStatus: "Tulaga Faamaoni Tulaga Faamaoni",
    numberOfTiers: "Aofai o Tiers",
    createdOn: "Fausiaina",
    createATier: "Fausia se Tier",
    stopCreatingTierConfirmation: "E te manaʻo e taofi le faia o se laʻasaga?",
    stopEditingTierConfirmation:
      "E te manaʻo e taofi le faʻatonutonuina o lenei vasega?",
    nameOfTier: "Igoa o Tier",
    minimumSpendingTarget: "Tapulaʻa Maualuga Autu",
    maximumSpendingTarget: "Tapulaʻa maualuga o tupe alu",
    minimumSpendingTargetRequired: "maualalo tapulaʻa faʻamoemoe e manaʻomia",
    maximumSpendingTargetRequired:
      "aupito maualuga tapulaʻa faʻatupeina e manaʻomia",
    rewardSponsor: "Taui Fesoasoani",
    pleaseChooseARewardSponsor: "Faʻamolemole filifili se taui lagolago",
    self: "Oe lava",
    partner: "Paʻaga",
    rewardPartner: "Paʻaga Taui",
    pleaseSelectRewardPartner: "Faʻamolemole filifili lau paʻaga taui",
    rewards: "Taui",
    pleaseSelectAReward: "Faʻamolemole filifili se taui",
    instructionsOnRedeemingReward:
      "Faʻatonuga ile Faʻafefea e Tagata Faʻatau Tatau Ona Taui Taui",
    pleaseFillInThisField: "Faʻamolemole faʻatumu lenei fanua!",
    toDeleteThisTier: " aveese lenei vaega",
    deleteTier: "Aveese Tier",
    viewMembers: "Vaʻai Sui",
    membersEnrolled: "Lesitalaina Sui Usufono",
    instruction: "Faatonuga",
    membersIn: "Sui i totonu",
    availableTiersInLoyalty: "Maua Tier (s) i le Faamaoni Polokalama",
    tiers: "Tiers",
    totalTier: "AOTELEGA FUA",
    availableLoyaltyProgramme: "Avanoa Faʻamaoni Polokalama avanoa",
    totalLoyalties: "AOTELEGA FAAMAONI",
    memberDetails: "Faamatalaga a le Sui",
    nameOfCustomer: "Igoa o le Tagata Faʻatau",
    address: "Tuatusi",
    allEnrolledMembers: "Usufono uma na lesitala",
    thisIsToWishYouHappyBirthday:
      "Lenei o le moʻomoʻo ia te oe se matua fiafia aso fanau ma le manuia olaga. Faʻafetai mo mea uma ua e ia Loyster. Manuia le Faʻamanatuina!",
    inputAnOfferPlease: "Faʻaulu maia se Ofo faʻamolemole",
    pleaseSelectTheInsertPoint:
      "Faʻamolemole filifili le faʻaofi itu i le feʻau ma kiliki toe",
    birthdayOfferAndMessage: "Taulaga Aso Fanau ma le Savali",
    birthdayOffer: "Taulaga Aso Fanau",
    birthdayMessage: "Savali Aso Fanau",
    noOfferFound: "Leai se ofa maua",
    settingABirthdayOffer:
      "O le setiina o se aso fanau ofofai mafai ai tagata faʻatau e mauaina lenei ofa e ala i SMS i lo latou aso fanau",
    createOffer: "Fausia le Ofo",
    whatIsTheOffer: "O le a le ofa?",
    editMessage: "Faʻasaʻo le Feau",
    insert: "Faaofi",
    theNameOfCustomerInserted: "O le igoa o le tagata faʻatau o le a tuʻu ii",
    theBirtdayOfferInserted: "O le a ofi ii le ofa aso fanau",
    youSuccessfullyAddedNewBranch: "Ua e manuia faʻaopoopo se lala fou!",
    addNewBranch: "Faʻaopopo Paranesi Fou",
    addBranch: "Faʻaopopo le Paranesi",
    additionalBranchWillIncur: "Faʻaopopo Lala o le a maua",
    perBranch: "i le lala",
    ecommerceBranchCosts: "Tau a le Paranesi a Ecommerce",
    pleaseEnterBranchName: "Faʻamolemole tusi le igoa o le lala",
    pleaseEnterBranchAddress1: "Faʻamolemole tusi le tuatusi tuatusi laina 1",
    enterBranchAddress1: "Ulufale i le tuatusi laina tuatusi 1",
    enterBranchAddress2: "Ulufale i le tuatusi laina tuatusi 1",
    pleaseEnterBranchAddress2: "Faʻamolemole ulufale ile tuatusi laina 2",
    enterBranchName: "Ulufale i le igoa lala",
    successfullyAddedStaff: "Ua e manuia faʻaopoopoina se aufaigaluega fou!",
    addNewStaff: "Faʻaopopo Fou Aufaigaluega",
    addStaff: "Faʻaopopo le Aufaigaluega",
    additionalStaffWillIncur: "Faʻapitoa Aufaigaluega o le a maua",
    perStaff: "i le aufaigaluega.",
    pleaseEnterStaffEmail: "Faʻamolemole ulufale i le imeli a le aufaigaluega",
    pleaseEnterStaffUsername:
      "Faʻamolemole ulufale i le aufaigaluega igoa igoa",
    pleaseEnterStaffPassword: "Faʻamolemole faʻaulu le aufaigaluega password",
    pleaseSelectStaffRole: "Faʻamolemole filifili le aufaigaluega matafaioi",
    selectStaffRole: "Filifili le matafaioi a le aufaigaluega",
    enterStaffEmail: "Ulufale imeli a le aufaigaluega",
    enterStaffUsername: "Ulufale igoa ole aufaigaluega",
    enterStaffPassword: "Ulufale upu faʻamau a le aufaigaluega",
    spacesNotAllowedInUsername: "avanoa e le faʻatagaina i le igoa igoa",
    admin: "Pule",
    pleaseSelectBusinessToAttachStaff:
      "Faʻamolemole filifili pisinisi e faʻapipiʻi ai le aufaigaluega",
    searchForBranchToAttachStaff: "Saili mo lala e faʻapipiʻi le aufaigaluega",
    username: "Igoa Igoa",
    role: "Matafaioi",
    areYouSureToDeleteThis: "O e mautinoa e aveʻese lenei",
    branches: "Lala",
    upgradeYourPlan: "Faʻaleleia lau Fuafuaga.",
    add: "FAʻAOPOOPO",
    addNew: "Faʻaopopo Fou",
    customerWithEmailAlreadyExists:
      "Tagata faʻatau ma le imeli / numera ole telefoni ua uma ona iai!",
    successfullyAddedNewCustomer:
      "Ua e faʻaopopoina ma le manuia se tagata faʻatau fou!",
    addCustomer: "Faʻaopopo Tagata Faʻatau",
    pleaseEnterCustomerFirstName:
      "Faʻamolemole ulufale le tagata faʻatau muamua igoa",
    pleaseEnterCustomerLastName:
      "Faʻamolemole ulufale le tagata mulimuli igoa igoa",
    pleaseEnterCustomerPhoneNumber:
      "Faʻamolemole ulufale ile numera ole telefoni a le tagata faʻatau",
    pleaseEnterCustomerEmail: "Faʻamolemole ulufale i le imeli tagata faʻatau",
    pleaseEnterCustomerAddressLine:
      "Faʻamolemole ulufale ile tuatusi tuatusi laina",
    pleaseEnterCustomerOtherAddress:
      "Faʻamolemole ulufale i le isi tagata tuatusi tuatusi",
    pleaseSelectCustomerGender: "Faʻamolemole filifili le itupa o aliʻi",
    gender: "Itupa",
    male: "Aliʻi",
    female: "Fafine",
    bank: "Faletupe",
    selectBank: "Filifili Faletupe",
    stateOrRegionOrProvince: "Setete / Itumalo / Itumalo",
    customerNotes: "Faʻamatalaga a Tagata Faʻatau",
    sendSms: "Lafo SMS",
    editCustomer: "Faʻatonutonu Tagata Faʻatau",
    redeemReward: "Togiola taui",
    issueLoyaltyCard: "Tuuina le Loyalty Card",
    deleteCustomer: "Aveese le Tagata Faʻatau",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ua e tofia ma le manuia se Loyalty Membership ID",
    noMembershipIdAvailable:
      "Leai ni avanoa faʻailoga sui. Faʻamolemole faʻafesoʻotaʻi mai matou hello@loystar.co",
    sendEmail: "Lafo imeli",
    membershipPoints: "Manatu mo Tagata Auai",
    customerDetails: "Auiliiliga a Tagata Faʻatau",
    close: "Tapuni",
    loyaltyBalance: "Paleni faʻamaoni",
    pointsBalance: "Manatu Paleni",
    starBalance: "Paleni paleni",
    requiredPoints: "Manatu Manaʻomia",
    requiredStars: "Fetu Manaʻomia",
    reddemCode: "Togiola Tulafono",
    toDeleteThisCustomer: "aveese lenei tagata faatau",
    customerHasBeenDeletedSuccessfully:
      "Tagata faʻatau ua aveʻesea ma le manuia!",
    customerWithPhoneAlreadyExists:
      "Tagata faʻatau ma le numera telefoni ua uma na iai",
    customerWasSuccessfullyEdited:
      "Sa faʻalelei ona faʻasaʻo le tagata faʻatau",
    anErrorOccured: "O se mea sese na tupu",
    phoneNumberIncludeCountry: "Telefoni Numera (Faʻaaofia ai le Country Code)",
    yourFileQueuedForUpload:
      "Ua maeʻa lau laina filo mo lau faila. Faʻamolemole faʻafou le itulau pe a maeʻa ni nai sekone.",
    thereWasAnErrorPerformingOperation:
      "Sa iai se mea sese na fai ai le taotoga!",
    pleaseSelectFile: "Faʻamolemole filifili se faila!",
    oopsOnlyCsvFilesAllowed:
      "Oi! Naʻo CSV faila e faʻatagaina. Faʻamolemole tuʻu se faila .csv.",
    fileShouldBeSmallerThan5Mb:
      "Faila e tatau ona laʻititi nai lo 5MB. A iai sau faila telē, faʻamolemole imeli i le support@loystar.co. faafetai",
    customerFirstNameIsRequired:
      "tagata faʻatau muamua igoa e manaʻomia luga o laina",
    customerPhoneNumberIsRequired:
      "tagata faʻatau telefoni numera e manaʻomia ile laina",
    importCustomers: "Faʻatau mai tagata faʻatau",
    upload: "Tuʻu i luga",
    clickIconToDownloadCsv:
      "Kiliki lenei faʻailoga e download ai le CSV file template.",
    getGoogleSheetVersion: "Maua le Google sheet version",
    clickOrDragFileToUpload: "Kiliki pe toso faila i lenei itu e tuu i luga",
    missingOrInvalidColumnHeader:
      "Leiloa poo le aoga le ulutala koluma. Faʻamolemole mulimuli i le mamanu faʻatulagaina. Faafetai.",
    youHaveImported: "Ua E Faʻatauaina",
    youSuccessfullyRedeemedReward: "Ua e togiina ma le manuia lou taui!",
    sixDigitCode: "Ono numera numera",
    pleaseEnterCustomerRewardCode:
      "Faʻamolemole ulufale i le tagata faʻatau taui code",
    enterRewardCode: "Ulufale taui code. (O le taui code e tulaga maaleale)",
    selectLoyaltyProgram: "Filifili le faʻamaoni polokalame",
    stamps: "Faailoga",
    smsUnitsLow: "SMS Iunite Maualalo",
    pleaseTopUpSmsUnits: "Faʻamolemole Faʻalelei Up Iunite SMS",
    smsSuccessfullySentForDelivery:
      "Na manuia le lafoina o le SMS mo le kilivaina!",
    sendSmsTo: "Lafo SMS i",
    allCustomers: "Tagata Faʻatau uma",
    unitsAvailable: "Iunite Avanoa",
    pleaseTypeInTheMessage: "Faʻamolemole ta i le feau",
    message: "Savali",
    charactersRemaining: "tagata o loʻo totoe",
    avoidUseOfSpecialCharacters:
      "Aloese mai le faʻaaogaina o Faʻapitoa Faʻailoga ma Emojis e faʻasao ai Iunite SMS.",
    note: "Faʻaliga",
    errorFetchingCustomersMultilevelDetail:
      "Sese Fetaui Tagata Faʻatau MultiLevel Auiliiliga",
    search: "Saili",
    reset: "Toe setiina",
    importCustomer: "Faʻatau Tagata Faʻatau",
    addNewCustomer: "Faʻaopopo Tagata Faʻatau Fou",
    sendSmsBroadcast: "Lafo Faasalalauga i le SMS",
    totalCustomers: "AOTELE O FAIGA FUA",
    disbursementDetails: "Faʻamatalaga o Tupe Totogiina",
    paidBy: "Totogi e",
    disbursed: "Totogiina",
    bankAccountName: "Faletupe igoa igoa",
    bankAccountNumber: "Faletupe Numera Numera",
    transferInitiated: "Fetuunaiga Amataina",
    transferCompleted: "Faʻaliliuina Ua Maeʻa",
    pleaseEnterAValid: "Faʻamolemole ulufale mai se aoga",
    begin: "amata",
    end: "iʻuga",
    date: "aso",
    paymentDate: "Aso totogiina",
    selectDisbursementDuration: "Filifili le Totogiina o le Umi",
    totalSettled: "Aofaʻi nofoia",
    totalUnsettled: "Aofaʻiga le setiina",
    toDeleteThisSale: "aveese lenei faʻatau",
    draftSaleDeletedSuccessfully:
      "Ua maeʻa ona soloia ma le manuia le faʻataʻitaʻiga Faʻatau",
    deleteSale: "Aveese le faʻatau",
    pleaseTypeInYourTotal:
      "Faʻamolemole ta i lau aofaʻi e faʻamaonia le tapeina",
    billDetails: "Pili Faamatalaga",
    printBill: "Tulafono Tau Faaofi",
    servedBy: "Auauna e",
    total: "Aofai",
    createdDate: "Aso Fausiaina",
    createdTime: "Taimi Fausia",
    status: "Tulaga",
    loginSuccessful: "Login Manuia",
    pleaseWaitWhileWeConnectAccount:
      "Faʻatali a o matou faʻafesoʻotaʻia lau teuga tupe",
    connectionFailedTryAgain:
      "Ua le aoga le fesoʻotaʻiga. Faʻamolemole toe taumafai.",
    connectionSuccessful: "Manuia le fesoʻotaʻiga",
    viewDetails: "Vaʻai Faʻamatalaga",
    enable: "Faʻamaonia",
    free: "Taoloto",
    cost: "Tau",
    visitWebsite: "Asiasi i le 'Upega tafaʻilagi",
    pleaseUpgradeYourPlanToPro:
      "Faʻamolemole Faʻalelei lau fuafuaga i le Pro Plus e mafai ai lenei app",
    connectYourBankAccount:
      "Faʻafesoʻotaʻi lau Faletupe Faletupe ina ia mafai ona maua totogi.",
    disable: "Faʻaleaoga",
    enableApp: "Faʻaleleia le App",
    addNewProductToInvoice: "Faʻaopopo Fou Oloa i Tupe Totogi",
    toDeleteThisInvoice: "e aveese ai lenei pili",
    invoiceDeletedSuccessfully: "O aitalafu ua uma ona aveeseina ma le manuia!",
    deleteInvoice: "Aveese aitalafu",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ua e manuia faʻaopoopo i ai se faʻamatalaga o totogi o Invoice!",
    pleaseEnterPaymentInstructions: "Faʻamolemole ulufale faʻatonuga totogi",
    invoiceId: "Faʻailoga ID",
    paidDate: "Aso Totogi",
    reference: "Tusitusiga",
    productAdded: "Ua faʻaopopo le oloa",
    productOutOfStock: "Oloa ua leai ni oloa. Faʻamolemole toe faʻasoa.",
    totalInvoices: "AOTELEGA O INVOI",
    totalPaidInvoices: "Aofaʻi Totogi Totogi",
    totalUnpaidInvoices: "AOTELEGA O TUPE FAʻAFAIGALUEGA",
    loyaltyProgramDeleted: "Faamaʻaina le Faʻamaoni Polokalama",
    thisLoyaltyProgramDeletedSuccessfully:
      "O lenei faʻamaoni polokalama ua aveʻesea ma le manuia",
    thisLoyaltyProgramEditedSuccessfully:
      "O lenei faʻamaoni polokalama ua maeʻa faʻatonutonuina lelei",
    loyaltyProgramAddedSuccessfully:
      "Ua faʻaopoopo ma le manuia polokalame faʻamaoni",
    loyaltyProgramEdited: "Faʻamaonia Polokalama Faʻamaoni",
    loyaltyProgramAdded: "Faʻaopoopoina le Faʻamaoni Polokalama",
    loyaltyDetails: "Faamatalaga Faamaoni",
    doYouWantToCloseDialog: "E te manaʻo e tapunia lenei talanoaga?",
    pleaseEnterLoyaltyName: "Faʻamolemole tusi le igoa o lou faʻamaoni",
    programType: "Polokalame Ituaiga",
    pleaseSelectProgramType: "Faʻamolemole filifili se ituaiga polokalame",
    simplePoints: "Manatu Faigofie",
    stampsProgram: "Faʻailoga Polokalama",
    threshold: "Amatafale",
    pleaseEnterLoyaltyThreshold:
      "Faʻamolemole ulufale i le faʻamaoniga faamaoni",
    reward: "Taui",
    pleaseEnterLoyaltyReward: "Faʻamolemole ulufale i le taui o le faʻamaoni",
    totalUserPoints: "Aofaʻi Manatu mo Tagata Faʻaoga",
    totalUserStamps: "Aofaʻi o Faʻailoga Tusitusi",
    spendingTarget: "Tupe faʻaalu",
    spendingTargetHint1:
      "Tupe faʻaalu faʻamoemoe o le a le tele e tatau ona faʻaalu e le tagata faʻatau e maua ai le taui. 1 tupe aoga = 1 togi.",
    spendingTargetHint2:
      "Faʻailoga sikuea o le fia faʻailoga tusi a le tagata faʻatau e tatau ona aoina e maua ai le taui. faʻapea 5",
    addNewLoyaltyProgram: "Faʻaopopo le New Loyalty Program",
    addLoyaltyProgram: "Faʻaopopo le Faʻamaoni Polokalama",
    loyaltyProgramType: "Faʻamaoni Polokalama Ituaiga",
    pleaseSelectLoyaltyProgramType:
      "Faʻamolemole filifili le faʻamaoni polokalama ituaiga",
    nameOfProgram: "Igoa o le polokalama",
    pleaseEnterProgramName: "Faʻamolemole tusi le igoa polokalame",
    whatIsTheReward: "O le a le taui?",
    egNextPurchaseIsFree: "Faʻapea Le isi faʻatau e leai se tau",
    customerName: "Igoa ole tagata faatau",
    guestCustomer: "Tagata Faʻatau Nofo",
    orderConfirmedSuccessfully: "Ua faʻamaonia ma le manuia le oka",
    orderCancelledSuccessfully: "Na faʻaleaogaina ma le manuia le oka",
    confirmOrder: "Faʻamaonia le oka",
    cancelOrder: "Faʻaleaoga le oka",
    allOrders: "Uma Poloaiga",
    totalOrders: "AOTAI TOLOA",
    doYouWantToAcceptOrder: "E te manaʻo e talia lenei faʻatonuga?",
    doYouWantToCancelOrder: "E te manaʻo e faʻaleaoga lenei faʻatonuga?",
    orderDetails: "Auiliiliga oka",
    orderCreatedAt: "Na faia le oka ile",
    supplier: "Faʻatau",
    productName: "Oloa Igoa",
    quantity: "Aofaʻiga",
    unitPrice: "Tau o Iunite",
    receivedBy: "Maua e",
    reportFrom: "Lipoti mai",
    totalSupplies: "Aofai Sapalai",
    allRightsReserved: "Taofia aia tatau uma",
    toDeleteThisTransaction: "aveese lenei Transaction",
    transactionDeletedSuccessfully:
      "Galue ua aveʻesea ma le manuia. O oloa ua toe faafoi i oloa.",
    deleteTransaction: "Aveese gaioiga",
    transactionDetails: "Auiliiliga o Galuega",
    printReceipt: "Lolomi lisiti",
    channel: "Channel",
    discount: "Totogi",
    profit: "Tupe maua",
    discountedSales: "Faʻatau pau",
    errorFetchingRecord: "Sese Pepa Faʻamaumau",
    exportTransactions: "Auina atu i fafo Galuega",
    errorFetchingSalesRecord:
      "O se mea sese le puʻeina o faʻamaumauga a le Sales mo le auina atu i fafo.",
    totalSellingPrice: "Tau Atoa o le Faʻatau atu",
    totalCostPrice: "Tau Aofai o Tau",
    appliedDiscount: "Totogi faʻaaogaina",
    noOfItems: "Nu o aitema",
    sales: "Faʻatau",
    export: "Auina atu i fafo",
    totalProfit: "Aofai o tupe mama",
    totalBalanceInPeriod: "Aofaʻiga Paleni i Vaitaimi",
    allTimeSales: "Faʻatau Uma taimi",
    records: "Faʻamaumauga",
    todaysSales: "Faʻatau o Aso Nei",
    transaction: "fefaʻatauaiga",
    youHaveExceededTotalNumberOfProducts:
      "Ua sili atu oe ile aofaʻi atoa o oloa na faʻatagaina i lau peleni. Faʻaleleia lau fuafuaga e fiafia ai i se maualuga atu tapulaʻa.",
    youNeedToHaveLoyaltyProgram:
      "Oe manaʻomia i ai se Faamaoni Polokalama e faʻaaoga ai lenei vaega!",
    price: "Tau",
    category: "Vaega",
    stockTracking: "Suʻesuʻega o Oloa",
    stockCount: "Faitauga o Oloa",
    taxed: "Faʻafefe",
    originalPrice: "Tau muamua",
    costPrice: "Tau o tau",
    unit: "Iunite",
    productImage: "Faʻatusa Ata",
    taxRate: "Totogi lafoga",
    taxType: "Ituaiga lafoga",
    trackInventory: "Ala Suʻesuʻe",
    variants: "Eseese",
    hasVariants: "E i ai ituaiga",
    importProduct: "Faaulufale oloa",
    exportProducts: "Auina atu i fafo oloa",
    addNewProduct: "Faʻaopopo Fou Oloa",
    viewCategory: "Vaʻai le vaega",
    viewSuppliers: "Vaʻai Faʻapitoa",
    receiveInventory: "Maua Inventory",
    printAllProductsTag: "Lolomi Uma Oloa Tag",
    deleteAll: "Aveese uma",
    totalProducts: "AOTELE Oloa",
    youveSuccessfullyAddedANewCategory:
      "Ua e manuia faʻaopoopoina se vaega fou",
    addNewCategory: "Faʻaopopo Fou Vaega",
    addCategory: "Faʻaopopo le Vaega",
    categoryName: "Category igoa",
    pleaseEnterCategoryName: "Faʻamolemole tusi le igoa igoa",
    stampsTarget: "Faailoga faailoga",
    sendInventory: "Lafo maia faamaumauga",
    productDetails: "Oloa Auiliiliga",
    youveSuccessfullyEditedThisCategory:
      "Ua e faʻamanuiaina i le faʻasaʻoina o lenei vaega",
    update: "Faʻafouga",
    categoryList: "Lisi vaega",
    categories: "Vaega",
    enterQuantityToUpdate: "Ulufale se aofaʻi e faʻafou",
    inventorySentSuccessfully: "Na manuia le lafoina o le lisi o oloa!",
    updateInventory: "Tala Fou Faʻatulagaina",
    currentQuantity: "Aofaʻiga o loʻo iai nei",
    pleaseEnterQuantityToAdd:
      "Faʻamolemole tusi le aofaʻi e te manaʻo e faʻaopopo",
    pleaseSelectABranch: "Faʻamolemole filifili se Lala",
    searchForBranch: "Saili mo lala",
    youCantSendMoreThanStock:
      "E le mafai ona e lafoina ni mea e sili atu nai lo mea o loʻo i ai i faʻasoa",
    send: "Lafo",
    pleaseEnterQuantityToSend: "Faʻamolemole tusi le aofaʻi e te manaʻo e lafo",
    productNameIsRequiredOnRow: "igoa oloa e manaʻomia luga o laina",
    productCategoryIsRequiredOnRow: "oloa vaega e manaʻomia luga laina",
    productPriceIsRequiredOnRow: "tau oloa e manaʻomia ile laina",
    productUnitIsRequiredOnRow: "oloa oloa e manaʻomia ile laina",
    productQuantityIsRequiredOnRow: "oloa aofaʻi e manaʻomia luga laina",
    productVariantsRequireTracking: "oloa eseʻesega manaʻomia siaki!",
    pleaseAddVariantClickButton:
      "Faʻamolemole faʻaopopo se fesuiaʻiga i le kilikiina o le faʻaopopo faʻamau!",
    totalVariantsMoreThanSelectedQuantity:
      "Aofaʻi Variants e sili atu nai lo filifilia oloa aofaʻi, Pls faʻaititia fesuiaʻiga aofaʻi",
    productEditedSuccessfully: "O oloa ua maeʻa faʻatonutonuina lelei!",
    fileTooLargeLessThan4Mb:
      "Tele tele tele! Tele faila lapoa e tatau ona itiiti ifo ma le 4MB.",
    suchVariantAlreadyExist: "O ituaiga eseese ua uma ona i ai",
    addVariants: "Faʻaopopo Suʻesuʻega",
    editProduct: "Faʻatonutonu Oloa",
    pleaseEnterProductName: "Faʻamolemole Ulufale Igoa Igoa",
    pleaseEnterProductPrice: "Faʻamolemole ulufale Tau Oloa",
    pleaseEnterProductOriginalPrice: "Faʻamolemole Ulufale Oloa Uluaʻi Tau",
    productDescription: "Faʻamatalaga Oloa",
    selectProductCategory: "Filifili Vaega o Oloa",
    pleaseSelectProductCategory: "Faʻamolemole filifili vaega oloa",
    productCostPrice: "Tau o Oloa Tau",
    productSellingPrice: "Tau Oloa faatau atu",
    productOriginalPrice: "Oloa Uluaʻi Tau",
    maxFileSizeAllowedIs4Mb: "Max. Faʻatagaina le tele faila o le 4mb",
    productsWithPicturesArePublished:
      "O oloa ma ata o loʻo faʻasalalauina ile Discover e maua ai oka",
    shouldThisProductBeTracked: "Tatau ona suʻesuʻe lenei oloa?",
    pleaseSelectStockUnit: "Faʻamolemole filifili stock unit",
    stockUnit: "Oloa Oloa",
    bag: "Faʻamau",
    bottle: "FUAFUAGA",
    bunch: "NOFOAGA",
    can: "MAFAI",
    carton: "KARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "TULAFONO",
    kilogram: "KILOGRAM",
    litre: "Ituaiga",
    pack: "Teu",
    pair: "PAU",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "Tone (MT)",
    uNIT: "IUNITE",
    yard: "Fanua",
    pleaseEnterProductQuantity: "Faʻamolemole ulufale oloa aofaʻi",
    productQuantity: "Oloa Aofaʻiga",
    isThisProductTaxed: "O lafoga lenei oloa?",
    selectTaxType: "Filifili Ituaiga Lafoga",
    pleaseSelectTaxType: "Faʻamolemole filifili Ituaiga Lafoga",
    progressive: "Alualu i luma",
    proportional: "Faʻatusatusa",
    pleaseEnterProductTaxRate: "Faʻamolemole ulufale fua faatatau lafoga oloa",
    doesProductHaveVariants: "O lenei oloa i ai variants?",
    type: "Ituaiga",
    value: "Taua",
    pleaseEnterVariantType: "Faʻamolemole ulufale i le ituaiga ituaiga",
    pleaseEnterVariantValue: "Faʻamolemole ulufale i le variant's aoga",
    pleaseEnterVariantPrice: "Faʻamolemole ulufale i le tau o variant",
    pleaseEnterVariantQuantity: "Faʻamolemole ulufale i le aofaʻi ese",
    productDeletedSuccessfully: "Oloa ua aveʻesea ma le manuia!",
    categoryDeletedSuccessfully: "Ua toʻesea ma le manuia le vaega!",
    toDeleteThisProduct: "aveese lenei oloa",
    invalidProductQuantity: "Lefaʻatau Oloa Aofaʻi",
    quantityAddedIsOverStock:
      "O le aofaʻi o loʻo e faʻaopopoina e sili atu nai lo mea o loʻo i ai i faʻasoa.",
    itemInventoryNotTracked: "Meataitasi lisi e le o mulimuli",
    addBulkQuantity: "Faʻaopopo tele Aofaʻiga",
    enterBulkQuantity: "Ulufale tele Aofaʻiga",
    pleaseEnterBulkQuantity: "Faʻamolemole ulufale mai Aofai tele",
    youveSuccessfullyAddedANewProduct:
      "Ua e faʻaopopoina ma le manuia se mea fou!",
    pleaseEnterProductSellingPrice:
      "Faʻamolemole ulufale i oloa faʻatau atu tau",
    doYouWantToTrackProductStock: "E te manaʻo e mataitu le oloa oloa?",
    sellingPrice: "Tau faatauina atu",
    setProductExpiryReminder: "Seti Faʻamanatuina o le Umi o le Oloa",
    productExpiryDate: "Aso e muta ai oloa",
    startRemindingFrom: "Amata Faamanatu Mai",
    productSuppliesAddedSuccessfully:
      "Ua e faʻaopopo ma le manuia oloa sapalai.",
    addProductSupplies: "Faʻaopopo Sapalai Oloa",
    pleaseSelectSupplier: "Faʻamolemole Filifili Supplier",
    nameOfProduct: "Igoa O Oloa",
    pleaseSelectProduct: "Faʻamolemole Filifili oloa",
    productVariant: "Vasega Oloa",
    pleaseSelectAVariant: "Faʻamolemole Filifili Se Suʻesuʻega",
    pleaseEnterUnitPrice: "Faʻamolemole ulufale i le Tau o le Iunite",
    businessBranch: "Paranesi Pisinisi",
    pleaseSelectBranch: "Faʻamolemole filifili paranesi",
    youveSuccessfullyAddedANewSupplier:
      "Ua e alualu i luma faʻalelei se kamupani fou",
    addSupplier: "Faʻaopopo le Fesoasoani",
    pleaseEnterSupplierEmail: "Faʻamolemole ulufale i le imeli kamupani",
    pleaseAddADescription: "Faʻamolemole faʻaopopo se faʻamatalaga",
    anErrorOccuredProductsDeleted:
      "O se mea sese na tupu ao faia le taotoga. Faʻamolemole ia matau, nai oloa atonu na aveʻesea i le gaioiga",
    bulkDelete: "Tape tele",
    youAreAboutToDelete: "O le a toeititi ona e tapeina lea",
    product: "Oloa",
    isDueToRedeem: "ua tatau ona togiola",
    aReward: "o se taui",
    pleaseSelectCustomerToReeemReward:
      "Faʻamolemole filifili se tagata faʻatau e togiola faʻailoga.",
    youHaveNoLoyaltyProgramRunning:
      "E leai sau faʻamaoni polokalama faʻatinoina tamoʻe",
    customerHhasNoPointsInLoyaltyProgram:
      "Tagata faatau e leai ni togi i lenei faʻamaoni polokalama",
    proceedWithPayment: "Faʻagasolo ma Totogi?",
    youAreAboutToPayForTransactionUsingPoints:
      "O le a e totogia le fefaʻatauaiga e faʻaaoga ai manatu.",
    customerHas: "Tagata faatau Ua",
    worth: "aoga",
    andIsNotSufficientToPayForTransaction:
      "ma e le lava e totogi ai lenei fefaʻatauaiga. Latou te manaʻo e faʻaopopo le paleni faʻaaoga seisi auala totogi?",
    addCustomerLoyalty: "Faʻaopopo Tagata Faʻamaoni Faʻamaoni",
    pleaseAddCustomerFirst:
      "Faʻamolemole faʻaopopo pe filifili se tagata faʻatau muamua.",
    pleaseWaitWhileWeGetReady: "Faʻatali, a o matou sauni",
    lowStock: "Maualalo Oloa",
    pleaseEnterAmountTendered: "Faʻamolemole ulufale i le aofaʻi tender",
    areYouSureToBookSaleOffline:
      "O e mautinoa e te manaʻo e tusi lenei faʻatau atu offline?",
    saleWouldBeBookedAutomatically:
      "Faʻatau atu o le a tusi otometi pe a oʻo ina e ki lau initaneti",
    offlineSalesBookingCancelled:
      "Ua faaleaogaina le resitalaina o le faatau atu i luga ole laiga",
    covid19Message:
      "COVID19: Fufulu ou lima i le fasimoli pe faʻamama e taofi ai le sosolo. Tumau Saogalelei i taimi uma",
    saleSuccessfullyRecorded: "Faʻatau Manuia Faʻamauina!",
    sendReceiptToEmail: "Lafo lisiti i le Imeli",
    sendThankYouSms: "Lafo faafetai SMS",
    sendShippingDetails: "Lafo Faamatalaga auiliili",
    addLoyalty: "Faʻaopopo le Faʻamaoni",
    searchCustomerNameOrNumber: "Saili le tagata faʻatau igoa poʻo le numera",
    clickTheSearchCustomerBox: "Kiliki le Search Customer Box ma Scan Card",
    enterProductPrice: "Ulufale Tau Oloa",
    enterPriceFor: "Ulufale Tau mo",
    searchForProduct: "Saili mo oloa",
    all: "Uma",
    backToDashboard: "Toe foi i le Dashboard",
    viewDraftSales: "Vaʻai le Faʻatau Tatau",
    variantSelected: "filifilia filifilia",
    variant: "eseʻese",
    thePreviousVariantSelectionNotAvailable:
      "O le muamua variant filifiliga e le o avanoa mo le fou ituaiga filifilia faʻavae i luga o le tau, faʻamolemole suia lau filifiliga.",
    pleaseSupplyPositiveQuantityNumber:
      "Faʻamolemole tuʻu mai se numera numera postive",
    lowStockFor: "Oloa maualalo mo",
    clearVariants: "Manino eseese",
    pleaseEnterQuantity: "Faʻamolemole ulufale Aotelega",
    picture: "Ata",
    redemptionToken: "Faailoga o le togiola",
    token: "Faailoga",
    totalSpent: "Aofaiga Faʻaalu",
    confirmPayment: "Faʻamaonia Totogi",
    hasPaymentBeenMade: "Ua faʻagaioia ma le manuia le totogiina?",
    enterTransactionReference:
      "Ulufale mai i le faʻamatalaga fefaʻatauaʻiga na e totogiina",
    pleaseSelectACustomer: "Faʻamolemole filifili se tagata faʻatau!",
    areYouSureToApplyDiscount: "O e mautinoa e te manaʻo e faʻapaʻu paʻu?",
    addYourBankAccountToEnableUssd:
      "Faʻaopopo lau faletupe e faʻaavanoa ai Instant USSD transfer e uPay",
    totalAmountToPay: "Aofaʻiga Aofai e Totogi",
    doYouWantToCancelTransaction: "E te manaʻo e faʻamuta lenei fefaʻatauaiga?",
    savePrintBill: "Sefe / Lolomi Tulafono Tau Faaofi",
    enterAmountCollectedForCustomer: "Ulufale aofaʻi aoina mo tagata faʻatau",
    recordSale: "Faʻatau Faʻatau",
    checkOutWith: "Siaki atu ma",
    instantTransfer: "Fesuiaiga vave",
    dialTheUSSDCode: "Vili le numera USSD",
    pleaseSelectABank: "Faʻamolemole filifili se faletupe",
    payWithUSSD: "Totogi Faʻatasi ma le USSD",
    sendBillTo: " - Lafo Tulafono ia",
    waitingForUSSDTransfer: "Faatali mo le USSD Transfer",
    percent: "Pasene",
    applyDiscount: "Faʻaoga le paʻu",
    thisBillHasBeenSaved: "Ua faasaoina lenei Tulafono Tau Faaofi",
    saveDraft: "Sefe le ata faataitai",
    pleaseTypeANameToIdentifyCustomer:
      "Faʻamolemole taina i se igoa e faʻailoa ai le tagata faʻatau",
    paymentDetails: "Totogiina Auiliiliga",
    basePrice: "Tau faʻavae",
    staff: "Aufaigaluega",
    subTotal: "Laulelei",
    durationMonths: "Umi umi (masina)",
    selectADuration: "Filifili se Umi",
    oneMonth: "1 Masina",
    twoMonths: "2 Masina",
    threeMonths: "3 Masina",
    sixMonths: "6 Masina",
    twelveMonths: "12 Masina",
    eighteenMonths: "18 Masina",
    twentyFourMonths: "24 Masina",
    twoMonthsFree: "(2 Masina Totogi)",
    threeMonthsFree: "(3 Masina Totogi)",
    fiveMonthsFree: "(5 Masina Totogi)",
    yourAccountHasBeen: "O lau Teugatupe sa",
    renewed: "faafouina",
    upgraded: "faʻafouina",
    successfully: "manuia",
    yourSubscription: "Lau lesitala",
    youAreCurrentlyEnrolledOnA: "O loʻo lesitalaina nei oe ile a",
    pleaseChooseAPaymentOption: "Faʻamolemole filifili se Totogi Filifiliga",
    planRenewal: "Fuafua Fuafua",
    planUpgrade: "Fuafua Faʻalelei",
    pleaseSelectDurationToPayFor:
      "Faʻamolemole Filifili Umi e te fuafua e totogi mo",
    staffAccounts: "Teugatupe a le aufaigaluega",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Faʻamolemole filifili se fuafuaga",
    includeAddons: "Faʻaopopo Mea Faʻaopopo",
    viewTransactions: "Vaʻai Galuega",
    customerHasNoCompletedTansactions: "Tagata faʻatau e leʻi maeʻa ona suia",
    branch: "Paranesi",
    enterNumberOfEcommerceBranches: "Ulufale numera o Ecommerce Lālā",
    enterNumberOfEcommerceBranchesToPay:
      "Ulufale numera o paranesi Ecommerce Oe faamoemoe e totogi mo",
    ecommerceIntegration: "Tuufaatasi Ecommerce",
    enterNumberOfBranches: "Ulufale numera o paranesi",
    enterNumberOfAdditionalBranchesToPay:
      "Ulufale numera o faʻaopopo paranesi e te manatu e totogi mo",
    enterNumberOfStaffs: "Ulufale numera o le aufaigaluega",
    enterNumberOfStaffsToPayFor:
      "Ulufale numera o aufaigaluega Oe faamoemoe e totogi mo",
    discountApplies: "Totogi Faʻapipiʻi",
    starsOnThe: "fetu i luga o le",
    offer: "ofo",
    get: "Maua",
    moreStarsToRedeem: "tele fetu e togiola",
    taxVat: "Lafoga (VAT)",
    callCashierToCompletePayment: "Valaʻau le kashier e faʻatumu totogi",
    receipt: "Tupe maua",
    dear: "Lau pele",
    thankYouForYourOrderFindGoods:
      "Faʻafetai mo lau oka. Faʻamolemole suʻe oloa o loʻo sapalai, e pei ona malilie i ai.",
    shippingNote: "Faamatalaga lafo",
    enterShippingNote: "Ulufale Atu i le Vaa",
    shippingAddress: "Lauga lafo",
    enterShippingAddress: "Ulufale lafoina tuatusi",
    wellDoneYouAreDueToRedeem: "Malo lava! Ua tatau ona e togiola",
    toGetYourRewardNextVisit: "ia maua lou taui i lau isi asiasiga. faafetai",
    pointsOnThe: "Manatu i le",
    morePointsToRedeem: "sili atu togi e togiola",
    showCode: "Faʻaali le code",
    toGetYourRewardOnNextVisit: "ia maua lou taui i lau isi asiasiga. faafetai",
    earn: "Mauaina",
    delivaryNote: "Faʻamaumauga Delivary",
    draftSales: "Fuafua Faʻatau",
    startDate: "Aso amata",
    endDate: "Aso faʻaiʻu",
    orders: "Poloaiga",
    checkout: "siaki atu",
    noProductItem: "Leai oloa oloa",
    selectProductImage: "Filifili Ata Oloa",
    selectCountry: "Filifili atunuu",
    selectRegion: "Filifili setete / itulagi",
    printProductTag: "Lolomiina Oloa Tag",
    transactionReference: "Faʻamatalaga gaioiga",
    Cashier: "Faletupe",
    Manager: "Pule",
    Owner: "Pule",
    Admin: "Pule",
    addPartners: "Faaopoopo Paaga",
    addNewLoyaltyPartner: "Fa'aopoopo Fou Paaga Fa'amaoni",
    pleaseEnterCompanyName: "Fa'amolemole tu'u le Igoa o le Kamupani",
    companyName: "Igoa Kamupani",
    pleaseEnterCompanyRepName:
      "Fa'amolemole tu'u mai le Igoa sui o le kamupani",
    companyRepName: "Igoa o le Sui Kamupani",
    pleaseEnterCompanyRepEmail:
      "Fa'amolemole tu'u ile imeli ole sui ole kamupani",
    companyRepEmail: "Imeli a le sui o le kamupani",
    pleaseEnterCompanyRepPhone:
      "Fa'amolemole tu'u numera telefoni a sui o le kamupani",
    companyRepPhone: "Numera telefoni a le sui o le kamupani",
    addReward: "Faaopoopo taui",
    pleaseEnterRewardName: "Fa'amolemole tu'u le igoa taui",
    rewardName: "Igoa Taui",
    rewardQuantity: "Aofaiga o taui",
    rewardDescription: "Fa'amatalaga Taui",
    rewardType: "Ituaiga Taui",
    pleaseEnterRewardType: "Fa'amolemole tu'u le ituaiga taui",
    pleaseEnterRewardQuantity: "Fa'amolemole tu'u le aofa'i o taui",
    pleaseEnterRewardDescription:
      "Fa'amolemole tu'u i totonu fa'amatalaga taui",
    fineDining: "Mea'ai Lelei",
    luxuryFashion: "Faiga Tauleleia",
    hotels: "Faletalimalo",
    travel: "Malaga",
    foodAndBeverage: "Mea'ai ma Meainu",
    fashion: "Teuga",
    health: "Soifua Maloloina",
    furniture: "Meafale",
    entertainment: "Fa'afiafiaga",
    automobile: "Ta'avale",
    education: "A'oga",
    beautyAndSpa: "Matagofie ma Spa",
    staycation: "Tumau",
    events: "Mea na tutupu",
    trips: "Malaga",
    oilAndGas: "Suau'u ma Kasa",
    laundry: "Tagamea",
    partnerCategory: "Vaega Paaga",
    freeItem: "Mea'ai fua",
  },
  Serbian: {
    cashier: "благајник",
    manager: "управник",
    owner: "власник",
    online: "онлине",
    offline: "одсутан",
    changePassword: "Промени лозинку",
    currentPasswordMessage: "Молимо унесите своју тренутну лозинку",
    passwordMessage: "Молим вас унесите вашу шифру",
    currentPassword: "Тренутна лозинка",
    password: "Лозинка",
    confirmPasswordMessage: "Молимо потврдите лозинку!",
    confirmPassword: "Потврди лозинку",
    sendViaEmail: "Пошаљи путем е -поште",
    sendViaWhatsapp: "Пошаљите путем ВхатсАпп -а",
    downloadPdf: "Преузмите ПДФ",
    paid: "плаћен",
    unpaid: "неплаћено",
    partial: "делимичан",
    closeInvoice: "Да ли желите да затворите фактуру?",
    closeInvoiceConfirmation:
      "Фактура се можда не може сачувати. Да ли желите да затворите?",
    yes: "да",
    no: "не",
    invoice: "Фактура",
    wasDeducted: "је одбијен",
    for: "за",
    item: "Ставка",
    addProduct: "Додајте производ",
    paymentReference: "Плаћање референца",
    amountPaid: "Износ плаћен",
    discountAmount: "Износ попуста",
    amountDue: "Преостали дуг",
    amount: "Износ",
    dueDate: "Рок предаје",
    paymentType: "Врста плаћања",
    card: "Цард",
    cash: "Готовином",
    bankTransfer: "Банкарски трансфер",
    paymentMessage: "Порука о плаћању",
    description: "Опис",
    sendReceipt: "Сенд Рецеипт",
    delete: "Избриши",
    save: "сачувати",
    resend: "Поново послати",
    saveAndSend: "сачувати",
    invoiceSaved: "Рачун је сачуван!",
    selectPaymentMethod: "Молимо изаберите начин плаћања!",
    selectCustomer: "Молимо изаберите клијента!",
    cartEmptyError:
      "Листа корпи не може бити празна, затворите рачун и додајте артикал у корпу!",
    subscriptionExpired:
      "Ваша претплата је истекла и ваш налог је сада ограничен. Кликните на дугме испод да бисте обновили налог",
    renew: "Обновити",
    holdOn: "Држи се",
    customerBank: "Банка клијената",
    cancel: "Поништити, отказати",
    selectACustomer: "Изаберите Клијент",
    invoiceSuccessfulPdfError:
      "Фактура је успешно направљена, али генерисање ПДФ -а траје дуже него обично. Проверите поново ускоро.",
    downloadingInvoice: "Преузимање фактуре",
    downloadingReceipt: "Преузимање потврде",
    whatsappReceiptError:
      "Дошло је до грешке при слању рачуна путем ВхатсАппа. Покушајте поново.",
    receiptToWhatsapp: "Рачун је прослеђен ВхатсАпп -у",
    thankYouForPatronage: "Хвала вам на покровитељству",
    hereIsYourReceipt: "Ево признанице о уплати",
    errorSendingEmailReceipt:
      "Дошло је до грешке при слању рачуна путем е-поште, покушајте поново или контактирајте подршку",
    receiptSentToEmail: "Рачун је послат на е -пошту купца",
    invoiceSentToEmail: "Фактура је послата на е -пошту купца",
    invoiceSuccessWhatsappError:
      "Рачун је успешно креиран, али је дошло до грешке при слању на ВхатсАпп. Покушајте поново на листи фактура",
    invoiceSuccessfulEmailError:
      "Рачун је успешно креиран, али је дошло до грешке при слању путем е-поште. Покушајте поново са листе фактура",
    invoiceSentToWhatsapp: "Рачун је прослеђен ВхатсАпп -у",
    hello: "Здраво",
    pleaseDownloadInvoice: "Молимо преузмите фактуру",
    pleaseDownloadReceipt: "Молимо вас да преузмете признаницу",
    from: "фром",
    email: "Емаил",
    upgrade: "Упграде",
    youAreOnFreePlan: "Налазите се на бесплатном плану.",
    clickOn: "Кликните на",
    yourPlanInFewSteps: " претплату у неколико брзих корака.",
    to: "до",
    yourSubscriptionHasExpired:
      "Ваша претплата је истекла и ваш налог је сада ограничен.",
    days: "дана",
    yourSubscriptionExpiresIn: "Ваша Лоистар претплата истиче за",
    createAcount: "Направи налог",
    signIn: "Пријавите се",
    continue: "Настави",
    enterOtp: "Унесите ОТП ПИН",
    enterValidOtp: "Унесите важећи ПИН",
    pin: "ПИН",
    tokenSentToMail: "Токен је послат на вашу е -пошту",
    passwordResetSuccessful: "Ресетовање лозинке је успешно",
    somethingWentWrong: "Нешто није у реду!",
    resetPassword: "Ресетуј шифру",
    iHaveResetCode: "Имам код за поништавање лозинке",
    pleaseEnterEmail: "Молимо унесите своју адресу е -поште",
    aTokenWillBeSentToEmail: "Токен ће бити послат на вашу е -пошту",
    enterEmail: "Унесите емаил",
    sendinYourToken: "Слање токена ...",
    makeSureItMatchesPassword: "Уверите се да се подудара са новом лозинком",
    pleaseChooseNewPassword: "Молимо изаберите нову лозинку",
    chooseNewPassword: "Одаберите нову лозинку",
    enterNewPassword: "Унесите нову лозинку за потврду",
    enterTokenSent: "Унесите токен који вам је послао пошту",
    resetToken: "Ресетујте токен",
    resettingPassword: "Ресетовање лозинке ...",
    signUp: "Пријави се",
    adminSignInWithEmail:
      " Администратор се пријављује путем е -поште, док се особље пријављује са корисничким именом.",
    pleaseEnterEmailOrUsername:
      "Молимо унесите своју адресу е -поште или корисничко име",
    emailOrUsername: "Емаил или корисничко име",
    pleaseEnterPassword: "Молим вас укуцајте лозинку",
    createAnAccount: "Направи налог",
    forgotPassword: "Заборавили сте лозинку?",
    enterPhoneNumber: "Унесите број телефона",
    personalData: "Лични подаци",
    validateConfirmationCOde: "Потврдите код за потврду",
    pleaseEnterFirstName: "Молимо Вас да унесете своје име",
    pleaseEnterPhoneNumber: "Унесите Ваш број телефона",
    pleaseEnterLastName: "Унесите презиме",
    pleaseEnterBusinessName: "Унесите назив предузећа",
    firstName: "Име",
    lastName: "Презиме",
    businessName: "Пословно име",
    previous: "Претходна",
    next: "Следећи",
    pleaseSelectBusinessCategory:
      "Молимо вас да изаберете категорију предузећа",
    pleaseEnterValidEmail: "Молимо Вас да унесете исправну е-маил",
    pleaseEnterPostCode: "Молимо унесите поштански број",
    postCode: "Поштански број",
    phoneNumberInUse: "Овај број телефона је већ у употреби!",
    emailInUse: "Ова адреса е -поште је већ у употреби!",
    foodAndDrinks: "Храна и пиће",
    salonAndBeauty: "Салон и лепота",
    fashionAndAccessories: "Мода и аксесоари",
    gymAndFitness: "Теретана и фитнес",
    travelAndHotel: "Путовања и хотел",
    homeAndGifts: "Дом и поклони",
    washingAndCleaning: "Прање и чишћење",
    gadgetsAndElectronics: "Гадгети и електроника",
    groceries: "Намирнице",
    others: "Други",
    submit: "прихвати",
    accountCreatedSuccessful: "Ваш налог је успешно креиран.",
    pleaseEnterAddress: "Молимо унесите своју адресу",
    addressLine1: "Адреса Линија 1",
    addressLine2: "Адреса 2",
    choosePassword: "Изабрати лозинку",
    passwordMustBe6Characters: "Лозинка мора имати најмање 6 знакова.",
    howDidYouHearLoystar: "Како сте чули за Лоистар?",
    referralCode: "упутни код",
    byClickingTheButton: " Кликом на дугме испод прихватате",
    termsAndSevice: "Услови",
    wereCreatingAccount: "Креирамо ваш налог",
    proceed: "Наставите",
    verificationCodeMustBe6: "Верификациони код мора имати 6 цифара",
    pleaseEnter6DigitCode: "Молимо унесите шестоцифрени код",
    enterVerificationCode: "Унесите верификациони код",
    resendToken: "Поново пошаљите токен",
    verify: "Проверити",
    transactions: "Трансакције",
    todaySales: "Данашња продаја",
    salesHistory: "Историја продаје",
    supplyHistory: "Историја снабдевања",
    new: "Нова",
    invoices: "Фактуре",
    disbursements: "Исплате",
    offlineSales: "Офлајн продаја",
    products: "Производи",
    customers: "Купци",
    multiLevelLoyalty: "Лојалност на више нивоа",
    loyaltyPrograms: "Програми лојалности",
    members: "Чланови",
    appStore: "Продавница апликација",
    orderMenu: "Мени за наручивање",
    settings: "Подешавања",
    staffAndBranches: "Особље и подружнице",
    myAccount: "Мој налог",
    switchToSellMode: "Пређите на режим продаје",
    signOut: "Одјава",
    getFreeSubscription: "Набавите бесплатну претплату",
    onlyNumbersAllowed: "Дозвољени су само бројеви",
    yourAccountMustBe10Digits: "број вашег рачуна мора бити десетоцифрени",
    yourBvnMustBe11: "ваш БВН мора бити 11 -цифрени број",
    pleaseSelectBank: "Молимо изаберите своју банку",
    selectYourBank: "Изаберите своју банку",
    enterBankAccountNumber: "Унесите број банковног рачуна",
    enterBvnNumber: "Унесите свој БВН",
    connectBankAccount: "Повежите банковни рачун",
    passwordResetSuccessfulAndSignOut:
      "Ваша лозинка је успешно поништена. Кликните на дугме Одјава испод да бисте се поново пријавили",
    enterCurrentPassword: "Унесите актуелну лозинку",
    pleaseEnterCurrentPassword: "Молимо унесите тренутну лозинку",
    phoneNumber: "Број телефона",
    sex: "Сек",
    dateOfBirth: "Датум рођења",
    state: "Држава",
    country: "Цоунтри",
    loyaltyId: "ИД лојалности",
    createdAt: "Креирано",
    noOfTransactionsMade: "Број извршених трансакција",
    yourDownloadWillStart: "Преузимање ће почети за тренутак",
    exportCustomers: "Извозни купци",
    youhaveSuccessfullyToppedUp: "Успешно сте допунили своје СМС јединице.",
    youNowHave: "Сада имате",
    smsUnits: "СМС јединице",
    enterNumberOfSmsUnits:
      "Унесите број СМС јединица које намеравате да купите",
    pleaseEnterANumericValue: "Унесите нумеричку вредност",
    pay: "Плати",
    accountEditedSuccessfully: "Ваш налог је успешно измењен.",
    youAeCurrentlyOn: "Тренутно сте укључени",
    plan: "План",
    userData: "Кориснички подаци",
    businessData: "ПОСЛОВНИ ПОДАЦИ",
    businessCategory: "Бусинесс Цатергори",
    pleaseSelectCurrency: "Молимо изаберите своју валуту",
    selectYourCurrency: "Изаберите своју валуту",
    purchaseMoreSmsUnits: "Купите још СМС јединица користећи доњи образац",
    youHave: "Имаш",
    atLeast4SmsUnitsRrequired:
      "За верификацију су потребне најмање 4 смс јединице. Молимо допуните!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Верификујте свој банковни рачун у складу са захтевима „Упознајте свог клијента“ (КИЦ). Ово ће вам омогућити прикупљање плаћања путем УССД -а или тренутни трансфер, примање налога од купаца и обраду намирења трансакција. Односи се само на нигеријске трговце. Притисните дугме испод за почетак.",
    reConnectBankAccount: "Поново повежите банковни рачун",
    accountName: "Назив рачуна",
    accountNumber: "Број рачуна",
    bankName: "Име банке",
    verified: "Проверено",
    accountDetails: "детаљи о рачуну",
    kycBankAccount: "КИЦ",
    createTier: "Цреате Тиер",
    fileUploadSuccessful: "датотека успешно отпремљена",
    fileUploadFailed: "отпремање датотеке није успело",
    createLoyaltyProgram: "Креирајте програм лојалности",
    createLoyalty: "Створите лојалност",
    name: "Име",
    loyaltyArtwork: "Лоиалти Артворк",
    clickToUpload: "Кликните да бисте отпремили",
    amountToPointsRatio: "Однос износа до поена",
    points: "Бодова",
    recommendedAmountToPointRatio:
      "Препоручује се: ₦ 1 до 1 поен. односно за сваку потрошену 1 Наиру ваши клијенти добијају 1 бод",
    pleaseTypeIn: "Молимо унесите",
    toDeleteLoyalty: "да обрише ову лојалност",
    deleteLoyalty: "Избришите лојалност",
    toConfirm: "за потврду",
    edit: "Уредити",
    pointsAwardedSuccessfully: "Бодови су успешно додељени.",
    enterPointValueToAward:
      "Унесите вредност поена коју желите да наградите купцима",
    award: "Награда",
    awardPointValuesToCustomer: "Доделите вредност поена клијенту",
    enrollMembersToLoyalty: "Учланите чланове у Лојалност",
    awardPoints: "Доделите поене",
    enroll: "Упиши се",
    home: "Кућа",
    loyalty: "Лојалност",
    enrollCustomers: "Упишите купце",
    selected: "Изабрано",
    customer: "Купац",
    loyaltyActivationSuccessful: "Активирање лојалности је успешно.",
    loyaltyDeactivationSuccessful: "Деактивација лојалности је успешна.",
    viewTier: "Виев Тиер",
    deactivate: "Деактивирај",
    activate: "Активирати",
    actions: "Радње",
    nameOfLoyalty: "Име лојалности",
    loyaltyStatus: "Статус лојалностиСтатус лојалности",
    numberOfTiers: "Број слојева",
    createdOn: "Направљено дана",
    createATier: "Направите ниво",
    stopCreatingTierConfirmation:
      "Да ли желите да престанете са стварањем слоја?",
    stopEditingTierConfirmation:
      "Желите ли да престанете са уређивањем овог нивоа?",
    nameOfTier: "Назив нивоа",
    minimumSpendingTarget: "Циљ минималне потрошње",
    maximumSpendingTarget: "Циљ максималне потрошње",
    minimumSpendingTargetRequired: "потребан је минимални циљ потрошње",
    maximumSpendingTargetRequired: "потребан је максималан циљ потрошње",
    rewardSponsor: "Спонзор награде",
    pleaseChooseARewardSponsor: "Молимо вас да изаберете спонзора награде",
    self: "Селф",
    partner: "Партнер",
    rewardPartner: "Наградни партнер",
    pleaseSelectRewardPartner: "Молимо изаберите партнера за награду",
    rewards: "Награде",
    pleaseSelectAReward: "Изаберите награду",
    instructionsOnRedeemingReward:
      "Упутства о томе како купац треба да искористи награду",
    pleaseFillInThisField: "Молимо попуните ово поље!",
    toDeleteThisTier: " да бисте избрисали овај ниво",
    deleteTier: "Избриши ниво",
    viewMembers: "Виев Мемберс",
    membersEnrolled: "Уписани чланови",
    instruction: "Упутство",
    membersIn: "Чланови у",
    availableTiersInLoyalty: "Доступни нивои у програму лојалности",
    tiers: "Тиерс",
    totalTier: "ТОТАЛ ТИЕР",
    availableLoyaltyProgramme: "Доступан програм лојалности",
    totalLoyalties: "УКУПНА ЛОЈАЛНОСТ",
    memberDetails: "Детаљи о члану",
    nameOfCustomer: "Име купца",
    address: "Адреса",
    allEnrolledMembers: "Сви уписани чланови",
    thisIsToWishYouHappyBirthday:
      "Овим путем желим вам срећан рођендан и срећан живот. Хвала ти за све што си Лоистер. Срећне прославе!",
    inputAnOfferPlease: "Молимо унесите понуду",
    pleaseSelectTheInsertPoint:
      "Молимо изаберите тачку уметања у поруку и кликните поново",
    birthdayOfferAndMessage: "Рођенданска понуда и порука",
    birthdayOffer: "Рођенданска понуда",
    birthdayMessage: "Рођенданска порука",
    noOfferFound: "Није пронађена ниједна понуда",
    settingABirthdayOffer:
      "Постављање рођенданске понуде омогућава клијентима да ову понуду добију путем СМС -а на свој рођендан",
    createOffer: "Направи понуду",
    whatIsTheOffer: "Која је понуда?",
    editMessage: "Измени поруку",
    insert: "Инсерт",
    theNameOfCustomerInserted: "Овде ће бити уметнуто име купца",
    theBirtdayOfferInserted: "Овде ће бити уметнута рођенданска понуда",
    youSuccessfullyAddedNewBranch: "Успешно сте додали нову грану!",
    addNewBranch: "Додајте нову грану",
    addBranch: "Адд Бранцх",
    additionalBranchWillIncur: "Додатна филијала би настала",
    perBranch: "по грани",
    ecommerceBranchCosts: "Трошкови подружнице е -трговине",
    pleaseEnterBranchName: "Молимо унесите назив филијале",
    pleaseEnterBranchAddress1: "Молимо унесите 1. ред адресе филијале",
    enterBranchAddress1: "Унесите 1. ред адресе филијале",
    enterBranchAddress2: "Унесите 1. ред адресе филијале",
    pleaseEnterBranchAddress2: "Молимо унесите 2. ред адресе филијале",
    enterBranchName: "Унесите назив огранка",
    successfullyAddedStaff: "Успешно сте додали ново особље!",
    addNewStaff: "Додајте ново особље",
    addStaff: "Додајте особље",
    additionalStaffWillIncur: "Додатно особље би настало",
    perStaff: "по особљу.",
    pleaseEnterStaffEmail: "Молимо унесите е -пошту особља",
    pleaseEnterStaffUsername: "Молимо унесите корисничко име особља",
    pleaseEnterStaffPassword: "Молимо унесите лозинку особља",
    pleaseSelectStaffRole: "Молимо вас да изаберете улогу особља",
    selectStaffRole: "Изаберите улогу особља",
    enterStaffEmail: "Унесите имејл особља",
    enterStaffUsername: "Унесите корисничко име особља",
    enterStaffPassword: "Унесите лозинку особља",
    spacesNotAllowedInUsername: "размаци нису дозвољени у корисничком имену",
    admin: "Админ",
    pleaseSelectBusinessToAttachStaff:
      "Молимо вас да изаберете предузеће за придруживање особља",
    searchForBranchToAttachStaff:
      "Потражите филијалу да бисте прикључили особље",
    username: "Корисничко име",
    role: "Улога",
    areYouSureToDeleteThis: "Јесте ли сигурни да ћете ово избрисати",
    branches: "Подружнице",
    upgradeYourPlan: "Надоградите свој план.",
    add: "ДОДАТИ",
    addNew: "Додај нови",
    customerWithEmailAlreadyExists:
      "Клијент са е -поштом/телефонским бројем већ постоји!",
    successfullyAddedNewCustomer: "Успешно сте додали новог клијента!",
    addCustomer: "Додајте клијента",
    pleaseEnterCustomerFirstName: "Молимо унесите име купца",
    pleaseEnterCustomerLastName: "Молимо унесите презиме купца",
    pleaseEnterCustomerPhoneNumber: "Молимо унесите телефонски број клијента",
    pleaseEnterCustomerEmail: "Молимо унесите е -маил клијента",
    pleaseEnterCustomerAddressLine: "Молимо унесите адресу клијента",
    pleaseEnterCustomerOtherAddress: "Молимо унесите другу адресу купца",
    pleaseSelectCustomerGender: "Молимо вас да изаберете пол клијента",
    gender: "Пол",
    male: "Мушки",
    female: "Женско",
    bank: "банка",
    selectBank: "Изаберите Банка",
    stateOrRegionOrProvince: "Држава/Регион/Покрајина",
    customerNotes: "Напомене купаца",
    sendSms: "Послати СМС",
    editCustomer: "Едит Цустомер",
    redeemReward: "Искористите награду",
    issueLoyaltyCard: "Издајте картицу лојалности",
    deleteCustomer: "Избришите клијента",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Успешно сте доделили ИД чланства лојалности",
    noMembershipIdAvailable:
      "Нема доступних ИД -ова чланства. Молимо контактирајте нас на хелло@лоистар.цо",
    sendEmail: "Шаљи имејл",
    membershipPoints: "Бодови за чланство",
    customerDetails: "детаљи о купцу",
    close: "Близу",
    loyaltyBalance: "Биланс лојалности",
    pointsBalance: "Биланс поена",
    starBalance: "Стар Баланце",
    requiredPoints: "Потребни бодови",
    requiredStars: "Потребне звезде",
    reddemCode: "Искористи код",
    toDeleteThisCustomer: "да избришете овог купца",
    customerHasBeenDeletedSuccessfully: "Клијент је успешно избрисан!",
    customerWithPhoneAlreadyExists: "Клијент са бројем телефона већ постоји",
    customerWasSuccessfullyEdited: "Клијент је успешно измењен",
    anErrorOccured: "Грешке",
    phoneNumberIncludeCountry: "Број телефона (Укључује позивни број земље)",
    yourFileQueuedForUpload:
      "Ваша датотека је у реду за отпремање. Освежите страницу након неколико секунди.",
    thereWasAnErrorPerformingOperation:
      "Дошло је до грешке при извођењу операције!",
    pleaseSelectFile: "Молимо изаберите датотеку!",
    oopsOnlyCsvFilesAllowed:
      "Упс! Дозвољене су само ЦСВ датотеке. Отпремите .цсв датотеку.",
    fileShouldBeSmallerThan5Mb:
      "Датотека би требала бити мања од 5 МБ. Ако имате већу датотеку, пошаљите е -поруку на суппорт@лоистар.цо. Хвала вам",
    customerFirstNameIsRequired: "име купца је потребно у реду",
    customerPhoneNumberIsRequired:
      "телефонски број корисника је потребан у реду",
    importCustomers: "Увоз купаца",
    upload: "Отпремити",
    clickIconToDownloadCsv:
      "Кликните на ову икону да бисте преузели предложак ЦСВ датотеке.",
    getGoogleSheetVersion: "Преузмите верзију Гоогле листа",
    clickOrDragFileToUpload:
      "Кликните или превуците датотеку у ову област да бисте је отпремили",
    missingOrInvalidColumnHeader:
      "Заглавље колоне недостаје или је неважеће. Пратите формат предлошка. Хвала вам.",
    youHaveImported: "Увезли сте",
    youSuccessfullyRedeemedReward: "Успешно сте откупили своју награду!",
    sixDigitCode: "Шестоцифрени код",
    pleaseEnterCustomerRewardCode: "Унесите код награде клијента",
    enterRewardCode:
      "Унесите код награде. (Код награде разликује велика и мала слова)",
    selectLoyaltyProgram: "Изаберите програм лојалности",
    stamps: "Марке",
    smsUnitsLow: "СМС јединице ниске",
    pleaseTopUpSmsUnits: "Допуните СМС јединице",
    smsSuccessfullySentForDelivery: "СМС је успешно испоручен!",
    sendSmsTo: "Пошаљите СМС на",
    allCustomers: "Сви купци",
    unitsAvailable: "Доступне јединице",
    pleaseTypeInTheMessage: "Молимо унесите поруку",
    message: "Порука",
    charactersRemaining: "преосталих знакова",
    avoidUseOfSpecialCharacters:
      "Избегавајте употребу посебних знакова и емотикона за очување СМС јединица.",
    note: "Белешка",
    errorFetchingCustomersMultilevelDetail:
      "Грешка при дохваћању детаља о више нивоа купаца",
    search: "Претрага",
    reset: "Ресетовање",
    importCustomer: "Увези купца",
    addNewCustomer: "Додајте новог клијента",
    sendSmsBroadcast: "Пошаљи СМС емитовање",
    totalCustomers: "УКУПНО КЛИЈЕНТИ",
    disbursementDetails: "Детаљи исплате",
    paidBy: "Плаћа",
    disbursed: "Исплаћено",
    bankAccountName: "Име банковног налога",
    bankAccountNumber: "Број рачуна",
    transferInitiated: "Пренос започет",
    transferCompleted: "Пренос завршен",
    pleaseEnterAValid: "Унесите исправну",
    begin: "започети",
    end: "крај",
    date: "датум",
    paymentDate: "Датум исплате",
    selectDisbursementDuration: "Изаберите Трајање исплате",
    totalSettled: "Укупно решено",
    totalUnsettled: "Укупно Неријешено",
    toDeleteThisSale: "да обришем ову продају",
    draftSaleDeletedSuccessfully: "Нацрт продаје је успешно избрисан!",
    deleteSale: "Избриши Сале",
    pleaseTypeInYourTotal: "Унесите укупан број да бисте потврдили брисање",
    billDetails: "Детаљи о рачуну",
    printBill: "Принт Билл",
    servedBy: "Опслужује",
    total: "Укупно",
    createdDate: "Датум креирања",
    createdTime: "Цреатед Тиме",
    status: "Статус",
    loginSuccessful: "Пријављивање успешно",
    pleaseWaitWhileWeConnectAccount: "Сачекајте док не повежемо ваш налог",
    connectionFailedTryAgain:
      "Веза није успостављена. Молим вас, покушајте поново.",
    connectionSuccessful: "Веза је успела",
    viewDetails: "Приказ детаља",
    enable: "Омогући",
    free: "бесплатно",
    cost: "Цост",
    visitWebsite: "Посетите сајт",
    pleaseUpgradeYourPlanToPro:
      "Надоградите свој план на Про Плус да бисте омогућили ову апликацију",
    connectYourBankAccount:
      "Повежите свој банковни рачун да бисте могли да примате уплате.",
    disable: "Онемогући",
    enableApp: "Омогући апликацију",
    addNewProductToInvoice: "Додајте нови производ у фактуру",
    toDeleteThisInvoice: "за брисање ове Фактуре",
    invoiceDeletedSuccessfully: "Фактура је успешно избрисана!",
    deleteInvoice: "Избришите фактуру",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Успешно сте додали поруку о плаћању фактуре!",
    pleaseEnterPaymentInstructions: "Унесите упутства за плаћање",
    invoiceId: "ИД фактуре",
    paidDate: "Датум плаћања",
    reference: "Референце",
    productAdded: "Производ је додат",
    productOutOfStock:
      "Производ није на лагеру. Молимо вас да поново направите залихе.",
    totalInvoices: "УКУПНО РАЧУНИ",
    totalPaidInvoices: "УКУПНО ПЛАЋЕНИ РАЧУНИ",
    totalUnpaidInvoices: "УКУПНО НЕПЛАЋЕНЕ РАЧУНЕ",
    loyaltyProgramDeleted: "Програм лојалности је избрисан",
    thisLoyaltyProgramDeletedSuccessfully:
      "Овај програм лојалности је успешно избрисан",
    thisLoyaltyProgramEditedSuccessfully:
      "Овај програм лојалности је успешно уређен",
    loyaltyProgramAddedSuccessfully: "Програм лојалности је успешно додат",
    loyaltyProgramEdited: "Програм лојалности измењен",
    loyaltyProgramAdded: "Програм лојалности је додат",
    loyaltyDetails: "Детаљи о лојалности",
    doYouWantToCloseDialog: "Да ли желите да затворите овај дијалог?",
    pleaseEnterLoyaltyName: "Молимо унесите назив своје лојалности",
    programType: "Тип програма",
    pleaseSelectProgramType: "Молимо одаберите врсту програма",
    simplePoints: "Симпле Поинтс",
    stampsProgram: "Програм за марке",
    threshold: "Тхресхолд",
    pleaseEnterLoyaltyThreshold: "Унесите праг лојалности",
    reward: "Награда",
    pleaseEnterLoyaltyReward: "Молимо вас унесите награду лојалности",
    totalUserPoints: "Укупан број корисничких поена",
    totalUserStamps: "Укупно корисничких марки",
    spendingTarget: "Циљ потрошње",
    spendingTargetHint1:
      "Циљ потрошње је колико купац мора потрошити да би зарадио награду. 1 вредност валуте = 1 бод.",
    spendingTargetHint2:
      "Циљеви маркица су колико маркица купац мора прикупити да би зарадио награду. на пример. 5",
    addNewLoyaltyProgram: "Додајте нови програм лојалности",
    addLoyaltyProgram: "Додајте програм лојалности",
    loyaltyProgramType: "Тип програма лојалности",
    pleaseSelectLoyaltyProgramType:
      "Молимо изаберите врсту програма лојалности",
    nameOfProgram: "Назив програма",
    pleaseEnterProgramName: "Молимо унесите назив програма",
    whatIsTheReward: "Која је награда?",
    egNextPurchaseIsFree: "На пример. Следећа куповина је бесплатна",
    customerName: "Име клијента",
    guestCustomer: "Гост купац",
    orderConfirmedSuccessfully: "Поруџбина је успешно потврђена",
    orderCancelledSuccessfully: "Поруџбина је успешно отказана",
    confirmOrder: "Потврди наредбу",
    cancelOrder: "Откажи поруџбину",
    allOrders: "Све поруџбине",
    totalOrders: "УКУПНО НАРУЏБЕ",
    doYouWantToAcceptOrder: "Да ли желите да прихватите ову поруџбину?",
    doYouWantToCancelOrder: "Да ли желите да откажете ову поруџбину?",
    orderDetails: "Детаљи поруџбине",
    orderCreatedAt: "Поруџбина је направљена у",
    supplier: "Добављач",
    productName: "Назив производа",
    quantity: "Куантити",
    unitPrice: "Цена по јединици",
    receivedBy: "Прима",
    reportFrom: "Извештај од",
    totalSupplies: "Тотал Супплиес",
    allRightsReserved: "Сва права задржана",
    toDeleteThisTransaction: "да бисте избрисали ову трансакцију",
    transactionDeletedSuccessfully:
      "Трансакција је успешно избрисана. Залихе су враћене у инвентар.",
    deleteTransaction: "Избриши трансакцију",
    transactionDetails: "Детаљи трансакције",
    printReceipt: "Одштампај признаницу",
    channel: "Цханнел",
    discount: "Попуст",
    profit: "Профит",
    discountedSales: "Продаја са попустом",
    errorFetchingRecord: "Грешка при преузимању записа",
    exportTransactions: "Извозне трансакције",
    errorFetchingSalesRecord:
      "Грешка при преузимању продајног записа за извоз.",
    totalSellingPrice: "Укупна продајна цена",
    totalCostPrice: "Укупна цена коштања",
    appliedDiscount: "Примењени попуст",
    noOfItems: "Број ставки",
    sales: "Продаја",
    export: "Извоз",
    totalProfit: "Укупан профит",
    totalBalanceInPeriod: "Укупно стање у периоду",
    allTimeSales: "Алл Тиме Салес",
    records: "Рецордс",
    todaysSales: "Данашња продаја",
    transaction: "трансакција",
    youHaveExceededTotalNumberOfProducts:
      "Премашили сте укупан број производа дозвољених у вашем плану. Надоградите свој план да бисте уживали у већој граници.",
    youNeedToHaveLoyaltyProgram:
      "Морате имати програм лојалности да бисте користили ову функцију!",
    price: "Цена",
    category: "Категорија",
    stockTracking: "Праћење акција",
    stockCount: "Стоцк Цоунт",
    taxed: "Опорезиво",
    originalPrice: "Оригинална цена",
    costPrice: "Цена коштања",
    unit: "Јединица",
    productImage: "Слика производа",
    taxRate: "Пореска стопа",
    taxType: "Врста пореза",
    trackInventory: "Инвентар праћења",
    variants: "Варијанте",
    hasVariants: "Има варијанте",
    importProduct: "Увоз производа",
    exportProducts: "Извоз производа",
    addNewProduct: "Додајте нови производ",
    viewCategory: "Прикажи категорију",
    viewSuppliers: "Погледајте добављаче",
    receiveInventory: "Прими инвентар",
    printAllProductsTag: "Одштампајте ознаку свих производа",
    deleteAll: "Избриши све",
    totalProducts: "УКУПНИ ПРОИЗВОДИ",
    youveSuccessfullyAddedANewCategory: "Успешно сте додали нову категорију",
    addNewCategory: "Додајте нову категорију",
    addCategory: "Додај категорију",
    categoryName: "име категорије",
    pleaseEnterCategoryName: "Молимо унесите назив категорије",
    stampsTarget: "Марке Таргет",
    sendInventory: "Пошаљи инвентар",
    productDetails: "детаљи о производу",
    youveSuccessfullyEditedThisCategory: "Успешно сте уредили ову категорију",
    update: "ажурирање",
    categoryList: "Листа категорија",
    categories: "Категорије",
    enterQuantityToUpdate: "Унесите количину за ажурирање",
    inventorySentSuccessfully: "Инвентар је успешно послат!",
    updateInventory: "Ажурирајте инвентар",
    currentQuantity: "Тренутна количина",
    pleaseEnterQuantityToAdd: "Молимо унесите количину коју желите да додате",
    pleaseSelectABranch: "Молимо вас да одаберете грану",
    searchForBranch: "Потражите грану",
    youCantSendMoreThanStock:
      "Не можете послати више него што имате на залихама",
    send: "Пошаљи",
    pleaseEnterQuantityToSend:
      "Молимо унесите количину коју желите да пошаљете",
    productNameIsRequiredOnRow: "назив производа је обавезан у реду",
    productCategoryIsRequiredOnRow: "категорија производа је обавезна у реду",
    productPriceIsRequiredOnRow: "цена производа је потребна у реду",
    productUnitIsRequiredOnRow: "јединица производа је потребна у реду",
    productQuantityIsRequiredOnRow: "количина производа је потребна у реду",
    productVariantsRequireTracking: "варијанте производа захтевају праћење!",
    pleaseAddVariantClickButton:
      "Молимо вас да додате варијанту кликом на дугме за додавање!",
    totalVariantsMoreThanSelectedQuantity:
      "Укупне варијанте су више од одабране количине производа, Плс смањује количину варијанте",
    productEditedSuccessfully: "Производ је успешно уређен!",
    fileTooLargeLessThan4Mb:
      "Величина датотеке је превелика! Величина датотеке би требала бити мања од 4 МБ.",
    suchVariantAlreadyExist: "Такве варијанте већ постоје",
    addVariants: "Додајте варијанте",
    editProduct: "Измените производ",
    pleaseEnterProductName: "Молимо унесите назив производа",
    pleaseEnterProductPrice: "Молимо унесите цену производа",
    pleaseEnterProductOriginalPrice: "Молимо унесите оригиналну цену производа",
    productDescription: "Опис производа",
    selectProductCategory: "Одаберите категорију производа",
    pleaseSelectProductCategory: "Молимо изаберите категорију производа",
    productCostPrice: "Цена производа",
    productSellingPrice: "Продајна цена производа",
    productOriginalPrice: "Оригинална цена производа",
    maxFileSizeAllowedIs4Mb: "Макс. Дозвољена величина датотеке је 4 МБ",
    productsWithPicturesArePublished:
      "Производи са сликама се објављују на Дисцовер -у ради примања наруџбина",
    shouldThisProductBeTracked: "Да ли треба пратити овај производ?",
    pleaseSelectStockUnit: "Молимо изаберите јединицу залихе",
    stockUnit: "Стоцк Унит",
    bag: "КЕСА",
    bottle: "БОЦА",
    bunch: "БУНЦХ",
    can: "МОЋИ",
    carton: "КАРТОНСКА КУТИЈА",
    crate: "САНДУК",
    cup: "ЦУП",
    dozen: "ДОЗЕН",
    gigabytes: "ГИГАБИТЕС",
    gram: "ГРАМ",
    kilogram: "КИЛОГРАМ",
    litre: "ЛИТЕР",
    pack: "ПАКОВАЊЕ",
    pair: "ПАИР",
    pieces: "КОМАДА",
    plate: "ТАЊИР",
    tonne: "ТОННЕ (МТ)",
    uNIT: "ЈЕДИНИЦА",
    yard: "ДВОРИШТЕ",
    pleaseEnterProductQuantity: "Молимо унесите количину производа",
    productQuantity: "Количина производа",
    isThisProductTaxed: "Да ли се овај производ опорезује?",
    selectTaxType: "Одаберите врсту пореза",
    pleaseSelectTaxType: "Молимо изаберите врсту пореза",
    progressive: "Прогрессиве",
    proportional: "Пропорционално",
    pleaseEnterProductTaxRate: "Унесите стопу пореза на производе",
    doesProductHaveVariants: "Да ли овај производ има варијанте?",
    type: "Тип",
    value: "Вредност",
    pleaseEnterVariantType: "Унесите тип варијанте",
    pleaseEnterVariantValue: "Унесите вредност варијанте",
    pleaseEnterVariantPrice: "Молимо вас унесите цену варијанте",
    pleaseEnterVariantQuantity: "Молимо унесите количину варијанте",
    productDeletedSuccessfully: "Производ је успешно избрисан!",
    categoryDeletedSuccessfully: "Категорија је успешно избрисана!",
    toDeleteThisProduct: "да избришете овај Производ",
    invalidProductQuantity: "Неважећа количина производа",
    quantityAddedIsOverStock:
      "Количина коју додајете је већа од онога што имате на залихама.",
    itemInventoryNotTracked: "Инвентар ставки се не прати",
    addBulkQuantity: "Додајте масовну количину",
    enterBulkQuantity: "Унесите масовну количину",
    pleaseEnterBulkQuantity: "Унесите масовну количину",
    youveSuccessfullyAddedANewProduct: "Успешно сте додали нови производ!",
    pleaseEnterProductSellingPrice: "Молимо унесите продајну цену производа",
    doYouWantToTrackProductStock: "Да ли желите да пратите залихе производа?",
    sellingPrice: "Продајна цена",
    setProductExpiryReminder: "Подесите подсетник истека производа",
    productExpiryDate: "Датум истека производа",
    startRemindingFrom: "Почни да подсећаш на",
    productSuppliesAddedSuccessfully: "Успешно сте додали залихе производа.",
    addProductSupplies: "Додајте залихе производа",
    pleaseSelectSupplier: "Молимо изаберите Добављача",
    nameOfProduct: "Назив производа",
    pleaseSelectProduct: "Молимо изаберите производ",
    productVariant: "Варијанта производа",
    pleaseSelectAVariant: "Молимо вас да изаберете варијанту",
    pleaseEnterUnitPrice: "Молимо унесите јединичну цену",
    businessBranch: "Пословна филијала",
    pleaseSelectBranch: "Молимо изаберите грану",
    youveSuccessfullyAddedANewSupplier: "Успешно сте додали новог добављача",
    addSupplier: "Додајте добављача",
    pleaseEnterSupplierEmail: "Молимо унесите е -пошту добављача",
    pleaseAddADescription: "Додајте опис",
    anErrorOccuredProductsDeleted:
      "Дошло је до грешке при извођењу операције. Имајте на уму да је у току процеса можда избрисано неколико производа",
    bulkDelete: "Скупно брисање",
    youAreAboutToDelete: "Управо ћете избрисати",
    product: "Производ",
    isDueToRedeem: "треба да се откупи",
    aReward: "награда",
    pleaseSelectCustomerToReeemReward:
      "Молимо изаберите клијента да бисте искористили награду.",
    youHaveNoLoyaltyProgramRunning: "Немате активан програм лојалности",
    customerHhasNoPointsInLoyaltyProgram:
      "Клијент нема бодова у овом програму лојалности",
    proceedWithPayment: "Наставити са плаћањем?",
    youAreAboutToPayForTransactionUsingPoints:
      "Планирате да трансакцију платите поенима.",
    customerHas: "Купац има",
    worth: "вреди",
    andIsNotSufficientToPayForTransaction:
      "и није довољно за плаћање ове трансакције. Да ли би желели да додају стање помоћу другог начина плаћања?",
    addCustomerLoyalty: "Додајте лојалност купаца",
    pleaseAddCustomerFirst: "Молимо вас да прво додате или изаберете клијента.",
    pleaseWaitWhileWeGetReady: "Сачекајте док се не спремимо",
    lowStock: "Лов Стоцк",
    pleaseEnterAmountTendered: "Молимо вас да унесете понуђени износ",
    areYouSureToBookSaleOffline:
      "Јесте ли сигурни да желите да резервишете ову продају ван мреже?",
    saleWouldBeBookedAutomatically:
      "Продаја се аутоматски књижи када укључите интернет",
    offlineSalesBookingCancelled: "Резервисање продаје ван мреже отказано",
    covid19Message:
      "ЦОВИД19: Оперите руке сапуном или дезинфикујте да бисте спречили ширење. Увек останите на сигурном",
    saleSuccessfullyRecorded: "Продаја успешно снимљена!",
    sendReceiptToEmail: "Пошаљите признаницу на е -пошту",
    sendThankYouSms: "Пошаљите захвални СМС",
    sendShippingDetails: "Пошаљите детаље испоруке",
    addLoyalty: "Додајте лојалност",
    searchCustomerNameOrNumber: "Претражите име или број клијента",
    clickTheSearchCustomerBox:
      "Кликните на Претражи кориснички оквир и картицу за скенирање",
    enterProductPrice: "Унесите цену производа",
    enterPriceFor: "Унесите цену за",
    searchForProduct: "Потражите производ",
    all: "Све",
    backToDashboard: "Назад на контролну таблу",
    viewDraftSales: "Погледајте Нацрт продаје",
    variantSelected: "изабрана варијанта",
    variant: "варијанта",
    thePreviousVariantSelectionNotAvailable:
      "Претходни избор варијанте није доступан за нову варијанту изабрану на основу цене. Молимо вас да промените свој избор.",
    pleaseSupplyPositiveQuantityNumber: "Наведите позитиван број количине",
    lowStockFor: "Ниске залихе за",
    clearVariants: "Јасне варијанте",
    pleaseEnterQuantity: "Молимо унесите количину",
    picture: "Слика",
    redemptionToken: "Токен Редемптион",
    token: "Токен",
    totalSpent: "Укупно потрошено",
    confirmPayment: "Потврдите уплату",
    hasPaymentBeenMade: "Да ли је уплата успешно обрађена?",
    enterTransactionReference:
      "Унесите референцу трансакције којом сте платили",
    pleaseSelectACustomer: "Молимо изаберите клијента!",
    areYouSureToApplyDiscount:
      "Јесте ли сигурни да желите да примените попуст?",
    addYourBankAccountToEnableUssd:
      "Додајте свој банковни рачун да бисте омогућили тренутни УССД трансфер путем уПаи -а",
    totalAmountToPay: "Укупан износ за плаћање",
    doYouWantToCancelTransaction: "Да ли желите да откажете ову трансакцију?",
    savePrintBill: "Сачувај/одштампај рачун",
    enterAmountCollectedForCustomer: "Унесите прикупљени износ за купца",
    recordSale: "Рецорд Сале",
    checkOutWith: "Одјавите се са",
    instantTransfer: "Инстант Трансфер",
    dialTheUSSDCode: "Позовите УССД код",
    pleaseSelectABank: "Изаберите банку",
    payWithUSSD: "Платите путем УССД -а",
    sendBillTo: " - Пошаљи Билл на",
    waitingForUSSDTransfer: "Чека се УССД пренос",
    percent: "Проценат",
    applyDiscount: "Примените попуст",
    thisBillHasBeenSaved: "Овај рачун је сачуван",
    saveDraft: "Сачувај нацрт",
    pleaseTypeANameToIdentifyCustomer:
      "Унесите име да бисте идентификовали купца",
    paymentDetails: "Подаци о плаћању",
    basePrice: "Основна цена",
    staff: "Особље",
    subTotal: "СубТотал",
    durationMonths: "Трајање (месеци)",
    selectADuration: "Изаберите Трајање",
    oneMonth: "1 месец",
    twoMonths: "2 месеца",
    threeMonths: "3 месеца",
    sixMonths: "6 месеци",
    twelveMonths: "12 месеци",
    eighteenMonths: "18 месеци",
    twentyFourMonths: "24 месеца",
    twoMonthsFree: "(2 месеца бесплатно)",
    threeMonthsFree: "(3 месеца бесплатно)",
    fiveMonthsFree: "(5 месеци бесплатно)",
    yourAccountHasBeen: "Ваш налог је био",
    renewed: "обновљено",
    upgraded: "надограђен",
    successfully: "успешно",
    yourSubscription: "Ваша претплата",
    youAreCurrentlyEnrolledOnA: "Тренутно сте уписани на а",
    pleaseChooseAPaymentOption: "Молимо вас да изаберете опцију плаћања",
    planRenewal: "План обнове",
    planUpgrade: "Планирајте надоградњу",
    pleaseSelectDurationToPayFor:
      "Молимо изаберите Трајање за које намеравате да платите",
    staffAccounts: "Рачуни особља",
    ecommerce: "Е -трговина",
    pleaseSelectAPlan: "Молимо изаберите план",
    includeAddons: "Укључите додатке",
    viewTransactions: "Погледајте трансакције",
    customerHasNoCompletedTansactions: "Клијент још није завршио трансакције",
    branch: "Бранцх",
    enterNumberOfEcommerceBranches: "Унесите број филијала е -трговине",
    enterNumberOfEcommerceBranchesToPay:
      "Унесите број филијала е -трговине за које намеравате да платите",
    ecommerceIntegration: "Интеграција е -трговине",
    enterNumberOfBranches: "Унесите број грана",
    enterNumberOfAdditionalBranchesToPay:
      "Унесите број додатних филијала за које намеравате да платите",
    enterNumberOfStaffs: "Унесите број особља",
    enterNumberOfStaffsToPayFor:
      "Унесите број запослених који намеравате да платите",
    discountApplies: "Важи попуст",
    starsOnThe: "звезде на",
    offer: "понуда",
    get: "Добити",
    moreStarsToRedeem: "више звезда за откуп",
    taxVat: "Порез (ПДВ)",
    callCashierToCompletePayment:
      "Позовите благајника да бисте довршили плаћање",
    receipt: "Признаница",
    dear: "Драга",
    thankYouForYourOrderFindGoods:
      "Хвала вам на наруџбини. У складу са договором пронађите следећу испоручену робу.",
    shippingNote: "Напомена о отпреми",
    enterShippingNote: "Унесите напомену о отпреми",
    shippingAddress: "Достава Адреса",
    enterShippingAddress: "Унесите адресу за испоруку",
    wellDoneYouAreDueToRedeem: "Добро урађено! Треба да откупите",
    toGetYourRewardNextVisit:
      "да бисте добили награду приликом следеће посете. Хвала вам",
    pointsOnThe: "Тачке на",
    morePointsToRedeem: "више бодова за откуп",
    showCode: "Прикажи код",
    toGetYourRewardOnNextVisit:
      "да бисте добили награду приликом следеће посете. Хвала вам",
    earn: "Зарадите",
    delivaryNote: "Напомена о испоруци",
    draftSales: "Нацрт продаје",
    startDate: "Датум почетка",
    endDate: "Крајњи датум",
    orders: "Наруџбе",
    checkout: "проверити",
    noProductItem: "Нема ставке производа",
    selectProductImage: "Изаберите слику производа",
    selectCountry: "Одабери земљу",
    selectRegion: "Одаберите државу/регију",
    printProductTag: "Одштампајте ознаку производа",
    transactionReference: "Референца трансакције",
    Cashier: "Благајник",
    Manager: "Менаџер",
    Owner: "Власник",
    Admin: "Админ",
    addPartners: "Dodajte partnere",
    addNewLoyaltyPartner: "Dodajte novog partnera za lojalnost",
    pleaseEnterCompanyName: "Unesite naziv kompanije",
    companyName: "назив фирме",
    pleaseEnterCompanyRepName: "Unesite ime predstavnika kompanije",
    companyRepName: "Ime predstavnika kompanije",
    pleaseEnterCompanyRepEmail: "Unesite adresu e-pošte predstavnika kompanije",
    companyRepEmail: "E-mail predstavnika kompanije",
    pleaseEnterCompanyRepPhone: "Unesite broj telefona predstavnika kompanije",
    companyRepPhone: "Broj telefona predstavnika kompanije",
    addReward: "Dodajte nagradu",
    pleaseEnterRewardName: "Unesite naziv nagrade",
    rewardName: "Naziv nagrade",
    rewardQuantity: "Količina nagrade",
    rewardDescription: "Opis nagrade",
    rewardType: "Vrsta nagrade",
    pleaseEnterRewardType: "Unesite vrstu nagrade",
    pleaseEnterRewardQuantity: "Unesite količinu nagrade",
    pleaseEnterRewardDescription: "Unesite opis nagrade",
    fineDining: "Fine Dining",
    luxuryFashion: "Luksuzna moda",
    hotels: "Hoteli",
    travel: "Travel",
    foodAndBeverage: "Храна и пиће",
    fashion: "Moda",
    health: "Здравље",
    furniture: "Nameštaj",
    entertainment: "Забава",
    automobile: "Automobile",
    education: "образовање",
    beautyAndSpa: "Beauty and Spa",
    staycation: "Staycation",
    events: "Događaji",
    trips: "Putovanja",
    oilAndGas: "Нафте и гаса",
    laundry: "Veš",
    partnerCategory: "Partner Category",
    freeItem: "Free Item",
  },
  Romanian: {
    cashier: "casier",
    manager: "administrator",
    owner: "proprietar",
    online: "pe net",
    offline: "deconectat",
    changePassword: "Schimbați parola",
    currentPasswordMessage: "Vă rugăm să introduceți parola dvs. curentă",
    passwordMessage: "Va rugam sa introduceti parola",
    currentPassword: "Parola actuală",
    password: "parola",
    confirmPasswordMessage: "Vă rugăm să vă confirmați parola!",
    confirmPassword: "Confirmă parola",
    sendViaEmail: "Trimiteți prin e-mail",
    sendViaWhatsapp: "Trimite prin WhatsApp",
    downloadPdf: "Descărcați PDF",
    paid: "plătit",
    unpaid: "neplătit",
    partial: "parțial",
    closeInvoice: "Doriți să închideți factura?",
    closeInvoiceConfirmation:
      "Este posibil ca factura să nu fie salvată. Vrei să închizi?",
    yes: "da",
    no: "Nu",
    invoice: "Factura fiscala",
    wasDeducted: "a fost dedus",
    for: "pentru",
    item: "Articol",
    addProduct: "Adăugați produs",
    paymentReference: "Referință de plată",
    amountPaid: "Suma plătită",
    discountAmount: "Suma de reducere",
    amountDue: "Suma datorată",
    amount: "Cantitate",
    dueDate: "Data scadentă",
    paymentType: "Tipul de plată",
    card: "Card",
    cash: "Bani gheata",
    bankTransfer: "Transfer bancar",
    paymentMessage: "Mesaj de plată",
    description: "Descriere",
    sendReceipt: "Trimiteți chitanța",
    delete: "Șterge",
    save: "Salvați",
    resend: "Retrimiteți",
    saveAndSend: "Salvați",
    invoiceSaved: "Factura salvată!",
    selectPaymentMethod: "Vă rugăm să selectați o metodă de plată!",
    selectCustomer: "Vă rugăm să selectați un client!",
    cartEmptyError:
      "Lista de coșuri nu poate fi goală, închideți factura și adăugați articolul în coș!",
    subscriptionExpired:
      "Abonamentul dvs. a expirat, iar contul dvs. este acum limitat. Faceți clic pe butonul de mai jos pentru a vă reînnoi contul",
    renew: "Reînnoi",
    holdOn: "Stai asa",
    customerBank: "Banca clienților",
    cancel: "Anulare",
    selectACustomer: "Selectați Client",
    invoiceSuccessfulPdfError:
      "Factura a fost creată cu succes, dar Generarea PDF durează mai mult decât de obicei. Vă rugăm să reveniți în curând.",
    downloadingInvoice: "Descărcarea facturii",
    downloadingReceipt: "Descărcarea chitanței",
    whatsappReceiptError:
      "A apărut o eroare la trimiterea chitanței prin WhatsApp. Încercați din nou.",
    receiptToWhatsapp: "Chitanță redirecționată către WhatsApp",
    thankYouForPatronage: "Vă mulțumim pentru mecenat",
    hereIsYourReceipt: "Iată chitanța de plată",
    errorSendingEmailReceipt:
      "A apărut o eroare la trimiterea chitanței prin e-mail, vă rugăm să încercați din nou sau să contactați asistența",
    receiptSentToEmail: "Chitanța a fost trimisă pe e-mailul clientului",
    invoiceSentToEmail: "Factura a fost trimisă pe e-mailul clientului",
    invoiceSuccessWhatsappError:
      "Factura a fost creată cu succes, dar a apărut o eroare la trimiterea către WhatsApp. Încercați din nou pe lista de facturi",
    invoiceSuccessfulEmailError:
      "Factura a fost creată cu succes, dar a apărut o eroare la trimiterea ca e-mail. Încercați din nou din lista de facturi",
    invoiceSentToWhatsapp: "Factură redirecționată către WhatsApp",
    hello: "Buna ziua",
    pleaseDownloadInvoice: "Vă rugăm să descărcați factura",
    pleaseDownloadReceipt: "Vă rugăm să descărcați chitanța",
    from: "din",
    email: "E-mail",
    upgrade: "Modernizare",
    youAreOnFreePlan: "Sunteți în plan gratuit.",
    clickOn: "Click pe",
    yourPlanInFewSteps: " abonamentul dvs. în câțiva pași rapizi.",
    to: "la",
    yourSubscriptionHasExpired:
      "Abonamentul dvs. a expirat, iar contul dvs. este acum limitat.",
    days: "zile",
    yourSubscriptionExpiresIn: "Abonamentul dvs. Loystar expiră în",
    createAcount: "Creează un cont",
    signIn: "Conectare",
    continue: "Continua",
    enterOtp: "Introduceți codul PIN OTP",
    enterValidOtp: "Vă rugăm să introduceți un cod PIN valid",
    pin: "Cod PIN",
    tokenSentToMail: "Un jeton a fost trimis la adresa dvs. de e-mail",
    passwordResetSuccessful: "Resetarea parolei a avut succes",
    somethingWentWrong: "Ceva n-a mers bine!",
    resetPassword: "Reseteaza parola",
    iHaveResetCode: "Am un cod de resetare a parolei",
    pleaseEnterEmail: "te rog introdu e-mailul tău",
    aTokenWillBeSentToEmail: "Un jeton ar fi trimis la adresa dvs. de e-mail",
    enterEmail: "Introduceți adresa dvs. de email",
    sendinYourToken: "Se trimite simbolul dvs. ...",
    makeSureItMatchesPassword:
      "Asigurați-vă că se potrivește cu noua dvs. parolă",
    pleaseChooseNewPassword: "Vă rugăm să alegeți o parolă nouă",
    chooseNewPassword: "Alegeți o parolă nouă",
    enterNewPassword: "Introduceți noua parolă pentru confirmare",
    enterTokenSent: "Introduceți indicativul care a fost trimis prin e-mail",
    resetToken: "Resetați jetonul",
    resettingPassword: "Resetarea parolei ...",
    signUp: "Inscrie-te",
    adminSignInWithEmail:
      " Administratorul se conectează cu e-mail, în timp ce personalul se conectează cu numele de utilizator.",
    pleaseEnterEmailOrUsername:
      "Vă rugăm să introduceți adresa de e-mail sau numele de utilizator",
    emailOrUsername: "Adresă de e-mail sau nume de utilizator",
    pleaseEnterPassword: "Te rog introdu parola",
    createAnAccount: "Creează un cont",
    forgotPassword: "Ați uitat parola?",
    enterPhoneNumber: "Introduceti numarul de telefon",
    personalData: "Date personale",
    validateConfirmationCOde: "Validați codul de confirmare",
    pleaseEnterFirstName: "Vă rugăm să introduceți prenumele",
    pleaseEnterPhoneNumber: "Vă rugăm să introduceți numărul dvs. de telefon",
    pleaseEnterLastName: "Vă rugăm să introduceți numele de familie",
    pleaseEnterBusinessName: "Vă rugăm să introduceți numele companiei dvs.",
    firstName: "Nume",
    lastName: "Nume",
    businessName: "Nume de afaceri",
    previous: "Anterior",
    next: "Următorul",
    pleaseSelectBusinessCategory:
      "Vă rugăm să selectați categoria companiei dvs.",
    pleaseEnterValidEmail: "te rog introdu un email valid",
    pleaseEnterPostCode: "Vă rugăm să introduceți codul poștal",
    postCode: "Cod poștal",
    phoneNumberInUse: "Acest număr de telefon este deja utilizat!",
    emailInUse: "Acest e-mail este deja utilizat!",
    foodAndDrinks: "Mancare si bautura",
    salonAndBeauty: "Salon și Frumusețe",
    fashionAndAccessories: "Modă și accesorii",
    gymAndFitness: "Sala de fitness și fitness",
    travelAndHotel: "Călătorii și Hotel",
    homeAndGifts: "Acasă și Cadouri",
    washingAndCleaning: "Spălare și curățare",
    gadgetsAndElectronics: "Gadgeturi și electronice",
    groceries: "Produse alimentare",
    others: "Alții",
    submit: "Trimite",
    accountCreatedSuccessful: "Contul tau a fost creat cu succes.",
    pleaseEnterAddress: "Vă rugăm să introduceți adresa dvs.",
    addressLine1: "Adresa Rândul 1",
    addressLine2: "Linia de adresă 2",
    choosePassword: "Alege parola",
    passwordMustBe6Characters: "Parola trebuie sa aibă cel puțin 6 caractere.",
    howDidYouHearLoystar: "Cum ai auzit de Loystar?",
    referralCode: "Codul de recomandare",
    byClickingTheButton:
      " Dând clic pe butonul de mai jos, sunteți de acord cu",
    termsAndSevice: "Termeni",
    wereCreatingAccount: "Vă creăm contul",
    proceed: "Continua",
    verificationCodeMustBe6: "Codul de verificare trebuie să aibă 6 cifre",
    pleaseEnter6DigitCode: "Vă rugăm să introduceți codul din 6 cifre",
    enterVerificationCode: "Introduceți codul de verificare",
    resendToken: "Retrimiteți Token",
    verify: "Verifica",
    transactions: "Tranzacții",
    todaySales: "Vânzările de astăzi",
    salesHistory: "Istoricul vânzărilor",
    supplyHistory: "Istoricul aprovizionării",
    new: "Nou",
    invoices: "Facturi",
    disbursements: "Plăți",
    offlineSales: "Vânzări offline",
    products: "Produse",
    customers: "Clienți",
    multiLevelLoyalty: "Loialitate pe mai multe niveluri",
    loyaltyPrograms: "Programe de loialitate",
    members: "Membri",
    appStore: "Magazin de aplicații",
    orderMenu: "Meniu Comandă",
    settings: "Setări",
    staffAndBranches: "Personal și sucursale",
    myAccount: "Contul meu",
    switchToSellMode: "Treceți la modul de vânzare",
    signOut: "Sign out",
    getFreeSubscription: "Obțineți abonament gratuit",
    onlyNumbersAllowed: "Numai numerele sunt permise",
    yourAccountMustBe10Digits:
      "numărul contului dvs. trebuie să fie un număr din 10 cifre",
    yourBvnMustBe11: "BVN-ul dvs. trebuie să fie un număr de 11 cifre",
    pleaseSelectBank: "Vă rugăm să selectați banca dvs.",
    selectYourBank: "Selectați banca dvs.",
    enterBankAccountNumber: "Introduceți numărul contului bancar",
    enterBvnNumber: "Introduceți BVN",
    connectBankAccount: "Conectați contul bancar",
    passwordResetSuccessfulAndSignOut:
      "Parola dvs. a fost resetată cu succes. Faceți clic pe butonul Deconectare de mai jos pentru a vă conecta din nou",
    enterCurrentPassword: "Introduceti parola curenta",
    pleaseEnterCurrentPassword: "Vă rugăm să introduceți parola curentă",
    phoneNumber: "Număr de telefon",
    sex: "Sex",
    dateOfBirth: "Data de nastere",
    state: "Stat",
    country: "Țară",
    loyaltyId: "ID de loialitate",
    createdAt: "Creat la",
    noOfTransactionsMade: "Numărul de tranzacții efectuate",
    yourDownloadWillStart: "Descărcarea dvs. va începe într-un moment",
    exportCustomers: "Exportați clienții",
    youhaveSuccessfullyToppedUp: "Ați completat cu succes unitățile SMS.",
    youNowHave: "Acum ai",
    smsUnits: "Unități SMS",
    enterNumberOfSmsUnits:
      "Introduceți numărul de unități SMS pe care intenționați să le cumpărați",
    pleaseEnterANumericValue: "Vă rugăm să introduceți o valoare numerică",
    pay: "A plati",
    accountEditedSuccessfully: "Contul dvs. a fost editat cu succes.",
    youAeCurrentlyOn: "În prezent ești activ",
    plan: "Plan",
    userData: "Datele utilizatorului",
    businessData: "DATE DE AFACERI",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Vă rugăm să selectați moneda dvs.",
    selectYourCurrency: "Selectați moneda dvs.",
    purchaseMoreSmsUnits:
      "Achiziționați mai multe unități SMS folosind formularul de mai jos",
    youHave: "Aveți",
    atLeast4SmsUnitsRrequired:
      "Pentru verificare sunt necesare cel puțin 4 unități SMS, vă rugăm să completați!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Vă rugăm să vă verificați contul bancar pentru a respecta cerințele „Cunoașteți-vă clientul” (KYC). Acest lucru vă va permite să colectați plăți prin USSD sau transfer instant, să primiți comenzi de la clienți și să procesați decontarea tranzacțiilor. Se aplică numai comercianților nigerieni. Vă rugăm să apăsați butonul de mai jos pentru a începe.",
    reConnectBankAccount: "Reconectați contul bancar",
    accountName: "Nume de cont",
    accountNumber: "Numar de cont",
    bankName: "Numele băncii",
    verified: "Verificat",
    accountDetails: "Detalii cont",
    kycBankAccount: "KYC",
    createTier: "Creați nivelul",
    fileUploadSuccessful: "fisier uploadat cu succes",
    fileUploadFailed: "încărcarea fișierului a eșuat",
    createLoyaltyProgram: "Creați un program de loialitate",
    createLoyalty: "Creează loialitate",
    name: "Nume",
    loyaltyArtwork: "Lucrări de loialitate",
    clickToUpload: "Faceți clic pentru a încărca",
    amountToPointsRatio: "Raport cantitate / puncte",
    points: "Puncte",
    recommendedAmountToPointRatio:
      "Recomandat: ₦ 1 la 1 punct. adică pentru fiecare 1 Naira cheltuit, clienții dvs. primesc 1 punct",
    pleaseTypeIn: "Vă rugăm să tastați",
    toDeleteLoyalty: "pentru a șterge această loialitate",
    deleteLoyalty: "Ștergeți loialitatea",
    toConfirm: "a confirma",
    edit: "Editați | ×",
    pointsAwardedSuccessfully: "Puncte acordate cu succes.",
    enterPointValueToAward:
      "Introduceți valoarea punctului pe care doriți să o acordați clienților",
    award: "Adjudecare",
    awardPointValuesToCustomer: "Valoarea punctului de acordare pentru client",
    enrollMembersToLoyalty: "Înscrieți membrii în loialitate",
    awardPoints: "Puncte de atribuire",
    enroll: "Înscrieți-vă",
    home: "Acasă",
    loyalty: "Loialitate",
    enrollCustomers: "Înscrieți clienții",
    selected: "Selectat",
    customer: "Client",
    loyaltyActivationSuccessful: "Activarea loialității este reușită.",
    loyaltyDeactivationSuccessful: "Dezactivarea loialității are succes.",
    viewTier: "Vizualizați nivelul",
    deactivate: "Dezactivați",
    activate: "Activati",
    actions: "Acțiuni",
    nameOfLoyalty: "Numele loialității",
    loyaltyStatus: "Stare loialitate Stare loialitate",
    numberOfTiers: "Numărul de niveluri",
    createdOn: "Creat in",
    createATier: "Creați un nivel",
    stopCreatingTierConfirmation: "Doriți să nu mai creați un nivel?",
    stopEditingTierConfirmation: "Doriți să nu mai editați acest nivel?",
    nameOfTier: "Numele nivelului",
    minimumSpendingTarget: "Țintă minimă de cheltuieli",
    maximumSpendingTarget: "Ținta maximă de cheltuieli",
    minimumSpendingTargetRequired:
      "obiectivul minim de cheltuieli este obligatoriu",
    maximumSpendingTargetRequired:
      "obiectivul maxim de cheltuieli este obligatoriu",
    rewardSponsor: "Sponsor de recompensă",
    pleaseChooseARewardSponsor: "Vă rugăm să alegeți un sponsor de recompensă",
    self: "De sine",
    partner: "Partener",
    rewardPartner: "Partener de recompensă",
    pleaseSelectRewardPartner:
      "Vă rugăm să selectați partenerul dvs. de recompensă",
    rewards: "Recompense",
    pleaseSelectAReward: "Vă rugăm să selectați o recompensă",
    instructionsOnRedeemingReward:
      "Instrucțiuni despre modul în care clientul ar trebui să răscumpere recompensa",
    pleaseFillInThisField: "Vă rugăm să completați acest câmp!",
    toDeleteThisTier: " pentru a șterge acest nivel",
    deleteTier: "Ștergeți nivelul",
    viewMembers: "Vizualizați membrii",
    membersEnrolled: "Membri înscriși",
    instruction: "Instrucțiuni",
    membersIn: "Membri în",
    availableTiersInLoyalty: "Niveluri disponibile în Programul de loialitate",
    tiers: "Niveluri",
    totalTier: "NIVEL TOTAL",
    availableLoyaltyProgramme: "Program de loialitate disponibil",
    totalLoyalties: "TOTAL LOIALITĂȚI",
    memberDetails: "Detalii despre membri",
    nameOfCustomer: "Numele clientului",
    address: "Abordare",
    allEnrolledMembers: "Toți membrii înscriși",
    thisIsToWishYouHappyBirthday:
      "Aceasta este pentru a vă dori o zi de naștere foarte fericită și o viață prosperă. Mulțumesc pentru tot ce ești pentru Loyster. Sărbători fericite!",
    inputAnOfferPlease: "Introduceți o ofertă, vă rog",
    pleaseSelectTheInsertPoint:
      "Vă rugăm să selectați punctul de inserare în mesaj și să faceți clic din nou",
    birthdayOfferAndMessage: "Ofertă de naștere și mesaj",
    birthdayOffer: "Ofertă de ziua de naștere",
    birthdayMessage: "Mesaj de ziua de naștere",
    noOfferFound: "Nu a fost găsită nicio ofertă",
    settingABirthdayOffer:
      "Setarea unei oferte de ziua de naștere permite clienților să primească această ofertă prin SMS de ziua lor",
    createOffer: "Creați ofertă",
    whatIsTheOffer: "Care este oferta?",
    editMessage: "Editați mesajul",
    insert: "Introduce",
    theNameOfCustomerInserted: "Numele clientului va fi inserat aici",
    theBirtdayOfferInserted: "Oferta de ziua de naștere va fi inserată aici",
    youSuccessfullyAddedNewBranch: "Ați adăugat cu succes o nouă sucursală!",
    addNewBranch: "Adăugați o filială nouă",
    addBranch: "Adăugați sucursală",
    additionalBranchWillIncur: "Ar urma să apară o filială suplimentară",
    perBranch: "pe ramură",
    ecommerceBranchCosts: "Costurile sucursalei de comerț electronic",
    pleaseEnterBranchName: "Vă rugăm să introduceți numele sucursalei",
    pleaseEnterBranchAddress1:
      "Vă rugăm să introduceți adresa filialei linia 1",
    enterBranchAddress1: "Introduceți linia de adresă 1 a sucursalei",
    enterBranchAddress2: "Introduceți linia de adresă 1 a sucursalei",
    pleaseEnterBranchAddress2: "Vă rugăm să introduceți adresa 2 a sucursalei",
    enterBranchName: "Introduceți numele sucursalei",
    successfullyAddedStaff: "Ați adăugat cu succes un nou personal!",
    addNewStaff: "Adăugați personal nou",
    addStaff: "Adăugați personal",
    additionalStaffWillIncur: "Personal suplimentar ar suporta",
    perStaff: "pe personal.",
    pleaseEnterStaffEmail: "Vă rugăm să introduceți e-mailul personalului",
    pleaseEnterStaffUsername:
      "Vă rugăm să introduceți numele de utilizator al personalului",
    pleaseEnterStaffPassword: "Vă rugăm să introduceți parola personalului",
    pleaseSelectStaffRole: "Vă rugăm să selectați rolul personalului",
    selectStaffRole: "Selectați rolul personalului",
    enterStaffEmail: "Introduceți e-mailul personalului",
    enterStaffUsername: "Introduceți numele de utilizator al personalului",
    enterStaffPassword: "Introduceți parola personalului",
    spacesNotAllowedInUsername: "spații nepermise în numele de utilizator",
    admin: "Administrator",
    pleaseSelectBusinessToAttachStaff:
      "Vă rugăm să selectați compania pentru a atașa personal",
    searchForBranchToAttachStaff: "Căutați sucursală pentru a atașa personal",
    username: "Nume de utilizator",
    role: "Rol",
    areYouSureToDeleteThis: "Sigur ștergeți acest lucru",
    branches: "Ramuri",
    upgradeYourPlan: "Actualizați-vă planul.",
    add: "ADĂUGA",
    addNew: "Adăuga nou",
    customerWithEmailAlreadyExists:
      "Clientul cu e-mail / număr de telefon există deja!",
    successfullyAddedNewCustomer: "Ați adăugat cu succes un client nou!",
    addCustomer: "Adăugați client",
    pleaseEnterCustomerFirstName:
      "Vă rugăm să introduceți prenumele clientului",
    pleaseEnterCustomerLastName:
      "Vă rugăm să introduceți numele de client al clientului",
    pleaseEnterCustomerPhoneNumber:
      "Vă rugăm să introduceți numărul de telefon al clientului",
    pleaseEnterCustomerEmail:
      "Vă rugăm să introduceți adresa de e-mail a clientului",
    pleaseEnterCustomerAddressLine:
      "Vă rugăm să introduceți linia de adresă a clientului",
    pleaseEnterCustomerOtherAddress:
      "Vă rugăm să introduceți cealaltă adresă a clientului",
    pleaseSelectCustomerGender: "Vă rugăm să selectați sexul clientului",
    gender: "Gen",
    male: "Masculin",
    female: "Femeie",
    bank: "bancă",
    selectBank: "Selectați Banca",
    stateOrRegionOrProvince: "Stat / Regiune / Provincie",
    customerNotes: "Note pentru clienți",
    sendSms: "Trimite SMS",
    editCustomer: "Editați clientul",
    redeemReward: "Răscumpărați recompensa",
    issueLoyaltyCard: "Emiteți cardul de loialitate",
    deleteCustomer: "Ștergeți clientul",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ați atribuit cu succes un ID de membru de loialitate",
    noMembershipIdAvailable:
      "Nu există ID-uri de membru disponibile. Vă rugăm să ne contactați pe hello@loystar.co",
    sendEmail: "Trimite email",
    membershipPoints: "Puncte de membru",
    customerDetails: "Detalii Client",
    close: "Închide",
    loyaltyBalance: "Soldul loialității",
    pointsBalance: "Soldul punctelor",
    starBalance: "Echilibrul stelelor",
    requiredPoints: "Puncte necesare",
    requiredStars: "Stele necesare",
    reddemCode: "Valorificați codul",
    toDeleteThisCustomer: "pentru a șterge acest client",
    customerHasBeenDeletedSuccessfully: "Clientul a fost șters cu succes!",
    customerWithPhoneAlreadyExists: "Clientul cu număr de telefon există deja",
    customerWasSuccessfullyEdited: "Clientul a fost editat cu succes",
    anErrorOccured: "A aparut o eroare",
    phoneNumberIncludeCountry: "Număr de telefon (Includeți codul țării)",
    yourFileQueuedForUpload:
      "Fișierul dvs. a fost pus în coadă pentru încărcare. Actualizați pagina după câteva secunde.",
    thereWasAnErrorPerformingOperation:
      "A apărut o eroare la efectuarea operației!",
    pleaseSelectFile: "Vă rugăm să selectați un fișier!",
    oopsOnlyCsvFilesAllowed:
      "Hopa! Sunt permise numai fișierele CSV. Vă rugăm să încărcați un fișier .csv.",
    fileShouldBeSmallerThan5Mb:
      "Fișierul trebuie să fie mai mic de 5 MB. Dacă aveți un fișier mai mare, vă rugăm să trimiteți un e-mail la support@loystar.co. Mulțumesc",
    customerFirstNameIsRequired:
      "prenumele clientului este obligatoriu pe rând",
    customerPhoneNumberIsRequired:
      "numărul de telefon al clientului este obligatoriu pe rând",
    importCustomers: "Importați clienți",
    upload: "Încărcați",
    clickIconToDownloadCsv:
      "Faceți clic pe această pictogramă pentru a descărca șablonul de fișier CSV.",
    getGoogleSheetVersion: "Obțineți versiunea de foaie Google",
    clickOrDragFileToUpload:
      "Faceți clic sau trageți fișierul în această zonă pentru încărcare",
    missingOrInvalidColumnHeader:
      "Antetul coloanei lipsește sau este nevalid. Vă rugăm să urmați formatul șablonului. Mulțumesc.",
    youHaveImported: "Ați importat",
    youSuccessfullyRedeemedReward: "V-ați răscumpărat cu succes recompensa!",
    sixDigitCode: "Cod din șase cifre",
    pleaseEnterCustomerRewardCode:
      "Vă rugăm să introduceți codul de recompensă al clientului",
    enterRewardCode:
      "Introduceți codul de recompensă. (Codul de recompensă este sensibil la majuscule și minuscule)",
    selectLoyaltyProgram: "Selectați programul de loialitate",
    stamps: "Timbre",
    smsUnitsLow: "Unități SMS reduse",
    pleaseTopUpSmsUnits: "Vă rugăm să completați unitățile SMS",
    smsSuccessfullySentForDelivery: "SMS-uri trimise cu succes pentru livrare!",
    sendSmsTo: "Trimite SMS către",
    allCustomers: "Toți clienții",
    unitsAvailable: "Unități disponibile",
    pleaseTypeInTheMessage: "Vă rugăm să tastați mesajul",
    message: "Mesaj",
    charactersRemaining: "caractere ramase",
    avoidUseOfSpecialCharacters:
      "Evitați utilizarea de caractere speciale și Emoji pentru a conserva unitățile SMS.",
    note: "Notă",
    errorFetchingCustomersMultilevelDetail:
      "Eroare la preluarea detaliilor clienților pe mai multe niveluri",
    search: "Căutare",
    reset: "Resetați",
    importCustomer: "Importați clientul",
    addNewCustomer: "Adăugați un client nou",
    sendSmsBroadcast: "Trimiteți difuzarea prin SMS",
    totalCustomers: "TOTAL CLIENTI",
    disbursementDetails: "Detalii despre debursare",
    paidBy: "Platit de",
    disbursed: "Plătit",
    bankAccountName: "Numele contului bancar",
    bankAccountNumber: "Numarul contului bancar",
    transferInitiated: "Transfer inițiat",
    transferCompleted: "Transfer finalizat",
    pleaseEnterAValid: "Vă rugăm să introduceți un cod valid",
    begin: "începe",
    end: "Sfârșit",
    date: "Data",
    paymentDate: "Data de plată",
    selectDisbursementDuration: "Selectați Durata plății",
    totalSettled: "Total decontat",
    totalUnsettled: "Total Necunoscut",
    toDeleteThisSale: "pentru a șterge această vânzare",
    draftSaleDeletedSuccessfully:
      "Proiectul de vânzare a fost șters cu succes!",
    deleteSale: "Ștergeți vânzarea",
    pleaseTypeInYourTotal: "Introduceți totalul pentru a confirma ștergerea",
    billDetails: "Detalii factură",
    printBill: "Print Bill",
    servedBy: "Servit de",
    total: "Total",
    createdDate: "data creata",
    createdTime: "Timp creat",
    status: "stare",
    loginSuccessful: "Autentificare reușită",
    pleaseWaitWhileWeConnectAccount:
      "Vă rugăm să așteptați până când vă conectăm contul",
    connectionFailedTryAgain:
      "Conexiune esuata. Vă rugăm să încercați din nou.",
    connectionSuccessful: "Conexiunea a reușit",
    viewDetails: "Vezi detalii",
    enable: "Permite",
    free: "Liber",
    cost: "Cost",
    visitWebsite: "Vizitati site-ul",
    pleaseUpgradeYourPlanToPro:
      "Vă rugăm să actualizați planul la Pro Plus pentru a activa această aplicație",
    connectYourBankAccount:
      "Conectați-vă contul bancar pentru a putea primi plăți.",
    disable: "Dezactivează",
    enableApp: "Activați aplicația",
    addNewProductToInvoice: "Adăugați un produs nou la factură",
    toDeleteThisInvoice: "pentru a șterge această factură",
    invoiceDeletedSuccessfully: "Factura a fost ștearsă cu succes!",
    deleteInvoice: "Ștergeți factura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ați adăugat cu succes un mesaj de plată cu factură!",
    pleaseEnterPaymentInstructions:
      "Vă rugăm să introduceți instrucțiuni de plată",
    invoiceId: "Numărul de indentificare al facturii",
    paidDate: "Data plății",
    reference: "Referinţă",
    productAdded: "Produs adăugat",
    productOutOfStock: "Produs epuizat. Vă rugăm să refaceți stocul.",
    totalInvoices: "FACTURI TOTALE",
    totalPaidInvoices: "TOTAL FACTURI PLĂTITE",
    totalUnpaidInvoices: "TOTAL FACTURI NEPLĂTITE",
    loyaltyProgramDeleted: "Programul de loialitate a fost șters",
    thisLoyaltyProgramDeletedSuccessfully:
      "Acest program de loialitate a fost șters cu succes",
    thisLoyaltyProgramEditedSuccessfully:
      "Acest program de loialitate a fost editat cu succes",
    loyaltyProgramAddedSuccessfully:
      "Programul de loialitate a fost adăugat cu succes",
    loyaltyProgramEdited: "Program de loialitate editat",
    loyaltyProgramAdded: "Program de loialitate adăugat",
    loyaltyDetails: "Detalii de loialitate",
    doYouWantToCloseDialog: "Doriți să închideți acest dialog?",
    pleaseEnterLoyaltyName: "Vă rugăm să introduceți numele loialității dvs.",
    programType: "Tipul programului",
    pleaseSelectProgramType: "Vă rugăm să selectați un tip de program",
    simplePoints: "Puncte simple",
    stampsProgram: "Programul de timbre",
    threshold: "Prag",
    pleaseEnterLoyaltyThreshold: "Vă rugăm să introduceți pragul de loialitate",
    reward: "Răsplată",
    pleaseEnterLoyaltyReward: "Vă rugăm să introduceți recompensa loialității",
    totalUserPoints: "Total puncte utilizator",
    totalUserStamps: "Total timbre de utilizator",
    spendingTarget: "Țintă de cheltuieli",
    spendingTargetHint1:
      "Obiectivul cheltuielilor este suma pe care trebuie să o cheltuiască un client pentru a câștiga recompensa. 1 valoare valutară = 1 punct.",
    spendingTargetHint2:
      "Ținta timbrelor este numărul de timbre pe care un client trebuie să le colecteze pentru a câștiga recompensa. de exemplu. 5",
    addNewLoyaltyProgram: "Adăugați un nou program de loialitate",
    addLoyaltyProgram: "Adăugați un program de loialitate",
    loyaltyProgramType: "Tipul programului de loialitate",
    pleaseSelectLoyaltyProgramType:
      "Vă rugăm să selectați tipul de program de loialitate",
    nameOfProgram: "Numele programului",
    pleaseEnterProgramName: "Vă rugăm să introduceți numele programului",
    whatIsTheReward: "Care este recompensa?",
    egNextPurchaseIsFree: "De exemplu. Următoarea achiziție este gratuită",
    customerName: "Numele clientului",
    guestCustomer: "Client invitat",
    orderConfirmedSuccessfully: "Comanda confirmată cu succes",
    orderCancelledSuccessfully: "Comanda a fost anulată cu succes",
    confirmOrder: "Confirmă comanda",
    cancelOrder: "Anulați comanda",
    allOrders: "Toate comenzile",
    totalOrders: "TOTAL COMENZI",
    doYouWantToAcceptOrder: "Doriți să acceptați această comandă?",
    doYouWantToCancelOrder: "Doriți să anulați această comandă?",
    orderDetails: "Comanda Detalii",
    orderCreatedAt: "Comandă creată la",
    supplier: "Furnizor",
    productName: "numele produsului",
    quantity: "Cantitate",
    unitPrice: "Preț unitar",
    receivedBy: "Primit de",
    reportFrom: "Raport de la",
    totalSupplies: "Total consumabile",
    allRightsReserved: "Toate drepturile rezervate",
    toDeleteThisTransaction: "pentru a șterge această tranzacție",
    transactionDeletedSuccessfully:
      "Tranzacția a fost ștearsă cu succes. Stocul a fost returnat la inventar.",
    deleteTransaction: "Ștergeți tranzacția",
    transactionDetails: "Detalii tranzacție",
    printReceipt: "Tipărire chitanță",
    channel: "Canal",
    discount: "Reducere",
    profit: "Profit",
    discountedSales: "Vânzări reduse",
    errorFetchingRecord: "Eroare la preluarea înregistrării",
    exportTransactions: "Tranzacții de export",
    errorFetchingSalesRecord:
      "Eroare la preluarea înregistrării de vânzări pentru export.",
    totalSellingPrice: "Prețul total de vânzare",
    totalCostPrice: "Prețul de cost total",
    appliedDiscount: "Reducere aplicată",
    noOfItems: "Număr de articole",
    sales: "Vânzări",
    export: "Export",
    totalProfit: "Profit total",
    totalBalanceInPeriod: "Sold total în perioadă",
    allTimeSales: "Vânzări permanente",
    records: "Înregistrări",
    todaysSales: "Vânzările de astăzi",
    transaction: "tranzacţie",
    youHaveExceededTotalNumberOfProducts:
      "Ați depășit numărul total de produse permise în planul dvs. Actualizați-vă planul pentru a vă bucura de o limită mai mare.",
    youNeedToHaveLoyaltyProgram:
      "Trebuie să aveți un program de loialitate pentru a utiliza această funcție!",
    price: "Preț",
    category: "Categorie",
    stockTracking: "Urmărirea stocului",
    stockCount: "Număr stoc",
    taxed: "Impozitat",
    originalPrice: "Pret original",
    costPrice: "Pretul",
    unit: "Unitate",
    productImage: "imaginea produsului",
    taxRate: "Cota de impozitare",
    taxType: "Tipul impozitului",
    trackInventory: "Urmăriți inventarul",
    variants: "Variante",
    hasVariants: "Are variante",
    importProduct: "Importați produsul",
    exportProducts: "Exportați produse",
    addNewProduct: "Adăugați un produs nou",
    viewCategory: "Vizualizați categoria",
    viewSuppliers: "Vizualizați furnizorii",
    receiveInventory: "Primiți inventar",
    printAllProductsTag: "Imprimați eticheta Toate produsele",
    deleteAll: "Sterge tot",
    totalProducts: "TOTAL PRODUSE",
    youveSuccessfullyAddedANewCategory:
      "Ați adăugat cu succes o nouă categorie",
    addNewCategory: "Adăugați o categorie nouă",
    addCategory: "Adăugați o categorie",
    categoryName: "Numele categoriei",
    pleaseEnterCategoryName: "Vă rugăm să introduceți numele categoriei",
    stampsTarget: "Ținte de timbre",
    sendInventory: "Trimite inventar",
    productDetails: "Detalii produs",
    youveSuccessfullyEditedThisCategory:
      "Ați editat cu succes această categorie",
    update: "Actualizați",
    categoryList: "Lista categoriilor",
    categories: "Categorii",
    enterQuantityToUpdate: "Introduceți o cantitate de actualizat",
    inventorySentSuccessfully: "Inventarul a fost trimis cu succes!",
    updateInventory: "Actualizați inventarul",
    currentQuantity: "Cantitatea curentă",
    pleaseEnterQuantityToAdd:
      "Vă rugăm să introduceți cantitatea pe care doriți să o adăugați",
    pleaseSelectABranch: "Vă rugăm să selectați o sucursală",
    searchForBranch: "Căutați sucursală",
    youCantSendMoreThanStock: "Nu puteți trimite mai mult decât aveți în stoc",
    send: "Trimite",
    pleaseEnterQuantityToSend:
      "Vă rugăm să introduceți cantitatea pe care doriți să o trimiteți",
    productNameIsRequiredOnRow: "numele produsului este obligatoriu pe rând",
    productCategoryIsRequiredOnRow:
      "categoria de produse este obligatorie pe rând",
    productPriceIsRequiredOnRow: "prețul produsului este obligatoriu pe rând",
    productUnitIsRequiredOnRow: "unitatea de produs este necesară pe rând",
    productQuantityIsRequiredOnRow:
      "cantitatea de produs este necesară pe rând",
    productVariantsRequireTracking: "variantele de produse necesită urmărire!",
    pleaseAddVariantClickButton:
      "Vă rugăm să adăugați o variantă făcând clic pe butonul Adăugați!",
    totalVariantsMoreThanSelectedQuantity:
      "Variantele totale sunt mai mult decât cantitatea de produs selectată, reducem cantitatea variantă",
    productEditedSuccessfully: "Produsul a fost editat cu succes!",
    fileTooLargeLessThan4Mb:
      "Dimensiunea fișierului este prea mare! Dimensiunea fișierului trebuie să fie mai mică de 4 MB.",
    suchVariantAlreadyExist: "O astfel de variantă există deja",
    addVariants: "Adăugați variante",
    editProduct: "Editați produsul",
    pleaseEnterProductName: "Vă rugăm să introduceți numele produsului",
    pleaseEnterProductPrice: "Vă rugăm să introduceți prețul produsului",
    pleaseEnterProductOriginalPrice:
      "Vă rugăm să introduceți prețul original al produsului",
    productDescription: "Descriere produs",
    selectProductCategory: "Selectați categoria de produse",
    pleaseSelectProductCategory: "Vă rugăm să selectați categoria de produse",
    productCostPrice: "Prețul costului produsului",
    productSellingPrice: "Preț de vânzare a produsului",
    productOriginalPrice: "Preț original produs",
    maxFileSizeAllowedIs4Mb: "Max. Dimensiunea fișierului permisă este de 4 MB",
    productsWithPicturesArePublished:
      "Produsele cu imagini sunt publicate pe Discover pentru a primi comenzi",
    shouldThisProductBeTracked: "Ar trebui urmărit acest produs?",
    pleaseSelectStockUnit: "Vă rugăm să selectați unitatea de stoc",
    stockUnit: "Unitate stoc",
    bag: "SAC",
    bottle: "STICLA",
    bunch: "BUCHET",
    can: "POATE SA",
    carton: "CUTIE DE CARTON",
    crate: "LADĂ",
    cup: "CEAȘCĂ",
    dozen: "DUZINĂ",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITRU",
    pack: "AMBALAJ",
    pair: "PERECHE",
    pieces: "PIESE",
    plate: "FARFURIE",
    tonne: "TONNE (MT)",
    uNIT: "UNITATE",
    yard: "CURTE",
    pleaseEnterProductQuantity: "Vă rugăm să introduceți cantitatea produsului",
    productQuantity: "Cantitatea produsului",
    isThisProductTaxed: "Acest produs este taxat?",
    selectTaxType: "Selectați Tipul de impozitare",
    pleaseSelectTaxType: "Vă rugăm să selectați Tipul de impozitare",
    progressive: "Progresist",
    proportional: "Proporţional",
    pleaseEnterProductTaxRate:
      "Vă rugăm să introduceți rata impozitului pe produs",
    doesProductHaveVariants: "Acest produs are variante?",
    type: "Tip",
    value: "Valoare",
    pleaseEnterVariantType: "Vă rugăm să introduceți tipul variantei",
    pleaseEnterVariantValue: "Vă rugăm să introduceți valoarea variantei",
    pleaseEnterVariantPrice: "Vă rugăm să introduceți prețul variantei",
    pleaseEnterVariantQuantity: "Vă rugăm să introduceți cantitatea variantei",
    productDeletedSuccessfully: "Produsul a fost șters cu succes!",
    categoryDeletedSuccessfully: "Categoria a fost ștearsă cu succes!",
    toDeleteThisProduct: "pentru a șterge acest produs",
    invalidProductQuantity: "Cantitate de produs nevalidă",
    quantityAddedIsOverStock:
      "Cantitatea pe care o adăugați depășește ceea ce aveți în stoc.",
    itemInventoryNotTracked: "Inventarul articolelor nu este urmărit",
    addBulkQuantity: "Adăugați o cantitate în vrac",
    enterBulkQuantity: "Introduceți cantitatea în vrac",
    pleaseEnterBulkQuantity: "Vă rugăm să introduceți cantitatea în vrac",
    youveSuccessfullyAddedANewProduct: "Ați adăugat cu succes un produs nou!",
    pleaseEnterProductSellingPrice:
      "Vă rugăm să introduceți prețul de vânzare al produsului",
    doYouWantToTrackProductStock: "Doriți să urmăriți stocul de produse?",
    sellingPrice: "Prețul de vânzare",
    setProductExpiryReminder: "Setați memento-ul expirării produsului",
    productExpiryDate: "Data de expirare a produsului",
    startRemindingFrom: "Începeți să reamintiți de la",
    productSuppliesAddedSuccessfully:
      "Ați adăugat aprovizionarea cu produse cu succes.",
    addProductSupplies: "Adăugați consumabile pentru produse",
    pleaseSelectSupplier: "Vă rugăm să selectați furnizorul",
    nameOfProduct: "Numele produsului",
    pleaseSelectProduct: "Vă rugăm să selectați produsul",
    productVariant: "Varianta produsului",
    pleaseSelectAVariant: "Vă rugăm să selectați o variantă",
    pleaseEnterUnitPrice: "Vă rugăm să introduceți prețul unitar",
    businessBranch: "Sucursala de afaceri",
    pleaseSelectBranch: "Vă rugăm să selectați sucursala",
    youveSuccessfullyAddedANewSupplier: "Ați adăugat cu succes un nou furnizor",
    addSupplier: "Adăugați furnizor",
    pleaseEnterSupplierEmail:
      "Vă rugăm să introduceți adresa de e-mail a furnizorului",
    pleaseAddADescription: "Vă rugăm să adăugați o descriere",
    anErrorOccuredProductsDeleted:
      "A apărut o eroare în timpul efectuării operației. Vă rugăm să luați notă, puține produse ar fi putut fi șterse în acest proces",
    bulkDelete: "Ștergere în bloc",
    youAreAboutToDelete: "Ești pe cale să ștergi",
    product: "Produs",
    isDueToRedeem: "este de răscumpărat",
    aReward: "o recompensa",
    pleaseSelectCustomerToReeemReward:
      "Vă rugăm să selectați un client pentru a valorifica recompensa.",
    youHaveNoLoyaltyProgramRunning:
      "Nu aveți niciun program de loialitate activ rulat",
    customerHhasNoPointsInLoyaltyProgram:
      "Clientul nu are puncte în acest program de loialitate",
    proceedWithPayment: "Continuați plata?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ești pe cale să plătești pentru tranzacție folosind puncte.",
    customerHas: "Clientul are",
    worth: "in valoare de",
    andIsNotSufficientToPayForTransaction:
      "și nu este suficient pentru a plăti această tranzacție. Ar dori să adauge soldul folosind o altă metodă de plată?",
    addCustomerLoyalty: "Adăugați loialitatea clienților",
    pleaseAddCustomerFirst:
      "Vă rugăm să adăugați sau să selectați mai întâi un client.",
    pleaseWaitWhileWeGetReady: "Vă rugăm să așteptați, în timp ce ne pregătim",
    lowStock: "Stoc redus",
    pleaseEnterAmountTendered: "Vă rugăm să introduceți suma licitată",
    areYouSureToBookSaleOffline:
      "Sigur doriți să rezervați această vânzare offline?",
    saleWouldBeBookedAutomatically:
      "Vânzarea va fi rezervată automat atunci când vă porniți internetul",
    offlineSalesBookingCancelled: "Rezervare de vânzări offline anulată",
    covid19Message:
      "COVID19: Spălați-vă mâinile cu săpun sau igienizați pentru a opri răspândirea. Rămâneți în siguranță întotdeauna",
    saleSuccessfullyRecorded: "Vânzare înregistrată cu succes!",
    sendReceiptToEmail: "Trimiteți chitanța prin e-mail",
    sendThankYouSms: "Trimite SMS de mulțumire",
    sendShippingDetails: "Trimite detalii de expediere",
    addLoyalty: "Adăugați loialitate",
    searchCustomerNameOrNumber: "Căutați numele sau numărul clientului",
    clickTheSearchCustomerBox:
      "Faceți clic pe Căutare casetă client și card de scanare",
    enterProductPrice: "Introduceți prețul produsului",
    enterPriceFor: "Introduceți prețul pentru",
    searchForProduct: "Căutați produs",
    all: "Toate",
    backToDashboard: "Înapoi la tabloul de bord",
    viewDraftSales: "Vizualizați proiectul de vânzări",
    variantSelected: "varianta selectată",
    variant: "variantă",
    thePreviousVariantSelectionNotAvailable:
      "Selecția anterioară a variantei nu este disponibilă pentru noua variantă selectată în funcție de preț, vă rugăm să modificați selecția.",
    pleaseSupplyPositiveQuantityNumber:
      "Vă rugăm să furnizați un număr de cantitate pozitivă",
    lowStockFor: "Stoc redus pentru",
    clearVariants: "Variante clare",
    pleaseEnterQuantity: "Vă rugăm să introduceți Cantitate",
    picture: "Imagine",
    redemptionToken: "Jeton de răscumpărare",
    token: "Jeton",
    totalSpent: "Total cheltuit",
    confirmPayment: "Confirma plata",
    hasPaymentBeenMade: "Plata a fost procesată cu succes?",
    enterTransactionReference:
      "Introduceți referința tranzacției cu care ați plătit",
    pleaseSelectACustomer: "Vă rugăm să selectați un client!",
    areYouSureToApplyDiscount: "Sigur doriți să aplicați reducere?",
    addYourBankAccountToEnableUssd:
      "Adăugați contul dvs. bancar pentru a activa transferul instantaneu USSD prin uPay",
    totalAmountToPay: "Suma totală de plătit",
    doYouWantToCancelTransaction: "Doriți să anulați această tranzacție?",
    savePrintBill: "Salvați / tipăriți factura",
    enterAmountCollectedForCustomer: "Introduceți suma colectată pentru client",
    recordSale: "Vânzare record",
    checkOutWith: "Verificați cu",
    instantTransfer: "Transfer instant",
    dialTheUSSDCode: "Formați codul USSD",
    pleaseSelectABank: "Vă rugăm să selectați o bancă",
    payWithUSSD: "Plătiți cu USSD",
    sendBillTo: " - Trimiteți Bill la",
    waitingForUSSDTransfer: "Se așteaptă transferul USSD",
    percent: "La sută",
    applyDiscount: "Aplicați reducere",
    thisBillHasBeenSaved: "Acest proiect de lege a fost salvat",
    saveDraft: "Salveaza schita",
    pleaseTypeANameToIdentifyCustomer:
      "Vă rugăm să introduceți un nume pentru a identifica clientul",
    paymentDetails: "Detaliile platii",
    basePrice: "Pret de baza",
    staff: "Personal",
    subTotal: "SubTotal",
    durationMonths: "Durata (luni)",
    selectADuration: "Selectați o Durată",
    oneMonth: "1 lună",
    twoMonths: "2 luni",
    threeMonths: "3 luni",
    sixMonths: "6 luni",
    twelveMonths: "12 luni",
    eighteenMonths: "18 luni",
    twentyFourMonths: "24 de luni",
    twoMonthsFree: "(2 luni gratuit)",
    threeMonthsFree: "(3 luni gratuit)",
    fiveMonthsFree: "(5 luni gratuit)",
    yourAccountHasBeen: "Contul dvs. a fost",
    renewed: "reînnoit",
    upgraded: "actualizat",
    successfully: "cu succes",
    yourSubscription: "Abonamentul dvs.",
    youAreCurrentlyEnrolledOnA: "În prezent sunteți înscris pe un",
    pleaseChooseAPaymentOption: "Vă rugăm să alegeți o opțiune de plată",
    planRenewal: "Reînnoirea planului",
    planUpgrade: "Planificați upgrade-ul",
    pleaseSelectDurationToPayFor:
      "Vă rugăm să selectați durata pe care intenționați să o plătiți",
    staffAccounts: "Conturi de personal",
    ecommerce: "Comerț electronic",
    pleaseSelectAPlan: "Vă rugăm să selectați un plan",
    includeAddons: "Includeți programe de completare",
    viewTransactions: "Vizualizați tranzacțiile",
    customerHasNoCompletedTansactions:
      "Clientul nu are încă tranzacții finalizate",
    branch: "Ramură",
    enterNumberOfEcommerceBranches:
      "Introduceți numărul de ramuri de comerț electronic",
    enterNumberOfEcommerceBranchesToPay:
      "Introduceți numărul de sucursale de comerț electronic pentru care intenționați să plătiți",
    ecommerceIntegration: "Integrarea comerțului electronic",
    enterNumberOfBranches: "Introduceți numărul de sucursale",
    enterNumberOfAdditionalBranchesToPay:
      "Introduceți numărul de sucursale suplimentare pentru care intenționați să plătiți",
    enterNumberOfStaffs: "Introduceți numărul de personal",
    enterNumberOfStaffsToPayFor:
      "Introduceți numărul personalului pentru care intenționați să plătiți",
    discountApplies: "Se aplică reducerea",
    starsOnThe: "stele pe",
    offer: "oferi",
    get: "obține",
    moreStarsToRedeem: "mai multe stele de răscumpărat",
    taxVat: "Taxă (TVA)",
    callCashierToCompletePayment: "Sunați la casierie pentru a finaliza plata",
    receipt: "Chitanță",
    dear: "dragă",
    thankYouForYourOrderFindGoods:
      "Vă mulțumim pentru comanda dumneavoastră. Vă rugăm să găsiți următoarele bunuri furnizate, conform acordului.",
    shippingNote: "Notă de expediere",
    enterShippingNote: "Introduceți nota de expediere",
    shippingAddress: "Adresa de transport",
    enterShippingAddress: "Introduceți adresa de expediere",
    wellDoneYouAreDueToRedeem: "Bine făcut! Trebuie să răscumpărați",
    toGetYourRewardNextVisit:
      "pentru a obține recompensa la următoarea vizită. Mulțumesc",
    pointsOnThe: "Puncte pe",
    morePointsToRedeem: "mai multe puncte de valorificat",
    showCode: "Afișați codul",
    toGetYourRewardOnNextVisit:
      "pentru a obține recompensa la următoarea vizită. Mulțumesc",
    earn: "Câştiga",
    delivaryNote: "Notă livrare",
    draftSales: "Proiect de vânzări",
    startDate: "Data de început",
    endDate: "Data de încheiere",
    orders: "Comenzi",
    checkout: "verifică",
    noProductItem: "Fără produs",
    selectProductImage: "Selectați Imagine produs",
    selectCountry: "Selecteaza tara",
    selectRegion: "Selectați stat / regiune",
    printProductTag: "Imprimați eticheta produsului",
    transactionReference: "Referință tranzacție",
    Cashier: "Casier",
    Manager: "Administrator",
    Owner: "Proprietar",
    Admin: "Administrator",
    addPartners: "Adăugați parteneri",
    addNewLoyaltyPartner: "Adăugați un nou partener de loialitate",
    pleaseEnterCompanyName: "Vă rugăm să introduceți numele companiei",
    companyName: "Numele Companiei",
    pleaseEnterCompanyRepName:
      "Vă rugăm să introduceți numele reprezentantului companiei",
    companyRepName: "Numele reprezentantului companiei",
    pleaseEnterCompanyRepEmail:
      "Vă rugăm să introduceți adresa de e-mail a reprezentantului companiei",
    companyRepEmail: "E-mailul reprezentantului companiei",
    pleaseEnterCompanyRepPhone:
      "Vă rugăm să introduceți numărul de telefon al reprezentantului companiei",
    companyRepPhone: "Numărul de telefon al reprezentantului companiei",
    addReward: "Adaugă recompensă",
    pleaseEnterRewardName: "Vă rugăm să introduceți numele recompensei",
    rewardName: "Numele recompensei",
    rewardQuantity: "Cantitatea de recompensă",
    rewardDescription: "Descrierea recompensei",
    rewardType: "Tip de recompensă",
    pleaseEnterRewardType: "Vă rugăm să introduceți tipul de recompensă",
    pleaseEnterRewardQuantity:
      "Vă rugăm să introduceți cantitatea de recompensă",
    pleaseEnterRewardDescription:
      "Vă rugăm să introduceți descrierea recompensei",
    fineDining: "Dining rafinat",
    luxuryFashion: "Moda de lux",
    hotels: "Hoteluri",
    travel: "Voiaj",
    foodAndBeverage: "Mancare si bautura",
    fashion: "Modă",
    health: "Sănătate",
    furniture: "Mobila",
    entertainment: "Divertisment",
    automobile: "Auto",
    education: "Educaţie",
    beautyAndSpa: "Frumusețe și spa",
    staycation: "Staycation",
    events: "Evenimente",
    trips: "Excursii",
    oilAndGas: "Ulei si gaz",
    laundry: "Spălătorie",
    partnerCategory: "Categoria de partener",
    freeItem: "Articol gratuit",
  },
  "Scots Gaelic": {
    cashier: "airgeadair",
    manager: "manaidsear",
    owner: "sealbhadair",
    online: "air-loidhne",
    offline: "far-loidhne",
    changePassword: "Atharraich facal-faire",
    currentPasswordMessage: "Cuir a-steach am facal-faire gnàthach agad",
    passwordMessage: "Cuir a-steach am facal-faire agad",
    currentPassword: "Facal-faire gnàthaichte",
    password: "facal-faire",
    confirmPasswordMessage: "Feuch an dearbhadh thu am facal-faire agad!",
    confirmPassword: "Dearbh am facal-faire",
    sendViaEmail: "Cuir tro phost-d",
    sendViaWhatsapp: "Cuir tro WhatsApp",
    downloadPdf: "Luchdaich sìos PDF",
    paid: "pàighte",
    unpaid: "gun phàigheadh",
    partial: "pàirt",
    closeInvoice: "A bheil thu airson an fhàirdeal a dhùnadh?",
    closeInvoiceConfirmation:
      "Is dòcha nach tèid fàirdeal a shàbhaladh. A bheil thu airson dùnadh?",
    yes: "tha",
    no: "Chan eil",
    invoice: "Fàirdeal",
    wasDeducted: "chaidh a thoirt a-mach",
    for: "airson",
    item: "Nì",
    addProduct: "Cuir toradh ris",
    paymentReference: "Fiosrachadh Pàighidh",
    amountPaid: "Meud air a phàigheadh",
    discountAmount: "Suim lasachaidh",
    amountDue: "Meud ri phàigheadh",
    amount: "Meud",
    dueDate: "An latha ri thighinn",
    paymentType: "Seòrsa Pàighidh",
    card: "Cairt",
    cash: "Airgead",
    bankTransfer: "Gluasad banca",
    paymentMessage: "Teachdaireachd pàighidh",
    description: "Tuairisgeul",
    sendReceipt: "Cuir cuidhteas",
    delete: "Cuir às",
    save: "Sàbhail",
    resend: "Ath-chuir",
    saveAndSend: "Sàbhail",
    invoiceSaved: "Fàirdeal air a shàbhaladh!",
    selectPaymentMethod: "Tagh Modh Pàighidh!",
    selectCustomer: "Tagh neach-ceannach!",
    cartEmptyError:
      "Chan urrainn dha Liosta Cart a bhith falamh, dùin an fhàirdeal agus cuir Nì ri cairt!",
    subscriptionExpired:
      "Tha an fho-sgrìobhadh agad air tighinn gu crìch agus tha do chunntas a-nis cuibhrichte. Cliog air a ’phutan gu h-ìosal gus do chunntas ùrachadh",
    renew: "Ath-nuadhachadh",
    holdOn: "Cùm do ghrèim",
    customerBank: "Banca Luchd-ceannach",
    cancel: "Sguir dheth",
    selectACustomer: "Tagh Neach-ceannach",
    invoiceSuccessfulPdfError:
      "Chaidh fàirdeal a chruthachadh gu soirbheachail ach tha PDF Generation a ’toirt nas fhaide na an àbhaist. Feuch an toir thu sùil air ais a dh ’aithghearr.",
    downloadingInvoice: "A ’luchdachadh sìos fàirdeal",
    downloadingReceipt: "A ’luchdachadh sìos cuidhteas",
    whatsappReceiptError:
      "Thachair mearachd a ’cur a’ chuidhteas tro WhatsApp, Feuch ris a-rithist.",
    receiptToWhatsapp: "Cuidhteas air a chuir air adhart gu WhatsApp",
    thankYouForPatronage: "Tapadh leibh airson ur taic",
    hereIsYourReceipt: "Seo an cuidhteas pàighidh agad",
    errorSendingEmailReceipt:
      "Thachair mearachd a ’cur a’ chuidhteas tro phost-d, feuch ris a-rithist no cuir fios gu taic",
    receiptSentToEmail: "Chaidh cuidhteas a chuir gu post-d teachdaiche",
    invoiceSentToEmail: "Chaidh fàirdeal a chuir gu post-d teachdaiche",
    invoiceSuccessWhatsappError:
      "Chaidh fàirdeal a chruthachadh gu soirbheachail ach thachair mearachd le bhith a ’cur gu WhatsApp. Feuch a-rithist air liosta nan fàirdealan",
    invoiceSuccessfulEmailError:
      "Chaidh fàirdeal a chruthachadh gu soirbheachail ach thachair mearachd nuair a chaidh a chuir air post-d. Feuch ris a-rithist bhon liosta fàirdeal",
    invoiceSentToWhatsapp: "Chaidh fàirdeal a chuir air adhart gu WhatsApp",
    hello: "Halò",
    pleaseDownloadInvoice: "Feuch an luchdaich thu sìos fàirdeal",
    pleaseDownloadReceipt: "Feuch an luchdaich thu sìos cuidhteas",
    from: "bho",
    email: "Post-d",
    upgrade: "Ùrachadh",
    youAreOnFreePlan: "Tha thu air Plana an-asgaidh.",
    clickOn: "Cliog air",
    yourPlanInFewSteps:
      " an fho-sgrìobhadh agad ann am beagan cheumannan sgiobalta.",
    to: "gu",
    yourSubscriptionHasExpired:
      "Tha an fho-sgrìobhadh agad air tighinn gu crìch agus tha do chunntas a-nis cuibhrichte.",
    days: "làithean",
    yourSubscriptionExpiresIn:
      "Thig an fho-sgrìobhadh Loystar agad gu crìch a-steach",
    createAcount: "Cruthaich cunntas",
    signIn: "Soidhnig a-steach",
    continue: "Lean air adhart",
    enterOtp: "Cuir a-steach OTP PIN",
    enterValidOtp: "Cuir a-steach PIN dligheach",
    pin: "PIN",
    tokenSentToMail: "Chaidh comharra a chuir chun phost-d agad",
    passwordResetSuccessful: "Bha ath-shuidheachadh facal-faire soirbheachail",
    somethingWentWrong: "Chaidh rudeigin ceàrr!",
    resetPassword: "Facal-faire ath-shuidheachadh",
    iHaveResetCode: "Tha còd ath-shuidheachadh facal-faire agam",
    pleaseEnterEmail: "Cuir a-steach am post-d agad",
    aTokenWillBeSentToEmail: "Chuireadh comharra chun phost-d agad",
    enterEmail: "Cuir a-steach am post-d agad",
    sendinYourToken: "A ’cur do shoidhne ...",
    makeSureItMatchesPassword:
      "Dèan cinnteach gu bheil e a ’maidseadh am facal-faire ùr agad",
    pleaseChooseNewPassword: "Tagh facal-faire ùr",
    chooseNewPassword: "Tagh facal-faire ùr",
    enterNewPassword: "Cuir a-steach am facal-faire ùr agad gus dearbhadh",
    enterTokenSent: "Cuir a-steach an comharra a chaidh a chuir sa phost agad",
    resetToken: "Ath-shuidheachadh Token",
    resettingPassword: "Ag ath-shuidheachadh do fhacal-faire ...",
    signUp: "Cuir d'ainm ris",
    adminSignInWithEmail:
      " Bidh rianachd a ’clàradh a-steach le post-d fhad‘ s a bhios luchd-obrach a ’clàradh a-steach le ainm-cleachdaidh.",
    pleaseEnterEmailOrUsername:
      "Cuir a-steach am post-d no an t-ainm-cleachdaidh agad",
    emailOrUsername: "Post-d no ainm-cleachdaidh",
    pleaseEnterPassword: "Cuir a-steach facal-faire",
    createAnAccount: "Cruthaich cunntas",
    forgotPassword: "An do dhìochuimhnich thu facal-faire?",
    enterPhoneNumber: "Cuir a-steach àireamh fòn",
    personalData: "Dàta Pearsanta",
    validateConfirmationCOde: "Còd dearbhaidh dligheach",
    pleaseEnterFirstName: "Cuir a-steach do chiad ainm",
    pleaseEnterPhoneNumber: "Cuir a-steach an àireamh fòn agad",
    pleaseEnterLastName: "Cuir a-steach d ’ainm mu dheireadh",
    pleaseEnterBusinessName: "Cuir a-steach ainm do ghnìomhachas",
    firstName: "A 'chiad ainm",
    lastName: "Ainm deireannach",
    businessName: "Ainm Gnothachais",
    previous: "Roimhe seo",
    next: "An ath rud",
    pleaseSelectBusinessCategory: "Tagh an roinn gnìomhachais agad",
    pleaseEnterValidEmail: "Cuir a-steach post-d dligheach",
    pleaseEnterPostCode: "Cuir a-steach còd a ’phuist",
    postCode: "Còd-puist",
    phoneNumberInUse: "Tha an àireamh fòn seo ga chleachdadh mu thràth!",
    emailInUse: "Tha am post-d seo ga chleachdadh mu thràth!",
    foodAndDrinks: "Biadh is deochan",
    salonAndBeauty: "Salon agus Bòidhchead",
    fashionAndAccessories: "Fasan agus Accessories",
    gymAndFitness: "Gym agus Fallaineachd",
    travelAndHotel: "Siubhal is Taigh-òsta",
    homeAndGifts: "Dachaigh agus Tiodhlacan",
    washingAndCleaning: "A ’nighe agus a’ glanadh",
    gadgetsAndElectronics: "Gadgets agus Electronics",
    groceries: "Grosairean",
    others: "Feadhainn eile",
    submit: "Cuir a-steach",
    accountCreatedSuccessful:
      "Chaidh do chunntas a chruthachadh gu soirbheachail.",
    pleaseEnterAddress: "Cuir a-steach do sheòladh",
    addressLine1: "Loidhne seòlaidh 1",
    addressLine2: "Loidhne seòlaidh 2",
    choosePassword: "Tagh Facal-faire",
    passwordMustBe6Characters:
      "Feumaidh facal-faire a bhith co-dhiù 6 caractaran.",
    howDidYouHearLoystar: "Ciamar a chuala tu mu Loystar?",
    referralCode: "Còd tar-chuir",
    byClickingTheButton:
      " Le bhith a ’briogadh air a’ phutan gu h-ìosal, tha thu ag aontachadh ris an",
    termsAndSevice: "Cumhachan",
    wereCreatingAccount: "Tha sinn a ’cruthachadh do chunntas",
    proceed: "Lean air adhart",
    verificationCodeMustBe6: "Feumaidh Còd Dearbhaidh a bhith 6 àireamhan",
    pleaseEnter6DigitCode: "Cuir a-steach còd 6 digit",
    enterVerificationCode: "Cuir a-steach an còd dearbhaidh",
    resendToken: "Resend Token",
    verify: "Dearbhaich",
    transactions: "Gnìomhan",
    todaySales: "Reic an latha an-diugh",
    salesHistory: "Eachdraidh reic",
    supplyHistory: "Eachdraidh solair",
    new: "Ùr",
    invoices: "Fàirdealan",
    disbursements: "Sgaoilidhean",
    offlineSales: "Reic far-loidhne",
    products: "Toraidhean",
    customers: "Luchd-ceannach",
    multiLevelLoyalty: "Dìlseachd ioma-ìre",
    loyaltyPrograms: "Prògraman Dìlseachd",
    members: "Buill",
    appStore: "Stòr App",
    orderMenu: "Clàr Òrdugh",
    settings: "Suidhichidhean",
    staffAndBranches: "Luchd-obrach agus Meuran",
    myAccount: "Mo chunntas",
    switchToSellMode: "Atharraich gu modh reic",
    signOut: "Soidhnig a-mach",
    getFreeSubscription: "Faigh fo-sgrìobhadh an-asgaidh",
    onlyNumbersAllowed: "Chan eil ach àireamhan ceadaichte",
    yourAccountMustBe10Digits:
      "feumaidh an àireamh cunntais agad a bhith na àireamh 10 digit",
    yourBvnMustBe11: "feumaidh do BVN a bhith na àireamh 11 digit",
    pleaseSelectBank: "Tagh do bhanca",
    selectYourBank: "Tagh do Bhanca",
    enterBankAccountNumber: "Cuir a-steach àireamh cunntas banca",
    enterBvnNumber: "Cuir a-steach do BVN",
    connectBankAccount: "Ceangal cunntas banca",
    passwordResetSuccessfulAndSignOut:
      "Chaidh am facal-faire agad ath-shuidheachadh gu soirbheachail. Cliog air a ’phutan Clàraich a-mach gu h-ìosal gus clàradh a-rithist",
    enterCurrentPassword: "Cuir a-steach am facal-faire gnàthach",
    pleaseEnterCurrentPassword: "Cuir a-steach am facal-faire gnàthach",
    phoneNumber: "Àireamh fòn",
    sex: "Feise",
    dateOfBirth: "Ceann-la-breith",
    state: "Stàite",
    country: "Dùthaich",
    loyaltyId: "ID dìlseachd",
    createdAt: "Air a chruthachadh aig",
    noOfTransactionsMade: "Àireamh de ghnothaichean air an dèanamh",
    yourDownloadWillStart: "Tòisichidh an luchdachadh sìos agad ann am mionaid",
    exportCustomers: "Luchd-ceannach às-mhalairt",
    youhaveSuccessfullyToppedUp:
      "Tha thu air na h-Aonadan SMS agad a mheudachadh.",
    youNowHave: "Tha agad a-nis",
    smsUnits: "Aonadan SMS",
    enterNumberOfSmsUnits:
      "Cuir a-steach an àireamh de dh'aonadan SMS a tha thu an dùil a cheannach",
    pleaseEnterANumericValue: "Cuir a-steach luach àireamhach",
    pay: "Pàigh",
    accountEditedSuccessfully:
      "Chaidh an cunntas agad a dheasachadh gu soirbheachail.",
    youAeCurrentlyOn: "Tha thu air adhart an-dràsta",
    plan: "Plana",
    userData: "Dàta cleachdaiche",
    businessData: "DÀTA BÒNAS",
    businessCategory: "Roinn-gnìomhachais",
    pleaseSelectCurrency: "Tagh do airgead-crìche",
    selectYourCurrency: "Tagh do airgead-crìche",
    purchaseMoreSmsUnits:
      "Ceannaich barrachd Aonadan SMS a ’cleachdadh an fhoirm gu h-ìosal",
    youHave: "Tha agad",
    atLeast4SmsUnitsRrequired:
      "Feumar co-dhiù 4 aonadan sms airson dearbhadh, feuch an cuir thu suas e!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Feuch an dearbhaich thu do chunntas banca gus gèilleadh ris na riatanasan 'Know Your Customer' (KYC). Leigidh seo dhut pàighidhean a chruinneachadh tro USSD no gluasad sa bhad, òrdughan fhaighinn bho luchd-ceannach agus rèiteachadh malairt pròiseas. Co-cheangailte ri ceannaichean à Nigeria a-mhàin. Brùth air a ’phutan gu h-ìosal gus tòiseachadh.",
    reConnectBankAccount: "Dèan ath-cheangal Cunntas Banca",
    accountName: "Ainm A 'chunntais",
    accountNumber: "Àireamh cunntais",
    bankName: "Ainm Banca",
    verified: "Air a dhearbhadh",
    accountDetails: "Fiosrachadh cunntais",
    kycBankAccount: "KYC",
    createTier: "Cruthaich Tier",
    fileUploadSuccessful: "faidhle air a luchdachadh suas gu soirbheachail",
    fileUploadFailed: "Dh'fhàillig luchdachadh suas faidhle",
    createLoyaltyProgram: "Cruthaich Prògram Dìlseachd",
    createLoyalty: "Cruthaich Dìlseachd",
    name: "Ainm",
    loyaltyArtwork: "Obair-ealain dìlseachd",
    clickToUpload: "Cliog gus luchdachadh suas",
    amountToPointsRatio: "Meud airson Co-mheas Puingean",
    points: "Puingean",
    recommendedAmountToPointRatio:
      "Air a mholadh: ₦ 1 gu 1point. i.e airson gach 1 Naira a chaidh a chaitheamh, gheibh an luchd-ceannach agad 1 phuing",
    pleaseTypeIn: "Cuir a-steach e",
    toDeleteLoyalty: "gus an dìlseachd seo a dhubhadh às",
    deleteLoyalty: "Cuir às do dhìlseachd",
    toConfirm: "gus dearbhadh",
    edit: "Deasaich",
    pointsAwardedSuccessfully:
      "Puingean air an toirt seachad gu soirbheachail.",
    enterPointValueToAward:
      "Cuir a-steach luach puing a bu mhath leat a thoirt do luchd-ceannach",
    award: "Duais",
    awardPointValuesToCustomer: "Luach puing duais don neach-ceannach",
    enrollMembersToLoyalty: "Clàraich Buill gu Dìlseachd",
    awardPoints: "Puingean duais",
    enroll: "Clàraich",
    home: "Dachaigh",
    loyalty: "Dìlseachd",
    enrollCustomers: "Clàraich luchd-ceannach",
    selected: "Air an taghadh",
    customer: "Neach-ceannach",
    loyaltyActivationSuccessful: "Tha Gnìomhachd Dìlseachd soirbheachail.",
    loyaltyDeactivationSuccessful: "Tha cur às do dhìlseachd soirbheachail.",
    viewTier: "Faic Sreath",
    deactivate: "Cuir dheth",
    activate: "Cuir an gnìomh",
    actions: "Gnìomhan",
    nameOfLoyalty: "Ainm na Dìlseachd",
    loyaltyStatus: "Inbhe dìlseachd Inbhe dìlseachd",
    numberOfTiers: "Àireamh nan Sreathan",
    createdOn: "Air a chruthachadh",
    createATier: "Cruthaich Tier",
    stopCreatingTierConfirmation:
      "A bheil thu airson stad a chuir air sreath a chruthachadh?",
    stopEditingTierConfirmation:
      "A bheil thu airson stad a chur air deasachadh an t-sreath seo?",
    nameOfTier: "Ainm an t-sreath",
    minimumSpendingTarget: "Targaid caitheamh as ìsle",
    maximumSpendingTarget: "Targaid caitheamh as àirde",
    minimumSpendingTargetRequired: "tha feum air targaid caitheamh as ìsle",
    maximumSpendingTargetRequired: "tha feum air an targaid caitheamh as àirde",
    rewardSponsor: "Urrasair Duaisean",
    pleaseChooseARewardSponsor: "Tagh neach-taic duais",
    self: "Fèin",
    partner: "Com-pàirtiche",
    rewardPartner: "Com-pàirtiche duais",
    pleaseSelectRewardPartner: "Tagh do chompanach duais",
    rewards: "Duaisean",
    pleaseSelectAReward: "Feuch an tagh thu duais",
    instructionsOnRedeemingReward:
      "Stiùireadh air mar a bu chòir do neach-ceannach ath-phàigheadh a thoirt seachad",
    pleaseFillInThisField: "Feuch an lìon thu an raon seo!",
    toDeleteThisTier: " gus an t-sreath seo a dhubhadh às",
    deleteTier: "Cuir às do shreath",
    viewMembers: "Faic na Buill",
    membersEnrolled: "Buill clàraichte",
    instruction: "Stiùireadh",
    membersIn: "Buill a-steach",
    availableTiersInLoyalty:
      "Sreath (ean) a tha rim faighinn ann am prògram dìlseachd",
    tiers: "Sreathan",
    totalTier: "IOMLAN TIER",
    availableLoyaltyProgramme: "Prògram dìlseachd ri fhaighinn",
    totalLoyalties: "IOMLAN LOYALTIES",
    memberDetails: "Fiosrachadh ball",
    nameOfCustomer: "Ainm an Neach-ceannach",
    address: "Seòladh",
    allEnrolledMembers: "Gach ball clàraichte",
    thisIsToWishYouHappyBirthday:
      "Tha seo airson co-là breith sona agus beatha shoirbheachail a thoirt dhut. Taing airson a h-uile càil a tha thu gu Loyster. Cuirmean sona!",
    inputAnOfferPlease: "Cuir a-steach tairgse mas e do thoil e",
    pleaseSelectTheInsertPoint:
      "Tagh a ’phuing cuir a-steach san teachdaireachd agus cliog a-rithist",
    birthdayOfferAndMessage: "Tairgse is teachdaireachd co-latha-breith",
    birthdayOffer: "Tairgse co-latha-breith",
    birthdayMessage: "Teachdaireachd co-là-breith",
    noOfferFound: "Cha deach tairgse a lorg",
    settingABirthdayOffer:
      "Le bhith a ’suidheachadh tairgse co-là-breith leigidh luchd-ceannach an tairgse seo fhaighinn tro SMS air an co-là-breith aca",
    createOffer: "Cruthaich tairgse",
    whatIsTheOffer: "Dè an tairgse a th ’ann?",
    editMessage: "Deasaich teachdaireachd",
    insert: "Cuir a-steach",
    theNameOfCustomerInserted:
      "Thèid ainm an neach-ceannach a chuir a-steach an seo",
    theBirtdayOfferInserted:
      "Thèid an tairgse co-là-breith a chuir a-steach an seo",
    youSuccessfullyAddedNewBranch:
      "Tha thu air meur ùr a chuir ris gu soirbheachail!",
    addNewBranch: "Cuir meur ùr ris",
    addBranch: "Cuir meur ris",
    additionalBranchWillIncur: "Thigeadh Meur a bharrachd",
    perBranch: "gach meur",
    ecommerceBranchCosts: "Cosgaisean Meur E-Malairt",
    pleaseEnterBranchName: "Cuir a-steach ainm a ’mheur",
    pleaseEnterBranchAddress1: "Cuir a-steach loidhne seòlaidh a ’mheur 1",
    enterBranchAddress1: "Cuir a-steach loidhne seòlaidh a ’mheur 1",
    enterBranchAddress2: "Cuir a-steach loidhne seòlaidh a ’mheur 1",
    pleaseEnterBranchAddress2: "Cuir a-steach loidhne seòlaidh 2 a ’mheur",
    enterBranchName: "Cuir a-steach ainm a ’mheur",
    successfullyAddedStaff:
      "Tha thu air luchd-obrach ùr a chuir ris gu soirbheachail!",
    addNewStaff: "Cuir luchd-obrach ùr ris",
    addStaff: "Cuir luchd-obrach ris",
    additionalStaffWillIncur: "Bhiodh luchd-obrach a bharrachd ann",
    perStaff: "gach neach-obrach.",
    pleaseEnterStaffEmail: "Cuir a-steach post-d an luchd-obrach",
    pleaseEnterStaffUsername: "Cuir a-steach ainm-cleachdaidh an luchd-obrach",
    pleaseEnterStaffPassword: "Cuir a-steach facal-faire an luchd-obrach",
    pleaseSelectStaffRole: "Tagh dreuchd an luchd-obrach",
    selectStaffRole: "Tagh dreuchd an luchd-obrach",
    enterStaffEmail: "Cuir a-steach post-d an luchd-obrach",
    enterStaffUsername: "Cuir a-steach ainm-cleachdaidh an luchd-obrach",
    enterStaffPassword: "Cuir a-steach facal-faire an luchd-obrach",
    spacesNotAllowedInUsername:
      "àiteachan nach eil ceadaichte ann an ainm-cleachdaidh",
    admin: "Rianachd",
    pleaseSelectBusinessToAttachStaff:
      "Tagh gnìomhachas airson luchd-obrach a cheangal",
    searchForBranchToAttachStaff: "Lorg meur airson luchd-obrach a cheangal",
    username: "Ainm-cleachdaidh",
    role: "Dreuchd",
    areYouSureToDeleteThis: "A bheil thu cinnteach gun cuir thu às dha seo",
    branches: "Meuran",
    upgradeYourPlan: "Dèan ùrachadh air a ’Phlana agad.",
    add: "ADD",
    addNew: "Cuir Ùr ris",
    customerWithEmailAlreadyExists:
      "Tha teachdaiche le post-d / àireamh fòn ann mu thràth!",
    successfullyAddedNewCustomer:
      "Tha thu air neach-ceannach ùr a chuir ris gu soirbheachail!",
    addCustomer: "Cuir teachdaiche ris",
    pleaseEnterCustomerFirstName: "Cuir a-steach ciad ainm an neach-ceannach",
    pleaseEnterCustomerLastName:
      "Cuir a-steach ainm mu dheireadh an neach-ceannach",
    pleaseEnterCustomerPhoneNumber:
      "Cuir a-steach àireamh fòn an neach-ceannach",
    pleaseEnterCustomerEmail: "Cuir a-steach post-d teachdaiche",
    pleaseEnterCustomerAddressLine:
      "Cuir a-steach loidhne seòlaidh an neach-ceannach",
    pleaseEnterCustomerOtherAddress:
      "Cuir a-steach seòladh eile an neach-ceannach",
    pleaseSelectCustomerGender: "Tagh gnè neach-ceannach",
    gender: "Gnè",
    male: "Fireann",
    female: "Boireann",
    bank: "Banca",
    selectBank: "Tagh Banca",
    stateOrRegionOrProvince: "Stàite / Roinn / Roinn",
    customerNotes: "Notaichean teachdaiche",
    sendSms: "Cuir SMS",
    editCustomer: "Deasaich teachdaiche",
    redeemReward: "Duais ath-dhìoladh",
    issueLoyaltyCard: "Cuir a-mach cairt dìlseachd",
    deleteCustomer: "Cuir às do neach-ceannach",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Tha thu air ID Ballrachd Dìlseachd a shònrachadh gu soirbheachail",
    noMembershipIdAvailable:
      "Chan eil IDan ballrachd ri fhaighinn. Cuir fios thugainn air hello@loystar.co",
    sendEmail: "Cuir post-d",
    membershipPoints: "Puingean Ballrachd",
    customerDetails: "Fiosrachadh teachdaiche",
    close: "Dùin",
    loyaltyBalance: "Cothromachadh dìlseachd",
    pointsBalance: "Cothromachadh Puingean",
    starBalance: "Cothromachadh Rionnag",
    requiredPoints: "Puingean riatanach",
    requiredStars: "Rionnagan a tha a dhìth",
    reddemCode: "Còd Fuasglaidh",
    toDeleteThisCustomer: "gus an neach-ceannach seo a dhubhadh às",
    customerHasBeenDeletedSuccessfully:
      "Chaidh an teachdaiche a dhubhadh às gu soirbheachail!",
    customerWithPhoneAlreadyExists:
      "Tha neach-ceannach le àireamh fòn ann mu thràth",
    customerWasSuccessfullyEdited:
      "Chaidh an neach-ceannach a dheasachadh gu soirbheachail",
    anErrorOccured: "Thachair mearachd",
    phoneNumberIncludeCountry: "Àireamh fòn (Cuir a-steach còd na dùthcha)",
    yourFileQueuedForUpload:
      "Chaidh am faidhle agad a chiudha airson a luchdachadh suas. Feuch an ùraich thu an duilleag an dèidh beagan dhiog.",
    thereWasAnErrorPerformingOperation:
      "Bha mearachd a ’coileanadh na h-obrach!",
    pleaseSelectFile: "Tagh faidhle!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Chan eil ach faidhlichean CSV ceadaichte. Feuch an luchdaich thu suas faidhle .csv.",
    fileShouldBeSmallerThan5Mb:
      "Bu chòir don fhaidhle a bhith nas lugha na 5MB. Ma tha faidhle nas motha agad, cuir post-d gu support@loystar.co. Tapadh leat",
    customerFirstNameIsRequired:
      "feumar ciad ainm neach-ceannach ann an sreath",
    customerPhoneNumberIsRequired:
      "tha feum air àireamh fòn teachdaiche ann an sreath",
    importCustomers: "Luchd-ceannach Import",
    upload: "Luchdaich suas",
    clickIconToDownloadCsv:
      "Cliog air an ìomhaigh seo gus teamplaid faidhle CSV a luchdachadh sìos.",
    getGoogleSheetVersion: "Faigh an dreach duilleag Google",
    clickOrDragFileToUpload:
      "Cliog no slaod faidhle chun raon seo gus a luchdachadh suas",
    missingOrInvalidColumnHeader:
      "Ceann colbh a dhìth no neo-dhligheach. Feuch an lean thu cruth an teamplaid. Tapadh leat.",
    youHaveImported: "Tha thu air ion-phortadh",
    youSuccessfullyRedeemedReward:
      "Tha thu air do dhuais a chosnadh gu soirbheachail!",
    sixDigitCode: "Còd sia-fhigearach",
    pleaseEnterCustomerRewardCode: "Cuir a-steach còd duais an neach-ceannach",
    enterRewardCode:
      "Cuir a-steach còd duais. (Tha an còd duais mothachail do chùis)",
    selectLoyaltyProgram: "Tagh prògram dìlseachd",
    stamps: "Stampaichean",
    smsUnitsLow: "Aonadan SMS ìosal",
    pleaseTopUpSmsUnits: "Feuch an cuir thu suas aonadan SMS",
    smsSuccessfullySentForDelivery:
      "Chuir SMS a-steach gu soirbheachail airson lìbhrigeadh!",
    sendSmsTo: "Cuir SMS gu",
    allCustomers: "Luchd-ceannach uile",
    unitsAvailable: "Aonadan rim faighinn",
    pleaseTypeInTheMessage: "Cuir a-steach an teachdaireachd",
    message: "Teachdaireachd",
    charactersRemaining: "caractaran air fhàgail",
    avoidUseOfSpecialCharacters:
      "Seachain caractaran sònraichte agus Emojis a chleachdadh gus Aonadan SMS a ghleidheadh.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Mearachd Fetching Customers MultiLevel Mion-fhiosrachadh",
    search: "Rannsaich",
    reset: "Ath-shuidhich",
    importCustomer: "Luchd-ceannach Import",
    addNewCustomer: "Cuir teachdaiche ùr ris",
    sendSmsBroadcast: "Cuir SMS Broadcast",
    totalCustomers: "IOMLAN CUSTOMERS",
    disbursementDetails: "Fiosrachadh mu phàigheadh",
    paidBy: "Air a phàigheadh le",
    disbursed: "Air a sgaoileadh",
    bankAccountName: "Ainm Cunntas Banca",
    bankAccountNumber: "Àireamh cunntas banca",
    transferInitiated: "Chaidh an gluasad a thòiseachadh",
    transferCompleted: "Gluasad crìochnaichte",
    pleaseEnterAValid: "Cuir a-steach dligheach",
    begin: "tòiseachadh",
    end: "deireadh",
    date: "ceann-latha",
    paymentDate: "Ceann-latha pàighidh",
    selectDisbursementDuration: "Tagh Ùine Sgaoilidh",
    totalSettled: "Iomlan air a rèiteachadh",
    totalUnsettled: "Iomlan gun fhuasgladh",
    toDeleteThisSale: "gus an reic seo a dhubhadh às",
    draftSaleDeletedSuccessfully:
      "Chaidh Dreach Reic a dhubhadh às gu soirbheachail!",
    deleteSale: "Cuir às do reic",
    pleaseTypeInYourTotal:
      "Cuir a-steach an àireamh iomlan agad gus dearbhadh cuir às",
    billDetails: "Mion-fhiosrachadh Bile",
    printBill: "Bile Clò-bhuail",
    servedBy: "Air a frithealadh le",
    total: "Iomlan",
    createdDate: "Ceann-latha cruthaichte",
    createdTime: "Ùine Cruthaichte",
    status: "Inbhe",
    loginSuccessful: "Log a-steach soirbheachail",
    pleaseWaitWhileWeConnectAccount:
      "Fuirich ort fhad ‘s a cheanglas sinn an cunntas agad",
    connectionFailedTryAgain: "Dh'fhàillig an ceangal. Feuch ris a-rithist.",
    connectionSuccessful: "Ceangal soirbheachail",
    viewDetails: "Faic mion-fhiosrachadh",
    enable: "Dèan comas",
    free: "An-asgaidh",
    cost: "Cosgais",
    visitWebsite: "Tadhail air an làrach-lìn",
    pleaseUpgradeYourPlanToPro:
      "Feuch an àrdaich thu do phlana gu Pro Plus gus an aplacaid seo a chomasachadh",
    connectYourBankAccount:
      "Ceangail do chunntas banca gus am faigh thu pàighidhean.",
    disable: "Cuir à comas",
    enableApp: "Dèan comas air aplacaid",
    addNewProductToInvoice: "Cuir toradh ùr ris an fhàirdeal",
    toDeleteThisInvoice: "gus an fhàirdeal seo a dhubhadh às",
    invoiceDeletedSuccessfully:
      "Chaidh fàirdeal a dhubhadh às gu soirbheachail!",
    deleteInvoice: "Cuir às do fhàirdeal",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Tha thu air teachdaireachd pàighidh fàirdeal a chuir ris gu soirbheachail!",
    pleaseEnterPaymentInstructions: "Cuir a-steach stiùireadh pàighidh",
    invoiceId: "ID an fhàirdeal",
    paidDate: "Ceann-latha pàighte",
    reference: "Fiosrachadh",
    productAdded: "Bathar air a chur ris",
    productOutOfStock: "Bathar a-mach à stoc. Feuch an ath-stoc thu.",
    totalInvoices: "IOMRAIDHEAN IOMLAN",
    totalPaidInvoices: "IOMLAN PÀIRT IOMLAN",
    totalUnpaidInvoices: "IOMLAN IONADAIL UNPAID",
    loyaltyProgramDeleted: "Prògram dìlseachd air a dhubhadh às",
    thisLoyaltyProgramDeletedSuccessfully:
      "Chaidh am prògram dìlseachd seo a dhubhadh às gu soirbheachail",
    thisLoyaltyProgramEditedSuccessfully:
      "Chaidh am prògram dìlseachd seo a dheasachadh gu soirbheachail",
    loyaltyProgramAddedSuccessfully:
      "Chaidh prògram dìlseachd a chuir ris gu soirbheachail",
    loyaltyProgramEdited: "Prògram dìlseachd air a dheasachadh",
    loyaltyProgramAdded: "Prògram dìlseachd air a chur ris",
    loyaltyDetails: "Mion-fhiosrachadh dìlseachd",
    doYouWantToCloseDialog: "A bheil thu airson an còmhradh seo a dhùnadh?",
    pleaseEnterLoyaltyName: "Cuir a-steach ainm do dhìlseachd",
    programType: "Seòrsa Prògram",
    pleaseSelectProgramType: "Tagh seòrsa prògram",
    simplePoints: "Puingean sìmplidh",
    stampsProgram: "Prògram stampaichean",
    threshold: "Threshold",
    pleaseEnterLoyaltyThreshold: "Cuir a-steach stairsneach na dìlseachd",
    reward: "Duais",
    pleaseEnterLoyaltyReward: "Feuch an cuir thu a-steach duais na dìlseachd",
    totalUserPoints: "Puingean cleachdaiche iomlan",
    totalUserStamps: "Stampaichean cleachdaiche iomlan",
    spendingTarget: "Targaid caitheamh",
    spendingTargetHint1:
      "Is e targaid caitheamh an ìre a dh'fheumas neach-ceannach a chosg gus an duais a chosnadh. 1 luach airgid = 1 phuing.",
    spendingTargetHint2:
      "Is e targaid stampaichean cia mheud stampa a dh ’fheumas neach-ceannach a chruinneachadh gus an duais a chosnadh. me. 5",
    addNewLoyaltyProgram: "Cuir prògram dìlseachd ùr ris",
    addLoyaltyProgram: "Cuir prògram dìlseachd ris",
    loyaltyProgramType: "Seòrsa Prògram Dìlseachd",
    pleaseSelectLoyaltyProgramType: "Tagh seòrsa prògram dìlseachd",
    nameOfProgram: "Ainm a ’phrògraim",
    pleaseEnterProgramName: "Cuir a-steach ainm a ’phrògraim",
    whatIsTheReward: "Dè an duais a th ’ann?",
    egNextPurchaseIsFree: "E.g. Tha an ath cheannach an-asgaidh",
    customerName: "Ainm teachdaiche",
    guestCustomer: "Neach-ceannach aoigh",
    orderConfirmedSuccessfully: "Òrdugh air a dhearbhadh gu soirbheachail",
    orderCancelledSuccessfully: "Chaidh òrdugh a chuir dheth gu soirbheachail",
    confirmOrder: "Dearbhaich òrdugh",
    cancelOrder: "Cuir dheth òrdugh",
    allOrders: "Gach Òrdugh",
    totalOrders: "ORDAIDHEAN IOMLAN",
    doYouWantToAcceptOrder: "A bheil thu airson gabhail ris an òrdugh seo?",
    doYouWantToCancelOrder: "A bheil thu airson an òrdugh seo a chuir dheth?",
    orderDetails: "Fiosrachadh òrdugh",
    orderCreatedAt: "Òrdugh air a chruthachadh aig",
    supplier: "Solaraiche",
    productName: "Ainm toraidh",
    quantity: "Meud",
    unitPrice: "Prìs Aonad",
    receivedBy: "Air fhaighinn le",
    reportFrom: "Aithisg bho",
    totalSupplies: "Solarachadh iomlan",
    allRightsReserved: "Còraichean uile glèidhte",
    toDeleteThisTransaction: "gus an Gnìomh seo a dhubhadh às",
    transactionDeletedSuccessfully:
      "Chaidh gluasad air falbh gu soirbheachail. Chaidh stoc a thilleadh gu clàr-seilbhe.",
    deleteTransaction: "Cuir às do ghluasad",
    transactionDetails: "Mion-fhiosrachadh gluasad",
    printReceipt: "Cuidhteas Clò-bhualaidh",
    channel: "Sianal",
    discount: "Lasachadh prìse",
    profit: "Prothaid",
    discountedSales: "Reic lasaichte",
    errorFetchingRecord: "Clàr mearachd mearachd",
    exportTransactions: "Gnìomhan às-mhalairt",
    errorFetchingSalesRecord:
      "Mearachd a ’faighinn clàr reic airson às-mhalairt.",
    totalSellingPrice: "Prìs reic iomlan",
    totalCostPrice: "Prìs Cosgais Iomlan",
    appliedDiscount: "Lasachadh gnìomhaichte",
    noOfItems: "Àireamh de stuthan",
    sales: "Reic",
    export: "Às-mhalairt",
    totalProfit: "Prothaid iomlan",
    totalBalanceInPeriod: "Cothromachadh iomlan san ùine",
    allTimeSales: "Reic ùine gu lèir",
    records: "Clàran",
    todaysSales: "Reic an latha an-diugh",
    transaction: "malairt",
    youHaveExceededTotalNumberOfProducts:
      "Tha thu air a dhol thairis air an àireamh iomlan de thoraidhean a tha ceadaichte air do phlana. Àrdaich do phlana gus crìoch nas àirde a mhealtainn.",
    youNeedToHaveLoyaltyProgram:
      "Feumaidh tu prògram dìlseachd a bhith agad gus am feart seo a chleachdadh!",
    price: "Prìs",
    category: "Roinn-seòrsa",
    stockTracking: "Sgrùdadh stoc",
    stockCount: "Àireamh stoc",
    taxed: "Cìs",
    originalPrice: "Prìs tùsail",
    costPrice: "Prìs Cosgais",
    unit: "Aonad",
    productImage: "Ìomhaigh toraidh",
    taxRate: "Ìre Cìsean",
    taxType: "Seòrsa Cìsean",
    trackInventory: "Clàr-seilbhe",
    variants: "Caochlaidhean",
    hasVariants: "Tha caochlaidhean aige",
    importProduct: "Bathar Import",
    exportProducts: "Toraidhean às-mhalairt",
    addNewProduct: "Cuir toradh ùr ris",
    viewCategory: "Faic an roinn-seòrsa",
    viewSuppliers: "Thoir sùil air solaraichean",
    receiveInventory: "Faigh Clàr-seilbhe",
    printAllProductsTag: "Clò-bhuail a h-uile toradh tag",
    deleteAll: "Cuir às do na h-uile",
    totalProducts: "TORAIDHEAN IOMLAN",
    youveSuccessfullyAddedANewCategory:
      "Tha thu air roinn ùr a chuir ris gu soirbheachail",
    addNewCategory: "Cuir roinn-seòrsa ùr ris",
    addCategory: "Cuir Roinn-seòrsa ris",
    categoryName: "Ainm roinn-seòrsa",
    pleaseEnterCategoryName: "Cuir a-steach ainm roinn-seòrsa",
    stampsTarget: "Targaid stampaichean",
    sendInventory: "Cuir Clàr-seilbhe",
    productDetails: "Mion-fhiosrachadh toraidh",
    youveSuccessfullyEditedThisCategory:
      "Tha thu air an roinn seo a dheasachadh gu soirbheachail",
    update: "Ùrachadh",
    categoryList: "Liosta roinn-seòrsa",
    categories: "Roinnean-seòrsa",
    enterQuantityToUpdate: "Cuir a-steach meud airson ùrachadh",
    inventorySentSuccessfully:
      "Chaidh an clàr-seilbhe a chuir gu soirbheachail!",
    updateInventory: "Clàr ùrachaidh",
    currentQuantity: "Meud gnàthach",
    pleaseEnterQuantityToAdd:
      "Cuir a-steach an tomhas a tha thu airson a chur ris",
    pleaseSelectABranch: "Tagh Meur",
    searchForBranch: "Lorg meur",
    youCantSendMoreThanStock:
      "Chan urrainn dhut barrachd a chuir na tha agad ann an stoc",
    send: "Cuir",
    pleaseEnterQuantityToSend:
      "Cuir a-steach an tomhas a tha thu airson a chuir",
    productNameIsRequiredOnRow: "tha feum air ainm toraidh ann an sreath",
    productCategoryIsRequiredOnRow: "tha feum air roinn toraidh ann an sreath",
    productPriceIsRequiredOnRow: "tha feum air prìs toraidh ann an sreath",
    productUnitIsRequiredOnRow: "tha feum air aonad toraidh ann an sreath",
    productQuantityIsRequiredOnRow: "tha feum air meud toraidh ann an sreath",
    productVariantsRequireTracking:
      "atharrachaidhean toraidh feumach air lorg!",
    pleaseAddVariantClickButton:
      "Feuch an cuir thu tionndadh le bhith a ’briogadh air a’ phutan cuir ris!",
    totalVariantsMoreThanSelectedQuantity:
      "Tha caochlaidhean iomlan nas motha na meud toraidh taghte, bidh Pls a ’lughdachadh meud caochlaideach",
    productEditedSuccessfully: "Chaidh toradh a dheasachadh gu soirbheachail!",
    fileTooLargeLessThan4Mb:
      "Tha meud faidhle ro mhòr! Bu chòir meud faidhle a bhith nas lugha na 4MB.",
    suchVariantAlreadyExist:
      "Tha an leithid de dh ’eadar-dhealachadh ann mu thràth",
    addVariants: "Cuir atharrachaidhean ris",
    editProduct: "Deasaich toradh",
    pleaseEnterProductName: "Cuir a-steach ainm toraidh",
    pleaseEnterProductPrice: "Cuir a-steach prìs toraidh",
    pleaseEnterProductOriginalPrice:
      "Feuch an cuir thu a-steach prìs tùsail toraidh",
    productDescription: "Tuairisgeul toraidh",
    selectProductCategory: "Tagh Roinn Bathar",
    pleaseSelectProductCategory: "Tagh roinn toraidh",
    productCostPrice: "Prìs Cosgais Bathar",
    productSellingPrice: "Prìs reic toraidh",
    productOriginalPrice: "Prìs tùsail toraidh",
    maxFileSizeAllowedIs4Mb: "Max. Is e meud faidhle ceadaichte 4mb",
    productsWithPicturesArePublished:
      "Tha toraidhean le dealbhan air am foillseachadh air Discover gus òrdughan fhaighinn",
    shouldThisProductBeTracked: "Am bu chòir sùil a chumail air an toradh seo?",
    pleaseSelectStockUnit: "Tagh aonad stoc",
    stockUnit: "Aonad Stoc",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "CAN",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITIR",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLEAN",
    tonne: "TONNE (MT)",
    uNIT: "AONAD",
    yard: "YARD",
    pleaseEnterProductQuantity: "Cuir a-steach meud toraidh",
    productQuantity: "Meud toraidh",
    isThisProductTaxed: "A bheil cìs air an toradh seo?",
    selectTaxType: "Tagh Seòrsa Cìsean",
    pleaseSelectTaxType: "Tagh Seòrsa Cìsean",
    progressive: "Adhartach",
    proportional: "Co-roinneil",
    pleaseEnterProductTaxRate: "Cuir a-steach ìre cìs toraidh",
    doesProductHaveVariants: "A bheil atharrachaidhean anns an toradh seo?",
    type: "Seòrsa",
    value: "Luach",
    pleaseEnterVariantType: "Cuir a-steach seòrsa caochlaideach",
    pleaseEnterVariantValue: "Cuir a-steach luach caochlaideach",
    pleaseEnterVariantPrice: "Cuir a-steach prìs caochlaideach",
    pleaseEnterVariantQuantity: "Cuir a-steach meud caochlaideach",
    productDeletedSuccessfully: "Chaidh toradh a dhubhadh às gu soirbheachail!",
    categoryDeletedSuccessfully:
      "Chaidh an roinn-seòrsa a dhubhadh às gu soirbheachail!",
    toDeleteThisProduct: "gus an toradh seo a dhubhadh às",
    invalidProductQuantity: "Meud toraidh neo-dhligheach",
    quantityAddedIsOverStock:
      "Tha an uiread a tha thu a ’cur ris os cionn na tha agad ann an stoc.",
    itemInventoryNotTracked: "Chan eilear a ’cumail sùil air clàr stuthan",
    addBulkQuantity: "Cuir meud mòr ris",
    enterBulkQuantity: "Cuir a-steach meud mòr",
    pleaseEnterBulkQuantity: "Cuir a-steach Bulk Quantity",
    youveSuccessfullyAddedANewProduct:
      "Tha thu air toradh ùr a chuir ris gu soirbheachail!",
    pleaseEnterProductSellingPrice: "Cuir a-steach prìs reic toraidh",
    doYouWantToTrackProductStock:
      "A bheil thu airson sùil a chumail air stoc toraidh?",
    sellingPrice: "Prìs reic",
    setProductExpiryReminder: "Suidhich cuimhneachan toraidh toraidh",
    productExpiryDate: "Ceann-latha crìochnachaidh toraidh",
    startRemindingFrom: "Tòisich a ’cuimhneachadh bho",
    productSuppliesAddedSuccessfully:
      "Tha thu air solar toraidh a chuir ris gu soirbheachail.",
    addProductSupplies: "Cuir solar toraidh ris",
    pleaseSelectSupplier: "Tagh Solaraiche",
    nameOfProduct: "Ainm an toraidh",
    pleaseSelectProduct: "Tagh Toradh",
    productVariant: "Caochladh toraidh",
    pleaseSelectAVariant: "Tagh caochladh",
    pleaseEnterUnitPrice: "Cuir a-steach prìs aonaid",
    businessBranch: "Meur Gnìomhachais",
    pleaseSelectBranch: "Tagh meur",
    youveSuccessfullyAddedANewSupplier:
      "Tha thu air solaraiche ùr a chuir ris gu soirbheachail",
    addSupplier: "Cuir solaraiche ris",
    pleaseEnterSupplierEmail: "Cuir a-steach post-d solaraiche",
    pleaseAddADescription: "Feuch an cuir thu tuairisgeul ris",
    anErrorOccuredProductsDeleted:
      "Thachair mearachd fhad ‘s a bha e a’ coileanadh na h-obrach. Thoir an aire, is dòcha nach deach mòran de thoraidhean a dhubhadh às sa phròiseas",
    bulkDelete: "Sguab às mòr",
    youAreAboutToDelete: "Tha thu gu bhith a ’cuir às",
    product: "Bathar",
    isDueToRedeem: "ri phàigheadh",
    aReward: "duais",
    pleaseSelectCustomerToReeemReward:
      "Tagh neach-ceannach gus duais fhaighinn.",
    youHaveNoLoyaltyProgramRunning:
      "Chan eil prògram dìlseachd gnìomhach agad a ’ruith",
    customerHhasNoPointsInLoyaltyProgram:
      "Chan eil puingean aig neach-ceannach sa phrògram dìlseachd seo",
    proceedWithPayment: "Cuir air adhart le pàigheadh?",
    youAreAboutToPayForTransactionUsingPoints:
      "Tha thu gu bhith a ’pàigheadh airson a’ ghnothaich a ’cleachdadh puingean.",
    customerHas: "Tha aig teachdaiche",
    worth: "luach",
    andIsNotSufficientToPayForTransaction:
      "agus chan eil e gu leòr airson pàigheadh airson a ’ghnothaich seo. Am biodh iad airson an cothromachadh a chuir ris a ’cleachdadh dòigh pàighidh eile?",
    addCustomerLoyalty: "Cuir Dìlseachd Luchd-ceannach",
    pleaseAddCustomerFirst:
      "Feuch an cuir thu no tagh neach-ceannach an toiseach.",
    pleaseWaitWhileWeGetReady: "Fuirich ort, fhad ‘s a bhios sinn deiseil",
    lowStock: "Stoc Ìosal",
    pleaseEnterAmountTendered:
      "Cuir a-steach an t-sùim a chaidh a thoirt a-steach",
    areYouSureToBookSaleOffline:
      "A bheil thu cinnteach gu bheil thu airson an reic seo a chuir air-loidhne?",
    saleWouldBeBookedAutomatically:
      "Bhiodh reic air a ghlèidheadh gu fèin-ghluasadach nuair a thionndaidheas tu air an eadar-lìn agad",
    offlineSalesBookingCancelled: "Sguir reic reic far-loidhne",
    covid19Message:
      "COVID19: Nigh do làmhan le siabann no Sanitize gus stad a chuir air sgaoileadh. Fuirich sàbhailte an-còmhnaidh",
    saleSuccessfullyRecorded: "Reic air a chlàradh gu soirbheachail!",
    sendReceiptToEmail: "Cuir cuidhteas gu Post-d",
    sendThankYouSms: "Cuir SMS taing",
    sendShippingDetails: "Cuir mion-fhiosrachadh mu luingearachd",
    addLoyalty: "Cuir dìlseachd ris",
    searchCustomerNameOrNumber: "Rannsaich ainm no àireamh teachdaiche",
    clickTheSearchCustomerBox:
      "Cliog air Bogsa Luchd-ceannach Rannsachaidh agus Cairt Scan",
    enterProductPrice: "Cuir a-steach prìs toraidh",
    enterPriceFor: "Cuir a-steach prìs airson",
    searchForProduct: "Lorg airson toradh",
    all: "Uile",
    backToDashboard: "Air ais gu Dashboard",
    viewDraftSales: "Thoir sùil air dreach reic",
    variantSelected: "caochladh air a thaghadh",
    variant: "caochlaideach",
    thePreviousVariantSelectionNotAvailable:
      "Chan eil an taghadh caochlaideach roimhe ri fhaighinn airson an caochladh ùr a chaidh a thaghadh stèidhichte air prìs, feuch an atharraich thu do thaghadh.",
    pleaseSupplyPositiveQuantityNumber: "Thoir seachad àireamh meud postive",
    lowStockFor: "Stoc ìosal airson",
    clearVariants: "Caochlaidhean soilleir",
    pleaseEnterQuantity: "Cuir a-steach meud",
    picture: "Dealbh",
    redemptionToken: "Taisbeanadh fuasglaidh",
    token: "Token",
    totalSpent: "Iomlan air a chaitheamh",
    confirmPayment: "Dearbhaich pàigheadh",
    hasPaymentBeenMade:
      "An deach am pàigheadh a phròiseasadh gu soirbheachail?",
    enterTransactionReference:
      "Cuir a-steach iomradh a ’ghnothaich leis na phàigh thu",
    pleaseSelectACustomer: "Tagh neach-ceannach!",
    areYouSureToApplyDiscount:
      "A bheil thu cinnteach gu bheil thu airson lasachadh a chuir a-steach?",
    addYourBankAccountToEnableUssd:
      "Cuir ris a ’chunntas banca agad gus leigeil le Instant USSD gluasad le uPay",
    totalAmountToPay: "Suim iomlan ri phàigheadh",
    doYouWantToCancelTransaction:
      "A bheil thu airson an gnothach seo a chuir dheth?",
    savePrintBill: "Bile Sàbhail / Clò-bhuail",
    enterAmountCollectedForCustomer:
      "Cuir a-steach suim a chaidh a chruinneachadh airson neach-ceannach",
    recordSale: "Reic reic",
    checkOutWith: "Thoir sùil air le",
    instantTransfer: "Instant Transfer",
    dialTheUSSDCode: "Cuir fòn gu còd USSD",
    pleaseSelectABank: "Tagh banca",
    payWithUSSD: "Pàigh Le USSD",
    sendBillTo: " - Cuir Bile gu",
    waitingForUSSDTransfer: "A ’feitheamh ri gluasad USSD",
    percent: "Àireamh sa cheud",
    applyDiscount: "Cuir a-steach lasachadh prìse",
    thisBillHasBeenSaved: "Chaidh am Bile seo a shàbhaladh",
    saveDraft: "Sàbhail dreach",
    pleaseTypeANameToIdentifyCustomer:
      "Cuir a-steach ainm gus an neach-ceannach aithneachadh",
    paymentDetails: "Fiosrachadh pàighidh",
    basePrice: "Prìs Base",
    staff: "Luchd-obrach",
    subTotal: "Fo-làn",
    durationMonths: "Fad (mìosan)",
    selectADuration: "Tagh Faid",
    oneMonth: "1 Mìos",
    twoMonths: "2 mhìos",
    threeMonths: "3 mìosan",
    sixMonths: "6 mìosan",
    twelveMonths: "12 mìosan",
    eighteenMonths: "18 mìosan",
    twentyFourMonths: "24 mìosan",
    twoMonthsFree: "(2 mhìos an-asgaidh)",
    threeMonthsFree: "(3 mìosan an-asgaidh)",
    fiveMonthsFree: "(5 mìosan an-asgaidh)",
    yourAccountHasBeen: "Tha an cunntas agad air a bhith",
    renewed: "ùrachadh",
    upgraded: "ùrachadh",
    successfully: "gu soirbheachail",
    yourSubscription: "An fho-sgrìobhadh agad",
    youAreCurrentlyEnrolledOnA: "Tha thu clàraichte an-dràsta air a",
    pleaseChooseAPaymentOption: "Tagh Roghainn Pàighidh",
    planRenewal: "Ath-nuadhachadh a ’Phlana",
    planUpgrade: "Ùrachadh a ’Phlana",
    pleaseSelectDurationToPayFor: "Tagh an ùine a tha thu am beachd pàigheadh",
    staffAccounts: "Cunntasan Luchd-obrach",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Tagh plana",
    includeAddons: "Cuir a-steach tuilleadan",
    viewTransactions: "Faic Gnìomhan",
    customerHasNoCompletedTansactions:
      "Chan eil tansactions crìochnaichte aig an neach-ceannach fhathast",
    branch: "Meur",
    enterNumberOfEcommerceBranches:
      "Cuir a-steach àireamh de mheuran e-malairt",
    enterNumberOfEcommerceBranchesToPay:
      "Cuir a-steach àireamh de mheuran e-malairt a tha thu an dùil pàigheadh air a shon",
    ecommerceIntegration: "Amalachadh e-malairt",
    enterNumberOfBranches: "Cuir a-steach àireamh de mheuran",
    enterNumberOfAdditionalBranchesToPay:
      "Cuir a-steach àireamh de mheuran a bharrachd a tha thu an dùil pàigheadh air a shon",
    enterNumberOfStaffs: "Cuir a-steach àireamh luchd-obrach",
    enterNumberOfStaffsToPayFor:
      "Cuir a-steach an àireamh de luchd-obrach a tha thu am beachd pàigheadh air a shon",
    discountApplies: "Lasachadh tagraidhean",
    starsOnThe: "reultan air an",
    offer: "tairgse",
    get: "Faigh",
    moreStarsToRedeem: "barrachd rionnagan ri shaoradh",
    taxVat: "Cìs (VAT)",
    callCashierToCompletePayment:
      "Cuir fòn gu Cashier gus pàigheadh a chrìochnachadh",
    receipt: "Cuidhteas",
    dear: "Dearbh",
    thankYouForYourOrderFindGoods:
      "Tapadh leibh airson an òrdugh agad. Feuch an lorg thu na stuthan a leanas air an toirt seachad, mar a chaidh aontachadh.",
    shippingNote: "Nota luingeis",
    enterShippingNote: "Cuir a-steach nota luingeis",
    shippingAddress: "Seòladh luingeis",
    enterShippingAddress: "Cuir a-steach seòladh luingeis",
    wellDoneYouAreDueToRedeem:
      "S math a rinn thu! Tha thu gu bhith air do shaoradh",
    toGetYourRewardNextVisit:
      "gus do dhuais fhaighinn air an ath thuras agad. Tapadh leat",
    pointsOnThe: "Puingean air an",
    morePointsToRedeem: "barrachd phuingean airson ath-cheannach",
    showCode: "Seall còd",
    toGetYourRewardOnNextVisit:
      "gus do dhuais fhaighinn air an ath thuras agad. Tapadh leat",
    earn: "Chosnadh",
    delivaryNote: "Nota Delivary",
    draftSales: "Dreach reic",
    startDate: "Ceann-latha tòiseachaidh",
    endDate: "Ceann-latha crìochnachaidh",
    orders: "Òrdughan",
    checkout: "Dèan sgrùdadh air",
    noProductItem: "Nì Bathar",
    selectProductImage: "Tagh Ìomhaigh Bathar",
    selectCountry: "Tagh dùthaich",
    selectRegion: "Tagh Stàite / Roinn",
    printProductTag: "Tag toraidh clò-bhualaidh",
    transactionReference: "Iomradh gluasad",
    Cashier: "Airgead",
    Manager: "Manaidsear",
    Owner: "Sealbhadair",
    Admin: "Rianachd",
    addPartners: "Cuir com-pàirtichean ris",
    addNewLoyaltyPartner: "Cuir com-pàirtiche dìlseachd ùr ris",
    pleaseEnterCompanyName: "Cuir a-steach Ainm na Companaidh",
    companyName: "Ainm a 'Chompanaidh",
    pleaseEnterCompanyRepName: "Cuir a-steach Ainm riochdaire a ’chompanaidh",
    companyRepName: "Ainm Riochdaire a ’Chompanaidh",
    pleaseEnterCompanyRepEmail:
      "Cuir a-steach post-d riochdaire a ’chompanaidh",
    companyRepEmail: "Post-d riochdaire companaidh",
    pleaseEnterCompanyRepPhone:
      "Cuir a-steach àireamh fòn riochdaire a ’chompanaidh",
    companyRepPhone: "Àireamh fòn riochdaire a ’chompanaidh",
    addReward: "Cuir duais ris",
    pleaseEnterRewardName: "Cuir a-steach ainm duais",
    rewardName: "Ainm duais",
    rewardQuantity: "Meud duais",
    rewardDescription: "Tuairisgeul Duaisean",
    rewardType: "Seòrsa duais",
    pleaseEnterRewardType: "Cuir a-steach an seòrsa duais",
    pleaseEnterRewardQuantity: "Cuir a-steach meud duais",
    pleaseEnterRewardDescription: "Cuir a-steach tuairisgeul duais",
    fineDining: "Biadh grinn",
    luxuryFashion: "Fasan sòghail",
    hotels: "Taighean-òsta",
    travel: "Siubhal",
    foodAndBeverage: "Biadh is Dibhe",
    fashion: "Fasan",
    health: "Slàinte",
    furniture: "Àirneis",
    entertainment: "Cur-seachad",
    automobile: "Automobile",
    education: "Foghlam",
    beautyAndSpa: "Bòidhchead agus Spà",
    staycation: "Staycation",
    events: "Tachartasan",
    trips: "Tursan",
    oilAndGas: "Ola is Gas",
    laundry: "Nigheadaireachd",
    partnerCategory: "Roinn Com-pàirtiche",
    freeItem: "Nì an-asgaidh",
  },
  Russian: {
    cashier: "касса",
    manager: "менеджер",
    owner: "владелец",
    online: "онлайн",
    offline: "не в сети",
    changePassword: "Измени пароль",
    currentPasswordMessage: "Пожалуйста, введите ваш текущий пароль",
    passwordMessage: "Пожалуйста введите ваш пароль",
    currentPassword: "текущий пароль",
    password: "пароль",
    confirmPasswordMessage: "Пожалуйста, подтвердите свой пароль!",
    confirmPassword: "Подтвердите пароль",
    sendViaEmail: "Отправить по электронной почте",
    sendViaWhatsapp: "Отправить через WhatsApp",
    downloadPdf: "Скачать PDF",
    paid: "оплаченный",
    unpaid: "неоплаченный",
    partial: "частичный",
    closeInvoice: "Вы хотите закрыть счет-фактуру?",
    closeInvoiceConfirmation:
      "Счет-фактура не может быть сохранена. Вы хотите закрыть?",
    yes: "да",
    no: "нет",
    invoice: "Счет",
    wasDeducted: "было вычтено",
    for: "для",
    item: "Элемент",
    addProduct: "Добавить товар",
    paymentReference: "Ссылка на платеж",
    amountPaid: "Выплаченная сумма",
    discountAmount: "Сумма скидки",
    amountDue: "Сумма долга",
    amount: "Количество",
    dueDate: "Срок оплаты",
    paymentType: "Способ оплаты",
    card: "Карта",
    cash: "Наличные",
    bankTransfer: "Банковский перевод",
    paymentMessage: "Сообщение об оплате",
    description: "Описание",
    sendReceipt: "Отправить квитанцию",
    delete: "Удалить",
    save: "Сохранить",
    resend: "Отправить",
    saveAndSend: "Сохранить",
    invoiceSaved: "Счет сохранен!",
    selectPaymentMethod: "Пожалуйста, выберите способ оплаты!",
    selectCustomer: "Пожалуйста, выберите клиента!",
    cartEmptyError:
      "Список корзины не может быть пустым, закройте счет и добавьте товар в корзину!",
    subscriptionExpired:
      "Срок действия вашей подписки истек, и ваша учетная запись теперь ограничена. Нажмите кнопку ниже, чтобы обновить свою учетную запись",
    renew: "Возобновить",
    holdOn: "Подожди",
    customerBank: "Клиент Банк",
    cancel: "Отмена",
    selectACustomer: "Выберите клиента",
    invoiceSuccessfulPdfError:
      "Счет-фактура успешно создан, но создание PDF-файла занимает больше времени, чем обычно. Пожалуйста, вернитесь в ближайшее время.",
    downloadingInvoice: "Скачивание счета",
    downloadingReceipt: "Скачивание квитанции",
    whatsappReceiptError:
      "При отправке квитанции через WhatsApp произошла ошибка. Повторите попытку.",
    receiptToWhatsapp: "Квитанция отправлена в WhatsApp",
    thankYouForPatronage: "Благодарю вас за ваше покровительство",
    hereIsYourReceipt: "Вот квитанция об оплате",
    errorSendingEmailReceipt:
      "При отправке квитанции по электронной почте произошла ошибка. Повторите попытку или обратитесь в службу поддержки.",
    receiptSentToEmail: "Квитанция отправлена покупателю на электронную почту",
    invoiceSentToEmail: "Счет отправлен покупателю на электронную почту",
    invoiceSuccessWhatsappError:
      "Счет успешно создан, но при отправке в WhatsApp произошла ошибка. Попробуйте еще раз в списке счетов",
    invoiceSuccessfulEmailError:
      "Счет-фактура успешно создан, но при отправке по электронной почте произошла ошибка. Пожалуйста, попробуйте еще раз из списка счетов",
    invoiceSentToWhatsapp: "Счет отправлен в WhatsApp",
    hello: "Привет",
    pleaseDownloadInvoice: "Пожалуйста, скачайте счет",
    pleaseDownloadReceipt: "Пожалуйста, скачайте квитанцию",
    from: "из",
    email: "Эл. адрес",
    upgrade: "Обновление",
    youAreOnFreePlan: "Вы используете бесплатный тарифный план.",
    clickOn: "Нажмите на",
    yourPlanInFewSteps: " подписку в несколько быстрых шагов.",
    to: "к",
    yourSubscriptionHasExpired:
      "Срок действия вашей подписки истек, и ваша учетная запись теперь ограничена.",
    days: "дней",
    yourSubscriptionExpiresIn:
      "Срок действия вашей подписки Loystar истекает через",
    createAcount: "Завести аккаунт",
    signIn: "Войти",
    continue: "Продолжать",
    enterOtp: "Введите ПИН-код OTP",
    enterValidOtp: "Пожалуйста, введите действующий PIN-код",
    pin: "ШТЫРЬ",
    tokenSentToMail: "На вашу электронную почту отправлен токен",
    passwordResetSuccessful: "Сброс пароля прошел успешно",
    somethingWentWrong: "Что-то пошло не так!",
    resetPassword: "Сброс пароля",
    iHaveResetCode: "У меня есть код сброса пароля",
    pleaseEnterEmail: "Пожалуйста, введите ваш адрес электронной почты",
    aTokenWillBeSentToEmail: "Токен будет отправлен на вашу электронную почту",
    enterEmail: "Введите адрес электронной почты",
    sendinYourToken: "Отправка вашего токена ...",
    makeSureItMatchesPassword:
      "Убедитесь, что он соответствует вашему новому паролю",
    pleaseChooseNewPassword: "Пожалуйста, выберите новый пароль",
    chooseNewPassword: "Выберите новый пароль",
    enterNewPassword: "Введите новый пароль для подтверждения",
    enterTokenSent: "Введите токен, который был отправлен на вашу почту",
    resetToken: "Сбросить токен",
    resettingPassword: "Сброс пароля ...",
    signUp: "Зарегистрироваться",
    adminSignInWithEmail:
      " Администратор входит в систему с помощью электронной почты, а сотрудники - с помощью имени пользователя.",
    pleaseEnterEmailOrUsername:
      "Пожалуйста, введите ваш адрес электронной почты или имя пользователя",
    emailOrUsername: "Электронная почта или имя пользователя",
    pleaseEnterPassword: "Пожалуйста введите пароль",
    createAnAccount: "Завести аккаунт",
    forgotPassword: "Забыл пароль?",
    enterPhoneNumber: "Введите номер телефона",
    personalData: "Личные данные",
    validateConfirmationCOde: "Подтвердить код подтверждения",
    pleaseEnterFirstName: "Пожалуйста, введите ваше имя",
    pleaseEnterPhoneNumber: "пожалуйста введите ваш номер телефона",
    pleaseEnterLastName: "Пожалуйста, введите свою фамилию",
    pleaseEnterBusinessName: "Пожалуйста, введите название вашей компании",
    firstName: "Имя",
    lastName: "Фамилия",
    businessName: "Наименование фирмы",
    previous: "Предыдущий",
    next: "Следующий",
    pleaseSelectBusinessCategory:
      "Пожалуйста, выберите категорию вашего бизнеса",
    pleaseEnterValidEmail:
      "Пожалуйста, введите действительный адрес электронной почты",
    pleaseEnterPostCode: "Пожалуйста, введите почтовый индекс",
    postCode: "Почтовый индекс",
    phoneNumberInUse: "Этот номер телефона уже используется!",
    emailInUse: "Этот адрес электронной почты уже используется!",
    foodAndDrinks: "Продукты питания и напитки",
    salonAndBeauty: "Салон и красота",
    fashionAndAccessories: "Мода и аксессуары",
    gymAndFitness: "Тренажерный зал и Фитнес",
    travelAndHotel: "Путешествие и отель",
    homeAndGifts: "Дом и Подарки",
    washingAndCleaning: "Стирка и чистка",
    gadgetsAndElectronics: "Гаджеты и электроника",
    groceries: "Продовольственные товары",
    others: "Другие",
    submit: "Представлять на рассмотрение",
    accountCreatedSuccessful: "Ваша учетная запись была успешно создана.",
    pleaseEnterAddress: "Пожалуйста, введите ваш адрес",
    addressLine1: "Адресная строка 1",
    addressLine2: "Адресная строка 2",
    choosePassword: "Выберите пароль",
    passwordMustBe6Characters: "Пароль должен состоять минимум из 6 символов.",
    howDidYouHearLoystar: "Как вы узнали о Loystar?",
    referralCode: "Промо-код",
    byClickingTheButton: " Нажимая кнопку ниже, вы соглашаетесь с",
    termsAndSevice: "Условия",
    wereCreatingAccount: "Мы создаем вашу учетную запись",
    proceed: "Продолжить",
    verificationCodeMustBe6: "Код подтверждения должен состоять из 6 цифр.",
    pleaseEnter6DigitCode: "Пожалуйста, введите 6-значный код",
    enterVerificationCode: "Введите код подтверждения",
    resendToken: "Отправить токен повторно",
    verify: "Проверять",
    transactions: "Сделки",
    todaySales: "Сегодняшние продажи",
    salesHistory: "История продаж",
    supplyHistory: "История поставок",
    new: "Новый",
    invoices: "Счета",
    disbursements: "Выплаты",
    offlineSales: "Офлайн-продажи",
    products: "Продукты",
    customers: "Клиенты",
    multiLevelLoyalty: "Многоуровневая лояльность",
    loyaltyPrograms: "Программы лояльности",
    members: "Члены",
    appStore: "Магазин приложений",
    orderMenu: "Меню заказа",
    settings: "Настройки",
    staffAndBranches: "Аппараты и отделения",
    myAccount: "Мой аккаунт",
    switchToSellMode: "Перейти в режим продажи",
    signOut: "Выход",
    getFreeSubscription: "Получите бесплатную подписку",
    onlyNumbersAllowed: "Разрешены только числа",
    yourAccountMustBe10Digits:
      "номер вашего счета должен быть 10-значным числом",
    yourBvnMustBe11: "ваш BVN должен быть 11-значным числом",
    pleaseSelectBank: "Пожалуйста, выберите ваш банк",
    selectYourBank: "Выберите свой банк",
    enterBankAccountNumber: "Введите номер банковского счета",
    enterBvnNumber: "Введите свой BVN",
    connectBankAccount: "Подключить банковский счет",
    passwordResetSuccessfulAndSignOut:
      "Ваш пароль был успешно сброшен. Нажмите кнопку «Выйти» ниже, чтобы войти снова.",
    enterCurrentPassword: "Введите текущий пароль",
    pleaseEnterCurrentPassword: "Пожалуйста, введите текущий пароль",
    phoneNumber: "Номер телефона",
    sex: "Секс",
    dateOfBirth: "Дата рождения",
    state: "Состояние",
    country: "Страна",
    loyaltyId: "ID лояльности",
    createdAt: "Создано в",
    noOfTransactionsMade: "Кол-во проведенных транзакций",
    yourDownloadWillStart: "Ваша загрузка начнется через мгновение",
    exportCustomers: "Заказчики-экспортеры",
    youhaveSuccessfullyToppedUp: "Вы успешно пополнили свои SMS-единицы.",
    youNowHave: "Теперь у вас есть",
    smsUnits: "SMS-блоки",
    enterNumberOfSmsUnits:
      "Введите количество единиц SMS, которое вы собираетесь приобрести",
    pleaseEnterANumericValue: "Пожалуйста, введите числовое значение",
    pay: "Платить",
    accountEditedSuccessfully:
      "Ваша учетная запись была успешно отредактирована.",
    youAeCurrentlyOn: "Вы сейчас на",
    plan: "План",
    userData: "Данные пользователя",
    businessData: "ДАННЫЕ ДЛЯ БИЗНЕСА",
    businessCategory: "Бизнес-категория",
    pleaseSelectCurrency: "Пожалуйста, выберите вашу валюту",
    selectYourCurrency: "Выберите вашу валюту",
    purchaseMoreSmsUnits: "Купите больше SMS-единиц, используя форму ниже",
    youHave: "У вас есть",
    atLeast4SmsUnitsRrequired:
      "Для верификации необходимо минимум 4 смс, пополните счет!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Подтвердите свой банковский счет на соответствие требованиям «Знай своего клиента» (KYC). Это позволит вам собирать платежи через USSD или мгновенный перевод, получать заказы от клиентов и обрабатывать транзакции. Применимо только к нигерийским торговцам. Пожалуйста, нажмите кнопку ниже, чтобы начать.",
    reConnectBankAccount: "Повторно подключить банковский счет",
    accountName: "Название аккаунта",
    accountNumber: "Номер счета",
    bankName: "Название банка",
    verified: "Проверено",
    accountDetails: "Детали учетной записи",
    kycBankAccount: "KYC",
    createTier: "Создать уровень",
    fileUploadSuccessful: "Файл успешно загружен",
    fileUploadFailed: "загрузка файла не удалась",
    createLoyaltyProgram: "Создать программу лояльности",
    createLoyalty: "Создайте лояльность",
    name: "Имя",
    loyaltyArtwork: "Работа с лояльностью",
    clickToUpload: "Нажмите, чтобы загрузить",
    amountToPointsRatio: "Отношение суммы к баллам",
    points: "Точки",
    recommendedAmountToPointRatio:
      "Рекомендуется: ₦ от 1 до 1 балла. то есть за каждую потраченную 1 найру ваши клиенты получают 1 балл",
    pleaseTypeIn: "Пожалуйста, введите",
    toDeleteLoyalty: "чтобы удалить эту лояльность",
    deleteLoyalty: "Удалить лояльность",
    toConfirm: "подтвердить",
    edit: "Редактировать",
    pointsAwardedSuccessfully: "Баллы начислены успешно.",
    enterPointValueToAward:
      "Введите сумму баллов, которую вы хотите наградить клиентов",
    award: "Награда",
    awardPointValuesToCustomer: "Ценность бонусных баллов для клиента",
    enrollMembersToLoyalty: "Зарегистрируйте участников в программе лояльности",
    awardPoints: "Наградные баллы",
    enroll: "Зарегистрироваться",
    home: "Дом",
    loyalty: "Верность",
    enrollCustomers: "Зарегистрируйте клиентов",
    selected: "Выбрано",
    customer: "Покупатель",
    loyaltyActivationSuccessful: "Активация лояльности прошла успешно.",
    loyaltyDeactivationSuccessful: "Деактивация лояльности прошла успешно.",
    viewTier: "Посмотреть уровень",
    deactivate: "Деактивировать",
    activate: "Активировать",
    actions: "Действия",
    nameOfLoyalty: "Имя верности",
    loyaltyStatus: "Статус лояльности",
    numberOfTiers: "Количество ярусов",
    createdOn: "Создано на",
    createATier: "Создать уровень",
    stopCreatingTierConfirmation: "Вы хотите прекратить создание уровня?",
    stopEditingTierConfirmation:
      "Вы хотите прекратить редактирование этого уровня?",
    nameOfTier: "Название уровня",
    minimumSpendingTarget: "Минимальная цель расходов",
    maximumSpendingTarget: "Максимальный целевой показатель расходов",
    minimumSpendingTargetRequired: "требуется минимальная цель расходов",
    maximumSpendingTargetRequired: "требуется максимальная цель расходов",
    rewardSponsor: "Спонсор вознаграждения",
    pleaseChooseARewardSponsor: "Пожалуйста, выберите спонсора награды",
    self: "Себя",
    partner: "Партнер",
    rewardPartner: "Партнер по вознаграждению",
    pleaseSelectRewardPartner:
      "Пожалуйста, выберите своего партнера по вознаграждению",
    rewards: "Награды",
    pleaseSelectAReward: "Пожалуйста, выберите награду",
    instructionsOnRedeemingReward:
      "Инструкции о том, как клиент должен использовать вознаграждение",
    pleaseFillInThisField: "Пожалуйста, заполните это поле!",
    toDeleteThisTier: " удалить этот уровень",
    deleteTier: "Удалить уровень",
    viewMembers: "Просмотр участников",
    membersEnrolled: "Зарегистрированные участники",
    instruction: "Инструкция",
    membersIn: "Члены в",
    availableTiersInLoyalty: "Доступные уровни в программе лояльности",
    tiers: "Уровни",
    totalTier: "ОБЩИЙ УРОВЕНЬ",
    availableLoyaltyProgramme: "Доступная программа лояльности",
    totalLoyalties: "ПОЛНАЯ ЛОЯЛЬНОСТЬ",
    memberDetails: "Детали участника",
    nameOfCustomer: "Имя Заказчика",
    address: "Адрес",
    allEnrolledMembers: "Все зарегистрированные участники",
    thisIsToWishYouHappyBirthday:
      "Желаю вам счастливого дня рождения и благополучия. Спасибо за все, что вы делаете Loyster. С праздником!",
    inputAnOfferPlease: "Введите предложение, пожалуйста",
    pleaseSelectTheInsertPoint:
      "Выберите точку вставки в сообщении и щелкните еще раз.",
    birthdayOfferAndMessage: "Предложение на день рождения и сообщение",
    birthdayOffer: "Предложение на день рождения",
    birthdayMessage: "Сообщение Дня Рождения",
    noOfferFound: "Предложение не найдено",
    settingABirthdayOffer:
      "Настройка предложения на день рождения позволяет клиентам получать это предложение по SMS в свой день рождения.",
    createOffer: "Создать предложение",
    whatIsTheOffer: "Какое предложение?",
    editMessage: "Редактировать сообщение",
    insert: "Вставлять",
    theNameOfCustomerInserted: "Здесь будет указано имя клиента.",
    theBirtdayOfferInserted: "Предложение дня рождения будет вставлено здесь",
    youSuccessfullyAddedNewBranch: "Вы успешно добавили новую ветку!",
    addNewBranch: "Добавить новую ветку",
    addBranch: "Добавить филиал",
    additionalBranchWillIncur: "Дополнительное отделение повлечет за собой",
    perBranch: "на филиал",
    ecommerceBranchCosts: "Затраты на отделение электронной торговли",
    pleaseEnterBranchName: "Пожалуйста, введите название отделения",
    pleaseEnterBranchAddress1: "Введите адресную строку отделения 1",
    enterBranchAddress1: "Введите адрес филиала в строке 1",
    enterBranchAddress2: "Введите адрес филиала в строке 1",
    pleaseEnterBranchAddress2: "Пожалуйста, введите адрес филиала в строке 2",
    enterBranchName: "Введите название ветки",
    successfullyAddedStaff: "Вы успешно добавили новый состав!",
    addNewStaff: "Добавить новый персонал",
    addStaff: "Добавить персонал",
    additionalStaffWillIncur: "Дополнительный персонал понесет",
    perStaff: "на персонал.",
    pleaseEnterStaffEmail:
      "Пожалуйста, введите адрес электронной почты сотрудников",
    pleaseEnterStaffUsername: "Пожалуйста, введите имя пользователя персонала",
    pleaseEnterStaffPassword: "Пожалуйста, введите пароль персонала",
    pleaseSelectStaffRole: "Пожалуйста, выберите роль персонала",
    selectStaffRole: "Выберите роль персонала",
    enterStaffEmail: "Введите адрес электронной почты персонала",
    enterStaffUsername: "Введите имя пользователя персонала",
    enterStaffPassword: "Введите пароль персонала",
    spacesNotAllowedInUsername:
      "в имени пользователя нельзя использовать пробелы",
    admin: "Админ",
    pleaseSelectBusinessToAttachStaff:
      "Пожалуйста, выберите компанию, чтобы прикрепить персонал",
    searchForBranchToAttachStaff: "Найдите филиал, чтобы прикрепить персонал",
    username: "Имя пользователя",
    role: "Роль",
    areYouSureToDeleteThis: "Вы уверены, что удалите это",
    branches: "ветви",
    upgradeYourPlan: "Обновите свой план.",
    add: "ДОБАВИТЬ",
    addNew: "Добавить новое",
    customerWithEmailAlreadyExists:
      "Клиент с адресом электронной почты / номером телефона уже существует!",
    successfullyAddedNewCustomer: "Вы успешно добавили нового клиента!",
    addCustomer: "Добавить клиента",
    pleaseEnterCustomerFirstName: "Пожалуйста, введите имя клиента",
    pleaseEnterCustomerLastName: "Пожалуйста, введите фамилию клиента",
    pleaseEnterCustomerPhoneNumber:
      "Пожалуйста, введите номер телефона клиента",
    pleaseEnterCustomerEmail:
      "Пожалуйста, введите адрес электронной почты клиента",
    pleaseEnterCustomerAddressLine:
      "Пожалуйста, введите адресную строку клиента",
    pleaseEnterCustomerOtherAddress: "Пожалуйста, введите другой адрес клиента",
    pleaseSelectCustomerGender: "Пожалуйста, выберите пол клиента",
    gender: "Пол",
    male: "Мужчина",
    female: "женский",
    bank: "банк",
    selectBank: "Выберите банк",
    stateOrRegionOrProvince: "Штат / регион / провинция",
    customerNotes: "Примечания для клиентов",
    sendSms: "Отправить смс",
    editCustomer: "Изменить клиента",
    redeemReward: "Погасить награду",
    issueLoyaltyCard: "Выдать карту лояльности",
    deleteCustomer: "Удалить клиента",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Вы успешно назначили идентификатор участника программы лояльности",
    noMembershipIdAvailable:
      "Нет доступных идентификаторов членства. Свяжитесь с нами по адресу hello@loystar.co",
    sendEmail: "Отправить электронное письмо",
    membershipPoints: "Очки членства",
    customerDetails: "Подробная информация о клиенте",
    close: "Закрывать",
    loyaltyBalance: "Баланс лояльности",
    pointsBalance: "Баланс баллов",
    starBalance: "Звездный баланс",
    requiredPoints: "Обязательные баллы",
    requiredStars: "Обязательные звезды",
    reddemCode: "Погасить код",
    toDeleteThisCustomer: "удалить этого клиента",
    customerHasBeenDeletedSuccessfully: "Клиент успешно удален!",
    customerWithPhoneAlreadyExists: "Клиент с номером телефона уже существует",
    customerWasSuccessfullyEdited: "Заказчик успешно отредактирован",
    anErrorOccured: "Произошла ошибка",
    phoneNumberIncludeCountry: "Номер телефона (включая код страны)",
    yourFileQueuedForUpload:
      "Ваш файл поставлен в очередь на загрузку. Обновите страницу через несколько секунд.",
    thereWasAnErrorPerformingOperation:
      "Произошла ошибка при выполнении операции!",
    pleaseSelectFile: "Пожалуйста, выберите файл!",
    oopsOnlyCsvFilesAllowed:
      "Ой! Разрешены только файлы CSV. Загрузите файл .csv.",
    fileShouldBeSmallerThan5Mb:
      "Размер файла не должен превышать 5 МБ. Если у вас есть файл большего размера, напишите нам по адресу support@loystar.co. Спасибо",
    customerFirstNameIsRequired: "имя клиента необходимо в строке",
    customerPhoneNumberIsRequired: "номер телефона клиента требуется в строке",
    importCustomers: "Импортные клиенты",
    upload: "Загрузить",
    clickIconToDownloadCsv:
      "Щелкните этот значок, чтобы загрузить шаблон файла CSV.",
    getGoogleSheetVersion: "Получить версию Google таблицы",
    clickOrDragFileToUpload:
      "Щелкните или перетащите файл в эту область, чтобы загрузить",
    missingOrInvalidColumnHeader:
      "Заголовок столбца отсутствует или недействителен. Пожалуйста, следуйте формату шаблона. Спасибо.",
    youHaveImported: "Вы импортировали",
    youSuccessfullyRedeemedReward: "Вы успешно получили свою награду!",
    sixDigitCode: "Шестизначный код",
    pleaseEnterCustomerRewardCode:
      "Пожалуйста, введите код вознаграждения клиента",
    enterRewardCode:
      "Введите код вознаграждения. (Код вознаграждения чувствителен к регистру).",
    selectLoyaltyProgram: "Выберите программу лояльности",
    stamps: "Марки",
    smsUnitsLow: "Количество SMS-сообщений мало",
    pleaseTopUpSmsUnits: "Пожалуйста, пополните СМС-единицы",
    smsSuccessfullySentForDelivery: "СМС успешно отправлено на доставку!",
    sendSmsTo: "Отправить SMS на",
    allCustomers: "Все клиенты",
    unitsAvailable: "Доступные единицы",
    pleaseTypeInTheMessage: "Пожалуйста, введите сообщение",
    message: "Сообщение",
    charactersRemaining: "оставшиеся символы",
    avoidUseOfSpecialCharacters:
      "Избегайте использования специальных символов и эмодзи для экономии единиц SMS.",
    note: "Примечание",
    errorFetchingCustomersMultilevelDetail:
      "Ошибка при получении многоуровневой информации о клиентах",
    search: "Поиск",
    reset: "Сброс настроек",
    importCustomer: "Импортирующий клиент",
    addNewCustomer: "Добавить нового клиента",
    sendSmsBroadcast: "Отправить SMS-рассылку",
    totalCustomers: "ВСЕГО КЛИЕНТОВ",
    disbursementDetails: "Детали выплаты",
    paidBy: "Оплачивается",
    disbursed: "Выплачено",
    bankAccountName: "Имя банковского счета",
    bankAccountNumber: "Номер банковского счета",
    transferInitiated: "Передача инициирована",
    transferCompleted: "Перевод завершен",
    pleaseEnterAValid: "Пожалуйста, введите правильный",
    begin: "начинать",
    end: "конец",
    date: "Дата",
    paymentDate: "Дата платежа",
    selectDisbursementDuration: "Выберите продолжительность выплаты",
    totalSettled: "Всего рассчитано",
    totalUnsettled: "Всего не урегулировано",
    toDeleteThisSale: "удалить эту продажу",
    draftSaleDeletedSuccessfully: "Черновик распродажи успешно удален!",
    deleteSale: "Удалить распродажу",
    pleaseTypeInYourTotal:
      "Пожалуйста, введите вашу сумму, чтобы подтвердить удаление",
    billDetails: "Детали счета",
    printBill: "Распечатать счет",
    servedBy: "Обслуживается",
    total: "Общий",
    createdDate: "Дата создания",
    createdTime: "Время создания",
    status: "Положение дел",
    loginSuccessful: "Вход выполнен успешно",
    pleaseWaitWhileWeConnectAccount:
      "Подождите, пока мы подключим вашу учетную запись",
    connectionFailedTryAgain:
      "Ошибка подключения. Пожалуйста, попробуйте еще раз.",
    connectionSuccessful: "Соединение успешно",
    viewDetails: "Посмотреть детали",
    enable: "Давать возможность",
    free: "Бесплатно",
    cost: "Расходы",
    visitWebsite: "Посетить сайт",
    pleaseUpgradeYourPlanToPro:
      "Пожалуйста, обновите свой план до Pro Plus, чтобы включить это приложение",
    connectYourBankAccount:
      "Подключите свой банковский счет, чтобы получать платежи.",
    disable: "Запрещать",
    enableApp: "Включить приложение",
    addNewProductToInvoice: "Добавить новый продукт в счет-фактуру",
    toDeleteThisInvoice: "удалить этот счет-фактуру",
    invoiceDeletedSuccessfully: "Счет успешно удален!",
    deleteInvoice: "Удалить счет-фактуру",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Вы успешно добавили сообщение об оплате счета!",
    pleaseEnterPaymentInstructions: "Пожалуйста, введите платежные инструкции",
    invoiceId: "Идентификатор счета-фактуры",
    paidDate: "Дата оплаты",
    reference: "Ссылка",
    productAdded: "Товар добавлен",
    productOutOfStock: "Товара нет в наличии. Пожалуйста, пополните запасы.",
    totalInvoices: "ИТОГО СЧЕТОВ",
    totalPaidInvoices: "ИТОГО ОПЛАЧЕННЫХ СЧЕТОВ",
    totalUnpaidInvoices: "ИТОГО НЕОПЛАЧЕННЫХ СЧЕТОВ",
    loyaltyProgramDeleted: "Программа лояльности удалена",
    thisLoyaltyProgramDeletedSuccessfully:
      "Эта программа лояльности успешно удалена",
    thisLoyaltyProgramEditedSuccessfully:
      "Эта программа лояльности успешно отредактирована",
    loyaltyProgramAddedSuccessfully: "Программа лояльности успешно добавлена",
    loyaltyProgramEdited: "Программа лояльности изменена",
    loyaltyProgramAdded: "Программа лояльности добавлена",
    loyaltyDetails: "Детали лояльности",
    doYouWantToCloseDialog: "Вы хотите закрыть это диалоговое окно?",
    pleaseEnterLoyaltyName: "Пожалуйста, введите имя вашей лояльности",
    programType: "Тип программы",
    pleaseSelectProgramType: "Пожалуйста, выберите тип программы",
    simplePoints: "Простые точки",
    stampsProgram: "Программа марок",
    threshold: "Порог",
    pleaseEnterLoyaltyThreshold: "Пожалуйста, введите порог лояльности",
    reward: "Награда",
    pleaseEnterLoyaltyReward: "Пожалуйста, введите награду за лояльность",
    totalUserPoints: "Всего пользовательских баллов",
    totalUserStamps: "Всего пользовательских штампов",
    spendingTarget: "Целевой расход",
    spendingTargetHint1:
      "Целевой показатель расходов - это сумма, которую клиент должен потратить, чтобы получить вознаграждение. Стоимость 1 валюты = 1 балл.",
    spendingTargetHint2:
      "Целевое количество марок - это количество марок, которое покупатель должен собрать, чтобы получить вознаграждение. например. 5",
    addNewLoyaltyProgram: "Добавить новую программу лояльности",
    addLoyaltyProgram: "Добавить программу лояльности",
    loyaltyProgramType: "Тип программы лояльности",
    pleaseSelectLoyaltyProgramType:
      "Пожалуйста, выберите тип программы лояльности",
    nameOfProgram: "Название программы",
    pleaseEnterProgramName: "Пожалуйста, введите название программы",
    whatIsTheReward: "Какая награда?",
    egNextPurchaseIsFree: "Например. Следующая покупка бесплатна",
    customerName: "имя покупателя",
    guestCustomer: "Приглашенный клиент",
    orderConfirmedSuccessfully: "Заказ успешно подтвержден",
    orderCancelledSuccessfully: "Заказ успешно отменен",
    confirmOrder: "Подтвердите заказ",
    cancelOrder: "Отменить заказ",
    allOrders: "Все заказы",
    totalOrders: "ИТОГО ЗАКАЗОВ",
    doYouWantToAcceptOrder: "Вы хотите принять этот заказ?",
    doYouWantToCancelOrder: "Вы хотите отменить этот заказ?",
    orderDetails: "Информация для заказа",
    orderCreatedAt: "Заказ создан на",
    supplier: "Поставщик",
    productName: "наименование товара",
    quantity: "Количество",
    unitPrice: "Цена за единицу",
    receivedBy: "Получено",
    reportFrom: "Отчет от",
    totalSupplies: "Всего поставок",
    allRightsReserved: "Все права защищены",
    toDeleteThisTransaction: "чтобы удалить эту транзакцию",
    transactionDeletedSuccessfully:
      "Транзакция успешно удалена. Товар был возвращен в инвентарь.",
    deleteTransaction: "Удалить транзакцию",
    transactionDetails: "Детали транзакции",
    printReceipt: "Распечатать квитанцию",
    channel: "Канал",
    discount: "Скидка",
    profit: "Выгода",
    discountedSales: "Скидки на продажи",
    errorFetchingRecord: "Ошибка при получении записи",
    exportTransactions: "Экспортные операции",
    errorFetchingSalesRecord:
      "Ошибка при получении записи о продажах для экспорта.",
    totalSellingPrice: "Общая продажная цена",
    totalCostPrice: "Общая стоимость",
    appliedDiscount: "Примененная скидка",
    noOfItems: "Кол-во пунктов",
    sales: "Продажи",
    export: "Экспорт",
    totalProfit: "Общая прибыль",
    totalBalanceInPeriod: "Общий баланс за период",
    allTimeSales: "Продажи за все время",
    records: "Записи",
    todaysSales: "Сегодняшние продажи",
    transaction: "сделка",
    youHaveExceededTotalNumberOfProducts:
      "Вы превысили общее количество продуктов, разрешенных вашим планом. Обновите свой план, чтобы получить более высокий лимит.",
    youNeedToHaveLoyaltyProgram:
      "Чтобы использовать эту функцию, у вас должна быть программа лояльности!",
    price: "Цена",
    category: "Категория",
    stockTracking: "Отслеживание запасов",
    stockCount: "Количество запасов",
    taxed: "Облагается налогом",
    originalPrice: "Первоначальная цена",
    costPrice: "Себестоимость",
    unit: "Ед. изм",
    productImage: "Изображение продукта",
    taxRate: "Ставка налога",
    taxType: "Тип налога",
    trackInventory: "Отслеживать инвентарь",
    variants: "Варианты",
    hasVariants: "Есть варианты",
    importProduct: "Импорт продукта",
    exportProducts: "Экспорт продукции",
    addNewProduct: "Добавить новый продукт",
    viewCategory: "Просмотр категории",
    viewSuppliers: "Просмотреть поставщиков",
    receiveInventory: "Получить инвентарь",
    printAllProductsTag: "Распечатать теги всех продуктов",
    deleteAll: "Удалить все",
    totalProducts: "ВСЕГО ПРОДУКТОВ",
    youveSuccessfullyAddedANewCategory: "Вы успешно добавили новую категорию",
    addNewCategory: "Добавить новую категорию",
    addCategory: "Добавить категорию",
    categoryName: "Название категории",
    pleaseEnterCategoryName: "Пожалуйста, введите название категории",
    stampsTarget: "Марки Target",
    sendInventory: "Отправить инвентарь",
    productDetails: "информация о продукте",
    youveSuccessfullyEditedThisCategory:
      "Вы успешно отредактировали эту категорию",
    update: "Обновлять",
    categoryList: "Список категорий",
    categories: "Категории",
    enterQuantityToUpdate: "Введите количество для обновления",
    inventorySentSuccessfully: "Инвентаризация была успешно отправлена!",
    updateInventory: "Обновить инвентарь",
    currentQuantity: "Текущее количество",
    pleaseEnterQuantityToAdd:
      "Пожалуйста, введите количество, которое вы хотите добавить",
    pleaseSelectABranch: "Пожалуйста, выберите филиал",
    searchForBranch: "Искать филиал",
    youCantSendMoreThanStock:
      "Вы не можете отправить больше, чем есть на складе",
    send: "послать",
    pleaseEnterQuantityToSend:
      "Пожалуйста, введите количество, которое вы хотите отправить",
    productNameIsRequiredOnRow: "название продукта требуется в строке",
    productCategoryIsRequiredOnRow: "категория продукта обязательна в строке",
    productPriceIsRequiredOnRow: "цена продукта требуется в строке",
    productUnitIsRequiredOnRow: "единица продукта требуется в строке",
    productQuantityIsRequiredOnRow: "количество продукта требуется в строке",
    productVariantsRequireTracking: "варианты товара требует отслеживания!",
    pleaseAddVariantClickButton:
      "Пожалуйста, добавьте вариант, нажав кнопку добавления!",
    totalVariantsMoreThanSelectedQuantity:
      "Общее количество вариантов превышает выбранное количество продукта, пожалуйста, уменьшите количество вариантов",
    productEditedSuccessfully: "Товар успешно отредактирован!",
    fileTooLargeLessThan4Mb:
      "Размер файла слишком велик! Размер файла не должен превышать 4 МБ.",
    suchVariantAlreadyExist: "Такой вариант уже существует",
    addVariants: "Добавить варианты",
    editProduct: "Редактировать продукт",
    pleaseEnterProductName: "Пожалуйста, введите название продукта",
    pleaseEnterProductPrice: "Пожалуйста, введите цену продукта",
    pleaseEnterProductOriginalPrice:
      "Пожалуйста, введите первоначальную цену продукта",
    productDescription: "Описание товара",
    selectProductCategory: "Выберите категорию продукта",
    pleaseSelectProductCategory: "Пожалуйста, выберите категорию продукта",
    productCostPrice: "Себестоимость продукта",
    productSellingPrice: "Цена продажи продукта",
    productOriginalPrice: "Первоначальная цена продукта",
    maxFileSizeAllowedIs4Mb: "Максимум. Допустимый размер файла 4 МБ.",
    productsWithPicturesArePublished:
      "Товары с изображениями публикуются на сайте Discover для приема заказов.",
    shouldThisProductBeTracked: "Следует ли отслеживать этот продукт?",
    pleaseSelectStockUnit: "Пожалуйста, выберите единицу запаса",
    stockUnit: "Единица запаса",
    bag: "СУМКА",
    bottle: "БУТЫЛКА",
    bunch: "СВЯЗКА",
    can: "ЖЕСТЯНАЯ БАНКА",
    carton: "КАРТОН",
    crate: "ЯЩИК",
    cup: "ЧАШКА",
    dozen: "ДЮЖИНА",
    gigabytes: "ГИГАБАЙТЫ",
    gram: "ГРАММ",
    kilogram: "КИЛОГРАММ",
    litre: "ЛИТР",
    pack: "ПАКЕТ",
    pair: "ПАРА",
    pieces: "ШТ",
    plate: "ПЛАСТИНА",
    tonne: "ТОНН (МТ)",
    uNIT: "ЕД. ИЗМ",
    yard: "ПЛОЩАДКА",
    pleaseEnterProductQuantity: "Пожалуйста, введите количество товара",
    productQuantity: "Количество товара",
    isThisProductTaxed: "Облагается ли этот товар налогом?",
    selectTaxType: "Выберите тип налога",
    pleaseSelectTaxType: "Пожалуйста, выберите Тип налога",
    progressive: "Прогрессивный",
    proportional: "Пропорциональный",
    pleaseEnterProductTaxRate: "Введите ставку налога на товар",
    doesProductHaveVariants: "Есть ли у этого продукта варианты?",
    type: "Тип",
    value: "Ценить",
    pleaseEnterVariantType: "Пожалуйста, введите тип варианта",
    pleaseEnterVariantValue: "Пожалуйста, введите значение варианта",
    pleaseEnterVariantPrice: "Введите цену варианта",
    pleaseEnterVariantQuantity: "Введите количество варианта",
    productDeletedSuccessfully: "Товар был успешно удален!",
    categoryDeletedSuccessfully: "Категория успешно удалена!",
    toDeleteThisProduct: "удалить этот продукт",
    invalidProductQuantity: "Недействительное количество товара",
    quantityAddedIsOverStock:
      "Количество, которое вы добавляете, превышает то, что у вас есть на складе.",
    itemInventoryNotTracked: "Инвентаризация предметов не отслеживается",
    addBulkQuantity: "Добавить оптовое количество",
    enterBulkQuantity: "Введите оптовое количество",
    pleaseEnterBulkQuantity: "Пожалуйста, введите оптовое количество",
    youveSuccessfullyAddedANewProduct: "Вы успешно добавили новый товар!",
    pleaseEnterProductSellingPrice:
      "Пожалуйста, введите отпускную цену продукта",
    doYouWantToTrackProductStock: "Вы хотите отслеживать товарные запасы?",
    sellingPrice: "Цена продажи",
    setProductExpiryReminder:
      "Установить напоминание об истечении срока годности продукта",
    productExpiryDate: "Срок годности продукта",
    startRemindingFrom: "Начать напоминание с",
    productSuppliesAddedSuccessfully:
      "Вы успешно добавили расходные материалы.",
    addProductSupplies: "Добавить товары",
    pleaseSelectSupplier: "Пожалуйста, выберите поставщика",
    nameOfProduct: "Название продукта",
    pleaseSelectProduct: "Пожалуйста, выберите продукт",
    productVariant: "Вариант продукта",
    pleaseSelectAVariant: "Пожалуйста, выберите вариант",
    pleaseEnterUnitPrice: "Пожалуйста, введите цену за единицу",
    businessBranch: "Бизнес-отрасль",
    pleaseSelectBranch: "Пожалуйста, выберите филиал",
    youveSuccessfullyAddedANewSupplier: "Вы успешно добавили нового поставщика",
    addSupplier: "Добавить поставщика",
    pleaseEnterSupplierEmail:
      "Пожалуйста, введите адрес электронной почты поставщика",
    pleaseAddADescription: "Добавьте описание",
    anErrorOccuredProductsDeleted:
      "Произошла ошибка при выполнении операции. Обратите внимание: в процессе могло быть удалено несколько продуктов.",
    bulkDelete: "Массовое удаление",
    youAreAboutToDelete: "Вы собираетесь удалить",
    product: "Продукт",
    isDueToRedeem: "подлежит выкупу",
    aReward: "награда",
    pleaseSelectCustomerToReeemReward:
      "Выберите клиента, которого хотите получить за вознаграждение.",
    youHaveNoLoyaltyProgramRunning: "У вас нет активной программы лояльности",
    customerHhasNoPointsInLoyaltyProgram:
      "У клиента нет баллов в этой программе лояльности",
    proceedWithPayment: "Продолжить оплату?",
    youAreAboutToPayForTransactionUsingPoints:
      "Вы собираетесь оплатить транзакцию баллами.",
    customerHas: "Клиент имеет",
    worth: "стоимость",
    andIsNotSufficientToPayForTransaction:
      "и этого недостаточно для оплаты этой транзакции. Хотели бы они пополнить баланс другим способом оплаты?",
    addCustomerLoyalty: "Добавить лояльность клиентов",
    pleaseAddCustomerFirst:
      "Пожалуйста, сначала добавьте или выберите клиента.",
    pleaseWaitWhileWeGetReady: "Подождите, пока мы будем готовы",
    lowStock: "Низкий запас",
    pleaseEnterAmountTendered: "Пожалуйста, введите выставленную сумму",
    areYouSureToBookSaleOffline:
      "Вы уверены, что хотите забронировать эту распродажу в офлайн-режиме?",
    saleWouldBeBookedAutomatically:
      "Продажа будет забронирована автоматически, когда вы включите Интернет.",
    offlineSalesBookingCancelled: "Бронирование офлайн-продаж отменено",
    covid19Message:
      "COVID19: Вымойте руки с мылом или проведите дезинфекцию, чтобы остановить распространение. Оставайся в безопасности всегда",
    saleSuccessfullyRecorded: "Продажа успешно зарегистрирована!",
    sendReceiptToEmail: "Отправить квитанцию на электронную почту",
    sendThankYouSms: "Отправить SMS с благодарностью",
    sendShippingDetails: "Отправить детали доставки",
    addLoyalty: "Добавить лояльность",
    searchCustomerNameOrNumber: "Поиск по имени или номеру клиента",
    clickTheSearchCustomerBox:
      "Щелкните поле поиска клиента и отсканируйте карту.",
    enterProductPrice: "Введите цену продукта",
    enterPriceFor: "Введите цену для",
    searchForProduct: "Искать продукт",
    all: "Все",
    backToDashboard: "Вернуться в Личный кабинет",
    viewDraftSales: "Посмотреть черновики продаж",
    variantSelected: "вариант выбран",
    variant: "вариант",
    thePreviousVariantSelectionNotAvailable:
      "Предыдущий выбор варианта недоступен для нового варианта, выбранного на основе цены, пожалуйста, измените свой выбор.",
    pleaseSupplyPositiveQuantityNumber:
      "Пожалуйста, укажите положительный количественный номер",
    lowStockFor: "Низкий запас для",
    clearVariants: "Очистить варианты",
    pleaseEnterQuantity: "Пожалуйста, введите количество",
    picture: "Рисунок",
    redemptionToken: "Жетон погашения",
    token: "Токен",
    totalSpent: "Всего потрачено",
    confirmPayment: "Подтвердите оплату",
    hasPaymentBeenMade: "Успешно ли обработан платеж?",
    enterTransactionReference: "Введите номер транзакции, которой вы заплатили",
    pleaseSelectACustomer: "Пожалуйста, выберите клиента!",
    areYouSureToApplyDiscount: "Вы уверены, что хотите применить скидку?",
    addYourBankAccountToEnableUssd:
      "Добавьте свой банковский счет, чтобы включить мгновенный USSD-перевод через uPay",
    totalAmountToPay: "Общая сумма к оплате",
    doYouWantToCancelTransaction: "Вы хотите отменить эту транзакцию?",
    savePrintBill: "Сохранить / распечатать счет",
    enterAmountCollectedForCustomer: "Введите сумму, полученную для клиента",
    recordSale: "Рекордная распродажа",
    checkOutWith: "Проверить с",
    instantTransfer: "Мгновенный перевод",
    dialTheUSSDCode: "Наберите USSD код",
    pleaseSelectABank: "Пожалуйста, выберите банк",
    payWithUSSD: "Оплата с помощью USSD",
    sendBillTo: " - Отправить счет",
    waitingForUSSDTransfer: "Ожидание USSD-перевода",
    percent: "Процентов",
    applyDiscount: "Применить скидку",
    thisBillHasBeenSaved: "Этот счет был сохранен",
    saveDraft: "Сохранить черновик",
    pleaseTypeANameToIdentifyCustomer:
      "Введите имя, чтобы идентифицировать клиента",
    paymentDetails: "Детали оплаты",
    basePrice: "Базовая цена",
    staff: "Сотрудники",
    subTotal: "Промежуточный итог",
    durationMonths: "Продолжительность (мес.)",
    selectADuration: "Выберите продолжительность",
    oneMonth: "1 месяц",
    twoMonths: "2 месяца",
    threeMonths: "3 месяца",
    sixMonths: "6 месяцев",
    twelveMonths: "12 месяцев",
    eighteenMonths: "18 месяцев",
    twentyFourMonths: "24 месяца",
    twoMonthsFree: "(2 месяца бесплатно)",
    threeMonthsFree: "(3 месяца бесплатно)",
    fiveMonthsFree: "(5 месяцев бесплатно)",
    yourAccountHasBeen: "Ваша учетная запись была",
    renewed: "обновленный",
    upgraded: "улучшенный",
    successfully: "успешно",
    yourSubscription: "Ваша подписка",
    youAreCurrentlyEnrolledOnA: "В настоящее время вы зарегистрированы на",
    pleaseChooseAPaymentOption: "Пожалуйста, выберите способ оплаты",
    planRenewal: "Продление плана",
    planUpgrade: "План обновления",
    pleaseSelectDurationToPayFor:
      "Пожалуйста, выберите продолжительность, за которую вы собираетесь заплатить",
    staffAccounts: "Учетные записи персонала",
    ecommerce: "Электронная торговля",
    pleaseSelectAPlan: "Пожалуйста, выберите план",
    includeAddons: "Включить дополнения",
    viewTransactions: "Просмотр транзакций",
    customerHasNoCompletedTansactions:
      "У клиента еще нет завершенных транзакций",
    branch: "Ветвь",
    enterNumberOfEcommerceBranches:
      "Введите количество филиалов электронной торговли",
    enterNumberOfEcommerceBranchesToPay:
      "Введите количество отделений электронной торговли, за которые вы собираетесь платить",
    ecommerceIntegration: "Интеграция электронной коммерции",
    enterNumberOfBranches: "Введите количество филиалов",
    enterNumberOfAdditionalBranchesToPay:
      "Введите количество дополнительных филиалов, за которые вы собираетесь платить",
    enterNumberOfStaffs: "Введите количество посохов",
    enterNumberOfStaffsToPayFor:
      "Введите количество сотрудников, за которые вы собираетесь платить",
    discountApplies: "Скидка действует",
    starsOnThe: "звезды на",
    offer: "предложение",
    get: "Получать",
    moreStarsToRedeem: "больше звезд для погашения",
    taxVat: "Налог (НДС)",
    callCashierToCompletePayment: "Позвоните в кассу для завершения платежа",
    receipt: "Чек",
    dear: "дорогая",
    thankYouForYourOrderFindGoods:
      "Спасибо за ваш заказ. По согласованию, пожалуйста, найдите следующие поставляемые товары.",
    shippingNote: "Примечание о доставке",
    enterShippingNote: "Введите накладную",
    shippingAddress: "Адреса доставки",
    enterShippingAddress: "Введите адрес доставки",
    wellDoneYouAreDueToRedeem: "Отличная работа! Вы должны выкупить",
    toGetYourRewardNextVisit:
      "чтобы получить вознаграждение при следующем посещении. Спасибо",
    pointsOnThe: "Очки на",
    morePointsToRedeem: "больше очков для погашения",
    showCode: "Показать код",
    toGetYourRewardOnNextVisit:
      "чтобы получить вознаграждение при следующем посещении. Спасибо",
    earn: "Зарабатывать",
    delivaryNote: "Сопроводительная записка",
    draftSales: "Черновик продаж",
    startDate: "Дата начала",
    endDate: "Дата окончания",
    orders: "Заказы",
    checkout: "проверить",
    noProductItem: "Нет товара",
    selectProductImage: "Выберите изображение продукта",
    selectCountry: "Выберите страну",
    selectRegion: "Выберите штат / регион",
    printProductTag: "Распечатать бирку продукта",
    transactionReference: "Ссылка на транзакцию",
    Cashier: "Касса",
    Manager: "Менеджер",
    Owner: "Владелец",
    Admin: "Админ",
    addPartners: "Добавить партнеров",
    addNewLoyaltyPartner: "Добавить нового лояльного партнера",
    pleaseEnterCompanyName: "Пожалуйста, введите название компании",
    companyName: "Название компании",
    pleaseEnterCompanyRepName: "Пожалуйста, введите имя представителя компании",
    companyRepName: "Имя представителя компании",
    pleaseEnterCompanyRepEmail:
      "Пожалуйста, введите адрес электронной почты представителя компании",
    companyRepEmail: "Электронная почта представителя компании",
    pleaseEnterCompanyRepPhone:
      "Пожалуйста, введите номер телефона представителя компании",
    companyRepPhone: "Номер телефона представителя компании",
    addReward: "Добавить награду",
    pleaseEnterRewardName: "Пожалуйста, введите название награды",
    rewardName: "Название награды",
    rewardQuantity: "Количество награды",
    rewardDescription: "Описание награды",
    rewardType: "Тип награды",
    pleaseEnterRewardType: "Пожалуйста, введите тип вознаграждения",
    pleaseEnterRewardQuantity: "Пожалуйста, введите количество вознаграждения",
    pleaseEnterRewardDescription: "Пожалуйста, введите описание награды",
    fineDining: "Изысканный обед",
    luxuryFashion: "Роскошная мода",
    hotels: "Отели",
    travel: "Путешествовать",
    foodAndBeverage: "Еда и напитки",
    fashion: "Мода",
    health: "Здоровье",
    furniture: "Мебель",
    entertainment: "Развлечение",
    automobile: "Автомобиль",
    education: "Образование",
    beautyAndSpa: "Красота и СПА",
    staycation: "Staycation",
    events: "События",
    trips: "Поездки",
    oilAndGas: "Нефти и газа",
    laundry: "Прачечная",
    partnerCategory: "Категория партнера",
    freeItem: "Бесплатный предмет",
  },
  Sesotho: {
    cashier: "mokoki",
    manager: "mookameli",
    owner: "monga",
    online: "Inthaneteng",
    offline: ", offline",
    changePassword: "Fetola senotlolo",
    currentPasswordMessage: "Ka kopo kenya phasewete ea hau ea hajoale",
    passwordMessage: "Ka kopo kenya phasewete ea hau",
    currentPassword: "Senotlolo sa Hajoale",
    password: "phasewete",
    confirmPasswordMessage: "Ka kopo netefatsa phasewete ea hau!",
    confirmPassword: "Netefatsa Phasewete",
    sendViaEmail: "Romella ka Imeile",
    sendViaWhatsapp: "Romella ka WhatsApp",
    downloadPdf: "Khoasolla PDF",
    paid: "lefshoa",
    unpaid: "sa lefuoeng",
    partial: "ka leeme",
    closeInvoice: "O batla ho koala Invoice?",
    closeInvoiceConfirmation:
      "Invoice e kanna ea se bolokoe. O batla ho koala?",
    yes: "ee",
    no: "che",
    invoice: "Invoice",
    wasDeducted: "e tlositsoe",
    for: "bakeng sa",
    item: "Ntho",
    addProduct: "Kenya Sehlahisoa",
    paymentReference: "Tšupiso ea Tefo",
    amountPaid: "Chelete e Patiloeng",
    discountAmount: "Chelete ea theolelo",
    amountDue: "Chelete e lokelang ho lefuoa",
    amount: "Chelete",
    dueDate: "Letsatsi la tebello",
    paymentType: "Mofuta oa Tefo",
    card: "Karete",
    cash: "Chelete",
    bankTransfer: "Ho fetisetsa banka",
    paymentMessage: "Molaetsa oa Tefo",
    description: "Tlhaloso",
    sendReceipt: "Romella rasiti",
    delete: "Tlosa",
    save: "Boloka",
    resend: "Romella hape",
    saveAndSend: "Boloka",
    invoiceSaved: "Invoice e bolokiloe!",
    selectPaymentMethod: "Ka kopo khetha Mokhoa oa Tefo!",
    selectCustomer: "Ka kopo khetha Moreki!",
    cartEmptyError:
      "Lethathamo la Likoloi li ka se be letho, koala invoice ebe u eketsa Ntho ho kariki!",
    subscriptionExpired:
      "Peeletso ea hau e felile 'me ak'haonte ea hau e se e lekantsoe. Tobetsa ho Konopo e ka tlase ho nchafatsa ak'haonte ea hau",
    renew: "Nchafatsa",
    holdOn: "Tiisetsa",
    customerBank: "Banka ea bareki",
    cancel: "Hlakola",
    selectACustomer: "Khetha Moreki",
    invoiceSuccessfulPdfError:
      "Invoice e entsoe ka katleho empa PDF Generation e nka nako e telele ho feta tloaelo. Ka kopo sheba haufinyane.",
    downloadingInvoice: "Khoasolla Invoice",
    downloadingReceipt: "Khoasolla Risiti",
    whatsappReceiptError:
      "Ho hlahile phoso ha re romela rasiti ka WhatsApp, ka kopo leka hape.",
    receiptToWhatsapp: "Risiti e fetiselitsoe ho WhatsApp",
    thankYouForPatronage: "Ke leboha thuso ea hau",
    hereIsYourReceipt: "Risiti ea hau ea tefo ke ena",
    errorSendingEmailReceipt:
      "Ho bile le phoso ea ho romela rasiti ka lengolo tsoibila, ka kopo leka hape kapa ikopanye le ba ts'ehetso",
    receiptSentToEmail: "Risiti e rometsoe ho lengolo-tsoibila la moreki",
    invoiceSentToEmail: "Invoice e rometsoe ho imeile ea bareki",
    invoiceSuccessWhatsappError:
      "Invoice e entsoe ka katleho empa ho bile le phoso ha e romelloa ho WhatsApp. Leka hape lenaneng la invoice",
    invoiceSuccessfulEmailError:
      "Invoice e entsoe ka katleho empa ho bile le phoso ha re ntse re romella lengolo tsoibila. Ka kopo leka hape ho tsoa lenaneng la invoice",
    invoiceSentToWhatsapp: "Invoice e fetiselitsoe ho WhatsApp",
    hello: "Lumela",
    pleaseDownloadInvoice: "Ka kopo jarolla invoice",
    pleaseDownloadReceipt: "Ka kopo jarolla rasiti",
    from: "ho tloha",
    email: "Imeile",
    upgrade: "Ntlafatsa",
    youAreOnFreePlan: "U leanong la mahala.",
    clickOn: "Tobetsa ho",
    yourPlanInFewSteps:
      " phano ya hao ya tshebeletso ka mehato e mmalwa e potlakileng.",
    to: "ho",
    yourSubscriptionHasExpired:
      "Peeletso ea hau e felile 'me ak'haonte ea hau e se e lekantsoe.",
    days: "matsatsi",
    yourSubscriptionExpiresIn: "Peeletso ea hau ea Loystar e felloa ke nako",
    createAcount: "Theha ak'haonte",
    signIn: "Kena",
    continue: "Tsoela pele",
    enterOtp: "Kenya PIN ea OTP",
    enterValidOtp: "Ka kopo kenya PIN e nepahetseng",
    pin: "PIN",
    tokenSentToMail: "Letšoao le rometsoe ho lengolo-tsoibila la hau",
    passwordResetSuccessful: "Ho seta phasewete ho atlehile",
    somethingWentWrong: "Ho na le phoso e etsahetseng!",
    resetPassword: "Khutlisa phasewete",
    iHaveResetCode: "Ke na le phasewete ea ho seta phasewete hape",
    pleaseEnterEmail: "Ka kopo kenya lengolo tsoibila la hau",
    aTokenWillBeSentToEmail:
      "Letšoao le tla romelloa ho lengolo tsoibila la hau",
    enterEmail: "Kenya imeile ea hau",
    sendinYourToken: "Re romella letshwao la hau ...",
    makeSureItMatchesPassword:
      "Etsa bonnete ba hore e tšoana le phasewete ea hau e ncha",
    pleaseChooseNewPassword: "Ka kopo khetha phasewete e ncha",
    chooseNewPassword: "Khetha phasewete e ncha",
    enterNewPassword: "Kenya phasewete ea hau e ncha ho netefatsa",
    enterTokenSent: "Kenya letšoao le rometsoeng mangolo a hau",
    resetToken: "Khutlisa Letšoao",
    resettingPassword: "Ho seta phasewete ea hau ...",
    signUp: "Ngolisa",
    adminSignInWithEmail:
      " Tsamaiso e kena ka imeile ha Basebetsi ba saena ka lebitso la mosebelisi.",
    pleaseEnterEmailOrUsername:
      "Ka kopo kenya lengolo tsoibila la hau kapa lebitso la hau la mosebelisi",
    emailOrUsername: "Imeile kapa Username",
    pleaseEnterPassword: "Ka kopo kenya phasewete",
    createAnAccount: "Theha ak'haonte",
    forgotPassword: "U lebetse phasewete?",
    enterPhoneNumber: "Kenya Nomoro ea Mohala",
    personalData: "Lintlha tsa Botho",
    validateConfirmationCOde: "Netefatsa Khoutu ea Netefatso",
    pleaseEnterFirstName: "Ka kopo kenya lebitso la hau la pele",
    pleaseEnterPhoneNumber: "Ka kopo kenya nomoro ea hau ea mohala",
    pleaseEnterLastName: "Ka kopo kenya fane ea hau",
    pleaseEnterBusinessName: "Ka kopo kenya lebitso la khoebo ea hau",
    firstName: "Lebitso la pele",
    lastName: "Fane",
    businessName: "Lebitso la Khoebo",
    previous: "E fetileng",
    next: "E 'ngoe",
    pleaseSelectBusinessCategory: "Ka kopo khetha mofuta oa khoebo ea hau",
    pleaseEnterValidEmail: "Ka kopo kenya lengolo tsoibila le nepahetseng",
    pleaseEnterPostCode: "Ka kopo kenya khoutu ea poso",
    postCode: "Khoutu ea poso",
    phoneNumberInUse: "Nomoro ena ea mohala e se e ntse e sebelisoa!",
    emailInUse: "Imeile ena e se ntse e sebelisoa!",
    foodAndDrinks: "Lijo le Lino",
    salonAndBeauty: "Salon le Botle",
    fashionAndAccessories: "Feshene le Lisebelisoa",
    gymAndFitness: "Boikoetliso le Boikoetliso",
    travelAndHotel: "Leeto le Hotele",
    homeAndGifts: "Lapeng le Limpho",
    washingAndCleaning: "Ho hlatsoa le ho hloekisa",
    gadgetsAndElectronics: "Lisebelisoa le Elektroniki",
    groceries: "Lijo tsa korosari",
    others: "Ba bang",
    submit: "Ikokobelletsa",
    accountCreatedSuccessful: "Ak'haonte ea hau e entsoe ka katleho.",
    pleaseEnterAddress: "Ka kopo kenya aterese ea hau",
    addressLine1: "Mola oa Aterese oa 1",
    addressLine2: "Mohala oa Aterese 2",
    choosePassword: "Khetha phasewete",
    passwordMustBe6Characters:
      "Netefete e tlameha ho ba bonyane litlhaku tse 6.",
    howDidYouHearLoystar: "U utloile joang ka Loystar?",
    referralCode: "Khoutu ea phetisetso",
    byClickingTheButton: " Ka ho tobetsa konopo e ka tlase, o lumela ho",
    termsAndSevice: "Lipehelo",
    wereCreatingAccount: "Re theha ak'haonte ea hau",
    proceed: "Tsoela pele",
    verificationCodeMustBe6: "Khoutu ea netefatso e tlameha ho ba lipalo tse 6",
    pleaseEnter6DigitCode: "Ka kopo kenya khoutu ea linomoro tse 6",
    enterVerificationCode: "Kenya Khoutu ea netefatso",
    resendToken: "Romella Letšoao hape",
    verify: "Netefatsa",
    transactions: "Litšebelisano",
    todaySales: "Thekiso ea Kajeno",
    salesHistory: "Nalane ea Thekiso",
    supplyHistory: "Nalane ea Phepelo",
    new: "E ncha",
    invoices: "Invoice",
    disbursements: "Lits'enyehelo",
    offlineSales: "Khoebo ea Offline",
    products: "Lihlahisoa",
    customers: "Bareki",
    multiLevelLoyalty: "Botšepehi ba Maemo a Mangata",
    loyaltyPrograms: "Mananeo a Botšepehi",
    members: "Litho",
    appStore: "Lebenkele la App",
    orderMenu: "Order Menu",
    settings: "Litlhophiso",
    staffAndBranches: "Basebetsi le Makala",
    myAccount: "Akhaonte Ea Hau",
    switchToSellMode: "Fetohela ho Sell Mode",
    signOut: "Tsoaha",
    getFreeSubscription: "Fumana peeletso ea mahala",
    onlyNumbersAllowed: "Ke linomoro feela tse lumelloang",
    yourAccountMustBe10Digits:
      "nomoro ea hau ea ak'haonte e tlameha ho ba nomoro ea linomoro tse 10",
    yourBvnMustBe11: "BVN ea hau e tlameha ho ba nomoro ea linomoro tse 11",
    pleaseSelectBank: "Ka kopo khetha banka ea hau",
    selectYourBank: "Khetha Banka ea hau",
    enterBankAccountNumber: "Kenya Nomoro ea Ak'haonte ea Banka",
    enterBvnNumber: "Kenya BVN ea hau",
    connectBankAccount: "Kopanya Ak'haonte ea Banka",
    passwordResetSuccessfulAndSignOut:
      "Phasewete ya hao e setilwe botjha ka tsela e atlehileng. Tobetsa konopo ea Sign Out ka tlase ho saena hape",
    enterCurrentPassword: "Kenya password ea hajoale",
    pleaseEnterCurrentPassword: "Ka kopo kenya phasewete ea hajoale",
    phoneNumber: "Nomoro ea mohala",
    sex: "Thobalano",
    dateOfBirth: "Letsatsi la tsoalo",
    state: "Boemo",
    country: "Naha",
    loyaltyId: "Botšepehi ID",
    createdAt: "E entsoe ka",
    noOfTransactionsMade: "Che ea litšebelisano tse entsoeng",
    yourDownloadWillStart: "Khoasolla li tla qala ka motsotsoana",
    exportCustomers: "Barekisi ba Export",
    youhaveSuccessfullyToppedUp:
      "U atlehile ho phahamisa li-Units tsa hau tsa SMS.",
    youNowHave: "Joale u na le eona",
    smsUnits: "Liyuniti tsa SMS",
    enterNumberOfSmsUnits:
      "Kenya Nomoro ea Li-unit tsa SMS tseo u ikemiselitseng ho li reka",
    pleaseEnterANumericValue: "Ka kopo kenya boleng ba linomoro",
    pay: "Lefa",
    accountEditedSuccessfully: "Ak'haonte ea hau e fetotsoe ka katleho.",
    youAeCurrentlyOn: "U se u ntse u tsoetse pele",
    plan: "Moralo",
    userData: "Lintlha tsa mosebelisi",
    businessData: "DATA EA BOPHELO",
    businessCategory: "Khoebo ea Khoebo",
    pleaseSelectCurrency: "Ka kopo khetha chelete ea hau",
    selectYourCurrency: "Khetha chelete ea hau",
    purchaseMoreSmsUnits:
      "Reka li-Units tse ling tsa SMS u sebelisa foromo e ka tlase",
    youHave: "U na le",
    atLeast4SmsUnitsRrequired:
      "Ho hlokahala bonyane li-sms tsa 4 bakeng sa netefatso, ka kopo holimo!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ka kopo netefatsa ak'haonte ea hau ea banka ho latela litlhoko tsa 'Tseba Moreki oa Hau' (KYC). Sena se tla u lumella ho bokella litefo ka USSD kapa ho fetisetsa hanghang, ho amohela liotara ho tsoa ho bareki le ho sebetsana le tefo ea thekiso. E sebetsa ho barekisi ba Nigeria feela. Ka kopo, tobetsa konopo e ka tlase ho qala.",
    reConnectBankAccount: "Kopanya Ak'haonte ea Banka hape",
    accountName: "Lebitso la ak'haonte",
    accountNumber: "Nomoro ea ak'haonte",
    bankName: "Lebitso la Banka",
    verified: "E netefalitsoe",
    accountDetails: "Lintlha tsa Ak'haonte",
    kycBankAccount: "KYC",
    createTier: "Theha Tier",
    fileUploadSuccessful: "file e kentsoe ka katleho",
    fileUploadFailed: "file file e hlotsoe",
    createLoyaltyProgram: "Theha Lenaneo la Botšepehi",
    createLoyalty: "Etsa Botšepehi",
    name: "Lebitso",
    loyaltyArtwork: "Botšepehi Litšoantšo",
    clickToUpload: "Tobetsa ho kenya",
    amountToPointsRatio: "Palo ea Points Ratio",
    points: "Lintlha",
    recommendedAmountToPointRatio:
      "E khothalelitsoe: ₦ 1 ho 1point. ke hore Naira e ngoe le e ngoe e sebelisitsoeng, bareki ba hau ba fumana lintlha tse 1",
    pleaseTypeIn: "Ka kopo tlanya",
    toDeleteLoyalty: "ho hlakola botšepehi bona",
    deleteLoyalty: "Tlosa Botšepehi",
    toConfirm: "ho netefatsa",
    edit: "Edita",
    pointsAwardedSuccessfully: "Lintlha li fanoa ka katleho.",
    enterPointValueToAward:
      "Kenya boleng ba ntlha eo u ka ratang ho bo fa bareki",
    award: "Moputso",
    awardPointValuesToCustomer: "Boleng ba Poelo ea Moputso ho Moreki",
    enrollMembersToLoyalty: "Ngolisa Litho ho Botšepehi",
    awardPoints: "Lintlha tsa Moputso",
    enroll: "Ngolisa",
    home: "Lapeng",
    loyalty: "Botšepehi",
    enrollCustomers: "Ngolisa Bareki",
    selected: "E khethiloe",
    customer: "Moreki",
    loyaltyActivationSuccessful: "Ts'ebetso ea botšepehi e atlehile.",
    loyaltyDeactivationSuccessful: "Ho sa sebetse ka botšepehi ho atlehile.",
    viewTier: "Sheba boemo",
    deactivate: "Tlosa-sebetsa",
    activate: "Sebelisa",
    actions: "Liketso",
    nameOfLoyalty: "Lebitso la Botšepehi",
    loyaltyStatus: "Boemo ba Botšepehi Boemo ba Botšepehi",
    numberOfTiers: "Palo ea literene",
    createdOn: "Bōpiloe On",
    createATier: "Theha Karolo",
    stopCreatingTierConfirmation: "Na u batla ho emisa ho theha sehlopha?",
    stopEditingTierConfirmation: "O batla ho emisa ho hlophisa mohato ona?",
    nameOfTier: "Lebitso la Tier",
    minimumSpendingTarget: "Bonyane ba Target ea Chelete",
    maximumSpendingTarget: "Palo e kahodimodimo ea Chelete",
    minimumSpendingTargetRequired:
      "sepheo se tlase sa tšebeliso ea chelete sea hlokahala",
    maximumSpendingTargetRequired:
      "Lits'enyehelo tse phahameng tsa tšebeliso ea chelete lia hlokahala",
    rewardSponsor: "Mofani oa Moputso",
    pleaseChooseARewardSponsor: "Ka kopo khetha mofani oa moputso",
    self: "Boithati",
    partner: "Molekane",
    rewardPartner: "Molekane Molekane",
    pleaseSelectRewardPartner: "Ka kopo khetha molekane oa hau oa moputso",
    rewards: "Meputso",
    pleaseSelectAReward: "Ka kopo khetha moputso",
    instructionsOnRedeemingReward:
      "Litaelo tsa kamoo Moreki a Lokelang ho Lopolla Moputso",
    pleaseFillInThisField: "Ka kopo Tlatsa sebakeng sena!",
    toDeleteThisTier: " ho hlakola lehlakore lena",
    deleteTier: "Tlosa mohato",
    viewMembers: "Sheba Litho",
    membersEnrolled: "Litho li ngolisitsoe",
    instruction: "Thupelo",
    membersIn: "Litho tsa",
    availableTiersInLoyalty: "Likarolo tse fumanehang ho Lenaneo la Botšepehi",
    tiers: "Literene",
    totalTier: "TIEREKI SEBELE",
    availableLoyaltyProgramme: "Lenaneo le fumanehang la Botšepehi",
    totalLoyalties: "POTLAKO TSOHLE",
    memberDetails: "Lintlha tsa setho",
    nameOfCustomer: "Lebitso la Moreki",
    address: "Aterese",
    allEnrolledMembers: "Litho tsohle tse ngolisitsoeng",
    thisIsToWishYouHappyBirthday:
      "Sena ke ho u lakaletsa letsatsi le monate la tsoalo le bophelo bo atlehileng. Ke leboha sohle seo u leng sona ho Loyster. Mekete e Thabisang!",
    inputAnOfferPlease: "Kenya kopo ka kopo",
    pleaseSelectTheInsertPoint:
      "Ka kopo khetha ntlha e kentsoeng molaetseng ebe o tobetsa hape",
    birthdayOfferAndMessage: "Tlhahiso ea letsatsi la tsoalo le molaetsa",
    birthdayOffer: "Tlhahiso ea letsatsi la tsoalo",
    birthdayMessage: "Molaetsa oa letsatsi la tsoalo",
    noOfferFound: "Ha ho tlhahiso e fumanoeng",
    settingABirthdayOffer:
      "Ho beha tlhahiso ea letsatsi la tsoalo ho lumella bareki ho amohela mpho ena ka SMS ka letsatsi la bona la tsoalo",
    createOffer: "Theha Tlhahiso",
    whatIsTheOffer: "Monyetla ke ofe?",
    editMessage: "Edita Molaetsa",
    insert: "Kenya",
    theNameOfCustomerInserted: "Lebitso la moreki le tla kenngoa mona",
    theBirtdayOfferInserted: "Nyehelo ea letsatsi la tsoalo e tla kenngoa mona",
    youSuccessfullyAddedNewBranch: "U atlehile ho eketsa lekala le lecha!",
    addNewBranch: "Eketsa Lekala le Lecha",
    addBranch: "Eketsa Lekala",
    additionalBranchWillIncur: "Lekala le eketsehileng le ne le tla hlaha",
    perBranch: "lekaleng ka leng",
    ecommerceBranchCosts: "Litšenyehelo tsa Lekala la Ecommerce",
    pleaseEnterBranchName: "Ka kopo kenya lebitso la lekala",
    pleaseEnterBranchAddress1: "Ka kopo kenya aterese ea lekala ea 1",
    enterBranchAddress1: "Kenya mohala oa aterese ea lekala 1",
    enterBranchAddress2: "Kenya mohala oa aterese ea lekala 1",
    pleaseEnterBranchAddress2: "Ka kopo kenya aterese ea lekala ea 2",
    enterBranchName: "Kenya lebitso la lekala",
    successfullyAddedStaff: "U atlehile ho eketsa basebetsi!",
    addNewStaff: "Eketsa Basebetsi ba Bacha",
    addStaff: "Eketsa Basebetsi",
    additionalStaffWillIncur: "Basebetsi ba bang ba ne ba tla ba teng",
    perStaff: "basebetsi bohle.",
    pleaseEnterStaffEmail: "Ka kopo kenya lengolo tsoibila la basebetsi",
    pleaseEnterStaffUsername:
      "Ka kopo kenya lebitso la mosebelisi la basebetsi",
    pleaseEnterStaffPassword: "Ka kopo kenya phasewete ea basebetsi",
    pleaseSelectStaffRole: "Ka kopo khetha karolo ea basebetsi",
    selectStaffRole: "Khetha karolo ea basebetsi",
    enterStaffEmail: "Kenya lengolo tsoibila la basebetsi",
    enterStaffUsername: "Kenya lebitso la mosebelisi la basebetsi",
    enterStaffPassword: "Kenya phasewete ea basebetsi",
    spacesNotAllowedInUsername:
      "libaka ha lia lumelloa ka lebitso la mosebelisi",
    admin: "Tsamaiso",
    pleaseSelectBusinessToAttachStaff:
      "Ka kopo khetha khoebo ho hokela basebetsi",
    searchForBranchToAttachStaff: "Batla lekala ho hokela basebetsi",
    username: "Lebitso la mosebedisi",
    role: "Karolo e phethoang",
    areYouSureToDeleteThis: "Na ehlile u hlakola sena",
    branches: "Makala",
    upgradeYourPlan: "Ntlafatsa Moralo oa hau.",
    add: "EKETSA",
    addNew: "Kenya Ncha",
    customerWithEmailAlreadyExists:
      "Moreki ea nang le lengolo-tsoibila / nomoro ea mohala o se a ntse a le teng!",
    successfullyAddedNewCustomer: "U kentse moreki e mocha ka katleho!",
    addCustomer: "Kenya Moreki",
    pleaseEnterCustomerFirstName: "Ka kopo kenya lebitso la pele la moreki",
    pleaseEnterCustomerLastName: "Ka kopo kenya fane ea moreki",
    pleaseEnterCustomerPhoneNumber: "Ka kopo kenya nomoro ea mohala ea moreki",
    pleaseEnterCustomerEmail: "Ka kopo kenya lengolo tsoibila la moreki",
    pleaseEnterCustomerAddressLine: "Ka kopo kenya Mohala oa Aterese oa moreki",
    pleaseEnterCustomerOtherAddress: "Ka kopo kenya aterese e ngoe ea moreki",
    pleaseSelectCustomerGender: "Ka kopo khetha bong ba moreki",
    gender: "Bong",
    male: "E motona",
    female: "E motšehali",
    bank: "Banka",
    selectBank: "Khetha Banka",
    stateOrRegionOrProvince: "Naha / Sebaka / Porofense",
    customerNotes: "Lintlha tsa Bareki",
    sendSms: "Romella SMS",
    editCustomer: "Fetola Moreki",
    redeemReward: "Lopolla Moputso",
    issueLoyaltyCard: "Fana ka karete ea botšepehi",
    deleteCustomer: "Hlakola Moreki",
    youveSuccessfullyAssignedLoyaltyMembership:
      "U atlehile ho fana ka ID ea Botho ba Botšepehi",
    noMembershipIdAvailable:
      "Ha ho li-ID tsa botho. Ka kopo ikopanye le rona ho hello@loystar.co",
    sendEmail: "Romella Imeile",
    membershipPoints: "Lintlha tsa Botho",
    customerDetails: "Lintlha tsa Bareki",
    close: "Haufi",
    loyaltyBalance: "Tekano Tekano",
    pointsBalance: "Balance ea Lintlha",
    starBalance: "Tekanyo ea Star",
    requiredPoints: "Lintlha tse Hlokehang",
    requiredStars: "Linaleli tse Hlokehang",
    reddemCode: "Lopolla Khoutu",
    toDeleteThisCustomer: "ho hlakola moreki enoa",
    customerHasBeenDeletedSuccessfully: "Moreki o hlakotsoe ka katleho!",
    customerWithPhoneAlreadyExists:
      "Moreki ea nang le nomoro ea mohala o se a ntse a le teng",
    customerWasSuccessfullyEdited: "Moreki o hlophisitsoe ka katleho",
    anErrorOccured: "Ho hlahile bothata",
    phoneNumberIncludeCountry: "Nomoro ea mohala (Kenyelletsa Khoutu ea Naha)",
    yourFileQueuedForUpload:
      "Faele ea hau e letetsoe hore e kenngoe. Ka kopo khatholla leqephe kamora metsotsoana e seng mekae.",
    thereWasAnErrorPerformingOperation:
      "Ho bile le phoso ha re etsa tšebetso eo!",
    pleaseSelectFile: "Ka kopo khetha faele!",
    oopsOnlyCsvFilesAllowed:
      "Phepi! Ke lifaele tsa CSV feela tse lumelloang. Ka kopo kenya file ea .csv.",
    fileShouldBeSmallerThan5Mb:
      "Faele e lokela ho ba nyane ho feta 5MB. Haeba u na le faele e kholoanyane, ka kopo imeile support@loystar.co. kea leboha",
    customerFirstNameIsRequired: "lebitso la moreki lea hlokahala moleng",
    customerPhoneNumberIsRequired:
      "nomoro ea mohala ea moreki e hlokahala moleng",
    importCustomers: "Kenya Bareki",
    upload: "Kenya",
    clickIconToDownloadCsv:
      "Tobetsa letšoao lena ho jarolla template ea faele ea CSV.",
    getGoogleSheetVersion: "Fumana mofuta oa leqephe la Google",
    clickOrDragFileToUpload: "Tobetsa kapa hula faele sebakeng sena ho kenya",
    missingOrInvalidColumnHeader:
      "Sehlooho sa kholomo se sieo kapa se sa sebetseng. Ka kopo latela mofuta oa template. Kea leboha.",
    youHaveImported: "O Kentse Ka ntle",
    youSuccessfullyRedeemedReward: "O atlehile ho lopolla moputso wa hao!",
    sixDigitCode: "Khoutu ea linomoro tse tšeletseng",
    pleaseEnterCustomerRewardCode: "Ka kopo kenya khoutu ea moputso ea moreki",
    enterRewardCode:
      "Kenya khoutu ea moputso. (Khoutu ea moputso e ela hloko taba)",
    selectLoyaltyProgram: "Khetha lenaneo la botšepehi",
    stamps: "Litempe",
    smsUnitsLow: "Li-Units tsa SMS li tlase",
    pleaseTopUpSmsUnits: "Ka kopo, lihlopha tsa li-SMS tsa Top Up",
    smsSuccessfullySentForDelivery:
      "SMS e rometsoe ka katleho bakeng sa ho pepa!",
    sendSmsTo: "Romella SMS ho",
    allCustomers: "Bareki bohle",
    unitsAvailable: "Diyuniti lia fumaneha",
    pleaseTypeInTheMessage: "Ka kopo ngola molaetsa",
    message: "Molaetsa",
    charactersRemaining: "litlhaku tse setseng",
    avoidUseOfSpecialCharacters:
      "Qoba ts'ebeliso ea Batho ba Khethehileng le Emojis ho boloka li-Units tsa SMS.",
    note: "Hlokomela",
    errorFetchingCustomersMultilevelDetail:
      "Phoso ea ho lata lintlha tsa bareki ba MultiLevel",
    search: "Batla",
    reset: "Seta bocha",
    importCustomer: "Kenya Moreki",
    addNewCustomer: "Kenya Moreki o mocha",
    sendSmsBroadcast: "Romella Phatlalatso ea SMS",
    totalCustomers: "BABALISI KABALENG",
    disbursementDetails: "Lintlha tsa Tefo",
    paidBy: "Ho lefshoa ke",
    disbursed: "E ntšitsoeng",
    bankAccountName: "Lebitso la Ak'haonte ea Banka",
    bankAccountNumber: "Nomoro ea Ak'haonte ea Banka",
    transferInitiated: "Phetiso e Qalile",
    transferCompleted: "Phetiso e Phethiloe",
    pleaseEnterAValid: "Ka kopo kenya e nepahetseng",
    begin: "qala",
    end: "qeta",
    date: "letsatsi",
    paymentDate: "Letsatsi la tefo",
    selectDisbursementDuration: "Khetha Nako ea Tefo",
    totalSettled: "Kakaretso e Lula",
    totalUnsettled: "Kakaretso ha e na botsitso",
    toDeleteThisSale: "ho hlakola thekiso ena",
    draftSaleDeletedSuccessfully: "Moralo oa thekiso o hlakotsoe ka katleho!",
    deleteSale: "Hlakola Theko",
    pleaseTypeInYourTotal:
      "Ka kopo ngola mofuta oa hau kaofela ho netefatsa ho tlosoa",
    billDetails: "Lintlha tsa Bili",
    printBill: "Hatisa Bill",
    servedBy: "E sebeletsoa ke",
    total: "Kakaretso",
    createdDate: "Letsatsi le bōpiloeng",
    createdTime: "Nako e Bōpiloeng",
    status: "Boemo",
    loginSuccessful: "Ho kena ho atlehile",
    pleaseWaitWhileWeConnectAccount:
      "Ka kopo ema ha re ntse re hokela ak'haonte ea hau",
    connectionFailedTryAgain: "Khokelo ha e sebetse. Ka kopo leka hape.",
    connectionSuccessful: "Khokahano e atlehile",
    viewDetails: "Sheba Lintlha",
    enable: "Lumella",
    free: "Mahala",
    cost: "Litšenyehelo",
    visitWebsite: "Etela Webosaete",
    pleaseUpgradeYourPlanToPro:
      "Ka kopo Ntlafatsa leano la hau ho Pro Plus ho nolofalletsa sesebelisoa sena",
    connectYourBankAccount:
      "Hokela ak'haonte ea hau ea banka hore o tsebe ho fumana litefo.",
    disable: "Thibela",
    enableApp: "Numella App",
    addNewProductToInvoice: "Kenya Sehlahisoa se secha ho Invoice",
    toDeleteThisInvoice: "ho hlakola invoice ena",
    invoiceDeletedSuccessfully: "Invoice e hlakotsoe ka katleho!",
    deleteInvoice: "Hlakola Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "U atlehile ho kenya molaetsa oa tefo ea Invoice!",
    pleaseEnterPaymentInstructions: "Ka kopo kenya litaelo tsa tefo",
    invoiceId: "ID ea invoice",
    paidDate: "Letsatsi le Lefshoang",
    reference: "Tšupiso",
    productAdded: "Sehlahisoa se kentsoe",
    productOutOfStock:
      "Sehlahisoa se felile. Ka kopo kenya setoko se seng hape.",
    totalInvoices: "LIKOTSI KOTSI",
    totalPaidInvoices: "LITLHAKISO TSOHLE LITS'ELISO",
    totalUnpaidInvoices: "KOTSI LITLHAKISO TSE PUANG",
    loyaltyProgramDeleted: "Lenaneo la Botšepehi le Tlositsoe",
    thisLoyaltyProgramDeletedSuccessfully:
      "Lenaneo lena la botšepehi le hlakotsoe ka katleho",
    thisLoyaltyProgramEditedSuccessfully:
      "Lenaneo lena la botšepehi le hlophisitsoe ka katleho",
    loyaltyProgramAddedSuccessfully:
      "Lenaneo la botšepehi le ekelitsoe ka katleho",
    loyaltyProgramEdited: "Lenaneo la Botšepehi le Hlophisitsoe",
    loyaltyProgramAdded: "Lenaneo la Botšepehi lea Eketsoa",
    loyaltyDetails: "Lintlha tse tšepahalang",
    doYouWantToCloseDialog: "O batla ho koala lebokose lee?",
    pleaseEnterLoyaltyName: "Ka kopo kenya lebitso la botšepehi ba hau",
    programType: "Mofuta oa Lenaneo",
    pleaseSelectProgramType: "Ka kopo khetha mofuta oa lenaneo",
    simplePoints: "Lintlha Tse Bonolo",
    stampsProgram: "Lenaneo la Litempe",
    threshold: "Sekhahla",
    pleaseEnterLoyaltyThreshold: "Ka kopo kenya monyako oa botšepehi",
    reward: "Moputso",
    pleaseEnterLoyaltyReward: "Ka kopo kenya moputso oa botšepehi",
    totalUserPoints: "Kakaretso ea Lintlha tsa Basebelisi",
    totalUserStamps: "Kakaretso Litempe tsa Basebelisi",
    spendingTarget: "Chelete ea ho sebelisa chelete",
    spendingTargetHint1:
      "Chelete eo u e sebelisang ke hore na moreki o lokela ho sebelisa bokae ho fumana moputso. Boleng ba chelete e 1 = ntlha e le 1.",
    spendingTargetHint2:
      "Morero oa litempe ke hore na moreki o lokela ho bokella litempe tse kae ho fumana moputso. mohl. 5",
    addNewLoyaltyProgram: "Kenya Lenaneo le Lecha la Botšepehi",
    addLoyaltyProgram: "Kenya Lenaneo la Botšepehi",
    loyaltyProgramType: "Mofuta oa Lenaneo la Botšepehi",
    pleaseSelectLoyaltyProgramType:
      "Ka kopo khetha mofuta oa lenaneo la botšepehi",
    nameOfProgram: "Lebitso la lenaneo",
    pleaseEnterProgramName: "Ka kopo kenya lebitso la lenaneo",
    whatIsTheReward: "Moputso ke eng?",
    egNextPurchaseIsFree: "Mohlala Ho reka ho latelang ke mahala",
    customerName: "Lebitso la Bareki",
    guestCustomer: "Moreki Moreki",
    orderConfirmedSuccessfully: "Odara e netefalitsoe ka katleho",
    orderCancelledSuccessfully: "Odara e hlakotsoe ka katleho",
    confirmOrder: "Netefatsa taelo",
    cancelOrder: "Hlakola otara",
    allOrders: "Litaelo tsohle",
    totalOrders: "LITLHAKISO TSOHLE",
    doYouWantToAcceptOrder: "O batla ho amohela taelo ena?",
    doYouWantToCancelOrder: "O batla ho hlakola otara ena?",
    orderDetails: "Lintlha tsa odara",
    orderCreatedAt: "Odara e entsoe ka",
    supplier: "Mofani wa thepa",
    productName: "Lebitso la Sehlahisoa",
    quantity: "Bongata",
    unitPrice: "Theko ea thepa ka 'ngoe",
    receivedBy: "E Amohetsoe ke",
    reportFrom: "Tlaleha ho tsoa ho",
    totalSupplies: "Kakaretso ea Lisebelisoa",
    allRightsReserved: "Litokelo tsohle li sirelelitsoe",
    toDeleteThisTransaction: "ho hlakola Transaction ena",
    transactionDeletedSuccessfully:
      "Khoebo e hlakotsoe ka katleho. Stock e khutlisitsoe lethathamong.",
    deleteTransaction: "Tlosa Transaction",
    transactionDetails: "Lintlha tsa Transaction",
    printReceipt: "Risiti ea ho Hatisa",
    channel: "Mocha",
    discount: "Theolelo",
    profit: "Phaello",
    discountedSales: "Litheko tse theotsoeng",
    errorFetchingRecord: "Phoso ea ho Lata Rekoto",
    exportTransactions: "Lisebelisoa tsa Export",
    errorFetchingSalesRecord: "Phoso ea ho lata rekoto ea Khoebo ea Export.",
    totalSellingPrice: "Kakaretso ea Theko",
    totalCostPrice: "Kakaretso ea Theko ea Litsenyehelo",
    appliedDiscount: "Tšebeliso e Sebelisitsoeng",
    noOfItems: "Che ea Lintho",
    sales: "Lithekiso",
    export: "Romella kantle ho naha",
    totalProfit: "Chelete eohle",
    totalBalanceInPeriod: "Kakaretso ea Balance ka Nako",
    allTimeSales: "Khoebo eohle ea Nako",
    records: "Litlaleho",
    todaysSales: "Thekiso ea Kajeno",
    transaction: "phapanyetsano",
    youHaveExceededTotalNumberOfProducts:
      "U fetile palo e felletseng ea lihlahisoa tse lumelletsoeng morerong oa hau. Ntlafatsa moralo oa hau ho natefeloa ke moeli o phahameng.",
    youNeedToHaveLoyaltyProgram:
      "U hloka ho ba le Lenaneo la Botšepehi ho sebelisa tšobotsi ena!",
    price: "Theko",
    category: "Sehlopha",
    stockTracking: "Ho latela Stock",
    stockCount: "Palo ea Li-stock",
    taxed: "Lekhetho",
    originalPrice: "Theko ea mantlha",
    costPrice: "Theko ea Litsenyehelo",
    unit: "Yuniti",
    productImage: "Setšoantšo sa Sehlahisoa",
    taxRate: "Tekanyo ea lekhetho",
    taxType: "Mofuta oa Lekhetho",
    trackInventory: "Track Inventory",
    variants: "Mefuta e fapaneng",
    hasVariants: "E na le mefuta e fapaneng",
    importProduct: "Kenya Sehlahisoa",
    exportProducts: "Lihlahisoa tsa Kantle ho Naha",
    addNewProduct: "Kenya Sehlahisoa se secha",
    viewCategory: "Sheba Sehlopha",
    viewSuppliers: "Sheba Barekisi",
    receiveInventory: "Amohela Lethathamo",
    printAllProductsTag: "Hatisa Lihlahisoa Tsohle Tag",
    deleteAll: "Hlakola Tsohle",
    totalProducts: "LITLHAKISO TSOHLE",
    youveSuccessfullyAddedANewCategory: "U kentse sehlopha se secha ka katleho",
    addNewCategory: "Kenya Sehlopha se secha",
    addCategory: "Kenya Sehlopha",
    categoryName: "Lebitso la Sehlopha",
    pleaseEnterCategoryName: "Ka kopo kenya lebitso la sehlopha",
    stampsTarget: "Litempe tse shebiloeng",
    sendInventory: "Romella Inventory",
    productDetails: "Lintlha tsa Sehlahisoa",
    youveSuccessfullyEditedThisCategory:
      "U hlophisitse sehlopha sena ka katleho",
    update: "Nchafatsa",
    categoryList: "Lethathamo la Sehlopha",
    categories: "Lihlopha",
    enterQuantityToUpdate: "Kenya bongata hore o bo ntlafatse",
    inventorySentSuccessfully: "Lethathamo la libuka le rometsoe ka katleho!",
    updateInventory: "Ntlafatsa Inventory",
    currentQuantity: "Palo ea Hajoale",
    pleaseEnterQuantityToAdd: "Ka kopo kenya palo eo u batlang ho e eketsa",
    pleaseSelectABranch: "Ka kopo khetha Lekala",
    searchForBranch: "Batla lekala",
    youCantSendMoreThanStock:
      "U ke ke ua romella tse fetang tseo u nang le tsona setokong",
    send: "Romella",
    pleaseEnterQuantityToSend: "Ka kopo kenya palo eo u batlang ho e romela",
    productNameIsRequiredOnRow: "Lebitso la sehlahisoa le hlokahala moleng",
    productCategoryIsRequiredOnRow: "sehlopha sehlahisoa se hlokahala moleng",
    productPriceIsRequiredOnRow: "theko ea sehlahisoa e hlokahala ka mola",
    productUnitIsRequiredOnRow: "sehlahisoa sa lihlahisoa sea hlokahala moleng",
    productQuantityIsRequiredOnRow: "sehlahisoa se ngata se hlokahala moleng",
    productVariantsRequireTracking:
      "mefuta e fapaneng ea lihlahisoa e hloka ho latedisa!",
    pleaseAddVariantClickButton:
      "Ka kopo kenya phapang ka ho tobetsa konopo ea eketsa!",
    totalVariantsMoreThanSelectedQuantity:
      "Kakaretso ea Mefuta e mengata ho feta bongata ba lihlahisoa tse khethiloeng, Pls e fokotsa bongata bo fapaneng",
    productEditedSuccessfully: "Sehlahisoa se hlophisitsoe ka katleho!",
    fileTooLargeLessThan4Mb:
      "Saese ea faele e kholo haholo! Saese ea faele e lokela ho ba tlase ho 4MB.",
    suchVariantAlreadyExist: "Mofuta o joalo o se o ntse o le teng",
    addVariants: "Kenya Mefuta e fapaneng",
    editProduct: "Fetola Sehlahisoa",
    pleaseEnterProductName: "Ka kopo kenya Lebitso la Sehlahisoa",
    pleaseEnterProductPrice: "Ka kopo kenya Theko ea Lihlahisoa",
    pleaseEnterProductOriginalPrice:
      "Ka kopo Kenya Theko ea Pele ea Sehlahisoa",
    productDescription: "Tlhaloso ea Sehlahisoa",
    selectProductCategory: "Khetha Sehlopha sa Lihlahisoa",
    pleaseSelectProductCategory: "Ka kopo khetha sehlopha sa lihlahisoa",
    productCostPrice: "Theko ea Litšenyehelo tsa Sehlahisoa",
    productSellingPrice: "Theko ea ho Rekisa Lihlahisoa",
    productOriginalPrice: "Theko ea mantlha ea Sehlahisoa",
    maxFileSizeAllowedIs4Mb: "Boholo. Saese ea faele e lumelletsoeng ke 4mb",
    productsWithPicturesArePublished:
      "Lihlahisoa tse nang le litšoantšo li phatlalatsoa ho Discover ho amohela liodara",
    shouldThisProductBeTracked: "Na sehlahisoa see se lokela ho latelloa?",
    pleaseSelectStockUnit: "Ka kopo khetha sesebelisoa sa stock",
    stockUnit: "Setsi sa Lihlahisoa",
    bag: "Mokotla",
    bottle: "BOTLE",
    bunch: "BUNCH",
    can: "KA",
    carton: "LEBOTHA",
    crate: "KRELETE",
    cup: "KOPI",
    dozen: "DOZEN",
    gigabytes: "LITEKO",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "MONYANE",
    pack: "PAKI",
    pair: "BONOLO",
    pieces: "LIEKETSO",
    plate: "SEBATA",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "LEBATA",
    pleaseEnterProductQuantity: "Ka kopo kenya bongata ba sehlahisoa",
    productQuantity: "Product palo",
    isThisProductTaxed: "Na sehlahisoa see se lefisoa lekhetho?",
    selectTaxType: "Khetha Mofuta oa Lekhetho",
    pleaseSelectTaxType: "Ka kopo khetha Mofuta oa Lekhetho",
    progressive: "Tsoelo-pele",
    proportional: "Tekano",
    pleaseEnterProductTaxRate:
      "Ka kopo kenya sekhahla sa lekhetho la sehlahisoa",
    doesProductHaveVariants: "Na sehlahisoa see se na le mefuta e fapaneng?",
    type: "Tlanya",
    value: "Boleng",
    pleaseEnterVariantType: "Ka kopo kenya mofuta oa mofuta",
    pleaseEnterVariantValue: "Ka kopo kenya boleng ba mofuta",
    pleaseEnterVariantPrice: "Ka kopo kenya litheko",
    pleaseEnterVariantQuantity: "Ka kopo kenya bongata ba mofuta",
    productDeletedSuccessfully: "Sehlahisoa se hlakotsoe ka katleho!",
    categoryDeletedSuccessfully: "Sehlopha se hlakotsoe ka katleho!",
    toDeleteThisProduct: "ho hlakola Sehlahisoa sena",
    invalidProductQuantity: "Boholo ba Sehlahisoa bo sa nepahalang",
    quantityAddedIsOverStock:
      "Palo eo u e kenyang e feta seo u nang le sona setokong.",
    itemInventoryNotTracked: "Lenane la thepa ha le lateloe",
    addBulkQuantity: "Eketsa bongata ba bongata",
    enterBulkQuantity: "Kenya bongata ba bongata",
    pleaseEnterBulkQuantity: "Ka kopo kenya bongata ba bongata",
    youveSuccessfullyAddedANewProduct:
      "U kentse sehlahisoa se secha ka katleho!",
    pleaseEnterProductSellingPrice: "Ka kopo kenya theko e rekisoang",
    doYouWantToTrackProductStock: "O batla ho latela setoko sa lihlahisoa?",
    sellingPrice: "Theko ea ho rekisa",
    setProductExpiryReminder:
      "Beha Sehopotso sa ho Felloa ke Nako ha Sehlahisoa",
    productExpiryDate: "Letsatsi la ho felloa ke matla ha sehlahisoa",
    startRemindingFrom: "Qala ho hopotsa ho tloha",
    productSuppliesAddedSuccessfully:
      "U kentse thepa ea sehlahisoa ka katleho.",
    addProductSupplies: "Kenya Lisebelisoa tsa Lihlahisoa",
    pleaseSelectSupplier: "Ka kopo khetha Mofani oa thepa",
    nameOfProduct: "Lebitso la Sehlahisoa",
    pleaseSelectProduct: "Ka kopo khetha Sehlahisoa",
    productVariant: "Mofuta oa Sehlahisoa",
    pleaseSelectAVariant: "Ka kopo khetha mofuta o fapaneng",
    pleaseEnterUnitPrice: "Ka kopo kenya Theko ea Yuniti",
    businessBranch: "Lekala la Khoebo",
    pleaseSelectBranch: "Ka kopo khetha lekala",
    youveSuccessfullyAddedANewSupplier:
      "U kentse mofani oa thepa e mocha ka katleho",
    addSupplier: "Kenya Morekisi",
    pleaseEnterSupplierEmail:
      "Ka kopo kenya lengolo-tsoibila la mofani oa thepa",
    pleaseAddADescription: "Ka kopo eketsa tlhaloso",
    anErrorOccuredProductsDeleted:
      "Phoso e etsahetse ha ho etsoa ts'ebetso. Ka kopo elelloa, lihlahisoa tse fokolang e kanna eaba li tlositsoe molemong oa sena",
    bulkDelete: "Tlosa ka bongata",
    youAreAboutToDelete: "U mothating oa ho hlakola",
    product: "Sehlahisoa",
    isDueToRedeem: "ke ka lebaka la ho lopolla",
    aReward: "moputso",
    pleaseSelectCustomerToReeemReward:
      "Ka kopo khetha moreki ho lopolla moputso.",
    youHaveNoLoyaltyProgramRunning: "Ha u na lenaneo le sebetsang la botšepehi",
    customerHhasNoPointsInLoyaltyProgram:
      "Moreki ha a na lintlha lenaneong lena la botšepehi",
    proceedWithPayment: "Tsoela pele ka Tefo?",
    youAreAboutToPayForTransactionUsingPoints:
      "U mothating oa ho patalla khoebo eo u sebelisa lintlha.",
    customerHas: "Moreki o na le",
    worth: "bohlokoa",
    andIsNotSufficientToPayForTransaction:
      "ebile ha ea lekana ho lefella khoebo ena. Na ba ka batla ho eketsa chelete e setseng ba sebelisa mokhoa o mong oa ho patala?",
    addCustomerLoyalty: "Eketsa Botšepehi ba Bareki",
    pleaseAddCustomerFirst: "Ka kopo eketsa kapa khetha moreki pele.",
    pleaseWaitWhileWeGetReady: "Ke kopa o eme, ha re ntse re itokisa",
    lowStock: "Stock e tlase",
    pleaseEnterAmountTendered: "Ka kopo kenya chelete e fanoeng",
    areYouSureToBookSaleOffline:
      "Na u na le bonnete ba hore u batla ho boloka thekiso ee ntle le inthanete?",
    saleWouldBeBookedAutomatically:
      "Thekiso e ka behuoa ka boiketsetso ha u bula marang-rang",
    offlineSalesBookingCancelled: "Peeletso ea mahala ha e sa sebetsa",
    covid19Message:
      "COVID19: Hlatsoa matsoho ka sesepa kapa itlhoekise ho emisa ho ata. Lula U Sireletsehile Kamehla",
    saleSuccessfullyRecorded: "Ho rekisoa ho atlehile!",
    sendReceiptToEmail: "Romella rasiti ho Email",
    sendThankYouSms: "Romella melaetsa ea teboho",
    sendShippingDetails: "Romella lintlha tsa Shipping",
    addLoyalty: "Eketsa Botšepehi",
    searchCustomerNameOrNumber: "Batla lebitso la moreki kapa nomoro",
    clickTheSearchCustomerBox:
      "Tobetsa Lebokose la Bareki la Patlo ebe u Scan Card",
    enterProductPrice: "Kenya Theko ea Lihlahisoa",
    enterPriceFor: "Kenya Theko ea",
    searchForProduct: "Batla sehlahisoa",
    all: "Kaofela",
    backToDashboard: "Khutlela Dashboard",
    viewDraftSales: "Sheba Moralo oa Khoebo",
    variantSelected: "mofuta o khethiloeng",
    variant: "fapaneng",
    thePreviousVariantSelectionNotAvailable:
      "Khetho ea mofuta o fetileng ha e fumanehe bakeng sa mofuta o mocha o khethiloeng ho latela theko, ka kopo fetola khetho ea hau.",
    pleaseSupplyPositiveQuantityNumber:
      "Ka kopo fana ka nomoro ea palo ea poso",
    lowStockFor: "Stock e tlase bakeng sa",
    clearVariants: "Hlakola mefuta e fapaneng",
    pleaseEnterQuantity: "Ka kopo kenya bongata",
    picture: "Setšoantšo",
    redemptionToken: "Letshwao la Topollo",
    token: "Letshwao",
    totalSpent: "Kakaretso e Sebelisitsoeng",
    confirmPayment: "Netefatsa Tefo",
    hasPaymentBeenMade: "Na Tefo e sebetsitsoe hantle?",
    enterTransactionReference: "Kenya tšupiso eo u lefileng ka eona",
    pleaseSelectACustomer: "Ka kopo khetha moreki!",
    areYouSureToApplyDiscount:
      "Na u na le bonnete ba hore u batla ho etsa kopo ea theolelo?",
    addYourBankAccountToEnableUssd:
      "Kenya ak'haonte ea hau ea banka ho nolofalletsa phetiso ea Instant USSD ka uPay",
    totalAmountToPay: "Chelete eohle eo u lokelang ho e lefa",
    doYouWantToCancelTransaction: "O batla ho hlakola khoebo ee?",
    savePrintBill: "Boloka / Hatisa Bill",
    enterAmountCollectedForCustomer: "Kenya chelete e bokelletsoeng moreki",
    recordSale: "Rekoto e rekisoang",
    checkOutWith: "Hlahloba le",
    instantTransfer: "Phetiso ea hanghang",
    dialTheUSSDCode: "Tobetsa khoutu ea USSD",
    pleaseSelectABank: "Ka kopo khetha banka",
    payWithUSSD: "Lefa ka USSD",
    sendBillTo: " - Romella Bill ho",
    waitingForUSSDTransfer: "E emetse phetisetso ea USSD",
    percent: "Liphesente",
    applyDiscount: "Sebelisa Discount",
    thisBillHasBeenSaved: "Bili ena e bolokiloe",
    saveDraft: "Boloka Moralo",
    pleaseTypeANameToIdentifyCustomer:
      "Ka kopo ngola lebitso ho khetholla moreki",
    paymentDetails: "Lintlha tsa Tefo",
    basePrice: "Theko ea Base",
    staff: "Basebetsi",
    subTotal: "Kakaretso",
    durationMonths: "Nako (likhoeli)",
    selectADuration: "Khetha Nako",
    oneMonth: "Khoeli e le 'ngoe",
    twoMonths: "Likhoeli tse 2",
    threeMonths: "Likhoeli tse 3",
    sixMonths: "Likhoeli tse 6",
    twelveMonths: "Likhoeli tse 12",
    eighteenMonths: "Likhoeli tse 18",
    twentyFourMonths: "Likhoeli tse 24",
    twoMonthsFree: "(Likhoeli tse 2 mahala)",
    threeMonthsFree: "(Likhoeli tse 3 Mahala)",
    fiveMonthsFree: "(Likhoeli tse 5 mahala)",
    yourAccountHasBeen: "Ak'haonte ea hau e bile joalo",
    renewed: "nchafalitsoe",
    upgraded: "ntlafalitsoe",
    successfully: "ka katleho",
    yourSubscription: "Ngoliso ea hau",
    youAreCurrentlyEnrolledOnA: "Hona joale u ngolisitse ho",
    pleaseChooseAPaymentOption: "Ka kopo khetha khetho ea tefo",
    planRenewal: "Moralo oa Ntjhafatso",
    planUpgrade: "Rera ho ntlafatsa",
    pleaseSelectDurationToPayFor:
      "Ka kopo khetha Nako eo u ikemiselitseng ho e lefella",
    staffAccounts: "Litlaleho tsa Basebetsi",
    ecommerce: "Khoebo ea khoebo",
    pleaseSelectAPlan: "Ka kopo khetha moralo",
    includeAddons: "Kenyelletsa li-Add-ons",
    viewTransactions: "Sheba Litšebelisano",
    customerHasNoCompletedTansactions:
      "Moreki ha a na li-tansaction tse phethetsoeng",
    branch: "Lekala",
    enterNumberOfEcommerceBranches: "Kenya Palo ea Makala a Khoebo",
    enterNumberOfEcommerceBranchesToPay:
      "Kenya Palo ea Makala a Khoebo ao U Ikemiselitseng ho a Patala",
    ecommerceIntegration: "Khokahano ea Khoebo",
    enterNumberOfBranches: "Kenya Palo ea Makala",
    enterNumberOfAdditionalBranchesToPay:
      "Kenya Palo ea Makala a Eketsehileng ao U Ikemiselitseng ho a Patala",
    enterNumberOfStaffs: "Kenya Palo ea Basebetsi",
    enterNumberOfStaffsToPayFor:
      "Kenya Palo ea Basebetsi bao U Ikemiselitseng ho ba Lefa",
    discountApplies: "Theolelo ea Sebetsa",
    starsOnThe: "Linaleli ho",
    offer: "nyehelo",
    get: "Fumana",
    moreStarsToRedeem: "linaleli tse ling tseo u ka li lopollang",
    taxVat: "Lekhetho (VAT)",
    callCashierToCompletePayment: "Bitsa Cashier ho phethela Tefo",
    receipt: "Rasiti",
    dear: "Ratehang",
    thankYouForYourOrderFindGoods:
      "Ke leboha taelo ea hau. Ka kopo fumana thepa e latelang e fanoe, joalo ka ha ho lumellanoe.",
    shippingNote: "Thomello Hlokomela",
    enterShippingNote: "Kenya Kumbuka ea Shipping",
    shippingAddress: "Aterese ea Sebaka seo ho Romelloang",
    enterShippingAddress: "Kenya aterese ea Shipping",
    wellDoneYouAreDueToRedeem: "Mosebetsi o motle! O lokela ho lopolla",
    toGetYourRewardNextVisit:
      "ho fumana moputso oa hau ketelong e tlang. kea leboha",
    pointsOnThe: "Lintlha ho",
    morePointsToRedeem: "lintlha tse ling ho lopolla",
    showCode: "Bontša khoutu",
    toGetYourRewardOnNextVisit:
      "ho fumana moputso oa hau ketelong e tlang. kea leboha",
    earn: "Fumana",
    delivaryNote: "Tlhokomeliso",
    draftSales: "Moralo oa Khoebo",
    startDate: "Letsatsi la qalo",
    endDate: "Letsatsi la ho qetela",
    orders: "Litaelo",
    checkout: "sheba",
    noProductItem: "Ha ho na Sehlahisoa sa Sehlahisoa",
    selectProductImage: "Khetha Setšoantšo sa Sehlahisoa",
    selectCountry: "Khetha naha",
    selectRegion: "Kgetha Naha / Lebatowa",
    printProductTag: "Hatisa Sehlahisoa Tag",
    transactionReference: "Ts'ebetso ea transaction",
    Cashier: "Mokotlami",
    Manager: "Motsamaisi",
    Owner: "Mong",
    Admin: "Tsamaiso",
    addPartners: "Eketsa Balekane",
    addNewLoyaltyPartner: "Kenya Molekane e Mocha oa Botšepehi",
    pleaseEnterCompanyName: "Ka kopo, kenya Lebitso la Khampani",
    companyName: "Lebitso la K'hamphani",
    pleaseEnterCompanyRepName: "Ka kopo, kenya Lebitso la moemeli oa khamphani",
    companyRepName: "Lebitso la Moemeli oa Khampani",
    pleaseEnterCompanyRepEmail:
      "Ka kopo, kenya lengolo-tsoibila la moemeli oa k'hamphani",
    companyRepEmail: "Imeile ea moemeli oa k'hamphani",
    pleaseEnterCompanyRepPhone:
      "Ka kopo, kenya nomoro ea mohala ea moemeli oa k'hamphani",
    companyRepPhone: "Nomoro ea mohala ea moemeli oa k'hamphani",
    addReward: "Eketsa moputso",
    pleaseEnterRewardName: "Ka kopo, kenya lebitso la moputso",
    rewardName: "Lebitso la Moputso",
    rewardQuantity: "Bongata ba Moputso",
    rewardDescription: "Tlhaloso ea Moputso",
    rewardType: "Mofuta oa Moputso",
    pleaseEnterRewardType: "Ka kopo, kenya mofuta oa moputso",
    pleaseEnterRewardQuantity: "Ka kopo, kenya palo ea moputso",
    pleaseEnterRewardDescription: "Ka kopo, kenya tlhaloso ea moputso",
    fineDining: "Lijo tse Ntle",
    luxuryFashion: "Feshene ea Bokhabane",
    hotels: "Lihotele",
    travel: "Tsamaea",
    foodAndBeverage: "Lijo le Seno",
    fashion: "Feshene",
    health: "Bophelo bo botle",
    furniture: "Thepa ea ka tlung",
    entertainment: "Boithabiso",
    automobile: "Koloi",
    education: "Thuto",
    beautyAndSpa: "Botle le Spa",
    staycation: "Bolulo",
    events: "Liketsahalo",
    trips: "Maeto",
    oilAndGas: "Oli le Khase",
    laundry: "Hlatsoa",
    partnerCategory: "Sehlopha sa balekane",
    freeItem: "Ntho ea Mahala",
  },
  Shona: {
    cashier: "keshiya",
    manager: "maneja",
    owner: "muridzi",
    online: "online",
    offline: "offline",
    changePassword: "Chinja password",
    currentPasswordMessage: "Ndokumbirawo uise password yako yazvino",
    passwordMessage: "Ndapota nyora password yako",
    currentPassword: "pasiwedhi yazvino",
    password: "pasiwedhi",
    confirmPasswordMessage: "Ndokumbira usimbise password yako!",
    confirmPassword: "Tsigira pasiwedhi",
    sendViaEmail: "Tumira neemail",
    sendViaWhatsapp: "Tumira Via WhatsApp",
    downloadPdf: "Dhawunirodha PDF",
    paid: "akabhadhara",
    unpaid: "kusabhadharwa",
    partial: "kusarura",
    closeInvoice: "Iwe unoda kuvhara iyo Invoice?",
    closeInvoiceConfirmation:
      "Invoice inogona kusachengetedzwa. Iwe unoda kuvhara here?",
    yes: "Ehe",
    no: "Aihwa",
    invoice: "Invoice",
    wasDeducted: "yakabviswa",
    for: "nokuti",
    item: "Chinhu",
    addProduct: "Wedzera Chigadzirwa",
    paymentReference: "Kubhadhara Referensi",
    amountPaid: "Mari Yakabhadharwa",
    discountAmount: "Mari Yakadzikira",
    amountDue: "Mari Yakakodzera",
    amount: "Mari",
    dueDate: "Zuva racho",
    paymentType: "Rudzi rwekubhadhara",
    card: "Kadhi",
    cash: "Cash",
    bankTransfer: "Kutamisa Kwebhangi",
    paymentMessage: "Kubhadhara Meseji",
    description: "Tsananguro",
    sendReceipt: "Tumira Risiti",
    delete: "Delete",
    save: "Sevha",
    resend: "Re-Send",
    saveAndSend: "Sevha",
    invoiceSaved: "Invoice yakachengetedzwa!",
    selectPaymentMethod: "Ndokumbira usarudze nzira yekubhadhara!",
    selectCustomer: "Ndokumbira usarudze Mutengi!",
    cartEmptyError:
      "Cart Chinyorwa hachigone kuve chisina chinhu, vhara invoice uye wedzera Item kungoro!",
    subscriptionExpired:
      "Kunyorera kwako kwapera uye account yako ikozvino yaganhurirwa. Dzvanya Bhatani pazasi kuti uvandudze account yako",
    renew: "Vandudza",
    holdOn: "Batirira",
    customerBank: "Mutengi Bank",
    cancel: "Kanzura",
    selectACustomer: "Sarudza Mutengi",
    invoiceSuccessfulPdfError:
      "Invoice yakagadzirwa zvinobudirira asi PDF Generation iri kutora nguva yakareba kupfuura yakajairwa. Ndokumbira utarise kumashure munguva pfupi.",
    downloadingInvoice: "Kudhanilodha Invoice",
    downloadingReceipt: "Kudhanilodha Risiti",
    whatsappReceiptError:
      "Iko kukanganisa kwaitika kutumira risiti kuburikidza neWhatsApp, Ndokumbirawo uedze zvakare.",
    receiptToWhatsapp: "Risiti rinotumirwa kuWhatsApp",
    thankYouForPatronage: "Ndinokutendai nerubatsiro rwenyu",
    hereIsYourReceipt: "Heino risiti yako yekubhadhara",
    errorSendingEmailReceipt:
      "Kanganiso yakaitika pakutumira risiti kuburikidza ne-e-mail, ndapota edza zvakare kana kubvunza rutsigiro",
    receiptSentToEmail: "Irisiti yatumirwa kune email yemutengi",
    invoiceSentToEmail: "Invoice yatumirwa kune email yevatengi",
    invoiceSuccessWhatsappError:
      "Invoice yakagadzirwa zvinobudirira asi paitika kukanganisa kutumira kuWhatsApp. Edza zvakare pane invoice runyorwa",
    invoiceSuccessfulEmailError:
      "Invoice yakagadzirwa zvinobudirira asi paitika kukanganisa pakutumira se-e-mail. Ndokumbirawo uedze zvekare kubva kuinvoice runyorwa",
    invoiceSentToWhatsapp: "Invoice yakaendeswa kune WhatsApp",
    hello: "mhoro",
    pleaseDownloadInvoice: "Ndokumbirawo utore invoice",
    pleaseDownloadReceipt: "Ndokumbirawo utore risiti",
    from: "kubva",
    email: "Email",
    upgrade: "Ndiwedzere",
    youAreOnFreePlan: "Iwe uri pane Yemahara Chirongwa.",
    clickOn: "Dzvanya pa",
    yourPlanInFewSteps: " kunyoresa kwako mumatanho mashoma anokurumidza.",
    to: "ku",
    yourSubscriptionHasExpired:
      "Kunyorera kwako kwapera uye account yako ikozvino yaganhurirwa.",
    days: "mazuva",
    yourSubscriptionExpiresIn: "Kunyoresa kwako kweLoystar kunopera mukati",
    createAcount: "Gadzira Akaundi",
    signIn: "Nyorera mu",
    continue: "Enderera",
    enterOtp: "Pinda OTP PIN",
    enterValidOtp: "Ndokumbira upinze PIN inoshanda",
    pin: "PIN",
    tokenSentToMail: "Chiratidzo chakatumirwa kune yako email",
    passwordResetSuccessful: "Kugadzirisazve password kwabudirira",
    somethingWentWrong: "Chinhu chakatadza kufamba!",
    resetPassword: "Dzosera password",
    iHaveResetCode: "Ndine password yekumisazve kodhi",
    pleaseEnterEmail: "Ndokumbirawo upinde email",
    aTokenWillBeSentToEmail: "Chiratidzo chaizotumirwa kune yako email",
    enterEmail: "Pinda email yako",
    sendinYourToken: "Kutumira chiratidzo chako ...",
    makeSureItMatchesPassword: "Ita shuwa kuti inowirirana ne password nyowani",
    pleaseChooseNewPassword: "Ndokumbira usarudze password nyowani",
    chooseNewPassword: "Sarudza nyowani nyowani",
    enterNewPassword: "Pinda password yako nyowani kuti usimbise",
    enterTokenSent: "Pinda chiratidzo chakatumirwa tsamba yako",
    resetToken: "Reset Chiratidzo",
    resettingPassword: "Kudzorerazve password yako ...",
    signUp: "Nyorera",
    adminSignInWithEmail:
      " Admin inopinda mukati neemail apo Vashandi vanopinda nemazita ekushandisa.",
    pleaseEnterEmailOrUsername:
      "Ndokumbirawo upinde email kana zita rekushandisa",
    emailOrUsername: "Email kana Username",
    pleaseEnterPassword: "Ndapota nyora pasiwedhi",
    createAnAccount: "Gadzira Akaundi",
    forgotPassword: "Forgot Password?",
    enterPhoneNumber: "Pinda Runhare Nhamba",
    personalData: "Zvemunhu Dhata",
    validateConfirmationCOde: "Simbisa Kodhi yeKusimbisa",
    pleaseEnterFirstName: "Ndokumbirawo uise zita rako rekutanga",
    pleaseEnterPhoneNumber: "Ndapota nyora nhamba yako yefoni",
    pleaseEnterLastName: "Ndapota nyora zita rako rekupedzisira",
    pleaseEnterBusinessName: "Ndokumbirawo uise zita rako rebhizinesi",
    firstName: "Zita rokutanga",
    lastName: "Zita rokupedzisira",
    businessName: "Bhizinesi Zita",
    previous: "Kare",
    next: "Inotevera",
    pleaseSelectBusinessCategory: "Ndokumbira usarudze rako bhizinesi chikamu",
    pleaseEnterValidEmail: "Ndokumbirawo kuti utumire email inoshanda",
    pleaseEnterPostCode: "Ndokumbira upinze post code",
    postCode: "Post Code",
    phoneNumberInUse: "Iyi nhamba yefoni iri kushandiswa kare!",
    emailInUse: "Iyi email iri kushandiswa kare!",
    foodAndDrinks: "Kudya uye Zvinwiwa",
    salonAndBeauty: "Salon uye Runako",
    fashionAndAccessories: "Fashoni uye zvishongedzo",
    gymAndFitness: "Gym uye Fitness",
    travelAndHotel: "Kufamba uye Hotera",
    homeAndGifts: "Kumba Nezvipo",
    washingAndCleaning: "Kugeza nekutsvaira",
    gadgetsAndElectronics: "Gadgets uye Electronics",
    groceries: "Zvitoro",
    others: "Vamwe",
    submit: "Tumira",
    accountCreatedSuccessful: "Akaundi yako yakagadzirwa zvinobudirira.",
    pleaseEnterAddress: "Ndokumbirawo Unyore Kero Yako",
    addressLine1: "Mutsara Mutsara 1",
    addressLine2: "Mutsara Mutsara 2",
    choosePassword: "Sarudza Password",
    passwordMustBe6Characters:
      "Pasiwedhi inofanira kunge iine mavara matanhatu.",
    howDidYouHearLoystar: "Iwe wakanzwa sei nezve Loystar?",
    referralCode: "kutumira kodhi",
    byClickingTheButton: " Nekudzvanya bhatani pazasi, unobvumirana ne",
    termsAndSevice: "Mitemo",
    wereCreatingAccount: "Tiri kugadzira account yako",
    proceed: "Enderera",
    verificationCodeMustBe6:
      "Verification Code inofanira kuve matanhatu manhamba",
    pleaseEnter6DigitCode: "Ndokumbirawo upinze kodhi ine manhamba matanhatu",
    enterVerificationCode: "Pinda Verification Code",
    resendToken: "Dzorera Chiratidzo",
    verify: "Tarisa",
    transactions: "Kutengesa",
    todaySales: "Nhasi Kutengesa",
    salesHistory: "Nhoroondo Yekutengesa",
    supplyHistory: "Wedzera Nhoroondo",
    new: "Nyowani",
    invoices: "Invoice",
    disbursements: "Kubhadhara",
    offlineSales: "Offline Kutengesa",
    products: "Zvigadzirwa",
    customers: "Vatengi",
    multiLevelLoyalty: "Kuvimbika Kwemazinga Akawanda",
    loyaltyPrograms: "Kuvimbika Zvirongwa",
    members: "Nhengo",
    appStore: "App Store",
    orderMenu: "Order Menyu",
    settings: "Zvirongwa",
    staffAndBranches: "Vashandi nemapazi",
    myAccount: "Akaundi Yangu",
    switchToSellMode: "Chinja kuna Sell Mode",
    signOut: "Buda",
    getFreeSubscription: "Tora Mahara Kunyorera",
    onlyNumbersAllowed: "Nhamba chete dzinobvumidzwa",
    yourAccountMustBe10Digits:
      "yako account account inofanira kunge iri gumi manhamba",
    yourBvnMustBe11: "BVN yako inofanira kunge iri nhamba gumi nenhatu",
    pleaseSelectBank: "Ndokumbira usarudze bhangi rako",
    selectYourBank: "Sarudza yako Bhangi",
    enterBankAccountNumber: "Pinda Nhamba yeAkaundi yeBhangi",
    enterBvnNumber: "Pinda yako BVN",
    connectBankAccount: "Unganidza Akaundi yeBhangi",
    passwordResetSuccessfulAndSignOut:
      "Pasiwedhi yako yadzoswazve zvinobudirira. Dzvanya pane bhatani reKusaina pazasi kuti upinde zvakare",
    enterCurrentPassword: "Pinda password yazvino",
    pleaseEnterCurrentPassword: "Ndokumbira upinze password yazvino",
    phoneNumber: "Nhamba yenhare",
    sex: "Zvepabonde",
    dateOfBirth: "Zuva rekuzvarwa",
    state: "Hurumende",
    country: "Nyika",
    loyaltyId: "Kuvimbika ID",
    createdAt: "Yakagadzirwa At",
    noOfTransactionsMade: "Kwete yezvinhu zvakaitwa",
    yourDownloadWillStart: "Kurodha pasi kwako kuchatanga munguva pfupi",
    exportCustomers: "Export Vatengi",
    youhaveSuccessfullyToppedUp:
      "Iwe wakabudirira kuwedzera yako maSyuniti Units.",
    youNowHave: "Iwe ikozvino une",
    smsUnits: "MaSMS Units",
    enterNumberOfSmsUnits: "Pinda Nhamba yeSMS Units Iwe Unotarisira Kutenga",
    pleaseEnterANumericValue: "Ndokumbirawo upe huwandu hwehuwandu",
    pay: "Bhadhara",
    accountEditedSuccessfully: "Akaundi yako yakagadziridzwa zvinobudirira.",
    youAeCurrentlyOn: "Iwe uri parizvino",
    plan: "Ronga",
    userData: "Mushandisi Dhata",
    businessData: "Dhata reBUSINI",
    businessCategory: "Bhizinesi Catergory",
    pleaseSelectCurrency: "Ndokumbira usarudze mari yako",
    selectYourCurrency: "Sarudza mari yako",
    purchaseMoreSmsUnits: "Tenga mamwe maSMS Units uchishandisa fomu pazasi",
    youHave: "Une",
    atLeast4SmsUnitsRrequired:
      "Anokwana 4 sms zviyero zvinotarisirwa kuti zvionekwe, ndapota kumusoro!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Ndokumbira utarise account yako yebhangi kutevedzera izvo 'Ziva Wako Mutengi' (KYC) zvinodiwa. Izvi zvinokutendera iwe kutora mari kuburikidza neUSSD kana kuchinjisa chiriporipocho, gamuchira maodha kubva kune vatengi uye nekugadzirisa kutengeserana kugadzirisa. Inoshanda kune vatengesi veNigerian chete. Ndokumbirawo ubaye bhatani pazasi kuti utange.",
    reConnectBankAccount: "Dzorera zvakare Akaundi yeBhangi",
    accountName: "Zita reAccount",
    accountNumber: "Nhamba Yenyaya",
    bankName: "Zita reBhangi",
    verified: "Yakavimbiswa",
    accountDetails: "Nhoroondo dzeAccount",
    kycBankAccount: "KYC",
    createTier: "Gadzira Tier",
    fileUploadSuccessful: "faira rakaburitswa zvinobudirira",
    fileUploadFailed: "faira kurodha rakundikana",
    createLoyaltyProgram: "Gadzira Yakavimbika Chirongwa",
    createLoyalty: "Gadzira Kuvimbika",
    name: "Zita",
    loyaltyArtwork: "Kuvimbika Artwork",
    clickToUpload: "Dzvanya kurodha",
    amountToPointsRatio: "Amount kusvika Points Ratio",
    points: "Points",
    recommendedAmountToPointRatio:
      "Yakurudzirwa: ₦ 1 kusvika 1point. kureva kune yega 1 Naira yakashandiswa, vatengi vako vanowana 1 poindi",
    pleaseTypeIn: "Ndokumbirawo unyore mukati",
    toDeleteLoyalty: "kudzima kuvimbika uku",
    deleteLoyalty: "Bvisa Kuvimbika",
    toConfirm: "kusimbisa",
    edit: "Hora",
    pointsAwardedSuccessfully: "Mapoinzi Akapihwa Achibudirira.",
    enterPointValueToAward: "Pinda Pfungwa Kukosha Iwe Waungade Kupa Vatengi",
    award: "Mubayiro",
    awardPointValuesToCustomer: "Mubayiro wePoint Kukosha kune Mutengi",
    enrollMembersToLoyalty: "Nyoresa Nhengo kuKuvimbika",
    awardPoints: "Points Award",
    enroll: "Kunyoresa",
    home: "Kumba",
    loyalty: "Kuvimbika",
    enrollCustomers: "Nyoresa Vatengi",
    selected: "Selected",
    customer: "Mutengi",
    loyaltyActivationSuccessful: "Kuvimbika Kushanda kwakabudirira.",
    loyaltyDeactivationSuccessful: "Kuvimbika Kuremadza kuri kubudirira.",
    viewTier: "Wona Tier",
    deactivate: "De-Shandisa",
    activate: "Activate",
    actions: "Zviito",
    nameOfLoyalty: "Zita Rakavimbika",
    loyaltyStatus: "Kuvimbika MamiriroKuvimbika Mamiriro",
    numberOfTiers: "Nhamba yeTiresi",
    createdOn: "Yakagadzirwa On",
    createATier: "Gadzira Tier",
    stopCreatingTierConfirmation: "Iwe unoda kumira kugadzira iyo tier?",
    stopEditingTierConfirmation: "Iwe unoda kumira kugadzirisa iyi tier?",
    nameOfTier: "Zita reTier",
    minimumSpendingTarget: "Minimum Spending Target",
    maximumSpendingTarget: "Kunonyanyisa Kupedza Target",
    minimumSpendingTargetRequired: "mari shoma yekushandisa inodiwa",
    maximumSpendingTargetRequired: "kunonyanyisa kushandisa tarisiro kunodiwa",
    rewardSponsor: "Mubayiro Anotsigira",
    pleaseChooseARewardSponsor: "Ndokumbira usarudze mupi wemubairo",
    self: "Kuzvimirira",
    partner: "Partner",
    rewardPartner: "Mubayiro Partner",
    pleaseSelectRewardPartner: "Ndokumbira usarudze wako wekupa mubairo",
    rewards: "Mibayiro",
    pleaseSelectAReward: "Ndokumbira usarudze mubairo",
    instructionsOnRedeemingReward:
      "Mirayiridzo pamusoro pekuti Mutengi Anofanira Kununura Sei Mubairo",
    pleaseFillInThisField: "Ndapota Zadzai mundima iyi!",
    toDeleteThisTier: " kubvisa iyi tier",
    deleteTier: "Delete Tier",
    viewMembers: "Wona Nhengo",
    membersEnrolled: "Nhengo Dzakanyoreswa",
    instruction: "Mirayiridzo",
    membersIn: "Nhengo mu",
    availableTiersInLoyalty: "Inowanikwa Tier (s) muKuvimbika Chirongwa",
    tiers: "Matanho",
    totalTier: "YEMAHARA TIER",
    availableLoyaltyProgramme: "Inowanikwa Kuvimbika Chirongwa",
    totalLoyalties: "ZVAKAITIKA ZVAKAWANDA",
    memberDetails: "Nhengo Nhoroondo",
    nameOfCustomer: "Zita reMutengi",
    address: "Kero",
    allEnrolledMembers: "Nhengo Dzese Dzakanyoreswa",
    thisIsToWishYouHappyBirthday:
      "Izvi ndezvekukushuvira iwe zuva rekuzvarwa rakanaka uye hupenyu hunobudirira. Kutenda nezvose zvauri kuna Loyster. Mhemberero Dzinofadza!",
    inputAnOfferPlease: "Pinza Chipo ndapota",
    pleaseSelectTheInsertPoint:
      "Ndokumbira usarudze yekuisa poindi mumeseji wobva wadzvanya zvakare",
    birthdayOfferAndMessage: "Chipo cheBhavhadhe uye Meseji",
    birthdayOffer: "Chipo chekuzvarwa",
    birthdayMessage: "Bhavhadhe Mharidzo",
    noOfferFound: "Hapana kupihwa kwawanikwa",
    settingABirthdayOffer:
      "Kuisa zuva rekuzvarwa kunotendera vatengi kuti vagamuchire ichi kupihwa kuburikidza neSMS pazuva ravo rekuzvarwa",
    createOffer: "Gadzira Chipo",
    whatIsTheOffer: "Chii chiri kupihwa?",
    editMessage: "Hora Meseji",
    insert: "Isa",
    theNameOfCustomerInserted: "Zita remutengi richaiswa pano",
    theBirtdayOfferInserted: "Chipo chekuzvarwa chichaiswa pano",
    youSuccessfullyAddedNewBranch: "Iwe wakabudirira kuwedzera bazi idzva!",
    addNewBranch: "Wedzera Bazi Nyowani",
    addBranch: "Wedzera Bazi",
    additionalBranchWillIncur: "Yakawedzera Bazi yaizopinda",
    perBranch: "pabazi",
    ecommerceBranchCosts: "Ecommerce Bazi mari",
    pleaseEnterBranchName: "Ndokumbira kuti uise zita rebazi",
    pleaseEnterBranchAddress1: "Ndokumbirawo kuti uise kero yebazi yekero 1",
    enterBranchAddress1: "Pinda mutsara wekero webazi 1",
    enterBranchAddress2: "Pinda mutsara wekero webazi 1",
    pleaseEnterBranchAddress2: "Ndokumbirawo kuti uise kero yebazi yekero 2",
    enterBranchName: "Isa zita rebazi",
    successfullyAddedStaff: "Iwe wakabudirira kuwedzera vashandi vatsva!",
    addNewStaff: "Wedzera Vashandi Vatsva",
    addStaff: "Wedzera Vashandi",
    additionalStaffWillIncur: "Kuwedzera Vashandi vaizopinda",
    perStaff: "pamushandi.",
    pleaseEnterStaffEmail: "Ndokumbirawo unyore email yevashandi",
    pleaseEnterStaffUsername: "Ndapota nyora zita rekushandisa revashandi",
    pleaseEnterStaffPassword: "Ndokumbirawo unyore password yevashandi",
    pleaseSelectStaffRole: "Ndokumbira usarudze basa revashandi",
    selectStaffRole: "Sarudza basa revashandi",
    enterStaffEmail: "Pinda email yevashandi",
    enterStaffUsername: "Pinda zita rekushandisa revashandi",
    enterStaffPassword: "Pinda password yevashandi",
    spacesNotAllowedInUsername: "nzvimbo dzisingatenderwe muzita rekushandisa",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Ndokumbira usarudze bhizinesi kuti ubatanidze vashandi",
    searchForBranchToAttachStaff: "Tsvaga bazi kuti ubatanidze vashandi",
    username: "Username",
    role: "Basa",
    areYouSureToDeleteThis: "Une chokwadi chekudzima izvi",
    branches: "Mapazi",
    upgradeYourPlan: "Ndiwedzere hurongwa hwako.",
    add: "Wedzera",
    addNew: "Wedzera Zvitsva",
    customerWithEmailAlreadyExists:
      "Mutengi ane email / nhamba yefoni yatovepo!",
    successfullyAddedNewCustomer: "Iwe wakabudirira kuwedzera mutengi mutsva!",
    addCustomer: "Wedzera Mutengi",
    pleaseEnterCustomerFirstName: "Ndokumbira upinze zita rekutanga remutengi",
    pleaseEnterCustomerLastName:
      "Ndokumbira upinze zita rekupedzisira remutengi",
    pleaseEnterCustomerPhoneNumber:
      "Ndokumbirawo kuti uise nhamba yefoni yemutengi",
    pleaseEnterCustomerEmail: "Ndokumbira upinze email yemutengi",
    pleaseEnterCustomerAddressLine: "Ndokumbira upinze kero yevatengi yeKero",
    pleaseEnterCustomerOtherAddress: "Ndokumbirawo upinde imwe kero yemutengi",
    pleaseSelectCustomerGender: "Ndokumbira usarudze jekiseni revatengi",
    gender: "Gender",
    male: "Murume",
    female: "Mukadzi",
    bank: "Bank",
    selectBank: "Sarudza Bhangi",
    stateOrRegionOrProvince: "Nyika / Dunhu / Dunhu",
    customerNotes: "Makasitoma manotsi",
    sendSms: "Tumira SMS",
    editCustomer: "Rongedza Mutengi",
    redeemReward: "Rwira Reward",
    issueLoyaltyCard: "Bvisa Kadhi Yekuvimbika",
    deleteCustomer: "Delete Mutengi",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Iwe wakabudirira Kugovera Kuvimbika Nhengo ID",
    noMembershipIdAvailable:
      "Hapana maID ID ehunhengo anowanikwa. Ndapota taura nesu pa hello@loystar.co",
    sendEmail: "Tumira Email",
    membershipPoints: "Nhengo dzeMitezo",
    customerDetails: "Yevatengi Dhata",
    close: "Vhara",
    loyaltyBalance: "Kuvimbika Balance",
    pointsBalance: "Points Balance",
    starBalance: "Star Balance",
    requiredPoints: "Zvinodiwa Points",
    requiredStars: "Nyeredzi Dzinodiwa",
    reddemCode: "Nunura Code",
    toDeleteThisCustomer: "kudzima mutengi uyu",
    customerHasBeenDeletedSuccessfully: "Mutengi abviswa zvinobudirira!",
    customerWithPhoneAlreadyExists: "Mutengi ane nhamba yefoni atovepo",
    customerWasSuccessfullyEdited: "Mutengi akagadziridzwa zvinobudirira",
    anErrorOccured: "Pane zvakanganisika",
    phoneNumberIncludeCountry: "Foni Nhamba (Sanganisira Nyika Kodhi)",
    yourFileQueuedForUpload:
      "Faira rako raiswa mumutsetse kuti riiswe. Ndokumbirawo uvandudze peji racho mushure memasekondi mashoma.",
    thereWasAnErrorPerformingOperation:
      "Pane zvakakanganisa pakuita oparesheni!",
    pleaseSelectFile: "Ndapota Sarudza Faira!",
    oopsOnlyCsvFilesAllowed:
      "Oops! Chero ma CSV mafaera anotenderwa. Ndokumbira utakure iyo .csv faira.",
    fileShouldBeSmallerThan5Mb:
      "Faira rinofanira kuve diki pane 5MB. Kana iwe uine hombe faira, ndapota email email@loystar.co. Ndatenda",
    customerFirstNameIsRequired: "mutengi zita rekutanga rinodiwa pamutsara",
    customerPhoneNumberIsRequired:
      "nhamba yefoni yemutengi inodikanwa pamutsara",
    importCustomers: "Tumira Vatengi",
    upload: "Isa",
    clickIconToDownloadCsv:
      "Dzvanya iyi icon kudhanilodha iyo CSV faira template.",
    getGoogleSheetVersion: "Tora iyo Google sheet sheet",
    clickOrDragFileToUpload:
      "Dzvanya kana kudhonza faira kunharaunda ino kuti uise",
    missingOrInvalidColumnHeader:
      "Yakashaikwa kana isiriyo yekoramu musoro. Ndokumbirawo uteedzere iyo template fomati. Ndatenda.",
    youHaveImported: "Iwe Wakapinza",
    youSuccessfullyRedeemedReward: "Iwe wakabudirira kununura mubairo wako!",
    sixDigitCode: "Kodhi nhamba nhanhatu",
    pleaseEnterCustomerRewardCode:
      "Ndokumbirawo kuti uise kodhi yemutengi yevatengi",
    enterRewardCode:
      "Pinda kodhi yemubairo. (Iyo kodhi yemubairo inoenderana nenyaya)",
    selectLoyaltyProgram: "Sarudza kuvimbika chirongwa",
    stamps: "Zvidhindo",
    smsUnitsLow: "SMS Units Yakaderera",
    pleaseTopUpSmsUnits: "Ndokumbirawo Kumusoro Kumusoro SMS Units",
    smsSuccessfullySentForDelivery: "SMS yakabudirira kutumirwa kuendesa!",
    sendSmsTo: "Tumira SMS ku",
    allCustomers: "Vatengi vese",
    unitsAvailable: "Units Inowanikwa",
    pleaseTypeInTheMessage: "Ndokumbirawo utaure mumessage",
    message: "Meseji",
    charactersRemaining: "mavara asara",
    avoidUseOfSpecialCharacters:
      "Dzivisa kushandiswa kweVakakosha Maitiro uye Emojis kuchengetedza SMS Units.",
    note: "Tarira",
    errorFetchingCustomersMultilevelDetail:
      "Kukanganisa Kutora Vatengi MultiLevel Tsanangudzo",
    search: "Tsvaga",
    reset: "Dzorera zvakare",
    importCustomer: "Tumira Mutengi",
    addNewCustomer: "Wedzera Nyowani Mutengi",
    sendSmsBroadcast: "Tumira SMS Broadcast",
    totalCustomers: "KWEMAHARA Makasitoma",
    disbursementDetails: "Dzidziso Dzemubhadharo",
    paidBy: "Kubhadharwa Na",
    disbursed: "Yakadzingwa",
    bankAccountName: "Zita reBhangi reBhangi",
    bankAccountNumber: "Nhamba yeAkaundi yeBhangi",
    transferInitiated: "Chinjana Chakatangwa",
    transferCompleted: "Kutamisa Kwakapedzwa",
    pleaseEnterAValid: "Ndokumbira upinze chakakodzera",
    begin: "tanga",
    end: "magumo",
    date: "zuva",
    paymentDate: "Zuva Rekubhadhara",
    selectDisbursementDuration: "Sarudza Kubhadharwa Nguva",
    totalSettled: "Yese Yakagadziriswa",
    totalUnsettled: "Yese Isina Kugadzikana",
    toDeleteThisSale: "kudzima uku kutengesa",
    draftSaleDeletedSuccessfully:
      "Dhizaini yekutengesa yakabviswa zvinobudirira!",
    deleteSale: "Delete Kutengesa",
    pleaseTypeInYourTotal:
      "Ndokumbirawo utaure mune yako yakazara kuti usimbise kubviswa",
    billDetails: "Bhiri Ruzivo",
    printBill: "Dhinda Bhiri",
    servedBy: "Inoshandiswa ne",
    total: "Yese",
    createdDate: "Yakagadzirwa Zuva",
    createdTime: "Yakagadzirwa Nguva",
    status: "Chinzvimbo",
    loginSuccessful: "Kupinda Kunobudirira",
    pleaseWaitWhileWeConnectAccount:
      "Ndokumbirawo umirire patichabatanidza account yako",
    connectionFailedTryAgain: "Kubatana kwakundikana. Ndapota edza zvakare.",
    connectionSuccessful: "Kubatana Kunobudirira",
    viewDetails: "View Details",
    enable: "Gonesa",
    free: "Mahara",
    cost: "Mutengo",
    visitWebsite: "Shanyira Webhusaiti",
    pleaseUpgradeYourPlanToPro:
      "Ndokumbira uvandudze chirongwa chako kuPro Plus kuti zvibvumidze iyi app",
    connectYourBankAccount:
      "Unganidza yako Akaundi yeBhangi kuti ugone kugamuchira kubhadhara.",
    disable: "Disable",
    enableApp: "Gonesa App",
    addNewProductToInvoice: "Wedzera Chitsva Chigadzirwa kuInvoice",
    toDeleteThisInvoice: "kudzima Invoice iyi",
    invoiceDeletedSuccessfully: "Invoice yabviswa zvinobudirira!",
    deleteInvoice: "Delete Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Iwe wakabudirira kuwedzera iyo Invoice yekubhadhara meseji!",
    pleaseEnterPaymentInstructions:
      "Ndokumbira upinze mirayiridzo yekubhadhara",
    invoiceId: "ID yeinvoice",
    paidDate: "Zuva Rakabhadharwa",
    reference: "Refer",
    productAdded: "Chigadzirwa chakawedzerwa",
    productOutOfStock: "Chigadzirwa chapera. Ndapota re-stock.",
    totalInvoices: "ZVEKUWANIKWA INVOICES",
    totalPaidInvoices: "MAVHIDZO AKABHADZWA",
    totalUnpaidInvoices: "ZVEKUSHANDISWA ZVINOBHADHARA",
    loyaltyProgramDeleted: "Kuvimbika Chirongwa Chakadzimwa",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ichi chirongwa chekuvimbika chakabviswa zvinobudirira",
    thisLoyaltyProgramEditedSuccessfully:
      "Ichi chirongwa chekuvimbika chakagadziridzwa zvinobudirira",
    loyaltyProgramAddedSuccessfully:
      "Kuvimbika chirongwa chakawedzerwa zvinobudirira",
    loyaltyProgramEdited: "Kuvimbika Chirongwa Yakagadziriswa",
    loyaltyProgramAdded: "Kuvimbika Chirongwa Chakawedzerwa",
    loyaltyDetails: "Kuvimbika Dhata",
    doYouWantToCloseDialog: "Iwe unoda kuvhara iri dialog?",
    pleaseEnterLoyaltyName: "Ndapota nyora zita rekuvimbika kwako",
    programType: "Rudzi rwePurogiramu",
    pleaseSelectProgramType: "Ndokumbira usarudze chirongwa chechirongwa",
    simplePoints: "Pfungwa Dziri Nyore",
    stampsProgram: "Matampu Chirongwa",
    threshold: "Chikumbaridzo",
    pleaseEnterLoyaltyThreshold: "Ndokumbira upinze kuvimbika chikumbaridzo",
    reward: "Mubayiro",
    pleaseEnterLoyaltyReward: "Ndokumbira upinze mubairo wekuvimbika",
    totalUserPoints: "Yese Mushandisi Points",
    totalUserStamps: "Yese Mushandisi Matampu",
    spendingTarget: "Kupedza tarisiro",
    spendingTargetHint1:
      "Kushandisa tarisiro ndeyekuti mutengi anofanira kushandisa marii kuwana mubairo. 1 mari kukosha = 1 poindi.",
    spendingTargetHint2:
      "Matampu anotarisirwa kuti mangani matampu anotengwa nemutengi kuti awane mubairo. semuenzaniso. 5",
    addNewLoyaltyProgram: "Wedzera Nyowani Yekuvimbika Chirongwa",
    addLoyaltyProgram: "Wedzera Kuvimbika Chirongwa",
    loyaltyProgramType: "Kuvimbika Chirongwa Type",
    pleaseSelectLoyaltyProgramType:
      "Ndokumbira usarudze rudzi rwechirongwa chekuvimbika",
    nameOfProgram: "Zita rechirongwa",
    pleaseEnterProgramName: "Ndokumbirawo unyore zita rechirongwa",
    whatIsTheReward: "Ndeupi mubairo?",
    egNextPurchaseIsFree: "Muenzaniso Inotevera kutenga mahara",
    customerName: "Zita reMutengi",
    guestCustomer: "Mutengi Mutengi",
    orderConfirmedSuccessfully: "Order yakasimbiswa zvinobudirira",
    orderCancelledSuccessfully: "Order yakanzurwa zvinobudirira",
    confirmOrder: "Simbisa kurongeka",
    cancelOrder: "Kanzura odha",
    allOrders: "Yese Mirairo",
    totalOrders: "ZVEMAHARA ZVOSE",
    doYouWantToAcceptOrder: "Iwe unoda here kugamuchira iyi odha?",
    doYouWantToCancelOrder: "Unoda here kukanzura odha iyi?",
    orderDetails: "Hodha Dzemashoko",
    orderCreatedAt: "Order yakagadzirwa pa",
    supplier: "Nokutengesa",
    productName: "Chigadzirwa Chigadzirwa",
    quantity: "Zvakawanda",
    unitPrice: "Mutengo wechikamu",
    receivedBy: "Yakagamuchirwa Na",
    reportFrom: "Report from",
    totalSupplies: "Zvese Zvigadzirwa",
    allRightsReserved: "Kodzero Dzese dzakachengetedzwa",
    toDeleteThisTransaction: "kudzima Transaction iyi",
    transactionDeletedSuccessfully:
      "Transaction yabviswa zvinobudirira. Stock yakadzoserwa mukuverenga.",
    deleteTransaction: "Delete Transaction",
    transactionDetails: "Zvekufambisa Nhoroondo",
    printReceipt: "Dhinda Risiti",
    channel: "Chiteshi",
    discount: "Discount",
    profit: "Profit",
    discountedSales: "Kutengesa Kwakadzikiswa",
    errorFetchingRecord: "Kukanganisa Kutora Rekodhi",
    exportTransactions: "Export Transaction",
    errorFetchingSalesRecord:
      "Kukanganisa kutora Rekodhi rekutengesa reExpport.",
    totalSellingPrice: "Yese Kutengesa Mutengo",
    totalCostPrice: "Yese Mutengo Wemutengo",
    appliedDiscount: "Zvakarurama Discount",
    noOfItems: "Nha yezvinhu",
    sales: "Sales",
    export: "Export",
    totalProfit: "Yese purofiti",
    totalBalanceInPeriod: "Yakazara Balance muPeriod",
    allTimeSales: "Yese Nguva Yekutengesa",
    records: "Zvinyorwa",
    todaysSales: "Nhasi Kutengesa",
    transaction: "mutengapwe",
    youHaveExceededTotalNumberOfProducts:
      "Iwe wapfuura huwandu hwakazara hwezvigadzirwa zvinotenderwa pane yako hurongwa. Simudzira chirongwa chako kuti unakirwe neyakaganhurirwa yakakwira.",
    youNeedToHaveLoyaltyProgram:
      "Iwe Unofanirwa kuve neRakavimbika Chirongwa kuti ushandise chimiro ichi!",
    price: "Mutengo",
    category: "Chikamu",
    stockTracking: "Stock Kuchengeta",
    stockCount: "Stock Kuverenga",
    taxed: "Mutero",
    originalPrice: "Mutengo Wepakutanga",
    costPrice: "Mutengo Wemutengo",
    unit: "Chikwata",
    productImage: "Chigadzirwa Chigadzirwa",
    taxRate: "Mutero Mutero",
    taxType: "Mutero Wemutero",
    trackInventory: "Tevera Inventory",
    variants: "Variants",
    hasVariants: "Ine Zvimwe",
    importProduct: "Tumira Chigadzirwa",
    exportProducts: "Export Zvigadzirwa",
    addNewProduct: "Wedzera Chitsva Chigadzirwa",
    viewCategory: "Wona Chikamu",
    viewSuppliers: "Wona Vatengesi",
    receiveInventory: "Gamuchira Inventory",
    printAllProductsTag: "Dhinda Zvese Zvigadzirwa Tag",
    deleteAll: "Delete All",
    totalProducts: "ZVIITIKO ZVOSE",
    youveSuccessfullyAddedANewCategory:
      "Iwe wakabudirira kuwedzera chikamu chitsva",
    addNewCategory: "Wedzera Nyowani Chikamu",
    addCategory: "Wedzera Chikamu",
    categoryName: "Chikamu Zita",
    pleaseEnterCategoryName: "Ndokumbira upinze zita rechikamu",
    stampsTarget: "Matampu Target",
    sendInventory: "Tumira Inventory",
    productDetails: "Chigadzirwa Zvinyorwa",
    youveSuccessfullyEditedThisCategory:
      "Iwe wakabudirira kugadzirisa chikamu ichi",
    update: "Kwidziridza",
    categoryList: "Chikamu Chinyorwa",
    categories: "Zvikamu",
    enterQuantityToUpdate: "Pinda uwandu kuti uvandudze",
    inventorySentSuccessfully: "Iko kuverenga kwakatumirwa zvakabudirira!",
    updateInventory: "Gadziridza Inventory",
    currentQuantity: "Yazvino Huwandu",
    pleaseEnterQuantityToAdd: "Ndokumbira upinze huwandu hwaunoda kuwedzera",
    pleaseSelectABranch: "Ndokumbira usarudze Bazi",
    searchForBranch: "Tsvaga bazi",
    youCantSendMoreThanStock:
      "Iwe haugone kutumira zvinopfuura zvaunazvo mudura",
    send: "Tumira",
    pleaseEnterQuantityToSend: "Ndokumbira upinze huwandu hwaunoda kutumira",
    productNameIsRequiredOnRow: "chigadzirwa zita rinodiwa pamutsara",
    productCategoryIsRequiredOnRow:
      "chigadzirwa chigadzirwa chinodiwa pamutsara",
    productPriceIsRequiredOnRow: "chigadzirwa chigadzirwa chinodiwa pamutsara",
    productUnitIsRequiredOnRow: "chigadzirwa chigadzirwa chinodiwa pamutsara",
    productQuantityIsRequiredOnRow: "chigadzirwa huwandu hunodiwa pamutsara",
    productVariantsRequireTracking:
      "zvigadzirwa zvakasiyana zvinoda kuteedzera!",
    pleaseAddVariantClickButton:
      "Ndokumbirawo uwedzere musiyano nekudzvanya bhatani rekuwedzera!",
    totalVariantsMoreThanSelectedQuantity:
      "Yese Misiyano yakawanda kupfuura yakasarudzwa chigadzirwa huwandu, Pls inoderedza akasiyana huwandu",
    productEditedSuccessfully: "Chigadzirwa chakagadziridzwa zvinobudirira!",
    fileTooLargeLessThan4Mb:
      "Saizi yefaira yakanyanya kukura! Saizi yefaira inofanira kunge iri pasi pe4MB.",
    suchVariantAlreadyExist: "Musiyano wakadaro watovepo",
    addVariants: "Wedzera Zvimwe",
    editProduct: "Rongedza Chigadzirwa",
    pleaseEnterProductName: "Ndokumbirawo Unyore Chigadzirwa Chigadzirwa",
    pleaseEnterProductPrice: "Ndokumbirawo upinde Chigadzirwa Chigadzirwa",
    pleaseEnterProductOriginalPrice:
      "Ndokumbirawo upinde Chigadzirwa Chekutanga Mutengo",
    productDescription: "Chigadzirwa Chigadzirwa",
    selectProductCategory: "Sarudza Chigadzirwa Chikamu",
    pleaseSelectProductCategory: "Ndokumbira usarudze chigadzirwa chikamu",
    productCostPrice: "Chigadzirwa Mutengo Mutengo",
    productSellingPrice: "Chigadzirwa Kutengesa Mutengo",
    productOriginalPrice: "Chigadzirwa Chekutanga Mutengo",
    maxFileSizeAllowedIs4Mb: "Max. Saizi Saizi inobvumidzwa ndeye 4mb",
    productsWithPicturesArePublished:
      "Zvigadzirwa zvine mifananidzo zvinoburitswa paDisco kuti ugamuchire maodha",
    shouldThisProductBeTracked:
      "Ichi chigadzirwa chinofanira kuteedzerwa here?",
    pleaseSelectStockUnit: "Ndokumbira usarudze stock stock",
    stockUnit: "Stock Chikwata",
    bag: "BAG",
    bottle: "Bhodhoro",
    bunch: "BUNCH",
    can: "UNOGONA",
    carton: "Katoni",
    crate: "KARI",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "Rongedza",
    pair: "BHUKU",
    pieces: "ZVIDimbu",
    plate: "Ndiro",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Ndokumbirawo uise chigadzirwa huwandu",
    productQuantity: "Chigadzirwa Chiwanda",
    isThisProductTaxed: "Ichi chigadzirwa chakabhadhariswa mutero here?",
    selectTaxType: "Sarudza Mutero Werudzi",
    pleaseSelectTaxType: "Ndokumbira usarudze Rudzi rweMutero",
    progressive: "Kufambira mberi",
    proportional: "Proportional",
    pleaseEnterProductTaxRate:
      "Ndokumbira upinze chigadzirwa chemutero chiyero",
    doesProductHaveVariants: "Ichi chigadzirwa chine zvakasiyana here?",
    type: "Type",
    value: "Kukosha",
    pleaseEnterVariantType: "Ndokumbira upinze mhando yemhando",
    pleaseEnterVariantValue: "Ndokumbira upinze kukosha kwakasiyana",
    pleaseEnterVariantPrice: "Ndokumbirawo upinze mutengo wemusiyano",
    pleaseEnterVariantQuantity: "Ndokumbira upinze huwandu hwakawanda",
    productDeletedSuccessfully: "Chigadzirwa chakabviswa zvinobudirira!",
    categoryDeletedSuccessfully: "Chikamu chakabviswa zvinobudirira!",
    toDeleteThisProduct: "kudzima ichi Chigadzirwa",
    invalidProductQuantity: "Haushandi Chigadzirwa",
    quantityAddedIsOverStock:
      "Huwandu hwauri kuwedzera hwadarika pane zvaunazvo.",
    itemInventoryNotTracked: "Item hesera haina kuteverwa",
    addBulkQuantity: "Wedzera Bulk Yakawanda",
    enterBulkQuantity: "Pinda Bulk Yakawanda",
    pleaseEnterBulkQuantity: "Ndokumbira upinze Bulk Yakawanda",
    youveSuccessfullyAddedANewProduct:
      "Iwe wakabudirira kuwedzera chigadzirwa chitsva!",
    pleaseEnterProductSellingPrice:
      "Ndokumbira upinze chigadzirwa kutengesa mutengo",
    doYouWantToTrackProductStock: "Iwe unoda kuteedzera chigadzirwa stock?",
    sellingPrice: "Kutengesa Mutengo",
    setProductExpiryReminder: "Gadza Chiyeuchidzo Chekupera Chigadzirwa",
    productExpiryDate: "Chigadzirwa Kupera zuva",
    startRemindingFrom: "Kutanga Kuyeuchidza Kubva",
    productSuppliesAddedSuccessfully:
      "Wakawedzera zvigadzirwa zvigadzirwa zvinobudirira.",
    addProductSupplies: "Wedzera Zvigadzirwa Zvechigadzirwa",
    pleaseSelectSupplier: "Ndapota Sarudza Mutengesi",
    nameOfProduct: "Zita Rezvigadzirwa",
    pleaseSelectProduct: "Ndapota Sarudza Chigadzirwa",
    productVariant: "Chigadzirwa Chinosiyana",
    pleaseSelectAVariant: "Ndokumbira Sarudza Musiyano",
    pleaseEnterUnitPrice: "Ndokumbirawo upinze Unit Mutengo",
    businessBranch: "Bhizinesi Bazi",
    pleaseSelectBranch: "Ndokumbira usarudze bazi",
    youveSuccessfullyAddedANewSupplier:
      "Iwe wakabudirira kuwedzera mutengesi mutsva",
    addSupplier: "Wedzera Mutengesi",
    pleaseEnterSupplierEmail: "Ndokumbirawo upinze email yemutengesi",
    pleaseAddADescription: "Ndokumbirawo uwedzere tsananguro",
    anErrorOccuredProductsDeleted:
      "Iko kukanganisa kwakaitika panguva yekuita oparesheni. Ndokumbira utarise, zvigadzirwa zvishoma zvinogona kunge zvakabviswa mukuita",
    bulkDelete: "Kudzima Kwakawanda",
    youAreAboutToDelete: "Iwe wave kuda kudzima",
    product: "Chigadzirwa",
    isDueToRedeem: "inofanirwa kudzikunura",
    aReward: "mubairo",
    pleaseSelectCustomerToReeemReward:
      "Ndokumbira usarudze mutengi kudzikinura mubairo.",
    youHaveNoLoyaltyProgramRunning:
      "Iwe hauna chirongwa chekuvimbika chinoshanda",
    customerHhasNoPointsInLoyaltyProgram:
      "Mutengi haana mapoinzi muchirongwa ichi chekuvimbika",
    proceedWithPayment: "Pfuurira neMubhadharo?",
    youAreAboutToPayForTransactionUsingPoints:
      "Iwe wave kuda kubhadhara mutengisirwo uchishandisa mapoinzi.",
    customerHas: "Mutengi Ane",
    worth: "kukosha",
    andIsNotSufficientToPayForTransaction:
      "uye haina kukwana kubhadhara pachiitiko ichi. Vangada here kuwedzera bhalansi vachishandisa imwe nzira yekubhadhara?",
    addCustomerLoyalty: "Wedzera Kuvimbika Kwevatengi",
    pleaseAddCustomerFirst:
      "Ndokumbirawo uwedzere kana kusarudza mutengi kutanga.",
    pleaseWaitWhileWeGetReady: "Ndokumbira umirire, isu tichigadzirira",
    lowStock: "Yakaderera Stock",
    pleaseEnterAmountTendered: "Ndokumbira upinze huwandu hwakatenderedzwa",
    areYouSureToBookSaleOffline:
      "Une chokwadi here kuti unoda kubhuka kutengesa uku kunze kwenyika?",
    saleWouldBeBookedAutomatically:
      "Kutengesa kwaizogadziriswa otomatiki kana iwe ukavhura yako internet",
    offlineSalesBookingCancelled:
      "Kubhuka kutengesa pasipo pamhepo kwakanzurwa",
    covid19Message:
      "COVID19: Geza maoko ako nesipo kana Sanitize kumisa kupararira. Gara Wakachengeteka Nguva Dzose",
    saleSuccessfullyRecorded: "Kutengesa Zvinobudirira Zvakanyorwa!",
    sendReceiptToEmail: "Tumira risiti kuemail",
    sendThankYouSms: "Tumira SMS yekutenda",
    sendShippingDetails: "Tumira Kutumira ruzivo",
    addLoyalty: "Wedzera Kuvimbika",
    searchCustomerNameOrNumber: "Tsvaga zita remutengi kana nhamba",
    clickTheSearchCustomerBox:
      "Dzvanya pakutsvaga Kwevatengi Bhokisi uye Scan Kadhi",
    enterProductPrice: "Pinda Chigadzirwa Chigadzirwa",
    enterPriceFor: "Pinda Mutengo we",
    searchForProduct: "Tsvaga chigadzirwa",
    all: "Zvese",
    backToDashboard: "Dzokera kuDashboard",
    viewDraftSales: "Wona Dhizaini Yekutengesa",
    variantSelected: "zvakasiyana zvakasarudzwa",
    variant: "zvakasiyana",
    thePreviousVariantSelectionNotAvailable:
      "Sarudzo yakasarudzika yapfuura haiwanikwi kune imwe nyowani yakasarudzwa zvichienderana nemutengo, ndapota chinja sarudzo yako.",
    pleaseSupplyPositiveQuantityNumber:
      "Ndokumbirawo upe postive huwandu huwandu",
    lowStockFor: "Yakaderera stock ye",
    clearVariants: "Bvisa zvakasiyana",
    pleaseEnterQuantity: "Ndokumbira upinze huwandu",
    picture: "Mufananidzo",
    redemptionToken: "Rudzikinuro Chiratidzo",
    token: "Chiratidzo",
    totalSpent: "Yese Yakashandiswa",
    confirmPayment: "Simbisa Kubhadhara",
    hasPaymentBeenMade: "Kubhadhara kwakagadziriswa zvinobudirira here?",
    enterTransactionReference: "Pinda mutero wekutengesa wawakabhadhara nawo",
    pleaseSelectACustomer: "Ndokumbira usarudze mutengi!",
    areYouSureToApplyDiscount:
      "Une chokwadi here kuti unoda kuisa dhisikaundi?",
    addYourBankAccountToEnableUssd:
      "Wedzera account yako yekubhangi kuti ugone kugonesa Instant USSD naPay",
    totalAmountToPay: "Yese Mari Yekubhadhara",
    doYouWantToCancelTransaction: "Iwe unoda here kukanzura chiitiko ichi?",
    savePrintBill: "Sevha / Dhinda Bhiri",
    enterAmountCollectedForCustomer: "Isa mari yakaunganidzwa kumutengi",
    recordSale: "Rekodhi Kutengesa",
    checkOutWith: "Tarisa ne",
    instantTransfer: "Instant Kutamisirwa",
    dialTheUSSDCode: "Dhonza iyo USSD kodhi",
    pleaseSelectABank: "Ndokumbira usarudze bhangi",
    payWithUSSD: "Bhadhara NaUSSD",
    sendBillTo: " - Tumira Bhiri ku",
    waitingForUSSDTransfer: "Kumirira USSD Kutamisirwa",
    percent: "Percent",
    applyDiscount: "Shandisa Discount",
    thisBillHasBeenSaved: "Bhiri iri rakachengetedzwa",
    saveDraft: "Sevha Dhizaini",
    pleaseTypeANameToIdentifyCustomer:
      "Ndokumbirawo utaure muzita kuti uone mutengi",
    paymentDetails: "Zvekubhadhara",
    basePrice: "Base Mutengo",
    staff: "Vashandi",
    subTotal: "SubTotal",
    durationMonths: "Nguva (mwedzi)",
    selectADuration: "Sarudza Nguva",
    oneMonth: "1 Mwedzi",
    twoMonths: "2 Mwedzi",
    threeMonths: "3 Mwedzi",
    sixMonths: "Mwedzi mitanhatu",
    twelveMonths: "Mwedzi gumi nemiviri",
    eighteenMonths: "Mwedzi 18",
    twentyFourMonths: "24 Mwedzi",
    twoMonthsFree: "(2 Mwedzi Yemahara)",
    threeMonthsFree: "(3 Mwedzi Yemahara)",
    fiveMonthsFree: "(5 Mwedzi Yemahara)",
    yourAccountHasBeen: "Akaunti yako yanga iri",
    renewed: "kuvandudzwa",
    upgraded: "kukwidziridzwa",
    successfully: "kubudirira",
    yourSubscription: "Kunyorera kwako",
    youAreCurrentlyEnrolledOnA: "Iwe parizvino wakanyoreswa pane",
    pleaseChooseAPaymentOption: "Ndokumbira Usarudze Yekubhadhara Sarudzo",
    planRenewal: "Ronga Kuvandudza",
    planUpgrade: "Ronga Kuvandudza",
    pleaseSelectDurationToPayFor: "Ndapota Sarudza Nguva yaunofunga kubhadhara",
    staffAccounts: "Maakaundi evashandi",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "Ndokumbira usarudze chirongwa",
    includeAddons: "Sanganisira Wedzera-ons",
    viewTransactions: "Wona Kutengesa",
    customerHasNoCompletedTansactions:
      "Mutengi haasati apedza tansaction izvozvi",
    branch: "Bazi",
    enterNumberOfEcommerceBranches: "Pinda Nhamba YeEcommerce Mapazi",
    enterNumberOfEcommerceBranchesToPay:
      "Pinda Nhamba YeEcommerce Mapazi Aunofunga Kubhadhara Kwawo",
    ecommerceIntegration: "Ecommerce Kubatanidzwa",
    enterNumberOfBranches: "Pinda Nhamba Yemapazi",
    enterNumberOfAdditionalBranchesToPay:
      "Pinda Nhamba Yemamwe Mapazi Aunofunga Kubhadhara",
    enterNumberOfStaffs: "Pinda Nhamba Yevashandi",
    enterNumberOfStaffsToPayFor:
      "Pinda Nhamba Yevashandi Waunofunga Kubhadhara",
    discountApplies: "Discount Inoshanda",
    starsOnThe: "nyeredzi pa",
    offer: "chipo",
    get: "Tora",
    moreStarsToRedeem: "dzimwe nyeredzi kudzikunura",
    taxVat: "Mutero (VAT)",
    callCashierToCompletePayment: "Fonera Cashier kuti Apedze Kubhadhara",
    receipt: "Risiti",
    dear: "Anodiwa",
    thankYouForYourOrderFindGoods:
      "Ndatenda neodha yako. Ndokumbira utore zvinhu zvinotevera zvinopihwa, sekubvumirana.",
    shippingNote: "Kutumira Chiziviso",
    enterShippingNote: "Pinda Kutumira Chiziviso",
    shippingAddress: "Kero yekutumira",
    enterShippingAddress: "Pinda Kero yekutumira",
    wellDoneYouAreDueToRedeem: "Kuita zvakanaka! Iwe unofanirwa kudzikunura",
    toGetYourRewardNextVisit:
      "kuti uwane mubairo wako pakushanya kwako kunotevera. Ndatenda",
    pointsOnThe: "Pfungwa pane",
    morePointsToRedeem: "mamwe mapoinzi ekudzikinura",
    showCode: "Ratidza kodhi",
    toGetYourRewardOnNextVisit:
      "kuti uwane mubairo wako pakushanya kwako kunotevera. Ndatenda",
    earn: "Mubhadharo",
    delivaryNote: "Delivary Cherechedzo",
    draftSales: "Draft Sales",
    startDate: "Kutanga zuva",
    endDate: "Zuva rekuguma",
    orders: "Mirairo",
    checkout: "buda",
    noProductItem: "Hapana Chigadzirwa Chinhu",
    selectProductImage: "Sarudza Chigadzirwa Mufananidzo",
    selectCountry: "Sarudza nyika",
    selectRegion: "Sarudza Nyika / Dunhu",
    printProductTag: "Dhinda Chigadzirwa Tag",
    transactionReference: "Chiitiko chekutengesa",
    Cashier: "Cashier",
    Manager: "Manager",
    Owner: "Muridzi",
    Admin: "Admin",
    addPartners: "Wedzera Partners",
    addNewLoyaltyPartner: "Wedzera New Loyalty Partner",
    pleaseEnterCompanyName: "Ndokumbira uise Zita reKambani",
    companyName: "Zita rekambani",
    pleaseEnterCompanyRepName: "Ndokumbira uise zita remumiriri wekambani",
    companyRepName: "Zita reMumiriri weKambani",
    pleaseEnterCompanyRepEmail: "Ndokumbira uise email yemumiriri wekambani",
    companyRepEmail: "Email yekambani rep",
    pleaseEnterCompanyRepPhone:
      "Ndapota isa nhamba yefoni yemumiriri wekambani",
    companyRepPhone: "Nhamba Yefoni yekambani rep",
    addReward: "Wedzera mubayiro",
    pleaseEnterRewardName: "Ndapota isa zita remubairo",
    rewardName: "Zita Reward",
    rewardQuantity: "Reward Quantity",
    rewardDescription: "Reward Description",
    rewardType: "Reward Type",
    pleaseEnterRewardType: "Ndapota isa mhando yemubayiro",
    pleaseEnterRewardQuantity: "Ndapota isa huwandu hwemubairo",
    pleaseEnterRewardDescription: "Ndapota isa tsananguro yemubairo",
    fineDining: "Fine Dining",
    luxuryFashion: "Luxury Fashion",
    hotels: "Hotels",
    travel: "Travel",
    foodAndBeverage: "Zvokudya uye Zvinwiwa",
    fashion: "Fashion",
    health: "Utano",
    furniture: "Furniture",
    entertainment: "Varaidzo",
    automobile: "Automobile",
    education: "Dzidzo",
    beautyAndSpa: "Runako uye Spa",
    staycation: "Staycation",
    events: "Zviitiko",
    trips: "Trips",
    oilAndGas: "Mafuta neGasi",
    laundry: "Kuwacha",
    partnerCategory: "Partner Category",
    freeItem: "Yemahara Item",
  },
  Sinhala: {
    cashier: "මුදල් අයකැමියා",
    manager: "කළමනාකරු",
    owner: "හිමිකරු",
    online: "සමඟ අමුත්තන්",
    offline: "නොබැඳි",
    changePassword: "මුරපදය වෙනස් කරන්න",
    currentPasswordMessage: "කරුණාකර ඔබගේ වර්තමාන මුරපදය ඇතුළත් කරන්න",
    passwordMessage: "ඔබගේ මුරපදය ඇතුල් කරන්න",
    currentPassword: "වත්මන් මුර පදය",
    password: "රහස් පදය",
    confirmPasswordMessage: "කරුණාකර ඔබගේ මුරපදය තහවුරු කරන්න!",
    confirmPassword: "මුරපදය තහවුරු කරන්න",
    sendViaEmail: "විද්‍යුත් තැපෑල හරහා යවන්න",
    sendViaWhatsapp: "WhatsApp හරහා යවන්න",
    downloadPdf: "PDF බාගන්න",
    paid: "ගෙවා ඇත",
    unpaid: "නොගෙවූ",
    partial: "අර්ධ වශයෙන්",
    closeInvoice: "ඔබට ඉන්වොයිසිය වසා දැමීමට අවශ්‍යද?",
    closeInvoiceConfirmation:
      "ඉන්වොයිසිය සුරැකිය නොහැක. ඔබට වසා දැමීමට අවශ්‍යද?",
    yes: "ඔව්",
    no: "නැත",
    invoice: "ඉන්වොයිසිය",
    wasDeducted: "අඩු කරන ලදි",
    for: "සඳහා",
    item: "අයිතමය",
    addProduct: "නිෂ්පාදන එකතු කරන්න",
    paymentReference: "ගෙවීම් සඳහනක්",
    amountPaid: "ගෙවූ මුදල",
    discountAmount: "වට්ටම් මුදල",
    amountDue: "ගෙවිය යුතු මුදල",
    amount: "ප්රමාණය",
    dueDate: "ගෙවිය යුතු දිනය",
    paymentType: "ගෙවීම් වර්ගය",
    card: "කාඩ්පත",
    cash: "මුදල්",
    bankTransfer: "බැංකු මාරු කිරීම",
    paymentMessage: "ගෙවීම් පණිවිඩය",
    description: "විස්තර",
    sendReceipt: "රිසිට්පත යවන්න",
    delete: "මකන්න",
    save: "සුරකින්න",
    resend: "නැවත යවන්න",
    saveAndSend: "සුරකින්න",
    invoiceSaved: "ඉන්වොයිසිය සුරකින ලදි!",
    selectPaymentMethod: "කරුණාකර ගෙවීමේ ක්‍රමයක් තෝරන්න!",
    selectCustomer: "කරුණාකර පාරිභෝගිකයෙකු තෝරන්න!",
    cartEmptyError:
      "කරත්ත ලැයිස්තුව හිස් විය නොහැක, ඉන්වොයිසිය වසා දමා කරත්තයට අයිතමය එක් කරන්න!",
    subscriptionExpired:
      "ඔබේ දායකත්‍වය කල් ඉකුත් වී ඇති අතර ඔබේ ගිණුම දැන් සීමිතය. ඔබේ ගිණුම අලුත් කිරීම සඳහා පහත බොත්තම ක්ලික් කරන්න",
    renew: "අලුත් කරන්න",
    holdOn: "නවත් වන්න",
    customerBank: "පාරිභෝගික බැංකුව",
    cancel: "අවලංගු කරන්න",
    selectACustomer: "පාරිභෝගිකයා තෝරන්න",
    invoiceSuccessfulPdfError:
      "ඉන්වොයිසිය සාර්ථකව සාදන ලද නමුත් පීඩීඑෆ් උත්පාදනය වෙනදාට වඩා වැඩි කාලයක් ගත වේ. කරුණාකර ඉක්මනින් නැවත පරීක්ෂා කරන්න.",
    downloadingInvoice: "ඉන්වොයිසිය බාගැනීම",
    downloadingReceipt: "කුවිතාන්සිය බාගැනීම",
    whatsappReceiptError:
      "WhatsApp හරහා රිසිට්පත යැවීමේදී දෝෂයක් සිදු විය, කරුණාකර නැවත උත්සාහ කරන්න.",
    receiptToWhatsapp: "කුවිතාන්සිය WhatsApp වෙත යවන ලදි",
    thankYouForPatronage: "ඔබගේ අනුග්‍රහය සඳහා ඔබට ස්තුතියි",
    hereIsYourReceipt: "මෙන්න ඔබේ ගෙවීම් ලදුපත",
    errorSendingEmailReceipt:
      "විද්‍යුත් තැපෑල හරහා ලදුපත යැවීමේදී දෝෂයක් සිදු විය, කරුණාකර නැවත උත්සාහ කරන්න නැතහොත් සහාය අමතන්න",
    receiptSentToEmail: "කුවිතාන්සිය පාරිභෝගිකයාගේ විද්‍යුත් තැපෑල වෙත යවා ඇත",
    invoiceSentToEmail: "ඉන්වොයිසිය පාරිභෝගිකයාගේ විද්‍යුත් තැපෑල වෙත යවා ඇත",
    invoiceSuccessWhatsappError:
      "ඉන්වොයිසිය සාර්ථකව සාදන ලද නමුත් WhatsApp වෙත යැවීමේදී දෝෂයක් සිදු විය. ඉන්වොයිසි ලැයිස්තුවේ නැවත උත්සාහ කරන්න",
    invoiceSuccessfulEmailError:
      "ඉන්වොයිසිය සාර්ථකව සාදන ලද නමුත් විද්‍යුත් තැපෑල ලෙස යවන විට දෝෂයක් සිදු විය. කරුණාකර ඉන්වොයිසි ලැයිස්තුවෙන් නැවත උත්සාහ කරන්න",
    invoiceSentToWhatsapp: "ඉන්වොයිසිය WhatsApp වෙත යවන ලදි",
    hello: "ආයුබෝවන්",
    pleaseDownloadInvoice: "කරුණාකර ඉන්වොයිසිය බාගන්න",
    pleaseDownloadReceipt: "කරුණාකර රිසිට්පත බාගන්න",
    from: "සිට",
    email: "විද්යුත් තැපෑල",
    upgrade: "උසස් කරන්න",
    youAreOnFreePlan: "ඔබ සිටින්නේ නිදහස් සැලැස්මක් මත ය.",
    clickOn: "මත ක්ලික් කරන්න",
    yourPlanInFewSteps: " ඉක්මන් පියවර කිහිපයකින් ඔබේ දායක වීම.",
    to: "වෙත",
    yourSubscriptionHasExpired:
      "ඔබේ දායකත්‍වය කල් ඉකුත් වී ඇති අතර ඔබේ ගිණුම දැන් සීමිතය.",
    days: "දින",
    yourSubscriptionExpiresIn: "ඔබේ ලොයිස්ටාර් දායකත්වය කල් ඉකුත් වේ",
    createAcount: "ගිණුමක් තනන්න",
    signIn: "පුරන්න",
    continue: "දිගටම කරගෙන යන්න",
    enterOtp: "OTP PIN ඇතුළු කරන්න",
    enterValidOtp: "වලංගු PIN අංකයක් ඇතුළු කරන්න",
    pin: "PIN අංකය",
    tokenSentToMail: "ඔබේ විද්‍යුත් තැපෑලට සංකේතයක් යවා ඇත",
    passwordResetSuccessful: "මුරපද යළි පිහිටුවීම සාර්ථක විය",
    somethingWentWrong: "මොකක්හරි වැරැද්දක් වෙලා!",
    resetPassword: "මුරපදය නැවත සකසන්න",
    iHaveResetCode: "මට මුරපද යළි පිහිටුවීමේ කේතයක් ඇත",
    pleaseEnterEmail: "කරුණාකර ඔබේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    aTokenWillBeSentToEmail: "ඔබේ විද්‍යුත් තැපෑලට ටෝකනයක් එවනු ඇත",
    enterEmail: "ඔබේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    sendinYourToken: "ඔබේ ටෝකනය යවමින් ...",
    makeSureItMatchesPassword: "එය ඔබගේ නව මුරපදයට ගැලපෙන බවට වග බලා ගන්න",
    pleaseChooseNewPassword: "කරුණාකර නව මුරපදයක් තෝරන්න",
    chooseNewPassword: "නව මුරපදයක් තෝරන්න",
    enterNewPassword: "තහවුරු කිරීම සඳහා ඔබේ නව මුරපදය ඇතුළත් කරන්න",
    enterTokenSent: "ඔබේ තැපෑලෙන් එවූ සංකේතය ඇතුළත් කරන්න",
    resetToken: "ටෝකනය නැවත සකසන්න",
    resettingPassword: "ඔබේ මුරපදය නැවත සකසමින් ...",
    signUp: "ලියාපදිංචි වන්න",
    adminSignInWithEmail:
      " පරිපාලක විද්‍යුත් තැපෑලෙන් ඇතුළු වන අතර කාර්ය මණ්ඩලය පරිශීලක නාමයෙන් පුරනය වේ.",
    pleaseEnterEmailOrUsername:
      "කරුණාකර ඔබේ විද්‍යුත් තැපෑල හෝ පරිශීලක නාමය ඇතුළත් කරන්න",
    emailOrUsername: "විද්‍යුත් තැපෑල හෝ පරිශීලක නාමය",
    pleaseEnterPassword: "කරුණාකර මුරපදය ඇතුළත් කරන්න",
    createAnAccount: "ගිණුමක් තනන්න",
    forgotPassword: "මුරපදය අමතක වුණා ද?",
    enterPhoneNumber: "දුරකථන අංකය ඇතුළත් කරන්න",
    personalData: "පෞද්ගලික දත්ත",
    validateConfirmationCOde: "තහවුරු කිරීමේ කේතය වලංගු කරන්න",
    pleaseEnterFirstName: "කරුණාකර ඔබේ පළමු නම ඇතුළත් කරන්න",
    pleaseEnterPhoneNumber: "කරුණාකර ඔබේ දුරකථන අංකය ඇතුළත් කරන්න",
    pleaseEnterLastName: "කරුණාකර ඔබේ වාසගම ඇතුළත් කරන්න",
    pleaseEnterBusinessName: "කරුණාකර ඔබේ ව්‍යාපාර නාමය ඇතුළත් කරන්න",
    firstName: "මුල් නම",
    lastName: "අවසන් නම",
    businessName: "ව්යාපාරික නාමය",
    previous: "කලින්",
    next: "ඊළඟ",
    pleaseSelectBusinessCategory: "කරුණාකර ඔබේ ව්‍යාපාර කාණ්ඩය තෝරන්න",
    pleaseEnterValidEmail: "වලංගු විද්‍යුත් තැපෑලක් ඇතුළත් කරන්න",
    pleaseEnterPostCode: "කරුණාකර තැපැල් කේතය ඇතුළත් කරන්න",
    postCode: "තැපැල් කේතය",
    phoneNumberInUse: "මෙම දුරකථන අංකය දැනටමත් භාවිතයේ ඇත!",
    emailInUse: "මෙම විද්‍යුත් තැපෑල දැනටමත් භාවිතයේ ඇත!",
    foodAndDrinks: "කෑම සහ බීම",
    salonAndBeauty: "රූපලාවණ්‍යාගාරය සහ රූපලාවණ්‍යය",
    fashionAndAccessories: "විලාසිතා සහ උපාංග",
    gymAndFitness: "ජිම් සහ යෝග්‍යතාවය",
    travelAndHotel: "සංචාරක හා හෝටල්",
    homeAndGifts: "නිවස සහ තෑගි",
    washingAndCleaning: "සේදීම සහ පිරිසිදු කිරීම",
    gadgetsAndElectronics: "උපකරණ සහ ඉලෙක්ට්‍රොනික උපකරණ",
    groceries: "සිල්ලර බඩු",
    others: "අන් අය",
    submit: "ඉදිරිපත් කරන්න",
    accountCreatedSuccessful: "ඔබේ ගිණුම සාර්ථකව සාදන ලදි.",
    pleaseEnterAddress: "කරුණාකර ඔබේ ලිපිනය ඇතුළත් කරන්න",
    addressLine1: "ලිපින පේළි 1",
    addressLine2: "2 වන ලිපිනය",
    choosePassword: "මුර පදය තෝරන්න",
    passwordMustBe6Characters: "මුරපදය අවම වශයෙන් අකුරු 6 ක් විය යුතුය.",
    howDidYouHearLoystar: "ලොයිස්ටාර් ගැන ඔබ දැනගත්තේ කෙසේද?",
    referralCode: "යොමු කේතය",
    byClickingTheButton: " පහත බොත්තම ක්ලික් කිරීමෙන් ඔබ එයට එකඟ වේ",
    termsAndSevice: "කොන්දේසි",
    wereCreatingAccount: "අපි ඔබේ ගිණුම සාදන්නෙමු",
    proceed: "ඉදිරියට යන්න",
    verificationCodeMustBe6: "සත්‍යාපන කේතය ඉලක්කම් 6 ක් විය යුතුය",
    pleaseEnter6DigitCode: "කරුණාකර අංක 6 කේතය ඇතුළත් කරන්න",
    enterVerificationCode: "සත්‍යාපන කේතය ඇතුළත් කරන්න",
    resendToken: "ටෝකනය නැවත යවන්න",
    verify: "තහවුරු කරන්න",
    transactions: "ගනුදෙනු",
    todaySales: "අද අලෙවිය",
    salesHistory: "විකුණුම් ඉතිහාසය",
    supplyHistory: "සැපයුම් ඉතිහාසය",
    new: "අලුත්",
    invoices: "ඉන්වොයිසි",
    disbursements: "බෙදාහැරීම්",
    offlineSales: "නොබැඳි අලෙවිය",
    products: "නිෂ්පාදන",
    customers: "පාරිභෝගිකයන්",
    multiLevelLoyalty: "බහු මට්ටමේ පක්ෂපාතිත්වය",
    loyaltyPrograms: "පක්ෂපාතී වැඩසටහන්",
    members: "සාමාජිකයින්",
    appStore: "යෙදුම් වෙළඳසැල",
    orderMenu: "ඇණවුම් මෙනුව",
    settings: "සැකසුම්",
    staffAndBranches: "කාර්ය මණ්ඩලය සහ ශාඛා",
    myAccount: "මගේ ගිණුම",
    switchToSellMode: "විකුණුම් ප්‍රකාරයට මාරු වන්න",
    signOut: "වරන්න",
    getFreeSubscription: "නොමිලේ දායක මුදලක් ලබා ගන්න",
    onlyNumbersAllowed: "අංක වලට පමණක් අවසර ඇත",
    yourAccountMustBe10Digits: "ඔබේ ගිණුම් අංකය ඉලක්කම් 10 ක අංකයක් විය යුතුය",
    yourBvnMustBe11: "ඔබේ බීවීඑන් අංකය 11 ඉලක්කම් විය යුතුයි",
    pleaseSelectBank: "කරුණාකර ඔබේ බැංකුව තෝරන්න",
    selectYourBank: "ඔබේ බැංකුව තෝරන්න",
    enterBankAccountNumber: "බැංකු ගිණුම් අංකය ඇතුළත් කරන්න",
    enterBvnNumber: "ඔබේ BVN ඇතුළු කරන්න",
    connectBankAccount: "බැංකු ගිණුම සම්බන්ධ කරන්න",
    passwordResetSuccessfulAndSignOut:
      "ඔබේ මුරපදය සාර්ථකව නැවත සකසා ඇත. නැවත පුරනය වීම සඳහා පහත ඇති ඉවත් වීමේ බොත්තම ක්ලික් කරන්න",
    enterCurrentPassword: "වත්මන් මුරපදය ඇතුළත් කරන්න",
    pleaseEnterCurrentPassword: "කරුණාකර වත්මන් මුරපදය ඇතුළත් කරන්න",
    phoneNumber: "දුරකතන අංකය",
    sex: "ලිංගික",
    dateOfBirth: "උපන්දිනය",
    state: "රජයේ",
    country: "රට",
    loyaltyId: "පක්ෂපාතී හැඳුනුම්පත",
    createdAt: "දී නිර්මාණය කරන ලදි",
    noOfTransactionsMade: "සිදු කළ ගනුදෙනු ගණන",
    yourDownloadWillStart: "ඔබේ බාගැනීම මොහොතකින් ආරම්භ වේ",
    exportCustomers: "අපනයන පාරිභෝගිකයින්",
    youhaveSuccessfullyToppedUp: "ඔබගේ කෙටි පණිවුඩ ඒකක ඔබ සාර්ථකව ඉහළට ගෙන ඇත.",
    youNowHave: "ඔබට දැන් තිබේ",
    smsUnits: "කෙටි පණිවුඩ ඒකක",
    enterNumberOfSmsUnits: "ඔබ මිලදී ගැනීමට අදහස් කරන SMS ඒකක ගණන ඇතුළත් කරන්න",
    pleaseEnterANumericValue: "කරුණාකර සංඛ්‍යාත්මක අගයක් ඇතුළු කරන්න",
    pay: "ගෙවන්න",
    accountEditedSuccessfully: "ඔබේ ගිණුම සාර්ථකව සංස්කරණය කරන ලදි.",
    youAeCurrentlyOn: "ඔබ දැනට ක්‍රියාත්මකයි",
    plan: "සැලැස්ම",
    userData: "පරිශීලක දත්ත",
    businessData: "ව්යාපාරික දත්ත",
    businessCategory: "ව්‍යාපාරික ද්‍රව්‍ය ගබඩාව",
    pleaseSelectCurrency: "කරුණාකර ඔබේ මුදල් තෝරන්න",
    selectYourCurrency: "ඔබේ මුදල් තෝරන්න",
    purchaseMoreSmsUnits:
      "පහත දැක්වෙන පෝරමය භාවිතා කර තවත් කෙටි පණිවුඩ ඒකක මිලදී ගන්න",
    youHave: "ඔයාට තියෙනවා",
    atLeast4SmsUnitsRrequired:
      "සත්‍යාපනය සඳහා අවම වශයෙන් කෙටි පණිවුඩ 4 ක් වත් අවශ්‍ය වේ, කරුණාකර ඉහළට යන්න!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'ඔබේ පාරිභෝගිකයා දැන ගන්න' (KYC) අවශ්‍යතාවයන්ට අනුකූල වීම සඳහා කරුණාකර ඔබේ බැංකු ගිණුම සත්‍යාපනය කරන්න. මෙය ඔබට යූඑස්එස්ඩී හරහා ගෙවීම් එකතු කිරීමට හෝ ක්‍ෂණිකව මාරු කිරීමට, ගනුදෙනුකරුවන්ගෙන් ඇණවුම් ලබා ගැනීමට සහ ගනුදෙනු පියවීමේ ක්‍රියාවලියට ඉඩ සලසයි. නයිජීරියානු වෙළෙන්දන්ට පමණක් අදාළ වේ. ආරම්භ කිරීමට කරුණාකර පහත බොත්තම ඔබන්න.",
    reConnectBankAccount: "බැංකු ගිණුම නැවත සම්බන්ධ කරන්න",
    accountName: "ගිණුමේ නම",
    accountNumber: "ගිණුම් අංකය",
    bankName: "බැංකුවේ නම",
    verified: "තහවුරු කර ඇත",
    accountDetails: "ගිණුම් විස්තර",
    kycBankAccount: "KYC",
    createTier: "ස්ථරයක් සාදන්න",
    fileUploadSuccessful: "ගොනුව සාර්ථකව උඩුගත කරන ලදි",
    fileUploadFailed: "ගොනු උඩුගත කිරීම අසාර්ථක විය",
    createLoyaltyProgram: "පක්ෂපාතී වැඩසටහනක් සාදන්න",
    createLoyalty: "පක්ෂපාතීත්වය ඇති කරන්න",
    name: "නම",
    loyaltyArtwork: "පක්ෂපාතී කලා කෘති",
    clickToUpload: "උඩුගත කිරීමට ක්ලික් කරන්න",
    amountToPointsRatio: "ලකුණු අනුපාතය අනුව",
    points: "ලකුණු",
    recommendedAmountToPointRatio:
      "නිර්දේශිත: ලක්ෂ්‍ය 1 සිට 1 දක්වා. එනම් සෑම නයිරා 1 කටම ඔබේ ගනුදෙනුකරුවන්ට ලකුණු 1 ක් ලැබේ",
    pleaseTypeIn: "කරුණාකර ටයිප් කරන්න",
    toDeleteLoyalty: "මෙම පක්ෂපාතිත්වය මකා දැමීමට",
    deleteLoyalty: "පක්ෂපාතීත්වය මකන්න",
    toConfirm: "තහවුරු කිරීමට",
    edit: "සංස්කරණය කරන්න",
    pointsAwardedSuccessfully: "ලකුණු ප්‍රදානය කරන ලදි.",
    enterPointValueToAward:
      "ඔබ පාරිභෝගිකයින්ට ප්‍රදානය කිරීමට කැමති පොයින්ට් අගය ඇතුළත් කරන්න",
    award: "සම්මානය",
    awardPointValuesToCustomer: "පාරිභෝගිකයාට ප්‍රසාද ලකුණු වටිනාකම",
    enrollMembersToLoyalty: "පක්ෂපාතීත්වයට සාමාජිකයින් ඇතුළත් කර ගන්න",
    awardPoints: "සම්මාන ලකුණු",
    enroll: "ලියාපදිංචි වන්න",
    home: "ගෙදර",
    loyalty: "පක්ෂපාතිත්වය",
    enrollCustomers: "පාරිභෝගිකයින් ලියාපදිංචි කරන්න",
    selected: "තෝරා ගන්නා ලදි",
    customer: "පාරිභෝගික",
    loyaltyActivationSuccessful: "පක්ෂපාතීත්වය සක්‍රිය කිරීම සාර්‍ථකයි.",
    loyaltyDeactivationSuccessful: "පක්ෂපාතීත්වය අක්‍රිය කිරීම සාර්‍ථකයි.",
    viewTier: "ස්ථරය බලන්න",
    deactivate: "ක්‍රියා විරහිත කරන්න",
    activate: "සක්‍රිය කරන්න",
    actions: "ක්රියාවන්",
    nameOfLoyalty: "පක්ෂපාතීත්වයේ නම",
    loyaltyStatus: "පක්ෂපාතී තත්ත්වය පක්ෂපාතී තත්ත්වය",
    numberOfTiers: "ස්ථර ගණන",
    createdOn: "මත සාදන ලදි",
    createATier: "ස්ථරයක් සාදන්න",
    stopCreatingTierConfirmation: "ඔබට ස්ථරයක් තැනීම නැවැත්වීමට අවශ්‍යද?",
    stopEditingTierConfirmation:
      "ඔබට මෙම ස්ථරය සංස්කරණය කිරීම නැවැත්වීමට අවශ්‍යද?",
    nameOfTier: "ස්ථරයේ නම",
    minimumSpendingTarget: "අවම වියදම් ඉලක්කය",
    maximumSpendingTarget: "උපරිම වියදම් කිරීමේ ඉලක්කය",
    minimumSpendingTargetRequired: "අවම වියදම් ඉලක්කය අවශ්‍යයි",
    maximumSpendingTargetRequired: "උපරිම වියදම් කිරීමේ ඉලක්කය අවශ්‍යයි",
    rewardSponsor: "ත්‍යාග අනුග්‍රාහකයා",
    pleaseChooseARewardSponsor: "කරුණාකර ත්‍යාග අනුග්‍රාහකයෙකු තෝරන්න",
    self: "ස්වයං",
    partner: "හවුල්කරු",
    rewardPartner: "ත්‍යාග සහකරු",
    pleaseSelectRewardPartner: "කරුණාකර ඔබේ ත්‍යාග සහකරු තෝරන්න",
    rewards: "ත්‍යාග",
    pleaseSelectAReward: "කරුණාකර ත්‍යාගයක් තෝරන්න",
    instructionsOnRedeemingReward:
      "පාරිභෝගිකයා ත්‍යාගය මුදවා ගත යුත්තේ කෙසේද යන්න පිළිබඳ උපදෙස්",
    pleaseFillInThisField: "කරුණාකර මෙම ක්ෂේත්රය පුරවන්න!",
    toDeleteThisTier: " මෙම ස්ථරය මකා දැමීමට",
    deleteTier: "ස්ථරය මකන්න",
    viewMembers: "සාමාජිකයින් බලන්න",
    membersEnrolled: "සාමාජිකයින් ලියාපදිංචි කර ඇත",
    instruction: "උපදෙස්",
    membersIn: "සාමාජිකයින් තුළ",
    availableTiersInLoyalty:
      "පක්ෂපාතීත්ව වැඩසටහන තුළ ඇති ස්ථර (ම) ලබා ගත හැකිය",
    tiers: "ස්ථර",
    totalTier: "මුළු ස්ථරය",
    availableLoyaltyProgramme: "ලබා ගත හැකි පක්ෂපාතී වැඩසටහන",
    totalLoyalties: "මුළු ලෝයල්ටි",
    memberDetails: "සාමාජික විස්තර",
    nameOfCustomer: "පාරිභෝගිකයාගේ නම",
    address: "ලිපිනය",
    allEnrolledMembers: "සියලුම ලියාපදිංචි සාමාජිකයින්",
    thisIsToWishYouHappyBirthday:
      "මෙය ඔබට ඉතා සුභ උපන්දිනයක් සහ සෞභාග්‍යමත් ජීවිතයක් ප්‍රාර්ථනා කිරීමෙනි. ලොයිස්ටර් ආයතනයට ඔබ සැමගේ ස්තූතිය. සුභ සැමරුම්!",
    inputAnOfferPlease: "කරුණාකර දීමනාවක් ඇතුළත් කරන්න",
    pleaseSelectTheInsertPoint:
      "කරුණාකර පණිවිඩයේ ඇතුළත් කිරීමේ ස්ථානය තෝරා නැවත ක්ලික් කරන්න",
    birthdayOfferAndMessage: "උපන් දින දීමනාව සහ පණිවිඩය",
    birthdayOffer: "උපන් දින දීමනාව",
    birthdayMessage: "උපන් දින පණිවිඩය",
    noOfferFound: "දීමනාවක් හමු නොවීය",
    settingABirthdayOffer:
      "උපන්දින දීමනාවක් සැකසීම තුළින් පාරිභෝගිකයින්ට තම උපන්දිනය වන විට කෙටි පණිවුඩ මඟින් මෙම දීමනාව ලබා ගත හැකිය",
    createOffer: "දීමනාව සාදන්න",
    whatIsTheOffer: "දීමනාව කුමක්ද?",
    editMessage: "පණිවිඩය සංස්කරණය කරන්න",
    insert: "ඇතුළු කරන්න",
    theNameOfCustomerInserted: "පාරිභෝගිකයාගේ නම මෙහි ඇතුළත් කෙරේ",
    theBirtdayOfferInserted: "උපන් දින දීමනාව මෙහි ඇතුළත් කෙරේ",
    youSuccessfullyAddedNewBranch: "ඔබ සාර්ථකව නව ශාඛාවක් එකතු කර ඇත!",
    addNewBranch: "නව ශාඛාව එකතු කරන්න",
    addBranch: "ශාඛාව එකතු කරන්න",
    additionalBranchWillIncur: "අතිරේක ශාඛාවක් ගෙවනු ඇත",
    perBranch: "ශාඛාවකට",
    ecommerceBranchCosts: "ඊ -වාණිජ්‍ය ශාඛා පිරිවැය",
    pleaseEnterBranchName: "කරුණාකර ශාඛාවේ නම ඇතුළත් කරන්න",
    pleaseEnterBranchAddress1: "කරුණාකර ශාඛාවේ ලිපිනය 1 ඇතුළත් කරන්න",
    enterBranchAddress1: "ශාඛාවේ ලිපිනය 1 ඇතුළත් කරන්න",
    enterBranchAddress2: "ශාඛාවේ ලිපිනය 1 ඇතුළත් කරන්න",
    pleaseEnterBranchAddress2: "කරුණාකර ශාඛාවේ ලිපිනය 2 ඇතුළත් කරන්න",
    enterBranchName: "ශාඛාවේ නම ඇතුළත් කරන්න",
    successfullyAddedStaff: "ඔබ සාර්ථකව නව කාර්ය මණ්ඩලයක් එකතු කර ඇත!",
    addNewStaff: "නව කාර්ය මණ්ඩලය එකතු කරන්න",
    addStaff: "කාර්ය මණ්ඩලය එකතු කරන්න",
    additionalStaffWillIncur: "අතිරේක කාර්ය මණ්ඩලයට සිදු වනු ඇත",
    perStaff: "කාර්ය මණ්ඩලයකට.",
    pleaseEnterStaffEmail: "කරුණාකර කාර්යමණ්ඩලයේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    pleaseEnterStaffUsername: "කරුණාකර කාර්ය මණ්ඩලයේ පරිශීලක නාමය ඇතුළත් කරන්න",
    pleaseEnterStaffPassword: "කරුණාකර කාර්ය මණ්ඩල මුරපදය ඇතුළත් කරන්න",
    pleaseSelectStaffRole: "කරුණාකර කාර්ය මණ්ඩලයේ කාර්යභාරය තෝරන්න",
    selectStaffRole: "කාර්ය මණ්ඩලයේ කාර්යභාරය තෝරන්න",
    enterStaffEmail: "කාර්යමණ්ඩලයේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    enterStaffUsername: "කාර්ය මණ්ඩලයේ පරිශීලක නාමය ඇතුළත් කරන්න",
    enterStaffPassword: "කාර්ය මණ්ඩල මුරපදය ඇතුළත් කරන්න",
    spacesNotAllowedInUsername: "පරිශීලක නාමයෙන් ඉඩ නොදේ",
    admin: "පරිපාලක",
    pleaseSelectBusinessToAttachStaff:
      "කාර්ය මණ්ඩලය අනුයුක්ත කිරීම සඳහා කරුණාකර ව්‍යාපාරය තෝරන්න",
    searchForBranchToAttachStaff:
      "කාර්ය මණ්ඩලය සම්බන්ධ කිරීම සඳහා ශාඛාව සොයන්න",
    username: "පරිශීලක නාමය",
    role: "කාර්යභාරය",
    areYouSureToDeleteThis: "ඔබට මෙය මැකීමට විශ්වාසද?",
    branches: "ශාඛා",
    upgradeYourPlan: "ඔබේ සැලැස්ම වැඩි දියුණු කරන්න.",
    add: "එකතු කරන්න",
    addNew: "අලුතින් එකතු කරන්න",
    customerWithEmailAlreadyExists:
      "විද්‍යුත් තැපෑල/දුරකථන අංකය සහිත පාරිභෝගිකයා දැනටමත් සිටී!",
    successfullyAddedNewCustomer: "ඔබ සාර්ථකව නව පාරිභෝගිකයෙකු එකතු කර ඇත!",
    addCustomer: "පාරිභෝගිකයා එකතු කරන්න",
    pleaseEnterCustomerFirstName: "කරුණාකර පාරිභෝගිකයාගේ පළමු නම ඇතුළත් කරන්න",
    pleaseEnterCustomerLastName: "කරුණාකර පාරිභෝගිකයාගේ අවසාන නම ඇතුළත් කරන්න",
    pleaseEnterCustomerPhoneNumber:
      "කරුණාකර පාරිභෝගිකයාගේ දුරකථන අංකය ඇතුළත් කරන්න",
    pleaseEnterCustomerEmail:
      "කරුණාකර පාරිභෝගිකයාගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    pleaseEnterCustomerAddressLine: "කරුණාකර පාරිභෝගිකයාගේ ලිපිනය ඇතුළත් කරන්න",
    pleaseEnterCustomerOtherAddress:
      "කරුණාකර පාරිභෝගිකයාගේ වෙනත් ලිපිනය ඇතුළු කරන්න",
    pleaseSelectCustomerGender: "කරුණාකර පාරිභෝගිකයාගේ ලිංගභේදය තෝරන්න",
    gender: "ස්ත්රී පුරුෂ භාවය",
    male: "පිරිමි",
    female: "ගැහැණු",
    bank: "බැංකුව",
    selectBank: "බැංකුව තෝරන්න",
    stateOrRegionOrProvince: "ප්රාන්ත/කලාපය/පළාත",
    customerNotes: "පාරිභෝගික සටහන්",
    sendSms: "කෙටි පණිවුඩ යවන්න",
    editCustomer: "පාරිභෝගිකයා සංස්කරණය කරන්න",
    redeemReward: "ත්‍යාගය මුදවා ගන්න",
    issueLoyaltyCard: "පක්ෂපාතී කාඩ්පත නිකුත් කරන්න",
    deleteCustomer: "පාරිභෝගිකයා මකන්න",
    youveSuccessfullyAssignedLoyaltyMembership:
      "ඔබ පක්ෂපාතී සාමාජික හැඳුනුම්පතක් සාර්ථකව පවරා ඇත",
    noMembershipIdAvailable:
      "සාමාජික හැඳුනුම්පත් නොමැත. කරුණාකර අප හා සම්බන්ධ වන්න hello@loystar.co",
    sendEmail: "විද්‍යුත් තැපෑල යවන්න",
    membershipPoints: "සාමාජිකත්ව ලකුණු",
    customerDetails: "පාරිභෝගික විස්තර",
    close: "වසන්න",
    loyaltyBalance: "පක්ෂපාතී ශේෂය",
    pointsBalance: "ලකුණු ශේෂය",
    starBalance: "තරු ශේෂය",
    requiredPoints: "අවශ්‍ය කරුණු",
    requiredStars: "අවශ්‍ය තරු",
    reddemCode: "කේතය මුදවා ගන්න",
    toDeleteThisCustomer: "මෙම පාරිභෝගිකයා මකා දැමීමට",
    customerHasBeenDeletedSuccessfully: "පාරිභෝගිකයා සාර්ථකව මකා දමා ඇත!",
    customerWithPhoneAlreadyExists: "දුරකථන අංකය ඇති පාරිභෝගිකයා දැනටමත් සිටී",
    customerWasSuccessfullyEdited: "පාරිභෝගිකයා සාර්ථකව සංස්කරණය කරන ලදි",
    anErrorOccured: "දෝෂයක් සිදු විය",
    phoneNumberIncludeCountry: "දුරකථන අංකය (රටේ කේතය ඇතුළත්)",
    yourFileQueuedForUpload:
      "උඩුගත කිරීම සඳහා ඔබේ ගොනුව පෝලිමේ තබා ඇත. තත්පර කිහිපයකට පසු කරුණාකර පිටුව නැවුම් කරන්න.",
    thereWasAnErrorPerformingOperation: "මෙහෙයුම සිදු කිරීමේදී දෝෂයක් ඇති විය!",
    pleaseSelectFile: "කරුණාකර ගොනුවක් තෝරන්න!",
    oopsOnlyCsvFilesAllowed:
      "අපොයි! CSV ගොනු වලට පමණක් අවසර ඇත. කරුණාකර .csv ගොනුවක් උඩුගත කරන්න.",
    fileShouldBeSmallerThan5Mb:
      "ගොනුව 5MB ට වඩා කුඩා විය යුතුය. ඔබට විශාල ගොනුවක් තිබේ නම් support@loystar.co වෙත විද්‍යුත් තැපෑලෙන් එවන්න. ඔබට ස්තුතියි",
    customerFirstNameIsRequired: "පේළිය මත පාරිභෝගිකයාගේ පළමු නම අවශ්‍යයි",
    customerPhoneNumberIsRequired: "පේළිය මත පාරිභෝගික දුරකථන අංකය අවශ්‍යයි",
    importCustomers: "ආනයන පාරිභෝගිකයින්",
    upload: "උඩුගත කරන්න",
    clickIconToDownloadCsv:
      "CSV ගොනු අච්චුව බාගැනීමට මෙම නිරූපකය ක්ලික් කරන්න.",
    getGoogleSheetVersion: "ගූගල් ෂීට් අනුවාදය ලබා ගන්න",
    clickOrDragFileToUpload:
      "උඩුගත කිරීමට මෙම ප්‍රදේශයට ගොනුව ක්ලික් කරන්න හෝ ඇදගෙන යන්න",
    missingOrInvalidColumnHeader:
      "නැතිවූ හෝ වලංගු නොවන තීරු ශීර්ෂකය. කරුණාකර සැකිලි ආකෘතිය අනුගමනය කරන්න. ඔබට ස්තුතියි.",
    youHaveImported: "ඔබ ආනයනය කර ඇත",
    youSuccessfullyRedeemedReward: "ඔබ ඔබේ ත්‍යාගය සාර්ථකව මුදාගෙන ඇත!",
    sixDigitCode: "ඉලක්කම් හයක කේතය",
    pleaseEnterCustomerRewardCode:
      "කරුණාකර පාරිභෝගිකයාගේ ත්‍යාග කේතය ඇතුළත් කරන්න",
    enterRewardCode: "ත්‍යාග කේතය ඇතුළත් කරන්න. (ත්‍යාග කේතය සිද්ධ සංවේදී)",
    selectLoyaltyProgram: "පක්ෂපාතී වැඩසටහන තෝරන්න",
    stamps: "මුද්දර",
    smsUnitsLow: "කෙටි පණිවිඩ ඒකක අඩුයි",
    pleaseTopUpSmsUnits: "කරුණාකර කෙටි පණිවුඩ ඒකක ඉහළට",
    smsSuccessfullySentForDelivery:
      "බෙදා හැරීම සඳහා කෙටි පණිවුඩයක් සාර්ථකව යවන ලදි!",
    sendSmsTo: "වෙත කෙටි පණිවිඩයක් යවන්න",
    allCustomers: "සියලුම පාරිභෝගිකයින්",
    unitsAvailable: "ලබා ගත හැකි ඒකක",
    pleaseTypeInTheMessage: "කරුණාකර පණිවිඩය ටයිප් කරන්න",
    message: "පණිවුඩය",
    charactersRemaining: "ඉතිරි චරිත",
    avoidUseOfSpecialCharacters:
      "කෙටි පණිවුඩ ඒකක සංරක්ෂණය කිරීම සඳහා විශේෂ චරිත සහ ඉමොජි භාවිතා කිරීමෙන් වලකින්න.",
    note: "සටහන",
    errorFetchingCustomersMultilevelDetail:
      "බහු මට්ටමේ විස්තර පාරිභෝගිකයින් ලබා ගැනීමේ දෝෂය",
    search: "සෙවීම",
    reset: "නැවත සකසන්න",
    importCustomer: "ආනයන පාරිභෝගිකයා",
    addNewCustomer: "නව පාරිභෝගිකයා එකතු කරන්න",
    sendSmsBroadcast: "කෙටි පණිවුඩ විකාශනය යවන්න",
    totalCustomers: "මුළු පාරිභෝගිකයින්",
    disbursementDetails: "බෙදා හැරීමේ විස්තර",
    paidBy: "විසින් ගෙවන ලදි",
    disbursed: "බෙදා හරින ලදි",
    bankAccountName: "බැංකු ගිණුමේ නම",
    bankAccountNumber: "බැංකු ගිණුම් අංකය",
    transferInitiated: "මාරු කිරීම ආරම්භ කරන ලදි",
    transferCompleted: "මාරු කිරීම අවසන්",
    pleaseEnterAValid: "වලංගු ඇතුළත් කරන්න",
    begin: "ආරම්භය",
    end: "අවසානය",
    date: "දිනය",
    paymentDate: "ගෙවීම් දිනය",
    selectDisbursementDuration: "බෙදා හැරීමේ කාලය තෝරන්න",
    totalSettled: "සමථයකට පත් කර ඇත",
    totalUnsettled: "සමස්ථ නොසන්සුන්",
    toDeleteThisSale: "මෙම විකිණීම මකා දැමීමට",
    draftSaleDeletedSuccessfully: "කෙටුම්පත් විකිණීම සාර්ථකව මකා දමා ඇත!",
    deleteSale: "විකිණීම මකන්න",
    pleaseTypeInYourTotal:
      "මකාදැමීම තහවුරු කිරීම සඳහා කරුණාකර ඔබේ මුළු ගණන ඇතුළත් කරන්න",
    billDetails: "බිල් විස්තර",
    printBill: "මුද්‍රණ පනත් කෙටුම්පත",
    servedBy: "විසින් සේවය කරන ලදි",
    total: "සමස්ත",
    createdDate: "සාදන ලද දිනය",
    createdTime: "සාදන ලද කාලය",
    status: "තත්ත්වය",
    loginSuccessful: "පිවිසීම සාර්‍ථකයි",
    pleaseWaitWhileWeConnectAccount:
      "අපි ඔබේ ගිණුම සම්බන්ධ කරන තුරු කරුණාකර රැඳී සිටින්න",
    connectionFailedTryAgain:
      "සම්බන්ධතාවය අසාර්ථක විය. කරුණාකර නැවත උත්සාහ කරන්න.",
    connectionSuccessful: "සම්බන්ධතාවය සාර්‍ථකයි",
    viewDetails: "තොරතුරු පෙන්වන්න",
    enable: "සක්‍රීය කරන්න",
    free: "නිදහස්",
    cost: "පිරිවැය",
    visitWebsite: "වෙබ් අඩවියට පිවිසෙන්න",
    pleaseUpgradeYourPlanToPro:
      "මෙම යෙදුම සක්‍රීය කිරීම සඳහා කරුණාකර ඔබේ සැලැස්ම ප්‍රෝ ප්ලස් වෙත උසස් කරන්න",
    connectYourBankAccount:
      "ගෙවීම් ලබා ගැනීම සඳහා ඔබේ බැංකු ගිණුම සම්බන්ධ කරන්න.",
    disable: "අබල කරන්න",
    enableApp: "යෙදුම සක්‍රීය කරන්න",
    addNewProductToInvoice: "ඉන්වොයිසියට නව නිෂ්පාදනයක් එක් කරන්න",
    toDeleteThisInvoice: "මෙම ඉන්වොයිසිය මකා දැමීමට",
    invoiceDeletedSuccessfully: "ඉන්වොයිසිය සාර්ථකව මකා දමා ඇත!",
    deleteInvoice: "ඉන්වොයිසිය මකන්න",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "ඔබ සාර්ථකව ඉන්වොයිස් ගෙවීමේ පණිවිඩයක් එක් කර ඇත!",
    pleaseEnterPaymentInstructions: "කරුණාකර ගෙවීම් උපදෙස් ඇතුළත් කරන්න",
    invoiceId: "ඉන්වොයිසි හැඳුනුම්පත",
    paidDate: "ගෙවූ දිනය",
    reference: "යොමුව",
    productAdded: "නිෂ්පාදන එකතු කරන ලදි",
    productOutOfStock: "නිෂ්පාදන තොගයෙන් තොරයි. කරුණාකර නැවත ගබඩා කරන්න.",
    totalInvoices: "මුළු ඉන්වොයිසි",
    totalPaidInvoices: "මුළු ගෙවූ ඉන්වොයිසි",
    totalUnpaidInvoices: "මුළු එජාප ඉන්වොයිසි",
    loyaltyProgramDeleted: "පක්ෂපාතී වැඩසටහන මකා දමන ලදි",
    thisLoyaltyProgramDeletedSuccessfully:
      "මෙම පක්ෂපාතී වැඩසටහන සාර්ථකව මකා දමා ඇත",
    thisLoyaltyProgramEditedSuccessfully:
      "මෙම පක්ෂපාතී වැඩසටහන සාර්ථකව සංස්කරණය කර ඇත",
    loyaltyProgramAddedSuccessfully: "පක්ෂපාතීත්ව වැඩසටහන සාර්ථකව එකතු කර ඇත",
    loyaltyProgramEdited: "පක්ෂපාතී වැඩසටහන සංස්කරණය කරන ලදි",
    loyaltyProgramAdded: "පක්ෂපාතී වැඩසටහන එකතු කරන ලදි",
    loyaltyDetails: "පක්ෂපාතීත්වයේ විස්තර",
    doYouWantToCloseDialog: "ඔබට මෙම සංවාදය වැසීමට අවශ්‍යද?",
    pleaseEnterLoyaltyName: "කරුණාකර ඔබේ පක්ෂපාතිත්වයේ නම ඇතුළත් කරන්න",
    programType: "වැඩසටහන් වර්ගය",
    pleaseSelectProgramType: "කරුණාකර වැඩසටහන් වර්ගයක් තෝරන්න",
    simplePoints: "සරල කරුණු",
    stampsProgram: "මුද්දර වැඩසටහන",
    threshold: "එළිපත්ත",
    pleaseEnterLoyaltyThreshold: "කරුණාකර පක්ෂපාතීත්වයේ සීමාව ඇතුළු කරන්න",
    reward: "ත්යාගය",
    pleaseEnterLoyaltyReward: "පක්ෂපාතීත්වයේ ත්‍යාගය කරුණාකර ඇතුළත් කරන්න",
    totalUserPoints: "මුළු පරිශීලක ලකුණු",
    totalUserStamps: "මුළු පරිශීලක මුද්දර",
    spendingTarget: "වියදම් කිරීමේ ඉලක්කය",
    spendingTargetHint1:
      "වියදම් කිරීමේ ඉලක්කය නම් ත්‍යාගය උපයා ගැනීම සඳහා පාරිභෝගිකයෙකු කොපමණ මුදලක් වැය කළ යුතුද යන්නයි. 1 මුදල් වටිනාකම = 1 ලක්ෂ්‍යය.",
    spendingTargetHint2:
      "මුද්දර ඉලක්කය වන්නේ ත්‍යාගය උපයා ගැනීම සඳහා පාරිභෝගිකයෙකු විසින් කොපමණ මුද්දර එකතු කළ යුතුද යන්නයි. උදා. 5",
    addNewLoyaltyProgram: "නව පක්ෂපාතී වැඩසටහන එකතු කරන්න",
    addLoyaltyProgram: "පක්ෂපාතී වැඩසටහන එකතු කරන්න",
    loyaltyProgramType: "පක්ෂපාතී වැඩසටහන් වර්ගය",
    pleaseSelectLoyaltyProgramType:
      "කරුණාකර පක්ෂපාතීත්වයේ වැඩසටහන් වර්ගය තෝරන්න",
    nameOfProgram: "වැඩසටහනේ නම",
    pleaseEnterProgramName: "කරුණාකර වැඩසටහනේ නම ඇතුළත් කරන්න",
    whatIsTheReward: "ත්‍යාගය කුමක්ද?",
    egNextPurchaseIsFree: "උදා. ඊළඟ මිලදී ගැනීම නොමිලේ",
    customerName: "පාරිභෝගිකයාගේ නම",
    guestCustomer: "ආගන්තුක පාරිභෝගිකයා",
    orderConfirmedSuccessfully: "ඇණවුම සාර්ථකව තහවුරු කරන ලදි",
    orderCancelledSuccessfully: "ඇණවුම සාර්ථකව අවලංගු කරන ලදි",
    confirmOrder: "ඇණවුම තහවුරු කරන්න",
    cancelOrder: "ඇණවුම අවලංගු කරන්න",
    allOrders: "සියලුම ඇණවුම්",
    totalOrders: "මුළු ඇණවුම්",
    doYouWantToAcceptOrder: "ඔබට මෙම නියෝගය පිළිගැනීමට අවශ්‍යද?",
    doYouWantToCancelOrder: "ඔබට මෙම ඇණවුම අවලංගු කිරීමට අවශ්‍යද?",
    orderDetails: "ඇණවුම් විස්තර",
    orderCreatedAt: "හිදී ඇණවුම සාදන ලදි",
    supplier: "සැපයුම්කරු",
    productName: "නිෂ්පාදන නාමය",
    quantity: "ප්රමාණය",
    unitPrice: "ඒකක මිල",
    receivedBy: "විසින් ලැබුණි",
    reportFrom: "වෙතින් වාර්තා කරන්න",
    totalSupplies: "මුළු සැපයුම්",
    allRightsReserved: "සියලු හිමිකම් ඇවිරිණි",
    toDeleteThisTransaction: "මෙම ගනුදෙනුව මකා දැමීමට",
    transactionDeletedSuccessfully:
      "ගනුදෙනුව සාර්ථකව මකා දමා ඇත. තොගය බඩු තොග වෙත ආපසු ලබා දී ඇත.",
    deleteTransaction: "ගනුදෙනුව මකන්න",
    transactionDetails: "ගනුදෙනු විස්තර",
    printReceipt: "මුද්‍රිත රිසිට් පත",
    channel: "නාලිකාව",
    discount: "වට්ටම්",
    profit: "ලාභයක්",
    discountedSales: "වට්ටම් සහිත විකුණුම්",
    errorFetchingRecord: "වාර්තාව ලබා ගැනීමේ දෝෂය",
    exportTransactions: "අපනයන ගනුදෙනු",
    errorFetchingSalesRecord:
      "අපනයනය සඳහා විකුණුම් වාර්තාවක් ලබා ගැනීමේ දෝෂයකි.",
    totalSellingPrice: "මුළු විකුණුම් මිල",
    totalCostPrice: "මුළු පිරිවැය මිල",
    appliedDiscount: "අදාළ වට්ටම්",
    noOfItems: "අයිතම ගණන",
    sales: "විකුණුම්",
    export: "අපනයන",
    totalProfit: "මුළු ලාභය",
    totalBalanceInPeriod: "කාල සීමාව තුළ මුළු ශේෂය",
    allTimeSales: "සියලුම කාල අලෙවියන්",
    records: "වාර්තා",
    todaysSales: "අද අලෙවිය",
    transaction: "ගනුදෙනුව",
    youHaveExceededTotalNumberOfProducts:
      "ඔබේ සැලැස්මේ අවසර දී ඇති මුළු නිෂ්පාදන ගණන ඔබ ඉක්මවා ඇත. ඉහළ සීමාවක් භුක්ති විඳීමට ඔබේ සැලැස්ම වැඩි දියුණු කරන්න.",
    youNeedToHaveLoyaltyProgram:
      "මෙම විශේෂාංගය භාවිතා කිරීම සඳහා ඔබට පක්ෂපාතී වැඩසටහනක් තිබිය යුතුය!",
    price: "මිල",
    category: "වර්ගය",
    stockTracking: "කොටස් ලුහුබැඳීම",
    stockCount: "කොටස් ගණන්",
    taxed: "බදු අය කෙරේ",
    originalPrice: "මුල් මිල",
    costPrice: "පිරිවැය මිල",
    unit: "ඒකකය",
    productImage: "නිෂ්පාදන රූපය",
    taxRate: "බදු අනුපාතය",
    taxType: "බදු වර්ගය",
    trackInventory: "ඉන්වෙන්ටරි හඹා යන්න",
    variants: "ප්‍රභේද",
    hasVariants: "ප්‍රභේද ඇත",
    importProduct: "ආනයනික නිෂ්පාදන",
    exportProducts: "අපනයන නිෂ්පාදන",
    addNewProduct: "නව නිෂ්පාදනයක් එකතු කරන්න",
    viewCategory: "ප්රවර්ගය බලන්න",
    viewSuppliers: "සැපයුම්කරුවන් බලන්න",
    receiveInventory: "ඉන්වෙන්ටරි ලබා ගන්න",
    printAllProductsTag: "සියලුම නිෂ්පාදන ටැගය මුද්‍රණය කරන්න",
    deleteAll: "සියල්ල මකන්න",
    totalProducts: "මුළු නිෂ්පාදන",
    youveSuccessfullyAddedANewCategory: "ඔබ සාර්ථකව නව ප්‍රවර්ගයක් එකතු කර ඇත",
    addNewCategory: "නව ප්‍රවර්ගය එක් කරන්න",
    addCategory: "ප්රවර්ගය එකතු කරන්න",
    categoryName: "ප්රවර්ගය නම",
    pleaseEnterCategoryName: "කරුණාකර කාණ්ඩයේ නම ඇතුළත් කරන්න",
    stampsTarget: "ඉලක්ක කරගත් මුද්දර",
    sendInventory: "ඉන්වෙන්ටරි යවන්න",
    productDetails: "නිෂ්පාදන විස්තර",
    youveSuccessfullyEditedThisCategory: "ඔබ මෙම කාණ්ඩය සාර්ථකව සංස්කරණය කර ඇත",
    update: "යාවත්කාලීන කරන්න",
    categoryList: "ප්රවර්ග ලැයිස්තුව",
    categories: "ප්රවර්ග",
    enterQuantityToUpdate: "යාවත්කාලීන කිරීම සඳහා ප්‍රමාණයක් ඇතුළත් කරන්න",
    inventorySentSuccessfully: "ඉන්වෙන්ටරි සාර්ථකව යවන ලදි!",
    updateInventory: "ඉන්වෙන්ටරි යාවත්කාලීන කරන්න",
    currentQuantity: "වත්මන් ප්රමාණය",
    pleaseEnterQuantityToAdd:
      "කරුණාකර ඔබට එකතු කිරීමට අවශ්‍ය ප්‍රමාණය ඇතුළත් කරන්න",
    pleaseSelectABranch: "කරුණාකර ශාඛාවක් තෝරන්න",
    searchForBranch: "ශාඛාව සඳහා සොයන්න",
    youCantSendMoreThanStock: "තොගයේ ඇති ප්‍රමාණයට වඩා ඔබට යැවිය නොහැක",
    send: "යවන්න",
    pleaseEnterQuantityToSend:
      "කරුණාකර ඔබට යැවීමට අවශ්‍ය ප්‍රමාණය ඇතුළත් කරන්න",
    productNameIsRequiredOnRow: "පේළිය මත නිෂ්පාදන නාමය අවශ්‍ය වේ",
    productCategoryIsRequiredOnRow: "පේළිය මත නිෂ්පාදන කාණ්ඩය අවශ්‍යයි",
    productPriceIsRequiredOnRow: "නිෂ්පාදන මිල පේලියට අවශ්‍යයි",
    productUnitIsRequiredOnRow: "පේළිය මත නිෂ්පාදන ඒකකය අවශ්‍යයි",
    productQuantityIsRequiredOnRow: "පේළිය මත නිෂ්පාදන ප්‍රමාණය අවශ්‍යයි",
    productVariantsRequireTracking: "නිෂ්පාදන ප්‍රභේද සඳහා ලුහුබැඳීම අවශ්‍යයි!",
    pleaseAddVariantClickButton:
      "එකතු කිරීමේ බොත්තම ක්ලික් කිරීමෙන් කරුණාකර ප්‍රභේදයක් එක් කරන්න!",
    totalVariantsMoreThanSelectedQuantity:
      "මුළු ප්‍රභේදයන් තෝරාගත් නිෂ්පාදන ප්‍රමාණයට වඩා වැඩි ය, කරුණාකර ප්‍රභේද ප්‍රමාණය අඩු කරයි",
    productEditedSuccessfully: "නිෂ්පාදනය සාර්ථකව සංස්කරණය කර ඇත!",
    fileTooLargeLessThan4Mb:
      "ගොනුවේ ප්‍රමාණය ඉතා විශාලයි! ගොනු විශාලත්වය 4MB ට වඩා අඩු විය යුතුය.",
    suchVariantAlreadyExist: "එවැනි ප්රභේදයක් දැනටමත් පවතී",
    addVariants: "ප්‍රභේද එකතු කරන්න",
    editProduct: "නිෂ්පාදනය සංස්කරණය කරන්න",
    pleaseEnterProductName: "කරුණාකර නිෂ්පාදන නාමය ඇතුළත් කරන්න",
    pleaseEnterProductPrice: "කරුණාකර නිෂ්පාදන මිල ඇතුළත් කරන්න",
    pleaseEnterProductOriginalPrice: "කරුණාකර නිෂ්පාදන මුල් මිල ඇතුළත් කරන්න",
    productDescription: "නිෂ්පාදනය විස්තරය",
    selectProductCategory: "නිෂ්පාදන කාණ්ඩය තෝරන්න",
    pleaseSelectProductCategory: "කරුණාකර නිෂ්පාදන කාණ්ඩය තෝරන්න",
    productCostPrice: "නිෂ්පාදන පිරිවැය මිල",
    productSellingPrice: "නිෂ්පාදන විකිණීමේ මිල",
    productOriginalPrice: "නිෂ්පාදන මුල් මිල",
    maxFileSizeAllowedIs4Mb: "උපරිම අවසර දී ඇති ගොනු ප්‍රමාණය 4mb වේ",
    productsWithPicturesArePublished:
      "ඇණවුම් ලබා ගැනීම සඳහා පින්තූර සහිත නිෂ්පාදන ඩිස්කවර් හි ප්‍රකාශයට පත් කෙරේ",
    shouldThisProductBeTracked: "මෙම නිෂ්පාදනය නිරීක්ෂණය කළ යුතුද?",
    pleaseSelectStockUnit: "කරුණාකර කොටස් ඒකකය තෝරන්න",
    stockUnit: "කොටස් ඒකකය",
    bag: "බෑග්",
    bottle: "බෝතලය",
    bunch: "බන්ච්",
    can: "පුළුවන්",
    carton: "කාටන්",
    crate: "CATE",
    cup: "කුසලාන",
    dozen: "දුසිම්",
    gigabytes: "ගිගාබයිට්",
    gram: "ග්‍රෑම්",
    kilogram: "කිලෝග්‍රෑම්",
    litre: "අකුරු",
    pack: "ඇසුරුම් කරන්න",
    pair: "PAIR",
    pieces: "පීස්",
    plate: "තහඩුව",
    tonne: "ටොන් (එම්ටී)",
    uNIT: "ඒකකය",
    yard: "අංගනය",
    pleaseEnterProductQuantity: "කරුණාකර නිෂ්පාදන ප්‍රමාණය ඇතුළත් කරන්න",
    productQuantity: "නිෂ්පාදන ප්‍රමාණය",
    isThisProductTaxed: "මෙම නිෂ්පාදනය සඳහා බදු අය කෙරේ ද?",
    selectTaxType: "බදු වර්ගය තෝරන්න",
    pleaseSelectTaxType: "කරුණාකර බදු වර්ගය තෝරන්න",
    progressive: "ප්රගතිශීලී",
    proportional: "සමානුපාතික",
    pleaseEnterProductTaxRate: "කරුණාකර නිෂ්පාදන බදු අනුපාතය ඇතුළත් කරන්න",
    doesProductHaveVariants: "මෙම නිෂ්පාදනයට ප්‍රභේද තිබේද?",
    type: "ටයිප් කරන්න",
    value: "වටිනාකම",
    pleaseEnterVariantType: "කරුණාකර ප්‍රභේද වර්ගය ඇතුළත් කරන්න",
    pleaseEnterVariantValue: "කරුණාකර ප්‍රභේදයේ අගය ඇතුළත් කරන්න",
    pleaseEnterVariantPrice: "කරුණාකර ප්‍රභේදයේ මිල ඇතුළත් කරන්න",
    pleaseEnterVariantQuantity: "කරුණාකර ප්‍රභේද ප්‍රමාණය ඇතුළත් කරන්න",
    productDeletedSuccessfully: "නිෂ්පාදනය සාර්ථකව මකා දමා ඇත!",
    categoryDeletedSuccessfully: "ප්‍රවර්ගය සාර්ථකව මකා දමා ඇත!",
    toDeleteThisProduct: "මෙම නිෂ්පාදනය මකා දැමීමට",
    invalidProductQuantity: "වලංගු නොවන නිෂ්පාදන ප්‍රමාණය",
    quantityAddedIsOverStock:
      "ඔබ එකතු කරන ප්‍රමාණය ඔබ සතුව ඇති ප්‍රමාණයට වඩා වැඩිය.",
    itemInventoryNotTracked: "අයිතම ඉන්වෙන්ටරි නිරීක්ෂණය නොකෙරේ",
    addBulkQuantity: "තොග ප්‍රමාණය එකතු කරන්න",
    enterBulkQuantity: "තොග ප්‍රමාණය ඇතුළත් කරන්න",
    pleaseEnterBulkQuantity: "කරුණාකර තොග ප්‍රමාණය ඇතුළත් කරන්න",
    youveSuccessfullyAddedANewProduct: "ඔබ සාර්ථකව නව නිෂ්පාදනයක් එකතු කර ඇත!",
    pleaseEnterProductSellingPrice:
      "කරුණාකර නිෂ්පාදන විකුණුම් මිල ඇතුළත් කරන්න",
    doYouWantToTrackProductStock: "ඔබට නිෂ්පාදන තොගය නිරීක්ෂණය කිරීමට අවශ්‍යද?",
    sellingPrice: "විකුණුම් මිල",
    setProductExpiryReminder: "නිෂ්පාදන කල් ඉකුත් වීමේ මතක් කිරීම සකසන්න",
    productExpiryDate: "නිෂ්පාදන කල් ඉකුත් වීමේ දිනය",
    startRemindingFrom: "වෙතින් මතක් කිරීම ආරම්භ කරන්න",
    productSuppliesAddedSuccessfully: "ඔබ සාර්ථකව නිෂ්පාදන සැපයුම් එකතු කර ඇත.",
    addProductSupplies: "නිෂ්පාදන සැපයුම් එකතු කරන්න",
    pleaseSelectSupplier: "කරුණාකර සැපයුම්කරු තෝරන්න",
    nameOfProduct: "නිෂ්පාදනයේ නම",
    pleaseSelectProduct: "කරුණාකර නිෂ්පාදන තෝරන්න",
    productVariant: "නිෂ්පාදන ප්‍රභේදය",
    pleaseSelectAVariant: "කරුණාකර ප්‍රභේදයක් තෝරන්න",
    pleaseEnterUnitPrice: "කරුණාකර ඒකක මිල ඇතුළත් කරන්න",
    businessBranch: "ව්යාපාර ශාඛාව",
    pleaseSelectBranch: "කරුණාකර ශාඛාව තෝරන්න",
    youveSuccessfullyAddedANewSupplier:
      "ඔබ සාර්ථකව නව සැපයුම්කරුවෙකු එකතු කර ඇත",
    addSupplier: "සැපයුම්කරු එකතු කරන්න",
    pleaseEnterSupplierEmail:
      "කරුණාකර සැපයුම්කරුගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    pleaseAddADescription: "කරුණාකර විස්තරයක් එකතු කරන්න",
    anErrorOccuredProductsDeleted:
      "මෙහෙයුම සිදු කිරීමේදී දෝෂයක් සිදු විය. කරුණාකර සටහන් කර ගන්න, ක්‍රියාවලියේදී නිෂ්පාදන කිහිපයක් මකා දමා තිබිය හැකිය",
    bulkDelete: "තොග වශයෙන් මකන්න",
    youAreAboutToDelete: "ඔබ මකා දැමීමට ආසන්නයි",
    product: "නිෂ්පාදන",
    isDueToRedeem: "මුදවා ගැනීම නිසා ය",
    aReward: "ත්යාගයක්",
    pleaseSelectCustomerToReeemReward:
      "ත්‍යාගය මුදවා ගැනීමට කරුණාකර පාරිභෝගිකයෙකු තෝරන්න.",
    youHaveNoLoyaltyProgramRunning:
      "ඔබට සක්‍රීය පක්ෂපාතීත්ව වැඩසටහනක් ක්‍රියාත්මක නොවේ",
    customerHhasNoPointsInLoyaltyProgram:
      "මෙම පක්ෂපාතී වැඩසටහනේදී පාරිභෝගිකයාට කිසිදු කරුණක් නොමැත",
    proceedWithPayment: "ගෙවීම් සමඟ ඉදිරියට යන්නද?",
    youAreAboutToPayForTransactionUsingPoints:
      "ඔබ ලකුණු භාවිතා කරමින් ගනුදෙනුව සඳහා ගෙවීමට සූදානම් වේ.",
    customerHas: "පාරිභෝගිකයා සතුව ඇත",
    worth: "වටිනා",
    andIsNotSufficientToPayForTransaction:
      "සහ මෙම ගනුදෙනුව සඳහා ගෙවීමට ප්‍රමාණවත් නොවේ. වෙනත් ගෙවීමේ ක්‍රමයක් භාවිතයෙන් ශේෂය එකතු කිරීමට ඔවුන්ට අවශ්‍යද?",
    addCustomerLoyalty: "පාරිභෝගික පක්ෂපාතිත්වය එක් කරන්න",
    pleaseAddCustomerFirst: "කරුණාකර පළමුව පාරිභෝගිකයෙකු එක් කරන්න හෝ තෝරන්න.",
    pleaseWaitWhileWeGetReady: "කරුණාකර ඉන්න, අපි සූදානම් වන තුරු",
    lowStock: "අඩු තොග",
    pleaseEnterAmountTendered: "කරුණාකර ටෙන්ඩර් කළ මුදල ඇතුළත් කරන්න",
    areYouSureToBookSaleOffline:
      "ඔබට මෙම විකිණීම නොබැඳිව වෙන් කරවා ගැනීමට අවශ්‍ය බව ඔබට නිසැකයි ද?",
    saleWouldBeBookedAutomatically:
      "ඔබ අන්තර්ජාලය ක්‍රියාත්මක කරන විට ස්වයංක්‍රීයව විකිණීම වෙන් කරවා ගත හැකිය",
    offlineSalesBookingCancelled: "නොබැඳි විකුණුම් වෙන් කිරීම අවලංගු කරන ලදි",
    covid19Message:
      "COVID19: පැතිරීම නැවැත්වීම සඳහා සබන් යොදා සබන් යොදා ඔබේ අත් සෝදන්න. සැමවිටම ආරක්ෂිතව සිටින්න",
    saleSuccessfullyRecorded: "විකිණීම සාර්ථකව වාර්තා කර ඇත!",
    sendReceiptToEmail: "විද්‍යුත් තැපෑලට රිසිට්පත යවන්න",
    sendThankYouSms: "ස්තුති කෙටි පණිවිඩයක් යවන්න",
    sendShippingDetails: "නැව්ගත කිරීමේ විස්තර යවන්න",
    addLoyalty: "පක්ෂපාතීත්වය එකතු කරන්න",
    searchCustomerNameOrNumber: "පාරිභෝගිකයාගේ නම හෝ අංකය සොයන්න",
    clickTheSearchCustomerBox:
      "සෙවුම් පාරිභෝගික පෙට්ටිය ක්ලික් කර කාඩ්පත ස්කෑන් කරන්න",
    enterProductPrice: "නිෂ්පාදන මිල ඇතුළත් කරන්න",
    enterPriceFor: "සඳහා මිල ඇතුළත් කරන්න",
    searchForProduct: "නිෂ්පාදනය සඳහා සොයන්න",
    all: "සියළුම",
    backToDashboard: "උපකරණ පුවරුව වෙත ආපසු",
    viewDraftSales: "කෙටුම්පත් අලෙවිය බලන්න",
    variantSelected: "තෝරාගත් ප්රභේදය",
    variant: "ප්රභේදය",
    thePreviousVariantSelectionNotAvailable:
      "මිල මත පදනම්ව තෝරාගත් නව ප්‍රභේදය සඳහා පෙර පැවති ප්‍රභේද තේරීම නොමැත, කරුණාකර ඔබේ තේරීම වෙනස් කරන්න.",
    pleaseSupplyPositiveQuantityNumber: "කරුණාකර සාධනීය ප්‍රමාණ අංකයක් සපයන්න",
    lowStockFor: "සඳහා අඩු තොග",
    clearVariants: "පැහැදිලි ප්රභේද",
    pleaseEnterQuantity: "කරුණාකර ප්‍රමාණය ඇතුළත් කරන්න",
    picture: "පින්තූරය",
    redemptionToken: "මුදවා ගැනීමේ සංකේතය",
    token: "ටෝකනය",
    totalSpent: "මුළු වියදම්",
    confirmPayment: "ගෙවීම තහවුරු කරන්න",
    hasPaymentBeenMade: "ගෙවීම් සාර්ථකව ක්‍රියාවට නංවා තිබේද?",
    enterTransactionReference: "ඔබ ගෙවූ ගනුදෙනු සඳහන ඇතුළත් කරන්න",
    pleaseSelectACustomer: "කරුණාකර ගනුදෙනුකරුවෙකු තෝරන්න!",
    areYouSureToApplyDiscount: "ඔබට වට්ටම් අයදුම් කිරීමට අවශ්‍ය බව නිසැකයි ද?",
    addYourBankAccountToEnableUssd:
      "UPay මඟින් USSD ක්‍ෂණිකව මාරු කිරීම සක්‍රීය කිරීම සඳහා ඔබේ බැංකු ගිණුම එකතු කරන්න",
    totalAmountToPay: "ගෙවිය යුතු මුළු මුදල",
    doYouWantToCancelTransaction: "ඔබට මෙම ගනුදෙනුව අවලංගු කිරීමට අවශ්‍යද?",
    savePrintBill: "බිල්පත සුරකින්න/මුද්‍රණය කරන්න",
    enterAmountCollectedForCustomer:
      "පාරිභෝගිකයා සඳහා එකතු කළ මුදල ඇතුළත් කරන්න",
    recordSale: "වාර්තාගත විකිණීම",
    checkOutWith: "සමඟ පරීක්‍ෂා කරන්න",
    instantTransfer: "ක්ෂණික මාරු කිරීම",
    dialTheUSSDCode: "යූඑස්එස්ඩී කේතය අමතන්න",
    pleaseSelectABank: "කරුණාකර බැංකුවක් තෝරන්න",
    payWithUSSD: "යූඑස්එස්ඩී සමඟ ගෙවන්න",
    sendBillTo: " - වෙත පනත් කෙටුම්පත යවන්න",
    waitingForUSSDTransfer: "යූඑස්එස්ඩී හුවමාරුව සඳහා රැඳී සිටිමින්",
    percent: "සියයට",
    applyDiscount: "වට්ටම් අයදුම් කරන්න",
    thisBillHasBeenSaved: "මෙම පනත සුරැකී ඇත",
    saveDraft: "කෙටුම්පත සුරකින්න",
    pleaseTypeANameToIdentifyCustomer:
      "පාරිභෝගිකයා හඳුනා ගැනීමට කරුණාකර නමක් ඇතුළත් කරන්න",
    paymentDetails: "ගෙවීම් තොරතුරු",
    basePrice: "මූලික මිල",
    staff: "කාර්ය මණ්ඩලය",
    subTotal: "උප එකතුව",
    durationMonths: "කාල සීමාව මාස)",
    selectADuration: "කාල සීමාවක් තෝරන්න",
    oneMonth: "මාස 1 යි",
    twoMonths: "මාස 2 ක්",
    threeMonths: "මාස 3 යි",
    sixMonths: "මාස 6 යි",
    twelveMonths: "මාස 12 යි",
    eighteenMonths: "මාස 18 යි",
    twentyFourMonths: "මාස 24 යි",
    twoMonthsFree: "(මාස 2 ක් නොමිලේ)",
    threeMonthsFree: "(මාස 3 ක් නොමිලේ)",
    fiveMonthsFree: "(මාස 5 ක් නොමිලේ)",
    yourAccountHasBeen: "ඔබේ ගිණුම වී ඇත",
    renewed: "අලුත් කළා",
    upgraded: "වැඩි දියුණු කරන ලදි",
    successfully: "සාර්ථකව",
    yourSubscription: "ඔබේ දායකත්වය",
    youAreCurrentlyEnrolledOnA: "ඔබ දැනට ලියාපදිංචි වී සිටින්නේ අ",
    pleaseChooseAPaymentOption: "කරුණාකර ගෙවීමේ විකල්පයක් තෝරන්න",
    planRenewal: "අළුත් කිරීම සැලසුම් කරන්න",
    planUpgrade: "සැලැස්ම වැඩි දියුණු කිරීම",
    pleaseSelectDurationToPayFor: "කරුණාකර ඔබ ගෙවීමට අදහස් කරන කාලය තෝරන්න",
    staffAccounts: "කාර්ය මණ්ඩල ගිණුම්",
    ecommerce: "ඊ -වාණිජ්‍යය",
    pleaseSelectAPlan: "කරුණාකර සැලැස්මක් තෝරන්න",
    includeAddons: "-ඳුම් ඇතුළත් කරන්න",
    viewTransactions: "ගනුදෙනු බලන්න",
    customerHasNoCompletedTansactions:
      "පාරිභෝගිකයාට තවමත් සම්පූර්ණ කරන ලද සම්බන්ධතා නොමැත",
    branch: "ශාඛාව",
    enterNumberOfEcommerceBranches: "ඊ -වාණිජ්‍ය ශාඛා ගණන ඇතුළත් කරන්න",
    enterNumberOfEcommerceBranchesToPay:
      "ඔබ ගෙවීමට අදහස් කරන ඊ -වාණිජ්‍ය ශාඛා ගණන ඇතුළත් කරන්න",
    ecommerceIntegration: "ඊ -වාණිජ්‍යය ඒකාබද්ධ කිරීම",
    enterNumberOfBranches: "ශාඛා ගණන ඇතුළත් කරන්න",
    enterNumberOfAdditionalBranchesToPay:
      "ඔබ ගෙවීමට අදහස් කරන අතිරේක ශාඛා ගණන ඇතුළත් කරන්න",
    enterNumberOfStaffs: "කාර්ය මණ්ඩල සංඛ්යාව ඇතුළත් කරන්න",
    enterNumberOfStaffsToPayFor:
      "ඔබ ගෙවීමට අදහස් කරන සේවක සංඛ්‍යාව ඇතුළත් කරන්න",
    discountApplies: "වට්ටම් අදාළ වේ",
    starsOnThe: "මත තරු",
    offer: "පිරිනැමීම",
    get: "ලබා ගන්න",
    moreStarsToRedeem: "මුදවා ගැනීමට තවත් තරු",
    taxVat: "බදු (වැට්)",
    callCashierToCompletePayment:
      "ගෙවීම සම්පූර්ණ කිරීම සඳහා මුදල් අයකැමියා අමතන්න",
    receipt: "රිසිට්පත",
    dear: "හිතවත්",
    thankYouForYourOrderFindGoods:
      "ඔබගේ ඇණවුමට ස්තුතියි. එකඟ වූ පරිදි කරුණාකර සැපයූ පහත සඳහන් භාණ්ඩ සොයා ගන්න.",
    shippingNote: "නැව්ගත කිරීමේ සටහන",
    enterShippingNote: "නැව්ගත කිරීමේ සටහන ඇතුළත් කරන්න",
    shippingAddress: "බෙදාහැරීමේ ලිපිනය",
    enterShippingAddress: "නැව්ගත කිරීමේ ලිපිනය ඇතුළත් කරන්න",
    wellDoneYouAreDueToRedeem: "හොඳින් කළා! ඔබ මුදවා ගැනීම නිසා ය",
    toGetYourRewardNextVisit:
      "ඔබේ ඊළඟ සංචාරයේදී ඔබේ ත්‍යාගය ලබා ගැනීමට. ඔබට ස්තුතියි",
    pointsOnThe: "මත ලකුණු",
    morePointsToRedeem: "මුදවා ගැනීමට තවත් ලකුණු",
    showCode: "කේතය පෙන්වන්න",
    toGetYourRewardOnNextVisit:
      "ඔබේ ඊළඟ සංචාරයේදී ඔබේ ත්‍යාගය ලබා ගැනීමට. ඔබට ස්තුතියි",
    earn: "උපයන්න",
    delivaryNote: "බෙදා හැරීමේ සටහන",
    draftSales: "කෙටුම්පත් විකුණුම්",
    startDate: "ආරම්භක දිනය",
    endDate: "අවසාන දිනය",
    orders: "නියෝග",
    checkout: "පරීක්ෂාකාරී වන්න",
    noProductItem: "නිෂ්පාදන අයිතමයක් නොමැත",
    printProductTag: "නිෂ්පාදන ටැගය මුද්‍රණය කරන්න",
    transactionReference: "ගනුදෙනු යොමු කිරීම",
    Cashier: "මුදල් අයකැමියා",
    Manager: "කළමනාකරු",
    Owner: "හිමිකරු",
    Admin: "පරිපාලක",
    addPartners: "හවුල්කරුවන් එකතු කරන්න",
    addNewLoyaltyPartner: "නව ලෝයල්ටි සහකරු එක් කරන්න",
    pleaseEnterCompanyName: "කරුණාකර සමාගමේ නම ඇතුලත් කරන්න",
    companyName: "සමාගම් නාමය",
    pleaseEnterCompanyRepName: "කරුණාකර සමාගමේ නියෝජිත නම ඇතුළත් කරන්න",
    companyRepName: "සමාගම් නියෝජිතයාගේ නම",
    pleaseEnterCompanyRepEmail:
      "කරුණාකර සමාගම් නියෝජිතයාගේ විද්‍යුත් තැපෑල ඇතුළත් කරන්න",
    companyRepEmail: "සමාගමේ නියෝජිතයාගේ විද්‍යුත් තැපෑල",
    pleaseEnterCompanyRepPhone:
      "කරුණාකර සමාගම් නියෝජිතයාගේ දුරකථන අංකය ඇතුළත් කරන්න",
    companyRepPhone: "සමාගම් නියෝජිතයාගේ දුරකථන අංකය",
    addReward: "ත්‍යාගය එක් කරන්න",
    pleaseEnterRewardName: "කරුණාකර ත්‍යාග නාමය ඇතුළත් කරන්න",
    rewardName: "ත්යාග නාමය",
    rewardQuantity: "විපාක ප්රමාණය",
    rewardDescription: "විපාක විස්තරය",
    rewardType: "විපාක වර්ගය",
    pleaseEnterRewardType: "කරුණාකර ත්‍යාග වර්ගය ඇතුළු කරන්න",
    pleaseEnterRewardQuantity: "කරුණාකර ත්‍යාග ප්‍රමාණය ඇතුළත් කරන්න",
    pleaseEnterRewardDescription: "කරුණාකර ත්‍යාග විස්තරය ඇතුළු කරන්න",
    fineDining: "ෆයින් ඩිනින්",
    luxuryFashion: "සුඛෝපභෝගී විලාසිතා",
    hotels: "හෝටල්",
    travel: "ගමන්",
    foodAndBeverage: "කෑම බීම",
    fashion: "විලාසිතා",
    health: "සෞඛ්යය",
    furniture: "ගෘහභාණ්ඞ",
    entertainment: "විනෝදාස්වාදය",
    automobile: "මෝටර් රථය",
    education: "අධ්යාපන",
    beautyAndSpa: "රූපලාවන්‍ය සහ ස්පා",
    staycation: "රැඳී සිටීම",
    events: "සිද්ධීන්",
    trips: "චාරිකා",
    oilAndGas: "තෙල් සහ ගෑස්",
    laundry: "රෙදි සෝදන",
    partnerCategory: "හවුල්කාර කාණ්ඩය",
    freeItem: "නොමිලේ අයිතමය",
  },
  Sindhi: {
    cashier: "ڪيشير",
    manager: "مئنيجر",
    owner: "مالڪ",
    online: "آن لائين",
    offline: "آف لائن",
    changePassword: "پاسورڊ تبديل ڪريو",
    currentPasswordMessage: "مھرباني ڪري پنھنجو موجوده پاسورڊ داخل ڪريو",
    passwordMessage: "مھرباني ڪري پنھنجو پاسورڊ داخل ڪريو",
    currentPassword: "موجوده پاس ورڊ",
    password: "پاسورڊ",
    confirmPasswordMessage: "مھرباني ڪري پنھنجي پاسورڊ جي تصديق ڪريو!",
    confirmPassword: "پاس ورڊ جي تصديق ڪريو",
    sendViaEmail: "اي ميل ذريعي موڪليو",
    sendViaWhatsapp: "WhatsApp ذريعي موڪليو",
    downloadPdf: "PDF ڊائون لوڊ ڪريو",
    paid: "ادا ڪيل",
    unpaid: "ادا نه ڪيل",
    partial: "جزوي",
    closeInvoice: "youا توھان انوائس بند ڪرڻ چاھيو ٿا؟",
    closeInvoiceConfirmation:
      "انوائس محفوظ نه ٿي سگھي. closeا توھان بند ڪرڻ چاھيو ٿا؟",
    yes: "ها",
    no: "نه",
    invoice: "انوائس",
    wasDeducted: "ڪٽيو ويو",
    for: "لاء",
    item: "شيون",
    addProduct: "پراڊڪٽ شامل ڪريو",
    paymentReference: "ادائيگي جو حوالو",
    amountPaid: "ادا ڪيل رقم",
    discountAmount: "رعايت جي رقم",
    amountDue: "واجب الادا رقم",
    amount: "رقم",
    dueDate: "مقرر تاريخ",
    paymentType: "ادائيگي جو قسم",
    card: "ڪارڊ",
    cash: "نقد",
    bankTransfer: "بئنڪ منتقلي",
    paymentMessage: "ادائيگي جو پيغام",
    description: "وضاحت",
    sendReceipt: "رسيد موڪليو",
    delete: "ختم ڪريو",
    save: "بچايو",
    resend: "Sendيهر موڪل",
    saveAndSend: "بچايو",
    invoiceSaved: "انوائس محفوظ ٿي وئي!",
    selectPaymentMethod: "مھرباني ڪري چونڊيو ادائيگي جو طريقو!",
    selectCustomer: "مھرباني ڪري ھڪڙو ڪسٽمر چونڊيو!",
    cartEmptyError:
      "ڪارٽ لسٽ خالي نه ٿي سگھي ، انوائس بند ڪريو ۽ ڪارٽ ۾ شيءِ شامل ڪريو!",
    subscriptionExpired:
      "توھان جي سبسڪرپشن ختم ٿي وئي آھي ۽ توھان جو اڪائونٽ ھاڻي محدود آھي. ھي account ڏنل بٽڻ تي ڪلڪ ڪريو پنھنجو اڪائونٽ نئين ڪرڻ لاءِ",
    renew: "تجديد ڪريو",
    holdOn: "ترسو",
    customerBank: "ڪسٽمر بئنڪ",
    cancel: "منسوخ ڪريو",
    selectACustomer: "منتخب ڪريو ڪسٽمر",
    invoiceSuccessfulPdfError:
      "انوائس ڪاميابي سان createdاھيو ويو پر PDF جنريشن و longerي رھي آھي معمول کان ويڪ. مھرباني ڪري جلد واپس چيڪ ڪريو.",
    downloadingInvoice: "ڊائون لوڊ انوائس",
    downloadingReceipt: "رسيد ڊائون لوڊ ڪرڻ",
    whatsappReceiptError:
      "هڪ غلطي ٿي وئي رسيد موڪلڻ ذريعي WhatsApp ذريعي ، مھرباني ڪري tryيھر ڪوشش ڪريو.",
    receiptToWhatsapp: "رسيد WhatsApp ڏانهن موڪلي وئي",
    thankYouForPatronage: "توهان جي سرپرستي لاءِ مهرباني",
    hereIsYourReceipt: "ھتي آھي توھان جي ادائيگي جي رسيد",
    errorSendingEmailReceipt:
      "هڪ غلطي آئي رسيد موڪلڻ ذريعي اي ميل ذريعي ، مهرباني ڪري tryيهر ڪوشش ڪريو يا سپورٽ سان رابطو ڪريو",
    receiptSentToEmail: "رسيد موڪلي وئي آهي ڪسٽمر جي اي ميل تي",
    invoiceSentToEmail: "انوائس موڪليو ويو آھي ڪسٽمر جي اي ميل ڏانھن",
    invoiceSuccessWhatsappError:
      "انوائس ڪاميابي سان createdاھيو ويو پر ھڪڙي غلطي ٿي وئي WhatsApp ڏانھن موڪلڻ ۾. Tryيهر ڪوشش ڪريو انوائس لسٽ تي",
    invoiceSuccessfulEmailError:
      "انوائس ڪاميابي سان createdاھي وئي پر ھڪڙي غلطي ٿي وئي جڏھن اي ميل موڪلڻ دوران. مھرباني ڪري انوائس لسٽ مان tryيھر ڪوشش ڪريو",
    invoiceSentToWhatsapp: "انوائس موڪليو ويو WhatsApp ڏانهن",
    hello: "هيلو",
    pleaseDownloadInvoice: "مھرباني ڪري انوائس ڊائون لوڊ ڪريو",
    pleaseDownloadReceipt: "مھرباني ڪري رسيد ڊائون لوڊ ڪريو",
    from: "کان",
    email: "اي ميل",
    upgrade: "اپ گريڊ",
    youAreOnFreePlan: "توهان آهيو هڪ مفت منصوبي تي.",
    clickOn: "تي ڪلڪ ڪريو",
    yourPlanInFewSteps: " توھان جي سبسڪرپشن ڪجھ تيز قدمن ۾.",
    to: "جي طرف",
    yourSubscriptionHasExpired:
      "توھان جي سبسڪرپشن ختم ٿي وئي آھي ۽ توھان جو اڪائونٽ ھاڻي محدود آھي.",
    days: "ڏينهن",
    yourSubscriptionExpiresIn: "توھان جي Loystar سبسڪرپشن ختم ٿي ويندي",
    createAcount: "هڪ اڪائونٽ Createاهيو",
    signIn: "سائن ان ڪريو",
    continue: "جاري رکو",
    enterOtp: "OTP PIN داخل ڪريو",
    enterValidOtp: "مھرباني ڪري ھڪڙو درست PIN داخل ڪريو",
    pin: "پن",
    tokenSentToMail: "توھان جي اي ميل ڏانھن ھڪڙو نشان موڪليو ويو آھي",
    passwordResetSuccessful: "پاسورڊ ري سيٽ ڪرڻ ڪامياب ويو",
    somethingWentWrong: "ڪجھ غلط ٿيو!",
    resetPassword: "پاسورڊ ري سيٽ ڪريو",
    iHaveResetCode: "مون وٽ آھي پاسورڊ ري سيٽ ڪرڻ جو ڪوڊ",
    pleaseEnterEmail: "مھرباني ڪري پنھنجو اي ميل داخل ڪريو",
    aTokenWillBeSentToEmail: "ھڪڙو ٽوڪن توھان جي اي ميل ڏانھن موڪليو ويندو",
    enterEmail: "پنھنجو اي ميل داخل ڪريو",
    sendinYourToken: "پنھنجو نشان موڪليندي ...",
    makeSureItMatchesPassword: "پڪ ڪريو ته اھو توھان جي نئين پاسورڊ سان ملندو",
    pleaseChooseNewPassword: "مھرباني ڪري نئون پاسورڊ چونڊيو",
    chooseNewPassword: "نئون پاسورڊ چونڊيو",
    enterNewPassword: "داخل ڪريو پنھنجو نئون پاسورڊ تصديق ڪرڻ لاءِ",
    enterTokenSent: "داخل ڪريو ٽوڪن جيڪو موڪليو ويو توهان جو ميل",
    resetToken: "ٽوڪن کي etيهر سيٽ ڪريو",
    resettingPassword: "پنهنجو پاسورڊ etيهر سيٽ ڪري رهيو آهي ...",
    signUp: "سائن اپ ڪريو",
    adminSignInWithEmail:
      " منتظم سائن ان ڪندو اي ميل سان جڏهن اسٽاف سائن ان ڪندو يوزر نالي سان.",
    pleaseEnterEmailOrUsername:
      "مھرباني ڪري پنھنجو اي ميل يا يوزر نالو داخل ڪريو",
    emailOrUsername: "اي ميل يا يوزر نالو",
    pleaseEnterPassword: "مھرباني ڪري پاسورڊ داخل ڪريو",
    createAnAccount: "هڪ اڪائونٽ Createاهيو",
    forgotPassword: "پاسورڊ وساري ڏيو؟",
    enterPhoneNumber: "فون نمبر داخل ڪريو",
    personalData: "ذاتي ڊيٽا",
    validateConfirmationCOde: "تصديق جي ڪوڊ جي تصديق ڪريو",
    pleaseEnterFirstName: "مھرباني ڪري پنھنجو نالو داخل ڪريو",
    pleaseEnterPhoneNumber: "مھرباني ڪري پنھنجو فون نمبر داخل ڪريو",
    pleaseEnterLastName: "مھرباني ڪري پنھنجو نالو داخل ڪريو",
    pleaseEnterBusinessName: "مھرباني ڪري پنھنجو ڪاروبار نالو داخل ڪريو",
    firstName: "پهريون نالو",
    lastName: "پويون نالو",
    businessName: "ڪاروباري نالو",
    previous: "پويون",
    next: "ايون",
    pleaseSelectBusinessCategory: "مھرباني ڪري پنھنجي ڪاروباري ڪيٽيگري چونڊيو",
    pleaseEnterValidEmail: "مھرباني ڪري صحيح اي ميل داخل ڪريو",
    pleaseEnterPostCode: "مھرباني ڪري پوسٽ ڪوڊ داخل ڪريو",
    postCode: "پوسٽ ڪوڊ",
    phoneNumberInUse: "ھي فون نمبر ا use ۾ ئي استعمال ۾ آھي!",
    emailInUse: "ھي اي ميل ا use ۾ ئي استعمال ۾ آھي!",
    foodAndDrinks: "کا Foodو ۽ مشروبات",
    salonAndBeauty: "سيلون ۽ خوبصورتي",
    fashionAndAccessories: "فيشن ۽ لوازمات",
    gymAndFitness: "جم ۽ فٽنيس",
    travelAndHotel: "سفر ۽ ھوٽل",
    homeAndGifts: "گھر ۽ تحفا",
    washingAndCleaning: "وئڻ ۽ صفائي",
    gadgetsAndElectronics: "گيجٽ ۽ اليڪٽرانڪس",
    groceries: "گروسري",
    others: "يا",
    submit: "جمع ڪرائڻ",
    accountCreatedSuccessful: "توھان جو کاتو ڪاميابي سان اھيو ويو.",
    pleaseEnterAddress: "مھرباني ڪري پنھنجو پتو داخل ڪريو",
    addressLine1: "ايڊريس لائين 1",
    addressLine2: "ايڊريس لائين 2",
    choosePassword: "چونڊيو پاسورڊ",
    passwordMustBe6Characters: "پاسورڊ گھٽ ۾ گھٽ 6 اکر ھجڻ گھرجي.",
    howDidYouHearLoystar: "توهان Loystar بابت ڪيئن و؟",
    referralCode: "حوالو ڪوڊ",
    byClickingTheButton: " ھي below ڏنل بٽڻ کي د Byائڻ سان ، توھان متفق آھيو",
    termsAndSevice: "شرطون",
    wereCreatingAccount: "اسان توهان جو اڪائونٽ اهي رهيا آهيون",
    proceed: "اceedتي وو",
    verificationCodeMustBe6: "تصديق جو ڪوڊ 6 عددن جو هجڻ گھرجي",
    pleaseEnter6DigitCode: "مھرباني ڪري 6 عدد ڪوڊ داخل ڪريو",
    enterVerificationCode: "تصدیقی ڪوڊ داخل ڪريو",
    resendToken: "ٽوڪن eيهر موڪليو",
    verify: "تصديق ڪريو",
    transactions: "ٽرانزيڪشن",
    todaySales: "ا Today's جي سيلز",
    salesHistory: "سيلز جي تاريخ",
    supplyHistory: "فراهمي جي تاريخ",
    new: "نئون",
    invoices: "انوائس",
    disbursements: "ورb",
    offlineSales: "آف لائن وڪرو",
    products: "مصنوعات",
    customers: "گراهڪ",
    multiLevelLoyalty: "ملٽي ليول وفاداري",
    loyaltyPrograms: "وفاداري پروگرام",
    members: "ميمبر",
    appStore: "ايپ اسٽور",
    orderMenu: "آرڊر مينيو",
    settings: "جوڙ",
    staffAndBranches: "اسٽاف ۽ شاخون",
    myAccount: "منهنجو اڪائونٽ",
    switchToSellMode: "وڪرو موڊ ڏانھن مٽايو",
    signOut: "سائن آئوٽ ڪريو",
    getFreeSubscription: "حاصل ڪريو مفت سبسڪرپشن",
    onlyNumbersAllowed: "صرف نمبرن جي اجازت آھي",
    yourAccountMustBe10Digits: "توھان جو اڪائونٽ نمبر 10 عدد نمبر ھجڻ گھرجي",
    yourBvnMustBe11: "توھان جو BVN 11 عدد نمبر ھجڻ گھرجي",
    pleaseSelectBank: "مھرباني ڪري پنھنجو بئنڪ چونڊيو",
    selectYourBank: "پنھنجي بئنڪ کي چونڊيو",
    enterBankAccountNumber: "بئنڪ اڪائونٽ نمبر داخل ڪريو",
    enterBvnNumber: "پنھنجو BVN داخل ڪريو",
    connectBankAccount: "بئنڪ اڪائونٽ Connectنيو",
    passwordResetSuccessfulAndSignOut:
      "توھان جو پاسورڊ ڪاميابي سان ري سيٽ ڪيو ويو آھي. signيهر سائن ان ڪرڻ لاءِ ھي below ڏنل سائن آئوٽ بٽڻ تي ڪلڪ ڪريو",
    enterCurrentPassword: "موجوده پاسورڊ داخل ڪريو",
    pleaseEnterCurrentPassword: "مھرباني ڪري موجوده پاسورڊ داخل ڪريو",
    phoneNumber: "فون نمبر",
    sex: "جنس",
    dateOfBirth: "ڄم جي تاريخ",
    state: "رياست",
    country: "ملڪ",
    loyaltyId: "وفاداري ID",
    createdAt: "تي اهيل",
    noOfTransactionsMade: "ڪيل ٽرانزيڪشن جو تعداد",
    yourDownloadWillStart: "توھان جو ڊائون لوڊ ھڪڙي لمحي ۾ شروع ٿي ويندو",
    exportCustomers: "برآمد ڪندڙ گراهڪ",
    youhaveSuccessfullyToppedUp:
      "توھان ڪاميابي سان پنھنجي ايس ايم ايس يونٽس کي مٿي ڪيو آھي.",
    youNowHave: "توھان وٽ ھاڻي آھي",
    smsUnits: "ايس ايم ايس يونٽ",
    enterNumberOfSmsUnits:
      "داخل ڪريو ايس ايم ايس يونٽن جو تعداد جيڪو توھان خريد ڪرڻ جو ارادو رکو ٿا",
    pleaseEnterANumericValue: "مھرباني ڪري ھڪڙو عددي قدر داخل ڪريو",
    pay: "ادا",
    accountEditedSuccessfully: "توھان جو کاتو ڪاميابي سان تبديل ڪيو ويو.",
    youAeCurrentlyOn: "توهان هن وقت آهيو",
    plan: "منصوبو",
    userData: "استعمال ڪندڙ ڊيٽا",
    businessData: "ڪاروباري ڊيٽا",
    businessCategory: "ڪاروباري کاaterو",
    pleaseSelectCurrency: "مھرباني ڪري پنھنجي ڪرنسي چونڊيو",
    selectYourCurrency: "پنھنجي ڪرنسي چونڊيو",
    purchaseMoreSmsUnits:
      "خريد ڪريو و SMSيڪ ايس ايم ايس يونٽ ھي using ڏنل فارم استعمال ڪندي",
    youHave: "توهان کي آهي",
    atLeast4SmsUnitsRrequired:
      "تصديق لاءِ گھٽ ۾ گھٽ 4 ايس ايم ايس يونٽ گھرجن ، مھرباني ڪري مٿي ڪريو!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "مھرباني ڪري پنھنجي بئنڪ اڪائونٽ جي تصديق ڪريو انھيءَ جي تعميل ڪرڻ لاءِ ’Yourاڻو پنھنجو ڪسٽمر‘ (KYC) گھربل. اھو توھان کي اجازت ڏيندو USSD ذريعي ادائيگي گڏ ڪرڻ يا فوري منتقلي ، گراهڪن کان آرڊر وصول ڪرڻ ۽ ٽرانزيڪشن جي بندوبست تي عمل ڪرڻ. قابل اطلاق صرف نائجريائي واپارين لاءِ. مھرباني ڪري ھي below ڏنل بٽڻ کي دايو شروع ڪرڻ لاءِ.",
    reConnectBankAccount: "بئنڪ اڪائونٽ -يهر جوڙيو",
    accountName: "کاتي جو نالو",
    accountNumber: "اڪائونٽ نمبر",
    bankName: "بئنڪ جو نالو",
    verified: "تصديق ٿيل",
    accountDetails: "کاتي جا تفصيل",
    kycBankAccount: "KYC",
    createTier: "درجو Createاھيو",
    fileUploadSuccessful: "فائل اپ لوڊ ٿي وئي ڪاميابي سان",
    fileUploadFailed: "فائل اپ لوڊ ناڪام",
    createLoyaltyProgram: "هڪ وفاداري پروگرام اهيو",
    createLoyalty: "وفاداري اهيو",
    name: "نالو",
    loyaltyArtwork: "وفاداري جو فن",
    clickToUpload: "اپلوڊ ڪرڻ لاءِ ڪلڪ ڪريو",
    amountToPointsRatio: "رقم پوائنٽن جي نسبت",
    points: "پوائنٽون",
    recommendedAmountToPointRatio:
      "تجويز ڪيل: ₦ 1 کان 1 پوائنٽ. يعني هر 1 نيرا خرچ لاءِ ، توهان جا گراهڪ حاصل ڪندا 1 پوائنٽ",
    pleaseTypeIn: "مھرباني ڪري داخل ڪريو",
    toDeleteLoyalty: "ھن وفاداري کي ختم ڪرڻ لاءِ",
    deleteLoyalty: "وفاداري ختم ڪريو",
    toConfirm: "پڪ ڪرڻ",
    edit: "تبديلي آڻيو",
    pointsAwardedSuccessfully: "پوائنٽس ڪاميابي سان نوازيا ويا.",
    enterPointValueToAward:
      "پوائنٽ ويليو داخل ڪريو توھان چاھيو ٿا ته انعام ڏيو گراهڪن کي",
    award: "اوارڊ",
    awardPointValuesToCustomer: "اوارڊ پوائنٽ قيمت ڪسٽمر لاءِ",
    enrollMembersToLoyalty: "وفاداري تي ميمبر داخل ڪريو",
    awardPoints: "اوارڊ پوائنٽون",
    enroll: "داخل ٿيو",
    home: "گهر",
    loyalty: "وفاداري",
    enrollCustomers: "گراهڪن کي داخل ڪريو",
    selected: "منتخب ٿيل",
    customer: "ڪسٽمر",
    loyaltyActivationSuccessful: "وفاداري سرگرمي ڪامياب آھي.",
    loyaltyDeactivationSuccessful: "وفاداري ختم ڪرڻ ڪامياب آهي.",
    viewTier: "درجو ڏسو",
    deactivate: "غير فعال ڪريو",
    activate: "چالو ڪريو",
    actions: "عمل",
    nameOfLoyalty: "وفاداريءَ جو نالو",
    loyaltyStatus: "وفاداري جي حالت وفاداري جي حالت",
    numberOfTiers: "درجن جو تعداد",
    createdOn: "تي اهيل",
    createATier: "ھڪڙو درجو اھيو",
    stopCreatingTierConfirmation:
      "youا توھان ھڪڙو درجو creatingاھڻ کي روڪڻ چاھيو ٿا؟",
    stopEditingTierConfirmation:
      "youا توھان ھن درجي جي ترميم کي روڪڻ چاھيو ٿا؟",
    nameOfTier: "درجي جو نالو",
    minimumSpendingTarget: "گھٽ ۾ گھٽ خرچ ڪرڻ جو ھدف",
    maximumSpendingTarget: "وimum ۾ و S خرچ ڪرڻ جو ھدف",
    minimumSpendingTargetRequired: "گھٽ ۾ گھٽ خرچ جو ھدف گھربل آھي",
    maximumSpendingTargetRequired:
      "و maximum ۾ و spending خرچ جو ٽارگيٽ گھربل آھي",
    rewardSponsor: "انعام ڏيڻ وارو اسپانسر",
    pleaseChooseARewardSponsor: "مھرباني ڪري ھڪڙو انعام اسپانسر چونڊيو",
    self: "پاڻ",
    partner: "ساٿي",
    rewardPartner: "انعام وارو ساٿي",
    pleaseSelectRewardPartner: "مھرباني ڪري پنھنجو انعام وارو ساٿي چونڊيو",
    rewards: "انعام",
    pleaseSelectAReward: "مھرباني ڪري ھڪڙو انعام چونڊيو",
    instructionsOnRedeemingReward: "ھدايتون ته ڪيئن ڪسٽمر کي انعام ڏيڻ گھرجن",
    pleaseFillInThisField: "مھرباني ڪري ھن فيلڊ ۾ ريو!",
    toDeleteThisTier: " ھن درجي کي ختم ڪرڻ لاءِ",
    deleteTier: "درجو حذف ڪريو",
    viewMembers: "ميمبر ڏسو",
    membersEnrolled: "ميمبر داخل ٿيا",
    instruction: "هدايت",
    membersIn: "۾ ميمبر",
    availableTiersInLoyalty: "دستياب درجي وفاداري پروگرام ۾",
    tiers: "درجا",
    totalTier: "مجموعي ٽائر",
    availableLoyaltyProgramme: "دستياب وفاداري پروگرام",
    totalLoyalties: "مجموعي وفاداريون",
    memberDetails: "رڪن جا تفصيل",
    nameOfCustomer: "ڪسٽمر جو نالو",
    address: "پتو",
    allEnrolledMembers: "س Allئي داخل ٿيل ميمبر",
    thisIsToWishYouHappyBirthday:
      "هي آهي خواهش ڪرڻ توهان کي هڪ تمام خوش سالگره ۽ خوشحال زندگي. مهرباني انهن س forني جي جيڪي توهان آهيو Loyster ڏانهن. جشن مبارڪون!",
    inputAnOfferPlease: "مهرباني ڪري هڪ آ داخل ڪريو",
    pleaseSelectTheInsertPoint:
      "مھرباني ڪري پيغام ۾ داخل پوائنٽ چونڊيو ۽ clickيھر ڪلڪ ڪريو",
    birthdayOfferAndMessage: "سالگره جي آer ۽ پيغام",
    birthdayOffer: "سالگره جي آ",
    birthdayMessage: "جنم ڏينهن جو پيغام",
    noOfferFound: "ڪابه آ offer نه ملي",
    settingABirthdayOffer:
      "سالگره جي آ offer کي ترتيب ڏيڻ گراهڪن کي اجازت ڏئي ٿو ته اها آ offer وصول ڪن SMS ذريعي انهن جي سالگره تي",
    createOffer: "آ Create Createاھيو",
    whatIsTheOffer: "پيشڪش ا آهي؟",
    editMessage: "تبديلي آڻيو پيغام",
    insert: "داخل ڪريو",
    theNameOfCustomerInserted: "ڪسٽمر جو نالو داخل ڪيو ويندو هتي",
    theBirtdayOfferInserted: "سالگره جي آ offer داخل ڪئي ويندي هتي",
    youSuccessfullyAddedNewBranch:
      "توھان ڪاميابي سان شامل ڪئي آھي ھڪڙي نئين شاخ!",
    addNewBranch: "نئون برانچ شامل ڪريو",
    addBranch: "برانچ شامل ڪريو",
    additionalBranchWillIncur: "ايڊيشنل برانچ کڻندي",
    perBranch: "في شاخ",
    ecommerceBranchCosts: "اي ڪامرس برانچ خرچ",
    pleaseEnterBranchName: "مھرباني ڪري شاخ جو نالو داخل ڪريو",
    pleaseEnterBranchAddress1: "مھرباني ڪري شاخ جي ايڊريس لائين 1 داخل ڪريو",
    enterBranchAddress1: "شاخ جي ايڊريس لائين داخل ڪريو 1",
    enterBranchAddress2: "شاخ جي ايڊريس لائين داخل ڪريو 1",
    pleaseEnterBranchAddress2: "مھرباني ڪري شاخ جي ايڊريس لائين 2 داخل ڪريو",
    enterBranchName: "شاخ جو نالو داخل ڪريو",
    successfullyAddedStaff: "توھان ڪاميابي سان ھڪڙو نئون عملو شامل ڪيو آھي!",
    addNewStaff: "نئون اسٽاف شامل ڪريو",
    addStaff: "اسٽاف شامل ڪريو",
    additionalStaffWillIncur: "ايڊيشنل اسٽاف کڻندو",
    perStaff: "في اسٽاف.",
    pleaseEnterStaffEmail: "مھرباني ڪري عملي جي اي ميل داخل ڪريو",
    pleaseEnterStaffUsername: "مھرباني ڪري اسٽاف جو يوزر نالو داخل ڪريو",
    pleaseEnterStaffPassword: "مھرباني ڪري عملي جو پاسورڊ داخل ڪريو",
    pleaseSelectStaffRole: "مھرباني ڪري اسٽاف جو ڪردار چونڊيو",
    selectStaffRole: "اسٽاف جو ڪردار چونڊيو",
    enterStaffEmail: "اسٽاف جي اي ميل داخل ڪريو",
    enterStaffUsername: "اسٽاف جو يوزر نالو داخل ڪريو",
    enterStaffPassword: "اسٽاف جو پاسورڊ داخل ڪريو",
    spacesNotAllowedInUsername: "استعمال ڪندڙ جي نالي ۾ جesھن جي اجازت ناھي",
    admin: "ايڊمن",
    pleaseSelectBusinessToAttachStaff:
      "مھرباني ڪري ڪاروبار چونڊيو اسٽاف کي نڻ لاءِ",
    searchForBranchToAttachStaff: "شاخ Searchوليو عملو attachنڻ لاءِ",
    username: "استعمال ڪندڙ جو نالو",
    role: "ڪردار",
    areYouSureToDeleteThis: "youا توھان کي پڪ آھي ته ھن کي ختم ڪريو",
    branches: "شاخون",
    upgradeYourPlan: "پنھنجي منصوبي کي وايو.",
    add: "شامل ڪريو",
    addNew: "نئون شامل ڪريو",
    customerWithEmailAlreadyExists:
      "ڪسٽمر سان اي ميل/فون نمبر ا already ۾ ئي موجود آهي!",
    successfullyAddedNewCustomer:
      "توھان ڪاميابي سان شامل ڪيو آھي ھڪڙو نئون ڪسٽمر!",
    addCustomer: "ڪسٽمر شامل ڪريو",
    pleaseEnterCustomerFirstName: "مھرباني ڪري ڪسٽمر جو نالو داخل ڪريو",
    pleaseEnterCustomerLastName: "مھرباني ڪري ڪسٽمر جو آخري نالو داخل ڪريو",
    pleaseEnterCustomerPhoneNumber: "مھرباني ڪري ڪسٽمر جو فون نمبر داخل ڪريو",
    pleaseEnterCustomerEmail: "مھرباني ڪري ڪسٽمر جي اي ميل داخل ڪريو",
    pleaseEnterCustomerAddressLine:
      "مھرباني ڪري ڪسٽمر جي ايڊريس لائين داخل ڪريو",
    pleaseEnterCustomerOtherAddress:
      "مھرباني ڪري ڪسٽمر جو otherيو پتو داخل ڪريو",
    pleaseSelectCustomerGender: "مھرباني ڪري چونڊيو ڪسٽمر جي جنس",
    gender: "جنس",
    male: "نر",
    female: "عورت",
    bank: "بئنڪ",
    selectBank: "چونڊيو بئنڪ",
    stateOrRegionOrProvince: "رياست/علائقو/صوبو",
    customerNotes: "ڪسٽمر نوٽس",
    sendSms: "ايس ايم ايس موڪليو",
    editCustomer: "ڪسٽمر کي تبديل ڪريو",
    redeemReward: "انعام حاصل ڪريو",
    issueLoyaltyCard: "وفاداري ڪارڊ جاري ڪريو",
    deleteCustomer: "ڪسٽمر کي ختم ڪريو",
    youveSuccessfullyAssignedLoyaltyMembership:
      "توھان ڪاميابي سان مقرر ڪيو آھي وفاداري ميمبرشپ ID",
    noMembershipIdAvailable:
      "ڪابه رڪنيت جي س availableاڻپ موجود ناهي. مهرباني ڪري اسان سان رابطو ڪريو hello@loystar.co تي",
    sendEmail: "اي ميل موڪليو",
    membershipPoints: "رڪنيت جا پوائنٽون",
    customerDetails: "ڪسٽمر تفصيل",
    close: "بند",
    loyaltyBalance: "وفاداري توازن",
    pointsBalance: "پوائنٽس بيلنس",
    starBalance: "اسٽار بيلنس",
    requiredPoints: "گهربل پوائنٽون",
    requiredStars: "گھربل ستارا",
    reddemCode: "ريڊم ڪوڊ",
    toDeleteThisCustomer: "ھن ڪسٽمر کي ختم ڪرڻ لاءِ",
    customerHasBeenDeletedSuccessfully: "ڪسٽمر ھٽايو ويو ڪاميابي سان!",
    customerWithPhoneAlreadyExists:
      "ڪسٽمر فون نمبر سان ا already ۾ ئي موجود آهي",
    customerWasSuccessfullyEdited: "ڪسٽمر ڪاميابي سان تبديل ڪيو ويو",
    anErrorOccured: "هڪ غلطي ٿي وئي",
    phoneNumberIncludeCountry: "فون نمبر (ملڪ جو ڪوڊ شامل ڪريو)",
    yourFileQueuedForUpload:
      "توھان جي فائل اپ لوڊ ڪرڻ لاءِ قطار ۾ بيي آھي. مھرباني ڪري صفحي کي تازو ڪريو ڪجھ سيڪنڊن کان پوءِ.",
    thereWasAnErrorPerformingOperation: "اتي هڪ غلطي هئي آپريشن ڪرڻ ۾!",
    pleaseSelectFile: "مھرباني ڪري ھڪڙي فائل چونڊيو!",
    oopsOnlyCsvFilesAllowed:
      "اڙي! صرف CSV فائلن جي اجازت آھي. مھرباني ڪري ھڪڙي .csv فائل اپ لوڊ ڪريو.",
    fileShouldBeSmallerThan5Mb:
      "فائل 5MB کان نن beي ھجڻ گھرجي. جيڪڏھن توھان وٽ آھي ڪا وڏي فائل ، مھرباني ڪري اي ميل ڪريو support@loystar.co. توهان جي مهرباني",
    customerFirstNameIsRequired: "ڪسٽمر جو پهريون نالو گھربل آھي قطار تي",
    customerPhoneNumberIsRequired: "ڪسٽمر فون نمبر گھربل آھي قطار ۾",
    importCustomers: "گراهڪن کي درآمد ڪريو",
    upload: "اپ لوڊ",
    clickIconToDownloadCsv:
      "ڪلڪ ڪريو ھن آئڪن کي CSV فائل ٽيمپليٽ ڊائون لوڊ ڪرڻ لاءِ.",
    getGoogleSheetVersion: "حاصل ڪريو گوگل شيٽ ورجن",
    clickOrDragFileToUpload:
      "ڪلڪ ڪريو يا dragڪيو فائل ھن علائقي ڏانھن اپ لوڊ ڪرڻ لاءِ",
    missingOrInvalidColumnHeader:
      "غائب يا غلط ڪالمن جو مٿو. مھرباني ڪري ٽيمپليٽ فارميٽ جي پيروي ڪريو. توهان جي مهرباني.",
    youHaveImported: "توھان درآمد ڪيو آھي",
    youSuccessfullyRedeemedReward: "توھان ڪاميابي سان پنھنجو اجر حاصل ڪيو آھي!",
    sixDigitCode: "digitهه عدد ڪوڊ",
    pleaseEnterCustomerRewardCode: "مھرباني ڪري ڪسٽمر جو انعام ڪوڊ داخل ڪريو",
    enterRewardCode: "انعام جو ڪوڊ داخل ڪريو. (انعام جو ڪوڊ حساس آھي)",
    selectLoyaltyProgram: "چونڊيو وفاداري پروگرام",
    stamps: "ٽپالون",
    smsUnitsLow: "ايس ايم ايس يونٽ گھٽ",
    pleaseTopUpSmsUnits: "مھرباني ڪري مٿي ڪريو ايس ايم ايس يونٽ",
    smsSuccessfullySentForDelivery:
      "ايس ايم ايس ڪاميابي سان پهچائڻ لاءِ موڪليو ويو!",
    sendSmsTo: "ڏانهن ايس ايم ايس موڪليو",
    allCustomers: "س Allئي گراهڪ",
    unitsAvailable: "موجود يونٽ",
    pleaseTypeInTheMessage: "مھرباني ڪري پيغام لکو",
    message: "نياپو",
    charactersRemaining: "ڪردار باقي",
    avoidUseOfSpecialCharacters:
      "خاص اکرن ۽ ايموجيز جي استعمال کان پاسو ڪريو ايس ايم ايس يونٽس کي محفوظ ڪرڻ لاءِ.",
    note: "نوٽ",
    errorFetchingCustomersMultilevelDetail:
      "نقص آڻيو گراهڪن کي ملٽي ليول تفصيلات",
    search: "ولا",
    reset: "ري سيٽ ڪريو",
    importCustomer: "درآمد ڪندڙ ڪسٽمر",
    addNewCustomer: "نئون ڪسٽمر شامل ڪريو",
    sendSmsBroadcast: "ايس ايم ايس براڊ ڪاسٽ موڪليو",
    totalCustomers: "ڪل گراهڪ",
    disbursementDetails: "ادائيگي جا تفصيل",
    paidBy: "پاران ادا ڪيل",
    disbursed: "وراڻيو ويو",
    bankAccountName: "بئنڪ اڪائونٽ جو نالو",
    bankAccountNumber: "بئنڪ اڪائونٽ نمبر",
    transferInitiated: "منتقلي شروع ڪئي وئي",
    transferCompleted: "منتقلي مڪمل",
    pleaseEnterAValid: "مھرباني ڪري صحيح داخل ڪريو",
    begin: "شروع",
    end: "ختم",
    date: "تاريخ",
    paymentDate: "ادائيگي جي تاريخ",
    selectDisbursementDuration: "منتخب ڪريو تقسيم جي مدت",
    totalSettled: "کل آباد",
    totalUnsettled: "تمام بي پرواھ",
    toDeleteThisSale: "ھن وڪرو کي ختم ڪرڻ لاءِ",
    draftSaleDeletedSuccessfully: "ڊرافٽ سيل ڪاميابي سان ڊليٽ ڪيو ويو آھي!",
    deleteSale: "سيل ختم ڪريو",
    pleaseTypeInYourTotal:
      "مھرباني ڪري پنھنجي ڪل ٽائيپ ڪريو حذف ڪرڻ جي تصديق ڪرڻ لاءِ",
    billDetails: "بل جا تفصيل",
    printBill: "پرنٽ بل",
    servedBy: "پاران پيش ڪيل",
    total: "ڪُل",
    createdDate: "Creاهيل تاريخ",
    createdTime: "atedاهيل وقت",
    status: "حيثيت",
    loginSuccessful: "لاگ ان ڪامياب",
    pleaseWaitWhileWeConnectAccount:
      "مهرباني ڪري انتظار ڪريو جڏهن اسان توهان جو اڪائونٽ connectنيو",
    connectionFailedTryAgain: "ڪنيڪشن ناڪام ٿيو. مھرباني ڪري tryيھر ڪوشش ڪريو.",
    connectionSuccessful: "ڪنيڪشن ڪامياب",
    viewDetails: "تفصيل ڏسو",
    enable: "فعال ڪريو",
    free: "واندو",
    cost: "قيمت",
    visitWebsite: "ويب سائيٽ جو دورو ڪريو",
    pleaseUpgradeYourPlanToPro:
      "مھرباني ڪري ھن منصوبي کي فعال ڪرڻ لاءِ پنھنجي منصوبي کي پرو پلس ۾ اپ گريڊ ڪريو",
    connectYourBankAccount:
      "پنھنجو بئنڪ اڪائونٽ جوڙيو ته جيئن ادائيگيون حاصل ڪري سگھجن.",
    disable: "غير فعال ڪرڻ",
    enableApp: "ايپ کي فعال ڪريو",
    addNewProductToInvoice: "شامل ڪريو نئين پراڊڪٽ انوائس ۾",
    toDeleteThisInvoice: "ھن انوائس کي ختم ڪرڻ لاءِ",
    invoiceDeletedSuccessfully: "انوائس ڪاميابي سان ختم ڪئي وئي آھي!",
    deleteInvoice: "انوائس ختم ڪريو",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "توھان ڪاميابيءَ سان شامل ڪيو آھي انوائس ادائيگيءَ جو پيغام!",
    pleaseEnterPaymentInstructions: "مھرباني ڪري ادائيگي جون هدايتون داخل ڪريو",
    invoiceId: "انوائس ID",
    paidDate: "ادا ڪيل تاريخ",
    reference: "حوالو",
    productAdded: "پيداوار شامل ڪئي وئي",
    productOutOfStock: "پيداوار اسٽاڪ کان اهر. مهرباني ڪري -يهر اسٽاڪ ڪريو.",
    totalInvoices: "کل انوائس",
    totalPaidInvoices: "کل ادا ڪيل انوائس",
    totalUnpaidInvoices: "ٽوٽل غير ادائيگيون",
    loyaltyProgramDeleted: "وفاداري پروگرام ختم ڪيو ويو",
    thisLoyaltyProgramDeletedSuccessfully:
      "ھي وفاداري پروگرام ڪاميابي سان ختم ڪيو ويو آھي",
    thisLoyaltyProgramEditedSuccessfully:
      "ھي وفاداري پروگرام ڪاميابي سان تبديل ڪيو ويو آھي",
    loyaltyProgramAddedSuccessfully:
      "وفاداري پروگرام ڪاميابي سان شامل ڪيو ويو آھي",
    loyaltyProgramEdited: "وفاداري پروگرام ايڊٽ ڪيو ويو",
    loyaltyProgramAdded: "وفاداري پروگرام شامل ڪيو ويو",
    loyaltyDetails: "وفاداري جا تفصيل",
    doYouWantToCloseDialog: "thisا توھان ھي ڊائيلاگ بند ڪرڻ چاھيو ٿا؟",
    pleaseEnterLoyaltyName: "مھرباني ڪري پنھنجو وفاداري جو نالو داخل ڪريو",
    programType: "پروگرام جو قسم",
    pleaseSelectProgramType: "مھرباني ڪري ھڪڙو پروگرام جو قسم چونڊيو",
    simplePoints: "سادي پوائنٽون",
    stampsProgram: "ٽپال پروگرام",
    threshold: "حد",
    pleaseEnterLoyaltyThreshold: "مھرباني ڪري وفاداريءَ جي حد داخل ڪريو",
    reward: "انعام",
    pleaseEnterLoyaltyReward: "مھرباني ڪري وفاداريءَ جو انعام داخل ڪريو",
    totalUserPoints: "ڪل استعمال ڪندڙ پوائنٽون",
    totalUserStamps: "ڪل استعمال ڪندڙ اسٽيمپ",
    spendingTarget: "ھدف خرچ ڪرڻ",
    spendingTargetHint1:
      "خرچ ڪرڻ جو ٽارگيٽ اھو آھي ته ڪسٽمر کي ڪيترو خرچ ڪرڻ گھرجي انعام حاصل ڪرڻ لاءِ. 1 ڪرنسي ويليو = 1 پوائنٽ.",
    spendingTargetHint2:
      "ٽڪرن جو ٽارگيٽ آهي ته ڪيترا اسٽيمپ هڪ ڪسٽمر کي گڏ ڪرڻ گهرجن انعام حاصل ڪرڻ لاءِ. مثال طور 5",
    addNewLoyaltyProgram: "نئون وفاداري پروگرام شامل ڪريو",
    addLoyaltyProgram: "وفاداري پروگرام شامل ڪريو",
    loyaltyProgramType: "وفاداري پروگرام جو قسم",
    pleaseSelectLoyaltyProgramType: "مھرباني ڪري چونڊيو وفاداري پروگرام جو قسم",
    nameOfProgram: "پروگرام جو نالو",
    pleaseEnterProgramName: "مھرباني ڪري پروگرام جو نالو داخل ڪريو",
    whatIsTheReward: "ثواب ا آهي؟",
    egNextPurchaseIsFree: "مثال طور ايندڙ خريداري مفت آهي",
    customerName: "گراهڪ جو نالو",
    guestCustomer: "مهمان ڪسٽمر",
    orderConfirmedSuccessfully: "آرڊر ڪاميابي سان تصديق ڪئي",
    orderCancelledSuccessfully: "آرڊر ڪاميابي سان منسوخ ٿي ويو",
    confirmOrder: "حڪم جي تصديق ڪريو",
    cancelOrder: "آرڊر منسوخ ڪريو",
    allOrders: "س حڪم",
    totalOrders: "مجموعي حڪم",
    doYouWantToAcceptOrder: "youا توھان ھن حڪم کي قبول ڪرڻ چاھيو ٿا؟",
    doYouWantToCancelOrder: "youا توھان ھن آرڊر کي منسوخ ڪرڻ چاھيو ٿا؟",
    orderDetails: "آرڊر جا تفصيل",
    orderCreatedAt: "تي Orderاهيل آرڊر",
    supplier: "فراهم ڪندڙ",
    productName: "شئ جو نالو",
    quantity: "مقدار",
    unitPrice: "يونٽ جي قيمت",
    receivedBy: "پاران مليل",
    reportFrom: "کان رپورٽ",
    totalSupplies: "ڪل سامان",
    allRightsReserved: "س Rights حق محفوظ آهن",
    toDeleteThisTransaction: "ھن ٽرانزيڪشن کي ختم ڪرڻ لاءِ",
    transactionDeletedSuccessfully:
      "ٽرانزيڪشن ڪاميابي سان ختم ڪئي وئي آھي. اسٽاڪ انوینٽريءَ ڏانھن واپس ڪيو ويو آھي.",
    deleteTransaction: "ٽرانزيڪشن کي ختم ڪريو",
    transactionDetails: "ٽرانزيڪشن جا تفصيل",
    printReceipt: "پرنٽ رسيد",
    channel: "چئنل",
    discount: "رعايت",
    profit: "نفعو",
    discountedSales: "رعايتي وڪرو",
    errorFetchingRecord: "رڪارڊ آڻڻ ۾ غلطي",
    exportTransactions: "ايڪسپورٽ ٽرانزيڪشن",
    errorFetchingSalesRecord: "برآمد لاءِ سيلز رڪارڊ آڻڻ ۾ غلطي.",
    totalSellingPrice: "کل وڪڻڻ جي قيمت",
    totalCostPrice: "مجموعي قيمت",
    appliedDiscount: "لاliedو ٿيل رعايت",
    noOfItems: "شين جو تعداد",
    sales: "وڪرو",
    export: "برآمد",
    totalProfit: "کل منافعو",
    totalBalanceInPeriod: "دور ۾ ڪل توازن",
    allTimeSales: "س Time وقت وڪرو",
    records: "ريڪارڊ",
    todaysSales: "ا Today's جي سيلز",
    transaction: "ٽرانزيڪشن",
    youHaveExceededTotalNumberOfProducts:
      "توھان پنھنجي منصوبي تي اجازت ڏنل پروڊڪٽس جي ڪل تعداد کان وي ويا آھيو. پنھنجي منصوبي کي اپ گريڊ ڪريو لطف اندوز ٿيڻ لاءِ و higherيڪ حد تائين.",
    youNeedToHaveLoyaltyProgram:
      "توھان کي گھرجي ھڪڙو وفاداري پروگرام ھن خصوصيت کي استعمال ڪرڻ لاءِ!",
    price: "قيمت",
    category: "زمرو",
    stockTracking: "اسٽاڪ ٽريڪنگ",
    stockCount: "اسٽاڪ جي ڻپ",
    taxed: "ٽيڪس لل",
    originalPrice: "اصل قيمت",
    costPrice: "قيمت جي قيمت",
    unit: "يونٽ",
    productImage: "پيداوار تصوير",
    taxRate: "ٽيڪس جي شرح",
    taxType: "ٽيڪس جو قسم",
    trackInventory: "ٽريڪ جي فهرست",
    variants: "ورانڊي",
    hasVariants: "وٽ ورائٽيون آھن",
    importProduct: "پيداوار درآمد ڪريو",
    exportProducts: "مصنوعات برآمد ڪريو",
    addNewProduct: "نئون پراڊڪٽ شامل ڪريو",
    viewCategory: "درجه بندي ڏسو",
    viewSuppliers: "سپلائرز ڏسو",
    receiveInventory: "انوینٽري وصول ڪريو",
    printAllProductsTag: "Productsپايو س Products پراڊڪٽس ٽيگ",
    deleteAll: "سlete ختم ڪريو",
    totalProducts: "ٽوٽل پراڊڪٽس",
    youveSuccessfullyAddedANewCategory:
      "توھان ڪاميابي سان شامل ڪيو آھي ھڪڙو نئون درجو",
    addNewCategory: "نئون زمرو شامل ڪريو",
    addCategory: "درجه بندي شامل ڪريو",
    categoryName: "درجي جو نالو",
    pleaseEnterCategoryName: "مھرباني ڪري درجي جو نالو داخل ڪريو",
    stampsTarget: "ٽارگيٽ ٽارگيٽ",
    sendInventory: "سامان موڪليو",
    productDetails: "پيداوار جا تفصيل",
    youveSuccessfullyEditedThisCategory:
      "توھان ڪاميابي سان ھن ڪيٽيگري ۾ ترميم ڪئي آھي",
    update: "اپ ڊيٽ",
    categoryList: "درجه بندي جي فهرست",
    categories: "Categoriesاا",
    enterQuantityToUpdate: "ھڪڙو مقدار داخل ڪريو تازه ڪاري ڪرڻ لاءِ",
    inventorySentSuccessfully: "فهرست موڪليو ويو ڪاميابي سان!",
    updateInventory: "اپڊيٽ انوینٽري",
    currentQuantity: "موجوده مقدار",
    pleaseEnterQuantityToAdd:
      "مھرباني ڪري اھو مقدار داخل ڪريو جيڪو توھان شامل ڪرڻ چاھيو ٿا",
    pleaseSelectABranch: "مھرباني ڪري ھڪ برانچ چونڊيو",
    searchForBranch: "شاخ جي ولا ڪريو",
    youCantSendMoreThanStock:
      "توهان نٿا موڪلي سگهو ان کان و youيڪ جيڪو توهان وٽ آهي اسٽاڪ ۾",
    send: "موڪل",
    pleaseEnterQuantityToSend:
      "مھرباني ڪري اھو مقدار داخل ڪريو جيڪو توھان موڪلڻ چاھيو ٿا",
    productNameIsRequiredOnRow: "پيداوار جو نالو گھربل آھي قطار ۾",
    productCategoryIsRequiredOnRow: "پراڊڪٽ جو درجو گھربل آھي قطار ۾",
    productPriceIsRequiredOnRow: "پيداوار جي قيمت قطار ۾ گھربل آھي",
    productUnitIsRequiredOnRow: "پراڊڪٽ يونٽ قطار تي گھربل آھي",
    productQuantityIsRequiredOnRow: "پيداوار جي مقدار قطار ۾ گھربل آھي",
    productVariantsRequireTracking:
      "پراڊڪٽ جي مختلف قسمن کي ٽريڪنگ جي ضرورت آھي!",
    pleaseAddVariantClickButton:
      "مھرباني ڪري شامل ڪريو بٽڻ د clickingائڻ سان ھڪڙو قسم شامل ڪريو!",
    totalVariantsMoreThanSelectedQuantity:
      "ڪل ورائيٽيون چونڊيل پراڊڪٽ جي مقدار کان ويڪ آھن ، Pls مختلف مقدار کي گھٽائي ٿو",
    productEditedSuccessfully: "پراڊڪٽ کي تبديل ڪيو ويو ڪاميابي سان!",
    fileTooLargeLessThan4Mb:
      "فائل سائيز تمام وڏي آھي! فائل سائيز 4MB کان گھٽ ھجڻ گھرجي.",
    suchVariantAlreadyExist: "اهڙي قسم جو ا already ۾ ئي موجود آهي",
    addVariants: "Variants شامل ڪريو",
    editProduct: "پيداوار ۾ تبديلي آڻيو",
    pleaseEnterProductName: "مھرباني ڪري پراڊڪٽ جو نالو داخل ڪريو",
    pleaseEnterProductPrice: "مھرباني ڪري پراڊڪٽ جي قيمت داخل ڪريو",
    pleaseEnterProductOriginalPrice:
      "مھرباني ڪري پراڊڪٽ جي اصلي قيمت داخل ڪريو",
    productDescription: "پيداوار جي وضاحت",
    selectProductCategory: "منتخب ڪريو پراڊڪٽ جي ڪيٽيگري",
    pleaseSelectProductCategory: "مھرباني ڪري پراڊڪٽ جو درجو چونڊيو",
    productCostPrice: "پيداوار جي قيمت",
    productSellingPrice: "پراڊڪٽ وڪڻڻ جي قيمت",
    productOriginalPrice: "پيداوار جي اصل قيمت",
    maxFileSizeAllowedIs4Mb: "و.. فائل سائيز جي اجازت آھي 4 ايم بي",
    productsWithPicturesArePublished:
      "مصنوعات تصويرن سان شايع ٿيل آھن ڊسڪور تي آرڊر حاصل ڪرڻ لاءِ",
    shouldThisProductBeTracked: "productا ھي پراڊڪٽ ٽريڪ ٿيڻ گھرجي؟",
    pleaseSelectStockUnit: "مھرباني ڪري اسٽاڪ يونٽ چونڊيو",
    stockUnit: "اسٽاڪ يونٽ",
    bag: "بيگ",
    bottle: "بوتل",
    bunch: "گُڻ",
    can: "ڪري سگھي ٿو",
    carton: "ڪارون",
    crate: "CRATE",
    cup: "ڪپ",
    dozen: "ڊيزين",
    gigabytes: "گيگا بائٽس",
    gram: "گرام",
    kilogram: "ڪلوگرام",
    litre: "ليٽر",
    pack: "پيڪ",
    pair: "جوڙو",
    pieces: "PIECES",
    plate: "پليٽ",
    tonne: "ٽون (MT)",
    uNIT: "يونٽ",
    yard: "يارڊ",
    pleaseEnterProductQuantity: "مھرباني ڪري پراڊڪٽ جو مقدار داخل ڪريو",
    productQuantity: "پيداوار جي مقدار",
    isThisProductTaxed: "productا ھن پراڊڪٽ تي ٽيڪس لل آھي؟",
    selectTaxType: "چونڊيو ٽيڪس جو قسم",
    pleaseSelectTaxType: "مھرباني ڪري چونڊيو ٽيڪس جو قسم",
    progressive: "ترقي پسند",
    proportional: "تناسب",
    pleaseEnterProductTaxRate: "مھرباني ڪري پراڊڪٽ ٽيڪس جي شرح داخل ڪريو",
    doesProductHaveVariants: "thisا ھن پراڊڪٽ ۾ مختلف قسم آھن؟",
    type: "قسم",
    value: "قدر",
    pleaseEnterVariantType: "مھرباني ڪري قسم جو قسم داخل ڪريو",
    pleaseEnterVariantValue: "مھرباني ڪري قسم جو قدر داخل ڪريو",
    pleaseEnterVariantPrice: "مھرباني ڪري قسم جي قيمت داخل ڪريو",
    pleaseEnterVariantQuantity: "مھرباني ڪري مختلف قسم جو مقدار داخل ڪريو",
    productDeletedSuccessfully: "پيداوار ڪاميابي سان ختم ڪئي وئي!",
    categoryDeletedSuccessfully: "قسم ھٽايو ويو ڪاميابي سان!",
    toDeleteThisProduct: "ھن پراڊڪٽ کي ختم ڪرڻ لاءِ",
    invalidProductQuantity: "غلط پراڊڪٽ مقدار",
    quantityAddedIsOverStock:
      "اھو مقدار جيڪو توھان شامل ڪري رھيا آھيو اھو اھو آھي جيڪو توھان وٽ آھي اسٽاڪ ۾.",
    itemInventoryNotTracked: "شيون انوینٽري ٽريڪ ٿيل ناھي",
    addBulkQuantity: "گھڻي مقدار شامل ڪريو",
    enterBulkQuantity: "گھڻي مقدار داخل ڪريو",
    pleaseEnterBulkQuantity: "مھرباني ڪري گھڻي مقدار داخل ڪريو",
    youveSuccessfullyAddedANewProduct:
      "توھان ڪاميابي سان شامل ڪيو آھي ھڪڙو نئون پراڊڪٽ!",
    pleaseEnterProductSellingPrice: "مھرباني ڪري پراڊڪٽ وڪڻڻ جي قيمت داخل ڪريو",
    doYouWantToTrackProductStock:
      "productا توھان پراڊڪٽ اسٽاڪ کي ٽريڪ ڪرڻ چاھيو ٿا؟",
    sellingPrice: "وڪرو قيمت",
    setProductExpiryReminder: "پروڊڪٽ جي ختم ٿيڻ جي ياد ڏياريندڙ سيٽ ڪريو",
    productExpiryDate: "مصنوعات جي ختم ٿيڻ جي تاريخ",
    startRemindingFrom: "کان ياد ڏيارڻ شروع ڪريو",
    productSuppliesAddedSuccessfully:
      "توھان شامل ڪيو آھي پيداوار جو سامان ڪاميابي سان.",
    addProductSupplies: "پيداوار جو سامان شامل ڪريو",
    pleaseSelectSupplier: "مھرباني ڪري سپلائر چونڊيو",
    nameOfProduct: "پيداوار جو نالو",
    pleaseSelectProduct: "مھرباني ڪري پراڊڪٽ چونڊيو",
    productVariant: "پراڊڪٽ جي ور",
    pleaseSelectAVariant: "مھرباني ڪري منتخب ڪريو ھڪڙو قسم",
    pleaseEnterUnitPrice: "مھرباني ڪري يونٽ جي قيمت داخل ڪريو",
    businessBranch: "ڪاروباري شاخ",
    pleaseSelectBranch: "مھرباني ڪري شاخ چونڊيو",
    youveSuccessfullyAddedANewSupplier:
      "توھان ڪاميابيءَ سان ھڪڙو نئون سپلائر شامل ڪيو آھي",
    addSupplier: "فراهم ڪندڙ شامل ڪريو",
    pleaseEnterSupplierEmail: "مھرباني ڪري سپلائر جو اي ميل داخل ڪريو",
    pleaseAddADescription: "مھرباني ڪري وضاحت شامل ڪريو",
    anErrorOccuredProductsDeleted:
      "آپريشن دوران ڪو مسئلو پيش آيو. مھرباني ڪري نوٽ وو ، ڪجھ پروڊڪٽس شايد ڊليٽ ٿي ويا آھن عمل ۾",
    bulkDelete: "گھڻو ختم ڪرڻ",
    youAreAboutToDelete: "توھان ختم ڪرڻ وارا آھيو",
    product: "پيداوار",
    isDueToRedeem: "حاصل ڪرڻ جي ڪري آهي",
    aReward: "هڪ انعام",
    pleaseSelectCustomerToReeemReward:
      "مھرباني ڪري ھڪڙو گراهڪ چونڊيو انعام حاصل ڪرڻ لاءِ.",
    youHaveNoLoyaltyProgramRunning:
      "توھان وٽ ڪوبه فعال وفاداري پروگرام ھلندڙ ناھي",
    customerHhasNoPointsInLoyaltyProgram:
      "ڪسٽمر وٽ ھن پوائنٽ ناھي وفاداري پروگرام ۾",
    proceedWithPayment: "ادائيگي سان اتي وو؟",
    youAreAboutToPayForTransactionUsingPoints:
      "توھان پوائنٽ استعمال ڪندي ٽرانزيڪشن جي ادائيگي ڪرڻ وارا آھيو.",
    customerHas: "ڪسٽمر آهي",
    worth: "قيمت",
    andIsNotSufficientToPayForTransaction:
      "۽ ھن ٽرانزيڪشن جي ادائيگي لاءِ ڪافي ناھي. theyا اھي شامل ڪرڻ چاھين ٿا بيلنس usingئي ادائيگي جو طريقو استعمال ڪندي.",
    addCustomerLoyalty: "ڪسٽمر وفاداري شامل ڪريو",
    pleaseAddCustomerFirst:
      "مھرباني ڪري شامل ڪريو يا پھريائين ھڪڙو ڪسٽمر چونڊيو.",
    pleaseWaitWhileWeGetReady:
      "مھرباني ڪري انتظار ڪريو ، جڏھن اسان تيار ٿي وون",
    lowStock: "گھٽ اسٽاڪ",
    pleaseEnterAmountTendered: "مھرباني ڪري ڏنل رقم داخل ڪريو",
    areYouSureToBookSaleOffline:
      "youا توهان پڪ آهيو ته هي سيل آف لائن بک ڪرڻ چاهيو ٿا؟",
    saleWouldBeBookedAutomatically:
      "فروخت پاڻمرادو بُڪ ٿي ويندي جڏھن توھان پنھنجو انٽرنيٽ آن ڪندا",
    offlineSalesBookingCancelled: "آف لائن سيلز بُڪنگ منسوخ",
    covid19Message:
      "COVID19: پنھنجا ھٿ صابڻ سان Washوئو يا صفائي ڪريو پکيڙ کي روڪڻ لاءِ. سدائين سلامت رهو",
    saleSuccessfullyRecorded: "وڪرو ڪاميابي سان رڪارڊ ٿيو!",
    sendReceiptToEmail: "موڪليو اي ميل ڏانهن رسيد",
    sendThankYouSms: "مهرباني ڪري ايس ايم ايس موڪليو",
    sendShippingDetails: "موڪليو شپنگ جا تفصيل",
    addLoyalty: "وفاداري شامل ڪريو",
    searchCustomerNameOrNumber: "customerوليو ڪسٽمر جو نالو يا نمبر",
    clickTheSearchCustomerBox: "ڪلڪ ڪريو ڪسٽمر باڪس ۽ اسڪين ڪارڊ وليو",
    enterProductPrice: "مصنوعات جي قيمت داخل ڪريو",
    enterPriceFor: "قيمت داخل ڪريو",
    searchForProduct: "پراڊڪٽ جي ولا ڪريو",
    all: "س",
    backToDashboard: "واپس ڊيش بورڊ ڏانھن",
    viewDraftSales: "مسودو وڪرو ڏسو",
    variantSelected: "منتخب ٿيل قسم",
    variant: "مختلف",
    thePreviousVariantSelectionNotAvailable:
      "پوئين قسم جي چونڊ دستياب ناھي نئين ورائيٽي لاءِ منتخب ٿيل قيمت جي بنياد تي ، مھرباني ڪري پنھنجو انتخاب تبديل ڪريو.",
    pleaseSupplyPositiveQuantityNumber: "مھرباني ڪري ھڪڙي مقدار جو نمبر ڏيو",
    lowStockFor: "گھٽ اسٽاڪ لاءِ",
    clearVariants: "وينجن صاف ڪريو",
    pleaseEnterQuantity: "مھرباني ڪري مقدار داخل ڪريو",
    picture: "تصوير",
    redemptionToken: "emڏڻ جو نشان",
    token: "ٽوڪن",
    totalSpent: "مڪمل خرچ",
    confirmPayment: "ادائيگي جي تصديق ڪريو",
    hasPaymentBeenMade: "Payا ادائيگي تي عمل ڪيو ويو ڪاميابي سان؟",
    enterTransactionReference:
      "داخل ڪريو ٽرانزيڪشن جو حوالو جنهن سان توهان ادا ڪيو",
    pleaseSelectACustomer: "مھرباني ڪري ھڪڙو ڪسٽمر چونڊيو!",
    areYouSureToApplyDiscount:
      "sureا توهان واقعي رعايت لا applyو ڪرڻ چاهيو ٿا؟",
    addYourBankAccountToEnableUssd:
      "شامل ڪريو پنھنجو بئنڪ اڪائونٽ uPay ذريعي فوري USSD منتقلي کي فعال ڪرڻ لاءِ",
    totalAmountToPay: "ادا ڪرڻ جي کل رقم",
    doYouWantToCancelTransaction:
      "youا توھان ھن ٽرانزيڪشن کي منسوخ ڪرڻ چاھيو ٿا؟",
    savePrintBill: "محفوظ ڪريو/پرنٽ ڪريو بل",
    enterAmountCollectedForCustomer: "ڪسٽمر لاءِ گڏ ڪيل رقم داخل ڪريو",
    recordSale: "رڪارڊ وڪرو",
    checkOutWith: "سان چيڪ ڪريو",
    instantTransfer: "فوري منتقلي",
    dialTheUSSDCode: "USSD ڪوڊ ڊائل ڪريو",
    pleaseSelectABank: "مھرباني ڪري ھڪ بينڪ چونڊيو",
    payWithUSSD: "ادا ڪيو يو ايس ايس ڊي سان",
    sendBillTo: " - ڏانهن بل موڪليو",
    waitingForUSSDTransfer: "يو ايس ايس ڊي منتقلي جو انتظار",
    percent: "سيڪڙو",
    applyDiscount: "رعايت لاو ڪريو",
    thisBillHasBeenSaved: "ھي بل محفوظ ڪيو ويو آھي",
    saveDraft: "مسودو محفوظ ڪريو",
    pleaseTypeANameToIdentifyCustomer:
      "مھرباني ڪري ھڪڙو نالو لکو ڪسٽمر جي س identifyاڻپ لاءِ",
    paymentDetails: "ادائيگي جا تفصيل",
    basePrice: "بنيادي قيمت",
    staff: "اسٽاف",
    subTotal: "ذيلي مجموعو",
    durationMonths: "مدت (مهينا)",
    selectADuration: "مدت چونڊيو",
    oneMonth: "1 مهينو",
    twoMonths: "2 مهينا",
    threeMonths: "3 مهينا",
    sixMonths: "6 مهينا",
    twelveMonths: "12 مهينا",
    eighteenMonths: "18 مهينا",
    twentyFourMonths: "24 مهينا",
    twoMonthsFree: "(2 مهينا مفت)",
    threeMonthsFree: "(3 مهينا مفت)",
    fiveMonthsFree: "(5 مهينا مفت)",
    yourAccountHasBeen: "توهان جو اڪائونٽ ٿي ويو",
    renewed: "تجديد ٿيل",
    upgraded: "اپ گريڊ",
    successfully: "ڪاميابي سان",
    yourSubscription: "توھان جي سبسڪرپشن",
    youAreCurrentlyEnrolledOnA: "توھان ھن وقت داخل ٿيل آھيو a",
    pleaseChooseAPaymentOption: "مھرباني ڪري چونڊيو ادائيگي جو آپشن",
    planRenewal: "تجديد جو منصوبو",
    planUpgrade: "وا Planاري جو منصوبو",
    pleaseSelectDurationToPayFor:
      "مھرباني ڪري مدت چونڊيو جيڪو توھان ادا ڪرڻ چاھيو ٿا",
    staffAccounts: "اسٽاف اڪائونٽس",
    ecommerce: "اي ڪامرس",
    pleaseSelectAPlan: "مھرباني ڪري ھڪڙو منصوبو چونڊيو",
    includeAddons: "شامل ڪريو Add-ons",
    viewTransactions: "ٽرانزيڪشن ڏسو",
    customerHasNoCompletedTansactions:
      "ڪسٽمر وٽ ا completedا تائين مڪمل ٿيل معاملا ناھن",
    branch: "شاخ",
    enterNumberOfEcommerceBranches: "داخل ڪريو اي ڪامرس برانچز جو تعداد",
    enterNumberOfEcommerceBranchesToPay:
      "داخل ڪريو اي ڪامرس برانچز جو تعداد جنھن لاءِ توھان ادائيگي ڪرڻ چاھيو ٿا",
    ecommerceIntegration: "اي ڪامرس انضمام",
    enterNumberOfBranches: "شاخن جو تعداد داخل ڪريو",
    enterNumberOfAdditionalBranchesToPay:
      "داخل ڪريو اضافي شاخن جو تعداد جنھن لاءِ توھان ادائيگي ڪرڻ چاھيو ٿا",
    enterNumberOfStaffs: "اسٽاف جو تعداد داخل ڪريو",
    enterNumberOfStaffsToPayFor:
      "داخل ڪريو اسٽاف جو تعداد جنھن لاءِ توھان ادائيگي ڪرڻ چاھيو ٿا",
    discountApplies: "رعايت لاو ٿئي ٿي",
    starsOnThe: "تي ستارا",
    offer: "آ offer",
    get: "حاصل ڪريو",
    moreStarsToRedeem: "و starsيڪ ستارا حاصل ڪرڻ لاءِ",
    taxVat: "ٽيڪس (VAT)",
    callCashierToCompletePayment: "ادائيگي مڪمل ڪرڻ لاءِ ڪيشير کي ڪال ڪريو",
    receipt: "رسيد",
    dear: "پيارا",
    thankYouForYourOrderFindGoods:
      "توھان جي حڪم لاءِ مھرباني. مھرباني ڪري ھي following ڏنل سامان suppوليو مهيا ڪيو ويو ، جيئن اتفاق ڪيو ويو.",
    shippingNote: "شپنگ نوٽ",
    enterShippingNote: "شپنگ نوٽ داخل ڪريو",
    shippingAddress: "موڪلڻ جو پتو",
    enterShippingAddress: "شپنگ ايڊريس داخل ڪريو",
    wellDoneYouAreDueToRedeem: "بهترين! توھان کي ڏڻو آھي",
    toGetYourRewardNextVisit:
      "توھان جو انعام حاصل ڪرڻ لاءِ توھان جي ايندڙ دوري تي. توهان جي مهرباني",
    pointsOnThe: "تي پوائنٽون",
    morePointsToRedeem: "و moreيڪ پوائنٽون حاصل ڪرڻ لاءِ",
    showCode: "ڪوڊ ڏيکاريو",
    toGetYourRewardOnNextVisit:
      "توھان جو انعام حاصل ڪرڻ لاءِ توھان جي ايندڙ دوري تي. توهان جي مهرباني",
    earn: "ڪمايو",
    delivaryNote: "پهچائڻ وارو نوٽ",
    draftSales: "مسودو وڪرو",
    startDate: "شروعاتي تاريخ",
    endDate: "ختم ٿيڻ جي تاريخ",
    orders: "حڪم",
    checkout: "چيڪ آئوٽ ڪريو",
    noProductItem: "ڪوبه پراڊڪٽ آئٽم",
    selectProductImage: "منتخب ڪريو مصنوعات جي تصوير",
    selectCountry: "ملڪ چونڊيو",
    selectRegion: "منتخب ڪريو رياست/علائقو",
    printProductTag: "پرنٽ پراڊڪٽ ٽيگ",
    transactionReference: "ٽرانزيڪشن جو حوالو",
    Cashier: "ڪيشئر",
    Manager: "مئنيجر",
    Owner: "مالڪ",
    Admin: "ايڊمن",
    addPartners: "شريڪ شامل ڪريو",
    addNewLoyaltyPartner: "نئون لائلٽي پارٽنر شامل ڪريو",
    pleaseEnterCompanyName: "مھرباني ڪري ڪمپني جو نالو داخل ڪريو",
    companyName: "ڪمپني جو نالو",
    pleaseEnterCompanyRepName: "مھرباني ڪري ڪمپني جي نمائندي جو نالو داخل ڪريو",
    companyRepName: "ڪمپني جي نمائندي جو نالو",
    pleaseEnterCompanyRepEmail:
      "مهرباني ڪري ڪمپني جي نمائندي جي اي ميل داخل ڪريو",
    companyRepEmail: "ڪمپني جي نمائندي جو اي ميل",
    pleaseEnterCompanyRepPhone:
      "مھرباني ڪري ڪمپني جي نمائندي جو فون نمبر داخل ڪريو",
    companyRepPhone: "ڪمپني جي نمائندي جو فون نمبر",
    addReward: "انعام شامل ڪريو",
    pleaseEnterRewardName: "مهرباني ڪري انعام جو نالو داخل ڪريو",
    rewardName: "انعام جو نالو",
    rewardQuantity: "انعام جي مقدار",
    rewardDescription: "انعام جي وضاحت",
    rewardType: "انعام جو قسم",
    pleaseEnterRewardType: "مهرباني ڪري انعام جو قسم داخل ڪريو",
    pleaseEnterRewardQuantity: "مھرباني ڪري انعام جي مقدار داخل ڪريو",
    pleaseEnterRewardDescription: "مھرباني ڪري انعام جي تفصيل داخل ڪريو",
    fineDining: "چڱو کاڌو",
    luxuryFashion: "عيش فيشن",
    hotels: "هوٽلون",
    travel: "سفر",
    foodAndBeverage: "کاڌو ۽ مشروب",
    fashion: "فيشن",
    health: "صحت",
    furniture: "فرنيچر",
    entertainment: "وندر",
    automobile: "گاڏي",
    education: "تعليم",
    beautyAndSpa: "خوبصورتي ۽ سپا",
    staycation: "رهڻ",
    events: "واقعا",
    trips: "سفرون",
    oilAndGas: "تيل ۽ گئس",
    laundry: "ڌوٻي",
    partnerCategory: "ڀائيواري جو درجو",
    freeItem: "مفت شئي",
  },
  Slovenian: {
    cashier: "blagajnik",
    manager: "upravitelj",
    owner: "lastnik",
    online: "na spletu",
    offline: "brez povezave",
    changePassword: "Spremeni geslo",
    currentPasswordMessage: "Prosimo, vnesite svoje trenutno geslo",
    passwordMessage: "Prosimo vnesite svoje geslo",
    currentPassword: "trenutno geslo",
    password: "geslo",
    confirmPasswordMessage: "Prosimo, potrdite geslo!",
    confirmPassword: "potrdi geslo",
    sendViaEmail: "Pošlji po e -pošti",
    sendViaWhatsapp: "Pošlji prek WhatsApp",
    downloadPdf: "Prenesite PDF",
    paid: "plačano",
    unpaid: "neplačan",
    partial: "delno",
    closeInvoice: "Ali želite račun zapreti?",
    closeInvoiceConfirmation:
      "Računa morda ni mogoče shraniti. Ali želite zapreti?",
    yes: "da",
    no: "ne",
    invoice: "Račun",
    wasDeducted: "je bila odšteta",
    for: "za",
    item: "Artikel",
    addProduct: "Dodajte izdelek",
    paymentReference: "Referenca plačila",
    amountPaid: "Plačani znesek",
    discountAmount: "Znesek popusta",
    amountDue: "Znesek dolga",
    amount: "Znesek",
    dueDate: "Datum zapadlosti",
    paymentType: "Način plačila",
    card: "Kartica",
    cash: "Gotovina",
    bankTransfer: "Bančno nakazilo",
    paymentMessage: "Sporočilo o plačilu",
    description: "Opis",
    sendReceipt: "Pošlji potrdilo",
    delete: "Izbriši",
    save: "Shrani",
    resend: "Ponovno poslati",
    saveAndSend: "Shrani",
    invoiceSaved: "Račun shranjen!",
    selectPaymentMethod: "Izberite način plačila!",
    selectCustomer: "Prosimo, izberite stranko!",
    cartEmptyError:
      "Seznam košaric ne sme biti prazen, zaprite račun in artikel dodajte v košarico!",
    subscriptionExpired:
      "Vaša naročnina je potekla in vaš račun je zdaj omejen. Če želite obnoviti svoj račun, kliknite spodnji gumb",
    renew: "Obnoviti",
    holdOn: "Počakaj",
    customerBank: "Banka strank",
    cancel: "Prekliči",
    selectACustomer: "Izberite Stranka",
    invoiceSuccessfulPdfError:
      "Račun je bil uspešno ustvarjen, vendar ustvarjanje PDF -ja traja dlje kot običajno. Prosim, kmalu preverite.",
    downloadingInvoice: "Prenos računa",
    downloadingReceipt: "Prenos potrdila",
    whatsappReceiptError:
      "Pri pošiljanju potrdila prek WhatsAppa je prišlo do napake. Poskusite znova.",
    receiptToWhatsapp: "Potrdilo je poslano v WhatsApp",
    thankYouForPatronage: "Hvala za pokroviteljstvo",
    hereIsYourReceipt: "Tukaj je potrdilo o plačilu",
    errorSendingEmailReceipt:
      "Pri pošiljanju potrdila po e-pošti je prišlo do napake. Poskusite znova ali se obrnite na podporo",
    receiptSentToEmail: "Potrdilo je bilo poslano na e -poštni naslov stranke",
    invoiceSentToEmail: "Račun je bil poslan na elektronski naslov stranke",
    invoiceSuccessWhatsappError:
      "Račun je bil uspešno ustvarjen, pri pošiljanju v WhatsApp pa je prišlo do napake. Poskusite znova na seznamu računov",
    invoiceSuccessfulEmailError:
      "Račun je bil uspešno ustvarjen, pri pošiljanju po e-pošti pa je prišlo do napake. Poskusite znova s seznama računov",
    invoiceSentToWhatsapp: "Račun je bil poslan na WhatsApp",
    hello: "zdravo",
    pleaseDownloadInvoice: "Prosimo, prenesite račun",
    pleaseDownloadReceipt: "Prosimo, prenesite potrdilo",
    from: "od",
    email: "E-naslov",
    upgrade: "Nadgradnja",
    youAreOnFreePlan: "Ste na brezplačnem načrtu.",
    clickOn: "Kliknite na",
    yourPlanInFewSteps: " naročnino v nekaj hitrih korakih.",
    to: "do",
    yourSubscriptionHasExpired:
      "Vaša naročnina je potekla in vaš račun je zdaj omejen.",
    days: "dnevi",
    yourSubscriptionExpiresIn: "Naročnina na Loystar poteče čez",
    createAcount: "Ustvari račun",
    signIn: "Prijaviti se",
    continue: "Nadaljuj",
    enterOtp: "Vnesite PIN OTP",
    enterValidOtp: "Vnesite veljavno kodo PIN",
    pin: "PIN",
    tokenSentToMail: "Žeton je bil poslan na vaš e -poštni naslov",
    passwordResetSuccessful: "Ponastavitev gesla je bila uspešna",
    somethingWentWrong: "Nekaj je šlo narobe!",
    resetPassword: "Ponastavitev gesla",
    iHaveResetCode: "Imam kodo za ponastavitev gesla",
    pleaseEnterEmail: "Prosimo, vnesite svoj e -poštni naslov",
    aTokenWillBeSentToEmail: "Žeton bi bil poslan na vaš e -poštni naslov",
    enterEmail: "Vpišite svoj elektronski naslov",
    sendinYourToken: "Pošiljanje žetona ...",
    makeSureItMatchesPassword: "Poskrbite, da se ujema z vašim novim geslom",
    pleaseChooseNewPassword: "Izberite novo geslo",
    chooseNewPassword: "Izberite novo geslo",
    enterNewPassword: "Za potrditev vnesite novo geslo",
    enterTokenSent: "Vnesite žeton, ki vam je bil poslan po pošti",
    resetToken: "Ponastavi žeton",
    resettingPassword: "Ponastavitev gesla ...",
    signUp: "Prijavite se",
    adminSignInWithEmail:
      " Skrbnik se prijavi z e -pošto, osebje pa z uporabniškim imenom.",
    pleaseEnterEmailOrUsername:
      "Prosimo, vnesite svoj e -poštni naslov ali uporabniško ime",
    emailOrUsername: "E-poštni naslov ali uporabniško ime",
    pleaseEnterPassword: "Prosimo, vnesite geslo",
    createAnAccount: "Ustvari račun",
    forgotPassword: "Ste pozabili geslo?",
    enterPhoneNumber: "Vnesite telefonsko številko",
    personalData: "Osebni podatki",
    validateConfirmationCOde: "Potrdite potrditveno kodo",
    pleaseEnterFirstName: "Prosimo, vnesite svoje ime",
    pleaseEnterPhoneNumber: "Prosimo, vnesite svojo telefonsko številko",
    pleaseEnterLastName: "Prosimo, vnesite svoj priimek",
    pleaseEnterBusinessName: "Prosimo, vnesite ime svojega podjetja",
    firstName: "Ime",
    lastName: "Priimek",
    businessName: "Ime podjetja",
    previous: "Prejšnji",
    next: "Naslednji",
    pleaseSelectBusinessCategory: "Izberite kategorijo vašega podjetja",
    pleaseEnterValidEmail: "Prosimo, vnesite veljaven e -poštni naslov",
    pleaseEnterPostCode: "Prosimo, vnesite poštno številko",
    postCode: "Poštna številka",
    phoneNumberInUse: "Ta telefonska številka je že v uporabi!",
    emailInUse: "Ta e -poštni naslov je že v uporabi!",
    foodAndDrinks: "Hrana in pijača",
    salonAndBeauty: "Salon in lepota",
    fashionAndAccessories: "Moda in dodatki",
    gymAndFitness: "Telovadnica in fitnes",
    travelAndHotel: "Potovanja in hotel",
    homeAndGifts: "Dom in darila",
    washingAndCleaning: "Pranje in čiščenje",
    gadgetsAndElectronics: "Pripomočki in elektronika",
    groceries: "Trgovine z živili",
    others: "Drugi",
    submit: "Predloži",
    accountCreatedSuccessful: "Vaš račun je bil uspešno ustvarjen.",
    pleaseEnterAddress: "Prosimo, vnesite svoj naslov",
    addressLine1: "Naslovna vrstica 1",
    addressLine2: "Naslovna vrstica 2",
    choosePassword: "Izbrati geslo",
    passwordMustBe6Characters: "Geslo mora biti vsaj 6 znakov.",
    howDidYouHearLoystar: "Kako ste slišali za Loystar?",
    referralCode: "napotitvena koda",
    byClickingTheButton: " S klikom na spodnji gumb se strinjate z",
    termsAndSevice: "Pogoji",
    wereCreatingAccount: "Ustvarjamo vaš račun",
    proceed: "Nadaljuj",
    verificationCodeMustBe6: "Koda za preverjanje mora biti 6 -mestna",
    pleaseEnter6DigitCode: "Prosimo, vnesite 6 -mestno kodo",
    enterVerificationCode: "Vnesi potrditveno kodo",
    resendToken: "Znova pošlji žeton",
    verify: "Preverite",
    transactions: "Transakcije",
    todaySales: "Današnja prodaja",
    salesHistory: "Zgodovina prodaje",
    supplyHistory: "Zgodovina oskrbe",
    new: "Novo",
    invoices: "Računi",
    disbursements: "Izplačila",
    offlineSales: "Prodaja brez povezave",
    products: "Izdelki",
    customers: "Stranke",
    multiLevelLoyalty: "Zvestoba na več ravneh",
    loyaltyPrograms: "Programi zvestobe",
    members: "Člani",
    appStore: "Trgovina z aplikacijami",
    orderMenu: "Meni za naročila",
    settings: "Nastavitve",
    staffAndBranches: "Osebje in veje",
    myAccount: "Moj račun",
    switchToSellMode: "Preklopite v način prodaje",
    signOut: "Odjava",
    getFreeSubscription: "Pridobite brezplačno naročnino",
    onlyNumbersAllowed: "Dovoljene so samo številke",
    yourAccountMustBe10Digits: "številka vašega računa mora biti 10 -mestna",
    yourBvnMustBe11: "vaš BVN mora biti 11 -mestna številka",
    pleaseSelectBank: "Izberite svojo banko",
    selectYourBank: "Izberite svojo banko",
    enterBankAccountNumber: "Vnesite številko bančnega računa",
    enterBvnNumber: "Vnesite svoj BVN",
    connectBankAccount: "Povežite bančni račun",
    passwordResetSuccessfulAndSignOut:
      "Vaše geslo je bilo uspešno ponastavljeno. Če se želite znova prijaviti, kliknite spodnji gumb Odjava",
    enterCurrentPassword: "Vnesite trenutno geslo",
    pleaseEnterCurrentPassword: "Prosimo, vnesite trenutno geslo",
    phoneNumber: "Telefonska številka",
    sex: "Seks",
    dateOfBirth: "Datum rojstva",
    state: "Država",
    country: "Država",
    loyaltyId: "ID zvestobe",
    createdAt: "Ustvarjeno ob",
    noOfTransactionsMade: "Število opravljenih transakcij",
    yourDownloadWillStart: "Vaš prenos se bo začel kmalu",
    exportCustomers: "Izvoz strank",
    youhaveSuccessfullyToppedUp: "Uspešno ste dopolnili svoje SMS enote.",
    youNowHave: "Zdaj imate",
    smsUnits: "Enote SMS",
    enterNumberOfSmsUnits: "Vnesite število enot SMS, ki jih nameravate kupiti",
    pleaseEnterANumericValue: "Prosimo, vnesite številsko vrednost",
    pay: "Plačaj",
    accountEditedSuccessfully: "Vaš račun je bil uspešno urejen.",
    youAeCurrentlyOn: "Trenutno ste vključeni",
    plan: "Načrt",
    userData: "Uporabniški podatki",
    businessData: "POSLOVNI PODATKI",
    businessCategory: "Poslovna kategorija",
    pleaseSelectCurrency: "Izberite svojo valuto",
    selectYourCurrency: "Izberite svojo valuto",
    purchaseMoreSmsUnits: "Na spodnjem obrazcu kupite več enot SMS",
    youHave: "Imaš",
    atLeast4SmsUnitsRrequired:
      "Za preverjanje potrebujete vsaj 4 sms enote, napolnite jih!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Prosimo, preverite svoj bančni račun, da bo skladen z zahtevami 'Spoznaj svojo stranko' (KYC). To vam bo omogočilo zbiranje plačil prek USSD ali takojšnjega prenosa, prejemanje naročil strank in obdelavo poravnave transakcij. Velja samo za nigerijske trgovce. Za začetek pritisnite spodnji gumb.",
    reConnectBankAccount: "Znova povežite bančni račun",
    accountName: "Ime računa",
    accountNumber: "Številka računa",
    bankName: "Ime banke",
    verified: "Preverjeno",
    accountDetails: "podrobnosti računa",
    kycBankAccount: "KYC",
    createTier: "Ustvari raven",
    fileUploadSuccessful: "datoteka uspešno naložena",
    fileUploadFailed: "nalaganje datoteke ni uspelo",
    createLoyaltyProgram: "Ustvarite program zvestobe",
    createLoyalty: "Ustvarite zvestobo",
    name: "Ime",
    loyaltyArtwork: "Umetnost zvestobe",
    clickToUpload: "Kliknite za nalaganje",
    amountToPointsRatio: "Razmerje med zneski in točkami",
    points: "Točke",
    recommendedAmountToPointRatio:
      "Priporočeno: ₦ 1 do 1 točka. torej za vsako porabljeno 1 Nairo vaše stranke dobijo 1 točko",
    pleaseTypeIn: "Prosimo, vnesite",
    toDeleteLoyalty: "izbrisati to zvestobo",
    deleteLoyalty: "Izbriši zvestobo",
    toConfirm: "potrditi",
    edit: "Uredi",
    pointsAwardedSuccessfully: "Uspešno podeljene točke.",
    enterPointValueToAward:
      "Vnesite vrednost točke, ki jo želite nagraditi strankam",
    award: "Nagrada",
    awardPointValuesToCustomer: "Dodelite vrednost točke naročniku",
    enrollMembersToLoyalty: "Včlanite člane v zvestobo",
    awardPoints: "Nagradne točke",
    enroll: "Včlanite se",
    home: "Domov",
    loyalty: "Zvestoba",
    enrollCustomers: "Včlanite stranke",
    selected: "Izbrano",
    customer: "Stranka",
    loyaltyActivationSuccessful: "Aktiviranje zvestobe je uspešno.",
    loyaltyDeactivationSuccessful: "Deaktivacija zvestobe je uspešna.",
    viewTier: "Ogled stopnje",
    deactivate: "Deaktiviraj",
    activate: "Aktiviraj",
    actions: "Dejanja",
    nameOfLoyalty: "Ime zvestobe",
    loyaltyStatus: "Status zvestobe Status zvestobe",
    numberOfTiers: "Število stopenj",
    createdOn: "Ustvarjeno dne",
    createATier: "Ustvarite stopnjo",
    stopCreatingTierConfirmation: "Ali želite prenehati ustvarjati stopnjo?",
    stopEditingTierConfirmation: "Ali želite ustaviti urejanje te stopnje?",
    nameOfTier: "Ime stopnje",
    minimumSpendingTarget: "Cilj minimalne porabe",
    maximumSpendingTarget: "Cilj največje porabe",
    minimumSpendingTargetRequired: "zahtevan je minimalni cilj porabe",
    maximumSpendingTargetRequired: "potreben je najvišji cilj porabe",
    rewardSponsor: "Sponzor nagrad",
    pleaseChooseARewardSponsor: "Izberite sponzorja za nagrado",
    self: "Jaz",
    partner: "Partner",
    rewardPartner: "Nagradni partner",
    pleaseSelectRewardPartner: "Izberite svojega nagrajevalnega partnerja",
    rewards: "Nagrade",
    pleaseSelectAReward: "Izberite nagrado",
    instructionsOnRedeemingReward: "Navodila, kako naj kupec unovči nagrado",
    pleaseFillInThisField: "Prosimo, izpolnite to polje!",
    toDeleteThisTier: " za brisanje te stopnje",
    deleteTier: "Izbriši raven",
    viewMembers: "Ogled članov",
    membersEnrolled: "Vpisani člani",
    instruction: "Navodila",
    membersIn: "Člani v",
    availableTiersInLoyalty: "Razpoložljive stopnje v programu zvestobe",
    tiers: "Stopnje",
    totalTier: "SKUPAJ TIER",
    availableLoyaltyProgramme: "Razpoložljiv program zvestobe",
    totalLoyalties: "SKUPNA ZVEZNOST",
    memberDetails: "Podrobnosti o članih",
    nameOfCustomer: "Ime stranke",
    address: "Naslov",
    allEnrolledMembers: "Vsi vpisani člani",
    thisIsToWishYouHappyBirthday:
      "To je, da vam želim zelo vesel rojstni dan in uspešno življenje. Hvala za vse, kar si Loysterju. Vesela praznovanja!",
    inputAnOfferPlease: "Prosim, vnesite ponudbo",
    pleaseSelectTheInsertPoint:
      "Prosimo, izberite vstavitveno točko v sporočilu in znova kliknite",
    birthdayOfferAndMessage: "Rojstnodnevna ponudba in sporočilo",
    birthdayOffer: "Rojstnodnevna ponudba",
    birthdayMessage: "Sporočilo za rojstni dan",
    noOfferFound: "Ponudbe ni bilo mogoče najti",
    settingABirthdayOffer:
      "Določitev rojstnodnevne ponudbe strankam omogoča, da na svoj rojstni dan prejmejo to ponudbo prek SMS -a",
    createOffer: "Ustvari ponudbo",
    whatIsTheOffer: "Kakšna je ponudba?",
    editMessage: "Uredi sporočilo",
    insert: "Vstavi",
    theNameOfCustomerInserted: "Tu bo vstavljeno ime stranke",
    theBirtdayOfferInserted: "Tukaj bo vstavljena rojstnodnevna ponudba",
    youSuccessfullyAddedNewBranch: "Uspešno ste dodali novo podružnico!",
    addNewBranch: "Dodaj novo vejo",
    addBranch: "Dodaj vejo",
    additionalBranchWillIncur: "Dodatna podružnica bi nastala",
    perBranch: "na vejo",
    ecommerceBranchCosts: "Stroški podružnice e -trgovine",
    pleaseEnterBranchName: "Prosimo, vnesite ime podružnice",
    pleaseEnterBranchAddress1: "Prosimo, vnesite naslovno vrstico 1",
    enterBranchAddress1: "Vnesite naslovno vrstico podružnice 1",
    enterBranchAddress2: "Vnesite naslovno vrstico podružnice 1",
    pleaseEnterBranchAddress2: "Prosimo, vnesite naslovno vrstico 2",
    enterBranchName: "Vnesite ime podružnice",
    successfullyAddedStaff: "Uspešno ste dodali novo osebje!",
    addNewStaff: "Dodajte novo osebje",
    addStaff: "Dodajte osebje",
    additionalStaffWillIncur: "Dodatno osebje bi nastalo",
    perStaff: "na osebje.",
    pleaseEnterStaffEmail: "Vnesite e -poštni naslov osebja",
    pleaseEnterStaffUsername: "Prosimo, vnesite uporabniško ime osebja",
    pleaseEnterStaffPassword: "Prosimo, vnesite geslo osebja",
    pleaseSelectStaffRole: "Izberite vlogo osebja",
    selectStaffRole: "Izberite vlogo osebja",
    enterStaffEmail: "Vnesite e -poštni naslov osebja",
    enterStaffUsername: "Vnesite uporabniško ime osebja",
    enterStaffPassword: "Vnesite geslo osebja",
    spacesNotAllowedInUsername: "v uporabniškem imenu niso dovoljeni presledki",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Prosimo, izberite podjetje, če želite priključiti osebje",
    searchForBranchToAttachStaff: "Poiščite podružnico za priključitev osebja",
    username: "Uporabniško ime",
    role: "Vloga",
    areYouSureToDeleteThis: "Ali ste prepričani, da boste to izbrisali",
    branches: "Podružnice",
    upgradeYourPlan: "Nadgradite svoj načrt.",
    add: "DODAJ",
    addNew: "Dodaj novo",
    customerWithEmailAlreadyExists:
      "Stranka z e -pošto/telefonsko številko že obstaja!",
    successfullyAddedNewCustomer: "Uspešno ste dodali novo stranko!",
    addCustomer: "Dodajte stranko",
    pleaseEnterCustomerFirstName: "Prosimo, vnesite ime stranke",
    pleaseEnterCustomerLastName: "Prosimo, vnesite priimek stranke",
    pleaseEnterCustomerPhoneNumber:
      "Prosimo, vnesite telefonsko številko stranke",
    pleaseEnterCustomerEmail: "Prosimo, vnesite e -poštni naslov stranke",
    pleaseEnterCustomerAddressLine: "Prosimo, vnesite naslovno vrstico stranke",
    pleaseEnterCustomerOtherAddress: "Prosimo, vnesite drugi naslov stranke",
    pleaseSelectCustomerGender: "Izberite spol stranke",
    gender: "Spol",
    male: "Moški",
    female: "Ženska",
    bank: "Banka",
    selectBank: "Izberite Banka",
    stateOrRegionOrProvince: "Država/regija/pokrajina",
    customerNotes: "Opombe strank",
    sendSms: "Pošljite SMS",
    editCustomer: "Urejanje stranke",
    redeemReward: "Unovčite nagrado",
    issueLoyaltyCard: "Izdajte kartico zvestobe",
    deleteCustomer: "Izbrišite stranko",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Uspešno ste dodelili ID članstva zvestobe",
    noMembershipIdAvailable:
      "ID članov ni na voljo. Pišite nam na hello@loystar.co",
    sendEmail: "Pošlji sporočilo",
    membershipPoints: "Točke članstva",
    customerDetails: "Podrobnosti o stranki",
    close: "Zapri",
    loyaltyBalance: "Ravnotežje zvestobe",
    pointsBalance: "Stanje točk",
    starBalance: "Star Balance",
    requiredPoints: "Zahtevane točke",
    requiredStars: "Obvezne zvezde",
    reddemCode: "Unovči kodo",
    toDeleteThisCustomer: "izbrisati to stranko",
    customerHasBeenDeletedSuccessfully: "Stranka je bila uspešno izbrisana!",
    customerWithPhoneAlreadyExists: "Stranka s telefonsko številko že obstaja",
    customerWasSuccessfullyEdited: "Stranka je bila uspešno urejena",
    anErrorOccured: "Prišlo je do napake",
    phoneNumberIncludeCountry: "Telefonska številka (vključno s kodo države)",
    yourFileQueuedForUpload:
      "Vaša datoteka je v čakalni vrsti za nalaganje. Po nekaj sekundah osvežite stran.",
    thereWasAnErrorPerformingOperation:
      "Pri izvajanju operacije je prišlo do napake!",
    pleaseSelectFile: "Prosimo, izberite datoteko!",
    oopsOnlyCsvFilesAllowed:
      "Ups! Dovoljene so samo datoteke CSV. Naložite datoteko .csv.",
    fileShouldBeSmallerThan5Mb:
      "Datoteka mora biti manjša od 5 MB. Če imate večjo datoteko, pišite na support@loystar.co. Hvala vam",
    customerFirstNameIsRequired: "ime stranke je v vrstici obvezno",
    customerPhoneNumberIsRequired:
      "v vrstici je potrebna telefonska številka stranke",
    importCustomers: "Uvozi stranke",
    upload: "Naloži",
    clickIconToDownloadCsv:
      "Kliknite to ikono, če želite prenesti predlogo datoteke CSV.",
    getGoogleSheetVersion: "Pridobite različico Googlovega lista",
    clickOrDragFileToUpload:
      "Kliknite ali povlecite datoteko na to območje, da jo naložite",
    missingOrInvalidColumnHeader:
      "Manjkajoča ali neveljavna glava stolpca. Upoštevajte obliko predloge. Hvala vam.",
    youHaveImported: "Uvozili ste",
    youSuccessfullyRedeemedReward: "Uspešno ste unovčili svojo nagrado!",
    sixDigitCode: "Šestmestna koda",
    pleaseEnterCustomerRewardCode: "Prosimo, vnesite kodo nagrade stranke",
    enterRewardCode:
      "Vnesite kodo nagrade. (Koda nagrade je odvisna od velikih in malih črk)",
    selectLoyaltyProgram: "Izberite program zvestobe",
    stamps: "Žigi",
    smsUnitsLow: "Nizke enote SMS",
    pleaseTopUpSmsUnits: "Napolnite enote SMS",
    smsSuccessfullySentForDelivery: "SMS uspešno poslan za dostavo!",
    sendSmsTo: "Pošljite SMS na",
    allCustomers: "Vse stranke",
    unitsAvailable: "Na voljo enote",
    pleaseTypeInTheMessage: "Prosimo, vnesite sporočilo",
    message: "Sporočilo",
    charactersRemaining: "preostalih znakov",
    avoidUseOfSpecialCharacters:
      "Izogibajte se uporabi posebnih znakov in emojijev za ohranjanje enot SMS.",
    note: "Opomba",
    errorFetchingCustomersMultilevelDetail:
      "Napaka pri pridobivanju podrobnosti o več ravneh strank",
    search: "Iskanje",
    reset: "Ponastaviti",
    importCustomer: "Uvozi stranko",
    addNewCustomer: "Dodajte novo stranko",
    sendSmsBroadcast: "Pošlji oddajanje SMS",
    totalCustomers: "SKUPAJ kupcev",
    disbursementDetails: "Podrobnosti o izplačilu",
    paidBy: "Plačal avtor",
    disbursed: "Izplačano",
    bankAccountName: "Ime bančnega računa",
    bankAccountNumber: "Številka bančnega računa",
    transferInitiated: "Začetek prenosa",
    transferCompleted: "Prenos zaključen",
    pleaseEnterAValid: "Vnesite veljavno",
    begin: "začeti",
    end: "konec",
    date: "datum",
    paymentDate: "Dan plačila",
    selectDisbursementDuration: "Izberite Trajanje izplačila",
    totalSettled: "Poravnano skupaj",
    totalUnsettled: "Skupaj nerešeno",
    toDeleteThisSale: "izbrisati to prodajo",
    draftSaleDeletedSuccessfully: "Osnutek razprodaje je bil uspešno izbrisan!",
    deleteSale: "Izbriši razprodajo",
    pleaseTypeInYourTotal: "Za potrditev izbrisa vnesite skupno število",
    billDetails: "Podrobnosti o računu",
    printBill: "Natisni Bill",
    servedBy: "Postregel",
    total: "Skupaj",
    createdDate: "Datum ustvarjanja",
    createdTime: "Ustvarjen čas",
    status: "Stanje",
    loginSuccessful: "Prijava uspešna",
    pleaseWaitWhileWeConnectAccount: "Počakajte, da povežemo vaš račun",
    connectionFailedTryAgain: "Povezava ni uspela. Prosim poskusite ponovno.",
    connectionSuccessful: "Povezava uspešna",
    viewDetails: "Ogled podrobnosti",
    enable: "Omogoči",
    free: "prost",
    cost: "Stroški",
    visitWebsite: "Obiščite spletno stran",
    pleaseUpgradeYourPlanToPro:
      "Če želite omogočiti to aplikacijo, nadgradite svoj načrt na Pro Plus",
    connectYourBankAccount:
      "Če želite prejemati plačila, povežite svoj bančni račun.",
    disable: "Onemogoči",
    enableApp: "Omogoči aplikacijo",
    addNewProductToInvoice: "Računu dodajte nov izdelek",
    toDeleteThisInvoice: "izbrisati ta račun",
    invoiceDeletedSuccessfully: "Račun je bil uspešno izbrisan!",
    deleteInvoice: "Izbriši račun",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Uspešno ste dodali sporočilo o plačilu računa!",
    pleaseEnterPaymentInstructions: "Prosimo, vnesite navodila za plačilo",
    invoiceId: "ID računa",
    paidDate: "Datum plačila",
    reference: "Referenca",
    productAdded: "Izdelek dodan",
    productOutOfStock: "Izdelek ni na zalogi. Prosimo, da zaloge ponovno.",
    totalInvoices: "SKUPAJ RAČUNI",
    totalPaidInvoices: "SKUPAJ PLAČENI RAČUNI",
    totalUnpaidInvoices: "SKUPAJ NEPLAČENI RAČUNI",
    loyaltyProgramDeleted: "Program zvestobe izbrisan",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ta program zvestobe je bil uspešno izbrisan",
    thisLoyaltyProgramEditedSuccessfully:
      "Ta program zvestobe je bil uspešno urejen",
    loyaltyProgramAddedSuccessfully: "Program zvestobe je bil uspešno dodan",
    loyaltyProgramEdited: "Program zvestobe urejen",
    loyaltyProgramAdded: "Dodan program zvestobe",
    loyaltyDetails: "Podrobnosti zvestobe",
    doYouWantToCloseDialog: "Ali želite zapreti to pogovorno okno?",
    pleaseEnterLoyaltyName: "Prosimo, vnesite ime svoje zvestobe",
    programType: "Vrsta programa",
    pleaseSelectProgramType: "Izberite vrsto programa",
    simplePoints: "Preproste točke",
    stampsProgram: "Program žigov",
    threshold: "Prag",
    pleaseEnterLoyaltyThreshold: "Vnesite prag zvestobe",
    reward: "Nagrada",
    pleaseEnterLoyaltyReward: "Prosimo, vnesite nagrado zvestobe",
    totalUserPoints: "Skupno število uporabniških točk",
    totalUserStamps: "Skupni uporabniški žig",
    spendingTarget: "Cilj porabe",
    spendingTargetHint1:
      "Cilj porabe je, koliko mora stranka porabiti, da si prisluži nagrado. 1 vrednost valute = 1 točka.",
    spendingTargetHint2:
      "Cilj žigov je, koliko žigov mora kupec zbrati, da si prisluži nagrado. npr. 5",
    addNewLoyaltyProgram: "Dodajte nov program zvestobe",
    addLoyaltyProgram: "Dodajte program zvestobe",
    loyaltyProgramType: "Vrsta programa zvestobe",
    pleaseSelectLoyaltyProgramType: "Izberite vrsto programa zvestobe",
    nameOfProgram: "Ime programa",
    pleaseEnterProgramName: "Prosimo, vnesite ime programa",
    whatIsTheReward: "Kakšna je nagrada?",
    egNextPurchaseIsFree: "Npr. Naslednji nakup je brezplačen",
    customerName: "Ime stranke",
    guestCustomer: "Gost Kupec",
    orderConfirmedSuccessfully: "Naročilo je bilo uspešno potrjeno",
    orderCancelledSuccessfully: "Naročilo je bilo uspešno preklicano",
    confirmOrder: "Potrdite naročilo",
    cancelOrder: "Prekliči naročilo",
    allOrders: "Vsa naročila",
    totalOrders: "SKUPAJ NAROČILA",
    doYouWantToAcceptOrder: "Ali želite sprejeti to naročilo?",
    doYouWantToCancelOrder: "Ali želite preklicati to naročilo?",
    orderDetails: "podrobnosti naročila",
    orderCreatedAt: "Naročilo ustvarjeno ob",
    supplier: "Dobavitelj",
    productName: "ime izdelka",
    quantity: "Količina",
    unitPrice: "Cena na enoto",
    receivedBy: "Prejeto od",
    reportFrom: "Poročilo od",
    totalSupplies: "Skupne zaloge",
    allRightsReserved: "Vse pravice pridržane",
    toDeleteThisTransaction: "izbrisati to transakcijo",
    transactionDeletedSuccessfully:
      "Transakcija je bila uspešno izbrisana. Zaloge so vrnjene v zalogo.",
    deleteTransaction: "Izbriši transakcijo",
    transactionDetails: "Podrobnosti o transakciji",
    printReceipt: "Natisni potrdilo",
    channel: "Kanal",
    discount: "Popust",
    profit: "Dobiček",
    discountedSales: "Prodaja s popustom",
    errorFetchingRecord: "Napaka pri pridobivanju zapisa",
    exportTransactions: "Izvozne transakcije",
    errorFetchingSalesRecord:
      "Napaka pri pridobivanju prodajnega zapisa za izvoz.",
    totalSellingPrice: "Skupna prodajna cena",
    totalCostPrice: "Skupna cena",
    appliedDiscount: "Uporabljen popust",
    noOfItems: "Št. Predmetov",
    sales: "Prodaja",
    export: "Izvozi",
    totalProfit: "Skupni dobiček",
    totalBalanceInPeriod: "Skupno stanje v obdobju",
    allTimeSales: "Ves čas prodaja",
    records: "Zapisi",
    todaysSales: "Današnja prodaja",
    transaction: "transakcijo",
    youHaveExceededTotalNumberOfProducts:
      "Presegli ste skupno število izdelkov, dovoljenih v vašem načrtu. Nadgradite svoj načrt in uživajte v višji meji.",
    youNeedToHaveLoyaltyProgram:
      "Za uporabo te funkcije morate imeti program zvestobe!",
    price: "Cena",
    category: "Kategorija",
    stockTracking: "Sledenje zalogam",
    stockCount: "Štetje zalog",
    taxed: "Obdavčeno",
    originalPrice: "Prvotna cena",
    costPrice: "Nabavna cena",
    unit: "Enota",
    productImage: "Slika izdelka",
    taxRate: "Davčna stopnja",
    taxType: "Vrsta davka",
    trackInventory: "Sledite inventarju",
    variants: "Variante",
    hasVariants: "Ima variante",
    importProduct: "Uvoz izdelka",
    exportProducts: "Izvozni izdelki",
    addNewProduct: "Dodajte nov izdelek",
    viewCategory: "Ogled kategorije",
    viewSuppliers: "Oglejte si dobavitelje",
    receiveInventory: "Prejemanje zalog",
    printAllProductsTag: "Natisni oznako vseh izdelkov",
    deleteAll: "Izbriši vse",
    totalProducts: "SKUPAJ IZDELKI",
    youveSuccessfullyAddedANewCategory: "Uspešno ste dodali novo kategorijo",
    addNewCategory: "Dodaj novo kategorijo",
    addCategory: "Dodaj kategorijo",
    categoryName: "Ime kategorije",
    pleaseEnterCategoryName: "Prosimo, vnesite ime kategorije",
    stampsTarget: "Žig Target",
    sendInventory: "Pošlji inventar",
    productDetails: "Podrobnosti produkta",
    youveSuccessfullyEditedThisCategory: "To kategorijo ste uspešno uredili",
    update: "Nadgradnja",
    categoryList: "Seznam kategorij",
    categories: "Kategorije",
    enterQuantityToUpdate: "Vnesite količino za posodobitev",
    inventorySentSuccessfully: "Popis je bil uspešno poslan!",
    updateInventory: "Posodobi zalogo",
    currentQuantity: "Trenutna količina",
    pleaseEnterQuantityToAdd: "Prosimo, vnesite količino, ki jo želite dodati",
    pleaseSelectABranch: "Prosimo, izberite podružnico",
    searchForBranch: "Poiščite podružnico",
    youCantSendMoreThanStock: "Ne morete poslati več, kot imate na zalogi",
    send: "Pošlji",
    pleaseEnterQuantityToSend:
      "Prosimo, vnesite količino, ki jo želite poslati",
    productNameIsRequiredOnRow: "ime izdelka je obvezno v vrstici",
    productCategoryIsRequiredOnRow: "kategorija izdelka je obvezna v vrstici",
    productPriceIsRequiredOnRow: "cena izdelka je obvezna v vrstici",
    productUnitIsRequiredOnRow: "enota izdelka je potrebna v vrsti",
    productQuantityIsRequiredOnRow: "v vrstici je potrebna količina izdelka",
    productVariantsRequireTracking: "različice izdelka zahtevajo sledenje!",
    pleaseAddVariantClickButton:
      "Prosimo, da dodate različico s klikom na gumb za dodajanje!",
    totalVariantsMoreThanSelectedQuantity:
      "Skupne variante so več kot izbrana količina izdelka, prosimo, zmanjšajte količino variante",
    productEditedSuccessfully: "Izdelek je bil uspešno urejen!",
    fileTooLargeLessThan4Mb:
      "Velikost datoteke je prevelika! Velikost datoteke mora biti manjša od 4 MB.",
    suchVariantAlreadyExist: "Takšna varianta že obstaja",
    addVariants: "Dodaj variante",
    editProduct: "Uredi izdelek",
    pleaseEnterProductName: "Prosimo, vnesite ime izdelka",
    pleaseEnterProductPrice: "Prosimo, vnesite ceno izdelka",
    pleaseEnterProductOriginalPrice: "Prosimo, vnesite prvotno ceno izdelka",
    productDescription: "Opis izdelka",
    selectProductCategory: "Izberite kategorijo izdelka",
    pleaseSelectProductCategory: "Izberite kategorijo izdelka",
    productCostPrice: "Nabavna cena izdelka",
    productSellingPrice: "Prodajna cena izdelka",
    productOriginalPrice: "Originalna cena izdelka",
    maxFileSizeAllowedIs4Mb: "Maks. Dovoljena velikost datoteke je 4 MB",
    productsWithPicturesArePublished:
      "Izdelki s slikami so objavljeni na Discoverju za prejemanje naročil",
    shouldThisProductBeTracked: "Ali je treba temu izdelku slediti?",
    pleaseSelectStockUnit: "Prosimo, izberite enoto zaloge",
    stockUnit: "Enota zaloge",
    bag: "VREČKA",
    bottle: "STEKLENICA",
    bunch: "GROM",
    can: "LAHKO",
    carton: "KARTON",
    crate: "ŠKATLA",
    cup: "KUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PAKIRANJE",
    pair: "PAR",
    pieces: "KOSI",
    plate: "PLOŠČA",
    tonne: "TONNE (MT)",
    uNIT: "ENOTA",
    yard: "DVORIŠČE",
    pleaseEnterProductQuantity: "Prosimo, vnesite količino izdelka",
    productQuantity: "Količina izdelka",
    isThisProductTaxed: "Ali je ta izdelek obdavčen?",
    selectTaxType: "Izberite vrsto davka",
    pleaseSelectTaxType: "Izberite vrsto davka",
    progressive: "Progresivno",
    proportional: "Sorazmerno",
    pleaseEnterProductTaxRate: "Vnesite stopnjo davka na izdelek",
    doesProductHaveVariants: "Ali ima ta izdelek različice?",
    type: "Vrsta",
    value: "Vrednost",
    pleaseEnterVariantType: "Vnesite vrsto variante",
    pleaseEnterVariantValue: "Vnesite vrednost variante",
    pleaseEnterVariantPrice: "Prosimo, vnesite ceno variante",
    pleaseEnterVariantQuantity: "Prosimo, vnesite količino variante",
    productDeletedSuccessfully: "Izdelek je bil uspešno izbrisan!",
    categoryDeletedSuccessfully: "Kategorija je bila uspešno izbrisana!",
    toDeleteThisProduct: "izbrisati ta izdelek",
    invalidProductQuantity: "Neveljavna količina izdelka",
    quantityAddedIsOverStock:
      "Količina, ki jo dodate, presega tisto, kar imate na zalogi.",
    itemInventoryNotTracked: "Popisu elementov se ne sledi",
    addBulkQuantity: "Dodaj količino v velikem obsegu",
    enterBulkQuantity: "Vnesite količino v velikem obsegu",
    pleaseEnterBulkQuantity: "Prosimo, vnesite količino v velikem obsegu",
    youveSuccessfullyAddedANewProduct: "Uspešno ste dodali nov izdelek!",
    pleaseEnterProductSellingPrice: "Prosimo, vnesite prodajno ceno izdelka",
    doYouWantToTrackProductStock: "Ali želite spremljati zaloge izdelkov?",
    sellingPrice: "Prodajna cena",
    setProductExpiryReminder: "Nastavite opomnik o poteku izdelka",
    productExpiryDate: "Rok uporabnosti izdelka",
    startRemindingFrom: "Začni opomniti od",
    productSuppliesAddedSuccessfully: "Uspešno ste dodali zaloge izdelkov.",
    addProductSupplies: "Dodajte zaloge izdelkov",
    pleaseSelectSupplier: "Prosimo, izberite dobavitelja",
    nameOfProduct: "Ime izdelka",
    pleaseSelectProduct: "Prosimo, izberite izdelek",
    productVariant: "Različica izdelka",
    pleaseSelectAVariant: "Prosimo, izberite varianto",
    pleaseEnterUnitPrice: "Prosimo, vnesite ceno na enoto",
    businessBranch: "Poslovna podružnica",
    pleaseSelectBranch: "Izberite podružnico",
    youveSuccessfullyAddedANewSupplier: "Uspešno ste dodali novega dobavitelja",
    addSupplier: "Dodajte dobavitelja",
    pleaseEnterSupplierEmail: "Prosimo, vnesite e -poštni naslov dobavitelja",
    pleaseAddADescription: "Dodajte opis",
    anErrorOccuredProductsDeleted:
      "Pri izvajanju operacije je prišlo do napake. Upoštevajte, da je bilo med postopkom morda izbrisanih nekaj izdelkov",
    bulkDelete: "Množično brisanje",
    youAreAboutToDelete: "Kmalu boste izbrisali",
    product: "Izdelek",
    isDueToRedeem: "je treba unovčiti",
    aReward: "nagrado",
    pleaseSelectCustomerToReeemReward:
      "Prosimo, izberite stranko, da unovčite nagrado.",
    youHaveNoLoyaltyProgramRunning: "Nimate aktivnega programa zvestobe",
    customerHhasNoPointsInLoyaltyProgram:
      "Stranka v tem programu zvestobe nima točk",
    proceedWithPayment: "Nadaljujte s plačilom?",
    youAreAboutToPayForTransactionUsingPoints:
      "Plačali boste transakcijo s točkami.",
    customerHas: "Stranka ima",
    worth: "vredno",
    andIsNotSufficientToPayForTransaction:
      "in ne zadostuje za plačilo te transakcije. Bi želeli dobroimetje dodati z drugim plačilnim sredstvom?",
    addCustomerLoyalty: "Dodajte zvestobo strank",
    pleaseAddCustomerFirst: "Najprej dodajte ali izberite stranko.",
    pleaseWaitWhileWeGetReady: "Počakajte, da se pripravimo",
    lowStock: "Majhna zaloga",
    pleaseEnterAmountTendered: "Prosimo, vnesite ponudbeni znesek",
    areYouSureToBookSaleOffline:
      "Ali ste prepričani, da želite to prodajo rezervirati brez povezave?",
    saleWouldBeBookedAutomatically:
      "Prodaja se samodejno knjiži, ko vklopite internet",
    offlineSalesBookingCancelled:
      "Rezervacija prodaje brez povezave je preklicana",
    covid19Message:
      "COVID19: Umijte si roke z milom ali razkužite, da preprečite širjenje. Vedno ostanite na varnem",
    saleSuccessfullyRecorded: "Prodaja uspešno posneta!",
    sendReceiptToEmail: "Pošljite potrdilo na e -pošto",
    sendThankYouSms: "Pošljite SMS z zahvalo",
    sendShippingDetails: "Pošljite podatke o pošiljanju",
    addLoyalty: "Dodajte zvestobo",
    searchCustomerNameOrNumber: "Poiščite ime ali številko stranke",
    clickTheSearchCustomerBox:
      "Kliknite polje za iskanje strank in kartico za optično branje",
    enterProductPrice: "Vnesite ceno izdelka",
    enterPriceFor: "Vnesite ceno za",
    searchForProduct: "Poiščite izdelek",
    all: "Vse",
    backToDashboard: "Nazaj na nadzorno ploščo",
    viewDraftSales: "Oglejte si osnutke prodaje",
    variantSelected: "izbrana varianta",
    variant: "varianta",
    thePreviousVariantSelectionNotAvailable:
      "Prejšnja izbira variante ni na voljo za novo različico, izbrano glede na ceno. Prosimo, spremenite svojo izbiro.",
    pleaseSupplyPositiveQuantityNumber:
      "Navedite pozitivno količinsko številko",
    lowStockFor: "Nizke zaloge za",
    clearVariants: "Jasne variante",
    pleaseEnterQuantity: "Prosimo, vnesite količino",
    picture: "Slika",
    redemptionToken: "Žeton za unovčenje",
    token: "Žeton",
    totalSpent: "Skupna poraba",
    confirmPayment: "Potrdite plačilo",
    hasPaymentBeenMade: "Ali je bilo plačilo uspešno obdelano?",
    enterTransactionReference:
      "Vnesite referenco transakcije, s katero ste plačali",
    pleaseSelectACustomer: "Prosimo, izberite stranko!",
    areYouSureToApplyDiscount:
      "Ali ste prepričani, da želite uporabiti popust?",
    addYourBankAccountToEnableUssd:
      "Dodajte svoj bančni račun, če želite omogočiti takojšen prenos USSD z uPayom",
    totalAmountToPay: "Skupni znesek za plačilo",
    doYouWantToCancelTransaction: "Ali želite preklicati to transakcijo?",
    savePrintBill: "Shrani/Natisni račun",
    enterAmountCollectedForCustomer: "Vnesite zbrani znesek za stranko",
    recordSale: "Rekordna prodaja",
    checkOutWith: "Preverite pri",
    instantTransfer: "Takojšnji prenos",
    dialTheUSSDCode: "Pokličite kodo USSD",
    pleaseSelectABank: "Izberite banko",
    payWithUSSD: "Plačajte z USSD",
    sendBillTo: " - Pošlji Billa na",
    waitingForUSSDTransfer: "Čakanje na prenos USSD",
    percent: "Odstotek",
    applyDiscount: "Uporabi popust",
    thisBillHasBeenSaved: "Ta račun je shranjen",
    saveDraft: "Shrani osnutek",
    pleaseTypeANameToIdentifyCustomer:
      "Prosimo, vnesite ime, da identificirate stranko",
    paymentDetails: "Podatki o plačilu",
    basePrice: "Osnovna cena",
    staff: "Osebje",
    subTotal: "Vmesni seštevek",
    durationMonths: "Trajanje (meseci)",
    selectADuration: "Izberite Trajanje",
    oneMonth: "1 mesec",
    twoMonths: "2 meseca",
    threeMonths: "3 mesece",
    sixMonths: "6 mesecev",
    twelveMonths: "12 mesecev",
    eighteenMonths: "18 mesecev",
    twentyFourMonths: "24 mesecev",
    twoMonthsFree: "(2 meseca brezplačno)",
    threeMonthsFree: "(3 mesece brezplačno)",
    fiveMonthsFree: "(5 mesecev brezplačno)",
    yourAccountHasBeen: "Vaš račun je bil",
    renewed: "obnovljeno",
    upgraded: "nadgradili",
    successfully: "uspešno",
    yourSubscription: "Vaša naročnina",
    youAreCurrentlyEnrolledOnA: "Trenutno ste vpisani na a",
    pleaseChooseAPaymentOption: "Prosimo, izberite možnost plačila",
    planRenewal: "Načrt prenove",
    planUpgrade: "Načrtujte nadgradnjo",
    pleaseSelectDurationToPayFor:
      "Izberite Trajanje, za katero nameravate plačati",
    staffAccounts: "Računi osebja",
    ecommerce: "E -trgovina",
    pleaseSelectAPlan: "Izberite načrt",
    includeAddons: "Vključite dodatke",
    viewTransactions: "Ogled transakcij",
    customerHasNoCompletedTansactions: "Stranka še nima dokončanih opravil",
    branch: "Podružnica",
    enterNumberOfEcommerceBranches: "Vnesite število podružnic e -trgovine",
    enterNumberOfEcommerceBranchesToPay:
      "Vnesite število podružnic e -trgovine, za katere nameravate plačati",
    ecommerceIntegration: "Integracija e -trgovine",
    enterNumberOfBranches: "Vnesite število vej",
    enterNumberOfAdditionalBranchesToPay:
      "Vnesite število dodatnih podružnic, za katere nameravate plačati",
    enterNumberOfStaffs: "Vnesite število zaposlenih",
    enterNumberOfStaffsToPayFor:
      "Vnesite število zaposlenih, ki jih nameravate plačati",
    discountApplies: "Velja popust",
    starsOnThe: "zvezde na",
    offer: "ponudbo",
    get: "Pridobite",
    moreStarsToRedeem: "več zvezd za odkup",
    taxVat: "Davek (DDV)",
    callCashierToCompletePayment: "Pokličite blagajnika, da dokončate plačilo",
    receipt: "Potrdilo",
    dear: "dragi",
    thankYouForYourOrderFindGoods:
      "Hvala za vaše naročilo. V skladu z dogovorom najdete naslednje dobavljeno blago.",
    shippingNote: "Opomba o pošiljanju",
    enterShippingNote: "Vnesite opombo o pošiljanju",
    shippingAddress: "Naslov za pošiljanje",
    enterShippingAddress: "Vnesite naslov za dostavo",
    wellDoneYouAreDueToRedeem: "Dobro opravljeno! Dolžni ste odkupiti",
    toGetYourRewardNextVisit:
      "da ob naslednjem obisku prejmete nagrado. Hvala vam",
    pointsOnThe: "Točke na",
    morePointsToRedeem: "več točk za unovčenje",
    showCode: "Pokaži kodo",
    toGetYourRewardOnNextVisit:
      "da ob naslednjem obisku prejmete nagrado. Hvala vam",
    earn: "Zaslužite",
    delivaryNote: "Dobavnica",
    draftSales: "Osnutek prodaje",
    startDate: "Začetni datum",
    endDate: "Končni datum",
    orders: "Naročila",
    checkout: "preveri",
    noProductItem: "Brez artikla izdelka",
    selectProductImage: "Izberite sliko izdelka",
    selectCountry: "Izberi državo",
    selectRegion: "Izberite državo/regijo",
    printProductTag: "Natisnite oznako izdelka",
    transactionReference: "Sklic na transakcijo",
    Cashier: "Blagajnik",
    Manager: "Upravitelj",
    Owner: "Lastnik",
    Admin: "Admin",
    addPartners: "Dodaj partnerje",
    addNewLoyaltyPartner: "Dodajte novega partnerja zvestobe",
    pleaseEnterCompanyName: "Vnesite ime podjetja",
    companyName: "ime podjetja",
    pleaseEnterCompanyRepName: "Vnesite ime predstavnika podjetja",
    companyRepName: "Ime zastopnika podjetja",
    pleaseEnterCompanyRepEmail: "Vnesite e-pošto predstavnika podjetja",
    companyRepEmail: "E-pošta predstavnika podjetja",
    pleaseEnterCompanyRepPhone:
      "Vnesite telefonsko številko predstavnika podjetja",
    companyRepPhone: "Telefonska številka predstavnika podjetja",
    addReward: "Dodajte nagrado",
    pleaseEnterRewardName: "Vnesite ime nagrade",
    rewardName: "Ime nagrade",
    rewardQuantity: "Količina nagrade",
    rewardDescription: "Opis nagrade",
    rewardType: "Vrsta nagrade",
    pleaseEnterRewardType: "Vnesite vrsto nagrade",
    pleaseEnterRewardQuantity: "Prosimo vnesite količino nagrade",
    pleaseEnterRewardDescription: "Prosimo vnesite opis nagrade",
    fineDining: "Fine Dining",
    luxuryFashion: "Luksuzna moda",
    hotels: "Hoteli",
    travel: "Potovanje",
    foodAndBeverage: "Hrano in pijačo",
    fashion: "Moda",
    health: "zdravje",
    furniture: "Pohištvo",
    entertainment: "Zabava",
    automobile: "Avtomobil",
    education: "Izobraževanje",
    beautyAndSpa: "Lepota in spa",
    staycation: "Staycation",
    events: "Dogodki",
    trips: "Izleti",
    oilAndGas: "Nafta in plin",
    laundry: "Pralnica",
    partnerCategory: "Kategorija partnerja",
    freeItem: "Brezplačni izdelek",
  },
  Slovak: {
    cashier: "pokladník",
    manager: "manažér",
    owner: "majiteľ",
    online: "online",
    offline: "offline",
    changePassword: "Zmeniť heslo",
    currentPasswordMessage: "Zadajte svoje aktuálne heslo",
    passwordMessage: "Prosím vložte heslo",
    currentPassword: "Aktuálne heslo",
    password: "heslo",
    confirmPasswordMessage: "Potvrďte prosím svoje heslo!",
    confirmPassword: "Potvrďte heslo",
    sendViaEmail: "Odoslať e -mailom",
    sendViaWhatsapp: "Odoslať prostredníctvom WhatsApp",
    downloadPdf: "Stiahnuť PDF",
    paid: "zaplatené",
    unpaid: "nezaplatené",
    partial: "čiastočné",
    closeInvoice: "Chcete faktúru uzavrieť?",
    closeInvoiceConfirmation: "Faktúra nemusí byť uložená. Chcete zavrieť?",
    yes: "Áno",
    no: "č",
    invoice: "Faktúra",
    wasDeducted: "bola odpočítaná",
    for: "pre",
    item: "Položka",
    addProduct: "Pridajte produkt",
    paymentReference: "Označenie platby",
    amountPaid: "Čiastka vyplatená",
    discountAmount: "Suma zľavy",
    amountDue: "Dlžná suma",
    amount: "Čiastka",
    dueDate: "Do dátumu",
    paymentType: "Typ platby",
    card: "Karta",
    cash: "Hotovosť",
    bankTransfer: "Bankový prevod",
    paymentMessage: "Platobná správa",
    description: "Popis",
    sendReceipt: "Odoslať potvrdenie",
    delete: "Vymazať",
    save: "Uložiť",
    resend: "Znovu odoslať",
    saveAndSend: "Uložiť",
    invoiceSaved: "Faktúra uložená!",
    selectPaymentMethod: "Vyberte spôsob platby!",
    selectCustomer: "Vyberte prosím zákazníka!",
    cartEmptyError:
      "Zoznam košíkov nemôže byť prázdny, zatvorte faktúru a vložte položku do košíka!",
    subscriptionExpired:
      "Platnosť predplatného vypršala a váš účet je teraz obmedzený. Účet obnovíte kliknutím na tlačidlo nižšie",
    renew: "Obnoviť",
    holdOn: "Počkaj",
    customerBank: "Zákaznícka banka",
    cancel: "Zrušiť",
    selectACustomer: "Vyberte položku Zákazník",
    invoiceSuccessfulPdfError:
      "Faktúra bola úspešne vytvorená, ale generovanie PDF trvá dlhšie ako obvykle. Skúste to neskôr.",
    downloadingInvoice: "Sťahovanie faktúry",
    downloadingReceipt: "Sťahovanie potvrdenky",
    whatsappReceiptError:
      "Pri odosielaní potvrdenia prostredníctvom WhatsApp sa vyskytla chyba. Skúste to znova.",
    receiptToWhatsapp: "Potvrdenie preposlané na WhatsApp",
    thankYouForPatronage: "Ďakujem za vašu priazeň",
    hereIsYourReceipt: "Tu je váš platobný doklad",
    errorSendingEmailReceipt:
      "Pri odosielaní potvrdenia prostredníctvom e-mailu sa vyskytla chyba. Skúste to znova alebo kontaktujte podporu",
    receiptSentToEmail: "Účtenka bola odoslaná na e -mail zákazníka",
    invoiceSentToEmail: "Faktúra bola odoslaná na e -mail zákazníka",
    invoiceSuccessWhatsappError:
      "Faktúra bola úspešne vytvorená, ale pri odosielaní na WhatsApp sa vyskytla chyba. Skúste to znova v zozname faktúr",
    invoiceSuccessfulEmailError:
      "Faktúra bola úspešne vytvorená, ale pri odosielaní ako e-mail sa vyskytla chyba. Skúste to znova zo zoznamu faktúr",
    invoiceSentToWhatsapp: "Faktúra preposlaná na WhatsApp",
    hello: "Ahoj",
    pleaseDownloadInvoice: "Stiahnite si faktúru",
    pleaseDownloadReceipt: "Stiahnite si potvrdenie",
    from: "od",
    email: "E -mail",
    upgrade: "Vylepšiť",
    youAreOnFreePlan: "Máte bezplatný plán.",
    clickOn: "Kliknite na",
    yourPlanInFewSteps: " predplatné v niekoľkých rýchlych krokoch.",
    to: "do",
    yourSubscriptionHasExpired:
      "Platnosť predplatného vypršala a váš účet je teraz obmedzený.",
    days: "dni",
    yourSubscriptionExpiresIn: "Vaše predplatné Loystar vyprší o",
    createAcount: "Vytvoriť účet",
    signIn: "Prihlásiť sa",
    continue: "ďalej",
    enterOtp: "Zadajte PIN OTP",
    enterValidOtp: "Zadajte platný PIN",
    pin: "PIN",
    tokenSentToMail: "Na váš e -mail bol odoslaný token",
    passwordResetSuccessful: "Obnovenie hesla bolo úspešné",
    somethingWentWrong: "Niečo sa pokazilo!",
    resetPassword: "Obnoviť heslo",
    iHaveResetCode: "Mám kód na obnovenie hesla",
    pleaseEnterEmail: "Zadajte svoj e -mail",
    aTokenWillBeSentToEmail: "Token by bol odoslaný na váš e -mail",
    enterEmail: "Zadajte svoj e-mail",
    sendinYourToken: "Odosielanie tokenu ...",
    makeSureItMatchesPassword: "Uistite sa, že sa zhoduje s vašim novým heslom",
    pleaseChooseNewPassword: "Vyberte nové heslo",
    chooseNewPassword: "Vyberte nové heslo",
    enterNewPassword: "Potvrďte zadaním nového hesla",
    enterTokenSent: "Zadajte token, ktorý vám bol odoslaný",
    resetToken: "Obnoviť token",
    resettingPassword: "Obnovuje sa heslo ...",
    signUp: "Prihlásiť Se",
    adminSignInWithEmail:
      " Správca sa prihlasuje e -mailom, zatiaľ čo zamestnanci sa prihlasujú pomocou používateľského mena.",
    pleaseEnterEmailOrUsername: "Zadajte svoj e -mail alebo užívateľské meno",
    emailOrUsername: "E-mail alebo užívateľské meno",
    pleaseEnterPassword: "Zadajte heslo",
    createAnAccount: "Vytvoriť účet",
    forgotPassword: "Zabudol si heslo?",
    enterPhoneNumber: "Zadajte telefónne číslo",
    personalData: "Osobné údaje",
    validateConfirmationCOde: "Overte potvrdzovací kód",
    pleaseEnterFirstName: "Zadajte svoje krstné meno",
    pleaseEnterPhoneNumber: "Zadajte svoje telefónne číslo",
    pleaseEnterLastName: "Zadajte svoje priezvisko",
    pleaseEnterBusinessName: "Zadajte názov svojej firmy",
    firstName: "Krstné meno",
    lastName: "Priezvisko",
    businessName: "Obchodné meno",
    previous: "Predchádzajúce",
    next: "Ďalšie",
    pleaseSelectBusinessCategory: "Vyberte kategóriu svojej firmy",
    pleaseEnterValidEmail: "Prosím zadajte platný e-mail",
    pleaseEnterPostCode: "Zadajte poštové smerovacie číslo",
    postCode: "PSČ",
    phoneNumberInUse: "Toto telefónne číslo sa už používa!",
    emailInUse: "Tento e -mail sa už používa!",
    foodAndDrinks: "Jedlo a pitie",
    salonAndBeauty: "Salón a krása",
    fashionAndAccessories: "Móda a doplnky",
    gymAndFitness: "Telocvičňa a fitnes",
    travelAndHotel: "Cestovanie a hotel",
    homeAndGifts: "Domov a darčeky",
    washingAndCleaning: "Pranie a čistenie",
    gadgetsAndElectronics: "Pomôcky a elektronika",
    groceries: "Potraviny",
    others: "Iní",
    submit: "Predložiť",
    accountCreatedSuccessful: "Váš účet bol úspešne vytvorený.",
    pleaseEnterAddress: "Zadajte svoju adresu",
    addressLine1: "Riadok adresy 1",
    addressLine2: "2. riadok adresy",
    choosePassword: "Zvoliť heslo",
    passwordMustBe6Characters: "Heslo musí mať najmenej 6 znakov.",
    howDidYouHearLoystar: "Ako ste sa dozvedeli o Loystar?",
    referralCode: "referenčný kód",
    byClickingTheButton: " Kliknutím na tlačidlo nižšie súhlasíte s",
    termsAndSevice: "Podmienky",
    wereCreatingAccount: "Vytvárame váš účet",
    proceed: "Pokračujte",
    verificationCodeMustBe6: "Overovací kód musí mať 6 číslic",
    pleaseEnter6DigitCode: "Zadajte 6 -miestny kód",
    enterVerificationCode: "Zadajte overovací kód",
    resendToken: "Znovu odoslať token",
    verify: "Overiť",
    transactions: "Transakcie",
    todaySales: "Dnešný výpredaj",
    salesHistory: "História predaja",
    supplyHistory: "História dodávok",
    new: "Nový",
    invoices: "Faktúry",
    disbursements: "Výplaty",
    offlineSales: "Offline predaj",
    products: "Produkty",
    customers: "Zákazníci",
    multiLevelLoyalty: "Viacúrovňová vernosť",
    loyaltyPrograms: "Vernostné programy",
    members: "Členovia",
    appStore: "Obchod s aplikaciami",
    orderMenu: "Menu objednávky",
    settings: "nastavenie",
    staffAndBranches: "Štáby a pobočky",
    myAccount: "Môj účet",
    switchToSellMode: "Prepnúť do režimu predaja",
    signOut: "Odhlásiť Sa",
    getFreeSubscription: "Získajte bezplatné predplatné",
    onlyNumbersAllowed: "Povolené sú iba čísla",
    yourAccountMustBe10Digits: "číslo vášho účtu musí byť 10 -miestne",
    yourBvnMustBe11: "vaša BVN musí byť 11 -miestne číslo",
    pleaseSelectBank: "Vyberte svoju banku",
    selectYourBank: "Vyberte svoju banku",
    enterBankAccountNumber: "Zadajte číslo bankového účtu",
    enterBvnNumber: "Zadajte svoje BVN",
    connectBankAccount: "Pripojiť bankový účet",
    passwordResetSuccessfulAndSignOut:
      "Vaše heslo bolo úspešne resetované. Kliknutím na tlačidlo Odhlásiť sa nižšie sa prihlásite znova",
    enterCurrentPassword: "Uveďte súčasné heslo",
    pleaseEnterCurrentPassword: "Zadajte aktuálne heslo",
    phoneNumber: "Telefónne číslo",
    sex: "Sex",
    dateOfBirth: "Dátum narodenia",
    state: "Štát",
    country: "Krajina",
    loyaltyId: "Vernostné ID",
    createdAt: "Vytvorené v",
    noOfTransactionsMade: "Počet uskutočnených transakcií",
    yourDownloadWillStart: "Vaše sťahovanie sa začne o chvíľu",
    exportCustomers: "Exportovať zákazníkov",
    youhaveSuccessfullyToppedUp: "Úspešne ste nabili svoje jednotky SMS.",
    youNowHave: "Teraz máte",
    smsUnits: "Jednotky SMS",
    enterNumberOfSmsUnits: "Zadajte počet jednotiek SMS, ktoré chcete kúpiť",
    pleaseEnterANumericValue: "Zadajte číselnú hodnotu",
    pay: "Zaplatiť",
    accountEditedSuccessfully: "Váš účet bol úspešne upravený.",
    youAeCurrentlyOn: "Aktuálne ste na",
    plan: "Plán",
    userData: "Použivateľské dáta",
    businessData: "OBCHODNÉ ÚDAJE",
    businessCategory: "Obchodná kategória",
    pleaseSelectCurrency: "Vyberte svoju menu",
    selectYourCurrency: "Vyberte svoju menu",
    purchaseMoreSmsUnits:
      "Kúpte si viac jednotiek SMS pomocou nižšie uvedeného formulára",
    youHave: "Máš",
    atLeast4SmsUnitsRrequired:
      "Na overenie sú potrebné najmenej 4 sms jednotky, doplňte!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Overte svoj bankový účet, aby bol v súlade s požiadavkami „Poznajte svojho zákazníka“ (KYC). To vám umožní zbierať platby prostredníctvom USSD alebo okamžitým prevodom, prijímať objednávky od zákazníkov a spracovávať transakcie. Platí iba pre nigérijských obchodníkov. Začnite stlačením nižšie uvedeného tlačidla.",
    reConnectBankAccount: "Znova pripojte bankový účet",
    accountName: "Názov účtu",
    accountNumber: "Číslo účtu",
    bankName: "Meno banky",
    verified: "Overené",
    accountDetails: "detaily účtu",
    kycBankAccount: "KYC",
    createTier: "Vytvoriť vrstvu",
    fileUploadSuccessful: "súbor bol úspešne nahraný",
    fileUploadFailed: "nahrávanie súboru zlyhalo",
    createLoyaltyProgram: "Vytvorte vernostný program",
    createLoyalty: "Vytvorte vernosť",
    name: "názov",
    loyaltyArtwork: "Vernostné umelecké dielo",
    clickToUpload: "Kliknutím nahrajte",
    amountToPointsRatio: "Pomer čiastok k bodom",
    points: "Body",
    recommendedAmountToPointRatio:
      "Odporúčané: ₦ 1 až 1 bod. t.j. za každú 1 minútu Nairy vaši zákazníci získajú 1 bod",
    pleaseTypeIn: "Prosím zadajte",
    toDeleteLoyalty: "odstrániť túto vernosť",
    deleteLoyalty: "Odstrániť vernosť",
    toConfirm: "na potvrdenie",
    edit: "Upraviť",
    pointsAwardedSuccessfully: "Body boli úspešne udelené.",
    enterPointValueToAward:
      "Zadajte bodovú hodnotu, ktorou by ste chceli zákazníkov oceniť",
    award: "Cena",
    awardPointValuesToCustomer: "Udelenie bodovej hodnoty pre zákazníka",
    enrollMembersToLoyalty: "Zaregistrujte členov do vernosti",
    awardPoints: "Body ocenenia",
    enroll: "Zaregistrujte sa",
    home: "Domov",
    loyalty: "Vernosť",
    enrollCustomers: "Zaregistrujte zákazníkov",
    selected: "Vybraný",
    customer: "Zákazník",
    loyaltyActivationSuccessful: "Aktivácia vernosti je úspešná.",
    loyaltyDeactivationSuccessful: "Deaktivácia vernosti je úspešná.",
    viewTier: "Zobraziť úroveň",
    deactivate: "Deaktivovať",
    activate: "Aktivovať",
    actions: "Akcie",
    nameOfLoyalty: "Meno vernosti",
    loyaltyStatus: "Vernostný stavVernostný stav",
    numberOfTiers: "Počet úrovní",
    createdOn: "Vytvorené na",
    createATier: "Vytvorte vrstvu",
    stopCreatingTierConfirmation: "Chcete prestať vytvárať vrstvu?",
    stopEditingTierConfirmation: "Chcete zastaviť úpravu tejto vrstvy?",
    nameOfTier: "Názov vrstvy",
    minimumSpendingTarget: "Minimálny cieľ výdavkov",
    maximumSpendingTarget: "Maximálny cieľ výdavkov",
    minimumSpendingTargetRequired: "je požadovaný cieľ minimálnych výdavkov",
    maximumSpendingTargetRequired: "je požadovaný maximálny cieľ výdavkov",
    rewardSponsor: "Sponzor odmien",
    pleaseChooseARewardSponsor: "Vyberte sponzora odmien",
    self: "Seba",
    partner: "Partner",
    rewardPartner: "Partner odmien",
    pleaseSelectRewardPartner: "Vyberte partnera pre odmeny",
    rewards: "Odmeny",
    pleaseSelectAReward: "Vyberte odmenu",
    instructionsOnRedeemingReward:
      "Pokyny, ako by mal zákazník uplatniť odmenu",
    pleaseFillInThisField: "Vyplňte prosím toto pole!",
    toDeleteThisTier: " odstrániť túto vrstvu",
    deleteTier: "Odstrániť vrstvu",
    viewMembers: "Zobraziť členov",
    membersEnrolled: "Členovia zapísaní",
    instruction: "Pokyn",
    membersIn: "Členovia v",
    availableTiersInLoyalty: "Dostupné vrstvy vo vernostnom programe",
    tiers: "Úrovne",
    totalTier: "CELKOM ÚROVEŇ",
    availableLoyaltyProgramme: "Dostupný vernostný program",
    totalLoyalties: "CELKOVÁ VERNOSŤ",
    memberDetails: "Podrobnosti o členovi",
    nameOfCustomer: "Meno zákazníka",
    address: "Adresa",
    allEnrolledMembers: "Všetci zapísaní členovia",
    thisIsToWishYouHappyBirthday:
      "Želám vám všetko najlepšie k narodeninám a prosperujúci život. Ďakujem za všetko, čo pre Loyster ste. Šťastné oslavy!",
    inputAnOfferPlease: "Zadajte prosím ponuku",
    pleaseSelectTheInsertPoint:
      "Vyberte bod vloženia do správy a kliknite znova",
    birthdayOfferAndMessage: "Narodeninová ponuka a správa",
    birthdayOffer: "Narodeninová ponuka",
    birthdayMessage: "Narodeninová správa",
    noOfferFound: "Nenašla sa žiadna ponuka",
    settingABirthdayOffer:
      "Nastavenie ponuky narodenín umožňuje zákazníkom túto ponuku dostať prostredníctvom SMS v deň ich narodenín",
    createOffer: "Vytvoriť ponuku",
    whatIsTheOffer: "Aká je ponuka",
    editMessage: "Upraviť správu",
    insert: "Vložiť",
    theNameOfCustomerInserted: "Sem bude vložené meno zákazníka",
    theBirtdayOfferInserted: "Sem bude vložená ponuka k narodeninám",
    youSuccessfullyAddedNewBranch: "Úspešne ste pridali novú pobočku!",
    addNewBranch: "Pridať novú pobočku",
    addBranch: "Pridať pobočku",
    additionalBranchWillIncur: "Vznikne dodatočná pobočka",
    perBranch: "na pobočku",
    ecommerceBranchCosts: "Náklady na pobočku elektronického obchodu",
    pleaseEnterBranchName: "Zadajte názov pobočky",
    pleaseEnterBranchAddress1: "Zadajte riadok adresy 1 pobočky",
    enterBranchAddress1: "Zadajte riadok s adresou pobočky 1",
    enterBranchAddress2: "Zadajte riadok s adresou pobočky 1",
    pleaseEnterBranchAddress2: "Zadajte riadok adresy 2 pobočky",
    enterBranchName: "Zadajte názov pobočky",
    successfullyAddedStaff: "Úspešne ste pridali nového personálu!",
    addNewStaff: "Pridajte nového zamestnanca",
    addStaff: "Pridajte personál",
    additionalStaffWillIncur: "Vznikol by ďalší personál",
    perStaff: "na personál.",
    pleaseEnterStaffEmail: "Zadajte e -mail zamestnanca",
    pleaseEnterStaffUsername: "Zadajte používateľské meno personálu",
    pleaseEnterStaffPassword: "Zadajte heslo personálu",
    pleaseSelectStaffRole: "Vyberte rolu zamestnanca",
    selectStaffRole: "Vyberte rolu zamestnanca",
    enterStaffEmail: "Zadajte e -mail zamestnanca",
    enterStaffUsername: "Zadajte používateľské meno zamestnanca",
    enterStaffPassword: "Zadajte heslo personálu",
    spacesNotAllowedInUsername: "v používateľskom mene nie sú povolené medzery",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Ak chcete pripojiť zamestnancov, vyberte firmu",
    searchForBranchToAttachStaff: "Vyhľadajte pobočku a pripojte zamestnancov",
    username: "Používateľské meno",
    role: "Úloha",
    areYouSureToDeleteThis: "Naozaj to chcete odstrániť?",
    branches: "Pobočky",
    upgradeYourPlan: "Upgradujte svoj plán.",
    add: "PRIDAŤ",
    addNew: "Pridať nové",
    customerWithEmailAlreadyExists:
      "Zákazník s e -mailom/telefónnym číslom už existuje!",
    successfullyAddedNewCustomer: "Úspešne ste pridali nového zákazníka!",
    addCustomer: "Pridajte zákazníka",
    pleaseEnterCustomerFirstName: "Zadajte krstné meno zákazníka",
    pleaseEnterCustomerLastName: "Zadajte priezvisko zákazníka",
    pleaseEnterCustomerPhoneNumber: "Zadajte telefónne číslo zákazníka",
    pleaseEnterCustomerEmail: "Zadajte e -mail zákazníka",
    pleaseEnterCustomerAddressLine: "Zadajte riadok adresy zákazníka",
    pleaseEnterCustomerOtherAddress: "Zadajte inú adresu zákazníka",
    pleaseSelectCustomerGender: "Vyberte pohlavie zákazníka",
    gender: "rod",
    male: "Muž",
    female: "Žena",
    bank: "breh",
    selectBank: "Vyberte Banka",
    stateOrRegionOrProvince: "Štát/región/provincia",
    customerNotes: "Poznámky pre zákazníkov",
    sendSms: "Poslať SMS",
    editCustomer: "Upraviť zákazníka",
    redeemReward: "Uplatniť odmenu",
    issueLoyaltyCard: "Vydať vernostnú kartu",
    deleteCustomer: "Odstrániť zákazníka",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Úspešne ste priradili ID vernostného členstva",
    noMembershipIdAvailable:
      "Nie sú k dispozícii žiadne členské čísla. Kontaktujte nás na hello@loystar.co",
    sendEmail: "Poslať email",
    membershipPoints: "Členské body",
    customerDetails: "Podrobnosti o zákazníkovi",
    close: "Zavrieť",
    loyaltyBalance: "Vernostný zostatok",
    pointsBalance: "Bodový zostatok",
    starBalance: "Hviezdna rovnováha",
    requiredPoints: "Požadované body",
    requiredStars: "Požadované hviezdičky",
    reddemCode: "Použiť kód",
    toDeleteThisCustomer: "odstrániť tohto zákazníka",
    customerHasBeenDeletedSuccessfully: "Zákazník bol úspešne odstránený!",
    customerWithPhoneAlreadyExists: "Zákazník s telefónnym číslom už existuje",
    customerWasSuccessfullyEdited: "Zákazník bol úspešne upravený",
    anErrorOccured: "Vyskytla sa chyba",
    phoneNumberIncludeCountry: "Telefónne číslo (vrátane kódu krajiny)",
    yourFileQueuedForUpload:
      "Váš súbor bol zaradený do poradia na odoslanie. Obnovte stránku po niekoľkých sekundách.",
    thereWasAnErrorPerformingOperation:
      "Pri vykonávaní operácie došlo k chybe!",
    pleaseSelectFile: "Vyberte súbor!",
    oopsOnlyCsvFilesAllowed:
      "Ojoj! Povolené sú iba súbory CSV. Odošlite súbor .csv.",
    fileShouldBeSmallerThan5Mb:
      "Súbor by mal byť menší ako 5 MB. Ak máte väčší súbor, pošlite nám e -mail na adresu support@loystar.co. Ďakujem",
    customerFirstNameIsRequired: "v riadku je požadované krstné meno zákazníka",
    customerPhoneNumberIsRequired:
      "v riadku je požadované telefónne číslo zákazníka",
    importCustomers: "Importovať zákazníkov",
    upload: "Odovzdať",
    clickIconToDownloadCsv:
      "Kliknutím na túto ikonu si stiahnete šablónu súboru CSV.",
    getGoogleSheetVersion: "Získať verziu listu Google",
    clickOrDragFileToUpload:
      "Nahrajte kliknutím alebo potiahnutím súboru do tejto oblasti",
    missingOrInvalidColumnHeader:
      "Chýbajúca alebo neplatná hlavička stĺpca. Dodržujte prosím formát šablóny. Ďakujem.",
    youHaveImported: "Importovali ste",
    youSuccessfullyRedeemedReward: "Úspešne ste uplatnili svoju odmenu!",
    sixDigitCode: "Šesťmiestny kód",
    pleaseEnterCustomerRewardCode: "Zadajte kód odmeny zákazníka",
    enterRewardCode:
      "Zadajte kód odmeny. (V kóde odmeny sa rozlišujú malé a veľké písmena)",
    selectLoyaltyProgram: "Vyberte vernostný program",
    stamps: "Pečiatky",
    smsUnitsLow: "Jednotky SMS nízke",
    pleaseTopUpSmsUnits: "Doplňte jednotky SMS",
    smsSuccessfullySentForDelivery: "SMS bola úspešne odoslaná na doručenie!",
    sendSmsTo: "Poslať SMS na",
    allCustomers: "Všetci zákazníci",
    unitsAvailable: "Dostupné jednotky",
    pleaseTypeInTheMessage: "Napíšte prosím do správy",
    message: "Správa",
    charactersRemaining: "zostávajúce znaky",
    avoidUseOfSpecialCharacters:
      "Vyhnite sa používaniu špeciálnych znakov a ikon emodži na šetrenie jednotiek SMS.",
    note: "Poznámka",
    errorFetchingCustomersMultilevelDetail:
      "Chyba pri načítaní podrobností viacúrovňových úrovní zákazníkov",
    search: "Vyhľadávanie",
    reset: "Resetovať",
    importCustomer: "Importovať zákazníka",
    addNewCustomer: "Pridajte nového zákazníka",
    sendSmsBroadcast: "Odosielanie SMS správ",
    totalCustomers: "CELKOVÝ ZÁKAZNÍCI",
    disbursementDetails: "Podrobnosti o vyplatení",
    paidBy: "Zaplatené",
    disbursed: "Vyplatené",
    bankAccountName: "Názov bankového účtu",
    bankAccountNumber: "Číslo bankového účtu",
    transferInitiated: "Začal sa prenos",
    transferCompleted: "Prenos je dokončený",
    pleaseEnterAValid: "Zadajte platné",
    begin: "začať",
    end: "koniec",
    date: "dátum",
    paymentDate: "Dátum platby",
    selectDisbursementDuration: "Vyberte Trvanie výplaty",
    totalSettled: "Celkom vysporiadané",
    totalUnsettled: "Celkom nevyrovnaný",
    toDeleteThisSale: "odstrániť tento predaj",
    draftSaleDeletedSuccessfully: "Koncept predaja bol úspešne odstránený!",
    deleteSale: "Odstrániť výpredaj",
    pleaseTypeInYourTotal: "Na potvrdenie vymazania zadajte celkovú sumu",
    billDetails: "Podrobnosti o účte",
    printBill: "Vytlačiť účet",
    servedBy: "Podáva",
    total: "Celkom",
    createdDate: "Dátum vytvorenia",
    createdTime: "Čas vytvorenia",
    status: "Postavenie",
    loginSuccessful: "Prihlásenie úspešné",
    pleaseWaitWhileWeConnectAccount: "Počkajte, kým prepojíme váš účet",
    connectionFailedTryAgain: "Pripojenie zlyhalo. Prosím skúste znova.",
    connectionSuccessful: "Pripojenie bolo úspešné",
    viewDetails: "Zobraziť podrobnosti",
    enable: "Povoliť",
    free: "zadarmo",
    cost: "Náklady",
    visitWebsite: "Navštíviť webovú stránku",
    pleaseUpgradeYourPlanToPro:
      "Ak chcete túto aplikáciu povoliť, aktualizujte svoj plán na Pro Plus",
    connectYourBankAccount:
      "Pripojte svoj bankový účet, aby ste mohli prijímať platby.",
    disable: "Zakázať",
    enableApp: "Povoliť aplikáciu",
    addNewProductToInvoice: "Pridajte nový produkt k faktúre",
    toDeleteThisInvoice: "na odstránenie tejto Faktúry",
    invoiceDeletedSuccessfully: "Faktúra bola úspešne odstránená!",
    deleteInvoice: "Odstrániť faktúru",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Úspešne ste pridali správu o platbe faktúry!",
    pleaseEnterPaymentInstructions: "Zadajte pokyny k platbe",
    invoiceId: "ID faktúry",
    paidDate: "Platený dátum",
    reference: "Referencie",
    productAdded: "Produkt pridaný",
    productOutOfStock: "Tovar nie je na sklade. Doplňte si prosím zásoby.",
    totalInvoices: "CELKOVÉ FAKTÚRY",
    totalPaidInvoices: "CELKOM PLATENÉ FAKTÚRY",
    totalUnpaidInvoices: "CELKOM NEPLATENÉ FAKTÚRY",
    loyaltyProgramDeleted: "Vernostný program vymazaný",
    thisLoyaltyProgramDeletedSuccessfully:
      "Tento vernostný program bol úspešne odstránený",
    thisLoyaltyProgramEditedSuccessfully:
      "Tento vernostný program bol úspešne upravený",
    loyaltyProgramAddedSuccessfully: "Vernostný program bol úspešne pridaný",
    loyaltyProgramEdited: "Vernostný program upravený",
    loyaltyProgramAdded: "Vernostný program pridaný",
    loyaltyDetails: "Vernostné detaily",
    doYouWantToCloseDialog: "Chcete zatvoriť toto dialógové okno?",
    pleaseEnterLoyaltyName: "Zadajte názov svojej vernosti",
    programType: "Typ programu",
    pleaseSelectProgramType: "Vyberte typ programu",
    simplePoints: "Jednoduché body",
    stampsProgram: "Program známok",
    threshold: "Prah",
    pleaseEnterLoyaltyThreshold: "Zadajte prah vernosti",
    reward: "Odmena",
    pleaseEnterLoyaltyReward: "Zadajte odmenu za vernosť",
    totalUserPoints: "Celkový počet používateľských bodov",
    totalUserStamps: "Celkový počet pečiatok používateľov",
    spendingTarget: "Výdavkový cieľ",
    spendingTargetHint1:
      "Cieľom výdavkov je, koľko musí zákazník minúť, aby získal odmenu. 1 hodnota meny = 1 bod.",
    spendingTargetHint2:
      "Cieľom známok je počet známok, ktoré musí zákazník nazbierať, aby získal odmenu. napr. 5",
    addNewLoyaltyProgram: "Pridajte nový vernostný program",
    addLoyaltyProgram: "Pridať vernostný program",
    loyaltyProgramType: "Typ vernostného programu",
    pleaseSelectLoyaltyProgramType: "Vyberte typ vernostného programu",
    nameOfProgram: "Názov programu",
    pleaseEnterProgramName: "Zadajte názov programu",
    whatIsTheReward: "Aká je odmena?",
    egNextPurchaseIsFree: "Napr. Ďalší nákup je zadarmo",
    customerName: "Meno zákazníka",
    guestCustomer: "Hosťujúci zákazník",
    orderConfirmedSuccessfully: "Objednávka bola úspešne potvrdená",
    orderCancelledSuccessfully: "Objednávka bola úspešne zrušená",
    confirmOrder: "Potvrdiť objednávku",
    cancelOrder: "Zrušiť objednávku",
    allOrders: "Všetky objednávky",
    totalOrders: "CELKOVÉ OBJEDNÁVKY",
    doYouWantToAcceptOrder: "Chcete prijať túto objednávku?",
    doYouWantToCancelOrder: "Chcete zrušiť túto objednávku?",
    orderDetails: "Detaily objednávky",
    orderCreatedAt: "Objednávka vytvorená o",
    supplier: "Dodávateľ",
    productName: "Meno Produktu",
    quantity: "Množstvo",
    unitPrice: "Jednotková cena",
    receivedBy: "Prijaté od",
    reportFrom: "Správa od",
    totalSupplies: "Celkové zásoby",
    allRightsReserved: "Všetky práva vyhradené",
    toDeleteThisTransaction: "na odstránenie tejto Transakcie",
    transactionDeletedSuccessfully:
      "Transakcia bola úspešne odstránená. Zásoby boli vrátené do zásoby.",
    deleteTransaction: "Odstrániť transakciu",
    transactionDetails: "detaily transakcie",
    printReceipt: "Vytlačiť potvrdenie",
    channel: "Kanál",
    discount: "Zľava",
    profit: "Zisk",
    discountedSales: "Zľavnené výpredaje",
    errorFetchingRecord: "Chyba pri načítaní záznamu",
    exportTransactions: "Exportné transakcie",
    errorFetchingSalesRecord: "Chyba pri načítaní záznamu o predaji na export.",
    totalSellingPrice: "Celková predajná cena",
    totalCostPrice: "Celková nákladová cena",
    appliedDiscount: "Uplatnená zľava",
    noOfItems: "Počet položiek",
    sales: "Predaj",
    export: "Export",
    totalProfit: "Celkový zisk",
    totalBalanceInPeriod: "Celkový zostatok za obdobie",
    allTimeSales: "Predaj na celý čas",
    records: "Záznamy",
    todaysSales: "Dnešný výpredaj",
    transaction: "transakciu",
    youHaveExceededTotalNumberOfProducts:
      "Prekročili ste celkový počet produktov povolených vo vašom pláne. Inovujte svoj plán a užívajte si vyšší limit.",
    youNeedToHaveLoyaltyProgram:
      "Na používanie tejto funkcie musíte mať vernostný program!",
    price: "cena",
    category: "Kategória",
    stockTracking: "Sledovanie skladových zásob",
    stockCount: "Počet akcií",
    taxed: "Zdanené",
    originalPrice: "Pôvodná cena",
    costPrice: "Nákladová cena",
    unit: "Jednotka",
    productImage: "Obrázok produktu",
    taxRate: "Sadzba dane",
    taxType: "Typ dane",
    trackInventory: "Sledovať inventár",
    variants: "Varianty",
    hasVariants: "Má varianty",
    importProduct: "Importovať produkt",
    exportProducts: "Exportovať výrobky",
    addNewProduct: "Pridať nový produkt",
    viewCategory: "Zobraziť kategóriu",
    viewSuppliers: "Zobraziť dodávateľov",
    receiveInventory: "Prijať inventár",
    printAllProductsTag: "Vytlačiť štítok všetkých produktov",
    deleteAll: "Vymazať všetko",
    totalProducts: "CELKOVÉ VÝROBKY",
    youveSuccessfullyAddedANewCategory: "Úspešne ste pridali novú kategóriu",
    addNewCategory: "Pridať novú kategóriu",
    addCategory: "Pridať kategóriu",
    categoryName: "meno kategórie",
    pleaseEnterCategoryName: "Zadajte názov kategórie",
    stampsTarget: "Cieľ pečiatok",
    sendInventory: "Poslať inventár",
    productDetails: "Detaily produktu",
    youveSuccessfullyEditedThisCategory: "Túto kategóriu ste úspešne upravili",
    update: "Aktualizácia",
    categoryList: "Zoznam kategórií",
    categories: "Kategórie",
    enterQuantityToUpdate: "Zadajte množstvo, ktoré chcete aktualizovať",
    inventorySentSuccessfully: "Inventár bol úspešne odoslaný!",
    updateInventory: "Aktualizovať inventár",
    currentQuantity: "Aktuálne množstvo",
    pleaseEnterQuantityToAdd: "Zadajte množstvo, ktoré chcete pridať",
    pleaseSelectABranch: "Vyberte pobočku",
    searchForBranch: "Vyhľadajte pobočku",
    youCantSendMoreThanStock: "Nemôžete poslať viac, ako máte na sklade",
    send: "Odoslať",
    pleaseEnterQuantityToSend: "Zadajte množstvo, ktoré chcete odoslať",
    productNameIsRequiredOnRow: "v riadku je požadovaný názov produktu",
    productCategoryIsRequiredOnRow: "v riadku je vyžadovaná kategória produktu",
    productPriceIsRequiredOnRow: "cena produktu je vyžadovaná v riadku",
    productUnitIsRequiredOnRow: "v riadku je potrebná jednotka produktu",
    productQuantityIsRequiredOnRow: "v riadku je požadované množstvo produktu",
    productVariantsRequireTracking: "varianty produktu vyžadujú sledovanie!",
    pleaseAddVariantClickButton:
      "Pridajte variant kliknutím na tlačidlo Pridať!",
    totalVariantsMoreThanSelectedQuantity:
      "Celkové varianty sú vyššie ako vybrané množstvo produktu, Pls zníži množstvo variantu",
    productEditedSuccessfully: "Produkt bol úspešne upravený!",
    fileTooLargeLessThan4Mb:
      "Veľkosť súboru je príliš veľká! Veľkosť súboru by mala byť menšia ako 4 MB.",
    suchVariantAlreadyExist: "Takýto variant už existuje",
    addVariants: "Pridajte varianty",
    editProduct: "Upraviť produkt",
    pleaseEnterProductName: "Zadajte názov produktu",
    pleaseEnterProductPrice: "Zadajte cenu produktu",
    pleaseEnterProductOriginalPrice: "Zadajte pôvodnú cenu produktu",
    productDescription: "Popis produktu",
    selectProductCategory: "Vyberte kategóriu produktu",
    pleaseSelectProductCategory: "Vyberte kategóriu produktu",
    productCostPrice: "Cena produktu",
    productSellingPrice: "Predajná cena produktu",
    productOriginalPrice: "Pôvodná cena produktu",
    maxFileSizeAllowedIs4Mb: "Max. Povolená veľkosť súboru je 4 MB",
    productsWithPicturesArePublished:
      "Produkty s obrázkami sú publikované na serveri Discover, aby mohli prijímať objednávky",
    shouldThisProductBeTracked: "Mal by byť tento produkt sledovaný?",
    pleaseSelectStockUnit: "Vyberte skladovú jednotku",
    stockUnit: "Skladová jednotka",
    bag: "TAŠKA",
    bottle: "FĽAŠA",
    bunch: "BUNCH",
    can: "MÔCŤ",
    carton: "KARTÓN",
    crate: "KRABIČKA",
    cup: "POHÁR",
    dozen: "DOZEN",
    gigabytes: "GIGABYTY",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "BALENIE",
    pair: "PÁR",
    pieces: "KUSY",
    plate: "DOSKA",
    tonne: "TONNE (MT)",
    uNIT: "JEDNOTKA",
    yard: "YARD",
    pleaseEnterProductQuantity: "Zadajte množstvo produktu",
    productQuantity: "Množstvo produktu",
    isThisProductTaxed: "Je tento výrobok zdaňovaný?",
    selectTaxType: "Vyberte typ dane",
    pleaseSelectTaxType: "Vyberte typ dane",
    progressive: "Progresívne",
    proportional: "Proporcionálne",
    pleaseEnterProductTaxRate: "Zadajte sadzbu dane z produktov",
    doesProductHaveVariants: "Má tento výrobok varianty?",
    type: "Napíšte",
    value: "Hodnota",
    pleaseEnterVariantType: "Zadajte typ variantu",
    pleaseEnterVariantValue: "Zadajte hodnotu variantu",
    pleaseEnterVariantPrice: "Zadajte cenu variantu",
    pleaseEnterVariantQuantity: "Zadajte množstvo variantu",
    productDeletedSuccessfully: "Produkt bol úspešne odstránený!",
    categoryDeletedSuccessfully: "Kategória bola úspešne odstránená!",
    toDeleteThisProduct: "na odstránenie tohto Produktu",
    invalidProductQuantity: "Neplatné množstvo produktu",
    quantityAddedIsOverStock:
      "Množstvo, ktoré pridávate, presahuje množstvo, ktoré máte na sklade.",
    itemInventoryNotTracked: "Inventár položiek nie je sledovaný",
    addBulkQuantity: "Pridajte hromadné množstvo",
    enterBulkQuantity: "Zadajte hromadné množstvo",
    pleaseEnterBulkQuantity: "Zadajte hromadné množstvo",
    youveSuccessfullyAddedANewProduct: "Úspešne ste pridali nový produkt!",
    pleaseEnterProductSellingPrice: "Zadajte predajnú cenu produktu",
    doYouWantToTrackProductStock: "Chcete sledovať skladové zásoby produktov?",
    sellingPrice: "Predajná cena",
    setProductExpiryReminder:
      "Nastaviť pripomenutie uplynutia platnosti produktu",
    productExpiryDate: "Dátum exspirácie produktu",
    startRemindingFrom: "Začnite pripomínať od",
    productSuppliesAddedSuccessfully:
      "Úspešne ste pridali spotrebný materiál k produktu.",
    addProductSupplies: "Pridajte spotrebný materiál",
    pleaseSelectSupplier: "Vyberte dodávateľa",
    nameOfProduct: "Názov výrobku",
    pleaseSelectProduct: "Vyberte produkt",
    productVariant: "Variant produktu",
    pleaseSelectAVariant: "Vyberte prosím variant",
    pleaseEnterUnitPrice: "Zadajte jednotkovú cenu",
    businessBranch: "Obchodná pobočka",
    pleaseSelectBranch: "Vyberte pobočku",
    youveSuccessfullyAddedANewSupplier: "Úspešne ste pridali nového dodávateľa",
    addSupplier: "Pridať dodávateľa",
    pleaseEnterSupplierEmail: "Zadajte e -mail dodávateľa",
    pleaseAddADescription: "Pridajte popis",
    anErrorOccuredProductsDeleted:
      "Pri vykonávaní operácie sa vyskytla chyba. Vezmite prosím na vedomie, že počas tohto procesu mohlo byť odstránených niekoľko produktov",
    bulkDelete: "Hromadné odstránenie",
    youAreAboutToDelete: "Chystáte sa odstrániť",
    product: "Výrobok",
    isDueToRedeem: "je splatné",
    aReward: "odmena",
    pleaseSelectCustomerToReeemReward:
      "Vyberte zákazníka, ktorý chce uplatniť odmenu.",
    youHaveNoLoyaltyProgramRunning:
      "Nemáte spustený žiadny aktívny vernostný program",
    customerHhasNoPointsInLoyaltyProgram:
      "Zákazník nemá v tomto vernostnom programe žiadne body",
    proceedWithPayment: "Pokračovať s platbou?",
    youAreAboutToPayForTransactionUsingPoints:
      "Chystáte sa zaplatiť za transakciu pomocou bodov.",
    customerHas: "Zákazník má",
    worth: "stojí za to",
    andIsNotSufficientToPayForTransaction:
      "a nie je dostačujúca na zaplatenie tejto transakcie. Chceli by pridať zostatok iným spôsobom platby?",
    addCustomerLoyalty: "Pridajte vernosť zákazníkov",
    pleaseAddCustomerFirst: "Najprv pridajte alebo vyberte zákazníka.",
    pleaseWaitWhileWeGetReady: "Počkajte, kým sa pripravíme",
    lowStock: "Nízke zásoby",
    pleaseEnterAmountTendered: "Zadajte ponúkanú čiastku",
    areYouSureToBookSaleOffline:
      "Naozaj chcete rezervovať tento predaj offline?",
    saleWouldBeBookedAutomatically:
      "Predaj by sa zaúčtoval automaticky, keď zapnete internet",
    offlineSalesBookingCancelled: "Offline rezervácia predaja zrušená",
    covid19Message:
      "COVID19: Aby ste zabránili šíreniu, umyte si ruky mydlom alebo dezinfikujte. Zostaňte vždy v bezpečí",
    saleSuccessfullyRecorded: "Predaj bol úspešne zaznamenaný!",
    sendReceiptToEmail: "Poslať potvrdenie na email",
    sendThankYouSms: "Pošlite ďakovnú SMS",
    sendShippingDetails: "Odoslať prepravné údaje",
    addLoyalty: "Pridajte vernosť",
    searchCustomerNameOrNumber: "Vyhľadajte meno alebo číslo zákazníka",
    clickTheSearchCustomerBox:
      "Kliknite na pole Hľadať u zákazníka a Skenovať kartu",
    enterProductPrice: "Zadajte cenu produktu",
    enterPriceFor: "Zadajte cenu pre",
    searchForProduct: "Vyhľadajte produkt",
    all: "Všetky",
    backToDashboard: "Späť na informačný panel",
    viewDraftSales: "Zobraziť koncept predaja",
    variantSelected: "zvolený variant",
    variant: "varianta",
    thePreviousVariantSelectionNotAvailable:
      "Predošlý výber variantov nie je k dispozícii pre nový variant vybraný na základe ceny. Zmeňte prosím svoj výber.",
    pleaseSupplyPositiveQuantityNumber: "Uveďte číslo postivného množstva",
    lowStockFor: "Nízke zásoby pre",
    clearVariants: "Jasné varianty",
    pleaseEnterQuantity: "Zadajte množstvo",
    picture: "Obrázok",
    redemptionToken: "Vykupiteľský token",
    token: "Token",
    totalSpent: "Celková útrata",
    confirmPayment: "Potvrdiť platbu",
    hasPaymentBeenMade: "Bola platba úspešne spracovaná?",
    enterTransactionReference:
      "Zadajte referenciu transakcie, ktorou ste zaplatili",
    pleaseSelectACustomer: "Vyberte zákazníka!",
    areYouSureToApplyDiscount: "Ste si istí, že chcete uplatniť zľavu?",
    addYourBankAccountToEnableUssd:
      "Pridajte svoj bankový účet a povoľte okamžitý prenos USSD pomocou uPay",
    totalAmountToPay: "Celková čiastka na zaplatenie",
    doYouWantToCancelTransaction: "Chcete túto transakciu zrušiť?",
    savePrintBill: "Uložiť/vytlačiť účet",
    enterAmountCollectedForCustomer: "Zadajte sumu vybranú pre zákazníka",
    recordSale: "Rekordný predaj",
    checkOutWith: "Pozrite sa s",
    instantTransfer: "Okamžitý prevod",
    dialTheUSSDCode: "Vytočte kód USSD",
    pleaseSelectABank: "Vyberte banku",
    payWithUSSD: "Plaťte pomocou USSD",
    sendBillTo: " - Pošlite Billa na",
    waitingForUSSDTransfer: "Čaká sa na prenos USSD",
    percent: "Percento",
    applyDiscount: "Uplatniť zľavu",
    thisBillHasBeenSaved: "Tento návrh zákona bol uložený",
    saveDraft: "Uložiť koncept",
    pleaseTypeANameToIdentifyCustomer:
      "Na identifikáciu zákazníka zadajte meno",
    paymentDetails: "Platobné údaje",
    basePrice: "Základná cena",
    staff: "Personál",
    subTotal: "Medzisúčet",
    durationMonths: "Trvanie (mesiace)",
    selectADuration: "Vyberte trvanie",
    oneMonth: "1 mesiac",
    twoMonths: "2 mesiace",
    threeMonths: "3 mesiace",
    sixMonths: "6 mesiacov",
    twelveMonths: "12 mesiacov",
    eighteenMonths: "18 mesiacov",
    twentyFourMonths: "24 mesiacov",
    twoMonthsFree: "(2 mesiace zadarmo)",
    threeMonthsFree: "(3 mesiace zadarmo)",
    fiveMonthsFree: "(5 mesiacov zadarmo)",
    yourAccountHasBeen: "Váš účet bol",
    renewed: "obnovené",
    upgraded: "upgradované",
    successfully: "úspešne",
    yourSubscription: "Vaše predplatné",
    youAreCurrentlyEnrolledOnA: "Aktuálne ste zapísaní na a",
    pleaseChooseAPaymentOption: "Vyberte možnosť platby",
    planRenewal: "Plánujte obnovu",
    planUpgrade: "Plánujte upgrade",
    pleaseSelectDurationToPayFor: "Vyberte trvanie, za ktoré chcete zaplatiť",
    staffAccounts: "Účty zamestnancov",
    ecommerce: "Elektronický obchod",
    pleaseSelectAPlan: "Vyberte plán",
    includeAddons: "Zahrnúť doplnky",
    viewTransactions: "Zobraziť transakcie",
    customerHasNoCompletedTansactions:
      "Zákazník ešte nedokončil žiadne transakcie",
    branch: "Pobočka",
    enterNumberOfEcommerceBranches:
      "Zadajte počet pobočiek elektronického obchodu",
    enterNumberOfEcommerceBranchesToPay:
      "Zadajte počet pobočiek elektronického obchodu, za ktoré chcete zaplatiť",
    ecommerceIntegration: "Integrácia elektronického obchodu",
    enterNumberOfBranches: "Zadajte počet pobočiek",
    enterNumberOfAdditionalBranchesToPay:
      "Zadajte počet ďalších pobočiek, za ktoré chcete zaplatiť",
    enterNumberOfStaffs: "Zadajte počet štábov",
    enterNumberOfStaffsToPayFor:
      "Zadajte počet zamestnancov, za ktorých chcete zaplatiť",
    discountApplies: "Platí zľava",
    starsOnThe: "hviezdy na",
    offer: "ponuka",
    get: "Dostať",
    moreStarsToRedeem: "viac hviezd na vykúpenie",
    taxVat: "Daň (DPH)",
    callCashierToCompletePayment: "Zavolajte pokladníkovi a dokončite platbu",
    receipt: "Príjmový doklad",
    dear: "drahá",
    thankYouForYourOrderFindGoods:
      "Ďakujeme za vašu objednávku. Nasledujúci dodaný tovar nájdete podľa dohody.",
    shippingNote: "Dodací list",
    enterShippingNote: "Zadajte dodací list",
    shippingAddress: "Dodacia adresa",
    enterShippingAddress: "Zadajte dodaciu adresu",
    wellDoneYouAreDueToRedeem: "Dobre! Ste kvôli vykúpeniu",
    toGetYourRewardNextVisit:
      "aby ste dostali odmenu pri ďalšej návšteve. Ďakujem",
    pointsOnThe: "Body na",
    morePointsToRedeem: "získať viac bodov",
    showCode: "Zobraziť kód",
    toGetYourRewardOnNextVisit:
      "aby ste dostali odmenu pri ďalšej návšteve. Ďakujem",
    earn: "Zarobiť",
    delivaryNote: "Doručenie",
    draftSales: "Návrh predaja",
    startDate: "Dátum začiatku",
    endDate: "Dátum ukončenia",
    orders: "Objednávky",
    checkout: "odhlásiť sa",
    noProductItem: "Žiadna položka produktu",
    selectProductImage: "Vyberte obrázok produktu",
    selectCountry: "Vyberte krajinu",
    selectRegion: "Vyberte štát/región",
    printProductTag: "Vytlačiť štítok produktu",
    transactionReference: "Referencia transakcie",
    Cashier: "Pokladník",
    Manager: "Manažér",
    Owner: "Majiteľ",
    Admin: "Admin",
    addPartners: "Pridať partnerov",
    addNewLoyaltyPartner: "Pridať nového vernostného partnera",
    pleaseEnterCompanyName: "Zadajte názov spoločnosti",
    companyName: "meno spoločnosti",
    pleaseEnterCompanyRepName: "Zadajte meno zástupcu spoločnosti",
    companyRepName: "Meno zástupcu spoločnosti",
    pleaseEnterCompanyRepEmail: "Zadajte e-mail zástupcu spoločnosti",
    companyRepEmail: "Email zástupcu spoločnosti",
    pleaseEnterCompanyRepPhone: "Zadajte telefónne číslo zástupcu spoločnosti",
    companyRepPhone: "Telefónne číslo zástupcu spoločnosti",
    addReward: "Pridajte odmenu",
    pleaseEnterRewardName: "Zadajte názov odmeny",
    rewardName: "Názov odmeny",
    rewardQuantity: "Množstvo odmeny",
    rewardDescription: "Popis odmeny",
    rewardType: "Typ odmeny",
    pleaseEnterRewardType: "Zadajte typ odmeny",
    pleaseEnterRewardQuantity: "Zadajte množstvo odmeny",
    pleaseEnterRewardDescription: "Zadajte popis odmeny",
    fineDining: "Dobré stravovanie",
    luxuryFashion: "Luxusná móda",
    hotels: "hotely",
    travel: "Cestovanie",
    foodAndBeverage: "Jedlá a nápoje",
    fashion: "Móda",
    health: "Zdravie",
    furniture: "Nábytok",
    entertainment: "Zábava",
    automobile: "Automobilový",
    education: "Vzdelávanie",
    beautyAndSpa: "Krása a kúpele",
    staycation: "Pobyt",
    events: "Diania",
    trips: "Výlety",
    oilAndGas: "Olej a benzín",
    laundry: "Práčovňa",
    partnerCategory: "Kategória partnera",
    freeItem: "Voľná položka",
  },
  Somali: {
    cashier: "khasnaji",
    manager: "maamule",
    owner: "milkiilaha",
    online: "online",
    offline: "qad la'aan",
    changePassword: "Badalida Lambarka sirta ah",
    currentPasswordMessage: "Fadlan geli eraygaaga sirta ah ee hadda",
    passwordMessage: "Fadlan geli eraygaaga sirta ah",
    currentPassword: "Magaca Sirta Hadda",
    password: "sirta",
    confirmPasswordMessage: "Fadlan xaqiiji eraygaaga sirta ah!",
    confirmPassword: "Xaqiiji Furaha",
    sendViaEmail: "U soo dir Via Email",
    sendViaWhatsapp: "U dir WhatsApp",
    downloadPdf: "Soo degso PDF",
    paid: "bixiyay",
    unpaid: "aan la bixin",
    partial: "qayb ahaan",
    closeInvoice: "Ma rabtaa inaad xirto Qaansheegta?",
    closeInvoiceConfirmation:
      "Qaansheegta lama kaydin karo. Ma rabtaa inaad xirto?",
    yes: "haa",
    no: "maya",
    invoice: "Qaansheegta",
    wasDeducted: "waa laga jaray",
    for: "loogu talagalay",
    item: "Shayga",
    addProduct: "Ku dar Alaab",
    paymentReference: "Tixraaca Lacag -bixinta",
    amountPaid: "Qaddarka La Bixiyay",
    discountAmount: "Qadarka dhimista",
    amountDue: "Xaddiga Lacagta",
    amount: "Lacagta",
    dueDate: "Taariikhda Dhameystirka",
    paymentType: "Nooca Bixinta",
    card: "Kaarka",
    cash: "Lacag caddaan ah",
    bankTransfer: "Wareejinta Bangiga",
    paymentMessage: "Farriinta Lacag -bixinta",
    description: "Sharaxaad",
    sendReceipt: "Soo dir Rasiid",
    delete: "Tirtir",
    save: "Badbaadi",
    resend: "Dib-U-Dir",
    saveAndSend: "Badbaadi",
    invoiceSaved: "Invoice ayaa la keydiyay!",
    selectPaymentMethod: "Fadlan dooro Habka Lacag -bixinta!",
    selectCustomer: "Fadlan dooro Macaamil!",
    cartEmptyError:
      "Liiska Gaari ma noqon karo mid madhan, xiro qaansheegta oo ku dar Sheyga gaadhigii!",
    subscriptionExpired:
      "Diiwaangelintaadii way dhacday oo koontadaadu hadda way xadidan tahay. Guji badhanka hoose si aad u cusboonaysiiso koontadaada",
    renew: "Cusboonaysii",
    holdOn: "Joogso",
    customerBank: "Bangiga Macmiilka",
    cancel: "Jooji",
    selectACustomer: "Xulo Macaamil",
    invoiceSuccessfulPdfError:
      "Qaansheegta si guul leh ayaa loo abuuray laakiin PDF Generation ayaa qaadanaya waqti ka dheer sidii caadiga ahayd. Fadlan mar dhow dib u hubi.",
    downloadingInvoice: "Dajinta qaansheegta",
    downloadingReceipt: "Soo dejinta Rasiidka",
    whatsappReceiptError:
      "Khalad ayaa dhacay soo dirista risiidhka WhatsApp, Fadlan isku day mar kale.",
    receiptToWhatsapp: "Rasiidka ayaa loo gudbiyay WhatsApp",
    thankYouForPatronage: "Waad ku mahadsan tahay kaalmayntaada",
    hereIsYourReceipt: "Waa kan risiidhka lacag bixinta",
    errorSendingEmailReceipt:
      "Cillad ayaa dhacday in lagu soo diro rasiidka emayl ahaan, fadlan isku day mar kale ama la xiriir taageerada",
    receiptSentToEmail: "Rasiidka waxaa loo diray emaylka macmiilka",
    invoiceSentToEmail: "Qaansheegta ayaa loo diray emaylka macmiilka",
    invoiceSuccessWhatsappError:
      "Qaansheegta si guul leh ayaa loo abuuray laakiin qalad ayaa dhacay u dirista WhatsApp. Isku day mar kale liiska qaansheegta",
    invoiceSuccessfulEmailError:
      "Qaansheegta si guul leh ayaa loo abuuray laakiin qalad ayaa dhacay markii loo dirayay emayl ahaan. Fadlan isku day mar kale liiska qaansheegta",
    invoiceSentToWhatsapp: "Invoice ayaa loo gudbiyay WhatsApp",
    hello: "Hello",
    pleaseDownloadInvoice: "Fadlan soo dejiso qaansheegta",
    pleaseDownloadReceipt: "Fadlan soo degso rasiidka",
    from: "laga bilaabo",
    email: "Iimayl",
    upgrade: "Hagaaji",
    youAreOnFreePlan: "Waxaad ku jirtaa Qorshe Bilaash ah.",
    clickOn: "Guji",
    yourPlanInFewSteps: " rukummadaada dhowr tallaabo oo degdeg ah.",
    to: "ku",
    yourSubscriptionHasExpired:
      "Diiwaangelintaadii way dhacday oo koontadaadu hadda way xadidan tahay.",
    days: "maalmo",
    yourSubscriptionExpiresIn: "Diiwaankaaga Loystar wuxuu dhacayaa",
    createAcount: "Samee Xisaab",
    signIn: "Gal",
    continue: "Sii wad",
    enterOtp: "Geli PIN OTP",
    enterValidOtp: "Fadlan geli PIN sax ah",
    pin: "PIN",
    tokenSentToMail: "Calaamad ayaa loo diray emailkaaga",
    passwordResetSuccessful:
      "Dib -u -dejinta ereyga sirta ah waa lagu guuleystay",
    somethingWentWrong: "Waxbaa qaldamay!",
    resetPassword: "Dib u celi Password",
    iHaveResetCode: "Waxaan haystaa furaha furaha sirta ah",
    pleaseEnterEmail: "Fadlan geli iimaylkaaga",
    aTokenWillBeSentToEmail: "Calaamad ayaa loo diri doonaa emaylkaaga",
    enterEmail: "Geli emailkaaga",
    sendinYourToken: "Diritaanka calaamaddaada ...",
    makeSureItMatchesPassword: "Hubi inay ku habboon tahay eraygaaga cusub",
    pleaseChooseNewPassword: "Fadlan dooro eray cusub",
    chooseNewPassword: "Dooro eray cusub",
    enterNewPassword: "Geli eraygaaga sirta ah si aad u xaqiijiso",
    enterTokenSent: "Geli calaamadda loo soo diray boostadaada",
    resetToken: "Dib u celi Token",
    resettingPassword: "Dib u habeynta Furahaaga sirta ...",
    signUp: "Saxiix",
    adminSignInWithEmail:
      " Maamulka wuxuu ku soo galayaa email halka shaqaaluhu ku galayo magaca isticmaalaha.",
    pleaseEnterEmailOrUsername:
      "Fadlan geli iimaylkaaga ama magacaaga adeegsadaha",
    emailOrUsername: "Email ama Username",
    pleaseEnterPassword: "Fadlan geli erayga sirta ah",
    createAnAccount: "Samee Xisaab",
    forgotPassword: "Ilowday Furaha?",
    enterPhoneNumber: "Gali Lambarka Taleefanka",
    personalData: "Xogta Shakhsiyeed",
    validateConfirmationCOde: "Ansixi Xeerka Xaqiijinta",
    pleaseEnterFirstName: "Fadlan qor magacaaga koowaad",
    pleaseEnterPhoneNumber: "Fadlan geli lambarka taleefankaaga",
    pleaseEnterLastName: "Fadlan qor magacaaga dambe",
    pleaseEnterBusinessName: "Fadlan qor magacaaga ganacsi",
    firstName: "Magaca hore",
    lastName: "Magaca dambe",
    businessName: "magaca ganacsiga",
    previous: "Hore",
    next: "Xiga",
    pleaseSelectBusinessCategory: "Fadlan dooro qaybta ganacsigaaga",
    pleaseEnterValidEmail: "Fadlan gali iimayl sax ah",
    pleaseEnterPostCode: "Fadlan geli koodhka boostada",
    postCode: "Koodhka Boostada",
    phoneNumberInUse: "Lambarka taleefankan ayaa hore loo isticmaalay!",
    emailInUse: "Iimaylkan hore ayaa loo adeegsaday!",
    foodAndDrinks: "Cuntada iyo Cabitaannada",
    salonAndBeauty: "Salon iyo Beauty",
    fashionAndAccessories: "Dharka iyo Qalabka",
    gymAndFitness: "Jimicsiga iyo Jimicsiga",
    travelAndHotel: "Safarka iyo Hoteelka",
    homeAndGifts: "Hoyga iyo Hadiyadaha",
    washingAndCleaning: "Dhaqidda iyo Nadiifinta",
    gadgetsAndElectronics: "Aaladaha iyo Elektiroonigga",
    groceries: "Raashinka",
    others: "Kuwo kale",
    submit: "Gudbi",
    accountCreatedSuccessful: "Koontadaada si guul leh ayaa loo abuuray",
    pleaseEnterAddress: "Fadlan Geli Cinwaankaaga",
    addressLine1: "Khadka Cinwaanka 1",
    addressLine2: "Khadka Cinwaanka 2",
    choosePassword: "Dooro Furaha",
    passwordMustBe6Characters: "Furaha waa inuu ahaadaa ugu yaraan 6 xaraf.",
    howDidYouHearLoystar: "Sideed ku maqashay Loystar?",
    referralCode: "Xeerka gudbinta",
    byClickingTheButton:
      " Adigoo gujinaya badhanka hoose, waxaad ku raacsan tahay",
    termsAndSevice: "Shuruudaha",
    wereCreatingAccount: "Waxaan abuuraynaa koontadaada",
    proceed: "Sii wad",
    verificationCodeMustBe6: "Xeerka xaqiijinta waa inuu noqdaa 6 lambar",
    pleaseEnter6DigitCode: "Fadlan Geli 6 lambar lambar",
    enterVerificationCode: "Geli Xeerka Xaqiijinta",
    resendToken: "Resend Token",
    verify: "Xaqiiji",
    transactions: "Macaamilada",
    todaySales: "Iibka Maanta",
    salesHistory: "Taariikhda Iibka",
    supplyHistory: "Taariikhda Sahayda",
    new: "Cusub",
    invoices: "Biilasha",
    disbursements: "Bixinta",
    offlineSales: "Iibinta Tooska ah",
    products: "Alaabta",
    customers: "Macaamiisha",
    multiLevelLoyalty: "Daacadnimada Heerka Badan",
    loyaltyPrograms: "Barnaamijyada Daacadnimada",
    members: "Xubnaha",
    appStore: "Dukaanka App",
    orderMenu: "Dalabka Menu",
    settings: "Dejinta",
    staffAndBranches: "Shaqaalaha iyo Laamaha",
    myAccount: "Koontadayda",
    switchToSellMode: "U beddel Habka Iibinta",
    signOut: "Jooji",
    getFreeSubscription: "Hel Diiwaangelin Bilaash ah",
    onlyNumbersAllowed: "Lambarada kaliya ayaa la oggol yahay",
    yourAccountMustBe10Digits: "lambarka koontadaadu waa inuu noqdaa 10 lambar",
    yourBvnMustBe11: "BVN -gaagu waa inuu ahaadaa 11 lambar",
    pleaseSelectBank: "Fadlan dooro bangigaaga",
    selectYourBank: "Dooro Bangigaaga",
    enterBankAccountNumber: "Geli Lambarka Xisaabta Bangiga",
    enterBvnNumber: "Geli BVN -kaaga",
    connectBankAccount: "Ku Xir Xisaabta Bangiga",
    passwordResetSuccessfulAndSignOut:
      "Eraygaaga sirta ah si guul leh ayaa dib loo dhigay. Guji badhanka ka bixitaanka ee hoose si aad mar kale u saxiixdo",
    enterCurrentPassword: "Geli sirta hadda",
    pleaseEnterCurrentPassword: "Fadlan geli erayga sirta ah ee hadda",
    phoneNumber: "Lambarka taleefanka",
    sex: "Galmada",
    dateOfBirth: "Taariikhda Dhalashada",
    state: "Gobolka",
    country: "Dalka",
    loyaltyId: "Aqoonsiga Daacadnimada",
    createdAt: "Laga Abuuray",
    noOfTransactionsMade: "Tirada macaamilada la sameeyay",
    yourDownloadWillStart:
      "Soo dejintaadu waxay bilaaban doontaa daqiiqad gudaheed",
    exportCustomers: "Macaamiisha Dhoofisa",
    youhaveSuccessfullyToppedUp:
      "Waxaad si guul leh u buuxisay Unugyadaaga SMS.",
    youNowHave: "Hadda waad haysataa",
    smsUnits: "Unugyada SMS",
    enterNumberOfSmsUnits:
      "Geli Tirada Unugyada SMS ee aad Doonayso inaad Iibsato",
    pleaseEnterANumericValue: "Fadlan geli qiime tiro leh",
    pay: "Bixi",
    accountEditedSuccessfully: "Koontadaada si guul leh ayaa loo tafatiray.",
    youAeCurrentlyOn: "Hadda waad shidan tahay",
    plan: "Qorshee",
    userData: "Xogta Isticmaalaha",
    businessData: "MACLUUMAADKA GANACSIGA",
    businessCategory: "Catergory Ganacsi",
    pleaseSelectCurrency: "Fadlan dooro lacagtaada",
    selectYourCurrency: "Dooro lacagtaada",
    purchaseMoreSmsUnits:
      "Iibso Unugyo badan oo SMS ah adiga oo adeegsanaya foomka hoose",
    youHave: "Waxaad leedahay",
    atLeast4SmsUnitsRrequired:
      "Ugu yaraan 4 unug oo sms ayaa loo baahan yahay si loo xaqiijiyo, fadlan kor u qaad!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Fadlan xaqiiji koontadaada bangiga si aad ugu hoggaansanto shuruudaha 'Ogow Macmiilkaaga' (KYC). Tani waxay kuu oggolaan doontaa inaad lacag ku uruuriso USSD ama wareejin deg -deg ah, aad ka hesho macaamiisha amarro iyo habsocodka dejinta macaamilka. Waxaa lagu dabaqi karaa ganacsatada Nigeria kaliya. Fadlan riix badhanka hoose si aad u bilowdo.",
    reConnectBankAccount: "Dib-u-xidh Xisaabta Bangiga",
    accountName: "Magaca Xisaabta",
    accountNumber: "Lambarka Xisaabta",
    bankName: "Magaca Bangiga",
    verified: "Xaqiijiyay",
    accountDetails: "Faahfaahinta Xisaabta",
    kycBankAccount: "KYC",
    createTier: "Samee Tier",
    fileUploadSuccessful: "faylka si guul leh ayaa loo soo raray",
    fileUploadFailed: "soo gelinta faylka wuu fashilmay",
    createLoyaltyProgram: "Abuur Barnaamij Daacadnimo",
    createLoyalty: "Abuur Daacadnimo",
    name: "Magaca",
    loyaltyArtwork: "Farshaxanka Daacadnimada",
    clickToUpload: "Guji si aad u rarto",
    amountToPointsRatio: "Qaddarka Dhibcaha Qodobbada",
    points: "Dhibco",
    recommendedAmountToPointRatio:
      "Lagu taliyay: ₦ 1 ilaa 1 dhibcood. yacnii 1 Naira oo kasta, macaamiishaadu waxay helayaan 1 dhibic",
    pleaseTypeIn: "Fadlan ku qor",
    toDeleteLoyalty: "si loo tirtiro daacadnimadan",
    deleteLoyalty: "Tirtir Daacadnimada",
    toConfirm: "si loo xaqiijiyo",
    edit: "Tafatir",
    pointsAwardedSuccessfully: "Qodobbada Lagu Guuleystay.",
    enterPointValueToAward:
      "Geli Qiimaha Barta Waxaad Jeceshahay inaad Abaalmariso Macaamiisha",
    award: "Abaalmarin",
    awardPointValuesToCustomer: "Qiimaha Barta Abaalmarinta ee Macmiilka",
    enrollMembersToLoyalty: "Diiwaangeli Xubnayaasha Daacadnimada",
    awardPoints: "Qodobbada Abaalmarinta",
    enroll: "Isqor",
    home: "Hoyga",
    loyalty: "Daacadnimo",
    enrollCustomers: "Qor Macaamiisha",
    selected: "La doortay",
    customer: "Macmiil",
    loyaltyActivationSuccessful:
      "Daacadnimada Hawlgalintu waa lagu guulaystaa.",
    loyaltyDeactivationSuccessful: "Daacinta Daacadnimada waa lagu guulaystaa.",
    viewTier: "Eeg Tier",
    deactivate: "Dib-u-dhaqaajin",
    activate: "Dhaqdhaqaaq",
    actions: "Falalka",
    nameOfLoyalty: "Magaca Daacadnimada",
    loyaltyStatus: "Xaaladda Daacadnimada Xaaladda Daacadnimada",
    numberOfTiers: "Tirada Tirooyinka",
    createdOn: "Lagu Abuuray",
    createATier: "Samee Tier",
    stopCreatingTierConfirmation: "Ma rabtaa inaad joojiso abuurista tier?",
    stopEditingTierConfirmation: "Ma rabtaa inaad joojiso tafatirka heerkan?",
    nameOfTier: "Magaca Tier",
    minimumSpendingTarget: "Bartilmaameedka Kharashka Ugu Yar",
    maximumSpendingTarget: "Bartilmaameedka Kharashka Ugu Badan",
    minimumSpendingTargetRequired:
      "bartilmaameedka kharashka ugu yar ayaa loo baahan yahay",
    maximumSpendingTargetRequired:
      "bartilmaameedka kharashka ugu badan ayaa loo baahan yahay",
    rewardSponsor: "Kafiilka Abaalmarinta",
    pleaseChooseARewardSponsor: "Fadlan dooro kafaala qaade abaalmarin",
    self: "Is",
    partner: "Lammaane",
    rewardPartner: "Lammaanaha Abaalmarinta",
    pleaseSelectRewardPartner: "Fadlan dooro lammaanahaaga abaalmarinta",
    rewards: "Abaalmarinta",
    pleaseSelectAReward: "Fadlan dooro abaalmarin",
    instructionsOnRedeemingReward:
      "Tilmaamaha Sida Macmiilku u Abaalmarinayo Abaalmarinta",
    pleaseFillInThisField: "Fadlan Buuxi Goobtan!",
    toDeleteThisTier: " si aad u tirtirto heerkan",
    deleteTier: "Tirtir Tier",
    viewMembers: "Daawo Xubnaha",
    membersEnrolled: "Xubnaha Isqortey",
    instruction: "Tilmaan",
    membersIn: "Xubnaha ku jira",
    availableTiersInLoyalty:
      "Heerka (yada) la heli karo ee Barnaamijka Daacadnimada",
    tiers: "Tijaabooyin",
    totalTier: "WADARTA TIER",
    availableLoyaltyProgramme: "Barnaamijka Daacadnimada La Heli Karo",
    totalLoyalties: "WADANINTA WADARTA",
    memberDetails: "Faahfaahinta Xubinta",
    nameOfCustomer: "Magaca Macmiilka",
    address: "Cinwaanka",
    allEnrolledMembers: "Dhammaan Xubnaha Is -qoray",
    thisIsToWishYouHappyBirthday:
      "Tani waa inaan kuu rajeeyo dhalasho aad u faraxsan iyo nolol barwaaqo leh. Waad ku mahadsan tahay wax kasta oo aad u tahay Loyster. Dabaaldegyo Farxad leh!",
    inputAnOfferPlease: "Gelinta Dalab fadlan",
    pleaseSelectTheInsertPoint:
      "Fadlan dooro barta gelinta ee fariinta oo mar labaad guji",
    birthdayOfferAndMessage: "Bixinta Dhalashada iyo Fariinta",
    birthdayOffer: "Bixinta Dhalashada",
    birthdayMessage: "Farriinta Dhalashada",
    noOfferFound: "Wax dalab ah lama helin",
    settingABirthdayOffer:
      "Dejinta dalabka dhalashada waxay u oggolaanaysaa macaamiisha inay ku helaan dalabkan SMS -ka dhalashadooda",
    createOffer: "Samee Dalab",
    whatIsTheOffer: "Waa maxay dalabku?",
    editMessage: "Tafatir Fariinta",
    insert: "Geli",
    theNameOfCustomerInserted: "Magaca macmiilka ayaa halkan la gelin doonaa",
    theBirtdayOfferInserted:
      "Soo jeedinta dhalashada ayaa halkan la gelin doonaa",
    youSuccessfullyAddedNewBranch: "Waxaad si guul leh ugu dartay laan cusub!",
    addNewBranch: "Ku dar Laanta Cusub",
    addBranch: "Ku dar Laanta",
    additionalBranchWillIncur: "Laanta dheeraadka ah ayaa iman doonta",
    perBranch: "laan kasta",
    ecommerceBranchCosts: "Kharashka Laanta Ganacsiga",
    pleaseEnterBranchName: "Fadlan qor magaca laanta",
    pleaseEnterBranchAddress1: "Fadlan geli laanta cinwaanka cinwaanka 1",
    enterBranchAddress1: "Geli cinwaanka cinwaanka laanta 1",
    enterBranchAddress2: "Geli cinwaanka cinwaanka laanta 1",
    pleaseEnterBranchAddress2: "Fadlan geli laanta cinwaanka cinwaanka 2",
    enterBranchName: "Ku qor magaca laanta",
    successfullyAddedStaff: "Waxaad si guul leh ugu dartay shaqaale cusub!",
    addNewStaff: "Ku dar Shaqaale Cusub",
    addStaff: "Ku dar Shaqaalaha",
    additionalStaffWillIncur: "Shaqaalaha dheeraadka ah ayaa imaan doona",
    perStaff: "halkii shaqaale.",
    pleaseEnterStaffEmail: "Fadlan geli iimaylka shaqaalaha",
    pleaseEnterStaffUsername: "Fadlan geli magaca isticmaalaha shaqaalaha",
    pleaseEnterStaffPassword: "Fadlan geli sirta shaqaalaha",
    pleaseSelectStaffRole: "Fadlan dooro doorka shaqaalaha",
    selectStaffRole: "Dooro doorka shaqaalaha",
    enterStaffEmail: "Geli emailka shaqaalaha",
    enterStaffUsername: "Geli magaca isticmaalaha shaqaalaha",
    enterStaffPassword: "Geli sirta shaqaalaha",
    spacesNotAllowedInUsername:
      "meelaha bannaan looma oggola magaca isticmaalaha",
    admin: "Maamulka",
    pleaseSelectBusinessToAttachStaff:
      "Fadlan dooro meherad aad ku lifaaqdo shaqaalaha",
    searchForBranchToAttachStaff: "Raadi laan si aad ugu lifaaqdo shaqaalaha",
    username: "Magaca isticmaalaha",
    role: "Doorka",
    areYouSureToDeleteThis: "Ma hubtaa inaad tan tirtirto",
    branches: "Laamaha",
    upgradeYourPlan: "Kor u qaad Qorshahaaga.",
    add: "KU DAR",
    addNew: "Ku dar Cusub",
    customerWithEmailAlreadyExists:
      "Macmiilka leh lambarka iimaylka/taleefanka ayaa hore u jiray!",
    successfullyAddedNewCustomer:
      "Waxaad si guul leh ugu dartay macmiil cusub!",
    addCustomer: "Ku dar Macmiil",
    pleaseEnterCustomerFirstName: "Fadlan qor magaca koowaad ee macaamilka",
    pleaseEnterCustomerLastName: "Fadlan geli magaca dambe ee macmiilka",
    pleaseEnterCustomerPhoneNumber: "Fadlan geli lambarka taleefanka macmiilka",
    pleaseEnterCustomerEmail: "Fadlan geli iimaylka macmiilka",
    pleaseEnterCustomerAddressLine: "Fadlan geli Khadka Cinwaanka macmiilka",
    pleaseEnterCustomerOtherAddress: "Fadlan geli cinwaanka kale ee macaamilka",
    pleaseSelectCustomerGender: "Fadlan dooro jinsiga macaamilka",
    gender: "Jinsiga",
    male: "Lab",
    female: "Dheddig",
    bank: "Bangiga",
    selectBank: "Dooro Bangiga",
    stateOrRegionOrProvince: "Gobolka/Gobolka/Gobolka",
    customerNotes: "Qoraalada Macmiilka",
    sendSms: "U dir SMS",
    editCustomer: "Tafatir Macaamilka",
    redeemReward: "Abaal Marinta",
    issueLoyaltyCard: "Bixinta Kaarka Daacadnimada",
    deleteCustomer: "Tirtir Macmiilka",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Waxaad si guul leh u qoondeysay Aqoonsiga Xubinnimada Daacadnimada",
    noMembershipIdAvailable:
      "Aqoonsiyada xubinnimada lama hayo. Fadlan nagala soo xiriir hello@loystar.co",
    sendEmail: "Dir Email",
    membershipPoints: "Qodobbada Xubinnimada",
    customerDetails: "Faahfaahinta Macmiilka",
    close: "Xir",
    loyaltyBalance: "Dheelitirka Daacadnimada",
    pointsBalance: "Dheelitirka Dhibcaha",
    starBalance: "Dheelitirka Xiddiga",
    requiredPoints: "Qodobbada Loo Baahan Yahay",
    requiredStars: "Xiddigaha Loo Baahan Yahay",
    reddemCode: "Xeerka Bixinta",
    toDeleteThisCustomer: "si aad u tirtirto macmiilkan",
    customerHasBeenDeletedSuccessfully:
      "Macmiilka si guul leh ayaa loo tirtiray!",
    customerWithPhoneAlreadyExists:
      "Macmiilka leh lambarka taleefanka ayaa hore u jiray",
    customerWasSuccessfullyEdited: "Macmiilka si guul leh ayaa loo tifaftiray",
    anErrorOccured: "Khalad ayaa dhacay",
    phoneNumberIncludeCountry: "Lambarka taleefanka (Ku dar Koodka Dalka)",
    yourFileQueuedForUpload:
      "Faylkaaga ayaa saf loogu jiray in la raro. Fadlan cusboonaysii bogga dhawr ilbidhiqsi kadib.",
    thereWasAnErrorPerformingOperation:
      "Cillad baa dhacday markii qalliinka la samaynayey!",
    pleaseSelectFile: "Fadlan Xulo Faylka!",
    oopsOnlyCsvFilesAllowed:
      "Abaayo! Kaliya faylasha CSV ayaa la oggol yahay. Fadlan soo gal faylka .csv.",
    fileShouldBeSmallerThan5Mb:
      "Faylka waa inuu ka yar yahay 5MB. Haddii aad haysato fayl ka weyn, fadlan iimayl u dir support@loystar.co. Mahadsanid",
    customerFirstNameIsRequired:
      "magaca koowaad ee macaamilka ayaa looga baahan yahay safka",
    customerPhoneNumberIsRequired:
      "lambarka taleefanka macmiilka ayaa loo baahan yahay safka",
    importCustomers: "Soo deji Macaamiisha",
    upload: "Soo rar",
    clickIconToDownloadCsv:
      "Guji sumaddan si aad u soo dejiso tusaalaha faylka CSV.",
    getGoogleSheetVersion: "Soo qaado nooca xaashida Google",
    clickOrDragFileToUpload: "Guji ama jiid faylka aaggan si aad u rarto",
    missingOrInvalidColumnHeader:
      "Madaxa tiirka oo maqan ama aan ansax ahayn Fadlan raac qaabka template. Mahadsanid.",
    youHaveImported: "Waxaad Soo Dejisay",
    youSuccessfullyRedeemedReward:
      "Waxaad si guul leh u furtay abaalmarintaadii!",
    sixDigitCode: "Lix lambar lambar",
    pleaseEnterCustomerRewardCode:
      "Fadlan geli lambarka abaalmarinta macmiilka",
    enterRewardCode: "Geli lambarka abaalmarinta.",
    selectLoyaltyProgram: "Dooro barnaamijka daacadnimada",
    stamps: "Shaambadaha",
    smsUnitsLow: "Unugyada SMS -ka oo hooseeya",
    pleaseTopUpSmsUnits: "Fadlan kor u qaad Unugyada SMS -ka",
    smsSuccessfullySentForDelivery:
      "SMS ayaa si guul leh loogu diray gudbinta!",
    sendSmsTo: "U dir SMS",
    allCustomers: "Dhammaan Macaamiisha",
    unitsAvailable: "Cutubyo La Heli Karo",
    pleaseTypeInTheMessage: "Fadlan ku qor fariinta",
    message: "Fariin",
    charactersRemaining: "xarfaha haray",
    avoidUseOfSpecialCharacters:
      "Ka fogow adeegsiga Astaamaha Gaarka ah iyo Emojisyada si aad u ilaaliso Unugyada SMS.",
    note: "Ogow",
    errorFetchingCustomersMultilevelDetail:
      "Cilad Keenista Macaamiisha Faahfaahinta MultiLevel",
    search: "Raadi",
    reset: "Dib u celi",
    importCustomer: "Soo deji Macmiilka",
    addNewCustomer: "Ku dar Macmiil Cusub",
    sendSmsBroadcast: "U dir Fariinta SMS",
    totalCustomers: "DHAMMAAN MACAAMIISHA",
    disbursementDetails: "Faahfaahinta Bixinta",
    paidBy: "Waxaa Bixiyay",
    disbursed: "Bixiyay",
    bankAccountName: "Magaca Koontada Bangiga",
    bankAccountNumber: "Lambarka Xisaabta Bangiga",
    transferInitiated: "Wareejinta La Bilaabay",
    transferCompleted: "Wareejinta Waa La Dhamaystiray",
    pleaseEnterAValid: "Fadlan gali ansax",
    begin: "bilaw",
    end: "dhammaad",
    date: "taariikh",
    paymentDate: "Taariikhda Bixinta",
    selectDisbursementDuration: "Dooro Muddada Bixinta",
    totalSettled: "Wadarta Deggan",
    totalUnsettled: "Wadarta Aan Deganayn",
    toDeleteThisSale: "si aad u tirtirto iibkan",
    draftSaleDeletedSuccessfully:
      "Iibinta Qabyada ah si guul leh ayaa loo tirtiray!",
    deleteSale: "Tirtir iibka",
    pleaseTypeInYourTotal:
      "Fadlan ku qor wadartaada si aad u xaqiijiso tirtirka",
    billDetails: "Faahfaahinta Bill",
    printBill: "Biilka Daabacaadda",
    servedBy: "Waxaa u adeegay",
    total: "Wadarta",
    createdDate: "Taariikhda La Abuuray",
    createdTime: "Waqtiga Abuuray",
    status: "Xaaladda",
    loginSuccessful: "Gelitaanku Waa Guulaystay",
    pleaseWaitWhileWeConnectAccount:
      "Fadlan sug inta aan isku xireyno koontadaada",
    connectionFailedTryAgain:
      "Xiriirkii wuu fashilmay Fadlan isku day mar kale.",
    connectionSuccessful: "Xiriirkii wuu guulaystay",
    viewDetails: "Daawo Faahfaahinta",
    enable: "Awood",
    free: "Bilaash",
    cost: "Qiimaha",
    visitWebsite: "Booqo Website",
    pleaseUpgradeYourPlanToPro:
      "Fadlan u hagaaji qorshahaaga Pro Plus si aad u awooddo barnaamijkan",
    connectYourBankAccount:
      "Ku xiro Koontadaada Bangiga si aad u hesho lacagaha.",
    disable: "Hawl gab",
    enableApp: "Daar Abka",
    addNewProductToInvoice: "Ku dar Alaab Cusub Qaansheegta",
    toDeleteThisInvoice: "si aad u tirtirto Qaansheegtan",
    invoiceDeletedSuccessfully: "Qaan -sheegkii si guul leh ayaa loo tirtiray!",
    deleteInvoice: "Tirtir Qaansheegta",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Waxaad si guul leh ugu dartay fariin bixinta qaansheegta!",
    pleaseEnterPaymentInstructions: "Fadlan gali tilmaamaha lacag bixinta",
    invoiceId: "Aqoonsiga qaansheegta",
    paidDate: "Taariikhda La Bixiyay",
    reference: "Tixraac",
    productAdded: "Alaabta lagu daray",
    productOutOfStock: "Badeecadii ma dhammaato Fadlan dib u kaydso.",
    totalInvoices: "DHAQAALAHA WADARTA",
    totalPaidInvoices: "CADDOOYINKA LA BIXIYAY WADARTA",
    totalUnpaidInvoices: "DHALANYARO CAAFIMAAD OO AAN LAGU BIXIN",
    loyaltyProgramDeleted: "Barnaamijkii Daacadnimada waa la tirtiray",
    thisLoyaltyProgramDeletedSuccessfully:
      "Barnaamijkan daacadnimada ah si guul leh ayaa loo tirtiray",
    thisLoyaltyProgramEditedSuccessfully:
      "Barnaamijkan daacadnimada si guul leh ayaa loo tafatiray",
    loyaltyProgramAddedSuccessfully:
      "Barnaamijka daacadnimada ayaa si guul leh loogu daray",
    loyaltyProgramEdited: "Barnaamijka Daacadnimada Oo La Tafaftiray",
    loyaltyProgramAdded: "Barnaamijka Daacadnimada ayaa lagu daray",
    loyaltyDetails: "Faahfaahinta Daacadnimada",
    doYouWantToCloseDialog: "Ma rabtaa inaad xirto wada hadalkan?",
    pleaseEnterLoyaltyName: "Fadlan qor magaca daacadnimadaada",
    programType: "Nooca Barnaamijka",
    pleaseSelectProgramType: "Fadlan dooro nooca barnaamijka",
    simplePoints: "Qodobbo Fudud",
    stampsProgram: "Barnaamijka Shaambadaha",
    threshold: "Marinka",
    pleaseEnterLoyaltyThreshold: "Fadlan geli marinka daacadnimada",
    reward: "Abaalmarin",
    pleaseEnterLoyaltyReward: "Fadlan geli abaalmarinta daacadnimada",
    totalUserPoints: "Dhibcaha Isticmaalaha Guud",
    totalUserStamps: "Wadarta Shaambadaha Isticmaalaha",
    spendingTarget: "Ujeeddada kharashka",
    spendingTargetHint1:
      "Bartilmaameedka kharashka waa inta macmiilku ku qasban yahay inuu kasbado abaalmarinta. 1 qiima lacageed = 1 dhibic.",
    spendingTargetHint2:
      "Bartilmaameedka shaabaddu waa inta shaambadood ee ay tahay in macmiilku ururiyo si uu u kasbado abaalmarinta. tusaale. 5",
    addNewLoyaltyProgram: "Ku dar Barnaamijka Daacadnimada Cusub",
    addLoyaltyProgram: "Ku dar Barnaamijka Daacadnimada",
    loyaltyProgramType: "Nooca Barnaamijka Daacadnimada",
    pleaseSelectLoyaltyProgramType:
      "Fadlan dooro nooca barnaamijka daacadnimada",
    nameOfProgram: "Magaca barnaamijka",
    pleaseEnterProgramName: "Fadlan qor magaca barnaamijka",
    whatIsTheReward: "Waa maxay abaalgudku?",
    egNextPurchaseIsFree: "Tusaale ahaan Iibsiga xiga waa lacag la'aan",
    customerName: "Magaca Macmiilka",
    guestCustomer: "Macmiilka Martida",
    orderConfirmedSuccessfully: "Dalabka si guul leh ayaa loo xaqiijiyay",
    orderCancelledSuccessfully: "Dalabka waa la joojiyay si guul leh",
    confirmOrder: "Xaqiiji si",
    cancelOrder: "Jooji dalabka",
    allOrders: "Dhammaan Amarada",
    totalOrders: "AMARADA WADARTA",
    doYouWantToAcceptOrder: "Ma rabtaa inaad aqbasho amarkan?",
    doYouWantToCancelOrder: "Ma rabtaa inaad joojiso amarkan?",
    orderDetails: "Faahfaahinta Dalabka",
    orderCreatedAt: "Dalabka waxaa laga sameeyay",
    supplier: "Alaab -qeybiye",
    productName: "Magaca badeecada",
    quantity: "Tirada",
    unitPrice: "Qiimaha Cutubka",
    receivedBy: "Helay By",
    reportFrom: "Warbixinta ka",
    totalSupplies: "Sahayda Wadarta",
    allRightsReserved: "Dhammaan Xuquuqda Aad Leedahay",
    toDeleteThisTransaction: "si aad u tirtirto Macaamilkan",
    transactionDeletedSuccessfully:
      "Hawlgalkii si guul leh ayaa loo tirtiray Hantida ayaa dib loogu celiyey liiska.",
    deleteTransaction: "Tirtir macaamilka",
    transactionDetails: "Faahfaahinta macaamilka",
    printReceipt: "Rasiidka Daabacaadda",
    channel: "Kanaalka",
    discount: "Qiimo dhimis",
    profit: "Faa'iido",
    discountedSales: "Iibka la dhimay",
    errorFetchingRecord: "Diiwaanka Keenista Khalad",
    exportTransactions: "Macaamilka Dhoofinta",
    errorFetchingSalesRecord: "Cilad keenista rikoodhka Iibka ee Dhoofinta.",
    totalSellingPrice: "Wadarta Qiimaha Iibinta",
    totalCostPrice: "Qiimaha Wadarta",
    appliedDiscount: "Qiimo dhimista la dabaqay",
    noOfItems: "Tirada Alaabta",
    sales: "Iibinta",
    export: "Dhoofinta",
    totalProfit: "Wadarta Macaashka",
    totalBalanceInPeriod: "Isku -dheelitirka Wadarta Muddada",
    allTimeSales: "Iibinta Waqtiga oo dhan",
    records: "Diiwaanada",
    todaysSales: "Iibka Maanta",
    transaction: "macaamil ganacsi",
    youHaveExceededTotalNumberOfProducts:
      "Waxaad dhaaftay wadarta tirada alaabta laguu oggolaaday qorshahaaga. Kor u qaad qorshahaaga si aad ugu raaxaysato xadka sare.",
    youNeedToHaveLoyaltyProgram:
      "Waxaad u Baahan Tahay Inaad Leedahay Barnaamij Daacadnimo si aad u isticmaasho astaantan!",
    price: "Qiimo",
    category: "Qaybta",
    stockTracking: "Raad -raaca Hantida",
    stockCount: "Tirada saamiyada",
    taxed: "Canshuur",
    originalPrice: "Qiimaha Asalka",
    costPrice: "Qiimaha Qiimaha",
    unit: "Cutub",
    productImage: "Sawirka Alaabta",
    taxRate: "Heerka Canshuurta",
    taxType: "Nooca Canshuurta",
    trackInventory: "Raad -raac Track",
    variants: "Kala duwanaansho",
    hasVariants: "Leh Noocyo",
    importProduct: "Soodejinta alaabta",
    exportProducts: "Alaabada Dhoofinta",
    addNewProduct: "Ku dar Alaab Cusub",
    viewCategory: "Eeg Qaybta",
    viewSuppliers: "Eeg Alaab -qeybiyeyaasha",
    receiveInventory: "Hel Tirakoob",
    printAllProductsTag: "Daabac Dhammaan Alaabooyinka Tag",
    deleteAll: "Tirtir Dhammaan",
    totalProducts: "WAXYAABAHA WADARTA",
    youveSuccessfullyAddedANewCategory:
      "Waxaad si guul leh ugu dartay qayb cusub",
    addNewCategory: "Ku dar Qaybta Cusub",
    addCategory: "Ku dar Qaybta",
    categoryName: "Magaca Qaybta",
    pleaseEnterCategoryName: "Fadlan geli magaca qaybta",
    stampsTarget: "Bartilmaameedyada",
    sendInventory: "U dir Alaabta",
    productDetails: "Faahfaahinta Alaabta",
    youveSuccessfullyEditedThisCategory:
      "Waxaad si guul leh u tafatirtay qaybtan",
    update: "Cusboonaysii",
    categoryList: "Liiska Qaybta",
    categories: "Qaybaha",
    enterQuantityToUpdate: "Geli tiro si aad u cusboonaysiiso",
    inventorySentSuccessfully: "Tirakoobka ayaa si guul leh loogu diray!",
    updateInventory: "Cusboonaysii Alaabada",
    currentQuantity: "Tirada Hadda",
    pleaseEnterQuantityToAdd: "Fadlan geli tirada aad rabto inaad ku darto",
    pleaseSelectABranch: "Fadlan dooro Laan",
    searchForBranch: "Raadi laan",
    youCantSendMoreThanStock: "Ma diri kartid wax ka badan inta aad haysato",
    send: "Dir",
    pleaseEnterQuantityToSend: "Fadlan geli tirada aad rabto inaad dirto",
    productNameIsRequiredOnRow:
      "magaca badeecada waxaa looga baahan yahay safka",
    productCategoryIsRequiredOnRow:
      "qaybta alaabta ayaa looga baahan yahay safka",
    productPriceIsRequiredOnRow:
      "qiimaha badeecada ayaa looga baahan yahay safka",
    productUnitIsRequiredOnRow: "cutubka alaabta ayaa looga baahan yahay safka",
    productQuantityIsRequiredOnRow:
      "tirada badeecada ayaa loo baahan yahay safka",
    productVariantsRequireTracking:
      "kala duwanaanta badeecaddu waxay u baahan tahay raadraac!",
    pleaseAddVariantClickButton:
      "Fadlan ku dar kala duwanaansho adigoo gujinaya badhanka dar!",
    totalVariantsMoreThanSelectedQuantity:
      "Isbeddellada Wadarta ayaa ka badan tirada alaabta la soo xulay, Pls waxay yareyneysaa tirada kala duwan",
    productEditedSuccessfully: "Badeecada si tafatiran ayaa loo tafatiray!",
    fileTooLargeLessThan4Mb:
      "Cabbirka faylku aad buu u weyn yahay! Cabbirka faylka waa inuu ka yar yahay 4MB.",
    suchVariantAlreadyExist: "Kala duwanaanshaha noocan oo kale ah ayaa jira",
    addVariants: "Ku dar Noocyo",
    editProduct: "Tafatir badeecada",
    pleaseEnterProductName: "Fadlan Geli Magaca Alaabta",
    pleaseEnterProductPrice: "Fadlan Geli Qiimaha Alaabta",
    pleaseEnterProductOriginalPrice: "Fadlan Geli Qiimaha Asalka ah",
    productDescription: "Sharaxaada alaabta",
    selectProductCategory: "Dooro Qaybta Alaabta",
    pleaseSelectProductCategory: "Fadlan dooro qaybta badeecada",
    productCostPrice: "Qiimaha Qiimaha Alaabta",
    productSellingPrice: "Qiimaha Iibinta Alaabta",
    productOriginalPrice: "Qiimaha Asalka ah ee Alaabta",
    maxFileSizeAllowedIs4Mb:
      "Ugu badnaan Cabbirka faylka waa la oggol yahay 4mb",
    productsWithPicturesArePublished:
      "Alaabooyinka leh sawirrada waxaa lagu daabacay Discover si aad u hesho amarrada",
    shouldThisProductBeTracked: "Ma habboon tahay in la raaco badeecadan?",
    pleaseSelectStockUnit: "Fadlan dooro qaybta saamiyada",
    stockUnit: "Unugga Keydka",
    bag: "BAQ",
    bottle: "DAGAALKA",
    bunch: "CUNTO",
    can: "CAN",
    carton: "KARTON",
    crate: "QAYBTA",
    cup: "KOOB",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "QORAAL",
    pack: "XIDH",
    pair: "Lammaane",
    pieces: "PIECES",
    plate: "DHEEG",
    tonne: "TONNE (MT)",
    uNIT: "MIDOOB",
    yard: "YARD",
    pleaseEnterProductQuantity: "Fadlan geli tirada badeecada",
    productQuantity: "Tirada badeecada",
    isThisProductTaxed: "Alaabtaan ma la canshuuraa?",
    selectTaxType: "Dooro Nooca Canshuurta",
    pleaseSelectTaxType: "Fadlan dooro Nooca Canshuurta",
    progressive: "Horusocod ah",
    proportional: "Saami ahaan",
    pleaseEnterProductTaxRate: "Fadlan geli heerka canshuurta badeecada",
    doesProductHaveVariants: "Badeecadani ma leedahay kala duwanaansho?",
    type: "Nooca",
    value: "Qiimaha",
    pleaseEnterVariantType: "Fadlan geli nooca kala duwan",
    pleaseEnterVariantValue: "Fadlan geli qiimaha kala duwan",
    pleaseEnterVariantPrice: "Fadlan geli qiimaha kala duwan",
    pleaseEnterVariantQuantity: "Fadlan geli tirada kala duwan",
    productDeletedSuccessfully: "Alaabta si guul leh ayaa loo tirtiray!",
    categoryDeletedSuccessfully: "Qaybta si guul leh ayaa loo tirtiray!",
    toDeleteThisProduct: "si aad u tirtirto badeecadan",
    invalidProductQuantity: "Tirada badeecada ee aan ansax ahayn",
    quantityAddedIsOverStock:
      "Tirada aad ku darayso waxay ka badan tahay waxaad haysato.",
    itemInventoryNotTracked: "Tirakoobka walxaha lama raadraaco",
    addBulkQuantity: "Ku dar Tirada Badan",
    enterBulkQuantity: "Geli Tirada Badan",
    pleaseEnterBulkQuantity: "Fadlan geli Tirada Bulk",
    youveSuccessfullyAddedANewProduct:
      "Waxaad si guul leh ugu dartay badeeco cusub!",
    pleaseEnterProductSellingPrice: "Fadlan geli qiimaha iibinta alaabta",
    doYouWantToTrackProductStock:
      "Miyaad rabtaa inaad la socoto kaydka alaabta?",
    sellingPrice: "Qiimaha Iibinta",
    setProductExpiryReminder: "Samee Xusuusinta Wakhtiga Dhaca",
    productExpiryDate: "Taariikhda dhicitaanka alaabta",
    startRemindingFrom: "Ka Bilow Xusuusinta",
    productSuppliesAddedSuccessfully:
      "Waxaad si guul leh ugu dartay sahayda alaabta.",
    addProductSupplies: "Ku dar Agabka Alaabta",
    pleaseSelectSupplier: "Fadlan Xulo Alaab -qeybiye",
    nameOfProduct: "Magaca Alaabta",
    pleaseSelectProduct: "Fadlan Dooro Badeeco",
    productVariant: "Kala Duwanaanshaha Badeecada",
    pleaseSelectAVariant: "Fadlan Dooro Kala Duwanaansho",
    pleaseEnterUnitPrice: "Fadlan Geli Qiimaha Cutubka",
    businessBranch: "Laanta Ganacsiga",
    pleaseSelectBranch: "Fadlan dooro laan",
    youveSuccessfullyAddedANewSupplier:
      "Waxaad si guul leh ugu dartay alaab -qeybiye cusub",
    addSupplier: "Ku dar Alaab -qeybiye",
    pleaseEnterSupplierEmail: "Fadlan geli iimaylka alaab -qeybiyaha",
    pleaseAddADescription: "Fadlan ku dar sharraxaad",
    anErrorOccuredProductsDeleted:
      "Khalad ayaa dhacay intii lagu guda jiray qaliinka. Fadlan ogow, badeecooyin yar ayaa laga yaabaa in la tirtiray intii hawsha socotay",
    bulkDelete: "Tirtirid Badan",
    youAreAboutToDelete: "Waxaad ku dhowdahay inaad tirtirto",
    product: "Alaabta",
    isDueToRedeem: "waxaa sabab u ah furashada",
    aReward: "abaalmarin",
    pleaseSelectCustomerToReeemReward:
      "Fadlan dooro macmiil si aad u furato abaalmarinta.",
    youHaveNoLoyaltyProgramRunning:
      "Ma lihid barnaamij daacadnimo firfircoon oo socda",
    customerHhasNoPointsInLoyaltyProgram:
      "Macmiilku dhibco kuma laha barnaamijkan daacadnimada",
    proceedWithPayment: "Sii wad Bixinta?",
    youAreAboutToPayForTransactionUsingPoints:
      "Waxaad ku dhowdahay inaad bixiso macaamilka adoo adeegsanaya dhibco.",
    customerHas: "Macaamilka ayaa leh",
    worth: "qiimo leh",
    andIsNotSufficientToPayForTransaction:
      "mana ku filna in lagu bixiyo macaamilkan. Ma rabaan inay ku daraan dheelitirka iyagoo adeegsanaya hab kale oo lacag bixin ah?",
    addCustomerLoyalty: "Ku dar Daacadnimada Macmiilka",
    pleaseAddCustomerFirst: "Fadlan ku dar ama dooro macaamil marka hore.",
    pleaseWaitWhileWeGetReady: "Fadlan sug, inta aan is diyaarinayno",
    lowStock: "Hantid Yar",
    pleaseEnterAmountTendered: "Fadlan geli qadarka la soo bandhigay",
    areYouSureToBookSaleOffline:
      "Ma hubtaa inaad doonaysid inaad jartaan iibkaan si toos ah?",
    saleWouldBeBookedAutomatically:
      "Iibinta si otomaatig ah ayaa loo ballansan doonaa marka aad internetka furaysid",
    offlineSalesBookingCancelled:
      "Boos celinta iibka qadla'aanta waa la joojiyay",
    covid19Message:
      "COVID19: Ku dhaq gacmahaaga saabuun ama Nadiifi si aad u joojiso faafitaanka. Nabadgalyo Mar Walba",
    saleSuccessfullyRecorded: "Iibinta si guul leh ayaa loo duubay!",
    sendReceiptToEmail: "U dir risiidhka emaylka",
    sendThankYouSms: "U dir SMS mahadsanid",
    sendShippingDetails: "U dir faahfaahinta Maraakiibta",
    addLoyalty: "Ku dar Daacadnimo",
    searchCustomerNameOrNumber: "Raadi magaca macmiilka ama lambarka",
    clickTheSearchCustomerBox:
      "Guji Sanduuqa Macmiilka Raadinta iyo Kaarka Sawirka",
    enterProductPrice: "Geli Qiimaha Alaabta",
    enterPriceFor: "Geli Qiimaha",
    searchForProduct: "Raadi badeecad",
    all: "Dhammaan",
    backToDashboard: "Ku noqo Dashboard",
    viewDraftSales: "Eeg Iibinta Qabyada ah",
    variantSelected: "kala duwanaansho la doortay",
    variant: "kala duwan",
    thePreviousVariantSelectionNotAvailable:
      "Xulashada kaladuwan ee hore looma heli karo kala -duwanaanta cusub ee lagu xushay qiimaha, fadlan beddel xulashadaada.",
    pleaseSupplyPositiveQuantityNumber:
      "Fadlan soo gudbi lambarka tirada dambe",
    lowStockFor: "Hantida hooseysa",
    clearVariants: "Kala duwanaansho cad",
    pleaseEnterQuantity: "Fadlan geli Tirada",
    picture: "Sawir",
    redemptionToken: "Token furashada",
    token: "Token",
    totalSpent: "Wadarta La Bixiyay",
    confirmPayment: "Xaqiiji Bixinta",
    hasPaymentBeenMade: "Lacag -bixinta si guul leh ma looga baaraandegay?",
    enterTransactionReference:
      "Geli tixraaca macaamil ganacsi ee aad ku bixisay",
    pleaseSelectACustomer: "Fadlan dooro macmiil!",
    areYouSureToApplyDiscount:
      "Ma hubtaa inaad rabto inaad dalbato sicir -dhimis?",
    addYourBankAccountToEnableUssd:
      "Ku dar koontadaada bangiga si aad awood ugu yeelatid wareejinta USSD ee uPay",
    totalAmountToPay: "Wadarta Lacagta la Bixinayo",
    doYouWantToCancelTransaction: "Ma rabtaa inaad joojiso macaamilkan?",
    savePrintBill: "Hindise Sharciyeedka Keyd/Daabac",
    enterAmountCollectedForCustomer: "Geli qaddarka loo ururiyey macaamilka",
    recordSale: "Iibinta Diiwaanka",
    checkOutWith: "Iska hubi",
    instantTransfer: "Wareejin degdeg ah",
    dialTheUSSDCode: "Garaac lambarka USSD",
    pleaseSelectABank: "Fadlan dooro bangi",
    payWithUSSD: "Ku Bixi USSD",
    sendBillTo: " - U dir Bill",
    waitingForUSSDTransfer: "Sugitaanka Wareejinta USSD",
    percent: "Boqolkiiba",
    applyDiscount: "Codso dhimis",
    thisBillHasBeenSaved: "Sharcigaan waa la keydiyay",
    saveDraft: "Qabyo Qoraal",
    pleaseTypeANameToIdentifyCustomer:
      "Fadlan ku qor magac si loo garto macaamilka",
    paymentDetails: "Faahfaahinta Bixinta",
    basePrice: "Qiimaha Saldhigga",
    staff: "Shaqaalaha",
    subTotal: "Wadarta guud",
    durationMonths: "Muddada (bilaha)",
    selectADuration: "Dooro Muddo",
    oneMonth: "1 Bil",
    twoMonths: "2 Bilood",
    threeMonths: "3 Bilood",
    sixMonths: "6 Bilood",
    twelveMonths: "12 Bilood",
    eighteenMonths: "18 Bilood",
    twentyFourMonths: "24 Bilood",
    twoMonthsFree: "(Bilaha 2 Bilaash)",
    threeMonthsFree: "(Bilaha 3 Bilaash)",
    fiveMonthsFree: "(Bilaha 5 Bilaash)",
    yourAccountHasBeen: "Koontadaadu waxay ahayd",
    renewed: "la cusboonaysiiyay",
    upgraded: "la casriyeeyay",
    successfully: "si guul leh",
    yourSubscription: "Diiwaangelintaada",
    youAreCurrentlyEnrolledOnA: "Waxaad hadda ka diiwaangashan tahay a",
    pleaseChooseAPaymentOption: "Fadlan Dooro Xulashada Lacag -bixinta",
    planRenewal: "Qorshaha Cusboonaysiinta",
    planUpgrade: "Kordhinta Qorshaha",
    pleaseSelectDurationToPayFor:
      "Fadlan Dooro Muddada aad damacsan tahay inaad bixiso",
    staffAccounts: "Xisaabaadka Shaqaalaha",
    ecommerce: "Ganacsiga",
    pleaseSelectAPlan: "Fadlan dooro qorshe",
    includeAddons: "Ku dar dar-darrooyin",
    viewTransactions: "Eeg Macaamilka",
    customerHasNoCompletedTansactions:
      "Macmiilku weli ma samayn wax -qabadyo dhammaystiran",
    branch: "Laanta",
    enterNumberOfEcommerceBranches: "Geli Tirada Laamaha Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Geli Tirada Laamaha Ecommerce -ka ee Aad Doonayso Inaad Bixiso",
    ecommerceIntegration: "Isdhexgalka Ecommerce",
    enterNumberOfBranches: "Geli Tirada Laamaha",
    enterNumberOfAdditionalBranchesToPay:
      "Geli Tirada Laamaha Dheeraadka ah ee Aad Doonayso Inaad Bixiso",
    enterNumberOfStaffs: "Geli Tirada Shaqaalaha",
    enterNumberOfStaffsToPayFor:
      "Geli Tirada Shaqaalaha ee Aad Doonayso Inaad Bixiso",
    discountApplies: "Codsashada dhimista",
    starsOnThe: "xiddigaha on",
    offer: "dalab",
    get: "Hel",
    moreStarsToRedeem: "xiddigo badan si loo furto",
    taxVat: "Canshuurta (VAT)",
    callCashierToCompletePayment:
      "Wac Lacag -hayaha si aad u Bixiso Lacag -bixinta",
    receipt: "Rasiidka",
    dear: "Gacaliye",
    thankYouForYourOrderFindGoods:
      "Waad ku mahadsan tahay dalabkaaga. Fadlan hel alaabta soo socota oo la keenay, sida lagu heshiiyay.",
    shippingNote: "Qoraalka Maraakiibta",
    enterShippingNote: "Geli Qoraalka Maraakiibta",
    shippingAddress: "Cinwaanka dhoofinta",
    enterShippingAddress: "Geli cinwaanka Dhoofinta",
    wellDoneYouAreDueToRedeem: "Waa hagaag! Waa inaad furataa",
    toGetYourRewardNextVisit:
      "si aad u hesho abaalmarintaada booqashadaada xigta. Mahadsanid",
    pointsOnThe: "Qodobbada ku yaal",
    morePointsToRedeem: "dhibco badan si loo furto",
    showCode: "Tus koodh",
    toGetYourRewardOnNextVisit:
      "si aad u hesho abaalmarintaada booqashadaada xigta. Mahadsanid",
    earn: "Hesho",
    delivaryNote: "Xusuusin Delivary",
    draftSales: "Iibinta Qabyada ah",
    startDate: "Taariikhda billowga",
    endDate: "Taariikhda dhammaadka",
    orders: "Amarada",
    checkout: "baadhid",
    noProductItem: "Ma Jirto Alaab",
    printProductTag: "Daabac Tag Tag",
    transactionReference: "Tixraaca macaamilka",
    Cashier: "Lacag -haye",
    Manager: "Maareeyaha",
    Owner: "Milkiilaha",
    Admin: "Maamulka",
    addPartners: "Kudar Shuraakada",
    addNewLoyaltyPartner: "Kudar Saaxiib Cusub oo Daacadnimo ah",
    pleaseEnterCompanyName: "Fadlan geli Magaca Shirkadda",
    companyName: "Magaca Shirkadda",
    pleaseEnterCompanyRepName: "Fadlan gali Magaca wakiilka shirkada",
    companyRepName: "Magaca Wakiilka Shirkadda",
    pleaseEnterCompanyRepEmail: "Fadlan gali iimaylka wakiilka shirkada",
    companyRepEmail: "E-mailka wakiilka shirkadda",
    pleaseEnterCompanyRepPhone:
      "Fadlan geli lambarka taleefanka wakiilka shirkadda",
    companyRepPhone: "Lambarka telefoonka ee wakiilka shirkadda",
    addReward: "Abaal marin ku darso",
    pleaseEnterRewardName: "Fadlan geli magaca abaal-marinta",
    rewardName: "Magaca Abaalmarinta",
    rewardQuantity: "Tirada Abaalmarinta",
    rewardDescription: "Sharaxaada Abaalmarinta",
    rewardType: "Nooca Abaalmarinta",
    pleaseEnterRewardType: "Fadlan geli nooca abaalmarinta",
    pleaseEnterRewardQuantity: "Fadlan geli tirada abaalmarinta",
    pleaseEnterRewardDescription: "Fadlan geli sharaxaadda abaalmarinta",
    fineDining: "Cunto Fiican",
    luxuryFashion: "Dharka raaxada",
    hotels: "Hoteelada",
    travel: "Safarka",
    foodAndBeverage: "Cuntada iyo Cabitaanka",
    fashion: "Fashion",
    health: "Caafimaadka",
    furniture: "Alaabta guriga",
    entertainment: "Madadaalo",
    automobile: "Baabuur",
    education: "Waxbarasho",
    beautyAndSpa: "Quruxda iyo Spa",
    staycation: "Joogista",
    events: "Dhacdooyinka",
    trips: "Safarrada",
    oilAndGas: "Saliid iyo Gaas",
    laundry: "dhar dhaqid",
    partnerCategory: "Qaybta lammaanaha",
    freeItem: "Shayga bilaashka ah",
  },
  Spanish: {
    cashier: "cajero",
    manager: "gerente",
    owner: "dueño",
    online: "en línea",
    offline: "desconectado",
    changePassword: "Cambiar la contraseña",
    currentPasswordMessage: "Ingrese su contraseña actual",
    passwordMessage: "Por favor, introduzca su contraseña",
    currentPassword: "Contraseña actual",
    password: "contraseña",
    confirmPasswordMessage: "¡Por favor, confirme su contraseña!",
    confirmPassword: "confirmar Contraseña",
    sendViaEmail: "Enviar por correo electrónico",
    sendViaWhatsapp: "Enviar por WhatsApp",
    downloadPdf: "Descargar PDF",
    paid: "pagado",
    unpaid: "no pagado",
    partial: "parcial",
    closeInvoice: "¿Quieres cerrar la factura?",
    closeInvoiceConfirmation:
      "Es posible que la factura no se guarde. Quieres cerrar",
    yes: "sí",
    no: "no",
    invoice: "Factura",
    wasDeducted: "fue deducido",
    for: "por",
    item: "Artículo",
    addProduct: "Agregar producto",
    paymentReference: "Referencia de pago",
    amountPaid: "Cantidad pagada",
    discountAmount: "Importe de descuento",
    amountDue: "Monto adeudado",
    amount: "Monto",
    dueDate: "Fecha de vencimiento",
    paymentType: "Tipo de pago",
    card: "Tarjeta",
    cash: "Dinero en efectivo",
    bankTransfer: "Transferencia bancaria",
    paymentMessage: "Mensaje de pago",
    description: "Descripción",
    sendReceipt: "Enviar recibo",
    delete: "Borrar",
    save: "Ahorrar",
    resend: "Reenviar",
    saveAndSend: "Ahorrar",
    invoiceSaved: "Factura guardada!",
    selectPaymentMethod: "¡Por favor seleccione un método de pago!",
    selectCustomer: "¡Seleccione un cliente!",
    cartEmptyError:
      "La lista del carrito no puede estar vacía, cierre la factura y agregue el artículo al carrito.",
    subscriptionExpired:
      "Su suscripción ha expirado y su cuenta ahora es limitada. Haga clic en el botón de abajo para renovar su cuenta",
    renew: "Renovar",
    holdOn: "Esperar",
    customerBank: "Banco del cliente",
    cancel: "Cancelar",
    selectACustomer: "Seleccionar cliente",
    invoiceSuccessfulPdfError:
      "La factura se ha creado correctamente, pero la generación de PDF está tardando más de lo habitual. Vuelva a consultar en breve.",
    downloadingInvoice: "Descarga de la factura",
    downloadingReceipt: "Descarga de recibo",
    whatsappReceiptError:
      "Se produjo un error al enviar el recibo a través de WhatsApp. Inténtelo de nuevo.",
    receiptToWhatsapp: "Recibo reenviado a WhatsApp",
    thankYouForPatronage: "Gracias por su patrocinio",
    hereIsYourReceipt: "Aquí está su recibo de pago",
    errorSendingEmailReceipt:
      "Se produjo un error al enviar el recibo por correo electrónico, inténtelo de nuevo o comuníquese con el soporte",
    receiptSentToEmail:
      "El recibo se ha enviado al correo electrónico del cliente.",
    invoiceSentToEmail:
      "La factura se ha enviado al correo electrónico del cliente.",
    invoiceSuccessWhatsappError:
      "La factura se creó correctamente, pero se produjo un error al enviarla a WhatsApp. Vuelve a intentarlo en la lista de facturas",
    invoiceSuccessfulEmailError:
      "La factura se creó correctamente, pero se produjo un error al enviarla como correo electrónico. Vuelva a intentarlo desde la lista de facturas.",
    invoiceSentToWhatsapp: "Factura reenviada a WhatsApp",
    hello: "Hola",
    pleaseDownloadInvoice: "Descargue la factura",
    pleaseDownloadReceipt: "Descargue el recibo",
    from: "de",
    email: "Correo electrónico",
    upgrade: "Potenciar",
    youAreOnFreePlan: "Estás en un plan gratuito.",
    clickOn: "Haga clic en",
    yourPlanInFewSteps: " su suscripción en unos pocos pasos rápidos.",
    to: "para",
    yourSubscriptionHasExpired:
      "Su suscripción ha expirado y su cuenta ahora es limitada.",
    days: "dias",
    yourSubscriptionExpiresIn: "Su suscripción a Loystar vence en",
    createAcount: "Crea una cuenta",
    signIn: "Iniciar sesión",
    continue: "Continuar",
    enterOtp: "Ingrese el PIN de OTP",
    enterValidOtp: "Ingrese un PIN válido",
    pin: "ALFILER",
    tokenSentToMail: "Se ha enviado un token a su correo electrónico.",
    passwordResetSuccessful: "El restablecimiento de la contraseña fue exitoso",
    somethingWentWrong: "¡Algo salió mal!",
    resetPassword: "Restablecer la contraseña",
    iHaveResetCode: "Tengo un código de restablecimiento de contraseña",
    pleaseEnterEmail: "Por favor introduzca su correo electrónico",
    aTokenWillBeSentToEmail: "Se enviará un token a su correo electrónico",
    enterEmail: "Introduce tu correo electrónico",
    sendinYourToken: "Enviando tu token ...",
    makeSureItMatchesPassword:
      "Asegúrese de que coincida con su nueva contraseña",
    pleaseChooseNewPassword: "Elija una nueva contraseña",
    chooseNewPassword: "Elija una contraseña nueva",
    enterNewPassword: "Ingrese su nueva contraseña para confirmar",
    enterTokenSent: "Ingrese el token que se envió a su correo",
    resetToken: "Restablecer token",
    resettingPassword: "Restableciendo su contraseña ...",
    signUp: "Inscribirse",
    adminSignInWithEmail:
      " El administrador inicia sesión con correo electrónico mientras que el personal inicia sesión con nombre de usuario.",
    pleaseEnterEmailOrUsername:
      "Ingrese su correo electrónico o nombre de usuario",
    emailOrUsername: "Correo electrónico o nombre de usuario",
    pleaseEnterPassword: "Por favor, ingrese contraseña",
    createAnAccount: "Crea una cuenta",
    forgotPassword: "¿Has olvidado tu contraseña?",
    enterPhoneNumber: "Ingresa número telefónico",
    personalData: "Información personal",
    validateConfirmationCOde: "Validar código de confirmación",
    pleaseEnterFirstName: "Por favor, introduzca su nombre de pila",
    pleaseEnterPhoneNumber: "Por favor, introduzca su número de teléfono",
    pleaseEnterLastName: "Por favor ingrese su apellido",
    pleaseEnterBusinessName: "Ingrese el nombre de su empresa",
    firstName: "Primer nombre",
    lastName: "Apellido",
    businessName: "Nombre del Negocio",
    previous: "Anterior",
    next: "próximo",
    pleaseSelectBusinessCategory: "Seleccione la categoría de su empresa",
    pleaseEnterValidEmail:
      "Por favor introduzca una dirección de correo electrónico válida",
    pleaseEnterPostCode: "Por favor ingrese el código postal",
    postCode: "Código postal",
    phoneNumberInUse: "¡Este número de teléfono ya está en uso!",
    emailInUse: "¡Este correo electrónico ya está en uso!",
    foodAndDrinks: "Alimentos y bebidas",
    salonAndBeauty: "Salón y Belleza",
    fashionAndAccessories: "Moda y Complementos",
    gymAndFitness: "Gimnasio y Fitness",
    travelAndHotel: "Viajes y Hotel",
    homeAndGifts: "Hogar y Regalos",
    washingAndCleaning: "Lavado y limpieza",
    gadgetsAndElectronics: "Gadgets y electrónica",
    groceries: "Comestibles",
    others: "Otros",
    submit: "Enviar",
    accountCreatedSuccessful: "Tu cuenta ha sido creada exitosamente.",
    pleaseEnterAddress: "Por favor ingrese su direccion",
    addressLine1: "Dirección Línea 1",
    addressLine2: "Línea de dirección 2",
    choosePassword: "Elegir una contraseña",
    passwordMustBe6Characters:
      "La contraseña debe tener al menos 6 caracteres.",
    howDidYouHearLoystar: "¿Cómo se enteró de Loystar?",
    referralCode: "código de referencia",
    byClickingTheButton: " Al hacer clic en el botón de abajo, acepta las",
    termsAndSevice: "Condiciones",
    wereCreatingAccount: "Estamos creando tu cuenta",
    proceed: "Continuar",
    verificationCodeMustBe6: "El código de verificación debe tener 6 dígitos",
    pleaseEnter6DigitCode: "Ingrese un código de 6 dígitos",
    enterVerificationCode: "Ingrese el código de verificación",
    resendToken: "Reenviar token",
    verify: "Verificar",
    transactions: "Actas",
    todaySales: "Ventas de hoy",
    salesHistory: "Historial de ventas",
    supplyHistory: "Historial de suministro",
    new: "Nuevo",
    invoices: "Facturas",
    disbursements: "Desembolsos",
    offlineSales: "Ventas fuera de línea",
    products: "Productos",
    customers: "Clientes",
    multiLevelLoyalty: "Fidelización multinivel",
    loyaltyPrograms: "Programas de lealtad",
    members: "Miembros",
    appStore: "Tienda de aplicaciones",
    orderMenu: "Menú de pedidos",
    settings: "Ajustes",
    staffAndBranches: "Bastones y ramas",
    myAccount: "Mi cuenta",
    switchToSellMode: "Cambiar al modo de venta",
    signOut: "Desconectar",
    getFreeSubscription: "Obtenga una suscripción gratuita",
    onlyNumbersAllowed: "Solo se permiten números",
    yourAccountMustBe10Digits:
      "su número de cuenta debe ser un número de 10 dígitos",
    yourBvnMustBe11: "su BVN debe ser un número de 11 dígitos",
    pleaseSelectBank: "Por favor seleccione su banco",
    selectYourBank: "Seleccione su banco",
    enterBankAccountNumber: "Ingrese el número de cuenta bancaria",
    enterBvnNumber: "Ingrese su BVN",
    connectBankAccount: "Conectar cuenta bancaria",
    passwordResetSuccessfulAndSignOut:
      "Su contraseña se ha restablecido correctamente. Haga clic en el botón Cerrar sesión a continuación para iniciar sesión nuevamente",
    enterCurrentPassword: "Introducir la contraseña actual",
    pleaseEnterCurrentPassword: "Ingrese la contraseña actual",
    phoneNumber: "Número de teléfono",
    sex: "Sexo",
    dateOfBirth: "Fecha de cumpleaños",
    state: "Estado",
    country: "País",
    loyaltyId: "ID de fidelidad",
    createdAt: "Creado en",
    noOfTransactionsMade: "No. de transacciones realizadas",
    yourDownloadWillStart: "Tu descarga comenzará en un momento",
    exportCustomers: "Clientes de exportación",
    youhaveSuccessfullyToppedUp: "Ha recargado con éxito sus Unidades SMS.",
    youNowHave: "Ahora tienes",
    smsUnits: "Unidades SMS",
    enterNumberOfSmsUnits:
      "Ingrese el número de unidades de SMS que desea comprar",
    pleaseEnterANumericValue: "Por favor, introduzca un valor numérico",
    pay: "Pagar",
    accountEditedSuccessfully: "Su cuenta fue editada con éxito.",
    youAeCurrentlyOn: "Actualmente estás en",
    plan: "Plan",
    userData: "Datos del usuario",
    businessData: "DATOS EMPRESARIALES",
    businessCategory: "Categoría empresarial",
    pleaseSelectCurrency: "Seleccione su moneda",
    selectYourCurrency: "Seleccione su moneda",
    purchaseMoreSmsUnits:
      "Compre más Unidades SMS utilizando el formulario a continuación",
    youHave: "Tú tienes",
    atLeast4SmsUnitsRrequired:
      "Se requieren al menos 4 unidades sms para la verificación, ¡por favor recargue!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verifique su cuenta bancaria para cumplir con los requisitos de "Conozca a su cliente" (KYC). Esto le permitirá cobrar pagos a través de USSD o transferencia instantánea, recibir pedidos de clientes y procesar la liquidación de transacciones. Aplicable únicamente a comerciantes nigerianos. Presione el botón de abajo para comenzar.',
    reConnectBankAccount: "Vuelva a conectar la cuenta bancaria",
    accountName: "Nombre de la cuenta",
    accountNumber: "Número de cuenta",
    bankName: "Nombre del banco",
    verified: "Verificado",
    accountDetails: "detalles de la cuenta",
    kycBankAccount: "KYC",
    createTier: "Crear nivel",
    fileUploadSuccessful: "documento cargado exitosamente",
    fileUploadFailed: "la carga del archivo falló",
    createLoyaltyProgram: "Crea un programa de fidelización",
    createLoyalty: "Crear lealtad",
    name: "Nombre",
    loyaltyArtwork: "Obra de fidelidad",
    clickToUpload: "Haga clic para subir",
    amountToPointsRatio: "Proporción de cantidad a puntos",
    points: "Puntos",
    recommendedAmountToPointRatio:
      "Recomendado: ₦ 1 a 1 punto. es decir, por cada 1 Naira gastado, sus clientes obtienen 1 punto",
    pleaseTypeIn: "Por favor escriba",
    toDeleteLoyalty: "para borrar esta lealtad",
    deleteLoyalty: "Eliminar lealtad",
    toConfirm: "para confirmar",
    edit: "Editar",
    pointsAwardedSuccessfully: "Puntos otorgados con éxito.",
    enterPointValueToAward:
      "Ingrese el valor en puntos que le gustaría otorgar a los clientes",
    award: "Otorgar",
    awardPointValuesToCustomer: "Valor en puntos de premio al cliente",
    enrollMembersToLoyalty: "Inscriba miembros en el programa de fidelización",
    awardPoints: "Puntos de premio",
    enroll: "Inscribirse",
    home: "Hogar",
    loyalty: "Lealtad",
    enrollCustomers: "Inscribir clientes",
    selected: "Seleccionado",
    customer: "Cliente",
    loyaltyActivationSuccessful:
      "La activación de la lealtad se ha realizado correctamente.",
    loyaltyDeactivationSuccessful:
      "La desactivación de la lealtad se ha realizado correctamente.",
    viewTier: "Ver nivel",
    deactivate: "Desactivar",
    activate: "Activar",
    actions: "Comportamiento",
    nameOfLoyalty: "Nombre de lealtad",
    loyaltyStatus: "Estado de fidelidad",
    numberOfTiers: "Número de niveles",
    createdOn: "Creado en",
    createATier: "Crear un nivel",
    stopCreatingTierConfirmation: "¿Quieres dejar de crear un nivel?",
    stopEditingTierConfirmation: "¿Quieres dejar de editar este nivel?",
    nameOfTier: "Nombre del nivel",
    minimumSpendingTarget: "Objetivo de gasto mínimo",
    maximumSpendingTarget: "Objetivo de gasto máximo",
    minimumSpendingTargetRequired: "se requiere un objetivo de gasto mínimo",
    maximumSpendingTargetRequired: "se requiere un objetivo de gasto máximo",
    rewardSponsor: "Patrocinador de recompensa",
    pleaseChooseARewardSponsor: "Elija un patrocinador de recompensa",
    self: "Uno mismo",
    partner: "Pareja",
    rewardPartner: "Socio de recompensa",
    pleaseSelectRewardPartner: "Seleccione su socio de recompensa",
    rewards: "Recompensas",
    pleaseSelectAReward: "Por favor selecciona una recompensa",
    instructionsOnRedeemingReward:
      "Instrucciones sobre cómo el cliente debe canjear la recompensa",
    pleaseFillInThisField: "¡Por favor complete este campo!",
    toDeleteThisTier: " para eliminar este nivel",
    deleteTier: "Eliminar nivel",
    viewMembers: "Ver miembros",
    membersEnrolled: "Miembros inscritos",
    instruction: "Instrucción",
    membersIn: "Miembros en",
    availableTiersInLoyalty:
      "Niveles disponibles en el programa de fidelización",
    tiers: "Niveles",
    totalTier: "NIVEL TOTAL",
    availableLoyaltyProgramme: "Programa de fidelización disponible",
    totalLoyalties: "FIDELIDADES TOTALES",
    memberDetails: "Detalles del miembro",
    nameOfCustomer: "Nombre del cliente",
    address: "Dirección",
    allEnrolledMembers: "Todos los miembros inscritos",
    thisIsToWishYouHappyBirthday:
      "Esto es para desearte un feliz cumpleaños y una vida próspera. Gracias por todo lo que eres para Loyster. ¡Felices celebraciones!",
    inputAnOfferPlease: "Ingrese una oferta por favor",
    pleaseSelectTheInsertPoint:
      "Seleccione el punto de inserción en el mensaje y haga clic nuevamente",
    birthdayOfferAndMessage: "Mensaje y oferta de cumpleaños",
    birthdayOffer: "Oferta de cumpleaños",
    birthdayMessage: "Mensaje de cumpleaños",
    noOfferFound: "No se encontró oferta",
    settingABirthdayOffer:
      "Establecer una oferta de cumpleaños permite a los clientes recibir esta oferta por SMS el día de su cumpleaños.",
    createOffer: "Crear oferta",
    whatIsTheOffer: "Cual es la oferta?",
    editMessage: "Editar mensaje",
    insert: "Insertar",
    theNameOfCustomerInserted: "Aquí se insertará el nombre del cliente.",
    theBirtdayOfferInserted: "La oferta de cumpleaños se insertará aquí.",
    youSuccessfullyAddedNewBranch: "¡Ha agregado con éxito una nueva rama!",
    addNewBranch: "Agregar nueva rama",
    addBranch: "Agregar rama",
    additionalBranchWillIncur: "Rama adicional incurriría",
    perBranch: "por rama",
    ecommerceBranchCosts: "Costos de la sucursal de comercio electrónico",
    pleaseEnterBranchName: "Ingrese el nombre de la sucursal",
    pleaseEnterBranchAddress1:
      "Ingrese la línea 1 de la dirección de la sucursal",
    enterBranchAddress1: "Ingrese la línea 1 de la dirección de la sucursal",
    enterBranchAddress2: "Ingrese la línea 1 de la dirección de la sucursal",
    pleaseEnterBranchAddress2:
      "Ingrese la línea 2 de la dirección de la sucursal",
    enterBranchName: "Ingrese el nombre de la sucursal",
    successfullyAddedStaff: "¡Has agregado exitosamente un nuevo personal!",
    addNewStaff: "Agregar nuevo personal",
    addStaff: "Agregar personal",
    additionalStaffWillIncur: "Personal adicional incurriría",
    perStaff: "por personal.",
    pleaseEnterStaffEmail: "Ingrese el correo electrónico del personal",
    pleaseEnterStaffUsername: "Ingrese el nombre de usuario del personal",
    pleaseEnterStaffPassword: "Ingrese la contraseña del personal",
    pleaseSelectStaffRole: "Seleccione el rol del personal",
    selectStaffRole: "Seleccione el rol del personal",
    enterStaffEmail: "Ingrese el correo electrónico del personal",
    enterStaffUsername: "Ingrese el nombre de usuario del personal",
    enterStaffPassword: "Ingrese la contraseña del personal",
    spacesNotAllowedInUsername:
      "espacios no permitidos en el nombre de usuario",
    admin: "Administración",
    pleaseSelectBusinessToAttachStaff:
      "Seleccione la empresa para adjuntar personal",
    searchForBranchToAttachStaff: "Buscar sucursal para adjuntar personal",
    username: "Nombre de usuario",
    role: "Papel",
    areYouSureToDeleteThis: "¿Estás seguro de eliminar esto?",
    branches: "Sucursales",
    upgradeYourPlan: "Actualice su plan.",
    add: "AGREGAR",
    addNew: "Agregar nuevo",
    customerWithEmailAlreadyExists:
      "¡El cliente con correo electrónico / número de teléfono ya existe!",
    successfullyAddedNewCustomer: "¡Ha agregado exitosamente un nuevo cliente!",
    addCustomer: "Agregar cliente",
    pleaseEnterCustomerFirstName: "Ingrese el nombre del cliente",
    pleaseEnterCustomerLastName: "Ingrese el apellido del cliente",
    pleaseEnterCustomerPhoneNumber: "Ingrese el número de teléfono del cliente",
    pleaseEnterCustomerEmail: "Ingrese el correo electrónico del cliente",
    pleaseEnterCustomerAddressLine: "Ingrese la línea de dirección del cliente",
    pleaseEnterCustomerOtherAddress: "Ingrese la otra dirección del cliente",
    pleaseSelectCustomerGender: "Seleccione el sexo del cliente.",
    gender: "Género",
    male: "Masculino",
    female: "Mujer",
    bank: "Banco",
    selectBank: "Seleccionar banco",
    stateOrRegionOrProvince: "Estado / Región / Provincia",
    customerNotes: "Notas de los clientes",
    sendSms: "Enviar SMS",
    editCustomer: "Editar cliente",
    redeemReward: "Canjear recompensa",
    issueLoyaltyCard: "Emitir tarjeta de fidelidad",
    deleteCustomer: "Eliminar cliente",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ha asignado correctamente una identificación de miembro de lealtad",
    noMembershipIdAvailable:
      "No hay identificaciones de membresía disponibles. Por favor contáctenos en hello@loystar.co",
    sendEmail: "Enviar correo electrónico",
    membershipPoints: "Puntos de membresía",
    customerDetails: "Detalles del cliente",
    close: "Cerrar",
    loyaltyBalance: "Saldo de lealtad",
    pointsBalance: "Saldo de puntos",
    starBalance: "Balance de estrellas",
    requiredPoints: "Puntos requeridos",
    requiredStars: "Estrellas requeridas",
    reddemCode: "Canjear código",
    toDeleteThisCustomer: "para eliminar este cliente",
    customerHasBeenDeletedSuccessfully:
      "¡El cliente ha sido eliminado correctamente!",
    customerWithPhoneAlreadyExists:
      "El cliente con número de teléfono ya existe",
    customerWasSuccessfullyEdited: "El cliente fue editado correctamente",
    anErrorOccured: "Ocurrió un error",
    phoneNumberIncludeCountry:
      "Número de teléfono (incluya el código del país)",
    yourFileQueuedForUpload:
      "Su archivo se ha puesto en cola para su carga. Actualice la página después de unos segundos.",
    thereWasAnErrorPerformingOperation:
      "¡Hubo un error al realizar la operación!",
    pleaseSelectFile: "¡Seleccione un archivo!",
    oopsOnlyCsvFilesAllowed:
      "¡UPS! Solo se permiten archivos CSV. Sube un archivo .csv.",
    fileShouldBeSmallerThan5Mb:
      "El archivo debe tener un tamaño inferior a 5 MB. Si tiene un archivo más grande, envíe un correo electrónico a support@loystar.co. Gracias",
    customerFirstNameIsRequired:
      "El nombre del cliente es obligatorio en la fila.",
    customerPhoneNumberIsRequired:
      "se requiere el número de teléfono del cliente en la fila",
    importCustomers: "Importar clientes",
    upload: "Subir",
    clickIconToDownloadCsv:
      "Haga clic en este icono para descargar la plantilla de archivo CSV.",
    getGoogleSheetVersion: "Obtenga la versión de la hoja de Google",
    clickOrDragFileToUpload:
      "Haga clic o arrastre el archivo a esta área para cargarlo",
    missingOrInvalidColumnHeader:
      "Encabezado de columna faltante o no válido. Siga el formato de la plantilla. Gracias.",
    youHaveImported: "Has importado",
    youSuccessfullyRedeemedReward: "¡Has canjeado tu recompensa con éxito!",
    sixDigitCode: "Código de seis dígitos",
    pleaseEnterCustomerRewardCode:
      "Ingrese el código de recompensa del cliente",
    enterRewardCode:
      "Ingrese el código de recompensa (el código de recompensa distingue entre mayúsculas y minúsculas).",
    selectLoyaltyProgram: "Seleccionar programa de fidelización",
    stamps: "Sellos",
    smsUnitsLow: "Unidades SMS bajas",
    pleaseTopUpSmsUnits: "Por favor, recargue unidades de SMS",
    smsSuccessfullySentForDelivery: "SMS enviado con éxito para la entrega!",
    sendSmsTo: "Enviar SMS a",
    allCustomers: "Todos los clientes",
    unitsAvailable: "Unidades disponibles",
    pleaseTypeInTheMessage: "Por favor escriba el mensaje",
    message: "Mensaje",
    charactersRemaining: "caracteres restantes",
    avoidUseOfSpecialCharacters:
      "Evite el uso de caracteres especiales y emojis para conservar unidades de SMS.",
    note: "Nota",
    errorFetchingCustomersMultilevelDetail:
      "Error al obtener detalles de varios niveles de los clientes",
    search: "Buscar",
    reset: "Reiniciar",
    importCustomer: "Importar cliente",
    addNewCustomer: "Agregar nuevo cliente",
    sendSmsBroadcast: "Enviar difusión por SMS",
    totalCustomers: "CLIENTES TOTALES",
    disbursementDetails: "Detalles del desembolso",
    paidBy: "Pagado por",
    disbursed: "Desembolsado",
    bankAccountName: "Nombre de la cuenta bancaria",
    bankAccountNumber: "Número de cuenta bancaria",
    transferInitiated: "Transferencia iniciada",
    transferCompleted: "Transferencia completada",
    pleaseEnterAValid: "Por favor, introduza un valor valido",
    begin: "empezar",
    end: "fin",
    date: "fecha",
    paymentDate: "Fecha de pago",
    selectDisbursementDuration: "Seleccione la duración del desembolso",
    totalSettled: "Total liquidado",
    totalUnsettled: "Total sin resolver",
    toDeleteThisSale: "para eliminar esta venta",
    draftSaleDeletedSuccessfully:
      "¡El borrador de la venta se ha eliminado correctamente!",
    deleteSale: "Eliminar venta",
    pleaseTypeInYourTotal: "Ingrese su total para confirmar la eliminación",
    billDetails: "Detalles de la factura",
    printBill: "Imprimir factura",
    servedBy: "Servido por",
    total: "Total",
    createdDate: "Fecha de creación",
    createdTime: "Hora de creación",
    status: "Estado",
    loginSuccessful: "Inicio de sesión correcto",
    pleaseWaitWhileWeConnectAccount: "Espere mientras conectamos su cuenta",
    connectionFailedTryAgain: "La conexión falló. Inténtalo de nuevo.",
    connectionSuccessful: "Conexión exitosa",
    viewDetails: "Ver detalles",
    enable: "Habilitar",
    free: "Gratis",
    cost: "Costo",
    visitWebsite: "Visita la página web",
    pleaseUpgradeYourPlanToPro:
      "Actualice su plan a Pro Plus para habilitar esta aplicación",
    connectYourBankAccount:
      "Conecte su cuenta bancaria para poder recibir pagos.",
    disable: "Desactivar",
    enableApp: "Habilitar aplicación",
    addNewProductToInvoice: "Agregar nuevo producto a la factura",
    toDeleteThisInvoice: "para eliminar esta factura",
    invoiceDeletedSuccessfully: "¡La factura se ha eliminado correctamente!",
    deleteInvoice: "Eliminar factura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "¡Ha agregado correctamente un mensaje de pago de factura!",
    pleaseEnterPaymentInstructions: "Ingrese las instrucciones de pago",
    invoiceId: "ID de factura",
    paidDate: "Fecha de pago",
    reference: "Referencia",
    productAdded: "Producto añadido",
    productOutOfStock: "Producto agotado. Por favor reabastezca.",
    totalInvoices: "FACTURAS TOTALES",
    totalPaidInvoices: "TOTAL DE FACTURAS PAGADAS",
    totalUnpaidInvoices: "TOTAL DE FACTURAS SIN PAGAR",
    loyaltyProgramDeleted: "Programa de fidelización eliminado",
    thisLoyaltyProgramDeletedSuccessfully:
      "Este programa de fidelización se ha eliminado correctamente.",
    thisLoyaltyProgramEditedSuccessfully:
      "Este programa de fidelización se ha editado correctamente.",
    loyaltyProgramAddedSuccessfully:
      "El programa de fidelización se ha agregado con éxito",
    loyaltyProgramEdited: "Programa de fidelización editado",
    loyaltyProgramAdded: "Programa de lealtad agregado",
    loyaltyDetails: "Detalles de fidelidad",
    doYouWantToCloseDialog: "¿Quieres cerrar este diálogo?",
    pleaseEnterLoyaltyName: "Ingrese el nombre de su lealtad",
    programType: "Tipo de programa",
    pleaseSelectProgramType: "Seleccione un tipo de programa",
    simplePoints: "Puntos simples",
    stampsProgram: "Programa de sellos",
    threshold: "Umbral",
    pleaseEnterLoyaltyThreshold: "Ingrese el límite de lealtad",
    reward: "Recompensa",
    pleaseEnterLoyaltyReward: "Ingrese la recompensa de la lealtad",
    totalUserPoints: "Puntos totales del usuario",
    totalUserStamps: "Sellos de usuario totales",
    spendingTarget: "Objetivo de gasto",
    spendingTargetHint1:
      "El objetivo de gasto es cuánto debe gastar un cliente para ganar la recompensa. 1 valor de moneda = 1 punto.",
    spendingTargetHint2:
      "El objetivo de sellos es cuántos sellos debe recolectar un cliente para ganar la recompensa. p.ej. 5",
    addNewLoyaltyProgram: "Agregar nuevo programa de lealtad",
    addLoyaltyProgram: "Agregar programa de lealtad",
    loyaltyProgramType: "Tipo de programa de fidelización",
    pleaseSelectLoyaltyProgramType:
      "Seleccione el tipo de programa de fidelización",
    nameOfProgram: "Nombre del programa",
    pleaseEnterProgramName: "Ingrese el nombre del programa",
    whatIsTheReward: "Cual es la recompensa?",
    egNextPurchaseIsFree: "P.ej. La próxima compra es gratis",
    customerName: "Nombre del cliente",
    guestCustomer: "Cliente invitado",
    orderConfirmedSuccessfully: "Pedido confirmado con éxito",
    orderCancelledSuccessfully: "Pedido cancelado con éxito",
    confirmOrder: "Confirmar pedido",
    cancelOrder: "Cancelar orden",
    allOrders: "Todas las órdenes",
    totalOrders: "PEDIDOS TOTALES",
    doYouWantToAcceptOrder: "¿Quieres aceptar este pedido?",
    doYouWantToCancelOrder: "¿Quieres cancelar este pedido?",
    orderDetails: "Detalles del pedido",
    orderCreatedAt: "Pedido creado en",
    supplier: "Proveedor",
    productName: "nombre del producto",
    quantity: "Cantidad",
    unitPrice: "Precio unitario",
    receivedBy: "Recibido por",
    reportFrom: "Informe de",
    totalSupplies: "Suministros totales",
    allRightsReserved: "Reservados todos los derechos",
    toDeleteThisTransaction: "para eliminar esta transacción",
    transactionDeletedSuccessfully:
      "La transacción se ha eliminado correctamente. El stock se ha devuelto al inventario.",
    deleteTransaction: "Eliminar transacción",
    transactionDetails: "Detalles de la transacción",
    printReceipt: "Imprima el recibo",
    channel: "Canal",
    discount: "Descuento",
    profit: "Lucro",
    discountedSales: "Ventas con descuento",
    errorFetchingRecord: "Error al obtener el registro",
    exportTransactions: "Exportar transacciones",
    errorFetchingSalesRecord:
      "Error al obtener el registro de ventas para Exportar.",
    totalSellingPrice: "Precio de venta total",
    totalCostPrice: "Precio de costo total",
    appliedDiscount: "Descuento aplicado",
    noOfItems: "No. de artículos",
    sales: "Ventas",
    export: "Exportar",
    totalProfit: "Beneficio total",
    totalBalanceInPeriod: "Saldo total en el período",
    allTimeSales: "Ventas de todos los tiempos",
    records: "Registros",
    todaysSales: "Ventas de hoy",
    transaction: "transacción",
    youHaveExceededTotalNumberOfProducts:
      "Ha superado la cantidad total de productos permitidos en su plan. Actualice su plan para disfrutar de un límite más alto.",
    youNeedToHaveLoyaltyProgram:
      "¡Necesita tener un programa de lealtad para usar esta función!",
    price: "Precio",
    category: "Categoría",
    stockTracking: "Seguimiento de stock",
    stockCount: "Recuento de stock",
    taxed: "Gravado",
    originalPrice: "Precio original",
    costPrice: "Precio de coste",
    unit: "Unidad",
    productImage: "Imagen del producto",
    taxRate: "Tasa de impuesto",
    taxType: "Tipo de impuesto",
    trackInventory: "Seguimiento de inventario",
    variants: "Variantes",
    hasVariants: "Tiene variantes",
    importProduct: "Importar producto",
    exportProducts: "Exportar Productos",
    addNewProduct: "Agregar nuevo producto",
    viewCategory: "Ver categoría",
    viewSuppliers: "Ver proveedores",
    receiveInventory: "Recibir inventario",
    printAllProductsTag: "Imprimir etiqueta de todos los productos",
    deleteAll: "Eliminar todos",
    totalProducts: "PRODUCTOS TOTALES",
    youveSuccessfullyAddedANewCategory:
      "Ha agregado con éxito una nueva categoría",
    addNewCategory: "Añadir nueva categoria",
    addCategory: "añadir categoría",
    categoryName: "nombre de la categoría",
    pleaseEnterCategoryName: "Ingrese el nombre de la categoría",
    stampsTarget: "Sellos de destino",
    sendInventory: "Enviar Inventario",
    productDetails: "Detalles de producto",
    youveSuccessfullyEditedThisCategory:
      "Has editado con éxito esta categoría.",
    update: "Actualizar",
    categoryList: "Lista de categoría",
    categories: "Categorías",
    enterQuantityToUpdate: "Ingrese una cantidad para actualizar",
    inventorySentSuccessfully: "¡El inventario se envió correctamente!",
    updateInventory: "Actualizar inventario",
    currentQuantity: "Cantidad actual",
    pleaseEnterQuantityToAdd: "Ingrese la cantidad que desea agregar",
    pleaseSelectABranch: "Seleccione una sucursal",
    searchForBranch: "Buscar sucursal",
    youCantSendMoreThanStock: "No puede enviar más de lo que tiene en stock",
    send: "Enviar",
    pleaseEnterQuantityToSend: "Por favor ingrese la cantidad que desea enviar",
    productNameIsRequiredOnRow:
      "el nombre del producto es obligatorio en la fila",
    productCategoryIsRequiredOnRow:
      "la categoría de producto es obligatoria en la fila",
    productPriceIsRequiredOnRow:
      "el precio del producto es obligatorio en la fila",
    productUnitIsRequiredOnRow: "se requiere unidad de producto en la fila",
    productQuantityIsRequiredOnRow:
      "Se requiere la cantidad de producto en la fila.",
    productVariantsRequireTracking:
      "las variantes de productos requieren seguimiento!",
    pleaseAddVariantClickButton:
      "¡Agregue una variante haciendo clic en el botón Agregar!",
    totalVariantsMoreThanSelectedQuantity:
      "Las variantes totales son más que la cantidad de producto seleccionada, por favor reduzca la cantidad de variantes",
    productEditedSuccessfully: "¡El producto se ha editado correctamente!",
    fileTooLargeLessThan4Mb:
      "¡El tamaño del archivo es demasiado grande! El tamaño del archivo debe ser inferior a 4 MB.",
    suchVariantAlreadyExist: "Tal variante ya existe",
    addVariants: "Agregar variantes",
    editProduct: "Editar producto",
    pleaseEnterProductName: "Ingrese el nombre del producto",
    pleaseEnterProductPrice: "Ingrese el precio del producto",
    pleaseEnterProductOriginalPrice: "Ingrese el precio original del producto",
    productDescription: "Descripción del producto",
    selectProductCategory: "Seleccionar categoría de producto",
    pleaseSelectProductCategory: "Seleccione una categoría de producto",
    productCostPrice: "Precio de coste del producto",
    productSellingPrice: "Precio de venta del producto",
    productOriginalPrice: "Precio original del producto",
    maxFileSizeAllowedIs4Mb: "Max. El tamaño de archivo permitido es 4 MB",
    productsWithPicturesArePublished:
      "Los productos con imágenes se publican en Discover para recibir pedidos",
    shouldThisProductBeTracked: "¿Debe rastrearse este producto?",
    pleaseSelectStockUnit: "Seleccione la unidad de stock",
    stockUnit: "Unidad de stock",
    bag: "BOLSO",
    bottle: "BOTELLA",
    bunch: "RACIMO",
    can: "PUEDEN",
    carton: "CAJA DE CARTÓN",
    crate: "CAJA",
    cup: "TAZA",
    dozen: "DOCENA",
    gigabytes: "GIGABYTES",
    gram: "GRAMO",
    kilogram: "KILOGRAMO",
    litre: "LITRO",
    pack: "PAQUETE",
    pair: "PAR",
    pieces: "PIEZAS",
    plate: "PLATO",
    tonne: "TONELADAS (MT)",
    uNIT: "UNIDAD",
    yard: "YARDA",
    pleaseEnterProductQuantity: "Ingrese la cantidad del producto",
    productQuantity: "Cantidad de producto",
    isThisProductTaxed: "¿Este producto está sujeto a impuestos?",
    selectTaxType: "Seleccione el tipo de impuesto",
    pleaseSelectTaxType: "Seleccione el tipo de impuesto",
    progressive: "Progresivo",
    proportional: "Proporcional",
    pleaseEnterProductTaxRate: "Ingrese la tasa de impuestos del producto",
    doesProductHaveVariants: "¿Este producto tiene variantes?",
    type: "Escribe",
    value: "Valor",
    pleaseEnterVariantType: "Ingrese el tipo de variante",
    pleaseEnterVariantValue: "Ingrese el valor de la variante",
    pleaseEnterVariantPrice: "Ingrese el precio de la variante",
    pleaseEnterVariantQuantity: "Ingrese la cantidad de la variante",
    productDeletedSuccessfully: "¡El producto se ha eliminado correctamente!",
    categoryDeletedSuccessfully:
      "¡La categoría ha sido eliminada correctamente!",
    toDeleteThisProduct: "para eliminar este producto",
    invalidProductQuantity: "Cantidad de producto no válida",
    quantityAddedIsOverStock:
      "La cantidad que está agregando es superior a la que tiene en stock.",
    itemInventoryNotTracked:
      "No se realiza un seguimiento del inventario de artículos",
    addBulkQuantity: "Agregar cantidad a granel",
    enterBulkQuantity: "Ingrese la cantidad a granel",
    pleaseEnterBulkQuantity: "Ingrese la cantidad a granel",
    youveSuccessfullyAddedANewProduct:
      "¡Ha agregado exitosamente un nuevo producto!",
    pleaseEnterProductSellingPrice: "Ingrese el precio de venta del producto",
    doYouWantToTrackProductStock:
      "¿Quiere realizar un seguimiento de las existencias de productos?",
    sellingPrice: "Precio de venta",
    setProductExpiryReminder:
      "Establecer recordatorio de vencimiento del producto",
    productExpiryDate: "Fecha de caducidad del producto",
    startRemindingFrom: "Empezar a recordar desde",
    productSuppliesAddedSuccessfully:
      "Ha agregado suministros de productos correctamente.",
    addProductSupplies: "Agregar suministros de productos",
    pleaseSelectSupplier: "Seleccione Proveedor",
    nameOfProduct: "Nombre del producto",
    pleaseSelectProduct: "Seleccione el producto",
    productVariant: "Variante de producto",
    pleaseSelectAVariant: "Seleccione una variante",
    pleaseEnterUnitPrice: "Ingrese el precio unitario",
    businessBranch: "Rama comercial",
    pleaseSelectBranch: "Por favor seleccione rama",
    youveSuccessfullyAddedANewSupplier:
      "Ha agregado exitosamente un nuevo proveedor",
    addSupplier: "Agregar proveedor",
    pleaseEnterSupplierEmail: "Ingrese el correo electrónico del proveedor",
    pleaseAddADescription: "Por favor agregue una descripción",
    anErrorOccuredProductsDeleted:
      "Ocurrió un error al realizar la operación. Tenga en cuenta que es posible que se hayan eliminado algunos productos en el proceso",
    bulkDelete: "Eliminación masiva",
    youAreAboutToDelete: "Estás a punto de eliminar",
    product: "Producto",
    isDueToRedeem: "se debe canjear",
    aReward: "una recompensa",
    pleaseSelectCustomerToReeemReward:
      "Seleccione un cliente para canjear la recompensa.",
    youHaveNoLoyaltyProgramRunning:
      "No tienes ningún programa de fidelización activo en ejecución",
    customerHhasNoPointsInLoyaltyProgram:
      "El cliente no tiene puntos en este programa de fidelización",
    proceedWithPayment: "¿Continuar con el pago?",
    youAreAboutToPayForTransactionUsingPoints:
      "Está a punto de pagar la transacción con puntos.",
    customerHas: "El cliente tiene",
    worth: "valer",
    andIsNotSufficientToPayForTransaction:
      "y no es suficiente para pagar esta transacción. ¿Querrían agregar el saldo utilizando otro método de pago?",
    addCustomerLoyalty: "Agregar lealtad del cliente",
    pleaseAddCustomerFirst: "Primero agregue o seleccione un cliente.",
    pleaseWaitWhileWeGetReady: "Por favor espera mientras nos preparamos",
    lowStock: "Stock bajo",
    pleaseEnterAmountTendered: "Ingrese el monto entregado",
    areYouSureToBookSaleOffline:
      "¿Está seguro de que desea reservar esta venta sin conexión?",
    saleWouldBeBookedAutomatically:
      "La venta se reservará automáticamente cuando encienda su Internet",
    offlineSalesBookingCancelled: "Reserva de ventas offline cancelada",
    covid19Message:
      "COVID19: Lávese las manos con jabón o desinfecte para detener la propagación. Mantente seguro siempre",
    saleSuccessfullyRecorded: "¡Venta registrada con éxito!",
    sendReceiptToEmail: "Enviar recibo a correo electrónico",
    sendThankYouSms: "Enviar SMS de agradecimiento",
    sendShippingDetails: "Enviar detalles de envío",
    addLoyalty: "Agregar lealtad",
    searchCustomerNameOrNumber: "Buscar nombre o número de cliente",
    clickTheSearchCustomerBox:
      "Haga clic en el cuadro Buscar cliente y escanear tarjeta",
    enterProductPrice: "Ingrese el precio del producto",
    enterPriceFor: "Ingrese el precio para",
    searchForProduct: "Buscar producto",
    all: "Todos",
    backToDashboard: "Volver al panel",
    viewDraftSales: "Ver borradores de ventas",
    variantSelected: "variante seleccionada",
    variant: "variante",
    thePreviousVariantSelectionNotAvailable:
      "La selección de variante anterior no está disponible para la nueva variante seleccionada según el precio, cambie su selección.",
    pleaseSupplyPositiveQuantityNumber:
      "Proporcione un número de cantidad positivo",
    lowStockFor: "Existencias bajas para",
    clearVariants: "Variantes claras",
    pleaseEnterQuantity: "Por favor ingrese la cantidad",
    picture: "Fotografía",
    redemptionToken: "Token de canje",
    token: "Simbólico",
    totalSpent: "Total gastado",
    confirmPayment: "Confirmar pago",
    hasPaymentBeenMade: "¿Se ha procesado correctamente el pago?",
    enterTransactionReference:
      "Ingrese la referencia de la transacción con la que pagó",
    pleaseSelectACustomer: "¡Seleccione un cliente!",
    areYouSureToApplyDiscount:
      "¿Estás seguro de que deseas aplicar el descuento?",
    addYourBankAccountToEnableUssd:
      "Agregue su cuenta bancaria para habilitar la transferencia instantánea de USSD por uPay",
    totalAmountToPay: "Monto total a pagar",
    doYouWantToCancelTransaction: "¿Quieres cancelar esta transacción?",
    savePrintBill: "Guardar / imprimir factura",
    enterAmountCollectedForCustomer: "Ingrese el monto cobrado para el cliente",
    recordSale: "Venta récord",
    checkOutWith: "Echa un vistazo con",
    instantTransfer: "Transferencia instantánea",
    dialTheUSSDCode: "Marque el código USSD",
    pleaseSelectABank: "Seleccione un banco",
    payWithUSSD: "Paga con USSD",
    sendBillTo: " - Enviar factura a",
    waitingForUSSDTransfer: "Esperando transferencia de USSD",
    percent: "Por ciento",
    applyDiscount: "Aplicar descuento",
    thisBillHasBeenSaved: "Esta factura se ha guardado",
    saveDraft: "Guardar borrador",
    pleaseTypeANameToIdentifyCustomer:
      "Escriba un nombre para identificar al cliente",
    paymentDetails: "Detalles del pago",
    basePrice: "Precio base",
    staff: "Personal",
    subTotal: "Total parcial",
    durationMonths: "Duración (meses)",
    selectADuration: "Seleccione una duración",
    oneMonth: "1 mes",
    twoMonths: "2 meses",
    threeMonths: "3 meses",
    sixMonths: "6 meses",
    twelveMonths: "12 meses",
    eighteenMonths: "18 meses",
    twentyFourMonths: "24 meses",
    twoMonthsFree: "(2 meses gratis)",
    threeMonthsFree: "(3 meses gratis)",
    fiveMonthsFree: "(5 meses gratis)",
    yourAccountHasBeen: "Tu cuenta ha sido",
    renewed: "renovado",
    upgraded: "actualizado",
    successfully: "exitosamente",
    yourSubscription: "Su suscripción",
    youAreCurrentlyEnrolledOnA: "Actualmente está inscrito en un",
    pleaseChooseAPaymentOption: "Elija una opción de pago",
    planRenewal: "Renovación del plan",
    planUpgrade: "Plan de actualización",
    pleaseSelectDurationToPayFor: "Seleccione la duración que desea pagar",
    staffAccounts: "Cuentas de personal",
    ecommerce: "Comercio electrónico",
    pleaseSelectAPlan: "Por favor seleccione un plan",
    includeAddons: "Incluir complementos",
    viewTransactions: "Ver transacciones",
    customerHasNoCompletedTansactions:
      "El cliente aún no tiene transacciones completadas",
    branch: "Rama",
    enterNumberOfEcommerceBranches:
      "Ingrese el número de sucursales de comercio electrónico",
    enterNumberOfEcommerceBranchesToPay:
      "Ingrese el número de sucursales de comercio electrónico por las que desea pagar",
    ecommerceIntegration: "Integración de comercio electrónico",
    enterNumberOfBranches: "Ingrese el número de sucursales",
    enterNumberOfAdditionalBranchesToPay:
      "Ingrese el número de sucursales adicionales por las que desea pagar",
    enterNumberOfStaffs: "Ingrese el número de personal",
    enterNumberOfStaffsToPayFor:
      "Ingrese el número de empleados que desea pagar",
    discountApplies: "Se aplica descuento",
    starsOnThe: "estrellas en el",
    offer: "oferta",
    get: "Obtener",
    moreStarsToRedeem: "más estrellas para canjear",
    taxVat: "Impuesto (IVA)",
    callCashierToCompletePayment: "Llame al cajero para completar el pago",
    receipt: "Recibo",
    dear: "querido",
    thankYouForYourOrderFindGoods:
      "Gracias por su orden. Encuentre los siguientes productos suministrados, según lo acordado.",
    shippingNote: "Nota de envío",
    enterShippingNote: "Ingrese la nota de envío",
    shippingAddress: "Dirección de Envío",
    enterShippingAddress: "Ingrese la dirección de envío",
    wellDoneYouAreDueToRedeem: "¡Bien hecho! Debes canjear",
    toGetYourRewardNextVisit:
      "para obtener su recompensa en su próxima visita. Gracias",
    pointsOnThe: "Puntos en el",
    morePointsToRedeem: "más puntos para canjear",
    showCode: "Mostrar código",
    toGetYourRewardOnNextVisit:
      "para obtener su recompensa en su próxima visita. Gracias",
    earn: "Ganar",
    delivaryNote: "Nota de entrega",
    draftSales: "Borrador de ventas",
    startDate: "Fecha de inicio",
    endDate: "Fecha final",
    orders: "Pedidos",
    checkout: "verificar",
    noProductItem: "Ningún artículo del producto",
    selectProductImage: "Seleccionar imagen de producto",
    selectCountry: "Seleccionar país",
    selectRegion: "Seleccionar estado / región",
    printProductTag: "Imprimir etiqueta de producto",
    transactionReference: "Referencia de transacción",
    Cashier: "Cajero",
    Manager: "Gerente",
    Owner: "Dueño",
    Admin: "Administración",
    addPartners: "Agregar socios",
    addNewLoyaltyPartner: "Agregar nuevo socio de lealtad",
    pleaseEnterCompanyName: "Ingrese el nombre de la empresa",
    companyName: "nombre de empresa",
    pleaseEnterCompanyRepName:
      "Ingrese el nombre del representante de la empresa",
    companyRepName: "Nombre del representante de la empresa",
    pleaseEnterCompanyRepEmail:
      "Ingrese el correo electrónico del representante de la empresa",
    companyRepEmail: "Correo electrónico del representante de la empresa",
    pleaseEnterCompanyRepPhone:
      "Ingrese el número de teléfono del representante de la empresa",
    companyRepPhone: "Número de teléfono del representante de la empresa",
    addReward: "Agregar recompensa",
    pleaseEnterRewardName: "Ingrese el nombre de la recompensa",
    rewardName: "Nombre de la recompensa",
    rewardQuantity: "Cantidad de recompensa",
    rewardDescription: "Descripción de la recompensa",
    rewardType: "Tipo de recompensa",
    pleaseEnterRewardType: "Ingrese el tipo de recompensa",
    pleaseEnterRewardQuantity: "Ingrese la cantidad de recompensa",
    pleaseEnterRewardDescription: "Ingrese la descripción de la recompensa",
    fineDining: "Buena cena",
    luxuryFashion: "Moda de lujo",
    hotels: "Hoteles",
    travel: "Viaje",
    foodAndBeverage: "Alimentos y bebidas",
    fashion: "Moda",
    health: "Salud",
    furniture: "Muebles",
    entertainment: "Entretenimiento",
    automobile: "Automóvil",
    education: "Educación",
    beautyAndSpa: "Belleza y Spa",
    staycation: "Staycation",
    events: "Eventos",
    trips: "Excursiones",
    oilAndGas: "Petróleo y gas",
    laundry: "Lavandería",
    partnerCategory: "Categoría de socio",
    freeItem: "Articulo libre",
  },
  Sundanese: {
    cashier: "kasir",
    manager: "gerentes",
    owner: "boga",
    online: "online",
    offline: "offline",
    changePassword: "Gentos kecap konci",
    currentPasswordMessage: "Mangga lebetkeun kecap akses anjeun ayeuna",
    passwordMessage: "Punten lebetkeun kata sandi anjeun",
    currentPassword: "Kecap konci Ayeuna",
    password: "kecap akses",
    confirmPasswordMessage: "Punten mastikeun kata sandi anjeun!",
    confirmPassword: "Pastikeun Kecap konci",
    sendViaEmail: "Kirim Ngalangkungan Email",
    sendViaWhatsapp: "Kirim Ngalangkungan WhatsApp",
    downloadPdf: "Unduh PDF",
    paid: "dibayar",
    unpaid: "teu dibayar",
    partial: "parsial",
    closeInvoice: "Naha anjeun hoyong nutup Invoice?",
    closeInvoiceConfirmation:
      "Invoice moal disimpen. Naha anjeun hoyong tutup?",
    yes: "enya",
    no: "henteu",
    invoice: "Invoice",
    wasDeducted: "dikurangan",
    for: "pikeun",
    item: "Barang",
    addProduct: "Tambihkeun Produk",
    paymentReference: "Rujukan Pamayaran",
    amountPaid: "Jumlah Mayar",
    discountAmount: "Jumlah diskon",
    amountDue: "Jumlah Hutang",
    amount: "Jumlah",
    dueDate: "Tanggal paling telat",
    paymentType: "Jenis pamayaran",
    card: "Kartu",
    cash: "Kontan",
    bankTransfer: "Transfer Bank",
    paymentMessage: "Pesen Pamayaran",
    description: "Katerangan",
    sendReceipt: "Kirim Resi",
    delete: "Mupus",
    save: "Ngahémat",
    resend: "Ngirim Deui",
    saveAndSend: "Ngahémat",
    invoiceSaved: "Invoice disimpen!",
    selectPaymentMethod: "Mangga pilih Métode Pamayaran!",
    selectCustomer: "Mangga pilih Palanggan!",
    cartEmptyError:
      "Daptar Gorobag henteu tiasa kosong, tutup faktur teras tambihan Item kana karanjang!",
    subscriptionExpired:
      "Langganan anjeun parantos kadaluarsa sareng akun anjeun ayeuna diwatesan. Pencét kana Tombol di handap pikeun nganyarkeun akun anjeun",
    renew: "Anyarkeun",
    holdOn: "Cekelan",
    customerBank: "Bank Palanggan",
    cancel: "Ngabatalkeun",
    selectACustomer: "Pilih Palanggan",
    invoiceSuccessfulPdfError:
      "Invoice hasil hasil tapi PDF Generation langkung lami tibatan biasana. Punten parios deui sakedap deui.",
    downloadingInvoice: "Ngundeur Invoice",
    downloadingReceipt: "Ngunduh Resi",
    whatsappReceiptError:
      "Aya kasalahan nalika ngirim resi ngalangkungan WhatsApp, Punten cobian deui.",
    receiptToWhatsapp: "Kuitansi diteruskeun ka WhatsApp",
    thankYouForPatronage: "Hatur nuhun kanggo bantosan anjeun",
    hereIsYourReceipt: "Ieu resi pembayaran anjeun",
    errorSendingEmailReceipt:
      "Aya kalepatan nalika ngirim resi ngalangkungan e-mail, mangga cobian deui atanapi kontak kontak",
    receiptSentToEmail: "Resi parantos dikirim kana email palanggan",
    invoiceSentToEmail: "Invoice parantos dikirim kana email palanggan",
    invoiceSuccessWhatsappError:
      "Invoice parantos hasil tapi aya kasalahan nalika ngirim ka WhatsApp. Cobian deui dina daptar faktur",
    invoiceSuccessfulEmailError:
      "Invoice parantos hasil tapi aya kasalahan nalika ngirim sakumaha e-mail. Mangga cobian deui tina daptar faktur",
    invoiceSentToWhatsapp: "Invoice diteruskeun ka WhatsApp",
    hello: "Halo",
    pleaseDownloadInvoice: "Punten unduh faktur",
    pleaseDownloadReceipt: "Punten unduh resi",
    from: "ti",
    email: "Surélék",
    upgrade: "Ngaronjatkeun",
    youAreOnFreePlan: "Anjeun dina Rencana Gratis.",
    clickOn: "Pencét kana",
    yourPlanInFewSteps: " langganan anjeun dina sababaraha léngkah gancang.",
    to: "ka",
    yourSubscriptionHasExpired:
      "Langganan anjeun parantos kadaluarsa sareng akun anjeun ayeuna diwatesan.",
    days: "poé",
    yourSubscriptionExpiresIn: "Loystar langganan anjeun kadaluarsa dina",
    createAcount: "Ngadamel akun",
    signIn: "Daptar",
    continue: "Teraskeun",
    enterOtp: "Lebetkeun OTP PIN",
    enterValidOtp: "Punten lebetkeun PIN anu valid",
    pin: "PIN",
    tokenSentToMail: "Token parantos dikirim ka email anjeun",
    passwordResetSuccessful: "Sandi ulang parantos suksés",
    somethingWentWrong: "Aya anu salah!",
    resetPassword: "Reset Sandi",
    iHaveResetCode: "Abdi ngagaduhan kode ngareset kata sandi",
    pleaseEnterEmail: "Punten lebetkeun email anjeun",
    aTokenWillBeSentToEmail: "Token bakal dikirim kana email anjeun",
    enterEmail: "Lebetkeun email anjeun",
    sendinYourToken: "Ngirimkeun token anjeun ...",
    makeSureItMatchesPassword:
      "Pastikeun éta cocog sareng kecap akses anyar anjeun",
    pleaseChooseNewPassword: "Punten pilih kecap akses énggal",
    chooseNewPassword: "Pilih kecap akses anyar",
    enterNewPassword: "Lebetkeun kecap konci anyar anjeun pikeun mastikeun",
    enterTokenSent: "Lebetkeun token anu dikirimkeun kana surat anjeun",
    resetToken: "Reset Token",
    resettingPassword: "Reset Sandi anjeun ...",
    signUp: "Daptar",
    adminSignInWithEmail:
      " Admin asup nganggo email bari Staff asup nganggo nami pangguna.",
    pleaseEnterEmailOrUsername: "Punten lebetkeun email atanapi nami pangguna",
    emailOrUsername: "Email atanapi Username",
    pleaseEnterPassword: "Punten lebetkeun kecap akses",
    createAnAccount: "Ngadamel akun",
    forgotPassword: "Hilap kecap konci?",
    enterPhoneNumber: "Lebetkeun Nomer Telepon",
    personalData: "Data Pribadi",
    validateConfirmationCOde: "Konpirmasi Konfirmasi Code",
    pleaseEnterFirstName: "Punten lebetkeun nami payun anjeun",
    pleaseEnterPhoneNumber: "Punten lebetkeun nomer telepon anjeun",
    pleaseEnterLastName: "Punten lebetkeun nami tukang anjeun",
    pleaseEnterBusinessName: "Punten lebetkeun nami bisnis anjeun",
    firstName: "Nami payun",
    lastName: "Nami pengker",
    businessName: "Ngaran Bisnis",
    previous: "Sateuacanna",
    next: "Teras",
    pleaseSelectBusinessCategory: "Mangga pilih kategori bisnis anjeun",
    pleaseEnterValidEmail: "Punten lebetkeun email anu leres",
    pleaseEnterPostCode: "Punten lebetkeun kode pos",
    postCode: "Kode Pos",
    phoneNumberInUse: "Nomer telepon ieu parantos dianggo!",
    emailInUse: "Email ieu parantos dianggo!",
    foodAndDrinks: "Dahareun sareng Inuman",
    salonAndBeauty: "Salon sareng Beauty",
    fashionAndAccessories: "Pantun sareng Asesoris",
    gymAndFitness: "Gym sareng Kabugaran",
    travelAndHotel: "Wisata sareng Hotél",
    homeAndGifts: "Bumi sareng Kado",
    washingAndCleaning: "Ngumbah sareng Ngabersihan",
    gadgetsAndElectronics: "Alat jeung Elektronika",
    groceries: "Barang balanjaan",
    others: "Anu sanésna",
    submit: "Kirimkeun",
    accountCreatedSuccessful: "Rekening anjeun parantos hasil didamel.",
    pleaseEnterAddress: "Punten Lebetkeun Alamat anjeun",
    addressLine1: "Alamat Baris 1",
    addressLine2: "Garis Alamat 2",
    choosePassword: "Milih Sandi",
    passwordMustBe6Characters: "Sandi kedah sahenteuna 6 karakter.",
    howDidYouHearLoystar: "Kumaha anjeun nguping ngeunaan Loystar?",
    referralCode: "Kode rujukan",
    byClickingTheButton: " Ku ngaklik tombol di handap, anjeun satuju kana",
    termsAndSevice: "Syarat",
    wereCreatingAccount: "Kami nuju ngadamel akun anjeun",
    proceed: "Teraskeun",
    verificationCodeMustBe6: "Kode Verifikasi kedah 6 angka",
    pleaseEnter6DigitCode: "Mangga Lebetkeun kode 6 angka",
    enterVerificationCode: "Lebetkeun Kodeu Verifikasi",
    resendToken: "Kirimkeun deui Token",
    verify: "Pariksa",
    transactions: "Transaksi",
    todaySales: "Penjualan dinten ieu",
    salesHistory: "Sejarah Penjualan",
    supplyHistory: "Riwayat Suplai",
    new: "Énggal",
    invoices: "Invois",
    disbursements: "Pangeluaran",
    offlineSales: "Penjualan offline",
    products: "Produk",
    customers: "Palanggan",
    multiLevelLoyalty: "Kasatiaan Multi-Level",
    loyaltyPrograms: "Program Kasatiaan",
    members: "Anggota",
    appStore: "App Store",
    orderMenu: "Pesen Menu",
    settings: "Setélan",
    staffAndBranches: "Staf sareng Cabang",
    myAccount: "Akun abdi",
    switchToSellMode: "Pindah kana Mode Jual",
    signOut: "Kaluar",
    getFreeSubscription: "Kéngingkeun Langganan Gratis",
    onlyNumbersAllowed: "Ngan nomer anu diijinkeun",
    yourAccountMustBe10Digits: "nomer akun anjeun kedah nomer 10 angka",
    yourBvnMustBe11: "BVN anjeun kedah nomer 11 angka",
    pleaseSelectBank: "Mangga pilih bank anjeun",
    selectYourBank: "Pilih Bank anjeun",
    enterBankAccountNumber: "Lebetkeun Nomer Rekening Bank",
    enterBvnNumber: "Lebetkeun BVN anjeun",
    connectBankAccount: "Sambungkeun Rekening Bank",
    passwordResetSuccessfulAndSignOut:
      "Sandi anjeun parantos direset kalayan suksés. Pencét tombol Sign Out di handap pikeun asup deui",
    enterCurrentPassword: "Lebetkeun kecap akses ayeuna",
    pleaseEnterCurrentPassword: "Punten lebetkeun kecap akses ayeuna",
    phoneNumber: "Nomer telepon",
    sex: "Kelamin",
    dateOfBirth: "Tanggal lahir",
    state: "Nagara",
    country: "Nagara",
    loyaltyId: "KTP kasatiaan",
    createdAt: "Didamel Di",
    noOfTransactionsMade: "Jumlah transaksi anu dilakukeun",
    yourDownloadWillStart: "Unduhan anjeun bakal dimimitian sakedap",
    exportCustomers: "Ékspor Palanggan",
    youhaveSuccessfullyToppedUp:
      "Anjeun parantos hasil nga-top up Unit SMS anjeun.",
    youNowHave: "Anjeun ayeuna gaduh",
    smsUnits: "Unit SMS",
    enterNumberOfSmsUnits: "Lebetkeun Jumlah Unit SMS Anu Hayang Dibeuli",
    pleaseEnterANumericValue: "Punten lebetkeun nilai numerik",
    pay: "Mayar",
    accountEditedSuccessfully: "Rekening anjeun hasil diédit.",
    youAeCurrentlyOn: "Anjeun ayeuna nuju",
    plan: "Rencana",
    userData: "Data Pamaké",
    businessData: "DATA BISNIS",
    businessCategory: "Usaha Catergory",
    pleaseSelectCurrency: "Punten pilih mata uang anjeun",
    selectYourCurrency: "Pilih mata uang anjeun",
    purchaseMoreSmsUnits:
      "Mésér langkung seueur Unit SMS nganggo formulir di handap",
    youHave: "Anjeun gaduh",
    atLeast4SmsUnitsRrequired:
      "Sahenteuna 4 unit sms diperyogikeun pikeun verifikasi, punten top up!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Punten verifikasi akun bank anjeun pikeun sasuai sareng sarat 'Know Your Customer' (KYC). Ieu bakal ngamungkinkeun anjeun ngumpulkeun pangmayaran liwat USSD atanapi transfer instan, nampi pesenan ti nasabah sareng ngolah transaksi. Lumaku pikeun padagang Nigeria waé. Punten pencét tombol di handap pikeun ngamimitian.",
    reConnectBankAccount: "Sambungkeun deui Rekening Bank",
    accountName: "Nami akun",
    accountNumber: "Nomer akun",
    bankName: "Ngaran Bank",
    verified: "Diverifikasi",
    accountDetails: "Rincian Akun",
    kycBankAccount: "KYC",
    createTier: "Nyiptakeun Tahap",
    fileUploadSuccessful: "file diunggah kalayan suksés",
    fileUploadFailed: "file unggah gagal",
    createLoyaltyProgram: "Nyiptakeun Program Kasatiaan",
    createLoyalty: "Nyiptakeun Kasatiaan",
    name: "Nami",
    loyaltyArtwork: "Kasatiaan Karya Seni",
    clickToUpload: "Pencét kanggo unggah",
    amountToPointsRatio: "Jumlah ka Rasio Poin",
    points: "Poin",
    recommendedAmountToPointRatio:
      "Disarankeun: ₦ 1 dugi 1 poin. ie pikeun unggal 1 Naira nyéépkeun, konsumén anjeun kéngingkeun 1 poin",
    pleaseTypeIn: "Punten ngetik",
    toDeleteLoyalty: "pikeun mupus kasatiaan ieu",
    deleteLoyalty: "Hapus Kasatiaan",
    toConfirm: "pikeun mastikeun",
    edit: "Édit",
    pointsAwardedSuccessfully: "Poin Dilélér Suksés.",
    enterPointValueToAward:
      "Lebetkeun Nilai Titik Anjeun Hoyong Ngadaptarkeun Pelanggan",
    award: "Pangajén",
    awardPointValuesToCustomer: "Nilai Titik Award pikeun Palanggan",
    enrollMembersToLoyalty: "Daptar Anggota kana Kasatiaan",
    awardPoints: "Poin Award",
    enroll: "Daptar",
    home: "Bumi",
    loyalty: "Kasatiaan",
    enrollCustomers: "Daptar Palanggan",
    selected: "Dipilih",
    customer: "Palanggan",
    loyaltyActivationSuccessful: "Aktip Kasatiaan suksés.",
    loyaltyDeactivationSuccessful: "Kasatiaan Deactivation suksés.",
    viewTier: "Tingali Tier",
    deactivate: "De-Aktipkeun",
    activate: "Aktipkeun",
    actions: "Kalakuan",
    nameOfLoyalty: "Ngaran Kasatiaan",
    loyaltyStatus: "Status Kasatiaan Status Kasatiaan",
    numberOfTiers: "Jumlah Tingkatan",
    createdOn: "Dijieun Dina",
    createATier: "Nyiptakeun Tingkat",
    stopCreatingTierConfirmation: "Naha anjeun hoyong lirén ngadamel tingkat?",
    stopEditingTierConfirmation: "Naha anjeun hoyong lirén ngédit tingkat ieu?",
    nameOfTier: "Ngaran Tier",
    minimumSpendingTarget: "Target Pembelanjaan Minimum",
    maximumSpendingTarget: "Target Maksimum Maksimum",
    minimumSpendingTargetRequired: "target pangeluaran minimum diperyogikeun",
    maximumSpendingTargetRequired: "target pengeluaran maksimal diperyogikeun",
    rewardSponsor: "Panghargaan Sponsor",
    pleaseChooseARewardSponsor: "Punten pilih sponsor panghargaan",
    self: "Diri",
    partner: "Rakan",
    rewardPartner: "Ganjaran Rakan",
    pleaseSelectRewardPartner: "Punten pilih pasangan hadiah anjeun",
    rewards: "Ganjaran",
    pleaseSelectAReward: "Punten pilih hadiahna",
    instructionsOnRedeemingReward:
      "Pitunjuk ngeunaan Kumaha Palanggan Kedah Ngaleupaskeun Ganjaran",
    pleaseFillInThisField: "Punten Eusian Widang ieu!",
    toDeleteThisTier: " pikeun mupus undakan ieu",
    deleteTier: "Pupus tingkat",
    viewMembers: "Tingali Anggota",
    membersEnrolled: "Anggota Daptar",
    instruction: "Pitunjuk",
    membersIn: "Anggota di",
    availableTiersInLoyalty: "Tingkat Tersedia dina Program Kasatiaan",
    tiers: "Tingkatan",
    totalTier: "JUMLAH TIER",
    availableLoyaltyProgramme: "Program Kasatiaan anu Sayogi",
    totalLoyalties: "JUMLAH SÉRÉNGAL",
    memberDetails: "Rincian Anggota",
    nameOfCustomer: "Ngaran Palanggan",
    address: "Alamat",
    allEnrolledMembers: "Sadayana Anggota Anu Daptar",
    thisIsToWishYouHappyBirthday:
      "Ieu kanggo ngaharepkeun anjeun ulang taun anu bagja pisan sareng kahirupan anu sejahtera. Hatur nuhun pikeun sadayana anu anjeun ka Loyster. Bingah Perayaan!",
    inputAnOfferPlease: "Masihan tawaran punten",
    pleaseSelectTheInsertPoint:
      "Mangga pilih titik sisipan dina pesen teras pencét deui",
    birthdayOfferAndMessage: "Tawaran sareng Pesen Ulang Tahun",
    birthdayOffer: "Tawaran Ulang taun",
    birthdayMessage: "Pesen Ulang Tahun",
    noOfferFound: "Teu aya tawaran",
    settingABirthdayOffer:
      "Nyetél tawaran ulang taun ngamungkinkeun para nasabah nampi panawaran ieu ngalangkungan SMS nalika ulang taunna",
    createOffer: "Nyiptakeun Tawaran",
    whatIsTheOffer: "Naon tawaran na?",
    editMessage: "Édit Pesen",
    insert: "Lebetkeun",
    theNameOfCustomerInserted: "Ngaran palanggan bakal dilebetkeun di dieu",
    theBirtdayOfferInserted: "Tawaran ulang taun bakal dilebetkeun di dieu",
    youSuccessfullyAddedNewBranch:
      "Anjeun parantos hasil nambihan cabang anyar!",
    addNewBranch: "Tambahkeun Cabang Anyar",
    addBranch: "Tambahkeun Cabang",
    additionalBranchWillIncur: "Cabang tambihan bakal nandakeun",
    perBranch: "per cabang",
    ecommerceBranchCosts: "Waragad Cabang E-niaga",
    pleaseEnterBranchName: "Punten lebetkeun nami cabangna",
    pleaseEnterBranchAddress1: "Mangga lebetkeun alamat alamat cabang 1",
    enterBranchAddress1: "Lebetkeun garis alamat cabang 1",
    enterBranchAddress2: "Lebetkeun garis alamat cabang 1",
    pleaseEnterBranchAddress2: "Punten lebetkeun alamat alamat cabang 2",
    enterBranchName: "Lebetkeun nami dahan",
    successfullyAddedStaff: "Anjeun parantos hasil nambihan staf anyar!",
    addNewStaff: "Tambahkeun Staf Anyar",
    addStaff: "Tambihkeun Staff",
    additionalStaffWillIncur: "Staf tambahan bakal ngahasilkeun",
    perStaff: "per staf.",
    pleaseEnterStaffEmail: "Punten lebetkeun email staffna",
    pleaseEnterStaffUsername: "Punten lebetkeun nami pangguna",
    pleaseEnterStaffPassword: "Punten lebetkeun kata sandi staffna",
    pleaseSelectStaffRole: "Punten pilih kalungguhan staff na",
    selectStaffRole: "Pilih peran stafna",
    enterStaffEmail: "Lebetkeun email staffna",
    enterStaffUsername: "Lebetkeun nami pangguna",
    enterStaffPassword: "Lebetkeun kecap konci staffna",
    spacesNotAllowedInUsername: "rohangan henteu kénging dina nami pangguna",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Punten pilih usaha kanggo ngagantelkeun padamel",
    searchForBranchToAttachStaff: "Milarian cabang pikeun ngagantelkeun staf",
    username: "Ngaran pamaké",
    role: "Peran",
    areYouSureToDeleteThis: "Naha anjeun pasti bakal ngahapus ieu",
    branches: "Cabang",
    upgradeYourPlan: "Ningkatkeun Rencana anjeun.",
    add: "NAMBAHKEUN",
    addNew: "Tambihkeun Anyar",
    customerWithEmailAlreadyExists:
      "Palanggan kalayan nomer email / telepon parantos aya!",
    successfullyAddedNewCustomer:
      "Anjeun parantos hasil nambihan palanggan énggal!",
    addCustomer: "Tambahkeun Palanggan",
    pleaseEnterCustomerFirstName: "Punten lebetkeun nami hareup nasabah",
    pleaseEnterCustomerLastName: "Punten lebetkeun nami tukang konsumén",
    pleaseEnterCustomerPhoneNumber: "Punten lebetkeun nomer telepon pelanggan",
    pleaseEnterCustomerEmail: "Punten lebetkeun email palanggan",
    pleaseEnterCustomerAddressLine: "Mangga lebetkeun Alamat Alamat pelanggan",
    pleaseEnterCustomerOtherAddress:
      "Mangga lebetkeun Alamat anu sanés palanggan",
    pleaseSelectCustomerGender: "Punten pilih jinisna konsumén",
    gender: "Jenis kelamin",
    male: "Lalaki",
    female: "Awéwé",
    bank: "Bank",
    selectBank: "Pilih Bank",
    stateOrRegionOrProvince: "Nagara / Daérah / Propinsi",
    customerNotes: "Catetan Palanggan",
    sendSms: "Kirim SMS",
    editCustomer: "Ngédit Palanggan",
    redeemReward: "Ngaleungitkeun Pahala",
    issueLoyaltyCard: "Ngaluarkeun Kartu Kasatiaan",
    deleteCustomer: "Hapus Palanggan",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Anjeun parantos hasil Nugaskeun ID Kaanggotaan Kasatiaan",
    noMembershipIdAvailable:
      "Teu aya kaanggotaan ID. Mangga kontak kami di hello@loystar.co",
    sendEmail: "Kirim Email",
    membershipPoints: "Poin Kaanggotaan",
    customerDetails: "Rincian Palanggan",
    close: "Tutup",
    loyaltyBalance: "Kasatiaan Kasaimbangan",
    pointsBalance: "Kasaimbangan poin",
    starBalance: "Kasaimbangan Star",
    requiredPoints: "Poin anu diperyogikeun",
    requiredStars: "Béntang anu diperyogikeun",
    reddemCode: "Kodeu Redeem",
    toDeleteThisCustomer: "mupus konsumén ieu",
    customerHasBeenDeletedSuccessfully: "Palanggan parantos hasil dihapus!",
    customerWithPhoneAlreadyExists:
      "Palanggan kalayan nomer telepon parantos aya",
    customerWasSuccessfullyEdited: "Palanggan parantos hasil diédit",
    anErrorOccured: "Aya kasalahan",
    phoneNumberIncludeCountry: "Nomer Telepon (Kalebet Kodeu Nagara)",
    yourFileQueuedForUpload:
      "File anjeun parantos ngantri unggah. Mangga segerkeun halaman saatos sababaraha detik.",
    thereWasAnErrorPerformingOperation:
      "Aya kasalahan nalika ngalakukeun operasi!",
    pleaseSelectFile: "Punten Pilih File!",
    oopsOnlyCsvFilesAllowed:
      "Duh! Ngan ukur file CSV anu diijinkeun. Punten unggah file .csv.",
    fileShouldBeSmallerThan5Mb:
      "File kedah langkung alit tibatan 5MB. Upami anjeun gaduh file anu langkung ageung, punten email ka support@loystar.co. hatur nuhun",
    customerFirstNameIsRequired:
      "ngaran hareup nasabah diperyogikeun dina urutan",
    customerPhoneNumberIsRequired:
      "nomer telepon nasabah diperyogikeun dina urutan",
    importCustomers: "Palanggan Impor",
    upload: "Unggah",
    clickIconToDownloadCsv: "Pencét ikon ieu pikeun ngaunduh témplat file CSV.",
    getGoogleSheetVersion: "Kéngingkeun vérsi lambar Google",
    clickOrDragFileToUpload:
      "Pencét atanapi sered file ka daérah ieu kanggo unggah",
    missingOrInvalidColumnHeader:
      "Lulugu kolom leungit atanapi henteu valid. Punten tuturkeun format témplatna. Hatur nuhun.",
    youHaveImported: "Anjeun parantos Ngimpor",
    youSuccessfullyRedeemedReward: "Anjeun parantos hasil nebus hadiah anjeun!",
    sixDigitCode: "Kodeu genep angka",
    pleaseEnterCustomerRewardCode: "Mangga lebetkeun kode ganjaran palanggan",
    enterRewardCode: "Lebetkeun kode ganjaran. (Kodeu ganjaranana sénsitip)",
    selectLoyaltyProgram: "Pilih program kasatiaan",
    stamps: "Parangko",
    smsUnitsLow: "Unit SMS Lemah",
    pleaseTopUpSmsUnits: "Punten Top Up Unit SMS",
    smsSuccessfullySentForDelivery: "SMS hasil dikirim pikeun pangiriman!",
    sendSmsTo: "Kirim SMS ka",
    allCustomers: "Sadaya Palanggan",
    unitsAvailable: "Unit Sadia",
    pleaseTypeInTheMessage: "Punten ketik pesen na",
    message: "Pesen",
    charactersRemaining: "karakter sésana",
    avoidUseOfSpecialCharacters:
      "Nyingkahan panggunaan Karakter Husus sareng Emojis pikeun ngahémat Unit SMS.",
    note: "Catetan",
    errorFetchingCustomersMultilevelDetail:
      "Éror Ngala Palanggan Detil MultiLevel",
    search: "Milarian",
    reset: "Reset",
    importCustomer: "Palanggan Impor",
    addNewCustomer: "Tambahkeun Palanggan Anyar",
    sendSmsBroadcast: "Kirim Panyiaran SMS",
    totalCustomers: "JUMLAH PELANGGAN",
    disbursementDetails: "Rincian Pembayaran",
    paidBy: "Dibayar Ku",
    disbursed: "Dikaluarkeun",
    bankAccountName: "Ngaran Rekening Bank",
    bankAccountNumber: "Nomer Rekening Bank",
    transferInitiated: "Mindahkeun Dimimitian",
    transferCompleted: "Transfer Rengse",
    pleaseEnterAValid: "Punten lebetkeun anu leres",
    begin: "mimiti",
    end: "tungtung",
    date: "kaping",
    paymentDate: "Tanggal Pamayaran",
    selectDisbursementDuration: "Pilih Durasi Pembayaran",
    totalSettled: "Total Padumukan",
    totalUnsettled: "Total Henteu Masihan",
    toDeleteThisSale: "pikeun mupus penjualan ieu",
    draftSaleDeletedSuccessfully: "Draft Sale parantos hasil dihapus!",
    deleteSale: "Hapus Jualan",
    pleaseTypeInYourTotal:
      "Punten ngetik dina total anjeun pikeun mastikeun ngahapus",
    billDetails: "Rincian Bill",
    printBill: "RUU Cetak",
    servedBy: "Dilayanan ku",
    total: "Total",
    createdDate: "Tanggal Didamel",
    createdTime: "Waktos Didamel",
    status: "Statusna",
    loginSuccessful: "Login suksés",
    pleaseWaitWhileWeConnectAccount:
      "Punten antosan nalika urang nyambungkeun akun anjeun",
    connectionFailedTryAgain: "Sambungan gagal. Punten cobian deui.",
    connectionSuccessful: "Sambungan Suksés",
    viewDetails: "Tingali Rincian",
    enable: "Aktipkeun",
    free: "Gratis",
    cost: "Waragad",
    visitWebsite: "Didatangan Website",
    pleaseUpgradeYourPlanToPro:
      "Punten Tingkatkeun rencana anjeun ka Pro Plus kanggo ngaktipkeun aplikasi ieu",
    connectYourBankAccount:
      "Sambungkeun Akun Bank anjeun pikeun tiasa nampi pamayaran.",
    disable: "Pareuman",
    enableApp: "Aktipkeun App",
    addNewProductToInvoice: "Tambahkeun Produk Anyar pikeun Invoice",
    toDeleteThisInvoice: "pikeun mupus Invoice ieu",
    invoiceDeletedSuccessfully: "Invoice parantos hasil dihapus!",
    deleteInvoice: "Hapus Invoice",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Anjeun parantos hasil nambihan pesen pamayaran Invoice!",
    pleaseEnterPaymentInstructions: "Punten lebetkeun pitunjuk pamayaran",
    invoiceId: "KTP invoice",
    paidDate: "Tanggal mayar",
    reference: "Rujukan",
    productAdded: "Produk ditambih",
    productOutOfStock: "Produk kaluar tina saham. Punten stok deui.",
    totalInvoices: "TOTAL INVOISI",
    totalPaidInvoices: "JUMLAH INVOISI DIBAYAR",
    totalUnpaidInvoices: "TOTAL INVOISI UNPAID",
    loyaltyProgramDeleted: "Program Kasatiaan Pupus",
    thisLoyaltyProgramDeletedSuccessfully:
      "Program kasatiaan ieu parantos hasil dihapus",
    thisLoyaltyProgramEditedSuccessfully:
      "Program kasatiaan ieu parantos hasil diédit",
    loyaltyProgramAddedSuccessfully:
      "Program kasatiaan parantos ditambihan hasil",
    loyaltyProgramEdited: "Program Kasatiaan Diédit",
    loyaltyProgramAdded: "Program Kasatiaan Ditambahkeun",
    loyaltyDetails: "Rincian Kasatiaan",
    doYouWantToCloseDialog: "Naha anjeun hoyong nutup dialog ieu?",
    pleaseEnterLoyaltyName: "Punten lebetkeun nami kasatiaan anjeun",
    programType: "Jenis Program",
    pleaseSelectProgramType: "Punten pilih jinis program",
    simplePoints: "Poin Sederhana",
    stampsProgram: "Program Parangko",
    threshold: "Ambang",
    pleaseEnterLoyaltyThreshold: "Punten lebetkeun ambang kasatiaan",
    reward: "Balesan",
    pleaseEnterLoyaltyReward: "Punten lebetkeun pahala tina kasatiaan",
    totalUserPoints: "Total Poin Pamaké",
    totalUserStamps: "Total Parangko Pamaké",
    spendingTarget: "Target pengeluaran",
    spendingTargetHint1:
      "Targét pangeluaran mangrupikeun palanggan anu kedah dibalanjakeun pikeun kéngingkeun pahala. 1 nilai mata uang = 1 titik.",
    spendingTargetHint2:
      "Targét perangko nyaéta sabaraha perangko anu kedah dikumpulkeun konsumén pikeun kéngingkeun pahala. mis. 5",
    addNewLoyaltyProgram: "Tambihkeun Program Kasatiaan Anyar",
    addLoyaltyProgram: "Tambihkeun Program Kasatiaan",
    loyaltyProgramType: "Jinis Program Kasatiaan",
    pleaseSelectLoyaltyProgramType: "Punten pilih jinis program kasatiaan",
    nameOfProgram: "Nami program",
    pleaseEnterProgramName: "Punten lebetkeun nami program",
    whatIsTheReward: "Naon hadiahna?",
    egNextPurchaseIsFree: "Misalna Mésér salajengna gratis",
    customerName: "Ngaran Palanggan",
    guestCustomer: "Palanggan Tamu",
    orderConfirmedSuccessfully: "Pesenan dikonfirmasi hasil",
    orderCancelledSuccessfully: "Pesenan dibolaykeun hasil",
    confirmOrder: "Mastikeun urutan",
    cancelOrder: "Batal mesen",
    allOrders: "Sadaya Pesenan",
    totalOrders: "JUMLAH PESANAN",
    doYouWantToAcceptOrder: "Naha anjeun badé nampi paréntah ieu?",
    doYouWantToCancelOrder: "Naha anjeun badé ngabatalkeun pesenan ieu?",
    orderDetails: "Rincian Pesenan",
    orderCreatedAt: "Pesenan didamel di",
    supplier: "Panyekel",
    productName: "Ngaran Produk",
    quantity: "Jumlah",
    unitPrice: "Harga hijian",
    receivedBy: "Ditampi Ku",
    reportFrom: "Laporan ti",
    totalSupplies: "Total Suplai",
    allRightsReserved: "Sadaya hak disimpen",
    toDeleteThisTransaction: "mupus Transaksi ieu",
    transactionDeletedSuccessfully:
      "Transaksi parantos hasil dihapus. Saham parantos dipulangkeun ka inventarisasi.",
    deleteTransaction: "Hapus Transaksi",
    transactionDetails: "Rincian Transaksi",
    printReceipt: "Resi citak",
    channel: "Saluran",
    discount: "Diskon",
    profit: "Kauntungan",
    discountedSales: "Penjualan diskon",
    errorFetchingRecord: "Kasalahan Ngukut Data",
    exportTransactions: "Transaksi Ékspor",
    errorFetchingSalesRecord: "Éror nyandak catetan Penjualan pikeun Ékspor.",
    totalSellingPrice: "Harga Jual Total",
    totalCostPrice: "Harga Harga Total",
    appliedDiscount: "Diskon Terapan",
    noOfItems: "Jumlah barang",
    sales: "Penjualan",
    export: "Ékspor",
    totalProfit: "Total Kauntungan",
    totalBalanceInPeriod: "Total Kasaimbangan dina Periode",
    allTimeSales: "Penjualan Sadaya Waktos",
    records: "Rékaman",
    todaysSales: "Penjualan dinten ieu",
    transaction: "urus",
    youHaveExceededTotalNumberOfProducts:
      "Anjeun parantos ngaleuwihan total jumlah produk anu diidinan dina rencana anjeun. Ningkatkeun rencana anjeun pikeun nikmati wates anu langkung luhur.",
    youNeedToHaveLoyaltyProgram:
      "Anjeun Kedah gaduh Program Kasatiaan pikeun nganggo fitur ieu!",
    price: "Harga",
    category: "Kategori",
    stockTracking: "Nyukcruk Stock",
    stockCount: "Itungan saham",
    taxed: "Disambungkeun",
    originalPrice: "Harga Asli",
    costPrice: "Harga Ongkos",
    unit: "Unit",
    productImage: "Gambar Produk",
    taxRate: "Tarif Pajak",
    taxType: "Jenis Pajak",
    trackInventory: "Inventory lagu",
    variants: "Varian",
    hasVariants: "Boga Varian",
    importProduct: "Produk Impor",
    exportProducts: "Ékspor Produk",
    addNewProduct: "Tambahkeun Produk Anyar",
    viewCategory: "Tingali Kategori",
    viewSuppliers: "Tingali Suplier",
    receiveInventory: "Nampi Inventory",
    printAllProductsTag: "Nyitak Sadaya Tag Produk",
    deleteAll: "Hapus Sadayana",
    totalProducts: "JUMLAH PRODUK",
    youveSuccessfullyAddedANewCategory:
      "Anjeun parantos hasil nambihan katégori anyar",
    addNewCategory: "Tambihkeun Kategori Anyar",
    addCategory: "Tambihkeun Kategori",
    categoryName: "Kategori Ngaran",
    pleaseEnterCategoryName: "Punten lebetkeun nami kategori",
    stampsTarget: "Target Parangko",
    sendInventory: "Kirim Inventory",
    productDetails: "Rincian Produk",
    youveSuccessfullyEditedThisCategory:
      "Anjeun parantos hasil ngédit katégori ieu",
    update: "Ngamutahirkeun",
    categoryList: "Daptar katégori",
    categories: "Kategori",
    enterQuantityToUpdate: "Lebetkeun jumlah pikeun ngapdet",
    inventorySentSuccessfully: "Inventarisasi parantos hasil dikirim!",
    updateInventory: "Inventory Perbarui",
    currentQuantity: "Kuantitas Ayeuna",
    pleaseEnterQuantityToAdd:
      "Mangga lebetkeun kuantitas anu anjeun hoyong tambahkeun",
    pleaseSelectABranch: "Punten pilih Cabang",
    searchForBranch: "Milarian cabang",
    youCantSendMoreThanStock:
      "Anjeun teu tiasa ngirim langkung seueur tina saham",
    send: "Ngirim",
    pleaseEnterQuantityToSend: "Punten lebetkeun jumlah anu badé dikirim",
    productNameIsRequiredOnRow: "nami produk diperyogikeun dina urutan",
    productCategoryIsRequiredOnRow: "kategori produk diperyogikeun dina urutan",
    productPriceIsRequiredOnRow: "harga produk diperyogikeun dina urutan",
    productUnitIsRequiredOnRow: "unit produk diperyogikeun dina urutan",
    productQuantityIsRequiredOnRow:
      "kuantitas produk diperyogikeun dina urutan",
    productVariantsRequireTracking: "varian produk peryogi dilacak!",
    pleaseAddVariantClickButton:
      "Mangga tambihan varian ku ngaklik tombol tambihan!",
    totalVariantsMoreThanSelectedQuantity:
      "Total Varian langkung seueur tibatan jumlah produk anu dipilih, Pls ngirangan jumlah varian",
    productEditedSuccessfully: "Produk parantos hasil diédit!",
    fileTooLargeLessThan4Mb:
      "Ukuran file ageung teuing! Ukuran file kedahna kirang ti 4MB.",
    suchVariantAlreadyExist: "Varian sapertos kitu parantos aya",
    addVariants: "Tambihkeun Varian",
    editProduct: "Edit Produk",
    pleaseEnterProductName: "Mangga Lebetkeun Nami Produk",
    pleaseEnterProductPrice: "Punten Lebetkeun Harga Produk",
    pleaseEnterProductOriginalPrice: "Punten Lebetkeun Harga Asli Produk",
    productDescription: "Panjelasan Produk",
    selectProductCategory: "Pilih Kategori Produk",
    pleaseSelectProductCategory: "Mangga pilih kategori produk",
    productCostPrice: "Harga Biaya Produk",
    productSellingPrice: "Harga Ngajual Produk",
    productOriginalPrice: "Harga Asli Produk",
    maxFileSizeAllowedIs4Mb: "Max. Ukuran File anu diidinan nyaéta 4mb",
    productsWithPicturesArePublished:
      "Produk kalayan gambar diterbitkeun dina Discover pikeun nampi pesenan",
    shouldThisProductBeTracked: "Naha produk ieu dilacak?",
    pleaseSelectStockUnit: "Mangga pilih unit saham",
    stockUnit: "Unit Saham",
    bag: "BAG",
    bottle: "BOTOL",
    bunch: "BUNCH",
    can: "BISA",
    carton: "KARTON",
    crate: "Peti",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "Pék",
    pair: "PASAR",
    pieces: "PIECES",
    plate: "Piring",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "Kebon",
    pleaseEnterProductQuantity: "Punten lebetkeun kuantitas produk",
    productQuantity: "Jumlah Produk",
    isThisProductTaxed: "Naha produk ieu kena pajak?",
    selectTaxType: "Pilih Jenis Pajak",
    pleaseSelectTaxType: "Mangga pilih Tipe Pajak",
    progressive: "Maju",
    proportional: "Sabanding",
    pleaseEnterProductTaxRate: "Mangga lebetkeun tingkat pajak produk",
    doesProductHaveVariants: "Naha produk ieu ngagaduhan varian?",
    type: "Jenis",
    value: "Nilai",
    pleaseEnterVariantType: "Mangga lebetkeun jinis varian na",
    pleaseEnterVariantValue: "Mangga lebetkeun nilai varian",
    pleaseEnterVariantPrice: "Mangga lebetkeun harga varian",
    pleaseEnterVariantQuantity: "Punten lebetkeun jumlah varian",
    productDeletedSuccessfully: "Produk parantos hasil dihapus!",
    categoryDeletedSuccessfully: "Kategori parantos hasil dihapus!",
    toDeleteThisProduct: "pikeun mupus Produk ieu",
    invalidProductQuantity: "Kuantitas Produk henteu valid",
    quantityAddedIsOverStock:
      "Kuantitas anu anjeun nambihan langkung tina naon anu anjeun gaduh saham.",
    itemInventoryNotTracked: "Inventaris barang henteu dilacak",
    addBulkQuantity: "Tambahkeun Jumlah Bulk",
    enterBulkQuantity: "Lebetkeun Jumlah Massal",
    pleaseEnterBulkQuantity: "Punten lebetkeun Jumlah Massal",
    youveSuccessfullyAddedANewProduct:
      "Anjeun parantos hasil nambihan produk énggal!",
    pleaseEnterProductSellingPrice: "Punten lebetkeun harga jual produk",
    doYouWantToTrackProductStock: "Naha anjeun hoyong ngalacak stok produk?",
    sellingPrice: "Ngajual Harga",
    setProductExpiryReminder: "Atur Peringatan Kadaluareun Produk",
    productExpiryDate: "Tanggal béakna Produk",
    startRemindingFrom: "Mimitian Ngingetan Ti",
    productSuppliesAddedSuccessfully:
      "Anjeun parantos nambihan pasokan produk.",
    addProductSupplies: "Tambihkeun Suplai Produk",
    pleaseSelectSupplier: "Punten Pilih Suplier",
    nameOfProduct: "Ngaran Produk",
    pleaseSelectProduct: "Punten Pilih Produk",
    productVariant: "Varian Produk",
    pleaseSelectAVariant: "Punten Pilih Varian",
    pleaseEnterUnitPrice: "Punten Lebetkeun Harga Satuan",
    businessBranch: "Cabang Bisnis",
    pleaseSelectBranch: "Punten pilih cabang",
    youveSuccessfullyAddedANewSupplier:
      "Anjeun parantos hasil nambihan panyadia énggal",
    addSupplier: "Tambihkeun Suplier",
    pleaseEnterSupplierEmail: "Punten lebetkeun email pemasok",
    pleaseAddADescription: "Punten nambihan katerangan",
    anErrorOccuredProductsDeleted:
      "Aya kasalahan nalika ngalakukeun operasi. Punten perhatoskeun, sababaraha produk panginten parantos dihapus dina prosés na",
    bulkDelete: "Massal Hapus",
    youAreAboutToDelete: "Anjeun badé ngahapus",
    product: "Produk",
    isDueToRedeem: "nyaéta alatan ditebus",
    aReward: "hadiah",
    pleaseSelectCustomerToReeemReward:
      "Punten pilih palanggan kanggo ngagentos hadiah.",
    youHaveNoLoyaltyProgramRunning:
      "Anjeun teu ngagaduhan program kasatiaan aktip",
    customerHhasNoPointsInLoyaltyProgram:
      "Palanggan henteu ngagaduhan poin dina program kasatiaan ieu",
    proceedWithPayment: "Lumangsungna sareng Pamayaran?",
    youAreAboutToPayForTransactionUsingPoints:
      "Anjeun badé mayar transaksi nganggo poin.",
    customerHas: "Palanggan Ngagaduhan",
    worth: "hargana",
    andIsNotSufficientToPayForTransaction:
      "sareng henteu cekap kanggo mayar transaksi ieu. Naha aranjeunna badé nambihan kasaimbangan nganggo metode pamayaran anu sanés?",
    addCustomerLoyalty: "Tambahkeun Kasatiaan Palanggan",
    pleaseAddCustomerFirst: "Punten nambihan atanapi pilih palanggan heula.",
    pleaseWaitWhileWeGetReady: "Punten antosan, bari urang siap-siap",
    lowStock: "Saham Rendah",
    pleaseEnterAmountTendered: "Mangga lebetkeun jumlah tendered",
    areYouSureToBookSaleOffline:
      "Naha anjeun yakin rék buku penjualan ieu offline?",
    saleWouldBeBookedAutomatically:
      "Dijual bakal dipesen sacara otomatis nalika anjeun ngahurungkeun internét",
    offlineSalesBookingCancelled: "Pesenan penjualan offline dibatalkeun",
    covid19Message:
      "COVID19: Ngumbah panangan nganggo sabun atanapi Sanitasi pikeun ngeureunkeun panyebaran. Tetep Aman Salawasna",
    saleSuccessfullyRecorded: "Dijual hasil Dirékam!",
    sendReceiptToEmail: "Kirim resi kana Email",
    sendThankYouSms: "Kirimkeun hatur nuhun SMS",
    sendShippingDetails: "Kirimkeun detil Pengiriman",
    addLoyalty: "Tambihkeun Kasatiaan",
    searchCustomerNameOrNumber: "Milarian nami atanapi nomer palanggan",
    clickTheSearchCustomerBox:
      "Pencét Kotak Pilarian Pilarian sareng Kartu Scan",
    enterProductPrice: "Lebetkeun Harga Produk",
    enterPriceFor: "Lebetkeun Harga pikeun",
    searchForProduct: "Milarian produk",
    all: "Sadayana",
    backToDashboard: "Balik kana Dashboard",
    viewDraftSales: "Tingali Draf Penjualan",
    variantSelected: "varian dipilih",
    variant: "varian",
    thePreviousVariantSelectionNotAvailable:
      "Pilihan varian sateuacanna henteu sayogi pikeun varian anyar anu dipilih dumasar kana harga, punten robih pilihan anjeun.",
    pleaseSupplyPositiveQuantityNumber: "Punten pasihkeun nomer jumlah posif",
    lowStockFor: "Stock low pikeun",
    clearVariants: "Jelas varian",
    pleaseEnterQuantity: "Punten lebetkeun Kuantitas",
    picture: "Gambar",
    redemptionToken: "Token Panebusan",
    token: "Token",
    totalSpent: "Total Méakkeun",
    confirmPayment: "Konpirmasi Pamayaran",
    hasPaymentBeenMade: "Naha Pamayaran parantos diolah sacara suksés?",
    enterTransactionReference: "Lebetkeun rujukan transaksi anjeun mayar",
    pleaseSelectACustomer: "Mangga pilih konsumén!",
    areYouSureToApplyDiscount: "Naha anjeun yakin hoyong nerapkeun diskon?",
    addYourBankAccountToEnableUssd:
      "Tambahkeun rekening bank anjeun pikeun ngaktipkeun Transfer USSD Instan ku uPay",
    totalAmountToPay: "Total Jumlah kanggo Mayar",
    doYouWantToCancelTransaction:
      "Naha anjeun badé ngabatalkeun transaksi ieu?",
    savePrintBill: "Simpen / Cetak Bill",
    enterAmountCollectedForCustomer:
      "Lebetkeun jumlah anu dikumpulkeun pikeun konsumén",
    recordSale: "Penjualan Rékam",
    checkOutWith: "Pariksa kalayan",
    instantTransfer: "Transfer Sakedap",
    dialTheUSSDCode: "Pencét kode USSD",
    pleaseSelectABank: "Punten pilih bank",
    payWithUSSD: "Mayar Kalayan USSD",
    sendBillTo: " - Kirim Bill ka",
    waitingForUSSDTransfer: "Ngantosan Transfer USSD",
    percent: "Persén",
    applyDiscount: "Larapkeun diskon",
    thisBillHasBeenSaved: "RUU ieu parantos disimpen",
    saveDraft: "Simpen Draf",
    pleaseTypeANameToIdentifyCustomer:
      "Punten ngetik nami kanggo ngaidentipikasi palanggan na",
    paymentDetails: "Rincian pamayaran",
    basePrice: "Harga Dasar",
    staff: "Patugas",
    subTotal: "SubTotal",
    durationMonths: "Durasi (bulan)",
    selectADuration: "Pilih Durasi",
    oneMonth: "1 Bulan",
    twoMonths: "2 Bulan",
    threeMonths: "3 Bulan",
    sixMonths: "6 Bulan",
    twelveMonths: "12 Bulan",
    eighteenMonths: "18 Bulan",
    twentyFourMonths: "24 Bulan",
    twoMonthsFree: "(Gratis 2 Bulan)",
    threeMonthsFree: "(Gratis 3 Bulan)",
    fiveMonthsFree: "(Gratis 5 Bulan)",
    yourAccountHasBeen: "Rekening anjeun parantos",
    renewed: "diperbaharui",
    upgraded: "ditingkatkeun",
    successfully: "hasil",
    yourSubscription: "Langganan Anjeun",
    youAreCurrentlyEnrolledOnA: "Anjeun nuju didaptarkeun dina a",
    pleaseChooseAPaymentOption: "Punten Milih Pilihan Pamayaran",
    planRenewal: "Rencana Pembaharuan",
    planUpgrade: "Rencana Ngaronjatkeun",
    pleaseSelectDurationToPayFor: "Punten Pilih Durasi anu anjeun badé mayar",
    staffAccounts: "Rekening Staf",
    ecommerce: "E-commerce",
    pleaseSelectAPlan: "Punten pilih rencana",
    includeAddons: "Kalebet Tambihan",
    viewTransactions: "Tingali Transaksi",
    customerHasNoCompletedTansactions: "Palanggan teu acan acan parantosan",
    branch: "Cabang",
    enterNumberOfEcommerceBranches: "Lebetkeun Jumlah Cabang E-niaga",
    enterNumberOfEcommerceBranchesToPay:
      "Lebetkeun Jumlah Cabang E-niaga Anu Anjeun Hayang mayar",
    ecommerceIntegration: "Integrasi E-commerce",
    enterNumberOfBranches: "Lebetkeun Jumlah Cabang",
    enterNumberOfAdditionalBranchesToPay:
      "Lebetkeun Jumlah Cabang Tambahan Anu Anjeun Bayaran",
    enterNumberOfStaffs: "Lebetkeun Jumlah Staffs",
    enterNumberOfStaffsToPayFor: "Lebetkeun Jumlah Staf Anu Anjeun Bayaran",
    discountApplies: "Daptar diskon",
    starsOnThe: "béntang dina",
    offer: "wawaran",
    get: "Kéngingkeun",
    moreStarsToRedeem: "langkung béntang pikeun ditebus",
    taxVat: "Pajak (PPN)",
    callCashierToCompletePayment: "Nelepon kasir pikeun ngalengkepan pamayaran",
    receipt: "Resi",
    dear: "Sayang",
    thankYouForYourOrderFindGoods:
      "Hatur nuhun kana pesenan anjeun. Punten panggihan barang-barang ieu anu disayogikeun, sakumaha disatujuan.",
    shippingNote: "Catetan Pengiriman",
    enterShippingNote: "Lebetkeun Catetan Pengiriman",
    shippingAddress: "Alamat Pangiriman",
    enterShippingAddress: "Lebetkeun alamat Pengiriman",
    wellDoneYouAreDueToRedeem: "Saé! Anjeun bakal ditebus",
    toGetYourRewardNextVisit:
      "pikeun kéngingkeun hadiah anjeun dina kadatangan anjeun salajengna. hatur nuhun",
    pointsOnThe: "Poin ngeunaan",
    morePointsToRedeem: "langkung poin pikeun ditebus",
    showCode: "Tembongkeun kode",
    toGetYourRewardOnNextVisit:
      "pikeun kéngingkeun hadiah anjeun dina kadatangan anjeun salajengna. hatur nuhun",
    earn: "Ngahasilkeun",
    delivaryNote: "Catetan Delivary",
    draftSales: "Penjualan Draf",
    startDate: "Tanggal mimiti",
    endDate: "Tanggal tungtung",
    orders: "Pesenan",
    checkout: "parios",
    noProductItem: "Henteu Barang Produk",
    selectProductImage: "Pilih Gambar Produk",
    selectCountry: "Pilih nagara",
    selectRegion: "Pilih Nagara / Daérah",
    printProductTag: "Nyetak Tag Produk",
    transactionReference: "Rujukan urus",
    Cashier: "Kasir",
    Manager: "Gerentes",
    Owner: "Juragan",
    Admin: "Admin",
    addPartners: "Tambahkeun Mitra",
    addNewLoyaltyPartner: "Tambahkeun Anyar kasatiaan Mitra",
    pleaseEnterCompanyName: "Mangga lebetkeun Ngaran Perusahaan",
    companyName: "Nami Perusahaan",
    pleaseEnterCompanyRepName: "Mangga lebetkeun Ngaran wawakil pausahaan",
    companyRepName: "Nama Perwakilan Perusahaan",
    pleaseEnterCompanyRepEmail: "Mangga lebetkeun email perwakilan perusahaan",
    companyRepEmail: "Surélék ti rep parusahaan",
    pleaseEnterCompanyRepPhone:
      "Mangga lebetkeun nomer telepon wawakil pausahaan",
    companyRepPhone: "Nomer telepon rep perusahaan",
    addReward: "Tambahkeun ganjaran",
    pleaseEnterRewardName: "Mangga lebetkeun ngaran ganjaran",
    rewardName: "Ngaran Ganjaran",
    rewardQuantity: "Jumlah Ganjaran",
    rewardDescription: "Pedaran ganjaran",
    rewardType: "Jenis ganjaran",
    pleaseEnterRewardType: "Mangga lebetkeun tipe ganjaran",
    pleaseEnterRewardQuantity: "Mangga lebetkeun kuantitas ganjaran",
    pleaseEnterRewardDescription: "Mangga lebetkeun pedaran ganjaran",
    fineDining: "Rupa Dining",
    luxuryFashion: "Pantun méwah",
    hotels: "hotél",
    travel: "Lalampahan",
    foodAndBeverage: "Dahareun jeung inuman",
    fashion: "Pantun",
    health: "Kaséhatan",
    furniture: "jati",
    entertainment: "Hiburan",
    automobile: "Mobil",
    education: "Atikan",
    beautyAndSpa: "Beauty jeung Spa",
    staycation: "Tinggaleun",
    events: "Kajadian",
    trips: "Lalampahan",
    oilAndGas: "Minyak jeung Gas",
    laundry: "Cucian",
    partnerCategory: "Kategori Mitra",
    freeItem: "Barang Gratis",
  },
  Tajik: {
    cashier: "кассир",
    manager: "мудир",
    owner: "соҳиби",
    online: "онлайн",
    offline: "офлайн",
    changePassword: "Ивази калидвожа",
    currentPasswordMessage: "Лутфан пароли ҷории худро ворид кунед",
    passwordMessage: "Лутфан пароли худро ворид кунед",
    currentPassword: "Калидвожаи Феълӣ",
    password: "парол",
    confirmPasswordMessage: "Лутфан пароли худро тасдиқ кунед!",
    confirmPassword: "Калидвожаро Тасдиқ Кунед",
    sendViaEmail: "Тавассути почтаи электронӣ фиристед",
    sendViaWhatsapp: "Тавассути WhatsApp фиристед",
    downloadPdf: "PDF -ро зеркашӣ кунед",
    paid: "пардохт",
    unpaid: "бемузд",
    partial: "қисман",
    closeInvoice: "Оё шумо мехоҳед, ки ҳисобнома -фактураро пӯшед?",
    closeInvoiceConfirmation:
      "Ҳисобнома -фактура наҷот дода намешавад. Мехоҳед пӯшед?",
    yes: "ҳа",
    no: "не",
    invoice: "Ҳисобнома -фактура",
    wasDeducted: "тарҳ карда шуд",
    for: "барои",
    item: "Банди",
    addProduct: "Маҳсулот илова кунед",
    paymentReference: "Маълумотномаи пардохт",
    amountPaid: "Маблағи пардохтшуда",
    discountAmount: "Маблағи тахфиф",
    amountDue: "Маблағи пардохтшаванда",
    amount: "Маблағ",
    dueDate: "Мӯҳлати пардохт",
    paymentType: "Навъи пардохт",
    card: "Корт",
    cash: "Пули нақд",
    bankTransfer: "Интиқоли бонк",
    paymentMessage: "Паёми пардохт",
    description: "Тавсиф",
    sendReceipt: "Гирифтани квитансия",
    delete: "Нест кардан",
    save: "Захира кунед",
    resend: "Аз нав фиристодан",
    saveAndSend: "Захира кунед",
    invoiceSaved: "Ҳисобнома -фактура захира карда шуд!",
    selectPaymentMethod: "Лутфан усули пардохтро интихоб кунед!",
    selectCustomer: "Лутфан муштарӣ интихоб кунед!",
    cartEmptyError:
      "Рӯйхати ароба холӣ буда наметавонад, ҳисобнома -фактураро пӯшед ва ашёро ба ароба илова кунед!",
    subscriptionExpired:
      "Обунаи шумо ба охир расид ва аккаунти шумо ҳоло маҳдуд аст. Барои нав кардани ҳисоби худ тугмаи поёнро клик кунед",
    renew: "Навсозӣ кунед",
    holdOn: "Истодан",
    customerBank: "Бонки муштариён",
    cancel: "Бекор кардан",
    selectACustomer: "Мизоҷро интихоб кунед",
    invoiceSuccessfulPdfError:
      "Ҳисобнома -фактура бомуваффақият эҷод карда шуд, аммо насли PDF нисбат ба маъмулӣ тӯл мекашад. Лутфан дере нагузашта санҷед.",
    downloadingInvoice: "Зеркашӣ кардани фактура",
    downloadingReceipt: "Боргирии квитансия",
    whatsappReceiptError:
      "Ҳангоми фиристодани квитансия тавассути WhatsApp хатогӣ ба амал омад, Лутфан бори дигар кӯшиш кунед.",
    receiptToWhatsapp: "Қабул ба WhatsApp фиристода шуд",
    thankYouForPatronage: "Ташаккур барои сарпарастии шумо",
    hereIsYourReceipt: "Ин аст квитансияи пардохти шумо",
    errorSendingEmailReceipt:
      "Ҳангоми фиристодани квитансия тавассути почтаи электронӣ хатогӣ ба амал омад, лутфан дубора кӯшиш кунед ё бо дастгирии тамос гиред",
    receiptSentToEmail: "Қабул ба почтаи муштарӣ фиристода шудааст",
    invoiceSentToEmail: "Ҳисобнома ба почтаи муштарӣ фиристода шудааст",
    invoiceSuccessWhatsappError:
      "Ҳисобнома -фактура бомуваффақият сохта шуд, аммо ҳангоми фиристодани он ба WhatsApp хатогӣ ба амал омад. Дар рӯйхати ҳисобнома -фактура бори дигар кӯшиш кунед",
    invoiceSuccessfulEmailError:
      "Ҳисобнома-фактура бомуваффақият сохта шуд, аммо ҳангоми фиристодани почтаи электронӣ хатогӣ ба амал омад. Лутфан аз рӯйхати ҳисобнома -фактура бори дигар кӯшиш кунед",
    invoiceSentToWhatsapp: "Ҳисобнома ба WhatsApp фиристода шуд",
    hello: "Салом",
    pleaseDownloadInvoice: "Лутфан ҳисобнома -фактураро зеркашӣ кунед",
    pleaseDownloadReceipt: "Лутфан квитансияро зеркашӣ кунед",
    from: "аз",
    email: "Почтаи электронӣ",
    upgrade: "Навсозӣ кардан",
    youAreOnFreePlan: "Шумо дар нақшаи ройгон ҳастед.",
    clickOn: "Пахш кунед",
    yourPlanInFewSteps: " обунаи шуморо дар чанд қадами зуд.",
    to: "ба",
    yourSubscriptionHasExpired:
      "Обунаи шумо ба охир расид ва аккаунти шумо ҳоло маҳдуд аст.",
    days: "рӯзҳо",
    yourSubscriptionExpiresIn: "Обунаи Loystar -и шумо ба охир мерасад",
    createAcount: "Ҳисоб кушоед",
    signIn: "Даромад",
    continue: "Идома диҳед",
    enterOtp: "PIN OTP -ро ворид кунед",
    enterValidOtp: "Лутфан PIN -и дуруст ворид кунед",
    pin: "PIN",
    tokenSentToMail: "Ба почтаи электронии шумо нишона фиристода шудааст",
    passwordResetSuccessful: "Тағир додани парол муваффақ шуд",
    somethingWentWrong: "Чизе хато рафт!",
    resetPassword: "Паролро аз нав танзим кунед",
    iHaveResetCode: "Ман рамзи барқароркунии парол дорам",
    pleaseEnterEmail: "Лутфан почтаи электронии худро ворид кунед",
    aTokenWillBeSentToEmail:
      "Ба почтаи электронии шумо нишона фиристода мешавад",
    enterEmail: "Почтаи электронии худро ворид кунед",
    sendinYourToken: "Ирсоли нишони шумо ...",
    makeSureItMatchesPassword:
      "Боварӣ ҳосил кунед, ки он ба пароли нави шумо мувофиқат мекунад",
    pleaseChooseNewPassword: "Лутфан пароли нав интихоб кунед",
    chooseNewPassword: "Пароли навро интихоб кунед",
    enterNewPassword: "Барои тасдиқ кардан пароли нави худро ворид кунед",
    enterTokenSent: "Нишонеро, ки почтаи шумо фиристода шудааст, ворид кунед",
    resetToken: "Аз нав танзимкунии нишона",
    resettingPassword: "Аз нав танзимкунии пароли шумо ...",
    signUp: "Қайд кардан",
    adminSignInWithEmail:
      " Админ бо почтаи электронӣ ворид мешавад, дар ҳоле ки кормандон бо номи корбар ворид мешаванд.",
    pleaseEnterEmailOrUsername:
      "Лутфан почтаи электронӣ ё номи корбарии худро ворид кунед",
    emailOrUsername: "Номи корбар ё почтаи электронӣ",
    pleaseEnterPassword: "Лутфан паролро ворид кунед",
    createAnAccount: "Ҳисоб кушоед",
    forgotPassword: "Калидвожа фаромӯш шуд?",
    enterPhoneNumber: "Рақами телефонро ворид кунед",
    personalData: "Маълумоти шахсӣ",
    validateConfirmationCOde: "Рамзи тасдиқро тасдиқ кунед",
    pleaseEnterFirstName: "Лутфан номи худро ворид кунед",
    pleaseEnterPhoneNumber: "Лутфан рақами телефони худро ворид кунед",
    pleaseEnterLastName: "Лутфан насаби худро ворид кунед",
    pleaseEnterBusinessName: "Лутфан номи тиҷорати худро ворид кунед",
    firstName: "Ном",
    lastName: "Насаб",
    businessName: "Номи тиҷорат",
    previous: "Гузашта",
    next: "Баъдӣ",
    pleaseSelectBusinessCategory:
      "Лутфан категорияи тиҷорати худро интихоб кунед",
    pleaseEnterValidEmail: "Лутфан паёми дурустро ворид кунед",
    pleaseEnterPostCode: "Лутфан индекси почтаро ворид кунед",
    postCode: "Индекси почта",
    phoneNumberInUse: "Ин рақами телефон аллакай дар истифода аст!",
    emailInUse: "Ин почтаи электронӣ аллакай дар истифода аст!",
    foodAndDrinks: "Хӯрок ва нӯшокиҳо",
    salonAndBeauty: "Салон ва Зебоӣ",
    fashionAndAccessories: "Мода ва лавозимот",
    gymAndFitness: "Варзиш ва фитнес",
    travelAndHotel: "Саёҳат ва меҳмонхона",
    homeAndGifts: "Хона ва тӯҳфаҳо",
    washingAndCleaning: "Шустушӯй ва тозакунӣ",
    gadgetsAndElectronics: "Гаҷетҳо ва электроника",
    groceries: "Хӯрокворӣ",
    others: "Дигарон",
    submit: "Пешниҳод кунед",
    accountCreatedSuccessful: "Ҳисоби шумо бомуваффақият сохта шуд.",
    pleaseEnterAddress: "Лутфан суроғаи худро ворид кунед",
    addressLine1: "нишонаи сатри якум",
    addressLine2: "Хатти адрес 2",
    choosePassword: "Паролро интихоб кунед",
    passwordMustBe6Characters: "Парол бояд на камтар аз 6 аломат дошта бошад.",
    howDidYouHearLoystar: "Шумо дар бораи Loystar чӣ гуна шунидед?",
    referralCode: "Кодекси истинод",
    byClickingTheButton: " Бо зер кардани тугмаи поён шумо розӣ мешавед",
    termsAndSevice: "Шартҳо",
    wereCreatingAccount: "Мо ҳисоби шуморо эҷод мекунем",
    proceed: "Идома диҳед",
    verificationCodeMustBe6: "Рамзи тасдиқ бояд 6 рақам бошад",
    pleaseEnter6DigitCode: "Лутфан рамзи 6 -рақама ворид кунед",
    enterVerificationCode: "Рамзи тасдиқкуниро ворид кунед",
    resendToken: "Токенро дубора фиристед",
    verify: "Тафтиш кунед",
    transactions: "Амалиётҳо",
    todaySales: "Фурӯши имрӯза",
    salesHistory: "Таърихи фурӯш",
    supplyHistory: "Таърихи таъминот",
    new: "Нав",
    invoices: "Ҳисобнома -фактураҳо",
    disbursements: "Пардохтҳо",
    offlineSales: "Фурӯши офлайнӣ",
    products: "Маҳсулот",
    customers: "Мизоҷон",
    multiLevelLoyalty: "Вафодории бисёрзинагӣ",
    loyaltyPrograms: "Барномаҳои вафодорӣ",
    members: "Аъзоён",
    appStore: "Дӯкони App",
    orderMenu: "Менюи фармоиш",
    settings: "Танзимот",
    staffAndBranches: "Кормандон ва филиалҳо",
    myAccount: "Ҳисоби ман",
    switchToSellMode: "Гузариш ба Усули фурӯш",
    signOut: "Баромадан",
    getFreeSubscription: "Обунаи ройгон гиред",
    onlyNumbersAllowed: "Танҳо рақамҳо иҷозат дода шудаанд",
    yourAccountMustBe10Digits:
      "рақами ҳисоби шумо бояд рақами 10 -рақама бошад",
    yourBvnMustBe11: "BVN -и шумо бояд рақами 11 -рақама бошад",
    pleaseSelectBank: "Лутфан бонки худро интихоб кунед",
    selectYourBank: "Бонки худро интихоб кунед",
    enterBankAccountNumber: "Рақами суратҳисоби бонкиро ворид кунед",
    enterBvnNumber: "BVN -и худро ворид кунед",
    connectBankAccount: "Ҳисоби бонкиро пайваст кунед",
    passwordResetSuccessfulAndSignOut:
      "Пароли шумо бомуваффақият барқарор карда шуд. Барои дубора ворид шудан тугмаи Хуруҷро зер кунед",
    enterCurrentPassword: "Рамзи ҷорӣ ворид кунед",
    pleaseEnterCurrentPassword: "Лутфан пароли ҳозираро ворид кунед",
    phoneNumber: "Рақами мобилӣ",
    sex: "Ҷинс",
    dateOfBirth: "Таърихи таввалуд",
    state: "Давлат",
    country: "Кишвар",
    loyaltyId: "ID вафодорӣ",
    createdAt: "Cохта шуд дар",
    noOfTransactionsMade: "Шумораи амалиётҳои анҷомдодашуда",
    yourDownloadWillStart: "Зеркашии шумо дар як лаҳза оғоз меёбад",
    exportCustomers: "Мизоҷонро содир кунед",
    youhaveSuccessfullyToppedUp:
      "Шумо воҳидҳои SMS -и худро бомуваффақият пур кардед.",
    youNowHave: "Шумо ҳоло доред",
    smsUnits: "Воҳидҳои SMS",
    enterNumberOfSmsUnits:
      "Шумораи воҳидҳои SMS -ро, ки шумо мехоҳед харед, ворид кунед",
    pleaseEnterANumericValue: "Лутфан арзиши рақамиро ворид кунед",
    pay: "Пардохт",
    accountEditedSuccessfully: "Ҳисоби шумо бомуваффақият таҳрир карда шуд.",
    youAeCurrentlyOn: "Шумо ҳоло фаъол ҳастед",
    plan: "Нақша",
    userData: "Маълумоти корбар",
    businessData: "МАATЛУМОТИ БИЗНЕС",
    businessCategory: "Категорияи тиҷоратӣ",
    pleaseSelectCurrency: "Лутфан асъори худро интихоб кунед",
    selectYourCurrency: "Асъори худро интихоб кунед",
    purchaseMoreSmsUnits:
      "Бо истифода аз формаи зер, воҳидҳои бештари SMS харед",
    youHave: "Доред",
    atLeast4SmsUnitsRrequired:
      "Барои санҷиш ҳадди аққал 4 адад смс лозим аст, лутфан пур кунед!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Лутфан ҳисоби бонкии худро тасдиқ кунед, то ба талаботҳои "Мизоҷи худро бидонед" (KYC) риоя кунед. Ин ба шумо имкон медиҳад, ки пардохтҳоро тавассути USSD ё интиқоли фаврӣ ҷамъоварӣ кунед, фармоишҳоро аз муштариён қабул кунед ва ҳисоббаробаркуниро коркард кунед. Танҳо ба тоҷирони Нигерия дахл дорад. Лутфан тугмаи поёнро пахш кунед, то оғоз кунед.',
    reConnectBankAccount: "Ҳисоби бонкиро дубора пайваст кунед",
    accountName: "Номи ҳисоб",
    accountNumber: "Рақами ҳисобӣ",
    bankName: "Номи бонк",
    verified: "Санҷида шуд",
    accountDetails: "Тафсилоти ҳисоб",
    kycBankAccount: "KYC",
    createTier: "Сатҳ эҷод кунед",
    fileUploadSuccessful: "файл бомуваффақият бор карда шуд",
    fileUploadFailed: "боркунии файл ноком шуд",
    createLoyaltyProgram: "Барномаи вафодорӣ эҷод кунед",
    createLoyalty: "Вафодорӣ эҷод кунед",
    name: "Ном",
    loyaltyArtwork: "Санъати вафодорӣ",
    clickToUpload: "Барои боргузорӣ клик кунед",
    amountToPointsRatio: "Миқдори таносуби холҳо",
    points: "Нуқтаҳо",
    recommendedAmountToPointRatio:
      "Тавсия дода мешавад: ₦ аз 1 то 1 хол. яъне барои ҳар 1 Найраи сарфшуда, муштариёни шумо 1 хол мегиранд",
    pleaseTypeIn: "Лутфан ворид кунед",
    toDeleteLoyalty: "барои нест кардани ин садоқат",
    deleteLoyalty: "Вафодориро нест кунед",
    toConfirm: "тасдиқ кардан",
    edit: "Таҳрир кардан",
    pointsAwardedSuccessfully: "Нуқтаҳои бо муваффақият додашуда.",
    enterPointValueToAward:
      "Арзиши нуқтаро ворид кунед, ки шумо мехоҳед муштариёнро мукофот диҳед",
    award: "Ҷоиза",
    awardPointValuesToCustomer: "Арзиши нуқтаи мукофот ба муштарӣ",
    enrollMembersToLoyalty: "Аъзоёнро ба вафодорӣ номнавис кунед",
    awardPoints: "Нуқтаҳои мукофот",
    enroll: "Номнавис шудан",
    home: "Хона",
    loyalty: "Вафодорӣ",
    enrollCustomers: "Мизоҷонро сабт кунед",
    selected: "Интихобшуда",
    customer: "Муштарӣ",
    loyaltyActivationSuccessful: "Фаъолсозии вафодорӣ муваффақ аст.",
    loyaltyDeactivationSuccessful: "Хомӯшсозии вафодорӣ муваффақ аст.",
    viewTier: "Намоиши сатҳ",
    deactivate: "Хомӯш кардан",
    activate: "Фаъол кардан",
    actions: "Амалҳо",
    nameOfLoyalty: "Номи садоқат",
    loyaltyStatus: "Ҳолати вафодорӣ",
    numberOfTiers: "Шумораи сатҳҳо",
    createdOn: "Офарида шудааст",
    createATier: "Сатҳ эҷод кунед",
    stopCreatingTierConfirmation: "Оё мехоҳед эҷоди сатҳро қатъ кунед?",
    stopEditingTierConfirmation:
      "Оё шумо мехоҳед таҳрири ин сатҳро қатъ кунед?",
    nameOfTier: "Номи Тир",
    minimumSpendingTarget: "Ҳадафи ҳадди ақали хароҷот",
    maximumSpendingTarget: "Ҳадафи максималии хароҷот",
    minimumSpendingTargetRequired: "Ҳадди ақали хароҷот талаб карда мешавад",
    maximumSpendingTargetRequired: "Ҳадди ниҳоии хароҷот талаб карда мешавад",
    rewardSponsor: "Сарпарасти мукофот",
    pleaseChooseARewardSponsor: "Лутфан сарпарасти мукофотро интихоб кунед",
    self: "Худшиносӣ",
    partner: "Шарик",
    rewardPartner: "Шарики мукофот",
    pleaseSelectRewardPartner: "Лутфан шарики мукофоти худро интихоб кунед",
    rewards: "Мукофотҳо",
    pleaseSelectAReward: "Лутфан мукофотро интихоб кунед",
    instructionsOnRedeemingReward:
      "Дастурҳо дар бораи он ки чӣ тавр муштарӣ бояд мукофотро истифода барад",
    pleaseFillInThisField: "Лутфан ин майдонро пур кунед!",
    toDeleteThisTier: " барои нест кардани ин сатҳ",
    deleteTier: "Сатҳро нест кунед",
    viewMembers: "Намоиши аъзоён",
    membersEnrolled: "Аъзоён ба қайд гирифта шудаанд",
    instruction: "Дастурамал",
    membersIn: "Аъзоён дар",
    availableTiersInLoyalty: "Сатҳҳои дастрас дар барномаи вафодорӣ",
    tiers: "Зинаҳо",
    totalTier: "ПАЁМИ УМУМ",
    availableLoyaltyProgramme: "Барномаи вафодории дастрас",
    totalLoyalties: "САДОҚАТИ УМУМAL",
    memberDetails: "Тафсилоти аъзо",
    nameOfCustomer: "Номи Фармоишгар",
    address: "Суроға",
    allEnrolledMembers: "Ҳамаи аъзоёни ба қайд гирифташуда",
    thisIsToWishYouHappyBirthday:
      "Ин ба шумо зодрӯзи хушбахтона ва зиндагии шукуфоиро таманно дорад. Ташаккур барои ҳама чизҳое, ки шумо ба Loyster доред. Ҷашн муборак!",
    inputAnOfferPlease: "Лутфан пешниҳодро ворид кунед",
    pleaseSelectTheInsertPoint:
      "Лутфан нуқтаи дохилкуниро дар паём интихоб кунед ва дубора клик кунед",
    birthdayOfferAndMessage: "Пешниҳод ва паёми рӯзи таваллуд",
    birthdayOffer: "Пешниҳоди рӯзи таваллуд",
    birthdayMessage: "Паёми рӯзи таваллуд",
    noOfferFound: "Ягон пешниҳод ёфт нашуд",
    settingABirthdayOffer:
      "Танзими пешниҳоди зодрӯз ба муштариён имкон медиҳад, ки ин пешниҳодро тавассути SMS дар рӯзи таваллудашон гиранд",
    createOffer: "Эҷод Пешниҳод",
    whatIsTheOffer: "Пешниҳод чист?",
    editMessage: "Паёмро таҳрир кунед",
    insert: "Ворид кунед",
    theNameOfCustomerInserted: "Номи муштарӣ дар ин ҷо ворид карда мешавад",
    theBirtdayOfferInserted: "Пешниҳоди зодрӯз дар ин ҷо ворид карда мешавад",
    youSuccessfullyAddedNewBranch:
      "Шумо филиали навро бомуваффақият илова кардед!",
    addNewBranch: "Филиали нав илова кунед",
    addBranch: "Филиал илова кунед",
    additionalBranchWillIncur: "Филиали иловагӣ талаб карда мешавад",
    perBranch: "ба ҳар як филиал",
    ecommerceBranchCosts: "Хароҷоти филиали тиҷорати электронӣ",
    pleaseEnterBranchName: "Лутфан номи филиалро ворид кунед",
    pleaseEnterBranchAddress1: "Лутфан сатри суроғаи филиалро ворид кунед 1",
    enterBranchAddress1: "Хати суроғаи филиалро ворид кунед 1",
    enterBranchAddress2: "Хати суроғаи филиалро ворид кунед 1",
    pleaseEnterBranchAddress2: "Лутфан сатри суроғаи филиалро ворид кунед 2",
    enterBranchName: "Номи филиалро ворид кунед",
    successfullyAddedStaff: "Шумо бомуваффақият як ҳайати нав илова кардед!",
    addNewStaff: "Илова кардани ҳайати нав",
    addStaff: "Илова кардани кормандон",
    additionalStaffWillIncur: "Кормандони иловагӣ дучор меоянд",
    perStaff: "ба ҳар як корманд.",
    pleaseEnterStaffEmail: "Лутфан почтаи электронии кормандонро ворид кунед",
    pleaseEnterStaffUsername: "Лутфан номи корбарии кормандонро ворид кунед",
    pleaseEnterStaffPassword: "Лутфан пароли кормандонро ворид кунед",
    pleaseSelectStaffRole: "Лутфан нақши кормандонро интихоб кунед",
    selectStaffRole: "Нақши кормандонро интихоб кунед",
    enterStaffEmail: "Почтаи электронии кормандонро ворид кунед",
    enterStaffUsername: "Номи корбарии кормандонро ворид кунед",
    enterStaffPassword: "Рамзи кормандонро ворид кунед",
    spacesNotAllowedInUsername:
      "ҷойҳое, ки дар номи корбар иҷозат дода нашудаанд",
    admin: "Админ",
    pleaseSelectBusinessToAttachStaff:
      "Лутфан тиҷоратро барои пайваст кардани кормандон интихоб кунед",
    searchForBranchToAttachStaff:
      "Ҷустуҷӯи филиал барои пайваст кардани кормандон",
    username: "Номи корбар",
    role: "Нақши",
    areYouSureToDeleteThis: "Оё боварӣ доред, ки инро нест кунед",
    branches: "Филиалҳо",
    upgradeYourPlan: "Нақшаи худро такмил диҳед.",
    add: "Илова кардан",
    addNew: "Иловаи нав",
    customerWithEmailAlreadyExists:
      "Мизоҷ бо рақами почтаи электронӣ/телефон аллакай вуҷуд дорад!",
    successfullyAddedNewCustomer:
      "Шумо бомуваффақият як муштарии нав илова кардед!",
    addCustomer: "Илова кардани муштарӣ",
    pleaseEnterCustomerFirstName: "Лутфан номи муштариро ворид кунед",
    pleaseEnterCustomerLastName: "Лутфан насаби муштариро ворид кунед",
    pleaseEnterCustomerPhoneNumber:
      "Лутфан рақами телефони муштариро ворид кунед",
    pleaseEnterCustomerEmail: "Лутфан почтаи электронии муштариро ворид кунед",
    pleaseEnterCustomerAddressLine: "Лутфан хати адреси муштариро ворид кунед",
    pleaseEnterCustomerOtherAddress:
      "Лутфан Суроғаи дигари муштариро ворид кунед",
    pleaseSelectCustomerGender: "Лутфан ҷинси муштариро интихоб кунед",
    gender: "Ҷинс",
    male: "Мард",
    female: "Зан",
    bank: "Бонк",
    selectBank: "Бонкро интихоб кунед",
    stateOrRegionOrProvince: "Давлат/вилоят/вилоят",
    customerNotes: "Эзоҳҳои муштариён",
    sendSms: "SMS фиристед",
    editCustomer: "Таҳрири муштарӣ",
    redeemReward: "Мукофотро истифода баред",
    issueLoyaltyCard: "Корти вафодорӣ бароред",
    deleteCustomer: "Муштариро нест кунед",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Шумо бомуваффақият ID узвияти вафодорӣ таъин кардед",
    noMembershipIdAvailable:
      "ID -ҳои узвият дастрас нестанд. Лутфан бо мо дар тамос шавед hello@loystar.co",
    sendEmail: "Почтаи электронӣ фиристед",
    membershipPoints: "Нуқтаҳои узвият",
    customerDetails: "Тафсилоти муштариён",
    close: "Пӯшед",
    loyaltyBalance: "Тавозуни вафодорӣ",
    pointsBalance: "Тавозуни хол",
    starBalance: "Тавозуни ситораҳо",
    requiredPoints: "Нуқтаҳои зарурӣ",
    requiredStars: "Ситораҳои зарурӣ",
    reddemCode: "Рамзро истифода баред",
    toDeleteThisCustomer: "барои нест кардани ин муштарӣ",
    customerHasBeenDeletedSuccessfully: "Муштарӣ бомуваффақият нест карда шуд!",
    customerWithPhoneAlreadyExists:
      "Муштарӣ бо рақами телефон аллакай вуҷуд дорад",
    customerWasSuccessfullyEdited: "Фармоишгар бомуваффақият таҳрир карда шуд",
    anErrorOccured: "Хатогӣ ба амал омад",
    phoneNumberIncludeCountry:
      "Рақами телефон (Рамзи кишварро дар бар мегирад)",
    yourFileQueuedForUpload:
      "Файли шумо барои боргузорӣ дар навбат гузошта шудааст. Лутфан пас аз чанд сония саҳифаро нав кунед.",
    thereWasAnErrorPerformingOperation: "Ҳангоми иҷрои амалиёт хато рӯй дод!",
    pleaseSelectFile: "Лутфан файлро интихоб кунед!",
    oopsOnlyCsvFilesAllowed:
      "Вой! Танҳо файлҳои CSV иҷозат дода шудаанд. Лутфан файли .csv -ро бор кунед.",
    fileShouldBeSmallerThan5Mb:
      "Файл бояд аз 5 MB хурдтар бошад. Агар шумо файли калонтар дошта бошед, лутфан ба почтаи электронӣ support@loystar.co муроҷиат кунед. сипос",
    customerFirstNameIsRequired: "номи муштарӣ дар сатр талаб карда мешавад",
    customerPhoneNumberIsRequired:
      "рақами телефони муштарӣ дар сатр талаб карда мешавад",
    importCustomers: "Мизоҷонро ворид кунед",
    upload: "Боргузорӣ",
    clickIconToDownloadCsv:
      "Барои зеркашии қолаби файли CSV ин нишонро клик кунед.",
    getGoogleSheetVersion: "Версияи варақаи Google -ро гиред",
    clickOrDragFileToUpload:
      "Барои боргузорӣ файлро клик кунед ё ба ин минтақа кашед",
    missingOrInvalidColumnHeader:
      "Сарлавҳаи сутуни гумшуда ё нодуруст. Лутфан формати шаблонро риоя кунед. Сипос.",
    youHaveImported: "Шумо воридот кардед",
    youSuccessfullyRedeemedReward: "Шумо мукофоти худро бомуваффақият харидед!",
    sixDigitCode: "Рамзи шаш рақам",
    pleaseEnterCustomerRewardCode:
      "Лутфан рамзи мукофоти муштариро ворид кунед",
    enterRewardCode: "Рамзи мукофотро ворид кунед. (Рамзи мукофот ҳассос аст)",
    selectLoyaltyProgram: "Барномаи вафодориро интихоб кунед",
    stamps: "Маркаҳо",
    smsUnitsLow: "Воҳидҳои SMS паст",
    pleaseTopUpSmsUnits: "Лутфан воҳидҳои SMS -ро пур кунед",
    smsSuccessfullySentForDelivery:
      "SMS барои расонидани бомуваффақият фиристода шуд!",
    sendSmsTo: "SMS -ро ба",
    allCustomers: "Ҳамаи муштариён",
    unitsAvailable: "Воҳидҳои дастрас",
    pleaseTypeInTheMessage: "Лутфан паёмро нависед",
    message: "Паём",
    charactersRemaining: "аломатҳои боқимонда",
    avoidUseOfSpecialCharacters:
      "Истифодаи аломатҳои махсус ва эмодзҳоро барои ҳифзи воҳидҳои SMS пешгирӣ кунед.",
    note: "Шарҳ",
    errorFetchingCustomersMultilevelDetail:
      "Хато ҳангоми гирифтани тафсилоти бисёрсатҳавӣ ба мизоҷон",
    search: "Ҷустуҷӯ",
    reset: "Аз нав танзим кардан",
    importCustomer: "Мизоҷро ворид кунед",
    addNewCustomer: "Мизоҷи навро илова кунед",
    sendSmsBroadcast: "Ирсоли SMS Broadcast",
    totalCustomers: "МИЗОЧОНИ УМУМAL",
    disbursementDetails: "Тафсилоти пардохт",
    paidBy: "Аз ҷониби пардохт",
    disbursed: "Пардохт",
    bankAccountName: "Номи суратҳисоби бонкӣ",
    bankAccountNumber: "Рақами суратҳисоби бонкӣ",
    transferInitiated: "Интиқол оғоз ёфт",
    transferCompleted: "Интиқол анҷом ёфт",
    pleaseEnterAValid: "Лутфан эътибор ворид кунед",
    begin: "Оғоз",
    end: "Поён",
    date: "сана",
    paymentDate: "Санаи пардохт",
    selectDisbursementDuration: "Давомнокии пардохтро интихоб кунед",
    totalSettled: "Умумии ҳалшуда",
    totalUnsettled: "Ҳалли ноором",
    toDeleteThisSale: "барои нест кардани ин фурӯш",
    draftSaleDeletedSuccessfully:
      "Нақшаҳои фурӯш бомуваффақият нест карда шуданд!",
    deleteSale: "Фурӯшро нест кунед",
    pleaseTypeInYourTotal:
      "Лутфан маблағи умумии худро барои тасдиқ кардани несткунӣ нависед",
    billDetails: "Тафсилоти ҳисоб",
    printBill: "Биллро чоп кунед",
    servedBy: "Хизмат аз ҷониби",
    total: "Ҷамъ",
    createdDate: "Санаи таъсисёбӣ",
    createdTime: "Вақти офаридашуда",
    status: "Статус",
    loginSuccessful: "Воридшавӣ муваффақ шуд",
    pleaseWaitWhileWeConnectAccount:
      "Лутфан интизор шавед, вақте ки мо ҳисоби шуморо пайваст мекунем",
    connectionFailedTryAgain:
      "Пайвастшавӣ ноком шуд. Лутфан бори дигар кӯшиш кунед.",
    connectionSuccessful: "Пайвастшавӣ муваффақ шуд",
    viewDetails: "Тафсилотро дидан",
    enable: "Фаъол кардан",
    free: "Озод",
    cost: "Арзиш",
    visitWebsite: "Боздид аз вебсайт",
    pleaseUpgradeYourPlanToPro:
      "Лутфан нақшаи худро ба Pro Plus такмил диҳед, то ин барномаро фаъол созед",
    connectYourBankAccount:
      "Ҳисоби бонкии худро пайваст кунед, то пардохтҳоро қабул кунед.",
    disable: "Хомӯш кардан",
    enableApp: "Барномаро фаъол созед",
    addNewProductToInvoice: "Маҳсулоти навро ба ҳисобнома илова кунед",
    toDeleteThisInvoice: "барои нест кардани ин фактура",
    invoiceDeletedSuccessfully:
      "Ҳисобнома -фактура бомуваффақият нест карда шуд!",
    deleteInvoice: "Ҳисобнома -фактураро нест кунед",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Шумо бомуваффақият паёми пардохти фактураро илова кардед!",
    pleaseEnterPaymentInstructions: "Лутфан дастурҳои пардохтро ворид кунед",
    invoiceId: "ID фактура",
    paidDate: "Санаи пардохт",
    reference: "Маълумотнома",
    productAdded: "Маҳсулот илова карда шуд",
    productOutOfStock: "Маҳсулот дар захира нест. Лутфан дубора захира кунед.",
    totalInvoices: "Даъватҳои умумӣ",
    totalPaidInvoices: "ПАЁМҲОИ УМУМИИ ПУЛДА",
    totalUnpaidInvoices: "ПАЁМИ УМУМИИ БЕПРОД",
    loyaltyProgramDeleted: "Барномаи вафодорӣ нест карда шуд",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ин барномаи вафодорӣ бомуваффақият нест карда шуд",
    thisLoyaltyProgramEditedSuccessfully:
      "Ин барномаи вафодорӣ бомуваффақият таҳрир карда шуд",
    loyaltyProgramAddedSuccessfully:
      "Барномаи вафодорӣ бомуваффақият илова карда шуд",
    loyaltyProgramEdited: "Барномаи вафодорӣ таҳрир карда шуд",
    loyaltyProgramAdded: "Барномаи вафодорӣ илова карда шуд",
    loyaltyDetails: "Тафсилоти вафодорӣ",
    doYouWantToCloseDialog: "Оё мехоҳед ин муколамаро пӯшед?",
    pleaseEnterLoyaltyName: "Лутфан номи садоқати худро ворид кунед",
    programType: "Навъи барнома",
    pleaseSelectProgramType: "Лутфан як намуди барномаро интихоб кунед",
    simplePoints: "Нуқтаҳои оддӣ",
    stampsProgram: "Барномаи штампҳо",
    threshold: "Остона",
    pleaseEnterLoyaltyThreshold: "Лутфан ҳадди садоқатро ворид кунед",
    reward: "Мукофот",
    pleaseEnterLoyaltyReward: "Лутфан мукофоти вафодориро ворид кунед",
    totalUserPoints: "Нуқтаҳои умумии корбар",
    totalUserStamps: "Штампҳои умумии корбар",
    spendingTarget: "Ҳадафи харҷ",
    spendingTargetHint1:
      "Ҳадафи хароҷот ин аст, ки муштарӣ барои ба даст овардани мукофот бояд чӣ қадар маблағ сарф кунад. 1 арзиши асъор = 1 нуқта.",
    spendingTargetHint2:
      "Ҳадафи штампҳо ин аст, ки муштарӣ барои ба даст овардани мукофот бояд чанд мӯҳр гирад. масалан 5",
    addNewLoyaltyProgram: "Барномаи нави вафодорӣ илова кунед",
    addLoyaltyProgram: "Барномаи вафодорӣ илова кунед",
    loyaltyProgramType: "Навъи барномаи вафодорӣ",
    pleaseSelectLoyaltyProgramType:
      "Лутфан намуди барномаи вафодориро интихоб кунед",
    nameOfProgram: "Номи барнома",
    pleaseEnterProgramName: "Лутфан номи барномаро ворид кунед",
    whatIsTheReward: "Мукофот чист?",
    egNextPurchaseIsFree: "Мисол Харидани навбатӣ ройгон аст",
    customerName: "Номи муштарӣ",
    guestCustomer: "Мизоҷи Меҳмон",
    orderConfirmedSuccessfully: "Фармоиш бомуваффақият тасдиқ карда шуд",
    orderCancelledSuccessfully: "Фармоиш бомуваффақият бекор карда шуд",
    confirmOrder: "Тартибро тасдиқ кунед",
    cancelOrder: "Фармоишро бекор кунед",
    allOrders: "Ҳама фармоишҳо",
    totalOrders: "Фармоишҳои умумӣ",
    doYouWantToAcceptOrder: "Оё мехоҳед ин фармоишро қабул кунед?",
    doYouWantToCancelOrder: "Оё шумо ин фармонро бекор кардан мехоҳед?",
    orderDetails: "Тафсилоти фармоиш",
    orderCreatedAt: "Фармоиш дар",
    supplier: "Таъминкунанда",
    productName: "Номи маҳсулот",
    quantity: "Миқдор",
    unitPrice: "Нархи чакана",
    receivedBy: "Аз ҷониби қабулшуда",
    reportFrom: "Ҳисобот аз",
    totalSupplies: "Таъмини умумӣ",
    allRightsReserved: "Ҳамаи ҳуқуқ маҳфуз аст",
    toDeleteThisTransaction: "барои нест кардани ин Транзаксия",
    transactionDeletedSuccessfully:
      "Амалиёт бомуваффақият нест карда шуд. Саҳмияҳо ба инвентаризатсия баргардонида шуданд.",
    deleteTransaction: "Транзаксияро нест кунед",
    transactionDetails: "Тафсилоти муомилот",
    printReceipt: "Қабули чоп",
    channel: "Канал",
    discount: "Тахфиф",
    profit: "Фоида",
    discountedSales: "Фурӯши тахфифшуда",
    errorFetchingRecord: "Хато ҳангоми гирифтани сабт",
    exportTransactions: "Амалиётҳои содиротӣ",
    errorFetchingSalesRecord: "Хатогии гирифтани сабти фурӯш барои содирот.",
    totalSellingPrice: "Нархи умумии фурӯш",
    totalCostPrice: "Арзиши умумии хароҷот",
    appliedDiscount: "Тахфифи татбиқшаванда",
    noOfItems: "Шумораи ашёҳо",
    sales: "Фурӯш",
    export: "Содирот",
    totalProfit: "Фоидаи умумӣ",
    totalBalanceInPeriod: "Бақияи умумии давра",
    allTimeSales: "Ҳама вақт фурӯш",
    records: "Сабтҳо",
    todaysSales: "Фурӯши имрӯза",
    transaction: "муомилот",
    youHaveExceededTotalNumberOfProducts:
      "Шумо аз шумораи умумии маҳсулоте, ки дар нақшаи шумо иҷозат дода шудааст, зиёд шудед. Нақшаи худро такмил диҳед, то лимити баландтар гиред.",
    youNeedToHaveLoyaltyProgram:
      "Барои истифодаи ин хусусият шумо бояд барномаи вафодорӣ дошта бошед!",
    price: "Нарх",
    category: "Категория",
    stockTracking: "Пайгирии саҳҳомӣ",
    stockCount: "Ҳиссаи саҳмияҳо",
    taxed: "Андозбандӣ",
    originalPrice: "Нархи аслӣ",
    costPrice: "Нархи Арзиш",
    unit: "Шӯъба",
    productImage: "Тасвири Маҳсулот",
    taxRate: "Меъёри андоз",
    taxType: "Навъи андоз",
    trackInventory: "Инвентаризатсияи пайгирӣ",
    variants: "Вариантҳо",
    hasVariants: "Вариантҳо дорад",
    importProduct: "Маҳсулоти воридотӣ",
    exportProducts: "Маҳсулоти содиротӣ",
    addNewProduct: "Маҳсулоти нав илова кунед",
    viewCategory: "Дидани Категория",
    viewSuppliers: "Дидани таъминкунандагон",
    receiveInventory: "Гирифтани инвентаризатсия",
    printAllProductsTag: "Барчаспҳои ҳама маҳсулотро чоп кунед",
    deleteAll: "Ҳамаашро нест кунед",
    totalProducts: "МАҲСУЛОТИ УМУМAL",
    youveSuccessfullyAddedANewCategory:
      "Шумо категорияи навро бомуваффақият илова кардед",
    addNewCategory: "Категорияи нав илова кунед",
    addCategory: "Иловаи Категория",
    categoryName: "Номи категория",
    pleaseEnterCategoryName: "Лутфан номи категорияро ворид кунед",
    stampsTarget: "Ҳадафи тамғаҳо",
    sendInventory: "Инвентаризатсия фиристед",
    productDetails: "Тафсилоти маҳсулот",
    youveSuccessfullyEditedThisCategory:
      "Шумо ин категорияро бомуваффақият таҳрир кардед",
    update: "Навсозӣ",
    categoryList: "Рӯйхати категорияҳо",
    categories: "Категорияҳо",
    enterQuantityToUpdate: "Барои навсозӣ миқдорро ворид кунед",
    inventorySentSuccessfully: "Инвентаризатсия бомуваффақият фиристода шуд!",
    updateInventory: "Навсозии инвентаризатсия",
    currentQuantity: "Миқдори ҷорӣ",
    pleaseEnterQuantityToAdd:
      "Лутфан миқдореро, ки мехоҳед илова кунед, ворид кунед",
    pleaseSelectABranch: "Лутфан филиалро интихоб кунед",
    searchForBranch: "Ҷустуҷӯи филиал",
    youCantSendMoreThanStock:
      "Шумо наметавонед зиёда аз он ки дар захира доред, фиристед",
    send: "Фиристед",
    pleaseEnterQuantityToSend:
      "Лутфан миқдореро, ки мехоҳед фиристед, ворид кунед",
    productNameIsRequiredOnRow: "номи маҳсулот дар сатр талаб карда мешавад",
    productCategoryIsRequiredOnRow:
      "категорияи маҳсулот дар сатр талаб карда мешавад",
    productPriceIsRequiredOnRow:
      "нархи маҳсулот дар навбат талаб карда мешавад",
    productUnitIsRequiredOnRow: "воҳиди маҳсулот дар навбат лозим аст",
    productQuantityIsRequiredOnRow:
      "миқдори маҳсулот дар навбат талаб карда мешавад",
    productVariantsRequireTracking:
      "вариантҳои маҳсулот пайгирӣро талаб мекунанд!",
    pleaseAddVariantClickButton:
      "Лутфан бо пахш кардани тугмаи илова вариант илова кунед!",
    totalVariantsMoreThanSelectedQuantity:
      "Вариантҳои умумӣ аз миқдори интихобшудаи маҳсулот зиёдтаранд, Pls миқдори вариантҳоро кам мекунад",
    productEditedSuccessfully: "Маҳсулот бомуваффақият таҳрир карда шуд!",
    fileTooLargeLessThan4Mb:
      "Андозаи файл хеле калон аст! Андозаи файл бояд на камтар аз 4 MB бошад.",
    suchVariantAlreadyExist: "Чунин вариант аллакай вуҷуд дорад",
    addVariants: "Вариантҳоро илова кунед",
    editProduct: "Таҳрири Маҳсулот",
    pleaseEnterProductName: "Лутфан Номи Маҳсулотро ворид кунед",
    pleaseEnterProductPrice: "Лутфан Нархи Маҳсулотро ворид кунед",
    pleaseEnterProductOriginalPrice:
      "Лутфан нархи аслии маҳсулотро ворид кунед",
    productDescription: "Тавсифи Маҳсулот",
    selectProductCategory: "Категорияи Маҳсулотро интихоб кунед",
    pleaseSelectProductCategory: "Лутфан категорияи маҳсулотро интихоб кунед",
    productCostPrice: "Нархи Арзиши Маҳсулот",
    productSellingPrice: "Нархи фурӯши маҳсулот",
    productOriginalPrice: "Нархи аслии маҳсулот",
    maxFileSizeAllowedIs4Mb: "Макс. Андозаи файл иҷозат дода шудааст 4mb",
    productsWithPicturesArePublished:
      "Маҳсулот бо расмҳо дар Discover нашр карда мешаванд, то фармоиш гиранд",
    shouldThisProductBeTracked: "Оё ин маҳсулотро пайгирӣ бояд кард?",
    pleaseSelectStockUnit: "Лутфан воҳиди саҳҳомонро интихоб кунед",
    stockUnit: "Воҳиди саҳҳомӣ",
    bag: "BAG",
    bottle: "ШАБЛА",
    bunch: "БАНЧ",
    can: "МЕТАВОНАД",
    carton: "КАРТОН",
    crate: "КРАТ",
    cup: "ҶОМ",
    dozen: "ДУЗДА",
    gigabytes: "GIGABYTES",
    gram: "ГРАМ",
    kilogram: "КИЛОГРАМ",
    litre: "ЛИТР",
    pack: "ПАК",
    pair: "Ҷуфт",
    pieces: "Порчаҳо",
    plate: "Плита",
    tonne: "ТОНН (MT)",
    uNIT: "ЯГОНА",
    yard: "YARD",
    pleaseEnterProductQuantity: "Лутфан миқдори маҳсулотро ворид кунед",
    productQuantity: "Миқдори Маҳсулот",
    isThisProductTaxed: "Оё ин маҳсулот андозбандӣ шудааст?",
    selectTaxType: "Навъи андозро интихоб кунед",
    pleaseSelectTaxType: "Лутфан Навъи андозро интихоб кунед",
    progressive: "Пешрав",
    proportional: "Мутаносиб",
    pleaseEnterProductTaxRate: "Лутфан меъёри андози маҳсулотро ворид кунед",
    doesProductHaveVariants: "Оё ин маҳсулот вариантҳо дорад?",
    type: "Навиштан",
    value: "Арзиш",
    pleaseEnterVariantType: "Лутфан намуди вариантро ворид кунед",
    pleaseEnterVariantValue: "Лутфан арзиши вариантро ворид кунед",
    pleaseEnterVariantPrice: "Лутфан нархи вариантро ворид кунед",
    pleaseEnterVariantQuantity: "Лутфан миқдори вариантро ворид кунед",
    productDeletedSuccessfully: "Маҳсулот бомуваффақият нест карда шуд!",
    categoryDeletedSuccessfully: "Категория бомуваффақият нест карда шуд!",
    toDeleteThisProduct: "барои нест кардани ин маҳсулот",
    invalidProductQuantity: "Миқдори нодурусти маҳсулот",
    quantityAddedIsOverStock:
      "Миқдоре, ки шумо илова мекунед, аз он чизе, ки шумо дар саҳҳомӣ доред, зиёдтар аст.",
    itemInventoryNotTracked: "Инвентаризатсияи ашё пайгирӣ карда намешавад",
    addBulkQuantity: "Миқдори маҷмӯиро илова кунед",
    enterBulkQuantity: "Миқдори маҷмӯиро ворид кунед",
    pleaseEnterBulkQuantity: "Лутфан миқдори миқдорро ворид кунед",
    youveSuccessfullyAddedANewProduct:
      "Шумо маҳсулоти навро бомуваффақият илова кардед!",
    pleaseEnterProductSellingPrice:
      "Лутфан нархи фурӯши маҳсулотро ворид кунед",
    doYouWantToTrackProductStock:
      "Оё шумо мехоҳед захираи маҳсулотро пайгирӣ кунед?",
    sellingPrice: "Нархи фурӯш",
    setProductExpiryReminder: "Хотиррасонии мӯҳлати маҳсулотро таъин кунед",
    productExpiryDate: "Санаи ба охир расидани маҳсулот",
    startRemindingFrom: "Хотиррасониро аз оғоз кунед",
    productSuppliesAddedSuccessfully:
      "Шумо таъминоти маҳсулотро бомуваффақият илова кардед.",
    addProductSupplies: "Иловаи Маҳсулоти Маҳсулот",
    pleaseSelectSupplier: "Лутфан таъминкунандаро интихоб кунед",
    nameOfProduct: "Номи Маҳсулот",
    pleaseSelectProduct: "Лутфан Маҳсулотро интихоб кунед",
    productVariant: "Варианти маҳсулот",
    pleaseSelectAVariant: "Лутфан як вариантро интихоб кунед",
    pleaseEnterUnitPrice: "Лутфан нархи воҳидро ворид кунед",
    businessBranch: "Филиали тиҷоратӣ",
    pleaseSelectBranch: "Лутфан филиалро интихоб кунед",
    youveSuccessfullyAddedANewSupplier:
      "Шумо як таъминкунандаи навро бомуваффақият илова кардед",
    addSupplier: "Иловаи Таъминкунанда",
    pleaseEnterSupplierEmail:
      "Лутфан почтаи электронии таъминкунандаро ворид кунед",
    pleaseAddADescription: "Лутфан тавсиф илова кунед",
    anErrorOccuredProductsDeleted:
      "Ҳангоми иҷрои амал хатогӣ рух дод. Лутфан таваҷҷӯҳ намоед, ки шояд чанд маҳсулот дар ин раванд нест карда шуда бошанд",
    bulkDelete: "Тоза кардани маҷмӯӣ",
    youAreAboutToDelete: "Шумо мехоҳед нест кунед",
    product: "Маҳсулот",
    isDueToRedeem: "бо сабаби раҳоӣ аст",
    aReward: "мукофот",
    pleaseSelectCustomerToReeemReward:
      "Лутфан муштариро барои гирифтани мукофот интихоб кунед.",
    youHaveNoLoyaltyProgramRunning: "Шумо ягон барномаи садоқати фаъол надоред",
    customerHhasNoPointsInLoyaltyProgram:
      "Муштарӣ дар ин барномаи вафодорӣ нуқтае надорад",
    proceedWithPayment: "Бо пардохт идома диҳед?",
    youAreAboutToPayForTransactionUsingPoints:
      "Шумо барои транзаксия бо истифода аз нуқтаҳо пардохт кардан мехоҳед.",
    customerHas: "Муштарӣ дорад",
    worth: "арзиш",
    andIsNotSufficientToPayForTransaction:
      "ва барои пардохти ин муомилот кофӣ нест. Оё онҳо мехоҳанд тавозунро бо усули дигари пардохт илова кунанд?",
    addCustomerLoyalty: "Вафодории муштариёнро илова кунед",
    pleaseAddCustomerFirst: "Лутфан аввал муштарӣ илова кунед ё интихоб кунед.",
    pleaseWaitWhileWeGetReady: "Лутфан, мунтазир бошед, то мо омода шавем",
    lowStock: "Ҳиссаи паст",
    pleaseEnterAmountTendered: "Лутфан маблағи пешниҳодшударо ворид кунед",
    areYouSureToBookSaleOffline:
      "Шумо мутмаин ҳастед, ки мехоҳед ин фурӯшро дар офлайн брон кунед?",
    saleWouldBeBookedAutomatically:
      "Вақте ки шумо интернетро фаъол мекунед, фурӯш ба таври худкор брон карда мешавад",
    offlineSalesBookingCancelled: "Бронкунии фурӯши офлайн бекор карда шуд",
    covid19Message:
      "COVID19: Барои боздоштани паҳншавӣ дастҳои худро бо собун бишӯед ё санитаризатсия кунед. Ҳамеша бехатар бошед",
    saleSuccessfullyRecorded: "Фурӯш бомуваффақият сабт шуд!",
    sendReceiptToEmail: "Ирсолро ба почтаи электронӣ фиристед",
    sendThankYouSms: "Ташаккури SMS фиристед",
    sendShippingDetails: "Тафсилоти интиқолро фиристед",
    addLoyalty: "Вафодорӣ илова кунед",
    searchCustomerNameOrNumber: "Номи муштарӣ ё рақами муштариро ҷустуҷӯ кунед",
    clickTheSearchCustomerBox:
      "Қуттии ҷустуҷӯи муштариён ва корти сканро клик кунед",
    enterProductPrice: "Нархи Маҳсулотро ворид кунед",
    enterPriceFor: "Нархро ворид кунед",
    searchForProduct: "Ҷустуҷӯи маҳсулот",
    all: "Ҳама",
    backToDashboard: "Бозгашт ба панели идоракунӣ",
    viewDraftSales: "Дидани лоиҳаи фурӯш",
    variantSelected: "варианти интихобшуда",
    variant: "вариант",
    thePreviousVariantSelectionNotAvailable:
      "Интихоби варианти қаблӣ барои варианти нави вобаста ба нарх интихобшуда мавҷуд нест, лутфан интихоби худро тағир диҳед.",
    pleaseSupplyPositiveQuantityNumber:
      "Лутфан рақами миқдори мусбатро пешниҳод кунед",
    lowStockFor: "Захираи кам барои",
    clearVariants: "Вариантҳои равшан",
    pleaseEnterQuantity: "Лутфан Миқдорро ворид кунед",
    picture: "Тасвир",
    redemptionToken: "Нишони наҷот",
    token: "Нишона",
    totalSpent: "Ҳаҷми сарфшуда",
    confirmPayment: "Пардохтро тасдиқ кунед",
    hasPaymentBeenMade: "Оё пардохт бомуваффақият коркард шудааст?",
    enterTransactionReference:
      "Маълумоти муомилотро, ки шумо пардохт кардед, ворид кунед",
    pleaseSelectACustomer: "Лутфан муштарӣ интихоб кунед!",
    areYouSureToApplyDiscount:
      "Шумо мутмаин ҳастед, ки тахфифро татбиқ кардан мехоҳед?",
    addYourBankAccountToEnableUssd:
      "Ҳисоби бонкии худро илова кунед, то интиқоли фаврии USSD тавассути uPay имкон диҳад",
    totalAmountToPay: "Маблағи умумии пардохт",
    doYouWantToCancelTransaction: "Оё шумо мехоҳед ин муомилотро бекор кунед?",
    savePrintBill: "Биллро захира кунед/чоп кунед",
    enterAmountCollectedForCustomer:
      "Маблағи барои муштарӣ ҷамъшударо ворид кунед",
    recordSale: "Фурӯши сабтҳо",
    checkOutWith: "Аз назар гузаронед",
    instantTransfer: "Интиқоли фаврӣ",
    dialTheUSSDCode: "Рамзи USSD -ро ворид кунед",
    pleaseSelectABank: "Лутфан як бонкро интихоб кунед",
    payWithUSSD: "Бо USSD пардохт кунед",
    sendBillTo: " - Биллро ба",
    waitingForUSSDTransfer: "Интизории интиқоли USSD",
    percent: "Фоиз",
    applyDiscount: "Тахфифро татбиқ кунед",
    thisBillHasBeenSaved: "Ин Билл захира карда шуд",
    saveDraft: "Лоиҳаро захира кунед",
    pleaseTypeANameToIdentifyCustomer:
      "Лутфан номеро нависед, то муштарӣ шинос шавад",
    paymentDetails: "Тафсилоти пардохт",
    basePrice: "Нархи асосӣ",
    staff: "Кормандон",
    subTotal: "Ҷамъбастӣ",
    durationMonths: "Давомнокӣ (моҳҳо)",
    selectADuration: "Давомнокиро интихоб кунед",
    oneMonth: "1 моҳ",
    twoMonths: "2 моҳ",
    threeMonths: "3 моҳ",
    sixMonths: "6 моҳ",
    twelveMonths: "12 моҳ",
    eighteenMonths: "18 моҳ",
    twentyFourMonths: "24 моҳ",
    twoMonthsFree: "(2 моҳ ройгон)",
    threeMonthsFree: "(3 моҳ ройгон)",
    fiveMonthsFree: "(5 моҳ ройгон)",
    yourAccountHasBeen: "Ҳисоби шумо буд",
    renewed: "нав",
    upgraded: "такмил дода шуд",
    successfully: "бомуваффақият",
    yourSubscription: "Обунаи шумо",
    youAreCurrentlyEnrolledOnA: "Шумо ҳоло дар a сабти ном шудаед",
    pleaseChooseAPaymentOption: "Лутфан як варианти пардохтро интихоб кунед",
    planRenewal: "Навсозии нақша",
    planUpgrade: "Навсозии нақша",
    pleaseSelectDurationToPayFor:
      "Лутфан Давомеро интихоб кунед, ки шумо мехоҳед пардохт кунед",
    staffAccounts: "Ҳисобҳои кормандон",
    ecommerce: "Тиҷорати электронӣ",
    pleaseSelectAPlan: "Лутфан нақша интихоб кунед",
    includeAddons: "Иловаҳоро илова кунед",
    viewTransactions: "Намоиши амалиётҳо",
    customerHasNoCompletedTansactions:
      "Муштарӣ то ҳол ягон амали анҷомдодашударо надорад",
    branch: "Филиал",
    enterNumberOfEcommerceBranches:
      "Шумораи филиалҳои тиҷорати электрониро ворид кунед",
    enterNumberOfEcommerceBranchesToPay:
      "Шумораи филиалҳои тиҷорати электрониро, ки шумо пардохт кардан мехоҳед, ворид кунед",
    ecommerceIntegration: "Интегратсияи тиҷорати электронӣ",
    enterNumberOfBranches: "Шумораи филиалҳоро ворид кунед",
    enterNumberOfAdditionalBranchesToPay:
      "Шумораи филиалҳои иловагие, ки шумо мехоҳед пардохт кунед, ворид кунед",
    enterNumberOfStaffs: "Шумораи кормандонро ворид кунед",
    enterNumberOfStaffsToPayFor:
      "Шумораи кормандоне, ки шумо мехоҳед пардохт кунед, ворид кунед",
    discountApplies: "Тахфиф амал мекунад",
    starsOnThe: "ситораҳо дар",
    offer: "пешниҳод",
    get: "Гирифтан",
    moreStarsToRedeem: "ситораҳои бештар барои наҷот додан",
    taxVat: "Андоз (ААИ)",
    callCashierToCompletePayment:
      "Барои анҷом додани пардохт ба кассир занг занед",
    receipt: "Квитансия",
    dear: "Азизам",
    thankYouForYourOrderFindGoods:
      "Ташаккур барои фармоиши шумо. Лутфан, мувофиқи мувофиқат молҳои зеринро дастрас кунед.",
    shippingNote: "Шарҳи интиқол",
    enterShippingNote: "Шарҳи интиқолро ворид кунед",
    shippingAddress: "Нишонаи расондан",
    enterShippingAddress: "Суроғаи интиқолро ворид кунед",
    wellDoneYouAreDueToRedeem: "Офарин! Шумо бояд ҷазо гиред",
    toGetYourRewardNextVisit:
      "то мукофоти худро дар сафари навбатии худ ба даст оред. сипос",
    pointsOnThe: "Нуқтаҳо дар",
    morePointsToRedeem: "нуқтаҳои бештар барои наҷот",
    showCode: "Нишон додани код",
    toGetYourRewardOnNextVisit:
      "то мукофоти худро дар сафари навбатии худ ба даст оред. сипос",
    earn: "Пул кор кардан",
    delivaryNote: "Эзоҳи расонидашуда",
    draftSales: "Лоиҳаи фурӯш",
    startDate: "Саршавии Сана",
    endDate: "Санаи анҷом",
    orders: "Фармоишҳо",
    checkout: "тафтиш",
    noProductItem: "Банди Маҳсулот нест",
    selectProductImage: "Тасвири Маҳсулотро интихоб кунед",
    selectCountry: "Кишварро интихоб кунед",
    selectRegion: "Давлат/минтақаро интихоб кунед",
    printProductTag: "Теги Маҳсулотро чоп кунед",
    transactionReference: "Маълумотнома оид ба амалиёт",
    Cashier: "Кассир",
    Manager: "Мудир",
    Owner: "Соҳиб",
    Admin: "Админ",
    addPartners: "Илова кардани шарикон",
    addNewLoyaltyPartner: "Илова кардани шарики нави вафодорӣ",
    pleaseEnterCompanyName: "Лутфан номи ширкатро ворид кунед",
    companyName: "номи ширкат",
    pleaseEnterCompanyRepName: "Лутфан номи намояндаи ширкатро ворид кунед",
    companyRepName: "Номи Намояндаи ширкат",
    pleaseEnterCompanyRepEmail:
      "Лутфан почтаи электронии намояндаи ширкатро ворид кунед",
    companyRepEmail: "Почтаи электронии намояндаи ширкат",
    pleaseEnterCompanyRepPhone:
      "Лутфан рақами телефони намояндаи ширкатро ворид кунед",
    companyRepPhone: "Рақами телефони намояндаи ширкат",
    addReward: "Мукофот илова кунед",
    pleaseEnterRewardName: "Лутфан номи мукофотро ворид кунед",
    rewardName: "Номи мукофот",
    rewardQuantity: "Миқдори мукофот",
    rewardDescription: "Тавсифи мукофот",
    rewardType: "Навъи мукофот",
    pleaseEnterRewardType: "Лутфан намуди мукофотро ворид кунед",
    pleaseEnterRewardQuantity: "Лутфан миқдори мукофотро ворид кунед",
    pleaseEnterRewardDescription: "Лутфан тавсифи мукофотро ворид кунед",
    fineDining: "Хӯроки зебо",
    luxuryFashion: "Мода боҳашамат",
    hotels: "Меҳмонхонаҳо",
    travel: "Сафар",
    foodAndBeverage: "Хӯрок ва нӯшокиҳо",
    fashion: "Мода",
    health: "Тандурустй",
    furniture: "Мебел",
    entertainment: "Вақтхушӣ",
    automobile: "Мошин",
    education: "Маориф",
    beautyAndSpa: "Зебоӣ ва Spa",
    staycation: "Истиқомат",
    events: "Ҳодисаҳо",
    trips: "Сафарҳо",
    oilAndGas: "Нефт ва газ",
    laundry: "Чомашуйхона",
    partnerCategory: "Категорияи шарик",
    freeItem: "Объекти ройгон",
  },
  Tatar: {
    cashier: "кассир",
    manager: "менеджер",
    owner: "хуҗасы",
    online: "Онлайн",
    offline: "оффлайн",
    changePassword: "Серсүзне алмаштырыгыз",
    currentPasswordMessage: "Зинһар, хәзерге серсүзне кертегез",
    passwordMessage: "Зинһар, серсүзне кертегез",
    currentPassword: "Хәзер кулланыла торган серсүз",
    password: "серсүз",
    confirmPasswordMessage: "Зинһар, серсүзегезне раслагыз!",
    confirmPassword: "серсүзне расла",
    sendViaEmail: "Электрон почта аша җибәрегез",
    sendViaWhatsapp: "WhatsApp аша җибәрегез",
    downloadPdf: "PDF йөкләү",
    paid: "түләнде",
    unpaid: "түләнмәгән",
    partial: "өлешчә",
    closeInvoice: "Счет-фактураны ябарга телисезме?",
    closeInvoiceConfirmation:
      "Счет-фактура сакланырга мөмкин түгел. Ябарга телисезме?",
    yes: "әйе",
    no: "юк",
    invoice: "Фактура",
    wasDeducted: "алынды",
    for: "өчен",
    item: "Предмет",
    addProduct: "Продукция өстәү",
    paymentReference: "Түләү турында белешмәлек",
    amountPaid: "Түләү күләме",
    discountAmount: "Ташлама күләме",
    amountDue: "Түләү күләме",
    amount: "Күләм",
    dueDate: "Түләү срогы",
    paymentType: "Түләү төре",
    card: "Карточка",
    cash: "Акча",
    bankTransfer: "Банк күчерүе",
    paymentMessage: "Түләү хәбәре",
    description: "Тасвирлау",
    sendReceipt: "Квитанция җибәр",
    delete: "Бетерү",
    save: "Саклагыз",
    resend: "Кабат җибәрү",
    saveAndSend: "Саклагыз",
    invoiceSaved: "Эсеп-фактурасы сакланган!",
    selectPaymentMethod: "Зинһар, Түләү ысулын сайлагыз!",
    selectCustomer: "Зинһар, клиентны сайлагыз!",
    cartEmptyError:
      "Арба исемлеге буш була алмый, счет-фактураны ябып, арбага пункт өстәгез!",
    subscriptionExpired:
      "Сезнең язылу вакыты бетте, счетыгыз хәзер чикләнгән. Хисап язмагызны яңарту өчен астагы төймәгә басыгыз",
    renew: "Яңарту",
    holdOn: "Ныгытып тотын",
    customerBank: "Клиентлар банкы",
    cancel: "Баш тарту",
    selectACustomer: "Клиентны сайлагыз",
    invoiceSuccessfulPdfError:
      "Счет-фактура уңышлы ясалган, ләкин PDF буын гадәттәгедән озаграк бара. Зинһар, тиздән тикшерегез.",
    downloadingInvoice: "Счет-фактураны йөкләү",
    downloadingReceipt: "Квитанцияне йөкләү",
    whatsappReceiptError:
      "Квитанцияне WhatsApp аша җибәрүдә хата килеп чыкты, зинһар, кабатлап карагыз.",
    receiptToWhatsapp: "Квитанция WhatsApp'ка җибәрелә",
    thankYouForPatronage: "Шефлыгыгыз өчен рәхмәт",
    hereIsYourReceipt: "Менә сезнең түләү квитанциясе",
    errorSendingEmailReceipt:
      "Квитанцияне электрон почта аша җибәрүдә хата килеп чыкты, зинһар, кабатлап карагыз яки ярдәмгә мөрәҗәгать итегез",
    receiptSentToEmail: "Квитанция клиентның электрон почтасына җибәрелгән",
    invoiceSentToEmail:
      "Счет-фактурасы клиентның электрон почтасына җибәрелгән",
    invoiceSuccessWhatsappError:
      "Счет-фактура уңышлы ясалды, ләкин WhatsApp'ка җибәрүдә хата килеп чыкты. Счет-фактуралар исемлегендә кабатлап карагыз",
    invoiceSuccessfulEmailError:
      "Счет-фактура уңышлы ясалды, ләкин электрон почта итеп җибәргәндә хата килеп чыкты. Зинһар, счет-фактуралар исемлегеннән кабатлап карагыз",
    invoiceSentToWhatsapp: "Счет-фактура WhatsApp-ка җибәрелгән",
    hello: "Сәлам",
    pleaseDownloadInvoice: "Зинһар, счет-фактураны йөкләгез",
    pleaseDownloadReceipt: "Зинһар, квитанцияне йөкләгез",
    from: "дан",
    email: "Электрон почта",
    upgrade: "Яңарту",
    youAreOnFreePlan: "Сез бушлай планда.",
    clickOn: "Басыгыз",
    yourPlanInFewSteps: " берничә тиз адымда сезнең язылу.",
    to: "to",
    yourSubscriptionHasExpired:
      "Сезнең язылу вакыты бетте, счетыгыз хәзер чикләнгән.",
    days: "көннәр",
    yourSubscriptionExpiresIn: "Сезнең Лойстарга язылу вакыты бетә",
    createAcount: "Хисап язма ясарга",
    signIn: "Керегез",
    continue: "Дәвам итегез",
    enterOtp: "OTP PIN кертегез",
    enterValidOtp: "Зинһар, дөрес PIN кертегез",
    pin: "PIN",
    tokenSentToMail: "Электрон почтагызга билге җибәрелде",
    passwordResetSuccessful: "Серсүзне яңадан торгызу уңышлы булды",
    somethingWentWrong: "Нәрсәдер дөрес булмаган!",
    resetPassword: "Серсүзне яңадан урнаштыру",
    iHaveResetCode: "Минем серсүзне яңадан торгызу коды бар",
    pleaseEnterEmail: "Зинһар, электрон почтагызны кертегез",
    aTokenWillBeSentToEmail: "Токен сезнең электрон почтагызга җибәреләчәк",
    enterEmail: "Электрон почтагызны кертегез",
    sendinYourToken: "Токен җибәрү ...",
    makeSureItMatchesPassword: "Аның яңа серсүзгә туры килүенә инаныгыз",
    pleaseChooseNewPassword: "Зинһар, яңа серсүз сайлагыз",
    chooseNewPassword: "Яңа серсүз сайлагыз",
    enterNewPassword: "Раслау өчен яңа серсүзне кертегез",
    enterTokenSent: "Почтагызны җибәргән билге кертегез",
    resetToken: "Токенны кире кайтару",
    resettingPassword: "Серсүзне кире кайтару ...",
    signUp: "Теркәлергә",
    adminSignInWithEmail:
      " Администратор электрон почта белән керә, ә персонал кулланучы исеме белән керә.",
    pleaseEnterEmailOrUsername:
      "Зинһар, электрон почтагызны яки кулланучы исемегезне кертегез",
    emailOrUsername: "Электрон почта яки кулланучы исеме",
    pleaseEnterPassword: "Зинһар, серсүз кертегез",
    createAnAccount: "Хисап язма ясарга",
    forgotPassword: "Серсүзне оныттыгызмы?",
    enterPhoneNumber: "Телефон номерын кертегез",
    personalData: "Шәхси мәгълүматлар",
    validateConfirmationCOde: "Тастыру коды",
    pleaseEnterFirstName: "Зинһар, беренче исемегезне кертегез",
    pleaseEnterPhoneNumber: "Зинһар, телефон номерыгызны кертегез",
    pleaseEnterLastName: "Зинһар, фамилиягезне кертегез",
    pleaseEnterBusinessName: "Зинһар, бизнес-исемегезне кертегез",
    firstName: "Исем",
    lastName: "Фамилия",
    businessName: "Эш исеме",
    previous: "Алдагы",
    next: "Чираттагы",
    pleaseSelectBusinessCategory: "Зинһар, сезнең бизнес категориясен сайлагыз",
    pleaseEnterValidEmail: "Зинһар, дөрес электрон почта кертегез",
    pleaseEnterPostCode: "Зинһар, почта кодын кертегез",
    postCode: "Почта коды",
    phoneNumberInUse: "Бу телефон номеры инде кулланыла!",
    emailInUse: "Бу электрон почта инде кулланыла!",
    foodAndDrinks: "Ашамлыклар һәм эчемлекләр",
    salonAndBeauty: "Салон һәм матурлык",
    fashionAndAccessories: "Мода һәм аксессуарлар",
    gymAndFitness: "Спорт залы һәм фитнес",
    travelAndHotel: "Сәяхәт һәм кунакханә",
    homeAndGifts: "Өй һәм бүләкләр",
    washingAndCleaning: "Hingу һәм чистарту",
    gadgetsAndElectronics: "Гаджетлар һәм электроника",
    groceries: "Азык-төлек",
    others: "Башкалар",
    submit: "Тапшыр",
    accountCreatedSuccessful: "Сезнең хисап уңышлы ясалды.",
    pleaseEnterAddress: "Зинһар, адресыгызны кертегез",
    addressLine1: "1 нче адрес юлы",
    addressLine2: "2 нче адрес",
    choosePassword: "Серсүзне сайлагыз",
    passwordMustBe6Characters: "Серсүз ким дигәндә 6 символ булырга тиеш.",
    howDidYouHearLoystar: "Лойстар турында ничек ишеттегез?",
    referralCode: "юллама коды",
    byClickingTheButton: " Түбәндәге төймәгә басып, сез ризалашасыз",
    termsAndSevice: "Шартлар",
    wereCreatingAccount: "Без сезнең счетыгызны ясыйбыз",
    proceed: "Алга",
    verificationCodeMustBe6: "Тикшерү коды 6 сан булырга тиеш",
    pleaseEnter6DigitCode: "Зинһар, 6 санлы код кертегез",
    enterVerificationCode: "Тикшерү коды кертегез",
    resendToken: "Токен җибәрү",
    verify: "Тикшерегез",
    transactions: "Транзакцияләр",
    todaySales: "Бүгенге сату",
    salesHistory: "Сату тарихы",
    supplyHistory: "Тарих белән тәэмин итү",
    new: "Яңа",
    invoices: "Счет-фактуралар",
    disbursements: "Түләү",
    offlineSales: "Оффлайн сату",
    products: "Продукция",
    customers: "Клиентлар",
    multiLevelLoyalty: "Күп дәрәҗә тугрылык",
    loyaltyPrograms: "Тугрылык программалары",
    members: "Әгъзалар",
    appStore: "Кушымта кибете",
    orderMenu: "Заказлар менюсы",
    settings: "Көйләүләр",
    staffAndBranches: "Кадрлар һәм филиаллар",
    myAccount: "Минем хисап язма",
    switchToSellMode: "Сату режимына күчә",
    signOut: "Чыгарга",
    getFreeSubscription: "Түләүсез язылу",
    onlyNumbersAllowed: "Саннар гына рөхсәт ителә",
    yourAccountMustBe10Digits:
      "сезнең хисап номерыгыз 10 санлы сан булырга тиеш",
    yourBvnMustBe11: "сезнең BVN 11 санлы сан булырга тиеш",
    pleaseSelectBank: "Зинһар, сезнең банкны сайлагыз",
    selectYourBank: "Сезнең Банкны сайлагыз",
    enterBankAccountNumber: "Банк счет номерын кертегез",
    enterBvnNumber: "БВНны кертегез",
    connectBankAccount: "Банк счетын тоташтырыгыз",
    passwordResetSuccessfulAndSignOut:
      "Сезнең серсүз уңышлы яңартылды. Кабат керү өчен астагы Чыгу төймәсенә басыгыз",
    enterCurrentPassword: "Хәзерге серсүзне кертегез",
    pleaseEnterCurrentPassword: "Зинһар, хәзерге серсүзне кертегез",
    phoneNumber: "Телефон номеры",
    sex: "Секс",
    dateOfBirth: "Туган көн",
    state: "Дәүләт",
    country: "Ил",
    loyaltyId: "Тугрылык таныклыгы",
    createdAt: "Atәр сүзнең",
    noOfTransactionsMade: "Эшләнгән операцияләр саны",
    yourDownloadWillStart: "Сезнең йөкләү бер мизгелдән башланачак",
    exportCustomers: "Клиентларны экспортлау",
    youhaveSuccessfullyToppedUp:
      "Сезнең SMS бүлекчәләрегезне уңышлы тупладыгыз.",
    youNowHave: "Сездә хәзер",
    smsUnits: "СМС бүлекчәләре",
    enterNumberOfSmsUnits:
      "Сез сатып алырга теләгән SMS берәмлекләрен кертегез",
    pleaseEnterANumericValue: "Зинһар, сан бәясен кертегез",
    pay: "Түләү",
    accountEditedSuccessfully: "Сезнең хисап уңышлы редакцияләнде.",
    youAeCurrentlyOn: "Сез хәзер",
    plan: "План",
    userData: "Кулланучы мәгълүматлары",
    businessData: "Бизнес мәгълүматлары",
    businessCategory: "Бизнес Катергоры",
    pleaseSelectCurrency: "Зинһар, валютагызны сайлагыз",
    selectYourCurrency: "Валютагызны сайлагыз",
    purchaseMoreSmsUnits:
      "Түбәндәге форманы кулланып күбрәк SMS берәмлекләрен сатып алыгыз",
    youHave: "Синдә бар",
    atLeast4SmsUnitsRrequired:
      "Тикшерү өчен ким дигәндә 4 смс берәмлек кирәк, өстәгез!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Зинһар өчен, сезнең клиентны белегез (KYC) таләпләрен үтәү өчен, банк счетыгызны тикшерегез. Бу сезгә USSD яки тиз арада күчерү аша түләүләр җыярга, клиентлардан заказлар алырга һәм операцияләрне чишү мөмкинлеген бирәчәк. Нигерия сәүдәгәрләренә генә кагыла. Зинһар, башлау өчен астагы төймәгә басыгыз.",
    reConnectBankAccount: "Банк счетын яңадан тоташтырыгыз",
    accountName: "Аккаунт исеме",
    accountNumber: "Хисап номеры",
    bankName: "Банк исеме",
    verified: "Тикшерелгән",
    accountDetails: "Хисап мәгълүматлары",
    kycBankAccount: "KYC",
    createTier: "Звено ясагыз",
    fileUploadSuccessful: "файл уңышлы йөкләнде",
    fileUploadFailed: "файл йөкләү уңышсыз",
    createLoyaltyProgram: "Тугрылык программасы төзегез",
    createLoyalty: "Тугрылык булдыр",
    name: "Исем",
    loyaltyArtwork: "Тугрылык",
    clickToUpload: "Йөкләү өчен басыгыз",
    amountToPointsRatio: "Баллар коэффициенты",
    points: "Баллар",
    recommendedAmountToPointRatio:
      "Тәкъдим ителә: ₦ 1 нокта. Ягъни, үткәрелгән һәр 1 Наира өчен сезнең клиентлар 1 балл ала",
    pleaseTypeIn: "Зинһар, языгыз",
    toDeleteLoyalty: "бу тугрылыкны бетерергә",
    deleteLoyalty: "Тугрылыкны бетер",
    toConfirm: "расларга",
    edit: "Редакцияләү",
    pointsAwardedSuccessfully: "Уңышлы бүләкләнде.",
    enterPointValueToAward:
      "Клиентларны бүләкләргә теләгән нокта бәясен кертегез",
    award: "Бүләк",
    awardPointValuesToCustomer: "Клиентка бүләк ноктасы",
    enrollMembersToLoyalty: "Тугрылыкка әгъзаларны языгыз",
    awardPoints: "Бүләкләү пунктлары",
    enroll: "Керегез",
    home: "Өй",
    loyalty: "Тугрылык",
    enrollCustomers: "Клиентларны теркәү",
    selected: "Сайланган",
    customer: "Клиент",
    loyaltyActivationSuccessful: "Тугрылыкны активлаштыру уңышлы.",
    loyaltyDeactivationSuccessful: "Тугрылыкны туктату уңышлы.",
    viewTier: "Дәресне карау",
    deactivate: "Активлаштыру",
    activate: "Активлаштыру",
    actions: "Эшләр",
    nameOfLoyalty: "Тугрылык исеме",
    loyaltyStatus: "Тугрылык статусы Тугрылык статусы",
    numberOfTiers: "Планнар саны",
    createdOn: "Ясалган",
    createATier: "Бер дәрәҗә ясагыз",
    stopCreatingTierConfirmation: "Сез дәрәҗә ясауны туктатырга телисезме?",
    stopEditingTierConfirmation:
      "Сез бу баскычны редакцияләүдән туктарга телисезме?",
    nameOfTier: "Дәрәҗә исеме",
    minimumSpendingTarget: "Минималь чыгым максаты",
    maximumSpendingTarget: "Максималь чыгым максаты",
    minimumSpendingTargetRequired: "минималь чыгым максаты таләп ителә",
    maximumSpendingTargetRequired: "максималь чыгым максаты таләп ителә",
    rewardSponsor: "Бүләк иганәчесе",
    pleaseChooseARewardSponsor: "Зинһар, бүләк спонсорын сайлагыз",
    self: "Selfзең",
    partner: "Партнер",
    rewardPartner: "Бүләкләү партнеры",
    pleaseSelectRewardPartner: "Зинһар, бүләкләү партнерыгызны сайлагыз",
    rewards: "Бүләкләр",
    pleaseSelectAReward: "Зинһар, бүләкне сайлагыз",
    instructionsOnRedeemingReward:
      "Клиент бүләкне ничек кайтарырга тиешлеге турында инструкция",
    pleaseFillInThisField: "Зинһар, бу кырны тутырыгыз!",
    toDeleteThisTier: " бу баскычны бетерергә",
    deleteTier: "Бетерү",
    viewMembers: "Әгъзаларны карау",
    membersEnrolled: "Кулланучылар",
    instruction: "Инструкция",
    membersIn: "Әгъзалар",
    availableTiersInLoyalty: "Тугрылык программасында булган дәрәҗәләр",
    tiers: "Дәресләр",
    totalTier: "Гомуми дәрәҗә",
    availableLoyaltyProgramme: "Мөмкинлек тугрылык программасы",
    totalLoyalties: "Гомуми тугрылык",
    memberDetails: "Әгъза турында мәгълүмат",
    nameOfCustomer: "Клиентның исеме",
    address: "Адрес",
    allEnrolledMembers: "Барлык теркәлгән әгъзалар",
    thisIsToWishYouHappyBirthday:
      "Бу сезгә туган көнегез белән, мул тормыш телим. Лойстерга булганнарыгыз өчен рәхмәт. Бәйрәмнәрегез белән!",
    inputAnOfferPlease: "Зинһар, тәкъдим кертегез",
    pleaseSelectTheInsertPoint:
      "Зинһар, хәбәрдәге кертү ноктасын сайлагыз һәм кабат басыгыз",
    birthdayOfferAndMessage: "Туган көн тәкъдиме һәм хәбәр",
    birthdayOffer: "Туган көн тәкъдиме",
    birthdayMessage: "Туган көн хәбәре",
    noOfferFound: "Тәкъдим табылмады",
    settingABirthdayOffer:
      "Туган көн тәкъдим итү клиентларга бу тәкъдимне туган көнендә SMS аша алырга мөмкинлек бирә",
    createOffer: "Тәкъдим итү",
    whatIsTheOffer: "Нәрсә ул?",
    editMessage: "Хәбәрне үзгәртү",
    insert: "Керү",
    theNameOfCustomerInserted: "Клиентның исеме монда кертеләчәк",
    theBirtdayOfferInserted: "Туган көн тәкъдиме монда кертеләчәк",
    youSuccessfullyAddedNewBranch: "Сез яңа филиалны уңышлы өстәдегез!",
    addNewBranch: "Яңа филиал өстәргә",
    addBranch: "Филиал өстәгез",
    additionalBranchWillIncur: "Өстәмә филиал",
    perBranch: "филиалга",
    ecommerceBranchCosts: "Электрон сәүдә бүлеге чыгымнары",
    pleaseEnterBranchName: "Зинһар, филиал исемен кертегез",
    pleaseEnterBranchAddress1: "Зинһар, филиалның адрес сызыгын кертегез",
    enterBranchAddress1: "Филиалның адрес сызыгын кертегез",
    enterBranchAddress2: "Филиалның адрес сызыгын кертегез",
    pleaseEnterBranchAddress2: "Зинһар, филиалның адрес сызыгына керегез",
    enterBranchName: "Филиал исемен кертегез",
    successfullyAddedStaff: "Сез яңа персоналны уңышлы өстәдегез!",
    addNewStaff: "Яңа персонал өстәргә",
    addStaff: "Кадрлар өстәү",
    additionalStaffWillIncur: "Өстәмә персонал",
    perStaff: "бер хезмәткәргә.",
    pleaseEnterStaffEmail: "Зинһар, хезмәткәрләрнең электрон почтасын кертегез",
    pleaseEnterStaffUsername: "Зинһар, персоналның кулланучы исемен кертегез",
    pleaseEnterStaffPassword: "Зинһар, персонал серсүзен кертегез",
    pleaseSelectStaffRole: "Зинһар, персоналның ролен сайлагыз",
    selectStaffRole: "Персонал ролен сайлагыз",
    enterStaffEmail: "Персоналның электрон почтасын кертегез",
    enterStaffUsername: "Персоналның кулланучы исемен кертегез",
    enterStaffPassword: "Персонал серсүзен кертегез",
    spacesNotAllowedInUsername: "кулланучы исемендә рөхсәт ителмәгән урыннар",
    admin: "Админ",
    pleaseSelectBusinessToAttachStaff:
      "Зинһар, персоналны бәйләү өчен бизнесны сайлагыз",
    searchForBranchToAttachStaff: "Персоналны бәйләү өчен филиал эзләгез",
    username: "Кулланучы исеме",
    role: "Роль",
    areYouSureToDeleteThis: "Сез моны бетерәсезме?",
    branches: "Филиаллар",
    upgradeYourPlan: "Планыңны яңарт.",
    add: "Кушымта",
    addNew: "Яңа өстәргә",
    customerWithEmailAlreadyExists:
      "Электрон почта / телефон номеры булган клиент бар!",
    successfullyAddedNewCustomer: "Сез яңа клиентны уңышлы өстәдегез!",
    addCustomer: "Клиент өстәү",
    pleaseEnterCustomerFirstName: "Зинһар, клиентның исемен кертегез",
    pleaseEnterCustomerLastName: "Зинһар, клиентның фамилиясен кертегез",
    pleaseEnterCustomerPhoneNumber:
      "Зинһар, клиентның телефон номерын кертегез",
    pleaseEnterCustomerEmail: "Зинһар, клиентның электрон почтасын кертегез",
    pleaseEnterCustomerAddressLine: "Зинһар, клиентның адрес линиясен кертегез",
    pleaseEnterCustomerOtherAddress: "Зинһар, клиентның бүтән адресын кертегез",
    pleaseSelectCustomerGender: "Зинһар, клиентның җенесен сайлагыз",
    gender: "Гендер",
    male: "Ир-ат",
    female: "Хатын-кыз",
    bank: "Банк",
    selectBank: "Банкны сайлагыз",
    stateOrRegionOrProvince: "Дәүләт / Төбәк / Өлкә",
    customerNotes: "Клиент язмалары",
    sendSms: "SMS җибәрегез",
    editCustomer: "Клиентны үзгәртү",
    redeemReward: "Бүләкне кулланыгыз",
    issueLoyaltyCard: "Тугрылык картасы",
    deleteCustomer: "Клиентны бетерү",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Сез тугры әгъзалык таныклыгын уңышлы билгеләдегез",
    noMembershipIdAvailable:
      "Әгъзалык таныклыгы юк. Зинһар, безнең белән hello@loystar.co адресына мөрәҗәгать итегез",
    sendEmail: "Электрон почта җибәрегез",
    membershipPoints: "Әгъзалык пунктлары",
    customerDetails: "Клиент турында мәгълүмат",
    close: "Ябу",
    loyaltyBalance: "Тугрылык балансы",
    pointsBalance: "Баллар балансы",
    starBalance: "Йолдыз балансы",
    requiredPoints: "Кирәкле пунктлар",
    requiredStars: "Кирәкле йолдызлар",
    reddemCode: "Кодны кайтару",
    toDeleteThisCustomer: "бу клиентны бетерергә",
    customerHasBeenDeletedSuccessfully: "Клиент уңышлы бетерелде!",
    customerWithPhoneAlreadyExists: "Телефон номеры булган клиент инде бар",
    customerWasSuccessfullyEdited: "Клиент уңышлы редакцияләнде",
    anErrorOccured: "Хата килеп чыкты",
    phoneNumberIncludeCountry: "Телефон номеры (Ил коды кертегез)",
    yourFileQueuedForUpload:
      "Сезнең файл йөкләү өчен чиратта торды. Зинһар, берничә секундтан соң битне яңартыгыз.",
    thereWasAnErrorPerformingOperation: "Операцияне башкаруда хата бар!",
    pleaseSelectFile: "Зинһар, Файлны сайлагыз!",
    oopsOnlyCsvFilesAllowed:
      "Ой! Бары тик CSV файллары рөхсәт ителә. Зинһар, .csv файлын йөкләгез.",
    fileShouldBeSmallerThan5Mb:
      "Файл 5МБдан кечерәк булырга тиеш. Әгәр дә сезнең зуррак файл булса, зинһар, support@loystar.co электрон почтасына җибәрегез. Рәхмәт",
    customerFirstNameIsRequired: "Клиентның исеме рәттә кирәк",
    customerPhoneNumberIsRequired: "клиентның телефон номеры рәттә кирәк",
    importCustomers: "Клиентларны импортлау",
    upload: "Йөкләү",
    clickIconToDownloadCsv: "CSV файл шаблонын йөкләү өчен бу иконага басыгыз.",
    getGoogleSheetVersion: "Google таблицасының версиясен алыгыз",
    clickOrDragFileToUpload: "Йөкләү өчен файлны бу өлкәгә басыгыз яки сузыгыз",
    missingOrInvalidColumnHeader:
      "Columnгалган яки яраксыз багана башы. Зинһар, шаблон форматына иярегез. Рәхмәт.",
    youHaveImported: "Сез импортладыгыз",
    youSuccessfullyRedeemedReward: "Сез бүләкне уңышлы кулландыгыз!",
    sixDigitCode: "Алты санлы код",
    pleaseEnterCustomerRewardCode: "Зинһар, клиентның бүләкләү кодын кертегез",
    enterRewardCode: "Бүләк кодын кертегез. (Бүләк коды зур)",
    selectLoyaltyProgram: "Тугрылык программасын сайлагыз",
    stamps: "Маркалар",
    smsUnitsLow: "СМС берәмлекләре түбән",
    pleaseTopUpSmsUnits: "Зинһар, SMS бүлекчәләрен өстәгез",
    smsSuccessfullySentForDelivery: "СМС уңышлы җибәрү өчен җибәрелде!",
    sendSmsTo: "SMS җибәрегез",
    allCustomers: "Барлык клиентлар",
    unitsAvailable: "Бүлекләр бар",
    pleaseTypeInTheMessage: "Зинһар, хәбәр языгыз",
    message: "Хәбәр",
    charactersRemaining: "калган персонажлар",
    avoidUseOfSpecialCharacters:
      "СМС бүлекләрен саклау өчен махсус персонажлар һәм эможалар кулланудан сакланыгыз.",
    note: "Тамга",
    errorFetchingCustomersMultilevelDetail:
      "Клиентларны MultiLevel Детальләрен алуда хата",
    search: "Эзләү",
    reset: "Бетерү",
    importCustomer: "Клиентны импортлау",
    addNewCustomer: "Яңа клиент өстәргә",
    sendSmsBroadcast: "SMS тапшыруы җибәрегез",
    totalCustomers: "Гомуми клиентлар",
    disbursementDetails: "Түләү турында мәгълүмат",
    paidBy: "Түләүле",
    disbursed: "Тапшырылган",
    bankAccountName: "Банк счеты исеме",
    bankAccountNumber: "Банк счеты номеры",
    transferInitiated: "Күчерү башланды",
    transferCompleted: "Күчерү тәмамланды",
    pleaseEnterAValid: "Зинһар, дөресне кертегез",
    begin: "башларга",
    end: "ахыр",
    date: "дата",
    paymentDate: "Түләү датасы",
    selectDisbursementDuration: "Түләү вакытын сайлагыз",
    totalSettled: "Барлыгы урнаштырылган",
    totalUnsettled: "Барлык тәртипсез",
    toDeleteThisSale: "бу сатуны бетерергә",
    draftSaleDeletedSuccessfully: "Сату проекты уңышлы бетерелде!",
    deleteSale: "Сатуны бетерү",
    pleaseTypeInYourTotal: "Бетерүне раслау өчен зинһар",
    billDetails: "Хисап мәгълүматлары",
    printBill: "Биллны бастыру",
    servedBy: "Хезмәт итә",
    total: "Барлыгы",
    createdDate: "Ясалган дата",
    createdTime: "Вакыт",
    status: "Статус",
    loginSuccessful: "Керү уңышлы",
    pleaseWaitWhileWeConnectAccount:
      "Зинһар, без сезнең счетыгызны тоташтырганда көтегез",
    connectionFailedTryAgain: "Тоташу уңышсыз. Зинһар, кабатлап карагыз.",
    connectionSuccessful: "Бәйләнеш уңышлы",
    viewDetails: "Детальләрне карау",
    enable: "Килеш",
    free: "Бушлай",
    cost: "Бәясе",
    visitWebsite: "Вебсайтка керегез",
    pleaseUpgradeYourPlanToPro:
      "Зинһар, бу кушымтаны эшләтеп җибәрү өчен планыгызны Pro Plus-ка яңартыгыз",
    connectYourBankAccount: "Түләүләр алу өчен Банк счетыгызны тоташтырыгыз.",
    disable: "Сүндер",
    enableApp: "Кушымтаны кушу",
    addNewProductToInvoice: "Счет-фактурага яңа продукт өстәгез",
    toDeleteThisInvoice: "бу счет-фактураны бетерергә",
    invoiceDeletedSuccessfully: "Счет-фактура уңышлы бетерелде!",
    deleteInvoice: "Счет-фактураны бетерү",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Сез счет-фактура түләү хәбәрен уңышлы өстәдегез!",
    pleaseEnterPaymentInstructions: "Зинһар, түләү күрсәтмәләрен кертегез",
    invoiceId: "Фактура таныклыгы",
    paidDate: "Түләү вакыты",
    reference: "Сылтама",
    productAdded: "Продукция өстәлде",
    productOutOfStock: "Продукция запастан. Зинһар, яңадан запас итегез.",
    totalInvoices: "Гомуми чакырулар",
    totalPaidInvoices: "Гомуми түләүле чакырулар",
    totalUnpaidInvoices: "Гомуми түләүсез чакырулар",
    loyaltyProgramDeleted: "Тугрылык программасы бетерелде",
    thisLoyaltyProgramDeletedSuccessfully:
      "Бу тугрылык программасы уңышлы бетерелде",
    thisLoyaltyProgramEditedSuccessfully:
      "Бу тугрылык программасы уңышлы редакцияләнде",
    loyaltyProgramAddedSuccessfully: "Тугрылык программасы уңышлы өстәлде",
    loyaltyProgramEdited: "Тугрылык программасы редакцияләнде",
    loyaltyProgramAdded: "Тугрылык программасы өстәлде",
    loyaltyDetails: "Тугрылык турында мәгълүмат",
    doYouWantToCloseDialog: "Бу диалогны ябарга телисезме?",
    pleaseEnterLoyaltyName: "Зинһар, сезнең тугрылык исемен кертегез",
    programType: "Программа төре",
    pleaseSelectProgramType: "Зинһар, программа төрен сайлагыз",
    simplePoints: "Гади пунктлар",
    stampsProgram: "Маркалар программасы",
    threshold: "Бусага",
    pleaseEnterLoyaltyThreshold: "Зинһар, тугрылык бусагасын кертегез",
    reward: "Бүләк",
    pleaseEnterLoyaltyReward: "Зинһар, тугрылык бүләкләрен кертегез",
    totalUserPoints: "Гомуми кулланучы пунктлары",
    totalUserStamps: "Гомуми кулланучы маркалары",
    spendingTarget: "Сарыф итү максаты",
    spendingTargetHint1:
      "Бүләк алу өчен клиент күпме сарыф итәргә тиеш. 1 валюта бәясе = 1 балл.",
    spendingTargetHint2:
      "Маркаларның максаты - бүләк алу өчен клиент күпме маркалар җыярга тиеш. мәс. 5",
    addNewLoyaltyProgram: "Яңа тугрылык программасын өстәгез",
    addLoyaltyProgram: "Тугрылык программасын өстәгез",
    loyaltyProgramType: "Тугрылык программасы төре",
    pleaseSelectLoyaltyProgramType:
      "Зинһар, тугрылык программасының төрен сайлагыз",
    nameOfProgram: "Программаның исеме",
    pleaseEnterProgramName: "Зинһар, программа исемен кертегез",
    whatIsTheReward: "Бүләк нинди?",
    egNextPurchaseIsFree: "Мәсәлән, Киләсе сатып алу бушлай",
    customerName: "Клиент исеме",
    guestCustomer: "Кунак клиенты",
    orderConfirmedSuccessfully: "Заказ уңышлы расланды",
    orderCancelledSuccessfully: "Заказ уңышлы юкка чыгарылды",
    confirmOrder: "Тәртипне раслагыз",
    cancelOrder: "Заказны кире кагу",
    allOrders: "Барлык заказлар",
    totalOrders: "Гомуми заказлар",
    doYouWantToAcceptOrder: "Сез бу заказны кабул итәсегез киләме?",
    doYouWantToCancelOrder: "Сез бу заказны юкка чыгарырга телисезме?",
    orderDetails: "Заказ детальләре",
    orderCreatedAt: "Заказ",
    supplier: "Тапшыручы",
    productName: "Продукциянең исеме",
    quantity: "Сан",
    unitPrice: "Бер әйбер бәясе",
    receivedBy: "Кабул иттеләр",
    reportFrom: "Доклад",
    totalSupplies: "Барлык тәэмин итү",
    allRightsReserved: "Барлык хокуклар якланган",
    toDeleteThisTransaction: "бу Транзакцияне бетерергә",
    transactionDeletedSuccessfully:
      "Транзакция уңышлы бетерелде. Акция инвентаризациягә кайтарылды.",
    deleteTransaction: "Транзакцияне бетерегез",
    transactionDetails: "Транзакция детальләре",
    printReceipt: "Квитанцияне бастыру",
    channel: "Канал",
    discount: "Ташлама",
    profit: "Табыш",
    discountedSales: "Ташламалы сату",
    errorFetchingRecord: "Рекордны алуда хата",
    exportTransactions: "Экспорт операцияләре",
    errorFetchingSalesRecord: "Экспорт өчен сату рекорды алуда хата.",
    totalSellingPrice: "Сатуның гомуми бәясе",
    totalCostPrice: "Гомуми бәя",
    appliedDiscount: "Гамәли ташлама",
    noOfItems: "Itemsк",
    sales: "Сату",
    export: "Экспорт",
    totalProfit: "Гомуми табыш",
    totalBalanceInPeriod: "Вакыт эчендә гомуми баланс",
    allTimeSales: "Барлык вакыт сату",
    records: "Язмалар",
    todaysSales: "Бүгенге сату",
    transaction: "транзакция",
    youHaveExceededTotalNumberOfProducts:
      "Сез планыгызда рөхсәт ителгән продуктларның гомуми саныннан арттыгыз. Higherгары лимиттан файдалану өчен планыгызны яңартыгыз.",
    youNeedToHaveLoyaltyProgram:
      "Бу функцияне куллану өчен сезгә тугрылык программасы булырга тиеш!",
    price: "Бәя",
    category: "Төркем",
    stockTracking: "Акцияне күзәтү",
    stockCount: "Акция санау",
    taxed: "Салым салынган",
    originalPrice: "Оригиналь бәя",
    costPrice: "Кыйммәт бәясе",
    unit: "Берәмлек",
    productImage: "Продукция рәсеме",
    taxRate: "Салым ставкасы",
    taxType: "Салым төре",
    trackInventory: "Инвентаризацияне күзәтегез",
    variants: "Вариантлар",
    hasVariants: "Вариантлары бар",
    importProduct: "Продукцияне импортлау",
    exportProducts: "Экспорт продуктлары",
    addNewProduct: "Яңа продукт өстәргә",
    viewCategory: "Төркемне карау",
    viewSuppliers: "Тапшыручыларны карау",
    receiveInventory: "Инвентаризация алыгыз",
    printAllProductsTag: "Барлык продуктларны да бастырыгыз",
    deleteAll: "Барысын да бетерегез",
    totalProducts: "Гомуми продуктлар",
    youveSuccessfullyAddedANewCategory: "Сез яңа категорияне уңышлы өстәдегез",
    addNewCategory: "Яңа категория өстәгез",
    addCategory: "Төркем өстәү",
    categoryName: "Төркем исеме",
    pleaseEnterCategoryName: "Зинһар, категория исемен кертегез",
    stampsTarget: "Маркалар максаты",
    sendInventory: "Инвентаризация җибәр",
    productDetails: "Продукция турында мәгълүмат",
    youveSuccessfullyEditedThisCategory:
      "Сез бу категорияне уңышлы редакцияләдегез",
    update: "Яңарту",
    categoryList: "Төркем исемлеге",
    categories: "Төркемнәр",
    enterQuantityToUpdate: "Яңарту өчен сан кертегез",
    inventorySentSuccessfully: "Инвентаризация уңышлы җибәрелде!",
    updateInventory: "Инвентаризацияне яңарту",
    currentQuantity: "Хәзерге сан",
    pleaseEnterQuantityToAdd: "Зинһар, өстәргә теләгән күләмне кертегез",
    pleaseSelectABranch: "Зинһар, филиал сайлагыз",
    searchForBranch: "Филиал эзләү",
    youCantSendMoreThanStock: "Сез запастагыдан күбрәк җибәрә алмыйсыз",
    send: "Sendибәр",
    pleaseEnterQuantityToSend: "Зинһар, җибәрергә теләгән күләмне кертегез",
    productNameIsRequiredOnRow: "продукт исеме рәттә кирәк",
    productCategoryIsRequiredOnRow: "продукт категориясе рәттә кирәк",
    productPriceIsRequiredOnRow: "продукт бәясе рәттә кирәк",
    productUnitIsRequiredOnRow: "продукт берәмлеге рәттә кирәк",
    productQuantityIsRequiredOnRow: "продукт саны рәттә кирәк",
    productVariantsRequireTracking: "продукт вариантлары күзәтүне таләп итә!",
    pleaseAddVariantClickButton:
      "Зинһар, өстәү төймәсенә басып вариантны өстәгез!",
    totalVariantsMoreThanSelectedQuantity:
      "Гомуми вариантлар сайланган продукт саныннан күбрәк, Pls вариант санын киметә",
    productEditedSuccessfully: "Продукция уңышлы редакцияләнде!",
    fileTooLargeLessThan4Mb:
      "Файл зурлыгы бик зур! Файлның зурлыгы 4МБдан ким булырга тиеш.",
    suchVariantAlreadyExist: "Мондый вариант бар",
    addVariants: "Вариантларны өстәгез",
    editProduct: "Продукцияне үзгәртү",
    pleaseEnterProductName: "Зинһар, продукт исемен кертегез",
    pleaseEnterProductPrice: "Зинһар, продукт бәясен кертегез",
    pleaseEnterProductOriginalPrice:
      "Зинһар, продуктның оригиналь бәясен кертегез",
    productDescription: "Җитештермә тасвирламасы",
    selectProductCategory: "Продукция категориясен сайлагыз",
    pleaseSelectProductCategory: "Зинһар, продукт категориясен сайлагыз",
    productCostPrice: "Продукция бәясе",
    productSellingPrice: "Продукция сату бәясе",
    productOriginalPrice: "Продукциянең оригиналь бәясе",
    maxFileSizeAllowedIs4Mb: "Макс. Файлның размеры 4мб",
    productsWithPicturesArePublished:
      "Рәсемнәр белән продуктлар заказ алу өчен Discover сайтында бастырыла",
    shouldThisProductBeTracked: "Бу продуктны күзәтергә кирәкме?",
    pleaseSelectStockUnit: "Зинһар, запас берәмлеген сайлагыз",
    stockUnit: "Акция бүлеге",
    bag: "БАГ",
    bottle: "Бутылка",
    bunch: "Бунч",
    can: "БУЛА",
    carton: "КАРТОН",
    crate: "ЯРАТУ",
    cup: "CUP",
    dozen: "ДОЗЕН",
    gigabytes: "ГИГАБЫТ",
    gram: "ГРАМ",
    kilogram: "Килограм",
    litre: "Әдәбият",
    pack: "ПАК",
    pair: "ПАИР",
    pieces: "PIECES",
    plate: "ПЛЕТА",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "Ишегалды",
    pleaseEnterProductQuantity: "Зинһар, продукт күләмен кертегез",
    productQuantity: "Продукция саны",
    isThisProductTaxed: "Бу продуктка салым салынганмы?",
    selectTaxType: "Салым төрен сайлагыз",
    pleaseSelectTaxType: "Зинһар, салым төрен сайлагыз",
    progressive: "Прогрессив",
    proportional: "Пропорциональ",
    pleaseEnterProductTaxRate: "Зинһар, продуктка салым ставкасын кертегез",
    doesProductHaveVariants: "Бу продуктның вариантлары бармы?",
    type: "Тип",
    value: "Кыйммәт",
    pleaseEnterVariantType: "Зинһар, вариантның төрен кертегез",
    pleaseEnterVariantValue: "Зинһар, вариантның кыйммәтен кертегез",
    pleaseEnterVariantPrice: "Зинһар, вариант бәясен кертегез",
    pleaseEnterVariantQuantity: "Зинһар, вариантның күләмен кертегез",
    productDeletedSuccessfully: "Продукция уңышлы бетерелде!",
    categoryDeletedSuccessfully: "Төркем уңышлы бетерелде!",
    toDeleteThisProduct: "бу продуктны бетерергә",
    invalidProductQuantity: "Продукциянең яраксызлыгы",
    quantityAddedIsOverStock: "Сез өстәгән күләм сезнең запастагыдан артык.",
    itemInventoryNotTracked: "Предмет инвентаризациясе күзәтелми",
    addBulkQuantity: "Күп санны өстәгез",
    enterBulkQuantity: "Күп санны кертегез",
    pleaseEnterBulkQuantity: "Зинһар, күпчелек санны кертегез",
    youveSuccessfullyAddedANewProduct: "Сез уңышлы яңа продукт өстәдегез!",
    pleaseEnterProductSellingPrice: "Зинһар, продукт сату бәясен кертегез",
    doYouWantToTrackProductStock: "Сез продукт запасын күзәтергә телисезме?",
    sellingPrice: "Сату бәясе",
    setProductExpiryReminder: "Продукциянең срокы турында искә төшерү",
    productExpiryDate: "Продукциянең вакыты",
    startRemindingFrom: "Искә төшерә башлагыз",
    productSuppliesAddedSuccessfully:
      "Сез продукт белән тәэмин итүне уңышлы өстәдегез.",
    addProductSupplies: "Продукция кирәк",
    pleaseSelectSupplier: "Зинһар, тәэмин итүчене сайлагыз",
    nameOfProduct: "Продукциянең исеме",
    pleaseSelectProduct: "Зинһар, продуктны сайлагыз",
    productVariant: "Продукция варианты",
    pleaseSelectAVariant: "Зинһар, вариантны сайлагыз",
    pleaseEnterUnitPrice: "Зинһар, берәмлек бәясен кертегез",
    businessBranch: "Бизнес филиалы",
    pleaseSelectBranch: "Зинһар, филиалны сайлагыз",
    youveSuccessfullyAddedANewSupplier:
      "Сез уңышлы яңа тәэмин итүчене өстәдегез",
    addSupplier: "Тапшыручыны өстәгез",
    pleaseEnterSupplierEmail:
      "Зинһар, тәэмин итүченең электрон почтасын кертегез",
    pleaseAddADescription: "Зинһар, тасвирлама өстәгез",
    anErrorOccuredProductsDeleted:
      "Операция ясаганда хата килеп чыкты. Зинһар, игътибар итегез, процесста бик аз продукт бетерелгән булырга мөмкин",
    bulkDelete: "Күпләп бетерү",
    youAreAboutToDelete: "Сез бетерергә җыенасыз",
    product: "Продукция",
    isDueToRedeem: "сатып алырга тиеш",
    aReward: "бүләк",
    pleaseSelectCustomerToReeemReward: "Бүләкне алу өчен клиентны сайлагыз.",
    youHaveNoLoyaltyProgramRunning: "Сездә актив тугрылык программасы юк",
    customerHhasNoPointsInLoyaltyProgram:
      "Бу тугрылык программасында клиентның баллары юк",
    proceedWithPayment: "Түләү белән дәвам итәсезме?",
    youAreAboutToPayForTransactionUsingPoints:
      "Сез баллар кулланып транзакция өчен түләргә җыенасыз.",
    customerHas: "Клиент бар",
    worth: "кыйммәт",
    andIsNotSufficientToPayForTransaction:
      "һәм бу операция өчен түләү өчен җитәрлек түгел. Башка түләү ысулы ярдәмендә баланс өстәргә теләрләрме?",
    addCustomerLoyalty: "Клиентларның тугрылыгын өстәгез",
    pleaseAddCustomerFirst: "Зинһар, башта клиентны өстәгез яки сайлагыз.",
    pleaseWaitWhileWeGetReady: "Зинһар, көтегез, без әзерләнгәндә",
    lowStock: "Түбән акция",
    pleaseEnterAmountTendered: "Зинһар, тендерланган сумманы кертегез",
    areYouSureToBookSaleOffline:
      "Сез бу сатуны оффлайнда броньларга телисезме?",
    saleWouldBeBookedAutomatically:
      "Интернетны кабызганда сату автоматик рәвештә броньланыр иде",
    offlineSalesBookingCancelled: "Оффлайн сатуны броньлау юкка чыгарылды",
    covid19Message:
      "COVID19: таралуны туктатыр өчен кулларыгызны сабын белән юыгыз яки Санитизацияләгез. Waysәрвакыт куркынычсыз булыгыз",
    saleSuccessfullyRecorded: "Сату уңышлы язылган!",
    sendReceiptToEmail: "Электрон почтага квитанция җибәрегез",
    sendThankYouSms: "Рәхмәт SMS җибәрегез",
    sendShippingDetails: "Тапшыру детальләрен җибәрегез",
    addLoyalty: "Тугрылык өстәгез",
    searchCustomerNameOrNumber: "Клиент исемен яки номерын эзләгез",
    clickTheSearchCustomerBox: "Клиентларны эзләү һәм сканер картасын басыгыз",
    enterProductPrice: "Продукт бәясен кертегез",
    enterPriceFor: "Бәясен кертегез",
    searchForProduct: "Продукция эзләү",
    all: "Барысы да",
    backToDashboard: "Тактага кире кайту",
    viewDraftSales: "Сату проектын карау",
    variantSelected: "вариант сайланган",
    variant: "вариант",
    thePreviousVariantSelectionNotAvailable:
      "Алдагы вариант сайлау бәягә карап сайланган яңа вариант өчен мөмкин түгел, зинһар, сайлаугызны үзгәртегез.",
    pleaseSupplyPositiveQuantityNumber: "Зинһар, постив санны күрсәтегез",
    lowStockFor: "Аз запас",
    clearVariants: "Вариантларны чистарту",
    pleaseEnterQuantity: "Зинһар, санны кертегез",
    picture: "Рәсем",
    redemptionToken: "Котылу билгесе",
    token: "Токен",
    totalSpent: "Барлык чыгымнар",
    confirmPayment: "Түләүне раслагыз",
    hasPaymentBeenMade: "Түләү уңышлы эшкәртелгәнме?",
    enterTransactionReference: "Сез түләгән транзакция белешмәсен кертегез",
    pleaseSelectACustomer: "Зинһар, клиент сайлагыз!",
    areYouSureToApplyDiscount: "Сез ташлама кулланырга телисезме?",
    addYourBankAccountToEnableUssd:
      "UPay тарафыннан тиз арада USSD күчерү өчен банк счетыгызны өстәгез",
    totalAmountToPay: "Түләүнең гомуми күләме",
    doYouWantToCancelTransaction: "Сез бу операцияне юкка чыгарырга телисезме?",
    savePrintBill: "Саклагыз / Биллны бастырыгыз",
    enterAmountCollectedForCustomer: "Клиент өчен җыелган сумманы кертегез",
    recordSale: "Рекорд сату",
    checkOutWith: "Белән карагыз",
    instantTransfer: "Тиз күчерү",
    dialTheUSSDCode: "USSD кодын терегез",
    pleaseSelectABank: "Зинһар, банк сайлагыз",
    payWithUSSD: "USSD белән түләү",
    sendBillTo: " - Билл җибәрегез",
    waitingForUSSDTransfer: "USSD Күчерүне көтә",
    percent: "Процент",
    applyDiscount: "Ташлама кулланыгыз",
    thisBillHasBeenSaved: "Бу закон проекты сакланган",
    saveDraft: "Саклагыз",
    pleaseTypeANameToIdentifyCustomer:
      "Зинһар, клиентны ачыклау өчен исем языгыз",
    paymentDetails: "Түләү турында мәгълүмат",
    basePrice: "Төп бәя",
    staff: "Персонал",
    subTotal: "SubTotal",
    durationMonths: "Озынлыгы (айлар)",
    selectADuration: "Озынлыкны сайлагыз",
    oneMonth: "1 ай",
    twoMonths: "2 ай",
    threeMonths: "3 ай",
    sixMonths: "6 ай",
    twelveMonths: "12 ай",
    eighteenMonths: "18 ай",
    twentyFourMonths: "24 ай",
    twoMonthsFree: "(2 ай бушлай)",
    threeMonthsFree: "(3 ай бушлай)",
    fiveMonthsFree: "(5 ай бушлай)",
    yourAccountHasBeen: "Сезнең Хисап",
    renewed: "яңартылды",
    upgraded: "яңартылды",
    successfully: "уңышлы",
    yourSubscription: "Сезнең язылу",
    youAreCurrentlyEnrolledOnA: "Сез хәзерге вакытта а",
    pleaseChooseAPaymentOption: "Зинһар, Түләү вариантын сайлагыз",
    planRenewal: "Яңарту планы",
    planUpgrade: "Планны яңарту",
    pleaseSelectDurationToPayFor:
      "Зинһар, сез түләргә теләгән вакытны сайлагыз",
    staffAccounts: "Кадрлар счетлары",
    ecommerce: "Электрон сәүдә",
    pleaseSelectAPlan: "Зинһар, план сайлагыз",
    includeAddons: "Кушымталар кертегез",
    viewTransactions: "Транзакцияләрне карау",
    customerHasNoCompletedTansactions:
      "Клиентның әле тәмамланмаган танклары юк",
    branch: "Филиал",
    enterNumberOfEcommerceBranches: "Электрон сәүдә филиаллары санын кертегез",
    enterNumberOfEcommerceBranchesToPay:
      "Сез түләргә теләгән сәүдә филиалларының санын кертегез",
    ecommerceIntegration: "Электрон сәүдә интеграциясе",
    enterNumberOfBranches: "Филиаллар санын кертегез",
    enterNumberOfAdditionalBranchesToPay:
      "Сез түләргә теләгән өстәмә филиаллар санын кертегез",
    enterNumberOfStaffs: "Кадрлар санын кертегез",
    enterNumberOfStaffsToPayFor: "Сез түләргә теләгән персонал санын кертегез",
    discountApplies: "Ташлама кулланыла",
    starsOnThe: "йолдызлар",
    offer: "тәкъдим",
    get: "Ал",
    moreStarsToRedeem: "сатып алу өчен күбрәк йолдызлар",
    taxVat: "Салым (НДС)",
    callCashierToCompletePayment: "Түләү өчен кассирга шалтыратыгыз",
    receipt: "Квитанция",
    dear: "Кадерле",
    thankYouForYourOrderFindGoods:
      "Заказ өчен рәхмәт. Зинһар, китерелгән товарны килешегез.",
    shippingNote: "Тапшыру язмасы",
    enterShippingNote: "Тапшыру язмасын кертегез",
    shippingAddress: "Тапшыру адресы",
    enterShippingAddress: "Тапшыру адресын кертегез",
    wellDoneYouAreDueToRedeem: "Афәрин! Сез йолып алырга тиеш",
    toGetYourRewardNextVisit: "киләсе килүегездә бүләк алу өчен. Рәхмәт",
    pointsOnThe: ".Әр сүзнең",
    morePointsToRedeem: "сатып алу өчен күбрәк пунктлар",
    showCode: "Кодны күрсәт",
    toGetYourRewardOnNextVisit: "киләсе килүегездә бүләк алу өчен. Рәхмәт",
    earn: "Эшләгез",
    delivaryNote: "Килешү",
    draftSales: "Сату проекты",
    startDate: "Башлангыч көне",
    endDate: "Ахыр датасы",
    orders: "Заказлар",
    checkout: "тишерү",
    noProductItem: "Продукция пункты юк",
    selectProductImage: "Продукция рәсемен сайлагыз",
    selectCountry: "Илне сайлагыз",
    selectRegion: "Дәүләт / Төбәкне сайлагыз",
    printProductTag: "Продукция тэгын бастыру",
    transactionReference: "Транзакция белешмәсе",
    Cashier: "Кассир",
    Manager: "Менеджер",
    Owner: "Хуҗасы",
    Admin: "Админ",
    addPartners: "Партнерлар өстәгез",
    addNewLoyaltyPartner: "Яңа тугрылык партнерын өстәгез",
    pleaseEnterCompanyName: "Зинһар, компания исемен кертегез",
    companyName: "Оешма исеме",
    pleaseEnterCompanyRepName: "Зинһар, компания вәкиле исемен кертегез",
    companyRepName: "Компания вәкиленең исеме",
    pleaseEnterCompanyRepEmail:
      "Зинһар, компания вәкиленең электрон почтасын кертегез",
    companyRepEmail: "Компания вәкиле электрон почтасы",
    pleaseEnterCompanyRepPhone:
      "Зинһар, компания вәкиленең телефон номерын кертегез",
    companyRepPhone: "Компаниянең телефон номеры",
    addReward: "Бүләк өстәгез",
    pleaseEnterRewardName: "Зинһар, бүләк исемен кертегез",
    rewardName: "Бүләк исеме",
    rewardQuantity: "Бүләк саны",
    rewardDescription: "Бүләк тасвирламасы",
    rewardType: "Бүләк төре",
    pleaseEnterRewardType: "Зинһар, бүләк төрен кертегез",
    pleaseEnterRewardQuantity: "Зинһар, бүләк күләмен кертегез",
    pleaseEnterRewardDescription: "Зинһар, бүләкләү тасвирламасын кертегез",
    fineDining: "Яхшы ашау",
    luxuryFashion: "Люкс мода",
    hotels: "Кунакханәләр",
    travel: "Сәяхәт",
    foodAndBeverage: "Ашамлык һәм эчемлек",
    fashion: "Мода",
    health: "Сәламәтлек",
    furniture: "Мебель",
    entertainment: "Күңел ачу",
    automobile: "Автомобиль",
    education: "Мәгариф",
    beautyAndSpa: "Матурлык һәм Спа",
    staycation: "Стайкация",
    events: "Вакыйгалар",
    trips: "Сәяхәтләр",
    oilAndGas: "Нефть һәм газ",
    laundry: "Керү",
    partnerCategory: "Партнер категориясе",
    freeItem: "Бушлай пункт",
  },
  Tamil: {
    cashier: "காசாளர்",
    manager: "மேலாளர்",
    owner: "உரிமையாளர்",
    online: "நிகழ்நிலை",
    offline: "ஆஃப்லைன்",
    changePassword: "கடவுச்சொல்லை மாற்று",
    currentPasswordMessage:
      "தயவுசெய்து உங்கள் தற்போதைய கடவுச்சொல்லை உள்ளிடவும்",
    passwordMessage: "தங்கள் கடவு சொல்லை பதிவு செய்யவும்",
    currentPassword: "தற்போதைய கடவுச்சொல்",
    password: "கடவுச்சொல்",
    confirmPasswordMessage: "தயவுசெய்து உங்கள் கடவுச்சொல்லை உறுதிப்படுத்தவும்!",
    confirmPassword: "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    sendViaEmail: "மின்னஞ்சல் வழியாக அனுப்பவும்",
    sendViaWhatsapp: "வாட்ஸ்அப் வழியாக அனுப்பவும்",
    downloadPdf: "Pdf ஐ பதிவிறக்கவும்",
    paid: "செலுத்தப்பட்டது",
    unpaid: "செலுத்தப்படாத",
    partial: "பகுதி",
    closeInvoice: "விலைப்பட்டியலை மூட விரும்புகிறீர்களா?",
    closeInvoiceConfirmation:
      "விலைப்பட்டியல் சேமிக்கப்படாமல் இருக்கலாம். நீங்கள் மூட வேண்டுமா?",
    yes: "ஆம்",
    no: "இல்லை",
    invoice: "விலைப்பட்டியல்",
    wasDeducted: "கழிக்கப்பட்டது",
    for: "க்கான",
    item: "பொருள்",
    addProduct: "தயாரிப்பைச் சேர்க்கவும்",
    paymentReference: "கட்டண குறிப்பு",
    amountPaid: "பணம் செலுத்தப்பட்டது",
    discountAmount: "தள்ளுபடி தொகை",
    amountDue: "செலுத்த வேண்டிய தொகை",
    amount: "தொகை",
    dueDate: "உரிய தேதி",
    paymentType: "கட்டணம் வகை",
    card: "அட்டை",
    cash: "பணம்",
    bankTransfer: "வங்கி பரிமாற்றம்",
    paymentMessage: "பணம் செலுத்தும் செய்தி",
    description: "விளக்கம்",
    sendReceipt: "ரசீது அனுப்பவும்",
    delete: "அழி",
    save: "சேமி",
    resend: "மீண்டும் அனுப்பு",
    saveAndSend: "சேமி",
    invoiceSaved: "விலைப்பட்டியல் சேமிக்கப்பட்டது!",
    selectPaymentMethod: "கட்டண முறையைத் தேர்ந்தெடுக்கவும்!",
    selectCustomer: "தயவுசெய்து ஒரு வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்!",
    cartEmptyError:
      "வண்டி பட்டியல் காலியாக இருக்க முடியாது, விலைப்பட்டியலை மூடி, வண்டியில் பொருளைச் சேர்க்கவும்!",
    subscriptionExpired:
      "உங்கள் சந்தா காலாவதியாகிவிட்டது மற்றும் உங்கள் கணக்கு இப்போது வரையறுக்கப்பட்டுள்ளது. உங்கள் கணக்கை புதுப்பிக்க கீழே உள்ள பட்டனை கிளிக் செய்யவும்",
    renew: "புதுப்பிக்கவும்",
    holdOn: "காத்திருங்கள்",
    customerBank: "வாடிக்கையாளர் வங்கி",
    cancel: "ரத்து",
    selectACustomer: "வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்",
    invoiceSuccessfulPdfError:
      "விலைப்பட்டியல் வெற்றிகரமாக உருவாக்கப்பட்டது ஆனால் PDF தலைமுறை வழக்கத்தை விட அதிக நேரம் எடுத்துக்கொள்கிறது. தயவுசெய்து விரைவில் சரிபார்க்கவும்.",
    downloadingInvoice: "விலைப்பட்டியல் பதிவிறக்கம்",
    downloadingReceipt: "ரசீது பதிவிறக்கம்",
    whatsappReceiptError:
      "வாட்ஸ்அப் வழியாக ரசீது அனுப்புவதில் பிழை ஏற்பட்டது, மீண்டும் முயற்சிக்கவும்.",
    receiptToWhatsapp: "ரசீது வாட்ஸ்அப்பிற்கு அனுப்பப்பட்டது",
    thankYouForPatronage: "உங்கள் ஆதரவுக்கு நன்றி",
    hereIsYourReceipt: "உங்கள் கட்டண ரசீது இதோ",
    errorSendingEmailReceipt:
      "மின்னஞ்சல் வழியாக ரசீது அனுப்புவதில் பிழை ஏற்பட்டது, தயவுசெய்து மீண்டும் முயற்சிக்கவும் அல்லது ஆதரவைத் தொடர்பு கொள்ளவும்",
    receiptSentToEmail:
      "வாடிக்கையாளரின் மின்னஞ்சலுக்கு ரசீது அனுப்பப்பட்டுள்ளது",
    invoiceSentToEmail:
      "வாடிக்கையாளரின் மின்னஞ்சலுக்கு விலைப்பட்டியல் அனுப்பப்பட்டுள்ளது",
    invoiceSuccessWhatsappError:
      "விலைப்பட்டியல் வெற்றிகரமாக உருவாக்கப்பட்டது ஆனால் வாட்ஸ்அப்பில் அனுப்புவதில் பிழை ஏற்பட்டது. விலைப்பட்டியல் பட்டியலில் மீண்டும் முயற்சிக்கவும்",
    invoiceSuccessfulEmailError:
      "விலைப்பட்டியல் வெற்றிகரமாக உருவாக்கப்பட்டது, ஆனால் மின்னஞ்சலாக அனுப்பும்போது பிழை ஏற்பட்டது. விலைப்பட்டியல் பட்டியலில் இருந்து மீண்டும் முயற்சிக்கவும்",
    invoiceSentToWhatsapp: "விலைப்பட்டியல் வாட்ஸ்அப்பிற்கு அனுப்பப்பட்டது",
    hello: "வணக்கம்",
    pleaseDownloadInvoice: "தயவுசெய்து விலைப்பட்டியலைப் பதிவிறக்கவும்",
    pleaseDownloadReceipt: "தயவுசெய்து ரசீதை பதிவிறக்கவும்",
    from: "இருந்து",
    email: "மின்னஞ்சல்",
    upgrade: "மேம்படுத்தல்",
    youAreOnFreePlan: "நீங்கள் ஒரு இலவச திட்டத்தில் இருக்கிறீர்கள்.",
    clickOn: "கிளிக் செய்யவும்",
    yourPlanInFewSteps: " உங்கள் சந்தா சில விரைவான படிகளில்.",
    to: "க்கு",
    yourSubscriptionHasExpired:
      "உங்கள் சந்தா காலாவதியாகிவிட்டது மற்றும் உங்கள் கணக்கு இப்போது வரையறுக்கப்பட்டுள்ளது.",
    days: "நாட்கள்",
    yourSubscriptionExpiresIn: "உங்கள் Loystar சந்தா காலாவதியாகிறது",
    createAcount: "ஒரு கணக்கை உருவாக்க",
    signIn: "உள்நுழைக",
    continue: "தொடரவும்",
    enterOtp: "OTP பின்னை உள்ளிடவும்",
    enterValidOtp: "சரியான PIN ஐ உள்ளிடவும்",
    pin: "பின்",
    tokenSentToMail: "உங்கள் மின்னஞ்சலுக்கு ஒரு டோக்கன் அனுப்பப்பட்டுள்ளது",
    passwordResetSuccessful: "கடவுச்சொல் மீட்டமைப்பு வெற்றிகரமாக இருந்தது",
    somethingWentWrong: "ஏதோ தவறு நடைபெற்றிருக்கிறது!",
    resetPassword: "கடவுச்சொல்லை மீட்டமைக்க",
    iHaveResetCode: "என்னிடம் கடவுச்சொல் மீட்டமைப்பு குறியீடு உள்ளது",
    pleaseEnterEmail: "தயவுசெய்து உங்கள் மின்னஞ்சலை உள்ளிடவும்",
    aTokenWillBeSentToEmail: "உங்கள் மின்னஞ்சலுக்கு ஒரு டோக்கன் அனுப்பப்படும்",
    enterEmail: "மின்னஞ்சலை பதிவுசெய்",
    sendinYourToken: "உங்கள் டோக்கனை அனுப்புகிறது ...",
    makeSureItMatchesPassword:
      "இது உங்கள் புதிய கடவுச்சொல்லுடன் பொருந்துகிறதா என்பதை உறுதிப்படுத்தவும்",
    pleaseChooseNewPassword: "தயவுசெய்து புதிய கடவுச்சொல்லை தேர்வு செய்யவும்",
    chooseNewPassword: "புதிய கடவுச்சொல்லை தேர்வு செய்யவும்",
    enterNewPassword: "உறுதிப்படுத்த உங்கள் புதிய கடவுச்சொல்லை உள்ளிடவும்",
    enterTokenSent: "உங்கள் மெயில் அனுப்பப்பட்ட டோக்கனை உள்ளிடவும்",
    resetToken: "டோக்கனை மீட்டமைக்கவும்",
    resettingPassword: "உங்கள் கடவுச்சொல்லை மீட்டமைக்கிறது ...",
    signUp: "பதிவு",
    adminSignInWithEmail:
      " நிர்வாகி மின்னஞ்சலில் உள்நுழைகிறார், பணியாளர் பயனர்பெயருடன் உள்நுழைகிறார்.",
    pleaseEnterEmailOrUsername:
      "தயவுசெய்து உங்கள் மின்னஞ்சல் அல்லது பயனர்பெயரை உள்ளிடவும்",
    emailOrUsername: "மின்னஞ்சல் அல்லது பயனர் பெயர்",
    pleaseEnterPassword: "கடவுச்சொல்லை உள்ளிடவும்",
    createAnAccount: "ஒரு கணக்கை உருவாக்க",
    forgotPassword: "கடவுச்சொல்லை மறந்துவிட்டீர்களா?",
    enterPhoneNumber: "தொலைபேசி எண்ணை உள்ளிடவும்",
    personalData: "தனிப்பட்ட தகவல்",
    validateConfirmationCOde: "உறுதிப்படுத்தல் குறியீட்டைச் சரிபார்க்கவும்",
    pleaseEnterFirstName: "தயவுசெய்து உங்கள் முதல் பெயரை உள்ளிடவும்",
    pleaseEnterPhoneNumber: "தயவுசெய்து உங்கள் தொலைபேசி எண்ணை உள்ளிடவும்",
    pleaseEnterLastName: "தயவுசெய்து உங்கள் கடைசி பெயரை உள்ளிடவும்",
    pleaseEnterBusinessName: "தயவுசெய்து உங்கள் வணிகப் பெயரை உள்ளிடவும்",
    firstName: "முதல் பெயர்",
    lastName: "கடைசி பெயர்",
    businessName: "வணிகத்தின் பெயர்",
    previous: "முந்தைய",
    next: "அடுத்தது",
    pleaseSelectBusinessCategory:
      "தயவுசெய்து உங்கள் வணிக வகையைத் தேர்ந்தெடுக்கவும்",
    pleaseEnterValidEmail: "சரியான மின்னஞ்சலை உள்ளிடவும்",
    pleaseEnterPostCode: "தயவுசெய்து அஞ்சல் குறியீட்டை உள்ளிடவும்",
    postCode: "அஞ்சல் குறியீடு",
    phoneNumberInUse: "இந்த தொலைபேசி எண் ஏற்கனவே பயன்பாட்டில் உள்ளது!",
    emailInUse: "இந்த மின்னஞ்சல் ஏற்கனவே பயன்பாட்டில் உள்ளது!",
    foodAndDrinks: "உணவு மற்றும் பானங்கள்",
    salonAndBeauty: "வரவேற்புரை மற்றும் அழகு",
    fashionAndAccessories: "ஃபேஷன் மற்றும் ஆபரனங்கள்",
    gymAndFitness: "உடற்பயிற்சி மற்றும் உடற்பயிற்சி",
    travelAndHotel: "பயணம் மற்றும் ஹோட்டல்",
    homeAndGifts: "வீடு மற்றும் பரிசுகள்",
    washingAndCleaning: "கழுவுதல் மற்றும் சுத்தம் செய்தல்",
    gadgetsAndElectronics: "கேஜெட்டுகள் மற்றும் மின்னணுவியல்",
    groceries: "மளிகை",
    others: "மற்றவைகள்",
    submit: "சமர்ப்பிக்கவும்",
    accountCreatedSuccessful: "உங்கள் கணக்கு வெற்றிகரமாக உருவாக்கப்பட்டது.",
    pleaseEnterAddress: "தயவுசெய்து உங்கள் முகவரியை உள்ளிடவும்",
    addressLine1: "முகவரி வரி 1",
    addressLine2: "முகவரி வரி 2",
    choosePassword: "கடவுச்சொல்லை தேர்தெடுக்கவும்",
    passwordMustBe6Characters:
      "கடவுச்சொல் குறைந்தது 6 எழுத்துகள் இருக்க வேண்டும்.",
    howDidYouHearLoystar:
      "லாயிஸ்டாரைப் பற்றி நீங்கள் எப்படி கேள்விப்பட்டீர்கள்?",
    referralCode: "பரிந்துரை குறியீடு",
    byClickingTheButton:
      " கீழே உள்ள பொத்தானைக் கிளிக் செய்வதன் மூலம், நீங்கள் ஒப்புக்கொள்கிறீர்கள்",
    termsAndSevice: "விதிமுறை",
    wereCreatingAccount: "நாங்கள் உங்கள் கணக்கை உருவாக்குகிறோம்",
    proceed: "தொடரவும்",
    verificationCodeMustBe6:
      "சரிபார்ப்புக் குறியீடு 6 இலக்கங்களாக இருக்க வேண்டும்",
    pleaseEnter6DigitCode: "தயவுசெய்து 6 இலக்க குறியீட்டை உள்ளிடவும்",
    enterVerificationCode: "சரிபார்ப்புக் குறியீட்டை உள்ளிடவும்",
    resendToken: "டோக்கனை மீண்டும் அனுப்பு",
    verify: "சரிபார்க்கவும்",
    transactions: "பரிவர்த்தனைகள்",
    todaySales: "இன்றைய விற்பனை",
    salesHistory: "விற்பனை வரலாறு",
    supplyHistory: "விநியோக வரலாறு",
    new: "புதிய",
    invoices: "விலைப்பட்டியல்",
    disbursements: "கட்டணங்கள்",
    offlineSales: "ஆஃப்லைன் விற்பனை",
    products: "தயாரிப்புகள்",
    customers: "வாடிக்கையாளர்கள்",
    multiLevelLoyalty: "பல நிலை விசுவாசம்",
    loyaltyPrograms: "விசுவாசத் திட்டங்கள்",
    members: "உறுப்பினர்கள்",
    appStore: "ஆப் ஸ்டோர்",
    orderMenu: "ஆர்டர் மெனு",
    settings: "அமைப்புகள்",
    staffAndBranches: "ஊழியர்கள் மற்றும் கிளைகள்",
    myAccount: "என் கணக்கு",
    switchToSellMode: "விற்பனை முறைக்கு மாறவும்",
    signOut: "வெளியேறு",
    getFreeSubscription: "இலவச சந்தா கிடைக்கும்",
    onlyNumbersAllowed: "எண்கள் மட்டுமே அனுமதிக்கப்படுகின்றன",
    yourAccountMustBe10Digits:
      "உங்கள் கணக்கு எண் 10 இலக்க எண்ணாக இருக்க வேண்டும்",
    yourBvnMustBe11: "உங்கள் BVN 11 இலக்க எண்ணாக இருக்க வேண்டும்",
    pleaseSelectBank: "தயவுசெய்து உங்கள் வங்கியைத் தேர்ந்தெடுக்கவும்",
    selectYourBank: "உங்கள் வங்கியைத் தேர்ந்தெடுக்கவும்",
    enterBankAccountNumber: "வங்கி கணக்கு எண்ணை உள்ளிடவும்",
    enterBvnNumber: "உங்கள் BVN ஐ உள்ளிடவும்",
    connectBankAccount: "வங்கி கணக்கை இணைக்கவும்",
    passwordResetSuccessfulAndSignOut:
      "உங்கள் கடவுச்சொல் வெற்றிகரமாக மீட்டமைக்கப்பட்டது. மீண்டும் உள்நுழைய கீழே உள்ள வெளியேறு பொத்தானைக் கிளிக் செய்யவும்",
    enterCurrentPassword: "தற்போதைய கடவுச்சொல்லை உள்ளிடவும்",
    pleaseEnterCurrentPassword: "தயவுசெய்து தற்போதைய கடவுச்சொல்லை உள்ளிடவும்",
    phoneNumber: "தொலைபேசி எண்",
    sex: "செக்ஸ்",
    dateOfBirth: "பிறந்த தேதி",
    state: "நிலை",
    country: "நாடு",
    loyaltyId: "விசுவாச ஐடி",
    createdAt: "மணிக்கு உருவாக்கப்பட்டது",
    noOfTransactionsMade: "செய்யப்பட்ட பரிவர்த்தனைகளின் எண்ணிக்கை",
    yourDownloadWillStart: "உங்கள் பதிவிறக்கம் சிறிது நேரத்தில் தொடங்கும்",
    exportCustomers: "ஏற்றுமதி வாடிக்கையாளர்கள்",
    youhaveSuccessfullyToppedUp:
      "உங்கள் எஸ்எம்எஸ் யூனிட்களை வெற்றிகரமாக முடித்துவிட்டீர்கள்.",
    youNowHave: "இப்போது உங்களிடம் உள்ளது",
    smsUnits: "எஸ்எம்எஸ் அலகுகள்",
    enterNumberOfSmsUnits:
      "நீங்கள் வாங்க விரும்பும் எஸ்எம்எஸ் அலகுகளின் எண்ணிக்கையை உள்ளிடவும்",
    pleaseEnterANumericValue: "ஒரு எண் மதிப்பை உள்ளிடவும்",
    pay: "செலுத்து",
    accountEditedSuccessfully: "உங்கள் கணக்கு வெற்றிகரமாக திருத்தப்பட்டது.",
    youAeCurrentlyOn: "நீங்கள் தற்போது இருக்கிறீர்கள்",
    plan: "திட்டம்",
    userData: "பயனர் தரவு",
    businessData: "வணிக தரவு",
    businessCategory: "வணிக கேட்டரிகரி",
    pleaseSelectCurrency: "தயவுசெய்து உங்கள் நாணயத்தைத் தேர்ந்தெடுக்கவும்",
    selectYourCurrency: "உங்கள் நாணயத்தைத் தேர்ந்தெடுக்கவும்",
    purchaseMoreSmsUnits:
      "கீழேயுள்ள படிவத்தைப் பயன்படுத்தி மேலும் எஸ்எம்எஸ் யூனிட்களை வாங்கவும்",
    youHave: "உங்களிடம் உள்ளது",
    atLeast4SmsUnitsRrequired:
      "சரிபார்ப்புக்கு குறைந்தது 4 எஸ்எம்எஸ் அலகுகள் தேவை, தயவுசெய்து டாப் அப் செய்யவும்!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "தயவுசெய்து 'உங்கள் வாடிக்கையாளரை அறிந்து கொள்ளுங்கள்' (KYC) தேவைகளுக்கு இணங்க உங்கள் வங்கிக் கணக்கைச் சரிபார்க்கவும். இது USSD அல்லது உடனடி பரிமாற்றத்தின் மூலம் பணம் சேகரிக்கவும், வாடிக்கையாளர்களிடமிருந்து ஆர்டர்களைப் பெறவும் மற்றும் பரிவர்த்தனை தீர்வைச் செயல்படுத்தவும் அனுமதிக்கும். நைஜீரிய வணிகர்களுக்கு மட்டுமே பொருந்தும். தொடங்க கீழே உள்ள பொத்தானை அழுத்தவும்.",
    reConnectBankAccount: "வங்கி கணக்கை மீண்டும் இணைக்கவும்",
    accountName: "கணக்கின் பெயர்",
    accountNumber: "கணக்கு எண்",
    bankName: "வங்கி பெயர்",
    verified: "சரிபார்க்கப்பட்டது",
    accountDetails: "கணக்கு விவரங்கள்",
    kycBankAccount: "KYC",
    createTier: "அடுக்கு உருவாக்கவும்",
    fileUploadSuccessful: "கோப்பு வெற்றிகரமாக பதிவேற்றப்பட்டது",
    fileUploadFailed: "கோப்பு பதிவேற்றம் தோல்வியடைந்தது",
    createLoyaltyProgram: "ஒரு விசுவாசத் திட்டத்தை உருவாக்குங்கள்",
    createLoyalty: "விசுவாசத்தை உருவாக்குங்கள்",
    name: "பெயர்",
    loyaltyArtwork: "விசுவாசக் கலைப்படைப்பு",
    clickToUpload: "பதிவேற்ற கிளிக் செய்யவும்",
    amountToPointsRatio: "புள்ளிகளின் விகிதத்தின் அளவு",
    points: "புள்ளிகள்",
    recommendedAmountToPointRatio:
      "பரிந்துரைக்கப்படுகிறது: ₦ 1 முதல் 1 புள்ளி. அதாவது ஒவ்வொரு 1 நைராவுக்கும், உங்கள் வாடிக்கையாளர்களுக்கு 1 புள்ளி கிடைக்கும்",
    pleaseTypeIn: "தயவுசெய்து தட்டச்சு செய்யவும்",
    toDeleteLoyalty: "இந்த விசுவாசத்தை நீக்க",
    deleteLoyalty: "விசுவாசத்தை நீக்கு",
    toConfirm: "உறுதிப்படுத்த",
    edit: "தொகு",
    pointsAwardedSuccessfully: "புள்ளிகள் வெற்றிகரமாக வழங்கப்பட்டன.",
    enterPointValueToAward:
      "நீங்கள் வாடிக்கையாளர்களுக்கு விருது வழங்க விரும்பும் புள்ளி மதிப்பை உள்ளிடவும்",
    award: "விருது",
    awardPointValuesToCustomer: "வாடிக்கையாளருக்கு விருது புள்ளி மதிப்பு",
    enrollMembersToLoyalty: "உறுப்பினர்களை விசுவாசத்தில் சேர்க்கவும்",
    awardPoints: "விருது புள்ளிகள்",
    enroll: "பதிவு",
    home: "வீடு",
    loyalty: "விசுவாசம்",
    enrollCustomers: "வாடிக்கையாளர்களைப் பதிவு செய்யவும்",
    selected: "தேர்ந்தெடுக்கப்பட்டது",
    customer: "வாடிக்கையாளர்",
    loyaltyActivationSuccessful: "விசுவாசம் செயலாக்கம் வெற்றிகரமாக உள்ளது.",
    loyaltyDeactivationSuccessful: "விசுவாச செயலிழப்பு வெற்றிகரமாக உள்ளது.",
    viewTier: "அடுக்கைக் காண்க",
    deactivate: "டி-ஆக்டிவேட்",
    activate: "செயல்படுத்த",
    actions: "செயல்கள்",
    nameOfLoyalty: "விசுவாசத்தின் பெயர்",
    loyaltyStatus: "விசுவாச நிலை விசுவாச நிலை",
    numberOfTiers: "அடுக்குகளின் எண்ணிக்கை",
    createdOn: "அன்று உருவாக்கப்பட்ட",
    createATier: "ஒரு அடுக்கு உருவாக்கவும்",
    stopCreatingTierConfirmation:
      "ஒரு அடுக்கை உருவாக்குவதை நிறுத்த விரும்புகிறீர்களா?",
    stopEditingTierConfirmation:
      "இந்த அடுக்கைத் திருத்துவதை நிறுத்த விரும்புகிறீர்களா?",
    nameOfTier: "அடுக்கு பெயர்",
    minimumSpendingTarget: "குறைந்தபட்ச செலவு இலக்கு",
    maximumSpendingTarget: "அதிகபட்ச செலவு இலக்கு",
    minimumSpendingTargetRequired: "குறைந்தபட்ச செலவு இலக்கு தேவை",
    maximumSpendingTargetRequired: "அதிகபட்ச செலவு இலக்கு தேவை",
    rewardSponsor: "வெகுமதி ஸ்பான்சர்",
    pleaseChooseARewardSponsor: "வெகுமதி ஸ்பான்சரைத் தேர்ந்தெடுக்கவும்",
    self: "சுய",
    partner: "பங்குதாரர்",
    rewardPartner: "வெகுமதி பங்குதாரர்",
    pleaseSelectRewardPartner:
      "தயவுசெய்து உங்கள் வெகுமதி கூட்டாளரைத் தேர்ந்தெடுக்கவும்",
    rewards: "வெகுமதிகள்",
    pleaseSelectAReward: "வெகுமதியைத் தேர்ந்தெடுக்கவும்",
    instructionsOnRedeemingReward:
      "வாடிக்கையாளர் ரிவார்டை எப்படி மீட்க வேண்டும் என்பதற்கான வழிமுறைகள்",
    pleaseFillInThisField: "தயவுசெய்து இந்த புலத்தை நிரப்பவும்!",
    toDeleteThisTier: " இந்த அடுக்கை நீக்க",
    deleteTier: "அடுக்கு நீக்கு",
    viewMembers: "உறுப்பினர்களைக் காண்க",
    membersEnrolled: "உறுப்பினர்கள் பதிவு செய்தனர்",
    instruction: "அறிவுறுத்தல்",
    membersIn: "உள்ள உறுப்பினர்கள்",
    availableTiersInLoyalty: "விசுவாசத் திட்டத்தில் கிடைக்கும் அடுக்கு (கள்)",
    tiers: "அடுக்குகள்",
    totalTier: "மொத்த அடுக்கு",
    availableLoyaltyProgramme: "கிடைக்கும் விசுவாசத் திட்டம்",
    totalLoyalties: "மொத்த விசுவாசங்கள்",
    memberDetails: "உறுப்பினர் விவரங்கள்",
    nameOfCustomer: "வாடிக்கையாளரின் பெயர்",
    address: "முகவரி",
    allEnrolledMembers: "அனைத்து பதிவு செய்யப்பட்ட உறுப்பினர்களும்",
    thisIsToWishYouHappyBirthday:
      "இது உங்களுக்கு பிறந்த நாள் வாழ்த்துக்கள் மற்றும் வளமான வாழ்வை வாழ்த்துவதாகும். லாயிஸ்டருக்கு நீங்கள் அளித்த எல்லாவற்றிற்கும் நன்றி. இனிய கொண்டாட்டங்கள்!",
    inputAnOfferPlease: "தயவுசெய்து சலுகையை உள்ளிடவும்",
    pleaseSelectTheInsertPoint:
      "செய்தியில் செருகும் புள்ளியைத் தேர்ந்தெடுத்து மீண்டும் கிளிக் செய்யவும்",
    birthdayOfferAndMessage: "பிறந்தநாள் சலுகை மற்றும் செய்தி",
    birthdayOffer: "பிறந்தநாள் சலுகை",
    birthdayMessage: "பிறந்தநாள் செய்தி",
    noOfferFound: "சலுகை இல்லை",
    settingABirthdayOffer:
      "பிறந்தநாள் சலுகையை அமைப்பது வாடிக்கையாளர்களுக்கு அவர்களின் பிறந்தநாளில் SMS மூலம் இந்த சலுகையைப் பெற அனுமதிக்கிறது",
    createOffer: "சலுகையை உருவாக்கவும்",
    whatIsTheOffer: "சலுகை என்ன?",
    editMessage: "செய்தியைத் திருத்தவும்",
    insert: "செருக",
    theNameOfCustomerInserted: "வாடிக்கையாளரின் பெயர் இங்கே செருகப்படும்",
    theBirtdayOfferInserted: "பிறந்தநாள் சலுகை இங்கே செருகப்படும்",
    youSuccessfullyAddedNewBranch:
      "நீங்கள் ஒரு புதிய கிளையை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்!",
    addNewBranch: "புதிய கிளையைச் சேர்க்கவும்",
    addBranch: "கிளையைச் சேர்",
    additionalBranchWillIncur: "கூடுதல் கிளை ஏற்படும்",
    perBranch: "ஒரு கிளைக்கு",
    ecommerceBranchCosts: "இணையவழி கிளை செலவுகள்",
    pleaseEnterBranchName: "கிளை பெயரை உள்ளிடவும்",
    pleaseEnterBranchAddress1: "கிளையின் முகவரி வரி 1 ஐ உள்ளிடவும்",
    enterBranchAddress1: "கிளையின் முகவரி வரி 1 ஐ உள்ளிடவும்",
    enterBranchAddress2: "கிளையின் முகவரி வரி 1 ஐ உள்ளிடவும்",
    pleaseEnterBranchAddress2: "கிளையின் முகவரி வரி 2 ஐ உள்ளிடவும்",
    enterBranchName: "கிளை பெயரை உள்ளிடவும்",
    successfullyAddedStaff:
      "நீங்கள் ஒரு புதிய ஊழியரை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்!",
    addNewStaff: "புதிய பணியாளர்களைச் சேர்க்கவும்",
    addStaff: "பணியாளர்களைச் சேர்க்கவும்",
    additionalStaffWillIncur: "கூடுதல் ஊழியர்கள் பாதிக்கப்படுவார்கள்",
    perStaff: "ஒரு ஊழியருக்கு.",
    pleaseEnterStaffEmail: "தயவுசெய்து ஊழியர்களின் மின்னஞ்சலை உள்ளிடவும்",
    pleaseEnterStaffUsername: "பணியாளர்களின் பயனர்பெயரை உள்ளிடவும்",
    pleaseEnterStaffPassword: "பணியாளர்களின் கடவுச்சொல்லை உள்ளிடவும்",
    pleaseSelectStaffRole: "தயவுசெய்து ஊழியர்களின் பங்கைத் தேர்ந்தெடுக்கவும்",
    selectStaffRole: "ஊழியர்களின் பங்கைத் தேர்ந்தெடுக்கவும்",
    enterStaffEmail: "ஊழியர்களின் மின்னஞ்சலை உள்ளிடவும்",
    enterStaffUsername: "ஊழியரின் பயனர்பெயரை உள்ளிடவும்",
    enterStaffPassword: "ஊழியரின் கடவுச்சொல்லை உள்ளிடவும்",
    spacesNotAllowedInUsername: "பயனர் பெயரில் இடங்கள் அனுமதிக்கப்படவில்லை",
    admin: "நிர்வாகம்",
    pleaseSelectBusinessToAttachStaff:
      "ஊழியர்களை இணைக்க வணிகத்தைத் தேர்ந்தெடுக்கவும்",
    searchForBranchToAttachStaff: "ஊழியர்களை இணைக்க கிளையைத் தேடுங்கள்",
    username: "பயனர்பெயர்",
    role: "பங்கு",
    areYouSureToDeleteThis: "இதை நீங்கள் நிச்சயமாக நீக்க வேண்டுமா?",
    branches: "கிளைகள்",
    upgradeYourPlan: "உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    add: "கூட்டு",
    addNew: "புதிதாக சேர்க்கவும்",
    customerWithEmailAlreadyExists:
      "மின்னஞ்சல்/தொலைபேசி எண்ணுடன் வாடிக்கையாளர் ஏற்கனவே இருக்கிறார்!",
    successfullyAddedNewCustomer:
      "நீங்கள் ஒரு புதிய வாடிக்கையாளரை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்!",
    addCustomer: "வாடிக்கையாளரைச் சேர்க்கவும்",
    pleaseEnterCustomerFirstName:
      "தயவுசெய்து வாடிக்கையாளரின் முதல் பெயரை உள்ளிடவும்",
    pleaseEnterCustomerLastName: "வாடிக்கையாளரின் கடைசி பெயரை உள்ளிடவும்",
    pleaseEnterCustomerPhoneNumber:
      "தயவுசெய்து வாடிக்கையாளரின் தொலைபேசி எண்ணை உள்ளிடவும்",
    pleaseEnterCustomerEmail:
      "தயவுசெய்து வாடிக்கையாளரின் மின்னஞ்சலை உள்ளிடவும்",
    pleaseEnterCustomerAddressLine: "வாடிக்கையாளர் முகவரி வரியை உள்ளிடவும்",
    pleaseEnterCustomerOtherAddress:
      "தயவுசெய்து வாடிக்கையாளரின் மற்ற முகவரியை உள்ளிடவும்",
    pleaseSelectCustomerGender:
      "தயவுசெய்து வாடிக்கையாளரின் பாலினத்தைத் தேர்ந்தெடுக்கவும்",
    gender: "பாலினம்",
    male: "ஆண்",
    female: "பெண்",
    bank: "வங்கி",
    selectBank: "வங்கியைத் தேர்ந்தெடுக்கவும்",
    stateOrRegionOrProvince: "மாநிலம்/பிராந்தியம்/மாகாணம்",
    customerNotes: "வாடிக்கையாளர் குறிப்புகள்",
    sendSms: "எஸ்எம்எஸ் அனுப்பவும்",
    editCustomer: "வாடிக்கையாளரைத் திருத்து",
    redeemReward: "ரிவார்டை மீட்டெடுக்கவும்",
    issueLoyaltyCard: "விசுவாச அட்டை வழங்கவும்",
    deleteCustomer: "வாடிக்கையாளரை நீக்கு",
    youveSuccessfullyAssignedLoyaltyMembership:
      "நீங்கள் ஒரு லாயல்டி உறுப்பினர் ஐடியை வெற்றிகரமாக ஒதுக்கியுள்ளீர்கள்",
    noMembershipIdAvailable:
      "உறுப்பினர் அடையாளங்கள் இல்லை. தயவுசெய்து எங்களை hello@loystar.co இல் தொடர்பு கொள்ளவும்",
    sendEmail: "மின்னஞ்சல் அனுப்பு",
    membershipPoints: "உறுப்பினர் புள்ளிகள்",
    customerDetails: "வாடிக்கையாளர் விவரங்கள்",
    close: "நெருக்கமான",
    loyaltyBalance: "விசுவாச இருப்பு",
    pointsBalance: "புள்ளிகள் இருப்பு",
    starBalance: "நட்சத்திர இருப்பு",
    requiredPoints: "தேவையான புள்ளிகள்",
    requiredStars: "தேவையான நட்சத்திரங்கள்",
    reddemCode: "மீட்டு குறியீடு",
    toDeleteThisCustomer: "இந்த வாடிக்கையாளரை நீக்க",
    customerHasBeenDeletedSuccessfully:
      "வாடிக்கையாளர் வெற்றிகரமாக நீக்கப்பட்டது!",
    customerWithPhoneAlreadyExists:
      "தொலைபேசி எண்ணுடன் வாடிக்கையாளர் ஏற்கனவே இருக்கிறார்",
    customerWasSuccessfullyEdited: "வாடிக்கையாளர் வெற்றிகரமாக திருத்தப்பட்டார்",
    anErrorOccured: "ஒரு பிழை ஏற்பட்டது",
    phoneNumberIncludeCountry: "தொலைபேசி எண் (நாட்டின் குறியீடு அடங்கும்)",
    yourFileQueuedForUpload:
      "உங்கள் கோப்பு பதிவேற்ற வரிசையில் உள்ளது. சில நொடிகளுக்குப் பிறகு பக்கத்தைப் புதுப்பிக்கவும்.",
    thereWasAnErrorPerformingOperation:
      "செயல்பாட்டைச் செய்வதில் பிழை ஏற்பட்டது!",
    pleaseSelectFile: "தயவுசெய்து ஒரு கோப்பைத் தேர்ந்தெடுக்கவும்!",
    oopsOnlyCsvFilesAllowed:
      "அச்சச்சோ! CSV கோப்புகள் மட்டுமே அனுமதிக்கப்படுகின்றன. தயவுசெய்து .csv கோப்பை பதிவேற்றவும்.",
    fileShouldBeSmallerThan5Mb:
      "கோப்பு 5 எம்பி விட சிறியதாக இருக்க வேண்டும். உங்களிடம் பெரிய கோப்பு இருந்தால், support@loystar.co க்கு மின்னஞ்சல் செய்யவும். நன்றி",
    customerFirstNameIsRequired: "வரிசையில் வாடிக்கையாளர் முதல் பெயர் தேவை",
    customerPhoneNumberIsRequired: "வரிசையில் வாடிக்கையாளர் தொலைபேசி எண் தேவை",
    importCustomers: "இறக்குமதி வாடிக்கையாளர்கள்",
    upload: "பதிவேற்று",
    clickIconToDownloadCsv:
      "CSV கோப்பு வார்ப்புருவைப் பதிவிறக்க இந்த ஐகானைக் கிளிக் செய்யவும்.",
    getGoogleSheetVersion: "Google தாள் பதிப்பைப் பெறுங்கள்",
    clickOrDragFileToUpload:
      "பதிவேற்ற இந்த பகுதிக்கு கோப்பை கிளிக் செய்யவும் அல்லது இழுக்கவும்",
    missingOrInvalidColumnHeader:
      "காணாமல் போன அல்லது தவறான நெடுவரிசை தலைப்பு. தயவுசெய்து வார்ப்புரு வடிவத்தைப் பின்பற்றவும். நன்றி.",
    youHaveImported: "நீங்கள் இறக்குமதி செய்துள்ளீர்கள்",
    youSuccessfullyRedeemedReward: "உங்கள் வெகுமதியை வெற்றிகரமாக மீட்டீர்கள்!",
    sixDigitCode: "ஆறு இலக்க குறியீடு",
    pleaseEnterCustomerRewardCode:
      "வாடிக்கையாளரின் வெகுமதி குறியீட்டை உள்ளிடவும்",
    enterRewardCode:
      "வெகுமதி குறியீட்டை உள்ளிடவும். (வெகுமதி குறியீடு வழக்கு உணர்திறன் கொண்டது)",
    selectLoyaltyProgram: "விசுவாசத் திட்டத்தைத் தேர்ந்தெடுக்கவும்",
    stamps: "முத்திரைகள்",
    smsUnitsLow: "எஸ்எம்எஸ் அலகுகள் குறைவு",
    pleaseTopUpSmsUnits: "தயவுசெய்து SMS அலகுகளை டாப் அப் செய்யவும்",
    smsSuccessfullySentForDelivery:
      "டெலிவரிக்கு எஸ்எம்எஸ் வெற்றிகரமாக அனுப்பப்பட்டது!",
    sendSmsTo: "க்கு எஸ்எம்எஸ் அனுப்பவும்",
    allCustomers: "அனைத்து வாடிக்கையாளர்கள்",
    unitsAvailable: "அலகுகள் கிடைக்கின்றன",
    pleaseTypeInTheMessage: "தயவுசெய்து செய்தியை உள்ளிடவும்",
    message: "செய்தி",
    charactersRemaining: "மீதமுள்ள எழுத்துக்கள்",
    avoidUseOfSpecialCharacters:
      "எஸ்எம்எஸ் யூனிட்களைப் பாதுகாக்க சிறப்பு எழுத்துக்கள் மற்றும் ஈமோஜிகளைப் பயன்படுத்துவதைத் தவிர்க்கவும்.",
    note: "குறிப்பு",
    errorFetchingCustomersMultilevelDetail:
      "வாடிக்கையாளர்களின் பல நிலை விவரங்களைப் பெறுவதில் பிழை",
    search: "தேடு",
    reset: "மீட்டமை",
    importCustomer: "இறக்குமதி வாடிக்கையாளர்",
    addNewCustomer: "புதிய வாடிக்கையாளரைச் சேர்க்கவும்",
    sendSmsBroadcast: "எஸ்எம்எஸ் ஒளிபரப்பை அனுப்பவும்",
    totalCustomers: "மொத்த வாடிக்கையாளர்கள்",
    disbursementDetails: "விநியோக விவரங்கள்",
    paidBy: "மூலம் செலுத்தப்பட்டது",
    disbursed: "வழங்கப்பட்டது",
    bankAccountName: "வங்கி கணக்கு பெயர்",
    bankAccountNumber: "வங்கி கணக்கு எண்",
    transferInitiated: "இடமாற்றம் தொடங்கப்பட்டது",
    transferCompleted: "பரிமாற்றம் முடிந்தது",
    pleaseEnterAValid: "சரியானதை உள்ளிடவும்",
    begin: "தொடங்க",
    end: "முடிவு",
    date: "தேதி",
    paymentDate: "கட்டணம் தேதி",
    selectDisbursementDuration: "விநியோக காலத்தைத் தேர்ந்தெடுக்கவும்",
    totalSettled: "மொத்தமாக தீர்வு",
    totalUnsettled: "மொத்தமாக அமைதியற்றது",
    toDeleteThisSale: "இந்த விற்பனையை நீக்க",
    draftSaleDeletedSuccessfully: "வரைவு விற்பனை வெற்றிகரமாக நீக்கப்பட்டது!",
    deleteSale: "விற்பனையை நீக்கு",
    pleaseTypeInYourTotal:
      "நீக்குதலை உறுதிப்படுத்த உங்கள் மொத்தத்தை உள்ளிடவும்",
    billDetails: "பில் விவரங்கள்",
    printBill: "அச்சு பில்",
    servedBy: "வழங்கப்பட்டது",
    total: "மொத்தம்",
    createdDate: "உருவாக்கப்பட்ட தேதி",
    createdTime: "உருவாக்கப்பட்ட நேரம்",
    status: "நிலை",
    loginSuccessful: "உள்நுழைவு வெற்றிகரமாக உள்ளது",
    pleaseWaitWhileWeConnectAccount:
      "நாங்கள் உங்கள் கணக்கை இணைக்கும் வரை காத்திருங்கள்",
    connectionFailedTryAgain:
      "இணைப்பு தோல்வியடைந்தது. தயவு செய்து மீண்டும் முயற்சிக்கவும்.",
    connectionSuccessful: "இணைப்பு வெற்றிகரமாக உள்ளது",
    viewDetails: "விபரங்களை பார்",
    enable: "இயக்கு",
    free: "இலவசம்",
    cost: "செலவு",
    visitWebsite: "வலைத்தளத்தைப் பார்வையிடவும்",
    pleaseUpgradeYourPlanToPro:
      "இந்த பயன்பாட்டை செயல்படுத்த தயவுசெய்து உங்கள் திட்டத்தை ப்ரோ பிளஸிற்கு மேம்படுத்தவும்",
    connectYourBankAccount: "பணம் பெற உங்கள் வங்கி கணக்கை இணைக்கவும்.",
    disable: "முடக்கு",
    enableApp: "பயன்பாட்டை இயக்கு",
    addNewProductToInvoice: "விலைப்பட்டியலில் புதிய தயாரிப்பைச் சேர்க்கவும்",
    toDeleteThisInvoice: "இந்த விலைப்பட்டியலை நீக்க",
    invoiceDeletedSuccessfully: "விலைப்பட்டியல் வெற்றிகரமாக நீக்கப்பட்டது!",
    deleteInvoice: "விலைப்பட்டியலை நீக்கு",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "நீங்கள் ஒரு விலைப்பட்டியல் கட்டணச் செய்தியை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்!",
    pleaseEnterPaymentInstructions: "கட்டண வழிமுறைகளை உள்ளிடவும்",
    invoiceId: "விலைப்பட்டியல் ஐடி",
    paidDate: "பணம் செலுத்திய தேதி",
    reference: "குறிப்பு",
    productAdded: "தயாரிப்பு சேர்க்கப்பட்டது",
    productOutOfStock:
      "தயாரிப்பு கையிருப்பில் இல்லை. தயவுசெய்து மீண்டும் சேமித்து வைக்கவும்.",
    totalInvoices: "மொத்த அழைப்புகள்",
    totalPaidInvoices: "மொத்த கட்டண கட்டணங்கள்",
    totalUnpaidInvoices: "மொத்த UNPAID அழைப்புகள்",
    loyaltyProgramDeleted: "விசுவாசத் திட்டம் நீக்கப்பட்டது",
    thisLoyaltyProgramDeletedSuccessfully:
      "இந்த விசுவாசத் திட்டம் வெற்றிகரமாக நீக்கப்பட்டது",
    thisLoyaltyProgramEditedSuccessfully:
      "இந்த விசுவாசத் திட்டம் வெற்றிகரமாக திருத்தப்பட்டது",
    loyaltyProgramAddedSuccessfully:
      "விசுவாசத் திட்டம் வெற்றிகரமாகச் சேர்க்கப்பட்டது",
    loyaltyProgramEdited: "விசுவாசத் திட்டம் திருத்தப்பட்டது",
    loyaltyProgramAdded: "விசுவாசத் திட்டம் சேர்க்கப்பட்டது",
    loyaltyDetails: "விசுவாச விவரங்கள்",
    doYouWantToCloseDialog: "இந்த உரையாடலை மூட விரும்புகிறீர்களா?",
    pleaseEnterLoyaltyName: "தயவுசெய்து உங்கள் விசுவாசத்தின் பெயரை உள்ளிடவும்",
    programType: "நிரல் வகை",
    pleaseSelectProgramType: "தயவுசெய்து ஒரு நிரல் வகையைத் தேர்ந்தெடுக்கவும்",
    simplePoints: "எளிய புள்ளிகள்",
    stampsProgram: "முத்திரைகள் திட்டம்",
    threshold: "வாசல்",
    pleaseEnterLoyaltyThreshold: "தயவுசெய்து விசுவாச வரம்பை உள்ளிடவும்",
    reward: "வெகுமதி",
    pleaseEnterLoyaltyReward: "விசுவாசத்தின் வெகுமதியை உள்ளிடவும்",
    totalUserPoints: "மொத்த பயனர் புள்ளிகள்",
    totalUserStamps: "மொத்த பயனர் முத்திரைகள்",
    spendingTarget: "செலவு இலக்கு",
    spendingTargetHint1:
      "வெகுமதி பெறுவதற்கு ஒரு வாடிக்கையாளர் எவ்வளவு செலவு செய்ய வேண்டும் என்பது செலவு இலக்கு. 1 நாணய மதிப்பு = 1 புள்ளி.",
    spendingTargetHint2:
      "வெகுமதியைப் பெற வாடிக்கையாளர் எத்தனை முத்திரைகளை சேகரிக்க வேண்டும் என்பதுதான் முத்திரை இலக்கு. எ.கா. 5",
    addNewLoyaltyProgram: "புதிய விசுவாச திட்டத்தை சேர்க்கவும்",
    addLoyaltyProgram: "விசுவாசத் திட்டத்தை சேர்க்கவும்",
    loyaltyProgramType: "விசுவாசத் திட்ட வகை",
    pleaseSelectLoyaltyProgramType: "விசுவாசத் திட்ட வகையைத் தேர்ந்தெடுக்கவும்",
    nameOfProgram: "திட்டத்தின் பெயர்",
    pleaseEnterProgramName: "தயவுசெய்து நிரலின் பெயரை உள்ளிடவும்",
    whatIsTheReward: "வெகுமதி என்ன?",
    egNextPurchaseIsFree: "எ.கா. அடுத்த கொள்முதல் இலவசம்",
    customerName: "வாடிக்கையாளர் பெயர்",
    guestCustomer: "விருந்தினர் வாடிக்கையாளர்",
    orderConfirmedSuccessfully: "ஆர்டர் வெற்றிகரமாக உறுதிப்படுத்தப்பட்டது",
    orderCancelledSuccessfully: "ஆர்டர் வெற்றிகரமாக ரத்து செய்யப்பட்டது",
    confirmOrder: "ஒழுங்கை உறுதிப்படுத்தவும்",
    cancelOrder: "ஆர்டரை ரத்து செய்யவும்",
    allOrders: "அனைத்து ஆர்டர்கள்",
    totalOrders: "மொத்த ஆர்டர்கள்",
    doYouWantToAcceptOrder: "இந்த உத்தரவை ஏற்க விரும்புகிறீர்களா?",
    doYouWantToCancelOrder: "இந்த ஆர்டரை ரத்து செய்ய வேண்டுமா?",
    orderDetails: "ஆணை விவரங்கள்",
    orderCreatedAt: "இல் ஆணை உருவாக்கப்பட்டது",
    supplier: "சப்ளையர்",
    productName: "பொருளின் பெயர்",
    quantity: "அளவு",
    unitPrice: "அலகு விலை",
    receivedBy: "மூலம் பெற்றார்",
    reportFrom: "இருந்து அறிக்கை",
    totalSupplies: "மொத்த பொருட்கள்",
    allRightsReserved: "அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை",
    toDeleteThisTransaction: "இந்த பரிவர்த்தனையை நீக்க",
    transactionDeletedSuccessfully:
      "பரிவர்த்தனை வெற்றிகரமாக நீக்கப்பட்டது. இருப்பு சரக்குகளுக்கு திருப்பி அனுப்பப்பட்டது.",
    deleteTransaction: "பரிவர்த்தனையை நீக்கவும்",
    transactionDetails: "பரிமாற்ற விவரங்கள்",
    printReceipt: "அச்சு ரசீது",
    channel: "சேனல்",
    discount: "தள்ளுபடி",
    profit: "லாபம்",
    discountedSales: "தள்ளுபடி விற்பனை",
    errorFetchingRecord: "பதிவைப் பெறுவதில் பிழை",
    exportTransactions: "ஏற்றுமதி பரிவர்த்தனைகள்",
    errorFetchingSalesRecord: "ஏற்றுமதிக்கான விற்பனைப் பதிவைப் பெறுவதில் பிழை.",
    totalSellingPrice: "மொத்த விற்பனை விலை",
    totalCostPrice: "மொத்த செலவு விலை",
    appliedDiscount: "பொருந்தும் தள்ளுபடி",
    noOfItems: "பொருட்களின் எண்",
    sales: "விற்பனை",
    export: "ஏற்றுமதி",
    totalProfit: "மொத்த லாபம்",
    totalBalanceInPeriod: "காலத்தின் மொத்த இருப்பு",
    allTimeSales: "அனைத்து நேர விற்பனை",
    records: "பதிவுகள்",
    todaysSales: "இன்றைய விற்பனை",
    transaction: "பரிவர்த்தனை",
    youHaveExceededTotalNumberOfProducts:
      "உங்கள் திட்டத்தில் அனுமதிக்கப்பட்ட மொத்த தயாரிப்புகளின் எண்ணிக்கையை நீங்கள் மீறிவிட்டீர்கள். அதிக வரம்பை அனுபவிக்க உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    youNeedToHaveLoyaltyProgram:
      "இந்த அம்சத்தைப் பயன்படுத்த நீங்கள் ஒரு விசுவாசத் திட்டம் வைத்திருக்க வேண்டும்!",
    price: "விலை",
    category: "வகை",
    stockTracking: "பங்கு கண்காணிப்பு",
    stockCount: "பங்கு எண்ணிக்கை",
    taxed: "வரி விதிக்கப்பட்டது",
    originalPrice: "உண்மையான விலை",
    costPrice: "செலவு விலை",
    unit: "அலகு",
    productImage: "தயாரிப்பு படம்",
    taxRate: "வரி விகிதம்",
    taxType: "வரி வகை",
    trackInventory: "டிராக் சரக்கு",
    variants: "மாறுபாடுகள்",
    hasVariants: "மாறுபாடுகள் உள்ளன",
    importProduct: "இறக்குமதி தயாரிப்பு",
    exportProducts: "ஏற்றுமதி பொருட்கள்",
    addNewProduct: "புதிய தயாரிப்பைச் சேர்க்கவும்",
    viewCategory: "வகையைப் பார்க்கவும்",
    viewSuppliers: "சப்ளையர்களைப் பார்க்கவும்",
    receiveInventory: "சரக்குகளைப் பெறுங்கள்",
    printAllProductsTag: "அனைத்து தயாரிப்புகளின் குறிச்சொல்லை அச்சிடுங்கள்",
    deleteAll: "அனைத்தையும் நீக்கு",
    totalProducts: "மொத்த தயாரிப்புகள்",
    youveSuccessfullyAddedANewCategory:
      "நீங்கள் வெற்றிகரமாக ஒரு புதிய வகையைச் சேர்த்துள்ளீர்கள்",
    addNewCategory: "புதிய வகையைச் சேர்க்கவும்",
    addCategory: "வகையைச் சேர்",
    categoryName: "வகை பெயர்",
    pleaseEnterCategoryName: "தயவுசெய்து வகைப் பெயரை உள்ளிடவும்",
    stampsTarget: "முத்திரைகள் இலக்கு",
    sendInventory: "சரக்கு அனுப்பவும்",
    productDetails: "தயாரிப்பு விவரங்கள்",
    youveSuccessfullyEditedThisCategory:
      "இந்த வகையை வெற்றிகரமாகத் திருத்தியுள்ளீர்கள்",
    update: "புதுப்பிக்கவும்",
    categoryList: "வகை பட்டியல்",
    categories: "வகைகள்",
    enterQuantityToUpdate: "புதுப்பிக்க ஒரு அளவை உள்ளிடவும்",
    inventorySentSuccessfully: "சரக்கு வெற்றிகரமாக அனுப்பப்பட்டது!",
    updateInventory: "புதுப்பிப்பு சரக்கு",
    currentQuantity: "தற்போதைய அளவு",
    pleaseEnterQuantityToAdd: "நீங்கள் சேர்க்க விரும்பும் அளவை உள்ளிடவும்",
    pleaseSelectABranch: "ஒரு கிளையைத் தேர்ந்தெடுக்கவும்",
    searchForBranch: "கிளையைத் தேடுங்கள்",
    youCantSendMoreThanStock:
      "கையிருப்பில் இருப்பதை விட அதிகமாக அனுப்ப முடியாது",
    send: "அனுப்பு",
    pleaseEnterQuantityToSend: "நீங்கள் அனுப்ப விரும்பும் அளவை உள்ளிடவும்",
    productNameIsRequiredOnRow: "வரிசையில் தயாரிப்பு பெயர் தேவை",
    productCategoryIsRequiredOnRow: "வரிசையில் தயாரிப்பு வகை தேவை",
    productPriceIsRequiredOnRow: "வரிசையில் தயாரிப்பு விலை தேவைப்படுகிறது",
    productUnitIsRequiredOnRow: "வரிசையில் தயாரிப்பு அலகு தேவை",
    productQuantityIsRequiredOnRow: "வரிசையில் தயாரிப்பு அளவு தேவை",
    productVariantsRequireTracking: "தயாரிப்பு வகைகளுக்கு கண்காணிப்பு தேவை!",
    pleaseAddVariantClickButton:
      "சேர் பொத்தானைக் கிளிக் செய்வதன் மூலம் ஒரு மாறுபாட்டைச் சேர்க்கவும்!",
    totalVariantsMoreThanSelectedQuantity:
      "மொத்த மாறுபாடுகள் தேர்ந்தெடுக்கப்பட்ட தயாரிப்பு அளவை விட அதிகம், Pls மாறுபாடு அளவைக் குறைக்கிறது",
    productEditedSuccessfully: "தயாரிப்பு வெற்றிகரமாக திருத்தப்பட்டது!",
    fileTooLargeLessThan4Mb:
      "கோப்பின் அளவு மிகப் பெரியது! கோப்பின் அளவு 4 எம்பிக்கு குறைவாக இருக்க வேண்டும்.",
    suchVariantAlreadyExist: "அத்தகைய மாறுபாடு ஏற்கனவே உள்ளது",
    addVariants: "மாறுபாடுகளைச் சேர்க்கவும்",
    editProduct: "தயாரிப்பைத் திருத்து",
    pleaseEnterProductName: "தயவுசெய்து தயாரிப்பு பெயரை உள்ளிடவும்",
    pleaseEnterProductPrice: "தயவுசெய்து தயாரிப்பு விலையை உள்ளிடவும்",
    pleaseEnterProductOriginalPrice:
      "தயவுசெய்து தயாரிப்பு அசல் விலையை உள்ளிடவும்",
    productDescription: "தயாரிப்பு விளக்கம்",
    selectProductCategory: "தயாரிப்பு வகையைத் தேர்ந்தெடுக்கவும்",
    pleaseSelectProductCategory:
      "தயவுசெய்து தயாரிப்பு வகையைத் தேர்ந்தெடுக்கவும்",
    productCostPrice: "தயாரிப்பு செலவு விலை",
    productSellingPrice: "தயாரிப்பு விற்பனை விலை",
    productOriginalPrice: "தயாரிப்பு அசல் விலை",
    maxFileSizeAllowedIs4Mb: "அதிகபட்சம் அனுமதிக்கப்பட்ட கோப்பு அளவு 4 எம்பி",
    productsWithPicturesArePublished:
      "ஆர்டர்களைப் பெற படங்களுடன் கூடிய தயாரிப்புகள் டிஸ்கவரில் வெளியிடப்படுகின்றன",
    shouldThisProductBeTracked: "இந்த தயாரிப்பு கண்காணிக்கப்பட வேண்டுமா?",
    pleaseSelectStockUnit: "பங்கு அலகு தேர்ந்தெடுக்கவும்",
    stockUnit: "பங்கு அலகு",
    bag: "BAG",
    bottle: "பாட்டில்",
    bunch: "கொத்து",
    can: "முடியும்",
    carton: "அட்டைப்பெட்டி",
    crate: "கூடையின்",
    cup: "கோப்பை",
    dozen: "DOZEN",
    gigabytes: "ஜிகாபைட்ஸ்",
    gram: "கிராம்",
    kilogram: "கிலோகிராம்",
    litre: "LITER",
    pack: "பேக்",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "தட்டு",
    tonne: "டோன் (எம்டி)",
    uNIT: "அலகு",
    yard: "YARD",
    pleaseEnterProductQuantity: "தயவுசெய்து தயாரிப்பு அளவை உள்ளிடவும்",
    productQuantity: "தயாரிப்பு அளவு",
    isThisProductTaxed: "இந்த தயாரிப்புக்கு வரி விதிக்கப்படுகிறதா?",
    selectTaxType: "வரி வகையைத் தேர்ந்தெடுக்கவும்",
    pleaseSelectTaxType: "வரி வகையைத் தேர்ந்தெடுக்கவும்",
    progressive: "முற்போக்கு",
    proportional: "விகிதாசார",
    pleaseEnterProductTaxRate: "தயவுசெய்து தயாரிப்பு வரி விகிதத்தை உள்ளிடவும்",
    doesProductHaveVariants: "இந்த தயாரிப்புக்கு மாறுபாடுகள் உள்ளதா?",
    type: "வகை",
    value: "மதிப்பு",
    pleaseEnterVariantType: "மாறுபாடு வகையை உள்ளிடவும்",
    pleaseEnterVariantValue: "மாறுபாட்டின் மதிப்பை உள்ளிடவும்",
    pleaseEnterVariantPrice: "தயவுசெய்து மாறுபாட்டின் விலையை உள்ளிடவும்",
    pleaseEnterVariantQuantity: "தயவுசெய்து மாறுபாட்டின் அளவை உள்ளிடவும்",
    productDeletedSuccessfully: "தயாரிப்பு வெற்றிகரமாக நீக்கப்பட்டது!",
    categoryDeletedSuccessfully: "வகை வெற்றிகரமாக நீக்கப்பட்டது!",
    toDeleteThisProduct: "இந்த தயாரிப்பை நீக்க",
    invalidProductQuantity: "தவறான தயாரிப்பு அளவு",
    quantityAddedIsOverStock:
      "நீங்கள் சேர்த்துக் கொள்ளும் அளவு உங்கள் கையிருப்பில் உள்ளது.",
    itemInventoryNotTracked: "பொருள் சரக்கு கண்காணிக்கப்படவில்லை",
    addBulkQuantity: "மொத்த அளவைச் சேர்க்கவும்",
    enterBulkQuantity: "மொத்த அளவை உள்ளிடவும்",
    pleaseEnterBulkQuantity: "தயவுசெய்து மொத்த அளவை உள்ளிடவும்",
    youveSuccessfullyAddedANewProduct:
      "நீங்கள் ஒரு புதிய தயாரிப்பை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்!",
    pleaseEnterProductSellingPrice:
      "தயவுசெய்து தயாரிப்பு விற்பனை விலையை உள்ளிடவும்",
    doYouWantToTrackProductStock: "தயாரிப்புப் பங்கைக் கண்காணிக்க வேண்டுமா?",
    sellingPrice: "விற்பனை விலை",
    setProductExpiryReminder: "தயாரிப்பு காலாவதி நினைவூட்டலை அமைக்கவும்",
    productExpiryDate: "தயாரிப்பு காலாவதி தேதி",
    startRemindingFrom: "இதிலிருந்து நினைவூட்டலைத் தொடங்குங்கள்",
    productSuppliesAddedSuccessfully:
      "தயாரிப்புப் பொருட்களை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்.",
    addProductSupplies: "தயாரிப்பு பொருட்களைச் சேர்க்கவும்",
    pleaseSelectSupplier: "தயவுசெய்து சப்ளையரைத் தேர்ந்தெடுக்கவும்",
    nameOfProduct: "தயாரிப்பின் பெயர்",
    pleaseSelectProduct: "தயவுசெய்து தயாரிப்பைத் தேர்ந்தெடுக்கவும்",
    productVariant: "தயாரிப்பு மாறுபாடு",
    pleaseSelectAVariant: "தயவுசெய்து ஒரு மாறுபாட்டைத் தேர்ந்தெடுக்கவும்",
    pleaseEnterUnitPrice: "அலகு விலையை உள்ளிடவும்",
    businessBranch: "வணிக கிளை",
    pleaseSelectBranch: "கிளையைத் தேர்ந்தெடுக்கவும்",
    youveSuccessfullyAddedANewSupplier:
      "நீங்கள் ஒரு புதிய சப்ளையரை வெற்றிகரமாகச் சேர்த்துள்ளீர்கள்",
    addSupplier: "சப்ளையரைச் சேர்க்கவும்",
    pleaseEnterSupplierEmail: "தயவுசெய்து சப்ளையரின் மின்னஞ்சலை உள்ளிடவும்",
    pleaseAddADescription: "தயவுசெய்து ஒரு விளக்கத்தைச் சேர்க்கவும்",
    anErrorOccuredProductsDeleted:
      "அறுவை சிகிச்சை செய்யும் போது பிழை ஏற்பட்டது. தயவுசெய்து கவனிக்கவும், செயல்பாட்டில் சில தயாரிப்புகள் நீக்கப்பட்டிருக்கலாம்",
    bulkDelete: "மொத்தமாக நீக்கு",
    youAreAboutToDelete: "நீங்கள் நீக்க உள்ளீர்கள்",
    product: "தயாரிப்பு",
    isDueToRedeem: "மீட்பு காரணமாக உள்ளது",
    aReward: "ஒரு வெகுமதி",
    pleaseSelectCustomerToReeemReward:
      "வெகுமதியை ரிடீம் செய்ய வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்.",
    youHaveNoLoyaltyProgramRunning:
      "உங்களிடம் செயலில் உள்ள விசுவாசத் திட்டம் இல்லை",
    customerHhasNoPointsInLoyaltyProgram:
      "இந்த விசுவாசத் திட்டத்தில் வாடிக்கையாளருக்கு புள்ளிகள் இல்லை",
    proceedWithPayment: "கட்டணத்துடன் தொடரவா?",
    youAreAboutToPayForTransactionUsingPoints:
      "புள்ளிகளைப் பயன்படுத்தி பரிவர்த்தனைக்கு நீங்கள் பணம் செலுத்த உள்ளீர்கள்.",
    customerHas: "வாடிக்கையாளர் உண்டு",
    worth: "மதிப்பு",
    andIsNotSufficientToPayForTransaction:
      "மேலும் இந்த பரிவர்த்தனைக்கு பணம் செலுத்த போதுமானதாக இல்லை. அவர்கள் மற்றொரு கட்டண முறையைப் பயன்படுத்தி மீதியைச் சேர்க்க விரும்புகிறார்களா?",
    addCustomerLoyalty: "வாடிக்கையாளர் விசுவாசத்தைச் சேர்க்கவும்",
    pleaseAddCustomerFirst:
      "முதலில் ஒரு வாடிக்கையாளரைச் சேர்க்கவும் அல்லது தேர்ந்தெடுக்கவும்.",
    pleaseWaitWhileWeGetReady:
      "தயவுசெய்து காத்திருங்கள், நாங்கள் தயாராகும் போது",
    lowStock: "குறைந்த பங்கு",
    pleaseEnterAmountTendered: "டெண்டர் செய்யப்பட்ட தொகையை உள்ளிடவும்",
    areYouSureToBookSaleOffline:
      "இந்த விற்பனையை நிச்சயமாக ஆஃப்லைனில் பதிவு செய்ய விரும்புகிறீர்களா?",
    saleWouldBeBookedAutomatically:
      "உங்கள் இணையத்தை இயக்கும்போது விற்பனை தானாகவே பதிவு செய்யப்படும்",
    offlineSalesBookingCancelled:
      "ஆஃப்லைன் விற்பனை முன்பதிவு ரத்து செய்யப்பட்டது",
    covid19Message:
      "கோவிட் 19: உங்கள் கைகளை சோப்பு கொண்டு கழுவவும் அல்லது பரவுவதைத் தடுக்க சுத்தப்படுத்தவும். எப்போதும் பாதுகாப்பாக இருங்கள்",
    saleSuccessfullyRecorded: "விற்பனை வெற்றிகரமாக பதிவு செய்யப்பட்டது!",
    sendReceiptToEmail: "மின்னஞ்சலுக்கு ரசீது அனுப்பவும்",
    sendThankYouSms: "நன்றி எஸ்எம்எஸ் அனுப்பவும்",
    sendShippingDetails: "கப்பல் விவரங்களை அனுப்பவும்",
    addLoyalty: "விசுவாசத்தைச் சேர்க்கவும்",
    searchCustomerNameOrNumber: "வாடிக்கையாளர் பெயர் அல்லது எண்ணைத் தேடுங்கள்",
    clickTheSearchCustomerBox:
      "தேடல் வாடிக்கையாளர் பெட்டி மற்றும் ஸ்கேன் கார்டைக் கிளிக் செய்யவும்",
    enterProductPrice: "தயாரிப்பு விலையை உள்ளிடவும்",
    enterPriceFor: "க்கான விலையை உள்ளிடவும்",
    searchForProduct: "தயாரிப்பைத் தேடுங்கள்",
    all: "அனைத்து",
    backToDashboard: "டாஷ்போர்டுக்குத் திரும்பு",
    viewDraftSales: "வரைவு விற்பனையைக் காண்க",
    variantSelected: "மாறுபாடு தேர்ந்தெடுக்கப்பட்டது",
    variant: "மாறுபாடு",
    thePreviousVariantSelectionNotAvailable:
      "விலையின் அடிப்படையில் தேர்ந்தெடுக்கப்பட்ட புதிய மாறுபாட்டிற்கு முந்தைய மாறுபாடு தேர்வு கிடைக்கவில்லை, தயவுசெய்து உங்கள் தேர்வை மாற்றவும்.",
    pleaseSupplyPositiveQuantityNumber:
      "தயவுசெய்து ஒரு நேர்மறை அளவு எண்ணை வழங்கவும்",
    lowStockFor: "குறைந்த பங்கு",
    clearVariants: "தெளிவான மாறுபாடுகள்",
    pleaseEnterQuantity: "தயவுசெய்து அளவை உள்ளிடவும்",
    picture: "படம்",
    redemptionToken: "மீட்பு டோக்கன்",
    token: "டோக்கன்",
    totalSpent: "மொத்த செலவு",
    confirmPayment: "கட்டணத்தை உறுதிப்படுத்தவும்",
    hasPaymentBeenMade: "கட்டணம் வெற்றிகரமாக செயல்படுத்தப்பட்டதா?",
    enterTransactionReference:
      "நீங்கள் செலுத்திய பரிவர்த்தனை குறிப்பை உள்ளிடவும்",
    pleaseSelectACustomer: "தயவுசெய்து ஒரு வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்!",
    areYouSureToApplyDiscount:
      "நீங்கள் நிச்சயமாக தள்ளுபடி விண்ணப்பிக்க விரும்புகிறீர்களா?",
    addYourBankAccountToEnableUssd:
      "UPay மூலம் உடனடி USSD பரிமாற்றத்தை செயல்படுத்த உங்கள் வங்கிக் கணக்கைச் சேர்க்கவும்",
    totalAmountToPay: "செலுத்த வேண்டிய மொத்த தொகை",
    doYouWantToCancelTransaction:
      "இந்த பரிவர்த்தனையை ரத்து செய்ய விரும்புகிறீர்களா?",
    savePrintBill: "பில்லைச் சேமிக்கவும்/அச்சிடவும்",
    enterAmountCollectedForCustomer:
      "வாடிக்கையாளருக்காக சேகரிக்கப்பட்ட தொகையை உள்ளிடவும்",
    recordSale: "பதிவு விற்பனை",
    checkOutWith: "உடன் சரிபார்க்கவும்",
    instantTransfer: "உடனடி இடமாற்றம்",
    dialTheUSSDCode: "USSD குறியீட்டை டயல் செய்யவும்",
    pleaseSelectABank: "தயவுசெய்து ஒரு வங்கியைத் தேர்ந்தெடுக்கவும்",
    payWithUSSD: "USSD உடன் பணம் செலுத்துங்கள்",
    sendBillTo: " - க்கு பில் அனுப்பவும்",
    waitingForUSSDTransfer: "USSD பரிமாற்றத்திற்காக காத்திருக்கிறது",
    percent: "சதவீதம்",
    applyDiscount: "தள்ளுபடி விண்ணப்பிக்கவும்",
    thisBillHasBeenSaved: "இந்த மசோதா சேமிக்கப்பட்டது",
    saveDraft: "வரைவை சேமிக்கவும்",
    pleaseTypeANameToIdentifyCustomer:
      "வாடிக்கையாளரை அடையாளம் காண தயவுசெய்து ஒரு பெயரை உள்ளிடவும்",
    paymentDetails: "கட்டண விவரங்கள்",
    basePrice: "அடிப்படை விலை",
    staff: "ஊழியர்கள்",
    subTotal: "கூட்டுத்தொகை",
    durationMonths: "காலம் (மாதங்கள்)",
    selectADuration: "ஒரு காலத்தைத் தேர்ந்தெடுக்கவும்",
    oneMonth: "1 மாதம்",
    twoMonths: "2 மாதங்கள்",
    threeMonths: "3 மாதங்கள்",
    sixMonths: "6 மாதங்கள்",
    twelveMonths: "12 மாதங்கள்",
    eighteenMonths: "18 மாதங்கள்",
    twentyFourMonths: "24 மாதங்கள்",
    twoMonthsFree: "(2 மாதங்கள் இலவசம்)",
    threeMonthsFree: "(3 மாதங்கள் இலவசம்)",
    fiveMonthsFree: "(5 மாதங்கள் இலவசம்)",
    yourAccountHasBeen: "உங்கள் கணக்கு இருந்தது",
    renewed: "புதுப்பிக்கப்பட்டது",
    upgraded: "மேம்படுத்தப்பட்டது",
    successfully: "வெற்றிகரமாக",
    yourSubscription: "உங்கள் சந்தா",
    youAreCurrentlyEnrolledOnA: "நீங்கள் தற்போது ஏ",
    pleaseChooseAPaymentOption: "கட்டண விருப்பத்தை தேர்வு செய்யவும்",
    planRenewal: "திட்ட புதுப்பித்தல்",
    planUpgrade: "திட்ட மேம்பாடு",
    pleaseSelectDurationToPayFor:
      "நீங்கள் செலுத்த விரும்பும் காலத்தைத் தேர்ந்தெடுக்கவும்",
    staffAccounts: "பணியாளர் கணக்குகள்",
    ecommerce: "இணையவழி",
    pleaseSelectAPlan: "தயவுசெய்து ஒரு திட்டத்தை தேர்ந்தெடுக்கவும்",
    includeAddons: "துணை நிரல்களைச் சேர்க்கவும்",
    viewTransactions: "பரிவர்த்தனைகளைக் காண்க",
    customerHasNoCompletedTansactions:
      "வாடிக்கையாளருக்கு இன்னும் நிறைவடையவில்லை",
    branch: "கிளை",
    enterNumberOfEcommerceBranches: "இணையவழி கிளைகளின் எண்ணிக்கையை உள்ளிடவும்",
    enterNumberOfEcommerceBranchesToPay:
      "நீங்கள் செலுத்த விரும்பும் இணையவழி கிளைகளின் எண்ணிக்கையை உள்ளிடவும்",
    ecommerceIntegration: "இணையவழி ஒருங்கிணைப்பு",
    enterNumberOfBranches: "கிளைகளின் எண்ணிக்கையை உள்ளிடவும்",
    enterNumberOfAdditionalBranchesToPay:
      "நீங்கள் செலுத்த விரும்பும் கூடுதல் கிளைகளின் எண்ணிக்கையை உள்ளிடவும்",
    enterNumberOfStaffs: "ஊழியர்களின் எண்ணிக்கையை உள்ளிடவும்",
    enterNumberOfStaffsToPayFor:
      "நீங்கள் செலுத்த விரும்பும் ஊழியர்களின் எண்ணிக்கையை உள்ளிடவும்",
    discountApplies: "தள்ளுபடி பொருந்தும்",
    starsOnThe: "மீது நட்சத்திரங்கள்",
    offer: "சலுகை",
    get: "பெறு",
    moreStarsToRedeem: "மீட்க அதிக நட்சத்திரங்கள்",
    taxVat: "வரி (VAT)",
    callCashierToCompletePayment: "கட்டணத்தை முடிக்க காசாளரை அழைக்கவும்",
    receipt: "ரசீது",
    dear: "அன்பே",
    thankYouForYourOrderFindGoods:
      "உங்கள் ஆர்டருக்கு நன்றி. ஒப்புக்கொண்டபடி, வழங்கப்பட்ட பின்வரும் பொருட்களை தயவுசெய்து கண்டுபிடிக்கவும்.",
    shippingNote: "கப்பல் குறிப்பு",
    enterShippingNote: "ஷிப்பிங் குறிப்பை உள்ளிடவும்",
    shippingAddress: "சேரும் முகவரி",
    enterShippingAddress: "ஷிப்பிங் முகவரியை உள்ளிடவும்",
    wellDoneYouAreDueToRedeem:
      "நல்லது! நீங்கள் மீட்புக்கு காரணமாக இருக்கிறீர்கள்",
    toGetYourRewardNextVisit:
      "உங்கள் அடுத்த வருகையில் உங்கள் வெகுமதியைப் பெற. நன்றி",
    pointsOnThe: "புள்ளிகள்",
    morePointsToRedeem: "மீட்க அதிக புள்ளிகள்",
    showCode: "குறியீட்டைக் காட்டு",
    toGetYourRewardOnNextVisit:
      "உங்கள் அடுத்த வருகையில் உங்கள் வெகுமதியைப் பெற. நன்றி",
    earn: "சம்பாதி",
    delivaryNote: "விநியோக குறிப்பு",
    draftSales: "வரைவு விற்பனை",
    startDate: "தொடக்க தேதி",
    endDate: "கடைசி தேதி",
    orders: "உத்தரவுகள்",
    checkout: "சரிபார்",
    noProductItem: "தயாரிப்பு பொருள் இல்லை",
    selectProductImage: "தயாரிப்பு படத்தை தேர்ந்தெடுக்கவும்",
    selectCountry: "நாட்டினை தேர்வுசெய்",
    selectRegion: "மாநிலம்/பிராந்தியத்தைத் தேர்ந்தெடுக்கவும்",
    printProductTag: "தயாரிப்பு குறிச்சொல்லை அச்சிடுங்கள்",
    transactionReference: "பரிவர்த்தனை குறிப்பு",
    Cashier: "காசாளர்",
    Manager: "மேலாளர்",
    Owner: "உரிமையாளர்",
    Admin: "நிர்வாகம்",
    addPartners: "கூட்டாளர்களைச் சேர்க்கவும்",
    addNewLoyaltyPartner: "புதிய லாயல்டி பார்ட்னரைச் சேர்க்கவும்",
    pleaseEnterCompanyName: "நிறுவனத்தின் பெயரை உள்ளிடவும்",
    companyName: "நிறுவனத்தின் பெயர்",
    pleaseEnterCompanyRepName: "நிறுவனத்தின் பிரதிநிதி பெயரை உள்ளிடவும்",
    companyRepName: "நிறுவனத்தின் பிரதிநிதியின் பெயர்",
    pleaseEnterCompanyRepEmail:
      "நிறுவனத்தின் பிரதிநிதியின் மின்னஞ்சலை உள்ளிடவும்",
    companyRepEmail: "நிறுவனத்தின் பிரதிநிதியின் மின்னஞ்சல்",
    pleaseEnterCompanyRepPhone:
      "நிறுவனத்தின் பிரதிநிதியின் தொலைபேசி எண்ணை உள்ளிடவும்",
    companyRepPhone: "நிறுவனத்தின் பிரதிநிதியின் தொலைபேசி எண்",
    addReward: "வெகுமதியைச் சேர்க்கவும்",
    pleaseEnterRewardName: "தயவுசெய்து வெகுமதி பெயரை உள்ளிடவும்",
    rewardName: "வெகுமதி பெயர்",
    rewardQuantity: "வெகுமதி அளவு",
    rewardDescription: "வெகுமதி விளக்கம்",
    rewardType: "வெகுமதி வகை",
    pleaseEnterRewardType: "தயவுசெய்து வெகுமதி வகையை உள்ளிடவும்",
    pleaseEnterRewardQuantity: "வெகுமதியின் அளவை உள்ளிடவும்",
    pleaseEnterRewardDescription: "தயவுசெய்து வெகுமதி விளக்கத்தை உள்ளிடவும்",
    fineDining: "ஃபைன் டைனிங்",
    luxuryFashion: "ஆடம்பர ஃபேஷன்",
    hotels: "ஹோட்டல்கள்",
    travel: "பயணம்",
    foodAndBeverage: "உணவு மற்றும் குளிர்பானங்கள்",
    fashion: "ஃபேஷன்",
    health: "ஆரோக்கியம்",
    furniture: "மரச்சாமான்கள்",
    entertainment: "பொழுதுபோக்கு",
    automobile: "ஆட்டோமொபைல்",
    education: "கல்வி",
    beautyAndSpa: "அழகு மற்றும் ஸ்பா",
    staycation: "தங்குதல்",
    events: "நிகழ்வுகள்",
    trips: "பயணங்கள்",
    oilAndGas: "எண்ணெய் மற்றும் எரிவாயு",
    laundry: "சலவை",
    partnerCategory: "கூட்டாளர் வகை",
    freeItem: "இலவச பொருள்",
  },
  Swahili: {
    cashier: "mtunza fedha",
    manager: "Meneja",
    owner: "mmiliki",
    online: "mkondoni",
    offline: "nje ya mtandao",
    changePassword: "Badilisha neno la siri",
    currentPasswordMessage: "Tafadhali ingiza nywila yako ya sasa",
    passwordMessage: "Tafadhali ingiza nywila yako",
    currentPassword: "Nenosiri la sasa",
    password: "nywila",
    confirmPasswordMessage: "Tafadhali thibitisha nywila yako!",
    confirmPassword: "Thibitisha Nenosiri",
    sendViaEmail: "Tuma Kupitia Barua pepe",
    sendViaWhatsapp: "Tuma Kupitia WhatsApp",
    downloadPdf: "Pakua PDF",
    paid: "kulipwa",
    unpaid: "bila kulipwa",
    partial: "sehemu",
    closeInvoice: "Je! Unataka kufunga Ankara?",
    closeInvoiceConfirmation:
      "Ankara haiwezi kuhifadhiwa. Je! Unataka kufunga?",
    yes: "ndio",
    no: "Hapana",
    invoice: "Ankara",
    wasDeducted: "ilikatwa",
    for: "kwa",
    item: "Bidhaa",
    addProduct: "Ongeza Bidhaa",
    paymentReference: "Rejea ya Malipo",
    amountPaid: "Kiasi Kilicholipwa",
    discountAmount: "Kiasi cha Punguzo",
    amountDue: "Kiasi cha deni",
    amount: "Kiasi",
    dueDate: "Tarehe ya kukamilisha",
    paymentType: "Aina ya malipo",
    card: "Kadi",
    cash: "Fedha",
    bankTransfer: "Uhamisho wa Benki",
    paymentMessage: "Ujumbe wa Malipo",
    description: "Maelezo",
    sendReceipt: "Tuma Risiti",
    delete: "Futa",
    save: "Okoa",
    resend: "Tuma tena",
    saveAndSend: "Okoa",
    invoiceSaved: "Ankara imehifadhiwa!",
    selectPaymentMethod: "Tafadhali chagua Njia ya Malipo!",
    selectCustomer: "Tafadhali chagua Mteja!",
    cartEmptyError:
      "Orodha ya Kikapu haiwezi kuwa tupu, funga ankara na ongeza Bidhaa kwenye mkokoteni!",
    subscriptionExpired:
      "Usajili wako umekwisha na akaunti yako sasa imepunguzwa. Bonyeza kwenye Kitufe hapa chini ili kufanya upya akaunti yako",
    renew: "Upya",
    holdOn: "Subiri",
    customerBank: "Benki ya Wateja",
    cancel: "Ghairi",
    selectACustomer: "Chagua Mteja",
    invoiceSuccessfulPdfError:
      "Ankara imeundwa bila mafanikio lakini Kizazi cha PDF kinachukua muda mrefu kuliko kawaida. Tafadhali angalia tena hivi karibuni.",
    downloadingInvoice: "Inapakua Ankara",
    downloadingReceipt: "Inapakua Stakabadhi",
    whatsappReceiptError:
      "Hitilafu ilitokea kutuma risiti kupitia WhatsApp, Tafadhali jaribu tena.",
    receiptToWhatsapp: "Stakabadhi imepelekwa kwa WhatsApp",
    thankYouForPatronage: "Asante kwa ulinzi wako",
    hereIsYourReceipt: "Hapa kuna risiti yako ya malipo",
    errorSendingEmailReceipt:
      "Hitilafu ilitokea kutuma risiti kupitia barua pepe, tafadhali jaribu tena au wasiliana na msaada",
    receiptSentToEmail: "Stakabadhi imetumwa kwa barua pepe ya mteja",
    invoiceSentToEmail: "Ankara imetumwa kwa barua pepe ya mteja",
    invoiceSuccessWhatsappError:
      "Ankara imeundwa bila mafanikio lakini hitilafu imetokea kutuma kwa WhatsApp. Jaribu tena kwenye orodha ya ankara",
    invoiceSuccessfulEmailError:
      "Ankara imeundwa kwa mafanikio lakini hitilafu ilitokea wakati wa kutuma kama barua pepe. Tafadhali jaribu tena kutoka orodha ya ankara",
    invoiceSentToWhatsapp: "Ankara iliyopelekwa kwa WhatsApp",
    hello: "Halo",
    pleaseDownloadInvoice: "Tafadhali pakua ankara",
    pleaseDownloadReceipt: "Tafadhali pakua risiti",
    from: "kutoka",
    email: "Barua pepe",
    upgrade: "Boresha",
    youAreOnFreePlan: "Uko kwenye Mpango wa Bure.",
    clickOn: "Bonyeza",
    yourPlanInFewSteps: " usajili wako katika hatua chache za haraka.",
    to: "kwa",
    yourSubscriptionHasExpired:
      "Usajili wako umekwisha na akaunti yako sasa imepunguzwa.",
    days: "siku",
    yourSubscriptionExpiresIn: "Usajili wako wa Loystar unaisha",
    createAcount: "Fungua akaunti",
    signIn: "Weka sahihi",
    continue: "Endelea",
    enterOtp: "Ingiza PIN ya OTP",
    enterValidOtp: "Tafadhali weka PIN halali",
    pin: "PIN",
    tokenSentToMail: "Ishara imetumwa kwa barua pepe yako",
    passwordResetSuccessful: "Kuweka upya nenosiri kulifanikiwa",
    somethingWentWrong: "Hitilafu imetokea!",
    resetPassword: "Weka upya Nenosiri",
    iHaveResetCode: "Nina nambari ya kuweka upya nenosiri",
    pleaseEnterEmail: "Tafadhali ingiza barua pepe yako",
    aTokenWillBeSentToEmail: "Ishara itatumwa kwa barua pepe yako",
    enterEmail: "Ingiza barua pepe yako",
    sendinYourToken: "Inatuma tokeni yako ...",
    makeSureItMatchesPassword: "Hakikisha inalingana na nywila yako mpya",
    pleaseChooseNewPassword: "Tafadhali chagua nywila mpya",
    chooseNewPassword: "Chagua nywila mpya",
    enterNewPassword: "Ingiza nywila yako mpya ili uthibitishe",
    enterTokenSent: "Ingiza ishara iliyotumiwa barua yako",
    resetToken: "Weka upya Ishara",
    resettingPassword: "Inaweka upya Nenosiri lako ...",
    signUp: "Jisajili",
    adminSignInWithEmail:
      " Kuingia kwa Admin kwa barua pepe wakati Wafanyakazi wanaingia na jina la mtumiaji.",
    pleaseEnterEmailOrUsername:
      "Tafadhali ingiza barua pepe yako au jina la mtumiaji",
    emailOrUsername: "Barua pepe au Jina la Mtumiaji",
    pleaseEnterPassword: "Tafadhali ingiza nywila",
    createAnAccount: "Fungua akaunti",
    forgotPassword: "Umesahau nywila?",
    enterPhoneNumber: "Ingiza Nambari ya Simu",
    personalData: "Taarifa binafsi",
    validateConfirmationCOde: "Thibitisha Msimbo wa Uthibitishaji",
    pleaseEnterFirstName: "Tafadhali ingiza jina lako la kwanza",
    pleaseEnterPhoneNumber: "Tafadhali ingiza nambari yako ya simu",
    pleaseEnterLastName: "Tafadhali ingiza jina lako la mwisho",
    pleaseEnterBusinessName: "Tafadhali ingiza jina lako la biashara",
    firstName: "Jina la kwanza",
    lastName: "Jina la familia",
    businessName: "Jina la Biashara",
    previous: "Iliyotangulia",
    next: "Ifuatayo",
    pleaseSelectBusinessCategory: "Tafadhali chagua kategoria ya biashara yako",
    pleaseEnterValidEmail: "Tafadhali ingiza barua pepe halali",
    pleaseEnterPostCode: "Tafadhali ingiza msimbo wa posta",
    postCode: "Nambari ya Barua",
    phoneNumberInUse: "Nambari hii ya simu tayari inatumika!",
    emailInUse: "Barua pepe hii tayari inatumika!",
    foodAndDrinks: "Chakula na Vinywaji",
    salonAndBeauty: "Saluni na Uzuri",
    fashionAndAccessories: "Mitindo na Vifaa",
    gymAndFitness: "Gym na Fitness",
    travelAndHotel: "Usafiri na Hoteli",
    homeAndGifts: "Nyumba na Zawadi",
    washingAndCleaning: "Kuosha na Kusafisha",
    gadgetsAndElectronics: "Vifaa na Elektroniki",
    groceries: "Vyakula",
    others: "Wengine",
    submit: "Wasilisha",
    accountCreatedSuccessful: "Akaunti yako iliundwa kwa mafanikio.",
    pleaseEnterAddress: "Tafadhali Ingiza Anwani Yako",
    addressLine1: "Mstari wa Anwani 1",
    addressLine2: "Mstari wa Anwani 2",
    choosePassword: "Chagua Nenosiri",
    passwordMustBe6Characters: "Nenosiri lazima liwe na angalau herufi 6.",
    howDidYouHearLoystar: "Ulisikiaje kuhusu Loystar?",
    referralCode: "Kanuni ya rufaa",
    byClickingTheButton: " Kwa kubofya kitufe hapa chini, unakubali",
    termsAndSevice: "Masharti",
    wereCreatingAccount: "Tunaunda akaunti yako",
    proceed: "Endelea",
    verificationCodeMustBe6:
      "Nambari ya Uthibitishaji lazima iwe na tarakimu 6",
    pleaseEnter6DigitCode: "Tafadhali Ingiza nambari 6 ya nambari",
    enterVerificationCode: "Ingiza Nambari ya kuthibitisha",
    resendToken: "Tuma tena Ishara",
    verify: "Thibitisha",
    transactions: "Miamala",
    todaySales: "Mauzo ya Leo",
    salesHistory: "Historia ya Mauzo",
    supplyHistory: "Historia ya Ugavi",
    new: "Mpya",
    invoices: "Ankara",
    disbursements: "Malipo",
    offlineSales: "Mauzo ya Nje ya Mtandao",
    products: "Bidhaa",
    customers: "Wateja",
    multiLevelLoyalty: "Uaminifu wa Ngazi Mbalimbali",
    loyaltyPrograms: "Programu za Uaminifu",
    members: "Wanachama",
    appStore: "Duka la App",
    orderMenu: "Menyu ya Agizo",
    settings: "Mipangilio",
    staffAndBranches: "Wafanyikazi na Matawi",
    myAccount: "Akaunti yangu",
    switchToSellMode: "Badilisha kwa Njia ya Kuuza",
    signOut: "Toka",
    getFreeSubscription: "Pata Usajili wa Bure",
    onlyNumbersAllowed: "Nambari tu zinaruhusiwa",
    yourAccountMustBe10Digits:
      "nambari yako ya akaunti lazima iwe nambari ya tarakimu 10",
    yourBvnMustBe11: "BVN yako lazima iwe nambari 11 ya nambari",
    pleaseSelectBank: "Tafadhali chagua benki yako",
    selectYourBank: "Chagua Benki yako",
    enterBankAccountNumber: "Ingiza Nambari ya Akaunti ya Benki",
    enterBvnNumber: "Ingiza BVN yako",
    connectBankAccount: "Unganisha Akaunti ya Benki",
    passwordResetSuccessfulAndSignOut:
      "Nenosiri lako limewekwa upya kwa ufanisi. Bonyeza kitufe cha Ingia hapo chini ili uingie tena",
    enterCurrentPassword: "Ingiza nywila ya sasa",
    pleaseEnterCurrentPassword: "Tafadhali ingiza nenosiri la sasa",
    phoneNumber: "Nambari ya simu",
    sex: "Ngono",
    dateOfBirth: "Tarehe ya kuzaliwa",
    state: "Hali",
    country: "Nchi",
    loyaltyId: "Kitambulisho cha uaminifu",
    createdAt: "Iliundwa saa",
    noOfTransactionsMade: "Hapana ya shughuli zilizofanywa",
    yourDownloadWillStart: "Upakuaji wako utaanza kwa muda mfupi",
    exportCustomers: "Wateja wa kusafirisha nje",
    youhaveSuccessfullyToppedUp: "Umefanikiwa kuongeza Vitengo vyako vya SMS.",
    youNowHave: "Sasa unayo",
    smsUnits: "Vitengo vya SMS",
    enterNumberOfSmsUnits: "Ingiza Idadi ya Vitengo vya SMS Unakusudia Kununua",
    pleaseEnterANumericValue: "Tafadhali ingiza thamani ya nambari",
    pay: "Lipa",
    accountEditedSuccessfully: "Akaunti yako ilibadilishwa kwa mafanikio.",
    youAeCurrentlyOn: "Umewasha sasa",
    plan: "Panga",
    userData: "Takwimu za Mtumiaji",
    businessData: "DATA YA BIASHARA",
    businessCategory: "Catergory ya Biashara",
    pleaseSelectCurrency: "Tafadhali chagua sarafu yako",
    selectYourCurrency: "Chagua sarafu yako",
    purchaseMoreSmsUnits:
      "Nunua Vitengo zaidi vya SMS kwa kutumia fomu hapa chini",
    youHave: "Unayo",
    atLeast4SmsUnitsRrequired:
      "Angalau vitengo 4 vya sms vinahitajika kwa uthibitishaji, tafadhali jiongeze!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Tafadhali thibitisha akaunti yako ya benki kutii mahitaji ya 'Mjue Mteja Wako' (KYC). Hii itakuruhusu kukusanya malipo kupitia USSD au uhamisho wa papo hapo, pokea maagizo kutoka kwa wateja na usuluhishe usuluhishi wa manunuzi. Inatumika kwa wafanyabiashara wa Nigeria tu. Tafadhali bonyeza kitufe hapa chini kuanza.",
    reConnectBankAccount: "Unganisha tena Akaunti ya Benki",
    accountName: "Jina la Akaunti",
    accountNumber: "Nambari ya Akaunti",
    bankName: "Jina la benki",
    verified: "Imethibitishwa",
    accountDetails: "Maelezo ya Akaunti",
    kycBankAccount: "KYC",
    createTier: "Unda Kiwango",
    fileUploadSuccessful: "faili imepakiwa kwa mafanikio",
    fileUploadFailed: "upakiaji wa faili umeshindwa",
    createLoyaltyProgram: "Unda Programu ya Uaminifu",
    createLoyalty: "Unda Uaminifu",
    name: "Jina",
    loyaltyArtwork: "Sanaa ya Uaminifu",
    clickToUpload: "Bonyeza kupakia",
    amountToPointsRatio: "Kiasi cha Uwiano wa Pointi",
    points: "Pointi",
    recommendedAmountToPointRatio:
      "Imependekezwa: ₦ 1 kwa 1point. kwa kila Naira 1 uliyotumia, wateja wako wanapata alama 1",
    pleaseTypeIn: "Tafadhali andika",
    toDeleteLoyalty: "kufuta uaminifu huu",
    deleteLoyalty: "Futa Uaminifu",
    toConfirm: "kuthibitisha",
    edit: "Hariri",
    pointsAwardedSuccessfully: "Vidokezo Vimetuzwa Kwa Mafanikio.",
    enterPointValueToAward:
      "Ingiza Thamani ya Nambari Ungependa Tuzo kwa Wateja",
    award: "Tuzo",
    awardPointValuesToCustomer: "Thamani ya Kiwango cha Tuzo kwa Wateja",
    enrollMembersToLoyalty: "Sajili Wanachama kwenye Uaminifu",
    awardPoints: "Pointi za Tuzo",
    enroll: "Jisajili",
    home: "Nyumbani",
    loyalty: "Uaminifu",
    enrollCustomers: "Jiandikishe Wateja",
    selected: "Imechaguliwa",
    customer: "Mteja",
    loyaltyActivationSuccessful: "Uamilishaji wa Uaminifu umefanikiwa.",
    loyaltyDeactivationSuccessful: "Uanzishaji wa uaminifu umefanikiwa.",
    viewTier: "Angalia Kiwango",
    deactivate: "Futa",
    activate: "Amilisha",
    actions: "Vitendo",
    nameOfLoyalty: "Jina la Uaminifu",
    loyaltyStatus: "Hali ya UaminifuHali ya Uaminifu",
    numberOfTiers: "Idadi ya Vifungo",
    createdOn: "Imeundwa On",
    createATier: "Unda Kiwango",
    stopCreatingTierConfirmation: "Je! Unataka kuacha kuunda safu?",
    stopEditingTierConfirmation: "Je, unataka kuacha kuhariri kiwango hiki?",
    nameOfTier: "Jina la Tier",
    minimumSpendingTarget: "Kiwango cha chini cha Matumizi",
    maximumSpendingTarget: "Kiwango cha juu cha Matumizi",
    minimumSpendingTargetRequired: "lengo la matumizi ya chini linahitajika",
    maximumSpendingTargetRequired: "lengo kubwa la matumizi linahitajika",
    rewardSponsor: "Mfadhili wa Tuzo",
    pleaseChooseARewardSponsor: "Tafadhali chagua mfadhili wa zawadi",
    self: "Binafsi",
    partner: "Mpenzi",
    rewardPartner: "Mshirika wa Tuzo",
    pleaseSelectRewardPartner: "Tafadhali chagua mshirika wako wa zawadi",
    rewards: "Zawadi",
    pleaseSelectAReward: "Tafadhali chagua zawadi",
    instructionsOnRedeemingReward:
      "Maagizo ya Jinsi Mteja Anapaswa Kukomboa Zawadi",
    pleaseFillInThisField: "Tafadhali Jaza Shamba hili!",
    toDeleteThisTier: " kufuta safu hii",
    deleteTier: "Futa Kiwango",
    viewMembers: "Angalia Wanachama",
    membersEnrolled: "Wanachama Wamesajiliwa",
    instruction: "Maagizo",
    membersIn: "Wanachama katika",
    availableTiersInLoyalty:
      "Viwango vinavyopatikana katika Programu ya Uaminifu",
    tiers: "Majeshi",
    totalTier: "JIJINI KABISA",
    availableLoyaltyProgramme: "Programu ya Uaminifu Inapatikana",
    totalLoyalties: "UAMINIFU WA JUMLA",
    memberDetails: "Maelezo ya Mwanachama",
    nameOfCustomer: "Jina la Mteja",
    address: "Anwani",
    allEnrolledMembers: "Wanachama wote waliojiandikisha",
    thisIsToWishYouHappyBirthday:
      "Hii ni kwa kukutakia siku njema ya kuzaliwa na maisha ya mafanikio. Asante kwa yote wewe ni Loyster. Sherehe Njema!",
    inputAnOfferPlease: "Ingiza Ofa tafadhali",
    pleaseSelectTheInsertPoint:
      "Tafadhali chagua sehemu ya kuingiza kwenye ujumbe na bonyeza tena",
    birthdayOfferAndMessage: "Ofa ya Siku ya Kuzaliwa na Ujumbe",
    birthdayOffer: "Ofa ya Siku ya Kuzaliwa",
    birthdayMessage: "Ujumbe wa siku ya kuzaliwa",
    noOfferFound: "Hakuna ofa iliyopatikana",
    settingABirthdayOffer:
      "Kuweka toleo la siku ya kuzaliwa huruhusu wateja kupokea ofa hii kupitia SMS kwenye siku yao ya kuzaliwa",
    createOffer: "Unda Ofa",
    whatIsTheOffer: "Ofa ni nini?",
    editMessage: "Hariri Ujumbe",
    insert: "Ingiza",
    theNameOfCustomerInserted: "Jina la mteja litaingizwa hapa",
    theBirtdayOfferInserted: "Ofa ya siku ya kuzaliwa itaingizwa hapa",
    youSuccessfullyAddedNewBranch: "Umefanikiwa kuongeza tawi jipya!",
    addNewBranch: "Ongeza Tawi Jipya",
    addBranch: "Ongeza Tawi",
    additionalBranchWillIncur: "Tawi la ziada lingeingia",
    perBranch: "kwa kila tawi",
    ecommerceBranchCosts: "Gharama za Tawi la Biashara",
    pleaseEnterBranchName: "Tafadhali ingiza jina la tawi",
    pleaseEnterBranchAddress1: "Tafadhali ingiza laini ya anwani ya tawi 1",
    enterBranchAddress1: "Ingiza laini ya anwani ya tawi 1",
    enterBranchAddress2: "Ingiza laini ya anwani ya tawi 1",
    pleaseEnterBranchAddress2: "Tafadhali ingiza laini ya anwani ya tawi 2",
    enterBranchName: "Ingiza jina la tawi",
    successfullyAddedStaff: "Umeongeza wafanyakazi mpya!",
    addNewStaff: "Ongeza Wafanyikazi Wapya",
    addStaff: "Ongeza Wafanyikazi",
    additionalStaffWillIncur: "Wafanyikazi wa ziada wangeingia",
    perStaff: "kwa wafanyikazi.",
    pleaseEnterStaffEmail: "Tafadhali ingiza barua pepe ya wafanyikazi",
    pleaseEnterStaffUsername:
      "Tafadhali ingiza jina la mtumiaji la wafanyikazi",
    pleaseEnterStaffPassword: "Tafadhali ingiza nenosiri la wafanyikazi",
    pleaseSelectStaffRole: "Tafadhali chagua jukumu la wafanyikazi",
    selectStaffRole: "Chagua jukumu la wafanyikazi",
    enterStaffEmail: "Ingiza barua pepe ya wafanyikazi",
    enterStaffUsername: "Ingiza jina la mtumiaji la wafanyikazi",
    enterStaffPassword: "Ingiza nywila ya wafanyikazi",
    spacesNotAllowedInUsername: "nafasi haziruhusiwi katika jina la mtumiaji",
    admin: "Usimamizi",
    pleaseSelectBusinessToAttachStaff:
      "Tafadhali chagua biashara ili uambatanishe wafanyikazi",
    searchForBranchToAttachStaff: "Tafuta tawi la kushikamana na wafanyikazi",
    username: "Jina la mtumiaji",
    role: "Wajibu",
    areYouSureToDeleteThis: "Je! Una hakika kufuta hii",
    branches: "Matawi",
    upgradeYourPlan: "Boresha Mpango wako.",
    add: "ONGEZA",
    addNew: "Ongeza Mpya",
    customerWithEmailAlreadyExists:
      "Mteja aliye na barua pepe / nambari ya simu tayari yupo!",
    successfullyAddedNewCustomer: "Umefanikiwa kuongeza mteja mpya!",
    addCustomer: "Ongeza Mteja",
    pleaseEnterCustomerFirstName: "Tafadhali ingiza jina la kwanza la mteja",
    pleaseEnterCustomerLastName: "Tafadhali ingiza jina la mwisho la mteja",
    pleaseEnterCustomerPhoneNumber: "Tafadhali ingiza nambari ya simu ya mteja",
    pleaseEnterCustomerEmail: "Tafadhali ingiza barua pepe ya mteja",
    pleaseEnterCustomerAddressLine:
      "Tafadhali ingiza Mstari wa Anwani ya mteja",
    pleaseEnterCustomerOtherAddress:
      "Tafadhali ingiza Anwani nyingine ya mteja",
    pleaseSelectCustomerGender: "Tafadhali chagua jinsia ya mteja",
    gender: "Jinsia",
    male: "Mwanaume",
    female: "Mwanamke",
    bank: "Benki",
    selectBank: "Chagua Benki",
    stateOrRegionOrProvince: "Jimbo / Mkoa / Mkoa",
    customerNotes: "Vidokezo vya Wateja",
    sendSms: "Tuma SMS",
    editCustomer: "Hariri Mteja",
    redeemReward: "Komboa Tuzo",
    issueLoyaltyCard: "Toa Kadi ya Uaminifu",
    deleteCustomer: "Futa Mteja",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Umefanikiwa Kumkabidhi Kitambulisho cha Uanachama wa Uaminifu",
    noMembershipIdAvailable:
      "Hakuna vitambulisho vya uanachama vinavyopatikana. Tafadhali wasiliana nasi kwa hello@loystar.co",
    sendEmail: "Kutuma barua pepe",
    membershipPoints: "Pointi za Uanachama",
    customerDetails: "Maelezo ya Mteja",
    close: "Funga",
    loyaltyBalance: "Usawa wa Uaminifu",
    pointsBalance: "Pointi Mizani",
    starBalance: "Mizani ya Nyota",
    requiredPoints: "Pointi zinazohitajika",
    requiredStars: "Nyota zinazohitajika",
    reddemCode: "Tumia Kanuni",
    toDeleteThisCustomer: "kufuta mteja huyu",
    customerHasBeenDeletedSuccessfully: "Mteja amefutwa kwa mafanikio!",
    customerWithPhoneAlreadyExists:
      "Mteja aliye na nambari ya simu tayari yupo",
    customerWasSuccessfullyEdited: "Mteja alibadilishwa kwa mafanikio",
    anErrorOccured: "Hitilafu imetokea",
    phoneNumberIncludeCountry: "Nambari ya Simu (Jumuisha Nambari ya Nchi)",
    yourFileQueuedForUpload:
      "Faili yako imewekwa foleni kwa kupakiwa. Tafadhali onyesha ukurasa upya baada ya sekunde chache.",
    thereWasAnErrorPerformingOperation:
      "Kulikuwa na hitilafu wakati wa kufanya operesheni!",
    pleaseSelectFile: "Tafadhali Chagua Faili!",
    oopsOnlyCsvFilesAllowed:
      "Lo! Faili za CSV tu zinaruhusiwa. Tafadhali pakia faili ya .csv.",
    fileShouldBeSmallerThan5Mb:
      "Faili inapaswa kuwa ndogo kuliko 5MB. Ikiwa una faili kubwa, tafadhali tuma barua pepe kwa support@loystar.co. Asante",
    customerFirstNameIsRequired:
      "jina la kwanza la mteja linahitajika kwenye safu",
    customerPhoneNumberIsRequired:
      "nambari ya simu ya mteja inahitajika kwenye safu",
    importCustomers: "Ingiza Wateja",
    upload: "Pakia",
    clickIconToDownloadCsv:
      "Bonyeza ikoni hii kupakua templeti ya faili ya CSV.",
    getGoogleSheetVersion: "Pata toleo la laha la Google",
    clickOrDragFileToUpload: "Bonyeza au buruta faili kwenye eneo hili kupakia",
    missingOrInvalidColumnHeader:
      "Kichwa cha safu wima kinachokosekana au batili. Tafadhali fuata muundo wa kiolezo. Asante.",
    youHaveImported: "Umeagiza",
    youSuccessfullyRedeemedReward: "Umefanikiwa kukomboa tuzo yako!",
    sixDigitCode: "Nambari sita ya nambari",
    pleaseEnterCustomerRewardCode:
      "Tafadhali ingiza nambari ya malipo ya mteja",
    enterRewardCode:
      "Ingiza nambari ya malipo. (Nambari ya malipo ni nyeti kwa kesi)",
    selectLoyaltyProgram: "Chagua mpango wa uaminifu",
    stamps: "Mihuri",
    smsUnitsLow: "Vitengo vya SMS Chini",
    pleaseTopUpSmsUnits: "Tafadhali Juu Juu Vitengo vya SMS",
    smsSuccessfullySentForDelivery: "SMS imefanikiwa kutumwa!",
    sendSmsTo: "Tuma SMS kwa",
    allCustomers: "Wateja wote",
    unitsAvailable: "Vitengo vinapatikana",
    pleaseTypeInTheMessage: "Tafadhali andika ujumbe",
    message: "Ujumbe",
    charactersRemaining: "herufi zilizobaki",
    avoidUseOfSpecialCharacters:
      "Epuka utumiaji wa Wahusika maalum na Emoji za kuhifadhi Vitengo vya SMS.",
    note: "Kumbuka",
    errorFetchingCustomersMultilevelDetail:
      "Kosa Kuchukua Wateja Maelezo Mbalimbali ya Wateja",
    search: "Tafuta",
    reset: "Weka upya",
    importCustomer: "Ingiza Mteja",
    addNewCustomer: "Ongeza Mteja Mpya",
    sendSmsBroadcast: "Tuma Matangazo ya SMS",
    totalCustomers: "Wateja jumla",
    disbursementDetails: "Maelezo ya Utoaji",
    paidBy: "Ilipwa Na",
    disbursed: "Imetolewa",
    bankAccountName: "Jina la Akaunti ya Benki",
    bankAccountNumber: "Nambari ya Akaunti ya Benki",
    transferInitiated: "Uhamisho umeanzishwa",
    transferCompleted: "Uhamisho Umekamilika",
    pleaseEnterAValid: "Tafadhali ingiza halali",
    begin: "anza",
    end: "mwisho",
    date: "tarehe",
    paymentDate: "Siku ya malipo",
    selectDisbursementDuration: "Chagua Muda wa Malipo",
    totalSettled: "Jumla Imetulia",
    totalUnsettled: "Jumla haijatulia",
    toDeleteThisSale: "kufuta mauzo haya",
    draftSaleDeletedSuccessfully: "Uuzaji wa Rasimu umefutwa kwa mafanikio!",
    deleteSale: "Futa Uuzaji",
    pleaseTypeInYourTotal:
      "Tafadhali andika jumla yako ili uthibitishe kufutwa",
    billDetails: "Maelezo ya Muswada",
    printBill: "Chapisha Muswada",
    servedBy: "Inatumiwa na",
    total: "Jumla",
    createdDate: "Tarehe Iliyoundwa",
    createdTime: "Wakati Ulioundwa",
    status: "Hali",
    loginSuccessful: "Kuingia Kufanikiwa",
    pleaseWaitWhileWeConnectAccount:
      "Tafadhali subiri wakati tunaunganisha akaunti yako",
    connectionFailedTryAgain: "Muunganisho umeshindwa. Tafadhali jaribu tena.",
    connectionSuccessful: "Uunganisho umefanikiwa",
    viewDetails: "Angalia Maelezo",
    enable: "Washa",
    free: "Bure",
    cost: "Gharama",
    visitWebsite: "Tembelea Tovuti",
    pleaseUpgradeYourPlanToPro:
      "Tafadhali Boresha mpango wako kwa Pro Plus ili kuwezesha programu hii",
    connectYourBankAccount:
      "Unganisha Akaunti yako ya Benki ili uweze kupokea malipo.",
    disable: "Lemaza",
    enableApp: "Washa Programu",
    addNewProductToInvoice: "Ongeza Bidhaa Mpya kwa Ankara",
    toDeleteThisInvoice: "kufuta ankara hii",
    invoiceDeletedSuccessfully: "Ankara imefutwa bila mafanikio!",
    deleteInvoice: "Futa ankara",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Umefanikiwa kuongeza ujumbe wa malipo ya ankara!",
    pleaseEnterPaymentInstructions: "Tafadhali ingiza maagizo ya malipo",
    invoiceId: "Kitambulisho cha ankara",
    paidDate: "Tarehe ya Kulipwa",
    reference: "Rejea",
    productAdded: "Bidhaa imeongezwa",
    productOutOfStock: "Bidhaa imepotea. Tafadhali rejelea hisa.",
    totalInvoices: "DUKA ZA JUMLA",
    totalPaidInvoices: "PATO ZA JUMLA",
    totalUnpaidInvoices: "PESA ZOTE ZISIZOLIPWA",
    loyaltyProgramDeleted: "Programu ya Uaminifu Imefutwa",
    thisLoyaltyProgramDeletedSuccessfully:
      "Programu hii ya uaminifu imefutwa kwa mafanikio",
    thisLoyaltyProgramEditedSuccessfully:
      "Mpango huu wa uaminifu umebadilishwa kwa mafanikio",
    loyaltyProgramAddedSuccessfully:
      "Programu ya uaminifu imeongezwa kwa mafanikio",
    loyaltyProgramEdited: "Programu ya Uaminifu Imebadilishwa",
    loyaltyProgramAdded: "Programu ya Uaminifu Imeongezwa",
    loyaltyDetails: "Maelezo ya Uaminifu",
    doYouWantToCloseDialog: "Je! Unataka kufunga mazungumzo haya?",
    pleaseEnterLoyaltyName: "Tafadhali ingiza jina la uaminifu wako",
    programType: "Aina ya Programu",
    pleaseSelectProgramType: "Tafadhali chagua aina ya programu",
    simplePoints: "Pointi Rahisi",
    stampsProgram: "Programu ya Stempu",
    threshold: "Kizingiti",
    pleaseEnterLoyaltyThreshold: "Tafadhali ingiza kizingiti cha uaminifu",
    reward: "Zawadi",
    pleaseEnterLoyaltyReward: "Tafadhali ingiza tuzo ya uaminifu",
    totalUserPoints: "Jumla ya Pointi za Mtumiaji",
    totalUserStamps: "Stempu za Mtumiaji",
    spendingTarget: "Lengo la matumizi",
    spendingTargetHint1:
      "Lengo la matumizi ni kiasi gani mteja lazima atumie kupata tuzo. Thamani 1 ya sarafu = 1 kumweka.",
    spendingTargetHint2:
      "Lengo la stempu ni stempu ngapi ambazo mteja anapaswa kukusanya ili kupata tuzo. km. 5",
    addNewLoyaltyProgram: "Ongeza Programu Mpya ya Uaminifu",
    addLoyaltyProgram: "Ongeza Programu ya Uaminifu",
    loyaltyProgramType: "Aina ya Programu ya Uaminifu",
    pleaseSelectLoyaltyProgramType:
      "Tafadhali chagua aina ya mpango wa uaminifu",
    nameOfProgram: "Jina la programu",
    pleaseEnterProgramName: "Tafadhali ingiza jina la programu",
    whatIsTheReward: "Thawabu ni nini?",
    egNextPurchaseIsFree: "Mfano. Ununuzi unaofuata ni bure",
    customerName: "Jina la mteja",
    guestCustomer: "Mteja wa Wageni",
    orderConfirmedSuccessfully: "Agizo limethibitishwa kwa mafanikio",
    orderCancelledSuccessfully: "Agizo limeghairiwa kwa mafanikio",
    confirmOrder: "Thibitisha utaratibu",
    cancelOrder: "Ghairi agizo",
    allOrders: "Maagizo yote",
    totalOrders: "AMRI ZA JUMLA",
    doYouWantToAcceptOrder: "Je! Unataka kukubali agizo hili?",
    doYouWantToCancelOrder: "Je! Unataka kughairi agizo hili?",
    orderDetails: "Maelezo ya Agizo",
    orderCreatedAt: "Agizo iliyoundwa mnamo",
    supplier: "Muuzaji",
    productName: "Jina la bidhaa",
    quantity: "Wingi",
    unitPrice: "Bei ya Kitengo",
    receivedBy: "Imepokelewa Na",
    reportFrom: "Ripoti kutoka",
    totalSupplies: "Ugavi wa Jumla",
    allRightsReserved: "Haki zote zimehifadhiwa",
    toDeleteThisTransaction: "kufuta Muamala huu",
    transactionDeletedSuccessfully:
      "Muamala umefutwa kwa mafanikio. Hisa imerejeshwa kwa hesabu.",
    deleteTransaction: "Futa Shughuli",
    transactionDetails: "Maelezo ya Muamala",
    printReceipt: "Risiti ya Kuchapisha",
    channel: "Kituo",
    discount: "Punguzo",
    profit: "Faida",
    discountedSales: "Mauzo yenye Punguzo",
    errorFetchingRecord: "Hitilafu katika Kuchukua Rekodi",
    exportTransactions: "Shughuli za kuuza nje",
    errorFetchingSalesRecord:
      "Hitilafu wakati wa kuleta rekodi ya Mauzo ya Usafirishaji.",
    totalSellingPrice: "Bei ya Kuuza Jumla",
    totalCostPrice: "Bei ya Jumla ya Gharama",
    appliedDiscount: "Punguzo linalotumika",
    noOfItems: "Nambari ya Vitu",
    sales: "Mauzo",
    export: "Hamisha",
    totalProfit: "Faida ya Jumla",
    totalBalanceInPeriod: "Jumla ya Mizani katika Kipindi",
    allTimeSales: "Mauzo ya Wakati Wote",
    records: "Rekodi",
    todaysSales: "Mauzo ya Leo",
    transaction: "shughuli",
    youHaveExceededTotalNumberOfProducts:
      "Umezidi jumla ya bidhaa zinazoruhusiwa kwenye mpango wako. Boresha mpango wako ili ufurahie kikomo cha juu.",
    youNeedToHaveLoyaltyProgram:
      "Unahitaji kuwa na Programu ya Uaminifu ili utumie huduma hii!",
    price: "Bei",
    category: "Jamii",
    stockTracking: "Ufuatiliaji wa Hisa",
    stockCount: "Hesabu ya Hisa",
    taxed: "Ushuru",
    originalPrice: "Bei halisi",
    costPrice: "Bei ya gharama",
    unit: "Kitengo",
    productImage: "Picha ya Bidhaa",
    taxRate: "Kiwango cha Ushuru",
    taxType: "Aina ya Ushuru",
    trackInventory: "Kufuatilia Hesabu",
    variants: "Variants",
    hasVariants: "Ina anuwai",
    importProduct: "Ingiza Bidhaa",
    exportProducts: "Bidhaa za kuuza nje",
    addNewProduct: "Ongeza Bidhaa Mpya",
    viewCategory: "Angalia Jamii",
    viewSuppliers: "Angalia Wauzaji",
    receiveInventory: "Pokea Hesabu",
    printAllProductsTag: "Chapisha Bidhaa zote Tag",
    deleteAll: "Futa Zote",
    totalProducts: "BIDHAA ZA JUMLA",
    youveSuccessfullyAddedANewCategory: "Umefanikiwa kuongeza kategoria mpya",
    addNewCategory: "Ongeza Jamii Mpya",
    addCategory: "Ongeza Jamii",
    categoryName: "Jina la Jamii",
    pleaseEnterCategoryName: "Tafadhali ingiza jina la kategoria",
    stampsTarget: "Lengo la Stempu",
    sendInventory: "Tuma Hesabu",
    productDetails: "maelezo ya bidhaa",
    youveSuccessfullyEditedThisCategory: "Umefanikiwa kuhariri kitengo hiki",
    update: "Sasisha",
    categoryList: "Orodha ya Jamii",
    categories: "Jamii",
    enterQuantityToUpdate: "Ingiza wingi ili kusasisha",
    inventorySentSuccessfully: "Hesabu ilitumwa kwa mafanikio!",
    updateInventory: "Sasisha Hesabu",
    currentQuantity: "Kiasi cha sasa",
    pleaseEnterQuantityToAdd: "Tafadhali ingiza kiasi unachotaka kuongeza",
    pleaseSelectABranch: "Tafadhali chagua Tawi",
    searchForBranch: "Tafuta tawi",
    youCantSendMoreThanStock: "Hauwezi kutuma zaidi ya ulivyo na hisa",
    send: "Tuma",
    pleaseEnterQuantityToSend: "Tafadhali ingiza kiasi unachotaka kutuma",
    productNameIsRequiredOnRow: "jina la bidhaa linahitajika kwenye safu",
    productCategoryIsRequiredOnRow: "jamii ya bidhaa inahitajika kwenye safu",
    productPriceIsRequiredOnRow: "bei ya bidhaa inahitajika kwenye safu",
    productUnitIsRequiredOnRow: "kitengo cha bidhaa kinahitajika kwenye safu",
    productQuantityIsRequiredOnRow: "wingi wa bidhaa unahitajika kwenye safu",
    productVariantsRequireTracking: "anuwai ya bidhaa inahitaji ufuatiliaji!",
    pleaseAddVariantClickButton:
      "Tafadhali ongeza lahaja kwa kubofya kitufe cha kuongeza!",
    totalVariantsMoreThanSelectedQuantity:
      "Jumla ya anuwai ni zaidi ya bidhaa iliyochaguliwa, Pls hupunguza anuwai",
    productEditedSuccessfully: "Bidhaa imebadilishwa kwa mafanikio!",
    fileTooLargeLessThan4Mb:
      "Ukubwa wa faili ni kubwa mno! Ukubwa wa faili inapaswa kuwa chini ya 4MB.",
    suchVariantAlreadyExist: "Tofauti kama hiyo tayari ipo",
    addVariants: "Ongeza anuwai",
    editProduct: "Hariri Bidhaa",
    pleaseEnterProductName: "Tafadhali Ingiza Jina la Bidhaa",
    pleaseEnterProductPrice: "Tafadhali Ingiza Bei ya Bidhaa",
    pleaseEnterProductOriginalPrice: "Tafadhali Ingiza Bei Asili ya Bidhaa",
    productDescription: "Maelezo ya bidhaa",
    selectProductCategory: "Chagua Jamii ya Bidhaa",
    pleaseSelectProductCategory: "Tafadhali chagua kategoria ya bidhaa",
    productCostPrice: "Bei ya Gharama ya Bidhaa",
    productSellingPrice: "Bei ya Kuuza Bidhaa",
    productOriginalPrice: "Bei Asili ya Bidhaa",
    maxFileSizeAllowedIs4Mb: "Upeo. Ukubwa wa faili unaoruhusiwa ni 4mb",
    productsWithPicturesArePublished:
      "Bidhaa zilizo na picha zimechapishwa kwenye Gundua ili kupokea maagizo",
    shouldThisProductBeTracked: "Je! Bidhaa hii inapaswa kufuatiliwa?",
    pleaseSelectStockUnit: "Tafadhali chagua kitengo cha hisa",
    stockUnit: "Kitengo cha Hisa",
    bag: "MFUKO",
    bottle: "CHUPA",
    bunch: "BUNCHI",
    can: "WAWEZA",
    carton: "KABONI",
    crate: "CRATE",
    cup: "KOMBE",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "UFUNGASHAJI",
    pair: "BONYEZA",
    pieces: "Vipande",
    plate: "Bamba",
    tonne: "TONNE (MT)",
    uNIT: "KITENGO",
    yard: "UWANJA",
    pleaseEnterProductQuantity: "Tafadhali ingiza wingi wa bidhaa",
    productQuantity: "Wingi wa Bidhaa",
    isThisProductTaxed: "Je! Bidhaa hii imetozwa ushuru?",
    selectTaxType: "Chagua Aina ya Ushuru",
    pleaseSelectTaxType: "Tafadhali chagua Aina ya Ushuru",
    progressive: "Kuendelea",
    proportional: "Sawia",
    pleaseEnterProductTaxRate: "Tafadhali ingiza kiwango cha ushuru wa bidhaa",
    doesProductHaveVariants: "Je! Bidhaa hii ina lahaja?",
    type: "Andika",
    value: "Thamani",
    pleaseEnterVariantType: "Tafadhali ingiza aina ya lahaja",
    pleaseEnterVariantValue: "Tafadhali ingiza thamani ya lahaja",
    pleaseEnterVariantPrice: "Tafadhali weka bei ya lahaja",
    pleaseEnterVariantQuantity: "Tafadhali ingiza idadi ya lahaja",
    productDeletedSuccessfully: "Bidhaa imefutwa kwa mafanikio!",
    categoryDeletedSuccessfully: "Jamii imefutwa kwa mafanikio!",
    toDeleteThisProduct: "kufuta Bidhaa hii",
    invalidProductQuantity: "Wingi batili wa Bidhaa",
    quantityAddedIsOverStock:
      "Kiasi unachoongeza kimezidi kile ulichonacho katika hisa.",
    itemInventoryNotTracked: "Hesabu ya kipengee haifuatwi",
    addBulkQuantity: "Ongeza Wingi wa Wingi",
    enterBulkQuantity: "Ingiza Wingi wa Wingi",
    pleaseEnterBulkQuantity: "Tafadhali ingiza Wingi wa Wingi",
    youveSuccessfullyAddedANewProduct: "Umefanikiwa kuongeza bidhaa mpya!",
    pleaseEnterProductSellingPrice: "Tafadhali ingiza bei ya kuuza bidhaa",
    doYouWantToTrackProductStock: "Je! Unataka kufuatilia hisa ya bidhaa?",
    sellingPrice: "Bei ya kuuzia",
    setProductExpiryReminder: "Weka Kikumbusho cha Kuisha Bidhaa",
    productExpiryDate: "Tarehe ya kumalizika kwa Bidhaa",
    startRemindingFrom: "Anza Kukumbusha Kutoka",
    productSuppliesAddedSuccessfully:
      "Umeongeza vifaa vya bidhaa kwa mafanikio.",
    addProductSupplies: "Ongeza Vifaa vya Bidhaa",
    pleaseSelectSupplier: "Tafadhali Chagua Muuzaji",
    nameOfProduct: "Jina la Bidhaa",
    pleaseSelectProduct: "Tafadhali Chagua Bidhaa",
    productVariant: "Tofauti ya Bidhaa",
    pleaseSelectAVariant: "Tafadhali Chagua Tofauti",
    pleaseEnterUnitPrice: "Tafadhali Ingiza Bei ya Kitengo",
    businessBranch: "Tawi la Biashara",
    pleaseSelectBranch: "Tafadhali chagua tawi",
    youveSuccessfullyAddedANewSupplier: "Umefanikiwa kuongeza muuzaji mpya",
    addSupplier: "Ongeza Wasambazaji",
    pleaseEnterSupplierEmail: "Tafadhali ingiza barua pepe ya muuzaji",
    pleaseAddADescription: "Tafadhali ongeza maelezo",
    anErrorOccuredProductsDeleted:
      "Hitilafu ilitokea wakati wa kufanya operesheni. Tafadhali kumbuka, bidhaa chache zinaweza kuwa zimefutwa katika mchakato",
    bulkDelete: "Futa Wingi",
    youAreAboutToDelete: "Uko karibu kufuta",
    product: "Bidhaa",
    isDueToRedeem: "ni kutokana na kukomboa",
    aReward: "malipo",
    pleaseSelectCustomerToReeemReward:
      "Tafadhali chagua mteja kukomboa zawadi.",
    youHaveNoLoyaltyProgramRunning: "Huna programu inayotumika ya uaminifu",
    customerHhasNoPointsInLoyaltyProgram:
      "Mteja hana alama katika mpango huu wa uaminifu",
    proceedWithPayment: "Endelea na Malipo?",
    youAreAboutToPayForTransactionUsingPoints:
      "Uko karibu kulipia shughuli kwa kutumia alama.",
    customerHas: "Mteja Ana",
    worth: "thamani",
    andIsNotSufficientToPayForTransaction:
      "na haitoshi kulipia shughuli hii. Je! Watataka kuongeza salio kwa kutumia njia nyingine ya malipo?",
    addCustomerLoyalty: "Ongeza Uaminifu kwa Wateja",
    pleaseAddCustomerFirst: "Tafadhali ongeza au uchague mteja kwanza.",
    pleaseWaitWhileWeGetReady: "Tafadhali subiri, wakati tunajiandaa",
    lowStock: "Hisa ya Chini",
    pleaseEnterAmountTendered: "Tafadhali ingiza kiasi kilichopewa",
    areYouSureToBookSaleOffline:
      "Je! Una uhakika unataka kuhifadhi uuzaji huu nje ya mtandao?",
    saleWouldBeBookedAutomatically:
      "Uuzaji ungehifadhiwa moja kwa moja wakati utakapowasha mtandao wako",
    offlineSalesBookingCancelled:
      "Uhifadhi wa mauzo ya nje ya mtandao umeghairiwa",
    covid19Message:
      "COVID19: Osha mikono yako na sabuni au Sanitize ili kuzuia kuenea. Kaa Salama Daima",
    saleSuccessfullyRecorded: "Uuzaji umefanikiwa Kurekodiwa!",
    sendReceiptToEmail: "Tuma risiti kwa Barua pepe",
    sendThankYouSms: "Tuma SMS ya asante",
    sendShippingDetails: "Tuma maelezo ya Usafirishaji",
    addLoyalty: "Ongeza Uaminifu",
    searchCustomerNameOrNumber: "Tafuta jina la mteja au nambari",
    clickTheSearchCustomerBox:
      "Bonyeza Sanduku la Wateja wa Tafuta na Kadi ya Kutambaza",
    enterProductPrice: "Ingiza Bei ya Bidhaa",
    enterPriceFor: "Ingiza Bei kwa",
    searchForProduct: "Tafuta bidhaa",
    all: "Wote",
    backToDashboard: "Rudi kwenye Dashibodi",
    viewDraftSales: "Angalia Rasimu ya Mauzo",
    variantSelected: "lahaja iliyochaguliwa",
    variant: "lahaja",
    thePreviousVariantSelectionNotAvailable:
      "Uchaguzi wa lahaja ya awali haupatikani kwa lahaja mpya iliyochaguliwa kulingana na bei, tafadhali badilisha uteuzi wako.",
    pleaseSupplyPositiveQuantityNumber: "Tafadhali toa idadi ya idadi ya posti",
    lowStockFor: "Hifadhi ya chini kwa",
    clearVariants: "Futa anuwai",
    pleaseEnterQuantity: "Tafadhali ingiza Wingi",
    picture: "Picha",
    redemptionToken: "Ishara ya Ukombozi",
    token: "Ishara",
    totalSpent: "Jumla Iliyotumiwa",
    confirmPayment: "Thibitisha Malipo",
    hasPaymentBeenMade: "Je! Malipo yamechakatwa vyema?",
    enterTransactionReference: "Ingiza kumbukumbu ya manunuzi uliyolipa nayo",
    pleaseSelectACustomer: "Tafadhali chagua mteja!",
    areYouSureToApplyDiscount: "Je! Una uhakika unataka kutumia punguzo?",
    addYourBankAccountToEnableUssd:
      "Ongeza akaunti yako ya benki ili kuwezesha uhamishaji wa Papo hapo wa USSD na uPay",
    totalAmountToPay: "Kiasi cha Kulipa",
    doYouWantToCancelTransaction: "Je! Unataka kughairi shughuli hii?",
    savePrintBill: "Hifadhi / Chapisha Muswada",
    enterAmountCollectedForCustomer: "Ingiza kiasi kilichokusanywa kwa mteja",
    recordSale: "Uuzaji wa Rekodi",
    checkOutWith: "Angalia na",
    instantTransfer: "Uhamisho wa Papo hapo",
    dialTheUSSDCode: "Piga nambari ya USSD",
    pleaseSelectABank: "Tafadhali chagua benki",
    payWithUSSD: "Lipa na USSD",
    sendBillTo: " - Tuma Muswada kwa",
    waitingForUSSDTransfer: "Inasubiri Uhamisho wa USSD",
    percent: "Asilimia",
    applyDiscount: "Tumia Punguzo",
    thisBillHasBeenSaved: "Mswada huu umeokolewa",
    saveDraft: "Hifadhi Rasimu",
    pleaseTypeANameToIdentifyCustomer:
      "Tafadhali andika jina ili utambue mteja",
    paymentDetails: "Maelezo ya Malipo",
    basePrice: "Bei ya Msingi",
    staff: "Wafanyakazi",
    subTotal: "Jumla ndogo",
    durationMonths: "Muda (miezi)",
    selectADuration: "Chagua Muda",
    oneMonth: "Mwezi 1",
    twoMonths: "Miezi 2",
    threeMonths: "Miezi 3",
    sixMonths: "miezi 6",
    twelveMonths: "Miezi 12",
    eighteenMonths: "Miezi 18",
    twentyFourMonths: "Miezi 24",
    twoMonthsFree: "(Miezi 2 Bure)",
    threeMonthsFree: "(Miezi 3 Bure)",
    fiveMonthsFree: "(Miezi 5 Bure)",
    yourAccountHasBeen: "Akaunti yako imekuwa",
    renewed: "upya",
    upgraded: "imesasishwa",
    successfully: "mafanikio",
    yourSubscription: "Usajili wako",
    youAreCurrentlyEnrolledOnA: "Umeandikishwa kwa sasa",
    pleaseChooseAPaymentOption: "Tafadhali Chagua Chaguo la Malipo",
    planRenewal: "Mpango Upya",
    planUpgrade: "Panga Kuboresha",
    pleaseSelectDurationToPayFor: "Tafadhali Chagua Muda unaokusudia kulipia",
    staffAccounts: "Akaunti za Wafanyakazi",
    ecommerce: "Biashara ya kibiashara",
    pleaseSelectAPlan: "Tafadhali chagua mpango",
    includeAddons: "Jumuisha Viongezeo",
    viewTransactions: "Angalia Miamala",
    customerHasNoCompletedTansactions: "Wateja bado hawajakamilisha tansaction",
    branch: "Tawi",
    enterNumberOfEcommerceBranches: "Ingiza Idadi ya Matawi ya Biashara",
    enterNumberOfEcommerceBranchesToPay:
      "Ingiza Idadi ya Matawi ya Ecommerce Unayokusudia Kulipa",
    ecommerceIntegration: "Ushirikiano wa Biashara",
    enterNumberOfBranches: "Ingiza Idadi ya Matawi",
    enterNumberOfAdditionalBranchesToPay:
      "Ingiza Idadi ya Matawi ya Ziada Unayokusudia Kulipia",
    enterNumberOfStaffs: "Ingiza Idadi ya Wafanyikazi",
    enterNumberOfStaffsToPayFor:
      "Ingiza Idadi ya Wafanyikazi Unaokusudia Kulipa",
    discountApplies: "Punguzo Inatumika",
    starsOnThe: "nyota kwenye",
    offer: "kutoa",
    get: "Pata",
    moreStarsToRedeem: "nyota zaidi za kukomboa",
    taxVat: "Kodi (VAT)",
    callCashierToCompletePayment: "Piga Cashier kukamilisha Malipo",
    receipt: "Stakabadhi",
    dear: "Mpendwa",
    thankYouForYourOrderFindGoods:
      "Asante kwa agizo lako. Tafadhali pata bidhaa zifuatazo zilizotolewa, kama ilivyokubaliwa.",
    shippingNote: "Ujumbe wa Usafirishaji",
    enterShippingNote: "Ingiza Ujumbe wa Usafirishaji",
    shippingAddress: "Anwani ya kusafirishia",
    enterShippingAddress: "Ingiza anwani ya Usafirishaji",
    wellDoneYouAreDueToRedeem: "Umefanya vizuri! Unastahili kukomboa",
    toGetYourRewardNextVisit:
      "kupata tuzo yako katika ziara yako ijayo. Asante",
    pointsOnThe: "Pointi kwenye",
    morePointsToRedeem: "alama zaidi za kukomboa",
    showCode: "Onyesha nambari",
    toGetYourRewardOnNextVisit:
      "kupata tuzo yako katika ziara yako ijayo. Asante",
    earn: "Pata",
    delivaryNote: "Maelezo ya Delivary",
    draftSales: "Rasimu ya Mauzo",
    startDate: "Tarehe ya kuanza",
    endDate: "Tarehe ya mwisho",
    orders: "Maagizo",
    checkout: "Angalia",
    noProductItem: "Hakuna Bidhaa",
    selectProductImage: "Chagua Picha ya Bidhaa",
    selectCountry: "Chagua nchi",
    selectRegion: "Chagua Jimbo / Mkoa",
    printProductTag: "Chapisha Kitambulisho cha Bidhaa",
    transactionReference: "Rejea ya manunuzi",
    Cashier: "Mfadhili",
    Manager: "Meneja",
    Owner: "Mmiliki",
    Admin: "Usimamizi",
    addPartners: "Ongeza Washirika",
    addNewLoyaltyPartner: "Ongeza Mshirika Mpya wa Uaminifu",
    pleaseEnterCompanyName: "Tafadhali weka Jina la Kampuni",
    companyName: "jina la kampuni",
    pleaseEnterCompanyRepName: "Tafadhali weka Jina la mwakilishi wa kampuni",
    companyRepName: "Jina la Mwakilishi wa Kampuni",
    pleaseEnterCompanyRepEmail:
      "Tafadhali weka barua pepe ya mwakilishi wa kampuni",
    companyRepEmail: "Barua pepe ya mwakilishi wa kampuni",
    pleaseEnterCompanyRepPhone:
      "Tafadhali weka nambari ya simu ya mwakilishi wa kampuni",
    companyRepPhone: "Nambari ya simu ya mwakilishi wa kampuni",
    addReward: "Ongeza zawadi",
    pleaseEnterRewardName: "Tafadhali weka jina la zawadi",
    rewardName: "Jina la Tuzo",
    rewardQuantity: "Kiasi cha Tuzo",
    rewardDescription: "Maelezo ya Zawadi",
    rewardType: "Aina ya Tuzo",
    pleaseEnterRewardType: "Tafadhali weka aina ya zawadi",
    pleaseEnterRewardQuantity: "Tafadhali weka kiasi cha zawadi",
    pleaseEnterRewardDescription: "Tafadhali weka maelezo ya zawadi",
    fineDining: "Dining Bora",
    luxuryFashion: "Mitindo ya kifahari",
    hotels: "Hoteli",
    travel: "Safari",
    foodAndBeverage: "Chakula na Vinywaji",
    fashion: "Mitindo",
    health: "Afya",
    furniture: "Samani",
    entertainment: "Burudani",
    automobile: "Gari",
    education: "Elimu",
    beautyAndSpa: "Uzuri na Biashara",
    staycation: "Makazi",
    events: "Matukio",
    trips: "Safari",
    oilAndGas: "Mafuta na Gesi",
    laundry: "Kufulia",
    partnerCategory: "Kitengo cha Washirika",
    freeItem: "Kipengee cha Bure",
  },
  Swedish: {
    cashier: "kassör",
    manager: "chef",
    owner: "ägare",
    online: "uppkopplad",
    offline: "off-line",
    changePassword: "Ändra lösenord",
    currentPasswordMessage: "Ange ditt nuvarande lösenord",
    passwordMessage: "Vänligen ange ditt lösenord",
    currentPassword: "Nuvarande lösenord",
    password: "Lösenord",
    confirmPasswordMessage: "Bekräfta ditt lösenord!",
    confirmPassword: "Bekräfta lösenord",
    sendViaEmail: "Skicka via e -post",
    sendViaWhatsapp: "Skicka via WhatsApp",
    downloadPdf: "Ladda ner PDF",
    paid: "betalat",
    unpaid: "obetald",
    partial: "partiell",
    closeInvoice: "Vill du stänga fakturan?",
    closeInvoiceConfirmation: "Faktura kanske inte sparas. Vill du stänga?",
    yes: "ja",
    no: "Nej",
    invoice: "Faktura",
    wasDeducted: "drogs av",
    for: "för",
    item: "Artikel",
    addProduct: "Lägg till produkt",
    paymentReference: "Betalningsreferens",
    amountPaid: "Betalt belopp",
    discountAmount: "Rabattbelopp",
    amountDue: "Belopp som ska betalas",
    amount: "Belopp",
    dueDate: "Förfallodatum",
    paymentType: "Betalnings typ",
    card: "Kort",
    cash: "Kontanter",
    bankTransfer: "Banköverföring",
    paymentMessage: "Betalningsmeddelande",
    description: "Beskrivning",
    sendReceipt: "Skicka kvitto",
    delete: "Radera",
    save: "Spara",
    resend: "Skicka igen",
    saveAndSend: "Spara",
    invoiceSaved: "Faktura sparad!",
    selectPaymentMethod: "Välj en betalningsmetod!",
    selectCustomer: "Välj en kund!",
    cartEmptyError:
      "Varukorgslistan kan inte vara tom, stäng fakturan och lägg till varan i kundvagnen!",
    subscriptionExpired:
      "Din prenumeration har gått ut och ditt konto är nu begränsat. Klicka på knappen nedan för att förnya ditt konto",
    renew: "Förnya",
    holdOn: "Vänta",
    customerBank: "Kundbank",
    cancel: "Avbryt",
    selectACustomer: "Välj kund",
    invoiceSuccessfulPdfError:
      "Faktura skapades framgångsrikt men PDF -generering tar längre tid än vanligt. Kom tillbaka snart.",
    downloadingInvoice: "Ladda ner faktura",
    downloadingReceipt: "Ladda ner kvitto",
    whatsappReceiptError:
      "Ett fel uppstod när kvittot skulle skickas via WhatsApp. Försök igen.",
    receiptToWhatsapp: "Kvitto vidarebefordras till WhatsApp",
    thankYouForPatronage: "Tack för ditt stöd",
    hereIsYourReceipt: "Här är ditt betalningskvitto",
    errorSendingEmailReceipt:
      "Ett fel uppstod när kvittot skulle skickas via e-post. Försök igen eller kontakta support",
    receiptSentToEmail: "Kvittot har skickats till kundens mejl",
    invoiceSentToEmail: "Faktura har skickats till kundens mejl",
    invoiceSuccessWhatsappError:
      "Faktura skapades framgångsrikt men ett fel uppstod vid skicka till WhatsApp. Försök igen på fakturalistan",
    invoiceSuccessfulEmailError:
      "Faktura skapades framgångsrikt men ett fel uppstod när den skickades som e-post. Försök igen från fakturalistan",
    invoiceSentToWhatsapp: "Faktura vidarebefordras till WhatsApp",
    hello: "Hej",
    pleaseDownloadInvoice: "Ladda ner faktura",
    pleaseDownloadReceipt: "Ladda ner kvittot",
    from: "från",
    email: "E-post",
    upgrade: "Uppgradera",
    youAreOnFreePlan: "Du har en gratis plan.",
    clickOn: "Klicka på",
    yourPlanInFewSteps: " din prenumeration i några snabba steg.",
    to: "till",
    yourSubscriptionHasExpired:
      "Din prenumeration har gått ut och ditt konto är nu begränsat.",
    days: "dagar",
    yourSubscriptionExpiresIn: "Din Loystar -prenumeration går ut om",
    createAcount: "Skapa ett konto",
    signIn: "Logga in",
    continue: "Fortsätta",
    enterOtp: "Ange OTP -PIN",
    enterValidOtp: "Ange en giltig PIN -kod",
    pin: "STIFT",
    tokenSentToMail: "En token har skickats till din e -post",
    passwordResetSuccessful: "Återställning av lösenord lyckades",
    somethingWentWrong: "Något gick fel!",
    resetPassword: "Återställ lösenord",
    iHaveResetCode: "Jag har en lösenordskod",
    pleaseEnterEmail: "Ange din e -postadress",
    aTokenWillBeSentToEmail: "En token skickas till din e -post",
    enterEmail: "Skriv in din e-postadress",
    sendinYourToken: "Skickar din token ...",
    makeSureItMatchesPassword: "Se till att det matchar ditt nya lösenord",
    pleaseChooseNewPassword: "Välj ett nytt lösenord",
    chooseNewPassword: "Välj ett nytt lösenord",
    enterNewPassword: "Ange ditt nya lösenord för att bekräfta",
    enterTokenSent: "Ange den token som skickades ditt mail",
    resetToken: "Återställ token",
    resettingPassword: "Återställer ditt lösenord ...",
    signUp: "Bli Medlem",
    adminSignInWithEmail:
      " Administratören loggar in med e -post medan personalen loggar in med användarnamn.",
    pleaseEnterEmailOrUsername: "Ange din e -postadress eller användarnamn",
    emailOrUsername: "Email eller användarnamn",
    pleaseEnterPassword: "Var snäll och fyll i lösenordet",
    createAnAccount: "Skapa ett konto",
    forgotPassword: "Glömt ditt lösenord?",
    enterPhoneNumber: "Skriv in telefonnummer",
    personalData: "Personlig information",
    validateConfirmationCOde: "Verifiera bekräftelsekoden",
    pleaseEnterFirstName: "Ange ditt förnamn",
    pleaseEnterPhoneNumber: "Vänligen skriv in ditt telefonnummer",
    pleaseEnterLastName: "Ange ditt efternamn",
    pleaseEnterBusinessName: "Ange ditt företagsnamn",
    firstName: "Förnamn",
    lastName: "Efternamn",
    businessName: "Företagsnamn",
    previous: "Tidigare",
    next: "Nästa",
    pleaseSelectBusinessCategory: "Välj din företagskategori",
    pleaseEnterValidEmail: "Ange ett giltigt mejl",
    pleaseEnterPostCode: "Ange postnummer",
    postCode: "Postnummer",
    phoneNumberInUse: "Detta telefonnummer används redan!",
    emailInUse: "Det här e -postmeddelandet används redan!",
    foodAndDrinks: "Mat och dryck",
    salonAndBeauty: "Salong och skönhet",
    fashionAndAccessories: "Mode och accessoarer",
    gymAndFitness: "Gym och fitness",
    travelAndHotel: "Resor och hotell",
    homeAndGifts: "Hem och gåvor",
    washingAndCleaning: "Tvätt och städning",
    gadgetsAndElectronics: "Prylar och elektronik",
    groceries: "Specerier",
    others: "Andra",
    submit: "Skicka in",
    accountCreatedSuccessful: "Ditt konto skapades.",
    pleaseEnterAddress: "Ange din adress",
    addressLine1: "Adressrad 1",
    addressLine2: "Adress Linje 2",
    choosePassword: "Välj lösenord",
    passwordMustBe6Characters: "Lösenordet måste innehålla minst 6 tecken.",
    howDidYouHearLoystar: "Hur hörde du om Loystar?",
    referralCode: "hänvisningskod",
    byClickingTheButton: " Genom att klicka på knappen nedan godkänner du",
    termsAndSevice: "Villkor",
    wereCreatingAccount: "Vi skapar ditt konto",
    proceed: "Fortsätt",
    verificationCodeMustBe6: "Verifieringskoden måste vara 6 siffror",
    pleaseEnter6DigitCode: "Ange en sexsiffrig kod",
    enterVerificationCode: "Ange verifieringskod",
    resendToken: "Skicka igen token",
    verify: "Kontrollera",
    transactions: "Transaktioner",
    todaySales: "Dagens försäljning",
    salesHistory: "Försäljningshistorik",
    supplyHistory: "Supply History",
    new: "Ny",
    invoices: "Fakturor",
    disbursements: "Utbetalningar",
    offlineSales: "Offlineförsäljning",
    products: "Produkter",
    customers: "Kunder",
    multiLevelLoyalty: "Lojalitet på flera nivåer",
    loyaltyPrograms: "Lojalitetsprogram",
    members: "Medlemmar",
    appStore: "App Store",
    orderMenu: "Beställningsmeny",
    settings: "inställningar",
    staffAndBranches: "Staber och grenar",
    myAccount: "Mitt konto",
    switchToSellMode: "Byt till Säljläge",
    signOut: "Logga ut",
    getFreeSubscription: "Få gratis prenumeration",
    onlyNumbersAllowed: "Endast siffror är tillåtna",
    yourAccountMustBe10Digits:
      "ditt kontonummer måste vara ett 10 -siffrigt nummer",
    yourBvnMustBe11: "ditt BVN måste vara ett 11 -siffrigt nummer",
    pleaseSelectBank: "Välj din bank",
    selectYourBank: "Välj din bank",
    enterBankAccountNumber: "Ange bankkontonummer",
    enterBvnNumber: "Ange ditt BVN",
    connectBankAccount: "Anslut bankkonto",
    passwordResetSuccessfulAndSignOut:
      "Ditt lösenord har återställts. Klicka på knappen Logga ut nedan för att logga in igen",
    enterCurrentPassword: "Ange aktuellt lösenord",
    pleaseEnterCurrentPassword: "Ange aktuellt lösenord",
    phoneNumber: "Telefonnummer",
    sex: "Sex",
    dateOfBirth: "Födelsedatum",
    state: "stat",
    country: "Land",
    loyaltyId: "Lojalitets -ID",
    createdAt: "Skapad vid",
    noOfTransactionsMade: "Antal genomförda transaktioner",
    yourDownloadWillStart: "Din nedladdning startar om ett ögonblick",
    exportCustomers: "Exportera kunder",
    youhaveSuccessfullyToppedUp: "Du har fyllt på dina SMS -enheter.",
    youNowHave: "Du har nu",
    smsUnits: "SMS -enheter",
    enterNumberOfSmsUnits: "Ange antal SMS -enheter du tänker köpa",
    pleaseEnterANumericValue: "Ange ett numeriskt värde",
    pay: "Betala",
    accountEditedSuccessfully: "Ditt konto har redigerats.",
    youAeCurrentlyOn: "Du är för närvarande på",
    plan: "Planen",
    userData: "Användardata",
    businessData: "AFFÄRSDATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Välj din valuta",
    selectYourCurrency: "Välj din valuta",
    purchaseMoreSmsUnits: "Köp fler SMS -enheter med hjälp av formuläret nedan",
    youHave: "Du har",
    atLeast4SmsUnitsRrequired:
      "Minst 4 smsenheter krävs för verifiering, fyll på!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Verifiera ditt bankkonto för att uppfylla kraven "Känn din kund" (KYC). Detta gör att du kan samla in betalningar via USSD eller omedelbar överföring, ta emot beställningar från kunder och behandla transaktionsavveckling. Gäller endast nigerianska köpmän. Tryck på knappen nedan för att börja.',
    reConnectBankAccount: "Återanslut bankkonto",
    accountName: "Kontonamn",
    accountNumber: "Kontonummer",
    bankName: "Bank namn",
    verified: "Verifierad",
    accountDetails: "Kontouppgifter",
    kycBankAccount: "KYC",
    createTier: "Skapa nivå",
    fileUploadSuccessful: "filen laddades upp",
    fileUploadFailed: "filöverföring misslyckades",
    createLoyaltyProgram: "Skapa ett lojalitetsprogram",
    createLoyalty: "Skapa lojalitet",
    name: "namn",
    loyaltyArtwork: "Lojalitet konstverk",
    clickToUpload: "Klicka för att ladda upp",
    amountToPointsRatio: "Belopp till poängförhållande",
    points: "Poäng",
    recommendedAmountToPointRatio:
      "Rekommenderas: to 1 till 1 poäng. dvs för varje 1 Naira som spenderas får dina kunder 1 poäng",
    pleaseTypeIn: "Skriv in",
    toDeleteLoyalty: "att ta bort denna lojalitet",
    deleteLoyalty: "Ta bort lojalitet",
    toConfirm: "att bekräfta",
    edit: "Redigera",
    pointsAwardedSuccessfully: "Poäng tilldelas framgångsrikt.",
    enterPointValueToAward: "Ange poängvärde som du vill tilldela kunder",
    award: "Tilldela",
    awardPointValuesToCustomer: "Prisvärde till kund",
    enrollMembersToLoyalty: "Registrera medlemmar till lojalitet",
    awardPoints: "Tilldelningspoäng",
    enroll: "Skriva in",
    home: "Hem",
    loyalty: "Lojalitet",
    enrollCustomers: "Registrera kunder",
    selected: "Vald",
    customer: "Kund",
    loyaltyActivationSuccessful: "Lojalitetsaktivering är framgångsrik.",
    loyaltyDeactivationSuccessful: "Lojalitetsavaktivering är framgångsrik.",
    viewTier: "Visa nivå",
    deactivate: "Avaktivera",
    activate: "Aktivera",
    actions: "Handlingar",
    nameOfLoyalty: "Lojalitetens namn",
    loyaltyStatus: "Lojalitetsstatus Lojalitetsstatus",
    numberOfTiers: "Antal nivåer",
    createdOn: "Skapad på",
    createATier: "Skapa en nivå",
    stopCreatingTierConfirmation: "Vill du sluta skapa en nivå?",
    stopEditingTierConfirmation: "Vill du sluta redigera den här nivån?",
    nameOfTier: "Namn på Tier",
    minimumSpendingTarget: "Minsta utgiftsmål",
    maximumSpendingTarget: "Maximalt utgiftsmål",
    minimumSpendingTargetRequired: "minimianvändningsmål krävs",
    maximumSpendingTargetRequired: "högsta utgiftsmål krävs",
    rewardSponsor: "Belöningssponsor",
    pleaseChooseARewardSponsor: "Välj en belöningssponsor",
    self: "Själv",
    partner: "Partner",
    rewardPartner: "Belöningspartner",
    pleaseSelectRewardPartner: "Välj din belöningspartner",
    rewards: "Belöningar",
    pleaseSelectAReward: "Välj en belöning",
    instructionsOnRedeemingReward:
      "Instruktioner om hur kunden ska lösa in belöning",
    pleaseFillInThisField: "Fyll i detta fält!",
    toDeleteThisTier: " för att radera denna nivå",
    deleteTier: "Ta bort nivå",
    viewMembers: "Visa medlemmar",
    membersEnrolled: "Medlemmar anmälda",
    instruction: "Instruktion",
    membersIn: "Medlemmar i",
    availableTiersInLoyalty: "Tillgängliga nivåer i lojalitetsprogrammet",
    tiers: "Tiers",
    totalTier: "TOTALT TIER",
    availableLoyaltyProgramme: "Tillgängligt lojalitetsprogram",
    totalLoyalties: "TOTALA LOJALITETER",
    memberDetails: "Medlemsuppgifter",
    nameOfCustomer: "Kundens namn",
    address: "Adress",
    allEnrolledMembers: "Alla anmälda medlemmar",
    thisIsToWishYouHappyBirthday:
      "Detta för att önska dig en mycket lycklig födelsedag och välmående liv. Tack för allt du är för Loyster. Glada fester!",
    inputAnOfferPlease: "Ange ett erbjudande tack",
    pleaseSelectTheInsertPoint:
      "Välj infogningspunkten i meddelandet och klicka igen",
    birthdayOfferAndMessage: "Födelsedagserbjudande och meddelande",
    birthdayOffer: "Födelsedagserbjudande",
    birthdayMessage: "Födelsedagsmeddelande",
    noOfferFound: "Inget erbjudande hittades",
    settingABirthdayOffer:
      "Genom att ange ett födelsedagserbjudande kan kunderna få detta erbjudande via SMS på sin födelsedag",
    createOffer: "Skapa erbjudande",
    whatIsTheOffer: "Vad är erbjudandet?",
    editMessage: "Redigera meddelande",
    insert: "Föra in",
    theNameOfCustomerInserted: "Kundens namn infogas här",
    theBirtdayOfferInserted: "Födelsedagserbjudandet infogas här",
    youSuccessfullyAddedNewBranch: "Du har lagt till en ny filial!",
    addNewBranch: "Lägg till ny gren",
    addBranch: "Lägg till gren",
    additionalBranchWillIncur: "Ytterligare filial skulle ådra sig",
    perBranch: "per gren",
    ecommerceBranchCosts: "Kostnader för e -handel",
    pleaseEnterBranchName: "Ange filialnamnet",
    pleaseEnterBranchAddress1: "Ange filialens adressrad 1",
    enterBranchAddress1: "Ange filialens adressrad 1",
    enterBranchAddress2: "Ange filialens adressrad 1",
    pleaseEnterBranchAddress2: "Ange filialens adressrad 2",
    enterBranchName: "Ange filialnamnet",
    successfullyAddedStaff: "Du har framgångsrikt lagt till en ny personal!",
    addNewStaff: "Lägg till ny personal",
    addStaff: "Lägg till personal",
    additionalStaffWillIncur: "Ytterligare personal skulle ådra sig",
    perStaff: "per personal.",
    pleaseEnterStaffEmail: "Ange personalens mejl",
    pleaseEnterStaffUsername: "Ange personalens användarnamn",
    pleaseEnterStaffPassword: "Ange personalens lösenord",
    pleaseSelectStaffRole: "Välj personalens roll",
    selectStaffRole: "Välj personalens roll",
    enterStaffEmail: "Ange personalens mejl",
    enterStaffUsername: "Ange personalens användarnamn",
    enterStaffPassword: "Ange personalens lösenord",
    spacesNotAllowedInUsername: "mellanslag inte tillåtna i användarnamn",
    admin: "Administration",
    pleaseSelectBusinessToAttachStaff: "Välj företag för att knyta an personal",
    searchForBranchToAttachStaff: "Sök efter filial för att fästa personal",
    username: "Användarnamn",
    role: "Roll",
    areYouSureToDeleteThis: "Är du säker på att ta bort detta",
    branches: "Grenar",
    upgradeYourPlan: "Uppgradera din plan.",
    add: "LÄGG TILL",
    addNew: "Lägg till ny",
    customerWithEmailAlreadyExists:
      "Kund med e -post/telefonnummer finns redan!",
    successfullyAddedNewCustomer: "Du har framgångsrikt lagt till en ny kund!",
    addCustomer: "Lägg till kund",
    pleaseEnterCustomerFirstName: "Ange kundens förnamn",
    pleaseEnterCustomerLastName: "Ange kundens efternamn",
    pleaseEnterCustomerPhoneNumber: "Ange kundens telefonnummer",
    pleaseEnterCustomerEmail: "Ange kundens e -postadress",
    pleaseEnterCustomerAddressLine: "Ange kundens adressrad",
    pleaseEnterCustomerOtherAddress: "Ange kundens andra adress",
    pleaseSelectCustomerGender: "Välj kundens kön",
    gender: "Kön",
    male: "Manlig",
    female: "Kvinna",
    bank: "Bank",
    selectBank: "Välj Bank",
    stateOrRegionOrProvince: "Stat/region/provins",
    customerNotes: "Kundanteckningar",
    sendSms: "Skicka sms",
    editCustomer: "Redigera kund",
    redeemReward: "Lös in belöning",
    issueLoyaltyCard: "Utfärda lojalitetskort",
    deleteCustomer: "Radera kund",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Du har framgångsrikt tilldelat ett ID för lojalitetsmedlemskap",
    noMembershipIdAvailable:
      "Inga medlems -ID finns tillgängliga. Vänligen kontakta oss på hello@loystar.co",
    sendEmail: "Skicka epost",
    membershipPoints: "Medlemskapspoäng",
    customerDetails: "Kunddetaljer",
    close: "Stänga",
    loyaltyBalance: "Lojalitetsbalans",
    pointsBalance: "Poäng Balans",
    starBalance: "Star Balance",
    requiredPoints: "Obligatoriska poäng",
    requiredStars: "Obligatoriska stjärnor",
    reddemCode: "Inlösningskod",
    toDeleteThisCustomer: "att radera denna kund",
    customerHasBeenDeletedSuccessfully: "Kunden har tagits bort!",
    customerWithPhoneAlreadyExists: "Kund med telefonnummer finns redan",
    customerWasSuccessfullyEdited: "Kunden redigerades",
    anErrorOccured: "Ett fel inträffade",
    phoneNumberIncludeCountry: "Telefonnummer (inkludera landskod)",
    yourFileQueuedForUpload:
      "Din fil har stått i kö för uppladdning. Uppdatera sidan efter några sekunder.",
    thereWasAnErrorPerformingOperation:
      "Det uppstod ett fel när åtgärden utfördes!",
    pleaseSelectFile: "Välj en fil!",
    oopsOnlyCsvFilesAllowed:
      "hoppsan! Endast CSV -filer tillåtna. Ladda upp en .csv -fil.",
    fileShouldBeSmallerThan5Mb:
      "Filen bör vara mindre än 5 MB. Om du har en större fil, skicka ett e -postmeddelande till support@loystar.co. Tack",
    customerFirstNameIsRequired: "kundens förnamn krävs på rad",
    customerPhoneNumberIsRequired: "kundens telefonnummer krävs på rad",
    importCustomers: "Importera kunder",
    upload: "Ladda upp",
    clickIconToDownloadCsv:
      "Klicka på den här ikonen för att ladda ner CSV -filmallen.",
    getGoogleSheetVersion: "Hämta Google -bladversionen",
    clickOrDragFileToUpload:
      "Klicka eller dra filen till det här området för att ladda upp",
    missingOrInvalidColumnHeader:
      "Kolumnrubrik saknas eller är ogiltigt. Följ mallformatet. Tack.",
    youHaveImported: "Du har importerat",
    youSuccessfullyRedeemedReward: "Du har lyckats lösa in din belöning!",
    sixDigitCode: "Sexsiffrig kod",
    pleaseEnterCustomerRewardCode: "Ange kundens belöningskod",
    enterRewardCode: "Ange belöningskod. (Belöningskoden är skiftlägeskänslig)",
    selectLoyaltyProgram: "Välj lojalitetsprogram",
    stamps: "Frimärken",
    smsUnitsLow: "SMS -enheter låga",
    pleaseTopUpSmsUnits: "Vänligen fyll på SMS -enheter",
    smsSuccessfullySentForDelivery: "SMS skickat för leverans!",
    sendSmsTo: "Skicka SMS till",
    allCustomers: "Alla kunder",
    unitsAvailable: "Tillgängliga enheter",
    pleaseTypeInTheMessage: "Skriv in meddelandet",
    message: "Meddelande",
    charactersRemaining: "Återstående karaktärer",
    avoidUseOfSpecialCharacters:
      "Undvik att använda specialtecken och emojis för att spara SMS -enheter.",
    note: "Notera",
    errorFetchingCustomersMultilevelDetail:
      "Fel vid hämtning av kunders flernivåinformation",
    search: "Sök",
    reset: "Återställa",
    importCustomer: "Importera kund",
    addNewCustomer: "Lägg till ny kund",
    sendSmsBroadcast: "Skicka SMS -sändning",
    totalCustomers: "TOTALA KUNDER",
    disbursementDetails: "Utbetalningsinformation",
    paidBy: "Betalad av",
    disbursed: "Utbetalas",
    bankAccountName: "Bankkonto namn",
    bankAccountNumber: "Bankkontonummer",
    transferInitiated: "Överföring initierad",
    transferCompleted: "Överföringen klar",
    pleaseEnterAValid: "Ange en giltig",
    begin: "Börja",
    end: "slutet",
    date: "datum",
    paymentDate: "Betalningsdatum",
    selectDisbursementDuration: "Välj Utbetalningstid",
    totalSettled: "Totalt avräknat",
    totalUnsettled: "Totalt obefintligt",
    toDeleteThisSale: "att radera denna försäljning",
    draftSaleDeletedSuccessfully: "Utkast till försäljning har tagits bort!",
    deleteSale: "Ta bort försäljning",
    pleaseTypeInYourTotal:
      "Ange din totala summa för att bekräfta borttagningen",
    billDetails: "Bill detaljer",
    printBill: "Skriv ut Bill",
    servedBy: "Serverad av",
    total: "Total",
    createdDate: "Skapat Datum",
    createdTime: "Skapad tid",
    status: "Status",
    loginSuccessful: "Inloggningen lyckades",
    pleaseWaitWhileWeConnectAccount: "Vänta medan vi ansluter ditt konto",
    connectionFailedTryAgain: "Anslutningen misslyckades. Var god försök igen.",
    connectionSuccessful: "Anslutningen lyckades",
    viewDetails: "Visa detaljer",
    enable: "Gör det möjligt",
    free: "Fri",
    cost: "Kosta",
    visitWebsite: "Besök hemsida",
    pleaseUpgradeYourPlanToPro:
      "Uppgradera din plan till Pro Plus för att aktivera den här appen",
    connectYourBankAccount:
      "Anslut ditt bankkonto för att kunna ta emot betalningar.",
    disable: "Inaktivera",
    enableApp: "Aktivera app",
    addNewProductToInvoice: "Lägg till ny produkt på faktura",
    toDeleteThisInvoice: "att radera denna faktura",
    invoiceDeletedSuccessfully: "Fakturan har tagits bort!",
    deleteInvoice: "Radera faktura",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Du har lagt till ett fakturameddelande!",
    pleaseEnterPaymentInstructions: "Ange betalningsinstruktioner",
    invoiceId: "Faktura -ID",
    paidDate: "Betalt datum",
    reference: "Referens",
    productAdded: "Produkt tillagd",
    productOutOfStock: "Produkt slut i lager. Vänligen lagerför igen.",
    totalInvoices: "TOTALA FAKTURER",
    totalPaidInvoices: "TOTALA BETALADE FAKTURER",
    totalUnpaidInvoices: "TOTALA OBETALADE FAKTURER",
    loyaltyProgramDeleted: "Lojalitetsprogram borttaget",
    thisLoyaltyProgramDeletedSuccessfully:
      "Detta lojalitetsprogram har tagits bort",
    thisLoyaltyProgramEditedSuccessfully:
      "Detta lojalitetsprogram har redigerats framgångsrikt",
    loyaltyProgramAddedSuccessfully: "Lojalitetsprogram har lagts till",
    loyaltyProgramEdited: "Lojalitetsprogram redigerat",
    loyaltyProgramAdded: "Lojalitetsprogram tillagt",
    loyaltyDetails: "Lojalitetsdetaljer",
    doYouWantToCloseDialog: "Vill du stänga den här dialogrutan?",
    pleaseEnterLoyaltyName: "Ange namnet på din lojalitet",
    programType: "Programtyp",
    pleaseSelectProgramType: "Välj en programtyp",
    simplePoints: "Enkla poäng",
    stampsProgram: "Frimärksprogram",
    threshold: "Tröskel",
    pleaseEnterLoyaltyThreshold: "Ange lojalitetsgränsen",
    reward: "Pris",
    pleaseEnterLoyaltyReward: "Ange lojalitetens belöning",
    totalUserPoints: "Totalt antal användarpoäng",
    totalUserStamps: "Totalt antal frimärken",
    spendingTarget: "Utgiftsmål",
    spendingTargetHint1:
      "Utgiftsmål är hur mycket en kund måste spendera för att få belöningen. 1 valutavärde = 1 poäng.",
    spendingTargetHint2:
      "Frimärksmål är hur många frimärken en kund måste samla in för att få belöningen. t.ex. 5",
    addNewLoyaltyProgram: "Lägg till nytt lojalitetsprogram",
    addLoyaltyProgram: "Lägg till lojalitetsprogram",
    loyaltyProgramType: "Lojalitetsprogramtyp",
    pleaseSelectLoyaltyProgramType: "Välj typ av lojalitetsprogram",
    nameOfProgram: "Programnamn",
    pleaseEnterProgramName: "Ange programnamn",
    whatIsTheReward: "Vad är belöningen?",
    egNextPurchaseIsFree: "T.ex. Nästa köp är gratis",
    customerName: "Köparens namn",
    guestCustomer: "Gästkund",
    orderConfirmedSuccessfully: "Beställningen har godkänts",
    orderCancelledSuccessfully: "Beställningen avbröts",
    confirmOrder: "Bekräfta order",
    cancelOrder: "Avbryt beställning",
    allOrders: "Alla beställningar",
    totalOrders: "TOTALA BESTÄLLNINGAR",
    doYouWantToAcceptOrder: "Vill du acceptera denna beställning?",
    doYouWantToCancelOrder: "Vill du avbryta denna beställning?",
    orderDetails: "Orderdetaljer",
    orderCreatedAt: "Beställning skapad kl",
    supplier: "Leverantör",
    productName: "produktnamn",
    quantity: "Kvantitet",
    unitPrice: "Enhetspris",
    receivedBy: "Mottagen av",
    reportFrom: "Rapport från",
    totalSupplies: "Totalt antal förnödenheter",
    allRightsReserved: "Alla rättigheter förbehållna",
    toDeleteThisTransaction: "för att radera denna transaktion",
    transactionDeletedSuccessfully:
      "Transaktionen har tagits bort. Lager har återförts till lager.",
    deleteTransaction: "Ta bort transaktion",
    transactionDetails: "Transaktions Detaljer",
    printReceipt: "Skriv ut kvitto",
    channel: "Kanal",
    discount: "Rabatt",
    profit: "Vinst",
    discountedSales: "Rabatterad försäljning",
    errorFetchingRecord: "Fel vid hämtning av post",
    exportTransactions: "Exporttransaktioner",
    errorFetchingSalesRecord:
      "Fel vid hämtning av försäljningspost för export.",
    totalSellingPrice: "Totalt försäljningspris",
    totalCostPrice: "Total kostnadspris",
    appliedDiscount: "Tillämpad rabatt",
    noOfItems: "Antal artiklar",
    sales: "Försäljning",
    export: "Exportera",
    totalProfit: "Total vinst",
    totalBalanceInPeriod: "Total balans i period",
    allTimeSales: "All Time Sales",
    records: "Uppgifter",
    todaysSales: "Dagens försäljning",
    transaction: "transaktion",
    youHaveExceededTotalNumberOfProducts:
      "Du har överskridit det totala antalet produkter som tillåts i din plan. Uppgradera din plan för att njuta av en högre gräns.",
    youNeedToHaveLoyaltyProgram:
      "Du måste ha ett lojalitetsprogram för att kunna använda den här funktionen!",
    price: "Pris",
    category: "Kategori",
    stockTracking: "Stock Tracking",
    stockCount: "Lagersaldo",
    taxed: "Beskattad",
    originalPrice: "Original pris",
    costPrice: "Kostnad",
    unit: "Enhet",
    productImage: "Produktbild",
    taxRate: "Skattenivå",
    taxType: "Typ av skatt",
    trackInventory: "Spåra lager",
    variants: "Varianter",
    hasVariants: "Har varianter",
    importProduct: "Importera produkt",
    exportProducts: "Exportprodukter",
    addNewProduct: "Lägg till ny produkt",
    viewCategory: "Visa kategori",
    viewSuppliers: "Visa leverantörer",
    receiveInventory: "Få inventering",
    printAllProductsTag: "Skriv ut alla produkter",
    deleteAll: "Radera allt",
    totalProducts: "TOTALA PRODUKTER",
    youveSuccessfullyAddedANewCategory: "Du har lagt till en ny kategori",
    addNewCategory: "Lägg till ny kategori",
    addCategory: "Lägg till Kategori",
    categoryName: "kategori namn",
    pleaseEnterCategoryName: "Ange kategorinamn",
    stampsTarget: "Frimärken Mål",
    sendInventory: "Skicka lager",
    productDetails: "Produktinformation",
    youveSuccessfullyEditedThisCategory: "Du har redigerat den här kategorin",
    update: "Uppdatering",
    categoryList: "Kategorilista",
    categories: "Kategorier",
    enterQuantityToUpdate: "Ange en mängd som ska uppdateras",
    inventorySentSuccessfully: "Inventeringen skickades framgångsrikt!",
    updateInventory: "Uppdatera lager",
    currentQuantity: "Nuvarande kvantitet",
    pleaseEnterQuantityToAdd: "Ange den mängd du vill lägga till",
    pleaseSelectABranch: "Välj en filial",
    searchForBranch: "Sök efter gren",
    youCantSendMoreThanStock: "Du kan inte skicka mer än du har i lager",
    send: "Skicka",
    pleaseEnterQuantityToSend: "Ange den mängd du vill skicka",
    productNameIsRequiredOnRow: "produktnamn krävs på rad",
    productCategoryIsRequiredOnRow: "produktkategori krävs på rad",
    productPriceIsRequiredOnRow: "produktpris krävs på rad",
    productUnitIsRequiredOnRow: "produktenhet krävs på rad",
    productQuantityIsRequiredOnRow: "produktkvantitet krävs på rad",
    productVariantsRequireTracking: "produktvarianter kräver spårning!",
    pleaseAddVariantClickButton:
      "Lägg till en variant genom att klicka på knappen Lägg till!",
    totalVariantsMoreThanSelectedQuantity:
      "Totala varianter är mer än vald produktkvantitet, Pls minskar variantkvantiteten",
    productEditedSuccessfully: "Produkten har redigerats framgångsrikt!",
    fileTooLargeLessThan4Mb:
      "Filstorleken är för stor! Filstorleken ska vara mindre än 4 MB.",
    suchVariantAlreadyExist: "En sådan variant finns redan",
    addVariants: "Lägg till varianter",
    editProduct: "Redigera produkt",
    pleaseEnterProductName: "Ange produktnamn",
    pleaseEnterProductPrice: "Ange produktpris",
    pleaseEnterProductOriginalPrice: "Ange produktens ursprungliga pris",
    productDescription: "Produktbeskrivning",
    selectProductCategory: "Välj produktkategori",
    pleaseSelectProductCategory: "Välj produktkategori",
    productCostPrice: "Produkt Kostnadspris",
    productSellingPrice: "Produktens försäljningspris",
    productOriginalPrice: "Produktens ursprungliga pris",
    maxFileSizeAllowedIs4Mb: "Max. Tillåten filstorlek är 4 mb",
    productsWithPicturesArePublished:
      "Produkter med bilder publiceras på Discover för att ta emot beställningar",
    shouldThisProductBeTracked: "Ska denna produkt spåras?",
    pleaseSelectStockUnit: "Välj lagerenhet",
    stockUnit: "Lagerenhet",
    bag: "VÄSKA",
    bottle: "FLASKA",
    bunch: "KNIPPA",
    can: "BURK",
    carton: "KARTONG",
    crate: "SPJÄLLÅDA",
    cup: "KOPP",
    dozen: "DUSSIN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACKA",
    pair: "PAR",
    pieces: "BÄST",
    plate: "TALLRIK",
    tonne: "TONNE (MT)",
    uNIT: "ENHET",
    yard: "GÅRD",
    pleaseEnterProductQuantity: "Ange produktmängd",
    productQuantity: "Produktmängd",
    isThisProductTaxed: "Beskattas denna produkt?",
    selectTaxType: "Välj skattetyp",
    pleaseSelectTaxType: "Välj skatte typ",
    progressive: "Progressiv",
    proportional: "Proportionell",
    pleaseEnterProductTaxRate: "Ange produktskattesats",
    doesProductHaveVariants: "Har denna produkt varianter?",
    type: "Typ",
    value: "Värde",
    pleaseEnterVariantType: "Ange variantens typ",
    pleaseEnterVariantValue: "Ange variantens värde",
    pleaseEnterVariantPrice: "Ange variantens pris",
    pleaseEnterVariantQuantity: "Ange variantens mängd",
    productDeletedSuccessfully: "Produkten har tagits bort!",
    categoryDeletedSuccessfully: "Kategorin har tagits bort!",
    toDeleteThisProduct: "för att radera denna produkt",
    invalidProductQuantity: "Ogiltig produktmängd",
    quantityAddedIsOverStock:
      "Mängden du lägger till är över vad du har på lager.",
    itemInventoryNotTracked: "Varuinventering spåras inte",
    addBulkQuantity: "Lägg till bulk",
    enterBulkQuantity: "Ange bulkmängd",
    pleaseEnterBulkQuantity: "Ange bulkmängd",
    youveSuccessfullyAddedANewProduct: "Du har lagt till en ny produkt!",
    pleaseEnterProductSellingPrice: "Ange produktens försäljningspris",
    doYouWantToTrackProductStock: "Vill du spåra produktlager?",
    sellingPrice: "Säljande pris",
    setProductExpiryReminder: "Ställ in påminnelse om produktutgång",
    productExpiryDate: "Produktens utgångsdatum",
    startRemindingFrom: "Börja påminna från",
    productSuppliesAddedSuccessfully: "Du har lagt till produkttillbehör.",
    addProductSupplies: "Lägg till produkttillbehör",
    pleaseSelectSupplier: "Välj leverantör",
    nameOfProduct: "Produktnamn",
    pleaseSelectProduct: "Välj produkt",
    productVariant: "Produktvariant",
    pleaseSelectAVariant: "Välj en variant",
    pleaseEnterUnitPrice: "Ange enhetspris",
    businessBranch: "Affärsgren",
    pleaseSelectBranch: "Välj filial",
    youveSuccessfullyAddedANewSupplier:
      "Du har framgångsrikt lagt till en ny leverantör",
    addSupplier: "Lägg till leverantör",
    pleaseEnterSupplierEmail: "Ange leverantörens e -postadress",
    pleaseAddADescription: "Lägg till en beskrivning",
    anErrorOccuredProductsDeleted:
      "Ett fel uppstod när åtgärden utfördes. Observera att få produkter kan ha raderats i processen",
    bulkDelete: "Ta bort mass",
    youAreAboutToDelete: "Du håller på att radera",
    product: "Produkt",
    isDueToRedeem: "beror på att lösa in",
    aReward: "ett pris",
    pleaseSelectCustomerToReeemReward:
      "Välj en kund för att lösa in belöningen.",
    youHaveNoLoyaltyProgramRunning:
      "Du har inget aktivt lojalitetsprogram igång",
    customerHhasNoPointsInLoyaltyProgram:
      "Kunden har inga poäng i detta lojalitetsprogram",
    proceedWithPayment: "Fortsätta med betalning?",
    youAreAboutToPayForTransactionUsingPoints:
      "Du är på väg att betala för transaktionen med hjälp av poäng.",
    customerHas: "Kunden har",
    worth: "värde",
    andIsNotSufficientToPayForTransaction:
      "och är inte tillräckligt för att betala för denna transaktion. Skulle de vilja lägga till saldot med en annan betalningsmetod?",
    addCustomerLoyalty: "Lägg till kundlojalitet",
    pleaseAddCustomerFirst: "Lägg till eller välj en kund först.",
    pleaseWaitWhileWeGetReady: "Vänta, medan vi gör oss redo",
    lowStock: "Lågt lager",
    pleaseEnterAmountTendered: "Ange det anbudssumma",
    areYouSureToBookSaleOffline:
      "Är du säker på att du vill boka den här försäljningen offline?",
    saleWouldBeBookedAutomatically:
      "Försäljningen bokas automatiskt när du slår på internet",
    offlineSalesBookingCancelled: "Offline försäljningsbokning avbruten",
    covid19Message:
      "COVID19: Tvätta händerna med tvål eller sanera för att stoppa spridningen. Var alltid säker",
    saleSuccessfullyRecorded: "Försäljning framgångsrikt inspelad!",
    sendReceiptToEmail: "Skicka kvitto till e -post",
    sendThankYouSms: "Skicka tack SMS",
    sendShippingDetails: "Skicka fraktinformation",
    addLoyalty: "Lägg till lojalitet",
    searchCustomerNameOrNumber: "Sök kundnamn eller nummer",
    clickTheSearchCustomerBox: "Klicka på Sök kundbox och skanna kort",
    enterProductPrice: "Ange produktpris",
    enterPriceFor: "Ange pris för",
    searchForProduct: "Sök efter produkt",
    all: "Allt",
    backToDashboard: "Tillbaka till instrumentpanelen",
    viewDraftSales: "Visa utkast till försäljning",
    variantSelected: "variant vald",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Det föregående variantutbudet är inte tillgängligt för den nya varianten som valts utifrån pris, ändra ditt val.",
    pleaseSupplyPositiveQuantityNumber: "Ange ett postivt kvantitetsnummer",
    lowStockFor: "Lågt lager för",
    clearVariants: "Tydliga varianter",
    pleaseEnterQuantity: "Ange antal",
    picture: "Bild",
    redemptionToken: "Inlösningstoken",
    token: "Tecken",
    totalSpent: "Totalt spenderat",
    confirmPayment: "Bekräfta betalning",
    hasPaymentBeenMade: "Har betalningen behandlats framgångsrikt?",
    enterTransactionReference: "Ange transaktionsreferensen du betalade med",
    pleaseSelectACustomer: "Välj en kund!",
    areYouSureToApplyDiscount: "Är du säker på att du vill tillämpa rabatt?",
    addYourBankAccountToEnableUssd:
      "Lägg till ditt bankkonto för att möjliggöra omedelbar USSD -överföring med uPay",
    totalAmountToPay: "Totalt belopp att betala",
    doYouWantToCancelTransaction: "Vill du avbryta denna transaktion?",
    savePrintBill: "Spara/skriv ut faktura",
    enterAmountCollectedForCustomer: "Ange belopp som samlats in för kund",
    recordSale: "Skivförsäljning",
    checkOutWith: "Kolla in med",
    instantTransfer: "Omedelbar överföring",
    dialTheUSSDCode: "Slå USSD -koden",
    pleaseSelectABank: "Välj en bank",
    payWithUSSD: "Betala med USSD",
    sendBillTo: " - Skicka Bill till",
    waitingForUSSDTransfer: "Väntar på USSD -överföring",
    percent: "Procent",
    applyDiscount: "Tillämpa rabatt",
    thisBillHasBeenSaved: "Denna proposition har sparats",
    saveDraft: "Spara utkast",
    pleaseTypeANameToIdentifyCustomer:
      "Skriv in ett namn för att identifiera kunden",
    paymentDetails: "Betalningsinformation",
    basePrice: "Grundpris",
    staff: "Personal",
    subTotal: "Delsumma",
    durationMonths: "Längd (månader)",
    selectADuration: "Välj en Varaktighet",
    oneMonth: "1 månad",
    twoMonths: "2 månader",
    threeMonths: "3 månader",
    sixMonths: "6 månader",
    twelveMonths: "12 månader",
    eighteenMonths: "18 månader",
    twentyFourMonths: "24 månader",
    twoMonthsFree: "(2 månader gratis)",
    threeMonthsFree: "(3 månader gratis)",
    fiveMonthsFree: "(5 månader gratis)",
    yourAccountHasBeen: "Ditt konto har varit",
    renewed: "förnyad",
    upgraded: "uppgraderad",
    successfully: "framgångsrikt",
    yourSubscription: "Din prenumeration",
    youAreCurrentlyEnrolledOnA: "Du är för närvarande inskriven på en",
    pleaseChooseAPaymentOption: "Välj ett betalningsalternativ",
    planRenewal: "Planförnyelse",
    planUpgrade: "Planera uppgradering",
    pleaseSelectDurationToPayFor: "Välj Varaktighet du tänker betala för",
    staffAccounts: "Personalkonton",
    ecommerce: "E -handel",
    pleaseSelectAPlan: "Välj en plan",
    includeAddons: "Inkludera tillägg",
    viewTransactions: "Visa transaktioner",
    customerHasNoCompletedTansactions:
      "Kunden har inga avslutade transaktioner ännu",
    branch: "Gren",
    enterNumberOfEcommerceBranches: "Ange antal e -handelsgrenar",
    enterNumberOfEcommerceBranchesToPay:
      "Ange antalet e -handelsgrenar du tänker betala för",
    ecommerceIntegration: "Integration för e -handel",
    enterNumberOfBranches: "Ange antal grenar",
    enterNumberOfAdditionalBranchesToPay:
      "Ange antalet ytterligare grenar du tänker betala för",
    enterNumberOfStaffs: "Ange antal staber",
    enterNumberOfStaffsToPayFor: "Ange antalet anställda du tänker betala för",
    discountApplies: "Rabatt gäller",
    starsOnThe: "stjärnor på",
    offer: "erbjudande",
    get: "Skaffa sig",
    moreStarsToRedeem: "fler stjärnor att lösa in",
    taxVat: "Skatt (moms)",
    callCashierToCompletePayment: "Ring kassan för att slutföra betalningen",
    receipt: "Mottagande",
    dear: "Kära",
    thankYouForYourOrderFindGoods:
      "Tack för din beställning. Vänligen hitta följande levererade varor enligt överenskommelse.",
    shippingNote: "Frakt Obs",
    enterShippingNote: "Ange fraktsedel",
    shippingAddress: "Leveransadress",
    enterShippingAddress: "Ange leveransadress",
    wellDoneYouAreDueToRedeem: "Bra gjort! Du ska lösa in",
    toGetYourRewardNextVisit:
      "för att få din belöning vid ditt nästa besök. Tack",
    pointsOnThe: "Poäng på",
    morePointsToRedeem: "fler poäng att lösa in",
    showCode: "Visa kod",
    toGetYourRewardOnNextVisit:
      "för att få din belöning vid ditt nästa besök. Tack",
    earn: "Tjäna",
    delivaryNote: "Delivary Not",
    draftSales: "Utkast till försäljning",
    startDate: "Start datum",
    endDate: "Slutdatum",
    orders: "Order",
    checkout: "kolla upp",
    noProductItem: "Ingen produktartikel",
    selectProductImage: "Välj produktbild",
    selectCountry: "Välj land",
    selectRegion: "Välj stat/region",
    printProductTag: "Skriv ut produktmärke",
    transactionReference: "Transaktionsreferens",
    Cashier: "Kassör",
    Manager: "Chef",
    Owner: "Ägare",
    Admin: "Administration",
    addPartners: "Lägg till partners",
    addNewLoyaltyPartner: "Lägg till ny lojalitetspartner",
    pleaseEnterCompanyName: "Ange företagsnamn",
    companyName: "Företagsnamn",
    pleaseEnterCompanyRepName: "Ange företagsrepresentantens namn",
    companyRepName: "Namn på företagsrepresentant",
    pleaseEnterCompanyRepEmail: "Ange företagets representants e-postadress",
    companyRepEmail: "E-post till företagsrepresentant",
    pleaseEnterCompanyRepPhone:
      "Vänligen ange telefonnummer till företagets representant",
    companyRepPhone: "Telefonnummer till företagsrepresentant",
    addReward: "Lägg till belöning",
    pleaseEnterRewardName: "Vänligen ange belöningens namn",
    rewardName: "Belöningsnamn",
    rewardQuantity: "Belöning Kvantitet",
    rewardDescription: "Belöningsbeskrivning",
    rewardType: "Typ av belöning",
    pleaseEnterRewardType: "Ange belöningstyp",
    pleaseEnterRewardQuantity: "Ange belöningskvantitet",
    pleaseEnterRewardDescription: "Ange belöningsbeskrivning",
    fineDining: "Finmiddag",
    luxuryFashion: "Lyxigt mode",
    hotels: "Hotell",
    travel: "Resa",
    foodAndBeverage: "Mat och dryck",
    fashion: "Mode",
    health: "Hälsa",
    furniture: "möbel",
    entertainment: "Underhållning",
    automobile: "Bil",
    education: "Utbildning",
    beautyAndSpa: "Skönhet och Spa",
    staycation: "Staycation",
    events: "evenemang",
    trips: "Resor",
    oilAndGas: "Olja och gas",
    laundry: "Tvätt",
    partnerCategory: "Partnerkategori",
    freeItem: "Gratis föremål",
  },
  Telugu: {
    cashier: "క్యాషియర్",
    manager: "నిర్వాహకుడు",
    owner: "యజమాని",
    online: "ఆన్లైన్",
    offline: "ఆఫ్‌లైన్",
    changePassword: "పాస్వర్డ్ మార్చండి",
    currentPasswordMessage: "దయచేసి మీ ప్రస్తుత పాస్‌వర్డ్‌ని నమోదు చేయండి",
    passwordMessage: "దయచేసి మీ పాస్‌వర్డ్ నమోదు చేయండి",
    currentPassword: "ప్రస్తుత పాస్వర్డ్",
    password: "పాస్వర్డ్",
    confirmPasswordMessage: "దయచేసి మీ పాస్‌వర్డ్‌ని నిర్ధారించండి!",
    confirmPassword: "పాస్‌వర్డ్‌ని నిర్ధారించండి",
    sendViaEmail: "ఇమెయిల్ ద్వారా పంపండి",
    sendViaWhatsapp: "WhatsApp ద్వారా పంపండి",
    downloadPdf: "PDF ని డౌన్‌లోడ్ చేయండి",
    paid: "చెల్లించారు",
    unpaid: "చెల్లించబడలేదు",
    partial: "పాక్షికం",
    closeInvoice: "మీరు ఇన్‌వాయిస్‌ను మూసివేయాలనుకుంటున్నారా?",
    closeInvoiceConfirmation:
      "ఇన్‌వాయిస్ సేవ్ చేయబడకపోవచ్చు. మీరు మూసివేయాలనుకుంటున్నారా?",
    yes: "అవును",
    no: "లేదు",
    invoice: "ఇన్వాయిస్",
    wasDeducted: "తీసివేయబడింది",
    for: "కోసం",
    item: "అంశం",
    addProduct: "ఉత్పత్తిని జోడించండి",
    paymentReference: "చెల్లింపు సూచనా",
    amountPaid: "డబ్బులు చెల్లించబడినవి",
    discountAmount: "డిస్కౌంట్ మొత్తం",
    amountDue: "బకాయి మొత్తం",
    amount: "మొత్తం",
    dueDate: "గడువు తేది",
    paymentType: "చెల్లించు విధానము",
    card: "కార్డు",
    cash: "నగదు",
    bankTransfer: "బ్యాంకు బదిలీ",
    paymentMessage: "చెల్లింపు సందేశం",
    description: "వివరణ",
    sendReceipt: "రసీదు పంపండి",
    delete: "తొలగించు",
    save: "సేవ్ చేయండి",
    resend: "తిరిగి పంపండి",
    saveAndSend: "సేవ్ చేయండి",
    invoiceSaved: "ఇన్‌వాయిస్ సేవ్ చేయబడింది!",
    selectPaymentMethod: "దయచేసి చెల్లింపు పద్ధతిని ఎంచుకోండి!",
    selectCustomer: "దయచేసి కస్టమర్‌ను ఎంచుకోండి!",
    cartEmptyError:
      "కార్ట్ జాబితా ఖాళీగా ఉండదు, ఇన్‌వాయిస్‌ని మూసివేసి, కార్ట్‌కు ఐటెమ్‌ను జోడించండి!",
    subscriptionExpired:
      "మీ సభ్యత్వం గడువు ముగిసింది మరియు మీ ఖాతా ఇప్పుడు పరిమితం చేయబడింది. మీ ఖాతాను పునరుద్ధరించడానికి దిగువ బటన్ పై క్లిక్ చేయండి",
    renew: "పునరుద్ధరించు",
    holdOn: "ఆగండి",
    customerBank: "కస్టమర్ బ్యాంక్",
    cancel: "రద్దు చేయండి",
    selectACustomer: "కస్టమర్‌ని ఎంచుకోండి",
    invoiceSuccessfulPdfError:
      "ఇన్వాయిస్ విజయవంతంగా సృష్టించబడింది కానీ PDF జనరేషన్ సాధారణం కంటే ఎక్కువ సమయం తీసుకుంటుంది. దయచేసి త్వరలో తిరిగి తనిఖీ చేయండి.",
    downloadingInvoice: "ఇన్‌వాయిస్‌ని డౌన్‌లోడ్ చేస్తోంది",
    downloadingReceipt: "రసీదుని డౌన్‌లోడ్ చేస్తోంది",
    whatsappReceiptError:
      "WhatsApp ద్వారా రసీదుని పంపడంలో లోపం సంభవించింది, దయచేసి మళ్లీ ప్రయత్నించండి.",
    receiptToWhatsapp: "వాట్సాప్‌కు రసీదు ఫార్వార్డ్ చేయబడింది",
    thankYouForPatronage: "మీ ఆదరణకు ధన్యవాదాలు",
    hereIsYourReceipt: "ఇక్కడ మీ చెల్లింపు రసీదు ఉంది",
    errorSendingEmailReceipt:
      "ఇ-మెయిల్ ద్వారా రసీదుని పంపడంలో లోపం సంభవించింది, దయచేసి మళ్లీ ప్రయత్నించండి లేదా మద్దతుని సంప్రదించండి",
    receiptSentToEmail: "కస్టమర్ ఇమెయిల్‌కు రసీదు పంపబడింది",
    invoiceSentToEmail: "కస్టమర్ ఇమెయిల్‌కు ఇన్‌వాయిస్ పంపబడింది",
    invoiceSuccessWhatsappError:
      "ఇన్‌వాయిస్ విజయవంతంగా సృష్టించబడింది కానీ WhatsApp కి పంపడంలో లోపం సంభవించింది. ఇన్‌వాయిస్ జాబితాలో మళ్లీ ప్రయత్నించండి",
    invoiceSuccessfulEmailError:
      "ఇన్‌వాయిస్ విజయవంతంగా సృష్టించబడింది కానీ ఇ-మెయిల్‌గా పంపేటప్పుడు లోపం సంభవించింది. దయచేసి ఇన్‌వాయిస్ జాబితా నుండి మళ్లీ ప్రయత్నించండి",
    invoiceSentToWhatsapp: "ఇన్‌వాయిస్ WhatsApp కి ఫార్వార్డ్ చేయబడింది",
    hello: "హలో",
    pleaseDownloadInvoice: "దయచేసి ఇన్‌వాయిస్ డౌన్‌లోడ్ చేయండి",
    pleaseDownloadReceipt: "దయచేసి రసీదుని డౌన్‌లోడ్ చేయండి",
    from: "నుండి",
    email: "ఇమెయిల్",
    upgrade: "అప్‌గ్రేడ్",
    youAreOnFreePlan: "మీరు ఉచిత ప్రణాళికలో ఉన్నారు.",
    clickOn: "నొక్కండి",
    yourPlanInFewSteps: " మీ చందా కొన్ని శీఘ్ర దశల్లో.",
    to: "కు",
    yourSubscriptionHasExpired:
      "మీ సభ్యత్వం గడువు ముగిసింది మరియు మీ ఖాతా ఇప్పుడు పరిమితం చేయబడింది.",
    days: "రోజులు",
    yourSubscriptionExpiresIn:
      "మీ లాయిస్టార్ సబ్‌స్క్రిప్షన్ గడువు ముగుస్తుంది",
    createAcount: "ఒక ఖాతాను సృష్టించండి",
    signIn: "సైన్ ఇన్ చేయండి",
    continue: "కొనసాగించండి",
    enterOtp: "OTP పిన్ నమోదు చేయండి",
    enterValidOtp: "దయచేసి చెల్లుబాటు అయ్యే PIN ని నమోదు చేయండి",
    pin: "పిన్",
    tokenSentToMail: "మీ ఇమెయిల్‌కు టోకెన్ పంపబడింది",
    passwordResetSuccessful: "పాస్‌వర్డ్ రీసెట్ విజయవంతమైంది",
    somethingWentWrong: "ఎక్కడో తేడ జరిగింది!",
    resetPassword: "రహస్యపదాన్ని మార్చుకోండి",
    iHaveResetCode: "నా దగ్గర పాస్‌వర్డ్ రీసెట్ కోడ్ ఉంది",
    pleaseEnterEmail: "దయచేసి మీ ఇమెయిల్ నమోదు చేయండి",
    aTokenWillBeSentToEmail: "మీ ఇమెయిల్‌కు టోకెన్ పంపబడుతుంది",
    enterEmail: "మీ ఇమెయిల్ నమోదు చేయండి",
    sendinYourToken: "మీ టోకెన్ పంపుతోంది ...",
    makeSureItMatchesPassword:
      "ఇది మీ కొత్త పాస్‌వర్డ్‌తో సరిపోలుతోందని నిర్ధారించుకోండి",
    pleaseChooseNewPassword: "దయచేసి కొత్త పాస్‌వర్డ్‌ని ఎంచుకోండి",
    chooseNewPassword: "కొత్త పాస్‌వర్డ్‌ని ఎంచుకోండి",
    enterNewPassword: "నిర్ధారించడానికి మీ కొత్త పాస్‌వర్డ్‌ని నమోదు చేయండి",
    enterTokenSent: "మీ మెయిల్ పంపిన టోకెన్‌ను నమోదు చేయండి",
    resetToken: "రీసెట్ టోకెన్",
    resettingPassword: "మీ పాస్‌వర్డ్ రీసెట్ చేస్తోంది ...",
    signUp: "చేరడం",
    adminSignInWithEmail:
      " అడ్మిన్ ఇమెయిల్‌తో సైన్ ఇన్ చేయగా సిబ్బంది యూజర్‌నేమ్‌తో సైన్ ఇన్ చేస్తారు.",
    pleaseEnterEmailOrUsername:
      "దయచేసి మీ ఇమెయిల్ లేదా వినియోగదారు పేరు నమోదు చేయండి",
    emailOrUsername: "ఇమెయిల్ లేదా వినియోగదారు పేరు",
    pleaseEnterPassword: "దయచేసి పాస్‌వర్డ్ నమోదు చేయండి",
    createAnAccount: "ఒక ఖాతాను సృష్టించండి",
    forgotPassword: "పాస్‌వర్డ్ మర్చిపోయారా?",
    enterPhoneNumber: "ఫోన్ నంబర్ నమోదు చేయండి",
    personalData: "వ్యక్తిగత సమాచారం",
    validateConfirmationCOde: "నిర్ధారణ కోడ్‌ని ధృవీకరించండి",
    pleaseEnterFirstName: "దయచేసి మీ మొదటి పేరు నమోదు చేయండి",
    pleaseEnterPhoneNumber: "దయచేసి మీ ఫోన్ నంబర్ నమోదు చేయండి",
    pleaseEnterLastName: "దయచేసి మీ చివరి పేరు నమోదు చేయండి",
    pleaseEnterBusinessName: "దయచేసి మీ వ్యాపార పేరు నమోదు చేయండి",
    firstName: "మొదటి పేరు",
    lastName: "చివరి పేరు",
    businessName: "వ్యాపారం పేరు",
    previous: "మునుపటి",
    next: "తరువాత",
    pleaseSelectBusinessCategory: "దయచేసి మీ వ్యాపార వర్గాన్ని ఎంచుకోండి",
    pleaseEnterValidEmail: "దయచేసి సరైన ఈమెయిల్ ఇవ్వండి",
    pleaseEnterPostCode: "దయచేసి పోస్ట్ కోడ్‌ని నమోదు చేయండి",
    postCode: "పోస్ట్ కోడ్",
    phoneNumberInUse: "ఈ ఫోన్ నంబర్ ఇప్పటికే ఉపయోగంలో ఉంది!",
    emailInUse: "ఈ ఇమెయిల్ ఇప్పటికే ఉపయోగంలో ఉంది!",
    foodAndDrinks: "ఆహారం మరియు పానీయాలు",
    salonAndBeauty: "సెలూన్ మరియు అందం",
    fashionAndAccessories: "ఫ్యాషన్ మరియు ఉపకరణాలు",
    gymAndFitness: "జిమ్ మరియు ఫిట్‌నెస్",
    travelAndHotel: "ప్రయాణం మరియు హోటల్",
    homeAndGifts: "ఇల్లు మరియు బహుమతులు",
    washingAndCleaning: "వాషింగ్ మరియు క్లీనింగ్",
    gadgetsAndElectronics: "గాడ్జెట్లు మరియు ఎలక్ట్రానిక్స్",
    groceries: "కిరాణా సామాగ్రి",
    others: "ఇతరులు",
    submit: "సమర్పించండి",
    accountCreatedSuccessful: "మీ ఖాతా విజయవంతంగా సృష్టించబడింది.",
    pleaseEnterAddress: "దయచేసి మీ చిరునామాను నమోదు చేయండి",
    addressLine1: "చిరునామా లైన్ 1",
    addressLine2: "చిరునామా లైన్ 2",
    choosePassword: "పాస్‌వర్డ్‌ని ఎంచుకోండి",
    passwordMustBe6Characters: "పాస్వర్డ్ తప్పనిసరిగా కనీసం 6 అక్షరాలు ఉండాలి.",
    howDidYouHearLoystar: "లాయ్‌స్టార్ గురించి మీరు ఎలా విన్నారు?",
    referralCode: "రిఫెరల్ కోడ్",
    byClickingTheButton:
      " దిగువ బటన్‌ను క్లిక్ చేయడం ద్వారా, మీరు దీనికి అంగీకరిస్తున్నారు",
    termsAndSevice: "నిబంధనలు",
    wereCreatingAccount: "మేము మీ ఖాతాను సృష్టిస్తున్నాము",
    proceed: "కొనసాగండి",
    verificationCodeMustBe6: "ధృవీకరణ కోడ్ తప్పనిసరిగా 6 అంకెలు ఉండాలి",
    pleaseEnter6DigitCode: "దయచేసి 6 అంకెల కోడ్‌ని నమోదు చేయండి",
    enterVerificationCode: "ధృవీకరణ కోడ్ను నమోదు చేయండి",
    resendToken: "టోకెన్‌ను మళ్లీ పంపండి",
    verify: "ధృవీకరించు",
    transactions: "లావాదేవీలు",
    todaySales: "నేటి అమ్మకాలు",
    salesHistory: "అమ్మకాల చరిత్ర",
    supplyHistory: "సరఫరా చరిత్ర",
    new: "కొత్త",
    invoices: "ఇన్వాయిస్‌లు",
    disbursements: "పంపిణీలు",
    offlineSales: "ఆఫ్‌లైన్ అమ్మకాలు",
    products: "ఉత్పత్తులు",
    customers: "కస్టమర్లు",
    multiLevelLoyalty: "బహుళ స్థాయి విధేయత",
    loyaltyPrograms: "విధేయత కార్యక్రమాలు",
    members: "సభ్యులు",
    appStore: "యాప్ స్టోర్",
    orderMenu: "ఆర్డర్ మెనూ",
    settings: "సెట్టింగులు",
    staffAndBranches: "సిబ్బంది మరియు శాఖలు",
    myAccount: "నా ఖాతా",
    switchToSellMode: "విక్రయ మోడ్‌కు మారండి",
    signOut: "సైన్ అవుట్ చేయండి",
    getFreeSubscription: "ఉచిత చందా పొందండి",
    onlyNumbersAllowed: "సంఖ్యలు మాత్రమే అనుమతించబడతాయి",
    yourAccountMustBe10Digits:
      "మీ ఖాతా నంబర్ తప్పనిసరిగా 10 అంకెల నంబర్‌గా ఉండాలి",
    yourBvnMustBe11: "మీ BVN తప్పనిసరిగా 11 అంకెల సంఖ్య ఉండాలి",
    pleaseSelectBank: "దయచేసి మీ బ్యాంక్‌ని ఎంచుకోండి",
    selectYourBank: "మీ బ్యాంక్‌ని ఎంచుకోండి",
    enterBankAccountNumber: "బ్యాంక్ ఖాతా సంఖ్యను నమోదు చేయండి",
    enterBvnNumber: "మీ BVN ని నమోదు చేయండి",
    connectBankAccount: "బ్యాంక్ ఖాతాను కనెక్ట్ చేయండి",
    passwordResetSuccessfulAndSignOut:
      "మీ పాస్‌వర్డ్ విజయవంతంగా రీసెట్ చేయబడింది. మళ్లీ సైన్ ఇన్ చేయడానికి క్రింది సైన్ అవుట్ బటన్‌పై క్లిక్ చేయండి",
    enterCurrentPassword: "ప్రస్తుత పాస్‌వర్డ్‌ని నమోదు చేయండి",
    pleaseEnterCurrentPassword: "దయచేసి ప్రస్తుత పాస్‌వర్డ్‌ని నమోదు చేయండి",
    phoneNumber: "ఫోను నంబరు",
    sex: "సెక్స్",
    dateOfBirth: "పుట్టిన తేది",
    state: "రాష్ట్రం",
    country: "దేశం",
    loyaltyId: "విధేయత ID",
    createdAt: "వద్ద సృష్టించబడింది",
    noOfTransactionsMade: "చేసిన లావాదేవీల సంఖ్య",
    yourDownloadWillStart: "మీ డౌన్‌లోడ్ క్షణంలో ప్రారంభమవుతుంది",
    exportCustomers: "ఎగుమతి కస్టమర్‌లు",
    youhaveSuccessfullyToppedUp:
      "మీరు మీ SMS యూనిట్లలో విజయవంతంగా అగ్రస్థానంలో ఉన్నారు.",
    youNowHave: "మీకు ఇప్పుడు ఉంది",
    smsUnits: "SMS యూనిట్లు",
    enterNumberOfSmsUnits:
      "మీరు కొనుగోలు చేయడానికి ఉద్దేశించిన SMS యూనిట్ల సంఖ్యను నమోదు చేయండి",
    pleaseEnterANumericValue: "దయచేసి సంఖ్యా విలువను నమోదు చేయండి",
    pay: "చెల్లించండి",
    accountEditedSuccessfully: "మీ ఖాతా విజయవంతంగా సవరించబడింది.",
    youAeCurrentlyOn: "మీరు ప్రస్తుతం ఉన్నారు",
    plan: "ప్రణాళిక",
    userData: "వినియోగదారు డేటా",
    businessData: "వ్యాపార డేటా",
    businessCategory: "వ్యాపార కేటగిరి",
    pleaseSelectCurrency: "దయచేసి మీ కరెన్సీని ఎంచుకోండి",
    selectYourCurrency: "మీ కరెన్సీని ఎంచుకోండి",
    purchaseMoreSmsUnits:
      "దిగువ ఫారమ్‌ను ఉపయోగించి మరిన్ని SMS యూనిట్‌లను కొనుగోలు చేయండి",
    youHave: "మీకు ఉంది",
    atLeast4SmsUnitsRrequired:
      "ధృవీకరణ కోసం కనీసం 4 sms యూనిట్లు అవసరం, దయచేసి టాప్ అప్ చేయండి!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "దయచేసి 'మీ కస్టమర్‌ను తెలుసుకోండి' (KYC) అవసరాలకు అనుగుణంగా మీ బ్యాంక్ ఖాతాను ధృవీకరించండి. ఇది మీరు USSD లేదా తక్షణ బదిలీ ద్వారా చెల్లింపులను సేకరించడానికి, కస్టమర్ల నుండి ఆర్డర్‌లను స్వీకరించడానికి మరియు లావాదేవీ సెటిల్‌మెంట్‌ను ప్రాసెస్ చేయడానికి అనుమతిస్తుంది. నైజీరియన్ వ్యాపారులకు మాత్రమే వర్తిస్తుంది. ప్రారంభించడానికి దయచేసి దిగువ బటన్‌ని నొక్కండి.",
    reConnectBankAccount: "బ్యాంక్ ఖాతాను మళ్లీ కనెక్ట్ చేయండి",
    accountName: "ఖాతా పేరు",
    accountNumber: "ఖాతా సంఖ్య",
    bankName: "బ్యాంక్ పేరు",
    verified: "ధృవీకరించబడింది",
    accountDetails: "ఖాతా వివరాలు",
    kycBankAccount: "KYC",
    createTier: "శ్రేణిని సృష్టించండి",
    fileUploadSuccessful: "ఫైల్ విజయవంతంగా అప్‌లోడ్ చేయబడింది",
    fileUploadFailed: "ఫైల్ అప్‌లోడ్ విఫలమైంది",
    createLoyaltyProgram: "లాయల్టీ ప్రోగ్రామ్‌ను సృష్టించండి",
    createLoyalty: "విధేయతను సృష్టించండి",
    name: "పేరు",
    loyaltyArtwork: "విధేయత కళాకృతి",
    clickToUpload: "అప్‌లోడ్ చేయడానికి క్లిక్ చేయండి",
    amountToPointsRatio: "పాయింట్ల నిష్పత్తికి మొత్తం",
    points: "పాయింట్లు",
    recommendedAmountToPointRatio:
      "సిఫార్సు చేయబడింది: ₦ 1 నుండి 1 పాయింట్. అంటే ప్రతి 1 నైరా ఖర్చు చేసినందుకు, మీ కస్టమర్‌లు 1 పాయింట్ పొందుతారు",
    pleaseTypeIn: "దయచేసి టైప్ చేయండి",
    toDeleteLoyalty: "ఈ విధేయతను తొలగించడానికి",
    deleteLoyalty: "విధేయతను తొలగించండి",
    toConfirm: "నిర్దారించుటకు",
    edit: "సవరించు",
    pointsAwardedSuccessfully: "పాయింట్లు విజయవంతంగా అందించబడ్డాయి.",
    enterPointValueToAward:
      "మీరు వినియోగదారులకు అవార్డు ఇవ్వాలనుకుంటున్న పాయింట్ విలువను నమోదు చేయండి",
    award: "అవార్డు",
    awardPointValuesToCustomer: "కస్టమర్‌కు అవార్డు పాయింట్ విలువ",
    enrollMembersToLoyalty: "సభ్యులను విధేయతపై నమోదు చేయండి",
    awardPoints: "అవార్డు పాయింట్లు",
    enroll: "నమోదు",
    home: "హోమ్",
    loyalty: "విధేయత",
    enrollCustomers: "వినియోగదారులను నమోదు చేయండి",
    selected: "ఎంపిక చేయబడింది",
    customer: "కస్టమర్",
    loyaltyActivationSuccessful: "లాయల్టీ యాక్టివేషన్ విజయవంతమైంది.",
    loyaltyDeactivationSuccessful: "విధేయత నిష్క్రియం విజయవంతమైంది.",
    viewTier: "వ్యూ టైర్",
    deactivate: "డి-యాక్టివేట్ చేయండి",
    activate: "సక్రియం చేయండి",
    actions: "చర్యలు",
    nameOfLoyalty: "విధేయత పేరు",
    loyaltyStatus: "విధేయత స్థితి విధేయత స్థితి",
    numberOfTiers: "శ్రేణుల సంఖ్య",
    createdOn: "ఆన్‌లో సృష్టించబడింది",
    createATier: "శ్రేణిని సృష్టించండి",
    stopCreatingTierConfirmation:
      "మీరు శ్రేణిని సృష్టించడం ఆపివేయాలనుకుంటున్నారా?",
    stopEditingTierConfirmation:
      "మీరు ఈ శ్రేణిని సవరించడం ఆపివేయాలనుకుంటున్నారా?",
    nameOfTier: "టైర్ పేరు",
    minimumSpendingTarget: "కనీస ఖర్చు లక్ష్యం",
    maximumSpendingTarget: "గరిష్ట ఖర్చు లక్ష్యం",
    minimumSpendingTargetRequired: "కనీస ఖర్చు లక్ష్యం అవసరం",
    maximumSpendingTargetRequired: "గరిష్ట ఖర్చు లక్ష్యం అవసరం",
    rewardSponsor: "రివార్డ్ స్పాన్సర్",
    pleaseChooseARewardSponsor: "దయచేసి రివార్డ్ స్పాన్సర్‌ని ఎంచుకోండి",
    self: "నేనే",
    partner: "భాగస్వామి",
    rewardPartner: "రివార్డ్ భాగస్వామి",
    pleaseSelectRewardPartner: "దయచేసి మీ రివార్డ్ భాగస్వామిని ఎంచుకోండి",
    rewards: "రివార్డులు",
    pleaseSelectAReward: "దయచేసి బహుమతిని ఎంచుకోండి",
    instructionsOnRedeemingReward:
      "కస్టమర్ రివార్డ్‌ను ఎలా రీడీమ్ చేయాలి అనే దానిపై సూచనలు",
    pleaseFillInThisField: "దయచేసి ఈ ఫీల్డ్‌ని పూరించండి!",
    toDeleteThisTier: " ఈ శ్రేణిని తొలగించడానికి",
    deleteTier: "టైర్‌ని తొలగించండి",
    viewMembers: "సభ్యులను వీక్షించండి",
    membersEnrolled: "సభ్యులు నమోదు చేసుకున్నారు",
    instruction: "సూచన",
    membersIn: "లో సభ్యులు",
    availableTiersInLoyalty: "లాయల్టీ ప్రోగ్రామ్‌లో అందుబాటులో ఉన్న టైర్ (లు)",
    tiers: "శ్రేణులు",
    totalTier: "మొత్తం శ్రేణి",
    availableLoyaltyProgramme: "లాయల్టీ ప్రోగ్రామ్ అందుబాటులో ఉంది",
    totalLoyalties: "మొత్తం విధేయతలు",
    memberDetails: "సభ్యుల వివరాలు",
    nameOfCustomer: "కస్టమర్ పేరు",
    address: "చిరునామా",
    allEnrolledMembers: "నమోదు చేసుకున్న సభ్యులందరూ",
    thisIsToWishYouHappyBirthday:
      "ఇది మీకు పుట్టినరోజు శుభాకాంక్షలు మరియు సంపన్నమైన జీవితాన్ని కోరుకుంటున్నది. మీరు లాయిస్టర్‌కి ధన్యవాదాలు. హ్యాపీ సెలబ్రేషన్స్!",
    inputAnOfferPlease: "దయచేసి ఆఫర్‌ను ఇన్‌పుట్ చేయండి",
    pleaseSelectTheInsertPoint:
      "దయచేసి సందేశంలోని ఇన్సర్ట్ పాయింట్‌ని ఎంచుకుని, మళ్లీ క్లిక్ చేయండి",
    birthdayOfferAndMessage: "పుట్టినరోజు ఆఫర్ మరియు సందేశం",
    birthdayOffer: "పుట్టినరోజు ఆఫర్",
    birthdayMessage: "పుట్టినరోజు సందేశం",
    noOfferFound: "ఆఫర్ కనుగొనబడలేదు",
    settingABirthdayOffer:
      "పుట్టినరోజు ఆఫర్‌ను సెట్ చేయడం ద్వారా కస్టమర్‌లు తమ పుట్టినరోజున SMS ద్వారా ఈ ఆఫర్‌ను స్వీకరించవచ్చు",
    createOffer: "ఆఫర్‌ని సృష్టించండి",
    whatIsTheOffer: "ఆఫర్ ఏమిటి?",
    editMessage: "సందేశాన్ని సవరించండి",
    insert: "చొప్పించు",
    theNameOfCustomerInserted: "కస్టమర్ పేరు ఇక్కడ చేర్చబడుతుంది",
    theBirtdayOfferInserted: "పుట్టినరోజు ఆఫర్ ఇక్కడ చేర్చబడుతుంది",
    youSuccessfullyAddedNewBranch: "మీరు విజయవంతంగా కొత్త శాఖను జోడించారు!",
    addNewBranch: "కొత్త శాఖను జోడించండి",
    addBranch: "శాఖను జోడించండి",
    additionalBranchWillIncur: "అదనపు శాఖ ఏర్పడుతుంది",
    perBranch: "శాఖకు",
    ecommerceBranchCosts: "ఇకామర్స్ బ్రాంచ్ ఖర్చులు",
    pleaseEnterBranchName: "దయచేసి శాఖ పేరు నమోదు చేయండి",
    pleaseEnterBranchAddress1: "దయచేసి బ్రాంచ్ చిరునామా లైన్ 1 ని నమోదు చేయండి",
    enterBranchAddress1: "శాఖ చిరునామా లైన్ 1 నమోదు చేయండి",
    enterBranchAddress2: "శాఖ చిరునామా లైన్ 1 నమోదు చేయండి",
    pleaseEnterBranchAddress2: "దయచేసి బ్రాంచ్ చిరునామా లైన్ 2 ని నమోదు చేయండి",
    enterBranchName: "శాఖ పేరు నమోదు చేయండి",
    successfullyAddedStaff: "మీరు కొత్త సిబ్బందిని విజయవంతంగా చేర్చారు!",
    addNewStaff: "కొత్త సిబ్బందిని జోడించండి",
    addStaff: "సిబ్బందిని జోడించండి",
    additionalStaffWillIncur: "అదనపు సిబ్బంది నష్టపోతారు",
    perStaff: "ప్రతి సిబ్బందికి.",
    pleaseEnterStaffEmail: "దయచేసి సిబ్బంది ఇమెయిల్‌ని నమోదు చేయండి",
    pleaseEnterStaffUsername: "దయచేసి సిబ్బంది వినియోగదారు పేరును నమోదు చేయండి",
    pleaseEnterStaffPassword: "దయచేసి సిబ్బంది పాస్‌వర్డ్‌ని నమోదు చేయండి",
    pleaseSelectStaffRole: "దయచేసి సిబ్బంది పాత్రను ఎంచుకోండి",
    selectStaffRole: "సిబ్బంది పాత్రను ఎంచుకోండి",
    enterStaffEmail: "సిబ్బంది ఇమెయిల్ నమోదు చేయండి",
    enterStaffUsername: "సిబ్బంది వినియోగదారు పేరు నమోదు చేయండి",
    enterStaffPassword: "సిబ్బంది పాస్‌వర్డ్‌ని నమోదు చేయండి",
    spacesNotAllowedInUsername: "వినియోగదారు పేరులో ఖాళీలు అనుమతించబడవు",
    admin: "అడ్మిన్",
    pleaseSelectBusinessToAttachStaff:
      "దయచేసి సిబ్బందిని జోడించడానికి వ్యాపారాన్ని ఎంచుకోండి",
    searchForBranchToAttachStaff:
      "సిబ్బందిని అటాచ్ చేయడానికి శాఖ కోసం శోధించండి",
    username: "వినియోగదారు పేరు",
    role: "పాత్ర",
    areYouSureToDeleteThis: "మీరు దీన్ని ఖచ్చితంగా తొలగిస్తారా",
    branches: "శాఖలు",
    upgradeYourPlan: "మీ ప్లాన్ అప్‌గ్రేడ్ చేయండి.",
    add: "జోడించు",
    addNew: "కొత్తది జత పరచండి",
    customerWithEmailAlreadyExists:
      "ఇమెయిల్/ఫోన్ నంబర్ ఉన్న కస్టమర్ ఇప్పటికే ఉన్నారు!",
    successfullyAddedNewCustomer: "మీరు కొత్త కస్టమర్‌ని విజయవంతంగా జోడించారు!",
    addCustomer: "కస్టమర్‌ను జోడించండి",
    pleaseEnterCustomerFirstName:
      "దయచేసి కస్టమర్ యొక్క మొదటి పేరు నమోదు చేయండి",
    pleaseEnterCustomerLastName: "దయచేసి కస్టమర్ చివరి పేరు నమోదు చేయండి",
    pleaseEnterCustomerPhoneNumber: "దయచేసి కస్టమర్ ఫోన్ నంబర్ నమోదు చేయండి",
    pleaseEnterCustomerEmail: "దయచేసి కస్టమర్ ఇమెయిల్ నమోదు చేయండి",
    pleaseEnterCustomerAddressLine: "దయచేసి కస్టమర్ అడ్రస్ లైన్ ఎంటర్ చేయండి",
    pleaseEnterCustomerOtherAddress:
      "దయచేసి కస్టమర్ యొక్క ఇతర చిరునామాను నమోదు చేయండి",
    pleaseSelectCustomerGender: "దయచేసి కస్టమర్ యొక్క లింగాన్ని ఎంచుకోండి",
    gender: "లింగం",
    male: "పురుషుడు",
    female: "స్త్రీ",
    bank: "బ్యాంక్",
    selectBank: "బ్యాంక్ ఎంచుకోండి",
    stateOrRegionOrProvince: "రాష్ట్రం/ప్రాంతం/ప్రావిన్స్",
    customerNotes: "కస్టమర్ నోట్స్",
    sendSms: "SMS పంపండి",
    editCustomer: "కస్టమర్‌ను సవరించండి",
    redeemReward: "రివార్డ్‌ను రీడీమ్ చేయండి",
    issueLoyaltyCard: "లాయల్టీ కార్డు జారీ చేయండి",
    deleteCustomer: "కస్టమర్‌ను తొలగించండి",
    youveSuccessfullyAssignedLoyaltyMembership:
      "మీరు లాయల్టీ సభ్యత్వ ID ని విజయవంతంగా కేటాయించారు",
    noMembershipIdAvailable:
      "సభ్యత్వ ID లు అందుబాటులో లేవు. దయచేసి మమ్మల్ని hello@loystar.co లో సంప్రదించండి",
    sendEmail: "ఈ మెయిల్ పంపించండి",
    membershipPoints: "సభ్యత్వ పాయింట్లు",
    customerDetails: "వినియోగదారుని వివరాలు",
    close: "దగ్గరగా",
    loyaltyBalance: "విధేయత సంతులనం",
    pointsBalance: "పాయింట్ల బ్యాలెన్స్",
    starBalance: "స్టార్ బ్యాలెన్స్",
    requiredPoints: "అవసరమైన పాయింట్లు",
    requiredStars: "అవసరమైన నక్షత్రాలు",
    reddemCode: "కోడ్‌ను రీడీమ్ చేయండి",
    toDeleteThisCustomer: "ఈ కస్టమర్‌ను తొలగించడానికి",
    customerHasBeenDeletedSuccessfully: "కస్టమర్ విజయవంతంగా తొలగించబడింది!",
    customerWithPhoneAlreadyExists: "ఫోన్ నంబర్ ఉన్న కస్టమర్ ఇప్పటికే ఉన్నారు",
    customerWasSuccessfullyEdited: "కస్టమర్ విజయవంతంగా సవరించబడింది",
    anErrorOccured: "ఒక తప్పిదం సంభవించినది",
    phoneNumberIncludeCountry: "ఫోన్ నంబర్ (కంట్రీ కోడ్‌తో సహా)",
    yourFileQueuedForUpload:
      "మీ ఫైల్ అప్‌లోడ్ కోసం క్యూలో ఉంది. దయచేసి కొన్ని సెకన్ల తర్వాత పేజీని రిఫ్రెష్ చేయండి.",
    thereWasAnErrorPerformingOperation: "ఆపరేషన్ చేయడంలో లోపం ఉంది!",
    pleaseSelectFile: "దయచేసి ఒక ఫైల్‌ని ఎంచుకోండి!",
    oopsOnlyCsvFilesAllowed:
      "అయ్యో! CSV ఫైల్‌లు మాత్రమే అనుమతించబడతాయి. దయచేసి .csv ఫైల్‌ను అప్‌లోడ్ చేయండి.",
    fileShouldBeSmallerThan5Mb:
      "ఫైల్ 5MB కంటే చిన్నదిగా ఉండాలి. మీకు పెద్ద ఫైల్ ఉంటే, దయచేసి support@loystar.co కి ఇమెయిల్ చేయండి. ధన్యవాదాలు",
    customerFirstNameIsRequired: "వరుసలో కస్టమర్ మొదటి పేరు అవసరం",
    customerPhoneNumberIsRequired: "వరుసలో కస్టమర్ ఫోన్ నంబర్ అవసరం",
    importCustomers: "దిగుమతి కస్టమర్‌లు",
    upload: "అప్‌లోడ్ చేయండి",
    clickIconToDownloadCsv:
      "CSV ఫైల్ టెంప్లేట్ డౌన్‌లోడ్ చేయడానికి ఈ చిహ్నాన్ని క్లిక్ చేయండి.",
    getGoogleSheetVersion: "Google షీట్ వెర్షన్ పొందండి",
    clickOrDragFileToUpload:
      "అప్‌లోడ్ చేయడానికి ఈ ప్రాంతానికి ఫైల్‌ని క్లిక్ చేయండి లేదా లాగండి",
    missingOrInvalidColumnHeader:
      "కాలమ్ హెడర్ లేదు లేదా చెల్లదు. దయచేసి టెంప్లేట్ ఆకృతిని అనుసరించండి. ధన్యవాదాలు.",
    youHaveImported: "మీరు దిగుమతి చేసుకున్నారు",
    youSuccessfullyRedeemedReward:
      "మీరు మీ రివార్డ్‌ను విజయవంతంగా రీడీమ్ చేసారు!",
    sixDigitCode: "ఆరు అంకెల కోడ్",
    pleaseEnterCustomerRewardCode:
      "దయచేసి కస్టమర్ రివార్డ్ కోడ్‌ని నమోదు చేయండి",
    enterRewardCode:
      "రివార్డ్ కోడ్ నమోదు చేయండి. (రివార్డ్ కోడ్ కేస్ సెన్సిటివ్)",
    selectLoyaltyProgram: "లాయల్టీ ప్రోగ్రామ్‌ని ఎంచుకోండి",
    stamps: "స్టాంపులు",
    smsUnitsLow: "SMS యూనిట్లు తక్కువ",
    pleaseTopUpSmsUnits: "దయచేసి SMS యూనిట్లను టాప్ అప్ చేయండి",
    smsSuccessfullySentForDelivery: "డెలివరీ కోసం SMS విజయవంతంగా పంపబడింది!",
    sendSmsTo: "కు SMS పంపండి",
    allCustomers: "కస్టమర్లందరూ",
    unitsAvailable: "యూనిట్లు అందుబాటులో ఉన్నాయి",
    pleaseTypeInTheMessage: "దయచేసి సందేశాన్ని టైప్ చేయండి",
    message: "సందేశం",
    charactersRemaining: "అక్షరాలు మిగిలి ఉన్నాయి",
    avoidUseOfSpecialCharacters:
      "SMS యూనిట్‌లను సంరక్షించడానికి ప్రత్యేక అక్షరాలు మరియు ఎమోజీలను ఉపయోగించడం మానుకోండి.",
    note: "గమనిక",
    errorFetchingCustomersMultilevelDetail:
      "కస్టమర్‌ల బహుళస్థాయి వివరాలను పొందడంలో లోపం",
    search: "వెతకండి",
    reset: "రీసెట్ చేయండి",
    importCustomer: "దిగుమతి కస్టమర్",
    addNewCustomer: "కొత్త కస్టమర్‌ను జోడించండి",
    sendSmsBroadcast: "SMS బ్రాడ్‌కాస్ట్ పంపండి",
    totalCustomers: "మొత్తం వినియోగదారులు",
    disbursementDetails: "పంపిణీ వివరాలు",
    paidBy: "ద్వారా చెల్లించబడింది",
    disbursed: "పంపిణీ చేయబడింది",
    bankAccountName: "బ్యాంక్ ఖాతా పేరు",
    bankAccountNumber: "బ్యాంకు ఖాతా సంఖ్య",
    transferInitiated: "బదిలీ ప్రారంభించబడింది",
    transferCompleted: "బదిలీ పూర్తయింది",
    pleaseEnterAValid: "దయచేసి చెల్లుబాటు అయ్యేదాన్ని నమోదు చేయండి",
    begin: "ప్రారంభించండి",
    end: "ముగింపు",
    date: "తేదీ",
    paymentDate: "చెల్లింపు తేదీ",
    selectDisbursementDuration: "పంపిణీ వ్యవధిని ఎంచుకోండి",
    totalSettled: "మొత్తం స్థిరపడింది",
    totalUnsettled: "మొత్తం స్థిరపడలేదు",
    toDeleteThisSale: "ఈ అమ్మకాన్ని తొలగించడానికి",
    draftSaleDeletedSuccessfully: "డ్రాఫ్ట్ అమ్మకం విజయవంతంగా తొలగించబడింది!",
    deleteSale: "అమ్మకాన్ని తొలగించండి",
    pleaseTypeInYourTotal:
      "తొలగింపును నిర్ధారించడానికి దయచేసి మీ మొత్తాన్ని టైప్ చేయండి",
    billDetails: "బిల్లు వివరాలు",
    printBill: "ముద్రణ బిల్లు",
    servedBy: "ద్వారా అందించబడింది",
    total: "మొత్తం",
    createdDate: "సృష్టించిన తేదీ",
    createdTime: "సృష్టించబడిన సమయం",
    status: "స్థితి",
    loginSuccessful: "లాగిన్ విజయవంతమైంది",
    pleaseWaitWhileWeConnectAccount:
      "మేము మీ ఖాతాను కనెక్ట్ చేసే వరకు దయచేసి వేచి ఉండండి",
    connectionFailedTryAgain: "సంధానము విఫలమైనది. దయచేసి మళ్లీ ప్రయత్నించండి.",
    connectionSuccessful: "కనెక్షన్ విజయవంతమైంది",
    viewDetails: "వివరాలను వీక్షించండి",
    enable: "ప్రారంభించు",
    free: "ఉచిత",
    cost: "ఖరీదు",
    visitWebsite: "వెబ్‌సైట్‌ను సందర్శించండి",
    pleaseUpgradeYourPlanToPro:
      "దయచేసి ఈ యాప్‌ను ప్రారంభించడానికి మీ ప్లాన్‌ను ప్రో ప్లస్‌కి అప్‌గ్రేడ్ చేయండి",
    connectYourBankAccount:
      "చెల్లింపులను స్వీకరించడానికి మీ బ్యాంక్ ఖాతాను కనెక్ట్ చేయండి.",
    disable: "డిసేబుల్",
    enableApp: "యాప్‌ను ఎనేబుల్ చేయండి",
    addNewProductToInvoice: "ఇన్‌వాయిస్‌కి కొత్త ఉత్పత్తిని జోడించండి",
    toDeleteThisInvoice: "ఈ ఇన్‌వాయిస్‌ని తొలగించడానికి",
    invoiceDeletedSuccessfully: "ఇన్‌వాయిస్ విజయవంతంగా తొలగించబడింది!",
    deleteInvoice: "ఇన్‌వాయిస్‌ని తొలగించండి",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "మీరు విజయవంతంగా ఇన్‌వాయిస్ చెల్లింపు సందేశాన్ని జోడించారు!",
    pleaseEnterPaymentInstructions: "దయచేసి చెల్లింపు సూచనలను నమోదు చేయండి",
    invoiceId: "ఇన్వాయిస్ ID",
    paidDate: "చెల్లించిన తేదీ",
    reference: "సూచన",
    productAdded: "ఉత్పత్తి జోడించబడింది",
    productOutOfStock: "ఉత్పత్తి అయిపోయింది. దయచేసి తిరిగి నిల్వ చేయండి.",
    totalInvoices: "మొత్తం ఇన్వాయిస్‌లు",
    totalPaidInvoices: "మొత్తం చెల్లింపు ఇన్వాయిస్‌లు",
    totalUnpaidInvoices: "మొత్తం UNPAID ఇన్వాయిస్‌లు",
    loyaltyProgramDeleted: "విధేయత కార్యక్రమం తొలగించబడింది",
    thisLoyaltyProgramDeletedSuccessfully:
      "ఈ విధేయత కార్యక్రమం విజయవంతంగా తొలగించబడింది",
    thisLoyaltyProgramEditedSuccessfully:
      "ఈ విధేయత కార్యక్రమం విజయవంతంగా సవరించబడింది",
    loyaltyProgramAddedSuccessfully:
      "విధేయత కార్యక్రమం విజయవంతంగా జోడించబడింది",
    loyaltyProgramEdited: "విధేయత కార్యక్రమం సవరించబడింది",
    loyaltyProgramAdded: "విధేయత కార్యక్రమం జోడించబడింది",
    loyaltyDetails: "విధేయత వివరాలు",
    doYouWantToCloseDialog: "మీరు ఈ డైలాగ్‌ను మూసివేయాలనుకుంటున్నారా?",
    pleaseEnterLoyaltyName: "దయచేసి మీ విధేయత పేరును నమోదు చేయండి",
    programType: "ప్రోగ్రామ్ రకం",
    pleaseSelectProgramType: "దయచేసి ప్రోగ్రామ్ రకాన్ని ఎంచుకోండి",
    simplePoints: "సాధారణ పాయింట్లు",
    stampsProgram: "స్టాంపుల కార్యక్రమం",
    threshold: "త్రెషోల్డ్",
    pleaseEnterLoyaltyThreshold: "దయచేసి విధేయత ప్రవేశాన్ని నమోదు చేయండి",
    reward: "రివార్డ్",
    pleaseEnterLoyaltyReward: "దయచేసి విధేయత యొక్క బహుమతిని నమోదు చేయండి",
    totalUserPoints: "మొత్తం వినియోగదారు పాయింట్లు",
    totalUserStamps: "మొత్తం యూజర్ స్టాంపులు",
    spendingTarget: "ఖర్చు లక్ష్యం",
    spendingTargetHint1:
      "కస్టమర్ రివార్డ్ సంపాదించడానికి ఎంత ఖర్చు చేయాలనేది ఖర్చు లక్ష్యం. 1 కరెన్సీ విలువ = 1 పాయింట్.",
    spendingTargetHint2:
      "రివార్డ్ సంపాదించడానికి కస్టమర్ ఎన్ని స్టాంప్‌లను సేకరించాలి అనేది స్టాంపుల లక్ష్యం. ఉదా. 5",
    addNewLoyaltyProgram: "కొత్త లాయల్టీ ప్రోగ్రామ్‌ను జోడించండి",
    addLoyaltyProgram: "విధేయత కార్యక్రమాన్ని జోడించండి",
    loyaltyProgramType: "విధేయత కార్యక్రమం రకం",
    pleaseSelectLoyaltyProgramType:
      "దయచేసి లాయల్టీ ప్రోగ్రామ్ రకాన్ని ఎంచుకోండి",
    nameOfProgram: "కార్యక్రమం పేరు",
    pleaseEnterProgramName: "దయచేసి ప్రోగ్రామ్ పేరు నమోదు చేయండి",
    whatIsTheReward: "బహుమతి ఏమిటి?",
    egNextPurchaseIsFree: "ఉదా. తదుపరి కొనుగోలు ఉచితం",
    customerName: "వినియోగదారుని పేరు",
    guestCustomer: "అతిథి కస్టమర్",
    orderConfirmedSuccessfully: "ఆర్డర్ విజయవంతంగా నిర్ధారించబడింది",
    orderCancelledSuccessfully: "ఆర్డర్ విజయవంతంగా రద్దు చేయబడింది",
    confirmOrder: "క్రమాన్ని నిర్ధారించండి",
    cancelOrder: "ఆర్డర్‌ని రద్దు చేయండి",
    allOrders: "అన్ని ఆర్డర్లు",
    totalOrders: "మొత్తం ఆర్డర్లు",
    doYouWantToAcceptOrder: "మీరు ఈ ఆర్డర్‌ని ఆమోదించాలనుకుంటున్నారా?",
    doYouWantToCancelOrder: "మీరు ఈ ఆర్డర్‌ని రద్దు చేయాలనుకుంటున్నారా?",
    orderDetails: "ఆర్డర్ వివరాలు",
    orderCreatedAt: "వద్ద ఆర్డర్ సృష్టించబడింది",
    supplier: "సరఫరాదారు",
    productName: "ఉత్పత్తి పేరు",
    quantity: "పరిమాణం",
    unitPrice: "యూనిట్ ధర",
    receivedBy: "అందుకున్నవారు",
    reportFrom: "నుండి నివేదిక",
    totalSupplies: "మొత్తం సరఫరా",
    allRightsReserved: "అన్ని హక్కులూ ప్రత్యేకించబడ్డాయి",
    toDeleteThisTransaction: "ఈ లావాదేవీని తొలగించడానికి",
    transactionDeletedSuccessfully:
      "లావాదేవీ విజయవంతంగా తొలగించబడింది. స్టాక్ జాబితాకు తిరిగి ఇవ్వబడింది.",
    deleteTransaction: "లావాదేవీని తొలగించండి",
    transactionDetails: "లావాదేవీ వివరాలు",
    printReceipt: "ప్రింట్ రసీదు",
    channel: "ఛానల్",
    discount: "డిస్కౌంట్",
    profit: "లాభం",
    discountedSales: "రాయితీ అమ్మకాలు",
    errorFetchingRecord: "రికార్డ్ పొందడంలో లోపం",
    exportTransactions: "ఎగుమతి లావాదేవీలు",
    errorFetchingSalesRecord: "ఎగుమతి కోసం విక్రయాల రికార్డును పొందడంలో లోపం.",
    totalSellingPrice: "మొత్తం విక్రయ ధర",
    totalCostPrice: "మొత్తం ధర ధర",
    appliedDiscount: "వర్తించే తగ్గింపు",
    noOfItems: "అంశాల సంఖ్య",
    sales: "అమ్మకాలు",
    export: "ఎగుమతి",
    totalProfit: "మొత్తం లాభం",
    totalBalanceInPeriod: "పీరియడ్‌లో మొత్తం బ్యాలెన్స్",
    allTimeSales: "ఆల్ టైమ్ సేల్స్",
    records: "రికార్డులు",
    todaysSales: "నేటి అమ్మకాలు",
    transaction: "లావాదేవీ",
    youHaveExceededTotalNumberOfProducts:
      "మీ ప్లాన్‌లో అనుమతించబడిన మొత్తం ఉత్పత్తుల సంఖ్యను మీరు అధిగమించారు. అధిక పరిమితిని ఆస్వాదించడానికి మీ ప్లాన్‌ను అప్‌గ్రేడ్ చేయండి.",
    youNeedToHaveLoyaltyProgram:
      "ఈ ఫీచర్‌ని ఉపయోగించడానికి మీరు లాయల్టీ ప్రోగ్రామ్‌ని కలిగి ఉండాలి!",
    price: "ధర",
    category: "వర్గం",
    stockTracking: "స్టాక్ ట్రాకింగ్",
    stockCount: "స్టాక్ కౌంట్",
    taxed: "పన్ను విధించబడింది",
    originalPrice: "అసలు ధర",
    costPrice: "ఖర్చు ధర",
    unit: "యూనిట్",
    productImage: "ఉత్పత్తి చిత్రం",
    taxRate: "పన్ను శాతమ్",
    taxType: "పన్ను రకం",
    trackInventory: "ట్రాక్ ఇన్వెంటరీ",
    variants: "వైవిధ్యాలు",
    hasVariants: "వైవిధ్యాలు ఉన్నాయి",
    importProduct: "దిగుమతి ఉత్పత్తి",
    exportProducts: "ఎగుమతి ఉత్పత్తులు",
    addNewProduct: "కొత్త ఉత్పత్తిని జోడించండి",
    viewCategory: "వర్గాన్ని వీక్షించండి",
    viewSuppliers: "సరఫరాదారులను వీక్షించండి",
    receiveInventory: "ఇన్వెంటరీని స్వీకరించండి",
    printAllProductsTag: "అన్ని ఉత్పత్తుల ట్యాగ్‌ను ముద్రించండి",
    deleteAll: "అన్నిటిని తొలిగించు",
    totalProducts: "మొత్తం ఉత్పత్తులు",
    youveSuccessfullyAddedANewCategory:
      "మీరు విజయవంతంగా కొత్త వర్గాన్ని జోడించారు",
    addNewCategory: "కొత్త వర్గాన్ని జోడించండి",
    addCategory: "వర్గాన్ని జోడించండి",
    categoryName: "వర్గం పేరు",
    pleaseEnterCategoryName: "దయచేసి వర్గం పేరు నమోదు చేయండి",
    stampsTarget: "స్టాంపుల లక్ష్యం",
    sendInventory: "ఇన్వెంటరీ పంపండి",
    productDetails: "వస్తువు యొక్క వివరాలు",
    youveSuccessfullyEditedThisCategory:
      "మీరు ఈ వర్గాన్ని విజయవంతంగా సవరించారు",
    update: "అప్‌డేట్",
    categoryList: "వర్గం జాబితా",
    categories: "కేటగిరీలు",
    enterQuantityToUpdate: "అప్‌డేట్ చేయడానికి పరిమాణాన్ని నమోదు చేయండి",
    inventorySentSuccessfully: "జాబితా విజయవంతంగా పంపబడింది!",
    updateInventory: "నవీకరణ జాబితా",
    currentQuantity: "ప్రస్తుత పరిమాణం",
    pleaseEnterQuantityToAdd:
      "దయచేసి మీరు జోడించదలిచిన పరిమాణాన్ని నమోదు చేయండి",
    pleaseSelectABranch: "దయచేసి ఒక బ్రాంచ్‌ని ఎంచుకోండి",
    searchForBranch: "శాఖ కోసం శోధించండి",
    youCantSendMoreThanStock: "మీరు స్టాక్‌లో ఉన్నదానికంటే ఎక్కువ పంపలేరు",
    send: "పంపు",
    pleaseEnterQuantityToSend:
      "దయచేసి మీరు పంపాలనుకుంటున్న పరిమాణాన్ని నమోదు చేయండి",
    productNameIsRequiredOnRow: "వరుసలో ఉత్పత్తి పేరు అవసరం",
    productCategoryIsRequiredOnRow: "వరుసలో ఉత్పత్తి వర్గం అవసరం",
    productPriceIsRequiredOnRow: "వరుసలో ఉత్పత్తి ధర అవసరం",
    productUnitIsRequiredOnRow: "వరుసలో ఉత్పత్తి యూనిట్ అవసరం",
    productQuantityIsRequiredOnRow: "వరుసలో ఉత్పత్తి పరిమాణం అవసరం",
    productVariantsRequireTracking: "ఉత్పత్తి వేరియంట్‌లకు ట్రాకింగ్ అవసరం!",
    pleaseAddVariantClickButton:
      "దయచేసి యాడ్ బటన్ క్లిక్ చేయడం ద్వారా వేరియంట్‌ను జోడించండి!",
    totalVariantsMoreThanSelectedQuantity:
      "ఎంచుకున్న ఉత్పత్తి పరిమాణం కంటే మొత్తం వైవిధ్యాలు ఎక్కువ, Pls వేరియంట్ పరిమాణాన్ని తగ్గిస్తాయి",
    productEditedSuccessfully: "ఉత్పత్తి విజయవంతంగా సవరించబడింది!",
    fileTooLargeLessThan4Mb:
      "ఫైల్ పరిమాణం చాలా పెద్దది! ఫైల్ పరిమాణం 4MB కంటే తక్కువగా ఉండాలి.",
    suchVariantAlreadyExist: "అలాంటి వేరియంట్ ఇప్పటికే ఉంది",
    addVariants: "వైవిధ్యాలను జోడించండి",
    editProduct: "ఉత్పత్తిని సవరించండి",
    pleaseEnterProductName: "దయచేసి ఉత్పత్తి పేరు నమోదు చేయండి",
    pleaseEnterProductPrice: "దయచేసి ఉత్పత్తి ధరను నమోదు చేయండి",
    pleaseEnterProductOriginalPrice: "దయచేసి ఉత్పత్తి అసలు ధరను నమోదు చేయండి",
    productDescription: "ఉత్పత్తి వివరణ",
    selectProductCategory: "ఉత్పత్తి వర్గాన్ని ఎంచుకోండి",
    pleaseSelectProductCategory: "దయచేసి ఉత్పత్తి వర్గాన్ని ఎంచుకోండి",
    productCostPrice: "ఉత్పత్తి ధర ధర",
    productSellingPrice: "ఉత్పత్తి విక్రయ ధర",
    productOriginalPrice: "ఉత్పత్తి అసలు ధర",
    maxFileSizeAllowedIs4Mb: "గరిష్ట అనుమతించబడిన ఫైల్ పరిమాణం 4 ఎంబి",
    productsWithPicturesArePublished:
      "ఆర్డర్‌లను స్వీకరించడానికి డిస్కవర్‌లో చిత్రాలతో ఉత్పత్తులు ప్రచురించబడ్డాయి",
    shouldThisProductBeTracked: "ఈ ఉత్పత్తిని ట్రాక్ చేయాలా?",
    pleaseSelectStockUnit: "దయచేసి స్టాక్ యూనిట్‌ను ఎంచుకోండి",
    stockUnit: "స్టాక్ యూనిట్",
    bag: "BAG",
    bottle: "సీసా",
    bunch: "గుత్తి",
    can: "CAN",
    carton: "కార్టన్",
    crate: "గాజు సీసాలు రవాణా చేసేందుకు ఉపయోగించే పెట్టె",
    cup: "CUP",
    dozen: "డజన్",
    gigabytes: "గిగాబైట్స్",
    gram: "గ్రామ్",
    kilogram: "కిలోగ్రామ్",
    litre: "LITER",
    pack: "ప్యాక్",
    pair: "PAIR",
    pieces: "పైస్",
    plate: "ప్లేట్",
    tonne: "టోన్ (MT)",
    uNIT: "UNIT",
    yard: "యార్డ్",
    pleaseEnterProductQuantity: "దయచేసి ఉత్పత్తి పరిమాణాన్ని నమోదు చేయండి",
    productQuantity: "ఉత్పత్తి పరిమాణం",
    isThisProductTaxed: "ఈ ఉత్పత్తిపై పన్ను విధించబడిందా?",
    selectTaxType: "పన్ను రకాన్ని ఎంచుకోండి",
    pleaseSelectTaxType: "దయచేసి పన్ను రకాన్ని ఎంచుకోండి",
    progressive: "ప్రగతిశీల",
    proportional: "అనుపాతంలో",
    pleaseEnterProductTaxRate: "దయచేసి ఉత్పత్తి పన్ను రేటును నమోదు చేయండి",
    doesProductHaveVariants: "ఈ ఉత్పత్తికి వైవిధ్యాలు ఉన్నాయా?",
    type: "టైప్ చేయండి",
    value: "విలువ",
    pleaseEnterVariantType: "దయచేసి వేరియంట్ రకాన్ని నమోదు చేయండి",
    pleaseEnterVariantValue: "దయచేసి వేరియంట్ విలువను నమోదు చేయండి",
    pleaseEnterVariantPrice: "దయచేసి వేరియంట్ ధరను నమోదు చేయండి",
    pleaseEnterVariantQuantity: "దయచేసి వేరియంట్ పరిమాణాన్ని నమోదు చేయండి",
    productDeletedSuccessfully: "ఉత్పత్తి విజయవంతంగా తొలగించబడింది!",
    categoryDeletedSuccessfully: "వర్గం విజయవంతంగా తొలగించబడింది!",
    toDeleteThisProduct: "ఈ ఉత్పత్తిని తొలగించడానికి",
    invalidProductQuantity: "చెల్లని ఉత్పత్తి పరిమాణం",
    quantityAddedIsOverStock:
      "మీరు జోడించే పరిమాణం మీ స్టాక్‌లో ఉన్నదానికంటే ఎక్కువ.",
    itemInventoryNotTracked: "అంశం జాబితా ట్రాక్ చేయబడలేదు",
    addBulkQuantity: "బల్క్ క్వాంటిటీని జోడించండి",
    enterBulkQuantity: "బల్క్ క్వాంటిటీని నమోదు చేయండి",
    pleaseEnterBulkQuantity: "దయచేసి బల్క్ క్వాంటిటీని నమోదు చేయండి",
    youveSuccessfullyAddedANewProduct:
      "మీరు విజయవంతంగా కొత్త ఉత్పత్తిని జోడించారు!",
    pleaseEnterProductSellingPrice: "దయచేసి ఉత్పత్తి విక్రయ ధరను నమోదు చేయండి",
    doYouWantToTrackProductStock:
      "మీరు ఉత్పత్తి స్టాక్‌ను ట్రాక్ చేయాలనుకుంటున్నారా?",
    sellingPrice: "విక్రయ ధర",
    setProductExpiryReminder: "ఉత్పత్తి గడువు రిమైండర్‌ను సెట్ చేయండి",
    productExpiryDate: "ఉత్పత్తి గడువు తేదీ",
    startRemindingFrom: "నుండి రిమైండ్ చేయడం ప్రారంభించండి",
    productSuppliesAddedSuccessfully:
      "మీరు విజయవంతంగా ఉత్పత్తి సరఫరాలను జోడించారు.",
    addProductSupplies: "ఉత్పత్తి సామాగ్రిని జోడించండి",
    pleaseSelectSupplier: "దయచేసి సరఫరాదారుని ఎంచుకోండి",
    nameOfProduct: "ఉత్పత్తి పేరు",
    pleaseSelectProduct: "దయచేసి ఉత్పత్తిని ఎంచుకోండి",
    productVariant: "ఉత్పత్తి వైవిధ్యం",
    pleaseSelectAVariant: "దయచేసి ఒక వైవిధ్యాన్ని ఎంచుకోండి",
    pleaseEnterUnitPrice: "దయచేసి యూనిట్ ధరను నమోదు చేయండి",
    businessBranch: "వ్యాపార శాఖ",
    pleaseSelectBranch: "దయచేసి శాఖను ఎంచుకోండి",
    youveSuccessfullyAddedANewSupplier:
      "మీరు కొత్త సరఫరాదారుని విజయవంతంగా జోడించారు",
    addSupplier: "సరఫరాదారుని జోడించండి",
    pleaseEnterSupplierEmail: "దయచేసి సరఫరాదారు ఇమెయిల్ నమోదు చేయండి",
    pleaseAddADescription: "దయచేసి వివరణను జోడించండి",
    anErrorOccuredProductsDeleted:
      "ఆపరేషన్ చేస్తున్నప్పుడు లోపం సంభవించింది. దయచేసి గమనించండి, ఈ ప్రక్రియలో కొన్ని ఉత్పత్తులు తొలగించబడి ఉండవచ్చు",
    bulkDelete: "బల్క్ డిలీట్",
    youAreAboutToDelete: "మీరు తొలగించబోతున్నారు",
    product: "ఉత్పత్తి",
    isDueToRedeem: "విమోచన కారణంగా ఉంది",
    aReward: "ఒక బహుమతి",
    pleaseSelectCustomerToReeemReward:
      "రివార్డ్‌ను రీడీమ్ చేయడానికి దయచేసి కస్టమర్‌ని ఎంచుకోండి.",
    youHaveNoLoyaltyProgramRunning:
      "మీకు యాక్టివ్ లాయల్టీ ప్రోగ్రామ్ అమలు కావడం లేదు",
    customerHhasNoPointsInLoyaltyProgram:
      "ఈ విధేయత కార్యక్రమంలో కస్టమర్‌కు పాయింట్‌లు లేవు",
    proceedWithPayment: "చెల్లింపుతో కొనసాగాలా?",
    youAreAboutToPayForTransactionUsingPoints:
      "మీరు పాయింట్లను ఉపయోగించి లావాదేవీకి చెల్లించాల్సి ఉంటుంది.",
    customerHas: "కస్టమర్ కలిగి ఉంది",
    worth: "విలువ",
    andIsNotSufficientToPayForTransaction:
      "మరియు ఈ లావాదేవీకి చెల్లించడానికి సరిపోదు. వారు మరొక చెల్లింపు పద్ధతిని ఉపయోగించి బ్యాలెన్స్‌ని జోడించాలనుకుంటున్నారా?",
    addCustomerLoyalty: "కస్టమర్ విధేయతను జోడించండి",
    pleaseAddCustomerFirst:
      "దయచేసి ముందుగా కస్టమర్‌ని జోడించండి లేదా ఎంచుకోండి.",
    pleaseWaitWhileWeGetReady: "మేము సిద్ధంగా ఉన్నప్పుడు దయచేసి వేచి ఉండండి",
    lowStock: "సరుకు తక్కువ",
    pleaseEnterAmountTendered: "దయచేసి టెండర్ చేయబడిన మొత్తాన్ని నమోదు చేయండి",
    areYouSureToBookSaleOffline:
      "మీరు ఖచ్చితంగా ఈ అమ్మకాన్ని ఆఫ్‌లైన్‌లో బుక్ చేయాలనుకుంటున్నారా?",
    saleWouldBeBookedAutomatically:
      "మీరు మీ ఇంటర్నెట్‌ని ఆన్ చేసినప్పుడు అమ్మకం ఆటోమేటిక్‌గా బుక్ చేయబడుతుంది",
    offlineSalesBookingCancelled: "ఆఫ్‌లైన్ సేల్స్ బుకింగ్ రద్దు చేయబడింది",
    covid19Message:
      "COVID19: వ్యాప్తిని ఆపడానికి మీ చేతులను సబ్బుతో కడగండి లేదా శానిటైజ్ చేయండి. ఎల్లప్పుడూ సురక్షితంగా ఉండండి",
    saleSuccessfullyRecorded: "అమ్మకం విజయవంతంగా రికార్డ్ చేయబడింది!",
    sendReceiptToEmail: "ఇమెయిల్‌కు రసీదు పంపండి",
    sendThankYouSms: "ధన్యవాదాలు SMS పంపండి",
    sendShippingDetails: "షిప్పింగ్ వివరాలను పంపండి",
    addLoyalty: "విధేయతను జోడించండి",
    searchCustomerNameOrNumber: "కస్టమర్ పేరు లేదా నంబర్‌ని వెతకండి",
    clickTheSearchCustomerBox:
      "సెర్చ్ కస్టమర్ బాక్స్ మరియు స్కాన్ కార్డ్ క్లిక్ చేయండి",
    enterProductPrice: "ఉత్పత్తి ధరను నమోదు చేయండి",
    enterPriceFor: "ధరను నమోదు చేయండి",
    searchForProduct: "ఉత్పత్తి కోసం శోధించండి",
    all: "అన్ని",
    backToDashboard: "తిరిగి డాష్‌బోర్డ్‌కు",
    viewDraftSales: "డ్రాఫ్ట్ అమ్మకాలను వీక్షించండి",
    variantSelected: "వేరియంట్ ఎంపిక చేయబడింది",
    variant: "వేరియంట్",
    thePreviousVariantSelectionNotAvailable:
      "ధర ఆధారంగా ఎంచుకున్న కొత్త వేరియంట్‌కు మునుపటి వేరియంట్ ఎంపిక అందుబాటులో లేదు, దయచేసి మీ ఎంపికను మార్చండి.",
    pleaseSupplyPositiveQuantityNumber:
      "దయచేసి అనుకూల పరిమాణ సంఖ్యను అందించండి",
    lowStockFor: "కోసం తక్కువ స్టాక్",
    clearVariants: "స్పష్టమైన వైవిధ్యాలు",
    pleaseEnterQuantity: "దయచేసి పరిమాణాన్ని నమోదు చేయండి",
    picture: "చిత్రం",
    redemptionToken: "విమోచన టోకెన్",
    token: "టోకెన్",
    totalSpent: "మొత్తం ఖర్చు",
    confirmPayment: "చెల్లింపును నిర్ధారించండి",
    hasPaymentBeenMade: "చెల్లింపు విజయవంతంగా ప్రాసెస్ చేయబడిందా?",
    enterTransactionReference: "మీరు చెల్లించిన లావాదేవీ సూచనను నమోదు చేయండి",
    pleaseSelectACustomer: "దయచేసి కస్టమర్‌ను ఎంచుకోండి!",
    areYouSureToApplyDiscount:
      "మీరు ఖచ్చితంగా డిస్కౌంట్ వర్తింపజేయాలనుకుంటున్నారా?",
    addYourBankAccountToEnableUssd:
      "UPay ద్వారా తక్షణ USSD బదిలీని ప్రారంభించడానికి మీ బ్యాంక్ ఖాతాను జోడించండి",
    totalAmountToPay: "చెల్లించాల్సిన మొత్తం మొత్తం",
    doYouWantToCancelTransaction: "మీరు ఈ లావాదేవీని రద్దు చేయాలనుకుంటున్నారా?",
    savePrintBill: "బిల్లును సేవ్/ప్రింట్ చేయండి",
    enterAmountCollectedForCustomer:
      "కస్టమర్ కోసం సేకరించిన మొత్తాన్ని నమోదు చేయండి",
    recordSale: "రికార్డు అమ్మకం",
    checkOutWith: "తో తనిఖీ చేయండి",
    instantTransfer: "తక్షణ బదిలీ",
    dialTheUSSDCode: "USSD కోడ్‌ని డయల్ చేయండి",
    pleaseSelectABank: "దయచేసి బ్యాంకును ఎంచుకోండి",
    payWithUSSD: "USSD తో చెల్లించండి",
    sendBillTo: " - బిల్లును పంపండి",
    waitingForUSSDTransfer: "USSD బదిలీ కోసం వేచి ఉంది",
    percent: "శాతం",
    applyDiscount: "డిస్కౌంట్ వర్తించు",
    thisBillHasBeenSaved: "ఈ బిల్లు సేవ్ చేయబడింది",
    saveDraft: "రాసినది భద్రపరచు",
    pleaseTypeANameToIdentifyCustomer:
      "కస్టమర్‌ను గుర్తించడానికి దయచేసి పేరును టైప్ చేయండి",
    paymentDetails: "చెల్లింపు వివరాలు",
    basePrice: "మూల ధర",
    staff: "సిబ్బంది",
    subTotal: "ఉప మొత్తం",
    durationMonths: "వ్యవధి (నెలలు)",
    selectADuration: "వ్యవధిని ఎంచుకోండి",
    oneMonth: "1 నెల",
    twoMonths: "2 నెలల",
    threeMonths: "3 నెలలు",
    sixMonths: "6 నెలల",
    twelveMonths: "12 నెలలు",
    eighteenMonths: "18 నెలలు",
    twentyFourMonths: "24 నెలలు",
    twoMonthsFree: "(2 నెలలు ఉచితం)",
    threeMonthsFree: "(3 నెలలు ఉచితం)",
    fiveMonthsFree: "(5 నెలలు ఉచితం)",
    yourAccountHasBeen: "మీ ఖాతా ఉంది",
    renewed: "పునరుద్ధరించబడింది",
    upgraded: "అప్‌గ్రేడ్ చేయబడింది",
    successfully: "విజయవంతంగా",
    yourSubscription: "మీ చందా",
    youAreCurrentlyEnrolledOnA: "మీరు ప్రస్తుతం a లో నమోదు చేయబడ్డారు",
    pleaseChooseAPaymentOption: "దయచేసి చెల్లింపు ఎంపికను ఎంచుకోండి",
    planRenewal: "ప్రణాళిక పునరుద్ధరణ",
    planUpgrade: "ప్లాన్ అప్‌గ్రేడ్",
    pleaseSelectDurationToPayFor:
      "దయచేసి మీరు చెల్లించాలనుకుంటున్న వ్యవధిని ఎంచుకోండి",
    staffAccounts: "సిబ్బంది ఖాతాలు",
    ecommerce: "ఇకామర్స్",
    pleaseSelectAPlan: "దయచేసి ప్రణాళికను ఎంచుకోండి",
    includeAddons: "యాడ్-ఆన్‌లను చేర్చండి",
    viewTransactions: "లావాదేవీలను వీక్షించండి",
    customerHasNoCompletedTansactions:
      "కస్టమర్‌కు ఇంకా పూర్తి చేసిన టాన్సాక్షన్‌లు లేవు",
    branch: "శాఖ",
    enterNumberOfEcommerceBranches: "ఇకామర్స్ శాఖల సంఖ్యను నమోదు చేయండి",
    enterNumberOfEcommerceBranchesToPay:
      "మీరు చెల్లించాలనుకుంటున్న ఈకామర్స్ బ్రాంచ్‌ల సంఖ్యను నమోదు చేయండి",
    ecommerceIntegration: "ఇకామర్స్ ఇంటిగ్రేషన్",
    enterNumberOfBranches: "శాఖల సంఖ్యను నమోదు చేయండి",
    enterNumberOfAdditionalBranchesToPay:
      "మీరు చెల్లించడానికి ఉద్దేశించిన అదనపు శాఖల సంఖ్యను నమోదు చేయండి",
    enterNumberOfStaffs: "సిబ్బంది సంఖ్యను నమోదు చేయండి",
    enterNumberOfStaffsToPayFor:
      "మీరు చెల్లించడానికి ఉద్దేశించిన సిబ్బంది సంఖ్యను నమోదు చేయండి",
    discountApplies: "డిస్కౌంట్ వర్తిస్తుంది",
    starsOnThe: "న నక్షత్రాలు",
    offer: "ఆఫర్",
    get: "పొందండి",
    moreStarsToRedeem: "రీడీమ్ చేయడానికి మరిన్ని నక్షత్రాలు",
    taxVat: "పన్ను (VAT)",
    callCashierToCompletePayment:
      "చెల్లింపును పూర్తి చేయడానికి క్యాషియర్‌ని కాల్ చేయండి",
    receipt: "రసీదు",
    dear: "ప్రియమైన",
    thankYouForYourOrderFindGoods:
      "మీ ఆర్డర్ కి ధన్యవాదాలు. దయచేసి అంగీకరించిన విధంగా సరఫరా చేయబడిన కింది వస్తువులను కనుగొనండి.",
    shippingNote: "షిప్పింగ్ నోట్",
    enterShippingNote: "షిప్పింగ్ నోట్ నమోదు చేయండి",
    shippingAddress: "షిప్పింగ్ చిరునామా",
    enterShippingAddress: "షిప్పింగ్ చిరునామాను నమోదు చేయండి",
    wellDoneYouAreDueToRedeem: "బాగా చేసారు! మీరు విమోచన కారణంగా ఉన్నారు",
    toGetYourRewardNextVisit:
      "మీ తదుపరి సందర్శనలో మీ రివార్డ్ పొందడానికి. ధన్యవాదాలు",
    pointsOnThe: "పై పాయింట్లు",
    morePointsToRedeem: "రీడీమ్ చేయడానికి మరిన్ని పాయింట్లు",
    showCode: "కోడ్ చూపించు",
    toGetYourRewardOnNextVisit:
      "మీ తదుపరి సందర్శనలో మీ రివార్డ్ పొందడానికి. ధన్యవాదాలు",
    earn: "సంపాదించు",
    delivaryNote: "డెలివరీ నోట్",
    draftSales: "డ్రాఫ్ట్ అమ్మకాలు",
    startDate: "ప్రారంబపు తేది",
    endDate: "చివరి తేది",
    orders: "ఆదేశాలు",
    checkout: "చెక్అవుట్",
    noProductItem: "ఉత్పత్తి అంశం లేదు",
    selectProductImage: "ఉత్పత్తి చిత్రాన్ని ఎంచుకోండి",
    selectCountry: "దేశాన్ని ఎంచుకోండి",
    selectRegion: "రాష్ట్రం/ప్రాంతాన్ని ఎంచుకోండి",
    printProductTag: "ఉత్పత్తి ట్యాగ్‌ను ముద్రించండి",
    transactionReference: "లావాదేవీ సూచన",
    Cashier: "క్యాషియర్",
    Manager: "నిర్వాహకుడు",
    Owner: "యజమాని",
    Admin: "అడ్మిన్",
    addPartners: "భాగస్వాములను జోడించండి",
    addNewLoyaltyPartner: "కొత్త లాయల్టీ భాగస్వామిని జోడించండి",
    pleaseEnterCompanyName: "దయచేసి కంపెనీ పేరును నమోదు చేయండి",
    companyName: "కంపెనీ పేరు",
    pleaseEnterCompanyRepName: "దయచేసి కంపెనీ ప్రతినిధి పేరును నమోదు చేయండి",
    companyRepName: "కంపెనీ ప్రతినిధి పేరు",
    pleaseEnterCompanyRepEmail:
      "దయచేసి కంపెనీ ప్రతినిధి ఇమెయిల్‌ను నమోదు చేయండి",
    companyRepEmail: "కంపెనీ ప్రతినిధి ఇమెయిల్",
    pleaseEnterCompanyRepPhone:
      "దయచేసి కంపెనీ ప్రతినిధి ఫోన్ నంబర్‌ని నమోదు చేయండి",
    companyRepPhone: "కంపెనీ ప్రతినిధి ఫోన్ నంబర్",
    addReward: "బహుమతిని జోడించండి",
    pleaseEnterRewardName: "దయచేసి రివార్డ్ పేరును నమోదు చేయండి",
    rewardName: "రివార్డ్ పేరు",
    rewardQuantity: "రివార్డ్ పరిమాణం",
    rewardDescription: "రివార్డ్ వివరణ",
    rewardType: "రివార్డ్ రకం",
    pleaseEnterRewardType: "దయచేసి రివార్డ్ రకాన్ని నమోదు చేయండి",
    pleaseEnterRewardQuantity: "దయచేసి రివార్డ్ పరిమాణాన్ని నమోదు చేయండి",
    pleaseEnterRewardDescription: "దయచేసి రివార్డ్ వివరణను నమోదు చేయండి",
    fineDining: "ఫైన్ డైనింగ్",
    luxuryFashion: "లగ్జరీ ఫ్యాషన్",
    hotels: "హోటల్స్",
    travel: "ప్రయాణం",
    foodAndBeverage: "అన్నపానీయాలు",
    fashion: "ఫ్యాషన్",
    health: "ఆరోగ్యం",
    furniture: "ఫర్నిచర్",
    entertainment: "వినోదం",
    automobile: "ఆటోమొబైల్",
    education: "చదువు",
    beautyAndSpa: "అందం మరియు స్పా",
    staycation: "స్టేకేషన్",
    events: "ఈవెంట్స్",
    trips: "ప్రయాణాలు",
    oilAndGas: "చమురు మరియు వాయువు",
    laundry: "లాండ్రీ",
    partnerCategory: "భాగస్వామి వర్గం",
    freeItem: "ఉచిత అంశం",
  },
  Turkish: {
    cashier: "kasiyer",
    manager: "yönetici",
    owner: "sahip",
    online: "internet üzerinden",
    offline: "çevrimdışı",
    changePassword: "Şifre değiştir",
    currentPasswordMessage: "Lütfen mevcut şifrenizi giriniz",
    passwordMessage: "Şifrenizi giriniz Lütfen",
    currentPassword: "Şimdiki Şifre",
    password: "parola",
    confirmPasswordMessage: "Lütfen şifrenizi onaylayınız!",
    confirmPassword: "Şifreyi Onayla",
    sendViaEmail: "E-posta ile Gönder",
    sendViaWhatsapp: "WhatsApp ile Gönder",
    downloadPdf: "PDF İndir",
    paid: "paralı",
    unpaid: "ödenmemiş",
    partial: "kısmi",
    closeInvoice: "Faturayı kapatmak istiyor musunuz?",
    closeInvoiceConfirmation:
      "Fatura kaydedilemeyebilir. kapatmak istiyor musun",
    yes: "Evet",
    no: "numara",
    invoice: "Fatura",
    wasDeducted: "kesinti yapıldı",
    for: "için",
    item: "Kalem",
    addProduct: "ürün ekle",
    paymentReference: "Ödeme referansı",
    amountPaid: "Ödenen miktar",
    discountAmount: "İndirim tutarı",
    amountDue: "Alacak miktarı",
    amount: "Miktar",
    dueDate: "Bitiş tarihi",
    paymentType: "Ödeme türü",
    card: "kart",
    cash: "Peşin",
    bankTransfer: "Banka transferi",
    paymentMessage: "Ödeme Mesajı",
    description: "Açıklama",
    sendReceipt: "Makbuz Gönder",
    delete: "Silmek",
    save: "Kaydetmek",
    resend: "Yeniden gönder",
    saveAndSend: "Kaydetmek",
    invoiceSaved: "Fatura kaydedildi!",
    selectPaymentMethod: "Lütfen bir ödeme yöntemi seçin!",
    selectCustomer: "Lütfen bir Müşteri seçin!",
    cartEmptyError:
      "Sepet Listesi boş olamaz, faturayı kapatın ve Ürünü sepete ekleyin!",
    subscriptionExpired:
      "Aboneliğinizin süresi doldu ve hesabınız artık sınırlı. Hesabınızı yenilemek için aşağıdaki Düğmeye tıklayın",
    renew: "Yenile",
    holdOn: "Devam etmek",
    customerBank: "Müşteri Bankası",
    cancel: "İptal",
    selectACustomer: "Müşteri Seç",
    invoiceSuccessfulPdfError:
      "Fatura başarıyla oluşturuldu ancak PDF Oluşturma normalden daha uzun sürüyor. Lütfen kısa süre sonra tekrar kontrol edin.",
    downloadingInvoice: "Fatura İndirme",
    downloadingReceipt: "Makbuz İndiriliyor",
    whatsappReceiptError:
      "Makbuz WhatsApp üzerinden gönderilirken bir hata oluştu, Lütfen tekrar deneyin.",
    receiptToWhatsapp: "Makbuz WhatsApp'a iletildi",
    thankYouForPatronage: "Patronajınız için teşekkür ederiz",
    hereIsYourReceipt: "İşte ödeme makbuzunuz",
    errorSendingEmailReceipt:
      "Makbuz e-posta yoluyla gönderilirken bir hata oluştu, lütfen tekrar deneyin veya destek ile iletişime geçin.",
    receiptSentToEmail: "Makbuz müşterinin e-postasına gönderildi",
    invoiceSentToEmail: "Fatura müşterinin e-postasına gönderildi",
    invoiceSuccessWhatsappError:
      "Fatura başarıyla oluşturuldu ancak WhatsApp'a gönderilirken bir hata oluştu. Fatura listesinde tekrar deneyin",
    invoiceSuccessfulEmailError:
      "Fatura başarıyla oluşturuldu ancak e-posta olarak gönderilirken bir hata oluştu. Lütfen fatura listesinden tekrar deneyin",
    invoiceSentToWhatsapp: "Fatura WhatsApp'a iletildi",
    hello: "Merhaba",
    pleaseDownloadInvoice: "Lütfen faturayı indirin",
    pleaseDownloadReceipt: "Lütfen makbuzu indirin",
    from: "itibaren",
    email: "E-posta",
    upgrade: "Güncelleme",
    youAreOnFreePlan: "Ücretsiz Plandasınız.",
    clickOn: "Tıklamak",
    yourPlanInFewSteps: " aboneliğiniz birkaç hızlı adımda.",
    to: "ile",
    yourSubscriptionHasExpired:
      "Aboneliğinizin süresi doldu ve hesabınız artık sınırlı.",
    days: "günler",
    yourSubscriptionExpiresIn: "Loystar aboneliğiniz şu tarihte sona eriyor:",
    createAcount: "Bir hesap oluşturun",
    signIn: "Kayıt olmak",
    continue: "Devam et",
    enterOtp: "OTP PIN'ini girin",
    enterValidOtp: "Lütfen geçerli bir PIN girin",
    pin: "TOPLU İĞNE",
    tokenSentToMail: "E-postanıza bir jeton gönderildi",
    passwordResetSuccessful: "Şifre sıfırlama başarılı oldu",
    somethingWentWrong: "Bir şeyler yanlış gitti!",
    resetPassword: "Şifreyi yenile",
    iHaveResetCode: "Şifre sıfırlama kodum var",
    pleaseEnterEmail: "Lütfen E-postanızı girin",
    aTokenWillBeSentToEmail: "E-postanıza bir jeton gönderilecek",
    enterEmail: "E-postanızı giriniz",
    sendinYourToken: "Simgeniz gönderiliyor...",
    makeSureItMatchesPassword: "Yeni şifrenizle eşleştiğinden emin olun",
    pleaseChooseNewPassword: "Lütfen yeni bir şifre seçin",
    chooseNewPassword: "Yeni bir şifre seçin",
    enterNewPassword: "Onaylamak için yeni şifrenizi girin",
    enterTokenSent: "Postanıza gönderilen jetonu girin",
    resetToken: "Simgeyi Sıfırla",
    resettingPassword: "Parolanız Sıfırlanıyor...",
    signUp: "Üye olmak",
    adminSignInWithEmail:
      " Yönetici e-postayla, Personel ise kullanıcı adıyla oturum açar.",
    pleaseEnterEmailOrUsername:
      "Lütfen e-posta adresinizi veya kullanıcı adınızı girin",
    emailOrUsername: "E-posta ya da kullanıcı adı",
    pleaseEnterPassword: "Lütfen şifre giriniz",
    createAnAccount: "Bir hesap oluşturun",
    forgotPassword: "Parolanızı mı unuttunuz?",
    enterPhoneNumber: "Telefon Numarasını Girin",
    personalData: "Kişisel veri",
    validateConfirmationCOde: "Onay Kodunu Doğrula",
    pleaseEnterFirstName: "Lütfen isminizi giriniz",
    pleaseEnterPhoneNumber: "Lütfen telefon numaranızı girin",
    pleaseEnterLastName: "Lütfen soyadınızı giriniz",
    pleaseEnterBusinessName: "Lütfen işletmenizin adını girin",
    firstName: "İlk adı",
    lastName: "Soy isim",
    businessName: "iş adı",
    previous: "Öncesi",
    next: "Sonraki",
    pleaseSelectBusinessCategory: "Lütfen işletme kategorinizi seçin",
    pleaseEnterValidEmail: "lütfen geçerli eposta adresini giriniz",
    pleaseEnterPostCode: "Lütfen posta kodunu girin",
    postCode: "Posta Kodu",
    phoneNumberInUse: "Bu telefon numarası zaten kullanılıyor!",
    emailInUse: "Bu e-posta zaten kullanılıyor!",
    foodAndDrinks: "Yiyecek ve içecekler",
    salonAndBeauty: "Salon ve Güzellik",
    fashionAndAccessories: "Moda ve Aksesuarlar",
    gymAndFitness: "Spor Salonu ve Fitness",
    travelAndHotel: "Seyahat ve Otel",
    homeAndGifts: "Ev ve Hediyeler",
    washingAndCleaning: "Yıkama ve Temizleme",
    gadgetsAndElectronics: "Gadget'lar ve Elektronik",
    groceries: "bakkaliye",
    others: "Diğerleri",
    submit: "Göndermek",
    accountCreatedSuccessful: "Hesabın başarıyla oluşturuldu.",
    pleaseEnterAddress: "Lütfen Adresinizi Girin",
    addressLine1: "Adres satırı 1",
    addressLine2: "Adres Satırı 2",
    choosePassword: "Şifre seçin",
    passwordMustBe6Characters: "Şifre en az 6 karakter olmalıdır.",
    howDidYouHearLoystar: "Loystar'ı nasıl duydunuz?",
    referralCode: "Yönlendirme Kodu",
    byClickingTheButton:
      " Aşağıdaki butona tıklayarak, şunları kabul etmiş olursunuz:",
    termsAndSevice: "Şartlar",
    wereCreatingAccount: "Hesabınızı oluşturuyoruz",
    proceed: "İlerlemek",
    verificationCodeMustBe6: "Doğrulama Kodu 6 haneli olmalıdır",
    pleaseEnter6DigitCode: "Lütfen 6 haneli kodu giriniz",
    enterVerificationCode: "Dogrulama kodunu giriniz",
    resendToken: "Simgeyi Yeniden Gönder",
    verify: "Doğrulamak",
    transactions: "işlemler",
    todaySales: "Bugünün Satışları",
    salesHistory: "Satış Geçmişi",
    supplyHistory: "Tedarik Geçmişi",
    new: "Yeni",
    invoices: "faturalar",
    disbursements: "ödemeler",
    offlineSales: "Çevrimdışı Satış",
    products: "Ürün:% s",
    customers: "müşteriler",
    multiLevelLoyalty: "Çok Seviyeli Sadakat",
    loyaltyPrograms: "Sadakat programları",
    members: "Üyeler",
    appStore: "Uygulama mağazası",
    orderMenu: "Sipariş Menüsü",
    settings: "Ayarlar",
    staffAndBranches: "Personel ve Şubeler",
    myAccount: "Hesabım",
    switchToSellMode: "Satış Moduna Geç",
    signOut: "Oturumu Kapat",
    getFreeSubscription: "Ücretsiz Abonelik Alın",
    onlyNumbersAllowed: "Yalnızca sayılara izin verilir",
    yourAccountMustBe10Digits: "hesap numaranız 10 haneli bir sayı olmalıdır",
    yourBvnMustBe11: "BVN'niz 11 haneli bir sayı olmalıdır",
    pleaseSelectBank: "Lütfen bankanızı seçin",
    selectYourBank: "Bankanızı seçin",
    enterBankAccountNumber: "Banka Hesap Numarasını Girin",
    enterBvnNumber: "BVN'nizi girin",
    connectBankAccount: "Banka Hesabını Bağla",
    passwordResetSuccessfulAndSignOut:
      "Parolanız başarıyla sıfırlandı. Tekrar oturum açmak için aşağıdaki Oturumu Kapat düğmesine tıklayın",
    enterCurrentPassword: "Güncel şifrenizi giriniz",
    pleaseEnterCurrentPassword: "Lütfen geçerli şifreyi girin",
    phoneNumber: "Telefon numarası",
    sex: "Seks",
    dateOfBirth: "Doğum tarihi",
    state: "Durum",
    country: "Ülke",
    loyaltyId: "Bağlılık Kimliği",
    createdAt: "Oluşturma Tarihi",
    noOfTransactionsMade: "Yapılan işlem sayısı",
    yourDownloadWillStart: "İndirmeniz birazdan başlayacak",
    exportCustomers: "İhracat Müşterileri",
    youhaveSuccessfullyToppedUp: "SMS Birimlerinizi başarıyla doldurdunuz.",
    youNowHave: "şimdi sahipsin",
    smsUnits: "SMS Birimleri",
    enterNumberOfSmsUnits:
      "Satın Almayı Düşündüğünüz SMS Birimi Sayısını Girin",
    pleaseEnterANumericValue: "Lütfen sayısal bir değer girin",
    pay: "Ödemek",
    accountEditedSuccessfully: "Hesabınız başarıyla düzenlendi.",
    youAeCurrentlyOn: "şu anda açık",
    plan: "Plan",
    userData: "Kullanıcı bilgisi",
    businessData: "İŞ VERİSİ",
    businessCategory: "İş Kategorisi",
    pleaseSelectCurrency: "Lütfen para biriminizi seçin",
    selectYourCurrency: "Para biriminizi seçin",
    purchaseMoreSmsUnits:
      "Aşağıdaki formu kullanarak daha fazla SMS Birimi satın alın",
    youHave: "Var",
    atLeast4SmsUnitsRrequired:
      "Doğrulama için en az 4 sms birimi gereklidir, lütfen doldurun!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Lütfen 'Müşterinizi Tanıyın' (KYC) gereksinimlerine uymak için banka hesabınızı doğrulayın. Bu, USSD veya anında transfer yoluyla ödemeleri tahsil etmenize, müşterilerden sipariş almanıza ve işlem anlaşmasını işlemenize olanak tanır. Yalnızca Nijeryalı satıcılar için geçerlidir. Başlamak için lütfen aşağıdaki düğmeye basın.",
    reConnectBankAccount: "Banka Hesabını Yeniden Bağla",
    accountName: "Hesap adı",
    accountNumber: "Hesap numarası",
    bankName: "Banka adı",
    verified: "Doğrulandı",
    accountDetails: "hesap detayları",
    kycBankAccount: "KYC",
    createTier: "Katman Oluştur",
    fileUploadSuccessful: "dosya başarıyla yüklendi",
    fileUploadFailed: "dosya yükleme başarısız",
    createLoyaltyProgram: "Bir Sadakat Programı Oluşturun",
    createLoyalty: "Sadakat Yarat",
    name: "İsim",
    loyaltyArtwork: "Sadakat Eseri",
    clickToUpload: "Yüklemek için tıklayın",
    amountToPointsRatio: "Tutar Puan Oranı",
    points: "Puan",
    recommendedAmountToPointRatio:
      "Önerilen: ₦1 ila 1 puan. yani harcanan her 1 Naira için müşterileriniz 1 puan alır",
    pleaseTypeIn: "lütfen yazın",
    toDeleteLoyalty: "bu sadakati silmek için",
    deleteLoyalty: "Bağlılığı Sil",
    toConfirm: "onaylamak",
    edit: "Düzenlemek",
    pointsAwardedSuccessfully: "Başarıyla Kazanılan Puanlar.",
    enterPointValueToAward:
      "Müşterilere Vermek İstediğiniz Puan Değerini Girin",
    award: "Ödül",
    awardPointValuesToCustomer: "Müşteriye Puan Değeri Ödüllendirin",
    enrollMembersToLoyalty: "Üyeleri Sadakat'e Kaydedin",
    awardPoints: "Ödül Puanları",
    enroll: "Kaydolun",
    home: "Ev",
    loyalty: "Bağlılık",
    enrollCustomers: "Müşterileri Kaydedin",
    selected: "Seçildi",
    customer: "Müşteri",
    loyaltyActivationSuccessful: "Sadakat Aktivasyonu başarılı.",
    loyaltyDeactivationSuccessful: "Sadakat Devre Dışı Bırakma başarılı.",
    viewTier: "Katmanı Görüntüle",
    deactivate: "Devre dışı bırakmak",
    activate: "etkinleştir",
    actions: "Hareketler",
    nameOfLoyalty: "Sadakat Adı",
    loyaltyStatus: "Sadakat DurumuSadakat Durumu",
    numberOfTiers: "Katman Sayısı",
    createdOn: "Oluşturma Tarihi",
    createATier: "Katman Oluştur",
    stopCreatingTierConfirmation:
      "Katman oluşturmayı durdurmak istiyor musunuz?",
    stopEditingTierConfirmation:
      "Bu katmanı düzenlemeyi durdurmak istiyor musunuz?",
    nameOfTier: "Katmanın Adı",
    minimumSpendingTarget: "Minimum Harcama Hedefi",
    maximumSpendingTarget: "Maksimum Harcama Hedefi",
    minimumSpendingTargetRequired: "minimum harcama hedefi gerekli",
    maximumSpendingTargetRequired: "maksimum harcama hedefi gerekli",
    rewardSponsor: "Ödül Sponsoru",
    pleaseChooseARewardSponsor: "Lütfen bir ödül sponsoru seçin",
    self: "öz",
    partner: "Ortak",
    rewardPartner: "Ödül Ortağı",
    pleaseSelectRewardPartner: "Lütfen ödül ortağınızı seçin",
    rewards: "Ödüller",
    pleaseSelectAReward: "Lütfen bir ödül seç",
    instructionsOnRedeemingReward:
      "Müşterinin Ödülü Nasıl Kullanacağına İlişkin Talimatlar",
    pleaseFillInThisField: "Lütfen Bu Alanı Doldurunuz!",
    toDeleteThisTier: " bu katmanı silmek için",
    deleteTier: "Katmanı Sil",
    viewMembers: "Üyeleri Görüntüle",
    membersEnrolled: "Kayıtlı Üyeler",
    instruction: "Talimat",
    membersIn: "Üyeler",
    availableTiersInLoyalty: "Sadakat Programında Mevcut Kademe(ler)",
    tiers: "Katmanlar",
    totalTier: "TOPLAM KADEME",
    availableLoyaltyProgramme: "Mevcut Sadakat Programı",
    totalLoyalties: "TOPLAM SADAKAT",
    memberDetails: "Üye Ayrıntıları",
    nameOfCustomer: "Müşterinin adı",
    address: "Adres",
    allEnrolledMembers: "Tüm Kayıtlı Üyeler",
    thisIsToWishYouHappyBirthday:
      "Bu size çok mutlu bir doğum günü ve müreffeh bir yaşam dilemek içindir. Loyster için olduğun her şey için teşekkürler. Mutlu Kutlamalar!",
    inputAnOfferPlease: "Bir Teklif Girin lütfen",
    pleaseSelectTheInsertPoint:
      "Lütfen mesajdaki ekleme noktasını seçin ve tekrar tıklayın",
    birthdayOfferAndMessage: "Doğum Günü Teklifi ve Mesajı",
    birthdayOffer: "Doğum Günü Teklifi",
    birthdayMessage: "Doğum Günü Mesajı",
    noOfferFound: "Teklif bulunamadı",
    settingABirthdayOffer:
      "Bir doğum günü teklifi belirlemek, müşterilerin bu teklifi doğum günlerinde SMS yoluyla almalarını sağlar.",
    createOffer: "Teklif Oluştur",
    whatIsTheOffer: "Teklif nedir?",
    editMessage: "Mesajı Düzenle",
    insert: "Sokmak",
    theNameOfCustomerInserted: "Müşterinin adı buraya eklenecektir",
    theBirtdayOfferInserted: "Doğum günü teklifi buraya eklenecek",
    youSuccessfullyAddedNewBranch: "Başarıyla yeni bir şube eklediniz!",
    addNewBranch: "Yeni Şube Ekle",
    addBranch: "Şube Ekle",
    additionalBranchWillIncur: "Ek Şube tabi olacak",
    perBranch: "şube başına",
    ecommerceBranchCosts: "E-ticaret Şube maliyetleri",
    pleaseEnterBranchName: "Lütfen şube adını girin",
    pleaseEnterBranchAddress1: "Lütfen şubenin adres satırı 1'i giriniz",
    enterBranchAddress1: "Şubenin adres satırı 1'i girin",
    enterBranchAddress2: "Şubenin adres satırı 1'i girin",
    pleaseEnterBranchAddress2: "Lütfen şubenin adres satırı 2'yi giriniz",
    enterBranchName: "Şube adını girin",
    successfullyAddedStaff: "Başarıyla yeni bir personel eklediniz!",
    addNewStaff: "Yeni Personel Ekle",
    addStaff: "Personel Ekle",
    additionalStaffWillIncur: "Ek Personel alınacaktır",
    perStaff: "personel başına.",
    pleaseEnterStaffEmail: "Lütfen personelin e-posta adresini girin",
    pleaseEnterStaffUsername: "Lütfen personelin kullanıcı adını girin",
    pleaseEnterStaffPassword: "Lütfen personelin şifresini giriniz",
    pleaseSelectStaffRole: "Lütfen personelin rolünü seçin",
    selectStaffRole: "Personelin rolünü seçin",
    enterStaffEmail: "Personelin e-posta adresini girin",
    enterStaffUsername: "Personelin kullanıcı adını girin",
    enterStaffPassword: "Personelin şifresini girin",
    spacesNotAllowedInUsername: "kullanıcı adında boşluk bırakılamaz",
    admin: "yönetici",
    pleaseSelectBusinessToAttachStaff:
      "Lütfen personel eklemek için işletme seçin",
    searchForBranchToAttachStaff: "Personel eklemek için şube arayın",
    username: "Kullanıcı adı",
    role: "rol",
    areYouSureToDeleteThis: "bunu silmek istediğinden emin misin",
    branches: "Şubeler",
    upgradeYourPlan: "Planınızı yükseltin.",
    add: "EKLE",
    addNew: "Yeni ekle",
    customerWithEmailAlreadyExists:
      "E-posta/telefon numarası olan müşteri zaten var!",
    successfullyAddedNewCustomer: "Başarıyla yeni bir müşteri eklediniz!",
    addCustomer: "Müşteri Ekle",
    pleaseEnterCustomerFirstName: "Lütfen müşterinin adını girin",
    pleaseEnterCustomerLastName: "Lütfen müşterinin soyadını girin",
    pleaseEnterCustomerPhoneNumber:
      "Lütfen müşterinin telefon numarasını girin",
    pleaseEnterCustomerEmail: "Lütfen müşterinin e-posta adresini girin",
    pleaseEnterCustomerAddressLine: "Lütfen müşterinin Adres Satırını girin",
    pleaseEnterCustomerOtherAddress: "Lütfen müşterinin diğer adresini girin",
    pleaseSelectCustomerGender: "Lütfen müşterinin cinsiyetini seçin",
    gender: "Cinsiyet",
    male: "Erkek",
    female: "Dişi",
    bank: "Banka",
    selectBank: "Banka Seç",
    stateOrRegionOrProvince: "Eyalet/Bölge/İl",
    customerNotes: "müşteri notları",
    sendSms: "SMS gönder",
    editCustomer: "Müşteriyi Düzenle",
    redeemReward: "Ödülü Kullan",
    issueLoyaltyCard: "Sadakat Kartı Ver",
    deleteCustomer: "Müşteriyi Sil",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Başarıyla Bağlılık Üyeliği Kimliği Atadınız",
    noMembershipIdAvailable:
      "Üyelik kimliği yok. Lütfen hello@loystar.co adresinden bizimle iletişime geçin.",
    sendEmail: "Eposta gönder",
    membershipPoints: "Üyelik Puanları",
    customerDetails: "Müşteri detayları",
    close: "Kapat",
    loyaltyBalance: "Sadakat Dengesi",
    pointsBalance: "Puan Bakiyesi",
    starBalance: "Yıldız Dengesi",
    requiredPoints: "Gerekli Puanlar",
    requiredStars: "Gerekli Yıldızlar",
    reddemCode: "Kodu Kullan",
    toDeleteThisCustomer: "bu müşteriyi silmek için",
    customerHasBeenDeletedSuccessfully: "Müşteri başarıyla silindi!",
    customerWithPhoneAlreadyExists:
      "Telefon numarasına sahip müşteri zaten var",
    customerWasSuccessfullyEdited: "Müşteri başarıyla düzenlendi",
    anErrorOccured: "Bir hata oluştu",
    phoneNumberIncludeCountry: "Telefon Numarası (Ülke Kodu Dahil)",
    yourFileQueuedForUpload:
      "Dosyanız yükleme için sıraya alındı. Lütfen birkaç saniye sonra sayfayı yenileyin.",
    thereWasAnErrorPerformingOperation:
      "İşlem gerçekleştirilirken bir hata oluştu!",
    pleaseSelectFile: "Lütfen Dosya Seçiniz!",
    oopsOnlyCsvFilesAllowed:
      "Hata! Yalnızca CSV dosyalarına izin verilir. Lütfen bir .csv dosyası yükleyin.",
    fileShouldBeSmallerThan5Mb:
      "Dosya 5MB'den küçük olmalıdır. Daha büyük bir dosyanız varsa, lütfen support@loystar.co adresine e-posta gönderin. Teşekkürler",
    customerFirstNameIsRequired: "satırda müşterinin adı gerekli",
    customerPhoneNumberIsRequired: "satırda müşteri telefon numarası gerekli",
    importCustomers: "Müşterileri İçe Aktar",
    upload: "Yüklemek",
    clickIconToDownloadCsv:
      "CSV dosya şablonunu indirmek için bu simgeye tıklayın.",
    getGoogleSheetVersion: "Google sayfa sürümünü edinin",
    clickOrDragFileToUpload:
      "Yüklemek için dosyayı tıklayın veya bu alana sürükleyin",
    missingOrInvalidColumnHeader:
      "Eksik veya geçersiz sütun başlığı. Lütfen şablon biçimini takip edin. Teşekkürler.",
    youHaveImported: "İçe Aktardınız",
    youSuccessfullyRedeemedReward: "Ödülünüzü başarıyla kullandınız!",
    sixDigitCode: "Altı haneli kod",
    pleaseEnterCustomerRewardCode: "Lütfen müşterinin ödül kodunu girin",
    enterRewardCode:
      "Ödül kodunu girin.(Ödül kodu büyük/küçük harf duyarlıdır)",
    selectLoyaltyProgram: "Bağlılık programı seçin",
    stamps: "Pullar",
    smsUnitsLow: "SMS Birimleri Düşük",
    pleaseTopUpSmsUnits: "Lütfen SMS Birimlerini Doldurun",
    smsSuccessfullySentForDelivery: "SMS teslimat için başarıyla gönderildi!",
    sendSmsTo: "SMS gönder",
    allCustomers: "Tüm Müşteriler",
    unitsAvailable: "Mevcut Birimler",
    pleaseTypeInTheMessage: "Lütfen mesajı yazın",
    message: "İleti",
    charactersRemaining: "kalan karakter",
    avoidUseOfSpecialCharacters:
      "SMS Birimlerini korumak için Özel Karakterler ve Emojiler kullanmaktan kaçının.",
    note: "Not",
    errorFetchingCustomersMultilevelDetail:
      "Müşteriler Çok Düzeyli Ayrıntılar Getirilirken Hata",
    search: "Arama",
    reset: "Sıfırla",
    importCustomer: "Müşteriyi İçe Aktar",
    addNewCustomer: "Yeni Müşteri Ekle",
    sendSmsBroadcast: "SMS Yayını Gönder",
    totalCustomers: "TOPLAM MÜŞTERİ",
    disbursementDetails: "Ödeme Ayrıntıları",
    paidBy: "ödeyen",
    disbursed: "ödenmiş",
    bankAccountName: "Banka Hesap Adı",
    bankAccountNumber: "Banka hesabı numarası",
    transferInitiated: "Aktarım Başlatıldı",
    transferCompleted: "Transfer Tamamlandı",
    pleaseEnterAValid: "Lütfen geçerli bir girin",
    begin: "başlamak",
    end: "son",
    date: "tarih",
    paymentDate: "Ödeme tarihi",
    selectDisbursementDuration: "Ödeme Süresini Seçin",
    totalSettled: "Toplam Yerleşik",
    totalUnsettled: "Toplam Kararsız",
    toDeleteThisSale: "bu satışı silmek için",
    draftSaleDeletedSuccessfully: "Taslak Satış başarıyla silindi!",
    deleteSale: "Satışı Sil",
    pleaseTypeInYourTotal:
      "Silme işlemini onaylamak için lütfen toplamınızı yazın",
    billDetails: "Fatura Ayrıntıları",
    printBill: "Faturayı Yazdır",
    servedBy: "tarafından sunulan",
    total: "Toplam",
    createdDate: "Oluşturulma Tarihi",
    createdTime: "Oluşturma Zamanı",
    status: "Durum",
    loginSuccessful: "Giriş başarılı",
    pleaseWaitWhileWeConnectAccount: "Hesabınızı bağlarken lütfen bekleyin",
    connectionFailedTryAgain: "Bağlantı başarısız. Lütfen tekrar deneyin.",
    connectionSuccessful: "Bağlantı Başarılı",
    viewDetails: "Detayları göster",
    enable: "Olanak vermek",
    free: "Özgür",
    cost: "Maliyet",
    visitWebsite: "Siteyi ziyaret et",
    pleaseUpgradeYourPlanToPro:
      "Bu uygulamayı etkinleştirmek için lütfen planınızı Pro Plus'a yükseltin",
    connectYourBankAccount: "Ödeme alabilmek için Banka Hesabınızı bağlayın.",
    disable: "Devre dışı bırakmak",
    enableApp: "Uygulamayı Etkinleştir",
    addNewProductToInvoice: "Faturaya Yeni Ürün Ekle",
    toDeleteThisInvoice: "bu Faturayı silmek için",
    invoiceDeletedSuccessfully: "Fatura başarıyla silindi!",
    deleteInvoice: "Faturayı Sil",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Bir Fatura ödeme mesajını başarıyla eklediniz!",
    pleaseEnterPaymentInstructions: "Lütfen ödeme talimatlarını girin",
    invoiceId: "fatura kimliği",
    paidDate: "Ödenen tarih",
    reference: "Referans",
    productAdded: "Ürün eklendi",
    productOutOfStock: "Ürün stokta yok. Lütfen yeniden stoklayın.",
    totalInvoices: "TOPLAM FATURALAR",
    totalPaidInvoices: "TOPLAM ÖDENEN FATURALAR",
    totalUnpaidInvoices: "TOPLAM ÖDENMEYEN FATURALAR",
    loyaltyProgramDeleted: "Sadakat Programı Silindi",
    thisLoyaltyProgramDeletedSuccessfully:
      "Bu bağlılık programı başarıyla silindi",
    thisLoyaltyProgramEditedSuccessfully:
      "Bu bağlılık programı başarıyla düzenlendi",
    loyaltyProgramAddedSuccessfully: "Sadakat programı başarıyla eklendi",
    loyaltyProgramEdited: "Sadakat Programı Düzenlendi",
    loyaltyProgramAdded: "Sadakat Programı Eklendi",
    loyaltyDetails: "Sadakat Ayrıntıları",
    doYouWantToCloseDialog: "Bu iletişim kutusunu kapatmak istiyor musunuz?",
    pleaseEnterLoyaltyName: "Lütfen sadakatinizin adını girin",
    programType: "Program Türü",
    pleaseSelectProgramType: "Lütfen bir program türü seçin",
    simplePoints: "Basit Noktalar",
    stampsProgram: "Pul Programı",
    threshold: "Eşik",
    pleaseEnterLoyaltyThreshold: "Lütfen bağlılık eşiğini girin",
    reward: "Ödül",
    pleaseEnterLoyaltyReward: "Lütfen sadakatin ödülünü girin",
    totalUserPoints: "Toplam Kullanıcı Puanı",
    totalUserStamps: "Toplam Kullanıcı Damgası",
    spendingTarget: "harcama hedefi",
    spendingTargetHint1:
      "Harcama hedefi, bir müşterinin ödülü kazanmak için ne kadar harcaması gerektiğidir. 1 para birimi değeri = 1 puan.",
    spendingTargetHint2:
      "Pul hedefi, bir müşterinin ödülü kazanmak için kaç pul toplaması gerektiğidir. Örneğin. 5",
    addNewLoyaltyProgram: "Yeni Sadakat Programı Ekle",
    addLoyaltyProgram: "Bağlılık Programı Ekle",
    loyaltyProgramType: "Bağlılık Programı Türü",
    pleaseSelectLoyaltyProgramType: "Lütfen bağlılık programı türünü seçin",
    nameOfProgram: "Programın adı",
    pleaseEnterProgramName: "Lütfen program adını girin",
    whatIsTheReward: "Ödül nedir?",
    egNextPurchaseIsFree: "Örneğin. Sonraki satın alma ücretsizdir",
    customerName: "müşteri adı",
    guestCustomer: "Misafir Müşteri",
    orderConfirmedSuccessfully: "Sipariş başarıyla onaylandı",
    orderCancelledSuccessfully: "Sipariş başarıyla iptal edildi",
    confirmOrder: "Sipariş onaylamak",
    cancelOrder: "Siparişi iptal et",
    allOrders: "Tüm siparişler",
    totalOrders: "TOPLAM SİPARİŞ",
    doYouWantToAcceptOrder: "Bu siparişi kabul etmek istiyor musunuz?",
    doYouWantToCancelOrder: "Bu siparişi iptal etmek istiyor musunuz?",
    orderDetails: "sipariş detayları",
    orderCreatedAt: "Sipariş oluşturuldu",
    supplier: "Tedarikçi",
    productName: "Ürün adı",
    quantity: "Miktar",
    unitPrice: "Birim fiyat",
    receivedBy: "Tarafından alındı",
    reportFrom: "Şuradan rapor:",
    totalSupplies: "Toplam Sarf Malzemesi",
    allRightsReserved: "Her hakkı saklıdır",
    toDeleteThisTransaction: "bu İşlemi silmek için",
    transactionDeletedSuccessfully:
      "İşlem başarıyla silindi. Stok envantere iade edildi.",
    deleteTransaction: "İşlemi Sil",
    transactionDetails: "ödeme detayları",
    printReceipt: "Makbuzu yazdırın",
    channel: "Kanal",
    discount: "İndirim",
    profit: "Kâr",
    discountedSales: "İndirimli Satışlar",
    errorFetchingRecord: "Kayıt Alma Hatası",
    exportTransactions: "İhracat İşlemleri",
    errorFetchingSalesRecord:
      "Dışa Aktarma için Satış kaydı alınırken hata oluştu.",
    totalSellingPrice: "Toplam Satış Fiyatı",
    totalCostPrice: "Toplam Maliyet Fiyatı",
    appliedDiscount: "Uygulanan İndirim",
    noOfItems: "Ürün Sayısı",
    sales: "Satış",
    export: "İhracat",
    totalProfit: "Toplam kar",
    totalBalanceInPeriod: "Dönemdeki Toplam Bakiye",
    allTimeSales: "Tüm Zamanların Satışları",
    records: "Kayıtlar",
    todaysSales: "Bugünün Satışları",
    transaction: "işlem",
    youHaveExceededTotalNumberOfProducts:
      "Planınızda izin verilen toplam ürün sayısını aştınız. Daha yüksek bir limitin keyfini çıkarmak için planınızı yükseltin.",
    youNeedToHaveLoyaltyProgram:
      "Bu özelliği kullanmak için bir Sadakat Programına sahip olmanız gerekiyor!",
    price: "Fiyat",
    category: "Kategori",
    stockTracking: "Stok Takibi",
    stockCount: "Stok Sayısı",
    taxed: "vergilendirilmiş",
    originalPrice: "Orijinal fiyat",
    costPrice: "Maliyet fiyatı",
    unit: "Birim",
    productImage: "Ürün resmi",
    taxRate: "Vergi oranı",
    taxType: "Vergi Türü",
    trackInventory: "Envanteri Takip Et",
    variants: "Varyantlar",
    hasVariants: "Varyantları Var",
    importProduct: "Ürün İthalat",
    exportProducts: "İhracat Ürünleri",
    addNewProduct: "Yeni Ürün Ekle",
    viewCategory: "Kategoriyi Görüntüle",
    viewSuppliers: "Tedarikçileri Görüntüle",
    receiveInventory: "Envanter Al",
    printAllProductsTag: "Tüm Ürünler Etiketini Yazdır",
    deleteAll: "Hepsini sil",
    totalProducts: "TOPLAM ÜRÜNLER",
    youveSuccessfullyAddedANewCategory: "Başarıyla yeni bir kategori eklediniz",
    addNewCategory: "Yeni Kategori Ekle",
    addCategory: "Kategori ekle",
    categoryName: "Kategori adı",
    pleaseEnterCategoryName: "Lütfen kategori adını girin",
    stampsTarget: "Pul Hedefi",
    sendInventory: "Envanter Gönder",
    productDetails: "Ürün Detayları",
    youveSuccessfullyEditedThisCategory: "Bu kategoriyi başarıyla düzenlediniz",
    update: "Güncelleme",
    categoryList: "Kategori Listesi",
    categories: "Kategoriler",
    enterQuantityToUpdate: "Güncellenecek bir miktar girin",
    inventorySentSuccessfully: "Envanter başarıyla gönderildi!",
    updateInventory: "Envanteri Güncelle",
    currentQuantity: "Mevcut Miktar",
    pleaseEnterQuantityToAdd: "Lütfen eklemek istediğiniz miktarı giriniz",
    pleaseSelectABranch: "Lütfen bir Şube seçin",
    searchForBranch: "Şube ara",
    youCantSendMoreThanStock: "Stokta bulunandan fazlasını gönderemezsiniz",
    send: "Göndermek",
    pleaseEnterQuantityToSend: "Lütfen göndermek istediğiniz miktarı giriniz",
    productNameIsRequiredOnRow: "satırda ürün adı gerekli",
    productCategoryIsRequiredOnRow: "satırda ürün kategorisi gerekli",
    productPriceIsRequiredOnRow: "ürün fiyatı satırda gerekli",
    productUnitIsRequiredOnRow: "satırda ürün birimi gerekli",
    productQuantityIsRequiredOnRow: "ürün miktarı satırda gerekli",
    productVariantsRequireTracking: "ürün çeşitleri takip gerektirir!",
    pleaseAddVariantClickButton:
      "Lütfen ekle düğmesine tıklayarak bir varyant ekleyin!",
    totalVariantsMoreThanSelectedQuantity:
      "Toplam Varyantlar seçilen ürün miktarından fazladır, lütfen varyant miktarını azaltır",
    productEditedSuccessfully: "Ürün başarıyla düzenlendi!",
    fileTooLargeLessThan4Mb:
      "Dosya boyutu çok büyük! Dosya boyutu 4MB'den az olmalıdır.",
    suchVariantAlreadyExist: "Böyle bir varyant zaten var",
    addVariants: "Varyant Ekle",
    editProduct: "Ürünü Düzenle",
    pleaseEnterProductName: "Lütfen Ürün Adını Girin",
    pleaseEnterProductPrice: "Lütfen Ürün Fiyatını Giriniz",
    pleaseEnterProductOriginalPrice: "Lütfen Ürün Orijinal Fiyatını Girin",
    productDescription: "Ürün Açıklaması",
    selectProductCategory: "Ürün Kategorisini Seçin",
    pleaseSelectProductCategory: "Lütfen ürün kategorisini seçin",
    productCostPrice: "Ürün Maliyet Fiyatı",
    productSellingPrice: "Ürün Satış Fiyatı",
    productOriginalPrice: "Ürün Orijinal Fiyatı",
    maxFileSizeAllowedIs4Mb: "Maks. İzin verilen Dosya Boyutu 4mb",
    productsWithPicturesArePublished:
      "Resimli ürünler sipariş almak için Keşfet'te yayınlanır",
    shouldThisProductBeTracked: "Bu ürün takip edilmeli mi?",
    pleaseSelectStockUnit: "Lütfen stok birimi seçin",
    stockUnit: "Stok Birimi",
    bag: "ÇANTA",
    bottle: "ŞİŞE",
    bunch: "DEMET",
    can: "YAPABİLMEK",
    carton: "KARTON",
    crate: "sandık",
    cup: "FİNCAN",
    dozen: "DÜZİNE",
    gigabytes: "GIGABYTE",
    gram: "GRAM",
    kilogram: "KİLOGRAM",
    litre: "LİTRE",
    pack: "AMBALAJ",
    pair: "ÇİFT",
    pieces: "PARÇALAR",
    plate: "PLAKA",
    tonne: "TON (MT)",
    uNIT: "BİRİM",
    yard: "AVLU",
    pleaseEnterProductQuantity: "Lütfen ürün miktarını giriniz",
    productQuantity: "Ürün miktarı",
    isThisProductTaxed: "Bu ürün vergilendiriliyor mu?",
    selectTaxType: "Vergi Türünü Seçin",
    pleaseSelectTaxType: "Lütfen Vergi Türünü seçin",
    progressive: "ilerici",
    proportional: "Orantılı",
    pleaseEnterProductTaxRate: "Lütfen ürün vergi oranını girin",
    doesProductHaveVariants: "Bu ürünün çeşitleri var mı?",
    type: "Tip",
    value: "Değer",
    pleaseEnterVariantType: "Lütfen varyantın türünü girin",
    pleaseEnterVariantValue: "Lütfen varyantın değerini girin",
    pleaseEnterVariantPrice: "Lütfen varyantın fiyatını girin",
    pleaseEnterVariantQuantity: "Lütfen varyantın miktarını girin",
    productDeletedSuccessfully: "Ürün başarıyla silindi!",
    categoryDeletedSuccessfully: "Kategori başarıyla silindi!",
    toDeleteThisProduct: "Bu Ürünü silmek için",
    invalidProductQuantity: "Geçersiz Ürün Miktarı",
    quantityAddedIsOverStock:
      "Eklediğiniz miktar, stokta bulunan miktarın üzerindedir.",
    itemInventoryNotTracked: "Öğe envanteri takip edilmiyor",
    addBulkQuantity: "Toplu Miktar Ekle",
    enterBulkQuantity: "Toplu Miktarı Girin",
    pleaseEnterBulkQuantity: "Lütfen Toplu Miktar girin",
    youveSuccessfullyAddedANewProduct: "Başarıyla yeni bir ürün eklediniz!",
    pleaseEnterProductSellingPrice: "Lütfen ürün satış fiyatını giriniz",
    doYouWantToTrackProductStock: "Ürün stokunu takip etmek ister misiniz?",
    sellingPrice: "Satış fiyatı",
    setProductExpiryReminder: "Ürün Son Kullanma Hatırlatıcısını Ayarla",
    productExpiryDate: "Ürün Son Kullanma Tarihi",
    startRemindingFrom: "Şuradan Hatırlatmaya Başla",
    productSuppliesAddedSuccessfully:
      "Ürün sarf malzemelerini başarıyla eklediniz.",
    addProductSupplies: "Ürün Sarf Malzemeleri Ekle",
    pleaseSelectSupplier: "Lütfen Tedarikçi Seçiniz",
    nameOfProduct: "Ürün Adı",
    pleaseSelectProduct: "Lütfen Ürün Seçiniz",
    productVariant: "Ürün Varyantı",
    pleaseSelectAVariant: "Lütfen Bir Varyant Seçin",
    pleaseEnterUnitPrice: "Lütfen Birim Fiyatını Girin",
    businessBranch: "İş Şubesi",
    pleaseSelectBranch: "Lütfen şube seçin",
    youveSuccessfullyAddedANewSupplier:
      "Başarıyla yeni bir tedarikçi eklediniz",
    addSupplier: "Tedarikçi Ekle",
    pleaseEnterSupplierEmail: "Lütfen tedarikçinin e-posta adresini girin",
    pleaseAddADescription: "Lütfen bir açıklama ekleyin",
    anErrorOccuredProductsDeleted:
      "İşlem gerçekleştirilirken bir hata oluştu. Lütfen dikkat edin, işlem sırasında birkaç ürün silinmiş olabilir",
    bulkDelete: "Toplu Silme",
    youAreAboutToDelete: "silmek üzeresin",
    product: "Ürün",
    isDueToRedeem: "kurtarmak nedeniyle",
    aReward: "bir ödül",
    pleaseSelectCustomerToReeemReward:
      "Lütfen ödülü kullanmak için bir müşteri seçin.",
    youHaveNoLoyaltyProgramRunning:
      "Çalışan aktif bir bağlılık programınız yok",
    customerHhasNoPointsInLoyaltyProgram:
      "Müşterinin bu sadakat programında puanı yok",
    proceedWithPayment: "Ödemeye Devam Edilsin mi?",
    youAreAboutToPayForTransactionUsingPoints:
      "Puan kullanarak işlem için ödeme yapmak üzeresiniz.",
    customerHas: "Müşteri",
    worth: "değer",
    andIsNotSufficientToPayForTransaction:
      "ve bu işlem için ödeme yapmak için yeterli değildir. Bakiyeyi başka bir ödeme yöntemi kullanarak eklemek isterler mi?",
    addCustomerLoyalty: "Müşteri Sadakatini Ekleyin",
    pleaseAddCustomerFirst: "Lütfen önce bir müşteri ekleyin veya seçin.",
    pleaseWaitWhileWeGetReady: "Lütfen bekleyin, biz hazırlanırken",
    lowStock: "Az stok",
    pleaseEnterAmountTendered: "Lütfen teklif edilen tutarı girin",
    areYouSureToBookSaleOffline:
      "Bu indirim için çevrimdışı rezervasyon yapmak istediğinizden emin misiniz?",
    saleWouldBeBookedAutomatically:
      "İnternetinizi açtığınızda satış otomatik olarak rezerve edilir",
    offlineSalesBookingCancelled: "Çevrimdışı satış rezervasyonu iptal edildi",
    covid19Message:
      "COVID19: Yayılmayı durdurmak için ellerinizi sabunla yıkayın veya Sanitize edin. Daima Güvende Kalın",
    saleSuccessfullyRecorded: "Satış Başarıyla Kaydedildi!",
    sendReceiptToEmail: "Makbuzu E-postaya Gönder",
    sendThankYouSms: "Teşekkür SMS'i gönder",
    sendShippingDetails: "Gönderim ayrıntılarını gönder",
    addLoyalty: "Bağlılık Ekle",
    searchCustomerNameOrNumber: "Müşteri adını veya numarasını arayın",
    clickTheSearchCustomerBox:
      "Müşteri Ara Kutusuna ve Tarama Kartına tıklayın",
    enterProductPrice: "Ürün Fiyatını Girin",
    enterPriceFor: "Fiyat Girin",
    searchForProduct: "Ürün ara",
    all: "Tüm",
    backToDashboard: "Gösterge Tablosuna Geri Dön",
    viewDraftSales: "Taslak Satışları Görüntüle",
    variantSelected: "varyant seçildi",
    variant: "varyant",
    thePreviousVariantSelectionNotAvailable:
      "Fiyata göre seçilen yeni varyant için önceki varyant seçimi mevcut değildir, lütfen seçiminizi değiştirin.",
    pleaseSupplyPositiveQuantityNumber:
      "Lütfen pozitif bir miktar numarası sağlayın",
    lowStockFor: "için düşük stok",
    clearVariants: "Varyantları temizle",
    pleaseEnterQuantity: "Lütfen Miktar girin",
    picture: "Resim",
    redemptionToken: "Kullanım Simgesi",
    token: "Jeton",
    totalSpent: "Toplam harcama",
    confirmPayment: "Ödemeyi Onayla",
    hasPaymentBeenMade: "Ödeme başarıyla işlendi mi?",
    enterTransactionReference: "Ödeme yaptığınız işlem referansını girin",
    pleaseSelectACustomer: "Lütfen bir müşteri seçin!",
    areYouSureToApplyDiscount: "İndirim uygulamak istediğinizden emin misiniz?",
    addYourBankAccountToEnableUssd:
      "uPay ile Anında USSD transferini etkinleştirmek için banka hesabınızı ekleyin",
    totalAmountToPay: "Ödenecek Toplam Tutar",
    doYouWantToCancelTransaction: "Bu işlemi iptal etmek istiyor musunuz?",
    savePrintBill: "Faturayı Kaydet/Yazdır",
    enterAmountCollectedForCustomer: "Müşteri için toplanan tutarı girin",
    recordSale: "Satış rekoru",
    checkOutWith: "ile kontrol edin",
    instantTransfer: "Anında Transfer",
    dialTheUSSDCode: "USSD kodunu çevirin",
    pleaseSelectABank: "Lütfen bir banka seçin",
    payWithUSSD: "USSD ile Öde",
    sendBillTo: " - Faturayı şuraya gönder:",
    waitingForUSSDTransfer: "USSD Transferi bekleniyor",
    percent: "Yüzde",
    applyDiscount: "İndirim Uygula",
    thisBillHasBeenSaved: "Bu Fatura kaydedildi",
    saveDraft: "Taslağı kaydet",
    pleaseTypeANameToIdentifyCustomer:
      "Lütfen müşteriyi tanımlamak için bir ad yazın",
    paymentDetails: "Ödeme detayları",
    basePrice: "Taban fiyat",
    staff: "Kadro",
    subTotal: "Ara toplam",
    durationMonths: "Süre (ay)",
    selectADuration: "Bir Süre Seçin",
    oneMonth: "1 ay",
    twoMonths: "2 ay",
    threeMonths: "3 ay",
    sixMonths: "6 ay",
    twelveMonths: "12 ay",
    eighteenMonths: "18 ay",
    twentyFourMonths: "24 ay",
    twoMonthsFree: "(2 Ay Ücretsiz)",
    threeMonthsFree: "(3 Ay Ücretsiz)",
    fiveMonthsFree: "(5 Ay Ücretsiz)",
    yourAccountHasBeen: "Hesabınız",
    renewed: "yenilenmiş",
    upgraded: "yükseltilmiş",
    successfully: "başarıyla",
    yourSubscription: "Aboneliğiniz",
    youAreCurrentlyEnrolledOnA: "Şu anda bir",
    pleaseChooseAPaymentOption: "Lütfen bir Ödeme Seçeneği Seçin",
    planRenewal: "Plan Yenileme",
    planUpgrade: "Plan Yükseltmesi",
    pleaseSelectDurationToPayFor: "Lütfen ödemeyi düşündüğünüz süreyi seçin",
    staffAccounts: "Personel Hesapları",
    ecommerce: "E-ticaret",
    pleaseSelectAPlan: "Lütfen bir plan seçin",
    includeAddons: "Eklentileri Dahil Et",
    viewTransactions: "İşlemleri Görüntüle",
    customerHasNoCompletedTansactions:
      "Müşterinin henüz tamamlanmış işlemi yok",
    branch: "Dal",
    enterNumberOfEcommerceBranches: "E-Ticaret Şube Sayısını Girin",
    enterNumberOfEcommerceBranchesToPay:
      "Ödeme Yapmayı Düşündüğünüz E-Ticaret Şubelerinin Sayısını Girin",
    ecommerceIntegration: "E-ticaret Entegrasyonu",
    enterNumberOfBranches: "Şube Sayısını Girin",
    enterNumberOfAdditionalBranchesToPay:
      "Ödemeyi Düşündüğünüz Ek Şube Sayısını Girin",
    enterNumberOfStaffs: "Personel Sayısını Girin",
    enterNumberOfStaffsToPayFor:
      "Ödeme Yapmayı Düşündüğünüz Personel Sayısını Girin",
    discountApplies: "İndirim Uygulanır",
    starsOnThe: "üzerindeki yıldızlar",
    offer: "teklif",
    get: "Elde etmek",
    moreStarsToRedeem: "kullanmak için daha fazla yıldız",
    taxVat: "Vergi kasası)",
    callCashierToCompletePayment: "Ödemeyi Tamamlamak için Kasiyeri Arayın",
    receipt: "Fiş",
    dear: "canım",
    thankYouForYourOrderFindGoods:
      "Siparişiniz için teşekkür ederiz. Lütfen kararlaştırıldığı gibi sağlanan aşağıdaki malları bulun.",
    shippingNote: "Kargo notu",
    enterShippingNote: "Sevkiyat Notunu Girin",
    shippingAddress: "Teslimat adresi",
    enterShippingAddress: "Gönderim adresini girin",
    wellDoneYouAreDueToRedeem: "Tebrikler! ödemeniz gerekiyor",
    toGetYourRewardNextVisit:
      "Bir sonraki ziyaretinizde ödülünüzü almak için. Teşekkürler",
    pointsOnThe: "üzerindeki noktalar",
    morePointsToRedeem: "kullanmak için daha fazla puan",
    showCode: "Kodu göster",
    toGetYourRewardOnNextVisit:
      "Bir sonraki ziyaretinizde ödülünüzü almak için. Teşekkürler",
    earn: "Para kazanmak",
    delivaryNote: "Teslimat Notu",
    draftSales: "Taslak Satış",
    startDate: "Başlangıç tarihi",
    endDate: "Bitiş tarihi",
    orders: "Emirler",
    checkout: "ödeme",
    noProductItem: "Ürün Öğesi Yok",
    selectProductImage: "Ürün Resmini Seçin",
    selectCountry: "Ülke seçin",
    selectRegion: "Eyalet/Bölge Seçin",
    printProductTag: "Ürün Etiketini Yazdır",
    transactionReference: "İşlem referansı",
    Cashier: "kasiyer",
    Manager: "Yönetici",
    Owner: "Sahip",
    Admin: "yönetici",
    addPartners: "Ortak Ekle",
    addNewLoyaltyPartner: "Yeni Bağlılık Ortağı Ekle",
    pleaseEnterCompanyName: "Lütfen Şirket Adını girin",
    companyName: "Şirket Adı",
    pleaseEnterCompanyRepName: "Lütfen firma temsilcisinin adını giriniz",
    companyRepName: "Şirket Temsilcisinin Adı",
    pleaseEnterCompanyRepEmail:
      "Lütfen şirket temsilcisinin e-posta adresini giriniz",
    companyRepEmail: "Şirket temsilcisinin e-postası",
    pleaseEnterCompanyRepPhone:
      "Lütfen firma temsilcisinin telefon numarasını giriniz",
    companyRepPhone: "Şirket temsilcisinin telefon numarası",
    addReward: "ödül ekle",
    pleaseEnterRewardName: "Lütfen ödül adını girin",
    rewardName: "Ödül Adı",
    rewardQuantity: "Ödül Miktarı",
    rewardDescription: "Ödül Açıklaması",
    rewardType: "Ödül Türü",
    pleaseEnterRewardType: "Lütfen ödül türünü girin",
    pleaseEnterRewardQuantity: "Lütfen ödül miktarını girin",
    pleaseEnterRewardDescription: "Lütfen ödül açıklamasını girin",
    fineDining: "Kaliteli Yemek",
    luxuryFashion: "lüks moda",
    hotels: "Oteller",
    travel: "Yolculuk",
    foodAndBeverage: "Yiyecek ve içecek",
    fashion: "Moda",
    health: "Sağlık",
    furniture: "Mobilya",
    entertainment: "Eğlence",
    automobile: "Otomobil",
    education: "Eğitim",
    beautyAndSpa: "Güzellik ve Spa",
    staycation: "konaklama",
    events: "Olaylar",
    trips: "Geziler",
    oilAndGas: "Yağ ve gaz",
    laundry: "Çamaşır",
    partnerCategory: "Ortak Kategorisi",
    freeItem: "Ücretsiz Öğe",
  },
  Thai: {
    cashier: "แคชเชียร์",
    manager: "ผู้จัดการ",
    owner: "เจ้าของ",
    online: "ออนไลน์",
    offline: "ออฟไลน์",
    changePassword: "เปลี่ยนรหัสผ่าน",
    currentPasswordMessage: "กรุณาใส่รหัสผ่านปัจจุบันของคุณ",
    passwordMessage: "กรุณาใส่รหัสผ่านของคุณ",
    currentPassword: "รหัสผ่านปัจจุบัน",
    password: "รหัสผ่าน",
    confirmPasswordMessage: "กรุณายืนยันรหัสผ่านของคุณ!",
    confirmPassword: "ยืนยันรหัสผ่าน",
    sendViaEmail: "ส่งทางอีเมล",
    sendViaWhatsapp: "ส่งผ่าน WhatsApp",
    downloadPdf: "ดาวน์โหลด PDF",
    paid: "จ่าย",
    unpaid: "ค้างชำระ",
    partial: "บางส่วน",
    closeInvoice: "คุณต้องการปิดใบแจ้งหนี้หรือไม่?",
    closeInvoiceConfirmation:
      "ไม่สามารถบันทึกใบแจ้งหนี้ได้ คุณต้องการที่จะปิด?",
    yes: "ใช่",
    no: "ไม่",
    invoice: "ใบแจ้งหนี้",
    wasDeducted: "ถูกหัก",
    for: "สำหรับ",
    item: "รายการ",
    addProduct: "เพิ่มสินค้า",
    paymentReference: "หลักฐานการจ่ายเงิน",
    amountPaid: "จำนวนเงินที่จ่าย",
    discountAmount: "จำนวนส่วนลด",
    amountDue: "จำนวนที่เหลือจำนวนที่ยังค้างอยู่",
    amount: "จำนวน",
    dueDate: "วันครบกำหนด",
    paymentType: "ประเภทการชำระเงิน",
    card: "การ์ด",
    cash: "เงินสด",
    bankTransfer: "โอนเงินผ่านธนาคาร",
    paymentMessage: "ข้อความการชำระเงิน",
    description: "คำอธิบาย",
    sendReceipt: "ส่งใบเสร็จรับเงิน",
    delete: "ลบ",
    save: "บันทึก",
    resend: "ส่งซ้ำ",
    saveAndSend: "บันทึก",
    invoiceSaved: "บันทึกใบแจ้งหนี้แล้ว!",
    selectPaymentMethod: "โปรดเลือกวิธีการชำระเงิน!",
    selectCustomer: "กรุณาเลือกลูกค้า!",
    cartEmptyError:
      "รายการรถเข็นต้องไม่ว่างเปล่า ปิดใบแจ้งหนี้และเพิ่มรายการลงตะกร้า!",
    subscriptionExpired:
      "การสมัครของคุณหมดอายุและบัญชีของคุณถูกจำกัด คลิกที่ปุ่มด้านล่างเพื่อต่ออายุบัญชีของคุณ",
    renew: "ต่ออายุ",
    holdOn: "เดี๋ยว",
    customerBank: "ธนาคารลูกค้า",
    cancel: "ยกเลิก",
    selectACustomer: "เลือกลูกค้า",
    invoiceSuccessfulPdfError:
      "สร้างใบแจ้งหนี้สำเร็จแล้ว แต่การสร้าง PDF ใช้เวลานานกว่าปกติ โปรดกลับมาตรวจสอบใหม่เร็วๆ นี้",
    downloadingInvoice: "กำลังดาวน์โหลดใบแจ้งหนี้",
    downloadingReceipt: "กำลังดาวน์โหลดใบเสร็จ",
    whatsappReceiptError:
      "เกิดข้อผิดพลาดในการส่งใบเสร็จผ่าน WhatsApp โปรดลองอีกครั้ง",
    receiptToWhatsapp: "ใบเสร็จส่งต่อไปยัง WhatsApp",
    thankYouForPatronage: "ขอบคุณสำหรับการอุปถัมภ์",
    hereIsYourReceipt: "นี่คือใบเสร็จรับเงินของคุณ",
    errorSendingEmailReceipt:
      "เกิดข้อผิดพลาดในการส่งใบเสร็จรับเงินทางอีเมล โปรดลองอีกครั้งหรือติดต่อฝ่ายสนับสนุน",
    receiptSentToEmail: "ใบเสร็จรับเงินถูกส่งไปยังอีเมลของลูกค้าแล้ว",
    invoiceSentToEmail: "ส่งใบแจ้งหนี้ไปยังอีเมลของลูกค้าแล้ว",
    invoiceSuccessWhatsappError:
      "สร้างใบแจ้งหนี้สำเร็จแต่เกิดข้อผิดพลาดในการส่งไปยัง WhatsApp ลองอีกครั้งในรายการใบแจ้งหนี้",
    invoiceSuccessfulEmailError:
      "สร้างใบแจ้งหนี้สำเร็จแต่เกิดข้อผิดพลาดขณะส่งเป็นอีเมล โปรดลองอีกครั้งจากรายการใบแจ้งหนี้",
    invoiceSentToWhatsapp: "ใบแจ้งหนี้ที่ส่งต่อไปยัง WhatsApp",
    hello: "สวัสดี",
    pleaseDownloadInvoice: "กรุณาดาวน์โหลดใบแจ้งหนี้",
    pleaseDownloadReceipt: "กรุณาดาวน์โหลดใบเสร็จ",
    from: "จาก",
    email: "อีเมล",
    upgrade: "อัพเกรด",
    youAreOnFreePlan: "คุณอยู่ในแผนบริการฟรี",
    clickOn: "คลิกที่",
    yourPlanInFewSteps: " การสมัครของคุณในไม่กี่ขั้นตอนง่ายๆ",
    to: "ถึง",
    yourSubscriptionHasExpired: "การสมัครของคุณหมดอายุและบัญชีของคุณถูกจำกัด",
    days: "วัน",
    yourSubscriptionExpiresIn: "การสมัครสมาชิก Loystar ของคุณจะหมดอายุใน",
    createAcount: "สร้างบัญชี",
    signIn: "เข้าสู่ระบบ",
    continue: "ดำเนินการต่อ",
    enterOtp: "ป้อนรหัส OTP",
    enterValidOtp: "โปรดป้อน PIN ที่ถูกต้อง",
    pin: "เข็มหมุด",
    tokenSentToMail: "โทเค็นถูกส่งไปยังอีเมลของคุณแล้ว",
    passwordResetSuccessful: "รีเซ็ตรหัสผ่านสำเร็จ",
    somethingWentWrong: "อะไรบางอย่างผิดปกติ!",
    resetPassword: "รีเซ็ตรหัสผ่าน",
    iHaveResetCode: "ฉันมีรหัสรีเซ็ตรหัสผ่าน",
    pleaseEnterEmail: "กรุณากรอกอีเมล์ของคุณ",
    aTokenWillBeSentToEmail: "โทเค็นจะถูกส่งไปยังอีเมลของคุณ",
    enterEmail: "กรอกอีเมล์ของคุณ",
    sendinYourToken: "กำลังส่งโทเค็นของคุณ...",
    makeSureItMatchesPassword: "ตรวจสอบให้แน่ใจว่าตรงกับรหัสผ่านใหม่ของคุณ",
    pleaseChooseNewPassword: "โปรดเลือกรหัสผ่านใหม่",
    chooseNewPassword: "เลือกรหัสผ่านใหม่",
    enterNewPassword: "ใส่รหัสผ่านใหม่ของคุณเพื่อยืนยัน",
    enterTokenSent: "ป้อนโทเค็นที่ส่งจดหมายของคุณ",
    resetToken: "รีเซ็ตโทเค็น",
    resettingPassword: "กำลังรีเซ็ตรหัสผ่านของคุณ...",
    signUp: "ลงชื่อ",
    adminSignInWithEmail:
      " ผู้ดูแลระบบลงชื่อเข้าใช้ด้วยอีเมลในขณะที่พนักงานลงชื่อเข้าใช้ด้วยชื่อผู้ใช้",
    pleaseEnterEmailOrUsername: "กรุณากรอกอีเมล์หรือชื่อผู้ใช้",
    emailOrUsername: "อีเมล์หรือชื่อผู้ใช้",
    pleaseEnterPassword: "กรุณาใส่รหัสผ่าน",
    createAnAccount: "สร้างบัญชี",
    forgotPassword: "ลืมรหัสผ่าน?",
    enterPhoneNumber: "ใส่หมายเลขโทรศัพท์",
    personalData: "ข้อมูลส่วนบุคคล",
    validateConfirmationCOde: "ตรวจสอบรหัสยืนยัน",
    pleaseEnterFirstName: "กรุณากรอกชื่อของท่าน",
    pleaseEnterPhoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ของท่าน",
    pleaseEnterLastName: "กรุณาใส่นามสกุลของคุณ",
    pleaseEnterBusinessName: "กรุณาใส่ชื่อธุรกิจของคุณ",
    firstName: "ชื่อจริง",
    lastName: "นามสกุล",
    businessName: "ชื่อธุรกิจ",
    previous: "ก่อนหน้า",
    next: "ต่อไป",
    pleaseSelectBusinessCategory: "กรุณาเลือกหมวดหมู่ธุรกิจของคุณ",
    pleaseEnterValidEmail: "กรุณาใส่อีเมลที่ถูกต้อง",
    pleaseEnterPostCode: "กรุณาใส่รหัสไปรษณีย์",
    postCode: "รหัสไปรษณีย์",
    phoneNumberInUse: "หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว!",
    emailInUse: "อีเมลนี้ถูกใช้ไปแล้ว!",
    foodAndDrinks: "อาหารและเครื่องดื่ม",
    salonAndBeauty: "ซาลอนและความงาม",
    fashionAndAccessories: "แฟชั่นและเครื่องประดับ",
    gymAndFitness: "โรงยิม และ ฟิตเนส",
    travelAndHotel: "ท่องเที่ยวและโรงแรม",
    homeAndGifts: "บ้านและของขวัญ",
    washingAndCleaning: "ซักและทำความสะอาด",
    gadgetsAndElectronics: "แกดเจ็ตและอิเล็กทรอนิกส์",
    groceries: "ของชำ",
    others: "คนอื่น",
    submit: "ส่ง",
    accountCreatedSuccessful: "บัญชีของคุณสร้างสำเร็จแล้ว",
    pleaseEnterAddress: "กรุณากรอกที่อยู่ของคุณ",
    addressLine1: "ที่อยู่บรรทัดที่ 1",
    addressLine2: "ที่อยู่บรรทัดที่ 2",
    choosePassword: "เลือกรหัสผ่าน",
    passwordMustBe6Characters: "รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร",
    howDidYouHearLoystar: "คุณรู้จัก Loystar ได้อย่างไร?",
    referralCode: "รหัสอ้างอิง",
    byClickingTheButton: " การคลิกปุ่มด้านล่างแสดงว่าคุณยอมรับ",
    termsAndSevice: "เงื่อนไข",
    wereCreatingAccount: "เรากำลังสร้างบัญชีของคุณ",
    proceed: "ดำเนินการ",
    verificationCodeMustBe6: "รหัสยืนยันต้องเป็น 6 หลัก",
    pleaseEnter6DigitCode: "กรุณากรอกรหัส 6 หลัก",
    enterVerificationCode: "ใส่รหัสยืนยัน",
    resendToken: "ส่งโทเค็นอีกครั้ง",
    verify: "ตรวจสอบ",
    transactions: "ธุรกรรม",
    todaySales: "ยอดขายวันนี้",
    salesHistory: "ประวัติการขาย",
    supplyHistory: "ประวัติการจัดหา",
    new: "ใหม่",
    invoices: "ใบแจ้งหนี้",
    disbursements: "การเบิกจ่าย",
    offlineSales: "การขายออฟไลน์",
    products: "สินค้า",
    customers: "ลูกค้า",
    multiLevelLoyalty: "ความภักดีหลายระดับ",
    loyaltyPrograms: "โปรแกรมความภักดี",
    members: "สมาชิก",
    appStore: "แอพสโตร์",
    orderMenu: "เมนูสั่ง",
    settings: "การตั้งค่า",
    staffAndBranches: "พนักงานและสาขา",
    myAccount: "บัญชีของฉัน",
    switchToSellMode: "เปลี่ยนไปใช้โหมดการขาย",
    signOut: "ออกจากระบบ",
    getFreeSubscription: "สมัครสมาชิกฟรี",
    onlyNumbersAllowed: "อนุญาตเฉพาะตัวเลขเท่านั้น",
    yourAccountMustBe10Digits: "หมายเลขบัญชีของคุณต้องเป็นตัวเลข 10 หลัก",
    yourBvnMustBe11: "BVN ของคุณต้องเป็นตัวเลข 11 หลัก",
    pleaseSelectBank: "โปรดเลือกธนาคารของคุณ",
    selectYourBank: "เลือกธนาคารของคุณ",
    enterBankAccountNumber: "ใส่เลขที่บัญชีธนาคาร",
    enterBvnNumber: "ป้อนBVN .ของคุณ",
    connectBankAccount: "เชื่อมต่อบัญชีธนาคาร",
    passwordResetSuccessfulAndSignOut:
      "รีเซ็ตรหัสผ่านของคุณสำเร็จแล้ว คลิกที่ปุ่มออกจากระบบด้านล่างเพื่อลงชื่อเข้าใช้อีกครั้ง",
    enterCurrentPassword: "ใส่รหัสผ่านปัจจุบัน",
    pleaseEnterCurrentPassword: "กรุณาใส่รหัสผ่านปัจจุบัน",
    phoneNumber: "หมายเลขโทรศัพท์",
    sex: "เพศ",
    dateOfBirth: "วันเกิด",
    state: "สถานะ",
    country: "ประเทศ",
    loyaltyId: "รหัสความภักดี",
    createdAt: "สร้าง At",
    noOfTransactionsMade: "จำนวนรายการที่ทำ",
    yourDownloadWillStart: "การดาวน์โหลดของคุณจะเริ่มในอีกสักครู่",
    exportCustomers: "ลูกค้าส่งออก",
    youhaveSuccessfullyToppedUp: "คุณได้เติมเงินหน่วย SMS ของคุณสำเร็จแล้ว",
    youNowHave: "ตอนนี้คุณมี",
    smsUnits: "หน่วย SMS",
    enterNumberOfSmsUnits: "ป้อนจำนวนหน่วย SMS ที่คุณต้องการซื้อ",
    pleaseEnterANumericValue: "กรุณากรอกค่าตัวเลข",
    pay: "จ่าย",
    accountEditedSuccessfully: "บัญชีของคุณได้รับการแก้ไขเรียบร้อยแล้ว",
    youAeCurrentlyOn: "คุณกำลังอยู่ใน",
    plan: "วางแผน",
    userData: "ข้อมูลผู้ใช้",
    businessData: "ข้อมูลธุรกิจ",
    businessCategory: "หมวดหมู่ธุรกิจ",
    pleaseSelectCurrency: "กรุณาเลือกสกุลเงินของคุณ",
    selectYourCurrency: "เลือกสกุลเงินของคุณ",
    purchaseMoreSmsUnits: "ซื้อหน่วย SMS เพิ่มเติมโดยใช้แบบฟอร์มด้านล่าง",
    youHave: "คุณมี",
    atLeast4SmsUnitsRrequired:
      "ต้องมีหน่วย SMS อย่างน้อย 4 หน่วยเพื่อยืนยัน โปรดเติมเงิน!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "โปรดตรวจสอบบัญชีธนาคารของคุณเพื่อให้เป็นไปตามข้อกำหนด 'รู้จักลูกค้าของคุณ' (KYC) ซึ่งจะช่วยให้คุณเรียกเก็บเงินผ่าน USSD หรือการโอนเงินทันที รับคำสั่งซื้อจากลูกค้า และดำเนินการชำระธุรกรรม ใช้ได้กับพ่อค้าชาวไนจีเรียเท่านั้น กรุณากดปุ่มด้านล่างเพื่อเริ่มต้น",
    reConnectBankAccount: "เชื่อมต่อบัญชีธนาคารอีกครั้ง",
    accountName: "ชื่อบัญชี",
    accountNumber: "หมายเลขบัญชี",
    bankName: "ชื่อธนาคาร",
    verified: "ตรวจสอบแล้ว",
    accountDetails: "รายละเอียดบัญชี",
    kycBankAccount: "KYC",
    createTier: "สร้างระดับ",
    fileUploadSuccessful: "อัพโหลดไฟล์สำเร็จ",
    fileUploadFailed: "อัพโหลดไฟล์ไม่สำเร็จ",
    createLoyaltyProgram: "สร้างโปรแกรมความภักดี",
    createLoyalty: "สร้างความภักดี",
    name: "ชื่อ",
    loyaltyArtwork: "ความภักดีงานศิลปะ",
    clickToUpload: "คลิกเพื่ออัพโหลด",
    amountToPointsRatio: "อัตราส่วนจำนวนต่อคะแนน",
    points: "คะแนน",
    recommendedAmountToPointRatio:
      "แนะนำ : ₦1 ถึง 1 จุด เช่น ทุกๆ 1 Naira ที่ใช้จ่าย ลูกค้าของคุณจะได้รับ 1 คะแนน",
    pleaseTypeIn: "กรุณาพิมพ์",
    toDeleteLoyalty: "เพื่อลบความภักดีนี้",
    deleteLoyalty: "ลบความภักดี",
    toConfirm: "เพื่อยืนยัน",
    edit: "แก้ไข",
    pointsAwardedSuccessfully: "คะแนนที่ได้รับสำเร็จ",
    enterPointValueToAward: "ป้อนค่าคะแนนที่คุณต้องการให้รางวัลแก่ลูกค้า",
    award: "รางวัล",
    awardPointValuesToCustomer: "มูลค่าคะแนนรางวัลให้กับลูกค้า",
    enrollMembersToLoyalty: "ลงทะเบียนสมาชิกเข้าสู่ความภักดี",
    awardPoints: "คะแนนรางวัล",
    enroll: "ลงทะเบียน",
    home: "บ้าน",
    loyalty: "ความภักดี",
    enrollCustomers: "ลงทะเบียนลูกค้า",
    selected: "เลือกแล้ว",
    customer: "ลูกค้า",
    loyaltyActivationSuccessful: "การเปิดใช้งานความภักดีสำเร็จ",
    loyaltyDeactivationSuccessful: "การปิดใช้งานความภักดีสำเร็จ",
    viewTier: "ดูระดับ",
    deactivate: "ยกเลิกการเปิดใช้งาน",
    activate: "เปิดใช้งาน",
    actions: "การกระทำ",
    nameOfLoyalty: "ชื่อของความภักดี",
    loyaltyStatus: "สถานะความภักดี สถานะความภักดี",
    numberOfTiers: "จำนวนชั้น",
    createdOn: "สร้างบน",
    createATier: "สร้าง Tier",
    stopCreatingTierConfirmation: "คุณต้องการหยุดสร้างระดับหรือไม่",
    stopEditingTierConfirmation: "คุณต้องการหยุดแก้ไขระดับนี้หรือไม่",
    nameOfTier: "ชื่อชั้น",
    minimumSpendingTarget: "เป้าหมายการใช้จ่ายขั้นต่ำ",
    maximumSpendingTarget: "เป้าหมายการใช้จ่ายสูงสุด",
    minimumSpendingTargetRequired: "ต้องมีเป้าหมายการใช้จ่ายขั้นต่ำ",
    maximumSpendingTargetRequired: "ต้องกำหนดเป้าหมายการใช้จ่ายสูงสุด",
    rewardSponsor: "ผู้สนับสนุนรางวัล",
    pleaseChooseARewardSponsor: "โปรดเลือกผู้สนับสนุนรางวัล",
    self: "ตัวเอง",
    partner: "พันธมิตร",
    rewardPartner: "รางวัลพันธมิตร",
    pleaseSelectRewardPartner: "โปรดเลือกพันธมิตรรางวัลของคุณ",
    rewards: "รางวัล",
    pleaseSelectAReward: "โปรดเลือกรางวัล",
    instructionsOnRedeemingReward: "คำแนะนำเกี่ยวกับวิธีการแลกรางวัลของลูกค้า",
    pleaseFillInThisField: "กรุณากรอกข้อมูลในช่องนี้!",
    toDeleteThisTier: " เพื่อลบระดับนี้",
    deleteTier: "ลบระดับ",
    viewMembers: "ดูสมาชิก",
    membersEnrolled: "สมาชิกที่ลงทะเบียน",
    instruction: "การเรียนการสอน",
    membersIn: "สมาชิกใน",
    availableTiersInLoyalty: "ระดับที่มีอยู่ในโปรแกรมความภักดี",
    tiers: "เทียร์",
    totalTier: "ระดับรวม",
    availableLoyaltyProgramme: "โปรแกรมความภักดีที่มีอยู่",
    totalLoyalties: "ความภักดีทั้งหมด",
    memberDetails: "รายละเอียดสมาชิก",
    nameOfCustomer: "ชื่อลูกค้า",
    address: "ที่อยู่",
    allEnrolledMembers: "สมาชิกที่ลงทะเบียนทั้งหมด",
    thisIsToWishYouHappyBirthday:
      "นี่คือการขอให้คุณมีความสุขในวันเกิดและชีวิตที่เจริญรุ่งเรือง ขอบคุณสำหรับทุกสิ่งที่คุณมีต่อ Loyster สุขสันต์วันเฉลิมฉลอง!",
    inputAnOfferPlease: "กรุณากรอกข้อเสนอ",
    pleaseSelectTheInsertPoint: "โปรดเลือกจุดแทรกในข้อความแล้วคลิกอีกครั้ง",
    birthdayOfferAndMessage: "ข้อเสนอวันเกิดและข้อความ",
    birthdayOffer: "ข้อเสนอวันเกิด",
    birthdayMessage: "ข้อความวันเกิด",
    noOfferFound: "ไม่พบข้อเสนอ",
    settingABirthdayOffer:
      "การตั้งข้อเสนอวันเกิดช่วยให้ลูกค้าสามารถรับข้อเสนอนี้ทาง SMS ในวันเกิดได้",
    createOffer: "สร้างข้อเสนอ",
    whatIsTheOffer: "ข้อเสนอคืออะไร?",
    editMessage: "แก้ไขข้อความ",
    insert: "แทรก",
    theNameOfCustomerInserted: "จะใส่ชื่อลูกค้าที่นี่",
    theBirtdayOfferInserted: "ข้อเสนอวันเกิดจะถูกแทรกที่นี่",
    youSuccessfullyAddedNewBranch: "คุณเพิ่มสาขาใหม่สำเร็จแล้ว!",
    addNewBranch: "เพิ่มสาขาใหม่",
    addBranch: "เพิ่มสาขา",
    additionalBranchWillIncur: "สาขาเพิ่มเติมจะเกิดขึ้น",
    perBranch: "ต่อสาขา",
    ecommerceBranchCosts: "ค่าใช้จ่ายสาขาอีคอมเมิร์ซ",
    pleaseEnterBranchName: "กรุณากรอกชื่อสาขา",
    pleaseEnterBranchAddress1: "กรุณากรอกที่อยู่ของสาขาบรรทัดที่ 1",
    enterBranchAddress1: "ป้อนที่อยู่ของสาขาบรรทัด 1",
    enterBranchAddress2: "ป้อนที่อยู่ของสาขาบรรทัด 1",
    pleaseEnterBranchAddress2: "กรุณากรอกที่อยู่ของสาขาบรรทัดที่ 2",
    enterBranchName: "ใส่ชื่อสาขา",
    successfullyAddedStaff: "คุณเพิ่มพนักงานใหม่สำเร็จแล้ว!",
    addNewStaff: "เพิ่มพนักงานใหม่",
    addStaff: "เพิ่มพนักงาน",
    additionalStaffWillIncur: "พนักงานเพิ่มเติมจะต้องรับผิดชอบ",
    perStaff: "ต่อพนักงาน",
    pleaseEnterStaffEmail: "กรุณากรอกอีเมล์เจ้าหน้าที่",
    pleaseEnterStaffUsername: "กรุณากรอก username ของพนักงาน",
    pleaseEnterStaffPassword: "กรุณากรอกรหัสผ่านพนักงาน",
    pleaseSelectStaffRole: "กรุณาเลือกบทบาทของเจ้าหน้าที่",
    selectStaffRole: "เลือกบทบาทของพนักงาน",
    enterStaffEmail: "ใส่อีเมล์พนักงาน",
    enterStaffUsername: "ใส่ชื่อผู้ใช้ของพนักงาน",
    enterStaffPassword: "ใส่รหัสผ่านพนักงาน",
    spacesNotAllowedInUsername: "ไม่อนุญาตให้เว้นวรรคในชื่อผู้ใช้",
    admin: "แอดมิน",
    pleaseSelectBusinessToAttachStaff: "กรุณาเลือกธุรกิจเพื่อแนบพนักงาน",
    searchForBranchToAttachStaff: "ค้นหาสาขาเพื่อแนบพนักงาน",
    username: "ชื่อผู้ใช้",
    role: "บทบาท",
    areYouSureToDeleteThis: "คุณแน่ใจหรือว่าลบสิ่งนี้",
    branches: "สาขา",
    upgradeYourPlan: "อัปเกรดแผนของคุณ",
    add: "เพิ่ม",
    addNew: "เพิ่มใหม่",
    customerWithEmailAlreadyExists:
      "มีลูกค้าที่มีอีเมล/หมายเลขโทรศัพท์อยู่แล้ว!",
    successfullyAddedNewCustomer: "คุณเพิ่มลูกค้าใหม่สำเร็จแล้ว!",
    addCustomer: "เพิ่มลูกค้า",
    pleaseEnterCustomerFirstName: "กรุณากรอกชื่อลูกค้า",
    pleaseEnterCustomerLastName: "กรุณากรอกนามสกุลของลูกค้า",
    pleaseEnterCustomerPhoneNumber: "กรุณากรอกหมายเลขโทรศัพท์ของลูกค้า",
    pleaseEnterCustomerEmail: "กรุณากรอกอีเมล์ของลูกค้า",
    pleaseEnterCustomerAddressLine: "กรุณากรอกที่อยู่ของลูกค้า Line",
    pleaseEnterCustomerOtherAddress: "กรุณากรอกที่อยู่อื่นของลูกค้า",
    pleaseSelectCustomerGender: "กรุณาเลือกเพศของลูกค้า",
    gender: "เพศ",
    male: "ชาย",
    female: "หญิง",
    bank: "ธนาคาร",
    selectBank: "เลือกธนาคาร",
    stateOrRegionOrProvince: "รัฐ/ภูมิภาค/จังหวัด",
    customerNotes: "หมายเหตุลูกค้า",
    sendSms: "ส่ง SMS",
    editCustomer: "แก้ไขลูกค้า",
    redeemReward: "แลกรางวัล",
    issueLoyaltyCard: "ออกบัตรสะสมคะแนน",
    deleteCustomer: "ลบลูกค้า",
    youveSuccessfullyAssignedLoyaltyMembership:
      "คุณได้กำหนดรหัสสมาชิกความภักดีสำเร็จแล้ว",
    noMembershipIdAvailable:
      "ไม่มีรหัสสมาชิก โปรดติดต่อเราที่ hello@loystar.co",
    sendEmail: "ส่งอีเมล",
    membershipPoints: "คะแนนสมาชิก",
    customerDetails: "รายละเอียดลูกค้า",
    close: "ปิด I",
    loyaltyBalance: "ยอดคงเหลือความภักดี",
    pointsBalance: "ยอดคะแนน",
    starBalance: "สตาร์ บาลานซ์",
    requiredPoints: "คะแนนที่ต้องการ",
    requiredStars: "ดาวที่ต้องการ",
    reddemCode: "แลกรหัส",
    toDeleteThisCustomer: "เพื่อลบลูกค้ารายนี้",
    customerHasBeenDeletedSuccessfully: "ลบลูกค้าเรียบร้อยแล้ว!",
    customerWithPhoneAlreadyExists: "ลูกค้าที่มีหมายเลขโทรศัพท์อยู่แล้ว",
    customerWasSuccessfullyEdited: "แก้ไขลูกค้าเรียบร้อยแล้ว",
    anErrorOccured: "เกิดข้อผิดพลาด",
    phoneNumberIncludeCountry: "หมายเลขโทรศัพท์ (รวมรหัสประเทศ)",
    yourFileQueuedForUpload:
      "ไฟล์ของคุณอยู่ในคิวสำหรับการอัปโหลด โปรดรีเฟรชหน้าหลังจากไม่กี่วินาที",
    thereWasAnErrorPerformingOperation: "เกิดข้อผิดพลาดในการดำเนินการ!",
    pleaseSelectFile: "กรุณาเลือกไฟล์!",
    oopsOnlyCsvFilesAllowed: "อ๊ะ! อนุญาตเฉพาะไฟล์ CSV โปรดอัปโหลดไฟล์ .csv",
    fileShouldBeSmallerThan5Mb:
      "ไฟล์ควรมีขนาดเล็กกว่า 5MB หากคุณมีไฟล์ขนาดใหญ่ โปรดส่งอีเมลมาที่ support@loystar.co ขอขอบคุณ",
    customerFirstNameIsRequired: "ต้องระบุชื่อลูกค้าในแถว",
    customerPhoneNumberIsRequired: "ต้องระบุหมายเลขโทรศัพท์ของลูกค้าในแถว",
    importCustomers: "ลูกค้านำเข้า",
    upload: "ที่อัพโหลด",
    clickIconToDownloadCsv: "คลิกไอคอนนี้เพื่อดาวน์โหลดเทมเพลตไฟล์ CSV",
    getGoogleSheetVersion: "รับเวอร์ชัน Google ชีต",
    clickOrDragFileToUpload: "คลิกหรือลากไฟล์มาที่บริเวณนี้เพื่ออัปโหลด",
    missingOrInvalidColumnHeader:
      "ส่วนหัวของคอลัมน์ขาดหายไปหรือไม่ถูกต้อง โปรดปฏิบัติตามรูปแบบเทมเพลต ขอขอบคุณ.",
    youHaveImported: "คุณได้นำเข้า",
    youSuccessfullyRedeemedReward: "คุณแลกรางวัลสำเร็จแล้ว!",
    sixDigitCode: "รหัสหกหลัก",
    pleaseEnterCustomerRewardCode: "กรุณากรอกรหัสรางวัลของลูกค้า",
    enterRewardCode: "ใส่รหัสรางวัล (รหัสรางวัลเป็นกรณี ๆ ไป)",
    selectLoyaltyProgram: "เลือกโปรแกรมความภักดี",
    stamps: "แสตมป์",
    smsUnitsLow: "หน่วย SMS ต่ำ",
    pleaseTopUpSmsUnits: "กรุณาเติมเงินหน่วย SMS",
    smsSuccessfullySentForDelivery: "ส่ง SMS เรียบร้อยแล้วสำหรับการจัดส่ง!",
    sendSmsTo: "ส่ง SMS ไปที่",
    allCustomers: "ลูกค้าทุกท่าน",
    unitsAvailable: "หน่วยที่มีจำหน่าย",
    pleaseTypeInTheMessage: "กรุณาพิมพ์ข้อความ",
    message: "ข้อความ",
    charactersRemaining: "ตัวอักษรที่เหลืออยู่",
    avoidUseOfSpecialCharacters:
      "หลีกเลี่ยงการใช้อักขระพิเศษและอิโมจิเพื่ออนุรักษ์หน่วย SMS",
    note: "บันทึก",
    errorFetchingCustomersMultilevelDetail:
      "เกิดข้อผิดพลาดในการดึงข้อมูลลูกค้าหลายระดับ",
    search: "ค้นหา",
    reset: "รีเซ็ต",
    importCustomer: "ลูกค้านำเข้า",
    addNewCustomer: "เพิ่มลูกค้าใหม่",
    sendSmsBroadcast: "ส่ง SMS ออกอากาศ",
    totalCustomers: "ลูกค้าทั้งหมด",
    disbursementDetails: "รายละเอียดการเบิกจ่าย",
    paidBy: "จ่ายโดย",
    disbursed: "เบิกจ่าย",
    bankAccountName: "ชื่อบัญชีธนาคาร",
    bankAccountNumber: "หมายเลขบัญชีธนาคาร",
    transferInitiated: "เริ่มโอนแล้ว",
    transferCompleted: "โอนเสร็จ",
    pleaseEnterAValid: "กรุณาใส่ valid",
    begin: "เริ่ม",
    end: "จบ",
    date: "วันที่",
    paymentDate: "วันจ่าย",
    selectDisbursementDuration: "เลือกระยะเวลาการเบิกจ่าย",
    totalSettled: "ตัดสินทั้งหมด",
    totalUnsettled: "รวมไม่แน่นอน",
    toDeleteThisSale: "เพื่อลบการขายนี้",
    draftSaleDeletedSuccessfully: "ลบร่างการขายเรียบร้อยแล้ว!",
    deleteSale: "ลบการขาย",
    pleaseTypeInYourTotal: "กรุณาพิมพ์ผลรวมเพื่อยืนยันการลบ",
    billDetails: "รายละเอียดบิล",
    printBill: "พิมพ์บิล",
    servedBy: "ให้บริการโดย",
    total: "รวม",
    createdDate: "วันที่สร้าง",
    createdTime: "สร้างเวลา",
    status: "สถานะ",
    loginSuccessful: "เข้าสู่ระบบสำเร็จ",
    pleaseWaitWhileWeConnectAccount: "โปรดรอในขณะที่เราเชื่อมต่อบัญชีของคุณ",
    connectionFailedTryAgain: "การเชื่อมต่อล้มเหลว. กรุณาลองอีกครั้ง.",
    connectionSuccessful: "การเชื่อมต่อสำเร็จ",
    viewDetails: "ดูรายละเอียด",
    enable: "เปิดใช้งาน",
    free: "ฟรี",
    cost: "ค่าใช้จ่าย",
    visitWebsite: "เข้าไปดูในเว็บไซต์",
    pleaseUpgradeYourPlanToPro:
      "โปรดอัปเกรดแผนของคุณเป็น Pro Plus เพื่อเปิดใช้งานแอปนี้",
    connectYourBankAccount: "เชื่อมต่อบัญชีธนาคารของคุณเพื่อรับการชำระเงิน",
    disable: "ปิดการใช้งาน",
    enableApp: "เปิดใช้งานแอพ",
    addNewProductToInvoice: "เพิ่มสินค้าใหม่ในใบแจ้งหนี้",
    toDeleteThisInvoice: "เพื่อลบใบแจ้งหนี้นี้",
    invoiceDeletedSuccessfully: "ใบแจ้งหนี้ถูกลบเรียบร้อยแล้ว!",
    deleteInvoice: "ลบใบแจ้งหนี้",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "คุณเพิ่มข้อความการชำระเงินตามใบแจ้งหนี้สำเร็จแล้ว!",
    pleaseEnterPaymentInstructions: "กรุณากรอกขั้นตอนการชำระเงิน",
    invoiceId: "รหัสใบแจ้งหนี้",
    paidDate: "วันที่จ่าย",
    reference: "อ้างอิง",
    productAdded: "สินค้าเพิ่ม",
    productOutOfStock: "สินค้าหมด. กรุณาสต๊อกสินค้าอีกครั้ง",
    totalInvoices: "ใบแจ้งหนี้ทั้งหมด",
    totalPaidInvoices: "ใบแจ้งหนี้ที่ชำระทั้งหมด",
    totalUnpaidInvoices: "รวมใบแจ้งหนี้ที่ยังไม่ได้ชำระ",
    loyaltyProgramDeleted: "โปรแกรมความภักดีถูกลบ",
    thisLoyaltyProgramDeletedSuccessfully:
      "โปรแกรมความภักดีนี้ถูกลบเรียบร้อยแล้ว",
    thisLoyaltyProgramEditedSuccessfully:
      "โปรแกรมความภักดีนี้ได้รับการแก้ไขเรียบร้อยแล้ว",
    loyaltyProgramAddedSuccessfully: "เพิ่มโปรแกรมความภักดีเรียบร้อยแล้ว",
    loyaltyProgramEdited: "แก้ไขโปรแกรมความภักดี",
    loyaltyProgramAdded: "เพิ่มโปรแกรมความภักดี",
    loyaltyDetails: "รายละเอียดความภักดี",
    doYouWantToCloseDialog: "คุณต้องการปิดกล่องโต้ตอบนี้หรือไม่?",
    pleaseEnterLoyaltyName: "กรุณาใส่ชื่อความจงรักภักดีของคุณ",
    programType: "ประเภทโปรแกรม",
    pleaseSelectProgramType: "กรุณาเลือกประเภทโปรแกรม",
    simplePoints: "คะแนนง่ายๆ",
    stampsProgram: "โปรแกรมแสตมป์",
    threshold: "เกณฑ์",
    pleaseEnterLoyaltyThreshold: "โปรดป้อนเกณฑ์ความภักดี",
    reward: "รางวัล",
    pleaseEnterLoyaltyReward: "กรุณาป้อนรางวัลของความจงรักภักดี",
    totalUserPoints: "คะแนนผู้ใช้ทั้งหมด",
    totalUserStamps: "แสตมป์ผู้ใช้ทั้งหมด",
    spendingTarget: "เป้าหมายการใช้จ่าย",
    spendingTargetHint1:
      "เป้าหมายการใช้จ่ายคือจำนวนเงินที่ลูกค้าต้องใช้เพื่อรับรางวัล 1 มูลค่าสกุลเงิน = 1 จุด",
    spendingTargetHint2:
      "เป้าหมายแสตมป์คือจำนวนแสตมป์ที่ลูกค้าต้องรวบรวมเพื่อรับรางวัล เช่น. 5",
    addNewLoyaltyProgram: "เพิ่มโปรแกรมความภักดีใหม่",
    addLoyaltyProgram: "เพิ่มโปรแกรมความภักดี",
    loyaltyProgramType: "ประเภทโปรแกรมความภักดี",
    pleaseSelectLoyaltyProgramType: "โปรดเลือกประเภทโปรแกรมความภักดี",
    nameOfProgram: "ชื่อโปรแกรม",
    pleaseEnterProgramName: "กรุณากรอกชื่อโปรแกรม",
    whatIsTheReward: "ผลตอบแทนคืออะไร?",
    egNextPurchaseIsFree: "เช่น. การซื้อครั้งต่อไปฟรี",
    customerName: "ชื่อลูกค้า",
    guestCustomer: "ลูกค้ารับเชิญ",
    orderConfirmedSuccessfully: "ยืนยันการสั่งซื้อเรียบร้อยแล้ว",
    orderCancelledSuccessfully: "ยกเลิกคำสั่งซื้อเรียบร้อยแล้ว",
    confirmOrder: "ยืนยันรายการ",
    cancelOrder: "ยกเลิกคำสั่งซื้อ",
    allOrders: "คำสั่งซื้อทั้งหมด",
    totalOrders: "ยอดสั่งซื้อ",
    doYouWantToAcceptOrder: "คุณต้องการยอมรับคำสั่งซื้อนี้หรือไม่?",
    doYouWantToCancelOrder: "คุณต้องการยกเลิกคำสั่งซื้อนี้หรือไม่?",
    orderDetails: "รายละเอียดการสั่งซื้อ",
    orderCreatedAt: "สร้างคำสั่งซื้อที่",
    supplier: "ผู้ผลิต",
    productName: "ชื่อผลิตภัณฑ์",
    quantity: "ปริมาณ",
    unitPrice: "ราคาต่อหน่วย",
    receivedBy: "ที่ได้รับจาก",
    reportFrom: "รายงานจาก",
    totalSupplies: "พัสดุทั้งหมด",
    allRightsReserved: "สงวนลิขสิทธิ์",
    toDeleteThisTransaction: "เพื่อลบธุรกรรมนี้",
    transactionDeletedSuccessfully:
      "ลบธุรกรรมเรียบร้อยแล้ว สต็อคถูกส่งคืนไปยังสินค้าคงคลัง",
    deleteTransaction: "ลบธุรกรรม",
    transactionDetails: "รายละเอียดการทำธุรกรรม",
    printReceipt: "พิมพ์ใบเสร็จรับเงิน",
    channel: "ช่อง",
    discount: "การลดราคา",
    profit: "กำไร",
    discountedSales: "ลดราคาขาย",
    errorFetchingRecord: "เกิดข้อผิดพลาดในการดึงบันทึก",
    exportTransactions: "ธุรกรรมการส่งออก",
    errorFetchingSalesRecord:
      "เกิดข้อผิดพลาดในการดึงบันทึกการขายเพื่อการส่งออก",
    totalSellingPrice: "ราคาขายรวม",
    totalCostPrice: "ราคาต้นทุนรวม",
    appliedDiscount: "ใช้ส่วนลด",
    noOfItems: "จำนวนรายการ",
    sales: "ฝ่ายขาย",
    export: "ส่งออก",
    totalProfit: "กำไรรวม",
    totalBalanceInPeriod: "ยอดรวมในงวด",
    allTimeSales: "ยอดขายตลอดเวลา",
    records: "บันทึก",
    todaysSales: "ยอดขายวันนี้",
    transaction: "ธุรกรรม",
    youHaveExceededTotalNumberOfProducts:
      "คุณมีผลิตภัณฑ์เกินจำนวนที่อนุญาตในแผนของคุณแล้ว อัปเกรดแผนของคุณเพื่อรับขีดจำกัดที่สูงขึ้น",
    youNeedToHaveLoyaltyProgram:
      "คุณต้องมีโปรแกรมความภักดีเพื่อใช้คุณสมบัตินี้!",
    price: "ราคา",
    category: "หมวดหมู่",
    stockTracking: "การติดตามสต็อค",
    stockCount: "จำนวนสต็อค",
    taxed: "เสียภาษี",
    originalPrice: "ราคาเดิม",
    costPrice: "ราคา",
    unit: "หน่วย",
    productImage: "ภาพสินค้า",
    taxRate: "อัตราภาษี",
    taxType: "ประเภทภาษี",
    trackInventory: "ติดตามสินค้าคงคลัง",
    variants: "รุ่นต่างๆ",
    hasVariants: "มีรุ่นต่างๆ",
    importProduct: "สินค้านำเข้า",
    exportProducts: "สินค้าส่งออก",
    addNewProduct: "เพิ่มสินค้าใหม่",
    viewCategory: "ดูหมวดหมู่",
    viewSuppliers: "ดูซัพพลายเออร์",
    receiveInventory: "รับสินค้าคงคลัง",
    printAllProductsTag: "พิมพ์ สินค้าทั้งหมด แท็ก",
    deleteAll: "ลบทั้งหมด",
    totalProducts: "รวมผลิตภัณฑ์",
    youveSuccessfullyAddedANewCategory: "คุณเพิ่มหมวดหมู่ใหม่สำเร็จแล้ว",
    addNewCategory: "เพิ่มหมวดหมู่ใหม่",
    addCategory: "เพิ่มหมวดหมู่",
    categoryName: "ชื่อหมวดหมู่",
    pleaseEnterCategoryName: "กรุณากรอกชื่อหมวดหมู่",
    stampsTarget: "เป้าหมายแสตมป์",
    sendInventory: "ส่งสินค้าคงคลัง",
    productDetails: "รายละเอียดสินค้า",
    youveSuccessfullyEditedThisCategory: "คุณแก้ไขหมวดหมู่นี้สำเร็จแล้ว",
    update: "อัปเดต",
    categoryList: "รายการหมวดหมู่",
    categories: "หมวดหมู่",
    enterQuantityToUpdate: "ใส่จำนวนที่จะอัพเดท",
    inventorySentSuccessfully: "สินค้าคงคลังถูกส่งเรียบร้อยแล้ว!",
    updateInventory: "อัพเดทสินค้าคงคลัง",
    currentQuantity: "ปริมาณปัจจุบัน",
    pleaseEnterQuantityToAdd: "กรุณาระบุจำนวนที่ต้องการเพิ่ม",
    pleaseSelectABranch: "กรุณาเลือกสาขา",
    searchForBranch: "ค้นหาสาขา",
    youCantSendMoreThanStock: "คุณไม่สามารถส่งมากกว่าที่คุณมีในสต็อก",
    send: "ส่ง",
    pleaseEnterQuantityToSend: "กรุณาระบุจำนวนที่ต้องการส่ง",
    productNameIsRequiredOnRow: "ต้องระบุชื่อผลิตภัณฑ์ในแถว",
    productCategoryIsRequiredOnRow: "ต้องระบุหมวดหมู่สินค้าในแถว",
    productPriceIsRequiredOnRow: "ต้องระบุราคาสินค้าในแถว",
    productUnitIsRequiredOnRow: "ต้องมีหน่วยผลิตภัณฑ์ในแถว",
    productQuantityIsRequiredOnRow: "ต้องระบุจำนวนสินค้าในแถว",
    productVariantsRequireTracking: "ตัวเลือกสินค้าต้องมีการติดตาม!",
    pleaseAddVariantClickButton: "โปรดเพิ่มตัวแปรโดยคลิกปุ่มเพิ่ม!",
    totalVariantsMoreThanSelectedQuantity:
      "ตัวแปรทั้งหมดมีมากกว่าปริมาณผลิตภัณฑ์ที่เลือก โปรดลดปริมาณของตัวเลือกสินค้าลง",
    productEditedSuccessfully: "แก้ไขสินค้าเรียบร้อยแล้ว!",
    fileTooLargeLessThan4Mb: "ขนาดไฟล์ใหญ่เกินไป! ขนาดไฟล์ควรน้อยกว่า 4MB",
    suchVariantAlreadyExist: "ตัวแปรดังกล่าวมีอยู่แล้ว",
    addVariants: "เพิ่มตัวเลือกสินค้า",
    editProduct: "แก้ไขสินค้า",
    pleaseEnterProductName: "กรุณากรอกชื่อสินค้า",
    pleaseEnterProductPrice: "กรุณาระบุราคาสินค้า",
    pleaseEnterProductOriginalPrice: "กรุณาใส่สินค้า ราคาเดิม",
    productDescription: "รายละเอียดสินค้า",
    selectProductCategory: "เลือกหมวดหมู่สินค้า",
    pleaseSelectProductCategory: "กรุณาเลือกหมวดสินค้า",
    productCostPrice: "ราคาต้นทุนสินค้า",
    productSellingPrice: "ราคาขายสินค้า",
    productOriginalPrice: "สินค้าราคาเดิม",
    maxFileSizeAllowedIs4Mb: "แม็กซ์ ขนาดไฟล์ที่อนุญาตคือ 4mb",
    productsWithPicturesArePublished:
      "ผลิตภัณฑ์ที่มีรูปภาพเผยแพร่บน Discover เพื่อรับคำสั่งซื้อ",
    shouldThisProductBeTracked: "ผลิตภัณฑ์นี้ควรได้รับการติดตามหรือไม่?",
    pleaseSelectStockUnit: "กรุณาเลือกหน่วยหุ้น",
    stockUnit: "หน่วยสต็อค",
    bag: "กระเป๋า",
    bottle: "ขวด",
    bunch: "พวง",
    can: "สามารถ",
    carton: "กล่องกระดาษ",
    crate: "ลัง",
    cup: "ถ้วย",
    dozen: "โหล",
    gigabytes: "GIGABYTES",
    gram: "กรัม",
    kilogram: "กิโลกรัม",
    litre: "ลิตร",
    pack: "หีบห่อ",
    pair: "คู่",
    pieces: "ชิ้นส่วน",
    plate: "จาน",
    tonne: "ตัน (มอนแทนา)",
    uNIT: "หน่วย",
    yard: "ลาน",
    pleaseEnterProductQuantity: "กรุณาระบุจำนวนสินค้า",
    productQuantity: "ปริมาณสินค้า",
    isThisProductTaxed: "สินค้านี้มีภาษีหรือไม่?",
    selectTaxType: "เลือกประเภทภาษี",
    pleaseSelectTaxType: "กรุณาเลือกประเภทภาษี",
    progressive: "ความก้าวหน้า",
    proportional: "สัดส่วน",
    pleaseEnterProductTaxRate: "กรุณากรอกอัตราภาษีสินค้า",
    doesProductHaveVariants: "สินค้านี้มีรายละเอียดปลีกย่อยหรือไม่?",
    type: "พิมพ์",
    value: "ค่า",
    pleaseEnterVariantType: "โปรดป้อนประเภทของตัวแปร",
    pleaseEnterVariantValue: "โปรดป้อนค่าตัวแปร",
    pleaseEnterVariantPrice: "กรุณาใส่ราคาตัวแปร",
    pleaseEnterVariantQuantity: "กรุณากรอกจำนวนตัวแปร",
    productDeletedSuccessfully: "สินค้าถูกลบเรียบร้อยแล้ว!",
    categoryDeletedSuccessfully: "หมวดหมู่ถูกลบเรียบร้อยแล้ว!",
    toDeleteThisProduct: "เพื่อลบผลิตภัณฑ์นี้",
    invalidProductQuantity: "ปริมาณสินค้าไม่ถูกต้อง",
    quantityAddedIsOverStock:
      "ปริมาณที่คุณกำลังเพิ่มอยู่เหนือสิ่งที่คุณมีในสต็อก",
    itemInventoryNotTracked: "ไม่มีการติดตามสินค้าคงคลังของรายการ",
    addBulkQuantity: "เพิ่มปริมาณจำนวนมาก",
    enterBulkQuantity: "ป้อนปริมาณจำนวนมาก",
    pleaseEnterBulkQuantity: "โปรดป้อนปริมาณจำนวนมาก",
    youveSuccessfullyAddedANewProduct: "คุณเพิ่มผลิตภัณฑ์ใหม่สำเร็จแล้ว!",
    pleaseEnterProductSellingPrice: "กรุณาใส่ราคาขายของผลิตภัณฑ์",
    doYouWantToTrackProductStock: "คุณต้องการติดตามสต็อคสินค้าหรือไม่?",
    sellingPrice: "ราคาขาย",
    setProductExpiryReminder: "ตั้งค่าตัวเตือนการหมดอายุของผลิตภัณฑ์",
    productExpiryDate: "สินค้าหมดอายุ",
    startRemindingFrom: "เริ่มเตือนจาก",
    productSuppliesAddedSuccessfully:
      "คุณได้เพิ่มวัสดุสิ้นเปลืองของผลิตภัณฑ์เรียบร้อยแล้ว",
    addProductSupplies: "เพิ่มอุปกรณ์ผลิตภัณฑ์",
    pleaseSelectSupplier: "กรุณาเลือกซัพพลายเออร์",
    nameOfProduct: "ชื่อผลิตภัณฑ์",
    pleaseSelectProduct: "กรุณาเลือกสินค้า",
    productVariant: "ตัวเลือกสินค้า",
    pleaseSelectAVariant: "กรุณาเลือก A Variant",
    pleaseEnterUnitPrice: "กรุณาใส่ราคาต่อหน่วย",
    businessBranch: "สาขาธุรกิจ",
    pleaseSelectBranch: "กรุณาเลือกสาขา",
    youveSuccessfullyAddedANewSupplier: "คุณเพิ่มซัพพลายเออร์ใหม่สำเร็จแล้ว",
    addSupplier: "เพิ่มผู้จัดจำหน่าย",
    pleaseEnterSupplierEmail: "กรุณากรอกอีเมลของซัพพลายเออร์",
    pleaseAddADescription: "กรุณาเพิ่มคำอธิบาย",
    anErrorOccuredProductsDeleted:
      "เกิดข้อผิดพลาดขณะดำเนินการ โปรดทราบว่ามีผลิตภัณฑ์บางอย่างอาจถูกลบในกระบวนการ",
    bulkDelete: "ลบเป็นกลุ่ม",
    youAreAboutToDelete: "คุณกำลังจะลบ",
    product: "ผลิตภัณฑ์",
    isDueToRedeem: "เกิดจากการไถ่ถอน",
    aReward: "รางวัล",
    pleaseSelectCustomerToReeemReward: "โปรดเลือกลูกค้าเพื่อแลกรางวัล",
    youHaveNoLoyaltyProgramRunning: "คุณไม่มีโปรแกรมความภักดีที่กำลังทำงานอยู่",
    customerHhasNoPointsInLoyaltyProgram:
      "ลูกค้าไม่มีคะแนนในโปรแกรมความภักดีนี้",
    proceedWithPayment: "ดำเนินการชำระเงิน?",
    youAreAboutToPayForTransactionUsingPoints:
      "คุณกำลังจะชำระเงินสำหรับการทำธุรกรรมโดยใช้คะแนน",
    customerHas: "ลูกค้ามี",
    worth: "คุณค่า",
    andIsNotSufficientToPayForTransaction:
      "และไม่เพียงพอต่อการชำระเงินสำหรับธุรกรรมนี้ พวกเขาต้องการเพิ่มยอดเงินโดยใช้วิธีการชำระเงินอื่นหรือไม่?",
    addCustomerLoyalty: "เพิ่มความภักดีของลูกค้า",
    pleaseAddCustomerFirst: "โปรดเพิ่มหรือเลือกลูกค้าก่อน",
    pleaseWaitWhileWeGetReady: "กรุณารอสักครู่ในขณะที่เรากำลังเตรียมพร้อม",
    lowStock: "สต็อกต่ำ",
    pleaseEnterAmountTendered: "กรุณาระบุจำนวนเงินที่ประมูล",
    areYouSureToBookSaleOffline:
      "คุณแน่ใจหรือไม่ว่าต้องการจองการขายนี้แบบออฟไลน์",
    saleWouldBeBookedAutomatically:
      "การขายจะถูกจองโดยอัตโนมัติเมื่อคุณเปิดอินเทอร์เน็ต",
    offlineSalesBookingCancelled: "ยกเลิกการจองการขายแบบออฟไลน์",
    covid19Message:
      "COVID19: ล้างมือด้วยสบู่หรือฆ่าเชื้อเพื่อหยุดการแพร่กระจาย อยู่อย่างปลอดภัยเสมอ",
    saleSuccessfullyRecorded: "บันทึกการขายสำเร็จแล้ว!",
    sendReceiptToEmail: "ส่งใบเสร็จรับเงินมาที่ Email",
    sendThankYouSms: "ส่ง SMS ขอบคุณ",
    sendShippingDetails: "ส่งรายละเอียดการจัดส่ง",
    addLoyalty: "เพิ่มความภักดี",
    searchCustomerNameOrNumber: "ค้นหาชื่อลูกค้าหรือหมายเลข",
    clickTheSearchCustomerBox: "คลิกช่องค้นหาลูกค้าและสแกนการ์ด",
    enterProductPrice: "ใส่ราคาสินค้า",
    enterPriceFor: "ใส่ราคาสำหรับ",
    searchForProduct: "ค้นหาสินค้า",
    all: "ทั้งหมด",
    backToDashboard: "กลับไปที่แดชบอร์ด",
    viewDraftSales: "ดูการขายแบบร่าง",
    variantSelected: "ตัวแปรที่เลือก",
    variant: "ตัวแปร",
    thePreviousVariantSelectionNotAvailable:
      "การเลือกรูปแบบก่อนหน้าไม่สามารถใช้ได้สำหรับรูปแบบใหม่ที่เลือกตามราคา โปรดเปลี่ยนการเลือกของคุณ",
    pleaseSupplyPositiveQuantityNumber: "กรุณาระบุจำนวนบวก",
    lowStockFor: "หุ้นต่ำสำหรับ",
    clearVariants: "ตัวแปรที่ชัดเจน",
    pleaseEnterQuantity: "กรุณากรอกจำนวน",
    picture: "รูปภาพ",
    redemptionToken: "โทเค็นแลกของรางวัล",
    token: "โทเค็น",
    totalSpent: "การใช้จ่ายทั้งหมด",
    confirmPayment: "ยืนยันการชำระเงิน",
    hasPaymentBeenMade: "การชำระเงินได้รับการดำเนินการสำเร็จหรือไม่?",
    enterTransactionReference: "ป้อนข้อมูลอ้างอิงธุรกรรมที่คุณชำระด้วย",
    pleaseSelectACustomer: "กรุณาเลือกลูกค้า!",
    areYouSureToApplyDiscount: "คุณแน่ใจหรือว่าต้องการใช้ส่วนลด?",
    addYourBankAccountToEnableUssd:
      "เพิ่มบัญชีธนาคารของคุณเพื่อเปิดใช้งานการโอน USSD ทันทีโดย uPay",
    totalAmountToPay: "จำนวนเงินที่ต้องชำระ",
    doYouWantToCancelTransaction: "คุณต้องการยกเลิกธุรกรรมนี้หรือไม่?",
    savePrintBill: "บันทึก/พิมพ์บิล",
    enterAmountCollectedForCustomer: "ใส่จำนวนเงินที่เรียกเก็บสำหรับลูกค้า",
    recordSale: "ขายแผ่นเสียง",
    checkOutWith: "ตรวจสอบกับ",
    instantTransfer: "โอนทันที",
    dialTheUSSDCode: "กดรหัส USSD",
    pleaseSelectABank: "กรุณาเลือกธนาคาร",
    payWithUSSD: "ชำระเงินด้วย USSD",
    sendBillTo: " - ส่งบิลไปที่",
    waitingForUSSDTransfer: "กำลังรอการโอน USSD",
    percent: "เปอร์เซ็นต์",
    applyDiscount: "สมัครส่วนลด",
    thisBillHasBeenSaved: "บิลนี้ได้รับการบันทึก",
    saveDraft: "บันทึกร่าง",
    pleaseTypeANameToIdentifyCustomer: "กรุณาพิมพ์ชื่อเพื่อระบุตัวลูกค้า",
    paymentDetails: "รายละเอียดการจ่ายเงิน",
    basePrice: "ราคาพื้นฐาน",
    staff: "พนักงาน",
    subTotal: "ยอดรวม",
    durationMonths: "ระยะเวลา (เดือน)",
    selectADuration: "เลือกระยะเวลา",
    oneMonth: "1 เดือน",
    twoMonths: "2 เดือน",
    threeMonths: "3 เดือน",
    sixMonths: "6 เดือน",
    twelveMonths: "12 เดือน",
    eighteenMonths: "18 เดือน",
    twentyFourMonths: "24 เดือน",
    twoMonthsFree: "(ฟรี 2 เดือน)",
    threeMonthsFree: "(ฟรี 3 เดือน)",
    fiveMonthsFree: "(ฟรี 5 เดือน)",
    yourAccountHasBeen: "บัญชีของคุณได้รับ",
    renewed: "ต่ออายุ",
    upgraded: "อัพเกรด",
    successfully: "ประสบความสำเร็จ",
    yourSubscription: "การสมัครของคุณ",
    youAreCurrentlyEnrolledOnA: "คุณกำลังลงทะเบียนในa",
    pleaseChooseAPaymentOption: "โปรดเลือกตัวเลือกการชำระเงิน",
    planRenewal: "แผนต่ออายุ",
    planUpgrade: "อัพเกรดแผน",
    pleaseSelectDurationToPayFor: "โปรดเลือกระยะเวลาที่คุณต้องการชำระ",
    staffAccounts: "บัญชีพนักงาน",
    ecommerce: "อีคอมเมิร์ซ",
    pleaseSelectAPlan: "กรุณาเลือกแผน",
    includeAddons: "รวมโปรแกรมเสริม",
    viewTransactions: "ดูธุรกรรม",
    customerHasNoCompletedTansactions:
      "ลูกค้ายังไม่มีการดำเนินการที่เสร็จสมบูรณ์",
    branch: "สาขา",
    enterNumberOfEcommerceBranches: "ป้อนจำนวนสาขาอีคอมเมิร์ซ",
    enterNumberOfEcommerceBranchesToPay:
      "ป้อนจำนวนสาขาอีคอมเมิร์ซที่คุณต้องการจ่าย",
    ecommerceIntegration: "บูรณาการอีคอมเมิร์ซ",
    enterNumberOfBranches: "ใส่จำนวนสาขา",
    enterNumberOfAdditionalBranchesToPay:
      "ระบุจำนวนสาขาเพิ่มเติมที่คุณต้องการชำระเงิน",
    enterNumberOfStaffs: "ใส่จำนวนพนักงาน",
    enterNumberOfStaffsToPayFor: "ระบุจำนวนพนักงานที่คุณต้องการจ่าย",
    discountApplies: "ส่วนลดใช้",
    starsOnThe: "ดาวบน",
    offer: "เสนอ",
    get: "รับ",
    moreStarsToRedeem: "เพิ่มดาวให้แลก",
    taxVat: "ภาษี (VAT)",
    callCashierToCompletePayment: "โทรเรียกแคชเชียร์เพื่อชำระเงินให้เสร็จสิ้น",
    receipt: "ใบเสร็จ",
    dear: "ที่รัก",
    thankYouForYourOrderFindGoods:
      "ขอขอบคุณสำหรับการสั่งซื้อของคุณ. กรุณาค้นหาสินค้าดังต่อไปนี้ที่ให้มาตามที่ตกลงกันไว้",
    shippingNote: "หมายเหตุการจัดส่งสินค้า",
    enterShippingNote: "ใส่หมายเหตุการจัดส่ง",
    shippingAddress: "ที่อยู่จัดส่ง",
    enterShippingAddress: "ใส่ที่อยู่จัดส่ง",
    wellDoneYouAreDueToRedeem: "ทำได้ดี! คุณครบกำหนดไถ่ถอน",
    toGetYourRewardNextVisit: "เพื่อรับรางวัลของคุณในครั้งต่อไป ขอขอบคุณ",
    pointsOnThe: "คะแนนบน",
    morePointsToRedeem: "คะแนนเพิ่มเติมเพื่อแลก",
    showCode: "แสดงรหัส",
    toGetYourRewardOnNextVisit: "เพื่อรับรางวัลของคุณในครั้งต่อไป ขอขอบคุณ",
    earn: "ได้รับ",
    delivaryNote: "ใบส่งของ",
    draftSales: "ร่างการขาย",
    startDate: "วันที่เริ่มต้น",
    endDate: "วันที่สิ้นสุด",
    orders: "คำสั่งซื้อ",
    checkout: "เช็คเอาท์",
    noProductItem: "ไม่มีรายการสินค้า",
    selectProductImage: "เลือกภาพสินค้า",
    selectCountry: "เลือกประเทศ",
    selectRegion: "เลือกรัฐ/ภูมิภาค",
    printProductTag: "พิมพ์แท็กสินค้า",
    transactionReference: "การอ้างอิงธุรกรรม",
    Cashier: "แคชเชียร์",
    Manager: "ผู้จัดการ",
    Owner: "เจ้าของ",
    Admin: "แอดมิน",
    addPartners: "เพิ่มพันธมิตร",
    addNewLoyaltyPartner: "เพิ่มพันธมิตรความภักดีใหม่",
    pleaseEnterCompanyName: "กรุณากรอกชื่อบริษัท",
    companyName: "ชื่อ บริษัท",
    pleaseEnterCompanyRepName: "กรุณากรอกชื่อตัวแทนบริษัท",
    companyRepName: "ชื่อตัวแทนบริษัท",
    pleaseEnterCompanyRepEmail: "กรุณากรอกอีเมลของตัวแทนบริษัท",
    companyRepEmail: "อีเมลของตัวแทนบริษัท",
    pleaseEnterCompanyRepPhone: "กรุณากรอกหมายเลขโทรศัพท์ของตัวแทนบริษัท",
    companyRepPhone: "หมายเลขโทรศัพท์ของตัวแทนบริษัท",
    addReward: "เพิ่มรางวัล",
    pleaseEnterRewardName: "กรุณากรอกชื่อรางวัล",
    rewardName: "ชื่อรางวัล",
    rewardQuantity: "จำนวนรางวัล",
    rewardDescription: "คำอธิบายรางวัล",
    rewardType: "ประเภทรางวัล",
    pleaseEnterRewardType: "กรุณากรอกประเภทรางวัล",
    pleaseEnterRewardQuantity: "กรุณากรอกจำนวนรางวัล",
    pleaseEnterRewardDescription: "กรุณากรอกรายละเอียดรางวัล",
    fineDining: "อาหารรสเลิศ",
    luxuryFashion: "แฟชั่นสุดหรู",
    hotels: "โรงแรม",
    travel: "การท่องเที่ยว",
    foodAndBeverage: "อาหารและเครื่องดื่ม",
    fashion: "แฟชั่น",
    health: "สุขภาพ",
    furniture: "เฟอร์นิเจอร์",
    entertainment: "ความบันเทิง",
    automobile: "รถยนต์",
    education: "การศึกษา",
    beautyAndSpa: "ความงามและสปา",
    staycation: "สเตย์เคชั่น",
    events: "เหตุการณ์",
    trips: "ทริป",
    oilAndGas: "น้ำมันและก๊าซ",
    laundry: "ซักรีด",
    partnerCategory: "หมวดหมู่พันธมิตร",
    freeItem: "ไอเทมฟรี",
  },
  Turkmen: {
    cashier: "kassir",
    manager: "dolandyryjy",
    owner: "eýesi",
    online: "onlaýn",
    offline: "awtonom",
    changePassword: "Paroly üýtgetmek",
    currentPasswordMessage: "Häzirki parolyňyzy giriziň",
    passwordMessage: "Parolyňyzy giriziň",
    currentPassword: "Hazirki parolynyz",
    password: "parol",
    confirmPasswordMessage: "Parolyňyzy tassyklaň!",
    confirmPassword: "Paroly tassykla",
    sendViaEmail: "E-poçta arkaly iberiň",
    sendViaWhatsapp: "WhatsApp arkaly iberiň",
    downloadPdf: "PDF göçürip alyň",
    paid: "tölenen",
    unpaid: "tölenmedik",
    partial: "bölekleýin",
    closeInvoice: "Hasap-fakturany ýapmak isleýärsiňizmi?",
    closeInvoiceConfirmation:
      "Hasap-faktura ýatda saklanmazlygy mümkin. Toapmak isleýärsiňizmi?",
    yes: "hawa",
    no: "ýok",
    invoice: "Hasap-faktura",
    wasDeducted: "aýyryldy",
    for: "üçin",
    item: "Haryt",
    addProduct: "Önüm goşuň",
    paymentReference: "Töleg salgylanmasy",
    amountPaid: "Tölenen mukdar",
    discountAmount: "Arzanladyş mukdary",
    amountDue: "Berilmeli mukdar",
    amount: "Mukdary",
    dueDate: "Berlen senesi",
    paymentType: "Töleg görnüşi",
    card: "Kart",
    cash: "Nagt",
    bankTransfer: "Bank geçirmesi",
    paymentMessage: "Töleg habary",
    description: "Düşündiriş",
    sendReceipt: "Kwitansiýa iber",
    delete: "Öçür",
    save: "Saklaň",
    resend: "Gaýtadan iber",
    saveAndSend: "Saklaň",
    invoiceSaved: "Hasap-faktura saklandy!",
    selectPaymentMethod: "Töleg usulyny saýlaň!",
    selectCustomer: "Müşderi saýlaň!",
    cartEmptyError:
      "Araba sanawy boş bolup bilmez, hasap-faktura ýapyň we sebete element goşuň!",
    subscriptionExpired:
      "Abunalygyňyzyň möhleti gutardy we hasabyňyz çäkli. Hasabyňyzy täzelemek üçin aşakdaky düwmä basyň",
    renew: "Täzelen",
    holdOn: "Dowam et",
    customerBank: "Müşderi banky",
    cancel: "Elatyr",
    selectACustomer: "Müşderi saýlaň",
    invoiceSuccessfulPdfError:
      "Hasap-faktura üstünlikli döredildi, ýöne PDF nesli adatdakysyndan has köp wagt alýar. Gysga wagtda barlaň.",
    downloadingInvoice: "Hasap-faktura ýüklemek",
    downloadingReceipt: "Kwitany göçürip almak",
    whatsappReceiptError:
      "Kwitany WhatsApp arkaly ibermekde ýalňyşlyk ýüze çykdy, gaýtadan synanyşyň.",
    receiptToWhatsapp: "Kwit WhatsApp-a ugradyldy",
    thankYouForPatronage: "Howandarlygyňyz üçin sag boluň",
    hereIsYourReceipt: "Ine, töleg kwitansiýaňyz",
    errorSendingEmailReceipt:
      "Kwitansiýany e-poçta arkaly ibermekde ýalňyşlyk ýüze çykdy, gaýtadan synanyşyň ýa-da goldaw bilen habarlaşyň",
    receiptSentToEmail: "Kwitansiýa müşderiniň e-poçta iberildi",
    invoiceSentToEmail: "Hasap-faktura müşderiniň e-poçta iberildi",
    invoiceSuccessWhatsappError:
      "Hasap-faktura üstünlikli döredildi, ýöne WhatsApp-a ibermekde ýalňyşlyk ýüze çykdy. Hasap-faktura sanawynda gaýtadan synanyşyň",
    invoiceSuccessfulEmailError:
      "Hasap-faktura üstünlikli döredildi, ýöne e-poçta hökmünde iberilende ýalňyşlyk ýüze çykdy. Hasap-faktura sanawyndan gaýtadan synanyşmagyňyzy haýyş edýäris",
    invoiceSentToWhatsapp: "Hasap-faktura WhatsApp-a iberildi",
    hello: "Salam",
    pleaseDownloadInvoice: "Hasap-faktura göçürip almagyňyzy haýyş edýäris",
    pleaseDownloadReceipt: "Kwitansiýany göçürip almagyňyzy haýyş edýäris",
    from: "dan",
    email: "E-poçta iberiň",
    upgrade: "Döwrebaplaşdyryň",
    youAreOnFreePlan: "Mugt meýilnamada.",
    clickOn: "Basyň",
    yourPlanInFewSteps: " abunalygyňyz birnäçe çalt ädimde.",
    to: "to",
    yourSubscriptionHasExpired:
      "Abunalygyňyzyň möhleti gutardy we hasabyňyz çäkli.",
    days: "günler",
    yourSubscriptionExpiresIn: "“Loystar” abunalygyňyzyň möhleti gutarýar",
    createAcount: "Hasap dörediň",
    signIn: "Giriň",
    continue: "Dowam et",
    enterOtp: "OTP PIN giriziň",
    enterValidOtp: "Dogry PIN giriziň",
    pin: "PIN",
    tokenSentToMail: "E-poçtaňyza bir bellik iberildi",
    passwordResetSuccessful: "Paroly täzeden düzmek üstünlikli boldy",
    somethingWentWrong: "Bir zat ýalňyş boldy!",
    resetPassword: "Paroly täzeden düzmek",
    iHaveResetCode: "Paroly täzeden düzmek kody bar",
    pleaseEnterEmail: "E-poçtaňyzy ýazmagyňyzy haýyş edýäris",
    aTokenWillBeSentToEmail: "E-poçtaňyza bir bellik iberiler",
    enterEmail: "E-poçtaňyzy giriziň",
    sendinYourToken: "Tokeniňizi ibermek ...",
    makeSureItMatchesPassword: "Täze parolyňyza laýyk gelýändigine göz ýetiriň",
    pleaseChooseNewPassword: "Täze parol saýlaň",
    chooseNewPassword: "Täze parol saýlaň",
    enterNewPassword: "Tassyklamak üçin täze parolyňyzy giriziň",
    enterTokenSent: "Poçta iberen belligi giriziň",
    resetToken: "Token belläň",
    resettingPassword: "Parolyňyzy täzeden düzmek ...",
    signUp: "Hasaba gir",
    adminSignInWithEmail:
      " Işgärler ulanyjy ady bilen girenlerinde, administrator e-poçta bilen girýär.",
    pleaseEnterEmailOrUsername:
      "E-poçtaňyzy ýa-da ulanyjy adyňyzy ýazmagyňyzy haýyş edýäris",
    emailOrUsername: "E-poçta ýa-da ulanyjy ady",
    pleaseEnterPassword: "Paroly giriziň",
    createAnAccount: "Hasap dörediň",
    forgotPassword: "Paroly ýatdan çykardyňyzmy?",
    enterPhoneNumber: "Telefon belgisini giriziň",
    personalData: "Şahsy maglumatlar",
    validateConfirmationCOde: "Tassyklama kody tassyklaň",
    pleaseEnterFirstName: "Adyňyzy ýazmagyňyzy haýyş edýäris",
    pleaseEnterPhoneNumber: "Telefon belgiňizi ýazmagyňyzy haýyş edýäris",
    pleaseEnterLastName: "Familiýaňyzy ýazmagyňyzy haýyş edýäris",
    pleaseEnterBusinessName: "Iş adyňyzy ýazmagyňyzy haýyş edýäris",
    firstName: "Ady",
    lastName: "Familiýasy",
    businessName: "Iş ady",
    previous: "Öňki",
    next: "Indiki",
    pleaseSelectBusinessCategory: "Iş kategoriýaňyzy saýlaň",
    pleaseEnterValidEmail: "Dogry e-poçta giriziň",
    pleaseEnterPostCode: "Poçta kody giriziň",
    postCode: "Poçta kody",
    phoneNumberInUse: "Bu telefon belgisi eýýäm ulanylýar!",
    emailInUse: "Bu e-poçta eýýäm ulanylýar!",
    foodAndDrinks: "Iýmit we içgiler",
    salonAndBeauty: "Salon we gözellik",
    fashionAndAccessories: "Moda we esbaplar",
    gymAndFitness: "Sport zaly we bedenterbiýe",
    travelAndHotel: "Syýahat we myhmanhana",
    homeAndGifts: "Öý we sowgatlar",
    washingAndCleaning: "Hinguwmak we arassalamak",
    gadgetsAndElectronics: "Gadjetler we elektronika",
    groceries: "Azyk önümleri",
    others: "Beýlekiler",
    submit: "Iberiň",
    accountCreatedSuccessful: "Hasabyňyz üstünlikli döredildi.",
    pleaseEnterAddress: "Salgyňyzy ýazmagyňyzy haýyş edýäris",
    addressLine1: "Salgy 1-nji setir",
    addressLine2: "Salgy 2-nji setir",
    choosePassword: "Paroly saýlaň",
    passwordMustBe6Characters: "Parol azyndan 6 simwoldan ybarat bolmaly",
    howDidYouHearLoystar: "Loýstar hakda nähili eşitdiň?",
    referralCode: "ugrukdyrma kody",
    byClickingTheButton: " Aşakdaky düwmä basyp, siz razy",
    termsAndSevice: "Şertler",
    wereCreatingAccount: "Hasabyňyzy döredýäris",
    proceed: "Dowam et",
    verificationCodeMustBe6: "Barlag kody 6 san bolmaly",
    pleaseEnter6DigitCode: "6 sanly kody giriziň",
    enterVerificationCode: "Barlag koduny giriziň",
    resendToken: "Token iber",
    verify: "Barlaň",
    transactions: "Geleşikler",
    todaySales: "Şu günki satuw",
    salesHistory: "Satyş taryhy",
    supplyHistory: "Üpjünçilik taryhy",
    new: "Täze",
    invoices: "Hasap-faktura",
    disbursements: "Tölegler",
    offlineSales: "Oflayn satuw",
    products: "Önümler",
    customers: "Müşderiler",
    multiLevelLoyalty: "Köp derejeli wepalylyk",
    loyaltyPrograms: "Wepalylyk programmalary",
    members: "Agzalar",
    appStore: "Programma dükany",
    orderMenu: "Sargyt menýusy",
    settings: "Sazlamalar",
    staffAndBranches: "Işgärler we bölümler",
    myAccount: "Hasabym",
    switchToSellMode: "Satyş tertibine geçiň",
    signOut: "Çykyň",
    getFreeSubscription: "Mugt abuna ýazylyň",
    onlyNumbersAllowed: "Diňe sanlara rugsat berilýär",
    yourAccountMustBe10Digits: "hasabyňyzyň belgisi 10 sanly bolmaly",
    yourBvnMustBe11: "BVN-iňiz 11 sanly bolmaly",
    pleaseSelectBank: "Bankyňyzy saýlaň",
    selectYourBank: "Bankyňyzy saýlaň",
    enterBankAccountNumber: "Bank hasaby belgisini giriziň",
    enterBvnNumber: "BVN-i giriziň",
    connectBankAccount: "Bank hasaby birikdiriň",
    passwordResetSuccessfulAndSignOut:
      "Parolyňyz üstünlikli täzeden düzüldi. Againene girmek üçin aşakdaky Çykmak düwmesine basyň",
    enterCurrentPassword: "Häzirki paroly giriziň",
    pleaseEnterCurrentPassword: "Häzirki paroly giriziň",
    phoneNumber: "Telefon belgisi",
    sex: "Jyns",
    dateOfBirth: "Doglan gün",
    state: "Döwlet",
    country: "Countryurt",
    loyaltyId: "Wepalylyk şahsyýeti",
    createdAt: "Döredildi",
    noOfTransactionsMade: "Geçirilen geleşikleriň sany",
    yourDownloadWillStart: "Göçürip almak sähel salymdan başlar",
    exportCustomers: "Müşderileri eksport ediň",
    youhaveSuccessfullyToppedUp: "SMS bölümleriňizi üstünlikli doldurdyňyz.",
    youNowHave: "Indi bar",
    smsUnits: "SMS bölümleri",
    enterNumberOfSmsUnits:
      "Satyn almak isleýän SMS bölümleriňiziň sanyny giriziň",
    pleaseEnterANumericValue: "San bahasyny giriziň",
    pay: "Töleg",
    accountEditedSuccessfully: "Hasabyňyz üstünlikli redaktirlendi.",
    youAeCurrentlyOn: "Häzirki wagtda işleýärsiňiz",
    plan: "Meýilnama",
    userData: "Ulanyjy maglumatlary",
    businessData: "Işewürlik maglumatlary",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Puluňyzy saýlaň",
    selectYourCurrency: "Puluňyzy saýlaň",
    purchaseMoreSmsUnits:
      "Aşakdaky formany ulanyp has köp SMS bölümlerini satyn alyň",
    youHave: "Sizde bar",
    atLeast4SmsUnitsRrequired:
      "Barlamak üçin azyndan 4 sm birlik gerek, doldurmagyňyzy haýyş edýäris!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "'Müşderiňizi tanaň' (KYC) talaplaryny ýerine ýetirmek üçin bank hasabyňyzy barlaň. Bu, USSD ýa-da gyssagly geçiriş arkaly tölegleri ýygnamaga, müşderilerden sargyt almaga we amal amallaryny çözmäge mümkinçilik berer. Diňe Nigeriýaly täjirlere degişlidir. Başlamak üçin aşakdaky düwmä basmagyňyzy haýyş edýäris.",
    reConnectBankAccount: "Bank hasabyny täzeden birikdiriň",
    accountName: "Hasabyň ady",
    accountNumber: "Hasap belgisi",
    bankName: "Bankyň ady",
    verified: "Barlanyldy",
    accountDetails: "Hasap maglumatlary",
    kycBankAccount: "KYC",
    createTier: "Tier dörediň",
    fileUploadSuccessful: "faýl üstünlikli ýüklendi",
    fileUploadFailed: "faýl ýüklemek şowsuz",
    createLoyaltyProgram: "Wepalylyk programmasyny dörediň",
    createLoyalty: "Wepalylyk dörediň",
    name: "Ady",
    loyaltyArtwork: "Wepalylyk sungaty",
    clickToUpload: "Uploadüklemek üçin basyň",
    amountToPointsRatio: "Ballaryň gatnaşygy",
    points: "Ballar",
    recommendedAmountToPointRatio:
      "Maslahat berilýär: ₦ 1-den 1 nokada çenli. yagny sarp eden her 1 Naira üçin müşderileriňiz 1 bal alýarlar",
    pleaseTypeIn: "Inazmagyňyzy haýyş edýäris",
    toDeleteLoyalty: "bu wepalylygy ýok etmek",
    deleteLoyalty: "Wepalylygy poz",
    toConfirm: "tassyklamak üçin",
    edit: "Redaktirläň",
    pointsAwardedSuccessfully: "Ballar üstünlikli gowşuryldy.",
    enterPointValueToAward:
      "Müşderilere baýrak bermek isleýän nokadyňyzyň bahasyny giriziň",
    award: "Baýrak",
    awardPointValuesToCustomer: "Müşderä baýrak nokady",
    enrollMembersToLoyalty: "Agzalary wepalylyga ýazyň",
    awardPoints: "Baýrak ballary",
    enroll: "Bellige al",
    home: "Öý",
    loyalty: "Wepalylyk",
    enrollCustomers: "Müşderileri bellige alyň",
    selected: "Saýlandy",
    customer: "Müşderi",
    loyaltyActivationSuccessful: "Wepalylygy işjeňleşdirmek üstünlikli.",
    loyaltyDeactivationSuccessful: "Wepalylygy öçürmek üstünlikli.",
    viewTier: "Tier görmek",
    deactivate: "Işletmek",
    activate: "Işjeňleşdiriň",
    actions: "Hereketler",
    nameOfLoyalty: "Wepalylygyň ady",
    loyaltyStatus: "Wepalylyk ýagdaýy wepalylyk ýagdaýy",
    numberOfTiers: "Gatlaklaryň sany",
    createdOn: "Döredildi",
    createATier: "Bir dereje dörediň",
    stopCreatingTierConfirmation:
      "Bir dereje döretmegi bes etmek isleýärsiňizmi?",
    stopEditingTierConfirmation:
      "Bu derejäni redaktirlemegi bes etmek isleýärsiňizmi?",
    nameOfTier: "Tieriň ady",
    minimumSpendingTarget: "Iň az çykdajy maksady",
    maximumSpendingTarget: "Iň köp harçlamak maksady",
    minimumSpendingTargetRequired: "iň az çykdajy maksady talap edilýär",
    maximumSpendingTargetRequired: "iň ýokary çykdajy maksady talap edilýär",
    rewardSponsor: "Hemaýatkär",
    pleaseChooseARewardSponsor: "Baýrak hemaýatkäri saýlaň",
    self: "Özüň",
    partner: "Hyzmatdaş",
    rewardPartner: "Sylag hyzmatdaş",
    pleaseSelectRewardPartner: "Sylag ýoldaşyňyzy saýlaň",
    rewards: "Baýraklar",
    pleaseSelectAReward: "Baýrak saýlaň",
    instructionsOnRedeemingReward:
      "Müşderiniň sylagy nädip yzyna almalydygy barada görkezmeler",
    pleaseFillInThisField: "Bu ýeri dolduryň!",
    toDeleteThisTier: " bu derejäni pozmak",
    deleteTier: "Teri pozuň",
    viewMembers: "Agzalary görmek",
    membersEnrolled: "Agzalar hasaba alyndy",
    instruction: "Görkezme",
    membersIn: "Agzalar",
    availableTiersInLoyalty: "Wepalylyk programmasynda elýeterli derejeler",
    tiers: "Gatlaklar",
    totalTier: "Jemi dereje",
    availableLoyaltyProgramme: "Wepalylyk programmasy bar",
    totalLoyalties: "Jemi wepalylyk",
    memberDetails: "Agza maglumatlary",
    nameOfCustomer: "Müşderiniň ady",
    address: "Salgysy",
    allEnrolledMembers: "Allhli hasaba alnan agzalar",
    thisIsToWishYouHappyBirthday:
      "Bu, doglan günüňizi we abadan durmuşyňyzy arzuw etmekdir. Loýster üçin ähli zat üçin sag boluň. Baýramyňyz gutly bolsun!",
    inputAnOfferPlease: "Teklip giriziň",
    pleaseSelectTheInsertPoint:
      "Habardaky goýmak nokadyny saýlaň we gaýtadan basyň",
    birthdayOfferAndMessage: "Doglan gün teklibi we habar",
    birthdayOffer: "Doglan gün teklibi",
    birthdayMessage: "Doglan gün habary",
    noOfferFound: "Teklip tapylmady",
    settingABirthdayOffer:
      "Doglan gün teklibini bellemek, müşderilere doglan gününde SMS arkaly bu teklibi almaga mümkinçilik berýär",
    createOffer: "Teklip dörediň",
    whatIsTheOffer: "Teklip näme?",
    editMessage: "Habary redaktirläň",
    insert: "Goýmak",
    theNameOfCustomerInserted: "Müşderiniň ady şu ýere giriziler",
    theBirtdayOfferInserted: "Doglan gün teklibi şu ýere giriziler",
    youSuccessfullyAddedNewBranch: "Täze şahany üstünlikli goşduňyz!",
    addNewBranch: "Täze şahamça goşuň",
    addBranch: "Şahamça goşuň",
    additionalBranchWillIncur: "Goşmaça şahamça çeker",
    perBranch: "şahasy üçin",
    ecommerceBranchCosts: "Söwda bölüminiň çykdajylary",
    pleaseEnterBranchName: "Şahamçanyň adyny ýazmagyňyzy haýyş edýäris",
    pleaseEnterBranchAddress1: "Şahamçanyň salgy salgysyny 1-e giriziň",
    enterBranchAddress1: "Şahamçanyň salgy setirini 1 giriziň",
    enterBranchAddress2: "Şahamçanyň salgy setirini 1 giriziň",
    pleaseEnterBranchAddress2: "Şahamçanyň salgy salgysyny 2-e giriziň",
    enterBranchName: "Şahamçanyň adyny giriziň",
    successfullyAddedStaff: "Täze işgär goşduňyz!",
    addNewStaff: "Täze işgär goşuň",
    addStaff: "Işgär goşuň",
    additionalStaffWillIncur: "Goşmaça işgärler çekiler",
    perStaff: "işgärler üçin.",
    pleaseEnterStaffEmail:
      "Işgärleriň e-poçta salgysyny ýazmagyňyzy haýyş edýäris",
    pleaseEnterStaffUsername: "Işgärleriň ulanyjy adyny giriziň",
    pleaseEnterStaffPassword: "Işgärleriň parolyny giriziň",
    pleaseSelectStaffRole: "Işgärleriň roluny saýlaň",
    selectStaffRole: "Işgärleriň roluny saýlaň",
    enterStaffEmail: "Işgärleriň e-poçta salgysyny giriziň",
    enterStaffUsername: "Işgärleriň ulanyjy adyny giriziň",
    enterStaffPassword: "Işgärleriň parolyny giriziň",
    spacesNotAllowedInUsername: "ulanyjy adynda boş ýerler",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff: "Işgärleri birikdirmek üçin iş saýlaň",
    searchForBranchToAttachStaff: "Işgärleri birikdirmek üçin şahamça gözläň",
    username: "Ulanyjy ady",
    role: "Rol",
    areYouSureToDeleteThis: "Muny pozjakdygyňyza ynanýarsyňyzmy?",
    branches: "Şahamçalar",
    upgradeYourPlan: "Meýilnamaňyzy täzeläň.",
    add: "Goşuň",
    addNew: "Täze goş",
    customerWithEmailAlreadyExists:
      "E-poçta / telefon belgisi bolan müşderi eýýäm bar!",
    successfullyAddedNewCustomer: "Täze müşderini üstünlikli goşduňyz!",
    addCustomer: "Müşderi goşuň",
    pleaseEnterCustomerFirstName: "Müşderiniň adyny giriziň",
    pleaseEnterCustomerLastName:
      "Müşderiniň familiýasyny ýazmagyňyzy haýyş edýäris",
    pleaseEnterCustomerPhoneNumber: "Müşderiniň telefon belgisini giriziň",
    pleaseEnterCustomerEmail: "Müşderiniň e-poçta salgysyny giriziň",
    pleaseEnterCustomerAddressLine: "Müşderiniň salgy setirini giriziň",
    pleaseEnterCustomerOtherAddress: "Müşderiniň beýleki salgysyny giriziň",
    pleaseSelectCustomerGender: "Müşderiniň jynsyny saýlaň",
    gender: "Jyns",
    male: "Erkek",
    female: "Aýal",
    bank: "Bank",
    selectBank: "Bank saýlaň",
    stateOrRegionOrProvince: "Döwlet / sebit / welaýat",
    customerNotes: "Müşderi bellikleri",
    sendSms: "SMS iber",
    editCustomer: "Müşderini redaktirläň",
    redeemReward: "Sylagdan peýdalanyň",
    issueLoyaltyCard: "Wepalylyk kartoçkasy",
    deleteCustomer: "Müşderini pozuň",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Wepalylyk agzalyk şahsyýetini üstünlikli bellediňiz",
    noMembershipIdAvailable:
      "Agzalyk şahsyýetnamasy ýok Salam@loystar.co bilen habarlaşmagyňyzy haýyş edýäris",
    sendEmail: "E-poçta iber",
    membershipPoints: "Agzalyk nokatlary",
    customerDetails: "Müşderi maglumatlary",
    close: ".Akyn",
    loyaltyBalance: "Wepalylyk deňagramlylygy",
    pointsBalance: "Ballar deňagramlylygy",
    starBalance: "Staryldyz deňagramlylygy",
    requiredPoints: "Gerekli ballar",
    requiredStars: "Gerekli ýyldyzlar",
    reddemCode: "Kod tölegi",
    toDeleteThisCustomer: "bu müşderini pozmak",
    customerHasBeenDeletedSuccessfully: "Müşderi üstünlikli öçürildi!",
    customerWithPhoneAlreadyExists: "Telefon belgisi bolan müşderi eýýäm bar",
    customerWasSuccessfullyEdited: "Müşderi üstünlikli redaktirlendi",
    anErrorOccured: "Erroralňyşlyk ýüze çykdy",
    phoneNumberIncludeCountry: "Telefon belgisi (Codeurt koduny goşuň)",
    yourFileQueuedForUpload:
      "Faýlyňyz ýüklemek üçin nobata dur. Sahypany birnäçe sekuntdan soň täzeläň.",
    thereWasAnErrorPerformingOperation:
      "Amaly ýerine ýetirmekde ýalňyşlyk boldy!",
    pleaseSelectFile: "Faýl saýlaň!",
    oopsOnlyCsvFilesAllowed:
      "Wah! Diňe CSV faýllaryna rugsat berilýär. .Csv faýly ýükläň.",
    fileShouldBeSmallerThan5Mb:
      "Faýl 5MB-den kiçi bolmaly. Has uly faýlyňyz bar bolsa, support@loystar.co e-poçta iberiň. Sagbol",
    customerFirstNameIsRequired: "Müşderiniň ady yzygiderli talap edilýär",
    customerPhoneNumberIsRequired:
      "müşderiniň telefon belgisi yzygiderli talap edilýär",
    importCustomers: "Müşderileri import ediň",
    upload: "Uploadükle",
    clickIconToDownloadCsv:
      "CSV faýl şablonyny göçürip almak üçin bu nyşany basyň.",
    getGoogleSheetVersion: "Google sahypasynyň wersiýasyny alyň",
    clickOrDragFileToUpload: "Fileüklemek üçin faýly basyň ýa-da süýräň",
    missingOrInvalidColumnHeader:
      "Columnitirilen ýa-da sütün sözbaşysy. Şablon formatyna eýeriň. Sagbol.",
    youHaveImported: "Import etdiňiz",
    youSuccessfullyRedeemedReward: "Sylagyňyzy üstünlikli yzyna aldyňyz!",
    sixDigitCode: "Alty sanly kod",
    pleaseEnterCustomerRewardCode: "Müşderiniň sylag koduny giriziň",
    enterRewardCode: "Baýrak kody giriziň. (Sylag kody baş harp bilen)",
    selectLoyaltyProgram: "Wepalylyk programmasyny saýlaň",
    stamps: "Markalar",
    smsUnitsLow: "SMS bölümleri pes",
    pleaseTopUpSmsUnits: "SMS bölümlerini doldurmagyňyzy haýyş edýäris",
    smsSuccessfullySentForDelivery: "SMS ibermek üçin üstünlikli iberildi!",
    sendSmsTo: "SMS iberiň",
    allCustomers: "Allhli müşderiler",
    unitsAvailable: "Bölümler bar",
    pleaseTypeInTheMessage: "Habary ýazmagyňyzy haýyş edýäris",
    message: "Habar",
    charactersRemaining: "nyşanlary galan",
    avoidUseOfSpecialCharacters:
      "SMS bölümlerini tygşytlamak üçin ýörite nyşanlary we emojiýalary ulanmakdan gaça duruň.",
    note: "Bellik",
    errorFetchingCustomersMultilevelDetail:
      "Müşderileriň köp derejeli maglumatlary almakda ýalňyşlyk",
    search: "Gözlemek",
    reset: "Täzeden düzmek",
    importCustomer: "Müşderi import ediň",
    addNewCustomer: "Täze müşderi goşuň",
    sendSmsBroadcast: "SMS ýaýlymyny iber",
    totalCustomers: "Jemi müşderiler",
    disbursementDetails: "Töleg maglumatlary",
    paidBy: "Tölegli",
    disbursed: "Tölegli",
    bankAccountName: "Bank hasabynyň ady",
    bankAccountNumber: "Bank hasaby belgisi",
    transferInitiated: "Geçirmek başlandy",
    transferCompleted: "Geçirmek tamamlandy",
    pleaseEnterAValid: "Dogry ýazmagyňyzy haýyş edýäris",
    begin: "başla",
    end: "soňy",
    date: "senesi",
    paymentDate: "Töleg senesi",
    selectDisbursementDuration: "Tölegiň dowamlylygyny saýlaň",
    totalSettled: "Jemi düzüldi",
    totalUnsettled: "Jemi çözülmedik",
    toDeleteThisSale: "bu satuwy pozmak üçin",
    draftSaleDeletedSuccessfully: "Taslama satuwy üstünlikli öçürildi!",
    deleteSale: "Satmagy poz",
    pleaseTypeInYourTotal:
      "Öçürilmegini tassyklamak üçin jemi ýazmagyňyzy haýyş edýäris",
    billDetails: "Hasap maglumatlary",
    printBill: "Hasaby çap et",
    servedBy: "Hyzmat edýär",
    total: "Jemi",
    createdDate: "Döredilen senesi",
    createdTime: "Döredilen Wagt",
    status: ".Agdaý",
    loginSuccessful: "Giriş üstünlikli",
    pleaseWaitWhileWeConnectAccount: "Hasabyňyzy birikdirenimizde garaşyň",
    connectionFailedTryAgain: "Birikdirmek şowsuz. Gaýtadan synanyşyň.",
    connectionSuccessful: "Baglanyşyk üstünlikli",
    viewDetails: "Jikme-jiklikleri görüň",
    enable: "Işlet",
    free: "Mugt",
    cost: "Bahasy",
    visitWebsite: "Sahypa giriň",
    pleaseUpgradeYourPlanToPro:
      "Bu programmany işletmek üçin meýilnamaňyzy Pro Plus-a täzeläň",
    connectYourBankAccount:
      "Tölegleri alyp bilmek üçin bank hasabyňyzy birikdiriň.",
    disable: "Öçüriň",
    enableApp: "Programmany işlediň",
    addNewProductToInvoice: "Hasap-faktura täze önüm goşuň",
    toDeleteThisInvoice: "bu hasap-fakturany pozmak",
    invoiceDeletedSuccessfully: "Hasap-faktura üstünlikli öçürildi!",
    deleteInvoice: "Hasap-fakturany poz",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Hasap-faktura töleg habaryny üstünlikli goşduňyz!",
    pleaseEnterPaymentInstructions: "Töleg görkezmelerini giriziň",
    invoiceId: "Hasap-faktura belgisi",
    paidDate: "Tölegli senesi",
    reference: "Salgylanma",
    productAdded: "Önüm goşuldy",
    productOutOfStock: "Haryt Gaýtadan paýlaň.",
    totalInvoices: "Jemi MAGLUMATLAR",
    totalPaidInvoices: "Jemi tölegli teklipler",
    totalUnpaidInvoices: "JEMLE .JI GÖRNÜŞLER",
    loyaltyProgramDeleted: "Wepalylyk programmasy öçürildi",
    thisLoyaltyProgramDeletedSuccessfully:
      "Bu wepalylyk programmasy üstünlikli öçürildi",
    thisLoyaltyProgramEditedSuccessfully:
      "Bu wepalylyk programmasy üstünlikli redaktirlendi",
    loyaltyProgramAddedSuccessfully: "Wepalylyk programmasy üstünlikli goşuldy",
    loyaltyProgramEdited: "Wepalylyk programmasy redaktirlendi",
    loyaltyProgramAdded: "Wepalylyk programmasy goşuldy",
    loyaltyDetails: "Wepalylyk maglumatlary",
    doYouWantToCloseDialog: "Bu gepleşik penjiresini ýapmak isleýärsiňizmi?",
    pleaseEnterLoyaltyName: "Wepalylygyňyzyň adyny ýazmagyňyzy haýyş edýäris",
    programType: "Programmanyň görnüşi",
    pleaseSelectProgramType: "Programma görnüşini saýlaň",
    simplePoints: "Pleönekeý nokatlar",
    stampsProgram: "Markalar programmasy",
    threshold: "Bosgun",
    pleaseEnterLoyaltyThreshold: "Wepalylyk çägine girmegiňizi haýyş edýäris",
    reward: "Sylag",
    pleaseEnterLoyaltyReward: "Wepalylygyň sylagyny giriziň",
    totalUserPoints: "Jemi ulanyjy ballary",
    totalUserStamps: "Jemi ulanyjy markalary",
    spendingTarget: "Maksat harçlamak",
    spendingTargetHint1:
      "Maksat sarp etmek, müşderiniň sylag gazanmak üçin näçeräk sarp etmelidigi. 1 walýuta bahasy = 1 bal.",
    spendingTargetHint2:
      "Markalaryň nyşany, sylag gazanmak üçin müşderiniň näçe marka ýygnamalydygy. meselem. 5",
    addNewLoyaltyProgram: "Täze wepalylyk programmasyny goşuň",
    addLoyaltyProgram: "Wepalylyk programmasyny goşuň",
    loyaltyProgramType: "Wepalylyk programmasynyň görnüşi",
    pleaseSelectLoyaltyProgramType: "Wepalylyk programmasynyň görnüşini saýlaň",
    nameOfProgram: "Programmanyň ady",
    pleaseEnterProgramName: "Programmanyň adyny giriziň",
    whatIsTheReward: "Sylag näme?",
    egNextPurchaseIsFree: "Mysal üçin Indiki satyn almak mugt",
    customerName: "Müşderiniň ady",
    guestCustomer: "Myhman müşderi",
    orderConfirmedSuccessfully: "Sargyt üstünlikli tassyklandy",
    orderCancelledSuccessfully: "Sargyt üstünlikli ýatyryldy",
    confirmOrder: "Sargyt tassyklaň",
    cancelOrder: "Sargydy ýatyr",
    allOrders: "Orhli sargytlar",
    totalOrders: "Jemi sargytlar",
    doYouWantToAcceptOrder: "Bu buýrugy kabul etmek isleýärsiňizmi?",
    doYouWantToCancelOrder: "Bu buýrugy ýatyrmak isleýärsiňizmi?",
    orderDetails: "Sargyt maglumatlary",
    orderCreatedAt: "Buýruk",
    supplier: "Üpjün ediji",
    productName: "Haryt ady",
    quantity: "Mukdar",
    unitPrice: "Bölümiň bahasy",
    receivedBy: "Aldy",
    reportFrom: "Hasabat",
    totalSupplies: "Jemi üpjünçilik",
    allRightsReserved: "Allhli hukuklar goralandyr",
    toDeleteThisTransaction: "bu Geleşigi pozmak",
    transactionDeletedSuccessfully:
      "Geleşik üstünlikli öçürildi. Bir Stocka inwentara gaýtaryldy.",
    deleteTransaction: "Geleşigi poz",
    transactionDetails: "Geleşik maglumatlary",
    printReceipt: "Çykyş kwitansiýasy",
    channel: "Kanal",
    discount: "Arzanladyş",
    profit: "Girdeji",
    discountedSales: "Arzanladylan satuwlar",
    errorFetchingRecord: "Recordazgy almakda säwlik",
    exportTransactions: "Eksport amallary",
    errorFetchingSalesRecord:
      "Eksport üçin satuw ýazgylaryny almakda ýalňyşlyk.",
    totalSellingPrice: "Jemi satuw bahasy",
    totalCostPrice: "Jemi bahasy",
    appliedDiscount: "Amaly arzanladyş",
    noOfItems: "Harytlaryň sany",
    sales: "Satyş",
    export: "Eksport",
    totalProfit: "Jemi girdeji",
    totalBalanceInPeriod: "Döwrüň jemi balansy",
    allTimeSales: "Timehli wagt satuwy",
    records: "Sazgylar",
    todaysSales: "Şu günki satuw",
    transaction: "geleşik",
    youHaveExceededTotalNumberOfProducts:
      "Meýilnamaňyzda rugsat edilen önümleriň umumy sanyndan geçdiňiz. Has ýokary çäkden lezzet almak üçin meýilnamaňyzy täzeläň.",
    youNeedToHaveLoyaltyProgram:
      "Bu aýratynlygy ulanmak üçin wepalylyk programmasy bolmaly!",
    price: "Bahasy",
    category: "Kategoriýa",
    stockTracking: "Bir Stocka yzarlamak",
    stockCount: "Bir Stocka san",
    taxed: "Salgyt salynýar",
    originalPrice: "Asyl bahasy",
    costPrice: "Bahanyň bahasy",
    unit: "Bölüm",
    productImage: "Haryt şekili",
    taxRate: "Salgyt tölegi",
    taxType: "Salgyt görnüşi",
    trackInventory: "Inwentarlary yzarlaň",
    variants: "Görnüşler",
    hasVariants: "Wariantlary bar",
    importProduct: "Haryt import etmek",
    exportProducts: "Önümleri eksport etmek",
    addNewProduct: "Täze önüm goşuň",
    viewCategory: "Kategoriýany gör",
    viewSuppliers: "Üpjün edijileri görmek",
    receiveInventory: "Sanawy al",
    printAllProductsTag: "Productshli önümler belligini çap ediň",
    deleteAll: "Hemmesini poz",
    totalProducts: "Jemi önümler",
    youveSuccessfullyAddedANewCategory: "Täze kategoriýa üstünlikli goşduňyz",
    addNewCategory: "Täze kategoriýa goşuň",
    addCategory: "Kategoriýa goşuň",
    categoryName: "Kategoriýanyň ady",
    pleaseEnterCategoryName: "Kategoriýa adyny giriziň",
    stampsTarget: "Marka nyşany",
    sendInventory: "Inwentar iber",
    productDetails: "Haryt maglumatlary",
    youveSuccessfullyEditedThisCategory:
      "Bu kategoriýany üstünlikli redaktirlediňiz",
    update: "Täzelen",
    categoryList: "Kategoriýa sanawy",
    categories: "Kategoriýalar",
    enterQuantityToUpdate: "Täzelenmek üçin mukdar giriziň",
    inventorySentSuccessfully: "Inwentar üstünlikli iberildi!",
    updateInventory: "Sanawy täzeläň",
    currentQuantity: "Häzirki mukdar",
    pleaseEnterQuantityToAdd: "Goşmak isleýän mukdaryňyzy giriziň",
    pleaseSelectABranch: "Şahamça saýlaň",
    searchForBranch: "Şahamça gözläň",
    youCantSendMoreThanStock: "Aksiýaňyzdakydan köp iberip bilmersiňiz",
    send: "Iber",
    pleaseEnterQuantityToSend: "Ibermek isleýän mukdaryňyzy giriziň",
    productNameIsRequiredOnRow: "önümiň ady yzygiderli talap edilýär",
    productCategoryIsRequiredOnRow:
      "önüm kategoriýasy yzygiderli talap edilýär",
    productPriceIsRequiredOnRow: "önümiň bahasy yzygiderli talap edilýär",
    productUnitIsRequiredOnRow: "önüm birligi yzygiderli talap edilýär",
    productQuantityIsRequiredOnRow: "önümiň mukdary yzygiderli talap edilýär",
    productVariantsRequireTracking: "önümiň görnüşleri yzarlamagy talap edýär!",
    pleaseAddVariantClickButton:
      "Goşmak düwmesine basyp, bir wariant goşmagyňyzy haýyş edýäris!",
    totalVariantsMoreThanSelectedQuantity:
      "Jemi wariantlar saýlanan önüm mukdaryndan has köp, Pls wariant mukdaryny azaldýar",
    productEditedSuccessfully: "Önüm üstünlikli redaktirlendi!",
    fileTooLargeLessThan4Mb:
      "Faýlyň ölçegi gaty uly! Faýlyň ululygy 4MB-den az bolmaly.",
    suchVariantAlreadyExist: "Şeýle wariant eýýäm bar",
    addVariants: "Wariantlary goşuň",
    editProduct: "Önümi redaktirläň",
    pleaseEnterProductName: "Önümiň adyny giriziň",
    pleaseEnterProductPrice: "Haryt bahasyny giriziň",
    pleaseEnterProductOriginalPrice: "Önümiň asyl bahasyny giriziň",
    productDescription: "Önümiň beýany",
    selectProductCategory: "Önüm kategoriýasyny saýlaň",
    pleaseSelectProductCategory: "Önüm kategoriýasyny saýlaň",
    productCostPrice: "Önümiň bahasy",
    productSellingPrice: "Haryt satuw bahasy",
    productOriginalPrice: "Önümiň asyl bahasy",
    maxFileSizeAllowedIs4Mb: "Maks. Faýlyň ölçegi 4mb",
    productsWithPicturesArePublished:
      "Suratlar bilen önümler sargyt almak üçin “Discover” -de çap edilýär",
    shouldThisProductBeTracked: "Bu önümi yzarlamalymy?",
    pleaseSelectStockUnit: "Bir stocka bölümini saýlaň",
    stockUnit: "Bir Stocka bölümi",
    bag: "BAG",
    bottle: "Çüýşe",
    bunch: "BUNCH",
    can: "BOLAR",
    carton: "KARTON",
    crate: "Döret",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "EDEBI .AT",
    pack: "Gap",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Önümiň mukdaryny giriziň",
    productQuantity: "Önümiň mukdary",
    isThisProductTaxed: "Bu önüme salgyt salynýarmy?",
    selectTaxType: "Salgyt görnüşini saýlaň",
    pleaseSelectTaxType: "Salgyt görnüşini saýlaň",
    progressive: "Ösüş",
    proportional: "Proporsional",
    pleaseEnterProductTaxRate: "Önüm salgyt stawkasyny giriziň",
    doesProductHaveVariants: "Bu önümiň görnüşleri barmy?",
    type: "Görnüşi",
    value: "Gymmatlyk",
    pleaseEnterVariantType: "Wariantyň görnüşini giriziň",
    pleaseEnterVariantValue: "Wariantyň bahasyny giriziň",
    pleaseEnterVariantPrice: "Wariantyň bahasyny giriziň",
    pleaseEnterVariantQuantity: "Wariantyň mukdaryny giriziň",
    productDeletedSuccessfully: "Önüm üstünlikli öçürildi!",
    categoryDeletedSuccessfully: "Kategoriýa üstünlikli öçürildi!",
    toDeleteThisProduct: "bu önümi pozmak üçin",
    invalidProductQuantity: "Nädogry önüm mukdary",
    quantityAddedIsOverStock: "Goşýan mukdaryňyz, aksiýaňyzdakydan köp.",
    itemInventoryNotTracked: "Harytlaryň sanawy yzarlanok",
    addBulkQuantity: "Köp mukdarda goşuň",
    enterBulkQuantity: "Köp mukdarda giriň",
    pleaseEnterBulkQuantity: "Köp mukdarda girmegiňizi haýyş edýäris",
    youveSuccessfullyAddedANewProduct: "Täze önümi üstünlikli goşduňyz!",
    pleaseEnterProductSellingPrice: "Haryt satuw bahasyny giriziň",
    doYouWantToTrackProductStock: "Önümiň aksiýasyny yzarlamak isleýärsiňizmi?",
    sellingPrice: "Satyş bahasy",
    setProductExpiryReminder: "Önümiň möhleti barada ýatlatma düzüň",
    productExpiryDate: "Önümiň möhleti",
    startRemindingFrom: "Fromatlatmaga başlaň",
    productSuppliesAddedSuccessfully: "Önüm üpjünçiligini üstünlikli goşduňyz.",
    addProductSupplies: "Haryt önümlerini goşuň",
    pleaseSelectSupplier: "Üpjün edijini saýlaň",
    nameOfProduct: "Önümiň ady",
    pleaseSelectProduct: "Haryt saýlaň",
    productVariant: "Haryt görnüşi",
    pleaseSelectAVariant: "Bir warianty saýlaň",
    pleaseEnterUnitPrice: "Bölümiň bahasyny giriziň",
    businessBranch: "Iş bölümi",
    pleaseSelectBranch: "Şahamçany saýlaň",
    youveSuccessfullyAddedANewSupplier:
      "Täze üpjün edijini üstünlikli goşduňyz",
    addSupplier: "Üpjün edijini goşuň",
    pleaseEnterSupplierEmail: "Üpjün edijiniň e-poçta salgysyny giriziň",
    pleaseAddADescription: "Düşündiriş goşmagyňyzy haýyş edýäris",
    anErrorOccuredProductsDeleted:
      "Amal ýerine ýetirilende ýalňyşlyk ýüze çykdy. Üns beriň, amalda az sanly önüm öçürilen bolmagy mümkin",
    bulkDelete: "Köpçülik öçürmek",
    youAreAboutToDelete: "Öçürmekçi bolýarsyňyz",
    product: "Haryt",
    isDueToRedeem: "tölemeli",
    aReward: "sylag",
    pleaseSelectCustomerToReeemReward:
      "Sylagy yzyna almak üçin müşderi saýlaň.",
    youHaveNoLoyaltyProgramRunning: "Sizde işjeň wepalylyk programmasy ýok",
    customerHhasNoPointsInLoyaltyProgram:
      "Müşderiniň bu wepalylyk programmasynda hiç hili nokady ýok",
    proceedWithPayment: "Töleg bilen dowam ediň?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ballary ulanyp, geleşik üçin pul tölejek bolýarsyňyz.",
    customerHas: "Müşderi bar",
    worth: "gymmaty",
    andIsNotSufficientToPayForTransaction:
      "we bu amal üçin tölemek üçin ýeterlik däl. Başga töleg usuly bilen balansy goşmak isleýärlermi?",
    addCustomerLoyalty: "Müşderiniň wepalylygyny goşuň",
    pleaseAddCustomerFirst: "Ilki bilen bir müşderi goşuň ýa-da saýlaň.",
    pleaseWaitWhileWeGetReady: "Taýýar bolanymyzda garaşyň",
    lowStock: "Pes paý",
    pleaseEnterAmountTendered: "Teklip edilen mukdary giriziň",
    areYouSureToBookSaleOffline:
      "Bu satuwy awtonom görnüşde bron etmek isleýändigiňize ynanýarsyňyzmy?",
    saleWouldBeBookedAutomatically:
      "Internet açanyňyzda satuw awtomatiki bron edilerdi",
    offlineSalesBookingCancelled: "Oflayn satuw bron etmegi ýatyryldy",
    covid19Message:
      "COVID19: ýaýramagynyň öňüni almak üçin elleriňizi sabyn bilen ýuwuň ýa-da Sanitize beriň. Elmydama howpsuz boluň",
    saleSuccessfullyRecorded: "Satyş üstünlikli ýazga alyndy!",
    sendReceiptToEmail: "E-poçta kwitansiýa iberiň",
    sendThankYouSms: "Sag boluň",
    sendShippingDetails: "Ibermek jikme-jikliklerini iberiň",
    addLoyalty: "Wepalylyk goş",
    searchCustomerNameOrNumber: "Müşderiniň adyny ýa-da belgisini gözläň",
    clickTheSearchCustomerBox:
      "Müşderi gözlemek gutusyna we skaner kartasyna basyň",
    enterProductPrice: "Önümiň bahasyny giriziň",
    enterPriceFor: "Bahasyny giriziň",
    searchForProduct: "Önüm gözläň",
    all: "Hemmesi",
    backToDashboard: "Dolandyryş paneline dolan",
    viewDraftSales: "Taslamanyň satuwyny görüň",
    variantSelected: "görnüşi saýlandy",
    variant: "görnüşi",
    thePreviousVariantSelectionNotAvailable:
      "Öňki warianty baha esasynda saýlanan täze wariant üçin elýeterli däl, saýlawyňyzy üýtgediň.",
    pleaseSupplyPositiveQuantityNumber:
      "Poçta mukdaryny bermegiňizi haýyş edýäris",
    lowStockFor: "Pes bahasy",
    clearVariants: "Görnüşleri arassalaň",
    pleaseEnterQuantity: "Mukdar giriziň",
    picture: "Surat",
    redemptionToken: "Dikeldiş belligi",
    token: "Token",
    totalSpent: "Jemi harçlandy",
    confirmPayment: "Tölegini tassyklaň",
    hasPaymentBeenMade: "Töleg üstünlikli işledimi?",
    enterTransactionReference: "Tölän geleşik salgylanmany giriziň",
    pleaseSelectACustomer: "Müşderi saýlaň!",
    areYouSureToApplyDiscount:
      "Arzanladyş bermek isleýändigiňize ynanýarsyňyzmy?",
    addYourBankAccountToEnableUssd:
      "UPay tarapyndan gyssagly USSD geçirişini açmak üçin bank hasabyňyzy goşuň",
    totalAmountToPay: "Tölemeli jemi",
    doYouWantToCancelTransaction: "Bu geleşigi ýatyrmak isleýärsiňizmi?",
    savePrintBill: "Hasaby ýazdyryň / çap ediň",
    enterAmountCollectedForCustomer: "Müşderi üçin ýygnan mukdary giriziň",
    recordSale: "Satyş ýazgylary",
    checkOutWith: "Barlap görüň",
    instantTransfer: "Derrew geçirmek",
    dialTheUSSDCode: "USSD koduny aýlaň",
    pleaseSelectABank: "Bank saýlaň",
    payWithUSSD: "USSD bilen töläň",
    sendBillTo: " - Hasaby iberiň",
    waitingForUSSDTransfer: "USSD geçirişine garaşýarys",
    percent: "Göterim",
    applyDiscount: "Arzanladyş ulanyň",
    thisBillHasBeenSaved: "Bu kanun ýatda saklandy",
    saveDraft: "Taslamany ýazdyryň",
    pleaseTypeANameToIdentifyCustomer:
      "Müşderini tanamak üçin at ýazmagyňyzy haýyş edýäris",
    paymentDetails: "Töleg maglumatlary",
    basePrice: "Esasy bahasy",
    staff: "Işgärler",
    subTotal: "SubTotal",
    durationMonths: "Dowamlylygy (aýlar)",
    selectADuration: "Dowamlylygy saýlaň",
    oneMonth: "1 aý",
    twoMonths: "2 aý",
    threeMonths: "3 aý",
    sixMonths: "6 aý",
    twelveMonths: "12 aý",
    eighteenMonths: "18 aý",
    twentyFourMonths: "24 aý",
    twoMonthsFree: "(2 aý mugt)",
    threeMonthsFree: "(3 aý mugt)",
    fiveMonthsFree: "(5 aý mugt)",
    yourAccountHasBeen: "Hasabyňyz boldy",
    renewed: "täzelendi",
    upgraded: "täzelendi",
    successfully: "üstünlikli",
    yourSubscription: "Abunalygyňyz",
    youAreCurrentlyEnrolledOnA: "Häzirki wagtda a",
    pleaseChooseAPaymentOption: "Töleg opsiýasyny saýlaň",
    planRenewal: "Täzelenmegi meýilleşdiriň",
    planUpgrade: "Täzelenmegi meýilleşdiriň",
    pleaseSelectDurationToPayFor: "Tölemek isleýän wagtyňyzy saýlaň",
    staffAccounts: "Işgärleriň hasaplary",
    ecommerce: "Söwda",
    pleaseSelectAPlan: "Meýilnama saýlaň",
    includeAddons: "Goşmaçalary goşuň",
    viewTransactions: "Geleşikleri görmek",
    customerHasNoCompletedTansactions: "Müşderi entek gutaranok",
    branch: "Şahamça",
    enterNumberOfEcommerceBranches: "Söwda bölümleriniň sanyny giriziň",
    enterNumberOfEcommerceBranchesToPay:
      "Tölemek isleýän telekeçilik şahamçalaryňyzyň sanyny giriziň",
    ecommerceIntegration: "Söwda integrasiýasy",
    enterNumberOfBranches: "Filiallaryň sanyny giriziň",
    enterNumberOfAdditionalBranchesToPay:
      "Tölemek isleýän goşmaça şahamçalaryňyzyň sanyny giriziň",
    enterNumberOfStaffs: "Işgärleriň sanyny giriziň",
    enterNumberOfStaffsToPayFor:
      "Tölemek isleýän işgärleriňiziň sanyny giriziň",
    discountApplies: "Arzanladyş ulanylýar",
    starsOnThe: "ýyldyzlary",
    offer: "teklip",
    get: "Al",
    moreStarsToRedeem: "satyn almak üçin has köp ýyldyz",
    taxVat: "Salgyt (goşulan baha üçin salgydyň)",
    callCashierToCompletePayment: "Tölegiň doly bolmagy üçin kassirä jaň ediň",
    receipt: "Kwitansiýa",
    dear: "Gadyrly",
    thankYouForYourOrderFindGoods:
      "Sargyt üçin sag boluň. Ylalaşylyşy ýaly üpjün edilen aşakdaky harytlary tapmagyňyzy haýyş edýäris.",
    shippingNote: "Iberiş belligi",
    enterShippingNote: "Iberiş belligini giriziň",
    shippingAddress: "Iberilýän salgy",
    enterShippingAddress: "Iberiş salgysyny giriziň",
    wellDoneYouAreDueToRedeem: "Berekella! Siz tölemeli",
    toGetYourRewardNextVisit:
      "indiki saparyňyzda sylagyňyzy almak üçin. Sagbol",
    pointsOnThe: "Ballar",
    morePointsToRedeem: "satyn almak üçin has köp bal",
    showCode: "Kod görkez",
    toGetYourRewardOnNextVisit:
      "indiki saparyňyzda sylagyňyzy almak üçin. Sagbol",
    earn: "Girdeň",
    delivaryNote: "Elýeterli bellik",
    draftSales: "Taslamanyň satuwy",
    startDate: "Başlangyç senesi",
    endDate: "Ahyrky senesi",
    orders: "Sargytlar",
    checkout: "töleg",
    noProductItem: "Haryt önümi ýok",
    selectProductImage: "Önümiň suratyny saýlaň",
    selectCountry: "Countryurt saýlaň",
    selectRegion: "Döwlet / sebiti saýlaň",
    printProductTag: "Önüm belligini çap et",
    transactionReference: "Geleşik salgylanmasy",
    Cashier: "Kassir",
    Manager: "Dolandyryjy",
    Owner: "Eýesi",
    Admin: "Admin",
    addPartners: "Hyzmatdaşlary goşuň",
    addNewLoyaltyPartner: "Täze wepaly hyzmatdaş goşuň",
    pleaseEnterCompanyName: "Kompaniýanyň adyny giriziň",
    companyName: "Kompaniýanyň ady",
    pleaseEnterCompanyRepName: "Kompaniýanyň wekiliniň adyny giriziň",
    companyRepName: "Kompaniýanyň wekiliniň ady",
    pleaseEnterCompanyRepEmail:
      "Kompaniýanyň wekiliniň e-poçta salgysyny ýazmagyňyzy haýyş edýäris",
    companyRepEmail: "Kompaniýanyň wekili",
    pleaseEnterCompanyRepPhone:
      "Kompaniýanyň wekiliniň telefon belgisini giriziň",
    companyRepPhone: "Telefon belgisi",
    addReward: "Sylag goşuň",
    pleaseEnterRewardName: "Baýrak adyny ýazmagyňyzy haýyş edýäris",
    rewardName: "Sylag ady",
    rewardQuantity: "Sylag mukdary",
    rewardDescription: "Sylag beýany",
    rewardType: "Sylag görnüşi",
    pleaseEnterRewardType: "Baýrak görnüşini ýazmagyňyzy haýyş edýäris",
    pleaseEnterRewardQuantity: "Baýrak mukdaryny giriziň",
    pleaseEnterRewardDescription: "Baýrak düşündirişini giriziň",
    fineDining: "Gowy nahar",
    luxuryFashion: "Lýuks moda",
    hotels: "Myhmanhanalar",
    travel: "Syýahat",
    foodAndBeverage: "Iýmit we içgi",
    fashion: "Moda",
    health: "Saglyk",
    furniture: "Mebel",
    entertainment: "Güýmenje",
    automobile: "Awtoulag",
    education: "Bilim",
    beautyAndSpa: "Gözellik we spa",
    staycation: "Staycation",
    events: "Wakalar",
    trips: "Syýahatlar",
    oilAndGas: "Nebit we gaz",
    laundry: "Kir ýuwmak",
    partnerCategory: "Hyzmatdaş kategoriýasy",
    freeItem: "Mugt element",
  },
  Ukrainian: {
    cashier: "касир",
    manager: "менеджер",
    owner: "власник",
    online: "онлайн",
    offline: "офлайн",
    changePassword: "Змінити пароль",
    currentPasswordMessage: "Будь ласка, введіть свій поточний пароль",
    passwordMessage: "Будь ласка, введіть свій пароль",
    currentPassword: "Поточний пароль",
    password: "пароль",
    confirmPasswordMessage: "Будь ласка, підтвердьте свій пароль!",
    confirmPassword: "Підтвердьте пароль",
    sendViaEmail: "Надіслати електронною поштою",
    sendViaWhatsapp: "Надіслати через WhatsApp",
    downloadPdf: "Завантажити PDF",
    paid: "оплачується",
    unpaid: "неоплачений",
    partial: "частковий",
    closeInvoice: "Ви хочете закрити рахунок -фактуру?",
    closeInvoiceConfirmation:
      "Рахунок -фактуру не може бути збережено. Ви хочете закрити?",
    yes: "так",
    no: "немає",
    invoice: "Рахунок -фактура",
    wasDeducted: "був відрахований",
    for: "за",
    item: "Пункт",
    addProduct: "Додати товар",
    paymentReference: "Довідка про оплату",
    amountPaid: "Виплачувана сума",
    discountAmount: "Сума знижки",
    amountDue: "Сума боргу",
    amount: "Сума",
    dueDate: "Термін виконання",
    paymentType: "Тип оплати",
    card: "Картка",
    cash: "Готівкою",
    bankTransfer: "Банківський переказ",
    paymentMessage: "Повідомлення про оплату",
    description: "Опис",
    sendReceipt: "Надіслати квитанцію",
    delete: "Видалити",
    save: "Зберегти",
    resend: "Повторно надіслати",
    saveAndSend: "Зберегти",
    invoiceSaved: "Рахунок збережено!",
    selectPaymentMethod: "Виберіть спосіб оплати!",
    selectCustomer: "Виберіть клієнта!",
    cartEmptyError:
      "Список кошиків не може бути порожнім, закрийте рахунок -фактуру та додайте товар у кошик!",
    subscriptionExpired:
      "Ваша підписка закінчилася, і ваш обліковий запис тепер обмежений. Натисніть кнопку нижче, щоб оновити свій обліковий запис",
    renew: "Поновити",
    holdOn: "Зачекай",
    customerBank: "Банк клієнтів",
    cancel: "Скасувати",
    selectACustomer: "Виберіть Замовник",
    invoiceSuccessfulPdfError:
      "Рахунок -фактуру створено успішно, але створення PDF займає більше часу, ніж зазвичай. Перевірте найближчим часом.",
    downloadingInvoice: "Завантаження рахунку -фактури",
    downloadingReceipt: "Завантаження квитанції",
    whatsappReceiptError:
      "Під час надсилання квитанції через WhatsApp сталася помилка. Повторіть спробу.",
    receiptToWhatsapp: "Квитанція пересилається до WhatsApp",
    thankYouForPatronage: "Дякую за ваше патронаж",
    hereIsYourReceipt: "Ось ваша квитанція про оплату",
    errorSendingEmailReceipt:
      "Під час надсилання квитанції електронною поштою сталася помилка. Повторіть спробу або зв’яжіться зі службою підтримки",
    receiptSentToEmail: "Квитанція надіслана на електронну адресу замовника",
    invoiceSentToEmail:
      "Рахунок -фактуру надіслано на електронну адресу замовника",
    invoiceSuccessWhatsappError:
      "Рахунок -фактуру створено успішно, але сталася помилка надсилання до WhatsApp. Спробуйте ще раз у списку рахунків -фактур",
    invoiceSuccessfulEmailError:
      "Рахунок-фактуру створено успішно, але під час надсилання електронною поштою сталася помилка. Повторіть спробу зі списку рахунків -фактур",
    invoiceSentToWhatsapp: "Рахунок -фактуру перенаправлено до WhatsApp",
    hello: "Здравствуйте",
    pleaseDownloadInvoice: "Будь ласка, завантажте рахунок -фактуру",
    pleaseDownloadReceipt: "Завантажте квитанцію",
    from: "від",
    email: "Електронна пошта",
    upgrade: "Оновлення",
    youAreOnFreePlan: "Ви користуєтесь безкоштовним планом.",
    clickOn: "Натисніть на",
    yourPlanInFewSteps: " вашу підписку за кілька швидких кроків.",
    to: "до",
    yourSubscriptionHasExpired:
      "Ваша підписка закінчилася, і ваш обліковий запис тепер обмежений.",
    days: "днів",
    yourSubscriptionExpiresIn: "Ваша підписка на Loystar закінчується у",
    createAcount: "Створити аккаунт",
    signIn: "Увійти",
    continue: "Продовжити",
    enterOtp: "Введіть PIN -код OTP",
    enterValidOtp: "Введіть дійсний PIN -код",
    pin: "PIN -код",
    tokenSentToMail: "На вашу електронну адресу надіслано маркер",
    passwordResetSuccessful: "Скидання пароля пройшло успішно",
    somethingWentWrong: "Щось пішло не так!",
    resetPassword: "Скинути пароль",
    iHaveResetCode: "У мене є код скидання пароля",
    pleaseEnterEmail: "Будь ласка, введіть свою електронну адресу",
    aTokenWillBeSentToEmail: "Токен буде надіслано на вашу електронну пошту",
    enterEmail: "Введіть свою електронну адресу",
    sendinYourToken: "Надсилання маркера ...",
    makeSureItMatchesPassword:
      "Переконайтеся, що він відповідає вашому новому паролю",
    pleaseChooseNewPassword: "Виберіть новий пароль",
    chooseNewPassword: "Виберіть новий пароль",
    enterNewPassword: "Введіть новий пароль для підтвердження",
    enterTokenSent: "Введіть маркер, який був надісланий на вашу пошту",
    resetToken: "Скинути маркер",
    resettingPassword: "Скидання пароля ...",
    signUp: "Зареєструйтесь",
    adminSignInWithEmail:
      " Адміністратор входить за допомогою електронної пошти, а персонал - із ім’ям користувача.",
    pleaseEnterEmailOrUsername:
      "Будь ласка, введіть свою електронну адресу або ім’я користувача",
    emailOrUsername: "Електронна пошта або ім’я користувача",
    pleaseEnterPassword: "Будь ласка, введіть пароль",
    createAnAccount: "Створити аккаунт",
    forgotPassword: "Забули пароль?",
    enterPhoneNumber: "Введіть номер телефону",
    personalData: "Особисті дані",
    validateConfirmationCOde: "Перевірити код підтвердження",
    pleaseEnterFirstName: "Будь ласка, введіть своє ім'я",
    pleaseEnterPhoneNumber: "Будь ласка, введіть свій номер телефону",
    pleaseEnterLastName: "Будь ласка, введіть своє прізвище",
    pleaseEnterBusinessName: "Будь ласка, введіть назву своєї компанії",
    firstName: "Ім'я",
    lastName: "Прізвище",
    businessName: "Найменування фірми",
    previous: "Попередній",
    next: "Далі",
    pleaseSelectBusinessCategory: "Виберіть категорію своєї компанії",
    pleaseEnterValidEmail: "Будь ласка, введіть дійсну електронну адресу",
    pleaseEnterPostCode: "Будь ласка, введіть поштовий індекс",
    postCode: "Поштовий індекс",
    phoneNumberInUse: "Цей номер телефону вже використовується!",
    emailInUse: "Ця електронна адреса вже використовується!",
    foodAndDrinks: "Їжа та напої",
    salonAndBeauty: "Салон краси",
    fashionAndAccessories: "Мода та аксесуари",
    gymAndFitness: "Тренажерний зал та фітнес",
    travelAndHotel: "Подорожі та готель",
    homeAndGifts: "Будинок та подарунки",
    washingAndCleaning: "Миття та чищення",
    gadgetsAndElectronics: "Гаджети та електроніка",
    groceries: "Бакалія",
    others: "Інші",
    submit: "Подати",
    accountCreatedSuccessful: "Ваш обліковий запис створено успішно.",
    pleaseEnterAddress: "Будь ласка, введіть свою адресу",
    addressLine1: "Рядок адреси 1",
    addressLine2: "Адресний рядок 2",
    choosePassword: "Вибрати пароль",
    passwordMustBe6Characters: "Пароль повинен містити не менше 6 символів.",
    howDidYouHearLoystar: "Як ви почули про Loystar?",
    referralCode: "Промо-Код",
    byClickingTheButton: " Натискаючи кнопку нижче, ви погоджуєтесь із",
    termsAndSevice: "Умови",
    wereCreatingAccount: "Ми створюємо ваш обліковий запис",
    proceed: "Продовжити",
    verificationCodeMustBe6: "Код перевірки має складатися з 6 цифр",
    pleaseEnter6DigitCode: "Будь ласка, введіть 6 -значний код",
    enterVerificationCode: "Введіть код підтвердження",
    resendToken: "Повторно надіслати маркер",
    verify: "Перевірити",
    transactions: "Операції",
    todaySales: "Сьогоднішні продажі",
    salesHistory: "Історія продажів",
    supplyHistory: "Історія поставок",
    new: "Новий",
    invoices: "Рахунки -фактури",
    disbursements: "Виплати",
    offlineSales: "Офлайн -продаж",
    products: "Вироби",
    customers: "Клієнти",
    multiLevelLoyalty: "Багаторівнева лояльність",
    loyaltyPrograms: "Програми лояльності",
    members: "Члени",
    appStore: "App Store",
    orderMenu: "Меню замовлення",
    settings: "Налаштування",
    staffAndBranches: "Персонал та відділення",
    myAccount: "Мій рахунок",
    switchToSellMode: "Перейдіть у режим продажу",
    signOut: "Вийти з аккаунта",
    getFreeSubscription: "Отримайте безкоштовну підписку",
    onlyNumbersAllowed: "Допускаються лише цифри",
    yourAccountMustBe10Digits: "номер вашого рахунку має бути 10 -значним",
    yourBvnMustBe11: "ваш номер BVN має бути 11 -значним",
    pleaseSelectBank: "Виберіть свій банк",
    selectYourBank: "Виберіть свій банк",
    enterBankAccountNumber: "Введіть номер банківського рахунку",
    enterBvnNumber: "Введіть свій BVN",
    connectBankAccount: "Підключіть банківський рахунок",
    passwordResetSuccessfulAndSignOut:
      "Ваш пароль успішно скинуто. Натисніть кнопку Вийти нижче, щоб знову ввійти",
    enterCurrentPassword: "Введіть поточний пароль",
    pleaseEnterCurrentPassword: "Будь ласка, введіть поточний пароль",
    phoneNumber: "Телефонний номер",
    sex: "Секс",
    dateOfBirth: "Дата народження",
    state: "Держава",
    country: "Країна",
    loyaltyId: "Ідентифікатор лояльності",
    createdAt: "Створено в",
    noOfTransactionsMade: "Кількість здійснених операцій",
    yourDownloadWillStart: "Ваше завантаження почнеться за мить",
    exportCustomers: "Експорт клієнтів",
    youhaveSuccessfullyToppedUp: "Ви успішно поповнили свої SMS -одиниці.",
    youNowHave: "Тепер у вас є",
    smsUnits: "Одиниці SMS",
    enterNumberOfSmsUnits:
      "Введіть кількість одиниць SMS, які ви маєте намір придбати",
    pleaseEnterANumericValue: "Будь ласка, введіть числове значення",
    pay: "Оплата",
    accountEditedSuccessfully: "Ваш обліковий запис успішно відредаговано.",
    youAeCurrentlyOn: "Ви зараз ввімкнули",
    plan: "План",
    userData: "Дані користувача",
    businessData: "БІЗНЕС -ДАНІ",
    businessCategory: "Ділова категорія",
    pleaseSelectCurrency: "Виберіть валюту",
    selectYourCurrency: "Виберіть валюту",
    purchaseMoreSmsUnits:
      "Придбайте більше SMS -одиниць, використовуючи форму нижче",
    youHave: "Ти маєш",
    atLeast4SmsUnitsRrequired:
      "Для перевірки потрібні принаймні 4 одиниці смс, поповніть!",
    pleaseVerifyYourAccountToComplyWithKyc:
      'Перевірте свій банківський рахунок на відповідність вимогам "Знай свого клієнта" (KYC). Це дозволить вам збирати платежі через USSD або миттєвий переказ, отримувати замовлення від клієнтів та обробляти розрахунки за транзакціями. Застосовується тільки до нігерійських торговців. Будь ласка, натисніть кнопку нижче, щоб розпочати.',
    reConnectBankAccount: "Повторно підключіть банківський рахунок",
    accountName: "Назва рахунку",
    accountNumber: "Номер рахунку",
    bankName: "Назва банку",
    verified: "Перевірено",
    accountDetails: "Реквізити рахунку",
    kycBankAccount: "KYC",
    createTier: "Створити рівень",
    fileUploadSuccessful: "файл успішно завантажено",
    fileUploadFailed: "не вдалося завантажити файл",
    createLoyaltyProgram: "Створіть програму лояльності",
    createLoyalty: "Створюйте лояльність",
    name: "Назва",
    loyaltyArtwork: "Творчість лояльності",
    clickToUpload: "Натисніть, щоб завантажити",
    amountToPointsRatio: "Співвідношення суми до балів",
    points: "Очки",
    recommendedAmountToPointRatio:
      "Рекомендовано: від 1 до 1 бала. тобто за кожну витрачену 1 Найру ваші клієнти отримують 1 бал",
    pleaseTypeIn: "Будь ласка, введіть",
    toDeleteLoyalty: "видалити цю лояльність",
    deleteLoyalty: "Видалити лояльність",
    toConfirm: "підтвердити",
    edit: "Редагувати",
    pointsAwardedSuccessfully: "Очки успішно зараховані.",
    enterPointValueToAward:
      "Введіть цінність балів, яку ви хотіли б нагородити клієнтам",
    award: "Нагорода",
    awardPointValuesToCustomer: "Нагородження вартості балів",
    enrollMembersToLoyalty: "Зареєструйте учасників у лояльності",
    awardPoints: "Нагородження очок",
    enroll: "Зареєструйтесь",
    home: "Додому",
    loyalty: "Вірність",
    enrollCustomers: "Зареєструйте клієнтів",
    selected: "Вибрано",
    customer: "Замовник",
    loyaltyActivationSuccessful: "Активація лояльності пройшла успішно.",
    loyaltyDeactivationSuccessful: "Деактивація лояльності пройшла успішно.",
    viewTier: "Переглянути рівень",
    deactivate: "Деактивувати",
    activate: "Активувати",
    actions: "Дії",
    nameOfLoyalty: "Ім'я лояльності",
    loyaltyStatus: "Статус лояльності",
    numberOfTiers: "Кількість рівнів",
    createdOn: "Створено Увімкнено",
    createATier: "Створіть рівень",
    stopCreatingTierConfirmation: "Ви хочете припинити створення рівня?",
    stopEditingTierConfirmation: "Ви хочете припинити редагування цього рівня?",
    nameOfTier: "Назва рівня",
    minimumSpendingTarget: "Цільові мінімальні витрати",
    maximumSpendingTarget: "Максимальна цільова сума витрат",
    minimumSpendingTargetRequired: "необхідна мінімальна цільова сума витрат",
    maximumSpendingTargetRequired: "потрібна максимальна цільова сума витрат",
    rewardSponsor: "Спонсор винагороди",
    pleaseChooseARewardSponsor: "Виберіть спонсора винагороди",
    self: "Я",
    partner: "Партнер",
    rewardPartner: "Нагородний партнер",
    pleaseSelectRewardPartner: "Виберіть свого партнера -винагороду",
    rewards: "Нагороди",
    pleaseSelectAReward: "Виберіть винагороду",
    instructionsOnRedeemingReward:
      "Вказівки щодо того, як клієнт повинен виплатити винагороду",
    pleaseFillInThisField: "Будь ласка, заповніть це поле!",
    toDeleteThisTier: " видалити цей рівень",
    deleteTier: "Видалити рівень",
    viewMembers: "Переглянути учасників",
    membersEnrolled: "Зареєстровані члени",
    instruction: "Інструкція",
    membersIn: "Члени в",
    availableTiersInLoyalty: "Доступні рівні (и) в програмі лояльності",
    tiers: "Рівні",
    totalTier: "ВСЬОГО РІВНЯ",
    availableLoyaltyProgramme: "Доступна програма лояльності",
    totalLoyalties: "ЗАГАЛЬНА ЛОЯЛЬНІСТЬ",
    memberDetails: "Деталі учасника",
    nameOfCustomer: "Ім'я Замовника",
    address: "Адреса",
    allEnrolledMembers: "Усі зареєстровані члени",
    thisIsToWishYouHappyBirthday:
      "Це привітання з днем народження та щасливого життя. Дякую за все, що ти є для Loyster. Веселих свят!",
    inputAnOfferPlease: "Введіть, будь ласка, пропозицію",
    pleaseSelectTheInsertPoint:
      "Виберіть точку вставки у повідомленні та натисніть ще раз",
    birthdayOfferAndMessage: "Пропозиція та повідомлення на день народження",
    birthdayOffer: "Пропозиція на день народження",
    birthdayMessage: "Повідомлення про день народження",
    noOfferFound: "Пропозиції не знайдено",
    settingABirthdayOffer:
      "Встановлення пропозиції на день народження дозволяє клієнтам отримати цю пропозицію за допомогою SMS у день народження",
    createOffer: "Створити пропозицію",
    whatIsTheOffer: "Яка пропозиція?",
    editMessage: "Редагувати повідомлення",
    insert: "Вставити",
    theNameOfCustomerInserted: "Тут буде вставлено ім’я клієнта",
    theBirtdayOfferInserted:
      "Пропозиція про день народження буде вставлена тут",
    youSuccessfullyAddedNewBranch: "Ви успішно додали нову гілку!",
    addNewBranch: "Додати нову гілку",
    addBranch: "Додати гілку",
    additionalBranchWillIncur: "Додаткове відділення може виникнути",
    perBranch: "за гілку",
    ecommerceBranchCosts: "Витрати на відділення електронної комерції",
    pleaseEnterBranchName: "Будь ласка, введіть назву філії",
    pleaseEnterBranchAddress1: "Будь ласка, введіть 1 -й рядок адреси філії",
    enterBranchAddress1: "Введіть 1 -й рядок адреси відділення",
    enterBranchAddress2: "Введіть 1 -й рядок адреси відділення",
    pleaseEnterBranchAddress2: "Будь ласка, введіть 2 рядок адреси філії",
    enterBranchName: "Введіть назву філії",
    successfullyAddedStaff: "Ви успішно додали нового персоналу!",
    addNewStaff: "Додати нового персоналу",
    addStaff: "Додати персонал",
    additionalStaffWillIncur: "Буде нести додатковий персонал",
    perStaff: "на одного персоналу.",
    pleaseEnterStaffEmail: "Будь ласка, введіть електронну адресу персоналу",
    pleaseEnterStaffUsername: "Будь ласка, введіть ім’я користувача персоналу",
    pleaseEnterStaffPassword: "Будь ласка, введіть пароль персоналу",
    pleaseSelectStaffRole: "Виберіть роль персоналу",
    selectStaffRole: "Виберіть роль персоналу",
    enterStaffEmail: "Введіть електронну адресу персоналу",
    enterStaffUsername: "Введіть ім’я користувача персоналу",
    enterStaffPassword: "Введіть пароль персоналу",
    spacesNotAllowedInUsername: "пробіли в імені користувача не допускаються",
    admin: "Адмін",
    pleaseSelectBusinessToAttachStaff:
      "Будь ласка, оберіть бізнес, щоб приєднати персонал",
    searchForBranchToAttachStaff:
      "Знайдіть відділення, щоб залучити співробітників",
    username: "Ім'я користувача",
    role: "Роль",
    areYouSureToDeleteThis: "Ви впевнені, що видалили це",
    branches: "Відділення",
    upgradeYourPlan: "Оновіть свій план.",
    add: "ДОДАТИ",
    addNew: "Додати новий",
    customerWithEmailAlreadyExists:
      "Клієнт з електронною поштою/номером телефону вже існує!",
    successfullyAddedNewCustomer: "Ви успішно додали нового клієнта!",
    addCustomer: "Додати клієнта",
    pleaseEnterCustomerFirstName: "Будь ласка, введіть ім'я клієнта",
    pleaseEnterCustomerLastName: "Будь ласка, введіть прізвище клієнта",
    pleaseEnterCustomerPhoneNumber:
      "Будь ласка, введіть номер телефону клієнта",
    pleaseEnterCustomerEmail: "Будь ласка, введіть електронну адресу клієнта",
    pleaseEnterCustomerAddressLine:
      "Будь ласка, введіть адресний рядок клієнта",
    pleaseEnterCustomerOtherAddress: "Будь ласка, введіть іншу адресу клієнта",
    pleaseSelectCustomerGender: "Виберіть стать клієнта",
    gender: "Стать",
    male: "Чоловічий",
    female: "Жіночий",
    bank: "Банк",
    selectBank: "Виберіть банк",
    stateOrRegionOrProvince: "Штат/Регіон/Провінція",
    customerNotes: "Примітки клієнтів",
    sendSms: "Надіслати SMS",
    editCustomer: "Редагувати клієнта",
    redeemReward: "Активуйте винагороду",
    issueLoyaltyCard: "Видайте картку лояльності",
    deleteCustomer: "Видалити Клієнта",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Ви успішно призначили ідентифікатор учасника лояльності",
    noMembershipIdAvailable:
      "Немає ідентифікаторів членства. Будь ласка, зв'яжіться з нами за адресою hello@loystar.co",
    sendEmail: "Відправити лист",
    membershipPoints: "Очки членства",
    customerDetails: "Інформація про клієнта",
    close: "Закрити",
    loyaltyBalance: "Баланс лояльності",
    pointsBalance: "Баланс балів",
    starBalance: "Зірковий баланс",
    requiredPoints: "Необхідні бали",
    requiredStars: "Необхідні зірки",
    reddemCode: "Активувати код",
    toDeleteThisCustomer: "видалити цього клієнта",
    customerHasBeenDeletedSuccessfully: "Клієнта успішно видалено!",
    customerWithPhoneAlreadyExists: "Клієнт з номером телефону вже існує",
    customerWasSuccessfullyEdited: "Клієнта успішно відредаговано",
    anErrorOccured: "Сталася помилка",
    phoneNumberIncludeCountry: "Номер телефону (включає код країни)",
    yourFileQueuedForUpload:
      "Ваш файл поставлено в чергу для завантаження. Оновіть сторінку через кілька секунд.",
    thereWasAnErrorPerformingOperation:
      "Під час виконання операції сталася помилка!",
    pleaseSelectFile: "Виберіть файл!",
    oopsOnlyCsvFilesAllowed:
      "На жаль! Дозволено лише файли CSV. Будь ласка, завантажте файл .csv.",
    fileShouldBeSmallerThan5Mb:
      "Файл повинен бути меншим за 5 МБ. Якщо у вас є більший файл, надішліть електронний лист на support@loystar.co. Дякую",
    customerFirstNameIsRequired: "ім'я клієнта потрібно в рядку",
    customerPhoneNumberIsRequired:
      "у рядку потрібно вказати номер телефону клієнта",
    importCustomers: "Імпорт клієнтів",
    upload: "Завантажити",
    clickIconToDownloadCsv:
      "Натисніть цей значок, щоб завантажити шаблон файлу CSV.",
    getGoogleSheetVersion: "Отримайте версію аркуша Google",
    clickOrDragFileToUpload:
      "Натисніть або перетягніть файл у цю область, щоб завантажити",
    missingOrInvalidColumnHeader:
      "Відсутній або недійсний заголовок стовпця. Дотримуйтесь формату шаблону. Дякую.",
    youHaveImported: "Ви імпортували",
    youSuccessfullyRedeemedReward: "Ви успішно викупили свою винагороду!",
    sixDigitCode: "Шестизначний код",
    pleaseEnterCustomerRewardCode: "Будь ласка, введіть код винагороди клієнта",
    enterRewardCode:
      "Введіть код винагороди. (Код винагороди з урахуванням регістру)",
    selectLoyaltyProgram: "Виберіть програму лояльності",
    stamps: "Штампи",
    smsUnitsLow: "Низькі одиниці SMS",
    pleaseTopUpSmsUnits: "Поповніть SMS -одиниці",
    smsSuccessfullySentForDelivery: "SMS успішно відправлено для доставки!",
    sendSmsTo: "Надіслати SMS на",
    allCustomers: "Усі Клієнти",
    unitsAvailable: "Доступні одиниці",
    pleaseTypeInTheMessage: "Будь ласка, введіть повідомлення",
    message: "повідомлення",
    charactersRemaining: "символів, що залишилися",
    avoidUseOfSpecialCharacters:
      "Уникайте використання спеціальних символів та смайлів для збереження одиниць SMS.",
    note: "Примітка",
    errorFetchingCustomersMultilevelDetail:
      "Помилка отримання багаторівневої інформації клієнтів",
    search: "Пошук",
    reset: "Скинути",
    importCustomer: "Імпорт клієнта",
    addNewCustomer: "Додати нового клієнта",
    sendSmsBroadcast: "Надіслати SMS -трансляцію",
    totalCustomers: "ВСЬОГО КЛІЄНТІВ",
    disbursementDetails: "Деталі виплати",
    paidBy: "Оплачено",
    disbursed: "Виплачено",
    bankAccountName: "Назва банківського рахунку",
    bankAccountNumber: "Номер рахунку в банку",
    transferInitiated: "Початок передачі",
    transferCompleted: "Передача завершена",
    pleaseEnterAValid: "Будь ласка, введіть дійсний",
    begin: "почати",
    end: "кінець",
    date: "дата",
    paymentDate: "Дата оплати",
    selectDisbursementDuration: "Виберіть Тривалість виплати",
    totalSettled: "Всього розраховано",
    totalUnsettled: "Всього неврегульований",
    toDeleteThisSale: "видалити цей продаж",
    draftSaleDeletedSuccessfully: "Чернетку продажу успішно видалено!",
    deleteSale: "Видалити розпродаж",
    pleaseTypeInYourTotal:
      "Будь ласка, введіть загальну суму, щоб підтвердити видалення",
    billDetails: "Деталі рахунку",
    printBill: "Друк Білла",
    servedBy: "Обслуговується",
    total: "Всього",
    createdDate: "Дата створення",
    createdTime: "Створений час",
    status: "Статус",
    loginSuccessful: "Вхід успішний",
    pleaseWaitWhileWeConnectAccount:
      "Зачекайте, поки ми під’єднаємо ваш обліковий запис",
    connectionFailedTryAgain:
      "Підключення не вдалося. Будь ласка спробуйте ще раз.",
    connectionSuccessful: "Підключення успішне",
    viewDetails: "Докладніше",
    enable: "Увімкнути",
    free: "Безкоштовно",
    cost: "Вартість",
    visitWebsite: "Відвідайте веб -сайт",
    pleaseUpgradeYourPlanToPro:
      "Будь ласка, оновіть свій план до Pro Plus, щоб увімкнути цю програму",
    connectYourBankAccount:
      "Підключіть свій банківський рахунок, щоб мати можливість отримувати платежі.",
    disable: "Вимкнути",
    enableApp: "Увімкнути додаток",
    addNewProductToInvoice: "Додати новий товар до рахунку -фактури",
    toDeleteThisInvoice: "видалити цей рахунок -фактуру",
    invoiceDeletedSuccessfully: "Рахунок -фактуру успішно видалено!",
    deleteInvoice: "Видалити рахунок -фактуру",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ви успішно додали повідомлення про оплату рахунка -фактури!",
    pleaseEnterPaymentInstructions:
      "Будь ласка, введіть інструкції щодо оплати",
    invoiceId: "Ідентифікатор рахунка -фактури",
    paidDate: "Дата оплати",
    reference: "Довідка",
    productAdded: "Товар додано",
    productOutOfStock:
      "Товару немає на складі. Будь ласка, повторно складіть запас.",
    totalInvoices: "РАЗОМ ФАКТУРИ",
    totalPaidInvoices: "ВСЬОГО ОПЛАТЛЕНИХ РАЧУКІВ",
    totalUnpaidInvoices: "ВСЬОГО НЕОПЛАТНИХ РАЧУКІВ",
    loyaltyProgramDeleted: "Програму лояльності видалено",
    thisLoyaltyProgramDeletedSuccessfully:
      "Ця програма лояльності успішно видалена",
    thisLoyaltyProgramEditedSuccessfully:
      "Ця програма лояльності успішно відредагована",
    loyaltyProgramAddedSuccessfully: "Програму лояльності успішно додано",
    loyaltyProgramEdited: "Програму лояльності відредаговано",
    loyaltyProgramAdded: "Додано програму лояльності",
    loyaltyDetails: "Деталі лояльності",
    doYouWantToCloseDialog: "Закрити це діалогове вікно?",
    pleaseEnterLoyaltyName: "Будь ласка, введіть назву своєї лояльності",
    programType: "Тип програми",
    pleaseSelectProgramType: "Виберіть тип програми",
    simplePoints: "Прості очки",
    stampsProgram: "Програма штампів",
    threshold: "Поріг",
    pleaseEnterLoyaltyThreshold: "Будь ласка, введіть поріг лояльності",
    reward: "Нагорода",
    pleaseEnterLoyaltyReward: "Будь ласка, введіть винагороду за лояльність",
    totalUserPoints: "Загальна кількість очок користувача",
    totalUserStamps: "Загальна кількість штампів користувачів",
    spendingTarget: "Ціль витрат",
    spendingTargetHint1:
      "Ціль витрат - це сума, яку клієнт повинен витратити, щоб отримати винагороду. 1 вартість валюти = 1 бал.",
    spendingTargetHint2:
      "Цільові марки - це кількість марок, які клієнт повинен зібрати, щоб отримати винагороду. наприклад, 5",
    addNewLoyaltyProgram: "Додайте нову програму лояльності",
    addLoyaltyProgram: "Додайте програму лояльності",
    loyaltyProgramType: "Тип програми лояльності",
    pleaseSelectLoyaltyProgramType: "Виберіть тип програми лояльності",
    nameOfProgram: "Назва програми",
    pleaseEnterProgramName: "Будь ласка, введіть назву програми",
    whatIsTheReward: "Яка винагорода?",
    egNextPurchaseIsFree: "Наприклад, Наступна покупка безкоштовна",
    customerName: "Ім'я клієнта",
    guestCustomer: "Запрошений клієнт",
    orderConfirmedSuccessfully: "Замовлення успішно підтверджено",
    orderCancelledSuccessfully: "Замовлення успішно скасовано",
    confirmOrder: "Підтвердити замовлення",
    cancelOrder: "Відмінити замовлення",
    allOrders: "Усі замовлення",
    totalOrders: "ВСЬОГО ЗАМОВЛЕННЯ",
    doYouWantToAcceptOrder: "Ви хочете прийняти це замовлення?",
    doYouWantToCancelOrder: "Ви хочете скасувати це замовлення?",
    orderDetails: "деталі замовлення",
    orderCreatedAt: "Замовлення створено о",
    supplier: "Постачальник",
    productName: "Назва продукту",
    quantity: "Кількість",
    unitPrice: "Ціна за одиницю",
    receivedBy: "Отримав",
    reportFrom: "Звіт від",
    totalSupplies: "Загальний запас",
    allRightsReserved: "Всі права захищені",
    toDeleteThisTransaction: "видалити цю транзакцію",
    transactionDeletedSuccessfully:
      "Транзакцію успішно видалено. Запас повернено на склад.",
    deleteTransaction: "Видалити транзакцію",
    transactionDetails: "Деталі транзакції",
    printReceipt: "Друк квитанції",
    channel: "Канал",
    discount: "Знижка",
    profit: "Прибуток",
    discountedSales: "Продаж зі знижкою",
    errorFetchingRecord: "Помилка отримання запису",
    exportTransactions: "Експортні операції",
    errorFetchingSalesRecord:
      "Помилка отримання записів продажів для експорту.",
    totalSellingPrice: "Загальна ціна продажу",
    totalCostPrice: "Загальна ціна собівартості",
    appliedDiscount: "Застосовувана знижка",
    noOfItems: "Кількість елементів",
    sales: "Продаж",
    export: "Експорт",
    totalProfit: "Загальний прибуток",
    totalBalanceInPeriod: "Загальний баланс за період",
    allTimeSales: "Продаж за весь час",
    records: "Записи",
    todaysSales: "Сьогоднішні продажі",
    transaction: "транзакція",
    youHaveExceededTotalNumberOfProducts:
      "Ви перевищили загальну кількість продуктів, дозволених у вашому плані. Оновіть свій план, щоб користуватися більш високим лімітом.",
    youNeedToHaveLoyaltyProgram:
      "Щоб скористатися цією функцією, вам потрібна програма лояльності!",
    price: "Ціна",
    category: "Категорія",
    stockTracking: "Відстеження акцій",
    stockCount: "Підрахунок запасів",
    taxed: "Оподатковується",
    originalPrice: "Оригінальна ціна",
    costPrice: "Ціна",
    unit: "Одиниця",
    productImage: "Зображення продукту",
    taxRate: "Ставка податку",
    taxType: "Тип податку",
    trackInventory: "Інвентаризація треків",
    variants: "Варіанти",
    hasVariants: "Має варіанти",
    importProduct: "Імпорт товару",
    exportProducts: "Експорт продукції",
    addNewProduct: "Додати новий продукт",
    viewCategory: "Переглянути категорію",
    viewSuppliers: "Переглянути постачальників",
    receiveInventory: "Отримати інвентар",
    printAllProductsTag: "Роздрукувати теги всіх продуктів",
    deleteAll: "Видалити всі",
    totalProducts: "ВСЬОГО ТОВАРУ",
    youveSuccessfullyAddedANewCategory: "Ви успішно додали нову категорію",
    addNewCategory: "Додати нову категорію",
    addCategory: "Додати категорію",
    categoryName: "Назва категорії",
    pleaseEnterCategoryName: "Будь ласка, введіть назву категорії",
    stampsTarget: "Штампи цільові",
    sendInventory: "Надіслати інвентар",
    productDetails: "Деталі продукту",
    youveSuccessfullyEditedThisCategory:
      "Ви успішно відредагували цю категорію",
    update: "Оновлення",
    categoryList: "Список категорій",
    categories: "Категорії",
    enterQuantityToUpdate: "Введіть кількість для оновлення",
    inventorySentSuccessfully: "Інвентаризація успішно надіслана!",
    updateInventory: "Оновити інвентар",
    currentQuantity: "Поточна кількість",
    pleaseEnterQuantityToAdd:
      "Будь ласка, введіть кількість, яку потрібно додати",
    pleaseSelectABranch: "Виберіть філію",
    searchForBranch: "Шукайте відділення",
    youCantSendMoreThanStock:
      "Ви не можете надіслати більше, ніж є у вас на складі",
    send: "Надіслати",
    pleaseEnterQuantityToSend:
      "Будь ласка, введіть кількість, яку потрібно надіслати",
    productNameIsRequiredOnRow: "назва рядка обов’язкова в рядку",
    productCategoryIsRequiredOnRow: "категорія товару потрібна в рядку",
    productPriceIsRequiredOnRow: "ціна товару потрібна в рядку",
    productUnitIsRequiredOnRow: "одиниця продукту потрібна в рядку",
    productQuantityIsRequiredOnRow: "кількість товару потрібно в рядку",
    productVariantsRequireTracking: "Варіанти товару вимагають відстеження!",
    pleaseAddVariantClickButton:
      "Будь ласка, додайте варіант, натиснувши кнопку додавання!",
    totalVariantsMoreThanSelectedQuantity:
      "Загальна кількість варіантів більше, ніж обрана кількість товару, будь ласка, зменшіть кількість варіантів",
    productEditedSuccessfully: "Товар успішно відредагований!",
    fileTooLargeLessThan4Mb:
      "Завеликий розмір файлу! Розмір файлу повинен бути менше 4 МБ.",
    suchVariantAlreadyExist: "Такий варіант вже існує",
    addVariants: "Додати варіанти",
    editProduct: "Редагувати продукт",
    pleaseEnterProductName: "Будь ласка, введіть назву товару",
    pleaseEnterProductPrice: "Будь ласка, введіть ціну товару",
    pleaseEnterProductOriginalPrice:
      "Будь ласка, введіть початкову ціну товару",
    productDescription: "Опис продукту",
    selectProductCategory: "Виберіть категорію товару",
    pleaseSelectProductCategory: "Виберіть категорію товару",
    productCostPrice: "Вартість товару",
    productSellingPrice: "Ціна продажу товару",
    productOriginalPrice: "Оригінальна ціна товару",
    maxFileSizeAllowedIs4Mb: "Макс. Дозволений розмір файлу - 4 МБ",
    productsWithPicturesArePublished:
      "Вироби із зображеннями публікуються на Discover для отримання замовлень",
    shouldThisProductBeTracked: "Чи слід відстежувати цей продукт?",
    pleaseSelectStockUnit: "Виберіть одиницю запасу",
    stockUnit: "Запас одиниці",
    bag: "СУМКА",
    bottle: "ПЛАТКА",
    bunch: "ГРУП",
    can: "МОЖЕ",
    carton: "КАРТОН",
    crate: "ЯЩИК",
    cup: "CUP",
    dozen: "ДУЗЕН",
    gigabytes: "GIGABYTES",
    gram: "ГРАМ",
    kilogram: "КІЛОГРАМ",
    litre: "ЛІТАР",
    pack: "УПАКОВКА",
    pair: "ПАРА",
    pieces: "ШТ",
    plate: "ПЛИТА",
    tonne: "ТОННА (МТ)",
    uNIT: "UNIT",
    yard: "ДВОР",
    pleaseEnterProductQuantity: "Будь ласка, введіть кількість товару",
    productQuantity: "Кількість товару",
    isThisProductTaxed: "Чи оподатковується цей продукт?",
    selectTaxType: "Виберіть Тип податку",
    pleaseSelectTaxType: "Виберіть тип податку",
    progressive: "Прогресивний",
    proportional: "Пропорційно",
    pleaseEnterProductTaxRate: "Будь ласка, введіть ставку податку на продукт",
    doesProductHaveVariants: "Чи є у цього продукту варіанти?",
    type: "Тип",
    value: "Цінність",
    pleaseEnterVariantType: "Будь ласка, введіть тип варіанта",
    pleaseEnterVariantValue: "Будь ласка, введіть значення варіанта",
    pleaseEnterVariantPrice: "Будь ласка, введіть ціну варіанту",
    pleaseEnterVariantQuantity: "Будь ласка, введіть кількість варіанта",
    productDeletedSuccessfully: "Товар успішно видалено!",
    categoryDeletedSuccessfully: "Категорію успішно видалено!",
    toDeleteThisProduct: "видалити цей Продукт",
    invalidProductQuantity: "Недійсна кількість товару",
    quantityAddedIsOverStock:
      "Кількість, яку ви додаєте, перевищує те, що у вас є на складі.",
    itemInventoryNotTracked: "Інвентаризація товарів не відстежується",
    addBulkQuantity: "Додати масову кількість",
    enterBulkQuantity: "Введіть масову кількість",
    pleaseEnterBulkQuantity: "Будь ласка, введіть масовий обсяг",
    youveSuccessfullyAddedANewProduct: "Ви успішно додали новий продукт!",
    pleaseEnterProductSellingPrice: "Будь ласка, введіть ціну продажу товару",
    doYouWantToTrackProductStock: "Ви хочете відстежувати запаси продукції?",
    sellingPrice: "Відпускна ціна",
    setProductExpiryReminder: "Встановіть нагадування про термін дії товару",
    productExpiryDate: "Термін придатності продукту",
    startRemindingFrom: "Почати нагадування з",
    productSuppliesAddedSuccessfully: "Ви успішно додали запаси продуктів.",
    addProductSupplies: "Додайте витратні матеріали",
    pleaseSelectSupplier: "Виберіть постачальника",
    nameOfProduct: "Найменування товару",
    pleaseSelectProduct: "Виберіть продукт",
    productVariant: "Варіант продукту",
    pleaseSelectAVariant: "Виберіть варіант",
    pleaseEnterUnitPrice: "Будь ласка, введіть ціну за одиницю",
    businessBranch: "Бізнес -відділення",
    pleaseSelectBranch: "Виберіть філію",
    youveSuccessfullyAddedANewSupplier:
      "Ви успішно додали нового постачальника",
    addSupplier: "Додати постачальника",
    pleaseEnterSupplierEmail:
      "Будь ласка, введіть електронну адресу постачальника",
    pleaseAddADescription: "Будь ласка, додайте опис",
    anErrorOccuredProductsDeleted:
      "Під час виконання операції сталася помилка. Зверніть увагу, що деякі продукти могли бути видалені в процесі",
    bulkDelete: "Масове видалення",
    youAreAboutToDelete: "Ви збираєтесь видалити",
    product: "Продукт",
    isDueToRedeem: "підлягає погашенню",
    aReward: "винагороду",
    pleaseSelectCustomerToReeemReward:
      "Виберіть клієнта, щоб скористатися винагородою.",
    youHaveNoLoyaltyProgramRunning: "У вас немає активної програми лояльності",
    customerHhasNoPointsInLoyaltyProgram:
      "Клієнт не має балів у цій програмі лояльності",
    proceedWithPayment: "Продовжити оплату?",
    youAreAboutToPayForTransactionUsingPoints:
      "Ви збираєтесь оплатити транзакцію за допомогою балів.",
    customerHas: "Клієнт має",
    worth: "варто",
    andIsNotSufficientToPayForTransaction:
      "і не є достатнім для оплати цієї операції. Чи хотіли б вони додати баланс за допомогою іншого способу оплати?",
    addCustomerLoyalty: "Додайте лояльність клієнтів",
    pleaseAddCustomerFirst:
      "Будь ласка, спочатку додайте або виберіть клієнта.",
    pleaseWaitWhileWeGetReady: "Зачекайте, поки ми підготуємось",
    lowStock: "Низький запас",
    pleaseEnterAmountTendered: "Будь ласка, введіть запропоновану суму",
    areYouSureToBookSaleOffline:
      "Ви впевнені, що хочете забронювати цю розпродаж офлайн?",
    saleWouldBeBookedAutomatically:
      "Продаж буде реєструватися автоматично, коли ви вмикаєте Інтернет",
    offlineSalesBookingCancelled: "Бронювання офлайн -продажів скасовано",
    covid19Message:
      "COVID19: Мийте руки з милом або дезінфікуйте, щоб зупинити поширення. Захищайтесь завжди",
    saleSuccessfullyRecorded: "Продаж успішно записано!",
    sendReceiptToEmail: "Надсилайте квитанцію на електронну пошту",
    sendThankYouSms: "Надіслати подячне SMS",
    sendShippingDetails: "Надсилайте деталі доставки",
    addLoyalty: "Додайте лояльності",
    searchCustomerNameOrNumber: "Знайдіть ім’я або номер клієнта",
    clickTheSearchCustomerBox:
      "Натисніть на поле пошуку клієнта та картку сканування",
    enterProductPrice: "Введіть ціну товару",
    enterPriceFor: "Введіть ціну для",
    searchForProduct: "Пошук товару",
    all: "Усі",
    backToDashboard: "Повернутися до інформаційної панелі",
    viewDraftSales: "Переглянути проект продажу",
    variantSelected: "обраний варіант",
    variant: "варіант",
    thePreviousVariantSelectionNotAvailable:
      "Попередній вибір варіанту недоступний для нового варіанту, вибраного на основі ціни, будь ласка, змініть свій вибір.",
    pleaseSupplyPositiveQuantityNumber: "Надайте позитивний номер кількості",
    lowStockFor: "Низький запас для",
    clearVariants: "Чіткі варіанти",
    pleaseEnterQuantity: "Будь ласка, введіть кількість",
    picture: "Картина",
    redemptionToken: "Жетон погашення",
    token: "Токен",
    totalSpent: "Загальна витрата",
    confirmPayment: "Підтвердіть оплату",
    hasPaymentBeenMade: "Чи платіж успішно оброблено?",
    enterTransactionReference:
      "Введіть посилання на транзакцію, за допомогою якої ви здійснили оплату",
    pleaseSelectACustomer: "Виберіть клієнта!",
    areYouSureToApplyDiscount: "Ви впевнені, що хочете застосувати знижку?",
    addYourBankAccountToEnableUssd:
      "Додайте свій банківський рахунок, щоб увімкнути миттєвий переказ USSD через uPay",
    totalAmountToPay: "Загальна сума для сплати",
    doYouWantToCancelTransaction: "Ви хочете скасувати цю транзакцію?",
    savePrintBill: "Зберегти/надрукувати рахунок",
    enterAmountCollectedForCustomer: "Введіть суму, зібрану для клієнта",
    recordSale: "Рекордний продаж",
    checkOutWith: "Перевірте з",
    instantTransfer: "Миттєвий переказ",
    dialTheUSSDCode: "Наберіть код USSD",
    pleaseSelectABank: "Виберіть банк",
    payWithUSSD: "Оплата через USSD",
    sendBillTo: " - Надішліть Білла на",
    waitingForUSSDTransfer: "Очікування передачі USSD",
    percent: "Відсоток",
    applyDiscount: "Застосувати знижку",
    thisBillHasBeenSaved: "Цей законопроект збережено",
    saveDraft: "Зберегти чернетку",
    pleaseTypeANameToIdentifyCustomer:
      "Будь ласка, введіть ім'я, щоб ідентифікувати клієнта",
    paymentDetails: "Платіжні реквізити",
    basePrice: "Базова ціна",
    staff: "Персонал",
    subTotal: "Проміжна сума",
    durationMonths: "Тривалість (місяці)",
    selectADuration: "Виберіть тривалість",
    oneMonth: "1 місяць",
    twoMonths: "2 місяці",
    threeMonths: "3 місяці",
    sixMonths: "6 місяців",
    twelveMonths: "12 місяців",
    eighteenMonths: "18 місяців",
    twentyFourMonths: "24 місяці",
    twoMonthsFree: "(2 місяці безкоштовно)",
    threeMonthsFree: "(3 місяці безкоштовно)",
    fiveMonthsFree: "(5 місяців безкоштовно)",
    yourAccountHasBeen: "Ваш обліковий запис був",
    renewed: "оновлено",
    upgraded: "оновлений",
    successfully: "успішно",
    yourSubscription: "Ваша підписка",
    youAreCurrentlyEnrolledOnA: "Наразі ви зареєстровані на",
    pleaseChooseAPaymentOption: "Виберіть спосіб оплати",
    planRenewal: "План оновлення",
    planUpgrade: "Плануйте оновлення",
    pleaseSelectDurationToPayFor:
      "Будь ласка, оберіть Тривалість, за яку ви маєте намір платити",
    staffAccounts: "Рахунки персоналу",
    ecommerce: "Електронна комерція",
    pleaseSelectAPlan: "Виберіть план",
    includeAddons: "Включіть доповнення",
    viewTransactions: "Переглянути транзакції",
    customerHasNoCompletedTansactions: "Клієнт ще не завершив розмов",
    branch: "Відділення",
    enterNumberOfEcommerceBranches:
      "Введіть кількість відділень електронної комерції",
    enterNumberOfEcommerceBranchesToPay:
      "Введіть кількість відділень електронної комерції, за які ви маєте намір платити",
    ecommerceIntegration: "Інтеграція електронної комерції",
    enterNumberOfBranches: "Введіть кількість гілок",
    enterNumberOfAdditionalBranchesToPay:
      "Введіть кількість додаткових відділень, за які ви маєте намір платити",
    enterNumberOfStaffs: "Введіть кількість персоналу",
    enterNumberOfStaffsToPayFor:
      "Введіть кількість персоналу, за який ви маєте намір платити",
    discountApplies: "Діє знижка",
    starsOnThe: "зірки на",
    offer: "пропозиція",
    get: "Отримати",
    moreStarsToRedeem: "більше зірок, щоб викупити",
    taxVat: "Податок (ПДВ)",
    callCashierToCompletePayment: "Зателефонуйте в касу, щоб завершити оплату",
    receipt: "Квитанція",
    dear: "Шановний",
    thankYouForYourOrderFindGoods:
      "Дякуємо за ваше замовлення. Будь ласка, знайдіть наступні товари, що поставляються, за домовленістю.",
    shippingNote: "Примітка про доставку",
    enterShippingNote: "Введіть примітку про доставку",
    shippingAddress: "Адреса доставки",
    enterShippingAddress: "Введіть адресу доставки",
    wellDoneYouAreDueToRedeem: "Молодець! Ви маєте викупити",
    toGetYourRewardNextVisit:
      "щоб отримати винагороду під час наступного відвідування. Дякую",
    pointsOnThe: "Точки на",
    morePointsToRedeem: "більше балів, щоб викупити",
    showCode: "Показати код",
    toGetYourRewardOnNextVisit:
      "щоб отримати винагороду під час наступного відвідування. Дякую",
    earn: "Заробіть",
    delivaryNote: "Примітка про доставку",
    draftSales: "Проект продажу",
    startDate: "Дата початку",
    endDate: "Дата закінчення",
    orders: "Замовлення",
    checkout: "перевіряти",
    noProductItem: "Без товару",
    selectProductImage: "Виберіть зображення продукту",
    selectCountry: "Виберіть країну",
    selectRegion: "Виберіть штат/регіон",
    printProductTag: "Друк тегів продукту",
    transactionReference: "Посилання на транзакцію",
    Cashier: "Касир",
    Manager: "Менеджер",
    Owner: "Власник",
    Admin: "Адмін",
    addPartners: "Додати партнерів",
    addNewLoyaltyPartner: "Додати нового партнера лояльності",
    pleaseEnterCompanyName: "Будь ласка, введіть назву компанії",
    companyName: "Назва компанії",
    pleaseEnterCompanyRepName: "Будь ласка, введіть ім'я представника компанії",
    companyRepName: "Ім'я представника компанії",
    pleaseEnterCompanyRepEmail:
      "Будь ласка, введіть електронну адресу представника компанії",
    companyRepEmail: "Електронна адреса представника компанії",
    pleaseEnterCompanyRepPhone: "Введіть номер телефону представника компанії",
    companyRepPhone: "Номер телефону представника компанії",
    addReward: "Додайте винагороду",
    pleaseEnterRewardName: "Будь ласка, введіть назву винагороди",
    rewardName: "Назва винагороди",
    rewardQuantity: "Кількість винагороди",
    rewardDescription: "Опис винагороди",
    rewardType: "Тип винагороди",
    pleaseEnterRewardType: "Будь ласка, введіть тип винагороди",
    pleaseEnterRewardQuantity: "Будь ласка, введіть кількість винагороди",
    pleaseEnterRewardDescription: "Будь ласка, введіть опис винагороди",
    fineDining: "Вишукані вечері",
    luxuryFashion: "Розкішна мода",
    hotels: "Готелі",
    travel: "Подорожі",
    foodAndBeverage: "Продуктів харчування і напоїв",
    fashion: "Мода",
    health: "Здоров'я",
    furniture: "Меблі",
    entertainment: "Розваги",
    automobile: "Автомобільний",
    education: "Освіта",
    beautyAndSpa: "Краса і спа",
    staycation: "Перебування",
    events: "Події",
    trips: "Поїздки",
    oilAndGas: "Нафта і газ",
    laundry: "Пральня",
    partnerCategory: "Категорія партнера",
    freeItem: "Безкоштовний предмет",
  },
  Urdu: {
    cashier: "کیشئر",
    manager: "مینیجر",
    owner: "مالک",
    online: "آن لائن",
    offline: "آف لائن",
    changePassword: "پاس ورڈ تبدیل کریں",
    currentPasswordMessage: "براہ کرم اپنا موجودہ پاس ورڈ درج کریں۔",
    passwordMessage: "برائے مہربانی اپنا پاس ورڈ داخل کریں",
    currentPassword: "موجودہ خفیہ لفظ",
    password: "پاس ورڈ",
    confirmPasswordMessage: "براہ کرم اپنے پاس ورڈ کی تصدیق کریں!",
    confirmPassword: "پاس ورڈ کی تصدیق کریں۔",
    sendViaEmail: "ای میل کے ذریعے بھیجیں۔",
    sendViaWhatsapp: "واٹس ایپ کے ذریعے بھیجیں۔",
    downloadPdf: "پی ڈی ایف ڈاؤن لوڈ کریں",
    paid: "ادا کیا",
    unpaid: "بلا معاوضہ",
    partial: "جزوی",
    closeInvoice: "کیا آپ انوائس بند کرنا چاہتے ہیں؟",
    closeInvoiceConfirmation:
      "انوائس کو محفوظ نہیں کیا جا سکتا۔ کیا آپ بند کرنا چاہتے ہیں؟",
    yes: "جی ہاں",
    no: "نہیں",
    invoice: "رسید",
    wasDeducted: "کٹوتی کی گئی تھی",
    for: "کے لیے",
    item: "آئٹم",
    addProduct: "پروڈکٹ شامل کریں۔",
    paymentReference: "ادائیگی کا حوالہ",
    amountPaid: "رقم ادا کر دی",
    discountAmount: "ڈسکاؤنٹ کی رقم",
    amountDue: "واجب الادا رقم",
    amount: "رقم",
    dueDate: "واجب الادا تاریخ",
    paymentType: "ادائیگی کی قسم",
    card: "کارڈ۔",
    cash: "نقد",
    bankTransfer: "بینک ٹرانسفر۔",
    paymentMessage: "ادائیگی کا پیغام۔",
    description: "تفصیل",
    sendReceipt: "رسید بھیجیں۔",
    delete: "حذف کریں۔",
    save: "محفوظ کریں",
    resend: "دوبارہ بھیجیں۔",
    saveAndSend: "محفوظ کریں",
    invoiceSaved: "انوائس محفوظ!",
    selectPaymentMethod: "براہ کرم ادائیگی کا طریقہ منتخب کریں!",
    selectCustomer: "براہ کرم ایک کسٹمر منتخب کریں!",
    cartEmptyError:
      "ٹوکری کی فہرست خالی نہیں ہوسکتی ، انوائس بند کریں اور آئٹم کو ٹوکری میں شامل کریں!",
    subscriptionExpired:
      "آپ کی رکنیت ختم ہو چکی ہے اور آپ کا اکاؤنٹ اب محدود ہے۔ اپنے اکاؤنٹ کی تجدید کے لیے نیچے دیئے گئے بٹن پر کلک کریں۔",
    renew: "تجدید کریں۔",
    holdOn: "رکو",
    customerBank: "کسٹمر بینک۔",
    cancel: "منسوخ کریں",
    selectACustomer: "کسٹمر منتخب کریں۔",
    invoiceSuccessfulPdfError:
      "انوائس کامیابی سے بنائی گئی لیکن پی ڈی ایف جنریشن معمول سے زیادہ وقت لے رہی ہے۔ براہ کرم جلد ہی دوبارہ چیک کریں۔",
    downloadingInvoice: "انوائس ڈاؤن لوڈ ہو رہی ہے۔",
    downloadingReceipt: "رسید ڈاؤن لوڈ ہو رہی ہے۔",
    whatsappReceiptError:
      "واٹس ایپ کے ذریعے رسید بھیجنے میں ایک خرابی پیش آئی ، براہ کرم دوبارہ کوشش کریں۔",
    receiptToWhatsapp: "رسید واٹس ایپ پر بھیج دی گئی۔",
    thankYouForPatronage: "آپ کی سرپرستی کا شکریہ۔",
    hereIsYourReceipt: "یہ ہے آپ کی ادائیگی کی رسید۔",
    errorSendingEmailReceipt:
      "ای میل کے ذریعے رسید بھیجنے میں ایک خرابی پیش آئی ، براہ کرم دوبارہ کوشش کریں یا سپورٹ سے رابطہ کریں۔",
    receiptSentToEmail: "رسید کسٹمر کے ای میل پر بھیج دی گئی ہے۔",
    invoiceSentToEmail: "کسٹمر کے ای میل پر انوائس بھیج دیا گیا ہے۔",
    invoiceSuccessWhatsappError:
      "انوائس کامیابی کے ساتھ بنائی گئی لیکن واٹس ایپ کو بھیجنے میں ایک خرابی پیش آگئی۔ انوائس لسٹ پر دوبارہ کوشش کریں۔",
    invoiceSuccessfulEmailError:
      "انوائس کامیابی کے ساتھ بنائی گئی لیکن بطور ای میل بھیجتے ہوئے ایک خرابی پیش آگئی۔ براہ کرم رسید کی فہرست سے دوبارہ کوشش کریں۔",
    invoiceSentToWhatsapp: "انوائس واٹس ایپ پر بھیج دی گئی۔",
    hello: "ہیلو",
    pleaseDownloadInvoice: "براہ کرم انوائس ڈاؤن لوڈ کریں۔",
    pleaseDownloadReceipt: "براہ کرم رسید ڈاؤن لوڈ کریں۔",
    from: "سے",
    email: "ای میل۔",
    upgrade: "اپ گریڈ",
    youAreOnFreePlan: "آپ ایک مفت منصوبہ پر ہیں۔",
    clickOn: "پر کلک کریں",
    yourPlanInFewSteps: " چند فوری مراحل میں آپ کی رکنیت۔",
    to: "کو",
    yourSubscriptionHasExpired:
      "آپ کی رکنیت ختم ہو چکی ہے اور آپ کا اکاؤنٹ اب محدود ہے۔",
    days: "دن",
    yourSubscriptionExpiresIn: "آپ کا لوئ سٹار سبسکرپشن ختم ہو رہا ہے۔",
    createAcount: "کھاتا کھولیں",
    signIn: "سائن ان",
    continue: "جاری رہے",
    enterOtp: "OTP پن درج کریں۔",
    enterValidOtp: "براہ کرم ایک درست PIN درج کریں۔",
    pin: "PIN",
    tokenSentToMail: "آپ کے ای میل پر ایک ٹوکن بھیجا گیا ہے۔",
    passwordResetSuccessful: "پاس ورڈ ری سیٹ کامیاب رہا۔",
    somethingWentWrong: "کچھ غلط ہو گیا!",
    resetPassword: "پاس ورڈ ری سیٹ",
    iHaveResetCode: "میرے پاس پاس ورڈ ری سیٹ کوڈ ہے۔",
    pleaseEnterEmail: "براہ کرم اپنا ای میل درج کریں۔",
    aTokenWillBeSentToEmail: "آپ کے ای میل پر ایک ٹوکن بھیجا جائے گا۔",
    enterEmail: "اپنا ای میل درج کریں۔",
    sendinYourToken: "اپنا ٹوکن بھیج رہا ہے ...",
    makeSureItMatchesPassword:
      "یقینی بنائیں کہ یہ آپ کے نئے پاس ورڈ سے مماثل ہے۔",
    pleaseChooseNewPassword: "براہ کرم ایک نیا پاس ورڈ منتخب کریں۔",
    chooseNewPassword: "نیا پاس ورڈ منتخب کریں۔",
    enterNewPassword: "تصدیق کے لیے اپنا نیا پاس ورڈ درج کریں۔",
    enterTokenSent: "وہ ٹوکن درج کریں جو آپ کا میل بھیجا گیا تھا۔",
    resetToken: "ٹوکن کو دوبارہ ترتیب دیں۔",
    resettingPassword: "اپنا پاس ورڈ دوبارہ ترتیب دیا جا رہا ہے ...",
    signUp: "سائن اپ",
    adminSignInWithEmail:
      " ایڈمن ای میل کے ساتھ سائن ان کرتا ہے جبکہ اسٹاف صارف نام کے ساتھ سائن ان کرتا ہے۔",
    pleaseEnterEmailOrUsername: "براہ کرم اپنا ای میل یا صارف نام درج کریں۔",
    emailOrUsername: "ای میل یا اسم صارف",
    pleaseEnterPassword: "براہ کرم پاس ورڈ درج کریں۔",
    createAnAccount: "کھاتا کھولیں",
    forgotPassword: "پاسورڈ بھول گے؟",
    enterPhoneNumber: "فون نمبر درج کریں۔",
    personalData: "ذاتی مواد",
    validateConfirmationCOde: "تصدیقی کوڈ کی توثیق کریں۔",
    pleaseEnterFirstName: "براہ کرم اپنا پہلا نام درج کریں۔",
    pleaseEnterPhoneNumber: "براہ کرم اپنا فون نمبر درج کریں۔",
    pleaseEnterLastName: "براہ کرم اپنا آخری نام درج کریں۔",
    pleaseEnterBusinessName: "براہ کرم اپنے کاروبار کا نام درج کریں۔",
    firstName: "پہلا نام",
    lastName: "آخری نام",
    businessName: "کاروبار کا نام",
    previous: "پچھلا",
    next: "اگلے",
    pleaseSelectBusinessCategory: "براہ کرم اپنی کاروباری زمرہ منتخب کریں۔",
    pleaseEnterValidEmail: "ایک درست ای میل درج کریں",
    pleaseEnterPostCode: "براہ کرم پوسٹ کوڈ درج کریں۔",
    postCode: "پوسٹ کوڈ۔",
    phoneNumberInUse: "یہ فون نمبر پہلے ہی استعمال میں ہے!",
    emailInUse: "یہ ای میل پہلے ہی استعمال میں ہے!",
    foodAndDrinks: "کھانا اور مشروبات",
    salonAndBeauty: "سیلون اور خوبصورتی۔",
    fashionAndAccessories: "فیشن اور لوازمات۔",
    gymAndFitness: "جم اور فٹنس۔",
    travelAndHotel: "سفر اور ہوٹل۔",
    homeAndGifts: "گھر اور تحائف",
    washingAndCleaning: "دھلائی اور صفائی۔",
    gadgetsAndElectronics: "گیجٹ اور الیکٹرانکس۔",
    groceries: "گروسری",
    others: "دوسرے",
    submit: "جمع کرائیں",
    accountCreatedSuccessful: "آپ کا اکاؤنٹ کامیابی سے بن گیا۔",
    pleaseEnterAddress: "براہ کرم اپنا پتہ درج کریں۔",
    addressLine1: "پتہ لائن 1",
    addressLine2: "پتہ لائن 2",
    choosePassword: "خفیہ کوڈ کا انتخاب کیجیئے",
    passwordMustBe6Characters: "پاس ورڈ کم از کم 6 حروف کا ہونا چاہیے۔",
    howDidYouHearLoystar: "آپ نے Loystar کے بارے میں کیسے سنا؟",
    referralCode: "ریفرل کوڈ",
    byClickingTheButton:
      " نیچے دیئے گئے بٹن پر کلک کر کے ، آپ اس سے اتفاق کرتے ہیں۔",
    termsAndSevice: "شرائط",
    wereCreatingAccount: "ہم آپ کا اکاؤنٹ بنا رہے ہیں۔",
    proceed: "آگے بڑھو",
    verificationCodeMustBe6: "تصدیق کا کوڈ 6 ہندسوں کا ہونا چاہیے۔",
    pleaseEnter6DigitCode: "براہ کرم 6 ہندسوں کا کوڈ درج کریں۔",
    enterVerificationCode: "تصدیقی کوڈ درج کریں",
    resendToken: "ٹوکن دوبارہ بھیجیں۔",
    verify: "تصدیق کریں",
    transactions: "لین دین",
    todaySales: "آج کی فروخت۔",
    salesHistory: "فروخت کی تاریخ",
    supplyHistory: "سپلائی کی تاریخ",
    new: "نئی",
    invoices: "رسیدیں۔",
    disbursements: "ادائیگی",
    offlineSales: "آف لائن سیلز۔",
    products: "مصنوعات",
    customers: "گاہکوں",
    multiLevelLoyalty: "کثیر سطحی وفاداری۔",
    loyaltyPrograms: "وفاداری کے پروگرام۔",
    members: "ممبران۔",
    appStore: "اپلی کیشن سٹور",
    orderMenu: "آرڈر مینو۔",
    settings: "ترتیبات",
    staffAndBranches: "عملہ اور شاخیں۔",
    myAccount: "میرا اکاونٹ",
    switchToSellMode: "سیل موڈ پر سوئچ کریں۔",
    signOut: "باہر جائیں",
    getFreeSubscription: "مفت سبسکرپشن حاصل کریں۔",
    onlyNumbersAllowed: "صرف نمبروں کی اجازت ہے۔",
    yourAccountMustBe10Digits: "آپ کا اکاؤنٹ نمبر 10 ہندسوں کا ہونا چاہیے۔",
    yourBvnMustBe11: "آپ کا BVN 11 ہندسوں کا ہونا چاہیے۔",
    pleaseSelectBank: "براہ کرم اپنا بینک منتخب کریں۔",
    selectYourBank: "اپنا بینک منتخب کریں۔",
    enterBankAccountNumber: "بینک اکاؤنٹ نمبر درج کریں۔",
    enterBvnNumber: "اپنا BVN درج کریں۔",
    connectBankAccount: "بینک اکاؤنٹ کو جوڑیں۔",
    passwordResetSuccessfulAndSignOut:
      "آپ کا پاس ورڈ کامیابی سے ری سیٹ ہو گیا ہے۔ دوبارہ سائن ان کرنے کے لیے نیچے سائن آؤٹ بٹن پر کلک کریں۔",
    enterCurrentPassword: "موجودہ پاسورڈ درج کریں",
    pleaseEnterCurrentPassword: "براہ کرم موجودہ پاس ورڈ درج کریں۔",
    phoneNumber: "فون نمبر",
    sex: "سیکس۔",
    dateOfBirth: "پیدائش کی تاریخ",
    state: "حالت",
    country: "ملک",
    loyaltyId: "وفاداری ID۔",
    createdAt: "پر بنایا گیا۔",
    noOfTransactionsMade: "کئے گئے لین دین کی تعداد",
    yourDownloadWillStart: "آپ کا ڈاؤن لوڈ ایک لمحے میں شروع ہو جائے گا۔",
    exportCustomers: "صارفین کو برآمد کریں۔",
    youhaveSuccessfullyToppedUp:
      "آپ نے کامیابی کے ساتھ اپنے ایس ایم ایس یونٹس کو ٹاپ کیا ہے۔",
    youNowHave: "اب آپ کے پاس ہے۔",
    smsUnits: "ایس ایم ایس یونٹس",
    enterNumberOfSmsUnits:
      "ایس ایم ایس یونٹس کی تعداد درج کریں جو آپ خریدنا چاہتے ہیں۔",
    pleaseEnterANumericValue: "براہ کرم ایک عددی قدر درج کریں۔",
    pay: "ادا کریں۔",
    accountEditedSuccessfully: "آپ کے اکاؤنٹ میں کامیابی سے ترمیم کی گئی۔",
    youAeCurrentlyOn: "آپ فی الحال آن ہیں۔",
    plan: "منصوبہ",
    userData: "صارف کا ڈیٹا۔",
    businessData: "کاروبار کا ڈیٹا۔",
    businessCategory: "بزنس کیٹرگوری۔",
    pleaseSelectCurrency: "براہ کرم اپنی کرنسی منتخب کریں۔",
    selectYourCurrency: "اپنی کرنسی منتخب کریں۔",
    purchaseMoreSmsUnits:
      "نیچے دیئے گئے فارم کا استعمال کرتے ہوئے مزید ایس ایم ایس یونٹس خریدیں۔",
    youHave: "آپ کے پاس ہے",
    atLeast4SmsUnitsRrequired:
      "تصدیق کے لیے کم از کم 4 ایس ایم ایس یونٹ درکار ہیں ، براہ کرم ٹاپ اپ کریں!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "براہ کرم 'اپنے کسٹمر کو جانیں' (KYC) کی ضروریات کے مطابق اپنے بینک اکاؤنٹ کی تصدیق کریں۔ یہ آپ کو یو ایس ایس ڈی یا فوری منتقلی کے ذریعے ادائیگی جمع کرنے ، گاہکوں سے آرڈر وصول کرنے اور لین دین کے تصفیے کی اجازت دے گا۔ صرف نائجیریا کے تاجروں کے لیے قابل اطلاق۔ براہ کرم شروع کرنے کے لیے نیچے دیا گیا بٹن دبائیں۔",
    reConnectBankAccount: "بینک اکاؤنٹ کو دوبارہ جوڑیں۔",
    accountName: "کھاتے کا نام",
    accountNumber: "اکاؤنٹ نمبر۔",
    bankName: "بینک کا نام",
    verified: "تصدیق شدہ",
    accountDetails: "اکاؤنٹ کی تفصیلات",
    kycBankAccount: "کے وائی سی",
    createTier: "ٹائر بنائیں۔",
    fileUploadSuccessful: "فائل کامیابی سے اپ لوڈ ہو گئی",
    fileUploadFailed: "فائل اپ لوڈ ناکام",
    createLoyaltyProgram: "ایک وفاداری پروگرام بنائیں۔",
    createLoyalty: "وفاداری پیدا کریں۔",
    name: "نام۔",
    loyaltyArtwork: "وفاداری آرٹ ورک۔",
    clickToUpload: "اپ لوڈ کرنے کے لیے کلک کریں۔",
    amountToPointsRatio: "پوائنٹس کا تناسب",
    points: "پوائنٹس۔",
    recommendedAmountToPointRatio:
      "تجویز کردہ: ₦ 1 سے 1 پوائنٹ۔ یعنی ہر 1 نائرا خرچ کرنے پر ، آپ کے صارفین کو 1 پوائنٹ ملتا ہے۔",
    pleaseTypeIn: "براہ کرم ٹائپ کریں۔",
    toDeleteLoyalty: "اس وفاداری کو ختم کرنے کے لیے",
    deleteLoyalty: "وفاداری حذف کریں۔",
    toConfirm: "تصدیق کے لئے",
    edit: "ترمیم",
    pointsAwardedSuccessfully: "پوائنٹس کامیابی سے نوازے گئے۔",
    enterPointValueToAward:
      "پوائنٹ ویلیو درج کریں جو آپ صارفین کو دینا چاہتے ہیں۔",
    award: "ایوارڈ",
    awardPointValuesToCustomer: "کسٹمر کے لیے ایوارڈ پوائنٹ ویلیو۔",
    enrollMembersToLoyalty: "وفاداری میں ممبروں کا اندراج کریں۔",
    awardPoints: "ایوارڈ پوائنٹس۔",
    enroll: "اندراج کریں",
    home: "گھر",
    loyalty: "وفاداری۔",
    enrollCustomers: "صارفین کا اندراج کریں۔",
    selected: "منتخب شدہ",
    customer: "صارف",
    loyaltyActivationSuccessful: "وفاداری ایکٹیویشن کامیاب ہے۔",
    loyaltyDeactivationSuccessful: "وفاداری کو غیر فعال کرنا کامیاب ہے۔",
    viewTier: "ٹیئر دیکھیں۔",
    deactivate: "ڈی ایکٹیویٹ کریں۔",
    activate: "محرک کریں",
    actions: "اعمال",
    nameOfLoyalty: "وفاداری کا نام۔",
    loyaltyStatus: "وفاداری کی حیثیت وفاداری کی حیثیت",
    numberOfTiers: "درجات کی تعداد",
    createdOn: "پر بنایا",
    createATier: "ایک ٹائر بنائیں۔",
    stopCreatingTierConfirmation: "کیا آپ ٹائر بنانا بند کرنا چاہتے ہیں؟",
    stopEditingTierConfirmation: "کیا آپ اس درجے میں ترمیم روکنا چاہتے ہیں؟",
    nameOfTier: "درجے کا نام۔",
    minimumSpendingTarget: "کم سے کم خرچ کرنے کا ہدف۔",
    maximumSpendingTarget: "زیادہ سے زیادہ خرچ کرنے کا ہدف۔",
    minimumSpendingTargetRequired: "کم سے کم اخراجات کا ہدف ضروری ہے۔",
    maximumSpendingTargetRequired: "زیادہ سے زیادہ اخراجات کا ہدف درکار ہے۔",
    rewardSponsor: "انعام کفیل۔",
    pleaseChooseARewardSponsor: "برائے مہربانی ایک انعام سپانسر منتخب کریں۔",
    self: "خود",
    partner: "ساتھی۔",
    rewardPartner: "انعام کا ساتھی۔",
    pleaseSelectRewardPartner: "براہ کرم اپنے انعام کا ساتھی منتخب کریں۔",
    rewards: "انعامات۔",
    pleaseSelectAReward: "براہ کرم ایک انعام منتخب کریں۔",
    instructionsOnRedeemingReward:
      "کسٹمر کو انعام سے کیسے چھٹکارا حاصل کرنا چاہیے اس بارے میں ہدایات۔",
    pleaseFillInThisField: "براہ کرم اس فیلڈ کو پُر کریں!",
    toDeleteThisTier: " اس درجے کو حذف کرنے کے لیے۔",
    deleteTier: "درجے کو حذف کریں۔",
    viewMembers: "ممبرز دیکھیں۔",
    membersEnrolled: "ممبران کا اندراج",
    instruction: "ہدایات",
    membersIn: "ممبران میں",
    availableTiersInLoyalty: "وفاداری پروگرام میں درجے دستیاب ہیں۔",
    tiers: "درجے",
    totalTier: "کل ٹائر",
    availableLoyaltyProgramme: "دستیاب وفاداری پروگرام۔",
    totalLoyalties: "کل وفاداریاں",
    memberDetails: "ممبر کی تفصیلات",
    nameOfCustomer: "کسٹمر کا نام۔",
    address: "پتہ۔",
    allEnrolledMembers: "تمام اندراج شدہ ممبران۔",
    thisIsToWishYouHappyBirthday:
      "یہ آپ کو سالگرہ اور خوشحال زندگی کی مبارکباد دینا ہے۔ ان تمام چیزوں کے لیے جن کا آپ Loyster ہیں شکریہ۔ خوشیاں منائیں!",
    inputAnOfferPlease: "براہ کرم ایک آفر داخل کریں۔",
    pleaseSelectTheInsertPoint:
      "براہ کرم پیغام میں داخل نقطہ منتخب کریں اور دوبارہ کلک کریں۔",
    birthdayOfferAndMessage: "سالگرہ کی پیشکش اور پیغام۔",
    birthdayOffer: "سالگرہ کی پیشکش۔",
    birthdayMessage: "سالگرہ کا پیغام۔",
    noOfferFound: "کوئی پیشکش نہیں ملی۔",
    settingABirthdayOffer:
      "سالگرہ کی پیشکش کا تعین گاہکوں کو یہ پیشکش ایس ایم ایس کے ذریعے اپنی سالگرہ کے موقع پر وصول کرنے کی اجازت دیتا ہے۔",
    createOffer: "پیشکش بنائیں۔",
    whatIsTheOffer: "پیشکش کیا ہے؟",
    editMessage: "پیغام میں ترمیم کریں۔",
    insert: "داخل کریں",
    theNameOfCustomerInserted: "کسٹمر کا نام یہاں ڈالا جائے گا۔",
    theBirtdayOfferInserted: "سالگرہ کی پیشکش یہاں داخل کی جائے گی۔",
    youSuccessfullyAddedNewBranch:
      "آپ نے کامیابی کے ساتھ ایک نئی برانچ شامل کی ہے!",
    addNewBranch: "نئی برانچ شامل کریں۔",
    addBranch: "برانچ شامل کریں۔",
    additionalBranchWillIncur: "ایڈیشنل برانچ لگائے گی۔",
    perBranch: "فی شاخ",
    ecommerceBranchCosts: "ای کامرس برانچ کے اخراجات۔",
    pleaseEnterBranchName: "براہ کرم برانچ کا نام درج کریں۔",
    pleaseEnterBranchAddress1: "براہ کرم برانچ کی ایڈریس لائن 1 درج کریں۔",
    enterBranchAddress1: "برانچ کی ایڈریس لائن 1 درج کریں۔",
    enterBranchAddress2: "برانچ کی ایڈریس لائن 1 درج کریں۔",
    pleaseEnterBranchAddress2: "براہ کرم برانچ کی ایڈریس لائن 2 درج کریں۔",
    enterBranchName: "برانچ کا نام درج کریں۔",
    successfullyAddedStaff: "آپ نے کامیابی کے ساتھ ایک نیا عملہ شامل کیا ہے!",
    addNewStaff: "نیا عملہ شامل کریں۔",
    addStaff: "عملہ شامل کریں۔",
    additionalStaffWillIncur: "ایڈیشنل سٹاف لگے گا۔",
    perStaff: "فی عملہ",
    pleaseEnterStaffEmail: "براہ کرم عملے کا ای میل درج کریں۔",
    pleaseEnterStaffUsername: "براہ کرم عملے کا صارف نام درج کریں۔",
    pleaseEnterStaffPassword: "براہ کرم عملے کا پاس ورڈ درج کریں۔",
    pleaseSelectStaffRole: "براہ کرم عملے کا کردار منتخب کریں۔",
    selectStaffRole: "عملے کا کردار منتخب کریں۔",
    enterStaffEmail: "عملے کا ای میل درج کریں۔",
    enterStaffUsername: "عملے کا صارف نام درج کریں۔",
    enterStaffPassword: "عملے کا پاس ورڈ درج کریں۔",
    spacesNotAllowedInUsername: "صارف نام میں خالی جگہوں کی اجازت نہیں ہے۔",
    admin: "ایڈمن",
    pleaseSelectBusinessToAttachStaff:
      "براہ کرم عملے کو منسلک کرنے کے لیے کاروبار منتخب کریں۔",
    searchForBranchToAttachStaff: "عملے کو منسلک کرنے کے لیے شاخ تلاش کریں۔",
    username: "صارف نام",
    role: "کردار",
    areYouSureToDeleteThis: "کیا آپ اس کو حذف کرنے کا یقین رکھتے ہیں؟",
    branches: "شاخیں۔",
    upgradeYourPlan: "اپنے منصوبے کو اپ گریڈ کریں۔",
    add: "شامل کریں۔",
    addNew: "نیا شامل کریں",
    customerWithEmailAlreadyExists:
      "ای میل/فون نمبر والا کسٹمر پہلے سے موجود ہے!",
    successfullyAddedNewCustomer:
      "آپ نے کامیابی کے ساتھ ایک نیا گاہک شامل کیا ہے!",
    addCustomer: "کسٹمر شامل کریں۔",
    pleaseEnterCustomerFirstName: "براہ کرم کسٹمر کا پہلا نام درج کریں۔",
    pleaseEnterCustomerLastName: "براہ کرم کسٹمر کا آخری نام درج کریں۔",
    pleaseEnterCustomerPhoneNumber: "براہ کرم کسٹمر کا فون نمبر درج کریں۔",
    pleaseEnterCustomerEmail: "براہ کرم کسٹمر کا ای میل درج کریں۔",
    pleaseEnterCustomerAddressLine: "براہ کرم کسٹمر کی ایڈریس لائن درج کریں۔",
    pleaseEnterCustomerOtherAddress: "براہ کرم کسٹمر کا دوسرا پتہ درج کریں۔",
    pleaseSelectCustomerGender: "براہ کرم کسٹمر کی جنس منتخب کریں۔",
    gender: "صنف",
    male: "مرد",
    female: "عورت",
    bank: "بینک",
    selectBank: "بینک منتخب کریں۔",
    stateOrRegionOrProvince: "ریاست/علاقہ/صوبہ۔",
    customerNotes: "کسٹمر نوٹس۔",
    sendSms: "ایس ایم ایس بھیجیں۔",
    editCustomer: "کسٹمر میں ترمیم کریں۔",
    redeemReward: "انعام چھڑائیں۔",
    issueLoyaltyCard: "وفاداری کارڈ جاری کریں۔",
    deleteCustomer: "کسٹمر کو حذف کریں۔",
    youveSuccessfullyAssignedLoyaltyMembership:
      "آپ نے کامیابی کے ساتھ ایک وفاداری رکنیت کی شناخت تفویض کی ہے۔",
    noMembershipIdAvailable:
      "رکنیت کی کوئی شناخت دستیاب نہیں ہے۔ براہ کرم ہم سے hello@loystar.co پر رابطہ کریں۔",
    sendEmail: "ای میل بھیجیں",
    membershipPoints: "ممبر شپ پوائنٹس۔",
    customerDetails: "گاہک کی تفصیلات",
    close: "بند کریں",
    loyaltyBalance: "وفاداری کا توازن۔",
    pointsBalance: "پوائنٹس بیلنس۔",
    starBalance: "سٹار بیلنس۔",
    requiredPoints: "مطلوبہ پوائنٹس۔",
    requiredStars: "مطلوبہ ستارے۔",
    reddemCode: "کوڈ چھڑائیں۔",
    toDeleteThisCustomer: "اس کسٹمر کو ڈیلیٹ کرنے کے لیے۔",
    customerHasBeenDeletedSuccessfully:
      "کسٹمر کو کامیابی سے حذف کر دیا گیا ہے!",
    customerWithPhoneAlreadyExists: "فون نمبر والا کسٹمر پہلے سے موجود ہے۔",
    customerWasSuccessfullyEdited: "کسٹمر کو کامیابی سے ترمیم کیا گیا۔",
    anErrorOccured: "ایک خرابی آگئی ہے",
    phoneNumberIncludeCountry: "فون نمبر (کنٹری کوڈ شامل کریں)",
    yourFileQueuedForUpload:
      "اپ لوڈ کرنے کے لیے آپ کی فائل قطار میں کھڑی ہے۔ براہ کرم کچھ سیکنڈ کے بعد پیج کو ریفریش کریں۔",
    thereWasAnErrorPerformingOperation: "آپریشن کرنے میں ایک خرابی تھی!",
    pleaseSelectFile: "براہ کرم ایک فائل منتخب کریں!",
    oopsOnlyCsvFilesAllowed:
      "افوہ! صرف CSV فائلوں کی اجازت ہے۔ براہ کرم ایک .csv فائل اپ لوڈ کریں۔",
    fileShouldBeSmallerThan5Mb:
      "فائل 5MB سے چھوٹی ہونی چاہیے۔ اگر آپ کے پاس بڑی فائل ہے تو براہ کرم support@loystar.co پر ای میل کریں۔ شکریہ",
    customerFirstNameIsRequired: "قطار میں کسٹمر کا پہلا نام درکار ہے۔",
    customerPhoneNumberIsRequired: "قطار میں کسٹمر کا فون نمبر درکار ہے۔",
    importCustomers: "صارفین کو درآمد کریں۔",
    upload: "اپ لوڈ کریں۔",
    clickIconToDownloadCsv:
      "CSV فائل ٹیمپلیٹ ڈاؤنلوڈ کرنے کے لیے اس آئیکن پر کلک کریں۔",
    getGoogleSheetVersion: "گوگل شیٹ ورژن حاصل کریں۔",
    clickOrDragFileToUpload:
      "فائل کو اپ لوڈ کرنے کے لیے اس علاقے پر کلک کریں یا گھسیٹیں۔",
    missingOrInvalidColumnHeader:
      "گم شدہ یا غلط کالم ہیڈر۔ براہ کرم ٹیمپلیٹ فارمیٹ پر عمل کریں۔ شکریہ",
    youHaveImported: "آپ نے درآمد کیا ہے۔",
    youSuccessfullyRedeemedReward: "آپ نے اپنا انعام کامیابی سے چھڑا لیا ہے!",
    sixDigitCode: "چھ ہندسوں کا کوڈ۔",
    pleaseEnterCustomerRewardCode: "براہ کرم کسٹمر کا انعامی کوڈ درج کریں۔",
    enterRewardCode: "انعام کا کوڈ درج کریں۔ (انعام کا کوڈ کیس حساس ہے)",
    selectLoyaltyProgram: "وفاداری پروگرام منتخب کریں۔",
    stamps: "ڈاک ٹکٹ۔",
    smsUnitsLow: "ایس ایم ایس یونٹس کم۔",
    pleaseTopUpSmsUnits: "براہ کرم ایس ایم ایس یونٹس کو اوپر کریں۔",
    smsSuccessfullySentForDelivery:
      "SMS کامیابی کے ساتھ ڈیلیوری کے لیے بھیجا گیا!",
    sendSmsTo: "پر ایس ایم ایس بھیجیں۔",
    allCustomers: "تمام گاہک۔",
    unitsAvailable: "یونٹس دستیاب ہیں۔",
    pleaseTypeInTheMessage: "براہ کرم پیغام ٹائپ کریں۔",
    message: "پیغام",
    charactersRemaining: "حروف باقی",
    avoidUseOfSpecialCharacters:
      "ایس ایم ایس یونٹس کو محفوظ کرنے کے لیے خصوصی حروف اور ایموجیز کے استعمال سے گریز کریں۔",
    note: "نوٹ",
    errorFetchingCustomersMultilevelDetail:
      "صارفین کو ملٹی لیول کی تفصیلات لانے میں خرابی۔",
    search: "تلاش کریں۔",
    reset: "ری سیٹ کریں۔",
    importCustomer: "درآمد گاہک۔",
    addNewCustomer: "نیا کسٹمر شامل کریں۔",
    sendSmsBroadcast: "ایس ایم ایس براڈکاسٹ بھیجیں۔",
    totalCustomers: "کل کسٹمرز",
    disbursementDetails: "ادائیگی کی تفصیلات",
    paidBy: "بذریعہ ادائیگی",
    disbursed: "تقسیم شدہ",
    bankAccountName: "بینک اکاؤنٹ کا نام۔",
    bankAccountNumber: "بینک اکاؤنٹ نمبر",
    transferInitiated: "منتقلی شروع",
    transferCompleted: "منتقلی مکمل",
    pleaseEnterAValid: "براہ کرم ایک درست درج کریں۔",
    begin: "شروع کریں",
    end: "ختم",
    date: "تاریخ",
    paymentDate: "ادائیگی کی تاریخ",
    selectDisbursementDuration: "ادائیگی کی مدت منتخب کریں۔",
    totalSettled: "کل آباد",
    totalUnsettled: "کل بے چین",
    toDeleteThisSale: "اس فروخت کو حذف کرنے کے لیے۔",
    draftSaleDeletedSuccessfully: "مسودہ فروخت کامیابی سے حذف کر دیا گیا ہے!",
    deleteSale: "فروخت حذف کریں۔",
    pleaseTypeInYourTotal: "براہ کرم حذف کی تصدیق کے لیے اپنا کل درج کریں۔",
    billDetails: "بل کی تفصیلات",
    printBill: "بل پرنٹ کریں۔",
    servedBy: "کی طرف سے خدمت کی",
    total: "کل۔",
    createdDate: "تشکیل کی تاریخ",
    createdTime: "تخلیق شدہ وقت۔",
    status: "حالت",
    loginSuccessful: "لاگ ان کامیاب۔",
    pleaseWaitWhileWeConnectAccount:
      "براہ کرم انتظار کریں جب تک ہم آپ کے اکاؤنٹ کو جوڑتے ہیں۔",
    connectionFailedTryAgain: "رابطہ ناکام. دوبارہ کوشش کریں.",
    connectionSuccessful: "کنکشن کامیاب۔",
    viewDetails: "تفصیلات دیکھیں",
    enable: "فعال",
    free: "مفت۔",
    cost: "لاگت",
    visitWebsite: "ویب سائیٹ پر جائیں",
    pleaseUpgradeYourPlanToPro:
      "براہ کرم اس ایپ کو فعال کرنے کے لیے اپنے منصوبے کو پرو پلس میں اپ گریڈ کریں۔",
    connectYourBankAccount:
      "ادائیگی وصول کرنے کے قابل ہونے کے لیے اپنے بینک اکاؤنٹ کو جوڑیں۔",
    disable: "غیر فعال کریں",
    enableApp: "ایپ کو فعال کریں۔",
    addNewProductToInvoice: "انوائس میں نئی پروڈکٹ شامل کریں۔",
    toDeleteThisInvoice: "اس انوائس کو حذف کرنے کے لیے۔",
    invoiceDeletedSuccessfully: "انوائس کامیابی سے حذف کر دی گئی ہے!",
    deleteInvoice: "انوائس حذف کریں۔",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "آپ نے کامیابی کے ساتھ انوائس ادائیگی کا پیغام شامل کر دیا ہے!",
    pleaseEnterPaymentInstructions: "براہ کرم ادائیگی کی ہدایات درج کریں۔",
    invoiceId: "انوائس ID۔",
    paidDate: "ادائیگی کی تاریخ۔",
    reference: "حوالہ۔",
    productAdded: "پروڈکٹ شامل کی گئی۔",
    productOutOfStock: "اسٹاک سے باہر کی مصنوعات براہ کرم دوبارہ اسٹاک کریں۔",
    totalInvoices: "کل انوائس۔",
    totalPaidInvoices: "کل ادائیگی کی انوائس۔",
    totalUnpaidInvoices: "کل ادائیگی شدہ انوائسز۔",
    loyaltyProgramDeleted: "وفاداری پروگرام حذف کر دیا گیا۔",
    thisLoyaltyProgramDeletedSuccessfully:
      "یہ وفاداری پروگرام کامیابی سے حذف کر دیا گیا ہے۔",
    thisLoyaltyProgramEditedSuccessfully:
      "یہ وفاداری پروگرام کامیابی سے ترمیم کیا گیا ہے۔",
    loyaltyProgramAddedSuccessfully:
      "وفاداری پروگرام کامیابی کے ساتھ شامل کر دیا گیا ہے۔",
    loyaltyProgramEdited: "وفاداری پروگرام میں ترمیم",
    loyaltyProgramAdded: "وفاداری پروگرام شامل کر دیا گیا۔",
    loyaltyDetails: "وفاداری کی تفصیلات",
    doYouWantToCloseDialog: "کیا آپ یہ ڈائیلاگ بند کرنا چاہتے ہیں؟",
    pleaseEnterLoyaltyName: "براہ کرم اپنی وفاداری کا نام درج کریں۔",
    programType: "پروگرام کی قسم۔",
    pleaseSelectProgramType: "براہ کرم ایک پروگرام کی قسم منتخب کریں۔",
    simplePoints: "سادہ پوائنٹس۔",
    stampsProgram: "ڈاک ٹکٹ پروگرام۔",
    threshold: "دہلیز",
    pleaseEnterLoyaltyThreshold: "براہ کرم وفاداری کی حد درج کریں۔",
    reward: "انعام",
    pleaseEnterLoyaltyReward: "براہ کرم وفاداری کا انعام درج کریں۔",
    totalUserPoints: "کل یوزر پوائنٹس۔",
    totalUserStamps: "کل صارف ڈاک ٹکٹ۔",
    spendingTarget: "ہدف خرچ کرنا۔",
    spendingTargetHint1:
      "خرچ کرنے کا ہدف یہ ہے کہ کسٹمر کو انعام حاصل کرنے کے لیے کتنا خرچ کرنا چاہیے۔ 1 کرنسی ویلیو = 1 پوائنٹ۔",
    spendingTargetHint2:
      "ڈاک ٹکٹوں کا ہدف یہ ہے کہ کسٹمر کو انعام حاصل کرنے کے لیے کتنے ڈاک ٹکٹ جمع کرنا ہوں گے۔ جیسے 5۔",
    addNewLoyaltyProgram: "نیا وفاداری پروگرام شامل کریں۔",
    addLoyaltyProgram: "وفاداری پروگرام شامل کریں۔",
    loyaltyProgramType: "وفاداری پروگرام کی قسم",
    pleaseSelectLoyaltyProgramType:
      "براہ کرم وفاداری پروگرام کی قسم منتخب کریں۔",
    nameOfProgram: "پروگرام کا نام۔",
    pleaseEnterProgramName: "براہ کرم پروگرام کا نام درج کریں۔",
    whatIsTheReward: "ثواب کیا ہے؟",
    egNextPurchaseIsFree: "جیسے اگلی خریداری مفت ہے۔",
    customerName: "گاہک کا نام",
    guestCustomer: "مہمان گاہک۔",
    orderConfirmedSuccessfully: "آرڈر کی کامیابی سے تصدیق ہوگئی۔",
    orderCancelledSuccessfully: "آرڈر کامیابی سے منسوخ ہو گیا۔",
    confirmOrder: "حکم کی تصدیق",
    cancelOrder: "آرڈر منسوخ کر دیں",
    allOrders: "تمام احکامات۔",
    totalOrders: "کل آرڈرز",
    doYouWantToAcceptOrder: "کیا آپ اس حکم کو قبول کرنا چاہتے ہیں؟",
    doYouWantToCancelOrder: "کیا آپ اس آرڈر کو منسوخ کرنا چاہتے ہیں؟",
    orderDetails: "آرڈر کی تفصیلات",
    orderCreatedAt: "آرڈر پر بنایا گیا۔",
    supplier: "سپلائر",
    productName: "پروڈکٹ کا نام",
    quantity: "مقدار",
    unitPrice: "اکائی قیمت",
    receivedBy: "کی طرف سے موصول",
    reportFrom: "سے رپورٹ۔",
    totalSupplies: "کل سامان۔",
    allRightsReserved: "جملہ حقوق محفوظ ہیں",
    toDeleteThisTransaction: "اس لین دین کو حذف کرنے کے لیے۔",
    transactionDeletedSuccessfully:
      "لین دین کامیابی کے ساتھ حذف کر دیا گیا ہے۔ اسٹاک کو انوینٹری میں واپس کردیا گیا ہے۔",
    deleteTransaction: "لین دین حذف کریں۔",
    transactionDetails: "لین دین کی تفصیلات",
    printReceipt: "پرنٹ رسید۔",
    channel: "چینل۔",
    discount: "چھوٹ۔",
    profit: "نفع",
    discountedSales: "رعایتی فروخت۔",
    errorFetchingRecord: "ریکارڈ لانے میں خرابی۔",
    exportTransactions: "لین دین برآمد کریں۔",
    errorFetchingSalesRecord: "برآمد کے لیے سیلز ریکارڈ لانے میں خرابی۔",
    totalSellingPrice: "فروخت کی کل قیمت۔",
    totalCostPrice: "کل قیمت۔",
    appliedDiscount: "اپلائیڈ ڈسکاؤنٹ۔",
    noOfItems: "اشیاء کی تعداد",
    sales: "فروخت",
    export: "برآمد کریں۔",
    totalProfit: "کل منافع۔",
    totalBalanceInPeriod: "مدت میں کل بیلنس۔",
    allTimeSales: "آل ٹائم سیلز۔",
    records: "ریکارڈز۔",
    todaysSales: "آج کی فروخت۔",
    transaction: "لین دین",
    youHaveExceededTotalNumberOfProducts:
      "آپ نے اپنے منصوبے پر اجازت دی گئی مصنوعات کی کل تعداد سے تجاوز کر لیا ہے۔ زیادہ سے زیادہ حد سے لطف اندوز ہونے کے لیے اپنے منصوبے کو اپ گریڈ کریں۔",
    youNeedToHaveLoyaltyProgram:
      "اس خصوصیت کو استعمال کرنے کے لیے آپ کو ایک وفاداری پروگرام کی ضرورت ہے!",
    price: "قیمت",
    category: "قسم",
    stockTracking: "اسٹاک ٹریکنگ۔",
    stockCount: "اسٹاک کی گنتی",
    taxed: "ٹیکس لگایا گیا۔",
    originalPrice: "اصل قیمت",
    costPrice: "قیمت قیمت۔",
    unit: "یونٹ",
    productImage: "مصنوعات کی تصویر",
    taxRate: "ٹیکس کی شرح",
    taxType: "ٹیکس کی قسم",
    trackInventory: "ٹریک انوینٹری۔",
    variants: "متغیرات",
    hasVariants: "متغیرات ہیں۔",
    importProduct: "مصنوعات درآمد کریں۔",
    exportProducts: "مصنوعات برآمد کریں۔",
    addNewProduct: "نئی پروڈکٹ شامل کریں۔",
    viewCategory: "زمرہ دیکھیں۔",
    viewSuppliers: "سپلائرز دیکھیں۔",
    receiveInventory: "انوینٹری وصول کریں۔",
    printAllProductsTag: "تمام مصنوعات ٹیگ پرنٹ کریں۔",
    deleteAll: "تمام حذف کریں",
    totalProducts: "کل پروڈکٹس",
    youveSuccessfullyAddedANewCategory:
      "آپ نے کامیابی کے ساتھ ایک نیا زمرہ شامل کیا ہے۔",
    addNewCategory: "نئی زمرہ شامل کریں۔",
    addCategory: "زمرہ شامل کریں۔",
    categoryName: "قسم کا نام",
    pleaseEnterCategoryName: "براہ کرم زمرہ کا نام درج کریں۔",
    stampsTarget: "ڈاک ٹکٹ کا ہدف۔",
    sendInventory: "انوینٹری بھیجیں۔",
    productDetails: "مصنوعات کی تفصیلات",
    youveSuccessfullyEditedThisCategory:
      "آپ نے اس زمرے میں کامیابی سے ترمیم کی ہے۔",
    update: "اپ ڈیٹ",
    categoryList: "زمرہ کی فہرست۔",
    categories: "اقسام",
    enterQuantityToUpdate: "اپ ڈیٹ کرنے کے لیے ایک مقدار درج کریں۔",
    inventorySentSuccessfully: "انوینٹری کامیابی کے ساتھ بھیجی گئی!",
    updateInventory: "انوینٹری کو اپ ڈیٹ کریں۔",
    currentQuantity: "موجودہ مقدار",
    pleaseEnterQuantityToAdd:
      "براہ کرم وہ مقدار درج کریں جسے آپ شامل کرنا چاہتے ہیں۔",
    pleaseSelectABranch: "براہ کرم ایک برانچ منتخب کریں۔",
    searchForBranch: "برانچ کی تلاش کریں۔",
    youCantSendMoreThanStock: "آپ اپنے اسٹاک سے زیادہ نہیں بھیج سکتے۔",
    send: "بھیجیں",
    pleaseEnterQuantityToSend:
      "براہ کرم وہ مقدار درج کریں جسے آپ بھیجنا چاہتے ہیں۔",
    productNameIsRequiredOnRow: "پروڈکٹ کا نام قطار میں درکار ہے۔",
    productCategoryIsRequiredOnRow: "قطار میں پروڈکٹ کیٹیگری درکار ہے۔",
    productPriceIsRequiredOnRow: "مصنوعات کی قیمت قطار میں درکار ہے۔",
    productUnitIsRequiredOnRow: "قطار میں پروڈکٹ یونٹ درکار ہے۔",
    productQuantityIsRequiredOnRow: "قطار میں مصنوعات کی مقدار درکار ہے۔",
    productVariantsRequireTracking:
      "مصنوعات کی مختلف حالتوں سے باخبر رہنے کی ضرورت ہے!",
    pleaseAddVariantClickButton:
      "براہ کرم شامل کریں بٹن پر کلک کرکے ایک قسم شامل کریں!",
    totalVariantsMoreThanSelectedQuantity:
      "کل متغیرات منتخب شدہ مصنوعات کی مقدار سے زیادہ ہیں ، Pls مختلف مقدار کو کم کرتے ہیں۔",
    productEditedSuccessfully: "پروڈکٹ میں کامیابی سے ترمیم کی گئی ہے!",
    fileTooLargeLessThan4Mb:
      "فائل کا سائز بہت بڑا ہے! فائل کا سائز 4MB سے کم ہونا چاہیے۔",
    suchVariantAlreadyExist: "اس طرح کی قسم پہلے سے موجود ہے۔",
    addVariants: "متغیرات شامل کریں۔",
    editProduct: "پروڈکٹ میں ترمیم کریں۔",
    pleaseEnterProductName: "براہ کرم پروڈکٹ کا نام درج کریں۔",
    pleaseEnterProductPrice: "براہ کرم مصنوعات کی قیمت درج کریں۔",
    pleaseEnterProductOriginalPrice: "براہ کرم پروڈکٹ کی اصل قیمت درج کریں۔",
    productDescription: "مصنوعات کی وضاحت",
    selectProductCategory: "پروڈکٹ کیٹیگری منتخب کریں۔",
    pleaseSelectProductCategory: "براہ کرم پروڈکٹ کا زمرہ منتخب کریں۔",
    productCostPrice: "پروڈکٹ لاگت کی قیمت۔",
    productSellingPrice: "مصنوعات کی فروخت کی قیمت۔",
    productOriginalPrice: "مصنوعات کی اصل قیمت۔",
    maxFileSizeAllowedIs4Mb: "زیادہ سے زیادہ فائل سائز 4MB کی اجازت ہے۔",
    productsWithPicturesArePublished:
      "تصاویر کے ساتھ مصنوعات ڈسکور پر آرڈر حاصل کرنے کے لیے شائع کی جاتی ہیں۔",
    shouldThisProductBeTracked: "کیا اس پروڈکٹ کا سراغ لگانا چاہیے؟",
    pleaseSelectStockUnit: "براہ کرم اسٹاک یونٹ منتخب کریں۔",
    stockUnit: "اسٹاک یونٹ۔",
    bag: "بیگ",
    bottle: "بوتل۔",
    bunch: "جھنڈ",
    can: "کر سکتے ہیں",
    carton: "کارٹن۔",
    crate: "کریٹ",
    cup: "کپ",
    dozen: "ڈوزن۔",
    gigabytes: "گیگا بائٹس",
    gram: "گرام",
    kilogram: "کلوگرام",
    litre: "لیٹر۔",
    pack: "پیک",
    pair: "جوڑا",
    pieces: "ٹکڑے",
    plate: "پلیٹ",
    tonne: "ٹن (MT)",
    uNIT: "یونٹ",
    yard: "یارڈ۔",
    pleaseEnterProductQuantity: "براہ کرم مصنوعات کی مقدار درج کریں۔",
    productQuantity: "مصنوعات کی مقدار",
    isThisProductTaxed: "کیا اس پروڈکٹ پر ٹیکس لگایا گیا ہے؟",
    selectTaxType: "ٹیکس کی قسم منتخب کریں۔",
    pleaseSelectTaxType: "براہ کرم ٹیکس کی قسم منتخب کریں۔",
    progressive: "ترقی پسند",
    proportional: "متناسب",
    pleaseEnterProductTaxRate: "براہ کرم پروڈکٹ ٹیکس کی شرح درج کریں۔",
    doesProductHaveVariants: "کیا اس پروڈکٹ کی مختلف حالتیں ہیں؟",
    type: "ٹائپ کریں۔",
    value: "قدر",
    pleaseEnterVariantType: "براہ کرم متغیر کی قسم درج کریں۔",
    pleaseEnterVariantValue: "براہ کرم متغیر کی قیمت درج کریں۔",
    pleaseEnterVariantPrice: "براہ کرم متغیر کی قیمت درج کریں۔",
    pleaseEnterVariantQuantity: "براہ کرم مختلف قسم کی مقدار درج کریں۔",
    productDeletedSuccessfully: "پروڈکٹ کامیابی سے حذف کر دیا گیا ہے!",
    categoryDeletedSuccessfully: "زمرہ کامیابی سے حذف کر دیا گیا ہے!",
    toDeleteThisProduct: "اس پروڈکٹ کو حذف کرنے کے لیے۔",
    invalidProductQuantity: "غلط پروڈکٹ کی مقدار",
    quantityAddedIsOverStock:
      "آپ جو مقدار شامل کر رہے ہیں وہ آپ کے اسٹاک میں ہے۔",
    itemInventoryNotTracked: "آئٹم انوینٹری کو ٹریک نہیں کیا جاتا ہے۔",
    addBulkQuantity: "بلک مقدار شامل کریں۔",
    enterBulkQuantity: "بلک مقدار درج کریں",
    pleaseEnterBulkQuantity: "براہ کرم بلک مقدار درج کریں۔",
    youveSuccessfullyAddedANewProduct:
      "آپ نے کامیابی کے ساتھ ایک نئی پروڈکٹ شامل کی ہے!",
    pleaseEnterProductSellingPrice:
      "براہ کرم مصنوعات کی فروخت کی قیمت درج کریں۔",
    doYouWantToTrackProductStock: "کیا آپ پروڈکٹ اسٹاک کو ٹریک کرنا چاہتے ہیں؟",
    sellingPrice: "قیمت فروخت",
    setProductExpiryReminder: "پروڈکٹ کی میعاد ختم کرنے کی یاد دہانی سیٹ کریں۔",
    productExpiryDate: "مصنوعات کی میعاد ختم ہونے کی تاریخ۔",
    startRemindingFrom: "سے یاد دہانی شروع کریں۔",
    productSuppliesAddedSuccessfully:
      "آپ نے مصنوعات کی فراہمی کامیابی کے ساتھ شامل کر دی ہے۔",
    addProductSupplies: "مصنوعات کی فراہمی شامل کریں۔",
    pleaseSelectSupplier: "براہ کرم سپلائر منتخب کریں۔",
    nameOfProduct: "پروڈکٹ کا نام",
    pleaseSelectProduct: "براہ کرم پروڈکٹ منتخب کریں۔",
    productVariant: "مصنوعات کی مختلف حالت",
    pleaseSelectAVariant: "براہ کرم ایک متغیر منتخب کریں۔",
    pleaseEnterUnitPrice: "براہ کرم یونٹ کی قیمت درج کریں۔",
    businessBranch: "بزنس برانچ۔",
    pleaseSelectBranch: "براہ کرم برانچ منتخب کریں۔",
    youveSuccessfullyAddedANewSupplier:
      "آپ نے کامیابی کے ساتھ ایک نیا سپلائر شامل کیا ہے۔",
    addSupplier: "سپلائر شامل کریں۔",
    pleaseEnterSupplierEmail: "براہ کرم سپلائر کا ای میل درج کریں۔",
    pleaseAddADescription: "براہ کرم ایک تفصیل شامل کریں۔",
    anErrorOccuredProductsDeleted:
      "آپریشن کرتے ہوئے ایک خرابی پیش آگئی۔ براہ کرم نوٹ کریں ، اس عمل میں کچھ مصنوعات حذف ہو سکتی ہیں۔",
    bulkDelete: "بلک ڈیلیٹ۔",
    youAreAboutToDelete: "آپ حذف کرنے والے ہیں۔",
    product: "پروڈکٹ",
    isDueToRedeem: "چھڑانے کی وجہ سے ہے",
    aReward: "ایک اجر",
    pleaseSelectCustomerToReeemReward:
      "برائے مہربانی انعام کو چھڑانے کے لیے ایک کسٹمر منتخب کریں۔",
    youHaveNoLoyaltyProgramRunning:
      "آپ کے پاس کوئی فعال وفاداری پروگرام نہیں چل رہا ہے۔",
    customerHhasNoPointsInLoyaltyProgram:
      "اس وفاداری پروگرام میں کسٹمر کا کوئی نقطہ نہیں ہے۔",
    proceedWithPayment: "ادائیگی کے ساتھ آگے بڑھیں؟",
    youAreAboutToPayForTransactionUsingPoints:
      "آپ پوائنٹس کا استعمال کرتے ہوئے لین دین کی ادائیگی کرنے والے ہیں۔",
    customerHas: "کسٹمر کے پاس ہے۔",
    worth: "قابل",
    andIsNotSufficientToPayForTransaction:
      "اور اس لین دین کی ادائیگی کے لیے کافی نہیں ہے۔ کیا وہ ادائیگی کا دوسرا طریقہ استعمال کرتے ہوئے بیلنس شامل کرنا چاہیں گے؟",
    addCustomerLoyalty: "کسٹمر وفاداری شامل کریں۔",
    pleaseAddCustomerFirst:
      "براہ کرم پہلے کسی صارف کو شامل کریں یا منتخب کریں۔",
    pleaseWaitWhileWeGetReady: "براہ کرم انتظار کریں ، جب تک ہم تیار ہوں۔",
    lowStock: "کم اسٹاک۔",
    pleaseEnterAmountTendered: "براہ کرم ٹینڈر کردہ رقم درج کریں۔",
    areYouSureToBookSaleOffline:
      "کیا آپ واقعی یہ فروخت آف لائن بک کرنا چاہتے ہیں؟",
    saleWouldBeBookedAutomatically:
      "جب آپ اپنا انٹرنیٹ آن کرتے ہیں تو سیل خود بخود بک ہوجاتی ہے۔",
    offlineSalesBookingCancelled: "آف لائن سیلز بکنگ منسوخ۔",
    covid19Message:
      "کووڈ 19: اپنے ہاتھوں کو صابن سے دھوئیں یا پھیلاؤ کو روکنے کے لیے سینیٹائز کریں۔ ہمیشہ محفوظ رہیں۔",
    saleSuccessfullyRecorded: "فروخت کامیابی سے ریکارڈ کی گئی!",
    sendReceiptToEmail: "رسید ای میل پر بھیجیں۔",
    sendThankYouSms: "شکریہ ایس ایم ایس بھیجیں۔",
    sendShippingDetails: "شپنگ کی تفصیلات بھیجیں۔",
    addLoyalty: "وفاداری شامل کریں۔",
    searchCustomerNameOrNumber: "کسٹمر کا نام یا نمبر تلاش کریں۔",
    clickTheSearchCustomerBox: "سرچ کسٹمر باکس اور اسکین کارڈ پر کلک کریں۔",
    enterProductPrice: "مصنوعات کی قیمت درج کریں۔",
    enterPriceFor: "کے لیے قیمت درج کریں۔",
    searchForProduct: "مصنوعات کی تلاش کریں۔",
    all: "تمام",
    backToDashboard: "واپس ڈیش بورڈ پر۔",
    viewDraftSales: "ڈرافٹ سیلز دیکھیں۔",
    variantSelected: "مختلف قسم منتخب",
    variant: "مختلف",
    thePreviousVariantSelectionNotAvailable:
      "قیمت کی بنیاد پر منتخب کردہ نئے ویرینٹ کے لیے پچھلی قسم کا انتخاب دستیاب نہیں ہے ، براہ کرم اپنا انتخاب تبدیل کریں۔",
    pleaseSupplyPositiveQuantityNumber:
      "برائے مہربانی ایک پوسٹیو مقدار نمبر فراہم کریں۔",
    lowStockFor: "کے لیے کم اسٹاک۔",
    clearVariants: "مختلف حالتیں صاف کریں۔",
    pleaseEnterQuantity: "براہ کرم مقدار درج کریں۔",
    picture: "تصویر",
    redemptionToken: "چھٹکارا ٹوکن۔",
    token: "ٹوکن۔",
    totalSpent: "کل خرچ",
    confirmPayment: "ادائیگی کی تصدیق کریں۔",
    hasPaymentBeenMade: "کیا ادائیگی کامیابی سے عمل میں آئی ہے؟",
    enterTransactionReference:
      "لین دین کا حوالہ درج کریں جس کے ساتھ آپ نے ادائیگی کی ہے۔",
    pleaseSelectACustomer: "براہ کرم ایک کسٹمر منتخب کریں!",
    areYouSureToApplyDiscount: "کیا آپ واقعی ڈسکاؤنٹ لگانا چاہتے ہیں؟",
    addYourBankAccountToEnableUssd:
      "یوپی کے ذریعے فوری یو ایس ایس ڈی ٹرانسفر کو فعال کرنے کے لیے اپنا بینک اکاؤنٹ شامل کریں۔",
    totalAmountToPay: "ادائیگی کی کل رقم۔",
    doYouWantToCancelTransaction: "کیا آپ اس لین دین کو منسوخ کرنا چاہتے ہیں؟",
    savePrintBill: "بل محفوظ کریں/پرنٹ کریں۔",
    enterAmountCollectedForCustomer: "کسٹمر کے لیے جمع کی گئی رقم درج کریں۔",
    recordSale: "ریکارڈ فروخت۔",
    checkOutWith: "کے ساتھ چیک کریں۔",
    instantTransfer: "فوری منتقلی۔",
    dialTheUSSDCode: "یو ایس ایس ڈی کوڈ ڈائل کریں۔",
    pleaseSelectABank: "براہ کرم ایک بینک منتخب کریں۔",
    payWithUSSD: "یو ایس ایس ڈی کے ساتھ ادائیگی کریں۔",
    sendBillTo: " - بل بھیجیں۔",
    waitingForUSSDTransfer: "یو ایس ایس ڈی کی منتقلی کا انتظار ہے۔",
    percent: "فیصد",
    applyDiscount: "ڈسکاؤنٹ کا اطلاق کریں۔",
    thisBillHasBeenSaved: "یہ بل محفوظ ہو گیا ہے۔",
    saveDraft: "مسودے کو بچانے کے",
    pleaseTypeANameToIdentifyCustomer:
      "گاہک کی شناخت کے لیے براہ کرم ایک نام ٹائپ کریں۔",
    paymentDetails: "ادائیگی کی تفصیلات",
    basePrice: "بنیادی قیمت",
    staff: "عملہ",
    subTotal: "ذیلی کل",
    durationMonths: "دورانیہ (مہینے)",
    selectADuration: "دورانیہ منتخب کریں۔",
    oneMonth: "1 مہینہ",
    twoMonths: "2 مہینے",
    threeMonths: "3 ماہ",
    sixMonths: "6 ماہ",
    twelveMonths: "12 ماہ",
    eighteenMonths: "18 ماہ",
    twentyFourMonths: "24 ماہ",
    twoMonthsFree: "(2 ماہ مفت)",
    threeMonthsFree: "(3 ماہ مفت)",
    fiveMonthsFree: "(5 ماہ مفت)",
    yourAccountHasBeen: "آپ کا اکاؤنٹ ہو چکا ہے۔",
    renewed: "تجدید شدہ",
    upgraded: "اپ گریڈ",
    successfully: "کامیابی سے",
    yourSubscription: "آپ کی سبسکرپشن۔",
    youAreCurrentlyEnrolledOnA: "آپ فی الحال ایک پر اندراج شدہ ہیں۔",
    pleaseChooseAPaymentOption: "براہ کرم ادائیگی کا آپشن منتخب کریں۔",
    planRenewal: "تجدید کا منصوبہ بنائیں۔",
    planUpgrade: "اپ گریڈ کا منصوبہ بنائیں۔",
    pleaseSelectDurationToPayFor:
      "براہ کرم وہ مدت منتخب کریں جس کی آپ ادائیگی کرنا چاہتے ہیں۔",
    staffAccounts: "اسٹاف اکاؤنٹس۔",
    ecommerce: "ای کامرس",
    pleaseSelectAPlan: "براہ کرم ایک منصوبہ منتخب کریں۔",
    includeAddons: "ایڈ آن شامل کریں۔",
    viewTransactions: "لین دین دیکھیں۔",
    customerHasNoCompletedTansactions:
      "کسٹمر کے پاس ابھی تک کوئی مکمل ٹینشن نہیں ہے۔",
    branch: "شاخ",
    enterNumberOfEcommerceBranches: "ای کامرس برانچوں کی تعداد درج کریں۔",
    enterNumberOfEcommerceBranchesToPay:
      "ای کامرس برانچوں کی تعداد درج کریں جن کے لیے آپ ادائیگی کرنا چاہتے ہیں۔",
    ecommerceIntegration: "ای کامرس انضمام۔",
    enterNumberOfBranches: "شاخوں کی تعداد درج کریں۔",
    enterNumberOfAdditionalBranchesToPay:
      "اضافی شاخوں کی تعداد درج کریں جن کے لیے آپ ادائیگی کرنا چاہتے ہیں۔",
    enterNumberOfStaffs: "عملے کی تعداد درج کریں۔",
    enterNumberOfStaffsToPayFor:
      "عملے کی تعداد درج کریں جس کے لیے آپ ادائیگی کرنا چاہتے ہیں۔",
    discountApplies: "ڈسکاؤنٹ لاگو ہوتا ہے",
    starsOnThe: "پر ستارے",
    offer: "پیشکش",
    get: "حاصل کریں۔",
    moreStarsToRedeem: "چھڑانے کے لیے مزید ستارے",
    taxVat: "ٹیکس (VAT)",
    callCashierToCompletePayment:
      "ادائیگی مکمل کرنے کے لیے کیشیئر کو کال کریں۔",
    receipt: "رسید",
    dear: "عزیز۔",
    thankYouForYourOrderFindGoods:
      "آپ کے حکم کا شکریہ۔ برائے مہربانی درج ذیل سامان فراہم کریں ، جیسا کہ اتفاق کیا گیا ہے۔",
    shippingNote: "شپنگ نوٹ۔",
    enterShippingNote: "شپنگ نوٹ درج کریں۔",
    shippingAddress: "ترسیل کا پتہ۔",
    enterShippingAddress: "شپنگ ایڈریس درج کریں۔",
    wellDoneYouAreDueToRedeem: "بہت خوب! آپ کو چھڑانا ہے۔",
    toGetYourRewardNextVisit:
      "اپنے اگلے دورے پر اپنا انعام حاصل کرنے کے لیے۔ شکریہ",
    pointsOnThe: "پر پوائنٹس۔",
    morePointsToRedeem: "چھڑانے کے لیے مزید پوائنٹس",
    showCode: "کوڈ دکھائیں۔",
    toGetYourRewardOnNextVisit:
      "اپنے اگلے دورے پر اپنا انعام حاصل کرنے کے لیے۔ شکریہ",
    earn: "کمائیں۔",
    delivaryNote: "ڈلیوری نوٹ۔",
    draftSales: "ڈرافٹ سیلز۔",
    startDate: "شروع کرنے کی تاریخ",
    endDate: "آخری تاریخ",
    orders: "احکامات",
    checkout: "اس کو دیکھو",
    noProductItem: "کوئی پروڈکٹ آئٹم نہیں۔",
    selectProductImage: "پروڈکٹ امیج منتخب کریں۔",
    selectCountry: "ملک کا انتخاب کیجئے",
    selectRegion: "ریاست/علاقہ منتخب کریں۔",
    printProductTag: "پرنٹ پروڈکٹ ٹیگ۔",
    transactionReference: "لین دین کا حوالہ۔",
    Cashier: "کیشیئر",
    Manager: "منیجر",
    Owner: "مالک۔",
    Admin: "ایڈمن",
    addPartners: "شراکت داروں کو شامل کریں۔",
    addNewLoyaltyPartner: "نیا لائلٹی پارٹنر شامل کریں۔",
    pleaseEnterCompanyName: "براہ کرم کمپنی کا نام درج کریں۔",
    companyName: "کمپنی کا نام",
    pleaseEnterCompanyRepName: "براہ کرم کمپنی کے نمائندے کا نام درج کریں۔",
    companyRepName: "کمپنی کے نمائندے کا نام",
    pleaseEnterCompanyRepEmail: "براہ کرم کمپنی کے نمائندے کا ای میل درج کریں۔",
    companyRepEmail: "کمپنی کے نمائندے کا ای میل",
    pleaseEnterCompanyRepPhone:
      "براہ کرم کمپنی کے نمائندے کا فون نمبر درج کریں۔",
    companyRepPhone: "کمپنی کے نمائندے کا فون نمبر",
    addReward: "انعام شامل کریں۔",
    pleaseEnterRewardName: "براہ کرم انعام کا نام درج کریں۔",
    rewardName: "انعام کا نام",
    rewardQuantity: "انعام کی مقدار",
    rewardDescription: "انعام کی تفصیل",
    rewardType: "انعام کی قسم",
    pleaseEnterRewardType: "براہ کرم انعام کی قسم درج کریں۔",
    pleaseEnterRewardQuantity: "براہ کرم انعام کی مقدار درج کریں۔",
    pleaseEnterRewardDescription: "براہ کرم انعام کی تفصیل درج کریں۔",
    fineDining: "کھانے کا مناسب انتیظام",
    luxuryFashion: "لگژری فیشن",
    hotels: "ہوٹل",
    travel: "سفر",
    foodAndBeverage: "کھانے اور مشروبات",
    fashion: "فیشن",
    health: "صحت",
    furniture: "فرنیچر",
    entertainment: "تفریح",
    automobile: "گاڑی",
    education: "تعلیم",
    beautyAndSpa: "بیوٹی اینڈ سپا",
    staycation: "قیام",
    events: "تقریبات",
    trips: "دورے",
    oilAndGas: "تیل اور گیس",
    laundry: "لانڈری",
    partnerCategory: "پارٹنر کیٹیگری",
    freeItem: "مفت آئٹم",
  },
  Uyghur: {
    cashier: "كاسسىر",
    manager: "باشقۇرغۇچى",
    owner: "ئىگىسى",
    online: "توردا",
    offline: "تورسىز",
    changePassword: "پارولنى ئۆزگەرتىش",
    currentPasswordMessage: "نۆۋەتتىكى پارولىڭىزنى كىرگۈزۈڭ",
    passwordMessage: "پارولىڭىزنى كىرگۈزۈڭ",
    currentPassword: "نۆۋەتتىكى پارول",
    password: "پارول",
    confirmPasswordMessage: "پارولىڭىزنى جەزملەشتۈرۈڭ.",
    confirmPassword: "پارولنى جەزملەشتۈرۈڭ",
    sendViaEmail: "ئېلېكترونلۇق خەت ئارقىلىق ئەۋەتىڭ",
    sendViaWhatsapp: "WhatsApp ئارقىلىق ئەۋەتىڭ",
    downloadPdf: "PDF نى چۈشۈرۈڭ",
    paid: "ھەقلىق",
    unpaid: "ھەقسىز",
    partial: "قىسمەن",
    closeInvoice: "تالوننى ياپماقچىمۇ؟",
    closeInvoiceConfirmation: "تالون ساقلانماسلىقى مۇمكىن. تاقاشنى خالامسىز؟",
    yes: "ھەئە",
    no: "ياق",
    invoice: "تالون",
    wasDeducted: "تۇتۇپ قېلىندى",
    for: "for",
    item: "Item",
    addProduct: "مەھسۇلات قوشۇڭ",
    paymentReference: "ھەق تاپشۇرۇش پايدىلىنىش ماتېرىيالى",
    amountPaid: "تۆلىگەن پۇل",
    discountAmount: "ئېتىبار سوممىسى",
    amountDue: "سومما",
    amount: "سومما",
    dueDate: "قەرەلى توشقان ۋاقىت",
    paymentType: "چىقىم تىپى",
    card: "كارتا",
    cash: "نەق پۇل",
    bankTransfer: "Offline Bank Transfer",
    paymentMessage: "ھەق تۆلەش ئۇچۇرى",
    description: "چۈشەندۈرۈش",
    sendReceipt: "تالون ئەۋەتىش",
    delete: "ئۆچۈرۈش",
    save: "ساقلاش",
    resend: "قايتا ئەۋەتىڭ",
    saveAndSend: "ساقلاش",
    invoiceSaved: "تالون ساقلاندى!",
    selectPaymentMethod: "ھەق تۆلەش ئۇسۇلىنى تاللاڭ!",
    selectCustomer: "بىر خېرىدارنى تاللاڭ!",
    cartEmptyError:
      "ھارۋا تىزىملىكى بوش قالمايدۇ ، تالوننى تاقاپ ، ھارۋىغا تۈر قوشۇڭ!",
    subscriptionExpired:
      "مۇشتەرىلىكىڭىزنىڭ ۋاقتى توشتى ، ھېساباتىڭىز ھازىر چەكلىك. ھېساباتىڭىزنى يېڭىلاش ئۈچۈن تۆۋەندىكى كۇنۇپكىنى بېسىڭ",
    renew: "يېڭىلاش",
    holdOn: "ساقلاپ تۇرۇڭ",
    customerBank: "خېرىدارلار بانكىسى",
    cancel: "بىكار قىلىش",
    selectACustomer: "خېرىدارنى تاللاڭ",
    invoiceSuccessfulPdfError:
      "تالون مۇۋەپپەقىيەتلىك قۇرۇلدى ، ئەمما PDF ئەۋلاد ئادەتتىكىگە قارىغاندا ئۇزۇنراق ۋاقىت كەتتى. قىسقا ۋاقىت ئىچىدە قايتا تەكشۈرۈپ بېقىڭ.",
    downloadingInvoice: "تالون چۈشۈرۈش",
    downloadingReceipt: "چۈشۈرۈش تالونى",
    whatsappReceiptError:
      "تالوننى WhatsApp ئارقىلىق ئەۋەتىشتە خاتالىق كۆرۈلدى ، قايتا سىناڭ.",
    receiptToWhatsapp: "تالون WhatsApp غا يوللانغان",
    thankYouForPatronage: "ھىمايە قىلغانلىقىڭىزغا رەھمەت",
    hereIsYourReceipt: "بۇ سىزنىڭ پۇل تاپشۇرۇش تالونىڭىز",
    errorSendingEmailReceipt:
      "تالوننى ئېلېكترونلۇق خەت ئارقىلىق ئەۋەتىشتە خاتالىق كۆرۈلدى ، قايتا سىناڭ ياكى ياردەم بىلەن ئالاقىلىشىڭ",
    receiptSentToEmail:
      "تالون خېرىدارلارنىڭ ئېلېكترونلۇق خەت ساندۇقىغا ئەۋەتىلگەن",
    invoiceSentToEmail:
      "تالون خېرىدارلارنىڭ ئېلېكترونلۇق خەت ساندۇقىغا ئەۋەتىلگەن",
    invoiceSuccessWhatsappError:
      "تالون مۇۋەپپەقىيەتلىك قۇرۇلدى ، ئەمما WhatsApp غا ئەۋەتىشتە خاتالىق كۆرۈلدى. تالون تىزىملىكىدە قايتا سىناڭ",
    invoiceSuccessfulEmailError:
      "تالون مۇۋەپپەقىيەتلىك قۇرۇلدى ، ئەمما ئېلېكترونلۇق خەت ئەۋەتىشتە خاتالىق كۆرۈلدى. تالون تىزىملىكىدىن قايتا سىناڭ",
    invoiceSentToWhatsapp: "تالون WhatsApp غا يوللانغان",
    hello: "ياخشىمۇسىز",
    pleaseDownloadInvoice: "تالوننى چۈشۈرۈڭ",
    pleaseDownloadReceipt: "تالوننى چۈشۈرۈڭ",
    from: "from",
    email: "ئېلخەت",
    upgrade: "يېڭىلاش",
    youAreOnFreePlan: "سىز ھەقسىز پىلاندا.",
    clickOn: "چېكىڭ",
    yourPlanInFewSteps: " مۇشتەرىلىكىڭىز بىر قانچە تېز باسقۇچتا.",
    to: "to",
    yourSubscriptionHasExpired:
      "مۇشتەرىلىكىڭىزنىڭ ۋاقتى توشتى ، ھېساباتىڭىز ھازىر چەكلىك.",
    days: "كۈنلەر",
    yourSubscriptionExpiresIn: "Loystar مۇشتەرىلىكىڭىزنىڭ ۋاقتى توشىدۇ",
    createAcount: "ھېسابات قۇر",
    signIn: "تىزىملىتىڭ",
    continue: "داۋاملاشتۇر",
    enterOtp: "OTP PIN نى كىرگۈزۈڭ",
    enterValidOtp: "ئىناۋەتلىك PIN نى كىرگۈزۈڭ",
    pin: "PIN",
    tokenSentToMail: "ئېلېكترونلۇق خەت ساندۇقىڭىزغا بىر بەلگە ئەۋەتىلدى",
    passwordResetSuccessful: "پارولنى ئەسلىگە كەلتۈرۈش مۇۋەپپەقىيەتلىك بولدى",
    somethingWentWrong: "چاتاق چىقتى!",
    resetPassword: "پارولنى ئەسلىگە كەلتۈرۈش",
    iHaveResetCode: "مېنىڭ پارولنى ئەسلىگە كەلتۈرۈش كودىم بار",
    pleaseEnterEmail: "ئېلېكترونلۇق خەتنى كىرگۈزۈڭ",
    aTokenWillBeSentToEmail:
      "ئېلېكترونلۇق خەت ساندۇقىڭىزغا بىر بەلگە ئەۋەتىلىدۇ",
    enterEmail: "ئېلېكترونلۇق خەتنى كىرگۈزۈڭ",
    sendinYourToken: "بەلگە ئەۋەتىش ...",
    makeSureItMatchesPassword:
      "يېڭى پارولىڭىزغا ماس كېلىدىغانلىقىنى جەزملەشتۈرۈڭ",
    pleaseChooseNewPassword: "يېڭى پارول تاللاڭ",
    chooseNewPassword: "يېڭى پارول تاللاڭ",
    enterNewPassword: "جەزملەشتۈرۈش ئۈچۈن يېڭى پارولىڭىزنى كىرگۈزۈڭ",
    enterTokenSent: "ئېلېكترونلۇق خەت ئەۋەتىلگەن بەلگە كىرگۈزۈڭ",
    resetToken: "Token نى ئەسلىگە كەلتۈرۈش",
    resettingPassword: "پارولىڭىزنى ئەسلىگە كەلتۈرۈش ...",
    signUp: "تىزىملىتىڭ",
    adminSignInWithEmail:
      " خىزمەتچىلەر ئېلېكترونلۇق خەت بىلەن تىزىملىتىدۇ ، خىزمەتچىلەر ئىشلەتكۈچى ئىسمى بىلەن تىزىملىتىدۇ.",
    pleaseEnterEmailOrUsername:
      "ئېلېكترونلۇق خەت ياكى ئىشلەتكۈچى ئىسمىڭىزنى كىرگۈزۈڭ",
    emailOrUsername: "ئېلېكترونلۇق خەت ياكى ئىشلەتكۈچى ئىسمى",
    pleaseEnterPassword: "پارول كىرگۈزۈڭ",
    createAnAccount: "ھېسابات قۇر",
    forgotPassword: "پارولنى ئۇنتۇپ قالدىڭىزمۇ؟",
    enterPhoneNumber: "تېلېفون نومۇرىنى كىرگۈزۈڭ",
    personalData: "شەخسىي سانلىق مەلۇمات",
    validateConfirmationCOde: "جەزملەشتۈرۈش كودىنى ئىناۋەتلىك قىلىڭ",
    pleaseEnterFirstName: "ئىسمىڭىزنى كىرگۈزۈڭ",
    pleaseEnterPhoneNumber: "تېلېفون نومۇرىڭىزنى كىرگۈزۈڭ",
    pleaseEnterLastName: "فامىلىڭىزنى كىرگۈزۈڭ",
    pleaseEnterBusinessName: "سودا ئىسمىڭىزنى كىرگۈزۈڭ",
    firstName: "بىرىنچى ئىسمى",
    lastName: "فامىلىسى",
    businessName: "سودا ئىسمى",
    previous: "ئالدىنقى",
    next: "كېيىنكى",
    pleaseSelectBusinessCategory: "سودا تۈرىڭىزنى تاللاڭ",
    pleaseEnterValidEmail: "ئىناۋەتلىك ئېلېكترونلۇق خەت كىرگۈزۈڭ",
    pleaseEnterPostCode: "يازما كودىنى كىرگۈزۈڭ",
    postCode: "پوچتا نومۇرى",
    phoneNumberInUse: "بۇ تېلېفون نومۇرى ئاللىقاچان ئىشلىتىلىۋاتىدۇ!",
    emailInUse: "بۇ ئېلېكترونلۇق خەت ئاللىقاچان ئىشلىتىلىۋاتىدۇ!",
    foodAndDrinks: "يېمەكلىك ۋە ئىچىملىك",
    salonAndBeauty: "سالون ۋە گۈزەللىك",
    fashionAndAccessories: "مودا ۋە قوشۇمچە زاپچاسلار",
    gymAndFitness: "چېنىقىش ۋە بەدەن چېنىقتۇرۇش",
    travelAndHotel: "ساياھەت ۋە مېھمانخانا",
    homeAndGifts: "ئۆي ۋە سوۋغاتلار",
    washingAndCleaning: "يۇيۇش ۋە تازىلاش",
    gadgetsAndElectronics: "Gadgets and Electronics",
    groceries: "مىلىچماللار",
    others: "باشقىلار",
    submit: "يوللاڭ",
    accountCreatedSuccessful: "ھېساباتىڭىز مۇۋەپپەقىيەتلىك قۇرۇلدى.",
    pleaseEnterAddress: "ئادرېسىڭىزنى كىرگۈزۈڭ",
    addressLine1: "ئادرېس 1",
    addressLine2: "ئادرېس 2",
    choosePassword: "پارولنى تاللاڭ",
    passwordMustBe6Characters: "پارول كەم دېگەندە 6 ھەرپ بولۇشى كېرەك.",
    howDidYouHearLoystar: "لويىستارنى قانداق ئاڭلىدىڭىز؟",
    referralCode: "يوللانما كودى",
    byClickingTheButton:
      " تۆۋەندىكى كۇنۇپكىنى بېسىش ئارقىلىق ، سىز بۇنىڭغا قوشۇلىسىز",
    termsAndSevice: "شەرتلەر",
    wereCreatingAccount: "ھېساباتىڭىزنى قۇرۇۋاتىمىز",
    proceed: "داۋاملاشتۇرۇڭ",
    verificationCodeMustBe6: "دەلىللەش كودى چوقۇم 6 خانىلىق سان بولۇشى كېرەك",
    pleaseEnter6DigitCode: "6 خانىلىق كودنى كىرگۈزۈڭ",
    enterVerificationCode: "دەلىللەش كودىنى كىرگۈزۈڭ",
    resendToken: "توكەننى قايتۇرۇڭ",
    verify: "دەلىللەڭ",
    transactions: "سودا",
    todaySales: "بۈگۈنكى سېتىش",
    salesHistory: "سېتىش تارىخى",
    supplyHistory: "تەمىنلەش تارىخى",
    new: "يېڭى",
    invoices: "تالون",
    disbursements: "تارقىتىلىش",
    offlineSales: "تورسىز سېتىش",
    products: "مەھسۇلاتلار",
    customers: "خېرىدارلار",
    multiLevelLoyalty: "كۆپ قاتلاملىق ساداقەت",
    loyaltyPrograms: "سادىق پروگراممىلار",
    members: "ئەزالار",
    appStore: "ئەپ دۇكىنى",
    orderMenu: "زاكاز تىزىملىكى",
    settings: "تەڭشەك",
    staffAndBranches: "خىزمەتچىلەر ۋە تارماقلار",
    myAccount: "ھېساباتىم",
    switchToSellMode: "سېتىش ھالىتىگە ئالماشتۇرۇڭ",
    signOut: "چېكىنىش",
    getFreeSubscription: "ھەقسىز مۇشتەرىلىككە ئېرىشىڭ",
    onlyNumbersAllowed: "پەقەت سانلارغا رۇخسەت قىلىنىدۇ",
    yourAccountMustBe10Digits:
      "ھېسابات نومۇرىڭىز چوقۇم 10 خانىلىق سان بولۇشى كېرەك",
    yourBvnMustBe11: "سىزنىڭ BVN چوقۇم 11 خانىلىق سان بولۇشى كېرەك",
    pleaseSelectBank: "بانكىڭىزنى تاللاڭ",
    selectYourBank: "بانكىڭىزنى تاللاڭ",
    enterBankAccountNumber: "بانكا ھېسابات نومۇرىنى كىرگۈزۈڭ",
    enterBvnNumber: "BVN نى كىرگۈزۈڭ",
    connectBankAccount: "بانكا ھېساباتىنى ئۇلاڭ",
    passwordResetSuccessfulAndSignOut:
      "پارولىڭىز مۇۋەپپەقىيەتلىك ئەسلىگە كەلتۈرۈلدى. قايتا كىرىش ئۈچۈن تۆۋەندىكى چېكىنىش كۇنۇپكىسىنى بېسىڭ",
    enterCurrentPassword: "نۆۋەتتىكى پارولنى كىرگۈزۈڭ",
    pleaseEnterCurrentPassword: "نۆۋەتتىكى پارولنى كىرگۈزۈڭ",
    phoneNumber: "تېلېفون نومۇرى",
    sex: "جىنسىي مۇناسىۋەت",
    dateOfBirth: "تۇغۇلغان كۈنى",
    state: "دۆلەت",
    country: "دۆلەت",
    loyaltyId: "ساداقەت كىملىكى",
    createdAt: "At At",
    noOfTransactionsMade: "قىلىنغان سودا سانى",
    yourDownloadWillStart: "چۈشۈرۈش ۋاقتىڭىز بىر دەمدىلا باشلىنىدۇ",
    exportCustomers: "خېرىدارلارنى ئېكسپورت قىلىش",
    youhaveSuccessfullyToppedUp:
      "قىسقا ئۇچۇر بىرلىكىڭىزنى مۇۋەپپەقىيەتلىك ھالدا تولۇقلىدىڭىز.",
    youNowHave: "ھازىر بار",
    smsUnits: "قىسقا ئۇچۇر بىرلىكى",
    enterNumberOfSmsUnits:
      "سىز سېتىۋالماقچى بولغان قىسقا ئۇچۇر سانىنى كىرگۈزۈڭ",
    pleaseEnterANumericValue: "سانلىق قىممەتنى كىرگۈزۈڭ",
    pay: "پۇل تۆلەش",
    accountEditedSuccessfully: "ھېساباتىڭىز مۇۋەپپەقىيەتلىك تەھرىرلەندى.",
    youAeCurrentlyOn: "سىز ھازىر",
    plan: "پىلان",
    userData: "ئىشلەتكۈچى سانلىق مەلۇمات",
    businessData: "BUSINESS DATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "پۇلىڭىزنى تاللاڭ",
    selectYourCurrency: "پۇلىڭىزنى تاللاڭ",
    purchaseMoreSmsUnits:
      "تۆۋەندىكى جەدۋەلنى ئىشلىتىپ تېخىمۇ كۆپ قىسقا ئۇچۇر سېتىۋېلىڭ",
    youHave: "سىزدە بار",
    atLeast4SmsUnitsRrequired:
      "دەلىللەش ئۈچۈن كەم دېگەندە 4 sms بىرلىكى تەلەپ قىلىنىدۇ ، تولۇقلاپ قويۇڭ!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "بانكا ھېساباتىڭىزنى «خېرىدارىڭىزنى بىلىڭ» (KYC) تەلىپىگە ماس كېلىدىغانلىقىنى تەكشۈرۈپ بېقىڭ. بۇ ئارقىلىق USSD ياكى دەرھال يۆتكەش ئارقىلىق پۇل توپلىيالايسىز ، خېرىدارلارنىڭ زاكازلىرىنى تاپشۇرۇۋالىسىز ۋە سودا راسچوتىنى بىر تەرەپ قىلالايسىز. پەقەت نىگېرىيە سودىگەرلىرىگىلا ماس كېلىدۇ. باشلاش ئۈچۈن تۆۋەندىكى كۇنۇپكىنى بېسىڭ.",
    reConnectBankAccount: "بانكا ھېساباتىنى قايتا ئۇلاڭ",
    accountName: "ھېسابات ئىسمى",
    accountNumber: "ھېسابات نومۇرى",
    bankName: "بانكا ئىسمى",
    verified: "دەلىللەندى",
    accountDetails: "ھېسابات تەپسىلاتلىرى",
    kycBankAccount: "KYC",
    createTier: "قاتلام قۇرۇش",
    fileUploadSuccessful: "ھۆججەت مۇۋەپپەقىيەتلىك يۈكلەندى",
    fileUploadFailed: "ھۆججەت يوللاش مەغلۇب بولدى",
    createLoyaltyProgram: "ۋاپادارلىق پروگراممىسى قۇرۇڭ",
    createLoyalty: "ۋاپادارلىق يارىتىڭ",
    name: "ئىسمى",
    loyaltyArtwork: "سادىق سەنئەت ئەسەرلىرى",
    clickToUpload: "يۈكلەش ئۈچۈن چېكىڭ",
    amountToPointsRatio: "نومۇر نىسبىتى",
    points: "نۇقتىلار",
    recommendedAmountToPointRatio:
      "تەۋسىيە: ₦ 1 دىن 1 نۇقتا. يەنى خەجلىگەن ھەر 1 نايرا ئۈچۈن خېرىدارلىرىڭىز 1 نومۇر ئالىدۇ",
    pleaseTypeIn: "كىرگۈزۈڭ",
    toDeleteLoyalty: "بۇ ساداقەتنى ئۆچۈرۈش",
    deleteLoyalty: "ساداقەتنى ئۆچۈرۈڭ",
    toConfirm: "جەزملەشتۈرۈش",
    edit: "تەھرىر",
    pointsAwardedSuccessfully: "نومۇرلار مۇۋەپپەقىيەتلىك مۇكاپاتلاندى.",
    enterPointValueToAward:
      "خېرىدارلارنى مۇكاپاتلىماقچى بولغان نۇقتا قىممىتىنى كىرگۈزۈڭ",
    award: "مۇكاپات",
    awardPointValuesToCustomer: "خېرىدارغا مۇكاپات نۇقتىسى قىممىتى",
    enrollMembersToLoyalty: "ئەزالارنى ساداقەتكە تىزىملىتىڭ",
    awardPoints: "مۇكاپات نومۇرى",
    enroll: "تىزىملىتىڭ",
    home: "ئۆي",
    loyalty: "ساداقەت",
    enrollCustomers: "خېرىدارلارنى تىزىملىتىڭ",
    selected: "تاللانغان",
    customer: "خېرىدار",
    loyaltyActivationSuccessful:
      "ساداقەتمەنلىك پائالىيىتى مۇۋەپپەقىيەتلىك بولدى.",
    loyaltyDeactivationSuccessful:
      "ساداقەتنى ئەمەلدىن قالدۇرۇش مۇۋەپپەقىيەتلىك بولىدۇ.",
    viewTier: "قاتلامنى كۆرۈش",
    deactivate: "ئاكتىپلاش",
    activate: "ئاكتىپلاش",
    actions: "ھەرىكەتلەر",
    nameOfLoyalty: "ساداقەتنىڭ ئىسمى",
    loyaltyStatus: "ساداقەت ھالىتى ساداقەت ھالىتى",
    numberOfTiers: "دەرىجە سانى",
    createdOn: "قۇرۇلدى",
    createATier: "بىر دەرىجە قۇر",
    stopCreatingTierConfirmation: "قاتلام قۇرۇشنى توختاتامسىز؟",
    stopEditingTierConfirmation: "بۇ قاتلامنى تەھرىرلەشنى توختاتامسىز؟",
    nameOfTier: "قاتلامنىڭ ئىسمى",
    minimumSpendingTarget: "ئەڭ تۆۋەن چىقىم نىشانى",
    maximumSpendingTarget: "ئەڭ چوڭ چىقىم نىشانى",
    minimumSpendingTargetRequired: "ئەڭ تۆۋەن چىقىم نىشانى تەلەپ قىلىنىدۇ",
    maximumSpendingTargetRequired: "ئەڭ يۇقىرى چىقىم نىشانى تەلەپ قىلىنىدۇ",
    rewardSponsor: "مۇكاپاتقا ئېرىشكۈچى",
    pleaseChooseARewardSponsor: "مۇكاپات ياردەم بەرگۈچىنى تاللاڭ",
    self: "Self",
    partner: "ھەمكارلاشقۇچى",
    rewardPartner: "مۇكاپاتلاش ھەمراھى",
    pleaseSelectRewardPartner: "مۇكاپات ھەمراھىڭىزنى تاللاڭ",
    rewards: "مۇكاپات",
    pleaseSelectAReward: "مۇكاپاتنى تاللاڭ",
    instructionsOnRedeemingReward:
      "خېرىدارلارنىڭ مۇكاپاتنى قانداق قايتۇرۇشى توغرىسىدىكى كۆرسەتمە",
    pleaseFillInThisField: "بۇ يەرنى تولدۇرۇڭ!",
    toDeleteThisTier: " بۇ دەرىجىنى ئۆچۈرۈش",
    deleteTier: "قاتلامنى ئۆچۈرۈڭ",
    viewMembers: "ئەزالارنى كۆرۈش",
    membersEnrolled: "ئەزالار تىزىملاتتى",
    instruction: "كۆرسەتمە",
    membersIn: "ئەزالار",
    availableTiersInLoyalty:
      "ساداقەت پروگراممىسىدىكى ئىشلەتكىلى بولىدىغان قاتلاملار",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "ئىشلەتكىلى بولىدىغان ساداقەت پروگراممىسى",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "ئەزا تەپسىلاتلىرى",
    nameOfCustomer: "خېرىدارنىڭ ئىسمى",
    address: "ئادرېس",
    allEnrolledMembers: "بارلىق تىزىملاتقان ئەزالار",
    thisIsToWishYouHappyBirthday:
      "بۇ سىزنىڭ تۇغۇلغان كۈنىڭىزگە مۇبارەك ، بەختلىك تۇرمۇش تىلەيمەن. لويىستېرغا كەلگەنلىكىڭىزگە رەھمەت. تەبرىكلەيمەن!",
    inputAnOfferPlease: "تەكلىپنى كىرگۈزۈڭ",
    pleaseSelectTheInsertPoint:
      "ئۇچۇردىكى قىستۇرۇش نۇقتىسىنى تاللاڭ ۋە قايتا چېكىڭ",
    birthdayOfferAndMessage: "تۇغۇلغان كۈن سوۋغىسى ۋە ئۇچۇر",
    birthdayOffer: "تۇغۇلغان كۈن سوۋغىسى",
    birthdayMessage: "تۇغۇلغان كۈنى ئۇچۇرى",
    noOfferFound: "ھېچقانداق تەكلىپ تېپىلمىدى",
    settingABirthdayOffer:
      "تۇغۇلغان كۈن تەكلىپىنى تەڭشەش خېرىدارلارنىڭ تۇغۇلغان كۈنىدە قىسقا ئۇچۇر ئارقىلىق بۇ تەكلىپنى قوبۇل قىلالايدۇ",
    createOffer: "تەكلىپ بەر",
    whatIsTheOffer: "تەكلىپ نېمە؟",
    editMessage: "ئۇچۇرنى تەھرىرلەش",
    insert: "قىستۇر",
    theNameOfCustomerInserted: "بۇ يەرگە خېرىدارنىڭ ئىسمى قىستۇرۇلىدۇ",
    theBirtdayOfferInserted: "تۇغۇلغان كۈن تەكلىپى بۇ يەرگە قىستۇرۇلىدۇ",
    youSuccessfullyAddedNewBranch:
      "مۇۋەپپەقىيەتلىك ھالدا يېڭى تارماق قوشتىڭىز!",
    addNewBranch: "يېڭى شاخ قوشۇڭ",
    addBranch: "شاخ قوشۇڭ",
    additionalBranchWillIncur: "قوشۇمچە شۆبە",
    perBranch: "ھەر بىر شاخ",
    ecommerceBranchCosts: "سودا شۆبىسىنىڭ خىراجىتى",
    pleaseEnterBranchName: "تارماق نامىنى كىرگۈزۈڭ",
    pleaseEnterBranchAddress1: "تارماقنىڭ ئادرېس لىنىيىسىنى كىرگۈزۈڭ",
    enterBranchAddress1: "تارماقنىڭ ئادرېس لىنىيىسىنى كىرگۈزۈڭ",
    enterBranchAddress2: "تارماقنىڭ ئادرېس لىنىيىسىنى كىرگۈزۈڭ",
    pleaseEnterBranchAddress2: "تارماقنىڭ ئادرېس 2-لىنىيىسىنى كىرگۈزۈڭ",
    enterBranchName: "شاخ نامىنى كىرگۈزۈڭ",
    successfullyAddedStaff: "مۇۋەپپەقىيەتلىك ھالدا يېڭى خىزمەتچى قوشتىڭىز!",
    addNewStaff: "يېڭى خادىم قوشۇڭ",
    addStaff: "خىزمەتچى قوشۇڭ",
    additionalStaffWillIncur: "قوشۇمچە خىزمەتچىلەر ئۈستىگە ئالىدۇ",
    perStaff: "ھەر بىر خىزمەتچى.",
    pleaseEnterStaffEmail: "خىزمەتچىلەرنىڭ ئېلېكترونلۇق خەت ساندۇقىنى كىرگۈزۈڭ",
    pleaseEnterStaffUsername: "خىزمەتچىلەرنىڭ ئىشلەتكۈچى نامىنى كىرگۈزۈڭ",
    pleaseEnterStaffPassword: "خىزمەتچىلەرنىڭ پارولىنى كىرگۈزۈڭ",
    pleaseSelectStaffRole: "خىزمەتچىلەرنىڭ رولىنى تاللاڭ",
    selectStaffRole: "خىزمەتچىلەرنىڭ رولىنى تاللاڭ",
    enterStaffEmail: "خىزمەتچىلەرنىڭ ئېلېكترونلۇق خەت ساندۇقىنى كىرگۈزۈڭ",
    enterStaffUsername: "خىزمەتچىلەرنىڭ ئىشلەتكۈچى نامىنى كىرگۈزۈڭ",
    enterStaffPassword: "خىزمەتچىلەرنىڭ پارولىنى كىرگۈزۈڭ",
    spacesNotAllowedInUsername: "ئىشلەتكۈچى نامىدا رۇخسەت قىلىنمايدۇ",
    admin: "باشقۇرغۇچى",
    pleaseSelectBusinessToAttachStaff:
      "خىزمەتچىلەرنى باغلاش ئۈچۈن كەسىپ تاللاڭ",
    searchForBranchToAttachStaff: "خىزمەتچىلەرنى باغلاش ئۈچۈن تارماق ئىزدەڭ",
    username: "ئىشلەتكۈچى ئىسمى",
    role: "رولى",
    areYouSureToDeleteThis: "بۇنى ئۆچۈرەمسىز؟",
    branches: "شاخلىتىپ سېتىش",
    upgradeYourPlan: "پىلانىڭىزنى يېڭىلاڭ.",
    add: "ADD",
    addNew: "يېڭى قوشۇڭ",
    customerWithEmailAlreadyExists:
      "ئېلېكترونلۇق خەت / تېلېفون نومۇرى بار خېرىدار ئاللىبۇرۇن مەۋجۇت!",
    successfullyAddedNewCustomer:
      "مۇۋەپپەقىيەتلىك ھالدا يېڭى خېرىدار قوشتىڭىز!",
    addCustomer: "خېرىدار قوشۇڭ",
    pleaseEnterCustomerFirstName: "خېرىدارنىڭ ئىسمىنى كىرگۈزۈڭ",
    pleaseEnterCustomerLastName: "خېرىدارنىڭ فامىلىسىنى كىرگۈزۈڭ",
    pleaseEnterCustomerPhoneNumber: "خېرىدارلارنىڭ تېلېفون نومۇرىنى كىرگۈزۈڭ",
    pleaseEnterCustomerEmail: "خېرىدارلارنىڭ ئېلېكترونلۇق خەتلىرىنى كىرگۈزۈڭ",
    pleaseEnterCustomerAddressLine: "خېرىدارلارنىڭ ئادرېس لىنىيىسىنى كىرگۈزۈڭ",
    pleaseEnterCustomerOtherAddress: "خېرىدارنىڭ باشقا ئادرېسىنى كىرگۈزۈڭ",
    pleaseSelectCustomerGender: "خېرىدارلارنىڭ جىنسىنى تاللاڭ",
    gender: "جىنىس",
    male: "ئەر",
    female: "ئايال",
    bank: "Bank",
    selectBank: "بانكىنى تاللاڭ",
    stateOrRegionOrProvince: "شىتات / رايون / ئۆلكە",
    customerNotes: "خېرىدار خاتىرىسى",
    sendSms: "قىسقا ئۇچۇر يوللاڭ",
    editCustomer: "خېرىدارنى تەھرىرلەش",
    redeemReward: "مۇكاپاتنى قايتۇرۇڭ",
    issueLoyaltyCard: "سەمىمىيەت كارتىسى تارقىتىش",
    deleteCustomer: "خېرىدارنى ئۆچۈرۈڭ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "سىز ساداقەتمەنلىك سالاھىيەت كىملىكىنى مۇۋەپپەقىيەتلىك تەقسىم قىلدىڭىز",
    noMembershipIdAvailable:
      "ئەزالىق كىملىكى يوق. Hello@loystar.co بىلەن بىز بىلەن ئالاقىلىشىڭ",
    sendEmail: "ئېلېكترونلۇق خەت ئەۋەتىڭ",
    membershipPoints: "ئەزالىق نۇقتىلىرى",
    customerDetails: "خېرىدار تەپسىلاتلىرى",
    close: "تاقاش",
    loyaltyBalance: "ساداقەت تەڭپۇڭلۇقى",
    pointsBalance: "نۇقتا تەڭپۇڭلۇقى",
    starBalance: "Star Balance",
    requiredPoints: "لازىملىق نۇقتىلار",
    requiredStars: "لازىملىق چولپانلار",
    reddemCode: "Redeem Code",
    toDeleteThisCustomer: "بۇ خېرىدارنى ئۆچۈرۈش",
    customerHasBeenDeletedSuccessfully: "خېرىدار مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى!",
    customerWithPhoneAlreadyExists:
      "تېلېفون نومۇرى بار خېرىدار ئاللىبۇرۇن مەۋجۇت",
    customerWasSuccessfullyEdited: "خېرىدار مۇۋەپپەقىيەتلىك تەھرىرلەندى",
    anErrorOccured: "خاتالىق كۆرۈلدى",
    phoneNumberIncludeCountry:
      "تېلېفون نومۇرى (دۆلەت كودىنى ئۆز ئىچىگە ئالىدۇ)",
    yourFileQueuedForUpload:
      "ھۆججىتىڭىز يوللاش ئۈچۈن ئۆچرەتتە تۇردى. بىر نەچچە سېكۇنتتىن كېيىن بەتنى يېڭىلاڭ.",
    thereWasAnErrorPerformingOperation:
      "مەشغۇلاتنى ئىجرا قىلىشتا خاتالىق كۆرۈلدى!",
    pleaseSelectFile: "ھۆججەت تاللاڭ!",
    oopsOnlyCsvFilesAllowed:
      "ئاپلا! پەقەت CSV ھۆججىتىلا رۇخسەت قىلىنغان. .Csv ھۆججىتىنى يۈكلەڭ.",
    fileShouldBeSmallerThan5Mb:
      "ھۆججەت 5MB دىن كىچىك بولۇشى كېرەك. ئەگەر تېخىمۇ چوڭ ھۆججىتىڭىز بولسا support@loystar.co غا ئېلخەت يوللاڭ. رەھمەت سىزگە",
    customerFirstNameIsRequired:
      "خېرىدارلارنىڭ ئىسمى بىرىنچى قاتاردا تەلەپ قىلىنىدۇ",
    customerPhoneNumberIsRequired:
      "خېرىدارلارنىڭ تېلېفون نومۇرى رەت-رەت تەلەپ قىلىنىدۇ",
    importCustomers: "خېرىدارلارنى ئىمپورت قىلىش",
    upload: "يۈكلەش",
    clickIconToDownloadCsv: "بۇ سىنبەلگىنى چېكىپ CSV ھۆججەت قېلىپىنى چۈشۈرۈڭ.",
    getGoogleSheetVersion: "Google جەدۋەل نۇسخىسىنى ئېلىڭ",
    clickOrDragFileToUpload:
      "يۈكلەش ئۈچۈن ھۆججەتنى بۇ رايونغا چېكىڭ ياكى سۆرەپ كىرىڭ",
    missingOrInvalidColumnHeader:
      "يوقاپ كەتكەن ياكى ئىناۋەتسىز ئىستون. قېلىپ فورماتىغا ئەگىشىڭ. رەھمەت سىزگە.",
    youHaveImported: "سىز ئىمپورت قىلدىڭىز",
    youSuccessfullyRedeemedReward:
      "مۇكاپاتىڭىزنى مۇۋەپپەقىيەتلىك ھالدا قايتۇرۇۋالدىڭىز!",
    sixDigitCode: "ئالتە خانىلىق كود",
    pleaseEnterCustomerRewardCode: "خېرىدارلارنىڭ مۇكاپات كودىنى كىرگۈزۈڭ",
    enterRewardCode: "مۇكاپات كودىنى كىرگۈزۈڭ. (مۇكاپات كودى چوڭ سەزگۈر)",
    selectLoyaltyProgram: "ساداقەت پروگراممىسىنى تاللاڭ",
    stamps: "ماركا",
    smsUnitsLow: "قىسقا ئۇچۇر بىرلىكى تۆۋەن",
    pleaseTopUpSmsUnits: "قىسقا ئۇچۇر ئورۇنلىرىنى تولۇقلاڭ",
    smsSuccessfullySentForDelivery: "قىسقا ئۇچۇر يەتكۈزۈشكە ئەۋەتىلدى!",
    sendSmsTo: "قىسقا ئۇچۇر يوللاڭ",
    allCustomers: "بارلىق خېرىدارلار",
    unitsAvailable: "Units Available",
    pleaseTypeInTheMessage: "ئۇچۇرنى كىرگۈزۈڭ",
    message: "ئۇچۇر",
    charactersRemaining: "ھەرپلەر قالدى",
    avoidUseOfSpecialCharacters:
      "قىسقا ھەرپلەرنى ساقلاش ئۈچۈن ئالاھىدە ھەرپ ۋە Emojis ئىشلىتىشتىن ساقلىنىڭ.",
    note: "دىققەت",
    errorFetchingCustomersMultilevelDetail:
      "خېرىدارلارنىڭ كۆپ قاتلاملىق تەپسىلاتلىرىنى تېپىشتىكى خاتالىق",
    search: "ئىزدەش",
    reset: "ئەسلىگە قايتۇرۇش",
    importCustomer: "خېرىدارنى ئەكىرىش",
    addNewCustomer: "يېڭى خېرىدار قوشۇڭ",
    sendSmsBroadcast: "قىسقا ئۇچۇر يوللاڭ",
    totalCustomers: "TOTAL CUSTOMERS",
    disbursementDetails: "تارقىتىش تەپسىلاتلىرى",
    paidBy: "Paid By",
    disbursed: "تارقىتىلدى",
    bankAccountName: "بانكا ھېسابات ئىسمى",
    bankAccountNumber: "بانكا ھېسابات نومۇرى",
    transferInitiated: "يۆتكەش باشلاندى",
    transferCompleted: "يۆتكەش تاماملاندى",
    pleaseEnterAValid: "ئىناۋەتلىك كىرگۈزۈڭ",
    begin: "باشلاش",
    end: "end",
    date: "چېسلا",
    paymentDate: "پۇل تۆلەش ۋاقتى",
    selectDisbursementDuration: "تارقىتىش مۇددىتىنى تاللاڭ",
    totalSettled: "ئومۇمىي ھەل قىلىندى",
    totalUnsettled: "ئومۇمىي مۇقىمسىز",
    toDeleteThisSale: "بۇ سېتىشنى ئۆچۈرۈش",
    draftSaleDeletedSuccessfully: "لايىھە سېتىش مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى!",
    deleteSale: "سېتىشنى ئۆچۈرۈڭ",
    pleaseTypeInYourTotal:
      "ئۆچۈرۈۋېتىشنى جەزملەشتۈرۈش ئۈچۈن جەمىيىتىڭىزنى كىرگۈزۈڭ",
    billDetails: "Bill Details",
    printBill: "Print Bill",
    servedBy: "مۇلازىمەت قىلدى",
    total: "ئومۇمىي",
    createdDate: "قۇرۇلغان چېسلا",
    createdTime: "يارىتىلغان ۋاقىت",
    status: "ھالەت",
    loginSuccessful: "كىرىش مۇۋەپپەقىيەتلىك",
    pleaseWaitWhileWeConnectAccount: "ھېساباتىڭىزنى ئۇلىغاندا ساقلاپ تۇرۇڭ",
    connectionFailedTryAgain: "ئۇلىنىش مەغلۇپ بولدى. قايتا سىناڭ.",
    connectionSuccessful: "ئۇلىنىش مۇۋەپپەقىيەتلىك",
    viewDetails: "تەپسىلاتلارنى كۆرۈش",
    enable: "قوزغىتىش",
    free: "ھەقسىز",
    cost: "تەننەرخى",
    visitWebsite: "توربېكەتنى زىيارەت قىلىڭ",
    pleaseUpgradeYourPlanToPro:
      "بۇ ئەپنى قوزغىتىش ئۈچۈن پىلانىڭىزنى Pro Plus غا يېڭىلاڭ",
    connectYourBankAccount:
      "بانكا ھېساباتىڭىزنى ئۇلاپ ، پۇل تاپشۇرۇۋالالايسىز.",
    disable: "چەكلەش",
    enableApp: "ئەپنى قوزغىتىش",
    addNewProductToInvoice: "تالونغا يېڭى مەھسۇلات قوشۇڭ",
    toDeleteThisInvoice: "بۇ تالوننى ئۆچۈرۈش",
    invoiceDeletedSuccessfully: "تالون مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى!",
    deleteInvoice: "تالوننى ئۆچۈرۈڭ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "سىز تالون چىقىم قىلىش ئۇچۇرىنى مۇۋەپپەقىيەتلىك قوشتىڭىز!",
    pleaseEnterPaymentInstructions: "پۇل تۆلەش كۆرسەتمىسىنى كىرگۈزۈڭ",
    invoiceId: "تالون كىملىكى",
    paidDate: "ھەقلىق ۋاقىت",
    reference: "پايدىلانما",
    productAdded: "مەھسۇلات قوشۇلدى",
    productOutOfStock: "مەھسۇلاتنىڭ سىرتىدا. قايتا زاپاسلاڭ.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "TOTAL PAID INVOICES",
    totalUnpaidInvoices: "TOTAL UNPAID INVOICES",
    loyaltyProgramDeleted: "ساداقەت پروگراممىسى ئۆچۈرۈلدى",
    thisLoyaltyProgramDeletedSuccessfully:
      "بۇ ساداقەت پروگراممىسى مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى",
    thisLoyaltyProgramEditedSuccessfully:
      "بۇ ساداقەتمەنلىك پروگراممىسى مۇۋەپپەقىيەتلىك تەھرىرلەندى",
    loyaltyProgramAddedSuccessfully:
      "ساداقەتمەنلىك پروگراممىسى مۇۋەپپەقىيەتلىك قوشۇلدى",
    loyaltyProgramEdited: "ساداقەت پروگراممىسى تەھرىرلەندى",
    loyaltyProgramAdded: "ساداقەت پروگراممىسى قوشۇلدى",
    loyaltyDetails: "ساداقەت تەپسىلاتلىرى",
    doYouWantToCloseDialog: "بۇ دىئالوگنى ياپماقچىمۇ؟",
    pleaseEnterLoyaltyName: "ساداقەتمەنلىكىڭىزنىڭ نامىنى كىرگۈزۈڭ",
    programType: "پروگرامما تىپى",
    pleaseSelectProgramType: "پروگرامما تىپىنى تاللاڭ",
    simplePoints: "ئاددىي نۇقتىلار",
    stampsProgram: "ماركا پروگراممىسى",
    threshold: "Threshold",
    pleaseEnterLoyaltyThreshold: "ساداقەت چېكىگە كىرىڭ",
    reward: "مۇكاپات",
    pleaseEnterLoyaltyReward: "ساداقەتنىڭ مۇكاپاتىنى كىرگۈزۈڭ",
    totalUserPoints: "ئومۇمىي ئىشلەتكۈچى نۇقتىلىرى",
    totalUserStamps: "ئومۇمىي ئىشلەتكۈچى تامغىسى",
    spendingTarget: "خىراجەت نىشانى",
    spendingTargetHint1:
      "خىراجەت نىشانى خېرىدارنىڭ مۇكاپاتقا ئېرىشىش ئۈچۈن قانچىلىك پۇل خەجلىشى كېرەك. 1 پۇل قىممىتى = 1 نۇقتا.",
    spendingTargetHint2:
      "ماركا نىشانى خېرىدار مۇكاپاتقا ئېرىشىش ئۈچۈن قانچىلىك ماركا توپلىشى كېرەك. مەسىلەن. 5",
    addNewLoyaltyProgram: "يېڭى ساداقەت پروگراممىسىنى قوشۇڭ",
    addLoyaltyProgram: "ساداقەت پروگراممىسىنى قوشۇڭ",
    loyaltyProgramType: "ساداقەت پروگراممىسى تۈرى",
    pleaseSelectLoyaltyProgramType: "سادىق پروگرامما تۈرىنى تاللاڭ",
    nameOfProgram: "پروگراممىنىڭ ئىسمى",
    pleaseEnterProgramName: "پروگرامما نامىنى كىرگۈزۈڭ",
    whatIsTheReward: "مۇكاپات نېمە؟",
    egNextPurchaseIsFree: "مەسىلەن: كېيىنكى سېتىۋېلىش ھەقسىز",
    customerName: "خېرىدار ئىسمى",
    guestCustomer: "مېھمان خېرىدار",
    orderConfirmedSuccessfully: "زاكاز مۇۋەپپەقىيەتلىك ئىسپاتلاندى",
    orderCancelledSuccessfully: "زاكاز مۇۋەپپەقىيەتلىك ئەمەلدىن قالدۇرۇلدى",
    confirmOrder: "تەرتىپنى جەزملەشتۈرۈڭ",
    cancelOrder: "زاكازنى بىكار قىلىش",
    allOrders: "بارلىق بۇيرۇقلار",
    totalOrders: "TOTAL ORDERS",
    doYouWantToAcceptOrder: "بۇ بۇيرۇقنى قوبۇل قىلامسىز؟",
    doYouWantToCancelOrder: "بۇ بۇيرۇقنى بىكار قىلماقچىمۇ؟",
    orderDetails: "زاكاز تەپسىلاتلىرى",
    orderCreatedAt: "زاكاز قۇرۇلدى",
    supplier: "تەمىنلىگۈچى",
    productName: "مەھسۇلات ئىسمى",
    quantity: "سانى",
    unitPrice: "بىرلىك باھاسى",
    receivedBy: "قوبۇل قىلىنغان",
    reportFrom: "دوكلات",
    totalSupplies: "ئومۇمىي تەمىنات",
    allRightsReserved: "All Rights Reserved",
    toDeleteThisTransaction: "بۇ سودىنى ئۆچۈرۈش",
    transactionDeletedSuccessfully:
      "سودا مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى. پاي چېكى ئامبارغا قايتۇرۇلدى.",
    deleteTransaction: "سودىنى ئۆچۈرۈڭ",
    transactionDetails: "سودا تەپسىلاتلىرى",
    printReceipt: "بېسىش تالونى",
    channel: "Channel",
    discount: "ئېتىبار",
    profit: "پايدا",
    discountedSales: "ئېتىبار باھادا سېتىش",
    errorFetchingRecord: "خاتالىق خاتىرىلەش",
    exportTransactions: "ئېكىسپورت سودىسى",
    errorFetchingSalesRecord: "ئېكىسپورتنىڭ سېتىش خاتىرىسىنى ئېلىشتا خاتالىق.",
    totalSellingPrice: "ئومۇمىي سېتىلىش باھاسى",
    totalCostPrice: "ئومۇمىي تەننەرخ باھاسى",
    appliedDiscount: "قوللىنىشچان ئېتىبار",
    noOfItems: "تۈرلەرنىڭ نومۇرى",
    sales: "سېتىش",
    export: "ئېكسپورت",
    totalProfit: "ئومۇمىي پايدا",
    totalBalanceInPeriod: "دەۋردىكى ئومۇمىي تەڭپۇڭلۇق",
    allTimeSales: "بارلىق ۋاقىت سېتىش",
    records: "خاتىرىلەر",
    todaysSales: "بۈگۈنكى سېتىش",
    transaction: "سودا",
    youHaveExceededTotalNumberOfProducts:
      "پىلانىڭىزدا رۇخسەت قىلىنغان مەھسۇلاتلارنىڭ ئومۇمىي سانىدىن ئېشىپ كەتتىڭىز. تېخىمۇ يۇقىرى چەكتىن بەھرىمەن بولۇش پىلانىڭىزنى يېڭىلاڭ.",
    youNeedToHaveLoyaltyProgram:
      "بۇ ئىقتىدارنى ئىشلىتىش ئۈچۈن ساداقەتمەنلىك پروگراممىسى بولۇشىڭىز كېرەك!",
    price: "باھاسى",
    category: "سەھىپە",
    stockTracking: "پاي چېكى ئىز قوغلاش",
    stockCount: "پاي سانى",
    taxed: "باج ئېلىندى",
    originalPrice: "ئەسلى باھاسى",
    costPrice: "تەننەرخ باھاسى",
    unit: "بىرلىك",
    productImage: "مەھسۇلات رەسىمى",
    taxRate: "باج نىسبىتى",
    taxType: "باج تىپى",
    trackInventory: "ئىز قوغلاش",
    variants: "Variants",
    hasVariants: "ۋارىيانتلىرى بار",
    importProduct: "مەھسۇلات ئەكىرىش",
    exportProducts: "مەھسۇلاتلارنى ئېكسپورت قىلىش",
    addNewProduct: "يېڭى مەھسۇلات قوشۇڭ",
    viewCategory: "سەھىپىنى كۆرۈش",
    viewSuppliers: "تەمىنلىگۈچىلەرنى كۆرۈش",
    receiveInventory: "ئامبارنى قوبۇل قىلىڭ",
    printAllProductsTag: "بارلىق مەھسۇلات خەتكۈچىنى بېسىڭ",
    deleteAll: "ھەممىنى ئۆچۈرۈڭ",
    totalProducts: "TOTAL PRODUCTS",
    youveSuccessfullyAddedANewCategory:
      "مۇۋەپپەقىيەتلىك ھالدا يېڭى سەھىپە قوشتىڭىز",
    addNewCategory: "يېڭى سەھىپە قوشۇڭ",
    addCategory: "سەھىپە قوشۇڭ",
    categoryName: "تۈر ئىسمى",
    pleaseEnterCategoryName: "سەھىپە نامىنى كىرگۈزۈڭ",
    stampsTarget: "ماركا نىشانى",
    sendInventory: "ئامبار ئەۋەتىش",
    productDetails: "مەھسۇلات تەپسىلاتلىرى",
    youveSuccessfullyEditedThisCategory:
      "بۇ تۈرنى مۇۋەپپەقىيەتلىك تەھرىرلىدىڭىز",
    update: "يېڭىلاش",
    categoryList: "سەھىپە تىزىملىكى",
    categories: "سەھىپىلەر",
    enterQuantityToUpdate: "يېڭىلاش ئۈچۈن مىقدار كىرگۈزۈڭ",
    inventorySentSuccessfully: "ئامبار مۇۋەپپەقىيەتلىك ئەۋەتىلدى!",
    updateInventory: "ئامبارنى يېڭىلاش",
    currentQuantity: "نۆۋەتتىكى سانى",
    pleaseEnterQuantityToAdd: "قوشماقچى بولغان مىقدارنى كىرگۈزۈڭ",
    pleaseSelectABranch: "شۆبە تاللاڭ",
    searchForBranch: "شاخ ئىزدەڭ",
    youCantSendMoreThanStock: "پاي چېكىڭىزدىن كۆپ ئەۋەتەلمەيسىز",
    send: "ئەۋەتىڭ",
    pleaseEnterQuantityToSend: "ئەۋەتمەكچى بولغان مىقدارنى كىرگۈزۈڭ",
    productNameIsRequiredOnRow: "مەھسۇلاتنىڭ ئىسمى رەت بويىچە تەلەپ قىلىنىدۇ",
    productCategoryIsRequiredOnRow: "مەھسۇلات تۈرى قاتاردا تەلەپ قىلىنىدۇ",
    productPriceIsRequiredOnRow: "مەھسۇلات باھاسى رەت-رەت تەلەپ قىلىنىدۇ",
    productUnitIsRequiredOnRow: "مەھسۇلات بىرلىكى تەلەپ قىلىنىدۇ",
    productQuantityIsRequiredOnRow: "مەھسۇلات مىقدارى رەت-رەت تەلەپ قىلىنىدۇ",
    productVariantsRequireTracking:
      "مەھسۇلاتنىڭ ۋارىيانتلىرى ئىز قوغلاشنى تەلەپ قىلىدۇ!",
    pleaseAddVariantClickButton:
      "قوشۇش كۇنۇپكىسىنى بېسىش ئارقىلىق ۋارىيانت قوشۇڭ!",
    totalVariantsMoreThanSelectedQuantity:
      "ئومۇمىي ئۆزگەرگۈچى مىقدار تاللانغان مەھسۇلات مىقدارىدىن كۆپ ، Pls ئۆزگىرىشچان مىقدارنى ئازايتىدۇ",
    productEditedSuccessfully: "مەھسۇلات مۇۋەپپەقىيەتلىك تەھرىرلەندى!",
    fileTooLargeLessThan4Mb:
      "ھۆججەتنىڭ ھەجىمى بەك چوڭ! ھۆججەتنىڭ سىغىمى 4MB دىن تۆۋەن بولۇشى كېرەك.",
    suchVariantAlreadyExist: "بۇ خىل ۋارىيانت ئاللىبۇرۇن مەۋجۇت",
    addVariants: "ۋارىيانتلارنى قوشۇڭ",
    editProduct: "مەھسۇلاتنى تەھرىرلەش",
    pleaseEnterProductName: "مەھسۇلات نامىنى كىرگۈزۈڭ",
    pleaseEnterProductPrice: "مەھسۇلات باھاسىنى كىرگۈزۈڭ",
    pleaseEnterProductOriginalPrice: "مەھسۇلاتنىڭ ئەسلى باھاسىنى كىرگۈزۈڭ",
    productDescription: "مەھسۇلات چۈشەندۈرۈشى",
    selectProductCategory: "مەھسۇلات تۈرىنى تاللاڭ",
    pleaseSelectProductCategory: "مەھسۇلات تۈرىنى تاللاڭ",
    productCostPrice: "مەھسۇلات باھاسى",
    productSellingPrice: "مەھسۇلات سېتىش باھاسى",
    productOriginalPrice: "مەھسۇلاتنىڭ ئەسلى باھاسى",
    maxFileSizeAllowedIs4Mb: "Max. ھۆججەتنىڭ چوڭلۇقى 4mb",
    productsWithPicturesArePublished:
      "رەسىملەر بار مەھسۇلاتلار زاكاز قوبۇل قىلىش ئۈچۈن بايقاشتا ئېلان قىلىنغان",
    shouldThisProductBeTracked: "بۇ مەھسۇلاتنى ئىز قوغلاش كېرەكمۇ؟",
    pleaseSelectStockUnit: "پاي چېكى بىرلىكىنى تاللاڭ",
    stockUnit: "پاي چېكى بىرلىكى",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "CAN",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "PLATE",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "مەھسۇلات مىقدارىنى كىرگۈزۈڭ",
    productQuantity: "مەھسۇلات مىقدارى",
    isThisProductTaxed: "بۇ مەھسۇلاتقا باج ئېلىنامدۇ؟",
    selectTaxType: "باج تۈرىنى تاللاڭ",
    pleaseSelectTaxType: "باج تۈرىنى تاللاڭ",
    progressive: "ئىلگىرلەش",
    proportional: "نىسبەت",
    pleaseEnterProductTaxRate: "مەھسۇلات بېجى نىسبىتىنى كىرگۈزۈڭ",
    doesProductHaveVariants: "بۇ مەھسۇلاتنىڭ ۋارىيانتلىرى بارمۇ؟",
    type: "تىپ",
    value: "قىممەت",
    pleaseEnterVariantType: "ئۆزگەرگۈچى تىپنى كىرگۈزۈڭ",
    pleaseEnterVariantValue: "ئۆزگەرگۈچى مىقدارنىڭ قىممىتىنى كىرگۈزۈڭ",
    pleaseEnterVariantPrice: "ئۆزگەرگۈچى مىقدارنىڭ باھاسىنى كىرگۈزۈڭ",
    pleaseEnterVariantQuantity: "ئۆزگەرگۈچى مىقدارنى كىرگۈزۈڭ",
    productDeletedSuccessfully: "مەھسۇلات مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى!",
    categoryDeletedSuccessfully: "سەھىپە مۇۋەپپەقىيەتلىك ئۆچۈرۈلدى!",
    toDeleteThisProduct: "بۇ مەھسۇلاتنى ئۆچۈرۈش",
    invalidProductQuantity: "مەھسۇلات مىقدارى ئىناۋەتسىز",
    quantityAddedIsOverStock:
      "سىز قوشۇۋاتقان مىقدار پاي چېكىڭىزدىن ئېشىپ كەتتى.",
    itemInventoryNotTracked: "تۈر ئامبىرى ئىز قوغلانمايدۇ",
    addBulkQuantity: "توپ سانىنى قوشۇڭ",
    enterBulkQuantity: "كۆپ ساننى كىرگۈزۈڭ",
    pleaseEnterBulkQuantity: "توپ سانىنى كىرگۈزۈڭ",
    youveSuccessfullyAddedANewProduct:
      "مۇۋەپپەقىيەتلىك ھالدا يېڭى مەھسۇلات قوشتىڭىز!",
    pleaseEnterProductSellingPrice: "مەھسۇلات سېتىش باھاسىنى كىرگۈزۈڭ",
    doYouWantToTrackProductStock: "مەھسۇلات زاپىسىنى ئىز قوغلاشنى خالامسىز؟",
    sellingPrice: "سېتىش باھاسى",
    setProductExpiryReminder: "مەھسۇلاتنىڭ مۇددىتى ئەسكەرتىش",
    productExpiryDate: "مەھسۇلاتنىڭ ۋاقتى",
    startRemindingFrom: "ئەسكەرتىشنى باشلاڭ",
    productSuppliesAddedSuccessfully:
      "مەھسۇلات تەمىناتىنى مۇۋەپپەقىيەتلىك قوشتىڭىز.",
    addProductSupplies: "مەھسۇلات تەمىناتىنى قوشۇڭ",
    pleaseSelectSupplier: "تەمىنلىگۈچىنى تاللاڭ",
    nameOfProduct: "مەھسۇلاتنىڭ ئىسمى",
    pleaseSelectProduct: "مەھسۇلاتنى تاللاڭ",
    productVariant: "مەھسۇلات تۈرى",
    pleaseSelectAVariant: "ئۆزگەرگۈچى مىقدارنى تاللاڭ",
    pleaseEnterUnitPrice: "بىرلىك باھاسىنى كىرگۈزۈڭ",
    businessBranch: "سودا شۆبىسى",
    pleaseSelectBranch: "تارماقنى تاللاڭ",
    youveSuccessfullyAddedANewSupplier:
      "مۇۋەپپەقىيەتلىك ھالدا يېڭى تەمىنلىگۈچىنى قوشتىڭىز",
    addSupplier: "تەمىنلىگۈچىنى قوشۇڭ",
    pleaseEnterSupplierEmail: "تەمىنلىگۈچىنىڭ ئېلېكترونلۇق خەتنى كىرگۈزۈڭ",
    pleaseAddADescription: "چۈشەندۈرۈش قوشۇڭ",
    anErrorOccuredProductsDeleted:
      "مەشغۇلات قىلىۋاتقاندا خاتالىق كۆرۈلدى. دىققەت قىلىڭ ، بۇ جەرياندا ئاز ساندىكى مەھسۇلاتلار ئۆچۈرۈلگەن بولۇشى مۇمكىن",
    bulkDelete: "Bulk Delete",
    youAreAboutToDelete: "ئۆچۈرمەكچى بولۇۋاتىسىز",
    product: "مەھسۇلات",
    isDueToRedeem: "تۆلەمگە ئېرىشىدۇ",
    aReward: "مۇكاپات",
    pleaseSelectCustomerToReeemReward:
      "مۇكاپاتنى قايتۇرۇش ئۈچۈن خېرىدار تاللاڭ.",
    youHaveNoLoyaltyProgramRunning:
      "سىزدە ئاكتىپ ساداقەت پروگراممىسى ئىجرا بولمايدۇ",
    customerHhasNoPointsInLoyaltyProgram:
      "بۇ ساداقەت پروگراممىسىدا خېرىدارنىڭ ھېچقانداق نۇقتىسى يوق",
    proceedWithPayment: "پۇل تۆلەشنى داۋاملاشتۇرامسىز؟",
    youAreAboutToPayForTransactionUsingPoints:
      "نومۇر ئارقىلىق سودىغا پۇل تۆلىمەكچى بولۇۋاتىسىز.",
    customerHas: "خېرىدار بار",
    worth: "ئەرزىيدۇ",
    andIsNotSufficientToPayForTransaction:
      "ھەمدە بۇ سودىغا پۇل تۆلەشكە يەتمەيدۇ. ئۇلار باشقا پۇل تۆلەش ئۇسۇلى ئارقىلىق قالدۇقنى قوشماقچىمۇ؟",
    addCustomerLoyalty: "خېرىدارلارنىڭ ساداقەتمەنلىكىنى قوشۇڭ",
    pleaseAddCustomerFirst: "ئالدى بىلەن خېرىدار قوشۇڭ ياكى تاللاڭ.",
    pleaseWaitWhileWeGetReady: "بىز تەييارلىق قىلىۋاتقاندا ساقلاپ تۇرۇڭ",
    lowStock: "تۆۋەن پاي چېكى",
    pleaseEnterAmountTendered: "خېرىدار چاقىرىش سوممىسىنى كىرگۈزۈڭ",
    areYouSureToBookSaleOffline: "بۇ سېتىشنى تورسىز زاكاز قىلماقچىمۇ؟",
    saleWouldBeBookedAutomatically:
      "تورنى ئاچقاندا سېتىش ئاپتوماتىك زاكاس قىلىنىدۇ",
    offlineSalesBookingCancelled: "تورسىز سېتىش زاكاز ئەمەلدىن قالدۇرۇلدى",
    covid19Message:
      "COVID19: قولنى سوپۇن بىلەن يۇيۇڭ ياكى تازىلىۋېتىڭ. ھەر ۋاقىت بىخەتەر تۇرۇڭ",
    saleSuccessfullyRecorded: "سېتىش مۇۋەپپەقىيەتلىك خاتىرىلەندى!",
    sendReceiptToEmail: "ئېلېكترونلۇق خەتكە تالون ئەۋەتىڭ",
    sendThankYouSms: "رەھمەت سىزگە قىسقا ئۇچۇر يوللاڭ",
    sendShippingDetails: "توشۇش تەپسىلاتلىرىنى ئەۋەتىڭ",
    addLoyalty: "ساداقەتنى قوشۇڭ",
    searchCustomerNameOrNumber: "خېرىدارلارنىڭ ئىسمى ياكى نومۇرىنى ئىزدەڭ",
    clickTheSearchCustomerBox:
      "ئىزدەش خېرىدارلار ساندۇقى ۋە سايىلەش كارتىسىنى چېكىڭ",
    enterProductPrice: "مەھسۇلات باھاسىنى كىرگۈزۈڭ",
    enterPriceFor: "باھاغا كىرىڭ",
    searchForProduct: "مەھسۇلات ئىزدەش",
    all: "ھەممىسى",
    backToDashboard: "باش تاختا",
    viewDraftSales: "سېتىش لايىھىسىنى كۆرۈش",
    variantSelected: "variant selected",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "ئالدىنقى خىل تاللاشنى باھاغا ئاساسەن تاللانغان يېڭى نۇسخىغا ئىشلىتىشكە بولمايدۇ ، تاللىشىڭىزنى ئۆزگەرتىڭ.",
    pleaseSupplyPositiveQuantityNumber: "پوچتا نومۇرى بىلەن تەمىنلەڭ",
    lowStockFor: "تۆۋەن پاي چېكى",
    clearVariants: "ئېنىق ۋارىيانتلىرى",
    pleaseEnterQuantity: "ساننى كىرگۈزۈڭ",
    picture: "رەسىم",
    redemptionToken: "Redemption Token",
    token: "توكەن",
    totalSpent: "ئومۇمىي چىقىم",
    confirmPayment: "ھەق تاپشۇرۇشنى جەزملەشتۈرۈڭ",
    hasPaymentBeenMade: "چىقىم ئوڭۇشلۇق بىر تەرەپ قىلىندىمۇ؟",
    enterTransactionReference: "سىز تۆلىگەن سودا پايدىلىنىش ئۇچۇرىنى كىرگۈزۈڭ",
    pleaseSelectACustomer: "خېرىدار تاللاڭ!",
    areYouSureToApplyDiscount: "ئېتىبار بېرىشنى خالامسىز؟",
    addYourBankAccountToEnableUssd:
      "UPay ئارقىلىق دەرھال USSD يۆتكەشنى قوزغىتىش ئۈچۈن بانكا ھېساباتىڭىزنى قوشۇڭ",
    totalAmountToPay: "تۆلەيدىغان ئومۇمىي سومما",
    doYouWantToCancelTransaction: "بۇ سودىنى بىكار قىلامسىز؟",
    savePrintBill: "ساقلاش / بېسىش",
    enterAmountCollectedForCustomer: "خېرىدار ئۈچۈن يىغىلغان سوممىنى كىرگۈزۈڭ",
    recordSale: "خاتىرە سېتىش",
    checkOutWith: "تەكشۈرۈپ بېقىڭ",
    instantTransfer: "دەرھال يۆتكەش",
    dialTheUSSDCode: "USSD كودىغا تېلېفون قىلىڭ",
    pleaseSelectABank: "بانكا تاللاڭ",
    payWithUSSD: "USSD ئارقىلىق پۇل تۆلەڭ",
    sendBillTo: " - بىلنى ئەۋەتىڭ",
    waitingForUSSDTransfer: "USSD يوللاشنى ساقلاش",
    percent: "پىرسەنت",
    applyDiscount: "ئېتىبارنى ئىلتىماس قىلىڭ",
    thisBillHasBeenSaved: "بۇ قانۇن لايىھىسى ساقلاندى",
    saveDraft: "لايىھەنى ساقلاش",
    pleaseTypeANameToIdentifyCustomer: "خېرىدارنى تونۇش ئۈچۈن ئىسىم كىرگۈزۈڭ",
    paymentDetails: "چىقىم تەپسىلاتلىرى",
    basePrice: "ئاساسى باھاسى",
    staff: "خىزمەتچىلەر",
    subTotal: "SubTotal",
    durationMonths: "Duration (months)",
    selectADuration: "ۋاقىتنى تاللاڭ",
    oneMonth: "1 ئاي",
    twoMonths: "2 ئاي",
    threeMonths: "3 ئاي",
    sixMonths: "6 ئاي",
    twelveMonths: "12 ئاي",
    eighteenMonths: "18 ئاي",
    twentyFourMonths: "24 ئاي",
    twoMonthsFree: "(2 ئاي ھەقسىز)",
    threeMonthsFree: "(3 ئاي ھەقسىز)",
    fiveMonthsFree: "(5 ئاي ھەقسىز)",
    yourAccountHasBeen: "ھېساباتىڭىز بولدى",
    renewed: "يېڭىلاندى",
    upgraded: "يېڭىلاندى",
    successfully: "مۇۋەپپەقىيەتلىك",
    yourSubscription: "مۇشتەرىلىكىڭىز",
    youAreCurrentlyEnrolledOnA: "سىز ھازىر a غا تىزىملاتتىڭىز",
    pleaseChooseAPaymentOption: "ھەق تۆلەش ئۇسۇلىنى تاللاڭ",
    planRenewal: "پىلان يېڭىلاش",
    planUpgrade: "پىلان يېڭىلاش",
    pleaseSelectDurationToPayFor: "پۇل تۆلىمەكچى بولغان ۋاقىتنى تاللاڭ",
    staffAccounts: "خىزمەتچىلەر ھېساباتى",
    ecommerce: "Ecommerce",
    pleaseSelectAPlan: "پىلاننى تاللاڭ",
    includeAddons: "قوشۇمچە دېتاللارنى ئۆز ئىچىگە ئالىدۇ",
    viewTransactions: "سودىلارنى كۆرۈش",
    customerHasNoCompletedTansactions: "خېرىدار تېخى تاماملانمىغان",
    branch: "شۆبە",
    enterNumberOfEcommerceBranches: "سودا تارماقلىرىنىڭ سانىنى كىرگۈزۈڭ",
    enterNumberOfEcommerceBranchesToPay:
      "سىز تۆلىمەكچى بولغان سودا تارماقلىرىنىڭ سانىنى كىرگۈزۈڭ",
    ecommerceIntegration: "Ecommerce Integration",
    enterNumberOfBranches: "تارماقلارنىڭ سانىنى كىرگۈزۈڭ",
    enterNumberOfAdditionalBranchesToPay:
      "سىز تۆلىمەكچى بولغان قوشۇمچە تارماقلارنىڭ سانىنى كىرگۈزۈڭ",
    enterNumberOfStaffs: "خىزمەتچىلەر سانىنى كىرگۈزۈڭ",
    enterNumberOfStaffsToPayFor: "سىز تۆلىمەكچى بولغان خادىم سانىنى كىرگۈزۈڭ",
    discountApplies: "ئېتىبار باھا قوللىنىلىدۇ",
    starsOnThe: "يۇلتۇزلار",
    offer: "تەكلىپ",
    get: "Get",
    moreStarsToRedeem: "تېخىمۇ كۆپ يۇلتۇزلار",
    taxVat: "باج (قوشۇلما قىممەت بېجى)",
    callCashierToCompletePayment: "پۇل تۆلەش ئۈچۈن نەق پۇلغا تېلېفون قىلىڭ",
    receipt: "تالون",
    dear: "قەدىرلىك",
    thankYouForYourOrderFindGoods:
      "زاكاز قىلغانلىقىڭىزغا رەھمەت. پۈتۈشكەندەك تەمىنلەنگەن تۆۋەندىكى تاۋارلارنى تېپىڭ.",
    shippingNote: "توشۇش خاتىرىسى",
    enterShippingNote: "توشۇش خاتىرىسىنى كىرگۈزۈڭ",
    shippingAddress: "توشۇش ئادرېسى",
    enterShippingAddress: "توشۇش ئادرېسىنى كىرگۈزۈڭ",
    wellDoneYouAreDueToRedeem: "ياخشى! تۆلەمگە ئېرىشىسىز",
    toGetYourRewardNextVisit:
      "كېيىنكى زىيارىتىڭىزدە مۇكاپاتقا ئېرىشىش. رەھمەت سىزگە",
    pointsOnThe: "نۇقتىلار",
    morePointsToRedeem: "قايتۇرۇش ئۈچۈن تېخىمۇ كۆپ نۇقتىلار",
    showCode: "كودنى كۆرسىتىش",
    toGetYourRewardOnNextVisit:
      "كېيىنكى زىيارىتىڭىزدە مۇكاپاتقا ئېرىشىش. رەھمەت سىزگە",
    earn: "Earn",
    delivaryNote: "Delivary Note",
    draftSales: "Draft Sales",
    startDate: "باشلىنىش ۋاقتى",
    endDate: "ئاخىرلىشىش ۋاقتى",
    orders: "زاكاز",
    checkout: "چىقىمدان",
    noProductItem: "مەھسۇلات تۈرى يوق",
    selectProductImage: "مەھسۇلات رەسىمىنى تاللاڭ",
    selectCountry: "دۆلەت تاللاڭ",
    selectRegion: "دۆلەت / رايوننى تاللاڭ",
    printProductTag: "مەھسۇلات خەتكۈچىنى بېسىڭ",
    transactionReference: "سودا پايدىلىنىش ماتېرىيالى",
    Cashier: "Cashier",
    Manager: "باشقۇرغۇچى",
    Owner: "ئىگىسى",
    Admin: "باشقۇرغۇچى",
    addPartners: "شېرىكلەرنى قوشۇڭ",
    addNewLoyaltyPartner: "يېڭى سادىق شېرىك قوشۇڭ",
    pleaseEnterCompanyName: "شىركەت نامىنى كىرگۈزۈڭ",
    companyName: "شىركەت ئىسمى",
    pleaseEnterCompanyRepName: "شىركەت ۋەكىلى نامىنى كىرگۈزۈڭ",
    companyRepName: "شىركەت ۋەكىلى",
    pleaseEnterCompanyRepEmail: "شىركەت ۋەكىلى ئېلېكترونلۇق خەتنى كىرگۈزۈڭ",
    companyRepEmail: "شىركەت ۋەكىللىرىنىڭ ئېلېكترونلۇق خەت ساندۇقى",
    pleaseEnterCompanyRepPhone: "شىركەت ۋەكىلى تېلېفون نومۇرىنى كىرگۈزۈڭ",
    companyRepPhone: "شىركەت نومۇرى تېلېفون نومۇرى",
    addReward: "مۇكاپات قوشۇڭ",
    pleaseEnterRewardName: "مۇكاپات نامىنى كىرگۈزۈڭ",
    rewardName: "مۇكاپات ئىسمى",
    rewardQuantity: "مۇكاپات سوممىسى",
    rewardDescription: "مۇكاپاتلاش چۈشەندۈرۈشى",
    rewardType: "مۇكاپات تۈرى",
    pleaseEnterRewardType: "مۇكاپات تۈرىنى كىرگۈزۈڭ",
    pleaseEnterRewardQuantity: "مۇكاپات سوممىسىنى كىرگۈزۈڭ",
    pleaseEnterRewardDescription: "مۇكاپات چۈشەندۈرۈشىنى كىرگۈزۈڭ",
    fineDining: "ئېسىل تاماق",
    luxuryFashion: "ھەشەمەتلىك مودا",
    hotels: "مېھمانخانىلار",
    travel: "ساياھەت",
    foodAndBeverage: "يېمەكلىك ۋە ئىچىملىك",
    fashion: "مودا",
    health: "ساغلاملىق",
    furniture: "ئۆي جاھازلىرى",
    entertainment: "كۆڭۈل ئېچىش",
    automobile: "ماشىنا",
    education: "مائارىپ",
    beautyAndSpa: "گۈزەللىك ۋە سىپا",
    staycation: "Staycation",
    events: "Events",
    trips: "ساياھەت",
    oilAndGas: "نېفىت ۋە تەبىئىي گاز",
    laundry: "كىرئالغۇ",
    partnerCategory: "ھەمكارلاشقۇچىلار تۈرى",
    freeItem: "ھەقسىز تۈر",
  },
  Uzbek: {
    cashier: "kassir",
    manager: "menejer",
    owner: "egasi",
    online: "onlayn",
    offline: "oflayn",
    changePassword: "Kalit so'zni o'zgartirish",
    currentPasswordMessage: "Iltimos, joriy parolni kiriting",
    passwordMessage: "Iltimos, parolingizni kiriting",
    currentPassword: "Joriy parol",
    password: "parol",
    confirmPasswordMessage: "Iltimos, parolingizni tasdiqlang!",
    confirmPassword: "Parolni tasdiqlang",
    sendViaEmail: "Elektron pochta orqali yuborish",
    sendViaWhatsapp: "WhatsApp orqali yuboring",
    downloadPdf: "PDF yuklab olish",
    paid: "to'langan",
    unpaid: "to'lanmagan",
    partial: "qisman",
    closeInvoice: "Hisob -fakturani yopmoqchimisiz?",
    closeInvoiceConfirmation:
      "Hisob -faktura saqlanmasligi mumkin. Yopmoqchimisiz?",
    yes: "ha",
    no: "yo'q",
    invoice: "Hisob -faktura",
    wasDeducted: "chegirib tashlandi",
    for: "uchun",
    item: "Element",
    addProduct: "Mahsulot qo'shish",
    paymentReference: "To'lov ma'lumotnomasi",
    amountPaid: "To'langan miqdor",
    discountAmount: "Chegirma miqdori",
    amountDue: "To'lanadigan summa",
    amount: "Miqdori",
    dueDate: "Muddati",
    paymentType: "To'lov turi",
    card: "Karta",
    cash: "Naqd pul",
    bankTransfer: "Bank o'tkazmasi",
    paymentMessage: "To'lov xabari",
    description: "Tavsif",
    sendReceipt: "Qabul yuboring",
    delete: "O'chirish",
    save: "Saqlash",
    resend: "Qayta yuborish",
    saveAndSend: "Saqlash",
    invoiceSaved: "Hisob -faktura saqlandi!",
    selectPaymentMethod: "Iltimos, to'lov usulini tanlang!",
    selectCustomer: "Iltimos, xaridorni tanlang!",
    cartEmptyError:
      "Savatlar ro'yxati bo'sh bo'lishi mumkin emas, hisob -fakturani yoping va savatni tovarga qo'shing!",
    subscriptionExpired:
      "Sizning obuna muddati tugadi va hisobingiz endi cheklangan. Hisob qaydnomangizni yangilash uchun quyidagi tugmani bosing",
    renew: "Yangilash",
    holdOn: "To'xtab tur",
    customerBank: "Mijozlar banki",
    cancel: "Bekor qilish",
    selectACustomer: "Mijozni tanlang",
    invoiceSuccessfulPdfError:
      "Hisob -faktura muvaffaqiyatli yaratildi, lekin PDF -ni yaratish odatdagidan ko'proq vaqtni oladi. Tez orada qayta tekshiring.",
    downloadingInvoice: "Hisob -fakturani yuklab olish",
    downloadingReceipt: "Kvitansiya yuklab olinmoqda",
    whatsappReceiptError:
      "WhatsApp orqali kvitansiyani yuborishda xatolik yuz berdi, qaytadan urining.",
    receiptToWhatsapp: "Qabul WhatsApp -ga yuborildi",
    thankYouForPatronage: "Sizning homiyligingiz uchun tashakkur",
    hereIsYourReceipt: "Mana sizning to'lov kvitansiyangiz",
    errorSendingEmailReceipt:
      "Kvitansiyani elektron pochta orqali yuborishda xatolik yuz berdi, qayta urinib ko'ring yoki qo'llab-quvvatlash xizmatiga murojaat qiling",
    receiptSentToEmail: "Qabul mijozning elektron pochtasiga yuborilgan",
    invoiceSentToEmail:
      "Hisob -faktura xaridorning elektron pochtasiga yuborilgan",
    invoiceSuccessWhatsappError:
      "Hisob -faktura muvaffaqiyatli tuzildi, lekin WhatsApp -ga yuborishda xatolik yuz berdi. Hisob -fakturalar ro'yxatida qayta urinib ko'ring",
    invoiceSuccessfulEmailError:
      "Hisob-faktura muvaffaqiyatli tuzildi, lekin elektron pochta sifatida yuborishda xatolik yuz berdi. Hisob -fakturalar ro'yxatidan qaytadan urinib ko'ring",
    invoiceSentToWhatsapp: "Hisob -faktura WhatsApp -ga yuborildi",
    hello: "Salom",
    pleaseDownloadInvoice: "Iltimos, hisob -fakturani yuklab oling",
    pleaseDownloadReceipt: "Iltimos, kvitansiyani yuklab oling",
    from: "dan",
    email: "E -pochta",
    upgrade: "Yangilash",
    youAreOnFreePlan: "Siz bepul tarif rejasiga kirasiz.",
    clickOn: "Ustiga bosing",
    yourPlanInFewSteps: " obunangiz bir necha qadamda.",
    to: "ga",
    yourSubscriptionHasExpired:
      "Sizning obuna muddati tugadi va hisobingiz endi cheklangan.",
    days: "kunlar",
    yourSubscriptionExpiresIn: "Sizning Loystar obunangiz muddati tugaydi",
    createAcount: "Hisob ochish",
    signIn: "Tizimga kirish",
    continue: "Davom etish",
    enterOtp: "OTP PIN kodini kiriting",
    enterValidOtp: "PIN kodni kiriting",
    pin: "PIN kod",
    tokenSentToMail: "Sizning elektron pochtangizga token yuborildi",
    passwordResetSuccessful: "Parolni tiklash muvaffaqiyatli amalga oshirildi",
    somethingWentWrong: "Nimadir noto'g'ri bajarildi!",
    resetPassword: "Parolni tiklash",
    iHaveResetCode: "Menda parolni tiklash kodi bor",
    pleaseEnterEmail: "E -pochtangizni kiriting",
    aTokenWillBeSentToEmail: "Sizning elektron pochtangizga token yuboriladi",
    enterEmail: "E -pochtangizni kiriting",
    sendinYourToken: "Token yuborilmoqda ...",
    makeSureItMatchesPassword:
      "Yangi parolingizga mos kelishiga ishonch hosil qiling",
    pleaseChooseNewPassword: "Iltimos, yangi parolni tanlang",
    chooseNewPassword: "Yangi parolni tanlang",
    enterNewPassword: "Tasdiqlash uchun yangi parolni kiriting",
    enterTokenSent: "Pochta yuborilgan tokenni kiriting",
    resetToken: "Tokenni tiklash",
    resettingPassword: "Parol tiklanmoqda ...",
    signUp: "Ro'yxatdan o'tish",
    adminSignInWithEmail:
      " Administrator elektron pochta orqali, xodimlar esa foydalanuvchi nomi bilan kirishadi.",
    pleaseEnterEmailOrUsername:
      "Iltimos, elektron pochta yoki foydalanuvchi nomingizni kiriting",
    emailOrUsername: "Elektron pochta yoki foydalanuvchi nomi",
    pleaseEnterPassword: "Iltimos, parolni kiriting",
    createAnAccount: "Hisob ochish",
    forgotPassword: "Parolni unutdingizmi?",
    enterPhoneNumber: "Telefon raqamini kiriting",
    personalData: "Shaxsiy malumot",
    validateConfirmationCOde: "Tasdiqlash kodini tasdiqlang",
    pleaseEnterFirstName: "Iltimos, ismingizni kiriting",
    pleaseEnterPhoneNumber: "Iltimos, telefon raqamingizni kiriting",
    pleaseEnterLastName: "Iltimos, familiyangizni kiriting",
    pleaseEnterBusinessName: "Iltimos, korxona nomini kiriting",
    firstName: "Ism",
    lastName: "Familiya",
    businessName: "Biznes nomi",
    previous: "Oldingi",
    next: "Keyingi",
    pleaseSelectBusinessCategory: "Iltimos, biznesingiz toifasini tanlang",
    pleaseEnterValidEmail: "E -pochta manzilini kiriting",
    pleaseEnterPostCode: "Iltimos, pochta indeksini kiriting",
    postCode: "Pochta indeksi",
    phoneNumberInUse: "Bu telefon raqami allaqachon ishlatilgan!",
    emailInUse: "Bu elektron pochta allaqachon ishlatilgan!",
    foodAndDrinks: "Oziq -ovqat va ichimliklar",
    salonAndBeauty: "Go'zallik va salon",
    fashionAndAccessories: "Moda va aksessuarlar",
    gymAndFitness: "Sport zali va fitnes",
    travelAndHotel: "Sayohat va mehmonxona",
    homeAndGifts: "Uy va sovg'alar",
    washingAndCleaning: "Yuvish va tozalash",
    gadgetsAndElectronics: "Gadjetlar va elektronika",
    groceries: "Oziq -ovqat mahsulotlari",
    others: "Boshqalar",
    submit: "Yuborish",
    accountCreatedSuccessful: "Hisobingiz muvaffaqiyatli yaratildi.",
    pleaseEnterAddress: "Iltimos, manzilingizni kiriting",
    addressLine1: "Manzil uchun 1-chi qator",
    addressLine2: "Manzil liniyasi 2",
    choosePassword: "Parolni tanlang",
    passwordMustBe6Characters:
      "Parol kamida 6 ta belgidan iborat bo'lishi kerak.",
    howDidYouHearLoystar: "Loystar haqida qanday eshitgansiz?",
    referralCode: "murojaat kodeksi",
    byClickingTheButton:
      " Quyidagi tugmani bosish bilan siz rozilik bildirasiz",
    termsAndSevice: "Shartlar",
    wereCreatingAccount: "Biz sizning hisobingizni yaratmoqdamiz",
    proceed: "Davom eting",
    verificationCodeMustBe6:
      "Tasdiqlash kodi 6 ta raqamdan iborat bo'lishi kerak",
    pleaseEnter6DigitCode: "Iltimos, 6 xonali kodni kiriting",
    enterVerificationCode: "Tasdiqlash kodini kiriting",
    resendToken: "Tokenni qayta yuborish",
    verify: "Tasdiqlash",
    transactions: "Tranzaksiyalar",
    todaySales: "Bugungi sotuvlar",
    salesHistory: "Savdo tarixi",
    supplyHistory: "Ta'minot tarixi",
    new: "Yangi",
    invoices: "Hisob -fakturalar",
    disbursements: "To'lovlar",
    offlineSales: "Oflayn savdo",
    products: "Mahsulotlar",
    customers: "Xaridorlar",
    multiLevelLoyalty: "Ko'p darajali sodiqlik",
    loyaltyPrograms: "Sadoqat dasturlari",
    members: "A'zolar",
    appStore: "Uskunalar Do'koni",
    orderMenu: "Buyurtma menyusi",
    settings: "Sozlamalar",
    staffAndBranches: "Xodimlar va filiallar",
    myAccount: "Mening hisobim",
    switchToSellMode: "Sotish rejimiga o'ting",
    signOut: "Tizimdan chiqish",
    getFreeSubscription: "Bepul obunani oling",
    onlyNumbersAllowed: "Faqat raqamlarga ruxsat berilgan",
    yourAccountMustBe10Digits:
      "Sizning hisob raqamingiz 10 xonali raqam bo'lishi kerak",
    yourBvnMustBe11: "Sizning BVN raqamingiz 11 xonali bo'lishi kerak",
    pleaseSelectBank: "Iltimos, bankingizni tanlang",
    selectYourBank: "Bankingizni tanlang",
    enterBankAccountNumber: "Bank hisob raqamini kiriting",
    enterBvnNumber: "BVN raqamingizni kiriting",
    connectBankAccount: "Bank hisobini ulang",
    passwordResetSuccessfulAndSignOut:
      "Parolingiz muvaffaqiyatli tiklandi. Qayta kirish uchun pastdagi Chiqish tugmasini bosing",
    enterCurrentPassword: "Joriy parolni kiriting",
    pleaseEnterCurrentPassword: "Iltimos, joriy parolni kiriting",
    phoneNumber: "Telefon raqami",
    sex: "Jinsiy aloqa",
    dateOfBirth: "Tug'ilgan sana",
    state: "Shtat",
    country: "Mamlakat",
    loyaltyId: "Sodiqlik identifikatori",
    createdAt: "At yaratilgan",
    noOfTransactionsMade: "Bajarilgan bitimlar soni",
    yourDownloadWillStart: "Bir daqiqadan so'ng yuklash boshlanadi",
    exportCustomers: "Xaridorlarni eksport qilish",
    youhaveSuccessfullyToppedUp:
      "Siz SMS birliklarini muvaffaqiyatli to'ldirdingiz.",
    youNowHave: "Sizda hozir bor",
    smsUnits: "SMS birliklari",
    enterNumberOfSmsUnits:
      "Siz sotib olmoqchi bo'lgan SMS birliklarining sonini kiriting",
    pleaseEnterANumericValue: "Iltimos, raqamli qiymatni kiriting",
    pay: "To'lash",
    accountEditedSuccessfully: "Hisobingiz muvaffaqiyatli tahrir qilindi.",
    youAeCurrentlyOn: "Siz hozir faolsiz",
    plan: "Reja",
    userData: "Foydalanuvchi ma'lumotlari",
    businessData: "BIZNES MA'LUMOTLARI",
    businessCategory: "Ishbilarmonlik toifasi",
    pleaseSelectCurrency: "Valyutangizni tanlang",
    selectYourCurrency: "Valyutangizni tanlang",
    purchaseMoreSmsUnits:
      "Quyidagi shakl yordamida ko'proq SMS birliklarini sotib oling",
    youHave: "Sizda .. Bor",
    atLeast4SmsUnitsRrequired:
      "Tasdiqlash uchun kamida 4 sms birligi kerak, to'ldiring!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Iltimos, \"Mijozingizni bilish\" (KYC) talablariga rioya qilish uchun bank hisobingizni tasdiqlang. Bu sizga to'lovlarni USSD yoki tezkor o'tkazish orqali to'plash, mijozlardan buyurtmalarni qabul qilish va tranzaktsiyalarni amalga oshirish imkonini beradi. Faqat Nigeriya savdogarlari uchun amal qiladi. Boshlash uchun quyidagi tugmani bosing.",
    reConnectBankAccount: "Bank hisobini qayta ulang",
    accountName: "Hisob nomi",
    accountNumber: "Hisob raqami",
    bankName: "Bank nomi",
    verified: "Tasdiqlangan",
    accountDetails: "Hisob ma'lumotlari",
    kycBankAccount: "KYC",
    createTier: "Qatlam yaratish",
    fileUploadSuccessful: "fayl muvaffaqiyatli yuklandi",
    fileUploadFailed: "fayl yuklanmadi",
    createLoyaltyProgram: "Sadoqat dasturini yarating",
    createLoyalty: "Sadoqatni yarating",
    name: "Ism",
    loyaltyArtwork: "Sadoqatli san'at asari",
    clickToUpload: "Yuklash uchun bosing",
    amountToPointsRatio: "Ballar nisbati miqdori",
    points: "Ballar",
    recommendedAmountToPointRatio:
      "Tavsiya etiladi: ₦ 1 dan 1 ballgacha. ya'ni har bir sarflangan Naira uchun mijozlaringiz 1 ball oladi",
    pleaseTypeIn: "Iltimos, kiriting",
    toDeleteLoyalty: "bu sodiqlikni yo'q qilish uchun",
    deleteLoyalty: "Sadoqatni o'chirish",
    toConfirm: "tasdiqlash uchun",
    edit: "Tahrirlash",
    pointsAwardedSuccessfully: "Ballar muvaffaqiyatli topshirildi.",
    enterPointValueToAward:
      "Iste'molchilarni mukofotlashni istagan nuqta qiymatini kiriting",
    award: "Mukofot",
    awardPointValuesToCustomer: "Xaridorga berilgan balli qiymat",
    enrollMembersToLoyalty: "A'zolarni sodiqlikka yozib oling",
    awardPoints: "Mukofot ballari",
    enroll: "Ro'yxatdan o'tish",
    home: "Uy",
    loyalty: "Sadoqat",
    enrollCustomers: "Mijozlarni ro'yxatdan o'tkazish",
    selected: "Tanlangan",
    customer: "Xaridor",
    loyaltyActivationSuccessful: "Sodiqlikni faollashtirish muvaffaqiyatli.",
    loyaltyDeactivationSuccessful: "Sadoqatni o'chirish muvaffaqiyatli.",
    viewTier: "Qatlamni ko'rish",
    deactivate: "O'chirish",
    activate: "Faollashtirish",
    actions: "Harakatlar",
    nameOfLoyalty: "Sadoqatning nomi",
    loyaltyStatus: "Sodiqlik holati",
    numberOfTiers: "Darajalar soni",
    createdOn: "Yaratilgan",
    createATier: "Qatlam yaratish",
    stopCreatingTierConfirmation: "Darajani yaratishni to'xtatmoqchimisiz?",
    stopEditingTierConfirmation:
      "Bu qatlamni tahrir qilishni to'xtatmoqchimisiz?",
    nameOfTier: "Qatlam nomi",
    minimumSpendingTarget: "Minimal sarflash maqsadi",
    maximumSpendingTarget: "Maksimal sarflash maqsadi",
    minimumSpendingTargetRequired: "minimal xarajat maqsadlari talab qilinadi",
    maximumSpendingTargetRequired:
      "maksimal maqsadli xarajatlar talab qilinadi",
    rewardSponsor: "Mukofot homiysi",
    pleaseChooseARewardSponsor: "Iltimos, mukofot homiyini tanlang",
    self: "O'zini",
    partner: "Hamkor",
    rewardPartner: "Mukofot hamkori",
    pleaseSelectRewardPartner: "Iltimos, mukofot sherigingizni tanlang",
    rewards: "Mukofotlar",
    pleaseSelectAReward: "Iltimos, mukofotni tanlang",
    instructionsOnRedeemingReward:
      "Xaridor qanday qilib mukofotni qaytarishi kerakligi haqida ko'rsatma",
    pleaseFillInThisField: "Iltimos, bu maydonni to'ldiring!",
    toDeleteThisTier: " bu qatlamni o'chirish uchun",
    deleteTier: "Qatlamni o'chirish",
    viewMembers: "A'zolarni ko'rish",
    membersEnrolled: "Ro'yxatdan o'tgan a'zolar",
    instruction: "Ko'rsatma",
    membersIn: "A'zolar",
    availableTiersInLoyalty: "Sadoqat dasturida mavjud darajalar",
    tiers: "Darajalar",
    totalTier: "UMUMI TIER",
    availableLoyaltyProgramme: "Mavjud sodiqlik dasturi",
    totalLoyalties: "JAMI SADOQATLAR",
    memberDetails: "A'zo tafsilotlari",
    nameOfCustomer: "Xaridor nomi",
    address: "Manzil",
    allEnrolledMembers: "Barcha ro'yxatdan o'tgan a'zolar",
    thisIsToWishYouHappyBirthday:
      "Bu sizni tug'ilgan kuningiz bilan chin dildan tabriklash va farovon hayot tilashdir. Loysterga qilgan barcha ishlaringiz uchun tashakkur. Bayramingiz muborak!",
    inputAnOfferPlease: "Iltimos, taklifni kiriting",
    pleaseSelectTheInsertPoint:
      "Xabarda qo'shish nuqtasini tanlang va yana bosing",
    birthdayOfferAndMessage: "Tug'ilgan kun taklifi va xabar",
    birthdayOffer: "Tug'ilgan kun taklifi",
    birthdayMessage: "Tug'ilgan kun xabari",
    noOfferFound: "Taklif topilmadi",
    settingABirthdayOffer:
      "Tug'ilgan kun taklifini o'rnatish mijozlarga ushbu taklifni tug'ilgan kunida SMS orqali olish imkonini beradi",
    createOffer: "Taklif yaratish",
    whatIsTheOffer: "Taklif nima?",
    editMessage: "Xabarni tahrirlash",
    insert: "Kiritmoq",
    theNameOfCustomerInserted: "Bu erda mijozning ismi yoziladi",
    theBirtdayOfferInserted: "Tug'ilgan kun taklifi shu erga kiritiladi",
    youSuccessfullyAddedNewBranch:
      "Siz yangi filialni muvaffaqiyatli qo'shdingiz!",
    addNewBranch: "Yangi filial qo'shing",
    addBranch: "Filial qo'shing",
    additionalBranchWillIncur: "Qo'shimcha filial paydo bo'ladi",
    perBranch: "har bir filial uchun",
    ecommerceBranchCosts: "Elektron tijorat bo'limi xarajatlari",
    pleaseEnterBranchName: "Iltimos, filial nomini kiriting",
    pleaseEnterBranchAddress1: "Iltimos, filialning manzil satrini 1 kiriting",
    enterBranchAddress1: "Filialning manzil satrini 1 kiriting",
    enterBranchAddress2: "Filialning manzil satrini 1 kiriting",
    pleaseEnterBranchAddress2: "Iltimos, filialning manzil satrini 2 kiriting",
    enterBranchName: "Filial nomini kiriting",
    successfullyAddedStaff: "Siz yangi xodimlarni muvaffaqiyatli qo'shdingiz!",
    addNewStaff: "Yangi xodimlarni qo'shish",
    addStaff: "Xodimlarni qo'shish",
    additionalStaffWillIncur: "Qo'shimcha xodimlar jalb qilinadi",
    perStaff: "har bir xodim uchun.",
    pleaseEnterStaffEmail:
      "Iltimos, xodimlarning elektron pochta manzilini kiriting",
    pleaseEnterStaffUsername:
      "Iltimos, xodimlarning foydalanuvchi nomini kiriting",
    pleaseEnterStaffPassword: "Iltimos, xodimlarning parolini kiriting",
    pleaseSelectStaffRole: "Iltimos, xodimlarning rolini tanlang",
    selectStaffRole: "Xodimlarning rolini tanlang",
    enterStaffEmail: "Xodimlarning elektron pochta manzilini kiriting",
    enterStaffUsername: "Xodimning foydalanuvchi nomini kiriting",
    enterStaffPassword: "Xodimlarning parolini kiriting",
    spacesNotAllowedInUsername: "foydalanuvchi nomiga ruxsat berilmagan joylar",
    admin: "Admin",
    pleaseSelectBusinessToAttachStaff:
      "Xodimlarni biriktirish uchun biznesni tanlang",
    searchForBranchToAttachStaff:
      "Xodimlarni biriktirish uchun filialni qidiring",
    username: "Foydalanuvchi nomi",
    role: "Rol",
    areYouSureToDeleteThis: "Buni albatta o'chirib tashlaysizmi",
    branches: "Filiallar",
    upgradeYourPlan: "Rejangizni yangilang.",
    add: "QO'ShING",
    addNew: "Yangi qo'shish",
    customerWithEmailAlreadyExists:
      "E -pochta/telefon raqami bo'lgan mijoz allaqachon mavjud!",
    successfullyAddedNewCustomer:
      "Siz yangi mijozni muvaffaqiyatli qo'shdingiz!",
    addCustomer: "Xaridor qo'shish",
    pleaseEnterCustomerFirstName: "Iltimos, mijozning ismini kiriting",
    pleaseEnterCustomerLastName: "Iltimos, mijozning familiyasini kiriting",
    pleaseEnterCustomerPhoneNumber:
      "Iltimos, mijozning telefon raqamini kiriting",
    pleaseEnterCustomerEmail:
      "Iltimos, mijozning elektron pochta manzilini kiriting",
    pleaseEnterCustomerAddressLine:
      "Iltimos, mijozning manzil satrini kiriting",
    pleaseEnterCustomerOtherAddress: "Xaridorning boshqa manzilini kiriting",
    pleaseSelectCustomerGender: "Iltimos, mijozning jinsini tanlang",
    gender: "Jins",
    male: "Erkak",
    female: "Ayol",
    bank: "Bank",
    selectBank: "Bank -ni tanlang",
    stateOrRegionOrProvince: "Shtat/Viloyat/Viloyat",
    customerNotes: "Mijoz eslatmalari",
    sendSms: "SMS yuborish",
    editCustomer: "Xaridorni tahrirlash",
    redeemReward: "Mukofotni ishlating",
    issueLoyaltyCard: "Sadoqat kartasini chiqarish",
    deleteCustomer: "Xaridorni o'chirish",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Siz sodiqlik a'zolik identifikatorini muvaffaqiyatli topshirdingiz",
    noMembershipIdAvailable:
      "Hech qanday a'zolik identifikatorlari mavjud emas. Iltimos, biz bilan hello@loystar.co elektron pochta orqali bog'laning",
    sendEmail: "Email yuborish",
    membershipPoints: "A'zolik ballari",
    customerDetails: "Xaridor tafsilotlari",
    close: "Yopish",
    loyaltyBalance: "Sadoqat balansi",
    pointsBalance: "Ballar balansi",
    starBalance: "Yulduzlar balansi",
    requiredPoints: "Majburiy ballar",
    requiredStars: "Kerakli yulduzlar",
    reddemCode: "Kodni ishlatish",
    toDeleteThisCustomer: "bu mijozni o'chirish uchun",
    customerHasBeenDeletedSuccessfully: "Mijoz muvaffaqiyatli o'chirildi!",
    customerWithPhoneAlreadyExists:
      "Telefon raqami bo'lgan mijoz allaqachon mavjud",
    customerWasSuccessfullyEdited: "Mijoz muvaffaqiyatli tahrir qilindi",
    anErrorOccured: "Xatolik yuz berdi",
    phoneNumberIncludeCountry:
      "Telefon raqami (mamlakat kodini o'z ichiga oladi)",
    yourFileQueuedForUpload:
      "Faylingiz yuklash uchun navbatga qo'yildi. Sahifani bir necha soniyadan so'ng yangilang.",
    thereWasAnErrorPerformingOperation:
      "Operatsiyani bajarishda xatolik yuz berdi!",
    pleaseSelectFile: "Iltimos, faylni tanlang!",
    oopsOnlyCsvFilesAllowed:
      "Afsus! Faqat CSV fayllariga ruxsat berilgan. Iltimos .csv faylini yuklang.",
    fileShouldBeSmallerThan5Mb:
      "Fayl hajmi 5 MB dan oshmasligi kerak. Agar sizda katta fayl bo'lsa, support@loystar.co elektron pochta manziliga yuboring. rahmat",
    customerFirstNameIsRequired: "mijozning ismi qatorda ko'rsatilishi shart",
    customerPhoneNumberIsRequired:
      "mijozning telefon raqami navbatda ko'rsatilishi shart",
    importCustomers: "Xaridorlarni import qilish",
    upload: "Yuklab olish",
    clickIconToDownloadCsv:
      "CSV fayl shablonini yuklab olish uchun ushbu belgini bosing.",
    getGoogleSheetVersion: "Google jadvalining versiyasini oling",
    clickOrDragFileToUpload:
      "Yuklash uchun faylni bosing yoki bu maydonga torting",
    missingOrInvalidColumnHeader:
      "Ustun sarlavhasi yo'q yoki yaroqsiz. Iltimos, shablon formatiga rioya qiling. Rahmat.",
    youHaveImported: "Siz import qildingiz",
    youSuccessfullyRedeemedReward:
      "Siz mukofotni muvaffaqiyatli sotib oldingiz!",
    sixDigitCode: "Olti raqamli kod",
    pleaseEnterCustomerRewardCode: "Iltimos, mijozning mukofot kodini kiriting",
    enterRewardCode: "Mukofot kodini kiriting.",
    selectLoyaltyProgram: "Sadoqat dasturini tanlang",
    stamps: "Markalar",
    smsUnitsLow: "SMS birliklari past",
    pleaseTopUpSmsUnits: "SMS birliklarini to'ldiring",
    smsSuccessfullySentForDelivery: "SMS muvaffaqiyatli yuborildi!",
    sendSmsTo: "Ga SMS yuboring",
    allCustomers: "Barcha mijozlar",
    unitsAvailable: "Mavjud birliklar",
    pleaseTypeInTheMessage: "Iltimos, xabarni kiriting",
    message: "Xabar",
    charactersRemaining: "qolgan belgilar",
    avoidUseOfSpecialCharacters:
      "SMS birliklarini saqlash uchun maxsus belgilar va kulgichlardan foydalanmang.",
    note: "Eslatma",
    errorFetchingCustomersMultilevelDetail:
      "Mijozlarga ko'p darajali tafsilotlarni olishda xatolik",
    search: "Qidirmoq",
    reset: "Qayta o'rnatish",
    importCustomer: "Xaridorni import qilish",
    addNewCustomer: "Yangi mijoz qo'shish",
    sendSmsBroadcast: "SMS -xabarni yuborish",
    totalCustomers: "UMUMI Mijozlar",
    disbursementDetails: "To'lov tafsilotlari",
    paidBy: "To'lagan",
    disbursed: "To'langan",
    bankAccountName: "Bank hisobining nomi",
    bankAccountNumber: "Bank hisob raqami",
    transferInitiated: "Transfer boshlandi",
    transferCompleted: "O'tkazish tugallandi",
    pleaseEnterAValid: "Iltimos, yaroqli kiriting",
    begin: "boshlash",
    end: "oxiri",
    date: "sana",
    paymentDate: "To'lov sanasi",
    selectDisbursementDuration: "To'lovning davomiyligini tanlang",
    totalSettled: "To'liq hisob -kitob qilingan",
    totalUnsettled: "Hammasi beqaror",
    toDeleteThisSale: "bu sotuvni o'chirish uchun",
    draftSaleDeletedSuccessfully: "Qoralama savdosi muvaffaqiyatli o'chirildi!",
    deleteSale: "Sotishni o'chirish",
    pleaseTypeInYourTotal:
      "O'chirishni tasdiqlash uchun jami ma'lumotni kiriting",
    billDetails: "Bill tafsilotlari",
    printBill: "Billni chop etish",
    servedBy: "Xizmat qilgan",
    total: "Jami",
    createdDate: "Yaratilgan sana",
    createdTime: "Yaratilgan vaqt",
    status: "Holat",
    loginSuccessful: "Kirish muvaffaqiyatli",
    pleaseWaitWhileWeConnectAccount:
      "Iltimos, sizning hisobingizga ulanishni kuting",
    connectionFailedTryAgain: "Ulanmadi. Iltimos, yana bir bor urinib ko'ring.",
    connectionSuccessful: "Ulanish muvaffaqiyatli",
    viewDetails: "Tafsilotlarni ko'rish",
    enable: "Yoqish",
    free: "Ozod",
    cost: "Narx",
    visitWebsite: "Veb -saytga tashrif buyuring",
    pleaseUpgradeYourPlanToPro:
      "Iltimos, ushbu ilovani yoqish uchun rejangizni Pro Plus -ga yangilang",
    connectYourBankAccount:
      "To'lovlarni qabul qilish uchun bank hisobingizni ulang.",
    disable: "O'chirish",
    enableApp: "Ilovani yoqish",
    addNewProductToInvoice: "Hisob -fakturaga yangi mahsulot qo'shing",
    toDeleteThisInvoice: "bu hisob -fakturani o'chirish uchun",
    invoiceDeletedSuccessfully: "Hisob -faktura muvaffaqiyatli o'chirildi!",
    deleteInvoice: "Hisob -fakturani o'chirish",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Hisob -fakturani to'lash xabarini muvaffaqiyatli qo'shdingiz!",
    pleaseEnterPaymentInstructions: "Iltimos, to'lov ko'rsatmalarini kiriting",
    invoiceId: "Hisob -faktura identifikatori",
    paidDate: "To'langan sana",
    reference: "Malumot",
    productAdded: "Mahsulot qo'shildi",
    productOutOfStock: "Mahsulot stokda emas. Iltimos, qayta zaxiralang.",
    totalInvoices: "Umumiy chaqiruvlar",
    totalPaidInvoices: "Umumiy to'langan chaqiruvlar",
    totalUnpaidInvoices: "UMUMIY QO'LLANMAGAN CHAQARISHLAR",
    loyaltyProgramDeleted: "Sadoqat dasturi o'chirildi",
    thisLoyaltyProgramDeletedSuccessfully:
      "Bu sodiqlik dasturi muvaffaqiyatli o'chirildi",
    thisLoyaltyProgramEditedSuccessfully:
      "Bu sodiqlik dasturi muvaffaqiyatli tahrir qilindi",
    loyaltyProgramAddedSuccessfully: "Sadoqat dasturi muvaffaqiyatli qo'shildi",
    loyaltyProgramEdited: "Sadoqat dasturi tahrirlangan",
    loyaltyProgramAdded: "Sodiqlik dasturi qo'shildi",
    loyaltyDetails: "Sadoqat tafsilotlari",
    doYouWantToCloseDialog: "Bu dialogni yopmoqchimisiz?",
    pleaseEnterLoyaltyName: "Iltimos, sodiqligingiz nomini kiriting",
    programType: "Dastur turi",
    pleaseSelectProgramType: "Iltimos, dastur turini tanlang",
    simplePoints: "Oddiy ballar",
    stampsProgram: "Markalar dasturi",
    threshold: "Eshik",
    pleaseEnterLoyaltyThreshold: "Sadoqat chegarasini kiriting",
    reward: "Mukofot",
    pleaseEnterLoyaltyReward: "Iltimos, sodiqlik mukofotini kiriting",
    totalUserPoints: "Foydalanuvchilarning umumiy ballari",
    totalUserStamps: "Foydalanuvchilarning umumiy markalari",
    spendingTarget: "Maqsadni sarflash",
    spendingTargetHint1:
      "Xarajat maqsadi - mijoz mukofot olish uchun qancha pul sarflashi kerak. 1 valyuta qiymati = 1 ball.",
    spendingTargetHint2:
      "Markalarning maqsadi - mukofot olish uchun xaridor qancha markani yig'ishi kerak. masalan. 5",
    addNewLoyaltyProgram: "Yangi sodiqlik dasturini qo'shing",
    addLoyaltyProgram: "Sadoqat dasturini qo'shing",
    loyaltyProgramType: "Sadoqat dasturining turi",
    pleaseSelectLoyaltyProgramType: "Sadoqat dasturining turini tanlang",
    nameOfProgram: "Dastur nomi",
    pleaseEnterProgramName: "Iltimos, dastur nomini kiriting",
    whatIsTheReward: "Mukofot nima?",
    egNextPurchaseIsFree: "Masalan, Keyingi xarid bepul",
    customerName: "Mijoz nomi",
    guestCustomer: "Mehmon mijoz",
    orderConfirmedSuccessfully: "Buyurtma muvaffaqiyatli tasdiqlandi",
    orderCancelledSuccessfully: "Buyurtma muvaffaqiyatli bekor qilindi",
    confirmOrder: "Buyurtmani tasdiqlang",
    cancelOrder: "Buyurtmani bekor qilish",
    allOrders: "Barcha buyurtmalar",
    totalOrders: "Umumiy buyurtmalar",
    doYouWantToAcceptOrder: "Bu buyurtmani qabul qilmoqchimisiz?",
    doYouWantToCancelOrder: "Bu buyurtmani bekor qilmoqchimisiz?",
    orderDetails: "Buyurtma tafsilotlari",
    orderCreatedAt: "Buyurtma yaratildi",
    supplier: "Yetkazib beruvchi",
    productName: "Mahsulot nomi",
    quantity: "Miqdor",
    unitPrice: "Donasining narxi",
    receivedBy: "Qabul qilingan",
    reportFrom: "Xabar",
    totalSupplies: "Umumiy ta'minot",
    allRightsReserved: "Barcha huquqlar himoyalangan",
    toDeleteThisTransaction: "ushbu bitimni o'chirish uchun",
    transactionDeletedSuccessfully:
      "Tranzaksiya muvaffaqiyatli o'chirildi. Qimmatli qog'ozlar inventarizatsiyaga qaytarildi.",
    deleteTransaction: "Tranzaktsiyani o'chirish",
    transactionDetails: "Tranzaksiya tafsilotlari",
    printReceipt: "Chiptani chop etish",
    channel: "Kanal",
    discount: "Chegirma",
    profit: "Foyda",
    discountedSales: "Chegirmali savdo",
    errorFetchingRecord: "Yozuvni yuklashda xato",
    exportTransactions: "Eksport operatsiyalari",
    errorFetchingSalesRecord:
      "Eksport uchun savdo rekordini olishda xatolik yuz berdi.",
    totalSellingPrice: "Umumiy sotish narxi",
    totalCostPrice: "Umumiy narx",
    appliedDiscount: "Amaldagi chegirma",
    noOfItems: "Ob'ektlar soni",
    sales: "Sotish",
    export: "Eksport qilish",
    totalProfit: "Umumiy daromad",
    totalBalanceInPeriod: "Davrdagi jami qoldiq",
    allTimeSales: "Har doim sotish",
    records: "Rekordlar",
    todaysSales: "Bugungi sotuvlar",
    transaction: "bitim",
    youHaveExceededTotalNumberOfProducts:
      "Siz rejangiz bo'yicha ruxsat etilgan mahsulotlarning umumiy sonidan oshdingiz. Yuqori chegaradan bahramand bo'lish uchun rejangizni yangilang.",
    youNeedToHaveLoyaltyProgram:
      "Bu funksiyadan foydalanish uchun sizda sodiqlik dasturi bo'lishi kerak!",
    price: "Narx",
    category: "Turkum",
    stockTracking: "Aktsiyalarni kuzatish",
    stockCount: "Aktsiyalar soni",
    taxed: "Soliq solingan",
    originalPrice: "Original narx",
    costPrice: "Narxlar narxi",
    unit: "Birlik",
    productImage: "Mahsulot tasviri",
    taxRate: "Soliq stavkasi",
    taxType: "Soliq turi",
    trackInventory: "Track inventarizatsiyasi",
    variants: "Variantlar",
    hasVariants: "Variantlari bor",
    importProduct: "Mahsulotni import qilish",
    exportProducts: "Mahsulotlarni eksport qilish",
    addNewProduct: "Yangi mahsulot qo'shish",
    viewCategory: "Kategoriyani ko'rish",
    viewSuppliers: "Yetkazib beruvchilarni ko'rish",
    receiveInventory: "Inventarizatsiya qilish",
    printAllProductsTag: "Barcha mahsulotlar yorlig'ini chop eting",
    deleteAll: "Hammasini o'chirish",
    totalProducts: "UMUMIY MAHSULOTLAR",
    youveSuccessfullyAddedANewCategory:
      "Siz yangi toifani muvaffaqiyatli qo'shdingiz",
    addNewCategory: "Yangi toifani qo'shish",
    addCategory: "Kategoriya qo'shish",
    categoryName: "Kategoriya nomi",
    pleaseEnterCategoryName: "Iltimos, toifa nomini kiriting",
    stampsTarget: "Pochta markalari",
    sendInventory: "Inventarizatsiyani yuborish",
    productDetails: "Mahsulot tafsilotlari",
    youveSuccessfullyEditedThisCategory:
      "Siz ushbu toifani muvaffaqiyatli tahrir qildingiz",
    update: "Yangilash",
    categoryList: "Kategoriyalar ro'yxati",
    categories: "Kategoriyalar",
    enterQuantityToUpdate: "Yangilash uchun miqdorni kiriting",
    inventorySentSuccessfully: "Inventarizatsiya muvaffaqiyatli yuborildi!",
    updateInventory: "Inventarizatsiyani yangilash",
    currentQuantity: "Hozirgi miqdori",
    pleaseEnterQuantityToAdd: "Iltimos, qo'shmoqchi bo'lgan miqdorni kiriting",
    pleaseSelectABranch: "Iltimos, filialni tanlang",
    searchForBranch: "Filialni qidiring",
    youCantSendMoreThanStock: "Siz zaxirada bo'lganidan ko'proq yuborolmaysiz",
    send: "Yuborish",
    pleaseEnterQuantityToSend: "Iltimos, yubormoqchi bo'lgan miqdorni kiriting",
    productNameIsRequiredOnRow: "mahsulot nomi qatorda bo'lishi shart",
    productCategoryIsRequiredOnRow: "mahsulot toifasi qatorda talab qilinadi",
    productPriceIsRequiredOnRow: "mahsulot narxi ketma -ket talab qilinadi",
    productUnitIsRequiredOnRow: "mahsulot birligi qatorda talab qilinadi",
    productQuantityIsRequiredOnRow: "mahsulot miqdori qatorda talab qilinadi",
    productVariantsRequireTracking:
      "mahsulot variantlari kuzatishni talab qiladi!",
    pleaseAddVariantClickButton:
      "Iltimos, qo'shish tugmasini bosish orqali variantni qo'shing!",
    totalVariantsMoreThanSelectedQuantity:
      "Umumiy variantlar tanlangan mahsulot miqdoridan ko'p, Pls variant miqdorini kamaytiradi",
    productEditedSuccessfully: "Mahsulot muvaffaqiyatli tahrir qilindi!",
    fileTooLargeLessThan4Mb:
      "Fayl hajmi juda katta! Fayl hajmi 4 MB dan oshmasligi kerak.",
    suchVariantAlreadyExist: "Bunday variant allaqachon mavjud",
    addVariants: "Variantlarni qo'shing",
    editProduct: "Mahsulotni tahrirlash",
    pleaseEnterProductName: "Iltimos, mahsulot nomini kiriting",
    pleaseEnterProductPrice: "Iltimos, mahsulot narxini kiriting",
    pleaseEnterProductOriginalPrice:
      "Iltimos, mahsulotning asl narxini kiriting",
    productDescription: "Mahsulot tavsifi",
    selectProductCategory: "Mahsulot toifasini tanlang",
    pleaseSelectProductCategory: "Iltimos, mahsulot toifasini tanlang",
    productCostPrice: "Mahsulot tannarxi narxi",
    productSellingPrice: "Mahsulot sotish narxi",
    productOriginalPrice: "Mahsulotning asl narxi",
    maxFileSizeAllowedIs4Mb: "Maks. Ruxsat etilgan fayl hajmi 4 mb",
    productsWithPicturesArePublished:
      "Buyurtmalarni qabul qilish uchun rasmli mahsulotlar Discoverda e'lon qilinadi",
    shouldThisProductBeTracked: "Bu mahsulotni kuzatish kerakmi?",
    pleaseSelectStockUnit: "Iltimos, aksiya birligini tanlang",
    stockUnit: "Qimmatli qog'ozlar birligi",
    bag: "BAG",
    bottle: "BOSHQA",
    bunch: "BUNCH",
    can: "MUMKIN",
    carton: "KARTON",
    crate: "KRAT",
    cup: "CUP",
    dozen: "Do'Z",
    gigabytes: "GIGABITLAR",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "Juft",
    pieces: "PARÇALAR",
    plate: "TABAK",
    tonne: "TON (MT)",
    uNIT: "UNIT",
    yard: "YARD",
    pleaseEnterProductQuantity: "Iltimos, mahsulot miqdorini kiriting",
    productQuantity: "Mahsulot miqdori",
    isThisProductTaxed: "Bu mahsulot soliqqa tortiladimi?",
    selectTaxType: "Soliq turini tanlang",
    pleaseSelectTaxType: "Iltimos, soliq turini tanlang",
    progressive: "Progressiv",
    proportional: "Proportional",
    pleaseEnterProductTaxRate: "Mahsulotga soliq stavkasini kiriting",
    doesProductHaveVariants: "Ushbu mahsulotning variantlari bormi?",
    type: "Turi",
    value: "Qiymat",
    pleaseEnterVariantType: "Variant turini kiriting",
    pleaseEnterVariantValue: "Variant qiymatini kiriting",
    pleaseEnterVariantPrice: "Variant narxini kiriting",
    pleaseEnterVariantQuantity: "Variant miqdorini kiriting",
    productDeletedSuccessfully: "Mahsulot muvaffaqiyatli o'chirildi!",
    categoryDeletedSuccessfully: "Kategoriya muvaffaqiyatli o'chirildi!",
    toDeleteThisProduct: "ushbu mahsulotni o'chirish uchun",
    invalidProductQuantity: "Mahsulot miqdori noto'g'ri",
    quantityAddedIsOverStock:
      "Siz qo'shgan miqdor sizda bor narsadan oshib ketadi.",
    itemInventoryNotTracked: "Ob'ektlar inventarizatsiyasi kuzatilmaydi",
    addBulkQuantity: "Ommaviy miqdorni qo'shing",
    enterBulkQuantity: "Ommaviy miqdorni kiriting",
    pleaseEnterBulkQuantity: "Ommaviy miqdorni kiriting",
    youveSuccessfullyAddedANewProduct:
      "Siz yangi mahsulotni muvaffaqiyatli qo'shdingiz!",
    pleaseEnterProductSellingPrice: "Mahsulot sotish narxini kiriting",
    doYouWantToTrackProductStock: "Mahsulot zaxirasini kuzatmoqchimisiz?",
    sellingPrice: "Sotish narxi",
    setProductExpiryReminder:
      "Mahsulotning yaroqlilik muddati haqida eslatmani o'rnating",
    productExpiryDate: "Mahsulotning amal qilish muddati",
    startRemindingFrom: "Qaydan eslashni boshlang",
    productSuppliesAddedSuccessfully:
      "Siz mahsulot ta'minotini muvaffaqiyatli qo'shdingiz.",
    addProductSupplies: "Mahsulot zaxiralarini qo'shing",
    pleaseSelectSupplier: "Iltimos, etkazib beruvchini tanlang",
    nameOfProduct: "Mahsulot nomi",
    pleaseSelectProduct: "Iltimos, mahsulotni tanlang",
    productVariant: "Mahsulot varianti",
    pleaseSelectAVariant: "Iltimos, variantni tanlang",
    pleaseEnterUnitPrice: "Iltimos, birlik narxini kiriting",
    businessBranch: "Biznes bo'limi",
    pleaseSelectBranch: "Iltimos, filialni tanlang",
    youveSuccessfullyAddedANewSupplier:
      "Siz yangi etkazib beruvchini muvaffaqiyatli qo'shdingiz",
    addSupplier: "Yetkazib beruvchini qo'shish",
    pleaseEnterSupplierEmail:
      "Iltimos, etkazib beruvchining elektron pochta manzilini kiriting",
    pleaseAddADescription: "Iltimos, tavsif qo'shing",
    anErrorOccuredProductsDeleted:
      "Operatsiyani bajarishda xatolik yuz berdi. E'tibor bering, bu jarayonda bir nechta mahsulot o'chirilgan bo'lishi mumkin",
    bulkDelete: "Ommaviy o'chirish",
    youAreAboutToDelete: "Siz o‘chirmoqchisiz",
    product: "Mahsulot",
    isDueToRedeem: "qutqarish bilan bog'liq",
    aReward: "mukofot",
    pleaseSelectCustomerToReeemReward:
      "Iltimos, mukofot olish uchun mijozni tanlang.",
    youHaveNoLoyaltyProgramRunning: "Sizda sodiqlik dasturi ishlamayapti",
    customerHhasNoPointsInLoyaltyProgram:
      "Xaridorning sodiqlik dasturida hech qanday nuqtasi yo'q",
    proceedWithPayment: "To'lov davom ettirilsinmi?",
    youAreAboutToPayForTransactionUsingPoints:
      "Siz ballar yordamida tranzaksiya uchun pul to'lamoqchisiz.",
    customerHas: "Xaridor bor",
    worth: "qiymat",
    andIsNotSufficientToPayForTransaction:
      "va bu operatsiyani to'lash uchun etarli emas. Balansni boshqa to'lov usuli yordamida qo'shishni xohlaydilarmi?",
    addCustomerLoyalty: "Mijozlar sodiqligini qo'shing",
    pleaseAddCustomerFirst: "Iltimos, avval mijoz qo'shing yoki tanlang.",
    pleaseWaitWhileWeGetReady: "Iltimos, tayyor bo'lguncha kuting",
    lowStock: "Kam aktsiya",
    pleaseEnterAmountTendered: "Iltimos, taklif qilingan miqdorni kiriting",
    areYouSureToBookSaleOffline:
      "Haqiqatan ham bu sotuvni oflaynda band qilmoqchimisiz?",
    saleWouldBeBookedAutomatically:
      "Agar siz Internetni yoqsangiz, sotish avtomatik ravishda bron qilinadi",
    offlineSalesBookingCancelled: "Oflayn savdo buyurtmasi bekor qilindi",
    covid19Message:
      "COVID19: tarqalishni to'xtatish uchun qo'lingizni sovun bilan yuving yoki tozalang. Har doim xavfsiz bo'ling",
    saleSuccessfullyRecorded: "Sotuv muvaffaqiyatli qayd etildi!",
    sendReceiptToEmail: "Kvitansiyani elektron pochtaga yuboring",
    sendThankYouSms: "Rahmat SMS yuboring",
    sendShippingDetails: "Yetkazib berish tafsilotlarini yuboring",
    addLoyalty: "Sadoqatni qo'shing",
    searchCustomerNameOrNumber: "Mijozning ismini yoki raqamini qidiring",
    clickTheSearchCustomerBox:
      "Mijozlarni qidirish qutisini va skanerlash kartasini bosing",
    enterProductPrice: "Mahsulot narxini kiriting",
    enterPriceFor: "Narxini kiriting",
    searchForProduct: "Mahsulotni qidiring",
    all: "Hammasi",
    backToDashboard: "Boshqaruv paneliga qaytish",
    viewDraftSales: "Loyiha savdosini ko'rish",
    variantSelected: "variant tanlangan",
    variant: "variant",
    thePreviousVariantSelectionNotAvailable:
      "Oldingi variant varianti narxga qarab tanlangan yangi variant uchun mavjud emas, iltimos tanlovingizni o'zgartiring.",
    pleaseSupplyPositiveQuantityNumber: "Iltimos, pozitsiyali raqamni kiriting",
    lowStockFor: "Uchun kam zaxira",
    clearVariants: "Aniq variantlar",
    pleaseEnterQuantity: "Iltimos, miqdorini kiriting",
    picture: "Rasm",
    redemptionToken: "Qutqarish belgisi",
    token: "Token",
    totalSpent: "Jami sarflangan",
    confirmPayment: "To'lovni tasdiqlang",
    hasPaymentBeenMade: "To'lov muvaffaqiyatli amalga oshirildimi?",
    enterTransactionReference: "Siz to'lagan bitim ma'lumotnomasini kiriting",
    pleaseSelectACustomer: "Iltimos, mijozni tanlang!",
    areYouSureToApplyDiscount: "Haqiqatan ham chegirmadan foydalanmoqchimisiz?",
    addYourBankAccountToEnableUssd:
      "UPay orqali USSD orqali tezkor uzatishni yoqish uchun bank hisobingizni qo'shing",
    totalAmountToPay: "To'lanadigan umumiy miqdor",
    doYouWantToCancelTransaction: "Bu tranzaksiyani bekor qilmoqchimisiz?",
    savePrintBill: "Hisobni saqlash/chop etish",
    enterAmountCollectedForCustomer:
      "Xaridor uchun yig'ilgan miqdorni kiriting",
    recordSale: "Rekord sotuvi",
    checkOutWith: "Bilan tekshiring",
    instantTransfer: "Tezkor o'tkazish",
    dialTheUSSDCode: "USSD kodini tering",
    pleaseSelectABank: "Iltimos, bankni tanlang",
    payWithUSSD: "USSD bilan to'lash",
    sendBillTo: " - Billni yuboring",
    waitingForUSSDTransfer: "USSD uzatish kutilmoqda",
    percent: "Foiz",
    applyDiscount: "Chegirmani qo'llang",
    thisBillHasBeenSaved: "Bu Bill saqlandi",
    saveDraft: "Qoralamani saqlash",
    pleaseTypeANameToIdentifyCustomer: "Mijozni aniqlash uchun ism kiriting",
    paymentDetails: "To'lov tafsilotlari",
    basePrice: "Asosiy narx",
    staff: "Xodimlar",
    subTotal: "Jami",
    durationMonths: "Davomiyligi (oylar)",
    selectADuration: "Davomiyligini tanlang",
    oneMonth: "1 oy",
    twoMonths: "2 oy",
    threeMonths: "3 oy",
    sixMonths: "6 oy",
    twelveMonths: "12 oy",
    eighteenMonths: "18 oy",
    twentyFourMonths: "24 oy",
    twoMonthsFree: "(2 oy bepul)",
    threeMonthsFree: "(3 oy bepul)",
    fiveMonthsFree: "(5 oy bepul)",
    yourAccountHasBeen: "Sizning hisobingiz bor edi",
    renewed: "yangilandi",
    upgraded: "yangilandi",
    successfully: "muvaffaqiyatli",
    yourSubscription: "Sizning obunangiz",
    youAreCurrentlyEnrolledOnA: "Siz hozirda a ga ro'yxatdan o'tdingiz",
    pleaseChooseAPaymentOption: "Iltimos, to'lov variantini tanlang",
    planRenewal: "Yangilash rejasi",
    planUpgrade: "Rejani yangilash",
    pleaseSelectDurationToPayFor: "Iltimos, to'lamoqchi bo'lgan vaqtni tanlang",
    staffAccounts: "Xodimlar hisobi",
    ecommerce: "Elektron tijorat",
    pleaseSelectAPlan: "Iltimos, rejani tanlang",
    includeAddons: "Qo'shimchalarni qo'shing",
    viewTransactions: "Tranzaktsiyalarni ko'rish",
    customerHasNoCompletedTansactions:
      "Mijozda hali tugallangan tansaksiyalar yo'q",
    branch: "Filial",
    enterNumberOfEcommerceBranches:
      "Elektron tijorat filiallari sonini kiriting",
    enterNumberOfEcommerceBranchesToPay:
      "Siz to'lamoqchi bo'lgan elektron tijorat filiallarining sonini kiriting",
    ecommerceIntegration: "Elektron tijorat integratsiyasi",
    enterNumberOfBranches: "Filiallar sonini kiriting",
    enterNumberOfAdditionalBranchesToPay:
      "Siz to'lamoqchi bo'lgan qo'shimcha filiallar sonini kiriting",
    enterNumberOfStaffs: "Xodimlar sonini kiriting",
    enterNumberOfStaffsToPayFor:
      "Siz to'lamoqchi bo'lgan xodimlar sonini kiriting",
    discountApplies: "Chegirma amal qiladi",
    starsOnThe: "ustidagi yulduzlar",
    offer: "taklif",
    get: "Oling",
    moreStarsToRedeem: "qutqarish uchun ko'proq yulduzlar",
    taxVat: "Soliq (QQS)",
    callCashierToCompletePayment:
      "To'lovni yakunlash uchun kassirga qo'ng'iroq qiling",
    receipt: "Qabul",
    dear: "Aziz",
    thankYouForYourOrderFindGoods:
      "Buyurtma uchun rahmat. Iltimos, kelishilganidek, quyidagi tovarlarni toping.",
    shippingNote: "Yuk tashish haqida eslatma",
    enterShippingNote: "Yetkazib berish eslatmasini kiriting",
    shippingAddress: "Yetkazib berish manzili",
    enterShippingAddress: "Yetkazib berish manzilini kiriting",
    wellDoneYouAreDueToRedeem: "Juda qoyil! Siz qutqarishingiz kerak",
    toGetYourRewardNextVisit:
      "keyingi tashrifingizda mukofotingizni olish uchun. rahmat",
    pointsOnThe: "Belgilar",
    morePointsToRedeem: "sotib olish uchun ko'proq ball",
    showCode: "Kodni ko'rsatish",
    toGetYourRewardOnNextVisit:
      "keyingi tashrifingizda mukofotingizni olish uchun. rahmat",
    earn: "Ishlab topmoq",
    delivaryNote: "Qutqaruv eslatmasi",
    draftSales: "Loyihani sotish",
    startDate: "Boshlanish vaqti",
    endDate: "Tugash sanasi",
    orders: "Buyurtmalar",
    checkout: "tekshirib ko'rmoq",
    noProductItem: "Mahsulot elementi yo'q",
    selectProductImage: "Mahsulot tasvirini tanlang",
    selectCountry: "Mamlakatni tanlang",
    selectRegion: "Shtat/Mintaqani tanlang",
    printProductTag: "Mahsulot yorlig'ini chop etish",
    transactionReference: "Tranzaksiya ma'lumotnomasi",
    Cashier: "Kassir",
    Manager: "Menejer",
    Owner: "Egasi",
    Admin: "Admin",
    addPartners: "Hamkorlar qo'shing",
    addNewLoyaltyPartner: "Yangi sodiq sherik qo'shing",
    pleaseEnterCompanyName: "Kompaniya nomini kiriting",
    companyName: "kopmaniya nomi",
    pleaseEnterCompanyRepName: "Iltimos, kompaniya vakili nomini kiriting",
    companyRepName: "Kompaniya vakilining ismi",
    pleaseEnterCompanyRepEmail:
      "Iltimos, kompaniya vakilining elektron pochta manzilini kiriting",
    companyRepEmail: "Kompaniya vakilining elektron pochtasi",
    pleaseEnterCompanyRepPhone:
      "Iltimos, kompaniya vakilining telefon raqamini kiriting",
    companyRepPhone: "Kompaniya vakilining telefon raqami",
    addReward: "Mukofot qo'shing",
    pleaseEnterRewardName: "Iltimos, mukofot nomini kiriting",
    rewardName: "Mukofot nomi",
    rewardQuantity: "Mukofot miqdori",
    rewardDescription: "Mukofot tavsifi",
    rewardType: "Mukofot turi",
    pleaseEnterRewardType: "Iltimos, mukofot turini kiriting",
    pleaseEnterRewardQuantity: "Iltimos, mukofot miqdorini kiriting",
    pleaseEnterRewardDescription: "Iltimos, mukofot tavsifini kiriting",
    fineDining: "Yaxshi ovqatlanish",
    luxuryFashion: "Hashamatli moda",
    hotels: "Mehmonxonalar",
    travel: "Sayohat",
    foodAndBeverage: "Oziq-ovqat va ichimliklar",
    fashion: "Moda",
    health: "Salomatlik",
    furniture: "Mebel",
    entertainment: "O'yin-kulgi",
    automobile: "Avtomobil",
    education: "Ta'lim",
    beautyAndSpa: "Go'zallik va kurort",
    staycation: "Turish",
    events: "Voqealar",
    trips: "Safarlar",
    oilAndGas: "Neft va gaz",
    laundry: "Kir yuvish",
    partnerCategory: "Hamkorlar toifasi",
    freeItem: "Bepul element",
  },
  Yiddish: {
    cashier: "קאַסירער",
    manager: "פאַרוואַלטער",
    owner: "באַזיצער",
    online: "אָנליין",
    offline: "אָפפלינע",
    changePassword: "טוישן שפּריכוואָרט",
    currentPasswordMessage: "ביטע אַרייַן אייער קראַנט פּאַראָל",
    passwordMessage: "ביטע אַרייַן אייער פּאַראָל",
    currentPassword: "Current Password",
    password: "פּאַראָל",
    confirmPasswordMessage: "באַשטעטיקט אײַער פּאַראָל!",
    confirmPassword: "באַשטעטיקן שפּריכוואָרט",
    sendViaEmail: "שיקן דורך E- בריוו",
    sendViaWhatsapp: "שיקן דורך ווהאַצאַפּפּ",
    downloadPdf: "דאַונלאָוד פּדף",
    paid: "באַצאָלט",
    unpaid: "ניט באַצאָלט",
    partial: "פּאַרטיייש",
    closeInvoice: "צי איר ווילן צו פאַרמאַכן די ינווויס?",
    closeInvoiceConfirmation:
      "רעכענונג קען נישט זיין גילטיג. צי איר ווילן צו פאַרמאַכן?",
    yes: "יאָ",
    no: "קיין",
    invoice: "ינווויס",
    wasDeducted: "איז געווען דידאַקטיד",
    for: "פֿאַר",
    item: "פּונקט",
    addProduct: "לייג פּראָדוקט",
    paymentReference: "צאָלונג רעפערענץ",
    amountPaid: "סכום באַצאָלט",
    discountAmount: "אַראָפּרעכענען סומע",
    amountDue: "סכום צו באַצאָלן",
    amount: "סכום",
    dueDate: "פעליק טאָג",
    paymentType: "צאָלונג טיפּ",
    card: "קאַרטל",
    cash: "געלט",
    bankTransfer: "באַנק אַריבערפירן",
    paymentMessage: "צאָלונג מעסעדזש",
    description: "באַשרייַבונג",
    sendReceipt: "שיקן קאַבאָלע",
    delete: "ויסמעקן",
    save: "היט",
    resend: "שייַעך-שיקן",
    saveAndSend: "היט",
    invoiceSaved: "ינווויס געראטעוועט!",
    selectPaymentMethod: "ביטע סעלעקטירן אַ צאָלונג מעטאַד!",
    selectCustomer: "ביטע סעלעקטירן אַ קונה!",
    cartEmptyError:
      "וואָגן רשימה קענען ניט זיין ליידיק, פאַרמאַכן די ינווויס און לייגן די נומער צו די וואָגן!",
    subscriptionExpired:
      "דיין אַבאָנעמענט איז אויסגעגאנגען און דיין חשבון איז איצט לימיטעד. דריקט אויף די קנעפּל אונטן צו באַנייַען דיין חשבון",
    renew: "באַנײַען",
    holdOn: "האלט אן",
    customerBank: "קונה באַנק",
    cancel: "באָטל מאַכן",
    selectACustomer: "אויסקלייַבן קונה",
    invoiceSuccessfulPdfError:
      "ינווויס איז הצלחה באשאפן אָבער פּדף דור נעמט מער ווי געוויינטלעך. ביטע טשעק צוריק באַלד.",
    downloadingInvoice: "דאַונלאָודינג ינוואָיסע",
    downloadingReceipt: "דאַונלאָודינג קאַבאָלע",
    whatsappReceiptError:
      "א טעות פארגעקומען שיקט די קאַבאָלע דורך ווהאַצאַפּפּ. ביטע פּרובירן ווידער.",
    receiptToWhatsapp: "קאַבאָלע פאָרווערדיד צו ווהאַצאַפּפּ",
    thankYouForPatronage: "דאנק איר פֿאַר דיין שטיצן",
    hereIsYourReceipt: "דאָ איז דיין צאָלונג קאַבאָלע",
    errorSendingEmailReceipt:
      "עס איז געווען אַ טעות מיט שיקט די קאַבאָלע דורך E- בריוו. ביטע פּרובירן ווידער אָדער קאָנטאַקט די שטיצן",
    receiptSentToEmail: "דער קאַבאָלע איז געשיקט צו דער E- בריוו פון דער קונה",
    invoiceSentToEmail: "ינווויס איז געשיקט צו דער E- בריוו פון דער קונה",
    invoiceSuccessWhatsappError:
      "ינווויס איז הצלחה באשאפן אָבער אַ טעות איז געווען שיקט צו WhatsApp. פּרוּווט ווידער אויף די ינווויס רשימה",
    invoiceSuccessfulEmailError:
      "ינווויס איז הצלחה באשאפן אָבער אַ טעות איז פארגעקומען בשעת שיקט ווי E- בריוו. ביטע פּרוּווט ווידער פֿון דער ינווויס רשימה",
    invoiceSentToWhatsapp: "רעכענונג פאָרווערדיד צו ווהאַצאַפּפּ",
    hello: "העלא",
    pleaseDownloadInvoice: "ביטע אראפקאפיע די ינווויס",
    pleaseDownloadReceipt: "ביטע אראפקאפיע קאַבאָלע",
    from: "פון",
    email: "בליצפּאָסט",
    upgrade: "Upgrade",
    youAreOnFreePlan: "איר זענט אויף אַ פריי פּלאַן.",
    clickOn: "דריקט אויף",
    yourPlanInFewSteps: " דיין אַבאָנעמענט אין עטלעכע שנעל סטעפּס.",
    to: "צו",
    yourSubscriptionHasExpired:
      "דיין אַבאָנעמענט איז אויסגעגאנגען און דיין חשבון איז איצט לימיטעד.",
    days: "טעג",
    yourSubscriptionExpiresIn: "דיין Loystar אַבאָנעמענט יקספּייערז אין",
    createAcount: "שאַפֿן אַ אַקאַונט",
    signIn: "סיין אריין",
    continue: "פאָרזעצן",
    enterOtp: "אַרייַן אָטפּ PIN",
    enterValidOtp: "אַרייַן אַ גילטיק PIN",
    pin: "PIN",
    tokenSentToMail: "א סימען איז געשיקט צו דיין E- בריוו",
    passwordResetSuccessful: "באַשטעטיק פּאַראָל איז געווען מצליח",
    somethingWentWrong: "עפּעס איז פאַלש!",
    resetPassword: "באַשטעטיק שפּריכוואָרט",
    iHaveResetCode: "איך האָבן אַ פּאַראָל באַשטעטיק קאָד",
    pleaseEnterEmail: "ביטע אַרייַן אייער בליצפּאָסט",
    aTokenWillBeSentToEmail: "א סימען וואָלט זיין געשיקט צו דיין E- בריוו",
    enterEmail: "אַרייַן אייער בליצפּאָסט",
    sendinYourToken: "שיקט דיין סימען ...",
    makeSureItMatchesPassword: "מאַכן זיכער אַז עס גלייַכן דיין נייַ פּאַראָל",
    pleaseChooseNewPassword: "ביטע קלייַבן אַ נייַ פּאַראָל",
    chooseNewPassword: "קלייַבן אַ נייַ פּאַראָל",
    enterNewPassword: "אַרייַן אייער נייַ פּאַראָל צו באַשטעטיקן",
    enterTokenSent: "אַרייַן די סימען וואָס איז געשיקט דיין פּאָסט",
    resetToken: "באַשטעטיק טאָקען",
    resettingPassword: "באַשטעטיק דיין פּאַראָל ...",
    signUp: "צייכן אַרויף",
    adminSignInWithEmail:
      " דער אַדמיניסטראַטאָר קלאָץ אין מיט E- בריוו בשעת דער שטעקן קלאָץ אין מיט די נאמען.",
    pleaseEnterEmailOrUsername: "ביטע אַרייַן אייער בליצפּאָסט אָדער נאמען",
    emailOrUsername: "אימעיל אדער באנוצער - נאמען",
    pleaseEnterPassword: "ביטע אַרייַן פּאַראָל",
    createAnAccount: "שאַפֿן אַ אַקאַונט",
    forgotPassword: "פֿאַרגעסן שפּריכוואָרט?",
    enterPhoneNumber: "אַרייַן טעלעפאָנירן נומער",
    personalData: "פערזענלעכע דאַטאַ",
    validateConfirmationCOde: "באַשטעטיקן באַשטעטיקונג קאָד",
    pleaseEnterFirstName: "ביטע אַרייַן אייער ערשטער נאָמען",
    pleaseEnterPhoneNumber: "ביטע אַרייַן אייער טעלעפאָן נומער",
    pleaseEnterLastName: "ביטע אַרייַן אייער לעצטע נאָמען",
    pleaseEnterBusinessName: "ביטע אַרייַן אייער געשעפט נאָמען",
    firstName: "ערשטע נאמען",
    lastName: "לעצטע נאמען",
    businessName: "ביזנעס נאָמען",
    previous: "פֿריִערדיקע",
    next: "ווייטער",
    pleaseSelectBusinessCategory: "ביטע אויסקלייַבן דיין געשעפט קאַטעגאָריע",
    pleaseEnterValidEmail: "ביטע אַרייַן אַ גילטיק בליצפּאָסט",
    pleaseEnterPostCode: "ביטע אַרייַן פּאָסט קאָד",
    postCode: "פּאָסט קאָוד",
    phoneNumberInUse: "דער טעלעפאָן נומער איז שוין אין נוצן!",
    emailInUse: "דעם בליצפּאָסט איז שוין אין נוצן!",
    foodAndDrinks: "עסן און טרינקען",
    salonAndBeauty: "סאַלאָן און שיינקייט",
    fashionAndAccessories: "מאָדע און אַקסעססאָריעס",
    gymAndFitness: "ספּאָרטזאַל און טויגיקייט",
    travelAndHotel: "רייזע און האטעל",
    homeAndGifts: "היים און גיפס",
    washingAndCleaning: "וואַשינג און רייניקונג",
    gadgetsAndElectronics: "גאַדגעץ און עלעקטראָניק",
    groceries: "גראָסעריעס",
    others: "אנדערע",
    submit: "פאָרלייגן",
    accountCreatedSuccessful: "דיין אקאונט איז באשאפן געווארן מיט הצלחה.",
    pleaseEnterAddress: "ביטע אַרייַן אייער אַדרעס",
    addressLine1: "אַדרעס ליניע 1",
    addressLine2: "אַדרעס ליניע 2",
    choosePassword: "קלייַבן שפּריכוואָרט",
    passwordMustBe6Characters: "פּאַראָל מוזן זיין לפּחות 6 אותיות.",
    howDidYouHearLoystar: "ווי האָט איר געהערט וועגן Loystar?",
    referralCode: "רעפערראַל קאָוד",
    byClickingTheButton:
      " דורך געבן אַ קליק דעם קנעפּל אונטן, איר שטימען צו דעם",
    termsAndSevice: "תּנאָים",
    wereCreatingAccount: "מיר מאַכן דיין חשבון",
    proceed: "גיינ ווייַטער",
    verificationCodeMustBe6: "די וועראַפאַקיישאַן קאָוד מוזן זיין 6 דידזשאַץ",
    pleaseEnter6DigitCode: "ביטע אַרייַן 6 ציפֿער קאָד",
    enterVerificationCode: "אַרייַן וועריפיקאַטיאָן קאָוד",
    resendToken: "רעסענד טאָקען",
    verify: "באַשטעטיקן",
    transactions: "טראַנזאַקשאַנז",
    todaySales: "הייַנט ס סאַלעס",
    salesHistory: "פארקויפונג געשיכטע",
    supplyHistory: "צושטעלן געשיכטע",
    new: "New",
    invoices: "ינווויס",
    disbursements: "אויסצוגן",
    offlineSales: "אָפפלינע סאַלעס",
    products: "פּראָדוקטן",
    customers: "קוסטאָמערס",
    multiLevelLoyalty: "לויאַלטי פון פילע לעוועלס",
    loyaltyPrograms: "לויאַלטי מגילה",
    members: "מיטגלידער",
    appStore: "אַפּ סטאָר",
    orderMenu: "סדר מעניו",
    settings: "סעטטינגס",
    staffAndBranches: "שטעקן און בראַנטשעס",
    myAccount: "מיין אקאונט",
    switchToSellMode: "באַשטימען צו סעלל מאָדע",
    signOut: "צייכן אויס",
    getFreeSubscription: "באַקומען אַ פריי אַבאָנעמענט",
    onlyNumbersAllowed: "בלויז נומערן זענען ערלויבט",
    yourAccountMustBe10Digits: "דיין חשבון נומער מוזן זיין אַ 10 ציפֿער נומער",
    yourBvnMustBe11: "דיין BVN מוזן זיין אַ 11 ציפֿער נומער",
    pleaseSelectBank: "ביטע סעלעקטירן דיין באַנק",
    selectYourBank: "סעלעקטירן דיין באַנק",
    enterBankAccountNumber: "אַרייַן באַנק אַקאַונט נומער",
    enterBvnNumber: "אַרייַן דיין BVN",
    connectBankAccount: "פאַרבינדן די באַנק אקאונט",
    passwordResetSuccessfulAndSignOut:
      "דיין שפּריכוואָרט איז הצלחה ריסטאַרט. דריקט אויף די צייכן אָוט קנעפּל אונטן צו קלאָץ אין ווידער",
    enterCurrentPassword: "אַרייַן קראַנט פּאַראָל",
    pleaseEnterCurrentPassword: "ביטע אַרייַן די קראַנט פּאַראָל",
    phoneNumber: "טעלעפאָנירן נומער",
    sex: "סעקס",
    dateOfBirth: "געבורטס טאג",
    state: "שטאַט",
    country: "לאַנד",
    loyaltyId: "לויאַלטי שייַן",
    createdAt: "באשאפן אין",
    noOfTransactionsMade: "נומער פון טראַנזאַקשאַנז געמאכט",
    yourDownloadWillStart: "דיין אראפקאפיע וועט אָנהייבן אין אַ מאָמענט",
    exportCustomers: "אַרויספירן קוסטאָמערס",
    youhaveSuccessfullyToppedUp: "איר האָט הצלחה טאַפּט דיין SMS וניץ.",
    youNowHave: "איר איצט האָבן",
    smsUnits: "SMS וניץ",
    enterNumberOfSmsUnits: "אַרייַן די נומער פון SMS וניץ איר ווילן צו קויפן",
    pleaseEnterANumericValue: "ביטע אַרייַן אַ נומעריק ווערט",
    pay: "באַצאָלן",
    accountEditedSuccessfully: "אייער קאנטע איז געווארן ערפאלג רעדאקטירט.",
    youAeCurrentlyOn: "איר זענט איצט אויף",
    plan: "פּלאַן",
    userData: "באַניצער דאַטאַ",
    businessData: "BUSINESS DATA",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "ביטע אויסקלייַבן דיין קראַנטקייַט",
    selectYourCurrency: "אויסקלייַבן דיין קראַנטקייַט",
    purchaseMoreSmsUnits: "קויפן מער SMS וניץ מיט די פאָרעם אונטן",
    youHave: "דו האסט",
    atLeast4SmsUnitsRrequired:
      "פֿאַר וועראַפאַקיישאַן איז פארלאנגט לפּחות 4 SMS וניץ.",
    pleaseVerifyYourAccountToComplyWithKyc:
      "באַשטעטיקן דיין באַנק אקאונט צו נאָכקומען מיט די רעקווירעמענץ 'וויסן דיין קונה' (KYC). דאָס אַלאַוז איר צו זאַמלען פּיימאַנץ דורך וססד אָדער רעגע אַריבערפירן, באַקומען אָרדערס פון קאַסטאַמערז און פּראָצעס טראַנסאַקטיאָן ייִשובֿ. אָנווענדלעך בלויז צו ניגעריאַן סוחרים. דריקן דעם קנעפּל אונטן צו אָנהייבן.",
    reConnectBankAccount: "פאַרבינדן די באַנק חשבון ווידער",
    accountName: "חשבון נאָמען",
    accountNumber: "חשבון נומער",
    bankName: "באַנק נאָמען",
    verified: "וועראַפייד",
    accountDetails: "חשבון דעטאַילס",
    kycBankAccount: "KYC",
    createTier: "שאַפֿן טיר",
    fileUploadSuccessful: "טעקע ופּלאָאַדעד הצלחה",
    fileUploadFailed: "טעקע ופּלאָאַד ניט אַנדערש",
    createLoyaltyProgram: "שאַפֿן אַ לויאַלטי פּראָגראַם",
    createLoyalty: "שאַפֿן לויאַלטי",
    name: "נאָמען",
    loyaltyArtwork: "לויאַלטי אַרטוואָרק",
    clickToUpload: "דריקט צו צופֿעליקער",
    amountToPointsRatio: "סומע צו פּאָינץ פאַרהעלטעניש",
    points: "פונקטן",
    recommendedAmountToPointRatio:
      'רעקאַמענדיד: וועגן 1 צו 1 פונט. ד"ה פֿאַר יעדער 1 נעראַ פארבראכט, דיין קאַסטאַמערז באַקומען 1 פונט',
    pleaseTypeIn: "ביטע אַרייַן",
    toDeleteLoyalty: "צו ויסמעקן דעם לויאַלטי",
    deleteLoyalty: "ויסמעקן לויאַלטי",
    toConfirm: "צו באַשטעטיקן",
    edit: "רעדאַגירן",
    pointsAwardedSuccessfully: "פּאָינץ אַוואָרדיד הצלחה.",
    enterPointValueToAward:
      "אַרייַן פונט ווערט איר וואָלט ווי צו אַוואָרד קאַסטאַמערז",
    award: "אַוואַרד",
    awardPointValuesToCustomer: "אַוואַרד פּוינט ווערט צו קונה",
    enrollMembersToLoyalty: "פאַרשרייַבן מיטגלידער אויף לויאַלטי",
    awardPoints: "אַוואַרד פּאָינץ",
    enroll: "פאַרשרייַבן",
    home: "היים",
    loyalty: "לויאַלטי",
    enrollCustomers: "פאַרשרייַבן קוסטאָמערס",
    selected: "אויסגעקליבן",
    customer: "קונה",
    loyaltyActivationSuccessful: "לויאַלטי אַקטאַוויישאַן איז געראָטן.",
    loyaltyDeactivationSuccessful: "לויאַלטי דיאַקטיוויישאַן איז געראָטן.",
    viewTier: "View טיר",
    deactivate: "דע-אַקטאַווייט",
    activate: "אַקטאַווייט",
    actions: "אַקטיאָנס",
    nameOfLoyalty: "נאמען פון לויאַלטי",
    loyaltyStatus: "לויאַלטי סטאַטוס",
    numberOfTiers: "נומער פון טירעס",
    createdOn: "באשאפן אויף",
    createATier: "שאַפֿן אַ טיר",
    stopCreatingTierConfirmation: "צי איר ווילן צו האַלטן קריייטינג אַ ריי?",
    stopEditingTierConfirmation: "צי איר ווילן צו האַלטן עדיטינג דעם ריי?",
    nameOfTier: "נאָמען פון טיר",
    minimumSpendingTarget: "מינימום ספּענדינג ציל",
    maximumSpendingTarget: "מאַקסימום ספּענדינג ציל",
    minimumSpendingTargetRequired: "מינימום ספּענדינג ציל איז רעקווירעד",
    maximumSpendingTargetRequired: "מאַקסימום ספּענדינג ציל איז רעקווירעד",
    rewardSponsor: "באַלוינונג ספאנסער",
    pleaseChooseARewardSponsor: "ביטע קלייַבן אַ באַלוינונג פּאַטראָן",
    self: "זיך",
    partner: "שותף",
    rewardPartner: "באַלוינונג פּאַרטנער",
    pleaseSelectRewardPartner: "ביטע סעלעקטירן דיין באַלוינונג שוטעף",
    rewards: "ריוואָרדז",
    pleaseSelectAReward: "ביטע סעלעקטירן אַ באַלוינונג",
    instructionsOnRedeemingReward:
      "ינסטראַקשאַנז אויף ווי קונה זאָל ויסלייזן באַלוינונג",
    pleaseFillInThisField: "ביטע פּלאָמבירן אין דעם פעלד!",
    toDeleteThisTier: " צו ויסמעקן דעם ריי",
    deleteTier: "Delete Tier",
    viewMembers: "View מיטגלידער",
    membersEnrolled: "מיטגלידער ענראָולד",
    instruction: "לימעד",
    membersIn: "מיטגלידער אין",
    availableTiersInLoyalty: "פאַראַנען טיר (s) אין לויאַלטי פּראָגראַם",
    tiers: "Tiers",
    totalTier: "TOTAL TIER",
    availableLoyaltyProgramme: "בנימצא לויאַלטי פּראָגראַם",
    totalLoyalties: "TOTAL LOYALTIES",
    memberDetails: "מיטגליד דעטאַילס",
    nameOfCustomer: "נאָמען פון קונה",
    address: "אַדרעס",
    allEnrolledMembers: "כל ענראָולד מיטגלידער",
    thisIsToWishYouHappyBirthday:
      "דאָס איז צו ווינטשן איר אַ זייער גליקלעך דיין געבורסטאָג און בליענדיק לעבן. דאַנקען דיר פֿאַר אַלע וואָס איר האָט צו Loyster. Happy Celebrations!",
    inputAnOfferPlease: "ביטע אַרייַן אַ פאָרשלאָג",
    pleaseSelectTheInsertPoint:
      "סעלעקטירן דעם אַרייַנלייגן פונט אין דער אָנזאָג און גיט ווידער",
    birthdayOfferAndMessage: "דיין געבורסטאָג פאָרשלאָג און אָנזאָג",
    birthdayOffer: "דיין געבורסטאָג פאָרשלאָג",
    birthdayMessage: "דיין געבורסטאָג מעסעדזש",
    noOfferFound: "קיין פאָרשלאָג געפֿונען",
    settingABirthdayOffer:
      "באַשטעטיקן אַ דיין געבורסטאָג פאָרשלאָג אַלאַוז קאַסטאַמערז צו באַקומען דעם פאָרשלאָג דורך SMS אויף דיין דיין געבורסטאָג",
    createOffer: "שאַפֿן פאָרשלאָג",
    whatIsTheOffer: "וואָס איז די פאָרשלאָג?",
    editMessage: "רעדאַגירן מעסעדזש",
    insert: "Insert",
    theNameOfCustomerInserted:
      "דער נאָמען פון דעם קונה וועט זיין ינסערטאַד דאָ",
    theBirtdayOfferInserted:
      "די דיין געבורסטאָג פאָרשלאָג וועט זיין ינסערטאַד דאָ",
    youSuccessfullyAddedNewBranch: "איר האָט הצלחה צוגעלייגט אַ נייַע צווייַג!",
    addNewBranch: "לייג ניו בראַנטש",
    addBranch: "לייג בראַנטש",
    additionalBranchWillIncur: "נאָך בראַנטש וואָלט זיין ינקערד",
    perBranch: "פּער צווייַג",
    ecommerceBranchCosts: "קאָס פֿאַר E- האַנדל צווייַג",
    pleaseEnterBranchName: "ביטע אַרייַן די צווייַג נאָמען",
    pleaseEnterBranchAddress1: "אַרייַן די אַדרעס שורה 1 פון די צווייַג",
    enterBranchAddress1: "אַרייַן די אַדרעס שורה 1 פון די צווייַג",
    enterBranchAddress2: "אַרייַן די אַדרעס שורה 1 פון די צווייַג",
    pleaseEnterBranchAddress2: "ביטע אַרייַן די אַדרעס שורה 2 פון די צווייַג",
    enterBranchName: "אַרייַן די צווייַג נאָמען",
    successfullyAddedStaff: "איר האָט הצלחה צוגעגעבן אַ נייַע שטעקן!",
    addNewStaff: "לייג ניו שטעקן",
    addStaff: "לייג שטעקן",
    additionalStaffWillIncur: "נאָך שטעקן וואָלט זיין ינקערד",
    perStaff: "פּער שטעקן.",
    pleaseEnterStaffEmail: "ביטע אַרייַן דעם בליצפּאָסט פון דעם שטעקן",
    pleaseEnterStaffUsername: "ביטע אַרייַן די נאמען פון דעם שטעקן",
    pleaseEnterStaffPassword: "ביטע אַרייַן די שטעקן ס פּאַראָל",
    pleaseSelectStaffRole: "ביטע סעלעקטירן דעם ראָלע פון דער שטעקן",
    selectStaffRole: "אויסקלייַבן די שטעקן ראָלע",
    enterStaffEmail: "אַרייַן די E- בריוו פון דעם שטעקן",
    enterStaffUsername: "אַרייַן די נאמען פון דעם שטעקן",
    enterStaffPassword: "אַרייַן די פּאַראָל פון די שטעקן",
    spacesNotAllowedInUsername: "ספּייסאַז ניט ערלויבט אין נאמען",
    admin: "אַדמין",
    pleaseSelectBusinessToAttachStaff:
      "ביטע סעלעקטירן געשעפט צו צוטשעפּען שטעקן",
    searchForBranchToAttachStaff: "זוכן פֿאַר אַ צווייַג צו צוטשעפּען שטעקן",
    username: "באַניצער נאָמען",
    role: "ראָלע",
    areYouSureToDeleteThis: "ביסט איר זיכער צו ויסמעקן דעם",
    branches: "צווייגן",
    upgradeYourPlan: "אַפּגרייד דיין פּלאַן.",
    add: "לייג צו",
    addNew: "לייג New",
    customerWithEmailAlreadyExists:
      "קונה מיט E- בריוו/טעלעפאָן נומער איז שוין יגזיסץ!",
    successfullyAddedNewCustomer: "איר האָט הצלחה צוגעלייגט אַ נייַע קונה!",
    addCustomer: "לייג צו קונה",
    pleaseEnterCustomerFirstName: "ביטע אַרייַן קונה ס ערשטער נאָמען",
    pleaseEnterCustomerLastName: "ביטע אַרייַן די לעצטע נאָמען פון די קונה",
    pleaseEnterCustomerPhoneNumber: "ביטע אַרייַן קונה ס טעלעפאָן נומער",
    pleaseEnterCustomerEmail: "ביטע אַרייַן קונה ס בליצפּאָסט",
    pleaseEnterCustomerAddressLine: "ביטע אַרייַן קונה ס אַדרעס ליניע",
    pleaseEnterCustomerOtherAddress: "ביטע אַרייַן קונה ס אנדערע אַדרעס",
    pleaseSelectCustomerGender: "ביטע סעלעקטירן דעם דזשענדער פון קונה",
    gender: "דזשענדער",
    male: "זכר",
    female: "ווייַבלעך",
    bank: "באַנק",
    selectBank: "סעלעקטירן באַנק",
    stateOrRegionOrProvince: "שטאַט/געגנט/פּראַווינס",
    customerNotes: "קונה נאָטעס",
    sendSms: "שיקן SMS",
    editCustomer: "רעדאַגירן קונה",
    redeemReward: "אויסלייזן באַלוינונג",
    issueLoyaltyCard: "אַרויסגעבן לויאַלטי קאַרד",
    deleteCustomer: "ויסמעקן קונה",
    youveSuccessfullyAssignedLoyaltyMembership:
      "איר האָט הצלחה אַסיינד אַ לויאַלטי מיטגלידערשאַפֿט שייַן",
    noMembershipIdAvailable:
      "קיין מיטגלידערשאַפֿט שייַן בנימצא. קאָנטאַקט אונדז אויף hello@loystar.co",
    sendEmail: "שיקן בליצפּאָסט",
    membershipPoints: "מיטגלידערשאַפֿט פּאָינץ",
    customerDetails: "קונה דעטאַילס",
    close: "נאָענט",
    loyaltyBalance: "לויאַלטי וואָג",
    pointsBalance: "ווייזט וואָג",
    starBalance: "שטערן באַלאַנס",
    requiredPoints: "פארלאנגט פּאָינץ",
    requiredStars: "פארלאנגט שטערן",
    reddemCode: "ויסלייזן קאָד",
    toDeleteThisCustomer: "צו ויסמעקן דעם קונה",
    customerHasBeenDeletedSuccessfully: "דער קונה איז הצלחה אויסגעמעקט!",
    customerWithPhoneAlreadyExists: "קונה מיט טעלעפאָן נומער יגזיסץ שוין",
    customerWasSuccessfullyEdited: "קונה איז געווען הצלחה עדיטיד",
    anErrorOccured: "א טעות איז פארגעקומען",
    phoneNumberIncludeCountry: "טעלעפאָנירן נומער (אַרייַננעמען לאַנד קאָוד)",
    yourFileQueuedForUpload:
      "דיין טעקע איז געווען אין ריי פֿאַר ופּלאָאַד. ביטע דערפרישן דעם בלאַט נאָך אַ ביסל סעקונדעס.",
    thereWasAnErrorPerformingOperation:
      "עס איז געווען אַ טעות בעשאַס די אָפּעראַציע!",
    pleaseSelectFile: "ביטע סעלעקטירן אַ טעקע!",
    oopsOnlyCsvFilesAllowed:
      "אוי! בלויז קסוו טעקעס ערלויבט. צופֿעליקער אַ .קסוו טעקע.",
    fileShouldBeSmallerThan5Mb:
      "דער טעקע זאָל זיין קלענערער ווי 5 מעגאבייטן. אויב איר האָט אַ גרעסערע טעקע, ביטע בליצפּאָסט support@loystar.co. אדאנק",
    customerFirstNameIsRequired:
      "קונה ערשטער נאָמען איז פארלאנגט אויף די רודערן",
    customerPhoneNumberIsRequired:
      "קונה טעלעפאָן נומער איז פארלאנגט אויף די רודערן",
    importCustomers: "אַרייַנפיר קוסטאָמערס",
    upload: "צופֿעליקער",
    clickIconToDownloadCsv:
      "דריקט דעם ייקאַן צו אָפּלאָדירן די קסוו טעקע מוסטער.",
    getGoogleSheetVersion: "באַקומען די Google בלאַט ווערסיע",
    clickOrDragFileToUpload:
      "דריקט אָדער שלעפּן די טעקע צו דעם שטח צו צופֿעליקער",
    missingOrInvalidColumnHeader:
      "פעלנדיק אָדער פאַרקריפּלט זייַל כעדער. נאָכגיין דעם מוסטער פֿאָרמאַט. אדאנק.",
    youHaveImported: "איר האָט ימפּאָרטיד",
    youSuccessfullyRedeemedReward:
      "איר האָט הצלחה אויסגעקויפט דיין באַלוינונג!",
    sixDigitCode: "זעקס ציפֿער קאָד",
    pleaseEnterCustomerRewardCode: "ביטע אַרייַן קונה ס באַלוינונג קאָד",
    enterRewardCode:
      "אַרייַן באַלוינונג קאָד. (די באַלוינונג קאָד איז פאַל -שפּירעוודיק)",
    selectLoyaltyProgram: "אויסקלייַבן לויאַלטי פּראָגראַם",
    stamps: "סטאַמפּס",
    smsUnitsLow: "SMS וניץ נידעריק",
    pleaseTopUpSmsUnits: "ביטע פּלאָמבירן אַרויף SMS וניץ",
    smsSuccessfullySentForDelivery: "SMS איז הצלחה געשיקט פֿאַר עקספּרעס!",
    sendSmsTo: "שיקן SMS צו",
    allCustomers: "אַלע קאַסטאַמערז",
    unitsAvailable: "וניץ בנימצא",
    pleaseTypeInTheMessage: "אַרייַן די אָנזאָג",
    message: "אָנזאָג",
    charactersRemaining: "רוען אותיות",
    avoidUseOfSpecialCharacters:
      "ויסמיידן די נוצן פון ספּעציעלע אותיות און עמאָדזשיס צו ראַטעווען SMS וניץ.",
    note: "נאטיץ",
    errorFetchingCustomersMultilevelDetail:
      "טעות מיט פעטשינג קאַסטאַמערז מולטילעוועל דעטאַילס",
    search: "זוך",
    reset: "באַשטעטיק",
    importCustomer: "אַרייַנפיר קאַסטאַמער",
    addNewCustomer: "לייג ניו קאַסטאַמער",
    sendSmsBroadcast: "שיקן SMS בראָדקאַסט",
    totalCustomers: "גאַנץ קאַסטאַמערז",
    disbursementDetails: "דיסבורסעמענט דעטאַילס",
    paidBy: "באַצאָלט דורך",
    disbursed: "אויסגעטיילט",
    bankAccountName: "באַנק אַקאַונט נאָמען",
    bankAccountNumber: "באנק אקאונט נומער",
    transferInitiated: "אַריבערפירן ינישיייטיד",
    transferCompleted: "אַריבערפירן געענדיקט",
    pleaseEnterAValid: "ביטע אַרייַן אַ גילטיק",
    begin: "אָנהייבן",
    end: "סוף",
    date: "דאַטע",
    paymentDate: "צאָלונג טאָג",
    selectDisbursementDuration: "סעלעקטירן די צאָלונג געדויער",
    totalSettled: "גאַנץ סעטאַלד",
    totalUnsettled: "גאַנץ ונסעטטלעד",
    toDeleteThisSale: "צו ויסמעקן דעם פאַרקויף",
    draftSaleDeletedSuccessfully: "צייכענונג סאַלע איז הצלחה אויסגעמעקט!",
    deleteSale: "ויסמעקן סאַלע",
    pleaseTypeInYourTotal: "אַרייַן דיין גאַנץ צו באַשטעטיקן דילישאַן",
    billDetails: "ביל דעטאַילס",
    printBill: "דרוק ביל",
    servedBy: "געדינט דורך",
    total: "גאַנץ",
    createdDate: "באשאפן טאָג",
    createdTime: "באשאפן צייט",
    status: "סטאַטוס",
    loginSuccessful: "לאָגין מצליח",
    pleaseWaitWhileWeConnectAccount: "וואַרטן בשעת מיר פאַרבינדן דיין חשבון",
    connectionFailedTryAgain: "פארבינדונג דורכגעפאלן. ביטע פּרובירן ווידער.",
    connectionSuccessful: "קשר מצליח",
    viewDetails: "View דעטאַילס",
    enable: "געבן",
    free: "פרייַ",
    cost: "פּרייַז",
    visitWebsite: "באַזוכן וועבזייַטל",
    pleaseUpgradeYourPlanToPro:
      "אַפּגרייד דיין פּלאַן צו פּראָ פּלוס צו געבן דעם אַפּ",
    connectYourBankAccount:
      "פאַרבינדן דיין באַנק אַקאַונט צו באַקומען פּיימאַנץ.",
    disable: "דיסאַבלע",
    enableApp: "געבן אַפּ",
    addNewProductToInvoice: "לייג נייַ פּראָדוקט צו ינווויס",
    toDeleteThisInvoice: "צו ויסמעקן דעם ינווויס",
    invoiceDeletedSuccessfully: "די ינווויס איז הצלחה אויסגעמעקט!",
    deleteInvoice: "ויסמעקן ינווויס",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "איר האָט הצלחה צוגעלייגט אַן ינווויס צאָלונג אָנזאָג!",
    pleaseEnterPaymentInstructions: "ביטע אַרייַן צאָלונג ינסטראַקשאַנז",
    invoiceId: "ינווויס ID",
    paidDate: "באַצאָלט טאָג",
    reference: "רעפערענץ",
    productAdded: "פּראָדוקט צוגעגעבן",
    productOutOfStock: "פּראָדוקט אויס פון לאַגער. ביטע שייַעך-לאַגער.",
    totalInvoices: "TOTAL INVOICES",
    totalPaidInvoices: "גאַנץ באַצאָלט ינוואָיסעס",
    totalUnpaidInvoices: "גאַנץ ניט באַצאָלט ינוואָיסעס",
    loyaltyProgramDeleted: "לויאַלטי פּראָגראַם אויסגעמעקט",
    thisLoyaltyProgramDeletedSuccessfully:
      "די לויאַלטי פּראָגראַם איז הצלחה אויסגעמעקט",
    thisLoyaltyProgramEditedSuccessfully:
      "דעם לויאַלטי פּראָגראַם איז געווען עדיטיד הצלחה",
    loyaltyProgramAddedSuccessfully: "לויאַלטי פּראָגראַם איז הצלחה צוגעלייגט",
    loyaltyProgramEdited: "לויאַלטי פּראָגראַם עדיטיד",
    loyaltyProgramAdded: "לויאַלטי פּראָגראַם צוגעגעבן",
    loyaltyDetails: "לויאַלטי דעטאַילס",
    doYouWantToCloseDialog: "צי איר ווילן צו פאַרמאַכן דעם דיאַלאָג?",
    pleaseEnterLoyaltyName: "ביטע אַרייַן די נאָמען פון דיין לויאַלטי",
    programType: "פּראָגראַם טיפּ",
    pleaseSelectProgramType: "סעלעקטירן אַ פּראָגראַם טיפּ",
    simplePoints: "פּשוט פּאָינץ",
    stampsProgram: "סטאַמפּס פּראָגראַם",
    threshold: "שוועל",
    pleaseEnterLoyaltyThreshold: "ביטע אַרייַן די לויאַלטי שוועל",
    reward: "באלוינונג",
    pleaseEnterLoyaltyReward: "ביטע אַרייַן די באַלוינונג פון די לויאַלטי",
    totalUserPoints: "גאַנץ באַניצער פּאָינץ",
    totalUserStamps: "גאַנץ באַניצער סטאַמפּס",
    spendingTarget: "ספּענדינג ציל",
    spendingTargetHint1:
      "די ספּענדינג ציל איז ווי פיל אַ קונה מוזן פאַרברענגען צו פאַרדינען די באַלוינונג. 1 קראַנטקייַט ווערט = 1 פונט.",
    spendingTargetHint2:
      "דער ציל פון סטאַמפּס איז ווי פילע סטאַמפּס אַ קונה מוזן קלייַבן צו פאַרדינען די באַלוינונג. למשל. 5",
    addNewLoyaltyProgram: "לייג ניו לויאַלטי פּראָגראַם",
    addLoyaltyProgram: "לייג לויאַלטי פּראָגראַם",
    loyaltyProgramType: "לויאַלטי פּראָגראַם טיפּ",
    pleaseSelectLoyaltyProgramType:
      "סעלעקטירן דעם טיפּ פון לויאַלטי פּראָגראַם",
    nameOfProgram: "נאָמען פון פּראָגראַם",
    pleaseEnterProgramName: "ביטע אַרייַן די פּראָגראַם נאָמען",
    whatIsTheReward: "וואָס איז די באַלוינונג?",
    egNextPurchaseIsFree: "למשל דער ווייַטער קויפן איז פריי",
    customerName: "קליענט נאמען",
    guestCustomer: "גאַסט קונה",
    orderConfirmedSuccessfully: "דער סדר איז באשטעטיקט",
    orderCancelledSuccessfully: "דער סדר איז קאַנסאַלד מיט הצלחה",
    confirmOrder: "באַשטעטיקן דעם סדר",
    cancelOrder: "באָטל מאַכן סדר",
    allOrders: "כל אָרדערס",
    totalOrders: "TOTAL אָרדערס",
    doYouWantToAcceptOrder: "צי איר ווילן צו אָננעמען דעם סדר?",
    doYouWantToCancelOrder: "צי איר ווילן צו באָטל מאַכן דעם סדר?",
    orderDetails: "סדר דעטאַילס",
    orderCreatedAt: "דער סדר איז באשאפן ביי",
    supplier: "סאַפּלייער",
    productName: "פּראָדוקט נאָמען",
    quantity: "קוואַנטיטי",
    unitPrice: "יוניט פּרייס",
    receivedBy: "באקומען דורך",
    reportFrom: "באריכט פון",
    totalSupplies: "גאַנץ סופּפּליעס",
    allRightsReserved: "אלע רעכטן רעזערווירט",
    toDeleteThisTransaction: "צו ויסמעקן דעם טראַנסאַקטיאָן",
    transactionDeletedSuccessfully:
      "טראַנסאַקטיאָן איז הצלחה אויסגעמעקט. לאַגער איז אומגעקערט צו ינוואַנטאָרי.",
    deleteTransaction: "ויסמעקן טראַנסאַקטיאָן",
    transactionDetails: "טראַנסאַקטיאָן דעטאַילס",
    printReceipt: "דרוק קאַבאָלע",
    channel: "טשאַננעל",
    discount: "אַראָפּרעכענען",
    profit: "נוץ",
    discountedSales: "דיסקאָונטעד סאַלעס",
    errorFetchingRecord: "טעות מיט פעטשינג רעקאָרד",
    exportTransactions: "אַרויספירן טראַנזאַקשאַנז",
    errorFetchingSalesRecord: "פיילער ביי סאַלעס רעקאָרד פֿאַר עקספּאָרט.",
    totalSellingPrice: "גאַנץ סעלינג פּרייס",
    totalCostPrice: "גאַנץ קאָסט פּרייס",
    appliedDiscount: "אַפּפּליעד אַראָפּרעכענען",
    noOfItems: "נומער פון יטעמס",
    sales: "פארקויפונג",
    export: "אַרויספירן",
    totalProfit: "גאַנץ פּראָפיט",
    totalBalanceInPeriod: "גאַנץ וואָג אין פּעריאָד",
    allTimeSales: "כל צייט סאַלעס",
    records: "רעקאָרדס",
    todaysSales: "הייַנט ס סאַלעס",
    transaction: "מאַסע - מאַטן",
    youHaveExceededTotalNumberOfProducts:
      "איר האָט יקסידיד די גאַנץ נומער פון ערלויבט פּראָדוקטן אין דיין פּלאַן. אַפּגרייד דיין פּלאַן צו באַקומען אַ העכער שיעור.",
    youNeedToHaveLoyaltyProgram:
      "איר דאַרפֿן אַ לויאַלטי פּראָגראַם צו נוצן דעם שטריך!",
    price: "פּרייַז",
    category: "קאַטעגאָריע",
    stockTracking: "לאַגער טראַקינג",
    stockCount: "בערזע גראף",
    taxed: "טאַקסט",
    originalPrice: "אָריגינעל פּרייס",
    costPrice: "פּרייַז פּרייַז",
    unit: "יוניט",
    productImage: "פּראָדוקט בילד",
    taxRate: "שטייַער קורס",
    taxType: "שטייער טיפּ",
    trackInventory: "שפּירט ינווענטאָרי",
    variants: "וואַריאַנץ",
    hasVariants: "האט וועריאַנץ",
    importProduct: "אַרייַנפיר פּראָדוקט",
    exportProducts: "אַרויספירן פּראָדוקץ",
    addNewProduct: "לייג ניו פּראָדוקט",
    viewCategory: "View קאַטעגאָריע",
    viewSuppliers: "View סאַפּלייערז",
    receiveInventory: "באַקומען ינווענטאָרי",
    printAllProductsTag: "דרוק אַלע פּראָדוקטן קוויטל",
    deleteAll: "ויסמעקן אַלע",
    totalProducts: "גאַנץ פּראָדוקטן",
    youveSuccessfullyAddedANewCategory:
      "איר האָט הצלחה צוגעלייגט אַ נייַע קאַטעגאָריע",
    addNewCategory: "צוגעבן ניו קאַטעגאָריע",
    addCategory: "צוגעבן קאַטעגאָריע",
    categoryName: "קאַטעגאָריע נאָמען",
    pleaseEnterCategoryName: "ביטע אַרייַן קאַטעגאָריע נאָמען",
    stampsTarget: "סטאַמפּס ציל",
    sendInventory: "שיקן ינווענטאָרי",
    productDetails: "פּראָדוקט דעטאַילס",
    youveSuccessfullyEditedThisCategory:
      "איר האָט הצלחה רעדאַגירט די קאַטעגאָריע",
    update: "דערהייַנטיקן",
    categoryList: "קאַטעגאָריע רשימה",
    categories: "קאַטעגאָריעס",
    enterQuantityToUpdate: "אַרייַן אַ קוואַנטיטי צו דערהייַנטיקן",
    inventorySentSuccessfully: "די ינוואַנטאָרי איז הצלחה געשיקט!",
    updateInventory: "דערהייַנטיקן ינווענטאָרי",
    currentQuantity: "קראַנט קוואַנטיטי",
    pleaseEnterQuantityToAdd: "ביטע אַרייַן די קוואַנטיטי איר ווילן צו לייגן",
    pleaseSelectABranch: "ביטע סעלעקטירן אַ צווייַג",
    searchForBranch: "זוכן פֿאַר צווייַג",
    youCantSendMoreThanStock: "איר קענט נישט שיקן מער ווי אין לאַגער",
    send: "שיקן",
    pleaseEnterQuantityToSend: "ביטע אַרייַן די קוואַנטיטי איר ווילן צו שיקן",
    productNameIsRequiredOnRow: "פּראָדוקט נאָמען איז פארלאנגט אויף די רודערן",
    productCategoryIsRequiredOnRow:
      "פּראָדוקט קאַטעגאָריע איז פארלאנגט אויף די רודערן",
    productPriceIsRequiredOnRow:
      "פּראָדוקט פּרייַז איז פארלאנגט אויף די רודערן",
    productUnitIsRequiredOnRow:
      "פּראָדוקט אַפּאַראַט איז פארלאנגט אויף די רודערן",
    productQuantityIsRequiredOnRow:
      "פּראָדוקט קוואַנטיטי איז פארלאנגט אויף די רודערן",
    productVariantsRequireTracking: "פּראָדוקט וועריאַנץ ריקווייערז טראַקינג!",
    pleaseAddVariantClickButton:
      "לייג אַ וואַריאַנט דורך געבן אַ קליק דעם צוגעבן קנעפּל!",
    totalVariantsMoreThanSelectedQuantity:
      "גאַנץ וועריאַנץ זענען מער ווי אויסגעקליבן פּראָדוקט קוואַנטיטי, פּלס רעדוצירן וואַריאַנט קוואַנטיטי",
    productEditedSuccessfully: "פּראָדוקט איז געווארן עדיטיד הצלחה!",
    fileTooLargeLessThan4Mb:
      "טעקע גרייס איז צו גרויס! טעקע גרייס זאָל זיין ווייניקער ווי 4 מעגאבייטן.",
    suchVariantAlreadyExist: "אזא וואַריאַנט עקזיסטירט שוין",
    addVariants: "לייג וואַריאַנץ",
    editProduct: "רעדאַגירן פּראָדוקט",
    pleaseEnterProductName: "ביטע אַרייַן פּראָדוקט נאָמען",
    pleaseEnterProductPrice: "ביטע אַרייַן פּראָדוקט פּרייס",
    pleaseEnterProductOriginalPrice: "ביטע אַרייַן פּראָדוקט אָריגינעל פּרייס",
    productDescription: "פּראָדוקט דיסקריפּשאַן",
    selectProductCategory: "סעלעקט פּראָדוקט קאַטעגאָריע",
    pleaseSelectProductCategory: "ביטע סעלעקטירן פּראָדוקט קאַטעגאָריע",
    productCostPrice: "פּראָדוקט פּרייַז פּרייס",
    productSellingPrice: "פּראָדוקט סעלינג פּרייס",
    productOriginalPrice: "פּראָדוקט אָריגינעל פּרייס",
    maxFileSizeAllowedIs4Mb: "מאַקס. דער טעקע גרייס איז 4 מעגאבייטן",
    productsWithPicturesArePublished:
      "פּראָדוקטן מיט בילדער זענען ארויס אויף אַנטדעקן צו באַקומען אָרדערס",
    shouldThisProductBeTracked: "זאָל דעם פּראָדוקט זיין טראַקט?",
    pleaseSelectStockUnit: "ביטע סעלעקטירן לאַגער אַפּאַראַט",
    stockUnit: "Stock Unit",
    bag: "זעקל",
    bottle: "פלאַש",
    bunch: "בינטל",
    can: "קענען",
    carton: "קאַרטאַן",
    crate: "קרייט",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "קילאָגראַם",
    litre: "LITER",
    pack: "PACK",
    pair: "פּאָר",
    pieces: "PIECES",
    plate: "טעלער",
    tonne: "TONNE (מט)",
    uNIT: "UNIT",
    yard: "הויף",
    pleaseEnterProductQuantity: "ביטע אַרייַן פּראָדוקט קוואַנטיטי",
    productQuantity: "פּראָדוקט קוואַנטיטי",
    isThisProductTaxed: "איז דעם פּראָדוקט טאַקסט?",
    selectTaxType: "סעלעקטירן שטייער טיפּ",
    pleaseSelectTaxType: "ביטע אויסקלייַבן שטייער טיפּ",
    progressive: "פּראָגרעסיוו",
    proportional: "פּראָפּאָרציאָנעל",
    pleaseEnterProductTaxRate: "ביטע אַרייַן פּראָדוקט שטייַער קורס",
    doesProductHaveVariants: "טוט דעם פּראָדוקט האָבן וועריאַנץ?",
    type: "טיפּ",
    value: "ווערט",
    pleaseEnterVariantType: "אַרייַן די וואַריאַנט ס טיפּ",
    pleaseEnterVariantValue: "ביטע אַרייַן די ווערט פון די וואַריאַנט",
    pleaseEnterVariantPrice: "ביטע אַרייַן וואַריאַנט ס פּרייַז",
    pleaseEnterVariantQuantity: "ביטע אַרייַן די קוואַנטיטי פון די וואַריאַנט",
    productDeletedSuccessfully: "פּראָדוקט איז הצלחה אויסגעמעקט!",
    categoryDeletedSuccessfully: "קאַטעגאָריע איז געווארן אויסגעמעקט הצלחה!",
    toDeleteThisProduct: "צו ויסמעקן דעם פּראָדוקט",
    invalidProductQuantity: "פאַרקריפּלט פּראָדוקט קוואַנטיטי",
    quantityAddedIsOverStock:
      "די קוואַנטיטי איר לייגן איז מער ווי וואָס איר האָט אין לאַגער.",
    itemInventoryNotTracked: "נומער ינוואַנטאָרי איז נישט טראַקט",
    addBulkQuantity: "לייג פאַרנעם קוואַנטיטי",
    enterBulkQuantity: "אַרייַן פאַרנעם קוואַנטיטי",
    pleaseEnterBulkQuantity: "ביטע אַרייַן פאַרנעם קוואַנטיטי",
    youveSuccessfullyAddedANewProduct:
      "איר האָט הצלחה צוגעגעבן אַ נייַע פּראָדוקט!",
    pleaseEnterProductSellingPrice: "ביטע אַרייַן פּראָדוקט סעלינג פּרייַז",
    doYouWantToTrackProductStock: "צי איר ווילן צו שפּור פּראָדוקט לאַגער?",
    sellingPrice: "Selling Price",
    setProductExpiryReminder: "באַשטעטיק פּראָדוקט עקספּיירי דערמאָנונג",
    productExpiryDate: "עקספּיירי טאָג פון פּראָדוקט",
    startRemindingFrom: "אָנהייב רימיינדינג פֿון",
    productSuppliesAddedSuccessfully:
      "איר האָט הצלחה צוגעגעבן פּראָדוקט סאַפּלייז.",
    addProductSupplies: "לייג פּראָדוקט סופּפּליעס",
    pleaseSelectSupplier: "ביטע סעלעקטירן סאַפּלייער",
    nameOfProduct: "נאָמען פון פּראָדוקט",
    pleaseSelectProduct: "ביטע סעלעקטירן פּראָדוקט",
    productVariant: "פּראָדוקט וואַריאַנט",
    pleaseSelectAVariant: "ביטע סעלעקטירן א וואַריאַנט",
    pleaseEnterUnitPrice: "ביטע אַרייַן יוניט פּרייס",
    businessBranch: "ביזנעס צווייַג",
    pleaseSelectBranch: "ביטע אויסקלייַבן צווייגן",
    youveSuccessfullyAddedANewSupplier:
      "איר האָט הצלחה צוגעלייגט אַ נייַע סאַפּלייער",
    addSupplier: "לייג סאַפּלייער",
    pleaseEnterSupplierEmail: "ביטע אַרייַן סאַפּלייער ס בליצפּאָסט",
    pleaseAddADescription: "ביטע לייגן אַ באַשרייַבונג",
    anErrorOccuredProductsDeleted:
      "א טעות איז פארגעקומען בשעת דורכפירן די אָפּעראַציע. ביטע טאָן אַז עטלעכע פּראָדוקטן קען האָבן שוין אויסגעמעקט אין דעם פּראָצעס",
    bulkDelete: "פאַרנעם דיליט",
    youAreAboutToDelete: "דו ביסט וועגן צו ויסמעקן",
    product: "פּראָדוקט",
    isDueToRedeem: "איז רעכט צו ויסלייזן",
    aReward: "אַ באַלוינונג",
    pleaseSelectCustomerToReeemReward:
      "ביטע סעלעקטירן אַ קונה צו ויסלייזן באַלוינונג.",
    youHaveNoLoyaltyProgramRunning: "איר האָט קיין אַקטיוו לויאַלטי פּראָגראַם",
    customerHhasNoPointsInLoyaltyProgram:
      "דער קונה האט קיין פונקטן אין דעם לויאַלטי פּראָגראַם",
    proceedWithPayment: "גיינ ווייַטער מיט צאָלונג?",
    youAreAboutToPayForTransactionUsingPoints:
      "איר זענט וועגן צו צאָלן פֿאַר די מאַסע - מאַטן מיט פונקטן.",
    customerHas: "קונה האט",
    worth: "ווערט",
    andIsNotSufficientToPayForTransaction:
      "און איז ניט גענוג צו צאָלן פֿאַר דעם מאַסע. וואָלט זיי ווילן צו לייגן די וואָג מיט אן אנדער צאָלונג אופֿן?",
    addCustomerLoyalty: "לייג צו קונה לויאַלטי",
    pleaseAddCustomerFirst: "ביטע צוגעבן אָדער סעלעקטירן אַ קונה ערשטער.",
    pleaseWaitWhileWeGetReady: "ביטע וואַרטן, בשעת מיר באַקומען גרייט",
    lowStock: "נידעריק סטאַק",
    pleaseEnterAmountTendered: "ביטע אַרייַן די סומע טענדערד",
    areYouSureToBookSaleOffline:
      "ביסט איר זיכער אַז איר ווילן צו בוך דעם פאַרקויף אָפפלינע?",
    saleWouldBeBookedAutomatically:
      "פאַרקויף וואָלט זיין אויטאָמאַטיש בוקט ווען איר אָנשטעלן דיין אינטערנעט",
    offlineSalesBookingCancelled: "אָפפלינע פארקויפונג בוקינג קאַנסאַלד",
    covid19Message:
      "קאָוויד 19: וואַשן דיין הענט מיט זייף אָדער רייניקן זיי צו האַלטן די פאַרשפּרייטן. בלייַבן זיכער שטענדיק",
    saleSuccessfullyRecorded: "פאַרקויף הצלחה רעקאָרדעד!",
    sendReceiptToEmail: "שיקן קאַבאָלע צו E- בריוו",
    sendThankYouSms: "שיקן דאַנקען דיר SMS",
    sendShippingDetails: "שיקן שיפּינג דעטאַילס",
    addLoyalty: "לייג לויאַלטי",
    searchCustomerNameOrNumber: "זוכן קונה נאָמען אָדער נומער",
    clickTheSearchCustomerBox: "דריקט דעם זוכן קונה באָקס און יבערקוקן קאַרטל",
    enterProductPrice: "אַרייַן פּראָדוקט פּרייס",
    enterPriceFor: "אַרייַן פּרייס פֿאַר",
    searchForProduct: "זוכן פֿאַר פּראָדוקט",
    all: "אַלע",
    backToDashboard: "צוריק צו דאַשבאָרד",
    viewDraftSales: "View דראַפט סאַלעס",
    variantSelected: "וואַריאַנט אויסגעקליבן",
    variant: "וואַריאַנט",
    thePreviousVariantSelectionNotAvailable:
      "די פֿריִערדיקע וואַריאַנט סעלעקציע איז נישט בארעכטיגט פֿאַר די נייַע אויסגעקליבן וואַריאַנט באזירט אויף פּרייַז, ביטע טוישן דיין סעלעקציע.",
    pleaseSupplyPositiveQuantityNumber:
      "ביטע צושטעלן אַ פּאָסטטיוו קוואַנטיטי נומער",
    lowStockFor: "נידעריק לאַגער פֿאַר",
    clearVariants: "קלאָר וועריאַנץ",
    pleaseEnterQuantity: "ביטע אַרייַן קוואַנטיטי",
    picture: "בילד",
    redemptionToken: "גאולה טאָקען",
    token: "טאָקען",
    totalSpent: "גאַנץ ספּענט",
    confirmPayment: "באַשטעטיקן צאָלונג",
    hasPaymentBeenMade: "איז די צאָלונג הצלחה פּראַסעסט?",
    enterTransactionReference:
      "אַרייַן די טראַנסאַקטיאָן רעפֿערענץ מיט וואָס איר באַצאָלט",
    pleaseSelectACustomer: "ביטע סעלעקטירן אַ קונה!",
    areYouSureToApplyDiscount:
      "זענט איר זיכער אַז איר ווילט צולייגן אַ אַראָפּרעכענען?",
    addYourBankAccountToEnableUssd:
      "לייג דיין באַנק אקאונט צו געבן רעגע וסד אַריבערפירן דורך uPay",
    totalAmountToPay: "גאַנץ סומע צו צאָלן",
    doYouWantToCancelTransaction: "צי איר ווילן צו באָטל מאַכן דעם מאַסע?",
    savePrintBill: "היט/פּרינט ביל",
    enterAmountCollectedForCustomer: "אַרייַן סומע געזאמלט פֿאַר קונה",
    recordSale: "Record Sale",
    checkOutWith: "טשעק אויס מיט",
    instantTransfer: "רעגע טראַנספער",
    dialTheUSSDCode: "אַרייַן די וססד קאָד",
    pleaseSelectABank: "ביטע אויסקלייַבן אַ באַנק",
    payWithUSSD: "באַצאָלן מיט וססד",
    sendBillTo: " - שיק ביל צו",
    waitingForUSSDTransfer: "ווארטן אויף וססד אַריבערפירן",
    percent: "פראצענט",
    applyDiscount: "צולייגן אַראָפּרעכענען",
    thisBillHasBeenSaved: "דער ביל איז געראטעוועט",
    saveDraft: "היט דראַפט",
    pleaseTypeANameToIdentifyCustomer:
      "אַרייַן אַ נאָמען צו ידענטיפיצירן דער קונה",
    paymentDetails: "צאָלונג דעטאַילס",
    basePrice: "באַזע פּרייס",
    staff: "שטעקן",
    subTotal: "סובטאָטאַל",
    durationMonths: "געדויער (חדשים)",
    selectADuration: "סעלעקטירן אַ געדויער",
    oneMonth: "1 חודש",
    twoMonths: "2 חדשים",
    threeMonths: "3 מאנאטן",
    sixMonths: "6 חדשים",
    twelveMonths: "12 חדשים",
    eighteenMonths: "18 חדשים",
    twentyFourMonths: "24 חדשים",
    twoMonthsFree: "(2 חדשים פריי)",
    threeMonthsFree: "(3 חדשים פריי)",
    fiveMonthsFree: "(5 חדשים פריי)",
    yourAccountHasBeen: "דיין חשבון איז געווען",
    renewed: "באנייט",
    upgraded: "אַפּגריידיד",
    successfully: "הצלחה",
    yourSubscription: "דיין אַבאָנעמענט",
    youAreCurrentlyEnrolledOnA: "איר זענט דערווייַל ענראָולד אויף אַ",
    pleaseChooseAPaymentOption: "ביטע קלייַבן אַ צאָלונג אָפּציע",
    planRenewal: "פּלאַן רינואַל",
    planUpgrade: "פּלאַן ופּגראַדע",
    pleaseSelectDurationToPayFor: "סעלעקטירן די געדויער איר ווילן צו צאָלן",
    staffAccounts: "שטעקן אַקאַונץ",
    ecommerce: "עקאָממערסע",
    pleaseSelectAPlan: "ביטע אויסקלייַבן אַ פּלאַן",
    includeAddons: "אַרייַננעמען אַדד-אָנס",
    viewTransactions: "View טראַנזאַקשאַנז",
    customerHasNoCompletedTansactions:
      "דער קונה האט נאָך קיין גאַנץ טאַנזאַקשאַנז",
    branch: "צווייַג",
    enterNumberOfEcommerceBranches: "אַרייַן נומער פון E- האַנדל צווייגן",
    enterNumberOfEcommerceBranchesToPay:
      "אַרייַן די נומער פון E- האַנדל בראַנטשיז איר בדעה צו צאָלן פֿאַר",
    ecommerceIntegration: "עקאָממערסע ינטעגראַטיאָן",
    enterNumberOfBranches: "אַרייַן נומער פון בראַנטשעס",
    enterNumberOfAdditionalBranchesToPay:
      "אַרייַן די נומער פון נאָך בראַנטשיז איר בדעה צו צאָלן פֿאַר",
    enterNumberOfStaffs: "אַרייַן נומער פון סטאַפס",
    enterNumberOfStaffsToPayFor:
      "אַרייַן די נומער פון שטעקן פֿאַר וואָס איר ווילט צאָלן",
    discountApplies: "אַראָפּרעכענען אַפּלייז",
    starsOnThe: "שטערן אויף די",
    offer: "פאָרשלאָג",
    get: "באַקומען",
    moreStarsToRedeem: "מער שטערן צו ויסלייזן",
    taxVat: "שטייַער (וואַט)",
    callCashierToCompletePayment: "רוף קאַסירער צו פאַרענדיקן צאָלונג",
    receipt: "קאַבאָלע",
    dear: "טייַער",
    thankYouForYourOrderFindGoods:
      "דאנק איר פֿאַר דיין סדר. ביטע געפֿינען די פאלגענדע סכוירע סאַפּלייד, ווי מסכים.",
    shippingNote: "שיפּפּינג באַמערקונג",
    enterShippingNote: "אַרייַן שיפּפּינג באַמערקונג",
    shippingAddress: "אדרעס צו שיקן",
    enterShippingAddress: "אַרייַן שיפּפּינג אַדרעס",
    wellDoneYouAreDueToRedeem: "גוט געטאן! איר וועט דאַרפֿן צו ויסלייזן",
    toGetYourRewardNextVisit:
      "צו באַקומען דיין באַלוינונג פֿאַר דיין ווייַטער וויזיט. אדאנק",
    pointsOnThe: "פונקטן אויף די",
    morePointsToRedeem: "מער פונקטן צו ויסלייזן",
    showCode: "ווייַזן קאָד",
    toGetYourRewardOnNextVisit:
      "צו באַקומען דיין באַלוינונג פֿאַר דיין ווייַטער וויזיט. אדאנק",
    earn: "פאַרדינען",
    delivaryNote: "דעליווערי באַמערקונג",
    draftSales: "צייכענונג סאַלעס",
    startDate: "אנהייב דאטום",
    endDate: "סוף טאָג",
    orders: "אָרדערס",
    checkout: "טשעק אויס",
    noProductItem: "קיין פּראָדוקט נומער",
    selectProductImage: "סעלעקט פּראָדוקט בילד",
    selectCountry: "אויסקלייַבן לאַנד",
    selectRegion: "אויסקלייַבן שטאַט/געגנט",
    printProductTag: "דרוקן פּראָדוקט קוויטל",
    transactionReference: "טראַנסאַקטיאָן דערמאָנען",
    Cashier: "קאַסירער",
    Manager: "פאַרוואַלטער",
    Owner: "באַזיצער",
    Admin: "אַדמין",
    addPartners: "לייג פּאַרטנערס",
    addNewLoyaltyPartner: "לייג אַ נייַ לויאַלטי פּאַרטנער",
    pleaseEnterCompanyName: "ביטע אַרייַן די פירמע נאָמען",
    companyName: "פֿירמע נאָמען",
    pleaseEnterCompanyRepName: "ביטע אַרייַן פירמע פארשטייער נאָמען",
    companyRepName: "נאָמען פון פֿירמע פארשטייער",
    pleaseEnterCompanyRepEmail: "ביטע אַרייַן בליצפּאָסט פון פירמע פארשטייער",
    companyRepEmail: "בליצפּאָסט פון פירמע רעפּ",
    pleaseEnterCompanyRepPhone:
      "ביטע אַרייַן טעלעפאָן נומער פון פירמע פארשטייער",
    companyRepPhone: "טעלעפאָן נומער פון פירמע רעפּ",
    addReward: "לייג באַלוינונג",
    pleaseEnterRewardName: "ביטע אַרייַן באַלוינונג נאָמען",
    rewardName: "באַלוינונג נאָמען",
    rewardQuantity: "באַלוינונג קוואַנטיטי",
    rewardDescription: "באַלוינונג באַשרייַבונג",
    rewardType: "שכר טיפּ",
    pleaseEnterRewardType: "ביטע אַרייַן באַלוינונג טיפּ",
    pleaseEnterRewardQuantity: "ביטע אַרייַן באַלוינונג קוואַנטיטי",
    pleaseEnterRewardDescription: "ביטע אַרייַן באַלוינונג באַשרייַבונג",
    fineDining: "פיין דיינינג",
    luxuryFashion: "לוקסוס שניט",
    hotels: "האָטעלס",
    travel: "רייזע",
    foodAndBeverage: "עסנוואַרג און בעוועראַגע",
    fashion: "שניט",
    health: "געזונט",
    furniture: "מעבל",
    entertainment: "פֿאַרווייַלונג",
    automobile: "קאר",
    education: "בילדונג",
    beautyAndSpa: "שיינקייט און ספּאַ",
    staycation: "סטייקאַטיאָן",
    events: "געשעענישן",
    trips: "טריפּס",
    oilAndGas: "אָיל און גאַז",
    laundry: "וועש",
    partnerCategory: "פּאַרטנער קאַטעגאָריע",
    freeItem: "פֿרייַ נומער",
  },
  Yoruba: {
    cashier: "owo -owo",
    manager: "alakoso",
    owner: "eni",
    online: "online",
    offline: "aisinipo",
    changePassword: "Tun oruko akowole re se",
    currentPasswordMessage: "Jọwọ tẹ ọrọ igbaniwọle lọwọlọwọ rẹ sii",
    passwordMessage: "Jọwọ tẹ ọrọ igbaniwọle rẹ sii",
    currentPassword: "Ọrọigbaniwọle lọwọlọwọ",
    password: "ọrọigbaniwọle",
    confirmPasswordMessage: "Jọwọ jẹrisi ọrọ igbaniwọle rẹ!",
    confirmPassword: "So ni pato orukoabawole re",
    sendViaEmail: "Firanṣẹ Nipasẹ Imeeli",
    sendViaWhatsapp: "Firanṣẹ Nipasẹ WhatsApp",
    downloadPdf: "Ṣe igbasilẹ PDF",
    paid: "san",
    unpaid: "ti a ko sanwo",
    partial: "apa kan",
    closeInvoice: "Ṣe o fẹ pa Invoice naa?",
    closeInvoiceConfirmation: "Iwe risiti le ma wa ni fipamọ. Ṣe o fẹ lati pa?",
    yes: "bẹẹni",
    no: "rara",
    invoice: "Iwe -owo",
    wasDeducted: "ti yọkuro",
    for: "fun",
    item: "Nkan",
    addProduct: "Fi ọja kun",
    paymentReference: "Itọkasi isanwo",
    amountPaid: "Iye San",
    discountAmount: "Iye eni",
    amountDue: "Iye owo",
    amount: "Iye",
    dueDate: "Asiko to ba to",
    paymentType: "Isanwo Iru",
    card: "Kaadi",
    cash: "Owo",
    bankTransfer: "Afiranse ile ifowopamo",
    paymentMessage: "Ifiranṣẹ isanwo",
    description: "Apejuwe",
    sendReceipt: "Fi iwe -ẹri ranṣẹ",
    delete: "Paarẹ",
    save: "Fipamọ",
    resend: "Tun-Firanṣẹ",
    saveAndSend: "Fipamọ",
    invoiceSaved: "Ti fi iwe -owo pamọ!",
    selectPaymentMethod: "Jọwọ yan Ọna isanwo kan!",
    selectCustomer: "Jọwọ yan Onibara kan!",
    cartEmptyError:
      "Akojọ rira ko le ṣofo, pa iwe -owo ki o ṣafikun Ohun kan si rira!",
    subscriptionExpired:
      "Ṣiṣe alabapin rẹ ti pari ati akọọlẹ rẹ ti ni opin bayi. Tẹ bọtini Bọtini ni isalẹ lati tunse akọọlẹ rẹ",
    renew: "Tunse",
    holdOn: "Da duro",
    customerBank: "Onibara Bank",
    cancel: "Fagilee",
    selectACustomer: "Yan Onibara",
    invoiceSuccessfulPdfError:
      "Iwe -owo ti ṣẹda ni aṣeyọri ṣugbọn Iran PDF n gba to gun ju ti iṣaaju lọ. Jọwọ ṣayẹwo pada laipẹ.",
    downloadingInvoice: "Gbigba risiti wọle",
    downloadingReceipt: "Gbigba Gbigba wọle",
    whatsappReceiptError:
      "Aṣiṣe kan waye ni fifiranṣẹ iwe -ẹri nipasẹ WhatsApp, Jọwọ gbiyanju lẹẹkansi.",
    receiptToWhatsapp: "Ti gba ọjà ti firanṣẹ si WhatsApp",
    thankYouForPatronage: "O ṣeun fun atilẹyin rẹ",
    hereIsYourReceipt: "Eyi ni iwe isanwo isanwo rẹ",
    errorSendingEmailReceipt:
      "Aṣiṣe waye fifiranṣẹ iwe-ẹri nipasẹ imeeli, jọwọ gbiyanju lẹẹkansi tabi kan si atilẹyin",
    receiptSentToEmail: "A ti fi iwe -ẹri ranṣẹ si imeeli alabara",
    invoiceSentToEmail: "A ti fi risiti ranṣẹ si imeeli alabara",
    invoiceSuccessWhatsappError:
      "Iwe -owo ti ṣẹda ni aṣeyọri ṣugbọn aṣiṣe waye fifiranṣẹ si WhatsApp. Gbiyanju lẹẹkansi lori atokọ risiti",
    invoiceSuccessfulEmailError:
      "Iwe-owo ti ṣẹda ni aṣeyọri ṣugbọn aṣiṣe waye lakoko fifiranṣẹ bi imeeli. Jọwọ gbiyanju lẹẹkansi lati atokọ risiti",
    invoiceSentToWhatsapp: "Ifiweranṣẹ firanṣẹ siwaju si WhatsApp",
    hello: "Pẹlẹ o",
    pleaseDownloadInvoice: "Jọwọ gba risiti wọle lati ayelujara",
    pleaseDownloadReceipt: "Jọwọ gba iwe -ẹri lati ayelujara",
    from: "lati",
    email: "Imeeli",
    upgrade: "Igbesoke",
    youAreOnFreePlan: "O wa lori Eto Ọfẹ kan.",
    clickOn: "Tẹ lori",
    yourPlanInFewSteps: " ṣiṣe alabapin rẹ ni awọn igbesẹ iyara diẹ.",
    to: "si",
    yourSubscriptionHasExpired:
      "Ṣiṣe alabapin rẹ ti pari ati akọọlẹ rẹ ti ni opin bayi.",
    days: "ọjọ",
    yourSubscriptionExpiresIn: "Ṣiṣe alabapin Loystar rẹ ti pari",
    createAcount: "Ṣẹda akọọlẹ kan",
    signIn: "Wọle",
    continue: "Tesiwaju",
    enterOtp: "Tẹ PIN OTP sii",
    enterValidOtp: "Jọwọ tẹ PIN ti o wulo sii",
    pin: "PIN",
    tokenSentToMail: "A ti fi ami si imeeli rẹ",
    passwordResetSuccessful: "Atunto ọrọ igbaniwọle ti ṣaṣeyọri",
    somethingWentWrong: "Nnkan o lo daadaa!",
    resetPassword: "Tun Ọrọigbaniwọle",
    iHaveResetCode: "Mo ni koodu atunto ọrọ igbaniwọle kan",
    pleaseEnterEmail: "Jọwọ tẹ imeeli rẹ sii",
    aTokenWillBeSentToEmail: "A yoo fi ami kan ranṣẹ si imeeli rẹ",
    enterEmail: "Tẹ imeeli rẹ sii",
    sendinYourToken: "Fifiranṣẹ aami rẹ ...",
    makeSureItMatchesPassword: "Rii daju pe o baamu ọrọ igbaniwọle tuntun rẹ",
    pleaseChooseNewPassword: "Jọwọ yan ọrọ igbaniwọle tuntun kan",
    chooseNewPassword: "Yan ọrọ igbaniwọle tuntun kan",
    enterNewPassword: "Tẹ ọrọ igbaniwọle titun rẹ lati jẹrisi",
    enterTokenSent: "Tẹ ami ti a firanṣẹ meeli rẹ",
    resetToken: "Tun Token",
    resettingPassword: "Ntun ọrọ igbaniwọle rẹ ...",
    signUp: "Forukọsilẹ",
    adminSignInWithEmail:
      " Abojuto abojuto wọle pẹlu imeeli lakoko ti Awọn oṣiṣẹ nwọle pẹlu orukọ olumulo.",
    pleaseEnterEmailOrUsername: "Jọwọ tẹ imeeli rẹ tabi orukọ olumulo sii",
    emailOrUsername: "Imeeli tabi Orukọ olumulo",
    pleaseEnterPassword: "Jọwọ tẹ ọrọ igbaniwọle sii",
    createAnAccount: "Ṣẹda akọọlẹ kan",
    forgotPassword: "Gbagbe ọrọ aṣina bi?",
    enterPhoneNumber: "Tẹ Nọmba Foonu sii",
    personalData: "Data ti ara ẹni",
    validateConfirmationCOde: "Jẹrisi Koodu Ijerisi",
    pleaseEnterFirstName: "Jọwọ tẹ orukọ akọkọ rẹ sii",
    pleaseEnterPhoneNumber: "Jọwọ tẹ nọmba foonu rẹ sii",
    pleaseEnterLastName: "Jọwọ tẹ orukọ rẹ ti o kẹhin sii",
    pleaseEnterBusinessName: "Jọwọ tẹ orukọ iṣowo rẹ sii",
    firstName: "Orukọ akọkọ",
    lastName: "Oruko idile",
    businessName: "Orukọ Iṣowo",
    previous: "Ti tẹlẹ",
    next: "Itele",
    pleaseSelectBusinessCategory: "Jọwọ yan ẹka iṣowo rẹ",
    pleaseEnterValidEmail: "Jọwọ tẹ a wulo imeeli",
    pleaseEnterPostCode: "Jọwọ tẹ koodu ifiweranṣẹ sii",
    postCode: "Koodu Ifiweranṣẹ",
    phoneNumberInUse: "Nọmba foonu yii ti wa ni lilo tẹlẹ!",
    emailInUse: "Imeeli yii ti wa ni lilo tẹlẹ!",
    foodAndDrinks: "Ounje ati Ohun mimu",
    salonAndBeauty: "Salon ati Ẹwa",
    fashionAndAccessories: "Njagun ati Awọn ẹya ẹrọ",
    gymAndFitness: "Idaraya ati Amọdaju",
    travelAndHotel: "Irin -ajo ati Hotẹẹli",
    homeAndGifts: "Ile ati Ebun",
    washingAndCleaning: "Fifọ ati Wiwa",
    gadgetsAndElectronics: "Awọn irinṣẹ ati Itanna",
    groceries: "Awọn ohun -ọjà",
    others: "Awọn miiran",
    submit: "Firanṣẹ",
    accountCreatedSuccessful: "A ṣẹda akọọlẹ rẹ ni aṣeyọri.",
    pleaseEnterAddress: "Jọwọ Tẹ Adirẹsi rẹ sii",
    addressLine1: "Laini Adirẹsi 1",
    addressLine2: "Laini adirẹsi 2",
    choosePassword: "Yan Ọrọ igbaniwọle",
    passwordMustBe6Characters:
      "Ọrọ igbaniwọle gbọdọ jẹ o kere ju awọn ohun kikọ 6 lọ.",
    howDidYouHearLoystar: "Bawo ni o ṣe gbọ nipa Loystar?",
    referralCode: "koodu itọkasi",
    byClickingTheButton: " Nipa titẹ bọtini ti o wa ni isalẹ, o gba si",
    termsAndSevice: "Awọn ofin",
    wereCreatingAccount: "A n ṣẹda akọọlẹ rẹ",
    proceed: "Tẹsiwaju",
    verificationCodeMustBe6: "Koodu ijerisi gbọdọ jẹ awọn nọmba mẹfa",
    pleaseEnter6DigitCode: "Jọwọ Tẹ koodu nọmba 6 sii",
    enterVerificationCode: "Tẹ koodu Ijerisi sii",
    resendToken: "Resend Token",
    verify: "Ṣayẹwo",
    transactions: "Awọn iṣowo",
    todaySales: "Tita Oni",
    salesHistory: "Itan Tita",
    supplyHistory: "Itan Ipese",
    new: "Titun",
    invoices: "Awọn risiti",
    disbursements: "Awọn isanwo",
    offlineSales: "Awọn titaja aisinipo",
    products: "Awọn ọja",
    customers: "Awon onibara",
    multiLevelLoyalty: "Olona-Ipele iṣootọ",
    loyaltyPrograms: "Awọn eto Iṣootọ",
    members: "Awọn ọmọ ẹgbẹ",
    appStore: "Itaja App",
    orderMenu: "Akojọ aṣẹ",
    settings: "Ètò",
    staffAndBranches: "Awọn oṣiṣẹ ati Awọn ẹka",
    myAccount: "Akọọlẹ Mi",
    switchToSellMode: "Yipada si Ipo Tita",
    signOut: "Ifowosi jada",
    getFreeSubscription: "Gba Iforukọsilẹ ọfẹ",
    onlyNumbersAllowed: "Awọn nọmba nikan ni a gba laaye",
    yourAccountMustBe10Digits:
      "nọmba akọọlẹ rẹ gbọdọ jẹ nọmba oni -nọmba 10 kan",
    yourBvnMustBe11: "BVN rẹ gbọdọ jẹ nọmba oni -nọmba 11 kan",
    pleaseSelectBank: "Jọwọ yan banki rẹ",
    selectYourBank: "Yan Bank rẹ",
    enterBankAccountNumber: "Tẹ Nọmba Account Bank sii",
    enterBvnNumber: "Tẹ BVN rẹ sii",
    connectBankAccount: "So Bank Account",
    passwordResetSuccessfulAndSignOut:
      "Ọrọigbaniwọle rẹ ti ni atunto ni aṣeyọri. Tẹ bọtini Wọle Jade ni isalẹ lati tun wọle lẹẹkansi",
    enterCurrentPassword: "Tẹ ọrọ igbaniwọle lọwọlọwọ",
    pleaseEnterCurrentPassword: "Jọwọ tẹ ọrọ igbaniwọle lọwọlọwọ",
    phoneNumber: "Nomba fonu",
    sex: "Ibalopo",
    dateOfBirth: "Ojo ibi",
    state: "Ipinle",
    country: "Orilẹ -ede",
    loyaltyId: "ID iṣootọ",
    createdAt: "Ti ṣẹda ni",
    noOfTransactionsMade: "Rara. Ti awọn iṣowo ti a ṣe",
    yourDownloadWillStart: "Gbigba lati ayelujara rẹ yoo bẹrẹ ni iṣẹju kan",
    exportCustomers: "Okeere Onibara",
    youhaveSuccessfullyToppedUp:
      "Rẹ ti ṣaṣeyọri ni aṣeyọri si Awọn apakan SMS rẹ.",
    youNowHave: "O ni bayi",
    smsUnits: "SMS sipo",
    enterNumberOfSmsUnits: "Tẹ Nọmba Awọn ẹya SMS ti O pinnu lati Ra",
    pleaseEnterANumericValue: "Jọwọ tẹ iye nọmba kan sii",
    pay: "Sanwo",
    accountEditedSuccessfully: "A ti ṣatunkọ akọọlẹ rẹ ni aṣeyọri.",
    youAeCurrentlyOn: "O wa lọwọlọwọ",
    plan: "Gbero",
    userData: "Data olumulo",
    businessData: "DATA Iṣowo",
    businessCategory: "Business Catergory",
    pleaseSelectCurrency: "Jọwọ yan owo rẹ",
    selectYourCurrency: "Yan owo rẹ",
    purchaseMoreSmsUnits: "Ra awọn Ẹka SMS diẹ sii ni lilo fọọmu ni isalẹ",
    youHave: "O ni",
    atLeast4SmsUnitsRrequired:
      "O kere ju awọn sms 4 nilo fun iṣeduro, jọwọ gbe soke!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Jọwọ ṣayẹwo akọọlẹ banki rẹ lati ni ibamu pẹlu awọn ibeere 'Mọ Onibara Rẹ' (KYC). Eyi yoo gba ọ laaye lati gba awọn sisanwo nipasẹ USSD tabi gbigbe lẹsẹkẹsẹ, gba awọn aṣẹ lati ọdọ awọn alabara ati ilana idunadura ilana. Wulo fun awọn oniṣowo Naijiria nikan. Jọwọ tẹ bọtini isalẹ lati bẹrẹ.",
    reConnectBankAccount: "Tun-sopọ Bank Account",
    accountName: "Orukọ akọọlẹ",
    accountNumber: "Nọmba ifowopamọ",
    bankName: "Bank Name",
    verified: "Ti jẹrisi",
    accountDetails: "Awọn alaye akọọlẹ",
    kycBankAccount: "KYC",
    createTier: "Ṣẹda Ipele",
    fileUploadSuccessful: "ti gbe faili ni aṣeyọri",
    fileUploadFailed: "ikojọpọ faili kuna",
    createLoyaltyProgram: "Ṣẹda Eto Iṣootọ kan",
    createLoyalty: "Ṣẹda iṣootọ",
    name: "Oruko",
    loyaltyArtwork: "Iṣẹ ọnà Iṣootọ",
    clickToUpload: "Tẹ lati po si",
    amountToPointsRatio: "Iye to Points Ratio",
    points: "Awọn aaye",
    recommendedAmountToPointRatio:
      "Niyanju: ₦ 1 si aaye 1. ie fun gbogbo 1 Naira ti o lo, awọn alabara rẹ gba aaye 1",
    pleaseTypeIn: "Jọwọ tẹ sii",
    toDeleteLoyalty: "lati pa iṣootọ yii kuro",
    deleteLoyalty: "Pa iṣootọ rẹ",
    toConfirm: "lati jẹrisi",
    edit: "Ṣatunkọ",
    pointsAwardedSuccessfully: "Awọn Aami Aami ti Aseyori.",
    enterPointValueToAward: "Tẹ Iye Ojuami O fẹ lati fun Awọn alabara ni ẹbun",
    award: "Eye",
    awardPointValuesToCustomer: "Iye Aami Aami Aami si Onibara",
    enrollMembersToLoyalty: "Fi orukọ silẹ Awọn ọmọ ẹgbẹ si iṣootọ",
    awardPoints: "Ojuami Eye",
    enroll: "Forukọsilẹ",
    home: "Ile",
    loyalty: "Iṣootọ",
    enrollCustomers: "Ṣe iforukọsilẹ Awọn alabara",
    selected: "Ti yan",
    customer: "Onibara",
    loyaltyActivationSuccessful: "Iṣisẹ iṣootọ jẹ aṣeyọri.",
    loyaltyDeactivationSuccessful: "Imuduro iṣootọ jẹ aṣeyọri.",
    viewTier: "Wo Ipele",
    deactivate: "Mu-Muu ṣiṣẹ",
    activate: "Muu ṣiṣẹ",
    actions: "Awọn iṣe",
    nameOfLoyalty: "Orukọ Iṣootọ",
    loyaltyStatus: "Ipo Iṣootọ Ipo Iṣootọ",
    numberOfTiers: "Nọmba ti Tiers",
    createdOn: "Ti Ṣẹda Lori",
    createATier: "Ṣẹda ipele kan",
    stopCreatingTierConfirmation: "Ṣe o fẹ dawọ ṣiṣẹda ipele kan?",
    stopEditingTierConfirmation: "Ṣe o fẹ dẹkun ṣiṣatunṣe ipele yii?",
    nameOfTier: "Orukọ Ipele",
    minimumSpendingTarget: "Afojusun inawo to kere",
    maximumSpendingTarget: "O pọju inawo Àkọlé",
    minimumSpendingTargetRequired: "ibi -afẹde inawo to kere julọ jẹ ibeere",
    maximumSpendingTargetRequired: "ibi -afẹde inawo ti o pọ julọ jẹ ibeere",
    rewardSponsor: "Onigbowo ere",
    pleaseChooseARewardSponsor: "Jọwọ yan onigbowo ere kan",
    self: "Ti ara ẹni",
    partner: "Alabaṣepọ",
    rewardPartner: "Alabaṣepọ Ere",
    pleaseSelectRewardPartner: "Jọwọ yan alabaṣiṣẹpọ ere rẹ",
    rewards: "Awọn ere",
    pleaseSelectAReward: "Jọwọ yan ẹsan kan",
    instructionsOnRedeemingReward:
      "Awọn ilana lori Bi Onibara ṣe yẹ ki o ra ere",
    pleaseFillInThisField: "Jọwọ Fọwọsi ni aaye yii!",
    toDeleteThisTier: " lati paarẹ ipele yii",
    deleteTier: "Pa Ipele rẹ",
    viewMembers: "Wo Awọn ọmọ ẹgbẹ",
    membersEnrolled: "Awọn ọmọ ẹgbẹ ti forukọsilẹ",
    instruction: "Ilana",
    membersIn: "Awọn ọmọ ẹgbẹ ninu",
    availableTiersInLoyalty: "Awọn ipele (awọn) to wa ninu Eto Iṣootọ",
    tiers: "Tiers",
    totalTier: "Lapapọ ipele",
    availableLoyaltyProgramme: "Eto Iṣootọ Wa",
    totalLoyalties: "AWỌN IGBAGBỌ lapapọ",
    memberDetails: "Awọn alaye Ẹgbẹ",
    nameOfCustomer: "Orukọ Onibara",
    address: "Adirẹsi",
    allEnrolledMembers: "Gbogbo Awọn ọmọ ẹgbẹ ti o forukọ silẹ",
    thisIsToWishYouHappyBirthday:
      "Eyi ni lati nireti fun ọ ni ọjọ -ibi ti o ku pupọ ati igbesi aye aisiki. O ṣeun fun gbogbo ohun ti o jẹ si Loyster. Ayẹyẹ ayẹyẹ!",
    inputAnOfferPlease: "Fi ohun elo wọle jọwọ",
    pleaseSelectTheInsertPoint:
      "Jọwọ yan aaye ti o fi sii ninu ifiranṣẹ ki o tẹ lẹẹkansi",
    birthdayOfferAndMessage: "Ipese Ọjọ -ibi ati Ifiranṣẹ",
    birthdayOffer: "Ipese Ọjọ -ibi",
    birthdayMessage: "Ifiranṣẹ Ọjọ -ibi",
    noOfferFound: "Ko ri ipese kankan",
    settingABirthdayOffer:
      "Ṣiṣeto ipese ọjọ -ibi gba awọn alabara laaye lati gba ipese yii nipasẹ SMS lori ọjọ -ibi wọn",
    createOffer: "Ṣẹda Ipese",
    whatIsTheOffer: "Kini ipese naa?",
    editMessage: "Ṣatunkọ Ifiranṣẹ",
    insert: "Fi sii",
    theNameOfCustomerInserted: "Orukọ alabara yoo fi sii nibi",
    theBirtdayOfferInserted: "Ipese ọjọ -ibi yoo fi sii nibi",
    youSuccessfullyAddedNewBranch: "O ti ṣafikun ẹka tuntun ni aṣeyọri!",
    addNewBranch: "Ṣafikun Ẹka Tuntun",
    addBranch: "Ṣafikun Ẹka",
    additionalBranchWillIncur: "Ẹka Afikun yoo fa",
    perBranch: "fun ẹka",
    ecommerceBranchCosts: "Awọn idiyele Ẹka Ecommerce",
    pleaseEnterBranchName: "Jọwọ tẹ orukọ ẹka naa sii",
    pleaseEnterBranchAddress1: "Jọwọ tẹ laini adirẹsi ti ẹka 1",
    enterBranchAddress1: "Tẹ laini adirẹsi ti ẹka 1",
    enterBranchAddress2: "Tẹ laini adirẹsi ti ẹka 1",
    pleaseEnterBranchAddress2: "Jọwọ tẹ laini adirẹsi ti ẹka 2",
    enterBranchName: "Tẹ orukọ ẹka naa sii",
    successfullyAddedStaff: "O ti ṣafikun oṣiṣẹ tuntun ni aṣeyọri!",
    addNewStaff: "Ṣafikun Oṣiṣẹ Tuntun",
    addStaff: "Ṣafikun Oṣiṣẹ",
    additionalStaffWillIncur: "Afikun Oṣiṣẹ yoo fa",
    perStaff: "fun osise.",
    pleaseEnterStaffEmail: "Jọwọ tẹ imeeli osise naa sii",
    pleaseEnterStaffUsername: "Jọwọ tẹ orukọ olumulo ti oṣiṣẹ naa",
    pleaseEnterStaffPassword: "Jọwọ tẹ ọrọ igbaniwọle ti oṣiṣẹ",
    pleaseSelectStaffRole: "Jọwọ yan ipa oṣiṣẹ",
    selectStaffRole: "Yan ipa ti oṣiṣẹ",
    enterStaffEmail: "Tẹ imeeli osise",
    enterStaffUsername: "Tẹ orukọ olumulo ti oṣiṣẹ sii",
    enterStaffPassword: "Tẹ ọrọ igbaniwọle ti oṣiṣẹ sii",
    spacesNotAllowedInUsername: "awọn aaye ko gba laaye ni orukọ olumulo",
    admin: "Alakoso",
    pleaseSelectBusinessToAttachStaff: "Jọwọ yan iṣowo lati somọ oṣiṣẹ",
    searchForBranchToAttachStaff: "Wa fun ẹka lati so oṣiṣẹ",
    username: "Orukọ olumulo",
    role: "Ipa",
    areYouSureToDeleteThis: "Ṣe o daju pe o pa eyi kuro",
    branches: "Awọn ẹka",
    upgradeYourPlan: "Ṣe igbesoke Eto rẹ.",
    add: "Ṣafikun",
    addNew: "Fi Tuntun kun",
    customerWithEmailAlreadyExists:
      "Onibara pẹlu imeeli/nọmba foonu ti wa tẹlẹ!",
    successfullyAddedNewCustomer: "O ti ṣafikun alabara tuntun ni aṣeyọri!",
    addCustomer: "Ṣafikun Onibara",
    pleaseEnterCustomerFirstName: "Jọwọ tẹ orukọ alabara akọkọ sii",
    pleaseEnterCustomerLastName: "Jọwọ tẹ orukọ alabara ti alabara sii",
    pleaseEnterCustomerPhoneNumber: "Jọwọ tẹ nọmba foonu alabara sii",
    pleaseEnterCustomerEmail: "Jọwọ tẹ imeeli alabara",
    pleaseEnterCustomerAddressLine: "Jọwọ tẹ laini adirẹsi ti alabara",
    pleaseEnterCustomerOtherAddress: "Jọwọ tẹ adirẹsi miiran ti alabara sii",
    pleaseSelectCustomerGender: "Jọwọ yan akọ tabi abo ti alabara",
    gender: "Akọ",
    male: "Ọkunrin",
    female: "Obinrin",
    bank: "Banki",
    selectBank: "Yan Bank",
    stateOrRegionOrProvince: "Ipinle/Ekun/Ekun",
    customerNotes: "Awọn akọsilẹ Onibara",
    sendSms: "Firanṣẹ SMS",
    editCustomer: "Onibara Ṣatunkọ",
    redeemReward: "Gba Ere pada",
    issueLoyaltyCard: "Kaadi Iṣootọ Iṣowo",
    deleteCustomer: "Pa Onibara rẹ",
    youveSuccessfullyAssignedLoyaltyMembership:
      "O ti ni ifijišẹ sọtọ ID ọmọ ẹgbẹ iṣootọ kan",
    noMembershipIdAvailable:
      "Ko si awọn idanimọ ẹgbẹ. Jọwọ kan si wa lori hello@loystar.co",
    sendEmail: "Fi Imeeli ranṣẹ",
    membershipPoints: "Points omo egbe",
    customerDetails: "Awọn alaye Onibara",
    close: "Sunmọ",
    loyaltyBalance: "Iwontunws.funfun Iduroṣinṣin",
    pointsBalance: "Points Iwontunws.funfun",
    starBalance: "Iwontunws.funfun",
    requiredPoints: "Awọn aaye ti a beere",
    requiredStars: "Awọn irawọ ti a beere",
    reddemCode: "Ìràpadà Codefin",
    toDeleteThisCustomer: "lati pa onibara yi",
    customerHasBeenDeletedSuccessfully: "Onibara ti paarẹ ni aṣeyọri!",
    customerWithPhoneAlreadyExists: "Onibara ti o ni nọmba foonu ti wa tẹlẹ",
    customerWasSuccessfullyEdited: "Onibara ti satunkọ ni ifijišẹ",
    anErrorOccured: "Aṣiṣe kan ṣẹlẹ",
    phoneNumberIncludeCountry: "Nọmba foonu (Fi koodu Orilẹ -ede kun)",
    yourFileQueuedForUpload:
      "Faili rẹ ti ni laini fun ikojọpọ. Jọwọ sọ oju -iwe naa pada lẹhin iṣẹju diẹ.",
    thereWasAnErrorPerformingOperation: "Aṣiṣe kan wa ti n ṣiṣẹ abẹ!",
    pleaseSelectFile: "Jọwọ Yan Faili kan!",
    oopsOnlyCsvFilesAllowed:
      "Yeee! Awọn faili CSV nikan ni o gba laaye. Jọwọ gbe faili .csv kan wọle.",
    fileShouldBeSmallerThan5Mb:
      "Faili yẹ ki o kere ju 5MB. Ti o ba ni faili ti o tobi, jọwọ fi imeeli ranṣẹ support@loystar.co. e dupe",
    customerFirstNameIsRequired: "orukọ alabara akọkọ ni a nilo lori ila",
    customerPhoneNumberIsRequired:
      "nọmba foonu onibara wa ni ti beere lori kana",
    importCustomers: "Gbe wọle Onibara",
    upload: "Po si",
    clickIconToDownloadCsv: "Tẹ aami yii lati ṣe igbasilẹ awoṣe faili CSV.",
    getGoogleSheetVersion: "Gba ẹya dì Google",
    clickOrDragFileToUpload: "Tẹ tabi fa faili si agbegbe yii lati gbejade",
    missingOrInvalidColumnHeader:
      "Akọsori iwe ti o sonu tabi ti ko wulo. Jọwọ tẹle ọna awoṣe. E dupe.",
    youHaveImported: "O ti wole",
    youSuccessfullyRedeemedReward: "O ti ṣaṣeyọri irapada ere rẹ!",
    sixDigitCode: "Koodu oni -nọmba mẹfa",
    pleaseEnterCustomerRewardCode: "Jọwọ tẹ koodu ere ti alabara sii",
    enterRewardCode: "Tẹ koodu ere sii. (Koodu ere jẹ ifura ọran)",
    selectLoyaltyProgram: "Yan eto iṣootọ",
    stamps: "Awọn ontẹ",
    smsUnitsLow: "SMS sipo Low",
    pleaseTopUpSmsUnits: "Jọwọ Top Up SMS sipo",
    smsSuccessfullySentForDelivery: "Ti firanṣẹ SMS ni ifijišẹ fun ifijiṣẹ!",
    sendSmsTo: "Firanṣẹ SMS si",
    allCustomers: "Gbogbo Onibara",
    unitsAvailable: "Awọn sipo Wa",
    pleaseTypeInTheMessage: "Jọwọ tẹ ifiranṣẹ naa",
    message: "Ifiranṣẹ",
    charactersRemaining: "awọn ohun kikọ silẹ",
    avoidUseOfSpecialCharacters:
      "Yago fun lilo Awọn ohun kikọ Pataki ati Emojis lati ṣetọju Awọn apakan SMS.",
    note: "Akiyesi",
    errorFetchingCustomersMultilevelDetail:
      "Aṣiṣe Wiwa Awọn Onibara Awọn alaye MultiLevel",
    search: "Wa",
    reset: "Tunto",
    importCustomer: "Gbe wọle Onibara",
    addNewCustomer: "Ṣafikun Onibara Tuntun",
    sendSmsBroadcast: "Firanṣẹ Ifọrọranṣẹ SMS",
    totalCustomers: "Lapapọ onibara",
    disbursementDetails: "Awọn alaye isanwo",
    paidBy: "Ti sanwo nipasẹ",
    disbursed: "Ti san",
    bankAccountName: "Bank Account Name",
    bankAccountNumber: "Bank Account Number",
    transferInitiated: "Gbigbe Ibẹrẹ",
    transferCompleted: "Gbigbe Pari",
    pleaseEnterAValid: "Jọwọ tẹ wulo",
    begin: "berè",
    end: "pari",
    date: "ọjọ",
    paymentDate: "Ọjọ isanwo",
    selectDisbursementDuration: "Yan Iye isanwo",
    totalSettled: "Lapapọ gbe",
    totalUnsettled: "Lapapọ Idarudapọ",
    toDeleteThisSale: "lati pa tita yi run",
    draftSaleDeletedSuccessfully: "Tita Akọpamọ ti paarẹ ni aṣeyọri!",
    deleteSale: "Paarẹ Tita",
    pleaseTypeInYourTotal: "Jọwọ tẹ lapapọ rẹ lati jẹrisi piparẹ",
    billDetails: "Awọn alaye Bill",
    printBill: "Bill Sita",
    servedBy: "Iranṣẹ nipasẹ",
    total: "Lapapọ",
    createdDate: "Ọjọ ti a Ṣẹda",
    createdTime: "Akoko ti a ṣẹda",
    status: "Ipo",
    loginSuccessful: "Buwolu wọle Aseyori",
    pleaseWaitWhileWeConnectAccount: "Jọwọ duro nigba ti a ba so akọọlẹ rẹ pọ",
    connectionFailedTryAgain: "Asopọ kuna. Jọwọ gbiyanju lẹẹkansi.",
    connectionSuccessful: "Asopọ Aṣeyọri",
    viewDetails: "Wo Awọn alaye",
    enable: "Muu ṣiṣẹ",
    free: "Ọfẹ",
    cost: "Iye owo",
    visitWebsite: "Ṣabẹwo Oju opo wẹẹbu",
    pleaseUpgradeYourPlanToPro:
      "Jọwọ ṣe igbesoke ero rẹ si Pro Plus lati mu ohun elo yii ṣiṣẹ",
    connectYourBankAccount:
      "So Akọọlẹ Banki rẹ pọ lati ni anfani lati gba awọn sisanwo.",
    disable: "Muu ṣiṣẹ",
    enableApp: "Mu Ohun elo ṣiṣẹ",
    addNewProductToInvoice: "Ṣafikun Ọja Tuntun si risiti",
    toDeleteThisInvoice: "lati pa risiti yii",
    invoiceDeletedSuccessfully: "A ti paarẹ risiti rẹ ni aṣeyọri!",
    deleteInvoice: "Pa risiti rẹ",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "O ti ṣafikun ifiranṣẹ isanwo Invo ni aṣeyọri!",
    pleaseEnterPaymentInstructions: "Jọwọ tẹ awọn ilana isanwo",
    invoiceId: "ID risiti",
    paidDate: "San Ọjọ",
    reference: "Itọkasi",
    productAdded: "Ọja ti ṣafikun",
    productOutOfStock: "Ọja ko si ni ọja. Jọwọ tun-iṣura.",
    totalInvoices: "AWỌN AWỌN NIPA",
    totalPaidInvoices: "Apapọ awọn isanwo ti a san",
    totalUnpaidInvoices: "AWỌN AWỌN AWỌN AWỌN AWỌN NIPA",
    loyaltyProgramDeleted: "Eto Iṣootọ Paarẹ",
    thisLoyaltyProgramDeletedSuccessfully: "Eto iṣootọ yii ti paarẹ ni aṣeyọri",
    thisLoyaltyProgramEditedSuccessfully:
      "A ti satunkọ eto iṣootọ yii ni aṣeyọri",
    loyaltyProgramAddedSuccessfully: "Eto iṣootọ ti ṣafikun ni aṣeyọri",
    loyaltyProgramEdited: "Iṣatunṣe Eto Iṣatunkọ",
    loyaltyProgramAdded: "Eto Iṣootọ Fikun",
    loyaltyDetails: "Awọn alaye Iṣootọ",
    doYouWantToCloseDialog: "Ṣe o fẹ lati pa ibanisọrọ yii?",
    pleaseEnterLoyaltyName: "Jọwọ tẹ orukọ iṣootọ rẹ sii",
    programType: "Iru Eto",
    pleaseSelectProgramType: "Jọwọ yan iru eto kan",
    simplePoints: "Awọn aaye ti o rọrun",
    stampsProgram: "Awọn eto Stamps",
    threshold: "Ala",
    pleaseEnterLoyaltyThreshold: "Jọwọ tẹ iṣootọ iṣootọ",
    reward: "Ère",
    pleaseEnterLoyaltyReward: "Jọwọ tẹ ere iṣootọ",
    totalUserPoints: "Lapapọ olumulo Points",
    totalUserStamps: "Total ontẹ",
    spendingTarget: "Lilo afojusun",
    spendingTargetHint1:
      "Ifojusun inawo ni iye ti alabara gbọdọ lo lati gba ere naa. Iye owo 1 = aaye 1.",
    spendingTargetHint2:
      "Ibi -afẹde ontẹ ni iye awọn ontẹ ti alabara gbọdọ gba lati gba ere naa. apere. 5",
    addNewLoyaltyProgram: "Ṣafikun Eto Iṣootọ Tuntun",
    addLoyaltyProgram: "Ṣafikun Eto Iṣootọ",
    loyaltyProgramType: "Iru Eto Iṣootọ",
    pleaseSelectLoyaltyProgramType: "Jọwọ yan iru eto iṣootọ",
    nameOfProgram: "Orukọ eto naa",
    pleaseEnterProgramName: "Jọwọ tẹ orukọ eto sii",
    whatIsTheReward: "Kini ere naa?",
    egNextPurchaseIsFree: "Fun apẹẹrẹ Titaja atẹle jẹ ọfẹ",
    customerName: "Onibara Name",
    guestCustomer: "Onibara alejo",
    orderConfirmedSuccessfully: "Ti fidi aṣẹ mulẹ ni aṣeyọri",
    orderCancelledSuccessfully: "Ti fagile aṣẹ ni aṣeyọri",
    confirmOrder: "Jẹrisi aṣẹ",
    cancelOrder: "Fagilee aṣẹ",
    allOrders: "Gbogbo Bibere",
    totalOrders: "AWỌN ỌJỌ lapapọ",
    doYouWantToAcceptOrder: "Ṣe o fẹ lati gba aṣẹ yii?",
    doYouWantToCancelOrder: "Ṣe o fẹ fagilee aṣẹ yii?",
    orderDetails: "Awọn alaye aṣẹ",
    orderCreatedAt: "Bere fun ni a ṣẹda ni",
    supplier: "Olupese",
    productName: "Ọja Name",
    quantity: "Opoiye",
    unitPrice: "Oye eyo kan",
    receivedBy: "Ti gba Nipa",
    reportFrom: "Iroyin lati",
    totalSupplies: "Awọn ipese lapapọ",
    allRightsReserved: "Gbogbo awọn Ẹtọ wa ni ipamọ",
    toDeleteThisTransaction: "lati pa Idunadura yii kuro",
    transactionDeletedSuccessfully:
      "Iṣowo ti paarẹ ni aṣeyọri. A ti da iṣura pada si akojo oja.",
    deleteTransaction: "Pa Iṣowo kuro",
    transactionDetails: "Awọn alaye Iṣowo",
    printReceipt: "Atẹjade Atẹjade",
    channel: "Ikanni",
    discount: "Ẹdinwo",
    profit: "Profrè",
    discountedSales: "Tita ẹdinwo",
    errorFetchingRecord: "Igbasilẹ Gbigba aṣiṣe",
    exportTransactions: "Awọn Iṣowo okeere",
    errorFetchingSalesRecord: "Aṣiṣe n gba igbasilẹ Titaja fun Si ilẹ okeere.",
    totalSellingPrice: "Iye Iye Tita lapapọ",
    totalCostPrice: "Iye Iye Apapọ",
    appliedDiscount: "Applied eni",
    noOfItems: "Rara ti Awọn nkan",
    sales: "Tita",
    export: "Si ilẹ okeere",
    totalProfit: "Lapapọ èrè",
    totalBalanceInPeriod: "Apapọ Iwontunws.funfun ni Akoko",
    allTimeSales: "Gbogbo Aago Tita",
    records: "Awọn igbasilẹ",
    todaysSales: "Tita Oni",
    transaction: "idunadura",
    youHaveExceededTotalNumberOfProducts:
      "O ti kọja nọmba lapapọ ti awọn ọja ti o gba laaye lori ero rẹ. Ṣe igbesoke ero rẹ lati gbadun iye to ga julọ.",
    youNeedToHaveLoyaltyProgram: "O nilo lati ni Eto Iṣootọ lati lo ẹya yii!",
    price: "Iye owo",
    category: "Ẹka",
    stockTracking: "Àtòjọ Iṣura",
    stockCount: "Iṣura Iṣura",
    taxed: "Owo -ori",
    originalPrice: "Original Iye",
    costPrice: "Iye idiyele",
    unit: "Ẹyọ",
    productImage: "Aworan Ọja",
    taxRate: "Oṣuwọn owo -ori",
    taxType: "Oriṣi Owo -ori",
    trackInventory: "Oja Track",
    variants: "Awọn iyatọ",
    hasVariants: "O ni Awọn iyatọ",
    importProduct: "Gbe ọja wọle",
    exportProducts: "Awọn ọja okeere",
    addNewProduct: "Fi Ọja Tuntun kun",
    viewCategory: "Wo Ẹka",
    viewSuppliers: "Wo Awọn olupese",
    receiveInventory: "Gba Oja",
    printAllProductsTag: "Tẹjade Gbogbo Awọn Ọja Tag",
    deleteAll: "Pa Gbogbo rẹ Pa",
    totalProducts: "Awọn ọja lapapọ",
    youveSuccessfullyAddedANewCategory: "O ti ṣafikun ẹka tuntun ni aṣeyọri",
    addNewCategory: "Ṣafikun Ẹka Tuntun",
    addCategory: "Ṣafikun Ẹka",
    categoryName: "Orukọ Isori",
    pleaseEnterCategoryName: "Jọwọ tẹ orukọ ẹka sii",
    stampsTarget: "Àkọlé ontẹ",
    sendInventory: "Fi Iṣura ranṣẹ",
    productDetails: "Awọn alaye ọja",
    youveSuccessfullyEditedThisCategory: "O ti ṣatunkọ ẹka yii ni aṣeyọri",
    update: "Imudojuiwọn",
    categoryList: "Akojọ ẹka",
    categories: "Isori",
    enterQuantityToUpdate: "Tẹ opoiye kan lati ṣe imudojuiwọn",
    inventorySentSuccessfully: "Ti firanṣẹ akojo oja ni aṣeyọri!",
    updateInventory: "Imudojuiwọn Iṣura",
    currentQuantity: "Iwọn lọwọlọwọ",
    pleaseEnterQuantityToAdd: "Jọwọ tẹ opoiye ti o fẹ fikun",
    pleaseSelectABranch: "Jọwọ yan Ẹka kan",
    searchForBranch: "Wa fun ẹka",
    youCantSendMoreThanStock: "O ko le firanṣẹ diẹ sii ju ti o ni ni iṣura lọ",
    send: "Firanṣẹ",
    pleaseEnterQuantityToSend: "Jọwọ tẹ opoiye ti o fẹ firanṣẹ",
    productNameIsRequiredOnRow: "a nilo orukọ ọja ni ila",
    productCategoryIsRequiredOnRow: "a nilo ẹka ọja ni ila",
    productPriceIsRequiredOnRow: "idiyele ọja ni a nilo lori ila",
    productUnitIsRequiredOnRow: "ọja ti nilo lori ila",
    productQuantityIsRequiredOnRow: "a nilo opoiye ọja ni ila",
    productVariantsRequireTracking: "awọn iyatọ ọja nilo ipasẹ!",
    pleaseAddVariantClickButton:
      "Jọwọ ṣafikun iyatọ kan nipa tite bọtini afikun!",
    totalVariantsMoreThanSelectedQuantity:
      "Lapapọ Awọn iyatọ jẹ diẹ sii ju opoiye ọja ti a yan, Pls dinku iwọn iyatọ",
    productEditedSuccessfully: "A ti satunkọ ọja ni aṣeyọri!",
    fileTooLargeLessThan4Mb:
      "Iwọn faili ti tobi ju! Iwọn faili yẹ ki o kere ju 4MB.",
    suchVariantAlreadyExist: "Iru iyatọ tẹlẹ wa",
    addVariants: "Ṣafikun Awọn iyatọ",
    editProduct: "Ọja Ṣatunkọ",
    pleaseEnterProductName: "Jọwọ Tẹ Orukọ Ọja sii",
    pleaseEnterProductPrice: "Jọwọ Tẹ Iye Ọja sii",
    pleaseEnterProductOriginalPrice: "Jọwọ Tẹ Ọja Atilẹba Ọja",
    productDescription: "Apejuwe ọja",
    selectProductCategory: "Yan Ẹka Ọja",
    pleaseSelectProductCategory: "Jọwọ yan ẹka ọja",
    productCostPrice: "Iye idiyele Ọja",
    productSellingPrice: "Iye owo tita ọja",
    productOriginalPrice: "Ọja Original Price",
    maxFileSizeAllowedIs4Mb: "Max. Iwọn faili ti o gba laaye jẹ 4mb",
    productsWithPicturesArePublished:
      "Awọn ọja pẹlu awọn aworan ni a tẹjade lori Iwari lati gba awọn aṣẹ",
    shouldThisProductBeTracked: "Ṣe o yẹ ki o tọpinpin ọja yii?",
    pleaseSelectStockUnit: "Jọwọ yan ọja iṣura",
    stockUnit: "Iṣura Iṣura",
    bag: "BAG",
    bottle: "IGBA",
    bunch: "OUNJE",
    can: "CAN",
    carton: "KARTON",
    crate: "EKU",
    cup: "Cup",
    dozen: "MEJILA",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LITER",
    pack: "PACK",
    pair: "PAIRI",
    pieces: "PIECES",
    plate: "PATET.",
    tonne: "TONNE (MT)",
    uNIT: "UNIT",
    yard: "ÀGBÀ",
    pleaseEnterProductQuantity: "Jọwọ tẹ opoiye ọja sii",
    productQuantity: "Opoiye Ọja",
    isThisProductTaxed: "Njẹ ọja yii jẹ owo -ori?",
    selectTaxType: "Yan Iru -ori",
    pleaseSelectTaxType: "Jọwọ yan Iru -ori",
    progressive: "Onitẹsiwaju",
    proportional: "Ni ibamu",
    pleaseEnterProductTaxRate: "Jọwọ tẹ oṣuwọn owo -ori ọja",
    doesProductHaveVariants: "Ṣe ọja yi ni awọn iyatọ?",
    type: "Iru",
    value: "Iye",
    pleaseEnterVariantType: "Jọwọ tẹ iru iyatọ",
    pleaseEnterVariantValue: "Jọwọ tẹ iye iyatọ",
    pleaseEnterVariantPrice: "Jọwọ tẹ idiyele iyatọ",
    pleaseEnterVariantQuantity: "Jọwọ tẹ opoiye iyatọ",
    productDeletedSuccessfully: "Ọja ti paarẹ ni aṣeyọri!",
    categoryDeletedSuccessfully: "A ti paarẹ ẹka rẹ ni aṣeyọri!",
    toDeleteThisProduct: "lati pa Ọja yi",
    invalidProductQuantity: "Opoiye Ọja ti ko wulo",
    quantityAddedIsOverStock: "Iye ti o ṣafikun jẹ lori ohun ti o ni ni iṣura.",
    itemInventoryNotTracked: "A ko tọpinpin akojo ohun kan",
    addBulkQuantity: "Ṣafikun Iwọn Pupọ",
    enterBulkQuantity: "Tẹ Bulk opoiye",
    pleaseEnterBulkQuantity: "Jọwọ tẹ Bulk opoiye",
    youveSuccessfullyAddedANewProduct: "O ti ṣafikun ọja tuntun ni aṣeyọri!",
    pleaseEnterProductSellingPrice: "Jọwọ tẹ idiyele tita ọja",
    doYouWantToTrackProductStock: "Ṣe o fẹ lati tọpinpin ọja iṣura?",
    sellingPrice: "Iye owo tita",
    setProductExpiryReminder: "Ṣeto olurannileti ipari ọja",
    productExpiryDate: "Ọjọ Ipari Ọja",
    startRemindingFrom: "Bẹrẹ Iranti Lati",
    productSuppliesAddedSuccessfully: "O ti ṣafikun awọn ipese ọja ni aṣeyọri.",
    addProductSupplies: "Ṣafikun Awọn ipese Ọja",
    pleaseSelectSupplier: "Jọwọ Yan Olupese",
    nameOfProduct: "Orukọ Ọja",
    pleaseSelectProduct: "Jọwọ Yan Ọja",
    productVariant: "Iyatọ Ọja",
    pleaseSelectAVariant: "Jọwọ Yan Iyatọ kan",
    pleaseEnterUnitPrice: "Jọwọ Tẹ Iye Iye",
    businessBranch: "Ẹka Iṣowo",
    pleaseSelectBranch: "Jọwọ yan ẹka",
    youveSuccessfullyAddedANewSupplier:
      "O ti ṣafikun olupese tuntun ni aṣeyọri",
    addSupplier: "Ṣafikun Olupese",
    pleaseEnterSupplierEmail: "Jọwọ tẹ imeeli olupese sii",
    pleaseAddADescription: "Jọwọ ṣafikun apejuwe kan",
    anErrorOccuredProductsDeleted:
      "Aṣiṣe kan waye lakoko ṣiṣe iṣiṣẹ naa. Jọwọ ṣe akiyesi, awọn ọja diẹ le ti paarẹ ninu ilana naa",
    bulkDelete: "Pupọ Paarẹ",
    youAreAboutToDelete: "O ti fẹrẹ paarẹ",
    product: "Ọja",
    isDueToRedeem: "jẹ nitori irapada",
    aReward: "ere kan",
    pleaseSelectCustomerToReeemReward: "Jọwọ yan alabara kan lati ra ere pada.",
    youHaveNoLoyaltyProgramRunning: "Iwọ ko ni eto iṣootọ n ṣiṣẹ lọwọ",
    customerHhasNoPointsInLoyaltyProgram:
      "Onibara ko ni awọn aaye ninu eto iṣootọ yii",
    proceedWithPayment: "Tẹsiwaju pẹlu Isanwo?",
    youAreAboutToPayForTransactionUsingPoints:
      "O fẹrẹ sanwo fun idunadura lilo awọn aaye.",
    customerHas: "Onibara Ni",
    worth: "tọ",
    andIsNotSufficientToPayForTransaction:
      "ati pe ko to lati sanwo fun iṣowo yii. Ṣe wọn yoo fẹ lati ṣafikun iwọntunwọnsi ni lilo ọna isanwo miiran?",
    addCustomerLoyalty: "Ṣafikun Iṣootọ Onibara",
    pleaseAddCustomerFirst: "Jọwọ ṣafikun tabi yan alabara ni akọkọ.",
    pleaseWaitWhileWeGetReady: "Jọwọ duro, lakoko ti a mura silẹ",
    lowStock: "Kekere Iṣura",
    pleaseEnterAmountTendered: "Jọwọ tẹ iye ti a fiweranṣẹ",
    areYouSureToBookSaleOffline:
      "Ṣe o da ọ loju pe o fẹ iwe iwe tita yii ni aisinipo?",
    saleWouldBeBookedAutomatically:
      "Tita yoo wa ni iwe laifọwọyi nigbati o ba tan intanẹẹti rẹ",
    offlineSalesBookingCancelled: "Ti fagile fowo si titaja aisinipo",
    covid19Message:
      "COVID19: Fọ ọwọ rẹ pẹlu ọṣẹ tabi Sanitize lati da itankale naa duro. Duro lailewu Nigbagbogbo",
    saleSuccessfullyRecorded: "Ti ṣe igbasilẹ Tita ni aṣeyọri!",
    sendReceiptToEmail: "Fi iwe -ẹri ranṣẹ si Imeeli",
    sendThankYouSms: "Firanṣẹ ọpẹ SMS",
    sendShippingDetails: "Firanṣẹ awọn alaye Sowo",
    addLoyalty: "Ṣafikun Iṣootọ",
    searchCustomerNameOrNumber: "Wa orukọ alabara tabi nọmba",
    clickTheSearchCustomerBox: "Tẹ Apoti Onibara Wa ati Kaadi Iwoye",
    enterProductPrice: "Tẹ ọja Iye",
    enterPriceFor: "Tẹ Iye fun",
    searchForProduct: "Wa ọja",
    all: "Gbogbo",
    backToDashboard: "Pada si Dasibodu",
    viewDraftSales: "Wo Akọpamọ Tita",
    variantSelected: "ti yan iyatọ",
    variant: "iyatọ",
    thePreviousVariantSelectionNotAvailable:
      "Aṣayan iyatọ iṣaaju ko si fun iyatọ tuntun ti o yan da lori idiyele, jọwọ yi yiyan rẹ pada.",
    pleaseSupplyPositiveQuantityNumber: "Jọwọ pese nọmba opoiye ifiweranṣẹ",
    lowStockFor: "Kekere iṣura fun",
    clearVariants: "Ko awọn iyatọ",
    pleaseEnterQuantity: "Jọwọ tẹ Opoiye",
    picture: "Aworan",
    redemptionToken: "Àmi Ìràpadà",
    token: "Àmi",
    totalSpent: "Lapapọ Lo",
    confirmPayment: "Jẹrisi isanwo",
    hasPaymentBeenMade: "Njẹ a ti ṣe ilana isanwo ni aṣeyọri?",
    enterTransactionReference: "Tẹ itọkasi idunadura ti o sanwo pẹlu",
    pleaseSelectACustomer: "Jọwọ yan alabara kan!",
    areYouSureToApplyDiscount: "Ṣe o da ọ loju pe o fẹ lo ẹdinwo?",
    addYourBankAccountToEnableUssd:
      "Ṣafikun akọọlẹ banki rẹ lati jẹki gbigbe USSD lẹsẹkẹsẹ nipasẹ uPay",
    totalAmountToPay: "Lapapọ iye lati San",
    doYouWantToCancelTransaction: "Ṣe o fẹ fagilee iṣowo yii?",
    savePrintBill: "Fipamọ/Sita Bill",
    enterAmountCollectedForCustomer: "Tẹ iye ti a gba fun alabara",
    recordSale: "Tita igbasilẹ",
    checkOutWith: "Ṣayẹwo pẹlu",
    instantTransfer: "Gbigbe Lẹsẹkẹsẹ",
    dialTheUSSDCode: "Tẹ koodu USSD",
    pleaseSelectABank: "Jọwọ yan banki kan",
    payWithUSSD: "San Pẹlu USSD",
    sendBillTo: " - Firanṣẹ Bill si",
    waitingForUSSDTransfer: "Nduro fun Gbigbe USSD",
    percent: "Ogorun",
    applyDiscount: "Waye ẹdinwo",
    thisBillHasBeenSaved: "Owo yii ti wa ni ipamọ",
    saveDraft: "Fipamọ Akọpamọ",
    pleaseTypeANameToIdentifyCustomer:
      "Jọwọ tẹ ni orukọ kan lati ṣe idanimọ alabara",
    paymentDetails: "Awọn alaye isanwo",
    basePrice: "Ipilẹ Iye",
    staff: "Oṣiṣẹ",
    subTotal: "Apapo",
    durationMonths: "Iye (oṣù)",
    selectADuration: "Yan Iye akoko kan",
    oneMonth: "Oṣu 1",
    twoMonths: "Awọn oṣu 2",
    threeMonths: "3 Awọn oṣu",
    sixMonths: "6 Awọn oṣu",
    twelveMonths: "Awọn oṣu 12",
    eighteenMonths: "Awọn oṣu 18",
    twentyFourMonths: "Awọn oṣu 24",
    twoMonthsFree: "(Awọn oṣu 2 Ọfẹ)",
    threeMonthsFree: "(Awọn oṣu 3 Ọfẹ)",
    fiveMonthsFree: "(Awọn oṣu 5 Ọfẹ)",
    yourAccountHasBeen: "Akọọlẹ rẹ ti wa",
    renewed: "lotun",
    upgraded: "igbegasoke",
    successfully: "ni ifijišẹ",
    yourSubscription: "Alabapin rẹ",
    youAreCurrentlyEnrolledOnA: "O ti wa ni Lọwọlọwọ enrolled lori kan",
    pleaseChooseAPaymentOption: "Jọwọ Yan Aṣayan Isanwo kan",
    planRenewal: "Isọdọtun Eto",
    planUpgrade: "Igbesoke Eto",
    pleaseSelectDurationToPayFor:
      "Jọwọ Yan Iye akoko ti o pinnu lati sanwo fun",
    staffAccounts: "Awọn iroyin Awọn oṣiṣẹ",
    ecommerce: "Iṣowo Iṣowo",
    pleaseSelectAPlan: "Jọwọ yan eto kan",
    includeAddons: "Fi awọn afikun kun",
    viewTransactions: "Wo Awọn iṣowo",
    customerHasNoCompletedTansactions: "Onibara ko ni awọn iṣiṣẹ pari sibẹsibẹ",
    branch: "Ẹka",
    enterNumberOfEcommerceBranches: "Tẹ Nọmba ti Awọn ẹka Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Tẹ Nọmba ti Awọn ẹka Ecommerce Ti O pinnu lati Sanwo Fun",
    ecommerceIntegration: "Iṣọpọ Ecommerce",
    enterNumberOfBranches: "Tẹ Nọmba Awọn ẹka",
    enterNumberOfAdditionalBranchesToPay:
      "Tẹ Nọmba ti Awọn ẹka afikun ti O pinnu lati Sanwo Fun",
    enterNumberOfStaffs: "Tẹ Nọmba Awọn oṣiṣẹ",
    enterNumberOfStaffsToPayFor:
      "Tẹ Nọmba Awọn oṣiṣẹ ti O pinnu lati Sanwo Fun",
    discountApplies: "Eni Waye",
    starsOnThe: "irawọ lori awọn",
    offer: "pese",
    get: "Gba",
    moreStarsToRedeem: "awọn irawọ diẹ sii lati rapada",
    taxVat: "Owo -ori (VAT)",
    callCashierToCompletePayment: "Pe Cashier si Isanwo Ipari",
    receipt: "Gbigba owo",
    dear: "Olufẹ",
    thankYouForYourOrderFindGoods:
      "O ṣeun fun aṣẹ rẹ. Jọwọ wa awọn ẹru wọnyi ti a pese, bi a ti gba.",
    shippingNote: "Akọsilẹ Sowo",
    enterShippingNote: "Tẹ Akọsilẹ Sowo",
    shippingAddress: "Adiresi wo ni a ma gbe wa",
    enterShippingAddress: "Tẹ adirẹsi Sowo sii",
    wellDoneYouAreDueToRedeem: "Kú isé! O yẹ lati rapada",
    toGetYourRewardNextVisit: "lati gba ẹsan rẹ ni ibẹwo atẹle rẹ. e dupe",
    pointsOnThe: "Ojuami lori",
    morePointsToRedeem: "awọn aaye diẹ sii lati rà pada",
    showCode: "Fi koodu han",
    toGetYourRewardOnNextVisit: "lati gba ẹsan rẹ ni ibẹwo atẹle rẹ. e dupe",
    earn: "Jo'gun",
    delivaryNote: "Akọsilẹ Ifijiṣẹ",
    draftSales: "Titaja Akọpamọ",
    startDate: "Ọjọ ibẹrẹ",
    endDate: "Ọjọ ipari",
    orders: "Awọn aṣẹ",
    checkout: "ṣayẹwo",
    noProductItem: "Ko si Ohun elo Ọja",
    selectProductImage: "Yan Aworan Ọja",
    selectCountry: "Yan orilẹ -ede",
    selectRegion: "Yan Ipinle/Ekun",
    printProductTag: "Titẹ ọja Tag",
    transactionReference: "Itọkasi iṣowo",
    Cashier: "Oniṣowo",
    Manager: "Oluṣakoso",
    Owner: "Olohun",
    Admin: "Alakoso",
    addPartners: "Fi Awọn alabaṣepọ kun",
    addNewLoyaltyPartner: "Fi New iṣootọ Partner",
    pleaseEnterCompanyName: "Jọwọ tẹ Orukọ Ile-iṣẹ sii",
    companyName: "Orukọ Ile-iṣẹ",
    pleaseEnterCompanyRepName: "Jọwọ tẹ orukọ aṣoju ile-iṣẹ sii",
    companyRepName: "Orukọ Aṣoju Ile-iṣẹ",
    pleaseEnterCompanyRepEmail: "Jọwọ tẹ imeeli ti aṣoju ile-iṣẹ sii",
    companyRepEmail: "Imeeli ti aṣoju ile-iṣẹ",
    pleaseEnterCompanyRepPhone: "Jọwọ tẹ nọmba foonu ti aṣoju ile-iṣẹ sii",
    companyRepPhone: "Nọmba foonu ti aṣoju ile-iṣẹ",
    addReward: "Fi ere kun",
    pleaseEnterRewardName: "Jọwọ tẹ ẹsan orukọ",
    rewardName: "Orukọ Ẹsan",
    rewardQuantity: "Opoiye Ere",
    rewardDescription: "Apejuwe ere",
    rewardType: "Ere Iru",
    pleaseEnterRewardType: "Jọwọ tẹ iru ere sii",
    pleaseEnterRewardQuantity: "Jọwọ tẹ iye ere sii",
    pleaseEnterRewardDescription: "Jọwọ tẹ awọn ere apejuwe",
    fineDining: "Fine Ile ijeun",
    luxuryFashion: "Igbadun Fashion",
    hotels: "Awọn ile itura",
    travel: "Irin-ajo",
    foodAndBeverage: "Ounje ati Ohun mimu",
    fashion: "Njagun",
    health: "Ilera",
    furniture: "Awọn ohun-ọṣọ",
    entertainment: "Idanilaraya",
    automobile: "Ọkọ ayọkẹlẹ",
    education: "Ẹkọ",
    beautyAndSpa: "Ẹwa ati Spa",
    staycation: "Iduro",
    events: "Awọn iṣẹlẹ",
    trips: "Awọn irin ajo",
    oilAndGas: "Epo ati Gaasi",
    laundry: "Ifọṣọ",
    partnerCategory: "Ẹka Alabaṣepọ",
    freeItem: "Nkan Ọfẹ",
  },
  Zulu: {
    cashier: "okheshi",
    manager: "umphathi",
    owner: "umnikazi",
    online: "online",
    offline: "ungaxhunywanga",
    changePassword: "SHINTSHA iphasiwedi",
    currentPasswordMessage: "Sicela ufake iphasiwedi yakho yamanje",
    passwordMessage: "Sicela ufake iphasiwedi yakho",
    currentPassword: "I-password Yamanje",
    password: "iphasiwedi",
    confirmPasswordMessage: "Sicela uqinisekise iphasiwedi yakho!",
    confirmPassword: "Qinisekisa Iphasiwedi",
    sendViaEmail: "Thumela nge-imeyili",
    sendViaWhatsapp: "Thumela nge-WhatsApp",
    downloadPdf: "Landa i-PDF",
    paid: "ikhokhwe",
    unpaid: "okungakhokhelwa",
    partial: "ngokwengxenye",
    closeInvoice: "Ngabe ufuna ukuvala i-invoyisi?",
    closeInvoiceConfirmation:
      "I-invoyisi kungenzeka ingalondolozwa. Ufuna ukuvala?",
    yes: "yebo",
    no: "cha",
    invoice: "Irisidi",
    wasDeducted: "kudonswe imali",
    for: "ngoba",
    item: "Into",
    addProduct: "Faka Umkhiqizo",
    paymentReference: "Inkomba Yokukhokha",
    amountPaid: "Inani Elikhokhelwe",
    discountAmount: "Inani lesaphulelo",
    amountDue: "Inani elifunekayo",
    amount: "Inani",
    dueDate: "Usuku olumisiwe",
    paymentType: "Uhlobo lokukhokha",
    card: "Ikhadi",
    cash: "Imali",
    bankTransfer: "Ukudluliswa Kwebhange",
    paymentMessage: "Umlayezo Wenkokhelo",
    description: "Incazelo",
    sendReceipt: "Thumela Irisidi",
    delete: "Susa",
    save: "Gcina",
    resend: "Thumela kabusha",
    saveAndSend: "Gcina",
    invoiceSaved: "Irisidi ilondoloziwe!",
    selectPaymentMethod: "Sicela ukhethe indlela yokukhokha!",
    selectCustomer: "Sicela ukhethe iKhasimende!",
    cartEmptyError:
      "Uhlu Lwezinqola alunakuba nalutho, vala i-invoyisi bese ufaka Into ekalishini!",
    subscriptionExpired:
      "Ukubhalisa kwakho kuphelelwe yisikhathi futhi i-akhawunti yakho manje isikhawuliwe. Chofoza inkinobho engezansi ukuvuselela i-akhawunti yakho",
    renew: "Vuselela",
    holdOn: "Bamba kancane",
    customerBank: "Ikhasimende Lebhange",
    cancel: "Khansela",
    selectACustomer: "Khetha iKhasimende",
    invoiceSuccessfulPdfError:
      "I-invoyisi idalwe ngempumelelo kepha i-PDF Generation ithatha isikhathi eside kunokujwayelekile. Sicela uphinde uhlole maduzane.",
    downloadingInvoice: "Ilanda i-invoyisi",
    downloadingReceipt: "Ilanda Irisidi",
    whatsappReceiptError:
      "Kuvele iphutha ekuthumeleni irisidi nge-WhatsApp, Sicela uzame futhi.",
    receiptToWhatsapp: "Irisidi lidluliselwe ku-WhatsApp",
    thankYouForPatronage: "Siyabonga ngosizo lwakho",
    hereIsYourReceipt: "Nayi irisidi lakho lokukhokha",
    errorSendingEmailReceipt:
      "Kuvele iphutha ekuthumeleni irisidi nge-imeyili, sicela uzame futhi noma uthinte abosizo",
    receiptSentToEmail: "Irisidi ithunyelwe ku-imeyili yekhasimende",
    invoiceSentToEmail: "Irisidi ithunyelwe ku-imeyili yekhasimende",
    invoiceSuccessWhatsappError:
      "I-invoyisi idalwe ngempumelelo kepha kube nephutha lokuthumela ku-WhatsApp. Zama futhi ohlwini lwama-invoyisi",
    invoiceSuccessfulEmailError:
      "I-invoyisi idalwe ngempumelelo kepha kuvele iphutha ngenkathi kuthunyelwa i-imeyili. Sicela uzame futhi ohlwini lwama-invoyisi",
    invoiceSentToWhatsapp: "I-invoyisi idluliselwe ku-WhatsApp",
    hello: "Sawubona",
    pleaseDownloadInvoice: "Sicela ulande i-invoyisi",
    pleaseDownloadReceipt: "Sicela ulande irisidi",
    from: "kusuka",
    email: "Imeyili",
    upgrade: "Thuthukisa",
    youAreOnFreePlan: "UkusoHlelo Lamahhala.",
    clickOn: "Chofoza ku-",
    yourPlanInFewSteps: " okubhalisile ngezinyathelo ezimbalwa ezisheshayo.",
    to: "uku",
    yourSubscriptionHasExpired:
      "Ukubhalisa kwakho kuphelelwe yisikhathi futhi i-akhawunti yakho manje isikhawuliwe.",
    days: "izinsuku",
    yourSubscriptionExpiresIn:
      "Okubhalisile kwe-Loystar kuphelelwa isikhathi ngo-",
    createAcount: "Yenza i-akhawunti",
    signIn: "Ngena ngemvume",
    continue: "Qhubeka",
    enterOtp: "Faka i-OTP PIN",
    enterValidOtp: "Sicela ufake i-PIN evumelekile",
    pin: "I-PIN",
    tokenSentToMail: "Kuthunyelwe ithokheni ku-imeyili yakho",
    passwordResetSuccessful: "Ukusetha kabusha iphasiwedi kube yimpumelelo",
    somethingWentWrong: "Kukhona into engahambanga kahle!",
    resetPassword: "Setha kabusha iphasiwedi",
    iHaveResetCode: "Nginekhodi yokusetha kabusha iphasiwedi",
    pleaseEnterEmail: "Sicela ufake i-imeyili yakho",
    aTokenWillBeSentToEmail: "Ithokheni izothunyelwa ku-imeyili yakho",
    enterEmail: "Faka i-imeyili yakho",
    sendinYourToken: "Ithumela ithokheni yakho ...",
    makeSureItMatchesPassword: "Qiniseka ukuthi ifana nephasiwedi yakho entsha",
    pleaseChooseNewPassword: "Sicela ukhethe iphasiwedi entsha",
    chooseNewPassword: "Khetha iphasiwedi entsha",
    enterNewPassword: "Faka iphasiwedi yakho entsha ukuqinisekisa",
    enterTokenSent: "Faka ithokheni ethunyelwe imeyili yakho",
    resetToken: "Setha kabusha ithokheni",
    resettingPassword: "Isetha kabusha iphasiwedi yakho ...",
    signUp: "Bhalisela",
    adminSignInWithEmail:
      " Abaphathi bangena nge-imeyili ngenkathi Abasebenzi bengena ngegama lomsebenzisi.",
    pleaseEnterEmailOrUsername:
      "Sicela ufake i-imeyili yakho noma igama lomsebenzisi",
    emailOrUsername: "Imeyili noma Igama lomsebenzisi",
    pleaseEnterPassword: "Sicela ufake iphasiwedi",
    createAnAccount: "Yenza i-akhawunti",
    forgotPassword: "Ukhohlwe iphasiwedi?",
    enterPhoneNumber: "Faka inombolo yocingo",
    personalData: "Imininingwane yomuntu",
    validateConfirmationCOde: "Qinisekisa ikhodi yokuqinisekisa",
    pleaseEnterFirstName: "Sicela ufake igama lakho lokuqala",
    pleaseEnterPhoneNumber: "Sicela ufake inombolo yakho yocingo",
    pleaseEnterLastName: "Sicela ufake isibongo sakho",
    pleaseEnterBusinessName: "Sicela ufake igama lebhizinisi lakho",
    firstName: "Igama",
    lastName: "Isibongo",
    businessName: "Igama Lebhizinisi",
    previous: "Esedlule",
    next: "Olandelayo",
    pleaseSelectBusinessCategory: "Sicela ukhethe isigaba sebhizinisi lakho",
    pleaseEnterValidEmail: "Sicela ufake i-imeyili evumelekile",
    pleaseEnterPostCode: "Sicela ufake ikhodi yeposi",
    postCode: "Ikhodi leposi",
    phoneNumberInUse: "Le nombolo yocingo isivele isetshenziswa!",
    emailInUse: "Le imeyili isivele isetshenziswa!",
    foodAndDrinks: "Ukudla neziphuzo",
    salonAndBeauty: "Isaluni noBuhle",
    fashionAndAccessories: "Imfashini nezesekeli",
    gymAndFitness: "Ijimu nokuqina komzimba",
    travelAndHotel: "Ukuvakasha neHhotela",
    homeAndGifts: "Ikhaya Nezipho",
    washingAndCleaning: "Ukuwasha nokuhlanza",
    gadgetsAndElectronics: "Amagajethi ne-Electronics",
    groceries: "Ukudla",
    others: "Abanye",
    submit: "Thumela",
    accountCreatedSuccessful: "I-akhawunti yakho yenziwe ngempumelelo.",
    pleaseEnterAddress: "Sicela ufake ikheli lakho",
    addressLine1: "Umugqa Wekheli 1",
    addressLine2: "Umugqa Wekheli 2",
    choosePassword: "Khetha Iphasiwedi",
    passwordMustBe6Characters:
      "Iphasiwedi kumele okungenani ibe nezinhlamvu eziyi-6.",
    howDidYouHearLoystar: "Uzwe kanjani ngeLoystar?",
    referralCode: "ikhodi yokudlulisela",
    byClickingTheButton: " Ngokuchofoza inkinobho engezansi, uyavumelana ne-",
    termsAndSevice: "Imigomo",
    wereCreatingAccount: "Sakha i-akhawunti yakho",
    proceed: "Qhubeka",
    verificationCodeMustBe6:
      "Ikhodi yokuqinisekisa kufanele ibe yizinombolo ezi-6",
    pleaseEnter6DigitCode: "Sicela ufake ikhodi enamadijithi angu-6",
    enterVerificationCode: "Faka ikhodi yokuqinisekisa",
    resendToken: "Phinda Uthumele Ithokheni",
    verify: "Qinisekisa",
    transactions: "Ukuthengiselana",
    todaySales: "Ukuthengisa kwanamuhla",
    salesHistory: "Umlando Wokuthengisa",
    supplyHistory: "Umlando Wokunikezela",
    new: "Kusha",
    invoices: "Ama-invoice",
    disbursements: "Izinkokhelo",
    offlineSales: "Ukuthengisa Okungaxhunyiwe ku-inthanethi",
    products: "Imikhiqizo",
    customers: "Amakhasimende",
    multiLevelLoyalty: "Ukwethembeka kwamazinga amaningi",
    loyaltyPrograms: "Izinhlelo Zokuthembeka",
    members: "Amalungu",
    appStore: "Isitolo Sohlelo Lokusebenza",
    orderMenu: "Hlela Imenyu",
    settings: "Amasethingi",
    staffAndBranches: "Abasebenzi namaGatsha",
    myAccount: "I-akhawunti yami",
    switchToSellMode: "Shintshela Kuthengise Imodi",
    signOut: "Phuma ngemvume",
    getFreeSubscription: "Thola Ukubhaliswa kwamahhala",
    onlyNumbersAllowed: "Izinombolo kuphela ezivunyelwe",
    yourAccountMustBe10Digits:
      "inombolo yakho ye-akhawunti kumele ibe yizinombolo eziyi-10",
    yourBvnMustBe11: "i-BVN yakho kufanele ibe yinombolo enamadijithi ayi-11",
    pleaseSelectBank: "Sicela ukhethe ibhange lakho",
    selectYourBank: "Khetha iBhange lakho",
    enterBankAccountNumber: "Faka Inombolo Ye-akhawunti Yasebhange",
    enterBvnNumber: "Faka i-BVN yakho",
    connectBankAccount: "Xhuma i-Akhawunti Yasebhange",
    passwordResetSuccessfulAndSignOut:
      "Iphasiwedi yakho isethwe kabusha ngempumelelo. Chofoza kunkinobho yokuphuma ngemvume ngezansi ukuze ungene ngemvume futhi",
    enterCurrentPassword: "Faka iphasiwedi yamanje",
    pleaseEnterCurrentPassword: "Sicela ufake iphasiwedi yamanje",
    phoneNumber: "Inombolo yocingo",
    sex: "Ucansi",
    dateOfBirth: "Usuku lokuzalwa",
    state: "Izwe",
    country: "Izwe",
    loyaltyId: "Ukwethembeka ID",
    createdAt: "Idalwe ngo-",
    noOfTransactionsMade: "Cha yokwenziwe okwenziwe",
    yourDownloadWillStart: "Ukulanda kwakho kuzoqala isikhashana",
    exportCustomers: "Thekelisa Amakhasimende",
    youhaveSuccessfullyToppedUp: "Ubeke ngempumelelo amayunithi wakho we-SMS.",
    youNowHave: "Manje usunayo",
    smsUnits: "Amayunithi we-SMS",
    enterNumberOfSmsUnits:
      "Faka Inombolo Yezinyunithi Zama-SMS Ohlose Ukuthenga",
    pleaseEnterANumericValue: "Sicela ufake inani lenombolo",
    pay: "Khokha",
    accountEditedSuccessfully: "I-akhawunti yakho ihlelwe ngempumelelo.",
    youAeCurrentlyOn: "Manje uvule",
    plan: "Hlela",
    userData: "Idatha yomsebenzisi",
    businessData: "IMINININGWANE YEBHIZINISI",
    businessCategory: "ICatergory Yezebhizinisi",
    pleaseSelectCurrency: "Sicela ukhethe imali yakho",
    selectYourCurrency: "Khetha imali yakho",
    purchaseMoreSmsUnits:
      "Thenga amayunithi amaningi we-SMS usebenzisa ifomu elingezansi",
    youHave: "Unayo",
    atLeast4SmsUnitsRrequired:
      "Kudingeka okungenani ama-sms amayunithi ama-4 ukuze kuqinisekiswe, sicela ufake phezulu!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Sicela uqinisekise i-akhawunti yakho yasebhange ukuthobela izidingo ze-'Yazi Ikhasimende Lakho '(KYC). Lokhu kuzokuvumela ukuthi uqoqe izinkokhelo nge-USSD noma udlulise ngokushesha, uthole ama-oda avela kumakhasimende futhi usebenze ngokuthengiselana kokuthengiselana. Kusebenza kubathengisi baseNigeria kuphela. Sicela ucindezele inkinobho engezansi ukuqala.",
    reConnectBankAccount: "Phinda uxhume i-Akhawunti Yasebhange",
    accountName: "Igama le-akhwunti",
    accountNumber: "Inombolo Ye-akhawunti",
    bankName: "Igama Lebhange",
    verified: "Kuqinisekisiwe",
    accountDetails: "Imininingwane ye-Akhawunti",
    kycBankAccount: "I-KYC",
    createTier: "Dala isigaba",
    fileUploadSuccessful: "ifayela lilayishwe ngempumelelo",
    fileUploadFailed: "ukulayishwa kwefayela kwehlulekile",
    createLoyaltyProgram: "Dala Uhlelo Lokuthembeka",
    createLoyalty: "Dala Ubuqotho",
    name: "Igama",
    loyaltyArtwork: "Ukwethembeka Umsebenzi wobuciko",
    clickToUpload: "Chofoza ukuze ulayishe",
    amountToPointsRatio: "Inani Lokulinganisa Amaphuzu",
    points: "Amaphuzu",
    recommendedAmountToPointRatio:
      "Kunconyiwe: ₦ 1 to 1point. i.e. kuyo yonke i-Naira esetshenzisiwe, amakhasimende akho athola iphuzu elingu-1",
    pleaseTypeIn: "Sicela uthayiphe",
    toDeleteLoyalty: "ukususa lobu buqotho",
    deleteLoyalty: "Susa ubuqotho",
    toConfirm: "ukuqinisekisa",
    edit: "Hlela",
    pointsAwardedSuccessfully: "Amaphuzu Aklonyeliswe ngempumelelo.",
    enterPointValueToAward:
      "Faka Inani Lamaphoyinti Ongathanda Ukunikezela Amakhasimende",
    award: "Umklomelo",
    awardPointValuesToCustomer: "Inani Lenani Lomklomelo Wekhasimende",
    enrollMembersToLoyalty: "Bhalisa Amalungu ku Ukwethembeka",
    awardPoints: "Amaphuzu Omklomelo",
    enroll: "Bhalisa",
    home: "Ikhaya",
    loyalty: "Ukwethembeka",
    enrollCustomers: "Bhalisa Amakhasimende",
    selected: "Kukhethiwe",
    customer: "Ikhasimende",
    loyaltyActivationSuccessful: "Ukwenza ubuqotho kuphumelele.",
    loyaltyDeactivationSuccessful:
      "Ukwenza kungasebenzi ukuthembeka kuphumelele.",
    viewTier: "Buka isigaba",
    deactivate: "Susa ukusebenza",
    activate: "Yenza kusebenze",
    actions: "Izenzo",
    nameOfLoyalty: "Igama Lokwethembeka",
    loyaltyStatus: "Isimo Sokwethembeka Isimo Sokwethembeka",
    numberOfTiers: "Inani lamaTire",
    createdOn: "Kudalwe kwavulwa",
    createATier: "Dala isigaba",
    stopCreatingTierConfirmation: "Ingabe ufuna ukumisa ukudala i-tier?",
    stopEditingTierConfirmation: "Ingabe ufuna ukumisa ukuhlela lesi sigaba?",
    nameOfTier: "Igama leTier",
    minimumSpendingTarget: "Okuqondiwe kokuchitha okuncane",
    maximumSpendingTarget: "Ithagethi enkulu yokusebenzisa imali",
    minimumSpendingTargetRequired:
      "inani eliphansi lokusetshenziswa kwemali liyadingeka",
    maximumSpendingTargetRequired:
      "inani eliphakeme lokusetshenziswa kwemali liyadingeka",
    rewardSponsor: "Umxhasi Womklomelo",
    pleaseChooseARewardSponsor: "Sicela ukhethe umxhasi womklomelo",
    self: "Ukuzimela",
    partner: "Uzakwethu",
    rewardPartner: "Umlingani womvuzo",
    pleaseSelectRewardPartner: "Sicela ukhethe umlingani wakho womvuzo",
    rewards: "Imiklomelo",
    pleaseSelectAReward: "Sicela ukhethe umklomelo",
    instructionsOnRedeemingReward:
      "Imiyalo yokuthi Ikhasimende Kufanele Liwusebenzise Kanjani Umvuzo",
    pleaseFillInThisField: "Sicela ugcwalise kule nsimu!",
    toDeleteThisTier: " ukususa lesi sigaba",
    deleteTier: "Susa isigaba",
    viewMembers: "Buka Amalungu",
    membersEnrolled: "Amalungu Abhalisiwe",
    instruction: "Imiyalo",
    membersIn: "Amalungu ku-",
    availableTiersInLoyalty: "Izilinganiso ezitholakalayo kuHlelo Lokuthembeka",
    tiers: "Izigaba",
    totalTier: "ISIKHATHI ESIPHELELE",
    availableLoyaltyProgramme: "Uhlelo Lokuthembeka Olutholakalayo",
    totalLoyalties: "UBUQOTHO BONKE",
    memberDetails: "Imininingwane Yelungu",
    nameOfCustomer: "Igama leKhasimende",
    address: "Ikheli",
    allEnrolledMembers: "Wonke Amalungu Abhalisile",
    thisIsToWishYouHappyBirthday:
      "Lokhu kwenzelwa ukukufisela usuku oluhle lokuzalwa nempilo enempumelelo. Siyabonga ngakho konke oyikho kuLoyster. Imikhosi Ejabulisayo!",
    inputAnOfferPlease: "Sicela ufake okokufaka",
    pleaseSelectTheInsertPoint:
      "Sicela ukhethe iphoyinti lokufaka kumyalezo bese uchofoza futhi",
    birthdayOfferAndMessage: "Usuku lokuzalwa Umyalezo",
    birthdayOffer: "Umnikelo wosuku lokuzalwa",
    birthdayMessage: "Umlayezo Wosuku Lokuzalwa",
    noOfferFound: "Akukho ukunikezwa okutholakele",
    settingABirthdayOffer:
      "Ukusetha ukunikezwa kosuku lokuzalwa kuvumela amakhasimende ukuthi athole lo mnikelo nge-SMS ngosuku lwawo lokuzalwa",
    createOffer: "Dala okunikezwayo",
    whatIsTheOffer: "Yini ukunikezwa?",
    editMessage: "Hlela Umlayezo",
    insert: "Faka",
    theNameOfCustomerInserted: "Igama lekhasimende lizofakwa lapha",
    theBirtdayOfferInserted: "Ukunikezwa kosuku lokuzalwa kuzofakwa lapha",
    youSuccessfullyAddedNewBranch: "Ungeze ngempumelelo igatsha elisha!",
    addNewBranch: "Faka Igatsha Elisha",
    addBranch: "Faka Igatsha",
    additionalBranchWillIncur: "Igatsha elengeziwe lizovela",
    perBranch: "ngegatsha ngalinye",
    ecommerceBranchCosts: "Izindleko ze-Ecommerce Branch",
    pleaseEnterBranchName: "Sicela ufake igama legatsha",
    pleaseEnterBranchAddress1: "Sicela ufake ulayini wegatsha 1",
    enterBranchAddress1: "Faka ulayini wekheli wegatsha 1",
    enterBranchAddress2: "Faka ulayini wekheli wegatsha 1",
    pleaseEnterBranchAddress2: "Sicela ufake ulayini wegatsha 2",
    enterBranchName: "Faka igama legatsha",
    successfullyAddedStaff: "Ungeze ngempumelelo abasebenzi abasha!",
    addNewStaff: "Faka Abasebenzi Abasha",
    addStaff: "Faka Abasebenzi",
    additionalStaffWillIncur: "Abasebenzi abengeziwe bazovela",
    perStaff: "abasebenzi ngamunye.",
    pleaseEnterStaffEmail: "Sicela ufake i-imeyili yabasebenzi",
    pleaseEnterStaffUsername: "Sicela ufake igama lomsebenzisi labasebenzi",
    pleaseEnterStaffPassword: "Sicela ufake iphasiwedi yabasebenzi",
    pleaseSelectStaffRole: "Sicela ukhethe indima yabasebenzi",
    selectStaffRole: "Khetha indima yabasebenzi",
    enterStaffEmail: "Faka i-imeyili yabasebenzi",
    enterStaffUsername: "Faka igama lomsebenzisi labasebenzi",
    enterStaffPassword: "Faka iphasiwedi yabasebenzi",
    spacesNotAllowedInUsername: "izikhala azivunyelwe egameni lomsebenzisi",
    admin: "Ukuphatha",
    pleaseSelectBusinessToAttachStaff:
      "Sicela ukhethe ibhizinisi ukuze unamathisele abasebenzi",
    searchForBranchToAttachStaff: "Sesha igatsha ukuze unamathisele abasebenzi",
    username: "Igama lomsebenzisi",
    role: "Indima",
    areYouSureToDeleteThis: "Uqinisekile ukususa lokhu",
    branches: "Amagatsha",
    upgradeYourPlan: "Thuthukisa uhlelo lwakho.",
    add: "ENGEZA",
    addNew: "Faka okusha",
    customerWithEmailAlreadyExists:
      "Ikhasimende eline-imeyili / inombolo yefoni selivele likhona!",
    successfullyAddedNewCustomer: "Ungeze ngempumelelo ikhasimende elisha!",
    addCustomer: "Faka iKhasimende",
    pleaseEnterCustomerFirstName: "Sicela ufake igama lekhasimende lokuqala",
    pleaseEnterCustomerLastName: "Sicela ufake isibongo sekhasimende",
    pleaseEnterCustomerPhoneNumber: "Sicela ufake inombolo yefoni yekhasimende",
    pleaseEnterCustomerEmail: "Sicela ufake i-imeyili yekhasimende",
    pleaseEnterCustomerAddressLine: "Sicela ufake umugqa wekheli lekhasimende",
    pleaseEnterCustomerOtherAddress: "Sicela ufake elinye ikheli lekhasimende",
    pleaseSelectCustomerGender: "Sicela ukhethe ubulili bekhasimende",
    gender: "Ubulili",
    male: "Owesilisa",
    female: "Owesifazane",
    bank: "Ibhange",
    selectBank: "Khetha iBhange",
    stateOrRegionOrProvince: "Izwe / Isifunda / Isifundazwe",
    customerNotes: "Amanothi weKhasimende",
    sendSms: "Thumela i-SMS",
    editCustomer: "Hlela iKhasimende",
    redeemReward: "Sebenzisa uMvuzo",
    issueLoyaltyCard: "Khipha ikhadi lokuthembeka",
    deleteCustomer: "Susa iKhasimende",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Unikeze ngempumelelo i-ID yobulungu bokuthembeka",
    noMembershipIdAvailable:
      "Awekho ama-ID wobulungu atholakalayo. Sicela uxhumane nathi ku-hello@loystar.co",
    sendEmail: "Thumela i-imeyili",
    membershipPoints: "Amaphuzu Amalungu",
    customerDetails: "Imininingwane yekhasimende",
    close: "Vala",
    loyaltyBalance: "Ibhalansi Yokuthembeka",
    pointsBalance: "Amaphuzu Ibhalansi",
    starBalance: "I-Star Balance",
    requiredPoints: "Amaphuzu adingekayo",
    requiredStars: "Izinkanyezi ezidingekayo",
    reddemCode: "Sebenzisa ikhodi",
    toDeleteThisCustomer: "ukususa leli khasimende",
    customerHasBeenDeletedSuccessfully: "Ikhasimende lisuswe ngempumelelo!",
    customerWithPhoneAlreadyExists:
      "Ikhasimende elinenombolo yocingo selivele likhona",
    customerWasSuccessfullyEdited: "Ikhasimende lihlelwe ngempumelelo",
    anErrorOccured: "Kuvele iphutha",
    phoneNumberIncludeCountry: "Inombolo Yocingo (Faka neKhodi Yezwe)",
    yourFileQueuedForUpload:
      "Ifayela lakho lifakwe kulayini ukuze lilayishwe. Sicela uvuselele ikhasi ngemuva kwemizuzwana embalwa.",
    thereWasAnErrorPerformingOperation: "Kube nephutha ekwenzeni umsebenzi!",
    pleaseSelectFile: "Sicela ukhethe Ifayela!",
    oopsOnlyCsvFilesAllowed:
      "Eshu! Amafayela we-CSV kuphela avunyelwe. Sicela ulayishe ifayela le-.csv.",
    fileShouldBeSmallerThan5Mb:
      "Ifayela kufanele libe lincane kuno-5MB. Uma unefayela elikhudlwana, sicela uthumele i-imeyili support@loystar.co. Ngiyabonga",
    customerFirstNameIsRequired: "Igama lekhasimende liyadingeka kulayini",
    customerPhoneNumberIsRequired:
      "inombolo yocingo yekhasimende iyadingeka kulayini",
    importCustomers: "Ngenisa Amakhasimende",
    upload: "Layisha",
    clickIconToDownloadCsv:
      "Chofoza lesi sithonjana ukulanda isifanekiso sefayela le-CSV.",
    getGoogleSheetVersion: "Thola inguqulo yeshidi le-Google",
    clickOrDragFileToUpload:
      "Chofoza noma uhudule ifayela kule ndawo ukuze ulayishe",
    missingOrInvalidColumnHeader:
      "Unhlokweni wekholomu olahlekile noma ongavumelekile. Sicela ulandele ifomethi yesifanekiso. Ngiyabonga.",
    youHaveImported: "Ungenisile",
    youSuccessfullyRedeemedReward: "Uwutholile ngempumelelo umklomelo wakho!",
    sixDigitCode: "Ikhodi enamadijithi ayisithupha",
    pleaseEnterCustomerRewardCode: "Sicela ufake ikhodi yomvuzo yekhasimende",
    enterRewardCode: "Faka ikhodi yomvuzo. (Ikhodi yomklomelo izwela kakhulu)",
    selectLoyaltyProgram: "Khetha uhlelo lokwethembeka",
    stamps: "Izitembu",
    smsUnitsLow: "Amayunithi we-SMS aphansi",
    pleaseTopUpSmsUnits: "Sicela wenze amayunithi aphezulu we-SMS",
    smsSuccessfullySentForDelivery: "I-SMS ithunyelwe ngempumelelo ukulethwa!",
    sendSmsTo: "Thumela i-SMS ku",
    allCustomers: "Wonke Amakhasimende",
    unitsAvailable: "Amayunithi Ayatholakala",
    pleaseTypeInTheMessage: "Sicela uthayiphe umyalezo",
    message: "Umlayezo",
    charactersRemaining: "izinhlamvu ezisele",
    avoidUseOfSpecialCharacters:
      "Gwema ukusetshenziswa kwezinhlamvu ezikhethekile nama-emoji ukonga amayunithi we-SMS.",
    note: "Qaphela",
    errorFetchingCustomersMultilevelDetail:
      "Iphutha Lokulanda Amakhasimende Imininingwane Ye-MultiLevel",
    search: "Sesha",
    reset: "Setha kabusha",
    importCustomer: "Ngenisa iKhasimende",
    addNewCustomer: "Faka iKhasimende elisha",
    sendSmsBroadcast: "Thumela i-SMS Broadcast",
    totalCustomers: "AMAKHASIMENDE WONKE",
    disbursementDetails: "Imininingwane Yesikhokhelo",
    paidBy: "Kukhokhelwe Ngu",
    disbursed: "Kukhishwe",
    bankAccountName: "Igama le-Akhawunti Yasebhange",
    bankAccountNumber: "Inombolo ye-Akhawunti Yasebhange",
    transferInitiated: "Ukudlulisa Kuqalisiwe",
    transferCompleted: "Ukudlulisa kuqediwe",
    pleaseEnterAValid: "Sicela ufake evumelekile",
    begin: "qala",
    end: "ukuphela",
    date: "usuku",
    paymentDate: "Usuku lokukhokha",
    selectDisbursementDuration: "Khetha Isikhathi Senkokhelo",
    totalSettled: "Ingqikithi Isimisiwe",
    totalUnsettled: "Ingqikithi Engazinzile",
    toDeleteThisSale: "ukususa lokhu kuthengisa",
    draftSaleDeletedSuccessfully:
      "Okuthengiswayo okusalungiswa kususwe ngempumelelo!",
    deleteSale: "Susa ukuthengisa",
    pleaseTypeInYourTotal:
      "Sicela uthayiphe inani lakho ukuqinisekisa ukususwa",
    billDetails: "Imininingwane Yomthethosivivinywa",
    printBill: "Phrinta uBill",
    servedBy: "Kusetshenzwe ngu",
    total: "Ingqikithi",
    createdDate: "Idalwe Usuku",
    createdTime: "Isikhathi Esidaliwe",
    status: "Isimo",
    loginSuccessful: "Ukungena ngemvume Kuphumelele",
    pleaseWaitWhileWeConnectAccount:
      "Sicela ulinde ngenkathi sixhuma i-akhawunti yakho",
    connectionFailedTryAgain: "Ukuxhumeka kwehlulekile. Ngicela uzame futhi.",
    connectionSuccessful: "Ukuxhumeka Kuphumelele",
    viewDetails: "Buka Imininingwane",
    enable: "Nika amandla",
    free: "Mahhala",
    cost: "Izindleko",
    visitWebsite: "Vakashela Iwebhusayithi",
    pleaseUpgradeYourPlanToPro:
      "Sicela uthuthukise uhlelo lwakho ku-Pro Plus ukuze unike amandla lolu hlelo lokusebenza",
    connectYourBankAccount:
      "Xhuma i-Akhawunti Yakho Yasebhange ukuze ukwazi ukuthola izinkokhelo.",
    disable: "Khubaza",
    enableApp: "Nika amandla uhlelo lokusebenza",
    addNewProductToInvoice: "Faka Umkhiqizo Omusha ku-Invoyisi",
    toDeleteThisInvoice: "ukususa le Invoyisi",
    invoiceDeletedSuccessfully: "Irisidi isuswe ngempumelelo!",
    deleteInvoice: "Susa irisidi",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Ungeze ngempumelelo umlayezo wokukhokha we-Invoyisi!",
    pleaseEnterPaymentInstructions: "Sicela ufake imiyalo yokukhokha",
    invoiceId: "I-ID ye-invoyisi",
    paidDate: "Usuku olukhokhelwe",
    reference: "Inkomba",
    productAdded: "Umkhiqizo ungeziwe",
    productOutOfStock: "Umkhiqizo uphelile. Sicela ufake amasheya kabusha.",
    totalInvoices: "IMVUME YONKE",
    totalPaidInvoices: "IZIMVOBO ZOKUKHOKHELWA ZONKE",
    totalUnpaidInvoices: "IZIMVOBO EZIKHOKHELWE ZONKE",
    loyaltyProgramDeleted: "Uhlelo Lokuthembeka Lisusiwe",
    thisLoyaltyProgramDeletedSuccessfully:
      "Lolu hlelo lokwethembeka lisuswe ngempumelelo",
    thisLoyaltyProgramEditedSuccessfully:
      "Lolu hlelo lokuthembeka luhlelwe ngempumelelo",
    loyaltyProgramAddedSuccessfully:
      "Uhlelo lokuthembeka lungezwe ngempumelelo",
    loyaltyProgramEdited: "Uhlelo Lokuthembeka Luhleliwe",
    loyaltyProgramAdded: "Uhlelo Lokuthembeka Lungeziwe",
    loyaltyDetails: "Imininingwane Yokuthembeka",
    doYouWantToCloseDialog: "Ufuna ukuvala le ngxoxo?",
    pleaseEnterLoyaltyName: "Sicela ufake igama lobuqotho bakho",
    programType: "Uhlobo lohlelo",
    pleaseSelectProgramType: "Sicela ukhethe uhlobo lohlelo",
    simplePoints: "Amaphuzu Alula",
    stampsProgram: "Uhlelo lwezitembu",
    threshold: "Umkhawulo",
    pleaseEnterLoyaltyThreshold: "Sicela ufake umkhawulo wokwethembeka",
    reward: "Umvuzo",
    pleaseEnterLoyaltyReward: "Sicela ufake umvuzo wobuqotho",
    totalUserPoints: "Inani lamaphoyinti omsebenzisi",
    totalUserStamps: "Izitembu zomsebenzisi eziphelele",
    spendingTarget: "Inkomba yokusebenzisa imali",
    spendingTargetHint1:
      "Ukuchitha imali yilokho ikhasimende okufanele lisebenzise malini ukuthola umklomelo. Inani lemali eli-1 = 1 iphuzu.",
    spendingTargetHint2:
      "Izitembu ezihlosiwe ukuthi zingaki izitembu ikhasimende okufanele liziqoqe ukuthola umvuzo. isib. 5",
    addNewLoyaltyProgram: "Faka uhlelo olusha lokuthembeka",
    addLoyaltyProgram: "Faka uhlelo lokuthembeka",
    loyaltyProgramType: "Uhlobo lohlelo lokuthembeka",
    pleaseSelectLoyaltyProgramType:
      "Sicela ukhethe uhlobo lohlelo lokuthembeka",
    nameOfProgram: "Igama lohlelo",
    pleaseEnterProgramName: "Sicela ufake igama lohlelo",
    whatIsTheReward: "Uyini umvuzo?",
    egNextPurchaseIsFree: "Isb. Ukuthenga okulandelayo kumahhala",
    customerName: "Igama leKhasimende",
    guestCustomer: "Ikhasimende Lesihambeli",
    orderConfirmedSuccessfully: "I-oda liqinisekiswe ngempumelelo",
    orderCancelledSuccessfully: "I-oda likhanselwe ngempumelelo",
    confirmOrder: "Qinisekisa ukuhleleka",
    cancelOrder: "Khansela i-oda",
    allOrders: "Onke ama-oda",
    totalOrders: "IMIQOBO EYONKE",
    doYouWantToAcceptOrder: "Uyafuna ukwamukela le oda?",
    doYouWantToCancelOrder: "Ingabe ufuna ukukhansela le oda?",
    orderDetails: "Imininingwane Ye-oda",
    orderCreatedAt: "I-oda lenziwe ngo-",
    supplier: "Umhlinzeki",
    productName: "Igama lomkhiqizo",
    quantity: "Ubuningi",
    unitPrice: "Intengo yokukodwa",
    receivedBy: "Kutholwe Ngu",
    reportFrom: "Bika kusuka ku-",
    totalSupplies: "Izimpahla Eziphelele",
    allRightsReserved: "Wonke Amalungelo Agodliwe",
    toDeleteThisTransaction: "ukususa lo msebenzi",
    transactionDeletedSuccessfully:
      "Okwenziwayo kususwe ngempumelelo. Isitoko sibuyiselwe ku-inventory.",
    deleteTransaction: "Susa Okwenziwayo",
    transactionDetails: "Imininingwane Yokuthengiselana",
    printReceipt: "Ukuphrinta Irisidi",
    channel: "Isiteshi",
    discount: "Isaphulelo",
    profit: "Inzuzo",
    discountedSales: "Ukuthengiswa okunesaphulelo",
    errorFetchingRecord: "Iphutha lokulanda irekhodi",
    exportTransactions: "Export Ukuthengiselana",
    errorFetchingSalesRecord:
      "Iphutha ekulandeni irekhodi lokuthengisa lokuthekelisa.",
    totalSellingPrice: "Inani Lokuthengisa Lonke",
    totalCostPrice: "Intengo Yonke Yezindleko",
    appliedDiscount: "Isaphulelo Esetshenzisiwe",
    noOfItems: "Inani lezinto",
    sales: "Ukuthengisa",
    export: "Thumela",
    totalProfit: "Inani Lenzuzo",
    totalBalanceInPeriod: "Ingqikithi Yokulinganisela Esikhathini",
    allTimeSales: "Konke Ukuthengiswa Kwesikhathi",
    records: "Amarekhodi",
    todaysSales: "Ukuthengisa kwanamuhla",
    transaction: "okwenziwayo",
    youHaveExceededTotalNumberOfProducts:
      "Weqe inani eliphelele lemikhiqizo evunyelwe kuhlelo lwakho. Thuthukisa uhlelo lwakho ukuze ujabulele umkhawulo ophakeme.",
    youNeedToHaveLoyaltyProgram:
      "Udinga ukuba noHlelo Lokuthembeka ukuze usebenzise lesi sici!",
    price: "Intengo",
    category: "Isigaba",
    stockTracking: "Ukulandelwa Kwesitoko",
    stockCount: "Ukubalwa Kwesitoko",
    taxed: "Intela",
    originalPrice: "Intengo yoqobo",
    costPrice: "Intengo Yezindleko",
    unit: "Iyunithi",
    productImage: "Isithombe Somkhiqizo",
    taxRate: "Isilinganiso Sentela",
    taxType: "Uhlobo lwentela",
    trackInventory: "Landelela Inventory",
    variants: "Okuhlukile",
    hasVariants: "Inezinhlobonhlobo",
    importProduct: "Ngenisa Umkhiqizo",
    exportProducts: "Export Imikhiqizo",
    addNewProduct: "Faka Umkhiqizo Omusha",
    viewCategory: "Buka isigaba",
    viewSuppliers: "Buka abahlinzeki",
    receiveInventory: "Thola yokusungula",
    printAllProductsTag: "Phrinta Yonke Imikhiqizo Tag",
    deleteAll: "Susa Konke",
    totalProducts: "IMIKHIQIZO YONKE",
    youveSuccessfullyAddedANewCategory: "Ungeze ngempumelelo isigaba esisha",
    addNewCategory: "Faka isigaba esisha",
    addCategory: "Faka isigaba",
    categoryName: "Igama Lesigaba",
    pleaseEnterCategoryName: "Sicela ufake igama lesigaba",
    stampsTarget: "Izitembu Target",
    sendInventory: "Thumela Inventory",
    productDetails: "Imininingwane yomkhiqizo",
    youveSuccessfullyEditedThisCategory: "Uhlele ngempumelelo lesi sigaba",
    update: "Buyekeza",
    categoryList: "Uhlu Lwesigaba",
    categories: "Izigaba",
    enterQuantityToUpdate: "Faka ubuningi ukuze ubuyekeze",
    inventorySentSuccessfully: "Ukufakwa kuhlu kuthunyelwe ngempumelelo!",
    updateInventory: "Buyekeza Inventory",
    currentQuantity: "Ubuningi bamanje",
    pleaseEnterQuantityToAdd: "Sicela ufake inani ofuna ukulifaka",
    pleaseSelectABranch: "Sicela ukhethe Igatsha",
    searchForBranch: "Sesha igatsha",
    youCantSendMoreThanStock:
      "Awukwazi ukuthumela ngaphezu kwalokho onakho esitokweni",
    send: "Thumela",
    pleaseEnterQuantityToSend: "Sicela ufake inani ofuna ukulithumela",
    productNameIsRequiredOnRow: "igama lomkhiqizo liyadingeka kulayini",
    productCategoryIsRequiredOnRow: "isigaba somkhiqizo siyadingeka kulayini",
    productPriceIsRequiredOnRow: "Intengo yomkhiqizo iyadingeka kulayini",
    productUnitIsRequiredOnRow: "iyunithi yomkhiqizo iyadingeka kulayini",
    productQuantityIsRequiredOnRow: "inani lomkhiqizo liyadingeka kulayini",
    productVariantsRequireTracking:
      "okuhlukile komkhiqizo kudinga ukulandela ngomkhondo!",
    pleaseAddVariantClickButton:
      "Sicela ungeze okuhlukile ngokuchofoza inkinobho yokwengeza!",
    totalVariantsMoreThanSelectedQuantity:
      "Okuphelele okuphelele kungaphezu kwenani lomkhiqizo elikhethiwe, ama-Pls anciphisa ubuningi obuhlukile",
    productEditedSuccessfully: "Umkhiqizo uhlelwe ngempumelelo!",
    fileTooLargeLessThan4Mb:
      "Usayizi wefayela mkhulu kakhulu! Usayizi wefayela kufanele ube ngaphansi kuka-4MB.",
    suchVariantAlreadyExist: "Ukuhluka okunjalo sekuvele kukhona",
    addVariants: "Faka okwahlukile",
    editProduct: "Hlela umkhiqizo",
    pleaseEnterProductName: "Sicela ufake igama lomkhiqizo",
    pleaseEnterProductPrice: "Sicela ufake intengo yomkhiqizo",
    pleaseEnterProductOriginalPrice: "Sicela ufake inani loqobo lomkhiqizo",
    productDescription: "Incazelo Yomkhiqizo",
    selectProductCategory: "Khetha isigaba somkhiqizo",
    pleaseSelectProductCategory: "Sicela ukhethe isigaba somkhiqizo",
    productCostPrice: "Intengo Yezindleko Zomkhiqizo",
    productSellingPrice: "Intengo Yokuthengisa Yomkhiqizo",
    productOriginalPrice: "Intengo yoqobo yomkhiqizo",
    maxFileSizeAllowedIs4Mb: "UMax. Usayizi wefayela ovunyelwe yi-4mb",
    productsWithPicturesArePublished:
      "Imikhiqizo enezithombe ishicilelwa ku-Discover ukuthola ama-oda",
    shouldThisProductBeTracked: "Ngabe lo mkhiqizo kufanele ulandelelwe?",
    pleaseSelectStockUnit: "Sicela ukhethe iyunithi yesitoko",
    stockUnit: "Isitoko Sempahla",
    bag: "Isikhwama",
    bottle: "IBHODLELA",
    bunch: "I-BUNCH",
    can: "INGABE",
    carton: "IKHATHONI",
    crate: "IKHSITHI",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "IGIGABYTES",
    gram: "GRAM",
    kilogram: "IKILOGRAM",
    litre: "AMANCANE",
    pack: "PEKHA",
    pair: "BHANGQA",
    pieces: "IZINGXENYE",
    plate: "Ipuleti",
    tonne: "I-TONNE (MT)",
    uNIT: "UNIT",
    yard: "IGUZA",
    pleaseEnterProductQuantity: "Sicela ufake inani lomkhiqizo",
    productQuantity: "Ubuningi bomkhiqizo",
    isThisProductTaxed: "Ingabe lo mkhiqizo ukhokhiswa intela?",
    selectTaxType: "Khetha Uhlobo Lentela",
    pleaseSelectTaxType: "Sicela ukhethe Uhlobo lwentela",
    progressive: "Iyaqhubeka",
    proportional: "Ukulingana",
    pleaseEnterProductTaxRate: "Sicela ufake isilinganiso sentela yomkhiqizo",
    doesProductHaveVariants: "Ingabe lo mkhiqizo unokuhlukahluka?",
    type: "Thayipha",
    value: "Inani",
    pleaseEnterVariantType: "Sicela ufake uhlobo lokwahluka",
    pleaseEnterVariantValue: "Sicela ufake inani lokwahluka",
    pleaseEnterVariantPrice: "Sicela ufake inani lentengo",
    pleaseEnterVariantQuantity: "Sicela ufake inani lokwahluka",
    productDeletedSuccessfully: "Umkhiqizo ususwe ngempumelelo!",
    categoryDeletedSuccessfully: "Isigaba sisuswe ngempumelelo!",
    toDeleteThisProduct: "ukususa lo Mkhiqizo",
    invalidProductQuantity: "Inani lomkhiqizo elingavumelekile",
    quantityAddedIsOverStock:
      "Ubuningi obengezayo bungaphezu kwalokhu onakho esitokweni.",
    itemInventoryNotTracked: "Ukufakwa kwento akulandelelwa",
    addBulkQuantity: "Faka inani lobuningi",
    enterBulkQuantity: "Faka Ubuningi Bulk",
    pleaseEnterBulkQuantity: "Sicela ufake inani lobuningi",
    youveSuccessfullyAddedANewProduct: "Ungeze ngempumelelo umkhiqizo omusha!",
    pleaseEnterProductSellingPrice:
      "Sicela ufake intengo yokuthengisa yomkhiqizo",
    doYouWantToTrackProductStock:
      "Ngabe ufuna ukulandela umkhondo wesitoko somkhiqizo?",
    sellingPrice: "Intengo Yokuthengisa",
    setProductExpiryReminder: "Setha Isikhumbuzi Sokuphela Komkhiqizo",
    productExpiryDate: "Idethi yokuphelelwa yisikhathi komkhiqizo",
    startRemindingFrom: "Qala Ukukhumbuza Kusuka",
    productSuppliesAddedSuccessfully:
      "Ungeze izimpahla zomkhiqizo ngempumelelo.",
    addProductSupplies: "Faka Izimpahla Zomkhiqizo",
    pleaseSelectSupplier: "Sicela ukhethe umphakeli",
    nameOfProduct: "Igama Lomkhiqizo",
    pleaseSelectProduct: "Sicela ukhethe umkhiqizo",
    productVariant: "Ukwahluka Komkhiqizo",
    pleaseSelectAVariant: "Sicela ukhethe okuhlukile",
    pleaseEnterUnitPrice: "Sicela ufake i-Unit Price",
    businessBranch: "Igatsha Lebhizinisi",
    pleaseSelectBranch: "Sicela ukhethe igatsha",
    youveSuccessfullyAddedANewSupplier: "Ungeze ngempumelelo umphakeli omusha",
    addSupplier: "Faka Umhlinzeki",
    pleaseEnterSupplierEmail: "Sicela ufake i-imeyili yomphakeli",
    pleaseAddADescription: "Sicela ungeze incazelo",
    anErrorOccuredProductsDeleted:
      "Kuvele iphutha ngenkathi kwenziwa lo msebenzi. Uyacelwa ukuthi uqaphele, imbalwa imikhiqizo okungenzeka ukuthi isuswe ngenkathi kwenziwa inqubo",
    bulkDelete: "Susa ngobuningi",
    youAreAboutToDelete: "Usuzosusa",
    product: "Umkhiqizo",
    isDueToRedeem: "kufanele kuhlengiwe",
    aReward: "umvuzo",
    pleaseSelectCustomerToReeemReward:
      "Sicela ukhethe ikhasimende ukuze usebenzise umklomelo.",
    youHaveNoLoyaltyProgramRunning:
      "Awunalo uhlelo lokuthembeka olusebenzayo olusebenzayo",
    customerHhasNoPointsInLoyaltyProgram:
      "Ikhasimende alinawo amaphuzu kulolu hlelo lokuthembeka",
    proceedWithPayment: "Qhubeka ngokukhokha?",
    youAreAboutToPayForTransactionUsingPoints:
      "Usuzokhokhela okwenzayo usebenzisa amaphuzu.",
    customerHas: "Ikhasimende Linakho",
    worth: "kuwufanele",
    andIsNotSufficientToPayForTransaction:
      "futhi akwanele ukukhokhela lo msebenzi. Ngabe bafuna ukufaka ibhalansi besebenzisa enye indlela yokukhokha?",
    addCustomerLoyalty: "Faka Ukwethembeka Kwekhasimende",
    pleaseAddCustomerFirst: "Sicela ungeze noma ukhethe ikhasimende kuqala.",
    pleaseWaitWhileWeGetReady: "Sicela ulinde, ngenkathi silungiselela",
    lowStock: "Isitoko Esiphansi",
    pleaseEnterAmountTendered: "Sicela ufake inani lamathenda",
    areYouSureToBookSaleOffline:
      "Uqinisekile ukuthi ufuna ukubhukha lokhu kuthengisa ungaxhunyiwe ku-inthanethi?",
    saleWouldBeBookedAutomatically:
      "Ukuthengisa kungabhukwa ngokuzenzakalela lapho uvula i-inthanethi yakho",
    offlineSalesBookingCancelled:
      "Ukubhuka kokuthengisa okungaxhunyiwe ku-inthanethi kukhanseliwe",
    covid19Message:
      "COVID19: Geza izandla zakho ngensipho noma Sanitize ukunqanda ukusabalala. Hlala Uphephile Njalo",
    saleSuccessfullyRecorded: "Ukuthengiswa kuqoshwe ngempumelelo!",
    sendReceiptToEmail: "Thumela irisidi ku-imeyili",
    sendThankYouSms: "Thumela i-SMS yokubonga",
    sendShippingDetails: "Thumela imininingwane yokuThumela",
    addLoyalty: "Faka Ubuqotho",
    searchCustomerNameOrNumber: "Sesha igama lekhasimende noma inombolo",
    clickTheSearchCustomerBox:
      "Chofoza Ibhokisi Lokusesha Lekhasimende kanye neKhadi Lokuskena",
    enterProductPrice: "Faka Intengo Yomkhiqizo",
    enterPriceFor: "Faka Intengo ye-",
    searchForProduct: "Sesha umkhiqizo",
    all: "Konke",
    backToDashboard: "Buyela emuva kudeshibhodi",
    viewDraftSales: "Buka Okuthengwayo Okungakapheli",
    variantSelected: "okuhlukile kukhethiwe",
    variant: "okuhlukile",
    thePreviousVariantSelectionNotAvailable:
      "Ukukhetha okwahlukile kwangaphambilini akutholakali kokuhlukile okusha okukhethwe ngokuya ngentengo, sicela ushintshe ukukhetha kwakho.",
    pleaseSupplyPositiveQuantityNumber:
      "Sicela unikeze ngenombolo yeposi yeposi",
    lowStockFor: "Isitoko esiphansi se-",
    clearVariants: "Sula okuhlukile",
    pleaseEnterQuantity: "Sicela ufake Ubuningi",
    picture: "Isithombe",
    redemptionToken: "Ithokheni Lokuhlengwa",
    token: "Uphawu",
    totalSpent: "Ingqikithi Yokuchitha",
    confirmPayment: "Qinisekisa Inkokhelo",
    hasPaymentBeenMade: "Ngabe Inkokhelo icutshungulwe ngempumelelo?",
    enterTransactionReference: "Faka ireferensi yokwenziwayo okhokhe ngayo",
    pleaseSelectACustomer: "Sicela ukhethe ikhasimende!",
    areYouSureToApplyDiscount: "Uqinisekile ukuthi ufuna ukufaka isaphulelo?",
    addYourBankAccountToEnableUssd:
      "Faka i-akhawunti yakho yasebhange ukuze unike amandla ukudluliswa kwe-Instant USSD nguPay",
    totalAmountToPay: "Inani Elifanele Lokukhokhwa",
    doYouWantToCancelTransaction: "Ingabe ufuna ukukhansela lo msebenzi?",
    savePrintBill: "Gcina / Phrinta uBill",
    enterAmountCollectedForCustomer: "Faka inani eliqoqelwe ikhasimende",
    recordSale: "Qopha ukuthengiswa",
    checkOutWith: "Bheka nge",
    instantTransfer: "Ukudluliswa Okusheshayo",
    dialTheUSSDCode: "Shayela ikhodi ye-USSD",
    pleaseSelectABank: "Sicela ukhethe ibhange",
    payWithUSSD: "Khokha Nge-USSD",
    sendBillTo: " - Thumela uBill ku-",
    waitingForUSSDTransfer: "Ilinde Ukudluliswa kwe-USSD",
    percent: "Amaphesenti",
    applyDiscount: "Sebenzisa Isaphulelo",
    thisBillHasBeenSaved: "Lo Mthethosivivinywa ugciniwe",
    saveDraft: "Gcina okusalungiswa",
    pleaseTypeANameToIdentifyCustomer:
      "Sicela uthayiphe igama ukukhomba ikhasimende",
    paymentDetails: "Imininingwane Yokukhokha",
    basePrice: "Intengo Base",
    staff: "Abasebenzi",
    subTotal: "Inani eliphelele",
    durationMonths: "Isikhathi (izinyanga)",
    selectADuration: "Khetha ubude besikhathi",
    oneMonth: "Inyanga engu-1",
    twoMonths: "Izinyanga ezi-2",
    threeMonths: "Izinyanga ezi-3",
    sixMonths: "Izinyanga eziyi-6",
    twelveMonths: "Izinyanga eziyi-12",
    eighteenMonths: "Izinyanga eziyi-18",
    twentyFourMonths: "Izinyanga ezingama-24",
    twoMonthsFree: "(2 Izinyanga Mahhala)",
    threeMonthsFree: "(3 izinyanga mahhala)",
    fiveMonthsFree: "(Izinyanga eziyi-5 Mahhala)",
    yourAccountHasBeen: "I-Akhawunti yakho ibilokhu ikhona",
    renewed: "kuvuselelwe",
    upgraded: "ithuthukisiwe",
    successfully: "ngempumelelo",
    yourSubscription: "Okubhalisile",
    youAreCurrentlyEnrolledOnA: "Okwamanje ubhalisile ku-",
    pleaseChooseAPaymentOption: "Sicela ukhethe inketho yokukhokha",
    planRenewal: "Hlela Ukuvuselelwa",
    planUpgrade: "Hlela Ukuthuthukisa",
    pleaseSelectDurationToPayFor:
      "Sicela ukhethe Isikhathi ohlose ukusikhokhela",
    staffAccounts: "Ama-Akhawunti Abasebenzi",
    ecommerce: "Ezomnotho",
    pleaseSelectAPlan: "Sicela ukhethe uhlelo",
    includeAddons: "Faka izengezo",
    viewTransactions: "Buka Ukuthengiselana",
    customerHasNoCompletedTansactions:
      "Ikhasimende alinawo ama-tansaction aqediwe okwamanje",
    branch: "Igatsha",
    enterNumberOfEcommerceBranches: "Faka inombolo yamagatsha e-Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Faka inombolo yamagatsha e-Ecommerce ohlose ukuwakhokhela",
    ecommerceIntegration: "Ukuhlanganiswa Kwe-ecommerce",
    enterNumberOfBranches: "Faka inombolo yamagatsha",
    enterNumberOfAdditionalBranchesToPay:
      "Faka Inombolo Yamagatsha Owengeziwe Ohlose Ukuwakhokhela",
    enterNumberOfStaffs: "Faka Inombolo Yezisebenzi",
    enterNumberOfStaffsToPayFor:
      "Faka Inombolo Yezisebenzi Ohlose Ukuzikhokhela",
    discountApplies: "Isaphulelo Siyasebenza",
    starsOnThe: "izinkanyezi ku",
    offer: "sipho",
    get: "Thola",
    moreStarsToRedeem: "izinkanyezi eziningi ongazihlenga",
    taxVat: "Intela (i-VAT)",
    callCashierToCompletePayment: "Shayela uCashier ukuqedela ukukhokha",
    receipt: "Irisidi",
    dear: "Sawubona",
    thankYouForYourOrderFindGoods:
      "Siyabonga nge-oda lakho. Sicela uthole izimpahla ezilandelayo zinikezwe, njengoba kuvunyelwene.",
    shippingNote: "Ukuthumela Inothi",
    enterShippingNote: "Faka Inothi Lokuthumela",
    shippingAddress: "Ikheli Lokuthumela",
    enterShippingAddress: "Faka ikheli lokuthumela",
    wellDoneYouAreDueToRedeem: "Wenze kahle! Kufanele uhlenge",
    toGetYourRewardNextVisit:
      "ukuthola umvuzo wakho ekuhambeleni okulandelayo. Ngiyabonga",
    pointsOnThe: "Amaphuzu ku-",
    morePointsToRedeem: "amaphuzu amaningi wokuhlenga",
    showCode: "Khombisa ikhodi",
    toGetYourRewardOnNextVisit:
      "ukuthola umvuzo wakho ekuhambeleni okulandelayo. Ngiyabonga",
    earn: "Thola",
    delivaryNote: "Inothi Lokusetshenziswa",
    draftSales: "Okuthengiswayo Okusalungiswa",
    startDate: "Usuku okuqalwa ngalo",
    endDate: "Idethi yokugcina",
    orders: "Ama-oda",
    checkout: "Hlola",
    noProductItem: "Ayikho Into Yomkhiqizo",
    selectProductImage: "Khetha isithombe somkhiqizo",
    selectCountry: "Khetha izwe",
    selectRegion: "Khetha Isifunda / Isifunda",
    printProductTag: "Phrinta Umkhiqizo Tag",
    transactionReference: "Inkomba yokuthengiselana",
    Cashier: "Umphathi wemali",
    Manager: "Umphathi",
    Owner: "Umnikazi",
    Admin: "Ukuphatha",
    addPartners: "Engeza Ozakwethu",
    addNewLoyaltyPartner: "Engeza Uzakwethu Omusha Wobuqotho",
    pleaseEnterCompanyName: "Sicela ufake igama leNkampani",
    companyName: "Igama Lenkampani",
    pleaseEnterCompanyRepName: "Sicela ufake igama lommeleli wenkampani",
    companyRepName: "Igama Lomele Inkampani",
    pleaseEnterCompanyRepEmail: "Sicela ufake i-imeyili yommeleli wenkampani",
    companyRepEmail: "I-imeyili yommeli wenkampani",
    pleaseEnterCompanyRepPhone:
      "Sicela ufake inombolo yocingo yomele inkampani",
    companyRepPhone: "Inombolo yocingo yommeli wenkampani",
    addReward: "Engeza umvuzo",
    pleaseEnterRewardName: "Sicela ufake igama lomvuzo",
    rewardName: "Igama Lomvuzo",
    rewardQuantity: "Inani Lomvuzo",
    rewardDescription: "Incazelo Yomvuzo",
    rewardType: "Uhlobo Lomvuzo",
    pleaseEnterRewardType: "Sicela ufake uhlobo lomklomelo",
    pleaseEnterRewardQuantity: "Sicela ufake inani lomvuzo",
    pleaseEnterRewardDescription: "Sicela ufake incazelo yomvuzo",
    fineDining: "Ukudla Okuhle",
    luxuryFashion: "Imfashini Kanokusho",
    hotels: "Amahhotela",
    travel: "Ukuhamba",
    foodAndBeverage: "Ukudla Nesiphuzo",
    fashion: "Imfashini",
    health: "Impilo",
    furniture: "Ifenisha",
    entertainment: "Ezokuzijabulisa",
    automobile: "Imoto",
    education: "Imfundo",
    beautyAndSpa: "Ubuhle noSpha",
    staycation: "Indawo yokuhlala",
    events: "Imicimbi",
    trips: "Uhambo",
    oilAndGas: "Amafutha Negesi",
    laundry: "Ilondolo",
    partnerCategory: "Isigaba sikazakwethu",
    freeItem: "Into yamahhala",
  },
  Vietnamese: {
    cashier: "thu ngân",
    manager: "người quản lý",
    owner: "chủ nhân",
    online: "Trực tuyến",
    offline: "ngoại tuyến",
    changePassword: "Đổi mật khẩu",
    currentPasswordMessage: "Vui lòng nhập mật khẩu hiện tại của bạn",
    passwordMessage: "Vui lòng nhập mật khẩu của bạn",
    currentPassword: "Mật khẩu hiện tại",
    password: "mật khẩu mở khóa",
    confirmPasswordMessage: "Vui lòng xác nhận mật khẩu của bạn!",
    confirmPassword: "Xác nhận mật khẩu",
    sendViaEmail: "Gửi qua Email",
    sendViaWhatsapp: "Gửi qua WhatsApp",
    downloadPdf: "tải PDF",
    paid: "trả",
    unpaid: "Chưa thanh toán",
    partial: "một phần",
    closeInvoice: "Bạn có muốn đóng Hóa đơn không?",
    closeInvoiceConfirmation:
      "Hóa đơn có thể không được lưu. Bạn có muốn đóng cửa không?",
    yes: "đúng",
    no: "không",
    invoice: "Hóa đơn",
    wasDeducted: "đã được khấu trừ",
    for: "vì",
    item: "Bài báo",
    addProduct: "Thêm sản phẩm",
    paymentReference: "Tham chiếu thanh toán",
    amountPaid: "Số tiền đã trả",
    discountAmount: "Số tiền chiết khấu",
    amountDue: "Số tiền đến hạn",
    amount: "Số lượng",
    dueDate: "Ngày đáo hạn",
    paymentType: "Hình thức thanh toán",
    card: "Thẻ",
    cash: "Tiền mặt",
    bankTransfer: "Chuyển khoản ngân hàng",
    paymentMessage: "Tin nhắn thanh toán",
    description: "Sự miêu tả",
    sendReceipt: "Gửi biên nhận",
    delete: "Xóa bỏ",
    save: "Cứu",
    resend: "Gửi lại",
    saveAndSend: "Cứu",
    invoiceSaved: "Hóa đơn đã được lưu!",
    selectPaymentMethod: "Vui lòng chọn một phương thức thanh toán!",
    selectCustomer: "Vui lòng chọn một Khách hàng!",
    cartEmptyError:
      "Danh sách giỏ hàng không được để trống, đóng hóa đơn và thêm mặt hàng vào giỏ hàng!",
    subscriptionExpired:
      "Đăng ký của bạn đã hết hạn và tài khoản của bạn hiện bị giới hạn. Nhấp vào Nút bên dưới để gia hạn tài khoản của bạn",
    renew: "Thay mới",
    holdOn: "Giữ lấy",
    customerBank: "Ngân hàng khách hàng",
    cancel: "Hủy bỏ",
    selectACustomer: "Chọn khách hàng",
    invoiceSuccessfulPdfError:
      "Hóa đơn đã được tạo thành công nhưng quá trình tạo PDF mất nhiều thời gian hơn bình thường. Vui lòng quay lại sau.",
    downloadingInvoice: "Tải xuống hóa đơn",
    downloadingReceipt: "Đang tải xuống biên nhận",
    whatsappReceiptError:
      "Đã xảy ra lỗi khi gửi biên nhận qua WhatsApp, Vui lòng thử lại.",
    receiptToWhatsapp: "Biên nhận được chuyển tiếp đến WhatsApp",
    thankYouForPatronage: "Cảm ơn bạn đã bảo trợ của bạn",
    hereIsYourReceipt: "Đây là biên lai thanh toán của bạn",
    errorSendingEmailReceipt:
      "Đã xảy ra lỗi khi gửi biên nhận qua e-mail, vui lòng thử lại hoặc liên hệ với bộ phận hỗ trợ",
    receiptSentToEmail: "Biên nhận đã được gửi đến email của khách hàng",
    invoiceSentToEmail: "Hóa đơn đã được gửi đến email của khách hàng",
    invoiceSuccessWhatsappError:
      "Hóa đơn đã được tạo thành công nhưng đã xảy ra lỗi khi gửi đến WhatsApp. Thử lại trên danh sách hóa đơn",
    invoiceSuccessfulEmailError:
      "Hóa đơn đã được tạo thành công nhưng đã xảy ra lỗi khi gửi dưới dạng e-mail. Vui lòng thử lại từ danh sách hóa đơn",
    invoiceSentToWhatsapp: "Hóa đơn được chuyển tiếp đến WhatsApp",
    hello: "xin chào",
    pleaseDownloadInvoice: "Vui lòng tải xuống hóa đơn",
    pleaseDownloadReceipt: "Vui lòng tải xuống biên nhận",
    from: "từ",
    email: "E-mail",
    upgrade: "Nâng cấp",
    youAreOnFreePlan: "Bạn đang sử dụng Gói miễn phí.",
    clickOn: "Bấm vào",
    yourPlanInFewSteps: " đăng ký của bạn trong một vài bước nhanh chóng.",
    to: "đến",
    yourSubscriptionHasExpired:
      "Đăng ký của bạn đã hết hạn và tài khoản của bạn hiện bị giới hạn.",
    days: "ngày",
    yourSubscriptionExpiresIn: "Đăng ký Loystar của bạn sẽ hết hạn sau",
    createAcount: "Tạo một tài khoản",
    signIn: "Đăng nhập",
    continue: "Tiếp tục",
    enterOtp: "Nhập mã PIN OTP",
    enterValidOtp: "Vui lòng nhập mã PIN hợp lệ",
    pin: "GHIM",
    tokenSentToMail: "Một mã thông báo đã được gửi đến email của bạn",
    passwordResetSuccessful: "Đặt lại mật khẩu thành công",
    somethingWentWrong: "Đã xảy ra lỗi!",
    resetPassword: "Đặt lại mật khẩu",
    iHaveResetCode: "Tôi có mã đặt lại mật khẩu",
    pleaseEnterEmail: "Vui lòng nhập email của bạn",
    aTokenWillBeSentToEmail: "Một mã thông báo sẽ được gửi đến email của bạn",
    enterEmail: "Nhập email của bạn",
    sendinYourToken: "Đang gửi mã thông báo của bạn ...",
    makeSureItMatchesPassword: "Đảm bảo rằng nó khớp với mật khẩu mới của bạn",
    pleaseChooseNewPassword: "Vui lòng chọn một mật khẩu mới",
    chooseNewPassword: "Chọn một mật khẩu mới",
    enterNewPassword: "Nhập mật khẩu mới của bạn để xác nhận",
    enterTokenSent: "Nhập mã thông báo đã được gửi thư của bạn",
    resetToken: "Đặt lại mã thông báo",
    resettingPassword: "Đặt lại mật khẩu của bạn ...",
    signUp: "Đăng ký",
    adminSignInWithEmail:
      " Quản trị viên đăng nhập bằng email trong khi Nhân viên đăng nhập bằng tên người dùng.",
    pleaseEnterEmailOrUsername:
      "Vui lòng nhập email hoặc tên người dùng của bạn",
    emailOrUsername: "Thư điện tử hoặc tên người dùng",
    pleaseEnterPassword: "Xin vui lòng nhập mật khẩu",
    createAnAccount: "Tạo một tài khoản",
    forgotPassword: "Quên mật khẩu?",
    enterPhoneNumber: "Nhập số điện thoại",
    personalData: "Dữ liệu cá nhân",
    validateConfirmationCOde: "Xác thực mã xác nhận",
    pleaseEnterFirstName: "Vui lòng nhập tên của bạn",
    pleaseEnterPhoneNumber: "Xin vui lòng điền số điện thoại của bạn",
    pleaseEnterLastName: "Vui lòng nhập họ của bạn",
    pleaseEnterBusinessName: "Vui lòng nhập tên doanh nghiệp của bạn",
    firstName: "Tên đầu tiên",
    lastName: "Họ",
    businessName: "Tên doanh nghiệp",
    previous: "Trước",
    next: "Kế tiếp",
    pleaseSelectBusinessCategory: "Vui lòng chọn loại doanh nghiệp của bạn",
    pleaseEnterValidEmail: "Vui lòng nhập email hợp lệ",
    pleaseEnterPostCode: "Vui lòng nhập mã bưu điện",
    postCode: "Mã bưu điện",
    phoneNumberInUse: "Số điện thoại này đã được sử dụng!",
    emailInUse: "Email này đã được sử dụng!",
    foodAndDrinks: "Thức ăn và đồ uống",
    salonAndBeauty: "Salon và Làm đẹp",
    fashionAndAccessories: "Thời trang và Phụ kiện",
    gymAndFitness: "Phòng tập thể dục và thể hình",
    travelAndHotel: "Du lịch và Khách sạn",
    homeAndGifts: "Trang chủ và Quà tặng",
    washingAndCleaning: "Giặt và Làm sạch",
    gadgetsAndElectronics: "Tiện ích và Điện tử",
    groceries: "Cửa hàng tạp hóa",
    others: "Khác",
    submit: "Nộp",
    accountCreatedSuccessful: "Tài khoản của bạn đã được tạo thành công.",
    pleaseEnterAddress: "Vui lòng nhập địa chỉ của bạn",
    addressLine1: "Dòng địa chỉ 1",
    addressLine2: "Địa chỉ 2",
    choosePassword: "Chọn mật khẩu",
    passwordMustBe6Characters: "Mật khẩu phải có ít nhất 6 ký tự.",
    howDidYouHearLoystar: "Làm thế nào bạn biết về Loystar?",
    referralCode: "Mã giới thiệu",
    byClickingTheButton: " Bằng cách nhấp vào nút bên dưới, bạn đồng ý với",
    termsAndSevice: "Điều kiện",
    wereCreatingAccount: "Chúng tôi đang tạo tài khoản của bạn",
    proceed: "Tiếp tục",
    verificationCodeMustBe6: "Mã xác minh phải có 6 chữ số",
    pleaseEnter6DigitCode: "Vui lòng nhập mã gồm 6 chữ số",
    enterVerificationCode: "Nhập mã xác nhận",
    resendToken: "Gửi lại mã thông báo",
    verify: "Xác nhận",
    transactions: "Giao dịch",
    todaySales: "Bán hàng hôm nay",
    salesHistory: "Lịch sử bán hàng",
    supplyHistory: "Lịch sử cung cấp",
    new: "Mới",
    invoices: "Hóa đơn",
    disbursements: "Các khoản giải ngân",
    offlineSales: "Bán hàng ngoại tuyến",
    products: "Các sản phẩm",
    customers: "Khách hàng",
    multiLevelLoyalty: "Mức độ trung thành đa cấp",
    loyaltyPrograms: "Các chương trình lòng trung thành",
    members: "Các thành viên",
    appStore: "Cửa hàng ứng dụng",
    orderMenu: "Đơn đặt hàng",
    settings: "Cài đặt",
    staffAndBranches: "Nhân viên và Chi nhánh",
    myAccount: "Tài khoản của tôi",
    switchToSellMode: "Chuyển sang Chế độ Bán",
    signOut: "Đăng xuất",
    getFreeSubscription: "Nhận đăng ký miễn phí",
    onlyNumbersAllowed: "Chỉ số được phép",
    yourAccountMustBe10Digits:
      "số tài khoản của bạn phải là một số có 10 chữ số",
    yourBvnMustBe11: "BVN của bạn phải là một số có 11 chữ số",
    pleaseSelectBank: "Vui lòng chọn ngân hàng của bạn",
    selectYourBank: "Chọn Ngân hàng của bạn",
    enterBankAccountNumber: "Nhập số tài khoản ngân hàng",
    enterBvnNumber: "Nhập BVN của bạn",
    connectBankAccount: "Kết nối tài khoản ngân hàng",
    passwordResetSuccessfulAndSignOut:
      "Mật khẩu của bạn đã được đặt lại thành công. Nhấp vào nút Đăng xuất bên dưới để đăng nhập lại",
    enterCurrentPassword: "Nhập mật khẩu hiện tại",
    pleaseEnterCurrentPassword: "Vui lòng nhập mật khẩu hiện tại",
    phoneNumber: "Số điện thoại",
    sex: "Tình dục",
    dateOfBirth: "Ngày sinh",
    state: "Tiểu bang",
    country: "Quốc gia",
    loyaltyId: "ID khách hàng thân thiết",
    createdAt: "Được tạo lúc",
    noOfTransactionsMade: "Số lượng giao dịch đã thực hiện",
    yourDownloadWillStart:
      "Quá trình tải xuống của bạn sẽ bắt đầu trong giây lát",
    exportCustomers: "Khách hàng xuất khẩu",
    youhaveSuccessfullyToppedUp: "Bạn đã nạp thành công Đơn vị SMS của mình.",
    youNowHave: "Bây giờ bạn có",
    smsUnits: "Đơn vị SMS",
    enterNumberOfSmsUnits: "Nhập số đơn vị SMS bạn định mua",
    pleaseEnterANumericValue: "Vui lòng nhập một giá trị số",
    pay: "Tiền lương",
    accountEditedSuccessfully:
      "Tài khoản của bạn đã được chỉnh sửa thành công.",
    youAeCurrentlyOn: "Bạn hiện đang ở trên",
    plan: "Kế hoạch",
    userData: "Dữ liệu người dùng",
    businessData: "DỮ LIỆU KINH DOANH",
    businessCategory: "Danh mục kinh doanh",
    pleaseSelectCurrency: "Vui lòng chọn đơn vị tiền tệ của bạn",
    selectYourCurrency: "Chọn đơn vị tiền tệ của bạn",
    purchaseMoreSmsUnits:
      "Mua thêm đơn vị SMS bằng cách sử dụng biểu mẫu bên dưới",
    youHave: "Bạn có",
    atLeast4SmsUnitsRrequired:
      "Cần ít nhất 4 đơn vị sms để xác minh, vui lòng nạp tiền!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Vui lòng xác minh tài khoản ngân hàng của bạn để tuân thủ các yêu cầu 'Biết khách hàng của bạn' (KYC). Điều này sẽ cho phép bạn thu thập các khoản thanh toán qua USSD hoặc chuyển khoản ngay lập tức, nhận đơn đặt hàng từ khách hàng và xử lý thanh toán giao dịch. Chỉ áp dụng cho các thương gia Nigeria. Vui lòng nhấn nút bên dưới để bắt đầu.",
    reConnectBankAccount: "Kết nối lại tài khoản ngân hàng",
    accountName: "Tên tài khoản",
    accountNumber: "Số tài khoản",
    bankName: "Tên ngân hàng",
    verified: "Đã xác minh",
    accountDetails: "Chi tiết tài khoản",
    kycBankAccount: "KYC",
    createTier: "Tạo Bậc",
    fileUploadSuccessful: "Tải tài liệu thành công",
    fileUploadFailed: "tải tệp lên không thành công",
    createLoyaltyProgram: "Tạo chương trình khách hàng thân thiết",
    createLoyalty: "Tạo sự trung thành",
    name: "Tên",
    loyaltyArtwork: "Ảnh minh họa về mức độ trung thành",
    clickToUpload: "Bấm để tải lên",
    amountToPointsRatio: "Số tiền với Tỷ lệ điểm",
    points: "Điểm",
    recommendedAmountToPointRatio:
      "Khuyến nghị: ₦ 1 đến 1 điểm. tức là cứ mỗi 1 Naira chi tiêu, khách hàng của bạn nhận được 1 điểm",
    pleaseTypeIn: "Vui lòng nhập",
    toDeleteLoyalty: "để xóa lòng trung thành này",
    deleteLoyalty: "Xóa mức độ trung thành",
    toConfirm: "xác nhận",
    edit: "Chỉnh sửa",
    pointsAwardedSuccessfully: "Điểm đã được thưởng thành công.",
    enterPointValueToAward: "Nhập giá trị điểm bạn muốn thưởng cho khách hàng",
    award: "Phần thưởng",
    awardPointValuesToCustomer: "Giá trị điểm thưởng cho khách hàng",
    enrollMembersToLoyalty: "Đăng ký thành viên với mức độ trung thành",
    awardPoints: "Điểm thưởng",
    enroll: "Đăng ký",
    home: "Nhà",
    loyalty: "Lòng trung thành",
    enrollCustomers: "Đăng ký khách hàng",
    selected: "Đã chọn",
    customer: "Khách hàng",
    loyaltyActivationSuccessful: "Kích hoạt mức độ trung thành thành công.",
    loyaltyDeactivationSuccessful:
      "Hủy kích hoạt mức độ trung thành thành công.",
    viewTier: "Xem Bậc",
    deactivate: "Hủy kích hoạt",
    activate: "Hoạt động",
    actions: "Hành động",
    nameOfLoyalty: "Tên của lòng trung thành",
    loyaltyStatus: "Trạng thái khách hàng thân thiết",
    numberOfTiers: "Số bậc",
    createdOn: "Được tạo ra",
    createATier: "Tạo một Bậc",
    stopCreatingTierConfirmation: "Bạn có muốn ngừng tạo một tầng không?",
    stopEditingTierConfirmation: "Bạn có muốn dừng chỉnh sửa tầng này không?",
    nameOfTier: "Tên của Bậc",
    minimumSpendingTarget: "Mục tiêu Chi tiêu Tối thiểu",
    maximumSpendingTarget: "Mục tiêu Chi tiêu Tối đa",
    minimumSpendingTargetRequired: "mục tiêu chi tiêu tối thiểu được yêu cầu",
    maximumSpendingTargetRequired: "mục tiêu chi tiêu tối đa được yêu cầu",
    rewardSponsor: "Nhà tài trợ phần thưởng",
    pleaseChooseARewardSponsor: "Vui lòng chọn nhà tài trợ phần thưởng",
    self: "Bản thân",
    partner: "Bạn đồng hành",
    rewardPartner: "Đối tác khen thưởng",
    pleaseSelectRewardPartner: "Vui lòng chọn đối tác thưởng của bạn",
    rewards: "Phần thưởng",
    pleaseSelectAReward: "Xin vui lòng chọn một phần thưởng",
    instructionsOnRedeemingReward:
      "Hướng dẫn về cách khách hàng nên đổi phần thưởng",
    pleaseFillInThisField: "Vui lòng điền vào trường này!",
    toDeleteThisTier: " để xóa tầng này",
    deleteTier: "Xóa Bậc",
    viewMembers: "Xem thành viên",
    membersEnrolled: "Thành viên đã đăng ký",
    instruction: "Hướng dẫn",
    membersIn: "Các thành viên trong",
    availableTiersInLoyalty:
      "(Các) Bậc có sẵn trong Chương trình Khách hàng thân thiết",
    tiers: "Bậc",
    totalTier: "TỔNG Bậc",
    availableLoyaltyProgramme: "Chương trình khách hàng thân thiết có sẵn",
    totalLoyalties: "TỔNG SỐ TRUNG THÀNH",
    memberDetails: "Chi tiết thành viên",
    nameOfCustomer: "Tên của khách hàng",
    address: "Địa chỉ nhà",
    allEnrolledMembers: "Tất cả thành viên đã đăng ký",
    thisIsToWishYouHappyBirthday:
      "Đây là để chúc bạn một sinh nhật thật hạnh phúc và cuộc sống thịnh vượng. Cảm ơn vì tất cả những gì bạn dành cho Loyster. Lễ kỷ niệm vui vẻ!",
    inputAnOfferPlease: "Vui lòng nhập Phiếu mua hàng",
    pleaseSelectTheInsertPoint:
      "Vui lòng chọn điểm chèn trong tin nhắn và nhấp lại",
    birthdayOfferAndMessage: "Ưu đãi sinh nhật và tin nhắn",
    birthdayOffer: "Ưu đãi sinh nhật",
    birthdayMessage: "Tin nhắn sinh nhật",
    noOfferFound: "Không tìm thấy phiếu mua hàng nào",
    settingABirthdayOffer:
      "Đặt ưu đãi sinh nhật cho phép khách hàng nhận ưu đãi này qua SMS vào ngày sinh nhật của họ",
    createOffer: "Tạo phiếu mua hàng",
    whatIsTheOffer: "Ưu đãi là gì?",
    editMessage: "Chỉnh sửa tin nhắn",
    insert: "Chèn",
    theNameOfCustomerInserted: "Tên của khách hàng sẽ được chèn tại đây",
    theBirtdayOfferInserted: "Ưu đãi sinh nhật sẽ được chèn tại đây",
    youSuccessfullyAddedNewBranch: "Bạn đã thêm thành công một chi nhánh mới!",
    addNewBranch: "Thêm chi nhánh mới",
    addBranch: "Thêm chi nhánh",
    additionalBranchWillIncur: "Chi nhánh bổ sung sẽ phải chịu",
    perBranch: "mỗi chi nhánh",
    ecommerceBranchCosts: "Chi phí chi nhánh thương mại điện tử",
    pleaseEnterBranchName: "Vui lòng nhập tên chi nhánh",
    pleaseEnterBranchAddress1: "Vui lòng nhập dòng địa chỉ của chi nhánh 1",
    enterBranchAddress1: "Nhập dòng địa chỉ của chi nhánh 1",
    enterBranchAddress2: "Nhập dòng địa chỉ của chi nhánh 1",
    pleaseEnterBranchAddress2: "Vui lòng nhập địa chỉ của chi nhánh vào dòng 2",
    enterBranchName: "Nhập tên chi nhánh",
    successfullyAddedStaff: "Bạn đã thêm thành công một nhân viên mới!",
    addNewStaff: "Thêm nhân viên mới",
    addStaff: "Thêm nhân viên",
    additionalStaffWillIncur: "Nhân viên bổ sung sẽ phải chịu",
    perStaff: "mỗi nhân viên.",
    pleaseEnterStaffEmail: "Vui lòng nhập email của nhân viên",
    pleaseEnterStaffUsername: "Vui lòng nhập tên người dùng của nhân viên",
    pleaseEnterStaffPassword: "Vui lòng nhập mật khẩu của nhân viên",
    pleaseSelectStaffRole: "Vui lòng chọn vai trò của nhân viên",
    selectStaffRole: "Chọn vai trò của nhân viên",
    enterStaffEmail: "Nhập email của nhân viên",
    enterStaffUsername: "Nhập tên người dùng của nhân viên",
    enterStaffPassword: "Nhập mật khẩu của nhân viên",
    spacesNotAllowedInUsername:
      "khoảng trắng không được phép trong tên người dùng",
    admin: "quản trị viên",
    pleaseSelectBusinessToAttachStaff:
      "Vui lòng chọn doanh nghiệp để gắn nhân viên",
    searchForBranchToAttachStaff: "Tìm kiếm chi nhánh để gắn nhân viên",
    username: "tên tài khoản",
    role: "Vai diễn",
    areYouSureToDeleteThis: "Bạn có chắc chắn xóa cái này không",
    branches: "Chi nhánh",
    upgradeYourPlan: "Nâng cấp Kế hoạch của bạn.",
    add: "CỘNG",
    addNew: "Thêm mới",
    customerWithEmailAlreadyExists:
      "Khách hàng có email / số điện thoại đã tồn tại!",
    successfullyAddedNewCustomer: "Bạn đã thêm thành công một khách hàng mới!",
    addCustomer: "Thêm khách hàng",
    pleaseEnterCustomerFirstName: "Vui lòng nhập tên của khách hàng",
    pleaseEnterCustomerLastName: "Vui lòng nhập họ của khách hàng",
    pleaseEnterCustomerPhoneNumber:
      "Vui lòng nhập số điện thoại của khách hàng",
    pleaseEnterCustomerEmail: "Vui lòng nhập email của khách hàng",
    pleaseEnterCustomerAddressLine: "Vui lòng nhập Dòng địa chỉ của khách hàng",
    pleaseEnterCustomerOtherAddress:
      "Vui lòng nhập Địa chỉ khác của khách hàng",
    pleaseSelectCustomerGender: "Vui lòng chọn giới tính của khách hàng",
    gender: "Giới tính",
    male: "Nam giới",
    female: "Giống cái",
    bank: "ngân hàng",
    selectBank: "Chọn ngân hàng",
    stateOrRegionOrProvince: "Bang / Vùng / Tỉnh",
    customerNotes: "Ghi chú khách hàng",
    sendSms: "Gửi tin nhắn",
    editCustomer: "Chỉnh sửa khách hàng",
    redeemReward: "Đổi phần thưởng",
    issueLoyaltyCard: "Phát hành thẻ khách hàng thân thiết",
    deleteCustomer: "Xóa khách hàng",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Bạn đã chỉ định thành công ID tư cách thành viên trung thành",
    noMembershipIdAvailable:
      "Không có ID thành viên nào. Vui lòng liên hệ với chúng tôi qua hello@loystar.co",
    sendEmail: "Gửi email",
    membershipPoints: "Điểm thành viên",
    customerDetails: "Chi tiết khách hàng",
    close: "Gần",
    loyaltyBalance: "Số dư khách hàng thân thiết",
    pointsBalance: "Số dư điểm",
    starBalance: "Cân bằng sao",
    requiredPoints: "Điểm yêu cầu",
    requiredStars: "Dấu sao bắt buộc",
    reddemCode: "Đổi mã",
    toDeleteThisCustomer: "để xóa khách hàng này",
    customerHasBeenDeletedSuccessfully: "Khách hàng đã được xóa thành công!",
    customerWithPhoneAlreadyExists: "Khách hàng có số điện thoại đã tồn tại",
    customerWasSuccessfullyEdited: "Khách hàng đã được chỉnh sửa thành công",
    anErrorOccured: "Đã xảy ra lỗi",
    phoneNumberIncludeCountry: "Số điện thoại (Bao gồm mã quốc gia)",
    yourFileQueuedForUpload:
      "Tệp của bạn đã được xếp hàng đợi để tải lên. Vui lòng làm mới trang sau vài giây.",
    thereWasAnErrorPerformingOperation: "Đã xảy ra lỗi khi thực hiện thao tác!",
    pleaseSelectFile: "Vui lòng chọn một tệp!",
    oopsOnlyCsvFilesAllowed:
      "Giáo sư! Chỉ cho phép các tệp CSV. Vui lòng tải lên tệp .csv.",
    fileShouldBeSmallerThan5Mb:
      "Tệp phải nhỏ hơn 5MB. Nếu bạn có một tệp lớn hơn, vui lòng gửi email tới support@loystar.co. Cảm ơn bạn",
    customerFirstNameIsRequired: "tên khách hàng là bắt buộc trên hàng",
    customerPhoneNumberIsRequired:
      "số điện thoại của khách hàng là bắt buộc trên hàng",
    importCustomers: "Nhập khách hàng",
    upload: "Tải lên",
    clickIconToDownloadCsv: "Nhấp vào biểu tượng này để tải xuống mẫu tệp CSV.",
    getGoogleSheetVersion: "Tải xuống phiên bản trang tính của Google",
    clickOrDragFileToUpload: "Nhấp hoặc kéo tệp vào khu vực này để tải lên",
    missingOrInvalidColumnHeader:
      "Tiêu đề cột bị thiếu hoặc không hợp lệ. Vui lòng làm theo định dạng mẫu. Cảm ơn bạn.",
    youHaveImported: "Bạn đã nhập",
    youSuccessfullyRedeemedReward:
      "Bạn đã đổi thành công phần thưởng của mình!",
    sixDigitCode: "Mã sáu chữ số",
    pleaseEnterCustomerRewardCode:
      "Vui lòng nhập mã phần thưởng của khách hàng",
    enterRewardCode:
      "Nhập mã phần thưởng. (Mã phần thưởng có phân biệt chữ hoa chữ thường)",
    selectLoyaltyProgram: "Chọn chương trình khách hàng thân thiết",
    stamps: "Tem",
    smsUnitsLow: "Số đơn vị SMS thấp",
    pleaseTopUpSmsUnits: "Vui lòng nạp tiền cho các đơn vị SMS",
    smsSuccessfullySentForDelivery: "SMS đã được gửi thành công để giao hàng!",
    sendSmsTo: "Gửi SMS tới",
    allCustomers: "Tất cả khách hàng",
    unitsAvailable: "Đơn vị có sẵn",
    pleaseTypeInTheMessage: "Vui lòng nhập tin nhắn",
    message: "Thông điệp",
    charactersRemaining: "ký tự còn lại",
    avoidUseOfSpecialCharacters:
      "Tránh sử dụng các Ký tự đặc biệt và Biểu tượng cảm xúc để bảo tồn các Đơn vị SMS.",
    note: "Ghi chú",
    errorFetchingCustomersMultilevelDetail:
      "Lỗi khi tìm nạp chi tiết đa cấp của khách hàng",
    search: "Tìm kiếm",
    reset: "Cài lại",
    importCustomer: "Nhập khách hàng",
    addNewCustomer: "Thêm khách hàng mới",
    sendSmsBroadcast: "Gửi tin nhắn SMS Broadcast",
    totalCustomers: "TỔNG KHÁCH HÀNG",
    disbursementDetails: "Chi tiết giải ngân",
    paidBy: "Được trả bởi",
    disbursed: "Đã giải ngân",
    bankAccountName: "Tên tài khoản ngân hàng",
    bankAccountNumber: "Số tài khoản ngân hàng",
    transferInitiated: "Chuyển tiền được bắt đầu",
    transferCompleted: "Đã hoàn tất chuyển giao",
    pleaseEnterAValid: "Xin vui lòng nhập vào một giá trị",
    begin: "bắt đầu",
    end: "kết thúc",
    date: "ngày",
    paymentDate: "Ngày thanh toán",
    selectDisbursementDuration: "Chọn Thời hạn giải ngân",
    totalSettled: "Tổng số tiền đã giải quyết",
    totalUnsettled: "Tổng số chưa giải quyết",
    toDeleteThisSale: "để xóa đợt giảm giá này",
    draftSaleDeletedSuccessfully: "Bán Bản nháp đã được xóa thành công!",
    deleteSale: "Xóa giảm giá",
    pleaseTypeInYourTotal: "Vui lòng nhập tổng số của bạn để xác nhận xóa",
    billDetails: "Chi tiết hóa đơn",
    printBill: "In hóa đơn",
    servedBy: "Phục vụ bởi",
    total: "Toàn bộ",
    createdDate: "ngày tạo ra",
    createdTime: "Thời gian tạo",
    status: "Trạng thái",
    loginSuccessful: "Đăng nhập thành công",
    pleaseWaitWhileWeConnectAccount:
      "Vui lòng đợi trong khi chúng tôi kết nối tài khoản của bạn",
    connectionFailedTryAgain: "Kết nối thất bại. Vui lòng thử lại.",
    connectionSuccessful: "Kết nối thành công",
    viewDetails: "Xem chi tiết",
    enable: "Cho phép",
    free: "Miễn phí",
    cost: "Trị giá",
    visitWebsite: "Truy cập trang web",
    pleaseUpgradeYourPlanToPro:
      "Vui lòng nâng cấp gói của bạn lên Pro Plus để kích hoạt ứng dụng này",
    connectYourBankAccount:
      "Kết nối Tài khoản Ngân hàng của bạn để có thể nhận thanh toán.",
    disable: "Vô hiệu",
    enableApp: "Bật ứng dụng",
    addNewProductToInvoice: "Thêm sản phẩm mới vào hóa đơn",
    toDeleteThisInvoice: "để xóa hóa đơn này",
    invoiceDeletedSuccessfully: "Hóa đơn đã được xóa thành công!",
    deleteInvoice: "Xóa hóa đơn",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Bạn đã thêm thành công thông báo thanh toán Hóa đơn!",
    pleaseEnterPaymentInstructions: "Vui lòng nhập hướng dẫn thanh toán",
    invoiceId: "ID hóa đơn",
    paidDate: "Ngày thanh toán",
    reference: "Thẩm quyền giải quyết",
    productAdded: "Sản phẩm được thêm vào",
    productOutOfStock: "Sản phẩm hết hàng. Vui lòng nhập kho lại.",
    totalInvoices: "TỔNG HÓA ĐƠN",
    totalPaidInvoices: "TỔNG SỐ HÓA ĐƠN ĐÃ THANH TOÁN",
    totalUnpaidInvoices: "TỔNG SỐ HÓA ĐƠN CHƯA TRẢ TIỀN",
    loyaltyProgramDeleted: "Chương trình khách hàng thân thiết đã bị xóa",
    thisLoyaltyProgramDeletedSuccessfully:
      "Chương trình khách hàng thân thiết này đã được xóa thành công",
    thisLoyaltyProgramEditedSuccessfully:
      "Chương trình khách hàng thân thiết này đã được chỉnh sửa thành công",
    loyaltyProgramAddedSuccessfully:
      "Chương trình khách hàng thân thiết đã được thêm thành công",
    loyaltyProgramEdited:
      "Chương trình khách hàng thân thiết đã được chỉnh sửa",
    loyaltyProgramAdded: "Chương trình khách hàng thân thiết đã được thêm",
    loyaltyDetails: "Chi tiết về mức độ trung thành",
    doYouWantToCloseDialog: "Bạn có muốn đóng hộp thoại này không?",
    pleaseEnterLoyaltyName: "Vui lòng nhập tên của lòng trung thành của bạn",
    programType: "Loại chương trình",
    pleaseSelectProgramType: "Vui lòng chọn một loại chương trình",
    simplePoints: "Điểm đơn giản",
    stampsProgram: "Chương trình tem",
    threshold: "Ngưỡng",
    pleaseEnterLoyaltyThreshold: "Vui lòng nhập ngưỡng mức độ trung thành",
    reward: "Giải thưởng",
    pleaseEnterLoyaltyReward: "Vui lòng nhập phần thưởng của lòng trung thành",
    totalUserPoints: "Tổng số điểm của người dùng",
    totalUserStamps: "Tổng số tem người dùng",
    spendingTarget: "Mục tiêu chi tiêu",
    spendingTargetHint1:
      "Mục tiêu chi tiêu là số tiền khách hàng phải chi tiêu để nhận được phần thưởng. 1 giá trị tiền tệ = 1 điểm.",
    spendingTargetHint2:
      "Mục tiêu tem là khách hàng phải thu thập bao nhiêu tem để nhận được phần thưởng. ví dụ. 5",
    addNewLoyaltyProgram: "Thêm chương trình khách hàng thân thiết mới",
    addLoyaltyProgram: "Thêm chương trình khách hàng thân thiết",
    loyaltyProgramType: "Loại chương trình khách hàng thân thiết",
    pleaseSelectLoyaltyProgramType:
      "Vui lòng chọn loại chương trình khách hàng thân thiết",
    nameOfProgram: "Tên chương trình",
    pleaseEnterProgramName: "Vui lòng nhập tên chương trình",
    whatIsTheReward: "Phần thưởng là gì?",
    egNextPurchaseIsFree: "Ví dụ. Lần mua tiếp theo là miễn phí",
    customerName: "tên khách hàng",
    guestCustomer: "Khách hàng",
    orderConfirmedSuccessfully: "Đã xác nhận đơn hàng thành công",
    orderCancelledSuccessfully: "Đã hủy đơn đặt hàng thành công",
    confirmOrder: "Xác nhận đơn hàng",
    cancelOrder: "Hủy đơn hàng",
    allOrders: "Tất cả các đơn hàng",
    totalOrders: "TỔNG ĐƠN HÀNG",
    doYouWantToAcceptOrder: "Bạn có muốn chấp nhận đơn đặt hàng này không?",
    doYouWantToCancelOrder: "Bạn có muốn hủy đơn đặt hàng này không?",
    orderDetails: "Chi tiết đơn hàng",
    orderCreatedAt: "Đơn hàng được tạo lúc",
    supplier: "Nhà cung cấp",
    productName: "tên sản phẩm",
    quantity: "Số lượng",
    unitPrice: "Đơn giá",
    receivedBy: "Nhận bởi",
    reportFrom: "Báo cáo từ",
    totalSupplies: "Tổng nguồn cung cấp",
    allRightsReserved: "Đã đăng ký Bản quyền",
    toDeleteThisTransaction: "để xóa Giao dịch này",
    transactionDeletedSuccessfully:
      "Giao dịch đã được xóa thành công. Hàng tồn kho đã được trả lại hàng tồn kho.",
    deleteTransaction: "Xóa giao dịch",
    transactionDetails: "chi tiết giao dịch",
    printReceipt: "In biên nhận",
    channel: "Kênh",
    discount: "Chiết khấu",
    profit: "Lợi nhuận",
    discountedSales: "Bán hàng chiết khấu",
    errorFetchingRecord: "Lỗi tìm nạp bản ghi",
    exportTransactions: "Giao dịch xuất khẩu",
    errorFetchingSalesRecord: "Lỗi khi tìm nạp hồ sơ Bán hàng để Xuất.",
    totalSellingPrice: "Tổng giá bán",
    totalCostPrice: "Tổng giá thành",
    appliedDiscount: "Giảm giá áp dụng",
    noOfItems: "Số lượng mặt hàng",
    sales: "Bán hàng",
    export: "Xuất khẩu",
    totalProfit: "Tổng lợi nhuận",
    totalBalanceInPeriod: "Tổng số dư trong kỳ",
    allTimeSales: "Bán hàng mọi lúc",
    records: "Hồ sơ",
    todaysSales: "Bán hàng hôm nay",
    transaction: "Giao dịch",
    youHaveExceededTotalNumberOfProducts:
      "Bạn đã vượt quá tổng số sản phẩm được phép trong kế hoạch của mình. Nâng cấp gói của bạn để hưởng giới hạn cao hơn.",
    youNeedToHaveLoyaltyProgram:
      "Bạn cần có Chương trình khách hàng thân thiết để sử dụng tính năng này!",
    price: "Giá bán",
    category: "Loại",
    stockTracking: "Theo dõi hàng tồn kho",
    stockCount: "Số chứng khoán",
    taxed: "Đã đánh thuế",
    originalPrice: "Giá gốc",
    costPrice: "Giá cả",
    unit: "Đơn vị",
    productImage: "Hình ảnh sản phẩm",
    taxRate: "Thuế suất",
    taxType: "Loại thuế",
    trackInventory: "Theo dõi khoảng không quảng cáo",
    variants: "Các biến thể",
    hasVariants: "Có các biến thể",
    importProduct: "Nhập sản phẩm",
    exportProducts: "Sản phẩm xuất khẩu",
    addNewProduct: "Thêm sản phẩm mới",
    viewCategory: "Xem danh mục",
    viewSuppliers: "Xem nhà cung cấp",
    receiveInventory: "Nhận hàng tồn kho",
    printAllProductsTag: "In tất cả thẻ sản phẩm",
    deleteAll: "Xóa hết",
    totalProducts: "TỔNG SẢN PHẨM",
    youveSuccessfullyAddedANewCategory:
      "Bạn đã thêm thành công một danh mục mới",
    addNewCategory: "Thêm danh mục mới",
    addCategory: "thêm thể loại",
    categoryName: "tên danh mục",
    pleaseEnterCategoryName: "Vui lòng nhập tên danh mục",
    stampsTarget: "Mục tiêu tem",
    sendInventory: "Gửi hàng tồn kho",
    productDetails: "Thông tin chi tiết sản phẩm",
    youveSuccessfullyEditedThisCategory:
      "Bạn đã chỉnh sửa thành công danh mục này",
    update: "Cập nhật",
    categoryList: "Danh sách chuyên mục",
    categories: "Thể loại",
    enterQuantityToUpdate: "Nhập số lượng để cập nhật",
    inventorySentSuccessfully: "Khoảng không quảng cáo đã được gửi thành công!",
    updateInventory: "Cập nhật khoảng không quảng cáo",
    currentQuantity: "Số lượng hiện tại",
    pleaseEnterQuantityToAdd: "Vui lòng nhập số lượng bạn muốn thêm",
    pleaseSelectABranch: "Vui lòng chọn một chi nhánh",
    searchForBranch: "Tìm kiếm chi nhánh",
    youCantSendMoreThanStock:
      "Bạn không thể gửi nhiều hơn số lượng bạn có trong kho",
    send: "Gửi",
    pleaseEnterQuantityToSend: "Vui lòng nhập số lượng bạn muốn gửi",
    productNameIsRequiredOnRow: "tên sản phẩm là bắt buộc trên hàng",
    productCategoryIsRequiredOnRow: "danh mục sản phẩm được yêu cầu trên hàng",
    productPriceIsRequiredOnRow: "giá sản phẩm được yêu cầu trên hàng",
    productUnitIsRequiredOnRow: "đơn vị sản phẩm được yêu cầu trên hàng",
    productQuantityIsRequiredOnRow: "số lượng sản phẩm được yêu cầu trên hàng",
    productVariantsRequireTracking: "các biến thể sản phẩm yêu cầu theo dõi!",
    pleaseAddVariantClickButton:
      "Vui lòng thêm một biến thể bằng cách nhấp vào nút thêm!",
    totalVariantsMoreThanSelectedQuantity:
      "Tổng số biến thể nhiều hơn số lượng sản phẩm đã chọn, Vui lòng giảm số lượng biến thể",
    productEditedSuccessfully: "Sản phẩm đã được chỉnh sửa thành công!",
    fileTooLargeLessThan4Mb:
      "Kích thước tệp quá lớn! Kích thước tệp phải nhỏ hơn 4MB.",
    suchVariantAlreadyExist: "Biến thể như vậy đã tồn tại",
    addVariants: "Thêm các biến thể",
    editProduct: "Chỉnh sửa sản phẩm",
    pleaseEnterProductName: "Vui lòng nhập tên sản phẩm",
    pleaseEnterProductPrice: "Vui lòng nhập giá sản phẩm",
    pleaseEnterProductOriginalPrice: "Vui lòng nhập giá gốc của sản phẩm",
    productDescription: "Mô tả Sản phẩm",
    selectProductCategory: "Chọn danh mục sản phẩm",
    pleaseSelectProductCategory: "Vui lòng chọn danh mục sản phẩm",
    productCostPrice: "Giá thành sản phẩm",
    productSellingPrice: "Giá bán sản phẩm",
    productOriginalPrice: "Giá gốc của sản phẩm",
    maxFileSizeAllowedIs4Mb: "Tối đa Kích thước tệp cho phép là 4mb",
    productsWithPicturesArePublished:
      "Sản phẩm có hình đăng trên Khám Phá để nhận đặt hàng",
    shouldThisProductBeTracked: "Sản phẩm này có nên được theo dõi không?",
    pleaseSelectStockUnit: "Vui lòng chọn đơn vị chứng khoán",
    stockUnit: "Đơn vị chứng khoán",
    bag: "CÁI TÚI",
    bottle: "CHAI",
    bunch: "BÚP BÊ",
    can: "CÓ THỂ",
    carton: "CARTON",
    crate: "CRATE",
    cup: "TÁCH",
    dozen: "TÁ",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LÍT",
    pack: "ĐÓNG GÓI",
    pair: "ĐÔI",
    pieces: "MIẾNG",
    plate: "ĐĨA ĂN",
    tonne: "TONNE (MT)",
    uNIT: "ĐƠN VỊ",
    yard: "SÂN",
    pleaseEnterProductQuantity: "Vui lòng nhập số lượng sản phẩm",
    productQuantity: "Chất lượng sản phẩm",
    isThisProductTaxed: "Sản phẩm này có bị đánh thuế không?",
    selectTaxType: "Chọn loại thuế",
    pleaseSelectTaxType: "Vui lòng chọn Loại thuế",
    progressive: "Cấp tiến",
    proportional: "Theo tỷ lệ",
    pleaseEnterProductTaxRate: "Vui lòng nhập thuế suất sản phẩm",
    doesProductHaveVariants: "Sản phẩm này có biến thể không?",
    type: "Kiểu",
    value: "Giá trị",
    pleaseEnterVariantType: "Vui lòng nhập loại biến thể",
    pleaseEnterVariantValue: "Vui lòng nhập giá trị của biến thể",
    pleaseEnterVariantPrice: "Vui lòng nhập giá của biến thể",
    pleaseEnterVariantQuantity: "Vui lòng nhập số lượng của biến thể",
    productDeletedSuccessfully: "Sản phẩm đã được xóa thành công!",
    categoryDeletedSuccessfully: "Danh mục đã được xóa thành công!",
    toDeleteThisProduct: "để xóa Sản phẩm này",
    invalidProductQuantity: "Số lượng sản phẩm không hợp lệ",
    quantityAddedIsOverStock:
      "Số lượng bạn đang thêm nhiều hơn số lượng bạn có trong kho.",
    itemInventoryNotTracked: "Hàng tồn kho không được theo dõi",
    addBulkQuantity: "Thêm số lượng lớn",
    enterBulkQuantity: "Nhập số lượng lớn",
    pleaseEnterBulkQuantity: "Vui lòng nhập số lượng lớn",
    youveSuccessfullyAddedANewProduct:
      "Bạn đã thêm thành công một sản phẩm mới!",
    pleaseEnterProductSellingPrice: "Vui lòng nhập giá bán sản phẩm",
    doYouWantToTrackProductStock: "Bạn có muốn theo dõi kho sản phẩm không?",
    sellingPrice: "Gia ban",
    setProductExpiryReminder: "Đặt lời nhắc hết hạn sản phẩm",
    productExpiryDate: "Ngày hết hạn sản phẩm",
    startRemindingFrom: "Bắt đầu Nhắc nhở Từ",
    productSuppliesAddedSuccessfully:
      "Bạn đã thêm nguồn cung cấp sản phẩm thành công.",
    addProductSupplies: "Thêm nguồn cung cấp sản phẩm",
    pleaseSelectSupplier: "Vui lòng chọn nhà cung cấp",
    nameOfProduct: "Tên sản phẩm",
    pleaseSelectProduct: "Vui lòng chọn sản phẩm",
    productVariant: "Biến thể sản phẩm",
    pleaseSelectAVariant: "Vui lòng chọn một biến thể",
    pleaseEnterUnitPrice: "Vui lòng nhập đơn giá",
    businessBranch: "Chi nhánh kinh doanh",
    pleaseSelectBranch: "Vui lòng chọn chi nhánh",
    youveSuccessfullyAddedANewSupplier:
      "Bạn đã thêm thành công một nhà cung cấp mới",
    addSupplier: "Thêm nhà cung cấp",
    pleaseEnterSupplierEmail: "Vui lòng nhập email của nhà cung cấp",
    pleaseAddADescription: "Vui lòng thêm một mô tả",
    anErrorOccuredProductsDeleted:
      "Đã xảy ra lỗi khi thực hiện thao tác. Xin lưu ý, một số sản phẩm có thể đã bị xóa trong quá trình này",
    bulkDelete: "Xóa hàng loạt",
    youAreAboutToDelete: "Bạn sắp xóa",
    product: "Sản phẩm",
    isDueToRedeem: "là do để mua lại",
    aReward: "Phần thưởng",
    pleaseSelectCustomerToReeemReward:
      "Vui lòng chọn một khách hàng để đổi phần thưởng.",
    youHaveNoLoyaltyProgramRunning:
      "Bạn không có chương trình khách hàng thân thiết đang hoạt động nào đang chạy",
    customerHhasNoPointsInLoyaltyProgram:
      "Khách hàng không có điểm trong chương trình khách hàng thân thiết này",
    proceedWithPayment: "Tiến hành thanh toán?",
    youAreAboutToPayForTransactionUsingPoints:
      "Bạn sắp thanh toán cho giao dịch bằng cách sử dụng điểm.",
    customerHas: "Khách hàng có",
    worth: "đáng giá",
    andIsNotSufficientToPayForTransaction:
      "và không đủ để thanh toán cho giao dịch này. Họ có muốn thêm số dư bằng một phương thức thanh toán khác không?",
    addCustomerLoyalty: "Thêm lòng trung thành của khách hàng",
    pleaseAddCustomerFirst: "Vui lòng thêm hoặc chọn một khách hàng trước.",
    pleaseWaitWhileWeGetReady: "Vui lòng đợi, trong khi chúng tôi sẵn sàng",
    lowStock: "Cổ phiếu thấp",
    pleaseEnterAmountTendered: "Vui lòng nhập số tiền đấu thầu",
    areYouSureToBookSaleOffline:
      "Bạn có chắc chắn muốn đặt trước chương trình giảm giá này ngoại tuyến không?",
    saleWouldBeBookedAutomatically:
      "Giảm giá sẽ được đặt tự động khi bạn bật Internet",
    offlineSalesBookingCancelled: "Đã hủy đặt trước bán hàng ngoại tuyến",
    covid19Message:
      "COVID19: Rửa tay bằng xà phòng hoặc Vệ sinh để ngăn chặn sự lây lan. Luôn an toàn",
    saleSuccessfullyRecorded: "Bán hàng được ghi lại thành công!",
    sendReceiptToEmail: "Gửi biên nhận đến Email",
    sendThankYouSms: "Gửi tin nhắn SMS cảm ơn",
    sendShippingDetails: "Gửi chi tiết vận chuyển",
    addLoyalty: "Thêm lòng trung thành",
    searchCustomerNameOrNumber: "Tìm kiếm tên hoặc số của khách hàng",
    clickTheSearchCustomerBox: "Nhấp vào Hộp khách hàng tìm kiếm và thẻ quét",
    enterProductPrice: "Nhập giá sản phẩm",
    enterPriceFor: "Nhập giá cho",
    searchForProduct: "Tìm kiếm sản phẩm",
    all: "Tất cả các",
    backToDashboard: "Quay lại Trang tổng quan",
    viewDraftSales: "Xem doanh số bán hàng dự thảo",
    variantSelected: "biến thể được chọn",
    variant: "khác nhau",
    thePreviousVariantSelectionNotAvailable:
      "Lựa chọn biến thể trước đó không có sẵn cho biến thể mới được chọn dựa trên giá cả, vui lòng thay đổi lựa chọn của bạn.",
    pleaseSupplyPositiveQuantityNumber: "Vui lòng cung cấp số lượng còn hạn",
    lowStockFor: "Cổ phiếu thấp cho",
    clearVariants: "Các biến thể rõ ràng",
    pleaseEnterQuantity: "Vui lòng nhập số lượng",
    picture: "Bức ảnh",
    redemptionToken: "Mã thông báo đổi thưởng",
    token: "Mã thông báo",
    totalSpent: "Tổng đã dành",
    confirmPayment: "Xác nhận thanh toán",
    hasPaymentBeenMade: "Thanh toán đã được xử lý thành công chưa?",
    enterTransactionReference: "Nhập tham chiếu giao dịch mà bạn đã thanh toán",
    pleaseSelectACustomer: "Vui lòng chọn một khách hàng!",
    areYouSureToApplyDiscount:
      "Bạn có chắc chắn muốn áp dụng chiết khấu không?",
    addYourBankAccountToEnableUssd:
      "Thêm tài khoản ngân hàng của bạn để kích hoạt chuyển USSD tức thì bằng uPay",
    totalAmountToPay: "Tổng số tiền phải trả",
    doYouWantToCancelTransaction: "Bạn có muốn hủy giao dịch này không?",
    savePrintBill: "Lưu / In hóa đơn",
    enterAmountCollectedForCustomer: "Nhập số tiền thu được cho khách hàng",
    recordSale: "Bán kỷ lục",
    checkOutWith: "Hãy kiểm tra với",
    instantTransfer: "Chuyển khoản tức thì",
    dialTheUSSDCode: "Quay số mã USSD",
    pleaseSelectABank: "Vui lòng chọn một ngân hàng",
    payWithUSSD: "Thanh toán bằng USSD",
    sendBillTo: " - Gửi hóa đơn đến",
    waitingForUSSDTransfer: "Đang chờ chuyển USSD",
    percent: "Phần trăm",
    applyDiscount: "Áp dụng giảm giá",
    thisBillHasBeenSaved: "Hóa đơn này đã được lưu",
    saveDraft: "Lưu bản nháp",
    pleaseTypeANameToIdentifyCustomer:
      "Vui lòng nhập tên để xác định khách hàng",
    paymentDetails: "Chi tiết thanh toán",
    basePrice: "Giá cơ bản",
    staff: "Nhân viên",
    subTotal: "Tổng phụ",
    durationMonths: "Thời lượng (tháng)",
    selectADuration: "Chọn thời lượng",
    oneMonth: "1 tháng",
    twoMonths: "2 tháng",
    threeMonths: "3 tháng",
    sixMonths: "6 tháng",
    twelveMonths: "12 tháng",
    eighteenMonths: "18 tháng",
    twentyFourMonths: "24 tháng",
    twoMonthsFree: "(2 tháng miễn phí)",
    threeMonthsFree: "(3 tháng miễn phí)",
    fiveMonthsFree: "(5 tháng miễn phí)",
    yourAccountHasBeen: "Tài khoản của bạn đã được",
    renewed: "đổi mới",
    upgraded: "nâng cấp",
    successfully: "thành công",
    yourSubscription: "Đăng ký của bạn",
    youAreCurrentlyEnrolledOnA: "Bạn hiện đang đăng ký trên một",
    pleaseChooseAPaymentOption: "Vui lòng chọn một tùy chọn thanh toán",
    planRenewal: "Gia hạn gói",
    planUpgrade: "Nâng cấp kế hoạch",
    pleaseSelectDurationToPayFor:
      "Vui lòng chọn khoảng thời gian bạn định thanh toán",
    staffAccounts: "Tài khoản nhân viên",
    ecommerce: "Thương mại điện tử",
    pleaseSelectAPlan: "Vui lòng chọn một kế hoạch",
    includeAddons: "Bao gồm các tiện ích bổ sung",
    viewTransactions: "Xem giao dịch",
    customerHasNoCompletedTansactions:
      "Khách hàng chưa có giao dịch hoàn tất nào",
    branch: "Chi nhánh",
    enterNumberOfEcommerceBranches:
      "Nhập số lượng chi nhánh thương mại điện tử",
    enterNumberOfEcommerceBranchesToPay:
      "Nhập số chi nhánh thương mại điện tử mà bạn dự định thanh toán",
    ecommerceIntegration: "Tích hợp thương mại điện tử",
    enterNumberOfBranches: "Nhập số lượng chi nhánh",
    enterNumberOfAdditionalBranchesToPay:
      "Nhập số chi nhánh bổ sung mà bạn dự định thanh toán",
    enterNumberOfStaffs: "Nhập số lượng nhân viên",
    enterNumberOfStaffsToPayFor: "Nhập số lượng nhân viên bạn định trả",
    discountApplies: "Giảm giá áp dụng",
    starsOnThe: "các ngôi sao trên",
    offer: "phục vụ",
    get: "Hiểu được",
    moreStarsToRedeem: "nhiều sao hơn để đổi",
    taxVat: "Thùng thuế)",
    callCashierToCompletePayment:
      "Gọi Nhân viên thu ngân để hoàn tất thanh toán",
    receipt: "Biên nhận",
    dear: "Thân mến",
    thankYouForYourOrderFindGoods:
      "Cảm ơn bạn đã đặt hàng của bạn. Vui lòng tìm hàng hóa sau đây được cung cấp, theo thỏa thuận.",
    shippingNote: "Lưu ý vận chuyển",
    enterShippingNote: "Nhập ghi chú vận chuyển",
    shippingAddress: "Địa chỉ giao hàng",
    enterShippingAddress: "Nhập địa chỉ giao hàng",
    wellDoneYouAreDueToRedeem: "Làm tốt! Bạn đến hạn đổi",
    toGetYourRewardNextVisit:
      "để nhận được phần thưởng của bạn trong lần truy cập tiếp theo của bạn. Cảm ơn bạn",
    pointsOnThe: "Điểm trên",
    morePointsToRedeem: "nhiều điểm hơn để đổi",
    showCode: "Hiển thị mã",
    toGetYourRewardOnNextVisit:
      "để nhận được phần thưởng của bạn trong lần truy cập tiếp theo của bạn. Cảm ơn bạn",
    earn: "Kiếm",
    delivaryNote: "Ghi chú vi phạm",
    draftSales: "Bán hàng dự thảo",
    startDate: "Ngày bắt đầu",
    endDate: "Ngày cuối",
    orders: "Đơn hàng",
    checkout: "Thủ tục thanh toán",
    noProductItem: "Không có sản phẩm",
    selectProductImage: "Chọn hình ảnh sản phẩm",
    selectCountry: "Chọn quốc gia",
    selectRegion: "Chọn Tiểu bang / Khu vực",
    printProductTag: "In thẻ sản phẩm",
    transactionReference: "Lịch sử giao dịch",
    Cashier: "Thu ngân",
    Manager: "Người quản lý",
    Owner: "Chủ nhân",
    Admin: "quản trị viên",
    addPartners: "Thêm đối tác",
    addNewLoyaltyPartner: "Thêm đối tác trung thành mới",
    pleaseEnterCompanyName: "Vui lòng nhập Tên công ty",
    companyName: "Tên công ty",
    pleaseEnterCompanyRepName: "Vui lòng nhập Tên đại diện công ty",
    companyRepName: "Tên đại diện công ty",
    pleaseEnterCompanyRepEmail: "Vui lòng nhập email của đại diện công ty",
    companyRepEmail: "Email của đại diện công ty",
    pleaseEnterCompanyRepPhone:
      "Vui lòng nhập số điện thoại của đại diện công ty",
    companyRepPhone: "Số điện thoại của đại diện công ty",
    addReward: "Thêm phần thưởng",
    pleaseEnterRewardName: "Vui lòng nhập tên phần thưởng",
    rewardName: "Tên phần thưởng",
    rewardQuantity: "Số lượng phần thưởng",
    rewardDescription: "Mô tả phần thưởng",
    rewardType: "Loại phần thưởng",
    pleaseEnterRewardType: "Vui lòng nhập loại phần thưởng",
    pleaseEnterRewardQuantity: "Vui lòng nhập số lượng phần thưởng",
    pleaseEnterRewardDescription: "Vui lòng nhập mô tả phần thưởng",
    fineDining: "Ăn tối",
    luxuryFashion: "Thời trang sang trọng",
    hotels: "Nhiều khách sạn",
    travel: "Du lịch",
    foodAndBeverage: "Đồ ăn và đồ uống",
    fashion: "Thời trang",
    health: "Sức khỏe",
    furniture: "Đồ nội thất",
    entertainment: "Giải trí",
    automobile: "Ô tô",
    education: "Giáo dục",
    beautyAndSpa: "Làm đẹp và Spa",
    staycation: "Lưu trú",
    events: "Sự kiện",
    trips: "Các chuyến đi",
    oilAndGas: "Dầu khí",
    laundry: "Giặt ủi",
    partnerCategory: "Hạng mục Đối tác",
    freeItem: "Linh kiện miễn phí",
  },
  Welsh: {
    cashier: "ariannwr",
    manager: "rheolwr",
    owner: "perchennog",
    online: "ar-lein",
    offline: "all-lein",
    changePassword: "Newid cyfrinair",
    currentPasswordMessage: "Rhowch eich cyfrinair cyfredol",
    passwordMessage: "Rhowch eich cyfrinair",
    currentPassword: "Cyfrinair Cyfredol",
    password: "cyfrinair",
    confirmPasswordMessage: "Cadarnhewch eich cyfrinair os gwelwch yn dda!",
    confirmPassword: "Cadarnhau Cyfrinair",
    sendViaEmail: "Anfon Trwy E-bost",
    sendViaWhatsapp: "Anfonwch Trwy WhatsApp",
    downloadPdf: "Dadlwythwch PDF",
    paid: "taledig",
    unpaid: "di-dâl",
    partial: "rhannol",
    closeInvoice: "Ydych chi am gau'r Anfoneb?",
    closeInvoiceConfirmation: "Ni chaniateir arbed anfoneb. Ydych chi am gau?",
    yes: "ie",
    no: "na",
    invoice: "Anfoneb",
    wasDeducted: "ei ddidynnu",
    for: "canys",
    item: "Eitem",
    addProduct: "Ychwanegu Cynnyrch",
    paymentReference: "Cyfeirnod Taliad",
    amountPaid: "Swm a Dalwyd",
    discountAmount: "Swm Gostyngiad",
    amountDue: "Swm sy'n ddyledus",
    amount: "Swm",
    dueDate: "Dyddiad Dyladwy",
    paymentType: "Math o Daliad",
    card: "Cerdyn",
    cash: "Arian Parod",
    bankTransfer: "Trosglwyddo Banc",
    paymentMessage: "Neges Taliad",
    description: "Disgrifiad",
    sendReceipt: "Anfon Derbynneb",
    delete: "Dileu",
    save: "Arbedwch",
    resend: "Ail-Anfon",
    saveAndSend: "Arbedwch",
    invoiceSaved: "Anfoneb wedi'i chadw!",
    selectPaymentMethod: "Dewiswch Ddull Taliad!",
    selectCustomer: "Dewiswch Gwsmer!",
    cartEmptyError:
      "Ni all Rhestr Cart fod yn wag, cau'r anfoneb ac ychwanegu Eitem at y drol!",
    subscriptionExpired:
      "Mae eich tanysgrifiad wedi dod i ben ac mae eich cyfrif bellach yn gyfyngedig. Cliciwch ar y Botwm isod i adnewyddu eich cyfrif",
    renew: "Adnewyddu",
    holdOn: "Daliwch ymlaen",
    customerBank: "Banc Cwsmer",
    cancel: "Canslo",
    selectACustomer: "Dewiswch Gwsmer",
    invoiceSuccessfulPdfError:
      "Anfoneb wedi'i chreu'n llwyddiannus ond mae PDF Generation yn cymryd mwy o amser na'r arfer. Gwiriwch yn ôl yn fuan.",
    downloadingInvoice: "Lawrlwytho Anfoneb",
    downloadingReceipt: "Lawrlwytho Derbynneb",
    whatsappReceiptError:
      "Digwyddodd gwall wrth anfon y dderbynneb trwy WhatsApp, Rhowch gynnig arall arni.",
    receiptToWhatsapp: "Derbyniwyd y dderbynneb at WhatsApp",
    thankYouForPatronage: "Diolch am eich nawdd",
    hereIsYourReceipt: "Dyma'ch derbynneb taliad",
    errorSendingEmailReceipt:
      "Digwyddodd gwall wrth anfon y dderbynneb trwy e-bost, ceisiwch eto neu cysylltwch â'r gefnogaeth",
    receiptSentToEmail: "Mae derbynneb wedi'i hanfon at e-bost y cwsmer",
    invoiceSentToEmail: "Anfonwyd anfoneb at e-bost cwsmer",
    invoiceSuccessWhatsappError:
      "Crewyd anfoneb yn llwyddiannus ond digwyddodd gwall wrth anfon at WhatsApp. Rhowch gynnig arall arni ar y rhestr anfonebau",
    invoiceSuccessfulEmailError:
      "Crewyd anfoneb yn llwyddiannus ond digwyddodd gwall wrth anfon fel e-bost. Rhowch gynnig arall ar y rhestr anfonebau",
    invoiceSentToWhatsapp: "Anfonwyd yr anfoneb at WhatsApp",
    hello: "Helo",
    pleaseDownloadInvoice: "Dadlwythwch yr anfoneb",
    pleaseDownloadReceipt: "Dadlwythwch dderbynneb",
    from: "o",
    email: "E-bost",
    upgrade: "Uwchraddio",
    youAreOnFreePlan: "Rydych chi ar Gynllun Am Ddim.",
    clickOn: "Cliciwch ar",
    yourPlanInFewSteps: " eich tanysgrifiad mewn ychydig o gamau cyflym.",
    to: "i",
    yourSubscriptionHasExpired:
      "Mae eich tanysgrifiad wedi dod i ben ac mae eich cyfrif bellach yn gyfyngedig.",
    days: "dyddiau",
    yourSubscriptionExpiresIn: "Mae eich tanysgrifiad Loystar yn dod i ben yn",
    createAcount: "Creu cyfrif",
    signIn: "Mewngofnodi",
    continue: "Parhewch",
    enterOtp: "Rhowch PIN OTP",
    enterValidOtp: "Rhowch PIN dilys",
    pin: "PIN",
    tokenSentToMail: "Mae tocyn wedi'i anfon at eich e-bost",
    passwordResetSuccessful: "Roedd ailosod cyfrinair yn llwyddiannus",
    somethingWentWrong: "Aeth rhywbeth o'i le!",
    resetPassword: "Ailosod cyfrinair",
    iHaveResetCode: "Mae gen i god ailosod cyfrinair",
    pleaseEnterEmail: "Rhowch eich e-bost",
    aTokenWillBeSentToEmail: "Byddai tocyn yn cael ei anfon i'ch e-bost",
    enterEmail: "Rhowch eich e-bost",
    sendinYourToken: "Anfon eich tocyn ...",
    makeSureItMatchesPassword:
      "Sicrhewch ei fod yn cyd-fynd â'ch cyfrinair newydd",
    pleaseChooseNewPassword: "Dewiswch gyfrinair newydd",
    chooseNewPassword: "Dewiswch gyfrinair newydd",
    enterNewPassword: "Rhowch eich cyfrinair newydd i gadarnhau",
    enterTokenSent: "Rhowch y tocyn a anfonwyd at eich post",
    resetToken: "Ailosod Tocyn",
    resettingPassword: "Ailosod eich Cyfrinair ...",
    signUp: "Cofrestru",
    adminSignInWithEmail:
      " Mae gweinyddiaeth yn arwyddo i mewn gydag e-bost tra bod Staff yn arwyddo i mewn gydag enw defnyddiwr.",
    pleaseEnterEmailOrUsername: "Rhowch eich e-bost neu'ch enw defnyddiwr",
    emailOrUsername: "E-bost neu Enw defnyddiwr",
    pleaseEnterPassword: "Rhowch gyfrinair",
    createAnAccount: "Creu cyfrif",
    forgotPassword: "Wedi anghofio cyfrinair?",
    enterPhoneNumber: "Rhowch Rhif Ffôn",
    personalData: "Data personol",
    validateConfirmationCOde: "Dilysu Cod Cadarnhau",
    pleaseEnterFirstName: "Rhowch eich enw cyntaf",
    pleaseEnterPhoneNumber: "Rhowch eich rhif ffôn",
    pleaseEnterLastName: "Rhowch eich enw olaf",
    pleaseEnterBusinessName: "Rhowch enw eich busnes",
    firstName: "Enw cyntaf",
    lastName: "Enw olaf",
    businessName: "Enw Busnes",
    previous: "Blaenorol",
    next: "Nesaf",
    pleaseSelectBusinessCategory: "Dewiswch eich categori busnes",
    pleaseEnterValidEmail: "Rhowch e-bost dilys",
    pleaseEnterPostCode: "Rhowch god post",
    postCode: "Cod Post",
    phoneNumberInUse: "Mae'r rhif ffôn hwn yn cael ei ddefnyddio eisoes!",
    emailInUse: "Mae'r e-bost hwn yn cael ei ddefnyddio eisoes!",
    foodAndDrinks: "Bwyd a Diodydd",
    salonAndBeauty: "Salon a Harddwch",
    fashionAndAccessories: "Ffasiwn ac Affeithwyr",
    gymAndFitness: "Campfa a Ffitrwydd",
    travelAndHotel: "Teithio a Gwesty",
    homeAndGifts: "Cartref ac Anrhegion",
    washingAndCleaning: "Golchi a Glanhau",
    gadgetsAndElectronics: "Gadgets ac Electroneg",
    groceries: "Bwydydd",
    others: "Eraill",
    submit: "Cyflwyno",
    accountCreatedSuccessful: "Crëwyd eich cyfrif yn llwyddiannus.",
    pleaseEnterAddress: "Rhowch Eich Cyfeiriad",
    addressLine1: "Llinell Cyfeiriad 1",
    addressLine2: "Llinell Cyfeiriadau 2",
    choosePassword: "Dewiswch Gyfrinair",
    passwordMustBe6Characters: "Rhaid i'r cyfrinair fod o leiaf 6 nod.",
    howDidYouHearLoystar: "Sut glywsoch chi am Loystar?",
    referralCode: "Cod atgyfeirio",
    byClickingTheButton:
      " Trwy glicio ar y botwm isod, rydych chi'n cytuno i'r",
    termsAndSevice: "Telerau",
    wereCreatingAccount: "Rydyn ni'n creu eich cyfrif",
    proceed: "Ewch ymlaen",
    verificationCodeMustBe6: "Rhaid i'r Cod Gwirio fod yn 6 digid",
    pleaseEnter6DigitCode: "Rhowch god 6 digid",
    enterVerificationCode: "Rhowch y Cod Gwirio",
    resendToken: "Resend Token",
    verify: "Gwirio",
    transactions: "Trafodion",
    todaySales: "Gwerthiannau Heddiw",
    salesHistory: "Hanes Gwerthu",
    supplyHistory: "Hanes Cyflenwi",
    new: "Newydd",
    invoices: "Anfonebau",
    disbursements: "Taliadau",
    offlineSales: "Gwerthiannau All-lein",
    products: "Cynhyrchion",
    customers: "Cwsmeriaid",
    multiLevelLoyalty: "Teyrngarwch Aml-Lefel",
    loyaltyPrograms: "Rhaglenni Teyrngarwch",
    members: "Aelodau",
    appStore: "Siop app",
    orderMenu: "Dewislen Archebu",
    settings: "Gosodiadau",
    staffAndBranches: "Staff a Changhennau",
    myAccount: "Fy nghyfrif",
    switchToSellMode: "Newid i'r Modd Gwerthu",
    signOut: "Llofnodi",
    getFreeSubscription: "Cael Tanysgrifiad Am Ddim",
    onlyNumbersAllowed: "Dim ond rhifau a ganiateir",
    yourAccountMustBe10Digits: "rhaid i'ch rhif cyfrif fod yn rhif 10 digid",
    yourBvnMustBe11: "rhaid i'ch BVN fod yn rhif 11 digid",
    pleaseSelectBank: "Dewiswch eich banc",
    selectYourBank: "Dewiswch eich Banc",
    enterBankAccountNumber: "Rhowch Rhif Cyfrif Banc",
    enterBvnNumber: "Rhowch eich BVN i mewn",
    connectBankAccount: "Cysylltu Cyfrif Banc",
    passwordResetSuccessfulAndSignOut:
      "Mae eich Cyfrinair wedi'i ailosod yn llwyddiannus. Cliciwch ar y botwm Sign Out isod i fewngofnodi eto",
    enterCurrentPassword: "Rhowch gyfrinair cyfredol",
    pleaseEnterCurrentPassword: "Rhowch y cyfrinair cyfredol",
    phoneNumber: "Rhif ffôn",
    sex: "Rhyw",
    dateOfBirth: "Dyddiad Geni",
    state: "Nodwch",
    country: "Gwlad",
    loyaltyId: "ID Teyrngarwch",
    createdAt: "Wedi'i greu yn",
    noOfTransactionsMade: "Nifer y trafodion a wnaed",
    yourDownloadWillStart: "Bydd eich dadlwythiad yn cychwyn mewn eiliad",
    exportCustomers: "Cwsmeriaid Allforio",
    youhaveSuccessfullyToppedUp:
      "Rydych chi wedi ychwanegu at eich Unedau SMS yn llwyddiannus.",
    youNowHave: "Mae gennych chi nawr",
    smsUnits: "Unedau SMS",
    enterNumberOfSmsUnits:
      "Rhowch Nifer yr Unedau SMS rydych chi'n bwriadu eu Prynu",
    pleaseEnterANumericValue: "Rhowch werth rhifol",
    pay: "Tâl",
    accountEditedSuccessfully: "Golygwyd eich cyfrif yn llwyddiannus.",
    youAeCurrentlyOn: "Rydych chi ymlaen ar hyn o bryd",
    plan: "Cynllun",
    userData: "Data Defnyddiwr",
    businessData: "DATA BUSNES",
    businessCategory: "Categori Busnes",
    pleaseSelectCurrency: "Dewiswch eich arian cyfred",
    selectYourCurrency: "Dewiswch eich arian cyfred",
    purchaseMoreSmsUnits:
      "Prynu mwy o Unedau SMS gan ddefnyddio'r ffurflen isod",
    youHave: "Mae gennych chi",
    atLeast4SmsUnitsRrequired:
      "Mae angen o leiaf 4 uned sms i'w gwirio, ychwanegwch at eich gilydd!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Gwiriwch eich cyfrif banc i gydymffurfio â'r gofynion 'Gwybod Eich Cwsmer' (KYC). Bydd hyn yn caniatáu ichi gasglu taliadau trwy USSD neu drosglwyddo ar unwaith, derbyn archebion gan gwsmeriaid a phrosesu setliad trafodion. Yn berthnasol i fasnachwyr o Nigeria yn unig. Pwyswch y botwm isod i ddechrau.",
    reConnectBankAccount: "Ail-gysylltu Cyfrif Banc",
    accountName: "Enw cyfrif",
    accountNumber: "Rhif cyfrif",
    bankName: "Enw Banc",
    verified: "Wedi'i ddilysu",
    accountDetails: "Manylion y Cyfrif",
    kycBankAccount: "KYC",
    createTier: "Creu Haen",
    fileUploadSuccessful: "ffeil wedi'i llwytho i fyny yn llwyddiannus",
    fileUploadFailed: "methodd uwchlwytho ffeiliau",
    createLoyaltyProgram: "Creu Rhaglen Teyrngarwch",
    createLoyalty: "Creu Teyrngarwch",
    name: "Enw",
    loyaltyArtwork: "Gwaith Celf Teyrngarwch",
    clickToUpload: "Cliciwch i uwchlwytho",
    amountToPointsRatio: "Swm i'r Gymhareb Pwyntiau",
    points: "Pwyntiau",
    recommendedAmountToPointRatio:
      "Argymhellir: ₦ 1 i 1 pwynt. i.e am bob 1 Naira a wariwyd, mae eich cwsmeriaid yn cael 1 pwynt",
    pleaseTypeIn: "Teipiwch i mewn",
    toDeleteLoyalty: "i ddileu'r teyrngarwch hwn",
    deleteLoyalty: "Dileu Teyrngarwch",
    toConfirm: "i gadarnhau",
    edit: "Golygu",
    pointsAwardedSuccessfully: "Pwyntiau a Ddyfarnwyd yn Llwyddiannus.",
    enterPointValueToAward:
      "Rhowch Werth Pwynt yr hoffech ei Ddyfarnu Cwsmeriaid",
    award: "Gwobr",
    awardPointValuesToCustomer: "Gwerth Pwynt Dyfarnu i'r Cwsmer",
    enrollMembersToLoyalty: "Cofrestru Aelodau ar Deyrngarwch",
    awardPoints: "Pwyntiau Gwobrwyo",
    enroll: "Cofrestrwch",
    home: "Hafan",
    loyalty: "Teyrngarwch",
    enrollCustomers: "Cofrestru Cwsmeriaid",
    selected: "Wedi'i ddewis",
    customer: "Cwsmer",
    loyaltyActivationSuccessful: "Mae Actifadu Teyrngarwch yn llwyddiannus.",
    loyaltyDeactivationSuccessful:
      "Mae Deactivation Teyrngarwch yn llwyddiannus.",
    viewTier: "Gweld Haen",
    deactivate: "Dad-actifadu",
    activate: "Activate",
    actions: "Camau gweithredu",
    nameOfLoyalty: "Enw'r Teyrngarwch",
    loyaltyStatus: "Statws Teyrngarwch Statws Teyrngarwch",
    numberOfTiers: "Nifer yr Haenau",
    createdOn: "Wedi'i greu",
    createATier: "Creu Haen",
    stopCreatingTierConfirmation: "Ydych chi am roi'r gorau i greu haen?",
    stopEditingTierConfirmation: "Ydych chi am roi'r gorau i olygu'r haen hon?",
    nameOfTier: "Enw'r Haen",
    minimumSpendingTarget: "Isafswm y Targed Gwariant",
    maximumSpendingTarget: "Uchafswm y Targed Gwariant",
    minimumSpendingTargetRequired: "gofynnir am darged gwariant lleiaf",
    maximumSpendingTargetRequired: "gofynnir am y targed gwariant uchaf",
    rewardSponsor: "Noddwr Gwobrwyo",
    pleaseChooseARewardSponsor: "Dewiswch noddwr gwobrwyo",
    self: "Hunan",
    partner: "Partner",
    rewardPartner: "Partner Gwobrwyo",
    pleaseSelectRewardPartner: "Dewiswch eich partner gwobrwyo",
    rewards: "Gwobrwyon",
    pleaseSelectAReward: "Dewiswch wobr",
    instructionsOnRedeemingReward:
      "Cyfarwyddiadau ar Sut y Dylai Cwsmer Ail-ddyfarnu Gwobrwyo",
    pleaseFillInThisField: "Llenwch y Maes hwn os gwelwch yn dda!",
    toDeleteThisTier: " i ddileu'r haen hon",
    deleteTier: "Dileu Haen",
    viewMembers: "Gweld yr Aelodau",
    membersEnrolled: "Aelodau wedi'u Cofrestru",
    instruction: "Cyfarwyddyd",
    membersIn: "Aelodau yn",
    availableTiersInLoyalty: "Haen (au) sydd ar gael yn y Rhaglen Teyrngarwch",
    tiers: "Haenau",
    totalTier: "CYFANSWM TIER",
    availableLoyaltyProgramme: "Rhaglen Teyrngarwch Ar Gael",
    totalLoyalties: "CYFANSWM LLYTHYRAU",
    memberDetails: "Manylion yr Aelod",
    nameOfCustomer: "Enw'r Cwsmer",
    address: "Cyfeiriad",
    allEnrolledMembers: "Pob Aelod Cofrestredig",
    thisIsToWishYouHappyBirthday:
      "Mae hyn er mwyn dymuno pen-blwydd hapus iawn a bywyd llewyrchus i chi. Diolch am bopeth yr ydych i Loyster. Dathliadau Hapus!",
    inputAnOfferPlease: "Mewnbwn Cynnig os gwelwch yn dda",
    pleaseSelectTheInsertPoint:
      "Dewiswch y pwynt mewnosod yn y neges a chlicio eto",
    birthdayOfferAndMessage: "Cynnig a Neges Pen-blwydd",
    birthdayOffer: "Cynnig Pen-blwydd",
    birthdayMessage: "Neges Pen-blwydd",
    noOfferFound: "Ni chafwyd cynnig",
    settingABirthdayOffer:
      "Mae gosod cynnig pen-blwydd yn caniatáu i gwsmeriaid dderbyn y cynnig hwn trwy SMS ar eu pen-blwydd",
    createOffer: "Creu Cynnig",
    whatIsTheOffer: "Beth yw'r cynnig?",
    editMessage: "Golygu Neges",
    insert: "Mewnosod",
    theNameOfCustomerInserted: "Bydd enw'r cwsmer yn cael ei fewnosod yma",
    theBirtdayOfferInserted: "Bydd y cynnig pen-blwydd yn cael ei fewnosod yma",
    youSuccessfullyAddedNewBranch:
      "Rydych chi wedi ychwanegu cangen newydd yn llwyddiannus!",
    addNewBranch: "Ychwanegu Cangen Newydd",
    addBranch: "Ychwanegu Cangen",
    additionalBranchWillIncur: "Byddai Cangen Ychwanegol yn ysgwyddo",
    perBranch: "y gangen",
    ecommerceBranchCosts: "Costau Cangen E-Fasnach",
    pleaseEnterBranchName: "Rhowch enw'r gangen",
    pleaseEnterBranchAddress1: "Rhowch linell gyfeiriadau 1 y gangen",
    enterBranchAddress1: "Rhowch linell gyfeiriadau 1 y gangen",
    enterBranchAddress2: "Rhowch linell gyfeiriadau 1 y gangen",
    pleaseEnterBranchAddress2: "Rhowch linell gyfeiriadau 2 y gangen",
    enterBranchName: "Rhowch enw'r gangen",
    successfullyAddedStaff:
      "Rydych chi wedi ychwanegu staff newydd yn llwyddiannus!",
    addNewStaff: "Ychwanegu Staff Newydd",
    addStaff: "Ychwanegu Staff",
    additionalStaffWillIncur: "Byddai Staff Ychwanegol yn ysgwyddo",
    perStaff: "fesul staff.",
    pleaseEnterStaffEmail: "Rhowch e-bost y staff",
    pleaseEnterStaffUsername: "Rhowch enw defnyddiwr y staff",
    pleaseEnterStaffPassword: "Rhowch gyfrinair y staff",
    pleaseSelectStaffRole: "Dewiswch rôl y staff",
    selectStaffRole: "Dewiswch rôl y staff",
    enterStaffEmail: "Rhowch e-bost y staff",
    enterStaffUsername: "Rhowch enw defnyddiwr y staff",
    enterStaffPassword: "Rhowch gyfrinair y staff",
    spacesNotAllowedInUsername: "lleoedd na chaniateir yn yr enw defnyddiwr",
    admin: "Gweinyddiaeth",
    pleaseSelectBusinessToAttachStaff: "Dewiswch fusnes i atodi staff",
    searchForBranchToAttachStaff: "Chwilio am gangen i atodi staff",
    username: "Enw defnyddiwr",
    role: "Rôl",
    areYouSureToDeleteThis: "Ydych chi'n sicr o ddileu hyn",
    branches: "Canghennau",
    upgradeYourPlan: "Uwchraddio'ch Cynllun.",
    add: "YCHWANEGU",
    addNew: "Ychwanegu Newydd",
    customerWithEmailAlreadyExists:
      "Mae cwsmer sydd ag e-bost / rhif ffôn eisoes yn bodoli!",
    successfullyAddedNewCustomer:
      "Rydych chi wedi ychwanegu cwsmer newydd yn llwyddiannus!",
    addCustomer: "Ychwanegu Cwsmer",
    pleaseEnterCustomerFirstName: "Rhowch enw cyntaf y cwsmer",
    pleaseEnterCustomerLastName: "Rhowch enw olaf y cwsmer",
    pleaseEnterCustomerPhoneNumber: "Rhowch rif ffôn y cwsmer",
    pleaseEnterCustomerEmail: "Rhowch e-bost cwsmer",
    pleaseEnterCustomerAddressLine: "Rhowch Linell Cyfeiriadau'r cwsmer",
    pleaseEnterCustomerOtherAddress: "Rhowch gyfeiriad arall y cwsmer",
    pleaseSelectCustomerGender: "Dewiswch ryw'r cwsmer",
    gender: "Rhyw",
    male: "Gwryw",
    female: "Benyw",
    bank: "Banc",
    selectBank: "Dewis Banc",
    stateOrRegionOrProvince: "Gwladwriaeth / Rhanbarth / Talaith",
    customerNotes: "Nodiadau Cwsmer",
    sendSms: "Anfon SMS",
    editCustomer: "Golygu Cwsmer",
    redeemReward: "Gwobrwyo Gwared",
    issueLoyaltyCard: "Cyhoeddi Cerdyn Teyrngarwch",
    deleteCustomer: "Dileu Cwsmer",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Rydych chi wedi aseinio ID Aelodaeth Teyrngarwch yn llwyddiannus",
    noMembershipIdAvailable:
      "Nid oes IDau aelodaeth ar gael. Cysylltwch â ni ar hello@loystar.co",
    sendEmail: "Anfon e-bost",
    membershipPoints: "Pwyntiau Aelodaeth",
    customerDetails: "Manylion Cwsmer",
    close: "Caewch",
    loyaltyBalance: "Balans Teyrngarwch",
    pointsBalance: "Balans Pwyntiau",
    starBalance: "Balans Seren",
    requiredPoints: "Pwyntiau Gofynnol",
    requiredStars: "Sêr Angenrheidiol",
    reddemCode: "Cod Gwaredu",
    toDeleteThisCustomer: "i ddileu'r cwsmer hwn",
    customerHasBeenDeletedSuccessfully:
      "Mae'r cwsmer wedi'i ddileu yn llwyddiannus!",
    customerWithPhoneAlreadyExists:
      "Mae cwsmer gyda rhif ffôn eisoes yn bodoli",
    customerWasSuccessfullyEdited: "Golygwyd y cwsmer yn llwyddiannus",
    anErrorOccured: "Digwyddodd gwall",
    phoneNumberIncludeCountry: "Rhif Ffôn (Cynhwyswch God Gwlad)",
    yourFileQueuedForUpload:
      "Mae'ch ffeil wedi'i chiwio i'w huwchlwytho. Adnewyddwch y dudalen ar ôl ychydig eiliadau.",
    thereWasAnErrorPerformingOperation:
      "Roedd gwall yn cyflawni'r llawdriniaeth!",
    pleaseSelectFile: "Dewiswch Ffeil os gwelwch yn dda!",
    oopsOnlyCsvFilesAllowed:
      "Wps! Dim ond ffeiliau CSV a ganiateir. Llwythwch ffeil .csv i fyny.",
    fileShouldBeSmallerThan5Mb:
      "Dylai'r ffeil fod yn llai na 5MB. Os oes gennych ffeil fwy, e-bostiwch support@loystar.co. Diolch",
    customerFirstNameIsRequired: "mae angen enw cyntaf y cwsmer yn olynol",
    customerPhoneNumberIsRequired: "mae angen rhif ffôn cwsmer yn olynol",
    importCustomers: "Cwsmeriaid Mewnforio",
    upload: "Llwythiad i fyny",
    clickIconToDownloadCsv:
      "Cliciwch yr eicon hwn i lawrlwytho templed ffeil CSV.",
    getGoogleSheetVersion: "Sicrhewch fersiwn dalen Google",
    clickOrDragFileToUpload:
      "Cliciwch neu llusgwch ffeil i'r ardal hon i'w llwytho i fyny",
    missingOrInvalidColumnHeader:
      "Pennawd colofn ar goll neu annilys. Dilynwch fformat y templed. Diolch.",
    youHaveImported: "Rydych chi wedi Mewnforio",
    youSuccessfullyRedeemedReward:
      "Rydych chi wedi ad-dalu'ch gwobr yn llwyddiannus!",
    sixDigitCode: "Cod chwe digid",
    pleaseEnterCustomerRewardCode: "Rhowch god gwobr y cwsmer",
    enterRewardCode:
      "Rhowch god gwobr. (Mae'r cod gwobrwyo yn sensitif i achosion)",
    selectLoyaltyProgram: "Dewiswch raglen ffyddlondeb",
    stamps: "Stampiau",
    smsUnitsLow: "Unedau SMS Isel",
    pleaseTopUpSmsUnits: "Ychwanegwch Unedau SMS",
    smsSuccessfullySentForDelivery: "Anfonwyd SMS yn llwyddiannus i'w ddanfon!",
    sendSmsTo: "Anfon SMS i",
    allCustomers: "Pob Cwsmer",
    unitsAvailable: "Unedau Ar Gael",
    pleaseTypeInTheMessage: "Teipiwch y neges i mewn",
    message: "Neges",
    charactersRemaining: "cymeriadau ar ôl",
    avoidUseOfSpecialCharacters:
      "Osgoi defnyddio Cymeriadau Arbennig ac Emojis i warchod Unedau SMS.",
    note: "Nodyn",
    errorFetchingCustomersMultilevelDetail:
      "Gwall Yn Cipio Manylion Aml-lefel Cwsmeriaid",
    search: "Chwilio",
    reset: "Ail gychwyn",
    importCustomer: "Cwsmer Mewnforio",
    addNewCustomer: "Ychwanegu Cwsmer Newydd",
    sendSmsBroadcast: "Anfon Darllediad SMS",
    totalCustomers: "CYFANSWM CWSMERIAID",
    disbursementDetails: "Manylion Dosbarthu",
    paidBy: "Talwyd Gan",
    disbursed: "Wedi'i dalu",
    bankAccountName: "Enw'r Cyfrif Banc",
    bankAccountNumber: "Rhif Cyfrif Banc",
    transferInitiated: "Trosglwyddo Cychwynnwyd",
    transferCompleted: "Trosglwyddo Wedi'i Gwblhau",
    pleaseEnterAValid: "Rhowch ddilys",
    begin: "dechrau",
    end: "diwedd",
    date: "dyddiad",
    paymentDate: "Dyddiad talu",
    selectDisbursementDuration: "Dewiswch Hyd y Taliad",
    totalSettled: "Cyfanswm Wedi'i setlo",
    totalUnsettled: "Cyfanswm Heb ei Osod",
    toDeleteThisSale: "i ddileu'r gwerthiant hwn",
    draftSaleDeletedSuccessfully:
      "Mae Arwerthiant Drafft wedi'i ddileu yn llwyddiannus!",
    deleteSale: "Dileu Gwerthu",
    pleaseTypeInYourTotal: "Teipiwch eich cyfanswm i gadarnhau'r dileu",
    billDetails: "Manylion y Bil",
    printBill: "Mesur Argraffu",
    servedBy: "Gwasanaethir gan",
    total: "Cyfanswm",
    createdDate: "Dyddiad Creu",
    createdTime: "Amser wedi'i Greu",
    status: "Statws",
    loginSuccessful: "Mewngofnodi yn Llwyddiannus",
    pleaseWaitWhileWeConnectAccount: "Arhoswch wrth i ni gysylltu'ch cyfrif",
    connectionFailedTryAgain:
      "Methodd y cysylltiad. Trio eto os gwelwch yn dda.",
    connectionSuccessful: "Cysylltiad yn Llwyddiannus",
    viewDetails: "Gweld y Manylion",
    enable: "Galluogi",
    free: "Am ddim",
    cost: "Cost",
    visitWebsite: "Ewch i'r Wefan",
    pleaseUpgradeYourPlanToPro:
      "Uwchraddiwch eich cynllun i Pro Plus i alluogi'r app hon",
    connectYourBankAccount:
      "Cysylltwch eich Cyfrif Banc i allu derbyn taliadau.",
    disable: "Analluoga",
    enableApp: "Galluogi Ap",
    addNewProductToInvoice: "Ychwanegu Cynnyrch Newydd i'r Anfoneb",
    toDeleteThisInvoice: "i ddileu'r Anfoneb hon",
    invoiceDeletedSuccessfully: "Mae'r anfoneb wedi'i dileu yn llwyddiannus!",
    deleteInvoice: "Dileu Anfoneb",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Rydych chi wedi ychwanegu neges talu Anfoneb yn llwyddiannus!",
    pleaseEnterPaymentInstructions: "Rhowch gyfarwyddiadau talu",
    invoiceId: "ID yr anfoneb",
    paidDate: "Dyddiad taledig",
    reference: "Cyfeirnod",
    productAdded: "Ychwanegwyd y cynnyrch",
    productOutOfStock: "Cynnyrch allan o stoc. Ail-stociwch os gwelwch yn dda.",
    totalInvoices: "CYFANSWM YMCHWILIADAU",
    totalPaidInvoices: "CYFANSWM INVOICES A DALWYD",
    totalUnpaidInvoices: "CYFANSWM INVOICES UNPAID",
    loyaltyProgramDeleted: "Dileu'r Rhaglen Teyrngarwch",
    thisLoyaltyProgramDeletedSuccessfully:
      "Mae'r rhaglen ffyddlondeb hon wedi'i dileu yn llwyddiannus",
    thisLoyaltyProgramEditedSuccessfully:
      "Mae'r rhaglen ffyddlondeb hon wedi'i golygu'n llwyddiannus",
    loyaltyProgramAddedSuccessfully:
      "Ychwanegwyd rhaglen deyrngarwch yn llwyddiannus",
    loyaltyProgramEdited: "Golygwyd y Rhaglen Teyrngarwch",
    loyaltyProgramAdded: "Ychwanegwyd Rhaglen Teyrngarwch",
    loyaltyDetails: "Manylion Teyrngarwch",
    doYouWantToCloseDialog: "Ydych chi am gau'r ymgom hwn?",
    pleaseEnterLoyaltyName: "Rhowch enw eich teyrngarwch",
    programType: "Math o Raglen",
    pleaseSelectProgramType: "Dewiswch fath o raglen",
    simplePoints: "Pwyntiau Syml",
    stampsProgram: "Rhaglen Stampiau",
    threshold: "Trothwy",
    pleaseEnterLoyaltyThreshold: "Rhowch y trothwy teyrngarwch",
    reward: "Gwobr",
    pleaseEnterLoyaltyReward: "Rhowch wobr y teyrngarwch",
    totalUserPoints: "Cyfanswm Pwyntiau Defnyddwyr",
    totalUserStamps: "Cyfanswm Stampiau Defnyddwyr",
    spendingTarget: "Targed gwario",
    spendingTargetHint1:
      "Targed gwariant yw faint y mae'n rhaid i gwsmer ei wario i ennill y wobr. 1 gwerth arian cyfred = 1 pwynt.",
    spendingTargetHint2:
      "Targed stampiau yw faint o stampiau y mae'n rhaid i gwsmer eu casglu i ennill y wobr. ee. 5",
    addNewLoyaltyProgram: "Ychwanegu Rhaglen Teyrngarwch Newydd",
    addLoyaltyProgram: "Ychwanegu Rhaglen Teyrngarwch",
    loyaltyProgramType: "Math o Raglen Teyrngarwch",
    pleaseSelectLoyaltyProgramType: "Dewiswch y math o raglen ffyddlondeb",
    nameOfProgram: "Enw'r rhaglen",
    pleaseEnterProgramName: "Rhowch enw'r rhaglen",
    whatIsTheReward: "Beth yw'r wobr?",
    egNextPurchaseIsFree: "E.e. Mae'r pryniant nesaf yn rhad ac am ddim",
    customerName: "Enw Cwsmer",
    guestCustomer: "Cwsmer Gwadd",
    orderConfirmedSuccessfully: "Gorchymyn wedi'i gadarnhau'n llwyddiannus",
    orderCancelledSuccessfully: "Gorchymyn wedi'i ganslo'n llwyddiannus",
    confirmOrder: "Cadarnhau gorchymyn",
    cancelOrder: "Canslo archeb",
    allOrders: "Pob Gorchymyn",
    totalOrders: "CYFANSWM GORCHMYNION",
    doYouWantToAcceptOrder: "Ydych chi am dderbyn y gorchymyn hwn?",
    doYouWantToCancelOrder: "Ydych chi am ganslo'r gorchymyn hwn?",
    orderDetails: "Manylion y Gorchymyn",
    orderCreatedAt: "Gorchymyn wedi'i greu yn",
    supplier: "Cyflenwr",
    productName: "Enw Cynnyrch",
    quantity: "Nifer",
    unitPrice: "Pris Uned",
    receivedBy: "Derbyniwyd Gan",
    reportFrom: "Adroddiad gan",
    totalSupplies: "Cyfanswm y Cyflenwadau",
    allRightsReserved: "Cedwir Pob Hawl",
    toDeleteThisTransaction: "i ddileu'r Trafodiad hwn",
    transactionDeletedSuccessfully:
      "Mae'r trafodiad wedi'i ddileu yn llwyddiannus. Dychwelwyd stoc i'r rhestr eiddo.",
    deleteTransaction: "Dileu Trafodiad",
    transactionDetails: "Manylion y Trafodiad",
    printReceipt: "Derbynneb Argraffu",
    channel: "Sianel",
    discount: "Disgownt",
    profit: "Elw",
    discountedSales: "Gwerthiannau Gostyngedig",
    errorFetchingRecord: "Cofnod Fetching Gwall",
    exportTransactions: "Trafodion Allforio",
    errorFetchingSalesRecord: "Gwall yn nôl Cofnod gwerthu ar gyfer Allforio.",
    totalSellingPrice: "Cyfanswm y Pris Gwerthu",
    totalCostPrice: "Cyfanswm Pris y Gost",
    appliedDiscount: "Gostyngiad Cymhwysol",
    noOfItems: "Nifer yr Eitemau",
    sales: "Gwerthu",
    export: "Allforio",
    totalProfit: "Cyfanswm Elw",
    totalBalanceInPeriod: "Cyfanswm y Balans yn y Cyfnod",
    allTimeSales: "Pob Gwerthiant Amser",
    records: "Cofnodion",
    todaysSales: "Gwerthiannau Heddiw",
    transaction: "trafodiad",
    youHaveExceededTotalNumberOfProducts:
      "Rydych wedi rhagori ar gyfanswm y cynhyrchion a ganiateir ar eich cynllun. Uwchraddio'ch cynllun i fwynhau terfyn uwch.",
    youNeedToHaveLoyaltyProgram:
      "Mae angen i chi gael Rhaglen Teyrngarwch i ddefnyddio'r nodwedd hon!",
    price: "Pris",
    category: "Categori",
    stockTracking: "Olrhain Stoc",
    stockCount: "Cyfrif Stoc",
    taxed: "Trethir",
    originalPrice: "Pris Gwreiddiol",
    costPrice: "Pris Cost",
    unit: "Uned",
    productImage: "Delwedd Cynnyrch",
    taxRate: "Gyfradd dreth",
    taxType: "Math o Dreth",
    trackInventory: "Rhestr Trac",
    variants: "Amrywiadau",
    hasVariants: "Mae ganddo Amrywiadau",
    importProduct: "Cynnyrch Mewnforio",
    exportProducts: "Cynhyrchion Allforio",
    addNewProduct: "Ychwanegu Cynnyrch Newydd",
    viewCategory: "Gweld Categori",
    viewSuppliers: "Gweld Cyflenwyr",
    receiveInventory: "Derbyn Rhestr",
    printAllProductsTag: "Argraffu Pob Cynnyrch",
    deleteAll: "Dileu Pawb",
    totalProducts: "CYFANSWM CYNHYRCHION",
    youveSuccessfullyAddedANewCategory:
      "Rydych chi wedi ychwanegu categori newydd yn llwyddiannus",
    addNewCategory: "Ychwanegu Categori Newydd",
    addCategory: "Ychwanegu Categori",
    categoryName: "Enw Categori",
    pleaseEnterCategoryName: "Rhowch enw'r categori",
    stampsTarget: "Targed Stampiau",
    sendInventory: "Anfon Rhestr",
    productDetails: "Manylion Cynnyrch",
    youveSuccessfullyEditedThisCategory:
      "Rydych chi wedi golygu'r categori hwn yn llwyddiannus",
    update: "Diweddariad",
    categoryList: "Rhestr Categori",
    categories: "Categorïau",
    enterQuantityToUpdate: "Rhowch swm i'w ddiweddaru",
    inventorySentSuccessfully: "Anfonwyd y rhestr eiddo yn llwyddiannus!",
    updateInventory: "Diweddaru'r Rhestr",
    currentQuantity: "Nifer Cyfredol",
    pleaseEnterQuantityToAdd: "Rhowch y swm rydych chi am ei ychwanegu",
    pleaseSelectABranch: "Dewiswch Gangen",
    searchForBranch: "Chwilio am gangen",
    youCantSendMoreThanStock: "Ni allwch anfon mwy nag sydd gennych mewn stoc",
    send: "Anfon",
    pleaseEnterQuantityToSend: "Rhowch y swm rydych chi am ei anfon",
    productNameIsRequiredOnRow: "mae angen enw'r cynnyrch yn olynol",
    productCategoryIsRequiredOnRow: "mae angen categori cynnyrch yn olynol",
    productPriceIsRequiredOnRow: "mae angen pris cynnyrch yn olynol",
    productUnitIsRequiredOnRow: "mae angen uned cynnyrch yn olynol",
    productQuantityIsRequiredOnRow: "mae angen maint y cynnyrch yn olynol",
    productVariantsRequireTracking: "mae angen olrhain amrywiadau cynnyrch!",
    pleaseAddVariantClickButton:
      "Ychwanegwch amrywiad trwy glicio ar y botwm ychwanegu!",
    totalVariantsMoreThanSelectedQuantity:
      "Mae Cyfanswm Amrywiadau yn fwy na maint cynnyrch dethol, mae Pls yn lleihau maint amrywiolion",
    productEditedSuccessfully: "Mae'r cynnyrch wedi'i olygu'n llwyddiannus!",
    fileTooLargeLessThan4Mb:
      "Mae maint y ffeil yn rhy fawr! Dylai maint y ffeil fod yn llai na 4MB.",
    suchVariantAlreadyExist: "Mae amrywiad o'r fath yn bodoli eisoes",
    addVariants: "Ychwanegu Amrywiadau",
    editProduct: "Golygu Cynnyrch",
    pleaseEnterProductName: "Rhowch Enw'r Cynnyrch",
    pleaseEnterProductPrice: "Rhowch Bris y Cynnyrch",
    pleaseEnterProductOriginalPrice: "Rhowch Bris Gwreiddiol y Cynnyrch",
    productDescription: "Disgrifiad o'r Cynnyrch",
    selectProductCategory: "Dewiswch Categori Cynnyrch",
    pleaseSelectProductCategory: "Dewiswch gategori cynnyrch",
    productCostPrice: "Pris Cost Cynnyrch",
    productSellingPrice: "Pris Gwerthu Cynnyrch",
    productOriginalPrice: "Pris Gwreiddiol y Cynnyrch",
    maxFileSizeAllowedIs4Mb: "Max. Y maint ffeil a ganiateir yw 4mb",
    productsWithPicturesArePublished:
      "Cyhoeddir cynhyrchion â lluniau ar Discover i dderbyn archebion",
    shouldThisProductBeTracked: "A ddylid olrhain y cynnyrch hwn?",
    pleaseSelectStockUnit: "Dewiswch uned stoc",
    stockUnit: "Uned Stoc",
    bag: "BAG",
    bottle: "BOTTLE",
    bunch: "BUNCH",
    can: "GALL",
    carton: "CARTON",
    crate: "CRATE",
    cup: "CUP",
    dozen: "DOZEN",
    gigabytes: "GIGABYTES",
    gram: "GRAM",
    kilogram: "KILOGRAM",
    litre: "LLENYDD",
    pack: "PECYN",
    pair: "PAIR",
    pieces: "PIECES",
    plate: "LLEOEDD",
    tonne: "TONNE (MT)",
    uNIT: "UNED",
    yard: "IARD",
    pleaseEnterProductQuantity: "Rhowch faint y cynnyrch",
    productQuantity: "Nifer y Cynnyrch",
    isThisProductTaxed: "A yw'r cynnyrch hwn yn cael ei drethu?",
    selectTaxType: "Dewiswch Math o Dreth",
    pleaseSelectTaxType: "Dewiswch Math o Dreth",
    progressive: "Blaengar",
    proportional: "Cyfrannol",
    pleaseEnterProductTaxRate: "Rhowch gyfradd treth cynnyrch",
    doesProductHaveVariants: "A oes gan y cynnyrch hwn amrywiadau?",
    type: "Math",
    value: "Gwerth",
    pleaseEnterVariantType: "Rhowch fath amrywiad",
    pleaseEnterVariantValue: "Rhowch werth amrywiad",
    pleaseEnterVariantPrice: "Rhowch bris amrywiad",
    pleaseEnterVariantQuantity: "Rhowch faint amrywiad",
    productDeletedSuccessfully: "Mae'r cynnyrch wedi'i ddileu yn llwyddiannus!",
    categoryDeletedSuccessfully:
      "Mae'r categori wedi'i ddileu yn llwyddiannus!",
    toDeleteThisProduct: "i ddileu'r Cynnyrch hwn",
    invalidProductQuantity: "Nifer Cynnyrch Annilys",
    quantityAddedIsOverStock:
      "Mae'r swm rydych chi'n ei ychwanegu dros yr hyn sydd gennych chi mewn stoc.",
    itemInventoryNotTracked: "Nid yw rhestr eiddo yn cael ei olrhain",
    addBulkQuantity: "Ychwanegu Meintiau Swmp",
    enterBulkQuantity: "Rhowch Swmp Meintiau",
    pleaseEnterBulkQuantity: "Rhowch Swmp Meintiau",
    youveSuccessfullyAddedANewProduct:
      "Rydych chi wedi ychwanegu cynnyrch newydd yn llwyddiannus!",
    pleaseEnterProductSellingPrice: "Rhowch bris gwerthu cynnyrch",
    doYouWantToTrackProductStock: "Ydych chi am olrhain stoc cynnyrch?",
    sellingPrice: "Pris gwerthu",
    setProductExpiryReminder: "Gosod Atgoffa Dod i Ben Cynnyrch",
    productExpiryDate: "Dyddiad dod i ben y cynnyrch",
    startRemindingFrom: "Dechreuwch Atgoffa O",
    productSuppliesAddedSuccessfully:
      "Rydych wedi ychwanegu cyflenwadau cynnyrch yn llwyddiannus.",
    addProductSupplies: "Ychwanegu Cyflenwadau Cynnyrch",
    pleaseSelectSupplier: "Dewiswch Gyflenwr",
    nameOfProduct: "Enw'r Cynnyrch",
    pleaseSelectProduct: "Dewiswch Cynnyrch",
    productVariant: "Amrywiad Cynnyrch",
    pleaseSelectAVariant: "Dewiswch Amrywiad",
    pleaseEnterUnitPrice: "Rhowch Bris yr Uned",
    businessBranch: "Cangen Fusnes",
    pleaseSelectBranch: "Dewiswch gangen",
    youveSuccessfullyAddedANewSupplier:
      "Rydych chi wedi ychwanegu cyflenwr newydd yn llwyddiannus",
    addSupplier: "Ychwanegu Cyflenwr",
    pleaseEnterSupplierEmail: "Rhowch e-bost y cyflenwr",
    pleaseAddADescription: "Ychwanegwch ddisgrifiad",
    anErrorOccuredProductsDeleted:
      "Digwyddodd gwall wrth gyflawni'r llawdriniaeth. Sylwch, ychydig o gynhyrchion a allai fod wedi'u dileu yn y broses",
    bulkDelete: "Dileu Swmp",
    youAreAboutToDelete: "Rydych chi ar fin dileu",
    product: "Cynnyrch",
    isDueToRedeem: "i fod i adbrynu",
    aReward: "gwobr",
    pleaseSelectCustomerToReeemReward: "Dewiswch gwsmer i adbrynu gwobr.",
    youHaveNoLoyaltyProgramRunning:
      "Nid oes gennych unrhyw raglen ffyddlondeb weithredol yn rhedeg",
    customerHhasNoPointsInLoyaltyProgram:
      "Nid oes gan y cwsmer unrhyw bwyntiau yn y rhaglen ffyddlondeb hon",
    proceedWithPayment: "Ewch ymlaen gyda'r Taliad?",
    youAreAboutToPayForTransactionUsingPoints:
      "Rydych chi ar fin talu am y trafodiad gan ddefnyddio pwyntiau.",
    customerHas: "Cwsmer Wedi",
    worth: "werth",
    andIsNotSufficientToPayForTransaction:
      "ac nid yw'n ddigonol i dalu am y trafodiad hwn. A fyddent am ychwanegu'r balans gan ddefnyddio dull talu arall?",
    addCustomerLoyalty: "Ychwanegu Teyrngarwch Cwsmer",
    pleaseAddCustomerFirst: "Ychwanegwch neu dewiswch gwsmer yn gyntaf.",
    pleaseWaitWhileWeGetReady: "Arhoswch, wrth i ni baratoi",
    lowStock: "Stoc Isel",
    pleaseEnterAmountTendered: "Rhowch y swm a dendrwyd",
    areYouSureToBookSaleOffline:
      "Ydych chi'n siŵr eich bod chi am archebu'r gwerthiant hwn all-lein?",
    saleWouldBeBookedAutomatically:
      "Byddai gwerthiant yn cael ei archebu'n awtomatig pan fyddwch chi'n troi ar eich rhyngrwyd",
    offlineSalesBookingCancelled: "Canslo archeb gwerthu all-lein",
    covid19Message:
      "COVID19: Golchwch eich dwylo â sebon neu Glanweithiwch i atal y lledaeniad. Cadwch yn Ddiogel bob amser",
    saleSuccessfullyRecorded: "Gwerthu Wedi'i Gofnodi'n Llwyddiannus!",
    sendReceiptToEmail: "Anfon derbynneb i'r E-bost",
    sendThankYouSms: "Gyrrwch SMS diolch",
    sendShippingDetails: "Anfonwch fanylion Llongau",
    addLoyalty: "Ychwanegu Teyrngarwch",
    searchCustomerNameOrNumber: "Chwilio enw neu rif cwsmer",
    clickTheSearchCustomerBox:
      "Cliciwch y Blwch Cwsmer Chwilio a'r Cerdyn Sganio",
    enterProductPrice: "Rhowch Bris Cynnyrch",
    enterPriceFor: "Rhowch Bris am",
    searchForProduct: "Chwilio am gynnyrch",
    all: "I gyd",
    backToDashboard: "Yn ôl i'r Dangosfwrdd",
    viewDraftSales: "Gweld Gwerthiannau Drafft",
    variantSelected: "amrywiad wedi'i ddewis",
    variant: "amrywiad",
    thePreviousVariantSelectionNotAvailable:
      "Nid yw'r dewis amrywiad blaenorol ar gael ar gyfer yr amrywiad newydd a ddewiswyd yn seiliedig ar bris, newidiwch eich dewis.",
    pleaseSupplyPositiveQuantityNumber:
      "Rhowch rif maint postive os gwelwch yn dda",
    lowStockFor: "Stoc isel ar gyfer",
    clearVariants: "Amrywiadau clir",
    pleaseEnterQuantity: "Rhowch Meintiau",
    picture: "Llun",
    redemptionToken: "Tocyn Adbrynu",
    token: "Tocyn",
    totalSpent: "Cyfanswm a Wariwyd",
    confirmPayment: "Cadarnhau Taliad",
    hasPaymentBeenMade: "A yw'r taliad wedi'i brosesu'n llwyddiannus?",
    enterTransactionReference:
      "Rhowch gyfeirnod y trafodiad y gwnaethoch chi dalu ag ef",
    pleaseSelectACustomer: "Dewiswch gwsmer!",
    areYouSureToApplyDiscount:
      "Ydych chi'n siŵr eich bod chi am gymhwyso gostyngiad?",
    addYourBankAccountToEnableUssd:
      "Ychwanegwch eich cyfrif banc i alluogi trosglwyddiad Instant USSD gan uPay",
    totalAmountToPay: "Cyfanswm y Taliad",
    doYouWantToCancelTransaction: "Ydych chi am ganslo'r trafodiad hwn?",
    savePrintBill: "Bil Cadw / Argraffu",
    enterAmountCollectedForCustomer: "Rhowch y swm a gasglwyd ar gyfer cwsmer",
    recordSale: "Gwerthu Recordiau",
    checkOutWith: "Gwiriwch gyda",
    instantTransfer: "Trosglwyddo Instant",
    dialTheUSSDCode: "Deialwch y cod USSD",
    pleaseSelectABank: "Dewiswch fanc",
    payWithUSSD: "Talu Gyda USSD",
    sendBillTo: " - Anfon Bil at",
    waitingForUSSDTransfer: "Aros am Drosglwyddo USSD",
    percent: "Canran",
    applyDiscount: "Gwneud Cais Disgownt",
    thisBillHasBeenSaved: "Mae'r Bil hwn wedi'i arbed",
    saveDraft: "Arbedwch Ddrafft",
    pleaseTypeANameToIdentifyCustomer: "Teipiwch enw i adnabod y cwsmer",
    paymentDetails: "Manylion Talu",
    basePrice: "Pris Sylfaenol",
    staff: "Staff",
    subTotal: "Is-gyfanswm",
    durationMonths: "Hyd (misoedd)",
    selectADuration: "Dewiswch Hyd",
    oneMonth: "1 Mis",
    twoMonths: "2 Fis",
    threeMonths: "3 mis",
    sixMonths: "6 mis",
    twelveMonths: "12 mis",
    eighteenMonths: "18 Mis",
    twentyFourMonths: "24 Mis",
    twoMonthsFree: "(2 fis am ddim)",
    threeMonthsFree: "(3 mis am ddim)",
    fiveMonthsFree: "(5 mis am ddim)",
    yourAccountHasBeen: "Mae eich Cyfrif wedi bod",
    renewed: "adnewyddwyd",
    upgraded: "uwchraddio",
    successfully: "yn llwyddiannus",
    yourSubscription: "Eich Tanysgrifiad",
    youAreCurrentlyEnrolledOnA: "Ar hyn o bryd rydych wedi cofrestru ar a",
    pleaseChooseAPaymentOption: "Dewiswch Opsiwn Taliad",
    planRenewal: "Adnewyddu Cynllun",
    planUpgrade: "Uwchraddio Cynllun",
    pleaseSelectDurationToPayFor:
      "Dewiswch Hyd rydych chi'n bwriadu talu amdano",
    staffAccounts: "Cyfrifon Staff",
    ecommerce: "E-fasnach",
    pleaseSelectAPlan: "Dewiswch gynllun",
    includeAddons: "Cynhwyswch Ychwanegiadau",
    viewTransactions: "Gweld Trafodion",
    customerHasNoCompletedTansactions:
      "Nid oes gan y cwsmer unrhyw orchmynion wedi'u cwblhau eto",
    branch: "Cangen",
    enterNumberOfEcommerceBranches: "Rhowch Nifer y Canghennau E-Fasnach",
    enterNumberOfEcommerceBranchesToPay:
      "Rhowch Nifer y Canghennau E-Fasnach yr ydych yn bwriadu talu amdanynt",
    ecommerceIntegration: "Integreiddio E-Fasnach",
    enterNumberOfBranches: "Rhowch Nifer y Canghennau",
    enterNumberOfAdditionalBranchesToPay:
      "Rhowch Nifer y Canghennau Ychwanegol rydych chi'n bwriadu talu amdanynt",
    enterNumberOfStaffs: "Rhowch Nifer y Staff",
    enterNumberOfStaffsToPayFor:
      "Rhowch Nifer y Staff yr ydych yn bwriadu talu amdanynt",
    discountApplies: "Gostyngiadau yn Gymwys",
    starsOnThe: "sêr ar y",
    offer: "cynnig",
    get: "Cael",
    moreStarsToRedeem: "mwy o sêr i'w hadbrynu",
    taxVat: "Treth (TAW)",
    callCashierToCompletePayment: "Ffoniwch Ariannwr i Gwblhau'r Taliad",
    receipt: "Derbynneb",
    dear: "Annwyl",
    thankYouForYourOrderFindGoods:
      "Diolch am eich archeb. Dewch o hyd i'r nwyddau canlynol a gyflenwir, fel y cytunwyd.",
    shippingNote: "Nodyn Llongau",
    enterShippingNote: "Rhowch Nodyn Llongau",
    shippingAddress: "Cyfeiriad cludo",
    enterShippingAddress: "Rhowch gyfeiriad Llongau",
    wellDoneYouAreDueToRedeem: "Da iawn! Mae disgwyl ichi adbrynu",
    toGetYourRewardNextVisit:
      "i gael eich gwobr ar eich ymweliad nesaf. Diolch",
    pointsOnThe: "Pwyntiau ar y",
    morePointsToRedeem: "mwy o bwyntiau i'w hadbrynu",
    showCode: "Dangos cod",
    toGetYourRewardOnNextVisit:
      "i gael eich gwobr ar eich ymweliad nesaf. Diolch",
    earn: "Ennill",
    delivaryNote: "Nodyn Delivary",
    draftSales: "Gwerthiannau Drafft",
    startDate: "Dyddiad cychwyn",
    endDate: "Dyddiad Gorffen",
    orders: "Gorchmynion",
    checkout: "til",
    noProductItem: "Dim Eitem Cynnyrch",
    selectProductImage: "Dewiswch Delwedd Cynnyrch",
    selectCountry: "Dewis gwlad",
    selectRegion: "Dewiswch Wladwriaeth / Rhanbarth",
    printProductTag: "Tag Cynnyrch Argraffu",
    transactionReference: "Cyfeirnod trafodiad",
    Cashier: "Ariannwr",
    Manager: "Rheolwr",
    Owner: "Perchennog",
    Admin: "Gweinyddiaeth",
    addPartners: "Ychwanegu Partneriaid",
    addNewLoyaltyPartner: "Ychwanegwch Bartner Teyrngarwch Newydd",
    pleaseEnterCompanyName: "Rhowch Enw'r Cwmni",
    companyName: "Enw'r Cwmni",
    pleaseEnterCompanyRepName: "Rhowch Enw cynrychiolydd y cwmni",
    companyRepName: "Enw Cynrychiolydd y Cwmni",
    pleaseEnterCompanyRepEmail: "Rhowch e-bost cynrychiolydd y cwmni",
    companyRepEmail: "E-bost cynrychiolydd y cwmni",
    pleaseEnterCompanyRepPhone: "Rhowch rif ffôn cynrychiolydd y cwmni",
    companyRepPhone: "Rhif Ffôn cynrychiolydd y cwmni",
    addReward: "Ychwanegwch wobr",
    pleaseEnterRewardName: "Rhowch enw'r wobr",
    rewardName: "Enw Gwobrwyo",
    rewardQuantity: "Gwobrwyo Meintiau",
    rewardDescription: "Disgrifiad Gwobrwyo",
    rewardType: "Math Gwobrwyo",
    pleaseEnterRewardType: "Rhowch y math o wobr",
    pleaseEnterRewardQuantity: "Rhowch faint o wobr",
    pleaseEnterRewardDescription: "Rhowch y disgrifiad gwobr",
    fineDining: "Bwyta Gain",
    luxuryFashion: "Ffasiwn Moethus",
    hotels: "Gwestai",
    travel: "Teithio",
    foodAndBeverage: "Bwyd a Diod",
    fashion: "Ffasiwn",
    health: "Iechyd",
    furniture: "Dodrefn",
    entertainment: "Adloniant",
    automobile: "Automobile",
    education: "Addysg",
    beautyAndSpa: "Harddwch a Sba",
    staycation: "Aros",
    events: "Digwyddiadau",
    trips: "Teithiau",
    oilAndGas: "Olew a Nwy",
    laundry: "Golchdy",
    partnerCategory: "Categori Partner",
    freeItem: "Eitem Am Ddim",
  },
  Xhosa: {
    cashier: "othatha imali",
    manager: "umphathi",
    owner: "umnini",
    online: "kwi-intanethi",
    offline: "ngaphandle kweintanethi",
    changePassword: "Tshintsha iphasiwedi",
    currentPasswordMessage: "Nceda ngenisa igama lokugqitha lakho lwangoku",
    passwordMessage: "Nceda ufake iphasiwedi yakho",
    currentPassword: "Iphasiwedi yangoku",
    password: "inombolo yokuvula",
    confirmPasswordMessage: "Nceda uqinisekise iphasiwedi!",
    confirmPassword: "Ngqina iphasiwedi",
    sendViaEmail: "Thumela i-imeyile",
    sendViaWhatsapp: "Thumela u-WhatsApp",
    downloadPdf: "Khuphela iPDF",
    paid: "kuhlawulwe",
    unpaid: "engahlawulwanga",
    partial: "Inxalenye",
    closeInvoice: "Ngaba uyafuna ukuvala i-Invoice?",
    closeInvoiceConfirmation:
      "I-invoyisi ayinakugcinwa. Ngaba uyafuna ukuvala?",
    yes: "Ewe",
    no: "hayi",
    invoice: "I-invoyisi",
    wasDeducted: "yaxhuzulwa",
    for: "ye",
    item: "Umba",
    addProduct: "Yongeza iMveliso",
    paymentReference: "Isalathiso sentlawulo",
    amountPaid: "Isixa esihlawulelweyo",
    discountAmount: "Imali yesaphulelo",
    amountDue: "Imali eshiyekile",
    amount: "Isixa",
    dueDate: "Umhla wokuphila",
    paymentType: "Uhlobo lweNtlawulo",
    card: "Ikhadi",
    cash: "Imali",
    bankTransfer: "Udluliselo lwebhanki",
    paymentMessage: "Umyalezo wentlawulo",
    description: "Inkcazo",
    sendReceipt: "Thumela iirisithi",
    delete: "Cima",
    save: "Gcina",
    resend: "Thumela kwakhona",
    saveAndSend: "Gcina",
    invoiceSaved: "I-invoyisi igciniwe!",
    selectPaymentMethod: "Nceda ukhethe indlela yokuhlawula!",
    selectCustomer: "Nceda ukhethe uMthengi!",
    cartEmptyError:
      "Uluhlu lweenqwelo alunakungena, vala i-invoyisi kwaye ungeze into kwinqwelo!",
    subscriptionExpired:
      "Ubulungu bakho buphelelwe lixesha kwaye iakhawunti yakho ngoku inqunyelwe. Cofa kwiqhosha elingezantsi ukuze uvuselele iakhawunti yakho",
    renew: "Hlaziya",
    holdOn: "Bambelela",
    customerBank: "Umthengi weBhanki",
    cancel: "Rhoxisa",
    selectACustomer: "Khetha uMthengi",
    invoiceSuccessfulPdfError:
      "I-invoice yenziwe ngempumelelo kodwa i-PDF Generation ithatha ixesha elide kunesiqhelo. Nceda uphinde ubuye kungekudala.",
    downloadingInvoice: "Kuthotywa umthwalo i-invoyisi",
    downloadingReceipt: "Kuthotywa Umthwalo Werisithi",
    whatsappReceiptError:
      "Kwenzeke imposiso xa kuthunyelwa irisithi nge-WhatsApp, Nceda uzame kwakhona.",
    receiptToWhatsapp: "Irisithi ithunyelwe kuWhatsApp",
    thankYouForPatronage: "Enkosi ngenkxaso yakho",
    hereIsYourReceipt: "Nantsi irisithi yakho yentlawulo",
    errorSendingEmailReceipt:
      "Kwenzeke imposiso xa kuthunyelwa irisithi nge-imeyile, nceda uzame kwakhona okanye uqhagamshelane nenkxaso",
    receiptSentToEmail: "Irisithi ithunyelwe kwi-imeyile yomthengi",
    invoiceSentToEmail: "I-invoyisi ithunyelwe kwi-imeyile yomthengi",
    invoiceSuccessWhatsappError:
      "I-invoyisi yenziwe ngempumelelo kodwa kwenzeke impazamo ekuthumeleni ku-WhatsApp. Zama kwakhona kuluhlu lwe-invoyisi",
    invoiceSuccessfulEmailError:
      "I-invoice yenziwe ngempumelelo kodwa kwenzeke impazamo ngelixa kuthunyelwa njenge-imeyile. Nceda uzame kwakhona kuluhlu lwe-invoyisi",
    invoiceSentToWhatsapp: "I-invoyisi ithunyelwe ku-WhatsApp",
    hello: "Mholweni",
    pleaseDownloadInvoice: "Nceda ukhuphele i-invoyisi",
    pleaseDownloadReceipt: "Nceda ukhuphele irisithi",
    from: "ukusuka",
    email: "Imeyile",
    upgrade: "Ukuphucula",
    youAreOnFreePlan: "UkuSicwangciso saSimahla.",
    clickOn: "Cofa apha",
    yourPlanInFewSteps: " umrhumo wakho ngamanyathelo ambalwa akhawulezayo.",
    to: "ukuya",
    yourSubscriptionHasExpired:
      "Ubulungu bakho buphelelwe lixesha kwaye iakhawunti yakho ngoku inqunyelwe.",
    days: "iintsuku",
    yourSubscriptionExpiresIn: "Ubhaliso lwakho lweLoystar luphelelwa lixesha",
    createAcount: "Dala i-akhawunti",
    signIn: "Ngena",
    continue: "Qhubeka",
    enterOtp: "Ngenisa i-PIN ye-OTP",
    enterValidOtp: "Nceda ufake iPIN eyiyo",
    pin: "IPIN",
    tokenSentToMail: "Umqondiso uthunyelwe kwi-imeyile yakho",
    passwordResetSuccessful: "Ukusetha kwakhona iphasiwedi kube yimpumelelo",
    somethingWentWrong: "Into ayihamba kakuhle!",
    resetPassword: "Misela iphasiwedi kwakhona",
    iHaveResetCode: "Ndinekhowudi yokuseta kwakhona iphasiwedi",
    pleaseEnterEmail: "Nceda ngenisa imeyile yakho",
    aTokenWillBeSentToEmail: "Umqondiso uya kuthunyelwa kwi-imeyile yakho",
    enterEmail: "Ngenisa imeyile yakho",
    sendinYourToken: "Ithumela ithokheni yakho ...",
    makeSureItMatchesPassword:
      "Qiniseka ukuba iyahambelana negama lokugqitha elitsha",
    pleaseChooseNewPassword: "Nceda ukhethe iphasiwedi entsha",
    chooseNewPassword: "Khetha iphasiwedi entsha",
    enterNewPassword: "Ngenisa igama lokugqitha elitsha ukuqinisekisa",
    enterTokenSent: "Ngenisa umqondiso othunyelwe ngeposi yakho",
    resetToken: "Hlaziya umqondiso",
    resettingPassword: "Ukusetha kwakhona iphasiwedi yakho ...",
    signUp: "Bhalisa",
    adminSignInWithEmail:
      " Ukungena kwemiqondiso nge-imeyile ngelixa abasebenzi bangena ngegama lomsebenzisi.",
    pleaseEnterEmailOrUsername:
      "Nceda ngenisa i-imeyile okanye igama lomsebenzisi",
    emailOrUsername: "Imeyile okanye igama lomsebenzisi",
    pleaseEnterPassword: "Nceda ufake ipassword",
    createAnAccount: "Dala i-akhawunti",
    forgotPassword: "Ingaba uyilibele iphasiwedi?",
    enterPhoneNumber: "Ngenisa inombolo yefowuni",
    personalData: "Idatha yakho",
    validateConfirmationCOde: "Qinisekisa ikhowudi yokuqinisekisa",
    pleaseEnterFirstName: "Nceda ufake igama lakho lokuqala",
    pleaseEnterPhoneNumber: "Nceda ufake inombolo yakho yefowuni",
    pleaseEnterLastName: "Nceda ufake igama lakho lokugqibela",
    pleaseEnterBusinessName: "Nceda ufake igama lakho leshishini",
    firstName: "Igama lakho",
    lastName: "Ifani yakho",
    businessName: "Igama leShishini",
    previous: "Ngaphambili",
    next: "Okulandelayo",
    pleaseSelectBusinessCategory: "Nceda ukhethe udidi lweshishini lakho",
    pleaseEnterValidEmail: "Nceda ngenisa imeyile esebenzayo",
    pleaseEnterPostCode: "Nceda ufake ikhowudi yeposi",
    postCode: "Ikhowudi yeposi",
    phoneNumberInUse: "Le nombolo yefowuni sele isetyenziswa!",
    emailInUse: "Le imeyile sele isetyenziswa!",
    foodAndDrinks: "Ukutya neziselo",
    salonAndBeauty: "Salon kunye Ubuhle",
    fashionAndAccessories: "Ifashoni kunye nezixhobo",
    gymAndFitness: "Gym kunye Fitness",
    travelAndHotel: "Ukuhamba kunye nehotele",
    homeAndGifts: "Ikhaya neZipho",
    washingAndCleaning: "Ukuhlamba nokucoca",
    gadgetsAndElectronics: "Izixhobo kunye neze-Elektroniki",
    groceries: "Ukutya",
    others: "Abanye",
    submit: "Ngenisa",
    accountCreatedSuccessful: "Iakhawunti yakho yenziwe ngempumelelo.",
    pleaseEnterAddress: "Nceda ufake idilesi yakho",
    addressLine1: "Umgca wedilesi yoku-1",
    addressLine2: "Umgca wedilesi 2",
    choosePassword: "Khetha iphasiwedi",
    passwordMustBe6Characters:
      "Iphasiwedi kufuneka okungenani ibe nabalinganiswa aba-6.",
    howDidYouHearLoystar: "Uve njani malunga neLoystar?",
    referralCode: "Ikhowudi yokudlulisa",
    byClickingTheButton: " Ngokucofa iqhosha elingezantsi, uyavuma",
    termsAndSevice: "Imigaqo",
    wereCreatingAccount: "Senza iakhawunti yakho",
    proceed: "Qhubeka",
    verificationCodeMustBe6:
      "Ikhowudi yokuqinisekisa kufuneka ibe namanani ama-6",
    pleaseEnter6DigitCode: "Nceda ufake ikhowudi enamanani ama-6",
    enterVerificationCode: "Faka ikhowudi yokugqina",
    resendToken: "Phinda uthumele uMqondiso",
    verify: "Qinisekisa",
    transactions: "Intengiselwano",
    todaySales: "Intengiso yanamhlanje",
    salesHistory: "Imbali yokuthengisa",
    supplyHistory: "Imbali yokuNikezela",
    new: "Entsha",
    invoices: "Ii-invoyisi",
    disbursements: "Inkcitho",
    offlineSales: "Ukuthengisa ngaphandle kweintanethi",
    products: "Iimveliso",
    customers: "Abathengi",
    multiLevelLoyalty: "Ukunyaniseka kwamanqanaba amaninzi",
    loyaltyPrograms: "Iinkqubo zokunyaniseka",
    members: "Amalungu",
    appStore: "Ivenkile yosetyenziso",
    orderMenu: "Imenyu yoku-odola",
    settings: "Useto",
    staffAndBranches: "Abasebenzi namasebe",
    myAccount: "I-akhawunti yam",
    switchToSellMode: "Tshintshela kwiNdlela yokuThengisa",
    signOut: "Phuma",
    getFreeSubscription: "Fumana umrhumo wasimahla",
    onlyNumbersAllowed: "Amanani kuphela avunyelwe",
    yourAccountMustBe10Digits:
      "inombolo yeakhawunti yakho mayibe linani eliyi-10",
    yourBvnMustBe11: "i-BVN yakho kufuneka ibe linani elinemivo eli-11",
    pleaseSelectBank: "Nceda ukhethe ibhanki yakho",
    selectYourBank: "Khetha iBhanki yakho",
    enterBankAccountNumber: "Faka inombolo yeAkhawunti yeBhanki",
    enterBvnNumber: "Ngenisa i-BVN yakho",
    connectBankAccount: "Qhagamshela iAkhawunti yeBhanki",
    passwordResetSuccessfulAndSignOut:
      "Igama lakho lokugqithisa lisethwe kwakhona ngempumelelo. Cofa kwiqhosha lokuPhuma apha ngezantsi ukuze ungene kwakhona",
    enterCurrentPassword: "Ngenisa igama lokugqithisa langoku",
    pleaseEnterCurrentPassword: "Nceda ngenisa igama lokugqithisa langoku",
    phoneNumber: "Inombolo yomnxeba",
    sex: "Ngesondo",
    dateOfBirth: "Umhla wokuzalwa",
    state: "Urhulumente",
    country: "Ilizwe",
    loyaltyId: "Isazisi sokunyaniseka",
    createdAt: "Yenziwe ngo",
    noOfTransactionsMade: "Inani lentengiselwano ezenziwe",
    yourDownloadWillStart: "Ukhuphelo lwakho luza kuqala ngomzuzwana",
    exportCustomers: "Ukuthumela ngaphandle kubaThengi",
    youhaveSuccessfullyToppedUp: "Uzigqibe ngempumelelo iiYunithi zeSMS zakho.",
    youNowHave: "Ngoku unayo",
    smsUnits: "Iiyunithi zeSMS",
    enterNumberOfSmsUnits:
      "Ngenisa inani leeyunithi zeSMS oZimisele ukuzithenga",
    pleaseEnterANumericValue: "Nceda ufake ixabiso lamanani",
    pay: "Hlawula",
    accountEditedSuccessfully: "Iakhawunti yakho ihlelwe ngempumelelo.",
    youAeCurrentlyOn: "Ngoku uvule",
    plan: "Cwangcisa",
    userData: "Idatha yomsebenzisi",
    businessData: "IINKCUKACHA ZOSHISHINO",
    businessCategory: "ICatergory yeshishini",
    pleaseSelectCurrency: "Nceda ukhethe imali yakho",
    selectYourCurrency: "Khetha imali yakho",
    purchaseMoreSmsUnits:
      "Thenga iiYunithi zeSMS ezininzi usebenzisa le fomu ingezantsi",
    youHave: "Unayo",
    atLeast4SmsUnitsRrequired:
      "Ubuncinci iiyunithi zeesms ezi-4 ziyafuneka ukuze kuqinisekiswe, nceda wongeze!",
    pleaseVerifyYourAccountToComplyWithKyc:
      "Nceda uqinisekise iakhawunti yakho yebhanki ukuthobela iimfuno 'Zazi uMthengi wakho' (KYC). Oku kuyakuvumela ukuba uqokelele iintlawulo nge-USSD okanye ugqithiso kwangoko, ufumane iiodolo kubathengi kunye nenkqubo yokuhlawula intengiselwano. Isebenza kubarhwebi baseNigeria kuphela. Nceda ucinezele iqhosha elingezantsi ukuqala.",
    reConnectBankAccount: "Qhagamshela kwakhona iAkhawunti yeBhanki",
    accountName: "Igama le-akhawunti",
    accountNumber: "Inombolo ye-akhawunti",
    bankName: "Igama leBhanki",
    verified: "Iqinisekisiwe",
    accountDetails: "Iinkcukacha zeAkhawunti",
    kycBankAccount: "KYC",
    createTier: "Yenza iTier",
    fileUploadSuccessful: "ifayile ifakwe ngempumelelo",
    fileUploadFailed: "ufakelo lwefayile aluphumelelanga",
    createLoyaltyProgram: "Yenza iNkqubo yokunyaniseka",
    createLoyalty: "Yenza Ukunyaniseka",
    name: "Igama",
    loyaltyArtwork: "Ukunyaniseka kobugcisa",
    clickToUpload: "Cofa ukuze ufake",
    amountToPointsRatio: "Isixa kumanqaku Amanqaku",
    points: "Amanqaku",
    recommendedAmountToPointRatio:
      "Kunconywe: ₦ 1 ukuya 1point. Oko kukuthi kuyo yonke i-Naira echithiweyo, abathengi bakho bafumana inqaku eli-1",
    pleaseTypeIn: "Nceda uchwetheze",
    toDeleteLoyalty: "ukucima oku kunyaniseka",
    deleteLoyalty: "Cima ukunyaniseka",
    toConfirm: "ukuqinisekisa",
    edit: "Hlela",
    pointsAwardedSuccessfully: "Amanqaku aNikezelwe ngempumelelo.",
    enterPointValueToAward:
      "Ngena kwiXabiso leNqaku ongathanda ukuliNika abaThengi",
    award: "Igalelo",
    awardPointValuesToCustomer: "Ixabiso leNqaku leMbasa kuMthengi",
    enrollMembersToLoyalty: "Bhalisa amaLungu kukunyaniseka",
    awardPoints: "Amanqaku amaBhaso",
    enroll: "Bhalisa",
    home: "Ekhaya",
    loyalty: "Ukunyaniseka",
    enrollCustomers: "Bhalisa abaThengi",
    selected: "Ikhethiwe",
    customer: "Umthengi",
    loyaltyActivationSuccessful: "Ukusebenza kokunyaniseka kuyimpumelelo.",
    loyaltyDeactivationSuccessful: "Ukupheliswa kokunyaniseka kuyimpumelelo.",
    viewTier: "Jonga iNqanaba",
    deactivate: "Susa usebenze",
    activate: "Yenza kusebenze",
    actions: "Izenzo",
    nameOfLoyalty: "Igama lokunyaniseka",
    loyaltyStatus: "Imeko yokunyaniseka",
    numberOfTiers: "Inani leeTire",
    createdOn: "Yenziwe yaqalwa",
    createATier: "Yenza iTier",
    stopCreatingTierConfirmation: "Ngaba ufuna ukuyeka ukwenza i-tier?",
    stopEditingTierConfirmation: "Ngaba ufuna ukuyeka ukuhlela eli nqanaba?",
    nameOfTier: "Igama leNqanaba",
    minimumSpendingTarget: "Ubuncinci beThagethi yenkcitho",
    maximumSpendingTarget: "Ubuninzi beThagethi yenkcitho",
    minimumSpendingTargetRequired:
      "Ubuncinane ekujoliswe kuko kwinkcitho kuyafuneka",
    maximumSpendingTargetRequired:
      "Inkcitho ekujoliswe kuyo ubuninzi iyafuneka",
    rewardSponsor: "Umxhasi oxhasa umvuzo",
    pleaseChooseARewardSponsor: "Nceda ukhethe umxhasi wemivuzo",
    self: "Ukuzimela",
    partner: "Iqabane",
    rewardPartner: "Iqabane lomvuzo",
    pleaseSelectRewardPartner: "Nceda ukhethe iqabane lakho lomvuzo",
    rewards: "Imivuzo",
    pleaseSelectAReward: "Nceda ukhethe umvuzo",
    instructionsOnRedeemingReward:
      "Imiyalelo malunga noMthengi kufuneka awuhlangule njani uMvuzo",
    pleaseFillInThisField: "Nceda ugcwalise kule Ndawo!",
    toDeleteThisTier: " ukucima eli nqanaba",
    deleteTier: "Cima iNqanaba",
    viewMembers: "Jonga amalungu",
    membersEnrolled: "Amalungu abhalisile",
    instruction: "Umyalelo",
    membersIn: "Amalungu kwi",
    availableTiersInLoyalty: "Amanqanaba akhoyo kwiNkqubo yokunyaniseka",
    tiers: "Uluhlu",
    totalTier: "ITHIYI YONKE",
    availableLoyaltyProgramme: "Inkqubo yokunyaniseka ekhoyo",
    totalLoyalties: "IINYANISO ZONKE",
    memberDetails: "Iinkcukacha Zelungu",
    nameOfCustomer: "Igama loMthengi",
    address: "Idilesi",
    allEnrolledMembers: "Onke amalungu abhalisiweyo",
    thisIsToWishYouHappyBirthday:
      "Oku kukunqwenela usuku olumnandi lokuzalwa kunye nobomi obunempumelelo. Enkosi ngayo yonke into oyiyo eLoyster. Imibhiyozo emyoli!",
    inputAnOfferPlease: "Nceda ufake igalelo",
    pleaseSelectTheInsertPoint:
      "Nceda ukhethe indawo yokufaka kumyalezo kwaye ucofe kwakhona",
    birthdayOfferAndMessage: "Unikelo lokuzalwa kunye noMyalezo",
    birthdayOffer: "Umnikelo wokuzalwa",
    birthdayMessage: "Umyalezo wokuzalwa",
    noOfferFound: "Akukho mnikelo ufumanekileyo",
    settingABirthdayOffer:
      "Ukuseta isithembiso sokuzalwa kuvumela abathengi ukuba bafumane esi sipho ngeSMS ngomhla wokuzalwa kwabo",
    createOffer: "Yenza umnikelo",
    whatIsTheOffer: "Yintoni umnikelo?",
    editMessage: "Hlela uMyalezo",
    insert: "Faka",
    theNameOfCustomerInserted: "Igama lomthengi liya kufakwa apha",
    theBirtdayOfferInserted: "Unikezelo lokuzalwa luya kufakwa apha",
    youSuccessfullyAddedNewBranch: "Ulonge ngempumelelo isebe elitsha!",
    addNewBranch: "Yongeza iSebe elitsha",
    addBranch: "Yongeza iSebe",
    additionalBranchWillIncur: "Ukongeza iSebe kuya kubakho",
    perBranch: "kwisebe ngalinye",
    ecommerceBranchCosts: "Iindleko zeSebe lezoRhwebo",
    pleaseEnterBranchName: "Nceda ufake igama lesebe",
    pleaseEnterBranchAddress1: "Nceda ufake umgca wedilesi yesebe 1",
    enterBranchAddress1: "Ngenisa umgca wedilesi yesebe 1",
    enterBranchAddress2: "Ngenisa umgca wedilesi yesebe 1",
    pleaseEnterBranchAddress2: "Nceda ufake umgca wedilesi yesebe 2",
    enterBranchName: "Faka igama lesebe",
    successfullyAddedStaff: "Wongeze ngempumelelo abasebenzi abatsha!",
    addNewStaff: "Yongeza abasebenzi abatsha",
    addStaff: "Yongeza abasebenzi",
    additionalStaffWillIncur: "Abasebenzi abongezelelweyo baya kubakho",
    perStaff: "Umsebenzi ngamnye.",
    pleaseEnterStaffEmail: "Nceda ufake imeyile yabasebenzi",
    pleaseEnterStaffUsername: "Nceda ngenisa igama lomsebenzisi labasebenzi",
    pleaseEnterStaffPassword: "Nceda ufake ipassword yabasebenzi",
    pleaseSelectStaffRole: "Nceda ukhethe indima yabasebenzi",
    selectStaffRole: "Khetha indima yabasebenzi",
    enterStaffEmail: "Ngenisa i-imeyile yabasebenzi",
    enterStaffUsername: "Ngenisa igama lomsebenzisi wabasebenzi",
    enterStaffPassword: "Ngenisa igama lokugqithisa labasebenzi",
    spacesNotAllowedInUsername: "izithuba azivunyelwanga kwigama lomsebenzisi",
    admin: "Ulawulo",
    pleaseSelectBusinessToAttachStaff:
      "Nceda ukhethe ishishini ukuze uqhoboshele abasebenzi",
    searchForBranchToAttachStaff: "Khangela isebe lokuncamathisela abasebenzi",
    username: "Igama lomsebenzisi",
    role: "Indima",
    areYouSureToDeleteThis: "Uqinisekile ukuyicima le",
    branches: "Amasebe",
    upgradeYourPlan: "Ukuphucula isiCwangciso sakho.",
    add: "Yongeza",
    addNew: "Yongeza eNtsha",
    customerWithEmailAlreadyExists:
      "Umthengi nge-imeyile / inombolo yefowuni sele ikhona!",
    successfullyAddedNewCustomer: "Wongeze ngempumelelo umthengi omtsha!",
    addCustomer: "Yongeza uMthengi",
    pleaseEnterCustomerFirstName: "Nceda ufake igama lomthengi lokuqala",
    pleaseEnterCustomerLastName: "Nceda ngenisa igama lokugqibela lomthengi",
    pleaseEnterCustomerPhoneNumber: "Nceda ufake inombolo yefowuni yoMthengi",
    pleaseEnterCustomerEmail: "Nceda ufake imeyile yomthengi",
    pleaseEnterCustomerAddressLine: "Nceda ufake umgca wedilesi yomthengi",
    pleaseEnterCustomerOtherAddress: "Nceda ngenisa enye idilesi yomthengi",
    pleaseSelectCustomerGender: "Nceda ukhethe isini somthengi",
    gender: "Isini",
    male: "Indoda",
    female: "Ibhinqa",
    bank: "Ibhanki",
    selectBank: "Khetha iBhanki",
    stateOrRegionOrProvince: "Urhulumente / Ummandla / iPhondo",
    customerNotes: "Amanqaku oMthengi",
    sendSms: "Thumela iSMS",
    editCustomer: "Hlela uMthengi",
    redeemReward: "Khulula uMvuzo",
    issueLoyaltyCard: "Khupha ikhadi lokuthembeka",
    deleteCustomer: "Cima uMthengi",
    youveSuccessfullyAssignedLoyaltyMembership:
      "Uyinike ngempumelelo i-ID yobulungu yokunyaniseka",
    noMembershipIdAvailable:
      "Azikho izazisi zobulungu ezikhoyo. Nceda unxibelelane nathi ku-hello@loystar.co",
    sendEmail: "Thumela i-imeyile",
    membershipPoints: "Amanqaku obuLungu",
    customerDetails: "Iinkcukacha zabaThengi",
    close: "Vala",
    loyaltyBalance: "Ibhalansi yokunyaniseka",
    pointsBalance: "Amanqaku Ibhalansi",
    starBalance: "Ibhalansi yeNkwenkwezi",
    requiredPoints: "Amanqaku afunekayo",
    requiredStars: "Iinkwenkwezi ezifunekayo",
    reddemCode: "Sebenzisa ikhowudi",
    toDeleteThisCustomer: "ukucima lo mthengi",
    customerHasBeenDeletedSuccessfully: "Umthengi ucinywe ngempumelelo!",
    customerWithPhoneAlreadyExists: "Umthengi onenombolo yefowuni sele ekhona",
    customerWasSuccessfullyEdited: "Umthengi uhlelwe ngempumelelo",
    anErrorOccured: "Impazamo yenzekile",
    phoneNumberIncludeCountry: "Inombolo yefowuni (Faka neKhowudi yeLizwe)",
    yourFileQueuedForUpload:
      "Ifayile yakho ibekwe emgceni ukuze ilayishwe. Nceda uvuselele iphepha emva kwemizuzwana embalwa.",
    thereWasAnErrorPerformingOperation: "Kubekho impazamo ekwenzeni umsebenzi!",
    pleaseSelectFile: "Nceda ukhethe ifayile!",
    oopsOnlyCsvFilesAllowed:
      "Yhu! Zifayile ze-CSV kuphela ezivunyelweyo. Nceda ulayishe ifayile ye .csv.",
    fileShouldBeSmallerThan5Mb:
      "Ifayile kufuneka ibe ncinci kune-5MB. Ukuba unayo ifayile enkulu, nceda imeyile support@loystar.co. Enkosi",
    customerFirstNameIsRequired: "Igama lomthengi liyafuneka kumqolo",
    customerPhoneNumberIsRequired:
      "Inombolo yefowuni yomthengi iyafuneka kumqolo",
    importCustomers: "Ngenisa abathengi",
    upload: "Layisha",
    clickIconToDownloadCsv:
      "Cofa eli icon ukukhuphela itemplate yefayile ye-CSV.",
    getGoogleSheetVersion: "Fumana uguqulelo lweshiti likaGoogle",
    clickOrDragFileToUpload:
      "Cofa okanye utsale ifayile kule ndawo ukuze uyilayishe",
    missingOrInvalidColumnHeader:
      "Ikhowudi yekholam engekhoyo okanye engasebenziyo. Nceda ulandele ifomathi yetemplate. Enkosi.",
    youHaveImported: "Uthumele ngaphandle",
    youSuccessfullyRedeemedReward: "Uwukhulule ngempumelelo umvuzo wakho!",
    sixDigitCode: "Ikhowudi enamanani amathandathu",
    pleaseEnterCustomerRewardCode: "Nceda ufake ikhowudi yomvuzo wabathengi",
    enterRewardCode: "Ngenisa ikhowudi yomvuzo.",
    selectLoyaltyProgram: "Khetha inkqubo yokunyaniseka",
    stamps: "Izitampu",
    smsUnitsLow: "Iiyunithi zeSMS ziphantsi",
    pleaseTopUpSmsUnits: "Nceda uPhakamise iiYunithi zeSMS",
    smsSuccessfullySentForDelivery:
      "I-SMS ithunyelwe ngempumelelo ukuhanjiswa!",
    sendSmsTo: "Thumela i-SMS ku",
    allCustomers: "Bonke abaThengi",
    unitsAvailable: "Iiyunithi ziyafumaneka",
    pleaseTypeInTheMessage: "Nceda uchwetheze umyalezo",
    message: "Umyalezo",
    charactersRemaining: "abalinganiswa abaseleyo",
    avoidUseOfSpecialCharacters:
      "Kunqande ukusetyenziswa kwaBantu abaKhethekileyo kunye nee-Emojis zokugcina iiSMS.",
    note: "Phawula",
    errorFetchingCustomersMultilevelDetail:
      "Impazamo ekuLandeni abaThengi Iinkcukacha zeLevelLevel",
    search: "Khangela",
    reset: "Hlela kwakhona",
    importCustomer: "Ngenisa uMthengi",
    addNewCustomer: "Yongeza uMthengi omtsha",
    sendSmsBroadcast: "Thumela uSasazo lweSMS",
    totalCustomers: "BONKE ABathengi",
    disbursementDetails: "Iinkcukacha zeNtlawulo",
    paidBy: "Kuhlawulwa ngu",
    disbursed: "Ikhutshiwe",
    bankAccountName: "Igama leAkhawunti yeBhanki",
    bankAccountNumber: "Inombolo yeAkhawunti yeBhanki",
    transferInitiated: "Ukutshintshwa kuqalile",
    transferCompleted: "Ukugqitywa kugqityiwe",
    pleaseEnterAValid: "Nceda ngenisa eyiyo",
    begin: "qala",
    end: "isiphelo",
    date: "umhla",
    paymentDate: "Umhla wokuhlawula",
    selectDisbursementDuration: "Khetha ixesha lokuhlawula",
    totalSettled: "Iyonke ihleliwe",
    totalUnsettled: "Iyonke ayizinzanga",
    toDeleteThisSale: "ukucima le ntengiso",
    draftSaleDeletedSuccessfully: "Uyilo lweNtengiso lususwe ngempumelelo!",
    deleteSale: "Cima ukuthengisa",
    pleaseTypeInYourTotal:
      "Nceda uchwetheze itotali yakho ukuqinisekisa ukususwa",
    billDetails: "Iinkcukacha zeBhili",
    printBill: "Shicilela iBhili",
    servedBy: "Inikwa",
    total: "Itotali",
    createdDate: "Umhla owenziwe",
    createdTime: "Ixesha lokudala",
    status: "Ubume",
    loginSuccessful: "Ukungena kuphumelele",
    pleaseWaitWhileWeConnectAccount:
      "Nceda ulinde ngelixa siqhagamshela iakhawunti yakho",
    connectionFailedTryAgain: "Umdibaniso usilele. Nceda zama kwakhona.",
    connectionSuccessful: "Umdibaniso Uphumelele",
    viewDetails: "Jonga Iinkcukacha",
    enable: "Yenza isebenze",
    free: "Simahla",
    cost: "Iindleko",
    visitWebsite: "Tyelela iwebhusayithi",
    pleaseUpgradeYourPlanToPro:
      "Nceda uhlaziye isicwangciso sakho kwiPro Plus ukuze wenze le app",
    connectYourBankAccount:
      "Qhagamshela iAkhawunti yakho yeBhanki ukuze ukwazi ukufumana iintlawulo.",
    disable: "Khubaza",
    enableApp: "Nika amandla iApp",
    addNewProductToInvoice: "Yongeza iMveliso eNtsha kwi-invoyisi",
    toDeleteThisInvoice: "ukucima le Invoyisi",
    invoiceDeletedSuccessfully: "I-invoice isuswe ngempumelelo!",
    deleteInvoice: "Cima i-invoyisi",
    youveSuccessfullyAddedInvoicePaymentMessage:
      "Wongeze ngempumelelo umyalezo wentlawulo we-Invoice!",
    pleaseEnterPaymentInstructions: "Nceda ufake imiyalelo yentlawulo",
    invoiceId: "Isazisi se-invoyisi",
    paidDate: "Umhla ohlawulelweyo",
    reference: "Isalathiso",
    productAdded: "Imveliso yongezwa",
    productOutOfStock: "Imveliso iphelile. Nceda uphinde ubeke isitokhwe.",
    totalInvoices: "IINTETHO ZONKE",
    totalPaidInvoices: "IINTETHO ZONKE EZIHLAWULWAYO",
    totalUnpaidInvoices: "IINKCUKACHA ZONKE EZINGAHLAWULWA",
    loyaltyProgramDeleted: "Inkqubo yokunyaniseka icinyiwe",
    thisLoyaltyProgramDeletedSuccessfully:
      "Le nkqubo yokunyaniseka icinywe ngempumelelo",
    thisLoyaltyProgramEditedSuccessfully:
      "Le nkqubo yokunyaniseka ihlelwe ngempumelelo",
    loyaltyProgramAddedSuccessfully:
      "Inkqubo yokunyaniseka yongezwe ngempumelelo",
    loyaltyProgramEdited: "Inkqubo yokunyaniseka ihleliwe",
    loyaltyProgramAdded: "Inkqubo yokunyaniseka yongezwa",
    loyaltyDetails: "Iinkcukacha zokunyaniseka",
    doYouWantToCloseDialog: "Ufuna ukuyivala le ncoko yababini?",
    pleaseEnterLoyaltyName: "Nceda ufake igama ukunyaniseka kwakho",
    programType: "Uhlobo lweNkqubo",
    pleaseSelectProgramType: "Nceda ukhethe udweliso lwenkqubo",
    simplePoints: "Amanqaku alula",
    stampsProgram: "Inkqubo yeZitampu",
    threshold: "Umqobo",
    pleaseEnterLoyaltyThreshold: "Nceda ngenisa umqobo wokunyaniseka",
    reward: "Ibuyiselo",
    pleaseEnterLoyaltyReward: "Nceda ufake umvuzo wokunyaniseka",
    totalUserPoints: "Amanqaku apheleleyo omsebenzisi",
    totalUserStamps: "Izitampu zomsebenzisi zizonke",
    spendingTarget: "Inkcitho ekujoliswe kuyo",
    spendingTargetHint1:
      "Ukuchitha ekujolise kuko kukuba umthengi kufuneka achithe malini ukufumana umvuzo. Ixabiso lemali eli-1 = inqaku eli-1.",
    spendingTargetHint2:
      "Iitampu ekujoliswe kuzo zingaphi izitampu umthengi ekufuneka eqokelele ukufumana umvuzo. umz. 5",
    addNewLoyaltyProgram: "Yongeza iNkqubo eNtsha yokunyaniseka",
    addLoyaltyProgram: "Yongeza iNkqubo yokunyaniseka",
    loyaltyProgramType: "Uhlobo lweNkqubo yokunyaniseka",
    pleaseSelectLoyaltyProgramType:
      "Nceda ukhethe uhlobo lwenkqubo yokunyaniseka",
    nameOfProgram: "Igama lenkqubo",
    pleaseEnterProgramName: "Nceda ufake igama lenkqubo",
    whatIsTheReward: "Uyintoni umvuzo?",
    egNextPurchaseIsFree: "Umzekelo Ukuthenga okulandelayo simahla",
    customerName: "Igama loMthengi",
    guestCustomer: "Umthengi weNdwendwe",
    orderConfirmedSuccessfully: "Umyalelo uqinisekiswe ngempumelelo",
    orderCancelledSuccessfully: "Umyalelo urhoxisiwe ngempumelelo",
    confirmOrder: "Qinisekisa iodolo",
    cancelOrder: "Rhoxisa iodolo",
    allOrders: "Yonke iMiyalelo",
    totalOrders: "IMIYALELO EYONKE",
    doYouWantToAcceptOrder: "Uyafuna ukwamkela lo myalelo?",
    doYouWantToCancelOrder: "Ufuna ukurhoxisa lo myalelo?",
    orderDetails: "Iinkcukacha zoMyalelo",
    orderCreatedAt: "Umyalelo wenziwe nge",
    supplier: "Umthengisi",
    productName: "Igama lemveliso",
    quantity: "Ubungakanani",
    unitPrice: "Ixabiso lento",
    receivedBy: "Ifunyenwe ngu",
    reportFrom: "Ingxelo evela",
    totalSupplies: "Izinto ezibonelelwa zizonke",
    allRightsReserved: "Onke amalungelo agciniwe",
    toDeleteThisTransaction: "ukucima leTransekshini",
    transactionDeletedSuccessfully:
      "Intengiselwano icinywe ngempumelelo. Isitokhwe sibuyiselwe kuluhlu lwempahla.",
    deleteTransaction: "Cima iTransekshini",
    transactionDetails: "Iinkcukacha zentengiselwano",
    printReceipt: "Shicilela irisithi",
    channel: "Umjelo",
    discount: "Isaphulelo",
    profit: "Inzuzo",
    discountedSales: "Ukuthengiswa okunesaphulelo",
    errorFetchingRecord: "Imposiso xa bekufunyanwa irekhodi",
    exportTransactions: "Intengiselwano yokuthumela ngaphandle",
    errorFetchingSalesRecord:
      "Impazamo xa kuthathwa irekhodi lokuThengisa ngaphandle.",
    totalSellingPrice: "Ixabiso lilonke lokuthengisa",
    totalCostPrice: "Ixabiso lilonke lexabiso",
    appliedDiscount: "Isaphulelo esisetyenzisiweyo",
    noOfItems: "Inani lezinto",
    sales: "Intengiso",
    export: "Thumela ngaphandle",
    totalProfit: "Inzuzo iyonke",
    totalBalanceInPeriod: "Ibhalansi iyonke ngeXesha",
    allTimeSales: "Lonke ixesha lokuThengisa",
    records: "Iirekhodi",
    todaysSales: "Intengiso yanamhlanje",
    transaction: "intengiselwano",
    youHaveExceededTotalNumberOfProducts:
      "Ugqithisile kwinani elipheleleyo leemveliso ezivunyelwe kwisicwangciso sakho. Phucula isicwangciso sakho ukonwabela umda ophezulu.",
    youNeedToHaveLoyaltyProgram:
      "Kufuneka ube neNkqubo yokunyaniseka ukuze usebenzise eli nqaku!",
    price: "Ixabiso",
    category: "Udidi",
    stockTracking: "UkuKhangelwa kweStock",
    stockCount: "Ubalo lwesitokhwe",
    taxed: "Irhafu",
    originalPrice: "Ixabiso loqobo",
    costPrice: "Ixabiso lexabiso",
    unit: "Iyunithi",
    productImage: "Imveliso yeMveliso",
    taxRate: "Ixabiso lerhafu",
    taxType: "Uhlobo lweRhafu",
    trackInventory: "Ukulandelela uluhlu",
    variants: "Ezahlukeneyo",
    hasVariants: "Ineendlela ezahlukeneyo",
    importProduct: "Imveliso engeniswayo",
    exportProducts: "Iimveliso ezithunyelwa ngaphandle",
    addNewProduct: "Yongeza iMveliso eNtsha",
    viewCategory: "Icandelo lokujonga",
    viewSuppliers: "Jonga abaXhasi",
    receiveInventory: "Fumana uluhlu lwempahla",
    printAllProductsTag: "Shicilela Zonke iiMveliso zeTag",
    deleteAll: "Cima Zonke",
    totalProducts: "IMVELISO YONKE",
    youveSuccessfullyAddedANewCategory: "Udibanise ngempumelelo udidi olutsha",
    addNewCategory: "Yongeza iCandelo elitsha",
    addCategory: "Yongeza udidi",
    categoryName: "Udidi lwegama",
    pleaseEnterCategoryName: "Nceda ufake igama lodidi",
    stampsTarget: "Iitampu ekujoliswe kuzo",
    sendInventory: "Thumela uluhlu lweempahla",
    productDetails: "Iinkcukacha zeMveliso",
    youveSuccessfullyEditedThisCategory: "Uluhlele ngempumelelo olu didi",
    update: "Uhlaziyo",
    categoryList: "Uluhlu lodidi",
    categories: "Iindidi",
    enterQuantityToUpdate: "Ngenisa inani lokuhlaziya",
    inventorySentSuccessfully: "Uluhlu lweempahla lwathunyelwa ngempumelelo!",
    updateInventory: "Hlaziya uluhlu lweempahla",
    currentQuantity: "Ubungakanani bangoku",
    pleaseEnterQuantityToAdd: "Nceda ufake ubungakanani ofuna ukusongeza",
    pleaseSelectABranch: "Nceda ukhethe iSebe",
    searchForBranch: "Khangela isebe",
    youCantSendMoreThanStock:
      "Awunakho ukuthumela ngaphezulu kwento onayo kwisitokhwe",
    send: "Thumela",
    pleaseEnterQuantityToSend: "Nceda ufake ubungakanani ofuna ukuthumela",
    productNameIsRequiredOnRow: "Igama lemveliso liyafuneka kumqolo",
    productCategoryIsRequiredOnRow: "Udidi lwemveliso luyafuneka kumqolo",
    productPriceIsRequiredOnRow: "Ixabiso lemveliso liyafuneka kumqolo",
    productUnitIsRequiredOnRow: "iyunithi imveliso iyafuneka kumqolo",
    productQuantityIsRequiredOnRow: "Ubungakanani bemveliso buyafuneka kumqolo",
    productVariantsRequireTracking:
      "Ukwahluka kwemveliso kufuna ukulandelelwa!",
    pleaseAddVariantClickButton:
      "Nceda wongeze okwahlukileyo ngokunqakraza iqhosha lokongeza!",
    totalVariantsMoreThanSelectedQuantity:
      "Iitotali zizonke zingaphezulu kobungakanani bemveliso ekhethiweyo, ii-Pls zinciphisa ubungakanani obahlukileyo",
    productEditedSuccessfully: "Imveliso ihlelwe ngempumelelo!",
    fileTooLargeLessThan4Mb:
      "Ubungakanani befayile bukhulu kakhulu! Ubungakanani befayile kufuneka bube ngaphantsi kwe-4MB.",
    suchVariantAlreadyExist: "Umahluko onjalo sele ukhona",
    addVariants: "Yongeza okwahlukileyo",
    editProduct: "Hlela uMveliso",
    pleaseEnterProductName: "Nceda ufake igama leMveliso",
    pleaseEnterProductPrice: "Nceda ufake ixabiso lemveliso",
    pleaseEnterProductOriginalPrice: "Nceda ufake ixabiso leMveliso yoqobo",
    productDescription: "Ingcaciso yeMveliso",
    selectProductCategory: "Khetha Udidi lweMveliso",
    pleaseSelectProductCategory: "Nceda ukhethe udidi lwemveliso",
    productCostPrice: "Ixabiso leMveliso yexabiso",
    productSellingPrice: "Ixabiso lokuThengisa iMveliso",
    productOriginalPrice: "Ixabiso leMveliso yoqobo",
    maxFileSizeAllowedIs4Mb:
      "Ubukhulu. Ubungakanani beFayile buvumelekile yi-4mb",
    productsWithPicturesArePublished:
      "Iimveliso ezinemifanekiso zipapashwa kuFumanisa ukufumana iiodolo",
    shouldThisProductBeTracked: "Ngaba le mveliso kufuneka ilandelwe?",
    pleaseSelectStockUnit: "Nceda ukhethe iyunithi yesitokhwe",
    stockUnit: "Iyunithi yesitokhwe",
    bag: "Ibhegi",
    bottle: "Iibhotile",
    bunch: "I-BUNCH",
    can: "NGABA",
    carton: "Ibhokisi",
    crate: "IKreyiti",
    cup: "IKUP",
    dozen: "DOZEN",
    gigabytes: "IGIGABYTES",
    gram: "Igram",
    kilogram: "IKILOGRAM",
    litre: "UNOBHALA",
    pack: "PHAKATHI",
    pair: "BHANGISA",
    pieces: "IIPHEPHA",
    plate: "INDLELA",
    tonne: "TONNE (MT)",
    uNIT: "IYUNITHI",
    yard: "IYADI",
    pleaseEnterProductQuantity: "Nceda ufake ubungakanani bemveliso",
    productQuantity: "Ubungakanani beMveliso",
    isThisProductTaxed: "Ngaba le mveliso irhafiswa?",
    selectTaxType: "Khetha Uhlobo lweRhafu",
    pleaseSelectTaxType: "Nceda ukhethe Uhlobo lweRhafu",
    progressive: "Ukuqhubela phambili",
    proportional: "Ukulingana",
    pleaseEnterProductTaxRate: "Nceda ufake ixabiso lerhafu yemveliso",
    doesProductHaveVariants: "Ngaba le mveliso inezinto ezahlukeneyo?",
    type: "Chwetheza",
    value: "Ixabiso",
    pleaseEnterVariantType: "Nceda ngenisa uhlobo lokwahluka",
    pleaseEnterVariantValue: "Nceda ufake ixabiso lokwahluka",
    pleaseEnterVariantPrice: "Nceda ufake ixabiso elahlukileyo",
    pleaseEnterVariantQuantity: "Nceda ufake ubungakanani okwahlukileyo",
    productDeletedSuccessfully: "Imveliso icinywe ngempumelelo!",
    categoryDeletedSuccessfully: "Udidi lususwe ngempumelelo!",
    toDeleteThisProduct: "ukucima le mveliso",
    invalidProductQuantity: "Ubungakanani beMveliso engasebenziyo",
    quantityAddedIsOverStock:
      "Ubungakanani obongezayo bungaphezulu kwento onayo kwisitokhwe.",
    itemInventoryNotTracked: "Uluhlu lwezinto alulandelwa",
    addBulkQuantity: "Yongeza ubungakanani bobuninzi",
    enterBulkQuantity: "Ngenisa isixa sobuninzi",
    pleaseEnterBulkQuantity: "Nceda ufake ubungakanani bobuninzi",
    youveSuccessfullyAddedANewProduct: "Uyongeze ngempumelelo imveliso entsha!",
    pleaseEnterProductSellingPrice: "Nceda ufake ixabiso lokuthengisa imveliso",
    doYouWantToTrackProductStock:
      "Ngaba uyafuna ukulandela umkhondo wesitokhwe semveliso?",
    sellingPrice: "Ixabiso lokuthengisa",
    setProductExpiryReminder: "Cwangcisa ukukhunjulwa kokuphela kweMveliso",
    productExpiryDate: "Umhla wokuphelelwa kwemveliso",
    startRemindingFrom: "Qalisa Ukukhumbuza Ukusuka",
    productSuppliesAddedSuccessfully: "Ukongeze imveliso ngempumelelo.",
    addProductSupplies: "Yongeza iiMveliso",
    pleaseSelectSupplier: "Nceda ukhethe uMthengisi",
    nameOfProduct: "Igama leMveliso",
    pleaseSelectProduct: "Nceda ukhethe iMveliso",
    productVariant: "Imveliso eyahlukileyo",
    pleaseSelectAVariant: "Nceda ukhethe okwahlukileyo",
    pleaseEnterUnitPrice: "Nceda ufake ixabiso leyunithi",
    businessBranch: "ISebe lezoShishino",
    pleaseSelectBranch: "Nceda ukhethe isebe",
    youveSuccessfullyAddedANewSupplier:
      "Wongeze ngempumelelo umthengisi omtsha",
    addSupplier: "Yongeza uMthengisi",
    pleaseEnterSupplierEmail: "Nceda ufake imeyile yomthengisi",
    pleaseAddADescription: "Nceda ungeze inkcazo",
    anErrorOccuredProductsDeleted:
      "Impazamo yenzekile ngexesha kusenziwa lo msebenzi. Nceda uqaphele, zimbalwa iimveliso ezinokuthi zisuswe kwinkqubo",
    bulkDelete: "Cima ngobuninzi",
    youAreAboutToDelete: "Umalunga nokucima",
    product: "Imveliso",
    isDueToRedeem: "ifanele ukukhululwa",
    aReward: "umvuzo",
    pleaseSelectCustomerToReeemReward:
      "Nceda ukhethe umthengi ukuze uhlawule umvuzo.",
    youHaveNoLoyaltyProgramRunning: "Awunayo inkqubo yokunyaniseka esebenzayo",
    customerHhasNoPointsInLoyaltyProgram:
      "Umthengi akanamanqaku kule nkqubo yokunyaniseka",
    proceedWithPayment: "Qhubeka nentlawulo?",
    youAreAboutToPayForTransactionUsingPoints:
      "Sele uza kuhlawula intengiselwano usebenzisa amanqaku.",
    customerHas: "Umthengi unayo",
    worth: "Ixabiso",
    andIsNotSufficientToPayForTransaction:
      "kwaye ayonelanga ukuhlawulela le ntengiselwano. Ngaba bafuna ukongeza ibhalansi besebenzisa enye indlela yokuhlawula?",
    addCustomerLoyalty: "Yongeza ukuthembeka kwabaThengi",
    pleaseAddCustomerFirst: "Nceda wongeze okanye ukhethe umthengi kuqala.",
    pleaseWaitWhileWeGetReady: "Nceda ulinde, ngelixa silungiselela",
    lowStock: "Isitokhwe esisezantsi",
    pleaseEnterAmountTendered: "Nceda ufake isixa sethenda",
    areYouSureToBookSaleOffline:
      "Uqinisekile ufuna ukubhukisha le ntengiso ngaphandle kweintanethi?",
    saleWouldBeBookedAutomatically:
      "Intengiso iya kubhukishwa ngokuzenzekelayo xa uvula i-intanethi",
    offlineSalesBookingCancelled:
      "Ubhaliso lwangaphandle kweintengiso lurhoxisiwe",
    covid19Message:
      "I-COVID19: Hlamba izandla zakho ngesepha okanye ucoceke ukunqanda ukusasazeka. Hlala ukhuselekile rhoqo",
    saleSuccessfullyRecorded: "Intengiso irekhodwe ngempumelelo!",
    sendReceiptToEmail: "Thumela irisithi kwi-imeyile",
    sendThankYouSms: "Thumela i-SMS yombulelo",
    sendShippingDetails: "Thumela iinkcukacha zoThutho",
    addLoyalty: "Yongeza Ukunyaniseka",
    searchCustomerNameOrNumber: "Khangela igama lomthengi okanye inombolo",
    clickTheSearchCustomerBox:
      "Cofa kwiBhokisi yokuKhangela yaBathengi kunye neKhadi lokuSkena",
    enterProductPrice: "Ngenisa amaxabiso eMveliso",
    enterPriceFor: "Ngenisa ixabiso",
    searchForProduct: "Khangela imveliso",
    all: "Zonke",
    backToDashboard: "Buyela kwiDashboard",
    viewDraftSales: "Jonga ukuYilwa koThengiso",
    variantSelected: "okhethiweyo okhethiweyo",
    variant: "eyahlukileyo",
    thePreviousVariantSelectionNotAvailable:
      "Ukhetho lwangaphambili olwahlukileyo alufumaneki kukhetho olutsha olukhethiweyo ngokusekwe kwixabiso, nceda utshintshe ukhetho lwakho.",
    pleaseSupplyPositiveQuantityNumber:
      "Nceda unikeze inombolo yobungakanani beposi",
    lowStockFor: "Isitokhwe esisezantsi se",
    clearVariants: "Cacisa okwahlukileyo",
    pleaseEnterQuantity: "Nceda ufake Ubungakanani",
    picture: "Umfanekiso",
    redemptionToken: "Umqondiso weNkululeko",
    token: "Umqondiso",
    totalSpent: "Imali Iyonke",
    confirmPayment: "Qinisekisa Intlawulo",
    hasPaymentBeenMade: "Ngaba intlawulo yenziwe ngokuchanekileyo?",
    enterTransactionReference:
      "Ngenisa ireferensi yentengiselwano ohlawule ngayo",
    pleaseSelectACustomer: "Nceda ukhethe umthengi!",
    areYouSureToApplyDiscount: "Uqinisekile ufuna ukufaka isaphulelo?",
    addYourBankAccountToEnableUssd:
      "Yongeza iakhawunti yakho yebhanki ukwenza ukuba ugqithiso olukhawulezileyo lweUSSD nguPay",
    totalAmountToPay: "Imali iyonke ekufuneka uyihlawule",
    doYouWantToCancelTransaction: "Ngaba ufuna ukurhoxisa le ntengiselwano?",
    savePrintBill: "Gcina / Printa iBhili",
    enterAmountCollectedForCustomer: "Faka imali eqokelelwe umthengi",
    recordSale: "Ukurekhoda ukuthengisa",
    checkOutWith: "Jonga nge",
    instantTransfer: "Ukutshintshwa kwangoko",
    dialTheUSSDCode: "Cofa ikhowudi ye-USSD",
    pleaseSelectABank: "Nceda ukhethe ibhanki",
    payWithUSSD: "Hlawula nge-USSD",
    sendBillTo: " -Thumela iBhili kwi",
    waitingForUSSDTransfer: "Ilinde ukuhanjiswa kwe-USSD",
    percent: "Ipesenti",
    applyDiscount: "Faka isicelo sesaphulelo",
    thisBillHasBeenSaved: "Lo Mthetho uYilwayo ugciniwe",
    saveDraft: "Gcina uyilo",
    pleaseTypeANameToIdentifyCustomer:
      "Nceda uchwetheze igama ukuchonga umthengi",
    paymentDetails: "Iinkcukacha zeNtlawulo",
    basePrice: "Ixabiso leSiseko",
    staff: "Abasebenzi",
    subTotal: "Inani lilonke",
    durationMonths: "Ubude (iinyanga)",
    selectADuration: "Khetha ubude bexesha",
    oneMonth: "Inyanga e-1",
    twoMonths: "Iinyanga ezi-2",
    threeMonths: "Iinyanga ezi-3",
    sixMonths: "Iinyanga ezi-6",
    twelveMonths: "Iinyanga ezili-12",
    eighteenMonths: "Iinyanga ezili-18",
    twentyFourMonths: "Iinyanga ezingama-24",
    twoMonthsFree: "(Iinyanga ezi-2 zaSimahla)",
    threeMonthsFree: "(Iinyanga ezi-3 zaSimahla)",
    fiveMonthsFree: "(Iinyanga ezi-5 zaSimahla)",
    yourAccountHasBeen: "Iakhawunti yakho ibikhona",
    renewed: "kuvuselelwe",
    upgraded: "iphuculwe",
    successfully: "ngempumelelo",
    yourSubscription: "Umrhumo wakho",
    youAreCurrentlyEnrolledOnA: "Ngoku ubhalisile kwi",
    pleaseChooseAPaymentOption: "Nceda ukhethe uKhetho lweNtlawulo",
    planRenewal: "Cwangcisa ukuhlaziywa",
    planUpgrade: "Cwangcisa uPhuculo",
    pleaseSelectDurationToPayFor:
      "Nceda ukhethe ubude bexesha oza kuhlawula ngalo",
    staffAccounts: "IiAkhawunti zabasebenzi",
    ecommerce: "Uqoqosho lwezorhwebo",
    pleaseSelectAPlan: "Nceda ukhethe icebo",
    includeAddons: "Faka iiZongezo",
    viewTransactions: "Jonga iNtengiselwano",
    customerHasNoCompletedTansactions:
      "Umthengi akanayo imali egqityiweyo okwangoku",
    branch: "Isebe",
    enterNumberOfEcommerceBranches: "Ngenisa inani lamaSebe e-Ecommerce",
    enterNumberOfEcommerceBranchesToPay:
      "Ngenisa inani lamaSebe e-Ecommerce oJonge ukuyihlawula",
    ecommerceIntegration: "Umdibaniso we-ecommerce",
    enterNumberOfBranches: "Ngenisa inani lamaSebe",
    enterNumberOfAdditionalBranchesToPay:
      "Ngenisa inani lamasebe ongezelelweyo ojonge ukuwahlawulela",
    enterNumberOfStaffs: "Ngenisa inani labasebenzi",
    enterNumberOfStaffsToPayFor:
      "Ngenisa inani labasebenzi oLungiselela ukulihlawulela",
    discountApplies: "Isaphulelo siyasebenza",
    starsOnThe: "iinkwenkwezi kwi",
    offer: "umnikelo",
    get: "Fumana",
    moreStarsToRedeem: "iinkwenkwezi ezininzi zokukhulula",
    taxVat: "Irhafu (iVAT)",
    callCashierToCompletePayment:
      "Tsalela umnxeba u-Cashier ukuba agqibe ukuhlawula",
    receipt: "Irisithi",
    dear: "Molo",
    thankYouForYourOrderFindGoods:
      "Enkosi nge-odolo yakho. Nceda ufumane ezi zinto zilandelayo, njengoko kuvunyelwene.",
    shippingNote: "Ukuthumela ngenqaku",
    enterShippingNote: "Ngenisa iNqaku lokuThumela",
    shippingAddress: "Idilesi yokuyithumela ngenqanawe",
    enterShippingAddress: "Ngenisa idilesi yokuhambisa ngenqanawe",
    wellDoneYouAreDueToRedeem: "Wenze kakuhle! Umelwe kukuhlawulela",
    toGetYourRewardNextVisit:
      "ukufumana umvuzo wakho kutyelelo lwakho olulandelayo. Enkosi",
    pointsOnThe: "Amanqaku kwi",
    morePointsToRedeem: "amanqaku angaphezulu okuhlawulela",
    showCode: "Bonisa ikhowudi",
    toGetYourRewardOnNextVisit:
      "ukufumana umvuzo wakho kutyelelo lwakho olulandelayo. Enkosi",
    earn: "Zuza",
    delivaryNote: "Inqaku loThotho",
    draftSales: "Uyilo loThengiso",
    startDate: "Umhla wokuqala",
    endDate: "Umhla wokuphela",
    orders: "Imiyalelo",
    checkout: "Phuma",
    noProductItem: "Akukho nto yeMveliso",
    selectProductImage: "Khetha umfanekiso weMveliso",
    selectCountry: "Khetha ilizwe",
    selectRegion: "Khetha ilizwe / uMmandla",
    printProductTag: "Shicilela iTag yeMveliso",
    transactionReference: "Isalathiso sentengiselwano",
    Cashier: "Umxhasi wemali",
    Manager: "Umphathi",
    Owner: "Umnini",
    Admin: "Ulawulo",
    addPartners: "Yongeza abaSebenzi",
    addNewLoyaltyPartner: "Yongeza iqabane elitsha lokunyaniseka",
    pleaseEnterCompanyName: "Nceda ufake igama leNkampani",
    companyName: "Igama leKhampani",
    pleaseEnterCompanyRepName: "Nceda ufake igama lommeli wenkampani",
    companyRepName: "Igama lommeli weNkampani",
    pleaseEnterCompanyRepEmail: "Nceda ngenisa i-imeyile yommeli wenkampani",
    companyRepEmail: "I-imeyile yommeli wenkampani",
    pleaseEnterCompanyRepPhone:
      "Nceda ufake inombolo yefowuni yommeli wenkampani",
    companyRepPhone: "Inombolo yefowuni yommeli wenkampani",
    addReward: "Yongeza umvuzo",
    pleaseEnterRewardName: "Nceda ngenisa igama lomvuzo",
    rewardName: "Igama loMvuzo",
    rewardQuantity: "Ubungakanani boMvuzo",
    rewardDescription: "Inkcazo yoMvuzo",
    rewardType: "Uhlobo loMvuzo",
    pleaseEnterRewardType: "Nceda ngenisa uhlobo lomvuzo",
    pleaseEnterRewardQuantity: "Nceda ngenisa isixa somvuzo",
    pleaseEnterRewardDescription: "Nceda ngenisa inkcazo yomvuzo",
    fineDining: "Ukutya Okulungileyo",
    luxuryFashion: "IFashoni eNtofontofo",
    hotels: "Iihotele",
    travel: "Ukuhamba",
    foodAndBeverage: "Ukutya kunye nesiselo",
    fashion: "Ifashoni",
    health: "Impilo",
    furniture: "Ifenitshala",
    entertainment: "Ukuzonwabisa",
    automobile: "Imoto",
    education: "Imfundo",
    beautyAndSpa: "Ubuhle kunye ne-Spa",
    staycation: "Ukuhlala",
    events: "Iziganeko",
    trips: "Iihambo",
    oilAndGas: "I-oyile neGesi",
    laundry: "Impahla",
    partnerCategory: "ICandelo loMlingane",
    freeItem: "Inqaku lasimahla",
  },
};
