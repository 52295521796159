import React, { useEffect, useState } from "react";
import { Modal, Button, Popover } from "antd";
import paymentLogo from "../../../../../../icons/badges.png";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { usePaystackPayment } from "react-paystack";
import { connect } from "react-redux";
import strings from "../../../../../../strings";
import PaypalModal from "./paypalModal";



const PaymentModal = ({ 
    open, 
    onCancel,
    title,
    user,
    total,
    paymentDescription,
    onPay 
}) => {

    const FLUTTERWAVE_PUBLIC_KEY = process.env.REACT_APP_FLUTTERWAVE_KEY;
    const PAYSTACK_PUBLIC_KEY = process.env.REACT_APP_PAYSTACK_KEY;
    
    const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;
    const [ openPaypalModal, setOpenPaypalModal ] = useState(false);
    const [metaInfo, setMetaInfo] = useState({});
    const [ noOfMonth, setNoOfMonth ] = useState(null);



    useEffect(() => {
      setMonthToPay();
    },[]);


    const setMonthToPay = () => {
      const expiryDateYear = new Date(user.subscription_expires_on).getFullYear();
      const todayYear = new Date().getFullYear();

      const expiryMonth = new Date(user.subscription_expires_on).getMonth();
      const todaysMonth = new Date().getMonth();

      const difference = ((expiryDateYear - todayYear) * 12) + expiryMonth - todaysMonth;

      setNoOfMonth(() => {
        return difference <= 0 ? 1 : difference;
      })
    }
    const paystackConfig = {
        reference: new Date().getTime().toString(),
        email: user.email,
        currency: user.currency,
        amount:
          user.currency === "USD" || user.currency === "GBP"
            ? ((Number(total) * noOfMonth * 100) / nairaDollarRate).toFixed(2)
            : Number(total) * noOfMonth * 100,
        publicKey: PAYSTACK_PUBLIC_KEY,
    };

    const config = {
        public_key: FLUTTERWAVE_PUBLIC_KEY,
        tx_ref: Date.now(),
        amount: total * noOfMonth,
        currency: user.currency,
        payment_options: "card,mobilemoney,ussd",
        customer: {
          email: user.email,
          phonenumber: user.contact_number,
          name: `${user.first_name}${user.last_name}`,
        },
        meta: metaInfo,
    };

    const paymentCallback = (response) => {
        closePaymentModal();
        onCancel();
        onPay();
    };

    const handleFlutterPayment = useFlutterwave(config);
    const handlePaystackPayment = usePaystackPayment(paystackConfig);

    const africanCurrency = [
        "DZD",
        "AOA",
        "XOF",
        "BWP",
        "NOK",
        "XOF",
        "BIF",
        "XAF",
        "CVE",
        "KMF",
        "CDF",
        "DJF",
        "EGP",
        "ERN",
        "ETB",
        "GMD",
        "GHS",
        "GNF",
        "KES",
        "LSL",
        "LRD",
        "LYD",
        "MGA",
        "MWK",
        "MRO",
        "MUR",
        "MAD",
        "MZN",
        "NAD",
        "NGN",
        "RWF",
        "SCR",
        "SLL",
        "SOS",
        "ZAR",
        "SDG",
        "SZL",
        "TZS",
        "TND",
        "UGX",
        "ZMK",
        "ZWL"
    ];

    const ifAfricanCurrency = africanCurrency.includes(user.currency);

    const actionsContent = (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {ifAfricanCurrency && <Button
            key="flutterwave"
            onClick={() => {
              handleFlutterPayment({
                callback: paymentCallback,
                onClose: () => {},
              });
            }}
          >
            Pay With Flutterwave
          </Button>}
          {/* {ifAfricanCurrency && <Button
            key="paystack"
            onClick={() => {
              handlePaystackPayment(paymentCallback, () => {});
            }}
            style={{ marginTop: 5 }}
          >
            Pay With Paystack
          </Button>} */}
          <Button
            key="paypal"
            onClick={() => setOpenPaypalModal(true)}
            style={{ marginTop: 5 }}
          >
            Pay With Paypal
          </Button>
        </div>
      );
    return(
        <>
        <Modal
            open={open}
            onCancel={onCancel}
            title={`Pay ${user.currency}${total} * ${noOfMonth}months - ${user.currency}${total*noOfMonth} to continue`}
            footer={[
                <Button onClick={onCancel} type="ghost">
                  Cancel
                </Button>,
                <Button 
                  type="primary" 
                  style={{ marginLeft: 10 }} 
                  // disabled={total <= 1}
                >
                  <Popover 
                    key="actions" 
                    title="Actions" 
                    trigger="hover"
                    content={actionsContent}
                    >
                      Pay
                  </Popover>
                </Button>,
              ]}
        >
          <div style={{ margin: "0 0 10px" }}>
            <p>{paymentDescription}</p>
          </div>
            <img
                style={{ margin: "30px 0 -20px 0" }}
                src={paymentLogo}
                alt="payment options"
            />
        </Modal>
        
        {openPaypalModal && (
          <PaypalModal
            open={openPaypalModal}
            onCancel={() => setOpenPaypalModal(false)}
            onPay={onPay}
            total={total * noOfMonth}
            user={user}
          />
         )}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    allBranches: state.branch.allBranches,
});
  
export default connect(mapStateToProps, {
    
})(PaymentModal);